import { Center, Heading, Spinner, Stack } from '@chakra-ui/react';
import { sleep } from 'core';
import queryString from 'query-string';
import { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { LocalStore } from 'store/local';
import { useIntegrateSlack } from './hooks/integrateSlack';

interface IntegrateWithSlackState {
  code: string;
  slackState: string;
  redirect: boolean;
  error: Record<string, unknown>;
}

export function IntegrateWithSlackRedirect() {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const integrateSlack = useIntegrateSlack();

  const integrateWithSlack: IntegrateWithSlackState = {
    code: parsed.code as string,
    error: {},
    redirect: false,
    slackState: parsed.state as string,
  };

  (async () => {
    await sleep(1);
    const slackStateRes = await LocalStore.getSlackState();
    if (slackStateRes !== integrateWithSlack.slackState) {
      return;
    }
  })();

  useEffect(() => {
    integrateSlack.mutate(integrateWithSlack.code);
  }, []);

  return (
    <>
      {integrateSlack.isLoading && (
        <Center height={'100%'}>
          <Stack alignItems={'center'}>
            <Heading size="sm">Integrating</Heading>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Stack>
        </Center>
      )}

      {integrateSlack.isSuccess && (
        <Redirect
          to={`/settings/extension?${new URLSearchParams({ slack_success: 'true' }).toString()}`}
        />
      )}

      {integrateSlack.isError && (
        <Redirect
          to={`/settings/extension?${new URLSearchParams({
            E_SLACK_INTEGRATE: 'something went wrong',
          }).toString()}`}
        />
      )}
    </>
  );
}
