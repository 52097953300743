import './Accordin.css';

import { ClipboardIcon, CollapsedArrowIcon, ExpandedArrowIcon } from 'icons';

const RunbookAccordinBody = ({ title, collapse }: { title: string; collapse: boolean }) => {
  return (
    <div className="container">
      <ClipboardIcon className="mr-5" />
      {title}
      {collapse ? <ExpandedArrowIcon className="ml-5" /> : <CollapsedArrowIcon className="ml-5" />}
    </div>
  );
};

export { RunbookAccordinBody };
