import styled from 'styled-components';

export const SidebarContainer = styled.nav`
  border-right: 2px solid #f3f4f6;
  background-color: #fff;
  display: flex;
  min-width: 258px;
  width: 258px;
  padding-top: 6px;
  flex-direction: column;
`;

export const SidebarHeader = styled.header`
  border-bottom: 1px solid #f3f4f6;
  display: flex;
  justify-content: space-between;
  gap: 9px;
  font-size: 16px;
  color: #122146;
  font-weight: 600;
  white-space: nowrap;
  line-height: 132%;
  padding: 8px 8px 8px 8px;
`;

export const Title = styled.div`
  color: #122146;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%; /* 21.12px */
`;
export const List = styled.div`
  display: flex;
  flex-direction: column;

  max-height: 484px;
  overflow-y: scroll;
`;
