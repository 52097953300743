import React, { useReducer, useState } from 'react';
import { useQuery, QueryCache } from 'react-query';
import axios from 'axios';
import { createStandaloneToast } from '@chakra-ui/react';

import { API } from '../../../../../core/api';
import { IJiraConfigDetail } from 'core/interfaces/IJiraNew';
import { queryKeys, reactQueryConfig } from '../contants/service.contant';

import { getPager, reactQueryConfigError } from '../helpers/helper.service';
import { ServiceListReducer } from '../service.home/service.list/serviceList.reducer';
import { EntityACLMeta } from 'core/userAccess/types';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';

const toast = createStandaloneToast();

type escalationPolicy = {
  id: 'string';
  name: 'string';
  description: 'string';
  slug: 'string';
};
type tag = {
  key: 'string';
  value: 'string';
};

type typeOfOwner = 'team' | 'user' | 'squad';

type owner = {
  id: 'string';
  type: typeOfOwner;
  name: 'string';
  username_for_display: 'string';
};

type status = 'healthy' | 'maintenance';

type alertSource = {
  id: 'string';
  type: 'string';
  short_name: 'string';
};

export interface IJIssueType {
  name: string;
  id: string;
  statuses: IJStatus[];
}

export interface IJStatus {
  name: string;
  id: string;
}

export interface IJiraCloud {
  id: string;
  jira_client_key: string;
  issue_type: IJIssueType;
  project: IJProject;
  is_manual: boolean;
  statusmaps: IJStatusmap[];
}

export interface IJIraCloudServiceNew
  extends Omit<IJiraConfigDetail, 'jira_cloud_account_id' | 'owners' | 'jira_account_name'> {
  account_name: string;

  jira_account_id: string;
  owner_id: string;
  owner_type: string;
}

export interface IJProject {
  id: string;
  key: string;
  name: string;
}

export interface IJStatusmap {
  jira_status: string;
  system_status: string;
}

export interface ISlackChannelResponse {
  id: string;
  name: string;
  is_private: boolean;
}

type meta = {
  next_page: boolean;
  total_count: number;
  acl: Record<string, EntityACLMeta>;
};

export interface SeviceObj {
  id: 'string';
  name: 'string';
  created_at: 'string';
  updated_at: 'string';
  escalation_policy: escalationPolicy;
  api_key: 'string';
  description: 'string';
  tags: null | Array<tag>;
  owner: null | owner;
  maintainer: null | owner;
  status: string;
  active_alert_sources: Array<alertSource>;
  metrics: {
    responsiveness: {
      mtta: number;
      mttr: number;
    };
    incident_metrics: {
      triggered: number;
      acknowledged: number;
      suppressed: number;
      resolved: number;
    };
  };
  extensions: {
    jira_cloud: null | IJIraCloudServiceNew;
    slack: null | ISlackChannelResponse;
  };
  manager: { id: string; name: string; type: string };
}

export interface IServiceList {
  data: { services: Array<SeviceObj> };
  meta: meta;
  pagination: Array<number>;
}

export type sort_by = 'name' | 'mtta' | 'mttr' | 'status' | 'open_incidents';

export type sort_order = 'asc' | 'des';

const getServiceList = async (
  pageOffset: number,
  pageLimit: number,
  teamId: string | unknown,
  sort_by: sort_by | string,
  sort_order: sort_order | string,
  searchTerm: string,
  filterText: string,
) => {
  const { data } = await axios.get<IServiceList>(
    `${API.config.batman}/organizations/${API.config.organizationId}/catalog-services?page_limit=${pageLimit}&offset=${pageOffset}&owner_id=${teamId}&sort_by=${sort_by}&sort_order=${sort_order}&search_query=${searchTerm}&entity_owner=${filterText}`,
  );
  setUserAccessStore({
    entityACLMap: {
      ...getUserAccessStore().entityACLMap,
      services: {
        ...getUserAccessStore().entityACLMap?.services,
        ...data.meta.acl,
      },
    },
  });
  return data;
};

export function useServiceList(teamId: string | unknown, paginationLimit?: number) {
  const hasRead = true;
  const [pageOffset, setOffset] = useState<number>(1);
  const [pageLimit, setLimit] = useState<number>(10);

  const [state, dispatch] = useReducer(ServiceListReducer, {
    search: {
      searchText: '',
      enabled: false,
    },
    filters: {
      filterText: '',
      enabled: false,
    },
    pagination: {
      queryPageIndex: 1,
      queryPageSize: paginationLimit ?? 10,
    },
    drawer: {
      isDrawerOpen: false,
      drawerType: null,
      serviceID: null,
    },
    sorting: {
      sort_by: 'name',
      sort_order: 'asc',
    },
    isChecked: {
      checkedRows: [],
    },
    reset: {
      resetState: 0,
    },
  });

  const servicelist = useQuery(
    [
      queryKeys.SERVICELIST,
      state.pagination.queryPageIndex,
      state.pagination.queryPageSize,
      state.sorting.sort_by,
      state.sorting.sort_order,
      state.search.searchText,
      state.reset.resetState,
      teamId,
      state.filters.filterText,
    ],
    () =>
      getServiceList(
        state.pagination.queryPageIndex,
        state.pagination.queryPageSize,
        teamId,
        state.sorting.sort_by,
        state.sorting.sort_order,
        state.search.searchText,
        state.filters.filterText,
      ),
    {
      ...{ ...reactQueryConfig, refetchInterval: 300000, notifyOnChangeProps: ['data', 'error'] },
      onError: reactQueryConfigError,
      onSettled: data => {
        const totalPages = data?.meta?.total_count || 0;
        const pagination = getPager(totalPages, pageOffset, pageLimit);
        data && (data['pagination'] = pagination);
      },
    },
  );

  return {
    ...servicelist,
    enabled: hasRead,
    pageOffset,
    pageLimit,
    setLimit,
    setOffset,
    state,
    dispatch,
  };
}

async function serviceLastUpdated() {
  const response = await axios.get<{ data: { last_updated: string } }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/analyticsv2/last-updated`,
  );
  return response.data.data;
}

export const useLastUpdatedServiceQuery = () => {
  return useQuery(queryKeys.SERVICELASTUPDATED, serviceLastUpdated, {
    retry: 1,
    refetchOnWindowFocus: false,
  });
};
