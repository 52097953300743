import React from 'react';
import { EntityType, hasReadAccessToEntity, JSXResponseForEntity } from '../helpers';
import { useUserAccess } from 'core/userAccess/UserAccessContext';

const ResponsesForReassignUserReplacement: React.FC<
  Omit<JSXResponseForEntity, 'getEntityComponent' | 'getEntityName'>
> = ({ log, logMessage, getEntityComponentWithTruncatedName }) => {
  const acceptableEntityTypesForPopover = ['user', 'service'];
  const userHasReadSquadsPermission = useUserAccess().hasReadAccess('squads');

  const [_, entityNames] = logMessage.split(' with ');
  const [assignedToEntityName, assignedByEntityName] = entityNames?.split(' by ');
  const oldAssigneeEntityName = log.reason.split(' replacing ')[1].split(' with ')[0];
  const canNavigateToAssignedToEntity =
    assignedToEntityName !== 'Deleted Entity' &&
    hasReadAccessToEntity(log.assignedTo as EntityType);
  const canNavigateToOldAssigneeEntity = oldAssigneeEntityName !== 'Deleted Entity';
  const assignedToEntityComponent = canNavigateToAssignedToEntity ? (
    getEntityComponentWithTruncatedName({
      componentType: acceptableEntityTypesForPopover.includes(log.assignedTo)
        ? 'linkWPopover'
        : log.assignedTo === 'squad'
        ? userHasReadSquadsPermission
          ? 'linkWOPopover'
          : 'simple'
        : 'linkWPopover',
      name: assignedToEntityName,
      type: log.assignedTo as EntityType,
      id: log.id,
      popoverPlacement: 'left',
    })
  ) : (
    <>{assignedToEntityName}</>
  );
  const assignedByEntityComponent =
    assignedByEntityName !== 'Deleted Entity' ? (
      getEntityComponentWithTruncatedName({
        componentType: 'linkWPopover',
        name: assignedByEntityName,
        type: 'user',
        id: log.assignedBy,
        popoverPlacement: 'left',
      })
    ) : (
      <>{assignedByEntityName}</>
    );
  const oldAssigneeComponent = canNavigateToOldAssigneeEntity ? (
    getEntityComponentWithTruncatedName({
      componentType: 'linkWPopover',
      name: oldAssigneeEntityName,
      type: 'user',
      popoverPlacement: 'left',
      id: log.additionalInfo?.old_user_id,
    })
  ) : (
    <>{oldAssigneeEntityName}</>
  );
  return (
    <>
      {log.reason.split(oldAssigneeEntityName)[0]} {oldAssigneeComponent} with{' '}
      {assignedToEntityComponent} by {assignedByEntityComponent}
    </>
  );
};

export default ResponsesForReassignUserReplacement;
