import chakraUiTheme from '@chakra-ui/theme';
import React from 'react';
import ReactSelect, { GroupBase, Props, StylesConfig } from 'react-select';

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    size?: 'sm' | 'md' | 'lg';
    isParticipantsDropdown?: boolean;
    isStartTimeDropdown?: boolean;
    hideDropdownArrow?: boolean;
    hideSelectedValues?: boolean;
  }
}

const fontSizes = {
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
};

const paddings = {
  sm: '6px 9px',
  md: '8px 12px',
  lg: '10px 15px',
};

const px = {
  sm: '0.75rem',
  md: '1rem',
  lg: '1rem',
};

const customStyles: StylesConfig = {
  // When disabled, react-select sets the pointer-state to none
  // which prevents the `not-allowed` cursor style from chakra
  // from getting applied to the Control
  container: provided => ({
    ...provided,
    pointerEvents: 'auto',
    flex: 1,
  }),
  input: (provided, { selectProps: { inputValue, isMulti, size } }) => ({
    ...provided,
    ...(!(inputValue || isMulti) ? { position: 'absolute' } : {}),
    color: 'inherit',
    lineHeight: 1,
    fontSize: fontSizes[size ?? 'sm'],
  }),
  menu: provided => ({
    ...provided,
    boxShadow: `0 0 0 1px ${chakraUiTheme.colors.gray['200']}, 0 1px 1px ${chakraUiTheme.colors.gray['200']}`,
  }),
  option: (provided, { selectProps: { size } }) => ({
    ...provided,
    fontSize: fontSizes[size ?? 'sm'],
  }),
  control: (provided, { selectProps: { isParticipantsDropdown } }) => ({
    ...provided,
    borderColor: 'inherit',
    '&:hover': {
      borderColor: 'inherit',
    },
    borderRadius: isParticipantsDropdown ? '0px 6px 6px 0px' : '6px',
  }),
  valueContainer: (provided, { selectProps: { size } }) => {
    return {
      ...provided,
      padding: `0.345rem ${px[size ?? 'sm']}`,
    };
  },
  multiValue: (provided, { selectProps: { isParticipantsDropdown } }) =>
    isParticipantsDropdown
      ? {
          ...provided,
          borderRadius: '6px',
          backgroundColor: '#F1F3F6',
          padding: '4px 8px',
        }
      : { ...provided },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    color: chakraUiTheme.colors.gray['200'],
    '&:hover': {
      color: chakraUiTheme.colors.gray['200'],
    },
  }),
  loadingMessage: (provided, { selectProps: { size } }) => {
    return {
      ...provided,
      fontSize: fontSizes[size ?? 'sm'],
      padding: paddings[size ?? 'sm'],
    };
  },
};

type SelectProps = Props & {
  size?: 'sm' | 'md' | 'lg';
};

function Select({ size = 'sm', ...args }: SelectProps) {
  return (
    <ReactSelect
      name="colors"
      closeMenuOnSelect={false}
      styles={{
        ...customStyles,
      }}
      size={size}
      {...args}
    />
  );
}

export default Select;
