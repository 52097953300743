import { FocusBlock, Grid, Para, Tabs } from 'uie/components';
import { Text } from '@chakra-ui/react';
import { IncidentDescription } from './description';
import { DedupedEvents } from './dedupedEvents';
import { Cell } from 'react-table';
import { INCIDENT_DATA } from '../../../incident-list/common/types';
import {
  IHeatmapData,
  IIEvent,
  IIncident,
  IPastIncident,
  IPastIncidentListParameters,
  IPastIncidentStatsResponse,
} from 'core/interfaces/IIncidents';
import { IIntegration } from 'core/interfaces/IIntegration';
import { ChildIncidents } from './childIncidents';
import { PastIncidents } from './pastIncidents';
import { crownIconForDetailsTab } from '../../render.index';
import { AutoGroupedAlerts } from './autoGroupedAlerts';
import { Placeholder } from 'library/molecules';

interface Props {
  incident: IIncident;
  IncidentChildrenAction: ({ rowData }: { rowData: Partial<Cell<INCIDENT_DATA>> }) => JSX.Element;
  selectedDetailsTab: string;
  onTabSelect: (tab: string) => void;
  isPastIncidentsUnavailable: boolean;
  isParentIncident: boolean;
  isUnmergeIncidentsUnavailable: boolean;
  dedupedEvents: IIEvent[];
  getEvents: () => void;
  areDedupEventsLoading: boolean;
  arePastIncidentsLoading: boolean;
  alertSources: IIntegration[];
  children: IIncident[];
  pastIncidents: IPastIncident[];
  pastIncidentsStats: IPastIncidentStatsResponse;
  pastIncidentsHeatmap: IHeatmapData;
  unmergeModalProps: {
    onClose: () => void;
    isOpen: boolean;
    unMerge: (
      assignToMe: boolean,
      sendNotification: boolean,
      location: 'parent' | 'child',
    ) => Promise<void>;
    isUnmerging: boolean;
    latestAssigneeDeleted: boolean;
  };
  setPastIncidentsFilter: (activeDate: string) => void;
  clearPastIncidentsFilter: () => void;
  pastIncidentsListParameters: IPastIncidentListParameters;
  isPastIncidentsDataLoaded: boolean;
}

export const DetailsTab = ({
  incident,
  IncidentChildrenAction,
  isPastIncidentsUnavailable,
  onTabSelect,
  selectedDetailsTab,
  isParentIncident,
  isUnmergeIncidentsUnavailable,
  dedupedEvents,
  getEvents,
  areDedupEventsLoading,
  arePastIncidentsLoading,
  alertSources,
  children,
  pastIncidents,
  pastIncidentsStats,
  pastIncidentsHeatmap,
  setPastIncidentsFilter,
  clearPastIncidentsFilter,
  pastIncidentsListParameters,
  unmergeModalProps,
  isPastIncidentsDataLoaded,
}: Props) => {
  return (
    <div style={{ width: '100%' }}>
      <Grid>
        <Tabs
          className="details_tab"
          style={{
            padding: '20px',
          }}
          initialTab={selectedDetailsTab}
          onTabSelect={onTabSelect}
          tabWidth="100%"
          shellWidth="100%"
        >
          <FocusBlock value="Description">
            <Para as="span">Description</Para>
          </FocusBlock>

          {incident.event_count > 1 ? (
            <FocusBlock value="Deduped Events">
              <Para as="span">Deduped Events ({incident.event_count - 1})</Para>
            </FocusBlock>
          ) : (
            <></>
          )}

          <FocusBlock value="Past Incidents">
            <Text as="span" alignItems="center">
              Past Incidents {isPastIncidentsUnavailable ? crownIconForDetailsTab : null}
            </Text>
          </FocusBlock>

          {isParentIncident ? (
            <FocusBlock value="Child">
              <Para as="span">
                {`Child Incident${incident.children.length > 1 ? 's' : ''} (${
                  incident.children.length
                })`}{' '}
                {isUnmergeIncidentsUnavailable ? crownIconForDetailsTab : null}
              </Para>
            </FocusBlock>
          ) : (
            <></>
          )}

          {incident.grouped_alert_count ? (
            <FocusBlock value="Auto Grouped">
              <Para as="span">Auto Grouped ({incident.grouped_alert_count})</Para>
            </FocusBlock>
          ) : (
            <></>
          )}
        </Tabs>
      </Grid>
      <Grid style={selectedDetailsTab !== 'Child' ? { minHeight: 600 } : {}}>
        {selectedDetailsTab == 'Description' && (
          <IncidentDescription
            description={
              incident.description.length > 0 ? incident.description : 'No Description provided'
            }
          />
        )}

        {selectedDetailsTab === 'Deduped Events' && (
          <DedupedEvents
            events={dedupedEvents}
            getEvents={getEvents}
            isLoading={areDedupEventsLoading}
            showLoadMoreButton={
              !areDedupEventsLoading && dedupedEvents.length !== incident.event_count - 1
            }
          />
        )}

        {selectedDetailsTab === 'Child' && (
          <ChildIncidents
            {...{
              IncidentChildrenAction,
              alertSources,
              children,
              unmergeModalProps,
            }}
          />
        )}

        {selectedDetailsTab === 'Past Incidents' && (
          <PastIncidents
            {...{
              pastIncidents,
              pastIncidentsStats,
              pastIncidentsHeatmap,
              pastIncidentsListParameters,
              setPastIncidentsFilter,
              clearPastIncidentsFilter,
              arePastIncidentsLoading,
              incidentCreatedDate: `${incident.timeOfCreation}`,
              incidentID: incident.id,
              isPastIncidentsDataLoaded,
            }}
          />
        )}
        {selectedDetailsTab === 'Auto Grouped' && <AutoGroupedAlerts incidentId={incident.id} />}
      </Grid>
    </div>
  );
};
