import { Grid, Para, Theme } from 'uie/components';
import React from 'react';
import {
  IAnalyticsChartType,
  IAnalyticsPanelKind,
  IAnalyticsPanel,
  IAnalyticsPanelData,
  IAnalyticsQuery,
  ITabularDataResponse,
  ITimeSeriesDataResponse,
  IAnalyticsMaps,
} from '../../../../../core/interfaces/IAnalytics';
import { AnalyticsService } from '../../../../../core/services';

import Bar from './charts/bar';
import Calendar from './charts/calendar';
import Line from './charts/line';
import Pie from './charts/pie';
import DateConnected from './dateConnected';
import HeatMap from './heatMap';
import PrimeLine from './primeLine';
import Basic from './basic';
import { colorSchemes } from '@nivo/colors';
import { differenceInHours } from 'date-fns';

const { theme } = Theme;

export const getData = async (query: IAnalyticsQuery) => {
  const analyticsService = new AnalyticsService();

  try {
    const {
      data: { data },
    } = await analyticsService.executeQuery(query);
    return data;
  } catch (err: any) {
    throw err;
  }
};

export const renderPanel = (
  panel: IAnalyticsPanel,
  data: IAnalyticsPanelData,
  updatePanelData: (
    panelID: string,
    data: any[],
    owner?: string,
    dateString?: string,
    timeRange?: { gt: string; lt: string },
  ) => void,
) => {
  switch (panel.panelType) {
    case 'heat_map':
      return <HeatMap panel={panel} data={data} />;

    case 'date_connected':
      return <DateConnected panel={panel} data={data} updatePanelData={updatePanelData} />;

    case 'prime_line':
      return <PrimeLine panel={panel} data={data} updatePanelData={updatePanelData} />;

    default:
      return <Basic panel={panel} data={data} />;
  }
};

export const renderChart = (panel: IAnalyticsPanel, data: any[]) => {
  if (data.length === 0) {
    return (
      <Grid style={{ height: '100%' }}>
        <Para fontWeight={500} style={{ margin: 'auto' }}>
          No data found
        </Para>
      </Grid>
    );
  }

  switch (panel.chart) {
    case 'line':
      return <Line data={data} />;

    case 'heat_map':
      return <Calendar data={data} />;

    case 'pie':
      return <Pie data={data} config={panel.chart_config} />;

    case 'bar':
      return <Bar data={data} config={panel.chart_config} />;

    default:
      return (
        <Grid style={{ height: '100%' }}>
          <Para fontWeight={500} style={{ margin: 'auto' }}>
            Error Loading chart
          </Para>
        </Grid>
      );
  }
};

export const transformDataToChartData = (
  panel: IAnalyticsPanelKind,
  chart: IAnalyticsChartType,
  rawData: any[],
  type?: string,
  maps?: IAnalyticsMaps,
): any[] => {
  if (panel === 'time_series_data') {
    const data = rawData as ITimeSeriesDataResponse[];

    if (chart === 'heat_map') {
      return data.map(d => ({
        day: d.date,
        value: d.value,
      }));
    }
  } else if (panel === 'tabular_data') {
    const data = rawData as ITabularDataResponse[];

    if (chart === 'pie') {
      return data.map(d => ({
        id: d.entity,
        label: type && maps ? getEntityName(d.entity, type, maps) : d.entity,
        value: d.value,
      }));
    }

    if (chart === 'bar') {
      return data.map(d => ({
        entity: type && maps ? getEntityName(d.entity, type, maps) : d.entity,
        value: d.value,
      }));
    }
  }

  return rawData;
};

export const isCacheValid = (updatedAt: Date) => {
  const now = new Date().getTime();
  const updatedAtDate = new Date(updatedAt);

  const hoursDiff = differenceInHours(now, updatedAtDate.getTime());

  if (hoursDiff < 1) return true;

  return false;
};

const getEntityName = (id: string, type: string, maps: IAnalyticsMaps) => maps[type]?.[id] || id;

export const statusColors: { [key: string]: string } = {
  resolved: theme.success.default,
  suppressed: theme.shades.lightGrey,
  acknowledged: theme.acknowledge.default,
  triggered: theme.trigger.default,
};

export const getStatusColors = () => {
  let colorIndex = 0;
  const colors = [...colorSchemes.nivo, ...colorSchemes.category10];

  return (line: { id: string | number }) => {
    let color = '';
    if (statusColors[line.id]) {
      color = statusColors[line.id];
    } else {
      color = colors[colorIndex];
      colorIndex += 1;
    }
    return color;
  };
};
