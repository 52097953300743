import { EventInput } from '@fullcalendar/react';
import {
  GetScheduleQuery,
  GetSchedulesQuery,
  WhoIsOncallQuery,
} from 'views/main/organization/schedules/graphql/query';
import { FILTER_TYPES } from '../constants/schedulers.filter';
import {
  CustomPeriodUnit,
  ChangeParticipantsInterval,
  DayOfWeek,
  PeriodOptions,
  NewTag,
  NewOwner,
  ScheduleOverride,
} from 'views/main/organization/schedules/graphql/types';
import { ActionButtonLabels } from '../constants/schedules.copy';
import { CSSObject } from '@chakra-ui/react';
import { ReactNode } from 'react';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: Date;
};

export type INewSchedule = Pick<
  ISchedule,
  'name' | 'description' | 'timeZone' | 'rotations' | 'owner' | 'tags' | 'showGaps' | 'tagsSource'
>;

export interface ISchedule {
  __typename?: 'Schedule';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  orgId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  owner: NewOwner;
  tags?: InputMaybe<Array<NewTag>>;
  timeZone: Scalars['String'];
  rotations: Array<IRotation>;
  showGaps?: Scalars['Boolean'];
  tagsSource?: Record<string, string[]>;
}

export interface RotationDuration {
  startDate: Scalars['Time'];
  period: PeriodOptions; //none/daily/weekly/monthly/custom
  shiftTimeSlot: ITimeSlot;
  customPeriod?: InputMaybe<ICustomPeriod>;
  changeParticipantsFrequency: Scalars['Int'];
  changeParticipantsUnit: ChangeParticipantsInterval; // rotation/day/week/month
  ends: Scalars['Boolean'];
  endDate?: Scalars['Time'];
  endsAfterIterations?: Scalars['Int'];
  onCallHoursPerParticipant?: Scalars['Int'];
}

export interface IRotation extends RotationDuration {
  __typename?: 'Rotation';
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
  scheduleId?: Scalars['String'];
  participantGroups?: Array<IParticipantGroup>;
  showOnCalendar?: Scalars['Boolean'];
}

export interface IParticipantGroup {
  __typename?: 'ParticipantGroup';
  participants?: InputMaybe<Array<InputMaybe<IParticipant>>>;
  groupId?: string;
}

export interface IParticipant {
  __typename?: 'Participant';
  type: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  timeZone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
}

export interface ITimeOption {
  _typename?: 'TimeOption';
  label: Scalars['String'];
  value: Scalars['String'];
  duration: Scalars['Int'];
}

export interface ITimeSlot {
  _typename?: 'TimeSlot';
  startTime: Scalars['String'];
  duration: Scalars['Int'];
  endTime?: Scalars['String'];
  dayOfWeek?: InputMaybe<DayOfWeek>; // sunday/monday/tuesday/wednesday/thursday/friday/saturday
  id?: Scalars['Int'];
  isSameDay?: Scalars['Boolean'];
}

export interface ICustomPeriod {
  periodFrequency: Scalars['Int'];
  periodUnit: CustomPeriodUnit; // day/week/month
  timeSlots: Array<ITimeSlot>;
  daysOfWeekFilter?: Array<boolean>;
  repeatHoursAndTime?: boolean;
}

export interface IfilterOption {
  value: any;
  label: string;
  type?: string;
  time_zone?: string;
  id: string;
  username?: string;
}

export interface IfilterDetail {
  label: string;
  filterOptions: Array<IfilterOption>;
}

export interface IfilterDetails {
  [FILTER_TYPES.PARTICIPANT]: IfilterDetail;
  [FILTER_TYPES.ESCALATION_POLICY]: IfilterDetail;
}

export interface IfilterObject {
  [FILTER_TYPES.PARTICIPANT]: Array<IfilterOption>;
  [FILTER_TYPES.ESCALATION_POLICY]: Array<IfilterOption>;
  [FILTER_TYPES.SCHEDULE]: Array<IfilterOption>;
  [FILTER_TYPES.MY_ON_CALL]: boolean | null | undefined;
  [FILTER_TYPES.NO_ESCALATION_POLICY]: boolean | null | undefined;
}

/** ------------------------------------------ Interfaces ------------------------------------------ */
export enum ParticipantType {
  user = 'user',
  squads = 'squads',
}
export enum CalendarViewType {
  /** Table One Grid View */
  gridWeek = 'gridWeek',
  /** Table Two Grid View */
  twoGridWeek = 'twoGridWeek',
  /** Calendar Month Grid View */
  timeGridWeek = 'timeGridWeek',
  /** Calendar Two Month Grid View */
  twotimeGridWeek = 'twotimeGridWeek',
  /** Calendar Month Grid View */
  dayGridMonth = 'dayGridMonth',
  /** Table List View */
  listView = 'listView',
}
export type ICalendarViewType = 'timeGridWeek' | 'dayGridMonth' | 'timeGridDay' | 'listView';

export type IScheduleEvents = (EventInput & {
  isAGap?: boolean;
  rotationId?: number;
  scheduleId?: number;
  isOverride?: boolean;
  participants: IParticipantGroup[] | Array<Record<string, any>>;
  override?: ScheduleOverride & { enabled: boolean };
})[];

export type IScheduleDetail = GetScheduleQuery['schedule'];

export type ISchedulesDetail = GetSchedulesQuery['schedules'];
export type IGapsDetail = NonNullable<GetSchedulesQuery['schedules']>[number]['gaps'];
export type IOverridesDetail = NonNullable<GetSchedulesQuery['schedules']>[number]['overrides'];
export type IRotationDetail = NonNullable<
  NonNullable<GetSchedulesQuery['schedules']>[number]['rotations']
>;

export type IOnCallCoverageDetail = NonNullable<
  GetSchedulesQuery['schedules']
>[number]['oncallCoverage'];
export type ISchedulePeriod = 'daily' | 'weekly' | 'monthly' | 'custom';
export type IRotationEvent = IRotationDetail[number]['events'];

export type ISchedulesOnCallUsers = WhoIsOncallQuery;
export type IRotationEventParticipants = NonNullable<IRotationEvent>[number]['participants'];
export type IRotationEventParticipant = NonNullable<IRotationEventParticipants>[number];

export type IScheduleActionButtonInfo = {
  label: ActionButtonLabels;
  variant: string;
  icon: JSX.Element;
  iconStyles: CSSObject;
  showAction: boolean;
  disabled: boolean;
  hasPermission?: boolean;
  onClick: (scheduleId: number, scheduleName: string) => () => void;
};

export type SchedulesHelpGuide = {
  title: string;
  desc: ReactNode[];
  note?: string;
  link: { url: string; label: string }[];
  type: 'help' | 'queries' | 'tips';
}[];

export type EventParticipant = {
  ID: string;
  type: string;
  isOverride: boolean;
  participant: {
    members?:
      | {
          name: string | undefined;
          ID?: string;
          type?: string;
        }[]
      | undefined;
    timeZone?: string | undefined;
    name: string | undefined;
    username: string | undefined;
    originalParticipants?:
      | {
          name: string | undefined;
        }[]
      | undefined;
  };
};
