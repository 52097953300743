import styled from 'styled-components';

export const CardWrapper = styled.div`
  background-color: var(--white, #fff);
  max-width: 463px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 4px;
`;

export const CardHeader = styled.header`
  display: flex;
  gap: 12px;
  font-size: 24px;
  color: #09305a;
  font-weight: 500;
  line-height: 100%;
`;

export const MemberInfo = styled.div`
  display: flex;
  gap: 3px;
  flex-grow: 1;
`;

export const MemberName = styled.h2`
  font-family: Mulish, sans-serif;
  margin: 0;
`;

export const CardBody = styled.p`
  color: #09305a;
  font-family: Mulish, sans-serif;
  line-height: 24px;
  margin: 22px 0 0;
`;

export const MemberNameHighlight = styled.span`
  font-weight: 800;
`;

export const CardFooter = styled.footer`
  margin-top: 22px;
`;

export const SquadOwnerLabel = styled.label`
  color: #8890a2;
  font: 14px/132% Mulish, sans-serif;
  display: block;
  margin-bottom: 10px;
`;

export const CardActions = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 22px;
  line-height: 150%;
  justify-content: flex-end;
`;

export const ModalWrapper = styled.div`
  background-color: var(--white, #fff);
  max-width: 470px;
  font-size: 16px;
  color: #09305a;
  font-weight: 400;
  line-height: 150%;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 100%;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const ModalTitle = styled.h2`
  font-family: Mulish, sans-serif;
  margin: 0;
`;

export const ModalContent = styled.div`
  font-family: Mulish, sans-serif;
  margin-top: 22px;
`;

export const WarningMessage = styled.p`
  line-height: 24px;
  margin: 0;
`;

export const SectionTitle = styled.h3`
  font-weight: 800;
  margin: 15px 0 0;
`;

export const ConflictItemWrapper = styled.div`
  margin-top: 5px;
`;

export const BadgeContainer = styled.div`
  align-items: center;
  border-radius: 9999px;
  background-color: var(--Theme-1-Theme-1-400, #c8e2ff);
  color: var(--Gray-950, #09325e);
  white-space: nowrap;
  text-align: center;
  width: 19px;
  justify-content: center;
  height: 19px;
  padding: 4px 0;
  font: 600 9px Mulish, sans-serif;
  margin-left: 12px;
  margin-top: 7px;
`;
