import Axios from 'axios';
import { API } from 'core';
import { QUERY_CONFIG } from 'library/query-config';
import { useQuery } from 'react-query';

import { QUERY_KEY } from '../const';

const CONFIG = {
  ...QUERY_CONFIG,
  cacheTime: 0,
};

const getResponders = (incidentId: string) => {
  return Axios.get(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${incidentId}/additional-responders`,
  );
};

export const useGetResponders = (incidentId: string) => {
  const { data, isSuccess, isError, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEY.ADDITIONAL_RESPONDERS, incidentId],
    queryFn: () => getResponders(incidentId),
    ...CONFIG,
  });
  const response = data?.data ?? { data: { additional_responders: [] } };
  return {
    responders: response.data.additional_responders,
    isSuccess,
    isError,
    isLoading,
    isFetching,
  };
};
