import './index.css';

import {
  DialogModalFrame,
  FocusBlock,
  Grid,
  IconButton,
  Label,
  Para,
  SelectBox,
  Theme,
} from 'uie/components';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';

import { IAppState } from '../../../../../../core/interfaces/IAppState';
import { IIncident } from '../../../../../../core/interfaces/IIncidents';
import { IOrganization } from '../../../../../../core/interfaces/IOrganization';
import { BackIcon } from '../../../../../../icons';
import CircleCIAction from './integrations/circleCI';
import JiraCloudAction from './integrations/jiraCloud';
import JiraServerAction from './integrations/jiraServer';
import ManualWebhookTriggers from './integrations/manual_webhook_triggers';
import ServiceNowTrigger from './integrations/servicenow/';

const { theme } = Theme;

export interface IAction {
  name: string;
  description?: string;
  trigger: string;
}

export interface IIntegration {
  name: string;
  description?: string;
  actions: IAction[];
}

interface IProps extends Pick<IAppState, 'organization' | 'roles' | 'integrations' | 'userInfo'> {
  selectedOrg: IOrganization;
  incident: IIncident;
  getIncidentDetails: () => void;
  getIncidentMapping: () => void;
  children: ReactNode;
}

interface IState {
  openModal: boolean;
  actionsSearch: string;
  integrations: IIntegration[];
  selectedIntegration: IIntegration | null;
  selectedAction: IAction | null;
}

export class IncidentDetailsActions extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const plan = props.organization.plan.p;
    const integrations: IIntegration[] = [
      {
        name: 'CircleCI',
        description: 'Resolve With CircleCI',
        actions: [
          {
            name: 'Rebuild a project in CircleCI',
            trigger: 'ci.rebuild',
            description: 'Rebuild a project in CircleCI',
          },
        ],
      },
      ...(plan?.rules['jira-cloud']?.quantity
        ? [
            {
              name: 'Jira Cloud',
              description: 'Add incident to Jira Cloud',
              actions: [
                {
                  name: 'Create a new ticket in Jira Cloud',
                  trigger: 'jc.ticket',
                  description: 'Create a new ticket in Jira Cloud',
                },
              ],
            },
          ]
        : []),
      ...(plan?.rules['jira-server']?.quantity
        ? [
            {
              name: 'Jira DC',
              description: 'Add incident to Jira DC',
              actions: [
                {
                  name: 'Create a new ticket in Jira DC',
                  trigger: 'jc.ticket',
                  description: 'Create a new ticket in Jira DC',
                },
              ],
            },
          ]
        : []),
      {
        name: 'Webhook',
        description: 'Manually trigger a webhook',
        actions: [
          {
            name: 'Manually trigger a Webhook',
            trigger: 'webhook.manual',
            description: 'Select a webhook trigger',
          },
        ],
      },
      ...(plan?.rules['servicenow']?.quantity
        ? [
            {
              name: 'ServiceNow',
              description: 'Add incident to ServiceNow',
              actions: [
                {
                  name: 'Create a new incident in ServiceNow',
                  trigger: 'sn.incident',
                  description: 'Create a new incident in ServiceNow',
                },
              ],
            },
          ]
        : []),
    ];

    this.state = {
      integrations,
      openModal: false,
      actionsSearch: '',
      selectedIntegration: null,
      selectedAction: null,
    };
  }

  public incidentServiceName =
    this.props.organization.services.s.find(s => s.id === this.props.incident.service_id)?.name ??
    'Service Deleted';

  public toggleModal = () => this.setState(oldState => ({ openModal: !oldState.openModal }));
  public closeModal = () => this.setState({ openModal: false });

  public handleIntegrationSelect = (_: any, selectedIntegration: IIntegration) =>
    this.setState({ selectedIntegration, selectedAction: null });

  public handleActionSelect = (selectedAction: IAction) => () => this.setState({ selectedAction });

  public handleGoBackAction = () => this.setState({ selectedAction: null });

  public render(this: IncidentDetailsActions) {
    return (
      <>
        <div onClick={this.toggleModal}>{this.props.children}</div>
        <DialogModalFrame
          id="incident_details__actions_modal"
          onClose={this.closeModal}
          padding="32px"
          width="400px"
        >
          {this.state.openModal && (
            <Grid type="column">
              <Para
                fontWeight={500}
                color={theme.shades.cement}
                fontSize={24}
                className="pr-20 mb-10"
              >
                Respond With Action
              </Para>

              <Grid type="column" style={{ marginTop: '16px' }}>
                <Label fontWeight={400} color={theme.shades.grey} style={{ marginBottom: '4px' }}>
                  Via
                </Label>
                <SelectBox
                  hook={
                    this.state.selectedIntegration ? (
                      <Grid alignItems="center">
                        <Para fontSize={16} className="ml-10">
                          {this.state.selectedIntegration.name}
                        </Para>
                      </Grid>
                    ) : (
                      <Para fontSize={16} className="ml-10">
                        Select an Integration
                      </Para>
                    )
                  }
                  searchHookProps={{
                    value: this.state.actionsSearch,
                    height: '24px',
                    fontSize: '16px',
                    onChange: e => this.setState({ actionsSearch: e.target.value }),
                  }}
                  onValueChange={this.handleIntegrationSelect}
                  height="auto"
                  maxHeight="200px"
                  width="100%"
                  maxWidth="100%"
                >
                  {this.state.integrations
                    .filter(integration =>
                      this.state.actionsSearch
                        ? integration.name
                            .toLowerCase()
                            .includes(this.state.actionsSearch.toLowerCase())
                        : true,
                    )
                    .map((integration, idx) => (
                      <FocusBlock
                        key={idx}
                        value={integration}
                        isSelected={integration.name === this.state.selectedIntegration?.name}
                      >
                        <Grid alignItems="center">
                          <Para fontSize={16} className="ml-10">
                            {integration.name}
                          </Para>
                        </Grid>
                      </FocusBlock>
                    ))}
                </SelectBox>
              </Grid>

              {this.state.selectedIntegration && (
                <Grid type="column" style={{ marginTop: '24px' }}>
                  <Label fontWeight={400} color={theme.shades.grey} style={{ marginBottom: '4px' }}>
                    Actions
                  </Label>

                  {!this.state.selectedAction ? (
                    this.state.selectedIntegration.actions.map((action, idx) => (
                      <Grid
                        key={idx}
                        type="column"
                        className="incidentDetails-actionsModal-action cursor-pointer"
                        onClick={this.handleActionSelect(action)}
                      >
                        <Para fontWeight={500} className="mb-5">
                          {action.name}
                        </Para>
                      </Grid>
                    ))
                  ) : (
                    <Grid type="column" className="mt-10">
                      <Grid>
                        <IconButton
                          style={{ paddingLeft: 0 }}
                          base="32px"
                          onClick={this.handleGoBackAction}
                        >
                          <BackIcon />
                        </IconButton>
                        <Para fontWeight={500} className="ml-10" style={{ lineHeight: '32px' }}>
                          {this.state.selectedAction.description}
                        </Para>
                      </Grid>

                      {this.state.selectedIntegration.name === 'CircleCI' && (
                        <CircleCIAction
                          incident={this.props.incident}
                          getIncidentDetails={this.props.getIncidentDetails}
                        />
                      )}

                      {this.state.selectedIntegration.name === 'Jira Cloud' && (
                        <JiraCloudAction
                          serviceName={this.incidentServiceName}
                          incident={this.props.incident}
                          getIncidentDetails={this.props.getIncidentDetails}
                        />
                      )}

                      {this.state.selectedIntegration.name === 'Jira DC' && (
                        <JiraServerAction
                          serviceName={this.incidentServiceName}
                          incident={this.props.incident}
                          getIncidentDetails={this.props.getIncidentDetails}
                        />
                      )}

                      {this.state.selectedIntegration.name === 'ServiceNow' && (
                        <ServiceNowTrigger
                          incident={this.props.incident}
                          getIncidentDetails={this.props.getIncidentDetails}
                          getIncidentMapping={this.props.getIncidentMapping}
                        />
                      )}

                      {this.state.selectedIntegration.name === 'Webhook' && (
                        <ManualWebhookTriggers
                          incident={this.props.incident}
                          getIncidentDetails={this.props.getIncidentDetails}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </DialogModalFrame>
      </>
    );
  }
}

export default connect((state: IAppState) => ({
  organization: state.organization,
  selectedOrg: state.userOrganizations.o.find(
    o => o.organizationId === state.INIT_ORG.orgId,
  ) as IOrganization,
  roles: state.roles,
  integrations: state.integrations,
  userInfo: state.userInfo,
}))(IncidentDetailsActions);
