import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { Layout, PageHeader } from 'library/molecules';
import { memo, useCallback, useEffect, useReducer } from 'react';
import { useQueryClient } from 'react-query';
import useQueryParams from 'core/hooks/useQueryParams';
import { HEADER_FILTERS_COUNT, SEARCH_QUERY_KEY } from '../common/constants';
import { IOrganizationEvents } from 'core/interfaces/IOrganization';
import { filterManager } from '../filters/manager';
import { FilterTags } from '../filters/tags';
import { useForceUpdate } from '../hooks/useForceUpdate';
import useSoketiBinding from '../../../../../core/hooks/useSoketiBinding';
import IncidentTabGroup from './IncidentTabGroup';
import useIncidentSearch from '../hooks/useIncidentSearch';
import { useSearchParam } from 'core/hooks/useSearchParam';
import { IncidentParamsContext, useAllParameters } from '../hooks/useAllParams';
import { ActionKind, INITIAL_INCIDENT_LIST_STATE, IncidentListContext } from '../store';
import { CreateIncident } from '../create';
import { FiltersComponent } from '../filters';
import { IncidentListReducer } from '../store/reducer';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_INCIDENT_LIST_PAGE_V2_PAGE_VIEWED } from 'core/const/tracker';
import {
  canAutoRefreshIL,
  removeListDefaultDaysOverride,
  setDefaultDaysOverride,
} from '../store/persistent-storage';
import { invalIncidentsListData } from '../common/util';
import { debounce } from 'lodash';
import { ThrottleConfig } from 'core/interfaces/IOrgConfig';
import { useSession } from '../behaviors/Session';
import { DEFAULT_THROTTLE_CONFIG } from '../common/constants';
import { SOKETI_RELOAD_EVENTS } from 'core/const/soketi';

const IncidentListHome = memo(() => {
  const queryClient = useQueryClient();
  const {
    session: { orgState: organization },
  } = useSession();

  const isAllTimeTextSearchEnabled =
    organization?.currentOrg?.o?.config.all_time_text_search_enabled ?? false;

  const throttleConfig: ThrottleConfig =
    organization?.currentOrg?.o?.config['incident_list_throttle_config'] ?? DEFAULT_THROTTLE_CONFIG;

  const defaultDaysOverrideConfig =
    organization?.currentOrg?.o?.config.incident_list_default_config;

  const handleForceUpdate = useForceUpdate();
  const [searchQuery] = useSearchParam(SEARCH_QUERY_KEY, 'replace');
  const query = useQueryParams();
  const [state, dispatch] = useReducer(IncidentListReducer, INITIAL_INCIDENT_LIST_STATE);

  const { debouncedSearch } = useIncidentSearch({ state, dispatch });
  const params = useAllParameters(queryClient, query);

  const {
    isOpen: isOpenCreateIncident,
    onOpen: onOpenCreateIncident,
    onClose: onCloseCreateIncident,
  } = useDisclosure();

  const { isOpen: isOpenFilter, onOpen: onOpenFilter, onClose: onCloseFilter } = useDisclosure();

  const setCanAutoRefresh = (value: boolean) => {
    dispatch({ type: ActionKind.ALLOW_AUTO_REFRESH, payload: { canAutoRefresh: value } });
  };
  const debouncedAutoRefresh = useCallback(
    debounce(
      () => {
        invalIncidentsListData(queryClient);
      },
      throttleConfig.debounce_interval,
      { maxWait: throttleConfig.max_wait },
    ),
    [],
  );

  const incidentListSoketiBinding = useSoketiBinding({
    eventName: SOKETI_RELOAD_EVENTS,
    eventHandler: ({ message }: { message: IOrganizationEvents }) => {
      // if any drawer is opened, we aren't going to auto refresh
      if (
        message === 'reload-incidents' &&
        canAutoRefreshIL() &&
        window.document.visibilityState === 'visible'
      ) {
        debouncedAutoRefresh();
      }

      if (message === 'reload-group') {
        // reload saved filters
      }
    },
  });

  useEffect(() => {
    AppTracker.track(T_WA_UP_INCIDENT_LIST_PAGE_V2_PAGE_VIEWED);
    return incidentListSoketiBinding();
  }, []);

  const onClose = () => {
    setCanAutoRefresh(true);
    onCloseFilter();
    handleForceUpdate();
  };

  useEffect(() => {
    if (defaultDaysOverrideConfig && defaultDaysOverrideConfig.enabled) {
      setDefaultDaysOverride(defaultDaysOverrideConfig?.number_of_days);
    } else {
      removeListDefaultDaysOverride();
    }
    filterManager.rebuildFilters();
  }, [defaultDaysOverrideConfig]);

  return (
    <Layout>
      <Box
        bg="brand.white"
        borderRadius="4px 4px 0px 0px"
        width="100%"
        height="100%"
        overflow="auto"
      >
        <PageHeader
          title="Incidents"
          search={true}
          searchValue={searchQuery ?? ''}
          filter={true}
          isFilterApplied={filterManager.isFilterApplied()}
          onFilter={() => {
            setCanAutoRefresh(false);
            onOpenFilter();
          }}
          desc={
            <Text variant="body" color="secondary.1000">
              {filterManager.getDesc(isAllTimeTextSearchEnabled && !!searchQuery)}
            </Text>
          }
          button="Create New Incident"
          onButtonClick={() => {
            setCanAutoRefresh(false);
            onOpenCreateIncident();
          }}
          onSearch={debouncedSearch}
        >
          <FilterTags
            filters={filterManager.getUIFilters()}
            count={HEADER_FILTERS_COUNT}
            onChange={handleForceUpdate}
          />
        </PageHeader>

        <IncidentParamsContext.Provider value={{ params }}>
          <IncidentListContext.Provider value={{ state, dispatch }}>
            <IncidentTabGroup {...{ onOpenCreateIncident }} />
            <CreateIncident
              isOpen={isOpenCreateIncident}
              onClose={() => {
                setCanAutoRefresh(true);
                onCloseCreateIncident();
              }}
            />
            <FiltersComponent isOpen={isOpenFilter} onClose={onClose} />
          </IncidentListContext.Provider>
        </IncidentParamsContext.Provider>
      </Box>
    </Layout>
  );
});

export default IncidentListHome;
