import { ChakraProvider } from '@chakra-ui/react';
import {
  DialogModalFrame,
  Grid,
  IconButton,
  Label,
  Para,
  Pill,
  SnackBar,
  SnackContext,
  Theme,
} from 'uie/components';
import { EscalationService, exception } from 'core';
import { deepCopy, focusCard } from 'core/helpers';
import * as React from 'react';
import { connect } from 'react-redux';
import { generatePath, Link, RouteComponentProps, useHistory } from 'react-router-dom';
import shortid from 'shortid';
import { GetListOfSchedulesQuery } from 'views/main/organization/schedules/graphql/query';
import {
  ESCALATION_POLICIES_HOME_PATH,
  SERVICE_DETAILS_PATH,
  TEAM_SQUADS_PATH,
} from 'views/main/routes/routes';
import ConfirmModal from '../../../../components/confirm.modal';
import UnsavedChangesGuard from '../../../../components/unsavedChangesGuard';
import { requestOrganizationEscalation } from '../../../../core/actions';
import {
  T_WA_GS_ESCALATION_POLICY_DELETED,
  T_WA_GS_ESCALATION_POLICY_PAGE_VIEWED,
} from '../../../../core/const/tracker';
import { IAppState } from '../../../../core/interfaces/IAppState';
import {
  IEscalationPolicy,
  IEscalationPolicyRules,
} from '../../../../core/interfaces/IEscalationPolicy';
import { IService } from '../../../../core/interfaces/IService';
import {
  ArrowLeftIcon,
  CloseIcon,
  EscalationIcon,
  ProfileIcon,
  RoundRobinIcon,
  ScheduleIcon,
  ServiceIcon,
  SquadIcon,
} from '../../../../icons';
import { AppTracker } from '../../../../shared/analytics/tracker';
import { ActiveFilterTag } from '../owner-filters/ActiveFilterTags';
import { encodeFilterParam } from '../owner-filters/helpers';
import {
  DropdownOption,
  FilterEntity,
  FilterObject,
  Option,
  Owner,
  useEntityOwnerFilter,
} from '../owner-filters/hooks/useEntityOwnerFilter';
import { SelectedFilterTag } from '../owner-filters/SelectedFilterTags';
import withListOfSchedules from '../schedules/common/GetListOfSchedules';
import { chakraTheme } from '../service-catalog';
import useQueryParams from '../service-catalog/helpers/helper.query-params';
import { EntityOwnerPill } from './components/entity_owner_pill';
import { EPFilter } from './components/filter';
import { EPFilterTags } from './components/filter_tags';
import EscalationModal from './edit.modal';
import './index.css';
import { GetEntityComponentProps, EntityType } from '../navigation-flows/helpers';
import { THEME_COLORS } from 'library/theme/colors';
import { UserAccessContextValue, withUserAccess } from 'core/userAccess/UserAccessContext';
import { getUserAccessStore, subscribeToUserAccessStore } from 'core/userAccess/UserAccessStore';
import { EntityACLMeta } from 'core/userAccess/types';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import useUINavigationFunctions from 'views/main/organization/navigation-flows/useUINavigationFunctionV2';

type IMix = Pick<IAppState, 'organization' | 'userInfo'> & RouteComponentProps;
interface IProps extends IMix {
  showSnackBar: (msg: string, bg: string, timeout: number) => void;
  requestOrganizationEscalation: typeof requestOrganizationEscalation;
  schedulesList?: GetListOfSchedulesQuery;
  getEntityComponent: (props: GetEntityComponentProps) => JSX.Element;
  filters: {
    encodedFilter: string;
    activeFilterTags: ActiveFilterTag[];
    onClickActiveFilterTag: (owner: Owner, id: string) => void;
    setOpenDrawer: (openDrawer: boolean) => void;
    openDrawer: boolean;
    onCancel: () => void;
    ownerFilterSelected: boolean;
    onClickApplyFilters: () => void;
    onClearFilters: () => void;
    dropdownOptions: DropdownOption[];
    onChangeHandler: (values: Option) => void;
    filterTags: SelectedFilterTag[];
    handleSelectedFilterTagClick: (type: Owner, id: string) => void;
    filtersApplied: boolean;
  };
  userAccess: UserAccessContextValue;
}

const { theme } = Theme;

interface IState {
  searchTerm: string;
  showEscalationPolicyModal: boolean;
  escalationPolicyEdit?: IEscalationPolicy;
  showModalType: string;
  showEditConfig: string;
  showRemainingServices: string;
  deleteEscalationPolicy: string;
  errorMessage: string;
  showRemainingMembers: IEscalationPolicyRules | null;
  showUnsavedChangesModal: boolean;
  isDirty: boolean;
  escalationPolicies: IEscalationPolicy[];
  acl: Record<string, EntityACLMeta>;
}

class EscalationPolicies extends React.Component<IProps, IState> {
  private _refs: { [key: string]: HTMLDivElement } = {};
  private isEpFocused = false;
  private subsc: () => void | null = () => {};

  _escPolicyService = new EscalationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      searchTerm: '',
      showEscalationPolicyModal: false,
      showModalType: 'add',
      showEditConfig: '',
      showRemainingServices: '',
      deleteEscalationPolicy: '',
      errorMessage: '',
      showRemainingMembers: null,
      showUnsavedChangesModal: false,
      isDirty: false,
      escalationPolicies: [],
      acl: {},
    };
  }

  deleteEscalationPolicy = async () => {
    AppTracker.track(T_WA_GS_ESCALATION_POLICY_DELETED);
    try {
      await this._escPolicyService.delete(this.state.deleteEscalationPolicy);
      this.props.requestOrganizationEscalation();
    } catch (err) {
      this.props.showSnackBar(
        exception.handle('E_DELETE_ESCALATION_POLICY', err),
        theme.danger.default,
        10000,
      );
    }
  };

  editEscalationPolicy = (escalationPolicy: IEscalationPolicy) => {
    this.setState({
      escalationPolicyEdit: escalationPolicy,
      showModalType: 'edit',
      showEscalationPolicyModal: true,
      showEditConfig: '',
    });
  };

  public focusTargetEpId() {
    const epId = this.props.location.hash.slice(1).split('?')[0];
    if (this._refs[epId]) {
      this.isEpFocused = true;
      focusCard(this._refs[epId]);
    }
  }

  public componentDidMount() {
    this.focusTargetEpId();
    this.props.requestOrganizationEscalation();
    this.setState({ escalationPolicies: this.props.organization.escalations.e });
    AppTracker.track(T_WA_GS_ESCALATION_POLICY_PAGE_VIEWED);
    this.subsc = subscribeToUserAccessStore(store => {
      this.setState({
        acl: store.entityACLMap.escalation_policies ?? {},
      });
    });
  }

  public componentWillUnmount() {
    this.subsc();
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.filters.encodedFilter !== this.props.filters.encodedFilter) {
      this._escPolicyService
        .getAll(this.props.organization.selectedTeam.teamId, this.props.filters.encodedFilter)
        .then(data => this.setState({ escalationPolicies: data.data.data }))
        .catch();
    }
    if (prevProps.organization.escalations.e !== this.props.organization.escalations.e) {
      this.setState({ escalationPolicies: this.props.organization.escalations.e });
    }

    if (!this.isEpFocused) {
      this.focusTargetEpId();
    }
  }

  public closeModal = () => {
    this.setState({ deleteEscalationPolicy: '' });
  };

  public listMembers = (rule: IEscalationPolicyRules): JSX.Element[] => {
    const { theme } = Theme;
    if (!rule.entities) {
      return [];
    }

    const entitiesList = rule.entities.map((entity, entityIndex) => {
      let icon = <ProfileIcon height={12} width={12} stroke={theme.shades.cement} />;
      let name = 'Deleted User';
      let link;

      const schedulesList = this.props.schedulesList?.schedules;

      switch (entity.type) {
        case 'user':
          const user = this.props.organization.users.u.find(u => u.id === entity.id);
          if (user) {
            name = user.first_name + ' ' + user.last_name;
            link = 'user/' + user.id;
          }
          break;
        case 'squad':
          name =
            this.props.organization.squads.s.find(s => s.id === entity.id)?.name ?? 'Deleted Squad';
          icon = <SquadIcon height={12} width={12} stroke={theme.shades.cement} />;
          link = generatePath(TEAM_SQUADS_PATH, {
            id: this.props.organization.selectedTeam.teamId,

            sqid:
              (this.props.organization.squads.s.find(s => s.id === entity.id)?.id as string) ??
              entity.id,
          });
          break;
        case 'schedule':
          link = '/schedules#' + entity.id;
          name =
            this.props.organization.calendar.c.find(c => c.id === entity.id)?.name ??
            'Deleted Schedule';
          icon = <ScheduleIcon height={12} width={12} fill={theme.shades.cement} />;
          break;
        case 'schedulev2':
          link = 'schedules-v2/' + entity.pid;
          name = schedulesList?.find(sch => sch.ID === entity.pid)?.name ?? 'Deleted Schedule';
          icon = <ScheduleIcon height={12} width={12} fill={theme.shades.cement} />;
          break;
      }

      const pillComponent = (
        <Pill
          style={{
            backgroundColor: rule.roundrobin_enabled ? theme.shades.white : theme.shades.whiteSmoke,
            cursor: 'pointer',
            margin: 5,
          }}
        >
          <Grid alignItems="center" style={{ height: 20 }}>
            {rule.roundrobin_next_index === entityIndex && rule.roundrobin_enabled && (
              <ArrowLeftIcon
                style={{ transform: 'scaleX(-1)', marginRight: 10 }}
                height={20}
                width={20}
                color={theme.success.default}
              />
            )}

            {icon}
            <Para className="ml-5 ep-rule-participant-name" fontSize={12}>
              {name}
            </Para>
          </Grid>
        </Pill>
      );

      const entityPill =
        entity.type === 'user' ? (
          this.props.getEntityComponent({
            componentType: 'linkWPopover',
            id: entity.id,
            type: entity.type as EntityType,
            popoverTrigger: pillComponent,
            popoverPlacement: 'bottom',
          })
        ) : (
          <Link to={link} key={shortid.generate()}>
            {pillComponent}
          </Link>
        );

      return entityPill;
    });

    return entitiesList;
  };

  setUnsavedChanges = (showUnsavedChangesModal: boolean) =>
    this.setState({ showUnsavedChangesModal });
  closeUnsavedChangesModal = () => this.setState({ showUnsavedChangesModal: false });
  onDiscardChanges = () =>
    this.setState({
      showUnsavedChangesModal: false,
      showEscalationPolicyModal: false,
      isDirty: false,
    });

  checkAndSetDirty = () => {
    if (!this.state.isDirty) this.setState({ isDirty: true });
  };

  checkDirtyAndCloseModal = () => {
    if (this.state.isDirty) {
      this.setUnsavedChanges(true);
    } else {
      this.setState({
        isDirty: false,
        showEscalationPolicyModal: false,
      });
    }
  };

  hideEscalationModal = () => this.setState({ showEscalationPolicyModal: false, isDirty: false });

  public filterEscalationPolicies = (escalationPolicies: IEscalationPolicy[]) => {
    return escalationPolicies.filter((ep: IEscalationPolicy) => {
      return (
        ep.name.toLowerCase().includes(this.state.searchTerm.toLowerCase().trim()) ||
        this.props.organization.services.s
          .filter((service: IService) => service.escalation_policy_id === ep.id)
          .some((service: IService) =>
            service.name.toLowerCase().includes(this.state.searchTerm.toLowerCase().trim()),
          )
      );
    });
  };

  public render() {
    const escalationPolicies = this.filterEscalationPolicies(this.state.escalationPolicies);
    const { theme } = Theme;
    const schedulesList = this.props.schedulesList?.schedules;
    const targetEscalationPolicyId = window.location.hash.split('?')[0];

    const hasCreateAccess = this.props.userAccess.hasCreateAccess('escalation_policies');

    const { acl } = this.state;

    return (
      <ChakraProvider theme={chakraTheme}>
        <div
          className="main-container"
          onClick={() => {
            this.setState({
              showEditConfig: '',
              showRemainingServices: '',
              showRemainingMembers: null,
            });
          }}
        >
          <div className="clearfix">
            <div className="float-left">
              <h1 className="item-box-main-heading" style={{ display: 'inline-block' }}>
                Escalation Policies
                {escalationPolicies.length > 0 ? `   (${escalationPolicies.length})` : ''}
              </h1>
              <EPFilterTags
                activeFilterTags={this.props.filters.activeFilterTags}
                onClickActiveFilterTag={this.props.filters.onClickActiveFilterTag}
              />
            </div>
            <div className="float-right">
              <div
                style={{
                  display: 'inline-block',
                  marginBottom: '20px',
                  marginRight: '70px',
                }}
              >
                <EPFilter
                  setOpenDrawer={this.props.filters.setOpenDrawer}
                  openDrawer={this.props.filters.openDrawer}
                  onCancel={this.props.filters.onCancel}
                  ownerFilterSelected={this.props.filters.ownerFilterSelected}
                  onClickApplyFilters={this.props.filters.onClickApplyFilters}
                  onClearFilters={this.props.filters.onClearFilters}
                  dropdownOptions={this.props.filters.dropdownOptions}
                  onChangeHandler={this.props.filters.onChangeHandler}
                  filterTags={this.props.filters.filterTags}
                  handleSelectedFilterTagClick={this.props.filters.handleSelectedFilterTagClick}
                  filtersApplied={this.props.filters.filtersApplied}
                />
              </div>
              <div
                style={{ display: 'inline-block', verticalAlign: 'middle', position: 'relative' }}
              >
                <img src="/icons/greys/search copy.svg" className="search-bar-icon" alt="search" />
                <input
                  type="text"
                  autoComplete="off"
                  className="search-bar input-design"
                  placeholder="Search escalation policy by name or service"
                  value={this.state.searchTerm}
                  onChange={event => {
                    this.setState({ searchTerm: event.target.value });
                  }}
                />
              </div>

              <NoPermissionTooltip isDisabled={hasCreateAccess}>
                <button
                  className={`main-button no-select ml-10 ${!hasCreateAccess ? 'disabled' : ''}`}
                  onClick={() => {
                    this.setState({ showEscalationPolicyModal: true, showModalType: 'add' });
                  }}
                  disabled={!hasCreateAccess}
                >
                  Add Escalation Policy
                </button>
              </NoPermissionTooltip>
            </div>
          </div>
          <Para
            fontSize={14}
            style={{
              display: 'inline-block',
              fontWeight: 30,
              fontSize: '12px',
              color: '#808080',
            }}
          >
            Escalation policies allow you to connect services to on-call schedules, and ensure that
            the right people are notified at the right time. Learn more about escalation policies{' '}
            <a
              href="https://support.squadcast.com/escalation-policies/create-and-manage-escalation-policies"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </Para>
          <div
            className="items-list-container escalation-policy-data data-display-container mt-20"
            style={{ height: 'auto' }}
          >
            {!escalationPolicies.length ? (
              <div className="empty-state-container">
                <EscalationIcon
                  className="empty-state-img"
                  height="64px"
                  width="64px"
                  fill={theme.primary.default}
                />
                <p className="empty-state-headline">No policies found</p>
              </div>
            ) : null}
            {escalationPolicies
              .sort((a, b) => b.id.localeCompare(a.id))
              .map((escalationPolicy, index) => {
                const styleObject = {
                  padding: 0,
                };
                const styleObjectOnTarget =
                  targetEscalationPolicyId === '#' + escalationPolicy.id
                    ? {
                        border: `1px solid ${theme.primary.default}`,
                      }
                    : {};

                const hasUpdateAccess = this.props.userAccess.hasUpdateAccess(
                  'escalation_policies',
                  escalationPolicy.id,
                );
                const hasDeleteAccess = this.props.userAccess.hasDeleteAccess(
                  'escalation_policies',
                  escalationPolicy.id,
                );
                return (
                  <div
                    ref={ref => (this._refs[escalationPolicy.id] = ref as HTMLDivElement)}
                    key={escalationPolicy.id}
                    className="item-box"
                    style={{ ...styleObject, ...styleObjectOnTarget }}
                  >
                    <div className="bottom-line" style={{ padding: 16, margin: 0 }}>
                      <div className="clearfix">
                        <div className="float-left clearfix" style={{ width: '80%' }}>
                          <Grid alignItems="center">
                            <div
                              className="float-left"
                              style={{ maxWidth: '40%', marginRight: 10 }}
                            >
                              <h1 className="item-box-secondary-heading">
                                {escalationPolicy.name}
                              </h1>
                            </div>
                            <Grid alignItems="center" style={{ maxWidth: '60%' }}>
                              {window._.filter(
                                this.props.organization.services.s,
                                (service: IService) =>
                                  service.escalation_policy_id === escalationPolicy.id,
                              )
                                .slice(0, 1)
                                .map((service: IService, index: number) => {
                                  return (
                                    <Link
                                      to={generatePath(SERVICE_DETAILS_PATH, { id: service.id })}
                                      className="float-left mr-10"
                                      key={index}
                                    >
                                      <Pill>
                                        <Grid alignItems="center">
                                          <ServiceIcon
                                            height={14}
                                            width={14}
                                            fill={theme.shades.cement}
                                          />
                                          <Para className="ml-5 ep-service-name">
                                            {service.name}
                                          </Para>
                                        </Grid>
                                      </Pill>
                                    </Link>
                                  );
                                })}
                              {window._.filter(
                                this.props.organization.services.s,
                                (service: IService) =>
                                  service.escalation_policy_id === escalationPolicy.id,
                              ).length > 1 ? (
                                <Pill
                                  onClick={event => {
                                    event.stopPropagation();
                                    this.setState({ showRemainingServices: escalationPolicy.id });
                                  }}
                                  style={{ margin: 5, position: 'relative', cursor: 'pointer' }}
                                >
                                  <Para>
                                    +{' '}
                                    {window._.filter(
                                      this.props.organization.services.s,
                                      (service: IService) => {
                                        if (service.escalation_policy_id === escalationPolicy.id) {
                                          return true;
                                        }
                                        return false;
                                      },
                                    ).length - 1}
                                  </Para>
                                  {this.state.showRemainingServices === escalationPolicy.id ? (
                                    <div
                                      className="remaining-services-container"
                                      onClick={event => {
                                        event.stopPropagation();
                                      }}
                                    >
                                      <div
                                        style={{ maxHeight: 200, overflowY: 'auto', padding: 15 }}
                                      >
                                        {window._.filter(
                                          this.props.organization.services.s,
                                          (service: IService) => {
                                            if (
                                              service.escalation_policy_id === escalationPolicy.id
                                            ) {
                                              return true;
                                            }
                                            return false;
                                          },
                                        )
                                          .slice(
                                            1,
                                            window._.filter(
                                              this.props.organization.services.s,
                                              (service: IService) => {
                                                if (
                                                  service.escalation_policy_id ===
                                                  escalationPolicy.id
                                                ) {
                                                  return true;
                                                }
                                                return false;
                                              },
                                            ).length,
                                          )
                                          .map((service: IService, index: number) => {
                                            return (
                                              <Link
                                                to={generatePath(SERVICE_DETAILS_PATH, {
                                                  id: service.id,
                                                })}
                                                key={index}
                                              >
                                                <Pill className="mb-10">
                                                  <Grid alignItems="center">
                                                    <ServiceIcon
                                                      height={14}
                                                      width={14}
                                                      fill={theme.shades.cement}
                                                    />
                                                    <Para className="ml-5 ep-service-name">
                                                      {service.name}
                                                    </Para>
                                                  </Grid>
                                                </Pill>
                                              </Link>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  ) : null}
                                </Pill>
                              ) : null}
                              {this.props.organization?.selectedTeam.team && (
                                <EntityOwnerPill
                                  entityOwner={escalationPolicy.entity_owner}
                                  currentTeam={this.props.organization?.selectedTeam?.team}
                                  squads={this.props.organization.squads.s}
                                  users={this.props.organization.users.u}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </div>
                        <div className="float-right" style={{ position: 'relative' }}>
                          <img
                            src="/icons/greys/more.svg"
                            className="cursor-pointer"
                            onClick={event => {
                              this.setState({ showEditConfig: escalationPolicy.id });
                              event.stopPropagation();
                            }}
                            alt="more"
                          />
                          {this.state.showEditConfig === escalationPolicy.id ? (
                            <div className="config-modal animated fadeIn ">
                              <NoPermissionTooltip
                                isDisabled={hasUpdateAccess}
                                shouldWrapChildren={false}
                              >
                                <div
                                  className={`no-select cursor-pointer ${
                                    hasUpdateAccess ? '' : 'disabled'
                                  }`}
                                  onClick={() =>
                                    hasUpdateAccess && this.editEscalationPolicy(escalationPolicy)
                                  }
                                >
                                  <div className="float-left">
                                    <p className="item-box-tagline" style={{ margin: 0 }}>
                                      Edit
                                    </p>
                                  </div>
                                  <div className="float-right">
                                    <img src="/icons/greys/drop-right.svg" alt="right" />
                                  </div>
                                </div>
                              </NoPermissionTooltip>
                              <NoPermissionTooltip
                                isDisabled={hasDeleteAccess}
                                shouldWrapChildren={false}
                              >
                                <div
                                  className={`cursor-pointer no-select ${
                                    hasDeleteAccess ? '' : 'disabled'
                                  }`}
                                  onClick={() => {
                                    hasDeleteAccess &&
                                      this.setState({
                                        deleteEscalationPolicy: escalationPolicy.id,
                                      });
                                  }}
                                >
                                  <p className="item-box-tagline" style={{ margin: 0 }}>
                                    Delete
                                  </p>
                                </div>
                              </NoPermissionTooltip>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <p
                          style={{ margin: 0, marginTop: 5, marginBottom: 10 }}
                          className="item-box-description"
                        >
                          {escalationPolicy.description}
                        </p>
                      </div>
                    </div>
                    <div style={{ padding: '0px 0px' }}>
                      {escalationPolicy.rules.map((rule, index: number) => {
                        return (
                          <Grid
                            key={index}
                            style={{
                              justifyContent: 'space-between',
                              padding: 0,
                            }}
                          >
                            <div
                              style={{
                                width: '15%',
                                marginLeft: 16,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Para fontSize={14}>
                                Escalate after {rule.escalationTime} minute
                                {rule.escalationTime > 1 ? 's' : ''}
                              </Para>
                              {escalationPolicy.rules.length - 1 !== index && (
                                <Grid style={{ position: 'relative', bottom: -40, left: -110 }}>
                                  <div className="show-escalation-after-image-local">
                                    <img src="/icons/arrow_down.svg" width="12" alt="down" />
                                  </div>
                                </Grid>
                              )}
                            </div>
                            <Grid
                              style={{
                                width: '80%',
                                paddingLeft: 4,
                                alignItems: 'center',
                                borderLeft: `solid 1px #e7ecf5`,
                                backgroundColor: rule.roundrobin_enabled
                                  ? theme.shades.whiteSmoke
                                  : theme.shades.white,
                                minHeight: 80,
                              }}
                            >
                              <Grid
                                style={{
                                  flexWrap: 'wrap',
                                  width: '68%',
                                }}
                              >
                                {rule.roundrobin_enabled && (
                                  <Grid height={'auto'}>
                                    <Grid
                                      width={'160px'}
                                      alignItems={'center'}
                                      style={{ height: 20, marginTop: 8 }}
                                    >
                                      <RoundRobinIcon width={20} height={20} />
                                      <Label
                                        style={{ marginLeft: 10, height: 'max-content' }}
                                        fontSize={12}
                                      >
                                        Round robin enabled
                                      </Label>
                                    </Grid>
                                  </Grid>
                                )}
                                {this.listMembers(rule).slice(0, 6)}
                                {this.listMembers(rule).length <= 6 ? null : (
                                  <Pill
                                    onClick={event => {
                                      event.stopPropagation();
                                      this.setState({ showRemainingMembers: rule });
                                    }}
                                    style={{
                                      margin: 5,
                                      marginRight: 10,
                                      cursor: 'pointer',
                                      height: 20,
                                      display: 'flex',
                                      alignItems: 'center',
                                      backgroundColor: rule.roundrobin_enabled
                                        ? theme.shades.white
                                        : theme.shades.whiteSmoke,
                                    }}
                                  >
                                    <Para fontSize={12}>+ {this.listMembers(rule).length - 6}</Para>
                                    {this.state.showRemainingMembers === rule ? (
                                      <div
                                        onClick={event => {
                                          event.stopPropagation();
                                        }}
                                        style={{
                                          zIndex: 9999,
                                          backgroundColor: theme.shades.white,
                                          boxShadow: `0 0 30px 0 ${theme.shades.lightGrey}`,
                                          position: 'relative',
                                          left: 20,
                                          top: 100,
                                        }}
                                      >
                                        <div
                                          style={{ maxHeight: 200, overflowY: 'auto', padding: 15 }}
                                        >
                                          {this.listMembers(this.state.showRemainingMembers).slice(
                                            6,
                                          )}
                                        </div>
                                      </div>
                                    ) : null}
                                  </Pill>
                                )}
                              </Grid>
                              <Grid
                                style={{
                                  width: 0,
                                  minHeight: 80,
                                  borderRight: `solid 1px #e7ecf5`,
                                }}
                              />
                              <Grid
                                style={{
                                  flexWrap: 'wrap',
                                  width: '32%',
                                  marginLeft: 10,
                                }}
                              >
                                {(rule.via || []).map((medium, index: number) => {
                                  return (
                                    <Grid
                                      className="item-member"
                                      key={index}
                                      style={{
                                        margin: 0,
                                        marginRight: 10,
                                        alignItems: 'center',
                                        height: 30,
                                        backgroundColor: rule.roundrobin_enabled
                                          ? theme.shades.white
                                          : theme.shades.whiteSmoke,
                                      }}
                                    >
                                      <img
                                        src={`/icons/greys/${
                                          medium.toLowerCase() === 'push'
                                            ? 'notification'
                                            : medium.toLowerCase()
                                        }.svg`}
                                        className="item-member-icon"
                                        alt={medium}
                                      />
                                      <Para fontSize={12}>{medium}</Para>
                                    </Grid>
                                  );
                                })}
                                {!(rule.via || []).length && (
                                  <div
                                    className="item-member"
                                    style={{
                                      margin: 0,
                                      marginRight: 10,
                                      backgroundColor: rule.roundrobin_enabled
                                        ? theme.shades.white
                                        : theme.shades.whiteSmoke,
                                    }}
                                  >
                                    <Para fontSize={12}>Personal Notification Rules</Para>
                                  </div>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        width: '100%',
                        backgroundColor: '#f3f4f6',
                        borderTop: `solid 1px #e7ecf5`,
                      }}
                    >
                      <Para fontSize={14} style={{ padding: 16 }}>
                        If no one acknowledges, repeat this policy an additional{' '}
                        <span className="font-bold">{escalationPolicy.repetition}</span> time(s)
                        {escalationPolicy.repeat_after > 0 && (
                          <>
                            {' '}
                            after <span className="font-bold">
                              {escalationPolicy.repeat_after}
                            </span>{' '}
                            min(s)
                          </>
                        )}
                      </Para>
                    </div>
                  </div>
                );
              })}
          </div>
          <DialogModalFrame id="ep_modal" width="1024px" onClose={this.checkDirtyAndCloseModal}>
            {this.state.showEscalationPolicyModal && (
              <EscalationModal
                showModalType={this.state.showModalType}
                escalationPolicy={deepCopy(this.state.escalationPolicyEdit)}
                hide={this.hideEscalationModal}
                checkAndSetDirty={this.checkAndSetDirty}
                schedulesList={schedulesList}
              />
            )}
          </DialogModalFrame>
          {/* frame should have width > 400, as confirm modal is created with 400px width */}
          <DialogModalFrame id="ep_delete_confirm" width="500px" onClose={this.closeModal}>
            {this.state.deleteEscalationPolicy ? (
              <ConfirmModal
                hide={(result: boolean) => {
                  if (result) {
                    this.deleteEscalationPolicy();
                  }
                  this.setState({
                    deleteEscalationPolicy: '',
                  });
                }}
                buttonText={'Delete'}
                displayText={`Are you sure you want to delete this escalation policy: "${
                  this.props.organization.escalations.e.find(
                    e => e.id === this.state.deleteEscalationPolicy,
                  )?.name || 'Deleted escalation policy'
                }"?`}
              />
            ) : null}
          </DialogModalFrame>

          <UnsavedChangesGuard
            isManual={true}
            showModal={this.state.showUnsavedChangesModal}
            onModalClose={this.closeUnsavedChangesModal}
            onDiscardChanges={this.onDiscardChanges}
          />
        </div>
      </ChakraProvider>
    );
  }
}
const EscalationPolicyComponent = connect(
  (state: IAppState) => ({
    organization: state.organization,
    userInfo: state.userInfo,
  }),
  {
    requestOrganizationEscalation,
  },
)(EscalationPolicies);

const EscalationPolicyHOC = (props: IProps) => {
  const _createSnack = SnackContext();
  const [showSnack, setShowSnack] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [background, setBackground] = React.useState<string>('');
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const query = useQueryParams();
  const filterQueryParam = query.get('entity_owner') ?? '';

  const history = useHistory();

  let basePath = ESCALATION_POLICIES_HOME_PATH;

  if (window.location.hash) {
    const epId = (window.location.hash ?? '').split('?')[0];
    basePath = `${basePath}${epId}`;
  }

  const {
    activeFilterObject,
    selectedFilterObject,
    activeFilterTags,
    dropdownOptions,
    onChangeHandler,
    selectedFilterTags,
    handleActiveFilterTagClick,
    handleSelectedFilterTagClick,
    clearSelectedFilterValues,
    ownerFilterApplied,
    ownerFilterSelected,
    resetOwnerFilters,
    syncSelectedFilterWithActiveFilter,
  } = useEntityOwnerFilter({
    queryParamsString: filterQueryParam,
    entity: FilterEntity.ESCALATION_POLICY,
    selectedTeamId: '',
  });

  function onCancel() {
    if (!ownerFilterApplied) {
      clearSelectedFilterValues();
    } else {
      syncSelectedFilterWithActiveFilter();
    }
    setOpenDrawer(false);
  }

  function applyEntityOwnerFilters(filterObject: FilterObject) {
    const filterUrlParams = encodeFilterParam(filterObject);
    query.delete('entity_owner');
    filterUrlParams && query.append('entity_owner', filterUrlParams);
    history.push(`${basePath}?${query}`);
    syncSelectedFilterWithActiveFilter();
    setOpenDrawer(false);
  }

  function onClickApplyFilters() {
    applyEntityOwnerFilters(selectedFilterObject);
  }

  React.useEffect(() => {
    applyEntityOwnerFilters(activeFilterObject);
  }, [activeFilterObject]);

  const onClearFilters = () => {
    resetOwnerFilters();
    query.delete('entity_owner');
    const path = query.keys.length ? `${basePath}?${query}` : basePath;
    history.push(path);
    onCancel();
  };

  const onClickActiveFilterTag = (type: Owner, id: string) => {
    handleActiveFilterTagClick(type, id);
  };

  const { getEntityComponent } = useUINavigationFunctions();

  React.useEffect(() => {
    if (!showSnack) {
      _createSnack(null);
    } else {
      _createSnack(
        <SnackBar background={background} maxWidth="500px">
          <Grid alignItems="center" justifyContent="space-between">
            <Para color={theme.shades.white} fontWeight={400}>
              {message}
            </Para>
            <IconButton onClick={hideSnackBar} className="ml-10">
              <CloseIcon stroke={theme.shades.white} />
            </IconButton>
          </Grid>
        </SnackBar>,
      );
    }
    return () => _createSnack(null);
  });

  const hideSnackBar = () => {
    setShowSnack(false);
    setMessage('');
  };

  const showSnackBar = (msg: string, bg: string, timeout: number) => {
    setMessage(msg);
    setBackground(bg);
    setShowSnack(true);
    setTimeout(hideSnackBar, timeout);
  };

  return (
    <EscalationPolicyComponent
      {...{
        ...props,
        showSnackBar,
        getEntityComponent,
        filters: {
          encodedFilter: encodeFilterParam(activeFilterObject),
          activeFilterTags: activeFilterTags,
          dropdownOptions: dropdownOptions,
          filterTags: selectedFilterTags,
          filtersApplied: ownerFilterApplied,
          handleSelectedFilterTagClick: handleSelectedFilterTagClick,
          onCancel: onCancel,
          onChangeHandler: onChangeHandler,
          onClearFilters: onClearFilters,
          onClickActiveFilterTag: handleActiveFilterTagClick,
          onClickApplyFilters: onClickApplyFilters,
          openDrawer: openDrawer,
          ownerFilterSelected: ownerFilterSelected,
          setOpenDrawer: setOpenDrawer,
        },
      }}
    />
  );
};

export default withListOfSchedules(withUserAccess(EscalationPolicyHOC));
