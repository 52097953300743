import schema from '../schema';
import { PanelObject } from '../types';
import { dateRangeGranularity, dateRangeGranularityForCalendar } from '../utils/date.utils';
import { formatDateValue, formatDurationValue } from '../utils/graphs.utils';
import { timeDimensions } from '../utils/query.utils';
import { durationUnits, textUnits } from '../utils/stats.utils';

export const panels: PanelObject[] = [
  {
    id: 'incidents',
    title: 'Incidents',
    gridPos: { x: 0, y: 0, w: 4, h: 1 },
    query: filters => ({
      measures: [schema.IncidentsCount],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date, { compare: true }),
    }),
    chart: { type: 'stats', unitsFn: textUnits('incident') },
  },
  {
    id: 'mtta',
    title: 'MTTA',
    // Template literal for multiline tooltip
    helpText: `It shows how quickly your team is able to respond to an incident.
    This is calculated by their time to Acknowledge an incident.`,
    gridPos: { x: 4, y: 0, w: 4, h: 1 },
    query: filters => ({
      measures: [schema.IncidentsMTTA],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date, { compare: true }),
    }),
    chart: { type: 'stats', unitsFn: durationUnits(), valueFn: Math.trunc },
  },
  {
    id: 'mttr',
    title: 'MTTR',
    // Template literal for multiline tooltip
    helpText: `It shows how quickly your team is able to resolve incidents as they arise.
    This is calculated by their time to Resolve an incident.`,
    gridPos: { x: 8, y: 0, w: 4, h: 1 },
    query: filters => ({
      measures: [schema.IncidentsMTTR],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date, { compare: true }),
    }),
    chart: { type: 'stats', unitsFn: durationUnits(), valueFn: Math.trunc },
  },
  {
    id: 'mtta_mttr_over_time',
    title: 'MTTA & MTTR Over Time',
    gridPos: { x: 0, y: 1, w: 12, h: 2.25 },
    query: filters => ({
      measures: [schema.IncidentsMTTA, schema.IncidentsMTTR],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date, {
        granularity: dateRangeGranularity(filters.date),
      }),
    }),
    chart: {
      type: 'lines',
      yFormat: value => formatDurationValue(value as number),
      xFormat: date => formatDateValue(date as Date),
      seriesNames: {
        [schema.IncidentsMTTA]: 'MTTA',
        [schema.IncidentsMTTR]: 'MTTR',
      },
    },
  },
  {
    id: 'incident_count_by_date',
    title: 'Incident Count by Date',
    gridPos: { x: 0, y: 1, w: 8, h: 2.25 },
    query: filters => ({
      measures: [schema.IncidentsCount],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date, {
        granularity: 'day',
      }),
    }),
    chart: {
      type: 'calendar',
      measures: [schema.IncidentsCount],
      dimension: schema.ServicesName, // TODO: unused for now, fix later to generate better bar names/tooltips
    },
  },
  {
    id: 'incident_status_by_date',
    title: 'Incident Status by Date',
    gridPos: { x: 8, y: 1, w: 4, h: 2.25 },
    query: filters => ({
      measures: [schema.IncidentsCount],
      dimensions: [schema.IncidentsStatus],
      timeDimensions: timeDimensions(
        schema.IncidentsCreatedAt,
        {
          preset: 'custom',
          // Depends upon the `incident_count_by_date` for date
          startDate: filters.customDate ?? filters.date.endDate,
          endDate: filters.customDate ?? filters.date.endDate,
        },
        {
          granularity: 'day',
        },
      ),
    }),
    chart: {
      type: 'pie',
      measures: [schema.IncidentsCount],
      dimension: schema.ServicesName, // TODO: unused for now, fix later to generate better bar names/tooltips
    },
  },
  {
    id: 'open_incidents_by_team',
    title: 'Open Incidents by Team',
    gridPos: { x: 0, y: 3, w: 6, h: 2.5 },
    query: filters => ({
      measures: [schema.IncidentsCount],
      order: { [schema.IncidentsCount]: 'desc' },
      dimensions: [schema.TeamsName],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date),
      filters: [
        {
          member: schema.IncidentsStatus,
          operator: 'equals',
          values: ['acknowledged', 'triggered'],
        },
      ],
      limit: 10,
    }),
    chart: {
      type: 'bars',
      measures: [schema.IncidentsCount],
      dimension: schema.TeamsName,
    },
  },
  {
    id: 'deduped_incidents_by_team',
    title: 'Deduplicated Incidents by Team',
    gridPos: { x: 6, y: 3, w: 6, h: 2.5 },
    query: filters => ({
      measures: [schema.IncidentsDuplicatesCount],
      order: { [schema.IncidentsDuplicatesCount]: 'desc' },
      dimensions: [schema.TeamsName],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date),
      limit: 10,
    }),
    chart: {
      type: 'bars',
      measures: [schema.IncidentsDuplicatesCount],
      dimension: schema.TeamsName,
    },
  },
  {
    id: 'suppressed_incidents_by_team',
    title: 'Suppressed Incidents by Team',
    gridPos: { x: 0, y: 5.5, w: 6, h: 2.5 },
    query: filters => ({
      measures: [schema.IncidentsCount],
      order: { [schema.IncidentsCount]: 'desc' },
      dimensions: [schema.TeamsName],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date),
      filters: [
        { member: schema.IncidentsStatus, operator: 'equals', values: ['suppressed'] },
        { member: schema.IncidentsIsChild, operator: 'equals', values: ['false'] },
      ],
      limit: 10,
    }),
    chart: {
      type: 'bars',
      measures: [schema.IncidentsCount],
      dimension: schema.TeamsName,
    },
  },
];
