import { Circle, Flex, HStack, Link, Text } from '@chakra-ui/react';
import { Divider } from 'library/atoms';
import { THEME_COLORS } from 'library/theme/colors';
import { FC, ReactNode } from 'react';

import { getHealthStatus, getHealthStatusColor } from '../../helpers/helper.graph';
import { SERVICE_HEALTH_STATUS } from '../../Interfaces/graph';

type DetailBoxProps = {
  children: ReactNode;
};

type HeadingProps = {
  title: string;
};

type LinkProps = {
  title?: string;
  path?: string;
  isActiveLink?: boolean;
};

type FilterProps = {
  status: SERVICE_HEALTH_STATUS;
  count?: number;
  isActive: boolean;
  onClick: () => void;
};

export const DetailBox: FC<DetailBoxProps> = ({ children }) => {
  return (
    <>
      <Flex flexDir="column" py={3} pl={4} rowGap={2}>
        {children}
      </Flex>
      <Divider />
    </>
  );
};

export const Heading: FC<HeadingProps> = ({ title }) => {
  return (
    <Text color="secondary.700" variant="formInput_800" textTransform="capitalize">
      {title}
    </Text>
  );
};

export const LinkTitle: FC<LinkProps> = ({ title, path, isActiveLink }) => {
  const color = isActiveLink ? 'brand.blue' : 'secondary.700';
  const variant = isActiveLink ? 'h4_800' : 'formInput';
  const nameComp = (
    <Text color={color} variant={variant} cursor="pointer">
      {title ?? 'N/A'}
    </Text>
  );

  return path ? (
    <Link href={path} isExternal textDecor="none">
      {nameComp}
    </Link>
  ) : (
    nameComp
  );
};

export const FilterButton: FC<FilterProps> = ({ status, count, isActive, onClick }) => {
  const borderColor = isActive ? THEME_COLORS.brand.blue : THEME_COLORS.secondary[200];
  const bgColor = isActive ? THEME_COLORS.primary[300] : THEME_COLORS.brand.white;
  const totalNodes = count ?? 0;

  const onBtnClick = () => {
    if (totalNodes) {
      onClick();
    }
  };

  return (
    <HStack
      px={3}
      py={2}
      borderRadius="3px"
      borderColor={borderColor}
      borderWidth="1.5px"
      columnGap={2}
      alignItems="center"
      bg={bgColor}
      cursor={totalNodes ? 'pointer' : 'not-allowed'}
      onClick={onBtnClick}
      opacity={totalNodes ? 1 : 0.7}
    >
      <Circle size="12px" bg={getHealthStatusColor(status)} />
      <Text color="secondary.1000" variant="body">
        {getHealthStatus(status)} ({totalNodes})
      </Text>
    </HStack>
  );
};
