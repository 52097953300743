import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 18.281v.938a.781.781 0 0 0-.781.781h-.938a.781.781 0 0 0-1.562 0h-.938a.781.781 0 0 0-1.562 0h-.938a.781.781 0 0 0-1.562 0h-.938a.781.781 0 0 0-1.562 0H8.28a.781.781 0 1 0-1.562 0H5.78a.781.781 0 1 0-1.562 0H3.28a.781.781 0 0 0-1.562 0H.78A.781.781 0 0 0 0 19.219v-.938a.781.781 0 0 0 0-1.562v-.938a.781.781 0 0 0 0-1.562v-.938a.781.781 0 0 0 0-1.562v-.938A.781.781 0 0 0 0 9.22V8.28A.781.781 0 0 0 0 6.72V5.78A.781.781 0 0 0 0 4.22V3.28A.781.781 0 0 0 0 1.72V.78C.431.781.781.431.781 0h.938A.781.781 0 0 0 3.28 0h.938A.781.781 0 0 0 5.78 0h.938A.781.781 0 0 0 8.28 0h.938a.781.781 0 0 0 1.562 0h.938a.781.781 0 0 0 1.562 0h.938a.781.781 0 0 0 1.562 0h.938a.781.781 0 0 0 1.562 0h.938c0 .431.35.781.781.781v.938a.781.781 0 1 0 0 1.562v.938a.781.781 0 1 0 0 1.562v.938a.781.781 0 1 0 0 1.562v.938a.781.781 0 0 0 0 1.562v.938a.781.781 0 0 0 0 1.562v.938a.781.781 0 0 0 0 1.562v.938a.781.781 0 0 0 0 1.562Z"
        fill="#FFDE00"
      />
      <path
        d="M5.802 14.253h.624c.32 0 .522-.206.522-.534v-7.3c0-.327-.202-.534-.522-.534h-.624V4.267h4.517c2.426 0 4.348 1.257 4.348 3.65 0 2.41-1.921 3.668-4.348 3.668h-1.55v2.134c0 .328.201.534.538.534h1.264v1.636H5.802v-1.636Zm4.382-4.356c1.6 0 2.528-.689 2.528-1.946 0-1.291-.927-1.945-2.528-1.945H8.768v3.908h1.416v-.017Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
