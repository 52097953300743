import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';

import { Locale } from 'core/helpers/dateUtils';
import { getIncidentDetailsPath } from '../../common/util';
import TagList from '../TagList';
import { IAutoGroupAlert } from 'core/interfaces/IIncidents';
import { ID_QUERY_PARAM_KEYS } from '../../../incidentDetails/enums';
import { AUTO_GROUP_TAB_NAME } from '../../../incidentDetails/constants';
import { getNameComponent, getTextComponent } from '../column-utils';
import { COLUMN_DEF_PADDING_LEFT_VALUE } from '../../common/constants';

export const AUTO_GROUP_ALERT_COLUMN_DEFS = (incidentId: string) => [
  {
    Header: 'ALERT TITLE',
    Cell: (cell: Cell<IAutoGroupAlert>) => {
      const { id, message, tags } = cell.row.original;
      const queryParams = {
        [ID_QUERY_PARAM_KEYS.TAB_NAME]: AUTO_GROUP_TAB_NAME,
        [ID_QUERY_PARAM_KEYS.ALERT_ID]: id,
      };

      return (
        <VStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} paddingY={4} alignItems="flex-start">
          <Link to={getIncidentDetailsPath(incidentId, queryParams)}>
            <Text variant="body" noOfLines={1}>
              {message}
            </Text>
          </Link>
          {!!tags.length && <TagList tags={tags} />}
        </VStack>
      );
    },
  },
  {
    Header: 'ALERT SOURCE',
    Cell: (cell: Cell<IAutoGroupAlert>) => {
      const alertSrc = cell.row.original.alert_source ?? {};
      return (
        <HStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} columnGap="8px">
          {alertSrc.icon.svg && <Image src={alertSrc.icon.svg} width="24px" />}
          {getNameComponent(alertSrc.name)}
        </HStack>
      );
    },
    minWidth: 150,
  },
  {
    Header: 'CREATED AT',
    Cell: (cell: Cell<IAutoGroupAlert>) => {
      const { created_at: timeOfCreation } = cell.row.original;
      return (
        <VStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} alignItems="flex-start">
          {getTextComponent(Locale.toShortDate(timeOfCreation))}
          {getTextComponent(Locale.to24HrTimeWithSec(timeOfCreation))}
        </VStack>
      );
    },
    minWidth: 100,
  },
];
