import {
  Tag as ChakraTag,
  HStack,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
  Tooltip,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';

export type KeyValueTag = {
  keyStr: string;
  value: string;
  color: string;
  username?: string | null;
};

export enum TAG_VARIANTS {
  KEY_VALUE = 'keyValue',
  PLAIN = 'plain',
}

type KeyValueProps = KeyValueTag & {
  variant: TAG_VARIANTS.KEY_VALUE;
};

export type PlainTag = {
  label: string;
  username?: string;
};

type PlainProps = PlainTag & {
  variant: TAG_VARIANTS.PLAIN;
};

export type TagProps = {
  size?: 'md' | 'lg';
  onClose?: () => void;
};

export type TagVariants = KeyValueProps | PlainProps;

type Props = TagProps & TagVariants;

const Tag = (props: Props) => {
  const { variant } = props;
  const styles = useMultiStyleConfig('TagThemeV2', { variant });
  if (props.variant === TAG_VARIANTS.PLAIN) {
    const { onClose, label, username, ...rest } = props;

    return (
      <ChakraTag {...rest} sx={styles.container} style={{ borderRadius: 3 }}>
        <TagLabel>
          <Tooltip
            label={
              <VStack alignItems={'left'} spacing={0}>
                <Text
                  variant="hint"
                  fontSize={12}
                  fontWeight={'bold'}
                  color={THEME_COLORS.secondary[1000]}
                >
                  {label}
                </Text>
                <Text variant="hint" fontSize={12} color={THEME_COLORS.secondary[700]}>
                  {username}
                </Text>
              </VStack>
            }
            placement="bottom"
            bg={THEME_COLORS.secondary[100]}
          >
            <HStack>
              <Text variant="hint" isTruncated maxWidth={'25ch'}>
                {label}
              </Text>
              {username && (
                <Text variant="hint" isTruncated maxWidth={'25ch'}>
                  ({username})
                </Text>
              )}
            </HStack>
          </Tooltip>
        </TagLabel>
        {onClose && <TagCloseButton onClick={onClose} />}
      </ChakraTag>
    );
  }

  const { keyStr, value, onClose, color, ...rest } = props;
  return (
    <ChakraTag
      {...rest}
      color={color || '#1B7FF1'}
      alignItems="flex-start"
      px={0}
      sx={styles.container}
    >
      <TagLabel whiteSpace="break-spaces" maxWidth="200px">
        <Text>{keyStr}</Text>
      </TagLabel>
      <TagLabel>
        <HStack>
          <Text>{value}</Text>
          {onClose && <TagCloseButton color="white" onClick={onClose} alignSelf="center" />}
        </HStack>
      </TagLabel>
    </ChakraTag>
  );
};

export default Tag;
