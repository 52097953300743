import { DelayNotificationConfig } from '../../../types/service-detail.types';

export type WorkingDaysOptionType = {
  value: number;
  label: string;
  descLabel: string;
};

export const WORKING_DAYS_OPTIONS: WorkingDaysOptionType[] = [
  {
    value: 0,
    label: 'S',
    descLabel: 'Sunday',
  },
  {
    value: 1,
    label: 'M',
    descLabel: 'Monday',
  },
  {
    value: 2,
    label: 'T',
    descLabel: 'Tuesday',
  },
  {
    value: 3,
    label: 'W',
    descLabel: 'Wednesday',
  },
  {
    value: 4,
    label: 'T',
    descLabel: 'Thursday',
  },
  {
    value: 5,
    label: 'F',
    descLabel: 'Friday',
  },
  {
    value: 6,
    label: 'S',
    descLabel: 'Saturday',
  },
];

export const DEFAULT_SELECTED_TIME = '00:00';
export const DEFAULT_DELAY_NOTIFICATION_CONFIG: DelayNotificationConfig = {
  custom_timeslots_enabled: true,
  is_enabled: false,
  timezone: '',
  assigned_to: {
    type: '',
  },
};
