import React, {
  ChangeEvent,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDisclosure, HStack, Heading, Divider, Text } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory, generatePath } from 'react-router-dom';

import {
  usePauseScheduleMutation,
  useResumeScheduleMutation,
  useCloneScheduleMutation,
} from 'views/main/organization/schedules/graphql/mutation';
import {
  reactQueryConfig,
  TScheduleAction,
  getAlertDialogParameters,
} from '../helpers/helpers.schedule';
import { useQueryClient } from 'react-query';
import { ScheduleActionTypes, schedulesTextCopy } from '../constants/schedules.copy';
import { CustomDrawerComponent } from 'components/chakra/Drawer';
import EditRotations from '../schedules.edit/schedules.editrotations';
import ExportSelection from './exportSelection';
import DeleteRotationSelection from './deleteRotationSelection';
import { CustomAlertDialog } from 'components/chakra/AlertDialog';
import { SCHEDULES_V2_DETAIL_PATH, SCHEDULES_V2_EDIT_PATH } from 'views/main/routes/routes';
import DeleteScheduleSelection from './deleteScheduleSelection';
import SQTooltip from 'components/chakra/Tooltip';
import { useUserAccess } from 'core/userAccess/UserAccessContext';

export type TScheduleActionsContextProps = {
  onActionClick: (scheduleAction: TScheduleAction) => void;
  openAlertDialog: () => void;
  openEditSchedule: () => void;
  closeEditSchedule: () => void;
  scheduleAction: TScheduleAction | null;
  onClickRemoveRotation: (rotationId: string | undefined) => void;
  onSelectDeleteType: (type: TDeleteRotationType | null) => void;
  deleteRotationType: null | TDeleteRotationType;
  confirmDeleteRotation: boolean;
  resetDeleteRotationValues: () => void;
  reFetchOncallUsersData: () => void;
  reFetchSchedulesData: () => void;
  rotationToEndOrRemove: string;
  participantsGroupToEdit: TParticipantGroupToEdit | null;
  onClickEditParticipantGroup: (rotationId: string, groupId: string) => void;
  resetParticipantsGroupToEdit: () => void;
  resetScheduleAction: () => void;
  userHasReadPermission: boolean;
  userHasCreatePermission: boolean;
  userHasUpdatePermission: boolean;
  userHasDeletePermission: boolean;
};

export type TDeleteRotationType = 'entire' | 'end';

export type TParticipantGroupToEdit = {
  rotationId: string;
  participantGroupId: string;
};

export interface IScheduleActionsProviderProps {
  children: ReactNode;
}

const ScheduleActionsContext = createContext<TScheduleActionsContextProps>({
  onActionClick: (scheduleAction: TScheduleAction) => {},
  openAlertDialog: () => {},
  openEditSchedule: () => {},
  closeEditSchedule: () => {},
  scheduleAction: null,
  onClickRemoveRotation: (rotationId: string | undefined) => {},
  onSelectDeleteType: (type: TDeleteRotationType | null) => {},
  deleteRotationType: null,
  confirmDeleteRotation: false,
  resetDeleteRotationValues: () => {},
  reFetchOncallUsersData: () => {},
  reFetchSchedulesData: () => {},
  rotationToEndOrRemove: '',
  participantsGroupToEdit: null,
  onClickEditParticipantGroup: (rotationId: string, groupId: string) => {},
  resetParticipantsGroupToEdit: () => {},
  resetScheduleAction: () => {},
  userHasReadPermission: false,
  userHasCreatePermission: false,
  userHasUpdatePermission: false,
  userHasDeletePermission: false,
});

const ScheduleActionsConsumer = ScheduleActionsContext.Consumer;

const ScheduleActionsProvider = ({ children }: IScheduleActionsProviderProps) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [scheduleAction, setScheduleAction] = useState<TScheduleAction | null>(null);
  const [isRemoveRotationClicked, setIsRemoveRotationClicked] = useState<boolean>(false);
  const [deleteRotationType, setdeleteRotationType] = useState<null | TDeleteRotationType>(null);
  const [rotationToEndOrRemove, setRotationToEndOrRemove] = useState<string>('');
  const [confirmDeleteRotation, setConfirmDeleteRotation] = useState<boolean>(false);
  const [participantsGroupToEdit, setParticipantGroupsToEdit] =
    useState<TParticipantGroupToEdit | null>(null);
  const {
    isOpen: isAlertDialogOpen,
    onOpen: onOpenAlertDialog,
    onClose: onCloseAlertDialog,
  } = useDisclosure();
  const {
    isOpen: isEditScheduleOpen,
    onOpen: onOpenEditSchedule,
    onClose: onCloseEditSchedule,
  } = useDisclosure();

  const reFetchSchedulesData = useRef(() => {
    queryClient.invalidateQueries(['getSchedules']);
    queryClient.invalidateQueries(['getSchedule']);
  }).current;

  const reFetchOncallUsersData = useRef(() => {
    queryClient.invalidateQueries('whoIsOncall');
  }).current;

  const _uA = useUserAccess();
  const hasRead = _uA.hasReadAccess('schedules');
  const hasCreate = _uA.hasCreateAccess('schedules');
  const hasUpdate = _uA.hasUpdateAccess('schedules');
  const hasDelete = _uA.hasDeleteAccess('schedules');

  const userHasReadPermission = hasRead;

  const userHasCreatePermission = hasCreate;

  const userHasUpdatePermission = hasUpdate;

  const userHasDeletePermission = hasDelete;
  useEffect(() => {
    if (scheduleAction && scheduleAction.type === ScheduleActionTypes.EDIT_SCHEDULE) {
      const editScheduleLink = generatePath(SCHEDULES_V2_EDIT_PATH, {
        scheduleId: scheduleAction.params.scheduleId,
      });
      history.push(editScheduleLink);
    }
  }, [scheduleAction]);

  useEffect(() => {
    if (!isRemoveRotationClicked) {
      onCloseAlertDialog();
    }
  }, [isRemoveRotationClicked]);

  const onActionClick = useCallback(
    (scheduleAction: TScheduleAction) => setScheduleAction(scheduleAction),
    [],
  );

  const openAlertDialog = useCallback(() => onOpenAlertDialog(), []);

  const closeAlertDialog = useCallback(() => onCloseAlertDialog(), []);

  const openEditSchedule = useCallback(() => onOpenEditSchedule(), []);

  const closeEditSchedule = useCallback(() => onCloseEditSchedule(), []);

  const onClickRemoveRotation = useCallback((rotationId: string | undefined) => {
    setRotationToEndOrRemove(rotationId ?? '');
    setIsRemoveRotationClicked(true);
    openAlertDialog();
  }, []);

  const onSelectDeleteType = useCallback((type: TDeleteRotationType | null) => {
    setdeleteRotationType(type);
  }, []);

  const onClickEditParticipantGroup = useCallback((rotationId: string, groupId: string) => {
    setParticipantGroupsToEdit({ rotationId: rotationId, participantGroupId: groupId });
  }, []);

  const resetDeleteRotationValues = useCallback(() => {
    setConfirmDeleteRotation(false);
    setIsRemoveRotationClicked(false);
    setdeleteRotationType(null);
    setRotationToEndOrRemove('');
  }, []);

  const resetParticipantsGroupToEdit = useCallback(() => {
    setParticipantGroupsToEdit(null);
  }, []);

  const resetScheduleAction = useCallback(() => {
    closeAlertDialog();
    setScheduleAction(null);
  }, []);

  const { mutateAsync: pauseSchedule, isLoading: isSchedulePausing } = usePauseScheduleMutation({
    ...reactQueryConfig.mutation.pause,
    onSuccess: () => {
      reactQueryConfig.mutation.pause.onSuccess();
      reFetchSchedulesData();
      reFetchOncallUsersData();
    },
  });

  const { mutateAsync: resumeSchedule, isLoading: isScheduleResuming } = useResumeScheduleMutation({
    ...reactQueryConfig.mutation.resume,
    onSuccess: () => {
      reactQueryConfig.mutation.resume.onSuccess();
      reFetchSchedulesData();
      reFetchOncallUsersData();
    },
  });

  const { mutateAsync: cloneSchedule, isLoading: isScheduleCloning } = useCloneScheduleMutation({
    ...reactQueryConfig.mutation.clone,
    onSuccess: data => {
      reactQueryConfig.mutation.clone.onSuccess();

      const clonedScheduleLink = generatePath(SCHEDULES_V2_DETAIL_PATH, {
        scheduleId: data.cloneSchedule.ID,
      });
      history.push(clonedScheduleLink);

      reFetchSchedulesData();
    },
  });

  const onPauseSchedule = async (scheduleId: number) => {
    try {
      return pauseSchedule({ ID: scheduleId });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const onResumeSchedule = async (scheduleId: number) => {
    try {
      return resumeSchedule({ ID: scheduleId });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const onCloneSchedule = async (scheduleId: number) => {
    try {
      return cloneSchedule({ ID: scheduleId });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const onConfirmDeleteRotation = async () => {
    setConfirmDeleteRotation(true);
    return Promise.resolve('Deleted!');
  };

  const alertDialogParameters = useMemo(() => {
    if (isRemoveRotationClicked) {
      const alertDialogParameters = getAlertDialogParameters(null);
      alertDialogParameters.title = schedulesTextCopy.alertDialogs.deleteRotation.header;
      alertDialogParameters.confirmButtonText =
        schedulesTextCopy.alertDialogs.deleteRotation.confirmButtonText;
      alertDialogParameters.cancelButtonText =
        schedulesTextCopy.alertDialogs.deleteRotation.cancelButtonText;
      alertDialogParameters.body = (
        <>
          <Text>{schedulesTextCopy.alertDialogs.deleteRotation.bodyFirstLine}</Text>
          <Text>{schedulesTextCopy.alertDialogs.deleteRotation.bodySecondLine}</Text>
          <DeleteRotationSelection />
        </>
      );
      alertDialogParameters.onConfirm = () =>
        onConfirmDeleteRotation().catch(() => {}) ?? Promise.reject();
      alertDialogParameters.isLoading = false;

      return alertDialogParameters;
    }

    const alertDialogParameters = getAlertDialogParameters(scheduleAction);

    if (!scheduleAction) return alertDialogParameters;

    switch (scheduleAction.type) {
      case ScheduleActionTypes.PAUSE: {
        alertDialogParameters.onConfirm = () =>
          onPauseSchedule?.(scheduleAction.params.scheduleId).catch(() => {}) ?? Promise.reject();
        alertDialogParameters.isLoading = isSchedulePausing;
        break;
      }
      case ScheduleActionTypes.RESUME: {
        alertDialogParameters.onConfirm = () =>
          onResumeSchedule?.(scheduleAction.params.scheduleId).catch(() => {}) ?? Promise.reject();
        alertDialogParameters.isLoading = isScheduleResuming;
        break;
      }
      case ScheduleActionTypes.CLONE: {
        alertDialogParameters.onConfirm = () =>
          onCloneSchedule?.(scheduleAction.params.scheduleId).catch(() => {}) ?? Promise.reject();
        alertDialogParameters.isLoading = isScheduleCloning;
        break;
      }
      case ScheduleActionTypes.DELETE: {
        alertDialogParameters.body = (
          <>
            <DeleteScheduleSelection />
          </>
        );
        break;
      }
      case ScheduleActionTypes.EXPORT: {
        alertDialogParameters.body = (
          <>
            <Text>
              {schedulesTextCopy.alertDialogs.export.body.replace(
                'SCHEDULENAME',
                scheduleAction.params.scheduleName,
              )}
            </Text>
            <ExportSelection />
          </>
        );
        break;
      }
    }
    return alertDialogParameters;
  }, [
    scheduleAction,
    isSchedulePausing,
    isScheduleResuming,
    isScheduleCloning,
    isRemoveRotationClicked,
  ]);

  const value = {
    onActionClick,
    openAlertDialog,
    openEditSchedule,
    closeEditSchedule,
    scheduleAction,
    onClickRemoveRotation,
    onSelectDeleteType,
    deleteRotationType,
    confirmDeleteRotation,
    resetDeleteRotationValues,
    reFetchOncallUsersData,
    reFetchSchedulesData,
    rotationToEndOrRemove,
    participantsGroupToEdit,
    onClickEditParticipantGroup,
    resetParticipantsGroupToEdit,
    resetScheduleAction,
    userHasReadPermission,
    userHasCreatePermission,
    userHasUpdatePermission,
    userHasDeletePermission,
  };

  return (
    <ScheduleActionsContext.Provider value={value}>
      {children}
      <CustomAlertDialog
        {...alertDialogParameters}
        isOpen={isAlertDialogOpen && !!scheduleAction}
        onClose={closeAlertDialog}
        onCancel={() => {
          closeAlertDialog();
          resetDeleteRotationValues();
        }}
        enableOverlay
        modalOverlayBackgroundColor="modalOverlay"
        isCentered
        disableFooter={
          scheduleAction?.type === ScheduleActionTypes.EXPORT ||
          scheduleAction?.type === ScheduleActionTypes.DELETE
        }
        showCloseButton
        returnFocusOnClose={false}
      />
      {scheduleAction &&
        [ScheduleActionTypes.EDIT_ROTATIONS, ScheduleActionTypes.EDIT_PARTICIPANTS].includes(
          scheduleAction?.type,
        ) && (
          <CustomDrawerComponent
            size="md"
            isOpen={isEditScheduleOpen && !!scheduleAction}
            returnFocusOnClose={false}
            onClose={() => {
              closeEditSchedule();
              scheduleAction.type === ScheduleActionTypes.EDIT_PARTICIPANTS &&
                resetParticipantsGroupToEdit();
            }}
            title={
              <HStack spacing={5}>
                <ArrowBackIcon
                  onClick={() => {
                    closeEditSchedule();
                    scheduleAction.type === ScheduleActionTypes.EDIT_PARTICIPANTS &&
                      resetParticipantsGroupToEdit();
                  }}
                  _hover={{ cursor: 'pointer' }}
                />
                <SQTooltip
                  text={`${
                    scheduleAction.type === ScheduleActionTypes.EDIT_PARTICIPANTS
                      ? 'Edit participants of'
                      : 'Edit rotations of'
                  } ${(scheduleAction as TScheduleAction).params.scheduleName}`}
                >
                  <Heading variant="h6" maxW={250} isTruncated>
                    {`${
                      scheduleAction.type === ScheduleActionTypes.EDIT_PARTICIPANTS
                        ? 'Edit participants of'
                        : 'Edit rotations of'
                    } ${(scheduleAction as TScheduleAction).params.scheduleName}`}
                  </Heading>
                </SQTooltip>
              </HStack>
            }
            disableBodyPadding
          >
            <>
              <Divider w="100%" />
              <EditRotations scheduleId={`${scheduleAction.params.scheduleId}`} />
            </>
          </CustomDrawerComponent>
        )}
    </ScheduleActionsContext.Provider>
  );
};

const useScheduleActionsContext = () => useContext(ScheduleActionsContext);

export {
  ScheduleActionsContext,
  ScheduleActionsProvider,
  ScheduleActionsConsumer,
  useScheduleActionsContext,
};
