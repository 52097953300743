import React, { useCallback } from 'react';

import { Text, VStack } from '@chakra-ui/react';
import { Bar, BarDatum } from '@nivo/bar';
import { ResponsiveWrapper } from '@nivo/core';

import NodePopover from '../../../components/Popover';
import { getDayMonth, getMonthName, getWeekDateRange } from '../../../helpers/helper.date';

interface IAnalyticsData extends BarDatum {
  date: string;
  Triggered: number;
  Acknowledged: number;
  Resolved: number;
}

export enum AnalyticsRangeType {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  quarterly = 'quarterly',
}

export interface AnalyticsRange {
  key: AnalyticsRangeType;
  granularity: 'day' | 'week' | 'month' | 'quarter';
}

export const analyticsRange: AnalyticsRange[] = [
  { key: AnalyticsRangeType.daily, granularity: 'day' },
  { key: AnalyticsRangeType.weekly, granularity: 'week' },
  { key: AnalyticsRangeType.monthly, granularity: 'month' },
  // { key: AnalyticsRangeType.quarterly, granularity: 'quarter' },
];

const getBarPadding = (totalData: number) => {
  if (totalData < 3) {
    return 0.88;
  } else if (totalData < 5) {
    return 0.8;
  } else {
    return 0.6;
  }
};

const ServiceAnalytics = React.memo(
  ({
    data,
    granularity,
  }: {
    data: IAnalyticsData[];
    granularity: AnalyticsRange['granularity'];
  }) => {
    const formatLabel = (granularity: AnalyticsRange['granularity']) => (value: string) => {
      const selectedData = getBarValue(value);
      const incidentStatusTally = selectedData
        ? selectedData.Triggered + selectedData.Resolved + selectedData.Acknowledged
        : 0;

      let labelText = value;
      switch (granularity) {
        case 'day':
          labelText = getDayMonth(value);
          break;
        case 'week':
          labelText = getWeekDateRange(value);
          break;
        case 'month':
          labelText = getMonthName(value);
          break;
      }

      return (
        <NodePopover
          trigger={<tspan style={{ cursor: 'default', fontSize: 10 }}>{labelText}</tspan>}
          triggerType="hover"
        >
          <VStack p={2} alignItems="flex-start">
            <Text fontWeight={700}>Total ({incidentStatusTally})</Text>
            <Text>Triggered ({selectedData?.Triggered ?? 0})</Text>
            <Text>Acknowledged ({selectedData?.Acknowledged ?? 0})</Text>
            <Text>Resolved ({selectedData?.Resolved ?? 0})</Text>
          </VStack>
        </NodePopover>
      );
    };

    const getBarValue = useCallback(
      (barLabel: string) => {
        return data.find(b => b.date === barLabel);
      },
      [data],
    );

    const getTickRotation = () => {
      if (granularity === 'week') {
        return data.length > 6 ? -30 : 0;
      } else {
        return data.length > 10 ? -30 : 0;
      }
    };

    return (
      <ResponsiveWrapper>
        {({ width }) => (
          <Bar
            width={width}
            height={400}
            data={data}
            indexBy="date"
            keys={['Triggered', 'Acknowledged', 'Resolved']}
            margin={{ top: 50, right: 20, bottom: 80, left: 50 }}
            padding={getBarPadding(data.length)}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={['#9F7AEA', '#ECC94B', '#48BB78']}
            enableLabel={false}
            enableGridY={false}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: getTickRotation(),
              format: formatLabel(granularity),
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: e => Math.floor(e) === e && e,
            }}
            labelSkipWidth={0}
            labelSkipHeight={0}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-left',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 70,
                itemsSpacing: 2.5,
                itemWidth: 120,
                itemHeight: 10,
                itemOpacity: 0.85,
              },
            ]}
            role="incident"
            ariaLabel="Incident Graph"
          />
        )}
      </ResponsiveWrapper>
    );
  },
);

export default ServiceAnalytics;
