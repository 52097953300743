import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { REQUEST_ORG_CURRENT_USER } from '../../const/organization';
import { getOrgCurrentUser } from '../../api/organization/getCurrentUser';
import {
  onRequestOrganizationCurrentUserFail,
  onRequestOrganizationCurrentUserSuccess,
} from '../../actions/organization/currentUser';
import { IUserProfile } from '../../interfaces/IUserData';

const getOrganizationsCurrentUserEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ORG_CURRENT_USER),
    map(action => action.payload),
    switchMap(() => {
      return getOrgCurrentUser().pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ data }: { result: boolean; data: IUserProfile }) => {
          return onRequestOrganizationCurrentUserSuccess(data);
        }),
        catchError(error => of(onRequestOrganizationCurrentUserFail(error.xhr))),
      );
    }),
  );

export default getOrganizationsCurrentUserEpic;
