import { useQuery } from 'react-query';
import { reactQueryConfigError } from '../../jira-cloud/helpers/service.reactqery';
import { ServiceNowSvc } from 'core/services';
import { isServiceNowIntegrated } from '../helpers';
import { IServiceNowResponse } from 'core/interfaces/IExtensions';

async function GetServiceNowConfig() {
  const _servicenowSvc = new ServiceNowSvc();
  const { data } = await _servicenowSvc.getServiceNowConfig();
  return data.data;
}

const useGetServiceNowConfig = (
  showError?: boolean,
  serviceNowStatus?: string,
  cb?: (data: IServiceNowResponse) => void,
) => {
  return useQuery('servicenow-configuration', () => GetServiceNowConfig(), {
    retry: 1,
    staleTime: 0,
    keepPreviousData: true,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onSuccess: (data: IServiceNowResponse) => {
      if (cb && serviceNowStatus && isServiceNowIntegrated(serviceNowStatus)) cb(data);
    },
    onError: (error: any) => {
      const description: string =
        error?.response?.data?.meta?.error_message || 'error connecting to server';
      !description.includes('servicenow config not configured for this org') &&
        showError &&
        reactQueryConfigError({ description: description, title: 'Servicenow configuration' });
    },
  });
};

export { useGetServiceNowConfig };
