import { combineReducers } from 'redux';
import auth from './auth/sessionAuth';
import initApp from './init';
import userInfo from './auth/userInfo';
import userOrganizations from './Organization/userOrganization';
import initOrg from './Organization/initOrg';
import organizationUser from './Organization/organizationUser';
import organizationSquads from './Organization/organizationSquads';
import organizationTeams from './Organization/organizationTeams';
import organizationEscalation from './Organization/organizationEscalation';
import organizationCalendar from './Organization/organizationCalendar';
import organizationServices from './Organization/organizationServices';
import organizationPlan from './Organization/organizationBillingPlan';
import appRoles from './public/roles';
import appIntegrations from './public/integrations';
import organizationIntegrations from './Organization/organizationIntegrations';
import organizationUserAcl from './Organization/organizationUserAcl';
import appActions from './app/actions';
import appConfigs from './app/configs';
import appLimits from './app/limits';
import abTests from './abTest';
import appPreview from './appPreview';
import organizationCurrentUser from './Organization/organizationCurrentUser';
import organizationSelected from './Organization/organizationSelected';
import organizationSelectedTeam from './Organization/organizationSelectedTeam';
import appState from './app/state';
import userPermissionRoles from './Organization/userPermissionRoles';
import organizationRunbook from './Organization/organizationRunbook';
import incidentDetailRunbook from './Organization/incidentDetailRunbook';
import incidentDetailTask from './Organization/incidentDetailTask';
import organizationStakeholdersGroup from './Organization/organizationStakeholdersGroup';

const organization = combineReducers({
  userPermissionRoles,
  currentUser: organizationCurrentUser,
  currentOrg: organizationSelected,
  users: organizationUser,
  squads: organizationSquads,
  teams: organizationTeams,
  stakeholders: organizationStakeholdersGroup,
  escalations: organizationEscalation,
  services: organizationServices,
  calendar: organizationCalendar,
  plan: organizationPlan,
  integrations: organizationIntegrations,
  acl: organizationUserAcl,
  selectedTeam: organizationSelectedTeam,
  runbooks: organizationRunbook,
});

const reducers = combineReducers({
  auth,
  userInfo,
  userOrganizations,
  organization,
  roles: appRoles,
  incidentRunbooks: incidentDetailRunbook,
  incidentTask: incidentDetailTask,
  integrations: appIntegrations,
  INIT_APP: initApp,
  INIT_ORG: initOrg,
  APP_STATE: appState,
  APP_ACTION: appActions,
  APP_CONFIG: appConfigs,
  APP_AB_TEST: abTests,
  APP_PREVIEW: appPreview,
  APP_LIMITS: appLimits,
});

export default reducers;
