import Axios from 'axios';
import { API } from 'core';
import { GER_RULESET_FORM_PROPS_TYPE } from '../create/add-ruleset/ruleset-types';
import { RULESET_RULE, RULE_FORM } from '../types';

export const createGerRulesetRule = (
  gerId: string,
  alertVersion: string,
  alertName: string,
  ruleForm: RULE_FORM,
) => {
  const body = {
    description: ruleForm.description,
    expression: ruleForm.expression,
    action: {
      route_to: ruleForm.service?.value ?? '',
    },
  };
  return Axios.post<{ data: RULESET_RULE }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/global-event-rules/${gerId}/rulesets/${alertVersion}/${alertName}/rules`,
    body,
  );
};
