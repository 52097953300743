import {
  APP_LIMITS__RESET_LIMIT_MESSAGE,
  APP_LIMITS__SET_LIMIT_MESSAGE,
} from '../../const/appLimits';

const setAppLimits = (message: string, description: string) => ({
  type: APP_LIMITS__SET_LIMIT_MESSAGE,
  payload: {
    message,
    description,
  },
});

const resetAppLimits = () => ({
  type: APP_LIMITS__RESET_LIMIT_MESSAGE,
  payload: {},
});

export { setAppLimits, resetAppLimits };
