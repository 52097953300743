import * as React from 'react';
import { SVGProps } from 'react';

const SvgZohoDeskLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M3.598 17.972c.087 0 .219 0 .262-.044 1.268-1.181 2.799-1.181 4.416-1.137s3.192 0 4.81 0c1.093 0 1.443-.306 1.662-1.399.131-.743.219-1.487.35-2.23.087-.437.219-.787.743-.7.525.087.568.481.481.918-.131.875-.262 1.793-.437 2.667-.219 1.224-1.181 1.968-2.58 1.968a128.14 128.14 0 0 1-5.816 0c-1.006-.044-1.88.131-2.711.787-.525.394-1.137.7-1.705 1.049-.262.175-.568.219-.875 0-.306-.175-.306-.481-.219-.787.568-3.323 1.093-6.603 1.662-9.926.22-1.355 1.094-2.098 2.537-2.098 1.924 0 3.804-.044 5.728 0 .743 0 1.093-.131 1.137-.962.044-.7.481-1.443-.787-1.487-.525-.044-.656-.656-.568-1.181.131-.787.262-1.618.394-2.405C12.169.35 12.519 0 13.262 0c.787.044 1.574 0 2.361 0 1.662 0 2.667 1.268 2.405 2.842-.306 1.924-.656 3.892-.962 5.816-.087.568-.306 1.006-.831 1.312a204.397 204.397 0 0 0-5.16 3.192c-.612.394-1.181.394-1.749-.087-.743-.612-1.53-1.224-2.274-1.837-.306-.262-.612-.568-.219-1.006.35-.394.7-.219 1.049.044.525.437 1.093.831 1.618 1.312.481.481.918.525 1.487.131 1.356-.918 2.755-1.749 4.154-2.58.437-.262.656-.612.743-1.137.262-1.749.568-3.498.875-5.291.175-.962-.306-1.487-1.268-1.53h-.831c-1.356 0-1.312 0-1.574 1.312-.131.612-.131.962.656 1.006.656.044.875.612.787 1.224-.087.787-.262 1.618-.394 2.405-.131.743-.525 1.093-1.312 1.093H6.57c-1.224 0-1.574.306-1.749 1.487-.394 2.58-.831 5.116-1.224 7.696-.042.218-.13.393.001.568z"
      style={{
        fill: '#1ba057',
      }}
    />
  </svg>
);

export default SvgZohoDeskLogo20X20;
