import * as React from 'react';
import { SVGProps } from 'react';

const SvgOverops = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#52b2e9',
      }}
      d="M5.227 10.248c.91-5.661 2.19-7.107 5.992-7.107 2.645 0 3.802.826 3.926 3.347h4.05C18.987 2.025 16.507 0 11.88 0H.806l4.008 1.529C2.831 3.058 1.714 5.868 1.095 10a20.07 20.07 0 0 0-.248 2.48H4.98c.042-1.034.124-1.488.248-2.232"
    />
    <path
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#333',
      }}
      d="M14.814 9.793c-.868 5.579-2.314 7.232-5.992 7.232-2.562 0-3.719-.579-3.925-3.017H.847C1.137 18.43 3.822 20 8.74 20c6.322 0 9.05-2.314 10.206-9.917.124-.744.207-1.075.248-2.108h-4.09c-.083.496-.166 1.116-.29 1.818"
    />
  </svg>
);

export default SvgOverops;
