import React from 'react';
import axios from 'axios';
import { API } from '../../../../../core/api';
import { queryKeys } from '../contants/service.contant';
import { useQuery } from 'react-query';

interface IntegrationData {
  id: number;
  name: string;
  description: string;
  slug: string;
}

const fetchIntegrationList = async () => {
  const { data } = await axios.get<{ data: any }>(
    `${API.config.batman}/public/integration-categories`,
  );
  return data.data;
};

export function useIntegrationList() {
  const { data, isSuccess, isError } = useQuery(
    [queryKeys.INTEGRATIONLIST],
    () => fetchIntegrationList(),
    {
      enabled: true,
      retry: 1,
    },
  );
  const integrationListApiData = data;
  return {
    integrationListApiData,
    isSuccess,
    isError,
  };
}
