import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
} from '@chakra-ui/react';
import { CustomDrawerComponent } from 'components/chakra/Drawer';
import Header from 'components/chakra/Header';
import Layout from 'components/chakra/Layout';
import Loader from 'components/chakra/Loader';
import { NodePopover } from 'components/chakra/Popover';
import { Search } from 'components/chakra/Search';
import { API, debounce } from 'core';
import { SchedulesFilters } from 'views/main/organization/schedules/graphql/types';
import {
  useDeleteOverrideMutation,
  useSaveOverrideMutation,
  useUpdateOverrideMutation,
} from 'views/main/organization/schedules/graphql/mutation';
import {
  useGetListOfSchedulesQuery,
  useGetOverrideQuery,
  useGetSchedulesQuery,
} from 'views/main/organization/schedules/graphql/query';
import { CircleIcon, Filter } from 'icons';
import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { SCHEDULES_V2_CREATE_PATH, SCHEDULES_V2_PATH } from 'views/main/routes/routes';

import ScheduleConfirmationModal from '../common/ConfirmationModal';
import ScheduleOverride from '../common/Override';
import {
  activeFilterKeys,
  filterObjectWithoutFilters,
  filterSchedules,
  maxActiveFilters,
} from '../constants/schedulers.filter';
import { schedulesTextCopy } from '../constants/schedules.copy';
import { useGetAllPatternParticipants } from '../helpers/helpers.customrotations';
import { unmountEndRotationRoute, useGetEndRotationParams } from '../helpers/helpers.endRotation';
import {
  encodeFilterParam,
  getActiveFilterObjectForTags,
  useGetAllEscalationPolicies,
  getModifiedFilterParamObject,
  mapFilterParamStringToSetFilterParam,
} from '../helpers/helpers.filterschedules';
import { encodeFilterParam as encodeOwnerFilterParam } from '../../owner-filters/helpers';
import { useWhoIsOncallQuery } from 'views/main/organization/schedules/graphql/query';
import {
  getOverrideDetail,
  unmountOverrideRoute,
  unmountRemoveOverrideRoute,
  useGetOverrideParams,
} from '../helpers/helpers.override';
import {
  defaultReactQueryConfig,
  reactQueryConfig,
  reactQueryConfigError,
} from '../helpers/helpers.schedule';
import { CalendarViewType, IfilterObject } from '../interface/schedule';
import SchedulesHeader from '../schedules.view/schedules.header';
import { useScheduleHeaderContext } from '../schedules.view/schedules.header/context';
import FilterSchedules from './schedules.filter';
import { useScheduleActionsContext } from '../schedules.actions/context';
import { useOrganizationConfig } from '..';
import SQTooltip from 'components/chakra/Tooltip';
import { AppTracker } from 'shared/analytics/tracker';

import {
  T_WA_UP_SCHEDULES_V2_OVERRIDEN,
  T_WA_UP_SCHEDULES_V2_OVERRIDE_EDITED,
  T_WA_UP_SCHEDULES_V2_OVERRIDE_DELETED,
  T_WA_UP_SCHEDULES_V2_PAGE_VIEWED,
  T_WA_UP_SCHEDULES_V2_SEARCH_DONE,
  T_WA_UP_SCHEDULES_V2_FILTER_APPLIED,
} from 'core/const/tracker';
import { colors } from '../theme/styles/colors';
import {
  FilterEntity,
  FilterObject,
  useEntityOwnerFilter,
} from '../../owner-filters/hooks/useEntityOwnerFilter';

import { ActiveFilterTag } from '../../owner-filters/ActiveFilterTags';
import { usernameTooltipLabel } from 'library/atoms';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';
import { EntityACLMeta } from 'core/userAccess/types';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

const SchedulesView = React.lazy(() => import('../schedules.view'));
const PaginationBar = React.lazy(() => import('./schedules.pagination'));

const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const initialFilterValues = {
  escalationPolicies: [],
  limit: 0,
  myOncall: false,
  noEscalationPolicies: false,
  offset: 0,
  participants: [],
  scheduleIDs: [],
  teamID: API.config.teamId,
};

function SchedulesHome() {
  const queryClient = useQueryClient();

  const { userHasReadPermission, userHasCreatePermission } = useScheduleActionsContext();

  const {
    calendarRef,
    calendarNextWeekRef,
    visibleDates,
    activeViewType,
    resetActiveDate,
    allExpanded,
    activeRotViewType,
  } = useScheduleHeaderContext();

  const memoCalendarRef = useMemo(() => calendarRef, [calendarRef?.current]);
  const memoCalendarNextWeekRef = useMemo(
    () => calendarNextWeekRef,
    [calendarNextWeekRef?.current],
  );
  const memoActiveViewValue = useMemo(() => activeViewType.value, [activeViewType.value]);
  const memoActiveRotViewType = useMemo(() => activeRotViewType.value, [activeRotViewType.value]);
  const memoVisibleDates = useMemo(() => visibleDates.twoWeeksView, [visibleDates.twoWeeksView]);
  const memoAllExpanded = useMemo(() => allExpanded, [allExpanded]);

  const {
    overrideModal,
    overrideRotation,
    overrideEventId,
    overrideParticipant,
    overrideStartDate,
    overrideEndDate,
    overrideStartTime,
    overrideEndTime,
    removeOverrideModalOpen,
    removeOverrideId,
    removeOverrideRotation,
  } = useGetOverrideParams();
  const { openEndRotationModal } = useGetEndRotationParams();
  const history = useHistory();
  const query = useQueryParams();
  const filterQueryParams = query.get('filters');
  const scheduleType = query.get('view') ?? 'twoGridWeek';

  const ownerQueryParam = query.get('entity_owner') ?? '';
  const searchQueryParam = query.get('search');
  const limitParam = query.get('limit');
  const pageNumberParam = query.get('page');

  useEffect(() => {
    resetActiveDate();
    AppTracker.track(T_WA_UP_SCHEDULES_V2_PAGE_VIEWED);
  }, []);

  const allFilterParticipants = useGetAllPatternParticipants();
  const {
    organization: { currentUser },
  } = useOrganizationConfig();

  const currentUserId = currentUser.u?.id ?? '';

  const allFilterEscalationPolicies = useGetAllEscalationPolicies();
  const [schedulesFilters, setSchedulesFilters] =
    useState<Omit<SchedulesFilters, 'search'>>(initialFilterValues);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>(searchQueryParam?.toLowerCase() ?? '');
  const [isSearchEnabled, setIsSearchEnabled] = useState<boolean>(false);

  const rowsLimit = useMemo(() => Number.parseInt(limitParam ?? '5'), [limitParam]);
  const pageNumber = useMemo(() => Number.parseInt(pageNumberParam ?? '1'), [pageNumberParam]);

  const [startTime, endTime] = (function () {
    switch (activeViewType.value) {
      case CalendarViewType.dayGridMonth:
        return [
          visibleDates.oneMonthView[0],
          visibleDates.oneMonthView[visibleDates.oneMonthView.length - 1],
        ];
      default:
        return [
          visibleDates.twoWeeksView[0],
          visibleDates.twoWeeksView[visibleDates.twoWeeksView.length - 1],
        ];
    }
  })();

  const eventsFilter = useMemo(() => {
    return {
      startTime: startTime.startOf('day').minus({ days: 1 }).toUTC().toString(),
      endTime: endTime.endOf('day').plus({ days: 1 }).toUTC().toString(),
    };
  }, [startTime, endTime]);

  const reFetchSchedulesData = useRef(() => {
    queryClient.invalidateQueries(['getSchedules']);
  }).current;

  const reFetchOncallUsersData = useRef(() => {
    queryClient.invalidateQueries('whoIsOncall');
    queryClient.invalidateQueries('getOverrideParticipants');
  }).current;

  const ownerFilterString = ownerQueryParam.replace('%7C', '|');

  const { data: scheduleList, isLoading: showLoader } = useGetSchedulesQuery(
    {
      filters: {
        ...schedulesFilters,
        entityOwner: ownerFilterString,
        search: searchQueryParam?.toLowerCase() || '',
        teamID: API.config.teamId,
        limit: rowsLimit,
        offset: rowsLimit * (pageNumber - 1),
      },
      eventsFilter,
      from: eventsFilter.startTime,
      till: eventsFilter.endTime,
    },
    {
      ...defaultReactQueryConfig,
      cacheTime: 0,
      onError: reactQueryConfigError('Get Schedules'),
      onSuccess: data => {
        const acl = data.schedules?.reduce((acc, curr) => {
          acc[curr.ID] = {
            has_delete_access: curr.acl?.hasDeleteAccess ?? false,
            has_update_access: curr.acl?.hasUpdateAccess ?? false,
            has_update_owner_access: curr.acl?.hasUpdateOwnerAccess ?? false,
          };
          return acc;
        }, {} as Record<string, EntityACLMeta>);
        setUserAccessStore({
          entityACLMap: {
            ...getUserAccessStore().entityACLMap,
            schedules: {
              ...getUserAccessStore().entityACLMap?.schedules,
              ...acl,
            },
          },
        });
      },
    },
  );

  const { data: schedulesWFiltersWOLimit } = useGetListOfSchedulesQuery(
    {
      filters: {
        ...schedulesFilters,
        entityOwner: ownerFilterString,
        search: searchQueryParam?.toLowerCase() || '',
        teamID: API.config.teamId,
        limit: 0,
        offset: 0,
      },
    },
    { ...defaultReactQueryConfig, cacheTime: 0, onError: reactQueryConfigError('Get Schedules') },
  );

  const { data: allSchedules } = useGetListOfSchedulesQuery(
    {
      filters: { teamID: API.config.teamId },
    },
    { ...defaultReactQueryConfig, cacheTime: 0, onError: reactQueryConfigError('Get Schedules') },
  );

  const { data: overrideInfo } = useGetOverrideQuery(
    { ID: Number(overrideEventId) },
    { enabled: !!overrideEventId },
  );
  const { mutateAsync: updateOverride, isLoading: isUpdatingOverride } = useUpdateOverrideMutation({
    ...reactQueryConfig.mutation.updateOverride,
    onSuccess: () => {
      reFetchSchedulesData();
      reFetchOncallUsersData();
      reactQueryConfig.mutation.updateOverride.onSuccess();
      AppTracker.track(T_WA_UP_SCHEDULES_V2_OVERRIDE_EDITED);
    },
  });
  const { mutateAsync: saveOverride, isLoading: isSavingOverride } = useSaveOverrideMutation({
    ...reactQueryConfig.mutation.createOverride,
    onSuccess: () => {
      reFetchSchedulesData();
      reFetchOncallUsersData();
      reactQueryConfig.mutation.createOverride.onSuccess();
      AppTracker.track(T_WA_UP_SCHEDULES_V2_OVERRIDEN);
    },
  });
  const { mutateAsync: deleteOverride, isLoading: isDeletingOverride } = useDeleteOverrideMutation({
    ...reactQueryConfig.mutation.deleteOverride,
    onSuccess: () => {
      reFetchSchedulesData();
      reFetchOncallUsersData();
      reactQueryConfig.mutation.deleteOverride.onSuccess();
      AppTracker.track(T_WA_UP_SCHEDULES_V2_OVERRIDE_DELETED);
    },
  });

  const onCallSchedules = useMemo(
    () => allSchedules?.schedules?.map(s => s.ID) ?? [],
    [allSchedules],
  );
  const { data: onCallUsers } = useWhoIsOncallQuery(
    {
      filters: {
        teamID: API.config.teamId,
        ScheduleIDs: onCallSchedules,
      },
    },
    {
      ...defaultReactQueryConfig,
      enabled: !!onCallSchedules.length,
    },
  );

  const schedules = useMemo(() => scheduleList?.schedules || [], [scheduleList]);
  const schedulesWOFilters = useMemo(() => allSchedules?.schedules || [], [allSchedules]);

  const allScheduleOptions = useMemo(
    () =>
      schedulesWOFilters.map(schedule => ({
        label: schedule.name,
        value: schedule.ID,
        id: `${schedule.ID}`,
      })),
    [schedulesWOFilters],
  );

  const debouncedSearch = useRef(
    debounce((searchParam: string) => {
      setSearchTerm(searchParam);
      AppTracker.track(T_WA_UP_SCHEDULES_V2_SEARCH_DONE);
    }, 1000),
  ).current;

  const updateSchedulesFilter = ({ p, ep, sch, moc, nep }: IfilterObject) => {
    setSchedulesFilters({
      ...schedulesFilters,
      participants: moc || !p || !p[0] ? [] : [...p.map(participant => participant.value)],
      escalationPolicies:
        nep || !ep || !ep[0] ? [] : [...ep.map(escalationPolicy => escalationPolicy.value)],
      scheduleIDs: !sch || !sch[0] ? [] : [...sch.map(schedule => schedule.value)],
      myOncall: moc ?? false,
      noEscalationPolicies: nep ?? false,
    });
  };

  const activeFilters = useMemo(() => {
    return getActiveFilterObjectForTags(
      schedulesFilters,
      allFilterParticipants,
      allFilterEscalationPolicies,
      allScheduleOptions,
    );
  }, [schedulesFilters, allFilterParticipants, allFilterEscalationPolicies, allScheduleOptions]);

  useEffect(() => {
    //If there are currently no filters (or initial load)
    if (!filterQueryParams) {
      updateSchedulesFilter(filterObjectWithoutFilters);
      return;
    }
    //If there are any filters
    updateSchedulesFilter(
      mapFilterParamStringToSetFilterParam(
        filterQueryParams,
        allFilterParticipants,
        allFilterEscalationPolicies,
        allScheduleOptions,
      ),
    );
    AppTracker.track(T_WA_UP_SCHEDULES_V2_FILTER_APPLIED);
  }, [filterQueryParams, allScheduleOptions]);

  useEffect(() => {
    if (searchQueryParam && !isSearchEnabled) setIsSearchEnabled(true); //to handle the case where page is loaded with search params

    //Apply the search only if search term is not the same
    if (searchQueryParam && searchQueryParam.toLowerCase() !== searchTerm) {
      debouncedSearch(searchQueryParam.toLowerCase());
      return;
    } else {
      return;
    }
  }, [searchQueryParam]);

  const {
    activeFilterObject,
    clearSelectedFilterValues,
    selectedFilterObject,
    activeFilterTags,
    dropdownOptions: ownerOptions,
    onChangeHandler: ownerDropdownChangeHandler,
    selectedFilterTags,
    handleActiveFilterTagClick,
    handleSelectedFilterTagClick,
    ownerFilterApplied,
    ownerFilterSelected,
    resetOwnerFilters,
    syncSelectedFilterWithActiveFilter,
  } = useEntityOwnerFilter({ queryParamsString: ownerQueryParam, entity: FilterEntity.SCHEDULE });

  useEffect(() => {
    if (isFilterDrawerOpen && !ownerFilterSelected && ownerFilterApplied) {
      /** For scenario when we clear all selected tags from drawer and a filter is applied.
      Without this useEffect, we will not be able to clear out the filters */
      onClearFilters();
    }
  }, [isFilterDrawerOpen, ownerFilterSelected, ownerFilterApplied]);

  const onCloseFilterDrawer = () => {
    if (!ownerFilterApplied) {
      clearSelectedFilterValues();
    } else {
      syncSelectedFilterWithActiveFilter();
    }
    setIsFilterDrawerOpen(false);
  };

  const onClearFilters = () => {
    resetOwnerFilters();
    query.delete('entity_owner');
    history.push(`${SCHEDULES_V2_PATH}?${query}`);
    setIsFilterDrawerOpen(false);
  };

  const applyEntityOwnerFilters = (filterObject: FilterObject) => {
    const filterUrlParams = encodeOwnerFilterParam(filterObject);
    query.delete('entity_owner');
    filterUrlParams && query.append('entity_owner', filterUrlParams);
    history.push(`${SCHEDULES_V2_PATH}?${query}`);
  };

  useEffect(() => {
    applyEntityOwnerFilters(activeFilterObject);
  }, [activeFilterObject]);

  const handleModifyActiveFilter = (filter: any) => {
    const updatedFiltersObject: IfilterObject = getModifiedFilterParamObject(
      filter,
      schedulesFilters,
      allFilterParticipants,
      allFilterEscalationPolicies,
      allScheduleOptions,
    );
    const filterUrlParams = encodeFilterParam(updatedFiltersObject);
    query.delete('filters');
    query.delete('page');
    filterUrlParams && query.append('filters', filterUrlParams);
    history.push(`${SCHEDULES_V2_PATH}?${query}`);
  };

  const showFilterKey = (filter: any) => {
    return ![activeFilterKeys.MY_ON_CALL, activeFilterKeys.NO_ESCALATION_POLICY].includes(
      filter.filterKey,
    );
  };

  const mapFilterToTag = (filter: any) => {
    const { label, type, username } = filter.filterValue;

    const isParticipant = filter.filterKey === 'Participant';
    const filterText = isParticipant
      ? `${label}${type === 'user' && username ? ` (${username})` : ''}`
      : label;
    const tagLabel = `${showFilterKey(filter) ? `${filter.filterKey}: ` : ``}${filterText}`;
    return (
      <Tag key={filter.filterValue.value} size="md" _hover={{ cursor: 'pointer' }} m={1}>
        <SQTooltip text={usernameTooltipLabel(label, username)}>
          <TagLabel fontWeight="normal" maxW={150} isTruncated>
            {tagLabel}
          </TagLabel>
        </SQTooltip>
        <TagCloseButton onClick={() => handleModifyActiveFilter(filter)} />
      </Tag>
    );
  };

  const mapOwnerFilterToTag = (filter: ActiveFilterTag) => {
    const { type, value, key, id, username } = filter;
    const tagLabel = `${key}: ${value}`;
    const valueWithoutUsername = value.replace(`(${username})`, '');

    return (
      <Tag key={value} size="md" _hover={{ cursor: 'pointer' }} m={1}>
        <SQTooltip text={usernameTooltipLabel(valueWithoutUsername, username)}>
          <TagLabel fontWeight="normal" maxW={150} isTruncated>
            {tagLabel}
          </TagLabel>
        </SQTooltip>
        <TagCloseButton onClick={() => handleActiveFilterTagClick(type, id)} />
      </Tag>
    );
  };

  const showActiveFilters = () => {
    if ([...activeFilters, ...activeFilterTags].length <= maxActiveFilters) {
      return (
        <HStack>
          {activeFilterTags.map(filter => mapOwnerFilterToTag(filter))}
          {activeFilters.map(filter => (filter.filterValue ? mapFilterToTag(filter) : <></>))}
        </HStack>
      );
    } else {
      const visibleFilters = [...activeFilters, ...activeFilterTags].slice(0, maxActiveFilters);
      const filtersInsidePopover = [...activeFilters, ...activeFilterTags].slice(maxActiveFilters);
      return (
        <HStack>
          {visibleFilters.map(filter =>
            filter.key ? (
              mapOwnerFilterToTag(filter as ActiveFilterTag)
            ) : (filter as Record<string, any>).filterValue ? (
              mapFilterToTag(filter)
            ) : (
              <></>
            ),
          )}
          <NodePopover
            triggerType="hover"
            trigger={
              <Tag size="md">
                <TagLabel>+ {filtersInsidePopover.length}</TagLabel>
              </Tag>
            }
          >
            <Container maxW="md">
              {filtersInsidePopover.map(filter =>
                filter.key ? (
                  mapOwnerFilterToTag(filter as ActiveFilterTag)
                ) : (filter as Record<string, any>).filterValue ? (
                  mapFilterToTag(filter)
                ) : (
                  <></>
                ),
              )}
            </Container>
          </NodePopover>
        </HStack>
      );
    }
  };

  const openRemoveOverrideModal = useMemo(() => {
    return removeOverrideModalOpen && !!removeOverrideId && !!removeOverrideRotation;
  }, [schedules, removeOverrideModalOpen, removeOverrideId, removeOverrideRotation]);

  const overrideDetail = useMemo(() => {
    return getOverrideDetail({
      schedules,
      overrideInfo,
      overrideModal,
      overrideRotation,
      overrideEventId,
      overrideParticipant: overrideParticipant ?? ([] as string[]),
      overrideStartDate,
      overrideEndDate,
      overrideStartTime,
      overrideEndTime,
    });
  }, [
    schedules,
    overrideInfo,
    overrideModal,
    overrideRotation,
    overrideEventId,
    overrideParticipant,
    overrideStartDate,
    overrideEndDate,
    overrideStartTime,
    overrideEndTime,
  ]);

  const onRemoveOverride = useCallback(async () => {
    if (removeOverrideId) {
      try {
        await deleteOverride({ id: Number(removeOverrideId) });
        onCloseRemoveOverride();
      } catch (error) {}
    }
  }, [removeOverrideId]);

  const onCloseOverride = useCallback(() => {
    history.push(unmountOverrideRoute(history.location.pathname, history.location.search));
  }, [history]);

  const onCloseRemoveOverride = useCallback(() => {
    history.push(unmountRemoveOverrideRoute(history.location.pathname, history.location.search));
  }, [history]);

  const onCloseEndRotation = useCallback(() => {
    history.push(unmountEndRotationRoute(history.location.pathname, history.location.search));
  }, [history]);

  const viewHeight = useMemo(() => {
    switch (activeViewType.value) {
      case CalendarViewType.twoGridWeek:
        return `calc(100vh - ${activeFilters.length > 0 ? '345px' : '315px'})`;
      case CalendarViewType.listView:
        return `calc(100vh - ${activeFilters.length > 0 ? '375px' : '200px'})`;
      case CalendarViewType.dayGridMonth:
        return `calc(100vh - ${activeFilters.length > 0 ? '400px' : '370px'})`;
    }
  }, [activeFilters, activeViewType]);

  if (!userHasReadPermission) {
    return (
      <HStack justifyContent="center" mt={5}>
        <Text>You do not have permission to view Schedules!</Text>
      </HStack>
    );
  }

  return (
    <Layout>
      <Header
        title={
          <VStack alignItems="flex-start">
            <Text fontSize="27px" color="#1D426B">
              {schedulesTextCopy.home.title}
            </Text>
            {[CalendarViewType.dayGridMonth, CalendarViewType.listView].includes(
              activeViewType.value,
            ) && (
              <Text variant="grayedCaption">
                In this view, you will be viewing all the Schedules in the timezone associated with
                your{' '}
                <Link
                  href={'user/' + currentUserId}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="underlinedLink"
                >
                  profile
                </Link>
                .
              </Text>
            )}
            {schedules.length > 0 &&
              [CalendarViewType.dayGridMonth].includes(activeViewType.value) && (
                <NodePopover
                  triggerType="hover"
                  trigger={
                    <Text size="xs">{`Hover here to see the currently shown schedules`}</Text>
                  }
                  placement="right"
                >
                  <Container maxW="md">
                    {schedules.map(sch => (
                      <Tag key={sch.ID} size="md" m={1}>
                        <TagLabel>{sch.name}</TagLabel>
                      </Tag>
                    ))}
                  </Container>
                </NodePopover>
              )}
            {showActiveFilters()}
          </VStack>
        }
        actions={
          <HStack justifyContent="flex-end" gap={3}>
            <HStack gap={3}>
              <Search
                searchEnabled={isSearchEnabled}
                setSearchEnabled={isEnabled => {
                  query.delete('search');
                  if (isEnabled) {
                    setIsSearchEnabled(true);
                    query.append('search', '');
                    history.push(`${SCHEDULES_V2_PATH}?${query}`);
                  } else {
                    setIsSearchEnabled(false);
                    history.push(`${SCHEDULES_V2_PATH}?${query}`);
                  }
                }}
                isDisabled={false}
              />
              <Flex position="relative">
                <IconButton
                  aria-label={schedulesTextCopy.home.label.filter}
                  variant="icon"
                  icon={
                    <Filter
                      style={{ fill: colors.colors.blue['900'], stroke: colors.colors.blue['900'] }}
                    />
                  }
                  onClick={() => {
                    setIsFilterDrawerOpen(true);
                  }}
                />
                {[...activeFilters, ...activeFilterTags].length > 0 && (
                  <CircleIcon
                    style={{
                      fill: 'red',
                      stroke: 'red',
                      position: 'absolute',
                      top: '-5',
                      right: '-10',
                    }}
                    height="10px"
                  />
                )}
              </Flex>
            </HStack>

            <>
              <Center height={8}>
                <Divider orientation="vertical" />
              </Center>
              <NoPermissionTooltip isDisabled={userHasCreatePermission}>
                <Button
                  className="create-schedule"
                  onClick={() => history.push(SCHEDULES_V2_CREATE_PATH)}
                  isDisabled={!userHasCreatePermission}
                >
                  {schedulesTextCopy.home.add}
                </Button>
              </NoPermissionTooltip>
            </>
          </HStack>
        }
      />
      <Divider />
      <SchedulesHeader />
      <Divider />

      {showLoader && <Loader.Spinner centered isLoading spinnerProps={{ mt: 12, size: 'xl' }} />}
      {!showLoader && !!schedules.length && (
        <div className="schedules-list">
          <Suspense
            fallback={<Loader.Spinner centered isLoading spinnerProps={{ mt: 12, size: 'xl' }} />}
          >
            <SchedulesView
              calendarRef={memoCalendarRef}
              calendarNextWeekRef={memoCalendarNextWeekRef}
              calendarView={memoActiveViewValue}
              rotationViewType={memoActiveRotViewType}
              schedules={schedules}
              onCallUsers={onCallUsers}
              visibleDates={memoVisibleDates}
              allExpanded={memoAllExpanded}
              height={viewHeight}
            />
            {scheduleType === 'twoGridWeek' && (
              <PaginationBar
                data={
                  [...activeFilters, ...activeFilterTags].length > 0
                    ? schedulesWFiltersWOLimit?.schedules ?? []
                    : schedulesWOFilters
                }
              />
            )}
          </Suspense>
        </div>
      )}
      {!showLoader && !schedules.length && (
        <HStack justifyContent="center" mt={5}>
          <Text>No schedules</Text>
        </HStack>
      )}

      {/* {getCurrentView()} */}

      {isFilterDrawerOpen ? (
        <CustomDrawerComponent
          isOpen={isFilterDrawerOpen}
          onClose={onCloseFilterDrawer}
          title={
            <HStack spacing={5}>
              <ArrowBackIcon
                onClick={() => setIsFilterDrawerOpen(false)}
                _hover={{ cursor: 'pointer' }}
              />
              <Heading variant="h6">{filterSchedules.heading}</Heading>
            </HStack>
          }
          disableBodyPadding
        >
          <FilterSchedules
            activeFilters={schedulesFilters}
            allFilterParticipants={allFilterParticipants}
            allFilterEscalationPolicies={allFilterEscalationPolicies}
            allScheduleOptions={allScheduleOptions}
            ownerFilterProps={{
              ownerOptions,
              ownerDropdownChangeHandler,
              ownerFilterSelected,
              onClearFilters,
              selectedFilterTags,
              handleSelectedFilterTagClick,
            }}
            onSubmit={(filters: IfilterObject) => {
              const filterUrlParams = encodeFilterParam(filters);
              const entityOwnerUrlParams = encodeOwnerFilterParam(selectedFilterObject);
              query.delete('filters');
              query.delete('page');
              query.delete('entity_owner');
              filterUrlParams && query.append('filters', filterUrlParams);
              entityOwnerUrlParams && query.append('entity_owner', entityOwnerUrlParams);
              history.push(`${SCHEDULES_V2_PATH}?${query}`);
            }}
            onCancel={onCloseFilterDrawer}
          />
        </CustomDrawerComponent>
      ) : (
        <></>
      )}

      <ScheduleOverride
        isOpen={overrideModal}
        onClose={onCloseOverride}
        overrideDetail={overrideDetail}
        isLoading={showLoader}
        updateOverrideId={overrideEventId}
        isSavingOverride={isSavingOverride || isUpdatingOverride}
        onSave={saveOverride}
        onUpdate={updateOverride}
      />

      <ScheduleConfirmationModal
        isOpen={openEndRotationModal}
        isButtonLoading={false}
        onClose={onCloseEndRotation}
        onConfirm={() => {}}
        title={schedulesTextCopy.rotation.remove.title}
        body={schedulesTextCopy.rotation.remove.body}
      />

      <ScheduleConfirmationModal
        isOpen={openRemoveOverrideModal}
        isButtonLoading={isDeletingOverride}
        onClose={onCloseRemoveOverride}
        onConfirm={onRemoveOverride}
        title={schedulesTextCopy.override.remove.title}
        body={schedulesTextCopy.override.remove.body}
      />
    </Layout>
  );
}

export default SchedulesHome;
