import Axios from 'axios';
import { API } from '../api';
import { IWeeklyReportsConfig } from 'core/interfaces/IUserData';

class WeeklyReportsService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/weekly-reports`;

  public getWeeklyReportsConfig = () =>
    Axios.get<{ data: IWeeklyReportsConfig }>(`${this._api}/config`);

  public updateWeeklyReportsConfig = ({
    enabled,
    is_all_teams_selected,
    team_ids,
  }: IWeeklyReportsConfig) =>
    Axios.put<{ data: IWeeklyReportsConfig }>(`${this._api}/config`, {
      enabled,
      is_all_teams_selected,
      team_ids,
    });
}

export default WeeklyReportsService;
