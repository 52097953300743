import moment from 'moment';
import qs from 'query-string';
import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Cell } from 'react-table';
import DelayIcon from '../../icons/AlertSources/DataSync';

import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Spacer,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';

import IllustrationBox from '../../common/IllustrationBox';
import Card from '../../components/Card';
import StackLimit, { IconType } from '../../components/StackLimit';
import DataTable from '../../components/Table';
import { addAlertSourceUrl, serviceDetailUrl } from '../../contants/service.contant';
import { IAlertSource, IIncident, useServiceExpandedList } from '../../hooks/useExpandedView';
import AutomationIllustration from '../../icons/Illustrations/AutomationStats.png';
import AlertSourceIllustration from '../../icons/Illustrations/AlertSource.png';
import IncidentIllustration from '../../icons/Illustrations/RecentIncidents.png';
import MaintenanceIllustration from '../../icons/Illustrations/Maintenance.png';
import { OrganizationContext } from '../../index';
import { PlaceholderText } from '../../common';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { ServiceTooltip } from '../../components';
import { useLastUpdatedServiceQuery } from '../../hooks/useServiceList';
import { AppTracker } from '../../../../../../shared/analytics/tracker';
import { T_WA_UP_VIEW_DEPENDENCY } from '../../../../../../core/const/tracker';
import { ViewDetailIcon } from 'icons';
import { DialogModalFrame } from 'uie/components';
import UnsavedChangesGuard from 'components/unsavedChangesGuard';
import CustomTemplate from '../../service.detail/service.attribute/service.templates/customTemplates.modal';
import {
  useGetOneCustomTemplateQuery,
  useServiceCustomTemplateQuery,
} from '../../hooks/useServiceDetail';
import useQueryParams from '../../helpers/helper.query-params';
import { BillingService } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import { useSelector } from 'react-redux';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { useUserAccess } from 'core/userAccess/UserAccessContext';

interface IRecentIncident {
  description: string;
  time: string;
}

interface IAutomation {
  rule: string;
  stats: string;
}

interface IAlertSources {
  alert_source_id: string;
  short_name: string;
  type: string;
  open_incidents_count: number;
  last_incident_at: string;
  have_custom_content_template: boolean;
}

interface IMaintenanceMode {
  schedule?: string;
  time?: string;
  deleted: boolean;
  maintenanceFrom: string;
  maintenanceTill: string;
  repeatTill: string;
  repetitionDaily: boolean;
  repetitionMonthly: boolean;
  repetitionThreeWeekly: boolean;
  repetitionTwoWeekly: boolean;
  repetitionWeekly: boolean;
}
type statekeys =
  | 'modalOpen'
  | 'showAlertDropdown'
  | 'addTemplate'
  | 'isDirty'
  | 'hasUnsavedChanges';

enum ShowAlert {
  show,
  hide,
}

export const AlertSources = ({
  expandedAlertData,
  width,
  height,
  serviceId,
  refetchAlert,
}: {
  expandedAlertData: { data: 0 | IAlertSource };
  width?: string;
  height?: string;
  serviceId: string;
  refetchAlert: () => void;
}) => {
  const data = useMemo(
    () => (expandedAlertData.data ? expandedAlertData.data.alert_sources : []),
    [expandedAlertData.data],
  );
  const history = useHistory();
  const query = useQueryParams();
  const modeParamKey = 'mode';
  const alertSourceParamKey = 'alert-source';
  const hasCustomTemplateParamKey = 'hasCustomTemplate';

  const [isDirty, setIsDirty] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const mode = (query.get(modeParamKey) ?? '') as 'view' | 'edit' | undefined;
  const alertSource = query.get(alertSourceParamKey) ?? '';
  const hasCustomTemplate = query.get(hasCustomTemplateParamKey);

  const organization = React.useContext(OrganizationContext);
  const orgDetail = { organization } as Pick<IAppState, 'organization'>;
  const isCCTDisabled = BillingService.isFeatureDisabled(orgDetail, 'custom-content-templates');

  const currentUserRole: any = useMemo(() => {
    const result: any = {};
    for (const key in organization?.acl.a?.services) {
      if (key === serviceId) {
        result[key] = organization?.acl.a?.services[key];

        break;
      }
    }
    return result[serviceId] ?? {};
  }, [organization?.acl.a?.services, serviceId]);
  const viewPermission = currentUserRole['read-services'] ?? false;
  const createPermission = currentUserRole['create-services'] ?? false;
  const updatePermission = currentUserRole['update-services'] ?? false;
  const deletePermission = currentUserRole['delete-services'] ?? false;

  const { refetch } = useServiceCustomTemplateQuery(serviceId, isCCTDisabled, viewPermission);

  const { data: customDetail, isFetching } = useGetOneCustomTemplateQuery(
    serviceId,
    alertSource as string,
    hasCustomTemplate as string,
  );

  const hideModal = () => {
    setIsDirty(false);
    history.push({
      pathname: history.location.pathname,
      search: qs.exclude(history.location.search, [
        alertSourceParamKey,
        modeParamKey,
        hasCustomTemplateParamKey,
      ]),
    });
  };
  const checkDirtyAndCloseModal = () => {
    if (isDirty) {
      setHasUnsavedChanges(true);
    } else {
      hideModal();
    }
  };
  const checkAndSetDirty = (isModalFormDirty?: boolean) => {
    setIsDirty(isModalFormDirty ?? true);
  };

  const createTemplate = (short_name: string) => {
    history.push({
      pathname: history.location.pathname,
      search: qs.stringifyUrl({
        url: history.location.search,
        query: {
          [alertSourceParamKey]: short_name,
          [modeParamKey]: 'create',
        },
      }),
    });
  };
  const editTemplate = (short_name: string) => {
    history.push({
      pathname: history.location.pathname,
      search: qs.stringifyUrl({
        url: history.location.search,
        query: {
          [alertSourceParamKey]: short_name,
          [hasCustomTemplateParamKey]: 'true',
          [modeParamKey]: 'edit',
        },
      }),
    });
  };
  const commonConfig = {
    serviceId,
    customDetail,
    mode,
    alertSource,
    deletePermission,
    refetchRule: () => {
      refetch();
      hideModal();
    },
  };

  const columns = [
    {
      id: 'name',
      Header: 'NAME',
      Cell: (cell: Cell<IAlertSources>) => {
        const {
          row: {
            original: { short_name, type },
          },
        } = cell;

        return (
          <HStack>
            <StackLimit
              limit={2}
              type="icon"
              data={[{ icon: short_name as IconType, name: type }]}
            />
            <Text fontSize="sm">{type}</Text>
          </HStack>
        );
      },
    },
    {
      id: 'open_incident',
      Header: <Text textAlign="left">LIFETIME OPEN INCIDENTS</Text>,
      Cell: (cell: Cell<IAlertSources>) => {
        const {
          row: {
            original: { open_incidents_count },
          },
        } = cell;
        return (
          <Text variant="blackAlpha" textAlign="right">
            {open_incidents_count}
          </Text>
        );
      },
    },
    {
      id: 'Templates',
      Header: <Text textAlign="left">Templates</Text>,
      Cell: (cell: Cell<IAlertSources>) => {
        const {
          row: {
            original: { have_custom_content_template, short_name },
          },
        } = cell;
        return have_custom_content_template === false ? (
          createPermission ? (
            <Box onClick={() => createTemplate(short_name)}>
              <Text color="#1B7FF1" fontWeight={400} fontSize={'14px'} cursor={'pointer'}>
                +Add
              </Text>
            </Box>
          ) : null
        ) : updatePermission ? (
          <ViewDetailIcon
            height="22px"
            cursor={'pointer'}
            onClick={() => editTemplate(short_name)}
          />
        ) : null;
      },
    },
  ];

  const filteredColumns =
    !isCCTDisabled && (updatePermission || createPermission)
      ? columns
      : columns.filter(col => col.id !== 'Templates');
  return data.length ? (
    <div>
      <DataTable
        height={height || '250px'}
        width={width || '100%'}
        data={data}
        columns={filteredColumns}
      />
      <DialogModalFrame
        id={'custom-template'}
        width="1000px"
        onClose={checkDirtyAndCloseModal}
        skipFocusTrap
      >
        {mode && !isFetching && (
          <CustomTemplate
            {...commonConfig}
            setIsDirty={checkAndSetDirty}
            refetchAlert={refetchAlert}
          />
        )}
      </DialogModalFrame>
      <UnsavedChangesGuard
        isManual={true}
        showModal={hasUnsavedChanges}
        onModalClose={() => {
          setHasUnsavedChanges(false);
        }}
        onDiscardChanges={() => {
          hideModal();
          setHasUnsavedChanges(false);
        }}
      />
    </div>
  ) : (
    <Box height={height || '250px'} width={width || '100%'}>
      <IllustrationBox
        msg={
          <PlaceholderText
            message="No alert sources added"
            tip={
              'Tip: Alert sources are how services receive incidents. Add your first one to see the value of the platform.'
            }
          />
        }
        height="150px"
        width="150px"
        image={AlertSourceIllustration}
        name="alert source"
      />
    </Box>
  );
};

function ExpandedDetail({ serviceID }: { serviceID: string }) {
  const history = useHistory();
  const organization = React.useContext(OrganizationContext);
  const OBACEnabled = useSelector(
    (state: IAppState) => state.organization?.currentOrg.o?.config.obac_enabled,
  );

  const hasUpdateAccess = useUserAccess().hasUpdateAccess;

  const hasUpdate = hasUpdateAccess('services', serviceID);

  const {
    expandedData: [
      expandedIncidentData,
      expandedAutomationData,
      expandedAlertData,
      expandedMentainenceData,
    ],
  } = useServiceExpandedList(serviceID, organization?.selectedTeam.teamId);

  const serviceLastUpdated = useLastUpdatedServiceQuery();

  const showAutomationAction =
    expandedAutomationData.data &&
    [expandedAutomationData.data.duplicated, expandedAutomationData.data.suppressed].every(
      val => val,
    );

  const RecentIncident = () => {
    const data = useMemo(
      () =>
        (expandedIncidentData.data && expandedIncidentData.isSuccess
          ? expandedIncidentData.data.incidents
          : []) as any,
      [expandedIncidentData.data],
    );
    const columns = [
      {
        id: 'description',
        Header: 'DESCRIPTION',
        Cell: (cell: Cell<IIncident>) => {
          const {
            row: {
              original: { message },
            },
          } = cell;

          return (
            <Text fontSize="sm" wordBreak="break-word">
              {message}
            </Text>
          );
        },
      },
      {
        id: 'time',
        Header: () => <Text textAlign="right">TIME</Text>,
        Cell: (cell: Cell<IIncident>) => {
          const {
            row: {
              original: { status, timeOfCreation },
            },
          } = cell;
          return (
            <VStack alignItems="flex-start">
              <Text color="blackAlpha.600" textAlign="left" variant="smCapitalize">
                {status}
              </Text>
              <Text variant="blackAlpha" textAlign="left">
                {moment(timeOfCreation).fromNow()}
              </Text>
            </VStack>
          );
        },
      },
    ];
    return data.length ? (
      <DataTable height="250px" width="100%" data={data} columns={columns} />
    ) : (
      <Box height="250px" width="100%">
        <IllustrationBox
          msg={
            <PlaceholderText
              message="No recent incidents! (Yay!)"
              tip={'Tip: Check your alert sources to ensure the alerts are routed to the service.'}
            />
          }
          height="150px"
          width="150px"
          image={IncidentIllustration}
          name="recent incident"
        />
      </Box>
    );
  };

  const Automation = () => {
    const data = useMemo(
      () =>
        expandedAutomationData.data
          ? [
              { rule: 'Supressed Incidents', stats: expandedAutomationData.data.suppressed },
              { rule: 'De-duplicated Incidents', stats: expandedAutomationData.data.duplicated },
            ]
          : [],
      [expandedAutomationData.data],
    );

    const columns = [
      {
        id: 'rule',
        Header: 'RULE',
        Cell: (cell: Cell<IAutomation>) => {
          const {
            row: {
              original: { rule },
            },
          } = cell;

          return <Text fontSize="sm">{rule}</Text>;
        },
      },
      {
        id: 'weekly_stats',
        Header: <Text textAlign="left">WEEKLY STATS</Text>,
        Cell: (cell: Cell<IAutomation>) => {
          const {
            row: {
              original: { stats },
            },
          } = cell;
          return (
            <Text variant="blackAlpha" textAlign="right">
              {stats}
            </Text>
          );
        },
      },
    ];
    return data.some(automation => automation.stats) ? (
      <DataTable height="250px" width="100%" data={data} columns={columns} />
    ) : (
      <Box height="250px" width="100%">
        <IllustrationBox
          msg={
            <PlaceholderText
              message="No automation stats"
              tip={
                'Tip: Create automation rules to reduce alert noise and work on incidents that truly matter.'
              }
            />
          }
          height="150px"
          width="150px"
          image={AutomationIllustration}
          name="recent incident"
        />
      </Box>
    );
  };

  const MaintenanceMode = () => {
    const data = useMemo(
      () =>
        (expandedMentainenceData.data && expandedMentainenceData.data
          ? expandedMentainenceData.data.map(mdata => {
              if (!mdata.deleted) {
                if (
                  mdata.repetitionDaily ||
                  mdata.repetitionMonthly ||
                  mdata.repetitionThreeWeekly ||
                  mdata.repetitionTwoWeekly ||
                  mdata.repetitionWeekly
                ) {
                  if (mdata.repetitionDaily) return { schedule: 'Repeat (Day)', ...mdata };
                  if (mdata.repetitionWeekly) return { schedule: 'Repeat  (Week)', ...mdata };
                  if (mdata.repetitionTwoWeekly)
                    return { schedule: 'Repeat (Two Weeks)', ...mdata };
                  if (mdata.repetitionThreeWeekly)
                    return { schedule: 'Repeat (Three Weeks)', ...mdata };
                  if (mdata.repetitionMonthly) return { schedule: 'Repeat (One Month) ', ...mdata };
                } else {
                  return { schedule: 'Once', ...mdata };
                }
              }
            })
          : []) as any,
      [expandedIncidentData.data],
    );

    const columns1 = [
      {
        id: 'schedule',
        Header: 'SCHEDULE',
        Cell: (cell: Cell<IMaintenanceMode>) => {
          const {
            row: {
              original: { schedule },
            },
          } = cell;

          return <Text fontSize="sm">{schedule}</Text>;
        },
      },
      {
        id: 'maintenanceFrom',
        Header: <Text textAlign="right">TIME</Text>,
        Cell: (cell: Cell<IMaintenanceMode>) => {
          const {
            row: {
              original: { maintenanceFrom, maintenanceTill, schedule },
            },
          } = cell;
          return schedule ? (
            <VStack justifyContent="flex-start" alignItems="flex-start">
              <Text variant="blackAlpha" textAlign="left">
                {moment(maintenanceFrom).format('DD/MM/YYYY hh:mm:s')}
              </Text>
              <Text>to</Text>
              <Text variant="blackAlpha" textAlign="left">
                {moment(maintenanceTill).format('DD/MM/YYYY hh:mm:s')}
              </Text>
            </VStack>
          ) : null;
        },
      },
    ];
    return (
      <VStack w="full">
        {data.length ? (
          <DataTable height="250px" width="100%" data={data} columns={columns1} />
        ) : (
          <Box height="250px" width="100%">
            <IllustrationBox
              msg={
                <PlaceholderText
                  message="No Scheduled Maintenance"
                  tip={
                    'Tip: Schedule your first maintenance to auto-suppress incidents during the specified window.'
                  }
                />
              }
              height="150px"
              width="150px"
              image={MaintenanceIllustration}
              name="maintenance"
            />
          </Box>
        )}
      </VStack>
    );
  };

  const serviceDetail = [
    {
      title: 'Recent Incidents',
      tooltip: 'Shows incidents that have occurred in the last 7 days.',
      actionName: 'View All',
      action: () => history.push(`/incident`),
      contentLoading: expandedIncidentData.isLoading,
      contentSuccess: expandedIncidentData.isSuccess,
      enabled: true,
      content: <RecentIncident />,
    },
    {
      title: 'Automation',
      tooltip:
        'Shows number of deduplicated and suppressed incidents that have occurred in the last 7 days.',
      actionName: 'View All',
      action: () => history.push(`/service-catalog/${serviceID}?rule-tab=deduplication&tab=rules`),
      contentLoading: expandedAutomationData.isLoading,
      contentSuccess: expandedAutomationData.isSuccess,
      enabled: true,
      content: <Automation />,
    },
    {
      title: 'Alert Sources',
      tooltip:
        'Shows number of incidents by the configured alert sources that have occurred overall within your data retention period.',
      actionName: 'Add',
      action: () => history.push(`${addAlertSourceUrl}?service=${serviceID}`),
      enabled: hasUpdate,
      contentLoading: expandedAlertData.isLoading,
      contentSuccess: expandedAlertData.isSuccess,
      content: expandedAlertData.isSuccess ? (
        <AlertSources
          expandedAlertData={expandedAlertData}
          serviceId={serviceID}
          refetchAlert={expandedAlertData.refetch}
        />
      ) : (
        <></>
      ),
    },
    {
      title: 'Maintenance Mode',
      tooltip: 'Shows a series of upcoming and ongoing maintenance windows.',
      actionName: 'Edit',
      action: () => history.push(`/service-catalog?actionType=MAINTENANCE&serviceID=${serviceID}`),
      enabled: hasUpdate,
      contentLoading: expandedMentainenceData.isLoading,
      contentSuccess: expandedMentainenceData.isSuccess,
      content: <MaintenanceMode />,
    },
  ];
  const trackAnalytics = () => AppTracker.track(T_WA_UP_VIEW_DEPENDENCY);
  return (
    <Flex>
      <Box display="flex" flexDirection="column" bg="gray.100" py={3.5} width="100%">
        <Grid templateColumns={{ md: '1fr 1fr', lg: '1fr 1fr 1fr 1fr' }} gap={4}>
          {serviceDetail.map(detail => (
            <Card
              key={detail.title}
              header={
                <Flex justifyContent="space-between" alignItems="center">
                  <Heading as="h5" fontSize={18} fontWeight={400} color="black">
                    {detail.title}{' '}
                    <ServiceTooltip text={detail.tooltip} placement="top">
                      <InfoOutlineIcon w={3.5} h={3.5} mb={1} ml={1} />
                    </ServiceTooltip>
                    {detail.title === 'Alert Sources' &&
                      serviceLastUpdated.isSuccess &&
                      serviceLastUpdated.data.last_updated && (
                        <ServiceTooltip
                          text={`Updated ${moment(serviceLastUpdated.data.last_updated).fromNow(
                            true,
                          )} ago`}
                          placement="top"
                        >
                          <span>
                            <Icon w={3.5} h={3.5} ml={2} as={DelayIcon}></Icon>
                          </span>
                        </ServiceTooltip>
                      )}
                  </Heading>
                  {detail.action ? (
                    <NoPermissionTooltip isDisabled={detail.enabled}>
                      <Button
                        color="default"
                        variant="outline"
                        size="sm"
                        onClick={detail.action}
                        isDisabled={!detail.enabled ?? false}
                      >
                        {detail.actionName}
                      </Button>
                    </NoPermissionTooltip>
                  ) : (
                    <Box p={4} />
                  )}
                </Flex>
              }
              body={
                <Flex justifyContent="center" alignItems="center" gap={4}>
                  {detail.contentSuccess && detail.content}
                  {!detail.contentSuccess &&
                    (detail.contentLoading ? (
                      <Center h="250px" width="100%">
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="md"
                        />
                      </Center>
                    ) : (
                      <DataTable height="250px" width="100%" data={[]} columns={[]} />
                    ))}
                </Flex>
              }
            />
          ))}
        </Grid>
        <Flex mt="5" mr="5" align="flex-end" justifyContent="flex-end">
          <HStack>
            <Button
              variant={'outline'}
              mr={3.5}
              onClick={() => {
                trackAnalytics();
                history.push({
                  pathname: serviceDetailUrl.replace(':serviceId', serviceID),
                  search: qs.stringifyUrl({
                    url: '',
                    query: { tab: 'dependencies' },
                  }),
                });
              }}
            >
              View Dependency
            </Button>
            <Button
              variant={'default'}
              onClick={() => history.push(serviceDetailUrl.replace(':serviceId', serviceID))}
            >
              View Service Details
            </Button>
          </HStack>
        </Flex>
      </Box>
    </Flex>
  );
}

export default ExpandedDetail;
