import { Box, Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { debounce } from 'core';
import { T_WA_UP_GER_DETAIL_PAGE_VIEWED, T_WA_UP_GER_RULE_REORDERED } from 'core/const/tracker';
import useQueryParams from 'core/hooks/useQueryParams';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { queryUpdate } from 'core/util';
import { Divider, ListingButton, NameComponent, Search, Tooltip, useToast } from 'library/atoms';
import { LibraryIcons } from 'library/icons';
import { Loader, Placeholder, Stats, Table } from 'library/molecules';
import { FC, useEffect, useMemo, useRef } from 'react';
import { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { generatePath, useHistory, Link } from 'react-router-dom';
import { AppTracker } from 'shared/analytics/tracker';
import { AppConfig } from 'shared/app.config';
import { SERVICE_DETAILS_PATH } from 'views/main/routes/routes';

import { AppContext } from '../..';
import { BoxLoader } from '../../components';
import { invalidateGERDetail } from '../../query/useGetGerDetail';
import { invalidateGERRulesetList, useGetGerRulesetList } from '../../query/useGetGerRulesetRules';
import { reorderRule } from '../../query/useReorderRule';
import { GERContext } from '../../store';
import { ALERT_API_INFO, DROPDOWN_OPTIONS, ORG, RULESET, RULESET_RULE } from '../../types';
import {
  ADD_CATCH_ALL_RULE_TITLE,
  ADD_RULESET_RULE_PLACEHOLDER_TITLE,
  ADD_RULESET_RULE_TITLE,
  CATCH_ALL_MODE,
  CATCH_ALL_RULE_TITLE,
  EMAIL_ALERT_SRC_SHORTNAME,
  QUERY_PARAM_KEYS,
  RULE_EMAIL_TITLE,
  RULE_ENDPOINT_TITLE,
  RULE_MODE,
  SHIFT_TO,
  getServiceOptions,
} from '../../util';
import { CatchAllRule } from '../catch-all';
import { RuleDetails } from '../rule';
import { clearRuleObj } from '../rule/temp-store';
import { RULESET_COLUMN_DEFS } from './column-defs';

type Props = {
  ruleset: RULESET;
  routingKey: string;
  organization?: ORG;
  gerId: string;
};

export const RulesetList: FC<Props> = ({ ruleset, routingKey, gerId }) => {
  const toast = useToast();
  const context = useContext(AppContext);
  const {
    state: { permissions },
  } = useContext(GERContext);
  const queryClient = useQueryClient();
  const catchAllService: DROPDOWN_OPTIONS | undefined = useMemo(() => {
    const services = getServiceOptions(context?.organization);
    return services.find(service => service.value === ruleset.catch_all_action.route_to);
  }, [context?.organization, ruleset]);

  const { isOpen: isRuleOpen, onOpen: onRuleOpen, onClose: onRuleClose } = useDisclosure();
  const endpointTitle = useMemo(
    () =>
      ruleset.alert_source_shortname === EMAIL_ALERT_SRC_SHORTNAME
        ? RULE_EMAIL_TITLE
        : RULE_ENDPOINT_TITLE,
    [ruleset.alert_source_shortname],
  );

  const {
    isOpen: isCatchAllOpen,
    onOpen: onCatchAllOpen,
    onClose: onCatchAllClose,
  } = useDisclosure();
  const [catchAllMode, setCatchAllMode] = useState(CATCH_ALL_MODE.ADD);
  const [ruleMode, setRuleMode] = useState(RULE_MODE.ADD);
  const [rule, setRule] = useState<RULESET_RULE>();
  const alert: ALERT_API_INFO = {
    version: ruleset.alert_source_version,
    shortName: ruleset.alert_source_shortname,
  };

  const userAccess = useUserAccess();
  const hasUpdate = userAccess.hasUpdateAccess('ger', gerId);

  const [paginationInfo, setPaginationInfo] = useState({
    page: 0,
    limit: 5,
  });

  const handler = (page: number, limit: number) => {
    const isLimitUpdate = paginationInfo.limit != limit;
    if (paginationInfo.page != page || isLimitUpdate) {
      setPaginationInfo({
        page: isLimitUpdate ? 0 : page,
        limit,
      });
    }
  };

  const reorder = (id: string, shiftTo: SHIFT_TO) => {
    reorderRule(ruleset.global_event_rule_id, alert.version, alert.shortName, id, shiftTo)
      .then(() => {
        toast({
          status: 'success',
          text: `Success: Event rule priority updated`,
        });
        AppTracker.track(T_WA_UP_GER_RULE_REORDERED, {
          'GER Ruleset ID': ruleset.id,
        });
        invalidateGERRulesetList(queryClient);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const history = useHistory();
  const query = useQueryParams();
  const ruleQuery = query.get(QUERY_PARAM_KEYS.RULE);

  const [searchTerm, setSearchTerm] = useState<string>();

  const removeRuleFromQuery = () => {
    queryUpdate(query, null, QUERY_PARAM_KEYS.RULE);
    history.push({ search: query.toString() });
  };

  const closeRuleDetails = () => {
    removeRuleFromQuery();
    onRuleClose();
    clearRuleObj();
  };

  const addRule = () => {
    setRule(undefined);
    setRuleMode(RULE_MODE.ADD);
    onRuleOpen();
  };

  const { isFetching, isLoading, rules, count } = useGetGerRulesetList(
    ruleset?.global_event_rule_id,
    ruleset?.alert_source_version,
    ruleset?.alert_source_shortname,
    paginationInfo.page,
    paginationInfo.limit,
    searchTerm,
  );

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setPaginationInfo({
        page: 0,
        limit: paginationInfo.limit,
      });
      setSearchTerm(value);
    }, 1000),
  ).current;

  useEffect(() => {
    AppTracker.track(T_WA_UP_GER_DETAIL_PAGE_VIEWED);
  }, []);

  useEffect(() => {
    if (ruleQuery && !isRuleOpen && Array.isArray(rules)) {
      const matchedRule = rules.find((r: RULESET_RULE) => r.id == ruleQuery);
      if (matchedRule) {
        setRule(matchedRule);
        setRuleMode(RULE_MODE.VIEW);
        onRuleOpen();
      } else {
        removeRuleFromQuery();
      }
    }
  }, [ruleQuery, rules]);

  if (isLoading) {
    return <BoxLoader />;
  }

  const endpoint =
    endpointTitle === RULE_EMAIL_TITLE
      ? `${routingKey}@${AppConfig.ger_url}`
      : `${(AppConfig.api_url as string).toLowerCase()}/global/${ruleset.alert_source_version}/${
          ruleset.alert_source_shortname
        }/${routingKey}`;

  const copyEndpoint = () => {
    navigator.clipboard.writeText(endpoint);
    toast({ status: 'success', text: `${endpointTitle} has been copied!` });
  };

  const openCatchAll = (mode: CATCH_ALL_MODE) => {
    setCatchAllMode(mode);
    onCatchAllOpen();
  };

  const desc = [
    {
      title: endpointTitle,
      description: (
        <Text isTruncated width="16vw" variant="body" color="secondary.1000">
          {endpoint}
        </Text>
      ),
      hoverElement: (
        <Box pr={2}>
          <ListingButton title="Copy" onClick={copyEndpoint} />
        </Box>
      ),
    },
    {
      title: catchAllService ? (
        CATCH_ALL_RULE_TITLE
      ) : (
        <HStack>
          <Text variant="body_800" color="secondary.700">
            {CATCH_ALL_RULE_TITLE}
          </Text>
          {!catchAllService && (
            <Tooltip
              placement="right"
              label="Important: Configure this Catch-All Rule with a service. If other rules don't match, the alert source can reroute to the Catch-All Rule's service as a safety net"
            >
              <HStack bg="pageLevelMessage.background" alignItems="center" px={2} py={1}>
                <Text color="form.invalid" variant="hint">
                  Recommended
                </Text>
                <LibraryIcons.InfoIcon color="secondary.1000" w="13.5px" h="13.5px" />
              </HStack>
            </Tooltip>
          )}
        </HStack>
      ),
      description: (
        <>
          {catchAllService ? (
            <Link
              to={generatePath(SERVICE_DETAILS_PATH, { id: catchAllService.value })}
              style={{ marginTop: 0 }}
            >
              <NameComponent name={catchAllService.label} isHyperlink />
            </Link>
          ) : (
            <>
              <ListingButton
                variant="ghost"
                title={ADD_CATCH_ALL_RULE_TITLE}
                onClick={() => {
                  openCatchAll(CATCH_ALL_MODE.ADD);
                }}
                isDisabled={!hasUpdate}
                isPermissionPresent={hasUpdate}
              />
            </>
          )}
        </>
      ),
      ...(catchAllService && permissions.canUpdate
        ? {
            hoverElement: (
              <HStack gap={2} pr={2}>
                <ListingButton
                  variant="secondary"
                  title="Remove"
                  onClick={() => {
                    openCatchAll(CATCH_ALL_MODE.DELETE);
                  }}
                />
                <ListingButton
                  title="Edit"
                  onClick={() => {
                    openCatchAll(CATCH_ALL_MODE.EDIT);
                  }}
                />
              </HStack>
            ),
          }
        : null),
    },
  ];

  return (
    <Flex flexDir="column" width="full">
      <HStack w="full" pr={6} alignItems="center" justifyContent="space-between">
        <HStack align="flex-start">
          {desc.map((item, index) => {
            return <Stats key={index} {...item} padding={'16px 0px 16px 24px'} />;
          })}
        </HStack>
        <HStack>
          <Box>
            <Search
              onSearch={debouncedSearch}
              searchValue={searchTerm}
              isDisabled={!(searchTerm || count)}
            />
          </Box>
          <Box sx={{ height: '24px' }}>
            <Divider orientation="vertical" />
          </Box>
          <ListingButton
            variant="secondary"
            title={ADD_RULESET_RULE_TITLE}
            onClick={addRule}
            isDisabled={!hasUpdate}
            isPermissionPresent={hasUpdate}
          />
        </HStack>
      </HStack>
      {count && rules ? (
        <Table
          width="100%"
          columns={RULESET_COLUMN_DEFS({
            pageNum: paginationInfo.page,
            limit: paginationInfo.limit,
            count,
            canReorder: permissions.canUpdate,
            reorder,
            alertVersion: ruleset.alert_source_version,
            alertShortName: ruleset.alert_source_shortname,
          })}
          data={rules}
          onPageChange={handler}
          headerStyles={{ padding: '14px 24px 14px 24px' }}
          paginationStyles={{
            position: 'sticky',
            bottom: '0px',
          }}
          isLoading={isFetching}
          totalCount={count}
          pageSize={paginationInfo.limit}
          pageIndex={paginationInfo.page}
        />
      ) : (
        <Box pt={6}>
          <Placeholder
            onCreate={addRule}
            iconName="ger-rules.svg"
            addBtnTitle={permissions.canUpdate ? ADD_RULESET_RULE_PLACEHOLDER_TITLE : ''}
            description="With the alert source(s) added, you can start creating rules for it"
            size="md"
          />
        </Box>
      )}
      <CatchAllRule
        gerId={ruleset.global_event_rule_id}
        routeId={ruleset.catch_all_action.route_to}
        mode={catchAllMode}
        alert={alert}
        isOpen={isCatchAllOpen}
        onSuccess={() => {
          invalidateGERDetail(queryClient);
          onCatchAllClose();
        }}
        onClose={onCatchAllClose}
      />
      <RuleDetails
        gerId={ruleset.global_event_rule_id}
        mode={ruleMode}
        alert={alert}
        isOpen={isRuleOpen}
        rule={rule}
        onClose={closeRuleDetails}
      />
    </Flex>
  );
};
