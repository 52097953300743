import { ChevronDownIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import Snooze from 'components/Snooze';
import { IAppState } from 'core/interfaces/IAppState';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { ListingButton } from 'library/atoms';
import { PriorityButton } from 'library/molecules';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { AnyObject } from 'library/types';
import { Dispatch, SetStateAction } from 'react';

import { useSession } from '../behaviors/Session';
import { INCIDENT_ACTION_ID, INCIDENT_STATUS } from '../common/enums';
import { SelectedIncident } from '../common/types';
import { ListIncidentsQuery } from '../graphql/query';
import useIncidentInlineActions from '../hooks/useIncidentInlineActions';
import { PostmortemCTA } from './PostmortemCTA';

interface Props {
  rows: NonNullable<ListIncidentsQuery['listIncidents']['data']>;
  setInActionBtnId: Dispatch<SetStateAction<INCIDENT_ACTION_ID>>;
  setSelectedIncident: Dispatch<SetStateAction<SelectedIncident>>;
  onSave: () => void;
  onError: (error: any, field?: string) => void;
  incidentData: AnyObject;
  isDisabled: boolean;
  inActionBtnId: INCIDENT_ACTION_ID;
}

const InlineActionButtons = (props: Props) => {
  const { isDisabled, incidentData, inActionBtnId } = props;
  const {
    dropdownTitle,
    ackIncident,
    onPriorityUpdate,
    setAction,
    rowStatus,
    isSnoozed,
    incidentID,
    name,
  } = useIncidentInlineActions(props);
  const {
    session: { orgState: organization },
  } = useSession();

  const userAccess = useUserAccess();

  const hasUpdate = userAccess.hasUpdateAccess('incidents');

  return (
    <Flex gap={5} alignSelf="center" width="max-content">
      {rowStatus === INCIDENT_STATUS.RESOLVED && (
        <PostmortemCTA
          organization={organization as unknown as Pick<IAppState, 'organization'>}
          incidentId={incidentID}
          isDisabled={isDisabled}
          hasPostmortem={incidentData.hasRetrospectives}
        />
      )}
      <PriorityButton
        isLoading={inActionBtnId === INCIDENT_ACTION_ID.INLINE_UPDATE_PRIORITY}
        onClick={onPriorityUpdate}
      />
      {(rowStatus === INCIDENT_STATUS.TRIGGERED || rowStatus === INCIDENT_STATUS.ACKNOWLEDGED) && (
        <Snooze
          isSnoozed={isSnoozed}
          incidentId={incidentID}
          incidentName={name}
          disabled={false}
          screenName={'Incident List'}
          organization={organization as unknown as Pick<IAppState, 'organization'>}
        >
          <ListingButton
            id={INCIDENT_ACTION_ID.SNOOZE_UNSNOOZE}
            title={dropdownTitle}
            style={{ padding: '5px 18px', height: 29, width: 85 }}
            rightIcon={!isSnoozed ? <ChevronDownIcon /> : <></>}
          />
        </Snooze>
      )}
      {rowStatus === INCIDENT_STATUS.ACKNOWLEDGED && (
        <NoPermissionTooltip isDisabled={hasUpdate}>
          <ListingButton
            id={INCIDENT_ACTION_ID.INLINE_RESOLVE}
            title="Resolve"
            isDisabled={isDisabled || !hasUpdate}
            onClick={() => setAction(INCIDENT_ACTION_ID.INLINE_RESOLVE)}
          />
        </NoPermissionTooltip>
      )}
      {rowStatus === INCIDENT_STATUS.TRIGGERED && (
        <NoPermissionTooltip isDisabled={hasUpdate}>
          <ListingButton
            id={INCIDENT_ACTION_ID.INLINE_ACKNOWLEDGE}
            title="Acknowledge"
            isDisabled={isDisabled || !hasUpdate}
            isLoading={inActionBtnId === INCIDENT_ACTION_ID.INLINE_ACKNOWLEDGE}
            onClick={() => ackIncident()}
          />
        </NoPermissionTooltip>
      )}
    </Flex>
  );
};

export default InlineActionButtons;
