import * as React from 'react';
import { SVGProps } from 'react';

const SvgKibanaLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M2.2 7.5V18l7.5-8.6C7.4 8.2 4.8 7.5 2.2 7.5z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#343741',
      }}
    />
    <path
      d="M2.2 0v7.5c2.6 0 5.2.7 7.5 1.9L17.8 0H2.2z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#f04e98',
      }}
    />
    <path
      d="M11.2 10.4 2.9 20h14.6c-.8-3.9-3-7.3-6.3-9.6z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#00bfb3',
      }}
    />
  </svg>
);

export default SvgKibanaLogo20X20;
