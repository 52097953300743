import * as React from 'react';
import { SVGProps } from 'react';

const SvgWavefrontTanzuVmWareLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__svg20006"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.Wavefront-Tanzu(VMWare)_Logo-20x20_svg__st1{fill:#1bc1d5}.Wavefront-Tanzu(VMWare)_Logo-20x20_svg__st2{fill:#78be20}.Wavefront-Tanzu(VMWare)_Logo-20x20_svg__st3{fill:#1d428a}'
      }
    </style>
    <path
      id="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__path263"
      d="M13.032 17.495h-6.1a.801.801 0 0 1-.615-.283L2.49 12.534a.734.734 0 0 1-.141-.674l1.48-5.73a.92.92 0 0 1 .398-.492L9.64 2.996a.78.78 0 0 1 .692 0l5.488 2.639a.816.816 0 0 1 .432.53l1.407 5.73a.752.752 0 0 1-.142.674l-3.869 4.644a.912.912 0 0 1-.616.282zm-6.065-.775h6.03l3.862-4.678-1.407-5.73L10 3.667 4.585 6.306l-1.445 5.73 3.827 4.684z"
      style={{
        fill: '#0091da',
      }}
    />
    <path
      id="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__path265"
      className="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__st1"
      d="m16.967 4.547.685-.563L10.397.499v.88l6.57 3.168z"
    />
    <path
      id="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__path267"
      className="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__st2"
      d="m1.048 12.78-.868.176 5.055 6.195.398-.775-4.585-5.596z"
    />
    <path
      id="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__path269"
      className="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__st1"
      d="M9.602 1.378v-.88L2.42 3.984l.685.563 6.497-3.169z"
    />
    <path
      id="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__path271"
      className="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__st3"
      d="m2.635 5.214-.723-.6L0 12.18l.902-.175 1.733-6.791z"
    />
    <path
      id="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__path273"
      className="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__st3"
      d="m19.097 12.005.903.175-1.84-7.566-.688.566 1.625 6.825z"
    />
    <path
      id="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__path275"
      className="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__st2"
      d="m18.918 12.78-4.623 5.595.398.775 5.128-6.195-.903-.175z"
    />
    <path
      id="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__path277"
      className="Wavefront-Tanzu(VMWare)_Logo-20x20_svg__st1"
      d="m6.355 18.692-.398.809h8.015l-.398-.809H6.355z"
    />
  </svg>
);

export default SvgWavefrontTanzuVmWareLogo20X20;
