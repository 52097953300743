import { Box, Flex, Text } from '@chakra-ui/react';
import { T_WA_UP_SG_SEARCHED } from 'core/const/tracker';
import { Search } from 'library/atoms';
import { Placeholder } from 'library/molecules';
import { FC, useEffect, useState } from 'react';
import { AppTracker } from 'shared/analytics/tracker';

import { SERVICE_NODE_TYPE } from '../../Interfaces/graph';
import { ServicePlaceholder } from './placeholder';
import { ServiceEntity } from './ServiceEntity';

type Props = {
  services: SERVICE_NODE_TYPE[];
  onSelect: (node: SERVICE_NODE_TYPE) => void;
  serviceType: string; //used for mixpanel tracking and placeholder text only
};

export const ServicesList: FC<Props> = ({ services, onSelect, serviceType }) => {
  const [servicesListData, setServicesListData] = useState<SERVICE_NODE_TYPE[]>([]);
  const [filteredListData, setFilteredListData] = useState<SERVICE_NODE_TYPE[]>([]);

  useEffect(() => {
    const sortedList = services.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
    );
    setServicesListData(sortedList);
    setFilteredListData(sortedList);
  }, [services]);

  const updateList = (val = '', list = servicesListData) => {
    if (val) {
      const filteredList = list.filter(item => item.name?.toLowerCase().includes(val));
      setFilteredListData(filteredList);
      AppTracker.track(T_WA_UP_SG_SEARCHED, {
        'Service Type': serviceType,
      });
    } else {
      setFilteredListData(list);
    }
  };

  const filterData = (val: string) => {
    updateList(val.toLowerCase());
  };

  return (
    <>
      {servicesListData.length ? (
        <>
          <Box display="flex" flexDir="column" rowGap={2}>
            <Box pl={8} pt={4} pb={2} width="calc(100% - 40px)">
              <Search onSearch={filterData} hasAutoFocus={false} showSearchBarOnly />
            </Box>
            <Flex flexDir="column">
              <Box
                mt="0px !important"
                bgColor="secondary.150"
                width="100%"
                px={8}
                py={3}
                borderBottomWidth="1px"
                borderColor="secondary.200"
              >
                <Text variant="hint_800" color="secondary.700">
                  SERVICE NAME
                </Text>
              </Box>
              {filteredListData.length ? (
                <>
                  {filteredListData.map(serviceObj => (
                    <Box
                      key={serviceObj.id}
                      borderBottom={1}
                      borderStyle="solid"
                      borderBottomColor="secondary.200"
                      cursor="pointer"
                      onClick={() => onSelect(serviceObj)}
                    >
                      <ServiceEntity serviceData={serviceObj} />
                    </Box>
                  ))}
                </>
              ) : (
                <ServicePlaceholder description="No matches found" />
              )}
            </Flex>
          </Box>
        </>
      ) : (
        <ServicePlaceholder description={`No ${serviceType} service available`} />
      )}
    </>
  );
};
