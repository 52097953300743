import { IIncidentOverviewResponse } from 'core/interfaces/IIncidents';
import { THEME_COLORS } from 'library/theme/colors';
import { Para } from 'uie/components';
import useUINavigationFunctions from 'views/main/organization/navigation-flows/useUINavigationFunctionV2';

import IncidentSummarySection from '../Section';
import IncidentSummaryParticipantProfile from '../ParticipantProfile';

interface IProps {
  service: IIncidentOverviewResponse['service'];
}

const IncidentSummaryImpact: React.FC<IProps> = (props: IProps) => {
  const { getEntityComponent } = useUINavigationFunctions();

  return (
    <IncidentSummarySection heading="IMPACT">
      {getEntityComponent({
        componentType: 'linkWPopover',
        id: props.service.id,
        name: props.service.name,
        type: 'service',
      })}
      <Para
        fontSize={16}
        fontWeight={400}
        style={{
          color: THEME_COLORS.secondary[1000],
          marginTop: 6,
          marginBottom: 6,
          lineHeight: '24px',
        }}
      >
        Owned By
      </Para>
      <IncidentSummaryParticipantProfile
        id={props.service.owner.id}
        username={props.service.owner.username}
        name={props.service.owner.name}
        type={props.service.owner.type}
      />
    </IncidentSummarySection>
  );
};

export default IncidentSummaryImpact;
