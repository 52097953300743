import {
  REQUEST_ORG_SERVICES,
  REQUEST_ORG_SERVICES_SUCCESS,
  REQUEST_ORG_SERVICES_FAIL,
} from '../../const/organization';
import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';
import { IService } from '../../interfaces/IService';

const initialOrganizationServices = INITIAL_STATE.organization.services;

const organizationServices = (state = initialOrganizationServices, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ action: RESET_STATE, s: [], error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_SERVICES:
      return {
        ...state,
        ...{ action: REQUEST_ORG_SERVICES, error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_SERVICES_SUCCESS:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_SERVICES_SUCCESS,
          s: action.payload.services.sort((a: IService, b: IService) =>
            a.name.localeCompare(b.name),
          ),
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ORG_SERVICES_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_SERVICES_FAIL,
          s: [],
          error: { type: REQUEST_ORG_SERVICES_FAIL, reason: action.payload },
        },
      };
    default:
      return state;
  }
};

export default organizationServices;
