import { Textarea as ChakraTextarea, TextareaProps } from '@chakra-ui/react';
import { ChangeEventHandler, forwardRef, Ref } from 'react';

type Props = Omit<TextareaProps, 'variant' | 'errorBorderColor' | 'focusBorderColor'> & {
  placeholder: string;
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  isDisabled: boolean;
  isInvalid: boolean;
  isRequired: boolean;
  resize: 'horizontal' | 'vertical' | 'none';
};

export const Textarea = forwardRef(
  (
    {
      isDisabled,
      placeholder,
      isInvalid,
      isRequired,
      resize,
      value: val,
      onChange,
      ...rest
    }: Partial<Props>,
    ref: Ref<HTMLTextAreaElement>,
  ) => {
    return (
      <ChakraTextarea
        ref={ref}
        focusBorderColor="#1B7FF1"
        placeholder={placeholder ?? 'Enter text here'}
        isDisabled={isDisabled}
        value={val}
        onChange={onChange}
        isInvalid={isInvalid}
        isRequired={isRequired}
        resize={resize ?? 'vertical'}
        {...rest}
      />
    );
  },
);
