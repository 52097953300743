import axios from 'axios';
import { EntityACLMeta } from 'core/userAccess/types';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';
import { API } from '../api';
import { IEscalationPolicy } from '../interfaces/IEscalationPolicy';
import { ITeamEntityBasicResponse, ITeamOwner } from '../interfaces/ITeams';

class EscalationService {
  _api = `${API.config.batman}/organizations/${API.config.organizationId}/escalation-policies`;

  getAll = async (teamId: string, filterText: string) => {
    const response = await axios.get<{
      data: IEscalationPolicy[];
      meta: { acl: Record<string, EntityACLMeta> };
    }>(`${this._api}?owner_id=${teamId}&entity_owner=${filterText}`);
    setUserAccessStore({
      entityACLMap: {
        ...getUserAccessStore().entityACLMap,
        escalation_policies: {
          ...getUserAccessStore().entityACLMap?.escalation_policies,
          ...response.data.meta.acl,
        },
      },
    });
    return response;
  };

  create = (payload: Partial<IEscalationPolicy>) =>
    axios.post<IEscalationPolicy>(this._api, {
      owner_id: API.config.teamId,
      ...payload,
    });

  update = (id: string, payload: Partial<IEscalationPolicy>) =>
    axios.post<IEscalationPolicy>(`${this._api}/${id}`, payload);

  delete = (id: string) => axios.delete(`${this._api}/${id}`);

  public transferOwnership = (id: string, payload: ITeamOwner) =>
    axios.put<{ data: ITeamEntityBasicResponse }>(`${this._api}/${id}/owner`, payload);
}

export default EscalationService;
