import { VStack, Text, HStack } from '@chakra-ui/react';
import { AssigneeAvatar } from 'library/atoms';
import { AssigneeType } from 'library/enums';
import moment from 'moment';
import { useMemo } from 'react';
import { JSONCodeBlock } from 'uie/components';
import { capitalizeFirstLetter } from '../../helpers';
import { getAssigneeComponent } from '../../helpers/utils';
import { DrawerCellProps, DRAWER_TYPE } from '../../types/index.auditLogs';

function DrawerCell(props: DrawerCellProps) {
  const { title, body, type } = props;

  const renderBody = useMemo(() => {
    switch (type) {
      case DRAWER_TYPE.GENERIC:
        const lines = body.split('\n');
        if (lines?.length) {
          return lines.map((line: string, index: number) => (
            <Text key={index} style={{ marginTop: 10 }} variant="body">
              {capitalizeFirstLetter(line)}{' '}
            </Text>
          ));
        } else {
          return <Text>-</Text>;
        }
      case DRAWER_TYPE.LINK: {
        if (body?.id) {
          if (body?.isDeleted) {
            <Text variant="body" style={{ marginTop: 10 }}>
              Deleted Team
            </Text>;
          } else {
            return (
              <HStack style={{ marginTop: 10 }}>
                <AssigneeAvatar name={body.name} type={AssigneeType.Team} />
                {getAssigneeComponent(body.id, AssigneeType.Team, body.name)}
              </HStack>
            );
          }
        }
        return (
          <Text variant="body" style={{ marginTop: 10 }}>
            {body.name.length ? capitalizeFirstLetter(body?.name) : '-'}
          </Text>
        );
      }
      case DRAWER_TYPE.ASSIGNEE:
        if (body.userID.trim()?.length) {
          return (
            <HStack style={{ marginTop: 10 }}>
              <AssigneeAvatar name={body.fullName} type={AssigneeType.User} />
              {getAssigneeComponent(body.userID, AssigneeType.User, body.fullName)}
            </HStack>
          );
        }
        return (
          <Text style={{ marginTop: 10 }} variant="body">
            {'-'}
          </Text>
        );
      case DRAWER_TYPE.TIMESTAMP:
        return (
          <Text variant="body" style={{ marginTop: 10 }}>
            {body?.length
              ? moment(body, 'YYYY-MM-DD HH:mm:ss.SSSSSS Z').format('YYYY-MM-DD hh:mm:ss')
              : '-'}
          </Text>
        );
      case DRAWER_TYPE.META:
        if (body) {
          return (
            <JSONCodeBlock
              code={body || {}}
              enableSearch={false}
              shellProps={{
                minHeight: '300px',
                minWidth: 'calc(100% - 15px)',
              }}
            />
          );
        }
        return <Text variant="body">{'-'}</Text>;
    }
  }, [body]);

  return (
    <VStack alignItems={'flex-start'} p={3} borderBottom={'1px solid #E2E8F0'}>
      <Text variant="body">{title.toUpperCase()}</Text>
      {renderBody}
    </VStack>
  );
}

export default DrawerCell;
