import { Circle, HStack, Text, useBoolean, VStack } from '@chakra-ui/react';
import { AlertIcon } from 'library/icons';
import { THEME_COLORS } from 'library/theme/colors';
import { FC } from 'react';

import { OverlayModal } from '../common';

type Props = {
  name: string;
  entityNames: string[];
  isOpen: boolean;
  onClose: () => void;
  entityType?: string;
};

const DEF_LAST_INDEX = 4;

const EntityName = ({ name }: { name: string }) => (
  <Text fontWeight={800} fontSize="14.5px" noOfLines={1} color={THEME_COLORS.secondary[700]}>
    {name}
  </Text>
);

export const ServiceDeleteErrorInfo: FC<Props> = ({
  name,
  entityNames,
  isOpen,
  onClose,
  entityType,
}) => {
  const list = entityNames.slice(0, DEF_LAST_INDEX + 1);
  const [showAll, setShowAll] = useBoolean();
  const heading = entityType ?? 'Global Event Rulesets ';

  const onCancel = () => {
    setShowAll.off();
    onClose();
  };

  return (
    <OverlayModal
      isOpen={isOpen}
      enableOverlay
      title={
        <HStack>
          <AlertIcon width="24px" height="24px" /> <Text fontSize="16px">Delete Service</Text>
        </HStack>
      }
      content={
        <VStack justifyContent="center" mt={4} alignItems="flex-start">
          <Text color={THEME_COLORS.secondary[700]} fontSize="14.5px">
            {<span style={{ fontWeight: 800 }}>{name}</span>} is linked to the {heading} listed
            below and cannot be deleted
          </Text>
          {!!list.length &&
            list.map((name, index) => (
              <HStack key={name}>
                <EntityName name={name} />
                {index === DEF_LAST_INDEX && !!entityNames.length && !showAll && (
                  <Circle
                    cursor="pointer"
                    size="19px"
                    bg={THEME_COLORS.primary[400]}
                    onClick={setShowAll.on}
                  >
                    <Text fontSize="10.5px" color={THEME_COLORS.secondary[1000]}>
                      +{entityNames.length}
                    </Text>
                  </Circle>
                )}
              </HStack>
            ))}
          {!!entityNames.length && showAll && (
            <>
              {entityNames.map(name => (
                <EntityName key={name} name={name} />
              ))}
            </>
          )}
        </VStack>
      }
      disableFooter
      onClose={onCancel}
    />
  );
};
