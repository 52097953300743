import React, { useEffect } from 'react';
import { Box, ChakraProvider, Grid, VStack } from '@chakra-ui/react';
import HelpAndGuide from './HelpAndGuide';
import { useHistory } from 'react-router-dom';
import { WORKFLOWS_PATH } from 'views/main/routes/routes';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'views/shared/components';
import { Layout, PageHeader } from 'library/molecules';

import libraryTheme from 'library/theme';
import { IAppState } from 'core/interfaces/IAppState';
interface Props {
  children: React.ReactNode;
  title?: string;
}
function WorkflowProviderWrapper({ title, children }: Props) {
  const history = useHistory();
  const teamAction = useSelector((state: IAppState) => state.organization.selectedTeam);

  useEffect(() => {
    if (teamAction.action === 'REQUEST_ORG_SELECTED_TEAM_CHILDREN') {
      history.push(WORKFLOWS_PATH);
    }
  }, [teamAction.teamId]);

  return (
    <ChakraProvider theme={libraryTheme}>
      <ErrorBoundary>
        <Layout>
          <Grid templateColumns="2.25fr 1fr" gap={3} overflowY="auto" h="inherit">
            <VStack alignItems="flex-start" bg="container.background">
              <PageHeader
                title={title}
                noButton
                desc="Workflows are pre-configured sequence of actions that are performed when certain conditions are met."
              />
              <Box px={6} w="full" h="full">
                {children}
              </Box>
            </VStack>
            <HelpAndGuide />
          </Grid>
        </Layout>
      </ErrorBoundary>
    </ChakraProvider>
  );
}

export default WorkflowProviderWrapper;
