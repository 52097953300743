import { IRunbookList } from 'core/interfaces/ITask';
import {
  REQUEST_INCIDENT_DETAIL_RUNBOOK,
  REQUEST_INCIDENT_DETAIL_RUNBOOK_SUCCESS,
  REQUEST_INCIDENT_DETAIL_RUNBOOK_FAIL,
} from '../../const/organization';

const requestIncidentDetailRunbook = (incidentId: string) => ({
  type: REQUEST_INCIDENT_DETAIL_RUNBOOK,
  payload: { incidentId },
});

const requestIncidentDetailRunbookSuccess = (runbookList: IRunbookList[]) => ({
  type: REQUEST_INCIDENT_DETAIL_RUNBOOK_SUCCESS,
  payload: {
    runbookList,
  },
});

const requestIncidentDetailRunbookFail = (error: any) => ({
  type: REQUEST_INCIDENT_DETAIL_RUNBOOK_FAIL,
  payload: {
    error,
  },
});

export {
  requestIncidentDetailRunbook,
  requestIncidentDetailRunbookSuccess,
  requestIncidentDetailRunbookFail,
};
