import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API } from '../../../core/api';
import { NULL_ORG, NULL_PASSWORD } from '../../../core/const/auth';
import { T_WA_GS_FIRST_SIGN_IN, T_WA_GS_UPDATE_BUTTON_CLICKED } from '../../../core/const/tracker';
import { exception } from '../../../core/exception';
import { IOnboardReq } from '../../../core/interfaces/IAccount';
import { IAppState } from '../../../core/interfaces/IAppState';
import { IComponentErrorState, IComponentState } from '../../../core/interfaces/IComponentState';
import { AuthService, HelperService } from '../../../core/services';
import { AppTracker } from '../../../shared/analytics/tracker';
import { dialCodes } from '../../../store/countryCodes';
import { CommonAuth } from '../../auth/commonAuth';
import { render } from './render.index';
import { requestUserLogout } from '../../../core/actions/auth';

type IOnboardState = 'onboard' | 'onboard-invite' | 'noorg' | 'deactivated' | 'back';

//type IProps = Pick<IAppState, 'userInfo' | 'userOrganizations' | 'organization' | 'auth'>;

interface IProps
  extends Pick<IAppState, 'userInfo' | 'userOrganizations' | 'organization' | 'auth'> {
  requestUserLogout: typeof requestUserLogout;
}

interface IState {
  appState: IOnboardState;
  componentState: IComponentState;
  cannotChangeOrg: boolean;
  firstName: string;
  lastName: string;
  dialCode: string;
  mobile: string;
  organization: string;
  organizationID: string;
  countryCodeSearch: string;
  password: string;
  showPassword: boolean;
  errors: IComponentErrorState;
  onBoardError: string;
  trialSignup: boolean;
}

export class OnBoarding extends Component<IProps, IState> {
  private _authService = new AuthService();
  private _helperService = new HelperService();

  constructor(props: IProps) {
    super(props);

    const { d: userInfo } = this.props.userInfo;
    const { o: organizations } = this.props.userOrganizations;
    let appState: IOnboardState = 'onboard';

    let modState = {
      firstName: '',
      lastName: '',
    };

    try {
      if (!userInfo!.first_name || userInfo!.time_zone === '') {
        if (props.userOrganizations.o.length > 0) {
          modState = {
            firstName: userInfo!.first_name,
            lastName: userInfo!.last_name,
          };
          appState = 'onboard-invite';
        } else {
          appState = 'onboard';
        }
      } else {
        if (organizations.length === 0) {
          appState = 'noorg';
        } else {
          if (organizations.every(o => o.deActivated)) {
            appState = 'deactivated';
          }
        }
      }
      console.log('App State: ' + appState);
    } catch (_) {
      appState = 'back';
    }

    this.state = {
      appState,
      componentState: 'idle' as IComponentState,
      cannotChangeOrg: false,
      dialCode: '+1',
      mobile: '',
      organization: '',
      organizationID: '',
      countryCodeSearch: '',
      password: '',
      showPassword: false,
      errors: {},
      onBoardError: '',
      trialSignup: userInfo?.is_trial_signup || false,
      ...modState,
    };
  }

  componentDidMount = async () => {
    try {
      const { country_name } = await this._helperService.getIp();

      if (country_name.length > 0) {
        const dial = dialCodes.find(
          ({ name }) => name.toLowerCase() === country_name.toLowerCase(),
        );

        this.setState({
          dialCode: dial!.dial_code,
        });
      }
      AppTracker.track(T_WA_GS_FIRST_SIGN_IN);
      AppTracker.people({ 'First Sign In Time': new Date().toISOString() });
    } catch (_) {}
  };

  public onValueChange =
    (key: 'firstName' | 'lastName' | 'dialCode' | 'mobile' | 'organization' | 'password') =>
    (e: React.ChangeEvent<any>, v?: any) => {
      const value = key === 'dialCode' ? v : e.target.value;

      const newState: any = {
        [key]: value,
        errors: {},
      };

      this.setState(newState);
    };

  public searchValueChange = (value: string) => {
    this.setState({ countryCodeSearch: value });
  };

  public isValid = () => {
    const { firstName, lastName, organization, mobile, appState, password } = this.state;
    const errors: any = {};

    if (firstName.trim() === '') {
      errors.firstName = 'First name is required';
    }

    if (appState === 'onboard' && organization.trim() === '') {
      errors.organization = 'Organization name is required';
    }

    if (appState === 'onboard-invite' && (password === '' || password.length < 10)) {
      errors.password = 'Password should be atleast 10 characters';
    }

    if (
      mobile.trim() !== '' &&
      (isNaN(parseInt(mobile, 10)) || mobile.length < 4 || mobile.length > 15)
    ) {
      errors.mobile = 'Valid mobile number is required';
    }

    this.setState({ errors });

    return Object.entries(errors).length === 0;
  };

  onboard = async (trial: boolean) => {
    this.setState({ componentState: 'busy' });

    try {
      const { firstName, lastName, organization, dialCode, mobile, appState, password } =
        this.state;

      const organizationName = appState === 'onboard-invite' ? NULL_ORG : organization;
      const newPassword = appState === 'onboard-invite' ? password : NULL_PASSWORD;

      const payload: IOnboardReq = {
        first_name: firstName,
        last_name: lastName,
        organization: organizationName,
        time_zone: (moment as any).tz.guess(),
        password: newPassword,
        trialMode: trial,
        contact: {
          dial_code: mobile.length > 0 ? dialCode : '',
          phone_number: mobile,
        },
      };

      const {
        data: { result, type, message, organizationID },
      } = await this._authService.onBoard(payload);

      this.setState({ organizationID: organizationID || API.config.organizationId });
      CommonAuth.clearDb();
      window.location.reload();
      AppTracker.track(T_WA_GS_UPDATE_BUTTON_CLICKED);
    } catch (err: any) {
      this.setState({
        onBoardError: exception.handle('E_ONBOARD_INITIAL', err),
      });
    } finally {
      this.setState({
        componentState: 'idle',
      });
    }
  };

  onSubmitFreePlanOnboard = async (e?: any) => {
    if (e) {
      e.preventDefault();
    }

    if (!this.isValid()) {
      return;
    }
    this.onboard(false);
  };

  onSubmitOnboard = async (e?: any) => {
    if (e) {
      e.preventDefault();
    }

    if (!this.isValid()) {
      return;
    }
    this.onboard(true);
  };

  public onSubmitNewOrg = async (e?: any) => {
    if (e) {
      e.preventDefault();
    }

    const { organization } = this.state;

    if (organization === '') {
      this.setState({
        errors: {
          organization: 'Organization Name is required',
        },
      });
      return;
    }

    this.setState({ componentState: 'busy' });

    try {
      await this._authService.createOrganization(organization, this.state.trialSignup);
      window.location.reload();
    } catch (err: any) {
      this.setState({
        errors: {
          onBoardError: exception.handle('E_ONBOARD_ORGANIZATION_NULL', err),
        },
      });
    } finally {
      this.setState({ componentState: 'idle' });
    }
  };

  toggleShowPassword = (e: any) => {
    e.preventDefault();
    this.setState({ showPassword: !this.state.showPassword });
  };

  public render = render;
}

export default connect(
  (state: IAppState) => ({
    userInfo: state.userInfo,
    userOrganizations: state.userOrganizations,
    organization: state.organization,
    auth: state.auth,
  }),
  {
    requestUserLogout,
  },
)(OnBoarding);
