import React from 'react';
import { Flex } from '@chakra-ui/react';
import Select from 'components/chakra/Select';
import { DragHandleIcon } from '@chakra-ui/icons';
import ParticipantOptionLabel from './participantOptionLabel';
import { rotationsCustomizePattern } from '../../constants/rotations.customize';
import { ParticipantValue } from './participantMultiValue';

const ParticipantGroupContainer = ({
  options,
  onChange,
  groupNumber,
  defaultValue,
  groupRef,
  showDragIcon,
  hideGroupIndicator,
  menuPlacement,
  isDisabled,
  disableSelection,
  showTZOffsetOnHover,
  ...rest
}: any) => {
  const MultiValue = ParticipantValue({ componentType: 'multi', showTZInTooltip: true });
  return (
    <Flex width="inherit" ref={groupRef} {...rest}>
      {!hideGroupIndicator && (
        <Flex
          w="6vh"
          className="group-indicator"
          h="inherit"
          background="gray.160"
          alignItems="center"
          borderRadius="6px 0px 0px 6px"
          justifyContent="center"
          _hover={{ '& .drag-icon': { display: 'inline' } }}
        >
          {groupNumber}
          {showDragIcon ? (
            <Flex display="none" ml={1.5} className="drag-icon">
              <DragHandleIcon ml={0} color="blue.900" pointerEvents="none" />
            </Flex>
          ) : (
            ''
          )}
        </Flex>
      )}
      <Select
        name="participants"
        inputId="participants"
        value={defaultValue}
        options={options}
        onChange={onChange}
        components={showTZOffsetOnHover ? { MultiValue } : {}}
        placeholder={rotationsCustomizePattern.placeholders.participants}
        formatOptionLabel={ParticipantOptionLabel}
        closeMenuOnSelect
        isMulti
        hasInputAddon={!hideGroupIndicator}
        hideDropdownArrow
        isClearable={isDisabled ?? true}
        isDisabled={isDisabled ?? disableSelection ?? false}
        inheritControlBG={isDisabled ?? false}
        inheritMultiValueBG={isDisabled ?? false}
        disableMultiValueRemove={isDisabled ?? disableSelection ?? false}
        disableControlBorder={isDisabled ?? false}
        menuPlacement={menuPlacement}
      />
    </Flex>
  );
};

export default ParticipantGroupContainer;
