import { Box, Text } from '@chakra-ui/react';
import { useCreateEntityMap, transformMessage } from 'library/atoms/MdBlock/helper';
import { FC, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { SlackUserAction } from 'views/main/organization/workflow/types';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import { useGetSlackChannelUsers } from 'views/main/organization/settings/integrations/slack-v2/hooks/getSlackChannelsUsers';

interface IProps {
  action: SlackUserAction;
}
const MessageUserCard: FC<IProps> = ({ action }) => {
  const entities = useCreateEntityMap();
  const { data: slackUsers } = useGetSlackChannelUsers();

  const slackUser = slackUsers
    ?.filter(user => user.id === action.data.member_id)
    .map(user => {
      return user.profile.display_name_normalized;
    });
  return (
    <>
      <Box py={4}>
        <Text color="secondary.700" textStyle="bold_800" variant="h3">
          User
        </Text>
        <Text>{slackUser}</Text>
      </Box>
      <Box>
        <Text color="secondary.700" textStyle="bold_800" variant="h3">
          Message
        </Text>
        <ReactMarkdown className="mde" plugins={[breaks, gfm]}>
          {transformMessage(action.data.message.replace(/ /g, '\u00A0'), entities)}
        </ReactMarkdown>
      </Box>
    </>
  );
};

export default MessageUserCard;
