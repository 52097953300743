import { Dispatch } from 'react';
import { PROGRESS_STEPPER_TYPE } from 'views/shared/types';
import { AnyObject } from 'yup/lib/types';
import { WEBHOOK_METHODS } from './shared';
import { IWebhookContext, WEBHOOK_ACTIONS } from './store';

export interface WebhookContextType {
  state: IWebhookContext;
  dispatch: Dispatch<{ type: WEBHOOK_ACTIONS; payload: AnyObject }>;
}

export type WEBHOOK_STEPPER_PROPTYPE = {
  onSave: () => void;
  onCancel: () => void;
};

export type URL_TYPE = {
  method: string;
  url: string;
};

export type HEADER_TYPE = {
  key: string;
  value: string;
};

export enum WEBHOOK_STEPPER_INDEX {
  ADD_DETAILS,
  CHOOSE_TYPE,
  CHOOSE_PAYLOAD,
}

export enum WEBHOOK_TYPES {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
}

export enum TEAM_OPTIONS {
  ALL_TEAMS = 'all',
  SELECT_TEAMS = 'select',
}

export enum PAYLOAD_TYPES {
  STANDARD = 'standard',
  CUSTOM = 'custom',
}

export const WEBHOOK_STEPPER: PROGRESS_STEPPER_TYPE[] = [
  {
    title: 'Add Webhook Details',
    index: WEBHOOK_STEPPER_INDEX.ADD_DETAILS + 1,
  },
  {
    title: 'Choose Webhook Type',
    index: WEBHOOK_STEPPER_INDEX.CHOOSE_TYPE + 1,
  },
  {
    title: 'Configure Payload',
    index: WEBHOOK_STEPPER_INDEX.CHOOSE_PAYLOAD + 1,
  },
];

export const WEBHOOK_TYPES_OBJ = [
  {
    value: 'manual',
    title: 'Manual Webhook',
    description: 'Manually trigger Webhooks under incidents, on demand',
  },
  {
    value: 'automatic',
    title: 'Automatic Webhook',
    description: 'Automatically trigger Webhooks when the configured conditions match',
  },
];

export const VERSION_OPTIONS = [
  { value: 'v1', label: 'Version 1 (V1)' },
  { value: 'v2', label: 'Version 2 (V2)' },
];

export const TRIGGER_OPTIONS = [
  {
    label: 'Incident Triggered',
    event_class: 'incident',
    event_type: 'triggered',
    value: 0,
  },
  {
    label: 'Incident Acknowledged',
    event_class: 'incident',
    event_type: 'acknowledged',
    value: 1,
  },
  {
    label: 'Incident Resolved',
    event_class: 'incident',
    event_type: 'resolved',
    value: 2,
  },
  {
    label: 'Incident Reassigned',
    event_class: 'incident',
    event_type: 'reassigned',
    value: 3,
  },
  {
    label: 'Communication Channel Created',
    event_class: 'communication_channel',
    event_type: 'created',
    value: 4,
  },
  {
    label: 'Communication Channel Updated',
    event_class: 'communication_channel',
    event_type: 'updated',
    value: 5,
  },
  {
    label: 'Communication Channel Deleted',
    event_class: 'communication_channel',
    event_type: 'deleted',
    value: 6,
  },
  {
    label: 'Priority Updated',
    event_class: 'incident',
    event_type: 'priority_updated',
    value: 7,
  },
  {
    label: 'Incident Notes Created',
    event_class: 'incident_notes',
    event_type: 'created',
    value: 8,
  },
  {
    label: 'Incident Notes Updated',
    event_class: 'incident_notes',
    event_type: 'updated',
    value: 9,
  },
  {
    label: 'Incident Notes Deleted',
    event_class: 'incident_notes',
    event_type: 'deleted',
    value: 10,
  },
  {
    label: 'Incident Notes Starred',
    event_class: 'incident_notes',
    event_type: 'starred',
    value: 11,
  },
  {
    label: 'Incident Notes Unstarred',
    event_class: 'incident_notes',
    event_type: 'unstarred',
    value: 12,
  },
  {
    label: 'Incident Tags Updated',
    event_class: 'incident_tags',
    event_type: 'updated',
    value: 13,
  },
  {
    label: 'Incident Task Created',
    event_class: 'incident_task',
    event_type: 'created',
    value: 14,
  },
  {
    label: 'Incident Task Updated',
    event_class: 'incident_task',
    event_type: 'updated',
    value: 15,
  },
  {
    label: 'Incident Task Deleted',
    event_class: 'incident_task',
    event_type: 'deleted',
    value: 16,
  },
  {
    label: 'Incident Task Completed',
    event_class: 'incident_task',
    event_type: 'completed',
    value: 17,
  },
  {
    label: 'Incident Task Uncompleted',
    event_class: 'incident_task',
    event_type: 'uncompleted',
    value: 18,
  },
  {
    label: 'Postmortem Created',
    event_class: 'postmortem',
    event_type: 'created',
    value: 19,
  },
  {
    label: 'Postmortem Updated',
    event_class: 'postmortem',
    event_type: 'updated',
    value: 20,
  },
  {
    label: 'Postmortem Deleted',
    event_class: 'postmortem',
    event_type: 'deleted',
    value: 21,
  },
  {
    label: 'SLO-Violating Incident Created',
    event_class: 'slo_violating_incident',
    event_type: 'created',
    value: 22,
  },
  {
    label: 'SLO-Violating Incident Marked False Positive',
    event_class: 'slo_violating_incident',
    event_type: 'false_positive_marked',
    value: 23,
  },
  {
    label: 'SLO-Violating Incident Unmarked False Positive',
    event_class: 'slo_violating_incident',
    event_type: 'false_positive_unmarked',
    value: 24,
  },
  {
    label: 'SLO-Violating Incident Spent Error Budget',
    event_class: 'slo_violating_incident',
    event_type: 'error_budget_spent_updated',
    value: 25,
  },
  {
    label: 'StatusPage Updated',
    event_class: 'statuspage',
    event_type: 'updated',
    value: 26,
  },
];

export const EMPTY_URL_OBJ: URL_TYPE = {
  method: WEBHOOK_METHODS[0].toLowerCase(),
  url: '',
};

export const EMPTY_HEADER_OBJ: HEADER_TYPE = {
  key: '',
  value: '',
};

export const DEFAULT_HEADER_KEY = 'Content-Type';
export const DEFAULT_HEADER_VALUE = 'application/json';
