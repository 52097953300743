import { HStack, Icon, StackDivider, Text, VStack } from '@chakra-ui/react';
import { toSentenceCase } from 'core/helpers/stringUtils';
import { FailureIcon, SuccessIcon } from 'icons';
import { useState } from 'react';
import { CodeSnippet } from 'views/shared/components';

import styles from './payload-validation.module.css';
import { PAYLOAD_VALIDATION_MODEL } from './payload-validation.type';

export const PayloadValidation = ({
  input,
  valResultList,
}: {
  input: string;
  valResultList: PAYLOAD_VALIDATION_MODEL[];
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <HStack className={styles.stack} divider={<StackDivider borderColor="shades.smoke" />}>
      <VStack alignItems="flex-start" flex="30">
        <Text className={`${styles.text} ${styles.heading}`}>Trigger Events</Text>
        <VStack alignItems="flex-start" width="100%" spacing={0}>
          {valResultList.map((item, index) => {
            return (
              <HStack
                key={item.name}
                onClick={() => setSelectedIndex(index)}
                className={`${styles.item} ${index === selectedIndex ? styles.selected : ''} `}
              >
                {item.isPassed ? <SuccessIcon width="40px" /> : <FailureIcon width="40px" />}
                <Text>{item.name}</Text>
              </HStack>
            );
          })}
        </VStack>
      </VStack>
      <VStack className={`${styles.stack} ${styles.rightStack}`} flex="70">
        <Text className={`${styles.text} ${styles.heading}`}>Compiler Message</Text>
        <HStack>
          {valResultList[selectedIndex].isPassed ? (
            <Icon w={5} h={5} as={SuccessIcon} />
          ) : (
            <Icon w={5} h={5} as={FailureIcon} />
          )}
          <Text className={styles.text}>
            {valResultList[selectedIndex].isPassed
              ? 'Success'
              : toSentenceCase(valResultList[selectedIndex].errorMsg) ?? 'Error'}
          </Text>
        </HStack>
        <CodeSnippet title="Input" code={input} />
        {valResultList[selectedIndex].output && (
          <CodeSnippet title="Output" code={valResultList[selectedIndex].output ?? ''} />
        )}
      </VStack>
    </HStack>
  );
};
