import { Circle, Text, VStack, Wrap, WrapItem, HStack, ChakraProvider } from '@chakra-ui/react';
import SQTooltip from 'components/chakra/Tooltip';
import { deepCopy } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import { InfoIcon } from 'icons';
import { Popover, Tag } from 'library/atoms';
import libraryTheme from 'library/theme';
import { truncate } from 'lodash';
import { memo } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FilterEntity } from 'views/main/organization/owner-filters/hooks/useEntityOwnerFilter';
import { queryUpdate } from '../../common/util';

import { FilterKeysType, Filters } from '../../interfaces/filters';
import { filterManager } from '../manager';
import { INITIAL_FILTERS_STATE } from '../manager/constant';
import { getFilterKeyLabel, getFiltersTags } from '../manager/util';
import { condition, filterObjectValues, findNonCommonElements } from './helper';
import { Tooltip } from 'library/atoms/Tooltip';
import useQueryParams from 'core/hooks/useQueryParams';

export const RenderFilterTags = memo(
  ({
    filtersData,
    readOnly = false,
    count = 0,
    onChange,
  }: {
    filtersData: Filters;
    readOnly?: boolean;
    count?: number;
    onChange?: () => void;
  }) => {
    const filters = deepCopy(filtersData);
    const organization = useSelector((state: IAppState) => state.organization);
    const { tagsList, overflow } = getFiltersTags(filters, organization.currentUser.u)(count);
    let totalCount = tagsList.length + overflow.length;
    const queryClient = useQueryClient();
    const query = useQueryParams();
    const history = useHistory();

    const renderFilters = (
      {
        key,
        value,
        label,
        username,
        subFeilds,
        hideDeleteIcon,
      }: {
        key: string;
        value: string;
        label: string;
        username?: string | null;
        subFeilds?: any;
        hideDeleteIcon?: boolean;
      },
      index: number,
    ) => {
      const title = `${getFilterKeyLabel(key as FilterKeysType)} : ${label}`;
      const subArray = subFeilds ? [...subFeilds?.users, ...subFeilds?.squads] : [];
      const trucatedText = truncate(title, {
        length: 50,
        separator: ' ',
      });
      const onClearTag = () => {
        const filterKey: FilterKeysType = key as FilterKeysType;
        const filterValue = filters[filterKey];

        const clearDateFilters = () => {
          filters.created = null;
          filters.lastNumberOfDays = undefined;
          filters.startDate = null;
          filters.endDate = null;
        };

        if (filterValue) {
          if (['startDate', 'endDate'].includes(filterKey)) {
            clearDateFilters();
            totalCount -= 1;
          } else if (filterKey === 'lastNumberOfDays') {
            clearDateFilters();
          } else if (filterKey === 'withRetro') {
            filters['retroStatus'] = INITIAL_FILTERS_STATE['retroStatus'];
            filters[filterKey] = INITIAL_FILTERS_STATE[filterKey];
          } else if (filterKey === 'assignedTo') {
            const filteredObj = filterObjectValues(filterValue, value);
            filters['assignedTo'] = filteredObj;
          } else if (filterKey === 'serviceOwner') {
            const filteredObj = filterObjectValues(filterValue, value);
            filters['serviceOwner'] = filteredObj;
          } else if (Array.isArray(filterValue)) {
            const updatedVal = filterValue.filter(obj => obj.value !== value);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            filters[filterKey] = updatedVal;
            if (key === 'keyValue') {
              filters['tagsValue'] = updatedVal;
            }
            if (key === 'services') {
              filterManager.clearFilters(queryClient);
              queryUpdate(query, null, 'services');
              query.delete('filter');
              history.push({ search: query.toString() });
            }
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            filters[key] = INITIAL_FILTERS_STATE[key];
          }
          if (totalCount === 1) {
            filterManager.clearFilters(queryClient);
          } else {
            filterManager.applyFilters(filters, queryClient);
          }
          onChange?.();
        }
      };

      return (
        <WrapItem key={index}>
          <Tooltip label={username ?? title} placement="bottom">
            <Tag
              size="md"
              variant="filter"
              label={label}
              disableToolTip={true}
              popOver={
                (subFeilds?.squads.length || subFeilds?.users.length) && (
                  <Popover
                    hasArrow={false}
                    enableTriggerWrapper={true}
                    disablePortal={true}
                    trigger={<InfoIcon height={12} width={12} />}
                  >
                    <VStack
                      p="2"
                      spacing={2}
                      maxH="60vh"
                      overflowY="scroll"
                      alignItems="flex-start"
                    >
                      {subArray.map(renderFilters)}
                    </VStack>
                  </Popover>
                )
              }
              title={
                username ? (
                  <HStack>
                    <Text fontWeight={'bold'}>
                      {trucatedText}{' '}
                      <Text margin={1} as="span" fontWeight="normal">
                        ({truncate(username)})
                      </Text>
                    </Text>
                  </HStack>
                ) : (
                  <HStack>
                    <Text fontWeight={'bold'}>
                      <Text>{title}</Text>
                    </Text>
                  </HStack>
                )
              }
              onClose={hideDeleteIcon ? undefined : readOnly ? () => {} : onClearTag}
            />
          </Tooltip>
        </WrapItem>
      );
    };
    return (
      <Wrap>
        {tagsList.map(renderFilters)}
        {overflow.length ? (
          <Popover
            hasArrow={false}
            enableTriggerWrapper={true}
            disablePortal={true}
            trigger={
              <Circle cursor="pointer" size="19px" bg="primary.400">
                <Text variant="hint" color="secondary.1000">
                  +{overflow.length}
                </Text>
              </Circle>
            }
          >
            <VStack p="2" spacing={2} maxH="60vh" overflowY="scroll" alignItems="flex-start">
              {overflow.map(renderFilters)}
            </VStack>
          </Popover>
        ) : null}
      </Wrap>
    );
  },
);

export const FilterTags = memo(
  ({
    filters,
    count = 0,
    onChange,
  }: {
    filters: Filters;
    count?: number;
    onChange: () => void;
  }) => {
    const queryClient = useQueryClient();
    const query = useQueryParams();

    const history = useHistory();
    if (filters.name) {
      return (
        <WrapItem>
          <Tag
            size="md"
            variant="filter"
            title={`SavedFilter : ${truncate(filters.name, { length: 50 })}`}
            onClose={() => {
              filterManager.clearFilters(queryClient);
              queryUpdate(query, null, 'filterId');
              history.push({ search: query.toString() });
              onChange();
            }}
          />{' '}
        </WrapItem>
      );
    }
    if (filters) {
      return <RenderFilterTags filtersData={filters} count={count} onChange={onChange} />;
    }
    return <></>;
  },
);
