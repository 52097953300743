import axios from 'axios';
import { EntityACL } from 'core/userAccess/types';
import { setUserAccessStore, getUserAccessStore } from 'core/userAccess/UserAccessStore';
import { API } from '../api';
import { IWebForm } from '../interfaces/IWebform';

interface IUpdateWebForm {
  id: string;
  name: string;
  url: string;
  tls: {
    publicCertificate: string;
    privateKey: string;
  };
  services: {
    serviceId: string;
    alias: string;
  }[];
}

class WebFormService {
  private organizationId = API.config.organizationId;
  private apiNew = `${API.config.batman}/organizations/${this.organizationId}/webforms`;

  public createWebform = (payload: IWebForm) =>
    axios.post<{ data: IWebForm }>(this.apiNew, payload);

  public updateWebform = (webformId: number, payload: IWebForm) =>
    axios.put<{ data: IWebForm }>(
      `${this.apiNew}/${webformId}?owner_id=${API.config.teamId}`,
      payload,
    );

  getWebformsData = async (
    pageNumber: number,
    pageSize: number,
    setAcl: boolean,
    entityOwner = '',
  ) => {
    let queryStr = `owner_id=${API.config.teamId}&page_size=${pageSize}&page_number=${pageNumber}`;
    if (entityOwner) {
      queryStr += `&entity_owner=${entityOwner}`;
    }

    const response = await axios.get<{
      data: IWebForm[];
      meta: {
        acl: EntityACL;
        total_count: number;
      };
    }>(`${this.apiNew}?${queryStr}`);
    if (setAcl) {
      const acl = response.data.meta.acl;
      setUserAccessStore({
        entityACLMap: {
          ...getUserAccessStore().entityACLMap,
          webforms: {
            ...getUserAccessStore().entityACLMap?.webforms,
            ...acl,
          },
        },
      });
    }
    return response;
  };

  public getWebForm = (webformId: number) =>
    axios.get<{ data: IWebForm }>(`${this.apiNew}/${webformId}?owner_id=${API.config.teamId}`);

  public deleteWebform = (webformId: number) =>
    axios.delete<{ result: string }>(`${this.apiNew}/${webformId}?owner_id=${API.config.teamId}`);

  public fetchExistingWebformName = (name: string) =>
    axios.get<{ data: IWebForm }>(`${this.apiNew}/by-name?name=${name}`);
}

export default WebFormService;
