export type IPriorityPosition = 'top' | 'bottom' | 'up' | 'down';

export interface IServiceRules<T = IRule> {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  service_id: string;
  organization_id: string;
  rules: T[];
}

export interface IRule {
  basic_expression: IBasicExpression[];
  created_at: string;
  created_by: string;
  expression: string;
  is_basic: boolean;
  rule_id: string;
  updated_at: string;
  updated_by: string;
  tags?: { [key: string]: { color: string; value: string } };
  alert_source_shortname?: string;
  overlay: { template: string };
}

export interface IBasicExpression {
  lhs: string;
  op: IRuleExpressionOperation;
  rhs: any;
}

export enum IRuleExpressionOperation {
  EOpNoOp = '',
  EOpIs = 'is',
  EOpIsNot = 'is_not',
  EOpMatches = 'matches',
  EOpGT = 'gt',
  EOpLT = 'lt',
  EOpGTEQ = 'gt_eq',
  EOpLTEQ = 'lt_eq',
  EOpFieldIs = 'field_is',
}

export interface IDeduplicationRule {
  expression: string;
  time_window: number;
  time_unit: IRuleTimeUnit;
  is_basic: boolean;
  basic_expression: IBasicExpression[];
  dependency_deduplication: boolean;
  description: string;
}

export enum IRuleTimeUnit {
  Minute = 'minute',
  Hour = 'hour',
}

export interface ITaggingRule {
  expression: string;
  tags: {
    key: string;
    value: string;
    color: string;
  }[];
  is_basic: boolean;
  basic_expression: IBasicExpression[];
}

export interface ISuppressionRule {
  description: string;
  expression: string;
  is_basic: boolean;
  is_timebased: boolean;
  timeslots: ITimeslot[];
  basic_expression: IBasicExpression[];
}

export interface IDedupeKeyInput {
  overlay_template_type: 'go';
  dedup_key_overlay: {
    template: string;
    duration: number;
  };
}

export interface ITimeslot {
  time_zone: string;
  start_time: Date;
  end_time: Date;
  is_allday: boolean;
  repetition: string;
  is_custom: boolean;
  custom: ICustomTime;
  ends_never: boolean;
  ends_on: Date;
}

export interface ICustomTime {
  repeats_count: number;
  repeats: string;
  repeats_on_weekdays: (0 | 1 | 2 | 3 | 4 | 5 | 6)[];
  repeats_on_month: string;
}

export interface IRoutingRule {
  id: number;
  rule_id: string;
  expression: string;
  is_basic: boolean;
  basic_expression: Omit<IBasicExpression, 'op'>[];
  route_to: {
    name: string;
    entity_type: 'user' | 'squad' | 'escalationpolicy' | '';
    entity_id: string;
  };
  searchString: string;
  existing: boolean;
}

export interface IDedupKey {
  key: string;
  time_window: number;
  time_unit: IRuleTimeUnit;
  template_output: string;
}

export interface IDedupeKeyData {
  alert_source_shortname: string;
  alert_source_type: string;
  overlay: {
    template: string;
    duration: number;
  };
  created_at: string;
  created_by: string;
}

export interface IServiceConfig {
  dedup_key_enabled: boolean;
}
