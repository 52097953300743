import { NewStatusPageComponentType, StatusPageComponentListOrderItem } from '../graphql/types';
import { useCallback, useReducer, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';

import { ComponentActionType } from '../constants/status.constants';
import { getComponentData } from '../helpers/helper.details';
import { reactQueryConfigSuccess } from '../helpers/helper.toast';
import {
  IComponentsForm,
  IComponentTreeItem,
  IStatusPageComponent,
  IStatusPageGroup,
} from '../Interface';
import { ComponentReducer } from '../reducers/ComponentReducer';
import {
  useChangeComponentOrder,
  useCreateComponent,
  useCreateGroup,
  useDeleteComponent,
  useDeleteGroup,
  useUpdateComponent,
  useUpdateGroup,
} from './components';

type onSubmit = (values: IComponentTreeItem[]) => void;

export const useComponents = (
  onComponentSubmit: onSubmit,
  pageId?: number,
  refetch = () => {},
  isEditFlow = false,
) => {
  const [error, setError] = useState('');
  const [showComponentForm, setShowComponentForm] = useState(false);
  const [catchAllComponent, setCatchAllComponent] = useState<IComponentTreeItem | null>(null);
  const [state, dispatch] = useReducer(ComponentReducer, {
    components: [],
    componentForm: {},
    selectedGroupId: -1,
    isNewGroup: false,
    isNewComponent: false,
    groupOptions: [],
    isDragged: false,
    componentCount: 0,
  });

  const { mutateAsync: createComponent, isLoading: isCreateComponentProgress } = useCreateComponent(
    () => {
      refetch();
      setShowComponentForm(false);
      reactQueryConfigSuccess('Component created');
    },
  );

  const { mutateAsync: updateComponent, isLoading: isUpdateComponentProgress } = useUpdateComponent(
    () => {
      refetch();
      setShowComponentForm(false);
      reactQueryConfigSuccess('Component updated');
    },
  );

  const { mutateAsync: deleteComponentApi, isLoading: isDeleteComponentProgress } =
    useDeleteComponent(() => {
      refetch();
      setShowComponentForm(false);
      reactQueryConfigSuccess('Component deleted');
    });
  const { mutateAsync: createGroup } = useCreateGroup(() => {
    refetch();
    reactQueryConfigSuccess('Group created');
  });

  const { mutateAsync: updateGroup } = useUpdateGroup(() => {
    refetch();
    reactQueryConfigSuccess('Group updated');
  });

  const { mutateAsync: deleteGroupApi } = useDeleteGroup(() => {
    refetch();
    reactQueryConfigSuccess('Group deleted');
  });

  const { mutateAsync: changeOrderApi } = useChangeComponentOrder(() => {
    refetch();
    reactQueryConfigSuccess('Component order changed');
  });

  const addGroup = useCallback(() => {
    dispatch({ type: ComponentActionType.INIT_ADD_GROUP });
  }, []);

  const saveGroup = useCallback(
    (name: string) => {
      if (isEditFlow) {
        if (state.isNewGroup) {
          createGroup({
            input: {
              name: name,
              pageID: pageId || -1,
            },
          });
        } else {
          updateGroup({
            input: {
              id: state.selectedGroupId,
              name: name,
              pageID: pageId || -1,
            },
          });
        }
      }
      dispatch({ type: ComponentActionType.SAVE_GROUP, groupName: name });
    },
    [state.selectedGroupId, state.isNewGroup],
  );

  const editGroup = useCallback((id: number) => {
    dispatch({ type: ComponentActionType.INIT_EDIT_GROUP, updateGroupId: id });
  }, []);

  const cancelGroupChange = useCallback(() => {
    dispatch({ type: ComponentActionType.CANCEL_GROUP_CHANGE });
  }, []);

  const deleteGroup = useCallback((groupId: number, reassignGroupId?: number) => {
    if (isEditFlow) {
      deleteGroupApi({
        id: groupId,
        reassignGroupID: reassignGroupId,
      });
    } else {
      dispatch({
        type: ComponentActionType.DELETE_GROUP,
        updateGroupId: groupId,
        reassignGroupId: reassignGroupId,
      });
    }
  }, []);

  const initAddComponentForm = useCallback(() => {
    dispatch({ type: ComponentActionType.INIT_ADD_COMPONENT });
  }, []);

  const initEditComponent = useCallback((componentId: number, groupId?: number) => {
    dispatch({
      type: ComponentActionType.INIT_EDIT_COMPONENT,
      updateComponentId: componentId,
      updateGroupId: groupId,
    });
  }, []);

  const saveComponent = useCallback(
    (values: IComponentsForm) => {
      if (isEditFlow) {
        if (state.isNewComponent) {
          createComponent({
            input: {
              pageID: pageId || -1,
              name: values.name,
              description: values.description,
              groupID: values.groupId,
              serviceID: values.serviceID ?? '',
            },
          });
        } else {
          const hasGroupChanged = values.prevGroupId !== values.groupId;
          let belongsToGroup = null;
          if (hasGroupChanged) {
            belongsToGroup = typeof values.groupId === 'number' ? true : false;
          }
          updateComponent({
            input: {
              id: values.id,
              name: values.name,
              description: values.description,
              groupID: values.groupId,
              belongsToGroup,
              serviceID: values.serviceID ?? '',
              timelineOverrides: values.timelineOverrides ?? [],
            },
          });
        }
      } else {
        dispatch({
          type: state.isNewComponent
            ? ComponentActionType.ADD_COMPONENT
            : ComponentActionType.EDIT_COMPONENT,
          componentForm: values,
        });
        setError('');
      }
    },
    [state.isNewComponent],
  );

  const deleteComponent = useCallback((componentId: number) => {
    if (isEditFlow) {
      deleteComponentApi({
        id: componentId,
      });
    } else {
      dispatch({
        type: ComponentActionType.DELETE_COMPONENT,
        updateComponentId: componentId,
      });
    }
  }, []);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const reorder = (components: IStatusPageGroup[], startIndex: number, endIndex: number) => {
        const result: IStatusPageGroup[] = Array.from(components);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result as IStatusPageComponent[];
      };
      let comps = [...state.components];
      if (!result.destination) {
        return;
      }
      if (result.type === 'group') {
        comps = reorder(state.components, result.source.index, result.destination.index);
      } else {
        const dragGroupIndex = parseInt(result.type, 10);
        const components = (state.components[dragGroupIndex] as IStatusPageGroup).components;
        if (components && components.length > 0) {
          const reorderItems = reorder(components, result.source.index, result.destination.index);

          (comps[dragGroupIndex] as IStatusPageGroup).components = reorderItems;
        }
      }
      dispatch({
        type: ComponentActionType.INIT_COMPONENT_LIST,
        componentList: comps,
        isDragged: true,
      });
    },
    [state.components],
  );

  const onSubmit = () => {
    const { componentCount } = getComponentData(state.components);
    if (componentCount === 0) {
      setError('Atleast one component required');
    } else {
      if (isEditFlow) {
        const com: StatusPageComponentListOrderItem[] = state.components.map(
          (c: IComponentTreeItem) => {
            const co = (c as IStatusPageGroup).components?.map(comp => comp.id);
            return {
              type: c.type as NewStatusPageComponentType,
              id: c.id,
              components: co,
            };
          },
        );
        if (catchAllComponent) {
          com.push({
            id: catchAllComponent.id,
            type: catchAllComponent.type as NewStatusPageComponentType,
          });
        }
        changeOrderApi({
          input: {
            componentList: com,
            pageID: pageId || -1,
          },
        });
      }
      onComponentSubmit(state.components);
      setError('');
    }
  };

  const reInitiateState = (comps: IComponentTreeItem[]) => {
    const catchAll = comps.filter(c => c.isHidden);
    setCatchAllComponent(catchAll[0]);
    dispatch({
      type: ComponentActionType.INIT_COMPONENT_LIST,
      componentList: comps.filter(c => !c.isHidden),
    });
  };

  return {
    state,
    addGroup,
    saveGroup,
    editGroup,
    cancelGroupChange,
    deleteGroup,
    initAddComponentForm,
    saveComponent,
    initEditComponent,
    deleteComponent,
    onDragEnd,
    onSubmit,
    error,
    reInitiateState,
    showComponentForm,
    setShowComponentForm,
    isComponentProgress: isCreateComponentProgress || isUpdateComponentProgress,
  };
};
