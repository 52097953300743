import {
  REQUEST_INCIDENT_DETAIL_RUNBOOK,
  REQUEST_INCIDENT_DETAIL_RUNBOOK_SUCCESS,
  REQUEST_INCIDENT_DETAIL_RUNBOOK_FAIL,
} from '../../const/organization';
import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';

const incidentRunbooks = INITIAL_STATE.incidentRunbooks;

const incidentDetailRunbook = (state = incidentRunbooks, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{
          action: RESET_STATE,
          runbook: [],
          loading: false,
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_INCIDENT_DETAIL_RUNBOOK:
      return {
        ...state,
        ...{ loading: true },
      };
    case REQUEST_INCIDENT_DETAIL_RUNBOOK_SUCCESS:
      return {
        ...state,
        ...{
          action: REQUEST_INCIDENT_DETAIL_RUNBOOK_SUCCESS,
          runbook: action.payload.runbookList,
          loading: false,
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_INCIDENT_DETAIL_RUNBOOK_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_INCIDENT_DETAIL_RUNBOOK_FAIL,
          runbook: [],
          loading: false,
          error: { type: REQUEST_INCIDENT_DETAIL_RUNBOOK_FAIL, reason: action.payload },
        },
      };
    default:
      return state;
  }
};

export default incidentDetailRunbook;
