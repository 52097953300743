import { useQueryClient } from 'react-query';
import { QUERY_KEY } from './queryKeys';

export function useInvaliDateGER() {
  const queryClient = useQueryClient();

  const invalidateList = () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEY.GER_LIST,
    });
  };

  const invalidateDetail = () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEY.GER_DETAIL,
    });
  };

  return {
    invalidateList,
    invalidateDetail,
  };
}
