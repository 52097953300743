import * as React from 'react';
import { SVGProps } from 'react';

const SvgManageengineOpmanager = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#ffce26',
      }}
      d="M13.833.763c7.746 5.375 4.505 17.785-3.715 19.129C20.63 19.891 24.108 5.505 13.833.763z"
    />
    <path
      style={{
        fill: '#0879b8',
      }}
      d="M5.612.684c9.169-1.344 12.963 9.96 8.932 15.176C21.184 9.22 15.888-2.636 5.612.684z"
    />
    <path
      style={{
        fill: '#069b4f',
      }}
      d="M.237 6.138c4.505-6.719 13.912-2.608 14.86 4.189 1.107-8.3-9.959-12.963-14.86-4.189z"
    />
    <path
      style={{
        fill: '#cb2034',
      }}
      d="M0 11.196C1.107 4.241 10.118 3.529 13.437 8.43 10.75 1.474.158 2.343 0 11.196z"
    />
  </svg>
);

export default SvgManageengineOpmanager;
