import { Input } from '@chakra-ui/input';
import { VStack } from '@chakra-ui/layout';
import { Textarea } from '@chakra-ui/textarea';
import { FormTag, onTagUpdateParams } from 'library/molecules/Form/TagInput/FormTag';
import { Tag } from 'library/molecules/Form/TagInput/useFormTag';
import EntityOwnerDropdown from 'library/molecules/Form/EntityOwnerDropdown/EntityOwnerDropdown';
import FormField from 'library/molecules/FormField/FormField';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useWorkflowTagsQuery } from '../../api/hook';
import { WorkflowForm } from '../../types';

const BasicForm: FC = () => {
  const { register, formState, setValue, watch } = useFormContext<WorkflowForm>();

  const watchOwner = watch('owner');
  const watchTags = watch('tags');
  const { data: tagsList = [] } = useWorkflowTagsQuery();

  const tagErrors = formState.errors.tags?.map?.(tagErr => {
    return {
      key: tagErr?.key?.message ?? tagErr?.message,
      value: tagErr?.value?.message ?? tagErr?.message,
    };
  });

  const sanitizedTags = tagsList.filter(tag => tag.key && tag.values.length > 0);

  const onTagUpdate: onTagUpdateParams = (tags: Tag[]) => {
    setValue('tags', tags, {
      shouldDirty: true,
    });
  };

  return (
    <VStack w="80%" gap={3}>
      <FormField label="Title *" error={formState.errors.title?.message}>
        <Input {...register('title')} placeholder="Title" />
      </FormField>

      <FormField label="Description" error={formState.errors.description?.message}>
        <Textarea {...register('description')} placeholder="Describe Workflow" />
      </FormField>

      <EntityOwnerDropdown
        name="owner"
        error={formState.errors.owner?.id?.message}
        value={watchOwner}
        onMount={ownerOptions => {
          if (ownerOptions.length > 0) {
            setValue('owner', ownerOptions[0]);
          }
        }}
        isRequired
      />

      <FormField label="Tags">
        <FormTag
          tagsList={sanitizedTags}
          onTagUpdate={onTagUpdate}
          initialValue={watchTags}
          isTagCreatable
          error={tagErrors}
        />
      </FormField>
    </VStack>
  );
};

export default BasicForm;
