import { IAppState } from 'core/interfaces/IAppState';
import { IEscalationPolicy } from 'core/interfaces/IEscalationPolicy';
import { ILog } from 'core/interfaces/IIncidents';
import { IService } from 'core/interfaces/IService';
import { ISquad } from 'core/interfaces/ISquad';
import { ITeam } from 'core/interfaces/ITeams';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { isNullOrUndefined } from 'core/util';
import { generatePath, Link } from 'react-router-dom';
import {
  ESCALATION_POLICIES_LIST_PATH,
  INCIDENT_DETAILS_PATH,
  SERVICE_DETAILS_PATH,
  TEAM_SQUADS_PATH,
  TEAMS_PATH,
  USER_PROFILE_PATH,
  WORKFLOWS_DETAILS_PATH,
} from 'views/main/routes/routes';
import { REMAINING_ACTIONTYPE_MAP } from '../incidentDetails/constants';
import { useOrganizationConfig } from '../schedules';
import {
  ActionTypeForEntity,
  ActionTypeForMessage,
  EntityName,
  EntityType,
  GetEntityComponentProps,
  verifiedResponse,
} from './helpers';
import { truncate } from 'core/helpers/stringUtils';
import { entityResponse, messageResponse } from './helpers.activityTimeline';
import { UINavigationPopover } from './helpers.uiNavigation.popover';
import { getUserAccessStore } from 'core/userAccess/UserAccessStore';
import { Text } from '@chakra-ui/layout';
import { THEME_COLORS } from 'library/theme/colors';

export const linkStyles = {
  color: '#3D6DD8',
  cursor: 'pointer',
};

const useUINavigationFunctionsV2 = (organizationFromProps?: IAppState['organization']) => {
  const globalOrganization = useOrganizationConfig().organization;
  const organization = organizationFromProps || globalOrganization;
  const acceptableEntityTypesForPopover = ['user', 'service'];
  const userHasReadSquadsPermission = getUserAccessStore().teamLevelACL?.squads?.read ?? false;

  const getEntityLink = (type: EntityType, entityId: string | undefined) => {
    if (!(type && entityId)) return '';

    const entityLinkMap = {
      user: generatePath(USER_PROFILE_PATH, { id: entityId }),
      squad: generatePath(TEAM_SQUADS_PATH, {
        id: organization.selectedTeam.teamId,
        sqid: entityId,
      }),
      escalationpolicy: generatePath(ESCALATION_POLICIES_LIST_PATH, { id: entityId }),
      suppression_rule: generatePath(SERVICE_DETAILS_PATH, { id: entityId }),
      team: generatePath(TEAMS_PATH, { id: entityId }),
      incident: generatePath(INCIDENT_DETAILS_PATH, { id: entityId }),
      service: generatePath(SERVICE_DETAILS_PATH, { id: entityId }),
      routing_rule: generatePath(SERVICE_DETAILS_PATH, { id: entityId }),
      schedules: '',
      workflow: '',
    };
    return entityLinkMap[type] || '';
  };

  const getEntityObject = (type: EntityType, entityId: string | undefined) => {
    const entityObjectMap = {
      user: organization.users.u.find(user => user.id === entityId),
      squad: organization.squads.s.find(squad => squad.id === entityId),
      escalationpolicy: organization.escalations.e.find(ep => ep.id === entityId),
      team: organization.teams.t.find(team => team.id === entityId),
      service: organization.services.s.find(s => s.id === entityId),
    };
    return entityObjectMap[type as EntityName];
  };

  const getEntityName = (type: EntityType, entityId: string | undefined) => {
    if (!(type || entityId)) {
      return 'Deleted Entity';
    }
    const entityObject = getEntityObject(type, entityId);
    if (!entityObject) {
      if (type === 'escalationpolicy') return 'Deleted Escalation Policy';

      return `Deleted ${
        (type as EntityName).charAt(0).toUpperCase() + (type as EntityName).slice(1)
      }`;
    }
    const entityMap = {
      user: `${(entityObject as IUsersOfOrganization).first_name} ${
        (entityObject as IUsersOfOrganization).last_name
      }`,
      squad: `${(entityObject as ISquad).name}`,
      escalationpolicy: `${(entityObject as IEscalationPolicy).name}`,
      team: `${(entityObject as ITeam).name}`,
      service: `${(entityObject as IService).name}`,
    };

    return entityMap[type as EntityName] || 'Deleted Entity';
  };

  const getDetailsFromLogActivity = (log: ILog) => {
    const { action, actionType, assignedTo: entityType, id: entityId, reason } = log;

    let logMessage = action.replace(/[^a-zA-Z0-9]/g, ' ');
    let logMessageActivity = '';
    let entityComponent = <></>;
    let logChanges: string[] = [];

    const { messageForDelayedNotification, messageForSloWebhookWf } = messageResponse(
      log,
      organization,
    );
    const {
      ResponseForPublicMessage,
      ResponseForReassigned,
      ResponseForIncidentSnoozed,
      ResponseForSuppresedAtUserReplace,
      ResponseForDelayedNotificationResume,
      ResponseForActionIncidentUnsnooze,
      ResponseForReassignUserReplacement,
      ResponseForManualMergeChildLog,
      ResponseForManualMergeParentLog,
      ResponseForWfSloWebhook,
      ResponseForIagAdditionalandRespondersAction,
      ResponseForMSTeamMeetingLink,
      ResponseForNoActionMatched,
    } = entityResponse(log, getEntityComponent, logMessage, getEntityName);

    const componentForAction = {
      auto_resolved: getEntityComponent({ componentType: 'simple', name: logMessage }),
      slo_false_positive_true: getEntityComponent({ componentType: 'simple', name: reason }),
      reassigned: <ResponseForReassigned />,
      incident_snoozed: <ResponseForIncidentSnoozed />,
      suppressed_at_user_replacement: <ResponseForSuppresedAtUserReplace />,
      delayed_notification_resumed: <ResponseForDelayedNotificationResume />,
      incident_unsnoozed_automatically: <ResponseForActionIncidentUnsnooze triggerType="auto" />,
      incident_unsnoozed_manually: <ResponseForActionIncidentUnsnooze triggerType="manual" />,
      reassigned_at_user_replacement: <ResponseForReassignUserReplacement />,

      manually_merged_incidents_child: <ResponseForManualMergeChildLog />,
      manually_unmerged_incident_child: <ResponseForManualMergeChildLog />,
      manually_merged_incidents_parent: <ResponseForManualMergeParentLog />,
      manually_unmerged_incident_parent: <ResponseForManualMergeParentLog />,
      action_for_workflow_slo_webhook: <ResponseForWfSloWebhook />,
      update_slo_error_budget_spent: <ResponseForWfSloWebhook />,

      incident_priority_updated: <ResponseForIagAdditionalandRespondersAction />,
      additional_responders_added: <ResponseForIagAdditionalandRespondersAction />,
      additional_responders_removed: <ResponseForIagAdditionalandRespondersAction />,
      iag_vote_down: <ResponseForIagAdditionalandRespondersAction />,
      iag_vote_up: <ResponseForIagAdditionalandRespondersAction />,

      msteams_meeting_link: <ResponseForMSTeamMeetingLink />,
    };
    const messageForAction = {
      postmortem_status_updated: logMessage,
      tags_modified: action,
      delayed_notification_resumed: 'Incident assigned to $1 after resuming delayed notifications',
      delayed_notification: messageForDelayedNotification(),
      manually_marked_negative_transient_alert_feedback: reason.split(' by ')[0] + ' by ',
      manually_marked_positive_transient_alert_feedback: reason.split(' by ')[0] + ' by ',
      action_for_workflow_slo_webhook: messageForSloWebhookWf(),

      update_slo_error_budget_spent: ' has ' + reason.split(' has ')[1],
      incident_priority_updated: reason.split(' by ')[0],
      additional_responders_added: reason.split(' by ')[0],
      additional_responders_removed: reason.split(' by ')[0],
      iag_vote_down: reason.split(' by ')[0],
      iag_vote_up: reason.split(' by ')[0],
    };
    entityComponent = componentForAction[actionType as ActionTypeForEntity];
    logMessageActivity = messageForAction[actionType as ActionTypeForMessage];

    //could not object map these boolean so assigned its seperate key
    if (action === 'public_message') {
      entityComponent = <ResponseForPublicMessage />;
    } else if (
      Object.keys(REMAINING_ACTIONTYPE_MAP).includes(actionType) ||
      actionType.startsWith('slo_') ||
      actionType.startsWith('wf_') ||
      (actionType.startsWith('Webhook_') && entityType === 'workflow')
    ) {
      entityComponent = componentForAction['action_for_workflow_slo_webhook'];
      logMessageActivity = messageForAction['action_for_workflow_slo_webhook'];
    } else {
      // if your component is still not visibile or showing wrong messages, it might have gone inside this else block --verifiedResponse
      // verifiedResponse are those reponses whose messages are already made from above objectMap. so add that component in verifedResponse
      if (!verifiedResponse.includes(actionType)) {
        const splitWord = logMessage.includes(' to ') ? ' to ' : ' by ';
        if (actionType === 'tags_modified') {
          logMessage = action;
          const entityName = logMessage.split(splitWord)[1].split('\n')[0];
          logChanges = logMessage.split(entityName)[1].split('\n');
        }
        if (actionType.includes('transient')) {
          logMessage = reason;
        }
        let entityName;
        logMessageActivity = logMessage.split(splitWord)[0] + splitWord;
        entityComponent = <ResponseForNoActionMatched action={action} entityName={entityName} />;
      }
    }

    return {
      entityComponent,
      logMessageActivity,
      logChanges,
    };
  };
  const getEntityUsername = (type: EntityType, entityId: string | undefined) => {
    if (!(type || entityId)) {
      return '';
    }
    const entityObject = getEntityObject(type, entityId);
    switch (type) {
      case 'user':
        return entityObject ? `${(entityObject as IUsersOfOrganization).username_for_display}` : '';
      default:
        return '';
    }
  };

  const getEntityComponentWithTruncatedName = (props: GetEntityComponentProps) =>
    getEntityComponent({ ...props, truncateName: true, nameMaxLength: 30 });

  const getEntityComponent = ({
    componentType,
    id,
    name,
    popoverTrigger,
    popoverPlacement,
    type,
    workflowId,
    workflowActionIndex,
    workflowExecutionId,
    truncateName,
    nameMaxLength,
  }: GetEntityComponentProps) => {
    const entityName = truncateName
      ? truncate(name || getEntityName(type, id), nameMaxLength ?? 30)
      : name || getEntityName(type, id);
    const entityObject = getEntityObject(type, id);

    if (
      type === 'workflow' &&
      !isNullOrUndefined(workflowId) &&
      !isNullOrUndefined(workflowExecutionId)
    ) {
      return (
        <Link
          to={`${generatePath(WORKFLOWS_DETAILS_PATH, {
            id: workflowId,
          })}?tab=logs&log=${workflowExecutionId}&wf_action_index=${workflowActionIndex}`}
          style={{
            ...linkStyles,
            fontSize: '14px',
            textDecoration: 'none',
          }}
        >
          {popoverTrigger ?? (entityName || 'Deleted Entity')}
        </Link>
      );
    } else if (type === 'workflow' && !isNullOrUndefined(workflowId)) {
      return (
        <Link
          to={`${generatePath(WORKFLOWS_DETAILS_PATH, {
            id: workflowId?.toString() as string,
          })}`}
          style={{
            ...linkStyles,
            fontSize: '14px',
            textDecoration: 'none',
          }}
        >
          {popoverTrigger ?? (entityName || 'Deleted Entity')}
        </Link>
      );
    }

    if (!['routing_rule', 'suppression_rule', 'incident'].includes(type ?? '') && !entityObject)
      return (
        <Text
          style={{
            marginLeft: '0px',
            color: THEME_COLORS.secondary[400],
            fontWeight: 900,
            fontSize: '14px',
          }}
        >
          {entityName}
        </Text>
      );

    const { SimplePopover, LinkWithPopover, LinkWithoutPopover } = UINavigationPopover({
      id,
      popoverPlacement,
      popoverTrigger,
      type,
      entityObject,
      entityName,
      getEntityLink,
      organization,
    });
    const PopoverMap = {
      simple: SimplePopover(),
      linkWPopover: LinkWithPopover(),
      linkWOPopover: LinkWithoutPopover(),
    };
    return PopoverMap[componentType];
  };

  return {
    acceptableEntityTypesForPopover,
    getDetailsFromLogActivity,
    getEntityComponent,
    getEntityComponentWithTruncatedName,
    getEntityLink,
    getEntityName,
    getEntityUsername,
    getEntityObject,
    userHasReadSquadsPermission,
  };
};

export default useUINavigationFunctionsV2;
