import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { useMemo, useState, Dispatch, SetStateAction } from 'react';
import { ShiftTime } from './shift-time';
import { OnCallCoverage } from './types';
import { groupShiftsByDay } from './utils';

const MAX_ITEMS_TO_SHOW = 1;

const RenderShiftTime = ({
  shifts,
  idx,
  showMore,
  setShowMore,
  shouldShowMoreButton,
  day,
}: {
  shifts: {
    duration: number;
    startHour: number;
    startMin: number;
  }[];
  day: string;
  idx: number;
  showMore: boolean;
  setShowMore: Dispatch<SetStateAction<boolean>>;
  shouldShowMoreButton: boolean;
}) => {
  return (
    <HStack alignItems="flex-start" key={day} width="100%" justifyContent="space-between" mt={'1'}>
      <Text fontSize={10} color="#627C98" textTransform="capitalize" fontWeight="700">
        {day} : {'  '}
      </Text>
      <VStack alignItems={'flex-start'}>
        {shifts?.map((shift, shiftIdx) => (
          <ShiftTime
            key={shiftIdx}
            shift={shift}
            idx={shiftIdx}
            showMore={showMore}
            setShowMore={setShowMore}
            shouldShowMoreButton={shouldShowMoreButton && idx === 0}
          />
        ))}
      </VStack>
    </HStack>
  );
};

export const WeeklyRoutine = ({
  data,
}: {
  data: NonNullable<OnCallCoverage['rotations']>[number]['shiftTimeSlots'];
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const groupByDayOfWeek = useMemo(() => groupShiftsByDay(data), data);
  const days = useMemo(() => Object.keys(groupByDayOfWeek), [groupByDayOfWeek]);
  const subset = useMemo(
    () => days?.slice(0, showMore ? undefined : MAX_ITEMS_TO_SHOW),
    [days, showMore],
  );

  return (
    <Text fontSize={10} color="#627C98" fontWeight="700">
      Changes Weekly
      <Box width={'160px'}>
        {subset.map((day, idx) => {
          const shifts = groupByDayOfWeek[day];
          return (
            <RenderShiftTime
              key={idx}
              shifts={shifts}
              idx={idx}
              showMore={showMore}
              setShowMore={setShowMore}
              shouldShowMoreButton={!showMore && days.length > MAX_ITEMS_TO_SHOW}
              day={day}
            />
          );
        })}
      </Box>
    </Text>
  );
};
