import {
  REQUEST_ORG_SERVICES,
  REQUEST_ORG_SERVICES_FAIL,
  REQUEST_ORG_SERVICES_SUCCESS,
} from '../const/organization';
import { IEscalationPolicy } from './IEscalationPolicy';
import { IJiraCloud, IJIssueType, IJProject, IJStatusmap } from './IJira';
import { ITeamEntityAccessControl, ITeamOwner } from './ITeams';

export interface IServiceSlack {
  channel_id: string;
  name: string;
  time: number;
}

export interface ITagRuleRaw {
  expression: string;
  tags: {
    [key: string]: {
      value: string;
      color: string;
    };
  };
  is_basic: boolean;
  basic_expression: IServiceExpressionBranch[];
}

export interface ITagRule {
  expression: string;
  tags: {
    key: string;
    value: string;
    color: string;
  }[];
  is_basic: boolean;
  basic_expression: IServiceExpressionBranch[];
}

export interface ISuppressionRule {
  description: string;
  expression: string;
  is_basic: boolean;
  is_timebased: boolean;
  timeslots: ITimeslot[];
  basic_expression: IServiceExpressionBranch[];
}

export interface ITimeslot {
  time_zone: string;
  start_time: Date;
  end_time: Date;
  is_allday: boolean;
  repetition: string;
  is_custom: boolean;
  custom: ICustomTime;
  ends_never: boolean;
  ends_on: Date;
}

export interface ICustomTime {
  repeats_count: number;
  repeats: string;
  repeats_on_weekdays: (0 | 1 | 2 | 3 | 4 | 5 | 6)[];
  repeats_on_month: string;
}
export interface RuleCount {
  count: number;
}

export interface IDeduplicationRule {
  expression: string;
  time_window: number;
  time_unit: IServiceTimeUnit;
  is_basic: boolean;
  basic_expression: IServiceExpressionBranch[];
  dependency_deduplication: boolean;
  description: string;
}

export enum IServiceTimeUnit {
  Minute = 'minute',
  Hour = 'hour',
}

export enum IServiceExpressionOperation {
  EOpNoOp = '',
  EOpIs = 'is',
  EOpIsNot = 'is_not',
  EOpMatches = 'matches',
  EOpNotContains = 'not_contains',
  EOpGT = 'gt',
  EOpLT = 'lt',
  EOpGTEQ = 'gt_eq',
  EOpLTEQ = 'lt_eq',
  EOpFieldIs = 'field_is',
}

export interface IServiceExpressionBranch {
  lhs: string;
  op: IServiceExpressionOperation;
  rhs: any;
}

export interface IServiceExtension {
  organization_id: string;
  service_id: string;
  slack: IServiceSlack;
}

export interface IServiceRequest {
  name: string;
  description: string;
  escalation_policy_id: string;
  email_prefix: string;
  dedup_init_config?: {
    time_window: number;
    time_unit: IServiceTimeUnit;
  };
}

export interface IActiveServiceTags {
  organization_id: string;
  service_id: string;
  tags: {
    [key: string]: string;
  };
}

export interface IJiraCloudServiceExtension {
  jiraCloudClientKey: string;
  issue_type: IJIssueType;
  project: IJProject;
  statusmaps: IJStatusmap[];
  is_manual: boolean;
  deleted: boolean;
}

export interface IService {
  id: string;
  name: string;
  slug: string;
  email: string;
  escalation_policy_id: string;
  organization_id: string;
  api_key: string;
  description: string;
  depends: any[] | null;
  owner: ITeamOwner;
  access_control: ITeamEntityAccessControl<'services'>[];
  on_maintenance: boolean;
  slack: IServiceSlack;
  jira_cloud: IJiraCloudServiceExtension;
  escalation_policy: Pick<IEscalationPolicy, 'id' | 'description' | 'name' | 'slug'>;
  auto_pause_transient_alerts_config: ServiceAptaConfig;
  intelligent_alerts_grouping_config: ServiceIAGConfig;
}

export type ServiceIAGConfig = {
  is_enabled: boolean;
  rolling_window_in_mins: number;
};

export type ServiceAptaConfig = {
  is_enabled: boolean;
  timeout_in_mins: number;
};

export type IOrganizationServiceReduxAction =
  | typeof REQUEST_ORG_SERVICES
  | typeof REQUEST_ORG_SERVICES_SUCCESS
  | typeof REQUEST_ORG_SERVICES_FAIL;
