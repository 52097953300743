import Axios, { AxiosError } from 'axios';
import { API } from 'core';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { createStandaloneToast } from '@chakra-ui/react';

import { queryKeys, reactQueryConfig } from '../contants/service.contant';
import { reactQueryConfigSuccess } from '../helpers/helper.service';
import { RoutingRules } from '../service.detail/service.attribute/service.rule';
import {
  IDedupeKeyData,
  IDedupeKeyInput,
  IDeduplicationRule,
  IPriorityPosition,
  IRoutingRule,
  IRule,
  IServiceRules,
  ISuppressionRule,
  ITaggingRule,
  IServiceConfig,
} from '../Interfaces/automation-rule';

type IRuleSave =
  | ITaggingRule
  | IRoutingRule
  | IDeduplicationRule
  | ISuppressionRule
  | IDedupeKeyInput;

const toast = createStandaloneToast();

const onError = (error: unknown) => {
  const responseError = error as AxiosError<{ meta: { error_message: string } }>;
  const title = responseError?.response?.data?.meta?.error_message || 'Error connecting to server';
  toast.closeAll();
  toast({ title, status: 'error', variant: 'subtle', isClosable: true });
};

const getServiceDeduplicationRules = (serviceId: string) => async () => {
  const { data } = await Axios.get<{ data: IServiceRules<IDeduplicationRule> }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/deduplication-rules`,
  );
  return data.data;
};

/**
 * Get Service De-duplicated Rules
 * @param serviceId Service ID
 * @returns Service Deduplicated rules
 */
const useServiceDeduplicationRulesQuery = (serviceId: string) => {
  return useQuery(
    [queryKeys.SERVICEDEDUPLICATEDRULE, serviceId],
    getServiceDeduplicationRules(serviceId),
    {
      ...reactQueryConfig,
      enabled: !!serviceId,
      keepPreviousData: true,
      onError,
    },
  );
};

const getServiceTaggingRules = (serviceId: string) => async () => {
  const { data } = await Axios.get<{ data: IServiceRules<ITaggingRule> }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/tagging-rules`,
  );
  return data.data;
};

/**
 * Get Service Tagging Rules
 * @param serviceId Service ID
 * @param owner_id Team ID
 * @returns Service Tagging rules
 */
const useServiceTaggingRulesQuery = (serviceId: string) => {
  return useQuery([queryKeys.SERVICETAGGINGRULE, serviceId], getServiceTaggingRules(serviceId), {
    ...reactQueryConfig,
    enabled: !!serviceId,
    keepPreviousData: true,
    onError,
  });
};

const getServiceRoutingRules = (serviceId: string) => async () => {
  const { data } = await Axios.get<{ data: IServiceRules<IRoutingRule> }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/routing-rules`,
  );
  return data.data;
};

/**
 * Get Service Routing Rules
 * @param serviceId Service ID
 * @param owner_id Team ID
 * @returns Service Routing rules
 */
const useServiceRoutingRulesQuery = (serviceId: string) => {
  return useQuery([queryKeys.SERVICEROUTINGRULE, serviceId], getServiceRoutingRules(serviceId), {
    ...reactQueryConfig,
    enabled: !!serviceId,
    keepPreviousData: true,
    onError,
  });
};

const getServiceSuppressionRules = (serviceId: string) => async () => {
  const { data } = await Axios.get<{ data: IServiceRules<ISuppressionRule> }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/suppression-rules`,
  );
  return data.data;
};

/**
 * Get Service Suppression Rules
 * @param serviceId Service ID
 * @param owner_id Team ID
 * @returns Service Suppression rules
 */
const useServiceSuppressionRulesQuery = (serviceId: string) => {
  return useQuery(
    [queryKeys.SERVICESUPPRESSIONRULE, serviceId],
    getServiceSuppressionRules(serviceId),
    {
      ...reactQueryConfig,
      enabled: !!serviceId,
      keepPreviousData: true,
      onError,
    },
  );
};

const getServiceDedupeKeys = (serviceId: string) => async () => {
  const { data } = await Axios.get<{ data: IDedupeKeyData[] }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/overlays/dedup-key`,
  );
  return data.data;
};

/**
 * Get Service Dedupe Keys
 * @param serviceId Service ID
 * @returns Service Overlays (Dedupe Keys)
 */
const useServiceDedupeKeysQuery = (serviceId: string) => {
  return useQuery([queryKeys.SERVICEDEDUPEKEY, serviceId], getServiceDedupeKeys(serviceId), {
    ...reactQueryConfig,
    enabled: !!serviceId,
    keepPreviousData: true,
    onError,
  });
};

const getAlertSourceDedupeKey = (serviceId: string, alertSourceName: string) => async () => {
  const { data } = await Axios.get<{ data: IDedupeKeyData[] }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/overlays/${alertSourceName}`,
  );
  return data.data;
};

/**
 * Get Service Dedupe Keys
 * @param serviceId Service ID
 * @param alertSourceName Short name for the alert source
 * @returns Overlay (Dedupe Key) for the given alert source of the service
 */
const useAlertSourceDedupeKeyQuery = (serviceId: string, alertSourceName: string) => {
  return useQuery(
    [queryKeys.ALERTSOURCEDEDUPEKEY, serviceId, alertSourceName],
    getAlertSourceDedupeKey(serviceId, alertSourceName),
    {
      ...reactQueryConfig,
      enabled: !!serviceId,
      keepPreviousData: true,
      onError,
    },
  );
};

const getServiceConfig = (serviceId: string) => async () => {
  const { data } = await Axios.get<{ data: IServiceConfig }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/config`,
  );
  return data.data;
};

/**
 * Get Service Config
 * @param serviceId Service ID
 * @returns Config for the service
 */
const useServiceConfigQuery = (serviceId: string) => {
  return useQuery([queryKeys.SERVICECONFIG, serviceId], getServiceConfig(serviceId), {
    ...reactQueryConfig,
    enabled: !!serviceId,
    keepPreviousData: true,
    onError,
  });
};

const updateServiceConfig = (serviceId: string) => async (configObject: IServiceConfig) => {
  const url = `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}`;

  await Axios.patch(`${url}/config`, configObject);
  return { configObject };
};

/**
 * Update Service Config
 */
const useUpdateServiceConfigMutation = (service_id: string) => {
  const queryClient = useQueryClient();

  return useMutation(updateServiceConfig(service_id), {
    ...reactQueryConfig,
    onSuccess: () => {
      queryClient.refetchQueries([queryKeys.SERVICECONFIG, service_id]);

      toast({
        title: 'Service Rule settings',
        description: 'Updated successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError,
  });
};

const updateServiceRule =
  (serviceId: string) =>
  async ({
    ruleType,
    ruleId = '',
    ruleDetail,
    alertSourceName,
  }: {
    ruleType: RoutingRules;
    ruleId?: string;
    ruleDetail: IRuleSave;
    alertSourceName?: string;
  }) => {
    const url = `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}`;

    switch (ruleType) {
      case RoutingRules.suppression: {
        await Axios.put(`${url}/suppression-rules/${ruleId}`, { rule: ruleDetail });
        return { ruleType };
      }
      case RoutingRules.tagging: {
        await Axios.put(`${url}/tagging-rules/${ruleId}`, { rule: ruleDetail });
        return { ruleType };
      }
      case RoutingRules.deduplication: {
        await Axios.put(`${url}/deduplication-rules/${ruleId}`, { rule: ruleDetail });
        return { ruleType };
      }
      case RoutingRules.routing: {
        await Axios.put(`${url}/routing-rules/${ruleId}`, { rule: ruleDetail });
        return { ruleType };
      }
      case RoutingRules.dedupekey: {
        await Axios.put(`${url}/overlays/dedup-key/${alertSourceName}`, {
          ...ruleDetail,
        });
        return { ruleType };
      }
    }
  };

/**
 * Update Service Rule
 */
const useUpdateRuleMutation = (service_id: string) => {
  const queryClient = useQueryClient();

  return useMutation(updateServiceRule(service_id), {
    ...reactQueryConfig,
    onSuccess: response => {
      switch (response.ruleType) {
        case RoutingRules.tagging:
          queryClient.refetchQueries([queryKeys.SERVICETAGGINGRULE, service_id]);
          break;

        case RoutingRules.suppression:
          queryClient.refetchQueries([queryKeys.SERVICESUPPRESSIONRULE, service_id]);
          break;

        case RoutingRules.routing:
          queryClient.refetchQueries([queryKeys.SERVICEROUTINGRULE, service_id]);
          break;

        case RoutingRules.deduplication:
          queryClient.refetchQueries([queryKeys.SERVICEDEDUPLICATEDRULE, service_id]);
          break;

        case RoutingRules.dedupekey:
          queryClient.refetchQueries([queryKeys.SERVICEDEDUPEKEY, service_id]);
          break;
      }

      toast({
        title: 'Automation Rule',
        description: 'Rule updated successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError,
  });
};

const createServiceRule =
  (serviceId: string) =>
  async ({
    ruleType,
    ruleDetail,
    alertSourceName,
  }: {
    ruleType: RoutingRules;
    ruleDetail: IRuleSave;
    alertSourceName?: string;
  }) => {
    const url = `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}`;

    switch (ruleType) {
      case RoutingRules.dedupekey: {
        await Axios.put(`${url}/overlays/dedup-key/${alertSourceName}`, {
          ...ruleDetail,
        });
        return { ruleType };
      }
      case RoutingRules.suppression: {
        await Axios.post(`${url}/suppression-rules/new`, { rule: ruleDetail });
        return { ruleType };
      }
      case RoutingRules.tagging: {
        await Axios.post(`${url}/tagging-rules/new`, { rule: ruleDetail });
        return { ruleType };
      }
      case RoutingRules.deduplication: {
        await Axios.post(`${url}/deduplication-rules/new`, { rule: ruleDetail });
        return { ruleType };
      }
      case RoutingRules.routing: {
        await Axios.post(`${url}/routing-rules/new`, { rule: ruleDetail });
        return { ruleType };
      }
    }
  };

/**
 * Create Service Rule
 */
const useCreateRuleMutation = (service_id: string) => {
  const queryClient = useQueryClient();

  return useMutation(createServiceRule(service_id), {
    ...reactQueryConfig,
    onSuccess: response => {
      switch (response.ruleType) {
        case RoutingRules.tagging:
          queryClient.refetchQueries([queryKeys.SERVICETAGGINGRULE, service_id]);
          break;

        case RoutingRules.suppression:
          queryClient.refetchQueries([queryKeys.SERVICESUPPRESSIONRULE, service_id]);
          break;

        case RoutingRules.routing:
          queryClient.refetchQueries([queryKeys.SERVICEROUTINGRULE, service_id]);
          break;

        case RoutingRules.deduplication:
          queryClient.refetchQueries([queryKeys.SERVICEDEDUPLICATEDRULE, service_id]);
          break;
        case RoutingRules.dedupekey:
          queryClient.refetchQueries([queryKeys.SERVICEDEDUPEKEY, service_id]);
          break;
      }

      toast({
        title: response.ruleType === RoutingRules.dedupekey ? 'Dedup Key' : 'Automation Rule',
        description: `${
          response.ruleType === RoutingRules.dedupekey ? 'Key' : 'Rule'
        } created successfully!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError,
  });
};

const deleteServiceRule = async ({
  serviceId,
  ruleId,
  ruleType,
  alertSourceName,
}: {
  serviceId: string;
  ruleId: string;
  ruleType: RoutingRules;
  alertSourceName?: string;
}) => {
  const url = `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}`;

  switch (ruleType) {
    case RoutingRules.suppression: {
      await Axios.delete(`${url}/suppression-rules/${ruleId}`);
      return { ruleType };
    }
    case RoutingRules.tagging: {
      await Axios.delete(`${url}/tagging-rules/${ruleId}`);
      return { ruleType };
    }
    case RoutingRules.deduplication: {
      await Axios.delete(`${url}/deduplication-rules/${ruleId}`);
      return { ruleType };
    }
    case RoutingRules.routing: {
      await Axios.delete(`${url}/routing-rules/${ruleId}`);
      return { ruleType };
    }
    case RoutingRules.dedupekey: {
      await Axios.delete(`${url}/overlays/dedup-key/${alertSourceName}`);
      return { ruleType };
    }
  }
};

/**
 * Delete Service Rule
 */
const useDeleteRuleMutation = (service_id: string) => {
  const queryClient = useQueryClient();

  return useMutation(deleteServiceRule, {
    ...reactQueryConfig,
    onSuccess: response => {
      switch (response.ruleType) {
        case RoutingRules.tagging:
          queryClient.refetchQueries([queryKeys.SERVICETAGGINGRULE, service_id]);
          reactQueryConfigSuccess('Tagging rule deleted');
          break;

        case RoutingRules.suppression:
          queryClient.refetchQueries([queryKeys.SERVICESUPPRESSIONRULE, service_id]);
          reactQueryConfigSuccess('Suppression rule deleted');
          break;

        case RoutingRules.routing:
          queryClient.refetchQueries([queryKeys.SERVICEROUTINGRULE, service_id]);
          reactQueryConfigSuccess('Routing rule deleted');
          break;

        case RoutingRules.deduplication:
          queryClient.refetchQueries([queryKeys.SERVICEDEDUPLICATEDRULE, service_id]);
          reactQueryConfigSuccess('Deduplication rule deleted');
          break;

        case RoutingRules.dedupekey:
          queryClient.refetchQueries([queryKeys.SERVICEDEDUPEKEY, service_id]);
          reactQueryConfigSuccess('Deduplication key deleted');
          break;
      }
    },
    onError,
  });
};

const changeServiceRulePriority = async ({
  serviceId,
  ruleId,
  ruleType,
  positionDetail,
}: {
  serviceId: string;
  ruleId: string;
  ruleType: RoutingRules;
  positionDetail: { position: IPriorityPosition };
}) => {
  switch (ruleType) {
    case RoutingRules.suppression: {
      const { data } = await Axios.patch<{ data: IServiceRules }>(
        `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/suppression-rules/${ruleId}/priority`,
        positionDetail,
      );
      return { data: data.data, ruleType };
    }
    case RoutingRules.tagging: {
      const { data } = await Axios.patch<{ data: IServiceRules }>(
        `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/tagging-rules/${ruleId}/priority`,
        positionDetail,
      );
      return { data: data.data, ruleType };
    }
    case RoutingRules.deduplication: {
      const { data } = await Axios.patch<{ data: IServiceRules }>(
        `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/deduplication-rules/${ruleId}/priority`,
        positionDetail,
      );
      return { data: data.data, ruleType };
    }
    case RoutingRules.routing: {
      const { data } = await Axios.patch<{ data: IServiceRules }>(
        `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/routing-rules/${ruleId}/priority`,
        positionDetail,
      );
      return { data: data.data, ruleType };
    }
    case RoutingRules.dedupekey: {
      const { data } = await Axios.patch<{ data: IServiceRules }>(
        `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/routing-rules/${ruleId}/priority`,
        positionDetail,
      );
      return { data: data.data, ruleType };
    }
  }
};

const useChangeRulePriorityMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(changeServiceRulePriority, {
    ...reactQueryConfig,
    onSuccess: response => {
      switch (response.ruleType) {
        case RoutingRules.tagging:
          queryClient.refetchQueries([queryKeys.SERVICETAGGINGRULE, response.data.service_id]);
          reactQueryConfigSuccess('Tagging rule priority updated');
          break;

        case RoutingRules.suppression:
          queryClient.refetchQueries([queryKeys.SERVICESUPPRESSIONRULE, response.data.service_id]);
          reactQueryConfigSuccess('Suppression rule priority updated');
          break;

        case RoutingRules.routing:
          queryClient.refetchQueries([queryKeys.SERVICEROUTINGRULE, response.data.service_id]);
          reactQueryConfigSuccess('Routing rule priority updated');
          break;

        case RoutingRules.deduplication:
          queryClient.refetchQueries([queryKeys.SERVICEDEDUPLICATEDRULE, response.data.service_id]);
          reactQueryConfigSuccess('Deduplication rule priority updated');
          break;

        case RoutingRules.dedupekey:
          queryClient.refetchQueries([queryKeys.SERVICEDEDUPEKEY, response.data.service_id]);
          reactQueryConfigSuccess('Deduplication key priority updated');
          break;
      }
    },
    onError,
  });
};

export {
  useServiceDeduplicationRulesQuery,
  useServiceTaggingRulesQuery,
  useServiceRoutingRulesQuery,
  useServiceSuppressionRulesQuery,
  useServiceDedupeKeysQuery,
  useServiceConfigQuery,
  useAlertSourceDedupeKeyQuery,
  useCreateRuleMutation,
  useUpdateRuleMutation,
  useDeleteRuleMutation,
  useChangeRulePriorityMutation,
  useUpdateServiceConfigMutation,
};
