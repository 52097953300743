import { DateFilterChoice } from '../../graphql/generated/types';
import { filterDropdownOptions } from '../../interfaces/common';

export const CREATED_OPTIONS: filterDropdownOptions[] = [
  { label: 'Last 24 Hours', value: '1' },
  { label: 'This Week', value: DateFilterChoice.Week },
  { label: 'This Month', value: DateFilterChoice.Month },
  { label: 'Last 7 Days', value: '7' },
  { label: 'Last 14 Days', value: '14' },
  { label: 'Last 30 Days', value: '30' },
  { label: 'Date Range', value: DateFilterChoice.Range },
];

export const RADIO_OPTIONS = [
  { val: 'yes', label: 'Yes' },
  { val: 'no', label: 'No' },
];

export function cleanObject(obj: any) {
  const newObj: any = {};

  Object.keys(obj).forEach(key => {
    const value = obj[key];

    if (key === 'Me and My Squads' && value === false) {
      return;
    }

    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const cleanedValue = cleanObject(value);
      if (Object.keys(cleanedValue).length > 0) {
        newObj[key] = cleanedValue;
      }
    } else if (Array.isArray(value)) {
      if (value.length > 0) {
        newObj[key] = value;
      }
    } else if (value !== null && value !== undefined && value !== '') {
      newObj[key] = value;
    }
  });

  return newObj;
}
