import { Box, FormLabel, useBoolean, Flex, Text, HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { InfoToolTip } from '../atoms/InfoToolTip';

type Props = {
  label?: string;
  maxLimit?: number;
  isChildWrapper?: boolean;
  description?: string;
  value: any;
  children: ReactNode;
  onClear: () => void;
};

const FormControlWrapper = ({
  children,
  label,
  maxLimit,
  description,
  value,
  onClear,
  isChildWrapper = false,
}: Props) => {
  const [isFocused, setIsFocused] = useBoolean();
  const hasValue = Array.isArray(value) ? !!value.length : !!value;

  return (
    <Box
      py={4}
      px={isChildWrapper ? 0 : 4}
      onMouseEnter={setIsFocused.on}
      onMouseLeave={setIsFocused.off}
      {...(isChildWrapper ? null : { bgColor: isFocused ? 'primary.200' : 'white' })}
    >
      {label && (
        <Flex alignItems="center" justifyContent="space-between" mb={2}>
          <HStack alignItems="center">
            <FormLabel my={0} mx="2px" fontSize="16px" color="secondary.700" fontWeight="800">
              {label}
            </FormLabel>
            {description && <InfoToolTip pl={0} label={description} color="secondary.700" />}
            {maxLimit && <Text fontSize="12px">(you can select upto {maxLimit} values)</Text>}
          </HStack>
          {isFocused && hasValue && (
            <Text cursor="pointer" variant="hint_700" color="secondary.950" onClick={onClear}>
              Clear
            </Text>
          )}
        </Flex>
      )}
      {children}
    </Box>
  );
};

export default FormControlWrapper;
