import Axios from 'axios';
import { API } from 'core';
import { SloArray, slo } from 'core/interfaces/IIncidents';
import { useToast } from 'library/atoms';
import { useQuery } from 'react-query';

const getSloListApi = async () => {
  const response = await Axios.get<{ data: SloArray }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo?owner_id=${API.config.teamId}`,
  );
  return response.data.data;
};

const getSloDetailsApi = async (sloId: number) => {
  const response = await Axios.get<{ data: { slo: slo } }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo/${sloId}?owner_id=${API.config.teamId}`,
  );
  return response.data.data;
};

const useSloList = () => {
  const toast = useToast();
  return useQuery(['sloList'], getSloListApi, {
    onError: () => {
      toast({ text: 'Fetch slo list failed', status: 'error' });
    },
  });
};

const useSloDetails = (sloId: number | undefined) => {
  const toast = useToast();
  return useQuery(['sloDetails', sloId], () => getSloDetailsApi(sloId!), {
    enabled: !!sloId,
    onError: () => {
      toast({ text: 'Fetch slo details failed', status: 'error' });
    },
  });
};

export { useSloList, useSloDetails };
