import React, { PropsWithChildren } from 'react';
import { Box, Text, Flex, BoxProps, TextProps } from '@chakra-ui/layout';

type Props = unknown;
function EventCard(props: PropsWithChildren<Props>) {
  return <Box maxWidth="284px">{props.children}</Box>;
}

function Wrapper(props: BoxProps) {
  return (
    <Box borderRadius="4" p={3} my={1} mx={0} bgColor={props.bgColor ?? '#09305A'}>
      {props.children}
    </Box>
  );
}

function Row({
  title,
  content,
  color,
  borderTop,
  isBgDark,
  additionTitleProps = {},
  additionalContentProps = {},
  ...props
}: PropsWithChildren<{
  title: React.ReactNode;
  content: React.ReactNode;
  color?: string;
  isBgDark?: boolean;
  additionTitleProps?: TextProps;
  additionalContentProps?: TextProps;
}> &
  BoxProps) {
  return (
    <Flex my={'2.5'} alignItems="center" {...props}>
      <Text
        fontSize={12}
        fontWeight="bold"
        textTransform="uppercase"
        color={isBgDark ? '#FFFFFF' : color ?? '#1D426B'}
        borderTop={borderTop}
        whiteSpace="pre-wrap"
        {...additionTitleProps}
      >
        {title}
      </Text>
      <Text
        fontSize={10}
        color={isBgDark ? '#FFFFFF' : color ?? '#627C98'}
        fontWeight="700"
        {...additionalContentProps}
      >
        {content}
      </Text>
    </Flex>
  );
}

EventCard.Wrapper = Wrapper;
EventCard.Row = Row;

export default EventCard;
