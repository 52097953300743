import React from 'react';
import { Flex } from '@chakra-ui/react';
import { schedulesOverrideColor } from '../../constants/schedules.rotation-template';

export const OverrideParticipantWrapper = ({ children }: { children: JSX.Element }) => {
  return (
    <Flex borderWidth="2px" borderRadius="50%" borderColor={schedulesOverrideColor} padding="1.5px">
      {children}
    </Flex>
  );
};
