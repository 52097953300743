import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/layout';
import FormField from 'library/molecules/FormField/FormField';
import { THEME_COLORS } from 'library/theme/colors';
import { useFormContext } from 'react-hook-form';
import { StatusPageAction } from 'views/main/organization/workflow/types';
import NestedDropdown from 'views/main/organization/statuspage-v2/components/NestedDropdown';
import { IOptionComponent } from 'views/main/organization/statuspage-v2/Interface';
import { getSelectedComponents } from 'views/main/organization/statuspage-v2/helpers/helper.service';
import FrameWorkDropdown from 'library/atoms/FrameworkDropdown';

interface Ioptions {
  checked: boolean;
  isCollapsible: boolean;
  isHidden: boolean;
  isOpen: boolean;
  label: string;
  status: { label: string; value: number };
  value: number;
}

const ImpactForm = ({ statusPageDetail, err }: { statusPageDetail: any; err: string }) => {
  const { setValue, watch, getValues } = useFormContext<StatusPageAction>();

  const handleComponentSelect = (componentOptions: IOptionComponent[]) => {
    const selectedComponents = getSelectedComponents(componentOptions, []);

    const cleanValue = selectedComponents.map(
      ({
        id,
        label,
        value,
      }: {
        id: number;
        label: string;
        value: { label: string; value: number };
      }) => ({
        component_id: id,
        impact_status_id: value.value,
        component_label: label,
      }),
    );

    setValue('data.component_and_impact', cleanValue);
  };
  const impactValue = (impactID: number) => {
    if (statusPageDetail.statusCodes) {
      return statusPageDetail.statusCodes.filter(
        ({ value }: { value: number }) => value == impactID,
      )[0];
    }
  };
  const componentNames = (componentID: number) => {
    let componentGroupName;
    statusPageDetail.allComponents.forEach(
      ({ label, value, options }: { label: string; value: number; options: Ioptions[] }) => {
        if (value === componentID) {
          componentGroupName = label;
        } else {
          const foundComponent = options?.find(
            ({ value }: { value: number }) => value === componentID,
          );

          if (foundComponent) {
            componentGroupName = `${label} / ${foundComponent.label}`;
          }
        }
      },
    );
    return componentGroupName;
  };
  return (
    <Box
      py={2}
      pl={4}
      pr={8}
      backgroundColor={THEME_COLORS.primary[100]}
      borderBottom={`1px solid ${THEME_COLORS.primary[500]} `}
    >
      <FormField label="Select Component and Impact" error={err} isRequired>
        <NestedDropdown
          componentGroupOption={statusPageDetail.allComponents ?? []}
          disabled={false}
          setOption={handleComponentSelect}
        />
        {watch('data.component_and_impact').length > 0 ? (
          <Flex mt={4} direction="column">
            {watch('data.component_and_impact').map((comp, id: number) => {
              return (
                <Flex key={comp.component_id} align="center" mb="4">
                  <Flex flex={1} align="center">
                    <Text variant="formInput" fontSize="sm" fontWeight="semibold">
                      {comp.component_label ?? componentNames(comp.component_id)}
                    </Text>
                  </Flex>
                  <Flex flex={1}>
                    <FormField error={''} isRequired>
                      <FrameWorkDropdown<{ label: string; value: number }>
                        value={impactValue(
                          watch(`data.component_and_impact.${id}.impact_status_id`),
                        )}
                        options={statusPageDetail.statusCodes ?? []}
                        placeholder="Select"
                        onChange={val => {
                          setValue(`data.component_and_impact.${id}.impact_status_id`, val!.value);
                        }}
                      />
                    </FormField>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        ) : null}
      </FormField>
    </Box>
  );
};

export default React.memo(ImpactForm);
