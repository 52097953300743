import createDOMPurify from 'dompurify';

const DOMPurifyClass = () => {
  const DOMPurify = createDOMPurify();

  // Add a hook to make all links open a new window
  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
    }
    // set non-HTML/MathML links to xlink:show=new
    if (
      !node.hasAttribute('target') &&
      (node.hasAttribute('xlink:href') || node.hasAttribute('href'))
    ) {
      node.setAttribute('xlink:show', 'new');
    }
  });

  return DOMPurify;
};

const DOMPurify = DOMPurifyClass();
export default DOMPurify;
