import { Avatar, Box, HStack, VStack } from '@chakra-ui/react';
import { UsernameIcon } from 'icons';
import { AssigneeAvatar } from 'library/atoms';
import { AssigneeType } from 'library/enums';
import { THEME_COLORS } from 'library/theme/colors';
import React from 'react';
import { Para } from 'uie/components';
import { EntityType } from 'views/main/organization/navigation-flows/helpers';
import useUINavigationFunctions from 'views/main/organization/navigation-flows/useUINavigationFunctionV2';

interface IProps {
  id: string;
  name: string;
  type: string;
  username: string;
  rightSection?: React.ReactNode;
  withoutUsernameIcon?: boolean;
}

const IncidentSummaryParticipantProfile: React.FC<IProps> = (props: IProps) => {
  const { getEntityComponent } = useUINavigationFunctions();

  return (
    <VStack alignItems="flex-start" mb="6px">
      <HStack alignItems="center" style={{ marginBottom: props.type === 'user' ? '0px' : '8px' }}>
        {props.type === 'user' ? (
          <Avatar size="sm" name={props.name ? props.name : 'Deleted User'} src="#" />
        ) : (
          <Box
            className={
              props.type === 'squad'
                ? 'incident_summary_participant_squad'
                : 'incident_summary_participant_ep'
            }
            display="flex"
            justifyContent="center"
            alignItems="center"
            w="32px"
            h="32px"
          >
            <AssigneeAvatar
              name={props.name ? props.name : 'Deleted'}
              type={props.type as AssigneeType}
            />
          </Box>
        )}
        <HStack alignItems="center" spacing={1}>
          {getEntityComponent({
            componentType: props.type === 'user' ? 'linkWPopover' : 'linkWOPopover',
            id: props.id,
            name: props.name,
            type: props.type as EntityType,
            popoverPlacement: 'auto',
          })}
          {props.rightSection && typeof props.rightSection === 'string' ? (
            <Para fontSize={14} color={THEME_COLORS.secondary[700]}>
              {props.rightSection}
            </Para>
          ) : (
            props.rightSection
          )}
        </HStack>
      </HStack>
      {props.type === 'user' && (
        <HStack
          mt={'0px !important'}
          alignItems="flex-start"
          style={{ position: 'relative', left: '40px' }}
          spacing={1}
        >
          {!props.withoutUsernameIcon && <UsernameIcon style={{ marginTop: -2 }} />}
          <Para fontSize={14} color={THEME_COLORS.secondary[700]} style={{ lineHeight: '10px' }}>
            {props.username ? props.username : 'Deleted User'}
          </Para>
        </HStack>
      )}
    </VStack>
  );
};

export default IncidentSummaryParticipantProfile;
