import DrawerFooterWrapper from 'library/molecules/Drawer';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import { httpCallValidation } from 'views/main/organization/workflow/constant/validation';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { HttpCallAction } from 'views/main/organization/workflow/types';
import { FormFooter } from '../form-footer';
import HttpCallForm from './HttpCallForm';

const HttpCallContainer = () => {
  const { actionForm, onActionSubmit, checkDirtyAndCloseDrawer } = useWorkflowFormContext();
  return (
    <FormWrapper<HttpCallAction>
      onSubmit={onActionSubmit}
      defaultValues={actionForm! as HttpCallAction}
      validationSchema={httpCallValidation}
    >
      <HttpCallForm />
      <DrawerFooterWrapper>
        <FormFooter<HttpCallAction> checkDirtyAndCloseDrawer={checkDirtyAndCloseDrawer} />
      </DrawerFooterWrapper>
    </FormWrapper>
  );
};

export default HttpCallContainer;
