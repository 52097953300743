import { CardType, ICommunicationCard } from 'core/interfaces/ICommunicationCard';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { ArchiveIcon, DeleteIcon, EditIcon } from 'icons';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import React from 'react';
import styled from 'styled-components';
import Card from './Card';
import { DynamicIcon } from './InputwithLabel';

const Wrapper = styled.div`
  margin-top: 18px;
`;

const attachmentIconProps = {
  fontSize: 8,
  style: {
    marginRight: '8px',
  },
};

const CardWrapper = styled.div<{
  isArchieved?: boolean;
  updateDisabled?: boolean;
}>`
  position: relative;

  background-color: ${props => (props.isArchieved ? '#f3f7fd' : '#fff')};

  .iconWrapper {
    position: absolute;
    right: 0px;
    top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: ${props => (!props.updateDisabled ? '#d6e0f0' : '#bdbdbd')};
      cursor: ${props => (!props.updateDisabled ? 'pointer' : 'not-allowed')};
    }
  }
  .r10 {
    right: 30px;
  }

  &:hover {
    background-color: #f3f7fd;
    .iconWrapper {
      opacity: ${props => (!props.updateDisabled ? 1 : 0.5)};
    }
  }
`;

export const CardList: React.FC<{
  cards: Array<ICommunicationCard>;
  onClickCard: (card: ICommunicationCard) => void;
  setCardToEdit: (card: ICommunicationCard) => void;
  onDeleteIconClick: (cardId: string) => void;
  onArchiveIconClick: (cardId: string) => void;
  isSuppressedIncident: boolean;
}> = ({
  cards,
  onClickCard,
  setCardToEdit,
  onArchiveIconClick,
  onDeleteIconClick,
  isSuppressedIncident,
}) => {
  const isCardTypeSlack = (card: ICommunicationCard) => {
    return card.type === 'slack';
  };

  const isCardTypeMsTeamsMeetingLink = (card: ICommunicationCard) => {
    return card.type === CardType.msTeamsMeetingLink;
  };

  const handleDeleteClick = (cardId?: string) => {
    if (cardId) {
      onDeleteIconClick(cardId);
    }
  };

  const handleArchiveIconClick = (channelId?: string) => {
    if (channelId) {
      onArchiveIconClick(channelId);
    }
  };

  const canShowArchieveIcon = (card: ICommunicationCard) => {
    return isCardTypeSlack(card) && card.archived_at == null;
  };

  const hasUpdateAccess = useUserAccess().hasUpdateAccess('incidents');

  if (!cards) {
    return null;
  }

  return (
    <Wrapper>
      {cards.map(card => (
        <CardWrapper
          key={card.id}
          isArchieved={card?.archived_at !== null}
          updateDisabled={!hasUpdateAccess}
        >
          <Card
            icon={<DynamicIcon type={card.type} {...attachmentIconProps} />}
            title={card.title || card.url}
            onClick={() => onClickCard(card)}
          />
          {!isCardTypeSlack(card) &&
          !isCardTypeMsTeamsMeetingLink(card) &&
          !isSuppressedIncident ? (
            <>
              <NoPermissionTooltip isDisabled={hasUpdateAccess}>
                <div
                  className="iconWrapper r10"
                  onClick={hasUpdateAccess ? () => setCardToEdit(card) : () => {}}
                >
                  <EditIcon height={12} width={12} />
                </div>
              </NoPermissionTooltip>
              <NoPermissionTooltip isDisabled={hasUpdateAccess}>
                <div
                  className="iconWrapper"
                  onClick={hasUpdateAccess ? () => handleDeleteClick(card.id) : () => {}}
                >
                  <DeleteIcon height={12} width={12} />
                </div>
              </NoPermissionTooltip>
            </>
          ) : null}
          {canShowArchieveIcon(card) && !isSuppressedIncident ? (
            <>
              <NoPermissionTooltip isDisabled={hasUpdateAccess}>
                <div
                  className="iconWrapper"
                  onClick={
                    hasUpdateAccess ? () => handleArchiveIconClick(card.channel_id) : () => {}
                  }
                >
                  <ArchiveIcon height={12} width={12} />
                </div>
              </NoPermissionTooltip>
            </>
          ) : null}
        </CardWrapper>
      ))}
    </Wrapper>
  );
};
