import { IAppState } from 'core/interfaces/IAppState';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getEntitiesData, getEntityCounts } from '../api';
import {
  EntityIdentifiers,
  EntityList,
  EntityListPayload,
  EntityNames,
  EntityType,
} from '../types';
import { convertToEntityTableData } from '../utils';
import { useToast } from 'library/atoms';
import { useSquadListing } from '../../../squads/hooks';

export const useGetEntitiesCount = () => {
  const { id: teamId } = useParams<{ id: string }>();

  return useQuery({
    queryKey: ['entity', teamId],
    queryFn: () => getEntityCounts(teamId),
    select: data =>
      Object.keys(data.data.data).map((key: string) => ({
        name: EntityNames[key as EntityType],
        id: key,
        count: data.data.data[key as EntityType],
      })) as EntityList,
  });
};

export const useGetEntitiesData = ({ payload }: { payload: EntityListPayload }) => {
  const { id: teamId } = useParams<{ id: string }>();

  const users = useSelector((state: IAppState) => state.organization.users.u);

  const { data: squads } = useSquadListing();

  const toast = useToast();

  return useQuery({
    queryKey: [
      'entity-data',
      payload.type,
      teamId,
      payload.page_limit,
      payload.page_number,
      payload.text_filter,
      payload.entity_owner,
    ],
    queryFn: () =>
      getEntitiesData(teamId, {
        type: payload.type,
        page_limit: payload.page_limit,
        page_number: payload.page_number,
        text_filter: payload.text_filter,
        entity_owner: payload.entity_owner,
      }),
    select: data =>
      convertToEntityTableData(
        data.data,
        payload.type as keyof typeof EntityIdentifiers,
        users,
        squads?.data?.data?.map(squad => ({
          id: squad.id,
          name: squad.name,
        })) ?? [],
      ),

    onError: (error: any) => {
      toast({
        status: 'error',
        text: error?.response?.data?.meta?.error_message ?? 'Something went wrong',
      });
    },
  });
};
