import React from 'react';
import { DialogModalFrame, Grid, Para, TextButton, Theme } from 'uie/components';

interface IWarningDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  contentText: string;
  titleText: string;
  open: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

const { theme } = Theme;

export default function WarningDialog(props: IWarningDialogProps) {
  return (
    <DialogModalFrame
      root="unsaved-changes-confirm"
      id="modalFrame"
      onClose={props.onCancel}
      padding="32px"
      width="520px"
    >
      {props.open && (
        <>
          <Para fontSize={26} fontWeight={500} className="mb-10 mt-10">
            Unsaved Changes
          </Para>

          <Para fontSize={16} fontWeight={400} color={theme.shades.cement}>
            You haven't saved your changes. Are you sure you want to discard the changes?
          </Para>

          <Grid style={{ marginTop: 28 }}>
            <TextButton onClick={props.onCancel} style={{ padding: '4px 8px' }} className="mr-20">
              <Para color={theme.font.white} fontWeight={500} fontSize={16}>
                {props.cancelButtonText || 'Keep Editing'}
              </Para>
            </TextButton>

            <TextButton buttonType="ghost" style={{ padding: '4px 8px' }} onClick={props.onConfirm}>
              <Para color={theme.font.default} fontWeight={500} fontSize={16}>
                {props.confirmButtonText || 'Discard Changes'}
              </Para>
            </TextButton>
          </Grid>
        </>
      )}
    </DialogModalFrame>
  );
}
