import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import './index.css';
import {
  IComponentState,
  IComponentErrorState,
} from '../../../../../core/interfaces/IComponentState';
import render from './render';
import { FileUploadService, IncidentService } from '../../../../../core/services';
import { exception } from '../../../../../core/exception';
import { T_WA_GS_INCIDENT_CREATED_MANUALLY } from '../../../../../core/const/tracker';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { getAssignableTeamMembers } from '../../../../../shared/reducers';
import { getPriorityValue } from 'library/common';
import { IncidentPriorityFilter } from '../../incident-list/graphql/generated/types';
type IProps = Pick<IAppState, 'organization' | 'userInfo'>;

type TagsMapType = {
  keys: string[];
  valuesMap: { [key: string]: string[] };
};
interface IState {
  componentState: IComponentState;
  title: string;
  description: string;
  serviceId: string;
  serviceSearch: string;
  assign: {
    id: string;
    type: 'user' | 'squad' | 'escalation';
  };
  assignSearch: string;
  tags: {
    key: string;
    value: string;
    color: string;
  }[];
  errors: IComponentErrorState;
  saved: boolean;
  orgTags: TagsMapType;
  priority: IncidentPriorityFilter;
}

export class CreateIncident extends Component<IProps, IState> {
  private _incidentService = new IncidentService();
  public fileUploadService = new FileUploadService();

  public oService: any = (this.props.organization.services || []).s.reduce((c: any, n) => {
    c[n.id] = {
      _id: n.id,
      name: n.name,
      apiKey: n!.api_key,
      escalationPolicy: n.escalation_policy_id,
    };
    return c;
  }, {});

  public oUser: any = getAssignableTeamMembers(
    this.props.organization.selectedTeam.team!,
    this.props.organization.users.u,
  ).reduce((c: any, n) => {
    c[n.id] = {
      _id: n.id,
      name: `${n.first_name} ${n.last_name}`,
      username: n.username_for_display,
    };
    return c;
  }, {});

  public oSquad: any = this.props.organization.squads.s.reduce((c: any, n) => {
    c[n.id] = {
      _id: n.id,
      name: n.name,
    };
    return c;
  }, {});

  public oEscalation: any = this.props.organization.escalations.e.reduce((c: any, n) => {
    c[n.id] = {
      _id: n.id,
      name: n.name,
    };
    return c;
  }, {});

  public assignes = {
    user: this.oUser,
    squad: this.oSquad,
    escalation: this.oEscalation,
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      componentState: 'idle',
      title: '',
      description: '',
      serviceId:
        props.organization.services.s.length === 1
          ? props.organization.services.s[0]?.id ?? ''
          : '',
      serviceSearch: '',
      assign:
        props.organization.services.s.length === 1
          ? {
              type: 'escalation',
              id: props.organization.services.s[0]?.escalation_policy_id ?? '',
            }
          : { type: 'user', id: '' },
      assignSearch: '',
      tags: [
        {
          key: '',
          value: '',
          color: '#0C93E3',
        },
      ],
      errors: {},
      saved: false,
      orgTags: {
        keys: [],
        valuesMap: {},
      },
      priority: IncidentPriorityFilter.Unset,
    };
  }

  componentDidMount() {
    this.getOrgTags();
  }

  getOrgTags = async () => {
    try {
      const {
        data: { data: incidentTags },
      } = await this._incidentService.getAllTags();

      let tagKeys = incidentTags.reduce((acc: string[], item) => {
        const tags = item.tags.map(i => i.key as string);
        acc.push(...tags);
        return acc;
      }, []);

      tagKeys = Array.from(new Set(tagKeys));

      const tagValueMap = incidentTags.reduce((acc: { [key: string]: string[] }, item) => {
        item.tags.forEach(i => {
          acc[i.key] =
            acc[i.key] && acc[i.key].length
              ? Array.from(new Set([...i.values, ...acc[i.key]]))
              : Array.from(new Set(i.values));
        });

        return acc;
      }, {});

      this.setState({
        orgTags: {
          keys: tagKeys,
          valuesMap: tagValueMap,
        },
      });
    } catch (err: any) {
      exception.handle('E_GET_ALL_TAGS_INCIDENTS', err);
    }
  };

  onCancel = () => (this.props as any).history.goBack();

  onTextChange =
    (type: 'title' | 'serviceSearch' | 'assignSearch') =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const newState: any = {
        [type]: value,
      };
      this.setState(newState);
    };

  onDescriptionChange = (description: string) => this.setState({ description });

  onSelectChange = (_: any, value: string) =>
    this.setState({
      serviceId: value as string,
      assign: { type: 'escalation', id: this.oService[value]?.escalationPolicy ?? '' },
    });

  onAssignTypeChange = (_: any, type: 'user' | 'squad' | 'escalation') => {
    this.setState({ assign: { type, id: '' }, assignSearch: '' });
  };

  onAssign = (_: any, id: string) =>
    this.setState(({ assign }) => {
      assign.id = id;
      return { assign };
    });

  handleTagChange = (tagIndex: number) => (key: string, value: string) => {
    this.setState(({ tags }) => {
      tags[tagIndex].key = key;
      tags[tagIndex].value = value;

      return { tags };
    });
  };

  setTagValue = (type: 'key' | 'value' | 'color', i: number, c?: string) => (e: any) => {
    if (type === 'color') {
      this.setState(({ tags }) => {
        tags[i].color = c || '#fff';
        return { tags };
      });
      return;
    }

    const { value } = e.target;
    this.setState(({ tags }) => {
      tags[i][type] = value;
      return { tags };
    });
  };

  addTag = () => {
    this.setState(({ tags }) => {
      tags.push({
        color: '#0C93E3',
        key: '',
        value: '',
      });

      return { tags };
    });
  };

  removeTag = (i: number) => () => {
    this.setState(({ tags }) => {
      tags.splice(i, 1);
      if (tags.length === 0) {
        tags.push({
          color: '#0C93E3',
          key: '',
          value: '',
        });
      }
      return { tags };
    });
  };

  onVerify = () => {
    const { title, serviceId, assign, tags } = this.state;
    const errors: any = {};

    if (title.length === 0) {
      errors.title = 'Title is required';
    }

    if (serviceId.length === 0) {
      errors.serviceId = 'Service is required';
    }

    if (assign.id.length === 0) {
      errors.assignId = 'Assignee is required';
    }

    const validKey = new RegExp('^[a-zA-Z0-9_]+$');
    const tagsArr: string[] = [];

    tags.forEach((tag, i) => {
      if (tag.key === '' && tag.value === '') return;

      if (tag.key === '' || tag.value === '') {
        errors[`tag_${i}`] = "Can't leave key or value empty";
      }

      if (!validKey.test(tag.key)) {
        errors[`tag_${i}`] = 'Invalid tag key (Should not contain special characters)';
      }

      if (tagsArr.includes(`${tag.key}:${tag.value}`)) {
        errors[`tag_${i}`] = 'Selected tag already exists. Choose different value';
      } else {
        tagsArr.push(`${tag.key}:${tag.value}`);
      }
    });

    this.setState({ errors });
    return Object.entries(errors).length === 0;
  };

  onSave = async () => {
    if (!this.onVerify()) {
      return;
    }

    const { title: message, description, assign, serviceId, tags, priority } = this.state;
    const priorityValue = getPriorityValue(priority);

    AppTracker.track(T_WA_GS_INCIDENT_CREATED_MANUALLY, {
      'Incident Priority Chosen': priorityValue,
    });

    this.setState({ componentState: 'busy' });

    try {
      await this._incidentService.create({
        message,
        description,
        assignee: {
          id: assign.id,
          type: assign.type === 'escalation' ? 'escalationpolicy' : (assign.type as any),
        },
        tags: tags.reduce((c: any, nt: any, i) => {
          if (i === tags.length) {
            return c;
          }
          c[nt.key] = {
            value: nt.value,
            color: nt.color,
          };
          return c;
        }, {}),
        serviceApiKey: this.oService[serviceId].apiKey,
        created_by: this.props.userInfo.d!.id,
        attachments: this.fileUploadService.accessAttachmentArrayForIncident(),
        priority: priorityValue,
      });
      this.fileUploadService.emptyAttachmentArrayForIncident();
      this.setState({ saved: true }, () => this.onCancel());
    } catch (err: any) {
      this.setState({
        errors: {
          network: 'Looks like there is a network error. Please try again.',
        },
      });
      exception.handle('E_CREATE_INCIDENT', err);
    } finally {
      this.setState({ componentState: 'idle' });
    }
  };

  checkDirty = () => {
    if (this.state.saved) return false;

    const { description, serviceId, assign, tags, priority } = this.state;

    return (
      this.state.title !== '' ||
      description !== '' ||
      serviceId !== '' ||
      assign.id !== '' ||
      getPriorityValue(priority) != null ||
      tags.length > 1 ||
      tags[0].key !== '' ||
      tags[0].value !== ''
    );
  };
  render = render;
}

export default connect(({ organization, userInfo }: IAppState) => ({
  organization,
  userInfo,
}))(CreateIncident);
