/** global __REDUX_DEVTOOLS_EXTENSION_COMPOSE__  */
import { applyMiddleware, createStore, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import rootEpic from '../epic';
import { limits } from '../Limits';
import rootReducer from '../reducer';
import storage from 'redux-persist/lib/storage';
import { allInitOrgActions } from 'core/epic/organization/allEpics';
/**
 * @description init and configure redux store
 * @param userMiddleWares react debug tools
 * @param storage redux main store
 * @param appVersion
 * @returns {store, persistor} {redux.StoreCreator, Persistor}
 */
const configureStore = (
  userMiddleWares: any[] = [],
  appVersion: string,
): { store: any; persistor: Persistor } => {
  const persistConfig = {
    storage,
    appVersion,
    key: 'root',
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const epicMiddleware = createEpicMiddleware();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore

  const actionSanitizer = action =>
    action.type === 'REQUEST_LOGIN'
      ? {
          ...action,
          payload: {
            ...action.payload,
            password: undefined,
          },
        }
      : action;

  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionSanitizer })) ||
    compose;

  const store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(epicMiddleware, limits.reducer, allInitOrgActions, ...userMiddleWares),
    ),
  );

  const persistor = persistStore(store);
  epicMiddleware.run(rootEpic);
  return { store, persistor };
};

export { configureStore };
