import { Pill } from 'uie/components';
import { IEntityOwner } from 'core/interfaces/IEscalationPolicy';
import { ISquad } from 'core/interfaces/ISquad';
import { ITeam } from 'core/interfaces/ITeams';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { SquadIcon, TeamIcon, UserIcon } from 'icons';
import { getEntityOwnerValue } from './owner_entities_select';
import useUINavigationFunctions from '../../navigation-flows/useUINavigationFunctionV2';
import { THEME_COLORS } from 'library/theme/colors';
import { Text, Tooltip, VStack } from '@chakra-ui/react';
import { EntityType } from '../../navigation-flows/helpers';

interface Props {
  entityOwner: IEntityOwner;
  currentTeam: ITeam;
  squads: ISquad[];
  users: IUsersOfOrganization[];
}

export function EntityOwnerPill(props: Props) {
  const entityOwnerValue = getEntityOwnerValue(props);
  const { getEntityComponent } = useUINavigationFunctions();

  if (!entityOwnerValue) {
    return <></>;
  }
  const fullName = entityOwnerValue.label.replace(` (${entityOwnerValue.username})`, '');
  const simplePillComponent = (
    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      <div style={{ marginRight: '5px' }}>
        {entityOwnerValue.type === 'user' && <UserIcon height={13} width={13} />}
        {entityOwnerValue.type === 'squad' && <SquadIcon height={13} width={13} />}
        {entityOwnerValue.type === 'team' && <TeamIcon height={13} width={13} />}
      </div>
      <Tooltip
        label={
          entityOwnerValue.label.length > 30 ? (
            <VStack alignContent={'left'} spacing={0}>
              <Text maxW={'40ch'} fontWeight={'bold'}>
                {entityOwnerValue.username ? fullName : entityOwnerValue.label}
              </Text>
              <Text maxW={'40ch'}>{entityOwnerValue.username}</Text>
            </VStack>
          ) : null
        }
        placement="bottom"
        hasArrow
        bg={THEME_COLORS.secondary[100]}
      >
        <Text className="ep-owner-name" color="#0d2149" isTruncated maxWidth={'30ch'}>
          {fullName}
        </Text>
      </Tooltip>
    </div>
  );

  const pillComponent = getEntityComponent({
    componentType: props.entityOwner.type === 'user' ? 'linkWPopover' : 'linkWOPopover',
    id: props.entityOwner.id,
    type: props.entityOwner.type as EntityType,
    popoverTrigger: simplePillComponent,
    popoverPlacement: 'bottom',
  });

  return <Pill>{pillComponent}</Pill>;
}
