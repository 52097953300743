import { useOutsideClick } from '@chakra-ui/hooks';
import {
  Accordion,
  CardBlock,
  FormBlock,
  Grid,
  IconButton,
  InputBlock,
  Label,
  Para,
  SpinLoader,
  TextButton,
  Theme,
} from 'uie/components';
import { IAppState } from 'core/interfaces/IAppState';
import { AddIcon, CloseIcon } from 'icons';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import { TEAM_SQUADS_PATH } from 'views/main/routes/routes';
import { useCreateSquad, useUpdateSquadName } from '../../hooks/useSquad';
import { Squads } from '../../types';
import SearchSquad from './SearchSquad';
import SquadItem from './SquadItem';
import { List, SidebarContainer, SidebarHeader, Title } from './styles';

type Props = {
  squads: Squads;
};

const Sidebar = ({ squads }: Props) => {
  const { theme } = Theme;
  const [openAccordion, setOpenAccordion] = useState<'new-squad' | 'edit-squad' | undefined>();
  const [newSquadName, setNewSquadName] = useState('');

  const location = useLocation();
  const hash = location.hash;
  const selectedId = hash.replace('#', '');
  const params = useParams<{ id: string }>();

  const history = useHistory();

  useEffect(() => {
    if (!selectedId && squads?.length > 0) {
      history.push(
        generatePath(TEAM_SQUADS_PATH, {
          id: params.id,
          sqid: squads[0].id,
        }),
      );
    }
  }, [selectedId, squads, params.id, history]);

  const { mutate: createSquad, isLoading } = useCreateSquad({
    onSuccess: response => {
      setOpenAccordion(undefined);
      const id = response.data.data.id;
      history.push(
        generatePath(TEAM_SQUADS_PATH, {
          id: params.id,
          sqid: id,
        }),
      );
    },
  });

  const { mutate: mutateName, isLoading: isMutateNameLoading } = useUpdateSquadName({
    onSuccess: () => setOpenAccordion(undefined),
  });

  const currentUser = useSelector((state: IAppState) => state.organization.currentUser.u);

  const onFormSubmit = () => {
    if (openAccordion === 'edit-squad') {
      mutateName({
        name: newSquadName,
        squadId: selectedId,
      });
    } else {
      createSquad({
        name: newSquadName,
        members: [{ user_id: currentUser?.id ?? '', role: 'owner' }],
      });
    }
    setNewSquadName('');
  };

  const formRef = useRef(null);

  useOutsideClick({
    ref: formRef,
    handler: () => {
      setOpenAccordion(undefined);
      setNewSquadName('');
    },
  });

  const renderAccordionTitle = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Title>Squads ({squads.length})</Title>
      <IconButton
        onClick={() => {
          setOpenAccordion(prev =>
            prev === 'new-squad' || prev === 'edit-squad' ? undefined : 'new-squad',
          );
          setNewSquadName('');
        }}
      >
        {openAccordion !== 'new-squad' && openAccordion !== 'edit-squad' ? (
          <AddIcon height={20} width={20} />
        ) : (
          <CloseIcon height={20} width={20} />
        )}
      </IconButton>
    </div>
  );

  const renderForm = () => (
    <CardBlock
      style={{
        background: theme.shades.white,
        width: '242px',
        height: '148px',
        padding: '18px 9px',
        gap: '9px',
        borderRadius: '4px',
        boxShadow: '0px 4px 15px 0px rgba(152, 152, 152, 0.25)',
        position: 'relative',
        left: '-8px',
      }}
      className="mt-10"
      ref={formRef.current}
    >
      <FormBlock onFormSubmit={onFormSubmit}>
        <Label fontSize={14}>
          {(openAccordion === 'edit-squad' && 'Edit Squad Name') ||
            (openAccordion === 'new-squad' && 'Add New Squad')}
        </Label>

        <InputBlock
          className="mt-5"
          style={{ height: 32, padding: 4 }}
          value={newSquadName}
          onChange={e => setNewSquadName(e.target.value)}
          placeholder="Name"
          autoFocus={true}
        />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '18px',
          }}
        >
          <TextButton
            onClick={onFormSubmit}
            disabled={newSquadName.length === 0 || isLoading || isMutateNameLoading}
          >
            <Grid alignItems="center">
              <Para color={theme.shades.white}>
                {(openAccordion === 'edit-squad' && 'Update') ||
                  (openAccordion === 'new-squad' && 'Create')}
              </Para>
              {(isLoading || isMutateNameLoading) && <SpinLoader color={theme.shades.white} />}
            </Grid>
          </TextButton>
          <TextButton
            onClick={() => {
              setOpenAccordion(undefined);
              setNewSquadName('');
            }}
            buttonType="ghost"
            style={{ marginLeft: '8px' }}
          >
            <Para fontSize={16}>Cancel</Para>
          </TextButton>
        </div>
      </FormBlock>
    </CardBlock>
  );

  return (
    <SidebarContainer>
      <SidebarHeader>
        <Accordion
          shellProps={{ style: { width: '100%' } }}
          tabProps={{ style: { padding: 0, backgroundColor: 'transparent', width: '100%' } }}
          title={renderAccordionTitle()}
        >
          {(openAccordion === 'new-squad' || openAccordion === 'edit-squad') && renderForm()}
        </Accordion>
      </SidebarHeader>
      <div>
        <SearchSquad squads={squads} />
      </div>
      <List>
        {squads.map(squad => (
          <SquadItem
            name={squad.name}
            ID={squad.id}
            key={squad.id}
            onEditClick={() => {
              setOpenAccordion('edit-squad');
              setNewSquadName(squad.name);
            }}
          />
        ))}
      </List>
    </SidebarContainer>
  );
};

export default Sidebar;
