import * as React from 'react';
import { SVGProps } from 'react';

const SvgCrowdStrikeFalconLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="CrowdStrike_Falcon_Logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.CrowdStrike_Falcon_Logo-20x20_svg__st0{fill:#eb3725}'}</style>
    <path
      id="CrowdStrike_Falcon_Logo-20x20_svg__XMLID_21_"
      className="CrowdStrike_Falcon_Logo-20x20_svg__st0"
      d="M19.1 14.8c-.5 0-1.2-.1-2.2.4-1 .5-1.3.5-1.8.5.1.4.5.6 1.2.6 1 0 1.2.1.7 1.2 0-.4 0-1-1-1-1 .1-1.1 1-.1 1.2-.4 0-1 .1-1.3-1.1-.4.1-.7.5-1.7-.4.4.1.7.1.7.1-.7-.4-1.6-1.1-2-1.7.5.4.7.6 1.2.6-.4-.5-1.6-1.7-3-2.9 1 .6 2 1.6 3.8 1.3 1.9-.1 3.2-.5 5.5 1.2"
    />
    <path
      id="CrowdStrike_Falcon_Logo-20x20_svg__XMLID_20_"
      className="CrowdStrike_Falcon_Logo-20x20_svg__st0"
      d="M11.3 14.6c-1.1-.5-1.3-.6-2.9-1s-2.9-1.1-3.8-2.3c.6.5 2.2 1.6 3.5 1.3-.1-.5-.6-.7-1.1-1 .6.2 2.1.6 4.3 3"
    />
    <path
      id="CrowdStrike_Falcon_Logo-20x20_svg__XMLID_19_"
      className="CrowdStrike_Falcon_Logo-20x20_svg__st0"
      d="M8.8 10.2c-.2-1-.7-2-3.4-3.5C4.2 6 2.3 5 0 2.3c.1.7 1 2.4 4.8 4.9 1.3.8 2.8 1.4 4 3"
    />
    <path
      id="CrowdStrike_Falcon_Logo-20x20_svg__XMLID_18_"
      className="CrowdStrike_Falcon_Logo-20x20_svg__st0"
      d="M9 11.4c-.4-.7-1-1.7-3.4-2.9C4.6 8 2.5 7 .8 5.2c.1.6 1 2.2 4.4 4 1 .4 2.6.9 3.8 2.2"
    />
    <path
      className="CrowdStrike_Falcon_Logo-20x20_svg__st0"
      d="M11.4 7.7c-4.9-1.3-6.7-3-8-4.8C4 5.1 5.6 5.7 7.3 7s1.8 2.2 2.3 2.9c1.1 2 1.2 2.3 2.3 3.1 1.3 1 2.9.4 4.6.6 1.7.4 3.2 1.6 3.5 2.2.4-.6-.5-1.6-.7-1.8.1-1-1.8-1.2-2.6-1.6-.1 0-.5-.1-.1-1 .2-1 .7-1.9-5.2-3.7m6.7 5.5c1 .1 1 .4 1 .6-.5-.2-1-.6-1-.6"
    />
  </svg>
);

export default SvgCrowdStrikeFalconLogo20X20;
