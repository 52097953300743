import { AssigneeType } from 'library/enums';
import { object, string } from 'yup';

export const GERRulesetValidation = object().shape({
  title: string()
    .trim()
    .required('Title is required')
    .max(250, 'Max of 250 characters are allowed'),
  description: string().max(250, 'Max of 250 characters are allowed'),
  owner: object()
    .nullable()
    .required('Owner is Required')
    .shape({
      value: string().required('Owner is Required'),
      label: string().required('Owner label is required!'),
      assigneeType: string()
        .oneOf(Object.values(AssigneeType), 'Invalid owner value')
        .required('Owner value is required!'),
    }),
});
