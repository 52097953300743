import { Box, Button, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import { StatusPageContext } from '../..';
import HelpGuide from '../../components/HelpGuide';
import { statusThemeHelpGuide } from '../../constants/statuspage.copy';
import CustomizePageForm from './customizePageForm/customizePageForm';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_CREATION_PAGE_3_VIEWED } from 'core/const/tracker';
import { THEME_COLORS } from 'library/theme/colors';

interface Props {
  isMutationProgress?: boolean;
  isLogoUploading: boolean;
}

const StatuPageCustomizePage: React.FC<any> = (props: Props) => {
  const { statusPagePaths, navigateToPath, setPreviousStep } = useContext(StatusPageContext);
  const formik = useFormikContext();
  useEffect(() => {
    AppTracker.track(T_WA_UP_STATUS_PAGE_V2_CREATION_PAGE_3_VIEWED);
  }, []);

  return (
    <Fragment>
      <div style={{ backgroundColor: 'white', paddingTop: '24px' }}>
        <Text color={THEME_COLORS.secondary[900]} fontSize="24px">
          Customize your Page
        </Text>
        <Text color={THEME_COLORS.secondary[900]} fontSize="14px">
          Customize by adding your logo and selecting your brand theme so your customers can
          recognize it.
        </Text>

        <form style={{ width: '80%' }}>
          <CustomizePageForm />
          <Box w="100%" bottom={0} mx={-6}>
            <Divider />
            <HStack px={6} py={4} gap={4} sx={{ position: 'static', bottom: 0 }}>
              <Button
                onClick={formik.submitForm}
                variant="default"
                type="button"
                isLoading={props.isMutationProgress || props.isLogoUploading}
                loadingText="Save and continue"
                sx={{
                  _hover: {
                    bg: '--chakra-colors-primary-default',
                  },
                }}
              >
                Save and continue
              </Button>
              <Button
                variant="invertedDefault"
                onClick={() => navigateToPath(statusPagePaths.STATUSPAGE_V2_PATH)}
              >
                Cancel
              </Button>
              <Button
                variant="invertedDefault"
                onClick={() => {
                  setPreviousStep();
                }}
                style={{ marginLeft: 'auto' }}
              >
                Previous
              </Button>
            </HStack>
          </Box>
        </form>
      </div>
      <VStack
        // divider={<StackDivider width="20px" borderColor="black" />}
        spacing={4}
        align="stretch"
        h="100%"
        backgroundColor={'white'}
      >
        <HelpGuide helpGuide={statusThemeHelpGuide} />
      </VStack>
    </Fragment>
  );
};

export default StatuPageCustomizePage;
