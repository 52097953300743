import { Para, Theme } from 'uie/components';
import { replaceProperty } from 'core/helpers';
import { IAppState } from 'core/interfaces/IAppState';
import { IIncident, ILog } from 'core/interfaces/IIncidents';
import useUINavigationFunctionsV2 from 'views/main/organization/navigation-flows/useUINavigationFunctionV2';

type IProps = {
  log: ILog;
  logMessageActivity: string;
  entityComponent: JSX.Element;
  incident: IIncident;
};
const MessageBasedOnMultipleAction = (data: IProps & Pick<IAppState, 'organization'>) => {
  const { theme } = Theme;
  const { log, entityComponent, incident, organization } = data;
  const { getEntityComponent } = useUINavigationFunctionsV2(organization);

  return (
    <>
      {log.action.startsWith('Webhook_') && log.assignedTo === 'workflow' && (
        <Para fontSize={14} color={theme.shades.cement}>
          {log.reason} by {entityComponent}
        </Para>
      )}

      {log.additionalInfo &&
        (log.action.startsWith('Routing rule executed') ||
          log.action.startsWith('Suppression rule executed') ||
          log.actionType === 'suppressed') && (
          <Para fontSize={14} color={theme.shades.cement}>
            <span>
              {/* The scenario for incident suppression or routing is as follows -
                            If the incident is suppressed or routed due to an automation rule, one of
                            the conditions log.additionalInfo.routing_rule_applied OR log.additionalInfo.suppression_rule_applied
                            will be true. Else if none of these conditions are true, we show the action from log message (this happens
                            in case of a scenario like service maintenance). But in both cases,
                            we show to which entity the incident was routed.
                           */}
              {log.additionalInfo.routing_rule_applied && 'Routing rule '}
              {log.additionalInfo.suppression_rule_applied && 'Suppression rule '}
              {!(
                log.additionalInfo.routing_rule_applied ||
                log.additionalInfo.suppression_rule_applied
              ) && log.action.replace(/.$/, '')}
              {log.additionalInfo.routing_rule_applied &&
                getEntityComponent({
                  componentType: 'linkWOPopover',
                  name: log.additionalInfo!.routing_rule_applied!.is_basic
                    ? replaceProperty(
                        log.additionalInfo!.routing_rule_applied!.basic_expression?.[0] ?? '',
                        ' lhs == rhs ',
                        '',
                      )
                    : log.additionalInfo!.routing_rule_applied?.expression ?? '',
                  type: 'routing_rule',
                  id: incident.service_id,
                })}
              {log.additionalInfo.suppression_rule_applied &&
                getEntityComponent({
                  componentType: 'linkWOPopover',
                  name: log.additionalInfo!.suppression_rule_applied!.is_basic
                    ? replaceProperty(
                        log.additionalInfo!.suppression_rule_applied!.basic_expression?.[0] ?? '',
                        ' lhs op rhs ',
                        '',
                      )
                    : log.additionalInfo!.suppression_rule_applied?.expression ?? '',
                  type: 'suppression_rule',
                  id: incident.service_id,
                })}{' '}
              {log.additionalInfo.routing_rule_applied ||
              log.additionalInfo.suppression_rule_applied
                ? 'is executed'
                : ''}{' '}
              and incident is routed to {entityComponent}
            </span>
          </Para>
        )}
    </>
  );
};

export default MessageBasedOnMultipleAction;
