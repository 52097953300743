import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { GERState, IGerFilter, IGerFilterApply } from './state.interface';

const initialState: GERState = {
  gerFilters: [],
  gerFilterApply: [],
};

export const useGerFilter = create(
  combine(initialState, set => ({
    setGerFilter: (newFilter: IGerFilter) =>
      set(state => ({
        gerFilters: [...state.gerFilters, newFilter],
      })),
    removeGerFilter: (value: string) =>
      set(state => ({
        gerFilters: state.gerFilters.filter(filter => filter.value !== value),
      })),
    removeGerFilterApply: (value: string) =>
      set(state => ({
        gerFilterApply: state.gerFilters.filter(filter => filter.value !== value),
      })),
    removeGerFiltersByKey: (key: string) =>
      set(state => ({
        gerFilters: state.gerFilters.filter(filter => filter.key !== key),
      })),
    clearGerFilter: () =>
      set(() => ({
        gerFilters: [],
      })),

    clearAllGerFilter: () =>
      set(() => ({
        gerFilters: [],
        gerFilterApply: [],
      })),

    setGerFilterApply: () =>
      set(state => ({
        gerFilterApply: [...state.gerFilters],
      })),

    setInitGerFilter: () =>
      set(state => ({
        gerFilters: [...state.gerFilterApply],
      })),
  })),
);
