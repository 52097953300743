import { Center, Image, Text } from '@chakra-ui/react';

export const EmptyList = () => {
  return (
    <Center flexDirection="column" rowGap="24px" pt="24px">
      <Image src={`/icons/empty-states/saved-filters.png`} alt="No Incidents" />
      <Text variant="formInput_800" color="secondary.700">
        Save your filter for a quick filtration for later date
      </Text>
    </Center>
  );
};
