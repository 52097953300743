import { ChakraProvider } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import {
  SCHEDULES_V2_CREATE_PATH,
  SCHEDULES_V2_DETAIL_PATH,
  SCHEDULES_V2_EDIT_PATH,
} from 'views/main/routes/routes';

import { IAppState } from '../../../../core/interfaces/IAppState';
import { ErrorBoundary } from '../../../shared/components';
import { scheduleViews } from './constants/schedules.view';
import ScheduleAdd from './schedules.add';
import ScheduleDetail from './schedules.detail';
import SchedulesHome from './schedules.home';
import { ScheduleHeaderProvider } from './schedules.view/schedules.header/context';
import { ScheduleActionsProvider } from './schedules.actions/context';
import chakraTheme from './theme';

export const useOrganizationConfig = () => {
  const organization = useSelector((state: IAppState) => state.organization);
  return { organization };
};

export const ChakraWrapper: FC = ({ children }) => {
  return (
    <ChakraProvider theme={chakraTheme} portalZIndex={40}>
      {children}
    </ChakraProvider>
  );
};

const Schedules = () => {
  let { pathname } = useLocation();
  pathname = pathname.split('?')[0];
  const { path } = useRouteMatch();

  const defaultViewInfo = useMemo(() => {
    if (
      matchPath(pathname, SCHEDULES_V2_CREATE_PATH) ||
      matchPath(pathname, SCHEDULES_V2_EDIT_PATH)
    ) {
      return {
        defaultViewType: scheduleViews.preview.defaultViewType,
        disabledView: scheduleViews.preview.disabledView,
        isScheduleDetailsView: false,
      };
    } else if (matchPath(pathname, SCHEDULES_V2_DETAIL_PATH)) {
      return {
        defaultViewType: scheduleViews.detail.defaultViewType,
        disabledView: scheduleViews.detail.disabledView,
        defaultRotationViewType: scheduleViews.detail.defaultRotationViewType,
        isScheduleDetailsView: true,
      };
    } else {
      return {
        defaultViewType: scheduleViews.home.defaultViewType,
        disabledView: scheduleViews.home.disabledView,
        isScheduleDetailsView: false,
      };
    }
  }, [pathname]);

  return (
    <ChakraWrapper>
      <ErrorBoundary>
        <ScheduleHeaderProvider
          defaultViewType={defaultViewInfo.defaultViewType}
          disabledView={defaultViewInfo.disabledView}
          defaultRotationViewType={defaultViewInfo.defaultRotationViewType}
          isScheduleDetailsView={defaultViewInfo.isScheduleDetailsView}
        >
          <ScheduleActionsProvider>
            <Switch>
              <Route exact path={path}>
                <SchedulesHome />
              </Route>
              <Route exact path={SCHEDULES_V2_CREATE_PATH}>
                <ScheduleAdd />
              </Route>
              <Route exact path={SCHEDULES_V2_EDIT_PATH}>
                <ScheduleAdd />
              </Route>
              <Route exact path={SCHEDULES_V2_DETAIL_PATH}>
                <ScheduleDetail />
              </Route>
            </Switch>
          </ScheduleActionsProvider>
        </ScheduleHeaderProvider>
      </ErrorBoundary>
    </ChakraWrapper>
  );
};

export default Schedules;
