import { useFormContext } from 'react-hook-form';
import { WorkflowForm } from '../../types';
import DropdownSeparator from '../../details/components/WorkFlowConditions/DropdownSeparator';
import FormField from 'library/molecules/FormField/FormField';
import Separator from 'library/atoms/Separator';

interface ISeperator {
  fieldIndex: number;
  name: 'group.type' | `group.fields.children.${number}.type`;
}
const SeparatorComponent = ({ fieldIndex, name }: ISeperator) => {
  const { getValues, setValue } = useFormContext<WorkflowForm>();

  return (
    <>
      {fieldIndex === 1 && (
        <FormField>
          <DropdownSeparator name={name} />
        </FormField>
      )}
      {fieldIndex > 1 && (
        <Separator text={(getValues(name) as string) === 'and_filter' ? 'And' : 'Or'} />
      )}
    </>
  );
};

export default SeparatorComponent;
