import { Image, keyframes, usePrefersReducedMotion, Text, Center, VStack } from '@chakra-ui/react';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

type Props = {
  title?: string;
};

export const PageLoader = ({ title = 'Loading' }: Props) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion ? undefined : `${spin} infinite 1s linear`;

  return (
    <VStack rowGap={2}>
      <Image animation={animation} src="/icons/squadcast-spinner.svg" height="60px" />
      <Text color="#1D426B" variant="spinner_text">
        {title}
      </Text>
    </VStack>
  );
};
