import React, { useEffect, useRef } from 'react';
import { AlertSource } from 'icons';
import { Text } from '@chakra-ui/react';
import useAlertSourcesList from '../../../create-or-update/filter/alertSource/useAlertSourceList';
import Wrapper from './Wrapper';
import { Loader } from './Loader';
import { FILTER_TYPE_LABEL, OPERATOR_LABEL } from '../../../constant';
import { OperatorTypes } from '../../../types/enums';
import { safeEncodeUriComponent } from '../../../utils';
import { focusCard } from 'core';
import { ActiveWorkflowTarget } from './ActiveWorkflowTarget';
import { BE_FieldType } from '../../../types';

interface AlertSourcesConditionProps {
  alertSources?: BE_FieldType;
}

export const AlertSourcesCondition: React.FC<AlertSourcesConditionProps> = ({ alertSources }) => {
  const flexRef = useRef(null);

  const { data, isLoading } = useAlertSourcesList(alertSources);

  const { isactionTrigger, styleActionOnTarget, targetAction } =
    ActiveWorkflowTarget('#Alert%20Source');

  useEffect(() => {
    if (flexRef.current && targetAction === '#Alert%20Source') {
      focusCard(flexRef.current);
    }
  }, [isactionTrigger]);
  if (isLoading) {
    return <Loader />;
  }
  if (!alertSources) return null;

  const alertSource = data?.find(alertSource => alertSource._id === alertSources?.fields.value);

  return (
    <Wrapper
      id={safeEncodeUriComponent(FILTER_TYPE_LABEL.alert_source)}
      border={styleActionOnTarget}
      ref={flexRef}
    >
      <AlertSource width={24} height={24} />
      <Text variant="h1" as="h1">
        <strong> Alert Source </strong>
        <span>{OPERATOR_LABEL[alertSources?.type.split('alert_source_')[1] as OperatorTypes]}</span>
      </Text>
      <Text fontSize="2xl">{alertSource?.type}</Text>
    </Wrapper>
  );
};
