const GER_RULESET_OBJ = 'SQ_GER_RULESET_OBJ';

export const setRuleObj = (key: string, value: any) => {
  const obj = getRuleObj() ?? {};
  obj[key] = value;

  serializeRuleObj(obj);
};

export const serializeRuleObj = (obj: any) => {
  sessionStorage.setItem(GER_RULESET_OBJ, JSON.stringify(obj));
};

export const getRuleObj = () => {
  const str = sessionStorage.getItem(GER_RULESET_OBJ);
  return str ? JSON.parse(str) : null;
};

export const hasRuleObj = () => {
  return !!sessionStorage.getItem(GER_RULESET_OBJ);
};

export const clearRuleObj = () => {
  sessionStorage.removeItem(GER_RULESET_OBJ);
};
