import React, { Component, useEffect, useState } from 'react';
import { Grid, IconButton, Para, TextButton, Theme, SnackContext, SnackBar } from 'uie/components';
import { IRole } from '../../../../../../core/interfaces/IRole';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../../core/interfaces/IAppState';
import { IOrganization } from '../../../../../../core/interfaces/IOrganization';
import { CloseIcon } from '../../../../../../icons';
import { isEmailValid, getProperty } from '../../../../../../core/helpers';
import { IUserInfo, IUserReq } from '../../../../../../core/interfaces/IUserData';
import { UsersService } from '../../../../../../core/services';
import { exception } from '../../../../../../core/exception';
import * as helpers from './helpers';
import { render } from './render.index';
import './index.css';
import { IComponentErrorState } from '../../../../../../core/interfaces/IComponentState';
import { requestOrganizationUsers } from '../../../../../../core/actions/organization/users';
import { requestOrganizationTeams } from 'core/actions/organization/teams';
import { RouteComponentProps } from 'react-router-dom';
import { T_WA_GS_USER_ADDED } from '../../../../../../core/const/tracker';
import { AppTracker } from '../../../../../../shared/analytics/tracker';

const { theme } = Theme;

type IMix = Pick<IAppState, 'organization' | 'roles' | 'userInfo'>;

export interface IUser extends Pick<IUserInfo, 'first_name' | 'last_name' | 'email'> {
  role: IRole;
}

export interface IDeletedUser {
  user: IUser;
  index: number;
}

interface IProps extends IMix, RouteComponentProps {
  selectedOrg: IOrganization;
  users: IUser[];
  setUsers: (u: IUser[]) => void;
  deletedUser: IDeletedUser | null;
  setDeletedUser: (u: IDeletedUser | null) => void;
  showCsvDrop: boolean;
  setShowCsvDrop: (s: boolean) => void;
  onInvalidCsv: (s?: string) => void;
  requestOrganizationUsers: () => void;
  requestOrganizationTeams: () => void;
}

interface IState {
  error: IComponentErrorState;
  modal: 'invite_sent' | 'merge_data' | '';
  toMergeUsers: IUser[];
  loading: boolean;
  redirect: boolean;
  invitedUsers: number;
  deletedUserTimeout: any | null;
}

export class AddUsers extends Component<IProps, IState> {
  public emptyUser: IUser = {
    first_name: '',
    last_name: '',
    email: '',
    role: this.props.roles.r.find(r => r.name === 'User')!,
  };

  private _usersService = new UsersService();

  constructor(props: IProps) {
    super(props);

    this.state = {
      error: {},
      modal: '',
      toMergeUsers: [],
      loading: false,
      redirect: false,
      invitedUsers: 0,
      deletedUserTimeout: null,
    };
  }

  public handleValueUpdate =
    (type: 'first_name' | 'last_name' | 'email', userIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const users = [...this.props.users];

      users[userIndex][type] = value;

      if (userIndex === users.length - 1 && type === 'email') users.push({ ...this.emptyUser });

      this.props.setUsers(users);
    };

  public handleRoleChange = (userIndex: number) => (_: any, role: IRole) => {
    const users = [...this.props.users];
    users[userIndex].role = role;

    this.props.setUsers(users);
  };

  public handleDeleteUser = (userIndex: number) => () => {
    const users = [...this.props.users];
    const deleted = users[userIndex];
    users.splice(userIndex, 1);

    if (users.length === 0) {
      users.push({ ...this.emptyUser });
      this.props.setUsers(users);
      return;
    }

    this.props.setUsers(users);

    this.setState({ error: {} });

    if (!deleted.email && !deleted.first_name && !deleted.last_name) return;

    this.props.setDeletedUser({
      user: { ...deleted },
      index: userIndex,
    });

    if (this.state.deletedUserTimeout) {
      clearTimeout(this.state.deletedUserTimeout);
    }
    const timeOutID = setTimeout(() => {
      this.props.setDeletedUser(null);
      this.setState({ deletedUserTimeout: null });
    }, 10000);
    this.setState({ deletedUserTimeout: timeOutID });
  };

  public validateInput = () => {
    const error: IComponentErrorState = {};
    let isError = false;

    this.props.users.forEach((user, index) => {
      const [email, firstName, lastName] = [
        user.email.trim().toLowerCase(),
        user.first_name.trim(),
        user.last_name.trim(),
      ];

      if (!email && !firstName) return;

      if (!email || !firstName) {
        if (!email) error[`e_${index}_email`] = 'email';
        if (!firstName) error[`e_${index}_firstName`] = 'firstName';

        error.message = 'Please fill all the fields';
        isError = true;
        return;
      }

      if (!isEmailValid(email)) {
        error[`e_${index}_email`] = 'email';
        error.message = 'Invalid Email ID. Please check and try again';
        isError = true;
        return;
      }

      if (
        this.props.users.some((u, idx) => idx !== index && u.email.trim().toLowerCase() === email)
      ) {
        error[`e_${index}_email`] = 'email';
        error.message = 'Duplicate Email IDs';
        isError = true;
        return;
      }

      if (this.props.organization.users.u.some(u => u.email.toLowerCase() === email)) {
        error[`e_${index}_email`] = 'email';
        error.message = 'User with Email ID already exists';
        isError = true;
        return;
      }
    });

    this.setState({ error });
    return !isError;
  };

  public handleSubmit = async () => {
    if (!this.validateInput()) return;

    const userReq: IUserReq[] = this.props.users
      .filter(user => user.email.trim() && user.first_name.trim() && user.last_name.trim())
      .map(user => {
        AppTracker.track(T_WA_GS_USER_ADDED, {
          'Invited User First Name': user.first_name,
          'Invited User Last Name': user.last_name,
          'Invited User Role': user.role,
          'Invited User Email': user.email.trim(),
        });

        return {
          role: user.role.slug,
          email: user.email.trim(),
          first_name: user.first_name,
          last_name: user.last_name,
        };
      });

    if (userReq.length === 0) {
      this.setState({ error: { message: 'No invites sent' } });
      return;
    }

    this.setState({ loading: true });

    try {
      await this._usersService.bulkCreate(userReq);
      this.props.requestOrganizationUsers();
      this.props.requestOrganizationTeams();
      this.setState({
        modal: 'invite_sent',
        loading: false,
        invitedUsers: userReq.length,
        redirect: true,
      });
    } catch (err: any) {
      this.setState({
        error: {
          message:
            err?.response?.data?.meta?.error_details?.description ?? ''
              ? err?.response?.data?.meta?.error_details?.description ?? 'Network Error'
              : err?.response?.data?.meta?.error_message ?? 'Network Error',
        },
        loading: false,
      });
      exception.handle('E_CREATE_USERS_BULK', err?.response);
    }
  };

  public redirectToUsers = () => {
    this.setState({ modal: '' });
    const { history } = this.props;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore React-router-dom is wrongly referencing global 'history' package
    if (history.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      history.goBack();
    }
  };

  public checkDirty = () => {
    if (this.state.modal === 'invite_sent' || this.state.redirect === true) return false;

    const { users } = this.props;

    return (
      users.length > 1 ||
      users[0].first_name !== '' ||
      users[0].last_name !== '' ||
      users[0].email !== ''
    );
  };

  public handleDrag = helpers.handleDrag.bind(this);
  public handleFileDrop = helpers.handleFileDrop.bind(this);
  public handleFileUpload = helpers.handleFileUpload.bind(this);
  public parseCSVFile = helpers.parseCSVFile.bind(this);
  public handleConfirmMergeData = helpers.handleConfirmMergeData.bind(this);
  public handleCancelMergeData = helpers.handleCancelMergeData.bind(this);
  public render = render;
}

const AddUsersComponent = connect(
  (state: IAppState) => ({
    organization: state.organization,
    roles: state.roles,
    userInfo: state.userInfo,
    selectedOrg: state.userOrganizations.o.find(
      o => o.organizationId === state.INIT_ORG.orgId,
    ) as IOrganization,
  }),
  {
    requestOrganizationUsers,
    requestOrganizationTeams,
  },
)(AddUsers);

interface IAddUserHOCProps extends Pick<IAppState, 'roles'>, RouteComponentProps {}

/**
 * Functional wrapper for AddUsers to use SnackBar Context
 */
const AddUsersHOC = (props: IAddUserHOCProps) => {
  const _createSnack = SnackContext();

  const emptyUser: IUser = {
    first_name: '',
    last_name: '',
    email: '',
    role: props.roles.r.find(r => r.name === 'User')!,
  };

  const [users, setUsers] = useState<IUser[]>([{ ...emptyUser }]);
  const [deletedUser, setDeletedUser] = useState<IDeletedUser | null>(null);
  const [showCsvDrop, setShowCsvDrop] = useState(false);
  const [invalidCsv, setInvalidCsv] = useState('');

  const onInvalidCsv = (err = 'Invalid file type') => {
    setShowCsvDrop(false);
    setInvalidCsv(err);
    setTimeout(() => setInvalidCsv(''), 5000);
  };

  const handleUndoDelete = () => {
    if (!deletedUser) return;
    const newUsers = [...users];
    newUsers.splice(deletedUser.index, 0, deletedUser.user);
    setDeletedUser(null);
    setUsers(newUsers);
  };

  const onCancelDeletedUser = () => {
    if (!deletedUser) return;
    const newUsers = [...users];
    setDeletedUser(null);
    setUsers(newUsers);
  };

  useEffect(() => {
    if (invalidCsv !== '' && !showCsvDrop) {
      _createSnack(
        <SnackBar background="var(--danger-default)" className="add-users__csv-drop danger">
          <Para fontWeight={400} color={theme.shades.white} fontSize={16} style={{ padding: 16 }}>
            {invalidCsv}
          </Para>
        </SnackBar>,
      );
    } else if (showCsvDrop) {
      _createSnack(
        <SnackBar background="var(--primary-default)" className="add-users__csv-drop">
          <Para fontWeight={400} color={theme.shades.white} fontSize={16} style={{ padding: 16 }}>
            Drop your .csv file
          </Para>
        </SnackBar>,
      );
    } else if (deletedUser) {
      _createSnack(
        <SnackBar>
          <Grid alignItems="center" justifyContent="center">
            <Para color={theme.shades.white} fontWeight={400}>
              Removed {deletedUser.user.email}
            </Para>
            <TextButton buttonType="ghost" className="ml-20" onClick={handleUndoDelete}>
              <Para color={theme.shades.white} fontWeight={400}>
                Undo
              </Para>
            </TextButton>
            <IconButton onClick={onCancelDeletedUser} className="ml-10">
              <CloseIcon stroke={theme.shades.white} />
            </IconButton>
          </Grid>
        </SnackBar>,
      );
    } else {
      _createSnack(null);
    }
    return () => _createSnack(null);
  });

  return (
    <AddUsersComponent
      {...{
        ...props,
        users,
        setUsers,
        deletedUser,
        setDeletedUser,
        showCsvDrop,
        setShowCsvDrop,
        onInvalidCsv,
        history: props.history,
      }}
    />
  );
};

export default connect((state: IAppState) => ({
  roles: state.roles,
}))(AddUsersHOC);
