import React, { Component } from 'react';
import { IAppState } from '../../../../core/interfaces/IAppState';
import { connect } from 'react-redux';
import { Grid, Heading, Theme, Para, SwitchBlock } from 'uie/components';
import { appPreviewsData } from './previews';
import { IAppPreviewType } from '../../../../core/interfaces/IAppPreview';
import { AppPreview } from '../../../../core/appPreview';
import './index.css';

const { theme } = Theme;

type IProps = Pick<IAppState, 'APP_PREVIEW'>;

class ExperimentsModal extends Component<IProps> {
  public handleOnEnablePreview = (ap: IAppPreviewType) => (p: string) => {
    if (p === 'Enable') {
      AppPreview.enablePreview(ap);
    } else {
      AppPreview.disablePreview(ap);
    }
  };

  render() {
    return (
      <>
        <Grid className="mb-20" type="column">
          <Heading height={35} fontSize={24}>
            App previews
          </Heading>
          <Para fontSize={14} color={theme.shades.grey}>
            Early access features
          </Para>
        </Grid>
        <Grid type="column">
          {appPreviewsData.length === 0 && (
            <Para>No previews available as of now, check back later.</Para>
          )}
          {appPreviewsData
            .filter(p => this.props.APP_PREVIEW.appPreviews.includes(p.type))
            .map((p, i) => (
              <Grid key={i} className="mb-10 experiment-modal-card" width="calc(100% - 16px)">
                <Grid>
                  <img className="experiment-modal-card-image" src={p.image} alt="feat-preview" />
                </Grid>
                <Grid type="column" width="100%">
                  <Para fontSize={24}>{p.name}</Para>
                  <Para className="mt-10" fontSize={16} color={theme.shades.grey}>
                    {p.desc}
                  </Para>
                  <SwitchBlock
                    className="mt-10"
                    options={['Enable', 'Disable']}
                    selected={[
                      this.props.APP_PREVIEW.preview.includes(p.type) ? 'Enable' : 'Disable',
                    ]}
                    onSelectValue={this.handleOnEnablePreview(p.type)}
                  />
                </Grid>
              </Grid>
            ))}
        </Grid>
      </>
    );
  }
}

export default connect(
  (state: IAppState) => ({
    APP_PREVIEW: state.APP_PREVIEW,
  }),
  {},
)(ExperimentsModal);
