import React, { Fragment, useMemo } from 'react';
import { IconButton, Text } from '@chakra-ui/react';

import {
  AddUserIcon,
  CalendarIcon,
  CopyIcon,
  Delete2Icon,
  Edit2Icon,
  PauseIcon,
  PlayIcon,
} from 'icons';
import { ScheduleActionTypes, ActionButtonLabels } from '../constants/schedules.copy';
import { useScheduleActionsContext } from './context';
import { ISchedulesDetail, IScheduleActionButtonInfo } from '../interface/schedule';
import SQTooltip from 'components/chakra/Tooltip';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_SCHEDULES_V2_PARTICIPANTS_EDITED } from 'core/const/tracker';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

interface Props {
  schedule?: NonNullable<ISchedulesDetail>[number] | null;
  isScheduleDetailsView: boolean;
}

const ScheduleActions = ({ schedule, isScheduleDetailsView }: Props) => {
  const { onActionClick, openAlertDialog, openEditSchedule } = useScheduleActionsContext();

  const scheduleACL = useUserAccess();
  const hasUpdateAccess = scheduleACL.hasUpdateAccess;
  const hasDeleteAccess = scheduleACL.hasDeleteAccess;
  const hasReadAccess = scheduleACL.hasReadAccess;
  const hasCreateAccess = scheduleACL.hasCreateAccess;

  const hasUpdate = hasUpdateAccess('schedules', `${schedule?.ID}`);

  const hasDelete = hasDeleteAccess('schedules', `${schedule?.ID}`);

  const hasRead = hasReadAccess('schedules');

  const hasCreate = hasCreateAccess('schedules');

  const scheduleActions: IScheduleActionButtonInfo[] = [
    {
      label: ActionButtonLabels.PAUSE,
      variant: 'icon_cta',
      icon: <PauseIcon />,
      iconStyles: {
        '& path:last-of-type': { fill: 'blue.950' },
      },
      showAction: !schedule?.paused,
      disabled: false,
      hasPermission: hasUpdate,
      onClick: (scheduleId: number, scheduleName: string) => () => {
        onActionClick({
          type: ScheduleActionTypes.PAUSE,
          params: { scheduleId, scheduleName },
        });
        openAlertDialog();
      },
    },
    {
      label: ActionButtonLabels.RESUME,
      variant: 'icon_cta',
      icon: <PlayIcon />,
      iconStyles: {
        '& polygon:last-of-type': { fill: 'blue.950' },
      } as any,
      showAction: !!schedule?.paused,
      disabled: false,
      hasPermission: hasUpdate,
      onClick: (scheduleId: number, scheduleName: string) => () => {
        onActionClick({
          type: ScheduleActionTypes.RESUME,
          params: { scheduleId, scheduleName },
        });
        openAlertDialog();
      },
    },
    {
      label: ActionButtonLabels.EXPORT,
      variant: 'icon_cta',
      icon: <CalendarIcon />,
      iconStyles: { stroke: 'blue.950' },
      showAction: true,
      hasPermission: hasRead,
      disabled: !!schedule?.paused,
      onClick: (scheduleId: number, scheduleName: string) => () => {
        onActionClick({
          type: ScheduleActionTypes.EXPORT,
          params: { scheduleId, scheduleName },
        });
        openAlertDialog();
      },
    },
    {
      label: ActionButtonLabels.CLONE,
      variant: 'icon_cta',
      icon: <CopyIcon />,
      iconStyles: {
        '& path': { fill: 'blue.950' },
      },
      showAction: true,
      disabled: !!schedule?.paused,
      hasPermission: hasCreate,
      onClick: (scheduleId: number, scheduleName: string) => () => {
        onActionClick({
          type: ScheduleActionTypes.CLONE,
          params: { scheduleId, scheduleName },
        });
        openAlertDialog();
      },
    },
    {
      label: ActionButtonLabels.EDIT_PARTICIPANTS,
      variant: 'icon_cta',
      icon: <AddUserIcon />,
      iconStyles: {
        '&  path': { fill: 'blue.950' },
      },
      showAction: true,
      disabled: !!schedule?.paused,
      hasPermission: hasUpdate,
      onClick: (scheduleId: number, scheduleName: string) => () => {
        onActionClick({
          type: ScheduleActionTypes.EDIT_PARTICIPANTS,
          params: { scheduleId, scheduleName },
        });
        openEditSchedule();
        AppTracker.track(T_WA_UP_SCHEDULES_V2_PARTICIPANTS_EDITED);
      },
    },
    {
      label: ActionButtonLabels.EDIT_ROTATIONS,
      variant: 'icon_cta',
      icon: <Edit2Icon />,
      iconStyles: {
        '& path': { fill: 'blue.950' },
      },
      showAction: !isScheduleDetailsView,
      disabled: !!schedule?.paused,
      hasPermission: hasUpdate,
      onClick: (scheduleId: number, scheduleName: string) => () => {
        onActionClick({
          type: ScheduleActionTypes.EDIT_ROTATIONS,
          params: { scheduleId, scheduleName },
        });
        openEditSchedule();
      },
    },
    {
      label: ActionButtonLabels.DELETE,
      variant: 'icon_cta',
      icon: <Delete2Icon />,
      iconStyles: {
        '& path': { fill: 'blue.950' },
      },
      showAction: !isScheduleDetailsView,
      disabled: false,
      hasPermission: hasDelete,
      onClick: (scheduleId: number, scheduleName: string) => () => {
        onActionClick({
          type: ScheduleActionTypes.DELETE,
          params: { scheduleId, scheduleName },
        });
        openAlertDialog();
      },
    },
  ];

  return (
    <>
      {scheduleActions
        .filter(action => action.showAction)
        .map(action => {
          return (
            <Fragment key={action.label}>
              <NoPermissionTooltip isDisabled={action.hasPermission ?? true}>
                <SQTooltip text={action.label}>
                  <IconButton
                    aria-label={action.label}
                    variant={action.variant}
                    icon={action.icon}
                    sx={{
                      '& svg': {
                        width: 4,
                        height: 4,
                        ...action.iconStyles,
                      },
                    }}
                    onClick={schedule ? action.onClick(schedule.ID, schedule.name) : () => {}}
                    isDisabled={action.disabled || !action.hasPermission}
                  />
                </SQTooltip>
              </NoPermissionTooltip>
            </Fragment>
          );
        })}
    </>
  );
};

export default ScheduleActions;
