import chakraUiTheme from '@chakra-ui/theme';

export const colors = {
  colors: {
    black: '#000',
    white: '#fff',
    blue: {
      ...chakraUiTheme.colors.blue,
      50: '#f3f9ff',
      100: '#a5cbfa',
      200: '#e5f2ff',
      300: '#dceeff',
      900: '#1b7ff1',
    },
    red: {
      900: '#D14C44',
    },
    gray: {
      150: '#e7ecf5',
      700: '#627C98',
      900: '#1D426B',
    },
    smoke_white: '#F3F4F7',
  },
};
