import { HStack, Text, VStack, Tooltip } from '@chakra-ui/react';
import { ListingButton, Avatar } from 'library/atoms';
import { DateTime } from 'luxon';
import { generatePath, useHistory } from 'react-router-dom';
import { USER_PROFILE_PATH } from 'views/main/routes/routes';

import { UsernameIcon, ClockIcon } from 'icons';
import { THEME_COLORS } from 'library/theme/colors';

interface Props {
  userFullName: string;
  userName: string;
  userId: string;
  userTimezone: string;
}

const UserHeader = ({ userFullName, userName, userId, userTimezone }: Props) => {
  const history = useHistory();

  const clickHandler = () => {
    history.push(generatePath(USER_PROFILE_PATH, { id: userId }));
  };

  const localTime = DateTime.local().setZone(userTimezone).toFormat('hh:mm a');

  return (
    <VStack
      w="100%"
      padding="12px 16px 8px"
      alignItems="center"
      justifyContent="flex-start"
      boxSizing="border-box"
    >
      <HStack w="100%" alignItems="flex-start">
        <Avatar name={userFullName} size="sm" />
        <VStack alignItems="flex-start" ml={0} w="100%">
          <HStack justifyContent="space-between" w="100%">
            <Tooltip
              label={<Text>{userFullName}</Text>}
              placement="top"
              bg={THEME_COLORS.secondary[100]}
            >
              <Text maxW="30ch" isTruncated color="secondary.900" fontWeight={800}>
                {userFullName}
              </Text>
            </Tooltip>
            <ListingButton
              variant="secondary"
              size="xs"
              title="View Profile"
              onClick={clickHandler}
            />
          </HStack>
          {userName && (
            <HStack mt={'0px !important'} alignItems="flex-start">
              <UsernameIcon style={{ marginTop: 5 }} />
              <Text
                color="entityPopover.heading"
                maxW="27ch"
                ml={'var(--chakra-space-1) !important'}
              >
                {userName}
              </Text>
            </HStack>
          )}
        </VStack>
      </HStack>

      {userTimezone && (
        <HStack mt={'0px !important'} w="100%" pl={10}>
          <ClockIcon />
          <Text color="entityPopover.heading" ml={'var(--chakra-space-1) !important'} maxW="27ch">
            {userTimezone} | {localTime}
          </Text>
        </HStack>
      )}
    </VStack>
  );
};

export default UserHeader;
