import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const DropdownTheme: ComponentStyleConfig = {
  baseStyle: ({ isInvalid }) => ({
    container: {
      minWidth: '240px',
    },
    input: {
      color: 'inherit',
      lineHeight: 1,
    },
    menu: {
      boxShadow: 'none',
    },
    menuList: {
      borderWidth: '0.75px',
      borderStyle: 'solid',
      borderColor: 'secondary.200',
      borderRadius: '4.5px',
      padding: 0,
    },
    option: {
      ':hover': {
        backgroundColor: 'primary.100',
      },
    },
    optionActive: {
      backgroundColor: 'primary.100',
      color: '#09305A',
    },
    control: {
      borderColor: isInvalid ? '#E53E3E' : 'secondary.200',
      ':hover': {
        borderColor: 'brand.blue',
      },
    },
    controlDisabled: {
      backgroundColor: 'secondary.100',
      opacity: 0.5,
    },
    dropdownIndicator: {
      padding: '6px',
    },
    valueContainer: {
      padding: '0px 9px',
    },
    multiValueRemove: {},
    multiValue: {},
    indicatorSeparator: {
      display: 'none',
    },
    indicatorsContainer: {
      '& svg': {
        width: '15px',
        height: '15px',
        fill: '#09305A',
      },
    },
    loadingMessage: {},
    noOptionsMessage: { padding: '6px 12px' },
  }),
  sizes: {
    md: {
      menu: {
        fontSize: '12px',
      },
      control: {
        minHeight: '30px',
      },
      valueContainer: {
        fontSize: '12px',
      },
      selectLabel: {
        '& > span': {
          width: '18px',
          height: '18px',
        },
      },
    },
    lg: {
      menu: {
        fontSize: '16px',
      },
      control: {
        minHeight: '40px',
      },
      valueContainer: {
        fontSize: '16px',
      },
      selectLabel: {
        '& > span': {
          width: '24px',
          height: '24px',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
