import React, { SVGAttributes } from 'react';

const CloseIcon = (props: SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <circle cx="12" cy="12" r="10" fill="#fff" />
    <line x1="15" y1="9" x2="9" y2="15" stroke="currentColor" />
    <line x1="9" y1="9" x2="15" y2="15" stroke="currentColor" />
  </svg>
);

export default CloseIcon;
