import { exception, IncidentService } from 'core';
import React, { useEffect } from 'react';

export type TagsMapType = {
  keys: string[];
  valuesMap: { [key: string]: string[] };
};

const useGetAllTags = (serviceId?: string, teamId?: string) => {
  const _incidentService = new IncidentService();
  const [tags, setTags] = React.useState<TagsMapType>({
    keys: [],
    valuesMap: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { data: incidentTags },
        } = await _incidentService.getAllTags(teamId);

        let tagKeys = incidentTags.reduce((acc: string[], item) => {
          if (serviceId && item.service !== serviceId) return acc;

          const tags = item.tags.map(i => i.key as string);
          acc.push(...tags);
          return acc;
        }, []);

        tagKeys = Array.from(new Set(tagKeys));

        const tagValueMap = incidentTags.reduce((acc: { [key: string]: string[] }, item) => {
          if (serviceId && item.service !== serviceId) return acc;

          item.tags.forEach(i => {
            acc[i.key] =
              acc[i.key] && acc[i.key].length
                ? Array.from(new Set([...i.values, ...acc[i.key]]))
                : Array.from(new Set(i.values));
          });

          return acc;
        }, {});

        setTags({
          keys: tagKeys,
          valuesMap: tagValueMap,
        });
      } catch (err: any) {
        exception.handle('E_GET_ALL_TAGS_INCIDENTS', err);
      }
    };
    const timer = setTimeout(() => {
      fetchData();
    }, 100);

    return () => clearTimeout(timer);
  }, [serviceId, teamId]);

  return tags;
};

export default useGetAllTags;
