import { Duration, DurationUnit } from 'luxon';

export type Units = [display: string, tooltip: string];
export type UnitsFn = (value: number) => Units;

export function largestUnit(duration: Duration): DurationUnit {
  if (duration.years) {
    return 'years';
  } else if (duration.months) {
    return 'months';
  } else if (duration.days) {
    return 'days';
  } else if (duration.hours) {
    return 'hours';
  } else if (duration.minutes) {
    return 'minutes';
  } else {
    return 'seconds';
  }
}

export function humanDuration(): UnitsFn {
  return (durationInMillis: number) => {
    const duration = Duration.fromMillis(durationInMillis).set({ milliseconds: 0 });
    const tooltip = duration.toHuman({ unitDisplay: 'short' });

    const unit = largestUnit(duration);
    const length = duration.as(unit);
    const display = Duration.fromObject({ [unit]: length }).toHuman({ unitDisplay: 'short' });

    return [display, tooltip];
  };
}

export function applyUnits(value?: number, unitsFn?: UnitsFn): Units {
  if (value === undefined) {
    return ['', ''];
  }

  if (unitsFn === undefined) {
    const str = value.toLocaleString();
    return [str, str];
  }

  return unitsFn(value);
}

export function text(unit: string): UnitsFn {
  // TODO: I18N + pluralize, unit would be a key

  return (value: number) => {
    const display = value.toLocaleString();
    const base = `${display} ${unit}`;

    const tooltip = value > 1 ? base + 's' : base;

    return [display, tooltip];
  };
}
