import React, { useState, useEffect, useMemo } from 'react';
import {
  Grid,
  GridItem,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
  Flex,
} from '@chakra-ui/react';
import ChakraDatepicker from 'components/chakra/Datepicker';
import { useFormikContext } from 'formik';
import { getEndDateFromStartDate } from '../../helpers/helpers.customrotations';
import { INewSchedule } from '../../interface/schedule';
import { rotationsCustomizePattern } from '../../constants/rotations.customize';
import { isSameDate } from '../../helpers/helpers.date';

interface Props {
  rotationId: string;
}

const usePatternEnd = ({ rotationId }: Props) => {
  const {
    values: { rotations },
    errors,
    touched,
    handleChange,
  } = useFormikContext<INewSchedule>();

  const currentRotation = useMemo(
    () => rotations.find(rt => rt.id === rotationId),
    [rotations, rotationId],
  );
  const currentRotationIndex = rotations.findIndex(rt => rt.id === rotationId);
  const currentRotationEndDate = currentRotation?.endDate
    ? currentRotation.endDate
    : currentRotation?.startDate
    ? getEndDateFromStartDate(currentRotation.startDate, 1)
    : new Date();
  const currentRotationOccurences = currentRotation?.endsAfterIterations;
  const doesRotationEndToday = isSameDate(currentRotationEndDate, new Date());
  const datePickerFormat = (doesRotationEndToday ? "'Today, '" : '') + 'd MMM yyy, (EEE)';
  const [activeEndValue, setActiveEndValue] = useState('never');

  useEffect(() => {
    setActiveEndValue(
      currentRotation?.ends === false
        ? 'never'
        : currentRotation?.endsAfterIterations !== undefined
        ? 'after'
        : currentRotation?.endDate !== undefined
        ? 'on'
        : 'never',
    );
  }, [currentRotation]);

  const radioGroupChangeHandler = (value: any) => {
    setActiveEndValue(value);
    if (value === 'never') {
      handleChange({
        target: {
          name: `rotations.${currentRotationIndex}.ends`,
          value: false,
        },
      });
    } else if (value === 'on') {
      handleChange({
        target: {
          name: `rotations.${currentRotationIndex}.endDate`,
          value: currentRotationEndDate,
        },
      });
      handleChange({
        target: {
          name: `rotations.${currentRotationIndex}.ends`,
          value: true,
        },
      });
      handleChange({
        target: {
          name: `rotations.${currentRotationIndex}.endsAfterIterations`,
          value: undefined,
        },
      });
    } else {
      handleChange({
        target: {
          name: `rotations.${currentRotationIndex}.endsAfterIterations`,
          value: currentRotationOccurences ? currentRotationOccurences : 1,
        },
      });
      handleChange({
        target: {
          name: `rotations.${currentRotationIndex}.ends`,
          value: true,
        },
      });
      handleChange({
        target: {
          name: `rotations.${currentRotationIndex}.endDate`,
          value: undefined,
        },
      });
    }
  };
  return (
    <RadioGroup
      name="changeEnd"
      value={activeEndValue}
      onChange={value => radioGroupChangeHandler(value)}
    >
      <Stack>
        <Grid
          templateColumns="0.3fr 0.6fr"
          templateRows="1fr 1fr 0.5ft 0.8fr"
          gap={0.5}
          marginTop={6}
          alignItems="center"
        >
          <GridItem colSpan={2}>
            <FormLabel variant="rotationPattern">
              {rotationsCustomizePattern.customRepeat.labels.ends}
            </FormLabel>
          </GridItem>
          <GridItem colSpan={2}>
            <Radio value="never" checked>
              {' '}
              Never
            </Radio>
          </GridItem>
          <GridItem>
            <Radio value="on"> On</Radio>
          </GridItem>
          <GridItem>
            <ChakraDatepicker
              setDate={(value: Date) =>
                value instanceof Date
                  ? handleChange({
                      target: {
                        name: `rotations.${currentRotationIndex}.endDate`,
                        value,
                      },
                    })
                  : {}
              }
              date={currentRotationEndDate}
              disabled={activeEndValue !== 'on'}
              dateFormat={datePickerFormat}
            />
          </GridItem>
          <GridItem>
            <Radio value="after"> After</Radio>
          </GridItem>
          <GridItem>
            <HStack>
              <NumberInput
                width="70px"
                height=""
                defaultValue={1}
                onChange={(value: any) =>
                  handleChange({
                    target: {
                      name: `rotations.${currentRotationIndex}.endsAfterIterations`,
                      value: Number.parseInt(value),
                    },
                  })
                }
                value={currentRotationOccurences}
                name={`rotations.${currentRotationIndex}.endsAfterIterations`}
                min={1}
                max={30}
                isDisabled={activeEndValue !== 'after'}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Flex pl="20px"> occurences</Flex>
            </HStack>
          </GridItem>
        </Grid>
      </Stack>
    </RadioGroup>
  );
};

export default usePatternEnd;
