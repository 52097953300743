import { IABTest } from '../interfaces/IABTest';
import { addToTest, removeFromTest, addToUserTest, removeFromUserTest } from '../actions/abTest';
import { Dispatch } from 'redux';
/**
 * @description adds A/B Testing keys to redux store
 */
class ABTest {
  private _dispatcher: any = () => {
    throw new Error('Store Not Inited');
  };

  public init = (dispatcher: Dispatch<any>) => {
    this._dispatcher = dispatcher;
  };

  /**
   * api to add test in code
   */
  public addToTest = (tests: IABTest[]) => {
    this._dispatcher(addToTest(tests));
  };

  /**
   * api to remove test in code
   * @event ['__ALL__'] removes all the tests
   */
  public removeFromTest = (tests: IABTest[]) => {
    this._dispatcher(removeFromTest(tests));
  };

  /**
   * user can opt in tests
   */
  public addToUserTest = (test: IABTest) => {
    this._dispatcher(addToUserTest(test));
  };

  /**
   * user can opt out of test
   */
  public removeFromUserTest = (test: IABTest) => {
    this._dispatcher(removeFromUserTest(test));
  };
}

const abTest = new ABTest();
export { abTest as ABTest };
