import { FocusBlock, Para, SelectBox, TextButton } from 'uie/components';
import { IAppState } from 'core/interfaces/IAppState';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { InfoRedIcon } from 'icons';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAssignableTeamMembers } from 'shared/reducers';
import { SquadMember } from '../../types';
import {
  CardActions,
  CardBody,
  CardFooter,
  CardHeader,
  CardWrapper,
  MemberInfo,
  MemberName,
  MemberNameHighlight,
  SquadOwnerLabel,
} from './styles';
type ConflictModalContentProps = {
  memberName: string;
  members: SquadMember[];
  onConfirm: (selectedMember: IUsersOfOrganization) => void;
  onCancel: () => void;
  attemptedAction?: 'remove' | 'updateRole';
};

const ConflictModalContent: React.FC<ConflictModalContentProps> = ({
  memberName,
  members,
  onCancel,
  onConfirm,
  attemptedAction = 'remove',
}) => {
  const [selectedMember, setSelectedMember] = React.useState<IUsersOfOrganization | undefined>();

  const users = useSelector((state: IAppState) => state.organization.users.u);
  const teams = useSelector((state: IAppState) => state.organization.teams);

  const params = useParams<{ id: string }>();

  const selectedTeam = teams.t.find(t => t.id === params.id);

  const teamMembers = getAssignableTeamMembers(selectedTeam!, users);

  const teamMembersAsSquadMembers: SquadMember[] = teamMembers.map(m => {
    return {
      user_id: m.id,
      role: 'member',
      username_for_display: m.username_for_display,
      email_verified: m.email_verified,
      name: m.first_name ? `${m.first_name} ${m.last_name}` : undefined,
      email: m.email,
    };
  });

  const [searchMembers, setSearchMembers] = React.useState('');
  const filteredUsers = teamMembersAsSquadMembers.filter(
    m =>
      m.name !== memberName &&
      (m.name ?? 'Deleted user').toLowerCase().includes(searchMembers.toLowerCase()),
  );

  const _oUsers = users.reduce((acc, u) => {
    acc[u.id] = u;
    return acc;
  }, {} as Record<string, IUsersOfOrganization>);

  const headerString: Record<'remove' | 'updateRole', string> = {
    remove: 'Remove Squad Owner',
    updateRole: 'Convert to Squad Member',
  };

  const isSelectedMemberPartOfTheSquad = (member: IUsersOfOrganization) => {
    if (!member) {
      return true;
    }
    return members.some(m => m.user_id === member.id);
  };

  const getCardBody = (attemptedAction: 'remove' | 'updateRole') => {
    if (attemptedAction === 'remove') {
      return (
        <>
          Unable to remove <MemberNameHighlight>{memberName}</MemberNameHighlight> from the squad. A
          squad must have at least one squad owner.
          <br />
          <br />
          Please convert another squad member to a squad owner before trying again
        </>
      );
    } else if (attemptedAction === 'updateRole') {
      return (
        <>
          Unable to convert <MemberNameHighlight>{memberName}</MemberNameHighlight> to a Squad
          member. A squad must have at least one squad owner.
          <br />
          <br />
          Please convert another squad member to a squad owner before trying again
        </>
      );
    }
    return null;
  };

  return (
    <CardWrapper>
      <CardHeader>
        <MemberInfo>
          <InfoRedIcon width="24px" height="24px" />
          <MemberName>{headerString[attemptedAction]}</MemberName>
        </MemberInfo>
      </CardHeader>
      <CardBody>{getCardBody(attemptedAction)}</CardBody>
      <CardFooter>
        <SquadOwnerLabel>Select Squad Owner</SquadOwnerLabel>
        <div>
          <SelectBox
            hook={
              <Para className="global--text-clip" fontSize={14}>
                {selectedMember
                  ? `${selectedMember?.first_name ?? 'Deleted'} ${selectedMember?.last_name ?? ''}`
                  : 'Select...'}
              </Para>
            }
            searchHookProps={{
              value: searchMembers,
              height: '32px',
              fontSize: '14px',
              placeholder: 'Search users',
              onChange: e => setSearchMembers(e.target.value),
            }}
            onValueChange={(e, value: string) => {
              const selectedUser = _oUsers[value];
              setSelectedMember(selectedUser);
            }}
            height="auto"
            maxHeight="200px"
            width="368px"
            maxWidth="368px"
            style={{
              padding: '9px',
            }}
            buttonHeight="36px"
          >
            {filteredUsers.map((m, i) => (
              <FocusBlock
                className="global--text-clip"
                style={{
                  maxWidth: '368px',
                  marginTop: '4px',
                }}
                key={i}
                value={m.user_id}
              >
                <Para fontSize={14}>{m.name}</Para>
              </FocusBlock>
            ))}
            {filteredUsers.length === 0 ? (
              <FocusBlock key={1} value={-1} disabled={true} isSelected={false}>
                <Para fontSize={14}>None found!</Para>
              </FocusBlock>
            ) : (
              <></>
            )}
          </SelectBox>
        </div>
        {!isSelectedMemberPartOfTheSquad(selectedMember!) && (
          <Para fontSize={14} color="#757F8F" style={{ marginTop: '8px', lineHeight: '150%' }}>
            The selected user is not part of the squad. They will be added to the squad as an owner{' '}
          </Para>
        )}
        <CardActions>
          <TextButton
            disabled={!selectedMember}
            onClick={() => {
              if (selectedMember) {
                onConfirm(selectedMember);
              }
            }}
          >
            <Para fontSize={16} color="#fff">
              Confirm
            </Para>
          </TextButton>

          <TextButton buttonType="ghost" onClick={onCancel}>
            <Para fontSize={16}>Cancel</Para>
          </TextButton>
        </CardActions>
      </CardFooter>
    </CardWrapper>
  );
};

export default ConflictModalContent;
