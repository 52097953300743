export const queryKeys = {
  GETALLJIRACLOUDACCOUNTS: 'getAllJiraCloudAccounts',
  GETALLJIRACLOUDACCOUNTSOFTEAM: 'getAllJiraCloudAccountsOfTeam',
  GETNOTCONFIGUREDTEAM: 'getnotconfiguredteam',
  CREATEJIRACLOUDSACCOUNTS: 'createJiraCloudAccounts',
  DELETEJIRACLOUDSACCOUNTS: 'deleteJiraCloudAccounts',
  UPDATEJIRAACCOUNT: 'updateJiraAccount',
  GETALLJIRACLOUDACCOUNTSCONFIG: 'getAllJiraCloudAccountsConfig',
  CREATEJIRACLOUDSACCOUNTSCONFIG: 'createJiraCloudAccountsConfig',
  GETJIRAPROJECTSFORACCOUNT: 'getJiraProjectsForAccount',
  GETISSUEFORJIRAPROJECT: 'getIssueForJiraProject',
  GETDETAILOFJIRACLOUDCONFIG: 'getDetailOfJiraCloudConfig',
  DELETEJIRAACCOUNTCONFIG: 'deleteJiraAccountConfig',
};

export const reactQueryConfig = {
  enabled: true,
  retry: 1,
  keepPreviousData: true,
  refetchOnWindowFocus: false,
};
