import {
  AspectRatio,
  Box,
  Button,
  Center,
  ChakraProvider,
  Circle,
  Container,
  Divider,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Spacer,
  Stack,
  Tag,
  TagLabel,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService } from 'core';
import { Locale } from 'core/helpers/dateUtils';
import _ from 'lodash';
import moment from 'moment';
import { memo, useEffect, useState, useMemo } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { connect, useSelector } from 'react-redux';
import { Link as RLink, RouteChildrenProps, useHistory } from 'react-router-dom';
import { CustomTable } from 'views/shared/components';

import useQueryParams from 'core/hooks/useQueryParams';
import { T_WA_UP_SLO_DELETED, T_WA_UP_SLO_PAGE_VIEWED } from '../../../../core/const/tracker';
import { IAppState } from '../../../../core/interfaces/IAppState';
import { EditIcon, PlayBtnBlackIcon } from '../../../../icons';
import * as iconBtn from '../../../../icons';
import { AppTracker } from '../../../../shared/analytics/tracker';
import { ResponsivePieComponent } from '../../../shared/components/PieChart';
import { SLOListFilter } from './filters/sloList.filters';
import { useDeleteSlo } from './hooks/useDeleteSlo';
import { useSloAgregated } from './hooks/useSloAggregatedData';
import { useSloList } from './hooks/useSloList';
import { AlertDialogComponent } from '../../../shared/components';
import { VideoModel } from './modal/fullScreenModel';
import { sloListFilterConst, tableCellStyle, tableHeader, tooltipStyle } from './slo.constants';
import { getIsActive, getIsHealthy, getServicesList, getSloTags } from './slo.helper';
import { ISelectedFilterOptions } from './slo.interface';
import {
  FilterEntity,
  FilterObject,
  useEntityOwnerFilter,
} from '../owner-filters/hooks/useEntityOwnerFilter';
import { encodeFilterParam } from '../owner-filters/helpers';
import { fontTheme } from 'library/theme';
import { THEME_COLORS } from 'library/theme/colors';
import { TextButton, Para, Theme } from 'uie/components';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { EntityACLMeta } from 'core/userAccess/types';
import { getUserAccessStore, subscribeToUserAccessStore } from 'core/userAccess/UserAccessStore';

export type IMix = Pick<IAppState, 'organization'> &
  RouteChildrenProps<{ id: string; mode: string }>;

export type IServices = IAppState['organization']['services'];

const SloList = (props: IMix) => {
  const _uA = useUserAccess();
  const hasCreate = _uA.hasCreateAccess('slos');
  const hasRead = _uA.hasReadAccess('slos');
  const { theme } = Theme;

  const {
    pageOffset,
    pageLimit,
    setLimit,
    setOffset,
    data: sloListData,
    isLoading: isSloListLoading,
    isSuccess: isSloListSuccess,
    pagination,
    setTeamId,
    isError: isSloListError,
    setSloListFilters,
    totalNoOfPages,
    sloListFilters,
  } = useSloList(props.organization.selectedTeam.teamId, hasRead);

  const {
    data: aggregatedData,
    isLoading: isAgregatedLoading,
    isSuccess: isAgregatedSuccess,
    isError: isAgregatedError,
    setTeamId: setTeamIdForAggregated,
  } = useSloAgregated(props.organization.selectedTeam.teamId);
  const disableButton = BillingService.isFeatureDisabled(props, 'slo');

  const deleteSlo = useDeleteSlo(props.organization.selectedTeam.teamId);
  const [deleteSloId, setDeleteSloId] = useState<number | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const query = useQueryParams();
  const [acl, setACL] = useState<Record<string, EntityACLMeta>>({});

  useEffect(() => {
    const unsub = subscribeToUserAccessStore(state => {
      setACL(state.entityACLMap?.slos ?? {});
    });
    return unsub;
  }, []);

  const ownerQueryParam = useMemo(
    () => query.get('entity_owner') ?? '',
    [query.get('entity_owner')],
  );

  const {
    activeFilterObject,
    selectedFilterObject,
    activeFilterTags: ownerFilterTags,
    dropdownOptions: ownerDropdownOptions,
    onChangeHandler: ownerDropdownChangeHandler,
    handleActiveFilterTagClick: onClickActiveOwnerFilterTag,
    resetOwnerFilters,
  } = useEntityOwnerFilter({
    queryParamsString: ownerQueryParam,
    entity: FilterEntity.SLO,
  });

  const applyEntityOwnerFilters = (filterObject: FilterObject) => {
    const filterUrlParams = encodeFilterParam(filterObject);
    query.delete('entity_owner');
    filterUrlParams && query.append('entity_owner', filterUrlParams);
    history.push(`/slo?${query}`);
  };

  useEffect(() => {
    applyEntityOwnerFilters(activeFilterObject);
  }, [activeFilterObject]);

  useEffect(() => {
    applyEntityOwnerFilters(selectedFilterObject);
  }, [selectedFilterObject]);

  useEffect(() => {
    if (ownerQueryParam !== '') {
      setSloListFilters({ ...sloListFilters, owner: ownerQueryParam });
    }
  }, [ownerQueryParam]);

  const {
    isOpen: fullWidthIsOpen,
    onOpen: fullWidthOnOpen,
    onClose: fullWidthOnClose,
  } = useDisclosure();

  const _getSloList = async (
    selectedFilter: Array<string> = [],
    selectedFilterOpt?: ISelectedFilterOptions,
  ) => {
    if (selectedFilterOpt) {
      const queryParam: { [key: string]: string | boolean | string[] } = {};
      const isService = selectedFilter.some(_ => _ == 'service');
      const isActive = selectedFilter.some(_ => _ == 'IsActive');
      const isDateTime = selectedFilter.some(_ => _ == 'dateTime');

      if (isActive && selectedFilterOpt.IsActive.length > 0) {
        queryParam['is-active'] = selectedFilterOpt.IsActive[0] == 'Active' ? true : false;
      }

      if (isService) {
        queryParam['service-id'] = selectedFilterOpt.service;
      }

      if (isDateTime) {
        queryParam['fromDate'] = Locale.toISO(selectedFilterOpt.dateTime.fromDate);

        queryParam['toDate'] = Locale.toISO(selectedFilterOpt.dateTime.toDate);
      }

      if (ownerQueryParam) {
        queryParam['owner'] = ownerQueryParam;
      }

      setSloListFilters(queryParam);
    }
  };

  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);

  useEffect(() => {
    setShowUpgradeModal(BillingService.isFeatureDisabled(props, 'slo'));
    AppTracker.track(T_WA_UP_SLO_PAGE_VIEWED);
  }, []);

  useEffect(() => {
    setTeamId(props.organization.selectedTeam.teamId);
    setTeamIdForAggregated(props.organization.selectedTeam.teamId);
  }, [props.organization.selectedTeam.teamId]);

  const getEntityName = (id: string, type: string) => {
    let entityName = '';
    switch (type) {
      case 'user': {
        const user = props.organization.users.u?.find(user => user.id === id);
        if (user) {
          entityName = `${user.first_name} ${user.last_name}`;
        }
        break;
      }
      case 'squad': {
        const squad = props.organization.squads.s?.find(squad => squad.id === id);
        if (squad) {
          entityName = `${squad.name}`;
        }
        break;
      }
      case 'team': {
        const team = props.organization.selectedTeam.team;
        if (team) {
          entityName = `${team.name}`;
        }
        break;
      }
    }
    return entityName || 'Deleted Entity';
  };

  const getEntityUsername = (id: string) => {
    let entityUsername = '';
    const user = props.organization.users.u?.find(user => user.id === id);
    if (user) {
      entityUsername = user.username_for_display;
    }
    return entityUsername;
  };

  const getUsernameTooltipLabel = (id: string, type: string) => {
    const name = getEntityName(id, type);
    const username = getEntityUsername(id);
    return (
      <VStack alignItems={'left'} spacing={0}>
        <Text fontWeight={'bold'}>{name}</Text>
        <Text style={{ color: '#646464' }}>{username}</Text>
      </VStack>
    );
  };

  return (
    <ChakraProvider theme={fontTheme}>
      <Box m={5}>
        {
          <Container maxW="full">
            <Center>
              <Box p={1}>
                <Text fontSize="27px" mt={5} mb={2} size="md" color="#1D426B" fontWeight="400">
                  Service Level Objectives (SLOs){' '}
                  {!hasRead && (
                    <Text display="inline-block" color="red.400">
                      ( You are not authorized to access this )
                    </Text>
                  )}
                </Text>
                <Text
                  style={{
                    marginBottom: '10px',
                    fontSize: '12px',
                    color: '#808080',
                    fontWeight: 300,
                  }}
                >
                  Create and manage SLOs for your organization to define and track your service's
                  performance delivery. Learn more about SLOs{' '}
                  <a
                    href="https://support.squadcast.com/slo-tracker/slo-basics"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#0000FF', textDecoration: 'underline', fontSize: '12px' }}
                  >
                    here
                  </a>
                  .
                </Text>
              </Box>
              <Spacer />
              <Box display="flex">
                {sloListData && sloListData.slos.length > 0 && (
                  <IconButton
                    isRound={true}
                    mr={5}
                    aria-label=""
                    colorScheme="white"
                    onClick={fullWidthOnOpen}
                    icon={<PlayBtnBlackIcon />}
                  />
                )}
                <NoPermissionTooltip isDisabled={hasCreate}>
                  <TextButton
                    style={{ padding: '8px 22px' }}
                    disabled={disableButton || !hasCreate}
                    onClick={() => history.push('/slo/create')}
                    className={`${hasCreate ? '' : 'disabled'}`}
                  >
                    <Para color={theme.font.white} fontWeight={500} fontSize={14}>
                      + Create New SLO
                    </Para>
                  </TextButton>
                </NoPermissionTooltip>
              </Box>
            </Center>

            {isSloListLoading && (
              <Stack>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            )}

            {hasRead && sloListData && (sloListData.slos.length > 0 || !_.isEmpty(sloListFilters)) && (
              <>
                {isAgregatedSuccess && (
                  <Center>
                    <Stack direction="row" mb={10}>
                      {aggregatedData &&
                        aggregatedData.map(({ name, items }, index) => {
                          return (
                            <Box
                              key={index}
                              w="400px"
                              borderWidth="1px"
                              bgColor="rgba(255, 255, 255, 0.1)"
                              sx={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.16)' }}
                            >
                              <Text
                                p={2}
                                color={'rgba(45, 57, 100, 1)'}
                                fontFamily={'Mulish'}
                                fontSize={14}
                                textAlign={'center'}
                                fontWeight={'bold'}
                              >
                                {name}
                              </Text>
                              {Array.isArray(items) && (
                                <ResponsivePieComponent
                                  colors_={['accent', 'dark2', 'category10'][index]}
                                  data={{ items }}
                                ></ResponsivePieComponent>
                              )}
                            </Box>
                          );
                        })}
                    </Stack>
                  </Center>
                )}
                {isAgregatedLoading && (
                  <Stack m={5}>
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                  </Stack>
                )}

                {isAgregatedError && !isSloListError && (
                  <Box m={5} p={2} shadow="md" borderWidth="1px" textAlign="center">
                    <Text fontWeight="medium">No Data Available</Text>
                  </Box>
                )}

                {isSloListSuccess &&
                  sloListData &&
                  (sloListData.slos.length > 0 || !_.isEmpty(sloListFilters)) && (
                    <>
                      <SLOListFilter
                        getSloList={_getSloList}
                        services={props.organization.services}
                        sloData={sloListData}
                        filterList={sloListFilterConst}
                        {...{
                          onClickActiveOwnerFilterTag,
                          ownerQueryParam,
                          ownerFilterTags,
                          ownerDropdownChangeHandler,
                          ownerDropdownOptions,
                          resetOwnerFilters,
                        }}
                      />
                      {sloListData.slos.length > 0 && (
                        <CustomTable
                          pageOffset={pageOffset}
                          pageLimit={pageLimit}
                          pagination={pagination}
                          changeLimit={limit => {
                            setLimit(limit);
                          }}
                          changeOffset={offset => {
                            setOffset(offset);
                          }}
                          tableHeader={tableHeader}
                          totalPages={totalNoOfPages}
                        >
                          {sloListData.slos.map((member, index) => {
                            const hasUpdate = _uA.hasUpdateAccess('slos', `${member.id ?? 0}`);

                            const hasDelete = _uA.hasDeleteAccess('slos', `${member.id ?? 0}`);

                            return (
                              <Tr key={member.id}>
                                <Td
                                  onClick={() => {
                                    props.history.push(`/slo/${member.id}`);
                                  }}
                                  cursor="pointer"
                                  sx={{ ...tableCellStyle, width: '160px' }}
                                >
                                  <Text
                                    color="rgba(15, 97, 221, 1)"
                                    fontSize={13}
                                    fontFamily="Mulish"
                                  >
                                    {member.name}
                                  </Text>
                                </Td>
                                <Td sx={{ ...tableCellStyle, width: '100px' }}>
                                  <Text fontSize={13} color="muted">
                                    {member.target_slo}
                                  </Text>
                                </Td>
                                <Td sx={{ ...tableCellStyle, width: '100px' }}>
                                  <Text fontSize={13} color="muted">
                                    {member.current_slo?.toFixed(2)}
                                  </Text>
                                </Td>
                                <Td sx={{ ...tableCellStyle }}>
                                  {member.is_active ? (
                                    <HStack spacing="3">
                                      <Circle
                                        size="10px"
                                        bg={getIsHealthy(member.is_healthy)['color']}
                                        color="white"
                                      />
                                      <Text fontSize={13} color="muted">
                                        {getIsHealthy(member.is_healthy)['value']}
                                      </Text>
                                    </HStack>
                                  ) : (
                                    '-'
                                  )}
                                </Td>
                                <Td sx={{ ...tableCellStyle }}>
                                  <Tooltip
                                    hasArrow
                                    label={getServicesList(
                                      member.service_ids,
                                      props.organization.services,
                                    ).map(_ => (
                                      <>
                                        <Text fontSize="11px" fontWeight="bold" key={_}>
                                          {_}{' '}
                                        </Text>
                                        <Divider orientation="vertical" />
                                      </>
                                    ))}
                                    {...tooltipStyle}
                                  >
                                    <Flex>
                                      {getServicesList(
                                        member.service_ids,
                                        props.organization.services,
                                      ).map((value, index) => {
                                        if (index === 0) {
                                          return (
                                            <Tag key={index} size="sm" borderRadius="full">
                                              <TagLabel fontWeight="normal">{value}</TagLabel>
                                            </Tag>
                                          );
                                        }
                                      })}
                                      {member?.service_ids && member?.service_ids?.length > 1 && (
                                        <Text fontWeight="semibold" fontSize={13}>
                                          {'+'}
                                          {member?.service_ids && member?.service_ids?.length - 1}
                                        </Text>
                                      )}
                                    </Flex>
                                  </Tooltip>
                                </Td>
                                <Td sx={{ ...tableCellStyle }}>
                                  <HStack spacing="3">
                                    <Circle
                                      size="10px"
                                      bg={getIsActive(member.is_active)['color']}
                                      color="white"
                                    />
                                    <Text fontSize={13} color="muted">
                                      {getIsActive(member.is_active)['value']}
                                    </Text>
                                  </HStack>
                                </Td>
                                <Td sx={{ ...tableCellStyle, width: '100px' }}>
                                  <Text fontSize={13} color="muted">
                                    {member.incident_count}
                                  </Text>
                                </Td>
                                <Td sx={{ ...tableCellStyle, width: '100px' }}>
                                  <Text fontSize={13} color="muted">
                                    {_.capitalize(member?.time_interval_type || '')}
                                  </Text>
                                </Td>
                                <Td sx={{ ...tableCellStyle, width: '100px' }}>
                                  <Text fontSize={13} color="muted">
                                    {Number.isInteger(
                                      moment
                                        .duration(
                                          moment(member.end_time).diff(moment(member.start_time)),
                                        )
                                        .asDays(),
                                    ) == true && member.time_interval_type === 'fixed'
                                      ? moment
                                          .duration(
                                            moment(member.end_time).diff(moment(member.start_time)),
                                          )
                                          .asDays()
                                      : member.time_interval_type === 'rolling'
                                      ? member.duration_in_days
                                      : moment
                                          .duration(
                                            moment(member.end_time).diff(moment(member.start_time)),
                                          )
                                          .asDays()
                                          .toFixed(4)}
                                  </Text>
                                </Td>
                                <Td sx={{ ...tableCellStyle, width: '160px' }}>
                                  <Text fontSize={13} color="muted">
                                    {Locale.toFullDate(member.updated_at || new Date())}
                                  </Text>
                                </Td>
                                <Td sx={{ ...tableCellStyle }}>
                                  <Tooltip
                                    hasArrow
                                    label={getSloTags(member.tags).map(_ => (
                                      <>
                                        <Text fontSize="11px" fontWeight="bold" key={_}>
                                          {_}{' '}
                                        </Text>
                                        <Divider orientation="vertical" />
                                      </>
                                    ))}
                                    {...tooltipStyle}
                                  >
                                    <Flex>
                                      {getSloTags(member.tags).map((value, index) => {
                                        if (index == 1) {
                                          return (
                                            <Tag key={index} size="sm" borderRadius="full">
                                              <TagLabel fontWeight="normal">{value}</TagLabel>
                                            </Tag>
                                          );
                                        }
                                      })}
                                      {getSloTags(member.tags).length > 1 && (
                                        <Text fontWeight="medium" fontSize={12}>
                                          {'+'}
                                          {getSloTags(member.tags).length - 1}
                                        </Text>
                                      )}
                                    </Flex>
                                  </Tooltip>
                                </Td>
                                <Td sx={{ ...tableCellStyle, width: '160px' }}>
                                  <Tooltip
                                    hasArrow
                                    label={getUsernameTooltipLabel(
                                      member.slo_owner_id,
                                      member.slo_owner_type,
                                    )}
                                    bg={THEME_COLORS.secondary[100]}
                                    color={THEME_COLORS.secondary[1000]}
                                  >
                                    <Text fontSize={13} color="muted" maxW="inherit" isTruncated>
                                      {getEntityName(member.slo_owner_id, member.slo_owner_type)}
                                    </Text>
                                  </Tooltip>
                                </Td>
                                <Td textAlign="left" p={2}>
                                  <HStack spacing="1">
                                    <Popover size="sm" matchWidth>
                                      <PopoverTrigger>
                                        {
                                          <IconButton
                                            variant=" unstyled"
                                            _focus={{ outline: 'none' }}
                                            aria-label="More"
                                            icon={<iconBtn.MoreIcon />}
                                          />
                                        }
                                      </PopoverTrigger>
                                      <PopoverContent
                                        _focus={{ outline: 'none' }}
                                        width="15"
                                        bg="whiteAlpha.900"
                                      >
                                        <PopoverArrow />
                                        {/* <PopoverCloseButton /> */}
                                        <PopoverBody p={0}>
                                          <RLink
                                            onClick={e => {
                                              if (!member.is_active) e.preventDefault();
                                            }}
                                            to={hasUpdate ? `/slo/edit/${member.id}` : '#'}
                                            className={hasUpdate ? '' : 'disabled'}
                                          >
                                            <NoPermissionTooltip isDisabled={hasUpdate}>
                                              <IconButton
                                                disabled={!member.is_active}
                                                colorScheme="blackAlpha"
                                                icon={<EditIcon width="16px" />}
                                                variant="ghost"
                                                aria-label="Edit member"
                                                isDisabled={!hasUpdate}
                                              />
                                            </NoPermissionTooltip>
                                          </RLink>
                                          <NoPermissionTooltip isDisabled={hasDelete}>
                                            <IconButton
                                              onClick={() => {
                                                setDeleteSloId(member.id);
                                                onOpen();
                                              }}
                                              color="rgba(240, 69, 70, 1)"
                                              icon={<FiTrash2 fontSize="16px" />}
                                              variant="ghost"
                                              aria-label="Delete member"
                                              isDisabled={!hasDelete}
                                            />
                                          </NoPermissionTooltip>
                                        </PopoverBody>
                                      </PopoverContent>
                                    </Popover>
                                  </HStack>
                                </Td>
                              </Tr>
                            );
                          })}
                        </CustomTable>
                      )}
                    </>
                  )}
                {(isSloListError || (sloListData && sloListData.slos.length == 0)) && (
                  <Center p={2} shadow="md" borderWidth="1px" textAlign="center">
                    <Text fontWeight="medium">No Data Available</Text>
                  </Center>
                )}
              </>
            )}

            {((sloListData && sloListData.slos.length === 0 && _.isEmpty(sloListFilters)) ||
              !hasRead) && (
              <>
                <AspectRatio ratio={20 / 9} bgColor="gray.300">
                  <iframe
                    title="slo"
                    src="https://www.youtube.com/embed/qwGYc7m67O8"
                    allowFullScreen
                  />
                </AspectRatio>
              </>
            )}

            <AlertDialogComponent
              isOpen={isOpen}
              onClose={onClose}
              callbackFn={() => {
                deleteSloId && deleteSlo(deleteSloId);
                AppTracker.track(T_WA_UP_SLO_DELETED);
              }}
              msg={`Are you sure? You can't undo this action afterwards.`}
              title={'Delete Slo'}
              isDelete={true}
            />
            <VideoModel isOpen={fullWidthIsOpen} onClose={() => fullWidthOnClose()}></VideoModel>
          </Container>
        }
      </Box>
      {
        <UpgradePlanModal
          hasBillingPermission={BillingService.hasManageBillingPermission(props)}
          showModal={showUpgradeModal}
          message={BillingService.getMessage(0, 'slo', props)}
          onCancel={() => {
            setShowUpgradeModal(false);
            (props as any).history.goBack();
          }}
          header={BillingService.getHeader(0, 'slo', props)}
          onUpgrade={() => {
            setShowUpgradeModal(false);
          }}
        />
      }
    </ChakraProvider>
  );
};

export default connect(({ organization, APP_CONFIG }: IAppState) => ({
  organization,
  APP_CONFIG,
}))(memo(SloList));
