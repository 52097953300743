import { isNullOrUndefined } from 'core/util';
import { clear } from 'store/localStorage';

import { IIncidentGroup } from '../../core/interfaces/IIncidentGroups';
import {
  CURRENT_APP_VERSION,
  HOSTNAME,
  ORG_SEARCH_HISTORY,
  SHOULD_SHOW_MIGRATED_SCHEDULES_BANNER_TOP_BAR_STATE,
  SHOULD_SHOW_SCHEDULES_BANNER_TOP_BAR_STATE,
  SHOULD_SHOW_VERIFY_PHONE_NUMBER_TOP_BAR_STATE,
  SLACK_STATE,
  UDID,
  USER_INCIDENT_GROUPS,
} from './const';

const asyncStorage = window.localStorage;
const setDeviceId = async (deviceId: string) => {
  try {
    await asyncStorage.setItem(UDID, deviceId);
  } catch (err: any) {
    throw err;
  }
};

const getDeviceId = async () => {
  try {
    const deviceId = await asyncStorage.getItem(UDID);
    return deviceId;
  } catch (err: any) {
    throw err;
  }
};

const removeDeviceId = async () => {
  try {
    await asyncStorage.removeItem(UDID);
  } catch (err: any) {
    throw err;
  }
};

const setHostname = async (hostname: string) => {
  try {
    await asyncStorage.setItem(HOSTNAME, hostname);
  } catch (err: any) {
    throw err;
  }
};

const getHostname = async () => {
  try {
    const hostname = await asyncStorage.getItem(HOSTNAME);
    return hostname;
  } catch (err: any) {
    throw err;
  }
};

const removeHostname = async () => {
  try {
    await asyncStorage.removeItem(HOSTNAME);
  } catch (err: any) {
    throw err;
  }
};

const updateAPPVersion = async (newAppVersion: string) => {
  try {
    const appVersion = asyncStorage.getItem(CURRENT_APP_VERSION);
    const oMa = (appVersion || '0.0.0').split('.')[0];
    const nMa = newAppVersion.split('.')[0];

    if (!isNullOrUndefined(appVersion) && parseFloat(`${nMa}`) > parseFloat(`${oMa}`)) {
      clear();
    }

    await asyncStorage.setItem(CURRENT_APP_VERSION, newAppVersion);
  } catch (err: any) {
    throw err;
  }
};

const setSearchHistory = async (searches: { [key: string]: string[] }) => {
  try {
    await asyncStorage.setItem(ORG_SEARCH_HISTORY, JSON.stringify(searches));
  } catch (err: any) {
    throw err;
  }
};

const getSearchHistory = async () => {
  try {
    const search = await asyncStorage.getItem(ORG_SEARCH_HISTORY);
    return JSON.parse(search || '');
  } catch (err: any) {
    throw err;
  }
};

const setUserIncidentGroups = async (groups: IIncidentGroup[], orgSlug: string) => {
  const key = orgSlug ? `${USER_INCIDENT_GROUPS}:${orgSlug}` : USER_INCIDENT_GROUPS;
  try {
    await asyncStorage.setItem(key, JSON.stringify(groups));
  } catch (err: any) {
    throw err;
  }
};

const getUserIncidentGroups = async (orgSlug: string) => {
  const key = orgSlug ? `${USER_INCIDENT_GROUPS}:${orgSlug}` : USER_INCIDENT_GROUPS;
  try {
    const groups = await asyncStorage.getItem(key);
    return JSON.parse(groups || '[]');
  } catch (err: any) {
    throw err;
  }
};

const getSlackState = async () => {
  try {
    const slackState = await asyncStorage.getItem(SLACK_STATE);
    return slackState;
  } catch (err: any) {
    throw err;
  }
};

const setSlackState = async (slackState: string) => {
  try {
    await asyncStorage.setItem(SLACK_STATE, slackState);
  } catch (err: any) {
    throw err;
  }
};

const shouldShowVerifyPhoneNumberTopBar = async () => {
  try {
    const value = await asyncStorage.getItem(SHOULD_SHOW_VERIFY_PHONE_NUMBER_TOP_BAR_STATE);
    if (value === 'false') {
      return false;
    }
    return true;
  } catch (err: any) {
    throw err;
  }
};

const setShouldShowVerifyPhoneNumberTopBar = async (value: boolean) => {
  try {
    await asyncStorage.setItem(SHOULD_SHOW_VERIFY_PHONE_NUMBER_TOP_BAR_STATE, value.toString());
  } catch (err: any) {
    throw err;
  }
};

const shouldShowSchedulesBannerTopBar = () => {
  try {
    const value = asyncStorage.getItem(SHOULD_SHOW_SCHEDULES_BANNER_TOP_BAR_STATE);
    if (value === 'false') {
      return false;
    }
    return true;
  } catch (err: any) {
    throw err;
  }
};

const setShouldShowSchedulesBannerTopBar = async (value: boolean) => {
  try {
    asyncStorage.setItem(SHOULD_SHOW_SCHEDULES_BANNER_TOP_BAR_STATE, value.toString());
  } catch (err: any) {
    throw err;
  }
};

const shouldShowMigratedSchedulesBannerTopBar = () => {
  try {
    const value = asyncStorage.getItem(SHOULD_SHOW_MIGRATED_SCHEDULES_BANNER_TOP_BAR_STATE);
    if (value === 'false') {
      return false;
    }
    return true;
  } catch (err: any) {
    throw err;
  }
};

const setShouldShowMigratedSchedulesBannerTopBar = async (value: boolean) => {
  try {
    asyncStorage.setItem(SHOULD_SHOW_MIGRATED_SCHEDULES_BANNER_TOP_BAR_STATE, value.toString());
  } catch (err: any) {
    throw err;
  }
};

const localStore = {
  getDeviceId,
  setDeviceId,
  removeDeviceId,
  setHostname,
  getHostname,
  removeHostname,
  updateAPPVersion,
  setSearchHistory,
  getSearchHistory,
  setUserIncidentGroups,
  getUserIncidentGroups,
  getSlackState,
  setSlackState,
  shouldShowVerifyPhoneNumberTopBar,
  setShouldShowVerifyPhoneNumberTopBar,
  shouldShowSchedulesBannerTopBar,
  setShouldShowSchedulesBannerTopBar,
  shouldShowMigratedSchedulesBannerTopBar,
  setShouldShowMigratedSchedulesBannerTopBar,
};

export { localStore as LocalStore };
