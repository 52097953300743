import { Box, Flex, HStack } from '@chakra-ui/react';
import { FC } from 'react';

import { IconButton } from '../../../atoms';
import { LibraryIcons } from '../../../icons';
import { CursorPaginationProps, ICursorPagination, PageSizePaginationProps } from '../types';
import { PageSize } from './PageSize';

const CursorPagination: FC<ICursorPagination> = ({
  canNextPage,
  canPreviousPage,
  nextPage,
  previousPage,
  disabled,
}) => {
  const handleLeftNavigate = () => canPreviousPage && previousPage();
  const handleRightNavigate = () => canNextPage && nextPage();

  const iconProps = {
    width: '24px',
    height: '24px',
  };

  return (
    <Flex gap={2} justifyContent={'center'} alignItems={'center'}>
      <IconButton
        variant="ghost"
        title="Go to Previous"
        aria-label="Previous"
        disabled={!canPreviousPage || disabled}
        sx={{
          '&[disabled]': {
            opacity: '.5',
          },
        }}
        icon={
          <LibraryIcons.ChevronLeftIcon
            color={canPreviousPage ? 'brand.blue' : 'secondary.1000'}
            {...iconProps}
          />
        }
        onClick={handleLeftNavigate}
      />

      <IconButton
        variant="ghost"
        title="Go to Next"
        aria-label="Next"
        disabled={!canNextPage || disabled}
        sx={{
          '&[disabled]': {
            opacity: '.5',
          },
        }}
        icon={
          <LibraryIcons.ChevronRightIcon
            color={canNextPage ? 'brand.blue' : 'secondary.1000'}
            {...iconProps}
          />
        }
        onClick={handleRightNavigate}
      />
    </Flex>
  );
};

export const CursorPaginationBlock: React.FC<CursorPaginationProps> = ({
  style,
  loading,
  disabled,
  nextPage,
  previousPage,
  canNextPage,
  canPreviousPage,
  pageSize,
  setPageSize,
  maxPageSize,
}) => {
  const pageSizeProps: PageSizePaginationProps = {
    loading,
    setPageSize,
    pageSize,
    maxPageSize,
  };

  const paginationprops: ICursorPagination = {
    canNextPage,
    canPreviousPage,
    pageSize,
    nextPage,
    previousPage,
    disabled,
  };

  return (
    <Box
      opacity={loading ? 0.3 : 1}
      bg="brand.white"
      py="8px"
      px="24px"
      borderTopWidth="1px"
      borderTopStyle="solid"
      borderTopColor="secondary.200"
      zIndex={loading ? 'auto' : 1}
      {...style}
    >
      <HStack justifyContent={'space-between'} alignItems={'center'} mr={4}>
        <PageSize {...pageSizeProps} />
        <CursorPagination {...paginationprops} />
      </HStack>
    </Box>
  );
};
