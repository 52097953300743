import { IconButton } from 'uie/components';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { DeleteIcon, HelpFilledIcon } from 'icons';
import * as React from 'react';
import { useLocation } from 'react-router';
import { useAddMembersToSquad } from '../../hooks/useSquad';
import { Member, SquadMemberRole, SquadMemberRoleString } from '../../types';
import { SquadMemberDropDown } from './SquadMemberDropdown';
import {
  AddMembersButton,
  CancelButton,
  ModalActions,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SquadMemberSelectionColumn,
  SquadMemberSelectionContainer,
  SquadMemberSelectionLabel,
  SquadRoleDropdownIcon,
  SquadRoleLabel,
  SquadRoleOptionContainer,
  SquadRoleOptionsContainer,
  SquadRoleOptionsWrapper,
  SquadRoleRadio,
  SquadRoleSelectionColumn,
  SquadRoleSelectionLabel,
} from './styles';

import { Para, TextButton } from 'uie/components';
import { StyledHelpIcon } from '../styles';
import { pluralize } from 'core/helpers/stringUtils';
import { v4 } from 'uuid';

const SquadRoleOption: React.FC<{
  role: string;
  checked: boolean;
  onClick: (role: SquadMemberRole) => void;
}> = ({ role, checked, onClick }) => (
  <SquadRoleOptionContainer onClick={() => onClick(role as SquadMemberRole)}>
    <SquadRoleRadio checked={checked} />
    <SquadRoleLabel>{role}</SquadRoleLabel>
  </SquadRoleOptionContainer>
);

type SquadMembers = {
  user_id: string | null;
  role: SquadMemberRole;
  tempId: string;
};

type Props = {
  onClose: () => void;
  members: Member[];
  onHelpClick?: () => void;
};
const AddSquadMembersModal: React.FC<Props> = ({ onClose, members, onHelpClick }) => {
  const [newMembersList, setNewMembersList] = React.useState<SquadMembers[]>([
    {
      role: 'member',
      user_id: null,
      tempId: v4(),
    },
  ]);

  const updateRoleByIndex = (index: number, role: SquadMemberRole) => {
    const newMembersListCopy = [...newMembersList];
    newMembersListCopy[index].role = role;
    setNewMembersList(newMembersListCopy);
  };

  const addNewMemberRow = () => {
    setNewMembersList(prev => [
      ...prev,
      {
        role: 'member',
        user_id: null,
        tempId: v4(),
      },
    ]);
  };

  const updateNewMember = (index: number, user: IUsersOfOrganization) => {
    const canAddNewMember = newMembersList[index].user_id === null;
    setNewMembersList(prev => {
      const newMembersListCopy = [...prev];
      newMembersListCopy[index] = {
        ...newMembersListCopy[index],
        user_id: user.id,
      };
      return newMembersListCopy;
    });
    if (canAddNewMember) {
      addNewMemberRow();
    }
  };

  const deleteNewMember = (index: number) => {
    setNewMembersList(prev => {
      const newMembersListCopy = [...prev];
      // remove item at index
      newMembersListCopy.splice(index, 1);
      return newMembersListCopy;
    });
  };

  const { mutate: addMembersToSquad } = useAddMembersToSquad({
    onSuccess: () => {
      onClose();
    },
  });

  const location = useLocation();
  const hash = location.hash;
  const selectedId = hash.replace('#', '');

  const newMembersFiltered: Member[] = newMembersList
    .filter(m => m.user_id !== null)
    .map(m => ({
      user_id: m.user_id!,
      role: m.role,
    }));

  return (
    <ModalContainer>
      <ModalHeader>Add Members</ModalHeader>
      {newMembersList.map((newMember, idx) => {
        return (
          <ModalContent key={newMember.tempId}>
            <SquadMemberSelectionContainer>
              <SquadMemberSelectionColumn>
                <SquadMemberSelectionLabel
                  style={{
                    color: '#09305A',
                  }}
                >
                  Select Member
                </SquadMemberSelectionLabel>
                <SquadMemberDropDown
                  members={members}
                  onSelect={user => updateNewMember(idx, user)}
                  newMembersList={newMembersList}
                />
              </SquadMemberSelectionColumn>
              <SquadRoleSelectionColumn>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SquadRoleSelectionLabel>Select Squad Role</SquadRoleSelectionLabel>
                  <IconButton
                    style={{ padding: 0, marginLeft: '6px', marginTop: '1px' }}
                    onClick={() => onHelpClick?.()}
                  >
                    <StyledHelpIcon />
                  </IconButton>
                </div>
                <SquadRoleOptionsContainer>
                  <SquadRoleOptionsWrapper>
                    <SquadRoleOption
                      onClick={() => updateRoleByIndex(idx, 'owner')}
                      role={SquadMemberRoleString.owner}
                      checked={newMember.role === 'owner'}
                    />
                    <SquadRoleOption
                      onClick={() => updateRoleByIndex(idx, 'member')}
                      role={SquadMemberRoleString.member}
                      checked={newMember.role === 'member'}
                    />
                  </SquadRoleOptionsWrapper>
                  {newMembersList.length > 1 ? (
                    <IconButton
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                      }}
                      onClick={() => deleteNewMember(idx)}
                    >
                      <DeleteIcon height="18px" width="18px" color={'#D14C44'} />
                    </IconButton>
                  ) : null}
                </SquadRoleOptionsContainer>
              </SquadRoleSelectionColumn>
            </SquadMemberSelectionContainer>
          </ModalContent>
        );
      })}
      <ModalFooter>
        <ModalActions>
          <TextButton
            onClick={() => {
              onClose();

              addMembersToSquad({
                squadId: selectedId,
                members: newMembersFiltered,
              });
            }}
            disabled={newMembersFiltered.length === 0}
          >
            <Para
              fontSize={16}
              color="#fff"
              style={{
                lineHeight: '150%',
              }}
            >
              Add {newMembersFiltered.length}{' '}
              {pluralize(newMembersFiltered.length, 'Member', 'Members')}
            </Para>
          </TextButton>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
        </ModalActions>
      </ModalFooter>
    </ModalContainer>
  );
};

export default AddSquadMembersModal;
