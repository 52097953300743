import request from '../commons';
import { API } from '../../api';

function getOrgCurrentUser() {
  const req = request.get(API.config.batman)(
    `organizations/${API.config.organizationId}/users/${API.config.userId}`,
  );
  return req;
}

export { getOrgCurrentUser };
