import React from 'react';
import { Box, Text, VStack, Input } from '@chakra-ui/react';
import TriggersAndProperties from './TriggersAndPropertiesIndex';
import { Workflow } from '../../types/response/workflow';
import ActionsIndex from './ActionsIndex';

type WorkflowIndexProps = Pick<Workflow, 'trigger' | 'filters' | 'actions'>;

const WorkflowIndex: React.FC<WorkflowIndexProps> = ({ trigger, filters, actions }) => {
  const [search, setSearch] = React.useState('');
  const [triggerFound, setTriggerFound] = React.useState(true);
  const [actionFound, setActionFound] = React.useState(true);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (e.target.value === '') {
      setActionFound(true);
      setTriggerFound(true);
    }
  };
  return (
    <VStack align="stretch" pr={6}>
      <Text variant="h3" fontWeight="bold">
        INDEX
      </Text>
      <Input
        px={2}
        py={3}
        placeholder="Search"
        onChange={onChange}
        value={search}
        borderColor="secondary.200"
      />
      <Box overflowY="auto" height="40dvh">
        <TriggersAndProperties
          trigger={trigger}
          filters={filters}
          search={search}
          setSearchFound={setTriggerFound}
        />

        <ActionsIndex actions={actions} search={search} setSearchFound={setActionFound} />

        {!triggerFound && !actionFound && (
          <Text>
            No result found for <b>'{search}'</b>
          </Text>
        )}
      </Box>
    </VStack>
  );
};

export default WorkflowIndex;
