import { exception, UsersService } from 'core';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { useToast } from 'library/atoms';
import moment from 'moment';
import { ReminderType } from './table.types';
import { useState } from 'react';

export type LocalStorageTimer = {
  userId: string;
  activeTimer: number;
};

export function useReminders() {
  const _usersService = new UsersService();
  const toast = useToast();
  const currentTime = moment();
  const [sendReminderInProgress, setSendReminderInProgress] = useState<boolean>(false);

  const minuteValidator = (timeStamp: number) => {
    const difference = currentTime.diff(moment(timeStamp), 'seconds');
    return Math.abs(difference) <= 59;
  };

  const getTimers = () => {
    const activeReminders = localStorage.getItem('@activeReminders');

    const timers: LocalStorageTimer[] = activeReminders?.length ? JSON.parse(activeReminders) : [];
    let activeTimer: LocalStorageTimer[] = [];
    let expiredTimer: LocalStorageTimer[] = [];
    if (activeReminders?.length) {
      activeTimer = timers.filter((val: ReminderType) => {
        return minuteValidator(val.activeTimer);
      });
      expiredTimer = timers.filter((val: ReminderType) => {
        return !minuteValidator(val.activeTimer);
      });
    }
    return [activeTimer, expiredTimer];
  };

  const updateStorage = () => {
    const [activeTimer, expiredTimer] = getTimers();
    localStorage.setItem('@activeReminders', JSON.stringify(activeTimer));
  };

  const getRemiderTimer = (userId: string) => {
    const activeReminders = localStorage.getItem('@activeReminders');
    let specificReminder = undefined;
    if (activeReminders?.length) {
      specificReminder = JSON.parse(activeReminders)?.find((value: ReminderType) => {
        if (value.userId === userId && minuteValidator(value.activeTimer)) return value;
      });
    }
    return specificReminder;
  };
  const setReminderValeus = async (user: IUsersOfOrganization) => {
    try {
      setSendReminderInProgress(true);
      await _usersService.sendVerificationReminderMail(user.id);
      const msg = `Verification request has been successfully sent to the user "${user.first_name} ${user.last_name}"`;
      let [activeTimers] = getTimers();
      activeTimers = activeTimers.filter(timer => timer.userId !== user.id);

      const updatesValues = [
        ...activeTimers,
        {
          userId: user.id,
          activeTimer: Date.now(),
        },
      ];

      localStorage.setItem('@activeReminders', JSON.stringify(updatesValues));

      toast({
        status: 'success',
        text: msg,
      });
      return true;
    } catch (err: any) {
      toast({
        status: 'error',
        text: 'There was an error while sending the reminder. Please try again',
      });
      exception.handle('E_SEND_VERIFICATION_MAIL_USER', err);
      return false;
    } finally {
      setSendReminderInProgress(false);
    }
  };

  return { getRemiderTimer, setReminderValeus, updateStorage, sendReminderInProgress };
}
