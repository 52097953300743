import { Para, Theme } from 'uie/components';
import { IRunbookAndTaskActivity } from 'core/interfaces/IIncidents';
import { RUNBOOK, TASK } from '../../../tasksAndRunbook/taskAndRunbook.constants';
import { getAddOrUpdateEventsData } from '../../../tasksAndRunbook/taskOrRunbook.helper';
import { IGeneralMessages } from '../GeneralMessages';

const TaskAndRunbookMessage = ({
  log,
  entityComponent,
  logMessageActivity,
  setFocusMessageId,
}: IGeneralMessages) => {
  const { theme } = Theme;

  return (
    <Para fontSize={14} color={theme.shades.cement} style={{ marginBottom: 2 }}>
      <div className="mb-5">
        {entityComponent}
        {logMessageActivity}
      </div>

      {getAddOrUpdateEventsData(log)
        .filter(Boolean)
        .map(({ txt, isRunbook, focusId, index }: IRunbookAndTaskActivity, index_) => {
          return (
            <Para
              key={index_}
              fontSize={12}
              color={theme.primary.default}
              style={{ marginBottom: 2, cursor: 'pointer' }}
              onClick={() => {
                setFocusMessageId &&
                  (isRunbook
                    ? setFocusMessageId(focusId, RUNBOOK, index)
                    : setFocusMessageId(focusId, TASK, index));
              }}
            >
              {txt}
            </Para>
          );
        })}
    </Para>
  );
};

export default TaskAndRunbookMessage;
