import { Box, Text } from '@chakra-ui/react';
import {
  T_WA_UP_GER_CATCH_ALL_RULE_CREATED,
  T_WA_UP_GER_CATCH_ALL_RULE_DELETED,
  T_WA_UP_GER_CATCH_ALL_RULE_EDITED,
} from 'core/const/tracker';
import { Dropdown, FormButton, useToast } from 'library/atoms';
import { Modal } from 'library/molecules';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { AppTracker } from 'shared/analytics/tracker';

import { AppContext } from '../..';
import { updateGerRuleset } from '../../query/useUpdateGerRuleset';
import { ALERT_API_INFO, DROPDOWN_OPTIONS } from '../../types';
import { CATCH_ALL_MODE, getServiceOptions } from '../../util';

type Props = {
  gerId: string;
  routeId?: string;
  alert: ALERT_API_INFO;
  isOpen: boolean;
  mode: CATCH_ALL_MODE;
  onClose: () => void;
  onSuccess: () => void;
};

export const CatchAllRule: FC<Props> = ({
  gerId,
  routeId,
  alert,
  isOpen,
  mode,
  onSuccess,
  onClose,
}) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(AppContext);

  const [service, setService] = useState<DROPDOWN_OPTIONS | null>();
  const services: DROPDOWN_OPTIONS[] = useMemo(
    () => getServiceOptions(context?.organization),
    [context?.organization],
  );

  useEffect(() => {
    resetServiceOption();
  }, [services, routeId]);

  const resetServiceOption = () => {
    if (services.length && !!routeId) {
      setService(services.find(opt => opt.value === routeId) ?? null);
    } else {
      setService(null);
    }
  };

  const title = useMemo(
    () => (mode === CATCH_ALL_MODE.ADD ? 'Add' : mode === CATCH_ALL_MODE.EDIT ? 'Edit' : 'Remove'),
    [mode],
  );

  const isDeleteOp = useMemo(() => mode === CATCH_ALL_MODE.DELETE, [mode]);

  const onUpdate = () => {
    setIsLoading(true);
    updateGerRuleset(gerId, alert.version, alert.shortName, isDeleteOp ? '' : service?.value)
      .then(() => {
        onAPISuccess();
      })
      .catch(error => {
        onError(error);
      });
  };

  const onCancel = () => {
    resetServiceOption();
    onClose();
  };

  const onAPISuccess = () => {
    setIsLoading(false);

    const op = isDeleteOp ? 'removed' : mode === CATCH_ALL_MODE.ADD ? 'added' : 'updated';
    const eventName = isDeleteOp
      ? T_WA_UP_GER_CATCH_ALL_RULE_DELETED
      : mode === CATCH_ALL_MODE.ADD
      ? T_WA_UP_GER_CATCH_ALL_RULE_CREATED
      : T_WA_UP_GER_CATCH_ALL_RULE_EDITED;

    onSuccess();
    AppTracker.track(eventName, {
      'GER Ruleset ID': gerId,
      'Alert Source Name': alert.shortName,
    });
    toast({
      status: 'success',
      text: `Success: Catch all rule ${op} successfully`,
    });
  };

  const onError = (err: any) => {
    setIsLoading(false);
    const errMsg =
      err?.response?.data?.meta.error_message ?? `Unable to ${title.toLowerCase()} catch all rule`;

    toast({
      status: 'error',
      text: `Error: ${errMsg}`,
    });
    console.error(err);
  };

  return (
    <Modal
      key="catch-all-rule"
      body={
        <>
          <Text variant="formInput" color="secondary.700">
            {isDeleteOp
              ? 'Are you sure you want to remove Catch All Rule?'
              : 'Reroute alert source to an alternate target when conditions aren’t met'}
          </Text>
          {!isDeleteOp && (
            <Box pt={4} gap={2} alignItems="flex-start" width="full">
              <Text variant="formInput_800" color="secondary.700">
                Select Service
              </Text>
              <Dropdown
                options={services}
                value={service}
                onChange={val => {
                  setService(val as DROPDOWN_OPTIONS);
                }}
                placeholder="Select Service"
                isClearable={false}
                controlShouldRenderValue
              />
            </Box>
          )}
        </>
      }
      isOpen={isOpen}
      onClose={onCancel}
      primaryButton={
        <FormButton
          title={isDeleteOp ? 'Remove' : 'Save'}
          isDisabled={isDeleteOp ? false : !service?.value}
          onClick={onUpdate}
          isLoading={isLoading}
          variant={isDeleteOp ? 'danger' : 'primary'}
        />
      }
      secondaryButton={
        <FormButton title="Cancel" onClick={onCancel} disabled={isLoading} variant="secondary" />
      }
      title={`${title} Catch All Rule`}
    />
  );
};
