import { PageHeader } from 'library/molecules';
import { ChakraProvider, Text } from '@chakra-ui/react';
import React from 'react';
import AuditLogsTabGroup from './components/auditLogsTabGroup';
import { AuditLogsProvider } from './context/auditLogsContext';
import libraryTheme from 'library/theme';

function OrganizationAuditLogsTab() {
  return (
    <ChakraProvider theme={libraryTheme}>
      <PageHeader
        title="Audit Logs"
        noButton
        desc={
          <Text variant="body" color="secondary.1000">
            Detailed records of user activities and configuration changes
          </Text>
        }
      />
      <AuditLogsProvider>
        <AuditLogsTabGroup />
      </AuditLogsProvider>
    </ChakraProvider>
  );
}

export default OrganizationAuditLogsTab;
