import React from 'react';
import { useAnalyticsContext } from '../analyticsContext';
import CalendarRangePicker from '../../../../../components/CalendarRangePicker';

const TimeRange: React.FC = () => {
  const {
    config: { timeRange },
    updateConfig,
  } = useAnalyticsContext();

  const onUpdateRange = (startDate: Date, endDate: Date) => {
    updateConfig({
      timeRange: {
        gt: startDate,
        lt: endDate,
      },
    });
  };

  return (
    <CalendarRangePicker
      startDate={timeRange.gt}
      endDate={timeRange.lt}
      onUpdateRange={onUpdateRange}
      minDate={new Date(new Date().setMonth(new Date().getMonth() - 3))}
      maxDate={new Date()}
    />
  );
};

export default TimeRange;
