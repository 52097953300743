import * as React from 'react';
import { SVGProps } from 'react';

const SvgJiraPlugin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="jira-plugin_svg__Logos"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.jira-plugin_svg__st2{fill:url(#jira-plugin_svg__SVGID_00000158712213953830834040000017700137895757743241_)}'
      }
    </style>
    <path
      d="M19.5 9.4 10.9.8 10 0 3.5 6.5l-3 3c-.3.3-.3.8 0 1.1l5.9 5.9L10 20l6.5-6.5.1-.1 2.9-2.8c.2-.4.2-.8 0-1.2zM10 13l-3-3 3-3 3 3-3 3z"
      style={{
        fill: '#2684ff',
      }}
    />
    <linearGradient
      id="jira-plugin_svg__SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={9.485}
      y1={-672.048}
      x2={5.355}
      y2={-676.175}
      gradientTransform="matrix(1 0 0 -1 0 -668)"
    >
      <stop
        offset={0.18}
        style={{
          stopColor: '#0052cc',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#2684ff',
        }}
      />
    </linearGradient>
    <path
      d="M10 7c-1.9-1.9-2-5.1 0-7L3.5 6.5 7.1 10 10 7z"
      style={{
        fill: 'url(#jira-plugin_svg__SVGID_1_)',
      }}
    />
    <linearGradient
      id="jira-plugin_svg__SVGID_00000164512829865677457730000005342040849069876889_"
      gradientUnits="userSpaceOnUse"
      x1={10.581}
      y1={-683.907}
      x2={14.699}
      y2={-679.788}
      gradientTransform="matrix(1 0 0 -1 0 -668)"
    >
      <stop
        offset={0.18}
        style={{
          stopColor: '#0052cc',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#2684ff',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#jira-plugin_svg__SVGID_00000164512829865677457730000005342040849069876889_)',
      }}
      d="m13 10-3 3c2 2 2 5.1 0 7l6.5-6.5L13 10z"
    />
  </svg>
);

export default SvgJiraPlugin;
