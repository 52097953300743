// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const windowEnv = window._sq_env ?? {};

export const AppConfig = {
  pusher: windowEnv.VITE_PUSHER_KEY ?? '',
  pusher_host: windowEnv.VITE_PUSHER_HOST ?? '',
  mixpanel: windowEnv.VITE_MIXPANEL_KEY ?? '',
  sentry: {
    dns: {
      web: windowEnv.VITE_SENTRY_KEY ?? '',
    },
  },
  log_rocket: windowEnv.VITE_LOGROCKET_KEY ?? '',
  intercom: windowEnv.VITE_INTERCOM_KEY ?? '',
  userflow: windowEnv.VITE_USERFLOW_KEY ?? '',
  onesignal: windowEnv.VITE_ONESIGNAL_KEY ?? '',

  github: windowEnv.VITE_GITHUB_KEY ?? '',
  slack: windowEnv.VITE_SLACK_KEY ?? '',
  slackv2: windowEnv.VITE_SLACK_KEY_V2 ?? '',
  ms_teams: windowEnv.VITE_MS_TEAMS_KEY ?? '',

  cube_url: windowEnv.VITE_CUBE_URL ?? 'http://localhost:4000',

  mixpanel_proxy_url: windowEnv.VITE_MIXPANEL_PROXY_URL ?? '',

  incidents_url: windowEnv.VITE_INCIDENTS_URL ?? 'incidents.squadcast.com',
  status_page_url: windowEnv.VITE_STATUS_PAGE_URL ?? 'status.squadcast.io',
  status_page_host: windowEnv.VITE_STATUS_PAGE_HOST ?? 'https://statuspage.squadcast.com',
  ger_url: windowEnv.VITE_GER_URL ?? 'global-events.squadcast.com',

  sso_auth_url: windowEnv.VITE_SSO_AUTH_URL ?? 'https://auth.squadcast',
  EU_sso_auth_url: windowEnv.VITE_EU_SSO_AUTH_URL ?? 'https://auth.eu.squadcast',

  billing_url: windowEnv.VITE_BILLING_URL ?? 'https://squadcast.chargebeeportal.com/',
  ms_teams_bot_reauthorization_url: windowEnv.VITE_MS_TEAMS_BOT_REAUTHORIZATION_URL ?? '',

  platform_backend_url: windowEnv.VITE_PLATFORM_BACKEND_URL ?? 'https://platform-backend.squadcast',
  EU_platform_backend_url:
    windowEnv.VITE_EU_PLATFORM_BACKEND_URL ?? 'https://platform-backend.eu.squadcast',
  internal_api_url: windowEnv.VITE_INTERNAL_API_URL ?? 'https://internal-api.squadcast',
  EU_internal_api_url:
    windowEnv.VITE_EU_PLATFORM_BACKEND_URL ?? 'https://internal-api.eu.squadcast',
  api_url: windowEnv.VITE_API_URL ?? 'https://api.squadcast',
  EU_api_url: windowEnv.VITE_API_URL ?? 'https://api.eu.squadcast',
  app_url: windowEnv.VITE_URL ?? 'https://app.squadcast',
  eu_app_url: windowEnv.VITE_EU_URL ?? 'https://app.eu.squadcast.com',
  us_app_url: windowEnv.VITE_US_URL ?? 'https://app.squadcast.com',
  webforms_url: windowEnv.VITE_WEBFORM_URL ?? 'webforms.squadcast.com',
  webforms_cname: windowEnv.VITE_WEBFORM_CNAME ?? 'webforms.prod.squadcast.io',
};
