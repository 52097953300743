import { createStandaloneToast } from '@chakra-ui/react';

export const useChakraToast = () => {
  const toast = createStandaloneToast();

  const showErrorToast = (message: string) =>
    toast({
      isClosable: true,
      duration: 5000,
      title: message,
      status: 'error',
    });

  const showSuccessToast = (message: string) =>
    toast({
      isClosable: true,
      duration: 5000,
      title: message,
      status: 'success',
    });
  return [showErrorToast, showSuccessToast];
};
