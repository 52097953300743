export const REQUEST_USER_ORG = 'REQUEST_USER_ORG';
export const REQUEST_USER_ORG_SUCCESS = 'REQUEST_USER_ORG_SUCCESS';
export const REQUEST_USER_ORG_FAIL = 'REQUEST_USER_ORG_FAIL';

export const REQUEST_ORG_USERS = 'REQUEST_ORG_USERS';
export const REQUEST_ORG_USERS_SUCCESS = 'REQUEST_ORG_USERS_SUCCESS';
export const REQUEST_ORG_USERS_FAIL = 'REQUEST_ORG_USERS_FAIL';

export const REQUEST_ORG_CURRENT_USER = 'REQUEST_ORG_CURRENT_USER';
export const REQUEST_ORG_CURRENT_USER_SUCCESS = 'REQUEST_ORG_CURRENT_USER_SUCCESS';
export const REQUEST_ORG_CURRENT_USER_FAIL = 'REQUEST_ORG_CURRENT_USER_FAIL';

export const REQUEST_ORG_SQUADS = 'REQUEST_ORG_SQUADS';
export const REQUEST_ORG_SQUADS_SUCCESS = 'REQUEST_ORG_SQUADS_SUCCESS';
export const REQUEST_ORG_SQUADS_FAIL = 'REQUEST_ORG_SQUADS_FAIL';

export const REQUEST_ORG_STAKEHOLDERS = 'REQUEST_ORG_STAKEHOLDERS';
export const REQUEST_ORG_STAKEHOLDERS_SUCCESS = 'REQUEST_ORG_STAKEHOLDERS_SUCCESS';
export const REQUEST_ORG_STAKEHOLDERS_FAIL = 'REQUEST_ORG_STAKEHOLDERS_FAIL';

export const REQUEST_ORG_ESCALATION = 'REQUEST_ORG_ESCALATION';
export const REQUEST_ORG_ESCALATION_SUCCESS = 'REQUEST_ORG_ESCALATION_SUCCESS';
export const REQUEST_ORG_ESCALATION_FAIL = 'REQUEST_ORG_ESCALATION_FAIL';

export const REQUEST_ORG_SERVICES = 'REQUEST_ORG_SERVICES';
export const REQUEST_ORG_SERVICES_SUCCESS = 'REQUEST_ORG_SERVICES_SUCCESS';
export const REQUEST_ORG_SERVICES_FAIL = 'REQUEST_ORG_SERVICES_FAIL';

export const REQUEST_ORG_CALENDAR = 'REQUEST_ORG_CALENDAR';
export const REQUEST_ORG_CALENDAR_SUCCESS = 'REQUEST_ORG_CALENDAR_SUCCESS';
export const REQUEST_ORG_CALENDAR_CONSUMED = 'REQUEST_ORG_CALENDAR_CONSUMED';
export const REQUEST_ORG_CALENDAR_FAIL = 'REQUEST_ORG_CALENDAR_FAIL';
export const REQUEST_ORG_CALENDAR_ON_CALL = 'REQUEST_ORG_CALENDAR_ON_CALL';
export const REQUEST_ORG_CALENDAR_ON_CALL_SUCCESS = 'REQUEST_ORG_CALENDAR_ON_CALL_SUCCESS';
export const REQUEST_ORG_CALENDAR_ON_CALL_FAIL = 'REQUEST_ORG_CALENDAR_ON_CALL_FAIL';

export const REQUEST_ORG_PLAN = 'REQUEST_ORG_PLAN';
export const REQUEST_ORG_PLAN_SUCCESS = 'REQUEST_ORG_PLAN_SUCCESS';
export const REQUEST_ORG_PLAN_FAIL = 'REQUEST_ORG_PLAN_FAIL';

export const REQUEST_ORG_INTEGRATIONS = 'REQUEST_ORG_INTEGRATIONS';
export const REQUEST_ORG_INTEGRATIONS_SUCCESS = 'REQUEST_ORG_INTEGRATIONS_SUCCESS';
export const REQUEST_ORG_INTEGRATIONS_FAIL = 'REQUEST_ORG_INTEGRATIONS_FAIL';

export const REQUEST_ORG_TEAMS = 'REQUEST_ORG_TEAMS';
export const REQUEST_ORG_TEAMS_SUCCESS = 'REQUEST_ORG_TEAMS_SUCCESS';
export const REQUEST_ORG_TEAMS_FAIL = 'REQUEST_ORG_TEAMS_FAIL';
export const REQUEST_ORG_RUNBOOK = 'REQUEST_ORG_RUNBOOK';
export const REQUEST_ORG_RUNBOOK_SUCCESS = 'REQUEST_ORG_RUNBOOK_SUCCESS';
export const REQUEST_ORG_RUNBOOK_FAIL = 'REQUEST_ORG_RUNBOOK_FAIL';

export const REQUEST_INCIDENT_DETAIL_RUNBOOK = 'REQUEST_INCIDENT_DETAIL_RUNBOOK';
export const REQUEST_INCIDENT_DETAIL_RUNBOOK_SUCCESS = 'REQUEST_INCIDENT_DETAIL_RUNBOOK_SUCCESS';
export const REQUEST_INCIDENT_DETAIL_RUNBOOK_FAIL = 'REQUEST_INCIDENT_DETAIL_RUNBOOK_FAIL';

export const REQUEST_INCIDENT_DETAIL_TASK = 'REQUEST_INCIDENT_DETAIL_TASK';
export const REQUEST_INCIDENT_DETAIL_TASK_SUCCESS = 'REQUEST_INCIDENT_DETAIL_TASK_SUCCESS';
export const REQUEST_INCIDENT_DETAIL_TASK_FAIL = 'REQUEST_INCIDENT_DETAIL_TASK_FAIL';
