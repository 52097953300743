import { useDisclosure } from '@chakra-ui/react';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService, exception } from 'core';
import {
  T_WA_UP_INCIDENT_LIST_PAGE_V2_MERGE_PARENT_INCIDENTS,
  T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_UNSTARRED,
  T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_STARRED,
} from 'core/const/tracker';
import { IAppState } from 'core/interfaces/IAppState';
import { useToast } from 'library/atoms';
import { Table } from 'library/molecules';
import { PLS } from 'library/molecules/Table/types';
import { AnyObject } from 'library/types';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Cell, Column } from 'react-table';
import { AppTracker } from 'shared/analytics/tracker';

import { INCIDENT_ACTION_ID, INCIDENT_STATUS } from '../common/enums';
import { INCIDENT_DATA, IncidentListProps, SelectedIncident } from '../common/types';
import { clearIncidentObj } from '../create/temp-store';
import { DownloadIncidents } from '../download';
import { useStarIncidentMutation } from '../graphql/mutation';
import { Reassign } from '../reassign';
import { ResolveIncident } from '../resolve';
import { ActionKind, IncidentListContext } from '../store';
import {
  getILPageLimit,
  setILPageLimit,
  setILTagsPreference,
  showExpandedTags,
} from '../store/persistent-storage';
import { UpdateTags } from '../tags';
import { INCIDENT_LIST_COLUMN_DEFS } from './column-defs';
import { MergeIncident } from './MergeIncident';
import { useSession } from '../behaviors/Session';
import useBulkActions from '../hooks/useIncidentBulkActions';
import InlineActionButtons from './InlineActionButtons';
import HeaderCheckbox from './HeaderCheckbox';
import CellCheckbox from './CellCheckbox';
import ListToolbar from './ListToolbar';

export const IncidentList = memo((props: IncidentListProps) => {
  const {
    rows,
    status,
    selectedRowIds,
    setSelectedRowIds,
    isLoadingIncidents,
    cursorMeta,
    onPageChange,
    count,
    isCountFetching,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { state, dispatch } = useContext(IncidentListContext);
  const [tagsExpanded, setTagsExpanded] = useState(showExpandedTags());
  const [selectedIncident, setSelectedIncident] = useState<SelectedIncident>({
    id: '',
    name: '',
  });
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const {
    commonStatus,
    inActionBtnId,
    setInActionBtnId,
    bulkAckStatus,
    setCanAutoRefresh,
    mergeEligibility,
    mergeTooltipText,
    onBulkUpdatePriority,
    showBulkActionButtons,
    onSave,
    onError,
    updateCommonStatus,
    incidentsForMerge,
    parentIncidentIDs,
    invalListingData,
    updateListingData,
  } = useBulkActions(props);

  const {
    isOpen: isOpenMergeIncident,
    onOpen: onOpenMergeIncident,
    onClose: onCloseMergeIncident,
  } = useDisclosure();
  const {
    session: { orgState: organization },
  } = useSession();

  const isDisabled = inActionBtnId !== '';

  useEffect(() => {
    setCanAutoRefresh(!isOpenMergeIncident);
  }, [isOpenMergeIncident]);

  const setTagsExpandedPreference = (isExpanded: boolean) => {
    setTagsExpanded(isExpanded);
    setILTagsPreference(isExpanded);
  };

  const resetPageAction = () => {
    if (
      [
        INCIDENT_ACTION_ID.BULK_REASSIGN,
        INCIDENT_ACTION_ID.INLINE_REASSIGN,
        INCIDENT_ACTION_ID.INLINE_UPDATE_TAGS,
      ].includes(inActionBtnId)
    ) {
      setCanAutoRefresh(true);
    }
    setInActionBtnId(INCIDENT_ACTION_ID.NONE);
  };

  const pageNavigate = (isAdvance: boolean) => {
    onPageChange((isAdvance ? cursorMeta.next : cursorMeta.prev) ?? '');
  };

  const pageChangeHandler = (_page: number, limit: number) => {
    const plsLimit: PLS = limit as PLS;
    if (getILPageLimit() !== limit) {
      setILPageLimit(plsLimit);
      dispatch({ type: ActionKind.RESET_CURSOR });
      setInActionBtnId(INCIDENT_ACTION_ID.NONE);
    }
  };

  const getSelectedAssignee = () => {
    let assignee;
    if (inActionBtnId === INCIDENT_ACTION_ID.BULK_REASSIGN && selectedRowIds.length === 1) {
      assignee = rows.find(row => row?.id === selectedRowIds[0])?.assignedTo;
    } else if (inActionBtnId === INCIDENT_ACTION_ID.INLINE_REASSIGN) {
      assignee = { id: selectedIncident.assigneeId, type: selectedIncident.assigneeType };
    }
    return assignee;
  };

  const { mutateAsync: starIncidentMutation, isLoading: isStarringIncident } =
    useStarIncidentMutation();
  const starIncident = (id: string, isStarred: boolean) => {
    const row = rows.find(row => row?.id === id);
    if (isStarringIncident || !row) {
      return;
    }
    // Optimistically updating UI state as otherwise there is some noticeable gap in starring/unstarring
    row.isStarred = !isStarred;
    updateListingData();

    starIncidentMutation({
      incidentID: id,
      starred: !isStarred,
    }).catch(error => {
      exception.handle('E_STAR_INCIDENT', error);
      toast({ status: 'error', text: 'Error: Unable to star Incident' });
      // revert data to previous state on error
      row.isStarred = isStarred;
      updateListingData();
    });

    const starEvent = isStarred
      ? T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_UNSTARRED
      : T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_STARRED;

    AppTracker.track(starEvent);
  };

  // hover view elements
  const IncidentAction = useCallback(({ rowData }: { rowData: Partial<Cell<INCIDENT_DATA>> }) => {
    const data: AnyObject = rowData?.row?.original ?? {};

    return (
      <InlineActionButtons
        {...{
          rows,
          setInActionBtnId,
          setSelectedIncident,
          onError,
          onSave,
          incidentData: data,
          isDisabled,
          inActionBtnId,
        }}
      />
    );
  }, []);

  // column definitions
  const columnDefs = useMemo<Column<any>[]>(
    () => [
      {
        id: 'select-incidents',
        Header: HeaderCheckbox({ rows, selectedRowIds, setSelectedRowIds, updateCommonStatus }),
        Cell: CellCheckbox({ selectedRowIds, setSelectedRowIds, updateCommonStatus, starIncident }),
      },
      ...([INCIDENT_STATUS.ALL, INCIDENT_STATUS.OPEN].includes(status)
        ? INCIDENT_LIST_COLUMN_DEFS
        : INCIDENT_LIST_COLUMN_DEFS.filter(col => col.Header != 'STATUS')),
    ],
    [selectedRowIds, rows, incidentsForMerge],
  );

  const isMergeIncidentsUnavailable = organization
    ? BillingService.isFeatureDisabled({ organization }, 'merge-incidents')
    : false;

  const onClickMergeIncidents = () => {
    if (isMergeIncidentsUnavailable) {
      setShowUpgradeModal(true);
    } else {
      onOpenMergeIncident();
    }
  };

  const incidentsForResolveAction =
    inActionBtnId === INCIDENT_ACTION_ID.INLINE_RESOLVE ? [selectedIncident.id] : selectedRowIds;

  const areParentIncidentsSelectedForResolve = incidentsForResolveAction.some(id =>
    parentIncidentIDs.includes(id),
  );

  const trackMergeParentsEvent = () => {
    AppTracker.track(T_WA_UP_INCIDENT_LIST_PAGE_V2_MERGE_PARENT_INCIDENTS);
  };

  const toolbarProps = {
    setCanAutoRefresh,
    onOpen,
    isDisabled,
    setTagsExpandedPreference,
    tagsExpanded,
    bulkAckStatus,
    commonStatus,
    inActionBtnId,
    isMergeIncidentsUnavailable,
    mergeEligibility,
    onClickMergeIncidents,
    onBulkUpdatePriority,
    trackMergeParentsEvent,
    setInActionBtnId,
    mergeTooltipText,
    showBulkActionButtons,
    count,
    isCountFetching,
    status,
  };

  return (
    <>
      <ListToolbar {...toolbarProps} />
      <Table
        hoverView={<IncidentAction rowData={{}} />}
        width="100%"
        columns={columnDefs}
        data={rows}
        onPageChange={pageChangeHandler}
        headerStyles={{ padding: '14px 24px 14px 24px' }}
        additionalTableStyles={{
          '& thead > tr > th:nth-child(7),& thead > tr > th:nth-child(8)': {
            paddingRight: '0px',
          },
        }}
        paginationStyles={{
          position: 'sticky',
          bottom: '0px',
        }}
        isLoading={isLoadingIncidents}
        pageSize={getILPageLimit()}
        cursorPagination
        hasPrev={cursorMeta.hasPrev}
        hasNext={cursorMeta.hasNext}
        onPrev={() => {
          pageNavigate(false);
        }}
        onNext={() => {
          pageNavigate(true);
        }}
        onSortChange={col => {
          if (state.sortBy !== col.id) {
            dispatch({ type: ActionKind.SET_SORT_BY, payload: { sortBy: col.id } });
          }
        }}
        canToggleColumnSort={false}
        sortedColumnId={state.sortBy}
      />
      <DownloadIncidents
        isOpen={isOpen}
        onClose={onClose}
        enableAutoRefresh={() => {
          setCanAutoRefresh(true);
        }}
        status={status}
      />
      {[INCIDENT_ACTION_ID.INLINE_RESOLVE, INCIDENT_ACTION_ID.BULK_RESOLVE].includes(
        inActionBtnId,
      ) && (
        <ResolveIncident
          incidentIds={incidentsForResolveAction}
          incidentName={selectedIncident.name}
          action={inActionBtnId}
          onSave={onSave}
          onClose={resetPageAction}
          containsParentIncidents={areParentIncidentsSelectedForResolve}
        />
      )}
      {[INCIDENT_ACTION_ID.INLINE_REASSIGN, INCIDENT_ACTION_ID.BULK_REASSIGN].includes(
        inActionBtnId,
      ) && (
        <Reassign
          incidentIds={
            inActionBtnId === INCIDENT_ACTION_ID.INLINE_REASSIGN
              ? [selectedIncident.id]
              : selectedRowIds
          }
          incidentName={selectedIncident.name}
          action={inActionBtnId}
          onSave={onSave}
          onClose={resetPageAction}
          assigneeId={getSelectedAssignee()?.id}
          assigneeType={getSelectedAssignee()?.type}
        />
      )}
      {inActionBtnId === INCIDENT_ACTION_ID.INLINE_UPDATE_TAGS && (
        <UpdateTags
          incidentIds={[selectedIncident.id]}
          incidentName={selectedIncident.name}
          action={inActionBtnId}
          onSave={onSave}
          onClose={resetPageAction}
          tags={rows.find(row => row?.id === selectedIncident.id)?.tags ?? []}
        />
      )}
      <MergeIncident
        incidents={incidentsForMerge}
        isOpen={isOpenMergeIncident}
        onClose={() => {
          onCloseMergeIncident();
          clearIncidentObj();
        }}
        setSelectedRowIds={setSelectedRowIds}
        invalListingData={invalListingData}
      />
      <UpgradePlanModal
        hasBillingPermission={BillingService.hasManageBillingPermission({ organization } as Pick<
          IAppState,
          'organization'
        >)}
        showModal={showUpgradeModal}
        message={BillingService.getMessage(0, 'merge-incidents', { organization } as Pick<
          IAppState,
          'organization'
        >)}
        onCancel={() => setShowUpgradeModal(false)}
        header={BillingService.getHeader(0, 'merge-incidents', { organization } as Pick<
          IAppState,
          'organization'
        >)}
        onUpgrade={() => setShowUpgradeModal(false)}
      />
    </>
  );
});
