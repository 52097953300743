import * as React from 'react';
import { SVGProps } from 'react';

const SvgFreshping = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="freshping_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.freshping_svg__st0{fill:#1f9999}'}</style>
    <path
      className="freshping_svg__st0"
      d="M10 20C4.4 20 0 15.5 0 10S4.4 0 10 0h7.5C18.8 0 20 1.1 20 2.5V10c0 5.5-4.5 10-10 10z"
    />
    <path
      d="M10 15.2c2.9 0 5.2-2.3 5.2-5.2 0-2.9-2.3-5.2-5.2-5.2-2.9 0-5.2 2.3-5.2 5.2 0 2.8 2.3 5.2 5.2 5.2z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      className="freshping_svg__st0"
      d="M9.2 13c-.2 0-.4-.1-.5-.2l-1.4-1.9H3.2c-.3 0-.6-.3-.6-.6s.3-.6.6-.6h4.4c.2 0 .4.1.5.3l.9 1.1 1.1-3.2c.1-.2.3-.4.5-.4s.5.1.6.2l1.5 1.9h4.4c.3 0 .6.3.6.6s-.3.6-.6.6h-4.8c-.2 0-.4-.1-.5-.2l-1-1.2-1.1 3.2c0 .2-.2.4-.5.4.1 0 0 0 0 0z"
    />
  </svg>
);

export default SvgFreshping;
