export enum CardType {
  video = 'video_conference',
  chatOps = 'chat_room',
  other = 'other',
  slack = 'slack',
  msTeamsMeetingLink = 'msteams_meeting_link',
}

export interface ICommunicationCard {
  id?: string;
  type: CardType;
  title?: string;
  url: string;
  channel_id?: string;
  deleted_at?: string;
  archived_at?: string;
}

export interface ICommunicationCardResponse {
  data: ICommunicationCard;
}

export interface ICommunicationCardsResponse {
  data: Array<ICommunicationCard>;
}

export interface ICommunicationCardPatch {
  title?: string;
  url?: string;
}
