import { Box, Flex, Text } from '@chakra-ui/layout';
import { PRIORITY_OPTIONS } from 'library/common';
import { PRIORITY_COLORS } from 'library/theme/colors';
import { FC } from 'preact/compat';
import { PriorityAction } from 'views/main/organization/workflow/types';

interface IProps {
  action: PriorityAction;
}
const PriorityCard: FC<IProps> = ({ action }) => {
  const priorityID = action.data.priority;
  const priorityLabel = PRIORITY_OPTIONS.find(
    priority => priority.value === action.data.priority,
  )?.label;
  const hasValue = !!priorityID && priorityID !== 'UNSET';

  return (
    <Flex pt={2} gap={2} alignItems="center">
      <Box
        w="30px"
        h="20px"
        backgroundColor={PRIORITY_COLORS(priorityID)}
        borderRadius="3px"
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <Text color={hasValue ? 'brand.white' : 'secondary.1000'} fontSize="sm" lineHeight="100%">
          {hasValue ? priorityID : '-'}
        </Text>
      </Box>{' '}
      {priorityLabel}
    </Flex>
  );
};

export default PriorityCard;
