import { Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

type Props = {
  title: ReactNode;
  actions?: ReactNode;
  padding?: number;
  pb?: number;
  size?: string;
  wrap?: boolean;
};

const DEFAULT_PADDING = 6;

function Header({ title, actions, padding, pb, wrap }: Props) {
  return (
    /**
    @todo
    Rework on props
     */
    <Flex
      justifyContent="space-between"
      alignItems="center"
      p={padding ?? DEFAULT_PADDING}
      pb={pb || DEFAULT_PADDING}
      wrap={wrap ? { sm: 'wrap', md: 'wrap', xl: 'nowrap' } : 'nowrap'}
      rowGap={5}
    >
      {title}
      {actions}
    </Flex>
  );
}

export default Header;
