import React from 'react';
import momentTimeZone from 'moment-timezone';
import { DateTime } from 'luxon';
import ReactSelect, { OnChangeValue } from 'react-select';
import { SelectOption } from 'components/SecondaryFilter/types';

interface IProps {
  timezone: string;
  handleChange: (tz: string) => void;
  hideDefaultTimezone?: boolean;
}

const SelectTimeZone: React.FC<IProps> = props => {
  const timezones = momentTimeZone.tz.names()?.map(timezone => ({
    label: `(${DateTime.local().setZone(timezone).toFormat('ZZ')}
    ${DateTime.local().setZone(timezone).toFormat('ZZZZ')})
    ${timezone.replace(/_/g, ' ')}`,
    value: timezone,
  }));

  const handleChange = (newValue: OnChangeValue<SelectOption, false>) => {
    props.handleChange(newValue?.value || '');
  };

  const defaultTimezone = props.hideDefaultTimezone ? '' : timezones[0];

  return (
    <ReactSelect
      options={timezones}
      placeholder="Select timezone"
      value={
        props.timezone != '' ? timezones.filter(tz => tz.value === props.timezone) : defaultTimezone
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={handleChange}
    />
  );
};

export default SelectTimeZone;
