import { Box } from '@chakra-ui/layout';
import { useMemo } from 'react';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { SLACK_ACTIONS } from '../../../constant';
import ActionItem from './ActionItem';
import IntegrateActionsUI from '../IntegrateActionsUI';
import { useCheckSlackStatus } from '../../../details/hooks/useCheckSlactStatus';
import { useWorkflowPlan } from '../../../store/useWorkflowPlan';

const SlackActionList = () => {
  const { workflowOrg } = useWorkflowPlan();

  const isSlackV2Enabled = workflowOrg.currentOrg.o?.config?.['slackv2'];
  const { data: slackIntegrated } = useCheckSlackStatus(!!isSlackV2Enabled);

  const { handleSubActionClick, actions, triggerType } = useWorkflowFormContext();
  const addedActionTypes = useMemo(() => actions.map(action => action.name), [actions]);

  const availableActions = useMemo(
    () =>
      SLACK_ACTIONS.filter(
        action => !action.disabledForTriggers?.includes(triggerType) && !action.disabled,
      ),
    [triggerType],
  );
  if (slackIntegrated !== 'integrated') {
    return <IntegrateActionsUI action={'Slack'} />;
  }
  return (
    <Box>
      {availableActions.map(action => (
        <ActionItem
          action={action}
          onClick={handleSubActionClick}
          key={action.name}
          isAdded={addedActionTypes.includes(action.name)}
        />
      ))}
    </Box>
  );
};

export default SlackActionList;
