import { IconButton } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Flex, VStack } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Modal } from 'library/molecules';
import { ErrorIcon, MoreVerticalIcon } from 'icons';
import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { LCR_PATH } from 'views/main/routes/routes';
import { getLCRDetailsPath, getLCREditPath } from '../../../common/utils';
import { Breadcrumb, FormButton as FormButtonAtoms, useToast } from 'library/atoms';
import { Badge } from '@chakra-ui/react';
import { ILCRData } from '../../../common/interface';
import { useDeleteLCR } from '../../../hooks/lcrDelete';
import { useQueryClient } from 'react-query';
import { useUpdateLCRStatus } from '../../../hooks/lcrUpdate';
import { API } from 'core/api';
import { shallowEqual, useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { Text } from 'library/atoms/Text';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

interface IProps {
  data: ILCRData;
}
const LCRDetailsHeader = ({ data }: IProps) => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const dialogAction = useDisclosure();
  const enableAction = useDisclosure();
  const deleteLCRList = useDeleteLCR();
  const queryClient = useQueryClient();
  const toast = useToast();
  const lcrStatusUpdate = useUpdateLCRStatus();

  const teamId = API.config.teamId;
  const organization = useSelector((state: IAppState) => state.organization, shallowEqual);
  const team = organization.teams.t.find(t => t.id === teamId);
  const userAccess = useUserAccess();

  const hasEditAccess = userAccess.hasUpdateAccess('routing_numbers', params.id);
  const hasDeleteAccess = userAccess.hasDeleteAccess('routing_numbers', params.id);

  const [deleteLCR, setDeleteLCR] = useState<{ id: string; name: string } | null>(null);

  const onHandleEdit = (id: string) => {
    history.push(getLCREditPath(id));
  };

  const onHandleDelete = (id: string, name: string) => {
    dialogAction.onOpen();
    setDeleteLCR({ id, name });
  };

  const onDeleteLCR = async () => {
    try {
      await deleteLCRList.mutateAsync(deleteLCR?.id ?? '');
      dialogAction.onClose();
      queryClient.invalidateQueries('lcr-fetch');
    } catch (error) {
      console.error('Error deleting LCR data:', error);
      dialogAction.onClose();
    }
  };

  const onEnableLCR = async (enabled: boolean) => {
    try {
      const response = await lcrStatusUpdate.mutateAsync({
        id: params.id,
        enabled: enabled,
        source: '• LCR Details Page ',
      });
      const text = `Incident reporting is successfully ${
        response?.enabled ? 'enabled' : 'disabled'
      }!`;
      enableAction.onClose();
      queryClient.invalidateQueries(`lcr-details-${params.id}`);
      queryClient.refetchQueries('lcr-fetch');

      toast({ text: text, status: 'success' });
    } catch (error) {
      console.error('failed');
    }
  };
  return (
    <VStack bg={'container.background'} align="stretch" px="4" py="6">
      <Breadcrumb
        data={[
          {
            link: LCR_PATH,
            text: 'Live Call Routing',
          },
          { link: getLCRDetailsPath(params.id), text: data.name },
        ]}
      />
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="h1">{data?.name}</Text>
        <Menu>
          <MenuButton
            background="#F3F9FF"
            as={IconButton}
            aria-label="Options"
            icon={
              <div>
                <MoreVerticalIcon />
              </div>
            }
            variant="secondary"
            p={2}
          />
          <MenuList>
            <NoPermissionTooltip isDisabled={hasEditAccess}>
              <MenuItem onClick={() => onHandleEdit(params.id)} isDisabled={!hasEditAccess}>
                Edit
              </MenuItem>
            </NoPermissionTooltip>
            <NoPermissionTooltip isDisabled={hasDeleteAccess}>
              <MenuItem
                onClick={() => onHandleDelete(params.id, data.name)}
                isDisabled={!hasDeleteAccess}
              >
                Delete
              </MenuItem>
            </NoPermissionTooltip>
            <NoPermissionTooltip isDisabled={hasEditAccess}>
              <MenuItem isDisabled={!hasEditAccess} onClick={enableAction.onOpen}>
                {data.enabled ? 'Disable' : 'Enable'}
              </MenuItem>
            </NoPermissionTooltip>
          </MenuList>
        </Menu>
      </Flex>
      <Text>{data.description}</Text>
      {!data.enabled && (
        <Badge
          color="#E05000"
          background="#FFF6BF"
          w="fit-content"
          p={1}
          display="flex"
          gap={1}
          alignItems="center"
        >
          {' '}
          <ErrorIcon />
          <Text> LCR is disabled for this number </Text>
        </Badge>
      )}

      <Modal
        isOpen={enableAction.isOpen}
        onClose={enableAction.onClose}
        title={`Do you want to ${data.enabled ? 'disable' : 'enable'} this routing number ?`}
        body={
          <Text variant="modal" color="secondary.700">
            {`${
              data.enabled ? 'Disabling' : 'Enabling'
            } a Routing Number will remove all the configured actions and relevant data. This action cannot be undone. Are you sure you want to proceed?`}
          </Text>
        }
        primaryButton={
          <FormButtonAtoms
            variant="danger"
            isLoading={lcrStatusUpdate.isLoading}
            onClick={() => onEnableLCR(!data.enabled)}
            title={`Yes, ${data.enabled ? 'Disable' : 'Enable'}`}
          />
        }
        secondaryButton={
          <FormButtonAtoms variant="secondary" onClick={enableAction.onClose} title="Cancel" />
        }
      />

      {deleteLCR && (
        <Modal
          isOpen={dialogAction.isOpen}
          onClose={dialogAction.onClose}
          title={`Do you want to delete this routing number ?`}
          body={
            <Text variant="modal" color="secondary.700">
              Deleting a LCR will remove all the configured actions and relevant data. This action
              cannot be undone. Are you sure you want to proceed?
            </Text>
          }
          primaryButton={
            <FormButtonAtoms
              variant="danger"
              isLoading={deleteLCRList.isLoading}
              onClick={() => onDeleteLCR()}
              title="Yes, Delete"
            />
          }
          secondaryButton={
            <FormButtonAtoms variant="secondary" onClick={dialogAction.onClose} title="Cancel" />
          }
        />
      )}
    </VStack>
  );
};

export default LCRDetailsHeader;
