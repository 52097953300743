import React from 'react';
import { Grid, Para } from 'uie/components';
import { IAnalyticsPanelRow } from '../../../../../core/interfaces/IAnalytics';
import { useAnalyticsContext } from '../analyticsContext';

interface IProps {
  row: IAnalyticsPanelRow;
}

const PanelRow: React.FC<IProps> = ({ row }) => {
  return (
    <Grid alignItems="center">
      {row.isDateConnected ? (
        <DatePanelRow row={row} />
      ) : (
        <Para fontSize={24} fontWeight={500}>
          {row.name}
        </Para>
      )}
    </Grid>
  );
};

const DatePanelRow: React.FC<IProps> = ({ row }) => {
  const {
    config: { selectedDate },
  } = useAnalyticsContext();

  return (
    <>
      <Para fontSize={24} fontWeight={500}>
        {row.name}
      </Para>
      <Para style={{ marginLeft: 20 }}>[{selectedDate.toDateString().replace(/^\S+\s/, '')}]</Para>
    </>
  );
};

export default PanelRow;
