import { Text, VStack } from '@chakra-ui/layout';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { LCREmptyUIIcon } from 'icons';
import { Tooltip } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { LCR_CREATE_PATH } from 'views/main/routes/routes';
import { FormButton } from 'library/atoms';

export const EmptyList = () => {
  const userAccess = useUserAccess();
  const hasLCRCreateAccess = userAccess.hasCreateAccess('routing_numbers');

  const history = useHistory();
  return (
    <VStack justifyContent={'center'} alignItems={'center'} h="100vh">
      <LCREmptyUIIcon height={300} width={300} />

      <Text textAlign="center" variant={'h1'} as={'h1'} mt={2} mb={2}>
        No Live Call Routing Number
      </Text>
      <Tooltip
        label="You don't have permission to take this action"
        isDisabled={hasLCRCreateAccess}
        shouldWrapChildren
      >
        <FormButton
          isDisabled={!hasLCRCreateAccess}
          variant="create"
          w={'inherit'}
          onClick={() => history.push(LCR_CREATE_PATH)}
          title={'Add New Routing Number'}
        />
      </Tooltip>
    </VStack>
  );
};

export default EmptyList;
