import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const PaginationTheme: ComponentStyleConfig = {
  baseStyle: props => {
    const { loading, disabled, canNextPage, canPreviousPage } = props;

    return {
      container: {
        width: '100%',
        opacity: loading ? 0.3 : 1,
        bg: 'brand.white',
        py: '0.5rem',
        pl: '1.5rem',
        pr: '3.5rem',
        justifyContent: 'space-between',
        alignItems: 'center',
        mr: '1rem',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'secondary.200',
      },
      sizeContainer: {
        width: '5rem',
      },
      navigationContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
          ':first-of-type': {
            color: !disabled || canPreviousPage ? 'brand.blue' : 'secondary.1000',
          },
          ':last-of-type': {
            color: !disabled || canNextPage ? 'brand.blue' : 'secondary.1000',
          },
        },
      },
      icon: {
        width: '1.5rem',
        height: '1.5rem',

        '&[disabled]': {
          opacity: '.5',
        },
      },
    };
  },
};
