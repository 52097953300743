import { Locale } from 'core/helpers/dateUtils';
import React, { useState } from 'react';

import {
  Box,
  Divider,
  Flex,
  HStack,
  Spinner,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';

import IllustrationBox from '../../../common/IllustrationBox';
import StackLimit, { IconType } from '../../../components/StackLimit';
import { Pagination } from '../../../components/Table';
import { IIncident } from '../../../hooks/useExpandedView';
import IncidentIllustration from '../../../icons/Illustrations/RecentIncidents.png';
import { PlaceholderText } from '../../../common';

type Props = {
  incidents: (IIncident & { alert_source_short_name: string; alert_source_type: string })[];
  incidentsLoading: boolean;
};

function ServiceIncidents({ incidents, incidentsLoading }: Props) {
  const [pageParams, setPageParams] = useState({ size: 5, page: 1 });

  return (
    <Flex direction="column" flex={1} rowGap={2.5}>
      {incidentsLoading && (
        <Spinner speed="0.65s" emptyColor="gray.200" color="blue.500" size="lg" mt={2} />
      )}
      {!incidentsLoading &&
        (incidents.length ? (
          incidents
            .slice((pageParams.page - 1) * pageParams.size, pageParams.page - 1 + pageParams.size)
            .map(incident => (
              <VStack alignItems="flex-start" key={incident.id}>
                <HStack gap={1.5}>
                  <Box w={12} sx={{ '& svg': { width: '100%', height: '100%' } }}>
                    <StackLimit
                      limit={2}
                      type="icon"
                      data={[
                        {
                          icon: incident.alert_source_short_name as IconType,
                          name: incident.alert_source_type,
                        },
                      ]}
                    />
                  </Box>

                  <VStack alignItems="flex-start">
                    <Text fontSize={16} fontWeight={400}>
                      {incident.message}
                    </Text>

                    <Flex color="blackAlpha.600">
                      <Text
                        fontSize={12}
                        fontWeight={400}
                        casing="capitalize"
                        display="inline-block"
                        mr={1}
                      >
                        {incident.status} | Created:
                      </Text>

                      <Text fontSize={12} fontWeight={400}>
                        <Tooltip
                          hasArrow
                          placement="top"
                          bgColor="gray.100"
                          color="gray.700"
                          fontSize={12}
                          fontWeight={400}
                          label={Locale.toShortDateTimeWithOffset(incident.timeOfCreation)}
                        >
                          {Locale.toRelative(incident.timeOfCreation)}
                        </Tooltip>
                      </Text>
                    </Flex>
                  </VStack>
                </HStack>

                <Divider />
              </VStack>
            ))
        ) : (
          <VStack>
            <IllustrationBox
              width="300px"
              image={IncidentIllustration}
              name={'incidents'}
              msg={
                <PlaceholderText
                  message="No incidents today! (Yay!)"
                  tip={
                    'Tip: Check your alert sources to ensure the right data is routed to the service.'
                  }
                />
              }
            />
          </VStack>
        ))}

      {!!incidents.length && (
        <Pagination
          enabled
          queryPageSize={pageParams.size}
          pageSizeChange={size => setPageParams(oldParams => ({ ...oldParams, size }))}
          queryPageIndex={pageParams.page}
          pageChange={page => setPageParams(oldParams => ({ ...oldParams, page }))}
          totalCount={incidents.length}
        />
      )}
    </Flex>
  );
}

export default ServiceIncidents;
