import styled from 'styled-components';

interface IProps {
  fontSize?: 14 | number;
  height?: '16px' | 'fit-content';
  fontWeight?: 400 | 500 | number;
}

const Label = styled.label<IProps>`
  color: ${props => props.color || props.theme.font.label};
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.fontSize || 14}px;
  line-height: ${props => props.height || '16px'};
  font-weight: ${props => props.fontWeight || 400};
  height: auto;
  margin: 0;
`;

/** @component */
export default Label;
