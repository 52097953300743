import { BoxProps, Divider, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { StepCircle } from './StepCircle';

interface StepProps extends BoxProps {
  title: string;
  description: string;
  isCompleted: boolean;
  isActive: boolean;
  isLastStep: boolean;
  isFirstStep: boolean;
}

export const Step = (props: StepProps) => {
  const { isActive, isCompleted, isLastStep, isFirstStep, title, description, ...stackProps } =
    props;
  const isMobile = useBreakpointValue({ base: true, md: false });

  const orientation = useBreakpointValue<'horizontal' | 'vertical'>({
    base: 'vertical',
    md: 'horizontal',
  });

  return (
    <Stack spacing="5" direction={{ base: 'row', md: 'column' }} flex="3" {...stackProps}>
      <Stack spacing="0" align="center" direction={{ base: 'column', md: 'row' }}>
        <Divider
          width="100px"
          display={isMobile ? 'none' : 'initial'}
          orientation={orientation}
          borderWidth="3px"
          borderColor={isFirstStep ? 'transparent' : isCompleted || isActive ? 'accent' : 'inherit'}
        />
        <StepCircle isActive={isActive} isCompleted={isCompleted} />
        <Divider
          width="100px"
          orientation={orientation}
          borderWidth="3px"
          borderColor={isCompleted ? 'accent' : isLastStep ? 'transparent' : 'inherit'}
        />
      </Stack>
      <Stack
        spacing="0.5"
        pb={isMobile && !isLastStep ? '8' : '0'}
        align={{ base: 'start', md: 'center' }}
      >
        <Text color="emphasized" fontWeight="medium">
          {title}
        </Text>
      </Stack>
    </Stack>
  );
};
