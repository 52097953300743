import { generatePath } from 'react-router';
import {
  INCIDENT_DETAILS_PATH,
  LCR_DETAILS_PATH,
  LCR_EDIT_PATH,
  LCR_SESSIONS_DETAILS_PATH,
  LCR_SESSIONS_PATH,
  SCHEDULES_V2_DETAIL_PATH,
  SERVICE_DETAILS_PATH,
} from 'views/main/routes/routes';

export const getLCREditPath = (id: string) => {
  return generatePath(LCR_EDIT_PATH, { id });
};

export const getLCRDetailsPath = (id: string) => {
  return generatePath(LCR_DETAILS_PATH, { id });
};

export const getIncidentDetailsPath = (id: string) => {
  return generatePath(INCIDENT_DETAILS_PATH, { id });
};

export const getServiceDetailsPath = (id: string) => {
  return generatePath(SERVICE_DETAILS_PATH, { id });
};

export const getSchedulesDetailsPath = (scheduleId: string) => {
  return generatePath(SCHEDULES_V2_DETAIL_PATH, { scheduleId });
};

export const getSessionPath = (id: string, tabs: string) => {
  return generatePath(LCR_SESSIONS_PATH, { id, tabs });
};
export const getSessionsDetailsPath = (id: string, tabs: string, sessionID: string) => {
  return generatePath(LCR_SESSIONS_DETAILS_PATH, { id, tabs, sessionID });
};
