import React, { ReactNode } from 'react';

import {
  Flex,
  Spinner as CircleSpinner,
  SpinnerProps as LoaderSpinnerProps,
  Text,
} from '@chakra-ui/react';

interface SpinnerProps {
  isLoading?: boolean;
  loadingMessage?: string;
  centered?: boolean;
  spinnerProps?: LoaderSpinnerProps;
  children?: ReactNode | ReactNode[];
}
const Spinner = ({ children, isLoading, loadingMessage, centered, spinnerProps }: SpinnerProps) => {
  return isLoading ? (
    <Flex
      flexDirection="column"
      justifyContent={centered ? 'center' : 'flex-start'}
      alignItems={centered ? 'center' : 'flex-start'}
    >
      <CircleSpinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="lg"
        mt={2}
        {...spinnerProps}
      />
      {!!loadingMessage && (
        <Text mt={1} fontSize="xs">
          {loadingMessage}
        </Text>
      )}
    </Flex>
  ) : (
    <>{children}</>
  );
};

const Loader = {
  Spinner,
};

export default Loader;
