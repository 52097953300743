import { IIncident } from 'core/interfaces/IIncidents';
import IncidentSummaryDrawer from './components/drawer';
import GenerateIncidentSummaryButton from './components/GenerateSummaryButton';
import IncidentSummaryUpgradeModal from './components/UpgradeModal';
import { IncidentSummaryProvider } from './context/SummaryContext';

interface IProps {
  incidentId: string;
  incident: IIncident;
}

const IncidentSummary = (props: IProps) => {
  return (
    <IncidentSummaryProvider incidentId={props.incidentId} incident={props.incident}>
      <GenerateIncidentSummaryButton />
      <IncidentSummaryDrawer />
      <IncidentSummaryUpgradeModal />
    </IncidentSummaryProvider>
  );
};

export default IncidentSummary;
