import React, { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../../../core/interfaces/IAppState';
import { ITeam } from '../../../../../../../core/interfaces/ITeams';
import {
  Para,
  Theme,
  Label,
  InputBlock,
  TextBlock,
  Grid,
  TextButton,
  SpinLoader,
  ErrorBlock,
  FormBlock,
  Checkbox,
} from 'uie/components';
import {
  IComponentErrors,
  IComponentNetworkState,
} from '../../../../../../../core/interfaces/IComponentState';
import {
  checkIfActionChanged,
  checkIfKeyChanged,
  checkIfNotValid,
  objectIsEmpty,
} from 'core/helpers';
import TeamsService from '../../../../../../../core/services/rbac/service.teams';
import { requestOrganizationTeams } from '../../../../../../../core/actions/organization/teams';
import { exception } from '../../../../../../../core/exception';
import { AppTracker } from '../../../../../../../shared/analytics/tracker';
import { T_WA_GS_TEAMS_DELETED } from '../../../../../../../core/const/tracker';
import { SettingPageACL, withSettingPageACL } from '../../acl';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

interface IProps extends Pick<IAppState, 'organization'> {
  team: ITeam;
  refreshId: number;
  requestOrganizationTeams: typeof requestOrganizationTeams;
  aclStore: SettingPageACL;
}

interface IState extends Pick<ITeam, 'name' | 'description'> {
  networkState: IComponentNetworkState | 'name-change';
  errors: IComponentErrors<'error_name' | 'error_network'>;
  agreeDelete: boolean;
}

class OrganizationSettingsTeamsRenderSettings extends Component<IProps, IState> {
  private _teamService = new TeamsService();

  constructor(props: IProps) {
    super(props);

    this.state = {
      name: this.props.team.name,
      description: this.props.team.description,
      networkState: 'idle',
      agreeDelete: false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (checkIfKeyChanged(prevProps, this.props, 'refreshId')) {
      this.refreshStates();
    }

    if (
      checkIfActionChanged(
        prevProps.organization.teams,
        this.props.organization.teams,
        'action',
        'REQUEST_ORG_TEAMS_SUCCESS',
      )
    ) {
      this.refreshStates();
    }
  }

  refreshStates = () => {
    this.setState({
      name: this.props.team.name,
      description: this.props.team.description,
      networkState: 'idle',
      agreeDelete: false,
      errors: {},
    });
  };

  onValueChange =
    (type: keyof Pick<IState, 'name' | 'description'>) =>
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      this.setState({
        [type]: ev.target.value,
      } as any);
    };

  onUpdate = async () => {
    const { name, description } = this.state;

    const errors = checkIfNotValid([[name, '', { error_name: 'Team name is required' }]]);

    if (!objectIsEmpty(errors)) {
      console.log(errors);
      this.setState({ errors });
      return;
    }

    this.setState({ networkState: 'request' });

    try {
      const { id, members } = this.props.team;

      await this._teamService.update(id, {
        name,
        description,
        members,
      });
    } catch (err: any) {
      this.setState({
        errors: {
          error_network: err.response.data.meta.error_message,
        },
      });
      exception.handle('E_PUT_TEAMS_UPDATE', err);
    } finally {
      this.props.requestOrganizationTeams();
    }
  };

  onDeleteTeam = async () => {
    try {
      const { id } = this.props.team;

      await this._teamService.delete(id);
      this.props.requestOrganizationTeams();
      AppTracker.track(T_WA_GS_TEAMS_DELETED, {
        'Team ID': id,
      });
    } catch (err: any) {
      this.setState({
        errors: {
          error_network: exception.handle('E_DELETE_TEAM', err),
        },
      });
    }
  };

  onAgreeDelete = () => this.setState(({ agreeDelete }) => ({ agreeDelete: !agreeDelete }));

  render() {
    const { theme } = Theme;
    const { team } = this.props;
    const { name, description, networkState, errors, agreeDelete } = this.state;

    const hasDelete = this.props.aclStore.hasDeleteAccess('teams', team.id);
    const hasUpdate = this.props.aclStore.hasUpdateAccess('teams', team.id);

    return (
      <div>
        <FormBlock onFormSubmit={this.onUpdate}>
          <div>
            <Label fontSize={14}>Name</Label>
            <div className="mt-5">
              <NoPermissionTooltip isDisabled={hasUpdate}>
                <InputBlock
                  padding="4px"
                  height="32px"
                  width="440px"
                  value={name}
                  placeholder="name"
                  onChange={this.onValueChange('name')}
                  error={!!errors.error_name}
                  readOnly={!hasUpdate}
                />
              </NoPermissionTooltip>
              <ErrorBlock>{errors.error_name}</ErrorBlock>
            </div>
          </div>

          <div className="mt-10">
            <Label fontSize={14}>Description</Label>
            <div className="mt-5">
              <NoPermissionTooltip isDisabled={hasUpdate}>
                <TextBlock
                  padding="4px"
                  width="440px"
                  placeholder="What's it for ?"
                  value={description}
                  onChange={this.onValueChange('description')}
                  readOnly={!hasUpdate}
                />
              </NoPermissionTooltip>
            </div>
          </div>
        </FormBlock>

        {!team.default && (team.name !== name || team.description !== description) && (
          <div className="mt-10">
            <ErrorBlock>{errors.error_network}</ErrorBlock>
            <TextButton height="30px" disabled={networkState === 'request'} onClick={this.onUpdate}>
              <Grid alignItems="center">
                <Para color={theme.shades.white}>Update</Para>
                {networkState === 'request' && <SpinLoader base="8px" color={theme.shades.white} />}
              </Grid>
            </TextButton>
          </div>
        )}

        {!team.default && (
          <div className="mt-20">
            <Label fontSize={14} color={theme.danger.default}>
              Delete {team.name}
            </Label>
            {errors.error_network && <ErrorBlock>{errors.error_network}</ErrorBlock>}
            {!errors.error_network && (
              <div className="mt-10">
                <label>
                  <NoPermissionTooltip isDisabled={hasDelete}>
                    <Checkbox
                      background={theme.danger.default}
                      lineFill="white"
                      checked={agreeDelete}
                      onChange={this.onAgreeDelete}
                      disabled={!hasDelete}
                    />
                  </NoPermissionTooltip>
                  <span style={{ marginLeft: '8px' }}>
                    I understand, this will delete <span className="font-bold">{team.name}</span>,
                    this is not reversible.
                  </span>
                </label>
                {agreeDelete && (
                  <TextButton
                    className="mt-10"
                    height="30px"
                    disabled={networkState === 'request'}
                    onClick={this.onDeleteTeam}
                    color={theme.danger.default}
                  >
                    <Grid alignItems="center">
                      <Para color={theme.shades.white}>Delete {team.name}</Para>
                      {networkState === 'request' && (
                        <SpinLoader base="8px" color={theme.shades.white} />
                      )}
                    </Grid>
                  </TextButton>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  ({ organization }: IAppState) => ({
    organization,
  }),
  {
    requestOrganizationTeams,
  },
)(withSettingPageACL(OrganizationSettingsTeamsRenderSettings));
