import { ReduceEntity } from 'core/interfaces/IHelpers';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { getAssignableStakeHolders } from 'shared/reducers';

export const getOrganizationUsers = (organization: any) => {
  return organization.users.u.reduce(
    (
      c: ReduceEntity<IUsersOfOrganization, 'id' | 'email_verified' | 'phone_verified', 'name'>,
      u: any,
    ) => {
      c[u.id] = {
        id: u.id,
        name: `${u?.first_name ?? 'Deleted'} ${u?.last_name ?? ''}`,
        email_verified: u.email_verified,
        phone_verified: u.phone_verified,
      };

      return c;
    },
    {},
  );
};

export const getFilteredUsers = (team: any, organization: any, searchTerm: string) => {
  const _oUsers = getOrganizationUsers(organization);
  return getAssignableStakeHolders(team, organization.users.u).filter(
    m =>
      m.role === 'stakeholder' &&
      (_oUsers[m.id]?.name ?? 'Deleted').toLowerCase().includes(searchTerm.toLowerCase()),
  );
};
