import * as React from 'react';
import { SVGProps } from 'react';

const SvgDatadog = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="m16.3 14.2-1.7-1.1-1.4 2.3-1.6-.5-1.4 2.2.1.7 7.7-1.4-.4-4.8-1.3 2.6zm-7.2-2.1 1.2-.2c.2.1.3.1.6.2.4.1.8.2 1.5-.1.1-.1.5-.4.6-.5l5.1-.9.5 6.3-8.7 1.6-.8-6.4zm9.4-2.2-.5.1-1-10L.7 1.9l2 16.4 1.9-.3c-.2-.2-.4-.5-.8-.8-.6-.5-.4-1.3 0-1.8.4-.9 2.7-1.9 2.6-3.3 0-.5-.1-1.1-.6-1.6v.4s-.2-.2-.3-.6l-.3-.3c-.1.2-.1.4-.1.4s-.1-.3-.1-.6c-.1.1-.1.4-.1.4s-.2-.6-.2-.9c-.1-.3-.3-.8-.3-2 .5.4 1.6.3 2-.4.2-.2.3-.8 0-1.9-.2-.7-.7-1.8-.9-2.2.1.3.3 1 .4 1.4.2 1 .3 1.4.2 1.8-.1.4-.4.7-.9 1-.6.3-1.4-.5-1.5-.5-.5-.5-1-1.2-1-1.6-.1-.4.2-.6.3-.9-.2 0-.4.1-.4.1s.3-.3.6-.5c.1-.1.2-.1.4-.3h-.4s.3-.2.7-.3h-.5s.8-.3 1.4-.6c.4-.2.8-.1 1.1.2.3.4.6.7 1.3.8.4-.1.5-.2 1-.4.5-.5.8-.6.8-.6s-.1.2-.2.5c.3-.2.5-.4.5-.4s0 .1-.1.3c.3-.2.7-.3.7-.3l-.3.3h.9c1.1 0 1.3-1.1 1.7-1.3.5-.2.7-.3 1.6.6.8.7 1.3 2.1 1 2.4-.2.2-.7-.1-1.3-.8-.2-.3-.4-.7-.5-1.3-.1-.4-.4-.7-.4-.7s.2.4.2.8c0 .2 0 1 .4 1.4 0 .1 0 .3-.1.4-.4-.5-1.2-.8-1.3-.9.5.4 1.5 1.2 1.9 2 .3.8.1 1.5.3 1.7.1.1.8 1 1 1.5.3.8 0 1.7-.3 2.2l-1 .2c-.1 0-.2-.1-.4-.1.1-.1.2-.4.2-.5l-.1-.1c-.3.4-.8.8-1.2 1-.5.3-1.1.3-1.5.1-1.1-.3-2.2-1.1-2.4-1.3v.2c.3.3.9.9 1.6 1.3l-1.3.1.6 4.9c-.3 0-.3.1-.6.1-.3-1-.8-1.6-1.4-1.9-.5-.3-1.2-.4-1.8-.3.5 0 1 0 1.6.4.5.3 1 1.2 1.1 1.8.2.7.4 1.4-.2 2.2-.4.6-1.6.9-2.5.2.2.4.6.7 1 .8.7.1 1.3 0 1.8-.5.4-.4.6-1.2.5-2l.6-.1.2 1.5 9.9-1.2-.9-7.7zm-6-4.2c0 .1-.1.1 0 .3v.1c.1.2.2.5.5.6h.2c.2 0 .3 0 .4.1v-.2c0-.3.1-.8-.5-1.1-.2-.1-.5-.1-.6.1.2 0 .1 0 0 .1m1.7 2.8h-.7c-.5.1-1 .2-1.1.3-.2.2-.1.4 0 .5.4.3.8.5 1.2.5.2 0 .5-.4.6-.8.1-.1.1-.4 0-.5M9.9 6.1c.2-.1-.6-.3-1.3.1-.5.3-.5 1 0 1.4l.1.1c.2-.1.3-.2.5-.2.3-.1.5-.1.7-.2.1-.1.2-.3.2-.6-.1-.4-.4-.4-.2-.6"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#632ca6',
      }}
    />
  </svg>
);

export default SvgDatadog;
