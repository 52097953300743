import { THEME_COLORS } from 'library/theme/colors';
import React from 'react';
import { EXPORT_HISTORY_ENUM } from '../../types/index.auditLogs';

// Define the DotComponent
interface DotComponentProps {
  status: EXPORT_HISTORY_ENUM;
}
const DotComponent = (props: DotComponentProps) => {
  const { status } = props;
  // Determine the color based on the status
  let color;
  switch (status) {
    case EXPORT_HISTORY_ENUM.FAILED:
      color = THEME_COLORS.brand.failed;
      break;
    case EXPORT_HISTORY_ENUM.IN_PROGRESS:
      color = THEME_COLORS.severity['2'];
      break;
    case EXPORT_HISTORY_ENUM.PENDING:
      color = THEME_COLORS.severity['2'];
      break;
    case EXPORT_HISTORY_ENUM.COMPLETED:
      color = THEME_COLORS.brand.green;
      break;
    default:
      color = THEME_COLORS.brand.gray; // Default color if status is undefined or unknown
  }

  // Define the dot style
  const dotStyle = {
    height: '8px',
    width: '8px',
    backgroundColor: color,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: 10,
  };

  return <span style={dotStyle}></span>;
};

export default DotComponent;
