import * as React from 'react';
import { SVGProps } from 'react';

const SvgAppdynamics = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path d="M6.3 11.7 3.5 5.5C1.9 6.9 1 8.8 1 10.9c0 4 3.2 7.1 7.1 7.1.3 0 .7 0 1-.1l-2.8-6.2c0 .1 0 0 0 0zM8.1 0c-.9 0-1.8.1-2.6.3l1.6 3.6c.3 0 .7-.1 1-.1 3.9 0 7.1 3.2 7.1 7.1 0 2.1-.9 4.1-2.5 5.4l1.6 3.6c2.9-2 4.8-5.3 4.8-9.1C19 4.9 14.1 0 8.1 0zm9.8 18.4h.1v.3h.1v-.3h.1v-.1l-.3.1zm.6.3-.1-.3h-.1v.4h.1v-.3l.1.2.1-.1v.3h.1v-.4h-.1l-.1.2z" />
  </svg>
);

export default SvgAppdynamics;
