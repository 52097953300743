import Axios from 'axios';
import { IJProject, IJIssueType, IJStatusmap } from '../interfaces/IJira';
import { API } from '../api';
import {
  IGetALLJiraAccResponse,
  IJiraAccForTeam,
  IJiraIssue,
  IJiraProjects,
} from 'core/interfaces/IJiraNew';
class JiraCloudService {
  private api = `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud`;
  private organizationId = API.config.organizationId;

  public getJiraConfigs() {
    return Axios.get(this.api).then(({ data }) => data);
  }

  public getJiraProjects() {
    return Axios.get(`${this.api}/projects`).then(({ data }) => data);
  }

  public getJiraProjectIssues(projectId: string) {
    return Axios.get(`${this.api}/projects/${projectId}/issue-types`).then(({ data }) => data);
  }

  public postIncidentToJira(incidentId: string) {
    return Axios.post(
      `${API.config.batman}/organizations/${this.organizationId}/incidents/${incidentId}/actions/jira?type=cloud`,
      {},
    ).then(({ data }) => data);
  }

  public saveExtensionConfig(
    payload: {
      is_manual: boolean;
      project: IJProject;
      issue_type: IJIssueType;
      service_ids: string[];
      status_maps: IJStatusmap[];
    },
    id: string,
  ) {
    return Axios.put(`${this.api}/${id}`, payload).then(({ data }) => data);
  }

  public postJiraTokenUpdate(token: string) {
    return Axios.post(this.api, {
      jira_client_key: token,
    }).then(({ data }) => data);
  }

  public revoke(id: string) {
    return Axios.delete(`${this.api}/${id}`);
  }
}

class JiraCloudServiceNew {
  private api = `${API.config.batman}/organizations/${API.config.organizationId}/extensions`;
  private cunnrentTeamId = API.config.teamId;
  private organizationId = API.config.organizationId;

  public getJiraAccounts() {
    return Axios.get<IGetALLJiraAccResponse>(`${this.api}/jira-cloud/new`).then(({ data }) => data);
  }

  public GetJiraAccountsOfTeam() {
    return Axios.get<IJiraAccForTeam>(
      `${this.api}/jira-cloud/new/jira-accounts-by-team/${this.cunnrentTeamId}`,
    ).then(({ data }) => data);
  }

  public getJiraConfigs() {
    return Axios.get(`${this.api}/jira-cloud/new/jira-cloud-configs`).then(({ data }) => data);
  }

  public getJiraProjects(accountId: string) {
    return Axios.get<IJiraProjects>(`${this.api}/jira-cloud/new/${accountId}/projects`).then(
      ({ data }) => data,
    );
  }

  public getJiraProjectIssues(accountId: string, projectId: string) {
    return Axios.get<IJiraIssue>(
      `${this.api}/jira-cloud/new/${accountId}/projects/${projectId}/issue-types`,
    ).then(({ data }) => data);
  }

  public postIncidentToJira(incidentId: string) {
    return Axios.post(
      `${API.config.batman}/organizations/${this.organizationId}/incidents/${incidentId}/actions/jira?type=cloud`,
      {},
    ).then(({ data }) => data);
  }

  public postJiraTokenUpdate(token: string) {
    return Axios.post(this.api, {
      jira_client_key: token,
    }).then(({ data }) => data);
  }

  public postNewJiraTicket(incidentId: string) {
    return Axios.post(
      `${API.config.batman}/organizations/${this.organizationId}/incidents/${incidentId}/actions/jira/new/jira-cloud`,
    );
  }

  public revoke(id: string) {
    return Axios.delete(`${this.api}/${id}`);
  }
}

export { JiraCloudServiceNew };

export default JiraCloudService;
