import { StatusIcon } from 'icons';
import React, { Fragment, ReactElement, ReactNode, useEffect, useState } from 'react';
import {
  Column,
  TableToggleCommonProps,
  useExpanded,
  useTable,
  usePagination,
  useRowSelect,
  useSortBy,
  useGlobalFilter,
} from 'react-table';

import {
  Box,
  Button,
  Checkbox as CCheckbox,
  CheckboxProps,
  CSSObject,
  Flex,
  HStack,
  IconButton,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

export function getPager(totalRows: number, currentpage: number, pageLimit: number): Array<number> {
  if (totalRows <= pageLimit) return [1];
  const totalPages = Math.ceil(totalRows / pageLimit);
  const totalPageArray = Array.from(Array(totalPages), (_, i) => i + 1);
  return totalPageArray;
}

const styleTableWithRightBorder = {
  '& th': {
    boxShadow: 'none',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: 'gray.100',
  },
  '& td': {
    boxShadow: 'none',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: 'gray.100',
  },
};

const styleTableWithButtom = {
  '& th': {
    boxShadow: 'none',
  },
  '& td': {
    boxShadow: 'none',
    borderButtomWidth: 1,
    borderButtomStyle: 'solid',
    borderButtomColor: 'gray.100',
  },
};

const paddingStyles = {
  '& th': {
    paddingY: 3,
    paddingX: 2,
  },
  '& td': {
    paddingY: 3,
    paddingX: 2,
  },
};

interface IDataTable {
  columns: Column<Record<any, any>>[];
  data: Record<string, any>[];
  // Element to show on expanded
  expandedView?: ReactNode;
  // Should expand all rows
  isAllExpanded?: boolean;
  // Element to show on hover
  hoverView?: ReactElement;
  height?: string;
  width?: string;

  searchText?: string;

  sortBy?: (sortField: string, sort_order: 'asc' | 'des') => void;
  sorted?: { sort_by: string; sort_order: 'asc' | 'des' | string };

  isClientPagination?: boolean;
  paginationProps?: {
    queryPageIndex: number;
    queryPageSize: number;
    totalCount: number;
    pageChange: (queryPageIndex: number) => void;
    pageSizeChange: (queryPageSize: number) => void;
  };

  disableOverflow?: boolean;
  containerStyles?: CSSObject;

  //if table header needs a background color
  headerBackgroundColor?: string;
  //if we need a table with borderbuttom & no rightside border
  rowBottomBorder?: boolean;
}

/**
 * General datatable component
 * @param props IDataTable
 * @returns JSX Table Element
 */
const DataTable = React.memo(
  ({
    columns,
    data,
    expandedView,
    isAllExpanded,
    hoverView,
    height,
    width,
    isClientPagination,
    paginationProps,
    sortBy,
    disableOverflow,
    searchText,
    containerStyles,
    headerBackgroundColor,
    rowBottomBorder,
  }: IDataTable) => {
    const tableInstance = useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: isClientPagination ? 0 : 1,
        },
        manualPagination: !isClientPagination,
        pageCount: isClientPagination
          ? undefined
          : paginationProps
          ? paginationProps.totalCount
          : 0,
        autoResetPage: !isClientPagination,
      },
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect,
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      setGlobalFilter,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, selectedRowIds },
      ///Pagination
      toggleAllRowsExpanded,
    } = tableInstance;

    const {
      queryPageIndex = 1,
      queryPageSize = 10,
      totalCount,
      pageSizeChange,
      pageChange,
    } = isClientPagination
      ? {
          queryPageIndex: pageIndex + 1,
          queryPageSize: pageSize,
          totalCount: data.length,
          pageSizeChange: setPageSize,
          pageChange: (page: number) => gotoPage(page - 1),
        }
      : paginationProps ?? {};

    const [hoveredRow, setHoveredRow] = useState<string | null>(null);

    useEffect(() => {
      if (searchText?.trim().length) {
        setGlobalFilter(searchText);
      } else {
        setGlobalFilter('');
      }
    }, [searchText]);

    useEffect(() => {
      toggleAllRowsExpanded(isAllExpanded ?? false);
    }, [isAllExpanded, toggleAllRowsExpanded]);
    return (
      <TableContainer
        height={height}
        width={width}
        whiteSpace="unset"
        //This will enable any external input padding style to override the internally defined padding
        sx={{ ...paddingStyles, ...(containerStyles ? containerStyles : {}) }}
      >
        <Table
          {...getTableProps()}
          size="sm"
          sx={rowBottomBorder ? styleTableWithButtom : styleTableWithRightBorder}
        >
          <Thead background={headerBackgroundColor ? headerBackgroundColor : ''}>
            {headerGroups.map(headergroup => {
              return (
                <Tr {...headergroup.getHeaderGroupProps()} key={headergroup.id}>
                  {headergroup.headers.map(column => {
                    return (
                      <Th {...column.getHeaderProps()} color="gray.950" key={column.id}>
                        {column.render('Header')}
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {!(isClientPagination ? page : rows).length && (
              <Tr>
                <Td colSpan={columns.length}>
                  <Text fontSize={14} textAlign="center">
                    No data
                  </Text>
                </Td>
              </Tr>
            )}

            {(isClientPagination ? page : rows).map(row => {
              prepareRow(row);

              return (
                <Fragment key={row.id}>
                  <Tr
                    {...row.getRowProps()}
                    onMouseEnter={() => {
                      hoverView && setHoveredRow(row.id);
                    }}
                    onMouseLeave={() => hoverView && setHoveredRow(null)}
                    bgColor={hoverView && hoveredRow === row.id ? 'gray.100' : 'white'}
                    position={hoverView && hoveredRow === row.id ? 'relative' : 'static'}
                  >
                    {row.cells.map(cell => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={cell.getCellProps().key}
                          position="relative"
                        >
                          {cell.render('Cell')}
                        </Td>
                      );
                    })}

                    {/* Show this view on hover */}
                    {hoverView && hoveredRow === row.id ? (
                      <Flex
                        height="80%"
                        position="absolute"
                        right="5rem"
                        top="50%"
                        transform="translateY(-50%)"
                        bgColor="gray.100"
                      >
                        {React.cloneElement(hoverView, {
                          rowData: { row },
                          onMouseEnter: () => {
                            setHoveredRow(row.id);
                          },
                        })}
                      </Flex>
                    ) : null}
                  </Tr>

                  {/* Show this view on expand */}
                  {row.isExpanded && (
                    <Tr {...row.getRowProps()}>
                      <Td colSpan={columns.length} p={0}>
                        {/* <ExpandedDetail serviceID={row.original.id} /> */}
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              );
            })}
          </Tbody>
        </Table>

        <Pagination
          enabled={!!(paginationProps || isClientPagination)}
          queryPageSize={queryPageSize}
          pageSizeChange={pageSizeChange}
          queryPageIndex={queryPageIndex}
          pageChange={pageChange}
          totalCount={totalCount ?? 0}
        />
      </TableContainer>
    );
  },
);

interface PaginationProps {
  enabled: boolean;
  queryPageSize: number;
  pageSizeChange?: (size: number) => void;
  queryPageIndex: number;
  pageChange?: (page: number) => void;
  totalCount: number;
}
export const Pagination = ({
  enabled,
  queryPageSize,
  pageSizeChange,
  queryPageIndex,
  pageChange,
  totalCount,
}: PaginationProps) => {
  return enabled ? (
    <Box px={{ base: '4', md: '6' }} p="5">
      <HStack spacing="60">
        <HStack spacing={10}>
          <Select
            value={queryPageSize}
            width="81px"
            borderRadius="6px"
            size="sm"
            placeholder="Select option"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              pageSizeChange && pageSizeChange(parseInt(e.target.value));
            }}
          >
            {[5, 10, 20, 30].map((value, index) => {
              return (
                <option key={index} value={value}>
                  {value}
                </option>
              );
            })}
          </Select>

          <Text fontSize="sm">
            Showing {queryPageSize > totalCount ? totalCount : queryPageSize} of {totalCount}
          </Text>
        </HStack>
        <HStack spacing={5}>
          <IconButton
            aria-label="Search services"
            size="sm"
            variant="unstyled"
            fontSize="14px"
            fontWeight="medium"
            borderRadius={0}
            disabled={queryPageIndex == 1}
            onClick={() => pageChange && pageChange(queryPageIndex - 1)}
            icon={<ChevronLeftIcon />}
          />

          {getPager(totalCount, queryPageIndex, queryPageSize).map((page, index) => {
            return (
              <Button
                key={index}
                variant="ghost"
                fontSize="14px"
                fontWeight="medium"
                borderRadius="6px"
                size="sm"
                p={0}
                bgColor={queryPageIndex === page ? 'rgba(245, 245, 245, 1)' : 'white'}
                onClick={() => pageChange && pageChange(page)}
              >
                {page}
              </Button>
            );
          })}

          <IconButton
            aria-label="Search services"
            size="sm"
            variant="unstyled"
            fontSize="14px"
            fontWeight="medium"
            borderRadius={0}
            onClick={() => pageChange && pageChange(queryPageIndex + 1)}
            disabled={queryPageIndex * queryPageSize >= totalCount}
            icon={<ChevronRightIcon />}
          />
        </HStack>
      </HStack>
    </Box>
  ) : null;
};

const Checkbox = (checkboxProps: TableToggleCommonProps & CheckboxProps) => {
  const { indeterminate, ...rest } = checkboxProps;
  const checkboxRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (checkboxRef.current && typeof indeterminate === 'boolean') {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [checkboxRef, indeterminate]);

  return <CCheckbox ref={checkboxRef} {...rest} />;
};

export default DataTable;

Checkbox.displayName = 'Checkbox';
export { Checkbox };
