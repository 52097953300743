import { useFormContext } from 'react-hook-form';
import { FormButton } from 'library/atoms';

export function FormFooter<T>({
  checkDirtyAndCloseDrawer,
}: {
  checkDirtyAndCloseDrawer: () => void;
}) {
  const { formState } = useFormContext<T>();
  return (
    <>
      <FormButton
        variant="primary"
        type="submit"
        aria-label="submit"
        disabled={!formState.isDirty}
        title="Save"
      />
      <FormButton variant="secondary" onClick={() => checkDirtyAndCloseDrawer()} title="Cancel" />
    </>
  );
}
