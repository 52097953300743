import { Accordion as ChakraAccordion, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import { AccordionButton } from 'library/atoms';
import { ReactElement } from 'react';

export type AccordionItemType = {
  title: string;
  description?: string;
  isDisabled?: boolean;
  content?: ReactElement;
  actionButton?: JSX.Element;
};

export type AccordionProps = {
  items: Array<AccordionItemType>;
  allowMultiple?: boolean;
  allowToggle?: boolean;
  defaultIndex?: number;
};

export const Accordion = ({
  items,
  defaultIndex = 0,
  allowMultiple = false,
  allowToggle = true,
}: AccordionProps) => {
  return (
    <ChakraAccordion
      defaultIndex={[defaultIndex]}
      allowMultiple={allowMultiple}
      allowToggle={allowToggle}
    >
      {items &&
        items.map((item, index) => (
          <AccordionItem key={index} isDisabled={item.isDisabled}>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  title={item.title}
                  description={item.description}
                  isDisabled={item.isDisabled}
                  isExpanded={isExpanded}
                  actionButton={item.actionButton}
                />
                {item.content && <AccordionPanel pb={4}>{item.content}</AccordionPanel>}
              </>
            )}
          </AccordionItem>
        ))}
    </ChakraAccordion>
  );
};
