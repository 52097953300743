import Axios from 'axios';
import { API } from '../../api';
import { ITeam, ITeamRoles } from '../../interfaces/ITeams';

class TeamsRolesService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/teams`;
  constructor(teamId: string) {
    this._api = `${this._api}/${teamId}/roles`;
  }

  create = (payload: Pick<ITeamRoles, 'name' | 'abilities'>) =>
    Axios.post<{ data: ITeam }>(this._api, payload);

  update = (roleId: string, payload: Pick<ITeamRoles, 'name' | 'abilities'>) =>
    Axios.put<{ data: ITeam }>(`${this._api}/${roleId}`, payload);

  delete = (roleId: string) => Axios.delete<{ data: boolean }>(`${this._api}/${roleId}`);
}

export default TeamsRolesService;
