import { Box, Text, VStack } from '@chakra-ui/react';
import { API } from 'core';
import { Input } from 'library/atoms';
import { AssigneeType } from 'library/enums';
import { cloneDeep } from 'lodash';
import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
  DEFAULT_REACT_QUERY_CONFIG,
  EMPTY_STRING,
} from 'views/main/organization/incident-list/common/constants';
import { IncidentAssignToType } from 'views/main/organization/incident-list/graphql/generated/types';
import {
  GetIncidentAssignableEntitiesQuery,
  useGetIncidentAssignableEntitiesQuery,
} from 'views/main/organization/incident-list/graphql/query';

import { Loader } from '../Loader';
import { Entity } from './Entity';
import { AssignableEntityType, IncidentAssignableSearchProps } from './types';

export const IncidentAssignableSearch = memo(
  forwardRef((props: IncidentAssignableSearchProps, ref) => {
    const { assignees, onSelectionUpdate } = props;
    const [selectedEntities, setSelectedEntities] = useState<
      Array<{
        name: string;
        type: IncidentAssignToType;
        id: string;
      }>
    >([]);
    const [searchTerm, setSearchTerm] = useState(EMPTY_STRING);
    const [filteredData, setFilteredData] =
      useState<GetIncidentAssignableEntitiesQuery['getIncidentAssignableEntities']>();
    const disallowedType = useMemo(() => props.disallowType ?? [], [props.disallowType]);

    const { isLoading, data } = useGetIncidentAssignableEntitiesQuery(
      {
        teamID: API.config.teamId,
      },
      {
        ...DEFAULT_REACT_QUERY_CONFIG,
        cacheTime: 0,
      },
    );

    const resetFilterData = () => {
      setFilteredData(data?.getIncidentAssignableEntities);
    };

    useImperativeHandle(ref, () => ({
      clearSelection() {
        setSelectedEntities([]);
      },
    }));

    useEffect(() => {
      resetFilterData();
    }, [data]);

    const getEntity = (id: string) => {
      let type = IncidentAssignToType.User;
      let entityName = data?.getIncidentAssignableEntities.users.find(
        (user: any) => user.ID === id,
      )?.name;

      if (!entityName) {
        entityName = data?.getIncidentAssignableEntities.squads.find(
          squad => squad.ID === id,
        )?.name;
        type = IncidentAssignToType.Squad;
      }

      if (!entityName) {
        entityName = data?.getIncidentAssignableEntities.escalationPolicies.find(
          ep => ep.ID === id,
        )?.name;
        type = IncidentAssignToType.Escalationpolicy;
      }

      return {
        name: entityName ?? '',
        type,
        id,
      };
    };

    const onSelectionChange = (e: any) => {
      const assignee = getEntity(e.target.value);
      let assigneeList = [assignee];

      if (props.allowMulti) {
        assigneeList = selectedEntities.some(entity => entity.id === assignee.id)
          ? selectedEntities.filter(entity => entity.id !== assignee.id)
          : [...selectedEntities, assignee];
      }
      setSelectedEntities(assigneeList);
      onSelectionUpdate(assigneeList);
    };

    const filterEntity = (array: AssignableEntityType[], searchText: string) => {
      return array.filter(
        item =>
          !assignees.some(assignee => assignee.id === item.ID) &&
          item.name.toLowerCase().includes(searchText),
      );
    };

    const filterData = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
      if (e.target.value && data?.getIncidentAssignableEntities) {
        const entities = cloneDeep(data.getIncidentAssignableEntities);
        const searchText = e.target.value.trim().toLowerCase();

        entities.users = filterEntity(entities.users, searchText);
        entities.squads = filterEntity(entities.squads, searchText);
        entities.escalationPolicies = filterEntity(entities.escalationPolicies, searchText);

        setFilteredData(entities);
      } else {
        resetFilterData();
      }
    };

    const EntityUnit = (
      type: AssigneeType,
      title: string,
      array: Array<AssignableEntityType> = [],
    ) => {
      return (
        <>
          {!!array.length && (
            <>
              <Box
                mt="0px !important"
                bgColor="secondary.150"
                width="100%"
                px={4}
                py={3}
                borderBottomWidth="1px"
                borderColor="secondary.200"
              >
                <Text variant="hint_800" color="secondary.700">
                  {title}
                </Text>
              </Box>
              {array.map(item => {
                const { ID: id, name, usernameForDisplay, ...rest } = item;
                return (
                  <>
                    {assignees.some(assignee => assignee.id === id && assignee.type === type) ? (
                      <></>
                    ) : (
                      <Entity
                        {...rest}
                        key={id}
                        isSelected={selectedEntities.some(entity => entity.id === id)}
                        name={name}
                        username={usernameForDisplay}
                        type={type}
                        id={id}
                        onChange={onSelectionChange}
                        allowMulti={props.allowMulti}
                      />
                    )}
                  </>
                );
              })}
            </>
          )}
        </>
      );
    };

    return (
      <Box p={4}>
        <VStack
          p={4}
          borderBottomWidth="1px"
          borderBottomColor="primary.500"
          alignItems="flex-start"
          spacing={2}
          bg="primary.100"
        >
          {!props.hideTitle && (
            <Text variant="formInput_800" color="secondary.700">
              Reassign To
            </Text>
          )}
          <Input
            isDisabled={isLoading}
            value={searchTerm}
            onChange={filterData}
            placeholder={
              props.searchPlaceholder || 'Type an escalation policy, squad or user to reassign'
            }
          ></Input>
        </VStack>
        {isLoading ? (
          <Loader />
        ) : (
          <VStack alignItems="flex-start" mt={3}>
            {!disallowedType.includes(AssigneeType.User) &&
              EntityUnit(AssigneeType.User, 'USERS', filteredData?.users)}
            {!disallowedType.includes(AssigneeType.Squad) &&
              EntityUnit(AssigneeType.Squad, 'SQUADS', filteredData?.squads)}
            {!disallowedType.includes(AssigneeType.Escalationpolicy) &&
              EntityUnit(
                AssigneeType.Escalationpolicy,
                'ESCALATION POLICY',
                filteredData?.escalationPolicies,
              )}
          </VStack>
        )}
      </Box>
    );
  }),
);
