import { API } from 'core';
import { transformTags } from '../../../helper';
import { useGetIncidentActiveTagsQuery } from 'views/main/organization/incident-list/graphql/query';
import { useToast } from 'library/atoms';

export const DEFAULT_REACT_QUERY_CONFIG = {
  retry: 1,
  keepPreviousData: true,
  refetchOnWindowFocus: false,
};

export function useTags() {
  const toast = useToast();
  const teamId = API.config.teamId;

  const queryResult = useGetIncidentActiveTagsQuery(
    {
      teamID: teamId,
    },
    {
      ...DEFAULT_REACT_QUERY_CONFIG,
      onError: error => {
        toast({ text: 'Tags list fetch failed ', status: 'error' });
      },
      select: transformTags,
    },
  );

  return queryResult;
}
