import { HStack, Switch, Tag, Box, Flex, Divider } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/table-core';
import { NodePopover } from 'components/chakra/Popover';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CountriesKey, NumberTypeKeys, RoutingTypeKeys } from '../../common/constant';
import { LCRDataResponse } from '../../common/interface';
import { getLCRDetailsPath, getServiceDetailsPath } from '../../common/utils';
import { useLCRStore } from '../../hooks/lcr.state';
import { API } from 'core/api';
import { shallowEqual, useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { userAccess } from 'core/userAccess';
import { HoverComponentProps } from 'library/molecules/Tablev2/Table';
import { FormButton } from 'library/atoms';
import { Text } from 'library/atoms/Text';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

type Props = {
  onDelete: ({ id, name }: { id: string; name: string }) => void;
  onEdit: ({ id }: { id: string }) => void;
};

type LCRData = LCRDataResponse['data'][number];

export const HoverComponent = (props: HoverComponentProps<LCRData> & Props) => {
  const userAccess = useUserAccess();

  const hasEditAccess = userAccess.hasUpdateAccess('routing_numbers', props.row.original.id);
  const hasDeleteAccess = userAccess.hasDeleteAccess('routing_numbers', props.row.original.id);

  return (
    <HStack gap="0.5rem">
      <NoPermissionTooltip isDisabled={hasDeleteAccess}>
        <FormButton
          variant="outline-danger"
          title="Delete"
          onClick={() =>
            props.onDelete({ id: props.row.original.id, name: props.row.original.name })
          }
        ></FormButton>
      </NoPermissionTooltip>
      <NoPermissionTooltip isDisabled={hasEditAccess}>
        <FormButton onClick={() => props.onEdit({ id: props.row.original.id })} title="Edit" />
      </NoPermissionTooltip>
    </HStack>
  );
};

export const useLCRColumn = () => {
  const { setLCRStatus, openEnableModal } = useLCRStore();

  const teamId = API.config.teamId;
  const organization = useSelector((state: IAppState) => state.organization, shallowEqual);
  const team = organization.teams.t.find(t => t.id === teamId);
  const userAccess = useUserAccess();

  const handleSwitchChange = async (enabled: boolean, id: string) => {
    setLCRStatus(id, enabled);
    openEnableModal();
  };

  const columns = useMemo<ColumnDef<LCRData, any>[]>(
    () => [
      {
        header: 'NAME',
        cell: ({ row }) => {
          return (
            <Link to={getLCRDetailsPath(row.original.id)}>
              <Text>{row.original.name}</Text>
            </Link>
          );
        },
      },
      {
        header: 'ENABLE',
        cell: ({ row }) => {
          const hasEditAccess = userAccess.hasUpdateAccess('routing_numbers', row.original.id);

          return (
            <NoPermissionTooltip isDisabled={hasEditAccess}>
              <Switch
                size="sm"
                isChecked={row.original.enabled}
                disabled={!hasEditAccess}
                onChange={e => handleSwitchChange(row.original.enabled, row.original.id)}
              />
            </NoPermissionTooltip>
          );
        },
      },
      {
        header: 'COUNTRY',
        cell: ({ row }) => <Text>{CountriesKey[row.original.country]}</Text>,
      },
      {
        header: 'ROUTING NUMBER',
        cell: ({ row }) => <Text>{row.original.number}</Text>,
      },
      {
        header: 'ROUTING TYPE',
        cell: ({ row }) => <Text>{RoutingTypeKeys[row.original.routing_type]}</Text>,
      },
      {
        header: 'NUMBER TYPE',
        cell: ({ row }) => <Text>{NumberTypeKeys[row.original.type]}</Text>,
      },
      {
        header: 'Services',
        cell: ({ row }) => {
          return (
            <NodePopover
              triggerType="hover"
              hasArrow
              trigger={
                <Flex>
                  <Link to={getServiceDetailsPath(row.original.keypad_mappings[0].service_id)}>
                    {row.original.keypad_mappings[0].digit}-
                    {row.original.keypad_mappings[0].service_name}
                  </Link>

                  {row.original.keypad_mappings.length > 1 && (
                    <Tag
                      size="sm"
                      borderRadius="full"
                      cursor="pointer"
                      ml={2}
                      background={'#C8E2FF'}
                    >
                      +{row.original.keypad_mappings.length - 1}
                    </Tag>
                  )}
                </Flex>
              }
            >
              {row.original.keypad_mappings.slice(1).map(_ => (
                <Box key={_.service_id} backgroundColor="#EEF2F6">
                  <Link
                    to={`/service-catalog/${_.service_id}`}
                    color="#3D6DD8"
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >
                    <Text key={_.service_id}>
                      {_.digit}-{_.service_name}{' '}
                    </Text>
                  </Link>
                  <Divider orientation="vertical" />
                </Box>
              ))}
            </NodePopover>
          );
        },
      },
    ],
    [],
  );

  return columns;
};
