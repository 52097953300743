import { ContainerLoad } from 'uie/components';
import { IAppState } from 'core/interfaces/IAppState';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import { TEAM_SQUADS_PATH } from 'views/main/routes/routes';
import { useSquadListing } from '../hooks';
import { Member, SquadMember } from '../types';
import { EmptySquad } from './EmptySquads';
import SquadSelector from './SquadSelector';
import { SquadTable } from './SquadTable';
import styles from './styles.module.css';

const getDemoralizedMember = (member: Member, users: IUsersOfOrganization[]): SquadMember => {
  const user = users.find(u => u.id === member.user_id);
  return {
    ...member,
    username_for_display: user?.username_for_display ?? '',
    email_verified: user?.email_verified ?? false,
    name: `${user?.first_name} ${user?.last_name}` ?? '',
    email: user?.email ?? '',
  };
};

type MemberMap = Map<string, { members: SquadMember[]; name: string }> | undefined;

type Props = {
  activeTab: string;
};
function SquadBasedPermissionSquadPage() {
  const { data, isLoading, isFetching, isError } = useSquadListing();
  const users = useSelector((state: IAppState) => state.organization.users.u);

  const params = useParams<{ id: string }>();
  const history = useHistory();

  const membersMap: MemberMap = useMemo(
    () =>
      data?.data.data.reduce((acc: MemberMap, squad) => {
        const members = squad.members;
        for (const member of members) {
          const demoralizedMembers = getDemoralizedMember(member, users);
          if (!acc) return new Map();
          if (acc.has(squad.id)) {
            acc.set(squad.id, {
              members: [...(acc.get(squad.id)?.members ?? []), demoralizedMembers],
              name: squad.name,
            });
          } else {
            acc.set(squad.id, { members: [demoralizedMembers], name: squad.name });
          }
        }
        return acc;
      }, new Map()),
    [data, users],
  );

  const location = useLocation();
  const hash = location.hash;
  const selectedId = hash.replace('#', '');

  if (isError) {
    return <div>Something went wrong</div>;
  }

  return (
    <div>
      <div className={styles.main_title}>
        Squads are smaller groups of members who are responsible for specific projects within a
        team. Learn more{' '}
        <a
          style={{
            color: '#0E61DD',
          }}
          href="https://support.squadcast.com/manage-teams/squads"
          target="_blank"
          rel="noreferrer"
        >
          {' '}
          here
        </a>
      </div>
      <ContainerLoad isLoading={isLoading || isFetching} />
      <div
        style={{
          display: 'flex',
        }}
      >
        {data?.data.data.length === 0 ? (
          <EmptySquad />
        ) : (
          <>
            {data?.data.data && <SquadSelector squads={data?.data?.data} />}
            {data?.data.data && (
              <SquadTable
                members={membersMap?.get(selectedId)?.members ?? []}
                squadName={membersMap?.get(selectedId)?.name ?? ''}
                onDeleted={() => {
                  history.push(
                    generatePath(TEAM_SQUADS_PATH, {
                      id: params.id,
                      sqid: data?.data?.data?.[0]?.id,
                    }),
                  );
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export { SquadBasedPermissionSquadPage };
