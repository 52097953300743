import {
  Box,
  Center,
  Flex,
  HStack,
  Image,
  Text,
  useBoolean,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { T_WA_UP_GER_ALERT_SRC_CREATED } from 'core/const/tracker';

import { useIntegrationList } from 'core/hooks/useGetAllIntegrationCategories';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { Dropdown, FormButton, ListingButton, Search, useToast } from 'library/atoms';
import { CustomDrawerComponent, Placeholder } from 'library/molecules';
import { FC, useContext, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { AppTracker } from 'shared/analytics/tracker';

import { AppContext } from '../..';
import { getNameComponent } from '../../components';
import { createGerRuleset } from '../../query/useCreateGerRuleset';
import { invalidateGERDetail } from '../../query/useGetGerDetail';
import { GERContext } from '../../store';
import { ALERT_INFO, RULESET } from '../../types';
import { ADD_ALERT_SRC_TITLE, ALERT_SRC_TITLE, getAlertsList, NEXT_ADD_RULESET } from '../../util';
import { AlertEntity } from './alert';

type Props = {
  gerId: string;
  rulesets: RULESET[];
  selectedId?: string;
  onSelection: (id: string) => void;
};

const allOption = {
  label: 'All Integrations',
  value: '',
};

export const GERAlertList: FC<Props> = ({ gerId, rulesets, selectedId, onSelection }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const context = useContext(AppContext);
  const [isAddingAlert, setIsAddingAlert] = useBoolean();

  const userAccess = useUserAccess();

  const alertSrcList: ALERT_INFO[] = useMemo(() => {
    return getAlertsList(context?.integrations);
  }, [context?.integrations]);

  const availableAlertSrc: ALERT_INFO[] = useMemo(() => {
    return alertSrcList.filter(
      alert =>
        !rulesets.some(
          rule =>
            rule.alert_source_shortname === alert.shortName &&
            rule.alert_source_version === alert.version,
        ),
    );
  }, [alertSrcList, rulesets]);

  const { isLoading: isLoadingCategories, integrationOptions } = useIntegrationList();
  const categories = useMemo(
    () => [allOption, ...(integrationOptions?.length ? integrationOptions : [])],
    integrationOptions,
  );

  const [filteredList, setFilteredList] = useState(availableAlertSrc);
  const [categoryList, setCategoryList] = useState(availableAlertSrc);
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState(allOption);
  const [selectedAlert, setSelectedAlert] = useState<ALERT_INFO>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const onSuccess = (alert: ALERT_INFO) => {
    toast({
      status: 'success',
      text: `Success: ${alert.name} added successfully`,
    });
    AppTracker.track(T_WA_UP_GER_ALERT_SRC_CREATED, {
      'GER Ruleset ID': gerId,
      'Alert Source Name': alert.shortName,
    });
    invalidateGERDetail(queryClient);
    onClose();
  };

  const onAdd = () => {
    if (selectedAlert) {
      setIsAddingAlert.on();
      createGerRuleset(gerId, selectedAlert.version, selectedAlert.shortName)
        .then(() => {
          onSuccess(selectedAlert);
        })
        .catch(err => {
          const errMsg = err?.response?.data?.meta.error_message ?? 'Unable to add alert source';

          toast({
            status: 'error',
            text: `Error: ${errMsg}`,
          });
          console.error(err);
        })
        .finally(setIsAddingAlert.off);
    }
  };

  const filterData = (text: string) => {
    setSearchTerm(text);
    const val = text.toLowerCase();
    const alerts = categoryList.filter(alert => alert.name.toLowerCase().includes(val));
    setFilteredList(alerts);
  };

  const onFilter = (val: any) => {
    setCategory(val);
    setSearchTerm('');
    const alertsList = val?.value
      ? availableAlertSrc.filter(alert => alert.categories.includes(val.value))
      : availableAlertSrc;
    setCategoryList(alertsList);
    setFilteredList(alertsList);
  };

  return (
    <>
      <Flex flexDir="column" flex="1">
        {rulesets.length ? (
          <>
            <Flex py={3} pr={2} pl={6} alignItems="center" justifyContent="space-between">
              <Text variant="body_800" color="secondary.1000">
                {ALERT_SRC_TITLE}
              </Text>
              <ListingButton
                title={ADD_ALERT_SRC_TITLE}
                variant="secondary"
                onClick={onOpen}
                isPermissionPresent={userAccess.hasUpdateAccess('ger', gerId)}
                isDisabled={!userAccess.hasUpdateAccess('ger', gerId)}
              />
            </Flex>
            <Flex flexDir="column" maxH="calc(100vh - 340px)" overflowY="auto">
              {rulesets.map(rule => (
                <Box
                  key={rule.id}
                  borderBottom={1}
                  borderStyle="solid"
                  borderBottomColor="secondary.100"
                  bg={rule.id === selectedId ? 'primary.100' : 'brand.white'}
                >
                  <AlertEntity
                    rule={rule}
                    alertsList={alertSrcList}
                    onSelection={() => onSelection(rule.id)}
                    gerId={gerId}
                  />
                </Box>
              ))}
            </Flex>
          </>
        ) : (
          <Placeholder
            iconName="alert-sources.svg"
            addBtnTitle={userAccess.hasUpdateAccess('ger', gerId) ? NEXT_ADD_RULESET : ''}
            description={
              userAccess.hasUpdateAccess('ger', gerId)
                ? 'Add an alert source, and start configuring rules for your ruleset'
                : 'You do not have permission to update GER'
            }
            onCreate={onOpen}
            size="md"
          />
        )}
      </Flex>
      {isOpen && (
        <CustomDrawerComponent
          isOpen={isOpen}
          onClose={onClose}
          title={ADD_ALERT_SRC_TITLE}
          disableBodyPadding
          footer={
            <HStack spacing={3}>
              <FormButton
                title="Add"
                isDisabled={!selectedAlert?.id}
                onClick={onAdd}
                isLoading={isAddingAlert}
              />
              <FormButton
                title="Cancel"
                variant="secondary"
                onClick={onClose}
                isDisabled={isAddingAlert}
              />
            </HStack>
          }
        >
          <Box p={4} display="flex" flexDir="column" rowGap={2}>
            <HStack>
              <Search
                searchValue={searchTerm}
                onSearch={filterData}
                hasAutoFocus={false}
                showSearchBarOnly
              />
              <Dropdown
                onChange={onFilter}
                isLoading={isLoadingCategories}
                isSearchable={false}
                options={categories}
                value={category}
              />
            </HStack>
            <Wrap>
              {filteredList.map(alert => (
                <WrapItem key={alert.id}>
                  <Box
                    borderWidth="0.5px"
                    pt={5}
                    boxShadow="md"
                    width="148px"
                    height="136px"
                    rounded="md"
                    _hover={{ boxShadow: 'xl' }}
                    borderColor={selectedAlert?.id === alert.id ? 'brand.blue' : 'none'}
                    onClick={() => setSelectedAlert(alert)}
                  >
                    <Center flexDir="column" gap={2} px={2}>
                      <Image src={alert.iconURL} height="40px" />
                      <Text align="center" variant="formInput" color="secondary.1000">
                        {getNameComponent(alert.name, 50)}
                      </Text>
                    </Center>
                  </Box>
                </WrapItem>
              ))}
            </Wrap>
          </Box>
        </CustomDrawerComponent>
      )}
    </>
  );
};
