import React from 'react';
import { Text } from '@chakra-ui/react';

import { ITimeSlot } from '../../../interface/schedule';
import { getOnCallHoursValue } from '../../../helpers/helpers.customrotations';

const OnCallDuration = (props: { inputSlots: ITimeSlot[] | undefined }) => {
  const { inputSlots } = props;
  const sumOfAllDurations =
    inputSlots?.reduce((sum: number, option: ITimeSlot) => sum + option.duration, 0) ?? 0;
  return <Text ml={2}>{getOnCallHoursValue(sumOfAllDurations)?.label}</Text>;
};

export default OnCallDuration;
