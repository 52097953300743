import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../../core/interfaces/IAppState';
import { useAnalyticsContext } from './analyticsContext';
import { setAnalyticsConfigs } from '../../../../core/actions/app/configs';
import { IAnalyticsConfig } from '../../../../core/interfaces/IAnalytics';

interface IProps extends Pick<IAppState, 'APP_CONFIG'> {
  setAnalyticsConfigs: (config: IAnalyticsConfig) => void;
}

const ContextWrapper: React.FC<IProps> = ({
  APP_CONFIG,
  setAnalyticsConfigs: setConfigs,
  children,
}) => {
  const { config, updateConfig } = useAnalyticsContext();

  useEffect(() => {
    const oldConfig = APP_CONFIG.analyticsConfigs;
    if (oldConfig) {
      const newConfig: Partial<IAnalyticsConfig> = {
        selectedDate: new Date(oldConfig.selectedDate),
        selectedLines: oldConfig.selectedLines,
      };

      if (oldConfig.timeRange) {
        newConfig.timeRange = {
          gt: new Date(oldConfig.timeRange.gt),
          lt: new Date(oldConfig.timeRange.lt),
        };
      }

      updateConfig(newConfig);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      setConfigs(config);
    };
  }, [config, setConfigs]);

  return <>{children}</>;
};

export default connect(
  ({ APP_CONFIG }: IAppState) => ({
    APP_CONFIG,
  }),
  {
    setAnalyticsConfigs,
  },
)(ContextWrapper);
