import { object, string } from 'yup';

export const GERRuleValidation = object().shape({
  description: string()
    .trim()
    .required('Description is required')
    .max(250, 'Max of 250 characters are allowed'),
  expression: string()
    .trim()
    .required('Expression is required')
    .max(1000, 'Max of 1000 characters are allowed'),
  service: object()
    .nullable()
    .required('Route to Service is Required')
    .shape({
      value: string().required('Service value is Required'),
      label: string().required('Service label is required!'),
    }),
});
