import { API } from '../api';
import Axios from 'axios';
import {
  IAuthCheck,
  IAuthOnBoard,
  IAuthActivateUser,
  ISendOTPResponse,
  IVerifyOTPResponse,
  IAuthenticationResponse,
} from '../interfaces/IAuth';
import { IOnboardReq } from '../interfaces/IAccount';

class AuthService {
  public activateUser = (key: string) =>
    Axios.post<IAuthActivateUser>(`${API.config.batman}/accounts/activate-user`, { key });

  public authenticateLogin = (token: string) =>
    Axios.post<IAuthenticationResponse>(
      `${API.config.batman}/accounts/authenticate?token=${token}`,
    );

  public requestResetPassword = (email: string) =>
    Axios.post(`${API.config.batman}/accounts/password/recover`, { email });

  public resetPassword = (password: string) =>
    Axios.post(
      `${API.config.batman}/accounts/password/reset-password`,
      { password },
      { withCredentials: true },
    );

  public checkEmail = (email: string) =>
    Axios.post<IAuthCheck>(`${API.config.endpoint}/accounts/register/check`, { email });

  public register = (email: string, password: string, captcha: string) =>
    Axios.post<IAuthCheck>(`${API.config.endpoint}/accounts/register-new`, {
      email,
      password,
      token: captcha,
    });

  public onBoard = (payload: IOnboardReq) => {
    return Axios.post<IAuthOnBoard>(`${API.config.batman}/accounts/onboard`, payload);
  };

  public createOrganization = (organization: string, trial: boolean) =>
    Axios.post(`${API.config.batman}/accounts/organization`, {
      organization_name: organization,
      trial: trial,
    });

  public sendOtp = (organizationID: string) =>
    Axios.post<ISendOTPResponse>(`${API.config.batman}/me/otp/send`, {
      organization_id: organizationID,
    });

  public verifyOtp = (code: string) =>
    Axios.post<IVerifyOTPResponse>(`${API.config.batman}/me/otp/verify`, { code });
}

export default AuthService;
