import Axios from 'axios';
import { IJProject, IJIssueType, IJStatusmap, IJServicemap } from '../interfaces/IJira';
import { API } from '../api';

class JiraServerService {
  private api = `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-server`;
  private organizationId = API.config.organizationId;
  private pJiraServerId = '';

  set jiraServerId(serverId: string) {
    this.pJiraServerId = serverId;
  }

  public getJiraConfigs() {
    return Axios.get(this.api).then(({ data }) => data);
  }

  public checkJiraServerConfig() {
    return Axios.get(`${this.api}/check`).then(({ data }) => data);
  }

  public getJiraProjects() {
    return Axios.get(`${this.api}/projects`).then(({ data }) => data);
  }

  public getJiraProjectIssues(projectId: string) {
    return Axios.get(`${this.api}/projects/${projectId}/issue-types`).then(({ data }) => data);
  }

  public postIncidentToJira(incidentId: string) {
    return Axios.post(
      `${API.config.batman}/organizations/${this.organizationId}/incidents/${incidentId}/actions/jira?type=server`,
      {},
    ).then(({ data }) => data);
  }

  public saveExtensionConfig(payload: {
    is_manual: boolean;
    project: IJProject;
    issue_type: IJIssueType;
    service_ids: string[];
    service_maps: IJServicemap[];
    status_maps: IJStatusmap[];
  }) {
    return Axios.put(`${this.api}/${this.pJiraServerId}`, payload).then(({ data }) => data);
  }

  public revoke() {
    return Axios.delete(`${this.api}/${this.pJiraServerId}`);
  }
}

export default JiraServerService;
