import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  AccordionButton as ChakraAccordionButton,
  Box,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';

type Props = {
  title: string;
  isExpanded: boolean;
  description?: string;
  isDisabled?: boolean;
  actionButton?: JSX.Element;
};

export const AccordionButton = ({
  title,
  isExpanded,
  description,
  isDisabled,
  actionButton,
}: Props) => {
  const titleColor = isDisabled ? 'secondary.400' : 'secondary.900';
  return (
    <ChakraAccordionButton>
      <VStack alignItems="flex-start" width="100%">
        <HStack p={0} width="100%" justifyContent="space-between">
          <HStack>
            <Text color={titleColor} variant="h3_800">
              {title}
            </Text>
            {!isDisabled && (
              <>
                {isExpanded ? (
                  <ChevronDownIcon width={6} height={6} color="brand.blue" />
                ) : (
                  <ChevronRightIcon width={6} height={6} color="brand.blue" />
                )}
              </>
            )}
          </HStack>
          {actionButton && <Box alignSelf="flex-end">{actionButton}</Box>}
        </HStack>
        {description && (
          <Text sx={{ color: 'secondary.700' }} variant="hint">
            {description}
          </Text>
        )}
      </VStack>
    </ChakraAccordionButton>
  );
};
