import React from 'react';
import { Search } from '.';
import { IService } from '../../../core/interfaces/IService';
import { IIntegration } from '../../../core/interfaces/IIntegration';
import { Theme, Grid, Para, TextButton, CardBlock } from 'uie/components';
import moment from 'moment';
import './index.css';
import ReactMarkdown from 'react-markdown';

const { theme } = Theme;
const icons = {
  escalationpolicy: '/icons/greys/escalation.svg',
  user: '/icons/greys/profile.svg',
  squad: '/icons/greys/squad.svg',
  integration: '/icons/greys/integrations.svg',
  service: '/icons/greys/services.svg',
};
const colorMap: any = {
  triggered: {
    b: `${theme.trigger.default}3e`,
    w: theme.trigger.default,
    f: `${theme.trigger.default}7e`,
  },
  acknowledged: {
    b: `${theme.acknowledge.default}3e`,
    w: theme.acknowledge.default,
    f: `${theme.acknowledge.default}7e`,
  },
  resolved: {
    b: `${theme.success.default}3e`,
    w: theme.success.default,
    f: `${theme.success.default}7e`,
  },
  suppressed: {
    b: `${theme.shades.smoke}3e`,
    w: theme.shades.smoke,
    f: `${theme.shades.smoke}7e`,
  },
};

export function renderIncident(this: Search, searchItem: any, index: number) {
  const incidentService = this.props.organization.services.s.find(
    (service: IService) => service.id === searchItem.service,
  );

  if (searchItem.postmortem) {
    return (
      <TextButton
        onClick={this.navigateToIncident(searchItem.incident_id, true)}
        className="search-history-map-object search-history-map-object--no-borders"
        buttonType="ghost"
        key={index}
      >
        <CardBlock
          borderStyle="sharp"
          animateOnHover
          className="mb-10"
          style={{ width: 'calc(100% - 32px)' }}
        >
          <Grid justifyContent="space-between">
            <Grid justifyContent="flex-start" alignItems="center">
              <Para color={theme.shades.cement}>{`#${searchItem.incident_id}`}</Para>
              <Para
                color={theme.shades.cement}
                fontSize={12}
                style={{
                  marginLeft: 4,
                }}
              >
                {moment(searchItem.created_at).format('YYYY-MM-DD hh:mm A')}
              </Para>
            </Grid>
          </Grid>
          <Grid className="search-incident-desc">
            <ReactMarkdown
              className="response-message-md"
              source={
                searchItem.postmortem.length > 100
                  ? `${searchItem.postmortem.slice(0, 100)}...`
                  : searchItem.postmortem
              }
              escapeHtml={true}
            />
          </Grid>
          <Grid type="column" style={{ marginTop: 8 }}>
            <Grid flexWrap="wrap" justifyContent="flex-start" alignItems="center">
              <Grid alignItems="center" style={{ marginRight: 8 }}>
                <img
                  color={theme.shades.smoke}
                  width={14}
                  src={icons.service}
                  alt="incident service"
                />
                <Para
                  color={theme.shades.cement}
                  fontSize={14}
                  style={{
                    marginLeft: 4,
                  }}
                >
                  {incidentService ? incidentService.name : 'delete Service'}
                </Para>
              </Grid>
            </Grid>
          </Grid>
        </CardBlock>
      </TextButton>
    );
  }
  const statusColor = colorMap[searchItem.status].b;

  const incidentIntegration = this.props.integrations.i.find(
    (integration: IIntegration) => integration._id === searchItem.integrationType,
  );

  const lastAssignedTo = (() => {
    const last = searchItem.assignedTo.slice(-1).pop();
    let name = '';
    switch (last!.type) {
      case 'user':
        const user = this.props.organization.users.u.find(u => u.id === last!.id);
        name = user ? `${user.first_name} ${user.last_name}` : '';
        break;
      case 'squad':
        const squad = this.props.organization.squads.s.find(s => s.id === last!.id);
        name = squad ? squad.name : '';
        break;
      case 'escalationpolicy':
        const escalationpolicy = this.props.organization.escalations.e.find(e => e.id === last!.id);
        name = escalationpolicy ? escalationpolicy.name : '';
        break;
    }

    return {
      name: name || 'Deleted',
      icon: (icons as any)[last!.type],
    };
  })();

  const tags: [string, string][] = searchItem.tags
    ? Object.entries(searchItem.tags).reduce((c: any, [key, v]: any) => {
        c.push([`${key} : ${v.value}`, v.color]);
        return c;
      }, [])
    : [];

  return (
    <TextButton
      onClick={this.navigateToIncident(searchItem._id, false)}
      className="search-history-map-object search-history-map-object--no-borders"
      buttonType="ghost"
      key={index}
    >
      <CardBlock
        borderStyle="sharp"
        animateOnHover
        className="mb-10"
        style={{ width: 'calc(100% - 32px)' }}
      >
        <Grid justifyContent="space-between">
          <Grid justifyContent="flex-start" alignItems="center">
            <Para color={theme.shades.cement}>{`#${searchItem._id}`}</Para>
            <Para
              color={theme.shades.cement}
              fontSize={12}
              style={{
                marginLeft: 4,
              }}
            >
              {moment(searchItem.timeOfCreation).format('YYYY-MM-DD hh:mm A')}
            </Para>
            {searchItem.event_count > 1 && (
              <Grid
                className="search-incident-tag"
                style={{
                  backgroundColor: theme.shades.white,
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Para fontSize={14}>{searchItem.event_count}</Para>
              </Grid>
            )}
          </Grid>
          <Grid
            style={{
              backgroundColor: statusColor,
              padding: 4,
              borderRadius: 4,
            }}
          >
            <Para
              fontSize={12}
              style={{
                textTransform: 'capitalize',
              }}
            >
              {searchItem.status}
            </Para>
          </Grid>
        </Grid>
        <Para fontWeight={400} className="search-incident-desc">
          {searchItem.message.length > 120
            ? `${searchItem.message.slice(0, 120)}...`
            : searchItem.message}
        </Para>
        <Grid type="column" style={{ marginTop: 8 }}>
          <Grid alignItems="center" justifyContent="space-between">
            <Grid flexWrap="wrap" justifyContent="flex-start" alignItems="center">
              <Grid alignItems="center" style={{ marginRight: 8 }}>
                <img
                  color={theme.shades.smoke}
                  width={14}
                  src={icons.service}
                  alt="incident service"
                />
                <Para
                  color={theme.shades.cement}
                  fontSize={14}
                  style={{
                    marginLeft: 4,
                  }}
                >
                  {incidentService ? incidentService.name : 'delete Service'}
                </Para>
              </Grid>
              <Grid alignItems="center" style={{ marginLeft: 8 }}>
                <img
                  color={theme.shades.smoke}
                  width={14}
                  src={icons.integration}
                  alt="integration"
                />
                <Para
                  color={theme.shades.cement}
                  fontSize={14}
                  style={{
                    marginLeft: 4,
                  }}
                >
                  {incidentIntegration ? incidentIntegration.type : 'Alert Source Deleted'}
                </Para>
              </Grid>
            </Grid>
            <Grid>
              <img
                color={theme.shades.smoke}
                width={14}
                src={lastAssignedTo.icon}
                alt="lastAssigned"
              />
              <Para
                color={theme.shades.cement}
                fontSize={14}
                style={{
                  marginLeft: 4,
                }}
              >
                {lastAssignedTo.name}
              </Para>
            </Grid>
          </Grid>
        </Grid>

        {tags.length > 0 && (
          <Grid
            style={{
              marginTop: 16,
              borderTop: '1px solid',
              borderColor: theme.shades.grey,
            }}
            flexWrap="wrap"
          >
            {tags.slice(0, 2).map(([value, color], i) => (
              <Grid
                key={i}
                className="search-incident-tagMore"
                style={{
                  backgroundColor: `${color}11`,
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Para fontSize={12} color={color} fontWeight={300}>
                  {value}
                </Para>
              </Grid>
            ))}
            {tags.slice(2).length > 0 && (
              <Grid
                className="search-incident-tagMore"
                style={{
                  backgroundColor: `${theme.shades.grey}7e`,
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Para fontSize={12}>+ {tags.slice(2).length} More</Para>
              </Grid>
            )}
          </Grid>
        )}
      </CardBlock>
    </TextButton>
  );
}
