import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M10.011.011A10.017 10.017 0 0 1 20 10c0 5.516-4.473 9.989-9.989 9.989S.021 15.516 0 10 4.473.011 10.011.011c-.022 0-.022 0 0 0zm6.262 8.605c.064-.277.106-.554.128-.852-.021-.298-.043-.596-.128-.873V3.866l-.362.064c-1.15.128-2.322.213-3.472.277a6.308 6.308 0 0 0-4.899 0c-1.15-.064-2.3-.149-3.45-.277l-.363-.043v3.024a3.005 3.005 0 0 0 .001 1.726c-.532.298-.958.958-.958 1.896-.064 2.109 1.384 4.175 1.384 4.175.149-.447.362-.895.639-1.278a6.328 6.328 0 0 0 2.066 1.981.366.366 0 0 0-.277.149c-.064-.064-.149-.128-.256-.128h-.107c-.192 0-.319.149-.341.341v1.108c0 .192.149.341.341.341h.128a.366.366 0 0 0 .277-.149c.064.085.17.149.277.149h.085a.366.366 0 0 0 .277-.149c.064.085.17.149.277.149h.021c.192 0 .319-.149.341-.341v-.958c1.363.49 2.833.49 4.196 0v.937c0 .192.149.341.341.362h.085a.366.366 0 0 0 .277-.149c.064.085.17.149.277.149h.085a.366.366 0 0 0 .277-.149c.064.085.17.149.277.149h.085a.336.336 0 0 0 .341-.341v-1.129a.369.369 0 0 0-.341-.362h-.128a.366.366 0 0 0-.277.149.364.364 0 0 0-.277-.149 6.384 6.384 0 0 0 2.087-1.981c.277.383.49.831.639 1.278.149.447 1.448-2.045 1.384-4.175a2.046 2.046 0 0 0-.957-1.896zm-6.262 3.237-1.235-1.235a3.636 3.636 0 0 0 1.235-1.427 3.636 3.636 0 0 0 1.235 1.427l-1.235 1.235zm-3.067-1.342c-1.491 0-2.726-1.214-2.726-2.726s1.214-2.726 2.726-2.726S9.67 6.273 9.67 7.785s-1.214 2.726-2.726 2.726zm6.112 0c-1.491 0-2.726-1.214-2.726-2.726s1.214-2.705 2.726-2.705a2.731 2.731 0 0 1 2.726 2.726c-.021 1.491-1.235 2.705-2.726 2.705zm0-3.855c.639 0 1.15.511 1.15 1.15 0 .639-.511 1.15-1.15 1.15s-1.15-.511-1.15-1.15c0-.639.511-1.15 1.15-1.15zm-6.112 0c.639 0 1.15.511 1.15 1.15 0 .639-.511 1.15-1.15 1.15s-1.15-.511-1.15-1.15c.021-.639.532-1.15 1.15-1.15z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#13274e',
      }}
    />
  </svg>
);

export default SvgComponent;
