import React, { useEffect, useRef, useState } from 'react';
import { Grid, Para, SpinLoader } from 'uie/components';
import {
  IAnalyticsPanel,
  IAnalyticsPanelData,
  IAnalyticsTabularRequest,
} from '../../../../../core/interfaces/IAnalytics';
import { getData, isCacheValid, renderChart, transformDataToChartData } from './helpers';
import { useAnalyticsContext } from '../analyticsContext';
import { exception } from '../../../../../core/exception';
import { convertDateString } from '../../../../../core/const/analytics';

interface IProps {
  panel: IAnalyticsPanel;
  data: IAnalyticsPanelData;
  updatePanelData: (
    panelID: string,
    data: any[],
    owner?: string,
    dateString?: string,
    timeRange?: { gt: string; lt: string },
  ) => void;
}

const DateConnected: React.FC<IProps> = ({ panel, data, updatePanelData }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const currentDate = useRef<Date>(new Date());

  const {
    config: { selectedDate },
    maps,
  } = useAnalyticsContext();

  useEffect(() => {
    if (panel.query.filter_by?.owner === data.owner && currentDate.current === selectedDate) return;

    const dateString = convertDateString(selectedDate);

    if (
      panel.query.filter_by?.owner === data.owner &&
      isCacheValid(data.updatedAt) &&
      dateString === data.dateString
    )
      return;

    currentDate.current = selectedDate;

    const query: IAnalyticsTabularRequest = {
      ...(panel.query as IAnalyticsTabularRequest),
      time_range: {
        gt: dateString,
        lt: dateString,
      },
    };

    (async () => {
      setLoading(true);

      try {
        const rawData = await getData(query);
        const chartData = transformDataToChartData(
          panel.kind,
          panel.chart,
          rawData,
          query.group_by,
          maps,
        );

        updatePanelData(panel.id, chartData, panel.query.filter_by?.owner, dateString);
      } catch (err: any) {
        exception.handle('E_GET_ANALYTICS_DATA', err);
      } finally {
        setLoading(false);
      }
    })();
  }, [panel, selectedDate, maps, currentDate, updatePanelData, data, panel.query.filter_by?.owner]);

  return (
    <div className="analytics__panel-content">
      <Para fontSize={20} style={{ paddingBottom: panel.chart === 'bar' ? 15 : 0 }}>
        {panel.name} - {selectedDate.toDateString().replace(/^\S+\s/, '')}
      </Para>
      {loading ? (
        <Grid style={{ height: '100%' }}>
          <SpinLoader style={{ margin: 'auto' }} />
        </Grid>
      ) : (
        renderChart(panel, data.data)
      )}
    </div>
  );
};

export default DateConnected;
