import { IAppState } from 'core/interfaces/IAppState';
import { FormikHelpers, useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import ReactSelect from 'react-select';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';

import { useCreateConfigForAccount } from '../../hooks/createConfigForAccount';
import { useGetIssuesForJiraProject } from '../../hooks/getAllIssueInJiraProjects';
import { useGetAllJiraAccounts } from '../../hooks/getAllJiraAccounts';
import { useGetJiraProjectsForAccount } from '../../hooks/getAllProjectOfAccount';
import { useGetJiraConfigDetailById } from '../../hooks/getDetailOfConfig';
import { useGetAllJiraAccountsOfTeam } from '../../hooks/getJiraAccOfTeam';
import { useGetNotConfiguredTeams } from '../../hooks/getNotConfiguredTeam';
import { useUpdateConfigForAccount } from '../../hooks/updateJiraConfigDetail';
import {
  IAddConfigForm,
  IAddConfigModalProps,
  StatusMapOrg,
} from '../../../../../../../../core/interfaces/IJiraNew';
export default function AddConfigModal(props: IAddConfigModalProps) {
  const allOrgTeams = useSelector((state: IAppState) => state.organization.teams);
  const [teamsOptions, setTeamsOptions] = React.useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const onsubmit = async (values: IAddConfigForm, formikHelpers: FormikHelpers<IAddConfigForm>) => {
    try {
      const token = jiraAccounts.find(el => el.id.toString() == values.account_id);
      const issue_name = jiraProjectsIssue.find(el => values.issue_id === el.id)?.name || '';
      const project = jiraProjects.find(el => el.id === values.project_id);
      const { name: project_name, key: project_key } = project!;
      const { owner_ids, ...restValues } = values;
      const payload = {
        token: token?.jira_client_key || '',
        issue_name,
        project_name,
        project_key,
        jira_link: '',
        ...restValues,
      };
      const payloadAllTeamConsidered = !(formik.values.owner_ids[0] === 'all')
        ? { ...payload, owner_ids: owner_ids, is_all_teams_configured: false }
        : { ...payload, is_all_teams_configured: true };
      if (props.configId && props.accountId) {
        await updateJiraConfig({
          config_id: props.configId,
          ...payloadAllTeamConsidered,
        });
      } else {
        await createDefaultConfig({
          ...payloadAllTeamConsidered,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  //formiks
  const addconfigSchema = Yup.object().shape({
    account_id: Yup.string().required('Jira Account is required'),
    project_id: Yup.string().required('Select a jira project'),
    issue_id: Yup.string().required('Select a jira issue'),
    creation_mode: Yup.string().required('Select a creation mode'),
    status_maps: Yup.array().when('issue_id', {
      is: (val: string) => val !== '',
      then: Yup.array().of(
        Yup.object().shape({
          jira_status: Yup.string().required('Select a status'),
          incident_status: Yup.string().required('Select a status'),
          jira_issue_status_id: Yup.string().required('Select a status'),
        }),
      ),
    }),
    owner_ids: Yup.array().min(1, 'Map the status').required('Select one team'),
  });
  const formik = useFormik<IAddConfigForm>({
    initialValues: {
      account_id: '',
      project_id: '',
      issue_id: '',
      creation_mode: 'manual',
      status_maps: [],
      owner_ids: [],
      is_all_teams_configured: false,
    },
    validationSchema: addconfigSchema,
    onSubmit: onsubmit,
    enableReinitialize: true,
    validateOnMount: true,
  });

  //hooks
  const {
    mutate: createDefaultConfig,
    isLoading: isCreatingConfig,
    isSuccess: isCreateAccountConfigSuccess,
  } = useCreateConfigForAccount();
  const {
    mutate: updateJiraConfig,
    isLoading: isUpdatingConfig,
    isSuccess: isUpdateAccConfigSuccess,
  } = useUpdateConfigForAccount();
  const {
    data: jiraAccounts = [],
    isError,
    isLoading: isFetchingJiraAcc,
  } = useGetAllJiraAccounts();
  const { data: jiraAccForTeams = [] } = useGetAllJiraAccountsOfTeam(formik.values.owner_ids[0]);
  const { data: unconfiguredTeams = [], isLoading: isFetchingUnconfiguredTeams } =
    useGetNotConfiguredTeams();

  const {
    data: jiraProjects = [],
    isError: isConfigError,
    isLoading: isConfigLoading,
  } = useGetJiraProjectsForAccount(formik.values.account_id || '');

  const { data: jiraProjectsIssue = [], isLoading: isProjectIssueFetching } =
    useGetIssuesForJiraProject(formik.values.account_id || '', formik.values.project_id || '');

  const { data: configDetail } = useGetJiraConfigDetailById(props.accountId, props.configId);

  //Data formating

  React.useEffect(() => {
    if (configDetail) {
      formik.setValues({
        account_id: configDetail.jira_cloud_account_id.toString(),
        project_id: configDetail?.project_id || '',
        issue_id: configDetail?.issue_id || '',
        creation_mode: configDetail?.creation_mode || 'manual',
        status_maps: configDetail?.status_maps || [],
        owner_ids: configDetail.is_all_teams_configured
          ? ['all']
          : configDetail?.owners?.map(el => el.owner_id.toString()) || [],
        is_all_teams_configured: configDetail?.is_all_teams_configured || false,
      });
    }
  }, [configDetail]);

  React.useEffect(() => {
    const totalTeams = [
      ...unconfiguredTeams,
      ...(configDetail?.owners.map(el => el.owner_id) || []),
    ];

    const unconfigedTeams =
      totalTeams?.filter(el => {
        return allOrgTeams.t.find(el2 => el2.id === el);
      }) || [];

    //in the case of edit we should show already integrated team too
    const teamsOptions = unconfigedTeams.map((el, index) => {
      return { label: allOrgTeams.t.find(el2 => el2.id === el)?.name || '', value: el };
    });
    if (props.totalConfigCount === 0 || (props.totalConfigCount === 1 && props.configId)) {
      teamsOptions.unshift({ label: 'All Teams', value: 'all' });
    }
    setTeamsOptions(teamsOptions);
  }, [unconfiguredTeams, configDetail, allOrgTeams]);

  React.useEffect(() => {
    if (isCreateAccountConfigSuccess || isUpdateAccConfigSuccess) {
      props.onClose();
    }
  }, [isCreateAccountConfigSuccess, isUpdateAccConfigSuccess]);

  const accountOptions = useMemo(
    () =>
      jiraAccForTeams.map((el, index) => {
        return { label: el.account_name, value: el.account_id };
      }),
    [jiraAccForTeams],
  );

  const projectOptions = useMemo(
    () =>
      jiraProjects.map((el, index) => ({
        label: el.name,
        value: el.id,
      })),
    [jiraProjects],
  );

  const projectIssueOptions = useMemo(
    () =>
      jiraProjectsIssue.map((el, index) => ({
        label: el.name,
        value: el.id,
      })),
    [jiraProjectsIssue],
  );
  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={20} fontWeight={500} fontStyle="normal">
            {props.accountId && props.configId ? 'Update' : 'Add'} Configuration
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {(isConfigLoading || isProjectIssueFetching || isFetchingJiraAcc) && (
              <Progress size="xs" isIndeterminate mb={2} />
            )}
            {!isFetchingUnconfiguredTeams ? (
              <>
                {jiraAccounts.length > 0 ? (
                  <form onSubmit={formik.handleSubmit} id="jira-addconfig-form">
                    <Grid
                      justifyContent="space-between"
                      columnGap={8}
                      templateColumns="repeat(2,1fr)"
                      rowGap={8}
                    >
                      <GridItem>
                        <Text fontSize={14} fontWeight={500} mb={1} color={'PoliceBlue'}>
                          Team
                        </Text>
                        <ReactSelect
                          placeholder="Select Team"
                          isMulti={false}
                          onChange={newValue => {
                            formik.setFieldValue('accountId', '');
                            formik.setFieldValue('owner_ids', [newValue?.value]);
                          }}
                          value={teamsOptions.find(el => el.value == formik.values.owner_ids[0])}
                          options={teamsOptions}
                        />

                        {formik.errors.owner_ids && formik.touched.owner_ids ? (
                          <Text fontSize={12} color="red.500" fontWeight={500}>
                            {formik.errors.owner_ids}{' '}
                          </Text>
                        ) : null}
                      </GridItem>
                      <GridItem>
                        <Text fontSize={14} fontWeight={500} mb={1} color={'PoliceBlue'}>
                          Jira Account
                        </Text>
                        <ReactSelect
                          placeholder="Select Account"
                          value={accountOptions.find(el => el.value == formik.values.account_id)}
                          onChange={newValue => {
                            formik.setFieldValue('account_id', newValue?.value);
                            formik.setFieldValue('project_id', '');
                          }}
                          options={accountOptions}
                        />
                        {formik.errors.account_id && formik.touched.account_id ? (
                          <Text fontSize={12} color="red.500" fontWeight={500}>
                            {formik.errors.account_id}{' '}
                          </Text>
                        ) : null}
                      </GridItem>
                      <GridItem>
                        <Text fontSize={14} fontWeight={500} mb={1} color={'PoliceBlue'}>
                          Jira Project
                        </Text>
                        <ReactSelect
                          placeholder="Select Jira Project"
                          value={projectOptions.find(el => el.value == formik.values.project_id)}
                          isMulti={false}
                          onChange={newValue => {
                            formik.setFieldValue('issue_id', '');
                            formik.setFieldValue('project_id', newValue?.value);
                          }}
                          options={projectOptions}
                        />
                        {formik.errors.project_id && formik.touched.owner_ids ? (
                          <Text fontSize={12} color="red.500" fontWeight={500}>
                            {formik.errors.project_id}{' '}
                          </Text>
                        ) : null}
                      </GridItem>
                      <GridItem>
                        <Text fontSize={14} fontWeight={500} mb={1} color={'PoliceBlue'}>
                          Jira Project Issue
                        </Text>
                        <ReactSelect
                          placeholder="Select Jira Issue"
                          value={projectIssueOptions.find(el => el.value == formik.values.issue_id)}
                          isMulti={false}
                          onChange={newValue => {
                            const statusToMap =
                              jiraProjectsIssue.find(el => newValue?.value === el.id)?.statuses ||
                              [];
                            formik.setFieldValue('issue_id', newValue?.value);
                            formik.setFieldValue(
                              'status_maps',
                              statusToMap.map(el => ({
                                jira_status: el.name,
                                incident_status: '',
                                jira_issue_status_id: el.id,
                              })),
                            );
                          }}
                          options={projectIssueOptions}
                        />
                        {formik.errors.issue_id && formik.touched.owner_ids ? (
                          <Text fontSize={12} color="red.500" fontWeight={500}>
                            {formik.errors.issue_id}{' '}
                          </Text>
                        ) : null}
                      </GridItem>
                    </Grid>
                    <Box mt={6}>
                      <Text fontWeight={500} fontSize={14} fontStyle="normal" mb={4}>
                        Add Incidents to Jira
                      </Text>
                      <RadioGroup value={formik.values.creation_mode}>
                        <Stack direction="column">
                          <Grid templateColumns="repeat(12,1fr)" rowGap={4}>
                            <GridItem colStart={1} colEnd={3}>
                              <Radio
                                value="manual"
                                name="creation_mode"
                                onChange={formik.handleChange}
                              >
                                <Text pl={4}>Manually </Text>
                              </Radio>
                            </GridItem>
                            <GridItem colStart={4} colEnd={11}>
                              <Text color={'DarkElectricBlue'} fontWeight={400} fontSize={14}>
                                (Create Jira tickets for incidents manually at Incident details
                                page)
                              </Text>
                            </GridItem>
                            <GridItem colSpan={2}></GridItem>
                            <GridItem colStart={1} colEnd={3}>
                              <Radio
                                value="automatic"
                                name="creation_mode"
                                onChange={formik.handleChange}
                              >
                                <Text pl={4}>Automatically </Text>
                              </Radio>
                            </GridItem>
                            <GridItem colStart={4} colEnd={11}>
                              <Text color={'DarkElectricBlue'} fontWeight={400} fontSize={14}>
                                (Create Jira tickets for incidents automatically)
                              </Text>
                            </GridItem>
                          </Grid>
                        </Stack>
                      </RadioGroup>
                    </Box>
                    {formik.values.status_maps.length > 0 ? (
                      <Box mt={6}>
                        <Text fontWeight={500} fontSize={14} fontStyle="normal" mb={4}>
                          Map Jira issue to Squadcast Jira
                        </Text>
                        <Grid templateColumns="repeat(12,1fr)" rowGap={4} alignItems="center">
                          {formik.values.status_maps.map((issue, index) => {
                            return (
                              <>
                                <GridItem colStart={1} colEnd={3}>
                                  <Text fontSize={14} fontWeight={500} mb={1} color={'PoliceBlue'}>
                                    {issue.jira_status}
                                  </Text>
                                </GridItem>
                                <GridItem gridAutoFlow="row" colStart={3} colEnd={7}>
                                  <Select
                                    size="lg"
                                    placeholder="Select Incident status"
                                    name={`status_maps[${index}]`}
                                    onChange={e =>
                                      formik.setFieldValue(`status_maps[${index}]`, {
                                        jira_status: issue.jira_status,
                                        incident_status: e.target.value,
                                        jira_issue_status_id: issue.jira_issue_status_id,
                                      })
                                    }
                                    value={formik.values.status_maps[index]?.incident_status}
                                  >
                                    <option value="triggered">Triggered</option>
                                    <option value="acknowledged">Acknowledged</option>
                                    <option value="resolved">Resolved</option>
                                  </Select>
                                  {formik.errors?.status_maps?.[index] ? (
                                    <Text fontSize={12} color="red.500" fontWeight={500}>
                                      {
                                        (formik.errors.status_maps[index] as StatusMapOrg)
                                          ?.incident_status
                                      }
                                    </Text>
                                  ) : null}
                                </GridItem>
                              </>
                            );
                          })}
                        </Grid>
                      </Box>
                    ) : null}
                  </form>
                ) : (
                  <Box>
                    <Text>
                      No Jira Account Created Yet!. First Create Jira Accounts To Configure Them.
                    </Text>
                  </Box>
                )}
              </>
            ) : (
              <Stack direction="row" justifyContent={'center'} height={'100%'} spacing={4}>
                <Spinner size="xl" />
              </Stack>
            )}
          </ModalBody>
          <ModalFooter marginTop={12}>
            <Button
              mb={3}
              form="jira-addconfig-form"
              color={'#FFFFFF'}
              background={'primary.default'}
              type="submit"
              isLoading={isCreatingConfig || isUpdatingConfig}
              disabled={!formik.isValid || formik.isSubmitting}
              loadingText={isCreatingConfig ? 'Saving' : 'Updating'}
            >
              {props.accountId && props.configId ? 'Update' : 'Save'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
