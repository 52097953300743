import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { ISCalendarEvent, ISCalendar } from '../../../../../core/interfaces/ICalendar';
import { Row } from '@squadcast/alchemy-ui/carbon';
import './index.css';
import { ISquad } from '../../../../../core/interfaces/ISquad';
import { Grid, Para, Pill, Theme, Tooltip } from 'uie/components';
import { InfoIcon, ProfileIcon, TeamIcon, UnVerifiedIcon } from '../../../../../icons';
import { UserAccessContextValue, withUserAccess } from 'core/userAccess/UserAccessContext';

const { theme } = Theme;

type IMix = Pick<IAppState, 'organization'> &
  Pick<
    ISCalendarEvent,
    'start_time' | 'end_time' | 'user_ids' | 'squad_ids' | 'calendar_id' | 'name'
  >;

interface IProps extends IMix {
  hide: (reload: boolean) => () => void;
  editEvent: () => void;
  userAccess: UserAccessContextValue;
}

class OnCallModal extends Component<IProps> {
  public oUsers = this.props.organization.users.u.reduce((c: any, n) => {
    c[n.id] = {
      _id: n.id,
      name: `${n.first_name} ${n.last_name}`,
      emailVerified: n.email_verified,
      phoneVerified: n.phone_verified,
      type: 'u',
    };
    return c;
  }, {});

  public oSquads = this.props.organization.squads.s.reduce((c: any, n) => {
    c[n.id] = {
      _id: n.id,
      name: n.name,
      type: 's',
      squad: n,
    };
    return c;
  }, {});

  public oCalender: { [key: string]: ISCalendar } = this.props.organization.calendar.c.reduce(
    (c: any, n) => {
      c[n.id] = n;
      return c;
    },
    {},
  );

  public calendar = this.oCalender[this.props.calendar_id];

  public render() {
    const orgConfig = this.props.organization.currentOrg.o?.config;
    const editSchedulesDisabled = !!orgConfig?.disable_edit_old_schedules;

    return (
      <div>
        <div className="clearfix">
          <div className="float-left">
            <Grid alignItems="center">
              <h1 className="modal-container-heading">
                On Call {this.props.name && `(${this.props.name})`}
              </h1>
              <Pill className="ml-10">
                <Grid alignItems="center">
                  <TeamIcon height={16} width={16} />
                  <Para className="ml-5">{this.props.organization.selectedTeam.team?.name}</Para>
                </Grid>
              </Pill>
            </Grid>
          </div>
          <div className="float-right" style={{ paddingRight: 25, paddingTop: 5 }}>
            <Row>
              {this.props.userAccess.hasUpdateAccess('schedules', false) && (
                <img
                  className="cursor-pointer mr-10"
                  src="/icons/edit.svg"
                  onClick={this.props.editEvent}
                  alt="edit"
                />
              )}
            </Row>
          </div>
        </div>
        <div className="mt-10">
          <div>
            <div
              className="on-call-calendar"
              style={{
                backgroundColor: `${this.calendar.colour}19`,
                borderLeft: `3px solid ${this.calendar.colour}`,
              }}
            >
              {this.calendar.name}
            </div>
            {this.props.user_ids &&
              this.props.user_ids.length === 0 &&
              this.props.squad_ids &&
              this.props.squad_ids.length === 0 && (
                <div>
                  <p className="modal-container-sub-heading">No assignees!</p>
                </div>
              )}

            {this.props.user_ids && this.props.user_ids.length > 0 && (
              <div>
                <p className="modal-container-sub-heading">Users</p>
                <div style={{ marginLeft: -5 }}>
                  <Row alignItems="center" flexWrap="wrap">
                    {this.props.user_ids.map((userIds: string, index) =>
                      !(this.oUsers[userIds]?.emailVerified ?? false) ||
                      !(this.oUsers[userIds]?.phoneVerified ?? false) ? (
                        <Tooltip
                          key={index}
                          padding="8px"
                          offset={{ top: '-90px', left: '0' }}
                          width="300px"
                          label={
                            this.oUsers[userIds]?.emailVerified ?? false
                              ? 'User needs to verify phone number to get SMS and Phone notifications'
                              : 'Unverified users detected. Users need to verify email to start receiving notifications.'
                          }
                        >
                          <Pill className="ma-5">
                            <Grid alignItems="center">
                              <UnVerifiedIcon height={14} width={14} />
                              <InfoIcon
                                className="ml-5"
                                height={14}
                                width={14}
                                stroke={theme.shades.grey}
                              />
                              <Para fontSize={14} className="ml-5">
                                {this.oUsers[userIds]?.name ?? '(Deleted User)'}
                              </Para>
                            </Grid>
                          </Pill>
                        </Tooltip>
                      ) : (
                        <Pill key={index} className="ma-5">
                          <Grid alignItems="center">
                            <ProfileIcon height={14} width={14} />
                            <Para fontSize={14} className="ml-5">
                              {this.oUsers[userIds]?.name ?? '(Deleted User)'}
                            </Para>
                          </Grid>
                        </Pill>
                      ),
                    )}
                  </Row>
                </div>
              </div>
            )}

            {this.props.squad_ids && this.props.squad_ids.length > 0 && (
              <div>
                <p className="modal-container-sub-heading">Squads</p>
                {this.props.squad_ids.map((squadIds: string, index) => (
                  <div key={index} className="pt-10">
                    <p className="m-0 on-call-squad-label">
                      {this.oSquads[squadIds]?.name ?? '(Deleted Squad)'}
                    </p>
                    {this.oSquads[squadIds] && (
                      <div className="tags pt-20 pa-5">
                        <Row alignItems="center" flexWrap="wrap">
                          {(this.oSquads[squadIds].squad as ISquad).members.map(
                            (userId, userIndex) =>
                              !(this.oUsers[userId]?.emailVerified ?? false) ||
                              !(this.oUsers[userId]?.phoneVerified ?? false) ? (
                                <Tooltip
                                  key={userIndex}
                                  padding="8px"
                                  offset={{ top: '-90px', left: '0' }}
                                  width="300px"
                                  label={
                                    this.oUsers[userId]?.emailVerified ?? false
                                      ? 'User needs to verify phone number to get SMS and Phone notifications'
                                      : 'Unverified users detected. Users need to verify email to start receiving notifications.'
                                  }
                                >
                                  <Pill className="ma-5">
                                    <Grid alignItems="center">
                                      <UnVerifiedIcon height={14} width={14} />
                                      <InfoIcon
                                        className="ml-5"
                                        height={14}
                                        width={14}
                                        stroke={theme.shades.grey}
                                      />
                                      <Para fontSize={14} className="ml-5">
                                        {this.oUsers[userId]?.name ?? '(Deleted User)'}
                                      </Para>
                                    </Grid>
                                  </Pill>
                                </Tooltip>
                              ) : (
                                <Pill key={index} className="ma-5">
                                  <Grid alignItems="center">
                                    <ProfileIcon height={14} width={14} />
                                    <Para fontSize={14} className="ml-5">
                                      {this.oUsers[userId]?.name ?? '(Deleted User)'}
                                    </Para>
                                  </Grid>
                                </Pill>
                              ),
                          )}
                        </Row>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ organization }: IAppState) => ({ organization }))(
  withUserAccess(OnCallModal),
);
