import { RESET_STATE } from '../../const/init';
import { REQUEST_ORG_TEAMS_SUCCESS } from '../../const/organization';
import {
  REQUEST_ORG_SELECTED_TEAM_CHANGE,
  REQUEST_ORG_SELECTED_TEAM_CHANGE__TEAM_UPDATE,
  REQUEST_ORG_SELECTED_TEAM_CHILDREN,
  REQUEST_ORG_SELECTED_TEAM_CHILDREN_FAIL,
  REQUEST_ORG_SELECTED_TEAM_CHILDREN_SUCCESS,
} from '../../const/selectedTeam';
import { ITeam } from '../../interfaces/ITeams';
import { INITIAL_STATE } from '../state';

const initialCurrentOrg = INITIAL_STATE.organization.selectedTeam;
let organizationTeams: ITeam[] = [];

const organizationSelectedTeamChange = (state = initialCurrentOrg, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{
          action: RESET_STATE,
          refresh: 0,
          team: null,
          children: null,
          teamId: '',
        },
      };
    case REQUEST_ORG_TEAMS_SUCCESS: {
      organizationTeams = action.payload.teams;
      if (state.teamId !== '') {
        return {
          ...state,
          ...{
            action: REQUEST_ORG_SELECTED_TEAM_CHANGE__TEAM_UPDATE,
            team: organizationTeams.find(t => t.id === state.teamId),
          },
        };
      }
      return state;
    }
    case REQUEST_ORG_SELECTED_TEAM_CHANGE:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_SELECTED_TEAM_CHANGE,
          team: organizationTeams.find(t => t.id === action.payload.teamId),
          teamId: action.payload.teamId,
        },
      };
    case REQUEST_ORG_SELECTED_TEAM_CHILDREN:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_SELECTED_TEAM_CHILDREN,
        },
      };
    case REQUEST_ORG_SELECTED_TEAM_CHILDREN_SUCCESS:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_SELECTED_TEAM_CHILDREN_SUCCESS,
          refresh: new Date().getTime(),
          children: action.payload.children,
        },
      };
    case REQUEST_ORG_SELECTED_TEAM_CHILDREN_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_SELECTED_TEAM_CHILDREN_FAIL,
          children: null,
        },
      };
    default:
      return state;
  }
};

export default organizationSelectedTeamChange;
