import React, { ReactNode, useEffect, useState } from 'react';
import { Grid, IconButton, Para, TextButton, Theme } from 'uie/components';
import { CloseIcon } from 'icons';
import { useSecondaryFilters } from '../context';
import { SecondaryFiltersKeys } from '../types';

const { theme } = Theme;

type Props = {
  selected: string[];
  filterKey: SecondaryFiltersKeys;
  label: string;
  onClose?: () => void;
  children: ReactNode;
  isPrimaryButton?: boolean;
  isInitializedOpen?: boolean;
};

const DropdownContainer: React.FC<Props> = ({
  selected,
  label,
  children,
  onClose,
  filterKey,
  isPrimaryButton,
  isInitializedOpen,
}) => {
  const { removeActiveFilters } = useSecondaryFilters();

  const onRemoveFilter = () => {
    removeActiveFilters(filterKey);
  };

  const [isOpen, setIsOpen] = useState<boolean>(isInitializedOpen || false);
  const toggleOpen = () => {
    if (isOpen) onClose?.();

    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isInitializedOpen) {
      setIsOpen(isInitializedOpen);
    }
  }, [isInitializedOpen]);

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      onRemove={onRemoveFilter}
      isButton={isPrimaryButton}
      target={
        <TextButton
          onClick={toggleOpen}
          style={{
            background: theme.shades.white,
            minWidth: 150,
            padding: '8px 10px',
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Para fontSize={14}>
            <span style={{ fontWeight: 600 }}>{label}</span>
            {selected.length !== 0 && (
              <>
                : {selected[0]}
                {selected.length - 1 !== 0 && (
                  <span
                    style={{
                      borderRadius: '8px',
                      fontSize: '12px',
                      backgroundColor: theme.shades.smoke,
                      padding: '0 6px',
                      marginLeft: '8px',
                      top: '-1px',
                      position: 'relative',
                    }}
                  >
                    +{selected.length - 1}
                  </span>
                )}
              </>
            )}
          </Para>

          <img className="ml-10" width={10} src="/icons/dropdown.svg" alt="down" />
        </TextButton>
      }
    >
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { closeContainerDropdown: toggleOpen });
        }
        return child;
      })}
    </Dropdown>
  );
};

const Menu = (props: JSX.IntrinsicElements['div']) => {
  const shadow = theme.shades.lightGrey;
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
        fontSize: 14,
      }}
      {...props}
    />
  );
};
const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
interface DropdownProps {
  readonly isOpen: boolean;
  readonly isButton: boolean | undefined;
  readonly target: ReactNode;
  readonly onClose: () => void;
  children: ReactNode;
  onRemove: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  children,
  isOpen,
  target,
  isButton,
  onClose,
  onRemove,
}) => (
  <div
    style={{ position: 'relative', border: `1px solid ${theme.shades.lightGrey}`, borderRadius: 4 }}
  >
    <Grid style={{ alignItems: 'center' }}>
      {target}
      {!isButton && (
        <IconButton base="24px" onClick={onRemove}>
          <CloseIcon />
        </IconButton>
      )}
    </Grid>
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

export default DropdownContainer;
