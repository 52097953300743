import * as React from 'react';
import { SVGProps } from 'react';

const SvgElecardLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Elecard_Logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.Elecard_Logo-20x20_svg__st0{fill:#107ebc}'}</style>
    <path
      className="Elecard_Logo-20x20_svg__st0"
      d="M5.6 4.7h-4L2.1.6h4zM13 4.7H9L9.5.6h4zM4.7 12h-4l.5-4h4zM12.1 12h-4l.5-4h4zM19.5 12h-4l.5-4h4zM4 19.4H0l.5-4.1h4zM11.4 19.4h-4l.5-4.1h4zM18.8 19.4h-4l.5-4.1h4z"
    />
  </svg>
);

export default SvgElecardLogo20X20;
