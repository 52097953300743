import { WarningTwoIcon } from '@chakra-ui/icons';
import { Center, Icon } from '@chakra-ui/react';
import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Center>
          <h1>
            <Icon as={WarningTwoIcon} /> Sorry.. there seems to be some issue here. Please try
            reloading the screen or try after sometime
          </h1>
        </Center>
      );
    }

    return this.props.children;
  }
}
