import * as Types from './types';

import { useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from './fetcher/index';
export type CreateScheduleMutationVariables = Types.Exact<{
  input: Types.NewSchedule;
}>;

export type CreateScheduleMutation = {
  __typename?: 'Mutation';
  createSchedule: { __typename?: 'Schedule'; ID: number };
};

export type PauseScheduleMutationVariables = Types.Exact<{
  ID: Types.Scalars['Int'];
}>;

export type PauseScheduleMutation = {
  __typename?: 'Mutation';
  pauseSchedule: { __typename?: 'Schedule'; ID: number };
};

export type ResumeScheduleMutationVariables = Types.Exact<{
  ID: Types.Scalars['Int'];
}>;

export type ResumeScheduleMutation = {
  __typename?: 'Mutation';
  resumeSchedule: { __typename?: 'Schedule'; ID: number };
};

export type CloneScheduleMutationVariables = Types.Exact<{
  ID: Types.Scalars['Int'];
}>;

export type CloneScheduleMutation = {
  __typename?: 'Mutation';
  cloneSchedule: { __typename?: 'Schedule'; ID: number };
};

export type DeleteScheduleMutationVariables = Types.Exact<{
  ID: Types.Scalars['Int'];
  input?: Types.InputMaybe<
    Array<Types.EscalationPolicyToReplacementMapping> | Types.EscalationPolicyToReplacementMapping
  >;
}>;

export type DeleteScheduleMutation = {
  __typename?: 'Mutation';
  deleteSchedule: {
    __typename?: 'DeleteScheduleResponse';
    schedule: { __typename?: 'Schedule'; ID: number };
  };
};

export type UpdateScheduleMutationVariables = Types.Exact<{
  ID: Types.Scalars['Int'];
  input: Types.UpdateSchedule;
}>;

export type UpdateScheduleMutation = {
  __typename?: 'Mutation';
  updateSchedule: { __typename?: 'Schedule'; ID: number };
};

export type CreateICalUrlMutationVariables = Types.Exact<{
  scheduleID: Types.Scalars['Int'];
  myOncallOnly?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type CreateICalUrlMutation = { __typename?: 'Mutation'; createICalURL: string };

export type RefreshICalUrlMutationVariables = Types.Exact<{
  scheduleID: Types.Scalars['Int'];
  myOncallOnly?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type RefreshICalUrlMutation = { __typename?: 'Mutation'; refreshICalURL: string };

export type DeleteICalUrlMutationVariables = Types.Exact<{
  scheduleID: Types.Scalars['Int'];
  myOncallOnly?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type DeleteICalUrlMutation = { __typename?: 'Mutation'; deleteICalURL: string };

export type SaveOverrideMutationVariables = Types.Exact<{
  input: Types.NewScheduleOverride;
}>;

export type SaveOverrideMutation = {
  __typename?: 'Mutation';
  createOverride: {
    __typename?: 'ScheduleOverride';
    ID: number;
    teamID: string;
    scheduleID: number;
    startTime: any;
    endTime: any;
    reason?: string | null;
  };
};

export type UpdateOverrideMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  input: Types.UpdateScheduleOverride;
}>;

export type UpdateOverrideMutation = {
  __typename?: 'Mutation';
  updateOverride: {
    __typename?: 'ScheduleOverride';
    ID: number;
    teamID: string;
    scheduleID: number;
    startTime: any;
    endTime: any;
    reason?: string | null;
  };
};

export type DeleteOverrideMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;

export type DeleteOverrideMutation = {
  __typename?: 'Mutation';
  deleteOverride: {
    __typename?: 'ScheduleOverride';
    ID: number;
    teamID: string;
    scheduleID: number;
  };
};

export const CreateScheduleDocument = `
    mutation createSchedule($input: NewSchedule!) {
  createSchedule(input: $input) {
    ID
  }
}
    `;
export const useCreateScheduleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateScheduleMutation,
    TError,
    CreateScheduleMutationVariables,
    TContext
  >,
) =>
  useMutation<CreateScheduleMutation, TError, CreateScheduleMutationVariables, TContext>(
    ['createSchedule'],
    (variables?: CreateScheduleMutationVariables) =>
      fetcher<CreateScheduleMutation, CreateScheduleMutationVariables>(
        CreateScheduleDocument,
        variables,
      )(),
    options,
  );
export const PauseScheduleDocument = `
    mutation pauseSchedule($ID: Int!) {
  pauseSchedule(ID: $ID) {
    ID
  }
}
    `;
export const usePauseScheduleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PauseScheduleMutation,
    TError,
    PauseScheduleMutationVariables,
    TContext
  >,
) =>
  useMutation<PauseScheduleMutation, TError, PauseScheduleMutationVariables, TContext>(
    ['pauseSchedule'],
    (variables?: PauseScheduleMutationVariables) =>
      fetcher<PauseScheduleMutation, PauseScheduleMutationVariables>(
        PauseScheduleDocument,
        variables,
      )(),
    options,
  );
export const ResumeScheduleDocument = `
    mutation resumeSchedule($ID: Int!) {
  resumeSchedule(ID: $ID) {
    ID
  }
}
    `;
export const useResumeScheduleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ResumeScheduleMutation,
    TError,
    ResumeScheduleMutationVariables,
    TContext
  >,
) =>
  useMutation<ResumeScheduleMutation, TError, ResumeScheduleMutationVariables, TContext>(
    ['resumeSchedule'],
    (variables?: ResumeScheduleMutationVariables) =>
      fetcher<ResumeScheduleMutation, ResumeScheduleMutationVariables>(
        ResumeScheduleDocument,
        variables,
      )(),
    options,
  );
export const CloneScheduleDocument = `
    mutation cloneSchedule($ID: Int!) {
  cloneSchedule(ID: $ID) {
    ID
  }
}
    `;
export const useCloneScheduleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CloneScheduleMutation,
    TError,
    CloneScheduleMutationVariables,
    TContext
  >,
) =>
  useMutation<CloneScheduleMutation, TError, CloneScheduleMutationVariables, TContext>(
    ['cloneSchedule'],
    (variables?: CloneScheduleMutationVariables) =>
      fetcher<CloneScheduleMutation, CloneScheduleMutationVariables>(
        CloneScheduleDocument,
        variables,
      )(),
    options,
  );
export const DeleteScheduleDocument = `
    mutation deleteSchedule($ID: Int!, $input: [EscalationPolicyToReplacementMapping!]) {
  deleteSchedule(ID: $ID, input: $input) {
    schedule {
      ID
    }
  }
}
    `;
export const useDeleteScheduleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteScheduleMutation,
    TError,
    DeleteScheduleMutationVariables,
    TContext
  >,
) =>
  useMutation<DeleteScheduleMutation, TError, DeleteScheduleMutationVariables, TContext>(
    ['deleteSchedule'],
    (variables?: DeleteScheduleMutationVariables) =>
      fetcher<DeleteScheduleMutation, DeleteScheduleMutationVariables>(
        DeleteScheduleDocument,
        variables,
      )(),
    options,
  );
export const UpdateScheduleDocument = `
    mutation updateSchedule($ID: Int!, $input: UpdateSchedule!) {
  updateSchedule(ID: $ID, input: $input) {
    ID
  }
}
    `;
export const useUpdateScheduleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateScheduleMutation,
    TError,
    UpdateScheduleMutationVariables,
    TContext
  >,
) =>
  useMutation<UpdateScheduleMutation, TError, UpdateScheduleMutationVariables, TContext>(
    ['updateSchedule'],
    (variables?: UpdateScheduleMutationVariables) =>
      fetcher<UpdateScheduleMutation, UpdateScheduleMutationVariables>(
        UpdateScheduleDocument,
        variables,
      )(),
    options,
  );
export const CreateICalUrlDocument = `
    mutation createICalURL($scheduleID: Int!, $myOncallOnly: Boolean) {
  createICalURL(scheduleID: $scheduleID, myOncallOnly: $myOncallOnly)
}
    `;
export const useCreateICalUrlMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateICalUrlMutation,
    TError,
    CreateICalUrlMutationVariables,
    TContext
  >,
) =>
  useMutation<CreateICalUrlMutation, TError, CreateICalUrlMutationVariables, TContext>(
    ['createICalURL'],
    (variables?: CreateICalUrlMutationVariables) =>
      fetcher<CreateICalUrlMutation, CreateICalUrlMutationVariables>(
        CreateICalUrlDocument,
        variables,
      )(),
    options,
  );
export const RefreshICalUrlDocument = `
    mutation refreshICalURL($scheduleID: Int!, $myOncallOnly: Boolean) {
  refreshICalURL(scheduleID: $scheduleID, myOncallOnly: $myOncallOnly)
}
    `;
export const useRefreshICalUrlMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RefreshICalUrlMutation,
    TError,
    RefreshICalUrlMutationVariables,
    TContext
  >,
) =>
  useMutation<RefreshICalUrlMutation, TError, RefreshICalUrlMutationVariables, TContext>(
    ['refreshICalURL'],
    (variables?: RefreshICalUrlMutationVariables) =>
      fetcher<RefreshICalUrlMutation, RefreshICalUrlMutationVariables>(
        RefreshICalUrlDocument,
        variables,
      )(),
    options,
  );
export const DeleteICalUrlDocument = `
    mutation deleteICalURL($scheduleID: Int!, $myOncallOnly: Boolean) {
  deleteICalURL(scheduleID: $scheduleID, myOncallOnly: $myOncallOnly)
}
    `;
export const useDeleteICalUrlMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteICalUrlMutation,
    TError,
    DeleteICalUrlMutationVariables,
    TContext
  >,
) =>
  useMutation<DeleteICalUrlMutation, TError, DeleteICalUrlMutationVariables, TContext>(
    ['deleteICalURL'],
    (variables?: DeleteICalUrlMutationVariables) =>
      fetcher<DeleteICalUrlMutation, DeleteICalUrlMutationVariables>(
        DeleteICalUrlDocument,
        variables,
      )(),
    options,
  );
export const SaveOverrideDocument = `
    mutation saveOverride($input: NewScheduleOverride!) {
  createOverride(input: $input) {
    ID
    teamID
    scheduleID
    startTime
    endTime
    reason
  }
}
    `;
export const useSaveOverrideMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaveOverrideMutation,
    TError,
    SaveOverrideMutationVariables,
    TContext
  >,
) =>
  useMutation<SaveOverrideMutation, TError, SaveOverrideMutationVariables, TContext>(
    ['saveOverride'],
    (variables?: SaveOverrideMutationVariables) =>
      fetcher<SaveOverrideMutation, SaveOverrideMutationVariables>(
        SaveOverrideDocument,
        variables,
      )(),
    options,
  );
export const UpdateOverrideDocument = `
    mutation updateOverride($id: Int!, $input: UpdateScheduleOverride!) {
  updateOverride(ID: $id, input: $input) {
    ID
    teamID
    scheduleID
    startTime
    endTime
    reason
  }
}
    `;
export const useUpdateOverrideMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateOverrideMutation,
    TError,
    UpdateOverrideMutationVariables,
    TContext
  >,
) =>
  useMutation<UpdateOverrideMutation, TError, UpdateOverrideMutationVariables, TContext>(
    ['updateOverride'],
    (variables?: UpdateOverrideMutationVariables) =>
      fetcher<UpdateOverrideMutation, UpdateOverrideMutationVariables>(
        UpdateOverrideDocument,
        variables,
      )(),
    options,
  );
export const DeleteOverrideDocument = `
    mutation deleteOverride($id: Int!) {
  deleteOverride(ID: $id) {
    ID
    teamID
    scheduleID
  }
}
    `;
export const useDeleteOverrideMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteOverrideMutation,
    TError,
    DeleteOverrideMutationVariables,
    TContext
  >,
) =>
  useMutation<DeleteOverrideMutation, TError, DeleteOverrideMutationVariables, TContext>(
    ['deleteOverride'],
    (variables?: DeleteOverrideMutationVariables) =>
      fetcher<DeleteOverrideMutation, DeleteOverrideMutationVariables>(
        DeleteOverrideDocument,
        variables,
      )(),
    options,
  );
