import { Flex } from '@chakra-ui/layout';
import FormField from 'library/molecules/FormField/FormField';
import { TAG_VARIANTS } from 'library/atoms/Tag/Tag';
import TagStack from 'library/atoms/Tag/TagStack';
import { useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { SliOption, SloAffectingAction, SloOption } from 'views/main/organization/workflow/types';

import { useSloDetails, useSloList } from './useSloList';
import FrameWorkDropdown from 'library/atoms/FrameworkDropdown';

interface Props {
  serviceId?: string;
}
const SloAffectingForm = (props: Props) => {
  const { setIsDirty } = useWorkflowFormContext();
  const { formState, setValue, watch } = useFormContext<SloAffectingAction>();
  const { append, remove } = useFieldArray<SloAffectingAction>({ name: 'data.sli' });
  const slo = watch('data.slo');
  const slis = watch('data.sli') ?? [];
  const { data: sloListData, isLoading: isSloLoading } = useSloList();
  const { data: sloDetails, isLoading: isDetailsLoading } = useSloDetails(slo?.id);

  const sloOptions: SloOption[] = useMemo(
    () =>
      sloListData?.slos
        ?.filter(slo =>
          props.serviceId ? slo.service_ids.some(id => id === props.serviceId) : true,
        )
        ?.filter(slo => slo.is_active)
        ?.map(slo => ({
          id: slo.id,
          name: slo.name,
        })) || [],
    [sloListData?.slos, props.serviceId],
  );

  const sliOptions: SliOption[] = useMemo(() => {
    if (sloDetails?.slo) {
      return sloDetails.slo.slis.map(sliName => ({ label: sliName, value: sliName }));
    }
    return [];
  }, [sloDetails?.slo.slis]);

  const selectedSli = slis?.map(f => f.value);
  const remainingSliOptions = sliOptions.filter(f => !selectedSli.includes(f.value));

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <Flex px={6} pt={4} flexDirection="column">
      <FormField label="Select SLO" error={formState.errors.data?.slo?.message}>
        <FrameWorkDropdown<SloOption>
          options={sloOptions}
          isLoading={isSloLoading}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id.toString()}
          onChange={o => {
            setValue('data.slo', o!);
            remove();
          }}
          value={slo}
        />
      </FormField>

      <FormField label="Select SLIs" error={formState.errors.data?.sli?.message}>
        <FrameWorkDropdown<SliOption>
          options={remainingSliOptions}
          isLoading={isDetailsLoading}
          onChange={o => append(o as SliOption)}
          value={null}
        />
      </FormField>
      <TagStack
        tags={slis}
        variant={TAG_VARIANTS.PLAIN}
        onClose={(index, item) => remove(index)}
        defaultLength={slis.length}
      />
    </Flex>
  );
};

export default SloAffectingForm;
