import { useBoolean } from '@chakra-ui/react';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { CrownSmallIcon } from 'icons';
import { ListingButton } from 'library/atoms';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { INCIDENT_ACTION_ID } from '../common/enums';
import { getIncidentDetailsPath } from '../common/util';

type Props = {
  organization: Pick<IAppState, 'organization'>;
  incidentId: string;
  isDisabled: boolean;
  hasPostmortem: boolean;
};

const FEAT_NAME = 'postmortem';

export const PostmortemCTA: FC<Props> = ({
  organization,
  incidentId,
  hasPostmortem,
  isDisabled,
}) => {
  const org = { organization } as unknown as Pick<IAppState, 'organization'>;
  const [showModal, setShowModal] = useBoolean(false);
  const history = useHistory();
  const isFeatureUnAvailable = organization
    ? BillingService.isFeatureDisabled(org, FEAT_NAME)
    : false;

  const onClick = () => {
    isFeatureUnAvailable
      ? setShowModal.on()
      : history.push(getIncidentDetailsPath(incidentId, { action: FEAT_NAME }));
  };

  const userAccess = useUserAccess();

  const hasCreate = userAccess.hasCreateAccess('postmortems');

  return (
    <>
      <NoPermissionTooltip isDisabled={hasCreate}>
        <ListingButton
          variant="secondary"
          id={INCIDENT_ACTION_ID.INLINE_RETRO}
          isDisabled={isDisabled || !hasCreate}
          title={`${hasPostmortem ? 'View' : 'Start'} Postmortem`}
          onClick={onClick}
        />
      </NoPermissionTooltip>
      {isFeatureUnAvailable && (
        <CrownSmallIcon
          className="pro"
          style={{
            position: 'absolute',
            left: '-4px',
            top: '-8px',
            height: 14,
            width: 14,
          }}
        />
      )}
      <UpgradePlanModal
        hasBillingPermission={BillingService.hasManageBillingPermission(org)}
        showModal={showModal}
        onCancel={setShowModal.toggle}
        message={BillingService.getMessage(0, FEAT_NAME, org)}
        header={BillingService.getHeader(0, FEAT_NAME, org)}
        onUpgrade={setShowModal.toggle}
      />
    </>
  );
};
