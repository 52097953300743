import { Box, Text } from '@chakra-ui/react';
import { FormButton } from 'library/atoms';
import { Modal } from 'library/molecules';

import { BaseModalProps } from '../common/types';

export const Tip = ({ isOpen, onClose }: BaseModalProps) => {
  return (
    <Modal
      key="download-incidents"
      body={
        <Box display="flex" flexDirection="column" gap="10px">
          <Text variant="formInput" color="brand.red">
            Max of 1000 incidents to be downloaded at once.
            <br />
            Nerd Tip: Use Export API to download more.
          </Text>
        </Box>
      }
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={<FormButton title="Okay" onClick={onClose} variant="primary" />}
      title="Download All Incidents"
    />
  );
};
