import { ChevronDownIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Checkbox,
  CloseButton,
  Container,
  createStandaloneToast,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Kbd,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Stack,
  Tag,
  TagLabel,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useMenuItem,
} from '@chakra-ui/react';
import axios from 'axios';
import { add } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { connect, useSelector } from 'react-redux';
import { Link as RLink, RouteChildrenProps } from 'react-router-dom';

import CalendarRangePicker from '../../../../components/CalendarRangePicker';
import { API } from '../../../../core/api';
import { T_WA_UP_NEW_SLO_CREATED, T_WA_UP_SLO_EDITED } from '../../../../core/const/tracker';
import { IAppState } from '../../../../core/interfaces/IAppState';
import { AppTracker } from '../../../../shared/analytics/tracker';
import { Stepper } from '../../../shared/components';
import { useAlreadyExistSloName } from './hooks/useAlreadySloName';
import { useCreateSlo, useUpdateSlo } from './hooks/useCreateSlo';
import { AlertDialogComponent } from '../../../shared/components';
import { sliConsts, SLO_STEPS, tooltipStyle } from './slo.constants';
import { ISloOverview } from './slo.interface';
import { IEntityOwner, OwnerEntitiesSelect } from './ownerDropdown';
import { ITeam } from 'core/interfaces/ITeams';
import { fontTheme } from 'library/theme';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { truncate } from 'core/helpers/stringUtils';

type IMix = Pick<IAppState, 'organization'> & RouteChildrenProps<{ id: string; mode: string }>;
const CreateSloTracker = (props: IMix) => {
  const [sliderValue, setSliderValue] = React.useState(0);
  const { createSlo_ } = useCreateSlo();
  const { updateSLO } = useUpdateSlo();
  const sloID = (props as any).match.params.sloid;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const _uA = useUserAccess();
  const hasCreate = _uA.hasCreateAccess('slos');
  const hasUpdate = _uA.hasUpdateAccess('slos');
  const hasDelete = _uA.hasDeleteAccess('slos');
  const hasRead = _uA.hasReadAccess('slos');
  const toast = createStandaloneToast();
  const prevRef = useRef<string>();
  function update(index: number, event: React.ChangeEvent<HTMLInputElement>, type: string) {
    const newTags = [...tab1Data.tags];
    if (type == 'key') newTags[index]['key'] = event.target.value;
    if (type == 'value') newTags[index]['value'] = event.target.value;

    const duplicateTags = getDuplicateTags(newTags);

    setTab1Data({ ...tab1Data, tags: newTags, duplicateTags });
  }

  const onUpdateTimeRange = (fromDate: Date, toDate: Date) => {
    const from = moment(fromDate);
    const to = moment(toDate);
    const diffTime = to.diff(from, 'days');
    if (diffTime <= 365 && diffTime >= 7) {
      setTab2Data({
        ...tab2Data,
        duration: {
          ...tab2Data.duration,
          number: diffTime.toString(),
          from: fromDate,
          to: toDate,
          isInvalid: false,
        },
      });
    } else {
      setTab2Data({
        ...tab2Data,
        duration: { ...tab2Data.duration, from: fromDate, to: toDate, isInvalid: true },
      });
    }
  };

  const currentUser = useSelector((state: IAppState) => state.organization.currentUser.u);

  const [tab1Data, setTab1Data] = React.useState({
    sloName: '',
    sloDescription: '',
    sloOwner: {
      id: currentUser?.id ?? '',
      type: 'user',
    },
    tags: [
      { key: 'Environment', value: '' },
      { key: 'Type', value: '' },
    ],
    duplicateTags: {} as Record<string, boolean>,
    nextButtonClicked: false,
  });

  const [tab2Data, setTab2Data] = React.useState({
    associatedService: [] as string[],
    slis: {
      value: [...sliConsts] as string[],
      current: [...sliConsts] as string[],
      customText: '',
    },
    targetSlo: '0',
    errorBudget: 0,
    duration: {
      isRoling: 'F',
      type: 'days',
      number: '7',
      to: add(new Date(), { days: 365 }),
      from: new Date(),
      isInvalid: false,
    },
  });

  const [tab3Data, setTab3Data] = React.useState({
    userList: [''],
    serviceList: [''],
    isFlasePositive: false,
    isBreachError: false,
    isUnhealthy: false,
    isErrBudget: false,
    falsePositive: 0,
    errorBudget: 0,
    isSendingEmail: false,
    isCreatingIncident: false,
    isFlasePositiveID: 0,
    isBreachErrorID: 0,
    isUnhealthyID: 0,
    isErrBudgetID: 0,
  });

  const [isStep1Valid, setIsStep1Valid] = useState<boolean>(false);
  const [isStep2Valid, setIsStep2Valid] = useState<boolean>(false);

  useEffect(() => {
    calculateErrorBudget();
  }, [tab2Data.duration, tab2Data.targetSlo]);

  useEffect(() => {
    const sloID = (props as any).match.params.sloid;
    prevRef.current = props.organization.selectedTeam.teamId;
    try {
      sloID &&
        axios
          .get(
            `${API.config.batman}/organizations/${API.config.organizationId}/slo/${sloID}?owner_id=${props.organization.selectedTeam.teamId}`,
          )
          .then(
            response => {
              const data = response.data.data.slo as ISloOverview;
              setTab1Data({
                sloName: data.name,
                sloDescription: data.description,
                tags: data?.tags
                  ? Object.keys(data.tags).map(_ => {
                      return { key: _, value: data.tags[_] };
                    })
                  : [],
                sloOwner: { id: data.slo_owner_id, type: data.slo_owner_type },
                duplicateTags: {},
              });

              setTab2Data({
                associatedService: data.service_ids,
                slis: { value: data.slis, current: [...sliConsts, ...data.slis], customText: '' },
                targetSlo: data.target_slo.toString(),
                errorBudget: data.remaining_error_budget,
                duration: {
                  isRoling: data.time_interval_type === 'fixed' ? 'F' : 'R',
                  type: 'days',
                  number: data.duration_in_days ? data.duration_in_days.toString() : '0',
                  from: new Date(data.start_time),
                  to: new Date(data.end_time),
                  isInvalid: false,
                },
              });

              const users = [] as string[];
              data.slo_actions
                ? data.slo_actions?.forEach(actions => {
                    if (actions.type == 'USER' && actions.user_id) {
                      users.push(actions.user_id);
                    }
                  })
                : [];

              const service_ = [] as string[];
              data.slo_actions
                ? data.slo_actions?.forEach(actions => {
                    if (actions.type == 'SERVICE' && actions.service_id) {
                      service_.push(actions.service_id);
                    }
                  })
                : [];

              const falsePositiveNumberObject =
                Array.isArray(data.slo_monitoring_checks) &&
                data.slo_monitoring_checks.find(
                  _ => _.name === 'increased_false_positives_threshold',
                );
              const falsePositiveNumber = falsePositiveNumberObject
                ? falsePositiveNumberObject['threshold']
                : 0;

              const errorBudgetNumberObject =
                Array.isArray(data.slo_monitoring_checks) &&
                data.slo_monitoring_checks.find(_ => _.name === 'remaining_err_budget_threshold');
              const errorBudgetNumber = errorBudgetNumberObject
                ? errorBudgetNumberObject['threshold']
                : 0;

              const breachErrorObject =
                Array.isArray(data.slo_monitoring_checks) &&
                data.slo_monitoring_checks.find(_ => _.name === 'is_breached_err_budget');

              const unhealthyObject =
                Array.isArray(data.slo_monitoring_checks) &&
                data.slo_monitoring_checks.find(_ => _.name === 'is_unhealthy_slo');

              setTab3Data({
                userList: users,
                serviceList: service_,
                isFlasePositive: falsePositiveNumberObject
                  ? falsePositiveNumberObject.is_checked
                  : false,
                isBreachError: breachErrorObject ? breachErrorObject.is_checked : false,
                isUnhealthy: unhealthyObject ? unhealthyObject.is_checked : false,
                isErrBudget: errorBudgetNumberObject ? errorBudgetNumberObject.is_checked : false,
                falsePositive: falsePositiveNumber || 0,
                errorBudget: errorBudgetNumber || 0,
                isSendingEmail: !!users.length,
                isCreatingIncident: !!service_.length,
                isFlasePositiveID: falsePositiveNumberObject
                  ? falsePositiveNumberObject.id || 0
                  : 0,
                isBreachErrorID: breachErrorObject ? breachErrorObject.id || 0 : 0,
                isUnhealthyID: unhealthyObject ? unhealthyObject.id || 0 : 0,
                isErrBudgetID: errorBudgetNumberObject ? errorBudgetNumberObject.id || 0 : 0,
              });
            },
            err => {
              toast({
                title: '',
                description:
                  err?.response?.data?.data || 'Error while fetching slo details - Please retry',
                status: 'error',
                duration: 9000,
                isClosable: true,
              });
              cancelChanges();
            },
          );
    } catch (err: any) {
      toast({
        title: '',
        description: err?.response?.data?.data || 'Error while fetching slo details - Please retry',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      cancelChanges();
    }
  }, []);

  useEffect(() => {
    if (prevRef.current && prevRef.current !== props.organization.selectedTeam.teamId) {
      props.history.push('/slo');
    }
  }, [props.organization.selectedTeam.teamId]);

  function calculateErrorBudget() {
    const from = moment(tab2Data.duration.from, 'YYYY-MM-DD');
    const to = moment(tab2Data.duration.to, 'YYYY-MM-DD');
    const fromFixed = moment.duration(to.diff(from)).asSeconds() / 60;
    const rollingType =
      tab2Data.duration.type === 'days'
        ? 'days'
        : tab2Data.duration.type === 'weeks'
        ? 'weeks'
        : 'months';

    const durationInMin =
      tab2Data.duration.isRoling == 'R'
        ? moment.duration(tab2Data.duration.number, rollingType).asSeconds() / 60
        : fromFixed;
    const downtimeInFraction = 1 - parseFloat(tab2Data.targetSlo) / 100;
    const errBudget = downtimeInFraction * durationInMin;
    const errBudgetInMin = Math.trunc(errBudget);
    const errBudgetSecInPerc = errBudget - errBudgetInMin;
    const formatedErrorBudget = errBudgetInMin + (60 * errBudgetSecInPerc) / 100;
    setTab2Data({ ...tab2Data, errorBudget: parseFloat(formatedErrorBudget.toFixed(2)) });
  }

  const getDurationInDays = (type: string, val: number) => {
    if (type === 'weeks') return val * 7;

    if (type === 'days') return val;

    if (type === 'months') return val * 30;
  };

  const cancelChanges = () => {
    props.history.push('/slo');
  };

  // Consolidate duplicate tags
  const getDuplicateTags = (
    tags: {
      key: string;
      value: string;
    }[],
  ) => {
    // Count the number of times each tag key appears
    const tagKeyCounter: Record<string, number> = {};
    tags.forEach(tag => {
      if (tagKeyCounter[tag.key]) {
        tagKeyCounter[tag.key] += 1;
      } else {
        tagKeyCounter[tag.key] = 1;
      }
    });

    // Check if any tag key appears more than once
    const duplicateTags: Record<string, boolean> = {};
    Object.keys(tagKeyCounter).forEach(key => {
      if (tagKeyCounter[key] > 1) {
        duplicateTags[key] = true;
      } else {
        duplicateTags[key] = false;
      }
    });

    return duplicateTags;
  };

  const hasDuplicateTags = () => {
    return Object.values(tab1Data.duplicateTags).includes(true);
  };

  const hasEmptyKeysOrValues = () => {
    return tab1Data.tags.some(tag => tag.key.trim().length === 0 || tag.value.trim().length === 0);
  };

  const trimTagKeysAndValues = () => {
    setTab1Data(prev => {
      return {
        ...prev,
        tags: prev.tags.map(tag => {
          return {
            key: tag.key.trim(),
            value: tag.value.trim(),
          };
        }),
      };
    });
  };

  const MenuInput = (props: any) => {
    const { role, ...rest } = useMenuItem(props);
    return (
      <Box p={1}>
        <InputGroup>
          <Input
            pr="4.5rem"
            onChange={e => {
              e.preventDefault();
              setTab2Data({
                ...tab2Data,
                slis: { ...tab2Data.slis, customText: e.target.value },
              });
            }}
            onFocus={e => (e.target.selectionStart = tab2Data.slis.customText.length)}
            value={tab2Data.slis.customText}
            onKeyPress={e => {
              if (e.key === 'Enter' && tab2Data.slis.customText.length) {
                if (!tab2Data.slis.current.includes(tab2Data.slis.customText)) {
                  setTab2Data({
                    ...tab2Data,
                    slis: {
                      value: [...tab2Data.slis.value],
                      current: [tab2Data.slis.customText, ...tab2Data.slis.current],
                      customText: '',
                    },
                  });
                }
              }
            }}
            placeholder="Sli"
            size="sm"
            {...rest}
          />
          <InputRightElement width="4.5rem" alignItems="center">
            {tab2Data.slis.customText.length > 0 && <Kbd>Enter</Kbd>}
          </InputRightElement>
        </InputGroup>
      </Box>
    );
  };

  const createSlo = () => {
    const sloID = (props as any).match.params.sloid;
    const tags_: { [k: string]: any } = {};
    tab1Data.tags.forEach(({ key, value }) => {
      tags_[key] = value;
    });

    const timeForRolling = getDurationInDays(
      tab2Data.duration.type,
      parseInt(tab2Data.duration.number),
    );

    const slo_monitoring_checks = [
      {
        ...(sloID ? { id: tab3Data.isBreachErrorID } : []),
        name: 'is_breached_err_budget',
        owner_id: props.organization.selectedTeam.teamId,
        org_id: API.config.organizationId,
        owner_type: 'team',
        is_checked: tab3Data.isBreachError,
        ...(sloID ? { slo_id: parseInt(sloID) } : []),
      },

      {
        ...(sloID ? { id: tab3Data.isUnhealthyID } : []),
        name: 'is_unhealthy_slo',
        owner_id: props.organization.selectedTeam.teamId,
        org_id: API.config.organizationId,
        owner_type: 'team',
        is_checked: tab3Data.isUnhealthy,
        ...(sloID ? { slo_id: parseInt(sloID) } : []),
      },

      {
        ...(sloID ? { id: tab3Data.isFlasePositiveID } : []),
        name: 'increased_false_positives_threshold',
        threshold: tab3Data.falsePositive,
        owner_id: props.organization.selectedTeam.teamId,
        org_id: API.config.organizationId,
        owner_type: 'team',
        is_checked: tab3Data.isFlasePositive,
        ...(sloID ? { slo_id: parseInt(sloID) } : []),
      },

      {
        ...(sloID ? { id: tab3Data.isErrBudgetID } : []),
        name: 'remaining_err_budget_threshold',
        threshold: tab3Data.errorBudget,
        owner_id: props.organization.selectedTeam.teamId,
        org_id: API.config.organizationId,
        owner_type: 'team',
        is_checked: tab3Data.isErrBudget,
        ...(sloID ? { slo_id: parseInt(sloID) } : []),
      },
    ];

    const serviceSelected = tab3Data.serviceList.map(service => {
      return {
        type: 'SERVICE',
        service_id: service,
        owner_id: props.organization.selectedTeam.teamId,
        org_id: API.config.organizationId,
        ...(sloID ? { slo_id: parseInt(sloID) } : []),
        owner_type: 'team',
      };
    });

    const userSelected = tab3Data.userList.map(user => {
      if (user.includes('_SQD')) {
        return {
          type: 'SQUAD',
          squad_id: user.replace(/_SQD/g, '').trim(),
          owner_id: props.organization.selectedTeam.teamId,
          org_id: API.config.organizationId,
          ...(sloID ? { slo_id: parseInt(sloID) } : []),
          owner_type: 'team',
        };
      }
      return {
        type: 'USER',
        user_id: user,
        owner_id: props.organization.selectedTeam.teamId,
        org_id: API.config.organizationId,
        ...(sloID ? { slo_id: parseInt(sloID) } : []),
        owner_type: 'team',
      };
    });

    const slo_actions = [
      ...(tab3Data.isCreatingIncident ? serviceSelected : []),
      ...(tab3Data.isSendingEmail ? userSelected : []),
    ];

    const payload = {
      name: tab1Data.sloName,
      description: tab1Data.sloDescription,
      service_ids: tab2Data.associatedService,
      slis: tab2Data.slis.value,
      target_slo: parseFloat(tab2Data.targetSlo),
      start_time: tab2Data.duration.from,
      end_time: tab2Data.duration.to,
      allocated_error_budget: tab2Data.errorBudget,
      tags: tags_,
      remaining_error_budget: tab2Data.errorBudget,
      time_interval_type: tab2Data.duration.isRoling === 'R' ? 'rolling' : 'fixed',
      owner_id: props.organization.selectedTeam.teamId,
      org_id: API.config.organizationId,
      duration_in_days: timeForRolling,
      slo_monitoring_checks,
      slo_actions,
      slo_owner_id: tab1Data.sloOwner.id,
      slo_owner_type: tab1Data.sloOwner.type,
    };

    if (sloID) {
      updateSLO({ sloID, payload, teamID: props.organization.selectedTeam.teamId });
      AppTracker.track(T_WA_UP_SLO_EDITED);
    } else {
      createSlo_({ sloID, payload });
      AppTracker.track(T_WA_UP_NEW_SLO_CREATED);
    }
  };

  const step1 = () => {
    if (!tab1Data.nextButtonClicked) {
      setTab1Data(prev => {
        return { ...prev, nextButtonClicked: true };
      });
    }

    let isInvalid = false;
    tab1Data.tags.forEach(({ key, value }) => {
      if (!key.length || !value.length) {
        isInvalid = true;
      }
    });
    if (hasDuplicateTags() || hasEmptyKeysOrValues()) {
      isInvalid = true;
    }

    if (!tab1Data.sloName.length) {
      isInvalid = true;
    }
    if (!tab1Data.sloOwner.id) {
      isInvalid = true;
    }
    if (isSuccess) {
      isInvalid = true;
    }
    !isInvalid && setSliderValue(1);
    setIsStep1Valid(isInvalid);

    if (!isInvalid) {
      trimTagKeysAndValues();
    }
  };

  const step2 = () => {
    let isInvalid = false;
    if (!tab2Data.associatedService.length || !tab2Data.slis.value.length) {
      isInvalid = true;
    }

    if (tab2Data.duration.isInvalid) {
      isInvalid = true;
    }

    !isInvalid && setSliderValue(2);
    setIsStep2Valid(isInvalid);
  };

  const { alreadySloData, isSuccess, isError, setSloName } = useAlreadyExistSloName(
    props.organization.selectedTeam.teamId,
  );

  const onChangeEntityOwner = (entityOwner: IEntityOwner) => {
    setTab1Data({ ...tab1Data, sloOwner: entityOwner });
  };
  const isInvalidDuration = (numValue: number, type: string) => {
    if (type === 'days') {
      return numValue < 7 || numValue > 30;
    } else if (type === 'weeks') {
      return numValue < 1 || numValue > 4;
    }
    return true;
  };

  const adjustNumber = (currentNumber: number, type: string) => {
    if (isNaN(currentNumber)) {
      return { adjustedNumber: '', isInvalid: true };
    }

    if (type === 'days') {
      const adjustedNumber = currentNumber < 7 ? 7 : currentNumber > 30 ? 30 : currentNumber;
      return { adjustedNumber, isInvalid: adjustedNumber < 7 || adjustedNumber > 30 };
    } else if (type === 'weeks') {
      const adjustedNumber = currentNumber < 1 ? 1 : currentNumber > 4 ? 4 : currentNumber;
      return { adjustedNumber, isInvalid: adjustedNumber < 1 || adjustedNumber > 4 };
    }
  };

  return (
    <ChakraProvider theme={fontTheme}>
      <Box>
        {hasCreate || hasUpdate ? (
          <Container maxW="container.xl" centerContent>
            <Flex
              w="container.md"
              direction={'row'}
              justifyContent={'space-between'}
              alignItems="center"
              mb={5}
            >
              <Heading mt={5} mb={5} size="md">
                {sloID ? 'Update SLO' : 'Create SLO'}
              </Heading>
              <Spacer />
              <CloseButton onClick={onOpen} />
            </Flex>

            <Spacer />
            <Stepper
              steps={SLO_STEPS}
              onChangeStep={v => setSliderValue(v)}
              sliderValue={sliderValue}
            />

            {sliderValue == 0 && (
              <Box mt={5} mb={5} w="container.md">
                <FormControl
                  isInvalid={
                    (isSuccess && alreadySloData?.slo?.id !== parseInt(sloID)) ||
                    (isStep1Valid && !tab1Data.sloName)
                  }
                  mb={5}
                >
                  {/* formlabel is of chakra and not library so couldn't pass isrequired props here */}
                  <FormLabel htmlFor="sloName">
                    <HStack>
                      <Text>SLO Name</Text> <Text color="red">*</Text>
                    </HStack>
                  </FormLabel>
                  <Input
                    id="sloName"
                    onBlur={e => {
                      setSloName(e.target.value);
                    }}
                    value={tab1Data.sloName}
                    onChange={e => {
                      setTab1Data({ ...tab1Data, sloName: e.target.value });
                    }}
                    placeholder="Enter SLO Name"
                  />
                  {!isSuccess && !tab1Data.sloName && (
                    <FormErrorMessage> SLO Name Required</FormErrorMessage>
                  )}
                  {isSuccess && alreadySloData?.slo?.id !== parseInt(sloID) && tab1Data.sloName && (
                    <FormErrorMessage>SLO name already taken</FormErrorMessage>
                  )}
                </FormControl>
                <Spacer />
                <FormControl isInvalid={false} mb={5}>
                  <FormLabel htmlFor="sloDescripton" color={'#3A4860'}>
                    SLO Description
                  </FormLabel>
                  <Textarea
                    id="sloDescripton"
                    value={tab1Data.sloDescription}
                    onChange={e => {
                      setTab1Data({ ...tab1Data, sloDescription: e.target.value });
                    }}
                    placeholder="Enter SLO Description"
                  />
                </FormControl>
                <FormControl isInvalid={isStep1Valid && !tab1Data.sloOwner.id} mb={5}>
                  <FormLabel htmlFor="sloDescripton" color={'#3A4860'}>
                    <HStack>
                      <Text>SLO Owner</Text> <Text color="red">*</Text>
                    </HStack>
                  </FormLabel>
                  <OwnerEntitiesSelect
                    currentTeam={props.organization?.selectedTeam?.team as ITeam}
                    squads={props.organization.squads.s}
                    users={props.organization.users.u}
                    entityOwner={tab1Data.sloOwner}
                    onEntityOwnerChange={onChangeEntityOwner}
                  />
                  {!isSuccess && !tab1Data.sloOwner.id && (
                    <FormErrorMessage> SLO Owner Required</FormErrorMessage>
                  )}
                </FormControl>
                <Text fontSize={'md'} mb="8px">
                  Tags{' '}
                  <Tooltip
                    fontWeight={'medium'}
                    fontSize={'sm'}
                    {...tooltipStyle}
                    label={
                      <Text fontSize="11px" fontWeight="normal">
                        {'Define Tag Key-Value pairs to add more information to your SLO'}
                      </Text>
                    }
                  >
                    <InfoIcon m={2} />
                  </Tooltip>
                </Text>

                <Flex align={'start'} justify={'start'} direction={'row'}>
                  <Stack width="xl" spacing={3} mr={5}>
                    <Text fontSize="sm" color="gray.500">
                      Key
                    </Text>
                    {tab1Data.tags.map((obj, index) => {
                      return (
                        <FormControl key={index} isInvalid={isStep1Valid && !obj.key} mb={5}>
                          <Input
                            bgColor={'rgba(230, 232, 236, 1)'}
                            key={index}
                            placeholder="Enter the key here"
                            _placeholder={{ opacity: 1, color: 'gray.500' }}
                            value={obj.key}
                            onChange={event => update(index, event, 'key')}
                            errorBorderColor="red.500"
                            isInvalid={
                              tab1Data.duplicateTags[obj.key] || obj.key.trim().length === 0
                            }
                          />
                        </FormControl>
                      );
                    })}
                  </Stack>
                  <Stack width="xl" spacing={3}>
                    <Text fontSize="sm" color="gray.500">
                      Value
                    </Text>
                    {tab1Data.tags.map((obj, index) => {
                      return (
                        <HStack key={index} alignItems="flex-start">
                          <FormControl key={index} isInvalid={isStep1Valid && !obj.value}>
                            <Input
                              key={index}
                              placeholder="Enter the value here"
                              _placeholder={{ opacity: 1, color: 'gray.500' }}
                              value={obj.value}
                              onChange={event => update(index, event, 'value')}
                              isInvalid={
                                tab1Data.nextButtonClicked && obj.value.trim().length === 0
                              }
                            />
                          </FormControl>
                          {
                            <IconButton
                              onClick={() => {
                                const newTags = [
                                  ...tab1Data.tags.filter((_, idx) => idx !== index),
                                ];
                                const duplicateTags = getDuplicateTags(newTags);
                                setTab1Data({
                                  ...tab1Data,
                                  tags: newTags,
                                  duplicateTags,
                                });
                              }}
                              fontSize={10}
                              color="rgba(240, 69, 70, 1)"
                              icon={<FiTrash2 fontSize="1.25rem" />}
                              variant="ghost"
                              aria-label="Delete member"
                            />
                          }
                        </HStack>
                      );
                    })}
                  </Stack>
                </Flex>
                {hasDuplicateTags() && (
                  <Text fontSize="sm" color="red.500" mt="2">
                    Duplicate Tag Key found
                  </Text>
                )}
                {tab1Data.nextButtonClicked && hasEmptyKeysOrValues() && (
                  <Text fontSize="sm" color="red.500" mt="2">
                    Tag Key or Value cannot be empty
                  </Text>
                )}
                <Flex mt={2} width="100%" alignContent="center" justifyContent="center">
                  <Button
                    fontWeight="normal"
                    onClick={() => {
                      setTab1Data({
                        ...tab1Data,
                        tags: [...tab1Data.tags, { key: '', value: '' }],
                      });
                    }}
                    leftIcon={<>+</>}
                    variant="unstyled"
                    _focus={{ outline: 'none' }}
                  >
                    Add Tag
                  </Button>
                </Flex>
              </Box>
            )}

            {sliderValue == 1 && (
              <Box mt={5} mb={5} w="container.md">
                <Text color={'#3A4860'} mb="8px">
                  Select Services{' '}
                  <Text color="red" display="inline">
                    *
                  </Text>
                  <Tooltip
                    fontWeight={'medium'}
                    fontSize={'sm'}
                    {...tooltipStyle}
                    label={
                      <Text fontSize="11px" fontWeight="normal">
                        {
                          'Choose those services which are associated with this SLO. Only incidents from these services can affect this SLO'
                        }
                      </Text>
                    }
                  >
                    <InfoIcon m={2} />
                  </Tooltip>
                </Text>
                <FormControl isInvalid={isStep2Valid && !tab2Data.associatedService.length} mb={5}>
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      color={'gray.500'}
                      textAlign={'start'}
                      w="100%"
                      p={2}
                      borderWidth="1px"
                      borderRadius="lg"
                    >
                      <Flex dir="row" justifyContent="space-between">
                        <Text>Select Services associated with this SLO</Text>
                        <ChevronDownIcon color={'black'} fontSize="xl" />
                      </Flex>
                    </MenuButton>
                    <MenuList maxH="200px" overflow="auto" minWidth="240px">
                      <MenuOptionGroup
                        blockSize="10px"
                        defaultValue={tab2Data.associatedService}
                        onChange={service => {
                          setTab2Data({
                            ...tab2Data,
                            associatedService: [...(Array.isArray(service) ? service : [])],
                          });
                        }}
                        type="checkbox"
                      >
                        {props.organization.services.s.map(({ id, name }, index) => {
                          return (
                            <MenuItemOption key={index} value={id}>
                              {name}
                            </MenuItemOption>
                          );
                        })}
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                  {<FormErrorMessage>Atleast one Service is required.</FormErrorMessage>}
                </FormControl>
                <Stack m={2} justifyContent="start" height="20px" direction="row">
                  {props.organization.services.s.map((service, index) => {
                    if (
                      Array.isArray(tab2Data.associatedService) &&
                      tab2Data.associatedService.includes(service.id)
                    ) {
                      return (
                        <Tag
                          size={'sm'}
                          key={'sm'}
                          borderRadius="full"
                          variant="solid"
                          colorScheme="gray"
                        >
                          <TagLabel>{service.name}</TagLabel>
                        </Tag>
                      );
                    }
                  })}
                </Stack>
                <Spacer />
                <Text color={'#3A4860'} mb="8px">
                  Add SLIs
                  <Tooltip
                    fontWeight={'medium'}
                    fontSize={'sm'}
                    {...tooltipStyle}
                    label={
                      <Text fontSize="11px" fontWeight="normal">
                        {
                          'Map SLIs and match them against the SLO to improve SRE. Examples include Latency, Memory, Uptime, Status Code'
                        }
                      </Text>
                    }
                  >
                    <InfoIcon m={2} />
                  </Tooltip>
                </Text>
                {
                  <FormControl isInvalid={isStep2Valid && !tab2Data.slis.value.length} mb={5}>
                    <Menu closeOnSelect={false}>
                      <MenuButton
                        color={'gray.500'}
                        textAlign={'start'}
                        w="100%"
                        p={2}
                        borderWidth="1px"
                        borderRadius="lg"
                      >
                        <Flex dir="row" justifyContent="space-between">
                          <Text>SLI</Text>
                        </Flex>
                      </MenuButton>
                      <MenuList maxH="200px" overflow="auto" minWidth="240px">
                        <MenuInput />
                        <MenuOptionGroup
                          defaultValue={tab2Data.slis.value}
                          onChange={sli => {
                            setTab2Data({
                              ...tab2Data,
                              slis: {
                                value: [...(Array.isArray(sli) ? sli : [])],
                                current: [...tab2Data.slis.current],
                                customText: tab2Data.slis.customText,
                              },
                            });
                          }}
                          type="checkbox"
                        >
                          {tab2Data.slis.current.map((first_name, index) => {
                            return (
                              <MenuItemOption
                                type="checkbox"
                                isChecked={tab2Data.slis.value.includes(first_name)}
                                key={index}
                                value={first_name}
                              >
                                {first_name}
                              </MenuItemOption>
                            );
                          })}
                        </MenuOptionGroup>
                      </MenuList>
                    </Menu>
                    {<FormErrorMessage>Atleast one SLI is required.</FormErrorMessage>}
                  </FormControl>
                }

                <Spacer />
                <Flex justifyContent="start" direction="row" m={2}>
                  <Stack
                    justifyContent="space-around"
                    alignItems={'end'}
                    height="20px"
                    direction="row"
                  >
                    {tab2Data.slis.value.map((sli, index) => {
                      if (sli.length) {
                        return (
                          <Tag
                            size={'sm'}
                            key={sli}
                            borderRadius="full"
                            variant="solid"
                            colorScheme="gray"
                          >
                            <TagLabel>{sli}</TagLabel>
                          </Tag>
                        );
                      }
                    })}
                  </Stack>
                </Flex>
                <Spacer />
                <Stack width="full" spacing={3} mr={5}>
                  <Text color={'#3A4860'}>Target SLO in %</Text>
                  <NumberInput
                    defaultValue={0}
                    max={100}
                    min={0}
                    keepWithinRange={false}
                    clampValueOnBlur={false}
                    onChange={e => {
                      const percent = parseInt(e);
                      if (percent > 100 || percent < 0) {
                        setTab2Data({ ...tab2Data, targetSlo: '0' });
                      } else {
                        setTab2Data({ ...tab2Data, targetSlo: e });
                      }
                    }}
                    value={tab2Data.targetSlo}
                    placeholder="Enter your target SLO in percentage"
                    _placeholder={{ opacity: 1, color: 'gray.500' }}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Stack>
                <Box width="100%" mb={10} mt={10}>
                  <Text color={'#3A4860'} mb="8px">
                    Duration of the SLO{' '}
                    <Tooltip
                      fontWeight={'medium'}
                      fontSize={'sm'}
                      {...tooltipStyle}
                      label={
                        <Text fontSize="11px" fontWeight="normal">
                          {`The duration of the SLO defines the extent of time for which the SLO is to be measured. Typically it's done on a rolling (i.e. continuous) basis or on a fixed basis (for example, one quarter at a time)`}
                        </Text>
                      }
                    >
                      <InfoIcon m={2} />
                    </Tooltip>
                  </Text>
                  <RadioGroup
                    onChange={_ =>
                      setTab2Data({
                        ...tab2Data,
                        duration: { ...tab2Data.duration, isRoling: _, isInvalid: false },
                      })
                    }
                    value={tab2Data.duration.isRoling}
                    defaultValue="2"
                    mb={5}
                  >
                    <Stack spacing={5} direction="row">
                      <Radio colorScheme="blue" value="F">
                        Fixed Duration
                      </Radio>
                      <Radio colorScheme="blue" value="R">
                        Rolling Period
                      </Radio>
                    </Stack>
                  </RadioGroup>

                  {
                    <FormControl isInvalid={tab2Data.duration.isInvalid} mb={5}>
                      <Stack spacing={5} direction="row" w="md">
                        {tab2Data.duration.isRoling === 'R' && (
                          <>
                            <NumberInput
                              onChange={e => {
                                const numValue = parseInt(e);
                                const isInvalid = isNaN(numValue)
                                  ? true
                                  : isInvalidDuration(numValue, tab2Data.duration.type);
                                setTab2Data({
                                  ...tab2Data,
                                  duration: {
                                    ...tab2Data.duration,
                                    number: isNaN(numValue) ? '' : e,
                                    isInvalid,
                                  },
                                });
                              }}
                              value={tab2Data.duration.number}
                              step={1}
                              min={tab2Data.duration.type === 'days' ? 7 : 1}
                              max={tab2Data.duration.type === 'days' ? 30 : 4}
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>

                            <Select
                              defaultValue={'days'}
                              value={tab2Data.duration.type}
                              onChange={val => {
                                const { adjustedNumber, isInvalid } = adjustNumber(
                                  parseInt(tab2Data.duration.number),
                                  val.target.value,
                                );
                                setTab2Data({
                                  ...tab2Data,
                                  duration: {
                                    ...tab2Data.duration,
                                    type: val.target.value,
                                    number: adjustedNumber,
                                    isInvalid,
                                  },
                                });
                              }}
                              variant="outline"
                            >
                              <option value="days">Days</option>
                              <option value="weeks">Weeks</option>
                            </Select>
                          </>
                        )}
                        {tab2Data.duration.isRoling === 'F' && (
                          <Box>
                            <CalendarRangePicker
                              startDate={tab2Data.duration.from}
                              endDate={tab2Data.duration.to}
                              onUpdateRange={onUpdateTimeRange}
                              minDate={moment().subtract(1, 'years').toDate()}
                              maxDate={moment().add(1, 'years').toDate()}
                              offset={'0px'}
                              isFuture={true}
                              className="slo-calendar"
                            />
                          </Box>
                        )}
                      </Stack>
                      {tab2Data.duration.isInvalid && (
                        <FormErrorMessage>
                          {tab2Data.duration.isRoling === 'F'
                            ? 'Min 7 days and a maximum 1-year of date range is allowed'
                            : tab2Data.duration.type === 'days'
                            ? 'Min 7 days and a maximum 30 days of date range is allowed.'
                            : 'Min 1 week and a maximum 4 weeks of date range is allowed.'}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  }
                </Box>
                <Spacer />
                <Stack width="full" spacing={3}>
                  <Text color={'#3A4860'}>
                    Error Budget (in minutes)
                    <Tooltip
                      fontWeight={'medium'}
                      fontSize={'sm'}
                      {...tooltipStyle}
                      label={
                        <Text fontSize="11px" fontWeight="normal">
                          {
                            'The error budget is the maximum amount of time that a system can fail without consequences. It is automatically calculated from the Target SLO defined earlier and the duration of this SLO'
                          }
                        </Text>
                      }
                    >
                      <InfoIcon m={2} />
                    </Tooltip>
                  </Text>
                  <Input
                    placeholder="Error Budget"
                    _placeholder={{ opacity: 1, color: 'gray.500' }}
                    readOnly={true}
                    value={tab2Data.errorBudget}
                    disabled={true}
                  />
                </Stack>
              </Box>
            )}

            {sliderValue == 2 && (
              <Box mt={5} mb={5} w="container.md">
                <Text color={'#3A4860'} mb="8px">
                  Alert me when
                </Text>
                <Stack mb={5} spacing={5} direction="column">
                  <Checkbox
                    isChecked={tab3Data.isBreachError}
                    onChange={e => setTab3Data({ ...tab3Data, isBreachError: e.target.checked })}
                    colorScheme="blue"
                  >
                    Alert me when Error Budget is breached
                  </Checkbox>
                  <Checkbox
                    isChecked={tab3Data.isUnhealthy}
                    onChange={e => setTab3Data({ ...tab3Data, isUnhealthy: e.target.checked })}
                    colorScheme="blue"
                  >
                    Alert me when SLO burn rate is unhealthy
                  </Checkbox>
                  <HStack>
                    <Checkbox
                      isChecked={tab3Data.isFlasePositive}
                      onChange={e =>
                        setTab3Data({ ...tab3Data, isFlasePositive: e.target.checked })
                      }
                      colorScheme="blue"
                    >
                      {'Alert me when number of false positives >'}
                    </Checkbox>
                    <Input
                      htmlSize={4}
                      type="number"
                      width="auto"
                      value={tab3Data.falsePositive}
                      onChange={e =>
                        setTab3Data({
                          ...tab3Data,
                          falsePositive: parseInt(e.target.value),
                        })
                      }
                    />
                    <Text>incidents</Text>
                  </HStack>

                  <HStack>
                    <Checkbox
                      isChecked={tab3Data.isErrBudget}
                      onChange={e => setTab3Data({ ...tab3Data, isErrBudget: e.target.checked })}
                      colorScheme="blue"
                    >
                      {' Warning when error budget <='}
                    </Checkbox>
                    <Input
                      htmlSize={4}
                      type="number"
                      width="auto"
                      value={tab3Data.errorBudget}
                      onChange={e =>
                        setTab3Data({
                          ...tab3Data,
                          errorBudget: parseInt(e.target.value),
                        })
                      }
                    />
                    <Text>minutes</Text>
                  </HStack>
                </Stack>

                <Box width="100%" mb={5}>
                  <Text color={'#3A4860'} mb="8px">
                    Alert by
                  </Text>
                  <Checkbox
                    isChecked={tab3Data.isSendingEmail}
                    onChange={e => setTab3Data({ ...tab3Data, isSendingEmail: e.target.checked })}
                    colorScheme="blue"
                    defaultChecked
                  >
                    Sending email to
                  </Checkbox>
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      color={'gray.500'}
                      textAlign={'start'}
                      w="100%"
                      p={2}
                      borderWidth="1px"
                      borderRadius="lg"
                    >
                      <Flex dir="row" justifyContent="space-between">
                        <Text>Add Users and Squads</Text>
                        <ChevronDownIcon color={'black'} fontSize="xl" />
                      </Flex>
                    </MenuButton>
                    <MenuList maxH="200px" overflow="auto" minWidth="240px">
                      <MenuOptionGroup
                        onChange={user => {
                          setTab3Data({
                            ...tab3Data,
                            userList: [...(Array.isArray(user) ? user : [])],
                          });
                        }}
                        defaultValue={tab3Data.userList}
                        type="checkbox"
                      >
                        {props.organization.users.u
                          .sort((comparingElement, compareWithElement) =>
                            comparingElement.first_name.localeCompare(
                              compareWithElement.first_name,
                            ),
                          )
                          .map(({ first_name, last_name, id }, index) => {
                            return (
                              <MenuItemOption key={index} value={id}>
                                {truncate(`${first_name} ${last_name}`, 20)}
                              </MenuItemOption>
                            );
                          })}
                        <Divider />
                        {props.organization.squads.s
                          .sort((comparingElement, compareWithElement) =>
                            comparingElement.name.localeCompare(compareWithElement.name),
                          )
                          .map(({ name, id }, index) => {
                            return (
                              <MenuItemOption key={index} value={id + '_SQD'}>
                                {name}
                              </MenuItemOption>
                            );
                          })}
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                  <Stack m={2} justifyContent="start" height="20px" direction="row">
                    {props.organization.users.u.map((user, index) => {
                      if (Array.isArray(tab3Data.userList) && tab3Data.userList.includes(user.id)) {
                        return (
                          <Tag
                            size={'sm'}
                            key={index}
                            borderRadius="full"
                            variant="solid"
                            colorScheme="gray"
                          >
                            <TagLabel>{truncate(user.first_name, 15)}</TagLabel>
                          </Tag>
                        );
                      }
                    })}

                    {props.organization.squads.s.map((user, index) => {
                      if (
                        Array.isArray(tab3Data.userList) &&
                        tab3Data.userList.includes(user.id + '_SQD')
                      ) {
                        return (
                          <Tag
                            size={'sm'}
                            key={index}
                            borderRadius="full"
                            variant="solid"
                            colorScheme="gray"
                          >
                            <TagLabel>{truncate(user.name, 15)}</TagLabel>
                          </Tag>
                        );
                      }
                    })}
                  </Stack>
                </Box>

                <Box width="100%">
                  <Checkbox
                    isChecked={tab3Data.isCreatingIncident}
                    onChange={e =>
                      setTab3Data({ ...tab3Data, isCreatingIncident: e.target.checked })
                    }
                    colorScheme="blue"
                    defaultChecked
                  >
                    Creating incident in
                  </Checkbox>
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      color={'gray.500'}
                      textAlign={'start'}
                      w="100%"
                      p={2}
                      borderWidth="1px"
                      borderRadius="lg"
                    >
                      <Flex dir="row" justifyContent="space-between">
                        <Text>Select Service</Text>
                        <ChevronDownIcon color={'black'} fontSize="xl" />
                      </Flex>
                    </MenuButton>
                    <MenuList maxH="200px" overflow="auto" minWidth="240px">
                      <MenuOptionGroup
                        onChange={service => {
                          setTab3Data({
                            ...tab3Data,
                            serviceList: [...(Array.isArray(service) ? service : [service])],
                          });
                        }}
                        type="radio"
                      >
                        {props.organization.services.s.map(({ id, name }, index) => {
                          return (
                            <MenuItemOption key={index} value={id}>
                              {name}
                            </MenuItemOption>
                          );
                        })}
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                  <Stack m={2} justifyContent="start" height="20px" direction="row">
                    {props.organization.services.s.map((service, index) => {
                      if (
                        Array.isArray(tab3Data.serviceList) &&
                        tab3Data.serviceList.includes(service.id)
                      ) {
                        return (
                          <Tag
                            size={'sm'}
                            key={'sm'}
                            borderRadius="full"
                            variant="solid"
                            colorScheme="gray"
                          >
                            <TagLabel>{service.name}</TagLabel>
                          </Tag>
                        );
                      }
                    })}
                  </Stack>
                </Box>
              </Box>
            )}

            <Center mb={10} w="container.md">
              <Button
                onClick={() => {
                  return sliderValue < 2 ? (sliderValue < 1 ? step1() : step2()) : createSlo();
                }}
                _focus={{ outline: 'none' }}
                width="100%"
                color={'white'}
                bg={'rgba(15, 97, 221, 1)'}
              >
                {sliderValue == 2 ? (sloID ? 'Update' : 'Create') : 'Next'}
              </Button>
              <Spacer />
              <Button
                onClick={() => {
                  return sliderValue > 0
                    ? sliderValue > 1
                      ? setSliderValue(1)
                      : setSliderValue(0)
                    : onOpen();
                }}
                _focus={{ outline: 'none' }}
                bg={'gray.200'}
                width="100%"
                color={'blackAlpha.500'}
              >
                {sliderValue == 0 ? 'Cancel' : 'Previous'}
              </Button>
            </Center>

            {
              <AlertDialogComponent
                isOpen={isOpen}
                onClose={onClose}
                callbackFn={() => {
                  cancelChanges();
                }}
                msg={`Are you sure? You want to discard the changes and go back.`}
                title={''}
                isDelete={false}
              />
            }
          </Container>
        ) : (
          <Center>
            <Text m={5}> Looks like you don't have access to Create/Update Slo </Text>
            <RLink to="/slo" style={{ textDecoration: 'none' }}>
              <Button fontWeight="medium" bgColor={'rgba(15, 97, 221, 1)'} color={'white'}>
                Back
              </Button>
            </RLink>
          </Center>
        )}
      </Box>
    </ChakraProvider>
  );
};

export default connect(({ organization, APP_CONFIG }: IAppState) => ({
  organization,
  APP_CONFIG,
}))(CreateSloTracker);
