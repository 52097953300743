import React, { Children, Fragment, ReactElement, ReactNode, useEffect, useState } from 'react';
import { FocusBlock, Grid, IconButton, Para, Radio, TextButton, Theme } from 'uie/components';
import { CloseIcon } from 'icons';
import { useSecondaryFilters } from '../../context';
import { FilterOptionsLabel, SecondaryFiltersKeys, SelectOption } from '../../types';
import { HStack, VStack } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';

const { theme } = Theme;

type Props = {
  selected: string[];
  filterKey: SecondaryFiltersKeys;
  label: string;
  onClose?: () => void;
  children: ReactNode;
  isPrimaryButton?: boolean;
  isInitializedOpen?: boolean;
};

const DropdownContainer: React.FC<Props> = ({
  selected,
  label,
  children,
  onClose,
  filterKey,
  isPrimaryButton,
  isInitializedOpen,
}) => {
  const { removeActiveFilters } = useSecondaryFilters();

  const onRemoveFilter = () => {
    removeActiveFilters(filterKey);
  };

  const [isOpen, setIsOpen] = useState<boolean>(isInitializedOpen || false);
  const toggleOpen = () => {
    if (isOpen) onClose?.();

    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isInitializedOpen) {
      setIsOpen(isInitializedOpen);
    }
  }, [isInitializedOpen]);

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      onRemove={onRemoveFilter}
      isButton={isPrimaryButton}
      target={
        <TextButton
          onClick={toggleOpen}
          style={{
            background: theme.shades.white,
            border: `1px solid ${theme.primary.default}`,
            boxShadow: 'none',
            minWidth: 100,
            padding: '6px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Para fontSize={14} color={theme.primary.default}>
            <span style={{ fontWeight: 600 }}>{label}</span>
            {selected.length !== 0 && (
              <>
                : {selected[0]}
                {selected.length - 1 !== 0 && (
                  <span
                    style={{
                      borderRadius: '8px',
                      fontSize: '12px',
                      backgroundColor: theme.shades.smoke,
                      padding: '0 6px',
                      marginLeft: '8px',
                      top: '-1px',
                      position: 'relative',
                    }}
                  >
                    +{selected.length - 1}
                  </span>
                )}
              </>
            )}
          </Para>
        </TextButton>
      }
    >
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { closeContainerDropdown: toggleOpen });
        }
        return child;
      })}
    </Dropdown>
  );
};

const Menu = (props: JSX.IntrinsicElements['div']) => {
  const shadow = theme.shades.lightGrey;
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
        fontSize: 14,
      }}
      {...props}
    />
  );
};
const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
interface DropdownProps {
  readonly isOpen: boolean;
  readonly isButton: boolean | undefined;
  readonly target: ReactNode;
  readonly onClose: () => void;
  children: ReactNode;
  onRemove: () => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  children,
  isOpen,
  target,
  isButton,
  onClose,
  onRemove,
}) => (
  <div style={{ position: 'relative', borderRadius: 4 }}>
    <Grid style={{ alignItems: 'center' }}>
      {target}
      {!isButton && (
        <IconButton base="24px" onClick={onRemove}>
          <CloseIcon />
        </IconButton>
      )}
    </Grid>
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

export default DropdownContainer;

interface BaseContainerProps {
  selectedFilter: string;
  filterOptionLabels: FilterOptionsLabel[];
  onFilterSelect: (filterKey: SecondaryFiltersKeys) => void;
  closeContainerDropdown?: () => void;
  onAddCompare?: () => void;
  isCompare?: boolean;
  isCompareDisabled?: boolean;
  children: ReactNode;
}
export const BaseContainer: React.FC<BaseContainerProps> = ({
  selectedFilter,
  filterOptionLabels,
  onFilterSelect,
  closeContainerDropdown,
  onAddCompare,
  isCompareDisabled,
  isCompare,
  children,
}) => {
  return (
    <Grid
      width={'400px'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.primary.default}`,
        borderRadius: '3px',
        boxShadow: 'none',
      }}
    >
      {!isCompare ? (
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 0 12px 20px',
            borderBottom: `1px solid ${theme.shades.smoke}`,
          }}
        >
          <IconButton
            base="24px"
            style={{ alignSelf: 'flex-end', paddingBottom: 0, paddingTop: 0 }}
            onClick={closeContainerDropdown}
          >
            <Para fontSize={14} height="16px" color={theme.shades.grey}>
              x
            </Para>
          </IconButton>
          <Para
            fontWeight={500}
            fontSize={13}
            style={{ lineHeight: '20px' }}
            color={theme.shades.grey}
          >
            Apply filters to get specific analysis
          </Para>
        </Grid>
      ) : (
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 0 12px 20px',
            borderBottom: `1px solid ${theme.shades.smoke}`,
          }}
        >
          <IconButton
            base="24px"
            style={{ alignSelf: 'flex-end', paddingBottom: 0, paddingTop: 0 }}
            onClick={closeContainerDropdown}
          >
            <Para fontSize={16} fontWeight={500} height="16px" color={theme.shades.grey}>
              x
            </Para>
          </IconButton>
          <Para
            fontWeight={500}
            fontSize={13}
            style={{ lineHeight: '20px' }}
            color={theme.shades.grey}
          >
            Aggregate one or more attributes
          </Para>
          <Para fontWeight={500} fontSize={13} style={{ lineHeight: '20px' }}>
            The collection shown here
          </Para>
        </Grid>
      )}

      <Grid
        style={{
          flex: 1,
        }}
      >
        <Grid
          flexWidth={4}
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRight: `1px solid ${theme.shades.smoke}`,
          }}
        >
          {filterOptionLabels.map(filterType => (
            <FocusBlock
              style={{
                padding: '15px 20px',
                fontSize: 14,
                opacity: 1,
                fontWeight: selectedFilter === filterType.key ? 700 : 'inherit',
                color: selectedFilter === filterType.key ? theme.primary.default : 'inherit',
              }}
              value={filterType.key}
              key={filterType.key}
              onSelectValue={() => onFilterSelect(filterType.key)}
            >
              {filterType.label}
            </FocusBlock>
          ))}
        </Grid>
        <Grid flexWidth={8}>{children}</Grid>
      </Grid>

      {!isCompare ? (
        <Grid
          style={{
            justifyContent: 'flex-end',
            padding: '8px',
            borderTop: `1px solid ${theme.shades.smoke}`,
          }}
        >
          <TextButton
            onClick={closeContainerDropdown}
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }}
          >
            <Para fontSize={14} color={theme.primary.default}>
              Close x
            </Para>
          </TextButton>
        </Grid>
      ) : (
        <Grid
          style={{
            justifyContent: 'center',
            padding: '8px',
          }}
        >
          <TextButton
            onClick={onAddCompare}
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }}
            disabled={isCompareDisabled}
          >
            <Para fontSize={14} fontWeight={500}>
              Add to Compare
            </Para>
          </TextButton>
        </Grid>
      )}
    </Grid>
  );
};

interface CommonFilterItemProps {
  hasExpandIcon?: boolean;
  checkItemSelected: (item: SelectOption) => boolean;
  onItemSelect: (item: SelectOption) => void;
}

interface FilterItemProps extends CommonFilterItemProps {
  item: SelectOption;
}

export const FilterItem = ({
  hasExpandIcon,
  item,
  checkItemSelected,
  onItemSelect,
}: FilterItemProps) => {
  const isSelected = checkItemSelected(item);

  return (
    <FocusBlock
      style={{
        padding: '14px 10px',
        backgroundColor: isSelected ? theme.shades.smoke : undefined,
      }}
      value={item.value}
      onSelectValue={() => onItemSelect(item)}
      isSelected={isSelected}
    >
      <Grid width="100%" justifyContent="space-between" alignItems="center">
        <label style={{ display: 'flex', alignItems: 'center' }}>
          <VStack alignItems={'left'} spacing={0}>
            <HStack>
              <Radio value="5" checked={isSelected} background={theme.primary.default} />
              <span
                style={{ marginLeft: '8px', fontSize: 14, textAlign: 'left', maxWidth: '20ch' }}
              >
                {item.label}
              </span>
            </HStack>
            <span
              style={{
                marginLeft: '22px',
                fontSize: 12,
                textAlign: 'left',
                maxWidth: '20ch',
                color: THEME_COLORS.secondary[1200],
              }}
            >
              {item.username}
            </span>
          </VStack>
        </label>
        {hasExpandIcon && <img src="/icons/side_expand.svg" alt="expand" width={18} height={18} />}
      </Grid>
    </FocusBlock>
  );
};

interface FilterItemListProps extends CommonFilterItemProps {
  itemList: SelectOption[];
  hideNoneItem?: boolean;
}

export const FilterItemList = ({
  itemList,
  checkItemSelected,
  onItemSelect,
  hasExpandIcon,
}: FilterItemListProps) => {
  return (
    <Fragment>
      {itemList.length ? (
        itemList.map(item => (
          <FilterItem
            key={item.value}
            item={item}
            checkItemSelected={checkItemSelected}
            onItemSelect={onItemSelect}
            hasExpandIcon={hasExpandIcon}
          />
        ))
      ) : (
        <Grid width="100%" justifyContent="center" alignItems="center">
          <Para style={{ padding: '14px 10px' }}>No Options</Para>
        </Grid>
      )}
    </Fragment>
  );
};
