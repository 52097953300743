import {
  REQUEST_USER_ORG,
  REQUEST_USER_ORG_SUCCESS,
  REQUEST_USER_ORG_FAIL,
} from '../../const/organization';
import { IOrganization } from '../../interfaces/IOrganization';

const requestUserOrganization = () => ({
  type: REQUEST_USER_ORG,
  payload: {},
});

const onRequestUserOrganizationSuccess = (organizations: IOrganization[]) => ({
  type: REQUEST_USER_ORG_SUCCESS,
  payload: {
    organizations,
  },
});

const onRequestUserOrganizationFail = (error: any) => ({
  type: REQUEST_USER_ORG_FAIL,
  payload: {
    error,
  },
});

export { requestUserOrganization, onRequestUserOrganizationSuccess, onRequestUserOrganizationFail };
