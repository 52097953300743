export const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
  return {
    userSelect: 'none',
    background: isDragging ? '#E5F2FF' : 'white',
    ...draggableStyle,
  };
};
export const getGroupLevelStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : 'white',
});

export const getNestedComponentStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : 'white',
});
