import React from 'react';
import { EntityType, JSXResponseForEntity } from '../helpers';

const ResponsesForMSTeamMeetingLink: React.FC<
  Omit<JSXResponseForEntity, 'logMessage' | 'getEntityName'>
> = ({ log, getEntityComponent, getEntityComponentWithTruncatedName }) => {
  return log.assignedTo === 'workflow' ? (
    <>
      Workflow (
      {getEntityComponent({
        componentType: 'linkWOPopover',
        type: log.assignedTo as EntityType,
        id: log.workflow_id?.toString(),
        name: log.additionalInfo?.workflow_name,
        workflowId: log.workflow_id,
        workflowExecutionId: log.additionalInfo?.wf_exec_id,
      })}
      )
    </>
  ) : (
    getEntityComponentWithTruncatedName({
      componentType: 'linkWPopover',
      type: log.assignedTo as EntityType,
      id: log.id,
    })
  );
};

export default ResponsesForMSTeamMeetingLink;
