import * as React from 'react';
import { SVGProps } from 'react';

const SvgAppsignalLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M17.5 6.1c-1.5 0-2.6 1.3-3.6 2.8-.7-3.1-1.4-6.2-3.8-6.2S6.9 5.8 6.2 8.9C5.1 7.4 4 6.1 2.6 6.1c-1 0-2.6.7-2.6 3v4.4c0 2.4 1.3 2.9 2 3.1 1.3.3 4.5.7 8 .7s6.7-.3 8-.7c.8-.2 2-.8 2-3.1V9.2c0-2.5-1.6-3.1-2.5-3.1M10 4c1.4 0 2 2.8 2.7 6.3-.8 1-1.7 1.9-2.7 1.9s-1.9-.8-2.7-1.9C8 7.1 8.5 4 10 4m-8.6 9.5V9.2c0-1.5.8-1.7 1.2-1.7 1.1 0 2.2 1.7 3.2 3.2-.8 3.2-1.6 5.1-3.5 4.7-.5-.2-.9-.5-.9-1.9m3.7 2.2c.8-.9 1.4-2.3 1.8-3.8.9.9 1.9 1.6 3.2 1.6s2.3-.7 3.2-1.6c.4 1.5 1 2.9 1.8 3.8-1.6.2-3.4.3-5.1.3s-3.5-.1-4.9-.3m13.6-2.2c0 1.4-.5 1.7-1 1.8-1.9.4-2.7-1.5-3.5-4.7 1.1-1.4 2.2-3.2 3.2-3.2.4 0 1.2.2 1.2 1.7l.1 4.4z"
      style={{
        fill: '#0a1530',
      }}
    />
  </svg>
);

export default SvgAppsignalLogo20X20;
