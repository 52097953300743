import { IAnalyticsPanel, IAnalyticsPanelRow } from '../interfaces/IAnalytics';

export const convertDateString = (date: Date) =>
  `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`;

export const todayString = convertDateString(new Date());
export const last3Months = new Date(new Date().setMonth(new Date().getMonth() - 3));

export const DefaultAnalyticsPanel: (IAnalyticsPanel | IAnalyticsPanelRow)[] = [
  {
    id: '0',
    kind: 'time_series_data',
    name: 'Incident Count',
    gridPos: {
      x: 0,
      y: 0,
      w: 12,
      h: 3,
    },
    chart: 'line',
    query: {
      data_source: 'sq_incidents',
      operation: 'count',
      kind: 'time_series_data',
      operand: '_id',
    },
    panelType: 'prime_line',
  },
  {
    id: 'row::1',
    name: 'Incident Count By Date',
    panelType: 'row',
    gridPos: {
      x: 0,
      y: 3,
      w: 12,
      h: 0.25,
    },
    isDateConnected: true,
  },
  {
    id: '1',
    kind: 'time_series_data',
    name: 'Heat Map',
    gridPos: {
      x: 0,
      y: 3,
      w: 8,
      h: 2,
    },
    chart: 'heat_map',
    query: {
      data_source: 'sq_incidents',
      operation: 'count',
      operand: '_id',
      kind: 'time_series_data',
    },
    panelType: 'heat_map',
  },
  {
    id: '2',
    kind: 'tabular_data',
    name: 'Status',
    gridPos: {
      x: 8,
      y: 3,
      w: 4,
      h: 2,
    },
    chart: 'pie',
    query: {
      data_source: 'sq_incidents',
      operation: 'count',
      operand: '_id',
      kind: 'tabular_data',
      time_range: {
        gt: todayString,
        lt: todayString,
      },
      group_by: 'status',
      limit: 4,
    },
    panelType: 'date_connected',
    chart_config: {
      enableRadialLabels: false,
    },
  },
  {
    id: '3',
    kind: 'tabular_data',
    name: 'Services',
    gridPos: {
      x: 0,
      y: 5,
      w: 6,
      h: 2,
    },
    chart: 'bar',
    query: {
      data_source: 'sq_incidents',
      operation: 'count',
      operand: '_id',
      kind: 'tabular_data',
      time_range: {
        gt: todayString,
        lt: todayString,
      },
      group_by: 'service',
      limit: 5,
      sort: 1,
    },
    panelType: 'date_connected',
  },
  {
    id: '4',
    kind: 'tabular_data',
    name: 'Alert Sources',
    gridPos: {
      x: 6,
      y: 5,
      w: 6,
      h: 2,
    },
    chart: 'bar',
    query: {
      data_source: 'sq_incidents',
      operation: 'count',
      operand: '_id',
      kind: 'tabular_data',
      time_range: {
        gt: todayString,
        lt: todayString,
      },
      group_by: 'integrationType',
      limit: 5,
      sort: 1,
    },
    panelType: 'date_connected',
  },
  {
    id: 'row::2',
    name: 'Incident Count By Service',
    panelType: 'row',
    gridPos: {
      x: 0,
      y: 7,
      w: 12,
      h: 0.25,
    },
  },
  {
    id: '5',
    kind: 'tabular_data',
    name: 'Suppressed',
    gridPos: {
      x: 0,
      y: 7,
      w: 6,
      h: 2,
    },
    chart: 'bar',
    query: {
      data_source: 'sq_incidents',
      operation: 'count',
      operand: '_id',
      kind: 'tabular_data',
      filter_by: {
        status: 'suppressed',
      },
      group_by: 'service',
      limit: 5,
      sort: 1,
    },
    chart_config: {
      colors: '#969696',
    },
    panelType: 'basic',
  },
  {
    id: '7',
    kind: 'tabular_data',
    name: 'Open Incident (Triggered / Acknowledged)',
    gridPos: {
      x: 6,
      y: 7,
      w: 6,
      h: 2,
    },
    chart: 'bar',
    query: {
      data_source: 'sq_incidents',
      operation: 'count',
      operand: '_id',
      kind: 'tabular_data',
      filter_by: {
        open: '1',
      },
      group_by: 'service',
      limit: 5,
      sort: 1,
    },
    chart_config: {
      colors: '#be7fee',
    },
    panelType: 'basic',
  },
  {
    id: '8',
    kind: 'tabular_data',
    name: 'Resolved',
    gridPos: {
      x: 0,
      y: 9,
      w: 6,
      h: 2,
    },
    chart: 'bar',
    query: {
      data_source: 'sq_incidents',
      operation: 'count',
      operand: '_id',
      kind: 'tabular_data',
      filter_by: {
        status: 'resolved',
      },
      group_by: 'service',
      limit: 5,
      sort: 1,
    },
    chart_config: {
      colors: '#5eda9c',
    },
    panelType: 'basic',
  },
];
