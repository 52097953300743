import React, { useState, useEffect } from 'react';
import {
  Grid,
  Theme,
  Para,
  Label,
  TextButton,
  DialogModalFrame,
  InputBlock,
  Heading,
  Pill,
  SpinLoader,
  SnackContext,
} from 'uie/components';
import { ThemeProvider } from 'styled-components';
import { API } from '../../../../../core/api';
import { NewTabIcon, SloEditIcon, SloDetailIcon } from '../../../../../icons';
import SnackBarComp from '../../runbooks/common/snackbar.component';
import { IIncident, SloViolatingIncident } from '../../../../../core/interfaces/IIncidents';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IncidentService } from 'core/services';
import { useHistory } from 'react-router-dom';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_UP_MARKED_AS_FALSE_POSITIVE } from '../../../../../core/const/tracker';
import { T_WA_UP_UNDO_MARKED_AS_FALSE_POSITIVE } from '../../../../../core/const/tracker';
import TimePicker from 'react-time-picker';
import moment, { Moment } from 'moment';
import { SingleDatePicker } from 'react-dates';

const { theme } = Theme;
const snackMsg = {
  default: 'Something went wrong please try again',
  default_SUCCESS: 'Something went wrong please try again',
  UPDATE_SUCCESS: 'Error budget has been updated Successfully!',
};

interface IProps {
  incident: IIncident;
  teamId: string;
  getIncidentDetails: () => void;
  sloData: { slo_violating_incident: SloViolatingIncident };
  alertSource: string | undefined;
}

const SloDetails = (props: IProps) => {
  const history = useHistory();
  const _createSnack = SnackContext();
  const __incidentService = new IncidentService();
  const [openModal, setOpenModal] = useState(false);
  const [isIncidentMarked, setIsIncidentMarked] = useState(false);
  const [isUndo, setIsUndo] = useState(false);
  const [error_budget, setError_budget] = useState<number>();
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [showSnack, setShowSnack] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [background, setBackground] = useState<string>('');
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState<string>('');
  const [error, setError] = useState('');

  const handleShowPicker = (str: string) => {
    setShowDatePicker(str);
  };

  const closeOpenModal = () => {
    setOpenModal(false);
    setStartTime(new Date(props?.sloData?.slo_violating_incident?.start_time));
    setEndTime(new Date(props?.sloData?.slo_violating_incident?.end_time));
  };

  const hideSnackBar = () => {
    setShowSnack(false);
    setMessage('');
  };
  useEffect(() => {
    if (!showSnack) {
      _createSnack(null);
    } else {
      _createSnack(
        <SnackBarComp message={message} background={background} hideSnackBar={hideSnackBar} />,
      );
    }
  }, [showSnack]);

  useEffect(() => {
    setStartTime(new Date(props?.sloData?.slo_violating_incident?.start_time));
    setEndTime(new Date(props?.sloData?.slo_violating_incident?.end_time));
  }, [props.sloData.slo_violating_incident]);

  useEffect(() => {
    setError_budget(
      parseFloat(
        (Math.abs(new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000 / 60).toFixed(
          6,
        ),
      ),
    );
    if (startTime > endTime) {
      setError(`Start time can't be past end time`);
    } else if (endTime < startTime) {
      setError(`End time can't be before start time`);
    } else if (endTime > new Date(new Date().setHours(23, 59, 59, 999))) {
      setError(`End time can't be past today`);
    } else {
      setError('');
    }
  }, [startTime, endTime]);

  const showSnackBar = (msg: string, bg: string, timeout: number) => {
    setMessage(msg);
    setBackground(bg);
    setShowSnack(true);
    setTimeout(hideSnackBar, timeout);
  };

  const markFalsePositive = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const value = e.currentTarget.id.trim();
      if (e.currentTarget && e.currentTarget.id) {
        await __incidentService.isMarkedFalsePositive(
          props.incident.slo_id,
          props?.sloData?.slo_violating_incident?.id,
          e.currentTarget.id,
          props.teamId,
        );
        setIsIncidentMarked(JSON.parse(value));
        setIsUndo(!JSON.parse(value));
        props.getIncidentDetails();
      }
    } catch (err: any) {
      showSnackBar(
        err?.response?.data?.meta?.error_message || 'error connecting to server',
        theme.danger.default,
        3000,
      );
    }
  };

  const updatErrorBudget = async () => {
    setUpdateLoader(true);
    try {
      const response = await __incidentService.getIncidentWithSlos(props.incident, props.teamId);
      const updateErrorBudget = await __incidentService.updateErrorBudget(
        props.incident.slo_id,
        response.data.data.slo_violating_incident.id,
        error_budget,
        props.teamId,
        startTime,
        endTime,
      );
      if (updateErrorBudget) {
        setUpdateLoader(false);
        setOpenModal(false);
        props.getIncidentDetails();
        showSnackBar(snackMsg.UPDATE_SUCCESS, theme.success.default, 3000);
      }
    } catch (err: any) {
      setOpenModal(false);
      setUpdateLoader(false);
      showSnackBar(
        err?.response?.data?.meta?.error_message || 'error connecting to server',
        theme.danger.default,
        3000,
      );
    }
  };

  const fillUpErrorBudget = (e: any) => {
    setError_budget(e.target.value);
  };

  const goToSloDetails = () => {
    history.push(`/slo/${props.incident.slo_id}`);
  };

  const handleCustomTimeChange = (type: 'to' | 'from') => (value: any) => {
    const time = value as Date;
    if (time) {
      const [hh, mm] = time.toString().split(':');

      if (type === 'from') {
        const date = startTime;
        date.setHours(parseInt(hh, 10));
        date.setMinutes(parseInt(mm, 10));
        setStartTime(new Date(date));
      } else if (type === 'to') {
        const date = endTime;
        date.setHours(parseInt(hh, 10));
        date.setMinutes(parseInt(mm, 10));
        setEndTime(new Date(date));
      }
    }
  };

  return (
    <>
      {
        <Grid flexWidth={12}>
          {props?.incident?.slo_id &&
            props?.sloData?.slo_violating_incident?.is_false_positive === false && (
              <Grid type="column" className="incident_details__soft_card invert">
                <Grid
                  type="row"
                  justifyContent="space-between"
                  style={{ paddingRight: '8px' }}
                  className="mb-10"
                >
                  <Label fontSize={14} color={theme.shades.black} fontWeight={500}>
                    SLO Details
                  </Label>
                  <SloDetailIcon className="fillColor" height="16" width="16" />
                </Grid>
                <Para fontSize={14} color={theme.shades.cement}>
                  SLO Name
                </Para>
                <Grid
                  type="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="mb-10 mt-10"
                >
                  <Label fontSize={14} color={theme.shades.black} fontWeight={500}>
                    {props?.sloData?.slo_violating_incident?.slo_name}
                  </Label>
                  <TextButton buttonType="ghost">
                    <NewTabIcon height="16" width="16" onClick={() => goToSloDetails()} />
                  </TextButton>
                </Grid>
                <Para fontSize={14} color={theme.shades.cement} className="mb-10 mt-10">
                  SLI
                </Para>
                <Grid flexWidth={12} type="row" style={{ flexWrap: 'wrap' }}>
                  {props?.sloData &&
                    props?.sloData?.slo_violating_incident &&
                    props?.sloData?.slo_violating_incident?.slis.length > 0 &&
                    props?.sloData?.slo_violating_incident?.slis.map((item: any, indx: number) => {
                      return (
                        <Pill key={indx} className="mr-5 mb-5">
                          <Para fontSize={14}>{item}</Para>
                        </Pill>
                      );
                    })}
                </Grid>
                <Grid
                  type="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="mb-10 mt-20"
                >
                  <Para fontSize={14} color={theme.shades.cement}>
                    Error Budget
                  </Para>

                  {props.alertSource === 'squadcastui' && (
                    <TextButton
                      buttonType="ghost"
                      onClick={() => {
                        setOpenModal(true);
                        setError_budget(props?.sloData?.slo_violating_incident?.error_budget_spent);
                      }}
                    >
                      <SloEditIcon height="16" width="16" />
                    </TextButton>
                  )}
                </Grid>
                <Label fontSize={14} color={theme.shades.black} fontWeight={500}>
                  {props?.sloData?.slo_violating_incident?.error_budget_spent} min
                </Label>
                <Grid flexWidth={12}></Grid>
                <Grid flexWidth={12}>
                  <TextButton
                    style={{
                      borderRadius: '2px',
                      justifyContent: 'center',
                      fontSize: '16px',
                      color: '#0F61DD',
                    }}
                    buttonType="inverted"
                    disabled={isIncidentMarked == true}
                    className="mt-20"
                    width="100%"
                    id="true"
                    onClick={e => {
                      markFalsePositive(e);
                      AppTracker.track(T_WA_UP_MARKED_AS_FALSE_POSITIVE);
                    }}
                  >
                    Mark as false positive
                  </TextButton>
                </Grid>
              </Grid>
            )}
          {props?.incident?.slo_id &&
            props?.sloData?.slo_violating_incident?.is_false_positive === true && (
              <Grid
                type="column"
                style={{ border: '1px dashed #999999', backgroundColor: '#F1F2F5' }}
                className="incident_details__soft_card invert"
              >
                <Grid justifyContent="center" flexWidth={12}>
                  <Para fontSize={14} color="#112246">
                    Incident marked as false positive
                  </Para>
                </Grid>
                <Grid justifyContent="center" alignItems="center" flexWidth={12}>
                  <TextButton
                    buttonType="ghost"
                    disabled={isUndo === true}
                    width="250px"
                    className="pt-20"
                    id="false"
                    style={{
                      fontSize: '12px',
                      color: '#0F61DD',
                    }}
                    onClick={e => {
                      markFalsePositive(e);
                      AppTracker.track(T_WA_UP_UNDO_MARKED_AS_FALSE_POSITIVE);
                    }}
                  >
                    UNDO
                  </TextButton>
                </Grid>
              </Grid>
            )}
        </Grid>
      }
      <DialogModalFrame
        id="errorBudgetUpdate"
        onClose={closeOpenModal}
        padding="16px"
        width="360px"
      >
        {openModal && (
          <Grid flexWidth={12} flexWrap="wrap">
            <Grid flexWidth={12}>
              <Heading fontSize={24} color="#2D3964" fontWeight={500}>
                Edit Error Budget
              </Heading>
            </Grid>
            <Grid type="column" width={'90%'}>
              <p style={{ fontSize: '14px', fontWeight: 600 }}>Duration</p>
              <Grid alignItems="center" style={{ marginBottom: '10px' }}>
                <p
                  className="modal-container-sub-heading"
                  style={{
                    margin: 0,
                    paddingRight: 15,
                    fontSize: '14px',
                    fontWeight: 400,
                  }}
                >
                  Start:
                </p>
                <div style={{ width: '60%', display: 'inline' }}>
                  <div className="event-modal-date-picker-wrapper" style={{ marginRight: '10px' }}>
                    <SingleDatePicker
                      date={moment(startTime)}
                      isOutsideRange={() => false}
                      onDateChange={(date: Moment | null) => {
                        if (date) {
                          setStartTime(
                            new Date(
                              moment(date.toDate())
                                .set({
                                  hour: 0,
                                  minute: 0,
                                  second: 0,
                                  millisecond: 0,
                                })
                                .format(),
                            ),
                          );
                        }
                      }}
                      displayFormat="ddd,MMMM D"
                      focused={showDatePicker === 'start-date'}
                      numberOfMonths={1}
                      onFocusChange={({ focused }) => {
                        handleShowPicker(focused ? 'start-date' : '');
                      }}
                      id={`event-start-date-${0}`}
                      hideKeyboardShortcutsPanel={true}
                      disabled={props.alertSource ? props.alertSource != 'squadcastui' : false}
                    />
                  </div>
                </div>
                <div
                  className="float-right"
                  style={{
                    width: '60%',
                    position: 'relative',
                    textAlign: 'center',
                    border: 'solid 1px #e7ecf5',
                    borderRadius: '3px',
                  }}
                >
                  <TimePicker
                    className="slo-time-picker"
                    value={startTime}
                    onChange={handleCustomTimeChange('from')}
                    clearIcon={null}
                    clockIcon={null}
                    disableClock={true}
                    disabled={props.alertSource ? props.alertSource != 'squadcastui' : false}
                  />
                </div>
              </Grid>

              <Grid alignItems="center">
                <p
                  className="modal-container-sub-heading"
                  style={{
                    margin: 0,
                    paddingRight: 22,
                    fontSize: '14px',
                    fontWeight: 400,
                  }}
                >
                  End:
                </p>
                <div style={{ width: '60%', display: 'inline' }}>
                  <div className="event-modal-date-picker-wrapper" style={{ marginRight: '10px' }}>
                    <SingleDatePicker
                      date={moment(endTime)}
                      isOutsideRange={() => false}
                      onDateChange={(date: Moment | null) => {
                        if (date) {
                          setEndTime(
                            new Date(
                              moment(date.toDate())
                                .set({
                                  hour: 0,
                                  minute: 0,
                                  second: 0,
                                  millisecond: 0,
                                })
                                .format(),
                            ),
                          );
                        }
                      }}
                      displayFormat="ddd,MMMM D"
                      focused={showDatePicker === 'end-date'}
                      numberOfMonths={1}
                      onFocusChange={({ focused }) => {
                        handleShowPicker(focused ? 'end-date' : '');
                      }}
                      id={`event-start-date-${0}`}
                      hideKeyboardShortcutsPanel={true}
                      disabled={props.alertSource ? props.alertSource != 'squadcastui' : false}
                    />
                  </div>
                </div>
                <div
                  className="float-right"
                  style={{
                    width: '60%',
                    position: 'relative',
                    textAlign: 'center',
                    border: 'solid 1px #e7ecf5',
                    borderRadius: '3px',
                  }}
                >
                  <TimePicker
                    className="slo-time-picker"
                    value={endTime}
                    onChange={handleCustomTimeChange('to')}
                    clearIcon={null}
                    clockIcon={null}
                    disableClock={true}
                    disabled={props.alertSource ? props.alertSource != 'squadcastui' : false}
                  />
                </div>
              </Grid>
              <p
                style={{ fontSize: '14px', color: Theme.theme.danger.default }}
                className="float-right"
              >
                {error || ''}
              </p>
            </Grid>
            <Grid flexWidth={12} style={{ marginTop: '15px' }}>
              <Label fontSize={14} color="#8690A4" fontWeight={500}>
                Error Budget
              </Label>
            </Grid>
            <Grid flexWidth={12} className="mt-10">
              <Grid>
                <InputBlock
                  height="24px"
                  style={{ borderColor: '#979797', width: 132, marginRight: 15 }}
                  color="#112246"
                  value={error_budget}
                  disabled={true}
                  onChange={e => fillUpErrorBudget(e)}
                />
              </Grid>
              <Grid className="ml-5 mt-10">
                <Label fontSize={14} color="#112246" fontWeight={500}>
                  Mins
                </Label>
              </Grid>
            </Grid>

            <Grid
              flexWidth={12}
              style={{ marginTop: 60 }}
              className="mt-20 pt-20"
              justifyContent="flex-end"
            >
              <TextButton
                buttonType="inverted"
                style={{
                  borderRadius: '2px',
                  paddingTop: '10 px',
                  paddingLeft: '20 px',
                  paddingRight: '20 px',
                  paddingBottom: '20 px',
                }}
                className="py-10 px-20"
                onClick={() => {
                  setOpenModal(false);
                  setStartTime(new Date(props?.sloData?.slo_violating_incident?.start_time));
                  setEndTime(new Date(props?.sloData?.slo_violating_incident?.end_time));
                }}
              >
                <Para fontSize={16} color="#0F61DD" style={{ fontSize: '16px' }}>
                  Cancel
                </Para>
              </TextButton>
              <TextButton
                style={{ borderRadius: '2px', fontSize: '16px', color: '#fff' }}
                className="ml-10 py-10 px-20"
                value={error_budget}
                onClick={updatErrorBudget}
                disabled={error != ''}
              >
                {updateLoader && (
                  <ThemeProvider theme={Theme.theme}>
                    <SpinLoader color="#fff" />
                  </ThemeProvider>
                )}
                Update
              </TextButton>
            </Grid>
          </Grid>
        )}
      </DialogModalFrame>
    </>
  );
};

export default SloDetails;
