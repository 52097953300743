import { INITIAL_STATE } from '../state';
import {
  L_REQUEST_AP_DISABLE_PREVIEW,
  L_REQUEST_AP_ENABLE_PREVIEW,
  L_REQUEST_AP_APP_ENABLED_PREVIEW,
} from '../../const/appPreview';
import { RESET_STATE } from '../../const/init';
import { IAppPreviewType } from '../../interfaces/IAppPreview';

const initialAppState = INITIAL_STATE.APP_PREVIEW;

const appPreview = (state = initialAppState, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ appPreviews: [] },
      };
    case L_REQUEST_AP_APP_ENABLED_PREVIEW: {
      const preview = action.payload.appPreviews.filter((p: IAppPreviewType) =>
        state.preview.includes(p),
      );
      return {
        ...state,
        ...{ preview, appPreviews: action.payload.appPreviews },
      };
    }
    case L_REQUEST_AP_ENABLE_PREVIEW:
      return {
        ...state,
        ...{ preview: Array.from(new Set([...state.preview, action.payload.preview])) },
      };
    case L_REQUEST_AP_DISABLE_PREVIEW: {
      state.preview.splice(state.preview.indexOf(action.payload.preview), 1);
      return {
        ...state,
        ...{ preview: state.preview },
      };
    }
    default:
      return state;
  }
};

export default appPreview;
