import { IOrgConfig } from 'core/interfaces/IOrgConfig';
import { ITeamRoleAbilities } from '../../../../../../../core/interfaces/ITeams';

export const defaultTeamAbilities = (orgConfig: IOrgConfig): ITeamRoleAbilities => {
  const defaults: ITeamRoleAbilities = {
    escalation_policies: {
      'read-escalation-policies': true,
      'create-escalation-policies': true,
      'delete-escalation-policies': true,
      'update-escalation-policies': true,
    },
    ger: {
      'read-ger': true,
      'create-ger': true,
      'delete-ger': true,
      'update-ger': true,
    },
    postmortems: {
      'read-postmortems': true,
      'create-postmortems': true,
      'delete-postmortems': true,
      'update-postmortems': true,
    },
    schedules: {
      'read-schedules': true,
      'create-schedules': true,
      'delete-schedules': true,
      'update-schedules': true,
    },
    services: {
      'read-services': true,
      'create-services': true,
      'delete-services': true,
      'update-services': true,
    },
    squads: {
      'read-squads': true,
      'create-squads': true,
      'delete-squads': true,
      'update-squads': true,
    },
    status_pages: {
      'read-status-pages': true,
      'create-status-pages': true,
      'delete-status-pages': true,
      'update-status-pages': true,
    },
    runbooks: {
      'read-runbooks': true,
      'create-runbooks': true,
      'delete-runbooks': true,
      'update-runbooks': true,
    },
    slos: {
      'read-slos': true,
      'create-slos': true,
      'delete-slos': true,
      'update-slos': true,
    },
    webforms: {
      'read-webforms': true,
      'create-webforms': true,
      'delete-webforms': true,
      'update-webforms': true,
    },
    workflows: {
      'read-workflows': true,
      'create-workflows': true,
      'delete-workflows': true,
      'update-workflows': true,
    },
    routing_numbers: {
      'read-routing_numbers': true,
      'create-routing_numbers': true,
      'delete-routing_numbers': true,
      'update-routing_numbers': true,
    },
  };

  return {
    ...defaults,
    ...(orgConfig['analyticsv2.enabled']
      ? {
          team_analytics: {
            'read-team-analytics': true,
          },
        }
      : {}),
  };
};
