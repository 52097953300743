import { Box, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { JSONCodeBlock } from 'uie/components';
import { FormButton } from 'library/atoms';
import { CustomDrawerComponent, UnsavedChangesModal } from 'library/molecules';
import { isEmpty } from 'lodash';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { invalidateGERDetail } from '../../query/useGetGerDetail';
import { useGetGerRulesetPayload } from '../../query/useGetGerRulesetPayload';
import { invalidateGERRulesetList } from '../../query/useGetGerRulesetRules';
import { GERContext } from '../../store';
import { ALERT_API_INFO, RULESET_RULE } from '../../types';
import {
  ADD_RULE_TITLE,
  EDIT_RULE_TITLE,
  RULE_MODE,
  RULESET_RULE_FORM_ID,
  VIEW_RULE_TITLE,
} from '../../util';
import { DeleteRule } from './delete';
import { RuleForm } from './form';

type Props = {
  gerId: string;
  alert: ALERT_API_INFO;
  isOpen: boolean;
  mode: RULE_MODE;
  rule?: RULESET_RULE;
  onClose: () => void;
};

export const RuleDetails: FC<Props> = ({ gerId, mode, alert, isOpen, rule, onClose }) => {
  const {
    state: { permissions },
  } = useContext(GERContext);
  const [ruleMode, setRuleMode] = useState(mode);
  const queryClient = useQueryClient();
  const isViewMode = useMemo(() => ruleMode === RULE_MODE.VIEW, [ruleMode]);
  const title = useMemo(
    () =>
      ruleMode === RULE_MODE.VIEW
        ? VIEW_RULE_TITLE
        : ruleMode === RULE_MODE.ADD
        ? ADD_RULE_TITLE
        : EDIT_RULE_TITLE,
    [ruleMode],
  );
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

  const { payload } = useGetGerRulesetPayload(gerId, alert.version, alert.shortName);

  useEffect(() => {
    setRuleMode(mode);
  }, [mode]);

  const onEdit = () => {
    setRuleMode(RULE_MODE.EDIT);
  };

  const afterDelete = () => {
    invalidateGERRulesetList(queryClient);
    invalidateGERDetail(queryClient);
    onDeleteClose();
    onClose();
  };

  const onRuleFormClose = (isSuccess?: boolean) => {
    if (!isSuccess && ruleMode !== RULE_MODE.VIEW) {
      onModalOpen();
    } else {
      closeDrawer(isSuccess);
    }
  };

  const closeDrawer = (isSuccess?: boolean) => {
    if (ruleMode === RULE_MODE.EDIT) {
      setRuleMode(RULE_MODE.VIEW);
      if (isSuccess) {
        onClose();
      }
    } else {
      onClose();
    }
  };

  const onDiscard = () => {
    onModalClose();
    closeDrawer();
  };

  return (
    <>
      <CustomDrawerComponent
        isOpen={isOpen}
        onClose={() => onRuleFormClose()}
        title={title}
        disableBodyPadding
        showBackIcon={ruleMode === RULE_MODE.EDIT}
        footer={
          permissions.canUpdate && (
            <HStack gap={3}>
              {isViewMode ? (
                <>
                  <FormButton title="Edit Rule" onClick={onEdit} />
                  <FormButton
                    title="Delete Rule"
                    variant="secondary-danger"
                    onClick={onDeleteOpen}
                  />
                </>
              ) : (
                <FormButton type="submit" form={RULESET_RULE_FORM_ID} title="Save" />
              )}
            </HStack>
          )
        }
      >
        <HStack alignItems="flex-start">
          <RuleForm
            gerId={gerId}
            alert={alert}
            rule={rule}
            onClose={onRuleFormClose}
            isReadOnly={isViewMode}
            onSuccess={() => onRuleFormClose(true)}
          />
          <Box pt={4}>
            <Text color="secondary.700" variant="body_800">
              Payload Data
            </Text>
            <JSONCodeBlock
              code={isEmpty(payload) ? {} : payload}
              enableSearch={true}
              shellProps={{
                minHeight: '500px',
                minWidth: '350px',
              }}
            />
          </Box>
        </HStack>
      </CustomDrawerComponent>
      {rule && (
        <DeleteRule
          isOpen={isDeleteOpen}
          onSuccess={afterDelete}
          onClose={onDeleteClose}
          id={rule.id}
          gerId={rule.global_event_rule_id}
          alert={alert}
        />
      )}
      <UnsavedChangesModal isOpen={isModalOpen} onClose={onModalClose} onDiscard={onDiscard} />
    </>
  );
};
