import { Box, Button, Center, Divider, HStack, Spinner } from '@chakra-ui/react';
import { FormikProvider } from 'formik';
import React, { Fragment, useState } from 'react';

import { reactQueryConfigSuccess } from '../../../helpers/helper.toast';
import { usePageIssue } from '../../../hooks/usePageIssue';
import IssueForm from '../../../statuspage.add/statuspage.issue/issueForm/issueForm';

interface Props {
  pageId: number;
  onCancel: () => void;
  onSuccess?: () => void;
}

const IssueAdd = (props: Props) => {
  const [disableButton, setDisableButton] = useState(false);

  const { formik, isSuccess, isCreateIssueProgress } = usePageIssue(props.pageId, () => {
    props.onCancel();

    reactQueryConfigSuccess('Issue Added');
    props?.onSuccess && props?.onSuccess();
  });

  return (
    <Fragment>
      {isSuccess ? (
        <Box bgColor="white" pt={2}>
          <form>
            <Box mb={10} px={6}>
              <FormikProvider value={formik}>
                <IssueForm />
              </FormikProvider>
            </Box>
            <Box w="100%" bottom={0} position="fixed" bgColor={'white'} zIndex="2">
              <Divider />
              <HStack py={2} gap={4} px={6}>
                <Button
                  variant="default"
                  isLoading={isCreateIssueProgress}
                  isDisabled={disableButton}
                  onClick={() => {
                    !formik.errors && setDisableButton(true);
                    formik.submitForm();
                  }}
                >
                  Save
                </Button>
                <Button variant="invertedDefault" onClick={() => props.onCancel()}>
                  Cancel
                </Button>
              </HStack>
            </Box>
          </form>
        </Box>
      ) : (
        <Center h="60vh">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      )}
    </Fragment>
  );
};

export default IssueAdd;
