import React from 'react';
import { Grid, Para, SnackBar, Theme, IconButton, SnackContext } from 'uie/components';

const { theme } = Theme;
import { CloseIcon } from '../../../../../icons';

interface ISnackbar {
  background: string;
  message: string;
  hideSnackBar: () => void;
}

const snackBar = (props: ISnackbar) => {
  return (
    <SnackBar background={props.background} maxWidth="500px">
      <Grid alignItems="center" justifyContent="space-between">
        <Para color={theme.shades.white} fontWeight={400}>
          {props.message}
        </Para>
        <IconButton onClick={props.hideSnackBar} className="ml-10">
          <CloseIcon stroke={theme.shades.white} />
        </IconButton>
      </Grid>
    </SnackBar>
  );
};

export default snackBar;
