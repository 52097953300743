import { EVENT_TEMPLATE_TYPE } from 'core/interfaces/IEventWebHooks';

export const EVENT_TEMPLATES_CODE: EVENT_TEMPLATE_TYPE[] = [
  {
    slug: 'Incident Triggered',
    name: 'Incident Triggered',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e670afbb04a577527b4b8",
    "type": "incident.triggered",
    "resource": "incident",
    "timestamp": "2022-10-06T05:26:34.945695898Z"
  },
  "data": {
    "resource_data": {
      "id": "633e670ae998089239c9f293",
      "message": "test incident",
      "description": "test",
      "url": "https://app.squadcast.com/incident/633e670ae998089239c9f293",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "e287c6c3-fff9-40b7-9561-72dc7bf4cf28",
      "status": "triggered",
      "created_at": "2022-10-06T05:26:34.37Z",
      "assigned_to": {
        "id": "629e23f5eb4041a20a43b11c",
        "name": "test",
        "type": "escalationpolicy",
        "assigned_at": "0001-01-01T00:00:00Z",
        "email": "example@example.com"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "test",
        "message": "test incident",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:26:34.256Z"
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    },
    "resource_type": "incident",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    }
  }
}`,
  },
  {
    slug: 'Incident Reassigned',
    name: 'Incident Reassigned',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e69d3fbb04a577527b4bc",
    "type": "incident.reassigned",
    "resource": "incident",
    "timestamp": "2022-10-06T05:38:27.857754614Z"
  },
  "data": {
    "resource_data": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z",
        "email": "example@example.com"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    },
    "resource_type": "incident",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    }
  }
}`,
  },
  {
    slug: 'Incident Acknowledged',
    name: 'Incident Acknowledged',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e697dfbb04a577527b4b9",
    "type": "incident.acknowledged",
    "resource": "incident",
    "timestamp": "2022-10-06T05:37:01.388057811Z"
  },
  "data": {
    "resource_data": {
      "id": "633e670ae998089239c9f293",
      "message": "test incident",
      "description": "test",
      "url": "https://app.squadcast.com/incident/633e670ae998089239c9f293",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "status": "acknowledged",
      "created_at": "2022-10-06T05:26:34.37Z",
      "assigned_to": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "type": "user",
        "assigned_at": "0001-01-01T00:00:00Z",
        "email": "example@example.com"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "test",
        "message": "test incident",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:26:34.256Z"
        },
        {
          "action": "acknowledged",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:37:01.339Z"
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    },
    "resource_type": "incident",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    }
  }
}`,
  },
  {
    slug: 'Incident Resolved',
    name: 'Incident Resolved',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e699ffbb04a577527b4ba",
    "type": "incident.resolved",
    "resource": "incident",
    "timestamp": "2022-10-06T05:37:35.998872552Z"
  },
  "data": {
    "resource_data": {
      "id": "633e670ae998089239c9f293",
      "message": "test incident",
      "description": "test",
      "url": "https://app.squadcast.com/incident/633e670ae998089239c9f293",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "status": "resolved",
      "created_at": "2022-10-06T05:26:34.37Z",
      "assigned_to": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "type": "user",
        "assigned_at": "0001-01-01T00:00:00Z",
        "email": "example@example.com"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "test",
        "message": "test incident",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:26:34.256Z"
        },
        {
          "action": "acknowledged",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:37:01.339Z"
        },
        {
          "action": "resolved",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:37:35.945Z"
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    },
    "resource_type": "incident",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    }
  }
}`,
  },
  {
    slug: 'SLO Violating Incident',
    name: 'SLO-Violating Incident Created',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e6a1efbb04a577527b4bd",
    "type": "incident.slo_violating_incident_created",
    "resource": "slo_violating_incident",
    "timestamp": "2022-10-06T05:39:42.861202433Z"
  },
  "data": {
    "resource_data": {
      "id": "1030",
      "slo": {
        "id": "1540",
        "name": "slo"
      },
      "slis": [
        "availability"
      ],
      "is_false_positive": false,
      "error_budget_spent": 0
    },
    "resource_type": "slo_violating_incident",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'SLO Violation False Positive',
    name: 'SLO-Violating Incident Marked False Positive',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e6a62fbb04a577527b4be",
    "type": "incident.slo_violating_incident_false_positive_marked",
    "resource": "slo_violating_incident",
    "timestamp": "2022-10-06T05:40:50.004482094Z"
  },
  "data": {
    "resource_data": {
      "id": "1030",
      "slo": {
        "id": "1540",
        "name": "slo"
      },
      "slis": [
        "availability"
      ],
      "is_false_positive": true,
      "error_budget_spent": 0
    },
    "resource_type": "slo_violating_incident",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        },
        {
          "action": "slo_false_positive_true",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:40:49.975Z",
          "reason": "Yash Jain has marked the SLO as false positive"
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'SLO Violation Unmark False Positive',
    name: 'SLO-Violating Incident Unmarked False Positive',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e6ab3fbb04a577527b4bf",
    "type": "incident.slo_violating_incident_false_positive_unmarked",
    "resource": "slo_violating_incident",
    "timestamp": "2022-10-06T05:42:11.321267741Z"
  },
  "data": {
    "resource_data": {
      "id": "1030",
      "slo": {
        "id": "1540",
        "name": "slo"
      },
      "slis": [
        "availability"
      ],
      "is_false_positive": false,
      "error_budget_spent": 0
    },
    "resource_type": "slo_violating_incident",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        },
        {
          "action": "slo_false_positive_true",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:40:49.975Z",
          "reason": "Yash Jain has marked the SLO as false positive"
        },
        {
          "action": "slo_false_positive_false",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:42:11.29Z",
          "reason": "Yash Jain as marked the SLO as not a false positive"
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'SLO Violation Incident Error budget update',
    name: 'SLO-Violating Incident Spent Error Budget',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e7f5dfbb04a577527b4c0",
    "type": "incident.slo_violating_incident_error_budget_spent_updated",
    "resource": "slo_violating_incident",
    "timestamp": "2022-10-06T07:10:21.526346133Z"
  },
  "data": {
    "resource_data": {
      "id": "1030",
      "slo": {
        "id": "1540",
        "name": "slo"
      },
      "slis": [
        "availability"
      ],
      "is_false_positive": false,
      "error_budget_spent": 7762
    },
    "resource_type": "slo_violating_incident",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        },
        {
          "action": "slo_false_positive_true",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:40:49.975Z",
          "reason": "Yash Jain has marked the SLO as false positive"
        },
        {
          "action": "slo_false_positive_false",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:42:11.29Z",
          "reason": "Yash Jain as marked the SLO as not a false positive"
        },
        {
          "action": "update_slo_error_budget_spent",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:10:21.478Z",
          "reason": "Yash Jain has updated the SLO Error Budget from 0 to 7762"
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'Incident Notes Created',
    name: 'Incident Notes Created',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e806cfbb04a577527b4c1",
    "type": "incident.incident_notes_created",
    "resource": "incident_notes",
    "timestamp": "2022-10-06T07:14:52.907286419Z"
  },
  "data": {
    "resource_data": {
      "id": "633e806c488a9b8c053e04e1",
      "created_by": {
        "id": "61963608090a650008fff152",
        "email_id": "yash@squadcast.com",
        "name": "Yash Jain"
      },
      "message": {
        "message": "test note",
        "raw_message": "test note"
      },
      "created_at": "2022-10-06T07:14:52.695310844Z",
      "updated_at": "2022-10-06T07:14:52.695310923Z",
      "type": "message"
    },
    "resource_type": "incident_notes",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        },
        {
          "action": "slo_false_positive_true",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:40:49.975Z",
          "reason": "Yash Jain has marked the SLO as false positive"
        },
        {
          "action": "slo_false_positive_false",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:42:11.29Z",
          "reason": "Yash Jain as marked the SLO as not a false positive"
        },
        {
          "action": "update_slo_error_budget_spent",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:10:21.478Z",
          "reason": "Yash Jain has updated the SLO Error Budget from 0 to 7762"
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:14:52.712Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'Incident Notes Updated',
    name: 'Incident Notes Updated',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e80b2fbb04a577527b4c2",
    "type": "incident.incident_notes_updated",
    "resource": "incident_notes",
    "timestamp": "2022-10-06T07:16:02.985875976Z"
  },
  "data": {
    "resource_data": {
      "id": "633e806c488a9b8c053e04e1",
      "created_by": {
        "id": "61963608090a650008fff152",
        "email_id": "yash@squadcast.com",
        "name": "Yash Jain"
      },
      "message": {
        "message": "test note updated",
        "raw_message": "test note updated"
      },
      "created_at": "2022-10-06T07:14:52.695Z",
      "updated_at": "2022-10-06T07:16:02.82Z",
      "type": "message"
    },
    "resource_type": "incident_notes",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        },
        {
          "action": "slo_false_positive_true",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:40:49.975Z",
          "reason": "Yash Jain has marked the SLO as false positive"
        },
        {
          "action": "slo_false_positive_false",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:42:11.29Z",
          "reason": "Yash Jain as marked the SLO as not a false positive"
        },
        {
          "action": "update_slo_error_budget_spent",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:10:21.478Z",
          "reason": "Yash Jain has updated the SLO Error Budget from 0 to 7762"
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:14:52.712Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_updated",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:02.829Z",
          "reason": "Yash Jain updated a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'Incident Notes Deleted',
    name: 'Incident Notes Deleted',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e80dafbb04a577527b4c3",
    "type": "incident.incident_notes_deleted",
    "resource": "incident_notes",
    "timestamp": "2022-10-06T07:16:42.728073667Z"
  },
  "data": {
    "resource_data": {
      "id": "633e806c488a9b8c053e04e1",
      "created_by": {
        "id": "61963608090a650008fff152",
        "email_id": "yash@squadcast.com",
        "name": "Yash Jain"
      },
      "message": {
        "message": "test note updated",
        "raw_message": "test note updated"
      },
      "created_at": "2022-10-06T07:14:52.695Z",
      "updated_at": "2022-10-06T07:16:02.82Z",
      "type": "message"
    },
    "resource_type": "incident_notes",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        },
        {
          "action": "slo_false_positive_true",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:40:49.975Z",
          "reason": "Yash Jain has marked the SLO as false positive"
        },
        {
          "action": "slo_false_positive_false",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:42:11.29Z",
          "reason": "Yash Jain as marked the SLO as not a false positive"
        },
        {
          "action": "update_slo_error_budget_spent",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:10:21.478Z",
          "reason": "Yash Jain has updated the SLO Error Budget from 0 to 7762"
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:14:52.712Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_updated",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:02.829Z",
          "reason": "Yash Jain updated a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_deleted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:42.658Z",
          "reason": "Yash Jain deleted a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'Incident Notes Starred',
    name: 'Incident Notes Starred',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e8167fbb04a577527b4c5",
    "type": "incident.incident_notes_starred",
    "resource": "incident_notes",
    "timestamp": "2022-10-06T07:19:03.574966015Z"
  },
  "data": {
    "resource_data": {
      "id": "633e8163488a9b8c053e04e2",
      "created_by": {
        "id": "61963608090a650008fff152",
        "email_id": "yash@squadcast.com",
        "name": "Yash Jain"
      },
      "message": {
        "message": "test note",
        "raw_message": "test note"
      },
      "created_at": "2022-10-06T07:18:59.691Z",
      "updated_at": "2022-10-06T07:19:03.494Z",
      "type": "message"
    },
    "resource_type": "incident_notes",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        },
        {
          "action": "slo_false_positive_true",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:40:49.975Z",
          "reason": "Yash Jain has marked the SLO as false positive"
        },
        {
          "action": "slo_false_positive_false",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:42:11.29Z",
          "reason": "Yash Jain as marked the SLO as not a false positive"
        },
        {
          "action": "update_slo_error_budget_spent",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:10:21.478Z",
          "reason": "Yash Jain has updated the SLO Error Budget from 0 to 7762"
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:14:52.712Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_updated",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:02.829Z",
          "reason": "Yash Jain updated a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_deleted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:42.658Z",
          "reason": "Yash Jain deleted a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:18:59.699Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_starred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:03.501Z",
          "reason": "Yash Jain starred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'Incident Notes Unstarred',
    name: 'Incident Notes Unstarred',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e818afbb04a577527b4c6",
    "type": "incident.incident_notes_unstarred",
    "resource": "incident_notes",
    "timestamp": "2022-10-06T07:19:38.896156852Z"
  },
  "data": {
    "resource_data": {
      "id": "633e8163488a9b8c053e04e2",
      "created_by": {
        "id": "61963608090a650008fff152",
        "email_id": "yash@squadcast.com",
        "name": "Yash Jain"
      },
      "message": {
        "message": "test note",
        "raw_message": "test note"
      },
      "created_at": "2022-10-06T07:18:59.691Z",
      "updated_at": "2022-10-06T07:19:38.809Z",
      "type": "message"
    },
    "resource_type": "incident_notes",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        },
        {
          "action": "slo_false_positive_true",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:40:49.975Z",
          "reason": "Yash Jain has marked the SLO as false positive"
        },
        {
          "action": "slo_false_positive_false",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:42:11.29Z",
          "reason": "Yash Jain as marked the SLO as not a false positive"
        },
        {
          "action": "update_slo_error_budget_spent",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:10:21.478Z",
          "reason": "Yash Jain has updated the SLO Error Budget from 0 to 7762"
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:14:52.712Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_updated",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:02.829Z",
          "reason": "Yash Jain updated a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_deleted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:42.658Z",
          "reason": "Yash Jain deleted a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:18:59.699Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_starred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:03.501Z",
          "reason": "Yash Jain starred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_unstarred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:38.815Z",
          "reason": "Yash Jain unstarred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'Incident Tags Updated',
    name: 'Incident Tags Updated',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e81b9fbb04a577527b4c7",
    "type": "incident.tags_updated",
    "resource": "incident_tags",
    "timestamp": "2022-10-06T07:20:25.392756374Z"
  },
  "data": {
    "resource_data": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": {
        "severity": {
          "value": "critical",
          "color": "#0f61dd"
        }
      },
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        },
        {
          "action": "slo_false_positive_true",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:40:49.975Z",
          "reason": "Yash Jain has marked the SLO as false positive"
        },
        {
          "action": "slo_false_positive_false",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:42:11.29Z",
          "reason": "Yash Jain as marked the SLO as not a false positive"
        },
        {
          "action": "update_slo_error_budget_spent",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:10:21.478Z",
          "reason": "Yash Jain has updated the SLO Error Budget from 0 to 7762"
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:14:52.712Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_updated",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:02.829Z",
          "reason": "Yash Jain updated a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_deleted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:42.658Z",
          "reason": "Yash Jain deleted a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:18:59.699Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_starred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:03.501Z",
          "reason": "Yash Jain starred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_unstarred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:38.815Z",
          "reason": "Yash Jain unstarred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "tags_modified",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:20:25.248Z",
          "reason": "Incident tags modified by Yash Jain",
          "additional_info": {
            "from": null,
            "to": {
              "severity": {
                "color": "#0f61dd",
                "value": "critical"
              }
            }
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    },
    "resource_type": "incident_tags",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    }
  }
}`,
  },
  {
    slug: 'Incident Task Created',
    name: 'Incident Task Created',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e81f7fbb04a577527b4c8",
    "type": "incident.incident_task_created",
    "resource": "incident_task",
    "timestamp": "2022-10-06T07:21:27.280983469Z"
  },
  "data": {
    "resource_data": {
      "id": "633e81f7488a9b8c053e04e4",
      "tasks": [
        {
          "content": "test task",
          "id": "633e81f7488a9b8c053e04e3",
          "completed": false,
          "completed_at": "0001-01-01T00:00:00Z"
        }
      ],
      "created_by": {
        "id": "61963608090a650008fff152",
        "email_id": "yash@squadcast.com",
        "name": "Yash Jain"
      },
      "deleted": false,
      "deleted_at": "0001-01-01T00:00:00Z"
    },
    "resource_type": "incident_task",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": {
        "severity": {
          "value": "critical",
          "color": "#0f61dd"
        }
      },
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        },
        {
          "action": "slo_false_positive_true",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:40:49.975Z",
          "reason": "Yash Jain has marked the SLO as false positive"
        },
        {
          "action": "slo_false_positive_false",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:42:11.29Z",
          "reason": "Yash Jain as marked the SLO as not a false positive"
        },
        {
          "action": "update_slo_error_budget_spent",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:10:21.478Z",
          "reason": "Yash Jain has updated the SLO Error Budget from 0 to 7762"
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:14:52.712Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_updated",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:02.829Z",
          "reason": "Yash Jain updated a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_deleted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:42.658Z",
          "reason": "Yash Jain deleted a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:18:59.699Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_starred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:03.501Z",
          "reason": "Yash Jain starred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_unstarred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:38.815Z",
          "reason": "Yash Jain unstarred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "tags_modified",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:20:25.248Z",
          "reason": "Incident tags modified by Yash Jain",
          "additional_info": {
            "from": null,
            "to": {
              "severity": {
                "color": "#0f61dd",
                "value": "critical"
              }
            }
          }
        },
        {
          "action": "task_added",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:21:27.114Z",
          "reason": "Yash Jain added a new task",
          "additional_info": {
            "added_task": "test task",
            "content": "test task",
            "index": 0,
            "task_id": "633e81f7488a9b8c053e04e3",
            "tasks_id": "633e81f7488a9b8c053e04e4"
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'Incident Task Updated',
    name: 'Incident Task Updated',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e823afbb04a577527b4c9",
    "type": "incident.incident_task_updated",
    "resource": "incident_task",
    "timestamp": "2022-10-06T07:22:34.561662026Z"
  },
  "data": {
    "resource_data": {
      "id": "633e81f7488a9b8c053e04e4",
      "tasks": [
        {
          "content": "test task updated",
          "id": "633e81f7488a9b8c053e04e3",
          "completed": false,
          "completed_at": "0001-01-01T00:00:00Z"
        }
      ],
      "updated_task": {
        "content": "test task updated",
        "id": "633e81f7488a9b8c053e04e3",
        "completed": false,
        "completed_at": "0001-01-01T00:00:00Z"
      },
      "created_by": {
        "id": "61963608090a650008fff152",
        "email_id": "yash@squadcast.com",
        "name": "Yash Jain"
      },
      "deleted": false,
      "deleted_at": "0001-01-01T00:00:00Z"
    },
    "resource_type": "incident_task",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": {
        "severity": {
          "value": "critical",
          "color": "#0f61dd"
        }
      },
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        },
        {
          "action": "slo_false_positive_true",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:40:49.975Z",
          "reason": "Yash Jain has marked the SLO as false positive"
        },
        {
          "action": "slo_false_positive_false",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:42:11.29Z",
          "reason": "Yash Jain as marked the SLO as not a false positive"
        },
        {
          "action": "update_slo_error_budget_spent",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:10:21.478Z",
          "reason": "Yash Jain has updated the SLO Error Budget from 0 to 7762"
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:14:52.712Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_updated",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:02.829Z",
          "reason": "Yash Jain updated a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_deleted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:42.658Z",
          "reason": "Yash Jain deleted a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:18:59.699Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_starred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:03.501Z",
          "reason": "Yash Jain starred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_unstarred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:38.815Z",
          "reason": "Yash Jain unstarred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "tags_modified",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:20:25.248Z",
          "reason": "Incident tags modified by Yash Jain",
          "additional_info": {
            "from": null,
            "to": {
              "severity": {
                "color": "#0f61dd",
                "value": "critical"
              }
            }
          }
        },
        {
          "action": "task_added",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:21:27.114Z",
          "reason": "Yash Jain added a new task",
          "additional_info": {
            "added_task": "test task",
            "content": "test task",
            "index": 0,
            "task_id": "633e81f7488a9b8c053e04e3",
            "tasks_id": "633e81f7488a9b8c053e04e4"
          }
        },
        {
          "action": "task_updated",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:22:34.525Z",
          "reason": "Yash Jain updated a task",
          "additional_info": {
            "content": "test task updated",
            "index": 0,
            "task_id": "633e81f7488a9b8c053e04e3",
            "tasks_id": "633e81f7488a9b8c053e04e4"
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'Incident Task Deleted',
    name: 'Incident Task Deleted',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e8327fbb04a577527b4ca",
    "type": "incident.incident_task_deleted",
    "resource": "incident_task",
    "timestamp": "2022-10-06T07:26:31.983228958Z"
  },
  "data": {
    "resource_data": {
      "id": "633e81f7488a9b8c053e04e4",
      "tasks": [],
      "deleted_task": {
        "content": "test task updated",
        "id": "633e81f7488a9b8c053e04e3",
        "completed": false,
        "completed_at": "0001-01-01T00:00:00Z"
      },
      "created_by": {
        "id": "61963608090a650008fff152",
        "email_id": "yash@squadcast.com",
        "name": "Yash Jain"
      },
      "deleted": false,
      "deleted_at": "0001-01-01T00:00:00Z"
    },
    "resource_type": "incident_task",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": {
        "severity": {
          "value": "critical",
          "color": "#0f61dd"
        }
      },
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        },
        {
          "action": "slo_false_positive_true",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:40:49.975Z",
          "reason": "Yash Jain has marked the SLO as false positive"
        },
        {
          "action": "slo_false_positive_false",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:42:11.29Z",
          "reason": "Yash Jain as marked the SLO as not a false positive"
        },
        {
          "action": "update_slo_error_budget_spent",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:10:21.478Z",
          "reason": "Yash Jain has updated the SLO Error Budget from 0 to 7762"
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:14:52.712Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_updated",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:02.829Z",
          "reason": "Yash Jain updated a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_deleted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:42.658Z",
          "reason": "Yash Jain deleted a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:18:59.699Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_starred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:03.501Z",
          "reason": "Yash Jain starred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_unstarred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:38.815Z",
          "reason": "Yash Jain unstarred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "tags_modified",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:20:25.248Z",
          "reason": "Incident tags modified by Yash Jain",
          "additional_info": {
            "from": null,
            "to": {
              "severity": {
                "color": "#0f61dd",
                "value": "critical"
              }
            }
          }
        },
        {
          "action": "task_added",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:21:27.114Z",
          "reason": "Yash Jain added a new task",
          "additional_info": {
            "added_task": "test task",
            "content": "test task",
            "index": 0,
            "task_id": "633e81f7488a9b8c053e04e3",
            "tasks_id": "633e81f7488a9b8c053e04e4"
          }
        },
        {
          "action": "task_updated",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:22:34.525Z",
          "reason": "Yash Jain updated a task",
          "additional_info": {
            "content": "test task updated",
            "index": 0,
            "task_id": "633e81f7488a9b8c053e04e3",
            "tasks_id": "633e81f7488a9b8c053e04e4"
          }
        },
        {
          "action": "task_deleted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:26:31.952Z",
          "reason": "Yash Jain deleted a task",
          "additional_info": {
            "index": 0,
            "task_id": "633e81f7488a9b8c053e04e3"
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'Incident Task Completed',
    name: 'Incident Task Completed',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e835bfbb04a577527b4cc",
    "type": "incident.incident_task_completed",
    "resource": "incident_task",
    "timestamp": "2022-10-06T07:27:23.616935713Z"
  },
  "data": {
    "resource_data": {
      "id": "633e81f7488a9b8c053e04e4",
      "tasks": [
        {
          "content": "test",
          "id": "633e8358488a9b8c053e04e5",
          "completed": true,
          "completed_at": "0001-01-01T00:00:00Z"
        }
      ],
      "created_by": {
        "id": "61963608090a650008fff152",
        "email_id": "yash@squadcast.com",
        "name": "Yash Jain"
      },
      "deleted": false,
      "deleted_at": "0001-01-01T00:00:00Z"
    },
    "resource_type": "incident_task",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": {
        "severity": {
          "value": "critical",
          "color": "#0f61dd"
        }
      },
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        },
        {
          "action": "slo_false_positive_true",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:40:49.975Z",
          "reason": "Yash Jain has marked the SLO as false positive"
        },
        {
          "action": "slo_false_positive_false",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:42:11.29Z",
          "reason": "Yash Jain as marked the SLO as not a false positive"
        },
        {
          "action": "update_slo_error_budget_spent",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:10:21.478Z",
          "reason": "Yash Jain has updated the SLO Error Budget from 0 to 7762"
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:14:52.712Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_updated",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:02.829Z",
          "reason": "Yash Jain updated a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_deleted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:42.658Z",
          "reason": "Yash Jain deleted a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:18:59.699Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_starred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:03.501Z",
          "reason": "Yash Jain starred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_unstarred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:38.815Z",
          "reason": "Yash Jain unstarred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "tags_modified",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:20:25.248Z",
          "reason": "Incident tags modified by Yash Jain",
          "additional_info": {
            "from": null,
            "to": {
              "severity": {
                "color": "#0f61dd",
                "value": "critical"
              }
            }
          }
        },
        {
          "action": "task_added",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:21:27.114Z",
          "reason": "Yash Jain added a new task",
          "additional_info": {
            "added_task": "test task",
            "content": "test task",
            "index": 0,
            "task_id": "633e81f7488a9b8c053e04e3",
            "tasks_id": "633e81f7488a9b8c053e04e4"
          }
        },
        {
          "action": "task_updated",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:22:34.525Z",
          "reason": "Yash Jain updated a task",
          "additional_info": {
            "content": "test task updated",
            "index": 0,
            "task_id": "633e81f7488a9b8c053e04e3",
            "tasks_id": "633e81f7488a9b8c053e04e4"
          }
        },
        {
          "action": "task_deleted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:26:31.952Z",
          "reason": "Yash Jain deleted a task",
          "additional_info": {
            "index": 0,
            "task_id": "633e81f7488a9b8c053e04e3"
          }
        },
        {
          "action": "task_added",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:27:20.732Z",
          "reason": "Yash Jain added a new task",
          "additional_info": {
            "added_task": "test",
            "content": "test",
            "index": 0,
            "task_id": "633e8358488a9b8c053e04e5",
            "tasks_id": "633e81f7488a9b8c053e04e4"
          }
        },
        {
          "action": "task_checked",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:27:23.582Z",
          "reason": "Yash Jain checked task as done",
          "additional_info": {
            "completed": true,
            "content": "test",
            "index": 0,
            "task_id": "633e8358488a9b8c053e04e5",
            "tasks_id": "633e81f7488a9b8c053e04e4"
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'Incident Task Uncompleted',
    name: 'Incident Task Uncompleted',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e8387fbb04a577527b4cd",
    "type": "incident.incident_task_uncompleted",
    "resource": "incident_task",
    "timestamp": "2022-10-06T07:28:07.393974813Z"
  },
  "data": {
    "resource_data": {
      "id": "633e81f7488a9b8c053e04e4",
      "tasks": [
        {
          "content": "test",
          "id": "633e8358488a9b8c053e04e5",
          "completed": false,
          "completed_at": "0001-01-01T00:00:00Z"
        }
      ],
      "created_by": {
        "id": "61963608090a650008fff152",
        "email_id": "yash@squadcast.com",
        "name": "Yash Jain"
      },
      "deleted": false,
      "deleted_at": "0001-01-01T00:00:00Z"
    },
    "resource_type": "incident_task",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633e69cce9980815a9c9f2a3",
      "message": "incident reassign",
      "description": "",
      "url": "https://app.squadcast.com/incident/633e69cce9980815a9c9f2a3",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "8ea5fc83-e4f0-4256-9fd3-bb2fd7a80a7d",
      "status": "triggered",
      "created_at": "2022-10-06T05:38:20.198Z",
      "assigned_to": {
        "id": "61a461cfa06594e09eda4288",
        "name": "Yash",
        "type": "squad",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": {
        "severity": {
          "value": "critical",
          "color": "#0f61dd"
        }
      },
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "incident reassign",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:38:20.171Z"
        },
        {
          "action": "reassigned",
          "assigned_to": "squad",
          "name": "Yash",
          "time": "2022-10-06T05:38:27.729Z"
        },
        {
          "action": "slo_incident_promoted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:39:42.826Z",
          "reason": "Yash Jain has promoted the incident to SLO Violating Incident"
        },
        {
          "action": "slo_false_positive_true",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:40:49.975Z",
          "reason": "Yash Jain has marked the SLO as false positive"
        },
        {
          "action": "slo_false_positive_false",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T05:42:11.29Z",
          "reason": "Yash Jain as marked the SLO as not a false positive"
        },
        {
          "action": "update_slo_error_budget_spent",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:10:21.478Z",
          "reason": "Yash Jain has updated the SLO Error Budget from 0 to 7762"
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:14:52.712Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_updated",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:02.829Z",
          "reason": "Yash Jain updated a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_deleted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:16:42.658Z",
          "reason": "Yash Jain deleted a note",
          "additional_info": {
            "note": "test note updated",
            "note_id": "633e806c488a9b8c053e04e1"
          }
        },
        {
          "action": "note_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:18:59.699Z",
          "reason": "Yash Jain created a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_starred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:03.501Z",
          "reason": "Yash Jain starred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "note_unstarred",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:19:38.815Z",
          "reason": "Yash Jain unstarred a note",
          "additional_info": {
            "note": "test note",
            "note_id": "633e8163488a9b8c053e04e2"
          }
        },
        {
          "action": "tags_modified",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:20:25.248Z",
          "reason": "Incident tags modified by Yash Jain",
          "additional_info": {
            "from": null,
            "to": {
              "severity": {
                "color": "#0f61dd",
                "value": "critical"
              }
            }
          }
        },
        {
          "action": "task_added",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:21:27.114Z",
          "reason": "Yash Jain added a new task",
          "additional_info": {
            "added_task": "test task",
            "content": "test task",
            "index": 0,
            "task_id": "633e81f7488a9b8c053e04e3",
            "tasks_id": "633e81f7488a9b8c053e04e4"
          }
        },
        {
          "action": "task_updated",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:22:34.525Z",
          "reason": "Yash Jain updated a task",
          "additional_info": {
            "content": "test task updated",
            "index": 0,
            "task_id": "633e81f7488a9b8c053e04e3",
            "tasks_id": "633e81f7488a9b8c053e04e4"
          }
        },
        {
          "action": "task_deleted",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:26:31.952Z",
          "reason": "Yash Jain deleted a task",
          "additional_info": {
            "index": 0,
            "task_id": "633e81f7488a9b8c053e04e3"
          }
        },
        {
          "action": "task_added",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:27:20.732Z",
          "reason": "Yash Jain added a new task",
          "additional_info": {
            "added_task": "test",
            "content": "test",
            "index": 0,
            "task_id": "633e8358488a9b8c053e04e5",
            "tasks_id": "633e81f7488a9b8c053e04e4"
          }
        },
        {
          "action": "task_checked",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:27:23.582Z",
          "reason": "Yash Jain checked task as done",
          "additional_info": {
            "completed": true,
            "content": "test",
            "index": 0,
            "task_id": "633e8358488a9b8c053e04e5",
            "tasks_id": "633e81f7488a9b8c053e04e4"
          }
        },
        {
          "action": "task_unchecked",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T07:28:07.361Z",
          "reason": "Yash Jain checked task as not done",
          "additional_info": {
            "completed": false,
            "content": "test",
            "index": 0,
            "task_id": "633e8358488a9b8c053e04e5",
            "tasks_id": "633e81f7488a9b8c053e04e4"
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'Incident Communication Channel Created',
    name: 'Communication Channel Created',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633eab6cfbb04a577527b4d1",
    "type": "incident.communication_channel_created",
    "resource": "communication_channel",
    "timestamp": "2022-10-06T10:18:20.636856327Z"
  },
  "data": {
    "resource_data": {
      "id": "22",
      "url": "https://example.com",
      "type": "other",
      "title": "example",
      "channel_id": "",
      "archived_at": null
    },
    "resource_type": "communication_channel",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633ea656e998088559c9f2f1",
      "message": "test incident whatever",
      "description": "",
      "url": "https://app.squadcast.com/incident/633ea656e998088559c9f2f1",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "e1a8a3f9-a2aa-41e3-9af9-f7f22e6c7bea",
      "status": "triggered",
      "created_at": "2022-10-06T09:56:38.241Z",
      "assigned_to": {
        "id": "629e23f5eb4041a20a43b11c",
        "name": "test",
        "type": "escalationpolicy",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "test incident whatever",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T09:56:38.169Z"
        },
        {
          "action": "communication_card_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T10:18:20.605Z",
          "reason": "Yash Jain attached a communication card named as example ",
          "additional_info": {
            "communication_card": {
              "archivedat": null,
              "baseschema": {
                "createdat": "2022-10-06T10:18:20.598Z",
                "deletedat": {
                  "time": "0001-01-01T00:00:00Z",
                  "valid": false
                },
                "updatedat": "2022-10-06T10:18:20.598Z"
              },
              "channelid": "",
              "id": 22,
              "mincidentid": "633ea656e998088559c9f2f1",
              "title": "example",
              "type": "other",
              "url": "https://example.com"
            },
            "communication_card_id": 22
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'Incident Communication Channel Updated',
    name: 'Communication Channel Updated',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633eabadfbb04a577527b4d2",
    "type": "incident.communication_channel_updated",
    "resource": "communication_channel",
    "timestamp": "2022-10-06T10:19:25.053317458Z"
  },
  "data": {
    "resource_data": {
      "id": "22",
      "url": "https://example.com",
      "type": "other",
      "title": "example updated",
      "channel_id": "",
      "archived_at": null
    },
    "resource_type": "communication_channel",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633ea656e998088559c9f2f1",
      "message": "test incident whatever",
      "description": "",
      "url": "https://app.squadcast.com/incident/633ea656e998088559c9f2f1",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "e1a8a3f9-a2aa-41e3-9af9-f7f22e6c7bea",
      "status": "triggered",
      "created_at": "2022-10-06T09:56:38.241Z",
      "assigned_to": {
        "id": "629e23f5eb4041a20a43b11c",
        "name": "test",
        "type": "escalationpolicy",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "test incident whatever",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T09:56:38.169Z"
        },
        {
          "action": "communication_card_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T10:18:20.605Z",
          "reason": "Yash Jain attached a communication card named as example ",
          "additional_info": {
            "communication_card": {
              "archivedat": null,
              "baseschema": {
                "createdat": "2022-10-06T10:18:20.598Z",
                "deletedat": {
                  "time": "0001-01-01T00:00:00Z",
                  "valid": false
                },
                "updatedat": "2022-10-06T10:18:20.598Z"
              },
              "channelid": "",
              "id": 22,
              "mincidentid": "633ea656e998088559c9f2f1",
              "title": "example",
              "type": "other",
              "url": "https://example.com"
            },
            "communication_card_id": 22
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'Incident Communication Channel Deleted',
    name: 'Communication Channel Deleted',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633eabd7fbb04a577527b4d3",
    "type": "incident.communication_channel_deleted",
    "resource": "communication_channel",
    "timestamp": "2022-10-06T10:20:07.580119371Z"
  },
  "data": {
    "resource_data": {
      "id": "22",
      "url": "https://example.com",
      "type": "other",
      "title": "example updated",
      "channel_id": "",
      "archived_at": null
    },
    "resource_type": "communication_channel",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633ea656e998088559c9f2f1",
      "message": "test incident whatever",
      "description": "",
      "url": "https://app.squadcast.com/incident/633ea656e998088559c9f2f1",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "e1a8a3f9-a2aa-41e3-9af9-f7f22e6c7bea",
      "status": "triggered",
      "created_at": "2022-10-06T09:56:38.241Z",
      "assigned_to": {
        "id": "629e23f5eb4041a20a43b11c",
        "name": "test",
        "type": "escalationpolicy",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "test incident whatever",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T09:56:38.169Z"
        },
        {
          "action": "communication_card_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T10:18:20.605Z",
          "reason": "Yash Jain attached a communication card named as example ",
          "additional_info": {
            "communication_card": {
              "archivedat": null,
              "baseschema": {
                "createdat": "2022-10-06T10:18:20.598Z",
                "deletedat": {
                  "time": "0001-01-01T00:00:00Z",
                  "valid": false
                },
                "updatedat": "2022-10-06T10:18:20.598Z"
              },
              "channelid": "",
              "id": 22,
              "mincidentid": "633ea656e998088559c9f2f1",
              "title": "example",
              "type": "other",
              "url": "https://example.com"
            },
            "communication_card_id": 22
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
  {
    slug: 'Priority Updated',
    name: 'Incident Priority Updated',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633e670afbb04a577527b4b8",
    "type": "incident.priority_updated",
    "resource": "incident",
    "timestamp": "2022-10-06T05:26:34.945695898Z"
  },
  "data": {
    "resource_data": {
      "id": "633e670ae998089239c9f293",
      "message": "test incident",
      "description": "test",
      "url": "https://app.squadcast.com/incident/633e670ae998089239c9f293",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "e287c6c3-fff9-40b7-9561-72dc7bf4cf28",
      "status": "triggered",
      "created_at": "2022-10-06T05:26:34.37Z",
      "assigned_to": {
        "id": "629e23f5eb4041a20a43b11c",
        "name": "test",
        "type": "escalationpolicy",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "test",
        "message": "test incident",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
       {
            "action" : "incident_priority_updated",
            "assignedBy" : "6194adafcc885c0007ba3b4a",
            "time" : "2023-12-14T08:22:35.536Z",
            "reason" : "Priority updated from P1 to P2 by Sagar CS s",
            "additionalInfo" : {
                "previous_priority" : "P1",
                "new_priority" : "P2"
            }
        },
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T05:26:34.256Z"
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    },
    "resource_type": "incident",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    }
  }
}`,
  },
  {
    slug: 'Statuspage Updated',
    name: 'StatusPage Updated',
    payload_content: `{
  "version": "v2",
  "event": {
    "id": "633eac06fbb04a577527b4d4",
    "type": "incident.statuspage_updated",
    "resource": "statuspage",
    "timestamp": "2022-10-06T10:20:54.350471243Z"
  },
  "data": {
    "resource_data": {
      "id": "63204c66b643a9ffcbf70d23",
      "user": {
        "id": "61963608090a650008fff152",
        "email_id": "yash@squadcast.com",
        "name": "Yash Jain"
      },
      "updated_at": "2022-10-06T10:20:54.274Z",
      "message": "cscsc",
      "status": "resolve",
      "is_public": true
    },
    "resource_type": "statuspage",
    "organization": {
      "id": "61963631090a650008fff21d",
      "name": "sqdev"
    },
    "team": {
      "id": "6196371cda7b4ab764b13a1f",
      "name": "Yash"
    },
    "incident": {
      "id": "633ea656e998088559c9f2f1",
      "message": "test incident whatever",
      "description": "",
      "url": "https://app.squadcast.com/incident/633ea656e998088559c9f2f1",
      "alert_source": {
        "id": "6077f7225fdc7075e371685f",
        "type": "Squadcast UI",
        "short_name": "squadcastui"
      },
      "service": {
        "id": "62f0fdde613c98528db9f8bc",
        "name": "Service Test2",
        "slug": "service-test2"
      },
      "request_id": "e1a8a3f9-a2aa-41e3-9af9-f7f22e6c7bea",
      "status": "triggered",
      "created_at": "2022-10-06T09:56:38.241Z",
      "assigned_to": {
        "id": "629e23f5eb4041a20a43b11c",
        "name": "test",
        "type": "escalationpolicy",
        "assigned_at": "0001-01-01T00:00:00Z"
      },
      "tags": null,
      "event_payload": {
        "assignee": {
          "id": "629e23f5eb4041a20a43b11c",
          "type": "escalationpolicy"
        },
        "attachments": [],
        "created_by": "61963608090a650008fff152",
        "description": "",
        "message": "test incident whatever",
        "tags": {
          "": {
            "color": "#0C93E3",
            "value": ""
          }
        }
      },
      "timeline": [
        {
          "action": "triggered",
          "assigned_to": "escalationpolicy",
          "name": "test",
          "time": "2022-10-06T09:56:38.169Z"
        },
        {
          "action": "communication_card_created",
          "assigned_to": "user",
          "name": "Yash Jain",
          "time": "2022-10-06T10:18:20.605Z",
          "reason": "Yash Jain attached a communication card named as example ",
          "additional_info": {
            "communication_card": {
              "archivedat": null,
              "baseschema": {
                "createdat": "2022-10-06T10:18:20.598Z",
                "deletedat": {
                  "time": "0001-01-01T00:00:00Z",
                  "valid": false
                },
                "updatedat": "2022-10-06T10:18:20.598Z"
              },
              "channelid": "",
              "id": 22,
              "mincidentid": "633ea656e998088559c9f2f1",
              "title": "example",
              "type": "other",
              "url": "https://example.com"
            },
            "communication_card_id": 22
          }
        }
      ],
      "event_count": 1,
      "owner": {
        "id": "6196371cda7b4ab764b13a1f",
        "name": "Yash",
        "type": "team"
      },
      "manually_created_by": {
        "id": "61963608090a650008fff152",
        "name": "Yash Jain",
        "email": "example@org.com"
      }
    }
  }
}`,
  },
];
