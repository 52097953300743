import React, { PureComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router';
import './index.css';
import GetStarted from './getStarted';
import LogIn from './logIn';
import A0 from './a0';
import ForgotPassword from './forgotPassword';
import ResetPassword from './resetPassword';
import { Frame } from 'uie/components';
import { Banner } from '../../components/Banner';
import { API } from 'core/api';
import SsoLogin from './ssoLogin';

interface IProps {
  networkState: 'offline' | 'online';
}

class AuthRoutes extends PureComponent<IProps> {
  render() {
    return (
      <Frame isConnected={this.props.networkState === 'online'}>
        <div className="auth-flex-row flex-row-reverse">
          <div className="auth-flex-w-2-5 auth-main-navigation-content">
            <div className="auth-common-container">
              <BrowserRouter>
                <Switch>
                  <Route exact={true} path="/" component={LogIn} />
                  {/* Route registering to www.squadcast.com */}
                  {API.env === 'dev' ? (
                    <Route exact={true} path="/register" component={GetStarted} />
                  ) : (
                    <Route
                      exact={true}
                      path="/register"
                      render={() => {
                        location.assign(
                          `${API.config.squadcastWebsite}/register?${API.config.tld}`,
                        );
                        return <></>;
                      }}
                    />
                  )}
                  <Route exact={true} path="/forgot-password" component={ForgotPassword} />
                  <Route exact={true} path="/verify/:_id" component={A0} />
                  <Route exact={true} path="/reset/:_id" component={A0} />
                  <Route exact={true} path="/reset-password" component={ResetPassword} />
                  <Route exact={true} path="/sso-login" component={SsoLogin} />
                  <Route exact={true} path="/activate-user/:_id" component={A0} />
                  <Route exact={true} path="/sso/saml" component={A0} />
                  <Route exact={true} path="/sso/enabled" component={A0} />
                  <Route exact={true} path="/:page" component={A0} />
                  <Route exact={true} path="/:page/:id" component={A0} />
                  <Route exact={true} path="/authenticate" component={A0} />
                  <Redirect from="*" to="/" />
                </Switch>
              </BrowserRouter>
            </div>
          </div>
          <div className="auth-adver auth-flex-w-3-5 auth-flex-col auth-main-display-content">
            <Banner />
          </div>
        </div>
      </Frame>
    );
  }
}

export { AuthRoutes };
