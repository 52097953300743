import { BillingService } from 'core';
import { T_WA_INCIDENT_SUMMARY_INCIDENT_SUMMARY_GENERATED } from 'core/const/tracker';
import { IAppState } from 'core/interfaces/IAppState';
import {
  IIncident,
  IIncidentOverviewResponse,
  IIncidentSummaryResponse,
  IIncidentSummaryResponseMetadata,
} from 'core/interfaces/IIncidents';
import { useToast } from 'library/atoms';
import React, { createContext, useEffect, useState } from 'react';
import { useQueries, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';
import { AppTracker } from 'shared/analytics/tracker';

import { sendIncidentSummaryFeedback } from '../api';
import { getIncidentOverviewQuery, getIncidentSummaryQuery } from '../api/queries';
import { IncidentSummaryDrawerState } from '../types';
import incidentSummaryMarkdown from './copy';
import summaryLastUpdatedAtFormat from '../utils/lastUpdatedAt';

interface IncidentSummaryContextProps {
  incidentId: string;
  incident: IIncident;
  summaryDrawerOpen: boolean;
  setSummaryDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  drawerState: IncidentSummaryDrawerState;
  refetchSummaryAndOverview: () => void;
  summary: UseQueryResult<
    | {
        data: IIncidentSummaryResponse;
        meta: IIncidentSummaryResponseMetadata;
      }
    | undefined
  >;
  overview: UseQueryResult<
    | {
        data: IIncidentOverviewResponse;
      }
    | undefined
  >;
  setSummaryDrawerStateFeedback: () => void;
  setSummaryDrawerStateSuccess: () => void;
  feedback: string;
  setFeedback: (feedback: string) => void;
  feedbackSubmitting: boolean;
  sendPositiveFeedback: () => void;
  sendNegativeFeedback: () => void;
  canUseIncidentSummary: boolean;
  showUpgradeModal: boolean;
  setShowUpgradeModal: React.Dispatch<React.SetStateAction<boolean>>;
  getParticipants: () => IIncidentOverviewResponse['participants'];
  lastUpdatedTimestampString: () => string;
  markdownSummaryForCopy: () => string;
}

export const IncidentSummaryContext = createContext<IncidentSummaryContextProps | undefined>(
  undefined,
);

export const IncidentSummaryProvider: React.FC<{
  incidentId: string;
  incident: IIncident;
  children: React.ReactNode;
}> = ({ incidentId, incident, children }) => {
  const appState = useSelector((state: IAppState) => state);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [drawerState, setDrawerState] = useState<IncidentSummaryDrawerState>(
    IncidentSummaryDrawerState.LOADING,
  );
  const [summaryDrawerOpen, setSummaryDrawerOpen] = useState(false);
  const [overview, summary] = useQueries([
    getIncidentOverviewQuery(incidentId, summaryDrawerOpen),
    getIncidentSummaryQuery(incidentId, summaryDrawerOpen),
  ]);
  const [summaryGenerated, setSummaryGenerated] = useState(false);
  // TODO: Use new FormWrapper instead
  const [feedback, setFeedback] = useState('');
  const [feedbackSubmitting, setFeedbackSubmitting] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if ((overview.isError || summary.isError) && drawerState !== IncidentSummaryDrawerState.ERROR) {
      setDrawerState(IncidentSummaryDrawerState.ERROR);
    }
    if (
      (overview.isLoading || summary.isLoading || overview.isRefetching || summary.isRefetching) &&
      drawerState !== IncidentSummaryDrawerState.LOADING
    ) {
      setDrawerState(IncidentSummaryDrawerState.LOADING);
    }
    if (
      overview.data &&
      summary.data &&
      !overview.isLoading &&
      !summary.isLoading &&
      !overview.isRefetching &&
      !summary.isRefetching &&
      drawerState !== IncidentSummaryDrawerState.FEEDBACK
    ) {
      setDrawerState(IncidentSummaryDrawerState.SUCCESS);
      if (!summaryGenerated) {
        setSummaryGenerated(true);
        AppTracker.track(T_WA_INCIDENT_SUMMARY_INCIDENT_SUMMARY_GENERATED, {
          'Incident ID': incidentId,
        });
      }
    }
  }, [overview, summary]);

  useEffect(() => {
    if (summaryDrawerOpen && drawerState === IncidentSummaryDrawerState.LOADING) {
      if (!overview.data && !overview.isLoading && !overview.isRefetching) {
        overview.refetch();
      }
      if (!summary.data && !summary.isLoading && !summary.isRefetching) {
        summary.refetch();
      }
    }
  }, [summaryDrawerOpen]);

  const refetchSummaryAndOverview = () => {
    if (drawerState !== IncidentSummaryDrawerState.ERROR) return;
    Promise.all([overview.refetch(), summary.refetch()]);
    setSummaryGenerated(false);
  };

  const setSummaryDrawerStateFeedback = () => {
    setDrawerState(IncidentSummaryDrawerState.FEEDBACK);
  };

  const setSummaryDrawerStateSuccess = () => {
    setDrawerState(IncidentSummaryDrawerState.SUCCESS);
  };

  const sendPositiveFeedback = async () => {
    setFeedbackSubmitting(true);

    try {
      const res = await sendIncidentSummaryFeedback(incidentId, {
        feedback: 'positive',
        comment: '',
      });

      if (res.status === 200) {
        toast({
          status: 'success',
          text: 'Feedback successfully sent',
        });
        setSummaryDrawerStateSuccess();
      } else {
        throw new Error('Failed to send feedback', {
          cause: res.data,
        });
      }
    } catch (err) {
      toast({
        status: 'error',
        text: `Error: Failed to send feedback. ${String(err)}`,
      });
    } finally {
      setFeedbackSubmitting(false);
    }
  };

  const sendNegativeFeedback = async () => {
    setFeedbackSubmitting(true);
    try {
      const res = await sendIncidentSummaryFeedback(incidentId, {
        feedback: 'negative',
        comment: feedback,
      });
      if (res.status === 200) {
        toast({
          status: 'success',
          text: 'Feedback successfully sent',
        });
        setFeedback('');
        setSummaryDrawerOpen(false);
      } else {
        throw new Error('Failed to send feedback', {
          cause: res.data,
        });
      }
    } catch (err) {
      toast({
        status: 'error',
        text: `Error: Failed to send feedback. ${String(err)}`,
      });
    } finally {
      setFeedbackSubmitting(false);
    }
  };

  // Overview API doesn't return deleted participants. If current assignee is deleted, we add it back here
  const getParticipants = () => {
    if (!overview.data?.data.participants) return [];

    const currAssignedTo = incident.assignedTo[incident.assignedTo.length - 1];
    const participants = overview.data?.data.participants;

    const currentAssigneeInParticipants = participants?.find(
      p => p.id === currAssignedTo.id && p.type === currAssignedTo.type,
    );

    if (currentAssigneeInParticipants) {
      return overview.data?.data.participants;
    }

    participants?.unshift({
      id: currAssignedTo.id,
      type: currAssignedTo.type,
    });

    return participants;
  };

  const lastUpdatedTimestampString = () => {
    if (!summary.data?.meta) return '';

    return summaryLastUpdatedAtFormat(summary.data.meta.generated_at);
  };

  const markdownSummaryForCopy = () =>
    incidentSummaryMarkdown({
      incident,
      summary: summary.data?.data,
      summaryMeta: summary.data?.meta,
      overview: overview.data?.data,
    });

  const value = {
    incidentId,
    incident,
    summaryDrawerOpen,
    setSummaryDrawerOpen,
    drawerState,
    refetchSummaryAndOverview,
    summary,
    overview,
    setSummaryDrawerStateFeedback,
    setSummaryDrawerStateSuccess,
    feedback,
    setFeedback,
    feedbackSubmitting,
    sendPositiveFeedback,
    sendNegativeFeedback,
    canUseIncidentSummary:
      BillingService.getLimit({ organization: appState.organization }, 'incident-summary') ===
      'unlimited'
        ? true
        : false,
    setShowUpgradeModal,
    showUpgradeModal,
    getParticipants,
    lastUpdatedTimestampString,
    markdownSummaryForCopy,
  };

  return (
    <IncidentSummaryContext.Provider value={value}>{children}</IncidentSummaryContext.Provider>
  );
};
