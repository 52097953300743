import React from 'react';
import { ACTION_TITLE } from '../../../constant';
import ActionCardContainer from '../../../create-or-update/actions/list/ActionCardContainer';
import CommunicationChannelCard from '../../../create-or-update/actions/squadcast/communication-channel/CommunicationChannelCard';
import IncidentNoteCard from '../../../create-or-update/actions/squadcast/incident-note/IncidentNoteCard';
import RunbookCard from '../../../create-or-update/actions/squadcast/runbook/RunbookCard';
import SloAffectingCard from '../../../create-or-update/actions/squadcast/slo-affected/SloAffectingCard';
import {
  mapResponseToSqCommunicationChannelAction,
  mapResponseToSloAffectingAction,
  mapResponseToAttachRunbookAction,
} from '../../../helper';
import { SubActionTypes } from '../../../types';
import { ActionTypeDetailsResponse } from '../../../types/response/workflow';
import HttpCallCard from '../../../create-or-update/actions/squadcast/http-call/HttpCallCard';
import TriggeredWebhookCard from '../../../create-or-update/actions/squadcast/trigger-webhook/TriggeredWebhookCard';
import EmailCard from '../../../create-or-update/actions/squadcast/send-email/EmailCard';
import MessageTeamChannelCard from '../../../create-or-update/actions/microsoft/messageChannel/MessageTeamChannelCard';
import MessageUserCard from '../../../create-or-update/actions/slack/messageUser/MessageUserCard';
import SlackCard from '../../../create-or-update/actions/slack/channelCreate/SlackCard';
import MessageChannelCard from '../../../create-or-update/actions/slack/messageChannel/MessageChannelCard';
import JiraTicketCard from '../../../create-or-update/actions/jira/JiraTicketCard';
import PriorityCard from '../../../create-or-update/actions/squadcast/priority/PriorityCard';
import MessageTeamUserCard from '../../../create-or-update/actions/microsoft/messageTeam/messageTeamUserCard';
import StatusPageCard from '../../../create-or-update/actions/squadcast/statuspage/StatusPageCard';
import { ACTION_ICONS_MAP } from 'library/atoms/ActionsStack/constant';

interface ActionsCardProps {
  actions: ActionTypeDetailsResponse;
}

const ActionsCard: React.FC<ActionsCardProps> = ({ actions }) => {
  const { name } = actions;

  switch (name) {
    case SubActionTypes.COMMUNICATION_CHANNEL:
      const communicationCard = actions;
      return (
        <ActionCardContainer
          id={encodeURIComponent(ACTION_TITLE[communicationCard.name])}
          title={`${ACTION_TITLE[communicationCard.name]} (${
            communicationCard.data.channels.length
          })`}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <CommunicationChannelCard
            action={mapResponseToSqCommunicationChannelAction(communicationCard)}
          />
        </ActionCardContainer>
      );
    case SubActionTypes.SLO_AFFECTED:
      const sloData = actions;
      return (
        <ActionCardContainer
          id={encodeURIComponent(ACTION_TITLE[sloData.name])}
          title={ACTION_TITLE[sloData.name]}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <SloAffectingCard action={mapResponseToSloAffectingAction(sloData)} />
        </ActionCardContainer>
      );
    case SubActionTypes.RUNBOOKS:
      const runbookData = actions;
      return (
        <ActionCardContainer
          title={`${ACTION_TITLE[runbookData.name]} (${runbookData.data.runbooks.length})`}
          id={encodeURIComponent(ACTION_TITLE[runbookData.name])}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <RunbookCard action={mapResponseToAttachRunbookAction(runbookData)} />
        </ActionCardContainer>
      );
    case SubActionTypes.INCIDENT_NOTE:
      const incidentNoteData = actions;
      return (
        <ActionCardContainer
          title={ACTION_TITLE[incidentNoteData.name]}
          id={encodeURIComponent(ACTION_TITLE[incidentNoteData.name])}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <IncidentNoteCard action={incidentNoteData} />
        </ActionCardContainer>
      );
    case SubActionTypes.HTTP_CALL:
      const HttpCallData = actions;
      return (
        <ActionCardContainer
          title={ACTION_TITLE[HttpCallData.name]}
          id={encodeURIComponent(ACTION_TITLE[HttpCallData.name])}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <HttpCallCard action={HttpCallData} />
        </ActionCardContainer>
      );
    case SubActionTypes.TRIGGER_MANUAL_WEBHOOK:
      const triggeredWebhookData = actions;
      return (
        <ActionCardContainer
          title={ACTION_TITLE[triggeredWebhookData.name]}
          id={encodeURIComponent(ACTION_TITLE[triggeredWebhookData.name])}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <TriggeredWebhookCard action={triggeredWebhookData} />
        </ActionCardContainer>
      );
    case SubActionTypes.SEND_EMAIL:
      const EmailData = actions;
      return (
        <ActionCardContainer
          title={ACTION_TITLE[EmailData.name]}
          id={encodeURIComponent(ACTION_TITLE[EmailData.name])}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <EmailCard action={EmailData} />
        </ActionCardContainer>
      );
    case SubActionTypes.PRIORITY:
      const Priority = actions;
      return (
        <ActionCardContainer
          title={ACTION_TITLE[Priority.name]}
          id={encodeURIComponent(ACTION_TITLE[Priority.name])}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <PriorityCard action={Priority} />
        </ActionCardContainer>
      );
    case SubActionTypes.STATUS_PAGE:
      const StatusPage = actions;
      return (
        <ActionCardContainer
          title={ACTION_TITLE[StatusPage.name]}
          id={encodeURIComponent(ACTION_TITLE[StatusPage.name])}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <StatusPageCard action={StatusPage} />
        </ActionCardContainer>
      );
    case SubActionTypes.MS_TEAMS_MESSAGE_CHANNEL:
      const messageTeamChannelData = actions;
      return (
        <ActionCardContainer
          title={ACTION_TITLE[messageTeamChannelData.name]}
          id={encodeURIComponent(ACTION_TITLE[messageTeamChannelData.name])}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <MessageTeamChannelCard action={messageTeamChannelData} />
        </ActionCardContainer>
      );
    case SubActionTypes.MS_TEAMS_MESSAGE_USER:
      const messageTeamUserData = actions;
      return (
        <ActionCardContainer
          title={ACTION_TITLE[messageTeamUserData.name]}
          id={encodeURIComponent(ACTION_TITLE[messageTeamUserData.name])}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <MessageTeamUserCard action={messageTeamUserData} />
        </ActionCardContainer>
      );
    case SubActionTypes.MS_TEAMS_CREATE_MEETING_LINK:
      const createMeetingLinkData = actions;
      return (
        <ActionCardContainer
          title={ACTION_TITLE[createMeetingLinkData.name]}
          id={encodeURIComponent(ACTION_TITLE[createMeetingLinkData.name])}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <div />
        </ActionCardContainer>
      );
    case SubActionTypes.SLACK_CHANNEL_CREATE:
      const createSlackData = actions;
      return (
        <ActionCardContainer
          title={ACTION_TITLE[createSlackData.name]}
          id={encodeURIComponent(ACTION_TITLE[createSlackData.name])}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <SlackCard action={createSlackData} />
        </ActionCardContainer>
      );
    case SubActionTypes.SLACK_CHANNEL_ARCHIEVE:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[actions.name]}
          icon={ACTION_ICONS_MAP['md'][actions.name]}
          id={encodeURIComponent(ACTION_TITLE[actions.name])}
        >
          <div />
        </ActionCardContainer>
      );

    case SubActionTypes.SLACK_MESSAGE_USER:
      const messagedUserData = actions;
      return (
        <ActionCardContainer
          title={ACTION_TITLE[messagedUserData.name]}
          id={encodeURIComponent(ACTION_TITLE[messagedUserData.name])}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <MessageUserCard action={messagedUserData} />
        </ActionCardContainer>
      );
    case SubActionTypes.SLACK_MESSAGE_CHANNEL:
      const messagedChannelData = actions;
      return (
        <ActionCardContainer
          title={ACTION_TITLE[messagedChannelData.name]}
          id={encodeURIComponent(ACTION_TITLE[messagedChannelData.name])}
          icon={ACTION_ICONS_MAP['md'][name]}
        >
          <MessageChannelCard action={messagedChannelData} />
        </ActionCardContainer>
      );
    case SubActionTypes.JIRA_CREATE_TICKET:
      const jiraTicketData = actions;
      return (
        <ActionCardContainer
          title={ACTION_TITLE[jiraTicketData.name]}
          id={encodeURIComponent(ACTION_TITLE[jiraTicketData.name])}
          icon={ACTION_ICONS_MAP['md']['jira_create']}
        >
          <JiraTicketCard action={jiraTicketData} />
        </ActionCardContainer>
      );
    default:
      return null;
  }
};

export default ActionsCard;
