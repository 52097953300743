import { StepTypes } from 'views/shared/types';

export const perPage = [
  { value: 5, name: '5 per page' },
  { value: 10, name: '10 per page' },
  { value: 20, name: '20 per page' },
  { value: 30, name: '30 per page' },
  { value: 40, name: '40 per page' },
  { value: 50, name: '50 per page' },
];

export const tableHeader = [
  { name: 'Name', id: 'name' },
  { name: 'Target SLO (%)', id: 'target_slo' },
  {
    name: 'Current SLO (%)',
    id: 'target_slo',
  },
  {
    name: 'Health',
    id: 'target_slo',
    tooltip:
      'Health defines the rate at which the error budget is getting consumed. It indicates Healthy if SLO has consumed less error budget than allocated for the days since its creation.',
  },
  {
    name: 'Service',
    id: 'service_ids',
  },
  {
    name: 'Status',
    id: 'name',
    tooltip: 'Status indicates whether an SLO is active or not.',
  },
  { name: 'Incidents Reported', id: 'incident_count' },
  { name: 'Time Window', id: 'time_window' },
  { name: 'Duration (Days)', id: 'duration' },
  {
    name: 'Updated On',
    id: 'start_time',
  },
  {
    name: 'Tags',
    id: 'tags',
  },
  {
    name: 'Owner',
    id: 'owner',
  },
  {
    name: '',
    id: '',
  },
];

export const exportTableclm = [
  'name',
  'target_slo',
  'remaining_error_budget',
  'service_ids',
  'incident_count',
  'start_time',
  'tags',
];

export const sloViolatingIncidentList = [
  { name: '', id: 'checkBox' },
  { name: 'Incident ID', id: 'incident_id' },
  { name: 'Incident Title', id: 'incident_id' },
  { name: 'Slis affected', id: 'slis' },
  {
    name: 'Error Budget(mins)',
    id: 'error_budget_spent',
    tooltip:
      'Indicates the Error Budget consumed solely by this incident. This is calculated as the duration between Start Time and End Time.',
  },
  { name: 'Service', id: 'service_id' },
  { name: 'Assigned To', id: 'assigned_to' },
  { name: 'Status', id: 'status' },
  { name: 'Start Time', id: 'start_time' },
  { name: 'End Time', id: 'end_time' },
];

export const falsePositiveIncidentList = [
  { name: '', id: 'checkBox' },
  { name: 'Incident ID', id: 'incident_id' },
  { name: 'Incident Title', id: 'incident_id' },
  { name: 'Service', id: 'service_id' },
  { name: 'Assigned To', id: 'assigned_to' },
  { name: 'Status', id: 'status' },
  { name: 'Start Time', id: 'start_time' },
  { name: 'End Time', id: 'end_time' },
];

export const slo_aggregate = [
  {
    name: ' Number of Active SLOs',
    items: [
      { id: 'total_active_slos', label: 'Active' },
      { id: 'total_inactive_slos', label: 'Inactive' },
    ],
  },
  {
    name: ' Number of Healthy SLOs',
    items: [
      { id: 'total_healthy_slos', label: 'Healthy SLO(s)' },
      { id: 'total_unhealthy_slos', label: 'Needs Attention' },
    ],
  },
  {
    name: '  Number of SLO affected incidents',
    items: [
      { id: 'total_slo_affected_incidents_slos', label: 'SLO affected Incidents' },
      { id: 'total_incidents', label: 'False Positive incidents' },
    ],
  },
];

export const colorPallet = [
  '#3CB371',
  '#9370DB',
  '#BA55D3',
  '#0000CD',
  '#66CDAA',
  '#800000',
  '#FF00FF',
  '#32CD32',
  '#00FF00',
  '#FFFFE0',
  '#B0C4DE',
  '#778899',
  '#87CEFA',
  '#20B2AA',
  '#FFA07A',
  '#D2691E',
  '#7FFF00',
  '#5F9EA0',
  '#DEB887',
  '#A52A2A',
  '#8A2BE2',
  '#0000FF',
  '#FFEBCD',
  '#000000',
  '#FFE4C4',
  '#F5F5DC',
  '#F0FFFF',
  '#7FFFD4',
  '#00FFFF',
  '#FAEBD7',
  '#F0F8FF',
];

export const sloListFilterConst = [
  { id: 'service', name: 'Service' },
  { id: 'IsActive', name: 'Status' },
  { id: 'dateTime', name: 'Date Time' },
  { id: 'owner', name: 'SLO Owner' },
];

export const sloOverviewFilterConst = [
  { id: 'service', name: 'Services' },
  { id: 'Slis', name: 'SLIs' },
];

export const sloOverviewFalsePositiveFilterConst = [{ id: 'service', name: 'Service' }];

export const tooltipStyle = {
  bg: 'rgb(243, 244, 246)',
  shadow: 'md',
  color: 'rgb(58, 72, 96)',
  boxShadow: 'rgba(66, 66, 66, 0.08) 0px 1px 1px 0px',
};

export const tableCellStyle = {
  'text-align': 'left',
  'vertical-align': 'center',
  'max-width': '150px',
  padding: 2,
};

export const queryKeys = {
  SLOAGGREGATED: 'sloLAggregated',
  SLOLIST: 'sloList',
  SLODETAIL: 'sloDetails',
  SLILIST: 'sliList',
  SLOVIOLATING: 'sloViolating',
  SLOFALSEPOSITIVE: 'sloFalsePositive',
  SLOMTTA: 'sloMtta',
  SLOMTTR: 'sloMttr',
  TOTALINCIDENTS: 'total_incidents',
  ALREADYEXISTSLONAME: 'alreadyExistSloName',
};

export const cardStyling = {
  p: 2,
  color: 'rgba(45, 57, 100, 1)',
  'font-family': 'Mulish',
  'font-size': 14,
  'text-align': 'center',
  'font-weight': 'bold',
  mr: 5,
};

export const sliConsts = ['availability', 'latency', 'errors', 'saturation'];

export const SLO_STEPS: StepTypes[] = [
  {
    title: 'Define SLO',
    description: 'Description',
  },
  {
    title: 'Configure SLO',
    description: 'Description',
  },
  {
    title: 'Error Budget Policy',
    description: 'Description',
  },
];

export const visibleTagsCount = 3;
