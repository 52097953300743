import { Center, Image, Text } from '@chakra-ui/react';
import { FormButton } from 'library/atoms';
import { FC, ReactNode } from 'react';

type Props = {
  addBtnTitle?: string;
  description: string | ReactNode;
  onCreate?: () => void;
  iconName?: string;
  size?: 'md' | 'lg';
};

export const Placeholder: FC<Props> = ({
  addBtnTitle,
  description,
  onCreate,
  iconName,
  size = 'lg',
}) => {
  const icon = iconName ?? 'default.svg';
  const isDefault = size === 'lg';

  return (
    <Center
      flexDirection="column"
      rowGap="16px"
      mb="16px"
      px="4"
      justifyContent="center"
      minH="70vh"
    >
      <Image
        width={isDefault ? '400px' : '200px'}
        src={`/icons/empty-states/${icon}`}
        alt="No data"
      />
      <Text variant={isDefault ? 'placeholder' : 'h2'} align="center" color="secondary.900">
        {description}
      </Text>
      {addBtnTitle && <FormButton title={addBtnTitle} onClick={onCreate}></FormButton>}
    </Center>
  );
};
