import { Box, Button, Grid, GridItem, Spinner, Stack, Text } from '@chakra-ui/react';
import { IAppState } from 'core/interfaces/IAppState';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AddAccountModal from './addAccountModal';
import DeleteConfirmation from '../../Component/ConfirmationModal';
import { useCreateJiraCloudAccount } from '../../hooks/createJiraAccount';
import { useDeleteJiraCloudAccount } from '../../hooks/deleteJiraAccount';
import { useGetAllJiraAccounts } from '../../hooks/getAllJiraAccounts';
import { IJiraAccDetail } from '../../../../../../../../core/interfaces/IJiraNew';
import JiraCloudExtensionDetailsBox from './jiraCloudExtensionDetailsBox';
function JiraCloudAccountTab() {
  const myTeam = useSelector((state: IAppState) => state.organization.teams);

  //states
  const [isOpenAddAccountModal, setIsOpenAddAccountModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<IJiraAccDetail>();
  const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);
  const [accToDeleteId, setAccToDeleteId] = React.useState<string | null>(null);
  //hooks
  const { data: jiraAccounts = [], isError, isLoading } = useGetAllJiraAccounts();
  const { mutateAsync: deleteJiraAccount } = useDeleteJiraCloudAccount();

  const onDeleteAccount = (accountId: string) => {
    // mutateAsync(accountId);
    setAccToDeleteId(accountId);
    setIsConfirmingDelete(true);
  };

  const onDeleteAccConfirm = () => {
    if (accToDeleteId) {
      deleteJiraAccount(accToDeleteId);
      setIsConfirmingDelete(false);
      setAccToDeleteId(null);
    }
  };

  function closeAddAccountModal() {
    setIsOpenAddAccountModal(false);
    setSelectedAccount(undefined);
  }

  const onEditAccount = (account: IJiraAccDetail) => {
    setIsOpenAddAccountModal(true);
    setSelectedAccount(account);
  };

  return (
    <>
      <Box height={'100%'}>
        <Box display="flex" flexDirection={'row-reverse'} mb={5} height={'100%'}>
          <Button
            background="primary.default"
            color="white"
            fontWeight={400}
            fontSize={16}
            _hover={{ opacity: 0.6 }}
            onClick={() => setIsOpenAddAccountModal(true)}
          >
            Add Account
          </Button>
        </Box>

        {isLoading ? (
          <Stack direction="row" justifyContent={'center'} height={'100%'} spacing={4}>
            <Spinner size="xl" />
          </Stack>
        ) : (
          <Grid
            templateColumns={{ sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr 1fr' }}
            columnGap={3}
            rowGap={3}
            pr={3}
          >
            {jiraAccounts.length > 0 ? (
              jiraAccounts?.map(account => {
                return (
                  <GridItem key={account.id}>
                    <JiraCloudExtensionDetailsBox
                      account={account}
                      onDeleteAccount={onDeleteAccount}
                      onEditAccount={onEditAccount}
                    />
                  </GridItem>
                );
              })
            ) : (
              <GridItem colSpan={4}>
                <Text> No Accounts Created Yet </Text>
              </GridItem>
            )}
          </Grid>
        )}
      </Box>
      {isOpenAddAccountModal && (
        <AddAccountModal
          isOpen={isOpenAddAccountModal}
          onCloseAddAccountModal={closeAddAccountModal}
          accountDetail={selectedAccount}
        />
      )}
      {isConfirmingDelete && (
        <DeleteConfirmation
          bodyText={`Are you sure you want to delete this Jira Account?\nThis will delete this account and associated Team & Service Jira configurations as well.\nYou won't be able to create any Jira tickets for this account.`}
          headerText="Delete Jira Account?"
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          isOpen={isConfirmingDelete}
          onConfirm={onDeleteAccConfirm}
          onClose={() => {
            setIsConfirmingDelete(false);
            setAccToDeleteId(null);
          }}
        />
      )}
    </>
  );
}

export default JiraCloudAccountTab;
