import { useStateSyncedWithQueryParams } from 'core/hooks/useStateSyncedWithQueryParams';

export const useSearchSquad = () => {
  return useStateSyncedWithQueryParams<string | undefined>(
    undefined,
    'squad_search',
    s => (s ? s : ''),
    s => s,
  );
};
