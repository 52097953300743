import React from 'react';

import {
  Button,
  ChakraProvider,
  extendTheme,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { Theme } from 'uie/components';

import DefaultTeamConfig from './TabPages/DefaultTeamConfig';
import JiraCloudAccountTab from './TabPages/JiraCloudAccount/jiraCloudAccountTab';
import { ArrowLeftIcon } from 'icons';
import { useHistory } from 'react-router-dom';

const tabStyle = {
  background: 'AliceBlue',
  color: 'primary.default',
  borderColor: 'primary.default',
  borderBottom: '2px solid',
  _focus: {
    boxShadow: 'none',
  },
};

function JiraCloudSettings() {
  const history = useHistory();
  const { theme: squadcastTheme } = Theme;
  const chakraTheme = extendTheme({
    colors: {
      ...squadcastTheme,
      PoliceBlue: '#3B495F',
      AliceBlue: '#EFF7FF',
      DarkElectricBlue: '#616B84',
      Gainsboro: '#DBDEE4',
      Gray: '#8B8B8B',
    },
    fonts: {
      body: squadcastTheme.fontFamily,
      heading: squadcastTheme.fontFamily,
    },
  });
  return (
    <ChakraProvider theme={chakraTheme}>
      <Button
        display={'flex'}
        flexDirection="row"
        background={'white'}
        _hover={{
          background: 'AliceBlue',
        }}
        _active={{
          background: 'white',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        mt={10}
        alignItems="center"
        pl={0}
        onClick={() => history.push('/settings/extension')}
      >
        <ArrowLeftIcon width={15} height={15} color="black" fontStyle={'normal'} />
        <Text ml={1} fontWeight={400} fontSize={16}>
          Extension
        </Text>
      </Button>
      <Text fontSize="xl" style={{ margin: '20px 0' }}>
        Jira Cloud Extension
      </Text>
      <Tabs>
        <TabList>
          <Tab mr={10} _selected={tabStyle}>
            Jira Accounts
          </Tab>
          <Tab _selected={tabStyle}>Team Configurations (Default)</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <JiraCloudAccountTab />
          </TabPanel>
          <TabPanel>
            <DefaultTeamConfig />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ChakraProvider>
  );
}

export default JiraCloudSettings;
