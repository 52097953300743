import DrawerFooterWrapper from 'library/molecules/Drawer';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import { priorityValidation } from 'views/main/organization/workflow/constant/validation';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { PriorityAction } from 'views/main/organization/workflow/types';
import { FormFooter } from '../form-footer';
import PriorityForm from './PriorityForm';

const PriorityContainer = () => {
  const { actionForm, onActionSubmit, checkDirtyAndCloseDrawer } = useWorkflowFormContext();

  return (
    <FormWrapper<PriorityAction>
      onSubmit={onActionSubmit}
      defaultValues={actionForm! as PriorityAction}
      validationSchema={priorityValidation}
    >
      <PriorityForm />
      <DrawerFooterWrapper>
        <FormFooter<PriorityAction> checkDirtyAndCloseDrawer={checkDirtyAndCloseDrawer} />
      </DrawerFooterWrapper>
    </FormWrapper>
  );
};

export default PriorityContainer;
