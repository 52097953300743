import { API } from 'core';
import { IOrganizationEvents } from 'core/interfaces/IOrganization';

interface Props {
  eventName: string;
  eventHandler: ({ message }: { message: IOrganizationEvents }) => void;
}

const useSoketiBinding = ({ eventName, eventHandler }: Props) => {
  return () => {
    const soketi = API.socket.subscribe(API.config.organizationId);
    soketi.bind(eventName, eventHandler);

    return () => {
      soketi.unbind(eventName);
    };
  };
};
export default useSoketiBinding;
