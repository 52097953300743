import { CopyIcon } from '@chakra-ui/icons';
import { Button, Center, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import Editor from '@monaco-editor/react';
import { IDedupKey } from 'views/main/organization/service-catalog/Interfaces/automation-rule';
import { MONACO_EDITOR_OPTIONS } from 'views/shared/constants';

interface Props {
  dedupeKey: IDedupKey;
  onChangeDedupeKeyTemplate: (value: any) => void;
  onCopyDedupeKey: (key: string) => void;
  isViewMode: boolean;
  enableEditMode: () => void;
  saving: boolean;
  isEditMode: boolean;
  enableEditKey: boolean;
}

const TemplateEditor = ({
  dedupeKey,
  enableEditMode,
  isEditMode,
  isViewMode,
  onChangeDedupeKeyTemplate,
  onCopyDedupeKey,
  saving,
  enableEditKey,
}: Props) => {
  return (
    <div className="clearfix mb-20">
      <div className="field-parent-div">
        <VStack alignItems="flex-start">
          <HStack
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb="10px"
            w="100%"
          >
            <Text variant="secondary">Editor</Text>
            <Icon
              as={CopyIcon}
              onClick={() => onCopyDedupeKey(dedupeKey.key)}
              style={
                dedupeKey.key ? { cursor: 'pointer' } : { pointerEvents: 'none', opacity: '50%' }
              }
            />
          </HStack>
          <Flex
            className="editor-wrapper"
            position="relative"
            minHeight="20vh"
            maxHeight="40vh"
            width="100%"
            justifyContent="center"
            alignItems="center"
            marginTop="0px !important"
          >
            <Editor
              className="dedupe-key-editor"
              language="go"
              options={{
                ...MONACO_EDITOR_OPTIONS,
                readOnly: isViewMode,
              }}
              value={dedupeKey.key}
              onChange={onChangeDedupeKeyTemplate}
            />
            {!enableEditKey && isEditMode && (
              <>
                <Center
                  height="100%"
                  width="100%"
                  backgroundColor="#DADADA"
                  zIndex="10"
                  position="absolute"
                  opacity="0.4"
                />
                <Button
                  size="xs"
                  variant="outline"
                  backgroundColor="white"
                  isDisabled={saving}
                  onClick={enableEditMode}
                  position="absolute"
                  zIndex="20"
                >
                  Edit
                </Button>
              </>
            )}
          </Flex>
        </VStack>
      </div>
    </div>
  );
};

export default TemplateEditor;
