import React from 'react';
import { Line, Serie } from '@nivo/line';
import { ResponsiveWrapper } from '@nivo/core';

interface IProps {
  data: Serie[];
}

const LineChart: React.FC<IProps> = ({ data, ...props }) => {
  return (
    <ResponsiveWrapper>
      {({ width }) => (
        <Line
          width={width}
          height={300}
          data={data}
          margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
          useMesh={true}
          enablePoints={false}
          curve="natural"
          xScale={{
            type: 'time',
            format: '%Y-%m-%d',
            useUTC: false,
            precision: 'day',
          }}
          enableArea={true}
          colors={{ scheme: 'paired' }}
          xFormat="time:%b %d"
          axisBottom={{
            tickValues: 'every 2 days',
            format: '%b %d',
          }}
        />
      )}
    </ResponsiveWrapper>
  );
};

export default LineChart;
