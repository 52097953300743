import {
  REQUEST_ORG_USERS,
  REQUEST_ORG_USERS_SUCCESS,
  REQUEST_ORG_USERS_FAIL,
} from '../../const/organization';
import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';
import { IUserInfo } from '../../interfaces/IUserData';

const initialOrganizationUsers = INITIAL_STATE.organization.users;

const organizationUsers = (state = initialOrganizationUsers, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ action: RESET_STATE, u: [], error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_USERS:
      return {
        ...state,
        ...{ action: REQUEST_ORG_USERS, error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_USERS_SUCCESS:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_USERS_SUCCESS,
          u: action.payload.users.sort((a: IUserInfo, b: IUserInfo) =>
            (a.first_name || '').localeCompare(b.last_name || ''),
          ),
          error: {
            type: '',
            reason: {},
          },
        },
      };
    case REQUEST_ORG_USERS_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_USERS_FAIL,
          u: [],
          error: { type: REQUEST_ORG_USERS_FAIL, reason: action.payload },
        },
      };
    default:
      return state;
  }
};

export default organizationUsers;
