import * as React from 'react';
import { SVGProps } from 'react';

const SvgRedgateLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#c00',
      }}
      d="m13.902 9.09-5.587-.176 1.907 2.084 3.68-.089zM13.06 13.969l.842-.133v-1.73l-2.483.133zM2.506 15.033l9.09-.931-1.773-1.774-7.318.444zM6.408 8.87l-3.903-.09v2.395l6.032-.133zM6.896 7.45l7.006.4V6.12l-9.18-.976zM2.506 4.922v2.262l2.394.133z"
    />
    <path
      style={{
        fill: '#c00',
      }}
      d="M14.39 15.166a.69.69 0 0 0 .488-.754V5.543c.044-.31-.177-.62-.488-.754L2.505 3.104V1.641C3.835.665 6.675 0 10 0s6.164.665 7.495 1.64v16.72C16.165 19.334 13.325 20 10 20s-6.164-.71-7.495-1.685v-1.463l11.885-1.686"
    />
  </svg>
);

export default SvgRedgateLogo20X20;
