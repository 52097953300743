import React, { useEffect, useState, Fragment, useMemo } from 'react';
import {
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  HStack,
  Text,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import ChakraDatepicker from 'components/chakra/Datepicker';
import { components } from 'react-select';
import { useFormikContext, FormikTouched, FormikErrors } from 'formik';
import CreatableReactSelect from 'react-select/creatable';
import {
  rotationsCustomizePattern,
  ChangeParticipantsFreqLimit,
} from '../../constants/rotations.customize';
import Select from 'components/chakra/Select';
import { INewSchedule, IRotation } from '../../interface/schedule';
import {
  getStartTimeDropDownValues,
  getOnCallHoursValue,
  getEndTimeTimeStamp,
  getCustomRepeatsText,
  getCustomTimeSlotsText,
  getParticipantFrequencyDropdownOptions,
} from '../../helpers/helpers.customrotations';
import { customStyles } from 'components/chakra/Select/customStyles';
import InputNumber from 'components/chakra/NumberInput';
import {
  ChangeParticipantsInterval,
  CustomPeriodUnit,
  PeriodOptions,
} from 'views/main/organization/schedules/graphql/types';
import { isSameDate } from '../../helpers/helpers.date';
import { getPaddedValue, isSameDayEvent } from '../../helpers/helpers.event';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import { InfoIcon } from 'icons';
import { useScheduleActionsContext } from '../../schedules.actions/context';
import { ScheduleActionTypes } from '../../constants/schedules.copy';

interface Props {
  rotationId: string;
  modalHandler: (object: any) => void;
}

const PatternDuration = ({ rotationId, modalHandler }: Props) => {
  const {
    values: { rotations },
    errors,
    touched,
    handleChange,
  } = useFormikContext<INewSchedule>();
  const { scheduleAction } = useScheduleActionsContext();
  const currentRotation = rotations.find(rt => rt.id === rotationId);
  const currentRotationIndex = rotations.findIndex(rt => rt.id === rotationId);
  const currentRotationStartTime = currentRotation?.shiftTimeSlot.startTime;
  const currentRotationInterval = currentRotation?.shiftTimeSlot.duration;
  const customRepeatPeriod = currentRotation?.customPeriod;
  const onCallHoursPerParticipant = currentRotation?.onCallHoursPerParticipant;
  const rotationStartDate = currentRotation?.startDate ?? new Date();
  const [startDateJS, setStartDateJS] = useState(rotationStartDate);
  const doesRotationStartToday = isSameDate(rotationStartDate ?? new Date(), new Date());
  const datePickerFormat = (doesRotationStartToday ? "'Today, '" : '') + 'd MMM yyy, (EEE)';
  const [onCallHoursOptions, setOnCallHoursOptions] = useState(
    currentRotationInterval
      ? [
          ...rotationsCustomizePattern.selectOptions.onCallHours,
          {
            label: getOnCallHoursValue(currentRotationInterval).label,
            value: currentRotationInterval,
          },
        ]
      : [...rotationsCustomizePattern.selectOptions.onCallHours],
  );
  const [endTimeValue, setEndTimeValue] = useState('00:00');
  const [startTimeOptions, setStartTimeOptions] = useState(getStartTimeDropDownValues());

  useEffect(() => {
    if (currentRotationStartTime && currentRotationInterval) {
      setEndTimeValue(getEndTimeTimeStamp(currentRotationStartTime, currentRotationInterval));
    }
  }, [currentRotationStartTime, currentRotationInterval]);

  useEffect(() => {
    let startJSDate = cloneDeep(rotationStartDate);
    const temp = DateTime.fromJSDate(rotationStartDate).setZone('UTC');
    startJSDate = DateTime.fromJSDate(startJSDate)
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .set({
        day: temp.day,
        month: temp.month,
        year: temp.year,
      })
      .toJSDate();
    setStartDateJS(startJSDate);
  }, [rotationStartDate]);

  const participantFreqLimit = useMemo(() => {
    if (!currentRotation?.changeParticipantsUnit) return 1;

    switch (currentRotation.changeParticipantsUnit) {
      case ChangeParticipantsInterval.Day: {
        return ChangeParticipantsFreqLimit.Day;
      }
      case ChangeParticipantsInterval.Week: {
        return ChangeParticipantsFreqLimit.Week;
      }
      case ChangeParticipantsInterval.Month: {
        return ChangeParticipantsFreqLimit.Month;
      }
      case ChangeParticipantsInterval.Rotation: {
        return ChangeParticipantsFreqLimit.Rotation;
      }
    }
  }, [currentRotation?.changeParticipantsUnit]);

  const indexOfRotationRepeats = rotationsCustomizePattern.selectOptions.repeats.findIndex(
    option => option.value === currentRotation?.period,
  );
  const rotationRepeats = {
    label: rotationsCustomizePattern.selectOptions.repeats[indexOfRotationRepeats].label,
    value: rotationsCustomizePattern.selectOptions.repeats[indexOfRotationRepeats].value,
  };
  const indexOfChangeParticipants =
    rotationsCustomizePattern.selectOptions.changeParticipants.findIndex(
      option => option.value === currentRotation?.changeParticipantsUnit,
    );
  const changeParticipants = {
    label: `${
      rotationsCustomizePattern.selectOptions.changeParticipants[indexOfChangeParticipants].label
    }${(currentRotation?.changeParticipantsFrequency ?? 1) > 1 ? 's' : ''}`,
    value:
      rotationsCustomizePattern.selectOptions.changeParticipants[indexOfChangeParticipants].value,
  };

  const timeChangeHandler = (value: any, type: 'onCallHours' | 'startTime') => {
    const dropdownValue = value.value; // 00:10, 03:40 and so on

    if (value.__isNew__) {
      const hoursPart = dropdownValue.split(':')[0];
      const minutesPart = dropdownValue.split(':')[1];
      const hours = Number.parseInt(hoursPart);
      const minutes = Number.parseInt(minutesPart);
      if (isNaN(hours) || isNaN(minutes)) {
        return;
      }
      if (hours < 0 || hours > 24 || minutes < 0 || minutes > 60) {
        return;
      }
      const updatedDuration = hours * 60 + minutes;
      const updatedTimeStamp = [getPaddedValue(hours), getPaddedValue(minutes)].join(':');
      const newDropDownOption = {
        label: type === 'onCallHours' ? `${updatedTimeStamp} hrs` : updatedTimeStamp,
        value: type === 'onCallHours' ? updatedDuration : updatedTimeStamp,
        ...(type === 'startTime' ? { duration: updatedDuration } : {}),
      };
      handleChange({
        target: {
          name:
            type === 'onCallHours'
              ? `rotations.${currentRotationIndex}.shiftTimeSlot.duration`
              : `rotations.${currentRotationIndex}.shiftTimeSlot.startTime`,
          value: type === 'onCallHours' ? updatedDuration : updatedTimeStamp,
        },
      });
      if (type === 'onCallHours') {
        const updatedDropDownOptions = [...onCallHoursOptions, newDropDownOption].filter(
          (option, index, self) => self.indexOf(option) === index,
        );
        setOnCallHoursOptions(
          updatedDropDownOptions as Array<{
            label: string;
            value: number;
          }>,
        );
      } else {
        const updatedDropDownOptions = [...startTimeOptions, newDropDownOption].filter(
          (option, index, self) => self.indexOf(option) === index,
        );
        setStartTimeOptions(
          updatedDropDownOptions as Array<{
            duration?: number | undefined;
            label: string;
            value: string | number;
          }>,
        );
      }
    } else {
      handleChange({
        target: {
          name:
            type === 'onCallHours'
              ? `rotations.${currentRotationIndex}.shiftTimeSlot.duration`
              : `rotations.${currentRotationIndex}.shiftTimeSlot.startTime`,
          value: dropdownValue,
        },
      });
    }
  };

  const SingleValue = (props: any) => {
    const { label } = props.getValue()[0];
    const isSameDay = isSameDayEvent(label, endTimeValue);
    return (
      <components.SingleValue {...props}>
        <HStack spacing={1}>
          <Flex>{label}</Flex>
          <Flex style={{ color: 'gray' }}>
            - {endTimeValue}
            <Text as="span" ml={1} textColor="gray.400">
              {!isSameDay && '+1'}
            </Text>
          </Flex>
        </HStack>
      </components.SingleValue>
    );
  };

  const updateDate = (value: Date) => {
    const dateTime = DateTime.fromObject(
      {
        year: value.getFullYear(),
        month: value.getMonth() + 1,
        day: value.getDate(),
        hour: 0,
        minute: 0,
      },
      { zone: 'UTC' },
    ).toJSDate();
    handleChange({
      target: { name: `rotations.${currentRotationIndex}.startDate`, value: dateTime },
    });
  };

  const showTooltip = () => {
    if (
      scheduleAction &&
      [ScheduleActionTypes.EDIT_ROTATIONS, ScheduleActionTypes.EDIT_SCHEDULE].includes(
        scheduleAction.type,
      )
    ) {
      return true;
    }
    return false;
  };

  return (
    <FormControl
      isInvalid={
        !!(
          (errors.rotations && errors.rotations[currentRotationIndex]
            ? (errors.rotations[currentRotationIndex] as FormikErrors<IRotation>).startDate
            : false) &&
          (touched.rotations && touched.rotations[currentRotationIndex]
            ? (touched.rotations[currentRotationIndex] as FormikTouched<IRotation>).startDate
            : false)
        )
      }
    >
      <Grid templateColumns="0.6fr" templateRows="1fr" marginTop={6} alignItems="flex-end">
        <GridItem>
          <FormLabel variant="rotationPattern" style={{ display: 'flex' }}>
            {rotationsCustomizePattern.labels.duration.startDate}
            {showTooltip() && (
              <Tooltip
                label="Please note that changing the Start Date will delete original on-call rotation events and populate rotation events as per new Start Date. If you wish to retain history but want to have a rotation with a new Start Date, then create a new rotation instead."
                backgroundColor={'blackAlpha.900'}
                hasArrow
              >
                <InfoIcon
                  fontSize={'small'}
                  style={{ width: 16, height: 16, marginLeft: 4, marginTop: 4 }}
                />
              </Tooltip>
            )}
          </FormLabel>
          <ChakraDatepicker
            setDate={(value: Date) => (value instanceof Date ? updateDate(value) : {})}
            date={startDateJS}
            dateFormat={datePickerFormat}
          />
        </GridItem>
      </Grid>
      <Grid
        templateColumns={
          rotationRepeats.value !== PeriodOptions.Custom ? '1fr 1.5fr' : '0.75fr 1.5fr'
        }
        templateRows={
          rotationRepeats.value !== PeriodOptions.Custom ? '1fr 0.1fr 1fr' : '1fr 0.1 1fr'
        }
        gap={5}
        alignItems="flex-end"
        mt={5}
      >
        <GridItem>
          <FormLabel variant="rotationPattern" htmlFor="repeats">
            {rotationsCustomizePattern.labels.duration.repeats}
          </FormLabel>
          <Select
            inputId="repeats"
            value={rotationRepeats}
            options={rotationsCustomizePattern.selectOptions.repeats}
            onChange={modalHandler}
            closeMenuOnSelect
          />
        </GridItem>
        {rotationRepeats.value === 'none' ? (
          <GridItem></GridItem>
        ) : (
          <GridItem>
            <FormLabel variant="rotationPattern">
              {rotationsCustomizePattern.labels.duration.changeParticipants}
            </FormLabel>

            <HStack>
              <Text>Every</Text>
              <InputNumber
                defaultValue={1}
                w={rotationRepeats.value !== PeriodOptions.Custom ? '35%' : '60%'}
                min={1}
                max={Number.parseInt(`${participantFreqLimit}`)}
                value={currentRotation?.changeParticipantsFrequency}
                onChange={(value: any) => {
                  if (!isNaN(Number.parseInt(value))) {
                    handleChange({
                      target: {
                        name: `rotations.${currentRotationIndex}.changeParticipantsFrequency`,
                        value: Number.parseInt(value),
                      },
                    });
                  }
                }}
              />
              {rotationRepeats.value !== 'custom' ? (
                <Text>{`rotation${
                  (currentRotation?.changeParticipantsFrequency ?? 1) > 1 ? 's' : ''
                }`}</Text>
              ) : (
                <Select
                  value={changeParticipants}
                  options={getParticipantFrequencyDropdownOptions(currentRotation)}
                  onChange={(selectedValue: any) =>
                    handleChange({
                      target: {
                        name: `rotations.${currentRotationIndex}.changeParticipantsUnit`,
                        value: selectedValue.value,
                      },
                    })
                  }
                  disableLeftPaddingInValueContainer
                  closeMenuOnSelect
                />
              )}
            </HStack>
          </GridItem>
        )}
        {rotationRepeats.value !== PeriodOptions.None && (
          <>
            <GridItem></GridItem>

            <GridItem>
              <Text textAlign="center" background="#F1F3F6" p={1}>
                {`${
                  Number.isInteger(onCallHoursPerParticipant)
                    ? onCallHoursPerParticipant
                    : onCallHoursPerParticipant?.toFixed(2) || 0
                } hrs / participant`}
              </Text>
            </GridItem>
          </>
        )}
        {rotationRepeats.value !== 'custom' && (
          <>
            <GridItem>
              <FormLabel variant="rotationPattern" htmlFor="onCallHours">
                {rotationsCustomizePattern.labels.duration.onCallHours}
              </FormLabel>
              <CreatableReactSelect
                value={getOnCallHoursValue(currentRotation?.shiftTimeSlot.duration)}
                options={onCallHoursOptions
                  .filter(
                    (option: Record<string, any>, index, self) =>
                      option.label &&
                      index ===
                        self.findIndex((o: Record<string, any>) => o.value === option.value),
                  )
                  .sort((option1, option2) => option1.value - option2.value)}
                onChange={(value: any) => timeChangeHandler(value, 'onCallHours')}
                closeMenuOnSelect
                components={{ IndicatorSeparator: () => null }}
                styles={customStyles}
                inputId="onCallHours"
              />
            </GridItem>
            <GridItem>
              <FormLabel variant="rotationPattern" htmlFor="onCallStartTime">
                {rotationsCustomizePattern.labels.duration.onCallTime}
              </FormLabel>
              <CreatableReactSelect
                inputId="onCallStartTime"
                defaultValue={{ label: '00:00', value: '00:00' }}
                value={{
                  label: currentRotation?.shiftTimeSlot.startTime,
                  value: currentRotation?.shiftTimeSlot.startTime,
                }}
                options={startTimeOptions.sort(
                  (option1, option2) => option1.duration - option2.duration,
                )}
                onChange={(value: any) => timeChangeHandler(value, 'startTime')}
                closeMenuOnSelect
                components={{ IndicatorSeparator: () => null, SingleValue }}
                styles={customStyles}
              />
            </GridItem>
          </>
        )}
      </Grid>
      {rotationRepeats.value === PeriodOptions.Custom && (
        <>
          <FormLabel variant="rotationPattern" mt={5}>
            {rotationsCustomizePattern.labels.duration.daysAndTime}
          </FormLabel>
          <Text>{getCustomRepeatsText(customRepeatPeriod, currentRotation?.startDate)}</Text>
          {customRepeatPeriod?.periodUnit === CustomPeriodUnit.Week &&
          !customRepeatPeriod.repeatHoursAndTime &&
          (getCustomTimeSlotsText(customRepeatPeriod) as Array<string>) ? (
            (getCustomTimeSlotsText(customRepeatPeriod) as Array<string>)?.map((str, index) => {
              if (!str) return;
              return (
                <HStack key={index}>
                  <Text fontWeight="bold">{str.split('|')[0] + `: `}</Text>
                  <Text>{str.split('|')[1]}</Text>
                </HStack>
              );
            })
          ) : (
            <Text>{getCustomTimeSlotsText(customRepeatPeriod)}</Text>
          )}
        </>
      )}
    </FormControl>
  );
};

export default PatternDuration;
