import { Badge, Flex, HStack } from '@chakra-ui/layout';
import { useEffect, useRef } from 'react';
import { focusCard } from 'core';
import { Theme } from 'uie/components';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/accordion';
import { Link } from 'react-router-dom';
import { IKeypadMapping } from 'views/main/organization/lcr/common/interface';
import {
  getSchedulesDetailsPath,
  getServiceDetailsPath,
} from 'views/main/organization/lcr/common/utils';
import { useLCRStore } from 'views/main/organization/lcr/hooks/lcr.state';
import { Text } from 'library/atoms/Text';
const ActionCardContainer = ({ id, data }: { id: string; data: IKeypadMapping }) => {
  const { theme } = Theme;
  const flexRef = useRef(null);
  const lcrDetails = useLCRStore();

  const targetAction = window.location.hash.split('?')[0];

  const styleActionOnTarget =
    targetAction === '#' + id ? `1px solid ${theme.primary.default}` : 'secondary.200';

  useEffect(() => {
    if (flexRef.current && targetAction === '#' + id) {
      focusCard(flexRef.current);
    }
  }, [targetAction]);
  return (
    <HStack
      bg="brand.white"
      p={4}
      width="50dvw"
      borderWidth="1px"
      borderStyle="solid"
      border={styleActionOnTarget}
      align="center"
      borderRadius={'6px'}
      id="keypad"
      ref={flexRef}
    >
      <Flex direction="column">
        <Accordion allowToggle w="100%">
          <AccordionItem
            border="none"
            w="100%"
            _focus={{ boxShadow: 'none' }}
            justifyContent="flex-start"
            backgroundImage={'none'}
          >
            <AccordionButton borderTopWidth="0px !important" bg="Background" padding={'0'}>
              <HStack gap={2}>
                <Badge
                  borderRadius="100%"
                  h="24px"
                  w="24px"
                  padding="2px"
                  backgroundColor="secondary.700"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text variant="h4_800" color="#fff">
                    {data.digit}
                  </Text>
                </Badge>
                <Text variant="h4_800" color="secondary.700">
                  Press {data.digit} to {lcrDetails.lcrDataDetails.message_structure} {data.alias}{' '}
                </Text>
              </HStack>
              <AccordionIcon
                sx={{
                  color: 'secondary.700 !important',
                  width: '24px',
                  height: '24px',
                }}
                ml={2}
              />
            </AccordionButton>
            <AccordionPanel pb={4} bg="container.background" border="none" padding={'0'}>
              <Flex mt={'10'} gap={2} direction="column">
                <Text variant="body_800" color="secondary.700">
                  SERVICE
                </Text>
                <Link to={getServiceDetailsPath(data.service_id)}>
                  <Text variant="h4" color={'brand.blue'}>
                    {data.service_name}
                  </Text>
                </Link>
              </Flex>

              {lcrDetails.lcrDataDetails.routing_type !== 'voicemail' && (
                <Flex mt={5} gap={2} direction="column">
                  <Text variant="body_800" color="secondary.700">
                    SCHEDULE
                  </Text>
                  {data.schedules.map(data => (
                    <Link key={data.id} to={getSchedulesDetailsPath(data?.id)}>
                      <Text variant="h4" color={'brand.blue'}>
                        {data.name}
                      </Text>
                    </Link>
                  ))}
                </Flex>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </HStack>
  );
};

export default ActionCardContainer;
