import { VStack, Text } from '@chakra-ui/react';
import { FormButton, useToast } from 'library/atoms';
import { Modal } from 'library/molecules';
import { useState } from 'react';

import { bulkResolveIncident } from '../common/commands';
import { INCIDENT_ACTION_ID } from '../common/enums';
import { IncidentListActionProps } from '../common/types';
import { truncate } from '../common/util';
import { useResolveIncidentMutation } from '../graphql/mutation';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENT_RESOLVED } from 'core/const/tracker';

export const ResolveIncident = ({
  incidentIds,
  incidentName = '',
  action,
  onSave,
  onClose,
  containsParentIncidents,
}: IncidentListActionProps) => {
  const toast = useToast();
  const isBulkResolve = incidentIds.length > 1;
  // using state method because bulk resolve is REST api, so consuming single isLoading for both rest and graphQL one
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: resolveIncidentMutation } = useResolveIncidentMutation();
  const onResolve = () => {
    setIsLoading(true);
    isBulkResolve ? bulkResolve() : inlineResolve();
  };

  const bulkResolve = () => {
    bulkResolveIncident(incidentIds)
      .then(() => {
        onSuccess();
      })
      .catch(error => {
        onError(error);
      });
  };

  const inlineResolve = () => {
    resolveIncidentMutation({ incidentID: incidentIds[0] })
      .then(() => {
        onSuccess();
      })
      .catch(error => {
        onError(error);
      });
  };

  const onSuccess = () => {
    setIsLoading(false);
    const msgSubStr = isBulkResolve
      ? `${incidentIds.length} incidents`
      : `'${truncate(incidentName)}'`;
    toast({
      status: 'success',
      text: `Success: ${msgSubStr} marked as Resolved`,
    });
    onSave();
    onClose();

    AppTracker.track(T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENT_RESOLVED, {
      'Incident ID': incidentIds,
    });
  };

  const onError = (error: any) => {
    setIsLoading(false);
    toast({
      status: 'error',
      text: 'Error: Unable to update Incident status',
    });
    console.error(error);
  };

  return (
    <Modal
      key="resolve-incident"
      body={
        <VStack rowGap={1} alignItems="flex-start">
          <Text variant="formInput" color="secondary.700">
            {`Are you sure you want to mark ${
              isBulkResolve ? `${incidentIds.length} incidents` : 'this incident'
            } as resolved? ${containsParentIncidents ? 'Some incidents have child incidents' : ''}`}
          </Text>

          <Text variant="formInput_800" color="secondary.700">
            Note : This cannot be changed later
          </Text>
        </VStack>
      }
      isOpen={[INCIDENT_ACTION_ID.INLINE_RESOLVE, INCIDENT_ACTION_ID.BULK_RESOLVE].includes(action)}
      onClose={onClose}
      primaryButton={
        <FormButton title="Resolve" onClick={onResolve} isLoading={isLoading} variant="primary" />
      }
      secondaryButton={
        <FormButton title="Cancel" onClick={onClose} disabled={isLoading} variant="secondary" />
      }
      title={`Resolve Incident${isBulkResolve ? 's' : ''}`}
    />
  );
};
