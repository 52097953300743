import { ITeam } from 'core/interfaces/ITeams';
import React, { useEffect, useMemo } from 'react';
import { MultiValue, OnChangeValue, SingleValue } from 'react-select';
import { IAppState } from '../../../core/interfaces/IAppState';
import { FilterOptions } from '../const';
import { useSecondaryFilters } from '../context';
import { SelectOption } from '../types';
import DropdownContainer from './dropdown_container';
import { Select } from './select';
import { shallowEqual, useSelector } from 'react-redux';

interface Props {
  selected: string[];
  isInitializedOpen?: boolean;
  isMulti?: boolean;
}

function Teams(props: Props): JSX.Element {
  const organization = useSelector((state: IAppState) => state.organization, shallowEqual);
  const teams = organization.teams.t.filter(team =>
    team.members.find(member => member.user_id === organization.currentUser.u?.id),
  ) as ITeam[];
  const { updateFilter } = useSecondaryFilters();
  const { isInitializedOpen } = props;

  const serviceOptions = useMemo(
    () =>
      teams.map((t: ITeam) => {
        return {
          label: t.name,
          value: t.id,
        };
      }),
    [teams],
  );

  const [selectedOptions, selectedNames] = useMemo(() => {
    const sel = teams.filter(t => {
      return props.selected.some(f => {
        return f === t.id;
      });
    });

    return [
      sel.map(t => {
        return {
          label: t.name,
          value: t.id,
        };
      }),
      sel.map(t => t.name),
    ];
  }, [teams, props.selected]);

  const handleChange = (newValue: OnChangeValue<SelectOption, boolean>) => {
    const arr: string[] = [];

    if (Array.isArray(newValue)) {
      const options = newValue as MultiValue<SelectOption>;
      options.map(o => {
        arr.push(o.value);
      });
    } else if (newValue) {
      const option = newValue as SingleValue<SelectOption>;
      if (option?.value) {
        arr.push(option.value);
      }
    }

    updateFilter('teams', arr);
  };

  return (
    <DropdownContainer
      selected={selectedNames}
      filterKey="teams"
      label={FilterOptions['teams'].slice(0, props.isMulti ? undefined : -1)}
      isInitializedOpen={isInitializedOpen}
    >
      <Select
        options={serviceOptions}
        value={selectedOptions}
        onChange={handleChange}
        isLoading={!serviceOptions.length}
        isMulti={props.isMulti || undefined}
      />
    </DropdownContainer>
  );
}

export default Teams;
