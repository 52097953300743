import * as React from 'react';
import { SVGProps } from 'react';

const SvgDynatraceLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M7 2.1c-.2 1.3-.5 3.2-.7 5.2-.3 3.4-.1 5.7-.1 5.7l-4.8 4.6S1 15.1.9 12.2C.8 10.4.8 8.9.8 7.9v-.2c0-.1.1-.7.7-1.3.6-.8 5.6-4.7 5.5-4.3z"
      style={{
        fill: '#1496ff',
      }}
    />
    <path
      d="M7 2.1c-.2 1.3-.5 3.2-.7 5.2 0 0-5.3-.7-5.6.7 0-.1.1-.8.7-1.5S7.1 1.7 7 2.1z"
      style={{
        fill: '#1284ea',
      }}
    />
    <path
      d="M.7 7.6v.3c.1-.2.1-.4.4-.7.4-.4 1-.6 1.3-.6 1.3-.1 3.2-.4 5.2-.4 3.3-.1 5.6.1 5.6.1L18 1.7s-2.5-.4-5.4-.8C10.7.7 9 .5 8.1.5 8 .5 7.4.4 6.8 1c-.8.7-4.2 4-5.6 5.3-.6.6-.5 1.3-.5 1.3z"
      style={{
        fill: '#b4dc00',
      }}
    />
    <path
      d="M17.8 13.4c-1.3.1-3.2.4-5.2.4-3.3.1-5.6-.1-5.6-.1l-4.8 4.6s2.5.5 5.4.8c1.8.2 3.3.3 4.2.4.1 0 .1-.1.2-.1s.7-.1 1.3-.7c.7-.7 4.8-5.4 4.5-5.3z"
      style={{
        fill: '#6f2da8',
      }}
    />
    <path
      d="M17.8 13.4c-1.3.1-3.2.4-5.2.4 0 0 .4 5.3-.9 5.6.1 0 1-.1 1.6-.7.7-.5 4.8-5.4 4.5-5.3z"
      style={{
        fill: '#591f91',
      }}
    />
    <path
      d="M12 19.5h-.3c.2-.1.4-.1.7-.3.5-.4.7-1 .7-1.3.2-1.3.5-3.2.7-5.1.3-3.4.1-5.7.1-5.7l4.8-4.6s.4 2.5.5 5.4c.1 1.9.1 3.6.1 4.5 0 .1.1.7-.6 1.3-.7.7-4.1 3.9-5.5 5.3-.5.6-1.2.5-1.2.5z"
      style={{
        fill: '#73be28',
      }}
    />
  </svg>
);

export default SvgDynatraceLogo20X20;
