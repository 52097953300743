import { useDisclosure } from '@chakra-ui/react';
import { Location } from 'history';
import { UnsavedChangesModal } from 'library/molecules';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

interface Props {
  title?: string;
  enableGuard: boolean;
}

export const RouteGuard: FC<Props> = ({
  title = 'You haven’t saved any changes. Are you sure you want to discard the changes?',
  enableGuard,
}) => {
  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  function handleBlockedNavigation(nextLocation: Location): boolean {
    if (!confirmedNavigation && enableGuard) {
      onOpen();
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  }

  function handleConfirmNavigationClick(): void {
    onClose();
    setConfirmedNavigation(true);
  }

  const preventNavigation = useCallback(
    (event: BeforeUnloadEvent) => {
      if (enableGuard) {
        event.preventDefault();
        event.returnValue = true;
      }
    },
    [enableGuard],
  );

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation);
    }
  }, [confirmedNavigation, lastLocation, history]);

  useEffect(() => {
    window.addEventListener('beforeunload', preventNavigation);

    return () => window.removeEventListener('beforeunload', preventNavigation);
  }, [preventNavigation]);

  return (
    <Fragment>
      <Prompt message={handleBlockedNavigation} />
      <UnsavedChangesModal
        isOpen={isOpen}
        onClose={onClose}
        onDiscard={handleConfirmNavigationClick}
        title={title}
      />
    </Fragment>
  );
};
