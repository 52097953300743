import { Box, HStack, Text } from '@chakra-ui/react';
import { API, exception } from 'core';
import { T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENT_REASSIGNED } from 'core/const/tracker';
import { FormButton, useToast } from 'library/atoms';
import { AssigneeType } from 'library/enums';
import { CustomDrawerComponent, IncidentAssignableSearch, Loader } from 'library/molecules';
import { useState } from 'react';
import { AppTracker } from 'shared/analytics/tracker';

import { DEFAULT_REACT_QUERY_CONFIG, EMPTY_STRING } from '../common/constants';
import { INCIDENT_ACTION_ID } from '../common/enums';
import { IncidentListActionProps } from '../common/types';
import { truncate } from '../common/util';
import { IncidentAssignToType } from '../graphql/generated/types';
import { useBulkReassignIncidentMutation, useReassignIncidentMutation } from '../graphql/mutation';
import { useGetIncidentAssignableEntitiesQuery } from '../graphql/query';

type ReassignProps = IncidentListActionProps & {
  assigneeId?: string;
  assigneeType?: string;
};

export const Reassign = ({
  incidentIds,
  incidentName,
  action,
  onSave,
  onClose,
  assigneeId,
  assigneeType,
}: ReassignProps) => {
  const [selectedId, setSelectedId] = useState(EMPTY_STRING);
  const [userData, setSelectedUserData] = useState({
    name: '',
    type: IncidentAssignToType.User,
  });

  const toast = useToast();
  const isBulkReassignAction = action === INCIDENT_ACTION_ID.BULK_REASSIGN;
  const msgSubstr = isBulkReassignAction
    ? `${incidentIds.length} incidents`
    : `'${truncate(incidentName ?? '')}'`;

  const onUserChange = (name: string, type: string) => {
    setSelectedUserData({
      name: name,
      type: type as IncidentAssignToType,
    });
  };

  const { mutateAsync: reassignIncident, isLoading: isReassigning } = useReassignIncidentMutation();
  const { mutateAsync: bulkReassignIncident, isLoading: isBulkReassigning } =
    useBulkReassignIncidentMutation();

  const reassign = () => {
    const { type, name } = userData;

    reassignIncident({
      input: {
        incidentID: incidentIds[0],
        reAssignToType: type,
        reAssignToID: selectedId ?? '',
      },
    })
      .then(() => {
        onSuccess(name, [incidentIds[0]]);
      })
      .catch(error => {
        exception.handle('E_REASSIGN_INCIDENT', error);
        onError(error.message);
      });
  };

  const bulkReassign = () => {
    const { type, name } = userData;

    bulkReassignIncident({
      input: {
        incidentIDs: incidentIds,
        reAssignToType: type,
        reAssignToID: selectedId ?? '',
      },
    })
      .then(data => {
        if (data.bulkReassignIncident.isSuccess) {
          onSuccess(name, incidentIds);
        } else if (data.bulkReassignIncident.errors.length) {
          onError();
        } else {
          onError(data.bulkReassignIncident.errMsg);
        }
      })
      .catch(error => {
        exception.handle('E_BULK_REASSIGN_INCIDENT', error);
        onError();
      });
  };

  const onSuccess = (name: string, incidentIds?: string[]) => {
    toast({
      status: 'success',
      text: `Success: ${msgSubstr} reassigned to ${name}`,
    });
    setSelectedId(EMPTY_STRING);
    onSave();

    AppTracker.track(T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENT_REASSIGNED, {
      'Incident ID': incidentIds ?? [],
    });
  };

  const onError = (errorMsg?: string) => {
    const text = errorMsg ?? `Error: Unable to reassign Incident${isBulkReassignAction ? 's' : ''}`;
    toast({
      status: 'error',
      text,
    });
  };

  const onSelectionChange = (e: any) => {
    setSelectedId(e);
  };

  return (
    <CustomDrawerComponent
      onClose={onClose}
      isOpen={true}
      size="md"
      disableBodyPadding
      title={
        action === INCIDENT_ACTION_ID.INLINE_REASSIGN
          ? 'Reassign'
          : `Reassign (${incidentIds.length} incidents)`
      }
      footer={
        <HStack spacing={5}>
          <FormButton
            title="Confirm"
            isDisabled={selectedId === EMPTY_STRING}
            isLoading={isReassigning || isBulkReassigning}
            onClick={isBulkReassignAction ? bulkReassign : reassign}
          ></FormButton>
          <FormButton
            variant="secondary"
            isDisabled={isReassigning || isBulkReassigning}
            title="Cancel"
            onClick={onClose}
          ></FormButton>
        </HStack>
      }
    >
      <Box
        style={{
          flexDirection: 'column',
          flex: 1,
          flexGrow: 1,
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box style={{ height: '93%', overflow: 'auto' }}>
          <IncidentAssignableSearch
            onSelectionUpdate={selectedAssignees => {
              const { id, name, type } = selectedAssignees[0];
              onSelectionChange(id);
              onUserChange(name, type);
            }}
            assignees={[{ id: assigneeId ?? '', type: assigneeType ?? '' }]}
            disallowType={[AssigneeType.Escalationpolicy]}
          />
        </Box>
        <Box p={1} marginTop={3} marginLeft={3} alignItems="center" bg="primary.100" width={'70%'}>
          <Text variant="formInput_800" color="secondary.700">
            Note: Reassigning will unsnooze the incident
          </Text>
        </Box>
      </Box>
    </CustomDrawerComponent>
  );
};
