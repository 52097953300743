import { Grid } from 'uie/components';
import { BaseContainer } from 'components/SecondaryFilter/DialogFilter/filters/dropdown_container';
import Priority from 'components/SecondaryFilter/DialogFilter/filters/priority';
import FocusedSearchBox from 'components/SecondaryFilter/DialogFilter/filters/search';
import Services from 'components/SecondaryFilter/DialogFilter/filters/services';
import Tags from 'components/SecondaryFilter/DialogFilter/filters/tags';
import Users from 'components/SecondaryFilter/DialogFilter/filters/users';
import { SecondaryFiltersKeys } from 'components/SecondaryFilter/types';
import useGetAllTags from 'core/hooks/useGetAllTags';
import { IService } from 'core/interfaces/IService';
import { PRIORITY_TYPE } from 'library/types';
import React, { useEffect, useState } from 'react';

import { CompareOptions } from './const';

type Props = {
  teamId: string;
  servicesList: IService[];
  addToCompareData: (service: string, tag: string, user: string, priority: PRIORITY_TYPE) => void;
  closeContainerDropdown: () => void;
};
const FilterContainer: React.FC<Props> = ({ ...props }) => {
  const [selectedFilter, setSelectedFilter] = useState('services');
  const [searchTerm, setSearchTerm] = useState('');
  const [service, setService] = useState('');
  const [tag, setTag] = useState('');
  const [user, setUser] = useState('');
  const [priority, setPriority] = useState('');

  const tagsList = useGetAllTags('', props.teamId);

  const updateFilter = (type: SecondaryFiltersKeys, data: string[]) => {
    if (type === 'services') {
      setService(data[0]);
    }
    if (type === 'tags') {
      setTag(data[0]);
    }
    if (type === 'users') {
      setUser(data[0]);
    }
    if (type === 'priority') {
      setPriority(data[0]);
    }
  };

  const addCompareData = () => {
    if (service) props.addToCompareData(service, tag, user, priority);
  };

  useEffect(() => {
    setSearchTerm('');
  }, [selectedFilter]);

  return (
    <BaseContainer
      filterOptionLabels={CompareOptions}
      selectedFilter={selectedFilter}
      onFilterSelect={filterType => setSelectedFilter(filterType)}
      isCompare={true}
      onAddCompare={addCompareData}
      isCompareDisabled={!service.length}
      closeContainerDropdown={props.closeContainerDropdown}
    >
      <Grid
        flexWidth={12}
        style={{
          flexDirection: 'column',
          padding: '14px',
        }}
      >
        <FocusedSearchBox
          searchTerm={searchTerm}
          onSearch={searchValue => setSearchTerm(searchValue)}
          placeholder={`Search for ${selectedFilter}`}
        />
        <Grid
          flexWidth={12}
          style={{
            flexDirection: 'column',
            height: '410px',
          }}
        >
          {selectedFilter === 'tags' && (
            <Tags
              selected={tag ? [tag] : []}
              tags={tagsList}
              filteredTerm={searchTerm}
              updateFilter={updateFilter}
            />
          )}
          {selectedFilter === 'services' && (
            <Services
              selected={[service]}
              filteredTerm={searchTerm}
              services={props.servicesList}
              updateFilter={updateFilter}
            />
          )}

          {selectedFilter === 'users' && (
            <Users
              selected={[user]}
              selectedTeam={props.teamId}
              filteredTerm={searchTerm}
              updateFilter={updateFilter}
            />
          )}
          {selectedFilter === 'priority' && (
            <Priority selected={[priority]} updateFilter={updateFilter} />
          )}
        </Grid>
      </Grid>
    </BaseContainer>
  );
};

export default FilterContainer;
