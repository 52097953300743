import React, { useEffect } from 'react';
import { Box, Grid, VStack } from '@chakra-ui/react';
import HelpAndGuide from '../screens/HelpAndGuide';
import { LCR_PATH } from 'views/main/routes/routes';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { PageHeader } from 'library/molecules';
import { IAppState } from 'core/interfaces/IAppState';

interface Props {
  children: React.ReactNode;
  title?: string;
}
function LCRProviderWrapper({ title, children }: Props) {
  const history = useHistory();
  const teamAction = useSelector((state: IAppState) => state.organization.selectedTeam);

  useEffect(() => {
    if (teamAction.action === 'REQUEST_ORG_SELECTED_TEAM_CHILDREN') {
      history.push(LCR_PATH);
    }
  }, [teamAction.teamId]);

  return (
    <Grid templateColumns="2.25fr 1fr" gap={3} overflowY="auto" h="inherit">
      <VStack alignItems="flex-start" bg="container.background">
        <PageHeader
          title={title}
          noButton
          desc="Live Call Routing allows end users to call a number and route those incoming calls and voicemails to the on-call responders."
        />
        <Box px={6} w="full" h="full">
          {children}
        </Box>
      </VStack>
      <HelpAndGuide />
    </Grid>
  );
}

export default LCRProviderWrapper;
