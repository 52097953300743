import { useContext } from 'react';

import { StatuspageComponentTimelineContext } from './context';

const useStatuspageComponentTimelineContext = () => {
  const context = useContext(StatuspageComponentTimelineContext);

  if (!context) {
    throw new Error(
      'useStatuspageComponentTimelineContext must be used within a StatuspageComponentTimelineProvider',
    );
  }

  return context;
};

export default useStatuspageComponentTimelineContext;
