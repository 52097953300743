import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useWorkflowDetails } from './hooks/useWorkflowDetails';
import { Center, ChakraProvider, VStack } from '@chakra-ui/react';
import { ErrorBoundary } from 'views/shared/components';
import { WorkflowHeader } from './components/WorkflowHeader';
import { WorkflowSummary } from './components/WorkflowSummary';
import { WorkflowDetailsTab } from './components/WorkflowDetailsTab';
import PageLoader from 'components/PageLoader';
import { WORKFLOW_DETAILS_TABS } from '../constant';
import { useCallback, useEffect } from 'react';
import qs, { ParsedQuery } from 'query-string';
import { WorkflowLogs } from './components/workflow-logs';
import { WorkflowLogsProvider } from '../context/workflowLogsContext';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_WP_DETAILS_PAGE_VIEWED } from 'core/const/tracker';
import { WORKFLOWS_PATH } from 'views/main/routes/routes';
import libraryTheme from 'library/theme';
import { Layout } from 'library/molecules';
import { Tab } from 'library/atoms';
import {
  GlobalTeamIDGate,
  useCompareGlobalAndEntityTeamId,
} from 'core/hooks/useCompareGlobalAndEntityTeamId';

const WorkflowDetails = () => {
  const params = useParams<{
    id: string;
  }>();

  const { search, pathname } = useLocation();

  const history = useHistory();
  const query = qs.parse(history.location.search) as ParsedQuery<string>;
  const [workflowPage, workflowSize] = [query['workflowPage'], query['workflowSize']];

  const { data, isLoading } = useWorkflowDetails(params.id);

  const onTabChange = useCallback(
    (index: number) => {
      const tab = WORKFLOW_DETAILS_TABS[index - 1];
      history.push(
        `${pathname}?tab=${tab.queryKey}&workflowPage=${workflowPage}&workflowSize=${workflowSize}`,
      );
    },
    [history, params.id],
  );

  const currentTab = qs.parse(search);
  const currentTabKey = typeof currentTab.tab === 'string' ? currentTab : undefined;

  const currentTabIndex = currentTabKey
    ? WORKFLOW_DETAILS_TABS.findIndex(tab => tab.queryKey === currentTab.tab)
    : 0;

  useEffect(() => {
    AppTracker.track(T_WA_WP_DETAILS_PAGE_VIEWED, {
      'Workflow Tab Viewed': WORKFLOW_DETAILS_TABS[currentTabIndex].analytics_key,
    });
  }, [currentTabIndex]);

  if (isLoading)
    return (
      <Center h="100vh">
        <PageLoader />
      </Center>
    );

  if (!data) {
    return <div>Not found</div>;
  }

  return (
    <ChakraProvider theme={libraryTheme}>
      <Layout>
        <ErrorBoundary>
          <GlobalTeamIDGate entityTeamId={data.team_id} />
          <WorkflowHeader
            id={data.id}
            title={data.title}
            description={data.description}
            tags={data.tags}
          />
          <WorkflowSummary
            actions={data.actions}
            owner_id={data.owner_id}
            owner_type={data.owner_type}
            enabled={data.enabled}
            execution_count={data.execution_count}
          />
          <VStack mt={6} bg={'container.background'} align="stretch" pl="4" pt="4">
            <Tab
              onChange={onTabChange}
              labels={WORKFLOW_DETAILS_TABS}
              defaultIndex={currentTabIndex + 1}
              variant="outline"
              isLazy
            >
              <WorkflowDetailsTab
                actions={data.actions}
                filters={data.filters}
                trigger={data.trigger}
              />
              <WorkflowLogsProvider id={data.id}>
                <WorkflowLogs />
              </WorkflowLogsProvider>
            </Tab>
          </VStack>
        </ErrorBoundary>
      </Layout>
    </ChakraProvider>
  );
};

export default WorkflowDetails;
