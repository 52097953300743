import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={2490}
    height={2500}
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 256 257"
    {...props}
  >
    <path
      fill="#995B80"
      d="m98.875 232.033-26.433-7.408-25.001-28.508 31.272-.863 20.162 36.779m-61.125-18.8-14.875-4.166-14.058-16.034 17.082-2.809 11.851 23.009"
    />
    <path
      fill="#7B3F65"
      d="m0 191.017 15.204 3.091 2.207-3.882V58.503l-2.207-2.561L0 64.6v126.417"
    />
    <path
      fill="#C17B9D"
      d="M73.933 69.708 15.208 55.942v138.166l8.798-.818 13.744 19.943 10.6-22.205 25.583-2.378V69.708"
    />
    <path
      fill="#7B3F65"
      d="m33.958 198.133 26.063 5.25 1.716-4.045V37.44l-1.716-3.665-26.063 13.208v151.15"
    />
    <path
      fill="#C17B9D"
      d="M208.734 81.516 60.021 33.775v169.612l17.221-2.216 21.633 30.862 17.126-35.85 92.733-11.933V81.516"
    />
    <path fill="#995B80" d="m181.833 256.492-37.566-10.525-35.509-40.5 46.033-.468 27.042 51.493" />
    <path
      fill="#7B3F65"
      d="m89.591 208.95 38.33 7.417 2.977-2.566V4.117L127.921 0l-38.33 19.158V208.95"
    />
    <path
      fill="#C17B9D"
      d="M256 64.033 127.925 0v216.367l22.597-4.528 31.311 44.653 26.901-56.309-.017-.002L256 190.708V64.033"
    />
  </svg>
);
export default SvgComponent;
