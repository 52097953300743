import {
  REQUEST_ORG_PLAN,
  REQUEST_ORG_PLAN_SUCCESS,
  REQUEST_ORG_PLAN_FAIL,
} from '../../const/organization';
import { IBillingPlan } from '../../interfaces/IBillingPlan';

const requestOrganizationPlan = () => ({
  type: REQUEST_ORG_PLAN,
  payload: {},
});

const onRequestOrganizationPlanSuccess = (billingPlan: IBillingPlan) => ({
  type: REQUEST_ORG_PLAN_SUCCESS,
  payload: {
    billingPlan,
  },
});

const onRequestOrganizationPlanFail = (error: any) => ({
  type: REQUEST_ORG_PLAN_FAIL,
  payload: {
    error,
  },
});

export { requestOrganizationPlan, onRequestOrganizationPlanSuccess, onRequestOrganizationPlanFail };
