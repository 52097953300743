import {
  ErrorBlock,
  FocusBlock,
  Grid,
  Heading,
  InputBlock,
  Label,
  Para,
  PulseLoader,
  SelectBox,
  TextButton,
  Theme,
} from 'uie/components';
import * as React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IUserInfo } from '../../../../../core/interfaces/IUserData';
import { dialCodes } from '../../../../../store/countryCodes';
import ProfileService from '../../../../../core/services/service.editprofile';
import * as shortId from 'shortid';
import { exception } from '../../../../../core/exception';
import { Box, Text } from '@chakra-ui/react';
import { InfoIcon } from 'library/icons';

const CustomTooltip = ({ label, children }: { label: string; children: React.ReactNode }) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <Box
      position="relative"
      display="inline-block"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}

      {showTooltip && (
        <Box
          position="absolute"
          top="110%"
          left="50%"
          transform="translateX(-50%)"
          p={5}
          paddingBottom={10}
          bg="#E2E8F0"
          color="#1A365D"
          borderRadius={12}
          boxShadow="md"
          textAlign="center"
          zIndex={9999}
          width="350px"
          whiteSpace="normal"
          fontSize="sm"
        >
          <Text fontSize={15}>{label}</Text>
          <Box
            position="absolute"
            top="-8px"
            left="50%"
            transform="translateX(-50%)"
            width={0}
            height={0}
            borderLeft="8px solid transparent"
            borderRight="8px solid transparent"
            borderBottom="8px solid #E2E8F0"
          />
        </Box>
      )}
    </Box>
  );
};

interface IProps extends Pick<IAppState, 'userInfo'> {
  hide: () => void;
  openVerificationModal: () => void;
  UserInfo: IUserInfo;
}

interface IState {
  dialCode: string;
  mobileNumber: string;
  mobileNumberError: string;
  showCountryDropDown: boolean;
  countryCodeSearch: string;
  networkState: 'idle' | 'sending-otp' | 'updating-phone';
}

class EditPhoneNumberModal extends React.Component<IProps, IState> {
  private _ProfileService = new ProfileService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      dialCode:
        this.props.UserInfo && this.props.UserInfo.contact
          ? this.props.UserInfo.contact.dial_code
          : dialCodes[0].dial_code,
      mobileNumber:
        this.props.UserInfo && this.props.UserInfo.contact
          ? this.props.UserInfo.contact.phone_number
          : '',
      mobileNumberError: '',
      showCountryDropDown: false,
      countryCodeSearch: '',
      networkState: 'idle',
    };
  }

  public searchValueChange = (value: string) => {
    this.setState({ countryCodeSearch: value });
  };

  public onMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ mobileNumberError: '', mobileNumber: e.target.value });
  };

  public isValid = (mobile: string) => {
    if (mobile.length < 4 || mobile.length > 15) {
      this.setState({ mobileNumberError: 'Enter a valid Phone Number' });
      return false;
    }
    return true;
  };

  public save = async () => {
    if (!this.isValid(this.state.mobileNumber)) return;
    try {
      this.setState({ networkState: 'updating-phone' });
      await this._ProfileService.update({
        first_name: this.props.UserInfo.first_name,
        last_name: this.props.UserInfo.last_name,
        contact: { dial_code: this.state.dialCode, phone_number: this.state.mobileNumber },
        time_zone: this.props.UserInfo.time_zone,
      });
      this.props.openVerificationModal();
    } catch (err: any) {
      this.setState({
        mobileNumberError:
          err?.response?.data?.meta?.error_message ?? 'Error processing request, try again later.',
      });
      exception.handle('E_UPDATE_USER', err);
    } finally {
      this.setState({ networkState: 'idle' });
    }
  };

  public render() {
    const { theme } = Theme;
    const selectedDial = dialCodes.find(
      ({ dial_code }: any) => dial_code === this.state.dialCode,
    ) || {
      dial_code: '',
      code: '',
    };

    return (
      <div style={{ zIndex: 0 }}>
        <Heading fontSize={20} style={{ lineHeight: 1.2 }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            {this.props.UserInfo?.contact?.phone_number ?? false ? 'Edit' : 'Add'}
            {' Phone Number'}
            <CustomTooltip label="By providing your phone number, you are opting-in to receive incident-related SMS and Voice notifications on this number.">
              <Box pl={2} display="inline-flex" alignItems="center" cursor="pointer">
                <InfoIcon color="brand.black" width={12} height={12} />
              </Box>
            </CustomTooltip>
          </Box>
        </Heading>
        <div style={{ marginTop: 30 }}>
          <Label htmlFor="profile-edit-phone-input">Mobile Number</Label>
          <Grid style={{ marginTop: 10 }}>
            <div style={{ width: 130, marginRight: 10 }}>
              <SelectBox
                hook={
                  <div>
                    <span>{selectedDial.dial_code}</span>
                    <span style={{ color: theme.shades.grey, marginLeft: 10 }}>
                      {selectedDial.code}
                    </span>
                  </div>
                }
                onValueChange={(_, v: string) => {
                  this.setState({ dialCode: v });
                }}
                searchHookProps={{
                  value: this.state.countryCodeSearch,
                  onChange: e => this.searchValueChange(e.target.value),
                }}
                height="auto"
                width="100%"
                maxWidth="100%"
              >
                {dialCodes
                  .filter(({ name, dial_code }: { name: string; dial_code: string }) => {
                    return (
                      this.state.countryCodeSearch === '' ||
                      name.toLowerCase().indexOf(this.state.countryCodeSearch) > -1 ||
                      dial_code.toLowerCase().indexOf(this.state.countryCodeSearch) > -1
                    );
                  })
                  .map(({ dial_code, code }: { dial_code: string; code: string }) => (
                    <FocusBlock
                      value={dial_code}
                      key={shortId.generate()}
                      isSelected={dial_code === this.state.dialCode}
                    >
                      <div>
                        <span>{dial_code}</span>
                        <span style={{ color: theme.shades.grey, marginLeft: 10 }}>{code}</span>
                      </div>
                    </FocusBlock>
                  ))}
              </SelectBox>
            </div>
            <InputBlock
              id="profile-edit-phone-input"
              width={400}
              type="number"
              value={this.state.mobileNumber}
              onChange={this.onMobileChange}
            />
          </Grid>
        </div>
        <ErrorBlock>{this.state.mobileNumberError}</ErrorBlock>
        <TextButton
          style={{ marginTop: 20 }}
          onClick={this.save}
          disabled={
            this.state.dialCode === '' ||
            this.state.mobileNumber === '' ||
            this.state.mobileNumberError !== '' ||
            this.state.networkState !== 'idle' ||
            (this.state.mobileNumber === (this.props.UserInfo?.contact?.phone_number ?? '') &&
              this.state.dialCode === (this.props.UserInfo?.contact?.dial_code ?? '') &&
              (this.props.UserInfo?.phone_verified ?? false) === true)
          }
        >
          {this.state.networkState === 'updating-phone' ? (
            <PulseLoader base="10px" color={theme.shades.white} />
          ) : (
            <Para color={theme.shades.white} style={{ margin: '5px', fontWeight: 600 }}>
              Verify
            </Para>
          )}
        </TextButton>
      </div>
    );
  }
}

export default connect((state: IAppState) => ({
  userInfo: state.userInfo,
}))(EditPhoneNumberModal);
