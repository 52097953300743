import { DialogModalFrame, Grid, Para, TextButton, Theme } from 'uie/components';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './../upgradeonly.tooltip/index.css';

const { theme } = Theme;

interface IProps {
  showModal?: boolean;
  message: string;
  header: string;
  hasBillingPermission: boolean;
  onCancel: () => void;
  onUpgrade?: () => void;
}

const UpgradePlanModal = ({
  hasBillingPermission,
  showModal,
  onUpgrade,
  onCancel: onCancelUpgrade,
  message,
  header,
}: IProps) => {
  const closeModal = () => {
    showModal = false;
  };
  const history = useHistory();
  const onUpgradePlanAction = async () => {
    onUpgrade ? await onUpgrade() : '';
    history.push('/settings/billings');
  };

  return (
    <>
      <DialogModalFrame
        root="unsaved-changes-confirm"
        id="modalFrame"
        onClose={() => {
          showModal = false;
          onCancelUpgrade ? onCancelUpgrade() : closeModal();
        }}
        padding="24px"
        width="520px"
      >
        {showModal && (
          <>
            <Para
              fontSize={24}
              fontWeight={500}
              className="mb-10 mt-10"
              style={{
                fontStyle: 'normal',
                fontFamily: 'Mulish',
                lineHeight: '40px',
                color: '#2D3964',
              }}
            >
              {header}
            </Para>

            <Para
              fontSize={16}
              fontWeight={500}
              style={{
                fontFamily: 'Mulish',
                fontStyle: 'normal',
                lineHeight: '24px',
                color: '#969AA0',
              }}
              color={theme.shades.cement}
            >
              {message}
              <br />
              <br />
              {!hasBillingPermission ? (
                <>
                  You do not seem to have the right permissions to upgrade the current plan. Reach
                  out to your Account Owner for the same.
                </>
              ) : (
                <>
                  To use this feature,{' '}
                  <Link
                    to="/settings/billings"
                    target="_blank"
                    rel="noreferrer"
                    className="modal_link"
                  >
                    upgrade to one of our higher plans
                  </Link>{' '}
                  or learn more about all our plans{' '}
                  <a
                    href="https://www.squadcast.com/pricing"
                    target="_blank"
                    rel="noreferrer"
                    className="modal_link"
                  >
                    here
                  </a>
                </>
              )}
            </Para>

            <div style={{ marginTop: 15 }}>
              <Grid justifyContent="flex-end">
                <TextButton
                  buttonType="ghost"
                  onClick={onCancelUpgrade || closeModal}
                  style={{ padding: '4px 8px' }}
                  className="mr-20"
                >
                  <Para color={theme.font.default} fontWeight={500} fontSize={16}>
                    Cancel
                  </Para>
                </TextButton>

                <TextButton
                  style={{ padding: '4px 8px' }}
                  onClick={onUpgradePlanAction}
                  disabled={!hasBillingPermission}
                >
                  <Para color={theme.font.white} fontWeight={500} fontSize={16}>
                    Upgrade
                  </Para>
                </TextButton>
              </Grid>
            </div>
          </>
        )}
      </DialogModalFrame>
    </>
  );
};
export default UpgradePlanModal;
