import { PRIORITY_TYPE } from 'library/types';
import {
  IncidentPriority,
  IncidentPriorityFilter,
} from 'views/main/organization/incident-list/graphql/generated/types';

export { IncidentPriorityFilter as PRIORITY };
export { IncidentPriority };

export const PRIORITY_OPTIONS = [
  { value: IncidentPriorityFilter.P1, label: 'Priority 1' },
  { value: IncidentPriorityFilter.P2, label: 'Priority 2' },
  { value: IncidentPriorityFilter.P3, label: 'Priority 3' },
  { value: IncidentPriorityFilter.P4, label: 'Priority 4' },
  { value: IncidentPriorityFilter.P5, label: 'Priority 5' },
  { value: IncidentPriorityFilter.Unset, label: 'Unset' },
];

export const getPriorityValue = (val: IncidentPriorityFilter) => {
  if (val === IncidentPriorityFilter.Unset) return null;
  return val as unknown as IncidentPriority;
};

export const getPrioritySelectBoxValue = (val?: PRIORITY_TYPE) => {
  if (val === null || !val) return IncidentPriorityFilter.Unset;
  return val as unknown as IncidentPriorityFilter;
};
