import {
  DeleteStatusPageMaintenanceMutation,
  DeleteStatusPageMaintenanceMutationVariables,
  useDeleteStatusPageMaintenanceMutation,
} from '../graphql/mutation';
import { useGetMaintenanceListQuery } from '../graphql/query';
import moment from 'moment';
import React, { useReducer } from 'react';
import { UseMutationOptions, useQueryClient } from 'react-query';

import { queryKeys, reactQueryConfig } from '../constants/status.constants';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/helper.toast';
import { MaintenanceListReducer } from '../reducers/MaintenanceListReducer';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_MAINTENANCE_DELETED } from 'core/const/tracker';

const useDeleteMaintenance = <TError = unknown, TContext = unknown>(
  onSuccess: () => void,
  options?: UseMutationOptions<
    DeleteStatusPageMaintenanceMutation,
    TError,
    DeleteStatusPageMaintenanceMutationVariables,
    TContext
  >,
) => {
  const queryClient = useQueryClient();
  return useDeleteStatusPageMaintenanceMutation({
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.GET_MAINTENANCE_LIST]);
      onSuccess();
    },
    onError: reactQueryConfigError('Delete maintenance'),
  });
};

export function useMaintenanceList(pageId: number) {
  const queryClient = useQueryClient();
  const [state, dispatch] = useReducer(MaintenanceListReducer, {
    search: {
      searchText: '',
      enabled: false,
    },
    pagination: {
      queryPageIndex: 1,
      queryPageSize: 5,
    },
    drawer: {
      isDrawerOpen: false,
      drawerType: null,
      maintenanceId: -1,
    },
    filter: {
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    },
    deleteMaintenace: {
      showModal: false,
      selectedMaintenanceId: -1,
    },
  });

  const maintenanceListData = useGetMaintenanceListQuery(
    {
      input: {
        pageID: pageId,
        pageNumber: state.pagination.queryPageIndex,
        pageSize: state.pagination.queryPageSize,
        search: state.search.searchText,
        startTime: moment(`${state.filter.year}${state.filter.month + 1}`, 'YYYYMM')
          .startOf('M')
          .toISOString(),
        endTime: moment(`${state.filter.year}${state.filter.month + 1}`, 'YYYYMM')
          .endOf('M')
          .toISOString(),
      },
    },
    { ...reactQueryConfig, onError: reactQueryConfigError('Get maintenance list') },
  );

  const { mutateAsync: deletePageMaintenanceAction } = useDeleteMaintenance(() => {
    if (typeof maintenanceListData.refetch === 'function') {
      maintenanceListData.refetch();
    }

    reactQueryConfigSuccess('Maintenance deleted');

    AppTracker.track(T_WA_UP_STATUS_PAGE_V2_MAINTENANCE_DELETED);
  });

  return {
    list: maintenanceListData?.data?.listStatusPageMaintenances.data || [],
    totalCount: maintenanceListData?.data?.listStatusPageMaintenances.meta?.totalCount || 0,
    isLoading: maintenanceListData.isLoading,
    isError: maintenanceListData.isError,
    isSuccess: maintenanceListData.isSuccess,
    refetchMaintenanceList: maintenanceListData.refetch,
    state,
    dispatch,
    deletePageMaintenanceAction,
  };
}
