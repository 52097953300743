import { Box } from '@chakra-ui/react';
import { Para } from 'uie/components';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService } from 'core';
import { T_WA_UP_SHG_LIST_PAGE_VIEWED } from 'core/const/tracker';
import { IAppState } from 'core/interfaces/IAppState';
import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AppTracker } from 'shared/analytics/tracker';
import EmptyStakeHolderPage from './component/EmptyStakeHolderPage';
import StakeholderComponent from './component/stakeholderComponent';
import { findNonCommonObjects } from './helpers';
import { useStakeHolders } from './hooks/stakeholders.hooks';
import { IStakeHolderProps } from './types/stakeholder.types';
import { getFilteredUsers } from './utils/utils.stakeholders';

export function OrganizationSettingsStakeHolderGroupRenderRoles(props: IStakeHolderProps) {
  const { team, organization } = props;
  const history = useHistory();

  const { fetchAllStakeHolderGroups, fetchingStakeHolders } = useStakeHolders();
  const [stakeHolderGroupData, setStakeHolderGroupData] = useState([]);
  const navigatoinURL = 'https://support.squadcast.com/manage-teams/stakeholder-groups';

  const fetchData = async () => {
    const res = await fetchAllStakeHolderGroups(props.team?.id);
    if (res) setStakeHolderGroupData(res);
  };

  const stakeholders = useMemo(() => {
    const filter = getFilteredUsers(team, organization, '');
    return findNonCommonObjects(filter, []);
  }, [team, organization]);

  useEffect(() => {
    fetchData();
    AppTracker.track(T_WA_UP_SHG_LIST_PAGE_VIEWED);
  }, []);

  const isThisFeatureAvailable = useMemo(() => {
    return organization
      ? !BillingService.isFeatureDisabled(
          { organization } as unknown as Pick<IAppState, 'organization'>,
          'stakeholder-groups',
        )
      : true;
  }, [organization, BillingService]);

  const toggleModal = useCallback(() => {
    history.replace(`/settings/teams/${team.id}`);
  }, [history, team.id]);

  return (
    <React.Fragment>
      <Para style={{ marginBottom: '10px', fontSize: '12px', color: '#808080' }}>
        Stakeholder Groups are subsets of stakeholders within teams who can be notified about
        progress of incidents that have occurred. Learn more about Stakeholder Groups
        <a href={navigatoinURL} target="_blank" rel="noopener noreferrer">
          {' '}
          here
        </a>
        .
      </Para>
      <Box style={{ height: 'calc(100vh - 150px' }}>
        {stakeHolderGroupData.length || stakeholders.length || fetchingStakeHolders ? (
          <StakeholderComponent {...props} />
        ) : (
          <EmptyStakeHolderPage team={team} />
        )}
      </Box>
      <UpgradePlanModal
        hasBillingPermission={BillingService.hasManageBillingPermission({
          organization,
        } as unknown as Pick<IAppState, 'organization'>)}
        showModal={!isThisFeatureAvailable}
        onCancel={toggleModal}
        message={BillingService.getMessage(0, 'stakeholder', {
          organization,
        } as unknown as Pick<IAppState, 'organization'>)}
        header={BillingService.getHeader(0, 'stakeholder', {
          organization,
        } as unknown as Pick<IAppState, 'organization'>)}
        onUpgrade={toggleModal}
      />
    </React.Fragment>
  );
}

export default React.memo(OrganizationSettingsStakeHolderGroupRenderRoles);
