import { Box, Text, VStack } from '@chakra-ui/react';
import { Para, TextButton, Theme } from 'uie/components';
import { ITeam } from 'core/interfaces/ITeams';
import { AddStakeHolderIcon } from 'icons';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSettingPageACL } from '../../../../acl';

function EmptyStakeHolderPage(props: { team: ITeam }) {
  const { theme } = Theme;
  const { team } = props;
  const userAccessStore = useSettingPageACL(state => state);
  const hasCreate = userAccessStore.hasCreateAccess('stakeholder_groups');
  return (
    <VStack style={{ height: 'calc(100vh - 150px', justifyContent: 'center' }}>
      <AddStakeHolderIcon style={{ height: '35%', width: '90%' }} />
      <Box style={{ width: '40%' }}>
        <Text
          style={{
            fontSize: '27px',
            fontWeight: 400,
            lineHeight: '32.25px',
            textAlign: 'center',
          }}
        >
          No stakeholders available, add stakeholders to the team to create stakeholder group.
        </Text>
      </Box>
      <NoPermissionTooltip isDisabled={hasCreate}>
        <TextButton height="30px" disabled={!hasCreate}>
          <Link style={{ textDecoration: 'none' }} to={`/settings/teams/${team.id}`}>
            <Para color={theme.font.white}>Add stakeholder</Para>
          </Link>
        </TextButton>
      </NoPermissionTooltip>
    </VStack>
  );
}

export default React.memo(EmptyStakeHolderPage);
