import { HStack, Flex } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/table-core';
import { DownloadBlue } from 'icons';
import { useMemo } from 'react';

import { Link } from 'react-router-dom';
import { getServiceDetailsPath } from 'views/main/organization/lcr/common/utils';
import { LCRDataLogsResponse } from 'views/main/organization/lcr/common/interface';
import AudioPlayer from 'views/main/organization/lcr/components/AudioPlayer';
import { getIncidentDetailsPath } from 'views/main/organization/incident-list/common/util';
import { AssigneeAvatar, FormButton, Tooltip, usernameTooltipLabel } from 'library/atoms';
import { getAssigneeComponent } from 'views/main/organization/incident-list/listing/column-utils';
import { AssigneeType } from 'library/enums';
import { HoverComponentProps } from 'library/molecules/Tablev2/Table';
import { Text } from 'library/atoms/Text';

type Props = {
  onViewLogs: ({ id }: { id: string }) => void;
};

type LCRLogsData = LCRDataLogsResponse['data'][number];

export const HoverComponent = (props: HoverComponentProps<LCRLogsData> & Props) => {
  return (
    <HStack gap="0.5rem">
      <FormButton
        onClick={() => props.onViewLogs({ id: props.row.original.id })}
        title="View Logs"
      />
    </HStack>
  );
};

export const useLCRLogsColumn = () => {
  const columns = useMemo<ColumnDef<LCRLogsData, any>[]>(
    () => [
      {
        header: 'CALLER ID',
        cell: ({ row }) => <Text>{row.original.caller_number} </Text>,
      },

      {
        header: 'INCIDENT ID',
        cell: ({ row }) => {
          return row.original.incident_id ? (
            <Link to={getIncidentDetailsPath(row.original.incident_id)}>
              <Text>{row.original.incident_id}</Text>
            </Link>
          ) : (
            <Text>No Incident Linked</Text>
          );
        },
      },
      {
        header: 'RECORDING ',
        cell: ({ row }) => {
          return (
            <Flex direction="column" width="200px">
              {row.original.recording_url !== '' ? (
                <Flex mt={1} alignItems="center" gap={1}>
                  <a target="_blank" href={row.original.recording_url} rel="noreferrer">
                    <DownloadBlue cursor="pointer" />
                  </a>
                  <AudioPlayer audioUrl={row.original.recording_url} isTable={true} />
                </Flex>
              ) : (
                <Text>No recording available</Text>
              )}
            </Flex>
          );
        },
      },
      {
        header: 'ASSIGNED TO',
        cell: ({ row }) => {
          const {
            assigned_to_type: type,
            assigned_to_id: id,
            assigned_to_name: name,
          } = row.original;
          return (
            <>
              {id &&
                (type !== AssigneeType.User ? (
                  <HStack pl={6} columnGap="4px">
                    <AssigneeAvatar
                      name={AssigneeType.Escalationpolicy}
                      type={AssigneeType.Escalationpolicy}
                    />
                    {getAssigneeComponent(id, AssigneeType.Escalationpolicy, name)}
                  </HStack>
                ) : (
                  <Tooltip label={usernameTooltipLabel(name)} placement="bottom">
                    <HStack pl={6} columnGap="4px">
                      <AssigneeAvatar name={name} type={AssigneeType.User} />
                      {getAssigneeComponent(id, AssigneeType.User, name)}
                    </HStack>
                  </Tooltip>
                ))}
            </>
          );
        },
      },
      {
        header: 'SELECTED SERVICE',
        cell: ({ row }) => {
          return row.original.service_id ? (
            <Link to={getServiceDetailsPath(row.original.service_id)}>
              <Text>{row.original.service_alias}</Text>
            </Link>
          ) : (
            <Text>No service available</Text>
          );
        },
      },
      {
        header: 'DURATION',
        cell: ({ row }) => <Text>{row.original.duration_in_seconds} Sec</Text>,
      },
    ],
    [],
  );

  return columns;
};
