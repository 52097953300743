import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import Editor, { useMonaco } from '@monaco-editor/react';
import { useEffect } from 'react';
import { READONLY_MONACO_EDITOR_OPTIONS } from 'views/shared/constants';
import styles from './code-snippet.module.css';

export const CodeSnippet = ({ title, code }: { title: string; code: string }) => {
  const monaco = useMonaco();

  useEffect(() => {
    if (monaco) {
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: false,
      });
    }
  }, [monaco]);

  return (
    <Accordion className={styles.accordion} defaultIndex={0} allowToggle>
      <AccordionItem className={styles.title}>
        <h2>
          <AccordionButton outline="none">
            <Box flex="1" textAlign="left">
              {title}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel>
          <Editor
            height="60vh"
            language="json"
            value={code}
            options={READONLY_MONACO_EDITOR_OPTIONS}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
