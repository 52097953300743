import { IAppState } from 'core/interfaces/IAppState';
import { getAssignableTeamMembers } from 'shared/reducers';
import { ReassignEntity } from 'views/main/organization/workflow/types';

const getEscalationPolicies = (state: IAppState): ReassignEntity[] => {
  return state.organization.escalations.e.map(escalation => ({
    id: escalation.id,
    name: escalation.name,
    type: 'escalationpolicy',
  }));
};

const getSquadOptions = (state: IAppState): ReassignEntity[] => {
  return state.organization.squads.s.map(squad => ({
    id: squad.id,
    name: squad.name,
    type: 'squad',
  }));
};

const getAllUserOptions = (state: IAppState): ReassignEntity[] => {
  if (!state.organization.selectedTeam.team) {
    return [];
  }
  return getAssignableTeamMembers(
    state.organization.selectedTeam.team,
    state.organization.users.u,
  ).map(user => ({
    id: user.id,
    name: `${user.first_name} ${user.last_name}`,
    type: 'user',
  }));
};

export const getReassignEntityOptions = (state: IAppState): ReassignEntity[] => {
  const squadOptions = getSquadOptions(state);
  const userOptions = getAllUserOptions(state);
  const escationPolicyOptions = getEscalationPolicies(state);
  return [...userOptions, ...squadOptions, ...escationPolicyOptions];
};
