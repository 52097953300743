import { Grid, Para, SpinLoader, TextButton } from 'uie/components';
import { IAppState } from 'core/interfaces/IAppState';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { TEAM_SQUADS_PATH } from 'views/main/routes/routes';
import { useCreateSquad } from '../../hooks/useSquad';
import { Button, ButtonGroup, Container, Input, Label, Title } from './styles';

type SquadFormProps = {
  onClose: () => void;
};
const SquadForm = (props: SquadFormProps) => {
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const { mutate: createSquad, isLoading } = useCreateSquad({
    onSuccess: response => {
      props.onClose();
      const squadId = response.data.data.id;
      history.push(
        generatePath(TEAM_SQUADS_PATH, {
          id: params.id,
          sqid: squadId,
        }),
      );
    },
    onError: () => {
      // handle error
      props.onClose();
    },
  });

  const [squadName, setSquadName] = React.useState('');
  const currentUser = useSelector((state: IAppState) => state.organization.currentUser.u);

  const handleCreateSquad = () => {
    createSquad({
      name: squadName,
      members: [
        {
          user_id: currentUser?.id ?? '',
          role: 'owner',
        },
      ],
    });
  };

  return (
    <Container>
      <Title>Add New Squad</Title>
      <Label>Squad Name</Label>
      <Input
        placeholder="Enter squad name"
        value={squadName}
        onChange={e => setSquadName(e.target.value)}
      />
      <ButtonGroup>
        <TextButton onClick={handleCreateSquad} disabled={squadName.length === 0 || isLoading}>
          <Grid alignItems="center">
            <Para color={'#fff'}>Create</Para>
            {isLoading && <SpinLoader color={'#fff'} />}
          </Grid>
        </TextButton>
        <Button variant="secondary" onClick={props.onClose}>
          Cancel
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default SquadForm;
