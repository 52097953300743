import { Button, Input, InputGroup, InputLeftAddon, InputRightAddon } from '@chakra-ui/react';
import React from 'react';

interface IColorInput {
  color: string;
  isDisabled?: boolean;
  setColor: (color: string) => void;
}
function ColorInput(props: IColorInput) {
  const colorCode = props.color.split('#')[1];
  return (
    <InputGroup size={'md'} w={'210px'}>
      <InputLeftAddon>#</InputLeftAddon>
      <Input
        placeholder="ffffff"
        w="185px"
        borderRightWidth={0}
        value={colorCode}
        disabled={props.isDisabled}
        maxLength={6}
        onChange={e => {
          props.setColor(`#${e.target.value}`);
        }}
      />
      <InputRightAddon background="white" position="relative">
        <Button
          aria-label={props.color}
          background={props.color}
          height="22px"
          width="22px"
          padding={0}
          minWidth="unset"
          borderRadius={3}
          borderWidth={1}
          borderColor={'#f0ecec'}
        ></Button>
      </InputRightAddon>
    </InputGroup>
  );
}
export default ColorInput;
