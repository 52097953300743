import * as React from 'react';
import { SVGProps } from 'react';

const SvgFreshDeskLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M10 0h7.5C18.9 0 20 1.1 20 2.5V10c0 5.5-4.5 10-10 10S0 15.5 0 10 4.5.1 10 0z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#20a849',
      }}
    />
    <path
      d="M10 4.4c-2.4 0-4.4 2-4.4 4.4v3c0 .8.7 1.5 1.5 1.5h1.2V9.8H6.6v-1c.1-1.9 1.7-3.4 3.5-3.3s3.2 1.5 3.3 3.3v1h-1.7v3.4h1.1v.1c0 .7-.6 1.3-1.3 1.3h-1.3c-.1 0-.2.1-.2.2s.1.2.2.2h1.3c1 0 1.7-.8 1.7-1.7v-.1c.7-.2 1.1-.7 1.1-1.4v-3c.1-2.5-1.8-4.4-4.3-4.4.1 0 0 0 0 0z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#fff',
      }}
    />
  </svg>
);

export default SvgFreshDeskLogo;
