export const REQUEST_ROLES = 'REQUEST_ROLES';
export const REQUEST_ROLES_SUCCESS = 'REQUEST_ROLES_SUCCESS';
export const REQUEST_ROLES_FAIL = 'REQUEST_ROLES_FAIL';

export const REQUEST_USER_PERMISSION_ROLES = 'REQUEST_USER_PERMISSION_ROLES';
export const REQUEST_USER_PERMISSION_ROLES_SUCCESS = 'REQUEST_USER_PERMISSION_ROLES_SUCCESS';
export const REQUEST_USER_PERMISSION_ROLES_FAIL = 'REQUEST_USER_PERMISSION_ROLES_FAIL';

export const REQUEST_INTEGRATIONS = 'REQUEST_INTEGRATIONS';
export const REQUEST_INTEGRATIONS_SUCCESS = 'REQUEST_INTEGRATIONS_SUCCESS';
export const REQUEST_INTEGRATIONS_FAIL = 'REQUEST_INTEGRATIONS_FAIL';
