import { Box, Flex } from '@chakra-ui/layout';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import { CustomDrawerComponent } from 'library/molecules';
import { useWorkflowTagsQuery } from '../../api/hook';
import { workflowFilterValidation } from '../../constant/validation';
import { useWorkflowListContext } from '../../context/workflowListContext';
import { WorkflowFilterForm } from '../../types';
import FilterForm from './Form';
import FilterFormFooter from './Footer';

const FilterDrawer = () => {
  const { data: tagsList = [] } = useWorkflowTagsQuery();
  const workflowContext = useWorkflowListContext();

  return (
    <CustomDrawerComponent
      title="Filter"
      disableBodyPadding
      size="sm"
      isOpen={workflowContext.isFilterOpen}
      onClose={workflowContext.onCloseFilter}
    >
      <FormWrapper<WorkflowFilterForm>
        onSubmit={workflowContext.onApplyFilter}
        defaultValues={workflowContext.filterFormValues}
        validationSchema={workflowFilterValidation}
      >
        <Flex direction="column" justifyContent="space-between" height="100%">
          <Box sx={{ '& > form': { mb: 20 } }}>
            <FilterForm tagList={tagsList} />
          </Box>
          <FilterFormFooter />
        </Flex>
      </FormWrapper>
    </CustomDrawerComponent>
  );
};

export default FilterDrawer;
