import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#3398C4"
      fillRule="evenodd"
      d="M19.483 10.116c.486.016.51-.323.516-.663.013-.534-.105-.7-.516-.71a18.764 18.764 0 00-1.524.002c-.311.016-.48-.107-.648-.354-1.113-1.646-2.236-3.284-3.36-4.923l-.652-.953c-.374-.546-.574-.544-.952.01a954.957 954.957 0 00-3.835 5.673L7.69 9.423c-.485.721-.548.722-1.033.025l-.154-.218c-.13-.183-.26-.367-.38-.557-.227-.362-.484-.377-.82-.14-.553.389-.626.54-.353.95.504.757 1.013 1.511 1.533 2.258.623.894.756.882 1.365-.022 1.523-2.266 3.052-4.527 4.583-6.786.34-.501.45-.5.792-.005.252.367.51.73.766 1.094.632.892 1.263 1.784 1.822 2.72l.049.078.016.027c.356.584.588.909.976 1.083.338.151.795.188 1.556.184l.338-.002c.247-.002.493-.005.738.004zm-5.613 1.213c.233.359.486.37.82.131.55-.392.621-.54.345-.953-.505-.757-1.014-1.51-1.533-2.257-.617-.886-.755-.874-1.363.03-1.523 2.263-3.05 4.523-4.58 6.78-.342.505-.45.506-.79.014-.249-.361-.503-.72-.756-1.08-.642-.908-1.284-1.816-1.852-2.768-.851-1.342-1.31-1.343-2.51-1.343a5468.162 5468.162 0 00-.387-.001c-.248-.002-.496-.004-.744.003-.435.011-.526.139-.52.69.005.363.026.702.537.685.233-.008.466-.005.7-.002.259.003.519.006.778-.005.3-.013.482.079.656.337 1.117 1.658 2.246 3.307 3.375 4.956l.63.92c.392.572.58.57.976-.013a2351.1 2351.1 0 003.427-5.066c.467-.696.935-1.39 1.41-2.081.259-.378.393-.373.667-.005.25.335.488.678.714 1.028z"
      clipRule="evenodd"
    ></path>
  </svg>
);
export default SvgComponent;
