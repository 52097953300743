import { Box, RadioGroup, Stack, Radio, Text, Flex, Input } from '@chakra-ui/react';
import FormField from 'library/molecules/FormField/FormField';
import { THEME_COLORS } from 'library/theme/colors';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { SlackCreateAction } from 'views/main/organization/workflow/types';

const CreateSlackForm = () => {
  const { setIsDirty } = useWorkflowFormContext();

  const { register, formState, watch, setValue } = useFormContext<SlackCreateAction>();
  const [isChecked, setIsChecked] = useState(
    watch('data.auto_name') === true ? 'auto' : 'slackname',
  );

  const handleOptionChange = (value: string) => {
    setIsChecked(value);

    if (value === 'auto') {
      setValue('data.auto_name', true, { shouldDirty: true });
      setValue('data.channel_name', '');
    } else {
      setValue('data.auto_name', false, { shouldDirty: true });
    }
  };
  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty]);
  return (
    <Box px={6} pt={4} pb={20}>
      <RadioGroup value={isChecked} onChange={handleOptionChange}>
        <Stack gap={3}>
          <Radio value="auto">
            <Text fontSize={'14px'} fontWeight={800} color={THEME_COLORS.secondary[700]}>
              Automatically Choose Slack Channel Name
            </Text>
          </Radio>
          <Flex direction={'column'}>
            <Radio value="slackname">
              <Text fontSize={'14px'} fontWeight={800} color={THEME_COLORS.secondary[700]}>
                Create Slack Channel name in this specific format
              </Text>
            </Radio>

            <Box mt={3} ml={4}>
              <FormField
                error={
                  formState?.errors?.channel_name?.message ||
                  formState?.errors?.data?.channel_name?.message
                }
              >
                <Input
                  isDisabled={watch('data.auto_name')}
                  placeholder="eg : incident-{{event.incident_id}}"
                  {...register('data.channel_name')}
                />
              </FormField>
            </Box>
          </Flex>
        </Stack>
      </RadioGroup>
    </Box>
  );
};
export default CreateSlackForm;
