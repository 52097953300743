import * as React from 'react';
import { SVGProps } from 'react';

const SvgIcingaLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Icinga_Logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.Icinga_Logo-20x20_svg__st0{fill:#06062c}'}</style>
    <path
      className="Icinga_Logo-20x20_svg__st0"
      d="M10.5 15.5zM18.2 6.1c-.8.1-1.4.7-1.3 1.5v.2l-5.7 1.4c-.1-.5-.3-1-.7-1.5-.3-.3-.6-.6-1-.8l1.7-3.5c.5.1 1.1 0 1.6-.3.8-.6.9-1.7.3-2.5-.6-.8-1.7-.9-2.5-.3-.8.7-.9 1.8-.3 2.6.1.2.3.3.5.4L9.1 6.8c-1.1-.5-2.4-.3-3.4.5l-.2.2L3.7 6c.1-.3.1-.6 0-.8-.3-.5-.9-.7-1.4-.5-.5.3-.8.9-.5 1.4.2.5.9.7 1.4.4.1 0 .2-.1.2-.2l1.8 1.5c-.9 1.2-1 3 .1 4.2.2.3.5.5.8.7l-2.1 3c-.4-.3-.9-.4-1.4-.4-1.3.1-2.2 1.2-2.1 2.5.1 1.3 1.2 2.2 2.5 2.1 1.3-.1 2.2-1.2 2.1-2.5 0-.6-.3-1.1-.7-1.4l2-3c1 .5 2.1.5 3 0l1.2 2c-.1.1-.2.3-.2.4 0 .4.2.7.6.8.4 0 .7-.2.8-.6 0-.4-.2-.7-.6-.8h-.1l-1.3-2.1c.1 0 .1-.1.2-.1.9-.7 1.3-1.8 1.2-2.9L17 8.3c.3.4.7.7 1.3.6.8-.1 1.3-.7 1.3-1.5s-.7-1.3-1.4-1.3z"
    />
  </svg>
);

export default SvgIcingaLogo20X20;
