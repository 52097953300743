import Axios from 'axios';
import { API } from 'core';
import { useMutation } from 'react-query';
import { ResponderAPIProps } from '../types';
import { DEFAULT_REACT_QUERY_CONFIG } from 'library/query-config';

import { onAPIError, reactQueryConfigSuccess } from './helper';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_MR_RESEND_NOTIFICATION } from 'core/const/tracker';

const sendReminder = ({ incidentId, responderId }: ResponderAPIProps) => {
  const url = `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${incidentId}/additional-responders/${responderId}/send-reminder`;
  return Axios.post(url);
};

export const useSendReminderMutation = (incidentId: string) => {
  return useMutation(sendReminder, {
    ...DEFAULT_REACT_QUERY_CONFIG,
    onSuccess: () => {
      reactQueryConfigSuccess(`Notification sent successfully`);
      AppTracker.track(T_WA_UP_MR_RESEND_NOTIFICATION, {
        'Incident ID': incidentId,
      });
    },
    onError: err => {
      onAPIError(err);
    },
  });
};
