import { Dispatch, SetStateAction } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { useGetServiceNowConfig } from './hooks/getServiceNowConfig';
import { THEME_COLORS } from 'library/theme/colors';
import { CopyIcon } from 'icons';
import { SpinLoader } from 'uie/components';
import { getVerificationStatus, inputIconStyles, SaveConfiguration } from './helpers';
import { useServiceNowConfiguration } from './hooks';
import { TA_WA_UP_SNW_EXTENSION_TESTED } from 'core/const/tracker';
import { AppTracker } from 'shared/analytics/tracker';

export interface ServiceNowConfigurationProps {
  serviceNowStatus: string;
  onSaveConfiguration: (config: SaveConfiguration) => void;
  setIsButtonEnabled: (isDirty: boolean) => void;
  setHasChanged: Dispatch<SetStateAction<boolean>>;
  setWasEnabled: Dispatch<SetStateAction<boolean | null>>;
}

function ServiceNowConfiguration(props: ServiceNowConfigurationProps) {
  const {
    isServiceNowEnabled,
    isVerifyButtonEnabled,
    verifyConnectionStatus,
    passwordShown,
    setPasswordShown,
    integrationKey,
    config,
    setConfig,
    verifyConfigHandler,
    onToggle,
    getConfigCallback,
    toast,
  } = useServiceNowConfiguration(props);

  const serviceNowConfigurationRes = useGetServiceNowConfig(
    true,
    props.serviceNowStatus,
    getConfigCallback,
  );

  const verifyConfig = async () => {
    const isVerified = await verifyConfigHandler(() => props.setIsButtonEnabled(true));
    AppTracker.track(TA_WA_UP_SNW_EXTENSION_TESTED, {
      'Was Servicenow Test Successful': isVerified ? 'Yes' : 'No',
    });
  };

  const handleChange =
    (prop: keyof typeof config) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setConfig({ ...config, [prop]: event.target.value });
    };

  return (
    <>
      <Flex
        alignItems={'center'}
        gap={2}
        p={18}
        borderBottom={1}
        borderStyle="solid"
        borderBottomColor={THEME_COLORS.secondary[200]}
      >
        <Switch isChecked={isServiceNowEnabled} onChange={onToggle} size="sm" />
        <Text ml="2" fontWeight={600}>
          Enable ServiceNow Integration
        </Text>
      </Flex>

      <Box
        p={18}
        pt={3}
        borderBottom={1}
        borderStyle="solid"
        borderBottomColor={THEME_COLORS.secondary[200]}
      >
        <Text fontSize="sm" fontWeight={800} color={THEME_COLORS.secondary[900]}>
          Enter ServiceNow Details
        </Text>
        <Box mt="9px" fontSize="sm">
          <Flex flexDirection={'column'} gap={2} py={3}>
            <Text fontWeight={600} color={THEME_COLORS.secondary['700']}>
              Servicenow Domain Name*
            </Text>
            <Box w="full">
              <Input
                placeholder="Ex : mycompany.servicenow.com"
                value={config.servicenow_domain}
                onChange={handleChange('servicenow_domain')}
                disabled={!isServiceNowEnabled}
                bgColor="white"
                px="9px"
                py="6px"
                fontSize="sm"
              />
            </Box>
          </Flex>
          <Flex flexDirection={'column'} py={3} gap={2}>
            <Text fontWeight={600} color={THEME_COLORS.secondary['700']}>
              Sync Incidents to Servicenow
            </Text>
            <RadioGroup
              mt={5}
              onChange={e => setConfig({ ...config, sync_mode: e })}
              value={config.sync_mode}
              w="full"
              marginTop={0}
              isDisabled={!isServiceNowEnabled}
            >
              <Stack direction={'column'}>
                <Flex gap={2} alignItems={'flex-start'}>
                  <Radio
                    size="md"
                    value="auto"
                    alignItems={'flex-start'}
                    mt={1}
                    disabled={!isServiceNowEnabled}
                  />
                  <Flex direction={'column'}>
                    <Text color={THEME_COLORS.secondary['1000']}>Automatically</Text>
                    <Text color={THEME_COLORS.secondary['700']}>
                      Automatically sync ServiceNow incidents with Squadcast incidents
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap={2} alignItems={'flex-start'}>
                  <Radio
                    size="md"
                    value="manual"
                    alignItems={'flex-start'}
                    mt={1}
                    disabled={!isServiceNowEnabled}
                  />
                  <Flex direction={'column'}>
                    <Text color={THEME_COLORS.secondary['1000']}>Manual</Text>
                    <Text color={THEME_COLORS.secondary['700']}>
                      Manually sync ServiceNow incidents with Squadcast incidents under Incident
                      actions
                    </Text>
                  </Flex>
                </Flex>
              </Stack>
            </RadioGroup>
          </Flex>

          <Flex flexDirection={'column'} py={3} gap={2}>
            <Text fontWeight={600} color={THEME_COLORS.secondary['700']}>
              Servicenow Username*
            </Text>
            <Box w="full">
              <Input
                placeholder="Enter username"
                autoComplete="off"
                name="servicenow_username"
                value={config.servicenow_username}
                onChange={handleChange('servicenow_username')}
                disabled={!isServiceNowEnabled}
                bgColor="white"
                px="9px"
                py="6px"
                fontSize="sm"
              />
            </Box>
          </Flex>

          <Flex flexDirection={'column'} py={3} gap={2}>
            <Text fontWeight={600} color={THEME_COLORS.secondary['700']}>
              Servicenow Password*
            </Text>
            <Box w="full" position="relative">
              <Input
                placeholder="Enter password"
                type={passwordShown ? 'text' : 'password'}
                name="servicenow_password"
                autoComplete="off"
                value={config.servicenow_password}
                onChange={handleChange('servicenow_password')}
                disabled={!isServiceNowEnabled}
                bgColor="white"
                px="9px"
                py="6px"
                fontSize="sm"
                backgroundColor={'transparent'}
              />
              <div style={inputIconStyles} onClick={() => setPasswordShown(!passwordShown)}>
                <img
                  src={passwordShown ? '/assets/eye-open.svg' : '/assets/eye-closed.svg'}
                  style={{
                    width: '15px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              </div>
            </Box>
          </Flex>

          <Box display="flex" gap={3} mt={18}>
            <Button
              bg={'#1B7FF1'}
              color={'white'}
              variant="solid"
              onClick={verifyConfig}
              disabled={!isVerifyButtonEnabled}
              p="9px"
              fontSize="sm"
              borderRadius="3px"
              width={141}
              _hover={{ bg: '#1B7FF1' }}
            >
              {verifyConnectionStatus === 'loading' ? <Spinner size="sm" /> : 'Test Configuration'}
            </Button>
            {getVerificationStatus(verifyConnectionStatus)}
          </Box>
        </Box>
      </Box>

      <Box
        my={3}
        mt={15}
        mx={18}
        p={18}
        fontSize="sm"
        bgColor={THEME_COLORS.primary[100]}
        borderBottom="1px"
        borderStyle="solid"
        borderBottomColor={THEME_COLORS.primary[400]}
      >
        <Text fontWeight={800} color={'#09305A'} mb={3}>
          To complete the integration, activate the key from ServiceNow
        </Text>
        <Text fontWeight={800} color={THEME_COLORS.secondary['700']} mb="6px">
          ServiceNow Integration Key
        </Text>
        <Box
          w="full"
          position="relative"
          overflow="hidden"
          bgColor={THEME_COLORS.secondary['100']}
          borderColor={THEME_COLORS.secondary['150']}
          textColor={THEME_COLORS.secondary[1000]}
          borderRadius="3px"
          borderWidth="0.75px"
          px="9px"
          py="6px"
          height="37px"
        >
          {integrationKey.length ? integrationKey : <SpinLoader />}
          <div
            style={inputIconStyles}
            onClick={() => {
              navigator.clipboard.writeText(integrationKey);
              toast({
                status: 'success',
                title: 'ServiceNow Integration Key copied',
                isClosable: true,
              });
            }}
          >
            <CopyIcon
              style={{
                width: '15px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </div>
        </Box>
        <Text fontWeight={800} fontSize="xs" color={THEME_COLORS.secondary['700']} mt={18}>
          Note : If saved without activating the key, the integration will show as incomplete
        </Text>
      </Box>
    </>
  );
}

export default ServiceNowConfiguration;
