import {
  Button,
  Text,
  Textarea,
  Center,
  HStack,
  Accordion,
  AccordionButton,
  AccordionPanel,
  AccordionItem,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { IDedupKey } from 'views/main/organization/service-catalog/Interfaces/automation-rule';

interface Props {
  dedupeKey: IDedupKey;
  isShowOutputButtonDisabled: boolean;
  getDedupeKeyOutput: () => void;
}

const Output = ({ dedupeKey, isShowOutputButtonDisabled, getDedupeKeyOutput }: Props) => {
  return (
    <Accordion allowToggle={true}>
      <AccordionItem borderTop="0px">
        {({ isExpanded = false }) => (
          <>
            <AccordionButton
              _expanded={{ background: 'white !important' }}
              _hover={{ background: 'white !important' }}
              padding="12px 12px"
            >
              <HStack p={0}>
                <Text variant="secondary">Output</Text>
                {isExpanded ? (
                  <ChevronDownIcon width={6} height={6} color="blue.900" />
                ) : (
                  <ChevronRightIcon width={6} height={6} color="blue.900" />
                )}
              </HStack>
            </AccordionButton>
            <AccordionPanel p={0} backgroundColor="white">
              {dedupeKey.template_output ? (
                <Textarea
                  height="20vh"
                  value={dedupeKey.template_output}
                  isDisabled
                  width="100%"
                  _disabled={{ cursor: 'default' }}
                />
              ) : (
                <Center height="20vh" width="100%" backgroundColor="#DADADA">
                  <Button
                    size="xs"
                    variant="outline"
                    backgroundColor="white"
                    isDisabled={isShowOutputButtonDisabled}
                    onClick={getDedupeKeyOutput}
                  >
                    Show Output
                  </Button>
                </Center>
              )}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default Output;
