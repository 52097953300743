import ReactSelect, { components, StylesConfig } from 'react-select';

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

export const customStyles: StylesConfig = {
  container: provided => ({
    ...provided,
    pointerEvents: 'auto',
  }),
  menu: provided => ({
    ...provided,
    width: 'max-content',
  }),
  input: provided => ({
    ...provided,
  }),
  control: provided => ({
    ...provided,
    border: 0,
    // This line disable the blue border
    boxShadow: 'none',
    flex: 1,
  }),
  dropdownIndicator: provided => {
    return { ...provided };
  },
  valueContainer: provided => {
    return {
      ...provided,
      height: '0px',
      position: 'absolute',
    };
  },
  multiValue: provided => {
    return { ...provided, display: 'none' };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    borderWidth: '0px',
    outline: 'none',
    backgroundColor: '#F3F4F6',
  }),
};

export const Multiselect = (args: any) => {
  return (
    <ReactSelect
      placeholder=""
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      controlShouldRenderValue={false}
      isClearable={false}
      isSearchable={false}
      hideContainerBorder
      hideSelectedValues
      components={{
        Option,
      }}
      styles={customStyles}
      {...args}
    />
  );
};
