import * as React from 'react';
import { SVGProps } from 'react';

const SvgAwsOpenSearchLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="AWS_OpenSearch_Logo_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.AWS_OpenSearch_Logo_svg__st0{fill-rule:evenodd;clip-rule:evenodd;fill:url(#AWS_OpenSearch_Logo_svg__Rectangle_00000101065658738911706230000004307830645971348402_)}'
      }
    </style>
    <g id="AWS_OpenSearch_Logo_svg__Icon-Architecture_x2F_48_x2F_Arch_x5F_Amazon-Elasticsearch-Service_x5F_48">
      <g id="AWS_OpenSearch_Logo_svg__Icon-Architecture-BG_x2F_48_x2F_Analytics">
        <linearGradient
          id="AWS_OpenSearch_Logo_svg__Rectangle_00000179609017485608352670000000758263831616077479_"
          gradientUnits="userSpaceOnUse"
          x1={-282.516}
          y1={425.281}
          x2={-282.203}
          y2={425.594}
          gradientTransform="matrix(64 0 0 -64 18081 27238)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#4d27a8',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#a166ff',
            }}
          />
        </linearGradient>
        <path
          id="AWS_OpenSearch_Logo_svg__Rectangle"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'url(#AWS_OpenSearch_Logo_svg__Rectangle_00000179609017485608352670000000758263831616077479_)',
          }}
          d="M0 0h20v20H0z"
        />
      </g>
      <path
        id="AWS_OpenSearch_Logo_svg__Amazon-Elasticsearch-Service_Icon_48_Squid"
        d="M10.9 12.8c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6 1.6.7 1.6 1.6-.7 1.6-1.6 1.6zm0-3.7c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2 2.2-1 2.2-2.2-1-2.2-2.2-2.2zm4.6 6.2c-.1.1-.4.2-.5 0l-1.7-1.6.5-.5 1.8 1.6c.1.1.1.3-.1.5zm-7.4-4.1c0-1.6 1.3-2.8 2.8-2.8s2.8 1.3 2.8 2.8-1.3 2.8-2.8 2.8-2.8-1.2-2.8-2.8zm6 1.5c.2-.4.3-.9.3-1.4 0-1.9-1.5-3.4-3.4-3.4s-3.4 1.5-3.4 3.4 1.5 3.4 3.4 3.4c.7 0 1.3-.2 1.9-.5l1.8 1.6c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.3-1-.1-1.4l-1.9-1.7zm2.5-7.4v8.1H16V5.6h-1.6v2.5h-.6V5.3c0-.2.1-.3.3-.3h2.2c.1 0 .3.1.3.3zm-4.1 10.3h.6v.9c0 .2-.1.3-.3.3h-2.2c-.2 0-.3-.1-.3-.3v-1.2h.6v.9h1.6v-.6zm-1.6-8.7h-.6V3.8c0-.2.1-.3.3-.3h2.2c.2 0 .3.1.3.3v3.4h-.6V4.1h-1.6v2.8zM9.1 15h.6v1.6c0 .2-.1.3-.3.3H7.2c-.2 0-.3-.1-.3-.3v-3.1h.6v2.8h1.6V15zM7.5 8.8h-.6V5.9c0-.2.1-.3.3-.3h2.2c.2 0 .3.1.3.3v1.2h-.6v-.9H7.5v2.6zm-3.4 7.4h1.6V8.1H4.1v8.1zm1.8-8.7H3.8c-.2 0-.3.1-.3.3v8.8c0 .2.1.3.3.3H6c.2 0 .3-.1.3-.3V7.8c-.1-.2-.2-.3-.4-.3z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);

export default SvgAwsOpenSearchLogo;
