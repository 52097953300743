export const REQUEST_ACCOUNT_PROFILE_UPDATE = 'REQUEST_ACCOUNT_PROFILE_UPDATE';
export const REQUEST_ACCOUNT_PROFILE_UPDATE_SUCCESS = 'REQUEST_ACCOUNT_PROFILE_UPDATE_SUCCESS';
export const REQUEST_ACCOUNT_PROFILE_UPDATE_FAIL = 'REQUEST_ACCOUNT_PROFILE_UPDATE_FAIL';

export const REQUEST_ACCOUNT_PASSWORD_CHANGE = 'REQUEST_ACCOUNT_PASSWORD_CHANGE';
export const REQUEST_ACCOUNT_PASSWORD_CHANGE_SUCCESS = 'REQUEST_ACCOUNT_PASSWORD_CHANGE_SUCCESS';
export const REQUEST_ACCOUNT_PASSWORD_CHANGE_FAIL = 'REQUEST_ACCOUNT_PASSWORD_CHANGE_FAIL';

export const REQUEST_ACCOUNT_NOTIFICATION_UPDATE = 'REQUEST_ACCOUNT_NOTIFICATION_UPDATE';
export const REQUEST_ACCOUNT_NOTIFICATION_UPDATE_SUCCESS =
  'REQUEST_ACCOUNT_NOTIFICATION_UPDATE_SUCCESS';
export const REQUEST_ACCOUNT_NOTIFICATION_UPDATE_FAIL = 'REQUEST_ACCOUNT_NOTIFICATION_UPDATE_FAIL';
export const REQUEST_ACCOUNT_TIMEZONE_UPDATE = 'REQUEST_ACCOUNT_TIMEZONE_UPDATE';
