import Axios from 'axios';

import { API } from '../../api';
import { INotificationLog } from '../../interfaces/IIncidents';

class IncidentNotificationLogsService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/incidents`;

  public getLogs = (id: string) =>
    Axios.get<{ data: INotificationLog[] }>(`${this._api}/${id}/notification-logs`);

  public exportLogs = (incidentId: string, queryParam?: string) => {
    const queryStr = queryParam ? `?${queryParam}` : '';
    return Axios.get<{ data: any; result: boolean }>(
      `${this._api}/${incidentId}/notification-logs/export/csv${queryStr}`,
    );
  };
}

export default IncidentNotificationLogsService;
