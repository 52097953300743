import { Grid } from 'uie/components';
import React from 'react';

/*

Page-level error component.
This is bounded by the top bar and the left nav bar, and optionally the sidebar.

TODO:
Error title
Error message
Error code
Error level
Icon/illustration
CTA
Contact support button
Centre it horizontally and vertically.
Handle multiple error types:
  Client error
  Network error
  User error
  Server error

 */
const ErrorPage: React.FC = ({ children }) => {
  return (
    <Grid type="column" alignItems="center">
      {children}
    </Grid>
  );
};

export default ErrorPage;
