import { INIT_APP, INIT_APP_FAIL, INIT_APP_SUCCESS } from '../const/init';

const requestInitApp = (sessionId: string) => ({
  type: INIT_APP,
  payload: {
    sessionId,
  },
});

const onInitSuccess = (payload: any) => ({
  payload,
  type: INIT_APP_SUCCESS,
});

const onInitFail = (error: any) => ({
  type: INIT_APP_FAIL,
  payload: {
    error,
  },
});

export { requestInitApp, onInitSuccess, onInitFail };
