import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, SpinLoader } from '@squadcast/alchemy-ui/carbon';
import cx from 'classnames';
import './index.css';
import { renderPageConfig, renderServicesConfig } from './renders.index';
import { StatusPageService } from '../../../../../core/services';
import { IStatusPage } from '../../../../../core/interfaces/IStatusPage';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { deepCopy, findGracefully, pick } from 'core/helpers';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_GS_STATUSPAGE_CREATED } from '../../../../../core/const/tracker';
import equal from 'fast-deep-equal/es6/react';
import { exception } from '../../../../../core/exception';
import { IAppExceptions } from '../../../../../core/interfaces/IAppExceptions';

interface IProps extends Pick<IAppState, 'organization'> {
  hide: () => void;
  pageId: string;
  checkAndSetDirty: () => void;
}

interface IState {
  componentState: 'new' | 'edit';
  appState: 'loading' | 'idle';
  currentConfigTab: 'page' | 'service';
  name: string;
  pageUrl: string;
  services: {
    id: string;
    name: string;
    alias: string;
  }[];
  showDropDown: boolean;
  searchString: string;
  selectAllServices: boolean;
  errors: {
    name: string;
    pageUrl: string;
    SSLCert: string;
    services: string;
    network: string;
  };
  statusPage: IStatusPage | null;
  loading: boolean;
  copied: boolean;
}

export class NewStatusPageModal extends Component<IProps, IState> {
  public SPService: StatusPageService = new StatusPageService();

  public _serviceFind = findGracefully(this.props.organization.services.s, 'id', 'Service Deleted');
  private _initialState?: IState;

  constructor(props: IProps) {
    super(props);

    const isInEdit = this.props.pageId.length > 0;
    this.state = {
      componentState: isInEdit ? 'edit' : 'new',
      appState: isInEdit ? 'loading' : 'idle',
      currentConfigTab: 'page',
      name: '',
      pageUrl: '',
      services: [],
      showDropDown: false,
      searchString: '',
      selectAllServices: false,
      errors: {
        name: '',
        pageUrl: '',
        SSLCert: '',
        services: '',
        network: '',
      },
      statusPage: null,
      loading: false,
      copied: false,
    };

    if (this.props.pageId === '') this._initialState = deepCopy(this.state);
  }

  public componentDidMount() {
    if (this.props.pageId.length > 0) {
      this.getStatusPageDetail();
    }
  }

  public getStatusPageDetail = async () => {
    try {
      const {
        data: { data: page },
      } = await this.SPService.getStatusPage(this.props.pageId);
      const { name, url, services } = page;
      this.setState(
        ({ componentState }) => ({
          name: name as any,
          pageUrl: url as any,
          services: services.map(s => ({
            id: s.serviceId,
            name: this._serviceFind(s.serviceId, 'name'),
            alias: s.alias,
          })) as any[],
          appState: 'idle',
          statusPage: componentState === 'edit' ? page : null,
        }),
        () => (this._initialState = deepCopy(this.state)),
      );
    } catch (error: any) {
      exception.handle('E_GET_SINGLE_STATUS_PAGE', error);
    }
  };

  public setTab = (tab: 'page' | 'service') => {
    this.setState({ currentConfigTab: tab });
  };

  public onInputChange = (event: any, value: string) => {
    const newState: any = {};
    newState[event.target.name as string] = value;
    this.setState(newState);
  };

  public validate = () => {
    let isValid = true;
    this.setState({
      errors: {
        SSLCert: '',
        name: '',
        pageUrl: '',
        services: '',
        network: '',
      },
    });

    if (this.state.name === '') {
      this.setState(({ errors }) => ({
        errors: { ...errors, ...{ name: 'Name is required' } },
      }));
      isValid = false;
    }

    if (this.state.pageUrl === '') {
      this.setState(({ errors }) => ({
        errors: { ...errors, ...{ pageUrl: 'PageUrl is required' } },
      }));
      isValid = false;
    } else if (urlValidator(this.state.pageUrl) !== '') {
      this.setState(({ errors }) => ({
        errors: { ...errors, ...{ pageUrl: urlValidator(this.state.pageUrl) } },
      }));
      isValid = false;
    }

    return isValid;
  };

  public onNext = () => {
    this.validate() && this.setState({ currentConfigTab: 'service' });
  };

  public addToServiceArray = (id: string, name: string) => {
    const { services } = this.state;
    if (services.findIndex(service => service.id === id) > -1) {
      return;
    }

    services.push({
      id,
      name,
      alias: '',
    });

    this.setState({
      services,
      selectAllServices: services.length === this.props.organization.services.s.length,
    });
  };

  public removeService = (id: string) => {
    this.setState(({ services }) => ({
      services: services.filter(s => s.id !== id),
    }));
  };

  public onServiceAliasChange = (serviceId: string, value: string) => {
    const { services } = this.state;
    const index = services.findIndex(service => service.id === serviceId);

    if (index > -1) {
      services[index].alias = value;
    }

    this.setState({ services });
  };

  public onSelectAllServices = () => {
    if (this.state.selectAllServices) {
      this.setState({
        selectAllServices: false,
        services: [],
      });
    } else {
      this.setState({
        selectAllServices: true,
        services: this.props.organization.services.s.map(service => ({
          id: service.id,
          name: service.name,
          alias: '',
        })),
      });
    }
  };

  public createNewPage = () => {
    AppTracker.track(T_WA_GS_STATUSPAGE_CREATED, {
      'Statuspage Name': this.state.name,
      'Statuspage Hostname': this.state.pageUrl,
    });
    if (!this.validate()) {
      this.setState({ currentConfigTab: 'page' });
      return;
    }

    if (this.state.services.length === 0) {
      this.setState(({ errors }) => ({
        errors: {
          ...errors,
          ...{ services: 'At least one service is required' },
        },
      }));
      return;
    }

    if (this.state.services.some(service => service.alias === '')) {
      this.setState(({ errors }) => ({
        errors: { ...errors, ...{ services: 'Aliases are required' } },
      }));
      return;
    }

    this.state.componentState === 'new' ? this.postCreatePage() : this.updatePage();
  };

  public postCreatePage = async () => {
    this.setState({ loading: true });
    const { name, pageUrl, services } = this.state;

    try {
      await this.SPService.createStatusPage({
        name: name,
        url: pageUrl,
        services: services.map(s => ({ serviceId: s.id, alias: s.alias })),
      });

      this.props.hide();
    } catch (err: any) {
      this.handleError('E_CREATE_STATUS_PAGE', err);
    }
  };

  public updatePage = async () => {
    this.setState({ loading: true });
    const { name, pageUrl, services } = this.state;

    try {
      const statusPage = this.state.statusPage as IStatusPage;

      statusPage.name = name;
      statusPage.url = pageUrl;
      statusPage.services = services.map(s => ({
        serviceId: s.id,
        alias: s.alias,
      })) as any;

      await this.SPService.updateStatusPage(statusPage);
      this.props.hide();
    } catch (err: any) {
      this.handleError('E_UPDATE_STATUS_PAGE', err);
    }
  };

  handleError = (errorType: IAppExceptions, err: any) => {
    const { response } = err;

    // server error
    if (response.status >= 500) {
      exception.handle(errorType, err);
      return;
    }

    const error_message = response?.data?.meta?.error_message ?? 'Network Error';
    const error_details = response?.data?.meta?.error_details?.description ?? 'Network Error';

    // payment required
    if (response.status === 402) {
      this.setState(({ errors }) => ({
        errors: {
          ...errors,
          network: error_details,
        },
        loading: false,
      }));
    }

    // conflict
    if (response.status === 409) {
      this.setState(({ errors }) => {
        if (error_message.includes('same name')) {
          errors.name = error_message;
        }

        if (error_message.includes('same url')) {
          errors.pageUrl = error_message;
        }

        return { errors, loading: false, currentConfigTab: 'page' };
      });
    }
  };

  componentDidUpdate() {
    if (
      this._initialState &&
      !equal(
        pick(this.state, 'name', 'pageUrl', 'selectAllServices', 'services'),
        pick(this._initialState, 'name', 'pageUrl', 'selectAllServices', 'services'),
      )
    )
      this.props.checkAndSetDirty();
  }

  public render() {
    if (this.state.appState === 'loading') {
      return (
        <div className="modal-background faster animated fadeIn" onClick={this.props.hide}>
          <div
            className="modal-container faster animated slideInDown"
            onClick={event => {
              event.stopPropagation();
            }}
            style={{ width: 800 }}
          >
            <Row justifyContent="center">
              <SpinLoader size="large" />
            </Row>
          </div>
        </div>
      );
    }

    return (
      <div
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <div className="clearfix">
          <div className="float-left">
            <h1 className="modal-container-heading">Add Status Page</h1>
          </div>
        </div>
        <div className="mt-10">
          <Row justifyContent="flex-start" alignItems="center">
            <button
              className={cx('link', {
                active: this.state.currentConfigTab === 'page',
              })}
              style={{ marginRight: 5 }}
              onClick={() => {
                this.setTab('page');
              }}
              tabIndex={0}
            >
              1. Page Configuration
            </button>
            <span
              style={{ borderTop: '1px solid var(--shades-grey)', width: 60 }}
              className="ml-20 mr-20"
            />
            <button
              className={cx('link', {
                active: this.state.currentConfigTab === 'service',
              })}
              style={{ marginLeft: 5 }}
              onClick={() => {
                this.setTab('service');
              }}
              tabIndex={0}
            >
              2. Service Configuration
            </button>
          </Row>
        </div>
        <div className="mt-20">
          <hr
            style={{
              borderColor: 'var(--shades-whiteSmoke)',
              borderTopWidth: '0.5px',
              borderBottom: 'none',
            }}
          />
        </div>
        <div className="mt-20">
          {this.state.currentConfigTab === 'page' && renderPageConfig.call(this)}
          {this.state.currentConfigTab === 'service' && renderServicesConfig.call(this)}
        </div>
      </div>
    );
  }
}

export function urlValidator(url: string) {
  const validUrl = new RegExp(/^[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/);
  if (!validUrl.test(url)) {
    return 'Please provide valid Url (status.example.com)';
  }

  return '';
}

export default connect((state: IAppState) => ({
  organization: state.organization,
}))(NewStatusPageModal);
