import Tippy from '@tippy.js/react';
import { InfoIcon } from 'icons';
import { Para } from 'uie/components';

const InfoToolTipOnUsageCalculation = (props: any) => {
  return (
    <>
      <Tippy
        content={
          <div
            style={{
              width: '372px',
              height: '104px',
              left: '609px',
              top: '615px',
              background: '#FFFFFF',
              boxShadow: '0px 0px 8px 2px rgba(0, 0, 0, 0.12)',
            }}
          >
            <Para
              style={{
                padding: '16px',
                fontFamily: 'Mulish',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
                color: '#969AA0',
              }}
            >
              The SMS and Phone calls consumed during the trial will be considered for the
              calculation should you continue using the free plan
            </Para>
          </div>
        }
        interactive={true}
        animation={'perspective'}
        placement="bottom-start"
      >
        <span>
          <InfoIcon style={{ width: '12px', height: '12px', position: 'relative', top: '2px' }} />
        </span>
      </Tippy>
    </>
  );
};

export default InfoToolTipOnUsageCalculation;
