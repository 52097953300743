import { Theme } from 'uie/components';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  Text,
  Textarea,
  Divider,
} from '@chakra-ui/react';
import React from 'react';

const { theme } = Theme;
const inactiveColor = theme.danger.default;

interface IOutput {
  isSaving: boolean;
  CustomOutputMessage: string;
  decodedMessage: string;
  decodedDesc: string;
  getCustomIncidentOutput: () => void;
}
const Output: React.FC<IOutput> = ({
  isSaving,
  CustomOutputMessage,
  decodedMessage,
  decodedDesc,
  getCustomIncidentOutput,
}) => {
  return (
    <>
      <Box mt={4}>
        <Accordion allowMultiple>
          <AccordionItem border="none">
            <Flex w="100%">
              <AccordionButton
                color="default"
                p={2}
                pt={0}
                w="100%"
                _focus={{ boxShadow: 'none' }}
                justifyContent="flex-start"
              >
                <Flex>
                  Output
                  <AccordionIcon ml={1.5} />
                </Flex>
              </AccordionButton>
            </Flex>
            <AccordionPanel>
              {decodedDesc !== '' || decodedMessage !== '' ? (
                <Box display="block" height="20vh" overflowY={'auto'}>
                  <Box mb={1} overflowY="hidden">
                    <Text fontWeight={800} style={{ fontSize: '12px', color: '#000' }}>
                      Incident Message
                    </Text>
                    <Textarea
                      color="#000"
                      border={'none'}
                      height="10vh"
                      value={decodedMessage}
                      isDisabled
                      width="100%"
                      _disabled={{ cursor: 'default' }}
                    />
                  </Box>
                  <Box>
                    <Text fontWeight={800} style={{ fontSize: '12px', color: '#000' }}>
                      Incident Description
                    </Text>
                    <Textarea
                      color="#000"
                      border={'none'}
                      height="10vh"
                      value={decodedDesc}
                      isDisabled
                      width="100%"
                      _disabled={{ cursor: 'default' }}
                    />
                  </Box>
                </Box>
              ) : (
                <Center height="20vh" width="100%" backgroundColor="#DADADA">
                  <Button
                    size="xs"
                    variant="outline"
                    backgroundColor="white"
                    isDisabled={isSaving}
                    onClick={getCustomIncidentOutput}
                  >
                    Show Output
                  </Button>
                </Center>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Divider colorScheme={'#E1E6EB'} />
      </Box>
      {CustomOutputMessage !== '' && (
        <Box mb={2}>
          <Text color={inactiveColor}>{CustomOutputMessage}</Text>
        </Box>
      )}
    </>
  );
};

export default Output;
