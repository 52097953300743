import React from 'react';

import { Box, Grid, Progress, Text, VStack } from '@chakra-ui/react';

type Props = {
  steps: {
    name: string;
  }[];
  activeStep: number;
  /** Step starts from index 0 */
  disabledSteps: number[];
  /** Step starts from index 0 */
  onStepClick?: (step: number) => void;
};

function Stepper({ steps, activeStep, disabledSteps = [], onStepClick }: Props) {
  const getProgressColor = (step: number) => {
    if (activeStep === step) {
      return 'gray.500';
    } else if (activeStep > step) {
      return 'green.500';
    }
    return 'gray.200';
  };

  return (
    <Grid templateColumns={`repeat(${steps.length}, 1fr)`} gap={8} p={6}>
      {steps.map((step, index) => {
        return (
          <VStack
            key={index}
            alignItems="flex-start"
            spacing={4}
            role="tab"
            tabIndex={0}
            onClick={() => !disabledSteps.includes(index) && onStepClick?.(index)}
          >
            <Progress
              value={100}
              size="xs"
              w="100%"
              h="4px"
              sx={{
                '& div': {
                  backgroundColor: getProgressColor(index),
                },
              }}
            />

            <Box cursor={!disabledSteps.includes(index) ? 'pointer' : 'default'}>
              <Text fontWeight={600} fontSize="xs" color={getProgressColor(index)}>
                Step {index + 1}
              </Text>
              <Text fontWeight={500} fontSize="sm">
                {step.name}
              </Text>
            </Box>
          </VStack>
        );
      })}
    </Grid>
  );
}

export default Stepper;
