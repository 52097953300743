import request from '../commons';
import { API } from '..';
function getIncidentDetailRunbooks(incidentId: string) {
  const req = request.get(API.config.batman)(
    `organizations/${API.config.organizationId}/incidents/${incidentId}/runbooks`,
  );
  return req;
}
function getIncidentDetailTask(incidentId: string) {
  const req = request.get(API.config.batman)(
    `organizations/${API.config.organizationId}/incidents/${incidentId}/tasks`,
  );

  return req;
}

export { getIncidentDetailRunbooks, getIncidentDetailTask };
