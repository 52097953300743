import { combineEpics } from 'redux-observable';
// user inits
import auth from './auth/getSession';
import initApp from './init';
import userInfo from './auth/getUserInfo';
import userOrganization from './organization/getUserOrganization';
import postDeviceInfoEpic from './auth/postDeviceInfo';

// org inits
import initOrg from './organization';
import organizationUsers from './organization/getUsers';
import organizationSquads from './organization/getSquad';
import organizationTeams from './organization/getTeams';
import organizationEscalations from './organization/getEscalationPolicies';
import organizationServices from './organization/getServices';
import organizationRunbook from './organization/getRunbook';
import incidentDetailRunbook from './organization/getIncidentDetailRunbook';
import incidentDetailTask from './organization/getIncidentDetailTask';
import {
  getOrganizationsCalendarEpic as organizationCalendar,
  getOrganizationsCalendarOnCallEpic as organizationCalendarOnCall,
} from './organization/getCalendar';
import organizationBillingPlan from './organization/getPlan';
import organizationCurrentUser from './organization/getCurrentUser';
import organizationUserAcl from './organization/getUserAcl';
import organizationTeamEntities from './organization/getTeamEntities';
import getOrganizationsSelectedTeamChildrensEpic from './organization/getSelectedTeamChildren';

// app init
import integrationEpic from './public/integrations';
import rolesEpic from './public/roles';

// app exit
import logoutUserEpic from './auth/logoutUser';

// user account
import updateAccountInfoEpic from './account/updateAccount';
import updateAccountPasswordEpic from './account/changePassword';
import updateAccountNotificationRulesEpic from './account/updateNotificationRules';
import userPermissionRolesEpic from './organization/userPermissionRoles';
import getStakeHoldersForTeamEpic from './organization/getStakeHoldersGroupForTeam';

const userAccount = combineEpics(
  updateAccountNotificationRulesEpic,
  updateAccountInfoEpic,
  updateAccountPasswordEpic,
);

const organization = combineEpics(
  initOrg,
  organizationUsers,
  organizationSquads,
  organizationTeams,
  getStakeHoldersForTeamEpic,
  organizationServices,
  organizationCalendar,
  organizationCalendarOnCall,
  organizationBillingPlan,
  organizationEscalations,
  organizationCurrentUser,
  organizationUserAcl,
  organizationTeamEntities,
  getOrganizationsSelectedTeamChildrensEpic,
  organizationRunbook,
  incidentDetailRunbook,
  incidentDetailTask,
);

const epics = combineEpics(
  auth,
  initApp,
  userInfo,
  userOrganization,
  organization,
  userAccount,
  rolesEpic,
  userPermissionRolesEpic,
  postDeviceInfoEpic,
  integrationEpic,
  logoutUserEpic,
);

export default epics;
