import { Radio as ChakraRadio, RadioProps, useBreakpointValue } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = Omit<RadioProps, 'variant' | 'size'> & {
  label?: string | ReactNode;
};

export function Radio({ label, ...props }: Partial<Props>) {
  const size = useBreakpointValue({ base: 'md', lg: 'lg' });

  return (
    <ChakraRadio {...props} size={size}>
      {label}
    </ChakraRadio>
  );
}
