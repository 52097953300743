import { Circle, HStack, Text, Wrap } from '@chakra-ui/react';
import { Popover } from 'library/atoms';
import { FC } from 'react';

import { AlertIcon } from '../components';
import { ALERT_INFO } from '../types';

type Props = {
  alerts: ALERT_INFO[];
};

export const AlertsList: FC<Props> = ({ alerts }) => {
  const visibleAlerts = alerts.splice(0, 5);

  return (
    <HStack pl={6} spacing={2}>
      {visibleAlerts.map(item => (
        <AlertIcon key={item.shortName} alert={item} />
      ))}
      {!!alerts.length && (
        <Popover
          trigger={
            <Circle cursor="pointer" size="19px" bg="primary.400">
              <Text variant="hint" color="secondary.1000">
                +{alerts.length}
              </Text>
            </Circle>
          }
        >
          <Wrap p={1} spacing={1} alignItems="flex-start" maxW="30vw" maxH="60vh" overflowY="auto">
            {alerts.map(item => {
              return <AlertIcon key={item.shortName} alert={item} />;
            })}
          </Wrap>
        </Popover>
      )}
    </HStack>
  );
};
