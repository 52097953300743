import type { ComponentStyleConfig } from '@chakra-ui/theme';

const linkButtonProperties = {
  color: 'primary.default',
  border: 'none',
  _focus: { outline: 'none' },
};

const expandViewCommonProperties = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'blue.100',
  color: 'primary.default',
  borderRadius: '0px 6px 6px 0px',
};

const collapseViewCommonProperties = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'blue.100',
  color: 'primary.default',
  borderRadius: '6px 0px 0px 6px',
};

const invertedDefaultProperties = {
  bg: 'white',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'blue.100',
  color: 'primary.default',
  _disabled: {
    opacity: 0.5,
  },
  borderRadius: '6px',
};

const weekdayIconProperties = {
  ...invertedDefaultProperties,
  borderRadius: '50%',
  width: '28px',
  height: '28px',
};

const baseOutline = {
  bg: 'white',
  _focus: { outline: 'none' },
  _disabled: {
    opacity: 0.5,
  },
};

export const Button: ComponentStyleConfig = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: 600,
    borderRadius: 4,
    lineHeight: 'inherit',
    _focus: { boxShadow: 'outline' },
  },

  // Two sizes: sm and md
  sizes: {
    xs: {
      fontSize: 'xs',
    },
    sm: {
      fontSize: 'sm',
    },
    md: {
      fontSize: 'md',
      px: 6, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
  },

  variants: {
    icon: {
      bg: 'blue.50',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'blue.100',
      _focus: { outline: 'none' },
    },
    icon_cta: {
      bg: 'white',
      width: '32px',
      height: '32px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'blue.200',
    },
    expandViewIconSelected: {
      ...expandViewCommonProperties,
      bg: 'blue.300',
    },
    expandViewIconUnselected: {
      ...expandViewCommonProperties,
      bg: 'white',
    },
    collapseViewIconSelected: {
      ...collapseViewCommonProperties,
      bg: 'blue.300',
    },
    collapseViewIconUnselected: {
      ...collapseViewCommonProperties,
      bg: 'white',
    },
    link: {
      ...linkButtonProperties,
    },
    invertedDefault: {
      ...invertedDefaultProperties,
    },
    weekdayIconUnselected: {
      ...weekdayIconProperties,
      bg: 'white',
    },
    weekdayIconSelected: {
      ...weekdayIconProperties,
      bg: 'blue.300',
    },

    outline: {
      ...baseOutline,
      // py: 5,
      // px: 4,
      boxShadow: '0 0 0 1px #CBD5E0',
      color: 'gray.950',
      border: 'none',
    },

    outlineBlue: {
      ...baseOutline,
      boxShadow: 'none',
      color: 'primary.default',
      borderWidth: 1,
      borderColor: 'primary.default',
    },

    warn: {
      bg: 'red.900',
      color: 'white',
    },

    severe: {
      bg: 'red.900',
      color: 'white',
    },

    default: {
      bg: 'primary.default',
      color: 'white',
      _disabled: {
        opacity: 0.5,
      },
      borderRadius: '6px',
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
  },
};
