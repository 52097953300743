import styled from 'styled-components';
export const Container = styled.div`
  .main_title {
    color: #8a92a3;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 18px;
  }
`;
