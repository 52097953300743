import { TextButton, Para } from 'uie/components';
import { pluralize } from 'core/helpers/stringUtils';
import React from 'react';
import { CancelButton, ModalActions, ModalFooter } from './styles';

import { Member, Role, RoleText } from '../types';

type AddMemberModalFooterProps = {
  onClose: () => void;
  addMemberToTeam: (data: { team_id: string; members: Member[] }) => void;
  newMembersFiltered: Member[];
  teamId: string;
};

function AddMemberModalFooter({
  onClose,
  addMemberToTeam,
  newMembersFiltered,
  teamId,
}: AddMemberModalFooterProps) {
  return (
    <ModalFooter>
      <ModalActions>
        <TextButton
          onClick={() => {
            onClose();

            addMemberToTeam({
              team_id: teamId,
              members: newMembersFiltered,
            });
          }}
          disabled={newMembersFiltered.length === 0}
        >
          <Para
            fontSize={16}
            color="#fff"
            style={{
              lineHeight: '150%',
            }}
          >
            Add {newMembersFiltered.length}{' '}
            {pluralize(newMembersFiltered.length, 'Member', 'Members')}
          </Para>
        </TextButton>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </ModalActions>
    </ModalFooter>
  );
}

export default AddMemberModalFooter;
