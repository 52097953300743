import { Grid, Label, Para, Theme } from 'uie/components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IIncident } from '../../../../../core/interfaces/IIncidents';
import { IIntegration } from '../../../../../core/interfaces/IIntegration';
import { IService } from '../../../../../core/interfaces/IService';
import { IUserInfo } from '../../../../../core/interfaces/IUserData';
import {
  AlertTriangleIcon,
  EditIcon,
  NewTabIcon,
  ServiceIcon,
  ServiceNowIcon,
} from '../../../../../icons';
import useUINavigationFunctionsV2 from '../../navigation-flows/useUINavigationFunctionV2';
import { linkStyles } from '../constants';
import { IIncidentServiceNowMapping } from 'core/interfaces/IExtensions';
import { truncate } from 'core/helpers/stringUtils';
import { Tooltip } from 'library/atoms';

const { theme } = Theme;

interface IProps extends Pick<IAppState, 'organization' | 'integrations'> {
  incident: IIncident;
  incidentMapping: IIncidentServiceNowMapping | null;
}

const IncidentDetailsBasicBox = ({ incident, incidentMapping, ...props }: IProps) => {
  const _oService: {
    [key: string]: IService;
  } = props.organization.services.s.reduce((c: any, n) => {
    c[n.id] = n;
    return c;
  }, {});
  const _oIntegration: {
    [key: string]: IIntegration;
  } = props.integrations.i.reduce((c: any, n) => {
    c[n._id] = n;
    return c;
  }, {});
  const [elapsedTime, setElapsedTime] = useState<null | number>(null);

  let user: IUserInfo | undefined;
  if (
    (_oIntegration[incident.alert_source_id]?.shortName ?? 'Deleted entity') === 'email' &&
    incident.sender_email
  ) {
    user = props.organization.users.u.find((u: IUserInfo) => u.email === incident.sender_email);
  } else if (
    (_oIntegration[incident.alert_source_id]?.shortName ?? 'Deleted entity') === 'squadcastui' &&
    incident.created_by
  ) {
    user = props.organization.users.u.find((u: IUserInfo) => u.id === incident.created_by);
  }

  useEffect(() => {
    const updateElapsedTime = () => {
      if (incident.status === 'suppressed') {
        setElapsedTime(moment().diff(moment()));
        return;
      }

      if (incident.status === 'resolved' && incident.analytics?.ttr?.time) {
        setElapsedTime(incident.analytics!.ttr.time);
        return;
      }

      setElapsedTime(moment().diff(moment(incident.timeOfCreation)));
    };

    const interval = setInterval(updateElapsedTime, 1000);

    return () => {
      clearTimeout(interval);
    };
  }, [incident.status, incident.logs, incident.analytics, incident.timeOfCreation]);

  const serviceDetails = _oService[incident.service_id];
  const { getEntityComponent } = useUINavigationFunctionsV2(props.organization);

  return (
    <Grid type="column" className="incident_details__soft_card invert">
      <Label fontSize={14} color={theme.shades.black} fontWeight={500}>
        Incident Details
      </Label>

      {(_oIntegration[incident.alert_source_id]?.shortName ?? 'Deleted entity') === 'squadcastui' &&
        user && (
          <Grid alignItems="center" justifyContent="space-between" className="mt-10">
            <Label fontSize={14} fontWeight={500} className="incident_details__label_align_center">
              <EditIcon className="mr-5" height={14} width={14} />
              From
            </Label>
            {getEntityComponent({
              componentType: 'linkWPopover',
              type: 'user',
              id: user?.id,
              popoverTrigger: (
                <Para
                  fontSize={14}
                  fontWeight={500}
                  className="align_text__right"
                  style={linkStyles}
                >
                  {`${user?.first_name ?? 'User'} ${user?.last_name ?? 'Deleted'}`}
                </Para>
              ),
              popoverPlacement: 'bottom-start',
            })}
          </Grid>
        )}
      {(_oIntegration[incident.alert_source_id]?.shortName ?? 'Deleted entity') === 'email' &&
        incident.sender_email && (
          <Grid alignItems="center" justifyContent="space-between" className="mt-10">
            <Label fontSize={14} fontWeight={500} className="incident_details__label_align_center">
              <EditIcon className="mr-5" height={14} width={14} />
              From
            </Label>
            <Tooltip
              label={
                user
                  ? `${user.first_name ?? 'User'} ${user.last_name ?? 'Deleted'} <${
                      incident.sender_email
                    }>`
                  : incident.sender_email
              }
              placement="bottom"
            >
              {getEntityComponent({
                componentType: 'linkWOPopover',
                name: user
                  ? truncate(
                      `${user.first_name ?? 'User'} ${user.last_name ?? 'Deleted'} <${
                        incident.sender_email
                      }>`,
                      30,
                    )
                  : truncate(incident.sender_email, 30),
                type: 'user',
                id: user?.id,
                popoverPlacement: 'bottom-start',
              })}
            </Tooltip>
          </Grid>
        )}
      <Grid alignItems="center" justifyContent="space-between" className="mt-10">
        <Label fontSize={14} fontWeight={500} className="incident_details__label_align_center">
          <AlertTriangleIcon className="mr-5" height={14} width={14} />
          Alert Source
        </Label>
        <Para
          color={theme.shades.cement}
          fontSize={14}
          fontWeight={500}
          className="align_text__right"
        >
          {_oIntegration[incident.alert_source_id]?.type ?? 'Deleted entity'}
        </Para>
      </Grid>
      <Grid alignItems="center" justifyContent="space-between" className="mt-10">
        <Label fontSize={14} fontWeight={500} className="incident_details__label_align_center">
          <ServiceIcon className="mr-5" height={14} width={14} />
          Affected Service
        </Label>
        {serviceDetails && serviceDetails.name !== 'Deleted Entity' ? (
          getEntityComponent({
            componentType: 'linkWPopover',
            type: 'service',
            id: serviceDetails.id,
            popoverTrigger: (
              <Para fontSize={14} fontWeight={500} className="align_text__right" style={linkStyles}>
                {truncate(serviceDetails.name, 30) ?? 'Deleted Service'}
              </Para>
            ),
            popoverPlacement: 'bottom-start',
          })
        ) : (
          <Para fontSize={14} fontWeight={500} className="align_text__right">
            Deleted Service
          </Para>
        )}
      </Grid>
      {incidentMapping && (
        <Grid alignItems="center" justifyContent="space-between" className="mt-10">
          <Label fontSize={14} fontWeight={500} className="incident_details__label_align_center">
            <ServiceNowIcon className="mr-5" height={14} width={14} />
            ServiceNow Incident
          </Label>
          <Para fontSize={14} fontWeight={500} className="align_text__right" style={linkStyles}>
            <a
              className="installation-guide-design"
              target="_blank"
              rel="noopener noreferrer"
              href={`${incidentMapping.servicenow_incident_link}`}
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: '2px',
              }}
            >
              <span
                style={{
                  textDecoration: 'underline',
                  textUnderlineOffset: '1.5px',
                }}
              >
                #...{incidentMapping.servicenow_incident_number.slice(-3)}
              </span>
              <NewTabIcon />
            </a>
          </Para>
        </Grid>
      )}
    </Grid>
  );
};

export default connect(
  ({ organization, integrations }: IAppState) => ({
    organization,
    integrations,
  }),
  {},
)(IncidentDetailsBasicBox);
