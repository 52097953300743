import { Box, VStack } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo } from 'react';
import { useOrganizationConfig } from '..';
import { RotationViewType } from '../constants/schedules.rotation-type';
import { useGetAllPatternParticipants } from '../helpers/helpers.customrotations';
import {
  filterEventPerTheRotationType,
  mapGapsToCalendarEvent,
  mapSchedulesToCalendarEvents,
} from '../helpers/helpers.event';
import { includeOverridesInTheView } from '../helpers/helpers.override';
import { CalendarViewType, ISchedulesDetail, ISchedulesOnCallUsers } from '../interface/schedule';
import {
  ScheduleHeaderProviderProps,
  useScheduleHeaderContext,
} from '../schedules.view/schedules.header/context';
import SchedulesCalendar from './schedules.calendar';
import ListView from './schedules.list';
import { IListEvent } from './schedules.list/listView.table';
import WeekView from './schedules.week';

interface ISchedulesViewProps {
  calendarRef: ScheduleHeaderProviderProps['calendarRef'];
  calendarNextWeekRef: ScheduleHeaderProviderProps['calendarRef'];

  rotationViewType?: RotationViewType;
  calendarView: CalendarViewType;
  schedules: ISchedulesDetail;
  onCallUsers?: ISchedulesOnCallUsers;
  visibleDates: DateTime[];

  height?: string;
  isScheduleDetailsView?: boolean;
  allExpanded?: boolean;
  gaps?: {
    all: { startTime: string; endTime: string }[];
    day: { startTime: string; endTime: string }[];
  };
  showGaps?: boolean;
  onSyncCalendar?: () => void;
}

function SchedulesView({
  calendarRef,
  calendarNextWeekRef,

  rotationViewType,
  calendarView,
  schedules,
  onCallUsers,
  visibleDates,
  height,
  isScheduleDetailsView,
  allExpanded,
  gaps,
  showGaps,
  onSyncCalendar,
}: ISchedulesViewProps) {
  const {
    organization: { currentUser },
  } = useOrganizationConfig();
  const allParticipants = useGetAllPatternParticipants();
  const { currentScheduleTimezone, updateCurrentTimezone } = useScheduleHeaderContext();
  const timeZone =
    (isScheduleDetailsView
      ? currentScheduleTimezone || schedules?.[0]?.timeZone
      : currentUser.u?.time_zone) ?? '';

  useEffect(() => {
    updateCurrentTimezone(timeZone);
  }, []);

  const includeOverrides = useMemo(() => {
    return includeOverridesInTheView(
      isScheduleDetailsView ?? false,
      rotationViewType as RotationViewType,
    );
  }, [isScheduleDetailsView, rotationViewType]);

  const activeSchedules = useMemo(() => {
    let scheduleList = isScheduleDetailsView
      ? schedules
      : schedules?.filter(schedule => !schedule.paused);

    scheduleList = scheduleList?.map(s => ({
      ...s,
      rotations: s.rotations?.map(r => ({
        ...r,
        events: r.events?.filter(e => {
          return isScheduleDetailsView
            ? filterEventPerTheRotationType(
                e as IListEvent,
                rotationViewType as RotationViewType,
                allParticipants,
                currentUser.u?.id,
              )
            : true;
        }),
      })),
    }));

    return scheduleList || [];
  }, [isScheduleDetailsView, schedules, rotationViewType]);

  const gapEvents = useMemo(() => {
    if (gaps) {
      let gapsData = [] as typeof gaps.day;
      if (showGaps) {
        gapsData = gaps.day;
      }
      if (
        [RotationViewType.finalRotation, RotationViewType.gaps].includes(
          rotationViewType as RotationViewType,
        )
      ) {
        gapsData = gaps.all;
      }
      return mapGapsToCalendarEvent(gapsData, timeZone);
    }
    return [];
  }, [gaps, timeZone, rotationViewType, showGaps]);
  const mappedScheduleEvents = [
    ...mapSchedulesToCalendarEvents(activeSchedules, allParticipants, includeOverrides),
    ...gapEvents,
  ];

  if ([CalendarViewType.gridWeek, CalendarViewType.twoGridWeek].includes(calendarView)) {
    return (
      <Box maxH={height} overflowY="auto">
        <WeekView
          schedules={schedules}
          onCallUsers={onCallUsers}
          visibleDates={visibleDates}
          allExpanded={allExpanded ?? false}
        />
      </Box>
    );
  }

  if (calendarView === CalendarViewType.listView) {
    return (
      <Box maxH={height} overflowY="auto">
        <ListView
          schedules={activeSchedules}
          visibleDates={visibleDates}
          overflowHeight={height || 'auto'}
          isScheduleDetailsView={isScheduleDetailsView ?? false}
          timeZone={timeZone}
        />
      </Box>
    );
  }

  const calendarProps = {
    events: mappedScheduleEvents,
    disableScheduleDrawer: false,
    schedules: schedules ? schedules : [],
    onSyncSelectedDate: onSyncCalendar,
    isScheduleDetailsView: !!isScheduleDetailsView,
    timeZone,
  };

  if (calendarView === CalendarViewType.twotimeGridWeek) {
    return (
      <VStack spacing={0}>
        <SchedulesCalendar
          ref={calendarRef}
          viewType={CalendarViewType.timeGridWeek}
          height={`calc(${height} / 2)`}
          {...calendarProps}
        />
        <SchedulesCalendar
          ref={calendarNextWeekRef}
          viewType={CalendarViewType.timeGridWeek}
          height={`calc(${height} / 2)`}
          {...calendarProps}
        />
      </VStack>
    );
  }

  return (
    <SchedulesCalendar
      ref={calendarRef}
      viewType={calendarView}
      height={height}
      {...calendarProps}
    />
  );
}

export default React.memo(SchedulesView);
