import * as React from 'react';
import { SVGProps } from 'react';

const SvgThreatstackLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M18.47.172c-.841 0-1.53.688-1.53 1.53s.688 1.53 1.53 1.53S20 2.543 20 1.702s-.707-1.53-1.53-1.53M10 .096c-.899 0-1.587.727-1.587 1.587 0 .898.727 1.587 1.587 1.587.899 0 1.587-.727 1.587-1.587S10.86.096 10 .096m0 4.302c-.841 0-1.53.688-1.53 1.53s.688 1.53 1.53 1.53c.841 0 1.53-.688 1.53-1.53s-.708-1.53-1.53-1.53M10 8.7a1.452 1.452 0 1 0 1.453 1.453c0-.803-.669-1.453-1.453-1.453m0 8.642a1.28 1.28 0 1 0 0 2.562 1.28 1.28 0 0 0 0-2.562M1.53.172C.688.172 0 .86 0 1.702s.688 1.53 1.53 1.53 1.53-.688 1.53-1.53S2.39.172 1.53.172M10 13.002c-.765 0-1.358.631-1.358 1.358 0 .727.631 1.358 1.358 1.358.765 0 1.358-.631 1.358-1.358-.02-.727-.593-1.358-1.358-1.358"
      style={{
        fill: '#ea1947',
      }}
    />
    <path
      d="M18.47 4.646c-.727 0-1.319.593-1.319 1.319s.593 1.319 1.319 1.319 1.319-.593 1.319-1.319c-.038-.726-.63-1.319-1.319-1.319m0 4.417c-.631 0-1.147.516-1.147 1.147s.516 1.147 1.147 1.147 1.109-.516 1.109-1.147a1.13 1.13 0 0 0-1.109-1.147m0 4.36a.98.98 0 0 0-.975.975.98.98 0 0 0 .975.975.98.98 0 0 0 .975-.975c-.038-.517-.458-.975-.975-.975M14.226 2.39c-.765 0-1.396.631-1.396 1.396s.631 1.396 1.396 1.396c.765 0 1.396-.631 1.396-1.396-.039-.765-.632-1.396-1.396-1.396m0 4.302c-.727 0-1.319.593-1.319 1.319S13.5 9.33 14.226 9.33c.727 0 1.319-.593 1.319-1.319-.038-.726-.631-1.319-1.319-1.319m0 4.417c-.631 0-1.147.516-1.147 1.147s.516 1.147 1.147 1.147 1.147-.516 1.147-1.147c-.038-.669-.516-1.147-1.147-1.147m0 4.359a.98.98 0 0 0-.975.975.98.98 0 0 0 .975.975.98.98 0 0 0 .975-.975c0-.516-.459-.975-.975-.975M5.755 2.39c-.765 0-1.396.631-1.396 1.396s.631 1.396 1.396 1.396 1.396-.631 1.396-1.396S6.52 2.39 5.755 2.39m0 4.302c-.727 0-1.319.593-1.319 1.319S5.029 9.33 5.755 9.33s1.319-.593 1.319-1.319a1.32 1.32 0 0 0-1.319-1.319m0 4.417c-.631 0-1.147.516-1.147 1.147s.516 1.147 1.147 1.147 1.109-.516 1.109-1.147c0-.669-.478-1.147-1.109-1.147m0 4.359a.98.98 0 0 0-.975.975.98.98 0 0 0 .975.975.98.98 0 0 0 .975-.975c0-.516-.458-.975-.975-.975M1.53 4.646c-.727 0-1.319.593-1.319 1.319s.592 1.32 1.319 1.32 1.319-.593 1.319-1.319-.593-1.32-1.319-1.32m0 4.417c-.631 0-1.147.516-1.147 1.147s.516 1.147 1.147 1.147 1.109-.516 1.109-1.147c.038-.669-.478-1.147-1.109-1.147m0 4.36a.98.98 0 0 0-.975.975.98.98 0 0 0 .975.975.98.98 0 0 0 .975-.975.979.979 0 0 0-.975-.975"
      style={{
        fill: '#192b54',
      }}
    />
  </svg>
);

export default SvgThreatstackLogo20X20;
