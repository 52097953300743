import { useEffect, useState } from 'react';
import { useSearchParam } from './useSearchParam';

interface Props {
  includeInSearchParam: boolean;
  queryParamKey: string;
  getTabNameFn: (index: number) => string;
  getTabIndexFn: (name: string) => number;
}

const useTabGroup = ({
  includeInSearchParam,
  queryParamKey,
  getTabIndexFn,
  getTabNameFn,
}: Props) => {
  const [activeTabParam, setActiveTabParam] = useSearchParam(queryParamKey, 'push');
  const [activeTabIndex, setActiveTabIndex] = useState(getTabIndexFn(activeTabParam ?? ''));

  useEffect(() => {
    if (includeInSearchParam) {
      const value = getTabNameFn(activeTabIndex);
      setActiveTabParam(value);
    }
  }, [activeTabIndex, includeInSearchParam]);

  return { activeTabIndex, setActiveTabIndex };
};

export default useTabGroup;
