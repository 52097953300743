import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Flex,
  FormLabel,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
} from '@chakra-ui/react';
import { filterSchedules } from '../../constants/schedulers.filter';
import { components } from 'react-select';
import Select from 'components/chakra/Select';
import {
  DropdownOption,
  DropdownSection,
  Option,
  Owner,
} from 'views/main/organization/owner-filters/hooks/useEntityOwnerFilter';
import {
  SelectedFilterTag,
  SelectedFilterTags,
} from 'views/main/organization/owner-filters/SelectedFilterTags';
import SQTooltip from 'components/chakra/Tooltip';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { IfilterOption } from '../../interface/schedule';
import { THEME_COLORS } from 'library/theme/colors';

const Group = (props: any) => {
  const {
    children,
    label,
    selectProps: { inputValue },
  } = props;

  return (
    <Accordion
      allowToggle={true}
      defaultIndex={label === DropdownSection.YOU_AND_YOUR_SQUADS ? 0 : -1}
      zIndex={1}
      {...(inputValue ? { index: 0 } : {})}
    >
      <AccordionItem>
        {({ isExpanded = false }) => (
          <>
            <AccordionButton
              _expanded={{ background: 'white !important' }}
              padding="8px 12px"
              sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}
            >
              <HStack p={0}>
                <Text color="#646464" fontWeight={500}>
                  {label}
                </Text>
                {isExpanded ? (
                  <ChevronDownIcon width={6} height={6} color="#646464" />
                ) : (
                  <ChevronRightIcon width={6} height={6} color="#646464" />
                )}
              </HStack>
            </AccordionButton>
            <AccordionPanel p={0}>{children}</AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

const Menu = (props: any) => {
  const { children } = props;
  return (
    <components.Menu {...props}>
      <Flex
        sx={{
          ...{
            boxShadow: '0px 4px 10px 0px #0000000D',
          },
          ...{
            boxShadow: '2px 0px 10px 0px #0000000D',
          },
          width: '100%',
        }}
      >
        {children}
      </Flex>
    </components.Menu>
  );
};

export const avatarOptionLabel = (option: IfilterOption) => {
  return (
    <VStack alignItems="flex-start">
      <HStack>
        {option.label !== 'Select All' && !option.label.startsWith('Current Team') && (
          <Avatar size="xs" name={option.label} />
        )}
        <Box>
          <Text
            {...(option.label.startsWith('Current Team')
              ? {
                  ...{
                    color: '#646464',
                    fontSize: 'md',
                    fontWeight: 600,
                  },
                }
              : {})}
          >
            {option.label}
          </Text>
        </Box>
      </HStack>
      {option.type === 'user' && option.username && (
        <Text color={THEME_COLORS.secondary[600]} ml={'32px !important'} mt={'0px !important'}>
          {option.username}
        </Text>
      )}
    </VStack>
  );
};

interface Props {
  ownerOptions: Array<DropdownOption>;
  selectedFilterTags: SelectedFilterTag[];
  ownerDropdownChangeHandler: (values: Option) => void;
  handleSelectedFilterTagClick: (type: Owner, id: string) => void;
}

const EntityOwnerDropdown = ({
  ownerOptions,
  selectedFilterTags,
  ownerDropdownChangeHandler,
  handleSelectedFilterTagClick,
}: Props) => {
  return (
    <VStack px={5} py={3} spacing={5} alignItems="flex-start">
      <FormLabel mb={0} mr={1}>
        {filterSchedules.labels.owner}
      </FormLabel>
      <Select
        placeholder={filterSchedules.placeholders.owner}
        value={[]}
        options={ownerOptions}
        name="owner"
        components={{ Group, Menu }}
        formatOptionLabel={avatarOptionLabel as (data: unknown) => React.ReactNode}
        onChange={value => ownerDropdownChangeHandler(value as Option)}
        closeMenuOnSelect
        hideDropdownArrow
        hideSelectedValues
        isOwnerFilterDropdown
      />
      <SelectedFilterTags
        tags={selectedFilterTags}
        visibleTagsCount={4}
        applyChakraStyle={false}
        onClose={handleSelectedFilterTagClick}
      />
    </VStack>
  );
};

export default EntityOwnerDropdown;
