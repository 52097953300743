import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { REQUEST_LOGIN, REQUEST_LOGIN_FAIL } from '../../const/auth';
import { onLoginSuccess } from '../../actions/auth';
import { getLoginSession } from '../../api/auth';
import { AjaxResponse } from 'rxjs/ajax';
import { IAuthInviteDb } from '../../interfaces/IAuth';

const loginUserEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_LOGIN),
    map((action: any) => action.payload),
    switchMap(({ email, password }: { email: string; password: string }) => {
      return getLoginSession(email, password).pipe(
        map(({ response }: AjaxResponse) => response.data),
        map(
          (response: {
            firstName: string;
            lastName: string;
            email: string;
            sessionToken: string;
            type?: 'invited';
          }) => {
            return onLoginSuccess(response.sessionToken);
          },
        ),
        catchError(error =>
          of({
            type: REQUEST_LOGIN_FAIL,
            payload: error.response.data.meta,
            error: true,
          }),
        ),
      );
    }),
  );

export default loginUserEpic;
