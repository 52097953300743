import * as React from 'react';
import { SVGProps } from 'react';

const SvgRapid7Insightidr = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#231f20',
      }}
      d="M.221 9.389c.117 0 .221.093.221.221a.214.214 0 0 1-.22.221A.221.221 0 0 1 0 9.61c.012-.116.105-.221.221-.221zm-.14 1.106h.292v2.235H.08v-2.235zM.978 11.123c0-.163-.023-.512-.023-.628h.29c0 .163.012.349.024.396a.839.839 0 0 1 .768-.454c.699 0 .85.477.85.966v1.327h-.291v-1.316c0-.36-.105-.71-.559-.71-.43 0-.768.303-.768.908v1.118H.978v-1.607zM3.446 12.101c.116.233.384.373.652.373.256 0 .512-.152.512-.408 0-.617-1.327-.186-1.327-1.036 0-.43.396-.64.768-.64.361 0 .64.14.792.42l-.256.15c-.117-.197-.268-.314-.524-.314-.21 0-.477.128-.477.361 0 .605 1.327.14 1.327 1.036 0 .454-.384.687-.804.687-.384 0-.721-.14-.896-.454l.233-.175zM5.483 9.389c.117 0 .221.093.221.221a.214.214 0 0 1-.22.221.221.221 0 1 1 0-.442zm-.151 1.106h.29v2.235h-.29v-2.235zM8.475 12.579c0 1.106-.733 1.303-1.187 1.303-.454 0-.885-.174-1.106-.465l.232-.21c.198.28.536.42.885.42.885 0 .897-.769.897-1.025v-.314h-.012c-.175.267-.466.43-.85.43-.652 0-1.199-.512-1.199-1.14 0-.63.536-1.141 1.2-1.141.383 0 .674.174.849.43h.012v-.372h.29l-.011 2.084zm-1.164-1.886a.884.884 0 1 0 .885.884.884.884 0 0 0-.885-.884zM8.964 9.075h.29v1.816h.013a.839.839 0 0 1 .768-.454c.698 0 .85.477.85.966v1.327h-.291v-1.316c0-.36-.105-.71-.56-.71-.43 0-.767.303-.767.908v1.118h-.291l-.012-3.655zM12.526 10.704h-.64v1.455c0 .198.116.315.314.315a.793.793 0 0 0 .326-.082l.023.256a1.33 1.33 0 0 1-.372.082c-.42 0-.57-.256-.57-.57v-1.456h-.49v-.256h.49v-.652h.29v.64h.64l-.01.268zM12.945 9.296h.466v3.422h-.466V9.296zM14.051 9.296h1.2c1.443 0 1.792 1.082 1.792 1.711 0 .873-.652 1.711-1.874 1.711H14.05V9.296zm.466 2.992h.582c.78 0 1.455-.431 1.455-1.281 0-.43-.221-1.28-1.339-1.28h-.698v2.56zM17.567 9.296h1.199c.873 0 1.094.524 1.094.954 0 .431-.326.85-.838.908l.978 1.56h-.582l-.873-1.513h-.512v1.513h-.466V9.296zm.454 1.513h.605c.373 0 .734-.081.734-.547s-.361-.547-.734-.547h-.605v1.094z"
    />
    <path d="M1.42 7.165H.85l.128-.558h.524c.174 0 .267.093.267.232.012.175-.116.326-.349.326m.943-.384c0-.384-.29-.663-.803-.663H.512L0 8.446h.582l.175-.803h.337l.338.803h.582l-.36-.838a.81.81 0 0 0 .71-.827M3.364 7.584l.501-.861.186.861h-.687zM3.62 6.13 2.27 8.458h.582l.221-.385h1.083l.081.385h.583l-.513-2.329H3.62zm2.864 1.036h-.512l.116-.558h.454c.175 0 .292.081.292.232 0 .175-.14.326-.35.326m.117-1.036h-.99L5.1 8.458h.582l.175-.804h.652c.558 0 .896-.419.896-.861 0-.396-.303-.664-.803-.664M8.533 6.13h-.582L7.44 8.457h.582l.512-2.329z" />
    <path
      style={{
        fill: '#e85e26',
      }}
      d="M12.7 6.13h-1.839a.666.666 0 0 1 .082.092c.21.245.314.56.314.908a1.79 1.79 0 0 1-.582 1.34h1.13l.535-1.328h-.664l.117-.512h1.327l-.699 1.84h.268c.64 0 1.071-.525 1.071-1.165s-.419-1.176-1.06-1.176"
    />
    <path d="M9.453 7.969h-.326l.303-1.35h.314c.337 0 .524.244.524.558-.012.419-.315.792-.815.792m1.176-1.467c-.198-.245-.524-.384-.92-.384h-.745l-.512 2.328h.943c.36 0 .64-.082.861-.21.43-.267.594-.733.594-1.129a1.063 1.063 0 0 0-.221-.605" />
  </svg>
);

export default SvgRapid7Insightidr;
