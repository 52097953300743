import {
  T_WA_UP_LCR_CONFIG_DISABLED,
  T_WA_UP_LCR_CONFIG_ENABLED,
  T_WA_UP_LCR_CONFIG_UPDATED,
} from 'core/const/tracker';
import { exception } from 'core/exception';
import LCRService from 'core/services/service.lcr';
import { useToast } from 'library/atoms';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AppTracker } from 'shared/analytics/tracker';
import { generateKeywordMapping, getCountryTypeValue, getDurationTypeValue } from './helper';
import { useLCRStore } from './lcr.state';
import { useVariant } from '@unleash/proxy-client-react';

async function updateLCRStatus({
  id,
  enabled,
  source,
}: {
  id: string;
  enabled: boolean;
  source: string;
}) {
  const _extensionService = new LCRService();
  try {
    const { data } = await _extensionService.updateLCRStatus(id, enabled);
    if (data) {
      AppTracker.track(`${enabled ? T_WA_UP_LCR_CONFIG_ENABLED : T_WA_UP_LCR_CONFIG_DISABLED}`, {
        'LCR ID': id,
      });
      return data;
    }
  } catch (error) {}
}

const useUpdateLCRStatus = () => {
  const query = useQueryClient();
  return useMutation(updateLCRStatus, {
    onSuccess: () => {
      query.invalidateQueries();
    },
  });
};

const useLCRUpdate = (id: number) => {
  const _lcrService = new LCRService();
  const toast = useToast();

  const setFormData = useLCRStore((state: any) => state.setFormData);
  const setMappingData = useLCRStore((state: any) => state.setMappingData);

  const extraDurationMinutes = useVariant('lcr_call_duration');

  const [loading, setLoading] = useState<boolean>(false);
  const [updatingLCR, setUpdatingLCR] = useState<boolean>(false);

  const getRoutingNumberData = async () => {
    try {
      setLoading(true);
      const res = await _lcrService.getRoutingNumberById(id);
      if (res) {
        const response = res?.data;
        const formData: any = {
          name: response?.name,
          description: response?.description,
          country: getCountryTypeValue(response?.country),
          routingType: response?.type,
          routingBehavior: response?.routing_type,
          recordCall: response?.record_call,
          messageStructure: response?.message_structure,
          greetingMessage: response?.greeting_message,
          routingNumber: response?.number,
          keywordMapping: generateKeywordMapping(response?.keypad_mappings),
          // notifyUser: boolean;
          callDuration: getDurationTypeValue(
            response?.max_duration,
            response?.routing_type,
            extraDurationMinutes,
          ),
          owner: {
            id: response?.owner_id,
            name: response?.owner_name,
            type: response?.owner_type,
          },
        };
        setMappingData(generateKeywordMapping(response?.keypad_mappings));
        setFormData(formData);
      }
    } catch (error: any) {
      toast({
        status: 'error',
        text: error.response?.data?.message,
      });
      exception.handle('E_FETCH_LCR__DETAILS_ERROR', error);
    } finally {
      setLoading(false);
    }
  };

  const updateLCRData = async (params: any, cb: () => void) => {
    try {
      setUpdatingLCR(true);
      const res = await _lcrService.updateRotutingNumber(params, id);
      if (res?.data) {
        AppTracker.track(T_WA_UP_LCR_CONFIG_UPDATED, {
          'LCR ID': id,
        });
        toast({
          status: 'success',
          text: `LCR has been updated`,
        });

        cb();
      }
    } catch (error: any) {
      toast({
        status: 'error',
        text: error.response?.data?.message,
      });
      exception.handle('E_UPDATE_LCR_ERROR', error);
    } finally {
      setUpdatingLCR(false);
    }
  };

  return { getRoutingNumberData, loading, updateLCRData, updatingLCR, extraDurationMinutes };
};

export { useUpdateLCRStatus, useLCRUpdate };
