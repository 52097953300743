import React, { useState } from 'react';
import { connect } from 'react-redux';
import { requestUserLogout } from '../../core/actions';
import { Grid, Para, TextButton, SpinLoader } from 'uie/components';

interface IProps {
  requestUserLogout: typeof requestUserLogout;
}

const PageLoaderComponent = (props: IProps) => {
  const [showLogout, setShowLogout] = useState(false);

  setTimeout(() => {
    setShowLogout(true);
  }, 5000);

  const logoutAndReload = () => {
    props.requestUserLogout();
    setTimeout(() => window.location.reload(), 1000);
  };

  return (
    <Grid type="column" height="100%" justifyContent="center">
      <Grid flexWidth={12} justifyContent="center">
        <Grid type="column" alignItems="center">
          <SpinLoader base="32px" style={{ margin: 0, height: 32, width: 32 }}>
            <img src="/icons/squadcast-spinner.svg" alt="." width="32px" height="32px" />
          </SpinLoader>

          {showLogout && (
            <>
              <Para className="mt-10">Taking too long...</Para>
              <TextButton className="mt-5" buttonType="ghost" onClick={logoutAndReload}>
                <Para fontSize={14}>
                  Try clearing cache. Hard refresh this browser and try again
                </Para>
              </TextButton>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(null, {
  requestUserLogout,
})(PageLoaderComponent);
