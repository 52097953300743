import React, { Component } from 'react';
import { API } from '../../../../../../core/api';
import { LocalStore } from '../../../../../../store/local';

class SlackAuthRedirect extends Component {
  public _slack = API.config.integration.slack;
  public async componentDidMount() {
    const client_id = this._slack.id;
    const redirect_uri = this._slack.redirects + '/setup-organization-slack';

    const scopes = [
      // bot token scopes
      'app_mentions:read',
      'channels:join',
      'channels:read',
      'chat:write',
      'commands',
      'groups:read',
      'groups:write',
      'im:read',
      'im:write',
      'mpim:read',
      'mpim:write',
      'team:read',
      'users:read',
      'users:read.email',
    ];

    // user token scopes
    const userScopes = [
      'channels:read',
      'channels:write',
      'groups:read',
      'groups:write',
      // 'identify',
      'users:read',
      'users:read.email',
    ];

    const slackState = new Date().getTime().toString();
    await LocalStore.setSlackState(slackState);
    window.location.href =
      'https://slack.com/oauth/v2/authorize?client_id=' +
      client_id +
      '&redirect_uri=' +
      redirect_uri +
      '&state=' +
      slackState +
      '&scope=' +
      scopes.join(',') +
      '&user_scope=' +
      userScopes.join(',');
  }

  public render() {
    return <div />;
  }
}

export default SlackAuthRedirect;
