import { Text } from '@chakra-ui/react';
import { T_WA_UP_GER_ALERT_SRC_DELETED } from 'core/const/tracker';
import { FormButton, useToast } from 'library/atoms';
import { Modal } from 'library/molecules';
import { FC, useState } from 'react';
import { AppTracker } from 'shared/analytics/tracker';

import { deleteGerRuleset } from '../../query/useDeleteGerRuleset';
import { ALERT_INFO } from '../../types';

type Props = {
  id: string;
  alert: ALERT_INFO;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const DeleteGERRuleset: FC<Props> = ({ id, alert, isOpen, onSuccess, onClose }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const onDelete = () => {
    setIsLoading(true);
    deleteGerRuleset(id, alert.version, alert.shortName)
      .then(() => {
        onDeleteSuccess();
      })
      .catch(error => {
        onError(error);
      });
  };

  const onDeleteSuccess = () => {
    setIsLoading(false);
    onSuccess();
    AppTracker.track(T_WA_UP_GER_ALERT_SRC_DELETED, {
      'GER Ruleset ID': id,
      'Alert Source Name': alert.shortName,
    });
    toast({
      status: 'success',
      text: 'Ruleset deleted successfully',
    });
  };

  const onError = (err: any) => {
    setIsLoading(false);
    const errMsg = err?.response?.data?.meta.error_message ?? 'Unable to delete Ruleset';

    toast({
      status: 'error',
      text: `Error: ${errMsg}`,
    });
    console.error(err);
  };

  return (
    <Modal
      key="delete-ger-ruleset"
      body={
        <Text variant="formInput" color="secondary.700">
          {`Deleting ${alert.name} will delete all the rule configurations contained within it. This action
          cannot be undone`}
        </Text>
      }
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={
        <FormButton title="Delete" onClick={onDelete} isLoading={isLoading} variant="danger" />
      }
      secondaryButton={
        <FormButton title="Cancel" onClick={onClose} disabled={isLoading} variant="secondary" />
      }
      title={`Delete ${alert.name}`}
    />
  );
};
