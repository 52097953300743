import { Text } from '@chakra-ui/react';
import { OnCallCoverage } from './types';
import { calculateTime, parseDateTimeInTimezone } from './utils';
import { WeeklyRoutine } from './weekly-routine';

type TimeDisplayProps = {
  data: Pick<
    NonNullable<OnCallCoverage['rotations']>[number],
    'startDate' | 'endDate' | 'period' | 'shiftTimeSlot' | 'shiftTimeSlots' | 'customPeriod'
  >;
  timeZone: string;
  dateTimeColor?: string;
  useTimeZone?: boolean;
};

const renderCustomPeriod = (
  periodUnit: string,
  shiftTimeSlots: TimeDisplayProps['data']['shiftTimeSlots'],
) => {
  if (periodUnit === 'week') {
    return <WeeklyRoutine data={shiftTimeSlots} />;
  } else if (periodUnit === 'day') {
    const { duration: durationInMinutes, startHour, startMin } = shiftTimeSlots[0];
    const { startTime, endTime, isNextDay } = calculateTime(startHour, startMin, durationInMinutes);
    return `${startTime} - ${endTime} ${isNextDay ? ' +1' : ''}`;
  }
};

const renderTimeSlots = (shiftTimeSlots: TimeDisplayProps['data']['shiftTimeSlots']) => {
  const { duration: durationInMinutes, startHour, startMin } = shiftTimeSlots[0];
  const { startTime, endTime, isNextDay } = calculateTime(startHour, startMin, durationInMinutes);
  return `${startTime} - ${endTime} ${isNextDay ? ' +1' : ''}`;
};

export const TimeDisplay = ({ data, timeZone, dateTimeColor, useTimeZone }: TimeDisplayProps) => {
  const { period, shiftTimeSlots, customPeriod } = data;
  let content;

  if (period === 'custom' && customPeriod?.periodUnit) {
    content = renderCustomPeriod(customPeriod.periodUnit, shiftTimeSlots);
  } else if (shiftTimeSlots && shiftTimeSlots.length > 0) {
    content = renderTimeSlots(shiftTimeSlots);
  } else {
    const { startDateTime, endDateTime } = parseDateTimeInTimezone({
      startTime: data.startDate,
      endTime: data.endDate,
      timeZone,
      useTimeZone,
    });
    const startTime = startDateTime.toFormat('t');
    const endTime = endDateTime?.toFormat('t') ?? 'Forever';
    content = `${startTime} - ${endTime}`;
  }

  return (
    <Text fontSize={10} fontWeight="700" mt={'1px'} color={dateTimeColor ?? '#627C98'}>
      {content}
    </Text>
  );
};
