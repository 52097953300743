import { Tooltip } from '@chakra-ui/react';
import React from 'react';

type NoPermissionTooltipProps = {
  isDisabled: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  shouldWrapChildren?: boolean;
};

export function NoPermissionTooltip({
  shouldWrapChildren = true,
  ...props
}: NoPermissionTooltipProps) {
  if (props.isDisabled) {
    return <>{props.children}</>;
  }
  return (
    <Tooltip
      label="You do not have permission to perform this action"
      placement="bottom"
      shouldWrapChildren={shouldWrapChildren}
      backgroundColor="#F1F3F6"
      color="#09305A"
      padding="6px"
      fontSize="12.5px"
      fontFamily="Mulish"
      fontWeight="400"
      arrowSize={14}
    >
      {props.children}
    </Tooltip>
  );
}
