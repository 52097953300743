import { API } from 'core';
import { T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_SEARCH_PERFORMED } from 'core/const/tracker';
import { useSearchParam } from 'core/hooks/useSearchParam';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { AppTracker } from 'shared/analytics/tracker';

import { SEARCH_QUERY_KEY } from '../common/constants';
import { invalIncidentsListData } from '../common/util';
import { filterManager } from '../filters/manager';
import { ActionKind, IncidentListContextType } from '../store';

const useIncidentSearch = ({ state, dispatch }: IncidentListContextType) => {
  const queryClient = useQueryClient();
  const [searchQuery, setSearchTerm, unsetSearchTerm] = useSearchParam(SEARCH_QUERY_KEY, 'replace');

  const [teamId, setTeamId] = useState(API.config.teamId);

  const setSearch = (value: string) => {
    if (searchQuery !== value || state.search.term !== value) {
      invalIncidentsListData(queryClient);
      dispatch({ type: ActionKind.SET_SEARCH_TERM, payload: { searchTerm: value } });
      const searchParams = new URLSearchParams(location.search);
      if (value) {
        searchParams.set('search', value);
      } else {
        searchParams.delete('search');
      }
      history.push({
        pathname: history.location.pathname,
        search: searchParams.toString(),
      });
      AppTracker.track(T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_SEARCH_PERFORMED);
    }
  };
  const history = useHistory();

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearch(value);
    }, 1000),
  ).current;

  useEffect(() => {
    if (teamId !== API.config.teamId) {
      filterManager.clearFilters(queryClient);
      setTeamId(API.config.teamId);
    }
  }, [API.config.teamId]);

  useEffect(() => {
    const searchTerm = searchQuery ?? '';
    if (state.search.term !== searchTerm) {
      debouncedSearch(searchTerm);
    }
  }, [searchQuery]);

  return {
    debouncedSearch,
  };
};

export default useIncidentSearch;
