import * as React from 'react';
import { SVGProps } from 'react';

const SvgStackdriver = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="stackdriver_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.stackdriver_svg__st0{fill:#97c35f}'}</style>
    <g id="stackdriver_svg__surface1">
      <path
        className="stackdriver_svg__st0"
        d="M5.001 1.368.001 10l5 8.632H15L20 10l-5-8.632H5.001"
      />
      <path
        d="M5.001 18.632h9.933L9.964 10l-4.963 8.632z"
        style={{
          fill: '#00a35e',
        }}
      />
      <path
        d="m0 10 5.001 8.632L9.967 10H0z"
        style={{
          fill: '#008754',
        }}
      />
      <path
        d="M5 1.368 0 10h9.964l.002-.004 4.968-8.628H5"
        style={{
          fill: '#10b260',
        }}
      />
      <path
        className="stackdriver_svg__st0"
        d="M14.966 1.368 10 10l4.966 8.632L19.967 10l-5.001-8.632"
      />
    </g>
  </svg>
);

export default SvgStackdriver;
