import * as React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IUserNotificationRule } from '../../../../../core/interfaces/IUserData';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_GS_NOTIFICATION_RULES_MODIFIED } from '../../../../../core/const/tracker';
import {
  ErrorBlock,
  FocusBlock,
  Grid,
  Heading,
  IconButton,
  InputBlock,
  Para,
  SelectBox,
  TextButton,
  Theme,
} from 'uie/components';
import ProfileService from '../../../../../core/services/service.editprofile';
import { IComponentErrors } from '../../../../../core/interfaces/IComponentState';
import { exception } from 'core/exception';
import { DeleteIcon } from 'icons';

interface IProps extends Pick<IAppState, 'userInfo'> {
  hide: () => void;
  editNotificationRules: IUserNotificationRule[];
}

interface IState {
  saving: boolean;
  notificationRules: IUserNotificationRule[];
  error: IComponentErrors<'edit_notification_rule'>;
}

const notificationTypes: IUserNotificationRule['type'][] = ['Push', 'Phone', 'SMS', 'Email'];
class EditNotifcationsRules extends React.Component<IProps, IState> {
  _profileService = new ProfileService();
  constructor(props: IProps) {
    super(props);
    this.state = {
      saving: false,
      notificationRules: this.props.editNotificationRules,
      error: { edit_notification_rule: '' },
    };
  }
  removeRule = (index: number) => {
    this.setState({
      notificationRules: this.state.notificationRules.filter((_, i) => index !== i),
    });
  };
  addRule = () => {
    this.setState(prevState => ({
      notificationRules: [...prevState.notificationRules, { time: 0, type: 'Push' }],
    }));
  };

  validate = (notificationRules: IUserNotificationRule[]) => {
    notificationRules = notificationRules.map(rule => {
      rule.validationError = '';
      if (isNaN(rule.time) || rule.time < 0) {
        rule.validationError = 'Enter valid time';
      }
      if (rule.type === '') {
        rule.validationError = 'Select a type';
      }
      return rule;
    });
    // Check for duplicates
    notificationRules = notificationRules.map((rule1, index1) => {
      if (
        notificationRules.some(
          (rule2, index2) =>
            index1 !== index2 && rule1.type === rule2.type && rule1.time === rule2.time,
        )
      )
        rule1.validationError = 'There cannot be duplicate rules';
      return rule1;
    });
    this.setState({ notificationRules });
    return notificationRules.some(rule => rule.validationError !== '');
  };

  save = async () => {
    this.setState({ error: { edit_notification_rule: '' } });
    if (this.validate(this.state.notificationRules)) return;
    const notificationRules = this.state.notificationRules.sort((a, b) => a.time - b.time);
    this.setState({
      saving: true,
    });
    try {
      await this._profileService.updateIncidentNotificationRules(notificationRules);
      this.props.hide();
      AppTracker.track(T_WA_GS_NOTIFICATION_RULES_MODIFIED, {
        'Notification Type': notificationRules.map(notificationRules => notificationRules.type),
      });
    } catch (err: any) {
      this.setState({
        error: {
          edit_notification_rule: exception.handle('E_UPDATE_INCIDENT_NOTIFICATION_RULES', err),
        },
      });
    } finally {
      this.setState({ saving: false });
    }
  };

  onTypeChange = (index: number, _: React.KeyboardEvent<HTMLDivElement>, value: any) => {
    this.setState(prevState => {
      prevState.notificationRules[index].type = value;
      return prevState;
    });
  };

  onTimeValueChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => {
      prevState.notificationRules[index].time = parseInt(event.target.value);
      return prevState;
    });
  };

  public render() {
    const { theme } = Theme;
    return (
      <div>
        <Heading fontSize={20} style={{ lineHeight: 1.2 }}>
          Edit Incident Notification Rules
        </Heading>
        <div style={{ marginTop: 30, marginBottom: 10 }}>
          <Para style={{ marginBottom: 20 }}>
            If an incident is assigned to you, you will be notified via
          </Para>
          {this.state.notificationRules.map((notificationRule, index) => {
            return (
              <div key={index}>
                <Grid alignItems="center" justifyContent="space-between">
                  <div className="select-box-container">
                    <SelectBox
                      hook={<Para>{notificationRule.type}</Para>}
                      onValueChange={this.onTypeChange.bind(this, index)}
                      error={!!notificationRule.validationError}
                      height="auto"
                      style={{ padding: 4 }}
                    >
                      {notificationTypes.map((type, index) => {
                        return (
                          <FocusBlock
                            key={index}
                            value={type}
                            isSelected={notificationRule.type === type}
                          >
                            {type}
                          </FocusBlock>
                        );
                      })}
                    </SelectBox>
                  </div>
                  <Para>after</Para>
                  <InputBlock
                    type="number"
                    placeholder="#"
                    value={notificationRule.time}
                    onChange={this.onTimeValueChange.bind(this, index)}
                    style={{
                      maxWidth: 100,
                      textAlign: 'center',
                      boxShadow: notificationRule.validationError
                        ? `0 0 0 1px ${theme.danger.default}`
                        : '',
                    }}
                    width={100}
                  />
                  <Para>minutes</Para>
                  <IconButton
                    onClick={this.removeRule.bind(null, index)}
                    disabled={this.state.notificationRules.length === 1}
                  >
                    <DeleteIcon color={theme.danger.default} />
                  </IconButton>
                </Grid>
                <ErrorBlock>{notificationRule.validationError}</ErrorBlock>
              </div>
            );
          })}
          <TextButton
            buttonType="ghost"
            onClick={this.addRule}
            style={{ color: theme.primary.default, fontWeight: 600 }}
          >
            Add More Rules
          </TextButton>
        </div>
        <ErrorBlock>{this.state.error.edit_notification_rule || ''}</ErrorBlock>
        <TextButton
          onClick={this.save}
          style={{
            color: theme.shades.white,
            padding: 10,
            width: 120,
            fontSize: 14,
            fontWeight: 600,
            marginTop: 10,
          }}
          disabled={this.state.saving}
        >
          Save
        </TextButton>
      </div>
    );
  }
}
export default connect((state: IAppState) => ({
  userInfo: state.userInfo,
}))(EditNotifcationsRules);
