import { Box, Text } from '@chakra-ui/layout';
import { FormButton } from 'library/atoms';
import DrawerFooterWrapper from 'library/molecules/Drawer';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { communicationChannelValidation } from 'views/main/organization/workflow/constant/validation';
import { communicationChannelLinkInit } from 'views/main/organization/workflow/constant/values';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { SqCommunicationChannelAction } from 'views/main/organization/workflow/types';

import CommunicationLinkForm from './CommunicationLinkForm';

const CommunicationChannelForm = () => {
  const { setIsDirty } = useWorkflowFormContext();
  const { formState } = useFormContext<SqCommunicationChannelAction>();
  const { append, remove, fields } = useFieldArray<SqCommunicationChannelAction>({
    name: 'data.channels',
  });
  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <Box px={6} pt={4} pb={20}>
      {fields.map((links, index) => (
        <CommunicationLinkForm index={index} key={links.id} remove={remove} />
      ))}

      {formState.errors.data?.message ? (
        <Text color="brand.red" variant="h3" mb={2}>
          {formState.errors.data?.message}
        </Text>
      ) : null}

      <FormButton
        variant="ghost"
        onClick={() => append(communicationChannelLinkInit)}
        aria-label="add"
        title="Add another link"
      />
    </Box>
  );
};

const FormFooter = ({ checkDirtyAndCloseDrawer }: { checkDirtyAndCloseDrawer: () => void }) => {
  const { formState } = useFormContext<SqCommunicationChannelAction>();
  return (
    <>
      <FormButton
        variant="primary"
        type="submit"
        aria-label="submit"
        disabled={!formState.isDirty}
        title="Save"
      />
      <FormButton variant="secondary" onClick={() => checkDirtyAndCloseDrawer()} title="Cancel" />
    </>
  );
};

const CommunicationChannelFormContainer = () => {
  const { actionForm, onActionSubmit, checkDirtyAndCloseDrawer } = useWorkflowFormContext();

  return (
    <FormWrapper<SqCommunicationChannelAction>
      onSubmit={onActionSubmit}
      defaultValues={actionForm! as SqCommunicationChannelAction}
      validationSchema={communicationChannelValidation}
    >
      <CommunicationChannelForm />
      <DrawerFooterWrapper>
        <FormFooter checkDirtyAndCloseDrawer={checkDirtyAndCloseDrawer} />
      </DrawerFooterWrapper>
    </FormWrapper>
  );
};

export default CommunicationChannelFormContainer;
