import {
  Breadcrumb as CBreadcrumb,
  useMultiStyleConfig,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import type { LinkObject } from 'library/types';
import { NavLink } from 'react-router-dom';
import { forwardRef, Ref } from 'react';

type Props = {
  data: LinkObject[];
};
export const Breadcrumb = forwardRef(({ data, ...rest }: Props, ref: Ref<HTMLElement>) => {
  const styles = useMultiStyleConfig('Breadcrumb', {});
  return (
    <CBreadcrumb
      {...rest}
      separator={<ChevronRightIcon sx={{ ...styles.icon, mb: '0.25rem' }} />}
      ref={ref}
    >
      {data.map((item: { link: string; text: string }, index: number) => {
        const isCurrentPage = index === data.length - 1;
        return (
          <BreadcrumbItem key={index} isCurrentPage>
            <BreadcrumbLink as={NavLink} to={item.link} sx={{ ...styles.hyperText }}>
              <Text variant="hint" sx={isCurrentPage ? styles.currentPage : styles.previousPages}>
                {item.text}
              </Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </CBreadcrumb>
  );
});
