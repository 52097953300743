export function findNonCommonElements(
  arrayOfObjects: any,
  arrayOfObjectsToCompare: any,
  property: any,
) {
  // Extracting property values from arrayOfObjects
  const propertyValues = arrayOfObjects.map((obj: any) => obj[property]);

  // Extracting property values from arrayOfObjectsToCompare
  const propertyValuesToCompare = arrayOfObjectsToCompare.map((obj: any) => obj[property]);

  // Finding values present in propertyValuesToCompare but not in propertyValues
  const nonCommonValues = propertyValuesToCompare.filter(
    (value: any) => !propertyValues.includes(value),
  );

  // Finding objects with property values present in nonCommonValues
  const nonCommonObjects = arrayOfObjectsToCompare.filter((obj: any) =>
    nonCommonValues.includes(obj[property]),
  );

  return nonCommonObjects;
}

export function filterObjectValues(object: any, value: any) {
  const filteredObject: any = {};
  let meAndMySqaudValue = object['meAndMySquads'];
  for (const key in object) {
    if (object.hasOwnProperty(key) && key !== 'meAndMySquads') {
      // Filter the array of objects based on the provided condition
      filteredObject[key] = object[key].filter((val: any) => val.value !== value);
    }
  }
  if (
    object?.myUsers?.length !== filteredObject?.myUsers?.length ||
    filteredObject?.mySquads?.length !== object?.mySquads?.length ||
    value?.length === 0
  ) {
    meAndMySqaudValue = false;
  }
  if (value?.length === 0) {
    filteredObject['myUsers'] = [];
    filteredObject['mySquads'] = [];
  }
  return {
    ...filteredObject,
    meAndMySquads: meAndMySqaudValue,
  };
}

// Example condition function to filter out objects with id greater than 3
export function condition(obj: any, value: any) {
  return obj.value === value;
}
