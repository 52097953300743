import React, { useEffect, useRef } from 'react';
import { Tags } from 'icons';
import { HStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import Wrapper from './Wrapper';
import { FILTER_TYPE_LABEL, OPERATOR_LABEL } from '../../../constant';
import { safeEncodeUriComponent } from '../../../utils';
import { focusCard } from 'core';
import { ActiveWorkflowTarget } from './ActiveWorkflowTarget';
import { BE_FieldType, OperatorTypes } from '../../../types';

import Tag, { KeyValueTag, TAG_VARIANTS } from 'library/atoms/Tag/Tag';

interface TagsConditionsProps {
  tags?: BE_FieldType;
}

export const TagsConditions: React.FC<TagsConditionsProps> = ({ tags }) => {
  const flexRef = useRef(null);
  const { isactionTrigger, styleActionOnTarget, targetAction } = ActiveWorkflowTarget('#Tags');

  useEffect(() => {
    if (flexRef.current && targetAction === '#Tags') {
      focusCard(flexRef.current);
    }
  }, [isactionTrigger]);

  if (!tags) return null;
  const tagData: KeyValueTag = {
    keyStr: tags.fields.key ?? '',
    value: tags.fields.value ?? '',
    color: '#1B7FF1',
  };

  return (
    <Wrapper
      id={safeEncodeUriComponent(FILTER_TYPE_LABEL.tag)}
      border={styleActionOnTarget}
      ref={flexRef}
    >
      <Tags width={24} height={24} />
      <Text variant="h1" as="h1">
        <strong> Tags </strong>
      </Text>
      <HStack align="center">
        <Text variant="h1" as="h1">
          {OPERATOR_LABEL[tags?.type.split('tag_')[1] as OperatorTypes]}
        </Text>
        <Tag {...tagData} variant={TAG_VARIANTS.KEY_VALUE} />
      </HStack>
    </Wrapper>
  );
};
