import { Box, Flex, HStack } from '@chakra-ui/layout';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TRIGGER_OPTIONS } from '../../constant';
import { TriggerOption, WorkflowForm } from '../../types';
import FilterTypeContainer from './FilterTypeContainer';
import { useWorkflowFormContext } from '../../context/workflowFormContext';
import React, { useEffect, useMemo, useState } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import FilterSelection from './utility/reUseableComponents/FilterSelection';
import SeparatorComponent from './SeparatorComponent';
import GroupUI from './utility/reUseableComponents/GroupUI';
import { determineFilterDropdownType, determineFilterType } from '../../helper';
import FormField from 'library/molecules/FormField/FormField';
import { FormButton, IconButton } from 'library/atoms';
import FormDropdown from 'library/molecules/Form/FormDropdown/FormDropdown';
import { useAutoAnimate } from 'library/atoms/AutoAnimate/useAutoAnimate';

const FilterForm = () => {
  const [addFilter, setAddFilter] = useState(false);
  const { element } = useAutoAnimate();
  const [addGroup, setAddGroup] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { setTriggerType, actions, workflowForm, enableGroup, setEnableGroup } =
    useWorkflowFormContext();
  const { getValues, formState, watch, setValue } = useFormContext<WorkflowForm>();

  // appends the filter  or group as per condition
  const { append, remove, fields } = useFieldArray<WorkflowForm>({
    name: 'group.fields.children',
  });

  // appends the filter inside group
  const { fields: groupFields, append: appendFields } = useFieldArray<WorkflowForm>({
    name: `group.fields.children.${
      watch('isGroup') === 'subgroup' ? activeIndex : fields.length - 1
    }.fields.children`,
  });

  const triggerOptions = useMemo(() => {
    const actionNames = actions.map(action => action.name);
    return TRIGGER_OPTIONS.filter(
      option => !option.blockedForActions?.some(action => actionNames.includes(action)),
    );
  }, [actions]);

  const trigger = watch('trigger');
  useEffect(() => {
    setTriggerType(trigger?.value);
    watch();
  }, [trigger]);

  const handleAddFilter = () => {
    setAddFilter(true);
    setValue('isGroup', 'filter');
  };

  // on add group, set isGroup as group

  const handleAddGroup = () => {
    setValue('isGroup', 'group');
    setEnableGroup(false);
    setAddGroup(false);
    // appending the new filter, with default filter type group
    append({
      filterType: 'group',
      fields: {
        children: [],
      },
      value: '',
      type: 'and_filter',
    });
  };
  useEffect(() => {
    if (workflowForm && workflowForm.response && workflowForm.response.fields) {
      const fieldsToAdd = workflowForm.response.fields.map(data => ({
        filterType: determineFilterType(data.type),
        fields: {
          value: data.fields.value, // Initialize children array
          key: data.fields.key ?? '',
          children:
            data.fields?.children &&
            data.fields.children.length > 0 &&
            data.fields.children.map(data => ({
              filterType: determineFilterType(data.type),
              fields: {
                value: data.fields.value, // Initialize children array
                key: data.fields.key ?? '',
              },
              value:
                determineFilterDropdownType(data.type) === 'alert'
                  ? 'alert_source'
                  : determineFilterDropdownType(data.type), // Set default value
              type: data.type, // Set default type
            })),
        },
        value:
          determineFilterDropdownType(data.type) === 'alert'
            ? 'alert_source'
            : determineFilterDropdownType(data.type), // Set default value
        type: data.type, // Set default type
      }));

      append(fieldsToAdd); // Append multiple fields at once
    }
    if (workflowForm && workflowForm.response) {
      setValue('group.type', workflowForm.response.type);
    }
  }, [workflowForm]);
  // on adding filter inside group, set isGroup as subgroup
  const handleAddSubFilter = (index: number) => {
    setValue('isGroup', 'subgroup');
    setAddGroup(true);
    setActiveIndex(index);
  };

  useEffect(() => {
    if (getValues('group.fields.children')?.length === 0) {
      setValue('group.type', 'empty');
    } else if (getValues('group.fields.children')?.length === 1) {
      setValue('group.type', 'and_filter');
    }
  }, [getValues(`group.fields.children`)?.length]);
  return (
    <Box w="80%" ref={element}>
      <FormField label="Select  Trigger" error={formState.errors.trigger?.value?.message}>
        <FormDropdown<TriggerOption> name="trigger" options={triggerOptions} value={trigger} />
      </FormField>
      {fields.map((filter, fieldIndex) => {
        return (
          <React.Fragment key={fieldIndex}>
            {watch(`group.fields.children.${fieldIndex}.filterType`) !== 'group' ? (
              <>
                <SeparatorComponent fieldIndex={fieldIndex} name={'group.type'} />
                <FilterTypeContainer
                  type={watch(`group.fields.children.${fieldIndex}.value`) as string}
                  index={fieldIndex}
                  removeField={remove}
                  isGroup="filter"
                  key={filter.id}
                />
              </>
            ) : (
              <Box>
                <SeparatorComponent fieldIndex={fieldIndex} name={'group.type'} />
                <Flex gap={4}>
                  <GroupUI>
                    <Box key={filter.id} ref={element}>
                      {getValues(`group.fields.children.${fieldIndex}.fields.children`)?.map(
                        (filter, subIndex) => (
                          <>
                            <SeparatorComponent
                              fieldIndex={subIndex}
                              name={`group.fields.children.${fieldIndex}.type`}
                            />

                            <FilterTypeContainer
                              type={watch(
                                `group.fields.children.${fieldIndex}.fields.children.${subIndex}.value`,
                              )}
                              subIndex={subIndex}
                              index={fieldIndex}
                              removeField={remove}
                              isGroup="group"
                              key={subIndex}
                            />
                          </>
                        ),
                      )}
                      {addGroup && activeIndex === fieldIndex && (
                        <FilterSelection
                          setAddFilter={setAddFilter}
                          setAddGroup={setAddGroup}
                          addGroup={append}
                          addFilter={appendFields}
                          setShowButton={setEnableGroup}
                        />
                      )}

                      {getValues(`group.fields.children.${fieldIndex}.fields.children`)?.length <
                        20 && (
                        <FormButton
                          variant="secondary"
                          onClick={() => handleAddSubFilter(fieldIndex)}
                          title="Add Filter"
                        />
                      )}
                    </Box>
                  </GroupUI>
                  <IconButton
                    icon={<DeleteIcon />}
                    aria-label="delete"
                    onClick={() => {
                      remove(fieldIndex);
                      setEnableGroup(true);
                    }}
                    mt={4}
                  />
                </Flex>
              </Box>
            )}
          </React.Fragment>
        );
      })}

      {addFilter && (
        <FilterSelection
          setAddFilter={setAddFilter}
          setAddGroup={setAddGroup}
          addGroup={append}
          addFilter={appendFields}
          setShowButton={setEnableGroup}
        />
      )}
      <HStack py={2} mt={1}>
        <FormButton
          variant="secondary"
          disabled={!enableGroup || getValues(`group.fields.children`)?.length > 19}
          onClick={handleAddFilter}
          title="Add Filter"
        />
        <FormButton
          variant="secondary"
          disabled={!enableGroup || getValues(`group.fields.children`)?.length > 19}
          onClick={handleAddGroup}
          title="Add Group"
        />
      </HStack>
    </Box>
  );
};

export default FilterForm;
