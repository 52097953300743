import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { IComponentState } from 'core/interfaces/IComponentState';
import { deepCopy, pick } from 'core/helpers';
import { ServiceService } from 'core/services';
import render from './render.index';
import { IAlertSource } from 'core/interfaces/IIntegration';
import equal from 'fast-deep-equal/es6/react';
import { AutomationRuleContextProps } from '../../service.rule/context';
import { ICustomTemplate } from 'views/main/organization/service-catalog/hooks/useServiceDetail';
import { createStandaloneToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { AppTracker } from 'shared/analytics/tracker';
import {
  T_WA_UP_CUSTOM_CONTENT_TEMPLATE_CREATED,
  T_WA_UP_CUSTOM_CONTENT_TEMPLATE_EDITED,
} from 'core/const/tracker';
import { OnMount } from '@monaco-editor/react';

interface IProps extends Pick<IAppState, 'organization' | 'integrations'> {
  serviceId: string;
  setIsDirty: (isDirty: boolean) => void;
  editRuleId?: string;
  refetchAlert: () => void;
  customDetail?: ICustomTemplate;
  refetchRule: () => void;
  mode: 'view' | 'edit' | 'create' | undefined;
  editTemplate?: () => void;
  // alertsource is optional because on creating template from cta there is no default alertsource,but if user adds template from summary alert it has alertsource
  alertSource?: string;
  activeAlertSourceNames?: string[];
  updatePermission?: boolean;
  deletePermission?: boolean;
}

interface IState {
  componentState: IComponentState;
  saveState: 'idle' | 'saving' | 'saved' | 'error';
  eventState: 'loading' | 'noEvents' | 'idle' | 'default';
  event: any;
  alertSourceSearch: string;
  alertSource: IAlertSource | null;
  alertSourcesLoadState: 'loading' | 'success' | 'error';
  alertSourceErrorMessage: string;
  editRuleId: string | undefined;
  incidentMessage: string;
  incidentDesc: string;
  deleteModal: boolean;
  isDeleting: boolean;
  isSaving: boolean;
  customOutputMessage: string;
  decodedMessage: string;
  decodedDesc: string;
  isDirty: boolean;
}
const toast = createStandaloneToast();

export class CustomTemplateModal extends Component<IProps, IState, AutomationRuleContextProps> {
  private ServiceService = new ServiceService(this.props.serviceId);

  public _service = this.props.organization.services.s.find(s => s.id === this.props.serviceId);

  public alertSources: IAlertSource[] = this.props.integrations.i
    .filter(as => !as.hideAutomation && !as.deprecated)
    .map(as => ({
      isActive: false,
      ...as,
    }));

  public initialState?: IState;

  constructor(props: IProps) {
    super(props);
    this.state = {
      componentState: 'busy',
      eventState: 'default',
      event: {},
      saveState: 'idle',
      alertSourceSearch: '',
      alertSource: null,
      alertSourcesLoadState: 'loading',
      alertSourceErrorMessage: '',
      editRuleId: props.editRuleId,
      incidentMessage: '',
      incidentDesc: '',
      deleteModal: false,
      isDeleting: false,
      isSaving: false,
      customOutputMessage: '',
      decodedMessage: '',
      decodedDesc: '',
      isDirty: false,
    };
  }

  public render = render;

  public async componentDidMount() {
    if (this.props.alertSource && this.props.customDetail) {
      const alertSource = this.alertSources.find(
        (altSrc: IAlertSource) => altSrc.shortName === this.props.alertSource,
      );
      alertSource && this.getAlertSourceConfig(alertSource);
    }

    this.getActiveAlertSources();
    await this.getincidentDetail();
  }
  public deleteTemplate = async (alertname: string) => {
    this.setState({ isDeleting: true });
    try {
      await this.ServiceService.deleteNotificationTemplate(alertname);
      this.setState({ deleteModal: false, isDeleting: false });
      this.props.refetchRule();
      this.props.refetchAlert();
      const title = 'Template Deleted Successfully';
      toast({ title, status: 'success', variant: 'subtle', isClosable: true });
    } catch (error) {
      const err = error as AxiosError<{ meta: { error_message: string } }>;
      const title = err?.response?.data?.meta?.error_message || 'Template Delete Failed';
      toast({ title, status: 'error', variant: 'subtle', isClosable: true });

      this.setState({ deleteModal: false, isDeleting: false });
    }
  };

  public openDeleteModal = (open: boolean) => {
    if (open) {
      this.setState({ deleteModal: true });
    } else {
      this.setState({ deleteModal: false });
    }
  };

  updateMessageCode = (value = '') => {
    if (this.state.decodedMessage !== '') {
      this.setState({ decodedMessage: '' });
    }
    const trimmedMessage = value.trim();
    this.setState({ incidentMessage: trimmedMessage, customOutputMessage: '', isDirty: true });
  };
  updateDesc = (value = '') => {
    if (this.state.decodedDesc !== '') {
      this.setState({ decodedDesc: '' });
    }
    const trimmedDescription = value.trim();
    this.setState({ incidentDesc: trimmedDescription, customOutputMessage: '', isDirty: true });
  };

  handleEditorDidMount: OnMount = (editor, monaco) => {
    editor.updateOptions({ scrollBeyondLastLine: true });
  };

  public getActiveAlertSources = async () => {
    try {
      const { data } = await this.ServiceService.getActiveAlertSources();
      const activeAlertSources = data.data as string[];

      this.alertSources.forEach((as: IAlertSource) => {
        if (activeAlertSources.includes(as._id)) {
          as.isActive = true;
        }
      });

      // sorting according to shortName
      this.alertSources.sort((as1: IAlertSource, as2: IAlertSource) => {
        if (as1.type.toLowerCase() < as2.type.toLowerCase()) {
          return -1;
        }
        if (as1.type.toLowerCase() > as2.type.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      // sorting according to active status
      this.alertSources.sort((as1: IAlertSource, as2: IAlertSource) => {
        if (as1.isActive && !as2.isActive) {
          return -1;
        }
        if (!as1.isActive && as2.isActive) {
          return 1;
        }
        return 0;
      });

      if (this.props.alertSource && this.props.mode === 'create') {
        this.setState({ alertSourcesLoadState: 'success' });

        const alertSource = this.alertSources.find(
          (altSrc: IAlertSource) => altSrc.shortName === this.props.alertSource,
        );

        alertSource && this.getAlertSourceConfig(alertSource);
        return;
      }
      this.setState({ alertSourcesLoadState: 'success' });
    } catch (err: any) {
      this.setState({
        alertSourceErrorMessage: `Network Error: ${
          err?.response?.data?.meta?.error_message ?? 'Network Error'
        }`,
        alertSourcesLoadState: 'error',
      });
    }
  };

  public getAlertSourceConfig = async (alertSource: IAlertSource) => {
    this.setState({
      alertSource,
      eventState: 'loading',
    });

    try {
      const eventResponse = await this.ServiceService.getAlertSourceLatestEvent(alertSource._id);

      this.setState({
        eventState: 'idle',
        event: {
          payload: eventResponse.data.data.payload,
          source: alertSource.shortName,
        },
        incidentMessage:
          this.props.mode === 'view' || this.props.mode === 'edit'
            ? (this.props.customDetail?.overlay.message as string)
            : '',

        incidentDesc:
          this.props.mode === 'view' || this.props.mode === 'edit'
            ? (this.props.customDetail?.overlay.description as string)
            : '',
      });
    } catch (err: any) {
      this.setState({
        eventState: 'noEvents',
        event: {},
        incidentMessage:
          this.props.mode === 'view' || this.props.mode === 'edit'
            ? (this.props.customDetail?.overlay.message as string)
            : '',
        incidentDesc:
          this.props.mode === 'view' || this.props.mode === 'edit'
            ? (this.props.customDetail?.overlay.description as string)
            : '',
      });
    }
  };

  public getincidentDetail = async () => {
    this.setState({ componentState: 'busy' });
    try {
      this.setState(
        {
          componentState: 'idle',
        },
        () => (this.initialState = deepCopy(this.state)),
      );
    } catch (err: any) {
      console.log(err);
      this.setState({ componentState: 'error' });
    }
  };
  public getCustomIncidentOutput = async () => {
    this.setState({ isSaving: true });
    const messageData = {
      overlay_template_type: 'go',
      template: this.state.incidentMessage,
      payload: JSON.stringify(this.state.event.payload) ?? '{}',
    };
    const descData = {
      overlay_template_type: 'go',
      template: this.state.incidentDesc,
      payload: JSON.stringify(this.state.event.payload) ?? '{}',
    };
    try {
      const decoededMessage = await this.ServiceService.getCustomTeplateOutput(messageData);
      const decodedDesc = await this.ServiceService.getCustomTeplateOutput(descData);

      this.setState({
        customOutputMessage: '',
        isSaving: false,
        decodedMessage: decoededMessage.data.data,
        decodedDesc: decodedDesc.data.data,
      });
    } catch (error) {
      this.setState({
        customOutputMessage: 'Invalid Syntax Error',
        isSaving: false,
      });
    }
  };

  public save = async () => {
    this.setState({ saveState: 'saving' });

    const payload = {
      overlay_template_type: 'go',
      message_overlay: {
        template: this.state.incidentMessage,
      },
      description_overlay: {
        template: this.state.incidentDesc,
      },
    };
    try {
      await this.ServiceService.updateNotificationTemplate(
        payload,
        (this.props.alertSource as string) ?? (this.state.alertSource?.shortName as string),
      );
      const title = `Custom Template ${
        this.props.mode === 'create' ? 'Created' : 'Updated'
      } Successfully`;
      toast({ title, status: 'success', variant: 'subtle', isClosable: true });
      this.setState({ saveState: 'saved' });
      this.props.refetchAlert && this.props.refetchAlert();
      this.props.refetchRule();
      if (this.props.mode === 'create') {
        AppTracker.track(T_WA_UP_CUSTOM_CONTENT_TEMPLATE_CREATED);
      } else if (this.props.mode === 'edit') {
        AppTracker.track(T_WA_UP_CUSTOM_CONTENT_TEMPLATE_EDITED);
      }
    } catch (error) {
      const err = error as AxiosError<{ meta: { error_message: string } }>;
      const title = err.response?.data?.meta.error_message.includes('invalid template syntax')
        ? 'Invalid Syntax Error'
        : err.response?.data?.meta.error_message;

      this.setState({
        saveState: 'error',
        customOutputMessage: title as string,
      });
    }
  };

  onSelectChange = (_: any, alertSource: IAlertSource) => {
    this.getAlertSourceConfig(alertSource);
  };

  onTextChange = (type: 'alertSourceSearch') => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [type]: event.target.value });
  };

  componentDidUpdate() {
    if (
      this.initialState &&
      this.state.saveState !== 'saved' &&
      !equal(
        pick(this.state, 'incidentDesc', 'incidentMessage', 'alertSource'),
        pick(this.initialState, 'incidentDesc', 'incidentMessage', 'alertSource'),
      )
    ) {
      this.props.setIsDirty(true);
    } else {
      this.props.setIsDirty(false);
    }
  }
}

export default connect(({ organization, integrations }: IAppState) => ({
  organization,
  integrations,
}))(CustomTemplateModal);
