import styled from 'styled-components';

export const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #8450d7;
  background-image: linear-gradient(263deg, #8450d7, #237540);
`;
