import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Text: ComponentStyleConfig = {
  variants: {
    helpAndGuide_help: {
      lineHeight: 1.2,
      fontSize: 14,
    },
    helpAndGuide_query: {
      lineHeight: 1.2,
      fontSize: 12,
      fontStyle: 'italic',
    },
    grayedCaption: {
      fontSize: 'xs',
      color: '#808080',
    },
  },
};
