import { FILTER_TYPES, SearchActionKind } from '../constants/status.constants';
import { IfilterObject } from '../Interface';

// An interface for our actions
interface SearchAction {
  type: SearchActionKind;
  searchText?: string;
  isEnabled?: boolean;
  queryPageIndex?: number;
  queryPageSize?: number;
  isDrawerOpen?: boolean;
  drawerType?: string | null;
  filters?: IfilterObject;
  pageId?: number;
}

const filterInit = {
  [FILTER_TYPES.PAGE_TYPE]: [],
  [FILTER_TYPES.STATUS]: [],
};

// An interface for our state
interface StatusListState {
  search: {
    searchText: string;
    enabled: boolean;
  };
  pagination: {
    queryPageIndex: number;
    queryPageSize: number;
  };
  drawer: {
    isDrawerOpen: boolean;
    drawerType: string | null;
    pageId: number;
  };
  deleteStatusPage: {
    showModal: boolean;
    selectedPageID: number;
  };
  filters: IfilterObject;
  filterCount: number;
}

export function StatusListReducer(state: StatusListState, action: SearchAction) {
  const {
    type,
    isEnabled = false,
    searchText = '',
    queryPageIndex = 1,
    queryPageSize = 10,
    drawerType = null,
    isDrawerOpen = false,
    filters = filterInit,
    pageId = -1,
  } = action;
  switch (type) {
    case SearchActionKind.SEARCH_ENABLED_OR_DISABLED:
      return {
        ...state,
        search: {
          ...state.search,
          enabled: isEnabled,
        },
      };
    case SearchActionKind.SEARCHED:
      return {
        ...state,
        search: {
          ...state.search,
          searchText: searchText,
        },
        pagination: {
          ...state.pagination,
          queryPageIndex: 1,
        },
      };

    case SearchActionKind.PAGE_INDEX_CHANGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          queryPageIndex,
        },
      };
    case SearchActionKind.PAGE_SIZE_CHANGED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          queryPageSize,
        },
      };

    case SearchActionKind.OPEN_DRAWER:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          drawerType,
          isDrawerOpen,
          pageId,
        },
      };

    case SearchActionKind.CLOSE_DRAWER:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          drawerType: null,
          isDrawerOpen: false,
          pageId: -1,
        },
      };

    case SearchActionKind.APPLY_FILTER:
      const count = Object.values(filters).reduce((a, c) => (a = a + c.length), 0);
      return {
        ...state,
        filters,
        filterCount: count,
        pagination: {
          ...state.pagination,
          queryPageIndex: 1,
        },
      };
    case SearchActionKind.SELECT_PAGE_DELETE:
      return {
        ...state,
        deleteStatusPage: {
          showModal: true,
          selectedPageID: pageId,
        },
      };

    case SearchActionKind.REJECT_PAGE_DELETE:
      return {
        ...state,
        deleteStatusPage: {
          showModal: false,
          selectedPageID: -1,
        },
      };
    default:
      return state;
  }
}
