import * as React from 'react';
import { SVGProps } from 'react';

const SvgShortcutLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#58b1e4',
      }}
      d="M20 5a5 5 0 0 0-5-5H5a4.984 4.984 0 0 0-3.535 1.464 5 5 0 0 0 0 7.072l1.78 1.78A5.002 5.002 0 0 0 5 20h10a4.983 4.983 0 0 0 3.536-1.465 5 5 0 0 0 0-7.07l-1.781-1.782A5.002 5.002 0 0 0 20 5zM2.837 17.164a3.06 3.06 0 0 1 2.035-5.22l6.117 6.117H5a3.041 3.041 0 0 1-2.162-.897zm14.327-4.328a3.06 3.06 0 0 1-4.327 4.328l-10-10a3.06 3.06 0 0 1 4.327-4.328l10 10zm0-5.672a3.038 3.038 0 0 1-2.036.893L9.011 1.94h5.99a3.06 3.06 0 0 1 2.163 5.224z"
    />
  </svg>
);

export default SvgShortcutLogo20X20;
