import { Button, ButtonGroup, HStack, Image, Link, Text, VStack } from '@chakra-ui/react';
import { CalendarTooltipProps, TimeRange } from '@nivo/calendar';
import { ResponsiveWrapper } from '@nivo/core';
import { Grid, Para, SpinLoader } from 'uie/components';
import {
  T_WA_UP_PAST_INCIDENTS_LIST_SORTED,
  T_WA_UP_PAST_INCIDENTS_LIST_VIEWED,
  T_WA_UP_PAST_INCIDENTS_TAB_VIEWED,
} from 'core/const/tracker';
import {
  IHeatmapData,
  IPastIncident,
  IPastIncidentListParameters,
  IPastIncidentStatsResponse,
  PastIncidentsDataRanges,
} from 'core/interfaces/IIncidents';
import { Table } from 'library/molecules';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { HeaderGroup } from 'react-table';
import { AppTracker } from 'shared/analytics/tracker';
import { PAST_INCIDENTS_COLUMN_DEFS } from 'views/main/organization/incident-list/listing/column-defs';
import { changeTTRTimeFormat } from '../../helpers';

interface Props<T extends Record<string, unknown>> {
  pastIncidents: IPastIncident[];
  pastIncidentsStats: IPastIncidentStatsResponse;
  pastIncidentsHeatmap: IHeatmapData;
  pastIncidentsListParameters: IPastIncidentListParameters;
  setPastIncidentsFilter: (activeDate: string) => void;
  clearPastIncidentsFilter: () => void;
  arePastIncidentsLoading: boolean;
  incidentCreatedDate: string;
  incidentID: string;
  isPastIncidentsDataLoaded: boolean;
}

const heatmapTooltip = ({ value, day }: CalendarTooltipProps) => {
  return (
    <VStack
      w="150px"
      alignItems="flex-start"
      background="white"
      p={2}
      _after={{
        content: '""',
        position: 'absolute',
        top: '100%',
        right: '70px',
        width: '13px',
        marginLeft: '-8px',
        borderLeft: '8px solid transparent',
        borderRight: '8px solid transparent',
        borderTop: '8px solid white',
      }}
    >
      <Text variant="stats_label">
        {new Date(day).toLocaleDateString(undefined, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}
      </Text>
      <Text variant="h3_800">{value} Incidents</Text>
    </VStack>
  );
};

export const PastIncidents = <T extends Record<string, unknown>>({
  pastIncidents,
  pastIncidentsStats,
  pastIncidentsHeatmap,
  pastIncidentsListParameters,
  setPastIncidentsFilter,
  clearPastIncidentsFilter,
  arePastIncidentsLoading,
  incidentCreatedDate,
  incidentID,
  isPastIncidentsDataLoaded,
}: Props<T>) => {
  const stats180Object = pastIncidentsStats[PastIncidentsDataRanges.Days_180];
  const stats90Object = pastIncidentsStats[PastIncidentsDataRanges.Days_90];
  const stats7Object = pastIncidentsStats[PastIncidentsDataRanges.Days_7];
  const pastIncidentStat180MTTR = stats180Object ? changeTTRTimeFormat(stats180Object.mttr) : null;
  const pastIncidentStat90MTTR = stats90Object ? changeTTRTimeFormat(stats90Object.mttr) : null;
  const pastIncidentStat7MTTR = stats7Object ? changeTTRTimeFormat(stats7Object.mttr) : null;
  const [loadEventTracked, setLoadEventEventTracked] = useState(false);

  const heatMapColors = ['#DCEEFF', '#A5CBFA', '#58A6FC', '#1B7FF1'];

  const isPastIncidentsFilterActive = !!pastIncidentsListParameters.activeDate;
  const hasNextPage =
    pastIncidentsListParameters.pageOffset + 1 !== pastIncidentsListParameters.maxPages;
  const hasPreviousPage = pastIncidentsListParameters.pageOffset > 0;

  const hasValue = (day: string) => {
    return pastIncidentsHeatmap.find(data => data.date === day)?.value;
  };

  useEffect(() => {
    if (isPastIncidentsDataLoaded && !loadEventTracked) {
      AppTracker.track(T_WA_UP_PAST_INCIDENTS_TAB_VIEWED, {
        'Past Incidents Present': pastIncidents.length > 0,
        'Incident ID': incidentID,
      });
      setLoadEventEventTracked(true);
    }
  }, [isPastIncidentsDataLoaded, pastIncidents, incidentID, loadEventTracked]);

  if (!isPastIncidentsDataLoaded && arePastIncidentsLoading) {
    return (
      <Grid className="incident_details__block">
        <SpinLoader base="14px" />
        <Para className="ml-10">Loading Past Incidents</Para>
      </Grid>
    );
  }

  return (
    <Grid flexWidth={12}>
      {pastIncidents.length > 0 ? (
        <VStack w="100%" alignItems="flex-start">
          {(stats180Object || stats90Object || stats7Object) && (
            <HStack p={3} w="100%" justifyContent="space-between">
              <VStack alignItems="flex-start">
                <Text variant="stats_label">LAST 6 MONTHS</Text>
                <HStack gap={1}>
                  <Text variant="stats_number">{stats180Object.count}</Text>
                  <Text variant="body" mt={'6px !important'}>
                    Incident{stats180Object.count > 1 ? 's' : ''}
                  </Text>
                </HStack>
                <HStack gap={1}>
                  <Text variant="stats_number">
                    {pastIncidentStat180MTTR ? pastIncidentStat180MTTR : ' - '}
                  </Text>
                  <Text variant="body" mt={'6px !important'}>
                    Median TTR
                  </Text>
                </HStack>
              </VStack>
              <VStack alignItems="flex-start">
                <Text variant="stats_label">LAST 3 MONTHS</Text>
                <HStack gap={1}>
                  <Text variant="stats_number">{stats90Object.count}</Text>
                  <Text variant="body" mt={'6px !important'}>
                    Incident{stats90Object.count > 1 ? 's' : ''}
                  </Text>
                </HStack>
                <HStack gap={1}>
                  <Text variant="stats_number">
                    {pastIncidentStat90MTTR ? pastIncidentStat90MTTR : ' - '}
                  </Text>
                  <Text variant="body" mt={'6px !important'}>
                    Median TTR
                  </Text>
                </HStack>
              </VStack>
              <VStack alignItems="flex-start">
                <Text variant="stats_label">LAST 7 DAYS</Text>
                <HStack gap={1}>
                  <Text variant="stats_number">{stats7Object.count}</Text>
                  <Text variant="body" mt={'6px !important'}>
                    Incident{stats7Object.count > 1 ? 's' : ''}
                  </Text>
                </HStack>
                <HStack gap={1}>
                  <Text variant="stats_number">
                    {pastIncidentStat7MTTR ? pastIncidentStat7MTTR : ' - '}
                  </Text>
                  <Text variant="body" mt={'6px !important'}>
                    Median TTR
                  </Text>
                </HStack>
              </VStack>
            </HStack>
          )}
          <VStack
            mx={'var(--chakra-space-3) !important'}
            pl={3}
            pb={5}
            pt={10}
            w="97%"
            overflow="hidden"
            height={410}
            background="primary.100"
            alignItems="flex-start"
            //Added following style to enable pointer as the cursor for the rectangles
            _hover={{ ' & g > rect ': { cursor: 'pointer' } }}
          >
            <ResponsiveWrapper>
              {({ width, height }) => (
                <TimeRange
                  // TODO: Use other calculated number to make tooltip visible on scrollable chart during hover at the end
                  width={width - 40}
                  height={height}
                  data={pastIncidentsHeatmap.map(data => ({ day: data.date, value: data.value }))}
                  from={DateTime.fromISO(incidentCreatedDate).minus({ days: 180 }).toJSDate()}
                  to={DateTime.fromISO(incidentCreatedDate).minus({ days: 1 }).toJSDate()}
                  emptyColor="#fff"
                  colors={heatMapColors}
                  margin={{ top: 30, right: 20 }}
                  dayBorderWidth={1}
                  dayBorderColor="#F3F9FF"
                  daySpacing={4}
                  weekdayTicks={[0, 1, 2, 3, 4, 5, 6]}
                  tooltip={heatmapTooltip}
                  onClick={data => {
                    if (hasValue(data.day)) {
                      setPastIncidentsFilter(data.day);
                      AppTracker.track(T_WA_UP_PAST_INCIDENTS_LIST_VIEWED, {
                        'Incident ID': incidentID,
                      });
                    }
                  }}
                />
              )}
            </ResponsiveWrapper>

            <HStack ml="var(--chakra-space-20) !important">
              <Text variant="h4_800" color="secondary.700">
                Past Incidents : Less
              </Text>
              <ButtonGroup alignSelf="flex-start">
                {heatMapColors.map((color, index) => (
                  <Button
                    size="xs"
                    m={1}
                    key={index}
                    background={color}
                    pointerEvents="none"
                    borderRadius={0}
                  ></Button>
                ))}
              </ButtonGroup>
              <Text ml="var(--chakra-space-2) !important" variant="h4_800" color="secondary.700">
                More
              </Text>
            </HStack>
          </VStack>
          <VStack alignItems="flex-start" mt="var(--chakra-space-4) !important" w="99.3%" pl={3}>
            {!isPastIncidentsFilterActive ? (
              <Text variant="h3">
                Top {pastIncidents.length} Relevant Incident{pastIncidents.length > 1 ? 's' : ''}
              </Text>
            ) : (
              <HStack>
                <Text variant="h3">
                  Showing {pastIncidents.length} Incident{pastIncidents.length > 1 ? 's' : ''} for{' '}
                  {new Date(pastIncidentsListParameters.activeDate).toLocaleDateString(undefined, {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </Text>
                <Link onClick={clearPastIncidentsFilter}>Clear</Link>
              </HStack>
            )}
            <Table
              width="100%"
              columns={PAST_INCIDENTS_COLUMN_DEFS}
              maxPageSize={20}
              data={pastIncidents as unknown as readonly Record<string, unknown>[]}
              hidePagination={!isPastIncidentsFilterActive}
              onPageChange={(_, limit) => pastIncidentsListParameters.onPageLimitChange(limit)}
              headerStyles={{ padding: '7px 24px 7px 24px' }}
              isLoading={arePastIncidentsLoading}
              containerStyles={{
                border: '1px solid #E1E6EB',
                overflow: 'hidden',
              }}
              additionalHeaderStyles={{ lineHeight: 'inherit' }}
              initialSortByState={[pastIncidentsListParameters.sortingState]}
              onSortChange={(column: HeaderGroup<T>) => {
                if (isPastIncidentsFilterActive && (hasPreviousPage || hasNextPage)) {
                  pastIncidentsListParameters.onSortChange(
                    column.id as 'created_at' | 'incident_activity',
                    !column.isSortedDesc,
                  );
                }
                AppTracker.track(T_WA_UP_PAST_INCIDENTS_LIST_SORTED, {
                  'Incident ID': incidentID,
                  'Past Incident Column Sorted':
                    column.id === 'created_at' ? 'Created At' : 'Incident Activity',
                  'Sort Order': column.isSortedDesc ? 'Ascending' : 'Descending',
                });
              }}
              {...(isPastIncidentsFilterActive
                ? {
                    cursorPagination: true,
                    pageSize: pastIncidentsListParameters.pageLimit,
                    hasPrev: hasPreviousPage,
                    hasNext: hasNextPage,
                    onPrev: () => pastIncidentsListParameters.onPageChange('prev'),
                    onNext: () => pastIncidentsListParameters.onPageChange('next'),
                  }
                : {})}
            />
          </VStack>
        </VStack>
      ) : (
        <Grid width="100%" alignItems="center" justifyContent="center">
          <VStack>
            <Image
              style={{
                display: 'inline-block',
                height: '207px',
              }}
              src={`/icons/empty-states/all-incidents.png`}
              alt="No Incidents"
            />
            <Text variant="h2" mt={'var(--chakra-space-4) !important'}>
              No relevant past incidents to show
            </Text>
          </VStack>
        </Grid>
      )}
    </Grid>
  );
};
