export enum ICountry {
  US = 'US',
  CA = 'CA',
  AUS = 'AUS',
  IN = 'IN',
}

export enum IRoutingType {
  voicemail = 'voicemail',
  oncallRouting = 'oncall',
}

export enum INumberType {
  local = 'local',
  tollFree = 'toll-free',
}
