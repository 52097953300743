import { API } from '../../api';
import Axios from 'axios';
import { IWRCreateMessageResponse, IWRCGetMessageResponse } from '../../interfaces/IWarroom';
import { IAttachmentRequest } from 'core/interfaces/ISignUpload';

class IncidentWarroomService {
  private _api = '';

  constructor(incidentId: string) {
    this._api = `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${incidentId}/warroom`;
  }

  public createMessage = (message: string, attachments: IAttachmentRequest[]) =>
    Axios.post<IWRCreateMessageResponse>(this._api, { message, attachments });

  public getAllMessages = () => Axios.get<IWRCGetMessageResponse>(this._api);

  public getStarredMessages = () => Axios.get<IWRCGetMessageResponse>(`${this._api}/starred`);

  public getSingleMessage = (wrMessageId: string) =>
    Axios.get<IWRCreateMessageResponse>(`${this._api}/${wrMessageId}`);

  public getMessageAfter = (wrMessageId: string) =>
    Axios.get<IWRCGetMessageResponse>(`${this._api}/after?warroom_message_id=${wrMessageId}`);

  public updateMessage = (wrMessageId: string, message: string) =>
    Axios.put<IWRCreateMessageResponse>(`${this._api}/${wrMessageId}`, { message });

  public deleteMessage = (wrMessageId: string) => Axios.delete(`${this._api}/${wrMessageId}`);

  public starMessage = (wrMessageId: string, star: boolean) =>
    Axios.patch<IWRCreateMessageResponse>(`${this._api}/${wrMessageId}/star`, { star });
}

export default IncidentWarroomService;
