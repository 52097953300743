import { useQuery } from 'react-query';
import { API, ExtensionService } from 'core';
import { reactQueryConfigError } from 'views/main/organization/settings/integrations/jira-cloud/helpers/service.reactqery';
import { IExtensionMsTeamsUser } from 'core/interfaces/IExtensions';

async function getTeamsChannel() {
  const _extensionService = new ExtensionService();
  const { data } = await _extensionService.getMSTeamsConfig();
  return data.data;
}

const useCheckMsTeamStatus = () => {
  return useQuery(['msteam-status', API.config.organizationId], () => getTeamsChannel(), {
    retry: 1,
    refetchOnWindowFocus: true,
  });
};

async function getTeamsUserList() {
  const _extensionService = new ExtensionService();
  const { data } = await _extensionService.getMSTeamsGraphAPIUserList();

  const res: IExtensionMsTeamsUser[] = data.data.map(user => {
    return {
      id: user.id,
      name: user.displayName,
      email: user.mail || '',
      userRole: user.userType,
    };
  });

  return res;
}

const useGetMsTeamUserList = () => {
  return useQuery('msteam-user', () => getTeamsUserList(), {
    retry: 1,
  });
};

export { useCheckMsTeamStatus, useGetMsTeamUserList };
