import { useQuery, useQueries } from 'react-query';
import axios from 'axios';
import { API } from '../../../../../core/api';
import { reactQueryConfig } from '../contants/service.contant';
import { reactQueryConfigError } from '../helpers/helper.service';
import moment from 'moment';

export type IIncidentStatus = 'triggered' | 'acknowledged' | 'resolved' | 'suppressed';
export type IIAssigne = 'user' | 'squad' | 'escalationpolicy';
export interface IAssignedTo {
  type: IIAssigne;
  id: string;
  timeOfAssignment: Date;
}
export interface IIncident {
  id: string;
  service_id: string;
  message: string;
  event_count: number;
  organization_id: string;
  description: string;
  status: IIncidentStatus;
  timeOfCreation: Date;
  responseNotes: any[];
  assignedTo: IAssignedTo[];
  alert_source_id: string;
  relevantUsers: string[];
  relevantPeopleLogs: any[];
  created_by: string;
  sender_email: string;
  slo_id: number;
  updated_at: Date;
}

export interface IAlertSource {
  alert_sources: Array<{
    alert_source_id: string;
    short_name: string;
    open_incidents_count: number;
    last_incident_at: string;
  }>;
}

const common = {
  onError: reactQueryConfigError,
  ...reactQueryConfig,
  refetchIntervalInBackground: false,
  retry: 0,
};

const fromDate = moment().subtract(1, 'weeks').toDate();
const toDate = moment().toDate();
const fromDateISO = moment().subtract(1, 'weeks').toISOString();
const toDateISO = moment().endOf('day').toISOString();
const limit = 30;

const getIncidentForExpanded = async (teamID: string | unknown, serviceID: string) => {
  const { data } = await axios.get<{ data: { incidents: IIncident } }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents?limit=${limit}&selectedUnit=30&incidentsFor=all&from=0&status[]=acknowledged&status[]=resolved&status[]=suppressed&status[]=triggered&services[]=${serviceID}&postmortem=&customDates=true&fromDate=${fromDateISO}&toDate=${toDateISO}&textFilter=&owner_id=${teamID}`,
  );
  return data.data;
};

const getAutomationForExpanded = async (teamID: string | unknown, serviceID: string) => {
  const { data } = await axios.get<{ data: { suppressed: number; duplicated: number } }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/catalog-services/${serviceID}/metrics/automation?from_date=${fromDateISO}&to_date=${toDateISO}`,
  );
  return data.data;
};

const getAlertSourcesForExpanded = async (teamID: string | unknown, serviceID: string) => {
  const { data } = await axios.get<{
    data: IAlertSource;
  }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/catalog-services/${serviceID}/metrics/alert-sources`,
  );
  return data.data;
};

const getMentainenceForExpanded = async (teamID: string | unknown, serviceID: string) => {
  const { data } = await axios.get<{
    data: Array<{
      deleted: boolean;
      maintenanceFrom: string;
      maintenanceTill: string;
      repeatTill: string;
      repetitionDaily: boolean;
      repetitionMonthly: boolean;
      repetitionThreeWeekly: boolean;
      repetitionTwoWeekly: boolean;
      repetitionWeekly: boolean;
    }>;
  }>(
    `${API.config.endpoint}/organizations/${API.config.organizationId}/services/${serviceID}/maintenance`,
  );
  return data.data;
};

export function useServiceExpandedList(serviceID: string, teamId: string | unknown) {
  const expandedData = useQueries([
    {
      queryKey: ['incident', serviceID, teamId],
      queryFn: () => getIncidentForExpanded(teamId, serviceID),
      ...common,
    },
    {
      queryKey: ['automation', serviceID, teamId],
      queryFn: () => serviceID.length && getAutomationForExpanded(teamId, serviceID),
      ...common,
    },
    {
      queryKey: ['alertSources', serviceID, teamId],
      queryFn: () => serviceID.length && getAlertSourcesForExpanded(teamId, serviceID),
      ...common,
    },
    {
      queryKey: ['mentainence', serviceID, teamId],
      queryFn: () => serviceID.length && getMentainenceForExpanded(teamId, serviceID),
      ...common,
    },
  ]);

  return { expandedData };
}

/**
 * Get Alert Sources of a service
 * @param serviceId Service ID
 * @param teamId Team Id
 * @returns Alert sources of a service
 */
export const useServiceAlertSourcesQuery = (serviceId: string, teamId: string) => {
  return useQuery(
    ['alertSources', serviceId],
    () => getAlertSourcesForExpanded(teamId, serviceId),
    {
      ...common,
      enabled: !!serviceId && !!teamId,
    },
  );
};

/**
 * Get Automation Rules of multiple services
 * @param serviceIds Service Id List
 * @param teamId Team Id
 * @returns Automation Rules of multiple services
 */
export const useMultiServiceAutomationRulesQuery = (serviceIds: string[], teamId: string) => {
  return useQueries(
    serviceIds.map(service => ({
      queryKey: ['automation', service, teamId],
      queryFn: () => getAutomationForExpanded(teamId, service),
      ...common,
      enabled: !!service && !!teamId,
      select: (res: { suppressed: number; duplicated: number }) => {
        return { ...res, serviceId: service };
      },
    })),
  );
};
