import React from 'react';
import { Container, HStack, Tag, TagLabel, Text } from '@chakra-ui/react';
import { NewTag } from 'views/main/organization/schedules/graphql/types';
import { NodePopover } from 'components/chakra/Popover';
import { truncate } from 'core/helpers/stringUtils';
import SQTooltip from 'components/chakra/Tooltip';

const ScheduleTags = (props: {
  tags: Array<NewTag>;
  maxTagsCount: number;
  tagSize?: string;
  textColor?: string;
  popoverContainerMaxWidth?: string;
}) => {
  const { tags, maxTagsCount, tagSize, textColor, popoverContainerMaxWidth } = props;

  const visibleTags = tags.length > maxTagsCount ? tags.slice(0, maxTagsCount) : tags;
  const remainingTags = tags.length > maxTagsCount ? tags.slice(maxTagsCount) : [];

  return (
    <HStack>
      {visibleTags.map((tag, index) => (
        <Tag
          key={index}
          size={tagSize ?? 'sm'}
          backgroundColor={tag.color ?? ''}
          borderRadius="6px"
          color={textColor ?? 'gray.180'}
        >
          <SQTooltip text={`${tag?.key} : ${tag?.value}`}>
            <Text fontWeight="normal" width={'fit-content'}>
              {truncate([tag.key, tag.value].join(' : '), 40)}
            </Text>
          </SQTooltip>
        </Tag>
      ))}
      {remainingTags.length && (
        <NodePopover
          triggerType="hover"
          trigger={
            <Tag
              size={tagSize ?? 'sm'}
              backgroundColor={remainingTags[0].color ?? ''}
              borderRadius="6px"
              color={textColor ?? 'gray.180'}
            >
              <TagLabel>+ {remainingTags.length}</TagLabel>
            </Tag>
          }
        >
          <Container maxW={popoverContainerMaxWidth ?? 'md'}>
            {remainingTags.map((tag, index) => (
              <Tag
                key={index}
                size={tagSize ?? 'sm'}
                backgroundColor={tag.color ?? ''}
                borderRadius="6px"
                color={textColor ?? 'gray.180'}
                m={1}
              >
                <TagLabel fontWeight="normal">{[tag.key, tag.value].join(' : ')}</TagLabel>
              </Tag>
            ))}
          </Container>
        </NodePopover>
      )}
    </HStack>
  );
};

export default ScheduleTags;
