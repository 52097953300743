import { useDisclosure } from '@chakra-ui/hooks';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Grid } from 'uie/components';
import { T_WA_UP_IAG_VOTE_DOWN, T_WA_UP_IAG_VOTE_UP } from 'core/const/tracker';
import useQueryParams from 'core/hooks/useQueryParams';
import { IAutoGroupAlert, VOTE_TYPE } from 'core/interfaces/IIncidents';
import { queryUpdate } from 'core/util';
import { IconButton, Tooltip, useToast } from 'library/atoms';
import { VoteDown, VoteDownGrey, VoteUp, VoteUpGrey } from 'library/icons';
import { Loader, Placeholder, Table } from 'library/molecules';
import { FC, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Cell } from 'react-table';
import { AppTracker } from 'shared/analytics/tracker';
import { AUTO_GROUP_ALERT_COLUMN_DEFS } from 'views/main/organization/incident-list/listing/column-defs';

import { AUTO_GROUP_TAB_NAME } from '../../constants';
import { ID_QUERY_PARAM_KEYS } from '../../enums';
import {
  invalidateAutoGroupedAlertsList,
  useGetAutoGroupedAlertsList,
} from '../../query/useGetAutoGroupedAlerts';
import { voteDownAlert } from '../../query/useVoteDownAlert';
import { voteUpAlert } from '../../query/useVoteUpAlert';
import { AlertDetails } from './alertDetails';
import { VoteDownAlertFC } from './voteDown';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

interface Props {
  incidentId: string;
}

export const InitialPaginationState = {
  pageLimit: 10,
  pageIndex: 0,
};

export const AutoGroupedAlerts: FC<Props> = ({ incidentId }) => {
  const [limit, setLimit] = useState(InitialPaginationState.pageLimit);
  const [pageIndex, setPageIndex] = useState(InitialPaginationState.pageIndex);
  const [alertId, setAlertId] = useState<string>('');
  const [voteType, setVoteType] = useState<VOTE_TYPE>(null);

  const history = useHistory();
  const query = useQueryParams();
  const tabQuery = query.get(ID_QUERY_PARAM_KEYS.TAB_NAME);
  const alertQuery = query.get(ID_QUERY_PARAM_KEYS.ALERT_ID);
  const {
    isOpen: isAlertDetailsOpen,
    onOpen: onAlertDetailsOpen,
    onClose: onAlertDetailsClose,
  } = useDisclosure();

  const onAlertDrawerClose = () => {
    updateAlertFromQuery(null);
    onAlertDetailsClose();
  };

  useEffect(() => {
    if (!tabQuery) {
      queryUpdate(query, AUTO_GROUP_TAB_NAME, ID_QUERY_PARAM_KEYS.TAB_NAME);
      history.push({ search: query.toString() });
    }

    return () => {
      queryUpdate(query, null, ID_QUERY_PARAM_KEYS.TAB_NAME);
      history.push({ search: query.toString() });
    };
  }, []);

  const updateAlertFromQuery = (alertId: string | null) => {
    queryUpdate(query, alertId, ID_QUERY_PARAM_KEYS.ALERT_ID);
    history.push({ search: query.toString() });
  };

  const handler = (pageNumber: number, pageLimit: number) => {
    if (limit != pageLimit) {
      setPageIndex(InitialPaginationState.pageIndex);
      setLimit(pageLimit);
    }
    if (pageIndex != pageNumber) {
      setPageIndex(pageNumber);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();

  const onAPISuccess = (eventName: string) => {
    setVoteType(null);
    AppTracker.track(eventName);
    invalidateAutoGroupedAlertsList(queryClient);
  };

  const onError = (err: any) => {
    const errMsg = err?.response?.data?.meta.error_message ?? `Unable to vote`;

    toast({
      status: 'error',
      text: `Error: ${errMsg}`,
    });
    setVoteType(null);
    console.error(err);
  };

  const onVoteDown = () => {
    setVoteType('negative');
    voteDownAlert(incidentId, alertId)
      .then(() => {
        onAPISuccess(T_WA_UP_IAG_VOTE_DOWN);
      })
      .catch(error => {
        onError(error);
      })
      .finally(() => {
        setAlertId('');
        onClose();
      });
  };

  const onVoteUp = (id: string) => {
    setVoteType('positive');
    voteUpAlert(incidentId, id)
      .then(() => {
        onAPISuccess(T_WA_UP_IAG_VOTE_UP);
      })
      .catch(error => {
        onError(error);
      });
  };

  const AutoGroupAlertAction = ({ rowData }: { rowData: Partial<Cell<IAutoGroupAlert>> }) => {
    const rowVoteType: VOTE_TYPE = rowData.row?.original.vote_type ?? null;
    const alertId = rowData.row?.original.id ?? '';

    const onClickVoteDown = () => {
      setAlertId(alertId);
      onOpen();
    };

    const onClickVoteUp = () => {
      onVoteUp(alertId);
    };

    const hasUpdateAccess = useUserAccess().hasUpdateAccess('incidents');

    return (
      <Flex gap={2} alignItems="center" width="max-content" position="relative">
        {rowVoteType ? (
          <>
            {rowVoteType === 'positive' ? <VoteUpGrey /> : <VoteDownGrey />}
            <Text variant="body_800" color="secondary.700">
              Voted {rowVoteType === 'positive' ? 'Up' : 'Down'}
            </Text>
          </>
        ) : (
          <>
            <Tooltip label="This action cannot be undone" placement="bottom">
              <NoPermissionTooltip isDisabled={hasUpdateAccess}>
                <IconButton
                  aria-label="vote up"
                  onClick={onClickVoteUp}
                  icon={<VoteUp />}
                  disabled={voteType === 'negative' || !hasUpdateAccess}
                  isLoading={voteType === 'positive'}
                />
              </NoPermissionTooltip>
            </Tooltip>
            <Tooltip label="This action cannot be undone" placement="bottom">
              <NoPermissionTooltip isDisabled={hasUpdateAccess}>
                <IconButton
                  onClick={onClickVoteDown}
                  aria-label="vote down"
                  icon={<VoteDown />}
                  disabled={voteType === 'positive' || !hasUpdateAccess}
                />
              </NoPermissionTooltip>
            </Tooltip>
          </>
        )}
      </Flex>
    );
  };

  const columnDefs = useMemo(() => AUTO_GROUP_ALERT_COLUMN_DEFS(incidentId), [incidentId]);

  const { isLoading, isFetching, isError, alertsData, count } = useGetAutoGroupedAlertsList(
    incidentId,
    pageIndex,
    limit,
  );

  useEffect(() => {
    if (alertQuery && !isAlertDetailsOpen && Array.isArray(alertsData)) {
      const matchedRule = alertsData.find(data => data.id == alertQuery);
      if (matchedRule) {
        updateAlertFromQuery(alertQuery);
        onAlertDetailsOpen();
      } else {
        updateAlertFromQuery(null);
      }
    }
  }, [alertQuery, alertsData]);

  if (isLoading) {
    return (
      <Box width="full" bg="container.background">
        <Loader />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box width="full" height="full" bg="container.background">
        <Placeholder description="Failed to load alerts data!" />
      </Box>
    );
  }

  return (
    <Grid
      flexWidth={12}
      style={{
        marginTop: 'var(--chakra-space-3)',
      }}
    >
      <Table
        hoverView={<AutoGroupAlertAction rowData={{}} />}
        hoverViewStyling={{
          right: '16px',
        }}
        width="100%"
        columns={columnDefs}
        data={alertsData as unknown as readonly Record<string, unknown>[]}
        totalCount={count}
        maxPageSize={20}
        pageSize={limit}
        pageIndex={pageIndex}
        isLoading={isFetching}
        onPageChange={handler}
        headerStyles={{ padding: '14px 24px 14px 24px' }}
        containerStyles={{
          overflow: 'unset',
          '& thead': {
            position: 'sticky',
            top: 0,
            zIndex: 1,
            background: 'white',
          },
        }}
      />
      <VoteDownAlertFC
        isOpen={isOpen}
        onClose={onClose}
        onVoteDown={onVoteDown}
        isVoting={voteType != null}
      />
      {alertQuery && (
        <AlertDetails
          incidentId={incidentId}
          alertId={alertQuery}
          isOpen={isAlertDetailsOpen}
          onClose={onAlertDrawerClose}
        />
      )}
    </Grid>
  );
};
