import { SimpleMDEEditorProps } from '@SquadcastHub/react-simplemde-editor';
import cx from 'classnames';
import React, {
  InputHTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';

import { CautionIcon } from '../../utils/icons';
import Grid from '../Grid';
import { passedOptionMerger } from '../MdBlock';
import { MDE } from '../MdBlock/MdBlockStyle';
import { createFileUploadIcon, dynamicDropdownHandler } from '../MdBlock/mdeOptionHelper';

interface IShell {
  height?: string | '52px';
  width?: string | '100%';
  type?: 'regular' | 'reverse';
  error?: boolean;
  noDivider?: boolean | false;
  alignItems?: 'center' | 'flex-end' | 'flex-start';
}

const Shell = styled.div<IShell>`
  height: ${props => props.height || '52px'};
  width: ${props => props.width || '100%'};
  background: ${props => props.theme.shades.white};
  box-shadow: 0 0 0 1px
    ${props => (props.error ? props.theme.danger.default : props.theme.shades.lightGrey)};
  flex-direction: ${props => (props.type === 'reverse' ? 'row-reverse' : 'row')};
  align-items: ${props => props.alignItems || 'center'};
  border-radius: 3px;
  border: none;
  display: flex;
  justify-content: space-between;
  outline: none;
  &:hover:not(.disabled),
  &.focus:not(.disabled) {
    box-shadow: 0 0 0 1px
      ${props => (props.error ? props.theme.danger.default : props.theme.primary.default)};
  }
  &.focus {
    box-shadow: 0 0 0 1px ${props => props.theme.primary.default};
  }
  &.disabled {
    opacity: 0.5;
    cursor: no-drop !important;
  }
`;

const HookHolder = styled.div<IShell>`
  cursor: pointer;
  height: fit-content;
  padding-left: 16px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: ${props => props.width || 'fit-content'};
  justify-content: ${props => (props.type === 'reverse' ? 'flex-start' : 'flex-end')};
  ${props => (props.type === 'reverse' ? 'border-right' : 'border-left')}: 2px solid ${props =>
    props.error ? props.theme.danger.default : props.theme.shades.lightGrey};
  ${props => (props.noDivider ? 'border: none;' : '')}
  &:hover:not(.disabled), &.focus:not(.disabled), ${Shell}:hover:not(.disabled) & {
    ${props => (props.type === 'reverse' ? 'border-right' : 'border-left')}: 2px solid ${props =>
      props.error ? props.theme.danger.default : props.theme.primary.default};
    ${props => (props.noDivider ? 'border: none;' : '')}
  }
  &.disabled {
    opacity: 0.5;
    cursor: no-drop !important;
  }
`;

const Input = styled.input`
  color: ${props => props.theme.font.default};
  font-family: ${props => props.theme.fontFamily};
  cursor: text;
  height: 100%;
  font-size: 16px;
  line-height: 22px;
  border: none;
  width: inherit;
  outline: none;
  padding: 0 16px;
  margin: 0;
  &:disabled {
    opacity: 0.33;
    cursor: no-drop;
    color: ${props => props.theme.font.disabled};
    background: ${props => props.theme.shades.white};
  }
  &::placeholder {
    color: ${props => props.theme.font.label};
  }
`;

interface IInputComboBox extends InputHTMLAttributes<HTMLInputElement> {
  height?: string | '52px';
  width?: string | '100%';
  type?: 'regular' | 'reverse';
  elementHook: ReactElement;
  hookWidth?: string | 'fit-content';
  error?: boolean;
  noDivider?: boolean | false;
  alignItems?: 'flex-end' | 'flex-start' | 'center';
}

const InputComboBlock = ({
  height,
  width,
  type,
  className,
  elementHook,
  hookWidth,
  error,
  alignItems,
  ...props
}: IInputComboBox) => {
  const [focus, setFocus] = useState(false);

  const onFocus = () => setFocus(true);
  const onBlur = () => setFocus(false);

  return (
    <Shell
      className={cx({ focus, disabled: props.disabled })}
      {...{ height, width, type, error, alignItems }}
    >
      <Grid flexWidth={12}>
        <Input {...props} onFocus={onFocus} onBlur={onBlur} />
        {error && <CautionIcon style={{ marginRight: 16 }} height={16} width={16} />}
      </Grid>
      <HookHolder
        width={hookWidth}
        noDivider={props.noDivider}
        className={cx({ focus, disabled: props.disabled })}
        error={error}
        type={type}
      >
        {elementHook}
      </HookHolder>
    </Shell>
  );
};

interface IInputComboBoxWithMde extends SimpleMDEEditorProps {
  height?: string | '150px';
  maxHeight?: string | '200px';
  minHeight?: string | '100px';
  width?: string | '100%';
  type?: 'regular' | 'reverse';
  elementHook: ReactElement;
  hookWidth?: string | 'fit-content';
  error?: boolean;
  noDivider?: boolean | false;
  alignItems?: 'flex-end' | 'flex-start' | 'center';
  padding?: string | '16px';
  fontFamily?: string | 'theme.fontFamily';
  monoFontFamily?: string | 'theme.monospaceFontFamily';
  inputClassName?: string;
}
// IInputComboBoxWithMde
//
const InputComboBlockWithMde = React.forwardRef((props: IInputComboBoxWithMde, ref: any) => {
  const {
    height,
    width,
    type,
    className,
    inputClassName,
    elementHook,
    hookWidth,
    error,
    alignItems,
    noDivider,
    events,
    options,
    ...restprops
  } = props;
  const [focus, setFocus] = useState(false);
  const onFocus = () => setFocus(true);
  const onBlur = () => setFocus(false);
  const optionToUse = useMemo(() => passedOptionMerger(options), [options]);
  const giveParentToolbar = useCallback((): HTMLElement => {
    return ref?.current?.simpleMde.codemirror.getWrapperElement().parentElement;
  }, [ref]);
  useEffect(() => {
    const mde = ref?.current.simpleMde;
    const toolbar = giveParentToolbar();
    const uploadFileButton: HTMLElement | null = toolbar.querySelector('button.upload-file');
    // if there is a file uploadOption then create a file input element
    if (uploadFileButton) {
      createFileUploadIcon(uploadFileButton);
    }

    const dropdown =
      mde?.options.toolbar && mde?.options.toolbar.find((item: any, _: number) => item.children);
    // if there are any toolbar in dropdown then only plcae the cusor activity
    if (dropdown) {
      dynamicDropdownHandler(mde, giveParentToolbar);
    }
    return () => {
      mde?.codemirror.off('cursorActivity', () => {
        return;
      });
    };
  }, [giveParentToolbar, ref]);

  return (
    <Shell
      className={cx(className || '', { focus })}
      {...{ height, width, type, error, alignItems }}
    >
      <Grid flexWidth={12}>
        <MDE
          {...restprops}
          options={optionToUse}
          height={height || '150px'}
          hideborders="true"
          fontFamily="SansSerif"
          className={cx(inputClassName || '', { focus })}
          events={{
            ...events,
            focus: onFocus,
            blur: onBlur,
          }}
          ref={ref}
        />
        {error && <CautionIcon style={{ marginRight: 16, marginTop: 16 }} />}
      </Grid>
      <HookHolder
        width={hookWidth}
        noDivider={noDivider}
        className={cx({ focus })}
        error={error}
        type={type}
      >
        {elementHook}
      </HookHolder>
    </Shell>
  );
});

/** @component */
export default InputComboBlock;
export { InputComboBlockWithMde };
