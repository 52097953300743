import useQueryParams from 'core/hooks/useQueryParams';
import { queryUpdate } from 'core/util';
import { useHistory } from 'react-router-dom';
import { ALERT_API_INFO } from '../types';
import { QUERY_PARAM_KEYS } from '../util';
import { IAppState } from 'core/interfaces/IAppState';
import { useSelector } from 'react-redux';

export const useMutateAlertQuery = () => {
  const history = useHistory();
  const query = useQueryParams();

  const updateAlert = (alert?: ALERT_API_INFO) => {
    queryUpdate(query, alert?.version, QUERY_PARAM_KEYS.ALERT_VERSION);
    queryUpdate(query, alert?.shortName, QUERY_PARAM_KEYS.ALERT_NAME);

    history.replace({ search: query.toString() });
  };

  return {
    updateAlert,
  };
};

const useGerPlan = () => {
  const gerPlan = useSelector((state: IAppState) => state.organization.plan.p?.rules.ger);
  const gerOrg = useSelector((state: IAppState) => state.organization);

  return { gerPlan, gerOrg };
};

export { useGerPlan };
