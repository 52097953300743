import { API, exception } from 'core';
import { useToast } from 'library/atoms';
import { Loader } from 'library/molecules';
import { useContext, useEffect } from 'react';

import { IL_REACT_QUERY_CONFIG, INVAL_COUNT } from '../common/constants';
import { INCIDENT_STATUS } from '../common/enums';
import { getIncidentStatusType, getTimeZone } from '../common/util';
import { filterManager } from '../filters/manager';
import { useCountIncidentsQuery, useListIncidentsQuery } from '../graphql/query';
import { IncidentList } from '../listing';
import { ActionKind, IncidentListContext, INITIAL_INCIDENT_LIST_STATE } from '../store';
import { getILPageLimit } from '../store/persistent-storage';
import { Placeholder } from './Placeholder';
import { useSession } from '../behaviors/Session';

type Props = {
  status: INCIDENT_STATUS;
  onListingUpdate: () => void;
  onCreate?: () => void;
};

export const IncidentTab = ({ status, onListingUpdate, onCreate }: Props) => {
  const toast = useToast();

  const statuses = status === INCIDENT_STATUS.ALL ? [] : getIncidentStatusType(status);
  const { state, dispatch } = useContext(IncidentListContext);
  const {
    session: { orgState: organization },
  } = useSession();
  const timeZone = getTimeZone(organization);
  const shouldCursorReset = filterManager.checkCursorResetStatus();

  useEffect(() => {
    if (shouldCursorReset) {
      dispatch({ type: ActionKind.RESET_CURSOR });
    }
  }, [shouldCursorReset]);

  const { isFetching: isCountFetching, data: countData } = useCountIncidentsQuery(
    {
      input: {
        teamID: API.config.teamId,
        filters: {
          ...filterManager.getFiltersData(timeZone),
          ...(state.search.isEnabled ? { text: state.search.term } : null),
        },
        statuses: getIncidentStatusType(status),
      },
    },
    {
      ...{ ...IL_REACT_QUERY_CONFIG, refetchOnWindowFocus: true },
      onError: err => {
        exception.handle('E_GET_INCIDENT_COUNT', err);
      },
      enabled: state.canAutoRefresh,
    },
  );

  const { isFetching, isLoading, data, isError } = useListIncidentsQuery(
    {
      input: {
        teamID: API.config.teamId,
        pageSize: getILPageLimit(),
        filters: {
          ...filterManager.getFiltersData(timeZone),
          ...(state.search.isEnabled ? { text: state.search.term } : null),
        },
        statuses,
        sortBy: state.sortBy,
        cursorValue: filterManager.checkCursorResetStatus()
          ? INITIAL_INCIDENT_LIST_STATE.cursor[status]
          : state.cursor[status],
      },
    },
    {
      ...{ ...IL_REACT_QUERY_CONFIG, refetchOnWindowFocus: true },
      onError: err => {
        toast({
          status: 'error',
          text: `The requested data could not be retrieved. We apologize for the inconvenience. Please try again later.`,
        });
        exception.handle('E_GET_INCIDENT_LIST', err);
      },
      onSuccess: () => {
        onListingUpdate();
      },
      enabled: state.canAutoRefresh,
    },
  );

  const updateCursor = (value: string) => {
    if (state.cursor[status] !== value) {
      dispatch({ type: ActionKind.SET_CURSOR_FOR_STATUS, payload: { status, cursor: value } });
    }
  };

  if (isLoading) return <Loader />;

  if (isError) {
    return (
      <Placeholder
        errorMsg="Apologies, data retrieval failed. Please try again later."
        status={status}
      />
    );
  }

  if (!data?.listIncidents.data.length) {
    return <Placeholder onCreate={onCreate} status={status} />;
  }

  return (
    <IncidentList
      setSelectedRowIds={checkIds => {
        dispatch({ type: ActionKind.BULK_SELECT, payload: { select_for_all: checkIds } });
      }}
      selectedRowIds={state.bulk.select_for_all}
      rows={data?.listIncidents.data ?? []}
      status={status}
      isLoadingIncidents={isFetching}
      count={countData?.countIncidents.totalCount ?? INVAL_COUNT}
      isCountFetching={isCountFetching}
      cursorMeta={data?.listIncidents.meta ?? {}}
      onPageChange={updateCursor}
    />
  );
};
