import {
  REQUEST_LOGIN_SUCCESS,
  REQUEST_LOGIN,
  REQUEST_LOGIN_FAIL,
  REQUEST_USER_LOGOUT,
} from '../../const/auth';
import { INITIAL_STATE } from '../state';
import {
  REQUEST_ACCOUNT_PASSWORD_CHANGE,
  REQUEST_ACCOUNT_PASSWORD_CHANGE_SUCCESS,
  REQUEST_ACCOUNT_PASSWORD_CHANGE_FAIL,
} from '../../const/account';
import { RESET_STATE } from '../../const/init';

const initialAuthState = INITIAL_STATE.auth;

const auth = (state = initialAuthState, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ action: RESET_STATE, sessionId: '', error: { type: '', reason: {} } },
      };
    case REQUEST_LOGIN:
      return {
        ...state,
        ...{ action: REQUEST_LOGIN, sessionId: '', error: { type: '', reason: {} } },
      };
    case REQUEST_LOGIN_SUCCESS:
      return {
        ...state,
        ...{
          action: REQUEST_LOGIN_SUCCESS,
          sessionId: action.payload.sessionId,
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_LOGIN_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_LOGIN_FAIL,
          sessionId: '',
          error: { type: REQUEST_LOGIN_FAIL, reason: { message: action.payload.error_message } },
        },
      };
    case REQUEST_USER_LOGOUT:
      return {
        ...state,
        ...{
          action: REQUEST_USER_LOGOUT,
          sessionId: '',
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ACCOUNT_PASSWORD_CHANGE:
      return {
        ...state,
        ...{ action: REQUEST_ACCOUNT_PASSWORD_CHANGE },
      };
    case REQUEST_ACCOUNT_PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        ...{ action: REQUEST_ACCOUNT_PASSWORD_CHANGE_SUCCESS },
      };
    case REQUEST_ACCOUNT_PASSWORD_CHANGE_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_ACCOUNT_PASSWORD_CHANGE_FAIL,
          sessionId: state.sessionId,
          error: { type: REQUEST_ACCOUNT_PASSWORD_CHANGE_FAIL, reason: action.payload },
        },
      };
    default:
      return state;
  }
};

export default auth;
