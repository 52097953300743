import request from '../commons';
import { API } from '../../api';

function getOrgUserAcl() {
  const req = request.get(API.config.batman)(
    `organizations/${API.config.organizationId}/teams/acl`,
  );
  return req;
}

export { getOrgUserAcl };
