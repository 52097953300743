import { Box, CircularProgress, HStack, Text, VStack } from '@chakra-ui/react';
import Table, { HoverComponentProps } from 'library/molecules/Tablev2/Table';
import { EmptyExportHistory, EmptyLiveLogs } from 'icons';
import React, { useMemo } from 'react';
import { useAuditLogsListContext } from '../../context/auditLogsContext';
import { openInNewTab } from '../../helpers';
import { useAuditLogsTable, useExportHistoryTable } from '../../hooks/tableHooks';
import { AUDITLOGS_TABS } from '../../index.auditLogs';
import { AuditLogsTableProps, EXPORT_HISTORY_ENUM } from '../../types/index.auditLogs';
import { FormButton } from 'library/atoms';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_AUDIT_LOGS_EXPORT_DOWNLOADED } from 'core/const/tracker';

function AuditLogsTable(props: AuditLogsTableProps) {
  const AuditLogsColumns = useAuditLogsTable();

  const { status } = props;
  const auditlogsContext = useAuditLogsListContext();
  const ExportHistoryColumns = useExportHistoryTable();

  const downloadData = (link: string, id: string) => {
    AppTracker.track(T_WA_AUDIT_LOGS_EXPORT_DOWNLOADED, {
      'Audit Logs Export ID': id,
    });
    openInNewTab(link);
  };

  const HoverComponent = (props: HoverComponentProps<any>) => {
    if (status === AUDITLOGS_TABS.LIVE_LOGS) {
      return (
        <HStack gap="0.5rem">
          <FormButton
            onClick={() => auditlogsContext.toggleLogsDrawer(props.row.original.id)}
            title="View Logs"
          />
        </HStack>
      );
    } else if (status === AUDITLOGS_TABS.EXPORT_HISTORY && props.row.original.downloadLink.length) {
      return (
        <HStack gap="0.5rem">
          <FormButton
            disabled={props.row.original.status !== EXPORT_HISTORY_ENUM.COMPLETED}
            onClick={() => {
              downloadData(props.row.original.downloadLink, props.row?.original.id);
            }}
            title="Download"
          />
        </HStack>
      );
    }
    return <></>;
  };
  const hasData = useMemo(() => {
    if (status === AUDITLOGS_TABS.LIVE_LOGS)
      return Boolean(auditlogsContext.auditLogsData.metadata.totalCount);
    else return Boolean(auditlogsContext.exportHistoryData.metadata.totalCount);
  }, [status, auditlogsContext]);

  const renderEmptySection = useMemo(() => {
    if (status === AUDITLOGS_TABS.LIVE_LOGS) return <EmptyLiveLogs height={500} width={500} />;
    else return <EmptyExportHistory height={500} width={500} />;
  }, [status, auditlogsContext]);

  const renderBody = useMemo(() => {
    if (auditlogsContext.dataLoading || auditlogsContext.loadingHistory)
      return (
        <VStack style={{ marginTop: '20%' }}>
          <CircularProgress isIndeterminate />
        </VStack>
      );
    else if (
      !(auditlogsContext.dataLoading || auditlogsContext.loadingHistory) &&
      ((auditlogsContext?.auditLogsData?.metadata.totalCount === 0 &&
        status === AUDITLOGS_TABS.LIVE_LOGS) ||
        (auditlogsContext?.exportHistoryData.metadata.totalCount === 0 &&
          status === AUDITLOGS_TABS.EXPORT_HISTORY))
    ) {
      return <HStack style={{ justifyContent: 'center' }}>{renderEmptySection}</HStack>;
    } else if (
      !(auditlogsContext.dataLoading || auditlogsContext.loadingHistory) &&
      (auditlogsContext?.auditLogsData?.metadata.totalCount !== 0 ||
        auditlogsContext?.exportHistoryData.metadata.totalCount !== 0)
    ) {
      return (
        <Table
          pageCount={
            status === AUDITLOGS_TABS.LIVE_LOGS
              ? auditlogsContext.auditLogsData.metadata.totalCount
              : auditlogsContext.exportHistoryData.metadata.totalCount
          }
          pageIndex={auditlogsContext.paginationInfo.pageIndex}
          pageSize={auditlogsContext.paginationInfo.pageSize}
          onPaginationChange={auditlogsContext.setPagination}
          isLoading={
            status === AUDITLOGS_TABS.LIVE_LOGS
              ? auditlogsContext.dataLoading
              : auditlogsContext.loadingHistory
          }
          columns={status === AUDITLOGS_TABS.LIVE_LOGS ? AuditLogsColumns : ExportHistoryColumns}
          showNumberBasedPagination={true}
          data={
            status === AUDITLOGS_TABS.LIVE_LOGS
              ? auditlogsContext?.auditLogsData?.data
              : auditlogsContext?.exportHistoryData.data
          }
          hoverComponent={props => <HoverComponent {...props} />}
        />
      );
    }
  }, [auditlogsContext, status]);

  return <Box mt="12px">{renderBody}</Box>;
}

export default AuditLogsTable;
