import React from 'react';
import { Grid, Para, Theme } from 'uie/components';
import { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';
import { SelectOption } from '../types';
import ReactSelect, { components } from 'react-select';
import CreatableReactSelect from 'react-select/creatable';

type Props = {
  options?: SelectOption[];
  value?: SelectOption[];
  onChange?: (
    newValue: OnChangeValue<SelectOption, true>,
    actionMeta: ActionMeta<SelectOption>,
  ) => void;
  isLoading?: boolean;
  placeholder?: string;
  heading?: string;
  controlShouldRenderValue?: boolean;
  isClearable?: boolean;
  isMulti?: true;
  hideSelectedOptions?: boolean;
  closeDropdownOnSelect?: boolean;
  closeContainerDropdown?: () => void;
};

const { Option } = components;
const IconOption = (props: any) => (
  <Option {...props}>
    <Grid>
      {props.data.Icon ? (
        <Grid style={{ marginRight: '10px' }}>
          <props.data.Icon />
        </Grid>
      ) : null}
      <Grid>{props.data.label}</Grid>
    </Grid>
  </Option>
);

export const Select: React.FC<Props> = ({
  closeDropdownOnSelect,
  closeContainerDropdown,
  onChange,
  hideSelectedOptions,
  ...props
}) => (
  <ReactSelect
    autoFocus
    backspaceRemovesValue={false}
    closeMenuOnSelect={false}
    components={{ DropdownIndicator: () => null, IndicatorSeparator: null, Option: IconOption }}
    menuIsOpen
    styles={selectStyles}
    {...props}
    hideSelectedOptions={!props.isMulti || hideSelectedOptions}
    onChange={(...args) => {
      if (closeDropdownOnSelect) closeContainerDropdown?.();
      onChange?.(...args);
    }}
  />
);

export const CreatableSelect: React.FC<Props> = ({ heading, ...props }) => (
  <>
    {props.value && props.value.length > 0 && (
      <CreatableReactSelect
        value={props.value}
        isSearchable={false}
        menuIsOpen={false}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: null,
        }}
        styles={selectStyles}
        onChange={props.onChange}
      />
    )}

    {heading && (
      <Para style={{ padding: '8px 12px', fontWeight: 600, fontSize: 20 }}>{heading}</Para>
    )}

    <CreatableReactSelect
      autoFocus
      backspaceRemovesValue={false}
      closeMenuOnSelect={false}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: null,
        ClearIndicator: () => null,
      }}
      menuIsOpen
      controlShouldRenderValue={false}
      styles={selectStyles}
      {...props}
    />
  </>
);

const selectStyles: StylesConfig<SelectOption, true> = {
  control: provided => ({
    ...provided,
    margin: 0,
    padding: 5,
    border: 0,
    minWidth: 200,
    boxShadow: 'none',
  }),
  menu: () => ({ boxShadow: `inset 0 1px 0 ${Theme.theme.shades.lightGrey}` }),
};
