import { Box, Text } from '@chakra-ui/react';
import { Radio } from 'library/atoms';
import React, { useMemo } from 'react';
import { unSnoozeOption } from './constants';
import { ReassignmentTileProps, UnSnoozeType } from './type';

const ReassignmentTile = (props: ReassignmentTileProps) => {
  const { selectedReassignmentValue, handleReassignmentChange } = props;

  const renderReassignmentOptions = useMemo(() => {
    return unSnoozeOption.map((option: UnSnoozeType, index: number) => {
      return (
        <React.Fragment key={index}>
          <Box style={{ display: 'flex' }} p={2}>
            <Radio
              value={option.type}
              isChecked={selectedReassignmentValue === option.type}
              onChange={() => handleReassignmentChange(option.type)}
            />
            <Box marginLeft={2}>
              <Text variant="formInput_800" color="secondary.700">
                {option.title}
              </Text>
            </Box>
          </Box>
        </React.Fragment>
      );
    });
  }, [unSnoozeOption, selectedReassignmentValue, handleReassignmentChange]);

  return <React.Fragment>{renderReassignmentOptions}</React.Fragment>;
};

export default React.memo(ReassignmentTile);
