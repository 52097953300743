import { HStack, Text, VStack } from '@chakra-ui/react';
import { API } from 'core/api';
import FormField from 'library/molecules/FormField/FormField';
import { THEME_COLORS } from 'library/theme/colors';
import { useEffect, useMemo } from 'react';
import { components, GroupBase, SingleValueProps } from 'react-select';
import { QUERY_CONFIG } from 'views/main/organization/incident-list/common/constants';
import { useListServicesQuery } from 'views/main/organization/incident-list/graphql/query';
import { LCRFormType, ServiceTypes } from 'views/main/organization/lcr/types/LCR.types';
import { OwnerOption } from '../EntityOwnerDropdown/helper';
import FormDropdown from '../FormDropdown/FormDropdown';

interface Props {
  error?: string;
  name: string;
  isRequired?: boolean;
  value?: LCRFormType['Service'];
  onMount?: (ownerOptions: ServiceTypes[]) => void;
  onClearField?: () => void;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: any;
  menuPortalTarget?: boolean;
}
const EntityServiceropdown = ({
  name,
  error,
  onClearField,
  placeholder,
  isRequired,
  style,
  menuPortalTarget = false,
  value,
  onMount,
  onChange,
}: Props) => {
  const teamID = API.config.teamId;
  const config = { ...QUERY_CONFIG, staleTime: 1000 };

  const { data: services, isLoading: isServicesLoading } = useListServicesQuery({ teamID }, config);

  const servieList: ServiceTypes[] = services?.listServices ?? [];

  useEffect(() => {
    if (servieList.length > 0 && !value?.id) {
      onMount?.(servieList);
    }
  }, []);

  const SingleValue = (props: SingleValueProps<OwnerOption, boolean, GroupBase<OwnerOption>>) => {
    return (
      <components.SingleValue {...props}>
        <HStack>
          <Text style={{ color: 'black' }}>{props.data.name}</Text>
          {props.data.username && <Text>({props.data.username})</Text>}
        </HStack>
      </components.SingleValue>
    );
  };

  return (
    <FormField error={error} onClearField={onClearField} style={{ ...style }}>
      <FormDropdown<ServiceTypes>
        options={servieList}
        name={name}
        value={value}
        menuPortalTarget={menuPortalTarget ? document.body : null}
        getOptionLabel={option => option.name}
        components={{ SingleValue }}
        getOptionValue={option => option.serviceID}
        onChange={(e: any) => onChange(e)}
        placeholder={placeholder ?? 'Select ...'}
        formatOptionLabel={option => (
          <VStack alignItems={'left'} spacing={0}>
            {option.name && (
              <Text
                style={{
                  marginLeft: 10,
                  color: value?.name === option.name ? THEME_COLORS.primary[100] : 'black',
                }}
              >
                {option.name}
              </Text>
            )}
          </VStack>
        )}
      />
    </FormField>
  );
};

export default EntityServiceropdown;
