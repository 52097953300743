import ChakraDatepicker from 'components/chakra/Datepicker';
import { CustomDrawerComponent } from 'components/chakra/Drawer';
import Select from 'components/chakra/Select';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Flex,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';

import Loader from 'components/chakra/Loader';
import {
  SaveOverrideMutationVariables,
  UpdateOverrideMutationVariables,
} from 'views/main/organization/schedules/graphql/mutation';
import {
  useGetAllPatternParticipants,
  getStartTimeDropDownValues,
  IPatternParticipant,
} from '../../helpers/helpers.customrotations';
import { getTZWTimeISO, getUTCDateTime, isSameDate } from '../../helpers/helpers.date';
import {
  CalendarViewType,
  IRotationDetail,
  IRotationEventParticipants,
} from '../../interface/schedule';
import ParticipantGroupContainer from '../../schedules.add/schedules.customizerotations/participantGroupContainer';
import { overrideOptions, overrideReasons } from '../../constants/schedules.override';
import { OwnerType } from 'views/main/organization/schedules/graphql/types';
import { getPaddedValue } from '../../helpers/helpers.event';
import { useScheduleHeaderContext } from '../../schedules.view/schedules.header/context';
import { matchPath, useLocation } from 'react-router-dom';
import { SCHEDULES_V2_DETAIL_PATH } from 'views/main/routes/routes';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  overrideDetail: {
    scheduleId: number | null;
    scheduleName: string;
    startTime: string;
    endTime: string;
    reason: string;
    scheduleParticipants: NonNullable<
      IRotationDetail[number]['participantGroups']
    >[number]['participants'];
    overriddenParticipant: NonNullable<
      IRotationDetail[number]['participantGroups']
    >[number]['participants'];
    overrideWith: IRotationEventParticipants;
    timeZone: string;
  } | null;

  isLoading: boolean;
  updateOverrideId: string | null;
  isSavingOverride: boolean;
  onSave: (variables: SaveOverrideMutationVariables) => Promise<any>;
  onUpdate?: (variables: UpdateOverrideMutationVariables) => Promise<any>;
};

const initialValues = {
  startDate: new Date(),
  startTime: '00:00',
  endDate: new Date(),
  endTime: '00:00',
  reasonType: overrideReasons[0].value,
  reason: '',
  overriddenParticipant: [] as IPatternParticipant[],
  overrideWith: [] as IPatternParticipant[],
};

function ScheduleOverride({
  isOpen,
  onClose,
  overrideDetail,
  isLoading,
  updateOverrideId,
  isSavingOverride,
  onSave,
  onUpdate,
}: Props) {
  const saveButtonRef = useRef<HTMLButtonElement | null>(null);
  const allParticipants = useGetAllPatternParticipants().filter(p => p.type !== OwnerType.Team);
  const [participantOptions, setParticipantOptions] = useState<IPatternParticipant[]>([]);
  const { activeViewType } = useScheduleHeaderContext();
  let { pathname } = useLocation();
  pathname = pathname.split('?')[0];
  const isScheduleDetailsView = matchPath(pathname, SCHEDULES_V2_DETAIL_PATH);
  const onSaveOverride = async (values: typeof initialValues) => {
    setError('');
    const [startTimeHr, startTimeMin] = values.startTime.split(':').map(Number);
    const [endTimeHr, endTimeMin] = values.endTime.split(':').map(Number);

    if (overrideDetail) {
      const params = {
        startTime: getTZWTimeISO(
          overrideDetail.timeZone,
          values.startDate,
          startTimeHr,
          startTimeMin,
        ),
        endTime: getTZWTimeISO(overrideDetail.timeZone, values.endDate, endTimeHr, endTimeMin),
        reason:
          values.reasonType === overrideReasons[overrideReasons.length - 1].value
            ? values.reason
            : overrideReasons.find(r => r.value === values.reasonType)?.label,
        overriddenParticipant: {
          participants: values.overriddenParticipant.map(p => ({
            type: p.type,
            ID: p.id,
          })),
        },
        overrideWith: {
          participants: values.overrideWith.map(p => ({
            type: p.type,
            ID: p.id,
          })),
        },
      };

      if (params.startTime >= params.endTime) {
        setError('Start time should be before end time');
        return;
      }

      try {
        if (updateOverrideId) {
          await onUpdate?.({ id: Number(updateOverrideId), input: params });
          onClose();
        } else if (overrideDetail.scheduleId) {
          await onSave({ input: { ...params, scheduleID: overrideDetail.scheduleId } });
          onClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: values => {
      onSaveOverride(values);
    },
  });

  const doesOverrideStartToday = isSameDate(formik.values.startDate, new Date());
  const doesOverrideEndToday = isSameDate(formik.values.endDate, new Date());
  const startDatePickerFormat = (doesOverrideStartToday ? "'Today, '" : '') + 'd MMM yyy, (EEE)';
  const endDatePickerFormat = (doesOverrideEndToday ? "'Today, '" : '') + 'd MMM yyy, (EEE)';
  const [error, setError] = useState('');
  const timeRange = getStartTimeDropDownValues();
  useEffect(() => {
    if (overrideDetail) {
      const overriddenParticipant =
        (overrideDetail.overriddenParticipant as IRotationEventParticipants)?.map(participant => ({
          id: participant.ID,
          label: allParticipants.find(p => p.id === participant.ID)?.label ?? '',
          value: participant.ID,
          type: participant.type as IPatternParticipant['type'],
          timeZone: allParticipants.find(p => p.id === participant.ID)?.timeZone ?? '',
          username: allParticipants.find(p => p.id === participant.ID)?.username ?? '',
        })) || [];

      //TODO: Refactor this later, add better comments

      const startDate = getUTCDateTime(overrideDetail.startTime, overrideDetail.timeZone);
      const endDate = getUTCDateTime(overrideDetail.endTime, overrideDetail.timeZone);
      const { hour: startHr, minute: startMin } = startDate;
      const { hour: endHr, minute: endMin } = endDate;

      const startJSDate = new Date(startDate.year, startDate.month - 1, startDate.day);
      const endJSDate = new Date(endDate.year, endDate.month - 1, endDate.day);

      formik.resetForm({
        values: {
          startDate: startJSDate,
          startTime: `${getPaddedValue(startHr)}:${getPaddedValue(startMin)}`,
          endDate: endJSDate,
          endTime: `${getPaddedValue(endHr)}:${getPaddedValue(endMin)}`,
          reasonType:
            overrideReasons.find(r => r.label === overrideDetail.reason)?.value ??
            overrideReasons[overrideReasons.length - 1].value,
          reason: overrideDetail.reason,
          overriddenParticipant,
          overrideWith:
            overrideDetail.overrideWith?.map(participant => ({
              id: participant.ID,
              label: allParticipants.find(p => p.id === participant.ID)?.label ?? '',
              value: participant.ID,
              type: participant.type as IPatternParticipant['type'],
              timeZone: allParticipants.find(p => p.id === participant.ID)?.timeZone ?? '',
              username: allParticipants.find(p => p.id === participant.ID)?.username ?? '',
            })) || [],
        },
      });
      const scheduleParticipants =
        (overrideDetail?.scheduleParticipants as IRotationEventParticipants)?.map(participant => ({
          id: participant.ID,
          label: allParticipants.find(p => p.id === participant.ID)?.label ?? '',
          value: participant.ID,
          type: participant.type as IPatternParticipant['type'],
          timeZone: allParticipants.find(p => p.id === participant.ID)?.timeZone ?? '',
          username: allParticipants.find(p => p.id === participant.ID)?.username ?? '',
        })) || [];

      setParticipantOptions(scheduleParticipants);
    }
  }, [overrideDetail, overrideDetail?.scheduleParticipants]);

  return (
    <CustomDrawerComponent
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setError('');
      }}
      onBack={() => {
        onClose();
        setError('');
      }}
      title={<Heading>Override</Heading>}
      isFooter
      footerButtons={
        isLoading
          ? []
          : [
              {
                text: updateOverrideId ? 'Update' : 'Save',
                type: 'button',
                isDisabled:
                  /***
                  Button will be disabled if no reason has been provided for override type - Other reason
                  or if the overriden participant is not selected
                  **/
                  formik.values.reasonType === overrideReasons[3].value && !formik.values.reason,
                isLoading: isSavingOverride,
                onClick: () => saveButtonRef.current?.click(),
              },
              {
                text: 'Cancel',
                type: 'button',
                onClick: () => {
                  onClose();
                  setError('');
                },
                variant: 'outline',
              },
            ]
      }
    >
      {isLoading && <Loader.Spinner centered isLoading spinnerProps={{ mt: 12, size: 'xl' }} />}
      {!isLoading && (
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Box mb={6}>
              <Text>
                {activeViewType.value === CalendarViewType.dayGridMonth && !isScheduleDetailsView
                  ? `Configuring Overrides will assign all slots of the existing users to selected users. Overrides will appear as per the viewing user's timezone`
                  : `Configuring Overrides will assign all slots of the existing users to selected users. Overrides will appear as per the Schedule's timezone`}
              </Text>
            </Box>

            <Stack spacing={6}>
              <Stack>
                <FormLabel>Participant</FormLabel>
                <ParticipantGroupContainer
                  onChange={(value: any) => formik.setFieldValue('overriddenParticipant', value)}
                  groupNumber={1}
                  options={participantOptions}
                  name="overriddenParticipant"
                  inputId="overriddenParticipant"
                  defaultValue={formik.values.overriddenParticipant}
                  closeMenuOnSelect
                  hideGroupIndicator
                  showTZOffsetOnHover
                  disableSelection
                />
              </Stack>

              <Stack direction="row" columnGap={3}>
                <Box flex={1}>
                  <FormLabel>Override</FormLabel>
                  <Text>
                    {overrideOptions[0].label} ({overrideDetail?.scheduleName})
                  </Text>
                </Box>
                <Box flex={1} />
              </Stack>

              <Stack direction="row" columnGap={3}>
                <Box flex={1}>
                  <FormLabel>Start Date</FormLabel>
                  <ChakraDatepicker
                    setDate={(value: Date) => formik.setFieldValue('startDate', value)}
                    date={formik.values.startDate}
                    dateFormat={startDatePickerFormat}
                  />
                </Box>
                <Box flex={1}>
                  <FormLabel>Start Time</FormLabel>
                  <Select
                    value={{
                      label: formik.values.startTime,
                      value: formik.values.startTime,
                    }}
                    options={timeRange}
                    onChange={(selectedValue: any) => {
                      formik.setFieldValue('startTime', selectedValue.value);
                    }}
                    closeMenuOnSelect
                  />
                </Box>
              </Stack>

              <Stack direction="row" columnGap={3}>
                <Box flex={1}>
                  <FormLabel>End Date</FormLabel>
                  <ChakraDatepicker
                    setDate={(value: Date) => formik.setFieldValue('endDate', value)}
                    date={formik.values.endDate}
                    dateFormat={endDatePickerFormat}
                  />
                </Box>
                <Box flex={1}>
                  <FormLabel>End Time</FormLabel>
                  <Select
                    value={{
                      label: formik.values.endTime,
                      value: formik.values.endTime,
                    }}
                    options={timeRange}
                    onChange={(selectedValue: any) => {
                      formik.setFieldValue('endTime', selectedValue.value);
                    }}
                    closeMenuOnSelect
                  />
                </Box>
              </Stack>
              {error && <Text style={{ color: 'red' }}>{error}</Text>}

              <Stack>
                <FormLabel>Reason</FormLabel>

                <RadioGroup
                  value={formik.values.reasonType}
                  onChange={value => formik.setFieldValue('reasonType', value)}
                >
                  <Stack>
                    {overrideReasons.map((reason, index) => (
                      <Radio key={reason.value} size="md" name="reasonType" value={reason.value}>
                        {reason.label}
                        {formik.values.reasonType ===
                          overrideReasons[overrideReasons.length - 1].value &&
                        index === overrideReasons.length - 1
                          ? '*'
                          : ''}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
                {formik.values.reasonType === overrideReasons[overrideReasons.length - 1].value && (
                  <Textarea
                    name="reason"
                    value={formik.values.reason}
                    onChange={formik.handleChange}
                  />
                )}
              </Stack>

              <Stack>
                <FormLabel>Assign To</FormLabel>
                <ParticipantGroupContainer
                  onChange={(value: any) => formik.setFieldValue('overrideWith', value)}
                  groupNumber={1}
                  options={allParticipants}
                  name="overrideWith"
                  inputId="overrideWith"
                  defaultValue={formik.values.overrideWith}
                  hideGroupIndicator
                  menuPlacement="top"
                  showTZOffsetOnHover
                />
              </Stack>
            </Stack>
            <button hidden type="submit" ref={saveButtonRef} />
          </Form>
        </FormikProvider>
      )}
    </CustomDrawerComponent>
  );
}

export default ScheduleOverride;
