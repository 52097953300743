import { TimeDimensionGranularity } from '@cubejs-client/core';
import { DateTime, Duration, DurationObjectUnits } from 'luxon';
import { LineData, LineDataPoint } from '../types';

export const getValidDurations = (durations: DurationObjectUnits) => {
  return Object.entries(durations).reduce((acc, [durationKey, durationValue]) => {
    if (durationValue) {
      acc[durationKey as keyof DurationObjectUnits] = durationValue;
    }
    return acc;
  }, {} as Record<keyof DurationObjectUnits, number>);
};

/**
 * Format seconds duration to human readable format
 * @param durationInSeconds Duration in seconds
 * @returns Human readable duration
 */
export function formatDurationValue(durationInSeconds: number): string {
  const durationShift = Duration.fromDurationLike({ seconds: durationInSeconds }).shiftTo(
    'days',
    'hours',
    'minutes',
    'seconds',
    'milliseconds',
  );
  const validDurations = getValidDurations({
    days: durationShift.days,
    hours: durationShift.hours,
    minutes: durationShift.minutes,
    seconds: durationShift.seconds,
    milliseconds: durationShift.milliseconds,
  });

  return Duration.fromDurationLike(validDurations).toHuman({
    unitDisplay: 'narrow',
  });
}

export function formatDateValue(date: Date, format?: TimeDimensionGranularity): string {
  const dateTime = DateTime.fromJSDate(new Date(date));
  const dateFormat = format || ('hour' as TimeDimensionGranularity);

  switch (dateFormat) {
    case 'day':
    case 'week':
      return dateTime.toLocaleString(DateTime.DATE_MED);
    case 'month':
      return dateTime.toLocaleString({ month: 'short', year: 'numeric' });
    default:
      return dateTime.toLocaleString(DateTime.DATETIME_MED);
  }
}

export function formatDateOverRange(
  date: Date,
  format?: TimeDimensionGranularity,
  isWithinWeek?: boolean,
): string {
  const dateTime = DateTime.fromJSDate(new Date(date));
  const dateFormat = format || ('hour' as TimeDimensionGranularity);

  switch (dateFormat) {
    case 'hour':
      // Between 2 and 6 days
      // Will get time as 2022-01-01T04:00:00:000 for 2022-01-01T00:00:00:000 - 2022-01-01T23:00:00:000
      if (isWithinWeek) {
        return `${dateTime.plus({ hour: -4 }).toFormat('ha')} - ${dateTime
          .plus({ hour: 4 })
          .toFormat('ha')}`;
      } else {
        return dateTime.toFormat('hh:mm a');
      }
    case 'day':
      return dateTime.toFormat('dd MMM');
    case 'week': {
      const weekDT = DateTime.fromObject({
        weekYear: dateTime.year,
        weekNumber: dateTime.weekNumber,
      });

      // Subtract a day to Start weekday from Sunday
      const startWeekDT = weekDT.startOf('week').minus({ day: 1 });
      const endWeekDT = weekDT.endOf('week').minus({ day: 1 });

      return `${startWeekDT.day}${
        startWeekDT.month !== endWeekDT.month ? ' ' + startWeekDT.monthShort : ''
      } - ${endWeekDT.day} ${endWeekDT.monthShort}`;
    }
    case 'month':
      return dateTime.toFormat('MMM');
    default:
      return date.toLocaleString();
  }
}

export function convertToHourlyData(hourlyDayData: LineDataPoint[], hour: number) {
  const dataByDate = {} as { [key: string]: LineDataPoint[] };

  hourlyDayData.forEach(hourData => {
    const dateValue = new Date(hourData.x).getDate().toString();
    dataByDate[dateValue] = [...(dataByDate[dateValue] || []), hourData];
  });

  Object.entries(dataByDate).forEach(([key, value]) => {
    dataByDate[key] = arrayToChunks(value, 24 / hour).flatMap(calcLineAvg);
  });

  return Object.values(dataByDate).flat();
}

function calcLineAvg(lineData: LineDataPoint[]) {
  return [
    {
      x: lineData[lineData.length / 2]?.x,
      y: lineData.reduce((total, lineData) => total + lineData.y, 0) / lineData.length,
    },
  ];
}

function arrayToChunks<T>(array: T[], chunkSize: number) {
  let arrayChunks = [] as T[][];
  const chunkLength = array.length / chunkSize;

  for (let i = 0; i < array.length; i += chunkLength) {
    arrayChunks = [...arrayChunks, array.slice(i, i + chunkLength)];
  }

  return arrayChunks;
}

export const getYTickValues = (lines: LineData[]) => {
  const values = lines.flatMap(l => l.data.map(ld => ld.y));
  const max = Math.max(...values),
    tickLength = 8,
    initialTick = max / tickLength;

  return [0, ...Array.from(Array(tickLength).keys()).map(tick => (tick + 1) * initialTick)];
};
