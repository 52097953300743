import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const CloseButtonTheme: ComponentStyleConfig = {
  baseStyle: () => {
    return {
      color: '#09305A',
      _focus: {
        boxShadow: 'none',
      },
      _disabled: {
        pointerEvents: 'none',
      },
      _hover: {
        borderColor: 'secondary.1007',
      },
      _pressed: {
        borderColor: 'secondary.200',
      },
    };
  },
  sizes: {
    // for 1440 screen size
    md: {
      borderRadius: '3px',
      width: '24px',
      height: '24px',
    },
    // for 1920 screen size
    lg: {
      borderRadius: '4px',
      width: '32px',
      height: '32px',
    },
  },
  variants: {
    default: {},
  },
  defaultProps: {
    size: 'md',
    variant: 'default',
  },
};
