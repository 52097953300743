import { PropsWithChildren } from 'react';
import ReactSelect, { GroupBase, Props as ReactSelectProps, StylesConfig } from 'react-select';

import { Grid, Label, Theme } from '..';

interface IProps<OptionType> extends ReactSelectProps<OptionType, boolean, GroupBase<OptionType>> {
  label?: string;
}

function Select<OptionType>(props: PropsWithChildren<IProps<OptionType>>) {
  const customStyles: StylesConfig<OptionType> = {
    option: (styles, _) => ({
      ...styles,
      cursor: 'pointer',
      fontFamily: Theme.theme.fontFamily,
      fontSize: '16px',
    }),
    control: styles => ({
      ...styles,
      cursor: 'pointer',
      height: 'auto',
      fontFamily: Theme.theme.fontFamily,
      fontSize: '16px',
    }),
    input: styles => ({
      ...styles,
      fontFamily: Theme.theme.fontFamily,
      fontSize: '16px',
    }),
    placeholder: styles => ({
      ...styles,
      fontFamily: Theme.theme.fontFamily,
      fontSize: '16px',
    }),
  };

  const { label: labelText, ...rest } = props;
  return (
    <Grid width="100%" height="auto" type="column">
      {labelText && (
        <Label fontWeight={500} fontSize={16} height={'fit-content'}>
          {labelText}
        </Label>
      )}
      <ReactSelect {...rest} styles={customStyles} />
    </Grid>
  );
}
export default Select;
