export const queryUpdate = (
  query: URLSearchParams,
  data: string | null | undefined,
  type: string,
) => {
  if (data) {
    query.get(type) ? query.set(type, data) : query.append(type, data);
  } else {
    query.has(type) && query.delete(type);
  }
};

export const isNullOrUndefined = (value: any) => value === null || typeof value === 'undefined';
