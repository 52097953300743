import { Box } from '@chakra-ui/react';
import { Placeholder } from 'library/molecules';
import { FC } from 'react';
import { GER_ERROR } from '../util';

type Props = {
  error?: GER_ERROR;
};

const errMsg = {
  [GER_ERROR.API_FAILURE]: 'Apologies, data retrieval failed. Please try again later.',
  [GER_ERROR.NO_READ_PERMISSION]: 'You do not have permission to view Global Event Rulesets.',
  [GER_ERROR.NO_DATA]: 'No relevant data found for this record.',
};

export const GERError: FC<Props> = ({ error = GER_ERROR.API_FAILURE }) => {
  return (
    <Box width="full" height="full" bg="container.background">
      <Placeholder iconName="global-event-rules.svg" description={errMsg[error]} />
    </Box>
  );
};
