export const EntityTypes = [
  'escalationPolicies',
  'gers',
  'postmortems',
  'runbooks',
  'schedulesV1',
  'schedulesV2',
  'services',
  'slos',
  'statusPagesV1',
  'statusPagesV2',
  'webforms',
  'workflows',
  'liveCallRouting',
] as const;

export const EntityNames = {
  escalationPolicies: 'Escalation Policies',
  gers: 'Global Event Rules',
  postmortems: 'Postmortems',
  runbooks: 'Runbooks',
  schedulesV1: 'Legacy Schedules',
  schedulesV2: 'Schedules',
  services: 'Services',
  slos: 'SLOs',
  statusPagesV1: 'Legacy Status Pages',
  statusPagesV2: 'Status Pages',
  webforms: 'Webforms',
  workflows: 'Workflows',
  liveCallRouting: 'Live Call Routing',
} as const;

export type EntityType = typeof EntityTypes[number];

export type EntityCount = {
  [key in EntityType]: number;
};

export type Entity = {
  id: string;
  name: string;
  count: number;
};

export type EntityList = Entity[];

export type EntityAPIError = {
  meta: Meta;
};

export type Meta = {
  status: number;
  error_message: string;
};

export const EntityIdentifiers = {
  escalationPolicies: 'escalationPolicies',
  gers: 'gers',
  postmortems: 'postmortems',
  runbooks: 'runbooks',
  schedulesV1: 'schedulesV1',
  schedulesV2: 'schedulesV2',
  services: 'services',
  slos: 'slos',
  statusPagesV1: 'statusPagesV1',
  statusPagesV2: 'statusPagesV2',
  webforms: 'webforms',
  workflows: 'workflows',
  liveCallRouting: 'liveCallRouting',
};

export type EntityListPayload = {
  type: string;
  page_limit?: number;
  page_number?: number;
  text_filter?: string;
  entity_owner?: string[];
};

export type EntitiesForType = {
  data: {
    [key in EntityType]: EntityData[];
  } & {
    count: number;
  };
};

export type EntityData = {
  id: string;
  name: string;
  team_id: string;
  entity_owner: {
    id: string;
    type: string;
  };
};

export type EntityTableData = {
  data: Array<
    EntityData & {
      owner_meta: {
        name: string;
        userNameForDisplay?: string;
      };
    }
  >;
  pagination: {
    total_count: number;
  };
};
