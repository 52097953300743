import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { Fragment, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import {
  Column,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import { DOTS, usePaginationCustom } from '../../hooks/usePagination';
import { IPatternParticipant } from '../../Interface';

interface IDataTable {
  columns: Column<Record<any, any>>[];
  data: Record<string, any>[];
  expandedView?: ReactNode;
  hoverView?: ReactElement;
  height?: string;
  width?: string;

  searchText?: string;

  sortBy?: (sortField: string, sort_order: 'asc' | 'des') => void;
  sorted?: { sort_by: string; sort_order: 'asc' | 'des' | string };

  isClientPagination?: boolean;
  paginationProps?: {
    queryPageIndex: number;
    queryPageSize: number;
    totalCount: number;
    pageChange: (queryPageIndex: number) => void;
    pageSizeChange: (queryPageSize: number) => void;
  };

  disableOverflow?: boolean;
  onDelete?: (id: number) => void;
  ownerOptions?: IPatternParticipant[];
  timezone?: string;
}

/**
 * General datatable component
 * @param props IDataTable
 * @returns JSX Table Element
 */
const DataTable = React.memo(
  ({
    columns,
    data,
    hoverView,
    height,
    width,
    isClientPagination,
    paginationProps,
    disableOverflow,
    onDelete,
    ownerOptions,
    timezone,
  }: IDataTable) => {
    const tableInstance = useTable(
      {
        columns,
        data,
        //pagination
        initialState: {
          pageIndex: isClientPagination ? 0 : 1,
        },
        // manualPagination: !isClientPagination, // Tell the usePagination
        // hook that we'll handle our own data fetching
        // This means we'll also have to provide our own
        // pageCount.

        pageCount: isClientPagination
          ? undefined
          : paginationProps
          ? paginationProps.totalCount
          : 0,
        autoResetPage: !isClientPagination,
        onDelete,
        ownerOptions,
        timezone,
      },
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect,
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      gotoPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = tableInstance;

    const {
      queryPageIndex = 1,
      queryPageSize = 5,
      totalCount,
      pageSizeChange,
      pageChange,
    } = isClientPagination
      ? {
          queryPageIndex: pageIndex + 1,
          queryPageSize: pageSize,
          totalCount: rows.length,
          pageSizeChange: setPageSize,
          pageChange: (page: number) => gotoPage(page - 1),
        }
      : paginationProps ?? {};

    const [hoveredRow, setHoveredRow] = useState<string | null>(null);

    return (
      <Box overflowY={disableOverflow ? 'unset' : 'auto'} height={height} width={width}>
        <Table {...getTableProps()}>
          <Thead bg="gray.50">
            {headerGroups.map(headergroup => {
              return (
                <Tr {...headergroup.getHeaderGroupProps()} key={headergroup.id}>
                  {headergroup.headers.map(column => {
                    return (
                      <Th {...column.getHeaderProps()} color="gray.700" key={column.id}>
                        <Flex justifyContent="flex-start" alignItems="center">
                          {column.render('Header')}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {!(isClientPagination ? page : rows).length && (
              <Tr>
                <Td colSpan={columns.length}>
                  <Text fontSize={14} textAlign="center">
                    No data
                  </Text>
                </Td>
              </Tr>
            )}
            {(isClientPagination ? page : rows).map(row => {
              prepareRow(row);

              return (
                <Fragment key={row.id}>
                  <Tr
                    {...row.getRowProps()}
                    onMouseEnter={() => {
                      hoverView && setHoveredRow(row.id);
                    }}
                    onMouseLeave={() => hoverView && setHoveredRow(null)}
                    bgColor={hoverView && hoveredRow === row.id ? 'gray.100' : 'white'}
                    position={hoverView && hoveredRow === row.id ? 'relative' : 'static'}
                    data-testid="table-row"
                  >
                    {row.cells.map(cell => {
                      return (
                        <Td {...cell.getCellProps()} key={cell.getCellProps().key}>
                          <Flex justifyContent="flex-start" alignItems="center">
                            {cell.render('Cell')}
                          </Flex>
                        </Td>
                      );
                    })}

                    {/* Show this view on hover */}
                    {hoverView && hoveredRow === row.id ? (
                      <Flex
                        height="80%"
                        position="absolute"
                        right="3.5rem"
                        top="50%"
                        data-testid={`overlay-div-${row.id}`}
                        transform="translateY(-50%)"
                        bgColor="gray.100"
                      >
                        {React.cloneElement(hoverView, {
                          rowData: { row },
                          onMouseEnter: () => {
                            setHoveredRow(row.id);
                          },
                        })}
                      </Flex>
                    ) : null}
                  </Tr>
                </Fragment>
              );
            })}
          </Tbody>
        </Table>

        <Pagination
          enabled={!!(paginationProps || isClientPagination)}
          queryPageSize={queryPageSize}
          pageSizeChange={pageSizeChange}
          queryPageIndex={queryPageIndex}
          pageChange={pageChange}
          totalCount={totalCount ?? 0}
        />
      </Box>
    );
  },
);

interface PaginationProps {
  enabled: boolean;
  queryPageSize: number;
  pageSizeChange?: (size: number) => void;
  queryPageIndex: number;
  pageChange?: (page: number) => void;
  totalCount: number;
}
export const Pagination = ({
  enabled,
  queryPageSize,
  pageSizeChange,
  queryPageIndex,
  pageChange,
  totalCount,
}: PaginationProps) => {
  const paginationRange = usePaginationCustom({
    totalCount,
    pageSize: queryPageSize,
    siblingCount: 1,
    currentPage: queryPageIndex,
  });

  return enabled ? (
    <Box px={{ base: '4', md: '6' }} p="5">
      <HStack spacing="60">
        <HStack spacing={10}>
          <Select
            value={queryPageSize}
            width="81px"
            borderRadius="6px"
            size="sm"
            data-testid="page-size-select"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              pageSizeChange && pageSizeChange(parseInt(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 50, 100].map((value, index) => {
              return (
                <option key={index} value={value}>
                  {value}
                </option>
              );
            })}
          </Select>

          <Text fontSize="sm">
            Showing {queryPageSize > totalCount ? totalCount : queryPageSize} of {totalCount}
          </Text>
        </HStack>
        <HStack spacing={5}>
          <IconButton
            aria-label="Search services"
            size="sm"
            variant="unstyled"
            fontSize="14px"
            fontWeight="medium"
            data-testid="pagination-left"
            borderRadius={0}
            disabled={queryPageIndex == 1}
            onClick={() => pageChange && pageChange(queryPageIndex - 1)}
            icon={<ChevronLeftIcon />}
          />

          {paginationRange &&
            paginationRange.map((page, index) => {
              return (
                <Button
                  key={index}
                  variant="ghost"
                  fontSize="14px"
                  fontWeight="medium"
                  borderRadius="6px"
                  size="sm"
                  data-testid="pagination-pages"
                  p={0}
                  bgColor={queryPageIndex === page ? 'rgba(245, 245, 245, 1)' : 'white'}
                  onClick={() => pageChange && typeof page != 'string' && pageChange(page)}
                  disabled={page === DOTS}
                >
                  {page}
                </Button>
              );
            })}

          <IconButton
            aria-label="Search services"
            size="sm"
            variant="unstyled"
            fontSize="14px"
            fontWeight="medium"
            data-testid="pagination-right"
            borderRadius={0}
            onClick={() => pageChange && pageChange(queryPageIndex + 1)}
            disabled={queryPageIndex * queryPageSize >= totalCount}
            icon={<ChevronRightIcon />}
          />
        </HStack>
      </HStack>
    </Box>
  ) : null;
};

export default DataTable;
