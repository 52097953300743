import React from 'react';
import { Bar } from '@nivo/bar';
import { ResponsiveWrapper } from '@nivo/core';
import { Para } from 'uie/components';

interface IProps {
  data: Record<string, any>[];
  config?: any;
}

const customTooltip = (props: any) => {
  return (
    <Para fontWeight={500}>
      {props.indexValue} - {props.value}
    </Para>
  );
};

const BarChart: React.FC<IProps> = ({ data, config }) => {
  return (
    <ResponsiveWrapper>
      {({ width }) => (
        <Bar
          data={data}
          width={width}
          height={270}
          indexBy="entity"
          margin={{ top: 0, right: 50, bottom: 50, left: 120 }}
          layout="horizontal"
          colors={{ scheme: 'paired' }}
          enableGridX={false}
          enableGridY={false}
          tooltip={customTooltip}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'incident count',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            format: (v: string) => {
              return v.length > 17 ? (
                <tspan>
                  {`${v.substring(0, 17)}...`}
                  <title>{v}</title>
                </tspan>
              ) : (
                v
              );
            },
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          {...config}
        />
      )}
    </ResponsiveWrapper>
  );
};

export default BarChart;
