import { ArrowLeftIcon } from 'icons';
import { Grid, Para } from 'uie/components';

import { useIncidentSummaryContext } from '../../../context/hook';

const IncidentSummaryDrawerFeedbackTitle: React.FC = () => {
  const { summary, setSummaryDrawerStateSuccess } = useIncidentSummaryContext();

  if (!summary.data) return null;

  return (
    <Grid alignItems="center" justifyContent="center">
      <ArrowLeftIcon
        height={19.5}
        width={19.5}
        className="mr-5 cursor-pointer"
        onClick={() => setSummaryDrawerStateSuccess()}
      />
      <Para fontSize={27} fontWeight={400}>
        What went wrong?
      </Para>
    </Grid>
  );
};

export default IncidentSummaryDrawerFeedbackTitle;
