import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const InputTheme: ComponentStyleConfig = {
  baseStyle: {
    field: {
      fontFamily: 'Mulish',
      bg: 'brand.white',
      borderWidth: '0.75px',

      '::placeholder': {
        color: 'secondary.400',
      },
      '::-ms-input-placeholder': {
        color: 'secondary.400',
      },

      _disabled: {
        bg: 'secondary.100',
        color: '#09305A',
        opacity: 1,
        cursor: 'not-allowed',

        _hover: {
          borderColor: 'transparent',
        },
      },

      _invalid: {
        borderColor: '#E05000',
      },

      _hover: {
        borderColor: 'brand.blue',
      },

      _active: {
        borderColor: 'primary.300',
      },
      _focus: {
        borderColor: 'brand.blue',
      },
      _focusWithin: {
        borderColor: 'brand.blue',
      },
    },
  },
  sizes: {
    md: {
      field: {
        borderRadius: '3px',
        height: '30px',
        px: '9px',
        py: '6px',
        fontSize: '12px',
      },
    },
    lg: {
      field: {
        borderRadius: '3px',
        height: '40px',
        px: '12px',
        py: '8px',
        fontSize: '16px',
      },
    },
  },
  variants: {
    addon: ({ size }) => ({
      field: {
        pl: size === 'md' ? '40px' : '44px',
      },
      element: {
        bg: 'secondary.100',
        borderLeftRadius: '3px',
        borderRight: '0.75px solid secondary.200',
        // top: 1px was overriden by top: 0px by chakra
        transform: 'translateY(0.75px)',
        '&.chakra-input__left-element': {
          left: '1px',
          borderLeftRadius: '3px',
          borderRight: '0.75px solid #E1E6EB',
        },
        '&.chakra-input__right-element': {
          right: '1px',
          borderRightRadius: '3px',
          borderLeft: '0.75px solid #E1E6EB',
        },
        width: size === 'md' ? '28.5px' : '38.5px',
        height: size === 'md' ? '28.5px' : '38.5px',
      },
    }),
    icon: ({ size }) => ({
      field: {
        pl: size === 'md' ? '27px' : '36px',
      },
      element: {
        height: size === 'md' ? '30px' : '40px',
        width: 'auto',
        pl: size === 'md' ? '9px' : '12px',
      },
    }),
    avatar: ({ size }) => ({
      field: {
        pl: size === 'md' ? '33px' : '44px',
      },
      element: {
        height: size === 'md' ? '30px' : '40px',
        width: 'auto',
        pl: size === 'md' ? '9px' : '12px',
      },
    }),
  },
  defaultProps: {
    size: 'md',
    variant: null,
  },
};
