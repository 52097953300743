import * as React from 'react';
import './index.css';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import MessageModal from '../../../../../components/message.modal';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IOrganization } from '../../../../../core/interfaces/IOrganization';
import {
  TextButton,
  Grid,
  Para,
  Theme,
  DialogModalFrame,
  DropDown,
  Checkbox,
  ErrorToastBlock,
  ToastContext,
  ContainerLoad,
  Heading,
  FocusedSearch,
  ErrorBlock,
  Avatar,
  Label,
  SnackContext,
  SnackBar,
} from 'uie/components';
import { IRole } from '../../../../../core/interfaces/IRole';
import { useToast } from 'library/atoms';
import {
  IUsersOfOrganization,
  IUserUpdateReq,
  IUserVerificationReq,
} from '../../../../../core/interfaces/IUserData';
import { exception } from '../../../../../core/exception';
import equal from 'fast-deep-equal/es6/react';
import { requestOrganizationUsers } from '../../../../../core/actions/organization/users';
import { requestOrganizationTeams } from '../../../../../core/actions/organization/teams';
import { requestOrganizationSquad } from '../../../../../core/actions/organization/squads';
import { BillingService, SSOService, UsersService } from '../../../../../core/services';
import Table from './table';
import ConfirmModal from '../../../../../components/confirm.modal';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_GS_USERS_PAGE_VIEWED } from '../../../../../core/const/tracker';
import { TeamIcon } from 'icons';
import { useEffect, useState } from 'react';
import { IStakeholderDependencies } from 'core/interfaces/ITeams';
import StakeholderDependencies from './UserDependecies';
import UpgradePlanModal from '../../../../../components/upgradeplan.modal';
import { Redirect } from 'react-router';
import organization from '../organization';
import Tippy from '@tippy.js/react';
import UpgradeOnlyModal from 'components/upgradeonly.tooltip';
import { TOAST_STATUS } from 'library/types';
import { Note } from '../shared/note';

type IMix = Pick<IAppState, 'organization' | 'roles' | 'userInfo'> & RouteComponentProps;

const { theme } = Theme;

interface IProps extends IMix {
  selectedOrg: IOrganization;
  requestOrganizationUsers: () => void;
  requestOrganizationTeams: () => void;
  requestOrganizationSquad: () => void;
  showToast: (msg: string, duration?: number) => void;
  showSnackBar: (msg: string, bg: string, timeout: number) => void;
  showNewToast: (status: TOAST_STATUS, text: string) => void;
}

interface IState {
  searchTerm: string;
  users: IUsersOfOrganization[];
  showFilter: boolean;
  roleFilter: string[];
  saving: number;
  showBoxShow: boolean;
  showMessageModal: boolean;
  showConfirmModal: boolean;
  showConflictModal: boolean;
  conflictTeams: {
    id: string;
    name: string;
  }[];
  stakeholderConflictData: IStakeholderDependencies[];
  showStakeholderConflictModal: boolean;
  stakeholderConflictUserId: string;
  messageText: string;
  roleEnabled: number;
  deletedUserID: string;
  showUpgradeModal: boolean;
  userLimitExceeded: boolean;
  orgHasEnabledSSO: boolean;
  role: string;
}

class OrganizationUserTab extends React.Component<IProps, IState> {
  private _usersService = new UsersService();
  private dropdownRef = React.createRef<any>();
  constructor(props: IProps) {
    super(props);
    this.state = {
      searchTerm: '',
      users: this.sortUsersAlphabetical(this.props.organization.users.u),
      showFilter: false,
      roleFilter: [],
      saving: -1,
      showBoxShow: false,
      showMessageModal: false,
      showConfirmModal: false,
      showConflictModal: false,
      conflictTeams: [],
      stakeholderConflictData: [],
      showStakeholderConflictModal: false,
      stakeholderConflictUserId: '',
      messageText: '',
      roleEnabled: -1,
      deletedUserID: '',
      showUpgradeModal: false,
      userLimitExceeded: false,
      orgHasEnabledSSO: false,
      role: '',
    };
  }

  addUserIfPlanPermits = () => {
    if (this.state.userLimitExceeded) {
      this.setState({ showUpgradeModal: true });
    } else {
      this.props.history.push('/add-users');
    }
  };

  public getStakeholderConflicts = async (userID: string) => {
    try {
      const res = await this._usersService.getStakeholderDependencies(userID);
      this.setState({ stakeholderConflictData: res.data.data });
    } catch (err) {
      exception.handle('E_GET_STAKEHOLDER_DEPENDENCIES', err);
    } finally {
    }
  };

  public updateUser = async (userID: string, index: number, roleID: string, role: string) => {
    const selectedUser = this.props.organization.users.u.find(u => u.id === userID);
    const data: IUserUpdateReq = {
      role: (this.props.roles.r.find((r: IRole) => r._id === roleID)?.name ?? '')
        .toLowerCase()
        .replace(/ /g, '_') as IRole['slug'],
      abilities: selectedUser?.abilities.map(a => a.slug) || [],
    };
    this.setState({
      saving: -1,
    });
    try {
      const res = await this._usersService.getStakeholderDependencies(userID);
      if (
        res.data.data.length &&
        res.data.data?.every?.(row => row.dependency_exists || row.nra_roles.length > 0)
      ) {
        this.setState({
          stakeholderConflictData: res.data.data,
          showStakeholderConflictModal: true,
          stakeholderConflictUserId: userID,
          role: role,
        });
        return;
      }
      await this._usersService.update(data, userID);
      this.setState({
        saving: index,
        stakeholderConflictData: [],
      });
      this.props.showSnackBar('User updated', theme.success.default, 5000);
      this.props.requestOrganizationUsers();
    } catch (err: any) {
      this.setState({
        saving: -1,
      });
      this.props.showSnackBar(exception.handle('E_UPDATE_USER', err), theme.danger.default, 6000);
    }
  };

  public resendVerificationMail = (user: IUsersOfOrganization) => async () => {
    try {
      await this._usersService.sendVerificationReminderMail(user.id);
      const msg = `Verification request has been successfully sent to the user "${user.first_name} ${user.last_name}"`;
      this.props.showNewToast('success', msg);
      return true;
    } catch (err: any) {
      this.props.showNewToast(
        'error',
        'There was an error while sending the reminder. Please try again',
      );
      exception.handle('E_SEND_VERIFICATION_MAIL_USER', err);
      return false;
    }
  };

  public async isOrgHasEnabledSSO() {
    const _ssoService = new SSOService();
    try {
      const orgId = this.props.organization.plan.p?.organization_id;
      const { data: ssoConfig } = await _ssoService.getSSOConfig(orgId ? orgId : '');
      this.setState({ orgHasEnabledSSO: ssoConfig.data && ssoConfig.data.is_enabled });
    } catch {
      this.setState({ orgHasEnabledSSO: false });
    }
  }

  public confirmDelete = async (deleteConfirm: boolean) => {
    if (deleteConfirm) {
      try {
        await this._usersService.delete(this.state.deletedUserID);
        this.setState({
          deletedUserID: '',
          showConfirmModal: false,
        });
        this.props.showSnackBar('User has been deleted successfully!', theme.success.default, 3000);
        this.props.requestOrganizationUsers();
        this.props.requestOrganizationTeams();
        this.props.requestOrganizationSquad();
      } catch (err: any) {
        this.setState({ showConfirmModal: false });

        const isConflictError = err?.response?.data?.meta?.status === 409;

        if (isConflictError) {
          const conflictTeams = err?.response?.data?.meta?.conflict_data || [];
          this.setState({ conflictTeams, showConflictModal: true });
          return;
        }

        this.setState({ deletedUserID: '' });
        const toastDuration = 6000;
        const errMessage: string = err?.response?.data?.meta?.error_message ?? 'Network Error';
        this.props.showToast(errMessage, toastDuration);
        exception.handle('E_DELETE_USER', err);
      }
    } else {
      this.setState({ deletedUserID: '', showConfirmModal: false });
    }
  };

  public componentDidUpdate = async (prevProps: IProps, prevState: IState) => {
    if (
      !equal(this.props.organization.users.u, prevProps.organization.users.u) ||
      this.props.organization.plan?.p?.plan_slug !== prevProps.organization.plan?.p?.plan_slug
    ) {
      await this.setState({
        users: this.sortUsersAlphabetical(this.props.organization.users.u),
      });
      this.updateUserLimitExceeded();
    }

    if (
      !equal(prevState.roleFilter, this.state.roleFilter) ||
      !equal(prevState.searchTerm, this.state.searchTerm)
    ) {
      const users = this.props.organization.users.u.filter((user: IUsersOfOrganization) => {
        if (
          (this.state.roleFilter.length === 0 ||
            this.state.roleFilter.indexOf(user.role_id as string) !== -1) &&
          (`${user.first_name} ${user.last_name}`
            .toLowerCase()
            .trim()
            .includes(this.state.searchTerm.toLowerCase().trim()) ||
            (user.email as string)
              .toLowerCase()
              .trim()
              .includes(this.state.searchTerm.toLowerCase().trim()))
        ) {
          return true;
        }

        return false;
      });
      this.setState({ users: this.sortUsersAlphabetical(users) });
    }
  };

  public componentDidMount = () => {
    this.props.requestOrganizationUsers();
    document.addEventListener('click', this.outsideClickListener, false);
    this.updateUserLimitExceeded();
    this.isOrgHasEnabledSSO();
    AppTracker.track(T_WA_GS_USERS_PAGE_VIEWED);
  };

  updateUserLimitExceeded = () => {
    if (
      BillingService.isLimitExceeded(
        this.props,
        'user',
        () => this.props.organization.users.u.filter(u => u.role !== 'stakeholder').length,
      ) &&
      BillingService.isLimitExceeded(
        this.props,
        'stakeholder',
        () => this.props.organization.users.u.filter(u => u.role === 'stakeholder').length,
      )
    ) {
      this.setState({ userLimitExceeded: true });
    } else {
      this.setState({ userLimitExceeded: false });
    }
  };

  public componentWillUnmount = () => {
    document.removeEventListener('click', this.outsideClickListener, false);
  };
  public sortUsersAlphabetical = (users: IUsersOfOrganization[]) => {
    return users.sort((first, second) =>
      first.first_name.toLocaleLowerCase() > second.first_name.toLocaleLowerCase() ? 0 : -1,
    );
  };
  public deleteUser = (id: string) => {
    this.setState({ deletedUserID: id, showConfirmModal: true });
  };

  public messageModalHideHandler = () => {
    this.setState({ showMessageModal: false });
  };

  public userSearchHandler = () => (event: any) => {
    this.setState({ searchTerm: event.target.value });
  };

  public userFilterButtonClickHandler = () => (event: any) => {
    this.setState({ showFilter: !this.state.showFilter });
    event.stopPropagation();
  };

  public userFilterDropDownHandler = (r: any) => {
    const roleFilter = [...this.state.roleFilter];
    if (!this.state.roleFilter.includes(r._id)) {
      roleFilter.push(r._id);
      this.setState({
        roleFilter,
      });
    } else {
      roleFilter.splice(roleFilter.indexOf(r._id), 1);
      this.setState({
        roleFilter,
      });
    }
  };

  public outsideClickListener = (event: any) => {
    if (this.dropdownRef.current) {
      if (
        !(this.dropdownRef.current as unknown as HTMLElement).contains(event.target) &&
        this.state.showFilter
      ) {
        this.setState({ showFilter: false });
      }
    }
  };

  public stopPropagationHandler = (event: any) => {
    event.stopPropagation();
  };

  public showFilterHandler = () => {
    this.setState({
      showFilter: false,
    });
  };

  public closeConflict = () =>
    this.setState({ showConflictModal: false, conflictTeams: [], deletedUserID: '' });

  public closeStakeholderConflict = () => this.setState({ showStakeholderConflictModal: false });

  public getUsersCount = (filteredUsers: IUsersOfOrganization[]) => {
    const { roles } = this.props;
    const { roleFilter } = this.state;
    if (!filteredUsers.length) return null;
    if (roleFilter.length && filteredUsers.length) {
      const usersCountWithRole = roles.r
        .filter(
          role =>
            roleFilter.includes(role._id) && filteredUsers.find(user => user.role_id === role._id),
        )
        .map(role => ({
          role: role.name,
          count: filteredUsers.filter(user => user.role_id === role._id).length,
        }));
      if (usersCountWithRole.length) {
        const usersWithRoleAsString = usersCountWithRole.map(ur => `${ur.count} ${ur.role}`);
        return (
          [usersWithRoleAsString.slice(0, -1).join(', '), usersWithRoleAsString.slice(-1)[0]].join(
            usersWithRoleAsString.length < 2 ? '' : ' and ',
          ) + ' in this organization'
        );
      }
    }
    return `(${filteredUsers.length})`;
  };

  public render() {
    const { users, conflictTeams } = this.state;

    const user = users.find((user: IUsersOfOrganization) => user.id === this.state.deletedUserID)!;
    const userCountText = this.getUsersCount(users);

    const userLimit = BillingService.getLimit(this.props, 'user');
    const stakeholderLimit = BillingService.getLimit(this.props, 'stakeholder');
    const finalLimit =
      (typeof userLimit === 'number' ? userLimit : 0) +
      (typeof stakeholderLimit === 'number' ? stakeholderLimit : 0);

    return (
      <>
        <ContainerLoad isLoading={false} />
        <Grid
          type="column"
          flexWidth={12}
          className="org_settings__container_padding global--soft-scroll"
        >
          <Grid
            flexWidth={12}
            height="40px"
            justifyContent="space-between"
            style={{ alignItems: 'center' }}
          >
            <Heading fontSize={20} height={32} fontWeight={500} color={theme.shades.black}>
              Users
            </Heading>
            {userCountText && (
              <Para
                fontSize={20}
                color={theme.shades.black}
                style={{ marginLeft: 10, marginRight: 'auto' }}
              >
                {userCountText}
              </Para>
            )}
            <Grid className="org">
              <Grid
                onClick={this.userFilterButtonClickHandler()}
                ref={this.dropdownRef}
                className="organizationUserTab_filter"
              >
                <DropDown
                  width="170px"
                  padding="20px"
                  maxWidth="170px"
                  hook={
                    <Grid width="120px" className="filter-button" justifyContent="space-between">
                      Filter User
                      <i className={`fa fa-angle-${this.state.showFilter ? 'up' : 'down'}`} />
                    </Grid>
                  }
                >
                  <Grid type="column">
                    <Para
                      className="item-box-tagline"
                      style={{ fontWeight: 600, marginTop: 0, marginBottom: 10 }}
                    >
                      Filter By User Type
                    </Para>
                    {this.props.roles.r.map((role, index) => {
                      return (
                        <Grid
                          key={index}
                          className="no-select cursor-pointer"
                          style={{ padding: '8px 15px', paddingLeft: 0 }}
                        >
                          <label className="item-box-tagline cursor-pointer">
                            <Checkbox
                              checked={this.state.roleFilter.indexOf(role._id) !== -1}
                              onChange={this.userFilterDropDownHandler.bind(null, role)}
                            />
                            <span className="ml-20">{role.name}</span>
                          </label>
                        </Grid>
                      );
                    })}
                  </Grid>
                </DropDown>
              </Grid>
              <Grid
                style={{ marginLeft: '15px', marginRight: '15px' }}
                className="organizationUserTab_search__filter"
              >
                <FocusedSearch
                  width="200px"
                  height="32px"
                  style={{
                    padding: 0,
                  }}
                  placeholder="Search users"
                  fontSize="14px"
                  value={this.state.searchTerm}
                  onChange={this.userSearchHandler()}
                />
              </Grid>
              <Tippy
                content={
                  this.state.showUpgradeModal ? (
                    <UpgradeOnlyModal
                      hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
                      align="left"
                      showModal={this.state.showUpgradeModal}
                      message={BillingService.getMessage(finalLimit, 'user', this.props)}
                      header={BillingService.getHeader(finalLimit, 'user', this.props)}
                    />
                  ) : (
                    ''
                  )
                }
                interactive={true}
                arrow={true}
                animation={'scale'}
              >
                <TextButton
                  height="32px"
                  onClick={this.addUserIfPlanPermits}
                  onMouseOver={() =>
                    this.setState({ showUpgradeModal: this.state.userLimitExceeded })
                  }
                  style={this.state.userLimitExceeded ? { opacity: 0.5 } : { opacity: 1.0 }}
                >
                  <Para fontWeight={400} fontSize={16} color={theme.shades.white}>
                    Add Users
                  </Para>
                </TextButton>
              </Tippy>
            </Grid>
          </Grid>
          <Para style={{ marginBottom: '10px', fontSize: '12px', color: 'var(--shades-grey)' }}>
            Add and manage users to your organization within Squadcast. Learn more about users{' '}
            <a
              href="https://support.squadcast.com/manage-users/add-and-delete-users"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </Para>
          <Grid type="column" flexWidth={12}>
            <Table
              users={users}
              searchTerm={this.state.searchTerm}
              roleFilter={this.state.roleFilter}
              resendVerificationMail={this.resendVerificationMail}
              deleteUser={this.deleteUser}
              updateUser={this.updateUser}
              saving={this.state.saving}
              orgHasEnabledSSO={this.state.orgHasEnabledSSO}
            />
          </Grid>
        </Grid>

        <DialogModalFrame id="delete-user" width="400px" onClose={this.deleteUser.bind(null, '')}>
          {this.state.deletedUserID !== '' && this.state.showConfirmModal && (
            <ConfirmModal
              displayText={
                <>
                  <div>
                    Are you sure you want to delete {user.first_name} {user.last_name}
                    {user.username_for_display ? ` (${user.username_for_display})` : ''}?
                  </div>
                  {user.role !== 'stakeholder' && <Note isConfirmationModal />}
                </>
              }
              buttonText="Delete"
              hide={this.confirmDelete}
            />
          )}
        </DialogModalFrame>
        {this.state.showMessageModal && (
          <MessageModal
            show={this.state.showMessageModal}
            hide={this.messageModalHideHandler}
            displayText={this.state.messageText}
          />
        )}

        <DialogModalFrame
          id="convert_to_stakeholder_conflict"
          width="1000px"
          onClose={this.closeStakeholderConflict}
        >
          {this.state.showStakeholderConflictModal &&
            this.state.stakeholderConflictData.length > 0 && (
              <StakeholderDependencies
                header={this.state.role}
                userId={this.state.stakeholderConflictUserId}
                dependencies={this.state.stakeholderConflictData}
                closeModal={this.closeStakeholderConflict}
                getStakeholderConflicts={this.getStakeholderConflicts}
              />
            )}
        </DialogModalFrame>

        <DialogModalFrame id="delete_user_conflict" width="500px" onClose={this.closeConflict}>
          {this.state.showConflictModal && (
            <>
              <Grid className="mb-20">
                <Grid alignItems="flex-start" type="column">
                  <Heading height={35} fontSize={24}>
                    Delete User
                  </Heading>
                </Grid>
              </Grid>

              <Grid alignItems="baseline">
                <Grid flexWidth={1}>
                  <Avatar base={10} fontSize={14} reduceColorString={user.id as string}>
                    {`${user.first_name} ${user.last_name}`}
                  </Avatar>
                </Grid>

                <Grid flexWidth={11}>
                  <Label fontSize={16} fontWeight={500} color={theme.shades.black}>
                    {`${user.first_name} ${user.last_name}`}
                  </Label>
                </Grid>
              </Grid>

              <Grid type="column" className="mt-10" alignItems="flex-end">
                <Grid type="column" flexWidth={11}>
                  <Grid>
                    <Label fontSize={16} fontWeight={500}>
                      {`(${user.email}) is part of the following teams:`}
                    </Label>
                  </Grid>

                  <Grid type="column" className="mt-20">
                    {conflictTeams.map((t, idx) => (
                      <Grid key={idx} className="mt-10">
                        <Grid>
                          <TeamIcon />
                        </Grid>
                        <Grid style={{ marginLeft: 8 }}>{t.name}</Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>

              <Grid className="mt-20" alignItems="center" justifyContent="flex-start">
                <ErrorBlock fontSize={14}>
                  Users who are a part of a Team cannot be deleted. Remove them from the Team(s) and
                  try again
                </ErrorBlock>
              </Grid>
            </>
          )}
        </DialogModalFrame>
      </>
    );
  }
}

const UserComponent = connect(
  (state: IAppState) => ({
    organization: state.organization,
    roles: state.roles,
    userInfo: state.userInfo,
    selectedOrg: state.userOrganizations.o.find(
      o => o.organizationId === state.INIT_ORG.orgId,
    ) as IOrganization,
  }),
  {
    requestOrganizationUsers,
    requestOrganizationTeams,
    requestOrganizationSquad,
  },
)(OrganizationUserTab);

const UsersHOC = (props: IProps) => {
  // TOAST
  const _createToast = ToastContext();
  const toast = useToast();

  const showToast = (msg: string, duration = 2500) => {
    _createToast(
      <ErrorToastBlock style={{ backgroundColor: theme.danger.light }} maxWidth="400px">
        <Para fontWeight={400} style={{ whiteSpace: 'pre-wrap' }}>
          {msg}
        </Para>
      </ErrorToastBlock>,
      duration,
    );
  };

  // SNACK
  const _createSnack = SnackContext();
  const [showSnack, setShowSnack] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [background, setBackground] = useState<string>('');

  useEffect(() => {
    if (!showSnack) {
      _createSnack(null);
    } else {
      _createSnack(
        <SnackBar background={background} maxWidth="300px">
          <Grid alignItems="center" justifyContent="center">
            <Para color={theme.shades.white} fontWeight={400} style={{ textAlign: 'center' }}>
              {message}
            </Para>
          </Grid>
        </SnackBar>,
      );
    }
    return () => _createSnack(null);
  });

  const hideSnackBar = () => {
    setShowSnack(false);
    setMessage('');
  };

  const showSnackBar = (msg: string, bg: string, timeout: number) => {
    setMessage(msg);
    setBackground(bg);
    setShowSnack(true);
    setTimeout(hideSnackBar, timeout);
  };

  const showNewToast = (status: TOAST_STATUS, text: string) => {
    toast({
      status,
      text,
    });
  };

  return (
    <UserComponent
      {...{
        ...props,
        showToast,
        showSnackBar,
        showNewToast,
      }}
    />
  );
};

export default UsersHOC;
