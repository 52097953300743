import Axios from 'axios';
import { API } from 'core';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys, reactQueryConfig } from '../constant';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/service.reactqery';

interface IDeleteJiraAccConfigProps {
  accountId: number;
  configId: number;
}

const deleteJiraAccConfig = ({ accountId, configId }: IDeleteJiraAccConfigProps) => {
  return Axios.delete(
    `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud/new/${accountId}/jira-cloud-configs/${configId}`,
  );
};

const useDeleteJiraAccConfig = () => {
  const queryClient = useQueryClient();

  return useMutation([queryKeys.DELETEJIRAACCOUNTCONFIG], deleteJiraAccConfig, {
    ...reactQueryConfig,
    onSuccess: () => {
      queryClient.refetchQueries([queryKeys.GETALLJIRACLOUDACCOUNTSCONFIG]);
      reactQueryConfigSuccess('Jira Cloud Account Config Deleted successfully!');
    },
    onError: (error: any) => {
      const description =
        error?.response?.data?.meta?.error_message || 'Failed to Update Jira Cloud Account Config';
      reactQueryConfigError({ description: description, title: 'Update Jira Account Config' });
    },
  });
};
export { useDeleteJiraAccConfig };
