import { Box, Button, Flex } from '@chakra-ui/react';
import React, { useContext } from 'react';
import DataTable from '../../../components/Table';

// import { tableColumns } from '../components/Table/columns';
import { IPageIssueList } from '../../../Interface';
import { tableColumns } from './column';

type action = (id: number) => void;
interface IProps {
  list: IPageIssueList[];
  pageChange: (queryPageIndex: number) => void;
  pageSizeChange: (queryPageSize: number) => void;
  queryPageIndex: number;
  queryPageSize: number;
  totalCount: number;
  onDelete: action;
  onIssueUpdate: action;
  onDetailsUpdate: action;
  timezone: string;
  canEdit: boolean;
}

interface IPropsAction {
  rowData: any;
  onIssueUpdate: action;
  onDetailsUpdate: action;
  canEdit: boolean;
}

const StatusAction = (props: IPropsAction) => {
  const { rowData, onIssueUpdate, onDetailsUpdate, canEdit } = props;
  const issueId = rowData?.row?.original?.id || -1;

  return (
    <Flex gap={3} align="center" boxShadow="-10px 0px 10px 1px var(--chakra-colors-gray-100)">
      <Button
        onClick={() => {
          onDetailsUpdate(issueId);
        }}
        variant={'outline'}
      >
        {canEdit ? 'Edit' : 'View'} Issue Details
      </Button>
      <Button
        onClick={() => {
          onIssueUpdate(issueId);
        }}
      >
        {canEdit ? 'Update' : 'View'} State & Messages
      </Button>
    </Flex>
  );
};

const IssueList = (props: IProps) => {
  const {
    queryPageIndex,
    queryPageSize,
    pageSizeChange,
    pageChange,
    totalCount,
    onDelete,
    onIssueUpdate,
    onDetailsUpdate,
    timezone,
    canEdit,
  } = props;
  return (
    <Box>
      <DataTable
        data={props.list}
        columns={tableColumns}
        hoverView={
          <StatusAction
            rowData={{}}
            onIssueUpdate={onIssueUpdate}
            onDetailsUpdate={onDetailsUpdate}
            canEdit={canEdit}
          />
        }
        onDelete={onDelete}
        timezone={timezone}
        paginationProps={{
          queryPageIndex,
          queryPageSize,
          totalCount: totalCount || 0,
          pageChange: (queryPageIndex: number) => pageChange(queryPageIndex),
          pageSizeChange: (queryPageSize: number) => {
            pageSizeChange(queryPageSize);
          },
        }}
      />
    </Box>
  );
};

export default IssueList;
