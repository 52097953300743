import { CrossIcon } from 'icons';

export const ClearSearchButton: React.FC<{ onClick: () => void; numberOfSelectedItems?: number }> =
  ({ onClick, numberOfSelectedItems = null }) => {
    if (numberOfSelectedItems === 0) return null;
    return (
      <CrossIcon
        width={16}
        height={16}
        style={{
          position: 'absolute',
          right: 10,
          top: 12,
          cursor: 'pointer',
          color: '#3D485E',
        }}
        onClick={onClick}
      />
    );
  };
