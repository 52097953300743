import React from 'react';
import styled from 'styled-components';
import { pulse } from '../../utils/Animations';

interface IProps {
  base?: string | '12px';
  color?: string | 'theme.shades.primary';
}

const Shell = styled.div<IProps>`
  height: fit-content;
  padding: 5px;
  width: fit-content;
  text-align: center;
  background: transparent;
  margin: 5px;

  & > div {
    height: ${props => props.base || '12px'};
    width: ${props => props.base || '12px'};
    background: ${props => props.color || props.theme.primary.default};
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ${pulse} 1.4s infinite ease both;
    animation: ${pulse} 1.4s infinite ease both;
  }

  & > div:nth-child(1) {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  & > div:nth-child(2) {
    margin-left: calc(${props => props.base || '12px'} / 6);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  & > div:nth-child(3) {
    margin-left: calc(${props => props.base || '12px'} / 6);
    -webkit-animation-delay: -0.08s;
    animation-delay: -0.08s;
  }
`;

const PulseLoader = (props: IProps) => {
  return (
    <Shell {...props}>
      <div />
      <div />
      <div />
    </Shell>
  );
};

/** @component */
export default PulseLoader;
