import {
  Accordion,
  AccordionItem,
  AccordionButton,
  HStack,
  Checkbox,
  AccordionIcon,
  AccordionPanel,
  Box,
  Text,
} from '@chakra-ui/react';
import { useState, useEffect, FC } from 'react';
import { EventChecked } from './consts';

interface AccordionProps {
  eventClass: EventChecked;
  eventTypes: EventChecked[];
  onCheckedNotifications: (
    accordianEvents: { eventClass: EventChecked; eventTypes: EventChecked[] },
    idx: number,
  ) => void;
  index: number;
}

export const EventNotificationAccordian: FC<AccordionProps> = props => {
  const { eventClass, eventTypes, onCheckedNotifications } = props;
  const [eventClassChecked, setEventClassChecked] = useState(eventClass);
  const [eventTypeChecked, setEventTypeChecked] = useState(eventTypes);
  const allchecked = eventTypeChecked.every(({ isChecked }) => isChecked);
  const isIndeterminate = !allchecked && eventTypeChecked.some(({ isChecked }) => isChecked);

  useEffect(() => {
    setEventClassChecked(eventClass);
    setEventTypeChecked(eventTypes);
  }, [eventClass, eventTypes]);

  function onEventClassChecked(isChecked: boolean) {
    const updatedEventTypes: EventChecked[] = eventTypeChecked.map(event => ({
      isChecked: isChecked,
      label: event.label,
      type: event.type,
    }));

    setEventClassChecked(prev => ({ isChecked: isChecked, label: prev.label, type: prev.type }));
    setEventTypeChecked(updatedEventTypes);
  }

  function onEventTypeChecked(idx: number, isChecked: boolean) {
    const eventTypes = eventTypeChecked;

    const updatedEvent = eventTypes[idx];
    updatedEvent.isChecked = isChecked;
    eventTypes[idx] = updatedEvent;

    if (!isChecked) {
      let isEveryCheckboxFalse = true;
      eventTypes.forEach(event => {
        if (event.isChecked) {
          isEveryCheckboxFalse = false;
        }
      });

      if (isEveryCheckboxFalse) {
        setEventClassChecked(prev => ({ isChecked: false, label: prev.label, type: prev.type }));
      }
    }

    setEventTypeChecked([...eventTypes]);
  }

  useEffect(() => {
    onCheckedNotifications(
      { eventClass: eventClassChecked, eventTypes: eventTypeChecked },
      props.index,
    );
  }, [eventClassChecked, eventTypeChecked]);

  return (
    <Accordion allowMultiple>
      <AccordionItem>
        <AccordionButton>
          <HStack>
            <Checkbox
              isChecked={allchecked}
              isIndeterminate={isIndeterminate}
              onChange={e => onEventClassChecked(e.target.checked)}
              isDisabled={eventClassChecked.type === 'incident'}
            />
            <Text fontSize="sm">{eventClassChecked.label}</Text>
          </HStack>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          {eventTypeChecked.map((eventType, idx) => (
            <Box ml="1rem" key={eventType.label}>
              <HStack>
                <Checkbox
                  isChecked={eventTypeChecked[idx].isChecked}
                  onChange={e => onEventTypeChecked(idx, e.target.checked)}
                  isDisabled={eventTypeChecked[idx].type === 'triggered'}
                />
                <Text fontSize="sm">{eventType.label}</Text>
              </HStack>
            </Box>
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
