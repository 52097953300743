import { Box, HStack, Text } from '@chakra-ui/react';
import { useCreateEntityMap, transformMessage } from 'library/atoms/MdBlock/helper';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { EmailAction } from 'views/main/organization/workflow/types';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
interface IProps {
  action: EmailAction;
}
const EmailCard: FC<IProps> = ({ action }) => {
  const entities = useCreateEntityMap();

  return (
    <>
      <Box py={4}>
        <Text color="secondary.700" textStyle="bold_800" variant="h3">
          To
        </Text>
        <HStack flexWrap={'wrap'}>
          {action.data.to.map((email: string, index: number) => {
            return (
              <>
                {index > 0 && <Text>,</Text>}
                <Text key={index}>{email}</Text>
              </>
            );
          })}
        </HStack>
      </Box>
      <Box pb={4}>
        <Text color="secondary.700" textStyle="bold_800" variant="h3">
          Subject
        </Text>
        {action.data.subject}
      </Box>
      <Box pb={4}>
        <Text color="secondary.700" textStyle="bold_800" variant="h3">
          Message
        </Text>
        <ReactMarkdown className="mde" plugins={[breaks, gfm]}>
          {transformMessage(action.data.body.replace(/ /g, '\u00A0'), entities)}
        </ReactMarkdown>
      </Box>
    </>
  );
};

export default EmailCard;
