import { T_WA_UP_LCR_CONFIG_DELETED } from 'core/const/tracker';
import LCRService from 'core/services/service.lcr';
import { useToast } from 'library/atoms';
import { useMutation } from 'react-query';
import { AppTracker } from 'shared/analytics/tracker';

async function deleteLCR(id: string) {
  const _extensionService = new LCRService();

  const res = await _extensionService.deleteLCRData(id);
  AppTracker.track(T_WA_UP_LCR_CONFIG_DELETED, {
    'LCR ID': id,
  });
  return res?.data;
}

const useDeleteLCR = () => {
  const toast = useToast();

  return useMutation(deleteLCR, {
    onSuccess: () => {
      toast({ text: 'routing number deleted successfully!', status: 'success' });
    },
    onError: (error: any) => {
      toast({ text: error.response.data.message, status: 'error' });
    },
  });
};

export { useDeleteLCR };
