import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import {
  IComponentErrorState,
  IComponentNetworkState,
} from '../../../../../core/interfaces/IComponentState';
import { render } from './render.index';
import './index.css';
import { IOrganization } from '../../../../../core/interfaces/IOrganization';
import { IUserAbilitySlugMap, IUserUpdateReq } from '../../../../../core/interfaces/IUserData';
import { UsersService } from '../../../../../core/services';
import { IRole } from '../../../../../core/interfaces/IRole';
import { requestOrganizationUsers, requestUserInfo } from '../../../../../core/actions';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_GS_PERMISSIONS_UPDATED } from '../../../../../core/const/tracker';
import { RouteComponentProps } from 'react-router';

import { withRouter } from 'react-router';
interface IProps
  extends Pick<IAppState, 'organization' | 'roles' | 'userInfo'>,
    RouteComponentProps {
  selectedOrg: IOrganization;
  requestOrganizationUsers: typeof requestOrganizationUsers;
  requestUserInfo: typeof requestUserInfo;
}

interface IState {
  userAbilityMap: ({
    user_id: string;
  } & IUserUpdateReq)[];
  componentNetworkState: IComponentNetworkState | 'update-user-permissions';
  errors: IComponentErrorState;
  userAbilityChangeIdMap: string[];
  changesSaved: boolean;
  showRemoveUserAbilityAlert: 'unset' | 'set' | 'agreed';
  searchQuery: string;
  isModalDirty: boolean;
}

export class OrganizationPermissionsTab extends Component<IProps, IState> {
  private _userService = new UsersService();

  constructor(props: IProps) {
    super(props);

    this.state = {
      userAbilityMap:
        this.props.organization.users.u.map(u => ({
          user_id: u.id,
          abilities: u.abilities.map(ua => ua.slug) || [],
          role: this.props.roles.r.find((r: IRole) => r._id === u.role_id)?.slug ?? 'user',
        })) || [],
      componentNetworkState: 'idle',
      errors: {},
      userAbilityChangeIdMap: [],
      changesSaved: false,
      showRemoveUserAbilityAlert: 'unset',
      searchQuery: '',
      isModalDirty: false,
    };
  }

  render = render;

  confirmUpdateUserPermission = async () => {
    const { userAbilityMap, userAbilityChangeIdMap } = this.state;
    if (userAbilityChangeIdMap.length === 0) return;

    this.setState({ componentNetworkState: 'update-user-permissions' });
    try {
      await this._userService.updateUserPermissions(
        userAbilityMap
          .filter(ua => userAbilityChangeIdMap.includes(ua.user_id))
          .map(u => ({ user_id: u.user_id, abilities: u.abilities })),
      );
      this.props.requestUserInfo();
      this.props.requestOrganizationUsers();
      this.setState({
        userAbilityChangeIdMap: [],
        changesSaved: true,
        isModalDirty: false,
      });
      AppTracker.track(T_WA_GS_PERMISSIONS_UPDATED, {
        'Permissions Type': userAbilityMap
          .filter(ua => userAbilityChangeIdMap.includes(ua.user_id))
          .map(u => u.abilities)
          .pop(),
      });
      this.setState({ isModalDirty: false });
    } catch (err: any) {
    } finally {
      this.setState({ componentNetworkState: 'idle' });
    }
  };

  updateUserPermission = (userId: string, permissionToken: keyof IUserAbilitySlugMap) => () => {
    const { userAbilityMap } = this.state;
    const selectedUsersIndex = userAbilityMap.findIndex(u => u.user_id === userId);

    if (selectedUsersIndex < 0) return;

    const selectedUserAbilities = userAbilityMap[selectedUsersIndex].abilities;

    if (selectedUserAbilities.includes(permissionToken)) {
      if (
        userAbilityMap[selectedUsersIndex].user_id === this.props.organization.currentUser.u?.id &&
        permissionToken === 'manage-users' &&
        this.state.showRemoveUserAbilityAlert !== 'agreed'
      ) {
        this.setState({ showRemoveUserAbilityAlert: 'set' });
        return;
      }

      selectedUserAbilities.splice(
        selectedUserAbilities.findIndex(p => p === permissionToken),
        1,
      );
    } else {
      selectedUserAbilities.push(permissionToken);
    }

    userAbilityMap[selectedUsersIndex].abilities = selectedUserAbilities;

    this.setState(({ userAbilityChangeIdMap }) => {
      const userAbilityChangeIdMapSet = new Set(userAbilityChangeIdMap);
      userAbilityChangeIdMapSet.add(userAbilityMap[selectedUsersIndex].user_id);

      return {
        userAbilityMap,
        userAbilityChangeIdMap: Array.from(userAbilityChangeIdMapSet),
        changesSaved: false,
        isModalDirty: true,
      };
    });
  };

  setShowRemoveUserAbilityAlert =
    (showRemoveUserAbilityAlert: IState['showRemoveUserAbilityAlert']) =>
    (onHide = false) =>
      this.setState({ showRemoveUserAbilityAlert: onHide ? 'agreed' : showRemoveUserAbilityAlert });

  onSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ searchQuery: event.target.value });
}
const OrganizationPermissionsTabWithHistory = withRouter(OrganizationPermissionsTab);
export default connect(
  ({ organization, roles, userOrganizations, INIT_ORG, userInfo }: IAppState) => ({
    organization,
    roles,
    userInfo,
    selectedOrg: userOrganizations.o.find(
      o => o.organizationId === INIT_ORG.orgId,
    ) as IOrganization,
  }),
  {
    requestOrganizationUsers,
    requestUserInfo,
  },
)(OrganizationPermissionsTabWithHistory);
