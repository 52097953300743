import { Box, Link } from '@chakra-ui/react';
import { Grid, Heading } from 'uie/components';
import React from 'react';

interface HeaderProps {
  isViewMode: boolean;
}

const Header: React.FC<HeaderProps> = ({ isViewMode }) => {
  return (
    <>
      <Box mb={2.5}>
        <Grid justifyContent="space-between" alignItems="center">
          <Heading height={23} fontSize={15} fontWeight={800}>
            {isViewMode ? 'View Custom Content Templates' : 'Custom Content Templates'}
          </Heading>
        </Grid>
      </Box>
      {!isViewMode && (
        <Box mb={2.5}>
          <Grid justifyContent="space-between" alignItems="center" style={{ marginBottom: '24px' }}>
            <Heading
              height={20}
              style={{
                fontSize: '14px',
                color: '#627C98',
                fontWeight: 400,
              }}
            >
              Define custom incident message and description templates using the payload of a
              configured alert source for this service.
              <br /> If there is a template configured, incoming incident will have the message and
              description customised accordingly. Learn more &nbsp;
              <Link
                href="https://support.squadcast.com/services/event-tagging"
                target="_blank"
                rel="noopener noreferrer"
                variant="underlinedLink"
              >
                here
              </Link>
              .
            </Heading>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Header;
