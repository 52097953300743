import { ThrottleConfig } from 'core/interfaces/IOrgConfig';

export const VALID_TAG_KEYS = new RegExp('^[a-zA-Z0-9_]+$');
export const SEARCH_QUERY_KEY = 'search';

export const DEFAULT_QUERY_DAYS = 7;
export const HEADER_FILTERS_COUNT = 6;
export const INVAL_COUNT = -1;
export const EMPTY_STRING = '';
export const INVALID_TAGS_ERROR_MSG =
  'Please make sure that tags are not empty or remove them before proceeding!';
export const DUPLICATE_TAGS_ERROR_MSG =
  'Same tag already exists. Choose different value or remove redundancy before proceeding!';
export const INVALID_TAG_KEY_ERROR_MSG = 'Tags key should not contain special characters!';
export const ASSIGNEE_TOOLTIP = 'Displays the current assignee of the incident.';
export const ASSIGNEE_TOOLTIP_FILTER_INFO =
  'But the filter works to show incidents for both current and historic assignees';

export const DEFAULT_REACT_QUERY_CONFIG = {
  retry: 1,
  keepPreviousData: true,
  refetchOnWindowFocus: false,
};

// query config to cache out inactive data
export const IL_REACT_QUERY_CONFIG = {
  ...DEFAULT_REACT_QUERY_CONFIG,
  cacheTime: 0,
};

export const DEFAULT_THROTTLE_CONFIG: ThrottleConfig = {
  debounce_interval: 10000,
  max_wait: 10000,
};

export const QUERY_KEYS = {
  SERVICELIST: 'serviceListunique',
  ALERTSOURCELISTDATA: 'alertSourceListData',
  SLOLIST: 'sloList',
  FILTERLIST: 'filterlist',
  SAVEDFILTERS: 'listIncidentGroups',
};

export const QUERY_CONFIG = {
  ...DEFAULT_REACT_QUERY_CONFIG,
  enabled: true,
  retry: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
};

export const MERGE_INCIDENT_PARENT_TEXT_MAX_LENGTH = 70;

export const MERGE_INCIDENT_MAX_CHILD_INCIDENTS_COUNT = 50;

export const COLUMN_DEF_PADDING_LEFT_VALUE = 6;

export const GET_INCIDENT_ASSIGNABLE_ENTITIES_QUERY_KEY = 'getIncidentAssignableEntities';
