import { components } from 'react-select';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  HStack,
  Text,
  Accordion,
  AccordionButton,
  AccordionPanel,
  AccordionItem,
  Flex,
  VStack,
} from '@chakra-ui/react';
import Select from 'components/chakra/Select';
import {
  DropdownOption,
  DropdownSection,
  Option,
} from '../../owner-filters/hooks/useEntityOwnerFilter';
import { ReactNode } from 'react';
import { THEME_COLORS } from 'library/theme/colors';

interface Props {
  dropdownOptions: DropdownOption[];
  onChangeHandler: (values: Option) => void;
}

const Group = (props: any) => {
  const {
    children,
    label,
    selectProps: { inputValue },
  } = props;

  return (
    <Accordion
      allowToggle={true}
      defaultIndex={label === DropdownSection.YOU_AND_YOUR_SQUADS ? 0 : -1}
      {...(inputValue ? { index: 0 } : {})}
    >
      <AccordionItem>
        {({ isExpanded = false }) => (
          <>
            <AccordionButton
              _expanded={{ background: 'white !important' }}
              _hover={{ background: 'white !important' }}
              padding="8px 12px"
              sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}
            >
              <HStack p={0}>
                <Text color="#646464" fontWeight={500}>
                  {label}
                </Text>
                {isExpanded ? (
                  <ChevronDownIcon width={6} height={6} color="#646464" />
                ) : (
                  <ChevronRightIcon width={6} height={6} color="#646464" />
                )}
              </HStack>
            </AccordionButton>
            <AccordionPanel p={0} backgroundColor="white">
              {children}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

const Menu = (props: any) => {
  const { children } = props;
  return (
    <components.Menu {...props}>
      <Flex
        sx={{
          ...{
            boxShadow: '0px 4px 10px 0px #0000000D',
          },
          ...{
            boxShadow: '2px 0px 10px 0px #0000000D',
          },
        }}
      >
        {children}
      </Flex>
    </components.Menu>
  );
};

const optionLabel = (option: Option) => {
  return (
    <VStack alignItems={'left'} spacing={0}>
      <HStack w="100%">
        <Text
          {...(option.label.startsWith('Current Team')
            ? {
                ...{
                  color: '#646464',
                  fontSize: 'md',
                  fontWeight: 600,
                },
              }
            : {})}
        >
          {option.label}
        </Text>
      </HStack>
      {option.username && <Text color={THEME_COLORS.secondary[1200]}>{option.username}</Text>}
    </VStack>
  );
};

const OwnerDropdown = ({ dropdownOptions, onChangeHandler }: Props) => {
  return (
    <Select
      placeholder="SLO Owner"
      options={dropdownOptions}
      components={{ Group, Menu }}
      value={[]}
      onChange={value => onChangeHandler(value as Option)}
      formatOptionLabel={optionLabel as (data: unknown) => ReactNode}
      isOwnerFilterDropdown
      menuWidth="200%"
      closeMenuOnSelect
    />
  );
};

export default OwnerDropdown;
