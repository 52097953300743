import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Para, Grid, ErrorBlock, Theme, TextButton } from 'uie/components';
import AceEditor from 'react-ace';
import { IAppState } from '../../../core/interfaces/IAppState';
import { setCompleters } from 'ace-builds/src-noconflict/ext-language_tools';
import cx from 'classnames';
import {
  startMaps,
  IAutoCompletes,
  statusMap,
  transformTypings,
  sortMaps,
  inMap,
} from './autoCompletes';
import { IncidentSearchService, IncidentService } from '../../../core/services';
import { LocalStore } from '../../../store/local';
import { Redirect } from 'react-router-dom';
import { IComponentErrorState, IComponentState } from '../../../core/interfaces/IComponentState';
import { renderIncident } from './renders.helpers';
import { API } from '../../../core/api';
import { onExecute, queryOn } from './helpers.index';

import './index.css';
import './stokens';
import 'ace-builds/src-noconflict/theme-github';
import { exception } from '../../../core/exception';
import { AppTracker } from '../../../shared/analytics/tracker';
import { T_WA_GS_QUERY_SEARCH } from '../../../core/const/tracker';

const { theme } = Theme;
const cmd = navigator.platform.toLowerCase().includes('mac') ? '⌘' : 'ctrl + ';
const docFiltersMap = {
  message: 'search string (in: incidents(d) | postmortem)',
  service: 'service',
  status: 'triggered | acknowledge | resolved | suppressed',
  in: 'incidents(d) | postmortems',
  integration: 'by integration.type',
  before: 'search before (YYYY-MM-DD)',
  after: 'search after (YYYY-MM-DD)',
  sort: 'sort by timeOfCreation',
};

interface IProps extends Pick<IAppState, 'organization' | 'integrations' | 'userInfo'> {
  hide: () => void;
}

interface IState {
  componentState: IComponentState;
  search: string;
  isFocus: boolean;
  gotoIncidentId: string | null;
  openPostmortem: boolean;
  errors: IComponentErrorState;
  showHelp: boolean;
  searchHistory: { [key: string]: string[] };
  searches: any[];
}

export class Search extends Component<IProps, IState> {
  private _incidentSearchService = new IncidentSearchService();
  private _incidentService = new IncidentService();

  private _integrationMaps = this.props.integrations.i.map((integration, i) =>
    transformTypings('integration')(integration.type, i),
  );
  private _userMaps = this.props.organization.users.u.map((u, i) =>
    transformTypings('user')(u.email, i),
  );
  private _serviceMap = this.props.organization.services.s.map((s, i) =>
    transformTypings('service')(s.slug, i),
  );

  public _completionMap: { [key: string]: IAutoCompletes[] } = {
    start: startMaps,
    profile: this._userMaps,
    service: this._serviceMap,
    status: statusMap,
    in: inMap,
    integration: this._integrationMaps,
    help: this._integrationMaps,
    sort: sortMaps,
  };
  public _completions: IAutoCompletes[] = this._completionMap.start;

  onExecute = onExecute.bind(this);
  queryOn = queryOn.bind(this);

  constructor(props: IProps) {
    super(props);
    this.state = {
      componentState: 'idle',
      search: '',
      isFocus: false,
      gotoIncidentId: null,
      openPostmortem: false,
      errors: {},
      showHelp: false,
      searchHistory: {},
      searches: [],
    };
  }

  componentDidMount() {
    this.getOrgSearchHistory();
  }

  toggleFocus = (isFocus: boolean) => () => this.setState({ isFocus });

  getOrgSearchHistory = async () => {
    try {
      const searches = await LocalStore.getSearchHistory();
      const searchHistory = searches || {};
      this.setState({ searchHistory, showHelp: Object.values(searchHistory).length === 0 });
    } catch (err: any) {
      exception.handle('E_DISABLED_APP_STORE', err);
      this.setState({ searchHistory: {}, showHelp: true });
    }
  };

  setSearchHistory = async (search: string) => {
    try {
      const { searchHistory } = this.state;
      if (searchHistory[API.config.organizationId]) {
        const orgSearchHistory = searchHistory[API.config.organizationId];
        if (!orgSearchHistory.includes(search)) {
          orgSearchHistory.push(search);
          searchHistory[API.config.organizationId] =
            searchHistory[API.config.organizationId].slice(-5);
          this.setState({ searchHistory });
        }
      } else {
        searchHistory[API.config.organizationId] = [search];
      }
      await LocalStore.setSearchHistory(searchHistory);
    } catch (err: any) {
      exception.handle('E_DISABLED_APP_STORE', err);
    }
  };

  public navigateToIncident = (incidentId: string, openPostmortem: boolean) => async () => {
    this.setState({ componentState: 'busy' });
    try {
      const {
        data: { data: incidentInfo },
      } = await this._incidentService.getIncidentById(incidentId);
      this.setState({ openPostmortem, gotoIncidentId: incidentInfo.id });
      this.props.hide();
    } catch (err: any) {
      this.setState({ errors: { redirect: 'redirection failed' } });
    } finally {
      this.setState({ componentState: 'idle' });
    }
  };

  onSearchChange = (value: string, event: any) => {
    const cursorEnd = event.end.column || 0;
    let search = value.replace(/(\n)|\//, '');

    if (search.slice(0, cursorEnd).slice(-2) === '?:') {
      this.setState({ showHelp: true });
      search = search.slice(0, cursorEnd).slice(0, -2);
    }

    const matchRegex = new RegExp(/(\w*:{1}\s(("[^:]*")|(\([^:]*\))|\S*))$/gi);
    const matchers = matchRegex.exec(search.slice(0, cursorEnd));

    const completionToken = matchers ? matchers[0] : value.includes('goto:') ? 'goto:' : 'start';
    this._completions = this._completionMap[completionToken.split(':')[0]] || [];

    this.setState({ search });
  };

  editorOnLoad = (editor: any) => {
    editor.focus();
    editor.commands.on('afterExec', (e: any) => {
      const sep: string = this.state.search.slice(-2);
      if ((sep === ': ' || sep === '()') && e.command.name === 'Return') {
        editor.execCommand('startAutocomplete');
      }
    });
    setCompleters([
      {
        getCompletions: (_: any, __: any, ___: any, ____: any, callback: any) => {
          callback(null, this._completions);
        },
      },
    ]);
  };

  searchIncidents = async (
    query: {
      q: (string | undefined)[];
      filter?: (string | undefined)[];
      sort?: string;
    },
    collection: 'incidents' | 'postmortems',
  ) => {
    this.setState({ componentState: 'busy' });
    try {
      if (collection === 'postmortems' && query.sort?.includes('timeOfCreation')) {
        query.sort = query.sort.replace('timeOfCreation', 'created_at');
      }
      const {
        data: {
          data: {
            hits: { hits },
          },
        },
      } = await this._incidentSearchService.getSearchResults(query, collection);
      this.setState({ searches: hits.map(h => ({ ...h._source, _id: h._id })) });
      this.setSearchHistory(this.state.search);
    } catch (err: any) {
      const errors = { results: 'query returned with error!. try with a different one' };
      this.setState({ errors });
    } finally {
      this.setState({ componentState: 'idle' });
    }

    AppTracker.track(T_WA_GS_QUERY_SEARCH, { 'Search Type': this.state.search });
  };

  setSearchFromHistory = (search: string) => () => this.setState({ search }, this.onExecute);

  render() {
    const {
      search,
      isFocus,
      errors,
      showHelp,
      searches,
      searchHistory,
      componentState,
      gotoIncidentId,
      openPostmortem,
    } = this.state;
    const sH = searchHistory[API.config.organizationId] || [];

    if (gotoIncidentId !== null) {
      if (openPostmortem) {
        return (
          <Redirect
            to={{ pathname: `/incident/${gotoIncidentId}`, state: { showPostmortemModal: true } }}
            push={true}
          />
        );
      }
      return <Redirect to={`/incident/${gotoIncidentId}`} push={true} />;
    }

    return (
      <Grid type="column" flexWidth={12}>
        <Grid className={cx('search-inputs', { 's-inFocus': isFocus })} alignItems="center">
          <img src="/icons/search.svg" height="21" width="21" className="mr-10" alt="search" />
          <AceEditor
            focus={true}
            mode="stokens"
            theme="github"
            placeholder="Query"
            value={search}
            onChange={this.onSearchChange}
            name="UNIQUE_ID_OF_DIV"
            fontSize={16}
            maxLines={1}
            minLines={1}
            highlightActiveLine={false}
            enableBasicAutocompletion={true}
            enableLiveAutocompletion={true}
            showGutter={false}
            width="100%"
            markers={[]}
            onFocus={this.toggleFocus(true)}
            onBlur={this.toggleFocus(false)}
            onLoad={this.editorOnLoad}
            commands={[
              {
                name: 'run command',
                exec: this.onExecute,
                bindKey: { mac: 'cmd+enter', win: 'ctrl+enter' },
              },
              {
                name: 'hide',
                exec: this.props.hide,
                bindKey: { mac: 'esc', win: 'esc' },
              },
            ]}
            setOptions={{
              useWorker: false,
            }}
          />
        </Grid>
        <Grid className="search-content-brackets" type="column">
          {Object.values(errors).length > 0 && (
            <Grid className="search-content-error" width="calc(100% - 52px)" type="column">
              <ErrorBlock className="pa-0">Query Errors</ErrorBlock>
              {Object.values(errors).map((value, i) => (
                <ErrorBlock key={i} className="pa-0">
                  · {value}
                </ErrorBlock>
              ))}
            </Grid>
          )}
          {showHelp && (
            <Grid flexWidth={12} type="column">
              <Para fontWeight={400}>Help</Para>
              <Grid type="column" className="mt-10 search-help-markers">
                <Grid alignItems="center">
                  <Para className="search-token" fontWeight={400}>
                    ?:{' '}
                  </Para>
                  <Para className="ml-10">Toggle help</Para>
                </Grid>
                <Grid className="mt-10" alignItems="center">
                  <Para className="search-token" fontWeight={400}>
                    help:{' '}
                  </Para>
                  <Para className="ml-10">Get help for an integration type</Para>
                </Grid>
                <Para fontWeight={400} className="mt-20">
                  Search incident filter
                </Para>
                {Object.entries(docFiltersMap).map(([token, message], i) => (
                  <Grid className="mt-10" alignItems="center" key={i}>
                    <Para className="search-token" fontWeight={400}>
                      {token}:{' '}
                    </Para>
                    <Para className="ml-10">{message}</Para>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
          {!showHelp && (
            <Grid type="column">
              {componentState === 'idle' && searches.length === 0 && (
                <Grid type="column">
                  <Para color={theme.shades.grey}>
                    {sH.length === 0 ? 'Search something...' : 'Search history'}
                  </Para>
                  {sH.map((s, i) => (
                    <TextButton
                      onClick={this.setSearchFromHistory(s)}
                      className="search-history-map-object"
                      buttonType="ghost"
                      key={i}
                    >
                      <Para fontWeight={400}>{s}</Para>
                    </TextButton>
                  ))}
                </Grid>
              )}
            </Grid>
          )}
          <Grid type="column" className="search-results">
            {!showHelp && searches.map(renderIncident.bind(this))}
          </Grid>
          <Grid className="search-loader-progress mt-20">
            {componentState === 'busy' && (
              <Grid className="search-loader-progress--indeterminate" />
            )}
          </Grid>
          <Grid
            className="search-content-brackets-cmd-helpers"
            justifyContent="space-between"
            alignItems="center"
          >
            <Para color={theme.shades.grey}>
              {componentState === 'busy' && (gotoIncidentId !== null ? 'Redirecting' : 'Searching')}
            </Para>
            <Grid>
              <Para fontSize={12}>
                use
                <span className="search-command ml-10">{`${cmd} + Enter↲`}</span> Execute
                <span className="search-command ml-10">?:</span> Help
                <span className="search-command ml-10">{'Esc'}</span> Close
              </Para>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default connect(({ organization, integrations, userInfo }: IAppState) => ({
  organization,
  integrations,
  userInfo,
}))(Search);
