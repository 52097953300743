import { Button, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import { IncidentTag, JSONCodeBlock, Theme } from 'uie/components';
import { EventWebhookHooksService } from 'core';
import { T_WA_UP_WEBHOOK_LOG_VIEWED } from 'core/const/tracker';
import { IEventWebHookLogData, IEventWebHookLogs } from 'core/interfaces/IEventWebHooks';
import { subMonths } from 'date-fns';
import { useEffect, useState } from 'react';
import { Cell } from 'react-table';
import { AppTracker } from 'shared/analytics/tracker';
import DataTable from 'views/main/organization/service-catalog/components/Table';
import { CustomDrawerComponent } from 'views/shared/components';
import { useChakraToast } from 'views/shared/hooks';

import styles from './view-logs.module.css';

interface Props {
  webhookName: string;
  webhookId: string;
}

function ViewLogs(props: Props) {
  const { theme } = Theme;

  const [toDate, setToDate] = useState(new Date());
  const [eventWebhookLogs, setEventWebhookLogs] = useState<IEventWebHookLogs>({
    data: [] as IEventWebHookLogData[],
    meta: {} as any,
  });
  const [showPayload, setShowPayload] = useState<any>(null);
  const [limitReached, setLimitReached] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [showErrorToast] = useChakraToast();

  const columnDefs = [
    {
      Header: 'Sent On',
      Cell: (cell: Cell<IEventWebHookLogData>) => {
        const date = new Date(cell.row.original.created_at);
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
        return <Text>{`${formattedDate}, ${date.toLocaleTimeString()}`}</Text>;
      },
    },
    {
      Header: 'Status',
      Cell: (cell: Cell<IEventWebHookLogData>) => {
        let color = theme.danger;
        if (cell.row.original.status_code >= 400) color = theme.danger;
        else if (cell.row.original.status_code >= 300) color = theme.acknowledge;
        else if (cell.row.original.status_code >= 200) color = theme.success;

        return (
          <IncidentTag
            fontSize="16px"
            color={color.default}
            tag={cell.row.original.status_code}
            value={cell.row.original.status}
          />
        );
      },
    },
    {
      Header: 'View Payload',
      Cell: (cell: Cell<IEventWebHookLogData>) => {
        return (
          <Button
            variant="outline"
            size="sm"
            onClick={() => setShowPayload(cell.row.original.transactions)}
            className={styles.cancelBtn}
          >
            View Logs
          </Button>
        );
      },
    },
  ];

  const _eventWebhookService = new EventWebhookHooksService();
  const getLogs = async () => {
    setIsLoading(true);
    try {
      const data = await _eventWebhookService.getLogs(
        subMonths(toDate, 3),
        toDate,
        eventWebhookLogs.data.length,
        10,
        props.webhookId || '',
      );

      const eventWebhookLogsResp = data.data;
      setEventWebhookLogs({
        data: [...eventWebhookLogs.data, ...eventWebhookLogsResp.data],
        meta: eventWebhookLogsResp.meta,
      });

      if (eventWebhookLogsResp.data.length < 10) {
        setLimitReached(true);
      }
    } catch (err) {
      showErrorToast('An error occurred while fetching logs');
    }
    setIsLoading(false);
    AppTracker.track(T_WA_UP_WEBHOOK_LOG_VIEWED);
  };

  useEffect(() => {
    getLogs();
  }, [toDate]);

  return (
    <>
      <HStack justifyContent={'space-between'} alignItems="stretch" w="100%">
        <Text className={styles.name}>{props.webhookName}</Text>
        <Button
          variant="outline"
          className={styles.cancelBtn}
          onClick={() => setToDate(new Date())}
        >
          Refresh
        </Button>
      </HStack>
      {isLoading ? (
        <VStack alignItems="center" justifyContent="center" h="100%">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </VStack>
      ) : (
        <>
          {eventWebhookLogs.data.length === 0 ? (
            <Text size="xs" fontWeight="200" my={4} color="#8690A4">
              No logs found.
            </Text>
          ) : (
            <>
              <DataTable columns={columnDefs} data={eventWebhookLogs?.data || []} />
              {limitReached ? (
                <Text size="xs" fontWeight="200" my={4} pl={6} color="#8690A4">
                  You've reached the limit
                </Text>
              ) : (
                <Text tabIndex={0} className={styles.actionText} onClick={() => getLogs()}>
                  Load More
                </Text>
              )}
              <CustomDrawerComponent
                isOpen={showPayload != null}
                onClose={() => setShowPayload(null)}
                title={'View Payload'}
                showBackIcon
              >
                <JSONCodeBlock
                  code={showPayload}
                  shellProps={{
                    minHeight: '600px',
                    minWidth: 'calc(100% - 10px)',
                  }}
                />
              </CustomDrawerComponent>
            </>
          )}
        </>
      )}
    </>
  );
}

export default ViewLogs;
