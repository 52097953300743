import { Box, Divider } from '@chakra-ui/react';

export const TimelineDivider = ({
  leftOffset,
  noOfRows,
  isSchedulePaused,
}: {
  leftOffset: number;
  noOfRows: number;
  isSchedulePaused: boolean;
}) => (
  <Box position="absolute" top={0} left={`${leftOffset}%`} h={`${noOfRows * 100}%`} zIndex={2}>
    <Divider
      pos="relative"
      orientation="vertical"
      borderColor={isSchedulePaused ? 'GrayText' : 'green'}
      borderLeftWidth={2}
      sx={{
        '&:before': {
          content: "''",
          width: 2.5,
          height: 2.5,
          position: 'absolute',
          left: -1.5,
          borderRadius: 5,
          background: isSchedulePaused ? 'GrayText' : 'green',
        },
      }}
    />
  </Box>
);
