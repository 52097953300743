import * as React from 'react';
import { SVGProps } from 'react';

const SvgDomortzLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#152a49',
      }}
      d="M0 0h20v20H0z"
    />
    <path
      d="M16.3 10.9V2c-.1-.6-.6-1-1.2-1-.5.1-.9.5-1 1v8.9c.1 1 .1 2 0 3-.2 1.6-1.9 3-4 3-2.4-.3-4-2.4-3.7-4.8.2-2 1.8-3.5 3.8-3.7.3 0 .6.1.9.1h.1c.6.1 1.2-.2 1.4-.8.2-.6-.2-1.2-.7-1.4h-.2c-.5-.1-1-.2-1.5-.2-3.6 0-6.5 2.9-6.5 6.5S6.6 19 10.1 19c3.1 0 5.9-2.1 6.1-4.8.1-1.1.1-2.2.1-3.3zm-4.9-4.1c.4 0 .7.3.8.7v.1c0 .4-.3.7-.7.8h-.1c-.4 0-.8-.4-.7-.8-.1-.5.3-.8.7-.8zm3.8-4c-.4 0-.7-.4-.7-.7 0-.4.4-.7.7-.7.4 0 .7.4.7.7.1.4-.2.7-.7.7.1 0 0 0 0 0z"
      style={{
        fill: '#fff',
      }}
    />
  </svg>
);

export default SvgDomortzLogo20X20;
