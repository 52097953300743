import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  background?: string | 'theme.primary.default';
}

const RadioContainer = styled.label`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenRadio = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:focus-visible ~ * {
    outline: ${props => props.theme.focus.default};
    outline-offset: 4px;
  }
`;

const RadioBase = styled.div`
  border-radius: 100%;
  height: 12px;
  width: 12px;
  transition: border 0.17s, background 0.17s ease;
`;

const StyledRadio = styled(RadioBase)<IProps>`
  border: 1px solid
    ${props =>
      props.checked
        ? props.background || props.theme.primary.default
        : props.theme.shades.lightGrey};

  &:before {
    display: block;
    content: '';
    border-radius: 100%;
    height: 8px;
    width: 8px;
    margin: 2px;
    transition: border 0.17s, background 0.17s ease;

    background: ${props =>
      props.checked ? props.background || props.theme.primary.default : props.theme.shades.white};
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${props => props.background || props.theme.primary.default};
    background: ${props => props.background || props.theme.primary.default}27;
  }

  &:active {
    opacity: 0.77;
    cursor: pointer;
    border: 1px solid ${props => props.background || props.theme.primary.default};
    background: ${props => props.background || props.theme.primary.default};
  }
`;

const DisabledRadio = styled(RadioBase)`
  opacity: 0.33;
  border: 1px solid ${props => props.theme.shades.lightGrey};

  &:before {
    display: block;
    content: '';
    border-radius: 100%;
    height: 8px;
    width: 8px;
    margin: 2px;

    background: ${props => props.theme.shades.lightGrey};
  }

  &:hover {
    cursor: not-allowed;
  }
`;

const Radio = (props: IProps) => (
  <RadioContainer>
    <HiddenRadio type="radio" {...props} />
    {!props.disabled ? <StyledRadio {...props} /> : <DisabledRadio />}
  </RadioContainer>
);

/** @component */
export default Radio;
