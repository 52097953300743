import React, { useState, useEffect } from 'react';
import { createStandaloneToast, useClipboard } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import {
  RoutingRules,
  activeRuleModalParam,
  activeRuleModalEditParam,
  alertSourceParam,
  modeParam,
} from '.';
import { stringifyBasicExp } from './columns';
import { IServiceConfig } from '../../../Interfaces/automation-rule';
import { RuleTabLabels } from '../../../contants/service.contant';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_DEDUPE_KEYS_KEY_COPIED } from 'core/const/tracker';

interface Props {
  serviceConfig: IServiceConfig | undefined;
  activeRuleModalQuery: string;
}

const toast = createStandaloneToast();

export const useServiceRule = (props: Props) => {
  const { serviceConfig, activeRuleModalQuery } = props;
  const history = useHistory();
  const [isDirty, setIsDirty] = React.useState(false);
  const [isDeleteRuleModalOpen, setIsDeleteRuleModalOpen] = useState(false);
  const [dedupPreference, setDedupPreference] = useState('');
  const [deleteRuleDetails, setDeleteRuleDetails] = useState<{
    id: string;
    title: string;
    ruleType: RoutingRules;
    alertSourceShortname?: string;
  } | null>(null);
  const [dedupeKeyCopyText, setDedupeKeyCopyText] = useState('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [activeRuleModal, setActiveRuleModal] = useState<RoutingRules | null>(null);
  const { hasCopied: hasCopiedDedupeKey, onCopy: onCopyDedupeKey } =
    useClipboard(dedupeKeyCopyText);

  const deleteAlertDialogMessage =
    deleteRuleDetails && deleteRuleDetails.ruleType !== RoutingRules.dedupekey
      ? `The rule for this service will get deleted. You will not be able to restore it later`
      : `Dedup key for this alert source in this service will be deleted, and auto-grouping will not be possible. If you proceed, you cannot restore it later`;

  const deleteAlertDialogTitle = `Are you sure you want to delete this ${
    deleteRuleDetails && deleteRuleDetails.ruleType === RoutingRules.dedupekey ? 'key' : 'rule'
  } ?`;

  const activeDedupeMethod = serviceConfig?.dedup_key_enabled
    ? 'Key Based Deduplication'
    : 'Deduplication Rules';

  const dedupPreferenceDialogTitle = `Change ${dedupPreference} to ${
    activeDedupeMethod === dedupPreference ? 'inactive' : 'active'
  }`;

  const dedupPreferenceDialogMessage = `If you switch to ${dedupPreference} by setting it to active, ${
    dedupPreference === 'Key Based Deduplication'
      ? 'Deduplication Rules'
      : 'Key Based Deduplication'
  } will become inactive`;

  useEffect(() => {
    if (activeRuleModalQuery) {
      setActiveRuleModal(activeRuleModalQuery as RoutingRules);
    }
  }, [activeRuleModalQuery]);

  useEffect(() => {
    if (dedupeKeyCopyText) {
      onCopyDedupeKey();
      toast({
        title: 'Dedup Key',
        description: 'Key copied successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      AppTracker.track(T_WA_UP_DEDUPE_KEYS_KEY_COPIED);
    }
  }, [dedupeKeyCopyText]);

  useEffect(() => {
    hasCopiedDedupeKey && setDedupeKeyCopyText('');
  }, [hasCopiedDedupeKey]);

  const showLockIconOnTab = (label: RuleTabLabels) => {
    if (!serviceConfig) return false;

    switch (label) {
      case RuleTabLabels.dedupKey:
        return !serviceConfig.dedup_key_enabled;
      case RuleTabLabels.dedupRule:
        return serviceConfig.dedup_key_enabled;
      default:
        return false;
    }
  };

  const showActiveDedupPreferenceText = (label: RuleTabLabels) => {
    switch (label) {
      case RuleTabLabels.dedupRule:
      case RuleTabLabels.dedupKey:
        return true;
      default:
        return false;
    }
  };

  const activeDedupPreferenceText = (label: string) => {
    if (!serviceConfig) return '';

    switch (label) {
      case 'Key Based Deduplication':
        return `Key Based Deduplication is ${
          serviceConfig.dedup_key_enabled ? 'active' : 'inactive'
        }`;
      case 'Deduplication Rules':
        return `Deduplication Rule is ${serviceConfig.dedup_key_enabled ? 'inactive' : 'active'}`;
      default:
        return '';
    }
  };

  const onAddRule = (ruleKey: RoutingRules) => {
    return {
      pathname: history.location.pathname,
      search: qs.stringifyUrl({
        url: history.location.search,
        query: {
          [activeRuleModalParam]: ruleKey,
          ...(ruleKey === RoutingRules.dedupekey ? { [modeParam]: 'create' } : {}),
        },
      }),
    };
  };

  const onEditRule = (ruleKey: RoutingRules, ruleId: string, alertSourceName?: string) => {
    history.push({
      pathname: history.location.pathname,
      search: qs.stringifyUrl({
        url: history.location.search,
        query: {
          [activeRuleModalParam]: ruleKey,
          ...(ruleId ? { [activeRuleModalEditParam]: ruleId } : {}),
          [alertSourceParam]: alertSourceName,
          ...(alertSourceName ? { [modeParam]: 'edit' } : {}),
        },
      }),
    });
  };

  const onDeleteRule = (
    rule_id: string,
    basic_expression: { lhs: string; op: string; rhs: string }[],
    ruleType: RoutingRules,
    alert_source_shortname: string | undefined,
  ) => {
    setIsDeleteRuleModalOpen(true);
    setDeleteRuleDetails({
      id: rule_id ?? '',
      title: basic_expression ? stringifyBasicExp(basic_expression) : '',
      ruleType: ruleType,
      alertSourceShortname: alert_source_shortname,
    });
  };

  const onViewDedupKey = (alertSourceName: string) => {
    history.push({
      pathname: history.location.pathname,
      search: qs.stringifyUrl({
        url: history.location.search,
        query: {
          [activeRuleModalParam]: RoutingRules.dedupekey,
          [alertSourceParam]: alertSourceName,
          [modeParam]: 'view',
        },
      }),
    });
  };

  const checkAndSetDirty = (isModalFormDirty?: boolean) => {
    setIsDirty(isModalFormDirty ?? true);
  };

  const hideModal = () => {
    setIsDirty(false);
    setActiveRuleModal(null);
    history.push({
      pathname: history.location.pathname,
      search: qs.exclude(history.location.search, [
        activeRuleModalParam,
        activeRuleModalEditParam,
        alertSourceParam,
        modeParam,
      ]),
    });
  };

  const checkDirtyAndCloseModal = () => {
    if (isDirty) {
      setHasUnsavedChanges(true);
    } else {
      hideModal();
    }
  };

  const onCopyDedupeKeyCB = (dedupeKey: string) => {
    setDedupeKeyCopyText(dedupeKey);
  };

  const onCloseDeleteModal = () => {
    setIsDeleteRuleModalOpen(false);
    setDeleteRuleDetails(null);
    hideModal();
  };

  const onCancelDeleteModal = () => {
    setIsDeleteRuleModalOpen(false);
    setDeleteRuleDetails(null);
  };

  const closeDedupPreferenceModal = () => {
    setDedupPreference('');
  };

  const onChangeDedupPreference = (label: RuleTabLabels) => {
    if (serviceConfig) {
      let result = '';
      switch (label) {
        case RuleTabLabels.dedupKey:
          result = !serviceConfig.dedup_key_enabled ? label : RuleTabLabels.dedupRule;
          break;
        case RuleTabLabels.dedupRule:
          result = serviceConfig.dedup_key_enabled ? label : RuleTabLabels.dedupKey;
          break;
      }
      setDedupPreference(result);
    }
  };

  const enableDedupeKeyEditMode = (alertSourceName: string) =>
    onEditRule(RoutingRules.dedupekey, '', alertSourceName);

  const getModalId = (ruleType: RoutingRules | null) => {
    switch (ruleType) {
      case RoutingRules.routing:
        return 'service_routing_rule';
      case RoutingRules.tagging:
        return 'service_tagging_rule';
      case RoutingRules.deduplication:
        return 'service_dedup_rule';
      case RoutingRules.suppression:
        return 'service_suppression_rule';
      case RoutingRules.dedupekey:
        return 'service_dedupe_key';
      default:
        return '';
    }
  };
  return {
    activeDedupPreferenceText,
    activeRuleModal,
    checkAndSetDirty,
    checkDirtyAndCloseModal,
    closeDedupPreferenceModal,
    dedupPreference,
    dedupPreferenceDialogTitle,
    dedupPreferenceDialogMessage,
    deleteAlertDialogMessage,
    deleteAlertDialogTitle,
    deleteRuleDetails,
    enableDedupeKeyEditMode,
    getModalId,
    hasUnsavedChanges,
    hideModal,
    isDeleteRuleModalOpen,
    isDirty,
    onAddRule,
    onCloseDeleteModal,
    onCancelDeleteModal,
    onChangeDedupPreference,
    onCopyDedupeKeyCB,
    onEditRule,
    onDeleteRule,
    onViewDedupKey,
    setHasUnsavedChanges,
    showActiveDedupPreferenceText,
    showLockIconOnTab,
  };
};
