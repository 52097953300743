import * as React from 'react';
import { SVGProps } from 'react';

const SvgLogicMonitor = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#030e4a',
      }}
      d="M6.048 8.575v7.16l-4.104 2.24V6.536L13.952 0v4.271L6.048 8.575zm7.904-4.312v7.146l-7.904 4.312V20l12.008-6.56V2.023l-4.104 2.24zm-2.486 5.982c-.416 0-.656-.333-.656-.915 0-.583.24-.92.656-.92s.656.336.656.92c0 .584-.246.915-.656.915zm-2.918 1.35c-.416 0-.656-.333-.656-.915 0-.582.24-.919.656-.919.416 0 .657.335.657.92 0 .583-.247.915-.663.915h.006z"
    />
  </svg>
);

export default SvgLogicMonitor;
