import React, { useState } from 'react';
import IconButton from '../IconButton';
import { CheckSquareIcon, CopyIcon } from '../../utils/icons';
import Tooltip from '../Tooltip';
import Theme from '../Theme';

interface IProps {
  children: string;
  disabled?: boolean;
  label?: string | 'copy';
  type?: 'transparent';
}

const CopyIconButton = (props: IProps) => {
  const [copied, setCopied] = useState(false);
  const { theme } = Theme;

  const copy = async () => {
    try {
      await navigator.clipboard.writeText(props.children);
    } catch (_) {
      const textArea = document.createElement('textarea');
      textArea.value = props.children;

      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      document.execCommand('copy');
      document.body.removeChild(textArea);
    } finally {
      setCopied(true);
    }
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <IconButton
      onClick={copy}
      borderType="rounded"
      buttonType="inverted"
      color={
        props.type === 'transparent'
          ? 'transparent'
          : copied
          ? theme.primary.light
          : theme.primary.light
      }
      disabled={props.disabled}
      style={{ height: 36, width: 36 }}
    >
      <Tooltip
        label={copied ? 'Copied!' : props.label || 'Copy'}
        offset={{ top: '-70px', left: '-0px' }}
      >
        {copied ? (
          <CheckSquareIcon width={20} height={20} color={theme.primary.default} />
        ) : (
          <CopyIcon />
        )}
      </Tooltip>
    </IconButton>
  );
};

/** @component */
export default CopyIconButton;
