import { Center, Flex, Spacer, Text, VStack } from '@chakra-ui/react';
import React from 'react';

type Props = {
  message: string;
  tip: string;
};

function PlaceholderText({ message, tip }: Props) {
  return (
    <VStack dir="row" px={4} spacing={2}>
      <Text fontWeight="medium" color="blackAlpha.700">
        {message}
      </Text>
      <PlaceholderTip tip={tip} />
    </VStack>
  );
}

function PlaceholderTip({ tip }: Omit<Props, 'message'>) {
  return (
    <Text color="gray.500" variant="italic" textAlign="center" fontSize="xs">
      {tip}
    </Text>
  );
}

PlaceholderText.Tip = PlaceholderTip;

export default PlaceholderText;
