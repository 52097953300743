import { DateTime, Duration, DurationObjectUnits } from 'luxon';

const getDateRange = (granularity?: 'day' | 'week' | 'month' | 'quarter') => {
  const dt = DateTime.fromObject({
    weekYear: DateTime.now().weekYear,
    weekNumber: DateTime.now().weekNumber,
  });

  switch (granularity) {
    case 'month': {
      const dateFromStr = dt.startOf('month');
      const dateToStr = dt.endOf('month');

      return { from_date: dateFromStr.toISO(), to_date: dateToStr.toISO() };
    }
    case 'week': {
      const dateFromStr = dt.startOf('week');
      const dateToStr = dt.endOf('week');

      return { from_date: dateFromStr.toISO(), to_date: dateToStr.toISO() };
    }
    default: {
      const dateFromStr = dt.startOf('day');
      const dateToStr = dt.endOf('day');

      return { from_date: dateFromStr.toISO(), to_date: dateToStr.toISO() };
    }
  }
};

const getMediumDateTime = (date: string | Date) => {
  return DateTime.fromJSDate(new Date(date)).toLocaleString(DateTime.DATETIME_MED);
};

const getMonthName = (date: string | Date) => {
  return DateTime.fromJSDate(new Date(date)).toFormat('LLL');
};

const getDayMonth = (date: string | Date) => {
  return DateTime.fromJSDate(new Date(date)).toFormat('LLL dd');
};

const getWeekDateRange = (date: string | Date) => {
  const dt = DateTime.fromJSDate(new Date(date));
  const dateFromStr = dt.startOf('week');
  const dateToStr = dt.endOf('week');

  return [dateFromStr.toFormat('LLL dd'), dateToStr.toFormat('LLL dd')].join(' - ');
};

const getSQLDate = (date: string | Date) => {
  return DateTime.fromJSDate(new Date(date)).toSQLDate();
};

const getValidDurations = (durations: DurationObjectUnits) => {
  return Object.entries(durations).reduce((acc, [durationKey, durationValue]) => {
    if (durationValue) {
      acc[durationKey as keyof DurationObjectUnits] = durationValue;
    }
    return acc;
  }, {} as Record<keyof DurationObjectUnits, number>);
};

/**
 * Format seconds duration to human readable format
 * @param durationInSeconds Duration in seconds
 * @returns Human readable duration
 */
function formatDurationValue(durationInSeconds: number): string {
  const durationShift = Duration.fromDurationLike({ seconds: durationInSeconds }).shiftTo(
    'hours',
    'minutes',
    'seconds',
  );
  const validDurations = getValidDurations({
    hours: durationShift.hours,
    minutes: durationShift.minutes,
    seconds: durationShift.seconds,
  });

  return Duration.fromDurationLike(validDurations).toHuman({
    unitDisplay: 'narrow',
  });
}

function durationUnits(durationInSeconds: number) {
  const duration = Duration.fromDurationLike({ seconds: durationInSeconds }).shiftTo(
    'hours',
    'minutes',
    'seconds',
  );

  const validDurations = getValidDurations({
    hours: duration.hours,
    minutes: duration.minutes,
    seconds: duration.seconds,
  });

  const tooltip = Duration.fromDurationLike({ ...validDurations }).toHuman({
    unitDisplay: 'short',
  });

  const unit = largestUnit(duration);
  const length = round(duration.as(unit));
  const display = Duration.fromObject({ [unit]: length }).toHuman({ unitDisplay: 'short' });
  return { display, tooltip };
}

const round = (num: number) => +num.toFixed(2);

function largestUnit(duration: Duration) {
  if (duration.years) {
    return 'years';
  } else if (duration.months) {
    return 'months';
  } else if (duration.days) {
    return 'days';
  } else if (duration.hours) {
    return 'hours';
  } else if (duration.minutes) {
    return 'minutes';
  } else {
    return 'seconds';
  }
}

export {
  getDateRange,
  getMediumDateTime,
  getDayMonth,
  getMonthName,
  getWeekDateRange,
  getSQLDate,
  formatDurationValue,
  durationUnits,
};
