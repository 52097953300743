import { IAppState } from 'core/interfaces/IAppState';
import { MoreIcon } from 'icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { Cell } from 'react-table';
import DataTable from 'views/main/organization/service-catalog/components/Table';

import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Progress,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import DeleteConfirmation from '../../Component/ConfirmationModal';
import { useDeleteJiraAccConfig } from '../../hooks/deleteJiraConfig';
import { useGetJiraConfigByOrg } from '../../hooks/getJiraConfigForOrg';
import { Owner } from '../../../../../../../../core/interfaces/IJiraNew';
import { stringShortener } from 'core/helpers/stringUtils';
import AddConfigModal from './AddConfigModal';

interface ITableRowProps {
  id: number;
  accountId: number;
  accountName: string;
  projectName: string;
  teams: Owner[];
}

export default function DefaultTeamConfig() {
  //redux
  const teams = useSelector((state: IAppState) => state.organization.teams.t);

  //states
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);

  const [configToEditId, setConfigToEditId] = React.useState<number | null>(null);
  const [accountId, setAccountId] = React.useState<number | null>(null);
  const [accountIdToDelete, setAccountIdToDelete] = React.useState<{
    accountId: number;
    configId: number;
  } | null>(null);

  const { data: jiraConfig = [], isLoading } = useGetJiraConfigByOrg();
  const { mutate: deleteJiraConfig, isLoading: isDeleting } = useDeleteJiraAccConfig();

  //handlers
  const onEditConfig = (configId: number, accountId: number) => {
    setConfigToEditId(configId);
    setAccountId(accountId);
    setIsModalVisible(true);
  };

  const deleteConfig = async (configId: number, accountId: number) => {
    setIsConfirmingDelete(true);
    setAccountIdToDelete({
      accountId,
      configId,
    });
  };

  const onDeleteConfirm = async () => {
    if (accountIdToDelete) {
      deleteJiraConfig({
        accountId: accountIdToDelete?.accountId,
        configId: accountIdToDelete?.configId,
      });
      setIsConfirmingDelete(false);
    }
  };

  //data formating
  const configData = React.useMemo(
    () =>
      jiraConfig?.map(item => {
        return {
          id: item.id,
          accountId: item.jira_cloud_account_id,
          accountName: item.jira_account_name,
          projectName: item.project_name,
          teams: item.is_all_teams_configured
            ? 'All'
            : item?.owners?.map(teamId => {
                return teams?.find(team => team.id === teamId.owner_id)?.name || '';
              }),
        };
      }),
    [jiraConfig],
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb={8}
        paddingRight={5}
      >
        <Box display="flex" flexDirection="row" alignItems={'center'} justifyContent="flex-start">
          <Text fontSize={16} fontWeight={400}>
            The Default configuration settings for a team allow you to provide the initial
            configuration settings for a service created by the team{' '}
            <Tooltip
              backgroundColor={'white'}
              color="black"
              label={
                'modifying the default configuration settings for a team does not affect the configuration of any services that have already been created.'
              }
              fontSize={14}
              fontWeight={400}
            >
              <InfoOutlineIcon w={5} h={5} pl={1} />
            </Tooltip>
          </Text>
        </Box>
        <Button
          background="primary.default"
          color="white"
          fontWeight={400}
          fontSize={16}
          _hover={{ opacity: 0.6 }}
          style={{ padding: 10, whiteSpace: 'nowrap', minWidth: 'unset' }}
          onClick={() => setIsModalVisible(true)}
        >
          Add Configuration
        </Button>
      </Box>
      {(isLoading || isDeleting) && <Progress size="xs" isIndeterminate mb={2} />}
      <DataTable
        columns={[
          {
            Header: 'Team',
            Cell: (cell: Cell<ITableRowProps>) => {
              return (
                <>
                  <Text>{cell.row.original.teams}</Text>
                </>
              );
            },
          },
          {
            Header: 'Account',
            Cell: (cell: Cell<ITableRowProps>) => {
              return (
                <Tooltip
                  backgroundColor={'white'}
                  color="black"
                  label={cell.row.original.accountName}
                >
                  {stringShortener(cell.row.original.accountName, 30)}
                </Tooltip>
              );
            },
          },
          {
            Header: 'Project',
            Cell: (cell: Cell<ITableRowProps>) => {
              return (
                <>
                  <Text>{cell.row.original.projectName}</Text>
                </>
              );
            },
          },
          {
            Header: 'Actions',
            Cell: (cell: Cell<ITableRowProps>) => {
              return (
                <Popover size="sm" matchWidth>
                  <PopoverTrigger>
                    <IconButton
                      variant=" unstyled"
                      _focus={{ outline: 'none' }}
                      aria-label="More"
                      icon={<MoreIcon />}
                    />
                  </PopoverTrigger>
                  <PopoverContent _focus={{ outline: 'none' }} width="15" bg="whiteAlpha.900">
                    <PopoverBody padding={0}>
                      {['Edit', 'Delete'].map((item, i) => (
                        <Text
                          key={i}
                          fontSize={16}
                          pr={20}
                          pl={4}
                          py={2}
                          fontWeight={400}
                          cursor="pointer"
                          textAlign="start"
                          _hover={{ background: 'AliceBlue' }}
                          onClick={() =>
                            item === 'Edit'
                              ? onEditConfig(cell.row.original.id, cell.row.original.accountId)
                              : deleteConfig(cell.row.original.id, cell.row.original.accountId)
                          }
                        >
                          {item}
                        </Text>
                      ))}
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              );
            },
          },
        ]}
        data={configData}
      />
      {isModalVisible && (
        <AddConfigModal
          isOpen={isModalVisible}
          onClose={() => {
            setIsModalVisible(false);
            setConfigToEditId(null);
            setAccountId(null);
          }}
          configId={configToEditId}
          accountId={accountId}
          totalConfigCount={configData.length}
        />
      )}
      {isConfirmingDelete && (
        <DeleteConfirmation
          bodyText={`Are you sure you want to delete this team's default configuration?\nYou won't be able to create Jira tickets for this team.`}
          headerText="Delete Team default configuration?"
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          isOpen={isConfirmingDelete}
          onConfirm={onDeleteConfirm}
          onClose={() => {
            setIsConfirmingDelete(false);
            setAccountIdToDelete(null);
          }}
        />
      )}
    </>
  );
}
