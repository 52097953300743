import * as React from 'react';
import { SVGProps } from 'react';

const SvgSonarLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <ellipse
      cx={10}
      cy={10}
      rx={9.972}
      ry={10}
      style={{
        opacity: 0.3,
        fill: '#0199ff',
      }}
    />
    <circle
      cx={10}
      cy={10}
      style={{
        fill: '#0199ff',
      }}
      r={5.221}
    />
  </svg>
);

export default SvgSonarLogo20X20;
