export function findNonCommonObjects(array1: any, array2: any) {
  const nonCommonObjects = [];

  for (const obj1 of array1) {
    if (!array2.some((obj2: any) => isEqual(obj1, obj2))) {
      nonCommonObjects.push(obj1);
    }
  }

  for (const obj2 of array2) {
    if (!array1.some((obj1: any) => isEqual(obj1, obj2))) {
      nonCommonObjects.push(obj2);
    }
  }

  return nonCommonObjects;
}

function isEqual(obj1: any, obj2: any) {
  return obj1.id === obj2.user_id;
}
