import { CheckIcon } from '@chakra-ui/icons';
import { Box, Text } from '@chakra-ui/react';
import React from 'react';

interface IProps {
  primary: string;
  secondary: string;
  isSelected: boolean;
}

const ThemeDisplay = (props: IProps) => {
  return (
    <Box
      bgColor={props.primary}
      py={2}
      px={3}
      borderRadius={4}
      position="relative"
      cursor={'pointer'}
      w={'fit-content'}
    >
      {props.isSelected ? (
        <CheckIcon
          color="white"
          w={5}
          height={5}
          position="absolute"
          top={-2}
          p={1}
          right={-2}
          borderWidth={2}
          borderColor={'white'}
          bgColor={'green'}
          borderRadius={10}
        />
      ) : null}
      <Text color={props.secondary} fontSize={12}>
        Text
      </Text>
    </Box>
  );
};

export default ThemeDisplay;
