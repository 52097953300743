import { isEmpty } from 'lodash';
import moment from 'moment';
import { QueryClient } from 'react-query';

import { applyTimeZone, invalIncidentsListData } from '../../common/util';
import {
  DateFilterChoice,
  FilterChoice,
  IncidentFilters,
  IncidentTagFilterType,
} from '../../graphql/generated/types';
import { filterDropdownOptions } from '../../interfaces/common';
import { Filters } from '../../interfaces/filters';
import { getListDefaultDaysOverride, isAllTimeLimit } from '../../store/persistent-storage';
import { INITIAL_FILTERS_STATE } from './constant';

const INCIDENT_LIST_FILTER_KEY = 'SQ_IL_FILTERS';

class FilterManager {
  private _filter: IncidentFilters;
  private _isDirty: boolean;
  private _rebuildDateFilters: boolean;
  private _desc: string;
  private _resetCursorData: boolean;

  constructor() {
    // dummy intialization to satisfy compiler warnings
    this._filter = {};
    this._desc = '';
    this._rebuildDateFilters = false;
    this._resetCursorData = false;

    // initializing values
    this._isDirty = true;
    this._resetFilters();
  }

  /**
   *
   * @returns whether filter is applied or not
   */
  isFilterApplied = () => {
    const appliedFilter = this._getStoredFilter();
    return appliedFilter ? Object.values(appliedFilter).some(val => !isEmpty(val)) : false;
  };

  checkCursorResetStatus = () => {
    const result = this._resetCursorData;
    this._resetCursorData = false;
    return result;
  };

  /**
   * this routine will fetch fetch filter obj for filters form and tags rendering on header - UI
   * @returns filters : Filters
   */
  getUIFilters = (): Filters => {
    const filters = this._getStoredFilter() ?? INITIAL_FILTERS_STATE;
    if (isAllTimeLimit() && !filters.created) {
      filters.lastNumberOfDays = undefined;
    }
    return filters;
  };

  /**
   * this routine will rebuild API filters
   */
  rebuildFilters = () => {
    if (this._filter) {
      this._parseFilters();
    }
  };

  rebuildDateFilters = () => {
    if (this._filter) {
      this._rebuildDateFilters = true;
    }
  };

  getFiltersData = (timeZone: string) => {
    const filterData = filterManager.getAPIFilters();
    if (this._rebuildDateFilters) {
      this._rebuildDateFilters = false;
      const startDate = filterData?.customDateFrom;
      const endDate = filterData?.customDateTo;
      if (startDate && endDate) {
        filterData.customDateFrom = applyTimeZone(startDate, timeZone);
        filterData.customDateTo = applyTimeZone(endDate, timeZone, true);
      }
    }
    return filterData;
  };
  /**
   * this routine will fetch applicable filters - API
   * @returns filters : IncidentFilters
   */
  getAPIFilters = (): IncidentFilters | null => {
    if (!this._isDirty) {
      return this._filter;
    }
    return this._parseFilters();
  };

  /**
   * this routine will provide description for incidents list header
   * @returns description: string
   */
  getDesc = (isAllTimeData?: boolean): string => {
    if (isAllTimeData) return 'Showing results for all time';

    return this._desc;
  };

  /**
   * this routine will only save filters to storage
   * @param filters : Filters
   */
  setFilters = (filters: Filters) => {
    this._isDirty = true;
    this._resetCursorData = true;
    sessionStorage.setItem(INCIDENT_LIST_FILTER_KEY, JSON.stringify(filters));
  };

  /**
   * this routine will clear out filters (if any) from session storage
   */
  clearFiltersFromStorage = () => {
    sessionStorage.removeItem(INCIDENT_LIST_FILTER_KEY);
    this._resetFilters();
  };

  /**
   * this rotine will clear out filters (if any) and reload incidnets list
   * @param queryClient : QueryClient
   */
  clearFilters = (queryClient: QueryClient) => {
    this.clearFiltersFromStorage();
    invalIncidentsListData(queryClient);
  };

  /**
   *
   * this routine will save filters and apply to list
   * @param filters : Filters
   * @param queryClient : QueryClient
   */
  applyFilters = (filters: Filters, queryClient: QueryClient) => {
    this.setFilters(filters);
    invalIncidentsListData(queryClient);
  };

  /**
   *
   * @param filterObj - UI filter obj (Filters) to transform to API model (IncidentFilters)
   * @returns - apiFilter : IncidentFilters
   */
  buildAPIFilterModel = (filterObj: Filters): IncidentFilters => {
    const apiFilter: IncidentFilters = {};
    if (filterObj) {
      const getValueList = (list: Array<filterDropdownOptions>) =>
        list.map((item: { value: any }) => item.value);
      const getBoolFilterVal = (value: string) => value as FilterChoice;

      if (filterObj.alert.length) {
        apiFilter.alertSourceIDs = getValueList(filterObj.alert);
      }

      if (filterObj?.assignedTo?.meAndMySquads) {
        apiFilter.assignedToUserIDsAndTheirSquads = getValueList([
          ...filterObj?.assignedTo?.myUsers,
        ]);
      }
      if (filterObj?.assignedTo?.squads?.length || filterObj?.assignedTo?.users?.length) {
        const resonderIds = [...filterObj?.assignedTo?.squads, ...filterObj?.assignedTo?.users];
        apiFilter.responderIDs = getValueList(resonderIds);
      }

      if (filterObj.services.length) {
        apiFilter.serviceIDs = getValueList(filterObj.services);
      }

      if (filterObj.priority.length) {
        apiFilter.priority = getValueList(filterObj.priority);
      }

      if (filterObj.sloList.length) {
        apiFilter.sloIDs = getValueList(filterObj.sloList);
      }

      if (filterObj.tagsValue.length) {
        apiFilter.tags = filterObj.tagsValue.reduce((acc, item) => {
          const tag: IncidentTagFilterType = {
            key: item.label.split(':')[0],
            value: item.value,
          };
          acc.push(tag);
          return acc;
        }, [] as IncidentTagFilterType[]);
      }

      if (filterObj.isStarred) {
        apiFilter.isStarred = getBoolFilterVal(filterObj.isStarred);
      }
      if (filterObj.withNotes) {
        apiFilter.hasNotes = getBoolFilterVal(filterObj.withNotes);
      }
      if (filterObj.withRetro) {
        apiFilter.hasRetrospectives = getBoolFilterVal(filterObj.withRetro);
        if (filterObj.retroStatus) {
          apiFilter.retrospectiveStatus = getValueList(filterObj.retroStatus);
        }
      }
      if (filterObj.isSlo) {
        apiFilter.isSLO = getBoolFilterVal(filterObj.isSlo);
      }
      if (
        filterObj.serviceOwner.meAndMySquads ||
        filterObj.serviceOwner.squads.length ||
        filterObj.serviceOwner.users.length
      ) {
        if (!filterObj.services.length) {
          apiFilter.serviceIDs = getValueList(filterObj?.temprorayServices);
        }
        apiFilter.serviceOwner = {
          userIDs: getValueList(filterObj.serviceOwner.users),
          squadIDs: getValueList(filterObj.serviceOwner.squads),
          userIDsAndTheirSquads: getValueList(
            filterObj.serviceOwner.meAndMySquads ? filterObj.serviceOwner.myUsers : [],
          ),
        };
      }

      const rangeValue = filterObj.created?.value as DateFilterChoice;

      if ([DateFilterChoice.Month, DateFilterChoice.Week].includes(rangeValue)) {
        apiFilter.dateFilter = rangeValue;
      } else if (filterObj.startDate && filterObj.endDate) {
        apiFilter.dateFilter = DateFilterChoice.Range;
        apiFilter.customDateFrom = filterObj.startDate;
        apiFilter.customDateTo = filterObj.endDate;
        this._rebuildDateFilters = true;
      } else if (filterObj.lastNumberOfDays) {
        apiFilter.dateFilter = DateFilterChoice.Days;
        apiFilter.lastNumberOfDays = filterObj.lastNumberOfDays;
      }
    }
    return apiFilter;
  };

  /*************************  PRIVATE METHODS *************************/
  private _resetFilters = () => {
    const defaultDays = getListDefaultDaysOverride();
    const queryAllTime = isAllTimeLimit();
    this._filter = queryAllTime
      ? {}
      : {
          lastNumberOfDays: defaultDays,
        };
    this._resetCursorData = true;
    this._desc = queryAllTime ? '' : `Showing incidents for last ${defaultDays} days`;
  };

  private _getFilterString = () => {
    return sessionStorage.getItem(INCIDENT_LIST_FILTER_KEY);
  };

  private _getStoredFilter = () => {
    const filterStr = this._getFilterString();
    return filterStr ? JSON.parse(filterStr) : null;
  };

  private _parseFilters = (): IncidentFilters => {
    const filterObj = this._getStoredFilter();

    this._isDirty = false;
    this._resetFilters();

    if (filterObj) {
      const apiFilter = this.buildAPIFilterModel(filterObj);

      let descSuffix = '';
      if (!filterObj.created?.label) {
        const lastNumberOfDays = this._filter.lastNumberOfDays;
        this._filter = { ...apiFilter, lastNumberOfDays };
      } else {
        this._filter = apiFilter;

        if (apiFilter.dateFilter === DateFilterChoice.Range) {
          const getDateStr = (date: string | null) =>
            date ? moment(date).format('DD/MM/YYYY') : '';
          descSuffix = `from ${getDateStr(filterObj.startDate)} to ${getDateStr(
            filterObj.endDate,
          )}`;
        } else {
          descSuffix = `for ${filterObj.created.label.toLowerCase()}`;
        }
      }

      if (descSuffix) {
        this._desc = `Showing incidents ${descSuffix}`;
      }
    }

    return this._filter;
  };
}

export const filterManager = new FilterManager();
