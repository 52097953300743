import React, { Component, ChangeEvent, Ref } from 'react';
import { Link, Redirect } from 'react-router-dom';
import cx from 'classnames';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { connect } from 'react-redux';

import { IComponentState, IComponentErrorState } from '../../../core/interfaces/IComponentState';
import { isEmailValid } from '../../../core/helpers';
import { exception } from '../../../core/exception';
import { AuthService } from '../../../core/services';
import { CommonAuth } from '../commonAuth';
import { IAppState } from '../../../core/interfaces/IAppState';
import { onLoginSuccess } from '../../../core/actions/auth';
import { Heading, Tooltip, Para, SpinLoader, PulseLoader, Grid, Theme } from 'uie/components';
import { AppTracker } from '../../../shared/analytics/tracker';
import { IAuthCheck } from '../../../core/interfaces/IAuth';
import {
  T_WA_GS_EMAIL_ENTERED,
  T_WA_GS_GET_STARTED_CLICKED,
  T_WA_GS_PASSWORD_ENTERED,
  T_WA_GS_REGISTER_PAGE_VIEWED,
} from '../../../core/const/tracker';

import '../common.css';

const { theme } = Theme;

interface IProps extends Pick<IAppState, 'auth'> {
  onLoginSuccess: (sessionId: string) => void;
}

interface IState {
  componentState: IComponentState;
  onboardState: 'email' | 'password';
  email: string;
  password: string;
  captcha: string;
  showPassword: boolean;
  errors: IComponentErrorState;
  created: boolean;
  time: string;
}

class GetStarted extends Component<IProps, IState> {
  private _authService = new AuthService();
  private _captcha: HCaptcha | null = null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      componentState: 'idle',
      onboardState: 'email',
      email: '',
      password: '',
      captcha: '',
      showPassword: false,
      errors: {},
      created: false,
      time: '',
    };
  }

  componentDidMount() {
    const { email, redirectFrom } = CommonAuth.db;
    if (redirectFrom === 'a0' && email.length > 0) {
      this.setState({ email, onboardState: 'password' });
    }
    CommonAuth.clearDb();
    AppTracker.track(T_WA_GS_REGISTER_PAGE_VIEWED);

    window.addEventListener('online', this.onOnline);
    window.addEventListener('offline', this.onOffline);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.onOnline);
    window.removeEventListener('offline', this.onOffline);
  }

  onOnline = () => {
    this.setState(state => ({
      ...state,
      errors: {
        ...state.errors,
        network: undefined,
      } as any,
    }));
  };

  onOffline = () => {
    this.setState(state => ({
      ...state,
      errors: {
        ...state.errors,
        network: 'No Network connection, please connect to the internet.',
      } as any,
    }));
  };

  public togglePasswordVisible = () => {
    this.setState(({ showPassword }) => ({ showPassword: !showPassword }));
  };

  onValueChange = (key: 'email' | 'password') => (e: ChangeEvent<HTMLInputElement>) => {
    const newState: any = {};
    newState[key] = e.target.value || '';
    newState.errors = {};
    this.setState(newState);
  };

  onVerify = () => {
    const { email, password, captcha, onboardState } = this.state;
    const errors: any = {};

    if (onboardState === 'email' && emailVerification(email)) {
      errors.email = 'Please enter a valid Email-id';
    }

    if (onboardState === 'password' && passwordVerification(password)) {
      errors.password = 'Password should be between 10 and 72 characters';
    }

    if (onboardState === 'password' && captcha === '') {
      errors.captcha = 'Please solve the captcha before submitting.';
    }

    if (!navigator.onLine) {
      errors.network = 'No Network connection, please connect to the internet.';
    }

    this.setState(state => ({
      ...state,
      errors: {
        ...state.errors,
        ...errors,
      } as any,
    }));
    return Object.entries(errors).length === 0;
  };

  onSubmit = async (e?: any) => {
    if (e) {
      e.preventDefault();
    }

    if (!this.onVerify()) {
      return;
    }

    this.setState({ componentState: 'busy' });

    const { email, password, captcha, onboardState } = this.state;

    let emailCheck: IAuthCheck;

    try {
      const response = await this._authService.checkEmail(email);
      emailCheck = response.data;
    } catch (err: any) {
      this.setState({
        errors: {
          network: 'Invalid hostname. Please check your email-id and try again',
        },
        componentState: 'idle',
      });
      exception.handle('E_START_EMAIL_CHECK_FAILED', err);
      return;
    }

    const { message, result, type } = emailCheck;

    try {
      if (result) {
        if (onboardState === 'email') {
          this.setState({ onboardState: 'password' });
          AppTracker.track(T_WA_GS_EMAIL_ENTERED, { Email: this.state.email });
        }

        if (onboardState === 'password') {
          const {
            data: {
              message: registerMessage,
              result: registerResult,
              type: registerType,
              data: registerData,
            },
          } = await this._authService.register(email, password, captcha);

          this.clearCaptcha();

          if (registerResult) {
            if (registerType === 'new') {
              const time = new Date().getTime().toString();
              CommonAuth.db = {
                email,
                id: time,
                redirectFrom: 'register',
              };
              this.setState({
                time,
                created: true,
              });
              this.props.onLoginSuccess(registerData.token);
            }

            if (registerType === 'saved') {
              const time = new Date().getTime().toString();
              CommonAuth.db = {
                email,
                id: time,
                redirectFrom: 'register',
              };
              this.setState({
                time,
                created: true,
              });
            }

            if (registerType === 'invited') {
              const time = new Date().getTime().toString();
              CommonAuth.db = {
                email,
                id: time,
                redirectFrom: 'invited',
              };
              this.setState({
                time,
                created: true,
              });
              this.props.onLoginSuccess(registerData.token);
            }

            AppTracker.trackSignUp({ email: this.state.email });
          } else {
            this.setState({
              errors: {
                [registerType]: registerMessage,
              },
            });
          }
          AppTracker.track(T_WA_GS_PASSWORD_ENTERED);
        }
      } else {
        let t = type;
        if (type === 'email-verified') {
          const time = new Date().getTime().toString();
          CommonAuth.db = {
            email,
            id: time,
            redirectFrom: 'register',
          };
          t = 'email';
        }
        this.setState({
          errors: {
            [t]: message,
          },
        });
      }
    } catch (err: any) {
      if (err?.response?.data?.reason === 'captcha') {
        this.setState({
          errors: {
            captcha: err?.response?.data?.message,
          },
        });
      } else {
        this.setState({
          errors: {
            network: 'Network Error, please check again',
          },
        });
      }
      exception.handle('E_START_EMAIL_CHECK_FAILED', err);
    } finally {
      this.setState({ componentState: 'idle' });
    }
    AppTracker.track(T_WA_GS_GET_STARTED_CLICKED);
  };

  onCaptchaVerify = (token: string) => {
    this.setState({ captcha: token });
  };

  clearCaptcha = () => {
    this.setState({ captcha: '' }, () => {
      this._captcha?.resetCaptcha();
    });
  };

  render() {
    const {
      componentState,
      email,
      errors,
      password,
      captcha,
      onboardState,
      showPassword,
      created,
      time,
    } = this.state;

    if (created) {
      return <Redirect to={`/verify/${time}`} exact={true} />;
    }

    return (
      <div className="login-container">
        <div className="login-interact">
          <div className="mb-10">
            <Heading fontSize={24} height={24}>
              Incident response for
            </Heading>
            <br />
            <Heading fontSize={24} height={24}>
              SRE, DevOps & IT Teams
            </Heading>
          </div>
          <Heading className="mt-10" fontSize={16} fontWeight={300} height={16}>
            Get Started For Free.
          </Heading>

          <form onSubmit={this.onSubmit} style={{ marginTop: 40 }}>
            <div className="login-input-container">
              <label className="login-label" htmlFor="login_email">
                Work Email
              </label>
              <Grid justifyContent="flex-start" alignItems="center">
                <input
                  id="login_email"
                  className={cx('login-input', { error: errors.email && errors.email.length > 0 })}
                  value={email}
                  onChange={this.onValueChange('email')}
                  placeholder="example@company.com"
                  type="email"
                  disabled={onboardState === 'password' || componentState === 'busy'}
                />
                <div className="ml-10">
                  {componentState === 'busy' && onboardState === 'email' && (
                    <SpinLoader base="12px" />
                  )}
                  {onboardState === 'password' && <img src="/icons/check_green.svg" alt="good" />}
                </div>
              </Grid>
              <p className="login-error-message">
                {errors.email || ''}
                {errors.email && errors.email.includes('invalid') && (
                  <Tooltip
                    label="A valid email address would give us a way to initiate account verification"
                    offset={{
                      top: '30px',
                      left: '-60px',
                    }}
                  >
                    <Para fontSize={12} color={theme.danger.default}>
                      <u className="ml-5">know more</u>
                    </Para>
                  </Tooltip>
                )}
              </p>
            </div>

            {onboardState === 'password' && (
              <div className="login-input-container">
                <label className="login-label" htmlFor="login_password">
                  Set password
                </label>
                <Grid justifyContent="flex-start" alignItems="center">
                  <input
                    id="login_password"
                    className={cx('login-input', {
                      error: errors.password && errors.password.length > 0,
                    })}
                    value={password}
                    onChange={this.onValueChange('password')}
                    placeholder="10+ Characters"
                    type={showPassword ? 'text' : 'password'}
                    disabled={componentState === 'busy'}
                  />
                  {password.length > 0 && (
                    <img
                      style={{ marginLeft: -40, cursor: 'pointer' }}
                      src={`/icons/${!showPassword ? 'show_password' : 'hide_password'}.svg`}
                      alt="show password"
                      onClick={this.togglePasswordVisible}
                    />
                  )}
                </Grid>
                {errors.password && <p className="login-error-message">{errors.password || ''}</p>}
              </div>
            )}

            <p className="login-error-message">{errors.network || ''}</p>

            {onboardState === 'password' && (
              <div className="login-input-container" style={{ marginBottom: '24px' }}>
                <HCaptcha
                  sitekey="b7fac127-f1f7-4ef9-b17e-45a7feec2512"
                  ref={inst => (this._captcha = inst)}
                  onVerify={this.onCaptchaVerify}
                  // onError=
                  // onExpire=
                />
                {errors.captcha && <p className="login-error-message">{errors.captcha || ''}</p>}
              </div>
            )}

            <button
              className="login-get-started"
              style={{ marginTop: 0 }}
              disabled={
                (onboardState === 'email' && email === '') ||
                (onboardState === 'password' && password === '') ||
                (onboardState === 'password' && captcha === '') ||
                componentState === 'busy'
              }
              onClick={this.onSubmit}
            >
              {componentState === 'busy' && onboardState === 'password' ? (
                <Grid alignContent="center" justifyContent="center">
                  <PulseLoader color={theme.shades.white} base="12px" />
                </Grid>
              ) : (
                'GET STARTED'
              )}
            </button>
          </form>

          <div className="login-account-exists">
            <span>Already have an account?</span>
            <Link className="login-signin-link" to="/">
              Login
            </Link>
          </div>

          <div className="login-t-c">
            <span>
              By signing up I am agreeing with the and
              <a
                className="login-link"
                target="_blank"
                href="https://www.squadcast.com/terms"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
              <span style={{ marginLeft: 10 }}>and</span>
              <a
                className="login-link"
                target="_blank"
                href="https://www.squadcast.com/privacy"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

function emailVerification(email: string): string {
  if (isEmailValid(email)) {
    return '';
  }

  return "Doesn't Look right. Try Again ?";
}

function passwordVerification(password: string): string {
  if (password.length >= 10 && password.length <= 72) {
    return '';
  }
  return 'Password should be between 10 and 72 characters';
}

export default connect(({ auth }: IAppState) => ({ auth }), {
  onLoginSuccess,
})(GetStarted);
