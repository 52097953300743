import styled from 'styled-components';

interface IProps {
  height?: '24px' | '16px' | 'fit-content' | string;
  backgroundColor?: string | 'theme.shades.whiteSmoke';
  width?: string | 'fit-content';
}

const Pill = styled.div<IProps>`
  height: ${props => props.height || 'fit-content'};
  color: ${props => props.color || props.theme.font.default};
  background-color: ${props => props.backgroundColor || props.theme.shades.whiteSmoke};
  border: none;
  width: ${props => props.width || 'fit-content'};
  text-align: left;
  border-radius: 3px;
  padding: 4px 8px;
  margin: 0;

  & > p {
    color: ${props => props.theme.shades.cement};
  }
`;

/** @component */
export default Pill;
