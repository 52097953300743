import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 418 139"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m350.652 92.71 1.067-3.679c1.267-4.374.798-8.41-1.336-11.386-1.994-2.737-5.245-4.344-9.224-4.532l-75.314-.952a1.467 1.467 0 0 1-1.436-1.294c-.026-.234.004-.47.09-.69a1.983 1.983 0 0 1 1.745-1.329l76.042-.991c8.975-.407 18.786-7.687 22.207-16.573l4.327-11.267a2.649 2.649 0 0 0 .12-1.527 49.218 49.218 0 0 0-16.345-26.84A49.677 49.677 0 0 0 323.272.07a49.727 49.727 0 0 0-30.403 8.381 49.297 49.297 0 0 0-19.147 24.94 22.363 22.363 0 0 0-12.743-4.37 22.376 22.376 0 0 0-12.946 3.738 22.19 22.19 0 0 0-8.411 10.476 22.053 22.053 0 0 0-.8 13.384 31.776 31.776 0 0 0-12.923 3.112 31.611 31.611 0 0 0-10.497 8.118c-2.881 3.416-5 7.4-6.216 11.692a31.32 31.32 0 0 0-.837 13.198 1.468 1.468 0 0 0 1.446 1.26h139.092c.395.004.781-.12 1.099-.352.318-.233.552-.561.666-.937Z"
      fill="#F78100"
    />
    <path
      d="M376.388 41a39.7 39.7 0 0 0-2.116.06.898.898 0 0 0-.322.068 1.162 1.162 0 0 0-.766.79l-3.022 10.136c-1.289 4.357-.815 8.377 1.35 11.341a11.697 11.697 0 0 0 4.117 3.322 11.925 11.925 0 0 0 5.191 1.193l16.228.988a1.454 1.454 0 0 1 1.149.612 1.454 1.454 0 0 1 .171 1.354 1.972 1.972 0 0 1-.679.921c-.313.235-.69.375-1.084.402l-16.853.988c-9.156.415-19.018 7.656-22.473 16.498l-1.219 3.121a.872.872 0 0 0 .086.801.896.896 0 0 0 .305.286c.124.07.262.11.405.119h58.052c.354.008.7-.102.982-.312.282-.21.483-.508.57-.844a40.08 40.08 0 0 0 .808-18.628 40.463 40.463 0 0 0-7.718-17.039 41.48 41.48 0 0 0-14.637-11.899A42.32 42.32 0 0 0 376.408 41h-.02Z"
      fill="#FCAD32"
    />
    <path
      d="M43 104h9.821v25.749H70V138H43v-34ZM73 121.593v-.088C73 111.843 80.987 104 91.55 104S110 111.745 110 121.407v.098c0 9.662-7.987 17.495-18.55 17.495S73 131.225 73 121.593Zm27.286 0v-.088c0-4.89-3.584-9.085-8.836-9.085-5.251 0-8.686 4.127-8.686 8.987v.098c0 4.89 3.584 9.085 8.786 9.085 5.201 0 8.736-4.137 8.736-8.997M122 123.301V104h9.675v19.082c0 4.99 2.496 7.315 6.33 7.315s6.33-2.255 6.33-7.066V104H154v19.032C154 134.12 147.67 139 137.905 139 128.14 139 122 134.01 122 123.301M169 104h12.99c12.031 0 19.01 6.998 19.01 16.802v.099c0 9.815-7.077 17.099-19.196 17.099H169v-34Zm13.137 25.698c5.579 0 9.289-3.114 9.289-8.609V121c0-5.446-3.71-8.609-9.289-8.609h-3.828v17.267l3.828.04ZM215 104h28v8.26h-18.294v5.781h16.554v7.815h-16.554V138H215v-34ZM256 104h9.457v25.749H282V138h-26v-34ZM307.485 104h9.08L331 138h-10.098l-2.48-6.025h-13.121L302.88 138H293l14.485-34Zm8.25 20.685-3.784-9.597-3.874 9.597h7.658ZM343 104h16.445c5.326 0 8.995 1.364 11.33 3.695a10.5 10.5 0 0 1 2.391 3.636c.53 1.361.766 2.815.692 4.269v.099a10.576 10.576 0 0 1-1.854 6.351 10.962 10.962 0 0 1-5.302 4.093L375 138h-11.118l-7.015-10.296h-4.225V138H343v-34Zm16 16.323c3.285 0 5.175-1.561 5.175-4.031v-.099c0-2.678-2.022-4.031-5.226-4.031h-6.307v8.161H359ZM388 104h27.759v8.013h-18.292v5.148h16.567v7.431h-16.567v5.395H416V138h-28v-34ZM26.705 125.508a8.313 8.313 0 0 1-3.182 3.697 8.717 8.717 0 0 1-4.77 1.348c-5.27 0-8.882-4.233-8.882-9.082v-.098c0-4.888 3.522-8.995 8.78-8.995a8.92 8.92 0 0 1 5.044 1.521c1.479 1 2.6 2.419 3.212 4.062H37C35.425 109.954 28.169 104 18.752 104 8.034 104 0 111.821 0 121.5v.098C0 131.257 7.933 139 18.651 139c9.165 0 16.33-5.749 18.218-13.453l-10.164-.039Z"
      fill="#000"
    />
  </svg>
);

export default SvgComponent;
