import { ExtensionService } from 'core';
import React from 'react';
import { useMutation } from 'react-query';
import { reactQueryConfigError } from '../../jira-cloud/helpers/service.reactqery';
import { reactQueryConfig } from '../consts';

const useIntegrateSlack = () => {
  const _extensionService = new ExtensionService();
  return useMutation((code: string) => _extensionService.integrateSlackV2(code), {
    ...reactQueryConfig,
    onError: (error: any) => {
      const description = error?.response?.data?.meta?.error_message || 'Failed to integrate Slack';
      reactQueryConfigError({ description: description, title: 'Integrate Slack' });
    },
  });
};

const useRevokeSlack = () => {
  const _extensionService = new ExtensionService();
  return useMutation(() => _extensionService.revoke('slack_v2'), {
    ...reactQueryConfig,
    onError: (error: any) => {
      const description = error?.response?.data?.meta?.error_message || 'Failed to revoke Slack';
      reactQueryConfigError({ description: description, title: 'Revoke Slack' });
    },
  });
};

export { useIntegrateSlack, useRevokeSlack };
