import EasyMDE from '@SquadcastHub/easymde';
import React, { Component } from 'react';

import { CautionIcon } from '../../utils/icons';
import Grid from '../Grid';
import { IProps, MDE } from './MdBlockStyle';
import { defaultOption, defaultToolbar } from './mde.constant';
import { createFileUploadIcon, dynamicDropdownHandler } from './mdeOptionHelper';

interface IState {
  mount: boolean;
}

export function passedOptionMerger(passedOption: EasyMDE.Options = {}) {
  if (Object.keys(passedOption).length <= 0) {
    return { ...defaultOption };
  }
  if (passedOption.toolbar === false) {
    return { ...passedOption, toolbar: false };
  }
  return { ...passedOption, toolbar: defaultToolbar };
}

class MdBlock extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      mount: false,
    };
  }
  inputRef = this.props.inputref || React.createRef<null>();
  mde: any = null;
  giveParentToolbar = (): HTMLElement => {
    return this.inputRef?.current?.simpleMde.codemirror.getWrapperElement().parentElement;
  };
  componentDidMount() {
    setTimeout(() => {
      this.setState({ mount: true }, () => {
        this.mde = this.inputRef.current.simpleMde;
        const toolbar = this.giveParentToolbar();
        const uploadFileButton: HTMLElement | null = toolbar.querySelector('button.upload-file');
        // if there is a file uploadOption then create a file input element
        if (uploadFileButton) {
          createFileUploadIcon(uploadFileButton);
        }
        const dropdown =
          this.mde?.options.toolbar &&
          this.mde?.options.toolbar.find((item: any, _: number) => item.children);
        // if there are any toolbar in dropdown then only plcae the cusor activity
        if (dropdown) {
          dynamicDropdownHandler(this.mde, this.giveParentToolbar);
        }
      });
    }, 10);
  }
  componentWillUnmount() {
    this.mde?.codemirror.off('cursorActivity', () => {
      this.setState({ mount: false });
    });
  }

  render() {
    const { events, className, hideborders, ...props } = this.props;

    // create a temporary option object
    const optionsToUse = passedOptionMerger(this.props.options);
    return (
      <Grid width={props.width || '100%'} alignItems="flex-start">
        {this.state.mount && (
          <MDE
            {...props}
            options={optionsToUse}
            hideborders={hideborders}
            events={{
              ...events,
            }}
            ref={this.inputRef}
          />
        )}
        {props.error && <CautionIcon style={{ marginLeft: -34, marginTop: 16, zIndex: 1 }} />}
      </Grid>
    );
  }
}
/** @component */
export default MdBlock;
export { MDE };
