import {
  CreateScheduleMutation,
  CreateScheduleMutationVariables,
  useCreateScheduleMutation,
} from 'views/main/organization/schedules/graphql/mutation';
import { UseMutationOptions } from 'react-query';
import { schedulesTextCopy } from '../constants/schedules.copy';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/helpers.schedule';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_SCHEDULES_V2_CREATED } from 'core/const/tracker';

export const useCreateSchedule = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateScheduleMutation,
    TError,
    CreateScheduleMutationVariables,
    TContext
  >,
) =>
  useCreateScheduleMutation({
    ...options,
    onSuccess: () => {
      reactQueryConfigSuccess(schedulesTextCopy.toasts.created);
      AppTracker.track(T_WA_UP_SCHEDULES_V2_CREATED);
    },
    onError: reactQueryConfigError('Create Schedule'),
  });
