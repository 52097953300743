import { Avatar, HStack, Text, VStack } from '@chakra-ui/react';
import { UsernameIcon } from 'icons';
import { THEME_COLORS } from 'library/theme/colors';
import React from 'react';

export interface IUser {
  name: string;
  id: string;
  username?: string;
}
export interface IType {
  item: IUser;
  watcher: any;
  setUsers: any;
  isUser: boolean;
}
export const ProfileItem = ({ item, isUser }: IType) => {
  return (
    <HStack justifyContent="space-between" alignItems="center">
      <HStack>
        <Avatar size="sm" name={item.username ? item.name : 'Deleted'} src="#" />
        <VStack m="0px !important" alignItems="flex-start">
          <HStack>
            <Text fontSize="sm" as="span" ml={3}>
              {item.username ? item.name : 'Deleted User'}
            </Text>
            <Text fontSize="xs" as="span" ml={3} color="gray.500">
              {isUser ? 'You' : ''}
            </Text>
          </HStack>
          {item.username && (
            <HStack
              mt={'0px !important'}
              ml={'var(--chakra-space-3) !important'}
              alignItems="flex-start"
            >
              <UsernameIcon style={{ marginTop: 2 }} />
              <Text
                fontSize="xs"
                maxW="45ch"
                ml={'var(--chakra-space-1) !important'}
                fontWeight="bold"
                color={THEME_COLORS.secondary[700]}
              >
                {item.username}
              </Text>
            </HStack>
          )}
        </VStack>
      </HStack>
    </HStack>
  );
};
