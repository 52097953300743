import React, { ChangeEvent, Component } from 'react';
import { ApiTokenService } from '../../../../../core/services';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { exception } from '../../../../../core/exception';
import { IApiTokenResponseAdminGetAll } from '../../../../../core/interfaces/IApiToken';
import { mapArrayToObjectIndex } from 'core/helpers';
import { IUserInfo } from '../../../../../core/interfaces/IUserData';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import {
  T_WA_GS_USER_API_TOKEN_CREATED,
  T_WA_GS_USER_API_TOKEN_DELETED,
} from '../../../../../core/const/tracker';
import {
  Grid,
  Heading,
  Theme,
  Para,
  CopyBox,
  IconButton,
  Tooltip,
  TextButton,
  SelectBox,
  FocusBlock,
  Accordion,
  ContainerLoad,
} from 'uie/components';
import { DeleteIcon } from '../../../../../icons';

type IProps = Pick<IAppState, 'organization' | 'userInfo' | 'roles'>;

interface IState extends Pick<IApiTokenResponseAdminGetAll, 'data'> {
  loadingAPITokens: boolean;
  showAssignNew: boolean;
  userSearch: string;
  selectedUserId: string;
}

class OrganizationAPITokenTab extends Component<IProps, IState> {
  private _apiTokenService = new ApiTokenService();
  private _oUsers = mapArrayToObjectIndex<IUserInfo>(
    this.props.organization.users.u,
    'id',
    'User Deleted',
  );
  private _currentUser = this._oUsers(this.props.userInfo?.d?.id ?? '');

  constructor(props: IProps) {
    super(props);

    this.state = {
      loadingAPITokens: true,
      showAssignNew: false,
      data: [],
      userSearch: '',
      selectedUserId: '',
    };
  }

  componentDidMount() {
    this.getAllUsersAPIToken();
  }

  public toggleAssignUser = () =>
    this.setState(({ showAssignNew }) => ({ showAssignNew: !showAssignNew, selectedUserId: '' }));
  public onUserSearchChange = (event: ChangeEvent<HTMLInputElement>) =>
    this.setState({ userSearch: event.target.value });
  public onUserSelectChange = (_: any, selectedUserId: string) => this.setState({ selectedUserId });

  public getAllUsersAPIToken = async () => {
    if (!this._currentUser) return;
    try {
      const {
        data: { data: userTokens },
      } = await this._apiTokenService.adminGetAllTokens();
      this.setState({ data: userTokens });
    } catch (err) {
      exception.handle('E_GET_ADMIN_API_TOKEN', err);
    } finally {
      this.setState({ loadingAPITokens: false });
    }
  };

  public createUserToken = () => async () => {
    if (!this._currentUser) return;

    AppTracker.track(T_WA_GS_USER_API_TOKEN_CREATED);
    this.setState({ loadingAPITokens: true });
    try {
      await this._apiTokenService.adminCreateUserToken(this.state.selectedUserId);
      this.getAllUsersAPIToken();
    } catch (err) {
      exception.handle('E_CREATE_ADMIN_API_TOKEN', err);
      this.setState({ loadingAPITokens: false });
    } finally {
      this.toggleAssignUser();
    }
  };

  public deleteUserApiToken = (tokenId: string) => async () => {
    if (!this._currentUser) return;
    AppTracker.track(T_WA_GS_USER_API_TOKEN_DELETED);

    this.setState({ loadingAPITokens: true });
    try {
      await this._apiTokenService.adminDeleteToken(tokenId);
      this.getAllUsersAPIToken();
    } catch (err) {
      exception.handle('E_DELETE_ADMIN_API_TOKEN', err);
      this.setState({ loadingAPITokens: false });
    }
  };

  render() {
    const { theme } = Theme;
    const { data: userTokens, loadingAPITokens, showAssignNew, userSearch } = this.state;

    return (
      <>
        <ContainerLoad isLoading={loadingAPITokens} />
        <Grid
          type="column"
          flexWidth={12}
          className="org_settings__container_padding global--soft-scroll"
        >
          <Accordion
            title={
              <Grid justifyContent="space-between" flexWidth={12} alignItems="baseline">
                <Heading fontSize={20} height={32} fontWeight={500} color={theme.shades.black}>
                  User API Tokens
                </Heading>
                {userTokens.length > 0 && (
                  <Para
                    fontSize={20}
                    color={theme.shades.black}
                    style={{ marginLeft: 10, marginRight: 'auto' }}
                  >
                    ({userTokens.length})
                  </Para>
                )}

                {!loadingAPITokens && !showAssignNew && (
                  <TextButton buttonType="inverted" onClick={this.toggleAssignUser}>
                    <Para fontSize={16} color={theme.primary.default}>
                      Assign New Token
                    </Para>
                  </TextButton>
                )}
              </Grid>
            }
            tabProps={{
              style: { padding: 0, background: 'transparent' },
            }}
          >
            {!loadingAPITokens && showAssignNew && (
              <div
                className="br-3 mt-10"
                style={{ background: theme.shades.whiteSmoke, padding: 16 }}
              >
                <Heading fontSize={16} height={24} fontWeight={500} color={theme.shades.black}>
                  Assign new API token to a user
                </Heading>
                <div className="mt-10">
                  <SelectBox
                    hook={
                      <Para>
                        {this.state.selectedUserId.length === 0
                          ? 'Select a user'
                          : `${this._oUsers(this.state.selectedUserId).first_name} ${
                              this._oUsers(this.state.selectedUserId).last_name
                            }`}
                      </Para>
                    }
                    onValueChange={this.onUserSelectChange}
                    height="auto"
                    maxHeight="200px"
                    searchHookProps={{
                      value: userSearch,
                      height: '24px',
                      fontSize: '16px',
                      placeholder: 'Search',
                      onChange: this.onUserSearchChange,
                    }}
                  >
                    {(this.props.organization.users?.u ?? [])
                      .filter(
                        (u: IUserInfo) =>
                          !userTokens.some(t => t.user_id === u.id) &&
                          (u.first_name
                            .toLowerCase()
                            .includes(this.state.userSearch.toLowerCase()) ||
                            u.last_name
                              .toLowerCase()
                              .includes(this.state.userSearch.toLowerCase())),
                      )
                      .map((u: IUserInfo, index: number) => (
                        <FocusBlock value={u.id} key={index}>
                          <Para style={{ textAlign: 'left' }}>
                            {this._oUsers(u.id).first_name} {this._oUsers(u.id).last_name}
                          </Para>
                        </FocusBlock>
                      ))}
                  </SelectBox>
                  <Grid className="mt-20" justifyContent="flex-start" alignItems="center">
                    <TextButton
                      onClick={this.createUserToken()}
                      disabled={this.state.selectedUserId === ''}
                    >
                      <Para fontWeight={500} color={theme.shades.white}>
                        Create
                      </Para>
                    </TextButton>

                    <TextButton
                      buttonType="ghost"
                      onClick={this.toggleAssignUser}
                      className="ml-10"
                    >
                      <Para>Cancel</Para>
                    </TextButton>
                  </Grid>
                </div>
              </div>
            )}
          </Accordion>
          <Para style={{ marginBottom: '10px', fontSize: '12px', color: '#808080' }}>
            Generate refresh token to access Squadcast API. Learn more about user API tokens{' '}
            <a
              href="https://support.squadcast.com/terraform-and-api-documentation/public-api-refresh-token"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            . Explore our API Docs{' '}
            <a href="https://apidocs.squadcast.com/" target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </Para>
          {!this.state.loadingAPITokens && (
            <div className="br-3 mt-20 mb-20">
              {userTokens.length === 0 && (
                <Grid justifyContent="flex-start" alignItems="center">
                  No Tokens generated Yet!
                </Grid>
              )}
              {userTokens.map((t, index) => {
                const user = this._oUsers(t.user_id);
                const userName = `${user?.first_name ?? 'Deleted user'} ${user?.last_name ?? ''}`;

                return (
                  <Grid
                    key={index}
                    className="pb-10 mb-10"
                    alignItems="center"
                    flexWidth={12}
                    style={{ borderBottom: '1px solid var(--shades-whiteSmoke)' }}
                  >
                    <Grid flexWidth={4}>
                      <Grid type="column">
                        <Para fontSize={16} color={theme.shades.black}>
                          {userName}
                        </Para>
                        <Para fontSize={14} color={theme.shades.grey}>
                          {user?.email ?? 'Deleted user'}
                        </Para>
                      </Grid>
                    </Grid>
                    <Grid flexWidth={8} alignItems="center">
                      <CopyBox>{t.token}</CopyBox>
                      <Tooltip label={`Delete token`} offset={{ left: '-20px' }}>
                        <IconButton
                          className="ml-10"
                          base="32px"
                          onClick={this.deleteUserApiToken(t.id)}
                        >
                          <DeleteIcon stroke={theme.danger.default} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          )}
          {!this.state.loadingAPITokens && <></>}
        </Grid>
      </>
    );
  }
}

export default connect(({ organization, userInfo, roles }: IAppState) => ({
  organization,
  userInfo,
  roles,
}))(OrganizationAPITokenTab);
