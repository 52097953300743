import { Center, Image, Text } from '@chakra-ui/react';
import { FormButton } from 'library/atoms';

import { INCIDENT_STATUS } from '../common/enums';
import { toSentenceCase } from '../common/util';

type Props = {
  status: INCIDENT_STATUS;
  errorMsg?: string;
  onCreate?: () => void;
};

export const Placeholder = ({ status, onCreate, errorMsg }: Props) => {
  const createIncident = () => {
    onCreate?.();
  };

  const isAllIncidents = status === INCIDENT_STATUS.ALL;

  return (
    <Center flexDirection="column" rowGap="16px">
      <Image
        style={{
          display: 'inline-block',
          width: '400px',
        }}
        src={`/icons/empty-states/${status}-incidents.png`}
        alt="No Incidents"
      />
      <Text variant="placeholder" color="secondary.900">
        {errorMsg || `No ${isAllIncidents ? '' : toSentenceCase(status)} Incidents`}
      </Text>
      {!errorMsg && isAllIncidents && (
        <FormButton title="Create New Incident" onClick={createIncident}></FormButton>
      )}
    </Center>
  );
};
