import React from 'react';
import { useLocation } from 'react-router-dom';

export const OVERVIEW_TAB_INDEX = 1;

export enum SERVICES_TAB {
  OVERVIEW = 'overview',
  GRAPH = 'graph',
}

function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default useQueryParams;

export const getServiceTabIndex = (tabName: string) => {
  const tabs = Object.values(SERVICES_TAB);
  let index = tabs.findIndex(tab => tab === tabName);
  // if tab not found, defaults to Overview tab
  if (index === -1) {
    index = tabs.findIndex(tab => tab === SERVICES_TAB.OVERVIEW);
  }
  // add 1, as tab indexes (in tabs molecules) starts from 1st index
  return index + 1;
};

export const getServiceTabName = (index: number) => {
  const tabs = Object.values(SERVICES_TAB);
  return index > 0 && index <= tabs.length ? tabs[index - 1] : SERVICES_TAB.OVERVIEW;
};
