import { SVGProps } from 'react';

import AdminLabs from './AlertSources/Admin-labs';
import Airbrake from './AlertSources/Airbrake';
import AmazonCodeguruViaCloudwatch from './AlertSources/Amazon-codeguru-via-cloudwatch';
import AmazonEc2AutoScaling from './AlertSources/Amazon-ec2-auto-scaling';
import AmazonEventbridge from './AlertSources/Amazon-eventbridge';
import AmazonGuardduty from './AlertSources/Amazon-guardduty';
import AmazonLambda from './AlertSources/Amazon-lambda';
import AmazonOpensearch from './AlertSources/Amazon-opensearch';
import AmazonOpensearchViaCloudwatch from './AlertSources/Amazon-opensearch-via-cloudwatch';
import AmazonRds from './AlertSources/Amazon-rds';
import AmazonSesViaCloudwatch from './AlertSources/Amazon-ses-via-cloudwatch';
import AmazonSns from './AlertSources/Amazon-sns';
import Api from './AlertSources/Api';
import Apimetrics from './AlertSources/Apimetrics';
import Appdynamics from './AlertSources/Appdynamics';
import Appsignal from './AlertSources/Appsignal';
import Auvik from './AlertSources/Auvik';
import AwsElasticBeanstalkViaCloudWatch from './AlertSources/Aws-elastic-beanstalk-via-cloudWatch';
import AwsElasticBeanstalkLogo from './AlertSources/AwsElasticBeanstalkLogo';
import AwsSsoLogo from './AlertSources/AwsSsoLogo';
import AzureActiveDirectoryLogo from './AlertSources/AzureActiveDirectoryLogo';
import Azuremonitor from './AlertSources/Azuremonitor';
import BetterUptime from './AlertSources/Better-uptime';
import Bitbucket from './AlertSources/Bitbucket';
import BitrixIcon from './AlertSources/Bitrix-24';
import BlueMatador from './AlertSources/Blue-matador';
import Bugsnag from './AlertSources/Bugsnag';
import Buildkite from './AlertSources/Buildkite';
import Checkly from './AlertSources/Checkly';
import Checkmk from './AlertSources/Checkmk';
import Circleci from './AlertSources/Circleci';
import CircleCiLogo20X20 from './AlertSources/CircleCiLogo20X20';
import CiscoDnac from './AlertSources/Cisco-dnac';
import CiscoMeraki from './AlertSources/Cisco-meraki';
import Clickup from './AlertSources/Clickup';
import Cloudflare from './AlertSources/Cloudflare';
import CloudtrailCloudwatch from './AlertSources/Cloudtrail-cloudwatch';
import CloudtrailLogs from './AlertSources/Cloudtrail-logs';
import Cloudwatch from './AlertSources/Cloudwatch';
import CloudwatchEventRules from './AlertSources/Cloudwatch-event-rules';
import Conviva from './AlertSources/Conviva';
import Copperegg from './AlertSources/Copperegg';
import Coralogix from './AlertSources/Coralogix';
import Create from './AlertSources/Create';
import Cronitor from './AlertSources/Cronitor';
import CrowdstrikeFalcon from './AlertSources/Crowdstrike-falcon';
import Datadog from './AlertSources/Datadog';
import DataSync from './AlertSources/DataSync';
import DeadMansSnitch from './AlertSources/Dead-mans-snitch';
import Domotz from './AlertSources/Domotz';
import DotcomMonitor from './AlertSources/Dotcom-monitor';
import Dynatrace from './AlertSources/Dynatrace';
import Elastalert from './AlertSources/Elastalert';
import Elastic from './AlertSources/Elastic';
import ElecardBoro from './AlertSources/Elecard-boro';
import Email from './AlertSources/Email';
import Errorception from './AlertSources/Errorception';
import Freshdesk from './AlertSources/Freshdesk';
import Freshping from './AlertSources/Freshping';
import Freshservice from './AlertSources/Freshservice';
import GhostHyphenInspector from './AlertSources/Ghost-inspector';
import GhostInspector from './AlertSources/GhostInspector';
import Github from './AlertSources/Github';
import Gitlab from './AlertSources/Gitlab';
import GoogleHangoutsLogo from './AlertSources/GoogleHangoutsLogo';
import GoogleSsoLogo from './AlertSources/GoogleSsoLogo';
import Grafana from './AlertSources/Grafana';
import Grafana_8 from './AlertSources/Grafana-8';
import Graylog from './AlertSources/Graylog';
import GraylogV4 from './AlertSources/Graylog-v4';
import Halopsa from './AlertSources/Halopsa';
import Healthchecks from './AlertSources/Healthchecks';
import Heroku from './AlertSources/Heroku';
import Hetrixtools from './AlertSources/Hetrixtools';
import HoneyBadger from './AlertSources/Honey-badger';
import Honeycomb from './AlertSources/Honeycomb';
import Humio from './AlertSources/Humio';
import Hund from './AlertSources/Hund';
import Hydrozen from './AlertSources/Hydrozen';
import Hyperping from './AlertSources/Hyperping';
import Icinga2 from './AlertSources/Icinga2';
import Insightops from './AlertSources/Insightops';
import Instana from './AlertSources/Instana';
import Intercom from './AlertSources/Intercom';
import Jenkins from './AlertSources/Jenkins';
import JiraCloud from './AlertSources/Jira-cloud';
import JiraServer from './AlertSources/Jira-server';
import JiraPlugin from './AlertSources/JiraPlugin';
import Kapacitor from './AlertSources/Kapacitor';
import Kibana from './AlertSources/Kibana';
import Komodor from './AlertSources/Komodor';
import Librenms from './AlertSources/Librenms';
import Linear from './AlertSources/Linear';
import Logdna from './AlertSources/Logdna';
import Loggly from './AlertSources/Loggly';
import LogicMonitor from './AlertSources/Logic-monitor';
import Logstash from './AlertSources/Logstash';
import Logzio from './AlertSources/Logzio';
import ManageengineAppmanager from './AlertSources/Manageengine-appmanager';
import ManageengineOpmanager from './AlertSources/Manageengine-opmanager';
import MezmoLogo20X20 from './AlertSources/MezmoLogo20X20';
import MicrosoftLogo20X20 from './AlertSources/MicrosoftLogo20X20';
import MicrosoftTeamsLogo from './AlertSources/MicrosoftTeamsLogo';
import MongoAtlas from './AlertSources/Mongo-atlas';
import MongoCloudManager from './AlertSources/Mongo-cloud-manager';
import Nagios from './AlertSources/Nagios';
import Newrelic from './AlertSources/Newrelic';
import Nixstats from './AlertSources/Nixstats';
import Nodeping from './AlertSources/Nodeping';
import NoImage from './AlertSources/NoIMage';
import Observium from './AlertSources/Observium';
import OhDear from './AlertSources/Oh-dear';
import OktaLogo20X20 from './AlertSources/OktaLogo20X20';
import OsnexusQuantastor from './AlertSources/Osnexus-quantastor';
import Overops from './AlertSources/Overops';
import Papertrail from './AlertSources/Papertrail';
import Pingdom from './AlertSources/Pingdom';
import Plesk_360 from './AlertSources/Plesk-360';
import Postman from './AlertSources/Postman';
import Postmark from './AlertSources/Postmark';
import Powercode from './AlertSources/Powercode';
import ProgressWhatsupGold from './AlertSources/Progress-whatsup-gold';
import Prometheus from './AlertSources/Prometheus';
import Prtg from './AlertSources/Prtg';
import RapidSike from './AlertSources/Rapidspike';
import Rapid7Insightidr from './AlertSources/Rapid7-insightidr';
import Redash from './AlertSources/Redash';
import RedgateSqlMonitor from './AlertSources/Redgate-sql-monitor';
import Retrace from './AlertSources/Retrace';
import Rollbar from './AlertSources/Rollbar';
import Rundeck from './AlertSources/Rundeck';
import Runscope from './AlertSources/Runscope';
import SalesforceCloud from './AlertSources/Salesforce-cloud';
import SalesforceLogo from './AlertSources/SalesforceLogo';
import Scoutapm from './AlertSources/Scoutapm';
import Sematext from './AlertSources/Sematext';
import Sensu from './AlertSources/Sensu';
import SensuGo from './AlertSources/Sensu-go';
import Sentry from './AlertSources/Sentry';
import Serverdensity from './AlertSources/Serverdensity';
import Serverguard24 from './AlertSources/Serverguard24';
import Servicenow from './AlertSources/Servicenow';
import Servicedeskplusod from './AlertSources/Servicedeskplusod';
import Shortcut from './AlertSources/Shortcut';
import Signalfx from './AlertSources/Signalfx';
import Site24x7 from './AlertSources/Site24x7';
import Signoz from './AlertSources/Signoz';
import SlackLogo20X20 from './AlertSources/SlackLogo20X20';
import SolarwindsAppoptics from './AlertSources/Solarwinds-appoptics';
import SolarwindsDatabasePerformanceAnalyzer from './AlertSources/Solarwinds-database-performance-analyzer';
import SolarwindsIpAddressManager from './AlertSources/Solarwinds-ip-address-manager';
import SolarwindsLogAnalyzer from './AlertSources/Solarwinds-log-analyzer';
import SolarwindsNetflowTrafficAnalyzer from './AlertSources/Solarwinds-netflow-traffic-analyzer';
import SolarwindsNetworkConfigurationManager from './AlertSources/Solarwinds-network-configuration-manager';
import SolarwindsNetworkPerformanceManager from './AlertSources/Solarwinds-network-performance-manager';
import SolarwindsOrion from './AlertSources/Solarwinds-orion';
import SolarwindsPatchManager from './AlertSources/Solarwinds-patch-manager';
import SolarwindsServerApplicationMonitor from './AlertSources/Solarwinds-server-application-monitor';
import SolarwindsServerConfigurationMonitor from './AlertSources/Solarwinds-server-configuration-monitor';
import SolarwindsStorageResourceMonitor from './AlertSources/Solarwinds-storage-resource-monitor';
import SolarwindsUserDeviceTracker from './AlertSources/Solarwinds-user-device-tracker';
import SolarwindsVirtualizationManager from './AlertSources/Solarwinds-virtualization-manager';
import SolarwindsVoipNetworkQualityManager from './AlertSources/Solarwinds-voip-network-quality-manager';
import SolarwindsWebPerformanceMonitor from './AlertSources/Solarwinds-web-performance-monitor';
import Sonar from './AlertSources/Sonar';
import Splunk from './AlertSources/Splunk';
import Sqreen from './AlertSources/Sqreen';
import Squadcastui from './AlertSources/Squadcastui';
import Stackdriver from './AlertSources/Stackdriver';
import StackifyRetraceLogo20X20 from './AlertSources/StackifyRetraceLogo20X20';
import Stathat from './AlertSources/Stathat';
import StatusCake from './AlertSources/Status-cake';
import SumoLogic from './AlertSources/Sumo-logic';
import SysdigMonitor from './AlertSources/Sysdig-monitor';
import TerraformLogo20X20 from './AlertSources/TerraformLogo20X20';
import ThreatStack from './AlertSources/Threat-stack';
import Trello from './AlertSources/Trello';
import Twilio from './AlertSources/Twilio';
import UptimeRobot from './AlertSources/Uptime-robot';
import Uptrends from './AlertSources/Uptrends';
import Wavefront from './AlertSources/Wavefront';
import Zabbix from './AlertSources/Zabbix';
import Zabbix_5_0 from './AlertSources/Zabbix-5.0';
import Zabbix_6_2 from './AlertSources/Zabbix-6.2';

import Zendesk from './AlertSources/Zendesk';
import ZohoDesk from './AlertSources/Zoho-desk';
import ZohoDeskViaZohoFlow from './AlertSources/Zoho-desk-via-zoho-flow';

export const AlertSourceIcons: { [key: string]: (props: SVGProps<SVGSVGElement>) => JSX.Element } =
  {
    'Admin-labs': AdminLabs,
    Airbrake: Airbrake,
    'Amazon-codeguru-via-cloudwatch': AmazonCodeguruViaCloudwatch,
    'Amazon-ec2-auto-scaling': AmazonEc2AutoScaling,
    'Amazon-eventbridge': AmazonEventbridge,
    'Amazon-guardduty': AmazonGuardduty,
    'Amazon-lambda': AmazonLambda,
    'Amazon-opensearch-via-cloudwatch': AmazonOpensearchViaCloudwatch,
    'Amazon-opensearch': AmazonOpensearch,
    'Amazon-rds': AmazonRds,
    'Amazon-ses-via-cloudwatch': AmazonSesViaCloudwatch,
    'Amazon-sns': AmazonSns,
    Api: Api,
    Apimetrics: Apimetrics,
    Appdynamics: Appdynamics,
    Appsignal: Appsignal,
    Auvik: Auvik,
    'Aws-elastic-beanstalk-via-cloudWatch': AwsElasticBeanstalkViaCloudWatch,
    AwsElasticBeanstalkLogo: AwsElasticBeanstalkLogo,
    AwsSsoLogo: AwsSsoLogo,
    AzureActiveDirectoryLogo: AzureActiveDirectoryLogo,
    Azuremonitor: Azuremonitor,
    'Better-uptime': BetterUptime,
    Bitbucket: Bitbucket,
    'Bitrix-24': BitrixIcon,
    'Blue-matador': BlueMatador,
    Bugsnag: Bugsnag,
    Buildkite: Buildkite,
    Checkly: Checkly,
    Checkmk: Checkmk,
    CircleCiLogo20X20: CircleCiLogo20X20,
    Circleci: Circleci,
    'Cisco-dnac': CiscoDnac,
    'Cisco-meraki': CiscoMeraki,
    Clickup: Clickup,
    Cloudflare: Cloudflare,
    'Cloudtrail-cloudwatch': CloudtrailCloudwatch,
    'Cloudtrail-logs': CloudtrailLogs,
    'Cloudwatch-event-rules': CloudwatchEventRules,
    Cloudwatch: Cloudwatch,
    Conviva: Conviva,
    Copperegg: Copperegg,
    Coralogix: Coralogix,
    Create: Create,
    Cronitor: Cronitor,
    'Crowdstrike-falcon': CrowdstrikeFalcon,
    DataSync: DataSync,
    Datadog: Datadog,
    'Dead-mans-snitch': DeadMansSnitch,
    Domotz: Domotz,
    'Dotcom-monitor': DotcomMonitor,
    Dynatrace: Dynatrace,
    Elastalert: Elastalert,
    Elastic: Elastic,
    'Elecard-boro': ElecardBoro,
    Email: Email,
    Errorception: Errorception,
    Freshdesk: Freshdesk,
    Freshping: Freshping,
    Freshservice: Freshservice,
    'Ghost-inspector': GhostHyphenInspector,
    GhostInspector: GhostInspector,
    Github: Github,
    Gitlab: Gitlab,
    GoogleHangoutsLogo: GoogleHangoutsLogo,
    GoogleSsoLogo: GoogleSsoLogo,
    'Grafana-8': Grafana_8,
    Grafana: Grafana,
    'Graylog-v4': GraylogV4,
    Graylog: Graylog,
    Halopsa: Halopsa,
    Healthchecks: Healthchecks,
    Heroku: Heroku,
    Hetrixtools: Hetrixtools,
    'Honey-badger': HoneyBadger,
    Honeycomb: Honeycomb,
    Humio: Humio,
    Hund: Hund,
    Hydrozen: Hydrozen,
    Hyperping: Hyperping,
    Icinga2: Icinga2,
    Insightops: Insightops,
    Instana: Instana,
    Intercom: Intercom,
    Jenkins: Jenkins,
    'Jira-cloud': JiraCloud,
    'Jira-server': JiraServer,
    JiraPlugin: JiraPlugin,
    Kapacitor: Kapacitor,
    Kibana: Kibana,
    Komodor: Komodor,
    Librenms: Librenms,
    Linear: Linear,
    Logdna: Logdna,
    Loggly: Loggly,
    'Logic-monitor': LogicMonitor,
    Logstash: Logstash,
    Logzio: Logzio,
    'Manageengine-appmanager': ManageengineAppmanager,
    'Manageengine-opmanager': ManageengineOpmanager,
    MezmoLogo20X20: MezmoLogo20X20,
    MicrosoftLogo20X20: MicrosoftLogo20X20,
    MicrosoftTeamsLogo: MicrosoftTeamsLogo,
    'Mongo-atlas': MongoAtlas,
    'Mongo-cloud-manager': MongoCloudManager,
    Nagios: Nagios,
    Newrelic: Newrelic,
    Nixstats: Nixstats,
    NoImage: NoImage,
    Nodeping: Nodeping,
    Observium: Observium,
    'Oh-dear': OhDear,
    OktaLogo20X20: OktaLogo20X20,
    'Osnexus-quantastor': OsnexusQuantastor,
    Overops: Overops,
    Papertrail: Papertrail,
    Pingdom: Pingdom,
    'Plesk-360': Plesk_360,
    Postman: Postman,
    Postmark: Postmark,
    Powercode: Powercode,
    'Progress-whatsup-gold': ProgressWhatsupGold,
    Prometheus: Prometheus,
    Prtg: Prtg,
    Rapidspike: RapidSike,
    'Rapid7-insightidr': Rapid7Insightidr,
    Redash: Redash,
    'Redgate-sql-monitor': RedgateSqlMonitor,
    Retrace: Retrace,
    Rollbar: Rollbar,
    Rundeck: Rundeck,
    Runscope: Runscope,

    'Salesforce-cloud': SalesforceCloud,
    SalesforceLogo: SalesforceLogo,
    Scoutapm: Scoutapm,
    Sematext: Sematext,
    'Sensu-go': SensuGo,
    Sensu: Sensu,
    Sentry: Sentry,
    Serverdensity: Serverdensity,
    Serverguard24: Serverguard24,
    Servicenow: Servicenow,
    Sdpod: Servicedeskplusod,
    Shortcut: Shortcut,
    Signalfx: Signalfx,
    Site24x7: Site24x7,
    Signoz: Signoz,
    SlackLogo20X20: SlackLogo20X20,
    'Solarwinds-appoptics': SolarwindsAppoptics,
    'Solarwinds-database-performance-analyzer': SolarwindsDatabasePerformanceAnalyzer,
    'Solarwinds-ip-address-manager': SolarwindsIpAddressManager,
    'Solarwinds-log-analyzer': SolarwindsLogAnalyzer,
    'Solarwinds-netflow-traffic-analyzer': SolarwindsNetflowTrafficAnalyzer,
    'Solarwinds-network-configuration-manager': SolarwindsNetworkConfigurationManager,
    'Solarwinds-network-performance-manager': SolarwindsNetworkPerformanceManager,
    'Solarwinds-orion': SolarwindsOrion,
    'Solarwinds-patch-manager': SolarwindsPatchManager,
    'Solarwinds-server-application-monitor': SolarwindsServerApplicationMonitor,
    'Solarwinds-server-configuration-monitor': SolarwindsServerConfigurationMonitor,
    'Solarwinds-storage-resource-monitor': SolarwindsStorageResourceMonitor,
    'Solarwinds-user-device-tracker': SolarwindsUserDeviceTracker,
    'Solarwinds-virtualization-manager': SolarwindsVirtualizationManager,
    'Solarwinds-voip-network-quality-manager': SolarwindsVoipNetworkQualityManager,
    'Solarwinds-web-performance-monitor': SolarwindsWebPerformanceMonitor,
    Sonar: Sonar,
    Splunk: Splunk,
    Sqreen: Sqreen,
    Squadcastui: Squadcastui,
    Webform: Squadcastui,
    Stackdriver: Stackdriver,
    StackifyRetraceLogo20X20: StackifyRetraceLogo20X20,
    Stathat: Stathat,
    'Status-cake': StatusCake,
    'Sumo-logic': SumoLogic,
    'Sysdig-monitor': SysdigMonitor,
    TerraformLogo20X20: TerraformLogo20X20,
    'Threat-stack': ThreatStack,
    Trello: Trello,
    Twilio: Twilio,
    'Uptime-robot': UptimeRobot,
    Uptrends: Uptrends,
    Wavefront: Wavefront,
    'Zabbix-5.0': Zabbix_5_0,
    'Zabbix-6.2': Zabbix_6_2,
    Zabbix: Zabbix,
    Zendesk: Zendesk,
    'Zoho-desk-via-zoho-flow': ZohoDeskViaZohoFlow,
    'Zoho-desk': ZohoDesk,
  };
