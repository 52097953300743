import { T_WA_INCIDENT_SUMMARY_GENERATE_SUMMARY_BUTTON_CLICKED } from 'core/const/tracker';
import { BetaIcon, CrownIcon, GenAiIcon } from 'icons';
import { AppTracker } from 'shared/analytics/tracker';
import { Grid, Para, TextButton, Theme } from 'uie/components';

import { useIncidentSummaryContext } from '../context/hook';

const { theme } = Theme;

const GenerateIncidentSummaryButton: React.FC = () => {
  const { incidentId, setSummaryDrawerOpen, canUseIncidentSummary, setShowUpgradeModal } =
    useIncidentSummaryContext();

  const Icon = () =>
    canUseIncidentSummary ? (
      <BetaIcon
        height={18}
        width={33}
        className="newFlag"
        style={{ position: 'absolute', right: -16, top: -9 }}
      />
    ) : (
      <CrownIcon
        height={18}
        width={33}
        className="newFlag"
        style={{ position: 'absolute', right: -16, top: -9 }}
      />
    );

  return (
    <TextButton
      color={theme.primary.default}
      buttonType="inverted"
      style={{ padding: '8px 16px', height: 37, width: 192, position: 'relative' }}
      onClick={e => {
        if (canUseIncidentSummary) {
          setSummaryDrawerOpen(true);
          e.currentTarget.blur();
        } else {
          setShowUpgradeModal(true);
        }
        AppTracker.track(T_WA_INCIDENT_SUMMARY_GENERATE_SUMMARY_BUTTON_CLICKED, {
          'Incident ID': incidentId,
        });
      }}
    >
      <Icon />
      <Grid alignItems="center" justifyContent="center">
        <GenAiIcon height={24} width={24} className="mr-5" />
        <Para color={theme.primary.default} fontSize={14} fontWeight={400} className="mr-5">
          Generate Summary
        </Para>
      </Grid>
    </TextButton>
  );
};

export default GenerateIncidentSummaryButton;
