import { useEffect } from 'react';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { JiraTicketCreateAction } from 'views/main/organization/workflow/types';
import { jiraTicketValidation } from 'views/main/organization/workflow/constant/validation';
import { useFormContext } from 'react-hook-form';
import { FormFooter } from '../squadcast/form-footer';
import JiraTicketForm from './JiraTicketForm';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import DrawerFooterWrapper from 'library/molecules/Drawer';

const JiraTicketContainer = () => {
  const { actionForm, onActionSubmit, checkDirtyAndCloseDrawer, setIsDirty } =
    useWorkflowFormContext();
  const { formState } = useFormContext<JiraTicketCreateAction>();
  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <FormWrapper<JiraTicketCreateAction>
      onSubmit={onActionSubmit}
      defaultValues={actionForm! as JiraTicketCreateAction}
      validationSchema={jiraTicketValidation}
    >
      <JiraTicketForm />
      <DrawerFooterWrapper>
        <FormFooter<JiraTicketCreateAction> checkDirtyAndCloseDrawer={checkDirtyAndCloseDrawer} />
      </DrawerFooterWrapper>
    </FormWrapper>
  );
};

export default JiraTicketContainer;
