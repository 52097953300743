import { GetStatusPageDetailsQuery } from 'views/main/organization/statuspage-v2/graphql/query';

export const getStatusCodeMapping = (
  statusCodes: GetStatusPageDetailsQuery['getStatusPageDetails']['statusCodes'] = [],
) => {
  const map: Record<string, string> = { 'under-maintenance': 'Under Maintenance' };
  statusCodes
    ?.filter(status => status !== null)
    ?.forEach(status => ((map as any)[status?.slug] = status?.message));
  return map;
};

export const getStatusCodeColorMapping = (
  statusCodes: GetStatusPageDetailsQuery['getStatusPageDetails']['statusCodes'] = [],
) => {
  const map: Record<string, string> = {};
  statusCodes
    ?.filter(status => status !== null)
    ?.forEach(status => ((map as any)[status?.slug] = status?.color));
  return map;
};
