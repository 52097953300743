import { Box, chakra, useStyleConfig } from '@chakra-ui/react';
import { FC } from 'react';

const Track = chakra(Box);

export const Switch: FC<{
  isOn?: boolean;
  isDisabled?: boolean;
  size?: 'md' | 'lg';
  handleChange?: () => void;
  variant?: string;
}> = ({ isDisabled = false, size = 'md', isOn = false, handleChange, variant = 'default' }) => {
  const styles = useStyleConfig('Switch', { isDisabled, size, active: isOn, variant });

  return <Track sx={styles} variant={variant} onClick={handleChange} />;
};
