import React, { useEffect, useState } from 'react';
import PageLengthSelector from './pageLengthSelector';
import PaginationArrow from './paginationArrow';

interface Props {
  pageCount: number;
  pageSize: number;
  totalCount: number;
  pageIndex: number;
  setPageSize: (pageSize: number) => void;
  setPageIndex: (pageIndex: number) => void;
}

function PaginationBar(props: Props): JSX.Element {
  const filterPages = (visiblePages: number[], totalPages: number) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  const getVisiblePages = (page: number, pageCount: number) => {
    if (pageCount < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], pageCount);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < pageCount) {
        return [page - 2, page - 1, page, page + 1, page + 2];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= pageCount) {
        return [pageCount - 4, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
      } else {
        return [1, 2, 3, 4, 5];
      }
    }
  };

  const visiblePages = getVisiblePages(props.pageIndex + 1, props.pageCount);

  function changePage(page: number) {
    const activePage = props.pageIndex + 1;

    if (page === activePage) {
      return;
    }
    props.setPageIndex(page - 1);
  }
  return (
    <div
      style={{
        paddingTop: '32px',
        display: 'flex',
        paddingBottom: '50px',
        backgroundColor: 'white',
        paddingLeft: '10px',
      }}
    >
      <PageLengthSelector
        totalCount={props.totalCount}
        pageSize={props.pageSize}
        setPageSize={props.setPageSize}
      />
      <div className="float-right" style={{ flexGrow: 100 }}>
        <PaginationArrow
          visiblePages={visiblePages}
          activePage={props.pageIndex + 1}
          changePage={changePage}
          pageCount={props.pageCount}
        />
      </div>
    </div>
  );
}

export default PaginationBar;
