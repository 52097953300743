import { DialogModalFrame, Para } from 'uie/components';
import ConfirmModal from 'components/confirm.modal';
import React, { useEffect } from 'react';
import { SquadMember } from '../types';

type Props = {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  squadName: string;
  members?: SquadMember[];
  userName?: string;
  onConfirm: (value: boolean) => void;
};
export function ConfirmDeleteModal({
  openModal,
  setOpenModal,
  members,
  squadName,
  userName,
  onConfirm,
}: Props) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);
  return (
    <DialogModalFrame
      root="rsg-root"
      id="modalFrame"
      onClose={() => setOpenModal(false)}
      padding="24px"
      width="500px"
    >
      {openModal && (
        <ConfirmModal
          displayText={
            members ? (
              <Para>
                Delete <span style={{ fontWeight: 'bold' }}> {squadName} </span> with{' '}
                <span style={{ fontWeight: 'bold' }}> {members.length} Members </span> ?
              </Para>
            ) : (
              <Para>
                Remove
                <span style={{ fontWeight: 'bold' }}> {userName} </span>
                from <span style={{ fontWeight: 'bold' }}> {squadName} </span> squad?
              </Para>
            )
          }
          buttonText="Yes, Remove"
          cancelText="No"
          primaryButtonColor="#FF4D4F"
          hide={(confirm: boolean) => {
            if (confirm) {
              onConfirm(true);
            } else {
              setOpenModal(false);
            }
          }}
        />
      )}
    </DialogModalFrame>
  );
}

export function ConfirmConvertToMembersModal({
  openModal,
  setOpenModal,
  memberName,
  onConfirm,
}: {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  memberName: string;
  onConfirm: (value: boolean) => void;
}) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);
  return (
    <DialogModalFrame
      root="rsg-root"
      id="modalFrame"
      onClose={() => setOpenModal(false)}
      padding="24px"
      width="500px"
    >
      {openModal && (
        <ConfirmModal
          displayText={
            <>
              <Para
                style={{
                  color: '#09305A',
                  fontSize: '16px',
                  lineHeight: '150%',
                }}
              >
                Are you sure you want to convert
                <span style={{ fontWeight: 'bold' }}> {memberName} </span>
                to a Squad member?
              </Para>
              <Para
                style={{
                  color: '#09305A',
                  fontSize: '16px',
                  lineHeight: '150%',
                }}
              >
                Doing so will revoke their ability to manage the squad.
              </Para>
            </>
          }
          buttonText="Yes, Convert"
          cancelText="No"
          primaryButtonColor="#FF4D4F"
          hide={(confirm: boolean) => {
            if (confirm) {
              onConfirm(true);
            } else {
              setOpenModal(false);
            }
          }}
        />
      )}
    </DialogModalFrame>
  );
}
