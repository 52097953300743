import {
  REQUEST_ORG_INTEGRATIONS,
  REQUEST_ORG_INTEGRATIONS_FAIL,
  REQUEST_ORG_INTEGRATIONS_SUCCESS,
} from '../../const/organization';
import { IExtensions } from '../../interfaces/IOrganization';

const requestOrganizationIntegrations = () => ({
  type: REQUEST_ORG_INTEGRATIONS,
  payload: {},
});

const onRequestOrganizationIntegrationsSuccess = (extensions: IExtensions) => ({
  type: REQUEST_ORG_INTEGRATIONS_SUCCESS,
  payload: {
    extensions,
  },
});

const onRequestOrganizationIntegrationsFail = (error: any) => ({
  type: REQUEST_ORG_INTEGRATIONS_FAIL,
  payload: {
    error,
  },
});

export {
  requestOrganizationIntegrations,
  onRequestOrganizationIntegrationsSuccess,
  onRequestOrganizationIntegrationsFail,
};
