/* eslint-disable prettier/prettier */
import { Box, ChakraProvider, Flex, Icon, VStack, Text } from '@chakra-ui/react';
import {
  ContainerLoad,
  CopyIconButton,
  DropDown,
  FocusBlock,
  Grid,
  Heading,
  IncidentTag,
  Label,
  Para,
  StatusBlock,
  Tabs,
  TextButton,
  Theme,
  Tooltip,
} from 'uie/components';
import { TagValue } from 'components/TagValue';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService } from 'core';
import { TAG_COMP_MAX_WIDTH } from 'core/const/immutables';
import { truncate } from 'core/helpers/stringUtils';
import { IService } from 'core/interfaces/IService';
import { IncidentMergeIcon, SnoozeIcon, TransientAlertIcon } from 'icons';
import { ListingButton, Tooltip as LibraryTooltip } from 'library/atoms';
import { getPrioritySelectBoxValue } from 'library/common';
import { AutoGroupedIcon, DelayedNotificationsIcon } from 'library/icons';
import libraryTheme from 'library/theme';
import { Cell } from 'react-table';
import { PrioritySelectBox } from 'views/shared/components';

import { IncidentDetails } from '.';
import { IRole } from '../../../../core/interfaces/IRole';
import { CrownIcon, CrownSmallIcon } from '../../../../icons';
import AffectsSlo from '../../organization/affectsSloModal/affectPopUp';
import { INCIDENT_DATA } from '../incident-list/common/types';
import CommunicationChannels from './renders/actions/communicationCard';
import IncidentDetailsActivityBox from './renders/activity';
import IncidentDetailsBasicBox from './renders/basicDetails';
import { DetailsTab } from './renders/detailsTab/detailsTab';
import MergedStatusBlock from './renders/mergedStatusBlock';
import IncidentDetailsResponderBox from './renders/responders/responder';
import IncidentDetailsResponseBox from './renders/response';
import IncidentDetailsResponsivenessBox from './renders/responsiveness';
import SloDetails from './renders/sloDetails';
import RunbookTab from './renders/tasksAndRunbook/runbook';
import TaskTab from './renders/tasksAndRunbook/task';
import IncidentDetailsUpdateTags from './renders/updateTags';
import IncidentDetailsViewPayload from './renders/viewPayload';
import IncidentWatcher from './renders/watcher';
import Responses from './Responses';
import { IncidentWatcherProvider } from './Responses/IncidentWatcherContext';
import UnmergeChildIncident from './unMergeIncident';
import IdeaIcon from 'library/icons/idea.png';
import userflow from 'userflow.js';
import OverlayModal from '../../../../components/chakra/Modal';
import { InfoIcon } from '@chakra-ui/icons';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import IncidentSummary from './renders/summary';

const { theme } = Theme;

export const crownIconForTab = (
  <CrownIcon
    style={{
      height: 32,
      width: 32,
      background: 'inherit',
    }}
  />
);

export const crownIconForDetailsTab = (
  <CrownIcon
    style={{
      height: 24,
      width: 24,
      background: 'inherit',
      marginLeft: '12px',
    }}
  />
);

export function render(this: IncidentDetails) {
  const {
    incidentId,
    incident,
    children,
    dedupedEvents,
    componentErrorState,
    componentNetworkState,
    publicMessages,
    statusPage,
    selectedTab,
    selectedDetailsTab,
    sloData,
    notes,
    loadNotesComponent,
    latestAssigneeDeleted,
    isUnmergeIncidentsUnavailable,
    isPastIncidentsUnavailable,
    pastIncidents,
    pastIncidentsStats,
    pastIncidentsHeatmap,
    areDedupEventsLoading,
    arePastIncidentsLoading,
    pastIncidentsListParameters,
    incidentServiceNowMapping,
  } = this.state;
  const notesLength = notes.length;
  const priorityValue = getPrioritySelectBoxValue(incident?.priority);
  const {
    incidentRunbooks,
    incidentTask,
    roles,
    selectedOrg,
    integrations: { i: alertSources },
  } = this.props;
  const totalRunbooks = incidentRunbooks.runbook?.length ?? 0;

  const taskList = incidentTask.tasks?.tasks || [];
  const checkedTaskLength = taskList.filter(t => t?.completed).length;
  const userRole = this.props.roles.r.find(
    role => role._id === this.props.selectedOrg.roleId,
  ) as IRole;
  const isStakeholder = userRole.name === 'Stakeholder';
  const isSuppressedIncident = incident ? incident.status === 'suppressed' : false;
  const isChildIncident = !!(incident && incident.is_child);
  const isParentIncident = !!(incident && incident.children.length > 0);
  const isTransientAlert = !!(
    incident &&
    incident.did_auto_pause &&
    incident.status === 'suppressed'
  );
  const disableUnmergeInParent = !!(
    incident && ['resolved', 'suppressed'].includes(incident.status)
  );
  const disableUnmergeInChild = !!(
    incident &&
    incident.parent &&
    ['resolved', 'suppressed'].includes(incident.parent.status)
  );
  const parentIncidentStatus = incident && incident.parent ? incident.parent.status : '';

  const parentIncidentTooltipLabel = `${children.length} incident${
    children.length > 1 ? 's' : ''
  } merged`;

  const upgradeModalFeature = isUnmergeIncidentsUnavailable
    ? 'merge-incidents'
    : isPastIncidentsUnavailable
    ? 'past-incidents'
    : 'runbooks';

  const incidentService = this._oService[incident?.service_id ?? ''] as IService;

  const snoozedIncidentTooltipLable = 'Incident is snoozed';

  const IncidentChildrenAction = ({ rowData }: { rowData: Partial<Cell<INCIDENT_DATA>> }) => {
    const incident = rowData.row?.original;
    const canUpdate = useUserAccess().hasUpdateAccess('incidents');

    return (
      <Flex gap={5} alignSelf="center" width="max-content" position="relative">
        <NoPermissionTooltip isDisabled={canUpdate}>
          <ListingButton
            title="Unmerge"
            onClick={() => {
              if (isUnmergeIncidentsUnavailable) {
                this.setState({ showUpgradeModal: true });
              } else {
                this.onClickUnmerge(incident?.id ?? '');
              }
            }}
            disabled={!canUpdate || disableUnmergeInParent}
          />
          {isUnmergeIncidentsUnavailable && (
            <CrownSmallIcon
              className="pro"
              style={{
                position: 'absolute',
                left: '-3px',
                top: '-6px',
                height: 14,
                width: 14,
              }}
            />
          )}
        </NoPermissionTooltip>
      </Flex>
    );
  };

  const tags: any[] =
    incident !== null && incident.tags
      ? Object.entries(incident.tags).map(([k, v]) => {
          return {
            key: k,
            value: v,
          };
        })
      : [];

  return (
    <ChakraProvider theme={libraryTheme}>
      <Grid type="column" height="100%">
        <ContainerLoad isLoading={componentNetworkState !== 'idle'} />
        <Grid className="incident_details__page">
          <Grid
            type="column"
            flexWidth={9}
            className="pb-20"
            style={{ paddingTop: 16, overflow: 'auto' }}
          >
            <Grid justifyContent="space-between">
              <Grid alignItems="center">
                {incident?.snooze_details?.is_snoozed && (
                  <Tooltip label={snoozedIncidentTooltipLable} offset={{ top: '5px' }}>
                    <Icon as={SnoozeIcon} />
                  </Tooltip>
                )}
                <Para fontSize={16} fontWeight={500} color={theme.shades.cement}>
                  Incident / #{incident?.id}
                </Para>
                {incident?.id && (
                  <div className="ml-10 incident_details__copy-incident">
                    <CopyIconButton label="Copy incident" type="transparent">
                      {document.location.href}
                    </CopyIconButton>
                  </div>
                )}

                <IncidentWatcher incidentId={incidentId} />
              </Grid>
              {incident !== null && (
                <Grid alignItems="flex-start">
                  <div className="incident_details_priority">
                    <PrioritySelectBox
                      priority={priorityValue}
                      onChange={this.onPriorityUpdate}
                      applyMinHeight
                    />
                  </div>

                  {isChildIncident ? (
                    <MergedStatusBlock />
                  ) : (
                    <StatusBlock status={incident.status} />
                  )}
                </Grid>
              )}
            </Grid>
            {incident === null ? (
              <>
                {componentErrorState.get_incident && (
                  <Grid flexWidth={12} type="column" style={{ marginTop: 16 }}>
                    <Para fontSize={16}>
                      Failed to load incident or incident with id {incidentId} does not exist.
                    </Para>
                    <TextButton
                      className="mt-10"
                      buttonType="normal"
                      color={theme.primary.default}
                      onClick={this.getIncidentDetails}
                    >
                      <Para color={theme.shades.white} fontWeight={500}>
                        {' '}
                        Retry ?
                      </Para>
                    </TextButton>
                  </Grid>
                )}
                {componentErrorState.outside_retention_period && (
                  <Grid flexWidth={12} type="column" style={{ marginTop: 16 }}>
                    <Para fontSize={16}>
                      This incident is outside your plan's retention period. Please upgrade your
                      plan to view this incident. This incident's data is safe with us.
                    </Para>
                    <a href="/billing" style={{ textDecoration: 'none' }}>
                      <TextButton
                        className="mt-10"
                        buttonType="normal"
                        color={theme.primary.default}
                      >
                        <Para color={theme.shades.white} fontWeight={500}>
                          Upgrade Now?
                        </Para>
                      </TextButton>
                    </a>
                  </Grid>
                )}
              </>
            ) : (
              <Grid type="column" flexWidth={12}>
                <Grid className="mt-20 mb-20" alignItems="flex-start">
                  <Grid flexWidth={9}>
                    <Heading fontSize={16} height={24} style={{ maxWidth: '95%' }}>
                      {!!incident.grouped_alert_count && (
                        <LibraryTooltip label="Auto Grouped" placement="bottom">
                          <span>
                            <Icon
                              as={AutoGroupedIcon}
                              style={{ height: '13px', marginRight: '4px' }}
                            />
                          </span>
                        </LibraryTooltip>
                      )}
                      {isParentIncident && (
                        <Tooltip label={parentIncidentTooltipLabel}>
                          <span>
                            <Icon
                              as={IncidentMergeIcon}
                              style={{ height: '13px', marginRight: '4px' }}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {isTransientAlert && (
                        <LibraryTooltip label="Transient Alert" placement="bottom">
                          <span>
                            <Icon
                              as={TransientAlertIcon}
                              style={{ height: '13px', marginRight: '4px' }}
                            />
                          </span>
                        </LibraryTooltip>
                      )}
                      {!!incident.did_notification_delay && (
                        <LibraryTooltip label="Delayed Notifications" placement="bottom">
                          <span>
                            <Icon
                              as={DelayedNotificationsIcon}
                              style={{ height: '13px', marginRight: '4px' }}
                            />
                          </span>
                        </LibraryTooltip>
                      )}
                      {incident.message}
                    </Heading>
                  </Grid>
                  {incident.status !== 'suppressed' && (
                    <Grid flexWidth={3} justifyContent="flex-end" style={{ minWidth: '192px' }}>
                      <IncidentSummary incidentId={incidentId} incident={incident} />
                    </Grid>
                  )}
                </Grid>

                <Grid flexWidth={12} alignItems="baseline" className="mt-10">
                  {tags.length > 0 && (
                    <>
                      {tags.slice(0, 2).map((tag, i) => (
                        <Tooltip
                          offset={{ top: '10px' }}
                          key={i}
                          label={`${tag?.key} : ${tag?.value?.value}`}
                          width={
                            `${tag?.key} : ${tag?.value?.value}`.length > 50
                              ? '300px'
                              : 'fit-content'
                          }
                        >
                          <Box mr={4} height="auto">
                            <IncidentTag
                              color={tag?.value?.color}
                              fontSize={12}
                              fontWeight={400}
                              tag={truncate(tag?.key, 25)}
                              padding="2px 8px"
                              isDeletable
                              onDelete={this.deleteTag(tag?.key)}
                              value={<TagValue value={truncate(tag?.value?.value, 20)} />}
                              style={{
                                maxWidth: TAG_COMP_MAX_WIDTH,
                              }}
                            />
                          </Box>
                        </Tooltip>
                      ))}
                    </>
                  )}
                  {tags.slice(2).length > 0 && (
                    <Grid className="mr-10">
                      <DropDown
                        style={{ maxHeight: 'fit-content' }}
                        hook={
                          <IncidentTag
                            color={theme.shades.cement}
                            fontSize={12}
                            fontWeight={400}
                            padding="2px 8px"
                            tag={`+${tags.slice(2).length}`}
                            value="more"
                          />
                        }
                      >
                        <Grid
                          flexWidth={12}
                          alignItems="baseline"
                          flexWrap="wrap"
                          style={{ padding: '0 8px 8px 8px', height: 'fit-content' }}
                        >
                          {tags.slice(2).map((tag, tIndex) => (
                            <Tooltip
                              offset={{ top: '10px' }}
                              key={tIndex}
                              label={`${tag?.key} : ${tag?.value?.value}`}
                              width={
                                `${tag?.key} : ${tag?.value?.value}`.length > 50
                                  ? '300px'
                                  : 'fit-content'
                              }
                            >
                              <Box pt={2}>
                                <IncidentTag
                                  className="mt-5 ml-5"
                                  color={tag?.value?.color}
                                  fontSize={12}
                                  fontWeight={400}
                                  tag={truncate(tag?.key, 25)}
                                  padding="2px 8px"
                                  isDeletable
                                  onDelete={this.deleteTag(tag?.key)}
                                  value={<TagValue value={truncate(tag?.value?.value, 25)} />}
                                  style={{
                                    maxWidth: TAG_COMP_MAX_WIDTH,
                                  }}
                                />
                              </Box>
                            </Tooltip>
                          ))}
                        </Grid>
                      </DropDown>
                    </Grid>
                  )}
                  {!isStakeholder && (
                    <IncidentDetailsUpdateTags
                      tags={incident.tags ? incident.tags : {}}
                      incidentId={incident.id}
                    />
                  )}
                </Grid>

                <Grid className="mt-20 mb-20" alignItems="center">
                  {!isStakeholder && (
                    <IncidentDetailsResponseBox
                      incidentService={incidentService}
                      incident={incident}
                      acknowledgeIncident={this.acknowledgeIncident}
                      resolveIncident={this.resolveIncident}
                      reassignIncident={this.reassignIncident}
                      getIncidentDetails={this.getIncidentDetails}
                      getIncidentMapping={this.getIncidentMapping}
                      markAsTransient={this.markAsTransient}
                      markAsNotTransient={this.markAsNotTransient}
                      canUpdateStatusPage={!isStakeholder && !isSuppressedIncident}
                      onClickUnmerge={() => {
                        if (isUnmergeIncidentsUnavailable) {
                          this.setState({ showUpgradeModal: true });
                        } else {
                          this.onClickUnmerge(incident.id);
                        }
                      }}
                      disableUnmerge={disableUnmergeInChild}
                      isUnmergeIncidentsUnavailable={isUnmergeIncidentsUnavailable}
                      parentIncidentStatus={parentIncidentStatus}
                      isRunbooksDisabled={this.state.runbookFeatDisabled}
                      onSLOAffect={this.openAffectsSloModal}
                      showAffectsSLO={
                        !!(
                          selectedOrg?.config?.['slo-tracker.enabled'] &&
                          !incident?.slo_id &&
                          !isChildIncident
                        )
                      }
                    />
                  )}
                  <IncidentDetailsViewPayload incidentId={incidentId} />
                  <UnmergeChildIncident
                    onClose={() => {
                      this.setState({ openUnmergeModal: false });
                    }}
                    isOpen={this.state.openUnmergeModal}
                    unMerge={this.unMergeIncident}
                    isUnmerging={this.state.isUnmerging}
                    latestAssigneeDeleted={latestAssigneeDeleted}
                    location="child"
                  />
                </Grid>

                <div style={{ border: '2px solid var(--shades-smoke)' }}>
                  <Grid flexWidth={12}>
                    <Tabs
                      style={{ backgroundColor: '#F1F2F5', padding: '5px 10px' }}
                      initialTab={selectedTab}
                      onTabSelect={async tab => {
                        await this.setState({ selectedTab: tab });
                        if (
                          ((this.state.selectedTab == 'Task' ||
                            this.state.selectedTab == 'Runbook') &&
                            this.state.runbookFeatDisabled) ||
                          (this.state.selectedTab === 'Child' && isUnmergeIncidentsUnavailable)
                        ) {
                          await this.setState({ showUpgradeModal: true });
                        } else {
                          await this.setState({ showUpgradeModal: false });
                        }
                      }}
                      tabWidth="100%"
                      shellWidth="100%"
                    >
                      <FocusBlock style={{ padding: '20px 10px' }} value="Details">
                        <>Details</>
                      </FocusBlock>

                      <FocusBlock style={{ padding: '20px 10px' }} value="Notes">
                        {`Notes (${notesLength})`}
                      </FocusBlock>

                      <FocusBlock style={{ padding: '20px 25px' }} value="Runbook">
                        <>
                          {`Runbooks (${totalRunbooks})`}{' '}
                          {this.state.runbookFeatDisabled ||
                          BillingService.isSomeProductTrialPlan(this.props)
                            ? crownIconForTab
                            : ''}
                        </>
                      </FocusBlock>
                      <FocusBlock style={{ padding: '20px 25px' }} value="Task">
                        <>
                          {`Tasks (${checkedTaskLength || 0}/${taskList.length})`}{' '}
                          {this.state.runbookFeatDisabled ||
                          BillingService.isSomeProductTrialPlan(this.props)
                            ? crownIconForTab
                            : ''}
                        </>
                      </FocusBlock>
                    </Tabs>
                  </Grid>
                  <Grid
                    flexWidth={12}
                    style={
                      selectedTab !== 'Details' ||
                      (selectedTab == 'Details' && selectedDetailsTab !== 'Child')
                        ? { minHeight: 600 }
                        : {}
                    }
                  >
                    {selectedTab == 'Details' && (
                      <DetailsTab
                        {...{
                          incident,
                          IncidentChildrenAction,
                          isPastIncidentsUnavailable,
                          onTabSelect: tab => {
                            this.setState({ selectedDetailsTab: tab });
                            if (tab === 'Past Incidents' && isPastIncidentsUnavailable) {
                              this.setState({ showUpgradeModal: true });
                            } else {
                              this.setState({ showUpgradeModal: false });
                            }
                          },
                          selectedDetailsTab,
                          isParentIncident,
                          isUnmergeIncidentsUnavailable,
                          dedupedEvents,
                          getEvents: this.getDedupedEvents,
                          areDedupEventsLoading,
                          arePastIncidentsLoading,
                          alertSources,
                          children,
                          pastIncidents,
                          pastIncidentsStats,
                          pastIncidentsHeatmap,
                          pastIncidentsListParameters,
                          setPastIncidentsFilter: this.setPastIncidentsFilter,
                          clearPastIncidentsFilter: this.clearPastIncidentsFilter,
                          isPastIncidentsDataLoaded: this.state.isPastIncidentsDataLoaded,
                          unmergeModalProps: {
                            onClose: () => {
                              this.setState({ openUnmergeModal: false });
                            },
                            isOpen: this.state.openUnmergeModal,
                            unMerge: this.unMergeIncident,
                            isUnmerging: this.state.isUnmerging,
                            latestAssigneeDeleted: latestAssigneeDeleted,
                          },
                        }}
                      />
                    )}
                    {loadNotesComponent && (
                      <Grid
                        flexWidth={12}
                        style={{ display: selectedTab === 'Notes' ? 'inherit' : 'none' }}
                      >
                        <IncidentWatcherProvider incidentID={this.state.incidentId}>
                          <Responses
                            incidentId={incident!.id}
                            notes={this.state.notes}
                            setNotes={this.setNotes}
                            focusMessageId={this.state.focusMessageId.id}
                          />
                        </IncidentWatcherProvider>
                      </Grid>
                    )}
                    {selectedTab == 'Runbook' && (
                      <Grid flexWidth={12}>
                        <RunbookTab
                          incidentId={incident!.id}
                          focusMessageId={this.state.focusMessageId}
                          currentTab={selectedTab}
                          getIncidentDetails={this.getIncidentDetails}
                          isSuppressedIncident={isSuppressedIncident}
                        />
                      </Grid>
                    )}
                    {selectedTab == 'Task' && (
                      <Grid flexWidth={12}>
                        <TaskTab
                          incidentId={incident!.id}
                          focusMessageId={this.state.focusMessageId}
                          currentTab={selectedTab}
                          getIncidentDetails={this.getIncidentDetails}
                          isSuppressedIncident={isSuppressedIncident}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <UpgradePlanModal
                    hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
                    showModal={this.state.showUpgradeModal}
                    message={BillingService.getMessage(0, upgradeModalFeature, this.props)}
                    onCancel={() => {
                      this.setState({ showUpgradeModal: false });
                      this.setState({ selectedTab: 'Details', selectedDetailsTab: 'Description' });
                    }}
                    header={BillingService.getHeader(0, upgradeModalFeature, this.props)}
                    onUpgrade={() => this.setState({ showUpgradeModal: false })}
                  />
                </div>
              </Grid>
            )}
            <div style={{ marginBottom: 50 }} />
          </Grid>
          {incident !== null && (
            <Grid type="column" className="incident_details__details" flexWidth={3}>
              <Grid justifyContent="space-between" alignItems="center">
                <Label fontSize={16} fontWeight={500}>
                  Details
                </Label>
                <img
                  src={IdeaIcon}
                  style={{ height: 20, width: 20 }}
                  onClick={() => {
                    userflow.start('585a0ccf-11e3-4c82-858f-5b9f74ef85c3');
                  }}
                />
              </Grid>

              <Grid className="mt-20">
                <IncidentDetailsBasicBox
                  incident={incident}
                  incidentMapping={incidentServiceNowMapping}
                />
              </Grid>

              <Grid className="mt-20">
                <IncidentDetailsResponsivenessBox incident={incident} />
              </Grid>

              <Grid className="mt-20">
                <CommunicationChannels
                  incidentId={incident.id}
                  organization={selectedOrg}
                  isSuppressedIncident={isSuppressedIncident}
                />
              </Grid>

              <Grid className="mt-20">
                <IncidentDetailsResponderBox incident={incident} />
              </Grid>

              <Grid className="mt-20">
                <IncidentDetailsActivityBox
                  incident={incident}
                  sloData={sloData}
                  sloDetails={this.sloDetails}
                  setFocusMessageId={this.setFocusMessageId}
                  publicMessages={publicMessages}
                  statusPages={statusPage}
                />
              </Grid>
              <Grid className="mt-20">
                <SloDetails
                  incident={incident}
                  teamId={this.props?.organization?.selectedTeam?.teamId}
                  sloData={sloData}
                  getIncidentDetails={this.getIncidentDetails}
                  alertSource={
                    this.props?.integrations.i.filter(alert => {
                      if (alert._id === incident.alert_source_id) {
                        return alert;
                      }
                    })[0]?.shortName
                  }
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        {selectedOrg?.config?.['slo-tracker.enabled'] && (
          <AffectsSlo
            opendSloModal={this.state.opendSloModal}
            getIncidentDetails={this.getIncidentDetails}
            incident={incident}
            sloData={sloData}
            teamId={this.props?.organization?.selectedTeam?.teamId}
            closeSloModel={this.closeModel}
          />
        )}
      </Grid>
    </ChakraProvider>
  );
}
