import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SpinLoader } from '@squadcast/alchemy-ui/carbon';
import styles from './upcomingOnCallShift.module.css';
import { useQuery } from 'react-query';
import { API, CalendarService } from 'core';
import { ICalendarEventResponse } from 'core/interfaces/ICalendar';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { TextButton, Theme, Tooltip } from 'uie/components';
import { DateRange } from 'react-date-range';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory, useLocation } from 'react-router-dom';
import { IUserProfile } from 'core/interfaces/IUserData';
import { format } from 'date-fns/esm';
import DatePicker from 'react-datepicker';
import { Locale } from 'core/helpers/dateUtils';
import { useOrganizationConfig } from 'views/main/organization/schedules';
import { useGetUserShiftsForProfile } from '../schedules/common/GetUserShiftsForProfile';
import { encodeFilterParam } from '../schedules/helpers/helpers.filterschedules';
import { FILTER_TYPES } from '../schedules/constants/schedulers.filter';
import { SCHEDULES_V2_PATH } from 'views/main/routes/routes';

interface Props {
  user: IUserProfile;
}

const NUMBER_OF_USER_TO_BE_SHOWN = 2;

const useCustomQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

function UpcomingOnCallShift({ user }: Props) {
  const timezone = user?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  function getTimezoneDate(date?: Date) {
    if (date) {
      return moment(date).tz(timezone);
    }
    return moment().tz(timezone);
  }

  const { theme } = Theme;
  const history = useHistory();
  const query = useCustomQuery();
  const {
    organization: {
      currentOrg: { o: organizationInfo },
    },
  } = useOrganizationConfig();

  const orgConfig = organizationInfo?.config;
  const showLegacyShifts = orgConfig?.show_migrated_schedules ?? true;

  const viewNewSchedules = useMemo(() => {
    if (organizationInfo) {
      return !!organizationInfo.config.disable_old_schedules;
    }
    return false;
  }, [organizationInfo]);

  const [date, setDate] = useState<{ fromDate: string; toDate: string }>({
    fromDate: getTimezoneDate()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toISOString(),
    toDate: getTimezoneDate()
      .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
      .toISOString(),
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState<null | Date>(null);

  const onChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    if (startDate && endDate) {
      setDate({
        fromDate: getTimezoneDate(startDate)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toISOString(),
        toDate: getTimezoneDate(endDate)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toISOString(),
      });
    }
  }, [startDate, endDate]);

  const { showSpinner, newSchedulesEvents } = useGetUserShiftsForProfile(user, date);

  type DivProps = React.HTMLProps<HTMLDivElement>;

  const CustomText = React.forwardRef<HTMLParagraphElement, DivProps>((props: DivProps, ref) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <p
        ref={ref}
        onClick={props.onClick}
        style={{ cursor: 'pointer', marginRight: '5px' }}
        className={styles['time-range']}
        tabIndex={0}
      >
        Custom
      </p>
      {startDate && endDate && (
        <p style={{ fontSize: '14px' }}>
          ({Locale.toFullDate(startDate)} - {Locale.toFullDate(endDate)})
        </p>
      )}
    </div>
  ));

  function setDateHandler(type: string) {
    if (type === 'day') {
      setDate({
        fromDate: getTimezoneDate()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toISOString(),
        toDate: getTimezoneDate()
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
          .toISOString(),
      });
    } else if (type === 'week') {
      setDate({
        fromDate: getTimezoneDate().startOf('week').toISOString(),
        toDate: getTimezoneDate().endOf('week').toISOString(),
      });
    }
  }

  const _calenderService = new CalendarService();

  const calendarEventsResponse = useQuery({
    queryKey: [`calendar-events-${date.toDate}-${date.fromDate}`, user.id],
    queryFn: () =>
      _calenderService.getCalendarEventsBetweenStartTimes(
        Locale.toISO(date.fromDate),
        Locale.toISO(date.toDate),
        user.id,
      ),
    enabled: showLegacyShifts,
  });

  const legacyCalendarData = showLegacyShifts ? calendarEventsResponse.data?.data.data || [] : [];

  const legacySchedulesEvents = legacyCalendarData.reduce((legacyCalendarData, calendarEvent) => {
    const date = new Date(calendarEvent.start_time).toLocaleDateString('en-US');
    if (!legacyCalendarData[date]) {
      legacyCalendarData[date] = [];
    }
    legacyCalendarData[date].push(calendarEvent);
    return legacyCalendarData;
  }, {} as Record<string, ICalendarEventResponse[]>);

  /**
   * There is some difference in how data for both types of schedules is fetched & managed here. We
   have decided to reduce complications by showing shifts for the new schedules only if the customer is
   using the new schedules (checked via the feature flag). Hence, fetching the calendar keys conditionally
   */
  const calendarDateKeys = [
    ...(!organizationInfo?.config.disable_old_schedules ? Object.keys(legacySchedulesEvents) : []),
    ...(organizationInfo?.config.disable_old_schedules ? Object.keys(newSchedulesEvents) : []),
  ];

  const calendarEventsArray = Array.from(new Set(calendarDateKeys))
    .map(date => {
      return {
        date,
        events: [
          ...(legacySchedulesEvents[date] ? legacySchedulesEvents[date] : []),
          ...(newSchedulesEvents[date] ? newSchedulesEvents[date] : []),
        ],
      };
    })
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  for (const calendarEvent of calendarEventsArray) {
    calendarEvent.events.sort(function (a, b) {
      const startTimeA = getTimezoneDate(a.start_time);
      const endTimeA = getTimezoneDate(a.end_time);
      const diffA = endTimeA.diff(startTimeA, 'days') >= 1;

      const startTimeB = getTimezoneDate(b.start_time);
      const endTimeB = getTimezoneDate(b.end_time);
      const diffB = endTimeB.diff(startTimeB, 'days') >= 1;

      if (diffA && !diffB) {
        return -1;
      }

      if (diffB && !diffA) {
        return 1;
      }

      if (moment(a.start_time).isBefore(moment(b.start_time))) {
        return -1;
      }

      if (moment(b.start_time).isBefore(moment(a.start_time))) {
        return 1;
      }

      return 0;
    });
  }

  const viewSchedulesHandler = useCallback(() => {
    const filterUrlParams = encodeFilterParam({
      [FILTER_TYPES.PARTICIPANT]: [
        {
          value: `${user.id}`,
          label: `${user.first_name} ${user.last_name}`,
          id: `${user.id}`,
        },
      ],
      [FILTER_TYPES.ESCALATION_POLICY]: [],
      [FILTER_TYPES.SCHEDULE]: [],
      [FILTER_TYPES.MY_ON_CALL]: false,
      [FILTER_TYPES.NO_ESCALATION_POLICY]: false,
    });
    query.delete('filters');
    query.append('filters', filterUrlParams);
    history.push(viewNewSchedules ? `${SCHEDULES_V2_PATH}?${query}` : '/schedules');
  }, [user, query, history]);

  return (
    <>
      <div
        style={{
          border: '1px solid #e7ecf5',
          padding: 10,
          height: '320px',
          marginTop: '25px',
          marginBottom: '25px',
        }}
      >
        <h4 style={{ margin: '0' }}>{`${user?.first_name}'s On-Call Shifts`}</h4>
        <div
          style={{
            fontSize: '14px',
            margin: '10px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              className={styles['time-range']}
              style={{ cursor: 'pointer' }}
              onClick={() => setDateHandler('day')}
              tabIndex={0}
            >
              Today
            </p>
            <span style={{ margin: '0 7px' }}>|</span>
            <p
              style={{ cursor: 'pointer', minWidth: '65px' }}
              onClick={() => setDateHandler('week')}
              className={styles['time-range']}
              tabIndex={0}
            >
              This Week
            </p>
            <span style={{ margin: '0 7px' }}>|</span>
            <DatePicker
              onChange={onChange}
              selectsRange
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              showMonthDropdown
              showYearDropdown
              customInput={<CustomText />}
              dropdownMode="select"
            />
          </div>
          <div>
            <TextButton
              buttonType="inverted"
              style={{ color: theme.primary.default }}
              onClick={viewSchedulesHandler}
            >
              View Schedules
            </TextButton>
          </div>
        </div>
        <table className={styles['oncall-table']}>
          <thead>
            <tr>
              <th>TIME</th>
              <th>SCHEDULE</th>
              <th>SHIFT</th>
            </tr>
          </thead>

          {showSpinner && <SpinLoader size="normal" />}
          {!showSpinner && calendarEventsArray.length > 0 && (
            <tbody
              style={{
                display: 'block',
                maxHeight: '200px',
                overflow: 'auto',
                overflowX: 'hidden',
              }}
            >
              {calendarEventsArray.map(event => (
                <OnCallTableData data={event} key={event.date} getTimezoneDate={getTimezoneDate} />
              ))}
            </tbody>
          )}

          {!showSpinner && calendarEventsArray.length === 0 && (
            <tr style={{ width: '100%', textAlign: 'center', fontWeight: 600 }}>
              <td colSpan={3} style={{ textAlign: 'center' }}>
                No On-Call Shifts Present
              </td>
            </tr>
          )}
        </table>
      </div>
    </>
  );
}

interface TableDataProps {
  data: {
    date: string;
    events: ICalendarEventResponse[];
  };
  getTimezoneDate: (date: Date | undefined) => moment.Moment;
}

function OnCallTableData({ data, getTimezoneDate }: TableDataProps) {
  return (
    <>
      <tr>
        <td colSpan={3} style={{ fontWeight: 600, fontSize: '13px', paddingTop: '20px' }}>
          {Locale.toFullDate(data.date)}
        </td>
      </tr>
      {data.events.map(event => (
        <OnCallTableRowForDate event={event} getTimezoneDate={getTimezoneDate} key={event.id} />
      ))}
    </>
  );
}

interface OnCallTableRowForDateProps {
  event: ICalendarEventResponse;
  getTimezoneDate: (date: Date | undefined) => moment.Moment;
}

function OnCallTableRowForDate({ event, getTimezoneDate }: OnCallTableRowForDateProps) {
  const startTime = getTimezoneDate(event.start_time);
  const endTime = getTimezoneDate(event.end_time);

  const formattedTimeString = `${startTime.format('hh:mm a')} - ${endTime.format('hh:mm a')}`;

  const formattedTime =
    endTime.diff(startTime, 'days') >= 1 || formattedTimeString === '12:00 am - 11:59 pm'
      ? 'All day'
      : `${startTime.format('hh:mm a')} - ${endTime.format('hh:mm a')}`;

  return (
    <tr>
      <td>{formattedTime}</td>
      <td>
        <Tooltip label={event.calendar_name} offset={{ top: '-64px', left: '-10px' }}>
          <span
            style={{
              height: 'auto',
              width: '100px',
              display: 'inline-block',
              marginRight: '5px',
              verticalAlign: 'middle',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {event.calendar_name}
          </span>
        </Tooltip>
      </td>
      <td>
        <Tooltip label={event.name} offset={{ top: '-64px', left: '-10px' }}>
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <span
              style={{
                height: '12px',
                width: '12px',
                backgroundColor: event.calendar_color,
                borderRadius: '50%',
                marginTop: '5px',
                marginRight: '5px',
                verticalAlign: 'middle',
                textOverflow: 'ellipsis',
              }}
            />
            <span
              style={{
                height: 'auto',
                width: '100px',
                marginRight: '5px',
                verticalAlign: 'middle',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {event.name}
            </span>
          </span>
        </Tooltip>
      </td>
    </tr>
  );
}

export default UpcomingOnCallShift;
