import axios, { AxiosError, AxiosInstance } from 'axios';
import { API } from 'core';
import qs from 'query-string';
import { Failure } from '../behaviors/Failure';

const isAxiosError = (err: any): err is AxiosError => {
  return err.isAxiosError === true;
};

let v3: AxiosInstance;

export function initV3Client(bearerToken: string) {
  v3 = axios.create();

  v3.defaults.paramsSerializer = params => {
    return qs.stringify(params);
  };

  v3.defaults.headers.common.Authorization = bearerToken;

  v3.interceptors.request.use(r => {
    if (!r.baseURL) {
      r.baseURL = `${API.config.batman}/organizations/${API.config.organizationId}`;
    }

    return r;
  });

  v3.interceptors.response.use(
    r => {
      if (!['delete', 'DELETE'].includes(r.request?.method) && r.status === 204) {
        throw Failure.globalNotFound(r.status);
      }

      return r.data;
    },
    e => {
      if (!isAxiosError(e)) {
        throw Failure.unknown(0);
      }

      const r = e.response;

      if (!r) {
        throw Failure.unknown(0);
      }

      const message = r.data?.meta?.error_message ?? '';

      if (r.status === 404) {
        throw Failure.notFound(r.status, message);
      }

      if (r.status >= 500) {
        throw Failure.unknown(r.status, message);
      }

      throw Failure.unknown(r.status, message);
    },
  );
}

export { v3 };
