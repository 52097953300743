import React, { useMemo, useRef } from 'react';
import { Box, ScaleFade, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { useScheduleActionsContext } from './context';
import { useDeleteScheduleMutation } from 'views/main/organization/schedules/graphql/mutation';
import {
  useGetListOfSchedulesQuery,
  useGetScheduleForDeleteActionQuery,
} from 'views/main/organization/schedules/graphql/query';
import Loader from 'components/chakra/Loader';
import { useQueryClient } from 'react-query';
import { useFormik, FormikProvider } from 'formik';
import { defaultReactQueryConfig, reactQueryConfig } from '../helpers/helpers.schedule';
import { useHistory } from 'react-router-dom';
import { SCHEDULES_V2_PATH } from 'views/main/routes/routes';
import { schedulesTextCopy } from '../constants/schedules.copy';
import { useGetAllPatternParticipants } from '../helpers/helpers.customrotations';
import Select from 'components/chakra/Select';
import ParticipantOptionLabel from '../schedules.add/schedules.customizerotations/participantOptionLabel';
import { ParticipantValue } from '../schedules.add/schedules.customizerotations/participantMultiValue';
import { customStyles } from 'components/chakra/Select/customStyles';
import { API } from 'core';
import { MutationDeleteScheduleArgs } from '../graphql/types';

const initialFormikValues = {
  ID: 0,
};

const DeleteScheduleSelection = () => {
  const { scheduleAction, resetScheduleAction } = useScheduleActionsContext();
  const history = useHistory();
  const allParticipants = useGetAllPatternParticipants();

  const scheduleID = scheduleAction?.params.scheduleId as number;
  const {
    data: { schedule } = {},
    isLoading: isLoadingSchedule,
    isSuccess,
  } = useGetScheduleForDeleteActionQuery({
    ID: scheduleID,
  });

  const { data: allSchedules, isLoading: isLoadingSchedulesList } = useGetListOfSchedulesQuery(
    {
      filters: { teamID: API.config.teamId },
    },
    defaultReactQueryConfig,
  );

  const scheduleInfo = useMemo(() => schedule, [schedule]);
  const schedulesGroup = useMemo(
    () => ({
      label: 'Schedules',
      options:
        allSchedules?.schedules
          ?.filter(schedule => schedule.ID !== scheduleID)
          .map(schedule => ({
            ID: schedule.ID,
            type: 'schedule',
            label: schedule.name,
            value: schedule.ID,
          })) || [],
    }),
    [allSchedules, scheduleID],
  );
  const squadsGroup = useMemo(
    () => ({
      label: 'Squads',
      options: allParticipants.filter(p => p.type === 'squad'),
    }),
    [allParticipants],
  );
  const usersGroup = useMemo(
    () => ({
      label: 'Users',
      options: allParticipants.filter(p => p.type === 'user'),
    }),
    [allParticipants],
  );

  const deleteScheduleInput = useMemo(
    () =>
      scheduleInfo
        ? {
            ID: scheduleInfo.ID,
            input:
              scheduleInfo.escalationPolicies?.map(ep => ({
                escalationPolicyID: ep.ID,
              })) || [],
          }
        : undefined,
    [scheduleInfo],
  );

  const { mutateAsync: deleteSchedule, isLoading: isScheduleDeleting } = useDeleteScheduleMutation({
    ...reactQueryConfig.mutation.delete,
    onSuccess: () => {
      reactQueryConfig.mutation.delete.onSuccess();

      history.push(SCHEDULES_V2_PATH);

      queryClient.invalidateQueries('getSchedules');
      queryClient.invalidateQueries('getListOfSchedules');
      queryClient.invalidateQueries('getSchedule');
      queryClient.invalidateQueries('whoIsOncall');
    },
  });

  const queryClient = useQueryClient();

  const formik = useFormik<MutationDeleteScheduleArgs>({
    enableReinitialize: true,
    initialValues: deleteScheduleInput ?? initialFormikValues,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      try {
        await deleteSchedule({
          ID: scheduleID,
          input: formik.values.input,
        });
      } catch (error: any) {
        console.log(error);
      }
      resetForm({ values });
      resetScheduleAction();
    },
  });

  const onReplaceHandler = (
    ID: string,
    selectedValues: Array<{ type: string; ID?: number; id?: string }>,
  ) => {
    if (formik.values.input) {
      const indexOfEscalationPolicy = formik.values.input.findIndex(
        ep => ep.escalationPolicyID === ID,
      );
      if (indexOfEscalationPolicy > -1) {
        const currentMapping = formik.values.input[indexOfEscalationPolicy];
        const scheduleValues = selectedValues
          .filter(value => value.type === 'schedule')
          .map(value => value.ID);
        const squadValues = selectedValues
          .filter(value => value.type === 'squad')
          .map(value => value.id);
        const userValues = selectedValues
          .filter(value => value.type === 'user')
          .map(value => value.id);
        formik.handleChange({
          target: {
            name: `input.${indexOfEscalationPolicy}`,
            value: {
              ...currentMapping,
              scheduleIDs: scheduleValues,
              squadIDs: squadValues,
              userIDs: userValues,
            },
          },
        });
      }
    }
  };

  const isDeleteButtonDisabled = useMemo(() => {
    if (
      scheduleInfo &&
      scheduleInfo.escalationPolicies &&
      scheduleInfo.escalationPolicies.length > 0
    ) {
      if (formik.values.input) {
        return !formik.values.input.every(
          mapping =>
            (mapping.scheduleIDs && mapping.scheduleIDs.length > 0) ||
            (mapping.squadIDs && mapping.squadIDs.length > 0) ||
            (mapping.userIDs && mapping.userIDs.length > 0),
        );
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [formik.values, scheduleInfo]);

  const MultiValue = ParticipantValue({ componentType: 'multi' });

  return (
    <Loader.Spinner
      isLoading={isLoadingSchedule || isLoadingSchedulesList}
      loadingMessage="Loading the delete options ..."
      centered
      spinnerProps={{ size: 'lg' }}
    >
      <ScaleFade initialScale={0.9} in={isSuccess}>
        <FormikProvider value={formik}>
          {scheduleInfo?.escalationPolicies && scheduleInfo.escalationPolicies.length > 0 ? (
            <Text>
              {schedulesTextCopy.alertDialogs.delete.bodyEscalationsPresent.replace(
                'SCHEDULENAME',
                scheduleInfo?.name ?? '',
              )}
            </Text>
          ) : (
            <Text>
              {schedulesTextCopy.alertDialogs.delete.bodyNoEscalations.replace(
                'SCHEDULENAME',
                scheduleInfo?.name ?? '',
              )}
            </Text>
          )}
          <VStack mt={5} p={1} alignItems="flex-start" maxH={150} overflow="auto" gap={2}>
            {scheduleInfo?.escalationPolicies &&
              scheduleInfo.escalationPolicies.map((ep, i) => {
                const mappingValue = formik.values.input?.[i];
                const dropdownValue = mappingValue
                  ? [
                      ...(schedulesGroup.options.filter(opt =>
                        mappingValue.scheduleIDs?.includes(opt.ID),
                      ) ?? []),
                      ...(squadsGroup.options.filter(opt =>
                        mappingValue.squadIDs?.includes(opt.id),
                      ) ?? []),
                      ...(usersGroup.options.filter(opt =>
                        mappingValue.userIDs?.includes(opt.id),
                      ) ?? []),
                    ]
                  : [];
                return (
                  <Box key={ep.ID} w="100%">
                    <Text alignSelf="flex-start" color="gray.700">
                      {ep.name}
                    </Text>
                    <Select
                      name="select-schedule-replacement"
                      inputId="select-schedule-replacement"
                      options={[schedulesGroup, squadsGroup, usersGroup]}
                      value={dropdownValue}
                      onChange={value =>
                        onReplaceHandler(
                          ep.ID,
                          value as Array<{ type: string; ID?: number; id?: string }>,
                        )
                      }
                      hideDropdownArrow
                      closeMenuOnSelect
                      placeholder={schedulesTextCopy.alertDialogs.delete.dropdownPlaceholder}
                      formatOptionLabel={ParticipantOptionLabel}
                      components={{ MultiValue }}
                      isMulti
                      styles={{
                        ...customStyles,
                        menuPortal: provided => ({
                          ...provided,
                          zIndex: 41,
                        }),
                      }}
                      menuPortalTarget={document.body}
                    />
                  </Box>
                );
              })}
          </VStack>
          <HStack w="100%" mt={4} justifyContent="flex-end">
            <Button variant="invertedDefault" onClick={resetScheduleAction}>
              {schedulesTextCopy.alertDialogs.delete.cancelButtonText}
            </Button>
            <Button
              variant="default"
              isLoading={isScheduleDeleting}
              onClick={() => {
                formik.handleSubmit();
              }}
              isDisabled={isDeleteButtonDisabled}
              ml={3}
            >
              {schedulesTextCopy.alertDialogs.delete.confirmButtonText}
            </Button>
          </HStack>
        </FormikProvider>
      </ScaleFade>
    </Loader.Spinner>
  );
};

export default DeleteScheduleSelection;
