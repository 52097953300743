import {
  Box,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Text,
} from '@chakra-ui/react';
import { Field, Form, useFormikContext } from 'formik';
import { THEME_COLORS } from 'library/theme/colors';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { DEFAULT_THEMES } from '../../../constants/colors';
import { THEME_TYPE } from '../../../constants/status.constants';
import { IStatusTheme } from '../../../Interface';
import ColorInput from './colorInput';
import LogoActionButtons from './LogoAction';
import ThemeDisplay from './themeDisplay';
import UploadLogoWithRef from './uploadLogo';

const CustomizePageForm = () => {
  //ref for change logo
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { errors, values, setFieldValue, touched, setFieldTouched } =
    useFormikContext<IStatusTheme>();

  return (
    <Form style={{ overflowY: 'auto', padding: '24px 0px' }}>
      <FormControl isInvalid={!!errors.logo} mb={25}>
        <FormLabel htmlFor="logo">Upload Company Logo*</FormLabel>
        <Container
          my="5"
          mx={0}
          px={0}
          display={'flex'}
          flexDirection="row"
          gap={5}
          justifyContent={'flex-start'}
          alignItems={'end'}
        >
          {values.logoUrl ? (
            <Image src={values.logoUrl} width="200px" height={'200px'} objectFit={'cover'} />
          ) : null}

          <UploadLogoWithRef
            areaHidden={!!values.logoUrl}
            ref={inputRef}
            setFieldValue={setFieldValue}
          />

          {values.logoUrl ? (
            <LogoActionButtons
              current={inputRef.current}
              logo={values.logo}
              setFieldValue={setFieldValue}
            />
          ) : null}
        </Container>
        {errors?.logo && <FormErrorMessage>{errors.logo}</FormErrorMessage>}
      </FormControl>

      <Box mb={5}>
        <Text fontSize={'14px'} fontWeight="bold" color={THEME_COLORS.secondary[700]}>
          Choose a color theme for your status page
        </Text>

        <Text fontSize={'12px'} fontWeight="normal" color={THEME_COLORS.secondary[700]}>
          Pick or define a color that resonates with your brand
        </Text>
      </Box>

      <FormControl>
        <label style={{ display: 'flex' }}>
          <Field type="radio" name="schemaType" value={THEME_TYPE.DEFAULT} />
          <Text ml={2} fontWeight="medium">
            Pick from default Colors
          </Text>
        </label>
        {values.schemaType === THEME_TYPE.DEFAULT ? (
          <Flex ml={5} my={3} direction="column">
            <Flex>
              {DEFAULT_THEMES.map(theme => {
                return (
                  <Flex
                    key={theme.primary}
                    mr={4}
                    onClick={() => {
                      setFieldValue('primaryColor', theme.primary);
                      setFieldValue('secondaryColor', theme.text);
                      setFieldTouched('secondaryColor');
                    }}
                  >
                    <ThemeDisplay
                      primary={theme.primary}
                      secondary={theme.text}
                      isSelected={
                        theme.primary === values.primaryColor &&
                        theme.text === values.secondaryColor
                      }
                    />
                  </Flex>
                );
              })}
            </Flex>
            {touched.secondaryColor && errors.secondaryColor ? (
              <Text color="red.400">Theme is required</Text>
            ) : null}
          </Flex>
        ) : null}
        <label style={{ display: 'flex' }}>
          <Field type="radio" name="schemaType" value={THEME_TYPE.CUSTOM} />
          <Text ml={2} fontWeight="medium">
            Define Colors
          </Text>
        </label>
        {values.schemaType === THEME_TYPE.CUSTOM ? (
          <Flex ml={5} my={3}>
            <Flex direction={'column'} mr={4}>
              <Text>Brand Color</Text>
              <ColorInput
                color={values.primaryColor || ''}
                setColor={(color: string) => {
                  setFieldTouched('primaryColor');
                  setFieldValue('primaryColor', color);
                }}
              />
              {touched.primaryColor && errors.primaryColor ? (
                <Text color="red.400">{errors.primaryColor}</Text>
              ) : null}
            </Flex>
            <Flex direction={'column'} mr={4}>
              <Text>Text Color</Text>
              <ColorInput
                color={values.secondaryColor || ''}
                setColor={(color: string) => {
                  setFieldTouched('secondaryColor');
                  setFieldValue('secondaryColor', color);
                }}
              />
              {touched.secondaryColor && errors.secondaryColor ? (
                <Text color="red.400">{errors.secondaryColor}</Text>
              ) : null}
            </Flex>
            <Flex direction={'column'} mr={4}>
              <Text>Preview</Text>
              <ThemeDisplay
                primary={values.primaryColor}
                secondary={values.secondaryColor}
                isSelected={false}
              />
            </Flex>
          </Flex>
        ) : null}
      </FormControl>
    </Form>
  );
};
export default CustomizePageForm;
