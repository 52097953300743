import {
  Grid,
  Avatar,
  Label,
  Para,
  Pill,
  SelectBox,
  FocusBlock,
  TextButton,
  SpinLoader,
  Theme,
  Checkbox,
  ErrorBlock,
} from 'uie/components';
import { exception, TeamsService } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import { IComponentErrors } from 'core/interfaces/IComponentState';
import { ReduceEntity } from 'core/interfaces/IHelpers';
import { ITeam, ITeamMemberDependencies } from 'core/interfaces/ITeams';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import {
  EscalationIcon,
  IncidentsIcon,
  RoutingRuleIcon,
  ScheduleIcon,
  SquadIcon,
  ServiceIcon,
  WebformsIcon,
  SloTrackerIcon,
  RunbookIcon,
  StakeholderIcon,
  TeamIcon,
  AlternateEyeFilledIcon,
} from 'icons';
import React, { useState } from 'react';
import { getAssignableStakeHolders, getAssignableTeamMembers } from 'shared/reducers';
import { Note } from '../../shared/note';

interface IProps extends Pick<IAppState, 'organization'> {
  oldUser: IUsersOfOrganization;
  dependencies: ITeamMemberDependencies;
  team: ITeam;
  getStakeholderConflicts: (userId: string) => void;
}

const { theme } = Theme;

const TeamDependencies: React.FC<IProps> = (props: IProps) => {
  const _teamService = new TeamsService();

  const [newUserId, setNewUserId] = useState('');
  const [isBusy, setBusy] = useState(false);
  const [suppressIncidents, setSuppressIncidents] = useState(false);
  const [errors, setErrors] = useState({} as IComponentErrors<'replace_error'>);

  const selectNewUser = (_: any, newUserId: string) => {
    setNewUserId(newUserId);
  };

  const toggleSuppressIncidents = () => {
    setSuppressIncidents(!suppressIncidents);
  };

  const replaceAndDelete = async () => {
    setBusy(true);
    try {
      await _teamService.replaceTeamMember(props.team.id, props.oldUser.id as string, {
        new_user_id: newUserId,
        squad_ids: props.dependencies.squads.map(sq => sq.id),
        escalation_policy_ids: props.dependencies.escalation_policies.map(ep => ep.id),
        schedule_ids: props.dependencies.schedules.map(sc => sc.id),
        schedulev2_ids: props.dependencies.schedulesV2.map(scv2 => Number(scv2.id)),
        ger_ids: props.dependencies.global_event_rules_owner.map(ger => Number(ger.id)),
        workflow_ids: props.dependencies.workflows.map(wf => Number(wf.id)),
        status_page_ids: props.dependencies.status_pages_owner.map(sp => Number(sp.id)),
        incident_group_ids: props.dependencies.incident_groups.map(ig => Number(ig.id)),
        replace_incidents: true,
        replace_routing_rules: true,
        replace_service_maintainer: true,
        replace_user_with_service_delay_notification_config: true,
        replace_webforms_entity_owner: true,
        replace_slos_entity_owner: true,
        replace_schedules_entity_owner: true,
        replace_rbooks_entity_owner: true,
        replace_esc_policy_owner: true,
        replace_ger_owners: true,
        suppress_incidents: suppressIncidents,
        replace_schedules_v2_participants: true,
        replace_status_page_owner: true,
        replace_incident_groups: true,
        replace_workflows_owner: true,
        replace_stakeholder_groups: Boolean(props.dependencies?.stakeholder_groups.length),
        stakeholder_group_ids: props.dependencies.stakeholder_groups.map(stk => stk.id),
        routing_number_ids: props.dependencies.routing_numbers.map(rn => Number(rn.id)),
        replace_routing_numbers_owner: true,
        replace_incident_watchers: true,
      });
      props.getStakeholderConflicts(props.oldUser.id);
    } catch (err) {
      setErrors({ replace_error: exception.handle('E_USER_REPLACE', err) });
    } finally {
      setBusy(false);
    }
  };

  const renderEntities = [
    {
      title: 'Escalation Policies',
      icon: <EscalationIcon width={18} height={18} />,
      entities: props.dependencies.escalation_policies,
    },
    {
      title: 'Escalation Policies Owned',
      icon: <EscalationIcon width={18} height={18} />,
      entities: props.dependencies.esc_policies_with_owners,
    },
    {
      title: 'Legacy Schedules',
      icon: <ScheduleIcon width={18} height={18} />,
      entities: props.dependencies.schedules,
    },
    {
      title: 'Schedules Owned',
      icon: <ScheduleIcon width={18} height={18} />,
      entities: props.dependencies.schedules_owner,
    },
    {
      title: 'Squads',
      icon: <SquadIcon width={18} height={18} />,
      entities: props.dependencies.squads,
    },
    {
      title: 'Services Owned',
      icon: <ServiceIcon width={18} height={18} />,
      entities: props.dependencies.services,
    },
    {
      title: 'Webforms Owned',
      icon: <WebformsIcon width={18} height={18} />,
      entities: props.dependencies.webforms,
    },
    {
      title: 'Slos Owned',
      icon: <SloTrackerIcon width={18} height={18} />,
      entities: props.dependencies.slos,
    },
    {
      title: 'Runbooks Owned',
      icon: <RunbookIcon width={18} height={18} />,
      entities: props.dependencies.runbooks_owner,
    },
    {
      title: 'Schedules',
      icon: <ScheduleIcon width={18} height={18} />,
      entities: props.dependencies.schedulesV2,
    },
    {
      title: 'Stakeholder group',
      icon: <StakeholderIcon stroke={theme.shades.black} width={18} height={18} />,
      entities: props.dependencies.stakeholder_groups,
    },
    {
      title: 'Incident Watcher',
      icon: <AlternateEyeFilledIcon stroke={theme.shades.black} width={18} height={18} />,
      entities: props.dependencies.incident_watchers,
    },
  ];

  const routingRules = props.dependencies.routing_rules.reduce((acc, curr) => acc + curr.count, 0);

  const assignableUsers = props.dependencies?.stakeholder_groups.length
    ? getAssignableStakeHolders(props.team, props.organization.users.u).reduce(
        (c: ReduceEntity<IUsersOfOrganization, 'id' | 'email', 'name'>, u) => {
          c[u.id] = {
            id: u.id,
            name: `${u.first_name} ${u.last_name}`,
            email: u.email,
          };

          return c;
        },
        {},
      )
    : getAssignableTeamMembers(props.team, props.organization.users.u).reduce(
        (c: ReduceEntity<IUsersOfOrganization, 'id' | 'email', 'name'>, u) => {
          c[u.id] = {
            id: u.id,
            name: `${u.first_name} ${u.last_name}`,
            email: u.email,
          };

          return c;
        },
        {},
      );

  return (
    <Grid justifyContent="space-between">
      <Grid flexWidth={8} type="column" style={{ padding: 16 }}>
        <Grid alignItems="baseline" style={{ marginBottom: 32 }}>
          <Grid>
            <Avatar base={10} fontSize={14} reduceColorString={props.oldUser.id as string}>
              {props.oldUser.first_name + ' ' + props.oldUser.last_name}
            </Avatar>
          </Grid>

          <Grid type="column" style={{ paddingLeft: 8 }}>
            <Grid>
              <Label fontSize={16} fontWeight={500} color={theme.shades.black}>
                {props.oldUser.first_name + ' ' + props.oldUser.last_name}
              </Label>
            </Grid>
            <Grid className="mt-10">
              <Label fontSize={16} fontWeight={500}>
                {`(${props.oldUser.email}) is part of the following:`}{' '}
              </Label>
            </Grid>
          </Grid>
        </Grid>

        <Grid flexWidth={10} type="column">
          {renderEntities.map((re, idx) => (
            <>
              {re.entities.length > 0 && (
                <Grid className="mb-20" alignItems="flex-start">
                  <Grid>{re.icon}</Grid>

                  <Grid type="column" style={{ paddingLeft: 8 }}>
                    <Grid>
                      <Para fontSize={14}>{re.title}</Para>
                    </Grid>
                    <Grid className="mt-10" justifyContent="flex-start" flexWrap="wrap">
                      {re.entities.map(e => {
                        return (
                          <Pill key={e.id} className="mr-10 mb-10">
                            <Para fontSize={14}>{e.name}</Para>
                          </Pill>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          ))}

          {/* Incidents */}
          {props.dependencies.incidents > 0 && (
            <Grid className="mb-20" alignItems="flex-start">
              <Grid>
                <IncidentsIcon stroke={theme.shades.black} width={18} height={18} />
              </Grid>

              <Grid type="column" style={{ paddingLeft: 8 }}>
                <Grid>
                  <Para fontSize={14}>Incidents</Para>
                </Grid>
                <Grid type="column">
                  <Grid className="mt-10" alignItems="center">
                    <Para fontSize={14}>Open Incidents:</Para>
                    <Pill className="ml-10">
                      <Para fontSize={14}>{`${props.dependencies.incidents} Incident(s)`}</Para>
                    </Pill>
                  </Grid>
                  <Grid className="mt-10">
                    <Label style={{ height: 'auto' }} className="mb-10 cursor-pointer">
                      <Grid alignItems="flex-start">
                        <Checkbox
                          checked={suppressIncidents}
                          onChange={toggleSuppressIncidents}
                          base="14px"
                        />
                        <Para className="ml-10" fontSize={14} fontWeight={400}>
                          Suppress the open incidents
                        </Para>
                      </Grid>
                    </Label>
                  </Grid>
                  <Grid>
                    <Para fontSize={12} color={theme.shades.lightGrey}>
                      If <strong>checked</strong>: incidents move to Suppressed state.
                      <br />
                      If <strong>unchecked</strong>: incidents are reassigned to user and will move
                      back to Triggered state. To avoid alert fatigue, they will only receive{' '}
                      <strong>one Email</strong> notification for all the reassigned incidents.
                    </Para>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Team */}
          {props.dependencies.is_last_member_with_manage_team_role && (
            <Grid className="mb-20" alignItems="flex-start">
              <Grid>
                <TeamIcon width={18} height={18} />
              </Grid>
              <Grid type="column" style={{ paddingLeft: 8 }}>
                <Grid>
                  <Para fontSize={14}>Team</Para>
                </Grid>
                <Grid className="mt-10">
                  <Label style={{ height: 'auto' }} className="mb-10">
                    <Para fontSize={14} fontWeight={400}>
                      This is the last member with the Manage Team role in this team. Removing this
                      member will remove manage team abilities.
                    </Para>
                  </Label>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Routing Rules */}
          {routingRules > 0 && (
            <Grid className="mb-20" alignItems="center">
              <Grid>
                <RoutingRuleIcon width={18} height={18} />
              </Grid>
              <Grid type="column" style={{ paddingLeft: 8 }}>
                <Grid alignItems="center">
                  <Para fontSize={14}>Routing Rules</Para>
                  <Pill className="ml-10">
                    <Para>{`${routingRules} Rule(s)`}</Para>
                  </Pill>
                </Grid>
              </Grid>
            </Grid>
          )}
          {props.oldUser.role !== 'stakeholder' && <Note />}
        </Grid>
      </Grid>
      <Grid flexWidth={4} type="column" style={{ padding: 16 }}>
        <Grid type="column">
          <Grid alignItems="center" className="mb-10" justifyContent="flex-end">
            <Grid>
              <Label fontSize={16} fontWeight={500} color={theme.shades.black}>
                Swap
              </Label>
            </Grid>
            <Grid className="ml-10 mr-10">
              <Avatar base={10} fontSize={14} reduceColorString={props.oldUser.id as string}>
                {props.oldUser.first_name + ' ' + props.oldUser.last_name}
              </Avatar>
            </Grid>
            <Grid>
              <Label fontSize={16} fontWeight={500} color={theme.shades.black}>{`${
                props.oldUser.first_name + ' ' + props.oldUser.last_name
              } (${props.oldUser.email})`}</Label>
            </Grid>
          </Grid>
          <Grid className="mb-10">
            <Label fontSize={16} fontWeight={500}>
              With
            </Label>
          </Grid>
          <Grid className="mb-10" flexWidth={5}>
            <SelectBox
              id="teams__members__user_depedencies__select_user"
              style={{ padding: '0px' }}
              width="220px"
              height="auto"
              hook={
                <Para fontSize={14}>
                  {newUserId === '' ? 'Select User' : assignableUsers[newUserId].name}
                </Para>
              }
              onValueChange={selectNewUser}
            >
              {Object.values(assignableUsers)
                .filter(u => u.id !== props.oldUser.id)
                .map((user, i) => {
                  return (
                    <FocusBlock key={i} value={user.id} isSelected={newUserId === user.id}>
                      <Para fontSize={14}>{user.name}</Para>
                    </FocusBlock>
                  );
                })}
            </SelectBox>
          </Grid>
        </Grid>

        <Grid>
          <ErrorBlock>{errors.replace_error}</ErrorBlock>
        </Grid>

        <Grid className="mt-10" justifyContent="flex-start">
          <TextButton
            color={theme.primary.default}
            onClick={replaceAndDelete}
            disabled={newUserId === '' || isBusy}
            height="32px"
          >
            <Grid alignItems="center">
              <Para color={theme.shades.white} fontWeight={500}>
                Swap & Remove
              </Para>
              {isBusy && <SpinLoader className="ml-5" color={theme.shades.white} base="8px" />}
            </Grid>
          </TextButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeamDependencies;
