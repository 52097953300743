import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

type Props = {
  isOpen: boolean;
  isButtonLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: ReactNode;
  body: ReactNode;
};

function ScheduleConfirmationModal({
  isOpen,
  isButtonLoading,
  onClose,
  onConfirm,
  title,
  body,
}: Props) {
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <AlertDialog
      size="md"
      isCentered
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={() => {}}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{body}</AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="outline" ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onConfirm} isLoading={isButtonLoading} ml={3}>
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default ScheduleConfirmationModal;
