import {
  Box,
  CloseButton,
  ListItem,
  Radio,
  RadioGroup,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import {
  ErrorBlock,
  FocusBlock,
  Grid,
  Heading,
  InputBlock,
  Para,
  PulseLoader,
  SelectBox,
  SpinLoader,
  TextButton,
  Theme,
} from 'uie/components';
import { IAppState } from 'core/interfaces/IAppState';
import {
  IComponentErrorState,
  IComponentNetworkState,
  IComponentState,
} from 'core/interfaces/IComponentState';
import { ISlackChannelResponse } from 'core/interfaces/IExtensions';
import { ExtensionService, ServiceService } from 'core/services';
import React, { ChangeEvent, Component } from 'react';
import { Link } from 'react-router-dom';
import { T_WA_UP_SLACK_CHANNEL_MAPPED_TO_SERVICE } from '../../../../../../../core/const/tracker';
import { AppTracker } from '../../../../../../../shared/analytics/tracker';
import NodePopover from '../../../components/Popover';
import { ServiceDetail } from '../../../hooks/useServiceDetail';
import { connect } from 'react-redux';
import { IOrganization } from 'core/interfaces/IOrganization';

const { theme } = Theme;

const SelectBoxContainer: React.FC<{ className?: string; children: React.ReactNode }> = ({
  children,
  className,
}) => (
  <Box className={className} sx={{ '& img': { display: 'inline-block' } }}>
    {children}
  </Box>
);

interface IProps extends Pick<IAppState, 'organization'> {
  serviceId: string;
  service: ServiceDetail | undefined;
  hide: () => void;
  checkAndSetDirty: () => void;
  refetch?: () => void;
}

interface IState {
  componentState: IComponentState;
  componentErrorState: IComponentErrorState;
  componentNetworkState: IComponentNetworkState;
  errorMessage: string | null;
  slackChannels: ISlackChannelResponse[];
  channelOption: 'input' | 'dropdown';
  inputChannelId: string;
  selectedChannel: {
    id: string;
    name: string;
    time: number;
    is_private: boolean;
  };
  searchChannelName: string;
}

class SlackExtensionModal extends Component<IProps, IState> {
  private _extensionService = new ExtensionService();
  private isSlackV2Enabled = this.props.organization.currentOrg.o?.config?.['slackv2'];
  private _serviceService = this.props.service
    ? new ServiceService(this.props.service!.id)
    : undefined;

  constructor(props: IProps) {
    super(props);

    this.state = {
      componentState: 'busy',
      componentErrorState: {},
      componentNetworkState: 'idle',
      errorMessage: null,
      selectedChannel: {
        id: '',
        name: '',
        time: 0,
        is_private: false,
      },
      slackChannels: [],
      searchChannelName: '',
      channelOption: 'input',
      inputChannelId: '',
    };
  }

  componentDidMount() {
    this.getSlackChannels();
  }

  getSlackChannels = async () => {
    this.setState({ componentState: 'busy' });
    try {
      const {
        data: {
          data: { channels },
        },
      } = !this.isSlackV2Enabled
        ? await this._extensionService.getSlackChannels()
        : await this._extensionService.getSlackV2Channels();

      const selectedChannel = channels.find(
        ch => ch.id === this.props?.service?.extensions.slack?.channel_id ?? '',
      );

      this.setState({
        slackChannels: channels,
        componentState: 'idle',
        selectedChannel: selectedChannel
          ? {
              id: selectedChannel.id,
              name: selectedChannel.name,
              time: this.props.service?.extensions.slack?.time as number,
              is_private: selectedChannel.is_private,
            }
          : this.state.selectedChannel,
        channelOption: 'input',
        inputChannelId: selectedChannel?.id || '',
      });

      this.setState({ slackChannels: channels, componentState: 'idle' });
    } catch (err: any) {
      this.setState({
        componentErrorState: { 'no-channels': 'Slack integration is not completed yet!' },
        componentState: 'error',
      });
    }
  };

  onSlackChannelSearchChange = (e: ChangeEvent<HTMLInputElement>) =>
    this.setState({ searchChannelName: e.target.value });

  onSlackChannelSelect = (e: any, v: { id: string; name: string; is_private: boolean }) => {
    this.setState({
      selectedChannel: {
        id: v.id,
        name: v.name,
        time: new Date().getTime(),
        is_private: v.is_private,
      },
    });
    this.props.checkAndSetDirty();
  };

  updateServiceChannel = async () => {
    if (!this._serviceService) return;

    this.setState({ componentNetworkState: 'request' });
    try {
      await this._serviceService.updateServiceExtension(
        this.state.channelOption === 'input'
          ? this.state.inputChannelId
          : this.state.selectedChannel.id,
      );
      this.props.refetch?.();
      this.props.hide();
      AppTracker.track(T_WA_UP_SLACK_CHANNEL_MAPPED_TO_SERVICE);
    } catch (err: any) {
      console.log(err.response.data.meta);
      this.setState({
        componentNetworkState: 'error',
        errorMessage: err.response?.data?.meta?.error_message,
      });
    }
  };

  render() {
    const {
      slackChannels,
      selectedChannel,
      componentState,
      searchChannelName,
      componentNetworkState,
      errorMessage,
    } = this.state;

    return (
      <>
        <Grid className="mb-20" justifyContent="space-between">
          <Grid alignItems="flex-start" type="column">
            <Heading height={35} fontSize={24}>
              Service Slack Channel
            </Heading>
            <Text fontSize={16}>{this.props.service?.name}</Text>
          </Grid>

          <CloseButton onClick={this.props.hide} />
        </Grid>
        {componentState === 'error' && (
          <Grid alignItems="center" justifyContent="flex-start">
            <Text>
              {errorMessage ?? 'Looks like there is no Slack integration configured yet!'}
            </Text>
            <Link
              className="ml-10"
              to="/settings/extension"
              style={{ textDecoration: 'underline' }}
            >
              Configure here
            </Link>
          </Grid>
        )}
        {componentState === 'busy' && (
          <Grid alignItems="center" justifyContent="flex-start">
            <SpinLoader base="16px" />
            <Text className="ml-10">Loading Extensions</Text>
          </Grid>
        )}
        {componentState === 'idle' && (
          <>
            <Grid type="column" flexWidth={12}>
              <Grid justifyContent="flex-start" type="column">
                <Text color={theme.shades.grey}>
                  The following channels from your Slack workspace are listed below:
                  <UnorderedList>
                    <ListItem ml={4}>all the Public channels</ListItem>
                    <ListItem ml={4}>
                      only the Private channels in which Squadcast bot is already present
                    </ListItem>
                  </UnorderedList>
                </Text>
                <Text className="mt-10 mb-10" color={theme.shades.grey}>
                  {`If you do not see your preferred channel then please follow the steps `}
                  <a
                    className="installation-guide-design no-select"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://support.squadcast.com/docs/slack#configuring-a-global-slack-channel-for-all-services-in-squadcast"
                    style={{ fontSize: 16 }}
                  >
                    here
                  </a>
                  {` to update the list.`}
                </Text>
              </Grid>
              <Grid>
                <Grid alignItems="center" style={{ marginBottom: '10px' }}>
                  <RadioGroup
                    value={this.state.channelOption}
                    onChange={value =>
                      this.setState({ channelOption: value as 'input' | 'dropdown' })
                    }
                    mr="10px"
                  >
                    <Radio mr="10px" value={'input'}>
                      Channel ID
                    </Radio>
                    <Radio value={'dropdown'}>Select a Channel</Radio>
                  </RadioGroup>
                  <NodePopover
                    triggerType="hover"
                    trigger={<img src="/icons/info_black.svg" alt="more info" width="16px" />}
                    disablePortal
                  >
                    <Text fontSize={12} maxWidth={300}>
                      Incidents triggered from this service ({this.props.service?.name}) will be
                      pinged to #{selectedChannel.name || 'the selected slack channel'}
                    </Text>
                  </NodePopover>
                </Grid>
              </Grid>

              {this.state.channelOption === 'dropdown' && (
                <SelectBoxContainer className="mt-10">
                  <SelectBox
                    hook={
                      <Text fontSize={16}>
                        {selectedChannel.name
                          ? `#${selectedChannel.name}`
                          : 'Select a slack channel'}
                      </Text>
                    }
                    onValueChange={this.onSlackChannelSelect}
                    height="auto"
                    maxHeight="200px"
                    width="100%"
                    maxWidth="100%"
                    searchHookProps={{
                      value: searchChannelName,
                      height: '24px',
                      fontSize: '16px',
                      onChange: this.onSlackChannelSearchChange,
                      placeholder: 'Search Channel',
                    }}
                  >
                    {slackChannels
                      .filter(c =>
                        searchChannelName.length > 0
                          ? c.name.toLowerCase().includes(searchChannelName.toLowerCase())
                          : true,
                      )
                      .map((c, i) => (
                        <FocusBlock key={i} value={c} isSelected={selectedChannel.id === c.id}>
                          <Text fontSize={16} pl={8}>
                            #{c.name}
                          </Text>
                        </FocusBlock>
                      ))}
                  </SelectBox>
                </SelectBoxContainer>
              )}
              {this.state.channelOption === 'input' && (
                <InputBlock
                  onChange={e => this.setState({ inputChannelId: e.target.value })}
                  value={this.state.inputChannelId}
                  fontSize="12px"
                  width={250}
                  placeholder="Slack Channel ID"
                />
              )}
            </Grid>
            <Grid className="mt-20" type="column">
              <TextButton
                height="32px"
                style={{ padding: 8 }}
                onClick={this.updateServiceChannel}
                disabled={componentNetworkState === 'request'}
              >
                {componentNetworkState === 'request' ? (
                  <PulseLoader color={theme.shades.white} base="6px" />
                ) : (
                  <Text fontWeight={500} color={theme.shades.white}>
                    Save
                  </Text>
                )}
              </TextButton>
              {componentNetworkState === 'error' && (
                <ErrorBlock className="mt-10">
                  {errorMessage ?? 'Looks like there is some network issue. Please try again.'}
                </ErrorBlock>
              )}
            </Grid>
          </>
        )}
      </>
    );
  }
}

export default connect(({ organization, userOrganizations, INIT_ORG }: IAppState) => ({
  selectedOrg: userOrganizations.o.find(o => o.organizationId === INIT_ORG.orgId) as IOrganization,
  organization,
}))(SlackExtensionModal);
