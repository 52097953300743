import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text } from '@chakra-ui/react';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { Field, useFormikContext } from 'formik';
import { THEME_COLORS } from 'library/theme/colors';
import Select from 'react-select';

import NestedDropdown from '../../../components/NestedDropdown';
import { ISSUES_STATES } from '../../../constants/status.constants';
import { getSelectedComponents } from '../../../helpers/helper.service';
import { IOptionComponent } from '../../../Interface';

export const IssueDetailsForm = () => {
  const { errors, values, setFieldValue, setFieldTouched, touched }: any = useFormikContext();

  const handleComponentSelect = (componentOptions: IOptionComponent[]) => {
    setFieldValue('allComponents', componentOptions);
    const selectedComponents = getSelectedComponents(componentOptions, values.selectedComponents);
    setFieldValue('selectedComponents', selectedComponents);
  };

  const canEdit = useUserAccess().hasUpdateAccess;
  return (
    <>
      <FormControl isInvalid={errors.title && touched.title} mb={25}>
        <FormLabel>
          Issue Title<span>*</span>
        </FormLabel>
        <Field
          as={Input}
          type="text"
          name="title"
          disabled={!canEdit}
          placeholder="Eg : Some files are experiencing problems to load"
        />
        {errors?.title && touched.title && <FormErrorMessage>{errors.title}</FormErrorMessage>}
      </FormControl>
      <FormControl isInvalid={errors.status} my={8}>
        <FormLabel htmlFor="status">Page Status*</FormLabel>
        <Field
          as={Select}
          name="status"
          id="status"
          options={values.statusCodes}
          isDisabled={values.isMigrated || !canEdit}
          onChange={(value: string) => setFieldValue('status', value)}
        />
        {errors.status && <FormErrorMessage>{errors.status}</FormErrorMessage>}
      </FormControl>
      <Flex justifyContent="flex-start" direction="column" bg={THEME_COLORS.primary[100]} p={5}>
        <FormControl>
          <FormLabel>Select Component and Impact*</FormLabel>
          <NestedDropdown
            componentGroupOption={values.allComponents}
            disabled={
              values.isMigrated || !canEdit || values.currentIssueState === ISSUES_STATES.RESOLVED
            }
            onTrigger={() => setFieldTouched('selectedComponents')}
            setOption={handleComponentSelect}
          />
          {values.selectedComponents.length > 0 ? (
            <Flex mt={4} direction="column">
              <Flex>
                <Flex flex="1">
                  <FormLabel>COMPONENT</FormLabel>
                </Flex>
                <Flex flex="1">
                  <FormLabel>COMPONENT STATUS</FormLabel>
                </Flex>
              </Flex>
              {values.selectedComponents.map((comp: any, id: number) => {
                return (
                  <Flex key={comp.id} align="center" mb="4">
                    <Flex flex={1} align="center">
                      <FormLabel>{comp.label}</FormLabel>
                    </Flex>
                    <Flex flex={1}>
                      <FormControl
                        isInvalid={errors.selectedComponents && errors.selectedComponents[id]}
                      >
                        <Field
                          as={Select}
                          isDisabled={values.isMigrated || !canEdit}
                          name={`selectedComponents[${id}].value`}
                          options={values.statusCodes}
                          placeholder="Select Status"
                          onChange={(value: string) =>
                            setFieldValue(`selectedComponents[${id}].value`, value)
                          }
                        />
                      </FormControl>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          ) : null}
          {touched.selectedComponents && values.selectedComponents.length === 0 ? (
            <Box>
              <Text color="red" fontSize={14}>
                {errors.selectedComponents}
              </Text>
            </Box>
          ) : null}
        </FormControl>
      </Flex>
    </>
  );
};
