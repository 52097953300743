import { ArrowDownIcon, ArrowUpIcon, InfoIcon } from 'icons';
import React, { Fragment, useMemo } from 'react';

import { DateRange } from '@cubejs-client/core';
import { Grid, Heading, Para, Theme } from 'uie/components';

import { Filters, Stat, StatsChart } from '../types';
import { formatDateValue } from '../utils/graphs.utils';
import { deltaPercent } from '../utils/resultset.utils';
import { applyUnits } from '../utils/stats.utils';
import { AnalyticsTooltip } from './PanelContainer';

const { theme } = Theme;

const ProgressColor = ({
  delta,
  previousTooltip,
}: {
  delta: number;
  previousTooltip: JSX.Element;
}) => {
  let color = theme.shades.grey;
  let deltaText = '';
  if (delta > 0) {
    color = theme.danger.default;
    deltaText = 'more';
  } else if (delta < 0) {
    color = theme.success.default;
    deltaText = 'less';
  }

  return (
    <Fragment>
      <Para color={color} style={{ display: 'inline-block' }}>
        {Math.abs(delta)}% {deltaText}
      </Para>
      {previousTooltip}
    </Fragment>
  );
};

interface StatsTooltipProps {
  previousDateRange: [string, string] | null;
  previousValue: string;
}

const StatsTooltip = ({ previousDateRange, previousValue }: StatsTooltipProps) => {
  return previousDateRange ? (
    <sup>
      <AnalyticsTooltip
        label={`${previousValue} ${previousDateRange[0]} - ${previousDateRange[1]}`}
        offset={{ top: '-70px' }}
        style={{ left: -10, maxWidth: 250, lineHeight: '1.25' }}
      >
        <InfoIcon height={14} width={14} />
      </AnalyticsTooltip>
    </sup>
  ) : null;
};

type Props = Stat & {
  disablePrevTracking: boolean;
  filters: Filters;
  chart: StatsChart;
  dateRange: Array<DateRange>;
};

const StatsPanel: React.FC<Props> = ({
  chart,
  value,
  previousValue,
  dateRange,
  disablePrevTracking,
}) => {
  const [display, tooltip] = applyUnits(value, chart.unitsFn);

  const [_, tooltipPreviousValue] = applyUnits(previousValue, chart.unitsFn);
  const deltaPc = deltaPercent(value, previousValue);

  const previousDateRange = useMemo(() => {
    const lastDateRange = dateRange[dateRange.length - 1];
    if (Array.isArray(lastDateRange)) {
      const [startDate, endDate] = [new Date(lastDateRange[0]), new Date(lastDateRange[1])];
      return [formatDateValue(startDate, 'day'), formatDateValue(endDate, 'day')] as [
        string,
        string,
      ];
    }
    return null;
  }, [dateRange]);

  const previousTooltip = (
    <StatsTooltip previousValue={tooltipPreviousValue} previousDateRange={previousDateRange} />
  );

  const previousPeriod = (
    <Para style={{ marginTop: 10, wordSpacing: 2 }} color={theme.shades.grey}>
      {deltaPc === 0 ? (
        <>No change{previousTooltip} from the previous period</>
      ) : (
        <>
          <ProgressColor delta={deltaPc} previousTooltip={previousTooltip} />{' '}
          <Para fontSize={13} color={theme.shades.grey} style={{ display: 'inline-block' }}>
            than the previous period
          </Para>
        </>
      )}
    </Para>
  );

  return (
    <Grid type="column">
      <Heading fontSize={28} title={tooltip} style={{ lineHeight: '45px' }}>
        {disablePrevTracking ? 0 : display}
      </Heading>
      {previousPeriod}
    </Grid>
  );
};

export default StatsPanel;
