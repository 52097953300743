import React from 'react';
import { SingleDatePicker } from 'react-dates';
import moment, { Moment } from 'moment';
import { Locale } from 'core/helpers/dateUtils';

interface Props {
  name: string;
  showPicker: string;
  date: Date;
  handleChange: (date: Date) => void;
  showPickerHandler: (str: string) => void;
}

function CalenderDatePicker(props: Props) {
  return (
    <SingleDatePicker
      date={moment(Locale.toSQLDate(props.date))}
      isOutsideRange={() => false}
      onDateChange={(date: Moment | null) => {
        if (date) {
          props.handleChange(date.toDate());
        }
      }}
      displayFormat="ddd,MMMM D"
      focused={props.showPicker === props.name}
      numberOfMonths={1}
      onFocusChange={({ focused }) => {
        props.showPickerHandler(focused ? props.name : '');
      }}
      id={`event-start-date-${0}`}
      hideKeyboardShortcutsPanel={true}
    />
  );
}

export default CalenderDatePicker;
