import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { Locale } from 'core/helpers/dateUtils';
import { generatePath, Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { SERVICE_DETAILS_PATH } from 'views/main/routes/routes';

import { hasReadAccessToEntity } from '../../../navigation-flows/helpers';
import { getIncidentDetailsPath } from '../../common/util';
import TagList, { TagType } from '../TagList';
import { IIntegration } from 'core/interfaces/IIntegration';
import { Priority } from 'library/common';
import { getPriorityColumnHeader, getTextComponent } from '../column-utils';
import { IChildIncident } from 'core/interfaces/IIncidents';
import { COLUMN_DEF_PADDING_LEFT_VALUE } from '../../common/constants';

export const INCIDENT_CHILDREN_COLUMN_DEFS = (alertSources: IIntegration[]) => [
  {
    Header: 'INCIDENT MESSAGE',
    Cell: (cell: Cell<IChildIncident>) => {
      const { id, message, tags } = cell.row.original;
      const updatedTags: TagType[] = tags
        ? Object.keys(tags).map(key => ({
            key,
            value: tags[key].value,
            color: tags[key].color,
          }))
        : [];
      return (
        <VStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} pb={4} alignItems="flex-start">
          <Link to={getIncidentDetailsPath(id)}>
            <Text variant="body" noOfLines={1}>
              {message}
            </Text>
          </Link>
          {!!updatedTags.length && <TagList tags={updatedTags} />}
        </VStack>
      );
    },
  },
  {
    id: 'priority',
    Header: () => {
      return getPriorityColumnHeader();
    },
    Cell: (cell: Cell<IChildIncident>) => {
      const priority = cell.row.original.priority;
      return (
        <Box pl={COLUMN_DEF_PADDING_LEFT_VALUE} mt={1}>
          <Priority value={priority} />
        </Box>
      );
    },
  },
  {
    Header: 'SERVICE',
    Cell: (cell: Cell<IChildIncident>) => {
      const { id, name } = cell.row.original.service;
      let nameComp = (
        <Text pl={COLUMN_DEF_PADDING_LEFT_VALUE} variant="body">
          {name}
        </Text>
      );
      if (hasReadAccessToEntity('service')) {
        nameComp = <Link to={generatePath(SERVICE_DETAILS_PATH, { id })}>{nameComp}</Link>;
      }
      return <Box pl={COLUMN_DEF_PADDING_LEFT_VALUE}>{nameComp}</Box>;
    },
  },
  {
    Header: 'ALERT SOURCE',
    Cell: (cell: Cell<IChildIncident>) => {
      const id = cell.row.original.alert_source_id;
      const alertSource = alertSources.find(as => as._id === id);
      const name = alertSource?.type ?? '';
      const icon = alertSource?.iconURL?.svg ?? '';
      return (
        <HStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} columnGap="4px">
          {icon && <img src={icon} width="24px" />}
          {getTextComponent(name)}
        </HStack>
      );
    },
    minWidth: 100,
  },
  {
    Header: 'CREATED AT',
    Cell: (cell: Cell<IChildIncident>) => {
      const { timeOfCreation } = cell.row.original;
      return (
        <VStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} alignItems="flex-start">
          {getTextComponent(Locale.toShortDate(timeOfCreation))}
          {getTextComponent(Locale.to24HrTimeWithSec(timeOfCreation))}
        </VStack>
      );
    },
    minWidth: 100,
  },
];
