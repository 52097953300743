import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="233.495 -1.625 502.902 503.25" {...props}>
    <path
      d="M2445 5154c-16-2-73-9-125-15-259-29-543-114-795-238C772 4531 265 3835 133 2990c-24-156-24-537 0-693 86-561 330-1049 715-1435 203-202 416-354 677-482 246-121 478-193 762-237 155-24 527-24 688 0 291 44 532 120 786 248 399 202 733 501 982 879 146 222 284 547 342 804 47 212 58 316 59 556 1 316-31 521-126 809-206 621-663 1150-1253 1448-257 130-501 207-785 248-108 16-467 29-535 19Zm335-504c713-54 1346-484 1659-1124 141-288 204-562 204-886s-63-598-204-886C4080 1019 3297 568 2487 630 1170 730 303 2031 720 3282c251 754 956 1307 1743 1367 150 12 170 12 317 1Z"
      style={{
        fill: '#10b77f',
      }}
      transform="matrix(.1 0 0 -.1 221.995 514.125)"
    />
    <path
      d="M2492 3469c-84-14-200-56-282-102-102-57-250-205-307-307-149-267-149-573 0-840 57-101 205-250 307-307 266-149 573-149 840 0 102 57 250 205 307 307 149 267 149 573 0 840-57 102-205 250-307 307-171 96-373 133-558 102Z"
      style={{
        fill: '#10b77f',
      }}
      transform="matrix(.1 0 0 -.1 221.995 514.125)"
    />
  </svg>
);

export default SvgComponent;
