import { Box, Text } from '@chakra-ui/react';
import { SelectOption } from 'components/SecondaryFilter/types';
import { IAppState } from 'core/interfaces/IAppState';
import { Dropdown, FormButton, useToast } from 'library/atoms';
import { Modal } from 'library/molecules';
import { useContext, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { INCIDENT_STATUS } from '../common/enums';
import { BaseModalProps } from '../common/types';
import { getTimeZone } from '../common/util';
import { IncidentListContext } from '../store';
import { exportIncidents } from './export';
import { Tip } from './tip';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_INCIDENT_LIST_PAGE_INCIDENTS_DOWNLOADED } from 'core/const/tracker';

type Props = BaseModalProps & {
  status: INCIDENT_STATUS;
  enableAutoRefresh: () => void;
};

export const DownloadIncidents = ({ isOpen, onClose, enableAutoRefresh, status }: Props) => {
  const downloadOptions: SelectOption[] = [
    { value: 'csv', label: 'CSV' },
    { value: 'json', label: 'JSON' },
  ];

  const organization = useSelector((state: IAppState) => state.organization, shallowEqual);
  const userInfo = useSelector((state: IAppState) => state.userInfo, shallowEqual);
  const timeZone = getTimeZone(organization);

  const [format, setFormat] = useState(downloadOptions[0]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showTip, setShowTip] = useState(false);

  const { state } = useContext(IncidentListContext);

  const toast = useToast();

  const closeWithRefresh = () => {
    onClose();
    enableAutoRefresh();
  };

  const download = async () => {
    setIsDownloading(true);
    const { isSuccess, message } = await exportIncidents(
      format.value,
      userInfo.d?.id ?? '',
      status,
      organization.selectedTeam.teamId,
      timeZone,
      state.sortBy,
      state.search.isEnabled ? state.search.term : undefined,
    );
    setIsDownloading(false);
    if (isSuccess) {
      closeWithRefresh();
      AppTracker.track(T_WA_UP_INCIDENT_LIST_PAGE_INCIDENTS_DOWNLOADED, {
        'File Format': format.label,
      });
    } else if (message?.includes('export a maximum of 1000 incidents')) {
      onClose();
      setShowTip(true);
    } else {
      toast({
        status: 'error',
        text: 'Error: Unable to download incidents',
      });
    }
  };

  return (
    <>
      <Modal
        key="download-incidents"
        body={
          <>
            <Box display="flex" flexDirection="column" gap="10px">
              <Text variant="formInput">Download as</Text>
              <Dropdown
                value={format}
                options={downloadOptions}
                onChange={option => setFormat(option as SelectOption)}
              />
              <Text color="secondary.700" variant="body">
                Max of 1000 incidents to be downloaded at once.
              </Text>
            </Box>
          </>
        }
        isOpen={isOpen}
        onClose={closeWithRefresh}
        primaryButton={
          <FormButton
            title="Download"
            isLoading={isDownloading}
            onClick={download}
            variant="primary"
          />
        }
        secondaryButton={
          <FormButton
            title="Cancel"
            isDisabled={isDownloading}
            onClick={closeWithRefresh}
            variant="secondary"
          />
        }
        title="Download All Incidents"
      />
      <Tip
        isOpen={showTip}
        onClose={() => {
          setShowTip(false);
          enableAutoRefresh();
        }}
      />
    </>
  );
};
