export const buildPageLabels = (pageIndex: number, pages: number) => {
  const currentPage = pageIndex + 1;
  const getNextPage = (currentPage: number) => Math.min(pages, currentPage + 1);
  const getPrevPage = (currentPage: number) => Math.max(1, currentPage - 1);
  const firstPage = 1;
  const lastPage = pages;
  const nextPage = getNextPage(currentPage);
  const prevPage = getPrevPage(currentPage);

  const dot = 0;
  const invalid = -1;

  return [
    ...new Set(
      [
        firstPage === currentPage ? invalid : firstPage,
        getPrevPage(prevPage) === firstPage ? invalid : dot,
        prevPage === firstPage ? invalid : prevPage,
        currentPage,
        nextPage === lastPage ? invalid : nextPage,
        getNextPage(nextPage) === lastPage ? invalid : dot,
        lastPage === currentPage ? invalid : lastPage,
      ].filter(x => x >= 0),
    ),
  ];
};
