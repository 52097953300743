import request from '../commons';
import { API } from '../../api';

function getOrgEscalations() {
  const req = request.get(API.config.batman)(
    `organizations/${API.config.organizationId}/escalation-policies?owner_id=${API.config.teamId}`,
  );
  return req;
}

export { getOrgEscalations };
