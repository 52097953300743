import * as React from 'react';
import { SVGProps } from 'react';

const SvgGoogleSsoLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M-7.2 49.5c0-.7-.1-1.3-.2-1.9h-9.4v3.8h5.4c-.2 1.2-.9 2.3-2 3v2.5h3.2c1.9-1.8 3-4.4 3-7.4z"
      style={{
        fill: '#4285f4',
      }}
      transform="translate(27.009 -39.239)"
    />
    <path
      d="M-16.8 59.2c2.7 0 5-.9 6.6-2.4l-3.2-2.5c-.9.6-2 1-3.4 1-2.6 0-4.8-1.8-5.6-4.1h-3.3v2.6c1.6 3.2 5 5.4 8.9 5.4z"
      style={{
        fill: '#34a853',
      }}
      transform="translate(27.009 -39.239)"
    />
    <path
      d="M-22.4 51.1c-.2-.6-.3-1.2-.3-1.9s.1-1.3.3-1.9v-2.6h-3.3c-.7 1.3-1.1 2.9-1.1 4.5s.4 3.1 1.1 4.5l3.3-2.6z"
      style={{
        fill: '#fbbc05',
      }}
      transform="translate(27.009 -39.239)"
    />
    <path
      d="M-16.8 43.2c1.5 0 2.8.5 3.8 1.5l2.9-2.9c-1.7-1.6-4-2.6-6.7-2.6-3.9 0-7.3 2.2-8.9 5.5l3.3 2.6c.8-2.3 3-4.1 5.6-4.1z"
      style={{
        fill: '#ea4335',
      }}
      transform="translate(27.009 -39.239)"
    />
  </svg>
);

export default SvgGoogleSsoLogo;
