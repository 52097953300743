import { IPostmortem, IPostmortemResponseData } from 'core/interfaces/IPostmortems';
import { v3 } from './base';
import { ListPostmortemsForTimeRangeParams } from './types.postmortem';

export function getPostmortemByIncidentId(incidentId: string) {
  return v3.get<IPostmortem>(`/incidents/${incidentId}/postmortem`).then(r => r.data);
}

export function listPostmortemsForTimeRange({
  fromDate,
  toDate,
  teamId,
  alertSources,
  services,
  status,
  skip,
  limit,
}: ListPostmortemsForTimeRangeParams) {
  return v3
    .get<IPostmortemResponseData[]>(`/incidents/postmortem`, {
      params: {
        alert_sources: alertSources,
        owner_id: teamId,
        fromDate,
        toDate,
        services,
        status,
        skip,
        limit,
      },
    })
    .then(r => r.data);
}
