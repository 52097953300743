import { CountryType } from 'library/molecules/Form/EntityCountryDropDown/type';
import { getAvailableCountries, getDurations } from '../screens/create-or-update/helper';
import { ROUTING_BEHAVIOR } from '../types/LCR.types';
import { IVariant } from 'unleash-proxy-client';

export const getCountryTypeValue = (country: string) => {
  const conuntries: CountryType[] = getAvailableCountries();

  return conuntries.find((el: CountryType) => {
    return el.id === country;
  });
};

export const getDurationTypeValue = (
  duration: string,
  type: ROUTING_BEHAVIOR,
  extraDurationMinutes: Partial<IVariant>,
) => {
  const availableDuration = getDurations(type, extraDurationMinutes.payload?.value);
  return availableDuration.find((el: { label: string; value: number }) => {
    return el.label.split(' ').join('') === duration;
  });
};

export const generateKeywordMapping = (elementArr: any) => {
  return elementArr.map((element: any) => {
    return {
      alias: element.alias,
      keypadNumber: Number(element?.digit),
      service: {
        serviceID: element?.service_id,
        name: element?.service_name,
      },
      schedule: element.schedules?.map((ele: any) => {
        return {
          ID: ele?.id,
          name: ele?.name,
        };
      }),
    };
  });
};
