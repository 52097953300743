import { Text } from '@chakra-ui/react';
import { withCompareGlobalAndEntityTeamId } from 'core/hooks/useCompareGlobalAndEntityTeamId';
import { IAppState } from 'core/interfaces/IAppState';
import { ICreateRunbook, IDataItem, ISteps } from 'core/interfaces/IRunBook';
import { ISquad } from 'core/interfaces/ISquad';
import { ITeam } from 'core/interfaces/ITeams';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { UserAccessContextValue, withUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouterProps } from 'react-router-dom';
import {
  AppFrame,
  ErrorBlock,
  Grid,
  Heading,
  InputBlock,
  Label,
  Para,
  PulseLoader,
  SwitchBlock,
  TextButton,
  Theme,
} from 'uie/components';
import UnsavedChangesGuard from '../../../../../src/components/unsavedChangesGuard';
import { T_WA_GS_RUNBOOK_CREATED, T_WA_GS_RUNBOOK_UPDATED } from '../../../../core/const/tracker';
import { FileUploadService, RunbookService } from '../../../../core/services';
import { CloseIcon } from '../../../../icons';
import { AppTracker } from '../../../../shared/analytics/tracker';
import { IEntityOwner, OwnerEntitiesSelect } from '../slo/ownerDropdown';
import { RenderForNoRunbook } from './index';
import './index.css';
import { isAxiosError } from './runbook.listComponent';
import { RunbookSteps } from './runbook.steps.component';
import {
  componentStateConst,
  markupType,
  runbookAction,
  snackMsg,
  switchOptions as switchOpt,
} from './runbooks.constant';

const { theme } = Theme;
type IAppProps = {
  runbookId: string;
  pathname: string;
  callParentComp: () => void;
  mode: string;
  teamId: string;
  showSnackBar: (msg: string, bg: string, timeout: number) => void;
  currentTeam: ITeam;
  squads: ISquad[];
  users: IUsersOfOrganization[];
  entityOwner?: IEntityOwner;
  organization: IAppState['organization'];
  userAccess: UserAccessContextValue;
  handleDifferentGlobalAndEntityTeamId: (entityTeamId?: string) => void;
} & RouterProps;

interface IState {
  title: string;
  owner: IEntityOwner;
  teamOwner: IEntityOwner;
  errors: { title: string };
  componentState: string;
  tableData: Array<{ content: string; type: string }>;
  contentType: string;
  isGuardReq: boolean;
  errorObj: { title: boolean; owner: boolean; steps: boolean; step: Array<number> };
}

class RunbookCreateOrUpdate extends React.Component<IAppProps> {
  RunbookService = new RunbookService();
  runbookDetails: ICreateRunbook = { name: '', steps: [] };
  switchOptions: Array<string> = switchOpt;
  fileUploadService = new FileUploadService();
  private unsubscribe: any = () => {};
  constructor(props: IAppProps) {
    super(props);
  }
  state: IState = {
    title: '',
    owner: {
      id: this.props.entityOwner?.id ?? '',
      type: this.props.entityOwner?.type ?? '',
    },
    teamOwner: {
      id: '',
      type: '',
    },
    errors: { title: '' },
    componentState: componentStateConst.ACTIVE,
    tableData: [],
    contentType: markupType.MARKDOWN,
    isGuardReq: true,
    errorObj: { title: false, owner: false, steps: false, step: [] },
  };

  onRunbookNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ title: value });
  };
  onChangeEntityOwner = (entityOwner: IEntityOwner) => {
    this.setState({ owner: entityOwner });
  };
  componentDidMount() {
    const { runbookId, mode } = this.props;
    runbookId.length && this.getRunbookDetails(runbookId);
    const hasUpdate = runbookId;
    const hasCreate = !runbookId;
    this.switchOptions = hasUpdate || hasCreate ? this.switchOptions : [this.switchOptions[0]];
    mode && this.switchOptions.includes(mode)
      ? this.setState({ contentType: mode })
      : this.setState({
          contentType: hasUpdate || hasCreate ? markupType.MARKDOWN : markupType.VISUAL,
        });
  }

  componentDidUpdate(prevProps: IAppProps, prevState: IState) {
    const { runbookId } = this.props;
    if (prevProps.runbookId != runbookId) {
      runbookId.length && this.getRunbookDetails(runbookId);
    }

    if (prevProps.currentTeam.id !== this.props.currentTeam.id) {
      // go to runbook list
      this.props.history.push('/runbooks');
      return;
    }

    this.props.handleDifferentGlobalAndEntityTeamId(this.state.teamOwner.id);
  }

  massageSteps = (data: ISteps[]) => {
    return data.map(({ content }) => {
      return { content, type: markupType.VISUAL };
    });
  };

  constructPayload = ({ title, tableData, owner }: IState) => {
    return {
      name: title,
      steps: tableData.map(({ content }: IDataItem) => {
        return { content };
      }),
      owner_id: this.props.teamId,
      entity_owner: {
        id: owner.id,
        type: owner.type,
      },
    };
  };

  save = async () => {
    const { runbookId } = this.props;
    const payload = this.constructPayload(this.state);
    let runbookDetails: any = null;
    try {
      this.setState({ componentState: componentStateConst.BUSY });
      const {
        data: { data: runbookDetails_ },
      } = runbookId.length
        ? await this.RunbookService.update(payload, runbookId)
        : await this.RunbookService.create(payload);

      runbookDetails = runbookDetails_;
      this.props.showSnackBar(snackMsg.runbookSave, theme.success.default, 3000);
      this.setState(
        state => {
          return { isGuardReq: false, componentState: componentStateConst.ACTIVE };
        },
        () => {
          if (runbookDetails && runbookDetails.id) {
            if (runbookId.length) {
              AppTracker.track(T_WA_GS_RUNBOOK_UPDATED, {});
            } else {
              AppTracker.track(T_WA_GS_RUNBOOK_CREATED, {});
            }
            this.props.callParentComp();
          }
        },
      );
    } catch (err) {
      if (isAxiosError(err)) {
        const errMsg = err?.response?.data?.meta?.error_message ?? snackMsg.errRunbookSave;
        this.props.showSnackBar(errMsg, theme.danger.default, 8000);
      } else {
        this.props.showSnackBar(snackMsg.errRunbookSave, theme.danger.default, 8000);
      }
    } finally {
    }
    this.setState({ componentState: componentStateConst.ACTIVE });
  };

  getRunbookDetails = async (runbookId: string) => {
    try {
      this.setState({ componentState: componentStateConst.INIT });
      const {
        data: { data: runbookDetails },
      } = await this.RunbookService.getRunbookDetail(runbookId);

      const title = runbookDetails.name;
      const owner = runbookDetails.entity_owner;
      const teamOwner = runbookDetails.owner;
      const tableData = this.massageSteps(runbookDetails.steps);
      this.runbookDetails = runbookDetails;
      this.setState({
        title,
        tableData: [...tableData],
        componentState: componentStateConst.ACTIVE,
        owner,
        teamOwner,
      });
    } catch (e) {
      this.runbookDetails = { name: '', steps: [] };
      this.props.showSnackBar(snackMsg.errRunbookDetail, theme.danger.default, 8000);
      this.setState({ componentState: componentStateConst.ACTIVE });
    }
  };

  renderHeader = () => {
    const { theme } = Theme;
    const { runbookId } = this.props;
    return (
      <div className="runbook_create_header">
        <Grid justifyContent="space-between" alignItems="center">
          <Heading height={35} fontSize={24}>
            {runbookId ? runbookAction.UPDATE : runbookAction.CREATE} Runbook
          </Heading>
          <Link to="/runbooks">
            <CloseIcon fill={theme.shades.grey} />
          </Link>
        </Grid>
      </div>
    );
  };

  renderBody = () => {
    const { title, contentType, componentState, errorObj, teamOwner } = this.state;
    const { runbookId, currentTeam, squads, users, teamId, callParentComp } = this.props;

    const hasUpdate = this.props.userAccess.hasUpdateAccess('runbooks', runbookId) && !!runbookId;
    const hasCreate = this.props.userAccess.hasCreateAccess('runbooks') && !runbookId;

    return (
      <>
        {componentState === componentStateConst.INIT ? (
          <div className="runbook_body_container">
            <Grid flexWidth={12} alignItems="center" justifyContent="center">
              <PulseLoader />
            </Grid>
          </div>
        ) : (
          <div className="runbook_body_container">
            <>
              <Grid flexWidth={12} alignItems="flex-start">
                <Label htmlFor="runbook_title">Runbook Name</Label>
              </Grid>
              <br />

              <Grid flexWidth={12} alignItems="flex-start">
                <InputBlock
                  id="runbook_title"
                  placeholder="Runbook Name"
                  readOnly={!(hasUpdate || hasCreate)}
                  value={title}
                  onChange={this.onRunbookNameChange}
                  error={!!errorObj.title}
                />
              </Grid>
              {errorObj.title && <ErrorBlock>Runbook should have title.</ErrorBlock>}
            </>
            <br />
            <>
              <Grid flexWidth={12} alignItems="flex-start">
                <Label htmlFor="runbook_title">
                  Runbook Owner
                  <Text as="span" color="#E73A40" pl={4}>
                    *
                  </Text>
                </Label>
              </Grid>
              <br />

              <Grid flexWidth={12} alignItems="flex-start">
                <OwnerEntitiesSelect
                  entityOwner={this.state.owner}
                  currentTeam={currentTeam}
                  squads={squads}
                  users={users}
                  onEntityOwnerChange={this.onChangeEntityOwner}
                />
              </Grid>
              {errorObj.owner && <ErrorBlock>Runbook should have owner.</ErrorBlock>}
            </>
            <br />
            <>
              <Grid flexWidth={12} alignItems="flex-start" justifyContent="space-between">
                <Label htmlFor="steps">Steps</Label>
                <SwitchBlock
                  options={this.switchOptions}
                  selected={[contentType]}
                  className="ml-5"
                  onSelectValue={this.onTypeChange}
                />
              </Grid>
              <br />
              {errorObj.steps && (
                <ErrorBlock className="runbook_step_empty_err">
                  Runbook should have atleast one step.
                </ErrorBlock>
              )}
              {this.renderSteps()}
            </>
          </div>
        )}
      </>
    );
  };

  renderSteps = () => {
    const { tableData, contentType, errorObj } = this.state;
    const { runbookId } = this.props;
    const hasUpdate = this.props.userAccess.hasUpdateAccess('runbooks', runbookId) && !!runbookId;
    const hasCreate = this.props.userAccess.hasCreateAccess('runbooks') && !runbookId;
    return (
      <RunbookSteps
        items={tableData}
        fileUploadService={this.fileUploadService}
        updateState={this.upDateState}
        currentState={contentType}
        errorMsg={errorObj}
        hasUpdate={!!hasUpdate}
        hasCreate={!!hasCreate}
        showSnackBar={(msg, bg, timeout) => {
          this.props.showSnackBar(msg, bg, timeout);
        }}
      />
    );
  };

  renderAddStep = () => {
    const { theme } = Theme;
    const { runbookId } = this.props;
    const hasUpdate = this.props.userAccess.hasUpdateAccess('runbooks', runbookId) && !!runbookId;
    const hasCreate = this.props.userAccess.hasCreateAccess('runbooks') && !runbookId;
    return (
      <Grid flexWidth={12} alignItems="center" justifyContent="center" className="mb-20">
        <NoPermissionTooltip isDisabled={runbookId ? hasUpdate : hasCreate}>
          <TextButton
            buttonType="ghost"
            onClick={this.addStep}
            disabled={runbookId ? !hasUpdate : !hasCreate}
          >
            <Para fontWeight={500} color={theme.primary.default}>
              &#43; Add Steps
            </Para>
          </TextButton>
        </NoPermissionTooltip>
      </Grid>
    );
  };

  addStep = () => {
    this.setState({
      tableData: [...this.state.tableData, { content: '', type: this.state.contentType }],
    });
  };

  upDateState = (tableData: Array<{ content: string; type: string }>) => {
    this.setState({ tableData });
  };

  onTypeChange = (type: string) => {
    this.setState({ contentType: type });
  };

  renderSave = () => {
    const { theme } = Theme;
    const { componentState } = this.state;
    const { runbookId } = this.props;
    const hasUpdate = this.props.userAccess.hasUpdateAccess('runbooks', runbookId) && !!runbookId;
    const hasCreate = this.props.userAccess.hasCreateAccess('runbooks') && !runbookId;

    return (
      <Grid
        flexWidth={12}
        className="mt-20 mb-20"
        alignItems="center"
        justifyContent="center"
        type="column"
      >
        <TextButton
          className="mt-20"
          height="56px"
          width="80%"
          onClick={this.checkForEmpty}
          disabled={runbookId ? !hasUpdate : !hasCreate}
        >
          <NoPermissionTooltip isDisabled={runbookId ? hasUpdate : hasCreate}>
            {componentState === componentStateConst.BUSY ? (
              <PulseLoader color={theme.shades.grey} />
            ) : (
              <Para fontWeight={500} color={theme.shades.white}>
                {runbookId ? runbookAction.SAVE : `${runbookAction.CREATE} Runbook`}
              </Para>
            )}
          </NoPermissionTooltip>
        </TextButton>
        {componentState !== componentStateConst.BUSY && (
          <Link to="/runbooks" className="runbook_display_blk" style={{ textDecoration: 'none' }}>
            <TextButton buttonType="ghost" className="mt-20 mb-20">
              <Para fontWeight={500}>Cancel</Para>
            </TextButton>
          </Link>
        )}
      </Grid>
    );
  };

  compareStepsForChange = (left: Array<IDataItem>, right: Array<IDataItem>): boolean => {
    const compareFunction = (leftValue: IDataItem, rightValue: IDataItem) =>
      leftValue.content == rightValue.content;

    const hasChanged = left.filter((step, index) => {
      return !compareFunction(step, right[index]);
    });
    return !!hasChanged.length;
  };

  checkDirty = (): boolean => {
    const { tableData: curr, title: currTitle } = this.state;
    const { name: preTitle, steps: pre } = this.runbookDetails;

    if (currTitle !== preTitle) return true;
    if (pre.length !== curr.length) return true;
    if (pre.length === curr.length) return this.compareStepsForChange(curr, pre);

    return false;
  };

  checkForEmpty = () => {
    const { title, tableData, owner } = this.state;
    const hasEmptySteps = tableData
      .map(({ content }: IDataItem, index) => {
        if (content.length <= 0) return index;
      })
      .filter(_ => _ !== undefined);

    const isEmpty = tableData.length === 0;
    const hasNoOwner = owner.id.length === 0;
    const hasNoTitle = title.length === 0;

    isEmpty || !!hasEmptySteps.length || hasNoTitle || hasNoOwner
      ? this.setState({
          errorObj: {
            title: hasNoTitle,
            steps: isEmpty,
            step: hasEmptySteps,
            owner: hasNoOwner,
          },
        })
      : this.save();
  };

  renderAddRunbookForm = () => {
    const { isGuardReq, componentState } = this.state;
    return (
      <div className="runbook_container_main">
        {this.renderHeader()}
        {this.renderBody()}
        {componentState !== componentStateConst.INIT && this.renderAddStep()}
        {componentState !== componentStateConst.INIT && this.renderSave()}
        {isGuardReq && <UnsavedChangesGuard checkDirty={this.checkDirty}></UnsavedChangesGuard>}
      </div>
    );
  };

  render() {
    const hasRead = this.props.userAccess.hasReadAccess('runbooks');
    if (hasRead) {
      return <AppFrame>{this.renderAddRunbookForm()}</AppFrame>;
    } else {
      return <RenderForNoRunbook />;
    }
  }
}

export default connect((state: IAppState) => ({
  organization: state.organization,
}))(withUserAccess(withCompareGlobalAndEntityTeamId(withRouter(RunbookCreateOrUpdate as any))));
