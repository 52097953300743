import React from 'react';
import { CalendarDayData, ResponsiveCalendar } from '@nivo/calendar';
import { IAnalyticsPanel, IAnalyticsPanelData } from '../../../../../core/interfaces/IAnalytics';
import { useAnalyticsContext } from '../analyticsContext';
import { convertDateString } from '../../../../../core/const/analytics';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_GS_ANALYTICS_PAGE_HEAT_MAP_CLICKED } from '../../../../../core/const/tracker';

interface IProps {
  panel: IAnalyticsPanel;
  data: IAnalyticsPanelData;
}

const HeatMap: React.FC<IProps> = ({ data }) => {
  const {
    updateConfig,
    config: {
      timeRange: { lt, gt },
    },
  } = useAnalyticsContext();

  const onDateSelect = (datum: CalendarDayData) => {
    const selectedDate = datum.date;

    if (selectedDate >= new Date(new Date(gt).setHours(0, 0, 0, 0)) && selectedDate <= lt) {
      updateConfig({
        selectedDate,
      });
    }
    AppTracker.track(T_WA_GS_ANALYTICS_PAGE_HEAT_MAP_CLICKED);
  };

  return (
    <ResponsiveCalendar
      data={data.data}
      from={convertDateString(gt)}
      to={convertDateString(lt)}
      emptyColor="#eeeeee"
      colors={['#9ECBE0', '#6AAED6', '#4291C5', '#2271B5', '#0B519C']}
      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
      monthBorderColor="#ffffff"
      dayBorderWidth={2}
      dayBorderColor="#ffffff"
      onClick={onDateSelect}
    />
  );
};

export default HeatMap;
