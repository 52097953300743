import { AssigneeType } from 'library/enums';
import { generatePath, Link } from 'react-router-dom';
import {
  ESCALATION_POLICIES_LIST_PATH,
  TEAMS_PATH,
  USER_PROFILE_PATH,
} from 'views/main/routes/routes';
import { Icon, Text } from '@chakra-ui/react';

import { truncate } from '.';
import { Tooltip } from 'library/atoms';
import { API } from 'core/api';

const getNameComponent = (name: string, maxLength = 20, type?: AssigneeType) => {
  const label = (
    <Text variant="body" color="secondary.1000">
      {truncate(name, maxLength)}
    </Text>
  );
  return name.length > maxLength && type !== AssigneeType.User ? (
    <Tooltip label={name} placement="bottom">
      {label}
    </Tooltip>
  ) : (
    label
  );
};

export const getAssigneeComponent = (id: string, type: AssigneeType, name: string) => {
  let assigneeLink = '';

  switch (type) {
    case AssigneeType.User:
      assigneeLink = generatePath(USER_PROFILE_PATH, { id });
      break;
    case AssigneeType.Team:
      assigneeLink = generatePath(TEAMS_PATH, { id: id });
      break;
    case AssigneeType.Escalationpolicy:
      assigneeLink = generatePath(ESCALATION_POLICIES_LIST_PATH, { id });
  }

  const nameComp = getNameComponent(name, 15, type);

  return <Link to={assigneeLink}>{nameComp}</Link>;
};
