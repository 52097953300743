import { ExtensionService } from 'core';
import React from 'react';
import { useQuery } from 'react-query';
import {
  reactQueryConfigError,
  reactQueryConfigWarning,
} from '../../jira-cloud/helpers/service.reactqery';

async function GetSlackConfig() {
  const _extensionService = new ExtensionService();
  const { data } = await _extensionService.getSlackConfiguration();
  return data.data;
}

async function GetTeamSlackConfig() {
  const _extensionService = new ExtensionService();
  const { data } = await _extensionService.getSlackV2Team();
  return data.data;
}

const useGetSlackConfig = () => {
  return useQuery('slack-configuration', () => GetSlackConfig(), {
    retry: 1,
    staleTime: 0,
    keepPreviousData: true,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      const description: string =
        error?.response?.data?.meta?.error_message || 'error connecting to server';
      description.includes('channel not selected for organization')
        ? reactQueryConfigWarning({ description: description, title: 'Slack configuration' })
        : reactQueryConfigError({ description: description, title: 'Slack configuration' });
    },
  });
};

const useGetSlackTeamConfig = () => {
  return useQuery('slack-org-configuration', () => GetTeamSlackConfig(), {
    retry: 1,
    staleTime: 0,
    keepPreviousData: true,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      const description =
        error?.response?.data?.meta?.error_message || 'error connecting to server';
      reactQueryConfigError({ description: description, title: 'Slack workspace' });
    },
  });
};

export { useGetSlackConfig, useGetSlackTeamConfig };
