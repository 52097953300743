import React, { ReactNode, ReactElement } from 'react';

const hasChildren = (children: ReactNode | ReactElement) => {
  try {
    return (
      React.Children.count(children) > 0 &&
      React.Children.map(children, child => !!child)!.filter(Boolean).length > 0
    );
  } catch (_) {
    return false;
  }
};

export default hasChildren;
