import * as React from 'react';
import { SVGProps } from 'react';

const SvgMicrosoftLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#f25022',
      }}
      d="M1 1h8.55v8.55H1z"
    />
    <path
      style={{
        fill: '#7fba00',
      }}
      d="M10.45 1H19v8.55h-8.55z"
    />
    <path
      style={{
        fill: '#00a4ef',
      }}
      d="M1 10.45h8.55V19H1z"
    />
    <path
      style={{
        fill: '#ffb900',
      }}
      d="M10.45 10.45H19V19h-8.55z"
    />
  </svg>
);

export default SvgMicrosoftLogo20X20;
