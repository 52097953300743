import {
  REQUEST_ORG_TEAMS,
  REQUEST_ORG_TEAMS_SUCCESS,
  REQUEST_ORG_TEAMS_FAIL,
} from '../../const/organization';
import { ITeam } from '../../interfaces/ITeams';

const requestOrganizationTeams = () => ({
  type: REQUEST_ORG_TEAMS,
  payload: {},
});

const onRequestOrganizationTeamsSuccess = (teams: ITeam[]) => ({
  type: REQUEST_ORG_TEAMS_SUCCESS,
  payload: {
    teams,
  },
});

const onRequestOrganizationTeamsFail = (error: any) => ({
  type: REQUEST_ORG_TEAMS_FAIL,
  payload: {
    error,
  },
});

export {
  requestOrganizationTeams,
  onRequestOrganizationTeamsSuccess,
  onRequestOrganizationTeamsFail,
};
