import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ChakraComponent,
  AspectRatio,
  Center,
  Text,
} from '@chakra-ui/react';
import { Heading } from 'uie/components';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const VideoModel = ({ isOpen, onClose }: IProps) => {
  return (
    <Modal onClose={onClose} size="6xl" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>SLO Tracker</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AspectRatio ratio={2} bgColor="gray.300">
            <iframe title="slo" src="https://www.youtube.com/embed/qwGYc7m67O8" allowFullScreen />
          </AspectRatio>
        </ModalBody>
        <ModalFooter>
          <Button
            fontWeight="medium"
            bgColor={'rgba(15, 97, 221, 1)'}
            color={'white'}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
