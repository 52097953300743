import { HStack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { truncate } from 'core/helpers/stringUtils';
import { Tag, Tooltip } from 'library/atoms';

export const FilterFormTags = ({
  values,
  isSingle,
  onClose,
}: {
  values: Array<{ value: string; label: string }>;
  isSingle?: boolean;
  onClose: (id: string) => void;
}) => {
  const tagValue = values;

  return (
    <>
      {tagValue.length ? (
        <Wrap mt={3}>
          {tagValue.map(
            ({
              value,
              label,
              username,
            }: {
              value: string;
              label: string;
              username?: string | null;
              type?: string;
            }) => {
              return (
                <WrapItem key={value?.toString()}>
                  <Tag
                    size="md"
                    variant="filter"
                    label={label}
                    title={
                      <HStack>
                        <Text fontWeight={'bold'}>{truncate(label, 40)}</Text>
                      </HStack>
                    }
                    onClose={() => {
                      onClose(isSingle ? null : values.filter((obj: any) => obj.value != value));
                    }}
                  />
                </WrapItem>
              );
            },
          )}
        </Wrap>
      ) : null}
    </>
  );
};
