import styled from 'styled-components';
interface IProps {
  fontSize?: 16 | 14 | number;
  fontFamily?: string | 'props.theme.fontFamily';
  height?: '24px' | '16px' | 'fit-content';
  fontWeight?: 400 | 500 | number;
}

const Para = styled.p<IProps>`
  color: ${props => props.color || props.theme.font.default};
  font-family: ${props => props.fontFamily || props.theme.fontFamily};
  font-size: ${props => props.fontSize || 16}px;
  line-height: ${props => props.height || 'fit-content'};
  font-weight: ${props => props.fontWeight || 400};
  text-align: left;
  height: auto;
  margin: 0;
`;

/** @component */
export default Para;
