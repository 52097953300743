import React from 'react';
import { HStack, Avatar, Text, VStack } from '@chakra-ui/react';
import { components } from 'react-select';

import { getTZDifference, getTZFormat } from '../../helpers/helpers.date';
import { Locale } from 'core/helpers/dateUtils';
import SQTooltip from 'components/chakra/Tooltip';
import momentTimeZone from 'moment-timezone';
import { THEME_COLORS } from 'library/theme/colors';
import { truncate } from 'core/helpers/stringUtils';

interface Props {
  componentType: 'single' | 'multi';
  showTZInTooltip?: boolean;
  showTooltip?: boolean;
}

export const ParticipantValue =
  ({ componentType, showTZInTooltip = false, showTooltip = true }: Props) =>
  (props: any) => {
    const { label, timeZone, username, type } = props.data;
    const filteredMomentTZ = momentTimeZone.tz.names().find(tz => tz === timeZone) ?? '';
    const timezoneOffset = getTZFormat(filteredMomentTZ, 'ZZZZ');
    const { relativeString } = getTZDifference(timeZone, Locale.userZone);
    const tooltipText = (
      <VStack alignItems="flex-start">
        <Text maxW="30ch" color={THEME_COLORS.secondary[1000]} fontWeight="bold" m="0px !important">
          {label}
        </Text>
        <Text maxW="30ch" color={THEME_COLORS.secondary[600]} m="0px !important">
          {username}
        </Text>
        {type === 'user' && timeZone && showTZInTooltip && (
          <Text m="0px !important">
            {timezoneOffset && `${timezoneOffset} | ${timeZone}`}
            {relativeString && ` | ${relativeString}`}
          </Text>
        )}
      </VStack>
    );

    const Wrapper = ({ children }: { children: JSX.Element }) =>
      componentType === 'single' ? (
        <components.SingleValue {...props}>{children}</components.SingleValue>
      ) : (
        <components.MultiValue {...props}>{children}</components.MultiValue>
      );

    const labelMaxLength = componentType === 'single' ? 45 : 20;
    const usernameMaxLength = componentType === 'single' ? 20 : 10;

    return (
      <Wrapper>
        <SQTooltip text={tooltipText} isDisabled={!showTooltip}>
          <HStack>
            <Avatar size="xs" name={label} />
            <HStack ml="5px" justifyContent="center" alignItems="center">
              <Text size="sm" maxW={`${labelMaxLength}ch`} color={THEME_COLORS.secondary[1000]}>
                {truncate(label, labelMaxLength)}
              </Text>
              {type === 'user' && username && (
                <Text
                  size="sm"
                  ml={'var(--chakra-space-1) !important'}
                  maxW={`${usernameMaxLength}ch`}
                  color={THEME_COLORS.secondary[1000]}
                >
                  ({truncate(username, usernameMaxLength)})
                </Text>
              )}
            </HStack>
          </HStack>
        </SQTooltip>
      </Wrapper>
    );
  };
