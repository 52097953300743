import { Box, Button, Text } from '@chakra-ui/react';
import { CheckBox, ErrorText, FocusBlock, Row } from '@squadcast/alchemy-ui/carbon';
import { SelectBox } from '@squadcast/alchemy-ui/metal';
import { Grid, Para } from 'uie/components';
import { stringShortener } from 'core/helpers/stringUtils';
import React from 'react';
import { Link } from 'react-router-dom';
import shortid from 'shortid';

import { JiraCloudExtensionModal } from '.';

const SelectBoxContainer: React.FC<{ className?: string; children: React.ReactNode }> = ({
  children,
  className,
}) => (
  <Box className={className} sx={{ '& img': { display: 'inline-block' } }}>
    {children}
  </Box>
);
function renders(this: JiraCloudExtensionModal) {
  const {
    componentTabState,
    jiraProjects,
    projectIssues,
    projectSearchString,
    issuesSearchString,
    accountSearchString,
    isCreated,
    loading,
  } = this.state;
  return (
    <div>
      {!isCreated && (
        <Grid alignItems="center" justifyContent="flex-start">
          <Text>Looks like there is no Jira Cloud integration configured yet!</Text>
          <Link className="ml-10" to="/settings/extension">
            Configure here
          </Link>
        </Grid>
      )}

      {isCreated && componentTabState === 'project' && (
        <div>
          <SelectBoxContainer>
            <Text mb={1} fontWeight={500} fontSize={14} fontStyle="normal">
              Select Jira Account
            </Text>
            <SelectBox
              id="Jira_account"
              name="Jira Account"
              label="Jira Account"
              onChange={this.onAccountSelect}
              selected={
                this.state.selectedAccount.id !== '' ? (
                  <div>{stringShortener(this.state.selectedAccount.name, 50)}</div>
                ) : (
                  <div>Please Select an Account</div>
                )
              }
              enableSearchBox={true}
              searchText={this.state.accountSearchString}
              onSearchValueChange={searchValue => {
                this.setState({ accountSearchString: searchValue });
              }}
            >
              {this.state.jiraAccounts
                .filter(account =>
                  accountSearchString.length > 0
                    ? account.account_name
                        .toLowerCase()
                        .indexOf(accountSearchString.toLowerCase()) > -1
                    : true,
                )
                .map((account, index: number) => (
                  <FocusBlock value={{ ...account, key: 'ok' }} key={shortid.generate()}>
                    <Text className="item-box-tagline" m={0}>
                      {stringShortener(account.account_name, 50)}
                    </Text>
                  </FocusBlock>
                ))}
            </SelectBox>
          </SelectBoxContainer>
          <SelectBoxContainer className="mt-20">
            <Text className="item-box-tagline font-bold m-0" mb={5}>
              Select a Jira Project
            </Text>
            <SelectBox
              id="Jira_projects"
              name="Jira projects"
              label="Jira projects"
              onChange={this.onProjectSelect}
              selected={
                this.state.selectedProject.id !== '' ? (
                  <div>{this.state.selectedProject.name}</div>
                ) : (
                  <div>Please Select a project</div>
                )
              }
              enableSearchBox={true}
              searchText={this.state.projectSearchString}
              onSearchValueChange={searchValue => {
                this.setState({ projectSearchString: searchValue });
              }}
            >
              {jiraProjects
                ?.filter(project =>
                  projectSearchString.length > 0
                    ? project.name.toLowerCase().indexOf(projectSearchString.toLowerCase()) > -1
                    : true,
                )
                .map((project, index: number) => (
                  <FocusBlock value={project} key={shortid.generate()}>
                    <Text className="item-box-tagline" m={0}>
                      {project.name}
                    </Text>
                  </FocusBlock>
                ))}
            </SelectBox>
          </SelectBoxContainer>
          {this.state.selectedProject?.id?.length > 0 && projectIssues?.length > 0 && (
            <SelectBoxContainer className="mt-20">
              <Text className="item-box-tagline font-bold m-0" mb={5}>
                Select an Issue for {this.state.selectedProject.name}
              </Text>
              <SelectBox
                id="jira_project_issue"
                name="Jira projects issues"
                label="Jira projects issues"
                onChange={this.onIssueSelect}
                selected={
                  this.state.selectedIssue.id !== '' ? (
                    <div>{this.state.selectedIssue.name}</div>
                  ) : (
                    <div>Please Select Project Issue</div>
                  )
                }
                enableSearchBox={true}
                searchText={this.state.issuesSearchString}
                onSearchValueChange={searchValue => {
                  this.setState({ issuesSearchString: searchValue });
                }}
              >
                {projectIssues
                  .filter(issues =>
                    issuesSearchString.length > 0
                      ? issues.name.toLowerCase().indexOf(issuesSearchString.toLowerCase()) > -1
                      : true,
                  )
                  .map((issue, index: number) => (
                    <FocusBlock value={issue} key={shortid.generate()}>
                      <Text className="item-box-tagline" m={0}>
                        {issue.name}
                      </Text>
                    </FocusBlock>
                  ))}
              </SelectBox>
            </SelectBoxContainer>
          )}
          <div className="mt-20">
            <Button
              id="to_projects"
              variant="default"
              disabled={this.state.selectedIssue.id.length === 0}
              onClick={() => this.setState({ componentTabState: 'map' })}
              size="md"
            >
              Next
            </Button>
            {this.props.service?.extensions.jira_cloud ? (
              <>
                <Button
                  className="ml-10"
                  id="to_services"
                  disabled={this.state.selectedIssue.id.length === 0}
                  backgroundColor="white"
                  border={`1px solid red`}
                  color="red"
                  onClick={() => this.onDeleteJira()}
                  size="md"
                  isLoading={this.state.isDeleting}
                  loadingText="Deleting"
                >
                  Delete
                </Button>
                {this.state.errors.save && this.state.errors.save.length > 0 && (
                  <span className="ml-10">
                    <ErrorText text={`Failed! ${this.state.errors.save}`} />
                  </span>
                )}
              </>
            ) : null}
          </div>
        </div>
      )}
      {componentTabState === 'map' && (
        <div>
          <Row alignItems="center">
            <CheckBox
              id="is_jira_manual"
              name="is manual"
              label="is_manual"
              checked={this.state.isManual}
              onChange={() => {
                this.setState({ isManual: true });
                this.props.checkAndSetDirty();
              }}
            >
              Manually
            </CheckBox>
            <span className="ml-10" />
            <CheckBox
              id="is_jira_automatic"
              name="is manual"
              label="is_manual"
              checked={!this.state.isManual}
              onChange={() => {
                this.setState({ isManual: false });
                this.props.checkAndSetDirty();
              }}
            >
              Automatic
            </CheckBox>
          </Row>
          <div>
            <Text className="item-box-tagline font-bold ma-0" mb={5}>
              Map Jira Issue to Squadcast Status
            </Text>
          </div>

          <div>
            {this.state.selectedIssue.id.length === 0 && (
              <div>
                <Text>Please select a Jira project and a relevant Issue Map</Text>
              </div>
            )}
            {this.state.selectedIssue.id.length !== 0 && this.state.issuesMap.length === 0 && (
              <div>
                <Text>Please Add default issue status to your Jira project</Text>
              </div>
            )}
            {this.state.issuesMap.map((issue, index) => {
              return (
                <div className="mt-10" key={index}>
                  <Row flexWidth={12} justifyContent="center" alignItems="center">
                    <Row flexWidth={6}>{issue.jira_status}</Row>
                    <Row flexWidth={6}>
                      <SelectBoxContainer className="w-1-1">
                        <SelectBox
                          id="jira_issue_to_status_map"
                          name="issue Map"
                          label="issue Map"
                          onChange={(_, value) => this.onIssueMapSelect(value, index)}
                          selected={
                            this.state.selectedIssue.id !== '' ? (
                              <div>
                                {issue.incident_status.charAt(0).toUpperCase() +
                                  issue.incident_status.slice(1)}
                              </div>
                            ) : (
                              <div>Please Map status</div>
                            )
                          }
                        >
                          {this.incidentStatus.map((status, index: number) => (
                            <FocusBlock value={status} key={shortid.generate()}>
                              <Text className="item-box-tagline" m={0}>
                                {status.charAt(0).toUpperCase() + status.slice(1)}
                              </Text>
                            </FocusBlock>
                          ))}
                        </SelectBox>
                      </SelectBoxContainer>
                    </Row>
                  </Row>
                </div>
              );
            })}
          </div>

          <div className="mt-20">
            <Row alignItems="center">
              <Button
                id="to_services"
                disabled={this.state.selectedIssue.id.length === 0}
                variant="default"
                onClick={() => this.saveIntegration()}
                size="md"
                isLoading={loading}
                loadingText="Save"
              >
                {this.props.service?.extensions.jira_cloud ? 'Update' : ' Save'}
              </Button>
              {this.state.updated && <span className="ml-10">Saved!</span>}
              {this.state.errors.save && this.state.errors.save.length > 0 && (
                <span className="ml-10">
                  <ErrorText text={`Failed! ${this.state.errors.save}`} />
                </span>
              )}
              {this.props.service?.extensions.jira_cloud ? (
                <>
                  <Button
                    className="ml-10"
                    id="to_services"
                    backgroundColor="white"
                    border={`1px solid red`}
                    color="red"
                    onClick={() => this.onDeleteJira()}
                    size="md"
                    isLoading={this.state.isDeleting}
                    loadingText="Deleting"
                  >
                    Delete
                  </Button>
                  {this.state.errors.save && this.state.errors.save.length > 0 && (
                    <span className="ml-10">
                      <ErrorText text={`Failed! ${this.state.errors.save}`} />
                    </span>
                  )}
                </>
              ) : null}
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}

export default renders;
