import * as React from 'react';
import { SVGProps } from 'react';

const SvgChecklyLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Checkly_Logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.Checkly_Logo-20x20_svg__st0,.Checkly_Logo-20x20_svg__st1,.Checkly_Logo-20x20_svg__st2,.Checkly_Logo-20x20_svg__st3{fill-rule:evenodd;clip-rule:evenodd;fill:#828d8c}.Checkly_Logo-20x20_svg__st1,.Checkly_Logo-20x20_svg__st2,.Checkly_Logo-20x20_svg__st3{fill:#121716}.Checkly_Logo-20x20_svg__st2,.Checkly_Logo-20x20_svg__st3{fill:#ededea}.Checkly_Logo-20x20_svg__st3{fill:#fffffe}'
      }
    </style>
    <path
      className="Checkly_Logo-20x20_svg__st0"
      d="M19.1 4c-.1-.1-.1-.2-.3-.3-.1-.1-.3-.1-.4-.1-2.1.4-4.3 2-5.6 4.5-1.4 2.7-1.4 5.7-.3 7.7 2.3-.2 4.7-1.9 6.1-4.6C19.9 8.8 20 6 19.1 4"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M18.4 3.9c-2.1.4-4.2 2-5.4 4.4-1.3 2.4-1.4 5.3-.4 7.3 2.2-.3 4.4-2 5.7-4.4 1.2-2.3 1.4-5 .5-7 0-.1-.1-.1-.2-.2.1-.1 0-.1-.2-.1.1 0 0 0 0 0zm-5.9 12.2c-.1 0-.2-.1-.2-.1-1.2-2.2-1.1-5.2.3-8 1.3-2.5 3.5-4.2 5.8-4.7h.1c.2 0 .4 0 .6.1.2.1.3.2.4.4 1 2.2.8 5-.5 7.5-1.5 2.8-4 4.6-6.5 4.8z"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M18.1 5.2c.6.3 1.1.7 1.6 1.2-.1-.8-.2-1.6-.6-2.3-.1-.1-.1-.2-.3-.3-.1-.1-.3-.1-.4-.1-.7.1-1.5.4-2.2.8.7.1 1.3.4 1.9.7M14.8 5.8c-.6.6-1.1 1.2-1.6 1.9 1.1-.1 2.2.2 3.3.8 1.1.6 1.8 1.5 2.3 2.5.4-.8.6-1.6.9-2.4-.5-.7-1.3-1.4-2.1-1.9-1-.5-1.9-.8-2.8-.9M12.1 9.2c-.4.7-.7 1.4-.9 2.1 1 0 2 .2 3 .8.9.6 1.4 1.5 1.8 2.4.8-.4 1.3-1.2 1.7-1.9-.5-1-1.3-1.8-2.3-2.4-1.2-.6-2.3-.9-3.3-1"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st0"
      d="M4.3 1.5c.9.9 1.1 2.1.5 2.7-.6.6-1.8.4-2.7-.5C1.2 2.8 1 1.5 1.6.9c.6-.6 1.8-.3 2.7.6"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M3.7 2.1c.4.5.6 1 .3 1.3-.2.3-.8.1-1.3-.3-.4-.5-.6-1.1-.3-1.4.3-.2.9-.1 1.3.4"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M2.5.9c-.2 0-.5 0-.7.3-.5.4-.2 1.5.5 2.3.5.5 1.1.8 1.7.8.2 0 .5 0 .7-.3.2-.2.3-.6.2-1-.1-.5-.4-.9-.8-1.3C3.6 1.2 3 .9 2.5.9zM4 4.8c-.7 0-1.4-.4-2-1C1 2.8.7 1.4 1.5.7c.2-.2.5-.4 1-.4.7 0 1.4.4 2 1 .5.4.8 1 .9 1.6.1.6 0 1.1-.4 1.5-.2.3-.6.4-1 .4z"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st0"
      d="M12.5 1.5c-.9.9-1.1 2.1-.5 2.7.6.6 1.8.4 2.7-.5.9-.9 1.1-2.1.5-2.7-.6-.7-1.8-.4-2.7.5"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M13.1 2.1c-.4.5-.6 1-.3 1.3.3.3.9.1 1.3-.3.4-.5.6-1 .3-1.3-.2-.3-.8-.2-1.3.3"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M12.7 1.7c-.4.4-.6.8-.7 1.3-.1.4 0 .8.2 1 .2.2.5.3.7.3.5 0 1.1-.3 1.6-.8.8-.8 1-1.9.5-2.3-.2-.2-.5-.3-.7-.3-.5 0-1.1.3-1.6.8zm-.9 2.7c-.4-.4-.5-.9-.4-1.5.1-.6.4-1.1.9-1.6.6-.6 1.3-1 2-1 .4 0 .8.1 1.1.4.7.7.5 2.1-.5 3.1-.6.6-1.3 1-2 1-.5 0-.8-.1-1.1-.4z"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st0"
      d="M14.5 3.7c-1.4-2-3.6-3.4-6-3.4s-4.6 1.4-6 3.4C.8 6.1-.2 9.5.8 12.4c.9 2.4 2.9 4.3 5.4 5 .8.2 1.6.3 2.3.3.8 0 1.6-.1 2.3-.3 2.4-.7 4.5-2.6 5.4-5 1-2.9 0-6.3-1.7-8.7"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st2"
      d="M15 4.8c-.1-.4-.3-.8-.6-1.1-.3-.4-.6-.8-1-1.2-1.3-.6-2.7-.6-3.8.1-.6.4-1 .9-1.2 1.6-.2-.6-.6-1.1-1.2-1.5-1.1-.8-2.6-.8-3.8-.1-.4.3-.7.7-1 1.1-.3.3-.5.7-.6 1.1-.1.4-.2 1-.2 1.4 0 .7.4 1.2 1 1.5.1.1 1.5 1.2 1.4 1.3.1-.1.2-.1.4-.2v.1c.5-.3 1.3-.7 1.8-.6.1 0 .3.1.4.2.2.3.2.6.2.9.1 0 .1-.1.2-.1 0 .2-.1.4-.1.4h-.2c.6.1 1.1.2 1.7.2.6 0 1.2-.1 1.7-.2l-.1-.1s0-.2-.1-.4c.1 0 .1.1.2.1-.1-.3-.1-.6.2-.9.1-.1.2-.2.4-.2.5-.1 1.3.3 1.8.6.1.1.3.1.4.2-.1-.1 1.3-1.2 1.4-1.3.5-.4 1-.9 1-1.5 0-.4-.2-1-.3-1.4M8.4 11c-2.1 0-3.7 2.1-3.7 4.7 0 .5.1 1 .2 1.5.2.1.5.1.7.2.9.2 1.9.3 2.9.3s1.9-.1 2.9-.3c.2-.1.5-.1.7-.2.1-.5.2-1 .2-1.5-.1-2.6-1.8-4.7-3.9-4.7"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M8.8 6.8c-.1 0-.2 0-.3.1v-.5h.1l.3-.4c.1-.1.1-.2-.1-.2H8c-.1 0-.2.1-.1.2l.3.4.1.1v.3c-.1 0-.2 0-.3-.1-.1 0-.4-.2-.4 0 0 .4.4.8.8.8s.8-.4.8-.8c0-.1-.2 0-.4.1"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M8.4.6C6.2.6 4.1 1.8 2.6 3.9.7 6.5.1 9.8.9 12.3c.8 2.3 2.8 4.1 5.2 4.8 1.5.4 3.1.4 4.5 0 2.4-.7 4.4-2.5 5.2-4.8.9-2.5.2-5.8-1.7-8.4C12.7 1.8 10.6.6 8.4.6zm0 17.4c-.8 0-1.6-.1-2.4-.3-2.6-.8-4.7-2.7-5.6-5.2-1.1-3.2.1-6.7 1.7-8.9C3.8 1.3 6 0 8.4 0s4.7 1.3 6.3 3.5c1.6 2.2 2.9 5.7 1.7 8.9-.9 2.5-3 4.4-5.6 5.2-.7.3-1.6.4-2.4.4z"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M6.2 3.6c-.2-.1-.5-.2-.7-.2-.8 0-1.6.6-2 1.1-.6.7-1.2 2.2 0 2.8.5.1.9.2 1.4.2.6 0 1.2 0 1.7-.4s.6-1.2.5-1.8c0-.8-.3-1.4-.9-1.7"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st3"
      d="M6.2 4.9c0-.1-.1-.2-.2-.3-.1-.1-.3-.2-.5-.2h-.2c-.2.1-.4.2-.6.4 0 .1-.1.2-.1.3 0 .1.1.1.2.1s.1 0 .2-.1c.1-.2.3-.3.6-.3.1 0 .2 0 .3.1.1.1.2.2.3.1 0 0 .1-.1 0-.1"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M10.6 3.6c.2-.1.5-.2.8-.2.8 0 1.5.7 1.9 1.1.6.7 1.2 2.2 0 2.8-.4.2-.9.2-1.4.3-.6 0-1.2 0-1.7-.4-.5-.6-.6-1.3-.5-1.9 0-.8.4-1.4.9-1.7"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st3"
      d="M10.6 4.9c0-.1.1-.2.2-.3.2-.1.3-.2.5-.2h.2c.3.1.5.2.6.4 0 .1.1.2.1.3 0 .1-.1.1-.2.1s-.1 0-.2-.1c-.1-.2-.3-.3-.6-.3-.1 0-.2 0-.3.1 0 .1-.1.2-.2.1-.1 0-.1-.1-.1-.1"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M3 9.9c-.1 0-.1 0-.2-.1s-.1-.3 0-.4c.1-.1.6-.6 1-.8.1-.1.3 0 .4.1.1.1 0 .3-.1.4-.3.2-.8.6-.9.7-.1.1-.1.1-.2.1"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M6.3 8.2c-.8-.5-2 .1-2.4.4-.3-.2-.6-.4-.9-.7-.3-.3-.5-.5-.7-.9-.1-.1-.2-.1-.3-.1-.3 0-.3.3-.2.5.4.6.8 1 1.4 1.4.3.2.4.3.6.4h.3c.3-.3 1.4-.9 1.9-.6.2.2.3.4.4.6 0 .4-.3 1-1 1.5-.6.5-1.8.6-2.7.3-.7-.2-1.6-.7-2.1-1.9 0-.2-.2-.2-.3-.2-.2.1-.2.2-.2.4.4 1.1 1.3 1.9 2.4 2.3.4.1.8.2 1.2.2.8 0 1.5-.2 2-.6.8-.6 1.2-1.3 1.2-1.9 0-.5-.2-.9-.6-1.1M13.8 9.9c.1 0 .1 0 .2-.1s.1-.3 0-.4c-.1-.1-.6-.6-1-.8-.1-.1-.3 0-.4.1-.1.1 0 .3.1.4.3.1.7.5.9.7.1.1.2.1.2.1"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M10.6 8.2c.8-.5 1.9.2 2.3.4.3-.2.6-.4.9-.7.3-.3.5-.5.7-.9.1-.1.1-.1.2-.1.3 0 .3.3.2.5-.4.6-.8 1-1.4 1.4-.1.2-.3.3-.5.4h-.3c-.3-.2-1.4-.8-1.9-.5-.2.1-.4.3-.4.6 0 .4.3 1 1 1.5.6.5 1.8.6 2.7.3.7-.2 1.6-.7 2.1-1.9.1-.1.2-.2.4-.2.1.1.2.2.2.4-.4 1.1-1.3 1.9-2.4 2.3-.4.1-.8.2-1.2.2-.8 0-1.5-.2-2-.6-.8-.6-1.2-1.3-1.2-1.9 0-.6.2-1 .6-1.2"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st0"
      d="M4.3 13.8c.9.9 1.1 2.2.4 2.8-.7.7-1.9.5-2.8-.4-.9-.9-1.1-2.2-.4-2.8.7-.7 1.9-.5 2.8.4"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st2"
      d="M4 14.8c.5.5.6 1.2.2 1.6-.4.4-1.1.3-1.6-.2S2 15 2.4 14.6c.4-.3 1.1-.3 1.6.2"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M2.5 13.2c-.3 0-.6.1-.8.3-.3.3-.4.6-.3 1.1.1.5.3 1 .7 1.4.8.8 1.9 1 2.4.4.3-.3.4-.6.3-1.1-.1-.5-.3-1-.7-1.4-.4-.4-1-.7-1.6-.7zm1.3 4.1c-.7 0-1.4-.3-2-.9-.5-.5-.8-1.1-.9-1.7-.1-.6.1-1.2.4-1.6.3-.3.7-.5 1.2-.5.7 0 1.4.3 2 .9.5.5.8 1.1.9 1.7.1.6-.1 1.2-.4 1.6-.3.3-.8.5-1.2.5z"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st0"
      d="M12.5 13.8c-.9.9-1.1 2.2-.4 2.8.7.7 1.9.5 2.8-.4.9-.9 1.1-2.2.4-2.8-.6-.7-1.9-.5-2.8.4"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st2"
      d="M12.8 14.8c-.5.5-.6 1.2-.2 1.6.4.4 1.1.3 1.6-.2s.6-1.2.2-1.6c-.4-.3-1.1-.3-1.6.2"
      transform="translate(0 1)"
    />
    <path
      className="Checkly_Logo-20x20_svg__st1"
      d="M12.7 14c-.4.4-.7.9-.7 1.4-.1.4 0 .8.3 1.1.5.5 1.7.3 2.4-.4.4-.4.7-.9.7-1.4.1-.4 0-.8-.3-1.1-.2-.2-.5-.3-.8-.3-.5-.1-1.1.2-1.6.7zm-.8 2.8c-.4-.4-.5-.9-.4-1.6.1-.6.4-1.2.9-1.7.6-.6 1.3-.9 2-.9.5 0 .9.2 1.2.5.4.4.5.9.4 1.6-.1.6-.4 1.2-.9 1.7-.6.6-1.3.9-2 .9-.5 0-.9-.2-1.2-.5zM10.1.6V.5C9.6.4 9 .3 8.4.3c-.6 0-1.2.1-1.8.2v.1l-.2.1v.2c.1 0 .2-.1.3-.1.2 0 .3.1.5.2.5.4.8.8 1.1 1.3l.1.1c.1 0 .1 0 .1-.1.2-.5.6-1 1.1-1.3.1-.1.3-.2.5-.2.1 0 .2 0 .3.1V.7l-.3-.1z"
      transform="translate(0 1)"
    />
  </svg>
);

export default SvgChecklyLogo20X20;
