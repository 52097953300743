interface IThemePallet {
  default: string;
  light: string;
}

export interface ITheme {
  primary: IThemePallet;
  danger: IThemePallet;
  success: IThemePallet;
  trigger: IThemePallet;
  acknowledge: IThemePallet;
  shades: {
    /** for fonts */
    black: string;
    cement: string;
    /** for borders */
    lightGrey: string;
    grey: string;
    /** for backgrounds */
    smoke: string;
    whiteSmoke: string;
    white: string;
  };
  font: {
    /** or use these */
    white: string;
    disabled: string;
    label: string;
    default: string;
  };
  sidebar: {
    close: string;
    open: string;
  };
  focus: {
    default: string;
  };
  shadow: {
    default: string;
    hover: string;
  };
  background: {
    hover: string;
    press: string;
    disabled: string;
  };
  fontFamily: string;
  monoFontFamily: string;
}

const defaultTheme: ITheme = {
  primary: {
    default: '#0f61dd',
    light: '#f3f7fd',
  },
  danger: {
    default: '#f04546',
    light: '#fef5f6',
  },
  success: {
    default: '#15c26b',
    light: '#f3fcf7',
  },
  trigger: {
    default: '#a951ed',
    light: '#faf6fe',
  },
  acknowledge: {
    default: '#eac407',
    light: '#fefcf2',
  },
  shades: {
    black: '#0d2149',
    cement: '#3A4860',
    lightGrey: '#b6bcc8',
    grey: '#979797',
    smoke: '#dfe1e5',
    whiteSmoke: '#f3f4f6',
    white: '#ffffff',
  },
  focus: {
    default: '2px dashed #0f61dd',
  },
  font: {
    white: '#ffffff',
    disabled: '#b6bcc8',
    label: '#8690a4',
    default: '#0d2149',
  },
  sidebar: {
    open: '#f3f4f6',
    close: '#0d2149',
  },
  shadow: {
    default: '0 1px 1px 0 rgba(66, 66, 66, 0.08), 0 1px 3px 1px rgba(66, 66, 66, 0.16)',
    hover: '0 1px 1px 0 rgba(66, 66, 66, 0.16), 0 1px 3px 1px rgba(66, 66, 66, 0.32)',
  },
  background: {
    press: 'hsla(218, 25%, 30%, 0.7)',
    hover: 'hsla(218, 25%, 30%, 0.5)',
    disabled: 'hsla(218, 25%, 30%, 0.3)',
  },
  fontFamily: 'Mulish, sans-serif',
  monoFontFamily: 'Inconsolata, monospace',
};

function setTheme(config: any) {
  if (CSS.supports('(--primary: red)')) {
    const selector = document.documentElement;
    Object.entries(config).forEach(([shade, value]) => {
      if (typeof value === 'object') {
        Object.keys(value as any).forEach(variation => {
          selector.style.setProperty(
            `--${shade}-${variation}`,
            (value as any)[variation] as string,
          );
        });
      } else {
        selector.style.setProperty(`--${shade}`, value as string);
      }
    });
  }
}

class ThemeComponent {
  private Theme: ITheme = defaultTheme;

  get theme() {
    return this.Theme;
  }

  set theme(theme: ITheme) {
    this.Theme = theme;
    this.init();
  }

  /**
   * extend from the default theme
   * @param theme
   */
  public extend(theme: Partial<ITheme>) {
    this.theme = { ...this.Theme, ...theme };
    this.init();
  }

  /**
   * attach theme attributes to document
   * @default documentElement attaches to document rootNode
   * @param appId application id
   */
  public init() {
    setTheme(this.Theme);
  }
}

const Theme = new ThemeComponent();

/** @component */
export default Theme;
