import React, { useCallback, useMemo, ReactNode } from 'react';
import { FocusBlock, Grid, Para, SelectBox, TextButton, Theme, DropDown, IconButton } from '..';
import { MoreIcon } from '../../utils/icons';
import {
  IAdvancedFilterBlockGroup,
  IAdvancedFilterBlockFilter,
  IAdvancedFilterGroupOperations,
  IAdvancedFilterBlockOptions,
} from './types';
import { availableGroupOperations, inputableList, isInstanceofGroup } from './helper';
import FilterBlock from './filterBlock';
import { v4 } from 'uuid';

const { theme } = Theme;

interface IProps {
  filter: IAdvancedFilterBlockGroup;
  updateFilter: (filter: IAdvancedFilterBlockGroup) => void;
  options: IAdvancedFilterBlockOptions;
  emptyRender: ReactNode;
  /**
   * @description selectDropdown | InputBox height
   */
  cellHeight?: number | 36;
  addBorder?: boolean;
  groupDepth?: number;
}

const AdvancedFilterBlock: React.FC<IProps> = ({
  filter,
  updateFilter,
  options,
  addBorder,
  cellHeight,
  groupDepth,
  emptyRender,
}: IProps) => {
  // this is called recursively so calculate depth for each instance
  const filterDepth = groupDepth ?? 1;

  const emptyFilter: IAdvancedFilterBlockFilter = useMemo(() => {
    const firstFilterOption = Object.keys(options)[0];
    const operation = options[firstFilterOption].operations[0];
    return {
      lhs: firstFilterOption,
      op: operation,
      rhs: inputableList.includes(operation)
        ? ''
        : Object.keys(options[firstFilterOption].options)[0],
    };
  }, [options]);

  const onAddFilter = useCallback(() => {
    const newFilter = { ...filter };
    newFilter.conditions = [...newFilter.conditions, emptyFilter];
    updateFilter(newFilter);
  }, [filter, updateFilter, emptyFilter]);

  const onAddGroup = () => {
    const newFilter = { ...filter };
    newFilter.conditions = [...newFilter.conditions, { op: 'and', conditions: [emptyFilter] }];
    updateFilter(newFilter);
  };

  const onChildGroupUpdate = (idx: number) => (childGroup: IAdvancedFilterBlockGroup) => {
    const newFilter = { ...filter };
    if (childGroup.conditions.length > 0) {
      newFilter.conditions[idx] = childGroup;
    } else {
      newFilter.conditions.splice(idx, 1);
    }
    updateFilter(newFilter);
  };

  const onUpdateGroupOperation = (_: any, op: IAdvancedFilterGroupOperations) => {
    if (op === filter.op) return;
    const newFilter = { ...filter };
    newFilter.op = op;
    updateFilter(newFilter);
  };

  const onDeleteFilter = (idx: number) => () => {
    const newFilter = { ...filter };
    newFilter.conditions.splice(idx, 1);
    updateFilter(newFilter);
  };

  const onConvertFilterToGroup = (idx: number) => () => {
    const newFilter = { ...filter };
    newFilter.conditions[idx] = { op: 'and', conditions: [newFilter.conditions[idx]] };
    updateFilter(newFilter);
  };

  const onFilterUpdate = (idx: number) => (childFilter: IAdvancedFilterBlockFilter) => {
    const newFilter = { ...filter };
    newFilter.conditions[idx] = childFilter;
    updateFilter(newFilter);
  };

  return (
    <Grid
      type="column"
      style={{
        padding: '6px',
        boxShadow: addBorder ? `0 0 0 1px ${theme.shades.lightGrey}` : 'none',
        borderRadius: '4px',
      }}
    >
      {filter.conditions.length === 0 && emptyRender}
      {filter.conditions.map((f, idx) => {
        return (
          <Grid key={v4()} style={{ margin: '6px 4px' }}>
            <Grid style={{ width: '80px' }}>
              {idx === 0 && (
                <Para
                  style={{
                    lineHeight: `${cellHeight ?? 36}px`,
                    width: '100%',
                    textAlign: 'right',
                    paddingRight: '12px',
                  }}
                >
                  Where
                </Para>
              )}
              {idx === 1 && (
                <SelectBox
                  hook={<Para fontSize={16}>{availableGroupOperations[filter.op]}</Para>}
                  onValueChange={onUpdateGroupOperation}
                  height="auto"
                  width="70px"
                  buttonHeight={`${cellHeight ?? 36}px`}
                >
                  {Object.keys(availableGroupOperations).map((o, i) => (
                    <FocusBlock value={o} isSelected={o === filter.op} key={i}>
                      {availableGroupOperations[o as IAdvancedFilterGroupOperations]}
                    </FocusBlock>
                  ))}
                </SelectBox>
              )}
              {idx > 1 && (
                <Para
                  style={{
                    lineHeight: `${cellHeight ?? 36}px`,
                    width: '100%',
                    textAlign: 'right',
                    paddingRight: '12px',
                  }}
                >
                  {availableGroupOperations[filter.op]}
                </Para>
              )}
            </Grid>

            {isInstanceofGroup(f) ? (
              <Grid>
                <AdvancedFilterBlock
                  emptyRender={false}
                  filter={f as IAdvancedFilterBlockGroup}
                  addBorder={true}
                  updateFilter={onChildGroupUpdate(idx)}
                  groupDepth={filterDepth - 1}
                  options={options}
                  cellHeight={cellHeight ?? 36}
                />

                <DropDown
                  width="300px"
                  tabIndex={0}
                  hook={
                    <Grid style={{ marginLeft: '8px' }}>
                      <IconButton>
                        <MoreIcon width="16px" />
                      </IconButton>
                    </Grid>
                  }
                >
                  <div onClick={onDeleteFilter(idx)}>
                    <FocusBlock value="delete" width="150px">
                      Delete
                    </FocusBlock>
                  </div>
                </DropDown>
              </Grid>
            ) : (
              <Grid>
                <FilterBlock
                  filter={f as IAdvancedFilterBlockFilter}
                  options={options}
                  updateFilter={onFilterUpdate(idx)}
                  cellHeight={cellHeight ?? 36}
                />
                <DropDown
                  width="300px"
                  hook={
                    <Grid style={{ marginLeft: '8px' }}>
                      <IconButton>
                        <MoreIcon width="16px" />
                      </IconButton>
                    </Grid>
                  }
                >
                  <div onClick={onDeleteFilter(idx)}>
                    <FocusBlock value="delete" width="150px">
                      Delete
                    </FocusBlock>
                  </div>
                  {filterDepth > 1 && (
                    <div onClick={onConvertFilterToGroup(idx)}>
                      <FocusBlock value="into-group" width="150px">
                        Turn into Group
                      </FocusBlock>
                    </div>
                  )}
                </DropDown>
              </Grid>
            )}
          </Grid>
        );
      })}

      <Grid style={{ marginTop: '12px' }}>
        <TextButton buttonType="ghost" onClick={onAddFilter} style={{ marginRight: '16px' }}>
          &#43; Add a Filter
        </TextButton>

        {filterDepth > 1 && (
          <TextButton buttonType="ghost" onClick={onAddGroup}>
            &#43; Add Group
          </TextButton>
        )}
      </Grid>
    </Grid>
  );
};

export default AdvancedFilterBlock;
