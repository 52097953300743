import React from 'react';
import {
  FocusBlock,
  Grid,
  IconButton,
  Para,
  SelectBox,
  SpinLoader,
  TextButton,
  Theme,
} from 'uie/components';
import { CircleCIService } from '../../../../../../../core/services';
import {
  ICIBuild,
  ICIProject,
  ICIRebuildResponse,
} from '../../../../../../../core/interfaces/IActions';
import { exception } from '../../../../../../../core/exception';
import { IIncident } from '../../../../../../../core/interfaces/IIncidents';
import moment from 'moment';
import { NewTabIcon } from '../../../../../../../icons';
import { AppTracker } from '../../../../../../../shared/analytics/tracker';
import { T_WA_GS_INCIDENT_ACTIONS_CIRCLE_CI } from '../../../../../../../core/const/tracker';

const { theme } = Theme;

interface IProps {
  incident: IIncident;
  getIncidentDetails: () => void;
}

interface IState {
  projects: ICIProject[];
  selectedProject: ICIProject | null;
  builds: ICIBuild[];
  selectedBuild: ICIBuild | null;
  actionResponse: ICIRebuildResponse | null;
  CIEnabled: boolean;
  projectsSearch: string;
  buildsSearch: string;
  loading: 'check' | 'projects' | 'builds' | 'rebuild' | '';
}

class CircleCIAction extends React.Component<IProps, IState> {
  private _circleCIService = new CircleCIService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      projects: [] as ICIProject[],
      selectedProject: null,
      builds: [] as ICIBuild[],
      selectedBuild: null,
      actionResponse: null,
      CIEnabled: false,
      projectsSearch: '',
      buildsSearch: '',
      loading: '',
    };
  }

  componentDidMount() {
    this.checkEnabled();
  }

  public checkEnabled = async () => {
    this.setState({ loading: 'check' });

    try {
      await this._circleCIService.checkEnabled();
      this.setState({ CIEnabled: true, loading: '' }, () => this.getProjects());
    } catch (err: any) {
      this.setState({ CIEnabled: false, loading: '' });
    }
  };

  public getProjects = async () => {
    this.setState({ loading: 'projects' });
    try {
      const {
        data: { data: projects },
      } = await this._circleCIService.getProjects();
      this.setState({ projects, selectedProject: projects[0], loading: '' }, () =>
        this.getBuilds(),
      );
    } catch (err: any) {
      exception.handle('E_CIRCLECI_GET_PROJECTS', err);
      this.setState({ loading: '' });
    }
  };

  public getBuilds = async () => {
    if (!this.state.selectedProject) return;
    this.setState({ loading: 'builds' });

    try {
      const {
        data: { data: builds },
      } = await this._circleCIService.getBuilds(this.state.selectedProject);
      this.setState({ builds, selectedBuild: builds[0], loading: '' });
    } catch (err: any) {
      exception.handle('E_CIRCLECI_GET_BUILDS', err);
      this.setState({ loading: '' });
    }
  };

  public handleProjectSelect = (_: any, selectedProject: ICIProject) =>
    this.setState({ selectedProject, selectedBuild: null, builds: [] }, () => this.getBuilds());

  public handleBuildSelect = (_: any, selectedBuild: ICIBuild) => this.setState({ selectedBuild });

  public handleRebuild = async () => {
    if (!this.state.selectedBuild || !this.state.selectedProject) return;
    this.setState({ loading: 'rebuild' });

    try {
      const {
        data: { data: actionResponse },
      } = await this._circleCIService.triggerAction(
        this.props.incident.id,
        this.state.selectedBuild,
        this.state.selectedProject,
      );
      this.setState({ actionResponse, loading: '' });
    } catch (err: any) {
      exception.handle('E_CIRCLECI_REBUILD', err);
      this.setState({ loading: '' });
    } finally {
      this.props.getIncidentDetails();
    }
    AppTracker.track(T_WA_GS_INCIDENT_ACTIONS_CIRCLE_CI);
  };

  public handleViewBuildClick = (url: string) => () => window.open(url, '_blank');

  public render() {
    const { selectedBuild, selectedProject } = this.state;

    return (
      <Grid type="column">
        {this.state.loading === 'check' ? (
          <Grid justifyContent="space-around" className="mt-10">
            <SpinLoader />
          </Grid>
        ) : this.state.CIEnabled ? (
          !this.state.actionResponse ? (
            <>
              <Grid type="column" style={{ marginTop: '16px' }}>
                <Para
                  fontWeight={500}
                  color={theme.shades.grey}
                  style={{ marginBottom: '4px', fontSize: '14px' }}
                >
                  Project
                </Para>
                <SelectBox
                  hook={
                    this.state.projects.length === 0 && this.state.loading !== 'projects' ? (
                      <Para fontSize={16} className="ml-10">
                        No Projects Found
                      </Para>
                    ) : selectedProject ? (
                      <Grid alignItems="center">
                        <Para fontSize={16} className="ml-10">
                          <span className="font-bold">{selectedProject.username}</span>/
                          {selectedProject.reponame}
                        </Para>
                      </Grid>
                    ) : (
                      <Para fontSize={16} className="ml-10">
                        Select Project
                      </Para>
                    )
                  }
                  searchHookProps={{
                    value: this.state.projectsSearch,
                    height: '24px',
                    fontSize: '16px',
                    onChange: e => this.setState({ projectsSearch: e.target.value }),
                  }}
                  disabled={this.state.projects.length === 0}
                  onValueChange={this.handleProjectSelect}
                  height="auto"
                  maxHeight="200px"
                  width="100%"
                  maxWidth="100%"
                  padding="0"
                >
                  {this.state.projects
                    .filter(project =>
                      this.state.projectsSearch
                        ? project.reponame
                            .toLowerCase()
                            .includes(this.state.projectsSearch.toLowerCase())
                        : true,
                    )
                    .map((project, idx) => (
                      <FocusBlock
                        key={idx}
                        value={project}
                        isSelected={project.reponame === selectedProject?.reponame}
                      >
                        <Grid alignItems="center">
                          <Para fontSize={16} className="ml-10">
                            <span className="font-bold">{project.username}</span>/{project.reponame}
                          </Para>
                        </Grid>
                      </FocusBlock>
                    ))}
                </SelectBox>
              </Grid>

              {this.state.builds.length > 0 && (
                <Grid type="column" style={{ marginTop: '16px' }}>
                  <Para
                    fontWeight={500}
                    color={theme.shades.grey}
                    style={{ marginBottom: '4px', fontSize: '14px' }}
                  >
                    Build
                  </Para>
                  <SelectBox
                    hook={
                      this.state.builds.length === 0 && this.state.loading !== 'builds' ? (
                        <Para fontSize={16} className="ml-10">
                          No Builds Found
                        </Para>
                      ) : selectedBuild ? (
                        <Grid alignItems="center">
                          <Para fontSize={16} fontWeight={500} className="ml-10">
                            #{selectedBuild.build_num} {selectedBuild.branch}
                          </Para>
                        </Grid>
                      ) : (
                        <Para fontSize={16} className="ml-10">
                          Select Build
                        </Para>
                      )
                    }
                    searchHookProps={{
                      value: this.state.buildsSearch,
                      height: '24px',
                      fontSize: '16px',
                      onChange: e => this.setState({ buildsSearch: e.target.value }),
                    }}
                    onValueChange={this.handleBuildSelect}
                    disabled={this.state.builds.length === 0}
                    height="auto"
                    maxHeight="200px"
                    width="100%"
                    maxWidth="100%"
                    padding="0"
                  >
                    {this.state.builds
                      .filter(build =>
                        this.state.buildsSearch
                          ? `${build.build_num} ${build.branch}`
                              .toLowerCase()
                              .includes(this.state.buildsSearch.toLowerCase())
                          : true,
                      )
                      .map((build, idx) => (
                        <FocusBlock
                          key={idx}
                          value={build}
                          isSelected={build.build_num === selectedBuild?.build_num}
                        >
                          <Grid type="column" width="100%" style={{ textAlign: 'left' }}>
                            <Grid justifyContent="space-between">
                              <Para fontWeight={500} fontSize={14}>
                                #{build.build_num} {build.branch}
                              </Para>
                              <Para fontSize={12}>{moment(build.start_time).fromNow()}</Para>
                            </Grid>
                            <Grid justifyContent="space-between" className="mt-10">
                              <Para
                                fontSize={12}
                                fontWeight={500}
                                style={{
                                  color:
                                    build.status === 'success'
                                      ? theme.success.default
                                      : theme.danger.default,
                                }}
                              >
                                {build.status}
                              </Para>
                              <Para
                                fontSize={12}
                                fontWeight={500}
                                style={{
                                  background: theme.shades.whiteSmoke,
                                  borderRadius: '4px',
                                  padding: '4px 12px',
                                }}
                              >
                                {build.build_parameters.CIRCLE_JOB}
                              </Para>
                            </Grid>
                            <Para className="mt-10" fontWeight={500} fontSize={14}>
                              {build.author_name}
                            </Para>
                            <Para fontSize={12}>{build.subject}</Para>
                          </Grid>
                        </FocusBlock>
                      ))}
                  </SelectBox>

                  {selectedBuild && (
                    <Grid justifyContent="flex-end" type="column" style={{ marginTop: '24px' }}>
                      {this.state.loading === 'rebuild' && (
                        <Para className="mt-10 mb-10" fontWeight={400} fontSize={12}>
                          Action Triggered! Awaiting response!
                        </Para>
                      )}

                      <TextButton
                        color={theme.primary.default}
                        onClick={this.handleRebuild}
                        disabled={this.state.loading === 'rebuild'}
                        style={{ padding: '8px 16px' }}
                      >
                        <Para fontWeight={500} color={theme.shades.white}>
                          Rebuild
                        </Para>
                      </TextButton>
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          ) : (
            <Grid className="mt-20">
              <Para>
                Your CircleCI - Rebuild action on{' '}
                <span className="font-bold">
                  {this.state.actionResponse.circleci_response.username}/
                  {this.state.actionResponse.circleci_response.reponame} #(
                  {this.state.actionResponse.circleci_response.build_num})
                </span>{' '}
                build has been scheduled
                <IconButton
                  style={{ display: 'inline-block' }}
                  onClick={this.handleViewBuildClick(
                    this.state.actionResponse.circleci_response.build_url,
                  )}
                >
                  <NewTabIcon />
                </IconButton>
              </Para>
            </Grid>
          )
        ) : (
          <Grid>
            <Para style={{ padding: '20px 12px' }} fontWeight={400}>
              Please integrate with CircleCI to continue
            </Para>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default CircleCIAction;
