import * as React from 'react';
import { SVGProps } from 'react';

const SvgObserviumLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <linearGradient
      id="Observium_Logo_svg__a"
      gradientUnits="userSpaceOnUse"
      x1={2.165}
      y1={14.002}
      x2={6.191}
      y2={14.002}
    >
      <stop
        offset={0}
        style={{
          stopColor: '#2d7e9e',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#103971',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#Observium_Logo_svg__a)',
      }}
      d="M6.118 15.159c-.459-.322-1.733-1.97-2.298-2.81-.16-.152-.442.15-1.649 1.64h-.006l.014.138v-.02s.864-.943 1.052-.809c.188.134 1.966 2.396 2.353 2.392.173-.1.406-.282.607-.45a.241.241 0 0 1-.073-.081z"
    />
    <linearGradient
      id="Observium_Logo_svg__b"
      gradientUnits="userSpaceOnUse"
      x1={0}
      y1={14.081}
      x2={2.179}
      y2={14.081}
    >
      <stop
        offset={0}
        style={{
          stopColor: '#2d7e9e',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#103971',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#Observium_Logo_svg__b)',
      }}
      d="M0 13.996v.177h2.179v-.084l-.014-.1c-.31 0-2.165.007-2.165.007z"
    />
    <linearGradient
      id="Observium_Logo_svg__c"
      gradientUnits="userSpaceOnUse"
      x1={6.118}
      y1={9.775}
      x2={20}
      y2={9.775}
    >
      <stop
        offset={0}
        style={{
          stopColor: '#2d7e9e',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#103971',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#Observium_Logo_svg__c)',
      }}
      d="M14.754 4.439s-.229-.29-.503 0c-.265.422-1.604 3.008-4.734 6.902C8.219 12.93 6.493 15 6.119 15.16h-.001a.242.242 0 0 0 .072.082c.226-.189.41-.357.41-.357s3.873-3.741 5.4-5.655c1.171-1.568 1.782-2.78 2.19-2.443.407.336 1.964 1.624 4.23 5.258.717.23 1.48.748 1.58.94-.55-1.291-2.624-6.005-5.246-8.545z"
    />
  </svg>
);

export default SvgObserviumLogo;
