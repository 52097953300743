import { useBoolean, useDisclosure } from '@chakra-ui/hooks';
import { Flex, HStack, Text, VStack, WrapItem } from '@chakra-ui/react';
import { T_WA_UP_IAG_VOTE_DOWN, T_WA_UP_IAG_VOTE_UP } from 'core/const/tracker';
import { Locale } from 'core/helpers/dateUtils';
import { VOTE_TYPE } from 'core/interfaces/IIncidents';
import { IconButton, Tag, Tooltip, useToast } from 'library/atoms';
import { VoteDown, VoteDownGrey, VoteUp, VoteUpGrey } from 'library/icons';
import { CustomDrawerComponent, Loader, Placeholder } from 'library/molecules';
import { FC, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useQueryClient } from 'react-query';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import { AppTracker } from 'shared/analytics/tracker';

import {
  invalidateAGAlertDetails,
  useGetAGAlertDetails,
} from '../../../query/useGetAGAlertDetails';
import { invalidateAutoGroupedAlertsList } from '../../../query/useGetAutoGroupedAlerts';
import { voteDownAlert } from '../../../query/useVoteDownAlert';
import { voteUpAlert } from '../../../query/useVoteUpAlert';
import { VoteDownAlertFC } from '../voteDown';
import { DetailBox } from './DetailBox';
import { Heading } from './Heading';

type Props = {
  incidentId: string;
  alertId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const AlertDetails: FC<Props> = ({ incidentId, alertId, isOpen, onClose }) => {
  const queryClient = useQueryClient();
  const {
    isOpen: isVoteDownOpen,
    onOpen: onVoteDownOpen,
    onClose: onVoteDownClose,
  } = useDisclosure();
  const { alert, isLoading, isError } = useGetAGAlertDetails(incidentId, alertId);
  const [voteType, setVoteType] = useState<VOTE_TYPE>(null);
  const [isVotePerformed, setIsVotePerformed] = useBoolean();
  const toast = useToast();

  const onDrawerClose = () => {
    if (isVotePerformed) {
      invalidateAutoGroupedAlertsList(queryClient);
    }
    onClose();
  };

  const onAPISuccess = (eventName: string) => {
    AppTracker.track(eventName);
    setIsVotePerformed.on();
    invalidateAGAlertDetails(queryClient);
  };

  const onError = (err: any) => {
    const errMsg = err?.response?.data?.meta.error_message ?? `Unable to vote`;

    toast({
      status: 'error',
      text: `Error: ${errMsg}`,
    });
    console.error(err);
  };

  const onVoteDown = () => {
    setVoteType('negative');

    voteDownAlert(incidentId, alertId)
      .then(() => {
        onAPISuccess(T_WA_UP_IAG_VOTE_DOWN);
      })
      .catch(error => {
        onError(error);
      })
      .finally(() => {
        setVoteType(null);
        onVoteDownClose();
      });
  };

  const onVoteUp = () => {
    setVoteType('positive');

    voteUpAlert(incidentId, alertId)
      .then(() => {
        onAPISuccess(T_WA_UP_IAG_VOTE_UP);
      })
      .catch(error => {
        onError(error);
      });
  };

  return (
    <>
      <CustomDrawerComponent
        isOpen={isOpen}
        onClose={onDrawerClose}
        title="Alert Detail"
        disableBodyPadding
        footer={
          <>
            {alert && (
              <Flex gap={2} alignItems="center" width="max-content" position="relative">
                {alert.vote_type ? (
                  <>
                    {alert.vote_type === 'positive' ? <VoteUpGrey /> : <VoteDownGrey />}
                    <Text variant="body_800" color="secondary.700">
                      Voted {alert.vote_type === 'positive' ? 'Up' : 'Down'}
                    </Text>
                  </>
                ) : (
                  <>
                    <Tooltip label="This action cannot be undone" placement="bottom">
                      <IconButton
                        aria-label="vote up"
                        onClick={onVoteUp}
                        icon={<VoteUp />}
                        disabled={voteType === 'negative'}
                        isLoading={voteType === 'positive'}
                      />
                    </Tooltip>
                    <Tooltip label="This action cannot be undone" placement="bottom">
                      <IconButton
                        onClick={onVoteDownOpen}
                        aria-label="vote down"
                        icon={<VoteDown />}
                        disabled={voteType === 'positive'}
                      />
                    </Tooltip>
                  </>
                )}
              </Flex>
            )}
          </>
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {isError ? (
              <Placeholder size="md" description="Failed to load alert details!" />
            ) : (
              <>
                <DetailBox>
                  <Heading title="alert message" />
                  <ReactMarkdown className="mde" plugins={[breaks, gfm]}>
                    {alert.message}
                  </ReactMarkdown>
                </DetailBox>
                <DetailBox>
                  <HStack columnGap={12}>
                    <VStack alignItems="flex-start">
                      <Heading title="alert source" />
                      <Text color="secondary.700" variant="formInput">
                        {alert.alert_source.name}
                      </Text>
                    </VStack>
                    <VStack alignItems="flex-start">
                      <Heading title="created at" />
                      <Text color="secondary.700" variant="formInput">
                        {Locale.toShortDate(alert.created_at)}{' '}
                        {Locale.to24HrTimeWithSec(alert.created_at)}
                      </Text>
                    </VStack>
                  </HStack>
                </DetailBox>
                {!!alert.tags?.length && (
                  <DetailBox>
                    <Heading title="tags" />
                    <Flex gap={4} flexDir="row" flexWrap="wrap">
                      {alert.tags.map(tag => (
                        <WrapItem key={`${tag.key}: ${tag.value}`}>
                          <Tag
                            variant="keyValue"
                            keyStr={tag.key}
                            value={tag.value}
                            color={tag.color}
                            showExpanded={true}
                          />
                        </WrapItem>
                      ))}
                    </Flex>
                  </DetailBox>
                )}
                <DetailBox>
                  <Heading title="alert description" />
                  <ReactMarkdown className="mde" plugins={[breaks, gfm]}>
                    {alert.description}
                  </ReactMarkdown>
                </DetailBox>
              </>
            )}
          </>
        )}
      </CustomDrawerComponent>
      <VoteDownAlertFC
        isOpen={isVoteDownOpen}
        isVoting={voteType != null}
        onClose={onVoteDownClose}
        onVoteDown={onVoteDown}
      />
    </>
  );
};
