import { EventModal } from '.';
import { hasProperty, objectIsEmpty, getProperty, deepCopy } from 'core/helpers';
import {
  ISCalendarSeriesRequest,
  ISCalendarSeries,
  ISCalendarSeriesFrequencyRepeats,
} from '../../../../../core/interfaces/ICalendar';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_GS_SHIFT_CREATED, T_WA_GS_SHIFT_MODIFIED } from '../../../../../core/const/tracker';
import { Locale } from 'core/helpers/dateUtils';
import moment from 'moment';

export function onDeleteButtonClick(this: EventModal, event: any) {
  this.setState({ showDeleteOptions: !this.state.showDeleteOptions });
  event.stopPropagation();
}

export function onRotationShiftFrequencyChange(this: EventModal, event: any) {
  this.setRotationFrequency(+event.target.value);
}
export function openModalClick(this: EventModal, event: any) {
  event.stopPropagation();
  this.setState({
    showDeleteOptions: false,
    userSearchFocusIndex: -1,
  });
}

export function onDateChange(
  this: EventModal,
  value: Date,
  key: 'start_time' | 'end_time',
  type: 'date' | 'time',
) {
  let time: Date;

  if (value === null) {
    this.setState(() => {
      const newState: any = {
        [key]: new Date(),
      };

      return newState;
    });

    return;
  }

  this.setState(state => {
    if (type === 'time') {
      const [hh, mm] = value.toString().split(':');
      state[key].setHours(parseInt(hh, 10));
      state[key].setMinutes(parseInt(mm, 10));
      time = state[key];
    } else {
      time = value;
    }

    const newState: any = {
      [key]: time,
    };

    return newState;
  });
}

export function onAddUserToRotation(this: EventModal, id: string, type: 'u' | 's', index: number) {
  const map = {
    u: 'user_ids',
    s: 'squad_ids',
  };
  this.setState(({ rotations }) => {
    const rotation: any = rotations[index];

    if (!hasProperty(rotation, map[type])) {
      rotation[map[type]] = [];
    }

    const idIndex = rotation[map[type]].indexOf(id);

    if (idIndex === -1) {
      rotation[map[type]].push(id);
    }
    rotations[index] = rotation;

    return { rotations };
  });
}

export function onAddToEvent(this: EventModal, id: string, type: 'u' | 's') {
  this.setState(({ user_ids, squad_ids }) => {
    const arrayRef = type === 'u' ? user_ids : squad_ids;

    const idIndex = (arrayRef || []).indexOf(id);

    if (idIndex === -1) {
      arrayRef.push(id);
    }

    return { user_ids, squad_ids };
  });
}

export function onRemoveUserFromCalendar(this: EventModal, id: string, index: number) {
  this.setState(({ rotations }) => {
    const rotation = rotations[index];

    const userIdIndex = (rotation.user_ids || []).indexOf(id);
    const squadIdIndex = (rotation.squad_ids || []).indexOf(id);

    if (userIdIndex > -1) {
      rotation.user_ids.splice(userIdIndex, 1);
    }

    if (squadIdIndex > -1) {
      rotation.squad_ids.splice(squadIdIndex, 1);
    }

    rotations[index] = rotation;
    return { rotations };
  });
}

export function onRemoveFromEvent(this: EventModal, id: string) {
  this.setState(({ user_ids, squad_ids }) => {
    const userIdIndex = (user_ids || []).indexOf(id);
    const squadIdIndex = (squad_ids || []).indexOf(id);

    if (userIdIndex > -1) {
      user_ids.splice(userIdIndex, 1);
    }

    if (squadIdIndex > -1) {
      squad_ids.splice(squadIdIndex, 1);
    }

    return { user_ids, squad_ids };
  });
}

export function onVerify(this: EventModal) {
  const errors: any = {};
  const now = new Date();
  const {
    start_time,
    end_time,
    calendar_id,
    rotations,
    repetition_type,
    frequency,
    user_ids,
    squad_ids,
    rotationFrequency,
  } = this.state;

  now.setHours(0, 0, 0, 0);
  if (
    this.isSingleEventUpdate &&
    user_ids &&
    user_ids.length === 0 &&
    squad_ids &&
    squad_ids.length === 0
  ) {
    errors.rotations = 'An on-call person is required';
  }

  if (!this.isSingleEventUpdate) {
    rotations.forEach(rot => {
      if (
        rot.squad_ids &&
        rot.squad_ids.length === 0 &&
        rot.user_ids &&
        rot.user_ids.length === 0
      ) {
        errors.rotations = 'You cannot add an empty group to a shift. Add at least one user';
      }
    });
  }

  if (
    !this.isSingleEventUpdate &&
    (rotations.length === 0 ||
      (rotations[0].squad_ids &&
        rotations[0].squad_ids.length === 0 &&
        rotations[0].user_ids &&
        rotations[0].user_ids.length === 0))
  ) {
    errors.rotations = 'You need to add at least one user in the group to activate this shift';
  }

  rotations.forEach(rot => {
    rot.user_ids.some(u => {
      const role = this.oUsers[u].role;
      if (role && role === 'Stakeholder') {
        errors.stakeholder = "Stakeholders can't go on-call";
        return true;
      }
      return false;
    });
  });

  if (!start_time.getTime()) {
    errors.time = 'Start Time should be valid';
  }

  if (!end_time.getTime()) {
    errors.time = 'End Time should be valid';
  }

  if (start_time.getTime() === end_time.getTime()) {
    errors.time = 'End time should be greater than start time';
  }

  if (start_time.getTime() > end_time.getTime()) {
    errors.time = 'End time should be greater than start time';
  }

  if (calendar_id.length === 0) {
    errors.calendar_id = 'Select a Schedule to add this shift to';
  }

  if (
    (repetition_type === 'weekly_once' || repetition_type === 'weekly_custom') &&
    frequency.every.weekdays.length === 0
  ) {
    errors.weekday = 'Weekday is required';
  }
  if (parseInt(frequency.every.occurrence as any, 10) === 0) {
    errors.repetitions = 'Frequency of shift repetition must be greater than 0';
  }
  if (rotationFrequency === 0) {
    errors.rotationFrequency = 'Frequency of assignee group rotation must be greater than 0';
  }
  this.setState({
    errors,
  });

  return errors;
}

/**
 * Create selected TZ Date with provided date and time
 * @param timezoneDate JS Date
 * @returns local date
 */
export const toTZTDatetime = (timezoneDate: Date) => {
  return Locale.toTZDateTime(
    timezoneDate.getFullYear(),
    timezoneDate.getMonth() + 1,
    timezoneDate.getDate(),
  )
    .set({
      hour: 0,
      minute: timezoneDate.getHours() * 60 + timezoneDate.getMinutes(),
      second: 0,
      millisecond: 0,
    })
    .toISO();
};

export async function onSave(this: EventModal) {
  if (!objectIsEmpty(this.onVerify())) {
    return;
  }

  this.setState({ componentState: 'busy' });

  try {
    const {
      start_time,
      end_time,
      name,
      frequency,
      repeats,
      is_override,
      rotations: rotationSets,
      rotationFrequency,
      user_ids,
      squad_ids,
      starting_group,
    } = this.state;

    const payload: ISCalendarSeriesRequest = {
      start_time: toTZTDatetime(start_time),
      end_time: toTZTDatetime(end_time),
      name,
      rotation_sets: this.isSingleEventUpdate ? [{ user_ids, squad_ids }] : rotationSets,
      config: {
        is_override,
        timezone: {
          // name: new Date()
          //   .toString()
          //   .match(/\((.*)\)/)!
          //   .pop()!
          //   .split(' ')
          //   .map(e => e.charAt(0)) Q1
          //   .join(''),
          // offset: new Date(start_time).getTimezoneOffset(),
          name: Locale.namedOffset,
          offset: Locale.timeZoneOffset * -1, // JS timezone offset is -330 and luxon is 330 so multiplied by -1
        },
        is_forever: frequency.endsOn === 'never',
        until: frequency.endsOn !== 'never' ? frequency.endsOn : new Date(),
        rotate: repeats,
        repeat: repeats,
        rotation_frequency: rotationFrequency,
      },
    };

    if (this.state.update_type === '2') {
      payload.starting_group = starting_group;
    }

    if (repeats) {
      payload.config.repetition = {
        frequency: parseInt(frequency.every.occurrence as any, 10),
        repeats_on: frequency.every.weekdays,
        type: frequency.every.type,
      };
    }
    if (this.state.id === '') {
      await this.calendarService.createACalendarEvent(this.state.calendar_id, payload);
    } else {
      await this.calendarService.updateCalendarEvent(this.state.currentCalendarId, this.state.id, {
        ...payload,
        ...{
          update_type: parseInt(this.state.update_type, 10),
        },
      });
    }
    this.setState({
      componentState: 'idle',
      networkState: 'success',
    });
    this.props.hide(true)();
  } catch ({ response }: any) {
    this.setState({
      componentState: 'error',
      networkState: 'error',
      errors: {
        network: getProperty(response, 'data.meta.error_message', 'Network Error'),
      },
    });
  }

  this.state.id === ''
    ? AppTracker.track(T_WA_GS_SHIFT_CREATED, {
        'Assignee Groups Squad': this.state.rotations.every(
          rotation => rotation.squad_ids.length > 0,
        ),
        Override: this.state.is_override,
        Repeats: this.state.repetition_type,
      })
    : AppTracker.track(T_WA_GS_SHIFT_MODIFIED, {
        'Assignee Groups Squad': this.state.rotations.every(
          rotation => rotation.squad_ids.length > 0,
        ),
        Override: this.state.is_override,
        Repeats: this.state.repetition_type,
      });
}

export async function getSeriesData(this: EventModal) {
  if (this.props.id === '') {
    this.setState({ componentState: 'idle' });
    return;
  }

  const { calendar_id, series_id } = this.state;

  try {
    const {
      data: {
        data: {
          is_forever,
          is_repeated,
          repetition,
          rotation_sets,
          until,
          rotation_frequency,
          starting_group,
        },
      },
    }: { data: { data: ISCalendarSeries } } = await this.calendarService.getCalendarSeries(
      calendar_id,
      series_id,
    );
    const repType = getRepitionType(repetition);

    this.setState(
      {
        repeats: is_repeated,
        rotations: rotation_sets,
        userSearch: Array(rotation_sets.length)
          .fill(null)
          .map(() => ({ value: '' })),
        frequency: {
          every: {
            occurrence: repetition.frequency,
            type: repetition.type,
            weekdays: repetition.repeats_on || [],
          },
          endsOn: is_forever ? 'never' : new Date(until),
        },
        repetition_type: repType,
        rotationFrequency: rotation_frequency,
        change_assigned_group: rotation_frequency > 1 ? 'custom_shift' : 'single_shift',
        componentState: 'idle',
        starting_group: starting_group ? starting_group : 0,
      },
      () => (this._initialState = deepCopy(this.state)),
    );
  } catch (err: any) {
    this.setState({
      componentState: 'idle',
    });
  }
}

function getRepitionType(repetition: ISCalendarSeriesFrequencyRepeats) {
  let repType = '';
  if (repetition.frequency === 1 && repetition.type === 'day') {
    repType = 'daily';
  } else if (repetition.frequency > 1 && repetition.type === 'day') {
    repType = 'custom';
  } else if (
    getProperty(repetition, 'repeats_on', []).length === 1 &&
    repetition.frequency === 1 &&
    repetition.type === 'month'
  ) {
    repType = 'custom';
  } else if (getProperty(repetition, 'repeats_on', []).length === 1 && repetition.frequency > 1) {
    repType = 'custom';
  } else if (getProperty(repetition, 'repeats_on', []).length > 1 && repetition.frequency === 1) {
    repType = 'weekly_custom';
  } else if (getProperty(repetition, 'repeats_on', []).length === 1 && repetition.frequency === 1) {
    repType = 'weekly_once';
  }
  return repType;
}
