import * as Types from './types';

import { useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from './fetcher/index';
export type CreateStatusPageMutationVariables = Types.Exact<{
  input: Types.NewStatusPage;
}>;

export type CreateStatusPageMutation = {
  __typename?: 'Mutation';
  createStatusPage: {
    __typename?: 'StatusPage';
    id: number;
    name: string;
    organizationID: string;
    teamID: string;
    logoUrl: string;
    isPublic: boolean;
    domainName: string;
    timezone: string;
    themeColor: { __typename?: 'StatusPageThemeColor'; primary: string; secondary: string };
    components: Array<{
      __typename?: 'StatusPageComponentList';
      type: Types.NewStatusPageComponentType;
      name: string;
      allowSubscription?: boolean | null;
      description?: string | null;
      components?: Array<{
        __typename?: 'StatusPageComponent';
        type: Types.NewStatusPageComponentType;
        name: string;
        allowSubscription: boolean;
        description?: string | null;
      } | null> | null;
    } | null>;
  };
};

export type UpdateStatusPageConfigurationMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.UpdateStatusPageConfigurationReq>;
}>;

export type UpdateStatusPageConfigurationMutation = {
  __typename?: 'Mutation';
  updateStatusPageConfiguration: {
    __typename?: 'UpdateStatusPageConfigurationRes';
    name: string;
    isPublic: boolean;
    domainName: string;
    customDomainName: string;
    timezone: string;
    description: string;
    id: number;
    hideFromSearchEngines?: boolean | null;
  };
};

export type UpdateStatusPageThemeMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.UpdateStatusPageTheme>;
}>;

export type UpdateStatusPageThemeMutation = {
  __typename?: 'Mutation';
  updateStatusPageTheme: {
    __typename?: 'StatusPageTheme';
    pageID: number;
    logoUrl: string;
    themeColor: { __typename?: 'StatusPageThemeColor'; primary: string; secondary: string };
  };
};

export type DeleteStatusPageMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;

export type DeleteStatusPageMutation = {
  __typename?: 'Mutation';
  deleteStatusPage: {
    __typename?: 'DeleteStatusPageRes';
    id: number;
    name: string;
    organizationID: string;
    teamID: string;
  };
};

export type CreateStatusPageIssueMutationVariables = Types.Exact<{
  input: Types.NewStatusPageIssue;
}>;

export type CreateStatusPageIssueMutation = {
  __typename?: 'Mutation';
  createStatusPageIssue: {
    __typename?: 'StatusPageIssue';
    id: number;
    componentIDs: Array<number | null>;
    title: string;
  };
};

export type UpdateStatusPageIssueMutationVariables = Types.Exact<{
  input: Types.UpdateStatusPageIssue;
}>;

export type UpdateStatusPageIssueMutation = {
  __typename?: 'Mutation';
  updateStatusPageIssue: {
    __typename?: 'StatusPageIssue';
    id: number;
    componentIDs: Array<number | null>;
    title: string;
  };
};

export type UpdateStatusPageIssueStateTemplateMutationVariables = Types.Exact<{
  input: Types.UpdateStatusPageIssueStateTemplate;
}>;

export type UpdateStatusPageIssueStateTemplateMutation = {
  __typename?: 'Mutation';
  updateStatusPageIssueStateTemplate?: Array<{
    __typename?: 'IssueStateTemplateRes';
    id: number;
    name: string;
    defaultMessage: string;
  }> | null;
};

export type DeleteStatusPageIssueMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;

export type DeleteStatusPageIssueMutation = {
  __typename?: 'Mutation';
  deleteStatusPageIssue: {
    __typename?: 'DeleteStatusPageIssueRes';
    id: number;
    pageID: number;
    title: string;
  };
};

export type UpdateStatusPageStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  statusID: Types.Scalars['Int'];
}>;

export type UpdateStatusPageStatusMutation = {
  __typename?: 'Mutation';
  updateStatusPageStatus: {
    __typename?: 'StatusPageStatus';
    id: number;
    name: string;
    statusID: number;
  };
};

export type UpdateStatusPageStatusMessageMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.UpdateStatusPageStatusMessage>;
}>;

export type UpdateStatusPageStatusMessageMutation = {
  __typename?: 'Mutation';
  updateStatusPageStatusMessage: Array<{
    __typename?: 'UpdatedStatusPageStatus';
    id: number;
    statusMessage: string;
  } | null>;
};

export type CreateStatusPageMaintenanceMutationVariables = Types.Exact<{
  input: Types.NewStatusPageMaintenance;
}>;

export type CreateStatusPageMaintenanceMutation = {
  __typename?: 'Mutation';
  createStatusPageMaintenance: {
    __typename?: 'StatusPageMaintenance';
    id: number;
    title: string;
    pageID: number;
    note?: string | null;
    components: Array<number | null>;
    startTime: any;
    endTime: any;
  };
};

export type DeleteStatusPageMaintenanceMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;

export type DeleteStatusPageMaintenanceMutation = {
  __typename?: 'Mutation';
  deleteStatusPageMaintenance: {
    __typename?: 'StatusPageMaintenance';
    id: number;
    title: string;
    pageID: number;
    note?: string | null;
    components: Array<number | null>;
    startTime: any;
    endTime: any;
  };
};

export type UpdateStatusPageMaintenanceMutationVariables = Types.Exact<{
  input: Types.UpdateStatusPageMaintenanceReq;
}>;

export type UpdateStatusPageMaintenanceMutation = {
  __typename?: 'Mutation';
  updateStatusPageMaintenance: {
    __typename?: 'StatusPageMaintenance';
    id: number;
    title: string;
    pageID: number;
    note?: string | null;
    components: Array<number | null>;
    startTime: any;
    endTime: any;
  };
};

export type CreateStatusPageComponentMutationVariables = Types.Exact<{
  input: Types.NewStatusPageComponentReq;
}>;

export type CreateStatusPageComponentMutation = {
  __typename?: 'Mutation';
  createStatusPageComponent: {
    __typename?: 'StatusPageComponentRes';
    id: number;
    pageID: number;
    name: string;
    description?: string | null;
    allowSubscription: boolean;
    groupID?: number | null;
  };
};

export type UpdateStatusPageComponentMutationVariables = Types.Exact<{
  input: Types.UpdateStatusPageComponentReq;
}>;

export type UpdateStatusPageComponentMutation = {
  __typename?: 'Mutation';
  updateStatusPageComponent: {
    __typename?: 'UpdateStatusPageComponentRes';
    id: number;
    name: string;
    description?: string | null;
    groupID?: number | null;
  };
};

export type DeleteStatusPageComponentMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;

export type DeleteStatusPageComponentMutation = {
  __typename?: 'Mutation';
  deleteStatusPageComponent: {
    __typename?: 'DeleteStatusPageComponentRes';
    id: number;
    name: string;
    description?: string | null;
    allowSubscription: boolean;
  };
};

export type CreateStatusPageGroupMutationVariables = Types.Exact<{
  input: Types.NewStatusPageGroupReq;
}>;

export type CreateStatusPageGroupMutation = {
  __typename?: 'Mutation';
  createStatusPageGroup: {
    __typename?: 'StatusPageGroupRes';
    id: number;
    pageID: number;
    name: string;
  };
};

export type UpdateStatusPageGroupMutationVariables = Types.Exact<{
  input: Types.UpdateStatusPageGroupReq;
}>;

export type UpdateStatusPageGroupMutation = {
  __typename?: 'Mutation';
  updateStatusPageGroup: {
    __typename?: 'StatusPageGroupRes';
    id: number;
    name: string;
    pageID: number;
  };
};

export type DeleteStatusPageGroupMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  reassignGroupID?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type DeleteStatusPageGroupMutation = {
  __typename?: 'Mutation';
  deleteStatusPageGroup: {
    __typename?: 'StatusPageGroupRes';
    id: number;
    name: string;
    pageID: number;
  };
};

export type UpdateStatusPageComponentListOrderMutationVariables = Types.Exact<{
  input: Types.UpdateStatusPageComponentListOrderReq;
}>;

export type UpdateStatusPageComponentListOrderMutation = {
  __typename?: 'Mutation';
  updateStatusPageComponentListOrder: Array<{
    __typename?: 'UpdateStatusPageComponentListOrderRes';
    id: number;
    type: Types.NewStatusPageComponentType;
    components?: Array<number> | null;
  }>;
};

export type UpdateStatusPageSubscriptionSettingsMutationVariables = Types.Exact<{
  input: Types.UpdateStatusPageSubscriptionSettings;
}>;

export type UpdateStatusPageSubscriptionSettingsMutation = {
  __typename?: 'Mutation';
  updateStatusPageSubscriptionSettings: {
    __typename?: 'StatusPageSubscriptionSettings';
    contactEmail: string;
    sendOnlyEmailID: string;
    allowWebhookSubscription: boolean;
    allowComponentsSubscription: boolean;
    allowMaintenanceSubscription: boolean;
  };
};

export type VerifyStatusPageCustomDomainMutationVariables = Types.Exact<{
  pageID: Types.Scalars['Int'];
}>;

export type VerifyStatusPageCustomDomainMutation = {
  __typename?: 'Mutation';
  verifyStatusPageCustomDomain: {
    __typename?: 'VerifyStatusPageCustomDomainResponse';
    isCustomDomainVerified: boolean;
    isCustomARecordVerified: {
      __typename?: 'VerificationStatus';
      isVerified: boolean;
      message: string;
    };
    isCustomTxtVerified: {
      __typename?: 'VerificationStatus';
      isVerified: boolean;
      message: string;
    };
  };
};

export const CreateStatusPageDocument = `
    mutation createStatusPage($input: NewStatusPage!) {
  createStatusPage(input: $input) {
    id
    name
    organizationID
    teamID
    logoUrl
    isPublic
    domainName
    timezone
    themeColor {
      primary
      secondary
    }
    components {
      type
      name
      allowSubscription
      description
      components {
        type
        name
        allowSubscription
        description
      }
    }
  }
}
    `;
export const useCreateStatusPageMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateStatusPageMutation,
    TError,
    CreateStatusPageMutationVariables,
    TContext
  >,
) =>
  useMutation<CreateStatusPageMutation, TError, CreateStatusPageMutationVariables, TContext>(
    ['createStatusPage'],
    (variables?: CreateStatusPageMutationVariables) =>
      fetcher<CreateStatusPageMutation, CreateStatusPageMutationVariables>(
        CreateStatusPageDocument,
        variables,
      )(),
    options,
  );
export const UpdateStatusPageConfigurationDocument = `
    mutation updateStatusPageConfiguration($input: UpdateStatusPageConfigurationReq) {
  updateStatusPageConfiguration(input: $input) {
    name
    isPublic
    domainName
    customDomainName
    timezone
    description
    id
    hideFromSearchEngines
  }
}
    `;
export const useUpdateStatusPageConfigurationMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateStatusPageConfigurationMutation,
    TError,
    UpdateStatusPageConfigurationMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateStatusPageConfigurationMutation,
    TError,
    UpdateStatusPageConfigurationMutationVariables,
    TContext
  >(
    ['updateStatusPageConfiguration'],
    (variables?: UpdateStatusPageConfigurationMutationVariables) =>
      fetcher<
        UpdateStatusPageConfigurationMutation,
        UpdateStatusPageConfigurationMutationVariables
      >(UpdateStatusPageConfigurationDocument, variables)(),
    options,
  );
export const UpdateStatusPageThemeDocument = `
    mutation updateStatusPageTheme($input: UpdateStatusPageTheme) {
  updateStatusPageTheme(input: $input) {
    pageID
    logoUrl
    themeColor {
      primary
      secondary
    }
  }
}
    `;
export const useUpdateStatusPageThemeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateStatusPageThemeMutation,
    TError,
    UpdateStatusPageThemeMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateStatusPageThemeMutation,
    TError,
    UpdateStatusPageThemeMutationVariables,
    TContext
  >(
    ['updateStatusPageTheme'],
    (variables?: UpdateStatusPageThemeMutationVariables) =>
      fetcher<UpdateStatusPageThemeMutation, UpdateStatusPageThemeMutationVariables>(
        UpdateStatusPageThemeDocument,
        variables,
      )(),
    options,
  );
export const DeleteStatusPageDocument = `
    mutation deleteStatusPage($id: Int!) {
  deleteStatusPage(id: $id) {
    id
    name
    organizationID
    teamID
  }
}
    `;
export const useDeleteStatusPageMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteStatusPageMutation,
    TError,
    DeleteStatusPageMutationVariables,
    TContext
  >,
) =>
  useMutation<DeleteStatusPageMutation, TError, DeleteStatusPageMutationVariables, TContext>(
    ['deleteStatusPage'],
    (variables?: DeleteStatusPageMutationVariables) =>
      fetcher<DeleteStatusPageMutation, DeleteStatusPageMutationVariables>(
        DeleteStatusPageDocument,
        variables,
      )(),
    options,
  );
export const CreateStatusPageIssueDocument = `
    mutation createStatusPageIssue($input: NewStatusPageIssue!) {
  createStatusPageIssue(input: $input) {
    id
    componentIDs
    title
  }
}
    `;
export const useCreateStatusPageIssueMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateStatusPageIssueMutation,
    TError,
    CreateStatusPageIssueMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CreateStatusPageIssueMutation,
    TError,
    CreateStatusPageIssueMutationVariables,
    TContext
  >(
    ['createStatusPageIssue'],
    (variables?: CreateStatusPageIssueMutationVariables) =>
      fetcher<CreateStatusPageIssueMutation, CreateStatusPageIssueMutationVariables>(
        CreateStatusPageIssueDocument,
        variables,
      )(),
    options,
  );
export const UpdateStatusPageIssueDocument = `
    mutation updateStatusPageIssue($input: UpdateStatusPageIssue!) {
  updateStatusPageIssue(input: $input) {
    id
    componentIDs
    title
  }
}
    `;
export const useUpdateStatusPageIssueMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateStatusPageIssueMutation,
    TError,
    UpdateStatusPageIssueMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateStatusPageIssueMutation,
    TError,
    UpdateStatusPageIssueMutationVariables,
    TContext
  >(
    ['updateStatusPageIssue'],
    (variables?: UpdateStatusPageIssueMutationVariables) =>
      fetcher<UpdateStatusPageIssueMutation, UpdateStatusPageIssueMutationVariables>(
        UpdateStatusPageIssueDocument,
        variables,
      )(),
    options,
  );
export const UpdateStatusPageIssueStateTemplateDocument = `
    mutation updateStatusPageIssueStateTemplate($input: UpdateStatusPageIssueStateTemplate!) {
  updateStatusPageIssueStateTemplate(input: $input) {
    id
    name
    defaultMessage
  }
}
    `;
export const useUpdateStatusPageIssueStateTemplateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateStatusPageIssueStateTemplateMutation,
    TError,
    UpdateStatusPageIssueStateTemplateMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateStatusPageIssueStateTemplateMutation,
    TError,
    UpdateStatusPageIssueStateTemplateMutationVariables,
    TContext
  >(
    ['updateStatusPageIssueStateTemplate'],
    (variables?: UpdateStatusPageIssueStateTemplateMutationVariables) =>
      fetcher<
        UpdateStatusPageIssueStateTemplateMutation,
        UpdateStatusPageIssueStateTemplateMutationVariables
      >(UpdateStatusPageIssueStateTemplateDocument, variables)(),
    options,
  );
export const DeleteStatusPageIssueDocument = `
    mutation deleteStatusPageIssue($id: Int!) {
  deleteStatusPageIssue(id: $id) {
    id
    pageID
    title
  }
}
    `;
export const useDeleteStatusPageIssueMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteStatusPageIssueMutation,
    TError,
    DeleteStatusPageIssueMutationVariables,
    TContext
  >,
) =>
  useMutation<
    DeleteStatusPageIssueMutation,
    TError,
    DeleteStatusPageIssueMutationVariables,
    TContext
  >(
    ['deleteStatusPageIssue'],
    (variables?: DeleteStatusPageIssueMutationVariables) =>
      fetcher<DeleteStatusPageIssueMutation, DeleteStatusPageIssueMutationVariables>(
        DeleteStatusPageIssueDocument,
        variables,
      )(),
    options,
  );
export const UpdateStatusPageStatusDocument = `
    mutation updateStatusPageStatus($id: Int!, $statusID: Int!) {
  updateStatusPageStatus(id: $id, statusID: $statusID) {
    id
    name
    statusID
  }
}
    `;
export const useUpdateStatusPageStatusMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateStatusPageStatusMutation,
    TError,
    UpdateStatusPageStatusMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateStatusPageStatusMutation,
    TError,
    UpdateStatusPageStatusMutationVariables,
    TContext
  >(
    ['updateStatusPageStatus'],
    (variables?: UpdateStatusPageStatusMutationVariables) =>
      fetcher<UpdateStatusPageStatusMutation, UpdateStatusPageStatusMutationVariables>(
        UpdateStatusPageStatusDocument,
        variables,
      )(),
    options,
  );
export const UpdateStatusPageStatusMessageDocument = `
    mutation updateStatusPageStatusMessage($input: UpdateStatusPageStatusMessage) {
  updateStatusPageStatusMessage(input: $input) {
    id
    statusMessage
  }
}
    `;
export const useUpdateStatusPageStatusMessageMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateStatusPageStatusMessageMutation,
    TError,
    UpdateStatusPageStatusMessageMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateStatusPageStatusMessageMutation,
    TError,
    UpdateStatusPageStatusMessageMutationVariables,
    TContext
  >(
    ['updateStatusPageStatusMessage'],
    (variables?: UpdateStatusPageStatusMessageMutationVariables) =>
      fetcher<
        UpdateStatusPageStatusMessageMutation,
        UpdateStatusPageStatusMessageMutationVariables
      >(UpdateStatusPageStatusMessageDocument, variables)(),
    options,
  );
export const CreateStatusPageMaintenanceDocument = `
    mutation createStatusPageMaintenance($input: NewStatusPageMaintenance!) {
  createStatusPageMaintenance(input: $input) {
    id
    title
    pageID
    note
    components
    startTime
    endTime
  }
}
    `;
export const useCreateStatusPageMaintenanceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateStatusPageMaintenanceMutation,
    TError,
    CreateStatusPageMaintenanceMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CreateStatusPageMaintenanceMutation,
    TError,
    CreateStatusPageMaintenanceMutationVariables,
    TContext
  >(
    ['createStatusPageMaintenance'],
    (variables?: CreateStatusPageMaintenanceMutationVariables) =>
      fetcher<CreateStatusPageMaintenanceMutation, CreateStatusPageMaintenanceMutationVariables>(
        CreateStatusPageMaintenanceDocument,
        variables,
      )(),
    options,
  );
export const DeleteStatusPageMaintenanceDocument = `
    mutation deleteStatusPageMaintenance($id: Int!) {
  deleteStatusPageMaintenance(id: $id) {
    id
    title
    pageID
    note
    components
    startTime
    endTime
  }
}
    `;
export const useDeleteStatusPageMaintenanceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteStatusPageMaintenanceMutation,
    TError,
    DeleteStatusPageMaintenanceMutationVariables,
    TContext
  >,
) =>
  useMutation<
    DeleteStatusPageMaintenanceMutation,
    TError,
    DeleteStatusPageMaintenanceMutationVariables,
    TContext
  >(
    ['deleteStatusPageMaintenance'],
    (variables?: DeleteStatusPageMaintenanceMutationVariables) =>
      fetcher<DeleteStatusPageMaintenanceMutation, DeleteStatusPageMaintenanceMutationVariables>(
        DeleteStatusPageMaintenanceDocument,
        variables,
      )(),
    options,
  );
export const UpdateStatusPageMaintenanceDocument = `
    mutation updateStatusPageMaintenance($input: UpdateStatusPageMaintenanceReq!) {
  updateStatusPageMaintenance(input: $input) {
    id
    title
    pageID
    note
    components
    startTime
    endTime
  }
}
    `;
export const useUpdateStatusPageMaintenanceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateStatusPageMaintenanceMutation,
    TError,
    UpdateStatusPageMaintenanceMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateStatusPageMaintenanceMutation,
    TError,
    UpdateStatusPageMaintenanceMutationVariables,
    TContext
  >(
    ['updateStatusPageMaintenance'],
    (variables?: UpdateStatusPageMaintenanceMutationVariables) =>
      fetcher<UpdateStatusPageMaintenanceMutation, UpdateStatusPageMaintenanceMutationVariables>(
        UpdateStatusPageMaintenanceDocument,
        variables,
      )(),
    options,
  );
export const CreateStatusPageComponentDocument = `
    mutation createStatusPageComponent($input: NewStatusPageComponentReq!) {
  createStatusPageComponent(input: $input) {
    id
    pageID
    name
    description
    allowSubscription
    groupID
  }
}
    `;
export const useCreateStatusPageComponentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateStatusPageComponentMutation,
    TError,
    CreateStatusPageComponentMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CreateStatusPageComponentMutation,
    TError,
    CreateStatusPageComponentMutationVariables,
    TContext
  >(
    ['createStatusPageComponent'],
    (variables?: CreateStatusPageComponentMutationVariables) =>
      fetcher<CreateStatusPageComponentMutation, CreateStatusPageComponentMutationVariables>(
        CreateStatusPageComponentDocument,
        variables,
      )(),
    options,
  );
export const UpdateStatusPageComponentDocument = `
    mutation updateStatusPageComponent($input: UpdateStatusPageComponentReq!) {
  updateStatusPageComponent(input: $input) {
    id
    name
    description
    groupID
  }
}
    `;
export const useUpdateStatusPageComponentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateStatusPageComponentMutation,
    TError,
    UpdateStatusPageComponentMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateStatusPageComponentMutation,
    TError,
    UpdateStatusPageComponentMutationVariables,
    TContext
  >(
    ['updateStatusPageComponent'],
    (variables?: UpdateStatusPageComponentMutationVariables) =>
      fetcher<UpdateStatusPageComponentMutation, UpdateStatusPageComponentMutationVariables>(
        UpdateStatusPageComponentDocument,
        variables,
      )(),
    options,
  );
export const DeleteStatusPageComponentDocument = `
    mutation deleteStatusPageComponent($id: Int!) {
  deleteStatusPageComponent(id: $id) {
    id
    name
    description
    allowSubscription
  }
}
    `;
export const useDeleteStatusPageComponentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteStatusPageComponentMutation,
    TError,
    DeleteStatusPageComponentMutationVariables,
    TContext
  >,
) =>
  useMutation<
    DeleteStatusPageComponentMutation,
    TError,
    DeleteStatusPageComponentMutationVariables,
    TContext
  >(
    ['deleteStatusPageComponent'],
    (variables?: DeleteStatusPageComponentMutationVariables) =>
      fetcher<DeleteStatusPageComponentMutation, DeleteStatusPageComponentMutationVariables>(
        DeleteStatusPageComponentDocument,
        variables,
      )(),
    options,
  );
export const CreateStatusPageGroupDocument = `
    mutation createStatusPageGroup($input: NewStatusPageGroupReq!) {
  createStatusPageGroup(input: $input) {
    id
    pageID
    name
  }
}
    `;
export const useCreateStatusPageGroupMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateStatusPageGroupMutation,
    TError,
    CreateStatusPageGroupMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CreateStatusPageGroupMutation,
    TError,
    CreateStatusPageGroupMutationVariables,
    TContext
  >(
    ['createStatusPageGroup'],
    (variables?: CreateStatusPageGroupMutationVariables) =>
      fetcher<CreateStatusPageGroupMutation, CreateStatusPageGroupMutationVariables>(
        CreateStatusPageGroupDocument,
        variables,
      )(),
    options,
  );
export const UpdateStatusPageGroupDocument = `
    mutation updateStatusPageGroup($input: UpdateStatusPageGroupReq!) {
  updateStatusPageGroup(input: $input) {
    id
    name
    pageID
  }
}
    `;
export const useUpdateStatusPageGroupMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateStatusPageGroupMutation,
    TError,
    UpdateStatusPageGroupMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateStatusPageGroupMutation,
    TError,
    UpdateStatusPageGroupMutationVariables,
    TContext
  >(
    ['updateStatusPageGroup'],
    (variables?: UpdateStatusPageGroupMutationVariables) =>
      fetcher<UpdateStatusPageGroupMutation, UpdateStatusPageGroupMutationVariables>(
        UpdateStatusPageGroupDocument,
        variables,
      )(),
    options,
  );
export const DeleteStatusPageGroupDocument = `
    mutation deleteStatusPageGroup($id: Int!, $reassignGroupID: Int) {
  deleteStatusPageGroup(id: $id, reassignGroupID: $reassignGroupID) {
    id
    name
    pageID
  }
}
    `;
export const useDeleteStatusPageGroupMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteStatusPageGroupMutation,
    TError,
    DeleteStatusPageGroupMutationVariables,
    TContext
  >,
) =>
  useMutation<
    DeleteStatusPageGroupMutation,
    TError,
    DeleteStatusPageGroupMutationVariables,
    TContext
  >(
    ['deleteStatusPageGroup'],
    (variables?: DeleteStatusPageGroupMutationVariables) =>
      fetcher<DeleteStatusPageGroupMutation, DeleteStatusPageGroupMutationVariables>(
        DeleteStatusPageGroupDocument,
        variables,
      )(),
    options,
  );
export const UpdateStatusPageComponentListOrderDocument = `
    mutation updateStatusPageComponentListOrder($input: UpdateStatusPageComponentListOrderReq!) {
  updateStatusPageComponentListOrder(input: $input) {
    id
    type
    components
  }
}
    `;
export const useUpdateStatusPageComponentListOrderMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateStatusPageComponentListOrderMutation,
    TError,
    UpdateStatusPageComponentListOrderMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateStatusPageComponentListOrderMutation,
    TError,
    UpdateStatusPageComponentListOrderMutationVariables,
    TContext
  >(
    ['updateStatusPageComponentListOrder'],
    (variables?: UpdateStatusPageComponentListOrderMutationVariables) =>
      fetcher<
        UpdateStatusPageComponentListOrderMutation,
        UpdateStatusPageComponentListOrderMutationVariables
      >(UpdateStatusPageComponentListOrderDocument, variables)(),
    options,
  );
export const UpdateStatusPageSubscriptionSettingsDocument = `
    mutation updateStatusPageSubscriptionSettings($input: UpdateStatusPageSubscriptionSettings!) {
  updateStatusPageSubscriptionSettings(input: $input) {
    contactEmail
    sendOnlyEmailID
    allowWebhookSubscription
    allowComponentsSubscription
    allowMaintenanceSubscription
  }
}
    `;
export const useUpdateStatusPageSubscriptionSettingsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateStatusPageSubscriptionSettingsMutation,
    TError,
    UpdateStatusPageSubscriptionSettingsMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateStatusPageSubscriptionSettingsMutation,
    TError,
    UpdateStatusPageSubscriptionSettingsMutationVariables,
    TContext
  >(
    ['updateStatusPageSubscriptionSettings'],
    (variables?: UpdateStatusPageSubscriptionSettingsMutationVariables) =>
      fetcher<
        UpdateStatusPageSubscriptionSettingsMutation,
        UpdateStatusPageSubscriptionSettingsMutationVariables
      >(UpdateStatusPageSubscriptionSettingsDocument, variables)(),
    options,
  );
export const VerifyStatusPageCustomDomainDocument = `
    mutation verifyStatusPageCustomDomain($pageID: Int!) {
  verifyStatusPageCustomDomain(pageID: $pageID) {
    isCustomARecordVerified {
      isVerified
      message
    }
    isCustomTxtVerified {
      isVerified
      message
    }
    isCustomDomainVerified
  }
}
    `;
export const useVerifyStatusPageCustomDomainMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    VerifyStatusPageCustomDomainMutation,
    TError,
    VerifyStatusPageCustomDomainMutationVariables,
    TContext
  >,
) =>
  useMutation<
    VerifyStatusPageCustomDomainMutation,
    TError,
    VerifyStatusPageCustomDomainMutationVariables,
    TContext
  >(
    ['verifyStatusPageCustomDomain'],
    (variables?: VerifyStatusPageCustomDomainMutationVariables) =>
      fetcher<VerifyStatusPageCustomDomainMutation, VerifyStatusPageCustomDomainMutationVariables>(
        VerifyStatusPageCustomDomainDocument,
        variables,
      )(),
    options,
  );
