import { Text } from '@chakra-ui/layout';
import { Grid, Para, Tooltip } from 'uie/components';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import PaginationBar from 'components/PaginationBar';
import { Locale } from 'core/helpers/dateUtils';
import { truncate } from 'core/helpers/stringUtils';
import { IPostmortemList } from 'core/interfaces/IPostmortems';
import { THEME_COLORS } from 'library/theme/colors';
import moment from 'moment';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { SERVICE_DETAILS_PATH } from 'views/main/routes/routes';
import { POSTMORTEM_STATUS_OPTIONS } from '../../incident-list/filters/manager/constant';
import './table.css';

interface IProps {
  data: IPostmortemList[];
  totalCount: number;
  onPostmortemClick: (incidentId: string) => void;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
}

const columnHelper = createColumnHelper<IPostmortemList>();

function Table({ data, totalCount, onPostmortemClick, pagination, setPagination }: IProps) {
  const columns = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: 'Postmortem Title',
        cell: ({ row, getValue }) => {
          if (getValue().length > 30)
            return (
              <Tooltip label={getValue()} offset={{ top: '-10px' }} width="300px">
                <Text
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      color: THEME_COLORS.brand.blue,
                    },
                  }}
                  onClick={() =>
                    row.original.incident_id && onPostmortemClick(row.original.incident_id)
                  }
                >
                  {truncate(getValue(), 28)}
                </Text>
              </Tooltip>
            );
          return (
            <Text
              style={{ cursor: 'pointer' }}
              onClick={() =>
                row.original.incident_id && onPostmortemClick(row.original.incident_id)
              }
            >
              {getValue()}
            </Text>
          );
        },
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: ({ getValue }) => {
          const status = getValue();
          if (!status) {
            return null;
          }
          const statusDetails = {
            label: POSTMORTEM_STATUS_OPTIONS[status].label,
            Icon: POSTMORTEM_STATUS_OPTIONS[status].Icon,
          };
          return (
            <Text style={{ display: 'flex' }}>
              <statusDetails.Icon style={{ marginRight: '10px' }} />
              <Para fontSize={14}>{statusDetails.label}</Para>
            </Text>
          );
        },
      }),
      columnHelper.accessor('active_follow_ups_count', {
        header: 'Active Follow Ups',
        cell: ({ getValue }) => {
          return <Text>{getValue()}</Text>;
        },
      }),

      columnHelper.accessor('created_at', {
        header: 'Created At',
        cell: ({ getValue }) => {
          const postmortemCreatedAt = moment(getValue());
          const postmortemCreatedAtJSX = postmortemCreatedAt.isAfter(
            moment().subtract(4, 'weeks'),
          ) ? (
            <Tooltip
              label={Locale.toFullDateTime(postmortemCreatedAt.toDate())}
              offset={{ top: '-64px', left: '-80px' }}
            >
              <Text>{postmortemCreatedAt.fromNow()}</Text>
            </Tooltip>
          ) : (
            <Text>{Locale.toFullDateTime(postmortemCreatedAt.toDate())}</Text>
          );

          return postmortemCreatedAtJSX;
        },
      }),
      columnHelper.accessor('service_name', {
        header: 'Service',
        cell: ({ row, getValue }) => {
          return (
            <Link to={generatePath(SERVICE_DETAILS_PATH, { id: (row.original as any).service_id })}>
              <Text> {getValue()}</Text>
            </Link>
          );
        },
      }),
      columnHelper.accessor('alert_source', {
        header: 'Alert Source',
        cell: ({ getValue }) => {
          return <Text>{getValue()}</Text>;
        },
      }),
    ],
    [onPostmortemClick],
  );

  const { getHeaderGroups, getRowModel, getPageCount, setPageSize, setPageIndex, getState } =
    useReactTable({
      data,
      columns,
      state: { pagination },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pagination.pageSize),
      onPaginationChange: setPagination,
      getCoreRowModel: getCoreRowModel(),
      debugTable: true,
    });

  return (
    <Grid type="column" style={{ minHeight: '70vh', justifyContent: 'space-between' }}>
      <table className="postmortemList-table" style={{ tableLayout: 'fixed' }}>
        <thead style={{ backgroundColor: '#f5f5f5' }}>
          {getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody style={{ backgroundColor: 'white' }}>
          {getRowModel().rows.map(row => {
            return (
              <tr key={row.id} style={{ cursor: 'default' }}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <PaginationBar
        pageIndex={getState().pagination.pageIndex}
        pageCount={getPageCount()}
        pageSize={getState().pagination.pageSize}
        totalCount={totalCount}
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
      />
    </Grid>
  );
}

export default Table;
