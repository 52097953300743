import { Flex, Text, Box } from '@chakra-ui/react';
import { NodePopover } from 'components/chakra/Popover';
import EventsList from './events-list';
import { DayData } from './types';
import { Tooltip } from 'library/atoms';
import SQTooltip from 'components/chakra/Tooltip';

type OnCallCoverageRowProps = {
  dayEventsInfo: DayData[];
  timeZone: string;
};

function OnCallCoverageRow({ dayEventsInfo, timeZone }: OnCallCoverageRowProps) {
  return (
    <div>
      {dayEventsInfo.map(({ eventDetailInfo, offset, width, onCallCoverage }, index) => {
        if (!onCallCoverage) {
          return null;
        }
        const isGap = onCallCoverage.type === 'gap';

        const item = (
          <Flex
            bgColor={isGap ? '#F56565' : '#C5DFFB'}
            p={1}
            borderWidth={1}
            borderColor={'#D5ECF8'}
            overflow="hidden"
            borderRadius={4}
            columnGap={1}
            justifyContent="center"
            pos="absolute"
            top="50%"
            left={offset + '%'}
            transform="translate(0, -50%)"
            width={`${width}%`}
            visibility={width ? 'visible' : 'hidden'}
            minH={8}
            _hover={{
              cursor: 'pointer',
              borderWidth: 2,
              borderColor: 'blue.400',
            }}
            zIndex={isGap ? 2 : 1}
            data-testid={eventDetailInfo.override?.enabled ? 'event-override-info' : 'event-info'}
          />
        );

        return (
          <NodePopover
            key={eventDetailInfo.eventId + index}
            placement={'auto'}
            trigger={
              isGap ? (
                <SQTooltip
                  text={
                    <Text color="#627C98" fontSize="12px">
                      {isGap ? 'Gap' : 'Coverage'}
                    </Text>
                  }
                  placement="bottom"
                >
                  {item}
                </SQTooltip>
              ) : (
                item
              )
            }
          >
            {!isGap && <EventsList onCallCoverage={onCallCoverage} timeZone={timeZone} />}
          </NodePopover>
        );
      })}
    </div>
  );
}

export default OnCallCoverageRow;
