import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import {
  REQUEST_ACCOUNT_PROFILE_UPDATE,
  REQUEST_ACCOUNT_PROFILE_UPDATE_FAIL,
} from '../../const/account';
import { IAccountUserUpdateRequest } from '../../interfaces/IAccount';
import { updateAccountInfo } from '../../api/account';
import {
  onRequestUserAccountUpdateSuccess,
  onRequestUserAccountUpdateFail,
} from '../../actions/account';

const updateAccountInfoEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ACCOUNT_PROFILE_UPDATE),
    map((action: any) => action.payload),
    switchMap(({ data }: { data: IAccountUserUpdateRequest }) => {
      return updateAccountInfo(data).pipe(
        map(({ response }: AjaxResponse) => response),
        map((response: { result: boolean }) => {
          if (!response.result) {
            return onRequestUserAccountUpdateFail('invalid data');
          }

          return onRequestUserAccountUpdateSuccess(data);
        }),
        catchError(error =>
          of({
            type: REQUEST_ACCOUNT_PROFILE_UPDATE_FAIL,
            payload: error.xhr,
            error: true,
          }),
        ),
      );
    }),
  );

export default updateAccountInfoEpic;
