import { IOrgConfig } from 'core/interfaces/IOrgConfig';
import { compact } from 'lodash';
import { IUserAbilitySlugMap } from '../../../../../core/interfaces/IUserData';

type UserPermissionLabel = {
  accessor: keyof IUserAbilitySlugMap;
  label: string;
};

export const userPermissionsLabels = (orgConfig: IOrgConfig): UserPermissionLabel[] => {
  const array: UserPermissionLabel[] = compact([
    {
      accessor: 'manage-users',
      label: 'Users',
    },
    {
      accessor: 'manage-teams',
      label: 'Teams',
    },
    {
      accessor: 'manage-api-tokens',
      label: 'API Tokens',
    },
    {
      accessor: 'manage-webhooks',
      label: 'Webhooks',
    },
    {
      accessor: 'manage-extensions',
      label: 'Extensions',
    },
    {
      accessor: 'manage-postmortem-templates',
      label: 'Postmortem Templates',
    },
    {
      accessor: 'manage-audit-logs',
      label: 'Audit Logs',
    },
    orgConfig['analyticsv2.enabled']
      ? {
          accessor: 'manage-organization-analytics',
          label: 'Organization Analytics',
        }
      : undefined,
    {
      accessor: 'manage-billing',
      label: 'Billing',
    },
  ]);

  return array;
};
