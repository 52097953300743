import { Box, VStack } from '@chakra-ui/layout';
import { useHistory, useLocation, useParams } from 'react-router';
import { useGetSessionDetails } from 'views/main/organization/lcr/hooks/lcrDetails';
import { HoverComponent, useLCRLogsColumn } from './LCRLogsAction';
import LCRLogsDetails from './LCRLogsDetails';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_LCR__LOGS_PAGE_LOADED } from 'core/const/tracker';

import { useDisclosure } from '@chakra-ui/hooks';
import LCRLogsFilter from './LCRLogsFilter';
import FilterPreview from './FilterPreview';
import { LCRFormType } from 'views/main/organization/lcr/types/LCR.types';
import { useLCRStore } from 'views/main/organization/lcr/hooks/lcr.state';
import { CircularProgress } from '@chakra-ui/progress';
import _ from 'lodash.mergewith/node_modules/@types/lodash';
import { useDebounce } from 'views/main/organization/lcr/hooks/useDebounce';
import { getSessionsDetailsPath, getSessionPath } from 'views/main/organization/lcr/common/utils';
import Table, { usePaginationParams } from 'library/molecules/Tablev2/Table';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import { PageHeader } from 'library/molecules';

const LogsTab = () => {
  const columns = useLCRLogsColumn();
  const params = useParams<{ id: string; sessionID: string }>();

  const [searchVal, setSearchVal] = useState('');
  const { setPagination, paginationInfo } = usePaginationParams({
    enabled: true,
  });
  const debouncedValue = useDebounce(searchVal, 1000);

  const { pathname, search } = useLocation();
  const history = useHistory();
  const lcrState = useLCRStore();
  const sessionId = qs.parse(search);
  const lcrDetails = useLCRStore();

  const assigned_to = lcrDetails.logsFilterApply
    ?.filter(val => val.key === 'User')
    .map(_ => _.value);
  const service_id = lcrDetails.logsFilterApply
    ?.filter(val => val.key === 'Service')
    .map(_ => _.value);

  const onFilterSearch = (value: string) => {
    useLCRStore.getState().setLogsSearch(value);
    setSearchVal(value);
  };

  const sessionLogs = useGetSessionDetails({
    id: params.id,
    pageSize: paginationInfo.pageSize,
    pageIndex: paginationInfo.pageIndex,
    assigned_to: assigned_to ?? [],
    service_id: service_id ?? [],
    search: debouncedValue ?? '',
  });

  const showFilter = useDisclosure();
  const onViewLogs = ({ id }: { id: string }) => {
    history.push(
      `${getSessionsDetailsPath(params.id, 'sessions', id)}?page=${paginationInfo.pageIndex}&size=${
        paginationInfo.pageSize
      }`,
    );
  };

  useEffect(() => {
    AppTracker.track(T_WA_UP_LCR__LOGS_PAGE_LOADED, {
      'LCR ID': lcrDetails?.lcrDataDetails.id,
    });
  }, []);

  const onHideActionLog = useCallback(
    () => history.push(getSessionPath(params.id, 'sessions')),
    [],
  );

  return (
    <FormWrapper<LCRFormType>>
      <VStack flex={1} mt="4">
        <PageHeader
          onFilter={showFilter.onOpen}
          isFilterApplied={lcrDetails?.logsFilter && !!lcrDetails.logsFilter.length}
          title=" "
          onSearch={val => {
            onFilterSearch(val);
          }}
          searchValue={lcrState.logsSearch}
          search={true}
          filter={true}
          noButton
        >
          <Box sx={{ '& *': { fontSize: 12 } }}>
            <FilterPreview />
          </Box>
        </PageHeader>

        {sessionLogs.isLoading ? (
          <VStack h="inherit" justifyContent="center" mt={5}>
            <CircularProgress isIndeterminate />
          </VStack>
        ) : (
          <Table
            data={sessionLogs.data?.data ?? []}
            columns={columns}
            hoverComponent={props => <HoverComponent {...props} onViewLogs={onViewLogs} />}
            showNumberBasedPagination
            pageCount={sessionLogs?.data?.metadata?.total_count}
            pageIndex={paginationInfo.pageIndex}
            pageSize={paginationInfo.pageSize}
            onPaginationChange={setPagination}
            isLoading={sessionLogs.isFetching}
          />
        )}
        <LCRLogsFilter isDrawerOpen={showFilter.isOpen} onDrawerClose={showFilter.onClose} />
        <LCRLogsDetails isDrawerOpen={!!params.sessionID} onDrawerClose={onHideActionLog} />
      </VStack>
    </FormWrapper>
  );
};

export default LogsTab;
