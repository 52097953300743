import { Heading, Para, Theme } from 'uie/components';
import React from 'react';
import Iframe from 'react-iframe';
import './index.css';
import { Box, Stack, Text, Center } from '@chakra-ui/react';

class Banner extends React.Component {
  public render() {
    const { theme } = Theme;
    return (
      <div className="banner-container">
        <div className="banner-content-container" style={{ justifyContent: 'flex-start' }}>
          <div>
            <Heading
              color={theme.shades.white}
              fontSize={24}
              style={{ display: 'block', lineHeight: '40px' }}
            >
              Welcome Back
            </Heading>
            <Para color={theme.shades.white} style={{ alignSelf: 'center' }}>
              Here's what we have been upto
            </Para>
          </div>
          <div
            className="headway"
            style={{ backgroundColor: 'white', marginTop: '30px', borderRadius: '10px' }}
          >
            <Iframe
              url="https://headway-widget.net/widgets/7Q9257"
              width="570px"
              height="385px"
              id="myId"
              className="myClassname"
              position="relative"
              frameBorder={0}
            />
          </div>
          <Para
            color={theme.shades.white}
            fontSize={24}
            style={{ marginTop: '30px', marginBottom: '10px', fontWeight: 600 }}
          >
            Blogs from the vault
          </Para>
          <Center>
            <Stack direction="row" mb={10}>
              <Box
                w="200px"
                h="150px"
                borderWidth="1px"
                bgColor="#1D426B"
                sx={{ boxShadow: '1px 1px 1px 1px #44C9D1' }}
                borderRadius="10px"
              >
                <Text
                  p={2}
                  color={'rgba(45, 57, 100, 1)'}
                  fontSize={14}
                  textAlign={'center'}
                  fontWeight={'bold'}
                  pt={45}
                >
                  <a
                    href="https://www.squadcast.com/blog/how-to-configure-services-in-squadcast-best-practices-to-reduce-mttr"
                    target="_blank"
                    rel="noreferrer"
                    className="blog-link"
                  >
                    How to configure services in Squadcast
                  </a>
                </Text>
              </Box>
              <Box
                w="200px"
                h="150px"
                borderWidth="1px"
                bgColor="#1D426B"
                sx={{ boxShadow: '1px 1px 1px 1px #44C9D1' }}
                borderRadius="10px"
              >
                <Text
                  p={2}
                  color={'rgba(45, 57, 100, 1)'}
                  fontFamily={'Mulish'}
                  fontSize={14}
                  textAlign={'center'}
                  fontWeight={'bold'}
                  pt={45}
                >
                  <a
                    href="https://www.squadcast.com/blog/best-practices-in-incident-management"
                    target="_blank"
                    rel="noreferrer"
                    className="blog-link"
                  >
                    Best Practices in Incident Management
                  </a>
                </Text>
              </Box>
              <Box
                w="200px"
                h="150px"
                borderWidth="1px"
                bgColor="#1D426B"
                sx={{ boxShadow: '1px 1px 1px 1px #44C9D1' }}
                borderRadius="10px"
              >
                <Text
                  p={2}
                  color={'rgba(45, 57, 100, 1)'}
                  fontFamily={'Mulish'}
                  fontSize={14}
                  textAlign={'center'}
                  fontWeight={'bold'}
                  pt={45}
                >
                  <a
                    href="https://www.squadcast.com/blog/7-tips-on-building-and-maintaining-an-sre-team-in-your-company"
                    target="_blank"
                    rel="noreferrer"
                    className="blog-link"
                  >
                    7 Tips On Building And Maintaining an SRE Team
                  </a>
                </Text>
              </Box>
            </Stack>
          </Center>
        </div>
      </div>
    );
  }
}

export { Banner };
