import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const CheckboxTheme: ComponentStyleConfig = {
  baseStyle: {
    label: {
      fontFamily: 'Mulish',
    },
    control: {
      borderRadius: '2px',
      borderWidth: '2px',
      borderStyle: 'solid',

      boxShadow: 'none',

      borderColor: 'brand.blue',
      bg: 'brand.white',

      // Button pressed action
      // Did not use _active as it persists the properties after key release
      '&:active': {
        bg: 'brand.blue',
        borderColor: 'brand.blue',
      },

      _hover: {
        ':not(:active)': {
          borderColor: 'brand.lightBlue',
        },
      },

      _disabled: {
        bg: 'brand.blueInactive',
        borderColor: 'brand.blueInactive',

        '&:active': {
          bg: 'brand.blueInactive',
          borderColor: 'brand.blueInactive',
        },

        _hover: {
          ':not(:active)': {
            borderColor: 'brand.blueInactive',
          },
        },
      },

      _focus: {
        boxShadow: 'none',
        borderColor: 'brand.blue',
      },

      _checked: {
        bg: 'brand.blue',
        borderColor: 'brand.blue',

        ':active': {
          color: 'brand.white',
        },

        _focus: {
          bg: 'brand.lightBlue',
          borderColor: 'brand.lightBlue',
        },

        _hover: {
          bg: 'brand.lightBlue',
          borderColor: 'brand.lightBlue',
        },

        _disabled: {
          color: 'brand.white',

          bg: 'brand.blueInactive',
          borderColor: 'brand.blueInactive',

          '&:active': {
            bg: 'brand.blueInactive',
            borderColor: 'brand.blueInactive',
          },

          _focus: {
            bg: 'brand.blueInactive',
            borderColor: 'brand.blueInactive',
          },
          _hover: {
            bg: 'brand.blueInactive',
            borderColor: 'brand.blueInactive',
          },
        },
      },
    },
  },
  sizes: {
    md: {
      control: {
        width: '12px',
        height: '12px',
      },
      label: {
        fontSize: '12px',
      },
      icon: {
        width: '5.66px',
        height: '4.01px',
      },
    },
    lg: {
      control: {
        width: '16px',
        height: '16px',
      },
      label: {
        fontSize: '16px',
      },
      icon: {
        width: '8.49px',
        height: '6.01px',
      },
    },
  },
  variants: {
    multi: {
      control: {
        _checked: {
          _disabled: {
            bg: 'brand.blueInactive',
            borderColor: 'brand.blueInactive',
          },
        },
      },
    },
  },
};
