export const UDID = '@udid';
export const HOSTNAME = 'hostanme';
export const CURRENT_APP_VERSION = '@app_c_v';
export const SHOW_CHANGE = '@show_change';
export const ORG_SEARCH_HISTORY = '@org_search_history';
export const USER_INCIDENT_GROUPS = '@user_incident_groups';
export const SLACK_STATE = 'slackState';
export const SHOULD_SHOW_VERIFY_PHONE_NUMBER_TOP_BAR_STATE =
  '@should_show_verify_phone_number_top_bar';
export const SHOULD_SHOW_SCHEDULES_BANNER_TOP_BAR_STATE = '@should_show_schedules_banner_top_bar';
export const SHOULD_SHOW_MIGRATED_SCHEDULES_BANNER_TOP_BAR_STATE =
  '@should_show_migrated_schedules_banner_top_bar';
