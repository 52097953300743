import { useContext } from 'react';
import { IncidentSummaryContext } from './SummaryContext';

export const useIncidentSummaryContext = () => {
  const context = useContext(IncidentSummaryContext);
  if (context === undefined) {
    throw new Error('useSummaryContext must be used within a SummaryProvider');
  }
  return context;
};
