import * as React from 'react';
import { SVGProps } from 'react';

const SvgUptimeRobot = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <ellipse
      cx={10}
      cy={10}
      rx={9.972}
      ry={10}
      style={{
        opacity: 0.3,
        fill: '#3bd771',
      }}
    />
    <circle
      cx={10}
      cy={10}
      style={{
        fill: '#3bd771',
      }}
      r={5.917}
    />
  </svg>
);

export default SvgUptimeRobot;
