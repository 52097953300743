import { useFormik } from 'formik';
import { values } from 'lodash';
import { DateTime } from 'luxon';
import { useContext, useMemo } from 'react';
import { UseMutationOptions, useQueryClient } from 'react-query';

import { PAGE_TYPES } from '../constants/schema';
import { reactQueryConfig } from '../constants/status.constants';
import {
  CreateStatusPageMaintenanceMutation,
  CreateStatusPageMaintenanceMutationVariables,
  UpdateStatusPageMaintenanceMutation,
  UpdateStatusPageMaintenanceMutationVariables,
  useCreateStatusPageMaintenanceMutation,
  useUpdateStatusPageMaintenanceMutation,
} from '../graphql/mutation';
import { useGetStatusPageMaintenanceDetailsQuery } from '../graphql/query';
import { formatComponentToOption, getPageInputDetails } from '../helpers/helper.service';
import { reactQueryConfigError } from '../helpers/helper.toast';
import { IOptionComponent, IStatusPageComponentList } from '../Interface';
import { usePageDetails } from './usePageDetails';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_MAINTENANCE_ADDED } from 'core/const/tracker';

type onSubmit = (values: any) => void;

const { initValues, schema } = getPageInputDetails(PAGE_TYPES.MAINTENANCE);

export const constructRequest = (val: any, strtTime: string, endTime: string, pageId: number) => {
  return {
    title: val.title,
    startTime: strtTime,
    endTime: endTime,
    components: val.selectedComponentsForMaintenance.map((c: any) => c.id),
    pageID: pageId,
    note: val?.selectedAddNote ? val.note : '',
  };
};

const useCreateMaintenance = <TError = unknown, TContext = unknown>(
  onSuccess: (data: any) => void,
  options?: UseMutationOptions<
    CreateStatusPageMaintenanceMutation,
    TError,
    CreateStatusPageMaintenanceMutationVariables,
    TContext
  >,
) => {
  return useCreateStatusPageMaintenanceMutation({
    ...options,
    onSuccess: data => {
      onSuccess(data as any);
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_MAINTENANCE_ADDED);
    },
    onError: reactQueryConfigError('Create maintenance'),
  });
};

const useUpdateMaintenance = <TError = unknown, TContext = unknown>(
  onSuccess: (data: any) => void,
  options?: UseMutationOptions<
    UpdateStatusPageMaintenanceMutation,
    TError,
    UpdateStatusPageMaintenanceMutationVariables,
    TContext
  >,
) => {
  return useUpdateStatusPageMaintenanceMutation({
    ...options,
    onSuccess: data => {
      onSuccess(data as any);
    },
    onError: reactQueryConfigError('Update maintenance'),
  });
};

export const usePageMaintenance = (
  pageId: number,
  onSuccessHandler: onSubmit,
  maintenanceId = -1,
) => {
  const { details, isSuccess } = usePageDetails(pageId);

  const { mutateAsync: createMaintenance, isLoading: loadingCreateMaintenance } =
    useCreateMaintenance((data: any) => {
      onSuccessHandler(data);
    });
  const { mutateAsync: updateMaintenance } = useUpdateMaintenance((data: any) => {
    onSuccessHandler(data);
  });
  const useMaintenanceDetail = (maintenanceId: number) => {
    const queryResult = useGetStatusPageMaintenanceDetailsQuery(
      { id: maintenanceId },
      {
        ...reactQueryConfig,
        enabled: maintenanceId !== -1, // Only run the query if maintenanceId is not -1
      },
    );

    // Return the relevant data and success state
    return {
      data: queryResult.data?.getStatusPageMaintenanceDetails || null,
      isSuccess: queryResult.isSuccess,
    };
  };
  const { data: maintenanceDetails, isSuccess: isMaintenanceDetailsAvailable } =
    useMaintenanceDetail(maintenanceId);

  const allDetailsAvilable =
    maintenanceId === -1 ? isSuccess : isSuccess && isMaintenanceDetailsAvailable;

  const onSubmitHandler = (values: any) => {
    const startTime = DateTime.fromISO(`${values.startDate}T${values.startTime}`, {
      zone: details.timezone,
    })
      .toUTC()
      .toISO();
    const endTime = DateTime.fromISO(`${values.endDate}T${values.endTime}`, {
      zone: details.timezone,
    })
      .toUTC()
      .toISO();
    const requestBody = constructRequest(values, startTime, endTime, pageId);
    if (maintenanceId === -1) {
      createMaintenance({
        input: requestBody,
      });
    } else {
      updateMaintenance({
        input: {
          ...requestBody,
          id: maintenanceId,
        },
      });
    }
  };

  const formikobject = useMemo(() => {
    if (allDetailsAvilable) {
      initValues.components = details.components.map((group: any) => {
        const comp: IOptionComponent = formatComponentToOption(group);
        if ((group.components || []).length > 0) {
          comp.options = group.components.map((c: any) => {
            const com = formatComponentToOption(c);
            if (isMaintenanceDetailsAvailable) {
              com.checked =
                (maintenanceDetails?.components || []).filter((comp: any) => c.id === comp.id)
                  .length > 0;
            }
            return com;
          });
        } else if (isMaintenanceDetailsAvailable) {
          comp.checked =
            (maintenanceDetails?.components || []).filter((c: any) => c.id === group.id).length > 0;
        }
        return comp;
      });
      if (isMaintenanceDetailsAvailable) {
        initValues.title = maintenanceDetails?.title;
        initValues.selectedAddNote = maintenanceDetails?.note ? true : false;
        initValues.note = maintenanceDetails?.note ?? '';
        const startUTC = DateTime.fromISO(maintenanceDetails?.startTime, {
          zone: details.timezone,
        });
        const endUTC = DateTime.fromISO(maintenanceDetails?.endTime, { zone: details.timezone });
        initValues.startDate = startUTC.toISODate();
        initValues.startTime = startUTC.toFormat('HH:mm');
        initValues.endDate = endUTC.toISODate();
        initValues.endTime = endUTC.toFormat('HH:mm');
        (initValues.selectedComponentsForMaintenance as IStatusPageComponentList[]) =
          (maintenanceDetails?.components || []) as IStatusPageComponentList[];
      } else {
        initValues.title = '';
        const date = DateTime.local({ zone: details.timezone });
        initValues.startDate = date.toISODate();
        initValues.startTime = '00:00';
        initValues.endDate = date.toISODate();
        initValues.endTime = '00:00';
        initValues.selectedComponentsForMaintenance = [];
      }
      initValues.pageName = details.name;
      initValues.timezone = details.timezone;
    }
    return {
      initialValues: initValues,
      validationSchema: schema,
      onSubmit: onSubmitHandler,
      validateOnChange: false,
      validateOnBlur: false,
      validateOnMount: false,
    };
  }, [allDetailsAvilable]);

  const formik = useFormik(formikobject);

  return {
    formik,
    isSuccess: allDetailsAvilable,
    loadingCreateMaintenance,
  };
};
