import * as React from 'react';
import { SVGProps } from 'react';

const SvgClickupLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Clickup_Logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.Clickup_Logo-20x20_svg__st1{fill:url(#Clickup_Logo-20x20_svg__SVGID_00000130617031665890597520000005136090674214429878_)}'
      }
    </style>
    <linearGradient
      id="Clickup_Logo-20x20_svg__SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={-285.758}
      y1={425.983}
      x2={-285.629}
      y2={425.983}
      gradientTransform="matrix(129.03 0 0 -54.12 36873.04 23070.719)"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#8930fd',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#49ccf9',
        }}
      />
    </linearGradient>
    <path
      d="m1.7 15.4 3-2.4c1.6 2.1 3.4 3.1 5.3 3.1s3.6-1 5.2-3.1l3.1 2.3C16 18.4 13.3 20 10 20c-3.2 0-6-1.6-8.3-4.6z"
      style={{
        fill: 'url(#Clickup_Logo-20x20_svg__SVGID_1_)',
      }}
    />
    <linearGradient
      id="Clickup_Logo-20x20_svg__SVGID_00000018238406680739190840000003255555037322886051_"
      gradientUnits="userSpaceOnUse"
      x1={-285.68}
      y1={426.557}
      x2={-285.551}
      y2={426.557}
      gradientTransform="matrix(123.63 0 0 -76.2 35320.648 32508.559)"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#ff02f0',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#ffc800',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#Clickup_Logo-20x20_svg__SVGID_00000018238406680739190840000003255555037322886051_)',
      }}
      d="M10 5.1 4.6 9.8 2 6.9 10 0l8 6.9-2.5 2.9L10 5.1z"
    />
  </svg>
);

export default SvgClickupLogo20X20;
