import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import cx from 'classnames';

interface IShellProps extends HTMLAttributes<HTMLDivElement> {
  height?: string | 'default';
  fontSize?: string | '16px';
}

const Shell = styled.div<IShellProps>`
  height: ${props => `calc(${props.height || 'auto'} - 8px)`};
  border: 1px solid ${props => props.theme.primary.default}47;
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 4px;
  width: fit-content;
  cursor: pointer;

  &:hover {
    box-shadow: 1px solid ${props => props.theme.primary.default}77;
  }
`;

const SwitchElement = styled.button<IShellProps>`
  color: ${props => props.theme.font.default};
  font-size: ${props => props.fontSize || '14px'};
  font-family: ${props => props.theme.fontFamily};
  font-weight: 400;
  border: none;
  background: transparent;
  padding: 2px 8px;
  transition: 0.17s background, 0.17s color ease;
  cursor: pointer;
  border-radius: 3px;
  outline: none;

  &.selected-child {
    background: ${props => props.theme.primary.default};
    color: ${props => props.theme.font.white};
  }

  &:not(:last-child) {
    margin-right: 4px;
  }

  &:hover:not(.selected-child) {
    background: ${props => props.theme.primary.default}47;
  }

  &:focus-visible {
    outline: ${props => props.theme.focus.default};
    outline-offset: 2px;
  }
`;

interface IProps extends IShellProps {
  selected: string[];
  options: string[];
  onSelectValue: (option: string) => void;
}

const SwitchBlock = ({ selected, options, onSelectValue, ...props }: IProps) => {
  const onClick = (option: string) => () => {
    onSelectValue(option);
  };

  return (
    <Shell {...props}>
      {options.map((o, i) => (
        <SwitchElement
          key={i}
          className={cx('', { 'selected-child': selected.includes(o) })}
          onClick={onClick(o)}
        >
          {o}
        </SwitchElement>
      ))}
    </Shell>
  );
};

/** @component */
export default SwitchBlock;
