import request from '../commons';
import { API } from '../../api';
import { setSettingPageACL, getSettingPageACL } from 'views/main/organization/settings/teams/acl';

function getOrgTeams() {
  const req = request.get(API.config.batman)(`organizations/${API.config.organizationId}/teams`);
  req
    .toPromise()
    .then(data => {
      const acl = data.response.meta.acl;
      setSettingPageACL({
        ACLOnEntity: {
          ...getSettingPageACL().ACLOnEntity,
          teams: {
            ...getSettingPageACL().ACLOnEntity?.teams,
            ...acl,
          },
        },
      });
    })
    .catch(err => {});
  return req;
}

function getOrgSelectedTeamEntities() {
  const req = request.get(API.config.batman)(
    `organizations/${API.config.organizationId}/teams/${API.config.teamId}/entities`,
  );
  return req;
}

export { getOrgTeams, getOrgSelectedTeamEntities };
