import { Box } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

type Props = {
  header: ReactNode;
  body: ReactNode;
  footer?: ReactNode;
  isRounded?: boolean;
  width?: string;
  height?: string;
};

function Card({ header, body, footer, isRounded, width, height }: Props) {
  return (
    <Box
      borderColor="gray.200"
      bg="white"
      borderWidth="1px"
      borderRadius={isRounded ? 'lg' : 'initial'}
      overflow="hidden"
      minW={width}
      minH={height}
    >
      <Box p={4}>{header}</Box>
      {body}
      {footer}
    </Box>
  );
}

export default Card;
