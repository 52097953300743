import { Box } from '@chakra-ui/react';
import { FormTextArea } from 'library/molecules';
import React from 'react';
import { useIncidentSummaryContext } from '../../../context/hook';
import IncidentSummarySection from '../../Section';

const IncidentSummaryDrawerFeedback: React.FC = () => {
  const { feedback, setFeedback } = useIncidentSummaryContext();

  return (
    <IncidentSummarySection heading="" withoutDivider>
      <form>
        <FormTextArea
          label="Feedback"
          placeholder="Type feedback"
          value={feedback}
          onChange={function (_: string, value: any): void {
            setFeedback(value);
          }}
          fieldName={'feedback'}
        />
      </form>
    </IncidentSummarySection>
  );
};

export default IncidentSummaryDrawerFeedback;
