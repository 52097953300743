import React, { useState } from 'react';
import SelectTimeZone from 'components/EventDateRangePicker/timezone';
import './index.css';
import { Checkbox, Radio, Theme } from 'uie/components';
import ReactSelect, { OnChangeValue } from 'react-select';
import CalenderDatePicker from './calenderdatepicker';
import TimePicker from './timepicker';
import { SelectOption } from 'components/SecondaryFilter/types';
import { ITimeslot } from '../../core/interfaces/IService';
import moment from 'moment';
import { Row } from '@squadcast/alchemy-ui/carbon';
import cx from 'classnames';

interface Props {
  slot: ITimeslot;
  handleChange: (newSlot: ITimeslot) => void;
}

interface Istate {}

function EventDateRange(props: Props, state: Istate) {
  const slot = props.slot;
  const weekDay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const week = ['first', 'second', 'third', 'fourth', 'last'];
  const repeat = ['none', 'daily', 'weekly', 'monthly', 'custom'];
  const weekNumber = Math.ceil(new Date(slot.start_time).getDate() / 7);
  const repeatOptions = repeat.map(r => {
    const l = r == 'none' ? 'Does not repeat' : r.charAt(0).toUpperCase() + r.slice(1);
    return {
      label: l,
      value: r,
    };
  });
  const repeatsOnMonth = [
    { label: 'Monthly on day ' + new Date(slot.start_time).getDate(), value: 'date-occurence' },
    {
      label: `Monthly on ${week[weekNumber - 1]} ${weekDay[new Date(slot.start_time).getDay()]}`,
      value: 'weekday-occurence',
    },
  ];
  const [showDatePicker, SetShowDatePicker] = useState('');
  const newSlot = { ...slot };
  const [errors, setErrors] = useState({
    endTime: '',
    endsOn: '',
  });

  const handleEnds = () => {
    newSlot.ends_never = !slot.ends_never;
    props.handleChange(newSlot);
  };

  const handleAlldayChange = () => {
    newSlot.is_allday = !slot.is_allday;
    newSlot.start_time = new Date(
      moment(slot.start_time)
        .tz(slot.time_zone)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format(),
    );
    newSlot.end_time = new Date(
      moment(slot.end_time)
        .tz(slot.time_zone)
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
        .format(),
    );
    props.handleChange(newSlot);
  };

  const handleRepetitionChange = (newValue: OnChangeValue<SelectOption, false>) => {
    newSlot.repetition = newValue?.value || '';
    if (newSlot.repetition === 'custom') {
      newSlot.is_custom = true;
    } else {
      newSlot.is_custom = false;
    }
    if (newSlot.repetition === 'none') {
      newSlot.ends_never = false;
      newSlot.ends_on = newSlot.end_time;
    }
    props.handleChange(newSlot);
  };

  //TODO: Bug -> updating timezone doesn't update the startTime and endTime to reflect the new timezone
  const handleTimezoneChange = (updatedTimezone: string) => {
    newSlot.time_zone = updatedTimezone;
    props.handleChange(newSlot);
  };

  const handleShowPicker = (str: string) => {
    SetShowDatePicker(str);
  };

  const updateEndTimeToEndOfDayIfAllDay = (newDate: Date) => {
    if (newSlot.is_allday) {
      newSlot.end_time = new Date(
        moment(newDate)
          .tz(newSlot.time_zone)
          .set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          })
          .format(),
      );
      return;
    }
    newSlot.end_time = newDate;
  };

  const handleStartDate = (date: Date) => {
    const time = moment(slot.start_time).tz(slot.time_zone).format('HH:mm');
    const newDate = getTimezoneDate(date, time);
    if (newDate > new Date(slot.end_time)) {
      updateEndTimeToEndOfDayIfAllDay(newDate);
    }
    newSlot.start_time = newDate;
    props.handleChange(newSlot);
  };

  const handleEndDate = (date: Date) => {
    const time = moment(slot.end_time).tz(slot.time_zone).format('HH:mm');
    const newDate = getTimezoneDate(date, time);
    if (newDate < new Date(slot.start_time)) {
      setErrors(errors => ({ ...errors, endTime: `End time can't be before start time` }));
    } else {
      setErrors(errors => ({ ...errors, endTime: '' }));
      if (new Date(slot.ends_on) < newDate) {
        newSlot.ends_on = newDate;
      }

      updateEndTimeToEndOfDayIfAllDay(newDate);

      props.handleChange(newSlot);
    }
  };

  const handleEndsOnDate = (date: Date) => {
    const time = moment(slot.ends_on).tz(slot.time_zone).format('HH:mm');
    const newDate = getTimezoneDate(date, time);
    if (newDate < new Date(slot.end_time)) {
      setErrors(errors => ({ ...errors, endsOn: `EndsOn time can't be before end time` }));
    } else {
      setErrors(errors => ({ ...errors, endsOn: '' }));
      newSlot.ends_on = newDate;
      props.handleChange(newSlot);
    }
  };

  const handleStartTime = (starttime: string) => {
    const newDate = getTimezoneDate(newSlot.start_time, starttime);
    if (newDate > new Date(slot.end_time)) {
      newSlot.end_time = newDate;
    }
    newSlot.start_time = newDate;
    props.handleChange(newSlot);
  };

  const handleEndTime = (endtime: string) => {
    const newDate = getTimezoneDate(newSlot.end_time, endtime);
    const newEndsOn = getTimezoneDate(newSlot.ends_on, endtime);
    if (newDate < new Date(slot.start_time)) {
      setErrors(errors => ({ ...errors, endTime: `End time can't be before start time` }));
    } else {
      setErrors(errors => ({ ...errors, endTime: '' }));
      newSlot.end_time = newDate;
      newSlot.ends_on = newEndsOn;
      props.handleChange(newSlot);
    }
  };

  const onRepeatsCountChange = (event: any) => {
    newSlot.custom.repeats_count = Number(event.target.value);
    props.handleChange(newSlot);
  };

  const onCustomRepitionChange = (event: any) => {
    newSlot.custom.repeats = event.target.value;
    props.handleChange(newSlot);
  };

  const onWeekdayChange = (d: any) => () => {
    if (!newSlot.custom.repeats_on_weekdays) {
      newSlot.custom.repeats_on_weekdays = [];
    }
    const dayIndex = newSlot.custom.repeats_on_weekdays.indexOf(d);
    if (dayIndex === -1) {
      newSlot.custom.repeats_on_weekdays.push(d);
    } else {
      newSlot.custom.repeats_on_weekdays.length > 1 &&
        newSlot.custom.repeats_on_weekdays.splice(dayIndex, 1);
    }
    props.handleChange(newSlot);
  };

  const onCustomMonthRepetitionChange = (event: any) => {
    newSlot.custom.repeats_on_month = event.target.value;
    props.handleChange(newSlot);
  };

  const getTimezoneDate = (date: Date, time: string) => {
    const newDate = new Date(
      moment(date)
        .tz(slot.time_zone)
        .set({
          hour: parseInt(time.split(':')[0], 10),
          minute: parseInt(time.split(':')[1], 10),
          second: 0,
          millisecond: 0,
        })
        .format(),
    );
    return newDate;
  };

  return (
    <div style={{ width: '100%', display: 'inline-block' }}>
      <div>
        <p style={{ fontSize: '14px', fontWeight: 600 }}>TimeZone</p>
        <SelectTimeZone timezone={slot.time_zone} handleChange={handleTimezoneChange} />
      </div>
      <div style={{ paddingTop: 10 }}>
        <p style={{ fontSize: '14px', fontWeight: 600 }}>Duration</p>
        <div>
          <div className="">
            <div
              className="float-left field-parent-div"
              style={{
                width: 'calc(60% - 10px)',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                textAlign: 'center',
              }}
            >
              <p
                className="modal-container-sub-heading"
                style={{
                  margin: 0,
                  paddingRight: 15,
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                Start:
              </p>
              <div style={{ width: '85%', display: 'inline' }}>
                <div className="event-modal-date-picker-wrapper">
                  <CalenderDatePicker
                    name={'start-date'}
                    date={slot.start_time}
                    showPicker={showDatePicker}
                    handleChange={handleStartDate}
                    showPickerHandler={handleShowPicker}
                  />
                </div>
              </div>
            </div>
            {!slot.is_allday && (
              <div
                className="float-right field-parent-div"
                style={{ width: 'calc(40% - 10px)', position: 'relative' }}
              >
                <TimePicker
                  date={slot.start_time}
                  timeZone={slot.time_zone}
                  handleChange={handleStartTime}
                />
              </div>
            )}
          </div>
          <div className="">
            <div
              className="float-left field-parent-div"
              style={{
                width: 'calc(60% - 10px)',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                textAlign: 'center',
              }}
            >
              <p
                className="modal-container-sub-heading"
                style={{
                  margin: 0,
                  paddingRight: 25,
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                End:
              </p>
              <div style={{ width: '95%', display: 'inline' }}>
                <div className="event-modal-date-picker-wrapper">
                  <CalenderDatePicker
                    name={'end-date'}
                    date={slot.end_time}
                    showPicker={showDatePicker}
                    handleChange={handleEndDate}
                    showPickerHandler={handleShowPicker}
                  />
                </div>
              </div>
            </div>
            {!slot.is_allday && (
              <div
                className="float-right field-parent-div"
                style={{ width: 'calc(40% - 10px)', position: 'relative' }}
              >
                <TimePicker
                  date={slot.end_time}
                  timeZone={slot.time_zone}
                  handleChange={handleEndTime}
                />
              </div>
            )}
            <p
              style={{ fontSize: '14px', color: Theme.theme.danger.default }}
              className="float-right"
            >
              {errors.endTime || ''}
            </p>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', display: 'flex' }}>
        <div
          className="float-left field-parent-div"
          style={{ width: '100%', position: 'relative', paddingBottom: '15px' }}
        >
          {' '}
          <Checkbox checked={slot.is_allday} onChange={handleAlldayChange} />
          <span className="ml-10" style={{ textAlign: 'left', fontSize: '14px', fontWeight: 600 }}>
            All Day
          </span>
          <div style={{ width: '280px', marginTop: '10px' }}>
            <ReactSelect
              options={repeatOptions}
              value={repeatOptions.filter(options => options.value === slot.repetition)}
              onChange={handleRepetitionChange}
            />
          </div>
          {slot.repetition === 'custom' && (
            <div style={{ marginTop: 20 }}>
              <div style={{ display: 'flex' }}>
                <p
                  style={{
                    marginTop: '15px',
                    fontSize: '15px',
                    fontWeight: 600,
                  }}
                >
                  Repeats every
                </p>
                <div style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Row>
                    <input
                      className="input-design"
                      id="freq_every_occurrences"
                      name="freq_every_occurrences"
                      type="number"
                      min={1}
                      style={{ marginLeft: 10, width: 80 }}
                      value={slot.custom.repeats_count}
                      onChange={onRepeatsCountChange}
                    />
                    <select
                      className="select-design ml-10"
                      value={slot.custom.repeats}
                      onChange={onCustomRepitionChange}
                    >
                      {['day', 'week', 'month'].map((e: string) => {
                        const label = e.charAt(0).toUpperCase() + e.slice(1);
                        return (
                          <option value={e} key={e}>
                            {slot.custom.repeats_count > 1 ? label + 's' : label}
                          </option>
                        );
                      })}
                    </select>
                  </Row>
                </div>
              </div>
              {slot.custom.repeats == 'week' && (
                <div>
                  <p
                    style={{
                      marginTop: '15px',
                      fontSize: '15px',
                      fontWeight: 600,
                    }}
                  >
                    Repeats on
                  </p>
                  <Row justifyContent="space-between" flexWidth={8}>
                    {weekDay.map((d, i) => {
                      return (
                        <div
                          key={i}
                          className={cx('week-day cursor-pointer', {
                            selected:
                              slot.custom.repeats_on_weekdays != null
                                ? slot.custom.repeats_on_weekdays.includes(i as any)
                                : '',
                          })}
                          style={{ marginBottom: 15 }}
                          onClick={onWeekdayChange(i)}
                        >
                          <span>{d.charAt(0)}</span>
                        </div>
                      );
                    })}
                  </Row>
                </div>
              )}
              {slot.custom.repeats === 'month' && (
                <div style={{ marginTop: '20px', width: '300px' }}>
                  <select
                    className="customselect-design ml-10"
                    value={slot.custom.repeats_on_month}
                    onChange={onCustomMonthRepetitionChange}
                    disabled={true}
                  >
                    {repeatsOnMonth.map((e: any) => {
                      return (
                        <option value={e.value} key={e.value}>
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
          )}
          {slot.repetition != 'none' && (
            <>
              <p
                style={{
                  marginTop: '15px',
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                Ends:
              </p>
              <div style={{ display: 'flex', width: '350px', margin: '0px', alignItems: 'center' }}>
                <Radio checked={slot.ends_never} onChange={handleEnds} />
                <span style={{ marginLeft: '10px', marginRight: '30px' }}>Never</span>
                <Radio checked={!slot.ends_never} onChange={handleEnds} />
                <span style={{ marginLeft: '10px', marginRight: '10px' }}>On</span>
                {!slot.ends_never && (
                  <div style={{ width: '65%', display: 'inline' }}>
                    <div
                      className="event-modal-date-picker-wrapper"
                      style={{ textAlign: 'center' }}
                    >
                      <CalenderDatePicker
                        name={'ends-on'}
                        date={slot.ends_on}
                        showPicker={showDatePicker}
                        handleChange={handleEndsOnDate}
                        showPickerHandler={handleShowPicker}
                      />
                    </div>
                  </div>
                )}
              </div>
              <p style={{ fontSize: '14px', color: Theme.theme.danger.default }}>
                {errors.endsOn || ''}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EventDateRange;
