import { Heading } from 'uie/components';
import './index.css';
import {
  Link,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
} from '@chakra-ui/react';

const TemplateHeader = () => {
  return (
    <>
      <Heading
        fontSize={14}
        style={{
          fontWeight: 400,
          lineHeight: 'normal',
        }}
      >
        Select an alert source to begin creating deduplication keys for your incoming events.
      </Heading>
      <br />
      <HStack>
        <Heading
          height={40}
          fontSize={14}
          style={{
            fontWeight: 500,
          }}
        >
          Template for Dedup Key
        </Heading>
        <Popover trigger="hover">
          <PopoverTrigger>
            <img src="/icons/info_black.svg" alt="more info" style={{ height: 16 }} />
          </PopoverTrigger>
          <PopoverContent
            css={{
              '&:focus': {
                boxShadow: 'none',
                outline: 'none',
              },
            }}
            backgroundColor={'#F1F3F6'}
            fontSize={'12.5px'}
            fontWeight={400}
            width="270px"
          >
            <PopoverHeader>
              For additional information on how to write templates, refer{' '}
              <Link
                as="a"
                href="https://developer.hashicorp.com/nomad/tutorials/templates/go-template-syntax"
                target="_blank"
                variant="underlinedLink"
                style={{ color: '#09305A' }}
              >
                Go’s standard library
              </Link>
            </PopoverHeader>
          </PopoverContent>
        </Popover>
      </HStack>
    </>
  );
};

export default TemplateHeader;
