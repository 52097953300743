import { Para, Theme } from 'uie/components';
import {
  IWorkflowMessages,
  WorkflowActionTypes,
} from 'views/main/organization/navigation-flows/helpers';
import workflowLogActionMap from './workflowLogActionMap';

const WorkflowMessages = (data: IWorkflowMessages) => {
  const { theme } = Theme;
  const { log, logMessageActivity, entityComponent } = data;
  const renderContent = workflowLogActionMap[log.action as WorkflowActionTypes];

  return renderContent ? (
    <Para fontSize={14} color={theme.shades.cement}>
      {renderContent({ log, logMessageActivity, entityComponent })}
    </Para>
  ) : null;
};

export default WorkflowMessages;
