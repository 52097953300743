import { HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { FormButton } from 'library/atoms';
import { Loader, Modal, PageHeader, Placeholder } from 'library/molecules';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import Table from 'library/molecules/Tablev2/Table';
import qs from 'query-string';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { WORKFLOWS_CREATE_PATH } from 'views/main/routes/routes';
import { useWorkflowListContext } from '../context/workflowListContext';
import { HoverComponent, useWorkflowColumn } from './TableAction';

const WorkflowTable = ({
  showUpgradeModal,
  setShowUpgradeModal,
}: {
  showUpgradeModal: boolean;
  setShowUpgradeModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const context = useWorkflowListContext();
  const dialogAction = useDisclosure();
  const history = useHistory();
  const workflowCreatePermission = useUserAccess().hasCreateAccess('workflows');
  const [loadingBulkAction, setLoadingBulkAction] = useState<'enable' | 'disable' | null>(null);
  const [deleteWorkflow, setDeleteWorkflow] = useState<{ id: string; title: string } | null>(null);
  const [selectedWorkflows, setSelectedWorkflows] = useState<{ id: string; enabled: boolean }[]>(
    [],
  );
  const queryParams = useLocation().search;
  const workFlowFilterTags = qs.parse(queryParams);
  const keysToCheck = ['action', 'created_by', 'updated_by', 'enabled', 'event', 'owner'];
  //could not directly use hasOwnProperty to filterTags so made new obj
  const plainObject = Object.assign({}, workFlowFilterTags);
  const workflowFilterExists = keysToCheck.some(key => plainObject.hasOwnProperty(key));

  const userAccess = useUserAccess();

  const onCellCheck = useCallback(
    (id: string, enabled: boolean, checked: boolean) =>
      setSelectedWorkflows(oldState =>
        checked ? [...oldState, { id, enabled }] : oldState.filter(w => w.id !== id),
      ),
    [],
  );
  const onAllCellCheck = useCallback(
    (cell: { id: string; enabled: boolean }[], checked: boolean) =>
      setSelectedWorkflows(checked ? cell : []),
    [],
  );

  const onBulkToggleEnable = (enable: boolean) => () => {
    setLoadingBulkAction(enable ? 'enable' : 'disable');
    context.onBulkToggleEnableWorkflow(
      enable,
      selectedWorkflows.filter(w => (enable ? !w.enabled : w.enabled)).map(w => w.id),
      () => {
        setLoadingBulkAction(null);
        setSelectedWorkflows([]);
      },
      () => {
        setLoadingBulkAction(null);
      },
    );
  };

  const onDelete = ({ id, title }: { id: string; title: string }) => {
    dialogAction.onOpen();
    setDeleteWorkflow({ id, title });
  };

  const selectedCells = useMemo(() => {
    const selected = selectedWorkflows.map(w => w.id);
    return {
      isAllSelected: context.workflowData.data.every(w => selected.includes(w.id)),
      list: selected,
    };
  }, [context.workflowData.data, selectedWorkflows.toString()]);

  const columns = useWorkflowColumn({
    isAllCellSelected: selectedCells.isAllSelected,
    selectedCells: selectedCells.list,
    onCellCheck,
    onAllCellCheck,
    onToggleEnableWorkflow: context.onToggleEnableWorkflow,
  });

  useEffect(() => {
    setSelectedWorkflows([]);
  }, [context.paginationInfo]);

  if (context.isLoadingInitialWorkflowData) {
    return (
      <VStack h="inherit" justifyContent="center">
        <Loader />
      </VStack>
    );
  }
  if (
    workflowFilterExists &&
    !context.workflowData.data.length &&
    context.paginationInfo.pageIndex === 0
  ) {
    return (
      <VStack w="full" flex={1} justifyContent="center" h="full" mt="12" mb="12">
        <Text>No results Found</Text>;
      </VStack>
    );
  }

  if (!context.workflowData.data.length && context.paginationInfo.pageIndex === 0) {
    return (
      <Placeholder
        onCreate={() => {
          if (!showUpgradeModal) {
            setShowUpgradeModal(true);
          } else {
            history.push(WORKFLOWS_CREATE_PATH);
          }
        }}
        iconName="wokflow-empty-state.svg"
        addBtnTitle={workflowCreatePermission ? 'Create Workflow' : ''}
        description="No Workflow"
      />
    );
  }
  return (
    <>
      {selectedWorkflows.length ? (
        <HStack p={6} justifyContent="flex-start" w="full">
          <NoPermissionTooltip
            isDisabled={selectedWorkflows.every(w => userAccess.hasUpdateAccess('workflows', w.id))}
          >
            <FormButton
              title="Enable"
              onClick={onBulkToggleEnable(true)}
              disabled={
                selectedWorkflows.every(w => w.enabled) ||
                !selectedWorkflows.every(w => userAccess.hasUpdateAccess('workflows', w.id))
              }
              isLoading={loadingBulkAction === 'enable'}
            />
          </NoPermissionTooltip>

          <NoPermissionTooltip
            isDisabled={selectedWorkflows.every(w => userAccess.hasUpdateAccess('workflows', w.id))}
          >
            <FormButton
              title="Disabled"
              onClick={onBulkToggleEnable(false)}
              variant="secondary"
              disabled={
                selectedWorkflows.every(w => !w.enabled) ||
                !selectedWorkflows.every(w => userAccess.hasUpdateAccess('workflows', w.id))
              }
              isLoading={loadingBulkAction === 'disable'}
            />
          </NoPermissionTooltip>
        </HStack>
      ) : (
        <PageHeader title="" desc="Select one or more workflows to get bulk actions" noButton />
      )}

      <Table
        data={context.workflowData.data}
        columns={columns}
        hoverComponent={props => (
          <HoverComponent {...props} onDelete={onDelete} onClone={context.onCloneWorkflow} />
        )}
        pageCount={context.workflowData.metadata.total_count}
        pageIndex={context.paginationInfo.pageIndex}
        pageSize={context.paginationInfo.pageSize}
        onPaginationChange={context.setPagination}
        isLoading={context.isLoadingWorkflowData}
        showNumberBasedPagination={true}
      />

      {deleteWorkflow && (
        <Modal
          isOpen={dialogAction.isOpen}
          onClose={dialogAction.onClose}
          title={`Delete Workflow`}
          body={
            <Text variant="modal" color="secondary.700">
              Deleting a workflow will remove all the configured actions and relevant data. This
              action cannot be undone. Are you sure you want to proceed?
            </Text>
          }
          primaryButton={
            <FormButton
              variant="danger"
              isLoading={context.isDeletingWorkflow}
              onClick={context.onDeleteWorkflow(deleteWorkflow.id, dialogAction.onClose)}
              title="Delete"
            />
          }
          secondaryButton={
            <FormButton variant="secondary" onClick={dialogAction.onClose} title="Cancel" />
          }
        />
      )}
    </>
  );
};

export default WorkflowTable;
