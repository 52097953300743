import { HStack, VStack, Tag as ChakraTag, TagCloseButton, TagLabel, Text } from '@chakra-ui/react';
import { TagValue } from 'components/TagValue';
import { TAG_STR_MAX_LENGTH } from 'core/const/immutables';
import { truncate } from 'core/helpers/stringUtils';
import { TERTIARY_COLORS } from 'library/theme/colors';
import { forwardRef, ReactNode, Ref } from 'react';

import type { INCIDENT_STATUS_TAGS } from 'library/types';
import Linkify from 'react-linkify';
import { Tooltip, usernameTooltipLabel } from './Tooltip';

type KeyValueProps = {
  variant: 'keyValue';
  keyStr: string;
  value: string;
  color: string;
  showExpanded?: boolean;
  onClose?: () => void;
};

type DefaultProps = { title: string; variant: 'default' };

type FilterProps = {
  variant: 'filter';
  title: string | ReactNode;
  username?: string | null;
  label?: string | null;
  onClose?: () => void;
  tooltipText?: string;
  disableToolTip?: boolean;
  popOver?: any;
};

type IncidentStatusProps = {
  variant: 'incidentStatus';
  status: INCIDENT_STATUS_TAGS;
};

type OwnProps = {
  size?: 'md' | 'lg';
};

type Props = OwnProps & (KeyValueProps | FilterProps | IncidentStatusProps | DefaultProps);

export const Tag = forwardRef((props: Props, ref: Ref<HTMLSpanElement>) => {
  // Filter tag
  if (props.variant === 'filter') {
    const showToolTip = props.disableToolTip ? false : true;
    const { onClose, title, popOver, username, ...rest } = props;
    const truncatedTitle = truncate(title as string, 20);
    const truncatedUsername = username ? `(${truncate(username ?? '', 20)})` : '';
    const tagLabel =
      typeof title === 'string' ? [truncatedTitle, truncatedUsername].join(' ') : title;
    return (
      <ChakraTag ref={ref} {...rest}>
        <TagLabel>
          {showToolTip ? (
            <Tooltip
              label={
                props?.tooltipText ? (
                  props.tooltipText
                ) : username ? (
                  usernameTooltipLabel(title as string, username)
                ) : (
                  <Text>{title}</Text>
                )
              }
              placement="bottom"
            >
              <Text variant="hint" isTruncated maxWidth={'25ch'}>
                {tagLabel}
              </Text>
            </Tooltip>
          ) : (
            <Text variant="hint" isTruncated maxWidth={'25ch'}>
              {tagLabel}
            </Text>
          )}
        </TagLabel>
        {popOver && popOver}
        {onClose && <TagCloseButton onClick={onClose} />}
      </ChakraTag>
    );
  } else if (props.variant === 'incidentStatus') {
    const { status, ...rest } = props;
    return (
      <ChakraTag ref={ref} {...rest} colorScheme={status}>
        {status}
      </ChakraTag>
    );
  } else if (props.variant === 'default') {
    const { title } = props;
    return (
      <ChakraTag ref={ref}>
        <TagLabel>
          <Text variant="body_700" isTruncated maxWidth={'25ch'}>
            {title}
          </Text>
        </TagLabel>
      </ChakraTag>
    );
  } else {
    // It's a key-value tag
    const { keyStr, value, color, onClose, showExpanded, ...rest } = props;
    const tagColor = color || TERTIARY_COLORS[0];

    const keyLabel = showExpanded ? keyStr : truncate(keyStr, TAG_STR_MAX_LENGTH);
    const tagComp = (
      <ChakraTag ref={ref} color={tagColor} {...rest} alignItems="flex-start" px={0}>
        <Text color={tagColor} variant="body_700" whiteSpace="break-spaces" maxWidth="150px" p={1}>
          {keyLabel}
        </Text>
        <HStack
          bgColor={tagColor}
          whiteSpace="break-spaces"
          maxWidth="180px"
          p={1}
          alignSelf="stretch"
          alignItems="flex-start"
        >
          <Text color="brand.white" width="100%" variant="body">
            {showExpanded ? (
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="_blank" rel="noopener noreferrer" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {value}
              </Linkify>
            ) : (
              <TagValue value={value} maxLength={TAG_STR_MAX_LENGTH} />
            )}
          </Text>
          {onClose && <TagCloseButton color="brand.white" onClick={onClose} mt={1} />}
        </HStack>
      </ChakraTag>
    );
    return showExpanded ? (
      tagComp
    ) : (
      <Tooltip label={`${keyStr} : ${value}`} placement="top">
        {tagComp}
      </Tooltip>
    );
  }
});
