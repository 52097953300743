import { Box, HStack } from '@chakra-ui/react';
import { T_WA_UP_MR_ADDED } from 'core/const/tracker';
import { IIAssigne, IIncident } from 'core/interfaces/IIncidents';
import { FormButton } from 'library/atoms';
import { AssigneeType } from 'library/enums';
import { CustomDrawerComponent, IncidentAssignableSearch } from 'library/molecules';
import { IncidentAssignee } from 'library/types';
import { FC, memo, useMemo, useRef, useState } from 'react';
import { AppTracker } from 'shared/analytics/tracker';
import { useAddRespondersMutation } from '../hooks/useAddResponders';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  incident: IIncident;
};

export const AddResponders: FC<Props> = memo(({ isOpen, onClose, incident }) => {
  const [assignees, setAssignees] = useState<Array<IncidentAssignee>>([]);
  const responderIds = useMemo(
    () => incident.additional_responders || [],
    [incident.additional_responders],
  );
  const clearAssigneeSelection = () => {
    setAssignees([]);
  };

  const onAddSuccess = () => {
    const userCount = assignees.filter(assignee => assignee.type === 'user').length;
    const squadCount = assignees.length - userCount;

    AppTracker.track(T_WA_UP_MR_ADDED, {
      'Incident ID': incident.id,
      'Number of Users added as responders': userCount,
      'Number of Squads added as responders': squadCount,
    });
    onDrawerClose();
  };

  const listingRef = useRef();

  const { mutate: addResponders, isLoading: isAdding } = useAddRespondersMutation(
    incident.id,
    onAddSuccess,
  );

  const addAssignees = () => {
    const responders = assignees.map(assignee => ({
      id: assignee.id,
      type: assignee.type as IIAssigne,
    }));
    addResponders({ incidentId: incident.id, responders });
  };

  const onDrawerClose = () => {
    clearAssigneeSelection();
    onClose();
  };

  const onClearSelection = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    listingRef.current?.clearSelection();
    clearAssigneeSelection();
  };

  return (
    <CustomDrawerComponent
      trapFocus={false}
      onClose={onDrawerClose}
      isOpen={isOpen}
      title="Add Responders"
      disableBodyPadding
      size="md"
      footer={
        <HStack spacing={5}>
          <FormButton
            title="Confirm"
            onClick={addAssignees}
            isLoading={isAdding}
            isDisabled={!assignees.length}
          />
          <FormButton
            variant="secondary"
            title="Cancel"
            onClick={onDrawerClose}
            disabled={isAdding}
          />
          <FormButton
            variant="ghost"
            disabled={isAdding || !assignees.length}
            title="Clear All"
            onClick={onClearSelection}
          />
        </HStack>
      }
    >
      <Box>
        <IncidentAssignableSearch
          ref={listingRef}
          allowMulti
          hideTitle
          assignees={responderIds}
          onSelectionUpdate={selection => setAssignees(selection)}
          disallowType={[AssigneeType.Escalationpolicy]}
          searchPlaceholder="Search users / squads"
        />
      </Box>
    </CustomDrawerComponent>
  );
});
