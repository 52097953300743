import { Frame } from 'uie/components';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoaderComponent from '../../components/PageLoader';
import { BillingService, getProperty } from '../../core';
import { exception } from '../../core/exception';
import {
  IWebAppRoutes,
  IWebAppVisibleRoutes,
  ValidRequiresKeys,
} from '../../core/interfaces/IAppRouts';
import Expired from './expired/';
import { OrganizationRoutesComponent } from './index';
import { SettingTab } from './organization/settings/const';
import { configRoutes, subNav, visibleRoutes } from './routes';
import SideBar from './sideBar';
import TopBar from './topBar';

export function calcRoutes(this: OrganizationRoutesComponent) {
  const { organization, isRefresh, featureEnabled } = this.props;

  const { currentUser } = organization;
  const routeAccessMap: string[] = [...(currentUser.u?.abilities.map(a => a.slug) || [])];
  const currentUserRole = currentUser.u!.role;
  const isOrganizationActive = isRefresh || getProperty(organization.plan.p, 'active', true);

  const configRoutesMap = isOrganizationActive ? configRoutes : [];
  const lcrActive =
    organization?.plan?.p?.rules?.['lcr-numbers'] &&
    organization?.plan?.p.rules['lcr-numbers'].quantity !== undefined &&
    organization.plan.p.rules['lcr-numbers'].quantity > 0;

  const canUserViewSomeRBACEntity = (k: ValidRequiresKeys) => {
    return this.props.userAccess.hasReadAccess(k as any);
  };
  const canUserAccessSomeRoute = (k: ValidRequiresKeys) => routeAccessMap.includes(k);

  const filterRoutes = (r: IWebAppRoutes | IWebAppVisibleRoutes) => {
    if (!isOrganizationActive) {
      return !r.requiresActiveOrg;
    }

    if ('showIf' in r && r.showIf) {
      const org = organization.currentOrg.o;
      if (org) {
        if (!r.showIf(org)) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (r.route === '/settings') {
      if (organization.currentOrg.o?.config.obac_enabled) {
        if (currentUserRole === 'stakeholder') {
          return false;
        }
      }
      return (
        currentUserRole === 'account_owner' ||
        (
          SettingTab.filter(
            setting =>
              !!this.props.organization.currentUser.u?.abilities.find(
                a => a.slug === setting.requires,
              ),
          ) || []
        ).length > 0
      );
    }

    if (currentUserRole === 'stakeholder') {
      const needsStakeholder = r.role.includes('stakeholder');
      let canUser = false;

      if (r.requires === 'none' && r.requiresOneOf && r.requiresOneOf.length > 0) {
        canUser = r.requiresOneOf.some(canUserViewSomeRBACEntity);
      } else {
        canUser = canUserViewSomeRBACEntity(r.requires);
      }
      return needsStakeholder || canUser;
    }

    if (currentUserRole === 'user') {
      if (r.requires === 'none' && r.requiresOneOf && r.requiresOneOf.length > 0) {
        return r.requiresOneOf.some(k => canUserAccessSomeRoute(k) || canUserViewSomeRBACEntity(k));
      } else {
        return (
          r.requires === 'none' ||
          canUserAccessSomeRoute(r.requires) ||
          canUserViewSomeRBACEntity(r.requires)
        );
      }
    }

    if (currentUserRole === 'account_owner') {
      return true;
    }

    return false;
  };

  const subNavRoutesMap = subNav.filter(filterRoutes);

  const visibleRoutesMap = visibleRoutes(featureEnabled, lcrActive)
    .filter(filterRoutes)
    .map(v => {
      const org = organization.currentOrg.o;
      if (v['isNew']) {
        if (org) {
          if (org.config['new-tag.enabled'] && !BillingService.isSomeProductTrialPlan(this.props)) {
            v.isNew = true;
          }
        }
      }
      if (org && (org.config.disable_old_schedules || org.config.show_migrated_schedules)) {
        if (v.name === 'New Schedules') {
          v.name = 'Schedules';
          v.isBeta = false;
        }
      }
      switch (v.pricingEntity) {
        case 'postmortem':
        case 'status-page':
        case 'runbooks':
        case 'slo':
        case 'webform':
        case 'ger':
          BillingService.isFeatureDisabled(this.props, v.pricingEntity) ||
          BillingService.isSomeProductTrialPlan(this.props)
            ? (v.proIcon = true)
            : (v.proIcon = false);
          break;
        case 'workflows':
          BillingService.isFeatureDisabled(this.props, v.pricingEntity) ||
          BillingService.isSomeProductTrialPlan(this.props)
            ? (v.proIcon = true)
            : (v.proIcon = false);
          break;
      }
      return v;
    });

  return {
    configRoutesMap,
    subNavRoutesMap,
    visibleRoutesMap,
    isOrganizationActive,
  };
}

export function render(this: OrganizationRoutesComponent) {
  try {
    const { networkState, APP_CONFIG, location, isRefresh, organization } = this.props;
    const { configRoutesMap, isOrganizationActive, subNavRoutesMap, visibleRoutesMap } =
      this.calcRoutes();

    if (!isOrganizationActive || organization.teams.t.length === 0) {
      return <Expired />;
    }

    if (this.state.showUpgradeModal) {
      return <Redirect to="/" push={true} />;
    }

    const onToggleOnCallView = () => {
      this.setState({ showMyOnCall: !this.state.showMyOnCall });
    };

    return (
      <Frame isConnected={networkState === 'online'}>
        <div id="main_app_interface" className={APP_CONFIG.sidebarState}>
          <SideBar
            visibleRoutes={visibleRoutesMap}
            subNavRoutes={subNavRoutesMap}
            pathName={location.pathname}
            isRefresh={isRefresh}
          />
          <TopBar
            visibleRoutes={visibleRoutesMap}
            isRefresh={isRefresh}
            pathName={location.pathname}
            showMyOnCall={this.state.showMyOnCall}
            onToggleOnCallView={onToggleOnCallView}
          />
          <div id="main_app_interface__view">
            <Switch>
              {configRoutesMap.map(r => (
                <Route key={r.route} path={r.route} exact={r.isExact} component={r.component} />
              ))}
              {subNavRoutesMap.map(r => (
                <Route key={r.route} path={r.route} exact={r.isExact} component={r.component} />
              ))}
              {visibleRoutesMap.map(r => (
                <Route key={r.route} path={r.route} exact={r.isExact} component={r.component} />
              ))}
              <Redirect from="*" to="/" />
            </Switch>
          </div>
        </div>
      </Frame>
    );
  } catch (err: any) {
    exception.handle('E_ERROR_RENDERS', {
      err,
      component: 'OrganizationRoutesComponent',
    });
    return <PageLoaderComponent />;
  }
}
