import { Search } from '.';
import { startMaps, statusMap } from './autoCompletes';

export function onExecute(this: Search) {
  this.setState({ errors: {}, showHelp: false });
  try {
    const matcher = new RegExp(/(\w*:\s)+(("[^:]*")|(\([^:]*\))|\S*)/gi);
    const tokenizer = this.state.search.matchAll(matcher);

    const errors: any = {};
    const query: any = {};
    let done = false;

    while (!done) {
      const { value, done: isDone } = tokenizer.next();

      if (!value || !!isDone) {
        done = true;
        break;
      }

      const keys = (value[0] as string).split(':');

      if (keys.length > 1) {
        if (keys.length === 2) {
          query[keys[0]] = keys[1].trim().replace(/[()"]/gi, '');
        } else {
          query[keys[0]] = keys.slice(1, -1).reduceRight((c, n, i) => {
            const reducer: any = {};
            if (i === 0) {
              reducer[n.trim()] = keys.slice(-1)[0].trim().replace(/[()"]/gi, '');
              return reducer;
            }
            reducer[n.trim()] = c;
            return reducer;
          }, {});
        }
      } else {
        errors.query = 'invalid query';
      }
    }
    const msg: string = this.state.search.replace(matcher, '').trim();
    if (msg) {
      query.message = msg;
    }

    const knownTokens = [...Object.keys(this._completionMap), ...startMaps.map(m => m.name)];

    Object.entries(query).forEach(([token, action]) => {
      const actionType = typeof action;
      if (knownTokens.includes(token)) {
        if (token === 'goto') {
          if (!(actionType === 'string' || actionType === 'object')) {
            errors.query = 'invalid query at goto';
          }
        } else {
          if (actionType !== 'string') {
            errors.query = `invalid query at \`${token}:\``;
          }
        }
      } else {
        errors.unknowns = `${
          errors.tokens ? ` ${errors.tokens},` : 'Unknown tokens => '
        } \`${token}:\``;
      }
    });

    const queryLength = Object.entries(query).length;
    if ((queryLength > 1 && query.goto) || (queryLength > 1 && query.help)) {
      errors.goto = '`goto:` or `help:`  cannot be mixed with search tokens';
    }

    if (Object.entries(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.queryOn(query);
    }
  } catch (err: any) {
    this.setState({ errors: { query: 'Invalid rules' } });
  }
}

export function queryOn(this: Search, query: any) {
  const errors: any = {};
  if (query.help) {
    try {
      const help = this.props.integrations.i.find(i => i.type === query.help);

      if (help && help.supportDoc) {
        window.open(help.supportDoc, '_blank');
      } else {
        throw new window.Error('invalid');
      }
    } catch (err: any) {
      errors.help = 'looks like there is a query error in `help:`';
      this.setState({ errors });
    }
    return;
  }

  if (query.message) {
    try {
      const filter = ['status', 'after', 'before', 'integration', 'service'].map(f => {
        const filterMapObj = query[f];
        if (filterMapObj && typeof filterMapObj === 'string') {
          switch (f) {
            case 'status': {
              if (!statusMap.some(s => s.name === filterMapObj)) {
                throw new window.Error('`status:` has invalid signature');
              }
              return `status:${filterMapObj}`;
            }
            case 'after': {
              if (query.in === 'postmortems') {
                return `created_at>${filterMapObj}`;
              }
              return `timeOfCreation>${filterMapObj}`;
            }
            case 'before': {
              if (query.in === 'postmortems') {
                return `created_at<${filterMapObj}`;
              }
              return `timeOfCreation<${filterMapObj}`;
            }
            case 'service': {
              const filteredService = this.props.organization.services.s.find(
                s => s.slug === filterMapObj,
              );

              if (!filteredService) {
                throw new window.Error('service in `service:` token is deleted or invalid');
              }

              return `service:${filteredService.id}`;
            }
            case 'integration': {
              const filteredIntegration = this.props.integrations.i.find(
                i => i.type === filterMapObj,
              );

              if (!filteredIntegration) {
                throw new window.Error('integration in `integration:` token is deleted or invalid');
              }

              return `integrationType:${filteredIntegration._id}`;
            }
            default:
              return undefined;
          }
        } else {
          return undefined;
        }
      });

      const incidentQueryObject = {
        filter,
        q: [query.message],
        sort: query.sort,
      };

      this.searchIncidents(incidentQueryObject, query.in || 'incidents');
    } catch (err: any) {
      // eslint-disable-next-line no-constant-condition
      errors.goto = typeof err.message.includes(':')
        ? err.message
        : 'looks like there is a query error while searching incidents';
      this.setState({ errors });
    }
  }
}
