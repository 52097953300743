import axios from 'axios';
import qs from 'query-string';
import { API } from '../api';
import {
  IAnalyticsLogs,
  IAnalyticsTabularRequest,
  IAnalyticsTimeSeriesRequest,
  ITabularDataResponse,
  ITimeSeriesDataResponse,
} from '../interfaces/IAnalytics';

class AnalyticsService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/analytics`;

  public executeQuery = (props: IAnalyticsTimeSeriesRequest | IAnalyticsTabularRequest) =>
    axios.get<{ data: ITimeSeriesDataResponse[] | ITabularDataResponse[] }>(
      `${this._api}?${qs.stringify({
        ...props,
        time_range: JSON.stringify({
          ...props.time_range,
        }),
        filter_by: JSON.stringify({
          ...props.filter_by,
        }),
      })}`,
    );

  public getLogs = () => axios.get<{ data: IAnalyticsLogs }>(`${this._api}/logs`);
}

export default AnalyticsService;
