import { IconButton, Tooltip } from 'uie/components';
import { ReactNode, FC } from 'react';

type Props = {
  label: string;
  onClick: () => void;
  children: ReactNode;
  isDisabled?: boolean;
};

export const HoverButton: FC<Props> = ({ label, onClick, children, isDisabled }) => {
  return (
    <Tooltip label={label} offset={{ left: '-120px', top: '-50px' }}>
      <IconButton disabled={isDisabled} style={{ padding: 0 }} onClick={onClick}>
        {children}
      </IconButton>
    </Tooltip>
  );
};
