import React from 'react';
import { JSXResponseForEntity } from '../helpers';

const ResponsesForIagAdditionalandRespondersAction: React.FC<
  Omit<JSXResponseForEntity, 'logMessage' | 'getEntityName'>
> = ({ log, getEntityComponent, getEntityComponentWithTruncatedName }) => {
  const result = log.reason.split(' by ');
  return log.additionalInfo && 'wf_exec_id' in log.additionalInfo
    ? getEntityComponent({
        componentType: 'linkWPopover',
        name: result[1],
        type: 'workflow',
        id: log.assignedBy,
        workflowId: log.workflow_id,
        workflowExecutionId: log.additionalInfo?.wf_exec_id,
        workflowActionIndex: log.additionalInfo?.action_exec_id,
        popoverPlacement: 'left',
      })
    : getEntityComponentWithTruncatedName({
        componentType: 'linkWPopover',
        name: result[1],
        type: 'user',
        id: log.assignedBy,
        popoverPlacement: 'left',
      });
};

export default ResponsesForIagAdditionalandRespondersAction;
