import { EntityType, hasReadAccessToEntity } from '../../navigation-flows/helpers';
import { ORG } from '../types';
import { useGetEntityLink, useHasEntityObject, useGetEntityUsername } from '../util';
import { Link } from 'react-router-dom';
import { NameComponent } from 'library/atoms';

const MAX_NAME_LENGTH = 20;

export const getNameComponent = (
  name: string,
  maxLength: number,
  isHyperlink = false,
  username = '',
) => {
  return (
    <NameComponent
      name={name}
      maxLength={maxLength ?? MAX_NAME_LENGTH}
      isHyperlink={isHyperlink}
      username={username}
    />
  );
};

export const useGetAssigneeComponent = (
  id: string,
  name: string,
  isHyperlink = false,
  type: EntityType = 'user',
  organization?: ORG,
) => {
  const assigneeLink = useGetEntityLink(type, id);
  const hasEntityObject = useHasEntityObject(type, id);
  const username = useGetEntityUsername(type, id);

  const canRead = hasReadAccessToEntity(type, organization) && hasEntityObject;

  const nameComp = getNameComponent(name, MAX_NAME_LENGTH, canRead && isHyperlink, username);

  return canRead ? (
    <Link to={assigneeLink} style={{ marginTop: 0 }}>
      {nameComp}
    </Link>
  ) : (
    nameComp
  );
};
