import {
  Box,
  Button,
  Divider,
  HStack,
  Link,
  Radio,
  RadioGroup,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import Editor, { useMonaco } from '@monaco-editor/react';
import { JSONCodeBlock } from 'uie/components';
import { EventWebhookHooksService } from 'core';
import { toSentenceCase } from 'core/helpers/stringUtils';
import {
  CUSTOM_PAYLOAD_TEMPLATE_TYPE,
  IEventTestWebhookPayload,
  IEventWebhookValidatePayload,
} from 'core/interfaces/IEventWebHooks';
import { cloneDeep } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { CustomDrawerComponent, Loader } from 'views/shared/components';
import { MONACO_EDITOR_OPTIONS } from 'views/shared/constants';
import { useChakraToast } from 'views/shared/hooks';
import { AnyObject } from 'yup/lib/types';

import { WebhookContext } from '../create/create-webhook';
import { PayloadValidation } from '../payload-validation/payload-validation';
import { PayloadValidationAdapter } from '../payload-validation/payload-validation.adapter';
import { PAYLOAD_VALIDATION_MODEL } from '../payload-validation/payload-validation.type';
import { getHeaderFromHeadersArr, getWebhookUpsertData, WEBHOOK_ACTIONS } from '../store';
import {
  PAYLOAD_TYPES,
  TRIGGER_OPTIONS,
  WEBHOOK_STEPPER_PROPTYPE,
  WEBHOOK_TYPES,
} from '../webhook-const';
import styles from './choose-payload.module.css';
import { EVENT_TEMPLATES_CODE } from './event-templates';

const DEFAULT_EDITOR_CODE = '// write your code here';
const DUMMY_EVENT_TEMPLATE = '{"name":"John", "age":30, "city":"New York"}';

import { AppTracker } from '../../../../../../shared/analytics/tracker';
import {
  T_WA_UP_WEBHOOK_ADDED,
  T_WA_UP_WEBHOOK_EDITED,
  T_WA_UP_WEBHOOK_TESTED,
} from '../../../../../../core/const/tracker';

const enum VALIDATION_STATUS {
  REQUIRED,
  NOT_REQUIRED,
  IN_PROCESS,
  SUCCESS,
  FAILURE,
}

export const ChoosePayload = ({ onSave, onCancel }: WEBHOOK_STEPPER_PROPTYPE) => {
  const _eventWebHookService = new EventWebhookHooksService();
  const payloadValAdapter = new PayloadValidationAdapter();
  const { state, dispatch } = useContext(WebhookContext);
  const monaco = useMonaco();

  const [payloadType, setPayloadType] = useState(
    (state.trigger_type === WEBHOOK_TYPES.MANUAL
      ? PAYLOAD_TYPES.CUSTOM
      : state.payload_type ?? PAYLOAD_TYPES.STANDARD) as string,
  );
  const [code, setCode] = useState(state.custom_payload ?? DEFAULT_EDITOR_CODE);
  const [customPayloadTemplates, setCustomPayloadTemplates] = useState(
    [] as CUSTOM_PAYLOAD_TEMPLATE_TYPE[],
  );

  const [eventTemplate, setEventTemplate] = useState(EVENT_TEMPLATES_CODE[0]);
  const [selectedTemplate, setSelectedTemplate] = useState({
    slug: state.custom_payload_template_slug,
  } as CUSTOM_PAYLOAD_TEMPLATE_TYPE);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [valStatus, setValStatus] = useState(VALIDATION_STATUS.NOT_REQUIRED);
  const [valResultCount, setValResultCount] = useState({
    success: 0,
    failure: 0,
  });
  const [valResult, setValResult] = useState([] as PAYLOAD_VALIDATION_MODEL[]);
  const [isSaving, setIsSaving] = useState(false);
  const [sqEventTemplateCode, setSqEventTemplateCode] = useState(EVENT_TEMPLATES_CODE);

  const isEditMode = !!state.id;

  useEffect(() => {
    if (state.triggers.length) {
      const appliedTriggers = cloneDeep(state.triggers) as AnyObject[];
      appliedTriggers.forEach(t => {
        t.name =
          TRIGGER_OPTIONS.find(
            trigger => trigger.event_class === t.event_class && trigger.event_type === t.event_type,
          )?.label ?? '';
      });

      const appliedTriggersName = appliedTriggers.map(trigger => trigger.name);
      setSqEventTemplateCode(
        EVENT_TEMPLATES_CODE.filter(templateCode =>
          appliedTriggersName.includes(templateCode.name),
        ),
      );
    }

    _eventWebHookService
      .getCustomPayloadTemplates()
      .then(({ data }) => {
        setCustomPayloadTemplates(data.data);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (monaco) {
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: false,
      });
    }
  }, [monaco]);

  useEffect(() => {
    if (customPayloadTemplates.length) {
      updateTemplateCode(state.custom_payload_template_slug, false);
    }
  }, [customPayloadTemplates]);

  const [showErrorToast, showSuccessToast] = useChakraToast();

  const save = () => {
    if (payloadType === PAYLOAD_TYPES.CUSTOM && !code) {
      showErrorToast('Please add payload');
      return;
    }

    const payload = {
      payload_type: payloadType,
      custom_payload: code,
      custom_payload_template_slug: selectedTemplate.slug,
    };
    dispatch({ type: WEBHOOK_ACTIONS.SAVE_PAYLOAD, payload });

    const data = getWebhookUpsertData(state, payload);
    setIsSaving(true);

    if (isEditMode) {
      _eventWebHookService
        .update(data.id, data)
        .then(() => {
          onAPISaveCompletion('Webhook updated successfully!!', true);
          AppTracker.track(T_WA_UP_WEBHOOK_EDITED);
        })
        .catch(err => {
          onAPISaveCompletion(err.response.data.meta.error_message, false);
        });
    } else {
      _eventWebHookService
        .create(data)
        .then(() => {
          onAPISaveCompletion('Webhook created successfully!!', true);
          AppTracker.track(T_WA_UP_WEBHOOK_ADDED);
        })
        .catch(err => {
          onAPISaveCompletion(err.response.data.meta.error_message, false);
        });
    }
  };

  const onAPISaveCompletion = (message: string, isSuccess: boolean) => {
    setIsSaving(false);
    isSuccess ? showSuccessToast(message) : showErrorToast(message);
    if (isSuccess) {
      onSave();
      onCancel();
    }
  };

  const updateTemplateCode = (val: string, isUserActionTriggered = true) => {
    let template = customPayloadTemplates[0];
    if (val) {
      template = customPayloadTemplates.find(template => template.slug === val) ?? template;
    }
    setSelectedTemplate(template);
    if (isUserActionTriggered || !code || code === DEFAULT_EDITOR_CODE) {
      // update code in timeout as if we do it in sync manner then setCode is getting invoked
      //  before template value is getting updated and again setting valStatus to required
      setTimeout(() => {
        setCode(template.template_code);
      }, 500);
    }
  };

  const updateEventTemplate = (val: string) => {
    const template =
      EVENT_TEMPLATES_CODE.find(template => template.slug === val) ?? EVENT_TEMPLATES_CODE[0];
    setEventTemplate(template);
  };

  const updateCode = (value = '') => {
    setCode(value);
    if (
      (isEditMode && value === state.custom_payload) ||
      value === selectedTemplate.template_code
    ) {
      setValStatus(VALIDATION_STATUS.NOT_REQUIRED);
    } else {
      setValStatus(VALIDATION_STATUS.REQUIRED);
    }
  };

  const testPayload = () => {
    const testUrlObj = state.urls[0];
    const payloadCode =
      payloadType === PAYLOAD_TYPES.CUSTOM ? code : EVENT_TEMPLATES_CODE[0].payload_content;
    const header = getHeaderFromHeadersArr(state.headers);

    let payloadData: IEventTestWebhookPayload = {
      method: testUrlObj.method,
      url: testUrlObj.url,
      payload: payloadCode,
      header,
      trigger_type: state.trigger_type,
      payload_type: payloadType,
    };

    if (state.trigger_type === WEBHOOK_TYPES.AUTOMATIC) {
      payloadData = { ...payloadData, event_trigger: state.triggers[0] };
    }
    _eventWebHookService
      .testWebhook(payloadData)
      .then(({ data: { data } }) => {
        showSuccessToast(data);
        AppTracker.track(T_WA_UP_WEBHOOK_TESTED);
      })
      .catch(err => {
        const msg = err.response?.data?.meta?.error_message ?? 'Test Webhook failed';
        showErrorToast(msg);
      });
  };

  const validatePayload = () => {
    setValStatus(VALIDATION_STATUS.IN_PROCESS);
    const valPayload: IEventWebhookValidatePayload = {
      input_payload: code,
      event_triggers: state.triggers,
      trigger_type: state.trigger_type,
      payload_type: payloadType,
    };

    _eventWebHookService
      .validatePayload(valPayload)
      .then(({ data: { data } }) => {
        const result = payloadValAdapter.adaptToModel(data, state.trigger_type);
        const failedCount = result.filter(res => !res.isPassed).length;
        setValResult(result);
        setValResultCount({
          success: result.length - failedCount,
          failure: failedCount,
        });
        setValStatus(failedCount ? VALIDATION_STATUS.FAILURE : VALIDATION_STATUS.SUCCESS);
        if (result.length) {
          setIsDrawerOpen(true);
        }
      })
      .catch(err => {
        setValStatus(VALIDATION_STATUS.REQUIRED);
        showErrorToast('Sorry, not able to validate at moment. Please try again after sometime');
      });
  };

  return (
    <>
      {isSaving ? (
        <Loader />
      ) : (
        <>
          <Box paddingX={6} paddingY={3} maxWidth="80%">
            <Text className={styles.heading}>Configure Payload</Text>
            <Text className={styles.subheading}>
              Choose payload type and configure your payload
            </Text>
          </Box>
          <VStack spacing={4} align="flex-start" padding="0px 20px">
            <Text className={styles.webhookTypeText}>
              You have chosen {toSentenceCase(state.trigger_type)} Webhook as your webhook type
            </Text>
            {state.trigger_type === WEBHOOK_TYPES.AUTOMATIC && (
              <RadioGroup value={payloadType} onChange={setPayloadType}>
                <VStack spacing={2} alignItems="flex-start">
                  <Radio value={PAYLOAD_TYPES.STANDARD}>
                    <HStack>
                      <Text>Standard Squadcast Payload</Text>
                    </HStack>
                  </Radio>
                  <Radio value={PAYLOAD_TYPES.CUSTOM}>
                    <Text>Custom Payload</Text>
                  </Radio>
                </VStack>
              </RadioGroup>
            )}
            {payloadType === PAYLOAD_TYPES.CUSTOM ? (
              <>
                <VStack alignItems="flex-start" spacing={0}>
                  <Text className={styles.controlTitle}>Select Payload Template *</Text>
                  <Text className={styles.subheading}>
                    Choose from one of the pre-configured templates or create your own payload
                  </Text>
                </VStack>
                <VStack alignItems="flex-start" spacing={1}>
                  <Select
                    onChange={event => updateTemplateCode(event.target.value)}
                    value={selectedTemplate.slug}
                    minWidth="400px"
                    maxWidth="40%"
                  >
                    {customPayloadTemplates.map(template => (
                      <option key={template.slug} value={template.slug}>
                        {template.name}
                      </option>
                    ))}
                  </Select>
                  <Text className={styles.subheading}>
                    Want to add more commonly used templates? Just create a PR on{' '}
                    <Link
                      color="#2C60D5"
                      href="https://github.com/SquadcastHub/squadcast-webhook-templates"
                      isExternal
                      textDecoration="underline"
                    >
                      Github
                    </Link>
                  </Text>
                </VStack>
                <VStack alignItems="flex-start" spacing={0}>
                  <Text className={styles.controlTitle}>Payload</Text>
                  <Text className={styles.subheading}>
                    To reference the payload variables on the right, please prefix the variable with
                    root
                  </Text>
                </VStack>
                <VStack alignItems="flex-start" className={styles.mainOutlineBox} spacing={4}>
                  <HStack alignItems="space-between" spacing={6} width="100%">
                    <VStack alignItems="flex-start" spacing={2} flex="60">
                      <Text className={styles.controlTitle}>Payload Editor</Text>
                      <VStack
                        alignItems="space-between"
                        className={styles.outlineBox}
                        padding="0px"
                      >
                        <Editor
                          language="json"
                          height={valStatus === VALIDATION_STATUS.NOT_REQUIRED ? '600px' : '550px'}
                          value={code}
                          onChange={val => updateCode(val)}
                          options={MONACO_EDITOR_OPTIONS}
                          width="100%"
                        />
                        {valStatus !== VALIDATION_STATUS.NOT_REQUIRED && (
                          <VStack
                            width="100%"
                            className={styles.valBlock}
                            alignItems="flex-start"
                            spacing={2}
                          >
                            {[VALIDATION_STATUS.IN_PROCESS, VALIDATION_STATUS.REQUIRED].includes(
                              valStatus,
                            ) && (
                              <Text>
                                You have modified the payload. Validate the payload before saving
                                Webhook
                              </Text>
                            )}
                            {[VALIDATION_STATUS.SUCCESS, VALIDATION_STATUS.FAILURE].includes(
                              valStatus,
                            ) && (
                              <>
                                <Text className={styles.resultTitle}>
                                  {`Payload Validat${
                                    valStatus === VALIDATION_STATUS.SUCCESS
                                      ? 'ed Successfully'
                                      : 'ion Failed!'
                                  }`}
                                </Text>
                                <Text
                                  className={styles.resultMsg}
                                  onClick={() => {
                                    if (valResultCount.failure + valResultCount.success > 0) {
                                      setIsDrawerOpen(true);
                                    }
                                  }}
                                >
                                  {`Payload Validation Results : Errors(${valResultCount.failure}) Success(${valResultCount.success})`}
                                </Text>
                              </>
                            )}
                            <HStack spacing={4} alignItems="center">
                              <Button
                                className={styles.cancelBtn}
                                disabled={valStatus === VALIDATION_STATUS.IN_PROCESS}
                                variant="outline"
                                onClick={() => validatePayload()}
                              >
                                Validate Payload
                              </Button>
                              <Text
                                className={styles.resetText}
                                onClick={() => updateCode(selectedTemplate.template_code)}
                              >
                                Reset
                              </Text>
                            </HStack>
                          </VStack>
                        )}
                      </VStack>
                    </VStack>
                    <VStack alignItems="flex-start" spacing={2} flex="40">
                      <Text className={styles.controlTitle}>Select Event Template</Text>
                      <Select
                        onChange={event => updateEventTemplate(event.target.value)}
                        value={eventTemplate.slug}
                        maxWidth="fit-content"
                      >
                        {EVENT_TEMPLATES_CODE.map(template => (
                          <option key={template.slug} value={template.slug}>
                            {template.name}
                          </option>
                        ))}
                      </Select>
                      <JSONCodeBlock
                        code={JSON.parse(eventTemplate.payload_content ?? DUMMY_EVENT_TEMPLATE)}
                        shellProps={{
                          minHeight: '600px',
                          minWidth: '400px',
                        }}
                      />
                    </VStack>
                  </HStack>
                </VStack>
              </>
            ) : (
              <>
                <Select
                  onChange={event => updateEventTemplate(event.target.value)}
                  value={eventTemplate.slug}
                  maxWidth="fit-content"
                >
                  {sqEventTemplateCode.map(template => (
                    <option key={template.slug} value={template.slug}>
                      {template.name}
                    </option>
                  ))}
                </Select>
                <JSONCodeBlock
                  code={JSON.parse(eventTemplate.payload_content ?? DUMMY_EVENT_TEMPLATE)}
                  shellProps={{
                    minHeight: '400px',
                    minWidth: 'calc(60%)',
                  }}
                />
                <Text>
                  To learn more about Standard Payload format data{' '}
                  <Link
                    color="#2C60D5"
                    href="https://developers.squadcast.com/outgoing-webhooks/payload/v2/"
                    isExternal
                    textDecoration="underline"
                  >
                    Click Here
                  </Link>
                </Text>
              </>
            )}
            <Divider height={0.5} backgroundColor="shades.smoke" />
            <HStack
              justifyContent="space-between"
              width="100%"
              className={styles.footer}
              paddingRight="16px"
            >
              <HStack>
                <Button
                  className={styles.submitBtn}
                  isDisabled={
                    payloadType === PAYLOAD_TYPES.CUSTOM &&
                    [
                      VALIDATION_STATUS.IN_PROCESS,
                      VALIDATION_STATUS.REQUIRED,
                      VALIDATION_STATUS.FAILURE,
                    ].includes(valStatus)
                  }
                  variant="solid"
                  type="submit"
                  onClick={save}
                >
                  Save
                </Button>
                <Button
                  className={styles.cancelBtn}
                  isDisabled={
                    payloadType === PAYLOAD_TYPES.CUSTOM &&
                    valStatus === VALIDATION_STATUS.IN_PROCESS
                  }
                  variant="outline"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </HStack>
              <Button
                className={styles.cancelBtn}
                onClick={() => testPayload()}
                isDisabled={
                  payloadType === PAYLOAD_TYPES.CUSTOM &&
                  [
                    VALIDATION_STATUS.IN_PROCESS,
                    VALIDATION_STATUS.REQUIRED,
                    VALIDATION_STATUS.FAILURE,
                  ].includes(valStatus)
                }
                variant="outline"
                alignSelf="flex-end"
                float="right"
              >
                Test Webhook
              </Button>
            </HStack>
          </VStack>
          <CustomDrawerComponent
            onClose={() => setIsDrawerOpen(false)}
            isOpen={isDrawerOpen}
            title="Payload Validation Results"
            disableBodyPadding
            showBackIcon
          >
            <PayloadValidation input={code} valResultList={valResult} />
          </CustomDrawerComponent>
        </>
      )}
    </>
  );
};
