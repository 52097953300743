import Axios from 'axios';
import { API } from 'core';

export const updateGerRuleset = (
  gerId: string,
  alertVersion: string,
  alertName: string,
  serviceId?: string,
) => {
  const body = {
    catch_all_action: {
      route_to: serviceId ?? '',
    },
  };
  return Axios.patch(
    `${API.config.batman}/organizations/${API.config.organizationId}/global-event-rules/${gerId}/rulesets/${alertVersion}/${alertName}`,
    body,
  );
};
