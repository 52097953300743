import * as React from 'react';
import { SVGProps } from 'react';

const SvgAwsLambdaLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="AWS_Lambda_Logo_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.AWS_Lambda_Logo_svg__st0{fill-rule:evenodd;clip-rule:evenodd;fill:url(#AWS_Lambda_Logo_svg__Rectangle_00000176033572433711266440000007209712245684575642_)}'
      }
    </style>
    <g id="AWS_Lambda_Logo_svg__Icon-Architecture_x2F_32_x2F_Arch_x5F_AWS-Lambda_x5F_32">
      <g id="AWS_Lambda_Logo_svg__Icon-Architecture-BG_x2F_32_x2F_Compute">
        <linearGradient
          id="AWS_Lambda_Logo_svg__Rectangle_00000088852925704430908790000005670762913818099081_"
          gradientUnits="userSpaceOnUse"
          x1={-279.825}
          y1={421.25}
          x2={-279.325}
          y2={421.75}
          gradientTransform="matrix(40 0 0 -40 11193 16870)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#c8511b',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#f90',
            }}
          />
        </linearGradient>
        <path
          id="AWS_Lambda_Logo_svg__Rectangle"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'url(#AWS_Lambda_Logo_svg__Rectangle_00000088852925704430908790000005670762913818099081_)',
          }}
          d="M0 0h20v20H0z"
        />
      </g>
      <path
        id="AWS_Lambda_Logo_svg__AWS-Lambda_Icon_32_Squid"
        d="M7.2 16.5H4.1l3.4-7.2L9 12.5l-1.8 4zm.5-7.9c0-.1-.1-.1-.2-.1s-.2.1-.2.1l-3.8 8v.2c.1.2.2.2.2.2h3.6c.1 0 .2-.1.2-.1l2-4.2v-.2L7.7 8.6zm8.3 7.9h-2.9L8.3 6.1S8.2 6 8.1 6H6.2V3.5H10l4.7 10.4c0 .1.1.1.2.1H16v2.5zm.3-3h-1.2L10.3 3.1c0-.1-.1-.1-.2-.1H5.9c-.1 0-.2.1-.2.2v3c0 .1 0 .1.1.2 0 0 .1.1.2.1h2l4.7 10.4c0 .1.1.1.2.1h3.3c.1 0 .2-.1.2-.2v-3c.1-.2 0-.3-.1-.3z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);

export default SvgAwsLambdaLogo;
