import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Heading: ComponentStyleConfig = {
  variants: {
    h3: {
      fontSize: '27px',
      fontWeight: 400,
      color: 'gray.900',
    },
  },
  defaultProps: {
    variant: 'h3',
  },
};
