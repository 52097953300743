import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';
import {
  REQUEST_ORG_USER_ACL,
  REQUEST_ORG_USER_ACL_FAIL,
  REQUEST_ORG_USER_ACL_SUCCESS,
} from '../../const/acl';

const initialOrganizationAbilities = INITIAL_STATE.organization.acl;

const organizationUserAcl = (state = initialOrganizationAbilities, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ action: action.type, a: null, error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_USER_ACL:
      return {
        ...state,
        ...{ action: action.type, error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_USER_ACL_SUCCESS:
      return {
        ...state,
        ...{ action: action.type, a: action.payload.acl, error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_USER_ACL_FAIL:
      return {
        ...state,
        ...{
          action: action.type,
          a: null,
          error: { type: REQUEST_ORG_USER_ACL_FAIL, reason: action.payload.error },
        },
      };
    default:
      return state;
  }
};

export default organizationUserAcl;
