import { Box, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { FormikProps } from 'formik';
import { AssigneeType } from 'library/enums';
import {
  DropdownOptions,
  FormInput,
  FormMDE,
  FormSelect,
  Loader,
  TagGroup,
} from 'library/molecules';
import { THEME_COLORS } from 'library/theme/colors';
import { isArray } from 'lodash';

import { OtherProps } from '../common/interfaces';
import { FormValues, filterDropdownOptions } from '../interfaces/common';
import { ASSIGNEE_OPTIONS } from './selectOptions';
import { setIncidentObj } from './temp-store';
import { AnyObject } from 'yup/lib/types';
import { CREATE_INCIDENT_FORM_ID } from '.';
import { PRIORITY_OPTIONS } from 'library/common';

const CreateIncidentTemplate = (props: OtherProps & FormikProps<FormValues>) => {
  const {
    values,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    errors,
    isLoading,
    assigneeList,
    fileUploadHandler,
    serviceListForFilters,
    tagData,
    additionalFormElements,
  } = props;
  if (isLoading) return <Loader />;

  const setObjFieldValue = (field: string, value: any) => {
    setFieldValue(field, value);
    setIncidentObj(field, value);
  };

  const handleServiceChange = (
    field: string,
    value: DropdownOptions | DropdownOptions[] | null,
  ) => {
    setObjFieldValue(field, value);

    if (!isArray(value) && typeof value == 'object') {
      const epID = value?.meta?.ep;
      const epObj = epID
        ? assigneeList?.getIncidentAssignableEntities.escalationPolicies.find(ep => ep.ID === epID)
        : null;
      if (epObj) {
        const assigneeValue = {
          label: epObj.name,
          value: epObj.ID,
          type: AssigneeType.Escalationpolicy,
        };

        setObjFieldValue('assignedkey', ASSIGNEE_OPTIONS[2]);
        setObjFieldValue('assigned', assigneeValue);
      }
    }
  };

  const getAssignProps = (value: filterDropdownOptions | null): filterDropdownOptions[] => {
    const data = assigneeList?.getIncidentAssignableEntities ?? null;
    if (!data || !value) return [];

    const getAsigneeDropdown = (obj: AnyObject) => {
      return {
        label: obj.name,
        value: obj.ID,
        assigneeType: value.value as AssigneeType,
        username: obj.usernameForDisplay,
      };
    };

    if (value && value.value === AssigneeType.User) return data.users.map(getAsigneeDropdown);
    if (value && value.value === AssigneeType.Squad) return data.squads.map(getAsigneeDropdown);
    if (value && value.value === AssigneeType.Escalationpolicy)
      return data.escalationPolicies.map(getAsigneeDropdown);

    return [];
  };

  return (
    <form id={CREATE_INCIDENT_FORM_ID} onSubmit={handleSubmit}>
      <VStack px={4} pt={4} alignItems="flex-start" rowGap={4}>
        <FormInput
          label="Incident Title*"
          fieldName="title"
          placeholder="Incident Title"
          onChange={setObjFieldValue}
          value={values.title}
          error={errors.title}
        />
        <FormMDE
          label="Incident Description"
          fieldName="description"
          onChange={setObjFieldValue}
          placeholder="Incident Description (supports markdown)"
          value={values && values.description}
          fileUploadHandler={fileUploadHandler}
        />
        <FormSelect
          options={PRIORITY_OPTIONS}
          value={values.priority}
          onChange={setObjFieldValue}
          onBlur={setFieldTouched}
          error={errors.priority}
          label={'Priority*'}
          isMulti={false}
          fieldName="priority"
          isDisabled={false}
          controlShouldRenderValue={true}
          isClearable={false}
          variant="priority"
        />
        <FormSelect
          options={serviceListForFilters}
          value={values.services}
          onChange={handleServiceChange}
          onBlur={setFieldTouched}
          error={errors.services}
          label={'Service*'}
          isMulti={false}
          fieldName="services"
          isDisabled={false}
          controlShouldRenderValue={true}
        />
        <Box width="100%">
          <HStack alignItems="flex-end" justifyContent="space-around">
            <FormSelect
              options={ASSIGNEE_OPTIONS}
              value={values.assignedkey}
              onChange={(field, value) => {
                setObjFieldValue(field, value);
                setObjFieldValue('assigned', null);
              }}
              onBlur={setFieldTouched}
              error={null}
              label={'Assign To*'}
              isMulti={false}
              fieldName="assignedkey"
              isDisabled={false}
              controlShouldRenderValue={true}
              defaultValue={ASSIGNEE_OPTIONS[0]}
              isClearable={false}
            />
            <Spacer />
            <FormSelect
              hideLabel={true}
              options={getAssignProps(values.assignedkey)}
              variant="assignee"
              value={values.assignedkey ? values.assigned : []}
              onChange={setObjFieldValue}
              onBlur={setFieldTouched}
              error={null}
              label={'Assign To'}
              isMulti={false}
              fieldName="assigned"
              isDisabled={!values.assignedkey}
              controlShouldRenderValue={true}
              styles={{
                container: () => ({
                  minWidth: '272px',
                }),
              }}
              useCustomValue={true}
              customValue={'single'}
            />
          </HStack>
          {errors.assigned && (
            <Text mt="5" color={THEME_COLORS.brand.red} variant="body">
              {errors.assigned}
            </Text>
          )}
        </Box>
        <VStack rowGap={1} alignItems="flex-start" marginBottom="28px !important">
          <Text variant="formInput_800" color="secondary.700">
            Tags
          </Text>
          <TagGroup
            tags={values?.tags ? values?.tags : []}
            allTags={tagData?.getIncidentActiveTags}
            getUpdatedTags={(tags: FormValues['tags']) => {
              setObjFieldValue('tags', tags);
            }}
            showError={errors.tags}
          />
        </VStack>
      </VStack>
      {additionalFormElements}
    </form>
  );
};

export default CreateIncidentTemplate;
