import { AddUsers, IUser } from '.';

export function handleDrag(this: AddUsers, event: React.DragEvent<HTMLDivElement>) {
  event.preventDefault();
  if (!this.props.showCsvDrop) {
    this.props.setShowCsvDrop(true);

    setTimeout(() => {
      this.props.setShowCsvDrop(false);
    }, 2000);
  }
}

export function handleFileDrop(this: AddUsers, event: React.DragEvent<HTMLDivElement>) {
  event.preventDefault();
  const file = event.dataTransfer.files[0];

  this.parseCSVFile(file);
}

export function handleFileUpload(this: AddUsers, event: React.ChangeEvent<HTMLInputElement>) {
  event.preventDefault();
  if (event.target.files) {
    const file = event.target.files[0];
    this.parseCSVFile(file);
  } else {
    this.props.onInvalidCsv('No file uploaded');
  }
}

export function parseCSVFile(this: AddUsers, file: any) {
  if (this.props.showCsvDrop) this.props.setShowCsvDrop(false);
  if (!file) {
    this.props.onInvalidCsv();
    return;
  }

  const acceptedMIMETypes = ['text/csv', 'application/vnd.ms-excel'];
  if (!acceptedMIMETypes.includes(file.type)) {
    this.props.onInvalidCsv();
    return;
  }

  const reader = new FileReader();

  reader.readAsText(file);

  reader.addEventListener('load', (e: ProgressEvent<FileReader>) => {
    if (e.target !== null && typeof e.target.result === 'string') {
      const rows = e.target.result.split(/\r?\n|\r/);

      let users: IUser[] = [];

      rows.forEach(row => {
        const rowSplit = row.split(',');
        if (!(rowSplit.length === 3 || rowSplit.length === 4)) return;
        if (['first_name', 'firstname'].includes(rowSplit[0].toLowerCase())) return;

        const [firstName, lastName, email] = rowSplit;
        let type = '';

        if (rowSplit[3]) {
          type = rowSplit[3].toLowerCase() as 'admin' | 'stakeholder' | 'user' | '';
          if (!['admin', 'stakeholder', 'user'].includes(type)) type = 'user';
        } else {
          type = 'user';
        }

        const role =
          this.props.roles.r.find(r => r.name.toLocaleLowerCase() === type) ||
          this.props.roles.r[this.props.roles.r.length - 1];

        users.push({ email, role, first_name: firstName, last_name: lastName });
      });

      if (users.length === 0) {
        this.setState({ error: { message: 'No valid data found to add' } });
        this.props.onInvalidCsv(
          'Error while parsing CSV File. Please validate your file and try again',
        );
        return;
      }

      users = users.filter(u => !this.props.users.some(us => us.email === u.email));

      if (users.length === 0) {
        this.setState({ error: { message: 'No unique users to add' } });
        return;
      }

      const cleanUsers = this.props.users.filter(u => u.email && u.first_name && u.last_name);

      if (cleanUsers.length === 0) {
        this.props.setUsers([...users, { ...this.emptyUser }]);
        this.setState({ error: {} });
      } else {
        this.setState({
          toMergeUsers: users,
          modal: 'merge_data',
          error: {},
        });
      }
    } else {
      this.props.onInvalidCsv(
        'Error while parsing CSV File. Please validate your file and try again',
      );
    }
  });
}

export function handleConfirmMergeData(this: AddUsers) {
  const cleanUsers = this.props.users.filter(u => u.email && u.first_name && u.last_name);

  this.props.setUsers([...cleanUsers, ...this.state.toMergeUsers, { ...this.emptyUser }]);

  this.setState({
    toMergeUsers: [],
    modal: '',
  });
}

export function handleCancelMergeData(this: AddUsers) {
  this.setState({
    modal: '',
    toMergeUsers: [],
  });
}
