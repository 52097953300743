import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { HStack, Icon, VStack, Tooltip as CTooltip } from '@chakra-ui/react';
import { IncidentMergeIcon } from 'icons';
import BulkActionModal from './bulkActions.modal';
import ReassignUserConfirmation from '../../../../components/reassign.modal';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';
import CountUp from 'react-countup';
import cx from 'classnames';
import { DateRange } from 'react-date-range';
import { Col, Row, SpinLoader } from '@squadcast/alchemy-ui/carbon';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './index.css';
import { API } from '../../../../core/api';
import { IAppState } from '../../../../core/interfaces/IAppState';
import { setDashConfigs } from '../../../../core/actions/app/configs';
import { ISquad } from '../../../../core/interfaces/ISquad';
import { IUserInfo } from '../../../../core/interfaces/IUserData';
import { IService } from '../../../../core/interfaces/IService';
import { IIntegration } from '../../../../core/interfaces/IIntegration';
import { IEscalationPolicy } from '../../../../core/interfaces/IEscalationPolicy';
import {
  IIncident,
  IIncidentMetric,
  IIncidentStatus,
} from '../../../../core/interfaces/IIncidents';
import { requestUserOrganization } from '../../../../core/actions/organization/initUserOrg';
import { requestOrganizationUsers } from '../../../../core/actions/organization/users';
import moment from 'moment';
import { Link, RouteComponentProps } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  ContainerLoad,
  DialogModalFrame,
  Grid,
  Heading,
  IncidentTag,
  Para,
  SnackBar,
  SnackContext,
  TextButton,
  Theme,
} from 'uie/components';
import { AlertSourcesService, AuthService, IncidentService } from '../../../../core/services';
import { IComponentNetworkState } from '../../../../core/interfaces/IComponentState';
import { exception } from '../../../../core/exception';
import { IOrganization } from '../../../../core/interfaces/IOrganization';
import { getTimeFromObjectId } from 'core/helpers/mongoObjectId';
import { BillingService, checkIfKeyChanged } from '../../../../core';
import PageLoader from '../../../../components/PageLoader';
import { allStatus } from '../../../../core/const/incidents';
import equal from 'fast-deep-equal/es6/react';
import { Locale } from 'core/helpers/dateUtils';
import TrailExpiredModal from 'components/trailexpired.modal';
import ConfirmModal from 'components/confirm.modal';
import { Tooltip } from 'uie/components';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  ESCALATION_POLICIES_LIST_PATH,
  INCIDENT_DETAILS_PATH,
  SERVICE_DETAILS_PATH,
  TEAM_SQUADS_PATH,
  USER_PROFILE_PATH,
} from 'views/main/routes/routes';
import { ThrottleConfig } from 'core/interfaces/IOrgConfig';
import { hasReadAccessToEntity } from '../navigation-flows/helpers';
import { TagValue } from 'components/TagValue';
import { TAG_COMP_MAX_WIDTH, TAG_DASHBOARD_LENGTH, TAG_MAX_LENGTH } from 'core/const/immutables';
import { truncate } from 'core/helpers/stringUtils';
import { Channel } from 'pusher-js';
const { theme } = Theme;

interface metricDataSet {
  triggered: number;
  acknowledged: number;
  resolved: number;
  suppressed: number;
  mtta: number;
  mttr: number;
}

type IMix = Pick<IAppState, 'organization' | 'userInfo' | 'integrations' | 'APP_CONFIG'> &
  RouteComponentProps;
interface IProps extends IMix {
  requestUserOrganization: () => void;
  requestOrganizationUsers: () => void;
  setDashConfigs: (configs: any | null) => void;
  activateUser: string;
  selectedOrg: IOrganization;
}

interface IState {
  selectedUnit: number;
  incidents: IIncident[];
  whetherAll: boolean;
  currentDataSet?: metricDataSet;
  previousDataSet?: metricDataSet;
  selectedStatus: IIncidentStatus[];
  pageLength: number;
  startingPoint: number;
  checked: string[];
  showAction: boolean;
  confirmAcknowledgement: boolean;
  confirmResolve: boolean;
  confirmReAssign: boolean;
  paginationStartingPoint: number;
  showAnimation: boolean;
  showAnimationsFor: string[];
  previousSelectedStatus: IIncidentStatus[];
  loading: boolean;
  assignFilterState: boolean;
  assignFilterValue: string[];
  viaFilterState: boolean;
  viaFilterValue: string[];
  serviceFilterState: boolean;
  serviceFilterValue: string[];
  currentView: 'dash' | 'slo';
  customDateFilterState: boolean;
  isCustomDate: boolean;
  fromDate: Date;
  toDate: Date;
  activeAlertSources: string[];
  isUserAndOrgReloaded: boolean;
  customRangeSelection: {
    startDate: Date;
    endDate: Date;
  };
}
interface pusherData {
  message: string;
}

const defaultThrottleConfig: ThrottleConfig = { debounce_interval: 10000, max_wait: 10000 };

let clearTimeoutClearShowAnimationsFor: any = null;
const filterStates = ['assign', 'via', 'service'];
class Dashboard extends React.Component<IProps, IState> {
  public _isMounted = false;
  private _alertSourcesService = new AlertSourcesService();
  private _incidentService = new IncidentService();
  private _teamId = API.config.teamId;
  private _socketConnection: Channel | null = null;

  private throttleConfig: ThrottleConfig =
    this.props.organization.currentOrg?.o?.config['dashboard_throttle_config'] ??
    defaultThrottleConfig;

  private refreshDebounced = debounce(
    () => {
      this.getIncidents();
    },
    this.throttleConfig.debounce_interval,
    { maxWait: this.throttleConfig.max_wait },
  );

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedUnit: 7, // The unit in days
      incidents: [],
      whetherAll: true,
      selectedStatus: allStatus,
      previousSelectedStatus: allStatus,
      pageLength: 5,
      startingPoint: 0,
      checked: [],
      showAction: false,
      confirmAcknowledgement: false,
      confirmResolve: false,
      confirmReAssign: false,
      paginationStartingPoint: 0,
      showAnimation: false,
      showAnimationsFor: [],
      loading: false,
      assignFilterState: false,
      assignFilterValue: [],
      viaFilterState: false,
      viaFilterValue: [],
      serviceFilterState: false,
      serviceFilterValue: [],
      currentView: 'dash',
      customDateFilterState: false,
      isCustomDate: false,
      fromDate: new Date(),
      toDate: new Date(),
      activeAlertSources: [],
      isUserAndOrgReloaded: false,
      customRangeSelection: {
        startDate: new Date(),
        endDate: new Date(),
      },
    };
    this.getIncidents = this.getIncidents.bind(this);
    this.getConcernedItemsName = this.getConcernedItemsName.bind(this);
    this.generatePageLengthSelector = this.generatePageLengthSelector.bind(this);
    this.acknowledge = this.acknowledge.bind(this);
    this.resolve = this.resolve.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.toLocaleISO = this.toLocaleISO.bind(this);
  }

  /**
   * Returns the timezone formatted ISO string Date of the supplied Date
   * @param date Date to format
   * @param isEndDate Boolean to determine if the date is the end date or not
   * @returns Formatted date
   */
  private toLocaleISO(date: Date, isEndDate?: boolean) {
    const dateTime = new Date(date);
    if (isEndDate) {
      dateTime.setDate(dateTime.getDate() + 1);
    }
    return Locale.toISO(dateTime);
  }

  public acknowledge() {
    if (this.state.checked.length) {
      axios
        .post(
          API.config.batman + `/organizations/${API.config.organizationId}/incidents/acknowledge`,
          {
            incident_ids: this.state.checked,
          },
        )
        .then(response => {
          if (!response.data) {
            console.log(response.data);
          }
        });
    }
  }
  public resolve() {
    if (this.state.checked.length) {
      axios
        .post(API.config.batman + `/organizations/${API.config.organizationId}/incidents/resolve`, {
          incident_ids: this.state.checked,
        })
        .then(response => {
          if (!response.data) {
            console.log(response.data);
          }
        });
    }
  }
  public generatePageLengthSelector() {
    const totalNumberOfIncidents = this.state.selectedStatus.reduce(
      (sum, incidentStatus) => sum + (this.state.currentDataSet as metricDataSet)[incidentStatus],
      0,
    );

    if (totalNumberOfIncidents < 5) {
      return null;
    }
    const intervals = [5];
    let increment = 5;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (intervals.length === 3) {
        break;
      }
      const current = intervals[intervals.length - 1];
      if (current + increment > totalNumberOfIncidents) {
        break;
      } else {
        intervals.push(current + increment);
      }
      increment += 5;
    }

    return (
      <div className="float-left" style={{ width: 180 }}>
        <select
          className="select-design"
          value={this.state.pageLength}
          onChange={event => {
            this.setState(
              {
                previousSelectedStatus: this.state.selectedStatus,
                pageLength: parseInt(event.target.value),
                paginationStartingPoint: 0,
                startingPoint: 0,
              },
              () => {
                this.getIncidents();
              },
            );
          }}
        >
          {intervals.map((interval: number, index: number) => {
            return (
              <option key={index} value={interval}>
                {interval} per page
              </option>
            );
          })}
        </select>
      </div>
    );
  }
  public generatePagination() {
    const totalNumberOfIncidents = this.state.selectedStatus.reduce(
      (sum, incidentStatus) => sum + (this.state.currentDataSet as metricDataSet)[incidentStatus],
      0,
    );
    const numberOfPages = Math.ceil(totalNumberOfIncidents / this.state.pageLength);
    if (numberOfPages === 1 || numberOfPages === 0) {
      return null;
    }
    const pages: any = [];
    for (let i = this.state.paginationStartingPoint, j = 0; i < numberOfPages && j < 5; i++, j++) {
      pages.push(
        <span
          className="page-number item-box-tagline font-bold no-select cursor-pointer"
          onClick={() => {
            this.setState(
              {
                previousSelectedStatus: this.state.selectedStatus,
                startingPoint: i * this.state.pageLength,
              },
              () => {
                this.getIncidents();
              },
            );
          }}
          style={
            Math.floor(this.state.startingPoint / this.state.pageLength) === i
              ? { backgroundColor: '#f3f4f6' }
              : {}
          }
          key={i}
        >
          {i + 1}
        </span>,
      );
    }
    return (
      <div style={{ textAlign: 'center' }} className="pagination-container-div">
        {numberOfPages > 5 ? (
          <img
            src="/icons/carousel-double-left.svg"
            className="no-select cursor-pointer"
            alt="left"
            onClick={() => {
              this.setState(
                {
                  startingPoint: 0 * this.state.pageLength,
                  paginationStartingPoint: 0,
                  previousSelectedStatus: this.state.selectedStatus,
                },
                () => {
                  this.getIncidents();
                },
              );
            }}
            style={{
              marginRight: 33,
              verticalAlign: 'middle',
              pointerEvents:
                Math.floor(this.state.startingPoint / this.state.pageLength) !== 0 ? 'all' : 'none',
              opacity: Math.floor(this.state.startingPoint / this.state.pageLength) !== 0 ? 1 : 0.3,
            }}
          />
        ) : null}
        {numberOfPages > 5 ? (
          <img
            className="no-select cursor-pointer"
            alt="next"
            onClick={() => {
              this.setState(
                {
                  previousSelectedStatus: this.state.selectedStatus,
                  startingPoint: this.state.startingPoint - this.state.pageLength,
                  paginationStartingPoint:
                    this.state.paginationStartingPoint - 1 < 0
                      ? 0
                      : this.state.paginationStartingPoint - 1,
                },
                () => {
                  this.getIncidents();
                },
              );
            }}
            src="/icons/arrow-back.svg"
            style={{
              width: 14,
              height: 14,
              position: 'relative',
              top: 2,
              marginRight: 33,
              pointerEvents: this.state.paginationStartingPoint > 0 ? 'all' : 'none',
              opacity: this.state.paginationStartingPoint > 0 ? 1 : 0.3,
            }}
          />
        ) : null}
        {pages}
        {numberOfPages > 5 ? (
          <img
            className="no-select cursor-pointer"
            alt="forward"
            onClick={() => {
              this.setState(
                {
                  previousSelectedStatus: this.state.selectedStatus,
                  startingPoint: this.state.startingPoint + this.state.pageLength,
                  paginationStartingPoint:
                    this.state.paginationStartingPoint + 1 === numberOfPages
                      ? numberOfPages - 1
                      : this.state.paginationStartingPoint + 1,
                },
                () => {
                  this.getIncidents();
                },
              );
            }}
            src="/icons/arrow-forward.svg"
            style={{
              width: 14,
              height: 14,
              position: 'relative',
              top: 2,
              marginLeft: 33,
              pointerEvents:
                this.state.paginationStartingPoint + 5 <= numberOfPages - 1 ? 'all' : 'none',
              opacity: this.state.paginationStartingPoint + 5 <= numberOfPages - 1 ? 1 : 0.3,
            }}
          />
        ) : null}
        {numberOfPages > 5 ? (
          <img
            src="/icons/carousel-double-right.svg"
            alt="forward"
            className="no-select cursor-pointer"
            onClick={() => {
              this.setState(
                {
                  previousSelectedStatus: this.state.selectedStatus,
                  startingPoint: (numberOfPages - 1) * this.state.pageLength,
                  paginationStartingPoint: numberOfPages - 5,
                },
                () => {
                  this.getIncidents();
                },
              );
            }}
            style={{
              marginLeft: 33,
              verticalAlign: 'middle',
              pointerEvents:
                Math.floor(this.state.startingPoint / this.state.pageLength) !== numberOfPages - 1
                  ? 'all'
                  : 'none',
              opacity:
                Math.floor(this.state.startingPoint / this.state.pageLength) !== numberOfPages - 1
                  ? 1
                  : 0.3,
            }}
          />
        ) : null}
      </div>
    );
  }
  public getConcernedItemsName(type: string, id: string) {
    const organization = this.props.organization;
    try {
      if (type === 'user') {
        return (
          organization.users.u[
            window._.findIndex(organization.users.u, (user: IUserInfo) => user.id === id)
          ].first_name +
          ' ' +
          organization.users.u[
            window._.findIndex(organization.users.u, (user: IUserInfo) => user.id === id)
          ].last_name
        );
      }
      if (type === 'squad') {
        return organization.squads.s[
          window._.findIndex(organization.squads.s, (squad: ISquad) => squad.id === id)
        ].name;
      }
      return organization.escalations.e[
        window._.findIndex(
          organization.escalations.e,
          (escalationPolicy: IEscalationPolicy) => escalationPolicy.id === id,
        )
      ].name;
    } catch (_: any) {
      return 'Entity Deleted';
    }
  }

  public getConcernedItemsUsername(type: string, id: string) {
    const organization = this.props.organization;
    try {
      if (type === 'user') {
        return organization.users.u[
          window._.findIndex(organization.users.u, (user: IUserInfo) => user.id === id)
        ].username_for_display;
      }
    } catch (_: any) {
      return '';
    }
  }

  public getIncidents() {
    this.setState(
      {
        loading: true,
      },
      () => {
        clearTimeout(clearTimeoutClearShowAnimationsFor);
        axios
          .get(API.config.batman + `/organizations/${API.config.organizationId}/incidents`, {
            params: {
              selectedUnit: this.state.selectedUnit,
              incidentsFor: this.state.whetherAll ? 'all' : 'yours',
              limit: this.state.pageLength,
              from: this.state.startingPoint,
              status: this.state.selectedStatus,
              services: this.state.serviceFilterValue,
              assignedTo: this.state.assignFilterValue,
              integrationTypes: this.state.viaFilterValue,
              customDates: this.state.isCustomDate,
              fromDate: this.toLocaleISO(this.state.fromDate),
              toDate: this.toLocaleISO(this.state.toDate, true),
              owner_id: this.props.organization.selectedTeam.teamId,
            },
          })
          .then(response => {
            const {
              data: { data },
            } = response;
            if (data) {
              const showAnimationsFor: any = [];
              if (this.state.showAnimation) {
                // Only those incidents which are different from the previous ones
                for (let i = 0; i < data.incidents.length; i++) {
                  // If new or changed
                  let found = false;
                  for (let j = 0; j < this.state.incidents.length; j++) {
                    if (data.incidents[i]._id === this.state.incidents[j].id) {
                      found = true;
                      if (
                        JSON.stringify(data.incidents[i]) !==
                        JSON.stringify(this.state.incidents[j])
                      ) {
                        showAnimationsFor.push(data.incidents[i]._id);
                      }
                    }
                  }
                  if (!found) {
                    showAnimationsFor.push(data.incidents[i]._id);
                  }
                }
              }

              this.setState(
                {
                  showAnimationsFor,
                  incidents: data.incidents,
                  checked: [],
                  showAnimation: false,
                  loading: false,
                },
                () => {
                  clearTimeoutClearShowAnimationsFor = setTimeout(() => {
                    if (this._isMounted) {
                      this.setState({
                        showAnimationsFor: [],
                      });
                    }
                  }, 5000);
                },
              );
            } else {
              console.log(response.data);
            }
          });
      },
    );
    this.getIncidentsCount();
  }
  public reOrder = (props: IIncidentMetric) => {
    const { acknowledged, resolved, suppressed, triggered } = props;
    return { triggered, acknowledged, resolved, suppressed };
  };

  public getIncidentsCount = async () => {
    try {
      const params = {
        selectedUnit: this.state.selectedUnit,
        incidentsFor: this.state.whetherAll ? 'all' : ('yours' as any),
        from: this.state.startingPoint,
        status: this.state.selectedStatus as IIncidentStatus[],
        services: this.state.serviceFilterValue,
        assignedTo: this.state.assignFilterValue,
        integrationTypes: this.state.viaFilterValue,
        customDates: this.state.isCustomDate,
        fromDate: this.toLocaleISO(this.state.fromDate),
        toDate: this.toLocaleISO(this.state.toDate, true),
        owner_id: this.props.organization.selectedTeam.teamId,
      };

      const {
        data: { data },
      } = await this._incidentService.getIncidentsCount(params);

      let currData = data.currentData;
      let prevData = data.previousData;

      const {
        data: { data: metricData },
      } = await this._incidentService.getIncidentsMetrics(params);
      const reorderedcurrData = this.reOrder(currData);
      const reorderedPrevData = this.reOrder(prevData);
      currData = { ...reorderedcurrData, ...metricData.currentData };
      prevData = { ...reorderedPrevData, ...metricData.previousData };

      this.setState({
        currentDataSet: currData,
        previousDataSet: prevData,
      });
    } catch (err: any) {
      exception.handle('E_GET_INCIDENTS_COUNT', err);
    }
  };

  public getIncidentDetails(incidentId: string) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          API.config.endpoint +
            `/organizations/${API.config.organizationId}/incidents/${incidentId}`,
        )
        .then(response => {
          if (response.data.result) {
            resolve(response.data.data);
          } else {
            reject('invalid data');
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getActiveAlertSources = async () => {
    try {
      const [fromDate, toDate] = this.state.isCustomDate
        ? [new Date(this.state.fromDate), new Date(this.state.toDate)]
        : [moment().subtract(this.state.selectedUnit, 'd').toDate(), moment().toDate()];

      const {
        data: { data: activeAlertSources },
      } = await this._alertSourcesService.getActive(
        { fromDate: this.toLocaleISO(fromDate), toDate: this.toLocaleISO(toDate, true) },
        API.config.teamId,
      );
      this.setState({ activeAlertSources });
    } catch (err: any) {
      exception.handle('E_GET_ORGANIZATION_ALERT_SOURCES', err);
      this.setState({ activeAlertSources: this.props.integrations.i.map(_ => _._id) });
    }
  };

  closeBulkAcknowledge = (result: boolean) => {
    if (result) {
      this.acknowledge();
    }
    this.setState({
      confirmAcknowledgement: false,
    });
  };

  closeBulkResolve = (result: boolean) => {
    if (result) {
      this.resolve();
    }
    this.setState({
      confirmResolve: false,
    });
  };
  closeReassign = () => {
    this.setState({
      confirmReAssign: false,
    });
  };

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.activateUser === 'success' && !this.state.isUserAndOrgReloaded) {
      this.props.requestUserOrganization();
      this.props.requestOrganizationUsers();
      this.setState({ isUserAndOrgReloaded: true });
    }

    if (API.config.teamId !== this._teamId) {
      this._teamId = API.config.teamId;
      this.getIncidents();
    }
  }

  public componentDidMount() {
    this.getActiveAlertSources();
    window.history.pushState(null, '', window.location.href);
    if (this.props.APP_CONFIG.dashboard !== null) {
      const old = this.props.APP_CONFIG.dashboard;
      this.setState(
        {
          ...old,
        },
        () => {
          this.getIncidents();
        },
      );
    } else {
      if (!this._isMounted) {
        this._isMounted = true;
        this.getIncidents();
      }
    }

    this._socketConnection = API.socket.subscribe(API.config.organizationId);
    if (this._socketConnection) {
      this._socketConnection.bind('reload-event', (data: pusherData) => {
        if (data.message === 'reload-incidents') {
          this.refreshDebounced();
        }
      });
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    this.props.setDashConfigs({ ...this.state, relocateTo: 0 });
    this.refreshDebounced.cancel();
    if (this._socketConnection) {
      this._socketConnection.unbind('reload-event');
      this._socketConnection.cancelSubscription();
    }
  }

  public closeAllFilters() {
    if (
      filterStates
        .map(filter => `${filter}FilterState`)
        .some((state: any) => (this.state as any)[state])
    ) {
      const newState: any = {};
      [...filterStates].forEach(filter => {
        newState[`${filter}FilterState` as any] = false;
      });
      this.setState(newState, () => this._onFilterIncidents());
    } else if (this.state.customDateFilterState) {
      this.setState({
        customDateFilterState: false,
        customRangeSelection: {
          startDate: this.state.fromDate,
          endDate: this.state.toDate,
        },
      });
    }
  }

  public toggleFilter(name: string) {
    const newState: any = {};
    newState[name] = !(this.state as any)[name];
    this.setState(newState);
  }

  public onFilterChange(event: React.ChangeEvent<HTMLInputElement>, name: string) {
    const value = event.target.value;
    const currentValues = [...(this.state as any)[name]];
    if (currentValues.includes(value)) {
      currentValues.splice(currentValues.indexOf(value), 1);
    } else {
      currentValues.push(value);
    }

    const newState: any = {};
    newState[name] = currentValues;
    this.setState(newState);
  }

  public resetFilter() {
    const newState = {
      pageLength: 5,
      startingPoint: 0,
    };
    filterStates.forEach(filter => {
      (newState as any)[`${filter}FilterValue`] = [];
    });
    this.setState(newState, () => {
      this.getIncidents();
    });
  }

  public _onFilterIncidents() {
    if (
      filterStates.map(filter => `${filter}FilterState`).every(state => !(this.state as any)[state])
    ) {
      this.setState({
        pageLength: 5,
        startingPoint: 0,
        paginationStartingPoint: 0,
      });
      this.getIncidents();
    }
  }

  calcDiff = (stateObj: any, key: string) => {
    if (!stateObj || !stateObj.previousDataSet || !stateObj.currentDataSet) {
      return 0;
    }

    let previousOne = (stateObj.previousDataSet as any)[key];
    if (previousOne === 0) {
      previousOne = 1;
    }

    return parseFloat((((stateObj.currentDataSet as any)[key] / previousOne) * 100).toFixed(2));
  };

  onApplyCustomRange = () => {
    const {
      customRangeSelection: { startDate, endDate },
      fromDate,
      toDate,
      isCustomDate,
    } = this.state;

    if (isCustomDate && startDate === fromDate && endDate === toDate) {
      this.setState({
        customDateFilterState: false,
      });

      return;
    }

    this.setState(
      {
        isCustomDate: true,
        fromDate: startDate,
        toDate: endDate,
        pageLength: 5,
        startingPoint: 0,
        paginationStartingPoint: 0,
        customDateFilterState: false,
      },
      () => {
        this.getIncidents();
        this.getActiveAlertSources();
      },
    );
  };

  onCancelCustomRange = () =>
    this.setState({
      customDateFilterState: false,
      customRangeSelection: {
        startDate: this.state.fromDate,
        endDate: this.state.toDate,
      },
    });

  public render() {
    const mttaTooltipContent =
      'Average time taken to acknowledge incidents. It is calculated as a separate metric for every team that you are a part of in Squadcast. It auto-refreshes every 1 min.';
    const mttrTooltipContent =
      'Average time taken to resolve incidents. It is calculated as a separate metric for every team that you are a part of in Squadcast. It auto-refreshes every 1 min.';
    if (!this.props.selectedOrg.organizationId) {
      return <PageLoader />;
    }

    const _orgCreatedTime = getTimeFromObjectId(this.props.selectedOrg.organizationId);

    return (
      <div
        className="main-container"
        onClick={() => {
          this.setState({ showAction: false });
          this.closeAllFilters();
        }}
      >
        <div>
          <div
            style={{
              borderBottom: '1px solid #DFE3EB',
              paddingBottom: 20,
              marginBottom: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p className="item-box-main-heading" style={{ margin: 0, paddingTop: 10 }}>
                {this.state.currentView === 'dash'
                  ? 'Dashboard'
                  : 'Service Level Objectives Summary'}
              </p>

              <div className="top-bar-switch">
                <span style={{ marginRight: 10, position: 'relative', top: -4 }}>All</span>
                <label className="switch no-select">
                  <input
                    type="checkbox"
                    id="togBtn"
                    checked={!this.state.whetherAll}
                    onChange={() => {
                      this.setState(
                        {
                          whetherAll: !this.state.whetherAll,
                          selectedUnit: 7,
                          pageLength: 5,
                          startingPoint: 0,
                          paginationStartingPoint: 0,
                        },
                        () => {
                          this.getIncidents();
                        },
                      );
                    }}
                  />
                  <div className="slider-toggle round">
                    <span className="on" />
                    <span className="off" />
                  </div>
                </label>
                <span style={{ marginLeft: 10, position: 'relative', top: -4 }}>Yours</span>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              {
                <section style={{ display: 'flex' }}>
                  <button
                    className="main-button-inverse w-200"
                    onClick={() => {
                      this.setState(
                        {
                          selectedUnit: 7,
                          pageLength: 5,
                          startingPoint: 0,
                          paginationStartingPoint: 0,
                          isCustomDate: false,
                        },
                        () => {
                          this.getIncidents();
                          this.getActiveAlertSources();
                        },
                      );
                    }}
                    style={
                      this.state.selectedUnit === 7 && !this.state.isCustomDate
                        ? { fontWeight: 600, marginRight: 10 }
                        : {
                            color: '#b6bcc8',
                            fontWeight: 600,
                            border: '1px solid #DFE3EB',
                            marginRight: 10,
                          }
                    }
                  >
                    Last Week
                  </button>
                  <button
                    className="main-button-inverse w-200"
                    onClick={() => {
                      this.setState(
                        {
                          selectedUnit: 30,
                          pageLength: 5,
                          startingPoint: 0,
                          paginationStartingPoint: 0,
                          isCustomDate: false,
                        },
                        () => {
                          this.getIncidents();
                          this.getActiveAlertSources();
                        },
                      );
                    }}
                    style={
                      this.state.selectedUnit === 30 && !this.state.isCustomDate
                        ? { fontWeight: 600, marginRight: 10 }
                        : {
                            border: '1px solid #DFE3EB',
                            color: '#b6bcc8',
                            fontWeight: 600,
                            marginRight: 10,
                          }
                    }
                  >
                    Last Month
                  </button>
                  {/* <button
                    className="main-button-inverse w-200"
                    onClick={() => {
                      this.setState(
                        {
                          selectedUnit: 365,
                          pageLength: 5,
                          startingPoint: 0,
                          paginationStartingPoint: 0,
                          isCustomDate: false,
                        },
                        () => {
                          this.getIncidents();
                          this.getActiveAlertSources();
                        },
                      );
                    }}
                    style={
                      this.state.selectedUnit === 365 && !this.state.isCustomDate
                        ? { fontWeight: 600 }
                        : { border: '1px solid #DFE3EB', color: '#b6bcc8', fontWeight: 600 }
                    }
                  >
                    Last Year
                  </button> */}
                  <div style={{ display: 'inline', position: 'relative' }}>
                    <button
                      className="filter-button-design"
                      style={
                        this.state.isCustomDate
                          ? {
                              border: '1px solid var(--primary-default',
                              fontWeight: 600,
                              color: 'var(--primary-default)',
                            }
                          : { border: '1px solid #DFE3EB', color: '#b6bcc8', fontWeight: 600 }
                      }
                      onClick={event => {
                        event.stopPropagation();
                        this.setState(({ customDateFilterState }) => ({
                          customDateFilterState: !customDateFilterState,
                        }));
                      }}
                    >
                      Custom Range &nbsp;&nbsp;
                      <i
                        className={cx('fa', {
                          'fa-angle-up': this.state.serviceFilterState,
                          'fa-angle-down': !this.state.serviceFilterState,
                        })}
                      />
                    </button>
                    {this.state.customDateFilterState && (
                      <div
                        className="filter-drop no-cursor animated fadeIn date-range-drop-down"
                        style={{ top: 50, right: 6 }}
                        onClick={event => {
                          event.stopPropagation();
                        }}
                      >
                        <DateRange
                          minDate={_orgCreatedTime}
                          maxDate={new Date()}
                          direction="vertical"
                          scroll={{ enabled: true }}
                          ranges={[
                            {
                              startDate: new Date(this.state.customRangeSelection.startDate),
                              endDate: new Date(this.state.customRangeSelection.endDate),
                              key: 'selection',
                            },
                          ]}
                          color={'#0f61dd'}
                          onChange={(e: any) => {
                            this.setState({
                              customRangeSelection: {
                                startDate: e.selection.startDate,
                                endDate: e.selection.endDate,
                              },
                            });
                          }}
                          style={{ background: 'white' }}
                        />
                        <Grid justifyContent="flex-end">
                          <TextButton
                            buttonType="ghost"
                            className="mr-20"
                            onClick={this.onCancelCustomRange}
                          >
                            <Para fontSize={14}>Cancel</Para>
                          </TextButton>
                          <TextButton onClick={this.onApplyCustomRange}>
                            <Para fontSize={14} color={theme.shades.white}>
                              Apply Time Range
                            </Para>
                          </TextButton>
                        </Grid>
                      </div>
                    )}
                  </div>
                </section>
              }
            </div>
          </div>
          {this.state.currentDataSet ? (
            <div style={{ marginTop: 20 }}>
              <div style={{ marginBottom: 40 }}>
                <Row alignContent="space-between" justifyContent="center">
                  {this.state.currentView === 'dash' &&
                    Object.keys(this.state.currentDataSet).map((key, index) => {
                      const diff = this.calcDiff(this.state, key);
                      const persistDiff = this.calcDiff(this.props.APP_CONFIG.dashboard, key);
                      return (
                        <div
                          className="metric-display float-left"
                          style={{
                            width: '16%',
                            marginRight:
                              index ===
                              Object.keys(this.state.currentDataSet as metricDataSet).length - 1
                                ? '0'
                                : '0.8%',
                            padding: '20px 15px',
                            boxSizing: 'border-box',
                          }}
                          key={index}
                        >
                          <div
                            style={{
                              display: 'flex',
                            }}
                          >
                            <div className="metric-display-image" style={{ marginRight: 15 }}>
                              <img
                                src={'/icons/' + key + '.svg'}
                                alt="number"
                                style={{
                                  height: 49,
                                  width: 49,
                                  display: 'block',
                                  position: 'relative',
                                  top: 10,
                                }}
                              />
                            </div>
                            <div className="metric-display-text">
                              <h1
                                className="item-box-tagline font-bold"
                                style={{
                                  margin: 0,
                                  marginBottom: 10,
                                  textTransform:
                                    key === 'mtta' || key === 'mttr' ? 'uppercase' : 'capitalize',
                                }}
                              >
                                {key}
                                {key === 'mtta' ? (
                                  <div style={{ textTransform: 'initial', display: 'inline' }}>
                                    <Tooltip
                                      padding="8px"
                                      offset={{ top: '-110px', left: '-50px' }}
                                      width="240px"
                                      label={mttaTooltipContent}
                                    >
                                      &nbsp;
                                      <InfoOutlineIcon w={10} h={10} mb={1} ml={1} />
                                    </Tooltip>
                                  </div>
                                ) : null}
                                {key === 'mttr' ? (
                                  <div style={{ textTransform: 'initial', display: 'inline' }}>
                                    <Tooltip
                                      padding="8px"
                                      offset={{ top: '-110px', left: '-200px' }}
                                      width="240px"
                                      label={mttrTooltipContent}
                                    >
                                      &nbsp;
                                      <InfoOutlineIcon w={10} h={10} mb={1} ml={1} />
                                    </Tooltip>
                                  </div>
                                ) : null}
                              </h1>
                              <div
                                className="item-box-tagline"
                                style={{
                                  fontSize: 24,
                                  margin: 0,
                                  marginBottom: 4,
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  alignItems: 'center',
                                }}
                              >
                                <CountUp
                                  preserveValue={true}
                                  duration={0.8}
                                  decimals={key === 'mtta' || key === 'mttr' ? 2 : 0}
                                  start={
                                    this.props.APP_CONFIG.dashboard &&
                                    this.props.APP_CONFIG.dashboard.currentDataSet
                                      ? key === 'mtta' || key === 'mttr'
                                        ? Number(
                                            (
                                              this.props.APP_CONFIG.dashboard
                                                .currentDataSet as metricDataSet
                                            )[key].toFixed(2),
                                          )
                                        : (this.props.APP_CONFIG.dashboard.currentDataSet as any)[
                                            key
                                          ]
                                      : null
                                  }
                                  end={
                                    key === 'mtta' || key === 'mttr'
                                      ? Number(
                                          (this.state.currentDataSet as metricDataSet)[key].toFixed(
                                            2,
                                          ),
                                        )
                                      : (this.state.currentDataSet as any)[key]
                                  }
                                />

                                {key === 'mtta' || key === 'mttr' ? (
                                  <span
                                    style={{
                                      fontSize: 14,
                                      marginLeft: 2,
                                      fontWeight: 600,
                                    }}
                                    className="item-box-tagline"
                                  >
                                    Hrs
                                  </span>
                                ) : null}
                              </div>
                              <div
                                style={{
                                  margin: 0,
                                  color:
                                    diff === 0
                                      ? '#0f61dd'
                                      : diff > 0
                                      ? key === 'mtta' || key === 'mttr'
                                        ? '#f04546'
                                        : '#15c26b'
                                      : key === 'mtta' || key === 'mttr'
                                      ? '#15c26b'
                                      : '#f04546',
                                }}
                              >
                                {diff === 0 ? null : (
                                  <i
                                    className={'fa fa-caret-' + (diff > 0 ? 'up' : 'down')}
                                    style={{ marginRight: 10 }}
                                  />
                                )}
                                {diff > 10000 ? (
                                  '>10000'
                                ) : (
                                  <CountUp
                                    preserveValue={true}
                                    duration={0.8}
                                    decimals={diff % 1 !== 0 ? 2 : 0}
                                    start={persistDiff}
                                    end={diff}
                                  />
                                )}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Row>
              </div>
              <div
                className="md-10"
                style={{
                  height: '65px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ top: 10 }}>
                  <p className="item-box-main-heading" style={{ margin: 0, fontSize: 20 }}>
                    {this.state.currentDataSet.acknowledged + this.state.currentDataSet.triggered}{' '}
                    Open Incident
                    {this.state.currentDataSet.acknowledged + this.state.currentDataSet.triggered >
                    1
                      ? 's'
                      : ''}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      marginTop: 10,
                      fontWeight: 600,
                      fontSize: 14,
                      color: '#8690A4',
                    }}
                  >
                    Out of{' '}
                    {(this.state.currentDataSet as metricDataSet).triggered +
                      (this.state.currentDataSet as metricDataSet).acknowledged +
                      (this.state.currentDataSet as metricDataSet).resolved +
                      (this.state.currentDataSet as metricDataSet).suppressed}{' '}
                    total incidents
                  </p>
                </div>
                <div style={{ top: 10, display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <div>
                      <p>Filter By</p>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '30px' }}>
                    <div>
                      <div style={{ display: 'inline', position: 'relative' }}>
                        <button
                          className="filter-button-design"
                          onClick={() => {
                            this.toggleFilter('serviceFilterState');
                          }}
                        >
                          Impact On &nbsp;&nbsp;
                          <i
                            className={cx('fa', {
                              'fa-angle-up': this.state.serviceFilterState,
                              'fa-angle-down': !this.state.serviceFilterState,
                            })}
                          />
                        </button>
                        {this.state.serviceFilterValue.length > 0 && (
                          <div className="element-modified" />
                        )}
                        {this.state.serviceFilterState && (
                          <div
                            className="filter-drop no-cursor animated fadeIn"
                            style={{ top: 40, right: 6 }}
                            onClick={event => {
                              event.stopPropagation();
                            }}
                          >
                            <div className="overflow-scroll">
                              <p
                                className="item-box-tagline"
                                style={{ fontWeight: 600, marginTop: 0, marginBottom: 10 }}
                              >
                                Service
                              </p>
                              {this.props.organization.services.s.map((service, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="no-select cursor-pointer flex"
                                    style={{ padding: '8px 15px', paddingLeft: 0 }}
                                  >
                                    <input
                                      id={'service-' + service.id}
                                      style={{ margin: 0 }}
                                      type="checkbox"
                                      value={service.id}
                                      checked={this.state.serviceFilterValue.includes(
                                        service.id as string,
                                      )}
                                      onChange={e => this.onFilterChange(e, 'serviceFilterValue')}
                                    />
                                    <label
                                      htmlFor={'service-' + service.id}
                                      className={cx('item-box-tagline cursor-pointer')}
                                      style={{
                                        position: 'relative',
                                        background: 'none',
                                        padding: 0,
                                        paddingLeft: '8px',
                                        textAlign: 'left',
                                        top: '-4px',
                                      }}
                                    >
                                      {service.name}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div style={{ marginLeft: '20px' }}>
                      <div style={{ display: 'inline', position: 'relative' }}>
                        <button
                          className="filter-button-design"
                          onClick={() => {
                            this.toggleFilter('viaFilterState');
                          }}
                        >
                          Via &nbsp;&nbsp;
                          <i
                            className={cx('fa', {
                              'fa-angle-up': this.state.viaFilterState,
                              'fa-angle-down': !this.state.viaFilterState,
                            })}
                          />
                        </button>
                        {this.state.viaFilterValue.length > 0 && (
                          <div className="element-modified" />
                        )}
                        {this.state.viaFilterState && (
                          <div
                            className="filter-drop no-cursor animated fadeIn"
                            style={{ top: 40, right: 6 }}
                            onClick={event => {
                              event.stopPropagation();
                            }}
                          >
                            <div className="overflow-scroll">
                              <p
                                className="item-box-tagline"
                                style={{ fontWeight: 600, marginTop: 0, marginBottom: 10 }}
                              >
                                Integrations
                              </p>
                              {this.props.integrations.i
                                .filter(i => this.state.activeAlertSources.includes(i._id))
                                .map((integration, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="no-select cursor-pointer flex"
                                      style={{ padding: '8px 15px', paddingLeft: 0 }}
                                    >
                                      <input
                                        id={'integration-' + integration._id}
                                        style={{ margin: 0 }}
                                        type="checkbox"
                                        value={integration._id}
                                        checked={this.state.viaFilterValue.includes(
                                          integration._id as string,
                                        )}
                                        onChange={e => this.onFilterChange(e, 'viaFilterValue')}
                                      />
                                      <label
                                        htmlFor={'integration-' + integration._id}
                                        className={cx('item-box-tagline cursor-pointer')}
                                        style={{
                                          position: 'relative',
                                          background: 'none',
                                          padding: 0,
                                          paddingLeft: '8px',
                                          textAlign: 'left',
                                          top: '-4px',
                                        }}
                                      >
                                        {integration.type}
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div style={{ marginLeft: '20px' }}>
                      <div style={{ display: 'inline', position: 'relative' }}>
                        <button
                          className="filter-button-design"
                          onClick={() => {
                            this.toggleFilter('assignFilterState');
                          }}
                        >
                          Assigned To &nbsp;&nbsp;
                          <i
                            className={cx('fa', {
                              'fa-angle-up': this.state.assignFilterState,
                              'fa-angle-down': !this.state.assignFilterState,
                            })}
                          />
                        </button>
                        {this.state.assignFilterValue.length > 0 && (
                          <div className="element-modified" />
                        )}
                        {this.state.assignFilterState && (
                          <div
                            className="filter-drop no-cursor animated fadeIn w-1-1"
                            style={{ top: 40, right: 6, display: 'flex', width: '600px' }}
                            onClick={event => {
                              event.stopPropagation();
                            }}
                          >
                            <div className="overflow-scroll w-1-3">
                              <p
                                className="item-box-tagline"
                                style={{ fontWeight: 600, marginTop: 0, marginBottom: 10 }}
                              >
                                Users
                              </p>
                              {[
                                ...this.props.organization.users.u
                                  .filter(user => user.email_verified)
                                  .map(({ id, first_name, last_name, username_for_display }) => ({
                                    id,
                                    name: `${first_name} ${last_name}`,
                                    username: username_for_display,
                                  })),
                              ].map((user, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="no-select cursor-pointer flex"
                                    style={{ padding: '8px 15px', paddingLeft: 0 }}
                                  >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div>
                                          <input
                                            id={`user-${user.id}`}
                                            style={{ margin: 0 }}
                                            type="checkbox"
                                            value={user.id}
                                            checked={
                                              this.state.assignFilterValue.includes(
                                                user.id as string,
                                              ) ||
                                              (!this.state.whetherAll &&
                                                user.id === this.props.userInfo.d!.id)
                                            }
                                            onChange={e =>
                                              this.onFilterChange(e, 'assignFilterValue')
                                            }
                                            disabled={!this.state.whetherAll}
                                          />
                                        </div>
                                        <div style={{ maxWidth: '15ch', marginLeft: '8px' }}>
                                          <label
                                            htmlFor={`user-${user.id}`}
                                            className="item-box-tagline cursor-pointer"
                                            style={{
                                              position: 'relative',
                                              background: 'none',
                                              padding: 0,
                                              textAlign: 'left',
                                              top: '-4px',
                                              color: !this.state.whetherAll
                                                ? 'lightgrey'
                                                : 'inherit',
                                            }}
                                          >
                                            {user.name}
                                          </label>
                                        </div>
                                      </div>
                                      <div style={{ maxWidth: '15ch', marginLeft: '8px' }}>
                                        <label
                                          htmlFor={`user-${user.id}`}
                                          className="item-box-tagline cursor-pointer"
                                          style={{
                                            position: 'relative',
                                            background: 'none',
                                            padding: 0,
                                            textAlign: 'left',
                                            top: '-4px',
                                            marginLeft: '12px',
                                            color: '#8890A2',
                                          }}
                                        >
                                          {user.username}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="overflow-scroll w-1-3 dl-s">
                              <p
                                className="item-box-tagline"
                                style={{ fontWeight: 600, marginTop: 0, marginBottom: 10 }}
                              >
                                Escalation Policies
                              </p>
                              {[...this.props.organization.escalations.e].map((ep, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="no-select cursor-pointer flex"
                                    style={{ padding: '8px 15px', paddingLeft: 0 }}
                                  >
                                    <input
                                      id={`epolicy-${ep.id}`}
                                      style={{ margin: 0 }}
                                      type="checkbox"
                                      value={ep.id}
                                      checked={
                                        this.state.assignFilterValue.includes(ep.id as string) ||
                                        (!this.state.whetherAll &&
                                          ep.id === this.props.userInfo.d!.id)
                                      }
                                      onChange={e => this.onFilterChange(e, 'assignFilterValue')}
                                      disabled={!this.state.whetherAll}
                                    />
                                    <label
                                      htmlFor={`epolicy-${ep.id}`}
                                      className="item-box-tagline cursor-pointer"
                                      style={{
                                        position: 'relative',
                                        background: 'none',
                                        padding: 0,
                                        paddingLeft: '8px',
                                        textAlign: 'left',
                                        top: '-4px',
                                        color: !this.state.whetherAll ? 'lightgrey' : 'inherit',
                                      }}
                                    >
                                      {ep.name}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="overflow-scroll w-1-3 dl-s">
                              <p
                                className="item-box-tagline"
                                style={{ fontWeight: 600, marginTop: 0, marginBottom: 10 }}
                              >
                                Squads
                              </p>
                              {[...this.props.organization.squads.s].map((squad, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="no-select cursor-pointer flex"
                                    style={{ padding: '8px 15px', paddingLeft: 0 }}
                                  >
                                    <input
                                      id={`squad-${squad.id}`}
                                      style={{ margin: 0 }}
                                      type="checkbox"
                                      value={squad.id}
                                      checked={
                                        this.state.assignFilterValue.includes(squad.id as string) ||
                                        (!this.state.whetherAll &&
                                          squad.id === this.props.userInfo.d!.id)
                                      }
                                      onChange={e => this.onFilterChange(e, 'assignFilterValue')}
                                      disabled={!this.state.whetherAll}
                                    />
                                    <label
                                      htmlFor={`squad-${squad.id}`}
                                      className="item-box-tagline cursor-pointer"
                                      style={{
                                        position: 'relative',
                                        background: 'none',
                                        padding: 0,
                                        paddingLeft: '8px',
                                        textAlign: 'left',
                                        top: '-4px',
                                        color: !this.state.whetherAll ? 'lightgrey' : 'inherit',
                                      }}
                                    >
                                      {squad.name}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <button
                      className="small-button animation fadeIn"
                      onClick={() => {
                        this.resetFilter();
                      }}
                      style={{ marginLeft: '20px', height: '50px' }}
                      disabled={
                        !filterStates.some(
                          state => (this.state as any)[`${state}FilterValue`].length > 0,
                        )
                      }
                    >
                      <img src="/icons/reset-filter.svg" alt="reset filter" />
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="clearfix"
                style={{
                  marginTop: 15,
                  borderBottom: '1px solid rgb(231, 236, 245)',
                }}
              >
                <div className="float-left" style={{ marginTop: 15 }}>
                  {equal(this.state.selectedStatus, ['triggered']) ||
                  equal(this.state.selectedStatus, ['acknowledged']) ? (
                    <div style={{ position: 'relative', display: 'inline' }}>
                      <button
                        onClick={event => {
                          this.setState({ showAction: !this.state.showAction });
                          event.stopPropagation();
                        }}
                        className="filter-button-small-design"
                      >
                        Actions &nbsp;&nbsp;
                        <i className={'fa fa-angle-' + (this.state.showAction ? 'up' : 'down')} />
                      </button>
                      {this.state.showAction ? (
                        <div className="filter-drop-left">
                          <div
                            onClick={() => {
                              this.setState({ confirmAcknowledgement: true, showAction: false });
                            }}
                            className={
                              'bulk-action-buttons ' +
                              (this.state.checked.length &&
                              equal(this.state.selectedStatus, ['triggered'])
                                ? 'bulk-action-buttons-hover'
                                : 'bulk-action-buttons-disabled')
                            }
                          >
                            Acknowledge
                          </div>
                          <div
                            onClick={() => {
                              this.setState({ confirmResolve: true, showAction: false });
                            }}
                            className={
                              'bulk-action-buttons ' +
                              (this.state.checked.length &&
                              (equal(this.state.selectedStatus, ['triggered']) ||
                                equal(this.state.selectedStatus, ['acknowledged']))
                                ? 'bulk-action-buttons-hover'
                                : 'bulk-action-buttons-disabled')
                            }
                          >
                            Resolve
                          </div>
                          {/* this button should  */}
                          {/* <div
                          onClick={() => {
                            this.setState({ confirmReAssign: true, showAction: false });
                          }}
                          className={
                            'bulk-action-buttons ' +
                            (this.state.checked.length &&
                            (equal(this.state.selectedStatus, ['triggered']) ||
                              equal(this.state.selectedStatus, ['acknowledged']))
                              ? 'bulk-action-buttons-hover'
                              : 'bulk-action-buttons-disabled')
                          }
                        >
                          Reassign
                        </div> */}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    ''
                  )}
                  <Link to="/incident/create">
                    <button
                      className="small-button-inverse"
                      style={{ marginLeft: 20, fontSize: 16 }}
                    >
                      +
                    </button>
                  </Link>
                </div>
                <div className="float-right">
                  <div
                    className="metric-tab no-select cursor-pointer"
                    onClick={() => {
                      this.setState(
                        {
                          selectedStatus: allStatus,
                          pageLength: 5,
                          previousSelectedStatus: this.state.selectedStatus,
                          startingPoint: 0,
                          paginationStartingPoint: 0,
                          showAction: false,
                        },
                        () => {
                          this.getIncidents();
                        },
                      );
                    }}
                    style={
                      equal(this.state.selectedStatus, allStatus)
                        ? { borderBottom: '1px solid #0f61dd' }
                        : {}
                    }
                  >
                    <p
                      className="item-box-tagline"
                      style={{
                        margin: 0,
                        fontWeight: 600,
                        textTransform: 'capitalize',
                        marginBottom: 8,
                        color: equal(this.state.selectedStatus, allStatus) ? '#0f61dd' : '#b6bcc8',
                      }}
                    >
                      All
                    </p>
                    <div
                      className="item-box-secondary-heading font-bold"
                      style={{
                        fontWeight: 600,
                        color: equal(this.state.selectedStatus, allStatus) ? '#0f61dd' : '#b6bcc8',
                        fontSize: 20,
                        margin: 0,
                      }}
                    >
                      <CountUp
                        preserveValue={true}
                        duration={0.8}
                        start={
                          this.props.APP_CONFIG.dashboard &&
                          this.props.APP_CONFIG.dashboard.currentDataSet
                            ? (this.props.APP_CONFIG.dashboard.currentDataSet as metricDataSet)
                                .triggered +
                              (this.props.APP_CONFIG.dashboard.currentDataSet as metricDataSet)
                                .acknowledged +
                              (this.props.APP_CONFIG.dashboard.currentDataSet as metricDataSet)
                                .resolved +
                              (this.props.APP_CONFIG.dashboard.currentDataSet as metricDataSet)
                                .suppressed
                            : null
                        }
                        end={
                          (this.state.currentDataSet as metricDataSet).triggered +
                          (this.state.currentDataSet as metricDataSet).acknowledged +
                          (this.state.currentDataSet as metricDataSet).resolved +
                          (this.state.currentDataSet as metricDataSet).suppressed
                        }
                      />
                    </div>
                  </div>
                  {Object.keys(this.state.currentDataSet)
                    .slice(0, 4)
                    .map((key, index) => {
                      return (
                        <div
                          className="metric-tab no-select cursor-pointer"
                          onClick={() => {
                            this.setState(
                              {
                                selectedStatus: [key as IIncidentStatus],
                                pageLength: 5,
                                previousSelectedStatus: this.state.selectedStatus,
                                startingPoint: 0,
                                showAction: false,
                                paginationStartingPoint: 0,
                              },
                              () => {
                                this.getIncidents();
                              },
                            );
                          }}
                          key={index}
                          style={
                            equal(this.state.selectedStatus, [key])
                              ? { borderBottom: '1px solid #0f61dd' }
                              : {}
                          }
                        >
                          <p
                            className="item-box-tagline"
                            style={{
                              margin: 0,
                              fontWeight: 600,
                              textTransform: 'capitalize',
                              marginBottom: 8,
                              color: equal(this.state.selectedStatus, [key])
                                ? '#0f61dd'
                                : '#b6bcc8',
                            }}
                          >
                            {key}
                          </p>
                          <div
                            className="item-box-secondary-heading font-bold"
                            style={{
                              fontWeight: 600,
                              color: equal(this.state.selectedStatus, [key])
                                ? '#0f61dd'
                                : '#b6bcc8',
                              fontSize: 20,
                              margin: 0,
                            }}
                          >
                            <CountUp
                              preserveValue={true}
                              duration={0.8}
                              start={
                                this.props.APP_CONFIG.dashboard &&
                                this.props.APP_CONFIG.dashboard.currentDataSet
                                  ? (this.props.APP_CONFIG.dashboard.currentDataSet as any)[key]
                                  : null
                              }
                              end={(this.state.currentDataSet as any)[key]}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              {this.state.loading &&
              !equal(this.state.previousSelectedStatus, this.state.selectedStatus) ? (
                <div style={{ marginTop: 10 }}>
                  <div style={{ height: 390, position: 'relative' }}>
                    <Col flexHeight={12} justifyContent="center">
                      <Row justifyContent="center">
                        <SpinLoader size="normal" />
                      </Row>
                    </Col>
                  </div>{' '}
                </div>
              ) : this.state.incidents.length ? (
                <div style={{ marginTop: 10 }}>
                  <div style={{ minHeight: 390, position: 'relative' }}>
                    <table className="incident-table-design">
                      <thead>
                        <tr>
                          {equal(this.state.selectedStatus, ['triggered']) ||
                          equal(this.state.selectedStatus, ['acknowledged']) ? (
                            <th style={{ width: '4%', marginRight: '1%' }}>
                              <input
                                type="checkbox"
                                checked={this.state.checked.length === this.state.incidents.length}
                                onChange={event => {
                                  let checked = this.state.checked;
                                  if (!event.target.checked) {
                                    checked = [];
                                  } else {
                                    checked = this.state.incidents.map((incident: IIncident) => {
                                      return incident.id;
                                    });
                                  }
                                  this.setState({
                                    checked,
                                  });
                                }}
                              />
                            </th>
                          ) : null}
                          <th
                            className="item-box-tagline font-bold"
                            style={{ width: '9%', marginRight: '1%' }}
                          >
                            Created
                          </th>
                          <th
                            className="item-box-tagline font-bold"
                            style={{ width: '4%', marginRight: '1%' }}
                          >
                            ID
                          </th>
                          <th
                            className="item-box-tagline font-bold"
                            style={{ width: '34%', marginRight: '1%' }}
                          >
                            Incident Message
                          </th>
                          <th
                            className="item-box-tagline font-bold"
                            style={{ width: '14%', marginRight: '1%', position: 'relative' }}
                          >
                            Impact On
                          </th>
                          <th
                            className="item-box-tagline font-bold"
                            style={{ width: '14%', marginRight: '1%', position: 'relative' }}
                          >
                            Via
                          </th>
                          <th
                            className="item-box-tagline font-bold"
                            style={{ width: '9%', marginRight: '1%', position: 'relative' }}
                          >
                            Assigned To
                          </th>
                          <th
                            className="item-box-tagline font-bold"
                            style={{ width: '5%', position: 'relative' }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.incidents.map((incident: IIncident, index: number) => {
                          const tags: any[] =
                            incident !== null && incident.tags
                              ? Object.entries(incident.tags).map(([k, v]) => {
                                  return {
                                    key: k,
                                    value: v,
                                  };
                                })
                              : [];

                          const serviceDetails =
                            this.props.organization.services.s[
                              window._.findIndex(
                                this.props.organization.services.s,
                                (service: IService) => service.id === incident.service_id,
                              )
                            ];

                          const { type: assigneeType, id: assigneeId } =
                            incident.assignedTo[incident.assignedTo.length - 1];
                          const assigneeName = this.getConcernedItemsName(assigneeType, assigneeId);
                          const assigneeUsername = this.getConcernedItemsUsername(
                            assigneeType,
                            assigneeId,
                          );

                          let assigneeLink = '';

                          switch (assigneeType) {
                            case 'user':
                              assigneeLink = generatePath(USER_PROFILE_PATH, { id: assigneeId });
                              break;
                            case 'squad':
                              assigneeLink = generatePath(TEAM_SQUADS_PATH, {
                                id: API.config.teamId,
                                sqid: assigneeId,
                              });
                              break;
                            case 'escalationpolicy':
                              assigneeLink = generatePath(ESCALATION_POLICIES_LIST_PATH, {
                                id: assigneeId,
                              });
                          }

                          let assigneeLinkComponent = <>{assigneeName}</>;

                          if (hasReadAccessToEntity(assigneeType)) {
                            assigneeLinkComponent =
                              assigneeType === 'user' ? (
                                <CTooltip
                                  label={
                                    <VStack background="#F1F3F6" p="6px" alignItems="flex-start">
                                      <span
                                        style={{
                                          fontWeight: 'bold',
                                          maxWidth: '30ch',
                                        }}
                                      >
                                        {assigneeName}
                                      </span>
                                      <span
                                        style={{
                                          fontWeight: 'normal',
                                          maxWidth: '30ch',
                                        }}
                                      >
                                        {assigneeUsername}
                                      </span>
                                    </VStack>
                                  }
                                  hasArrow
                                  placement="bottom"
                                >
                                  <Link to={assigneeLink}>{assigneeName}</Link>
                                </CTooltip>
                              ) : (
                                <Link to={assigneeLink}>{assigneeName}</Link>
                              );
                          }

                          const serviceComponent =
                            serviceDetails && hasReadAccessToEntity('service') ? (
                              <Link
                                to={generatePath(SERVICE_DETAILS_PATH, {
                                  id: serviceDetails.id,
                                })}
                              >
                                {serviceDetails.name}
                              </Link>
                            ) : serviceDetails ? (
                              serviceDetails.name
                            ) : (
                              'Service Deleted'
                            );

                          const parentIncidentTooltipLabel = `${
                            (incident.children ?? []).length
                          } incident${(incident.children ?? []).length > 1 ? 's' : ''} merged`;

                          return (
                            <tr
                              key={index}
                              className={'no-select '}
                              style={{
                                animation:
                                  this.state.showAnimationsFor.indexOf(incident.id) !== -1
                                    ? 'fade-in-animation 5s ease-in-out'
                                    : 'none',
                              }}
                            >
                              {equal(this.state.selectedStatus, ['triggered']) ||
                              equal(this.state.selectedStatus, ['acknowledged']) ? (
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={this.state.checked.indexOf(incident.id) !== -1}
                                    onChange={event => {
                                      event.stopPropagation();
                                      const checked = this.state.checked;
                                      if (event.target.checked) {
                                        checked.push(incident.id);
                                      } else {
                                        window._.remove(checked, function (_id: string) {
                                          return incident.id === _id;
                                        });
                                      }
                                      this.setState({
                                        checked,
                                      });
                                    }}
                                  />
                                </td>
                              ) : null}
                              <td className="item-box-tagline">
                                {Locale.toSimpleTimeDate(incident.timeOfCreation)}
                              </td>
                              <td className="item-box-tagline">
                                {`...${incident.id.substring(20)}`}
                              </td>
                              <td className="item-box-tagline" style={{ paddingRight: '10px' }}>
                                <Row
                                  flex-width={12}
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Row flexWidth={tags.length === 0 ? 12 : 6}>
                                    <HStack alignItems="center">
                                      {(incident.children ?? []).length > 0 && (
                                        <Tooltip
                                          padding="8px"
                                          offset={{ top: '0px', left: '-50px' }}
                                          width="100px"
                                          label={parentIncidentTooltipLabel}
                                        >
                                          <Icon as={IncidentMergeIcon} />
                                        </Tooltip>
                                      )}
                                      <Link
                                        to={generatePath(INCIDENT_DETAILS_PATH, {
                                          id: incident.id,
                                        })}
                                      >
                                        {incident.message.slice(0, 70) +
                                          (incident.message.length > 70 ? ' ...' : '')}
                                      </Link>
                                    </HStack>
                                  </Row>
                                  <Row alignItems="center">
                                    {tags.length > 0 && (
                                      <Tooltip
                                        offset={{ top: '10px' }}
                                        label={`${tags[0].key} : ${tags[0].value.value}`}
                                        width={
                                          `${tags[0]?.key} : ${tags[0]?.value?.value}`.length >
                                          TAG_MAX_LENGTH
                                            ? '300px'
                                            : 'fit-content'
                                        }
                                      >
                                        <IncidentTag
                                          color={tags[0].value.color}
                                          fontSize={12}
                                          fontWeight={400}
                                          tag={truncate(tags[0].key, TAG_DASHBOARD_LENGTH)}
                                          padding="2px 8px"
                                          value={
                                            <TagValue
                                              value={truncate(
                                                tags[0].value.value,
                                                TAG_DASHBOARD_LENGTH,
                                              )}
                                            />
                                          }
                                          style={{
                                            lineHeight: '12px',
                                            maxWidth: TAG_COMP_MAX_WIDTH,
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                    {incident.event_count > 1 && (
                                      <span className="item-member">{incident.event_count}</span>
                                    )}
                                  </Row>
                                </Row>
                              </td>
                              <td className="item-box-tagline">{serviceComponent}</td>
                              <td className="item-box-tagline">
                                {this.props.integrations.i[
                                  window._.findIndex(
                                    this.props.integrations.i,
                                    (integrationType: IIntegration) =>
                                      integrationType._id === incident.alert_source_id,
                                  )
                                ]
                                  ? this.props.integrations.i[
                                      window._.findIndex(
                                        this.props.integrations.i,
                                        (integrationType: IIntegration) =>
                                          integrationType._id === incident.alert_source_id,
                                      )
                                    ].type
                                  : 'Alert Source Deleted'}
                              </td>
                              <td className="item-box-tagline">{assigneeLinkComponent}</td>
                              <td className="item-box-tagline">
                                <span className={incident.status + '-box'}> {incident.status}</span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Grid alignItems="center" type="column" className="mt-20"></Grid>
                  <div style={{ marginTop: 32, display: 'flex', paddingBottom: 50 }} className="">
                    {this.generatePageLengthSelector()}
                    <div className="float-right" style={{ flexGrow: 100 }}>
                      {this.generatePagination()}
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ paddingTop: 60 }}>
                  <div style={{ textAlign: 'center' }}>
                    <img
                      src="/icons/empty-states/incident.svg"
                      alt="incident"
                      style={{ display: 'inline-block', width: 150 }}
                    />
                    <p className="empty-state-headline">No incidents found!</p>
                    <Link to="/incident/create">
                      <button className="main-button" style={{ marginTop: 30, marginBottom: 50 }}>
                        Create Incident
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
        <DialogModalFrame
          id="bulk_action_modal_acknowledge"
          width="650px"
          onClose={this.closeBulkAcknowledge.bind(null, false)}
        >
          {this.state.confirmAcknowledgement && (
            <BulkActionModal
              hide={this.closeBulkAcknowledge}
              buttonText={'Confirm & Acknowledge'}
              displayText={''}
              incidents={this.state.checked.map((incidentId: string) => {
                return this.state.incidents[
                  window._.findIndex(
                    this.state.incidents,
                    (incident: IIncident) => incident.id === incidentId,
                  )
                ];
              })}
            />
          )}
        </DialogModalFrame>

        <DialogModalFrame
          id="bulk_action_modal_resolve"
          width="650px"
          onClose={this.closeBulkResolve.bind(null, false)}
        >
          {this.state.confirmResolve && (
            <BulkActionModal
              hide={this.closeBulkResolve}
              buttonText={'Confirm & Resolve'}
              displayText={''}
              incidents={this.state.checked.map((incidentId: string) => {
                return this.state.incidents[
                  window._.findIndex(
                    this.state.incidents,
                    (incident: IIncident) => incident.id === incidentId,
                  )
                ];
              })}
            />
          )}
        </DialogModalFrame>
        <DialogModalFrame
          id="bulk_action_modal_resolve"
          width="550px"
          onClose={this.closeReassign.bind(null, false)}
        >
          {this.state.confirmReAssign && <ReassignUserConfirmation Close={this.closeReassign} />}
        </DialogModalFrame>
      </div>
    );
  }
}

const DashboardComponent = connect(
  (state: IAppState) => ({
    organization: state.organization,
    userInfo: state.userInfo,
    integrations: state.integrations,
    APP_CONFIG: state.APP_CONFIG,
    selectedOrg: state.userOrganizations.o.find(
      o => o.organizationId === state.INIT_ORG.orgId,
    ) as IOrganization,
  }),
  {
    setDashConfigs,
    requestUserOrganization,
    requestOrganizationUsers,
  },
)(Dashboard);

/**
 * Functional Wrapper for Dashboard in order to use Snackbar Context
 */
const DashboardHOC = (props: IProps) => {
  const _createSnack = SnackContext();

  const [componentState, setComponentState] = useState<IComponentNetworkState>('idle');
  const [showSnack, setShowSnack] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [showConfirmFreePlan, setConfirmFreePlan] = useState<boolean>(false);
  const [trialExpired, setTrialExpired] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    setTrialExpired(BillingService.isProductTrialExpired(props));
  }, []);

  useEffect(() => {
    if (trialExpired) {
      setShowUpgradeModal(true);
    } else {
      setShowUpgradeModal(false);
    }
  }, [trialExpired]);

  const activateEmail = useCallback(
    async (userId: string) => {
      try {
        setShowSnack(true);
        setComponentState('request');
        const _authService = new AuthService();
        await _authService.activateUser(userId);
        setComponentState('success');
      } catch (err: any) {
        exception.handle('E_START_EMAIL_VERIFY_FAILED', err);
        setComponentState('error');
        setError('Network Error. Try again after sometime.');
      } finally {
        setTimeout(() => {
          setShowSnack(false);
          history.push('/');
        }, 3000);
      }
    },
    [history],
  );

  useEffect(() => {
    if (!showSnack) {
      _createSnack(null);
    } else {
      _createSnack(
        <SnackBar className="incident_list__styled_snack" background={theme.shades.white}>
          <ContainerLoad isLoading={componentState === 'request'} />
          <div>
            <Grid justifyContent="center">
              <Heading fontSize={16} height={24} fontWeight={400} style={{ textAlign: 'center' }}>
                Email Verification
              </Heading>
            </Grid>
            {componentState === 'error' && (
              <Grid alignItems="center" type="column" className="mt-10">
                <Para
                  fontSize={14}
                  style={{ padding: 0, color: theme.danger.default }}
                  className="mt-10"
                >
                  {error}
                </Para>
              </Grid>
            )}
            {componentState === 'success' && (
              <Grid alignItems="center" type="column" className="mt-10">
                <Para
                  fontSize={14}
                  style={{ padding: 0, color: theme.success.default }}
                  className="mt-10"
                >
                  Email verified successfully!
                </Para>
              </Grid>
            )}
          </div>
        </SnackBar>,
      );
    }
    return () => _createSnack(null);
  });

  useEffect(() => {
    if (props.match.path === '/activate-user/:_id') {
      activateEmail((props.match.params as any)._id);
    }
  }, [props.match.path, props.match.params]);

  return (
    <>
      <DashboardComponent
        {...{
          ...props,
          activateUser: componentState,
        }}
      />
      <TrailExpiredModal
        showModal={showUpgradeModal}
        showFreePlan={!BillingService.isOnExtendedProductTrialPlan(props)}
        hasBillingPermission={BillingService.hasManageBillingPermission(props)}
        onCancel={() => {}}
        onFree={async () => {
          setShowUpgradeModal(false);
          await setConfirmFreePlan(true);
        }}
        onUpgrade={() => {
          setShowUpgradeModal(false);
        }}
      />
      <DialogModalFrame
        id="confirm_free_plan"
        width="400px"
        onClose={() => {
          setConfirmFreePlan(false);
          setShowUpgradeModal(true);
        }}
      >
        {showConfirmFreePlan ? (
          <ConfirmModal
            hide={async (result: boolean) => {
              if (result) {
                await setConfirmFreePlan(false);
                await history.push('/settings/billings');
              } else {
                await setConfirmFreePlan(false);
                await setShowUpgradeModal(true);
              }
            }}
            buttonText={'Continue to Free Plan'}
            displayText={`This will change some of your configuration. You will lose access to the Pro features. Would you still like to continue?`}
          />
        ) : (
          ''
        )}
      </DialogModalFrame>
    </>
  );
};

export default connect((state: IAppState) => ({
  organization: state.organization,
  selectedOrg: state.userOrganizations.o.find(
    o => o.organizationId === state.INIT_ORG.orgId,
  ) as IOrganization,
}))(DashboardHOC);
