import React, { useContext, useEffect, useMemo } from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  Button,
  Flex,
  Text,
  Divider,
  useClipboard,
  HStack,
  Image,
  VStack,
  Tooltip,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Link,
  Icon as ChakraIcon,
} from '@chakra-ui/react';
import { CopyIcon, ArrowBackIcon, InfoIcon } from '@chakra-ui/icons';
import { PropsDrawerInterface } from '../../Interfaces/intigration';
import { AppConfig } from 'shared/app.config';
import { useAddAlertSource } from '../../hooks/useAddAlertSource';
import NodePopover from '../../components/Popover';
import { useEditService } from '../../hooks/useEditService';
import { ServiceTooltip } from '../../components';
import { AppTracker } from '../../../../../../shared/analytics/tracker';
import { T_WA_UP_ADD_ALERT_SOURCE } from '../../../../../../core/const/tracker';
import { useUserAccess } from 'core/userAccess/UserAccessContext';

function AlertSourceDrawer({
  isOpen,
  onClose,
  finalFocusRef,
  name,
  shortName,
  id,
  version,
  api_key,
  refetch_API_key,
  supportDoc,
  type,
  addAlertSourcefunc,
  selected,
  image,
  email,
  serviceId,
}: PropsDrawerInterface) {
  const _uA = useUserAccess();
  const hasRead = _uA.hasReadAccess('services');
  const hasCreate = _uA.hasCreateAccess('services');
  const hasUpdate = _uA.hasUpdateAccess('services');
  const hasDelete = _uA.hasDeleteAccess('services');

  const { addalertsource_, addAlertSourceData } = useAddAlertSource();

  const [value, setValue] = React.useState('');
  const [emailValue, setEmailValue] = React.useState('');

  const [emailPrefix, emailSuffix] = useMemo(() => {
    if (email) {
      const emailSeparator = email.indexOf('@');
      return [email.substring(0, emailSeparator), email.substring(emailSeparator)];
    }

    return ['', ''];
  }, [email]);

  const emailAlertSource = useMemo(() => name === 'Email', [name]);
  const isEmailChanged = useMemo(
    () => email !== emailValue + emailSuffix,
    [email, emailValue, emailSuffix],
  );

  const copyValue = useMemo(
    () => (api_key ? (emailAlertSource ? emailValue + emailSuffix : value) : ''),
    [emailAlertSource, emailValue, emailSuffix, value],
  );

  const { hasCopied, onCopy } = useClipboard(copyValue);
  const { editService_, isEditing } = useEditService();

  const onEmailChange = () => {
    editService_({
      serviceId,
      email_prefix: emailValue,
    });
  };

  const addAlertSource = () => {
    if (addAlertSourcefunc != undefined) {
      addAlertSourcefunc(id);
      AppTracker.track(T_WA_UP_ADD_ALERT_SOURCE);
    }
  };

  if (addAlertSourceData?.data) {
    onClose();
  }

  useEffect(() => {
    setValue(`${AppConfig.api_url}/${version}/incidents/${shortName}/${api_key}`);
  });

  useEffect(() => {
    if (emailPrefix) {
      setEmailValue(emailPrefix);
    }
  }, [emailPrefix]);

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="lg"
      finalFocusRef={finalFocusRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader padding="20px">
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <ArrowBackIcon onClick={onClose} />
              <Text fontSize="xl" fontWeight={400} marginLeft="10px">
                Add Alert Source
              </Text>
            </Box>
            <DrawerCloseButton boxShadow="none !important" />
          </Box>
        </DrawerHeader>
        <Divider orientation="horizontal" />
        <DrawerBody>
          <Box>
            <HStack marginRight="30px">
              <Box borderWidth="0.2px" padding="20px" maxWidth="35%">
                <ChakraIcon aria-label={name} as={image} height={24} width={'auto'} />
              </Box>
              <VStack height="40px">
                <Text fontWeight={400} fontSize="20px" minWidth="max-content">
                  {name}
                </Text>
                {selected && (
                  <Text textAlign="left" color="#38A169">
                    {selected.includes(id) ? 'Added' : ''}
                  </Text>
                )}
              </VStack>
            </HStack>

            <Box></Box>
            <Box marginTop="20px">
              <Text fontSize="16px" fontWeight={400}>
                Configure your alert source
              </Text>
              <Text color="#4A5568" fontWeight={400} fontSize="14px">
                Make sure the configuration is setup accurately at the source end
              </Text>
            </Box>

            <Box marginTop="20px">
              <HStack mb={2}>
                <Text color="#2D3748" fontSize="16px" fontWeight={500}>
                  {emailAlertSource ? 'Email' : 'Webhook URL'}
                </Text>

                {emailAlertSource && (
                  <Tooltip
                    hasArrow
                    bgColor="white"
                    color="gray.700"
                    fontWeight={400}
                    label="Configure any third-party monitoring tools to email this ID to create an incident"
                  >
                    <InfoIcon />
                  </Tooltip>
                )}
              </HStack>

              <Flex mb={2} alignItems="center">
                {hasUpdate && emailAlertSource ? (
                  <HStack spacing={4} flex={1}>
                    <InputGroup>
                      <Input
                        value={emailValue}
                        onChange={({ target: { value } }) => setEmailValue(value)}
                      />
                      <InputRightAddon>
                        <ServiceTooltip text={emailSuffix}>
                          <Text isTruncated maxW={200}>
                            {emailSuffix}
                          </Text>
                        </ServiceTooltip>
                      </InputRightAddon>
                    </InputGroup>
                  </HStack>
                ) : (
                  <Box flex={1}>
                    <NodePopover
                      triggerType="hover"
                      enableTriggerWrapper
                      disablePortal={!!copyValue}
                      trigger={
                        <Input
                          value={copyValue}
                          isReadOnly
                          fontWeight={400}
                          fontSize="16px"
                          bg="#EDF2F7"
                          color="#171923"
                          isTruncated
                          noOfLines={1}
                        />
                      }
                    >
                      <Text fontSize={12}>{copyValue}</Text>
                    </NodePopover>
                  </Box>
                )}

                <Box>
                  {emailAlertSource && isEmailChanged ? (
                    <Button
                      ml={2}
                      variant="default"
                      size="sm"
                      type="button"
                      isLoading={isEditing}
                      loadingText="Update"
                      onClick={onEmailChange}
                      disabled={!emailValue.trim()}
                    >
                      Update
                    </Button>
                  ) : api_key ? (
                    <Button
                      onClick={onCopy}
                      aria-label=""
                      ml={2}
                      bg="#FFFFFF"
                      color="#2D3748"
                      fontSize="14px"
                      variant="outline"
                    >
                      <Box justifyContent="space-between">
                        <HStack>
                          <Box>
                            <CopyIcon />
                          </Box>
                          <Box fontWeight={600} fontSize="14px">
                            {hasCopied ? 'Copied' : 'Copy'}
                          </Box>
                        </HStack>
                      </Box>
                    </Button>
                  ) : (
                    <Button size="sm" variant="outline" onClick={refetch_API_key} ml={2}>
                      Retry
                    </Button>
                  )}
                </Box>
              </Flex>

              {!api_key && (
                <Text color="red.400" fontSize="xs">
                  Failed to fetch {emailAlertSource ? 'Email' : 'Webhook URL'} details
                </Text>
              )}
            </Box>

            <Box marginTop="20px">
              {' '}
              <Box>
                <Link
                  className="installation-guide-design no-select"
                  target="_blank"
                  href={supportDoc}
                  rel="noopener noreferrer"
                >
                  {`${type} Integration Guide`}
                </Link>
              </Box>
            </Box>
          </Box>
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px" paddingX="24px" justifyContent="flex-start">
          {selected && selected.includes(id) ? (
            <Button onClick={addAlertSource} bg="default" color="#ffffff">
              Remove Alert Source
            </Button>
          ) : (
            <Button onClick={addAlertSource} bg="default" color="#ffffff">
              Add Alert Source
            </Button>
          )}
          <Button variant="outline" ml={3} onClick={onClose}>
            Cancel
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default AlertSourceDrawer;
