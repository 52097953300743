import {
  ErrorBlock,
  FocusBlock,
  Grid,
  IconButton,
  InputBlock,
  Para,
  PulseLoader,
  Radio,
  SelectBox,
  SpinLoader,
  TextButton,
  Theme,
} from 'uie/components';
import { Refresh } from 'icons';
import * as React from 'react';
import { exception } from '../../../../../../../core/exception';
import {
  IComponentErrorState,
  IComponentNetworkState,
  IComponentState,
} from '../../../../../../../core/interfaces/IComponentState';
import {
  ISlackChannel,
  ISlackChannelResponse,
} from '../../../../../../../core/interfaces/IExtensions';
import { ExtensionService } from '../../../../../../../core/services';

const { theme } = Theme;

interface IProps {
  authorized: boolean;
  display: boolean;
  close: () => void;
  selectedChannel: ISlackChannel | null;
  showEmptyChannelError: boolean;
  resetShowEmptyChannelError: () => void;
  checkAndSetDirty: () => void;
}

interface IState {
  componentState: IComponentState;
  componentErrorState: IComponentErrorState;
  componentNetworkState: IComponentNetworkState | 'request_refresh' | 'request_save';
  selectedChannel: ISlackChannel | null;
  channels: ISlackChannelResponse[];
  searchChannelName: string;
  inputChannelId: string;
  channelOption: 'dropdown' | 'input';
}

class SlackChannelSelect extends React.Component<IProps, IState> {
  _extensionService = new ExtensionService();

  constructor(props: IProps) {
    super(props);

    this.state = {
      componentState: 'init',
      componentNetworkState: 'idle',
      componentErrorState: {},
      selectedChannel: props.selectedChannel,
      channels: [],
      searchChannelName: '',
      inputChannelId: props.selectedChannel?.channel.id || '',
      channelOption: 'input',
    };
  }

  componentDidMount() {
    if (this.props.authorized) {
      this.getSlackChannels();
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.showEmptyChannelError !== this.props.showEmptyChannelError &&
      this.props.showEmptyChannelError
    ) {
      this.setState({
        componentNetworkState: 'error',
        componentErrorState: {
          network_error: 'Please select a channel to complete the integration',
        },
      });
    }
  }

  getSlackChannels = async () => {
    try {
      this.setState({ componentState: 'busy' });
      const {
        data: {
          data: { channels },
        },
      } = await this._extensionService.getSlackChannels();
      this.setState({ channels, componentState: 'idle' });
    } catch (err: any) {
      exception.handle('E_SLACK_GET_CHANNELS', err);
      this.setState({
        componentState: 'error',
        componentErrorState: {
          E_SLACK_GET_CHANNELS: err?.response?.data?.meta?.error_message ?? 'Network Error',
        },
      });
    }
  };

  onSlackChannelSelect = (_: any, selectedChannel: ISlackChannel) => {
    this.setState({ selectedChannel });
    this.props.checkAndSetDirty();
  };

  onSlackChannelSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ searchChannelName: e.target.value });

  onSlackChannelRefresh = async () => {
    try {
      this.props.resetShowEmptyChannelError();
      this.setState({ componentNetworkState: 'request_refresh' });
      const {
        data: {
          data: { channels },
        },
      } = await this._extensionService.getSlackChannels();
      this.setState({ channels, componentNetworkState: 'success' });
    } catch (err: any) {
      exception.handle('E_SLACK_GET_CHANNELS', err);
      this.setState({
        componentNetworkState: 'error',
        componentErrorState: {
          network_error: err?.response?.data?.meta?.error_message ?? 'Network Error',
        },
      });
    }
  };

  validate = () => {
    if (this.state.selectedChannel !== null || this.state.inputChannelId !== '') return true;
    this.setState({
      componentNetworkState: 'error',
      componentErrorState: { network_error: 'No channel selected' },
    });
    return false;
  };

  onSlackChannelSave = async () => {
    this.props.resetShowEmptyChannelError();
    if (!this.validate()) return;
    try {
      this.setState({ componentNetworkState: 'request_save' });
      await this._extensionService.setOrganizationChannel(
        this.state.channelOption === 'input'
          ? this.state.inputChannelId
          : this.state.selectedChannel!.channel.id,
      );
      this.setState({ componentNetworkState: 'success' });
      this.props.close();
    } catch (err: any) {
      this.setState({
        componentNetworkState: 'error',
        componentErrorState: {
          network_error: err?.response?.data?.meta?.error_message ?? 'Network Error',
        },
      });
    }
  };

  renderUnauthorized = () => {
    return (
      <>
        <Grid justifyContent="flex-start" className="mb-20">
          <Para color={theme.shades.smoke}>
            Please finish the <span className="font-bold">Authorize</span> step for selecting a
            channel.
          </Para>
        </Grid>
      </>
    );
  };

  renderAuthorized = () => {
    const {
      componentState,
      componentErrorState,
      componentNetworkState,
      selectedChannel,
      channels,
      searchChannelName,
    } = this.state;
    return (
      <>
        {componentState === 'busy' && (
          <Grid alignItems="center" justifyContent="center">
            <SpinLoader base="16px" />
            <Para className="ml-10">Loading Slack Channels</Para>
          </Grid>
        )}
        {componentState === 'error' && (
          <Grid alignItems="center" justifyContent="flex-start">
            <ErrorBlock>{componentErrorState['E_SLACK_GET_CHANNELS']}</ErrorBlock>
          </Grid>
        )}
        {componentState === 'idle' && (
          <>
            <Grid type="column" alignItems="center" justifyContent="flex-start" className="mb-20">
              <Para color={theme.shades.smoke} className="mb-10">
                To complete this integration, please choose a channel from the dropdown below.
              </Para>
              <Para color={theme.shades.smoke}>
                You can also create a different channel, refresh the dropdown below to choose it
                from here.
              </Para>
            </Grid>
            <Grid alignItems="center" style={{ marginBottom: '10px' }}>
              <label style={{ marginRight: '10px' }}>
                <Radio
                  value={'input'}
                  checked={this.state.channelOption === 'input'}
                  onChange={e =>
                    this.setState({ channelOption: e.target.value as 'input' | 'dropdown' })
                  }
                />
                <span style={{ marginLeft: '5px' }}>Channel ID</span>
              </label>
              <label>
                <Radio
                  value={'dropdown'}
                  checked={this.state.channelOption === 'dropdown'}
                  onChange={e =>
                    this.setState({ channelOption: e.target.value as 'input' | 'dropdown' })
                  }
                />
                <span style={{ marginLeft: '5px' }}>Select a Channel</span>
              </label>
            </Grid>
            <Grid flexWidth={12}>
              {this.state.channelOption === 'input' && (
                <InputBlock
                  onChange={e => this.setState({ inputChannelId: e.target.value })}
                  value={this.state.inputChannelId}
                  fontSize="12px"
                  width={250}
                  placeholder="Slack Channel ID"
                />
              )}
              {this.state.channelOption === 'dropdown' && (
                <Grid flexWidth={6} alignItems="center">
                  <SelectBox
                    hook={
                      <Para fontSize={14}>
                        {selectedChannel
                          ? `#${selectedChannel.channel.name}`
                          : 'Select a slack channel'}
                      </Para>
                    }
                    onValueChange={this.onSlackChannelSelect}
                    height="auto"
                    maxHeight="200px"
                    width="95%"
                    maxWidth="100%"
                    searchHookProps={{
                      value: searchChannelName,
                      height: '24px',
                      fontSize: '14px',
                      onChange: this.onSlackChannelSearchChange,
                      placeholder: 'Search Channel',
                    }}
                    disabled={componentNetworkState.includes('request')}
                  >
                    {channels
                      .filter(c =>
                        searchChannelName.length > 0
                          ? c.name.toLowerCase().includes(searchChannelName.toLowerCase())
                          : true,
                      )
                      .map((c, i) => (
                        <FocusBlock
                          key={i}
                          value={c}
                          isSelected={!!selectedChannel && selectedChannel.channel.id === c.id}
                        >
                          <Para fontSize={14}>#{c.name}</Para>
                        </FocusBlock>
                      ))}
                  </SelectBox>
                  <IconButton
                    disabled={componentNetworkState.includes('request')}
                    onClick={this.onSlackChannelRefresh}
                  >
                    <Refresh color={theme.shades.black} height={20} width={20} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid flexWidth={12} justifyContent="flex-end">
              <TextButton
                width="100px"
                height="36px"
                onClick={this.onSlackChannelSave}
                disabled={componentNetworkState.includes('request')}
              >
                {componentNetworkState === 'request_save' ? (
                  <PulseLoader color={theme.shades.white} base="6px" />
                ) : (
                  <Para fontSize={16} color={theme.shades.white} fontWeight={500}>
                    Save
                  </Para>
                )}
              </TextButton>
            </Grid>
            {componentNetworkState === 'error' && (
              <ErrorBlock className="mt-10">{componentErrorState['network_error']}</ErrorBlock>
            )}
          </>
        )}
      </>
    );
  };

  render() {
    const { authorized, display } = this.props;

    if (!display) return null;

    return <>{authorized ? this.renderAuthorized() : this.renderUnauthorized()}</>;
  }
}
export default SlackChannelSelect;
