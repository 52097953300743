import * as React from 'react';
import { SVGProps } from 'react';

const SvgNagios = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path d="M1.893 18.912a2.285 2.285 0 0 1 2.396-1.081c2.183.237 4.388.225 6.569-.04a8.722 8.722 0 0 1 2.16-.016l3.357.277c.346.024.69.073 1.028.145a.728.728 0 0 1 .608.57.711.711 0 0 1-.405.802 2.11 2.11 0 0 1-.973.279l-3.607-.171a26.65 26.65 0 0 0-3.121.089 23.34 23.34 0 0 1-4.319.027c-.797-.07-1.604-.025-2.405-.04a1.403 1.403 0 0 1-1.29-.522l.002-.319zM17.981 4.661l.119 2.731c.02.589.001 1.187-.055 1.764a13.072 13.072 0 0 0 0 3.685c.075.53.059 1.068-.047 1.592a1.369 1.369 0 0 1-1.509 1.27 14.598 14.598 0 0 1-2.614-.381 2.679 2.679 0 0 1-1.498-1.165 16.672 16.672 0 0 1-.973-1.57 12.815 12.815 0 0 0-1.861-2.614 12.98 12.98 0 0 1-1.159-1.63 3.201 3.201 0 0 0-.254-.409.546.546 0 0 0-.96.182 1.909 1.909 0 0 0-.1.635l-.08 3.695a1.83 1.83 0 0 1-.138.623 1.709 1.709 0 0 0-.037 1.54.617.617 0 0 1-.444.889 3.672 3.672 0 0 1-2.82-.079 1.27 1.27 0 0 1-.791-1.197l-.095-2.653a1.73 1.73 0 0 1 .564-1.467 2.42 2.42 0 0 0 .449-.563c.272-.396.255-.91-.043-1.279a5.026 5.026 0 0 1-.977-3.712l.059-2.647.006-.643A.8.8 0 0 1 3.748.356 5.55 5.55 0 0 0 5.71-.01a1.187 1.187 0 0 1 1.629.725c.101.218.179.447.269.671.128.389.427.698.812.84.402.159.739.449.956.824l2.522 3.634c.258.376.556.724.889 1.036.36.327.658.225.717-.271.112-.767.148-1.545.107-2.32a26.032 26.032 0 0 1 .04-3.609A1.204 1.204 0 0 1 15.166.139l.889.051a1.716 1.716 0 0 1 1.789 1.425c.092.336.145.681.16 1.029l.008 2.012-.031.005z" />
  </svg>
);

export default SvgNagios;
