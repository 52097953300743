import {
  BinaryFilter,
  Filter as CubeFilter,
  TimeDimension as CubeTimeDimension,
  TimeDimensionGranularity,
} from '@cubejs-client/core';
import { PRIORITY } from 'library/common';
import { compact } from 'lodash';

import { Filters } from '../types';
import { compareDateRange, dateRange } from './date.utils';
import schema from '../schema';

export const wrapFilters = (...args: (CubeFilter | null)[]): CubeFilter[] => compact(args);

export const teamsFilter = (member: string, teamIds: Filters['teamIds']): CubeFilter | null => {
  if (!teamIds.length) {
    return null;
  }

  return {
    member,
    operator: 'equals',
    values: teamIds,
  };
};

export const servicesFilter = (
  member: string,
  serviceIds: Filters['serviceIds'],
): CubeFilter | null => {
  if (!serviceIds.length) {
    return null;
  }

  return {
    member,
    operator: 'equals',
    values: serviceIds,
  };
};

export const priorityFilter = (
  member: string,
  priority: Filters['priority'],
): CubeFilter | null => {
  if (!priority.length) {
    return null;
  }

  const priorityValues = priority.map(p => (p === PRIORITY.Unset || !p ? null : p));

  return {
    member,
    operator: 'equals',
    values: priorityValues,
  } as BinaryFilter;
};

export const usersFilter = (members: string[], userIds: Filters['userIds']): CubeFilter | null => {
  if (!userIds.length) {
    return null;
  }

  const cubeFilters: BinaryFilter[] = members.map(member =>
    member == schema.IncidentsAssignedToUserId
      ? {
          member,
          operator: 'contains',
          values: userIds,
        }
      : {
          member,
          operator: 'equals',
          values: userIds,
        },
  );

  return { or: cubeFilters };
};

export const tagsFilter = (member: string, tags: Filters['tags']): CubeFilter | null => {
  if (!tags.length) {
    return null;
  }

  const tagKeyToValueMappings: Map<string, string[]> = new Map();

  tags.forEach(tag => {
    //from a:b
    //to "a": "b"
    const splitTags = tag.split(':');
    const key = splitTags[0];
    if (!tagKeyToValueMappings.has(key)) {
      tagKeyToValueMappings.set(key, []);
    }
    tagKeyToValueMappings.get(key)?.push(splitTags.map(part => `"${part}"`).join(': '));
  });

  const filters: BinaryFilter[] = [];

  tagKeyToValueMappings.forEach(val => {
    filters.push({
      member,
      operator: 'contains',
      values: val,
    });
  });

  return {
    and: filters,
  };
};

export const timeDimensions = (
  dimension: string,
  filter: Filters['date'],
  options?: { compare?: boolean; granularity?: TimeDimensionGranularity },
): CubeTimeDimension[] => {
  return [
    {
      dimension,
      granularity: options?.granularity,
      ...(options?.compare
        ? { compareDateRange: compareDateRange(filter) }
        : { dateRange: dateRange(filter) }),
    },
  ];
};
