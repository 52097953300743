import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  IconButton,
  Spinner,
  Text,
  Image,
} from '@chakra-ui/react';
import { API, BillingService, debounce } from 'core';
import { FilterIcon } from 'library/icons';
import { isEmpty } from 'lodash';
import React, { ComponentState, FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { STATUSPAGE_V2_CREATE_PATH, STATUSPAGE_V2_PATH } from 'views/main/routes/routes';

import { AlertDialogComponent } from '../components/Alert';
import { CustomDrawerComponent } from '../components/Drawer';
import Header from '../components/Header';
import Layout from '../components/Layout';
import { Search } from '../components/Search';
import {
  DETAILS_TABS,
  FILTER_TYPES,
  PAGE_LIST_DRAWER_TYPES,
  SearchActionKind,
} from '../constants/status.constants';
import {
  decodeFilterParam,
  encodeFilterParam,
  getLoadingState,
  getStructuredFilters,
} from '../helpers/helper.service';
import useQueryParams from '../hooks/useQueryParams';
import { useStausPageList } from '../hooks/useStatusPageList';
import { IfilterObject, IStatusPageList } from '../Interface';
import UpdatePageStatus from '../statuspage.details/status.page.update';
import FilterStatus from './filter';
import FilterList from './filter/filterList';
import StautsList from './statusList';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_LIST_PAGE_VIEWED } from 'core/const/tracker';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { IAppState } from 'core/interfaces/IAppState';
import { shallowEqual, useSelector } from 'react-redux';
import NoData from '../../../../../icons/statuspage_empty.svg';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { THEME_COLORS } from 'library/theme/colors';

type closeCB = (type: string, value: string) => void;

const StatusPageDashboard = () => {
  const history = useHistory();
  const query = useQueryParams();
  const {
    isLoading,
    isFetched,
    isError,
    list,
    totalCount,
    orgTotalCount,
    isSuccess,
    refetch,
    filterDetails,
    deleteStatusPageAction,
    state: {
      pagination: { queryPageIndex, queryPageSize },
      search: { enabled: isSearchEnabled },
      drawer: { isDrawerOpen, drawerType, pageId },
      filters,
      deleteStatusPage: { showModal, selectedPageID },
      filterCount,
    },
    dispatch,
  } = useStausPageList(API.config.teamId);

  const organization = useSelector((state: IAppState) => state.organization, shallowEqual);

  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [statusPagePlanLimit, setStatusPagePlanLimit] = useState<number>(0);

  const uA = useUserAccess();
  const hasRead = uA.hasReadAccess('status_pages');
  const hasCreate = uA.hasCreateAccess('status_pages');
  const hasUpdate = uA.hasUpdateAccess('status_pages');
  const hasDelete = uA.hasDeleteAccess('status_pages');

  const searchQueryParam = query.get('search');
  const filterParams = query.get('filters');

  const debouncedSearch = useRef(
    debounce((searchParam: string) => {
      dispatch({ type: SearchActionKind.SEARCHED, searchText: searchParam || '' });
      dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex: 1 });
    }, 1000),
  ).current;

  useEffect(() => {
    const hasSearchParam = typeof searchQueryParam === 'string';
    const hasFilter = typeof filterParams === 'string';

    if (hasSearchParam) {
      dispatch({
        type: SearchActionKind.SEARCH_ENABLED_OR_DISABLED,
        isEnabled: true,
      });

      debouncedSearch(searchQueryParam);
    } else {
      dispatch({
        type: SearchActionKind.SEARCH_ENABLED_OR_DISABLED,
        isEnabled: false,
      });
    }
    if (hasFilter) {
      const filterObj = decodeFilterParam(filterParams);
      dispatch({
        type: SearchActionKind.APPLY_FILTER,
        filters: filterObj,
      });
    }
  }, [query.get('search'), query.get('filters')]);

  const closeDeleteModal = () => {
    dispatch({
      type: SearchActionKind.REJECT_PAGE_DELETE,
    });
  };

  useEffect(() => {
    dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex: 1 });
  }, [API.config.teamId]);

  useEffect(() => {
    AppTracker.track(T_WA_UP_STATUS_PAGE_V2_LIST_PAGE_VIEWED);
  }, []);

  const StatusPagetTable = (
    {
      failed: () => <></>,
      loading: () => (
        <Center h="60vh" data-testid="loader">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      ),
      done: () => {
        return (
          <StautsList
            data={list as IStatusPageList[]}
            pageChange={(queryPageIndex: number) =>
              dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex })
            }
            pageSizeChange={(queryPageSize: number) => {
              dispatch({ type: SearchActionKind.PAGE_SIZE_CHANGED, queryPageSize });
              dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex: 1 });
            }}
            onStatusUpdate={(pageId: number) => {
              dispatch({
                type: SearchActionKind.OPEN_DRAWER,
                drawerType: PAGE_LIST_DRAWER_TYPES.STATUS,
                isDrawerOpen: true,
                pageId: pageId,
              });
            }}
            onIssue={(pageId: number) => {
              history.push(`${STATUSPAGE_V2_PATH}/${pageId}?tab=${DETAILS_TABS.ISSUES}`);
            }}
            onMaintenace={(pageId: number) => {
              history.push(`${STATUSPAGE_V2_PATH}/${pageId}?tab=${DETAILS_TABS.MAINTENANCE}`);
            }}
            queryPageIndex={queryPageIndex}
            queryPageSize={queryPageSize}
            totalCount={totalCount}
            onDelete={(id: number) =>
              dispatch({
                type: SearchActionKind.SELECT_PAGE_DELETE,
                pageId: id,
              })
            }
            canEdit={hasUpdate}
          />
        );
      },
    } as Record<ComponentState, FC>
  )[getLoadingState(isLoading, isFetched, isError)];

  const MemoizedStausPageTable = useMemo(
    () => StatusPagetTable,
    [isLoading, isFetched, isError, list],
  );

  const onClickAddStatusPageButton = () => {
    if (!BillingService.isFeatureDisabled({ organization }, 'status-page')) {
      const statusPagePlanLimitExceeded = BillingService.isLimitExceeded(
        { organization },
        'status-page',
        () => orgTotalCount,
      );
      if (statusPagePlanLimitExceeded) {
        const statusPageLimit = BillingService.getLimit({ organization }, 'status-page');
        if (typeof statusPageLimit == 'number') {
          setStatusPagePlanLimit(() => statusPageLimit);
        }
        setShowUpgradeModal(true);
      } else {
        history.push(STATUSPAGE_V2_CREATE_PATH);
      }
    } else {
      setShowUpgradeModal(true);
    }
  };

  const onCancel: closeCB = (filterKey: string, filterValue: string) => {
    const newFilters = { ...filters };
    if (filterKey === FILTER_TYPES.PAGE_TYPE) {
      newFilters[FILTER_TYPES.PAGE_TYPE] = filters[FILTER_TYPES.PAGE_TYPE].filter(
        (value: string) => value !== filterValue,
      );
    }
    if (filterKey === FILTER_TYPES.STATUS) {
      newFilters[FILTER_TYPES.STATUS] = filters[FILTER_TYPES.STATUS].filter(
        (value: string) => value !== filterValue,
      );
    }
    const filterUrlParams = encodeFilterParam(newFilters);
    query.delete('filters');
    if (!isEmpty(filterUrlParams)) {
      query.append('filters', filterUrlParams);
    } else {
      dispatch({
        type: SearchActionKind.APPLY_FILTER,
        filters: newFilters,
      });
    }
    history.push(`${STATUSPAGE_V2_PATH}?${query}`);
  };
  const structuredFilters = getStructuredFilters(filters);

  const closeDrawer = () => {
    dispatch({ type: SearchActionKind.CLOSE_DRAWER });
  };

  return (
    <Layout>
      <Fragment>
        <Header
          title={
            <Text fontSize={27} color={THEME_COLORS.secondary[900]}>
              Status Page
            </Text>
          }
          actions={
            <Flex justifyContent={'flex-end'} gap={5}>
              <Search
                setSearchTerm={searchTerm => {
                  query.delete('search');
                  query.append('search', searchTerm);
                  history.push(`${STATUSPAGE_V2_PATH}?${query}`);
                }}
                searchTerm={searchQueryParam ?? ''}
                searchEnabled={isSearchEnabled}
                setSearchEnabled={isEnabled => {
                  if (isEnabled) {
                    query.append('search', '');
                    history.push(`${STATUSPAGE_V2_PATH}?${query}`);
                  } else {
                    query.delete('search');
                    history.push(`${STATUSPAGE_V2_PATH}?${query}`);
                  }
                }}
                isDisabled={!isSuccess}
              />
              <Box position={'relative'}>
                <IconButton
                  aria-label="Filter status"
                  variant="icon"
                  data-testid="filter-icon"
                  onClick={() => {
                    dispatch({
                      type: SearchActionKind.OPEN_DRAWER,
                      drawerType: PAGE_LIST_DRAWER_TYPES.FILTER,
                      isDrawerOpen: true,
                    });
                  }}
                  icon={<FilterIcon />}
                />
                {filterCount > 0 ? (
                  <Box
                    bgColor={'red.500'}
                    w={3}
                    h={3}
                    borderRadius={6}
                    mr={2}
                    position="absolute"
                    top={-1}
                    right={-3}
                  />
                ) : null}
              </Box>
              <Fragment>
                <Center height={8}>
                  <Divider orientation="vertical" />
                </Center>
                <NoPermissionTooltip isDisabled={hasCreate}>
                  <Button
                    isDisabled={!hasCreate}
                    onClick={onClickAddStatusPageButton}
                    variant="default"
                    size="sm"
                  >
                    Add Status Page
                  </Button>
                </NoPermissionTooltip>
              </Fragment>
            </Flex>
          }
        />
        <FilterList filters={structuredFilters} onCancel={onCancel} />
        <Divider height={0.5} backgroundColor="shades.smoke" />
        {totalCount > 0 ? (
          <MemoizedStausPageTable />
        ) : (
          <Center width={'inherit'} display={'flex'} height={'500px'}>
            {isLoading ? (
              <Center display={'block'}>
                <Box
                  flexDirection="row"
                  justifyContent="center"
                  width={'300px'}
                  display="flex"
                  marginTop="20px"
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                </Box>
              </Center>
            ) : (
              <Center display={'block'}>
                <Box
                  flexDirection="row"
                  justifyContent="center"
                  width={'300px'}
                  display="flex"
                  marginTop="20px"
                >
                  <Image src={NoData} alt="No Data Found" />
                </Box>
                <Text
                  textAlign={'center'}
                  padding={'10px'}
                  fontSize="24px"
                  lineHeight="24px"
                  fontWeight={'300'}
                >
                  No Status Pages created yet
                </Text>
                <Box
                  flexDirection="row"
                  justifyContent="center"
                  width={'300px'}
                  display="flex"
                  marginTop="20px"
                >
                  <NoPermissionTooltip isDisabled={hasCreate}>
                    <Button
                      width={'150px'}
                      height={'50px'}
                      bgColor={'rgba(15, 97, 221, 1)'}
                      color={'white'}
                      fontWeight={'500'}
                      fontSize={'14px'}
                      lineHeight={'20px'}
                      onClick={onClickAddStatusPageButton}
                      justifyContent="center"
                      isDisabled={!hasCreate}
                    >
                      Add Status Page
                    </Button>
                  </NoPermissionTooltip>
                </Box>
              </Center>
            )}
          </Center>
        )}

        <CustomDrawerComponent
          onClose={closeDrawer}
          isOpen={isDrawerOpen}
          title={drawerType || ''}
          size="md"
        >
          {drawerType === PAGE_LIST_DRAWER_TYPES.FILTER ? (
            <FilterStatus
              filters={filters}
              filterDetails={filterDetails}
              onClose={closeDrawer}
              onFilterSubmit={(selectedFilters: IfilterObject) => {
                const filterUrlParams = encodeFilterParam(selectedFilters);
                query.delete('filters');
                if (!isEmpty(filterUrlParams)) {
                  query.append('filters', filterUrlParams);
                } else {
                  dispatch({
                    type: SearchActionKind.APPLY_FILTER,
                    filters: selectedFilters,
                  });
                }
                closeDrawer();
                history.push(`${STATUSPAGE_V2_PATH}?${query}`);
              }}
            />
          ) : null}
          {drawerType === PAGE_LIST_DRAWER_TYPES.STATUS ? (
            <UpdatePageStatus pageId={pageId} onCancel={closeDrawer} refetch={refetch} />
          ) : null}
        </CustomDrawerComponent>
        <AlertDialogComponent
          isOpen={showModal}
          onClose={closeDeleteModal}
          callbackFn={() => {
            deleteStatusPageAction(selectedPageID);
            closeDeleteModal();
          }}
          msg="Are you sure you want to delete?"
          title="Delete Status Page"
          isDelete
        />
        <UpgradePlanModal
          hasBillingPermission={BillingService.hasManageBillingPermission({ organization } as Pick<
            IAppState,
            'organization'
          >)}
          showModal={showUpgradeModal}
          message={BillingService.getMessage(statusPagePlanLimit, 'status-page', {
            organization,
          } as Pick<IAppState, 'organization'>)}
          header={BillingService.getHeader(statusPagePlanLimit, 'status-page', {
            organization,
          } as Pick<IAppState, 'organization'>)}
          onCancel={() => setShowUpgradeModal(false)}
        />
      </Fragment>
    </Layout>
  );
};

export default StatusPageDashboard;
