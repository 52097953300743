import { Box } from '@chakra-ui/react';

import { useIncidentSummaryContext } from '../../../context/hook';
import IncidentSummaryImpact from '../../summary/Impact';
import IncidentSummaryParticipants from '../../summary/Participants';
import IncidentSummaryStatus from '../../summary/Status';
import IncidentSummaryLLMResponse from '../../summary/Summary';

const IncidentSummaryDrawerSuccess: React.FC = () => {
  const { overview, summary } = useIncidentSummaryContext();

  if (!overview.data || !summary.data) return null;

  return (
    <Box>
      <IncidentSummaryStatus status={overview.data.data.status} />
      <IncidentSummaryImpact service={overview.data.data.service} />
      <IncidentSummaryParticipants />
      <IncidentSummaryLLMResponse content={summary.data.data.content} />
    </Box>
  );
};

export default IncidentSummaryDrawerSuccess;
