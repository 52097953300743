import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { requestUserPermissionRoles } from '../../actions';
import { requestOrganizationCurrentUser } from '../../actions/organization/currentUser';
import { requestOrganizationIntegrations } from '../../actions/organization/integrations';
import { requestOrganizationPlan } from '../../actions/organization/plan';
import { requestOrganizationTeams } from '../../actions/organization/teams';
import { requestOrganizationUsers } from '../../actions/organization/users';
import { INIT_ORG } from '../../const/init';

const initOrg = (action$: Observable<any>) =>
  action$.pipe(
    ofType(INIT_ORG),
    map(action => action.payload),
    switchMap(() => [
      requestUserPermissionRoles(),
      requestOrganizationPlan(),
      requestOrganizationIntegrations(),
      requestOrganizationUsers(),
      requestOrganizationTeams(),
      requestOrganizationCurrentUser(),
    ]),
  );

export default initOrg;
