import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const LinkTheme: ComponentStyleConfig = {
  baseStyle: {
    color: 'brand.blue',
    fontFamily: 'Mulish',
  },
  variants: {
    sidemenuItems: {
      color: '#09305A',
      height: '33px',
      textTransform: 'capitalize',
      marginTop: '0px !important',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '18px',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        backgroundColor: 'secondary.100',
        textDecoration: 'none',
      },
      _activeLink: {
        fontWeight: 800,
        color: 'secondary.900',
        background: 'primary.200',
      },
    },
    default: {
      textDecoration: 'none',
      fontSize: '9px',
      fontWeight: 400,
      _focus: {
        boxShadow: 'none',
      },
      _hover: {},
      _activeLink: {},
    },
  },
};
