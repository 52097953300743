import { Circle, HStack, VStack } from '@chakra-ui/layout';
import { As, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { SlackIcon, JiraBlueIcon, MsTeamsIcon } from 'icons';

type Props = {
  content: string | React.ReactNode;
  circleH?: string;
  textSize?: string;
};

const icons: { [key: string]: As } = {
  Slack: SlackIcon,
  Jira: JiraBlueIcon,
  [`Microsoft Teams`]: MsTeamsIcon,
};
const pattern = /(\w+) is not integrated\. Please integrate to continue\./;

const Wrapper: FC<{ children: React.ReactNode; circleH?: string }> = ({ children, circleH }) => (
  <VStack w="full" flex={1} justifyContent="center" h="full" mt="12" mb="8">
    <HStack mb="12" justifyContent="center" pl="100px">
      <Circle size={circleH ?? '185px'} color="primary.300" backgroundColor="primary.300" />
      <Circle
        size={circleH ?? '185px'}
        position="relative"
        top="36px"
        left="-100px"
        color="primary.200"
        backgroundColor="primary.200"
      />
    </HStack>
    {children}
  </VStack>
);

function EmptyUI(props: Props) {
  const { content, circleH, textSize } = props;

  if (typeof content === 'string') {
    const RenderedIcon = icons[content.replace(pattern, '$1')];

    return (
      <VStack w="full" flex={1} justifyContent="center" h="full" mt="12" mb="8">
        {RenderedIcon && <RenderedIcon height={60} width={60} />}
        <Text textAlign="center" variant={textSize ?? 'h1'} as={textSize ?? 'h1'}>
          {content}
        </Text>
      </VStack>
    );
  } else {
    return <Wrapper circleH={circleH}>{content}</Wrapper>;
  }
}

export default EmptyUI;
