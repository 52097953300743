import React from 'react';
import { DialogModalFrame, Grid, Para } from 'uie/components';
import { FileUploadService } from 'core';
import { IPatchReq } from 'core/interfaces/ITask';
import { CheckCircle } from 'icons';
import TaskUnit from './../addTaskUnit';
import { ModalBtns } from '../runbook/RunbookModal';
import { snackMsg } from '../taskAndRunbook.constants';

interface IProps {
  setEnableModel: (enableModel: boolean) => void;
  selectedTask: IPatchReq | null;
  setSelectedTask: (selectedTask: IPatchReq | null) => void;
  taskContent: string;
  setContent: (content: string) => void;
  updateTaskCall: () => void;
  addTask: () => void;
  enableTaskModel: boolean;
}

export default function TaskModal(props: IProps) {
  const [taskContentIsEmpty, setTaskContentIsEmpty] = React.useState(false);
  const fileUploadService = new FileUploadService();
  const contentUpdate = (content: string) => {
    if (props.selectedTask) {
      const updatedContent: IPatchReq = {
        index: props.selectedTask.index as number,
        completed: props.selectedTask.completed as boolean,
        content,
      };
      props.setSelectedTask(updatedContent);
    }

    props.setContent(content);
  };
  const isDisabled = props.selectedTask
    ? !props.selectedTask.content.length
    : !props.taskContent.length;

  const btnTxt = `${props.selectedTask ? 'Update' : 'Add'} Task`;

  const onCancel = () => {
    props.setEnableModel(false);
  };
  const onClickFn = () => {
    const taskContentIsEmpty = props.taskContent.trim().length === 0;
    if (taskContentIsEmpty) {
      setTaskContentIsEmpty(true);
      return;
    }
    setTaskContentIsEmpty(false);

    props.selectedTask ? props.updateTaskCall() : props.addTask();
  };
  return (
    <DialogModalFrame
      id="incident_details__description_confirm_resolve_reassign_modal"
      onClose={() => {
        props.setEnableModel(false);
      }}
      padding="16px"
      width="70vw"
    >
      {props.enableTaskModel && (
        <>
          <Grid type="column" style={{ padding: '15px' }}>
            <Para fontWeight={400} fontSize={24}>
              <CheckCircle />
              <span style={{ position: 'relative', top: '-30px', left: '36px' }}>
                {props.selectedTask ? 'Update' : 'New'} Task
              </span>
            </Para>
            <div className="mb-10">
              <TaskUnit
                content={props.selectedTask?.content}
                fileUploadService={fileUploadService}
                contentUpdate={contentUpdate}
                isUpdate={!!props.selectedTask}
              />
              {taskContentIsEmpty && (
                <Para className="pt-10" fontSize={14} color="red">
                  {snackMsg.TASK_SHOULD_NOT_BE_EMPTY}
                </Para>
              )}
            </div>
            <ModalBtns
              onCancel={onCancel}
              onClickFn={onClickFn}
              isDisabled={isDisabled}
              btnTxt={btnTxt}
            />
          </Grid>
        </>
      )}
    </DialogModalFrame>
  );
}
