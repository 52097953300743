import {
  REQUEST_ORG_TEAMS,
  REQUEST_ORG_TEAMS_FAIL,
  REQUEST_ORG_TEAMS_SUCCESS,
} from '../../const/organization';
import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';
import { ITeam } from '../../interfaces/ITeams';

const initialOrganizationSquads = INITIAL_STATE.organization.teams;

const organizationSquads = (state = initialOrganizationSquads, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ action: RESET_STATE, t: [], error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_TEAMS:
      return {
        ...state,
        ...{ action: REQUEST_ORG_TEAMS, error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_TEAMS_SUCCESS:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_TEAMS_SUCCESS,
          t: action.payload.teams.sort((a: ITeam, b: ITeam) =>
            a.default || b.default ? -1 : a.name.localeCompare(b.name),
          ),
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ORG_TEAMS_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_TEAMS_FAIL,
          t: [],
          error: { type: REQUEST_ORG_TEAMS_FAIL, reason: action.payload.error },
        },
      };
    default:
      return state;
  }
};

export default organizationSquads;
