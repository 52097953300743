import * as React from 'react';
import { SVGProps } from 'react';

const SvgUptrendsLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Uptrends_Logo_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.Uptrends_Logo_svg__st1{fill:#fff}'}</style>
    <path
      d="M15.382 19.983H4.609A4.609 4.609 0 0 1 0 15.374V4.63A4.602 4.602 0 0 1 4.609.017H15.39A4.61 4.61 0 0 1 20 4.626v10.748a4.619 4.619 0 0 1-4.618 4.609"
      style={{
        fill: '#00bcc5',
      }}
    />
    <path
      className="Uptrends_Logo_svg__st1"
      d="M9.327 7.465h1.06v2.589c0 .765-.183 1.372-.528 1.775-.353.416-.848.615-1.48.615-.557 0-.985-.15-1.268-.441-.299-.299-.449-.765-.449-1.376V5.503H3.15v1.966h1.106v3.263c0 1.06.341 2.016.956 2.685.632.69 1.484 1.043 2.49 1.043.752 0 1.376-.15 1.858-.449.378-.229.673-.549.869-.956v1.309h2.406V5.499H9.323l.004 1.966zM19.996 11.608c-.033.042-.067.083-.1.129-.387.428-.935.657-1.579.657-.644 0-1.189-.229-1.579-.657-.416-.449-.615-1.097-.615-1.887 0-.781.216-1.43.636-1.887.399-.428.935-.657 1.567-.657s1.168.229 1.567.657c.033.042.067.075.1.121V5.453a3.794 3.794 0 0 0-1.06-.158c-1.259 0-2.211.47-2.864 1.397h-.021V5.49h-2.352v12.186h2.406v-4.539h.021c.657.894 1.629 1.351 2.855 1.351.341 0 .686-.046 1.018-.158v-2.722z"
    />
  </svg>
);

export default SvgUptrendsLogo;
