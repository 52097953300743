import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const NumberInput: ComponentStyleConfig = {
  defaultProps: {
    width: '105px',
    height: '40px',
    defaultValue: 1,
    max: 10,
    clampValueOnBlue: true,
    allowMouseWheel: true,
  },
};
