import { T_WA_ENTITIES_TAB_VIEWED } from 'core/const/tracker';
import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AppTracker } from 'shared/analytics/tracker';
import { EntitiesTable } from './EntitiesTable';
import { EntityItem } from './EntityItem';
import { useGetEntitiesCount } from './hooks';
import { Container, List, SidebarContainer, SidebarHeader, Title } from './styles';
import { EntityNames } from './types';

export function EntityList() {
  const { data, isLoading, isError } = useGetEntitiesCount();

  const history = useHistory();
  const location = useLocation();
  const hash = location.hash;

  const selectedId = hash.replace('#', '');
  const isSelected = selectedId !== '';

  const teamId = useParams<{ id: string }>().id;

  useEffect(() => {
    AppTracker.track(T_WA_ENTITIES_TAB_VIEWED, {
      'Team ID': teamId,
    });
  }, [teamId]);

  useEffect(() => {
    if (!isSelected && (data?.length ?? 0) > 0) {
      history.push({
        search: location.search,
        hash: '#' + data?.[0].id,
      });
    }
  }, [data, isSelected]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error</div>;
  }

  const totalCount = data?.reduce((acc, entity) => acc + entity.count, 0);

  return (
    <Container>
      <SidebarContainer>
        <SidebarHeader>
          <Title>Entities ({totalCount})</Title>
        </SidebarHeader>
        <List>
          {data?.map(entity => (
            <EntityItem
              name={entity.name}
              ID={entity.id + ''}
              key={entity.id}
              count={entity.count}
            />
          ))}
        </List>
      </SidebarContainer>
      <EntitiesTable
        entityCount={data?.find(entity => entity.id === selectedId)?.count ?? 0}
        selectedType={selectedId as keyof typeof EntityNames}
      />
    </Container>
  );
}
