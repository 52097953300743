import { Box, HStack, Text, useBoolean, VStack } from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  title: string | JSX.Element;
  description: string | JSX.Element;
  width?: string;
  hoverElement?: JSX.Element;
  padding?: string;
}

export const Stats: FC<Props> = ({ title, description, width, hoverElement, padding }) => {
  const [hover, showOnHover] = useBoolean(false);
  const titleJSX =
    typeof title === 'string' ? (
      <Box mb="9.75px">
        <Text variant="body_800" color="secondary.700" textTransform="uppercase">
          {title}
        </Text>
      </Box>
    ) : (
      title
    );

  const descJSX =
    typeof description === 'string' ? (
      <Box mb="9.75px">
        <Text variant="h4" color="override.bg" textTransform="capitalize">
          {description}
        </Text>
      </Box>
    ) : (
      description
    );

  return (
    <HStack
      gap={1}
      width={width}
      sx={{ position: 'relative', padding: padding ?? '24px', flex: 1 }}
      _hover={{
        background: hoverElement && 'primary.200',
      }}
      onMouseEnter={showOnHover.on}
      onMouseLeave={showOnHover.off}
    >
      <VStack alignItems="flex-start" sx={{ marginRight: hoverElement && '0%' }}>
        {titleJSX}
        {descJSX}
      </VStack>
      {hoverElement && <Box sx={{ visibility: hover ? 'visible' : 'hidden' }}>{hoverElement}</Box>}
    </HStack>
  );
};
