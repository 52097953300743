import * as React from 'react';
import { SVGProps } from 'react';

const SvgCopperegg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="copperegg_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.copperegg_svg__st0{fill:#81382a}.copperegg_svg__st2{fill:#b75f37}.copperegg_svg__st12{fill:#af5238}.copperegg_svg__st13{fill:#a14930}.copperegg_svg__st26{fill:#bd7055}.copperegg_svg__st27{fill:#ab5736}.copperegg_svg__st28{fill:#c9795c}.copperegg_svg__st29{fill:#a44d31}.copperegg_svg__st38{fill:#da9d85}.copperegg_svg__st41{fill:#d99d87}.copperegg_svg__st42{fill:#de8a68}.copperegg_svg__st44{fill:#ac5a43}.copperegg_svg__st45{fill:#b55938}.copperegg_svg__st54{fill:#c87951}.copperegg_svg__st61{fill:#c5785c}.copperegg_svg__st67{fill:#e18f6e}.copperegg_svg__st69{fill:#e49372}.copperegg_svg__st82{fill:#d7805f}.copperegg_svg__st89{fill:#ce855e}.copperegg_svg__st94{fill:#d77947}.copperegg_svg__st102{fill:#f6bba1}.copperegg_svg__st104{fill:#d57d5b}.copperegg_svg__st118{fill:#d48d66}.copperegg_svg__st123{fill:#cb815b}.copperegg_svg__st128{fill:#f3cfb8}.copperegg_svg__st140{fill:#dd9973}.copperegg_svg__st141{fill:#e1a380}.copperegg_svg__st154{fill:#e5a27c}.copperegg_svg__st160{fill:#f2bb97}.copperegg_svg__st161{fill:#efbd9c}'
      }
    </style>
    <path
      className="copperegg_svg__st0"
      d="M16.986 10c1.021 6.56-3.172 10-7.043 10S2.2 15.753 2.953 10c.807-6.505 4.14-10 6.99-10 3.172 0 6.129 3.817 7.043 10z"
    />
    <path
      d="M10.588.269c.107.322.484.054.591.322-.054.162-.215.162-.376.162-.377 0-.753.107-1.076.322-.698.43-1.182.162-1.72-.215a1.61 1.61 0 0 1 .538-.215C9.19.538 9.78.43 10.373.323c.053-.054.107-.054.215-.054z"
      style={{
        fill: '#bb6339',
      }}
    />
    <path
      className="copperegg_svg__st2"
      d="M10.319.269C9.727.484 9.136.699 8.49.59c.215-.107.484-.215.699-.322h1.129z"
    />
    <path
      d="M16.502 14.301c.053.269-.215.43-.27.699-.59 1.075-1.182 2.097-2.096 2.903-.591.538-1.29.807-1.882 1.183-.699.161-1.344.484-2.043.43-.645 0-1.29-.107-1.935-.269-.161-.053-.43-.053-.484-.322.215-.323.538-.108.806-.054 2.527.591 5.054-.699 6.29-2.742.377-.43.753-.914 1.022-1.452.108-.215.215-.483.592-.376z"
      style={{
        fill: '#893d2d',
      }}
    />
    <path
      d="M3.33 13.871c-.162-.484-.27-.968-.215-1.452a3.55 3.55 0 0 1 0-1.505c-.054-.215 0-.43 0-.591.053-.054.107-.054.16-.108.27-.054.377.054.485.323.054.215.054.43.107.591.054.538.108 1.022.162 1.505v1.022c.053.269-.108.43-.377.484-.107-.054-.161-.269-.322-.269z"
      style={{
        fill: '#af5536',
      }}
    />
    <path
      d="M4.405 16.452c-.538-.753-.968-1.56-1.13-2.474.055-.053.108-.107.216-.053.269.322.538.322.86.215.269.054.215.376.323.537.215.7.484 1.344.86 1.936.107.161.215.269.107.484-.107.107-.268.161-.376 0-.215-.269-.538-.377-.86-.645z"
      style={{
        fill: '#b4533a',
      }}
    />
    <path
      d="M5.104 3.871c.107-.161.215-.376.322-.538.162-.215.27-.484.592-.537.054 0 .107 0 .161.053.054.054.054.162.054.216-.269.537-.753.913-.968 1.505-.107.215-.054.484-.215.645-.43.807-.376 1.72-.376 2.58v1.506c-.054.269.107.538-.108.807-.054.053-.107.053-.215 0-.161-.216-.215-.538-.215-.807 0-1.236-.107-2.473.108-3.71 0-.107.053-.215.053-.268.108-.323.215-.7.43-.968.216-.161.216-.377.377-.484z"
      style={{
        fill: '#d28c72',
      }}
    />
    <path
      d="M16.77 10.591v.323c.054.161.054.269.054.43a6.145 6.145 0 0 1-1.075 2.903c-.108.215-.215.43-.484.538-.054 0-.107 0-.161-.054-.054-.054-.108-.107-.108-.161-.053-.43.215-.699.377-1.022.16-.16.215-.376.322-.591 0-.054.054-.108.054-.161.914-1.022.645-2.258.484-3.441-.054-.323-.269-.7-.054-1.022.43-.107.215.323.43.43.108.216-.054.484.108.7v.053c.107.323-.054.699.053 1.075z"
      style={{
        fill: '#b25436',
      }}
    />
    <path
      className="copperegg_svg__st0"
      d="M12.254 18.978c.7-.483 1.506-.806 2.097-1.451.538-.592.968-1.237 1.398-1.936.108-.215.215-.43.484-.591-.645 1.828-1.828 3.172-3.602 3.979a.46.46 0 0 1-.377 0z"
    />
    <path
      d="M3.545 7.58c.053-.645.268-1.236.537-1.827.323.269.054.537.054.806-.323 1.398-.107 2.742.215 4.14 0 .215.108.484.108.699 0 .054 0 .107-.054.161-.054.054-.054.108-.108.108-.215 0-.268-.108-.376-.27-.161-.59-.269-1.182-.538-1.72.215-.645-.053-1.344.162-2.096z"
      style={{
        fill: '#c16d4c',
      }}
    />
    <path
      d="M7.953.86c.807.323 1.56.215 2.312-.161.215-.108.592-.054.914-.054h.215c.054.108.376.054.215.323-.322.215-.752.054-1.075.107-.376 0-.699.323-1.022.43-.268.108-.537.27-.86.108-.107 0-.215 0-.322-.054-.323-.322-.646-.107-.914.108-.108 0-.162 0-.215-.054-.054 0-.054-.054-.054-.108v-.053c.161-.323.484-.43.806-.592z"
      style={{
        fill: '#c1693e',
      }}
    />
    <path
      d="M3.76 9.785c.107.591.215 1.129.322 1.72.108.27.43.538.215.86-.053.054-.107.054-.16.108-.27-.054-.27-.269-.27-.484-.054-.269-.054-.537-.215-.806-.054-.323 0-.7-.484-.807-.107-.268-.053-.537 0-.806v-.054c.054-.054.054-.161.108-.215.645-.215.322.323.484.484z"
      style={{
        fill: '#b96342',
      }}
    />
    <path
      d="M16.502 14.301c-.538.376-.645 1.022-1.022 1.505-.107.162-.161.323-.322.43-.27-.214-.054-.43-.054-.698.484-.914 1.129-1.775 1.505-2.742.054-.108.108-.108.215-.108 0 .592-.161 1.13-.322 1.613z"
      style={{
        fill: '#93412c',
      }}
    />
    <path
      className="copperegg_svg__st12"
      d="M16.61 8.763c-.108-.16-.216-.322-.377-.43-.323 0-.323-.268-.376-.43l-.323-1.129c-.054-.376-.215-.753-.269-1.129-.054-.215-.107-.376.054-.591.215-.108.215.107.269.215.322.752.537 1.613.806 2.365-.107.377.269.7.215 1.13z"
    />
    <path
      className="copperegg_svg__st13"
      d="M16.77 12.742c-.483.968-.967 1.935-1.612 2.85a.116.116 0 0 1-.108-.108.523.523 0 0 1 .054-.484 8.208 8.208 0 0 0 1.344-2.42c0-.053.107-.107.161-.107s.108 0 .161.054c.054.054.054.107 0 .215z"
    />
    <path
      d="M11.932.86c1.021.484 1.774 1.237 2.365 2.15.054.108.162.216.108.377-.054.054-.108.054-.161.054-.215 0-.323-.161-.43-.323-.323-.537-.753-.967-1.237-1.344-.215-.322-.699-.376-.807-.806.108-.054.108-.108.162-.108z"
      style={{
        fill: '#b1613d',
      }}
    />
    <path
      d="M12.254 18.978h.377c-.43.323-1.022.377-1.506.538-.161.108-.43 0-.591.108-.645 0-1.29.053-1.936-.215.323-.162.646-.108.968 0 .215.053.43.107.645 0 .7-.054 1.398-.162 2.043-.43z"
      style={{
        fill: '#7a3326',
      }}
    />
    <path
      d="M10.211 19.409c-.537.268-1.075-.108-1.613 0a4.339 4.339 0 0 1-1.989-.807c.377-.322.7 0 1.022.054.107 0 .161.107.215.161.107.215.269.269.484.323.645.107 1.29.161 1.881.269z"
      style={{
        fill: '#833829',
      }}
    />
    <path
      d="M16.34 7.634c-.376-.591-.43-1.29-.699-1.935l-.322-.645c-.376 0-.376-.27-.376-.592 0-.107.053-.215.16-.215.592 1.022 1.076 2.15 1.237 3.387z"
      style={{
        fill: '#a64834',
      }}
    />
    <path
      d="M4.405 16.452c.538-.108.806.43 1.29.537.215.054.323.162.215.377-.161.16-.322.053-.484.053-.322-.053-.483-.322-.699-.537a.704.704 0 0 1-.322-.43z"
      style={{
        fill: '#ad4f37',
      }}
    />
    <path
      d="M3.76 9.785c-.162-.108-.108-.538-.43-.484-.108-.215-.054-.376 0-.591 0-.377.107-.753.215-1.13-.108.753.268 1.452.215 2.205z"
      style={{
        fill: '#bc6744',
      }}
    />
    <path
      d="M4.727 16.935c.216.108.43.323.7.323.322.215.752.269 1.075.538.107.107.268.161.215.376-.054.054-.108.054-.162.108-.483-.054-.752-.43-1.129-.592-.268-.161-.483-.43-.699-.753z"
      style={{
        fill: '#a24833',
      }}
    />
    <path
      d="M3.975 6.613c.054-.269.054-.538.107-.807 0-.215.108-.376.215-.537.054-.054.108-.054.162 0v.215c-.108.43-.162.806-.162 1.236-.107.27.054.592-.16.807-.055 0-.108 0-.162-.054 0-.269-.054-.591 0-.86z"
      style={{
        fill: '#cb7a5c',
      }}
    />
    <path
      d="M7.63 18.817c-.376.054-.644-.215-1.02-.215-.216-.107-.43-.215-.592-.43.215-.215.376.108.591.054.377.107.807.161 1.022.591z"
      style={{
        fill: '#8e3e2d',
      }}
    />
    <path
      d="M7.04 1.452v.107c.107.376-.216.484-.377.7a1.636 1.636 0 0 1-.43.43c-.161.053-.269.214-.484.107a4.427 4.427 0 0 1 1.29-1.344z"
      style={{
        fill: '#b96a4c',
      }}
    />
    <path
      d="M15.104 4.247v.215c-.43-.16-.7-.591-.7-1.129-.053-.107-.053-.215-.107-.322.323.322.592.752.807 1.236z"
      style={{
        fill: '#a64d38',
      }}
    />
    <path
      d="M6.61 18.28c-.216-.054-.43-.054-.592-.108-.215-.107-.377-.269-.538-.484.43 0 .753.377 1.13.43.053.054.053.108 0 .162z"
      style={{
        fill: '#984430',
      }}
    />
    <path
      className="copperegg_svg__st26"
      d="M5.749 2.742c.108-.054.215-.161.376-.215.054.054.054.054.054.107 0 .108-.107.162-.161.215-.215.162-.377.323-.592.43 0-.214.162-.376.323-.537z"
    />
    <path
      className="copperegg_svg__st26"
      d="M5.211 3.44c.108-.053.162-.214.323-.268.054.054.054.054.054.108 0 .107-.054.16-.108.268-.161.215-.269.377-.484.538 0-.269.108-.43.215-.645z"
    />
    <path
      className="copperegg_svg__st13"
      d="M16.77 11.344v-.484a4.072 4.072 0 0 1 0 1.613h-.053c-.162-.376.053-.753.053-1.129z"
    />
    <path className="copperegg_svg__st27" d="M3.115 10.968v1.505c-.162-.538-.108-1.021 0-1.505z" />
    <path
      className="copperegg_svg__st28"
      d="M4.459 5.269h-.162c.108-.377.215-.7.484-.968-.054.376-.215.645-.322.968z"
    />
    <path
      className="copperegg_svg__st29"
      d="M16.77 10.591c-.215-.322-.107-.699-.107-1.075.161.323.161.699.107 1.075z"
    />
    <path
      d="M3.545 13.978H3.33v-.107c.053-.054.053-.215.107-.215.161-.054.161.107.215.215a.116.116 0 0 1-.107.107z"
      style={{
        fill: '#b15536',
      }}
    />
    <path
      className="copperegg_svg__st2"
      d="M11.932.86v.108c-.162.107-.269.054-.323-.108-.054-.107-.161-.161-.215-.215.215-.054.376.054.538.215z"
    />
    <path className="copperegg_svg__st27" d="M3.222 9.516v.807h-.107c0-.27 0-.538.107-.807z" />
    <path
      d="M3.33 8.71V9.3l-.108.108c-.054-.215 0-.43.108-.7z"
      style={{
        fill: '#b4603e',
      }}
    />
    <path
      className="copperegg_svg__st28"
      d="M5.104 3.871a.684.684 0 0 1-.377.484c.108-.215.162-.43.377-.484z"
    />
    <path
      className="copperegg_svg__st12"
      d="M16.663 9.462c-.161-.215-.108-.483-.108-.699.162.215.162.43.108.7z"
    />
    <path
      d="M6.61 18.28v-.108h.107c.322-.269.591 0 .806.108.645.215 1.29.43 1.936.376 1.72.054 3.225-.43 4.462-1.613.269-.269.484-.645.591-1.022a.783.783 0 0 1 .323-.483c.108-.054.215-.108.323 0v.107c-.054.215-.054.43-.108.7-.591.86-1.29 1.612-2.204 2.15-1.613.86-3.28 1.021-5 .483H7.63c-.323-.376-.753-.43-1.022-.698z"
      style={{
        fill: '#994a38',
      }}
    />
    <path
      d="M3.652 11.129c.323.323.215.86.484 1.237.323.591.591 1.182.807 1.774.107.215.107.43-.108.645-.108.054-.161 0-.269-.054-.054-.215-.161-.376-.215-.591-.376-.162-.269-.484-.322-.753-.054-.269-.054-.538-.108-.806-.107-.43-.161-.968-.269-1.452z"
      style={{
        fill: '#b75639',
      }}
    />
    <path
      d="M3.867 12.634c.27.484.27 1.076.484 1.613 0 .162.054.43-.215.484-.215.054-.322-.161-.484-.322-.054-.108-.107-.215-.161-.377l.107-.107c.27.107.27-.108.216-.27a2.23 2.23 0 0 0 .053-1.02z"
      style={{
        fill: '#b25438',
      }}
    />
    <path
      d="M4.566 14.785h.215c.484.054.377.538.592.753 0 .215.053.43.16.645.055.161.216.322.055.537-.054.054-.108.054-.162 0-.43-.591-.645-1.29-.86-1.935z"
      style={{
        fill: '#b7563d',
      }}
    />
    <path
      d="M5.48 16.667h.108c.484-.108.645.43 1.021.537.162.215.538.323.484.7-.054.107-.107.107-.161.107-.43-.054-.753-.377-1.022-.645-.053-.108-.161-.215-.215-.323-.054-.108-.161-.215-.215-.376z"
      style={{
        fill: '#b3573e',
      }}
    />
    <path
      d="M4.566 10.108V9.3c.215-.054.323.108.377.215.43.592.59.592 1.021.054.323-.43.484-.914.591-1.452.162-.914.43-1.828.7-2.742.214-.699.214-1.451.322-2.15.054-.484-.108-.914-.43-1.29.484-.323.753-.162.86.645V4.462a3.147 3.147 0 0 0-.107.7v.16c-.054.216-.108.377-.108.592a1.933 1.933 0 0 0-.108.591c-.107.323-.16.646-.215 1.022-.268 1.021-.376 2.097-1.021 3.01-.323.27-.591.592-1.075.484-.054 0-.108 0-.162-.053-.16-.108-.322-.323-.537-.43-.162-.054-.162-.215-.108-.43z"
      style={{
        fill: '#e39575',
      }}
    />
    <path
      className="copperegg_svg__st38"
      d="M4.781 9.677c-.054-.107-.161-.215-.215-.322.054-1.183-.107-2.42.108-3.602.053-.215.107-.484.215-.7 0 0 .054.055.107.055.215.43.108.913.054 1.344-.107.537-.054 1.129-.107 1.666v1.076c0 .16.053.376-.162.483z"
    />
    <path
      d="M7.846 2.634c-.108-.376-.108-.806-.7-.591 0 .323-.322.376-.483.591-.161.108-.269.323-.538.215h-.107a.408.408 0 0 0 .107-.215c.377-.43.7-.86 1.237-1.129.43-.107.645.162.753.538 0 .215.053.376-.108.591-.054.054-.107.054-.161 0z"
      style={{
        fill: '#d38f6a',
      }}
    />
    <path
      d="M4.244 6.72c0-.43-.054-.86.215-1.236-.108 1.559-.215 3.118 0 4.624H4.35c-.215-.108-.215-.323-.269-.538 0-.054-.053-.161-.053-.269 0-.86.053-1.72.215-2.58z"
      style={{
        fill: '#cd8469',
      }}
    />
    <path
      className="copperegg_svg__st41"
      d="M5.158 4.462c.268-.537.537-1.021 1.021-1.344l.054.054c-.054.699-.538 1.183-.968 1.667 0 .053-.107 0-.161-.054-.054-.108-.054-.215.054-.323z"
    />
    <path
      className="copperegg_svg__st42"
      d="M4.566 10.108c.054.107.161.215.215.322.054.108.162.215.215.376 0 .108 0 .162-.107.216s-.215.053-.323-.054c-.215-.162-.215-.43-.269-.645 0-.108.054-.162.108-.215h.161z"
    />
    <path
      className="copperegg_svg__st41"
      d="M6.18 2.903c.16-.107.322-.215.43-.322a.408.408 0 0 1 .107.215c-.108.215-.215.43-.538.322-.054-.107-.054-.161 0-.215z"
    />
    <path
      className="copperegg_svg__st38"
      d="M5.158 4.462v.323c0 .161-.054.269-.215.215-.054-.215.053-.376.215-.538z"
    />
    <path
      d="M14.405 3.333c.215.377.484.753.699 1.13 0 .214.054.43.215.59 0 .592.322 1.13.322 1.721.323 1.29.7 2.635.753 3.979.054.806-.215 1.559-.537 2.365-.162.162-.216.377-.323.592a2.989 2.989 0 0 0-.484.914c-.054.107-.161.215-.269.053-.107-.483.162-.806.323-1.236.161-.484.322-1.022.537-1.506.27-.59.27-1.236.162-1.827-.108-.968-.162-1.99-.484-2.904-.108-.322 0-.699-.161-1.021-.215-.86-.484-1.72-.914-2.473a.331.331 0 0 1 .053-.377h.108z"
      style={{
        fill: '#b9603c',
      }}
    />
    <path
      className="copperegg_svg__st44"
      d="M14.889 14.677c.054-.053.161-.053.215-.107v.107c.107.323-.054.538-.161.807-.108.215-.27.43-.323.699-.645 1.236-1.774 1.882-3.065 2.312a6.499 6.499 0 0 1-2.043.322c-.537-.054-1.129-.107-1.666-.322-.162-.054-.484 0-.43-.377.215-.215.43-.107.645-.053.806.215 1.666.215 2.473.053 1.828-.43 3.44-1.129 3.978-3.172.108-.161.108-.376.377-.269z"
    />
    <path
      className="copperegg_svg__st45"
      d="M15.91 13.065c.054-.7.323-1.398.323-2.097.054-1.237-.215-2.473-.484-3.656-.054-.161-.054-.377-.108-.538.323.484.216 1.13.592 1.613.054.699.161 1.344.269 1.99.161.967-.108 1.827-.592 2.688z"
    />
    <path
      className="copperegg_svg__st29"
      d="M15.211 15.43c-.053.054-.16.054-.215.108-.107-.323.108-.592.215-.914.807-.968 1.237-2.097 1.56-3.28v1.13c-.538.43-.592 1.182-.968 1.72-.27.376-.592.752-.592 1.236z"
    />
    <path
      d="M15.211 14.677c-.053.323-.16.592-.215.914-.053.162-.161.323-.215.484-.054.054-.107.162-.215.108.215-.484.377-.968.592-1.452-.054-.054 0-.054.053-.054z"
      style={{
        fill: '#a8563f',
      }}
    />
    <path
      className="copperegg_svg__st45"
      d="M15.534 13.656c0-.269.107-.43.323-.591 0 .215-.108.43-.323.59z"
    />
    <path
      d="M4.351 11.613v-.108c.108-.107.161-.16.323-.107.053 0 .107.054.161.107.054.054.054.108.054.162.054.053 0 0 0 .053.322.592 1.021 1.076 1.398.914.591-.215.699-.752.806-1.29.054-.322.108-.699.215-1.021.054-.215.161-.323.43-.162.162.162.108.43.108.592.054.376-.054.752-.108 1.129v.161a9.35 9.35 0 0 1-.591.968c-.377.645-.914.537-1.452.322-.699-.43-1.236-.914-1.344-1.72z"
      style={{
        fill: '#ce7352',
      }}
    />
    <path
      d="M4.244 12.366c-.054-.323-.054-.592-.108-.914l.215.215a5.56 5.56 0 0 0 1.613 1.935c.215.323.376.645.376 1.075 0 .054-.053.108-.107.162-.161.107-.269.053-.43 0-.484-.215-.538-.86-1.022-1.13-.215-.43-.591-.806-.537-1.343z"
      style={{
        fill: '#c56545',
      }}
    />
    <path
      d="M5.695 13.763c-.537-.645-1.183-1.182-1.344-2.043v-.107c.376.538.645 1.183 1.344 1.452.215.16.43.268.7.376.322.215.698.376.698.86-.054.108-.107.215-.215.269-.323.107-.538-.108-.699-.323a1.055 1.055 0 0 0-.484-.484z"
      style={{
        fill: '#c96c4c',
      }}
    />
    <path
      d="M3.975 6.613v.914c.269.538.054 1.129.107 1.72 0 .054.054.108.054.162 0 .215.054.483.108.699.053.107.053.215.053.376 0 .107 0 .215-.16.322-.27-1.397-.539-2.795-.162-4.193z"
      style={{
        fill: '#c57456',
      }}
    />
    <path
      d="M4.244 10.806v-.322c.268-.054.215.215.268.322.054.216.323.43.162.7H4.35c-.054-.27-.107-.484-.107-.7z"
      style={{
        fill: '#cc7553',
      }}
    />
    <path
      d="M11.61.86c.107.054.214.108.322.108.269.215.537.43.806.699.323.215.538.484.807.752.107.108.268.27.161.484-.269.162-.376-.054-.538-.215-.484-.43-.914-.86-1.451-1.183-.377-.215-.753-.16-1.13-.16-.107-.055-.268-.108-.322-.216-.054-.107 0-.215.108-.269h1.236z"
      style={{
        fill: '#c27550',
      }}
    />
    <path
      d="M8.276 1.29c.107.054.161.054.269.108.215.161.484.322.752.269.108 0 .215 0 .323.107.107.215 0 .43-.108.645-.053.108-.16.162-.215.215-.376.323-.645.27-.86-.16l-.161-.323c0-.323-.215-.592 0-.86z"
      style={{
        fill: '#cc703f',
      }}
    />
    <path
      className="copperegg_svg__st54"
      d="M8.276 1.29c.054.27.054.538.107.807.054.107.054.215.054.322-.054.054-.107.054-.161.054-.161-.054-.215-.161-.215-.322-.054-.484-.323-.646-.753-.592l-.054-.054c.323-.322.646-.376 1.022-.215z"
    />
    <path
      d="M9.351 1.882c-.538.269-.538.269-.806-.484.269-.054.591-.054.914-.108.16.27-.054.377-.108.592z"
      style={{
        fill: '#c76b3b',
      }}
    />
    <path
      d="M9.351 1.882c.054-.215.054-.377.108-.592.268-.161.537-.43.914-.484-.054.054-.054.162-.108.215v.054c-.215.215-.376.484-.645.592-.161.107-.215.161-.269.215z"
      style={{
        fill: '#c66d40',
      }}
    />
    <path
      className="copperegg_svg__st54"
      d="M7.308 1.613c-.376.322-.753.699-1.129 1.021v-.107c.323-.323.591-.645.914-1.022h.215c.054.054.054.108 0 .108z"
    />
    <path
      d="M4.244 12.366c.215.43.483.806.699 1.236.215.323.43.7.59 1.075 0 .323.108.646-.268.86l-.484-.806c.054-.43-.107-.753-.269-1.129-.215-.376-.43-.806-.43-1.29.054.054.108.054.162.054z"
      style={{
        fill: '#bb583d',
      }}
    />
    <path
      d="M13.598 2.903c-.053-.591-.645-.806-.914-1.236.538.215.86.699 1.183 1.129.108.161.27.376.377.537.537.86.914 1.775 1.075 2.742.107.645.215 1.29.215 1.936.054.484.161.967-.054 1.451-.269.162-.322-.107-.322-.268-.108-.377-.108-.753-.215-1.13-.054-.806 0-1.559-.162-2.365-.215-.86-.537-1.667-1.075-2.42-.054-.107-.161-.214-.108-.376z"
      style={{
        fill: '#bd6c4e',
      }}
    />
    <path
      d="M7.416 18.172c.645.43 1.451.376 2.15.591.054.054.054.054 0 .108-1.021.054-1.935-.323-2.85-.699-.376-.323-.913-.538-1.236-.914.161.054.323.054.484.108.376.107.591.483 1.022.591.107.108.322-.054.43.161-.054 0-.054 0 0 .054z"
      style={{
        fill: '#a75039',
      }}
    />
    <path
      d="M4.136 9.462v-.107a5.217 5.217 0 0 1 0-1.828c0-.269-.054-.538.108-.807v2.742c-.054.054-.108.054-.108 0z"
      style={{
        fill: '#c97e63',
      }}
    />
    <path
      className="copperegg_svg__st61"
      d="M4.136 7.527v1.828c-.269-.645-.107-1.237-.107-1.828h.107z"
    />
    <path
      d="M9.566 18.817v-.107c1.72-.108 3.28-.645 4.516-1.936l.484-.645c.054-.054.161-.054.215-.108-.269.914-.968 1.506-1.774 1.936-1.075.591-2.204.968-3.44.86z"
      style={{
        fill: '#a05241',
      }}
    />
    <path
      d="M5.265 15.538c.108-.323.108-.592.108-.914.322 0 .322.268.322.484.108.806.377 1.505.968 2.096v.162c-.054.053-.108.053-.161 0-.43-.108-.592-.484-.914-.7-.054-.376-.377-.698-.323-1.128z"
      style={{
        fill: '#ba5b41',
      }}
    />
    <path
      d="M6.502 17.366h.107c.7 0 1.29.43 1.936.483.161.054.322.108.537.108.538 0 1.076-.054 1.613.269-.806.215-1.613.107-2.473.054l-.806-.162c-.108-.107-.215-.107-.323-.215-.215-.161-.484-.269-.591-.537z"
      style={{
        fill: '#b35d42',
      }}
    />
    <path
      className="copperegg_svg__st44"
      d="M7.04 17.957c.16 0 .268.108.322.215-.161 0-.323-.107-.484-.215h.161z"
    />
    <path
      d="M4.781 9.677V8.118c.108-.053.162 0 .215.054.108.215.054.538.162.753.16.43.322.43.645.107.107-.107.215-.322.269-.484.43-.967.698-1.935.914-2.957.107-.43.107-.43.43-.483 0 .16.053.268-.054.43-.215.967-.484 1.881-.699 2.85a4.147 4.147 0 0 1-.538 1.343c-.161.269-.322.645-.699.645-.376 0-.483-.376-.645-.699z"
      style={{
        fill: '#eeab8c',
      }}
    />
    <path
      d="M7.953 2.634v-.591c.323.161.27.484.27.753.107 1.559-.108 3.118-.539 4.623-.053.27.108.646-.322.753-.215-.215-.108-.43-.108-.645.054-.376.108-.699.215-1.075 0-.215 0-.377.108-.592.107-.161 0-.376.107-.591V5.16c.162-.215-.053-.484.108-.699V4.14c.215-.43 0-.968.161-1.506z"
      style={{
        fill: '#dc8c60',
      }}
    />
    <path
      className="copperegg_svg__st67"
      d="M4.889 11.022v-.216c.161-.053.215.054.322.108.484.215.753-.108 1.022-.484.43-.753.699-1.505.806-2.365.054-.216.108-.377.215-.592l.054.054c.054 1.129-.215 2.204-.699 3.226-.322.376-.699.752-1.29.591-.215-.054-.376-.107-.43-.322 0 .053 0 .053 0 0z"
    />
    <path
      d="M7.416 5.591v-.483c-.215-.216-.162-.484-.108-.7.054-.322.108-.59.108-.913-.054-.7-.054-.7-.753-.753v-.215c.161-.215.376-.323.591-.484.43 0 .538.323.538.699 0 .968-.108 1.935-.376 2.85z"
      style={{
        fill: '#dda989',
      }}
    />
    <path
      className="copperegg_svg__st67"
      d="M7.953 2.634c-.053.538.108 1.076-.107 1.613V2.634h.107z"
    />
    <path
      className="copperegg_svg__st69"
      d="M6.287 10.484c-.162.484-.484.699-1.022.484.43.054.699-.27 1.022-.484z"
    />
    <path
      className="copperegg_svg__st67"
      d="M7.523 6.505c-.054.323-.054.7-.107 1.022-.054.054-.054.054-.108 0-.054-.376 0-.699.215-1.022z"
    />
    <path
      className="copperegg_svg__st69"
      d="M5.265 10.914c-.107-.054-.215-.054-.322-.108-.054-.107-.054-.215-.108-.322.161.107.269.269.43.43z"
    />
    <path
      className="copperegg_svg__st67"
      d="M7.846 4.57c0 .215.054.484-.108.699 0-.269-.054-.484.108-.7zM7.738 5.376c0 .215 0 .377-.107.592 0-.215-.054-.43.107-.592z"
    />
    <path
      d="M6.61 2.742c.214-.108.322-.43.644-.215.323.161.27.538.27.806-.216.108-.27-.107-.377-.161-.269-.107-.43.108-.592.269-.86.86-1.182 1.935-1.236 3.118v.914c-.108.215-.054.538-.215.753-.054.054-.108.054-.161 0l-.108-.108c.054-1.021.108-2.043.108-3.064.053-.108.16-.162.215-.215.322-.592.699-1.13 1.021-1.72.108-.108.269-.216.43-.377z"
      style={{
        fill: '#e2b19e',
      }}
    />
    <path
      d="M7.416 8.226c.43-1.882.806-3.817.699-5.807 0-.107-.054-.215-.108-.322-.107-.377-.323-.484-.699-.484v-.108c.7-.107.914.377 1.022.914.322.538.16 1.076.107 1.667-.054 1.183-.322 2.366-.43 3.602 0 .215-.107.377-.107.592-.108.645-.108 1.29-.592 1.828-.054 0-.107 0-.161-.054-.108-.323 0-.645 0-.914.054-.377.054-.645.269-.914z"
      style={{
        fill: '#d78058',
      }}
    />
    <path
      d="M4.459 10.806c-.054-.107-.162-.215-.215-.322v-.323a.403.403 0 0 1 0-.484c.053.162.16.323.215.484v.215c.053.108.053.27 0 .43z"
      style={{
        fill: '#cd7e5f',
      }}
    />
    <path
      className="copperegg_svg__st61"
      d="M4.244 9.677v.484c-.162-.215-.108-.43-.108-.699h.108v.215z"
    />
    <path
      d="M4.674 10.914c.053.054.161.054.215.108.484.59.86.59 1.559-.108.215-.215.215-.591.376-.806.054-.108.054-.27.215-.216.054.108.108.27.162.377 0 .054 0 .107-.054.161-.161.376-.269.807-.592 1.13-.698.752-1.29.698-1.828-.162-.053-.161-.215-.269-.053-.484z"
      style={{
        fill: '#db8563',
      }}
    />
    <path
      d="M4.674 10.914c.053.161.053.323.107.484.054.107 0 .107-.107.107-.162-.215-.215-.43-.215-.699v-.483c.053.215.161.43.215.591z"
      style={{
        fill: '#d6815f',
      }}
    />
    <path
      d="M15.426 7.957c.054-.645-.107-1.29-.107-1.935.322 1.021.376 2.043.538 3.118.053.376.053.806.107 1.183.161 1.344-.591 2.473-.914 3.71-.054.214-.161.43-.215.644-.054.108-.161.215-.215.323-.108 0-.161-.054-.215-.161-.054-.592.215-1.076.43-1.56.753-1.72.806-3.494.591-5.322z"
      style={{
        fill: '#bb6640',
      }}
    />
    <path
      d="m14.512 14.892.108.108c-.323 1.667-1.505 2.527-3.065 3.01-.322.108-.591.162-.914.27-.59-.054-1.129.107-1.72-.108.108-.215.323-.161.484-.161a1.9 1.9 0 0 0 .914-.215c.753-.162 1.505-.323 2.097-.807.215-.215.537-.215.752-.43.43-.322.592-.806.807-1.29.107-.161.107-.377.322-.43.108 0 .215 0 .215.053z"
      style={{
        fill: '#b4654c',
      }}
    />
    <path
      d="M7.254 10c.27-.591.215-1.237.484-1.828.43.591.108 1.183-.054 1.72 0 0-.053.054-.107.054-.215.377-.215.807-.323 1.183-.053.161-.053.376-.107.538-.215.484-.323 1.021-.968 1.075-.591.054-1.398-.591-1.505-1.183.053-.054.053-.054.107-.054.108.108.215.215.323.27.054.053.107.053.107.107.914.484 1.398.161 1.72-1.022.108-.269.216-.537.323-.86z"
      style={{
        fill: '#d27856',
      }}
    />
    <path
      d="M7.523 11.828c.054-.376.054-.753.108-1.13.484-.483.806 0 1.236.162.323.43.215.807-.053 1.183-.484.161-.914.108-1.29-.215z"
      style={{
        fill: '#cc724d',
      }}
    />
    <path
      d="M8.76 10.914c-.377-.054-.753-.161-1.13-.215V10c.108-.108.054-.269.108-.43.215-.376.592-.43.86-.108.162.215.323.484.323.753s.054.538-.161.699z"
      style={{
        fill: '#cf734b',
      }}
    />
    <path
      d="M7.523 11.828c.43 0 .807.054 1.237.107.107.054.107.108.107.216-.161.376-.161.86-.645 1.021-.269 0-.591.108-.806-.107-.323-.43.053-.807.107-1.237 0 .054 0 .054 0 0z"
      style={{
        fill: '#ca714e',
      }}
    />
    <path
      d="M7.523 11.935c0 .377-.107.753 0 1.13.054.483-.322.806-.806.645a1.91 1.91 0 0 1-.484-.215c-.215-.108-.538-.108-.592-.484.86.215 1.076.107 1.56-.7.107-.107.215-.214.322-.376z"
      style={{
        fill: '#cb7251',
      }}
    />
    <path
      className="copperegg_svg__st82"
      d="M4.781 11.613h-.107v-.108l.107-.107c1.022.806 1.613.591 2.043-.645.054-.108.162-.27.215-.377.108.645-.161 1.183-.537 1.613-.323.377-.914.323-1.398-.054l-.323-.322z"
    />
    <path
      d="M8.545 18.065c-.753.053-1.29-.377-1.936-.7v-.107c-.376-.215-.591-.591-.699-1.021-.107-.43-.107-.86-.215-1.237a.259.259 0 0 1 .108-.323c.107.054.215.054.322.108.323.161.323.43.43.753.108.537 0 1.129.484 1.505.43.323.753.753 1.344.806.108-.053.215.054.162.216z"
      style={{
        fill: '#c06347',
      }}
    />
    <path
      d="M5.803 14.677c.107.484.215.914.215 1.398 0 .484.269.807.537 1.183-.699-.269-.806-.914-1.021-1.505-.108-.323-.108-.753-.269-1.076a3.566 3.566 0 0 1-.484-1.021c.592.161.645.753 1.022 1.021z"
      style={{
        fill: '#be5f44',
      }}
    />
    <path
      d="M6.932 17.15c-.484-.268-.484-.806-.538-1.236-.054-.376-.107-.753-.269-1.13v-.107c.538-.215.484.377.7.538.268.591.268 1.183.214 1.828l-.107.108z"
      style={{
        fill: '#c2664a',
      }}
    />
    <path
      d="M6.717 15.215c-.27-.107-.27-.484-.592-.591a1.694 1.694 0 0 0-.484-.914c.484.161.7.591 1.13.806.053.054.107.108.107.161.108.215.161.484-.161.538z"
      style={{
        fill: '#c5694c',
      }}
    />
    <path
      d="M6.824 14.677v-.107c.054-.054.054-.161.108-.215.591-.269.914.161 1.29.484.108.269.108.537.161.806-.215.484-.053.914.43 1.398.162.161.43.269.216.591-.323.162-.646 0-.968-.053-.645-.215-.807-.753-.914-1.29-.108-.592-.269-1.076-.323-1.614z"
      style={{
        fill: '#c56d51',
      }}
    />
    <path
      d="M8.06 14.892c-.322-.268-.752-.376-1.128-.59-.215-.27-.484-.539-.7-.807.485 0 .969.053 1.237-.484.215-.054.484-.162.7 0 .43.322.376.806.43 1.236-.108.27-.108.645-.538.645z"
      style={{
        fill: '#c86f50',
      }}
    />
    <path
      className="copperegg_svg__st89"
      d="M13.598 2.903c.108.054.162.108.27.215.322.538.59 1.022.806 1.613.107.269.161.484.053.753-.053.054-.107.054-.16.054-.27-.162-.323-.484-.377-.753-.161-.699-.538-1.29-1.075-1.828-.27-.269-.484-.591-.807-.86-.753-.592-1.29-.592-1.882.215-.16.107-.322.376-.591.215-.054-.054-.108-.161-.054-.269.484-1.075 1.13-1.344 2.043-1.021.7.483 1.183 1.129 1.774 1.666z"
    />
    <path
      d="M11.824 1.29c-1.021-.322-1.451.323-1.935 1.022-.162 0-.269-.108-.269-.215 0-.108.054-.162.107-.215.216-.323.323-.753.86-.7.43-.16.807-.214 1.237.108z"
      style={{
        fill: '#cc7d55',
      }}
    />
    <path
      d="M10.588 1.183c-.43.107-.484.591-.807.806-.269-.484.215-.645.484-.914.054 0 .215.054.323.108z"
      style={{
        fill: '#c9774d',
      }}
    />
    <path
      d="M8.383 2.42v-.323c.484.645.484.645 1.022.215.054-.054.107-.054.161 0 .108.322-.054.591-.161.86-.054.108-.161.215-.215.323-.108.16-.215.376-.43.376-.323-.054-.27-.323-.323-.538 0-.322-.054-.591-.054-.914z"
      style={{
        fill: '#d17340',
      }}
    />
    <path
      d="M9.566 2.312H9.46c.053-.215.053-.377.107-.592.108-.322.323-.591.7-.698-.216.268-.485.537-.485.913l-.107.108a.164.164 0 0 1 0 .215c-.054.108-.108.108-.108.054z"
      style={{
        fill: '#c87246',
      }}
    />
    <path
      className="copperegg_svg__st94"
      d="M8.383 2.42c.162.268.108.59.108.913.269 1.13.107 2.205-.054 3.28-.107.376-.054.86-.43 1.129h-.161c.107-1.237.215-2.473.376-3.71.054-.537.054-1.075.054-1.613h.107z"
    />
    <path
      d="M15.104 7.957c.215.484.107 1.022.322 1.452.215.537.108 1.129.054 1.666-.107 1.13-.591 2.15-.968 3.226-.107.161-.215.323-.483.269-.215-.323.107-.645.053-1.022.592-1.29.914-2.58.807-3.978V8.44c.054-.16 0-.322.215-.483z"
      style={{
        fill: '#c0724b',
      }}
    />
    <path
      d="M14.405 14.301c.215-.806.645-1.613.806-2.473a12.27 12.27 0 0 0 .215-2.42V7.958c.43 1.828.323 3.656-.43 5.43a7.036 7.036 0 0 0-.484 1.505h-.107c-.215-.215-.108-.376 0-.59z"
      style={{
        fill: '#bd6d47',
      }}
    />
    <path
      d="M15.104 7.957v1.72c-.108.108-.161.215-.323.054-.161-.484-.054-.968-.054-1.451s0-.914-.107-1.398c-.054-.27 0-.484 0-.753 0-.215 0-.43.054-.591V5.43c.053-.645-.27-1.183-.538-1.774-.107-.215-.161-.43-.269-.645.86 1.021 1.183 2.204 1.237 3.494v1.452z"
      style={{
        fill: '#c67658',
      }}
    />
    <path
      d="M6.932 17.15v-.107c.161-.161.376-.161.484 0 .268.323.645.43 1.021.484.592.161 1.183.107 1.774.107.108.054.215.108.215.27-.483.322-.967.214-1.451.214-.162 0-.323 0-.484-.107-.538-.108-1.13-.377-1.56-.86z"
      style={{
        fill: '#c0684b',
      }}
    />
    <path
      d="M4.889 8.226h.107c.108 0 .215.107.27.215 0 .054.053.161.053.215.215.43.322.43.538.054l.16-.323c.108-.269.162-.538.216-.806.269-.753.537-1.506.699-2.312.107-.592.215-1.13.215-1.72 0-.216-.054-.43.161-.538.054.107.161.215.215.322.054.592-.107 1.183-.107 1.828-.43 1.183-.592 2.42-1.13 3.549-.107.215-.214.43-.376.591-.161.161-.269.43-.537.323-.27-.054-.27-.323-.377-.538-.107-.269-.107-.591-.107-.86z"
      style={{
        fill: '#efb89a',
      }}
    />
    <path
      d="M7.04 9.892c-.27.323-.216.807-.592 1.13a.164.164 0 0 1 0-.216c.322-1.075.699-2.15.806-3.28h.108v.7c-.054.322-.054.591-.108.914-.107.215 0 .537-.215.752z"
      style={{
        fill: '#db8965',
      }}
    />
    <path
      className="copperegg_svg__st42"
      d="M6.502 10.806v.216c-.645.752-1.183.752-1.613 0 .645.483 1.129.215 1.613-.216z"
    />
    <path
      d="M7.254 3.01c0 .807.054 1.614-.215 2.366-.053.054-.107.054-.161 0-.054-.053-.108-.107-.108-.215V5l.162-.806c0-.27.054-.538-.269-.7-.161 0-.215.162-.215.27-.108.16-.215.322-.323.537-.268.43-.43.86-.537 1.344-.162.645-.054 1.29-.323 1.882-.054.054-.107.054-.161.054-.108-.108-.108-.162 0-.27-.054-1.397.107-2.687 1.021-3.816.162-.215.323-.377.538-.592.215-.161.43-.161.591.108z"
      style={{
        fill: '#eac7b2',
      }}
    />
    <path
      className="copperegg_svg__st102"
      d="M5.104 7.42v.214c.215.27.215.538 0 .807-.054-.054-.054-.161-.108-.215.054-.269-.053-.592.108-.807z"
    />
    <path
      d="M7.846 7.742h.107c.215.43.807.215 1.076.538.107.322 0 .645.107.967 0 .377.108.807-.376.968 0-.054-.054-.161-.054-.215-.323-.645-.591-.699-1.075 0 .053-.591.053-1.183.107-1.828 0-.107 0-.269.108-.43z"
      style={{
        fill: '#d1754a',
      }}
    />
    <path
      className="copperegg_svg__st104"
      d="M5.158 11.935c.699.377 1.505.054 1.72-.752a4.48 4.48 0 0 1 .215-.807c.108-.107 0-.268.108-.322h.107c0 .591-.161 1.129-.376 1.666-.43.753-1.183.86-1.774.215z"
    />
    <path
      className="copperegg_svg__st82"
      d="M7.147 10c-.054.108-.054.215-.108.323v-.484c.054-.27.054-.538.215-.807 0 .377.054.7-.107.968z"
    />
    <path
      d="M10.48 17.957c-.054-.054-.161-.054-.215-.108.054-.376.376-.43.592-.59.053 0 .107-.055.16-.055.323.054.538-.053.807-.269.484-.376 1.022-.645 1.452-1.021.107-.108.215-.108.376 0 .162.215 0 .323-.161.484-.269.269-.591.537-.914.806-.591.43-1.344.592-2.097.753z"
      style={{
        fill: '#bc6a51',
      }}
    />
    <path
      d="M12.577 17.15c.269-.483.86-.698 1.021-1.236-.107-.269.054-.43.215-.591.108 0 .216-.054.323 0-.161.86-.699 1.451-1.56 1.828z"
      style={{
        fill: '#b96a50',
      }}
    />
    <path
      d="M14.19 15.323c-.054.053-.161.053-.215.107-.162-.107-.215-.269-.269-.43 0-.108.054-.215.108-.323s.16-.16.268-.16c.108-.055.215-.162.323-.216v.591c0 .162-.108.323-.215.43z"
      style={{
        fill: '#bb6d4c',
      }}
    />
    <path className="copperegg_svg__st104" d="m4.781 11.613.323.322a.485.485 0 0 1-.323-.322z" />
    <path
      d="M8.76 10.215c.215-.323.107-.699.107-1.021.27 0 .27.215.27.376 0 .43.107.86.214 1.29-.054.592.161 1.237.054 1.828-.376.215-.484-.054-.591-.269-.054-.107-.054-.161-.054-.268v-.216c0-.322.107-.698 0-1.021v-.699z"
      style={{
        fill: '#ce7752',
      }}
    />
    <path
      d="m8.76 12.15.107.108c.323.484.215 1.075.323 1.613-.054.269-.054.538-.215.753-.323.16-.484 0-.538-.323-.107-.43-.215-.806-.215-1.236.161-.323.323-.592.538-.914z"
      style={{
        fill: '#c97251',
      }}
    />
    <path
      d="M8.383 17.58c-.591.054-.967-.376-1.451-.59-.054-.592-.162-1.184-.215-1.829.107-.161.107-.376.107-.591.377.645.592 1.344.753 2.043.054.322.215.538.538.699.053.215.322 0 .268.269z"
      style={{
        fill: '#c36a4d',
      }}
    />
    <path
      d="M9.082 17.473c-.484-.215-.86-.591-1.021-1.075-.108-.269-.108-.484.107-.7.377-.053.7.108 1.022.27.484.591 1.183.537 1.828.645.215 0 .43-.054.591.107.161.538-.376.484-.591.753-.484.108-.914.108-1.398.161-.215 0-.43.054-.538-.16z"
      style={{
        fill: '#c36e52',
      }}
    />
    <path
      d="M9.082 16.129c-.322-.108-.699-.108-.914-.484-.107-.269-.107-.537-.107-.806.107-.215.215-.377.322-.592l.484.323c.377 0 .43.376.592.591-.054.27.215.538.053.807-.107.269-.268.269-.43.161z"
      style={{
        fill: '#c67153',
      }}
    />
    <path
      d="M9.082 17.473c.645 0 1.29-.107 1.936 0l-.807.323c-.591 0-1.236.053-1.828-.215-.107-.054-.215-.162-.322-.215.322.053.699.107 1.021.107z"
      style={{
        fill: '#c26c50',
      }}
    />
    <path
      d="M10.319 2.204c.645-.86 1.183-.967 1.935-.322.86.752 1.72 1.613 2.043 2.795.323.914.27 1.936.27 2.904v.43c-.108.537-.108 1.075-.108 1.613 0 .215.053.484-.27.537-.322-.269-.16-.699-.268-1.021V7.097c0-.914.054-1.882-.323-2.742-.376-.807-.967-1.452-1.612-2.043-.162-.161-.43-.161-.646-.108-.322 0-.591.215-.86.323-.107 0-.161-.054-.161-.161-.054-.054 0-.108 0-.162z"
      style={{
        fill: '#d39371',
      }}
    />
    <path
      d="M9.19 10.914c-.323-.538-.108-1.129-.323-1.72V8.172c-.054-.054-.107-.107-.107-.161a.636.636 0 0 1-.054-.538c.323-1.129.269-2.258.484-3.387a5.47 5.47 0 0 1 .484-1.774v-.215c.107.054.215.107.215.215v.215c.161.161.107.322.054.538-.377 1.827-.484 3.655-.484 5.483-.054.753.107 1.56-.108 2.312 0 .054-.054.108-.161.054z"
      style={{
        fill: '#d47c52',
      }}
    />
    <path
      d="M14.405 7.957v-.323c.107-.537 0-1.021.054-1.559 0-.215-.054-.43.053-.591h.108c.107.215.054.376.054.591-.054.484.053.914.053 1.398v.484c-.107.699-.053 1.344-.053 2.043 0 .484 0 .914-.108 1.398v.161c-.107.43-.269.807-.43 1.237 0 .053-.054.107-.107.161-.377.806-1.13 1.021-1.882 1.29-.484.162-.914-.107-1.344-.376-.162-.161-.27-.323-.162-.591.323-.215.592.053.86.053.7.43 1.882-.161 2.151-.914.377-1.075.7-2.204.7-3.387 0-.322-.055-.699.053-1.075z"
      style={{
        fill: '#d18762',
      }}
    />
    <path
      d="M9.351 8.548c-.054-2.043.108-4.032.592-6.021.16-.108.322-.215.43-.323v.108c.053.215 0 .484-.054.645-.484 1.613-.484 3.28-.484 4.946-.108.377-.054.753-.161 1.13-.054.053-.108.053-.162.053-.268-.108-.053-.376-.16-.538z"
      style={{
        fill: '#d98a5e',
      }}
    />
    <path
      className="copperegg_svg__st118"
      d="M14.512 5.484c-.053.699.108 1.451-.107 2.15 0-.967.054-1.935-.108-2.957.162.27.215.538.215.807z"
    />
    <path
      d="M8.276 6.72c0-1.129.054-2.258.215-3.387.107.323.215.538.484.108.054-.108.215-.215.322-.323.162.484-.053.86-.107 1.29-.108.86-.161 1.721-.484 2.527-.323.162-.43.108-.43-.215z"
      style={{
        fill: '#d67742',
      }}
    />
    <path
      className="copperegg_svg__st94"
      d="M8.652 6.828c.215-1.237.484-2.473.7-3.71 0-.269.107-.537.214-.806h.108c-.323 1.72-.592 3.494-.7 5.215 0 .215 0 .376-.107.538-.376-.323-.43-.753-.215-1.237z"
    />
    <path
      d="M8.652 6.828c.215.376.269.806.215 1.237v.107h-.054c-.215.054-.322.484-.645.323-.268-.162-.215-.538-.215-.807.054-.376.162-.699.323-1.021.107.268.215.215.376.161z"
      style={{
        fill: '#d37647',
      }}
    />
    <path
      d="M14.889 9.785c.054-.054.161-.054.215-.108.107 1.398-.215 2.689-.807 3.979-.107.107-.107.269-.268.269-.054 0-.108-.054-.162-.108-.054-.107-.054-.215-.054-.322a8.624 8.624 0 0 0 .914-3.441c0-.162.054-.215.162-.269z"
      style={{
        fill: '#c87b52',
      }}
    />
    <path
      d="m14.082 13.871.215-.215c.054.322-.16.591-.215.914-.053.054-.053.161-.107.215-.108.054-.215.215-.377.107-.107-.483.162-.752.484-1.021z"
      style={{
        fill: '#c17550',
      }}
    />
    <path
      className="copperegg_svg__st123"
      d="M14.889 9.785c-.054 1.29-.323 2.527-.914 3.71-.215.699-.753 1.075-1.398 1.29-.43.107-.86.215-1.29 0-.108-.161-.27-.269-.215-.484.053-.107.107-.107.215-.161 1.182.161 2.096-.269 2.634-1.344v-.108c.269-.322.323-.753.484-1.129.269-.968.161-1.935.161-2.903 0-.215-.054-.484.054-.699v-.484c.161-.215-.054-.484.107-.699.216.7.054 1.398.108 2.097.054.376.054.645.054.914z"
    />
    <path
      className="copperegg_svg__st89"
      d="M14.727 6.828c0 .215.054.484-.107.699V6.075c.054-.053.054-.053.107 0v.753z"
    />
    <path className="copperegg_svg__st123" d="M14.727 6.022h-.107V5.43c.161.215.107.43.107.592z" />
    <path
      d="M6.932 5.376h.107c-.107.807-.376 1.56-.699 2.258a5.425 5.425 0 0 1-.376.807c-.107.215-.269.43-.591.322-.27-.107-.27-.322-.27-.537v-.377c0-.053.055-.107.108-.107.377.269.646.161.753-.323.269-.484.43-1.021.591-1.559.108-.215.162-.43.377-.484z"
      style={{
        fill: '#f7c5ad',
      }}
    />
    <path
      d="M5.265 7.742c.054.269-.161.699.215.806.377.108.484-.322.7-.591.053-.108.16-.215.214-.323 0 .27-.107.538-.215.807-.054.107-.107.161-.215.269-.376.591-.484.591-.806-.054V7.634h.107v.108z"
      style={{
        fill: '#f6c0a7',
      }}
    />
    <path
      className="copperegg_svg__st102"
      d="M5.158 8.656c.483.645.699-.054 1.021-.215-.054.107-.054.269-.107.376-.108.215-.215.538-.538.484-.376-.054-.376-.376-.376-.645z"
    />
    <path
      d="M6.287 3.656c.053-.161.215-.269.322-.323.269-.107.377.054.484.27.161.537-.054 1.02-.107 1.505-.162.537-.323 1.129-.484 1.666-.108.215-.215.484-.377.7-.107.16-.268.268-.484.214-.215-.054-.215-.215-.215-.43.054-.538 0-1.129.108-1.667.107-.483.215-.967.591-1.344.215.323.054.645-.053.914-.108.323-.108.645-.162.968 0 .108 0 .215.108.215s.161-.054.161-.107c.108-.538.376-1.022.484-1.613 0-.323-.108-.7-.376-.968z"
      style={{
        fill: '#f7dac9',
      }}
    />
    <path
      d="M6.287 3.656c.43 0 .591.161.591.591 0 .86-.323 1.613-.699 2.366-.054.107-.161.269-.269.215-.161 0-.161-.215-.215-.323-.161-.537.054-1.021.162-1.559.053-.269.16-.484.215-.752.053-.162.107-.323.215-.538z"
      style={{
        fill: '#f3e0d0',
      }}
    />
    <path
      className="copperegg_svg__st128"
      d="M5.48 5.591v1.452c0 .161-.054.43.215.484.215.054.323-.108.43-.323.27-.484.43-.967.592-1.451.053-.215.107-.43.215-.645v.215c-.215.752-.323 1.505-.807 2.15-.215.215-.376.753-.806.215H5.21v-.107c.054-.646.108-1.29.27-1.99z"
    />
    <path
      className="copperegg_svg__st128"
      d="M6.932 5.161c0-.269.054-.537.107-.806 0-.377.054-.807-.43-.968.377-.322.538-.054.538.269.054.484 0 1.021-.215 1.505z"
    />
    <path
      d="M11.018 17.473c.215-.215.376-.484.591-.699-.107-.161 0-.269.108-.376.645-.323 1.183-.807 1.72-1.237.108-.107.215-.269.43-.215.054.162.054.323.108.484l-.323.484c-.86.591-1.613 1.29-2.634 1.56z"
      style={{
        fill: '#be6f52',
      }}
    />
    <path
      d="M13.867 15c-.591.645-1.183 1.237-2.043 1.613-.376-.376.054-.591.215-.86.377-.323.807-.484 1.183-.753.108-.108.269-.161.43-.054.108-.054.215-.054.323-.107-.054 0-.108.053-.108.161z"
      style={{
        fill: '#c07252',
      }}
    />
    <path
      d="M9.19 10.914h.107c.377.323.27.806.377 1.237.053.215 0 .43.107.59.108.377.108.754.323 1.076.107.108.054.215 0 .377-.161.107-.323.053-.43 0-.108-.108-.27-.162-.377-.27-.268-.376-.322-.806 0-1.182-.053-.591-.215-1.237-.107-1.828z"
      style={{
        fill: '#cd7b57',
      }}
    />
    <path
      d="M9.351 12.742c0 .376 0 .806.215 1.129.108.215.215.43.108.645-.27.215-.43.108-.592-.107-.107-.162-.107-.323-.215-.484 0-.538.054-1.076 0-1.613.162.107.323.269.484.43z"
      style={{
        fill: '#cb7654',
      }}
    />
    <path
      d="M8.867 13.871c.215.215.377.484.7.699.483.107.698.43.806.86a.41.41 0 0 1-.108.269c-.484.323-.753-.054-1.021-.323-.108-.215-.215-.484-.323-.699-.054-.268-.054-.537-.054-.806z"
      style={{
        fill: '#c77454',
      }}
    />
    <path
      d="m12.147 15.806-.323.807-.215.215c-.914.161-1.828.054-2.473-.699.108-.054.215-.054.323-.108a.867.867 0 0 1 .484-.16c.59.107 1.129-.055 1.72-.216.161 0 .376-.054.484.161z"
      style={{
        fill: '#c27153',
      }}
    />
    <path
      d="M11.61 15.806c-.7.323-1.399.484-2.151.216-.054-.216-.162-.484-.215-.7.322.108.645.27 1.021.216.376-.108.699-.054 1.075 0 .108.053.215.107.27.268z"
      style={{
        fill: '#c47354',
      }}
    />
    <path
      d="M14.082 7.85v1.236c.162 1.075.162 2.097-.43 3.01s-1.774.969-2.527.162c-.914-.968-1.021-2.204-1.29-3.44V7.741c0-.269 0-.591-.054-.86-.054-1.398.054-2.742.43-4.033.108-.107.162-.322.377-.215.215.27.053.538 0 .807a8.956 8.956 0 0 0-.323 2.365c-.054 1.13.054 2.312.269 3.441 0 .054 0 .108.054.162.107.914.376 1.72 1.075 2.365.591.538 1.344.377 1.667-.322.107-.27.16-.592.268-.86.323-.592.162-1.237.215-1.882v-.592c0-.053.054-.107.108-.161.054-.108.108-.161.161-.108z"
      style={{
        fill: '#e09b74',
      }}
    />
    <path
      d="M13.975 7.85v.107c-.108.215-.054.376-.054.591 0 .108 0 .27-.215.162-.161-.215-.108-.484-.108-.753-.053-.806.054-1.613-.107-2.42.054-1.075-.538-1.881-1.13-2.688-.16-.215-.375-.376-.698-.322-.376.161-.645.484-1.075.43v-.323c-.054-.483.376-.591.645-.806.86-.054 1.29.645 1.72 1.129 1.076 1.129 1.183 2.473 1.076 3.925-.108.376.107.699-.054.967z"
      style={{
        fill: '#dda286',
      }}
    />
    <path
      d="M9.996 8.871c.162.591.323 1.237.484 1.828.161.699.592 1.236 1.022 1.72.591.538 1.666.215 2.096-.591.43-.86.538-1.774.484-2.742.215.323.108.699.108 1.022.215.698-.054 1.29-.269 1.881-.269.484-.591.86-1.075 1.076-.592.268-1.452 0-1.936-.538-.161-.215-.376-.43-.537-.645-.215-.484-.538-.914-.484-1.506 0-.322-.108-.645-.108-.967.054-.162 0-.377.215-.538z"
      style={{
        fill: '#dc956f',
      }}
    />
    <path
      className="copperegg_svg__st118"
      d="M13.867 12.043c.108-.645.377-1.237.323-1.935.215-.377.054-.807.107-1.237 0-.323-.053-.645.108-.914 0 1.021.054 2.097-.108 3.118-.16.807-.376 1.613-1.021 2.205-.484.43-1.183.537-1.828.268-.215-.215-.699-.215-.591-.699.16-.16.322 0 .483.054 1.183.592 1.613.323 2.366-.591.054-.108.107-.161.161-.269z"
    />
    <path
      d="M9.996 8.871c.054.538.054 1.075.108 1.613-.377-.054-.377-.376-.377-.645 0-.592-.107-1.183-.053-1.72V8.01c0-1.72-.054-3.387.43-5.054.054-.215.107-.43.269-.591 0 0 .053 0 .107.053.054.162-.054.323-.107.484-.43 1.075-.43 2.205-.43 3.334v.698c.16.27-.054.538.107.807-.054.376-.054.753-.054 1.129z"
      style={{
        fill: '#df9268',
      }}
    />
    <path
      className="copperegg_svg__st140"
      d="m10.319 2.903.161-.484c.215-.268.43-.537.807-.591-.162.269-.484.43-.592.806-.054.054-.054.054-.107 0l-.27.27z"
    />
    <path className="copperegg_svg__st141" d="M13.975 7.85c0-.323-.054-.592.107-.915v.914h-.107z" />
    <path
      d="M9.674 12.85c-.162-.646-.215-1.29-.323-1.936V8.548c.108.162.108.377.108.592.322.484.215 1.075.268 1.613.108 1.075.43 2.043 1.076 2.903.161.269.537.269.591.645h-.215c-.376.215-.591-.054-.806-.269-.377-.322-.592-.752-.7-1.183z"
      style={{
        fill: '#d0805d',
      }}
    />
    <path
      d="M10.803 13.763c-.753-.483-.914-1.29-1.13-2.096s-.268-1.72-.214-2.581h.107c.323.376.161.914.269 1.344.161.484.215 1.022.43 1.505.108.323.161.7.43.968.108.108.108.27.054.43.054.162.161.323.215.484-.107 0-.161 0-.161-.054z"
      style={{
        fill: '#d48460',
      }}
    />
    <path
      className="copperegg_svg__st89"
      d="M10.803 13.763h.107c1.183.484 1.99.323 2.796-.698.054-.108.161-.162.269-.216-.215.807-1.29 1.56-2.097 1.506h-.484c-.215-.215-.484-.377-.591-.592z"
    />
    <path
      d="M10.695 13.28c-.269-.377-.537-.753-.591-1.237-.054-.054-.054-.161-.054-.215.269-.108.323.107.484.269.161.161.269.376.43.591 0 .054 0 .108-.054.161.215.216.377.484.592.7-.323-.108-.538-.162-.807-.27z"
      style={{
        fill: '#d58964',
      }}
    />
    <path
      className="copperegg_svg__st89"
      d="M14.62 7.957c-.054 1.075.107 2.15-.108 3.226 0 .161-.107.269-.107.43.161-1.075.107-2.15.107-3.172 0-.161.108-.323.108-.484zM14.405 11.613c-.054.43-.054.86-.484 1.129.215-.376.323-.753.484-1.13z"
    />
    <path
      d="M10.104 11.828v.215c-.484-.914-.592-1.935-.592-2.957.054-.376-.107-.753.108-1.129.161.86.107 1.774.323 2.634.053.27.107.484.215.753.053.215.107.323-.054.484z"
      style={{
        fill: '#d88862',
      }}
    />
    <path
      d="M12.577 14.677c.538-.268.968-.645 1.344-1.129v.323c-.161.538-.484.968-.968 1.237-.537.215-1.075.376-1.613.322-.107-.054-.16-.161-.16-.322.053-.216.16-.216.375-.216.323 0 .7-.053 1.022-.215z"
      style={{
        fill: '#c77b57',
      }}
    />
    <path
      d="M12.953 14.892c.484-.215.7-.645 1.022-1.021h.107c-.053.376-.322.645-.484 1.021-.483.323-.967.592-1.451.914H11.233c-.054-.322.215-.322.376-.322.484-.108.914-.323 1.344-.592z"
      style={{
        fill: '#c37654',
      }}
    />
    <path
      d="M12.577 14.677c-.376.323-.914.27-1.344.484-.86.215-1.613-.269-1.452-1.021.108.054.215.054.323.107.322-.215.484.108.699.162.161.053.376.16.537.268.43.215.86 0 1.237 0z"
      style={{
        fill: '#ca7b58',
      }}
    />
    <path
      d="M11.34 14.677c-.483.054-.806-.268-1.236-.483-.108-.484-.484-.86-.484-1.345.43.592.699 1.29 1.452 1.452.107.108.215.215.268.376z"
      style={{
        fill: '#cd7e5b',
      }}
    />
    <path
      d="M5.373 7.742c.322.161.537-.108.806-.215-.054.107-.107.269-.161.376-.108.162-.215.323-.43.269-.27 0-.215-.269-.215-.43z"
      style={{
        fill: '#f9ccb6',
      }}
    />
    <path
      d="M5.803 5.806c.054-.483.161-.967.376-1.397.054-.108.161-.215.323-.162a.296.296 0 0 1 .161.27c-.054.483-.161 1.02-.43 1.45-.054.108-.161.27-.323.216-.161-.108-.107-.269-.107-.377z"
      style={{
        fill: '#f9ede3',
      }}
    />
    <path
      d="M9.781 14.086c.27.699.807.914 1.452 1.022.054.053.054.16.107.215-.376.268-.752.376-1.129 0-.322-.215-.484-.538-.699-.807v-.699c.108.108.162.215.27.269z"
      style={{
        fill: '#c87857',
      }}
    />
    <path
      d="M10.211 15.323c.377.16.753 0 1.13 0 .537-.108 1.075-.215 1.612-.484-.484.537-1.075.699-1.72.914-.323-.108-.7-.054-1.022-.215v-.215z"
      style={{
        fill: '#c67755',
      }}
    />
    <path
      className="copperegg_svg__st154"
      d="M13.706 10.699c-.215 1.129-.645 1.613-1.398 1.613-.591 0-.914-.377-1.183-.807-.376-.591-.699-1.29-.645-1.989.43 0 .43.376.645.592.323.483.484 1.129 1.13 1.397.59.108.806 0 1.075-.591 0-.054.053-.108.107-.108.054-.16.161-.215.269-.107z"
    />
    <path
      d="M10.91 10.215c-.322-.161-.161-.591-.484-.806-.16-1.022-.322-1.99-.268-3.011 0-.215.053-.43-.054-.592-.108-1.075.215-2.096.484-3.172h.107v.323c.215.161.108.376.054.591-.376 1.56-.269 3.119-.054 4.678.054.484.162 1.021.323 1.505.054.215.054.377-.108.484z"
      style={{
        fill: '#e6a47d',
      }}
    />
    <path
      className="copperegg_svg__st141"
      d="M13.706 10.699c-.054.054-.161.054-.215.107l-.054-.053c0-.7.054-1.344.323-1.936.054-.269.161-.537.215-.806-.054.86.161 1.828-.269 2.688z"
    />
    <path
      className="copperegg_svg__st154"
      d="M10.104 5.806c.054.108.107.162.107.27-.107 1.128.215 2.257.215 3.333-.322-1.13-.322-2.366-.322-3.603z"
    />
    <path
      className="copperegg_svg__st140"
      d="M9.996 7.742c-.161-.215-.107-.538-.107-.807.161.27.107.538.107.807z"
    />
    <path
      d="M13.76 8.763c.054.7-.108 1.345-.215 2.043v.108c-.484.806-.645.914-1.452.806-.591-.322-.806-.967-1.129-1.451-.376-1.56-.591-3.118-.591-4.678 0-.86.107-1.72.376-2.526.269-.27.43-.7.914-.7.108.162-.054.27-.161.377-.27.215-.377.484-.484.806-.054.162-.108.323-.108.484-.107.323-.107.645-.107.968v.161c-.108 1.237.107 2.42.376 3.602.108.592.323 1.13.699 1.56.269.268.484.483.914.322.376-.161.538-.43.538-.86 0-.484.107-1.022.16-1.505.162-.753.055-1.56.055-2.312 0-.215-.054-.484.161-.592.215.592.054 1.183.107 1.775-.053.537-.053 1.075-.053 1.612z"
      style={{
        fill: '#ebae89',
      }}
    />
    <path
      d="M13.598 5.376V7.85c0 .216 0 .43-.107.592-.108.054-.215 0-.323-.108-.107-.161-.053-.268-.053-.43 0-.376.107-.806.107-1.183.054-1.075-.107-2.096-.645-3.01a2.338 2.338 0 0 0-.323-.377c-.376-.376-.537-.376-.914 0-.107.054-.16.162-.322.215-.108 0-.161 0-.215-.107.107-.484.376-.86.752-1.13.27 0 .538.108.7.323.752.753 1.343 1.613 1.343 2.742z"
      style={{
        fill: '#e6b99e',
      }}
    />
    <path
      d="M10.91 12.85v-.108c-.269-.215-.484-.484-.322-.914.376.215.43.699.86.914.86.376 1.29.323 1.935-.269.162-.161.323-.269.484-.43-.43 1.183-1.344 1.56-2.42 1.022-.214-.054-.375-.162-.537-.216z"
      style={{
        fill: '#d8906a',
      }}
    />
    <path
      d="M10.588 11.828c.107.323.215.591.322.914-.484-.108-.484-.645-.806-.914a23.092 23.092 0 0 1-.484-3.871c.161.484.215.914.215 1.398 0 .376.054.753.215 1.075.054.538.323.968.538 1.398z"
      style={{
        fill: '#d98d68',
      }}
    />
    <path
      className="copperegg_svg__st141"
      d="M12.04 11.72c.698.162 1.075-.322 1.45-.806-.214.806-.644 1.075-1.45.806z"
    />
    <path
      className="copperegg_svg__st160"
      d="M13.276 8.333c.054.054.161.054.215.108.054.591-.108 1.129-.161 1.667-.054.43-.27.806-.753.914-.43.107-.753-.162-1.022-.484-.376-.538-.537-1.13-.698-1.775-.27-1.021-.27-2.096-.43-3.118 0-.215 0-.43.107-.591.269.322.215.752.269 1.183.107.967.215 1.881.591 2.795 0 .054.054.108.054.162.054.16.161.322.269.483a.996.996 0 0 0 .43.377c.43.215.591.107.753-.323.215-.484.053-1.021.376-1.398z"
    />
    <path
      className="copperegg_svg__st161"
      d="M11.34 9.194c-.483-.753-.537-1.667-.645-2.527-.107-.538-.107-1.13-.107-1.667.161-.376-.054-.753.107-1.129.054-.161.054-.323.108-.484h.215c.269.269.054.645.054.914-.215 1.022-.054 2.097.16 3.118.055.162.055.27.108.43 0 .27.108.538.162.807 0 .215.053.43-.162.538z"
    />
    <path
      className="copperegg_svg__st160"
      d="M10.695 3.871c-.054.376.108.753-.107 1.129 0-.323-.108-.753.107-1.129z"
    />
    <path
      d="M10.91 4.355c.054-.323.054-.592.108-.914.699-.86 1.129-.807 1.72.161.645 1.022.7 2.204.645 3.387 0 .323-.053.592-.053.914-.054.054-.108.054-.162.054-.107-.054-.215-.215-.268-.269 0-.054-.054-.107-.054-.161.107-.699.107-1.344.107-2.043 0-.645-.269-1.237-.645-1.774-.215-.323-.484-.323-.699 0-.161.215-.215.537-.43.699-.161 0-.215 0-.269-.054z"
      style={{
        fill: '#edc5ae',
      }}
    />
    <path
      d="M13.168 7.85h.108v.483c0 .484 0 .968-.161 1.452-.215.699-.7.806-1.183.323-.108-.108-.215-.27-.323-.377-.107-.107-.161-.322-.215-.484-.054-.43-.161-.806-.215-1.236.323-.054.323.269.376.43.162.322.215.699.43.968.162.215.377.268.592.268.269 0 .269-.268.323-.483.107-.43-.054-.968.268-1.345z"
      style={{
        fill: '#f6c4a2',
      }}
    />
    <path
      className="copperegg_svg__st161"
      d="M11.34 9.194a.681.681 0 0 1 .215.483c-.16-.107-.215-.268-.215-.483z"
    />
    <path
      d="M13.168 7.85c0 .483 0 .913-.107 1.344-.054.268-.161.59-.484.645-.323.053-.591-.108-.753-.377-.376-.43-.376-1.021-.699-1.505-.215-.968-.376-1.935-.322-2.903 0-.215 0-.484.107-.7h.108c.215.162.161.377.161.592 0 .753.054 1.452.215 2.15.054.646.269 1.184.592 1.721.107.215.268.377.537.323s.215-.323.269-.484c.108-.376 0-.807.269-1.183.054.161.054.269.107.376z"
      style={{
        fill: '#f7c8a9',
      }}
    />
    <path
      d="M11.233 7.312c-.215-.968-.269-1.99-.215-2.957 0-.054 0-.108.054-.161.215-.377.215-.914.752-.914.592 0 .753.537.914.914.484 1.075.323 2.204.162 3.333.053.054.053.161.053.269-.053.161-.053.322-.107.43-.215.322-.538.43-.86.215-.323-.215-.484-.592-.646-.968 0-.107-.053-.161-.107-.161z"
      style={{
        fill: '#f7d3bb',
      }}
    />
    <path
      d="M11.233 7.312h.107c.108.322.323.699.538.968.323.483.645.43.914-.108.054-.107.054-.269.161-.43v-.215h.108c0 .484 0 1.021-.161 1.505-.054.215-.108.43-.377.43-.215 0-.376-.107-.537-.268-.377-.592-.7-1.183-.753-1.882z"
      style={{
        fill: '#faceaf',
      }}
    />
    <path
      d="M12.846 6.129c.054.376-.108.86-.108 1.344 0 .215-.107.43-.322.484-.215.054-.43-.054-.538-.269-.484-1.129-.806-2.365-.323-3.602.108-.484.538-.484.807-.054.484.592.484 1.29.484 2.097z"
      style={{
        fill: '#fcdfc9',
      }}
    />
    <path
      d="M12.953 7.742c-.053.215-.053.43-.107.645-.054.215-.054.484-.377.484-.268 0-.483-.054-.591-.323-.161-.43-.484-.806-.538-1.29.108.108.162.215.27.376.483.86.806.86 1.343.108z"
      style={{
        fill: '#f9d3b7',
      }}
    />
    <path
      d="M12.577 5.591c0 .484 0 .86-.108 1.237-.107.269-.268.376-.483.107-.538-.752-.592-1.559-.43-2.419.053-.215.214-.43.483-.43.215 0 .323.215.43.43.162.376.108.753.108 1.075z"
      style={{
        fill: '#fae2d0',
      }}
    />
    <path
      d="M12.362 5.484v.054c-.054.268.161.752-.161.806-.377.054-.377-.43-.43-.753a1.187 1.187 0 0 1 0-.86c.053-.161.16-.322.322-.269.161 0 .269.162.269.323v.699z"
      style={{
        fill: '#fce9d9',
      }}
    />
  </svg>
);

export default SvgCopperegg;
