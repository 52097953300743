import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import { runbookValidation } from 'views/main/organization/workflow/constant/validation';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { RunbookAction } from 'views/main/organization/workflow/types';
import RunbookForm from './RunbookForm';

const RunbookContainer = () => {
  const { actionForm, onActionSubmit } = useWorkflowFormContext();

  return (
    <FormWrapper<RunbookAction>
      onSubmit={onActionSubmit}
      defaultValues={actionForm! as RunbookAction}
      validationSchema={runbookValidation}
    >
      <RunbookForm />
    </FormWrapper>
  );
};

export default RunbookContainer;
