import * as React from 'react';
import { SVGProps } from 'react';
const SvgSolarwindsUserDeviceTracker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="solarwinds-network-performance-manager_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.solarwinds-network-performance-manager_svg__st0{fill:#f99d1c}'}</style>
    <path
      className="solarwinds-network-performance-manager_svg__st0"
      d="M9.534 9.499c.07-.023.117-.023.163-.047 1.282-.373 2.517-.862 3.776-1.375 1.212-.513 2.401-1.119 3.473-1.981 1.072-.886 1.935-2.028 2.424-3.427.07-.186.21-.49.21-.699-1.678 2.308-8.368 2.541-8.368 2.541l1.585-1.445C6.457 3.089 2.028 5.769 0 7.214a12.086 12.086 0 0 1 6.783 3.03c.909-.279 1.842-.489 2.751-.745zM20 9.266S13.846 8.8 7.436 10.851a12.967 12.967 0 0 1 2.121 3.007C12.914 12.016 17.599 9.615 20 9.266zM9.977 14.837c.396 1.002.653 2.075.769 3.193l5.874-6.037-6.643 2.844z"
    />
  </svg>
);
export default SvgSolarwindsUserDeviceTracker;
