import { Box, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { IAppState } from 'core/interfaces/IAppState';
import { useFormik } from 'formik';
import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import Select, { MultiValue } from 'react-select';
import { stringShortener } from 'core/helpers/stringUtils';

interface ISelectTeamSlider {
  cloudName: string;
  selectTeam: (team: string[]) => void;
  selectedTeams: string[];
  formikError: string | string[] | undefined;
  isAllTeamsConfigured: boolean;
  selectAllTeam: () => void;
}

function SelectTeamsSlider(props: ISelectTeamSlider) {
  //ref

  const [isAllValueSelected, setIsAllValueSelected] = React.useState<boolean>(false);

  const allOrgTeams = useSelector((state: IAppState) => state.organization.teams);

  const teamsOptions = allOrgTeams.t?.map(team => {
    return {
      label: team.name,
      value: team.id,
    };
  });

  const handleOptionSelected = (
    option: MultiValue<{
      label: string;
      value: string;
    }>,
  ) => {
    props.selectTeam(option.map(o => o.value));
  };

  const handleSelectAllTeams = (selectedValue: string) => {
    if (selectedValue === 'All') {
      props.selectAllTeam();
    } else {
      props.selectTeam([]);
    }
  };

  const handleDeselectAllTeams = () => {
    props.selectTeam([]);
  };

  const defaultSelectedOptions = useMemo(() => {
    return (
      props?.selectedTeams?.map(team => {
        return {
          label: allOrgTeams.t?.find(t => t.id === team)?.name || ' ',
          value: team,
        };
      }) || []
    );
  }, [props.selectedTeams, allOrgTeams.t]);

  React.useEffect(() => {
    const ifAllTeamSelected = defaultSelectedOptions?.length === allOrgTeams.t?.length;

    if (ifAllTeamSelected) {
      setIsAllValueSelected(true);
    }
  }, []);
  return (
    <Box>
      <Text
        fontSize={'14px'}
        fontWeight={400}
        fontStyle="normal"
        width="600px"
        marginY="20px"
        color="PoliceBlue"
      >
        Select team(s) that will have access to this account. Jira tickets can be created for
        incidents of each service that have been created by selected teams
      </Text>
      {props.cloudName && (
        <Box
          border={'1px Gainsboro'}
          width="fit-content"
          padding={'7px 13px'}
          background={'Gainsboro'}
          borderRadius="4px"
          marginBottom={'20px'}
        >
          <Text fontSize={16} fontWeight={500} fontStyle="normal">
            {stringShortener(props.cloudName, 70)}
          </Text>
        </Box>
      )}
      <Stack mt={6}>
        <Stack spacing={0}>
          <RadioGroup
            onChange={nextVallue => handleSelectAllTeams(nextVallue)}
            value={props.isAllTeamsConfigured ? 'All' : 'specific'}
          >
            <Radio value="All">
              <Text fontSize={'sm'} fontWeight={400}>
                All Teams{' '}
              </Text>
            </Radio>
            <Text fontSize={'sm'} paddingLeft="24px" color={'Gray'}>
              All teams created within the organisation will have access to this account
            </Text>
            <Radio value="specific">
              <Text fontSize={'sm'} fontWeight={400}>
                Specific teams
              </Text>
            </Radio>
          </RadioGroup>
        </Stack>
        {!props.isAllTeamsConfigured ? (
          <Stack mt={'9px'} pl={'24px'}>
            <Box width="350px">
              <Text fontSize={'sm'} fontWeight={'500'} paddingBottom={'7px'}>
                Select teams
              </Text>
              <Select
                value={defaultSelectedOptions}
                options={teamsOptions}
                name="teamsIds"
                isDisabled={isAllValueSelected}
                isMulti
                isSearchable
                placeholder="Select Team"
                onChange={newValue => {
                  handleOptionSelected(newValue);
                }}
              />
            </Box>
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
}

export default SelectTeamsSlider;
