import { ISquad } from 'core/interfaces/ISquad';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { generatePath } from 'react-router';
import {
  ESCALATION_POLICIES_LIST_PATH,
  GER_DETAILS_PATH,
  LEGACY_SCHEDULES_PATH,
  SCHEDULES_V2_DETAIL_PATH,
  SERVICE_DETAILS_PATH,
  STATUSPAGE_V2_DETAILS,
  WORKFLOWS_DETAILS_PATH,
} from 'views/main/routes/routes';
import { EntitiesForType, EntityIdentifiers, EntityTableData } from '../types';

export function convertToEntityTableData(
  entities: EntitiesForType,
  type: keyof typeof EntityIdentifiers,
  users: IUsersOfOrganization[],
  squad: Array<{
    name: string;
    id: string;
  }>,
): EntityTableData {
  const { data } = entities;

  const entityData = data[type];

  if (!entityData) {
    return {
      pagination: {
        total_count: data.count,
      },
      data: [],
    };
  }

  return {
    pagination: {
      total_count: data.count,
    },
    data: entityData.map(entity => {
      const entityOwnerId = entity.entity_owner.id;
      const entityOwnerType = entity.entity_owner.type;

      if (entityOwnerType === 'squad') {
        const squadName = squad.find(s => s.id === entityOwnerId)?.name;
        return {
          ...entity,
          owner_meta: {
            name: squadName ?? 'N/A',
          },
        };
      } else if (entityOwnerType === 'user') {
        const user = users.find(u => u.id === entityOwnerId);
        return {
          ...entity,
          owner_meta: {
            name: `${user?.first_name} ${user?.last_name}`,
            userNameForDisplay: user?.username_for_display,
          },
        };
      }
      return {
        ...entity,
        owner_meta: {
          name: '',
        },
      };
    }),
  };
}

export const generateEntityDetailPageUrl = (
  entityType: keyof typeof EntityIdentifiers,
  entityId: string,
) => {
  switch (entityType) {
    case 'escalationPolicies':
      return generatePath(ESCALATION_POLICIES_LIST_PATH, { id: entityId });
    case 'gers':
      return generatePath(GER_DETAILS_PATH, { id: entityId });
    case 'runbooks':
      return generatePath(`/runbooks/update/${entityId}/Visual`);
    case 'schedulesV1':
      return generatePath(`${LEGACY_SCHEDULES_PATH}/:id`, { id: entityId });
    case 'schedulesV2':
      return generatePath(SCHEDULES_V2_DETAIL_PATH, { scheduleId: entityId });
    case 'services':
      return generatePath(SERVICE_DETAILS_PATH, { id: entityId });
    case 'slos':
      return `/slo/${entityId}`;
    case 'statusPagesV1':
      return `/statuspage/${entityId}`;
    case 'statusPagesV2':
      return generatePath(STATUSPAGE_V2_DETAILS, { id: entityId });
    case 'workflows':
      return generatePath(WORKFLOWS_DETAILS_PATH, { id: entityId });
    case 'webforms':
      return generatePath('/webforms');
    default:
      return undefined;
  }
};
