import {
  CellBlock,
  DropDown,
  DropDownOptionShell,
  Grid,
  IconButton,
  Para,
  Theme,
} from 'uie/components';
import { T_WA_MEMBER_UPDATED_TO_OWNER } from 'core/const/tracker';
import {
  AlertTriangleIcon,
  MoreVerticalIcon,
  NotificationGreyEmail,
  ProfileIcon,
  UsernameIcon,
} from 'icons';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { AppTracker } from 'shared/analytics/tracker';
import { useSettingPageACL } from '../../../acl';
import { useUpdateRole } from './hook';
import { useOBACMemberStore } from './store';
import { MemberRowProps, Role, RoleConversionText, RoleText, UpdateRole } from './types';

const { theme } = Theme;

export const MemberRow = ({
  user,
  teamId,
  members,
}: {
  user: MemberRowProps;
  teamId: string;
  members: MemberRowProps[];
}) => {
  const openActionsDropdown = useOBACMemberStore(store => store.isActionDrawerOpened);
  const openActionsDrawer = useOBACMemberStore(store => store.openActionsDrawer);
  const closeActionsDrawer = useOBACMemberStore(store => store.closeActionsDrawer);

  const openConfirmDeleteMemberModal = useOBACMemberStore(store => store.openConfirmDeleteModal);

  const openUpdateRoleConflictModal = useOBACMemberStore(
    store => store.openUpdateRoleConflictModal,
  );

  const openConfirmConvertToMembersModal = useOBACMemberStore(
    store => store.openConfirmConvertToMembersModal,
  );

  const { mutate: updateRole } = useUpdateRole({
    onSuccess: () => {
      closeActionsDrawer();
      AppTracker.track(T_WA_MEMBER_UPDATED_TO_OWNER, {
        'Team ID': teamId,
      });
    },
    onError: (e: any) => {
      if (e.response?.status === 409) {
        openUpdateRoleConflictModal();
      }
    },
  });

  const conversionText = RoleConversionText[user.role];
  const role = RoleText[user.role];

  const hasUpdateAccess = useSettingPageACL(store => store.hasUpdateAccess);

  const canUpdate = hasUpdateAccess('teams', teamId);

  return (
    <>
      <Grid
        flexWidth={12}
        alignItems="flex-start"
        style={{
          marginTop: '8px',
        }}
        className="mb-5"
      >
        <Grid flexWidth={5}>
          <div>
            <Para fontSize={16} style={{ maxWidth: '30ch' }}>
              {user.name ?? 'Deleted'}
            </Para>
            <Grid alignItems="flex-start">
              <NotificationGreyEmail
                height={12}
                width={12}
                style={{ marginRight: 5, marginTop: 4 }}
              />
              <Para fontSize={12} color={theme.font.label} style={{ maxWidth: '30ch' }}>
                {user?.email ?? 'Deleted'}
              </Para>
              {!user?.email_verified && (
                <AlertTriangleIcon
                  height={10}
                  width={10}
                  className="ml-5"
                  stroke={theme.danger.default}
                />
              )}
            </Grid>
            {user?.username_for_display && (
              <Grid alignItems="flex-start">
                <UsernameIcon height={12} width={12} style={{ marginRight: 5, marginTop: 4 }} />
                <Para fontSize={12} color={theme.font.label} style={{ maxWidth: '30ch' }}>
                  {user?.username_for_display ?? ''}
                </Para>
              </Grid>
            )}
            <Grid alignItems="flex-start">
              <ProfileIcon
                height={12}
                width={12}
                stroke={theme.shades.grey}
                style={{ marginRight: 5, marginTop: 4 }}
              />
              <Para fontSize={12} color={theme.font.label}>
                {user.role === 'stakeholder' ? 'stakeholder' : 'user'}
              </Para>
            </Grid>
          </div>
        </Grid>
        <Grid flexWidth={6}>
          <Grid alignItems="center" flexWrap="wrap">
            <CellBlock className="mr-10 mt-10">
              <Para fontSize={14}>{role}</Para>
            </CellBlock>
          </Grid>
        </Grid>
        <Grid flexWidth={1} justifyContent="flex-end">
          <DropDown
            hook={
              <IconButton onClick={() => openActionsDrawer()}>
                <MoreVerticalIcon height="14px" width="14px" stroke={theme.shades.cement} />
              </IconButton>
            }
            height="auto"
            width="212px"
            maxWidth="212px"
            style={{ left: '-212px' }}
          >
            {openActionsDropdown && (
              <div>
                {user.role !== Role.Stakeholder && (
                  <NoPermissionTooltip isDisabled={canUpdate}>
                    <DropDownOptionShell
                      disabled={!canUpdate}
                      padding="8px"
                      onClick={() => {
                        if (user.role === Role.Owner) {
                          openConfirmConvertToMembersModal(
                            user.user_id,
                            user.name,
                            UpdateRole[user.role],
                          );
                        } else {
                          updateRole({
                            team_id: teamId,
                            user_id: user.user_id,
                            role: UpdateRole[user.role],
                          });
                        }
                      }}
                    >
                      <Para>{conversionText}</Para>
                    </DropDownOptionShell>
                  </NoPermissionTooltip>
                )}
                <NoPermissionTooltip isDisabled={canUpdate}>
                  <DropDownOptionShell
                    disabled={!canUpdate}
                    padding="8px"
                    onClick={() => {
                      openConfirmDeleteMemberModal(user.user_id, user.name, user.role);
                    }}
                  >
                    <Para color={theme.danger.default}>Remove</Para>
                  </DropDownOptionShell>
                </NoPermissionTooltip>
              </div>
            )}
          </DropDown>
        </Grid>
      </Grid>
    </>
  );
};
