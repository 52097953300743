import { Autocomplete, FocusBlock, Para, SelectBox } from 'uie/components';
import { IAppState } from 'core/interfaces/IAppState';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getAssignableTeamMembers } from 'shared/reducers';
import { Member, SquadMemberRole } from '../../types';
import { SquadMemberContainer, SquadMemberEmail, SquadMemberRoleSelector } from './styles';

type SquadMemberDropDownProps = {
  members: Member[];
  onSelect: (newMember: IUsersOfOrganization) => void;
  newMembersList: Array<{
    user_id: string | null;
    role: SquadMemberRole;
    tempId: string;
  }>;
};

export const SquadMemberDropDown: React.FC<SquadMemberDropDownProps> = ({
  members,
  onSelect,
  newMembersList,
}) => {
  const [selectedMember, setSelectedMember] = React.useState<IUsersOfOrganization | undefined>();
  const teams = useSelector((state: IAppState) => state.organization.teams?.t);

  const currentTeamId = useParams<{ id: string }>();

  const selectedTeam = teams.find(t => t.id === currentTeamId.id);
  const users = useSelector((state: IAppState) => state.organization.users.u);
  const [searchMembers, setSearchMembers] = React.useState('');
  const filteredUsers = getAssignableTeamMembers(selectedTeam!, users).filter(
    m =>
      m.role !== 'stakeholder' &&
      members?.findIndex(sq_member => sq_member.user_id === m.id) === -1 &&
      newMembersList.findIndex(nm => nm.user_id === m.id) === -1 &&
      `${m?.first_name ?? 'Deleted'} ${m?.last_name ?? ''}`
        .toLowerCase()
        .includes(searchMembers.toLowerCase()),
  );

  const _oUsers = users.reduce((acc, u) => {
    acc[u.id] = u;
    // name
    return acc;
  }, {} as Record<string, IUsersOfOrganization>);

  React.useEffect(() => {
    if (selectedMember) {
      onSelect(selectedMember);
    }
  }, [selectedMember]);

  return (
    <SquadMemberContainer>
      <div>
        <Autocomplete
          onValueChange={(e, value: string) => {
            const selectedUser = _oUsers[value];
            setSelectedMember(selectedUser);
          }}
          height="auto"
          maxHeight="200px"
          width="250px"
          maxWidth="250px"
          value={
            selectedMember
              ? `${selectedMember?.first_name ?? 'Deleted'} ${selectedMember?.last_name ?? ''}`
              : searchMembers
          }
          onChange={e => {
            setSelectedMember(undefined);
            setSearchMembers(e.target.value);
          }}
          placeHolder="Select member"
          showDropDownOnSelect={true}
          searchPropsHook={{
            style: {
              height: '38px',
              fontSize: '14px',
              border: '1px solid #B6BCC8',
              boxShadow: 'none',
              borderRadius: '4px',
            },
          }}
        >
          {filteredUsers.map((m, i) => (
            <FocusBlock className="global--text-clip" key={i} value={m.id}>
              <Para fontSize={14}>{`${m?.first_name ?? 'Deleted'} ${m?.last_name ?? ''}`}</Para>
            </FocusBlock>
          ))}
          {filteredUsers.length === 0 ? (
            <FocusBlock key={1} value={-1} disabled={true} isSelected={false}>
              <Para fontSize={14}>None found!</Para>
            </FocusBlock>
          ) : (
            <></>
          )}
        </Autocomplete>
      </div>
      {selectedMember && (
        <div
          style={{
            marginTop: '4px',
          }}
        >
          <SquadMemberEmail>{selectedMember?.email}</SquadMemberEmail>
          <SquadMemberRoleSelector>user</SquadMemberRoleSelector>
        </div>
      )}
    </SquadMemberContainer>
  );
};
