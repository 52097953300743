import { THEME_COLORS } from 'library/theme/colors';
import { QueryClient } from 'react-query';

import { invalidateServiceDependencies } from '../hooks/useGetServiceDependencies';
import { invalidateServiceGraphData } from '../hooks/useGetServiceGraphData';
import { GRAPH_EDGE_LIST_TYPE, SERVICE_HEALTH_STATUS } from '../Interfaces/graph';

export const getHealthStatus = (status?: SERVICE_HEALTH_STATUS) => {
  switch (status) {
    case 'healthy':
      return 'Healthy';
    case 'on_maintenance':
      return 'Under Maintenance';
    case 'unhealthy':
      return 'Needs Attention';
    default:
      return 'Awaiting Information';
  }
};

export const getHealthStatusColor = (status?: SERVICE_HEALTH_STATUS) => {
  switch (status) {
    case 'healthy':
      return THEME_COLORS.brand.darkGreen;
    case 'on_maintenance':
      return THEME_COLORS.severity[3];
    case 'unhealthy':
      return THEME_COLORS.severity[2];
    default:
      return THEME_COLORS.brand.blue;
  }
};

export const getHealthStatusBgColor = (status?: SERVICE_HEALTH_STATUS) => {
  switch (status) {
    case 'healthy':
      return THEME_COLORS.healthStatus.healthy;
    case 'on_maintenance':
      return THEME_COLORS.healthStatus.on_maintenance;
    case 'unhealthy':
      return THEME_COLORS.healthStatus.unhealthy;
    default:
      return THEME_COLORS.brand.white;
  }
};

export const invalGraphData = (queryClient: QueryClient) => {
  invalidateServiceGraphData(queryClient);
  invalidateServiceDependencies(queryClient);
};

export const findClusters = (edges: GRAPH_EDGE_LIST_TYPE) => {
  const clusters: Array<Array<string>> = [];
  edges.forEach(edge => {
    let sourceFound = false;
    let targetFound = false;
    clusters.forEach(cluster => {
      if (cluster.includes(edge.source)) {
        sourceFound = true;
        if (!cluster.includes(edge.target)) {
          cluster.push(edge.target);
        }
      } else if (cluster.includes(edge.target)) {
        targetFound = true;
        if (!cluster.includes(edge.source)) {
          cluster.push(edge.source);
        }
      }
    });
    if (!sourceFound && !targetFound) {
      clusters.push([edge.source, edge.target]);
    }
  });
  return clusters;
};
