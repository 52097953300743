import { useState, useLayoutEffect } from 'react';

const useMinMax = ({
  visibleItems,
  pageSize,
  pageIndex,
}: {
  visibleItems: number;
  pageSize: number;
  pageIndex: number;
}) => {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  useLayoutEffect(() => {
    const m = pageIndex * pageSize + 1;
    const mx = m + Math.min(pageSize, visibleItems) - 1;
    setMin(m);
    setMax(mx);
  }, [visibleItems, pageSize, pageIndex]);

  return [min, max];
};

export default useMinMax;
