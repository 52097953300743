import * as Yup from 'yup';
import { errorMessages } from '../constants/errormessage';

export const participantGroupsValidationSchema = Yup.array()
  .required()
  .test({
    test: arr =>
      arr?.length ? (arr.length < 2 ? arr.every(pg => !!pg.participants?.length) : true) : false,
    message: errorMessages.rotations.participantGroup,
  });
