import { useOrganizationConfig } from '..';
import { FILTER_TYPES, activeFilterKeys } from '../constants/schedulers.filter';
import { IfilterObject, IfilterOption } from '../interface/schedule';
import { SchedulesFilters } from 'views/main/organization/schedules/graphql/types';

const useGetAllFilterParticipants = () => {
  const { organization } = useOrganizationConfig();
  return [
    ...(organization?.users.u
      ? organization?.users?.u
          .filter(user => {
            return organization?.selectedTeam?.team?.members?.find(member => {
              //return member.user_id === user.id && user.role === 'user';
              return member.user_id === user.id;
            });
          })
          .sort((comparingElement: any, compareWithElement: any) =>
            comparingElement.first_name.localeCompare(compareWithElement.first_name),
          )
          .map(({ first_name, last_name, id, time_zone }: any, index: number) => {
            return {
              value: id,
              label: `${first_name} ${last_name}`,
              type: 'user',
              time_zone: time_zone,
              id: id,
            };
          })
      : []),
  ];
};

const useGetAllEscalationPolicies = () => {
  const { organization } = useOrganizationConfig();
  return [
    ...(organization?.escalations.e
      ? organization?.escalations?.e
          .sort((comparingElement: any, compareWithElement: any) =>
            comparingElement.name.localeCompare(compareWithElement.name),
          )
          .map(({ name, id }: any, index: number) => {
            return {
              value: id,
              label: `${name}`,
              id: id,
            };
          })
      : []),
  ];
};

const valueSeparator = ',';
const paramSeparator = '&';
const keyValueSeparator = ':';

const encodeFilterParam = (filters: IfilterObject) => {
  const filterParams: string[] = [];
  Object.values(FILTER_TYPES).forEach(key => {
    const filterValue: Array<IfilterOption> | boolean | undefined | null = filters[key];
    if (filterValue instanceof Array) {
      //for array values
      const mappedFilters = filterValue.map((filter: any) => filter.label);
      const values = Array(mappedFilters).join(valueSeparator);
      if (values.length) {
        filterParams.push(`${key}${keyValueSeparator}${values}`);
      }
    } else {
      //for boolean values
      const value = filterValue;
      filterValue && filterParams.push(`${key}${keyValueSeparator}${value}`);
    }
  });
  return encodeURI(filterParams.join(paramSeparator));
};

const decodeFilterParam = (params: string) => {
  const filterParams: any = {};
  const decodedString = decodeURI(params).split(paramSeparator);
  Object.values(FILTER_TYPES).forEach(key => {
    const paramValue: string[] = decodedString.filter(p => p.startsWith(key));
    if (paramValue.length) {
      const values: string = paramValue[0].split(keyValueSeparator)[1];
      filterParams[key] = values.split(valueSeparator);
    }
  });
  return filterParams;
};

const mapFilterParamsToFilterOptions = (
  queryParamsArray: Array<any> | undefined,
  arrayWithLabels: Array<any>,
) => {
  if (queryParamsArray && queryParamsArray.length > 0) {
    const options = queryParamsArray.map(queryParam =>
      arrayWithLabels.find(option => queryParam === option.label),
    );
    return options;
  }
  return [];
};

const mapFilterParamStringToSetFilterParam = (
  params: string,
  allFilterParticipants: Array<IfilterOption>,
  allFilterEscalationPolicies: Array<IfilterOption>,
  allScheduleOptions: Array<IfilterOption>,
) => {
  let filterObjectFromQueryParams = decodeFilterParam(params ?? '');

  if (!Object.keys(filterObjectFromQueryParams).length) return;
  Object.keys(filterObjectFromQueryParams)?.forEach(paramKey => {
    const paramValue = filterObjectFromQueryParams[paramKey];
    switch (paramKey) {
      case FILTER_TYPES.MY_ON_CALL: {
        filterObjectFromQueryParams = {
          ...filterObjectFromQueryParams,
          [FILTER_TYPES.MY_ON_CALL]: paramValue[0] === 'true',
        };
        break;
      }
      case FILTER_TYPES.NO_ESCALATION_POLICY: {
        filterObjectFromQueryParams = {
          ...filterObjectFromQueryParams,
          [FILTER_TYPES.NO_ESCALATION_POLICY]: paramValue[0] === 'true',
        };
        break;
      }
      case FILTER_TYPES.PARTICIPANT: {
        filterObjectFromQueryParams = {
          ...filterObjectFromQueryParams,
          [FILTER_TYPES.PARTICIPANT]: mapFilterParamsToFilterOptions(
            paramValue,
            allFilterParticipants,
          ),
        };
        break;
      }
      case FILTER_TYPES.ESCALATION_POLICY: {
        filterObjectFromQueryParams = {
          ...filterObjectFromQueryParams,
          [FILTER_TYPES.ESCALATION_POLICY]: mapFilterParamsToFilterOptions(
            paramValue,
            allFilterEscalationPolicies,
          ),
        };
        break;
      }
      case FILTER_TYPES.SCHEDULE: {
        filterObjectFromQueryParams = {
          ...filterObjectFromQueryParams,
          [FILTER_TYPES.SCHEDULE]: mapFilterParamsToFilterOptions(paramValue, allScheduleOptions),
        };
        break;
      }
    }
  });
  return filterObjectFromQueryParams;
};

const mapValuesArrayToOptions = (
  valuesArray: Array<any> | undefined,
  arrayWithLabels: Array<any>,
  returnType: string,
) => {
  if (valuesArray && valuesArray.length > 0) {
    const options = valuesArray.map(filterValue =>
      arrayWithLabels.find(option => filterValue === option.value),
    );
    switch (returnType) {
      case 'labels': {
        return options.map(option => option.label);
      }
      case 'values': {
        return options.map(option => option.value);
      }
      default: {
        return options;
      }
    }
  }
  return [];
};

const mapActiveFiltersToFilterObject = (
  activeFilters: SchedulesFilters,
  allFilterParticipants: Array<IfilterOption>,
  allFilterEscalationPolicies: Array<IfilterOption>,
  allScheduleOptions: Array<IfilterOption>,
) => {
  return {
    [FILTER_TYPES.MY_ON_CALL]: activeFilters.myOncall,
    [FILTER_TYPES.NO_ESCALATION_POLICY]: activeFilters.noEscalationPolicies,
    [FILTER_TYPES.PARTICIPANT]: [
      ...(mapValuesArrayToOptions(
        activeFilters.participants ?? [],
        allFilterParticipants,
        'options',
      ) ?? []),
    ],
    [FILTER_TYPES.ESCALATION_POLICY]: [
      ...(mapValuesArrayToOptions(
        activeFilters.escalationPolicies ?? [],
        allFilterEscalationPolicies,
        'options',
      ) ?? []),
    ],
    [FILTER_TYPES.SCHEDULE]: [
      ...(mapValuesArrayToOptions(activeFilters.scheduleIDs ?? [], allScheduleOptions, 'options') ??
        []),
    ],
  };
};

const getModifiedFilterParamObject = (
  filter: any,
  activeFilters: SchedulesFilters,
  allFilterParticipants: Array<IfilterOption>,
  allFilterEscalationPolicies: Array<IfilterOption>,
  allScheduleOptions: Array<IfilterOption>,
) => {
  const { filterKey, filterValue } = filter;
  const updatedFiltersObject: IfilterObject = {
    ...mapActiveFiltersToFilterObject(
      activeFilters,
      allFilterParticipants,
      allFilterEscalationPolicies,
      allScheduleOptions,
    ),
  };
  switch (filterKey) {
    case activeFilterKeys.PARTICIPANT: {
      updatedFiltersObject[FILTER_TYPES.PARTICIPANT] = updatedFiltersObject[
        FILTER_TYPES.PARTICIPANT
      ].filter(option => option.value !== filterValue.value);
      break;
    }
    case activeFilterKeys.ESCALATION_POLICY: {
      updatedFiltersObject[FILTER_TYPES.ESCALATION_POLICY] = updatedFiltersObject[
        FILTER_TYPES.ESCALATION_POLICY
      ].filter(option => option.value !== filterValue.value);
      break;
    }
    case activeFilterKeys.SCHEDULE: {
      updatedFiltersObject[FILTER_TYPES.SCHEDULE] = updatedFiltersObject[
        FILTER_TYPES.SCHEDULE
      ].filter(option => option.value !== filterValue.value);
      break;
    }
    case activeFilterKeys.MY_ON_CALL: {
      updatedFiltersObject[FILTER_TYPES.MY_ON_CALL] = false;
      break;
    }
    case activeFilterKeys.NO_ESCALATION_POLICY: {
      updatedFiltersObject[FILTER_TYPES.NO_ESCALATION_POLICY] = false;
      break;
    }
  }
  return updatedFiltersObject;
};

const getActiveFilterObjectForTags = (
  scheduleFilters: SchedulesFilters,
  allFilterParticipants: Array<IfilterOption>,
  allFilterEscalationPolicies: Array<IfilterOption>,
  allScheduleOptions: Array<IfilterOption>,
) => {
  let result: Array<any> = [];
  const activeFilters: Array<Record<string, any>> = [];
  if (scheduleFilters.myOncall) {
    result = [
      ...result,
      {
        [activeFilterKeys.MY_ON_CALL]: [{ label: 'My On-Call', value: 'moc' }],
      },
    ];
  } else {
    if (scheduleFilters.participants && scheduleFilters.participants.length > 0) {
      result = [
        ...result,
        {
          [activeFilterKeys.PARTICIPANT]: [
            ...(mapValuesArrayToOptions(
              scheduleFilters.participants,
              allFilterParticipants,
              'options',
            ) ?? []),
          ],
        },
      ];
    }
  }
  if (scheduleFilters.noEscalationPolicies) {
    result = [
      ...result,
      {
        [activeFilterKeys.NO_ESCALATION_POLICY]: [
          { label: 'No Escalation Policies', value: 'nep' },
        ],
      },
    ];
  } else {
    if (scheduleFilters.escalationPolicies && scheduleFilters.escalationPolicies.length > 0) {
      result = [
        ...result,
        {
          [activeFilterKeys.ESCALATION_POLICY]: [
            ...(mapValuesArrayToOptions(
              scheduleFilters.escalationPolicies,
              allFilterEscalationPolicies,
              'options',
            ) ?? []),
          ],
        },
      ];
    }
  }
  if (scheduleFilters.scheduleIDs && scheduleFilters.scheduleIDs.length > 0) {
    result = [
      ...result,
      {
        [activeFilterKeys.SCHEDULE]: [
          ...(mapValuesArrayToOptions(scheduleFilters.scheduleIDs, allScheduleOptions, 'options') ??
            []),
        ],
      },
    ];
  }
  result.map(tagObject => {
    const objectKey = Object.keys(tagObject)[0];
    tagObject[objectKey].forEach((tagOption: string) => {
      activeFilters.push({ filterKey: objectKey, filterValue: tagOption });
    });
  });
  return activeFilters;
};

export {
  useGetAllFilterParticipants,
  useGetAllEscalationPolicies,
  encodeFilterParam,
  decodeFilterParam,
  mapFilterParamStringToSetFilterParam,
  mapValuesArrayToOptions,
  getModifiedFilterParamObject,
  getActiveFilterObjectForTags,
};
