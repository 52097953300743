import {
  Box,
  HStack,
  Text,
  TextProps,
  useBreakpointValue,
  useMultiStyleConfig,
  VStack,
} from '@chakra-ui/react';
import { PriorityOption } from 'library/common';
import { THEME_COLORS } from 'library/theme/colors';
import { DROPDOWN_VARIANT } from 'library/types';
import { forwardRef, Ref as ForwardRef } from 'react';
import ReactSelect, {
  components,
  GroupBase,
  GroupProps,
  Options,
  Props as SelectProps,
  StylesConfig,
} from 'react-select';
import Select from 'react-select/base';
import { Owner } from 'views/main/organization/owner-filters/hooks/useEntityOwnerFilter';

import { AssigneeType } from '../enums';
import { AssigneeAvatar } from './AssigneeAvatar';
import { Avatar } from './Avatar';
import { SquadAvatar } from './SquadAvatar';
import { Tooltip, usernameTooltipLabel } from './Tooltip';

export type SelectRef = Select<Option, boolean, GroupBase<Option>>;
export type Option = {
  label: string;
  value: string;
  email?: any;
  username?: string;
  type?: Owner;
  assigneeType?: AssigneeType;
  imageUrl?: string;
};
type SelectOption = Options<Option>[number];

export interface DropdownProps extends SelectProps<SelectOption> {
  isInvalid?: boolean;
  variant?: DROPDOWN_VARIANT;
  isReadOnly?: boolean;
  teamOptionLabelStyles?: TextProps;
  useCustomValue?: boolean;
  customValue?: 'single' | 'multi';
}

export const Dropdown = forwardRef(function (
  {
    variant,
    teamOptionLabelStyles,
    isInvalid,
    styles: styleoverrides,
    isReadOnly,
    ...rest
  }: DropdownProps,
  ref: ForwardRef<SelectRef>,
) {
  const size = useBreakpointValue({ base: 'md', lg: 'lg' });
  const {
    container,
    placeholder,
    input,
    menu,
    menuList,
    option,
    optionActive,
    control,
    controlDisabled,
    dropdownIndicator,
    valueContainer,
    multiValueRemove,
    multiValue,
    indicatorSeparator,
    indicatorsContainer,
    loadingMessage,
    noOptionsMessage,
    selectLabel,
  } = useMultiStyleConfig('Dropdown', { size, isInvalid });

  const styles: StylesConfig<SelectOption> = {
    container: (provided, rest) => ({
      ...provided,
      ...(container as typeof provided),
      ...styleoverrides?.container?.(provided, rest),
    }),
    placeholder: (provided, rest) => ({
      ...provided,
      ...(placeholder as typeof provided),
      ...styleoverrides?.placeholder?.(provided, rest),
    }),
    input: (provided, { selectProps: { inputValue, isMulti } }) => ({
      ...provided,
      ...(!(inputValue || isMulti) ? { position: 'absolute' } : {}),
      ...(input as typeof provided),
    }),
    menu: provided => ({
      ...provided,
      ...(menu as typeof provided),
    }),
    menuList: provided => ({
      ...provided,
      width: '100%',
      ...(menuList as typeof provided),
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      ...(option as typeof provided),
      ...(isSelected ? (optionActive as typeof provided) : {}),
    }),
    control: (provided, rest) => ({
      ...provided,
      ...(control as typeof provided),
      ...styleoverrides?.control?.(provided, rest),
      ...(rest.isDisabled ? (controlDisabled as typeof provided) : {}),
    }),
    dropdownIndicator: provided => ({
      ...provided,
      ...(dropdownIndicator as typeof provided),
    }),
    valueContainer: provided => ({
      ...provided,
      ...(valueContainer as typeof provided),
    }),
    multiValueRemove: provided => ({
      ...provided,
      ...(multiValueRemove as typeof provided),
    }),
    multiValue: provided => ({ ...provided, ...(multiValue as typeof provided) }),
    indicatorSeparator: provided => ({
      ...provided,
      ...(indicatorSeparator as typeof provided),
    }),
    indicatorsContainer: (provided, rest) => ({
      ...provided,
      ...(indicatorsContainer as typeof provided),
      ...styleoverrides?.indicatorsContainer?.(provided, rest),
    }),
    loadingMessage: provided => ({
      ...provided,
      ...(loadingMessage as typeof provided),
    }),
    noOptionsMessage: provided => ({
      ...provided,
      ...(noOptionsMessage as typeof provided),
    }),
  };

  const avatarOptionLabel = (option: Option) => {
    return (
      <HStack sx={selectLabel}>
        {option.label === 'Select All' ||
        option.label.startsWith('Current Team') ? null : option.type === Owner.SQUAD ? (
          <SquadAvatar name={option.label} />
        ) : (
          <Avatar size="xs" name={option.label} />
        )}
        <Box>
          <Text
            {...(option.label.startsWith('Current Team')
              ? { ...(teamOptionLabelStyles as TextProps) }
              : {})}
          >
            {option.label}
          </Text>
          {option.username && (
            <Text maxW="30ch" isTruncated color={THEME_COLORS.secondary[1200]}>
              {option.username}
            </Text>
          )}
          {option.email && <Text>{option.email}</Text>}
        </Box>
      </HStack>
    );
  };

  const assigneeOptionLabel = (option: Option) => (
    <HStack sx={selectLabel} columnGap={2} alignItems="center">
      <AssigneeAvatar type={option.assigneeType} name={option.label} />
      <VStack sx={selectLabel} alignItems="left">
        <Text maxW="30ch" isTruncated>
          {option.label}
        </Text>
        {option.username && (
          <Text maxW="30ch" isTruncated color={THEME_COLORS.secondary[1200]}>
            {option.username}
          </Text>
        )}
      </VStack>
    </HStack>
  );

  const assigneeValue = (option: Option) => (
    <Tooltip label={usernameTooltipLabel(option.label, option.username)} placement={'bottom'}>
      <HStack sx={selectLabel} columnGap={2} alignItems="center">
        <AssigneeAvatar type={option.assigneeType} name={option.label} />
        <Text maxW="15ch" isTruncated>
          {option.label}
        </Text>
        {option.username && (
          <Text maxW="15ch" isTruncated>
            ({option.username})
          </Text>
        )}
      </HStack>
    </Tooltip>
  );

  const imageOptionLabel = (option: Option) => (
    <HStack sx={selectLabel} columnGap={2} alignItems="center">
      {option.imageUrl && <img src={option.imageUrl} width="16px" />}
      <Text>{option.label}</Text>
    </HStack>
  );

  const priorityOptionLabel = (option: Option) => (
    <PriorityOption label={option.label} value={option.value} />
  );

  const optionMap: Record<DROPDOWN_VARIANT, ((option: Option) => JSX.Element) | undefined> = {
    avatar: avatarOptionLabel,
    assignee: assigneeOptionLabel,
    image: imageOptionLabel,
    priority: priorityOptionLabel,
    icon: undefined,
  };

  const Group = (props: GroupProps<SelectOption, boolean>) => (
    <div>
      <components.Group {...props} />
    </div>
  );

  const SingleValue = (props: any) => {
    return <components.SingleValue {...props}>{assigneeValue(props.data)}</components.SingleValue>;
  };

  const MultiValue = (props: any) => {
    return <components.MultiValue {...props}>{assigneeValue(props.data)}</components.MultiValue>;
  };

  return (
    <ReactSelect
      {...rest}
      ref={ref}
      styles={styles}
      formatOptionLabel={variant ? optionMap[variant] : undefined}
      components={{
        Group,
        ...(rest.useCustomValue &&
          (rest.customValue === 'single' ? { SingleValue } : { MultiValue })),
      }}
      menuIsOpen={isReadOnly ? false : undefined}
      isSearchable={isReadOnly ? false : undefined}
    />
  );
});
