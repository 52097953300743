import { IKeypadMapping } from 'views/main/organization/lcr/common/interface';
import ActionCardContainer from './ActionCardContainer';

interface ActionsCardProps {
  actions: IKeypadMapping;
  key: number;
}

const ActionsCard: React.FC<ActionsCardProps> = ({ actions }) => {
  return <ActionCardContainer id={encodeURIComponent(actions.digit)} data={actions} />;
};

export default ActionsCard;
