import { ComponentStyleConfig } from '@chakra-ui/react';

export const Input: ComponentStyleConfig = {
  baseStyle: {
    field: {
      // To remove autofill from input
      '&:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 40px white inset !important',
      },
      '&:-webkit-autofill:hover': {
        '-webkit-box-shadow': '0 0 0 40px white inset !important',
      },
      '&:-webkit-autofill:focus': {
        '-webkit-box-shadow': '0 0 0 40px white inset !important',
      },
    },
  },
  sizes: {},
  defaultProps: {},
};
