import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Drawer {
  isOpen: boolean;
  onClose: () => void;
  callbackFn?: () => void;
  title: string;
  isFooter?: boolean;
  disableBodyPadding?: boolean;
  children: ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  showBackIcon?: boolean;
  justifyContent?: 'flex-start';
  closeButtonName?: string;
  showSave?: boolean;
}

export const CustomDrawerComponent = ({
  isOpen,
  onClose,
  callbackFn,
  title,
  isFooter,
  disableBodyPadding,
  children,
  size,
  showBackIcon,
  justifyContent,
  closeButtonName = 'Cancel',
  showSave = true,
}: Drawer) => {
  return (
    <>
      <Drawer onClose={() => onClose()} isOpen={isOpen} size={size ?? 'lg'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton boxShadow="none !important" />
          <DrawerHeader
            fontFamily="Mulish"
            fontSize={24}
            fontWeight={400}
            borderBottom="1px solid #CBD5E0"
          >
            <HStack alignItems="center" spacing={2}>
              {showBackIcon && (
                <IconButton
                  variant="unstyled"
                  onClick={onClose}
                  fontSize="24px"
                  icon={<ArrowBackIcon />}
                  aria-label="back-icon"
                />
              )}
              <div>{title}</div>
            </HStack>
          </DrawerHeader>
          <DrawerBody sx={disableBodyPadding ? { p: 0 } : {}}>{children}</DrawerBody>
          {isFooter && (
            <DrawerFooter justifyContent={justifyContent && justifyContent}>
              <Button variant="outline" mr={3} onClick={onClose}>
                {closeButtonName}
              </Button>
              {showSave && (
                <Button colorScheme="blue" onClick={callbackFn}>
                  Save
                </Button>
              )}
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};
