import React from 'react';
import {
  Avatar,
  CopyIconButton,
  DropDown,
  FocusBlock,
  Grid,
  Para,
  PulseLoader,
  TextButton,
  Theme,
  Tooltip,
} from 'uie/components';
import cx from 'classnames';
import { Locale } from 'core/helpers/dateUtils';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import { AlertDialogComponent, ImagePreview } from 'views/shared/components';

import { Responses } from '.';
import {
  ArrowLeftIcon,
  DeleteIcon,
  EditIcon,
  MoreIcon,
  StarIcon,
  WorkflowsIcon,
  UsernameIcon,
} from '../../../../../icons';
import MessageBlock from './MessageBlock';
import { ITransformedMessages } from './types';
import { IconWrapper } from './styles';
import { HStack, VStack } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const { theme } = Theme;

function render(this: Responses) {
  const { transformed, componentState, onlyStarred, alertNoteId, previewImageSrc } = this.state;

  const totalCount = transformed.length;
  const starredCount = transformed.filter(_ => !!_.starred_info).length;

  const CustomCodeRenderer = (props: { value: string }) => {
    return (
      <div className="incident_details__code-container">
        <pre className="incident_details__raw-code">{props.value}</pre>
        <div className="incident_details__code-copy">
          <CopyIconButton type="transparent">{props.value}</CopyIconButton>
        </div>
      </div>
    );
  };

  const imageRenderer = (props: Record<string, any>) => {
    return (
      <img
        src={props.src}
        alt={props.src}
        onClick={() =>
          this.setState({
            previewImageSrc: props.src,
          })
        }
        style={{ cursor: 'zoom-in' }}
      />
    );
  };

  const closeImagePopUp = () => {
    this.setState({
      previewImageSrc: '',
    });
  };

  const renderMdLink = (props: any) => {
    if ((props.href as string).match(/^\/user\/\w{24}$/gim)) {
      const userId = props.href.replace('/user/', '');
      const isYou = userId === this.props.userInfo.d?.id;
      const name = this._entityMap.users[userId]?.name ?? 'Deleted User';
      const isOnCall = this._onCallUsers.has(userId);
      return (
        <Link
          to={props.href}
          className={cx('response-message-tagged-user', { 'is-you': isYou })}
          style={{ textDecoration: 'none' }}
        >
          @{name} {isOnCall && <div className="response-message-user-on-call" />}
        </Link>
      );
    } else if ((props.href as string).includes('stakeholders')) {
      const stakeHolderId = props.href.split('/stakeholders/')[1];
      return (
        <Link
          to={`${props.href.split('/stakeholders')[0]}/stakeholder`}
          className={cx('response-message-tagged-user', {
            'is-you': false,
          })}
          style={{ textDecoration: 'none' }}
        >{`${
          this._entityMap.stakeholders[`${stakeHolderId}`]?.name || 'Deleted Stakeholder group'
        }`}</Link>
      );
    } else if ((props.href as string).match(/^\/settings\/teams\/\w{24}\/squads\?name=/gim)) {
      const queryString = props.href.split('?')[1];
      const queryParams = new URLSearchParams(queryString);
      const name = queryParams.get('name');
      const inactiveLink = !this.canFollowTeamsAndSquadsLink();
      return (
        <Link
          to={props.href}
          className={cx('response-message-tagged-user', {
            'is-you': false,
            'inactive-link': inactiveLink,
          })}
          style={{ textDecoration: 'none' }}
        >
          @{name}
        </Link>
      );
    } else if ((props.href as string).match(/^\/settings\/teams\/\w{24}$/gim)) {
      const teamId = props.href.replace('/settings/teams/', '');
      const name = this._entityMap.teams[teamId]?.name ?? 'Deleted Team';
      const inactiveLink = !this.canFollowTeamsAndSquadsLink();
      return (
        <Link
          to={props.href}
          className={cx('response-message-tagged-user', {
            'is-you': false,
            'inactive-link': inactiveLink,
          })}
          style={{ textDecoration: 'none' }}
        >
          @{name}
        </Link>
      );
    }

    return (
      <a
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        {props.children} <ExternalLinkIcon w={2.5} h={2.5} position={'relative'} top={-1.5} />
      </a>
    );
  };

  const getSourceUser = (
    source = '',
    {
      id = '',
      first_name = '',
      last_name = '',
    }: { id: string; first_name: string; last_name: string },
  ) => {
    switch (source) {
      case 'servicenow':
        return {
          first_name: first_name || 'servicenow',
          last_name,
          id,
          username: '',
        };
      default:
        return {};
    }
  };

  const getSourceImage = (source = '') => {
    switch (source) {
      case 'servicenow':
        return (
          <div
            style={{
              width: '29px',
              height: '29px',
              borderRadius: '50%',
              border: `1px solid #DDD`,
              backgroundColor: theme.shades.white,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src="/icons/servicenow_user.png" width={16} height={16} />
          </div>
        );
      case 'jira-cloud':
        return (
          <div
            style={{
              width: '29px',
              height: '29px',
              borderRadius: '50%',
              border: `1px solid #DDD`,
              backgroundColor: theme.shades.white,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src="/icons/jira-cloud.png" width={13} height={13} />
          </div>
        );
      default:
        return null;
    }
  };

  const getNoteSource = (source = '') => {
    switch (source) {
      case 'servicenow':
        return <img src="/icons/integrations/servicenow.png" width={70} height={10} />;
      case 'jira-cloud':
        return (
          <h1
            style={{
              fontWeight: 700,
              fontSize: '14px',
              fontFamily: 'Mulish',
            }}
          >
            Jira
          </h1>
        );
      default:
        return '';
    }
  };

  const renderResponses = () => (
    <React.Fragment>
      {componentState.responses === 'busy' && (
        <Grid
          className="response-message-container response-no-hover"
          alignItems="center"
          type="column"
        >
          <Grid style={{ marginTop: 180 }}>
            <Para fontWeight={400} fontSize={18}>
              Loading Conversations
            </Para>
            <Grid style={{ marginTop: -10 }}>
              <PulseLoader base="5px" />
            </Grid>
          </Grid>
        </Grid>
      )}
      {componentState.responses !== 'busy' && transformed.length === 0 && (
        <Grid
          className="response-message-container response-no-hover"
          alignItems="center"
          type="column"
        >
          <Para
            fontSize={18}
            fontWeight={400}
            color={theme.shades.cement}
            style={{ letterSpacing: 0, lineHeight: '28px', marginBottom: 10, marginTop: 180 }}
          >
            There are no notes for this incident yet.
          </Para>

          <Para
            fontSize={14}
            fontWeight={400}
            color={theme.shades.cement}
            style={{ letterSpacing: 0, lineHeight: '24px', textAlign: 'center' }}
          >
            <span style={{ color: theme.primary.default, fontWeight: 600 }}>Pro-tip:</span>
            &nbsp;Post important notes or links in this space for your entire team to view
          </Para>
        </Grid>
      )}
      {transformed.length > 0 && (
        <>
          {transformed
            .filter(_ => !this.state.onlyStarred || _.starred_info)
            .map((response, i, responses) => {
              const isWorkflowNote = response.created_by_entity_type === 'workflow';
              const isJiraNote = response.created_by_entity_type === 'jira-cloud';
              const hasSource = response?.metadata?.source !== undefined;

              const user = isJiraNote
                ? {
                    first_name: response.metadata?.jira_comment_metadata?.comment_author?.name,
                    last_name: '',
                    id: '',
                    username: '',
                  }
                : isWorkflowNote
                ? {
                    first_name: 'Note added by',
                    last_name: 'Workflow',
                    id: '',
                    username: '',
                  }
                : hasSource
                ? getSourceUser(response?.metadata?.source, response.user)
                : response.user
                ? {
                    ...response.user,
                    username: this._entityMap.users[response.user.id]?.username,
                  }
                : {
                    id: '',
                    first_name: '',
                    last_name: '',
                    username: '',
                  };
              return (
                <Grid
                  ref={this._messageRefs[response.id]}
                  key={i}
                  className="response-message-container"
                  onMouseEnter={this.setHoverMessage(response.id)}
                  onMouseLeave={this.setHoverMessage('')}
                  style={{ backgroundColor: this.getBackgroundColor(response) }}
                >
                  <Grid width="50px" type="column">
                    {isWorkflowNote ? (
                      <IconWrapper>
                        <WorkflowsIcon width={16} height={16} />
                      </IconWrapper>
                    ) : hasSource ? (
                      getSourceImage(response?.metadata?.source)
                    ) : (
                      <div>
                        <Avatar
                          base={24}
                          type="rounded"
                          reduceColorString={user.id}
                          attributeHook={this._onCallUsers.has(user.id ?? '') as any}
                          attributeProps={{
                            backgroundColor: theme.success.default,
                            base: 8,
                          }}
                        >
                          {`${user.first_name} ${user.last_name}`}
                        </Avatar>
                      </div>
                    )}
                  </Grid>
                  <Grid flexWidth={12} type="column">
                    <Grid justifyContent="space-between">
                      <Grid alignItems="center" style={{ marginBottom: 5 }}>
                        <VStack alignItems="flex-start">
                          <HStack>
                            <Para
                              fontWeight={500}
                              fontSize={14}
                              style={{ lineHeight: '17px', letterSpacing: 0 }}
                            >
                              {!hasSource
                                ? `${user.first_name} ${user.last_name}`
                                : getNoteSource(response?.metadata?.source)}
                              {user.deleted && ' (Deleted User)'}
                            </Para>
                            <Tooltip
                              label={Locale.toFullDateTime(response.created_at)}
                              padding="4px"
                              offset={{ top: '-25px', left: '90px' }}
                            >
                              <Para fontSize={12}>
                                {Locale.toSimpleWeekdayTime(response.created_at)}
                                {response?.is_edited && (
                                  <span
                                    style={{
                                      marginLeft: 6,
                                      color: '#bbb',
                                    }}
                                  >
                                    Edited
                                  </span>
                                )}
                              </Para>
                            </Tooltip>
                          </HStack>
                          {user.username && (
                            <HStack mt={'0px !important'} alignItems="flex-start">
                              <UsernameIcon style={{ marginTop: 3 }} />
                              <Para
                                fontSize={12}
                                fontWeight={500}
                                color={THEME_COLORS.secondary[1200]}
                                style={{ maxWidth: '35ch' }}
                              >
                                {user.username}
                              </Para>
                            </HStack>
                          )}
                          {hasSource && response.user && (
                            <HStack mt={'0px !important'} alignItems="flex-start">
                              <Para
                                fontSize={12}
                                fontWeight={500}
                                color={THEME_COLORS.secondary[1200]}
                                style={{ maxWidth: '35ch' }}
                              >
                                {user.first_name} {user.last_name}
                              </Para>
                            </HStack>
                          )}
                        </VStack>
                      </Grid>
                      {!this.state.onlyStarred && this.state.editMessageId === '' && (
                        <Grid alignItems="center">
                          {response.starred_info ? (
                            <StarIcon
                              fill={theme.acknowledge.default}
                              stroke={theme.acknowledge.default}
                              height={16}
                              width={16}
                              className="cursor-pointer"
                              onClick={this.onMessageStar(response.id, false)}
                              style={{ marginRight: 2 }}
                            />
                          ) : (
                            <StarIcon
                              stroke={theme.acknowledge.default}
                              height={16}
                              width={16}
                              className="cursor-pointer"
                              style={{
                                marginRight: 2,
                                visibility:
                                  this.state.hoverMessage === response.id ? 'visible' : 'hidden',
                              }}
                              onClick={this.onMessageStar(response.id, true)}
                            />
                          )}
                          {this.state.hoverMessage === response.id &&
                          this.isAuthor(response.id) &&
                          !hasSource ? (
                            <DropDown
                              offset="-85px"
                              top={
                                i === responses.length - 1 && responses.length > 1
                                  ? '-105px'
                                  : '10px'
                              }
                              hook={
                                <MoreIcon
                                  stroke={theme.shades.grey}
                                  height={15}
                                  width={15}
                                  className="cursor-pointer"
                                />
                              }
                              width="105px"
                              maxWidth="105px"
                              padding="0px"
                            >
                              <Grid type="column">
                                <FocusBlock
                                  key={`edit-${response.id}`}
                                  value={`edit-${response.id}`}
                                  onSelectValue={this.setEditMessageId(response.id)}
                                  height="40px"
                                >
                                  <Grid justifyContent="space-around">
                                    <EditIcon
                                      stroke={theme.shades.grey}
                                      height={15}
                                      width={15}
                                      style={{ verticalAlign: 'bottom', marginRight: 10 }}
                                    />
                                    <Para fontSize={14} fontWeight={400}>
                                      Edit
                                    </Para>
                                  </Grid>
                                </FocusBlock>
                                <FocusBlock
                                  key={`delete-${response.id}`}
                                  value={`delete-${response.id}`}
                                  onSelectValue={this.onMessageDelete(response.id)}
                                  height="40px"
                                >
                                  <Grid justifyContent="space-around">
                                    <DeleteIcon
                                      height={15}
                                      width={15}
                                      style={{ verticalAlign: 'bottom', marginRight: 10 }}
                                    />
                                    <Para fontSize={14} fontWeight={400}>
                                      Delete
                                    </Para>
                                  </Grid>
                                </FocusBlock>
                              </Grid>
                            </DropDown>
                          ) : (
                            <div className="dummy-ellipsis" />
                          )}
                        </Grid>
                      )}
                    </Grid>
                    {this.state.editMessageId === response.id ? (
                      <MessageBlock
                        entities={this._entityMap}
                        incidentId={this.props.incidentId}
                        sendMessage={this.onMessageUpdate}
                        onCallUsers={this._onCallUsers}
                        initialMessage={this.state.responses[response.id].message}
                        setEditMessageId={this.setEditMessageId}
                        fileUploadService={this.fileUploadService}
                      />
                    ) : (
                      <div className="incident-detail_markdown">
                        <ReactMarkdown
                          className="incident_details__mde remove-border-button-dotts"
                          plugins={[breaks, gfm]}
                          renderers={{
                            link: renderMdLink,
                            image: imageRenderer,
                            code: CustomCodeRenderer,
                          }}
                          source={response.message}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              );
            })}
        </>
      )}
    </React.Fragment>
  );

  return (
    <>
      <Grid width="calc(100% - 20px)" className="response-container" type="column">
        <Grid
          className="response-header"
          alignItems="center"
          style={{ padding: 20, height: 25 }}
          justifyContent="space-between"
        >
          <Grid alignItems="center">
            <Grid style={{ display: onlyStarred ? 'block' : 'none' }}>
              <TextButton
                buttonType="ghost"
                onClick={this.setOnlyStarred(false)}
                style={{ marginRight: 10 }}
              >
                <ArrowLeftIcon height={15} width={15} />
              </TextButton>
            </Grid>
            <Grid>
              <Para
                height="24px"
                fontSize={18}
                fontWeight={500}
                color={theme.shades.cement}
                style={{ letterSpacing: 0, lineHeight: '20px' }}
              >
                {onlyStarred ? `Starred Notes (${starredCount})` : `Notes (${totalCount})`}
              </Para>
            </Grid>
          </Grid>
          <Grid style={{ visibility: !onlyStarred ? 'visible' : 'hidden' }}>
            <TextButton
              buttonType="ghost"
              onClick={this.setOnlyStarred(true)}
              disabled={starredCount === 0}
              style={{ padding: 0 }}
            >
              <StarIcon
                stroke={theme.primary.default}
                fill={theme.primary.default}
                width={16}
                height={16}
                style={{ verticalAlign: 'text-bottom', marginRight: 2 }}
              />
              <Para
                height="24px"
                fontSize={12}
                fontWeight={500}
                color={theme.primary.default}
                style={{ letterSpacing: 0, lineHeight: '20px' }}
              >
                View Starred Notes ({starredCount})
              </Para>
            </TextButton>
          </Grid>
        </Grid>
        <Grid
          style={{
            width: '95%',
            backgroundColor: theme.shades.lightGrey,
            paddingTop: 1.5,
            alignSelf: 'center',
          }}
        />
        <Grid
          height="calc(100% - 61px)"
          className="response-chat-container"
          type="column"
          style={{ overflowX: 'hidden', overflowY: 'hidden' }}
        >
          <Grid
            height="100%"
            type="column"
            className="response-chat mb-10"
            ref={this._responseContainer}
            style={{ position: 'relative' }}
          >
            {renderResponses()}
          </Grid>
          {!this.state.onlyStarred && (
            <Grid type="column" className="response-text-box">
              <MessageBlock
                entities={this._entityMap}
                incidentId={this.props.incidentId}
                sendMessage={this.onMessageSend}
                onCallUsers={this._onCallUsers}
                fileUploadService={this.fileUploadService}
              />
            </Grid>
          )}
        </Grid>
        <AlertDialogComponent
          isOpen={!!alertNoteId}
          onClose={this.closeAlertDialog}
          callbackFn={this.onMsgDeletionByEdit}
          msg={'Are you sure you want to delete this note? This cannot be undone.'}
          title={'Delete Note'}
          isDelete={true}
          applyChakraStyle={false}
        />
      </Grid>
      <ImagePreview
        applyChakraStyle={false}
        src={previewImageSrc}
        onClose={closeImagePopUp}
      ></ImagePreview>
    </>
  );
}

export default render;
