import { Box, Button, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { TAG_MENU_MAX_WIDTH } from 'core/const/immutables';
import { truncateTagLabel } from 'core/helpers/stringUtils';
import { LibraryIcons } from 'library/icons';
import { ComponentProps, FC, ReactNode, useMemo, useState } from 'react';
import { ActionMeta, StylesConfig } from 'react-select';
import CreatableReactSelect from 'react-select/creatable';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import { Tag as ITag } from 'views/main/organization/schedules/graphql/types';

const customStyles: StylesConfig<any, boolean> = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: '100%',
    border: 1,
  }),
  control: provided => ({
    ...provided,
    margin: 0,
    padding: 5,
    border: 0,
    minWidth: 200,
    boxShadow: 'none',
  }),
  menu: () => ({ boxShadow: `inset 0 1px 0 gray.200` }),
};

type IProps = {
  value: ITag;
  keysOptions: string[];
  valuesMap: { [key: string]: string[] };
  handleChange?: (key: string, value: string) => void;
  errorMsg?: string;
};

const formatKeyOption: (data: any, formatOptionLabelMeta: FormatOptionLabelMeta<any>) => ReactNode =
  ({ label, valuesCount }, { context, selectValue }) => {
    const isSelected = label === selectValue[0]?.label;
    return (
      <VStack justifyContent="flex-start" alignItems="flex-start">
        <Text variant="formInput" color={isSelected ? 'brand.white' : 'secondary.1000'}>
          {label}
        </Text>
        {valuesCount && context !== 'value' && (
          <Text variant="hint" color={isSelected ? 'brand.white' : 'secondary.700'}>
            {valuesCount} {valuesCount > 1 ? 'values' : 'value'}
          </Text>
        )}
      </VStack>
    );
  };

const formatValueOption: (
  data: any,
  formatOptionLabelMeta: FormatOptionLabelMeta<any>,
) => ReactNode = ({ label }, { selectValue }) => {
  const isSelected = label === selectValue[0]?.label;
  return (
    <VStack justifyContent="flex-start" alignItems="flex-start">
      <Text variant="formInput" color={isSelected ? 'brand.white' : 'secondary.1000'}>
        {label}
      </Text>
    </VStack>
  );
};

export const TagDropdown: FC<IProps> = ({ keysOptions, valuesMap, value, ...props }) => {
  const keysOptionsFormat = useMemo(
    () =>
      keysOptions.map(key => ({
        value: key,
        label: key,
        valuesCount: valuesMap[key]?.length || 0,
      })),
    [keysOptions, valuesMap],
  );

  const valuesOptions = useMemo(
    () =>
      valuesMap[value.key]?.map(value => ({
        value: value,
        label: value,
      })) || [],
    [valuesMap, value.key],
  );

  const onUpdate = (type: 'key' | 'value') => (selected: any) => {
    const newKey = type === 'key' ? selected.value : value.key;
    let newValue = type === 'value' ? selected.value : value.value;

    if (type === 'key') {
      newValue = '';
    }

    props.handleChange && props.handleChange(newKey, newValue);
  };

  return (
    <HStack width="100%" columnGap="16px">
      <DropdownContainer
        type="key"
        selected={value.key}
        menuPlaceholder="Key"
        value={value.key ? [{ label: value.key, value: value.key }] : []}
        onChange={onUpdate('key')}
        formatOptionLabel={formatKeyOption}
        options={keysOptionsFormat}
        placeholder="Search Existing Keys"
      />
      <DropdownContainer
        type="value"
        selected={value.value}
        menuPlaceholder="Value"
        value={value.value ? [{ label: value.value, value: value.value }] : []}
        onChange={onUpdate('value')}
        formatOptionLabel={formatValueOption}
        options={valuesOptions}
        placeholder="Search Existing Values"
      />
    </HStack>
  );
};

type DropdownContainerProps = {
  selected: string;
  menuPlaceholder: string;
  onClose?: () => void;
  isInitializedOpen?: boolean;
  type: 'key' | 'value';
  onChange: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;
} & ComponentProps<typeof CreatableReactSelect>;

const DropdownContainer: FC<DropdownContainerProps> = ({
  selected,
  menuPlaceholder,
  onClose,
  isInitializedOpen,
  onChange,
  type,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitializedOpen || false);
  const toggleOpen = () => {
    if (isOpen) onClose?.();

    setIsOpen(!isOpen);
  };

  const handleChange = (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
    toggleOpen();
    onChange?.(newValue, actionMeta);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <Button
          onClick={toggleOpen}
          display="flex"
          justifyContent="space-between"
          minWidth="160px"
          height="30px"
          variant="secondary"
          borderWidth="1px"
          borderColor="secondary.200"
          rightIcon={<Icon as={LibraryIcons.ChevronDownIcon} color="secondary.1000" />}
        >
          <Text variant="formInput" color="secondary.1000">
            {truncateTagLabel(selected) || menuPlaceholder}
          </Text>
        </Button>
      }
    >
      <CreatableReactSelect
        autoFocus
        backspaceRemovesValue={false}
        closeMenuOnSelect={false}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: null,
          ClearIndicator: () => null,
        }}
        noOptionsMessage={() => `No ${type}s found. Type and add a new one`}
        formatCreateLabel={(inputValue: string) => (
          <VStack justifyContent="flex-start" alignItems="flex-start">
            <Text color="brand.blue" variant="formInput">{`+ ${inputValue}`}</Text>
          </VStack>
        )}
        menuIsOpen
        controlShouldRenderValue={false}
        styles={customStyles}
        onChange={handleChange}
        {...props}
      />
    </Dropdown>
  );
};

const Menu = (props: JSX.IntrinsicElements['div']) => {
  const shadow = '#80808045';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
        fontSize: 14,
        maxWidth: TAG_MENU_MAX_WIDTH,
      }}
      {...props}
    />
  );
};

const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
interface DropdownProps {
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
  children: ReactNode;
}

const Dropdown: FC<DropdownProps> = ({ children, isOpen, target, onClose }) => (
  <Box
    sx={{
      position: 'relative',
      border: `1px solid gray.400`,
      borderRadius: 4,
      width: '100%',
    }}
  >
    <Flex style={{ alignItems: 'center' }}>{target}</Flex>
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </Box>
);
