import { Text } from '@chakra-ui/react';
import Editor from '@monaco-editor/react';
import { READONLY_MONACO_EDITOR_OPTIONS } from 'views/shared/constants';

const HttpCallPayload = ({ payload }: { payload: string }) => {
  return (
    <>
      {payload !== '' && (
        <>
          <Text color="secondary.700" textStyle="bold_800" variant="h3" pb={1}>
            Payload
          </Text>
          <Editor
            language="json"
            height={'8rem'}
            value={payload}
            width="50rem"
            options={READONLY_MONACO_EDITOR_OPTIONS}
          />
        </>
      )}
    </>
  );
};

export default HttpCallPayload;
