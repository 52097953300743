import { HStack, useBoolean } from '@chakra-ui/react';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import { IIncident } from 'core/interfaces/IIncidents';
import { CrownSmallIcon } from 'icons';
import { FC, memo, ReactNode, useCallback, useMemo } from 'react';

import { AddResponders } from './components/add-responders';
import { RESPONDERS_FEAT_NAME } from './const';

type Props = {
  organization: Pick<IAppState, 'organization'>;
  incident: IIncident;
  children: ReactNode;
  addInlineCrownStyle?: boolean;
};

export const MultipleResponders: FC<Props> = memo(
  ({ children, organization, incident, addInlineCrownStyle = false }) => {
    const [showModal, setShowModal] = useBoolean();
    const [showDrawer, setShowDrawer] = useBoolean();

    const isFeatureUnavailable = useMemo(() => {
      return organization
        ? BillingService.isFeatureDisabled(
            { organization } as unknown as Pick<IAppState, 'organization'>,
            RESPONDERS_FEAT_NAME,
          )
        : false;
    }, [organization, BillingService]);

    const onClick = useCallback(() => {
      if (isFeatureUnavailable) setShowModal.toggle();
      else setShowDrawer.on();
    }, [showModal]);

    const renderCrown = useMemo(() => {
      if (isFeatureUnavailable)
        return (
          <CrownSmallIcon
            style={
              addInlineCrownStyle
                ? {
                    position: 'absolute',
                    right: '8px',
                  }
                : {}
            }
            className="pro"
          />
        );
      return null;
    }, [isFeatureUnavailable]);

    return (
      <>
        <HStack onClick={onClick} position="relative">
          {children}
          {renderCrown}
        </HStack>
        <UpgradePlanModal
          hasBillingPermission={BillingService.hasManageBillingPermission({
            organization,
          } as unknown as Pick<IAppState, 'organization'>)}
          showModal={showModal}
          onCancel={setShowModal.off}
          message={BillingService.getMessage(0, RESPONDERS_FEAT_NAME, {
            organization,
          } as unknown as Pick<IAppState, 'organization'>)}
          header={BillingService.getHeader(0, RESPONDERS_FEAT_NAME, {
            organization,
          } as unknown as Pick<IAppState, 'organization'>)}
          onUpgrade={setShowModal.off}
        />
        <AddResponders isOpen={showDrawer} onClose={setShowDrawer.off} incident={incident} />
      </>
    );
  },
);
