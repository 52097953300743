import {
  REQUEST_ORG_PLAN,
  REQUEST_ORG_PLAN_SUCCESS,
  REQUEST_ORG_PLAN_FAIL,
} from '../../const/organization';
import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';

const initialOrganizationPlan = INITIAL_STATE.organization.plan;

const organizationPlan = (state = initialOrganizationPlan, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ action: RESET_STATE, p: null, error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_PLAN:
      return {
        ...state,
        ...{ action: REQUEST_ORG_PLAN, p: null, error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_PLAN_SUCCESS:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_PLAN_SUCCESS,
          p: action.payload.billingPlan,
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ORG_PLAN_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_PLAN_FAIL,
          p: null,
          error: { type: REQUEST_ORG_PLAN_FAIL, reason: action.payload },
        },
      };
    default:
      return state;
  }
};

export default organizationPlan;
