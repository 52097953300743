import React, { ReactNode } from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  content: ReactNode;
  title?: string;
  enableOverlay?: boolean;
  disableFooter?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  maxW?: string;
  modalPositionObject?: Record<string, any>;
  unsetTopAndBottomPadding?: boolean;
  unsetLeftAndRightPadding?: boolean;
  modalOverlayBackgroundColor?: string;
};

function OverlayModal({
  isOpen,
  onClose,
  enableOverlay,
  disableFooter,
  title,
  content,
  size,
  maxW,
  modalPositionObject,
  unsetTopAndBottomPadding,
  unsetLeftAndRightPadding,
  modalOverlayBackgroundColor,
}: Props) {
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => onClose?.()}
      size={size ?? 'sm'}
      id="service_extension"
    >
      <ModalOverlay
        bg={modalOverlayBackgroundColor ? modalOverlayBackgroundColor : 'blackAlpha.200'}
        backdropFilter={enableOverlay ? 'blur(2px) saturate(80%)' : ''}
      />

      <ModalContent maxW={maxW} sx={modalPositionObject}>
        {title && (
          <>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
          </>
        )}

        <ModalBody
          pl={unsetLeftAndRightPadding ? 0 : 3}
          pr={unsetLeftAndRightPadding ? 0 : 3}
          pt={unsetTopAndBottomPadding ? 0 : 3}
          pb={unsetTopAndBottomPadding ? 0 : 3}
        >
          {content}
        </ModalBody>

        {!disableFooter && (
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}

export default OverlayModal;
