import Axios from 'axios';
import { API } from 'core';
import { QUERY_CONFIG } from 'library/query-config';
import { useMutation } from 'react-query';

import { QUERY_KEY } from './queryKeys';

export const createGerRuleset = (gerId: string, alertVersion: string, alertName: string) => {
  const body = {
    alert_source_shortname: alertName,
    alert_source_version: alertVersion,
  };
  return Axios.post(
    `${API.config.batman}/organizations/${API.config.organizationId}/global-event-rules/${gerId}/rulesets`,
    body,
  );
};

export const useCreateGerRulesetMutation = (
  gerId: string,
  alertVersion: string,
  alertName: string,
) => {
  const { isSuccess, isError, isLoading, mutate } = useMutation({
    mutationKey: [QUERY_KEY.CREATE_GER_RULESET, gerId, alertVersion, alertName],
    mutationFn: () => createGerRuleset(gerId, alertVersion, alertName),
    ...QUERY_CONFIG,
  });
  return {
    mutate,
    isSuccess,
    isError,
    isLoading,
  };
};
