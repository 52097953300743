import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  createStandaloneToast,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import qs from 'query-string';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout } from 'views/shared/components';

import { OrganizationContext } from '../..';
import { IllustrationBox, PlaceholderText } from '../../common';
import HelpGuide from '../../common/HelpGuide';
import { ServiceTooltip } from '../../components';
import { serviceDetailUrl } from '../../contants/service.contant';
import { alertSourceHelpGuide } from '../../contants/service.guide';
import useQueryParams from '../../helpers/helper.query-params';
import { commonAddAlertSource } from '../../helpers/helper.service';
import { useAddAlertSource } from '../../hooks/useAddAlertSource';
import { useAlertSourceList } from '../../hooks/useAlerSourceList';
import { useIntegrationList } from '../../hooks/useIntegrationList';
import { useServiceDetailQuery } from '../../hooks/useServiceDetail';
import AlertSourceIllustration from '../../icons/Illustrations/AlertSource.png';
import {
  IAlertSource,
  IIntegration,
  Integration,
  Integrations,
} from '../../Interfaces/intigration';
import AddAlertSourceListing from '../addAlertSource/addAlertSourceListingCards';

interface Props {
  integrations: Integration;
}
const queryParams = {
  tab: 'tab',
};

interface filterData {
  arraySelector: string;
  categories: string[];
  description: string;
  displayKeyOnly: boolean;
  eventIndicator: {
    events: string;
    key: string;
    _id: string;
  };
  heading: string;
  isBatched: boolean;
  isNative: boolean;
  isPrivate: boolean;
  isValid: boolean;
  message: string;
  shortName: string;
  supportDoc: string;
  type: string;
  version: string;
  _id: string;
}

const helpGuideWidth = 385;

function AlertSourceList(props: Props) {
  const _uA = useUserAccess();
  const hasRead = _uA.hasReadAccess('services');
  const hasCreate = _uA.hasCreateAccess('services');
  const hasUpdate = _uA.hasUpdateAccess('services');
  const hasDelete = _uA.hasDeleteAccess('services');

  const history = useHistory();
  const toast = createStandaloneToast();
  const serviceId: string | null = new URLSearchParams(location.search).get('service');
  const organization = useContext(OrganizationContext);

  const { alertSourceList }: { alertSourceList: IAlertSource[] } = useAlertSourceList();
  const { data: serviceDetail, refetch: refetchDetail } = useServiceDetailQuery(serviceId);
  const { integrationListApiData } = useIntegrationList();

  const [alertSourcesList, setAlertSourcesList] = useState<IIntegration[]>();
  const [alertSourcesListFilteration, setAlertSourcesListFilteration] = useState<IIntegration[]>();

  const [selected, setSelected] = useState<string[]>([]);

  const [integrationData, setIntegrationData] = useState<string>();
  const [loader, setLoader] = useState<boolean>(false);

  const query = useQueryParams();
  const activeParamsTab = query.get(queryParams.tab);
  const [showableAlertSource, setShowableAlertSource] = useState(alertSourcesList);

  const tabs = [
    {
      label:
        'All Alert Sources ' +
        `  ${showableAlertSource?.length ? `(${showableAlertSource?.length})` : ''}`,
      key: 'allAlertSource',
    },
    {
      label:
        'Configured Alert Sources ' +
        `  ${
          serviceDetail?.active_alert_sources?.length
            ? `(${serviceDetail?.active_alert_sources?.length})`
            : ''
        }`,
      key: 'configured',
    },
  ];

  const isFallbackSearchForAlerts = (showableAlertSource: any): boolean => {
    const isEmail = showableAlertSource.find((alert: any) => alert.shortName === 'email');
    const isWebhook = showableAlertSource.find((alert: any) => alert.shortName === 'api');
    return showableAlertSource && showableAlertSource.length == 2 && isEmail && isWebhook;
  };

  const { addalertsource_, addAlertSourceData } = useAddAlertSource();

  const alertSources: IAlertSource[] | IIntegration[] = props?.integrations?.integrations?.i
    .filter((as: IAlertSource | IIntegration) => !(as.deprecated || as.isPrivate))
    .map((as: IAlertSource | IIntegration) => ({
      isActive: false,
      ...as,
    }));

  const addAlertSource = (id: string) => {
    const data: string[] = commonAddAlertSource(id, selected);
    if (data.length > 0) {
      setSelected(data);
      setShowableAlertSource(showableAlertSource);
    } else {
      setSelected(data);
      setShowableAlertSource(showableAlertSource);
    }
  };
  const goToServiceListingPage = () => {
    setLoader(true);
    if (selected?.length > 0) {
      const payload = {
        alert_sources: selected,
        teamId: organization?.selectedTeam?.teamId,
        api_key: serviceDetail?.api_key,
        serviceId: serviceDetail?.id,
      };
      addalertsource_(payload);
    } else {
      setLoader(false);
      const title = 'Please select alert source';
      toast.closeAll();
      toast({ title, status: 'error', variant: 'subtle', isClosable: true });
    }
  };

  /** Render search result for alerts  */
  const filteredAlertSourcessData = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && alertSourcesListFilteration) {
      const filtered = alertSourcesListFilteration.filter((el: any, idx: number) => {
        return el.type.toUpperCase().includes(e.target.value.toUpperCase());
      });

      const filteredEmailAndWebhook = alertSourcesListFilteration.filter((el: any, idx: number) => {
        return ['email', 'api'].includes(el.shortName);
      });

      const filteredResult = filtered.length ? filtered : filteredEmailAndWebhook;

      setShowableAlertSource(filteredResult);
    } else {
      setAlertSourcesList(alertSourcesListFilteration);
      alertSourcesListFilteration
        ? setShowableAlertSource(alertSourcesListFilteration)
        : setShowableAlertSource(alertSourceList.filter((as: any) => !as.isPrivate));
    }
  };

  useEffect(() => {
    const alreadySelected: string[] = [];

    if (alertSources) {
      setAlertSourcesList(alertSources);
      setShowableAlertSource(alertSources);
      setAlertSourcesListFilteration(alertSources);
    }
    if (serviceDetail?.active_alert_sources) {
      serviceDetail?.active_alert_sources?.map((item: { id: string }, indx: number) => {
        alreadySelected.push(item.id);
      });
      setSelected(alreadySelected);
    }
  }, [serviceId, serviceDetail?.active_alert_sources]);

  /** Use effect for Integration result rendering */
  useEffect(() => {
    let integrationFilteredData: IAlertSource[] = [];

    if (integrationData && alertSourceList) {
      integrationFilteredData = alertSourceList?.filter(
        (data: IAlertSource) =>
          !data.isPrivate && !!data?.categories?.find((item: string) => item == integrationData),
      );
      setShowableAlertSource(integrationFilteredData);
      setAlertSourcesListFilteration(integrationFilteredData);
    } else {
      setAlertSourcesListFilteration(alertSources);
      setShowableAlertSource(alertSources);
    }
  }, [integrationData]);

  const activeTab = useMemo(() => {
    const activeTabIndex = tabs.findIndex(tab => tab.key === activeParamsTab) || 0;
    return activeTabIndex > -1 ? activeTabIndex : 0;
  }, [activeParamsTab]);

  const handleTabChange = (index: number, replaceHistory?: boolean) => {
    const config = {
      pathname: history.location.pathname,
      search: qs.stringifyUrl({
        url: '',
        query: { [queryParams.tab]: tabs.find((_tab, i) => i === index)?.key },
      }),
    };
    if (replaceHistory) {
      history.replace(`${config.pathname}${config.search}&service=${serviceId}`);
    } else {
      history.push(`${config.pathname}${config.search}&service=${serviceId}`);
    }
  };
  useEffect(() => {
    if (!activeParamsTab) {
      handleTabChange(0, true);
    }
  }, [activeParamsTab]);

  const filterMapdata = useMemo(() => {
    return (
      <AddAlertSourceListing
        alertSourcesList={showableAlertSource}
        api_key={serviceDetail?.api_key}
        serviceId={serviceDetail?.id}
        addAlertSource={addAlertSource}
        selected={selected}
        email={serviceDetail?.email}
        refetchKey={refetchDetail}
      />
    );
  }, [showableAlertSource, selected, serviceDetail]);

  return (
    <Layout>
      <Box background="gray.200" height="100%">
        <HStack alignItems="inherit" spacing={3} minHeight="100%">
          <Box
            width={`calc(100% - ${helpGuideWidth + 12}px)`}
            background="#fff"
            p={6}
            borderRadius={3}
            pos="relative"
          >
            <ServiceTooltip
              text={organization?.services.s.find(service => service.id === serviceId)?.name ?? ''}
            >
              <Text fontSize="24px" fontWeight={400} noOfLines={1}>
                Add Alert Source(s) to{'  '}
                {organization?.services.s.find(service => service.id === serviceId)?.name}
              </Text>
            </ServiceTooltip>

            <Text fontSize="14px" mb={5}>
              Configure your alert sources to send in actionable and meaningful alerts. Use the
              integration guides to understand how to set them up. After configuration, you can also
              define templates for custom incident message and description for every alert source
            </Text>

            <Tabs variant="enclosed" index={activeTab} onChange={index => handleTabChange(index)}>
              <TabList>
                {tabs.map(tab => (
                  <Tab key={tab.key}>{tab.label}</Tab>
                ))}
              </TabList>

              <TabPanels>
                <TabPanel p={0}>
                  <Box paddingY={4}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <InputGroup>
                        <InputLeftElement pointerEvents="none">
                          <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input placeholder="Search" onChange={filteredAlertSourcessData} />
                      </InputGroup>
                      <Select
                        placeholder="All Integrations"
                        marginLeft={5}
                        variant="filled"
                        onChange={e => {
                          if (e.target.value) setIntegrationData(e.target.value);
                          else setIntegrationData('');
                        }}
                      >
                        {integrationListApiData &&
                          integrationListApiData?.map(
                            (item: { name: string; slug: string }, indx: number) => {
                              return (
                                <option key={indx} value={item.slug}>
                                  {item.name}
                                </option>
                              );
                            },
                          )}
                      </Select>
                    </Box>
                    <Box
                      maxHeight="calc(100vh - 22.6rem)"
                      overflowY="auto"
                      overflowX="hidden"
                      my={4}
                    >
                      {showableAlertSource && showableAlertSource?.length ? (
                        <>
                          {filterMapdata}
                          {isFallbackSearchForAlerts(showableAlertSource) && (
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              flexDirection="row"
                              py="8"
                            >
                              <Text sx={{ padding: '10px' }}>
                                Have more questions around alert sources? Use the chat widget below
                                to reach us.
                              </Text>
                            </Box>
                          )}
                        </>
                      ) : (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="row"
                          py="8"
                        >
                          <Text sx={{ padding: '10px' }}>
                            Have more questions around alert sources? Use the chat widget below to
                            reach us.
                          </Text>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </TabPanel>

                <TabPanel p={0}>
                  <Box paddingY={4}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box>
                        {serviceDetail && serviceDetail?.active_alert_sources && (
                          <AddAlertSourceListing
                            configuredAlertSourceList={(alertSourcesList ?? [])
                              .filter(integration =>
                                serviceDetail.active_alert_sources.find(
                                  alertSource => alertSource.id === integration._id,
                                ),
                              )
                              .map(integration => ({ ...integration, id: integration._id }))}
                            api_key={serviceDetail.api_key}
                            serviceId={serviceDetail.id}
                            selected={selected}
                            addAlertSource={addAlertSource}
                            email={serviceDetail?.email}
                            refetchKey={refetchDetail}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {serviceDetail && serviceDetail?.active_alert_sources?.length == 0 && (
                    <Box height="250px" width="450px" margin="auto">
                      <IllustrationBox
                        msg={
                          <PlaceholderText
                            message="No alert sources added"
                            tip={
                              'Tip: Alert sources are how services receive incidents. Add your first one to see the value of the platform.'
                            }
                          />
                        }
                        height="150px"
                        width="150px"
                        image={AlertSourceIllustration}
                        name="alert source"
                      />
                    </Box>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>

            <HStack
              paddingY={2}
              paddingX={2}
              borderTopWidth={1}
              borderTopStyle="solid"
              borderTopColor="gray.300"
              pos="absolute"
              bgColor="white"
              bottom="0"
              w="95%"
            >
              <Button onClick={goToServiceListingPage} variant="default" size="sm">
                {loader && <Spinner size="sm" />}
                Save All
              </Button>
              <Button
                onClick={() =>
                  history.push(serviceDetailUrl.replace(':serviceId', serviceId ?? ''))
                }
                variant="outline"
                ml={3}
                size="sm"
              >
                Cancel
              </Button>
            </HStack>
          </Box>

          <HelpGuide helpGuide={alertSourceHelpGuide} helpSupportWidth={helpGuideWidth} />
        </HStack>
      </Box>
    </Layout>
  );
}

export default connect((integrations: Integrations) => ({
  integrations,
}))(AlertSourceList);
