import qs from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const rotationKey = 'rotation';

const useGetEndRotationParams = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  return { openEndRotationModal: !!query.get(rotationKey) };
};

const mountEndRotationRoute = (pathname: string, search: string) => {
  return {
    pathname: pathname,
    search: qs.stringifyUrl({
      url: search,
      query: {
        [rotationKey]: true,
      },
    }),
  };
};

const unmountEndRotationRoute = (pathname: string, search: string) => {
  return {
    pathname: pathname,
    search: qs.exclude(search, [rotationKey]),
  };
};

export { useGetEndRotationParams, mountEndRotationRoute, unmountEndRotationRoute };
