import { Button } from '@chakra-ui/button';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import { MsTeamsIcon } from 'icons';
import { useWorkflowFormContext } from '../../../context/workflowFormContext';
import { useWorkflowPlan } from '../../../store/useWorkflowPlan';
import { ActionTypes } from '../../../types';
import { JiraBlueIcon, SlackIcon } from 'icons';
import { useCheckSlackStatus } from '../../../details/hooks/useCheckSlactStatus';
import { useCheckMsTeamStatus } from '../../../details/hooks/useCheckMsTeamStatus';

import { useMemo } from 'react';
import { useCheckJiraStatus } from '../../../details/hooks/useCheckJiraStatus';
import { ACTION_ICONS_MAP } from 'library/atoms/ActionsStack/constant';

const ActionTypeList = () => {
  const { workflowOrg } = useWorkflowPlan();
  const isSlackV2Enabled = workflowOrg.currentOrg.o?.config?.['slackv2'];
  const { data: slackIntegrated } = useCheckSlackStatus(!!isSlackV2Enabled);
  const { data: msTeamIntegrated } = useCheckMsTeamStatus();
  const { data: jiraStatus } = useCheckJiraStatus();

  const availableActions = useMemo(
    () => [
      {
        icon: ACTION_ICONS_MAP['md'][ActionTypes.SQUADCAST],
        label: 'Squadcast',
        isIntegrated: true,
        type: ActionTypes.SQUADCAST,
      },
      {
        icon: <SlackIcon height={30} width={30} />,
        label: 'Slack',
        isIntegrated: slackIntegrated === 'integrated',
        type: ActionTypes.SLACK,
      },
      {
        icon: <JiraBlueIcon height={30} width={30} />,
        label: 'Jira',
        isIntegrated: jiraStatus && !!jiraStatus?.length,
        type: ActionTypes.JIRA,
      },
      {
        icon: <MsTeamsIcon height={30} width={30} />,
        label: 'Microsoft Teams',
        isIntegrated: msTeamIntegrated?.connected_teams.length,
        type: ActionTypes.MS_TEAMS,
      },
    ],

    [slackIntegrated, jiraStatus, msTeamIntegrated],
  );

  const { openDrawer, actions } = useWorkflowFormContext();

  const { workflowActionPlan } = useWorkflowPlan();

  const isActionLimitReached = () => {
    if (workflowActionPlan?.unlimited) {
      return false;
    }

    return (workflowActionPlan?.quantity ?? 0) <= actions.length;
  };

  return (
    <VStack alignItems="flex-start">
      <Text fontSize="15px" fontWeight="800" color="secondary.700">
        Select to add {actions.length > 0 && 'another'} action
      </Text>
      <HStack>
        {availableActions.map(action => (
          <Button
            leftIcon={action.icon}
            onClick={() => openDrawer(action.type)}
            key={action.label}
            variant="outline"
            disabled={isActionLimitReached()}
          >
            <Text fontSize="12px">
              {action.label}
              {!action.isIntegrated && (
                <Text display="inline" layerStyle="disabled">
                  {' '}
                  (yet to integrate)
                </Text>
              )}
            </Text>
          </Button>
        ))}
      </HStack>
    </VStack>
  );
};

export default ActionTypeList;
