import { QueryKey, useQuery } from 'react-query';
import { Failure } from './Failure';
import { key } from './ReactQuery';
import { Session, useSession } from './Session';

type QueryFn<TId, TData> = (session: Session, id: TId) => Promise<TData>;

type UseDetailProps<TId, TData> = {
  baseQueryKey: QueryKey;
  id: TId;
  queryFn: QueryFn<TId, TData>;
};

export function useDetail<TId, TData>({ baseQueryKey, queryFn, id }: UseDetailProps<TId, TData>) {
  const { session } = useSession();
  const queryKey = key(baseQueryKey, id);
  const query = useQuery<TData, Failure>(queryKey, () => queryFn(session, id));

  return {
    query,
  };
}
