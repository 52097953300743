import { Para } from 'uie/components';
import {
  SelectedFilterTag,
  SelectedFilterTags,
} from 'views/main/organization/owner-filters/SelectedFilterTags';
import { Owner } from 'views/main/organization/owner-filters/hooks/useEntityOwnerFilter';

type Props = {
  activeFilterTags: SelectedFilterTag[];
  removeFilter: (_: Owner, id: string) => void;
};

export const ActiveFilterTags: React.FC<Props> = ({ activeFilterTags, removeFilter }) => {
  if (activeFilterTags.length === 0) return null;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
      }}
    >
      <Para
        style={{
          color: '#3D485E',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '150%',
          minWidth: '60px',
        }}
      >
        Owner :
      </Para>
      <SelectedFilterTags
        tags={activeFilterTags}
        onClose={removeFilter}
        visibleTagsCount={3}
        applyChakraStyle={false}
      />
    </div>
  );
};
