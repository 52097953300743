import { ArrowBackIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Center,
  ChakraProvider,
  Circle,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  SimpleGrid,
  Skeleton,
  Spacer,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { Bar } from '@nivo/bar';
import { fontTheme } from 'library/theme';
import _ from 'lodash';
import moment from 'moment';
import { memo, useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link as RLink, RouteChildrenProps, useHistory } from 'react-router-dom';
import { CustomTable } from 'views/shared/components';

import CalendarRangePicker from '../../../../components/CalendarRangePicker';
import {
  T_WA_UP_MARKED_SLO_AFFECTED,
  T_WA_UP_SLO_OVERVIEW_FALSE_POSITIVES_PAGE_VISITED,
  T_WA_UP_SLO_OVERVIEW_INCIDENTS_PAGE_VISITED,
  T_WA_UP_SLO_OVERVIEW_INSIGHTS_PAGE_VISITED,
  T_WA_UP_SLO_OVERVIEW_MARKED_FALSE_POSITIVE,
  T_WA_UP_SLO_OVERVIEW_PAGE_VISITED,
} from '../../../../core/const/tracker';
import { IAppState } from '../../../../core/interfaces/IAppState';
import { EditIcon } from '../../../../icons';
import NoData from '../../../../icons/no_data_found.png';
import { AppTracker } from '../../../../shared/analytics/tracker';
import { ResponsivePieComponent } from '../../../shared/components/PieChart';
import { SliListing } from './common/SliPloting';
import { TableRows } from './common/TableRows';
import SLOOverviewFilter from './filters/sloOverview.filters';
import {
  useFalsePositiveIncident,
  useSloViolating,
  useMarkFalsePositive,
  useMarkViolating,
} from './hooks/useMarkIncident';
import { useSloDetails } from './hooks/useSloOverviewData';
import {
  cardStyling,
  falsePositiveIncidentList,
  sloOverviewFalsePositiveFilterConst,
  sloOverviewFilterConst,
  sloViolatingIncidentList,
  tooltipStyle,
} from './slo.constants';
import { getIsActive } from './slo.helper';
import { ISelectedOverviewFilterOptions, IsloInSights, ISloOverview } from './slo.interface';
import qs from 'query-string';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { useCompareGlobalAndEntityTeamId } from 'core/hooks/useCompareGlobalAndEntityTeamId';

type IMix = Pick<IAppState, 'organization'> & RouteChildrenProps<{ id: string; mode: string }>;
export type IServices = IAppState['organization']['services'];
export type IUsers = IAppState['organization']['users'];
type SloOverViewData = { slo: ISloOverview; insights: IsloInSights };

const MyResponsiveBar = (props: any) => {
  return (
    <Box maxWidth="85vw" overflowX="scroll" border="1px solid lightgray" mt={4}>
      {props.data && props.data.length > 0 ? (
        <Bar
          height={300}
          width={props.data.length === 1 ? props.data.length * 300 : props.data.length * 250}
          data={props.data}
          keys={['incident']}
          indexBy="date"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={{ scheme: 'nivo' }}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#eed312',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Error budget spent (in secs)',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
        />
      ) : (
        <Box textAlign="center">
          <Text fontWeight="medium">No Data Available</Text>
        </Box>
      )}
    </Box>
  );
};

const SloOverView = (props: IMix) => {
  const history = useHistory();
  const prevRef = useRef<string>();
  const sloID = (props as any).match.params.sloid as number;

  const hasUpdateAccess = useUserAccess().hasUpdateAccess;
  const hasUpdate = hasUpdateAccess('slos', `${sloID}`);
  const {
    results: [sloOverviewFullData, sliList, Metrics],
    setSloViolatingOverviewFilters,
    setSloFalsePositiveFilters,
    setOffset_,
    setLimit_,
    setOffset,
    setLimit,
    pageLimit_,
    pageLimit,
    pageOffset,
    pageOffset_,
    totalErrorBudget,
    filters,
    setFilter,
    pagination,
    pagination_,
    totalNoOfPages,
    totalNoOfPages_,
    sloViolatingOverviewFilters,
    setPaginationFn,
    sloFalsePositiveFilters,
  } = useSloDetails(sloID, props.organization.selectedTeam.teamId);

  const { markAsFalsePositive, checkedSloInc, setForSloAffected } = useMarkFalsePositive(
    props.organization.selectedTeam.teamId,
  );
  // writing these 2 queries- [SloViolating and falsePositiveIncident] outside the useSloDetails because it was badly written and was hitting same api multiple (6) times
  const {
    data: sloViolatingData,
    isLoading: sloViolatingDataLoading,
    isError: sloViolatingDataError,
    isSuccess: sloViolatingDataSuccess,
  } = useSloViolating(
    sloID,
    pageOffset_,
    pageLimit_,
    sloViolatingOverviewFilters,
    filters,
    props.organization.selectedTeam.teamId,
    pagination,
    totalNoOfPages,
    setPaginationFn,
  );

  const {
    data: falsePositiveIncidentData,
    isLoading: falsePositiveIncidentDataLoading,
    isError: falsePositiveIncidentDataError,
    isSuccess: falsePositiveIncidentDataSuccess,
  } = useFalsePositiveIncident(
    sloID,
    pageOffset,
    pageLimit,
    sloFalsePositiveFilters,
    filters,
    props.organization.selectedTeam.teamId,
    pagination_,
    totalNoOfPages_,
    setPaginationFn,
  );

  const { markAssloViolating, setFalsePositive, checkedfalseInc } = useMarkViolating(
    props.organization.selectedTeam.teamId,
  );

  const [isNoFilterSelected, setIsNoFilterSelected] = useState<boolean>(false);
  const { slo: _sloOverview, insights: _sloInsights }: SloOverViewData =
    sloOverviewFullData?.data || ({} as SloOverViewData);
  const sloOverview = {
    data: _sloOverview,
    isSuccess: sloOverviewFullData?.isSuccess,
    isLoading: sloOverviewFullData?.isLoading,
  };
  const sloInsights = {
    data: _sloInsights,
    isSuccess: sloOverviewFullData?.isSuccess,
    isLoading: sloOverviewFullData?.isLoading,
  };

  const durationDays = moment
    .duration(moment(sloOverview?.data?.end_time).diff(moment(sloOverview?.data?.start_time)))
    .asDays();

  const getTags = (tags: { [k: string]: any }) => {
    const isTag = tags;

    return isTag
      ? Object.keys(tags).map(badge => {
          return (
            <Badge
              bg="#f3f4f6"
              key={badge}
              p={2}
              fontWeight="normal"
            >{`${badge} : ${tags[badge]}`}</Badge>
          );
        })
      : null;
  };

  const mixpaneltab = (e: any) => {
    history.push({
      pathname: history.location.pathname,
      search: qs.stringifyUrl({
        url: history.location.search,
        query: {
          queryType: e,
        },
      }),
    });
    if (e === 0) {
      AppTracker.track(T_WA_UP_SLO_OVERVIEW_INCIDENTS_PAGE_VISITED);
    } else if (e === 1) {
      AppTracker.track(T_WA_UP_SLO_OVERVIEW_INSIGHTS_PAGE_VISITED);
    } else if (e === 2) {
      AppTracker.track(T_WA_UP_SLO_OVERVIEW_FALSE_POSITIVES_PAGE_VISITED);
    }
  };
  const getFilteredIncidents =
    (typeFilter: 'VIOLATE' | 'FALSEPOSITIVE') =>
    (selectedFilter: Array<string> = [], selectedFilterOpt?: ISelectedOverviewFilterOptions) => {
      if (selectedFilterOpt) {
        const queryParam: { [key: string]: string } = { ...sloViolatingOverviewFilters };
        const isService = selectedFilter.some(_ => _ == 'service');
        const isSli = selectedFilter.some(_ => _ == 'Slis');
        const isDateTime = selectedFilter.some(_ => _ == 'dateTime');

        if (isService) {
          selectedFilterOpt.service.forEach(element => {
            queryParam['service_id'] = element;
          });
        }

        if (isSli) {
          selectedFilterOpt.sli.forEach(sli => {
            queryParam['Slis'] = sli;
          });
        }

        if (isDateTime) {
          queryParam['fromDate'] = moment(selectedFilterOpt.dateTime.fromDate).toISOString();

          queryParam['toDate'] = moment(selectedFilterOpt.dateTime.toDate).toISOString();
        }

        setIsNoFilterSelected(!selectedFilter.length);
        const { fromDate, toDate, ...rest } = queryParam;
        if (Object.keys(rest).length) {
          setFilter({ ...filters, rest });
        }
        typeFilter == 'VIOLATE'
          ? (() => {
              setSloViolatingOverviewFilters(queryParam);
            })()
          : setSloFalsePositiveFilters(queryParam);
      }
    };

  useEffect(() => {
    if (prevRef.current && prevRef.current !== props.organization.selectedTeam.teamId) {
      props.history.push('/slo');
    }
    AppTracker.track(T_WA_UP_SLO_OVERVIEW_PAGE_VISITED);
  }, [props.organization.selectedTeam.teamId]);

  useEffect(() => {
    prevRef.current = props.organization.selectedTeam.teamId;
  }, []);

  useEffect(() => {
    let fromDate = new Date();
    if (sloOverview.isSuccess) {
      if (sloOverview.data.time_interval_type === 'fixed') {
        fromDate = new Date(sloOverview.data.start_time);
      } else if (sloOverview.data.time_interval_type === 'rolling') {
        if (
          moment().subtract(sloOverview.data.duration_in_days, 'days').toDate() <
          new Date(sloOverview.data.created_at)
        ) {
          fromDate = new Date(sloOverview.data.created_at);
        } else {
          fromDate = moment().subtract(sloOverview.data.duration_in_days, 'days').toDate();
        }
      }
      setFilter({
        ...filters,
        dateTime: { fromDate: fromDate, toDate: new Date(new Date().setHours(23, 59, 59, 999)) },
      });
    }
  }, [sloOverview?.data?.created_at]);

  return (
    <ChakraProvider theme={fontTheme}>
      <Box m={5}>
        <Container maxW="full">
          <Text fontWeight="medium" size="xs">
            <RLink to="/slo">
              {<ArrowBackIcon />}
              {'SLO Overview'}
            </RLink>
          </Text>
          {
            <HStack spacing={5} mt={2} alignItems="center" justifyContent="flex-start">
              <Text fontSize="27px" color="#1D426B">
                {sloOverview.isSuccess ? sloOverview.data.name : '-'}
              </Text>
              <NoPermissionTooltip isDisabled={hasUpdate}>
                <IconButton
                  colorScheme="blackAlpha"
                  icon={<EditIcon width="16px" />}
                  style={{ marginLeft: '0px' }}
                  variant="ghost"
                  aria-label="Edit member"
                  disabled={!sloOverview?.data?.is_active || false || !hasUpdate}
                  onClick={() => history.push(`/slo/edit/${sloOverview.data.id}`)}
                />
              </NoPermissionTooltip>
              {sloOverview?.data?.is_active && (
                <Flex>
                  <Text fontSize={14} fontWeight={500}>
                    {'SLO Health :'}
                  </Text>
                  <HStack marginLeft={2}>
                    <Circle
                      size="10px"
                      bg={
                        sloOverview.isSuccess
                          ? sloOverview.data.is_healthy
                            ? 'green'
                            : 'red'
                          : '-'
                      }
                      color="white"
                      m={0}
                    />

                    <Text
                      fontWeight="bold"
                      m={0}
                      color={sloOverview.isSuccess && sloOverview.data.is_healthy ? 'green' : 'red'}
                      fontSize={14}
                    >
                      {sloOverview.isSuccess
                        ? sloOverview.data.is_healthy
                          ? 'Healthy'
                          : 'Needs Attention'
                        : '-'}
                    </Text>
                  </HStack>
                </Flex>
              )}
              <Flex>
                <Text fontSize={14} fontWeight={500}>
                  {'SLO Status :'}
                </Text>
                <HStack marginLeft={2}>
                  <Circle
                    size="10px"
                    bg={getIsActive(sloOverview?.data?.is_active)['color']}
                    color="white"
                  />
                  <Text fontWeight="bold" fontSize={14} color="muted">
                    {getIsActive(sloOverview?.data?.is_active)['value']}
                  </Text>
                </HStack>
              </Flex>
            </HStack>
          }
          {sloOverview.isLoading && (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          )}
          {sloOverview.data && <HStack mt={3}>{getTags(sloOverview.data.tags)}</HStack>}
          {sloOverview.isSuccess && (
            <>
              <HStack mb={4} spacing="1em">
                <SimpleGrid columns={{ xl: 4, md: 4 }} width="100%">
                  <Box height="100%" padding={3}>
                    <Wrap spacing="20px">
                      <WrapItem width="100%">
                        <Stat size="lg">
                          <StatLabel fontWeight="bold" mb={2}>
                            TARGET SLO(%)
                            <Tooltip
                              hasArrow
                              label={
                                'Target SLO indicates the percentage value set to target performance compliance.'
                              }
                              {...tooltipStyle}
                            >
                              <InfoIcon ml={1} />
                            </Tooltip>
                          </StatLabel>
                          <StatNumber fontWeight="normal">{sloOverview.data.target_slo}</StatNumber>
                        </Stat>
                      </WrapItem>
                    </Wrap>
                  </Box>
                  <Box height="100%" padding={3}>
                    <Wrap spacing="20px">
                      <WrapItem width="100%">
                        <Stat size="lg">
                          <StatLabel fontWeight="bold" mb={2}>
                            TOTAL ERROR BUDGET(MINS)
                            <Tooltip
                              hasArrow
                              label={
                                'Error Budget indicates the entire time period for which a system can fail without violating the SLO.'
                              }
                              {...tooltipStyle}
                            >
                              <InfoIcon ml={1} />
                            </Tooltip>
                          </StatLabel>
                          <StatNumber fontWeight="normal">
                            {sloOverview?.data?.allocated_error_budget.toFixed(2)}
                          </StatNumber>
                        </Stat>
                      </WrapItem>
                    </Wrap>
                  </Box>
                  <Box height="100%" padding={3} paddingLeft={10}>
                    <Wrap spacing="20px">
                      <WrapItem width="100%">
                        <Stat size="lg">
                          <StatLabel fontWeight="bold" mb={2}>
                            TIME WINDOW
                            <Tooltip
                              hasArrow
                              label={
                                'Time Window indicates the extent of time for which the SLO has been set. It can be on a rolling (or continuous) basis or on a fixed basis (eg, once a quarter).'
                              }
                              {...tooltipStyle}
                            >
                              <InfoIcon ml={1} />
                            </Tooltip>
                          </StatLabel>
                          <StatNumber fontWeight="normal">
                            {_.capitalize(sloOverview.data.time_interval_type)}
                          </StatNumber>
                        </Stat>
                      </WrapItem>
                    </Wrap>
                  </Box>
                  <Box height="100%" padding={3}>
                    <Wrap spacing="20px">
                      <WrapItem width="100%">
                        <Stat size="lg">
                          <StatLabel fontWeight="bold" mb={2}>
                            DURATION(DAYS)
                          </StatLabel>
                          <StatNumber fontWeight="normal">
                            {Number.isInteger(durationDays) == true &&
                            sloOverview?.data?.time_interval_type === 'fixed'
                              ? `${new Date(sloOverview.data.start_time).toLocaleDateString(
                                  'en-us',
                                  {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                  },
                                )} - ${new Date(sloOverview.data.end_time).toLocaleDateString(
                                  'en-us',
                                  {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                  },
                                )} (${moment
                                  .duration(
                                    moment(sloOverview?.data?.end_time).diff(
                                      moment(sloOverview?.data?.start_time).subtract(1, 'day'),
                                    ),
                                  )
                                  .asDays()})`
                              : sloOverview?.data?.time_interval_type === 'rolling'
                              ? sloOverview?.data?.duration_in_days
                              : durationDays.toFixed(4)}
                          </StatNumber>
                        </Stat>
                      </WrapItem>
                    </Wrap>
                  </Box>
                </SimpleGrid>
              </HStack>
              <Divider mb={4} borderColor={'#2F3961'} />
              <HStack>
                <Spacer />
                <Box>
                  <CalendarRangePicker
                    startDate={filters.dateTime.fromDate}
                    endDate={filters.dateTime.toDate}
                    onUpdateRange={(fromDate: Date, toDate: Date) => {
                      setFilter({
                        ...filters,
                        dateTime: { fromDate, toDate: new Date(toDate.setHours(23, 59, 59, 999)) },
                      });
                    }}
                    minDate={moment().subtract(1, 'years').toDate()}
                    maxDate={moment().add(1, 'years').toDate()}
                    isFuture={false}
                  />
                </Box>
              </HStack>
              <HStack mt={4} spacing="1em" justifyContent="center">
                <SimpleGrid columns={{ xl: 3, md: 2 }}>
                  <Box height="100%" marginBottom="15px">
                    <Wrap spacing="10px">
                      <WrapItem width="45%">
                        <Box w="100%" boxShadow="md" rounded="md" bg="white" height="120px" p="4">
                          <Stat size="lg">
                            <StatLabel fontWeight="normal" mb={2} textAlign="center">
                              Current SLO (%)
                            </StatLabel>
                            <StatNumber textAlign="center">
                              {Metrics.isSuccess && Metrics.data
                                ? Metrics.data.current_slo.toFixed(2)
                                : 0}
                            </StatNumber>
                          </Stat>
                        </Box>
                      </WrapItem>
                      <WrapItem width="45%">
                        <Box
                          w="100%"
                          boxShadow="md"
                          rounded="md"
                          bg="white"
                          height="120px"
                          py="4"
                          px="2"
                        >
                          <Stat size="lg">
                            <StatLabel fontWeight="normal" mb={2} textAlign="center">
                              Error Budget Consumed(mins)
                              <Tooltip
                                hasArrow
                                label={
                                  'Indicates the Error Budget consumed in the selected time range. This accounts for overlapping incidents as well.'
                                }
                                {...tooltipStyle}
                              >
                                <InfoIcon ml={1} />
                              </Tooltip>
                            </StatLabel>
                            <StatNumber textAlign="center">
                              {Metrics.isSuccess && Metrics.data
                                ? Metrics.data.error_budget_spent.toFixed(2)
                                : 0}
                            </StatNumber>
                          </Stat>
                        </Box>
                      </WrapItem>
                      <WrapItem width="45%">
                        <Box w="100%" boxShadow="md" rounded="md" bg="white" height="120px" p="4">
                          <Stat size="lg">
                            <StatLabel fontWeight="normal" mb={2} textAlign="center">
                              MTTA(mins)
                              <Tooltip
                                hasArrow
                                label={
                                  'Mean time taken to acknowledge the SLO-violating incidents, for the selected time range.'
                                }
                                {...tooltipStyle}
                              >
                                <InfoIcon ml={1} />
                              </Tooltip>
                            </StatLabel>
                            <StatNumber textAlign="center">
                              {Metrics.isSuccess && Metrics.data ? Metrics.data.mtta.toFixed(2) : 0}
                            </StatNumber>
                          </Stat>
                        </Box>
                      </WrapItem>
                      <WrapItem width="45%">
                        <Box w="100%" boxShadow="md" rounded="md" bg="white" height="120px" p="4">
                          <Stat size="lg">
                            <StatLabel fontWeight="normal" mb={2} textAlign="center">
                              MTTR(mins)
                              <Tooltip
                                hasArrow
                                label={
                                  'Mean time taken to resolve the SLO-violating incidents, for the selected time range.'
                                }
                                {...tooltipStyle}
                              >
                                <InfoIcon ml={1} />
                              </Tooltip>
                            </StatLabel>
                            <StatNumber textAlign="center">
                              {Metrics.isSuccess && Metrics.data ? Metrics.data.mttr.toFixed(2) : 0}
                            </StatNumber>
                          </Stat>
                        </Box>
                      </WrapItem>
                    </Wrap>
                  </Box>
                  <Center
                    boxShadow="md"
                    rounded="md"
                    bg="white"
                    height="250px"
                    marginBottom="15px"
                    marginRight="15px"
                  >
                    <VStack>
                      <Text sx={{ ...cardStyling }} marginBottom={5}>
                        Error Budget(mins)
                      </Text>
                      <ResponsivePieComponent
                        height={200}
                        data={{
                          items: [
                            {
                              id: 'Consumed',
                              label: 'Consumed',
                              value:
                                Metrics.isSuccess && Metrics.data
                                  ? Metrics.data.error_budget_spent.toFixed(2)
                                  : 0,
                            },
                            {
                              id: 'Remaining',
                              label: 'Remaining',
                              value:
                                sloOverview.data &&
                                sloOverview.data.allocated_error_budget &&
                                Metrics.data
                                  ? (
                                      sloOverview.data?.allocated_error_budget -
                                      Metrics.data.error_budget_spent
                                    ).toFixed(2)
                                  : 0,
                            },
                          ],
                        }}
                        colors_={['paired', 'accent', 'category10'][1]}
                      />
                    </VStack>
                  </Center>
                  <Center boxShadow="md" rounded="md" bg="white" height="250px" marginBottom="15px">
                    <VStack>
                      <Text sx={{ ...cardStyling }}>% of SLO Affected Incidents</Text>
                      <Text size="xs" fontSize={14} textAlign={'center'}>
                        Total Incidents({Metrics.data?.total_incidents})
                      </Text>
                      <ResponsivePieComponent
                        height={200}
                        data={{
                          items: [
                            {
                              id: 'SLO Affected Incidents',
                              label: `SLO Affected Incidents(${Metrics.data?.slo_violating_incidents})`,
                              value:
                                Metrics.data &&
                                Metrics.data.total_incidents &&
                                Metrics.data.slo_violating_incidents
                                  ? (
                                      (Metrics.data.slo_violating_incidents /
                                        Metrics.data.total_incidents) *
                                      100
                                    ).toFixed(2)
                                  : 0,
                            },
                            {
                              id: 'Remaining Incidents',
                              label: `Remaining Incidents (${
                                Metrics.data && Metrics.data.total_incidents
                                  ? Metrics.data.slo_violating_incidents
                                    ? Metrics.data.total_incidents -
                                      Metrics.data.slo_violating_incidents
                                    : Metrics.data.total_incidents
                                  : 0
                              })`,
                              value:
                                Metrics.data && Metrics.data.total_incidents
                                  ? Metrics.data.slo_violating_incidents
                                    ? (
                                        ((Metrics.data.total_incidents -
                                          Metrics.data.slo_violating_incidents) /
                                          Metrics.data.total_incidents) *
                                        100
                                      ).toFixed(2)
                                    : 100
                                  : 0,
                            },
                          ],
                        }}
                        colors_={'paired'}
                        percentage={true}
                      />
                    </VStack>
                  </Center>
                </SimpleGrid>
              </HStack>
            </>
          )}
          {!sloViolatingDataLoading || !falsePositiveIncidentDataLoading ? (
            <>
              {(
                isNoFilterSelected
                  ? (sloViolatingData?.slo_violating_incidents &&
                      sloViolatingData?.slo_violating_incidents?.length > 0) ||
                    (falsePositiveIncidentData?.slo_violating_incidents &&
                      falsePositiveIncidentData?.slo_violating_incidents?.length > 0)
                  : sloOverview?.data?.incident_count
              ) ? (
                <Box>
                  {sliList.isFetched && (
                    <SliListing
                      sliList={sliList.data}
                      totalErrBudget={totalErrorBudget}
                      serviceIds={sloOverview.data.service_ids}
                      isError={sliList.isError}
                      orgServices={props.organization.services}
                    />
                  )}
                  {sliList.isLoading && (
                    <Stack m={3}>
                      <Skeleton height="20px" />
                      <Skeleton height="20px" />
                      <Skeleton height="20px" />
                      <Skeleton height="20px" />
                    </Stack>
                  )}
                  <Box mt={5}>
                    <Tabs size="md" variant="enclosed" onChange={mixpaneltab}>
                      <TabList>
                        <Tab _focus={{ outline: 'none' }}>Incidents</Tab>
                        <Box>
                          <Tab _focus={{ outline: 'none' }}>False Positives</Tab>
                        </Box>
                        <Tab _focus={{ outline: 'none' }}>Insights</Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          {
                            <>
                              <Flex
                                direction={'row'}
                                justifyContent={`${
                                  sloViolatingData?.slo_violating_incidents?.length
                                    ? 'space-between'
                                    : 'end'
                                }`}
                                alignItems={'center'}
                              >
                                {sloViolatingData &&
                                sloViolatingData.slo_violating_incidents.length ? (
                                  <Box>
                                    <HStack>
                                      <Box>{sloOverview && sloOverview.data?.incident_count}</Box>
                                      <Box marginLeft={2}>Incident(s)</Box>
                                    </HStack>
                                  </Box>
                                ) : (
                                  ''
                                )}
                                <NoPermissionTooltip isDisabled={hasUpdate}>
                                  <Button
                                    mr={1}
                                    onClick={() => {
                                      checkedSloInc &&
                                        markAsFalsePositive({
                                          sloId: sloID,
                                          incidentId: checkedSloInc[0],
                                        });
                                      AppTracker.track(T_WA_UP_SLO_OVERVIEW_MARKED_FALSE_POSITIVE);
                                    }}
                                    alignSelf={'flex-end'}
                                    borderColor="rgba(15, 97, 221, 1)"
                                    color="rgba(15, 97, 221, 1)"
                                    variant="outline"
                                    fontWeight="medium"
                                    isDisabled={!hasUpdate || !checkedSloInc.length}
                                  >
                                    Mark As False Positive
                                  </Button>
                                </NoPermissionTooltip>
                              </Flex>
                              {sloViolatingDataSuccess && sloViolatingData && (
                                <>
                                  {sloOverview &&
                                  sloOverview.data?.incident_count -
                                    sloOverview.data?.false_positive_count >
                                    0 ? (
                                    <SLOOverviewFilter
                                      services={props.organization.services.s}
                                      filterList={sloOverviewFilterConst}
                                      slis={sliList.data}
                                      getData={getFilteredIncidents('VIOLATE')}
                                    />
                                  ) : null}
                                  {sloViolatingData?.slo_violating_incidents.length > 0 && (
                                    <>
                                      <CustomTable
                                        pageOffset={pageOffset_}
                                        pageLimit={pageLimit_}
                                        pagination={pagination_}
                                        changeLimit={limit => {
                                          setLimit_(limit);
                                        }}
                                        changeOffset={offset => {
                                          setOffset_(offset);
                                        }}
                                        tableHeader={sloViolatingIncidentList}
                                        totalPages={totalNoOfPages_}
                                      >
                                        {sloViolatingData?.slo_violating_incidents.map(
                                          (member, index) => (
                                            <TableRows
                                              isDisabled={!hasUpdate}
                                              key={index}
                                              member={member}
                                              checkedInc={checkedSloInc}
                                              checkInc={(incIds: Array<number>) => {
                                                setForSloAffected(incIds);
                                                AppTracker.track(T_WA_UP_MARKED_SLO_AFFECTED);
                                              }}
                                              organization={props.organization}
                                              type="violating"
                                            />
                                          ),
                                        )}
                                      </CustomTable>
                                    </>
                                  )}
                                </>
                              )}

                              {(sloViolatingDataError ||
                                (sloViolatingData &&
                                  sloViolatingData.slo_violating_incidents.length == 0)) && (
                                <Center
                                  p={2}
                                  m={2}
                                  shadow="md"
                                  borderWidth="1px"
                                  textAlign="center"
                                >
                                  <Text fontWeight="medium">No Incidents Available</Text>
                                </Center>
                              )}

                              {sloViolatingDataLoading && (
                                <Stack>
                                  <Skeleton height="20px" />
                                  <Skeleton height="20px" />
                                  <Skeleton height="20px" />
                                  <Skeleton height="20px" />
                                </Stack>
                              )}
                            </>
                          }
                        </TabPanel>
                        <TabPanel>
                          {
                            <>
                              <Flex
                                direction={'row'}
                                justifyContent={`${
                                  falsePositiveIncidentData?.slo_violating_incidents?.length
                                    ? 'space-between'
                                    : 'end'
                                }`}
                                alignItems={'center'}
                              >
                                {falsePositiveIncidentData?.slo_violating_incidents &&
                                falsePositiveIncidentData.slo_violating_incidents.length > 0 ? (
                                  <Box>
                                    <HStack>
                                      <Box>
                                        {sloOverview && sloOverview?.data?.false_positive_count}
                                      </Box>
                                      <Box marginLeft={2}>Incident(s)</Box>
                                    </HStack>
                                  </Box>
                                ) : (
                                  ''
                                )}
                                <NoPermissionTooltip isDisabled={hasUpdate}>
                                  <Button
                                    mt={1}
                                    disabled={!checkedfalseInc.length}
                                    onClick={() =>
                                      checkedfalseInc &&
                                      markAssloViolating({
                                        sloId: sloID,
                                        incidentId: checkedfalseInc[0],
                                      })
                                    }
                                    alignSelf={'flex-end'}
                                    borderColor="rgba(15, 97, 221, 1)"
                                    color="rgba(15, 97, 221, 1)"
                                    variant="outline"
                                    fontWeight="medium"
                                    isDisabled={!hasUpdate}
                                  >
                                    SLO Affected
                                  </Button>
                                </NoPermissionTooltip>
                              </Flex>
                              {falsePositiveIncidentDataSuccess && falsePositiveIncidentData && (
                                <>
                                  {sloOverview && sloOverview?.data?.false_positive_count ? (
                                    <SLOOverviewFilter
                                      services={props.organization.services.s}
                                      filterList={sloOverviewFalsePositiveFilterConst}
                                      getData={getFilteredIncidents('FALSEPOSITIVE')}
                                    />
                                  ) : null}
                                  {falsePositiveIncidentData.slo_violating_incidents.length > 0 && (
                                    <CustomTable
                                      pageOffset={pageOffset}
                                      pageLimit={pageLimit}
                                      pagination={pagination}
                                      changeLimit={limit => {
                                        setLimit(limit);
                                      }}
                                      changeOffset={offset => {
                                        setOffset(offset);
                                      }}
                                      tableHeader={falsePositiveIncidentList}
                                      totalPages={totalNoOfPages}
                                    >
                                      {falsePositiveIncidentData.slo_violating_incidents.map(
                                        (member, index) => (
                                          <TableRows
                                            isDisabled={!hasUpdate}
                                            key={index}
                                            member={member}
                                            checkedInc={checkedfalseInc}
                                            checkInc={(incIds: Array<number>) => {
                                              setFalsePositive(incIds);
                                            }}
                                            organization={props.organization}
                                            type="falsePositive"
                                          />
                                        ),
                                      )}
                                    </CustomTable>
                                  )}
                                </>
                              )}

                              {(falsePositiveIncidentDataError ||
                                (falsePositiveIncidentData &&
                                  falsePositiveIncidentData.slo_violating_incidents.length ==
                                    0)) && (
                                <Center
                                  p={2}
                                  m={2}
                                  shadow="md"
                                  borderWidth="1px"
                                  textAlign="center"
                                >
                                  <Text fontWeight="medium">No Incidents Available</Text>
                                </Center>
                              )}

                              {falsePositiveIncidentDataLoading && (
                                <Stack>
                                  <Skeleton height="20px" />
                                  <Skeleton height="20px" />
                                  <Skeleton height="20px" />
                                  <Skeleton height="20px" />
                                </Stack>
                              )}
                            </>
                          }
                        </TabPanel>
                        <TabPanel>
                          <Heading
                            style={{
                              marginTop: '10px',
                              fontSize: '14px',
                              color: '#808080',
                              fontWeight: 600,
                            }}
                          >
                            Insights indicate the error budget spent by incidents affecting this SLO
                            over a period of time.
                          </Heading>

                          <MyResponsiveBar
                            data={
                              Array.isArray(sloViolatingData?.slo_violating_incidents)
                                ? sloViolatingData?.slo_violating_incidents.map(incident => {
                                    return {
                                      date: moment(incident.assigned_to.timeOfAssignment).format(
                                        'DD/MM/YYYY, h:mm:ss a',
                                      ),
                                      incident: incident.error_budget_spent.toFixed(2),
                                    };
                                  })
                                : []
                            }
                          />
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </Box>
                </Box>
              ) : (
                <Box
                  flexDirection="row"
                  justifyContent="center"
                  width="full"
                  display="flex"
                  marginTop="20px"
                >
                  <Image src={NoData} alt="No Data Found" />
                </Box>
              )}
            </>
          ) : (
            <Box justifyContent="center">
              <Stack m={3}>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            </Box>
          )}
        </Container>
      </Box>
    </ChakraProvider>
  );
};

export default connect(({ organization, APP_CONFIG }: IAppState) => ({
  organization,
  APP_CONFIG,
}))(memo(SloOverView));
