import './index.css';

import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { CopyIconButton, MdBlock } from 'uie/components';
import { FileUploadFeature, OperationType } from 'core/services/service.fileUpload';
import { FieldArray } from 'formik';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import * as React from 'react';
import DatePicker from 'react-date-picker';
import ReactMarkdown from 'react-markdown';
import TimePicker from 'react-time-picker';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import styled from 'styled-components';

import { FileUploadService } from '../../../../../../../core/services';
import { IMessageTemplate, IStatusIssueState, IStatusIssueStateMessage } from '../../../Interface';
import { StepCircle } from './StepCircle';
import { fileUploadViewConfig } from '../../../constants/schema';

const { imageTexts, ...fileUploadConfig } = fileUploadViewConfig;

const TimePickerGrid = styled(Flex)`
  border-radius: 6px;
  border: solid 1px #e7ecf5;
  margin-top: 6px;
  padding: 0px 16px;
`;
interface StepProps extends BoxProps {
  step: IStatusIssueState;
  template: IMessageTemplate;
  isUpdateFlow: boolean;
  idx: number;
  timezone: string;
  setFieldValue: (id: string, value: string) => void;
  onIssueStateChange: () => void;
  stateMessageCount: number;
  onDelete: (stateIdx: number, msgIdx: number) => void;
  readOnly: boolean;
  showDisclaimer: boolean;
  isResolved: boolean;
}

const renderMdLink = (props: any) => (
  <a href={props.href} target="_blank" rel="noopener noreferrer">
    {props.children}
  </a>
);

const fileUploadService = new FileUploadService();

const CustomCodeRenderer = (props: any) => {
  return (
    <div className="code-container">
      <pre className="raw-code">{props.value}</pre>
      <div className="code-copy">
        <CopyIconButton type="transparent">{props.value}</CopyIconButton>
      </div>
    </div>
  );
};

export const Step = (props: StepProps) => {
  const {
    step,
    idx,
    onClick,
    template,
    isUpdateFlow,
    onIssueStateChange,
    onDelete,
    readOnly,
    showDisclaimer,
    isResolved,
  } = props;

  const orientation = useBreakpointValue<'horizontal' | 'vertical'>({
    base: 'vertical',
    md: 'vertical',
  });

  return (
    <HStack spacing="5" align="stretch" flex="1">
      <VStack spacing="0" align="center" justify={'start'}>
        <StepCircle
          isActive={step.issueMessages.length > 0}
          isCompleted={step.issueMessages.length > 0}
        />

        <Divider orientation={orientation} borderWidth="1px" />
      </VStack>

      <VStack spacing="0" align="flex-start" flex="1">
        <HStack mb={2} cursor="pointer" onClick={onClick}>
          <Text color="emphasized" fontWeight="medium">
            {template.name || step.name}
          </Text>
        </HStack>

        {showDisclaimer ? (
          <>
            <Text fontSize="sm" color={'red.500'}>
              {isUpdateFlow
                ? 'Looks like your Page or some Components are still non-operational. You can change the state by clicking on Edit Issue Details on the top right corner'
                : 'Looks like your Page or some Components are still non-operational. You can change the page/component status above before you resolve the issue.'}
            </Text>
            <Text fontSize="sm" color={'red.500'}>
              Note: Resolving this would mean you are closing this issue and you will no longer be
              able to add new updates to this issue.
            </Text>
          </>
        ) : null}

        <FieldArray
          name={`issueStates.${idx}.issueMessages`}
          render={fieldArrayProps => (
            <Box w="98%">
              {step.issueMessages?.map((_message: IStatusIssueStateMessage, index: number) => {
                return (
                  <Flex key={index} mb={4} direction="column" flex="1" ml={3}>
                    <Flex justify={'flex-end'} mb="1" align="center">
                      <Popover closeOnBlur={false}>
                        {({ onClose }) => (
                          <>
                            <PopoverTrigger>
                              <Box as="span" color="primary.default" cursor="pointer">
                                <Button variant={'link'} name="date">
                                  {moment(
                                    moment(`${_message.timestamp}`)
                                      .tz(props.timezone)
                                      .format('DD-MMM-YYYY HH:mm'),
                                  ).calendar(null, { sameElse: 'Do MMM YY h:mm A' })}
                                  {` ${moment.tz(props.timezone).zoneAbbr()}`}
                                </Button>
                              </Box>
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverCloseButton />
                              <PopoverBody>
                                <Box display={'flex'} flexDirection="row" gap={2}>
                                  <Flex display={'flex'} flexDirection="column" flex={1}>
                                    <Text fontWeight={500} fontSize={14}>
                                      Date
                                    </Text>
                                    <DatePicker
                                      onChange={(value: any) => {
                                        const date = moment(value).format('YYYY-MM-DD');
                                        props.setFieldValue(
                                          `issueStates.${idx}.issueMessages.${index}.date`,
                                          date,
                                        );
                                      }}
                                      disabled={readOnly}
                                      value={new Date(_message.date || '')}
                                      calendarIcon={null}
                                      clearIcon={null}
                                      format="dd-MM-yyyy"
                                      maxDate={new Date()}
                                    />
                                  </Flex>
                                  <Box>
                                    <Text fontWeight={500} fontSize={14}>
                                      Time {` (${moment.tz(props.timezone).zoneAbbr()})`}
                                    </Text>
                                    <TimePickerGrid>
                                      <TimePicker
                                        // format="hh:mm a"
                                        value={_message.time || ''}
                                        isOpen={false}
                                        disabled={readOnly}
                                        onChange={(value: any) => {
                                          if (value) {
                                            props.setFieldValue(
                                              `issueStates.${idx}.issueMessages.${index}.time`,
                                              value,
                                            );
                                          }
                                        }}
                                        clearIcon={null}
                                        clockIcon={null}
                                        disableClock={true}
                                      />
                                    </TimePickerGrid>
                                  </Box>
                                </Box>
                                {_message.error.length > 0 ? (
                                  <Text color={'red.500'} fontSize={14} mt={1}>
                                    {_message.error}
                                  </Text>
                                ) : null}
                                <Flex justifyContent={'flex-end'} mt={2}>
                                  <Button
                                    variant="invertedDefault"
                                    hidden={readOnly}
                                    onClick={() => {
                                      const time = DateTime.fromISO(
                                        `${_message.date}T${_message.time}`,
                                        {
                                          zone: props.timezone,
                                        },
                                      ).toUTC();
                                      const timeISO = time.toISO();
                                      const { minutes } = DateTime.local({ zone: props.timezone })
                                        .diff(time, ['minutes'])
                                        .toObject();
                                      if ((minutes || 0) < 0) {
                                        props.setFieldValue(
                                          `issueStates.${idx}.issueMessages.${index}.error`,
                                          'Future time not allowed',
                                        );
                                      } else {
                                        props.setFieldValue(
                                          `issueStates.${idx}.issueMessages.${index}.timestamp`,
                                          timeISO,
                                        );
                                        props.setFieldValue(
                                          `issueStates.${idx}.issueMessages.${index}.error`,
                                          '',
                                        );
                                        props.setFieldValue(
                                          `issueStates.${idx}.issueMessages.${index}.isApiSavePending`,
                                          '1',
                                        );
                                        if (isUpdateFlow && !_message.isNew) {
                                          onIssueStateChange();
                                        }
                                        onClose();
                                      }
                                    }}
                                  >
                                    Update
                                  </Button>
                                </Flex>
                              </PopoverBody>
                            </PopoverContent>
                          </>
                        )}
                      </Popover>
                    </Flex>
                    {_message.isEdit === '1' ? (
                      <Box>
                        <MdBlock
                          value={_message.text}
                          onChange={content =>
                            props.setFieldValue(
                              `issueStates.${idx}.issueMessages.${index}.tempText`,
                              content,
                            )
                          }
                          options={{
                            maxHeight: '130px',
                            spellChecker: false,
                            placeholder: '',
                            imageUploadFunction: fileUploadService.getUploadFunctionForFeature(
                              FileUploadFeature.STATUS_PAGE,
                              OperationType.CREATE,
                            ),
                            imageTexts: {
                              sbInit: ``,
                              ...imageTexts,
                            },
                            ...fileUploadConfig,
                          }}
                        />
                        <HStack py={4}>
                          <Button
                            variant="default"
                            hidden={_message.isNew || readOnly}
                            onClick={() => {
                              props.setFieldValue(
                                `issueStates.${idx}.issueMessages.${index}.text`,
                                _message.tempText,
                              );
                              props.setFieldValue(
                                `issueStates.${idx}.issueMessages.${index}.isApiSavePending`,
                                '1',
                              );
                              if (isUpdateFlow) {
                                onIssueStateChange();
                              }
                            }}
                            disabled={_message.tempText.length === 0}
                          >
                            Save
                          </Button>
                          <Button
                            variant="default"
                            hidden={!isUpdateFlow || !_message.isNew || readOnly}
                            onClick={() => {
                              props.setFieldValue(
                                `issueStates.${idx}.issueMessages.${index}.text`,
                                _message.tempText,
                              );
                              props.setFieldValue(
                                `issueStates.${idx}.issueMessages.${index}.isApiSavePending`,
                                '1',
                              );
                              if (isUpdateFlow) {
                                onIssueStateChange();
                              }
                            }}
                            disabled={_message.tempText.length === 0}
                          >
                            Add Update
                          </Button>
                          <Button
                            variant="invertedDefault"
                            hidden={props.stateMessageCount === 1 || readOnly}
                            onClick={() => {
                              if (_message.isNew) {
                                fieldArrayProps.remove(index);
                                props.setFieldValue(
                                  'stateMessageCount',
                                  (props.stateMessageCount - 1).toString(),
                                );
                              } else {
                                props.setFieldValue(
                                  `issueStates.${idx}.issueMessages.${index}.isEdit`,
                                  '0',
                                );
                              }
                            }}
                          >
                            Cancel
                          </Button>
                        </HStack>
                      </Box>
                    ) : (
                      <Flex
                        direction={'column'}
                        bgColor="gray.100"
                        borderRadius={6}
                        borderWidth={1}
                        borderColor="gray.200"
                      >
                        <ReactMarkdown
                          className="reactMD mde"
                          plugins={[breaks, gfm]}
                          renderers={{ link: renderMdLink, code: CustomCodeRenderer }}
                          source={_message.text}
                        />
                        <HStack p={4}>
                          <Button
                            variant={'link'}
                            hidden={readOnly}
                            onClick={() =>
                              props.setFieldValue(
                                `issueStates.${idx}.issueMessages.${index}.isEdit`,
                                '1',
                              )
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            variant={'link'}
                            hidden={props.stateMessageCount === 1 || readOnly || isResolved}
                            onClick={() => {
                              if (isUpdateFlow) {
                                onDelete(idx, index);
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </HStack>
                      </Flex>
                    )}
                  </Flex>
                );
              })}
              {step.issueMessages.length > 0 ? (
                <Button
                  mb={15}
                  color="blue.900"
                  bg="unset"
                  textDecoration={'underline'}
                  hidden={readOnly || isResolved}
                  name="addComponent"
                  _focus={{ outline: 'none', boxShadow: 'unset' }}
                  onClick={async () => {
                    const date = DateTime.local({ zone: props.timezone });
                    fieldArrayProps.push({
                      text: template.defaultMessage,
                      tempText: template.defaultMessage,
                      date: date.toISODate(),
                      time: date.toFormat('HH:mm'),
                      timestamp: date.toUTC(),
                      isEdit: '1',
                      isNew: true,
                      isDelete: '0',
                      isApiSavePending: props.isUpdateFlow ? '0' : '1',
                      error: '',
                    });
                    if (!isUpdateFlow) {
                      props.setFieldValue(
                        'stateMessageCount',
                        (props.stateMessageCount + 1).toString(),
                      );
                    }
                  }}
                >
                  Add another update
                </Button>
              ) : null}
            </Box>
          )}
        />
      </VStack>
    </HStack>
  );
};
