import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Text,
  useMultiStyleConfig,
  VStack,
} from '@chakra-ui/react';
import { CircleIcon } from 'icons';
import { Tooltip } from 'library/atoms';
import { FilterIcon } from 'library/icons';
import { VARIANT } from 'library/types';
import { CSSProperties, ReactNode } from 'react';

import { IconButton, ListingButton } from '../atoms';
import { Search } from '../atoms/Search';

type Props = {
  title: string;
  desc: ReactNode;
  search: boolean;
  searchValue?: string;
  searchDisabled?: boolean;
  filter: boolean;
  filterDisabled?: boolean;
  /** Enable this to show a red dot above filter icon */
  isFilterApplied?: boolean;
  button: string;
  buttonDisabled?: boolean;
  buttonLoading?: boolean;
  secondaryButton?: string;
  secondaryButtonVariant?: VARIANT;
  secondaryButtonDisabled?: boolean;
  onSearch: (value: string) => void;
  onFilter: (event: React.MouseEvent<HTMLElement>) => void;
  onButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
  onSecondaryButtonClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disableTopPadding?: boolean;
  children?: ReactNode;
  noButton?: boolean;
};

export const PageHeader = ({
  title,
  desc,
  search,
  searchValue,
  searchDisabled,
  filter,
  filterDisabled,
  isFilterApplied,
  button,
  buttonDisabled,
  buttonLoading,
  secondaryButton,
  secondaryButtonVariant,
  secondaryButtonDisabled,
  onSearch,
  onFilter,
  onButtonClick,
  onSecondaryButtonClick,
  disableTopPadding,
  children,
  noButton = false,
}: Partial<Props>) => {
  const styles = useMultiStyleConfig('PageHeader', {});
  const boxStyle = { ...styles.box, ...(disableTopPadding ? { pt: 0 } : null) };
  const renderDivider = (search || filter) && (!!button || !!secondaryButton);

  return (
    <VStack align="stretch" sx={{ ...boxStyle }}>
      <Flex justifyContent="space-between">
        <Tooltip label={title ?? ''} placement="top">
          <Text maxW="60vw" isTruncated variant="h1">
            {title}
          </Text>
        </Tooltip>
        <HStack sx={{ ...styles.buttonContainer }} spacing={0}>
          {search && (
            <Box>
              <Search
                iconStyle={{ height: '36px', width: '36px' }}
                onSearch={onSearch}
                isDisabled={searchDisabled}
                searchValue={searchValue}
              />
            </Box>
          )}
          {filter && (
            <Box sx={styles.filterContainer}>
              <IconButton
                aria-label="Filter"
                variant="header"
                style={{ height: '36px', width: '36px' }}
                icon={<FilterIcon style={{ width: 16, height: 16, margin: 'auto' }} />}
                onClick={onFilter}
                isDisabled={filterDisabled}
              />
              {isFilterApplied && (
                <CircleIcon style={{ ...(styles.filterIndicator as CSSProperties) }} />
              )}
            </Box>
          )}
          {renderDivider && (
            <Center sx={{ ...styles.verticalDivider }}>
              <Divider orientation="vertical" />
            </Center>
          )}
          {secondaryButton && !noButton && (
            <ListingButton
              title={secondaryButton}
              aria-label="listing button"
              variant={secondaryButtonVariant ?? 'secondary'}
              onClick={onSecondaryButtonClick}
              isDisabled={secondaryButtonDisabled}
              isPermissionPresent={!secondaryButtonDisabled}
            />
          )}
          {!noButton && (
            <ListingButton
              title={button ?? ''}
              aria-label="listing button"
              variant="create"
              onClick={onButtonClick}
              isDisabled={buttonDisabled}
              isLoading={buttonLoading}
              style={{ minWidth: '150px' }}
              isPermissionPresent={!buttonDisabled}
            />
          )}
        </HStack>
      </Flex>
      {desc && (
        <Box sx={{ ...styles.desc }}>
          <Text variant="body">{desc}</Text>
        </Box>
      )}
      {children}
    </VStack>
  );
};
