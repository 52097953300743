import useGetAllTags from 'core/hooks/useGetAllTags';
import React, { useEffect, useMemo } from 'react';
import { CreatableSelect } from './select';
import DropdownContainer from './dropdown_container';
import { SelectOption } from '../types';
import { ActionMeta, OnChangeValue } from 'react-select';
import { useSecondaryFilters } from '../context';
import { FilterOptions } from '../const';

type Props = {
  selected: string[];
  serviceId?: string;
  teamId?: string;
  isMulti?: boolean;
  isInitializedOpen?: boolean;
};

const Tags: React.FC<Props> = ({ selected, isInitializedOpen, isMulti, ...props }) => {
  const tags = useGetAllTags(props.serviceId, props.teamId);
  const [mode, setMode] = React.useState<'key' | 'value'>('key');

  const [options, setOptions] = React.useState<SelectOption[]>([]);
  const [selectedKey, setSelectedKey] = React.useState<string>('');

  const { updateFilter } = useSecondaryFilters();

  useEffect(() => {
    if (mode === 'key') {
      setOptions(tags.keys.map(key => ({ label: key, value: key })));
    } else if (mode === 'value' && selectedKey) {
      if (tags.valuesMap[selectedKey]) {
        setOptions(tags.valuesMap[selectedKey].map(value => ({ label: value, value: value })));
      } else {
        setOptions([]);
      }
    }
  }, [tags, mode]);

  const selectedOptions: SelectOption[] = useMemo(
    () => selected.map(s => ({ label: s, value: s })),
    [selected],
  );

  const handleChange = (
    newValue: OnChangeValue<SelectOption, true>,
    actionMeta: ActionMeta<SelectOption>,
  ) => {
    if (actionMeta.action === 'remove-value') {
      const newSelected = selected.filter(s => s !== actionMeta.removedValue.label);
      updateFilter('tags', newSelected);
      return;
    }

    if (
      (actionMeta.action === 'select-option' || actionMeta.action === 'create-option') &&
      actionMeta.option
    ) {
      if (mode === 'key') {
        setSelectedKey(actionMeta.option.label);
        setMode('value');
      } else if (mode === 'value') {
        const tag = `${selectedKey}:${actionMeta.option.label}`;

        if (!selected.includes(tag)) {
          let newSelected: string[] = [];
          if (isMulti) {
            newSelected = [...selected, tag];
          } else {
            newSelected = [tag];
          }
          updateFilter('tags', newSelected);
        }

        setSelectedKey('');
        setMode('key');
      }
    }

    if (actionMeta.action === 'clear') {
      updateFilter('tags', []);
      setSelectedKey('');
      setMode('key');
    }
  };

  const onDropdownClose = () => {
    setSelectedKey('');
    setMode('key');
  };

  return (
    <DropdownContainer
      selected={selected}
      onClose={onDropdownClose}
      filterKey="tags"
      label={FilterOptions['tags'].slice(0, isMulti ? undefined : -1)}
      isInitializedOpen={isInitializedOpen}
    >
      <CreatableSelect
        options={options}
        onChange={handleChange}
        value={selectedOptions}
        placeholder={mode === 'key' ? 'Select Key' : 'Select Value'}
        heading={selectedKey ? `${selectedKey}:` : ''}
        isMulti
      />
    </DropdownContainer>
  );
};

export default Tags;
