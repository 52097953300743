import { LockIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  createStandaloneToast,
  Divider,
  Flex,
  HStack,
  Link as ChakraLink,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { DialogModalFrame } from 'uie/components';
import UnsavedChangesGuard from 'components/unsavedChangesGuard';
import { BillingService } from 'core';
import {
  T_WA_UP_DEDUPE_KEYS_KEY_DELETED,
  T_WA_UP_DEDUPE_KEYS_TAB_VIEWED,
} from 'core/const/tracker';
import { IAppState } from 'core/interfaces/IAppState';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import qs from 'query-string';
import React, { Fragment, memo, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import { AppTracker } from 'shared/analytics/tracker';

import { OrganizationContext } from '../../..';
import useUINavigationFunctions from '../../../../navigation-flows/useUINavigationFunctionV2';
import { PlaceholderText } from '../../../common';
import IllustrationBox from '../../../common/IllustrationBox';
import { Header, Loader } from '../../../components';
import { AlertDialogComponent } from '../../../components/Alert';
import { Search } from '../../../components/Search';
import DataTable from '../../../components/Table';
import { RuleTabLabels } from '../../../contants/service.contant';
import useQueryParams from '../../../helpers/helper.query-params';
import { useServiceDetailQuery } from '../../../hooks/useServiceDetail';
import {
  useChangeRulePriorityMutation,
  useDeleteRuleMutation,
  useServiceConfigQuery,
  useServiceDedupeKeysQuery,
  useServiceDeduplicationRulesQuery,
  useServiceRoutingRulesQuery,
  useServiceSuppressionRulesQuery,
  useServiceTaggingRulesQuery,
  useUpdateServiceConfigMutation,
} from '../../../hooks/useServiceRule';
import DedupRuleIllustration from '../../../icons/Illustrations/DedupRules.png';
import RoutingRuleIllustration from '../../../icons/Illustrations/RoutingRules.png';
import SuppressionRuleIllustration from '../../../icons/Illustrations/SuppressionRules.png';
import TaggingRuleIllustration from '../../../icons/Illustrations/TaggingRules.png';
import { IPriorityPosition, IRule } from '../../../Interfaces/automation-rule';
import { AutomationTabType } from '../../../types/service-detail.types';
import { MenuAction, MenuActionDetail, MenuActions, rulesColumns } from './columns';
import DedupeKeysModal from './dedupeKeys.modal';
import DeduplicationRulesModal from './deduplicationRules.modal';
import RoutingRulesModal from './routingRules.modal';
import SuppressionRulesModal from './suppressionRules.modal';
import TaggingRulesModal from './taggingRules.modal';
import { useServiceRule } from './useServiceRule';

const toast = createStandaloneToast();

export enum RoutingRules {
  'tagging' = 'tagging',
  'routing' = 'routing',
  'deduplication' = 'deduplication',
  'suppression' = 'suppression',
  'dedupekey' = 'dedupekey',
}

export const activeRuleModalParam = 'rule-modal';
export const activeRuleModalEditParam = 'rule-modal-edit';
export const activeRuleTabParam = 'rule-tab';
export const alertSourceParam = 'alert-source';
export const modeParam = 'mode';

const ServiceRules = memo(() => {
  const history = useHistory();

  const organization = React.useContext(OrganizationContext);
  const { serviceId } = useParams<{ serviceId: string }>();

  const hasUpdateAccess = useUserAccess().hasUpdateAccess;

  const hasUpdate = hasUpdateAccess('services', serviceId);

  const query = useQueryParams();
  const activeRuleModalQuery = query.get(activeRuleModalParam) ?? '';
  const activeRuleModalEditQuery = query.get(activeRuleModalEditParam) ?? '';
  const activeRuleTabQuery = query.get(activeRuleTabParam) ?? '';
  const alertSourceParamQuery = query.get(alertSourceParam) ?? '';
  const modeParamQuery = (query.get(modeParam) ?? '') as 'view' | 'edit' | 'create';
  const showDedupRules = !!(
    organization && BillingService.getRule({ organization }, 'deduplication-rules')
  );

  const {
    data: serviceConfig,
    isLoading: isServiceConfigInitialLoading,
    isFetching: isServiceConfigLoading,
    refetch: refetchServiceConfig,
  } = useServiceConfigQuery(serviceId);
  const {
    data: serviceDetail,
    isLoading: isServiceDetailInitialLoading,
    isFetching: isServiceDetailLoading,
    refetch: refetchServiceDetail,
  } = useServiceDetailQuery(serviceId);
  const {
    data: deduplicationRules,
    isLoading: isDeduplicationInitialLoading,
    isFetching: isDeduplicationLoading,
    refetch: refetchDeduplicationRule,
  } = useServiceDeduplicationRulesQuery(serviceId);
  const {
    data: taggingRules,
    isLoading: isTaggingInitialLoading,
    isFetching: isTaggingLoading,
    refetch: refetchTaggingRule,
  } = useServiceTaggingRulesQuery(serviceId);
  const {
    data: routingRules,
    isLoading: isRoutingInitialLoading,
    isFetching: isRoutingLoading,
    refetch: refetchRoutingRule,
  } = useServiceRoutingRulesQuery(serviceId);
  const {
    data: suppressionRules,
    isLoading: isSuppressionInitialLoading,
    isFetching: isSuppressionLoading,
    refetch: refetchSuppressionRule,
  } = useServiceSuppressionRulesQuery(serviceId);
  const {
    data: dedupekeys,
    isLoading: isDedupeKeysInitialLoading,
    isFetching: isDedupeKeysLoading,
    refetch: refetchDedupeKeys,
  } = useServiceDedupeKeysQuery(serviceId);

  const { mutate: deleteRule, isLoading: isRuleDeleting } = useDeleteRuleMutation(serviceId);
  const { mutate: updateServiceConfig, isLoading: isServiceConfigUpdating } =
    useUpdateServiceConfigMutation(serviceId);
  const { mutateAsync: changePriority } = useChangeRulePriorityMutation();

  const [searchText, setSearchText] = React.useState('');
  const [searchEnabled, setSearchEnabled] = React.useState(false);

  const { getEntityComponent } = useUINavigationFunctions();
  const {
    activeDedupPreferenceText,
    activeRuleModal,
    checkAndSetDirty,
    checkDirtyAndCloseModal,
    closeDedupPreferenceModal,
    deleteAlertDialogMessage,
    deleteAlertDialogTitle,
    deleteRuleDetails,
    dedupPreference,
    dedupPreferenceDialogTitle,
    dedupPreferenceDialogMessage,
    enableDedupeKeyEditMode,
    getModalId,
    hasUnsavedChanges,
    hideModal,
    isDeleteRuleModalOpen,
    isDirty,
    onAddRule,
    onCloseDeleteModal,
    onCancelDeleteModal,
    onChangeDedupPreference,
    onCopyDedupeKeyCB,
    onEditRule,
    onDeleteRule,
    onViewDedupKey,
    setHasUnsavedChanges,
    showActiveDedupPreferenceText,
    showLockIconOnTab,
  } = useServiceRule({
    serviceConfig,
    activeRuleModalQuery,
  });
  const alertSourcesConfiguredWithDedupeKeys = dedupekeys?.map(key => key.alert_source_shortname);

  const loadingState = useMemo(
    () => ({
      [RoutingRules.tagging]: { isFetching: isTaggingLoading, isLoading: isTaggingInitialLoading },
      [RoutingRules.deduplication]: {
        isFetching: isDeduplicationLoading && isServiceConfigLoading,
        isLoading: isDeduplicationInitialLoading && isServiceConfigInitialLoading,
      },
      [RoutingRules.routing]: { isFetching: isRoutingLoading, isLoading: isRoutingInitialLoading },
      [RoutingRules.suppression]: {
        isFetching: isSuppressionLoading,
        isLoading: isSuppressionInitialLoading,
      },
      [RoutingRules.dedupekey]: {
        isFetching: isDedupeKeysLoading && isServiceDetailLoading && isServiceConfigLoading,
        isLoading:
          isDedupeKeysInitialLoading &&
          isServiceDetailInitialLoading &&
          isServiceConfigInitialLoading,
      },
    }),
    [
      isDeduplicationInitialLoading,
      isDeduplicationLoading,
      isTaggingLoading,
      isTaggingInitialLoading,
      isRoutingLoading,
      isRoutingInitialLoading,
      isSuppressionLoading,
      isSuppressionInitialLoading,
      isServiceDetailLoading,
      isServiceDetailInitialLoading,
      isServiceConfigLoading,
      isServiceConfigInitialLoading,
      isDedupeKeysLoading,
      isDedupeKeysInitialLoading,
    ],
  );

  const refetchRule = useMemo(
    () => ({
      [RoutingRules.tagging]: refetchTaggingRule,
      [RoutingRules.deduplication]: refetchDeduplicationRule,
      [RoutingRules.routing]: refetchRoutingRule,
      [RoutingRules.suppression]: refetchSuppressionRule,
      [RoutingRules.dedupekey]: refetchDedupeKeys,
    }),
    [
      refetchTaggingRule,
      refetchDeduplicationRule,
      refetchRoutingRule,
      refetchSuppressionRule,
      refetchDedupeKeys,
    ],
  );

  const createdByCol = useMemo(
    () =>
      ({
        Header: 'CREATED By',
        disableSortBy: true,
        accessor: (row: IRule) => {
          const user = organization?.users.u.find(u => u.id === row.created_by);
          return user ? `${user.first_name} ${user.last_name}` : 'N/A';
        },
        Cell: (cell: any) => {
          const createdByText = cell.value === 'N/A' ? 'Deleted User' : cell.value;
          const simpleComponent = (
            <Text fontSize="sm" fontWeight={400}>
              {createdByText}
            </Text>
          );
          let entityComponent = simpleComponent;

          if (
            createdByText !== 'Deleted User' &&
            cell.row.original &&
            cell.row.original.created_by
          ) {
            const simpleComponentWithLinkStyles = (
              <ChakraLink
                color=""
                _hover={{ textDecoration: 'none' }}
                fontFamily="inherit"
                fontSize="sm"
                fontWeight={400}
              >
                {createdByText}
              </ChakraLink>
            );
            entityComponent = getEntityComponent({
              componentType: 'linkWPopover',
              type: 'user',
              id: cell.row.original.created_by,
              popoverTrigger: simpleComponentWithLinkStyles,
              popoverPlacement: 'left-start',
            });
          }
          return entityComponent;
        },
      } as Column<any>),
    [organization],
  );

  const actionsCol = (ruleType: RoutingRules): Column => {
    return {
      id: 'actions',
      Cell: (cell: Cell<IRule>) => {
        const {
          row: {
            original: { rule_id, basic_expression, alert_source_shortname },
            index,
          },
          data,
        } = cell;

        const updatePriorityFn = onUpdatePriority(ruleType, rule_id, index, data.length);

        const { onMoveTop, onMoveUp, onMoveDown, onMoveBottom } = updatePriorityFn;

        const type = [RoutingRules.dedupekey].includes(ruleType) ? 'Key' : 'Rule';

        const actions: MenuActionDetail[] = [
          {
            action: MenuAction.EDIT,
            label: `Edit ${type}`,
            handler: () => onEditRule(ruleType, rule_id, alert_source_shortname),
          },
          {
            action: MenuAction.DELETE,
            label: `Delete ${type}`,
            handler: () => onDeleteRule(rule_id, [], ruleType, alert_source_shortname),
          },
        ];

        if (ruleType === RoutingRules.dedupekey) {
          actions.unshift(
            {
              action: MenuAction.VIEW,
              label: `View ${type}`,
              handler: () => onViewDedupKey(alert_source_shortname ?? ''),
            },
            {
              action: MenuAction.COPY,
              label: `Copy ${type}`,
              handler: () => onCopyDedupeKeyCB(cell.row.original.overlay.template),
            },
          );
        } else if (![RoutingRules.dedupekey].includes(ruleType)) {
          actions.unshift(
            {
              action: MenuAction.MOVE_TOP,
              label: 'Move to Top',
              handler: onMoveTop,
            },
            {
              action: MenuAction.MOVE_BOTTOM,
              label: 'Move to Bottom',
              handler: onMoveBottom,
            },
            {
              action: MenuAction.MOVE_UP,
              label: 'Move Up',
              handler: onMoveUp,
            },
            {
              action: MenuAction.MOVE_DOWN,
              label: 'Move Down',
              handler: onMoveDown,
            },
          );
        }

        return <MenuActions actions={actions} />;
      },
    };
  };

  const tabContent = useMemo(() => {
    const tabsList: AutomationTabType[] = [
      {
        key: RoutingRules.tagging,
        label: RuleTabLabels.tagging,
        illustration: TaggingRuleIllustration,
        illustrationMessage: (
          <PlaceholderText
            message="No rules added"
            tip="Tip: We suggest using tags like Severity, Priority, Impact, Source, Owner & Type that can quickly give more context to your on-call engineers."
          />
        ),
        count: taggingRules?.rules?.length || 0,
        cols: [...rulesColumns.tagging, createdByCol, actionsCol(RoutingRules.tagging)].filter(
          col => (col.id === 'actions' ? hasUpdate : true),
        ),
        data: taggingRules?.rules?.map((rule, index) => ({ ...rule, priority: index + 1 })) ?? [],
      },
      {
        key: RoutingRules.routing,
        label: RuleTabLabels.routing,
        illustration: RoutingRuleIllustration,
        illustrationMessage: (
          <PlaceholderText
            message="No rules added"
            tip="Tip: Use tags to build specific routing rules and reach the right responder in time."
          />
        ),
        count: routingRules?.rules?.length || 0,
        cols: [...rulesColumns.routing, createdByCol, actionsCol(RoutingRules.routing)].filter(
          col => (col.id === 'actions' ? hasUpdate : true),
        ),
        data:
          routingRules?.rules?.map((r, index) => ({
            ...r,
            priority: index + 1,
            users: organization?.users.u.map(user => {
              return { id: user.id, first_name: user.first_name, last_name: user.last_name };
            }),
            squads: organization?.squads.s.map(s => ({
              name: s.name,
              id: s.id,
            })),
            escalations: organization?.escalations.e.map(e => ({
              name: e.name,
              id: e.id,
            })),
          })) ?? [],
      },
      {
        key: RoutingRules.dedupekey,
        label: RuleTabLabels.dedupKey,
        illustration: DedupRuleIllustration,
        illustrationMessage: (
          <PlaceholderText
            message="No key added"
            tip="Tip: We suggest adding deduplication keys to group similar or related alerts to avoid alert noise."
          />
        ),
        count: dedupekeys?.length || 0,
        cols: [
          ...rulesColumns.dedupeKeysCols,
          createdByCol,
          actionsCol(RoutingRules.dedupekey),
        ].filter(col => (col.id === 'actions' ? hasUpdate : true)),
        data: dedupekeys ?? [],
      },
      {
        key: RoutingRules.suppression,
        label: RuleTabLabels.suppression,
        illustration: SuppressionRuleIllustration,
        illustrationMessage: (
          <PlaceholderText
            message="No rules added"
            tip="Tip: We suggest setting suppression rules to avoid alert noise that could arise from non-actionable or information only alerts."
          />
        ),
        count: suppressionRules?.rules?.length || 0,
        cols: [
          ...rulesColumns.suppressionCols,
          createdByCol,
          actionsCol(RoutingRules.suppression),
        ].filter(col => (col.id === 'actions' ? hasUpdate : true)),
        data:
          suppressionRules?.rules?.map((rule, index) => ({ ...rule, priority: index + 1 })) ?? [],
      },
    ];

    if (showDedupRules) {
      const dedupObj: AutomationTabType = {
        key: RoutingRules.deduplication,
        label: RuleTabLabels.dedupRule,
        illustration: DedupRuleIllustration,
        illustrationMessage: (
          <PlaceholderText
            message="No rules added"
            tip="Tip: We suggest setting deduplication rules to group similar or related alerts to avoid alert noise."
          />
        ),
        count: deduplicationRules?.rules?.length || 0,
        cols: [
          ...rulesColumns.deduplicationCols,
          createdByCol,
          actionsCol(RoutingRules.deduplication),
        ].filter(col => (col.id === 'actions' ? hasUpdate : true)),
        data:
          deduplicationRules?.rules?.map((rule, index) => ({ ...rule, priority: index + 1 })) ?? [],
      };
      // add de-duplication rules tab
      tabsList.splice(2, 0, dedupObj);
    }
    return tabsList;
  }, [taggingRules, routingRules, deduplicationRules, suppressionRules, dedupekeys]);

  const activeTab = useMemo(() => {
    const activeTabIndex = tabContent.findIndex(tab => tab.key === activeRuleTabQuery) ?? 0;
    return activeTabIndex > -1 ? activeTabIndex : 0;
  }, [activeRuleTabQuery]);

  const onUpdatePriority = (
    ruleType: RoutingRules,
    ruleId: string,
    priority: number,
    totalRules: number,
  ) => {
    const updatePriority = (position: IPriorityPosition) => () =>
      changePriority({ serviceId, ruleId, ruleType, positionDetail: { position: position } });

    return {
      onMoveTop: priority === 0 ? undefined : updatePriority('top'),
      onMoveUp: priority !== 0 ? updatePriority('up') : undefined,
      onMoveDown: priority !== totalRules - 1 ? updatePriority('down') : undefined,
      onMoveBottom: priority === totalRules - 1 ? undefined : updatePriority('bottom'),
    };
  };

  const saveDedupPreference = async () => {
    try {
      await updateServiceConfig({
        dedup_key_enabled: dedupPreference === 'Key Based Deduplication',
      });
      closeDedupPreferenceModal();
    } catch (error) {
      console.error(error);
    }
  };

  const deleteSelectedRule = async () => {
    if (deleteRuleDetails) {
      try {
        await deleteRule({
          serviceId,
          ruleId: deleteRuleDetails.id,
          ruleType: deleteRuleDetails?.ruleType,
          alertSourceName: deleteRuleDetails.alertSourceShortname,
        });
        onCloseDeleteModal();
        AppTracker.track(T_WA_UP_DEDUPE_KEYS_KEY_DELETED);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleTabChange = (index: number, replaceHistory?: boolean) => {
    const config = {
      pathname: history.location.pathname,
      search: qs.stringifyUrl({
        url: history.location.search,
        query: {
          [activeRuleTabParam]: tabContent.find((_tab, i) => i === index)?.key ?? tabContent[0].key,
        },
      }),
    };

    if (replaceHistory) {
      history.replace(config);
    } else {
      history.push(config);
    }
  };

  const renderRuleModal = (ruleType: RoutingRules, editRuleId?: string) => {
    const commonConfig = {
      checkAndSetDirty,
      serviceId,
      hide: hideModal,
      refetchRule: () => {
        hideModal();
        refetchRule[ruleType]();
      },
    };

    switch (ruleType) {
      case RoutingRules.routing:
        return (
          <RoutingRulesModal
            {...commonConfig}
            setIsDirty={checkAndSetDirty}
            editRuleId={editRuleId}
            rules={routingRules?.rules ?? []}
          />
        );
      case RoutingRules.tagging:
        return (
          <TaggingRulesModal
            {...commonConfig}
            setIsDirty={checkAndSetDirty}
            editRuleId={editRuleId}
            rules={taggingRules?.rules ?? []}
          />
        );
      case RoutingRules.deduplication:
        return (
          <DeduplicationRulesModal
            {...commonConfig}
            editRuleId={editRuleId}
            rules={deduplicationRules?.rules ?? []}
          />
        );
      case RoutingRules.dedupekey:
        return (
          <DedupeKeysModal
            {...commonConfig}
            alertSourceName={alertSourceParamQuery}
            rules={deduplicationRules?.rules ?? []}
            alertSourcesConfiguredWithDedupeKeys={alertSourcesConfiguredWithDedupeKeys}
            onDeleteAction={onDeleteRule}
            isDirty={isDirty}
            onCopyDedupeKey={onCopyDedupeKeyCB}
            mode={modeParamQuery}
            activeAlertSources={activeAlertSources}
            enableDedupeKeyEditMode={enableDedupeKeyEditMode}
          />
        );
      case RoutingRules.suppression:
        return (
          <SuppressionRulesModal
            {...commonConfig}
            setIsDirty={checkAndSetDirty}
            editRuleId={editRuleId}
            rules={suppressionRules?.rules ?? []}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!activeRuleTabQuery) {
      handleTabChange(0, true);
    } else {
      if (activeRuleTabQuery === 'dedupekey') {
        AppTracker.track(T_WA_UP_DEDUPE_KEYS_TAB_VIEWED);
      }
    }
  }, [activeRuleTabQuery]);

  let tabActionButtonText =
    tabContent.find((_tab, index) => index === activeTab)?.label?.slice(0, -1) ?? 'Rule';

  if (tabActionButtonText.startsWith('Key Based')) {
    tabActionButtonText = 'Dedup Key';
  }

  const activeAlertSources = serviceDetail?.active_alert_sources ?? [];

  return (
    <Fragment>
      <Tabs
        variant="soft-rounded"
        colorScheme="gray"
        isLazy
        index={activeTab}
        onChange={index => {
          setSearchText('');
          handleTabChange(index);
        }}
      >
        <Header
          wrap
          padding={4}
          title={
            <TabList>
              {tabContent.map(rule => (
                <Tab
                  _focus={{ outline: 'none' }}
                  fontSize={16}
                  fontWeight={400}
                  borderRadius={6}
                  key={rule.label}
                >
                  <HStack>
                    {showDedupRules && showLockIconOnTab(rule.label) && <LockIcon />}
                    <Text>{`${rule.label} (${rule.count})`}</Text>
                  </HStack>
                </Tab>
              ))}
            </TabList>
          }
          actions={
            <Flex justifyContent="flex-end" gap={5} flexGrow={1}>
              {!!tabContent.find((_tab, index) => index === activeTab)?.data?.length && (
                <Fragment>
                  <Search
                    setSearchTerm={searchTerm => setSearchText(searchTerm)}
                    searchTerm={searchText}
                    searchEnabled={searchEnabled}
                    setSearchEnabled={isEnabled => setSearchEnabled(isEnabled)}
                    isDisabled={false}
                  />
                  {hasUpdate && (
                    <Center height={8}>
                      <Divider orientation="vertical" />
                    </Center>
                  )}
                </Fragment>
              )}
              <NoPermissionTooltip isDisabled={hasUpdate}>
                <Button
                  variant="default"
                  size="sm"
                  as={Link}
                  to={onAddRule(
                    tabContent.find((_tab, index) => index === activeTab)?.key as RoutingRules,
                  )}
                  onClick={() => setSearchText('')}
                  isDisabled={!hasUpdate}
                >
                  + {tabActionButtonText}
                </Button>
              </NoPermissionTooltip>
            </Flex>
          }
        />

        <TabPanels>
          {tabContent.map(content => (
            <TabPanel key={content.key}>
              {loadingState[content.key].isLoading && <Loader.Spinner centered />}
              {!loadingState[content.key].isLoading && loadingState[content.key].isFetching && (
                <Loader.Progress />
              )}

              {!loadingState[content.key].isLoading && (
                <Box w="100%" justifyContent="flex-start">
                  {showDedupRules && showActiveDedupPreferenceText(content.label) && (
                    <HStack mb="12px">
                      <Text fontWeight="bold">{activeDedupPreferenceText(content.label)}</Text>
                      <Popover trigger="hover">
                        <PopoverTrigger>
                          <img src="/icons/info_black.svg" alt="more info" style={{ height: 16 }} />
                        </PopoverTrigger>
                        <PopoverContent
                          css={{
                            '&:focus': {
                              boxShadow: 'none',
                              outline: 'none',
                            },
                          }}
                          backgroundColor={'#F1F3F6'}
                          fontSize={'12.5px'}
                          fontWeight={400}
                          width="270px"
                        >
                          <PopoverHeader>
                            Either Deduplication rules or Key based deduplication can be active at
                            one time
                          </PopoverHeader>
                        </PopoverContent>
                      </Popover>
                      <ChakraLink
                        color="#2C60D5"
                        _hover={{ textDecoration: 'none' }}
                        fontFamily="inherit"
                        fontWeight={400}
                        onClick={() => onChangeDedupPreference(content.label)}
                      >
                        Change
                      </ChakraLink>
                    </HStack>
                  )}
                  {content.data.length ? (
                    <DataTable
                      searchText={searchText}
                      data={content.data}
                      columns={content.cols}
                      isClientPagination
                      disableOverflow
                    />
                  ) : (
                    <Box width="450px" margin="auto">
                      <IllustrationBox
                        width="300px"
                        image={content.illustration}
                        name={content.label}
                        msg={content.illustrationMessage}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      <AlertDialogComponent
        isOpen={!!dedupPreference}
        onClose={closeDedupPreferenceModal}
        callbackFn={saveDedupPreference}
        msg={dedupPreferenceDialogMessage}
        title={dedupPreferenceDialogTitle}
        loading={isServiceConfigUpdating}
        isDelete={false}
        showActionButtonFirst
        actionButtonText="Proceed"
      />

      <AlertDialogComponent
        isOpen={isDeleteRuleModalOpen}
        onClose={onCloseDeleteModal}
        onCancel={
          deleteRuleDetails?.ruleType === RoutingRules.dedupekey ? onCancelDeleteModal : null
        }
        callbackFn={deleteSelectedRule}
        msg={deleteAlertDialogMessage}
        title={deleteAlertDialogTitle}
        isDelete={true}
        loading={isRuleDeleting}
      />

      <DialogModalFrame
        id={getModalId(activeRuleModal)}
        width="1000px"
        onClose={checkDirtyAndCloseModal}
        skipFocusTrap
      >
        {activeRuleModal && renderRuleModal(activeRuleModal, activeRuleModalEditQuery)}
      </DialogModalFrame>

      <UnsavedChangesGuard
        isManual={true}
        showModal={hasUnsavedChanges}
        onModalClose={() => {
          setHasUnsavedChanges(false);
        }}
        onDiscardChanges={() => {
          hideModal();
          setHasUnsavedChanges(false);
        }}
      />
    </Fragment>
  );
});

export default ServiceRules;
