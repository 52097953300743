import { Grid, Para, Pill, Theme, Tooltip } from 'uie/components';
import { Tooltip as CTooltip } from 'library/atoms';
import { IEscalationEntity, IEscalationEntityExtension } from 'core/interfaces/IEscalationPolicy';
import {
  ArrowLeftIcon,
  CloseIcon,
  DragAndDropIcon,
  InfoIcon,
  ProfileIcon,
  ScheduleIcon,
  SquadIcon,
  UnVerifiedIcon,
} from 'icons';
import React, { Component } from 'react';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Text, VStack } from '@chakra-ui/react';
import { truncate } from 'core/helpers/stringUtils';

interface IProps {
  entities: IEscalationEntityExtension[];
  ruleIndex: number;
  updateEntityList: (ruleIndex: number, entities: IEscalationEntity[]) => void;
  removeEntity: (ruleIndex: number, enitityId: string, pid?: number) => void;
}

const Handle = SortableHandle(() => <DragAndDropIcon height={15} cursor={'grab'} />);

const { theme } = Theme;

const SortableItem = SortableElement(
  ({
    value,
    ruleIndex,
    removeEntity,
  }: {
    value: IEscalationEntityExtension;
    removeEntity: (ruleIndex: number, enitityId: string, pid?: number) => void;
    ruleIndex: number;
  }) => (
    <Pill
      className="ma-5"
      backgroundColor={theme.shades.white}
      style={{
        cursor: 'pointer',
        height: 20,
        alignContent: 'center',
        display: 'flex',
      }}
    >
      <Grid alignItems="center">
        {value.highlight && (
          <ArrowLeftIcon
            style={{ transform: 'scaleX(-1)', marginRight: 10 }}
            height={20}
            width={20}
            color={theme.success.default}
          />
        )}
        {!value.email_verified || !value.phone_verified ? (
          <Tooltip
            padding="8px"
            offset={{ top: '-70px' }}
            width="300px"
            label={
              !value.email_verified
                ? 'Unverified users detected. Users need to verify email to start receiving notifications.'
                : 'User needs to verify phone number to get SMS and Phone notifications'
            }
          >
            <Grid alignItems="center">
              <UnVerifiedIcon height={14} width={14} stroke={theme.shades.black} fill="none" />
              <InfoIcon className="ml-5" height={14} width={14} stroke={theme.shades.black} />
            </Grid>
          </Tooltip>
        ) : value.type === 'schedule' || value.type === 'schedulev2' ? (
          <ScheduleIcon height={14} width={14} />
        ) : value.type === 'squad' ? (
          <SquadIcon height={14} width={14} />
        ) : (
          <ProfileIcon height={14} width={14} />
        )}
        {value.name.length > 30 ? (
          <CTooltip label={<Text maxW={'30ch'}>{value.name}</Text>} placement="bottom">
            <Text style={{ margin: 10 }} isTruncated>
              {truncate(value.name)}
            </Text>
          </CTooltip>
        ) : (
          <Text style={{ margin: 10 }} isTruncated>
            {truncate(value.name, 50)}
          </Text>
        )}
        <Handle />
        <CloseIcon
          height={14}
          width={14}
          stroke={theme.shades.cement}
          style={{ marginLeft: 5 }}
          onClick={() => removeEntity(ruleIndex, value.id as string, value.pid)}
        />
      </Grid>
    </Pill>
  ),
);

const SortableList = SortableContainer(
  ({
    items,
    ruleIndex,
    removeEntity,
  }: {
    items: IEscalationEntityExtension[];
    removeEntity: (ruleIndex: number, enitityId: string, pid?: number) => void;
    ruleIndex: number;
  }) => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
      }}
    >
      {items.map((item: IEscalationEntityExtension, index: number) => (
        <SortableItem
          key={item.id}
          index={index}
          value={item}
          ruleIndex={ruleIndex}
          removeEntity={removeEntity}
        />
      ))}
    </div>
  ),
);

export default class SortableComponent extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    this.props.updateEntityList(
      this.props.ruleIndex,
      arrayMove(this.props.entities, oldIndex, newIndex),
    );
  };

  render() {
    return (
      <SortableList
        axis="xy"
        useDragHandle
        transitionDuration={500}
        items={this.props.entities}
        onSortEnd={this.onSortEnd}
        ruleIndex={this.props.ruleIndex}
        removeEntity={this.props.removeEntity}
        helperClass="draggable-item"
      />
    );
  }
}
