import {
  Checkbox as CheckboxChakra,
  CheckboxProps,
  Icon,
  IconProps,
  useBreakpointValue,
} from '@chakra-ui/react';

type Props = Omit<CheckboxProps, 'variant'> & {
  /** In case of multi checkbox */
  isMulti: boolean;
  label: string;
};

const MultiCheckboxIcon = (props: CheckboxProps & IconProps) => {
  const { isIndeterminate, isChecked, ...rest } = props;

  if (isChecked) {
    return (
      <Icon viewBox="0 0 24 24" {...rest}>
        <rect width="24" height="24" rx="4" fill="none" />
        <rect x="0" y="10" width="24" height="5" fill="currentColor" />
      </Icon>
    );
  }
  return null;
};

export function Checkbox({ isMulti, label, ...props }: Partial<Props>) {
  const size = useBreakpointValue({ base: 'md', lg: 'lg' });

  return (
    <CheckboxChakra
      icon={isMulti ? <MultiCheckboxIcon /> : undefined}
      variant={isMulti ? 'multi' : undefined}
      size={size}
      {...props}
    >
      {label}
    </CheckboxChakra>
  );
}
