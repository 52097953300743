import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  StackItem,
  Text,
} from '@chakra-ui/react';
import { VerticalDots } from 'icons';
import { PageLevelErrorMessage } from 'library/atoms';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { STATUSPAGE_V2_PATH } from 'views/main/routes/routes';

import { CustomDrawerComponent } from '../components/Drawer';
import Layout from '../components/Layout';
import { DETAILS_MENU_ITEMS, DRAWER_TYPES } from '../constants/status.constants';
import {
  getComponentList,
  getDrawerHeader,
  getSquadcastUrl,
  truncateString,
} from '../helpers/helper.details';
import { getStatus } from '../helpers/helper.service';
import { reactQueryConfigSuccess } from '../helpers/helper.toast';
import { useIssueList } from '../hooks/useIssueList';
import { usePageDetails } from '../hooks/usePageDetails';
import useQueryParams from '../hooks/useQueryParams';
import { ChevronRightIcon, LockIcon } from '../icons';
import {
  IIssueListContext,
  IPageIssueList,
  IStatusPageComponentList,
  IStatusPageDetails,
} from '../Interface';
import { Panel } from './panels';
import StatusComponent from './status.component';
import StatusMessage from './status.message';
import UpdatePageConfig from './statuspage.config';
import SubscribersSettings from './statuspage.subscribers';
import UpdateMessageTemplate from './statuspage.template/UpdateMessageTemplate';
import UpdatePageTheme from './statuspage.theme';
import IssueAdd from './tab.issues/add';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_DETAILS_PAGE_VIEWED } from 'core/const/tracker';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { useCompareGlobalAndEntityTeamId } from 'core/hooks/useCompareGlobalAndEntityTeamId';

interface Props {
  details: IStatusPageDetails;
  openDrawer: (type: string) => void;
  goBack: () => void;
  canVisitUrl: boolean;
  url: string;
  canEdit: boolean;
}

const IssueListContext = React.createContext<IIssueListContext>({} as IIssueListContext);
export const useIssueListContext = () => useContext(IssueListContext);

export const Summary = (props: Props) => {
  const { details, openDrawer, url, canVisitUrl, canEdit } = props;
  const {
    status,
    timezone,
    isPublic,
    subscribersCount,
    maximumSubscribersLimit,
    underMaintenance,
    statusMaintenance,
  } = details;

  const currentStatus = getStatus(status, underMaintenance, statusMaintenance);
  useEffect(() => {
    AppTracker.track(T_WA_UP_STATUS_PAGE_V2_DETAILS_PAGE_VIEWED);
  }, []);

  return (
    <>
      <Box data-testid="summary">
        <HStack justifyContent={'space-between'} alignItems={'center'}>
          <StackItem>
            <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
              <BreadcrumbItem>
                <BreadcrumbLink onClick={props.goBack}>Status Page</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink>{truncateString(details.name, 30)}</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>

            <Flex py={2} alignItems="center">
              <Text fontSize={30}>{truncateString(details.name, 30)}</Text>
              {!canVisitUrl ? (
                <Box ml={2}>
                  <PageLevelErrorMessage
                    label="*Domain Verification is pending."
                    hideIcon
                    hyperlinkUrl="https://support.squadcast.com/status-page/status-page#edit-page-configuration-or-configure-custom-domain"
                  />
                </Box>
              ) : null}
            </Flex>
          </StackItem>
          <StackItem>
            <Flex alignItems={'center'} gap={'10px'}>
              <NoPermissionTooltip isDisabled={canEdit}>
                <Button onClick={() => openDrawer(DRAWER_TYPES.ADD_ISSUE)} isDisabled={!canEdit}>
                  Add Issue
                </Button>
              </NoPermissionTooltip>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="options"
                  icon={<VerticalDots />}
                  variant="unstyled"
                  display="flex"
                  justifyContent="center"
                  data-testid="menu"
                />
                <MenuList>
                  {Object.values(DETAILS_MENU_ITEMS).map(option => {
                    return (
                      <MenuItem
                        key={option.id}
                        onClick={() => openDrawer(option.id)}
                        data-testid={`menu-item-${option.id}`}
                      >
                        {option.title}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            </Flex>
          </StackItem>
        </HStack>
      </Box>
      <Box maxH="20dvh" overflowY={'auto'}>
        <Text>{details?.description || ''}</Text>
      </Box>

      <Box my={3}>
        <Text>
          {getComponentList(details.components)
            .filter((component: IStatusPageComponentList) => !component.isHidden)
            .map((component: IStatusPageComponentList) => component.name)
            .join(' | ')}
        </Text>
      </Box>

      <Flex flex="1" mt={8}>
        <Flex flex="1" direction={'column'}>
          <Text fontSize={14} color="gray.600">
            STATUS
          </Text>
          <Flex align={'center'}>
            <Box bgColor={currentStatus?.color} w={3} h={3} borderRadius={6} mr={2} />
            <Box flex="1">
              <Text>{currentStatus.name}</Text>
            </Box>
          </Flex>
        </Flex>
        <Flex flex="1" direction={'column'}>
          <Text fontSize={14} color="gray.600">
            TIMEZONE
          </Text>
          <Flex>
            <Text>{timezone}</Text>
          </Flex>
        </Flex>
        <Flex flex="1" direction={'column'} mr={6}>
          <Text fontSize={14} color="gray.600">
            URL
          </Text>
          <Flex>
            {canVisitUrl ? (
              <Link href={url} isExternal style={{ color: 'blue' }}>
                {url}
              </Link>
            ) : (
              <Box>
                <Text color={'red.500'}>{url}</Text>
              </Box>
            )}
          </Flex>
        </Flex>
        <Flex flex="1" direction={'column'}>
          <Text fontSize={14} color="gray.600">
            PUBLIC / PRIVATE
          </Text>
          <Flex>
            {isPublic ? (
              <Text>Public</Text>
            ) : (
              <Flex>
                <LockIcon />
                <Text ml={2}>Private</Text>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex flex="1" direction={'column'}>
          <Text fontSize={14} color="gray.600">
            NUMBER OF SUBSCRIBERS
          </Text>
          <Flex alignItems={'baseline'}>
            <Text>
              {subscribersCount} of {maximumSubscribersLimit}
            </Text>
            {maximumSubscribersLimit - subscribersCount <= 10 ? (
              <Text ml={3} fontSize={12}>
                {maximumSubscribersLimit === subscribersCount
                  ? 'Maximum limit reached'
                  : 'Almost reaching'}
              </Text>
            ) : null}
            {maximumSubscribersLimit === subscribersCount ? (
              <Link ml={3} fontSize={12}>
                Upgrade now
              </Link>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const StatusPageDetails = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const query = useQueryParams();
  const pageId = parseInt(id);
  const type = query.get('type');
  const {
    details,
    isLoading,
    isError,
    error,
    setOpenDrawer,
    setCloseDrawer,
    drawerOption,
    isEditFlow,
    setIsEditFlow,
    refetch,
  } = usePageDetails(pageId, true);
  const isServiceConnectorEnabled = details?.isAutomaticCompStatusUpdateEnabled;

  const { handleDifferentGlobalAndEntityTeamId } = useCompareGlobalAndEntityTeamId();

  useEffect(() => {
    handleDifferentGlobalAndEntityTeamId(details?.teamID);
  }, [details?.teamID]);

  const openDrawer = (type: string) => {
    query.delete('type');
    query.append('type', type);
    history.push(`${STATUSPAGE_V2_PATH}/${id}?${query}`);
  };

  const closeDrawer = () => {
    history.go(-1);
  };

  useEffect(() => {
    if (Object.values(DRAWER_TYPES).includes(type || '')) {
      setOpenDrawer(type || '');
    } else {
      setCloseDrawer();
    }
  }, [type]);

  const {
    isIssueListLoading,
    isFetched,
    isIssueListError,
    list,
    totalCount,
    isSuccess,
    refetchIssueList,
    issueFilterDetails,
    deleteIssuePageAction,
    state: {
      pagination: { queryPageIndex, queryPageSize },
      search: { enabled: isSearchEnabled },
      drawer: { isDrawerOpen, drawerType, issueId },
      filters,
      deleteIssue: { showModal, selectedIssueeID },
      filterCount,
    },
    dispatch,
  } = useIssueList(pageId);

  const canVisitUrl = details?.isCustomDomainEnabled
    ? details?.isCustomARecordVerified && details?.isCustomTxtVerified
    : true;
  const sqUrl = getSquadcastUrl(details?.domainName);
  const url = details?.isCustomDomainEnabled ? `https://${details?.customDomainName}` : sqUrl;
  const canEdit = useUserAccess().hasUpdateAccess('status_pages', `${pageId}`);

  if (isLoading)
    return (
      <Center h="60vh" data-testid="loader">
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      </Center>
    );

  const getDrawerContent = (type: string) => {
    if (type === DRAWER_TYPES.CONFIG) {
      return (
        <UpdatePageConfig
          pageId={pageId}
          isEdit={isEditFlow}
          setIsEditFlow={setIsEditFlow}
          details={details}
          closeDrawer={closeDrawer}
          refetch={refetch}
          canEdit={canEdit}
        />
      );
    }
    if (type === DRAWER_TYPES.THEME) {
      return (
        <UpdatePageTheme
          pageId={pageId}
          isEdit={isEditFlow}
          setIsEditFlow={setIsEditFlow}
          details={details}
          refetch={refetch}
          closeDrawer={closeDrawer}
          canEdit={canEdit}
        />
      );
    }
    if (type === DRAWER_TYPES.STATUS) {
      return (
        <StatusMessage
          pageId={pageId}
          onClose={closeDrawer}
          isEdit={isEditFlow}
          onSuccess={() => {
            refetch();
            reactQueryConfigSuccess('Status message updated');
            closeDrawer();
          }}
          onEdit={() => setIsEditFlow(true)}
          canEdit={canEdit}
        />
      );
    }
    if (type === DRAWER_TYPES.COMPONENTS) {
      return (
        <StatusComponent
          isServiceConnectorEnabled={isServiceConnectorEnabled}
          pageId={pageId}
          closeDrawer={closeDrawer}
          init={details.components}
          refetch={refetch}
          canEdit={canEdit}
          statusCodes={details?.statusCodes || []}
        />
      );
    }
    if (type === DRAWER_TYPES.ADD_ISSUE) {
      return <IssueAdd pageId={pageId} onCancel={closeDrawer} onSuccess={refetchIssueList} />;
    }
    if (type === DRAWER_TYPES.TEMPLATE) {
      return (
        <UpdateMessageTemplate
          pageId={pageId}
          isEdit={isEditFlow}
          refetch={refetch}
          setIsEditFlow={setIsEditFlow}
          templates={details.messageTemplates}
          closeDrawer={closeDrawer}
          canEdit={canEdit}
        />
      );
    }
    if (type === DRAWER_TYPES.SUBSCRIBER) {
      return <SubscribersSettings pageId={pageId} onClose={closeDrawer} canEdit={canEdit} />;
    }
    return null;
  };
  return (
    <Box data-testid="container">
      <Layout customheight={true}>
        <Box px={'24px'} py={'26px'}>
          <Summary
            details={details}
            openDrawer={openDrawer}
            canVisitUrl={canVisitUrl}
            url={url}
            goBack={() => history.push(STATUSPAGE_V2_PATH)}
            canEdit={canEdit}
          />
        </Box>
      </Layout>
      <Layout customheight={true}>
        <Box px={'24px'} py={'26px'}>
          <IssueListContext.Provider
            value={{
              isIssueListLoading,
              isFetched,
              isIssueListError,
              list: list as IPageIssueList[],
              totalCount,
              isSuccess,
              refetchIssueList,
              issueFilterDetails,
              deleteIssuePageAction,
              timezone: details?.timezone || '',
              state: {
                pagination: { queryPageIndex, queryPageSize },
                search: { enabled: isSearchEnabled },
                drawer: { isDrawerOpen, drawerType: drawerType || '', issueId },
                filters,
                deleteIssue: { showModal, selectedIssueeID },
                filterCount,
              },
              dispatch,
            }}
          >
            <Panel pageId={pageId} url={url} timezone={details.timezone} />
          </IssueListContext.Provider>
        </Box>
      </Layout>
      <CustomDrawerComponent
        onClose={() => {
          closeDrawer();
        }}
        disableBodyPadding={drawerOption.drawerType === DRAWER_TYPES.ADD_ISSUE}
        isOpen={drawerOption.isOpen}
        title={getDrawerHeader(drawerOption.drawerType, isEditFlow)}
      >
        {getDrawerContent(drawerOption.drawerType)}
      </CustomDrawerComponent>
    </Box>
  );
};

export default StatusPageDetails;
