import { Box, ChakraProvider, Divider } from '@chakra-ui/react';
import RouteLeavingGuard from 'components/UnsavedChangeNavigation';
import { EventWebhookHooksService } from 'core';
import { IEventHookServiceEntity } from 'core/interfaces/IEventWebHooks';
import { fontTheme } from 'library/theme';
import { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ErrorBoundary, HorzStepper, Loader } from 'views/shared/components';

import { AddWebhookDetails } from '../add-details/add-details';
import { ChoosePayload } from '../choose-payload/choose-payload';
import ChooseType from '../choose-type/choose-type';
import { INITIAL_WEBHOOK_STATE, WEBHOOK_ACTIONS, WebhookReducer } from '../store';
import { WEBHOOK_STEPPER, WEBHOOK_STEPPER_INDEX, WebhookContextType } from '../webhook-const';
import styles from './create-webhook.module.css';

export const WebhookContext = createContext<WebhookContextType>({
  state: INITIAL_WEBHOOK_STATE,
  dispatch: () => null,
});

export const CreateWebhook = () => {
  const _eventWebHookService = new EventWebhookHooksService();

  const [currentStep, setCurrentStep] = useState(WEBHOOK_STEPPER_INDEX.ADD_DETAILS);
  const [showWarning, setShowWarning] = useState(true);
  const [isWebhookDataLoading, setIsWebhookDataLoading] = useState(true);

  const [services, setServices] = useState<IEventHookServiceEntity[]>([]);

  const history = useHistory();
  const location = useLocation();
  const [state, dispatch] = useReducer(WebhookReducer, INITIAL_WEBHOOK_STATE);

  useEffect(() => {
    setServices(location.state.services);
    if (location.state.webhookId) {
      getWebhookDetails(location.state.webhookId);
    } else {
      setIsWebhookDataLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (state.isDataLoaded) {
      setIsWebhookDataLoading(false);
      setCurrentStep(location.state.stepperIndex);
    }
  }, [state.isDataLoaded]);

  const nextStep = () => {
    if (currentStep < WEBHOOK_STEPPER_INDEX.CHOOSE_PAYLOAD) {
      setCurrentStep(currentStep + 1);
    } else {
      setShowWarning(false);
    }
  };

  const getWebhookDetails = (webhookId: string) => {
    if (!webhookId) {
      return;
    }
    _eventWebHookService.get(webhookId).then(({ data }) => {
      dispatch({ type: WEBHOOK_ACTIONS.GET_DETAILS, payload: data.data });
    });
  };

  const onExit = () => {
    history.push('/settings/webhooks');
  };

  const renderStepper = useCallback(() => {
    switch (currentStep) {
      case WEBHOOK_STEPPER_INDEX.ADD_DETAILS:
        return <AddWebhookDetails onSave={nextStep} onCancel={onExit} />;
      case WEBHOOK_STEPPER_INDEX.CHOOSE_TYPE:
        return <ChooseType onSave={nextStep} onCancel={onExit} services={services} />;
      default:
        return <ChoosePayload onSave={nextStep} onCancel={onExit} />;
    }
  }, [currentStep]);

  return (
    <ChakraProvider theme={fontTheme}>
      <ErrorBoundary>
        <HorzStepper
          steps={WEBHOOK_STEPPER}
          currentStep={currentStep}
          clickHandler={stepIndex => setCurrentStep(stepIndex)}
        />
        <Divider height={0.5} backgroundColor="shades.smoke" />
        <WebhookContext.Provider value={{ state, dispatch }}>
          {isWebhookDataLoading ? <Loader /> : <Box className={styles.form}>{renderStepper()}</Box>}
        </WebhookContext.Provider>
        <RouteLeavingGuard
          when={showWarning && currentStep != WEBHOOK_STEPPER_INDEX.ADD_DETAILS}
          shouldBlockNavigation={() => true}
          navigate={path => history.push(path)}
        />
      </ErrorBoundary>
    </ChakraProvider>
  );
};
