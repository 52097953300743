import { Para, Theme } from 'uie/components';
import { ILog } from 'core/interfaces/IIncidents';
import { IStatusPage } from 'core/interfaces/IStatusPage';
import { GeneralActionTypes } from 'views/main/organization/navigation-flows/helpers';
import { taskAndRunbookEventList } from '../../tasksAndRunbook/taskAndRunbook.constants';
import CircleCIRebuild from './customMessages/CircleCIRebuild';
import PublicMessages from './customMessages/PublicMessages';
import TaskAndRunbookMessage from './customMessages/TaskAndRunbookMessage';

export type IGeneralMessages = {
  log: ILog;
  logMessageActivity: string;
  entityComponent: JSX.Element;
  userName?: any;
  statusPages?: { [key: string]: IStatusPage };
  setFocusMessageId?: (id: string, type?: string | undefined, index?: number | undefined) => void;
};

const GeneralMessages = (data: IGeneralMessages) => {
  const { log, logMessageActivity, entityComponent, setFocusMessageId, statusPages, userName } =
    data;
  const { theme } = Theme;
  if (taskAndRunbookEventList.includes(log.action)) {
    return <TaskAndRunbookMessage {...data} />;
  }

  const logActionMap = {
    incident_snoozed: () => (
      <>
        {logMessageActivity}
        {entityComponent}
      </>
    ),
    incident_unsnoozed_automatically: () => <>{log.reason}</>,
    incident_unsnoozed_manually: () => (
      <>
        {logMessageActivity}
        {entityComponent}
      </>
    ),
    global_rules_executed: () => <>{log.reason}</>,
    delayed_notification: () => <>{logMessageActivity}</>,
    delayed_notification_resumed: () => (
      <>
        {logMessageActivity.split('$1')[0]}
        {entityComponent}
        {logMessageActivity.split('$1')[1]}
      </>
    ),
    workflow_triggered: () => (
      <>
        Workflow triggered &nbsp;
        {entityComponent}
        {logMessageActivity}
      </>
    ),
    iag_vote_down: () => (
      <>
        {logMessageActivity} by {entityComponent}
      </>
    ),
    iag_vote_up: () => (
      <>
        {logMessageActivity} by {entityComponent}
      </>
    ),
    apta_violated: () => <>{log.reason}</>,
    marked_as_transient_by_squadcast: () => <>{log.reason}</>,

    incident_priority_updated: () => (
      <>
        {logMessageActivity} by {entityComponent}
      </>
    ),
    note_starred: () => {
      const { theme } = Theme;
      return (
        <>
          {entityComponent}
          {logMessageActivity}
          {log.additionalInfo?.note && (
            <Para
              fontSize={12}
              color={theme.primary.default}
              style={{ marginBottom: 2, cursor: 'pointer' }}
              onClick={() => setFocusMessageId && setFocusMessageId(log.additionalInfo!.note_id!)}
            >
              {log.additionalInfo!.note!.length <= 30
                ? log.additionalInfo!.note!
                : `${log.additionalInfo!.note!.slice(0, 30)}...`}
            </Para>
          )}
        </>
      );
    },
    public_message: () => {
      const props = {
        entityComponent,
        log,
        statusPages,
      };
      return <PublicMessages {...props} />;
    },
    circleci_rebuild: () => {
      const props = {
        log,
        userName,
      };
      return <CircleCIRebuild {...props} />;
    },

    additional_responders_added: () => (
      <>
        {logMessageActivity} by {entityComponent}
      </>
    ),
    additional_responders_removed: () => (
      <>
        {logMessageActivity} by {entityComponent}
      </>
    ),
  };

  const renderContent = logActionMap[log.action as GeneralActionTypes];

  return renderContent ? (
    <Para fontSize={14} color={theme.shades.cement}>
      {renderContent()}
    </Para>
  ) : null;
};

export default GeneralMessages;
