import React from 'react';
import { ErrorBlock, Grid, SpinLoader, Heading } from 'uie/components';
import { DedupeKeysModal } from '.';
import cx from 'classnames';
import './index.css';
import { Button, HStack } from '@chakra-ui/react';
import { RoutingRules } from '..';
import Header from './header';
import Duration from './duration';
import AlertSourceSelection from './alertSourceSelection';
import Output from './output';
import TemplateHeader from './templateHeader';
import TemplateEditor from './templateEditor';

function render(this: DedupeKeysModal) {
  const {
    alertSourcesLoadState,
    alertSourceSearch,
    alertSource,
    alertSourceErrorMessage,
    dedupeKey,
    event,
    eventState,
    enableEditKey,
    errors,
    saving,
    fetching,
    parsing,
    deleting,
    loading,
    networkError,
  } = this.state;

  const {
    alertSourceName,
    onDeleteAction,
    alertSourcesConfiguredWithDedupeKeys,
    isDirty,
    onCopyDedupeKey,
    mode,
    enableDedupeKeyEditMode,
  } = this.props;

  const {
    alertSources,
    enableEditMode,
    getDedupeKeyOutput,
    onChangeDedupeKeyDuration,
    onChangeDedupeKeyTimeUnit,
    onChangeDedupeKeyTemplate,
    onTextChange,
    onSelectChange,
  } = this;

  const isEditMode = mode === 'edit';
  const isViewMode = mode === 'view';
  const isCreateMode = mode === 'create';

  const noEventsForAlertSource =
    eventState === 'noEvents' || (eventState === 'idle' && Object.keys(event.past).length === 0);

  const areFormFieldsDisabled = saving || !alertSource || !dedupeKey.key || !dedupeKey.time_window;

  const isShowOutputButtonDisabled =
    saving || !alertSource || !dedupeKey.key || noEventsForAlertSource;

  const isSaveButtonDisabled =
    areFormFieldsDisabled || (isEditMode && !isDirty) || fetching || parsing;

  const isDeleteButtonDisabled = saving || parsing || fetching;

  return (
    <>
      <Header {...{ isViewMode }} />
      <div>
        <Grid flexWidth={12} justifyContent="flex-start" alignItems="stretch">
          <Grid flexWidth={7}>
            {loading ? (
              <div className="w-1-1" style={{ height: 300, position: 'relative' }}>
                <Grid type="column" justifyContent="center">
                  <Grid justifyContent="center">
                    <SpinLoader />
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div className="w-1-1" style={{ position: 'relative', paddingBottom: '50px' }}>
                <div className="w-1-1">
                  {!isViewMode && <TemplateHeader />}

                  <div
                    id={`dedup_key_container`}
                    className={cx('main-rule-rectangle')}
                    tabIndex={0}
                  >
                    <TemplateEditor
                      {...{
                        dedupeKey,
                        enableEditMode,
                        isEditMode,
                        isViewMode,
                        onChangeDedupeKeyTemplate,
                        onCopyDedupeKey,
                        saving,
                        enableEditKey,
                      }}
                    />

                    {!isViewMode && (
                      <Output {...{ dedupeKey, isShowOutputButtonDisabled, getDedupeKeyOutput }} />
                    )}
                  </div>
                  <Duration
                    {...{
                      dedupeKey,
                      onChangeDedupeKeyDuration,
                      errors,
                      onChangeDedupeKeyTimeUnit,
                      isViewMode,
                    }}
                  />
                  {networkError && <div className="error-block">{networkError}</div>}
                </div>
                <div>
                  {errors.find(err => err === 'Invalid duration') && (
                    <div>
                      <ErrorBlock style={{ padding: '0px' }}>
                        {dedupeKey.time_window === 0
                          ? 'Duration cannot be zero'
                          : 'The maximum time limit is 48 hours'}
                      </ErrorBlock>
                    </div>
                  )}
                  <HStack justifyContent="flex-start" position="absolute" bottom={0}>
                    <Button
                      onClick={
                        isViewMode && alertSourceName
                          ? () => enableDedupeKeyEditMode(alertSourceName)
                          : this.save
                      }
                      disabled={isSaveButtonDisabled}
                      isLoading={saving}
                      loadingText={isViewMode ? 'Edit' : 'Save'}
                      variant="default"
                      size="sm"
                    >
                      {isViewMode ? 'Edit' : 'Save'}
                    </Button>
                    {isEditMode && (
                      <Button
                        onClick={() => {
                          onDeleteAction('', [], RoutingRules.dedupekey, alertSourceName);
                        }}
                        disabled={isDeleteButtonDisabled}
                        isLoading={deleting}
                        loadingText="Delete"
                        variant="warn"
                        size="sm"
                      >
                        Delete
                      </Button>
                    )}
                  </HStack>
                </div>
              </div>
            )}
          </Grid>
          <AlertSourceSelection
            {...{
              alertSourceSearch,
              alertSource,
              alertSources,
              alertSourcesLoadState,
              alertSourcesConfiguredWithDedupeKeys,
              alertSourceErrorMessage,
              isCreateMode,
              event,
              eventState,
              onTextChange,
              onSelectChange,
            }}
          />
        </Grid>
      </div>
    </>
  );
}

export default render;
