import { HStack, VStack } from '@chakra-ui/layout';
import { UnionIcon } from 'icons';
import { useEffect, useRef } from 'react';
import { focusCard } from 'core';
import { Theme } from 'uie/components';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import { Text } from 'library/atoms/Text';
const GreetingMessage = ({ data, id }: { data: string; id: string }) => {
  const { theme } = Theme;
  const flexRef = useRef(null);

  const targetAction = window.location.hash.split('?')[0];

  const styleActionOnTarget =
    targetAction === '#' + id ? `1px solid ${theme.primary.default}` : 'secondary.200';

  useEffect(() => {
    if (flexRef.current && targetAction === '#' + id) {
      focusCard(flexRef.current);
    }
  }, [targetAction]);
  return (
    <VStack align="stretch" px={6} pb={2} gap={2}>
      <HStack gap={2}>
        <UnionIcon width={24} height={24} />
        <Text variant="h2_800" color="secondary.1000">
          Greeting Message{' '}
        </Text>
      </HStack>
      <HStack
        bg="brand.white"
        p={4}
        width="50dvw"
        borderWidth="1px"
        borderStyle="solid"
        border={styleActionOnTarget}
        align="center"
        borderRadius={'6px'}
        id="trigger"
        ref={flexRef}
      >
        <ReactMarkdown className="mde" plugins={[breaks, gfm]}>
          {data}
        </ReactMarkdown>
      </HStack>
    </VStack>
  );
};

export default GreetingMessage;
