import { IServiceExpressionOperation } from '../interfaces/IService';

const opFormats = {
  [IServiceExpressionOperation.EOpNoOp]: '',
  [IServiceExpressionOperation.EOpIs]: '{lhs} == {rhs}',
  [IServiceExpressionOperation.EOpIsNot]: '{lhs} != {rhs}',
  [IServiceExpressionOperation.EOpMatches]: 're({lhs}, {rhs})',
  [IServiceExpressionOperation.EOpNotContains]: '!re({lhs}, {rhs})',
  [IServiceExpressionOperation.EOpGT]: '{lhs} > {rhs}',
  [IServiceExpressionOperation.EOpLT]: '{lhs} < {rhs}',
  [IServiceExpressionOperation.EOpGTEQ]: '{lhs} >= {rhs}',
  [IServiceExpressionOperation.EOpLTEQ]: '{lhs} <= {rhs}',
  [IServiceExpressionOperation.EOpFieldIs]: '{lhs} == {rhs}',
};

const stringComparators: any = {
  [IServiceExpressionOperation.EOpIs]: '==',
  [IServiceExpressionOperation.EOpIsNot]: '!=',
  [IServiceExpressionOperation.EOpMatches]: 'matches/ contains',
  [IServiceExpressionOperation.EOpNotContains]: 'does not contain',
};

const numberComparators: any = {
  [IServiceExpressionOperation.EOpIs]: '==',
  [IServiceExpressionOperation.EOpGT]: '>',
  [IServiceExpressionOperation.EOpLT]: '<L',
  [IServiceExpressionOperation.EOpGTEQ]: '>=',
  [IServiceExpressionOperation.EOpLTEQ]: '<=',
};

const booleanComparators: any = {
  [IServiceExpressionOperation.EOpIs]: '==',
};

const allOperators: any = {
  [IServiceExpressionOperation.EOpIs]: '==',
  [IServiceExpressionOperation.EOpIsNot]: '!=',
  [IServiceExpressionOperation.EOpMatches]: 'matches/ contains',
  [IServiceExpressionOperation.EOpNotContains]: 'does not contain',
  [IServiceExpressionOperation.EOpGT]: '>',
  [IServiceExpressionOperation.EOpLT]: '<',
  [IServiceExpressionOperation.EOpGTEQ]: '>=',
  [IServiceExpressionOperation.EOpLTEQ]: '<=',
  [IServiceExpressionOperation.EOpFieldIs]: 'is same as',
};

export { opFormats, allOperators, booleanComparators, numberComparators, stringComparators };
