import Axios from 'axios';
import { Locale } from 'core/helpers/dateUtils';
import moment from 'moment';
import qs from 'query-string';
import { API } from '../../api';
import {
  IHeatmapAPIResponse,
  IIEvent,
  IIncident,
  IIncidentCount,
  IIncidentCreatePayload,
  IIncidentList,
  IIncidentRequestParams,
  IIncidentTags,
  IITags,
  IPastIncident,
  IPastIncidentListCursorMeta,
  IPastIncidentStatsResponse,
  IReassignIncidentRequest,
  ISnoozeIncidentRequest,
  IUnSnoozeIncidentRequest,
  IReassignIncidentRequestV3,
  slo,
  SloArray,
  SloViolatingIncident,
} from '../../interfaces/IIncidents';

class IncidentService {
  private _api = `${API.config.endpoint}/organizations/${API.config.organizationId}/incidents`;
  private _batmanApi = `${API.config.batman}/organizations/${API.config.organizationId}/incidents`;
  private _ingesterApi = `${API.config.ingester}/private/incidents/squadcastui`;
  private _sloDetailApi = `${API.config.batman}/organizations/${API.config.organizationId}/slo`;

  public create = ({ serviceApiKey, ...payload }: IIncidentCreatePayload) =>
    Axios.post<{ data: string }>(`${this._ingesterApi}/${serviceApiKey}`, payload);

  public getIncidentList = (props: Partial<IIncidentRequestParams>) =>
    Axios.get<{ data: IIncidentList }>(`${this._batmanApi}?${qs.stringify(props as any)}`);

  public getIncidentById = (incidentId: string) =>
    Axios.get<{ data: IIncident; result: boolean }>(`${this._batmanApi}/${incidentId}`);

  public getIncidentWithSlos = (incident: IIncident, teamId: string) =>
    Axios.get<{ data: { slo_violating_incident: SloViolatingIncident } }>(
      `${this._sloDetailApi}/${incident?.slo_id}/slo-incident?incident-id=${incident.id}&owner_id=${teamId}`,
    );

  public sloArrayData = (teamId: string) =>
    Axios.get<{ data: SloArray }>(`${this._sloDetailApi}?owner_id=${teamId}`);

  public sloAffected = (incidentId: string, teamId: string) =>
    Axios.get<{ data: { slo_violating_incident: SloViolatingIncident } }>(
      `${this._sloDetailApi}/affected-incident?incident-id=${incidentId}&owner_id=${teamId}`,
    );

  public selectSoData = (value: number, teamId: string) => {
    const data = Axios.get<{ data: { slo: slo } }>(
      `${this._sloDetailApi}/${value}?owner_id=${teamId}`,
    );
    return data;
  };

  public getSloViolatingIncidents = (slo_id: number, teamId: string) => {
    const data = Axios.get(`${this._sloDetailApi}/${slo_id}/incident?owner_id=${teamId}`);
    return data;
  };
  public updateErrorBudget = (
    slo_id: number,
    slo_violating_incident_id: number,
    error_budget: any,
    teamId: string,
    fromDate: Date,
    toDate: Date,
  ) => {
    const data = Axios.patch(
      `${
        this._sloDetailApi
      }/${slo_id}/incident/${slo_violating_incident_id}/error-budget-spent/${error_budget}?owner_id=${teamId}&fromDate=${moment(
        fromDate,
      ).toISOString()}&toDate=${moment(toDate).toISOString()}`,
    );
    return data;
  };
  public acknowledgeIncident = (incidentId: string) =>
    Axios.post(`${this._batmanApi}/${incidentId}/acknowledge`, {});

  public affectedSloSaved = async (
    selectedSloDataValue: number,
    incident_id: string,
    slis: string[],
    error_budget_spent: number,
    owner_id: string,
  ) => {
    const data = {
      incident_id: incident_id,
      slis: slis,
      owner_id: owner_id,
      org_id: API.config.organizationId,
      error_budget_spent: error_budget_spent,
    };
    const res = Axios.post(
      `${this._sloDetailApi}/${selectedSloDataValue}/incident?owner_id=${owner_id}`,
      data,
    );
    return res;
  };

  public isMarkedFalsePositive = (slo_id: number, id: number, isfalse: string, teamId: string) => {
    return Axios.patch(
      `${this._sloDetailApi}/${slo_id}/incident/${id}/false-positive/${isfalse}?owner_id=${teamId}`,
    );
  };

  public resolveIncident = (incidentId: string) =>
    Axios.post(`${this._batmanApi}/${incidentId}/resolve`, {});

  public snoozeIncident = (incidentId: string, snoozePayload: ISnoozeIncidentRequest) =>
    Axios.put(`${this._batmanApi}/${incidentId}/snooze`, snoozePayload);

  public unSnoozeIncident = (incidentId: string, unSnoozePayload: IUnSnoozeIncidentRequest) =>
    Axios.put(`${this._batmanApi}/${incidentId}/unsnooze`, unSnoozePayload);

  public reAssignIncident = (reassignPayload: IReassignIncidentRequest) =>
    Axios.post(`${this._api}/${reassignPayload.incidentId}/reassign`, reassignPayload);
  public reAssignIncidentV3 = (reassignPayload: IReassignIncidentRequestV3, incidentId: string) => {
    Axios.post(`${this._batmanApi}/${incidentId}/reassign`, reassignPayload);
  };

  public getIncidentEvents = (incidentId: string, from: number) =>
    Axios.get<{
      data: {
        events: IIEvent[];
      };
      meta: {
        count: number;
      };
    }>(`${this._batmanApi}/${incidentId}/events?offset=${from}&limit=10&deduped=true`);

  public getIncidentPayload = (incidentId: string) =>
    Axios.get<{
      data: {
        events: IIEvent[];
      };
      meta: {
        count: number;
      };
    }>(`${this._batmanApi}/${incidentId}/events?offset=0&limit=1&deduped=false`);

  public exportTimeline = (type: 'pdf' | 'csv', incidentId: string) =>
    Axios.get<{ data: any; result: boolean }>(
      `${this._batmanApi}/${incidentId}/timeline/export/${type}?timezone_offset=${Locale.timeZoneOffset}`,
    );

  public getAllTags = (teamId?: string) =>
    Axios.get<{ data: IIncidentTags[] }>(`${this._batmanApi}/tags`, {
      params: {
        owner_id: teamId,
      },
    });

  public updateTags = (incidentId: string, data: IITags) =>
    Axios.put(`${this._batmanApi}/${incidentId}/tags`, { tags: data });

  public bulkAckIncident = (incidentIDs: string[]) =>
    Axios.post<{ result: boolean }>(`${this._batmanApi}/acknowledge`, {
      incident_ids: incidentIDs,
    });

  public bulkResolveIncident = (incidentIDs: string[]) =>
    Axios.post<{ result: boolean }>(`${this._batmanApi}/resolve`, { incident_ids: incidentIDs });

  public getIncidentsBeforeRetentions = () =>
    Axios.get<{ data: { count: number } }>(`${this._batmanApi}/before-retention`);

  public getIncidentsCount = (params: Partial<IIncidentRequestParams>) =>
    Axios.get<{ data: IIncidentCount }>(`${this._batmanApi}/stats`, { params });

  public getIncidentsMetrics = (params: Partial<IIncidentRequestParams>) =>
    Axios.get<{ data: IIncidentCount }>(`${this._batmanApi}/metrics`, { params });

  public getChildIncidents = (teamId: string, incidentId: string) =>
    Axios.get<{
      data: {
        children: IIncident[];
      };
    }>(`${this._batmanApi}/${incidentId}/children?owner_id=${teamId}`);

  public mergeIncidentsInExistingParent = (
    teamId: string,
    parentIncident: string,
    childIncidents: string[],
  ) =>
    Axios.post<{
      data: {
        message: string;
        children: string[];
      };
      meta: Record<string, unknown>;
    }>(`${this._batmanApi}/merge`, {
      owner_id: teamId,
      parent_incident_id: parentIncident,
      children: childIncidents,
    });

  public mergeIncidentsInANewParent = (payload: Record<string, unknown>) =>
    Axios.post<{
      data: {
        message: string;
        children: string[];
      };
      meta: Record<string, unknown>;
    }>(`${this._batmanApi}/merge`, payload);

  public unMergeIncident = (
    teamId: string,
    incidentId: string,
    assignToMe: boolean,
    sendNotification: boolean,
  ) =>
    Axios.put<{
      data: Record<string, unknown>;
      meta: Record<string, unknown>;
    }>(`${this._batmanApi}/${incidentId}/unmerge?owner_id=${teamId}`, {
      assign_me: assignToMe,
      send_notification: sendNotification,
    });

  public getPastIncidentsHeatmap = (incidentId: string, userTimezone: string) => {
    const apiURL = new URL(`${this._batmanApi}/${incidentId}/past-incidents/heatmap`);
    const params = new URLSearchParams(apiURL.search);
    params.set('tz', userTimezone);
    return Axios.get<{
      data: IHeatmapAPIResponse;
    }>(`${apiURL}?${params.toString()}`);
  };

  public getPastIncidents = (incidentId: string, queryParams: Record<string, string>) => {
    const apiURL = new URL(`${this._batmanApi}/${incidentId}/past-incidents`);
    const params = new URLSearchParams(apiURL.search);
    Object.keys(queryParams).forEach(key => params.set(key, queryParams[key]));
    return Axios.get<{
      data: IPastIncident[];
      meta: IPastIncidentListCursorMeta;
    }>(`${apiURL}?${params.toString()}`);
  };

  public getPastIncidentStatistics = (incidentId: string) =>
    Axios.get<{
      data: IPastIncidentStatsResponse;
    }>(`${this._batmanApi}/${incidentId}/past-incidents/stats`);

  public markAsTransient = (incidentId: string) =>
    Axios.put(`${this._batmanApi}/${incidentId}/mark-as-transient`);

  public markAsNotTransient = (incidentId: string) =>
    Axios.put(`${this._batmanApi}/${incidentId}/mark-as-non-transient`);
}

export default IncidentService;
