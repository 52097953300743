import { Box, SimpleGrid } from '@chakra-ui/react';

import AlertSource from '../components/AlertSource';

interface Configured {
  short_name: string;
  id: string;
  type: string;
  version: string;
  supportDoc: string;
  shortName: string;
  _id: string;
}

function addAlertSourceListing({
  configuredAlertSourceList,
  alertSourcesList,
  api_key,
  serviceId,
  addAlertSource,
  selected,
  email,
  refetchKey,
}: {
  alertSourcesList?: any;
  configuredAlertSourceList?: any;
  api_key: string | undefined;
  serviceId: string | undefined;
  addAlertSource?: (id: string) => void;
  selected?: string[];
  email?: string;
  refetchKey: () => void;
}) {
  return (
    <SimpleGrid px={2} columns={[5, 5, 5, 5, 5, 6, 10]} spacing={2}>
      {alertSourcesList &&
        alertSourcesList.map((item: Configured, indx: number) => {
          return (
            <Box key={indx}>
              <AlertSource
                name={item.type}
                shortName={item.shortName}
                id={item._id}
                version={item.version}
                api_key={api_key}
                serviceId={serviceId}
                supportDoc={item.supportDoc}
                type={item.type}
                addAlertSource={addAlertSource}
                selected={selected}
                email={email}
                refetchKey={refetchKey}
              />
            </Box>
          );
        })}
      {configuredAlertSourceList &&
        configuredAlertSourceList.map((item: Configured, indx: number) => {
          return (
            <Box key={indx}>
              <AlertSource
                name={item.type}
                shortName={item.shortName}
                id={item?.id}
                version={item.version}
                api_key={api_key}
                serviceId={serviceId}
                supportDoc={item.supportDoc}
                type={item.type}
                addAlertSource={addAlertSource}
                selected={selected}
                title="configuredAlertSource"
                email={email}
                refetchKey={refetchKey}
              />
            </Box>
          );
        })}
    </SimpleGrid>
  );
}

export default addAlertSourceListing;
