import { UseRowSelectInstanceProps } from 'react-table';
import { INCIDENT_DATA } from '../common/types';
import { ListIncidentsQuery } from '../graphql/query';
import { ChangeEvent } from 'react';
import { Checkbox } from 'library/atoms';

interface Props {
  rows: NonNullable<ListIncidentsQuery['listIncidents']['data']>;
  selectedRowIds: Array<string>;
  updateCommonStatus: (rowIds: string[]) => void;
  setSelectedRowIds: (checkedRows: string[]) => void;
}

const HeaderCheckbox =
  ({ rows, selectedRowIds, setSelectedRowIds, updateCommonStatus }: Props) =>
  ({ getToggleAllRowsSelectedProps }: UseRowSelectInstanceProps<INCIDENT_DATA>) => {
    const { onChange, ...args } = getToggleAllRowsSelectedProps();
    const filteredRowIds = rows.map(row => row?.id ?? '');
    let isAllChecked =
      !!filteredRowIds.length && filteredRowIds.every(rowId => selectedRowIds.includes(rowId));
    const onSelectAllRows = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      let checkedRowIds: string[] = [];
      if (!isAllChecked) {
        checkedRowIds = filteredRowIds;
        updateCommonStatus(checkedRowIds);
      }

      setSelectedRowIds(checkedRowIds);

      isAllChecked = !isAllChecked;
    };
    const isIndeterminate = selectedRowIds.length > 0 && !isAllChecked;
    return (
      <Checkbox
        {...args}
        isIndeterminate={isIndeterminate}
        isChecked={isAllChecked}
        onChange={onSelectAllRows}
        size="lg"
      />
    );
  };

export default HeaderCheckbox;
