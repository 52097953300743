import { BetaIcon, GenAiIcon } from 'icons';
import { Grid, Para } from 'uie/components';
import { useIncidentSummaryContext } from '../../context/hook';
import { IncidentSummaryDrawerState } from '../../types';
import IncidentSummaryDrawerSuccessTitle from './success/Title';
import IncidentSummaryDrawerFeedbackTitle from './feedback/Title';

const IncidentSummaryDrawerTitle: React.FC = () => {
  const { drawerState } = useIncidentSummaryContext();

  if (drawerState === IncidentSummaryDrawerState.SUCCESS)
    return <IncidentSummaryDrawerSuccessTitle />;

  if (drawerState === IncidentSummaryDrawerState.FEEDBACK)
    return <IncidentSummaryDrawerFeedbackTitle />;

  return (
    <Grid alignItems="center" justifyContent="center">
      <GenAiIcon height={46} width={46} className="mr-5" />
      <Para fontSize={27} fontWeight={400}>
        Incident Summary
      </Para>
      <BetaIcon
        height={18}
        width={33}
        className="newFlag"
        style={{ position: 'relative', top: 3, left: 5 }}
      />
    </Grid>
  );
};

export default IncidentSummaryDrawerTitle;
