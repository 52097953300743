import { Box, Button, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { T_WA_UP_STATUS_PAGE_V2_CREATION_PAGE_1_VIEWED } from 'core/const/tracker';
import { useFormikContext } from 'formik';
import { THEME_COLORS } from 'library/theme/colors';
import React, { Fragment, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppTracker } from 'shared/analytics/tracker';
import { StatusPageContext } from '../..';
import HelpGuide from '../../components/HelpGuide';
import { PAGE_TYPES } from '../../constants/schema';
import { statusConfigHelpGuide } from '../../constants/statuspage.copy';
import { getPageInputDetails } from '../../helpers/helper.service';
import { useCheckDuplicateDomainStatus } from '../../hooks/useCheckDuplicateStatus';
import PageConfigForm from './pageConfigurationForm/pageConfigurationForm';

const StatuPageDetail: React.FC<any> = ({ ...props }) => {
  const statusPageCtx = useContext(StatusPageContext);

  const history = useHistory();

  const { initValues } = getPageInputDetails(PAGE_TYPES.DETAILS);
  const { submitForm, values } = useFormikContext<typeof initValues>();

  const { data: exisitingDomaindetail } = useCheckDuplicateDomainStatus(
    values.squadcastDomainName ?? '',
  );

  const checkDuplicateTitleAndDomain = async () => {
    if (!exisitingDomaindetail) {
      // means the input domain is unique in DB and did not find it
      submitForm();
    }
  };

  useEffect(() => {
    AppTracker.track(T_WA_UP_STATUS_PAGE_V2_CREATION_PAGE_1_VIEWED);
  }, []);
  return (
    <Fragment>
      <Box pt="6" bgColor={'white'}>
        <Text color={THEME_COLORS.secondary[900]} fontSize="30px" mb="2">
          Add Status Page Details
        </Text>
        <Text color={THEME_COLORS.secondary[900]} fontSize="14px">
          Keep your customers updated on how your systems are doing without them having to ask you
          about it
        </Text>
        <form>
          <PageConfigForm />
          <Divider />
          <Box w="100%" bottom={0} mx={-6}>
            <HStack px={6} py={4} gap={4} sx={{ position: 'static', bottom: 0 }}>
              <Button onClick={checkDuplicateTitleAndDomain} variant="default">
                Next : Add Component
              </Button>
              <Button
                variant="invertedDefault"
                onClick={() => {
                  history.push(statusPageCtx.statusPagePaths.STATUSPAGE_V2_PATH);
                }}
              >
                Cancel
              </Button>
            </HStack>
          </Box>
        </form>
      </Box>
      <VStack spacing={4} align="stretch" h="100%" backgroundColor={'white'}>
        <HelpGuide helpGuide={statusConfigHelpGuide} />
      </VStack>
    </Fragment>
  );
};

export default StatuPageDetail;
