import { ChangeEvent, Component, RefObject, createRef } from 'react';
import { connect } from 'react-redux';
import { requestOrganizationSquad } from 'core/actions';
import { requestOrganizationTeams } from 'core/actions/organization/teams';
import { IAppState } from 'core/interfaces/IAppState';
import { IComponentErrors, IComponentNetworkState } from 'core/interfaces/IComponentState';
import { ISquad } from 'core/interfaces/ISquad';
import { ITeam } from 'core/interfaces/ITeams';
import { SquadsService } from 'core/services';
import { exception } from 'core/exception';
import { render } from './render.index';
import { checkIfNotValid, focusCard, objectIsEmpty } from 'core/helpers';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import equal from 'fast-deep-equal/es6/react';
import { deepCopy } from 'core';
import { createStandaloneToast } from '@chakra-ui/react';
import { DeleteSquadConflictData } from './types';
import { UserAccessContextValue, withUserAccess } from 'core/userAccess/UserAccessContext';
import { SettingPageACL, withSettingPageACL } from '../../acl';

const toast = createStandaloneToast();

interface IProps extends Pick<IAppState, 'organization'>, RouteComponentProps {
  team: ITeam;
  refreshId: number;
  search: string;
  requestOrganizationSquad: typeof requestOrganizationSquad;
  requestOrganizationTeams: typeof requestOrganizationTeams;
  updateChildInRequest: (request: boolean) => void;
  hasUnsavedChanges: boolean;
  sethasUnsavedOnChild: (unsavedExist: boolean) => void;
  aclStore: SettingPageACL;
}

interface IState {
  squads: ISquad[];
  networkState: IComponentNetworkState | 'save';
  newSquad: Pick<ISquad, 'name' | 'members'> | null;
  deleteSquadId: string;
  deleteSquadName: string;
  isDeleteConflictModalOpen: boolean;
  editSquadId: string;
  searchMembers: string;
  errors: IComponentErrors<
    | 'new_squad__name'
    | 'new_squad__members'
    | 'edit_squad__name'
    | 'edit_squad__members'
    | 'network_err'
  >;
  hasUnsavedChanged: boolean;
  conflictData: DeleteSquadConflictData | null;
}

export class OrganizationSettingsTeamsRenderSquads extends Component<IProps, IState> {
  private _squadService = new SquadsService();
  _squadBlockRef: { [key: string]: HTMLDivElement } = {};
  isSquadFocused = false;

  constructor(props: IProps) {
    super(props);
    this.state = {
      squads: [],
      newSquad: null,
      networkState: 'request',
      deleteSquadId: '',
      deleteSquadName: '',
      isDeleteConflictModalOpen: false,
      editSquadId: '',
      searchMembers: '',
      errors: {},
      hasUnsavedChanged: false,
      conflictData: null,
    };
  }
  public focusTargetSquadBlock() {
    const targetSquadId = window.location.hash.split('?')[0]?.replace('#', '');
    if (this._squadBlockRef[targetSquadId]) {
      this.isSquadFocused = true;
      focusCard(this._squadBlockRef[targetSquadId]);
    }
  }
  render = render;

  componentDidMount() {
    this.getAllSquads();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (!this.state.hasUnsavedChanged) {
      if (prevState.newSquad !== null) {
        if (!equal(this.state.newSquad, prevState.newSquad)) {
          this.setState({ hasUnsavedChanged: true });
        }
      }
      if (prevState.editSquadId !== '') {
        if (!equal(this.state.editSquadId, prevState.editSquadId)) {
          this.setState({ hasUnsavedChanged: true });
        }
      }
      if (prevState.squads.length !== 0) {
        if (!equal(this.state.squads, prevState.squads)) {
          this.setState({ hasUnsavedChanged: true });
        }
      }
    }
    if (this.state.hasUnsavedChanged) {
      if (this.state.newSquad === null && !this.state.editSquadId) {
        this.setState({ hasUnsavedChanged: false });
      }
    }

    if (
      prevProps.hasUnsavedChanges &&
      prevProps.hasUnsavedChanges !== this.props.hasUnsavedChanges
    ) {
      this.setState({ hasUnsavedChanged: this.props.hasUnsavedChanges });
    } else {
      if (this.state.hasUnsavedChanged && !this.props.hasUnsavedChanges)
        this.props.sethasUnsavedOnChild(true);
      if (prevState.hasUnsavedChanged && !this.state.hasUnsavedChanged)
        this.props.sethasUnsavedOnChild(false);
    }
  }

  getAllSquads = async () => {
    this.props.updateChildInRequest(true);
    this.setState({ networkState: 'request' });
    try {
      const {
        data: { data: squads },
      } = await this._squadService.getAll(this.props.team.id);
      this.setState({ squads });
      squads.length === 0 && this.onAddNewSquad();
    } catch (err: any) {
      exception.handle('E_GET_SQUAD_ALL', err);
    } finally {
      this.props.updateChildInRequest(false);
      this.setState({ networkState: 'idle' });
      this.focusTargetSquadBlock();
    }
  };

  onDeleteConfirm = async () => {
    this.setState({ networkState: 'save' });
    this._squadService
      .delete(this.state.deleteSquadId)
      .then(res => {
        this.getAllSquads();
        this.props.requestOrganizationSquad();
        this.props.requestOrganizationTeams();
      })
      .catch(err => {
        exception.handle('E_SQUAD_DELETE', err);
        if (err.response.data?.meta?.status === 409) {
          this.setState({
            isDeleteConflictModalOpen: true,
            conflictData: err.response.data?.meta?.conflict_data,
          });
          return;
        }
        toast({
          title: 'Cannot delete the squad. ' + err.response.data.meta.error_message,
          status: 'error',
          variant: 'subtle',
          isClosable: true,
        });
      })
      .finally(() => {
        this.setState({ deleteSquadId: '', networkState: 'idle' });
      });
  };

  onCreateNewSquad = async () => {
    const { newSquad } = this.state;
    const { team } = this.props;

    if (newSquad === null) return;

    const errors = checkIfNotValid([
      [newSquad.name, '', { new_squad__name: 'Name is required' }],
      [newSquad.members.length, 0, { new_squad__members: 'One member is required' }],
    ]);

    if (!objectIsEmpty(errors)) {
      this.setState({ errors });
      return;
    }

    this.props.updateChildInRequest(true);
    this.setState({ networkState: 'save', errors: {} });
    try {
      await this._squadService.create(team.id, newSquad);
      this.setState({ newSquad: null, editSquadId: '', squads: [] });
      this.getAllSquads();
      this.props.requestOrganizationSquad();
      this.props.requestOrganizationTeams();
    } catch (err: any) {
      const error = exception.handle('E_POST_SQUAD_CREATE', err);
      this.setState({ errors: { network_err: error } });
    } finally {
      this.setState({ networkState: 'idle' });
    }
  };

  onUpdateSquad = async () => {
    const { squads, editSquadId } = this.state;
    const currentSquad = squads.find(s => s.id === editSquadId);

    if (currentSquad === undefined) return;

    const errors = checkIfNotValid([
      [currentSquad.name, '', { edit_squad__name: 'Name is required' }],
      [currentSquad.members.length, 0, { edit_squad__members: 'One member is required' }],
    ]);

    if (!objectIsEmpty(errors)) {
      this.setState({ errors });
      return;
    }

    this.props.updateChildInRequest(true);
    this.setState({ networkState: 'save', errors: {} });
    try {
      await this._squadService.update(currentSquad.id, currentSquad);
      this.setState({ newSquad: null, squads: [], editSquadId: '' });
      this.getAllSquads();
      this.props.requestOrganizationSquad();
      this.props.requestOrganizationTeams();
    } catch (err: any) {
      const error = exception.handle('E_SQUAD_SAVE', err);
      this.setState({ errors: { network_err: error } });
    } finally {
      this.setState({ networkState: 'idle' });
    }
  };

  onToggleDelete =
    ({ deleteSquadId, deleteSquadName }: { deleteSquadId: string; deleteSquadName: string }) =>
    () =>
      this.setState({ deleteSquadId, deleteSquadName });

  onHideConfirmDelete = (confirm: boolean) =>
    confirm
      ? this.onDeleteConfirm()
      : this.onToggleDelete({
          deleteSquadId: '',
          deleteSquadName: '',
        })();

  onAddNewSquad = () => {
    this.setState({
      errors: {},
      editSquadId: '',
      newSquad: {
        name: '',
        members: [],
      },
    });
  };

  onCancelAddNewSquad = () => {
    this.setState({ newSquad: null, hasUnsavedChanged: false });
  };

  onCancelEditSquad = () => {
    this.setState({ editSquadId: '', hasUnsavedChanged: false });
    this.getAllSquads();
  };

  onEditSquad = (editSquadId: string) => () => this.setState({ editSquadId, errors: {} });

  onNewSquadNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState(({ newSquad }) => {
      // const editableNewSquad = deepCopy(newSquad) as Pick<ISquad, 'name' | 'members'> | null;
      if (newSquad === null) {
        return { newSquad };
      }

      // editableNewSquad.name = value;
      return { newSquad: { ...newSquad, name: value } };
    });
  };

  onEditSquadNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState(({ squads, editSquadId }) => {
      const editableSquad = deepCopy(squads) as ISquad[];
      const currentSquadIndex = editableSquad.findIndex(s => s.id === editSquadId);

      if (currentSquadIndex === -1) {
        return { squads: editableSquad };
      }

      editableSquad[currentSquadIndex].name = value;
      return { squads: editableSquad };
    });
  };

  onAddNewMemberToNewSquad = (_: any, value: string) => {
    this.setState(({ newSquad }) => {
      if (newSquad === null) {
        return { newSquad };
      }

      const members = Array.from(new Set([...newSquad.members, value]));
      return { newSquad: { ...newSquad, members: members } };
    });
  };

  onEditSquadMembersAdd = (_: any, value: string) => {
    this.setState(({ squads, editSquadId }) => {
      const editableSquads = deepCopy(squads) as ISquad[];
      const currentSquadIndex = editableSquads.findIndex(s => s.id === editSquadId);

      if (currentSquadIndex === -1) {
        return { squads: editableSquads };
      }

      editableSquads[currentSquadIndex].members = Array.from(
        new Set([...editableSquads[currentSquadIndex].members, value]),
      );
      return { squads: editableSquads };
    });
  };

  onRemoveMemberFromNewSquad = (id: string) => () => {
    this.setState(({ newSquad }) => {
      if (newSquad === null) {
        return { newSquad };
      }

      const members = new Set([...newSquad.members]);
      members.delete(id);

      const editedMembers = Array.from(members);
      return { newSquad: { ...newSquad, members: editedMembers } };
    });
  };

  onEditSquadMembersRemove = (id: string) => () => {
    this.setState(({ squads, editSquadId }) => {
      const editableSquads = deepCopy(squads) as ISquad[];
      const currentSquadIndex = editableSquads.findIndex(s => s.id === editSquadId);

      if (currentSquadIndex === -1) {
        return { squads: editableSquads };
      }

      const members = new Set([...editableSquads[currentSquadIndex].members]);
      members.delete(id);

      editableSquads[currentSquadIndex].members = Array.from(members);
      return { squads: editableSquads };
    });
  };

  onSearchMembersChange = (e: ChangeEvent<HTMLInputElement>) =>
    this.setState({ searchMembers: e.target.value });
}

const OrganizationSettingsTeamsRenderSquadsWithHistory = withRouter(
  OrganizationSettingsTeamsRenderSquads,
);

export default connect(
  ({ organization }: IAppState) => ({
    organization,
  }),
  {
    requestOrganizationSquad,
    requestOrganizationTeams,
  },
)(withSettingPageACL(OrganizationSettingsTeamsRenderSquadsWithHistory));
