import React, { HTMLAttributes, useState } from 'react';
import Grid from '../Grid';
import styled from 'styled-components';

interface ICopyProps extends HTMLAttributes<HTMLButtonElement> {
  children: string;
  disabled?: boolean;
}

const CopyBlockDiv = styled.button`
  cursor: pointer;
  background: ${props => props.theme.shades.white};
  box-shadow: 0 0 0 1px ${props => props.theme.shades.lightGrey};
  border-radius: 3px;
  width: 100%;
  border: none;
  display: flex;
  align-items: normal;
  justify-content: space-between;
  padding: 0;
  outline: none;
  transition: 0.17s box-shadow ease-in;

  &:active:not(:disabled) {
    opacity: 0.77;
  }

  &:disabled {
    opacity: 0.33;
    cursor: no-drop !important;
  }
`;

const PBlock = styled.div<{ disabled?: boolean }>`
  display: ${props => (props.disabled ? 'none' : 'block')};
  font-family: ${props => props.theme.fontFamily};
  color: ${props => (props.disabled ? props.theme.font.disabled : props.theme.primary.default)};
  background: ${props => (props.disabled ? props.theme.shades.white : props.theme.primary.light)};
  font-size: 16px;
  padding: 2px 16px;
  border-radius: 3px;
  font-weight: 600;
  transition: 0.17s box-shadow ease-in;
`;

const CopyHolder = styled.input<{ parentDisabled?: boolean }>`
  cursor: pointer;
  height: 24px;
  color: ${props => props.theme.font.default};
  font-family: ${props => props.theme.fontFamily};
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  border: none;
  width: 100%;
  margin: 0;

  &:disabled {
    cursor: ${props => (props.parentDisabled ? 'no-drop' : 'pointer')};
    color: ${props => props.theme.font.default};
    background: ${props => props.theme.shades.white};
  }
`;

const CopyBox = (props: ICopyProps) => {
  const [copied, setCopied] = useState(false);

  const copy = async () => {
    try {
      await navigator.clipboard.writeText(props.children);
    } catch (_) {
      const textArea = document.createElement('textarea');
      textArea.value = props.children;

      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      document.execCommand('copy');
      document.body.removeChild(textArea);
    } finally {
      setCopied(true);
    }
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <CopyBlockDiv onClick={copy} disabled={props.disabled}>
      <Grid
        flexWidth={12}
        alignItems="center"
        flexWrap="nowrap"
        style={{
          alignItems: 'center',
          padding: '10px 16px',
          boxSizing: 'border-box',
          overflowX: 'auto',
        }}
      >
        <CopyHolder value={props.children} disabled={true} parentDisabled={props.disabled} />
      </Grid>
      <Grid
        width="fit-content"
        justify-content="flex-end"
        alignItems="center"
        style={{ padding: '10px 16px' }}
      >
        <PBlock disabled={props.disabled}>{copied ? 'Copied!' : 'Copy'}</PBlock>
      </Grid>
    </CopyBlockDiv>
  );
};

/** @component */
export default CopyBox;
