import { Collapse, Link, useDisclosure } from '@chakra-ui/react';
import { FC } from 'react';

export const ExpandCollapse: FC = ({ children }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Link onClick={onToggle} className="see_more">
        {isOpen ? 'Hide Changes' : 'See Changes'}
      </Link>
      <Collapse in={isOpen} animateOpacity>
        {children}
      </Collapse>
    </>
  );
};
