import { Para, Theme } from 'uie/components';
import { ILog } from 'core/interfaces/IIncidents';
import { MSTeamsMeetingLinkCreated } from '../customMessages/MSTeamsMeetingLinkCreated';

type IActionTypeMessages = {
  log: ILog;
  logMessageActivity: string;
  entityComponent: JSX.Element;
};
type actionType =
  | 'msteams_meeting_link'
  | 'suppressed_at_user_replacement'
  | 'reassigned_at_user_replacement';

const logActionTypeMap = {
  msteams_meeting_link: ({
    log,
    entityComponent,
  }: Omit<IActionTypeMessages, 'logMessageActivity'>) => (
    <MSTeamsMeetingLinkCreated log={log}>
      {entityComponent} {log.action}
    </MSTeamsMeetingLinkCreated>
  ),
  suppressed_at_user_replacement: ({
    entityComponent,
  }: Pick<IActionTypeMessages, 'entityComponent'>) => <>{entityComponent}</>,
  incident_starred: ({ entityComponent, log }: Omit<IActionTypeMessages, 'logMessageActivity'>) => (
    <>
      {entityComponent} starred {log.reason.split('starred')[1]}
    </>
  ),
  reassigned_at_user_replacement: ({
    entityComponent,
  }: Pick<IActionTypeMessages, 'entityComponent'>) => <> {entityComponent}</>,
};
const MessageBasedonActionType = (data: {
  log: ILog;
  logMessageActivity: string;
  entityComponent: JSX.Element;
}) => {
  const { theme } = Theme;
  const { log, entityComponent } = data;
  const renderContent = logActionTypeMap[log.actionType as actionType];

  return renderContent ? (
    <Para fontSize={14} color={theme.shades.cement}>
      {renderContent({ log, entityComponent })}
    </Para>
  ) : null;
};

export default MessageBasedonActionType;
