import { INIT_ORG, RESET_STATE } from '../../const/init';
import { REQUEST_USER_ORG_SUCCESS } from '../../const/organization';
import { IOrganization } from '../../interfaces/IOrganization';
import { INITIAL_STATE } from '../state';

const initialCurrentOrg = INITIAL_STATE.organization.currentOrg;
let userOrganizations: IOrganization[] = [];
let initOrgId = '';

const organizationSelected = (state = initialCurrentOrg, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      userOrganizations = [];
      initOrgId = '';
      return {
        ...state,
        ...{
          o: null,
          error: {},
        },
      };
    case REQUEST_USER_ORG_SUCCESS: {
      userOrganizations = action.payload.organizations || [];
      return {
        ...state,
        ...{
          o: userOrganizations.find(o => o.organizationId === initOrgId) || state.o,
          error: {},
        },
      };
    }
    case INIT_ORG: {
      initOrgId = action.payload.orgId || '';
      return {
        ...state,
        ...{
          o: userOrganizations.find(o => o.organizationId === initOrgId) || state.o,
          error: {},
        },
      };
    }
    default:
      return state;
  }
};

export default organizationSelected;
