import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#0093EE"
      d="M19.164 7.722v-.724a3.846 3.846 0 00-1.937-3.388c-.858-.505-1.799-1.06-2.777-1.626-.823-.478-1.675-.964-2.53-1.439A3.707 3.707 0 008.16.481C6.4 1.46 4.655 2.486 2.974 3.478l-.163.095A3.77 3.77 0 00.815 6.946v6.08c.03 1.451.647 2.585 1.786 3.278a124.895 124.895 0 005.642 3.246 3.61 3.61 0 001.734.45 3.873 3.873 0 001.894-.517c1.234-.7 2.487-1.408 3.702-2.123.574-.339 1.145-.674 1.706-1.013a3.647 3.647 0 001.793-2.523c.04-.214.064-.432.07-.65.05-1.931.056-3.715.022-5.452zm-4.717 8.625l-.69.397-1.22.687-.484.284c-.259.15-.521.308-.783.452-.788.492-1.786.5-2.582.022a514.184 514.184 0 01-5.245-3.01 2.368 2.368 0 01-1.234-2.1c-.025-1.491-.022-3.007-.015-4.476v-1.59a2.383 2.383 0 011.27-2.177l.818-.474c1.512-.866 3.045-1.762 4.554-2.61a2.088 2.088 0 011.03-.273 2.78 2.78 0 011.382.39c.794.453 1.585.91 2.373 1.371l1.261.73.676.389.82.47a2.393 2.393 0 011.256 2.086v.25c-2.081-.75-4.167-1.498-6.256-2.246a.633.633 0 00-.515.08A264.222 264.222 0 006.08 8.766a4.809 4.809 0 01-1.734.924c-.599.188-1.185.398-1.774.595l.016.05h2.486l.022.12L4 10.761c.506.008.972.274 1.234.706.35.465.803.871 1.071 1.377.469.884.423.905 1.413.868.043 0 .09-.018.05 0l.903-2.005 1.33 1.164-.364.733c.511.418.998.865 1.456 1.34.814.943 1.956.764 3.057 1.078l-.38-1.604c-.706.132-1.406.338-1.776-.57.247-.025.484-.04.716-.074 1.104-.16 1.058-.133 1.662.795.43.64.596 1.195.074 1.777zm2.052-1.198l-.37.218c-.164-.647-.587-1.232-.738-1.9.442-.025.882-.073 1.318-.145.252-.055.507-.091.765-.108l.182.068a2.264 2.264 0 01-1.157 1.86v.007z"
    ></path>
  </svg>
);
export default SvgComponent;
