import React, { ReactNode } from 'react';

import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';

interface PopoverProps {
  trigger: ReactNode;
  triggerType?: 'hover';
  enableTriggerWrapper?: boolean;
  disablePortal?: boolean;
  children: ReactNode;
}

const NodePopover = ({
  trigger,
  triggerType,
  enableTriggerWrapper,
  disablePortal,
  children,
}: PopoverProps) => {
  return (
    <Popover trigger={triggerType ?? 'click'}>
      <PopoverTrigger>{enableTriggerWrapper ? <Box>{trigger}</Box> : trigger}</PopoverTrigger>
      {disablePortal ? (
        <PopoverContent _focus={{ outline: 'none' }} width={'auto'}>
          <PopoverArrow />
          <PopoverBody p={1}>{children}</PopoverBody>
        </PopoverContent>
      ) : (
        <Portal>
          <PopoverContent _focus={{ outline: 'none' }} width={'auto'}>
            <PopoverArrow />
            <PopoverBody p={1}>{children}</PopoverBody>
          </PopoverContent>
        </Portal>
      )}
    </Popover>
  );
};

export default NodePopover;
