import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { Col, Row, SpinLoader } from '@squadcast/alchemy-ui/carbon';
import { IAppState } from '../../../../../../core/interfaces/IAppState';
import { LocalStore } from '../../../../../../store/local';
import { sleep } from 'core/helpers';
import { IComponentErrorState } from '../../../../../../core/interfaces/IComponentState';
import { ExtensionService } from '../../../../../../core/services';

type IProps = Pick<IAppState, 'userInfo'> & RouteComponentProps;

interface IState {
  code: string;
  slackState: string;
  redirect: boolean;
  showBoxShow: boolean;
  error: IComponentErrorState;
}

class IntegrateWithSlack extends React.Component<IProps, IState> {
  public mainContainer: HTMLDivElement | null = null;
  public _isMounted = true;
  private _extensionService = new ExtensionService();

  constructor(props: IProps) {
    super(props);
    const parsed = queryString.parse(this.props.location.search);
    this.state = {
      code: parsed.code as string,
      slackState: parsed.state as string,
      redirect: false,
      showBoxShow: false,
      error: {},
    };
  }

  public componentDidMount() {
    this.integrateSlack();
  }

  integrateSlack = async () => {
    await sleep(1);
    if (!this._isMounted) {
      return;
    }

    try {
      const slackState = await LocalStore.getSlackState();
      if (slackState !== this.state.slackState) {
        return;
      }

      await this._extensionService.integrateSlack(this.state.code);
    } catch (err: any) {
      this.setState({
        error: {
          E_SLACK_INTEGRATE: err?.response?.data?.meta?.error_message ?? 'Network Error',
        },
      });
    } finally {
      this.setState({ redirect: true });
    }
  };

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public onComponentScroll = () => {
    if (!this.mainContainer) {
      return;
    }

    const offSet = this.mainContainer.scrollTop;
    if (offSet) {
      this.setState({
        showBoxShow: true,
      });
    } else {
      this.setState({
        showBoxShow: false,
      });
    }
  };

  public render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={`/settings/extension?${new URLSearchParams(
            Object.keys(this.state.error).length === 0
              ? { slack_success: 'true' }
              : this.state.error,
          ).toString()}`}
        />
      );
    }

    return (
      <div
        className="main-container"
        ref={ref => (this.mainContainer = ref)}
        onScroll={this.onComponentScroll}
      >
        <div
          className=""
          style={this.state.showBoxShow ? { boxShadow: '0 10px 30px rgba(13, 33, 73, 0.08)' } : {}}
        >
          <h1 className="item-box-main-heading">Integrating</h1>
        </div>
        <div className="data-display-container" style={{ marginTop: 20 }}>
          <Col flexHeight={12} justifyContent="center">
            <Row justifyContent="center">
              <SpinLoader size="normal" />
            </Row>
          </Col>
        </div>
      </div>
    );
  }
}
export default connect(
  (state: IAppState) => ({
    userInfo: state.userInfo,
  }),
  null,
)(IntegrateWithSlack);
