import {
  REQUEST_INCIDENT_DETAIL_TASK,
  REQUEST_INCIDENT_DETAIL_TASK_SUCCESS,
  REQUEST_INCIDENT_DETAIL_TASK_FAIL,
} from '../../const/organization';
import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';

const initialOrganizationRunbook = INITIAL_STATE.incidentTask;

const incidentDetailTask = (state = initialOrganizationRunbook, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{
          action: RESET_STATE,
          tasks: undefined,
          loading: false,
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_INCIDENT_DETAIL_TASK:
      return {
        ...state,
        ...{ loading: true },
      };
    case REQUEST_INCIDENT_DETAIL_TASK_SUCCESS:
      return {
        ...state,
        ...{
          action: REQUEST_INCIDENT_DETAIL_TASK_SUCCESS,
          tasks: action.payload.taskList,
          loading: false,
          error: { type: 'OK', reason: {} },
        },
      };
    case REQUEST_INCIDENT_DETAIL_TASK_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_INCIDENT_DETAIL_TASK_FAIL,
          tasks: undefined,
          loading: false,
          error: { type: REQUEST_INCIDENT_DETAIL_TASK_FAIL, reason: action.payload },
        },
      };
    default:
      return state;
  }
};

export default incidentDetailTask;
