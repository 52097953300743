import {
  IIncident,
  IIncidentOverviewResponse,
  IIncidentSummaryResponse,
  IIncidentSummaryResponseMetadata,
} from 'core/interfaces/IIncidents';
import summaryLastUpdatedAtFormat from '../utils/lastUpdatedAt';
import { AppConfig } from 'shared/app.config';
import { capitalizeFirstLetter } from 'views/main/organization/settings/audit-logs/helpers';
import useUINavigationFunctionsV2 from 'views/main/organization/navigation-flows/useUINavigationFunctionV2';
import { EntityType } from 'library/molecules/EntityUI/EntityUI';

interface IncidentSummaryMarkdownOptions {
  incident: IIncident;
  overview: IIncidentOverviewResponse | undefined;
  summary: IIncidentSummaryResponse | undefined;
  summaryMeta: IIncidentSummaryResponseMetadata | undefined;
}

const useIncidentSummaryMarkdown = (options: IncidentSummaryMarkdownOptions) => {
  const { getEntityLink } = useUINavigationFunctionsV2();
  if (!options.summary || !options.overview || !options.summaryMeta) return '';

  return `# Incident Summary

Summary for incident [${options.incident.message}](${AppConfig.app_url}${getEntityLink(
    'incident',
    options.incident.id,
  )})

Summary was last updated at ${summaryLastUpdatedAtFormat(options.summaryMeta.generated_at)}.

## STATUS

**${capitalizeFirstLetter(options.overview.status)}**

## IMPACT

**Service:** [${options.overview.service.name}](${AppConfig.app_url}${getEntityLink(
    'service',
    options.overview.service.id,
  )})

**Owned By:** [${options.overview.service.owner.name}${
    options.overview.service.owner.type === 'user'
      ? ` (${options.overview.service.owner.username})`
      : ''
  }](${AppConfig.app_url}${getEntityLink(
    options.overview.service.owner.type as EntityType,
    options.overview.service.owner.id,
  )})

## PARTICIPANTS (${options.overview.participants.length})

${options.overview.participants
  .map(p => {
    return `- [${p.name}${p.type === 'user' ? ` (${p.username})` : ''}](${
      AppConfig.app_url
    }${getEntityLink(p.type as EntityType, p.id)})`;
  })
  .join('\n')}

${options.summary.content}`;
};

export default useIncidentSummaryMarkdown;
