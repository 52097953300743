import React from 'react';
import { Pie } from '@nivo/pie';
import { getStatusColors } from '../helpers';
import { Theme } from 'uie/components';
import { ResponsiveWrapper } from '@nivo/core';

const { theme } = Theme;

interface IProps {
  data: any[];
  config?: any;
}

const PieChart: React.FC<IProps> = ({ data, config }) => {
  return (
    <ResponsiveWrapper>
      {({ width }) => (
        <Pie
          data={data}
          width={width}
          height={300}
          margin={{ top: 30, right: 120, bottom: 30, left: 10 }}
          colors={getStatusColors()}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 80,
              translateY: -10,
              itemsSpacing: 2,
              itemWidth: 80,
              itemHeight: 18,
              itemTextColor: theme.shades.black,
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 14,
              symbolShape: 'circle',
            },
          ]}
          {...config}
        />
      )}
    </ResponsiveWrapper>
  );
};

export default PieChart;
