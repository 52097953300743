import { T_WA_AUDIT_LOGS_EXPORTED } from 'core/const/tracker';
import AuditLogsService from 'core/services/service.auditlogs';
import { useToast } from 'library/atoms/Toast';
import { useEffect, useState } from 'react';
import { AppTracker } from 'shared/analytics/tracker';
import { getErrorMessage } from '../helpers';
import {
  AuditLogsData,
  AuditLogsExportHistoryData,
  AUDITLOGS_TABS,
  ExportParams,
  GetAuditLogsHistoryParamsType,
  GetAuditLogsParamsType,
} from '../types/index.auditLogs';

export const useAuditLogs = (auditLogParams: GetAuditLogsParamsType) => {
  const toast = useToast();
  const auditLogService = new AuditLogsService();
  const [auditLogs, setAuditLogs] = useState<AuditLogsData>();
  const [auditLogsExportHistory, setAuditLogsExportHistory] =
    useState<AuditLogsExportHistoryData>();
  const [auditLogsFilterDrowDownValues, setAuditLogsFilterDrowDownValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [loadingLogs, setloadingLogs] = useState(false);
  const [exportingData, setExportingData] = useState(false);

  const getAuditLog = async (cb?: any, auditLogReqBody?: GetAuditLogsParamsType) => {
    try {
      setIsLoading(true);
      const res = await auditLogService.getAuditLogs({
        ...auditLogParams,
        ...auditLogReqBody,
      });
      if (res?.data) {
        setAuditLogs(res.data);
        if (cb) cb();
        return true;
      }
    } catch (error: any) {
      toast({
        status: 'error',
        text: getErrorMessage(error.response?.data?.message),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(false);
  }, [auditLogs]);

  const getAuditLogById = async (id: string) => {
    try {
      setloadingLogs(true);
      const res = await auditLogService.getAuditLogsById(id);
      if (res?.data) {
        return res.data;
      }
    } catch (error: any) {
      toast({
        status: 'error',
        text: getErrorMessage(error.response?.data?.message),
      });
    } finally {
      setloadingLogs(false);
    }
  };

  const getAuditLogsHistory = async (auditLogReqBody?: GetAuditLogsHistoryParamsType) => {
    try {
      setLoadingHistory(true);
      const res = await auditLogService.getExportHistory({
        ...auditLogParams,
        ...auditLogReqBody,
      });
      if (res?.data) {
        setAuditLogsExportHistory(res.data);
      }
    } catch (error: any) {
      toast({
        status: 'error',
        text: getErrorMessage(error.response?.data?.message),
      });
    } finally {
      setLoadingHistory(false);
    }
  };

  const getAuditLogFilters = async () => {
    try {
      const res = await auditLogService.getFilters();
      if (res?.data) {
        setAuditLogsFilterDrowDownValues(res.data?.data);
      }
    } catch (error: any) {
      toast({
        status: 'error',
        text: getErrorMessage(error.response?.data?.message),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const exportLogsData = async (params: ExportParams, cb: () => void) => {
    try {
      const res = await auditLogService.exportLogsData(params);
      if (res?.data) {
        AppTracker.track(T_WA_AUDIT_LOGS_EXPORTED, {
          'Export Format': params.exportType,
          'Audit Logs Export ID': res?.data?.id,
        });
        setExportingData(true);
        toast({
          status: 'success',
          text: 'Logs successfully exported. You can view the uploaded file in the Export History tab in a bit',
        });
        if (cb) cb();
      }
    } catch (error: any) {
      toast({
        status: 'error',
        text: getErrorMessage(error.response?.data?.message),
      });
    } finally {
      setExportingData(false);
    }
  };

  return {
    auditLogs,
    getAuditLog,
    isLoading,
    getAuditLogById,
    loadingLogs,
    auditLogsExportHistory,
    getAuditLogsHistory,
    getAuditLogFilters,
    auditLogsFilterDrowDownValues,
    exportLogsData,
    exportingData,
    loadingHistory,
    setLoadingHistory,
  };
};
