import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Heading: ComponentStyleConfig = {
  variants: {
    h3: {
      // as: 'h3',
      fontWeight: 400,
      // color: 'gray.900',
    },
    h6: {
      // as: 'h3',
      fontSize: 24,
      fontWeight: 400,
      // color: 'gray.900',
    },
  },
  defaultProps: {
    variant: 'h3',
  },
};
