import axios from 'axios';
import { API } from '../api';
import { IUserRole } from '../interfaces/IUserData';

class OrganizationService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}`;
  private _apiOld = `${API.config.endpoint}/organizations/${API.config.organizationId}`;

  public transferOwnership = (userID: string) =>
    axios.put<IUserRole[]>(`${this._api}/transfer-ownership`, { user_id: userID });

  public renameOrganization = (name: string) =>
    axios.put<{ result: boolean; message: string }>(`${this._apiOld}/rename`, {
      name,
      session_token: API.config.sessionId,
      _id: API.config.organizationId,
    });

  public toggleOrganizationActiveStatus = (status: boolean) =>
    axios.put(`${this._apiOld}/deactivate`, {
      status,
      session_token: API.config.sessionId,
      _id: API.config.organizationId,
    });
}

export default OrganizationService;
