import { Grid } from 'uie/components';
import { Button, VStack, ChakraProvider, Box } from '@chakra-ui/react';
import { CustomTemplateModal } from '.';
import libraryTheme from 'library/theme';
import { AlertDialogComponent } from 'views/main/organization/service-catalog/components/Alert';
import Header from './Header';
import EditorField from './Editor/Editor';
import AlertSourceDropdown from './AlertSourceDropdown';
import Output from './Output';

function render(this: CustomTemplateModal) {
  const { alertSourcesLoadState, alertSourceErrorMessage } = this.state;
  const { mode, editTemplate, activeAlertSourceNames, updatePermission, deletePermission } =
    this.props;
  const isViewMode = mode === 'view';
  const iscreateMode = mode === 'create';
  const trimmedMessage = this.state.incidentMessage.trim();
  const trimmedDescription = this.state.incidentDesc.trim();

  const disableButtonRule =
    this.state.isSaving ||
    this.state.eventState === 'loading' ||
    (trimmedMessage.length === 0 && trimmedDescription.length === 0) ||
    this.state.saveState === 'saving' ||
    this.state.eventState === 'default';

  return (
    <ChakraProvider theme={libraryTheme}>
      <Header isViewMode={isViewMode} />
      <Grid flexWidth={12} justifyContent="flex-start" alignItems="stretch">
        <Grid flexWidth={7}>
          <VStack width="100%">
            <EditorField
              isViewMode={isViewMode}
              incidentMessage={this.state.incidentMessage}
              incidentDesc={this.state.incidentDesc}
              componentState={this.state.componentState}
              updateMessageCode={this.updateMessageCode}
              updateDesc={this.updateDesc}
              handleEditorDidMount={this.handleEditorDidMount}
            />
            <Box alignItems={'flex-start'} width="100%">
              {!isViewMode && (
                <Output
                  CustomOutputMessage={this.state.customOutputMessage}
                  decodedDesc={this.state.decodedDesc}
                  decodedMessage={this.state.decodedMessage}
                  isSaving={this.state.isSaving}
                  getCustomIncidentOutput={this.getCustomIncidentOutput}
                />
              )}
              <Box mt={10}>
                <Grid justifyContent="flex-start" alignItems="center">
                  {isViewMode ? (
                    updatePermission ? (
                      <Button
                        style={{ backgroundColor: '#1B7FF1', color: 'white' }}
                        onClick={editTemplate}
                      >
                        Edit
                      </Button>
                    ) : null
                  ) : (
                    <>
                      <Button
                        variant="default"
                        style={{ backgroundColor: '#1B7FF1', color: 'white' }}
                        onClick={this.save}
                        disabled={disableButtonRule}
                        isLoading={this.state.saveState === 'saving'}
                        marginRight={4}
                      >
                        Save
                      </Button>
                      {!iscreateMode && deletePermission && (
                        <Button
                          style={{ backgroundColor: '#D14C44', color: 'white' }}
                          disabled={disableButtonRule}
                          onClick={() => this.openDeleteModal(true)}
                        >
                          Delete
                        </Button>
                      )}
                    </>
                  )}

                  {this.state.saveState === 'saved' && (
                    <small className="ml-20">All Changes Saved!</small>
                  )}
                </Grid>
              </Box>
            </Box>
          </VStack>
        </Grid>

        <AlertSourceDropdown
          alertSourcesLoadState={alertSourcesLoadState}
          alertSourceErrorMessage={alertSourceErrorMessage}
          eventState={this.state.eventState}
          event={this.state.event}
          alertSource={this.state.alertSource}
          alertSourceSearch={this.state.alertSourceSearch}
          onSelectChange={this.onSelectChange}
          onTextChange={this.onTextChange}
          activeAlertSourceNames={activeAlertSourceNames}
          alertSrc={this.props.alertSource}
          alertSources={this.alertSources}
        />
      </Grid>

      <AlertDialogComponent
        isOpen={this.state.deleteModal}
        onClose={() => this.openDeleteModal(false)}
        callbackFn={() => {
          this.deleteTemplate(
            this.props.customDetail?.alert_source_shortname ??
              (this.state.alertSource?.shortName as string),
          );
        }}
        msg={`Deleting the template would mean incoming alerts here on will follow the default incident message and description templates. Do you want to proceed?`}
        title={`Are you sure you want to delete this Custom Content Template ?`}
        isDelete={true}
        loading={this.state.isDeleting}
      />
    </ChakraProvider>
  );
}

export default render;
