import { API } from 'core';
import axios from 'axios';
import { Member, Squad, SquadList, SquadMemberRole } from '../types';
import { AppConfig } from 'shared/app.config';

const url = AppConfig.api_url;

export const getAllSquad = async (teamId: string, searchFilter?: string) => {
  const params: {
    team_id?: string;
    search_filter?: string;
  } = {};
  if (teamId) {
    params['team_id'] = teamId;
  }
  if (searchFilter) {
    params['search_filter'] = searchFilter;
  }
  const data = await axios.get<SquadList>(
    `${url}/v4/organizations/${API.config.organizationId}/squads`,
    {
      params: params,
      paramsSerializer: params => {
        return new URLSearchParams(params).toString();
      },
    },
  );
  return data;
};

export type CreateSquadPayload = {
  name: string;
  members: Member[];
};
export const createSquad = async (teamId: string, { name, members }: CreateSquadPayload) => {
  const resp = await axios.post<{ data: Squad }>(
    `${url}/v4/organizations/${API.config.organizationId}/squads`,
    {
      name,
      members,
      owner_id: teamId,
    },
  );
  return resp;
};

export type UpdateSquadNamePayload = {
  name: string;
};

export const updateSquadName = async (squadId: string, { name }: UpdateSquadNamePayload) => {
  const resp = await axios.put<SquadList>(
    `${url}/v4/organizations/${API.config.organizationId}/squads/${squadId}/name`,
    {
      name,
    },
  );
  return resp;
};

export const addMemberToSquad = async (squadId: string, members: Member[]) => {
  const resp = await axios.post<{ data: Squad }>(
    `${url}/v4/organizations/${API.config.organizationId}/squads/${squadId}/members`,
    {
      members,
    },
  );
  return resp;
};

export const removeMemberFromSquad = async (
  squadId: string,
  memberId: string,
  replaceWith?: string,
) => {
  const params: {
    replaceWith?: string;
  } = {};
  if (replaceWith) {
    params.replaceWith = replaceWith;
  }
  const resp = await axios.delete<{ data: Squad }>(
    `${url}/v4/organizations/${API.config.organizationId}/squads/${squadId}/members/${memberId}`,
    {
      params,
    },
  );
  return resp;
};

export const updateMemberRole = async (
  squadId: string,
  memberId: string,
  role: SquadMemberRole,
  replaceWith?: string,
) => {
  const params: {
    replaceWith?: string;
  } = {};
  if (replaceWith) {
    params.replaceWith = replaceWith;
  }
  const resp = await axios.put<SquadList>(
    `${url}/v4/organizations/${API.config.organizationId}/squads/${squadId}/members/${memberId}`,
    {
      role,
    },
    {
      params,
    },
  );
  return resp;
};

export const removeSquad = async (squadId: string) => {
  const resp = await axios.delete<SquadList>(
    `${url}/v3/organizations/${API.config.organizationId}/squads/${squadId}`,
  );
  return resp;
};
