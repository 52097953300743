import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 342 139"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx={64} cy={64.5} rx={64} ry={64.5} fill="#F8384B" />
    <ellipse cx={63.5} cy={64} rx={34.5} ry={35} fill="#fff" />
    <path d="m42.208 69 29.415 18.513-30.645 16.431 1.23-34.945Z" fill="#fff" />
    <path
      d="M160.475 65.688h5.785c6.408 0 9.612-2.789 9.612-8.366s-3.204-8.366-9.612-8.366h-5.785v16.732Zm0 36.312h-17.444V34.894h27.768c7.535 0 13.291 1.958 17.266 5.874 4.035 3.916 6.052 9.434 6.052 16.554 0 7.12-2.017 12.638-6.052 16.554-3.975 3.916-9.731 5.874-17.266 5.874h-10.324V102Zm76.16-49.929c-1.898-1.543-3.797-2.67-5.696-3.382-1.898-.771-3.738-1.157-5.518-1.157-2.254 0-4.094.534-5.518 1.602-1.424 1.068-2.136 2.462-2.136 4.183 0 1.187.356 2.166 1.068 2.937.712.771 1.632 1.454 2.759 2.047a30.062 30.062 0 0 0 3.916 1.424c1.484.415 2.937.86 4.361 1.335 5.696 1.899 9.85 4.45 12.46 7.654 2.67 3.145 4.005 7.268 4.005 12.371 0 3.441-.593 6.556-1.78 9.345a19.212 19.212 0 0 1-5.073 7.209c-2.195 1.958-4.924 3.471-8.188 4.539-3.204 1.127-6.853 1.691-10.947 1.691-8.484 0-16.346-2.522-23.585-7.565l7.476-14.062c2.611 2.314 5.192 4.035 7.743 5.162 2.552 1.127 5.073 1.691 7.565 1.691 2.848 0 4.955-.653 6.319-1.958 1.424-1.305 2.136-2.789 2.136-4.45 0-1.009-.178-1.869-.534-2.581-.356-.771-.949-1.454-1.78-2.047-.83-.653-1.928-1.246-3.293-1.78a91.48 91.48 0 0 0-4.806-1.78 126.877 126.877 0 0 1-6.675-2.314 22.72 22.72 0 0 1-5.785-3.471c-1.661-1.424-3.026-3.204-4.094-5.34-1.008-2.195-1.513-4.954-1.513-8.277 0-3.323.534-6.319 1.602-8.989 1.128-2.73 2.67-5.043 4.628-6.942 2.018-1.958 4.45-3.471 7.298-4.539 2.908-1.068 6.141-1.602 9.701-1.602 3.323 0 6.794.475 10.413 1.424 3.62.89 7.091 2.225 10.413 4.005l-6.942 13.617ZM289.04 77.08l-7.832-22.339-7.832 22.339h15.664Zm4.628 13.261h-24.92L264.743 102h-18.601l25.543-67.106h19.046L316.274 102h-18.601l-4.005-11.659Z"
      fill="#6E6E6E"
    />
  </svg>
);

export default SvgComponent;
