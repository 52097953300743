import Axios from 'axios';
import { API } from 'core';
import { AssigneeType } from 'library/enums';
import { QUERY_CONFIG } from 'library/query-config';
import { useMutation, useQueryClient } from 'react-query';

import { QUERY_KEY } from './queryKeys';

export const createGer = (
  teamId: string,
  name: string,
  id: string,
  type: AssigneeType,
  desc?: string,
) => {
  const body = {
    name,
    description: desc,
    owner_id: teamId,
    entity_owner: {
      id,
      type,
    },
  };
  return Axios.post(
    `${API.config.batman}/organizations/${API.config.organizationId}/global-event-rules`,
    body,
  );
};

export const useCreateGerMutation = (
  teamId: string,
  name: string,
  id: string,
  type: AssigneeType,
  desc?: string,
) => {
  const queryClient = useQueryClient();

  const { isSuccess, isError, isLoading, mutate } = useMutation({
    mutationKey: [QUERY_KEY.CREATE_GER, teamId, name, id, type, desc],
    mutationFn: () => createGer(teamId, name, id, type, desc),
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.ORG_LEVEL_GER_COUNT, { refetchInactive: true });
    },

    ...QUERY_CONFIG,
  });
  return {
    mutate,
    isSuccess,
    isError,
    isLoading,
  };
};
