import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 12px;
`;

export const Header = styled.header`
  display: flex;
  gap: 11px;
  margin-bottom: 8px;
  align-items: center;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

export const Title = styled.h1`
  color: #122146;
  margin: 0px 0 0;
  flex: 1 1 auto;
  font: 400 18px/150% Mulish, sans-serif;
`;

export const Actions = styled.div`
  display: flex;
  gap: 12px;
  white-space: nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export const SearchInputWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(230, 231, 236, 1);
  background: #fff;
  display: flex;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  align-items: center;
  color: #b6bcc8;

  @media (max-width: 991px) {
    white-space: initial;
  }

  &:focus-within {
    border-color: #0e61dd;
    color: #0e61dd;
  }
`;

export const SearchInputField = styled.input`
  font: 400 14px/150% Mulish, sans-serif;
  flex: 1;
  border: none;
  color: rgb(61, 72, 94);

  &::placeholder {
    color: #b6bcc8;
    font-size: 14px;
  }

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export const Divider = styled.div`
  background: var(--gray-200, #e2e8f0);
  width: 1px;
  height: 32px;
`;
