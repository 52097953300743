import * as React from 'react';
import { SVGProps } from 'react';

const SvgBuildkite = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M0 3.3V10l6.7 3.3V6.7L0 3.3zm13.3 0V10L20 6.7l-6.7-3.4z"
      style={{
        fill: '#30f2a2',
      }}
    />
    <path
      d="M13.3 10v6.7l6.7-3.3V6.7L13.3 10zM6.7 6.7v6.7l6.7-3.3V3.3L6.7 6.7z"
      style={{
        fill: '#14cc80',
      }}
    />
  </svg>
);

export default SvgBuildkite;
