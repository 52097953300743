import React from 'react';
import styled from 'styled-components';

import { Theme } from 'uie/components';

interface FocusedSearchBoxProps {
  placeholder: string;
  searchTerm: string;
  onSearch: (searchTerm: string) => void;
}

const { theme } = Theme;

const FocusedSearchContainer = styled.div`
  position: relative;
  margin-bottom: 16px;

  & > input {
    height: 36px;
    width: 100%;
    min-width: auto;
    padding: 0 10px;
    padding-right: 26px;
    border-radius: 4px;
    border-color: ${theme.shades.smoke};
    background-color: ${theme.shades.whiteSmoke};
    font-style: italic;
  }

  & > img {
    left: auto;
    right: 10px;
    width: 14px;
  }
`;

const FocusedSearchBox = ({ placeholder, searchTerm, onSearch }: FocusedSearchBoxProps) => {
  return (
    <FocusedSearchContainer>
      <input
        type="text"
        autoComplete="off"
        className="input-design"
        placeholder={placeholder}
        value={searchTerm}
        onChange={event => {
          onSearch(event.target.value);
        }}
      />
      <img src="/icons/greys/search copy.svg" className="search-bar-icon" alt="copy" />
    </FocusedSearchContainer>
  );
};

export default FocusedSearchBox;
