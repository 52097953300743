import { Circle, HStack, Text } from '@chakra-ui/react';
import { NameComponent, Tooltip } from 'library/atoms';
import { FC } from 'react';

import { getHealthStatusColor } from '../../helpers/helper.graph';
import { SERVICE_NODE_TYPE } from '../../Interfaces/graph';

type Props = {
  serviceData: SERVICE_NODE_TYPE;
};

export const ServiceEntity: FC<Props> = ({ serviceData }) => {
  return (
    <HStack pl={8} py={4} columnGap={2} alignItems="center">
      <Circle size="12px" bg={getHealthStatusColor(serviceData.status)} />
      <NameComponent name={serviceData.name} maxLength={50} />
    </HStack>
  );
};
