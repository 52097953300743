import * as React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../core/interfaces/IAppState';
import { IRole } from '../../../core/interfaces/IRole';
import Billing from '../organization/settings/billing';
import { LargeLogoWhiteIcon } from '../../../icons';
import './index.css';
import { CardBlock, Grid, Para, Pill, TextButton, Theme } from 'uie/components';
import { requestUserLogout } from '../../../core/actions';

interface IProps extends Pick<IAppState, 'organization' | 'roles'> {
  requestUserLogout: typeof requestUserLogout;
}

class Expired extends React.PureComponent<IProps> {
  public render() {
    const { theme } = Theme;
    const currentUserRole = this.props.organization.currentUser.u!.role;
    const accountOwnerId = this.props.roles.r.find((role: IRole) => {
      return role.name.toLowerCase().trim() === 'account owner';
    })!._id;
    const isTeamsEmpty = this.props.organization.teams.t.length === 0;

    const accountOwner = this.props.organization.users.u.find(
      user => user.role_id === accountOwnerId,
    );

    const _accountOwnerName = `${accountOwner?.first_name} ${accountOwner?.last_name}`;

    const renderComponent = () => {
      if (isTeamsEmpty) {
        return (
          <Grid className="push-down" type="column" alignItems="center">
            <CardBlock padding="64px">
              <Grid type="column" alignItems="center">
                <img
                  src="/icons/empty-states/incident.svg"
                  style={{ display: 'inline-block', width: 150, marginBottom: '30px' }}
                  alt="incident"
                />
                <Grid alignItems="center">
                  <Para>
                    You have not been assigned to any team, please contact your account owner
                  </Para>
                  <Pill className="ml-10">
                    <span className="font-bold">{_accountOwnerName}</span>
                  </Pill>
                </Grid>
              </Grid>
            </CardBlock>
          </Grid>
        );
      }

      if (currentUserRole === 'account_owner') {
        return <Billing />;
      }

      return (
        <Grid className="push-down" type="column" alignItems="center">
          <CardBlock padding="64px">
            <Grid type="column" alignItems="center">
              <img
                src="/icons/empty-states/incident.svg"
                style={{ display: 'inline-block', width: 150, marginBottom: '30px' }}
                alt="incident"
              />
              <Grid alignItems="center">
                <Para>
                  Your account doesn't have an active plan, please contact your account owner
                </Para>
                <Pill className="ml-10">
                  <span className="font-bold">{_accountOwnerName}</span>
                </Pill>
              </Grid>
            </Grid>
          </CardBlock>
        </Grid>
      );
    };

    return (
      <div className="expired__main_container">
        <Grid alignItems="center" justifyContent="space-between" className="header">
          <Grid flexWidth={11}>
            <LargeLogoWhiteIcon />
          </Grid>
          <Grid flexWidth={1} alignItems="center" justifyContent="flex-end">
            <TextButton buttonType="ghost" height="32px" onClick={this.props.requestUserLogout}>
              <Para color={theme.shades.white}>Logout</Para>
            </TextButton>
          </Grid>
        </Grid>
        <div>{renderComponent()}</div>
      </div>
    );
  }
}

export default connect(
  ({ organization, roles }: IAppState) => ({
    organization,
    roles,
  }),
  {
    requestUserLogout,
  },
)(Expired);
