import { Component } from 'react';
import { connect } from 'react-redux';
import { BillingService, StatusPageService } from '../../../../core/services';
import { IStatusPage } from '../../../../core/interfaces/IStatusPage';
import render from './render.index';
import { API } from '../../../../core/api';
import { IAppState } from 'core/interfaces/IAppState';
import './index.css';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_GS_STATUSPAGE_DELETED, T_WA_GS_STATUSPAGE_VIEWED } from 'core/const/tracker';
import { exception } from '../../../../core/exception';
import { RouteComponentProps } from 'react-router-dom';
import { checkIfKeyChanged } from 'core/helpers';
import { UserAccessContextValue, withUserAccess } from 'core/userAccess/UserAccessContext';

type IProps = Pick<IAppState, 'organization'> &
  RouteComponentProps & {
    userAccess: UserAccessContextValue;
  };

interface IState {
  openModal: 'add_new' | '';
  statusPages: IStatusPage[];
  showEditConfigId: string;
  removePageId: string;
  showUnsavedChangesModal: boolean;
  isDirty: boolean;
  showUpgradeModal: boolean;
  statusPageLimitExceeded: boolean;
  showUpgradeToolTip: boolean;
}

export class StatusPage extends Component<IProps, IState> {
  public _env = API.env;
  public SPService: StatusPageService;

  public render = render;

  constructor(props: IProps) {
    super(props);
    this.SPService = new StatusPageService();

    this.state = {
      statusPages: [],
      openModal: '',
      showEditConfigId: '',
      removePageId: '',
      showUnsavedChangesModal: false,
      isDirty: false,
      showUpgradeModal: false,
      statusPageLimitExceeded: false,
      showUpgradeToolTip: false,
    };
  }

  public async componentDidMount() {
    const location: {
      state: {
        openModal: 'add_new' | '';
      };
    } = this.props.location as any;
    await this.getStatusPages();
    this.setState({ openModal: location.state?.openModal ?? '' });

    //todo - coordination is required between fetching /billing/plan and this
    setTimeout(async () => {
      await this.updatePlanLimitExceeded();
      if (this.state.statusPageLimitExceeded) {
        this.setState({ showUpgradeModal: true });
      }
    }, 300);
    AppTracker.track(T_WA_GS_STATUSPAGE_VIEWED);
  }

  updatePlanLimitExceeded = () => {
    this.setState({
      statusPageLimitExceeded: BillingService.isLimitExceeded(
        this.props,
        'status-page',
        () => this.state.statusPages.length,
      ),
    });
  };

  public async componentDidUpdate(prevProps: IProps) {
    if (
      checkIfKeyChanged(
        prevProps.organization.selectedTeam,
        this.props.organization.selectedTeam,
        'refresh',
      )
    ) {
      await this.getStatusPages();
      this.updatePlanLimitExceeded();
    }
  }

  public getStatusPages = async () => {
    try {
      const {
        data: { data: statusPages },
      } = await this.SPService.getAll();
      this.setState({ statusPages });
    } catch (err: any) {
      exception.handle('E_GET_ALL_STATUS_PAGES', err);
    }
  };

  public onSetToDelete = (id: any) => {
    this.setState({
      removePageId: id,
    });
  };

  public deletePage = async () => {
    const page = this.state.statusPages.find(id => id.id === this.state.removePageId);
    AppTracker.track(T_WA_GS_STATUSPAGE_DELETED, {
      'StatusPage Name': page?.name,
      'StatusPage Hostname': page?.url,
    });

    const userAccess = this.props.userAccess;
    const hasDelete = userAccess.hasDeleteAccess('status_pages', this.state.removePageId);

    if (!hasDelete) {
      return;
    }

    try {
      await this.SPService.deletePage(this.state.removePageId);
      this.getStatusPages();
    } catch (error: any) {
      exception.handle('E_DELETE_STATUS_PAGE', error);
    }
  };

  public checkStatusPageLimitAndAddPage = () => {
    if (this.state.statusPageLimitExceeded) {
      this.setState({ showUpgradeToolTip: true });
    } else {
      this.setState({ openModal: 'add_new' });
    }
  };

  public closeModal = () => {
    this.setState({ removePageId: '' });
  };

  public hideNewStatusPageModal = () => {
    this.setState({ openModal: '', showEditConfigId: '', isDirty: false });
    this.getStatusPages();
  };

  public setUnsavedChanges = (showUnsavedChangesModal: boolean) =>
    this.setState({ showUnsavedChangesModal });
  public closeUnsavedChangesModal = () => this.setState({ showUnsavedChangesModal: false });
  public onDiscardChanges = () =>
    this.setState({
      showUnsavedChangesModal: false,
      openModal: '',
      showEditConfigId: '',
      isDirty: false,
    });

  checkAndSetDirty = () => {
    if (!this.state.isDirty) this.setState({ isDirty: true });
  };

  checkDirtyAndCloseModal = () => {
    if (this.state.isDirty) {
      this.setUnsavedChanges(true);
    } else {
      this.setState({
        isDirty: false,
        openModal: '',
        showEditConfigId: '',
      });
    }
  };
}

export default connect(({ organization }: IAppState) => ({
  organization,
}))(withUserAccess(StatusPage));
