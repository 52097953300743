import { InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { Field, FormikProvider, useFormikContext } from 'formik';
import React from 'react';
import { reactQueryConfigSuccess } from '../../helpers/helper.toast';
import { useSubscribersSettings } from '../../hooks/useSubscriberSettings';
import { ISubscriberSettings } from '../../Interface';

interface Props {
  pageId: number;
  onClose: () => void;
  canEdit: boolean;
}

interface FormProps {
  onClose: () => void;
  isUpdating: boolean;
  canEdit: boolean;
}

const StatusMessageForm = (props: FormProps) => {
  const { isUpdating, canEdit, onClose } = props;
  const { submitForm, values, setFieldValue, errors, touched }: any =
    useFormikContext<ISubscriberSettings>();

  return (
    <Box data-testid="form">
      <form>
        <Box mb={10}>
          <FormLabel>
            <Field
              as={() => (
                <Checkbox defaultChecked disabled>
                  Allow users to subscribe via Email
                </Checkbox>
              )}
              mb={8}
            />
          </FormLabel>
          <Flex mb={8} ml={6}>
            <FormControl mr={4}>
              <FormLabel htmlFor="emailFrom">Send Only Email (for updates)</FormLabel>
              <Field as={Input} type="text" name="emailFrom" disabled />
              <Text fontSize={12} mt={2}>
                End users will receive notifications from this email
              </Text>
            </FormControl>
            <FormControl isInvalid={errors?.contactEmail && touched.contactEmail}>
              <FormLabel htmlFor="contactEmail">
                Fallback Support Email*
                <Tooltip
                  label="This is added in the footer of all the relevant notification emails to ensure your end users have a way to reach out to you should they need to."
                  backgroundColor={'blackAlpha.900'}
                >
                  <InfoIcon fontSize={'small'} />
                </Tooltip>
              </FormLabel>
              <Field as={Input} type="text" name="contactEmail" disabled={!canEdit} />
              {errors?.contactEmail && touched.contactEmail && (
                <FormErrorMessage>Enter a valid email</FormErrorMessage>
              )}
            </FormControl>
          </Flex>
          <FormLabel mb={6}>
            <Checkbox
              isChecked={values.allowWebhookSubscription}
              onChange={e => setFieldValue('allowWebhookSubscription', e.target.checked)}
              disabled={!canEdit}
            >
              Allow users to subscribe via Webhook
            </Checkbox>
          </FormLabel>
          <Divider />
          <FormLabel mt={6}>
            <Field
              as={() => (
                <Checkbox defaultChecked disabled>
                  Entire Status Page
                </Checkbox>
              )}
              mb={8}
            />
          </FormLabel>
          <FormLabel>
            <Checkbox
              isChecked={values.componentSubscriptionEnabled}
              onChange={e => setFieldValue('componentSubscriptionEnabled', e.target.checked)}
              disabled={!canEdit}
            >
              Individual Components{' '}
              {values.componentSubscriptionCount > 0
                ? `(${values.componentSubscriptionCount} Subscribers)`
                : null}
            </Checkbox>
          </FormLabel>
          {values.showComponentMessage &&
          !values.componentSubscriptionEnabled &&
          values.componentSubscriptionCount > 0 ? (
            <Text ml={6} fontSize={12} mb={6}>
              Note: This action will remove your existing individual component subscribers from this
              list. An email will inform them of this change.
            </Text>
          ) : null}
          <FormLabel>
            <Checkbox
              isChecked={values.maintenanceSubscriptionEnabled}
              onChange={e => setFieldValue('maintenanceSubscriptionEnabled', e.target.checked)}
              disabled={!canEdit}
            >
              Maintenance Updates{' '}
              {values.maintenanceSubscriptionCount > 0
                ? `(${values.maintenanceSubscriptionCount} Subscribers)`
                : null}
            </Checkbox>
          </FormLabel>
          {values.showMaintenanceMessage &&
          !values.maintenanceSubscriptionEnabled &&
          values.maintenanceSubscriptionCount > 0 ? (
            <Text ml={6} fontSize={12} mb={6}>
              Note: This action will remove your existing maintenance subscribers from this list. An
              email will inform them of this change.
            </Text>
          ) : null}
        </Box>
        <Box w="100%" bottom={0} position="fixed" bgColor={'white'} zIndex="2">
          <Divider />
          <HStack px={2} py={2} gap={4}>
            <Button
              onClick={submitForm}
              variant="default"
              isLoading={isUpdating}
              disabled={!canEdit}
            >
              Save
            </Button>
            <Button variant="invertedDefault" onClick={onClose}>
              Cancel
            </Button>
          </HStack>
        </Box>
      </form>
    </Box>
  );
};

const SubscribersSettings = (props: Props) => {
  const { formik, isSuccess, isUpdating } = useSubscribersSettings(props.pageId, () => {
    props.onClose();
    reactQueryConfigSuccess('Subscriber settings updated');
  });

  return isSuccess ? (
    <FormikProvider value={formik}>
      <StatusMessageForm onClose={props.onClose} isUpdating={isUpdating} canEdit={props.canEdit} />
    </FormikProvider>
  ) : (
    <Center h="60vh" data-testid="loader-issue">
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
    </Center>
  );
};

export default SubscribersSettings;
