import axios from 'axios';
import { API } from '../api';
import { IUserNotificationRule, IUserProfile } from '../interfaces/IUserData';

class ProfileService {
  private _api = `${API.config.batman}/me`;

  public getUserInfo = () =>
    axios.get<{ data: IUserProfile }>(`${this._api}`, { withCredentials: true });

  public update = (
    req: Partial<
      Pick<IUserProfile, 'first_name' | 'last_name' | 'contact' | 'time_zone' | 'date_format'>
    >,
  ) => axios.put<{ data: IUserProfile }>(`${this._api}`, req);

  public changePassword = (currentPassword: string, newPassword: string) =>
    axios.put(`${this._api}/password`, { password: currentPassword, new_password: newPassword });

  public updateOncallReminderRules = (reminderRules: IUserNotificationRule[]) =>
    axios.put<{ data: IUserNotificationRule }>(`${this._api}/oncall-reminder-rules`, {
      rules: reminderRules,
    });

  public updateIncidentNotificationRules = (notificationRules: IUserNotificationRule[]) =>
    axios.put<{ data: IUserNotificationRule }>(`${this._api}/notification-rules`, {
      rules: notificationRules,
    });

  public setOverrideDND = () =>
    axios.put<{ data: IUserProfile }>(`${this._api}/notification-rules/set-override-dnd`);
  public resendVerificationEmail = () => axios.post(`${this._api}/send-verification-email`);

  public resetOverrideDND = () =>
    axios.put<{ data: IUserProfile }>(`${this._api}/notification-rules/reset-override-dnd`);
}
export default ProfileService;
