import { Button, Divider, Flex, Heading, HStack, Tag, Text, VStack } from '@chakra-ui/react';
import Header from 'components/chakra/Header';
import { NodePopover } from 'components/chakra/Popover';
import SQTooltip from 'components/chakra/Tooltip';
import { T_WA_UP_SCHEDULES_V2_EXPANDED_IN_SCHEDULE_LIST_PAGE } from 'core/const/tracker';
import { truncate } from 'core/helpers/stringUtils';
import { EscalationIcon } from 'icons';
import { DateTime } from 'luxon';
import React, { Fragment, useEffect, useState } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { AppTracker } from 'shared/analytics/tracker';
import { EntityType } from 'views/main/organization/navigation-flows/helpers';
import useUINavigationFunctions from 'views/main/organization/navigation-flows/useUINavigationFunctionV2';
import { SCHEDULES_V2_DETAIL_PATH } from 'views/main/routes/routes';
import { maxEscalationsCount, maxTagsCount } from '../../constants/schedules.view';
import { useGetAllPatternParticipants } from '../../helpers/helpers.customrotations';
import { getDayMonthDate, getTZOffset } from '../../helpers/helpers.date';
import { mapParticipantToEventParticipant } from '../../helpers/helpers.event';
import {
  CalendarViewType,
  ISchedulesDetail,
  ISchedulesOnCallUsers,
} from '../../interface/schedule';
import ScheduleActions from '../../schedules.actions';
import ScheduleEscalationPolicies from '../schedules.escalations';
import ScheduleTags from '../schedules.tags';
import RotationTable from './table.rotation';

interface IWeekView {
  schedules: ISchedulesDetail;
  onCallUsers: ISchedulesOnCallUsers | undefined;
  visibleDates: DateTime[];
  allExpanded?: boolean;
}

function WeekView({ schedules, onCallUsers, visibleDates, allExpanded }: IWeekView) {
  const [scheduleVisibility, setScheduleVisibility] = useState<Record<string, boolean>>({});
  const allParticipants = useGetAllPatternParticipants();
  const { getEntityComponent } = useUINavigationFunctions();

  const getScheduleStartEndDate = (schedule: NonNullable<ISchedulesDetail>[number]) => {
    const hasForeverRotation = schedule?.rotations?.some(r => r.endDate == null || !r.endDate);
    const [rotationStart, rotationEnd] =
      schedule.rotations?.reduce(
        (acc, rotation) => {
          const startDate = new Date(rotation.startDate);
          const endDate = rotation.endDate ? new Date(rotation.endDate) : null;
          if (startDate) {
            if (acc[0]) {
              acc[0] = acc[0].getTime() < startDate.getTime() ? acc[0] : startDate;
            }
            acc[0] = startDate;
          }

          if (endDate) {
            let farthestEndDate = endDate;
            if (acc[1] && acc[1].getTime() > farthestEndDate.getTime()) {
              farthestEndDate = acc[1];
            }
            acc[1] = farthestEndDate;
          }
          return acc;
        },
        [null, null] as (null | Date)[],
      ) || [];

    const rotationStartString = rotationStart
      ? DateTime.fromJSDate(rotationStart)
          .setZone('UTC')
          .toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' })
      : '';

    const rotationEndString =
      hasForeverRotation || !rotationEnd ? 'Forever' : getDayMonthDate(rotationEnd);

    return [rotationStartString, rotationEndString].filter(rt => rt).join(' - ');
  };

  const showHideSchedule = (scheduleId: number) => {
    setScheduleVisibility(oldState => ({ ...oldState, [scheduleId]: !oldState[scheduleId] }));
  };

  /**
   * Running Async to make sure the below useEffect runs first
   */
  useEffect(() => {
    setTimeout(() => {
      setScheduleVisibility(oldState =>
        Object.keys(oldState).reduce((acc, key) => {
          acc[key] = allExpanded ? true : false;
          return acc;
        }, {} as Record<string, boolean>),
      );
    }, 0);
  }, [allExpanded]);

  useEffect(() => {
    const schedulesIds = schedules?.map(s => s.ID) || [];
    setScheduleVisibility(oldState => {
      return schedulesIds.reduce((acc, val) => {
        acc[val.toString()] = oldState[val] || allExpanded || false;
        return acc;
      }, {} as Record<string, boolean>);
    });
  }, [schedules, allExpanded]);

  return (
    <Fragment>
      <Flex
        rowGap={5}
        direction="column"
        maxW={{ lg: '93vw', xl: '93vw', '2xl': '95vw' }}
        overflow="hidden"
      >
        {schedules?.map(schedule => {
          const isHidden = !scheduleVisibility[schedule.ID];
          const timeZoneQueryParam = encodeURIComponent(schedule.timeZone);
          const scheduleLink =
            generatePath(SCHEDULES_V2_DETAIL_PATH, { scheduleId: schedule.ID }) +
            `?view=${CalendarViewType.twotimeGridWeek}&timezone=${timeZoneQueryParam}`;
          const range = getScheduleStartEndDate(schedule);
          const rotationsWithScheduleDetails = schedule.rotations?.map(rotation => ({
            ...rotation,
            scheduleName: schedule.name,
            scheduleTimeZone: schedule.timeZone,
            isSchedulePaused: schedule.paused,
          }));
          const onCallUser = !schedule.paused
            ? Array.from(
                new Set(
                  onCallUsers?.whoIsOncall
                    ?.find(oncall => oncall.schedule.ID === schedule.ID)
                    ?.oncallParticipants?.map(p =>
                      mapParticipantToEventParticipant({ ID: p.ID, type: p.type }, allParticipants),
                    ),
                ),
              )
            : [];

          const tags = schedule.tags?.filter(tag => !!tag.key && !!tag.value);
          const escalationPolicies = schedule.escalationPolicies?.filter(ep => !!ep.name);
          const timeZoneText = `${getTZOffset(schedule.timeZone).shortName} (GMT ${
            getTZOffset(schedule.timeZone).shortOffset
          })`;
          const isUsedinEscalationPolicy = escalationPolicies && escalationPolicies.length > 0;

          const EscalationPolicyText = () => (
            <HStack>
              <EscalationIcon />
              <Text fontSize={12}>ESCALATION POLICY</Text>
            </HStack>
          );

          return (
            <VStack key={schedule.ID} w="100%" spacing={0}>
              <Header
                paddingX={6}
                paddingY={4}
                bgColor={schedule.paused ? 'gray.120' : 'blue.50'}
                title={
                  <VStack alignItems="flex-start">
                    <HStack>
                      <Link to={scheduleLink}>
                        <SQTooltip text={schedule.name}>
                          <Heading
                            as="h6"
                            size="sm"
                            color="default"
                            fontWeight="bold"
                            maxW={250}
                            isTruncated
                          >
                            {schedule.name}
                          </Heading>
                        </SQTooltip>
                      </Link>
                      <Text fontSize="xs">FINAL SCHEDULE</Text>
                      {range && (
                        <Text className="time-zone-range" fontSize="xs">
                          ({range})
                        </Text>
                      )}
                      <Text fontSize="xs">|</Text>
                      <SQTooltip text={getTZOffset(schedule.timeZone).longName}>
                        <Text fontSize="xs" className="time-zone-text">
                          {timeZoneText}
                        </Text>
                      </SQTooltip>
                      <Text fontSize="xs">|</Text>

                      {onCallUser.length && (
                        <HStack>
                          <Text fontSize="xs" isTruncated maxW={400}>
                            On Call -
                          </Text>
                          {onCallUser.slice(0, 3).map((user, index) => {
                            const participantComponent = (
                              <Text
                                fontSize={'14px'}
                                fontWeight={user.type === 'squad' ? 500 : 900}
                                color={'#3D6DD8'}
                                _hover={{ cursor: 'pointer' }}
                                maxW={310}
                                whiteSpace="nowrap"
                              >
                                {onCallUser.length === 1
                                  ? user.participant.name
                                  : user.participant.name +
                                    (index === onCallUser.slice(0, 3).length - 1 ? '' : ' , ')}
                              </Text>
                            );

                            const participantComponentWithLinkStyles = (
                              <Text
                                fontSize={'14px'}
                                fontWeight={user.type === 'squad' ? 500 : 900}
                                color={'#3D6DD8'}
                                _hover={{ cursor: 'pointer' }}
                                maxW={310}
                                whiteSpace="nowrap"
                              >
                                {onCallUser.length === 1
                                  ? truncate(user.participant.name as string, 30)
                                  : truncate(user.participant.name as string, 30) +
                                    (index === onCallUser.slice(0, 3).length - 1 ? '' : ' , ')}
                              </Text>
                            );
                            let nameComponent = participantComponent;
                            nameComponent = getEntityComponent({
                              componentType:
                                user.type === 'squad' ? 'linkWOPopover' : 'linkWPopover',
                              type: user.type as EntityType,
                              id: user.ID,
                              popoverTrigger: participantComponentWithLinkStyles,
                              popoverPlacement: 'left-start',
                            });
                            return <React.Fragment key={user.ID}>{nameComponent}</React.Fragment>;
                          })}
                          {onCallUser.length > 4 && (
                            <NodePopover
                              hasArrow
                              trigger={
                                <Button
                                  backgroundColor="transparent"
                                  _focus={{}}
                                  height={'auto'}
                                  p={0}
                                >
                                  <Tag size="sm" borderRadius="full" _hover={{ cursor: 'pointer' }}>
                                    +{onCallUser.length - 3}
                                  </Tag>
                                </Button>
                              }
                            >
                              {onCallUser.slice(3).map((user, index) => {
                                const participantComponent = (
                                  <Text
                                    fontWeight={user.type === 'squad' ? 500 : 900}
                                    maxW={250}
                                    isTruncated
                                    color="#3D6DD8"
                                    _hover={{ cursor: 'pointer' }}
                                  >
                                    {user.participant.name}
                                  </Text>
                                );

                                const participantComponentWithLinkStyles = (
                                  <Text
                                    fontWeight={user.type === 'squad' ? 500 : 800}
                                    fontSize="14px"
                                    maxW={250}
                                    isTruncated
                                    color="#3D6DD8"
                                    _hover={{ cursor: 'pointer' }}
                                    onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                                      event.stopPropagation()
                                    }
                                  >
                                    {user.participant.name}
                                  </Text>
                                );

                                let nameComponent = participantComponent;
                                nameComponent = getEntityComponent({
                                  componentType:
                                    user.type === 'squad' ? 'linkWOPopover' : 'linkWPopover',
                                  type: user.type as EntityType,
                                  id: user.ID,
                                  popoverTrigger: participantComponentWithLinkStyles,
                                  popoverPlacement: 'right-start',
                                });
                                return (
                                  <React.Fragment key={user.ID}>{nameComponent}</React.Fragment>
                                );
                              })}
                            </NodePopover>
                          )}
                        </HStack>
                      )}
                      {schedule.paused && <Text fontSize="xs">|</Text>}
                      {schedule.paused && <Text fontSize="xs">Paused</Text>}
                    </HStack>
                    <ScheduleTags tags={tags ?? []} maxTagsCount={maxTagsCount.home} />
                  </VStack>
                }
                actions={
                  <HStack gap={1}>
                    <Button
                      variant="link_wo_underscore"
                      onClick={() => {
                        showHideSchedule(schedule.ID);
                        AppTracker.track(T_WA_UP_SCHEDULES_V2_EXPANDED_IN_SCHEDULE_LIST_PAGE);
                      }}
                    >
                      {isHidden ? 'Show' : 'Hide'} Schedule
                    </Button>

                    <ScheduleActions schedule={schedule} isScheduleDetailsView={false} />
                  </HStack>
                }
              />

              <VStack w="100%">
                {!isHidden && (
                  <RotationTable
                    timeZone={schedule.timeZone}
                    rotations={rotationsWithScheduleDetails ?? []}
                    visibleDates={visibleDates}
                    gaps={schedule.gaps}
                    overrides={schedule.overrides}
                    onCallCoverage={schedule.oncallCoverage}
                  />
                )}

                <Header
                  paddingX={6}
                  paddingY={1}
                  title={
                    <HStack>
                      <EscalationPolicyText />

                      {isUsedinEscalationPolicy ? (
                        <ScheduleEscalationPolicies
                          escalationPolicies={escalationPolicies}
                          maxEscalationsCount={maxEscalationsCount.details}
                        />
                      ) : (
                        <Text fontSize={12}>None</Text>
                      )}
                    </HStack>
                  }
                />
                <Divider size="sm" />
              </VStack>
            </VStack>
          );
        })}
      </Flex>
    </Fragment>
  );
}

export default WeekView;
