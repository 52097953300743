import { Grid } from 'uie/components';
import getLogIcon from 'views/main/organization/navigation-flows/ActivityLogIcon';
import { ILog } from 'core/interfaces/IIncidents';

const ActivityIcon = ({ log }: { log: ILog }) => {
  return (
    <Grid type="column" alignItems="center">
      <Grid
        alignItems="center"
        justifyContent="center"
        style={{
          height: 40,
          width: 40,
        }}
      >
        <Grid
          style={{
            width: 40,
            height: 40,
            backgroundColor: 'whitesmoke',
            borderRadius: 100,
            position: 'relative',
            zIndex: 1,
          }}
          alignItems="center"
          justifyContent="center"
        >
          {getLogIcon(log)}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActivityIcon;
