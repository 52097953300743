import { ISSUE_FILTER_TYPES, SearchActionKind } from '../constants/status.constants';
import { IIssuefilterObject, IssueListSearchAction } from '../Interface';

// An interface for our actions

export const issueFilterInit = {
  [ISSUE_FILTER_TYPES.ISSUE_STATUS]: [],
  [ISSUE_FILTER_TYPES.COMPONENT_STATUS]: [],
  [ISSUE_FILTER_TYPES.COMPONENT_GROUP]: [],
  [ISSUE_FILTER_TYPES.COMPONENT]: [],
  [ISSUE_FILTER_TYPES.TIME]: [],
};

// An interface for our state
interface IssueListState {
  search: {
    searchText: string;
    enabled: boolean;
  };
  pagination: {
    queryPageIndex: number;
    queryPageSize: number;
  };
  drawer: {
    isDrawerOpen: boolean;
    drawerType: string | null;
    issueId: number;
    prevDrawer: string;
  };
  deleteIssue: {
    showModal: boolean;
    selectedIssueeID: number;
  };
  filters: IIssuefilterObject;
  filterCount: number;
}

export function IssueListReducer(state: IssueListState, action: IssueListSearchAction) {
  const {
    type,
    isEnabled = false,
    searchText = '',
    queryPageIndex = 1,
    queryPageSize = 10,
    drawerType = null,
    filters = issueFilterInit,
    issueId = -1,
    prevDrawer = '',
  } = action;
  switch (type) {
    case SearchActionKind.SEARCH_ENABLED_OR_DISABLED:
      return {
        ...state,
        search: {
          ...state.search,
          enabled: isEnabled,
        },
      };
    case SearchActionKind.SEARCHED:
      return {
        ...state,
        search: {
          ...state.search,
          searchText: searchText,
        },
        pagination: {
          ...state.pagination,
          queryPageIndex: 1,
        },
      };

    case SearchActionKind.PAGE_INDEX_CHANGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          queryPageIndex,
        },
      };
    case SearchActionKind.PAGE_SIZE_CHANGED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          queryPageSize,
        },
      };

    case SearchActionKind.OPEN_DRAWER:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          drawerType,
          isDrawerOpen: true,
          issueId,
          prevDrawer: prevDrawer,
        },
      };

    case SearchActionKind.CLOSE_DRAWER:
      const hasPreviousDrawer = state.drawer.prevDrawer !== '';
      return {
        ...state,
        drawer: {
          ...state.drawer,
          drawerType: hasPreviousDrawer ? state.drawer.prevDrawer : null,
          isDrawerOpen: hasPreviousDrawer,
          prevDrawer: '',
        },
      };

    case SearchActionKind.APPLY_FILTER:
      const count = Object.values(filters).reduce((a, c) => (a = a + c.length), 0);
      return {
        ...state,
        filters,
        filterCount: count,
        pagination: {
          ...state.pagination,
          queryPageIndex: 1,
        },
      };
    case SearchActionKind.SELECT_PAGE_DELETE:
      return {
        ...state,
        deleteIssue: {
          showModal: true,
          selectedIssueeID: issueId,
        },
      };

    case SearchActionKind.REJECT_PAGE_DELETE:
      return {
        ...state,
        deleteIssue: {
          showModal: false,
          selectedIssueeID: -1,
        },
      };
    default:
      return state;
  }
}
