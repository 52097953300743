import { HStack, StackItem, Text } from '@chakra-ui/react';
import { Dropdown } from 'library/atoms';
import React, { useMemo } from 'react';

import { IPageLimits, PageSizePaginationProps } from '../types';

const validPageSizeLimits: IPageLimits = [5, 10, 20, 30, 50, 100];

export const PageSize: React.FC<PageSizePaginationProps> = ({
  disabled,
  pageSize,
  setPageSize,
  maxPageSize,
}) => {
  const dropdownoptions = useMemo(
    () =>
      validPageSizeLimits
        .filter(l => (maxPageSize ? l <= maxPageSize : l))
        .map(l => ({ value: l.toString(), label: l.toString() })),
    [validPageSizeLimits],
  );

  const currentPageSize = {
    value: pageSize.toString(),
    label: pageSize.toString(),
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  return (
    <StackItem>
      <HStack>
        <StackItem>
          <Text variant="h4" color="secondary.700">
            Show rows per page
          </Text>
        </StackItem>
        <StackItem>
          <Dropdown
            onChange={newVal => handleChangePageSize(parseInt((newVal as { value: string }).value))}
            options={dropdownoptions}
            value={currentPageSize}
            styles={{
              container: base => ({
                ...base,
                minWidth: 'max-content',
              }),
            }}
            isDisabled={disabled}
            menuPlacement="auto"
          />
        </StackItem>
      </HStack>
    </StackItem>
  );
};
