import {
  Box,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  ComponentDayLog,
  Maybe,
  OverrideStats,
  StatusDurations,
  StatusSlugDuration,
} from 'views/main/organization/statuspage-v2/graphql/types';
import { getStatusCodeMapping } from 'views/main/organization/statuspage-v2/helpers/helper.timeline';

import { THEME_COLORS } from '../../../../../../../../../library/theme/colors';
import useStatuspageComponentTimelineContext from '../hook';
import OverrideSymbol from './OverrideSymbol';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_TIMELINE_OVERRIDE_EDIT_TIMELINE_MODAL_OPENED } from 'core/const/tracker';

export interface IProps {
  componentDayLog?: ComponentDayLog;
}

export default function StatusPageComponentTimelineItem(props: IProps) {
  const {
    statusCodes,
    activeDate,
    setActiveDate,
    overrideOpen,
    setOverrideOpen,
    overrideUnsaved,
    setUnsavedModalOpen,
    setUnsavedModalCallback,
    statusPageId,
    component,
  } = useStatuspageComponentTimelineContext();
  const currentDateString = new Date(props.componentDayLog?.date).toISOString();
  const isActiveItem = activeDate === currentDateString;

  const date = new Date(props.componentDayLog?.date);
  let dateString = date.toDateString();
  const dateStringSplit = dateString.split(' ');
  dateStringSplit.shift();
  dateString = dateStringSplit.join(' ');

  const statusCodeMap = getStatusCodeMapping(statusCodes);

  const onMouseEnter = async () => {
    return await new Promise(resolve => {
      if (!overrideOpen) {
        setActiveDate(currentDateString);
      }
      resolve(true);
    });
  };

  const onMouseLeave = async () => {
    return await new Promise(resolve => {
      if (!overrideOpen) {
        setActiveDate(null);
      }
      resolve(true);
    });
  };

  return (
    <VStack
      spacing="2px"
      className="component-timeline-graph-item-container"
      style={{
        marginBottom: props.componentDayLog?.override ? (isActiveItem ? '8px' : '6px') : '0px',
      }}
    >
      {props.componentDayLog?.override && <OverrideSymbol size={isActiveItem ? 6 : 4} />}
      <Popover
        trigger="hover"
        placement="bottom-end"
        offset={isActiveItem ? [6, 8] : [8, 8]}
        strategy="fixed"
      >
        <PopoverTrigger>
          <Box
            className={
              'component-timeline-graph-item' +
              (isActiveItem || (isActiveItem && overrideOpen)
                ? ' component-timeline-graph-item-hover'
                : '')
            }
            style={{
              backgroundColor: props.componentDayLog?.overallStatus.color,
              opacity: !isActiveItem && overrideOpen ? 0.5 : 1,
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          ></Box>
        </PopoverTrigger>
        <PopoverContent
          bg={THEME_COLORS.secondary[100]}
          width="320px"
          border="none"
          borderRadius="0px"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <PopoverArrow bg={THEME_COLORS.secondary[100]} />
          <PopoverBody padding="8px">
            <VStack spacing="8px">
              <HStack justify="space-between" align="center" width="100%" mb="4px">
                <Text fontSize="14px" fontWeight={400} color={THEME_COLORS.secondary[1000]}>
                  {dateString}
                </Text>
                {props.componentDayLog?.overallStatus?.slug !== 'not-created' && (
                  <Text
                    fontSize="12px"
                    fontWeight={800}
                    color={THEME_COLORS.brand.blue}
                    onClick={async () =>
                      await new Promise(resolve => {
                        if (overrideUnsaved) {
                          setUnsavedModalOpen(true);
                          setUnsavedModalCallback(() => () => setActiveDate(currentDateString));
                        } else {
                          setActiveDate(currentDateString);
                          setOverrideOpen(true);
                          AppTracker.track(
                            T_WA_UP_STATUS_PAGE_V2_TIMELINE_OVERRIDE_EDIT_TIMELINE_MODAL_OPENED,
                            {
                              'Status Page ID': statusPageId.toString(),
                              'Component ID': component.id?.toString(),
                            },
                          );
                        }
                        resolve();
                      })
                    }
                  >
                    Edit
                  </Text>
                )}
              </HStack>
              {props?.componentDayLog?.override
                ? [
                    ...Object.keys(props.componentDayLog?.statusDurations || {})
                      .filter(key => {
                        return (
                          props.componentDayLog?.overrideData &&
                          props.componentDayLog?.overrideData[key as keyof OverrideStats] > 0
                        );
                      })
                      .map(key => {
                        const statusDuration = props.componentDayLog?.statusDurations[
                          key as keyof StatusDurations
                        ] as StatusSlugDuration;
                        return (
                          <TimelineItemPopoverPair
                            key={`${key}-${dateString}`}
                            left={statusCodeMap[statusDuration?.slug]}
                            right={
                              props.componentDayLog?.overrideData &&
                              props.componentDayLog?.overrideData[key as keyof OverrideStats]
                            }
                          />
                        );
                      }),
                    (props.componentDayLog?.statusDurations['underMaintenance']?.duration ?? 0) >
                    0 ? (
                      <TimelineItemPopoverPair
                        key={`underMaintenance-${dateString}`}
                        left={statusCodeMap['under-maintenance']}
                        right={props.componentDayLog?.statusDurations['underMaintenance']?.duration}
                      />
                    ) : (
                      ''
                    ),
                  ]
                : Object.keys(props.componentDayLog?.statusDurations || {})
                    .filter(key => {
                      return (
                        props.componentDayLog?.statusDurations &&
                        (
                          props.componentDayLog?.statusDurations[
                            key as keyof StatusDurations
                          ] as StatusSlugDuration
                        )?.duration > 0
                      );
                    })
                    .map(key => {
                      const statusDuration = props.componentDayLog?.statusDurations[
                        key as keyof StatusDurations
                      ] as StatusSlugDuration;
                      return (
                        <TimelineItemPopoverPair
                          key={`${key}-${dateString}`}
                          left={statusCodeMap[statusDuration?.slug]}
                          right={statusDuration?.duration}
                        />
                      );
                    })}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </VStack>
  );
}

function TimelineItemPopoverPair(props: { left: string; right?: string | number | null }) {
  let hours = 0;
  let minutes = 0;
  let rightContent = '';
  if (typeof props.right === 'string') {
    rightContent = props.right;
  } else if (typeof props.right === 'number') {
    hours = Math.floor(props.right / 60);
    minutes = props.right % 60;
    let hoursStr = hours.toFixed(0);
    let minutesStr = minutes.toFixed(0);
    if (hours < 10) {
      hoursStr = '0' + hours.toFixed(0); // adding leading zero
    }
    if (minutes < 10) {
      minutesStr = '0' + minutes.toFixed(0); // adding leading zero
    }
    rightContent = `${hoursStr} hrs ${minutesStr} mins`;
  }

  return (
    <HStack justify="space-between" align="center" width="100%" mb="4px">
      <Text fontSize="14px" fontWeight={400} color={THEME_COLORS.secondary[1000]}>
        {props.left}
      </Text>
      <Text fontSize="14px" fontWeight={400} color={THEME_COLORS.secondary[1000]}>
        {rightContent}
      </Text>
    </HStack>
  );
}
