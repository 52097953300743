import * as React from 'react';
import { SVGProps } from 'react';

const SvgSqreen = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <circle
      cx={10}
      cy={9.993}
      style={{
        fill: '#b0acff',
      }}
      r={4.748}
    />
    <path
      d="M9.339 18.204v-7.648L2.56 6.677v6.73l2.398-1.383c.159.404.404.82.661 1.175l-2.496 1.432c-.012 0 6.216 3.573 6.216 3.573zm1.322 0 6.216-3.573-2.496-1.432c.257-.355.514-.771.661-1.175l2.398 1.383v-6.73l-6.779 3.928v7.599zm-7.599-12.8L10 9.43l6.938-4.026-6.265-3.622v2.802c-.208-.049-.453-.049-.661-.049-.208 0-.465 0-.661.049V1.782L3.062 5.404zm7.55-5.249 7.599 4.381c.355.208.612.612.612 1.077v8.774c0 .453-.257.82-.612 1.077l-7.599 4.381a1.278 1.278 0 0 1-1.224 0l-7.599-4.381c-.355-.257-.612-.612-.612-1.065V5.624c0-.465.257-.82.612-1.077L9.388.166c.355-.22.82-.22 1.224-.011z"
      style={{
        fill: '#4842b7',
      }}
    />
  </svg>
);

export default SvgSqreen;
