import * as React from 'react';
import { SVGProps } from 'react';

const SvgKapacitorLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M11.8 20c-.6.1-1.1-.3-1.6-.4-1.6-.4-3.1-.9-4.7-1.4-.8-.2-1.5-.4-2.3-.7-.8-.3-1.1-.9-1.2-1.6-.2-1.2-.5-2.3-.8-3.5-.1-.6-.3-1.3-.4-1.9C.7 9.9.5 9.2.4 8.6.3 8.2.2 7.9.1 7.5c-.1-.6.2-1 .6-1.4.9-.9 1.9-1.8 2.9-2.7 1.1-1 2.1-2 3.2-2.9.2-.2.4-.3.7-.4.4-.2.7 0 1 .1 1.7.5 3.3 1 5 1.5 1.1.3 2.2.7 3.3 1 .8.2 1.1.8 1.3 1.5l.9 3.9c.2 1.1.6 2.1.8 3.2.1.5.3.9.2 1.5-.1.4-.3.6-.6.9-.9.8-1.8 1.6-2.6 2.4-1.3 1.1-2.5 2.3-3.8 3.4-.5.3-.8.6-1.2.5zm1-5.7c0-.3-.1-.4-.3-.6-.3-.2-.6-.2-.9-.3-.9-.3-1.9-.7-2.9-.9l-3-.9c-.4-.1-.6 0-.8.4-.4 1.1-.7 2.3-1.1 3.4-.2.5 0 .8.5 1 .7.2 1.4.4 2.2.6 1.4.4 2.8.9 4.2 1.3.6.2.9.1 1-.6.3-1 .6-1.9.9-2.9.2-.2.3-.4.2-.5zM2 7.3c0 .2 0 .3.1.4.9.9 1.8 1.8 2.6 2.7.3.3.5.3.8 0 1-1 2.1-1.8 3.1-2.8.6-.6 1.3-1.2 1.9-1.8.4-.4.5-.6.1-1-.7-.9-1.5-1.7-2.2-2.5-.6-.6-.8-.6-1.4 0-.2.3-.5.6-.8.9-1.3 1.2-2.7 2.4-4 3.7-.2.1-.3.2-.2.4zm9.9-1.4c.1.8.3 1.6.5 2.4.3 1.5.8 2.9 1 4.4.1.4.3.6.6.5 1.2-.3 2.4-.5 3.5-.8.4-.1.7-.4.5-.8-.2-.5-.2-1.1-.3-1.6-.1-.4-.2-.8-.3-1.3-.3-1.3-.6-2.5-.8-3.7-.1-.6-.4-.7-.9-.5-.4.1-.9.3-1.3.3-.7.1-1.3.3-2 .4-.4.2-.6.4-.5.7zm1 6.7c-.1-.5-.3-1.1-.4-1.8-.3-1.3-.7-2.6-.9-4-.1-.3-.1-.5-.2-.7-.1-.3-.3-.3-.5-.1-.3.3-.6.5-.8.8-.9.9-1.7 1.7-2.6 2.4-.5.4-1 .9-1.5 1.4-.3.3-.2.5.1.6 2.1.6 4.1 1.3 6.2 1.9.4.1.5 0 .6-.5zm-8.2-1.3c0-.2-.1-.4-.2-.6-.7-.7-1.3-1.4-2-2.1-.2 0-.3-.1-.4 0-.2.1-.2.2-.1.4s.2.4.2.7c.2 1.1.5 2.2.7 3.3.1.5.2 1.1.4 1.6 0 .1.1.3.2.3.2 0 .1-.1.2-.2s.1-.3.2-.4c.1-.4.2-.9.4-1.3.2-.6.3-1.1.4-1.7zm7.8 5.9c0 .1 0 .3.1.3.1.1.2 0 .3-.1.3-.2.4-.4.6-.6l3.6-3.3c.1-.1.2-.2.1-.3-.1-.1-.2-.1-.3-.1-.3.1-.7.2-1 .3-.6.1-1.1.3-1.7.4-.4.1-.6.3-.8.6-.2.6-.4 1.1-.5 1.6-.1.4-.3.8-.4 1.2zm-.8-12.4c.1 0 .1 0 0 0 1-.2 1.9-.4 2.9-.6.1 0 .1-.1.2-.2 0-.1-.1-.2-.1-.2-.1 0-.2-.1-.3-.1-1.6-.5-3.3-1-4.9-1.5-.1 0-.2-.1-.3 0s0 .3.1.3c.6.6 1.2 1.3 1.8 1.9.2.3.4.4.6.4z"
      style={{
        fill: '#7f51e2',
      }}
    />
  </svg>
);

export default SvgKapacitorLogo20X20;
