import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Theme,
  Para,
  IconButton,
  DropDown,
  DropDownOptionShell,
  DialogModalFrame,
} from 'uie/components';
import { Tooltip } from '@chakra-ui/react';
import { IUsersOfOrganization } from '../../../../../../core/interfaces/IUserData';
import { IAppState } from '../../../../../../core/interfaces/IAppState';
import { IRole } from '../../../../../../core/interfaces/IRole';
import { Link } from 'react-router-dom';
import { IOrganization } from '../../../../../../core/interfaces/IOrganization';
import { Check, MoreVerticalIcon, UsernameIcon } from '../../../../../../icons';
import ConfirmModal from 'components/confirm.modal';
import { BillingService } from 'core';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { THEME_COLORS } from 'library/theme/colors';
import PendingVerification from './pendingVerification';
import { truncate } from 'core/helpers/stringUtils';
import ReminderComponent from './ReminderComponent';
import { Note } from '../../shared/note';

const { theme } = Theme;

interface IProps extends Pick<IAppState, 'organization' | 'integrations' | 'APP_CONFIG' | 'roles'> {
  user: IUsersOfOrganization;
  selectedOrg: IOrganization;
  index: number;
  resendVerificationMail: (user: IUsersOfOrganization) => () => Promise<boolean>;
  deleteUser: (userID: string) => void;
  updateUser: (userID: string, index: number, roleID: string, role: string) => void;
  saving: number;
  orgHasEnabledSSO: boolean;
}
interface IState {
  roleEnabled: number;
  saving: number;
  selectedRole: string;
  showConfirmRoleChangeModal: boolean;
  userId: string;
  index: number;
  roleId: string;
  userLimitExceeded: boolean;
  stakeholderLimitExceeded: boolean;
  showUpgradeUserLimitExceeded: boolean;
  showUpgradeStakeholderLimitExceeded: boolean;
  isReminderButtonDisabled: boolean;
  reminderCountdownStarted: boolean;
}

class UserItem extends Component<IProps, IState> {
  _resendInviteRef = React.createRef<HTMLButtonElement>();

  constructor(props: IProps) {
    super(props);

    this.state = {
      roleEnabled: -1,
      saving: -1,
      selectedRole: '',
      showConfirmRoleChangeModal: false,
      userId: '',
      index: 0,
      roleId: '',
      userLimitExceeded: false,
      stakeholderLimitExceeded: false,
      showUpgradeStakeholderLimitExceeded: false,
      showUpgradeUserLimitExceeded: false,
      isReminderButtonDisabled: false,
      reminderCountdownStarted: false,
    };
  }

  public componentDidMount() {
    this.setState({
      userLimitExceeded: BillingService.isLimitExceeded(
        this.props,
        'user',
        () => this.props.organization.users.u.filter(u => u.role !== 'stakeholder').length,
      ),
    });
    this.setState({
      stakeholderLimitExceeded: BillingService.isLimitExceeded(
        this.props,
        'stakeholder',
        () => this.props.organization.users.u.filter(u => u.role == 'stakeholder').length,
      ),
    });
  }

  public onRoleChange = (index: number, user: IUsersOfOrganization, newRole: IRole['name']) => {
    switch (newRole) {
      case 'Stakeholder':
        if (this.state.stakeholderLimitExceeded) {
          return this.setState({ showUpgradeStakeholderLimitExceeded: true });
        }
        break;
      default:
        if (this.state.userLimitExceeded) {
          return this.setState({ showUpgradeUserLimitExceeded: true });
        }
    }

    const role = this.props.roles.r.find((role: IRole) => role.name === newRole);
    if (!role) return;
    this.setState({
      userId: user.id,
      index,
      roleId: role._id,
      selectedRole: newRole,
      showConfirmRoleChangeModal: true,
    });
  };

  public confirmRoleChange = (confirmChange: boolean) => {
    if (confirmChange) {
      const { userId, index, roleId } = this.state;
      this.props.updateUser(userId, index, roleId, this.state.selectedRole);
      this.setState({ showConfirmRoleChangeModal: false });
    }
  };

  public closeConfirmModal = () => {
    this.setState({ showConfirmRoleChangeModal: false });
  };

  onResendInvite = async (userId: string) => {
    this.setState({ isReminderButtonDisabled: true });
    const result = await this.props.resendVerificationMail(this.props.user)();
    if (result) {
      this._resendInviteRef!.current?.blur();
    }
    this.setState({ isReminderButtonDisabled: false });
  };

  render() {
    const { user, index, organization, orgHasEnabledSSO } = this.props;
    const userLimit = BillingService.getLimit(this.props, 'user');
    const stakeholderlimit = BillingService.getLimit(this.props, 'stakeholder');
    const showReminder = orgHasEnabledSSO
      ? !user.phone_verified
      : !user.phone_verified || !user.email_verified;

    return (
      <Grid
        key={index}
        flexWidth={12}
        className={user.email_verified ? 'user-item' : 'user-item user-item-unverified'}
        alignItems="center"
      >
        <Grid flexWidth={3}>
          <Grid type="column">
            {(user.first_name + user.last_name).length > 40 ? (
              <Tooltip label={user.first_name + ' ' + user.last_name}>
                {truncate(user.first_name, 40)}
              </Tooltip>
            ) : (
              user.first_name + ' ' + user.last_name
            )}

            {user.id === organization.currentUser.u?.id && '· you'}
            {user.username_for_display && (
              <Grid type="row" style={{ marginBottom: 5, marginTop: 5, alignItems: 'flex-start' }}>
                <UsernameIcon height={12} width={12} style={{ marginRight: 5, marginTop: 5 }} />
                <Para
                  color={THEME_COLORS.secondary[1200]}
                  fontSize={14}
                  style={{ maxWidth: '20ch' }}
                >
                  {user.username_for_display}
                </Para>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid flexWidth={3}>
          <Grid type="column">
            <Grid>
              <Para fontSize={14} style={{ maxWidth: '20ch' }}>
                {user.email}
              </Para>
            </Grid>
            {!user.email_verified && (
              <PendingVerification
                text={orgHasEnabledSSO ? 'Awaiting login' : 'Verification pending'}
                showErrorIcon
              />
            )}
          </Grid>
        </Grid>
        <Grid flexWidth={2}>
          <Grid type="column">
            <Grid>
              <Para fontSize={14}>{`${user.contact.dial_code} ${user.contact.phone_number}`}</Para>
            </Grid>
            {!user.phone_verified && user.contact.phone_number.length > 0 && (
              <PendingVerification text="Verification pending" showErrorIcon />
            )}
            {!user.phone_verified && user.contact.phone_number.length == 0 && (
              <PendingVerification text="Phone number is not added" showErrorIcon={false} />
            )}
          </Grid>
        </Grid>
        <Grid flexWidth={2} alignItems="center" className="user_item__roles_select">
          {this.props.roles.r.find((role: IRole) => role._id === user.role_id)?.name ?? ''}
        </Grid>

        <Grid flexWidth={2} justifyContent="flex-end">
          {showReminder && <ReminderComponent user={user} />}
          {(user.email_verified || orgHasEnabledSSO) && user.phone_verified && (
            <Tooltip label="Account is verified" placement="bottom-start" hasArrow>
              <Check />
            </Tooltip>
          )}
          <DropDown
            offset="-150px"
            hook={
              <IconButton>
                <MoreVerticalIcon height="14px" width="14px" stroke={theme.shades.cement} />
              </IconButton>
            }
            width="170px"
            maxWidth="170px"
            height="auto"
          >
            <div>
              {user.email_verified && (
                <Link to={`/user/${user.id}`} style={{ textDecoration: 'none' }}>
                  <DropDownOptionShell>
                    <Para fontSize={15}>View Profile</Para>
                  </DropDownOptionShell>
                </Link>
              )}
              {user.role === 'account_owner' ? (
                <Link to={`/settings/org`} style={{ textDecoration: 'none' }}>
                  <DropDownOptionShell>
                    <Para fontSize={15}>Transfer Ownership</Para>
                  </DropDownOptionShell>
                </Link>
              ) : (
                <DropDownOptionShell
                  onClick={this.onRoleChange.bind(
                    null,
                    index,
                    user,
                    user.role === 'user' ? 'Stakeholder' : 'User',
                  )}
                >
                  <Para fontSize={15}>
                    {user.role === 'user' ? 'Convert to Stakeholder' : 'Convert to User'}
                  </Para>
                </DropDownOptionShell>
              )}
              {organization.currentUser.u!.id !== user.id && user.role !== 'account_owner' && (
                <DropDownOptionShell onClick={this.props.deleteUser.bind(null, user.id)}>
                  <Para fontSize={15} color={theme.danger.default}>
                    Delete
                  </Para>
                </DropDownOptionShell>
              )}
            </div>
          </DropDown>
        </Grid>
        <DialogModalFrame id="confirm_role_change" width="400px" onClose={this.closeConfirmModal}>
          {this.state.showConfirmRoleChangeModal && (
            <ConfirmModal
              displayText={
                <>
                  <div>
                    Are you sure you want to convert {user.first_name} {user.last_name} into a{' '}
                    {this.state.selectedRole}?
                  </div>
                  {this.state.selectedRole === 'Stakeholder' && <Note isConfirmationModal />}
                </>
              }
              buttonText="Confirm"
              hide={this.confirmRoleChange}
            />
          )}
        </DialogModalFrame>
        <UpgradePlanModal
          hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
          showModal={this.state.showUpgradeUserLimitExceeded}
          message={BillingService.getMessage(userLimit, 'user', this.props)}
          header={BillingService.getHeader(userLimit, 'user', this.props)}
          onCancel={() => {
            this.setState({ showUpgradeUserLimitExceeded: false });
          }}
          onUpgrade={() => this.setState({ showUpgradeUserLimitExceeded: false })}
        />
        <UpgradePlanModal
          hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
          showModal={this.state.showUpgradeStakeholderLimitExceeded}
          message={BillingService.getMessage(stakeholderlimit, 'stakeholder', this.props)}
          header={BillingService.getHeader(stakeholderlimit, 'stakeholder', this.props)}
          onCancel={() => {
            this.setState({ showUpgradeStakeholderLimitExceeded: false });
          }}
          onUpgrade={() => this.setState({ showUpgradeStakeholderLimitExceeded: false })}
        />
      </Grid>
    );
  }
}

export default connect((state: IAppState) => ({
  organization: state.organization,
  integrations: state.integrations,
  roles: state.roles,
  APP_CONFIG: state.APP_CONFIG,
  selectedOrg: state.userOrganizations.o.find(
    (o: IOrganization) => o.organizationId === state.INIT_ORG.orgId,
  ) as IOrganization,
}))(UserItem);
