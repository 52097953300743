import * as React from 'react';
import { SVGProps } from 'react';

const SvgLogstash = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#00bfb3',
      }}
      d="M11.563 20h6.874v-7.5h-6.875z"
    />
    <path
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#fec514',
      }}
      d="M2.188 0h-.626v12.5h8.126v-5a7.5 7.5 0 0 0-7.5-7.5"
    />
    <defs>
      <filter
        id="logstash_svg__a"
        filterUnits="userSpaceOnUse"
        x={1.563}
        y={12.5}
        width={8.125}
        height={7.5}
      >
        <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
      </filter>
    </defs>
    <mask
      maskUnits="userSpaceOnUse"
      x={1.563}
      y={12.5}
      width={8.125}
      height={7.5}
      id="logstash_svg__b"
    >
      <g
        style={{
          filter: 'url(#logstash_svg__a)',
        }}
      >
        <path
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: '#fff',
          }}
          d="M1.563 12.5h8.124V20H1.563z"
        />
      </g>
    </mask>
    <path
      style={{
        mask: 'url(#logstash_svg__b)',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#343741',
      }}
      d="M1.563 12.5a7.5 7.5 0 0 0 7.5 7.5h.624v-7.5H1.563z"
    />
  </svg>
);

export default SvgLogstash;
