import * as React from 'react';
import { SVGProps } from 'react';

const SvgGitLabLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="GitLab_Logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.GitLab_Logo-20x20_svg__st1{fill:#fc6d26}'}</style>
    <path
      d="M19.7 8 17 .8c-.1-.1-.2-.3-.3-.3-.3-.2-.8-.1-1 .2 0 .1-.1.1-.1.2l-1.8 5.6H6.3L4.4.9C4.3.5 3.9.3 3.6.4c-.1 0-.1 0-.2.1-.2.1-.3.2-.3.3L.4 7.9V8c-.8 2.1-.1 4.5 1.7 5.8L6.2 17l2.1 1.6 1.2.9c.3.2.7.2 1 0l1.2-.9 2.1-1.6 4.2-3.1c1.8-1.4 2.5-3.8 1.7-5.9z"
      style={{
        fill: '#e24329',
      }}
    />
    <path
      className="GitLab_Logo-20x20_svg__st1"
      d="M19.7 8c-1.4.2-2.6.8-3.7 1.6l-6 4.5 3.8 2.9 4.2-3.1c1.8-1.4 2.5-3.8 1.7-5.9z"
    />
    <path
      d="m6.2 17 2.1 1.6 1.2.9c.3.2.7.2 1 0l1.2-.9 2.1-1.6-3.8-2.9L6.2 17z"
      style={{
        fill: '#fca326',
      }}
    />
    <path
      className="GitLab_Logo-20x20_svg__st1"
      d="M4 9.6C2.9 8.8 1.7 8.2.4 7.9V8c-.8 2.1-.1 4.5 1.7 5.8L6.2 17l3.8-2.9-6-4.5z"
    />
  </svg>
);

export default SvgGitLabLogo20X20;
