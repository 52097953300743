import { ITasks } from 'core/interfaces/ITask';
import {
  REQUEST_INCIDENT_DETAIL_TASK,
  REQUEST_INCIDENT_DETAIL_TASK_SUCCESS,
  REQUEST_INCIDENT_DETAIL_TASK_FAIL,
} from '../../const/organization';

const requestIncidentDeatilTask = (incidentId: string) => ({
  type: REQUEST_INCIDENT_DETAIL_TASK,
  payload: { incidentId },
});

const requestIncidentDeatilTaskSuccess = (taskList: ITasks | undefined) => ({
  type: REQUEST_INCIDENT_DETAIL_TASK_SUCCESS,
  payload: {
    taskList,
  },
});

const requestIncidentDeatilTaskFail = (error: any) => ({
  type: REQUEST_INCIDENT_DETAIL_TASK_FAIL,
  payload: {
    error,
  },
});

export {
  requestIncidentDeatilTask,
  requestIncidentDeatilTaskSuccess,
  requestIncidentDeatilTaskFail,
};
