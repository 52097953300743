import { Button } from '@chakra-ui/react';
import { Para } from 'uie/components';
import { getPaddedValue } from 'core/helpers/stringUtils';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { THEME_COLORS } from 'library/theme/colors';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useReminders } from './hook';

function ReminderComponent(props: { user: IUsersOfOrganization }) {
  const { user } = props;
  const { setReminderValeus, getRemiderTimer, updateStorage, sendReminderInProgress } =
    useReminders();
  const reminderValue = getRemiderTimer(user.id);
  const timestamp = reminderValue?.activeTimer;
  const [hasCountdownStarted, setHasCoundownStarted] = useState<boolean>(reminderValue ?? false);

  const [elapsedTime, setElapsedTime] = useState<Duration | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = moment();
      const elapsedTimeDuration = moment.duration(currentTime.diff(moment(timestamp)));
      setElapsedTime((59 - elapsedTimeDuration.seconds()) as unknown as Duration);
      if (moment.duration(elapsedTimeDuration).seconds() >= 59) {
        updateStorage();
        setHasCoundownStarted(false);
      }
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup function to clear the interval on unmount
  }, [timestamp]);

  const onInviteResend = async (userData: IUsersOfOrganization) => {
    const status = await setReminderValeus(userData);
    if (status) setHasCoundownStarted(true);
  };
  return (
    <Button
      variant="outline"
      cursor="pointer"
      onClick={() => onInviteResend(user)}
      size="sm"
      padding="6px 9px"
      borderColor={'#A5CBFA'}
      backgroundColor={'white'}
      disabled={hasCountdownStarted || sendReminderInProgress}
    >
      <Para fontSize={12} fontWeight={600} color={THEME_COLORS.brand.blue}>
        {hasCountdownStarted && elapsedTime
          ? `Try again in 00:${getPaddedValue(Number(elapsedTime))}`
          : 'Send Reminder'}
      </Para>
    </Button>
  );
}

export default React.memo(ReminderComponent);
