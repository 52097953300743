import { Divider as ChakraDivider } from '@chakra-ui/react';

type Props = {
  orientation?: 'horizontal' | 'vertical';
};

export const Divider = ({ orientation, ...rest }: Props) => {
  return (
    <ChakraDivider orientation={orientation ?? 'horizontal'} {...rest} borderBottomWidth={0} />
  );
};
