import { Box } from '@chakra-ui/react';
import { Loader } from 'library/molecules';

export const BoxLoader = () => {
  return (
    <Box width="full" height="full" bg="container.background">
      <Loader />
    </Box>
  );
};
