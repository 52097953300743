import { useQuery } from 'react-query';
import { workflowTagsApi, fetchManualWebhooksApi, workflowCountApi } from '.';

export const useWorkflowTagsQuery = () => {
  return useQuery('workflowTags', workflowTagsApi);
};

export const useWorkflowWebhook = () => {
  return useQuery('workflowWebHooks', fetchManualWebhooksApi);
};

export const useWorkflowCountQuery = () => {
  return useQuery('workflowCount', workflowCountApi, {
    retry: 1,
  });
};
