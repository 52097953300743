import { EventModal } from '..';
import React from 'react';
import { CheckBox, Row, Col, ErrorText } from '@squadcast/alchemy-ui/carbon';
import DatePicker from 'react-date-picker';
import cx from 'classnames';

function repitition(this: EventModal) {
  return (
    <div>
      <div>
        <CheckBox
          id="calendar_override"
          name="Override"
          label="calendar_override"
          checked={this.state.is_override}
          onChange={this.onSelectOverride}
        >
          <span>Is an Override</span>
        </CheckBox>
      </div>

      {!this.isSingleEventUpdate && (
        <>
          <div>
            <CheckBox
              id="calendar_repeats"
              name="Repeats"
              label="calendar_repeats"
              checked={this.state.repeats}
              onChange={this.onRepeatsChange}
            >
              <span>Repeats</span>
            </CheckBox>
          </div>

          <div className="w-1-1 mb-20">
            {this.state.repeats && (
              <div
                className="pa-10"
                style={{ border: '1px solid var(--shades-whiteSmoke)', borderRadius: 5 }}
              >
                <Row alignItems="center">
                  <input
                    id="rep_every"
                    type="radio"
                    className="cursor-pointer radio-design no-select no-select"
                    name="rep_every"
                    value="1"
                    checked={this.state.repetition_type === 'daily'}
                    onChange={this.onDailyRepetitionInputChange}
                    style={{ marginRight: 10, marginBottom: 10 }}
                  />
                  <label
                    htmlFor="rep_every"
                    className="cursor-pointer item-box-tagline cursor-pointer block"
                    style={{ position: 'relative', marginRight: 30, marginBottom: 10 }}
                  >
                    Every Day
                  </label>
                </Row>
                <Col>
                  <Row>
                    <input
                      id="rep_week_once"
                      type="radio"
                      className="cursor-pointer radio-design no-select no-select"
                      name="rep_week_once"
                      value="2"
                      checked={this.state.repetition_type === 'weekly_once'}
                      onChange={this.onWeeklyOnceRepetitionInputChange}
                      style={{ marginRight: 10, marginBottom: 10 }}
                    />

                    <label
                      htmlFor="rep_week_once"
                      className="cursor-pointer item-box-tagline cursor-pointer block"
                      style={{ position: 'relative', marginRight: 5, marginBottom: 10 }}
                    >
                      Weekly -- Once a week
                    </label>
                  </Row>
                  {this.state.repetition_type === 'weekly_once' && (
                    <Row justifyContent="space-between" flexWidth={8}>
                      {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((d, i) => {
                        return (
                          <div
                            key={i}
                            className={cx('week-day cursor-pointer', {
                              selected: this.state.frequency.every.weekdays.includes(i as any),
                            })}
                            style={{ marginBottom: 15 }}
                            onClick={this.onSingleWeekDayInputChange(i)}
                          >
                            <span>{d}</span>
                          </div>
                        );
                      })}
                    </Row>
                  )}
                </Col>
                {this.state.repetition_type === 'weekly_once' && !!this.state.errors.weekday && (
                  <ErrorText text={this.state.errors.weekday} />
                )}
                <Col>
                  <Row>
                    <input
                      id="weekly_custom"
                      type="radio"
                      className="cursor-pointer radio-design no-select no-select"
                      name="weekly_custom"
                      value="2"
                      checked={this.state.repetition_type === 'weekly_custom'}
                      onChange={this.onWeeklyParticularRepetitionInputChange}
                      style={{ marginRight: 10, marginBottom: 10 }}
                    />

                    <label
                      htmlFor="weekly_custom"
                      className="cursor-pointer item-box-tagline cursor-pointer block"
                      style={{ position: 'relative', marginRight: 5, marginBottom: 10 }}
                    >
                      Weekly -- Particular Days Of a Week
                    </label>
                  </Row>
                  {this.state.repetition_type === 'weekly_custom' && (
                    <Row justifyContent="space-between" flexWidth={8}>
                      {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((d, i) => {
                        return (
                          <div
                            key={i}
                            className={cx('week-day cursor-pointer', {
                              selected: this.state.frequency.every.weekdays.includes(i as any),
                            })}
                            style={{ marginBottom: 15 }}
                            onClick={this.onMultipleWeekdayChange(i)}
                          >
                            <span>{d}</span>
                          </div>
                        );
                      })}
                    </Row>
                  )}
                </Col>
                {this.state.repetition_type === 'weekly_custom' && !!this.state.errors.weekday && (
                  <ErrorText text={this.state.errors.weekday} />
                )}
                <Row flexWidth={12} justifyContent="flex-start" alignItems="center">
                  <input
                    id="custom_repition"
                    type="radio"
                    className="cursor-pointer radio-design no-select no-select"
                    name="custom_repitition"
                    value="1"
                    checked={this.state.repetition_type === 'custom'}
                    onChange={this.onCustomRepetitionInputChange}
                    style={{ marginRight: 10 }}
                  />
                  <label
                    htmlFor="custom_repition"
                    className="cursor-pointer item-box-tagline cursor-pointer block"
                    style={{ position: 'relative', marginRight: 25 }}
                  >
                    Custom
                  </label>
                  {this.state.repetition_type === 'custom' && (
                    <label
                      htmlFor="custom_repition"
                      className="cursor-pointer item-box-tagline cursor-pointer block"
                      style={{ marginRight: 10 }}
                    >
                      Every
                    </label>
                  )}
                  {this.state.repetition_type === 'custom' && (
                    <Row>
                      <input
                        className="input-design"
                        id="freq_every_occurrences"
                        name="freq_every_occurrences"
                        type="number"
                        style={{ marginLeft: 10, width: 80 }}
                        value={this.state.frequency.every.occurrence}
                        onChange={this.onFrequencyInputChange}
                      />
                      <select
                        className="select-design ml-10"
                        value={this.state.frequency.every.type}
                        onChange={this.onSelectCustomRepitionTypeChange}
                      >
                        {['days', 'weeks', 'months'].map((e: string) => {
                          return (
                            <option value={e.slice(0, e.length - 1)} key={e.slice(0, e.length - 1)}>
                              {e}
                            </option>
                          );
                        })}
                      </select>
                    </Row>
                  )}
                </Row>
                <br />
                {!!this.state.errors.repetitions && (
                  <ErrorText text={this.state.errors.repetitions} />
                )}
                <div className="mt-20">
                  <label className="item-box-tagline font-bold mb-10 block">Ends on</label>
                  {this.state.frequency.endsOn !== 'never' && (
                    <div className="mb-10">
                      <DatePicker
                        onChange={this.onRepitionEndsDatePickerChange}
                        value={this.state.frequency.endsOn}
                        calendarIcon={null}
                        clearIcon={null}
                        format="dd/MM/y"
                        minDate={new Date()}
                      />
                    </div>
                  )}
                  <div>
                    <CheckBox
                      id="calendar_repeats_ends_on"
                      name="calendar_repeats_ends_on"
                      label="calendar_repeats_ends_on"
                      checked={this.state.frequency.endsOn === 'never'}
                      onChange={this.onChangeFrequencyEndsOn}
                    >
                      <span>Never</span>
                    </CheckBox>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default repitition;
