import React from 'react';
import { AppFrame, Grid, Heading, Para, SpinLoader, Theme } from 'uie/components';
import {
  IAnalyticsData,
  IAnalyticsPanel,
  IAnalyticsPanelRow,
} from '../../../../core/interfaces/IAnalytics';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import './index.css';
import AnalyticsGrid from './grid';
import AnalyticsProvider, { AnalyticsContext } from './analyticsContext';
import { debouncedUpdateAnalyticsCache, getAnalyticsCache } from './helpers';

import TimeFrame from './components/timeRange';
import ContextWrapper from './contextWrapper';
import { AnalyticsService } from '../../../../core/services';
import { exception } from '../../../../core/exception';
import { DefaultAnalyticsPanel } from '../../../../core/const/analytics';
import { AppTracker } from '../../../../shared/analytics/tracker';
import { T_WA_GS_ANALYTICS_PAGE_VIEWED } from '../../../../core/const/tracker';
import { checkIfKeyChanged } from 'core';
import { connect } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';

const { theme } = Theme;

interface IProps extends Pick<IAppState, 'organization' | 'integrations'> {}

interface IState {
  panels: (IAnalyticsPanel | IAnalyticsPanelRow)[];
  data: IAnalyticsData;
  loading: boolean;
  lastUpdated: Date | null;
}

class Analytics extends React.Component<IProps, IState> {
  private _analyticsService = new AnalyticsService();

  static contextType = AnalyticsContext;

  state = {
    panels: [],
    data: {},
    loading: false,
    lastUpdated: null,
  };

  componentDidMount() {
    this.setState({
      panels: this.setTeamIDToPanels(DefaultAnalyticsPanel),
    });

    this.getDataFromCache();
    this.getLastUpdatedDate();
    AppTracker.track(T_WA_GS_ANALYTICS_PAGE_VIEWED);
  }

  setTeamIDToPanels = (panels: (IAnalyticsPanel | IAnalyticsPanelRow)[]) => {
    return panels.map(panel => {
      if (panel.panelType !== 'row') {
        return {
          ...panel,
          query: {
            ...panel.query,
            filter_by: {
              ...panel.query.filter_by,
              owner: this.props.organization.selectedTeam.teamId,
            },
          },
        };
      }

      return panel;
    }) as (IAnalyticsPanel | IAnalyticsPanelRow)[];
  };

  componentDidUpdate(prevProps: IProps) {
    if (
      checkIfKeyChanged(
        prevProps.organization.selectedTeam,
        this.props.organization.selectedTeam,
        'refresh',
      )
    ) {
      this.setState({
        panels: this.setTeamIDToPanels(DefaultAnalyticsPanel),
      });
    }

    if (
      prevProps.organization.services.s.length !== this.props.organization.services.s.length ||
      prevProps.integrations.i.length !== this.props.integrations.i.length
    ) {
      const { updateMaps } = this.context;
      updateMaps();
    }
  }

  getLastUpdatedDate = async () => {
    try {
      const {
        data: { data },
      } = await this._analyticsService.getLogs();
      this.setState({ lastUpdated: new Date(data.end_time) });
    } catch (err: any) {
      exception.handle('E_GET_ANALYTICS_LOGS', err);
    }
  };

  getDataFromCache = async () => {
    this.setState({ loading: true });

    const data = await getAnalyticsCache();

    if (data) this.setState({ data, loading: false });
    else this.setState({ loading: false });
  };

  updatePanelData = (
    panelID: string,
    data: any[],
    owner?: string,
    dateString?: string,
    timeRange?: { gt: string; lt: string },
  ) => {
    const newData: IAnalyticsData = { ...this.state.data };
    newData[panelID] = {
      data,
      updatedAt: new Date(),
      owner,
    };

    if (dateString) newData[panelID].dateString = dateString;
    if (timeRange) newData[panelID].timeRange = timeRange;

    this.setState({ data: newData });

    debouncedUpdateAnalyticsCache(newData);
  };

  render() {
    return (
      <AppFrame>
        <Grid style={{ minHeight: '90vh' }} type="column">
          <Grid justifyContent="space-between">
            <Heading fontSize={28} style={{ marginLeft: '8px', padding: 16 }}>
              Analytics
            </Heading>
            {!this.state.loading && (
              <Grid style={{ margin: '0 24px' }}>
                {this.state.lastUpdated && (
                  <Para fontSize={14} style={{ margin: '36px 24px', color: theme.shades.grey }}>
                    Data was last updated on{' '}
                    <span className="font-bold">
                      {(this.state.lastUpdated as unknown as Date).toLocaleString()}
                    </span>
                  </Para>
                )}
                <TimeFrame />
              </Grid>
            )}
          </Grid>

          <ContextWrapper>
            {this.state.loading ? (
              <Grid justifyContent="space-around" style={{ marginTop: '120px' }}>
                <SpinLoader />
              </Grid>
            ) : (
              <AnalyticsGrid
                panels={this.state.panels}
                data={this.state.data}
                updatePanelData={this.updatePanelData}
              />
            )}
          </ContextWrapper>
        </Grid>
      </AppFrame>
    );
  }
}

const AnalyticsComponent = connect((state: IAppState) => ({
  organization: state.organization,
  integrations: state.integrations,
}))(Analytics);

const AnalyticsHOC = (props: any) => {
  return (
    <AnalyticsProvider>
      <AnalyticsComponent
        {...{
          ...props,
        }}
      />
    </AnalyticsProvider>
  );
};

export default AnalyticsHOC;
