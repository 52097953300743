import { useState } from 'react';
import { DropDown, FocusBlock, Grid, Label, Para, SpinLoader, Theme } from 'uie/components';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService, downloadFile, exception, IncidentService } from 'core';
import { CrownIcon, DownloadIcon } from 'icons';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_GS_TIMELINE_DOWNLOADED } from 'core/const/tracker';
import moment from 'moment';
import { IProps } from '../activity';
import { createStandaloneToast } from '@chakra-ui/toast';

const { theme } = Theme;

const ExportTimeline = (data: IProps) => {
  const [loading, setLoading] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const toast = createStandaloneToast();

  const isOnTrial = BillingService.isSomeProductTrialPlan(data);
  const isOnPro = BillingService.isFeatureDisabled(data, 'export-incident-timeline') || isOnTrial;

  const showToast = (msg: string) => toast({ title: msg, status: 'error', isClosable: true });

  const onDownloadReport = (type: 'pdf' | 'csv') => async () => {
    if (isOnPro && !isOnTrial) {
      setShowUpgradeModal(true);
      return;
    }
    setLoading(!loading);
    const _incidentService = new IncidentService();
    AppTracker.track(T_WA_GS_TIMELINE_DOWNLOADED, { 'Timeline Format': type, Platform: 'Web' });
    try {
      const response = await _incidentService.exportTimeline(type, data.incident.id);
      const fileName = `${moment(new Date()).format('DD-MM-YYYY-HH:mm')}-${
        data.selectedOrg.organizationId
      }-${data.incident.id}-report.${type}`;
      const fileType = type === 'csv' ? '.csv' : 'application/pdf';
      let report;

      if (type === 'pdf') {
        const res = await fetch(`data:application/pdf;base64,${response.data}`);
        const buf = await res.arrayBuffer();
        report = new File([buf], fileName, { type: 'application/pdf' });
      } else {
        report =
          typeof File === 'function'
            ? new File([response.data.toString()], fileName, { type: fileType })
            : new Blob([response.data.toString()], { type: fileType });
      }
      downloadFile(report, fileName);
    } catch (err: any) {
      if (err?.response?.status === 402) {
        showToast(err?.response?.data?.meta?.error_details?.description ?? 'Network Error');
      } else {
        showToast(err?.response?.data?.meta?.error_message ?? 'Network Error');
        exception.handle('E_DOWNLOAD_TIMELINE_INCIDENT', err);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <Grid type="row" justifyContent="space-between" className="mb-10">
      <Label fontSize={14} color={theme.shades.black} fontWeight={500}>
        Activity Timeline
      </Label>
      {loading ? (
        <SpinLoader base="6px" />
      ) : (
        <DropDown
          offset="-150px"
          hook={
            <div className="cursor-pointer">
              <DownloadIcon
                className="cursor-pointer"
                height={16}
                width={16}
                fill={theme.shades.black}
              />
              {isOnPro ? (
                <CrownIcon style={{ position: 'relative', top: '6px', left: '-3px' }} />
              ) : null}
            </div>
          }
          width="170px"
          maxWidth="200px"
          height="110px"
          padding="0px"
          maxHeight="150px"
        >
          <Grid type="column">
            <Label fontSize={14} fontWeight={500} style={{ padding: '8px 16px' }}>
              Export timeline as
            </Label>
            {['pdf', 'csv'].map((type, index) => {
              return (
                <FocusBlock key={index} value={index} onSelectValue={onDownloadReport(type as any)}>
                  <Grid className="float-left">
                    <Para
                      fontSize={14}
                      style={{ textTransform: 'uppercase' }}
                      className="item-box-tagline ma-0"
                    >
                      {type}
                    </Para>
                  </Grid>
                </FocusBlock>
              );
            })}
          </Grid>
        </DropDown>
      )}
      <UpgradePlanModal
        hasBillingPermission={BillingService.hasManageBillingPermission(data)}
        showModal={showUpgradeModal}
        message={BillingService.getMessage(0, 'export-incident-timeline', data)}
        onCancel={() => {
          setShowUpgradeModal(false);
        }}
        header={BillingService.getHeader(0, 'export-incident-timeline', data)}
        onUpgrade={() => setShowUpgradeModal(false)}
      />
    </Grid>
  );
};

export default ExportTimeline;
