import * as React from 'react';
import { SVGProps } from 'react';

const SvgAwsElasticBeanstalkLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <linearGradient
      id="AWS_ElasticBeanstalk_Logo_svg__a"
      gradientUnits="userSpaceOnUse"
      x1={-263.083}
      y1={396.167}
      x2={-261.417}
      y2={397.833}
      gradientTransform="matrix(12 0 0 -12 3157 4774)"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#c8511b',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#f90',
        }}
      />
    </linearGradient>
    <path
      d="M0 0h20v20H0V0z"
      style={{
        fill: 'url(#AWS_ElasticBeanstalk_Logo_svg__a)',
      }}
    />
    <path
      d="M12.6 11.5c0-.4-.3-.8-.7-.8-.4 0-.7.3-.7.8 0 .4.3.8.7.8.4-.1.7-.4.7-.8M9.9 9c.4 0 .7-.3.7-.8 0-.4-.3-.8-.7-.8-.4 0-.7.3-.7.8-.1.4.3.8.7.8m-2.3 2.7c.4 0 .7-.3.7-.8 0-.4-.3-.8-.7-.8-.4 0-.7.3-.7.8s.3.8.7.8m5.5-.2c0 .6-.4 1.1-1 1.2v1.5c0 .1-.1.3-.2.3h-1.7V16h-.6v-2.8h-2c-.1 0-.2-.1-.2-.3v-.8c-.6-.1-1-.6-1-1.2 0-.7.6-1.3 1.2-1.3.7 0 1.2.6 1.2 1.3 0 .6-.4 1.1-1 1.2v.5h1.7V9.5c-.6-.1-1-.6-1-1.2.1-.8.7-1.3 1.4-1.3s1.2.6 1.2 1.3c0 .6-.4 1.1-1 1.2V14h1.5v-1.3c-.6-.1-1-.6-1-1.2 0-.7.6-1.3 1.2-1.3.8 0 1.3.6 1.3 1.3m3.7-1.2c0 1.8-1.4 2.5-2.6 2.5v-.5c.6 0 2.1-.2 2.1-2 0-1.3-1-1.8-1.9-2-.1 0-.2-.1-.2-.2-.1-1.1-.7-1.5-1.2-1.5-.3 0-.7.2-.9.4-.1.1-.1.1-.2.1s-.2-.1-.2-.2c-.2-.5-.5-1-.8-1.3-1-.8-2.2-1.1-3.4-.6-1 .4-1.8 1.6-1.8 2.7v.4c0 .1-.1.2-.2.3-.5 0-1.5.5-1.5 1.8v.1c.1 1 1 1.9 2.1 1.9h.3v.5h-.3c-1.4 0-2.5-1-2.6-2.3v-.2c0-1.6 1.1-2.2 1.7-2.4v-.2c0-1.3.9-2.6 2.1-3.1 1.4-.6 2.8-.3 3.9.8.3.3.6.7.8 1.1.3-.2.6-.4 1-.4.7 0 1.5.5 1.7 1.7 1.3.4 2.1 1.3 2.1 2.6"
      style={{
        fill: '#fff',
      }}
    />
  </svg>
);

export default SvgAwsElasticBeanstalkLogo;
