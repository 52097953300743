import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M9.34 7.852c-.83 1.393-1.624 2.743-2.434 4.082-.209.344-.312.624-.145 1.061.459 1.207-.189 2.382-1.406 2.701-1.149.301-2.267-.454-2.495-1.683-.202-1.088.642-2.155 1.842-2.325.1-.014.203-.016.372-.028L6.9 8.6c-1.148-1.142-1.83-2.476-1.68-4.129.107-1.168.567-2.178 1.407-3.006A4.774 4.774 0 0 1 12.582.84c1.82 1.169 2.652 3.445 1.942 5.393l-1.667-.452c.223-1.083.058-2.056-.673-2.89-.482-.55-1.102-.838-1.806-.944-1.412-.213-2.798.694-3.21 2.08-.466 1.572.24 2.857 2.171 3.825Z"
        fill="#C73A63"
      />
      <path
        d="m11.706 6.205 1.765 3.112c2.97-.92 5.21.725 6.013 2.486.97 2.126.307 4.645-1.599 5.956-1.956 1.347-4.43 1.117-6.163-.613l1.36-1.138c1.712 1.109 3.21 1.057 4.32-.256a2.98 2.98 0 0 0-.047-3.887c-1.126-1.265-2.634-1.304-4.456-.09-.756-1.34-1.525-2.671-2.257-4.022-.247-.455-.52-.72-1.076-.816-.93-.16-1.53-.959-1.565-1.853A2.036 2.036 0 0 1 9.3 3.09a2.027 2.027 0 0 1 2.294.626c.444.56.585 1.19.351 1.881-.065.193-.149.38-.24.608Z"
        fill="#4B4B4B"
      />
      <path
        d="M13.102 14.626H9.526c-.343 1.41-1.083 2.547-2.358 3.271a4.769 4.769 0 0 1-3.198.57C1.875 18.129.163 16.244.012 14.12c-.17-2.405 1.483-4.543 3.686-5.023l.458 1.66C2.134 11.79 1.435 13.09 2 14.716c.498 1.43 1.913 2.214 3.45 1.91 1.568-.309 2.359-1.613 2.263-3.705 1.487 0 2.975-.015 4.462.008.581.009 1.03-.052 1.467-.564.72-.842 2.046-.766 2.822.03a2.026 2.026 0 0 1-.084 2.9 2.023 2.023 0 0 1-2.846-.098c-.155-.167-.278-.365-.432-.57Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v18.672H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
