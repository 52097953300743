import * as React from 'react';
import { SVGProps } from 'react';

const SvgBitbucketLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: 'none',
      }}
      d="M7.9 12.9h4.2l1-5.8H6.8z"
    />
    <path
      d="M.6 1c-.3 0-.6.3-.6.6v.1l2.7 16.5c.1.5.5.8.9.8h13c.3 0 .6-.2.6-.5L20 1.8c0-.4-.2-.7-.5-.8H.6zm11.5 11.9H7.9L6.8 7.1h6.3l-1 5.8z"
      style={{
        fill: '#2684ff',
      }}
    />
    <linearGradient
      id="Bitbucket_Logo-20x20_svg__a"
      gradientUnits="userSpaceOnUse"
      x1={20.52}
      y1={14.026}
      x2={10.581}
      y2={6.269}
      gradientTransform="matrix(1 0 0 -1 0 22.73)"
    >
      <stop
        offset={0.18}
        style={{
          stopColor: '#0052cc',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#2684ff',
        }}
      />
    </linearGradient>
    <path
      d="M19.1 7.1h-6l-1 5.9H7.9L3 18.8c.2.1.4.2.6.2h13.1c.3 0 .6-.2.6-.5l1.8-11.4z"
      style={{
        fill: 'url(#Bitbucket_Logo-20x20_svg__a)',
      }}
    />
  </svg>
);

export default SvgBitbucketLogo20X20;
