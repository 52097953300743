import { ServiceNowSvc, exception } from 'core';
import { T_WA_GS_INCIDENT_ACTIONS_SERVICENOW } from 'core/const/tracker';
import { IIncidentServiceNowMapping, IServiceNowResponse } from 'core/interfaces/IExtensions';
import { useEffect, useMemo, useState } from 'react';
import { AppTracker } from 'shared/analytics/tracker';
import { useGetServiceNowConfig } from 'views/main/organization/settings/integrations/servicenow/hooks/getServiceNowConfig';
import { useCreateServiceNowIncident } from './createServiceNowIncident';
import { ServiceNowTriggerProps } from '.';
import { useQuery } from 'react-query';

export function useServiceNow(props: ServiceNowTriggerProps) {
  const _servicenowSvc = new ServiceNowSvc();
  const [successfullyCreated, setSuccessfullyCreated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [incidentMapping, setIncidentMapping] = useState<IIncidentServiceNowMapping | null>(null);

  const config = useGetServiceNowConfig();

  const mappingQuery = useQuery(
    'servicenow-incident-mapping',
    () => _servicenowSvc.getIncidentMapping(props.incident.id),
    {
      enabled: false,
      onSuccess: data => {
        setIncidentMapping(data?.data?.data);
      },
      onError: (error: any) => {
        const description: string =
          error?.response?.data?.meta?.error_message || 'error connecting to server';
        setErrorMessage(description);
      },
    },
  );

  const createServiceNowIncidentMutation = useCreateServiceNowIncident({
    onSuccess: () => {
      setSuccessfullyCreated(true);
      AppTracker.track(T_WA_GS_INCIDENT_ACTIONS_SERVICENOW, {
        'Incident ID': props.incident.id,
      });
      props.getIncidentDetails();
      props.getIncidentMapping();
    },
    onError: async (err: any) => {
      setSuccessfullyCreated(false);
      const msg = exception.getErrorMsg(err);
      if (msg.includes('incident already created in servicenow')) {
        mappingQuery.refetch();
      } else {
        setErrorMessage(msg);
      }
    },
  });

  const createIncident = async (config: IServiceNowResponse | null) => {
    if (!config?.is_enabled || config?.sync_mode === 'auto') return;
    createServiceNowIncidentMutation.mutate(props.incident.id);
  };

  useEffect(() => {
    if (config.data) {
      let errorMessage = '';

      if (!config.data?.is_enabled) errorMessage = 'Please enable ServiceNow integration';
      else if (config.data?.sync_mode === 'auto')
        errorMessage = 'Please configure ServiceNow integration to manual mode';

      setErrorMessage(errorMessage);
    }
    if (config.isError) {
      const description: string =
        config.error?.response?.data?.meta?.error_message || 'error connecting to server';
      setErrorMessage(description);
    }
  }, [config.data, config.isError]);

  const loading: 'config' | 'incident' | undefined = useMemo(() => {
    if (config.isLoading) return 'config' as const;
    if (
      createServiceNowIncidentMutation.isLoading ||
      mappingQuery.isLoading ||
      mappingQuery.isRefetching
    )
      return 'incident' as const;
  }, [
    config.isLoading,
    createServiceNowIncidentMutation.isLoading,
    mappingQuery.isLoading,
    mappingQuery.isRefetching,
  ]);

  return {
    loading,
    errorMessage,
    config: config.data,
    createIncident,
    successfullyCreated,
    incidentMapping,
  };
}
