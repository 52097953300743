import { TagsMapType } from 'core/hooks/useGetAllTags';
import { matchSorter } from 'match-sorter';
import React, { CSSProperties, Fragment, useEffect, useMemo, useState } from 'react';

import {
  CardBlock,
  FormBlock,
  Grid,
  InputBlock,
  Label,
  Para,
  TextButton,
  Theme,
} from 'uie/components';

import { SecondaryFiltersKeys, SelectOption } from '../../types';
import { FilterItem, FilterItemList } from './dropdown_container';
import FocusedSearchBox from './search';

type Props = {
  selected: string[];
  serviceId?: string;
  teamId?: string;
  tags: TagsMapType;
  filteredTerm?: string;
  updateFilter: (type: SecondaryFiltersKeys, data: string[]) => void;
};

type TagMode = 'key' | 'value';

const noneItem = { label: 'None', value: '' };
const customTagItem = { label: 'Custom Tag', value: 'create-custom-tag' };
const { theme } = Theme;

const Tags: React.FC<Props> = ({ selected, filteredTerm, tags, updateFilter }) => {
  const [mode, setMode] = React.useState<'key' | 'value'>('key');

  const [keyOptions, setKeyOptions] = React.useState<SelectOption[]>([]);
  const [keyValueOptions, setKeyValueOptions] = React.useState<SelectOption[]>([]);

  const [selectedKey, setSelectedKey] = React.useState<string>('');
  const [searchValue, setSearchValue] = React.useState('');

  const customSelectedTag = useMemo(() => {
    if (selected.length) {
      const [selectedTag] = selected;

      const definedTagKey = tags.keys.find(tag => selectedTag.startsWith(tag));

      if (definedTagKey) {
        const definedTagValue = tags.keys.find(tag => selectedTag.startsWith(tag));
        if (definedTagValue) {
          return null;
        }
      }

      const [customTagKey, customTagValue] = selectedTag?.split(':');
      return { label: customTagKey, value: customTagValue } as SelectOption;
    }
    return null;
  }, [selected, tags.keys]);

  const onTagSelect = (tagOption: SelectOption, mode: TagMode) => {
    if (tagOption.value) {
      if (mode === 'key') {
        setSelectedKey(tagOption.label);
        setMode('value');
      } else if (mode === 'value' && selectedKey === customTagItem.label) {
        updateFilter('tags', [tagOption.value]);
      } else {
        const tag = `${selectedKey}:${tagOption.label}`;

        if (!selected.includes(tag)) {
          updateFilter('tags', [tag]);
        }

        setMode('key');
      }
    } else {
      setSelectedKey('');
      setMode('key');
      updateFilter('tags', []);
    }
  };

  const checkTagSelected = (tag: SelectOption, mode: TagMode) => {
    if (selected.length || selectedKey) {
      if (mode === 'key') {
        return selectedKey ? selectedKey === tag.label : selected[0]?.startsWith(tag.label);
      } else if (mode === 'value') {
        return selected[0]?.endsWith(tag.label);
      }
      return false;
    }

    // For None selected
    if (!selected.length && !tag.value) {
      return true;
    }

    // For Custom Tag option
    if (tag.value === customTagItem.value && customSelectedTag) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (mode === 'key') {
      const filteredKeys = matchSorter(tags.keys, filteredTerm || '');
      setKeyOptions(filteredKeys.map(key => ({ label: key, value: key })));
    } else if (mode === 'value' && selectedKey) {
      if (tags.valuesMap[selectedKey]) {
        const filteredValues = matchSorter(tags.valuesMap[selectedKey], searchValue || '');
        setKeyValueOptions(filteredValues.map(value => ({ label: value, value: value })));
      } else {
        setKeyValueOptions([]);
      }
    }
  }, [tags, mode, selectedKey, filteredTerm, searchValue]);

  return (
    <Grid style={{ overflowY: 'auto' }}>
      <Grid style={{ flexDirection: 'column', rowGap: '5px', width: '100%' }}>
        <FilterItem
          item={noneItem}
          checkItemSelected={tag => checkTagSelected(tag, 'key')}
          onItemSelect={tag => onTagSelect(tag, 'key')}
        />
        <FilterItem
          item={customTagItem}
          checkItemSelected={tag => checkTagSelected(tag, 'key')}
          onItemSelect={tag => onTagSelect(tag, 'key')}
          hasExpandIcon
        />
        <FilterItemList
          itemList={keyOptions}
          checkItemSelected={tag => checkTagSelected(tag, 'key')}
          onItemSelect={tag => onTagSelect(tag, 'key')}
          hasExpandIcon
        />
      </Grid>

      {selectedKey && (
        <CardBlock
          borderStyle="sharp"
          style={{
            position: 'absolute',
            left: 'calc(100%)',
            backgroundColor: theme.shades.white,
            boxShadow: `${theme.font.disabled} 0px 4px 11px`,
            border: `1px solid ${theme.primary.default}`,
            borderLeft: 'none',
            borderRadius: '3px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            padding: 0,
            minWidth: '120px',
          }}
        >
          <Grid>
            <Grid
              flexWidth={12}
              style={{
                flexDirection: 'column',
                padding: '14px',
              }}
            >
              {selectedKey === customTagItem.label ? (
                <CustomTagForm
                  onTagSave={tag => onTagSelect(tag, 'value')}
                  customTag={customSelectedTag}
                />
              ) : (
                <Fragment>
                  <Para fontSize={14} fontWeight={700} style={{ marginBottom: 16 }}>
                    {selectedKey}
                  </Para>
                  <FocusedSearchBox
                    searchTerm={searchValue}
                    onSearch={searchValue => setSearchValue(searchValue)}
                    placeholder="Search for values"
                  />
                  <FilterItemList
                    itemList={keyValueOptions}
                    checkItemSelected={tag => checkTagSelected(tag, 'value')}
                    onItemSelect={tag => onTagSelect(tag, 'value')}
                    hideNoneItem
                  />
                </Fragment>
              )}
            </Grid>
          </Grid>
        </CardBlock>
      )}
    </Grid>
  );
};

export default Tags;

interface CustomTagFormProps {
  onTagSave: (tag: SelectOption) => void;
  customTag: SelectOption | null;
}

const labelStyles: CSSProperties = {
  fontSize: 14,
  fontWeight: 700,
  padding: 8,
  color: theme.shades.black,
};

const inputStyles: CSSProperties = {
  fontSize: 14,
  padding: 8,
  height: 'auto',
  marginTop: 8,
  width: 'auto',
};

const CustomTagForm = ({ onTagSave, customTag }: CustomTagFormProps) => {
  const [tag, setTag] = useState<{ key: string; value: string }>({
    key: customTag?.label ?? '',
    value: customTag?.value ?? '',
  });
  const [formActive, setFormActive] = useState(false);

  const onSubmit = () => {
    const { key, value } = tag;
    if (key && value) {
      onTagSave({ label: `${key}:${value}`, value: `${key}:${value}` });
    }
  };

  const initTagEdit = () => {
    setTag({ key: '', value: '' });
    setFormActive(true);
  };

  useEffect(() => {
    if (customTag) {
      setFormActive(false);
    } else {
      setFormActive(true);
    }
  }, [customTag]);

  const ObjectEntries = <Obj extends Record<string, any>>(
    obj: Obj,
  ): Array<[keyof Obj, Obj[keyof Obj]]> => {
    return Object.entries(obj) as Array<[keyof Obj, Obj[keyof Obj]]>;
  };

  return formActive ? (
    <FormBlock onFormSubmit={onSubmit}>
      <Grid
        style={{
          display: 'flex',
          gap: '20px',
        }}
      >
        {ObjectEntries(tag).map(([title, value]) => {
          return (
            <Grid key={title} style={{ flexDirection: 'column' }}>
              <Label style={labelStyles}>{title}</Label>
              <InputBlock
                name={title}
                style={inputStyles}
                value={value}
                onChange={e => setTag(prev => ({ ...prev, [title]: e.target.value }))}
                placeholder={`Type ${title} here`}
              />
              <br />
            </Grid>
          );
        })}
      </Grid>
      <TextButton
        type="submit"
        style={{
          backgroundColor: 'transparent',
          whiteSpace: 'nowrap',
        }}
        onClick={onSubmit}
      >
        <Para fontSize={14} fontWeight={400} color={theme.primary.default}>
          Add Tag
        </Para>
      </TextButton>
    </FormBlock>
  ) : (
    <Fragment>
      <Para
        fontSize={14}
        fontWeight={400}
        color={theme.shades.black}
        style={{ padding: 8, whiteSpace: 'nowrap' }}
      >
        {`${customTag?.label}: ${customTag?.value}`}
      </Para>
      <br />
      <TextButton
        type="submit"
        style={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}
        onClick={initTagEdit}
      >
        <Para fontSize={14} fontWeight={400} color={theme.primary.default}>
          Update Tag
        </Para>
      </TextButton>
    </Fragment>
  );
};
