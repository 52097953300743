import { Circle, SquareProps } from '@chakra-ui/react';
import * as React from 'react';

interface RadioCircleProps extends SquareProps {
  isStatusActive: boolean;
}

export const StepCircle = (props: RadioCircleProps) => {
  const { isStatusActive } = props;
  return (
    <Circle
      size="4"
      bg={isStatusActive ? 'accent' : 'inherit'}
      borderWidth={isStatusActive ? '0' : '2px'}
      borderColor={isStatusActive ? 'accent' : 'inherit'}
      {...props}
    >
      <Circle bg={isStatusActive ? 'rgba(27, 127, 241, 1)' : 'border'} size="4" />
    </Circle>
  );
};
