import { Box, Text, Flex } from '@chakra-ui/layout';
import { Ellipsis } from 'views/main/organization/schedules/schedules.view/schedules.week/table.rotation/Elipsis';
import EventCard from './event-card';
import { Participants } from './participants';
import { TimeDisplay } from './time-display';
import { OnCallCoverage } from './types';
import { parseDateTimeInTimezone } from './utils';

export const OverridesCard = ({
  overrides,
  timeZone,
}: {
  overrides: OnCallCoverage['overrides'];
  timeZone: string;
}) => {
  if (!overrides) {
    return null;
  }

  if (!overrides.length) {
    return null;
  }

  return (
    <EventCard>
      {overrides.map(override => {
        if (!override) {
          return null;
        }

        const { startDateTime, endDateTime } = parseDateTimeInTimezone({
          startTime: override.startTime,
          endTime: override.endTime,
          timeZone,
          useTimeZone: true,
        });

        return (
          <EventCard.Wrapper key={override.ID}>
            <Text fontSize={12} fontWeight="bold" color="#FFFFFF" lineHeight="150%">
              Override
            </Text>
            <EventCard.Row title="Repeats : " content="None" isBgDark />
            <EventCard.Row
              title="Start & End Date : "
              content={`${startDateTime.toFormat('MMM dd, yyyy')} - ${
                endDateTime?.toFormat('MMM dd, yyyy') ?? 'Forever'
              }`}
              isBgDark
            />
            <EventCard.Row
              title="Start & End Time : "
              isBgDark
              content={
                <TimeDisplay
                  data={
                    {
                      startDate: override.startTime,
                      endDate: override.endTime,
                    } as any
                  }
                  timeZone={timeZone}
                  dateTimeColor={'#FFFFFF'}
                  useTimeZone
                />
              }
            />
            <EventCard.Row
              additionTitleProps={{ minWidth: '96px' }}
              additionalContentProps={{ marginTop: '-2px' }}
              alignItems="flex-start"
              title="Participants : "
              isBgDark
              content={
                <Participants
                  textColor={'#627C98'}
                  participants={override.overrideWith?.participants as any}
                />
              }
            />
            <Flex my={'2.5'} mb="0" alignItems="flex-start" pt="1.5" borderTop="1px solid #FFF">
              <Text fontSize={12} fontWeight="bold" textTransform="uppercase" color="#FFFFFF">
                ORIGINALLY <br /> ON-CALL :{' '}
              </Text>
              <Text fontSize={10} color="#FFFFFF" marginTop="2px" fontWeight="700">
                <Participants
                  participants={override.overriddenParticipant?.participants as any}
                  textColor={'#627C98'}
                />
              </Text>
            </Flex>
            <EventCard.Row
              isBgDark
              additionTitleProps={{ minWidth: '60px' }}
              title="Reason : "
              content={<Ellipsis text={override.reason ?? ''} maxLength={100} />}
              additionalContentProps={{ marginTop: '1px' }}
              alignItems="flex-start"
            />
          </EventCard.Wrapper>
        );
      })}
    </EventCard>
  );
};
