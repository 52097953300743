import { ChakraProvider, useBoolean } from '@chakra-ui/react';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { API, BillingService } from 'core';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { Layout } from 'library/molecules';
import LibraryTheme from 'library/theme';
import { createContext, memo, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import {
  GER_CREATE_PATH,
  GER_DETAILS_PATH,
  GER_EDIT_PATH,
  GER_PATH,
} from 'views/main/routes/routes';
import { ErrorBoundary } from 'views/shared/components';

import { IAppState } from '../../../../core/interfaces/IAppState';
import { GERError } from './components';
import { GERCreate } from './create';
import { GERDetails } from './detail';
import { GERHome } from './home';
import { ActionKind, GERContext, INITIAL_GER_STATE } from './store';
import { GERReducer } from './store/reducer';
import { GER_ERROR } from './util';

export type IMix = Pick<IAppState, 'organization' | 'integrations'>;

export const AppContext = createContext<IMix | null>(null);

const GERComponent = (props: IMix) => {
  const [state, dispatch] = useReducer(GERReducer, INITIAL_GER_STATE);
  const userAccess = useUserAccess();
  const history = useHistory();

  useEffect(() => {
    const hasCreate = userAccess.hasCreateAccess('ger');
    const hasRead = userAccess.hasReadAccess('ger');
    const hasUpdate = userAccess.hasUpdateAccess('ger');
    const hasDelete = userAccess.hasDeleteAccess('ger');
    dispatch({
      type: ActionKind.SET_PERMISSIONS,
      payload: {
        permissions: {
          canCreate: hasCreate,
          canRead: hasRead,
          canUpdate: hasUpdate,
          canDelete: hasDelete,
        },
      },
    });
  }, [API.config.teamId]);

  return (
    <ChakraProvider theme={LibraryTheme}>
      <Layout>
        <ErrorBoundary>
          {state.permissions.canRead ? (
            <AppContext.Provider value={props}>
              <GERContext.Provider value={{ state, dispatch }}>
                <Switch>
                  <Route exact path={GER_PATH} component={GERHome} />
                  <Route exact path={GER_CREATE_PATH} component={GERCreate} />
                  <Route exact path={GER_EDIT_PATH} component={GERCreate} />
                  <Route exact path={GER_DETAILS_PATH} component={GERDetails} />
                </Switch>
              </GERContext.Provider>
            </AppContext.Provider>
          ) : (
            <GERError error={GER_ERROR.NO_READ_PERMISSION} />
          )}
        </ErrorBoundary>
      </Layout>
    </ChakraProvider>
  );
};

export default connect(({ organization, integrations }: IAppState) => ({
  organization,
  integrations,
}))(memo(GERComponent));
