import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const TableTheme: ComponentStyleConfig = {
  baseStyle: props => {
    const { headerStyles, bodyStyles, theme } = props;

    return {
      table: {
        width: '100%',
        fontFamily: 'Mulish',
        '& *': {
          fontFamily: 'Mulish',
        },
        '& tr': {
          _hover: {
            'a > p': {
              color: 'brand.blue',
            },
          },
        },
      },
      bodyCell: {
        fontWeight: 400,
        padding: 0,
        margin: 0,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'secondary.200',
        color: 'secondary.1000',
        boxShadow: 'none',
        verticalAlign: 'top',
        ...bodyStyles,
        paddingY: '6px !important',
      },
      header: {
        bg: 'secondary.150',
        margin: 0,
        position: 'sticky',
        zIndex: 1,
        top: '0px',
        padding: 0,
        fontFamily: 'Mulish',
        fontWeight: '800',
        lineHeight: '9px',
        letterSpacing: 'wider',
        color: 'secondary.700',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'secondary.200',
        textTransform: 'uppercase',
        '&:first-child': {
          bg: `linear-gradient(90deg, ${theme.colors['secondary']['200']} 8.33%, ${theme.colors['secondary']['150']} 17.4%)`,
        },
        '&:last-child': {
          bg: `linear-gradient(90deg, ${theme.colors['secondary']['150']} 87.11%, ${theme.colors['secondary']['200']} 100%)`,
        },
        ...headerStyles,
      },
    };
  },
  sizes: {
    md: () => {
      return {
        bodyCell: {
          fontSize: '12.5px',
          lineHeight: '19px !important',
        },
        header: {
          fontSize: '10.5px',
        },
      };
    },
    lg: () => {
      return {
        bodyCell: {
          fontSize: '17px',
          lineHeight: '25px !important',
        },
        header: {
          fontSize: '14.5px',
        },
      };
    },
  },
  defaultProps: {
    size: 'md',
    variant: null,
  },
};
