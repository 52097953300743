import {
  ariaKeyDown,
  KEY_TAB,
  KEY_ESC,
  KEY_ENTER,
  KEY_UP,
  KEY_DOWN,
  KEY_SPACE,
} from './keyBindings';

interface IProps {
  onTab?: () => void;
  onEsc?: () => void;
  onSpace?: () => void;
  onEnter?: () => void;
  onKeyUp?: () => void;
  onKeyDown?: () => void;
}

const keyNav = (event: React.KeyboardEvent<HTMLElement>) => (props: IProps) => {
  if (props.onTab && ariaKeyDown(event, [KEY_TAB])) props.onTab();
  if (props.onEsc && ariaKeyDown(event, [KEY_ESC])) props.onEsc();
  if (props.onSpace && ariaKeyDown(event, [KEY_SPACE])) props.onSpace();
  if (props.onEnter && ariaKeyDown(event, [KEY_ENTER])) props.onEnter();
  if (props.onKeyUp && ariaKeyDown(event, [KEY_UP])) props.onKeyUp();
  if (props.onKeyDown && ariaKeyDown(event, [KEY_DOWN])) props.onKeyDown();
};

export default keyNav;
