import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { REQUEST_ORG_SELECTED_TEAM_CHILDREN } from '../../const/selectedTeam';
import { getOrgSelectedTeamEntities } from '../../api/organization/getTeams';
import { ITeamChildEntities } from '../../interfaces/ITeams';
import {
  onRquestOrganizationSelectedTeamChildrenSuccess,
  onRquestOrganizationSelectedTeamChildrenFail,
} from '../../actions/organization/selectedTeam';

const getOrganizationsSelectedTeamChildrensEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ORG_SELECTED_TEAM_CHILDREN),
    map(action => action.payload),
    switchMap(() => {
      return getOrgSelectedTeamEntities().pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ result, data }: { result: boolean; data: ITeamChildEntities }) => {
          return onRquestOrganizationSelectedTeamChildrenSuccess(data);
        }),
        catchError(error => of(onRquestOrganizationSelectedTeamChildrenFail(error.xhr))),
      );
    }),
  );

export default getOrganizationsSelectedTeamChildrensEpic;
