import { EscalationIcon } from 'icons';

import { AssigneeType } from '../enums';
import { Avatar } from './Avatar';
import { SquadAvatar } from './SquadAvatar';

type Props = {
  name: string;
  type?: AssigneeType;
};

export const AssigneeAvatar = ({ type, name }: Props) => {
  if (type === AssigneeType.Escalationpolicy) return <EscalationIcon />;

  if (type === AssigneeType.Squad) return <SquadAvatar name={name} />;

  return <Avatar size="xs" name={name} />;
};
