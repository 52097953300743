import EasyMDE from '@SquadcastHub/easymde';
import { actionCustomHeading, customAssetUploadAction } from './mdeOptionHelper';
interface ArrayOneOrMore<T> extends Array<T> {
  0: T;
}

// This are the options to appear in the dropdown
const childrensOptions: ArrayOneOrMore<{
  name: string;
  className: string;
  title: string;
  action: (editor: EasyMDE) => void;
}> = [
  {
    name: 'Normal Text',
    className: 'fa normal-text',
    title: 'Normal Text',
    action: (editor: EasyMDE) => {
      actionCustomHeading(editor, 0, true);
    },
  },
  {
    name: 'heading-1',
    className: 'fa fa-heading header-1',
    title: 'Heading 1',
    action: EasyMDE.toggleHeading1,
  },
  {
    name: 'heading-2',
    className: 'fa fa-heading header-2',
    title: 'Heading 2',
    action: EasyMDE.toggleHeading2,
  },
  {
    name: 'heading-3',
    className: 'fa fa-heading header-3',
    title: 'Heading 3',
    action: EasyMDE.toggleHeading3,
  },
  {
    name: 'heading-4',
    className: 'fa fa-heading header-4',
    title: 'Heading 4',
    action: (editor: EasyMDE) => {
      actionCustomHeading(editor, 4);
    },
  },
  {
    name: 'heading-5',
    className: 'fa fa-heading header-5',
    title: 'Heading 5',
    action: (editor: EasyMDE) => {
      actionCustomHeading(editor, 5);
    },
  },
  {
    name: 'heading-6',
    className: 'fa fa-heading header-6',
    title: 'Heading 6',
    action: (editor: EasyMDE) => {
      actionCustomHeading(editor, 6);
    },
  },
];

// This is the default toolbar
export const defaultToolbar:
  | boolean
  | ReadonlyArray<'|' | EasyMDE.ToolbarIcon | EasyMDE.ToolbarDropdownIcon> = [
  {
    name: 'others',
    className: 'fa fa-normal-text',
    title: 'Text Formating',
    children: childrensOptions,
  },
  '|',
  {
    name: 'bold',
    action: EasyMDE.toggleBold,
    className: 'fa fa-bold',
    title: 'Bold',
  },
  {
    name: 'italic',
    action: EasyMDE.toggleItalic,
    className: 'fa fa-italic',
    title: 'Italic',
  },
  '|',
  {
    name: 'ordered-list',
    action: EasyMDE.toggleOrderedList,
    className: 'fa fa-list-ol',
    title: 'Numbered List',
  },
  {
    name: 'unordered-list',
    action: EasyMDE.toggleUnorderedList,
    className: 'fa fa-list-ul',
    title: 'Generic List',
  },
  '|',
  {
    name: 'upload-image',
    className: 'fa fa-image',
    title: 'upload image',
    action: editor => {
      customAssetUploadAction(editor, 'image');
    },
  },
  {
    name: 'upload-file',
    className: 'fa fa-paperclip',
    title: 'upload file',
    action: editor => {
      customAssetUploadAction(editor, 'file');
    },
  },
  {
    name: 'link',
    action: EasyMDE.drawLink,
    className: 'fa fa-link',
    title: 'Create Link',
  },
  '|',
  {
    name: 'table',
    action: EasyMDE.drawTable,
    className: 'fa fa-table',
    title: 'Insert Table',
  },
  {
    name: 'code',
    action: EasyMDE.toggleCodeBlock,
    className: 'fa fa-code',
    title: 'Code',
  },
  {
    name: 'strikethrough',
    action: EasyMDE.toggleStrikethrough,
    className: 'fa fa-strikethrough',
    title: 'Strikethrough',
  },
  '|',
  {
    name: 'quote',
    action: EasyMDE.toggleBlockquote,
    className: 'fa fa-quote-left',
    title: 'Quote',
  },
  {
    name: 'horizontal-rule',
    action: EasyMDE.drawHorizontalRule,
    className: 'fa fa-minus',
    title: 'Insert Horizontal Line',
  },
  '|',
  {
    name: 'preview',
    action: EasyMDE.togglePreview,
    className: 'fa fa-eye',
    noDisable: true,
    title: 'Toggle Preview',
  },
  {
    name: 'fullScreen',
    action: EasyMDE.toggleFullScreen,
    className: 'fa fa-arrows-alt',
    title: 'Toggle FullScreen',
  },
];

// if no option is passed, it will use the default options
export const defaultOption: EasyMDE.Options = {
  indentWithTabs: false,
  tabSize: 4,
  imageAccept: 'image/*',
  toolbar: defaultToolbar,
};
