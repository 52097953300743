import { FC, ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';

type DetailBoxProps = {
  children: ReactNode;
};

export const DetailBox: FC<DetailBoxProps> = ({ children }) => {
  return (
    <Flex flexDir="column" py={3} pl={4} rowGap={2}>
      {children}
    </Flex>
  );
};
