import { Pie } from '@nivo/pie';

export const ResponsivePieComponent = (props: any) => {
  return (
    <Pie
      theme={{
        fontSize: 12,
      }}
      width={props.width || 350}
      height={props.height || 200}
      enableArcLinkLabels={false}
      data={props.data.items}
      margin={{ top: 20, right: 130, bottom: 30, left: -10 }}
      innerRadius={props.innerRadius || 0.5}
      padAngle={0.5}
      cornerRadius={0}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      colors={{ scheme: props.colors_ || 'blues' }}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      valueFormat={value => (props.percentage ? `${value}%` : value.toString())}
      tooltip={input => {
        if (input.datum && input.datum.label)
          return (
            <div
              style={{
                backgroundColor: 'rgb(243, 244, 246)',
                color: 'rgb(58, 72, 96)',
                boxShadow: 'rgba(66, 66, 66, 0.08) 0px 1px 1px 0px',
                fontSize: '12px',
                padding: '5px',
              }}
            >
              {props.percentage ? `${input.datum.label} ${input.datum.value}%` : input.datum.label}
            </div>
          );
        return <div></div>;
      }}
      legends={[
        {
          anchor: 'top-right',
          direction: 'column',
          justify: false,
          translateX: 140,
          translateY: 30,
          itemsSpacing: 40,
          itemWidth: props.itemWidth || 180,
          itemHeight: 14,
          itemTextColor: 'black',
          itemDirection: 'top-to-bottom',
          itemOpacity: 1,
          symbolSize: 12,
          symbolShape: 'circle',
        },
      ]}
    />
  );
};
