import React, { ForwardedRef, MutableRefObject } from 'react';
import { Accordion, Grid } from 'uie/components';
import { IPatchReqRunbook, IRunbookList, ITask } from 'core/interfaces/ITask';

import { RunbookAccordinBody } from '../accordin/Accordin';
import { EditOrDeleteRunbook } from '../editAndDelete';
import TaskOrRunbookStep from '../taskOrRunbookStep';

interface IProps {
  ref: React.RefObject<HTMLDivElement[]>;
  sidebarState: 'expanded' | 'collapsed';
  collapseRunbook: boolean;
  setRunbookCollapse: (collapse: boolean) => void;
  incidentrunbookList: IRunbookList[];
  enbleDelete: number;
  setEnableDelete: (deleteIndex: number) => void;
  setEnableDeleteModal: (enbleDelete: boolean) => void;
  setDeleteRunbookId: (id: string) => void;
  collapseRunbookStep: number[];
  setRunbookStepCollapse: (step: number[]) => void;
  updateRunbook: (runbookId: string, step: IPatchReqRunbook) => void;
  isSuppressedIncident: boolean;
}

interface IRunbookStepsProps {
  index: number;
  element: ITask;
  runbook: IRunbookList | null;
  updateRunbook: (runbookId: string, step: IPatchReqRunbook) => void;
  sidebarState: 'expanded' | 'collapsed';
  isSuppressedIncident: boolean;
}

const RunbookSteps = ({
  index,
  element,
  runbook,
  updateRunbook,
  sidebarState,
  isSuppressedIncident,
}: IRunbookStepsProps) => {
  const { completed, content } = element;
  const id = (runbook && runbook.id) || '';
  return (
    <>
      <TaskOrRunbookStep
        checkedValue={completed}
        content={content}
        update={() => {
          updateRunbook(id, {
            index,
            completed: !completed,
          });
        }}
        sideBarState={sidebarState}
        isSuppressedIncident={isSuppressedIncident}
      />
    </>
  );
};
function RunbookList(props: IProps, ref: ForwardedRef<HTMLDivElement[]>) {
  const collpaseValue = props.collapseRunbook;
  const incidentRunbookList = props.incidentrunbookList;
  const incidentDetailRunbookRef = ref as MutableRefObject<HTMLDivElement[]>;
  const pushRefs = (element: HTMLDivElement | null) => {
    if (element && !incidentDetailRunbookRef.current.includes(element)) {
      incidentDetailRunbookRef.current.push(element);
    }
  };
  const RunbookStepAccordinTitle = (RnbkEle: IRunbookList) => {
    if (RnbkEle) {
      return `${RnbkEle.name || 'Runbook'} (${
        RnbkEle.steps.filter(_ => _.completed).length || 0
      } /${RnbkEle.steps.length || 0})`;
    }
    return `Runbook (0/0)`;
  };
  return (
    <Grid flexWidth={12} width={'100%'} className="ml-10 mb-10">
      {collpaseValue && (
        <div className="mt-10 mb-10 ml-10" style={{ width: '100%' }}>
          {incidentRunbookList.map((RnbkEle, index) => {
            return (
              <div
                {...{ className: 'incident_details_task_or_runbook  mt-10 mb-10' }}
                key={index}
                ref={element => {
                  pushRefs(element);
                }}
              >
                {/* child */}
                <Accordion
                  key={index}
                  title={
                    <div
                      className="incident_details__runbook__addTask_accordian-steps"
                      onClick={() => {
                        if (props.collapseRunbookStep.includes(index))
                          props.setRunbookStepCollapse(
                            props.collapseRunbookStep.filter(_ => _ != index),
                          );
                        if (!props.collapseRunbookStep.includes(index))
                          props.setRunbookStepCollapse([...props.collapseRunbookStep, index]);
                      }}
                      onMouseEnter={() => {
                        props.setEnableDelete(index);
                      }}
                      onMouseLeave={() => {
                        props.setEnableDelete(-1);
                      }}
                    >
                      <div>
                        <RunbookAccordinBody
                          collapse={!props.collapseRunbookStep.includes(index)}
                          title={RunbookStepAccordinTitle(RnbkEle)}
                        />
                      </div>
                      <EditOrDeleteRunbook
                        enableDelete={props.enbleDelete}
                        setEnableDeleteModal={props.setEnableDeleteModal}
                        id={RnbkEle.id}
                        setDeleteRunbookId={props.setDeleteRunbookId}
                        index={index}
                        isSuppressedIncident={props.isSuppressedIncident}
                      />
                    </div>
                  }
                >
                  {!props.collapseRunbookStep.includes(index) && (
                    <div>
                      {(RnbkEle as IRunbookList)?.steps.map((step, index_) => {
                        return (
                          <div key={index_} className="incident_details_step">
                            <RunbookSteps
                              index={index_}
                              element={step}
                              runbook={RnbkEle}
                              updateRunbook={props.updateRunbook}
                              sidebarState={props.sidebarState}
                              isSuppressedIncident={props.isSuppressedIncident}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Accordion>
              </div>
            );
          })}
        </div>
      )}
    </Grid>
  );
}

export default React.forwardRef(RunbookList);
