import { THEME_COLORS } from 'library/theme/colors';

interface IProps {
  size?: number;
  style?: React.CSSProperties;
}

export default function OverrideSymbol(props: IProps = { size: 4 }) {
  return (
    <span
      style={{
        backgroundColor: THEME_COLORS.brand.blue,
        borderRadius: '50%',
        width: `${props.size}px`,
        height: `${props.size}px`,
        ...props.style,
      }}
    ></span>
  );
}
