import Axios from 'axios';
import { API } from 'core/api';
import {
  ICommunicationCard,
  ICommunicationCardPatch,
  ICommunicationCardResponse,
  ICommunicationCardsResponse,
} from 'core/interfaces/ICommunicationCard';

class CommunicationCardsService {
  private _api = '';

  constructor(incidentId: string) {
    this._api = `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${incidentId}/communication_cards/`;
  }

  public addCommunicationCard = (task: ICommunicationCard) =>
    Axios.post<ICommunicationCardResponse>(`${this._api}`, task);

  public getAll = () => Axios.get<ICommunicationCardsResponse>(`${this._api}`);

  public update = (id: string, update: ICommunicationCardPatch) =>
    Axios.put<ICommunicationCardResponse>(`${this._api}${id}`, { ...update });

  public getById = (id: string) => Axios.get<ICommunicationCardResponse>(`${this._api}${id}`);

  public delete = (id: string) => Axios.delete<ICommunicationCardResponse>(`${this._api}${id}`);

  public archiveSlackCommChannel = (slack_channel_id: string) =>
    Axios.post<{ data: ICommunicationCard }>(`${this._api}channel/archive`, {
      slack_channel_id,
    });

  public createMSTeamsMeetingLink = () =>
    Axios.post<{ data: ICommunicationCard }>(`${this._api}msteams-meeting-link`);
}

export default CommunicationCardsService;
