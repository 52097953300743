import React, {
  useCallback,
  useState,
  createContext,
  ReactNode,
  useContext,
  ReactChild,
  HTMLAttributes,
} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import hasChildren from '../../utils/hasChildrens';
interface IProps {
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const InnerSnackbarContext = createContext<ReactChild | null>();

const SnackWrapper = styled.div`
  position: fixed;
  bottom: 16px;
  transition: all 0.17s ease;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
  z-index: 99999 !important;

  & > .t__grp > .snack_transition-enter {
    opacity: 0;
    transform: translateY(100%);
  }
  & > .t__grp > .snack_transition-enter-active {
    opacity: 1;
    transform: translateY(0%);
  }
  & > .t__grp > .snack_transition-exit {
    opacity: 1;
    transform: translateY(0%);
  }
  & > .t__grp > .snack_transition-exit-active {
    opacity: 0;
  }
  & > .t__grp > .snack_transition-enter-active,
  & > .t__grp > .snack_transition-exit-active {
    transition: opacity 0.17s, transform 0.17s ease;
  }

  & > .t__grp > * {
    pointer-events: auto;
  }
`;

interface ISnackBar extends HTMLAttributes<HTMLDivElement> {
  maxWidth?: string | 'fit-content';
  maxHeight?: string | 'fit-content';
  background?: string | 'theme.shades.cement';
}

/** @component */
const SnackBar = styled.div<ISnackBar>`
  width: ${props => props.maxWidth || 'fit-content'};
  max-width: ${props => props.maxWidth || '100%'};
  max-height: ${props => props.maxHeight || 'fit-content'};
  background: ${props => props.background || props.theme.shades.cement};
  margin-top: 8px;
  padding: 8px 16px;
  box-shadow: ${props => props.theme.shadow.hover};
  border-radius: 3px;
  margin: auto;
`;

const SnackContextProvider = ({ children }: IProps) => {
  const [snack, setSnack] = useState<ReactChild | null>();

  const setSnackCallback = useCallback(
    (child: ReactChild | null) => {
      return setSnack(child);
    },
    [setSnack],
  );

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <InnerSnackbarContext.Provider value={setSnackCallback}>
      {children}
      {
        <SnackWrapper>
          <TransitionGroup className="t__grp">
            {hasChildren(snack) && (
              <CSSTransition
                in={true}
                timeout={300}
                classNames="snack_transition"
                unmountOnExit={true}
              >
                {snack}
              </CSSTransition>
            )}
          </TransitionGroup>
        </SnackWrapper>
      }
    </InnerSnackbarContext.Provider>
  );
};

/** @component */
export default SnackContextProvider;
export const SnackContext: () => (child: ReactChild | null) => void = () =>
  useContext(InnerSnackbarContext) as any;
export { SnackBar };
