import { Component } from 'react';
import { ISCalendarEvent, ISCalendarRotation } from '../../../../../core/interfaces/ICalendar';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { CalendarService } from '../../../../../core/services';
import render from './render';
import './index.css';
import {
  IComponentState,
  IComponentNetworkState,
  IComponentErrorState,
} from '../../../../../core/interfaces/IComponentState';
import {
  onDeleteButtonClick,
  onRotationShiftFrequencyChange,
  openModalClick,
  onDateChange,
  onAddUserToRotation,
  onAddToEvent,
  onRemoveUserFromCalendar,
  onRemoveFromEvent,
  onVerify,
  onSave,
  getSeriesData,
} from './helpers';
import { IUsersOfOrganization } from '../../../../../core/interfaces/IUserData';
import { ISquad } from '../../../../../core/interfaces/ISquad';
import moment from 'moment';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_GS_SHIFT_DELETED } from '../../../../../core/const/tracker';
import equal from 'fast-deep-equal/es6/react';
import { deepCopy, pick } from 'core/helpers';
import { getAssignableTeamMembers } from '../../../../../shared/reducers';
import { Locale } from 'core/helpers/dateUtils';

type IMix = ISCalendarEvent & Pick<IAppState, 'organization'>;

interface IProps extends IMix {
  hide: (reload: boolean) => () => void;
  shiftCount: number;
  checkAndSetDirty: () => void;
}

interface IState extends ISCalendarEvent {
  currentCalendarId: string;
  componentState: IComponentState;
  calendarSearch: string;
  userSearch: {
    value: string;
  }[];
  userSearchFocusIndex: number;
  repeats: boolean;
  rotations: ISCalendarRotation[];
  frequency: {
    every: {
      occurrence: number;
      type: 'day' | 'week' | 'month';
      weekdays: (0 | 1 | 2 | 3 | 4 | 5 | 6)[];
    };
    endsOn: 'never' | Date;
  };
  networkState: IComponentNetworkState;
  update_type: '1' | '2';
  showDeleteOptions: boolean;
  errors: IComponentErrorState;
  rotationFrequency: number;
  repetition_type: string;
  weekly_once: boolean;
  weekly_particular_days: boolean;
  daily_repeat: boolean;
  change_assigned_group: string;
  starting_group: number;
}

export class EventModal extends Component<IProps, IState> {
  public render = render;
  public calendarService = new CalendarService();
  public _initialState?: IState;

  constructor(props: IProps) {
    super(props);

    this.state = {
      id: props.id,
      series_id: props.series_id,
      calendar_id: props.calendar_id,
      start_time: this.getLocalDatetime(props.start_time),
      end_time: this.getLocalDatetime(props.end_time),
      name: props.name || `Shift ${this.props.shiftCount + 1}`,
      is_override: props.is_override,
      currentCalendarId: props.calendar_id,
      componentState: 'busy',
      calendarSearch: '',
      userSearch: [
        {
          value: '',
        },
      ],
      userSearchFocusIndex: -1,
      user_ids: props.user_ids,
      squad_ids: props.squad_ids,
      rotations: [
        {
          user_ids: [],
          squad_ids: [],
        },
      ],
      repeats: false,
      frequency: {
        every: {
          occurrence: 1,
          type: 'day',
          weekdays: [],
        },
        endsOn: 'never',
      },
      networkState: 'idle',
      update_type: '1',
      showDeleteOptions: false,
      errors: {},
      rotationFrequency: 1,
      repetition_type: 'daily',
      weekly_once: false,
      weekly_particular_days: false,
      daily_repeat: false,
      change_assigned_group: 'single_shift',
      starting_group: 0,
    };

    if (this.props.id === '') this._initialState = deepCopy(this.state);
  }

  /**
   * Set timezone date and time as local date and time
   * @param timezoneDate Js Date
   * @returns Moment Date
   */
  getLocalDatetime = (timezoneDate: Date) => {
    return moment(Locale.toSQLDate(timezoneDate))
      .set('hours', Locale.toDateTime(timezoneDate).hour)
      .set('minutes', Locale.toDateTime(timezoneDate).minute)
      .toDate();
  };

  public componentDidMount() {
    this.getSeriesData();
  }
  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.organization.calendar.c.length !== this.props.organization.calendar.c.length) {
      this.oCalender = this.getCalendarMapping();
    }
    if (prevProps.organization.users.u.length !== this.props.organization.users.u.length) {
      this.oUsers = this.getUsersMapping();
    }
    if (prevProps.organization.squads.s.length !== this.props.organization.squads.s.length) {
      this.oSquads = this.getSquadsMapping();
    }

    if (
      this._initialState &&
      !equal(
        pick(
          this.state,
          'name',
          'repeats',
          'is_override',
          'calendar_id',
          'start_time',
          'end_time',
          'repetition_type',
          'frequency',
          'user_ids',
          'squad_ids',
        ),
        pick(
          this._initialState,
          'name',
          'repeats',
          'is_override',
          'calendar_id',
          'start_time',
          'end_time',
          'repetition_type',
          'frequency',
          'user_ids',
          'squad_ids',
        ),
      )
    )
      this.props.checkAndSetDirty();
  }

  public getSeriesData = getSeriesData.bind(this);

  public openModalClick = openModalClick.bind(this);
  public onDateChange = onDateChange.bind(this);
  public onAddUserToRotation = onAddUserToRotation.bind(this);
  public onAddToEvent = onAddToEvent.bind(this);
  public onRemoveUserFromCalendar = onRemoveUserFromCalendar.bind(this);
  public onRemoveFromEvent = onRemoveFromEvent.bind(this);

  public getCalendarMapping = () => {
    return this.props.organization.calendar.c.reduce((c: any, n) => {
      c[n.id] = n;
      return c;
    }, {});
  };
  public getUsersMapping = () => {
    const organization = this.props.organization;
    return getAssignableTeamMembers(organization.selectedTeam.team!, organization.users.u).reduce(
      (c: any, n: IUsersOfOrganization) => {
        c[n.id] = {
          _id: n.id,
          name: `${n.first_name} ${n.last_name}`,
          emailVerified: n.email_verified,
          phoneVerified: n.phone_verified,
          type: 'u',
          role: n.role,
        };
        return c;
      },
      {},
    );
  };
  public getSquadsMapping = () => {
    return this.props.organization.squads.s.reduce((c: any, n: ISquad) => {
      c[n.id] = {
        _id: n.id,
        name: n.name,
        members: n.members,
        type: 's',
      };
      return c;
    }, {});
  };
  public oCalender = this.getCalendarMapping();
  public oUsers = this.getUsersMapping();
  public oSquads = this.getSquadsMapping();

  public onSelectRepeat = () => {
    this.setState(({ repeats }) => {
      return {
        repeats: !repeats,
      };
    });
  };

  public onSelectOverride = () => {
    this.setState(({ is_override }) => {
      return {
        is_override: !is_override,
      };
    });
  };

  get isSingleEventUpdate() {
    return this.state.id !== '' && this.state.update_type === '1';
  }

  public onChangeFrequency = (value: any, key: 'occurrence' | 'type') => {
    this.setState(({ frequency }: any) => {
      frequency.every[key] = value;
      return { frequency };
    });
  };
  public onSelectSingleWeekDay = (d: any) => {
    this.setState(({ frequency }) => {
      const dayIndex = frequency.every.weekdays.indexOf(d);
      if (dayIndex === -1) {
        frequency.every.weekdays.splice(0, frequency.every.weekdays.length);
        frequency.every.weekdays.push(d);
      }

      return { frequency };
    });
  };

  public onSelectWeekDays = (d: any) => {
    this.setState(({ frequency }) => {
      const dayIndex = frequency.every.weekdays.indexOf(d);
      if (dayIndex === -1) {
        frequency.every.weekdays.push(d);
      } else {
        frequency.every.weekdays.splice(dayIndex, 1);
      }

      return { frequency };
    });
  };

  public onChangeFrequencyEndsOn = () => {
    this.setState(({ frequency }) => {
      frequency.endsOn = frequency.endsOn === 'never' ? new Date() : 'never';

      return { frequency };
    });
  };

  public onChangeFrequencyRepeatEndDate = (value: Date) => {
    this.setState(({ frequency }) => {
      frequency.endsOn = value;
      return { frequency };
    });
  };

  public onAddRotation = () => {
    this.setState(({ rotations, userSearch }) => {
      rotations.push({
        user_ids: [],
        squad_ids: [],
      });

      userSearch.push({
        value: '',
      });

      return { rotations, userSearch };
    });
  };

  public onRemoveRotation = (rotationIndex: number) => {
    this.setState(({ rotations, userSearch }) => {
      rotations.splice(rotationIndex, 1);
      userSearch.splice(rotationIndex, 1);
      return { rotations, userSearch };
    });
  };

  public onUpdateMethodChange = (event: any) => {
    const value = event.target.value;
    this.setState(({ update_type, userSearch, errors }) => {
      update_type = value;
      userSearch.forEach(us => (us.value = ''));
      return { update_type, userSearch };
    });
  };
  public setRotationFrequency = (freq: number) => {
    this.setState({ rotationFrequency: freq });
  };
  public setRepetationFrequency = (type: string) => {
    this.setState({ repetition_type: type });
  };

  public onVerify = onVerify.bind(this);

  public onSave = onSave.bind(this);

  public onDeleteEvent = async (event: any) => {
    AppTracker.track(T_WA_GS_SHIFT_DELETED);
    this.setState({ componentState: 'busy' });

    const deleteType = event.target.value;

    try {
      await this.calendarService.deleteCalendarSeries(
        this.state.calendar_id,
        this.state.id,
        deleteType,
      );

      this.props.hide(true)();
    } catch (err: any) {
      this.setState({ componentState: 'idle' });
    }
  };

  public onShiftNameChange = (_: any, v: any) => {
    this.setState({ name: v });
  };
  public onSelectCalendarChange = (_: any, v: any) => {
    this.setState({ calendar_id: v });
  };
  public onSearchValueChange = (search: any) => {
    this.setState({ calendarSearch: search });
  };
  public onDateTimePickerChange = (time: any, date: any) => (value: any) => {
    this.onDateChange((value ? value : '00:00') as Date, time, date);
  };
  public onRepeatsChange = () => {
    this.onSelectRepeat();
  };
  public onDailyRepetitionInputChange = () => {
    this.setState({ repetition_type: 'daily' });
    this.onChangeFrequency('day', 'type');
    this.onChangeFrequency(1, 'occurrence');
  };
  public onWeeklyOnceRepetitionInputChange = () => {
    if (this.state.frequency.every.weekdays.length > 0) {
      this.setState(({ frequency }) => {
        frequency.every.weekdays.splice(0, this.state.frequency.every.weekdays.length);
        return { frequency };
      });
    }
    this.setState({ repetition_type: 'weekly_once' });
    this.onChangeFrequency('week', 'type');
    this.onChangeFrequency(1, 'occurrence');
  };
  public onSingleWeekDayInputChange = (i: number) => () => {
    this.onSelectSingleWeekDay(i);
  };
  public onWeeklyParticularRepetitionInputChange = () => {
    if (this.state.frequency.every.weekdays.length > 0) {
      this.setState(({ frequency }) => {
        frequency.every.weekdays.splice(0, this.state.frequency.every.weekdays.length);
        return { frequency };
      });
    }
    this.setState({ repetition_type: 'weekly_custom' });
    this.onChangeFrequency('week', 'type');
    this.onChangeFrequency(1, 'occurrence');
  };
  public onMultipleWeekdayChange = (i: number) => () => {
    this.onSelectWeekDays(i);
  };
  public onCustomRepetitionInputChange = () => {
    this.onChangeFrequency('day', 'type');
    if (this.state.frequency.every.weekdays.length > 0) {
      this.setState(({ frequency }) => {
        frequency.every.weekdays.splice(0, this.state.frequency.every.weekdays.length);
        return { frequency };
      });
    }
    this.setState({ repetition_type: 'custom' });

    this.onChangeFrequency(1, 'occurrence');
  };
  public onFrequencyInputChange = (event: any) => {
    this.onChangeFrequency(event.target.value, 'occurrence');
  };
  public onSelectCustomRepitionTypeChange = (event: any) => {
    this.onSelectSingleWeekDay(this.state.start_time.getDay());
    this.onChangeFrequency(event.target.value, 'type');
  };
  public onRepitionEndsDatePickerChange = (value: any) => {
    this.onChangeFrequencyRepeatEndDate(value as Date);
  };
  public onRemoveRotationClick = (rotationIndex: number) => () => {
    if (rotationIndex === this.state.starting_group) {
      this.setState({
        starting_group: 0,
      });
    }

    this.onRemoveRotation(rotationIndex);
  };
  public searchUsersClick = (event: any) => {
    event.stopPropagation();
  };
  public searchUserOnChange = (rotationIndex: number) => (event: any) => {
    const value = event.target.value;
    this.setState(({ userSearch }) => {
      userSearch[rotationIndex].value = value;
      return { userSearch };
    });
  };
  public onSearchUserFocus = (rotationIndex: number) => () => {
    this.setState({ userSearchFocusIndex: rotationIndex });
  };
  public onRemoveFromCalendarClick = (ids: any, rotationIndex: number) => () => {
    this.onRemoveUserFromCalendar(ids, rotationIndex);
  };

  public changeStartingGroup = (rotationIndex: number) => () => {
    this.setState({ starting_group: rotationIndex });
  };

  public onRemoveFromEventClick = (id: string) => () => {
    this.onRemoveFromEvent(id);
  };

  public onRotationFocusBlockClick = (e: any, rotationIndex: number) => () => {
    this.setState(({ userSearch }) => {
      userSearch[rotationIndex].value = '';
      return { userSearch };
    });
    this.onAddUserToRotation(e._id, e.type, rotationIndex);
  };

  public onEventFocusBlockClick = (e: any) => () => {
    this.setState(({ userSearch }) => {
      userSearch[0].value = '';
      return { userSearch };
    });
    this.onAddToEvent(e._id, e.type);
  };

  public onShiftInputChange = (type: string, freq: number) => () => {
    this.setState({ change_assigned_group: type });
    this.setRotationFrequency(freq);
  };
  public onRotationShiftFrequencyChange = onRotationShiftFrequencyChange.bind(this);
  public onDeleteButtonClick = onDeleteButtonClick.bind(this);
}

export default connect(({ organization }: IAppState) => ({
  organization,
}))(EventModal);
