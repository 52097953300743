import { Box, Flex } from '@chakra-ui/react';
import useQueryParams from 'core/hooks/useQueryParams';
import { FC } from 'preact/compat';
import { useEffect, useState } from 'react';

import { useMutateAlertQuery } from '../hooks';
import { GER_DATA, RULESET } from '../types';
import { QUERY_PARAM_KEYS } from '../util';
import { GERAlertList } from './alert';
import { RulesetList } from './ruleset';

type Props = {
  gerData: GER_DATA;
};

export const Rulesets: FC<Props> = ({ gerData }) => {
  const query = useQueryParams();
  const [ruleset, setRuleset] = useState<RULESET>(gerData.rulesets[0]);

  const { updateAlert } = useMutateAlertQuery();

  const onSelection = (id: string) => {
    const data = gerData.rulesets.find(ruleset => ruleset.id === id);
    if (data) {
      updateAlert({
        version: data.alert_source_version,
        shortName: data.alert_source_shortname,
      });
      setRuleset(data);
    }
  };

  useEffect(() => {
    const version = query.get(QUERY_PARAM_KEYS.ALERT_VERSION);
    const shortName = query.get(QUERY_PARAM_KEYS.ALERT_NAME);
    if (version && shortName) {
      const data = gerData.rulesets.find(
        ruleset =>
          ruleset.alert_source_version === version && ruleset.alert_source_shortname === shortName,
      );
      if (data) {
        setRuleset(data);
      }
    } else {
      updateAlert();
      gerData.rulesets.length && onSelection(gerData.rulesets[0].id);
    }
  }, [gerData.rulesets]);

  if (!gerData.rulesets.length) {
    return (
      <Flex flex="1" mt="11.25px" py={8} bg="container.background">
        <GERAlertList gerId={gerData.id} rulesets={gerData.rulesets} onSelection={onSelection} />
      </Flex>
    );
  }

  return (
    <>
      {ruleset && (
        <Flex mt="11.25px" gap="1" flex="1">
          <Flex bg="container.background" flex="1">
            <GERAlertList
              gerId={gerData.id}
              rulesets={gerData.rulesets}
              selectedId={ruleset.id}
              onSelection={onSelection}
            />
          </Flex>
          <Flex bg="container.background" flex="3">
            <RulesetList ruleset={ruleset} routingKey={gerData.routing_key} gerId={gerData.id} />
          </Flex>
        </Flex>
      )}
    </>
  );
};
