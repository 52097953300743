import React from 'react';
import { Avatar, Flex, VStack, Box, Text, HStack, Tooltip } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import { components } from 'react-select';
import { usernameTooltipLabel } from 'library/atoms';

export const ParticipantOptionLabel = ({ label, type, username, showTooltip = false }: any) => {
  return (
    <Flex>
      <Avatar size="xs" name={label} />
      {showTooltip ? (
        <Tooltip
          label={
            <VStack spacing={0} align="stretch" color={'#F2F2F2'}>
              <Box>
                <Text ml="5px" size="sm" maxW="35ch" color={THEME_COLORS.secondary[1000]}>
                  {label}
                </Text>
              </Box>
              {type === 'user' && (
                <Box>
                  <Text ml="5px" size="sm" maxW="35ch" color={THEME_COLORS.secondary[700]}>
                    {username}
                  </Text>
                </Box>
              )}
            </VStack>
          }
          placement="bottom"
          hasArrow
          bg={THEME_COLORS.secondary[100]}
        >
          <Box>
            <Text
              ml="5px"
              fontSize="sm"
              maxW="15ch"
              color={THEME_COLORS.secondary[1000]}
              isTruncated
            >
              {label}
            </Text>
          </Box>
        </Tooltip>
      ) : (
        <VStack spacing={1} align="stretch">
          <Box>
            <Text ml="5px" fontSize="sm" maxW="35ch" color={THEME_COLORS.secondary[1000]}>
              {label}
            </Text>
          </Box>
          {type === 'user' && (
            <Box>
              <Text ml="5px" size="sm" maxW="35ch" color={THEME_COLORS.secondary[700]}>
                {username}
              </Text>
            </Box>
          )}
        </VStack>
      )}
    </Flex>
  );
};

export const SingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <Tooltip
        label={usernameTooltipLabel(props.data.label, props.data.username)}
        placement="bottom"
        hasArrow
        color={THEME_COLORS.secondary[1000]}
        bg={THEME_COLORS.secondary[100]}
      >
        <HStack>
          <Avatar size="xs" name={props.data.label} />
          <Text fontSize="md" isTruncated>
            {props.data.label}
          </Text>
          {props.data.username && (
            <Text fontSize="md" isTruncated>
              ({props.data.username})
            </Text>
          )}
        </HStack>
      </Tooltip>
    </components.SingleValue>
  );
};
