import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.54 2.46a5 5 0 1 0-7.08 7.063 5 5 0 0 0 7.08-7.063ZM12 6a3 3 0 0 1 3-3 3 3 0 0 1 1.29.3l-4 4A2.999 2.999 0 0 1 12 6Zm5.12 2.12a3.08 3.08 0 0 1-3.4.57l4-4A3 3 0 0 1 18 6a3 3 0 0 1-.88 2.12ZM17 13a1 1 0 0 0-1 1v.39l-1.48-1.49a2.87 2.87 0 0 0-3.93 0l-.7.71-2.48-2.49a2.87 2.87 0 0 0-3.93 0L2 12.61V7a1 1 0 0 1 1-1h4a1 1 0 0 0 0-2H3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12c.324-.005.645-.062.95-.17h.09A3 3 0 0 0 18 19.44a1.443 1.443 0 0 0 0-.22V14a1 1 0 0 0-1-1ZM3 20a1 1 0 0 1-1-1v-3.57l2.9-2.89a.79.79 0 0 1 1.09 0l3.19 3.18L13.46 20H3Zm13-1a1 1 0 0 1-.18.54L11.3 15l.71-.7a.79.79 0 0 1 1.09 0l2.9 2.91V19Z"
      fill="#000"
    />
  </svg>
);

export default SvgComponent;
