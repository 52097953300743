import InputNumber from 'components/chakra/NumberInput';
import { useFormikContext } from 'formik';
import { Check } from 'icons';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SCHEDULES_V2_PATH } from 'views/main/routes/routes';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  ExpandedIndex,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Switch,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';

import { schedulesTextCopy } from '../../constants/schedules.copy';
import {
  blankRotationTemplate,
  customRotationSlug,
  rotationTemplates,
} from '../../constants/schedules.rotation-template';
import { mapGapsToCalendarEvent, mapMockEventsToCalendarEvents } from '../../helpers/helpers.event';
import { CalendarViewType, INewSchedule, IRotation } from '../../interface/schedule';
import SchedulesCalendar from '../../schedules.view/schedules.calendar';
import SchedulesHeader from '../../schedules.view/schedules.header';
import {
  ScheduleHeaderConsumer,
  useScheduleHeaderContext,
} from '../../schedules.view/schedules.header/context';
import {
  ChangeParticipantsInterval,
  NewSchedule,
  PeriodOptions,
} from 'views/main/organization/schedules/graphql/types';
import {
  GetMockEventsQuery,
  useGetMockEventsQuery,
} from 'views/main/organization/schedules/graphql/query';
import { defaultReactQueryConfig, formatScheduleRequest } from '../../helpers/helpers.schedule';
import {
  useGetAllPatternParticipants,
  IPatternParticipant,
} from '../../helpers/helpers.customrotations';
import { rotationsCustomizePattern } from '../../constants/rotations.customize';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_SCHEDULE_V2_CUSTOM_ROTATION_ADDED } from 'core/const/tracker';

function SchedulesTemplate() {
  const history = useHistory();
  const formik = useFormikContext<INewSchedule>();
  const customRotationIDs = formik.values.rotations
    .filter(r => r.id.includes(customRotationSlug))
    .map(r => Number.parseInt(r.id.replace(customRotationSlug, '')));
  const maxCustomRotationID = useRef(
    Math.max(...(customRotationIDs.length > 0 ? customRotationIDs : [0])),
  );
  const { calendarRef, visibleDates, activeViewType, onSyncCalendar } = useScheduleHeaderContext();

  const [isExpanded, setIsExpanded] = useState(false);
  const [newRotationCount, setNewRotationCount] = useState(1);
  const [rotationAddedMessage, setRotationAddedMessage] = useState('');

  const {
    values: { showGaps, timeZone },
    handleChange,
  } = formik;

  const previewDateRange =
    activeViewType.value === CalendarViewType.dayGridMonth
      ? visibleDates.oneMonthView
      : visibleDates.oneWeekView;

  const { data, isFetching: isFetchingMockEvents } = useGetMockEventsQuery(
    {
      schedule: formatScheduleRequest(
        {
          ...formik.values,
          rotations: formik.values.rotations.filter(rt => rt.showOnCalendar),
        },
        true,
      ) as NewSchedule,
      from: previewDateRange[0].setZone(timeZone).startOf('day').toUTC().toISO(),
      till: previewDateRange
        .slice(-1)[0]
        .setZone(timeZone)
        .plus({ days: 1 })
        .endOf('day')
        .toUTC()
        .toISO(),
    },
    defaultReactQueryConfig,
  );

  const allParticipants = useGetAllPatternParticipants();

  const mappedMockEvents = useMemo(() => {
    if (!isFetchingMockEvents) {
      return [
        ...mapMockEventsToCalendarEvents(
          {
            ...formik.values,
            rotations: formik.values.rotations.filter(rt => rt.showOnCalendar),
          },
          data?.mockEvents?.mockEvents,
          allParticipants,
        ),
        ...(showGaps ? mapGapsToCalendarEvent(data?.mockEvents.gaps ?? [], timeZone) : []),
      ];
    } else {
      return [];
    }
  }, [isFetchingMockEvents, formik.values, data, timeZone, showGaps]);

  const existingRotations = formik.values.rotations;
  const visibleRotations = [
    ...rotationTemplates,
    ...existingRotations.filter(er => er.id.includes(customRotationSlug)),
  ];

  const onAddRotation = () => {
    const template = Array.from({ length: newRotationCount }).map((_t, index) => {
      const templateIndex = index + 1 + maxCustomRotationID.current;
      return {
        ...blankRotationTemplate,
        id: customRotationSlug + templateIndex,
        name: `Custom Rotation ${templateIndex}`,
      };
    });
    maxCustomRotationID.current += newRotationCount;

    formik.setFieldValue('rotations', [...existingRotations, ...template], true);
    setRotationAddedMessage(`${newRotationCount} added`);
    setNewRotationCount(1);
  };

  const clearRotationAddedMsg = (expandedIndex?: ExpandedIndex) => {
    setIsExpanded(!expandedIndex);
    setRotationAddedMessage('');
  };

  const onUpdateRotation = (template: IRotation) => () => {
    const templateInfo = existingRotations.find(rt => rt.id === template.id);
    const templateStartTime = template.shiftTimeSlot.startTime;

    if (templateInfo) {
      const filteredRotations = existingRotations.filter(rt => rt.id !== template.id);
      formik.setFieldValue('rotations', filteredRotations, true);
    } else {
      if (!template.name.startsWith(customRotationSlug)) {
        calendarRef.current?.getApi().scrollToTime(templateStartTime);
      }
      formik.setFieldValue('rotations', [...existingRotations, template], true);
    }
  };

  const getRotationEndTime = (startTime: string, onCallInterval: number) => {
    const [hour, min] = startTime.split(':');
    return [Number(hour) + Math.floor(onCallInterval / 60), Number(min) + (onCallInterval % 60)]
      .map(num => num.toString().padStart(2, '0'))
      .join(':');
  };

  const getRotationCycle = (onCallInterval: number, rotationInterval: IRotation['period']) => {
    let cycle = 1;
    if (rotationInterval === PeriodOptions.Weekly) cycle = 5;

    return (onCallInterval / 60) * cycle;
  };

  const getRotationDays = (rotationPattern: IRotation) => {
    if (rotationPattern.changeParticipantsUnit === ChangeParticipantsInterval.Day) return 'All';
    if (rotationPattern.changeParticipantsUnit === ChangeParticipantsInterval.Week)
      return 'Mon-Fri';
    return 'All';
  };

  const toggleShowRotationOnCalendar = (rotationId: string) => {
    const showOnCalendarValue = formik.values.rotations.find(
      rt => rt.id === rotationId,
    )?.showOnCalendar;
    const rotationIndex = formik.values.rotations.findIndex(rt => rt.id === rotationId);
    handleChange({
      target: {
        name: `rotations.${rotationIndex}.showOnCalendar`,
        value: !showOnCalendarValue,
      },
    });
  };

  return (
    <>
      <Box bg="white" position="relative" h="inherit">
        <SchedulesHeader.Preview />
        <ScheduleHeaderConsumer>
          {({ activeViewType }) => (
            <SchedulesCalendar
              ref={calendarRef}
              viewType={activeViewType.value}
              timeZone={formik.values.timeZone}
              events={mappedMockEvents}
              disableScheduleDrawer
              disableEventModifiers
              height="calc(100% - 88px)"
              onSyncSelectedDate={onSyncCalendar}
            />
          )}
        </ScheduleHeaderConsumer>
      </Box>

      <Box bg="white" position="relative">
        <VStack alignItems="flex-start" gap={4} p={4}>
          <HStack>
            <VStack alignItems="flex-start">
              <Heading as="h6" variant="h6">
                {schedulesTextCopy.template.title}
              </Heading>
              <Box>
                <Text fontSize="xs">{schedulesTextCopy.template.desc}</Text>
                <Text fontSize="xs">{schedulesTextCopy.template.note}</Text>
              </Box>
            </VStack>
            <HStack position="absolute" spacing={1} right={2}>
              <FormLabel
                htmlFor="showGaps"
                alignContent="center"
                mb={0}
                fontSize="sm"
                fontWeight="normal"
              >
                {rotationsCustomizePattern.labels.viewGaps}
              </FormLabel>
              <Switch
                id="showGaps"
                size="sm"
                name="showGaps"
                isChecked={showGaps}
                onChange={handleChange}
              />
            </HStack>
          </HStack>

          <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
            <Box borderColor="gray.200" borderWidth={1} borderRadius="xl" p={4} w="inherit">
              <Accordion allowToggle onChange={clearRotationAddedMsg}>
                <AccordionItem border="none">
                  <Flex justifyContent="space-between">
                    <AccordionButton
                      color="default"
                      p={0}
                      w="auto"
                      _hover={{ bgColor: 'inherit' }}
                      _focus={{ boxShadow: 'none' }}
                    >
                      <Text fontSize="xs" fontWeight="bold" color="blue.900">
                        {schedulesTextCopy.template.accordion}
                      </Text>
                      <AccordionIcon ml={1} />
                    </AccordionButton>

                    {rotationAddedMessage && (
                      <HStack
                        sx={{ color: 'green.500', '& svg circle': { fill: 'green.500' } }}
                        spacing={1}
                      >
                        <Text fontSize="xs">{rotationAddedMessage}</Text>
                        <Check width={14} height={14} />
                      </HStack>
                    )}
                  </Flex>

                  <AccordionPanel py={2} px={0}>
                    <Flex alignItems="center" gap={4}>
                      <InputNumber
                        defaultValue={1}
                        min={1}
                        onChange={count => {
                          clearRotationAddedMsg();
                          setNewRotationCount(count);
                        }}
                        value={newRotationCount}
                      />
                      <Button
                        size="sm"
                        onClick={() => {
                          onAddRotation();
                          AppTracker.track(T_WA_UP_SCHEDULE_V2_CUSTOM_ROTATION_ADDED);
                        }}
                      >
                        {schedulesTextCopy.template.button.add}
                      </Button>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>

            <VStack
              w="100%"
              mt={4}
              spacing={4}
              maxH={{
                '2xl': `calc(100vh - ${isExpanded ? 445 + 50 : 445}px)`,
                xl: `calc(100vh - ${isExpanded ? 475 + 75 : 475}px)`,
                md: `calc(100vh - ${isExpanded ? 560 + 60 : 560}px)`,
              }}
              overflow="auto"
            >
              {visibleRotations.map((rotation, index) => {
                const isCustomRotation = rotation.id.startsWith(customRotationSlug);
                const rotationAdded = existingRotations.find(rt => rt.id === rotation.id);
                const showRotationOnCalendar = existingRotations.find(
                  rt => rt.id === rotation.id,
                )?.showOnCalendar;
                const rotationStartTime = rotation.shiftTimeSlot.startTime;
                const rotationEndTime = getRotationEndTime(
                  rotationStartTime,
                  rotation.shiftTimeSlot.duration,
                );
                const rotationDays = getRotationDays(rotation);
                const rotationCycle = getRotationCycle(
                  rotation.shiftTimeSlot.duration,
                  rotation.period,
                );

                const backgroundColor = rotationAdded
                  ? existingRotations.find(rt => rt.id === rotation.id)?.color ?? 'white'
                  : 'white';

                return (
                  <Box
                    key={rotation.name}
                    borderColor="gray.200"
                    borderWidth={1}
                    borderRadius="xl"
                    p={4}
                    w="inherit"
                    background={backgroundColor}
                  >
                    <VStack alignItems="flex-start" gap={1}>
                      <Grid templateColumns="1.8fr 1fr 0.4fr" w="100%">
                        <VStack align="flex-start" gap={0}>
                          <Heading as="h5" size="sm" fontWeight={500} color="black">
                            {rotation.name}
                          </Heading>

                          {!isCustomRotation && (
                            <Text as="h4" size="md" fontWeight={500}>
                              {rotation.shiftTimeSlot.startTime} - {rotationEndTime}
                            </Text>
                          )}
                        </VStack>

                        <VStack align="flex-start" gap={0}>
                          {!isCustomRotation && (
                            <Fragment>
                              <Text fontSize="xs" color="gray.500">
                                Each Cycle: {rotationCycle} hrs
                              </Text>

                              <Text fontSize="xs" color="gray.500">
                                Days: {rotationDays}
                              </Text>
                            </Fragment>
                          )}
                        </VStack>

                        {!isCustomRotation && (
                          <VStack
                            justifyContent="center"
                            gap={0}
                            sx={{
                              '& svg circle': {
                                fill: showRotationOnCalendar ? 'green.500' : 'gray.400',
                              },
                            }}
                          >
                            {rotationAdded && (
                              <>
                                <Text fontSize="xs" textAlign="center" color="gray.500">
                                  {showRotationOnCalendar ? 'Hide ' : 'Show '} rotation
                                </Text>
                                <IconButton
                                  aria-label={`${
                                    showRotationOnCalendar ? 'hide' : 'show'
                                  }-rotation`}
                                  _hover={{ cursor: 'pointer' }}
                                  onClick={() => toggleShowRotationOnCalendar(rotation.id)}
                                  _focus={{ boxShadow: 'none' }}
                                  backgroundColor="inherit"
                                >
                                  <Check width={20} height={20} />
                                </IconButton>
                              </>
                            )}
                          </VStack>
                        )}
                      </Grid>

                      <Button
                        variant={rotationAdded ? 'invertedDefault' : 'default'}
                        onClick={onUpdateRotation(rotation)}
                      >
                        {rotationAdded ? 'Remove' : 'Add'}
                      </Button>
                    </VStack>
                  </Box>
                );
              })}
            </VStack>

            <Box w="100%" bgColor="white" position="absolute" right="1.5rem" bottom={0} mx={-6}>
              <Divider />
              <FormControl
                isInvalid={!!formik.errors.rotations && !Array.isArray(formik.errors.rotations)}
              >
                <FormErrorMessage px={4}>
                  {Array.isArray(formik.errors.rotations) ? '' : formik.errors.rotations}
                </FormErrorMessage>
                <HStack p={4} gap={1}>
                  <Button
                    type="submit"
                    isDisabled={
                      formik.errors.rotations && !Array.isArray(formik.errors.rotations)
                        ? true
                        : false
                    }
                  >
                    {schedulesTextCopy.template.nextCustomizeRotation}
                  </Button>
                  <Button variant="invertedDefault" onClick={() => history.push(SCHEDULES_V2_PATH)}>
                    {schedulesTextCopy.common.cancel}
                  </Button>
                </HStack>
              </FormControl>
            </Box>
          </form>
        </VStack>
      </Box>
    </>
  );
}

export default SchedulesTemplate;
