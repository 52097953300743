import * as React from 'react';
import { SVGProps } from 'react';

const SvgPrometheus = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#e6522c',
      }}
      d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zm0 18.716c-1.571 0-2.845-1.05-2.845-2.344h5.69c0 1.294-1.274 2.344-2.845 2.344zm4.7-3.12H5.3V13.89h9.4v1.704zm-.034-2.582H5.327c-.031-.036-.063-.071-.093-.108-.962-1.168-1.189-1.778-1.409-2.4-.003-.02 1.167.24 1.997.426 0 0 .427.1 1.051.213-.6-.702-.955-1.596-.955-2.509 0-2.004 1.537-3.756.983-5.172.54.044 1.117 1.14 1.156 2.852.574-.793.814-2.241.814-3.13 0-.919.606-1.987 1.212-2.023-.54.89.14 1.653.744 3.547.227.71.198 1.908.373 2.667.058-1.577.33-3.877 1.33-4.671-.442 1 .065 2.251.411 2.853.559.97.898 1.706.898 3.097 0 .932-.344 1.81-.925 2.496.66-.123 1.116-.235 1.116-.235l2.145-.419s-.312 1.282-1.51 2.516z"
    />
  </svg>
);

export default SvgPrometheus;
