import { Box, Flex, Text } from '@chakra-ui/react';
import { IconButton, Tab } from 'library/atoms';
import { LibraryIcons } from 'library/icons';
import { FC } from 'react';

import { SERVICE_NODE_TYPE } from '../../Interfaces/graph';
import { ServicesList } from './service-list';

type Props = {
  depServices: SERVICE_NODE_TYPE[];
  indepServices: SERVICE_NODE_TYPE[];
  onClose: () => void;
  onSelect: (node: SERVICE_NODE_TYPE, isGraphNode?: boolean) => void;
};

export const ServicesInfoList: FC<Props> = ({ indepServices, depServices, onClose, onSelect }) => {
  return (
    <>
      <Box
        style={{
          position: 'absolute',
          top: '52%',
          background: 'white',
          width: '28px',
          height: '56px',
          zIndex: 100,
          cursor: 'pointer',
          borderRadius: '50px 0px 0 50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '2px solid #E1E6EB',
          borderRight: 'none',
          left: '-28px',
        }}
        onClick={onClose}
      >
        <LibraryIcons.ChevronRightIcon color="secondary.1000" width="24px" height="24px" />
      </Box>
      <Flex flexDir="column">
        <Box px={4} pt={2}>
          <Text variant="body" color="secondary.700">
            Search through the list of dependent services (also seen on the graph) to find them
            easily on the graph.
            <br /> Note: Independent services are those that don't have any dependency connections.
          </Text>
        </Box>
        <Tab
          isLazy
          pt="12px"
          labels={[
            {
              name: 'Dependent',
            },
            {
              name: 'Independent',
            },
          ]}
          variant="outline"
        >
          <ServicesList
            services={depServices}
            onSelect={node => onSelect(node, true)}
            serviceType="Dependent"
          />
          <ServicesList services={indepServices} onSelect={onSelect} serviceType="Independent" />
        </Tab>
      </Flex>
    </>
  );
};
