import { DateTime } from 'luxon';
import React, { createContext, ReactNode, useContext, useState } from 'react';

const CalendarContext = createContext<CalendarContextProps | undefined>(undefined);

type CalendarContextProps = {
  activeDate?: DateTime;
  updateActiveDate: (date: DateTime) => void;
};

interface Props {
  initialActiveDate?: DateTime;
  children: ReactNode;
}

export const CalendarProvider: React.FC<Props> = ({ initialActiveDate, children }) => {
  const [activeDate, setActiveDate] = useState<DateTime | undefined>(
    initialActiveDate ?? undefined,
  );

  const updateActiveDate = (date: DateTime) => {
    setActiveDate(date);
  };

  return (
    <CalendarContext.Provider value={{ activeDate, updateActiveDate }}>
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendarContext = () => {
  const context = useContext(CalendarContext);
  if (!context) throw new Error('No Provider context found!');
  return context;
};
