import Axios from 'axios';
import { API } from 'core';
import { QUERY_CONFIG } from 'library/query-config';
import { QueryClient, useQuery } from 'react-query';

import { QUERY_KEY } from './queryKeys';

const getRulesetPayload = (gerId: string, alertVersion: string, alertName: string) => {
  return Axios.get(
    `${API.config.batman}/organizations/${API.config.organizationId}/global-event-rules/${gerId}/rulesets/${alertVersion}/${alertName}/payload`,
  );
};

export const useGetGerRulesetPayload = (gerId: string, alertVersion: string, alertName: string) => {
  const { data, isSuccess, isError, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEY.GER_RULESET_PAYLOAD, gerId, alertVersion, alertName],
    queryFn: () => getRulesetPayload(gerId, alertVersion, alertName),
    ...QUERY_CONFIG,
  });
  const response = data?.data;
  return {
    payload: response?.data,
    isSuccess,
    isError,
    isLoading,
    isFetching,
  };
};

export const invalidateGERRulesetPayload = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(QUERY_KEY.GER_RULESET_PAYLOAD);
};
