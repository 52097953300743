import { useClipboard } from '@chakra-ui/hooks';
import Icon from '@chakra-ui/icon';
import { HStack } from '@chakra-ui/react';
import { FC, useEffect } from 'react';

import { IconButton } from '.';
import { CopyIcon } from '../icons';
import { NameComponent } from './Name';

type Props = {
  copyText: string;
  onTextCopied?: () => void;
  maxLength?: number;
};

export const CopyBox: FC<Props> = ({ copyText, onTextCopied, maxLength }) => {
  const { onCopy, value, hasCopied } = useClipboard(copyText);

  useEffect(() => {
    if (hasCopied && onTextCopied) {
      onTextCopied();
    }
  }, [hasCopied, onTextCopied]);

  return (
    <HStack sx={{ mt: '0px !important' }} onClick={onCopy}>
      <NameComponent name={value} maxLength={maxLength} />
      <IconButton
        variant="ghost"
        aria-label="copy"
        icon={<Icon as={CopyIcon} sx={{ verticalAlign: 'middle' }} />}
      />
    </HStack>
  );
};
