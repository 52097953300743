import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M14.347 16.75h2.762V8.26h-2.762v8.49zm-.409-9.307a.409.409 0 0 0-.409.409v9.307c0 .226.183.409.409.409h3.58a.409.409 0 0 0 .409-.409V7.852a.409.409 0 0 0-.409-.409h-3.58zm-.308-5.455a.17.17 0 0 1 .171-.17h3.853a.17.17 0 0 1 .17.17v3.853a.17.17 0 0 1-.17.17h-3.853a.17.17 0 0 1-.171-.17V1.988zm.171 4.841h3.853a.989.989 0 0 0 .988-.988V1.988A.989.989 0 0 0 17.654 1h-3.853a.99.99 0 0 0-.989.988v3.853a.99.99 0 0 0 .989.988zM1.051 19h17.898v-.818H1.051V19zm7.568-2.25h2.762v-5.268H8.619v5.268zm-.409.818h3.58a.409.409 0 0 0 .409-.409v-6.085a.409.409 0 0 0-.409-.409H8.21a.409.409 0 0 0-.409.409v6.085a.41.41 0 0 0 .409.409zm-5.318-.818h2.762V9.693H2.892v7.057zm-.409.818h3.58a.409.409 0 0 0 .409-.409V9.284a.409.409 0 0 0-.409-.409h-3.58a.409.409 0 0 0-.409.409v7.875c0 .226.183.409.409.409z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#b0084d',
      }}
    />
  </svg>
);

export default SvgComponent;
