import { Box, Link, ListItem, UnorderedList, Text, VStack } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import { FC } from 'react';

import { HELP_AND_GUIDE_NODE } from '../../types';

type Props = {
  data: Partial<HELP_AND_GUIDE_NODE>[];
};

export const HelpAndGuide: FC<Props> = ({ data }) => {
  const title = 'Help And Guide';
  return (
    <VStack
      px={4}
      pt={4}
      alignItems="flex-start"
      gap={4}
      background="container.background"
      overflowY="auto"
    >
      <Text variant="h1">{title}</Text>
      {data.map((guide, i) => {
        if (guide.type === 'help') {
          return (
            <Box key={`guide${i}`}>
              {!!guide.title && (
                <Text variant="h4_800" pb={2}>
                  {guide.title}
                </Text>
              )}
              {!!guide.desc &&
                guide.desc.map((tip, index) => (
                  <Text variant="body" key={`desc-${index}`}>
                    {tip}
                  </Text>
                ))}
              {!!guide.note && (
                <Box mt={2}>
                  <Text variant="body_800" as="span">
                    {guide.note.heading}
                  </Text>
                  <Text variant="body" as="span">
                    &nbsp;&nbsp;{guide.note.desc}
                  </Text>
                  {guide.note.points && (
                    <UnorderedList>
                      {guide.note.points.map((tip, index) => (
                        <ListItem ml={4} key={index}>
                          <Text variant="body">{tip}</Text>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  )}
                </Box>
              )}
            </Box>
          );
        } else if (guide.type === 'tips') {
          return (
            <Box key={`guide${i}`}>
              <Text variant="h4_800">{guide.title}</Text>
              <UnorderedList>
                {!!guide.desc &&
                  guide.desc.map((tip, index) => (
                    <ListItem ml={4} key={index}>
                      <Text variant="body">{tip}</Text>
                    </ListItem>
                  ))}
              </UnorderedList>
            </Box>
          );
        } else {
          return (
            <Box key={`guide${i}`}>
              {!!guide.link &&
                guide.link.map(({ url, label }, index) => (
                  <Link
                    href={url}
                    color={THEME_COLORS.brand.blue}
                    key={`link-${index}`}
                    fontSize={12}
                    fontStyle="italic"
                    textDecoration="underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    mb={2}
                  >
                    {label}
                  </Link>
                ))}
              {!!guide.desc &&
                guide.desc.map((tip, index) => (
                  <Text key={index} variant="hint" fontStyle="italic">
                    {tip}
                  </Text>
                ))}
            </Box>
          );
        }
      })}
    </VStack>
  );
};
