import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Circle,
  createStandaloneToast,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { isValidUrl } from 'core';
import { API } from 'core';
import { T_WA_UP_NEW_WEBFORM_CREATED, T_WA_UP_WEBFORM_EDITED } from 'core/const/tracker';
import { exception } from 'core/exception';
import { IAppState } from 'core/interfaces/IAppState';
import { IInuptField, ITags, IWFService } from 'core/interfaces/IWebform';
import WebFormService from 'core/services/service.webform';
import React, { ChangeEvent, Component, useState } from 'react';
import { connect } from 'react-redux';
import Select, { components, OnChangeValue, StylesConfig, GroupBase } from 'react-select';
import { AppTracker } from 'shared/analytics/tracker';
import { AppConfig } from 'shared/app.config';
import { AlertDialogComponent } from 'views/shared/components';
import { urlValidator } from 'views/main/organization/statuspage/modify.modal';
import equal from 'fast-deep-equal/es6/react';
import { Options } from '..';
import { ProfileIcon, TeamIcon, SquadIcon } from '../../../../../icons';
import { ProgressSteps } from '../StepsWithCirclesAndText/ProgressSteps';
import { CopyToClipboard } from './copytoclipboard';
import { THEME_COLORS } from 'library/theme/colors';
import { Tooltip, usernameTooltipLabel } from 'library/atoms';
import { truncate } from 'core/helpers/stringUtils';

interface IProps extends Pick<IAppState, 'organization'> {
  webformId: number;
  onclose: () => void;
  ownerOptions: Options[];
  serviceOptions: Options[];
}
interface IState {
  componentState: 'new' | 'edit';
  isOpen: boolean;
  sliderValue: number;
  modal_title: string;
  form_name: string;
  is_cname: boolean;
  host_name: string;
  public_url: string;
  tags: ITags[];
  form_owner_id: string;
  form_owner_type: string;
  form_owner_name: string;
  is_all_services: boolean;
  services: IWFService[];
  input_field: IInuptField[];
  header: string;
  title: string;
  description: string;
  logo_url?: string;
  footer_text: string;
  footer_link: string;
  email_on: string[];
  ownerOptions: Options[];
  serviceOptions: Options[];
  isExistingName: boolean;
  errors: any;
  isLoading: boolean;
  originalWebform?: IOriginalWebform | null;
}
interface IOriginalWebform
  extends Pick<
    IState,
    | 'form_name'
    | 'is_cname'
    | 'host_name'
    | 'tags'
    | 'public_url'
    | 'form_owner_id'
    | 'form_owner_type'
    | 'form_owner_name'
    | 'is_all_services'
    | 'services'
    | 'input_field'
    | 'header'
    | 'title'
    | 'description'
    | 'logo_url'
    | 'footer_text'
    | 'footer_link'
    | 'email_on'
  > {}

const toast = createStandaloneToast();
const validLabel = new RegExp('^[a-zA-Z0-9_]+$');

class ModifyWebformsModal extends Component<IProps, IState> {
  public fileRefs: any = null;
  public WFService: WebFormService = new WebFormService();

  constructor(props: IProps) {
    super(props);
    const isInEdit = this.props.webformId > 0;
    this.state = {
      componentState: isInEdit ? 'edit' : 'new',
      isOpen: false,
      sliderValue: 0,
      modal_title: '',
      form_name: '',
      is_cname: false,
      host_name: '',
      public_url: `${AppConfig.webforms_url}/${this.props.organization.currentOrg.o?.slug}/`,
      tags: [{ key: '', value: '' }],
      form_owner_id: this.props.organization.currentUser.u?.id || '',
      form_owner_type: 'user',
      form_owner_name: '',
      is_all_services: false,
      services: [],
      input_field: [{ label: '', options: [''] }],
      header: '',
      title: '',
      description: '',
      logo_url: '',
      footer_text: '',
      footer_link: '',
      email_on: [],
      ownerOptions: this.props.ownerOptions,
      serviceOptions: this.props.serviceOptions,
      isExistingName: false,
      errors: {},
      isLoading: false,
      originalWebform: null,
    };
  }

  public async componentDidMount() {
    const webformId = this.props.webformId;
    if (webformId > 0) {
      this.setState({ isLoading: true });
      await this.getWebformDetail();
    }
  }

  public getWebformDetail = async () => {
    try {
      const response = await this.WFService.getWebForm(this.props.webformId);
      const responseWebform = response.data.data;
      const webformData = {
        form_name: responseWebform.name,
        public_url: responseWebform.public_url,
        is_cname: responseWebform.is_cname,
        host_name: responseWebform.host_name,
        tags: responseWebform.tags
          ? Object.keys(responseWebform.tags).map(_ => {
              return { key: _, value: responseWebform.tags ? responseWebform.tags[_] : '' };
            })
          : [],
        form_owner_id: responseWebform.form_owner_id,
        form_owner_type: responseWebform.form_owner_type,
        form_owner_name: responseWebform.form_owner_name,
        is_all_services: responseWebform.is_all_services,
        services: responseWebform.services || [],
        input_field: responseWebform.input_field || [],
        header: responseWebform.header,
        title: responseWebform.title,
        description: responseWebform.description,
        logo_url: responseWebform.logo_url,
        footer_text: responseWebform.footer_text,
        footer_link: responseWebform.footer_link,
        email_on: responseWebform.email_on,
      };
      this.setState({
        ...webformData,
        originalWebform: webformData,
        serviceOptions: this.state.serviceOptions.map((obj, i) => {
          responseWebform.services &&
            responseWebform.services.forEach(s => {
              if (obj.value == s.service_id) {
                obj.type = s.alias;
              }
            });
          return obj;
        }),
        isLoading: false,
      });
    } catch (error: any) {
      this.setState({ isLoading: false });
      const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
      toast.closeAll();
      toast({ title, status: 'error', variant: 'subtle', isClosable: true });
      exception.handle('E_GET_SINGLE_WEBFORM', error);
    }
  };

  public updateTags = (index: number, event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const newTags = [...this.state.tags];
    if (type == 'key') newTags[index]['key'] = event.target.value;
    if (type == 'value') newTags[index]['value'] = event.target.value;
    this.setState({ tags: newTags });
  };

  public handleOwnerChange = (newValue: OnChangeValue<Options, false>) => {
    const selectedOption = this.state.ownerOptions.find(owner => {
      return newValue?.value === owner.value;
    });
    this.setState({
      form_owner_id: selectedOption ? selectedOption.value : '',
      form_owner_type: selectedOption ? selectedOption.type : '',
      form_owner_name: selectedOption ? selectedOption.label : '',
    });
  };

  public handleServiceChange = (newValue: OnChangeValue<Options, true>) => {
    const service = newValue.map(obj => {
      return {
        service_id: obj.value,
        name: obj.label,
        alias: obj.type,
        webform_id: this.props.webformId,
      };
    });

    this.setState({ services: service });
  };

  public handleAllService = () => {
    const service = this.state.serviceOptions.map(obj => {
      return {
        service_id: obj.value,
        name: obj.label,
        alias: obj.type,
        webform_id: this.props.webformId,
      };
    });
    if (!this.state.is_all_services) {
      this.setState({ is_all_services: !this.state.is_all_services, services: service });
    } else {
      this.setState({ is_all_services: !this.state.is_all_services, services: [] });
    }
  };

  public updateServiceAlias = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      services: this.state.services.map((obj, i) => {
        if (i === index) {
          return { ...obj, alias: event.target.value };
        }
        return obj;
      }),
      serviceOptions: this.state.serviceOptions.map((obj, i) => {
        if (i === index) {
          return { ...obj, type: event.target.value };
        }
        return obj;
      }),
    });
  };

  public removeService = (index: number) => {
    this.setState({
      services: this.state.services.filter((obj, i) => {
        if (i !== index) {
          return obj;
        }
      }),
    });
    if (this.state.is_all_services) {
      this.setState({ is_all_services: false });
    }
  };

  public updateInputField = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    type: string,
    optionsIndex: number,
  ) => {
    const newInputField = [...this.state.input_field];
    if (type == 'label') newInputField[index].label = event.target.value;
    if (type == 'options') {
      const options = newInputField[index].options.map(i => i);
      options[optionsIndex] = event.target.value;
      newInputField[index].options = options;
    }
    this.setState({ input_field: newInputField });
  };

  public changeEmailon = (value: string) => {
    if (this.state.email_on.includes(value)) {
      const emailon = this.state.email_on.filter(item => {
        return item !== value;
      });
      this.setState({ email_on: emailon });
    } else {
      const emailon = this.state.email_on;
      emailon.push(value);
      this.setState({ email_on: emailon });
    }
  };

  public openUploadFile = () => {
    if (!this.fileRefs) return;

    this.fileRefs.click();
  };

  public deleteLogo = () => {
    this.setState({ logo_url: '' });
  };

  public onLogoUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    try {
      const logo = event.target.files[0];
      const fileExtensions = logo.type.split('/')[1];

      const allowedExtensions = /(jpg|jpeg|png|gif)$/i;

      if (logo.size / 1025 > 70) {
        alert('logo should be less than 70kb');
        return;
      }

      if (!allowedExtensions.exec(fileExtensions)) {
        alert('Please upload file having extensions .jpeg/.jpg/.png/.gif only.');
        return;
      }

      (file => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.setState({ logo_url: reader.result as any });
        };
        reader.onerror = error => {
          console.log('Error: ', error);
        };
      })(logo);
    } catch (err: any) {
      console.log(err);
    }
  };

  public removeInputField = (index: number) => {
    const inputField = this.state.input_field.filter((_, i) => {
      return i != index;
    });
    this.setState({ input_field: inputField });
  };

  public step1 = () => {
    const { isInvalid, error } = this.validateStep(1);
    this.setState({ errors: error });
    !isInvalid && this.setState({ sliderValue: 1 });
  };

  public step2 = () => {
    const { isInvalid, error } = this.validateStep(2);
    !isInvalid && this.setState({ sliderValue: 2 });
    this.setState({ errors: error });
  };

  public step3 = () => {
    const { isInvalid, error } = this.validateStep(3);
    !isInvalid && this.setState({ sliderValue: 3 });
    this.setState({ errors: error });
  };

  public createWebform = async () => {
    const webformId = this.props.webformId;
    const tags_: { [k: string]: any } = {};
    const state = this.state;
    state.tags.forEach(({ key, value }) => {
      tags_[key] = value;
    });

    const { isInvalid, error } = this.validateStep(4);
    this.setState({ errors: error });
    if (isInvalid) {
      return;
    }

    const payload = {
      org_id: API.config.organizationId,
      owner_id: this.props.organization.selectedTeam.teamId,
      name: state.form_name,
      public_url: state.public_url,
      is_cname: state.is_cname,
      host_name: state.host_name,
      tags: tags_,
      form_owner_id: state.form_owner_id,
      form_owner_type: state.form_owner_type,
      form_owner_name: state.form_owner_name,
      is_all_services: state.is_all_services,
      services: state.services,
      input_field: state.input_field,
      header: state.header,
      title: state.title,
      description: state.description,
      logo_url: state.logo_url,
      footer_text: state.footer_text,
      footer_link: state.footer_link,
      email_on: state.email_on,
    };

    if (webformId) {
      try {
        await this.WFService.updateWebform(webformId, payload);
        this.props.onclose();
        toast({
          title: 'Webform Updated',
          description: 'Webform updated successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } catch (err: any) {
        const title = err?.response?.data?.meta?.error_message || 'error connecting to server';
        toast.closeAll();
        toast({ title, status: 'error', variant: 'subtle', isClosable: true });
        exception.handle('E_UPDATE_WEBFORM', err);
      }
      AppTracker.track(T_WA_UP_WEBFORM_EDITED);
    } else {
      try {
        await this.WFService.createWebform(payload);
        this.props.onclose();
        toast({
          title: 'Webform Created',
          description: 'Webform created successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } catch (err: any) {
        const title = err?.response?.data?.meta?.error_message || 'error connecting to server';
        toast.closeAll();
        toast({ title, status: 'error', variant: 'subtle', isClosable: true });
        exception.handle('E_CREATE_WEBFORM', err);
      }
      AppTracker.track(T_WA_UP_NEW_WEBFORM_CREATED);
    }
  };

  public validateStep(step: number): { isInvalid: boolean; error: any } {
    let isInvalid = false;
    const error: any = {};

    switch (step) {
      case 1:
        if (!this.state.form_name) {
          isInvalid = true;
          error.formName = true;
        }
        if (this.state.form_name.includes('-')) {
          isInvalid = true;
          error.hyphen = true;
        }
        if (this.state.isExistingName) {
          isInvalid = true;
        }
        if (
          this.state.is_cname &&
          (!this.state.host_name || urlValidator(this.state.host_name) !== '')
        ) {
          isInvalid = true;
          error.hostName = true;
        }
        this.state.tags.map(obj => {
          if (obj.key.length != 0 && !obj.value) {
            isInvalid = true;
          }
          if (obj.value.length != 0 && !obj.key) {
            isInvalid = true;
          }
        });
        return { isInvalid: isInvalid, error: error };
      case 2:
        if (this.state.form_owner_id === '') {
          isInvalid = true;
          error.owner = true;
        }
        if (this.state.services.length === 0) {
          isInvalid = true;
          error.service = true;
        }
        return { isInvalid: isInvalid, error: error };
      case 3:
        if (this.state.input_field.length) {
          this.state.input_field.forEach((obj, index) => {
            if (obj.label != '' && !validLabel.test(obj.label)) {
              isInvalid = true;
              error.IFLabelIndex = index;
              error.IFInvalidLable = true;
            }
            if (obj.label.length != 0 && obj.options.includes('')) {
              isInvalid = true;
              error.IFOptions = true;
              error.IFOptionsIndex = index;
            }
            if (!obj.options.includes('') && !obj.label) {
              isInvalid = true;
              error.IFLabelIndex = index;
              error.IFLabel = true;
            }
            if (obj.label === '') {
              isInvalid = true;
              error.IFLabelIndex = index;
              error.IFLabel = true;
            }
          });
        }
        return { isInvalid: isInvalid, error: error };
      case 4:
        if (!this.state.header) {
          isInvalid = true;
          error.formHeader = true;
        }
        if (!this.state.title) {
          isInvalid = true;
          error.formTitle = true;
        }
        if (this.state.footer_link && !isValidUrl(this.state.footer_link)) {
          isInvalid = true;
          error.footer_link = true;
        }
        return { isInvalid: isInvalid, error: error };
      default:
        return { isInvalid: isInvalid, error: error };
    }
  }

  public checkExistingName = async (name: string) => {
    try {
      const { data } = await this.WFService.fetchExistingWebformName(name);
      if (data.data) {
        this.setState({ isExistingName: true });
      }
    } catch (err: any) {
      console.log(err);
      this.setState({ isExistingName: false });
    }
  };

  public hasChanges = () => {
    if (!this.state.originalWebform) return false;
    const original = this.state.originalWebform;
    const current = {
      form_name: this.state.form_name,
      public_url: this.state.public_url,
      is_cname: this.state.is_cname,
      host_name: this.state.host_name,
      tags: this.state.tags,
      form_owner_id: this.state.form_owner_id,
      form_owner_type: this.state.form_owner_type,
      form_owner_name: this.state.form_owner_name,
      is_all_services: this.state.is_all_services,
      services: this.state.services,
      input_field: this.state.input_field,
      header: this.state.header,
      title: this.state.title,
      description: this.state.description,
      logo_url: this.state.logo_url,
      footer_text: this.state.footer_text,
      footer_link: this.state.footer_link,
      email_on: this.state.email_on,
    };

    return !equal(original, current);
  };

  public openCloseModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  public setSliderValueOnClick = (value: number) => {
    const { isInvalid, error } = this.validateStep(
      this.state.sliderValue < 2 ? (this.state.sliderValue < 1 ? 1 : 2) : 3,
    );
    !isInvalid && this.setState({ sliderValue: value });
    this.setState({ errors: error });
  };

  render() {
    const { webformId, onclose } = this.props;
    const {
      sliderValue,
      isOpen,
      form_name,
      public_url,
      is_cname,
      host_name,
      tags,
      form_owner_id,
      is_all_services,
      services,
      input_field,
      header,
      title,
      description,
      logo_url,
      footer_text,
      footer_link,
      email_on,
      ownerOptions,
      serviceOptions,
      isExistingName,
      errors,
      isLoading,
    } = this.state;

    const { Option } = components;
    const IconOption = (props: any) => (
      <Option {...props}>
        <VStack alignItems={'left'} spacing={0}>
          <HStack>
            <Icon
              mr={2}
              as={
                props.data.type === 'user'
                  ? ProfileIcon
                  : props.data.type === 'squad'
                  ? SquadIcon
                  : TeamIcon
              }
            />
            <Text>{props.data.label}</Text>
          </HStack>
          {props.data.username && (
            <Text style={{ marginLeft: '32px', color: THEME_COLORS.secondary[1200] }}>
              {props.data.username}
            </Text>
          )}
        </VStack>
      </Option>
    );

    const styles: StylesConfig<Options, false, GroupBase<Options>> = {
      input: (provided, { selectProps: { inputValue, isMulti } }) => ({
        ...provided,
        ...(!(inputValue || isMulti) ? { position: 'absolute' } : {}),
      }),
    };

    const SingleValue = (props: any) => {
      const labelWithoutUsername = props.data.label.replace(` (${props.data.username})`, '');
      return (
        <components.SingleValue {...props}>
          <Tooltip
            label={usernameTooltipLabel(labelWithoutUsername, props.data.username)}
            isDisabled={!props.data.username}
            placement="top"
          >
            <Text>
              {truncate(labelWithoutUsername, 30)}
              {props.data.username && ` (${truncate(props.data.username, 30)})`}
            </Text>
          </Tooltip>
        </components.SingleValue>
      );
    };

    const getEntityOwnerValue = (id: any, options: any) => {
      const entity = options.find((u: any) => u.value === id);
      if (!entity) {
        const currentUser = this.props.organization.currentUser.u;
        const usernameLabel = entity?.username
          ? `${entity.label} (${entity.username})`
          : `${entity.label}`;
        return {
          label: usernameLabel,
          value: currentUser?.id,
          type: 'user',
          username: currentUser?.username_for_display,
        };
      }
      const usernameLabel = entity?.username
        ? `${entity.label} (${entity.username})`
        : `${entity.label}`;
      return {
        label: usernameLabel,
        value: entity?.id,
        type: entity?.type,
        username: entity?.username,
      };
    };

    return (
      <Modal
        isOpen={true}
        onClose={() => {
          if (this.hasChanges()) this.openCloseModal();
          else onclose();
        }}
        closeOnOverlayClick={false}
        size={'4xl'}
      >
        <ModalOverlay />
        <ModalContent h="auto" w="1500px" padding={'10px'}>
          <ModalHeader>{webformId > 0 ? 'Edit ' : 'Create '}Webform</ModalHeader>
          <ModalCloseButton />
          {isLoading ? (
            <ModalBody textAlign={'center'}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </ModalBody>
          ) : (
            <ModalBody>
              <ProgressSteps
                onChangeStep={v => this.setState({ sliderValue: v })}
                setSliderValue={this.setSliderValueOnClick}
                sliderValue={sliderValue}
              />
              {sliderValue === 0 && (
                <Box mt={5} mb={5} w="container.md" alignItems={'center'}>
                  <FormControl
                    isInvalid={(!form_name && errors.formName) || isExistingName || errors.hyphen}
                    mb={5}
                    isRequired
                  >
                    <FormLabel htmlFor="formName" color={THEME_COLORS.secondary[1500]}>
                      Form Name
                    </FormLabel>
                    <Input
                      id="formName"
                      onBlur={e => {
                        this.setState({
                          public_url:
                            `${AppConfig.webforms_url}/${this.props.organization.currentOrg.o?.slug}/` +
                            e.target.value.replace(/ /g, '-'),
                        });
                        this.checkExistingName(e.target.value);
                      }}
                      value={form_name}
                      onChange={e => {
                        this.setState({
                          form_name: e.target.value,
                        });
                      }}
                      placeholder="Enter Form Name"
                      width={'550px'}
                    />
                    {errors.formName && !isExistingName && (
                      <FormErrorMessage> Form Name Required</FormErrorMessage>
                    )}
                    {isExistingName && (
                      <FormErrorMessage> Form Name Already Exists</FormErrorMessage>
                    )}
                    {errors.hyphen && (
                      <FormErrorMessage>Hyphens (-) are not allowed</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={false} mb={5}>
                    <FormLabel htmlFor="formUrl" color={THEME_COLORS.secondary[1500]}>
                      Form Public URL
                    </FormLabel>
                    <Flex
                      align={'start'}
                      justify={'start'}
                      direction={'row'}
                      alignItems={'baseline'}
                    >
                      <Input
                        id="formUrl"
                        value={public_url}
                        width={'550px'}
                        readOnly={true}
                        _focus={{ outline: 'none' }}
                      />
                      <CopyToClipboard text={public_url} />
                    </Flex>
                  </FormControl>
                  <FormControl mb={5}>
                    <Checkbox
                      size="lg"
                      fontSize={16}
                      fontWeight={400}
                      isChecked={is_cname}
                      onChange={() => this.setState({ is_cname: !this.state.is_cname })}
                      isInvalid={false}
                    >
                      Set up a Custom Domain instead
                    </Checkbox>
                  </FormControl>
                  {is_cname && (
                    <>
                      <FormControl isInvalid={errors.hostName} mb={5} ml={10} isRequired>
                        <FormLabel htmlFor="formName" color={THEME_COLORS.secondary[1500]}>
                          Form Hostname
                        </FormLabel>
                        <Input
                          id="hostname"
                          value={host_name}
                          onChange={e => {
                            this.setState({ host_name: e.target.value });
                          }}
                          placeholder="Enter Hostname"
                          width={'550px'}
                        />
                        {errors.hostName &&
                          (!host_name ? (
                            <FormErrorMessage> Hostname Required</FormErrorMessage>
                          ) : (
                            urlValidator(this.state.host_name) !== '' && (
                              <FormErrorMessage>
                                {urlValidator(this.state.host_name)}
                              </FormErrorMessage>
                            )
                          ))}
                      </FormControl>
                      <FormControl isInvalid={false} mb={5} ml={10}>
                        <FormLabel htmlFor="cname" color={THEME_COLORS.secondary[1500]}>
                          CNAME
                        </FormLabel>
                        <Flex
                          align={'start'}
                          justify={'start'}
                          direction={'row'}
                          alignItems={'baseline'}
                        >
                          <Input
                            id="cname"
                            value={AppConfig.webforms_cname}
                            width={'550px'}
                            readOnly={true}
                            _focus={{ outline: 'none' }}
                          />
                          <CopyToClipboard text={AppConfig.webforms_cname} />
                        </Flex>
                      </FormControl>
                    </>
                  )}
                  <FormLabel htmlFor="tags" color={THEME_COLORS.secondary[1500]}>
                    Tags
                  </FormLabel>
                  <Flex align={'start'} justify={'start'} direction={'row'}>
                    <Stack width="xl" spacing={3} mr={5}>
                      {tags.map((obj, index) => {
                        return (
                          <FormControl key={index} isInvalid={obj.value.length != 0 && !obj.key}>
                            <Input
                              key={index}
                              placeholder="Enter the key here"
                              _placeholder={{ opacity: 1, color: 'gray.500' }}
                              value={obj.key}
                              onChange={event => this.updateTags(index, event, 'key')}
                            />
                          </FormControl>
                        );
                      })}
                    </Stack>
                    <Stack width="xl" spacing={3}>
                      {tags.map((obj, index) => {
                        return (
                          <HStack key={index} alignItems="flex-start">
                            <FormControl key={index} isInvalid={obj.key.length != 0 && !obj.value}>
                              <Input
                                key={index}
                                placeholder="Enter the value here"
                                _placeholder={{ opacity: 1, color: 'gray.500' }}
                                value={obj.value}
                                onChange={event => this.updateTags(index, event, 'value')}
                              />
                            </FormControl>
                            {
                              <IconButton
                                onClick={e => {
                                  this.setState({
                                    tags: [...tags.filter(_ => _.key !== obj.key)],
                                  });
                                }}
                                fontSize={3}
                                icon={<CloseIcon boxSize={3} />}
                                variant="ghost"
                                aria-label="Delete member"
                              />
                            }
                          </HStack>
                        );
                      })}
                    </Stack>
                  </Flex>
                  <Flex mt={2} width="100%">
                    <Button
                      fontWeight="normal"
                      onClick={() => {
                        this.setState({
                          tags: [...tags, { key: '', value: '' }],
                        });
                      }}
                      leftIcon={<>+</>}
                      variant="unstyled"
                      _focus={{ outline: 'none' }}
                      color={'rgba(15, 97, 221, 1)'}
                    >
                      Add Tag
                    </Button>
                  </Flex>
                </Box>
              )}
              {sliderValue === 1 && (
                <Box mt={5} mb={5} w="container.md">
                  <FormControl isInvalid={!form_owner_id && errors.owner} mb={5} isRequired>
                    <FormLabel htmlFor="owner" color={THEME_COLORS.secondary[1500]}>
                      Form Owner
                    </FormLabel>
                    <Box style={{ margin: '1rem 0' }} w={'80%'}>
                      <Select
                        name={'owner'}
                        id="owner"
                        options={ownerOptions}
                        onChange={this.handleOwnerChange}
                        value={getEntityOwnerValue(form_owner_id, ownerOptions)}
                        components={{ Option: IconOption, SingleValue }}
                        placeholder={'Select User, Squad, Team'}
                        styles={styles}
                      />
                    </Box>
                    {<FormErrorMessage>Owner is required</FormErrorMessage>}
                  </FormControl>
                  <Text fontSize={14} fontWeight={400} mb={3}>
                    Identify the services the reporter will see on the form under affected services
                  </Text>
                  <FormControl isInvalid={!services.length && errors.service} mb={5} isRequired>
                    <FormLabel htmlFor="owner" color={THEME_COLORS.secondary[1500]}>
                      Add Services
                    </FormLabel>
                    <Box style={{ margin: '1rem 0' }} width={'110%'} display={'flex'}>
                      <Box w={'73%'}>
                        <Select
                          name={'service'}
                          id="service"
                          options={serviceOptions}
                          onChange={this.handleServiceChange}
                          value={services.map(s => {
                            return { value: s.service_id, label: s.name, type: s.alias };
                          })}
                          placeholder={'Search Services'}
                          isMulti
                          controlShouldRenderValue={false}
                          closeMenuOnSelect={false}
                          isClearable={false}
                          backspaceRemovesValue={false}
                          components={{ Option: IconOption }}
                          noOptionsMessage={() =>
                            'No Services exist in current Team. Please create a Service first'
                          }
                        />
                      </Box>
                      <Checkbox
                        ml={3}
                        size="lg"
                        fontSize={16}
                        isChecked={is_all_services}
                        onChange={this.handleAllService}
                        isInvalid={false}
                        disabled={!serviceOptions.length}
                      >
                        Select all services
                      </Checkbox>
                    </Box>
                    {<FormErrorMessage>Select atleast one service</FormErrorMessage>}
                  </FormControl>
                  {services.length > 0 && (
                    <Box ml={5}>
                      <Text fontSize={14} fontWeight={500} mb={3}>
                        {services.length} service(s) selected
                      </Text>
                      <Flex align={'start'} justify={'start'} direction={'row'}>
                        <Stack width="100%" spacing={3} mr={5}>
                          {services.map((service, index) => {
                            return (
                              <Stack key={index} direction={'row'} alignItems={'center'}>
                                <Box
                                  key={index}
                                  width={'100%'}
                                  p={4}
                                  borderWidth="1px"
                                  borderRadius={6}
                                  pr={10}
                                >
                                  <FormControl
                                    key={index}
                                    isInvalid={false}
                                    display={'flex'}
                                    alignItems={'center'}
                                  >
                                    <FormLabel
                                      width={'40%'}
                                      fontSize={16}
                                      fontWeight={400}
                                      color={THEME_COLORS.secondary[1500]}
                                      mb={0}
                                    >
                                      {service.name}
                                    </FormLabel>
                                    <Input
                                      key={index}
                                      placeholder="alias"
                                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                                      value={service.alias}
                                      onChange={event => this.updateServiceAlias(index, event)}
                                    />
                                  </FormControl>
                                </Box>
                                <Box>
                                  <Circle
                                    size="10"
                                    bg={'#fef5f6'}
                                    ml={-7}
                                    border={`solid 1px ${THEME_COLORS['brand'].failed}`}
                                    onClick={() => {
                                      this.removeService(index);
                                    }}
                                  >
                                    <DeleteIcon color={'red'} />
                                  </Circle>
                                </Box>
                              </Stack>
                            );
                          })}
                        </Stack>
                      </Flex>
                    </Box>
                  )}
                </Box>
              )}

              {sliderValue === 2 && (
                <Box mt={5} mb={5}>
                  <Text fontSize={14} mb={5}>
                    Specify the Input fields that the reporter will see on the webform and can
                    select one of the options against the label. You can add maximum of 10 labels
                    and upto 10 options for each label.
                  </Text>

                  <Flex align={'start'} justify={'start'} direction={'row'}>
                    <Stack width="100%" spacing={3} mr={5}>
                      {input_field.map((obj, index) => {
                        return (
                          <Stack key={index} direction={'row'} alignItems={'center'}>
                            <Box
                              key={index}
                              width={'100%'}
                              p={4}
                              borderWidth="1px"
                              borderRadius={6}
                              mb={3}
                              pr={10}
                            >
                              <Text mb={3}># Input Field {index + 1}</Text>
                              <FormControl
                                key={index}
                                isInvalid={
                                  errors.IFLabelIndex === index &&
                                  (errors.IFLabel || errors.IFInvalidLable)
                                }
                                display={'flex'}
                                alignItems={'center'}
                                mb={3}
                              >
                                <FormLabel
                                  width={'40%'}
                                  fontSize={16}
                                  fontWeight={400}
                                  color={THEME_COLORS.secondary[1500]}
                                >
                                  Label
                                </FormLabel>
                                <Input
                                  key={index}
                                  placeholder="Enter Key"
                                  _placeholder={{ opacity: 1, color: 'gray.500' }}
                                  value={obj.label}
                                  onChange={event =>
                                    this.updateInputField(index, event, 'label', 0)
                                  }
                                />
                              </FormControl>
                              <FormControl
                                key={index}
                                isInvalid={errors.IFOptions && errors.IFOptionsIndex === index}
                                display={'flex'}
                              >
                                <FormLabel
                                  width={'39%'}
                                  fontSize={16}
                                  fontWeight={400}
                                  color={THEME_COLORS.secondary[1500]}
                                >
                                  Options
                                </FormLabel>
                                <VStack key={index} width={'inherit'} spacing={2} align="stretch">
                                  {obj.options.map((val, i) => {
                                    return (
                                      <InputGroup key={i}>
                                        <Input
                                          placeholder="Enter Value"
                                          _placeholder={{ opacity: 1, color: 'gray.500' }}
                                          value={val}
                                          onChange={event =>
                                            this.updateInputField(index, event, 'options', i)
                                          }
                                        />
                                        {obj.options.length > 1 && (
                                          <InputRightElement
                                            onClick={() => {
                                              const newIF = [...this.state.input_field];
                                              newIF[index].options.splice(i, 1);
                                              this.setState({
                                                input_field: newIF,
                                              });
                                            }}
                                          >
                                            <CloseIcon boxSize={3} />
                                          </InputRightElement>
                                        )}
                                      </InputGroup>
                                    );
                                  })}
                                </VStack>
                              </FormControl>
                              {obj.options.length >= 10 ? (
                                <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
                                  <Text color={'red'} mb={3}>
                                    Options limit reached
                                  </Text>
                                </Box>
                              ) : (
                                <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
                                  <Button
                                    fontWeight="normal"
                                    onClick={() => {
                                      const newIF = [...this.state.input_field];
                                      newIF[index].options.push('');
                                      this.setState({
                                        input_field: newIF,
                                      });
                                    }}
                                    leftIcon={<>+</>}
                                    variant="unstyled"
                                    _focus={{ outline: 'none' }}
                                    color={'rgba(15, 97, 221, 1)'}
                                    borderWidth="1px"
                                    padding={2}
                                  >
                                    Add Options
                                  </Button>
                                </Box>
                              )}
                            </Box>
                            <Box>
                              <Circle
                                size="10"
                                bg={'#fef5f6'}
                                ml={-7}
                                border={`solid 1px ${THEME_COLORS['brand'].failed}`}
                                onClick={() => {
                                  this.removeInputField(index);
                                }}
                              >
                                <DeleteIcon color={'red'} />
                              </Circle>
                            </Box>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Flex>
                  <Box mt={2} width="100%">
                    {errors.IFInvalidLable && (
                      <Text color={'red'} mb={3}>
                        Input Field label can't contain special character other than _
                      </Text>
                    )}
                    {(errors.IFOptions || errors.IFLabel) && (
                      <Text color={'red'} mb={3}>
                        {`Resolve all errors, ${
                          errors.IFOptions ? ' options ' : ' label '
                        } can't be empty`}
                      </Text>
                    )}
                    {input_field.length < 10 && (
                      <Button
                        fontWeight="normal"
                        onClick={() => {
                          this.setState({
                            input_field: [...input_field, { label: '', options: [''] }],
                          });
                        }}
                        leftIcon={<>+</>}
                        variant="unstyled"
                        _focus={{ outline: 'none' }}
                        color={'rgba(15, 97, 221, 1)'}
                        borderWidth="1px"
                        padding={2}
                      >
                        Add Input Field
                      </Button>
                    )}
                  </Box>
                </Box>
              )}
              {sliderValue === 3 && (
                <Box mt={5} mb={5} w="container.md">
                  <FormControl isInvalid={!header && errors.formHeader} mb={5} isRequired>
                    <FormLabel htmlFor="formHeader" color={THEME_COLORS.secondary[1500]}>
                      Form Header
                    </FormLabel>
                    <Input
                      id="formHeader"
                      value={header}
                      onChange={e => {
                        this.setState({ header: e.target.value });
                      }}
                      placeholder="Enter Form Header; Example: Your Company's Name"
                      width={'100%'}
                    />
                    <FormErrorMessage> Form Header Required</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!title && errors.formTitle} mb={5} isRequired>
                    <FormLabel htmlFor="formTitle" color={THEME_COLORS.secondary[1500]}>
                      {`Form Title (Public)`}
                    </FormLabel>
                    <Input
                      id="formTitle"
                      value={title}
                      onChange={e => {
                        this.setState({ title: e.target.value });
                      }}
                      placeholder="Enter Form Title"
                      width={'100%'}
                    />
                    <FormErrorMessage> Form Title Required</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.description} mb={5}>
                    <FormLabel htmlFor="formdescription" color={THEME_COLORS.secondary[1500]}>
                      Form Description
                    </FormLabel>
                    <Textarea
                      id="formdescription"
                      value={description}
                      onChange={e => {
                        this.setState({ description: e.target.value });
                      }}
                      placeholder="Enter Form Description"
                      width={'100%'}
                    />
                  </FormControl>
                  <FormControl isInvalid={errors.logo} mb={5}>
                    <FormLabel htmlFor="formLogo" color={THEME_COLORS.secondary[1500]}>
                      Company Logo
                    </FormLabel>
                    <Stack direction="row">
                      <Box
                        width={'100%'}
                        display={'flex'}
                        p={5}
                        borderWidth="1px"
                        borderRadius={6}
                        alignItems={'center'}
                      >
                        <Image
                          boxSize="100px"
                          objectFit="cover"
                          src={(logo_url as string) || '/assets/statusPageDefaultLogo.png'}
                          alt="company logo"
                        />
                        <Button
                          variant={'ghost'}
                          fontWeight={500}
                          ml={5}
                          color={'rgba(15, 97, 221, 1)'}
                          onClick={() => this.openUploadFile()}
                          _focus={{ outline: 'none' }}
                        >
                          {(logo_url ? 'Change' : 'Upload') + ' Logo'}
                        </Button>
                        {logo_url && (
                          <Button
                            id="upload_company_logo_delete_button"
                            onClick={() => this.deleteLogo()}
                            variant="ghost"
                            ml={5}
                            _focus={{ outline: 'none' }}
                          >
                            Delete Logo
                          </Button>
                        )}
                        <Input
                          type="file"
                          style={{ display: 'none' }}
                          ref={ref => (this.fileRefs = ref)}
                          onChange={this.onLogoUpload}
                          accept=".png, .jpg"
                        />
                      </Box>
                    </Stack>
                    <Text display={'block'} textAlign={'right'}>
                      works best for 150x150
                    </Text>
                    <FormErrorMessage> Company Logo Required</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.footer_text} mb={5}>
                    <FormLabel htmlFor="footerText" color={THEME_COLORS.secondary[1500]}>
                      Footer Text
                    </FormLabel>
                    <Input
                      id="footerText"
                      value={footer_text}
                      onChange={e => {
                        this.setState({ footer_text: e.target.value });
                      }}
                      placeholder="Enter Footer Text; Example: You can add a call out to your Status Page"
                      width={'100%'}
                    />
                    <FormErrorMessage> Footer text Required</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.footer_link} mb={5}>
                    <FormLabel htmlFor="footerLink" color={THEME_COLORS.secondary[1500]}>
                      Footer Link
                    </FormLabel>
                    <Input
                      id="footerLink"
                      value={footer_link}
                      onChange={e => {
                        this.setState({ footer_link: e.target.value });
                      }}
                      placeholder="Enter Footer Link; Example: You can link your Status Page"
                      width={'100%'}
                    />
                    <FormErrorMessage> Invalid Footer Link </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.email_on} mb={5}>
                    <FormLabel htmlFor="footerLink" color={THEME_COLORS.secondary[1500]}>
                      Send email notifications to incident reported for
                    </FormLabel>
                    <Stack spacing={5} direction="row">
                      <Checkbox
                        value={'triggered'}
                        isChecked={email_on.includes('triggered')}
                        onChange={e => {
                          this.changeEmailon(e.target.value);
                        }}
                      >
                        Trigger
                      </Checkbox>
                      <Checkbox
                        value={'acknowledged'}
                        isChecked={email_on.includes('acknowledged')}
                        onChange={e => {
                          this.changeEmailon(e.target.value);
                        }}
                      >
                        Acknowlegement
                      </Checkbox>
                      <Checkbox
                        value={'resolved'}
                        isChecked={email_on.includes('resolved')}
                        onChange={e => {
                          this.changeEmailon(e.target.value);
                        }}
                      >
                        Resolution
                      </Checkbox>
                    </Stack>
                  </FormControl>
                </Box>
              )}
            </ModalBody>
          )}

          <ModalFooter display={'flex-start'}>
            {sliderValue != 0 && (
              <Button
                bgColor={'rgba(15, 97, 221, 1)'}
                color={'white'}
                mr={3}
                onClick={() => {
                  return sliderValue > 1
                    ? sliderValue > 2
                      ? this.setState({ sliderValue: 2 })
                      : this.setState({ sliderValue: 1 })
                    : this.setState({ sliderValue: 0 });
                }}
                _focus={{ outline: 'none' }}
              >
                Previous
              </Button>
            )}
            <Button
              bgColor={'rgba(15, 97, 221, 1)'}
              color={'white'}
              mr={3}
              onClick={() => {
                return sliderValue < 3
                  ? sliderValue < 2
                    ? sliderValue < 1
                      ? this.step1()
                      : this.step2()
                    : this.step3()
                  : this.createWebform();
              }}
              _focus={{ outline: 'none' }}
            >
              {sliderValue == 3 ? (this.props.webformId ? 'Update' : 'Finish') : 'Save & Next'}
            </Button>
          </ModalFooter>
        </ModalContent>
        <AlertDialogComponent
          isOpen={isOpen}
          onClose={this.openCloseModal}
          callbackFn={() => {
            onclose();
          }}
          msg={`Are you sure? You want to discard the changes and go back.`}
          title={''}
          isDelete={false}
        />
      </Modal>
    );
  }
}

export default connect((state: IAppState) => ({
  organization: state.organization,
}))(ModifyWebformsModal);
