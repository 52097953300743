import Axios from 'axios';
import { API } from 'core';
import { useQuery } from 'react-query';

import { queryKeys, reactQueryConfig } from '../contants/service.contant';
import { reactQueryConfigError } from '../helpers/helper.service';

export interface IServiceOwner {
  user_id: string;
  role_ids: string[];
  abilities: Record<string, string>;
}

const getServiceOwners = (teamId: string | null) => async () => {
  const { data } = await Axios.get<{ data: { suggestions: IServiceOwner[] } }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/teams/${teamId}/service-owner-suggestions`,
  );
  return data.data;
};

const useServiceOwnerQuery = (teamId: string | null) => {
  return useQuery([queryKeys.SERVICEOWNER, teamId], getServiceOwners(teamId), {
    ...reactQueryConfig,
    enabled: !!teamId,
    select: res => res.suggestions,
    staleTime: 10 * 1000,
    onError: err => console.error(err),
  });
};

export { useServiceOwnerQuery };
