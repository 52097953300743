import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
interface IProps {
  label: string;
  width?: string | 'max-content';
  height?: string | 'max-content';
  offset?: {
    top?: '-200%' | string;
    left?: '0' | string;
  };
  padding?: string | '8px';
  delay?: number | '100ms';
  background?: string | 'theme.shades.whiteSmoke';
  color?: string | 'theme.shades.cement';
  disabled?: boolean;
}

const Text = styled.span`
  color: ${props => props.color || props.theme.shades.cement};
  background-color: ${props => props.background || props.theme.shades.whiteSmoke};
  font-family: ${props => props.theme.fontFamily};
  margin-left: ${(props: IProps) => props.offset?.left || '0'};
  margin-top: ${(props: IProps) => props.offset?.top || '-25px'};
  width: ${(props: IProps) => props.width || 'max-content'};
  height: ${(props: IProps) => props.height || 'max-content'};
  padding: ${(props: IProps) => props.padding || '8px'};
  box-shadow: ${props => props.theme.shadow.default};
  transition: visibility 0.17s, opacity 0.17s ease;
  transition-delay: ${(props: IProps) => props.delay || 100}ms;
  visibility: hidden;
  opacity: 0;
  text-align: left;
  border-radius: 3px;
  font-size: 12px;
  position: absolute;
  z-index: 999;
`;

const Container = styled.div<{ disabled?: boolean }>`
  display: inline-block;
  position: relative;

  &:hover {
    & > ${Text} {
      display: ${props => (props.disabled ? 'none' : 'block')};
      visibility: visible;
      opacity: 1;
    }
  }
`;

const Tooltip: FunctionComponent<IProps> = props => (
  <Container disabled={props.disabled}>
    {props.children}
    <Text {...props}>{props.label}</Text>
  </Container>
);

/** @component */
export default Tooltip;
