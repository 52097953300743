import { TASK_RUNBOOK_SPLIT_WORDS } from './renders/tasksAndRunbook/taskAndRunbook.constants';

export const linkStyles = {
  color: '#3D6DD8',
  cursor: 'pointer',
};

export const APTA_ACTIONS = ['marked_as_transient_by_squadcast', 'apta_violated'];
export const IAG_ACTIONS = ['iag_vote_down', 'iag_vote_up'];
export const ADDITIONAL_RESPONDERS_ACTIONS = [
  'additional_responders_added',
  'additional_responders_removed',
];

export const COMMUNICATION_SPLIT_WORDS = {
  communication_card_created: ' created ',
  communication_card_updated: ' updated ',
  communication_card_deleted: ' removed ',
  slack_channel_added: ' created ',
  slack_channel_archived: ' archived ',
};

export const COMMUNICATION_LOG_TYPES = Object.keys(COMMUNICATION_SPLIT_WORDS);

export const MANUAL_MERGE_PARENT_SPLIT_WORDS = {
  manually_merged_incidents_parent: ' manually merged ',
  manually_unmerged_incident_parent: ' unmerged the incident ',
};

export const MANUAL_MERGE_PARENT_LOG_TYPES = Object.keys(MANUAL_MERGE_PARENT_SPLIT_WORDS);

export const MANUAL_MERGE_CHILD_SPLIT_WORDS = {
  manually_merged_incidents_child: ' merged this incident to ',
  manually_unmerged_incident_child: ' unmerged the incident from ',
};

export const MANUAL_MERGE_CHILD_LOG_TYPES = Object.keys(MANUAL_MERGE_CHILD_SPLIT_WORDS);

export const SPLIT_WORD_MAP = {
  note_starred: ' starred ',
  incident_starred: ' starred ',
  workflow_triggered: 'triggered',
  ...COMMUNICATION_SPLIT_WORDS,
  ...TASK_RUNBOOK_SPLIT_WORDS,
  ...MANUAL_MERGE_PARENT_SPLIT_WORDS,
  ...MANUAL_MERGE_CHILD_SPLIT_WORDS,
};

export const pastIncidentsDefaultSortState = {
  id: 'incident_activity',
  desc: true,
};

export const pastIncidentsListParametersInitialState = {
  activeDate: '',
  sortingState: pastIncidentsDefaultSortState,
  pageLimit: 10,
  pageOffset: 0,
  maxPages: 1,
  cursorMeta: {
    page_limit: 10,
    page_offset: 0,
    total_count: 0,
  },
};
export const REMAINING_ACTIONTYPE_MAP = {
  note_starred: ' starred ',
  incident_starred: ' starred ',
  workflow_triggered: 'triggered',
  ...COMMUNICATION_SPLIT_WORDS,
  ...TASK_RUNBOOK_SPLIT_WORDS,
};
export const AUTO_GROUP_TAB_NAME = 'auto-grouped';
export const truncuateTextLength = 25;

export const MSTEAMS_MEETING_LINK_LOG_ACTION_TYPE = 'msteams_meeting_link';
