import React, { useEffect, useState } from 'react';
import { Input, Box, RadioGroup, Radio } from '@chakra-ui/react';
import { useScheduleActionsContext, TDeleteRotationType } from './context';

const DeleteRotationSelection = () => {
  const [activeSelection, setActiveSelection] = useState<TDeleteRotationType>('end');
  const { onSelectDeleteType } = useScheduleActionsContext();

  useEffect(() => {
    onSelectDeleteType(activeSelection);
  }, []);

  return (
    <Box mt={5}>
      <RadioGroup
        name="changeSelection"
        value={activeSelection}
        onChange={value => {
          onSelectDeleteType(value as TDeleteRotationType);
          setActiveSelection(value as TDeleteRotationType);
        }}
      >
        <Radio value="entire" checked mr={5}>
          {' '}
          Delete the entire rotation
        </Radio>
        <Radio value="end" checked>
          {' '}
          End the rotation
        </Radio>
      </RadioGroup>
    </Box>
  );
};

export default DeleteRotationSelection;
