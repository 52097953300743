import React from 'react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { ChakraProvider, Flex, FormControl, Switch, Tooltip } from '@chakra-ui/react';
import libraryTheme from 'library/theme';
import { CrownSmallIcon } from 'icons';
import { BillingService } from 'core';
import { useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { Text } from 'library/atoms/Text';

interface IProps {
  is_weekly_reports_enabled: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}

export default function ReceiveWeeklyStats({ is_weekly_reports_enabled, handleChange }: IProps) {
  const organization = useSelector((state: IAppState) => state.organization);
  const isFeatureAvailable = BillingService.getRule({ organization }, 'weekly-reports');

  return (
    <ChakraProvider theme={libraryTheme}>
      <Text
        variant="p"
        padding="26px 23px 28px 23px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        lineHeight={1.2}
        style={{
          fontSize: '18px',
          fontWeight: 600,
          borderBottom: '1px solid var(--shades-smoke)',
        }}
      >
        <Flex alignItems="center" position="relative" width="100%">
          <span>Receive Weekly Team Stats</span>
          <Tooltip
            padding="8px"
            width="200px"
            label="Get insights on each of your teams' activities and performance delivered to your email every Monday."
          >
            <InfoOutlineIcon h={15} ml={1} />
          </Tooltip>
          {!isFeatureAvailable && (
            <CrownSmallIcon
              style={{
                height: 18,
                width: 18,
                marginLeft: 3,
              }}
            />
          )}
        </Flex>
        <FormControl width="min-content" ml="auto">
          <Switch
            isDisabled={!isFeatureAvailable}
            id="weekly-reports"
            isChecked={!isFeatureAvailable ? false : is_weekly_reports_enabled}
            onChange={handleChange}
          />
        </FormControl>
      </Text>
    </ChakraProvider>
  );
}
