import { AlertSourcesService, exception } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import { IIntegration } from 'core/interfaces/IIntegration';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { OnChangeValue } from 'react-select';
import { FilterOptions } from '../const';
import { useSecondaryFilters } from '../context';
import { SelectOption } from '../types';
import DropdownContainer from './dropdown_container';
import { Select } from './select';

type IOrgData = Pick<IAppState, 'integrations'>;

interface Props extends IOrgData {
  selected: string[];
  teamId?: string;
  isInitializedOpen?: boolean;
}

const useActiveAlertSources = (integrations: IIntegration[], teamId: string | undefined) => {
  const _alertSourcesService = new AlertSourcesService();
  const [activeAlertSources, setActiveSources] = useState<IIntegration[]>([]);
  const toDate = new Date();
  const fromDate = new Date(new Date().setMonth(new Date().getMonth() - 3));

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { data: activeSourceIDs },
        } = await _alertSourcesService.getActive(
          { fromDate: fromDate.toJSON(), toDate: toDate.toJSON() },
          teamId,
        );
        const newActiveSources = integrations.filter(i => activeSourceIDs.includes(i._id));
        setActiveSources(newActiveSources);
      } catch (err: any) {
        setActiveSources(integrations);
        exception.handle('E_ANALYTICS_GET_ACTIVE_ALERT_SOURCES', err);
      }
    })();
    // eslint-disable-next-line
  }, [teamId]);

  return activeAlertSources;
};

const AlertSource: React.FC<Props> = ({ selected, isInitializedOpen, ...props }) => {
  const activeSources = useActiveAlertSources(props.integrations.i, props.teamId);
  const { updateFilter } = useSecondaryFilters();

  const alertOptions = useMemo(
    () =>
      activeSources.map((t: IIntegration) => {
        return {
          label: t.type,
          value: t._id,
        };
      }),
    [activeSources],
  );

  const [selectedOptions, selectedNames] = useMemo(() => {
    const sel = activeSources.filter(t => {
      return selected.some(f => {
        return f === t._id;
      });
    });

    return [
      sel.map(t => {
        return {
          label: t.type,
          value: t._id,
        };
      }),
      sel.map(t => t.type),
    ];
  }, [activeSources, selected]);

  const handleChange = (newValue: OnChangeValue<SelectOption, true>) => {
    const arr: string[] = [];

    newValue.map(o => {
      arr.push(o.value);
    });

    updateFilter('alert_sources', arr);
  };

  return (
    <DropdownContainer
      selected={selectedNames}
      filterKey="alert_sources"
      label={FilterOptions['alert_sources']}
      isInitializedOpen={isInitializedOpen}
    >
      <Select
        options={alertOptions}
        value={selectedOptions}
        onChange={handleChange}
        isLoading={!alertOptions.length}
        isMulti
      />
    </DropdownContainer>
  );
};

export default connect(({ integrations }: IAppState) => ({
  integrations,
}))(AlertSource);
