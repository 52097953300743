import { IPricingEntity } from 'core/interfaces/IBillingPlan';
import React, { PropsWithChildren } from 'react';
import { BillingGate } from './BillingGate';
import { SessionProvider } from './Session';

type PageProps = PropsWithChildren<{
  pricingEntity?: IPricingEntity;
}>;

export const Page = ({ pricingEntity, children }: PageProps) => {
  return (
    <SessionProvider>
      {pricingEntity ? (
        <BillingGate pricingEntity={pricingEntity}>{children}</BillingGate>
      ) : (
        children
      )}
    </SessionProvider>
  );
};
