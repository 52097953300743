import { ofType } from 'redux-observable';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { REQUEST_USER_LOGOUT } from '../../const/auth';
import { logoutUser } from '../../api/auth';
import { onRequestUserLogoutSuccess, onRequestUserLogoutFail } from '../../actions/auth';
import broadcastChannel from 'core/broadcast/authBroadcastChannel';
import { EVENT_LOGGED_OUT } from 'core/broadcast/authBroadcastChannel';
import { API } from 'core';
const logoutUserEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_USER_LOGOUT),
    map((action: any) => action.payload),
    switchMap(() => {
      if (API.config.sessionId.length > 0) {
        return logoutUser().pipe(
          map(() => {
            broadcastChannel.postMessage({ event: EVENT_LOGGED_OUT });
            return onRequestUserLogoutSuccess();
          }),
          catchError(_ => of(onRequestUserLogoutFail())),
        );
      }
      return [];
    }),
  );

export default logoutUserEpic;
