import * as React from 'react';
import { SVGProps } from 'react';

const SvgHetrixtoolsLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Hetrixtools_Logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.Hetrixtools_Logo-20x20_svg__st0{fill:#bd1e2d}'}</style>
    <path
      className="Hetrixtools_Logo-20x20_svg__st0"
      d="M3.9 5.7 6 4.2c.6.5 1.1 1 1.6 1.5V6c.2 1.2-.4 2-1.2 2.9-1 1-1.4 2.4-1 4 .4 1.4 1.2 2.4 2.5 3 .8.4 1.7.7 2.7.4.6-.2 1.2-.2 1.7-.6 1.1-.8 2-1.8 2.1-3.3 0-.6.1-1.2 0-1.7-.2-.9-.7-1.6-1.3-2.2-.2-.3-.6-.5-.7-.8-.1-.6-.2-1.3 0-1.9.2-.6.7-1 1.1-1.5 0-.1.3 0 .4 0 .5.4 1.1.7 1.6 1.1.4.3.3.6 0 .9-.1.1-.2.3-.3.4-.4.4-.2.8 0 1.1.2.4.6.2.9.2.2 0 .3 0 .4-.1.6-.2.9 0 1.1.6.1.4.2.8.4 1.2.2.4.1.6-.3.8-.4.2-.7.3-1.1.5-.4.2-.4.7-.1 1 .3.2.7.4 1 .6.6.2.7.4.5 1-.2.4-.3.8-.4 1.2-.2.6-.6.7-1.1.5-.6-.3-1-.3-1.2.1-.3.4-.2.9.2 1.4.4.5.4.9-.2 1.3-.4.3-.7.6-1.1.8-.3.2-.6.1-.8-.2-.3-.4-.5-1-1.2-.8-.5.2-.7.4-.7 1 0 1.1-.1 1.1-1.1 1.1h-.9c-.6 0-.8-.2-.8-.8v-.6c-.1-.2-.2-.5-.4-.6-.3-.2-.6-.2-.9.1-.1.2-.3.3-.4.4-.3.4-.6.5-1 .2s-.9-.6-1.3-.9c-.3-.2-.2-.8.1-1.2.4-.6.2-1.3-.5-1.5-.1 0-.2 0-.3.1-.2 0-.5.1-.7.2-.4.1-.6-.1-.7-.5-.1-.4-.3-.8-.4-1.3-.2-.7 0-.9.7-1.1.6-.2.9-.7.7-1.3 0-.1-.2-.2-.3-.3-.1 0-.2 0-.2-.1-1.4-.4-1.4-.6-1.1-1.7 0-.2.2-.3.2-.5.3-.7.3-.8 1.2-.6.6.1 1 .2 1.3-.4.1-.2.1-.5 0-.7-.3-.4-.6-.7-.8-1.2z"
    />
    <path
      className="Hetrixtools_Logo-20x20_svg__st0"
      d="M8.6 0c0 .6.1 1.1.1 1.7 0 .4.1.8.4 1.1.5.5 1.5.4 1.8-.2.1-.5.1-1.1.2-1.6 0-.2.1-.5.2-.8.6.4.8.8.9 1.3.1.9 0 1.7-.8 2.4-.4.4-.7 1-.7 1.6v6.6c0 .7-.3 1.1-.8 1.1s-1-.6-1-1.2V5.6c0-.8-.2-1.4-.8-2-.5-.5-.9-1.3-.5-2.2.2-.5.4-1 1-1.4z"
    />
  </svg>
);

export default SvgHetrixtoolsLogo20X20;
