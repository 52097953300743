import React, { Component } from 'react';
import { TextButton } from '@squadcast/alchemy-ui/metal';
import { IStatusPage } from '../../../../../../core/interfaces/IStatusPage';
import { CheckBox, Row, Input } from '@squadcast/alchemy-ui/carbon';
import './index.css';
import { deepCopy, pick } from 'core/helpers';
import equal from 'fast-deep-equal/es6/react';

interface IProps {
  hide: (isASave?: boolean, pageConfig?: any) => void;
  pageConfig: Pick<IStatusPage, 'pageConfig'>;
  statusPageUrl: Pick<IStatusPage, 'url'>;
  checkAndSetDirty: () => void;
}

interface IState {
  pageConfig: Pick<IStatusPage, 'pageConfig'>;
  statusPageUrl: Pick<IStatusPage, 'url'>;
  showEditConfigId: string;
  subscriptionErrorMessage?: string;
}

class SubscriptionsModal extends Component<IProps, IState> {
  private _initialState?: IState;

  constructor(props: IProps) {
    super(props);

    this.state = {
      pageConfig: this.props.pageConfig,
      statusPageUrl: this.props.statusPageUrl,
      showEditConfigId: '',
    };

    this._initialState = deepCopy(this.state);
  }

  public onSenderEmailAliasChange = (value: string) => {
    this.setState(({ pageConfig }) => {
      const { pageConfig: newPageConfig } = pageConfig;
      newPageConfig.senderMail = value;
      return { pageConfig: { pageConfig: newPageConfig } };
    });
  };

  public toggleEnableSubscriptions = () => {
    this.setState(({ pageConfig }) => {
      const { pageConfig: newPageConfig } = pageConfig;
      newPageConfig.subscriptions.isEnabled = !pageConfig.pageConfig.subscriptions.isEnabled;
      return { pageConfig: { pageConfig: newPageConfig } };
    });
  };

  public toggleEnableEmail = () => {
    this.setState(({ pageConfig }) => {
      const { pageConfig: newPageConfig } = pageConfig;
      newPageConfig.subscriptions.type.email = !pageConfig.pageConfig.subscriptions.type.email;
      return { pageConfig: { pageConfig: newPageConfig } };
    });
    if (
      this.state.pageConfig.pageConfig.subscriptions.isEnabled === false &&
      this.state.pageConfig.pageConfig.subscriptions.type.email === false
    ) {
      this.toggleEnableSubscriptions();
    }
    if (this.state.pageConfig.pageConfig.subscriptions.isIncidentUpdate === false) {
      this.toggleEnableIncidentUpdate();
    }
  };

  public toggleEnableWebhook = () => {
    this.setState(({ pageConfig }) => {
      const { pageConfig: newPageConfig } = pageConfig;
      newPageConfig.subscriptions.type.webhook = !pageConfig.pageConfig.subscriptions.type.webhook;
      return { pageConfig: { pageConfig: newPageConfig } };
    });
    if (
      this.state.pageConfig.pageConfig.subscriptions.isEnabled === false &&
      this.state.pageConfig.pageConfig.subscriptions.type.webhook === false
    ) {
      this.toggleEnableSubscriptions();
    }
  };
  public toggleEnableIncidentUpdate = () => {
    this.setState(({ pageConfig }) => {
      const { pageConfig: newPageConfig } = pageConfig;
      newPageConfig.subscriptions.isIncidentUpdate =
        !pageConfig.pageConfig.subscriptions.isIncidentUpdate;
      return { pageConfig: { pageConfig: newPageConfig } };
    });
  };
  public toggleEnableMaintenanceUpdate = () => {
    this.setState(({ pageConfig }) => {
      const { pageConfig: newPageConfig } = pageConfig;
      newPageConfig.subscriptions.isMaintenanceUpdate =
        !pageConfig.pageConfig.subscriptions.isMaintenanceUpdate;
      return { pageConfig: { pageConfig: newPageConfig } };
    });
  };

  public validate = () => {
    if (
      this.state.pageConfig.pageConfig.subscriptions.isEnabled === true &&
      this.state.pageConfig.pageConfig.subscriptions.type.email === false &&
      this.state.pageConfig.pageConfig.subscriptions.type.webhook === false
    ) {
      this.setState({
        subscriptionErrorMessage:
          'Atleast one type of subscription needs to be enabled to show subscriptions.',
      });
      return false;
    }
    if (
      this.state.pageConfig.pageConfig.subscriptions.isEnabled &&
      this.state.pageConfig.pageConfig.senderMail === ''
    ) {
      this.setState({ subscriptionErrorMessage: "Sender mail can't be empty." });
      return false;
    }

    return true;
  };

  componentDidUpdate() {
    if (
      this._initialState &&
      !equal(
        pick(this.state, 'pageConfig', 'statusPageUrl'),
        pick(this._initialState, 'pageConfig', 'statusPageUrl'),
      )
    )
      this.props.checkAndSetDirty();
  }

  public render() {
    const { pageConfig } = this.state.pageConfig;

    return (
      <div
        onClick={event => {
          this.setState({ showEditConfigId: '' });
          event.stopPropagation();
        }}
      >
        <div className="">
          <h1 className="modal-container-heading">Edit Subscriptions Configuration</h1>
        </div>
        <div className="mt-20">
          <Row>
            <CheckBox
              id="enableSubscriptions"
              name="enableSubscriptions"
              label="Enable StatusPage Subscriptions"
              checked={pageConfig.subscriptions.isEnabled}
              onChange={() => this.toggleEnableSubscriptions()}
            >
              Enable Subscriptions
            </CheckBox>
          </Row>
          <div className="subscription-rectangle">
            <div className="method">
              <Row>
                <Row flexWidth={3}>
                  <CheckBox
                    id="emailSubscription"
                    name="emailSubscription"
                    label="Email"
                    checked={pageConfig.subscriptions.type.email}
                    onChange={() => this.toggleEnableEmail()}
                  >
                    Email
                  </CheckBox>
                </Row>
                <Row flexWidth={5}>
                  <Input
                    id="sender_email"
                    name="sender_email"
                    label="sender_email"
                    type="text"
                    placeHolder="notifications"
                    value={pageConfig.senderMail as string}
                    onChange={(_, value) => this.onSenderEmailAliasChange(value)}
                  />
                </Row>
                <Row>
                  <p className="senderMail"> @{this.state.statusPageUrl}</p>
                </Row>
              </Row>
            </div>
            <div className="method">
              <Row>
                <CheckBox
                  id="webhookSubscription"
                  name="webhookSubscription"
                  label="Webhook"
                  checked={pageConfig.subscriptions.type.webhook}
                  onChange={this.toggleEnableWebhook}
                >
                  Webhook
                </CheckBox>
              </Row>
            </div>
          </div>
          {pageConfig.subscriptions.isEnabled && (
            <div>
              <p>Select the notifications to be sent to subscribers</p>
              <div className="subscription-rectangle">
                <div className="method">
                  <Row>
                    <Row flexWidth={3}>
                      <CheckBox
                        id="incidentUpdate"
                        name="incidentUpdate"
                        label="Incident"
                        checked={pageConfig.subscriptions.isIncidentUpdate}
                        onChange={() => this.toggleEnableIncidentUpdate()}
                      >
                        Incident Updates
                      </CheckBox>
                    </Row>
                  </Row>
                </div>
                <div className="method">
                  <Row>
                    <CheckBox
                      id="maintenanceUpdate"
                      name="maintenanceUpdate"
                      label="Manitenance"
                      checked={pageConfig.subscriptions.isMaintenanceUpdate}
                      onChange={this.toggleEnableMaintenanceUpdate}
                    >
                      Maintenance
                    </CheckBox>
                  </Row>
                </div>
              </div>
            </div>
          )}
          <div id="subscription-error-block" className="error-block">
            {this.state.subscriptionErrorMessage}
          </div>
        </div>
        <div className="mt-20">
          <TextButton
            id="service_status_modal_save"
            onClick={() =>
              this.validate() && this.props.hide(true, this.state.pageConfig.pageConfig)
            }
            size="normal"
            text="Save"
          />
        </div>
      </div>
    );
  }
}

export default SubscriptionsModal;
