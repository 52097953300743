export const ADD_NEW_RULE_TITLE = 'Add New Ruleset';
export const ADD_ALERT_SRC_TITLE = 'Add Alert Source';
export const ALERT_SRC_TITLE = 'Alert Sources';
export const PENDING_CONFIG = 'Configuration pending';
export const NEXT_ADD_RULESET = 'Next: Add Alert Source';
export const ADD_RULESET_RULE_PLACEHOLDER_TITLE = 'Next: Add Rule';
export const ADD_RULESET_RULE_TITLE = 'Add Rule';
export const ADD_CATCH_ALL_RULE_TITLE = 'Add Catch All Rule';
export const ADD_RULE_TITLE = 'Add Rule';
export const VIEW_RULE_TITLE = 'View Rule';
export const EDIT_RULE_TITLE = 'Edit Rule';

export const RULESET_RULE_FORM_ID = 'GER Ruleset Rule Form';

export const CATCH_ALL_RULE_TITLE = 'CATCH ALL RULE';
export const RULE_ENDPOINT_TITLE = 'Endpoint';
export const RULE_EMAIL_TITLE = 'Email';
export const RULESET_OWNER_TITLE = 'Ruleset Owner';
