import DataTable from 'components/chakra/Table';
import {
  EscalationPolicy,
  NewTag,
  ScheduleOverride,
} from 'views/main/organization/schedules/graphql/types';
import React, { useEffect, useMemo, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { SCHEDULES_V2_DETAIL_PATH } from 'views/main/routes/routes';

import { useGetAllPatternParticipants } from '../../../helpers/helpers.customrotations';
import { IRotationDetail, IRotationEvent } from '../../../interface/schedule';
import initializeColumns from './columns';
import { useOrganizationConfig } from '../../..';
import { paddedUTCDate } from '../../../helpers/helpers.date';

export type IListEvent = IRotationDetail[number] &
  NonNullable<IRotationEvent>[number] & {
    scheduleID: number;
    rotationName: string;
    scheduleName: string;
    scheduleTimeZone: string;
    scheduleTags: Array<NewTag>;
    scheduleEscalations: Array<EscalationPolicy>;
    isOverride?: boolean;
    override?: ScheduleOverride;
    overlappedByOverride?: boolean;
  };
export type IListEventInfo = { [key: string]: IListEvent[] };

export interface IRotationTable {
  events: IListEventInfo[];
  overflowHeight: string;
  timeZone?: string;
}
const ListViewTable = React.memo(({ events, overflowHeight, timeZone }: IRotationTable) => {
  const { pathname } = useLocation();

  const scrollToRef = useRef<HTMLElement | null>(null);
  const allParticipants = useGetAllPatternParticipants();
  const {
    organization: { currentUser },
  } = useOrganizationConfig();

  const currentUserTimezone = currentUser.u?.time_zone ?? '';

  const columns = useMemo(
    () =>
      initializeColumns({
        allParticipants,
        timeZone: timeZone ?? currentUserTimezone,
        onSetScroll: (ref: HTMLElement) => (scrollToRef.current = ref),
      }).filter(col =>
        matchPath(pathname, SCHEDULES_V2_DETAIL_PATH)
          ? !['rotation_schedule_name', 'schedule_tags', 'rotation_escalation_policy'].includes(
              col.id,
            )
          : true,
      ),
    [allParticipants],
  );

  const filteredEvents = useMemo(
    () =>
      events?.map(eventDayData => {
        const result: IListEventInfo = {};

        // Iterate over the keys of eventDayData
        Object.keys(eventDayData).forEach(key => {
          // Filter the events for the current key
          result[key] = Object.values(eventDayData[key])
            .flat()
            .filter(eventData => {
              const startTimeString = paddedUTCDate(
                eventData.startTime,
                timeZone ?? currentUserTimezone,
              );
              const endTimeString = paddedUTCDate(
                eventData.endTime,
                timeZone ?? currentUserTimezone,
              );

              return startTimeString !== endTimeString;
            });
        });

        return result;
      }),
    [events],
  );

  useEffect(() => {
    if (scrollToRef.current && 'scrollIntoView' in scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }, [scrollToRef]);

  return (
    <DataTable
      columns={columns}
      rowBottomBorder={true}
      headerBackgroundColor="gray.120;"
      data={filteredEvents}
      width="100%"
      containerStyles={{
        '& th:first-of-type': {
          width: '12%',
        },
        height: overflowHeight,
        overflow: 'auto',
        '& thead': {
          position: 'sticky',
          top: 0,
          zIndex: 1,
          background: 'white',
          borderBottomWidth: 1,
          borderBottomColor: 'gray.100',
        },
        '& tbody tr > td:first-of-type': {
          verticalAlign: 'top',
        },
        '& td': {
          padding: 0,
          paddingY: 2,
        },
      }}
    />
  );
});

export default ListViewTable;
