import React from 'react';

import { Box, Button, ButtonGroup, Text, VStack } from '@chakra-ui/react';

interface ILogoActionProps {
  current: HTMLInputElement | null;
  logo: File;
  setFieldValue: (field: string, value: any, validate?: boolean) => void;
}

function LogoActionButtons(props: ILogoActionProps) {
  return (
    <Box display={'flex'} flexDirection="column" alignItems={'start'} textAlign="start">
      <VStack alignItems={'start'}>
        <Box width={'100%'}>
          <Text
            fontWeight={400}
            fontSize={12}
            color={' #899DB2'}
            lineHeight={'100%'}
            fontStyle={'normal'}
          >
            {props.logo?.name ?? ''}
          </Text>
        </Box>
        <ButtonGroup>
          <Button
            key={1}
            variant={'outlineBlue'}
            onClick={e => {
              e.stopPropagation();
              props.current?.click();
            }}
          >
            Change
          </Button>
          <Button
            key={2}
            variant={'outlineBlue'}
            onClick={e => {
              e.stopPropagation();
              props.setFieldValue('logoUrl', '');
              props.setFieldValue('isLogoChanged', true);
              props.setFieldValue('logo', '', true);
            }}
          >
            Delete
          </Button>
        </ButtonGroup>
      </VStack>
    </Box>
  );
}
export default LogoActionButtons;
