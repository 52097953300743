import * as React from 'react';
import { SVGProps } from 'react';

const SvgPowercodeLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#06a6e1',
      }}
      d="M8.637 11.608c.032-1.766-.094-3.942.063-6.118C8.858 3.06 10.31 1.2 12.548.444c2.365-.82 4.856.095 6.276 1.924 1.45 1.86 1.576 4.446.283 6.401-1.135 1.703-2.775 2.46-4.793 2.492h-1.42c-.346 0-.504-.095-.504-.473a14.79 14.79 0 0 0 0-1.672c0-.315.095-.441.41-.441.725 0 1.482.063 2.208-.126 1.576-.378 2.491-1.86 2.176-3.532-.284-1.482-1.798-2.555-3.312-2.302-1.608.252-2.554 1.387-2.586 3.216-.031 2.429 0 4.889 0 7.317 0 .851-.063 1.671-.284 2.491-.315 1.199-1.009 2.176-1.955 2.933-.094.095-.22.221-.347.158-.157-.063-.094-.252-.094-.41.031-2.113.031-4.258.031-6.812z"
    />
    <path
      style={{
        fill: '#b3b1af',
      }}
      d="M6.272 8.738c.252.032.715-.02 1.167.095.091.028.095.883.095 1.324 0 1.293.189 1.104-1.136 1.104-.788 0-1.576 0-2.302.442-1.135.662-1.671 1.986-1.293 3.437.284 1.167 1.514 2.081 2.744 2.113.599 0 1.135-.063 1.64-.379.189-.126.347-.157.347.158v2.27c0 .253-.19.285-.379.348-2.46.693-5.172-.379-6.433-2.65-1.64-2.964-.473-7.063 3.374-8.104.63-.158 1.293-.19 2.176-.158z"
    />
  </svg>
);

export default SvgPowercodeLogo20X20;
