import { Flex, HStack, Text } from '@chakra-ui/react';
import { useState, FC } from 'react';
import CreatableReactSelect from 'react-select/creatable';
import { getPaddedValue } from 'views/main/organization/schedules/helpers/helpers.event';
import { customStyles } from 'components/chakra/Select/customStyles';
import { components } from 'react-select';
import { DEFAULT_SELECTED_TIME } from '../delay-notifications/constant';

const getTimeDropDownValues = () => {
  let hours, minutes;
  const dropdownValues: Array<Record<string, any>> = [];
  for (let i = 0; i <= 1425; i += 15) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (hours < 10) {
      hours = '0' + hours; // adding leading zero
    }
    if (minutes < 10) {
      minutes = '0' + minutes; // adding leading zero
    }
    dropdownValues.push({
      value: `${hours}:${minutes}`,
      label: `${hours}:${minutes}`,
      duration: i,
    });
  }
  return dropdownValues;
};
const SingleValue = (props: any) => {
  const { label } = props.getValue()[0];
  return (
    <components.SingleValue {...props}>
      <HStack spacing={1}>
        <Flex>{label}</Flex>
      </HStack>
    </components.SingleValue>
  );
};

type Props = {
  time?: string;
  onTimeUpdate: (timeStamp: string) => void;
};

export const TimeSelector: FC<Props> = ({ time, onTimeUpdate }) => {
  const [timeOptions, setTimeOptions] = useState(getTimeDropDownValues());

  const timeChangeHandler = (value: any) => {
    const dropdownValue = value.value; // 00:10, 03:40 and so on

    if (value.__isNew__) {
      const hoursPart = dropdownValue.split(':')[0];
      const minutesPart = dropdownValue.split(':')[1];
      const hours = Number.parseInt(hoursPart);
      const minutes = Number.parseInt(minutesPart);
      if (isNaN(hours) || isNaN(minutes)) {
        return;
      }
      if (hours < 0 || hours > 24 || minutes < 0 || minutes > 60) {
        return;
      }
      const updatedDuration = hours * 60 + minutes;
      const updatedTimeStamp = [getPaddedValue(hours), getPaddedValue(minutes)].join(':');
      const newDropDownOption = {
        label: updatedTimeStamp,
        value: updatedTimeStamp,
        duration: updatedDuration,
      };

      const updatedDropDownOptions = [...timeOptions, newDropDownOption].filter(
        (option, index, self) => self.indexOf(option) === index,
      );
      setTimeOptions(
        updatedDropDownOptions as Array<{
          duration?: number | undefined;
          label: string;
          value: string | number;
        }>,
      );
    }

    onTimeUpdate(dropdownValue);
  };

  return (
    <CreatableReactSelect
      defaultValue={{ label: DEFAULT_SELECTED_TIME, value: DEFAULT_SELECTED_TIME }}
      value={{
        label: time ?? DEFAULT_SELECTED_TIME,
        value: time ?? DEFAULT_SELECTED_TIME,
      }}
      options={timeOptions.sort((option1, option2) => option1.duration - option2.duration)}
      onChange={(value: any) => timeChangeHandler(value)}
      closeMenuOnSelect
      components={{ IndicatorSeparator: () => null, SingleValue }}
      styles={customStyles}
    />
  );
};
