export const THEME_SPACES = {
  settings: {
    deactivate_delete_divider_margin: '24px 0px',
    permissions_table_name: '6px 20.57px 7.63px 18px',
    space_0: '0px',
    space_1: '1px',
    space_4: '4px',
    space_5: '5px',
    space_6: '6px',
    space_8: '8px',
    space_9: '9px',
    space_12: '12px',
    space_15: '15px',
    space_16: '16px',
    space_18: '18px',
    space_20: '20px',
    space_24: '24px',
    space_267: '267px',
    space_276: '276px',
    space_270: '270px',
  },
};

export const THEME_SIZES = {
  settings: {
    size_1: '1px',
    size_15: '15px',
    size_20: '20px',
    size_32_23: '32.23px',
    size_40: '40px',
    size_98: '98px',
    size_882: '882px',
    size_276: '276px',
    size_100_percentage: '100%',
  },
};

export const THEME_RADIUS = {
  settings: {
    route_border: '4px 4px 0px 0px',
    radius_1: '1px',
    radius_3: '3px',
    radius_6: '6px',
  },
};

export const THEME_BORDER = {
  settings: {
    border_1: '1px',
  },
};

export const THEME_BORDER_WIDTHS = {
  settings: {
    border_w_1: '1px',
  },
};
