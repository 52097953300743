import { create } from 'zustand';
import { Role } from '../types';

type DeleteState = 'checking_conflicts' | 'conflict' | 'deleting' | 'error' | 'success';

export type OBACMemberStore = {
  confirmDeleteModal: boolean;
  confirmConvertToMembersModal: boolean;
  isUpdateRoleConflictModalOpened: boolean;
  isRemoveMemberConflictModalOpened: boolean;
  isActionDrawerOpened: boolean;
  actingMemberId?: string;
  actingMemberRole?: Role;
  actingMemberName?: string;
  deleteState?: DeleteState;
  openConfirmDeleteModal: (memberId: string, memberName: string, memberRole: Role) => void;
  closeConfirmDeleteModal: () => void;

  openConfirmConvertToMembersModal: (
    memberId: string,
    memberName: string,
    memberRole: Role,
  ) => void;
  closeConfirmConvertToMembersModal: () => void;
  openActionsDrawer: () => void;
  closeActionsDrawer: () => void;

  openUpdateRoleConflictModal: () => void;
  closeUpdateRoleConflictModal: () => void;

  openRemoveMemberConflictModal: (memberId: string, memberName: string, memberRole: Role) => void;
  closeRemoveMemberConflictModal: () => void;

  setActingUser: (memberId: string, memberName: string, memberRole: Role) => void;

  setDeleteState: (state?: DeleteState) => void;
};

export const useOBACMemberStore = create<OBACMemberStore>(set => ({
  confirmDeleteModal: false,
  isActionDrawerOpened: false,
  actingMemberId: '',
  confirmConvertToMembersModal: false,
  isUpdateRoleConflictModalOpened: false,
  isRemoveMemberConflictModalOpened: false,

  openConfirmDeleteModal: (memberId: string, memberName: string, memberRole: Role) => {
    set((state: OBACMemberStore) => ({
      ...state,
      confirmDeleteModal: true,
      actingMemberId: memberId,
      actingMemberName: memberName,
      actingMemberRole: memberRole as Role,
    }));
  },
  closeConfirmDeleteModal: () => {
    set((state: OBACMemberStore) => ({
      ...state,
      confirmDeleteModal: false,
    }));
  },

  openActionsDrawer: () => {
    set((state: OBACMemberStore) => ({ ...state, isActionDrawerOpened: true }));
  },
  closeActionsDrawer: () => {
    set((state: OBACMemberStore) => ({ ...state, isActionDrawerOpened: false }));
  },

  openConfirmConvertToMembersModal: (memberId: string, memberName: string, memberRole: Role) => {
    set((state: OBACMemberStore) => ({
      ...state,
      confirmConvertToMembersModal: true,
      actingMemberId: memberId,
      actingMemberName: memberName,
      actingMemberRole: memberRole as Role,
    }));
  },
  closeConfirmConvertToMembersModal: () => {
    set((state: OBACMemberStore) => ({
      ...state,
      confirmConvertToMembersModal: false,
    }));
  },

  openUpdateRoleConflictModal: () => {
    set((state: OBACMemberStore) => ({
      ...state,
      isUpdateRoleConflictModalOpened: true,
    }));
  },

  closeUpdateRoleConflictModal: () => {
    set((state: OBACMemberStore) => ({
      ...state,
      isUpdateRoleConflictModalOpened: false,
      actingMemberId: undefined,
      actingMemberName: undefined,
      actingMemberRole: undefined,
    }));
  },

  openRemoveMemberConflictModal: (memberId: string, memberName: string, memberRole: Role) => {
    set((state: OBACMemberStore) => ({
      ...state,
      isRemoveMemberConflictModalOpened: true,
      actingMemberId: memberId,
      actingMemberName: memberName,
      actingMemberRole: memberRole as Role,
    }));
  },

  closeRemoveMemberConflictModal: () => {
    set((state: OBACMemberStore) => ({
      ...state,
      isRemoveMemberConflictModalOpened: false,
      actingMemberId: undefined,
      actingMemberName: undefined,
      actingMemberRole: undefined,
    }));
  },

  setActingUser: (memberId: string, memberName: string, memberRole: Role) => {
    set((state: OBACMemberStore) => ({
      ...state,
      actingMemberId: memberId,
      actingMemberName: memberName,
      actingMemberRole: memberRole as Role,
    }));
  },

  setDeleteState: (state?: DeleteState) => {
    set((s: OBACMemberStore) => ({ ...s, deleteState: state }));
  },
}));
