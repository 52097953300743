import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <linearGradient
      id="a"
      gradientUnits="userSpaceOnUse"
      x1={-1297.575}
      y1={-20.8}
      x2={-1297.325}
      y2={-21.05}
      gradientTransform="matrix(80 0 0 80 103806 1684)"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#bd0816',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#ff5252',
        }}
      />
    </linearGradient>
    <path
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: 'url(#a)',
      }}
      d="M0 0h20v20H0z"
    />
    <path
      d="M14.25 15.25c0-.406-.344-.75-.75-.75s-.75.344-.75.75.344.75.75.75.75-.344.75-.75zM10 15c-.406 0-.75.344-.75.75s.344.75.75.75.75-.344.75-.75S10.406 15 10 15zm-3.5-.5c-.406 0-.75.344-.75.75s.344.75.75.75.75-.344.75-.75-.344-.75-.75-.75zm.651-3.75h5.698l-1.914-1.722-.773.662a.248.248 0 0 1-.325 0l-.772-.662-1.914 1.722zM6.75 7.043v3.395L8.682 8.7 6.75 7.043zm6.074-.293H7.176L10 9.17l2.824-2.42zm.426 3.689V7.043l-1.932 1.656 1.932 1.74zm1.5 4.811c0 .677-.573 1.25-1.25 1.25s-1.25-.573-1.25-1.25c0-.592.438-1.104 1-1.224v-.776h-3v1.276c.562.12 1 .631 1 1.224 0 .677-.573 1.25-1.25 1.25s-1.25-.573-1.25-1.25c0-.592.438-1.104 1-1.224V13.25h-3v.776c.562.12 1 .631 1 1.224 0 .677-.573 1.25-1.25 1.25s-1.25-.573-1.25-1.25c0-.592.438-1.104 1-1.224V13a.25.25 0 0 1 .25-.25h3.25v-1.5H6.5a.25.25 0 0 1-.25-.25V6.5a.25.25 0 0 1 .25-.25h7a.25.25 0 0 1 .25.25V11a.25.25 0 0 1-.25.25h-3.25v1.5h3.25a.25.25 0 0 1 .25.25v1.026c.562.12 1 .632 1 1.224zM17 10c0 1.477-.456 2.89-1.318 4.086l-.406-.292A6.448 6.448 0 0 0 16.5 10c0-3.584-2.916-6.5-6.5-6.5A6.508 6.508 0 0 0 3.5 10c0 1.371.423 2.683 1.224 3.794l-.406.292A6.947 6.947 0 0 1 3 10c0-3.859 3.14-7 7-7 3.859 0 7 3.14 7 7z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#fff',
      }}
    />
  </svg>
);

export default SvgComponent;
