import { Box, Flex, Link, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';

import { DETAILS_TABS } from '../constants/status.constants';
import { DETAILS_TAB_LABELS } from '../constants/statuspage.copy';
import { ExternalUrl } from '../icons';
import IssueTab from './tab.issues';
import MaintenanceTab from './tab.maintenance';
import SubscribersTab from './tab.subscribers';

const panels = [DETAILS_TABS.ISSUES, DETAILS_TABS.MAINTENANCE, DETAILS_TABS.SUBSCRIBERS];

interface Props {
  pageId: number;
  url: string;
  timezone: string;
}

export const Panel = (props: Props) => {
  const location = useLocation();
  const history = useHistory();

  const { search } = location;
  const query = new URLSearchParams(search);
  const tab = query.get('tab') ?? DETAILS_TABS.ISSUES;
  const tabIndex = panels.findIndex(t => t === tab);

  const handleClick = (index: number) => {
    const params = new URLSearchParams(search);
    params.delete('tab');
    params.delete('search');
    params.delete('filters');
    params.append('tab', panels[index]);
    history.replace({ search: params.toString() });
  };

  return (
    <Tabs variant="enclosed" defaultIndex={tabIndex} onChange={handleClick}>
      <TabList>
        {panels.map((tab: string, index: number) => {
          return (
            <Tab key={index} data-testid={tab}>
              {DETAILS_TAB_LABELS[tab]}
            </Tab>
          );
        })}
        <Box px={4} py={3}>
          <Link href={props.url} isExternal>
            <Flex alignItems={'center'}>
              <Box mr={1}>View status page </Box>
              <ExternalUrl />
            </Flex>
          </Link>
        </Box>
      </TabList>

      <TabPanels>
        <TabPanel p={0}>
          <ErrorBoundary>
            <IssueTab pageId={props.pageId} />
          </ErrorBoundary>
        </TabPanel>
        <TabPanel p={0}>
          <ErrorBoundary>
            <MaintenanceTab pageId={props.pageId} timezone={props.timezone} />
          </ErrorBoundary>
        </TabPanel>

        <TabPanel p={0}>
          <ErrorBoundary>
            <SubscribersTab pageId={props.pageId} />
          </ErrorBoundary>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
