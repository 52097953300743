import { HStack, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';

import React, { useEffect, useRef } from 'react';
import { Workflow } from '../../types/response/workflow';
import { WorkflowTriggerTypes } from '../../types/triggers';
import {
  IncidentAcknowledged,
  IncidentTriggered,
  IncidentReassigned,
  IncidentResolved,
  TagsUpdatedIcon,
  PriorityUpdatedIcon,
  NoteAddedIcon,
} from 'icons';
import { focusCard } from 'core';
import { ActiveWorkflowTarget } from './WorkFlowConditions/ActiveWorkflowTarget';

type WorkflowTriggerProps = Pick<Workflow, 'trigger'>;

const TRIGGER_ICONS = {
  [WorkflowTriggerTypes.INCIDENT_ACKNOWLEDGED]: IncidentAcknowledged,
  [WorkflowTriggerTypes.INCIDENT_TRIGGERED]: IncidentTriggered,
  [WorkflowTriggerTypes.INCIDENT_REASSIGNED]: IncidentReassigned,
  [WorkflowTriggerTypes.INCIDENT_RESOLVED]: IncidentResolved,
  [WorkflowTriggerTypes.INCIDENT_TAGS_UPDATED]: TagsUpdatedIcon,
  [WorkflowTriggerTypes.INCIDENT_PRIORITY_UPDATED]: PriorityUpdatedIcon,
  [WorkflowTriggerTypes.INCIDENT_NOTE_ADDED]: NoteAddedIcon,
};

const TRIGGER_MESSAGE = {
  [WorkflowTriggerTypes.INCIDENT_ACKNOWLEDGED]: 'Incident is acknowledged',
  [WorkflowTriggerTypes.INCIDENT_TRIGGERED]: 'Incident is triggered',
  [WorkflowTriggerTypes.INCIDENT_REASSIGNED]: 'Incident is reassigned',
  [WorkflowTriggerTypes.INCIDENT_RESOLVED]: 'Incident is resolved',
  [WorkflowTriggerTypes.INCIDENT_TAGS_UPDATED]: 'Incident tags are updated',
  [WorkflowTriggerTypes.INCIDENT_PRIORITY_UPDATED]: 'Incident priority is updated',
  [WorkflowTriggerTypes.INCIDENT_NOTE_ADDED]: 'Incident note is added',
};

const WorkflowTrigger: React.FC<WorkflowTriggerProps> = ({ trigger }) => {
  const TriggerIcon = TRIGGER_ICONS[trigger];
  const triggerMessage = TRIGGER_MESSAGE[trigger];
  const flexRef = useRef(null);
  const { isactionTrigger, styleActionOnTarget, targetAction } = ActiveWorkflowTarget('#Trigger');
  useEffect(() => {
    if (flexRef.current && targetAction === '#Trigger') {
      focusCard(flexRef.current);
    }
  }, [isactionTrigger]);
  return (
    <VStack align="stretch" px={6} pb={12}>
      <Text variant="h2" as="h2" fontWeight="bold">
        TRIGGER
      </Text>
      <HStack
        bg="brand.white"
        p={4}
        width="50dvw"
        gap={2}
        borderWidth="1px"
        borderStyle="solid"
        border={styleActionOnTarget}
        align="center"
        borderRadius={2}
        id="trigger"
        ref={flexRef}
      >
        <TriggerIcon width={24} height={24} />
        <Text variant="h2" id={'Trigger'}>
          When <strong>{triggerMessage}</strong>
        </Text>
      </HStack>
    </VStack>
  );
};

export default WorkflowTrigger;
