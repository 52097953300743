export const fileUploadViewConfig = {
  status: ['upload-image'],
  uploadImage: true,
  imageTexts: {
    sbOnDragEnter: 'Drop file to upload it.',
    sbOnDrop: 'Uploading file #images_names#...',
    sbProgress: 'Uploading #file_name#: #progress#%',
    sbOnUploaded: 'Uploaded #image_name#',
    sizeUnits: ' B, KB, MB',
  },
  renderImageUploadsAsLinks: true,
  multipleFileUploadLimit: 5,
};

export const fileUploadHelperText = 'Attach files via drag & drop or paste files from clipboard';
