import { Button, ButtonGroup, Flex, FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { THEME_COLORS } from 'library/theme/colors';
import * as Yup from 'yup';
import { PAGE_TYPES } from '../../../constants/schema';
import { getPageInputDetails } from '../../../helpers/helper.service';
import { DoubleVertical } from '../../../icons';
import { IComponentTreeItem } from '../../../Interface';

const { schema } = getPageInputDetails(PAGE_TYPES.ADDGROUP);

interface GroupProps {
  value: string;
  componentData: IComponentTreeItem[];
  onSubmit: (value: string) => void;
  cancelGroupChange: () => void;
}
interface IComponent {
  components?: IComponentTreeItem[];
}
type Iprops = GroupProps & IComponent;

export function InlineGroupCreate(props: Iprops) {
  const componentSchema = Yup.object().shape({
    groupName: Yup.string()
      .required('Name of Group is required')
      .trim()
      .max(250, 'Max characters allowed are 250')
      .test('unique', 'Group name already exists', function (value) {
        const { componentData, components } = props;

        const isNewGroupDuplicate = (componentData ?? components)?.some(
          data => data.name.toLowerCase() === value?.toLowerCase(),
        );

        return !isNewGroupDuplicate;
      }),
  });
  const onGroupSave = (values: any) => {
    props.onSubmit(values.groupName);
  };
  return (
    <Flex
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
      px={6}
      py={5}
      bg={THEME_COLORS.primary[200]}
    >
      <DoubleVertical />
      <Formik
        initialValues={{ groupName: props.value }}
        onSubmit={onGroupSave}
        validationSchema={componentSchema}
      >
        {({ errors, submitForm }) => {
          return (
            <Form style={{ width: '100%', display: 'flex' }}>
              <FormControl width={'100%'} px={6} isInvalid={!!errors.groupName}>
                <Field as={Input} name="groupName" bg={'white'} placeholder="Add group name" />
                {errors?.groupName && <FormErrorMessage>{errors?.groupName}</FormErrorMessage>}
              </FormControl>
              <ButtonGroup>
                <Button variant="outline" onClick={props.cancelGroupChange}>
                  Cancel
                </Button>
                <Button mr={3} type="submit" onClick={submitForm}>
                  Save
                </Button>
              </ButtonGroup>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
}
