import { Box, Container } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  customheight?: boolean;
};

export const Layout = ({ children, customheight }: Props) => {
  return (
    <Container
      display="flex"
      bg="gray.200"
      px="8px"
      py={2}
      maxW="container.xxl"
      minHeight={customheight ? '' : '90vh'}
    >
      <Box bg="shades.white" flex="1" borderRadius={4}>
        {children}
      </Box>
    </Container>
  );
};
