import {
  Box,
  Button,
  Divider,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Switch,
} from '@chakra-ui/react';
import { memo, useEffect, useMemo, useState } from 'react';
import { useComponents } from '../../hooks/useComponents';
import { IComponentsForm, IComponentTreeItem, IServiceOption } from '../../Interface';
import { ComponentForm } from '../../statuspage.add/statuspage.addComponent/addComponentsForm/addComponents';
import ComponentList from '../../statuspage.add/statuspage.addComponent/listComponent';
import { connect } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { useServiceList } from 'views/main/organization/service-catalog/hooks/useServiceList';
import { usePageDetails } from '../../hooks/usePageDetails';
import { useUpdateConfig } from '../../hooks/usePageConfig';
import { useFlag } from '@unleash/proxy-client-react';
import { THEME_COLORS } from 'library/theme/colors';
import useServiceListOptions from '../../hooks/useServiceListData';
import { GetStatusPageDetailsQuery } from '../../graphql/query';

interface IComponentProps {
  pageId: number;
  closeDrawer: () => void;
  init: IComponentTreeItem[];
  refetch: () => void;
  canEdit: boolean;
  isServiceConnectorEnabled: boolean;
  statusCodes: GetStatusPageDetailsQuery['getStatusPageDetails']['statusCodes'];
}
type IMix = Pick<IAppState, 'organization'>;

interface IExtendedComponentProps extends IComponentProps, IMix {}

const StatusComponent = (props: IExtendedComponentProps) => {
  const selectedTeam = props.organization?.selectedTeam.teamId;
  const { data } = useServiceList(selectedTeam, 1000);

  const serviceListData = useServiceListOptions(data);

  const {
    state: { components, selectedGroupId, groupOptions, componentForm, isDragged, componentCount },
    addGroup,
    saveGroup,
    editGroup,
    cancelGroupChange,
    deleteGroup,
    initAddComponentForm,
    saveComponent,
    initEditComponent,
    deleteComponent,
    onDragEnd,
    onSubmit,
    showComponentForm,
    setShowComponentForm,
    reInitiateState,
    isComponentProgress,
  } = useComponents(
    () => {
      props.closeDrawer();
    },
    props.pageId,
    props.refetch,
    true,
  );

  const [serviceConnectorEnabled, setServiceConnectorEnabled] = useState(
    props.isServiceConnectorEnabled,
  );
  const enabledAdvanceSettings = useFlag('service-component-connector');

  const { refetch } = usePageDetails(props.pageId, true);
  const { mutateAsync: updateConfig } = useUpdateConfig(() => {
    refetch();
  });

  const onToggleServiceConnector = () => {
    setServiceConnectorEnabled(prev => !prev);
    try {
      updateConfig({
        input: {
          id: props.pageId,
          isAutomaticCompStatusUpdateEnabled: !props.isServiceConnectorEnabled,
        },
      });
    } catch (err) {
      setServiceConnectorEnabled(prev => !prev);
    }
  };
  useEffect(() => {
    if (props.init.length > 0) {
      reInitiateState(props.init);
    }
  }, [props.init]);

  const showAddComponent = () => {
    initAddComponentForm();
    setShowComponentForm(true);
  };

  const showEditComponent = (compId: number, groupId?: number) => {
    initEditComponent(compId, groupId);
    setShowComponentForm(true);
  };
  return showComponentForm ? (
    <Box>
      <ComponentForm
        components={components}
        onCancel={() => setShowComponentForm(false)}
        groupOptions={groupOptions}
        serviceOptions={serviceListData ?? []}
        init={componentForm}
        isComponentProgress={isComponentProgress}
        onSave={(values: IComponentsForm) => {
          saveComponent(values);
          setShowComponentForm(false);
        }}
        statusCodes={props.statusCodes}
        isComponentTimelineVisible={true}
      />
    </Box>
  ) : (
    <Box w={'100%'}>
      {enabledAdvanceSettings && (
        <>
          <Flex mb={2}>
            <>
              <Text fontWeight={400} color={THEME_COLORS.secondary[1000]}>
                Automatically update Component's status based on open incidents within the
                associated Service
              </Text>
            </>
            <Flex gap={2}>
              <Popover trigger="hover">
                <PopoverTrigger>
                  <img src="/icons/info_black.svg" alt="more info" style={{ height: 24 }} />
                </PopoverTrigger>
                <PopoverContent
                  css={{
                    '&:focus': {
                      boxShadow: 'none',
                      outline: 'none',
                    },
                  }}
                  backgroundColor={THEME_COLORS.secondary[100]}
                  p={2}
                  fontSize={'12.5px'}
                  fontWeight={400}
                  width="270px"
                >
                  Note:
                  <br />
                  <Flex>
                    1.
                    <Text pl={2}>
                      When there are open incidents for Squadcast service, the associated Status
                      Page component’s status will automatically move from Operational to Degraded.
                    </Text>
                    <br />
                  </Flex>
                  <Flex>
                    2.
                    <Text pl={2}>
                      When this is disabled, this automatic link will cease to work and you will
                      have to update the Status Page by creating an issue on the Status Page.
                    </Text>
                  </Flex>
                </PopoverContent>
              </Popover>

              <Switch
                isChecked={serviceConnectorEnabled}
                onChange={onToggleServiceConnector}
                sx={{
                  height: '20px',
                }}
              />
            </Flex>
          </Flex>
          <Text fontWeight={400} fontSize={14} color={THEME_COLORS.secondary[1000]} mb={4}>
            Note: Attach a service to a component under “Edit Component”
          </Text>
        </>
      )}
      <ComponentList
        showAddComponent={showAddComponent}
        showEditComponent={showEditComponent}
        addGroup={addGroup}
        saveGroup={saveGroup}
        editGroup={editGroup}
        cancelGroupChange={cancelGroupChange}
        deleteGroup={deleteGroup}
        deleteComponent={deleteComponent}
        components={components}
        selectedGroupId={selectedGroupId}
        onDragEnd={onDragEnd}
        groupOptions={groupOptions}
        isEdit
        componentCount={componentCount}
        canEdit={props.canEdit}
        selectedTeam={selectedTeam}
      />
      <Box w="100%" bottom={0} position="fixed" bgColor={'white'} zIndex="2">
        <Divider />
        <Button onClick={onSubmit} hidden={!isDragged || !props.canEdit} my={2}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default connect(({ organization, APP_CONFIG }: IAppState) => ({
  organization,
  APP_CONFIG,
}))(memo(StatusComponent));
