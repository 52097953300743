import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, SpinLoader } from '@squadcast/alchemy-ui/carbon';
import cx from 'classnames';
import { IJProject, IJiraServer, IJServicemap } from '../../../../../../../core/interfaces/IJira';
import renders from './render.index';
import { IIncidentStatus } from '../../../../../../../core/interfaces/IIncidents';
import { IAppState } from '../../../../../../../core/interfaces/IAppState';
import { JiraServerService } from '../../../../../../../core/services';
import { objectIsEmpty } from 'core/helpers';
import { exception } from '../../../../../../../core/exception';

interface IProjectIssues {
  name: string;
  id: string;
  link?: string;
  statuses?: IProjectIssues[];
}

interface IProps extends Pick<IAppState, 'organization'> {
  hide: () => void;
  checkAndSetDirty: () => void;
  removeModalDirty: () => void;
}

interface IState {
  jira: IJiraServer | null;
  appState:
    | 'test-connections'
    | 'get-configs'
    | 'save-token'
    | 'get-projects'
    | 'update-configs'
    | 'no-token'
    | 'saved';
  loading: boolean;
  componentTabState: 'token' | 'project' | 'map' | 'service';
  copied: boolean;
  apiToken: string;
  selectedServices: {
    id: string;
    name: string;
  }[];
  serviceMaps: IJServicemap[];
  searchString: string;
  showDropDown: boolean;
  errors: { [key: string]: string };
  jiraProjects: IJProject[];
  selectedProject: {
    id: string;
    name: string;
    key: string;
  };
  selectedIssue: IProjectIssues;
  projectIssues: IProjectIssues[];
  projectSearchString: string;
  issuesSearchString: string;
  serviceSearchString: string;
  issuesMap: { jira_status: string; system_status: IIncidentStatus }[];
  isManual: boolean;
  updated: boolean;
}

export class JiraServerModal extends Component<IProps, IState> {
  public serviceMap: { [key: string]: { id: string; name: string } } =
    this.props.organization.services.s.reduce((c: any, n) => {
      c[n.id] = {
        id: n.id,
        name: n.name,
      };
      return c;
    }, {});

  public incidentStatus = ['triggered', 'acknowledged', 'resolved'];
  private JSService = new JiraServerService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      jira: null,
      appState: 'get-configs',
      loading: false,
      copied: false,
      apiToken: '',
      componentTabState: 'token',
      selectedServices: [],
      serviceMaps: [],
      searchString: '',
      showDropDown: false,
      jiraProjects: [],
      selectedProject: {
        id: '',
        name: '',
        key: '',
      },
      selectedIssue: {
        id: '',
        link: '',
        name: '',
      },
      projectIssues: [],
      errors: {},
      projectSearchString: '',
      issuesSearchString: '',
      serviceSearchString: '',
      issuesMap: [],
      isManual: false,
      updated: false,
    };
  }

  public componentDidMount() {
    this.getJiraConfigs();
  }

  public getJiraConfigs = async (toNext = false) => {
    this.setState({ appState: 'get-configs' });

    try {
      const { data }: { data: IJiraServer } = await this.JSService.getJiraConfigs();

      // Updating the serviceMap with the services in the meta of the response
      this.serviceMap = data.services.reduce((c: any, n) => {
        c[n.id] = {
          id: n.id,
          name: n.name,
        };
        return c;
      }, this.serviceMap);

      this.setState(
        {
          jira: data,
          apiToken: data.token,
          appState: data.host.length === 0 ? 'test-connections' : 'get-projects',
          errors: {},
        },
        () => {
          this.JSService.jiraServerId = data.id;
          if (data.host.length > 0) {
            this.mapUpdates(toNext);
            this.getJiraProjects();
          }
        },
      );
    } catch (err: any) {
      this.setState({ appState: 'test-connections' });
      exception.handle('E_JIRA_SERVER_GET_CONFIG', err);
    }
  };

  public onCheckJiraConnection = async () => {
    const { jira } = this.state;
    if (jira?.host) {
      this.setState({
        componentTabState: 'project',
      });
    }

    try {
      const testResponse = await this.JSService.checkJiraServerConfig();
      const data = testResponse.data;
      if (data.host) {
        this.getJiraConfigs();
      }
    } catch (err: any) {
      this.setState({
        appState: 'test-connections',
        errors: {
          testFailed: 'true',
        },
      });
    }
  };

  public getJiraProjects = async () => {
    this.setState({ appState: 'get-projects' });

    try {
      const resp = await this.JSService.getJiraProjects();
      const data = resp.data;
      this.setState({ jiraProjects: data });
    } catch (err: any) {
      console.log(err);
      exception.handle('E_JIRA_SERVER_GET_PROJECTS', err);
    }
  };

  public getJiraProjectIssues = async () => {
    this.setState({ appState: 'get-projects' });

    try {
      const resp = await this.JSService.getJiraProjectIssues(this.state.selectedProject.id);
      const data = resp.data;
      this.setState({ projectIssues: data });
    } catch (err: any) {
      console.log(err);
      exception.handle('E_JIRA_SERVER_GET_PROJECT_ISSUES', err);
    }
  };

  public mapUpdates = (toNext = false) => {
    const { jira } = this.state;

    if (!jira) {
      return;
    }

    const newState: any = {
      isCreated: true,
      isManual: jira.is_manual,
      apiToken: jira.token,
      issuesMap: jira.statusmaps as any,
      selectedServices: jira.service_ids.map(sid => this.serviceMap[sid]),
      serviceMaps: jira.service_maps,
    };

    if (!objectIsEmpty(jira.project)) {
      newState.selectedProject = {
        id: jira.project.id,
        key: jira.project.key,
        name: jira.project.name,
      };
    }

    if (!objectIsEmpty(jira.issue_type) && jira.issue_type.statuses.length > 0) {
      newState.selectedIssue = {
        id: jira.issue_type.id,
        name: jira.issue_type.name,
        statuses: jira.issue_type.statuses,
      };
    }

    if (toNext) {
      newState.componentTabState = 'project';
    }

    this.setState(newState, () => {
      if (!objectIsEmpty(jira.project)) {
        this.getJiraProjectIssues();
      }
    });
  };

  public saveIntegrations = async () => {
    const { isManual, selectedIssue, selectedProject, selectedServices, issuesMap, serviceMaps } =
      this.state;

    if (
      selectedIssue.id.length === 0 ||
      selectedProject.id.length === 0 ||
      selectedServices.length === 0 ||
      issuesMap.length === 0
    ) {
      return;
    }

    try {
      this.setState({ loading: true });
      const data = await this.JSService.saveExtensionConfig({
        is_manual: isManual,
        project: {
          id: selectedProject.id,
          key: selectedProject.key,
          name: selectedProject.name,
        },
        issue_type: {
          id: selectedIssue.id,
          name: selectedIssue.name,
          statuses: selectedIssue.statuses
            ? selectedIssue.statuses.map(s => ({
                name: s.name,
                id: s.id,
              }))
            : [],
        },
        service_ids: selectedServices.filter(s => !!s).map(s => s.id),
        service_maps: serviceMaps,
        status_maps: issuesMap,
      });

      this.setState({ loading: false, jira: data.data, updated: true, errors: {} }, () =>
        this.mapUpdates(),
      );
      this.props.removeModalDirty();
    } catch (err: any) {
      const errMessage = err.response?.data?.meta?.error_message || 'Network Error';
      this.setState({ errors: { save: errMessage }, loading: false, updated: false });
      console.log(err);
      exception.handle('E_JIRA_SERVER_SAVE_INTEGRATION', err);
    }
  };

  public onProjectSelect = async (_: any, { id, name, key }: any) => {
    this.setState(
      {
        selectedProject: {
          id,
          name,
          key,
        },
        projectSearchString: '',
        issuesSearchString: '',
        projectIssues: [],
        selectedIssue: {
          id: '',
          name: '',
          link: '',
        },
      },
      () => {
        this.getJiraProjectIssues();
        this.props.checkAndSetDirty();
      },
    );
  };

  public onIssueSelect = (_: any, value: any) => {
    this.setState({
      selectedIssue: value,
      projectSearchString: '',
      issuesSearchString: '',
      issuesMap: value.statuses.map((status: any) => ({
        jira_status: status.name,
        system_status: this.incidentStatus[0],
      })),
    });
    this.props.checkAndSetDirty();
  };

  public onIssueMapSelect = (value: string, index: number) => {
    this.setState(({ issuesMap }) => {
      (issuesMap as any)[index].system_status = value;
      return { issuesMap };
    });
    this.props.checkAndSetDirty();
  };

  public addToServiceArray = (id: string, name: string) => {
    this.setState(({ selectedServices }) => {
      if (selectedServices.findIndex(s => s?.id === id) === -1) {
        selectedServices.push({
          id,
          name,
        });
      }
      this.props.checkAndSetDirty();
      return { selectedServices };
    });
  };

  public removeService = (id: string) => {
    this.setState(({ selectedServices }) => {
      const selectIndex = selectedServices.findIndex(s => s?.id === id);
      if (selectIndex > -1) {
        selectedServices.splice(selectIndex, 1);
      }
      this.props.checkAndSetDirty();
      return { selectedServices };
    });
  };

  public addNewServicemap = () => {
    this.setState(({ serviceMaps }) => {
      serviceMaps.push({
        jql: '',
        service_id: '',
      });
      this.props.checkAndSetDirty();
      return { serviceMaps };
    });
  };

  public onServicemapDelete = (smIndex: number) => {
    this.setState(({ serviceMaps }) => {
      serviceMaps.splice(smIndex, 1);
      return { serviceMaps };
    });
    this.props.checkAndSetDirty();
  };

  public onServicemapChange = (smIndex: number, name: 'jql' | 'service_id', value: string) => {
    this.setState(({ serviceMaps }) => {
      serviceMaps[smIndex][name] = value;
      return { serviceMaps };
    });
    this.props.checkAndSetDirty();
  };

  public render() {
    const { appState, componentTabState } = this.state;

    if (appState === 'get-configs') {
      return (
        <div
          style={{ width: 800 }}
          onClick={event => {
            event.stopPropagation();
          }}
        >
          <div className="clearfix modal-header-container">
            <div className="float-left" style={{ display: 'flex' }}>
              <h1 className="modal-container-heading">Jira DC Extension</h1>
            </div>
          </div>
          <div>
            <Row justifyContent="center" alignItems="center">
              <SpinLoader />
              <span className="ml-10">Loading</span>
            </Row>
          </div>
        </div>
      );
    }

    return (
      <div
        style={{ width: 'calc(800px - 64px)' }}
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <div className="clearfix modal-header-container">
          <div className="float-left" style={{ display: 'flex' }}>
            <h1 className="modal-container-heading">Jira DC Extension</h1>
          </div>
        </div>
        <div>
          <Row justifyContent="flex-start" alignItems="center">
            {[
              {
                name: 'Token',
                key: 'token',
              },
              {
                name: 'Select Project',
                key: 'project',
                on: 'token',
              },
              {
                name: 'Map Status',
                key: 'map',
                on: 'token',
              },
              {
                name: 'Finish',
                key: 'service',
                on: 'token',
              },
            ]
              .filter(tab => (tab.on ? appState !== 'test-connections' : true))
              .map((tab, i, e) => {
                return (
                  <React.Fragment key={i}>
                    <button
                      className={cx('link', {
                        active: componentTabState === tab.key,
                      })}
                      style={{ marginRight: 5 }}
                      onClick={() => {
                        this.setState({ componentTabState: tab.key as any });
                      }}
                      tabIndex={0}
                    >
                      {tab.name}
                    </button>
                    {i !== 3 && e.length !== 1 && (
                      <span
                        style={{
                          borderTop: '1px solid var(--shades-grey)',
                          width: 60,
                        }}
                        className="ml-20 mr-20"
                      />
                    )}
                  </React.Fragment>
                );
              })}
          </Row>
          <div className="mt-20">{renders.call(this)}</div>
        </div>
      </div>
    );
  }
}

export default connect((state: IAppState) => ({
  organization: state.organization,
}))(JiraServerModal);
