import React, { useState } from 'react';

import { EXPORT_TYPE_DROPWDOWN } from '../../helpers';
import { EXPORT_TYPE } from '../../types/index.auditLogs';
import { useAuditLogsListContext } from '../../context/auditLogsContext';
import { Dropdown, FormButton, Input, Textarea } from 'library/atoms';
import FormField from 'library/molecules/FormField/FormField';
import { Modal } from 'library/molecules';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function ExportModal(props: ModalProps) {
  const { isOpen, onClose } = props;

  const [exportName, setExportName] = useState('');
  const [exportDescription, setExportDescription] = useState('');
  const [exportType, setExportType] = useState({
    label: EXPORT_TYPE.CSV,
    value: EXPORT_TYPE.CSV,
  });
  const context = useAuditLogsListContext();

  const downloadData = () => {
    context.exportLogsData(
      {
        fileName: exportName,
        description: exportDescription,
        type: exportType.value,
      },
      onClose,
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Export"
      primaryButton={
        <FormButton
          disabled={exportName.length === 0 || context.exportingData}
          isLoading={context.exportingData}
          onClick={downloadData}
          title="Export"
        />
      }
      secondaryButton={<FormButton variant="ghost" onClick={onClose} title="Cancel" />}
      body={
        <>
          <FormField label="Export Name*">
            <Input
              value={exportName}
              placeholder={'Enter export name'}
              onChange={e => setExportName(e.target.value)}
            />
          </FormField>
          <FormField label="Export Description">
            <Textarea
              value={exportDescription}
              placeholder={'Enter Description'}
              onChange={e => setExportDescription(e.target.value)}
            />
          </FormField>
          <FormField label="Export as">
            <Dropdown
              options={EXPORT_TYPE_DROPWDOWN}
              value={exportType}
              onChange={(val: any) => setExportType(val)}
            />
          </FormField>
        </>
      }
    />
  );
}

export default ExportModal;
