import { FormControl, Skeleton, Text } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';

import StatusPageComponentTimelineCard from './components/Card';
import useStatuspageComponentTimelineContext from './hook';

interface IProps {}

export default function StatusPageComponentTimelineForm(props: IProps) {
  const { timelineIsLoading } = useStatuspageComponentTimelineContext();
  return (
    <FormControl>
      <Skeleton
        isLoaded={!timelineIsLoading}
        startColor={THEME_COLORS.secondary[100]}
        endColor={THEME_COLORS.secondary[200]}
      >
        <Text
          color={THEME_COLORS.secondary[700]}
          fontSize={14}
          fontWeight={800}
          textAlign="start"
          marginBottom="6px"
        >
          Uptime Graph
        </Text>
      </Skeleton>
      <StatusPageComponentTimelineCard />
    </FormControl>
  );
}
