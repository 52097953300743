import { requestOrganizationStakeholders } from 'core/actions/organization/stakeholders';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { requestOrganizationCalendar } from '../../actions/organization/calender';
import { requestOrganizationEscalation } from '../../actions/organization/escalation';
import { requestOrganizationRunbook } from '../../actions/organization/runbooks';
import { requestOrganizationService } from '../../actions/organization/services';
import { requestOrganizationSquad } from '../../actions/organization/squads';
import { API } from '../../api';
import { REQUEST_ORG_SELECTED_TEAM_CHANGE } from '../../const/selectedTeam';
import { IAppState } from 'core/interfaces/IAppState';

const initOrg = (action$: ActionsObservable<any>, state$: StateObservable<IAppState>) =>
  action$.pipe(
    ofType(REQUEST_ORG_SELECTED_TEAM_CHANGE),
    map(action => action.payload),
    switchMap(({ teamId }: { teamId: string }) => {
      API.config.teamId = teamId;
      const epics = [
        requestOrganizationSquad(),
        requestOrganizationStakeholders(),
        requestOrganizationEscalation(),
        requestOrganizationService(),
        requestOrganizationCalendar(),
        requestOrganizationRunbook(),
      ];
      if (state$.value.organization.currentOrg.o?.config.show_migrated_schedules) {
        epics.push(requestOrganizationCalendar());
      }
      return epics;
    }),
  );

export default initOrg;
