import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import {
  REQUEST_ACCOUNT_PASSWORD_CHANGE,
  REQUEST_ACCOUNT_PASSWORD_CHANGE_FAIL,
} from '../../const/account';
import { IChangePassword } from '../../interfaces/IAccount';
import { changePassword } from '../../api/account';
import { onRequestPasswordChangeFail, onRequestPasswordChangeSuccess } from '../../actions/account';

const updateAccountPasswordEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ACCOUNT_PASSWORD_CHANGE),
    map((action: any) => action.payload),
    switchMap(({ data }: { data: IChangePassword }) => {
      return changePassword(data).pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ result }: { result: boolean }) => {
          if (!result) {
            return onRequestPasswordChangeFail('invalid Password');
          }
          return onRequestPasswordChangeSuccess();
        }),
        catchError(error =>
          of({
            type: REQUEST_ACCOUNT_PASSWORD_CHANGE_FAIL,
            payload: error.xhr,
            error: true,
          }),
        ),
      );
    }),
  );

export default updateAccountPasswordEpic;
