import { useDisclosure } from '@chakra-ui/hooks';
import { useState } from 'react';
import EmptyList from '../../components/EmptyList';
import { Loader, Modal } from 'library/molecules';
import { useLCRColumn, HoverComponent } from './TableActions';
import { VStack } from '@chakra-ui/layout';
import { FormButton as FormButtonAtoms, useToast } from 'library/atoms';
import { getLCREditPath } from '../../common/utils';
import { useHistory } from 'react-router';
import { useGetLCRList } from '../../hooks/lcrListing';
import { useDeleteLCR } from '../../hooks/lcrDelete';
import { useQueryClient } from 'react-query';
import { useUpdateLCRStatus } from '../../hooks/lcrUpdate';
import { useLCRStore } from '../../hooks/lcr.state';
import { API } from 'core/api';
import { shallowEqual, useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { userAccess } from 'core/userAccess';
import Table from 'library/molecules/Tablev2/Table';
import { Text } from 'library/atoms/Text';

function LCRTable() {
  const dialogAction = useDisclosure();
  const history = useHistory();
  const queryClient = useQueryClient();
  const toast = useToast();
  const lcrStatusUpdate = useUpdateLCRStatus();
  const { closeEnableModal, enableModalIsOpen, lcrStatusData } = useLCRStore();
  const teamId = API.config.teamId;
  const organization = useSelector((state: IAppState) => state.organization, shallowEqual);
  const team = organization.teams.t.find(t => t.id === teamId);

  const { data, isFetching, isLoading } = useGetLCRList();
  const deleteLCRList = useDeleteLCR();

  const [deleteLCR, setDeleteLCR] = useState<{ id: string; name: string } | null>(null);

  const columns = useLCRColumn();

  const onDelete = ({ id, name }: { id: string; name: string }) => {
    dialogAction.onOpen();
    setDeleteLCR({ id, name });
  };

  const onEdit = ({ id }: { id: string }) => {
    history.push(getLCREditPath(id));
  };

  const onDeleteLCR = async () => {
    try {
      await deleteLCRList.mutateAsync(deleteLCR?.id ?? '');
      dialogAction.onClose();
      queryClient.invalidateQueries('lcr-fetch');
    } catch (error) {
      console.error('Error deleting LCR data:', error);
      dialogAction.onClose();
    }
  };

  const onEnableLCR = async (enabled: boolean) => {
    try {
      const response = await lcrStatusUpdate.mutateAsync({
        id: lcrStatusData.id,
        enabled: enabled,
        source: 'LCR List Page',
      });
      const text = `Incident reporting is successfully ${
        response?.enabled ? 'enabled' : 'disabled'
      }!`;
      queryClient.invalidateQueries('lcr-fetch');
      queryClient.refetchQueries(`lcr-details-${lcrStatusData.id}`);

      closeEnableModal();

      toast({ text: text, status: 'success' });
    } catch (error) {
      console.error('failed');
    }
  };

  if (isLoading) {
    return (
      <VStack h="inherit" justifyContent="center">
        <Loader />
      </VStack>
    );
  }
  if ((data && data?.data?.length < 1) || data === undefined) {
    return <EmptyList />;
  }
  return (
    <>
      <Table
        data={data ? data?.data : []}
        columns={columns}
        hoverComponent={props => <HoverComponent {...props} onDelete={onDelete} onEdit={onEdit} />}
        isLoading={isFetching}
        hidePagination
      />

      <Modal
        isOpen={enableModalIsOpen}
        onClose={() => closeEnableModal()}
        title={`Do you want to ${
          lcrStatusData.enabled ? 'disable' : 'enable'
        } this routing number ?`}
        body={
          <Text variant="modal" color="secondary.700">
            {`${
              lcrStatusData.enabled ? 'Disabling' : 'Enabling'
            }  a Routing Number will remove all the configured actions and relevant data. This action cannot be undone. Are you sure you want to proceed?`}
          </Text>
        }
        primaryButton={
          <FormButtonAtoms
            variant="danger"
            isLoading={lcrStatusUpdate.isLoading}
            onClick={() => onEnableLCR(!lcrStatusData.enabled)}
            title={`Yes, ${lcrStatusData.enabled ? 'Disable' : 'Enable'}`}
          />
        }
        secondaryButton={
          <FormButtonAtoms variant="secondary" onClick={() => closeEnableModal()} title="Cancel" />
        }
      />
      {deleteLCR && (
        <Modal
          isOpen={dialogAction.isOpen}
          onClose={dialogAction.onClose}
          title={`Do you want to delete this routing number ?`}
          body={
            <Text variant="modal" color="secondary.700">
              Deleting a LCR will remove all the configured actions and relevant data. This action
              cannot be undone. Are you sure you want to proceed?
            </Text>
          }
          primaryButton={
            <FormButtonAtoms
              variant="danger"
              isLoading={deleteLCRList.isLoading}
              onClick={() => onDeleteLCR()}
              title="Yes, Delete"
            />
          }
          secondaryButton={
            <FormButtonAtoms variant="secondary" onClick={dialogAction.onClose} title="Cancel" />
          }
        />
      )}
    </>
  );
}

export default LCRTable;
