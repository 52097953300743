import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import React from 'react';
import { components } from 'react-select';
import { SelectOwnerDropdown } from 'views/main/organization/owner-filters/Dropdown';
import {
  DropdownOption,
  DropdownSection,
  Option,
  Owner,
} from 'views/main/organization/owner-filters/hooks/useEntityOwnerFilter';
import {
  SelectedFilterTag,
  SelectedFilterTags,
} from 'views/main/organization/owner-filters/SelectedFilterTags';

interface Props {
  dropdownOptions: DropdownOption[];
  filterTags: SelectedFilterTag[];
  onChangeHandler: (values: Option) => void;
  onClickApplyFilters: () => void;
  onCancel: () => void;
  onClearFilters: () => void;
  handleSelectedFilterTagClick: (type: Owner, id: string) => void;
  filtersSelected: boolean;
  filterName: string;
}

const Group = (props: any) => {
  const {
    children,
    label,
    selectProps: { inputValue },
  } = props;

  return (
    <Accordion
      allowToggle={true}
      defaultIndex={label === DropdownSection.YOU_AND_YOUR_SQUADS ? 0 : -1}
      {...(inputValue ? { index: 0 } : {})}
    >
      <AccordionItem>
        {({ isExpanded = false }) => (
          <>
            <AccordionButton
              _expanded={{ background: 'white !important' }}
              padding="8px 12px"
              sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}
            >
              <HStack p={0}>
                <Text color="#646464" fontWeight={700}>
                  {label}
                </Text>
                {isExpanded ? (
                  <ChevronDownIcon width={6} height={6} color="#646464" />
                ) : (
                  <ChevronRightIcon width={6} height={6} color="#646464" />
                )}
              </HStack>
            </AccordionButton>
            <AccordionPanel p={0}>{children}</AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

const Menu = (props: any) => {
  const { children } = props;
  return (
    <components.Menu {...props}>
      <Flex
        sx={{
          ...{
            boxShadow: '0px 4px 10px 0px #0000000D',
          },
          ...{
            boxShadow: '2px 0px 10px 0px #0000000D',
          },
          width: '100%',
        }}
      >
        {children}
      </Flex>
    </components.Menu>
  );
};

const ServiceFilters = ({
  onClickApplyFilters,
  onCancel,
  onClearFilters,
  filterTags,
  dropdownOptions,
  onChangeHandler,
  handleSelectedFilterTagClick,
  filtersSelected,
  filterName,
}: Props) => {
  return (
    <Box height="100%" pos="relative">
      <VStack spacing={4} align="flex-start" py={4} px={6}>
        <FormControl width="100%">
          <FormLabel>{filterName}</FormLabel>
          <SelectOwnerDropdown
            variant="avatar"
            teamOptionLabelStyles={{
              color: '#646464',
              fontSize: 'md',
              fontWeight: 700,
            }}
            options={dropdownOptions}
            value={[]}
            placeholder="Search for users or squads"
            components={{ Group, Menu }}
            onChange={value => onChangeHandler(value as Option)}
            applyChakraStyle={false}
            closeMenuOnSelect
          />
        </FormControl>
        <SelectedFilterTags
          tags={filterTags}
          visibleTagsCount={4}
          applyChakraStyle={false}
          onClose={handleSelectedFilterTagClick}
        />
      </VStack>
      <HStack
        width="100%"
        paddingY={4}
        paddingX={6}
        gap={4}
        sx={{ borderTop: '1px solid #CBD5E0', position: 'absolute', bottom: 0 }}
      >
        <Button
          variant="default"
          size="sm"
          onClick={onClickApplyFilters}
          isDisabled={!filtersSelected}
        >
          Apply
        </Button>
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="link"
          onClick={onClearFilters}
          color={THEME_COLORS.brand.blue}
          _hover={{ textDecoration: 'none' }}
          isDisabled={!filtersSelected}
        >
          Clear all filters
        </Button>
      </HStack>
    </Box>
  );
};

export default ServiceFilters;
