import * as React from 'react';
import { SVGProps } from 'react';

const SvgAzuremonitor = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
    <defs>
      <radialGradient
        id="azuremonitor_svg__a"
        cx={5.72}
        cy={7.45}
        r={8.42}
        gradientTransform="matrix(1.01 0 0 1.01 3.23 1.51)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.18} stopColor="#5ea0ef" />
        <stop offset={0.56} stopColor="#5c9fee" />
        <stop offset={0.69} stopColor="#559ced" />
        <stop offset={0.78} stopColor="#4a97e9" />
        <stop offset={0.86} stopColor="#3990e4" />
        <stop offset={0.93} stopColor="#2387de" />
        <stop offset={0.99} stopColor="#087bd6" />
        <stop offset={1} stopColor="#0078d4" />
      </radialGradient>
      <radialGradient
        id="azuremonitor_svg__b"
        cx={28.18}
        cy={202.29}
        r={2.7}
        gradientTransform="matrix(.95 0 0 .95 -17.77 -185.01)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.19} stopColor="#8c8e90" />
        <stop offset={0.35} stopColor="#848688" />
        <stop offset={0.6} stopColor="#6e7071" />
        <stop offset={0.91} stopColor="#4a4b4c" />
        <stop offset={1} stopColor="#3e3f3f" />
      </radialGradient>
    </defs>
    <ellipse cx={9} cy={9} rx={8.5} ry={8.47} fill="url(#azuremonitor_svg__a)" />
    <ellipse cx={9} cy={9} rx={7.4} ry={7.37} fill="#fff" />
    <path d="M2.72 9.44a6.24 6.24 0 0 0 1.82 4l2-2a3.53 3.53 0 0 1-1-2z" fill="#9cebff" />
    <path
      d="M13.13 4.27a6.25 6.25 0 0 0-3.69-1.53v2.79a3.41 3.41 0 0 1 1.71.7zm-8.26 0 2 2a3.41 3.41 0 0 1 1.71-.7V2.74a6.25 6.25 0 0 0-3.71 1.53zm6.91 2.58a3.6 3.6 0 0 1 .71 1.71h2.79a6.16 6.16 0 0 0-1.53-3.67z"
      fill="#32bedd"
    />
    <path d="m6.22 6.85-2-2a6.16 6.16 0 0 0-1.5 3.71h2.79a3.6 3.6 0 0 1 .71-1.71z" fill="#50e6ff" />
    <path
      d="M14.14 7a.45.45 0 0 0-.57-.25L9.45 8.41l.32.81 4.12-1.63a.44.44 0 0 0 .25-.59z"
      fill="#f04049"
    />
    <circle cx={9} cy={9} fill="url(#azuremonitor_svg__b)" r={1.2} />
  </svg>
);

export default SvgAzuremonitor;
