import React from 'react';

interface Props {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  totalCount: number;
}

function PageLengthSelector(props: Props): JSX.Element {
  const totalCount = props.totalCount;
  if (totalCount < 5) {
    return <> </>;
  }
  const intervals = [5];
  let increment = 5;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (intervals.length === 3) {
      break;
    }
    const current = intervals[intervals.length - 1];
    if (current > totalCount) {
      break;
    } else {
      intervals.push(current + increment);
    }
    increment += 5;
  }
  return (
    <div className="float-left" style={{ width: 180 }}>
      <select
        className="select-design"
        value={props.pageSize}
        onChange={event => props.setPageSize(+event.target.value)}
      >
        {intervals.map((interval: number, index: number) => {
          return (
            <option key={index} value={interval}>
              {interval} per page
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default PageLengthSelector;
