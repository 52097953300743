import { REQUEST_ROLES, REQUEST_ROLES_SUCCESS, REQUEST_ROLES_FAIL } from '../../const/public';
import { IRole } from '../../interfaces/IRole';

const requestRoles = () => ({
  type: REQUEST_ROLES,
  payload: {},
});

const onRequestRolesSuccess = (roles: IRole[]) => ({
  type: REQUEST_ROLES_SUCCESS,
  payload: {
    roles,
  },
});

const onRequestRolesFail = (error: any) => ({
  type: REQUEST_ROLES_FAIL,
  payload: {
    error,
  },
});

export { requestRoles, onRequestRolesSuccess, onRequestRolesFail };
