import { TextButton, Theme } from 'uie/components';
import { HStack, Text, Icon, VStack, Button, Flex, Box } from '@chakra-ui/react';
import React, { ReactNode, useMemo, useState } from 'react';
import { ActionMeta, StylesConfig } from 'react-select';
import CreatableReactSelect from 'react-select/creatable';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { truncate } from 'core/helpers/stringUtils';
import { THEME_COLORS } from 'library/theme/colors';

const { theme } = Theme;

const customStyles: StylesConfig<any, boolean> = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: '100%',
  }),
  control: provided => ({
    ...provided,
    margin: 0,
    padding: 5,
    border: 0,
    minWidth: 200,
    boxShadow: 'none',
  }),
  menu: () => ({ boxShadow: `inset 0 1px 0 gray.200` }),
};

type IProps = {
  value: { key: string; value: string };
  keysOptions: string[];
  valuesMap: { [key: string]: string[] };
  handleChange?: (key: string, value: string) => void;
  errorMsg?: string;
  duplicateTagKeys?: Record<string, number>;
};

const formatKeyOption: (
  data: any,
  formatOptionLabelMeta: FormatOptionLabelMeta<any>,
) => React.ReactNode = ({ label, valuesCount }, { context }) => (
  <VStack justifyContent="flex-start" alignItems="flex-start">
    <Text textAlign="left">{label}</Text>
    {valuesCount && context !== 'value' && (
      <Text size="sm" color="gray.500" variant="italic" fontSize="12px">
        {valuesCount} {valuesCount > 1 ? 'values' : 'value'}
      </Text>
    )}
  </VStack>
);

const TagGroup: React.FC<IProps> = ({ keysOptions, valuesMap, value, ...props }) => {
  const keysOptionsFormat = useMemo(() => {
    return keysOptions.map(key => ({
      value: key,
      label: key,
      valuesCount: valuesMap[key]?.length || 0,
    }));
  }, [keysOptions, valuesMap, value.key]);

  const valuesOptions = useMemo(
    () =>
      valuesMap[value.key]?.map(value => ({
        value: value,
        label: value,
      })) || [],
    [valuesMap, value.key],
  );

  const onUpdate = (type: 'key' | 'value') => (selected: any) => {
    const newKey = type === 'key' ? selected.value : value.key;
    let newValue = type === 'value' ? selected.value : value.value;

    if (type === 'key') {
      newValue = '';
    }

    props.handleChange && props.handleChange(newKey, newValue);
  };

  return (
    <Flex flexDirection="column" width="100%">
      <Flex width="100%" gap={1}>
        <DropdownContainer
          type="key"
          selected={value.key.length > 20 ? truncate(value.key, 20) : value.key}
          menuPlaceholder="Key"
          value={value.key ? [{ label: value.key, value: value.key }] : []}
          onChange={onUpdate('key')}
          formatOptionLabel={formatKeyOption}
          options={keysOptionsFormat}
          placeholder="Search Existing Keys"
          isInvalid={
            props.duplicateTagKeys?.[value.key] ? props.duplicateTagKeys?.[value.key] > 1 : false
          }
        />

        <DropdownContainer
          type="value"
          selected={value.value.length > 20 ? truncate(value.value, 20) : value.value}
          menuPlaceholder="Value"
          value={value.value ? [{ label: value.value, value: value.value }] : []}
          onChange={onUpdate('value')}
          options={valuesOptions}
          placeholder="Search Existing Values"
        />
      </Flex>

      {props.errorMsg && (
        <Text fontSize={12} mt="4px" color="red.400">
          {props.errorMsg}
        </Text>
      )}
    </Flex>
  );
};

type DropdownContainerProps = {
  selected: string;
  menuPlaceholder: string;
  onClose?: () => void;
  isInitializedOpen?: boolean;
  type: 'key' | 'value';
  onChange: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;
  isInvalid?: boolean;
} & React.ComponentProps<typeof CreatableReactSelect>;

const DropdownContainer: React.FC<DropdownContainerProps> = ({
  selected,
  menuPlaceholder,
  onClose,
  isInitializedOpen,
  onChange,
  type,
  isInvalid,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitializedOpen || false);
  const toggleOpen = () => {
    if (isOpen) onClose?.();

    setIsOpen(!isOpen);
  };

  const handleChange = (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
    toggleOpen();
    onChange?.(newValue, actionMeta);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <Button
          fontWeight="normal"
          w="100%"
          onClick={toggleOpen}
          variant="outline"
          boxShadow={'0 0 0 1px gray.200'}
          color={'gray.600'}
          bg="whiteAlpha.100"
          p={5}
        >
          <HStack w="100%" spacing="auto" textAlign="left">
            <Text fontSize="16px" fontWeight="normal" w="95%">
              {selected || menuPlaceholder}
            </Text>
            <Icon w="5%" as={ChevronDownIcon} />
          </HStack>
        </Button>
      }
      isInvalid={isInvalid}
    >
      <CreatableReactSelect
        autoFocus
        backspaceRemovesValue={false}
        closeMenuOnSelect={false}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: null,
          ClearIndicator: () => null,
        }}
        noOptionsMessage={() => `No ${type}s found. Type and add a new one`}
        formatCreateLabel={(inputValue: string) => (
          <VStack justifyContent="flex-start" alignItems="flex-start">
            <Text>{`+ ${inputValue}`}</Text>
            <Text fontSize={10} mt="12px" fontWeight="500">
              Click + to add custom {type}
            </Text>
          </VStack>
        )}
        menuIsOpen
        controlShouldRenderValue={false}
        styles={customStyles}
        onChange={handleChange}
        {...props}
      />
    </Dropdown>
  );
};

const Menu = (props: JSX.IntrinsicElements['div']) => {
  const shadow = '#80808045';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
        fontSize: 14,
      }}
      {...props}
    />
  );
};

const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
interface DropdownProps {
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
  children: ReactNode;
  readonly isInvalid?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({ children, isOpen, target, onClose, isInvalid }) => (
  <Box
    sx={{
      position: 'relative',
      border: isInvalid ? `1px solid ${THEME_COLORS.brand.red}` : `1px solid gray.400`,
      borderRadius: 4,
      width: '100%',
    }}
  >
    <Flex alignItems="center">{target}</Flex>
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </Box>
);

export default TagGroup;
