import { BoxProps, Divider, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import * as React from 'react';

import { StepCircle } from './steps-circle';

interface StepProps extends BoxProps {
  title: string;
  description: string;
  isCompleted: boolean;
  isActive: boolean;
  isLastStep: boolean;
  isFirstStep: boolean;
  step: number;
  setSliderValue: (step: number) => void;
}

export const Step = (props: StepProps) => {
  const {
    isActive,
    isCompleted,
    isLastStep,
    isFirstStep,
    title,
    description,
    step,
    ...stackProps
  } = props;
  const isMobile = useBreakpointValue({ base: true, md: false });

  const orientation = useBreakpointValue<'horizontal' | 'vertical'>({
    base: 'vertical',
    md: 'horizontal',
  });

  return (
    <Stack spacing="5" direction={{ base: 'row', md: 'column' }} flex="3" {...stackProps}>
      <Stack
        spacing="0.5"
        pb={isMobile && !isLastStep ? '8' : '0'}
        align={{ base: 'start', md: 'center' }}
      >
        <Text color="emphasized" fontWeight="small">
          {title}
        </Text>
      </Stack>
      <Stack spacing="0" align="center" direction={{ base: 'column', md: 'row' }}>
        <Divider
          width="95px"
          display={isMobile ? 'none' : 'initial'}
          orientation={orientation}
          borderWidth="1px"
          borderColor={isFirstStep ? 'transparent' : isCompleted || isActive ? 'accent' : 'inherit'}
        />
        <StepCircle
          isActive={isActive}
          isCompleted={isCompleted}
          step={step}
          onClick={() => props.setSliderValue(step - 1)}
        />
        <Divider
          width="100px"
          orientation={orientation}
          borderWidth="1px"
          borderColor={isCompleted ? 'accent' : isLastStep ? 'transparent' : 'inherit'}
        />
      </Stack>
    </Stack>
  );
};
