import { array, object, string, boolean } from 'yup';

export const LCRFormValidation = object().shape({
  name: string()
    .required('Name is required')
    .max(250, 'Max characters allowed are 250')
    .trim()
    .min(1, 'Name cannot be empty'),
  greetingMessage: string().max(1000, 'Max characters allowed are 1000').trim(),
  country: object().shape({
    id: string().required('Country is Required'),
    name: string(),
  }),
  description: string().trim(),
  routingNumber: string()
    .required('Routing is required')
    .max(250, 'Max characters allowed are 10')
    .trim()
    .min(1, 'Routing Number cannot be empty'),
});
