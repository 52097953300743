import { Grid } from 'uie/components';
import { ITask, ITasks } from 'core/interfaces/ITask';
import React, { ForwardedRef, MutableRefObject, RefObject } from 'react';
import { CHECK } from '../taskAndRunbook.constants';
import TaskOrRunbookStep from '../taskOrRunbookStep';
import { EditOrDeleteTask } from '../editAndDelete';

interface ITaskListProps {
  enableDelete: number;
  setEnableDelete: (value: number) => void;
  sidebarState: string;
  tasks: ITasks | undefined;
  updateTask: (index: number, completed: boolean, content: string, type: string) => void;
  collapseTask: boolean;
  setTaskCollapse: (value: boolean) => void;
  listOfCheckedTask: ITask[] | undefined;
  setEnableDeleteModal: (value: boolean) => void;
  setDeleteTaskIndex: (value: number) => void;
  setUpdateTaskFn: (task: ITask, index: number) => void;
  isSuppressedIncident: boolean;
}

interface ITaskStepsProps {
  index: number;
  element: ITask;
  updateTask: (index: number, completed: boolean, content: string, type: string) => void;
  sidebarState: string;
  enableDelete: number;
  setEnableDeleteModal: (value: boolean) => void;
  setDeleteTaskIndex: (value: number) => void;
  setUpdateTaskFn: (task: ITask, index: number) => void;
  isSuppressedIncident: boolean;
}

const TaskSteps = ({
  index,
  element,
  updateTask,
  sidebarState,
  enableDelete,
  setEnableDeleteModal,
  setDeleteTaskIndex,
  setUpdateTaskFn,
  isSuppressedIncident,
}: ITaskStepsProps) => {
  const { completed, content } = element;
  return (
    <>
      <TaskOrRunbookStep
        checkedValue={completed}
        content={content}
        update={() => {
          updateTask(index, !completed, content, CHECK);
        }}
        sideBarState={sidebarState}
        isSuppressedIncident={isSuppressedIncident}
      />
      <EditOrDeleteTask
        element={element}
        index={index}
        enableDelete={enableDelete}
        setEnableDeleteModal={setEnableDeleteModal}
        setDeleteTaskIndex={setDeleteTaskIndex}
        setUpdateTaskFn={setUpdateTaskFn}
        isSuppressedIncident={isSuppressedIncident}
      />
    </>
  );
};

function TaskList(props: ITaskListProps, ref: ForwardedRef<HTMLDivElement[]>) {
  const collpaseValue = props.collapseTask;
  const taskList = props.tasks?.tasks || [];
  const incidentDetailTaskRef = ref as MutableRefObject<HTMLDivElement[]>;
  const pushRefs = (element: HTMLDivElement | null) => {
    if (element && !incidentDetailTaskRef.current?.includes(element)) {
      incidentDetailTaskRef.current?.push(element);
    }
  };

  return (
    <Grid flexWidth={12} className="ml-10 mb-10">
      {collpaseValue && (
        <div className="mt-10 mb-10 ml-10" style={{ width: '100%' }}>
          {taskList.map((task, index) => {
            return (
              <div
                {...{ className: 'incident_details_step' }}
                key={index}
                ref={element => {
                  pushRefs(element);
                }}
                onMouseEnter={() => {
                  props.setEnableDelete(index);
                }}
                onMouseLeave={() => {
                  props.setEnableDelete(-1);
                }}
              >
                {/* child */}
                <TaskSteps
                  {...{
                    index,
                    element: task,
                    updateTask: props.updateTask,
                    sidebarState: props.sidebarState,
                    enableDelete: props.enableDelete,
                    setEnableDeleteModal: props.setEnableDeleteModal,
                    setDeleteTaskIndex: props.setDeleteTaskIndex,
                    setUpdateTaskFn: props.setUpdateTaskFn,
                    isSuppressedIncident: props.isSuppressedIncident,
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </Grid>
  );
}

export default React.forwardRef(TaskList);
