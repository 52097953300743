import { DROPDOWN_OPTIONS, ORG } from '../types';

export const getServiceOptions = (org?: ORG) => {
  // generates array of services for Service dropdown
  // uses redux store value as data source
  const serviceArray = org?.services?.s ?? [];

  return serviceArray.reduce((acc: DROPDOWN_OPTIONS[], service) => {
    acc.push({
      label: service.name,
      value: service.id,
    });
    return acc;
  }, []);
};
