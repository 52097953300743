import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import libraryTheme from 'library/theme';
import { Popover, PopoverProps } from 'library/atoms';
import chakraTheme from 'views/main/organization/schedules/theme';

export function EntityDetailsPopover<T>({
  children,
  applyChakraStyle,
  ...rest
}: PopoverProps & { applyChakraStyle?: boolean }) {
  //libraryTheme was overiding the default theme so extendTheme was added
  const theme = extendTheme(chakraTheme, libraryTheme);
  return (
    <ChakraProvider resetCSS={applyChakraStyle} theme={theme}>
      <Popover {...rest}>{children}</Popover>
    </ChakraProvider>
  );
}
