import {
  DialogModalFrame,
  DropDown,
  ErrorBlock,
  FocusBlock,
  Grid,
  Label,
  Para,
  TextButton,
  Theme,
} from 'uie/components';
import Tippy from '@tippy.js/react';
import UnsavedChangesGuard from 'components/unsavedChangesGuard';
import UpgradeOnlyModal from 'components/upgradeonly.tooltip';
import { T_WA_GS_POSTMORTEM_DOWNLOADED_WEB_V1 } from 'core/const/tracker';
import { IAppState } from 'core/interfaces/IAppState';
import { IComponentErrorState } from 'core/interfaces/IComponentState';
import { IOrganization } from 'core/interfaces/IOrganization';
import { CrownSmallIcon, DownloadIcon } from 'icons';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AppTracker } from 'shared/analytics/tracker';
import { exception } from '../../../../../../core/exception';
import { IIncident } from '../../../../../../core/interfaces/IIncidents';
import { IPostmortem } from '../../../../../../core/interfaces/IPostmortems';
import {
  BillingService,
  IncidentService,
  PostmortemService,
} from '../../../../../../core/services';
import PostmortemModal from './postmortem.modal';
import { POSTMORTEM_STATUS_OPTIONS } from 'views/main/organization/incident-list/filters/manager/constant';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

const { theme } = Theme;

interface IProps extends Pick<IAppState, 'organization'> {
  selectedOrg: IOrganization;
  incident: IIncident;
  withTextButton?: boolean;
}

const Postmortem: React.FC<IProps> = (props: IProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [postmortemLimitExceeded, setLimitExceeded] = useState(false);
  const [isPostmortemFeatureAllowed, setFeatureAllowed] = useState(false);
  const [orgPostmortemCount, setOrgPostmortemCount] = useState(0);
  const [incident, setIncident] = useState<IIncident>(props.incident);

  const postmortemService = new PostmortemService('');
  const _incidentService = new IncidentService();

  const [downoading, setDownloading] = useState('');
  const [error, setError] = useState<IComponentErrorState>({});
  const [downloadMessage, setDownloadMessage] = useState('');
  const [isModalDirty, setIsModalDirty] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const search = location.search.split('=');
    if (search[0] != 'action' && search[1] != 'postmortem') {
      return;
    }
    const incidentID = location.pathname.split('/')[location.pathname.split('/').length - 1] || '';
    const incidentRes = _incidentService.getIncidentById(incidentID).then(incident => {
      setIncident(incident.data.data);
      setOpen(true);
    });
  }, [location]);

  const _PTMService = new PostmortemService(incident.id);

  const toggleModal = () => setOpen(!open);

  type TDownloadFunctionArguments = 'pdf' | 'markdown';

  const [postmortem, setPostmortem] = useState<IPostmortem | null>(null);
  const originalPostmortem = useRef<IPostmortem | null>(null);

  const _uA = useUserAccess();
  const hasRead = _uA.hasReadAccess('services');
  const hasCreate = _uA.hasCreateAccess('services');
  const hasUpdate = _uA.hasUpdateAccess('services');
  const hasDelete = _uA.hasDeleteAccess('services');

  const getPostmortem = useCallback(async () => {
    setLoading(true);
    try {
      const response = await _PTMService.get();
      if (response.status === 200) {
        const userPostmortem: IPostmortem = response.data.data;
        //even in the case of 200 the followup is may be coming null
        if (!userPostmortem.follow_ups) {
          userPostmortem.follow_ups = [];
        }
        setPostmortem(userPostmortem);
        originalPostmortem.current = structuredClone(userPostmortem);
      } else if (response.status === 204) {
        setPostmortem(null);
        originalPostmortem.current = null;
      }
    } catch (err: any) {
      /**  when postmortem is not found set postmortem null */
      if (err.response?.data?.meta?.status === 403) {
        setPostmortem(null);
        originalPostmortem.current = null;
      }
      exception.handle('E_GET_POSTMORTEM', err);
    } finally {
      setLoading(false);
    }
  }, [incident.id]);

  const downloadPostmortem = (type: TDownloadFunctionArguments) => async () => {
    try {
      type === 'pdf' ? setDownloading('pdf') : setDownloading('markdown');
      setDownloadMessage(`Please wait. Downloading under process...`);
      const fileName = `${moment(new Date()).format('YYYYMMDDHHmm')}-${
        props.selectedOrg.organizationName
      }-PM-${incident.id}.${type}`;

      let postmortemFile;

      if (type === 'pdf') {
        const response = await _PTMService.download('pdf');

        const res = await fetch(`data:application/pdf;base64,${response.data}`);
        const buf = await res.arrayBuffer();
        postmortemFile = new File([buf], fileName, { type: 'application/pdf' });

        AppTracker.track(T_WA_GS_POSTMORTEM_DOWNLOADED_WEB_V1, { 'Postmortem Format': type });
        // type === `md`
      } else {
        const postmortemMD = postmortem?.postmortem;

        const followUpsMD = postmortem?.follow_ups?.reduce(
          (accumulatedValue, followUp) =>
            `${accumulatedValue} - [${followUp.is_done ? 'x' : ' '}] ${followUp.task}\n`,
          '',
        );

        const fileContent = `${postmortemMD}\n\n# Follow-Ups\n${followUpsMD}`;

        postmortemFile =
          typeof File === 'function'
            ? new File([fileContent], fileName, { type: '.md' })
            : new Blob([postmortemMD ? postmortemMD : ''], { type: '.md' });

        AppTracker.track(T_WA_GS_POSTMORTEM_DOWNLOADED_WEB_V1, { 'Postmortem Format': type });
      }

      const blobURL = window.URL.createObjectURL(postmortemFile);

      const hiddenElement = document.createElement('a');
      hiddenElement.href = blobURL;
      hiddenElement.target = '_blank';
      hiddenElement.download = fileName;
      hiddenElement.click();

      setDownloading('');
      setDownloadMessage('');
      setError({});
    } catch (err: any) {
      exception.handle('E_DOWNLOAD_POSTMORTEM', err);
      setDownloading('');
      setError({ message: 'Error while downloading file. Please try again' });
    }
  };
  const closeModal = () => {
    if (isModalDirty) setShowUnsavedChangesModal(true);
    else {
      setShowUnsavedChangesModal(false);
      history.replace(`${incident.id}`);
      setOpen(false);
    }
  };
  const handleChangeInPostmortemModal = (isDirty: boolean) => {
    if (isDirty) {
      if (!isModalDirty) setIsModalDirty(true);
    } else {
      setIsModalDirty(false);
    }
  };
  const onSaveUpdate = () => {
    setIsModalDirty(false);
    setShowUnsavedChangesModal(false);
    setOpen(false);
    history.replace(`${incident.id}`);
  };
  const closeUnsavedChangesModal = () => setShowUnsavedChangesModal(false);

  const onDiscardChanges = () => {
    setPostmortem(structuredClone(originalPostmortem.current));
    setShowUnsavedChangesModal(false);
    setIsModalDirty(false);
    setOpen(false);
    history.replace(`${incident.id}`);
  };

  const queryParams = new URLSearchParams(location.search);

  const checkAndAddPostmortem = () => {
    if (!postmortem && !open && postmortemLimitExceeded) {
      setShowUpgradeModal(true);
    } else {
      history.push({ search: `${queryParams.toString()}?action=postmortem` });
    }
  };

  //

  useEffect(() => {
    getPostmortem();
  }, [getPostmortem]);
  useEffect(() => {
    async function getPostmortemCount() {
      try {
        const {
          data: {
            data: { count: orgCount },
          },
        } = await postmortemService.getOrgCount();
        setOrgPostmortemCount(orgCount);
      } catch (err: any) {
        console.log(err);
      }
    }
    getPostmortemCount();
  }, []);

  const isDisabledPostmortem = () => {
    return !isPostmortemFeatureAllowed || (!postmortem && postmortemLimitExceeded);
  };

  const userAccess = useUserAccess();

  const canCreatePostmortem = userAccess.hasCreateAccess('postmortems');
  const canReadPostmortem = userAccess.hasReadAccess('postmortems');

  const havePermissions = postmortem ? canReadPostmortem : canCreatePostmortem;

  useEffect(() => {
    setLimitExceeded(BillingService.isLimitExceeded(props, 'postmortem', () => orgPostmortemCount));
    setFeatureAllowed(!BillingService.isFeatureDisabled(props, 'postmortem'));
  }, [orgPostmortemCount, props.organization.plan]);

  const rule = BillingService.getRule(props, 'postmortem');
  const limit: number | undefined | 'unlimited' = rule?.unlimited
    ? 'unlimited'
    : rule?.quantity || undefined;
  const renewable = rule?.renewable || false;

  const postmortemStatus = {
    label: postmortem ? POSTMORTEM_STATUS_OPTIONS[postmortem.status].label : '',
    Icon: postmortem ? POSTMORTEM_STATUS_OPTIONS[postmortem.status].Icon : '',
  };

  return (
    <>
      {!loading && (
        <Grid type="column">
          <Grid>
            <Grid type="column">
              <Grid>
                <NoPermissionTooltip isDisabled={havePermissions}>
                  <TextButton
                    style={{
                      padding: '8px 16px',
                    }}
                    disabled={!havePermissions}
                    onClick={
                      havePermissions
                        ? isDisabledPostmortem()
                          ? () => setShowUpgradeModal(true)
                          : checkAndAddPostmortem
                        : () => {}
                    }
                  >
                    <Para color={theme.shades.white} fontSize={16} fontWeight={500}>
                      {postmortem ? 'View ' : 'Start '} Postmortem
                    </Para>
                    {isDisabledPostmortem() && <CrownSmallIcon className="ml-5 pro" />}
                  </TextButton>
                </NoPermissionTooltip>
              </Grid>
              {postmortem?.status && (
                <Grid
                  alignItems="center"
                  width="100%"
                  style={{
                    marginTop: '5px',
                  }}
                >
                  <Grid width="100%">
                    <Label
                      fontSize={9}
                      fontWeight={700}
                      style={{
                        margin: 'auto',
                      }}
                    >
                      Postmortem Status : {postmortemStatus.label}
                    </Label>
                  </Grid>
                  <Grid style={{ marginLeft: '4px' }}>
                    <postmortemStatus.Icon />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <UpgradePlanModal
              hasBillingPermission={BillingService.hasManageBillingPermission(props)}
              onCancel={() => {
                setShowUpgradeModal(false);
              }}
              showModal={showUpgradeModal}
              message={BillingService.getMessage(limit, 'postmortem', props, renewable)}
              header={BillingService.getHeader(limit, 'postmortem', props)}
            />
            {postmortem && isPostmortemFeatureAllowed && (
              <DropDown
                offset="-150px"
                hook={
                  <DownloadIcon
                    className="cursor-pointer"
                    height={16}
                    width={16}
                    fill={theme.shades.black}
                    style={{
                      margin: '8px',
                    }}
                  />
                }
                width="170px"
                maxWidth="200px"
                height="120px"
                padding="0px"
                maxHeight="150px"
              >
                <Grid type="column">
                  <Label fontSize={14} fontWeight={500} style={{ padding: '8px 16px' }}>
                    Download Postmortem as
                  </Label>
                  {(['pdf', 'markdown'] as TDownloadFunctionArguments[]).map((type, index) => {
                    return (
                      <FocusBlock
                        key={index}
                        value={index}
                        disabled={type === downoading}
                        onSelectValue={downloadPostmortem(type)}
                      >
                        <Grid className="float-left">
                          <Para
                            fontSize={14}
                            style={{ textTransform: 'capitalize' }}
                            className="item-box-tagline ma-0"
                          >
                            {type}
                          </Para>
                        </Grid>
                      </FocusBlock>
                    );
                  })}
                </Grid>
              </DropDown>
            )}
          </Grid>
          <Grid type="column">
            {!error.message && (
              <Para
                fontWeight={500}
                fontSize={12}
                color={theme.primary.default}
                style={{ padding: 5 }}
              >
                {downloadMessage}
              </Para>
            )}
            {error.message && <ErrorBlock fontWeight={500}>{error.message}</ErrorBlock>}
          </Grid>
        </Grid>
      )}

      <DialogModalFrame
        id="incident_details__postmortem"
        onClose={closeModal}
        padding="32px"
        width="800px"
      >
        {open && !loading && (
          <PostmortemModal
            postmortem={postmortem}
            incidentId={incident.id}
            incident={incident}
            hideModal={closeModal}
            getPostmortem={getPostmortem}
            isModalDirty={isModalDirty}
            setModalIsDirty={handleChangeInPostmortemModal}
            onSaveUpdate={onSaveUpdate}
          />
        )}
      </DialogModalFrame>
      <UnsavedChangesGuard
        isManual={true}
        showModal={showUnsavedChangesModal}
        onDiscardChanges={onDiscardChanges}
        onModalClose={closeUnsavedChangesModal}
      />
    </>
  );
};

export default connect((state: IAppState) => ({
  organization: state.organization,
  selectedOrg: state.userOrganizations.o.find(
    o => o.organizationId === state.INIT_ORG.orgId,
  ) as IOrganization,
}))(Postmortem);
