import { Para, Theme } from 'uie/components';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { EntityType, hasReadAccessToEntity, JSXResponseForEntity } from '../helpers';

const ResponsesForReassigned: React.FC<
  Omit<JSXResponseForEntity, 'getEntityComponent' | 'getEntityName'>
> = ({ logMessage, getEntityComponentWithTruncatedName, log }) => {
  const { theme: legacyTheme } = Theme;
  const acceptableEntityTypesForPopover = ['user', 'service'];
  const userHasReadSquadsPermission = useUserAccess().hasReadAccess('squads');

  const regex = logMessage.includes(' by ')
    ? /Incident reassigned to (.*) by/
    : /Incident reassigned to (.*)/;
  const assignedToEntityName = logMessage.match(regex)?.[1] || '';
  const assignedByEntityName = logMessage.split(' by ')[1];
  const assignedByEntityComponent =
    assignedByEntityName !== 'Deleted Entity' ? (
      getEntityComponentWithTruncatedName({
        componentType: 'linkWPopover',
        name: assignedByEntityName,
        id: log.assignedBy,
        type: 'user',
        popoverPlacement: 'left',
      })
    ) : (
      <>{assignedByEntityName}</>
    );

  const canNavigateToAssignedToEntity =
    assignedToEntityName !== 'Deleted Entity' &&
    hasReadAccessToEntity(log.assignedTo as EntityType);
  const assignedToEntityComponent = canNavigateToAssignedToEntity ? (
    getEntityComponentWithTruncatedName({
      componentType: acceptableEntityTypesForPopover.includes(log.assignedTo)
        ? 'linkWPopover'
        : log.assignedTo === 'squad'
        ? userHasReadSquadsPermission
          ? 'linkWOPopover'
          : 'simple'
        : 'linkWOPopover',
      name: assignedToEntityName,
      type: log.assignedTo as EntityType,
      id: log.id,
      popoverPlacement: 'left',
    })
  ) : (
    <>{assignedToEntityName}</>
  );

  return (
    <Para fontSize={14} color={legacyTheme.shades.cement}>
      Incident reassigned to {assignedToEntityComponent}
      {assignedByEntityComponent ? ' by ' : ''}
      {assignedByEntityComponent ?? <></>}
    </Para>
  );
};
export default ResponsesForReassigned;
