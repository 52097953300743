const overrideOptions = [{ label: 'Current Schedule', value: 'schedule' }];

const overrideReasons = [
  { label: 'Unavailable during that time', value: '0' },
  { label: 'Another Meeting', value: '1' },
  { label: 'On Leave', value: '2' },
  { label: 'Other Reason', value: '3' },
];

export { overrideOptions, overrideReasons };
