import React from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

import { FormikTouched, useFormikContext } from 'formik';
import { rotationsCustomizePattern } from '../../constants/rotations.customize';
import { INewSchedule, IRotation } from '../../interface/schedule';
import { schedulesColorPalette } from '../../constants/schedules.rotation-template';

type ErrorMessageObject = {
  color: string;
};

const PatternColor = ({ rotationId }: Record<string, string>) => {
  const {
    values: { rotations },
    errors,
    touched,
    handleChange,
  } = useFormikContext<INewSchedule>();

  const currentRotationColor = rotations.find(rt => rt.id === rotationId)?.color ?? '#FFFFFFFF';
  const currentRotationIndex = rotations.findIndex(rt => rt.id === rotationId);

  return (
    <FormControl
      isInvalid={
        !!(
          (errors.rotations && errors.rotations[currentRotationIndex]
            ? (errors.rotations[currentRotationIndex] as ErrorMessageObject).color
            : false) &&
          (touched.rotations && touched.rotations[currentRotationIndex]
            ? (touched.rotations[currentRotationIndex] as FormikTouched<IRotation>).color
            : false)
        )
      }
    >
      <VStack align="left" mt={6}>
        <FormLabel variant="rotationPattern">
          {rotationsCustomizePattern.labels.rotationColor}
        </FormLabel>
        <ButtonGroup alignSelf="flex-start">
          {schedulesColorPalette.map((colorObject, index) => (
            <Button
              name={`rotations.${currentRotationIndex}.color`}
              m={1.5}
              key={index}
              background={colorObject.value}
              value={colorObject.value}
              onClick={handleChange}
            >
              {currentRotationColor === colorObject.value ? (
                <CheckIcon color="gray.900" h={2} w={2} />
              ) : (
                ''
              )}
            </Button>
          ))}
        </ButtonGroup>
      </VStack>
      <FormErrorMessage>
        {errors.rotations && errors.rotations[currentRotationIndex]
          ? (errors.rotations[currentRotationIndex] as ErrorMessageObject).color
          : ''}
      </FormErrorMessage>
    </FormControl>
  );
};

export default PatternColor;
