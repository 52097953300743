import React, { FC, useContext, createContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import {
  STATUSPAGE_V2_CREATE_PATH,
  STATUSPAGE_V2_DETAILS,
  STATUSPAGE_V2_PATH,
} from 'views/main/routes/routes';

import { ChakraProvider } from '@chakra-ui/react';

import { IAppState } from '../../../../core/interfaces/IAppState';
import ErrorBoundary from './components/ErrorBoundary';
import chakraTheme from './theme';
import StatusPageAdd from './statuspage.add';
import StatusPageDashboard from './statuspage.list';
import StatusPageDetails from './statuspage.details';
import './theme/index.css';

interface IStatusPageContext {
  statusPagePaths: Record<string, any>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setPreviousStep: () => void;
  navigateToPath: (path: string) => void;
}

export const ChakraWrapper: FC = ({ children }) => {
  return <ChakraProvider theme={chakraTheme}>{children}</ChakraProvider>;
};

export const StatusPageContext = createContext<IStatusPageContext>({} as IStatusPageContext);

const statusPagePaths = {
  STATUSPAGE_V2_CREATE_PATH: STATUSPAGE_V2_CREATE_PATH,
  STATUSPAGE_V2_DETAILS: STATUSPAGE_V2_DETAILS,
  STATUSPAGE_V2_PATH: STATUSPAGE_V2_PATH,
};

const StatusPageV2 = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const history = useHistory();

  const navigateToPath = (path: string) => {
    history.push(path);
  };

  const setPreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <ChakraWrapper>
      <ErrorBoundary>
        <StatusPageContext.Provider
          value={{ statusPagePaths, activeStep, setActiveStep, setPreviousStep, navigateToPath }}
        >
          <Switch>
            <Route exact path={STATUSPAGE_V2_PATH}>
              <StatusPageDashboard />
            </Route>
            <Route exact path={STATUSPAGE_V2_CREATE_PATH}>
              <StatusPageAdd />
            </Route>
            <Route exact path={STATUSPAGE_V2_DETAILS}>
              <StatusPageDetails />
            </Route>
          </Switch>
        </StatusPageContext.Provider>
      </ErrorBoundary>
    </ChakraWrapper>
  );
};

export default StatusPageV2;
