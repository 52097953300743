import Tippy from '@tippy.js/react';
import { InfoIcon } from 'icons';
import { Para } from 'uie/components';

const TrailExpiredTooltip = (props: any) => {
  return (
    <>
      <Tippy
        content={
          <div
            style={{
              width: '372px',
              height: '104px',
              left: '609px',
              top: '615px',
              background: '#FFFFFF',
              boxShadow: '0px 0px 8px 2px rgba(0, 0, 0, 0.12)',
            }}
          >
            <Para
              style={{
                padding: '16px',
                fontFamily: 'Mulish',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
                color: '#969AA0',
              }}
            >
              You can't downgrade to the free plan. Should you wish to, reach out to our support
              team at{' '}
              <a
                href="mailto:support@squadcast.com"
                style={{
                  color: '#0F61DD',
                  fontFamily: 'Mulish',
                  fontWeight: 600,
                  fontStyle: 'normal',
                  lineHeight: '16px',
                  fontSize: '12px',
                }}
                target="_blank"
                rel="noreferrer"
              >
                support@squadcast.com
              </a>
            </Para>
          </div>
        }
        interactive={true}
        animation={'perspective'}
        placement="bottom"
      >
        <span>
          <InfoIcon />
        </span>
      </Tippy>
    </>
  );
};

export default TrailExpiredTooltip;
