import { Para, Theme } from 'uie/components';
import { IGeneralMessages } from '../GeneralMessages';

const PublicMessages = ({
  entityComponent,
  log,
  statusPages,
}: Pick<IGeneralMessages, 'entityComponent' | 'log' | 'statusPages'>) => {
  const { theme } = Theme;

  return (
    <>
      <Para fontSize={14} color={theme.shades.cement}>
        <span
          style={{
            ...{ textTransform: 'capitalize', fontWeight: 600, marginRight: 5 },
          }}
        >
          {entityComponent}
        </span>
        added an update to
        <span
          style={{
            textTransform: 'capitalize',
            fontWeight: 600,
            margin: '0 5px',
          }}
        >
          {(statusPages && statusPages[log.additionalInfo?.statusPageId ?? ''].name) ??
            'Deleted StatusPage'}
        </span>
        with the status
        <span style={{ margin: '0 5px', fontWeight: 600 }}>{log.additionalInfo?.status ?? ''}</span>
      </Para>
    </>
  );
};

export default PublicMessages;
