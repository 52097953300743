import { Grid } from '@chakra-ui/react';
import EasyMDE from '@SquadcastHub/easymde';
import React, { useEffect, useState } from 'react';
import { IProps, MDE } from './MdBlockStyle';
import { defaultOption, defaultToolbar } from './mde.constant';
import { createFileUploadIcon, dynamicDropdownHandler } from './mdeOptionHelper';
import { WarningIcon } from '@chakra-ui/icons';

const passedOptionMerger = (passedOption: EasyMDE.Options = {}) => {
  if (Object.keys(passedOption).length <= 0) {
    return { ...defaultOption };
  }
  if (passedOption.toolbar === false) {
    return { ...passedOption, toolbar: false };
  }
  return { ...passedOption, toolbar: defaultToolbar };
};

export const MdBlock = (props: IProps) => {
  const [mount, setMount] = useState<boolean>(false);
  const inputRef = props.inputref || React.createRef<null>();
  let mde: any = null;
  const giveParentToolbar = (): HTMLElement => {
    return inputRef?.current?.simpleMde.codemirror.getWrapperElement().parentElement;
  };

  useEffect(() => {
    setTimeout(() => {
      setMount(true);
      mde = inputRef?.current?.simpleMde;
      const toolbar = giveParentToolbar();
      const uploadFileButton: HTMLElement | null = toolbar?.querySelector('button.upload-file');
      // if there is a file uploadOption then create a file input element
      if (uploadFileButton) {
        createFileUploadIcon(uploadFileButton);
      }
      const dropdown =
        mde?.options.toolbar && mde?.options.toolbar.find((item: any, _: number) => item.children);
      // if there are any toolbar in dropdown then only plcae the cusor activity
      if (dropdown) {
        dynamicDropdownHandler(mde, giveParentToolbar);
      }
    }, 10);
    return () => {
      mde?.codemirror.off('cursorActivity', () => {
        setMount(false);
      });
    };
  });
  // create a temporary option object
  const optionsToUse = passedOptionMerger(props.options);
  return (
    <Grid width={props.width || '100%'} alignItems="flex-start">
      {mount && (
        <MDE
          {...props}
          options={optionsToUse}
          hideborders={props.hideborders}
          events={{
            ...props.events,
          }}
          ref={inputRef}
        />
      )}
      {props.error && <WarningIcon color="red" style={{ marginTop: 16, zIndex: 1 }} />}
    </Grid>
  );
};
/** @component */
export { MDE };
