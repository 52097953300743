import React from 'react';

import { Autocomplete, FocusBlock, Para, SelectBox } from 'uie/components';
import { Squad } from '../../types';
import { useParams, useHistory, generatePath } from 'react-router';
import { TEAM_SQUADS_PATH } from 'views/main/routes/routes';
import { SearchIcon } from 'icons';
import styled from 'styled-components';
import { useSearchSquad, Props } from './useSearchSquad';

function SearchSquad({ squads }: Props) {
  const { searchTerm, setSearchTerm, filteredSquads } = useSearchSquad({ squads });

  const params = useParams<{ id: string }>();

  const history = useHistory();

  return (
    <SearchInputWrapper>
      <Autocomplete
        onValueChange={(e, value: string) => {
          history.push(
            generatePath(TEAM_SQUADS_PATH, {
              id: params.id,
              sqid: value,
            }),
          );
          setSearchTerm('');
        }}
        value={searchTerm}
        height="auto"
        maxHeight="400px"
        width="250px"
        maxWidth="250px"
        onChange={e => setSearchTerm(e.target.value)}
        placeHolder="Search Squads"
        showDropDownOnSelect={true}
        searchPropsHook={{
          style: {
            height: '38px',
            fontSize: '14px',
            border: '1px solid #E5E5E5',
            boxShadow: 'none',
            borderRadius: '4px',
            paddingLeft: '30px',
            color: '#3D485E',
          },
        }}
      >
        {filteredSquads.map((m, i) => (
          <FocusBlock className="global--text-clip" key={i} value={m.id}>
            <Para fontSize={14}>{m.name}</Para>
          </FocusBlock>
        ))}
        {filteredSquads.length === 0 ? (
          <FocusBlock key={1} value={-1} disabled={true} isSelected={false}>
            <Para fontSize={14}>None found!</Para>
          </FocusBlock>
        ) : (
          <></>
        )}
      </Autocomplete>
      <SearchIcon
        style={{
          position: 'absolute',
          top: '11px',
          left: '9px',
        }}
        height="16"
        width="16"
      />
    </SearchInputWrapper>
  );
}

const SearchInputWrapper = styled.div`
  color: #b6bcc8;
  position: relative;
`;
export default SearchSquad;
