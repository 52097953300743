import momentTimeZone from 'moment-timezone';
import { getTZFormat } from '../helpers/helpers.date';

/** FIMXE: Use Intl api or a different library instead of moment-timezone */
const timeZoneMasterArray = momentTimeZone.tz.names();

export const timeZones = timeZoneMasterArray
  // .filter(timezone => timezone.includes('/') && !timezone.toLowerCase().startsWith('etc'))
  .map(timezone => {
    const label = '(' + getTZFormat(timezone, 'ZZZZ') + ')' + ' ' + timezone;
    return {
      label,
      value: timezone,
    };
  });
