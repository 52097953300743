import React, { ReactChild } from 'react';
import { Flyer, Shell } from './helper';
import Heading from '../Heading';
import Para from '../Para';
import Theme from '../Theme';

export interface IFrame {
  /**
   * App is online ?
   */
  isConnected?: boolean;
  /**
   * main app to render
   */
  children: ReactChild | any;
}

const Frame = (props: IFrame) => {
  const { theme } = Theme;

  return (
    <React.Fragment>
      {props.isConnected !== undefined && (
        <Shell className={`${!props.isConnected ? 'fly' : ''}`}>
          <Flyer>
            <Heading fontSize={24} height={35} color={theme.font.white}>
              Connection Error !
            </Heading>
            <Para fontSize={14} height="16px" color={theme.font.white}>
              Please check your internet connection
            </Para>
          </Flyer>
        </Shell>
      )}
      {props.children}
    </React.Fragment>
  );
};

export default Frame;
