import * as React from 'react';
import { SVGProps } from 'react';

const SvgLibreNmsLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path d="m11.2 6.9 1.9 1.9-.8.8-1.9-1.9.8-.8zm3.5-3.5 1.9 1.9-.8.8-1.9-1.9.8-.8zm-.1 6.9 1.9 1.9-.8.8-2-1.9.9-.8zm3.5-3.5L20 8.7l-.8.8-1.9-1.9.8-.8zm-7.6-1.4 1.9-1.9.8.8-1.9 1.9-.8-.8zm3.4 3.4 1.9-1.9.8.8-1.9 1.9-.8-.8zm3.4 3.4 1.9-1.9.8.7-2 1.9-.7-.7zM7 8.9 8.9 7l.8.8-2 1.8-.7-.7zm3.4 3.4 1.9-1.9.8.8-2 1.8-.7-.7zm3.3 3.4 1.9-1.9.8.8-1.9 2-.8-.9z" />
    <path
      d="m4.2 13.9 1.9 1.9-.8.8-1.9-1.9.8-.8zm3.5-3.5 1.9 1.9-.8.8-1.9-1.9.8-.8zm-.1 6.9 1.9 1.9-.8.8-1.9-1.9.8-.8zm3.5-3.5 1.9 1.9-.8.8-1.9-1.9.8-.8zM.8 10.5l1.9 1.9-.8.8L0 11.3l.8-.8zM4.3 7l1.9 1.9-.8.8-1.9-1.9.8-.8zm3.5-3.5 1.9 1.9-.7.8-2-1.9.8-.8zM11.3 0l1.9 1.9-.8.8-2-1.9.9-.8zM7.1 2 9 .1l.8.8-2 1.8-.7-.7zM3.6 5.5l1.9-1.9.8.8-2 1.9-.7-.8zM.1 9 2 7.1l.8.7-2 2L.1 9zm3.3 3.4 1.9-1.9.8.8-1.9 1.9-.8-.8zm3.4 3.4 1.9-1.9.8.8-1.9 1.9-.8-.8zm3.4 3.4 1.9-1.9.8.7-1.9 1.9-.8-.7z"
      style={{
        fill: '#db202e',
      }}
    />
  </svg>
);

export default SvgLibreNmsLogo20X20;
