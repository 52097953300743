import { ChakraProvider } from '@chakra-ui/react';
import { Layout } from 'library/molecules';
import libraryTheme from 'library/theme';
import { Route, Switch } from 'react-router';
import {
  LCR_CREATE_PATH,
  LCR_DETAILS_PATH,
  LCR_EDIT_PATH,
  LCR_PATH,
  LCR_SESSIONS_DETAILS_PATH,
  LCR_SESSIONS_PATH,
} from 'views/main/routes/routes';
import { ErrorBoundary } from 'views/shared/components';
import CreateOrUpdateLCR from './screens/create-or-update';
import LCRDetails from './screens/details';
import LCRDashboard from './screens/list';

const LCRComponent = () => {
  return (
    <ChakraProvider theme={libraryTheme}>
      <Layout>
        <ErrorBoundary>
          <Switch>
            <Route exact path={LCR_PATH} component={LCRDashboard} />
            <Route exact path={LCR_CREATE_PATH} component={CreateOrUpdateLCR} />
            <Route exact path={LCR_EDIT_PATH} component={CreateOrUpdateLCR} />
            <Route exact path={LCR_DETAILS_PATH} component={LCRDetails} />
            <Route exact path={LCR_SESSIONS_DETAILS_PATH} component={LCRDetails} />
            <Route exact path={LCR_SESSIONS_PATH} component={LCRDetails} />
          </Switch>
        </ErrorBoundary>
      </Layout>
    </ChakraProvider>
  );
};

export default LCRComponent;
