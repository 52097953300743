import React, { ReactNode, useState, useEffect, useRef, useCallback } from 'react';
import { Checkbox, CopyIconButton } from 'uie/components';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import breaks from 'remark-breaks';
import { EXPANDED } from './taskAndRunbook.constants';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

const renderMdLink = (props: any) => (
  <a href={props.href} target="_blank" rel="noopener noreferrer">
    {props.children}
  </a>
);

const CustomCodeRenderer = (props: any) => {
  return (
    <div
      className={
        props.sideBarState === EXPANDED
          ? 'incident_details__code-container expanded'
          : 'incident_details__code-container taskAndRunbookCodeContainer'
      }
    >
      <pre className="incident_details__raw-code">{props.value}</pre>
      <div className="incident_details__code-copy">
        <CopyIconButton type="transparent">{props.value}</CopyIconButton>
      </div>
    </div>
  );
};

interface IProps {
  checkedValue: boolean;
  content: string;
  update: () => void;
  sideBarState: string;
  isSuppressedIncident: boolean;
}

const TaskOrRunbookStep = ({
  checkedValue,
  content,
  update,
  sideBarState,
  isSuppressedIncident,
}: IProps) => {
  const hasUpdateAccess = useUserAccess().hasUpdateAccess('incidents');
  return (
    <div className="incident_details_taskOrRunbook_ReactMarkdown">
      {!isSuppressedIncident && (
        <NoPermissionTooltip isDisabled={hasUpdateAccess}>
          <Checkbox
            className="mr-20"
            checked={checkedValue}
            onChange={e => {
              update();
            }}
            disabled={!hasUpdateAccess}
          />
        </NoPermissionTooltip>
      )}
      <>
        {content ? (
          <ReactMarkdown
            className={
              sideBarState === EXPANDED ? 'incident_details__mde expanded' : 'incident_details__mde'
            }
            plugins={[breaks, gfm]}
            renderers={{
              link: renderMdLink,
              code: CustomCodeRenderer,
            }}
            source={content}
          />
        ) : null}
      </>
    </div>
  );
};

export default TaskOrRunbookStep;
