import { ofType, Epic } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of, ObservedValueOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { REQUEST_ORG_USER_ACL } from '../../const/acl';
import { getOrgUserAcl } from '../../api/organization/getUserAcl';
import {
  onRequestOrganizationUserAclFail,
  onRequestOrganizationUserAclSuccess,
} from '../../actions/organization/userAcl';
import { IUserAcl } from '../../interfaces/IUserAcl';
import { IAppState } from 'core/interfaces/IAppState';
import { ITeamRoleOperationAbility, MapTeamRoleIndexWithOperation } from 'core/interfaces/ITeams';

const getOrganizationsUserAclEpic: Epic<any, any, IAppState, any> = (action$, state$) =>
  action$.pipe(
    ofType(REQUEST_ORG_USER_ACL),
    map(action => action.payload),
    switchMap(() => {
      return getOrgUserAcl().pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ result, data }: { result: boolean; data: IUserAcl }) => {
          if (state$.value?.organization?.currentOrg?.o?.config?.obac_enabled) {
            return onRequestOrganizationUserAclSuccess({
              ...data,
              escalation_policies: Object.keys(data.escalation_policies ?? {}).reduce(
                (acc, key) => {
                  acc[key] = {
                    'create-escalation-policies': true,
                    'delete-escalation-policies': true,
                    'read-escalation-policies': true,
                    'update-escalation-policies': true,
                  };
                  return acc;
                },
                {} as Record<
                  string,
                  MapTeamRoleIndexWithOperation<'escalation-policies', ITeamRoleOperationAbility>
                >,
              ),
              postmortems: Object.keys(data.postmortems ?? {}).reduce((acc, key) => {
                acc[key] = {
                  'create-postmortems': true,
                  'delete-postmortems': true,
                  'read-postmortems': true,
                  'update-postmortems': true,
                };
                return acc;
              }, {} as Record<string, MapTeamRoleIndexWithOperation<'postmortems', ITeamRoleOperationAbility>>),

              schedules: Object.keys(data.schedules ?? {}).reduce((acc, key) => {
                acc[key] = {
                  'create-schedules': true,
                  'delete-schedules': true,
                  'read-schedules': true,
                  'update-schedules': true,
                };
                return acc;
              }, {} as Record<string, MapTeamRoleIndexWithOperation<'schedules', ITeamRoleOperationAbility>>),

              services: Object.keys(data.services ?? {}).reduce((acc, key) => {
                acc[key] = {
                  'create-services': true,
                  'delete-services': true,
                  'read-services': true,
                  'update-services': true,
                };
                return acc;
              }, {} as Record<string, MapTeamRoleIndexWithOperation<'services', ITeamRoleOperationAbility>>),

              squads: Object.keys(data.squads ?? {}).reduce((acc, key) => {
                acc[key] = {
                  'create-squads': true,
                  'delete-squads': true,
                  'read-squads': true,
                  'update-squads': true,
                };
                return acc;
              }, {} as Record<string, MapTeamRoleIndexWithOperation<'squads', ITeamRoleOperationAbility>>),

              status_pages: Object.keys(data.status_pages ?? {}).reduce((acc, key) => {
                acc[key] = {
                  'create-status-pages': true,
                  'delete-status-pages': true,
                  'read-status-pages': true,
                  'update-status-pages': true,
                };
                return acc;
              }, {} as Record<string, MapTeamRoleIndexWithOperation<'status-pages', ITeamRoleOperationAbility>>),
            });
          }
          return onRequestOrganizationUserAclSuccess(data);
        }),
        catchError(error => of(onRequestOrganizationUserAclFail(error.xhr))),
      );
    }),
  );

export default getOrganizationsUserAclEpic;
