import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
} from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';

import Select from '../../../components/Select';
import { ISSUE_FILTER_TYPES } from '../../../constants/status.constants';
import { IIssuefilterDetails, IIssuefilterObject, ISelectOption } from '../../../Interface';

interface Props {
  filters: IIssuefilterObject;
  filterDetails: IIssuefilterDetails;
  onFilterSubmit: (filters: IIssuefilterObject) => void;
  onClose: () => void;
}

const FilterIssue: React.FC<Props> = (props: Props) => {
  const { filters, filterDetails, onFilterSubmit, onClose } = props;
  const [currentFilters, setCurrentFilters] = useState<IIssuefilterObject>(filters);

  const mapFilterToTags = (array: (string | number)[], type: ISSUE_FILTER_TYPES) => {
    return (
      <HStack flexWrap="wrap" rowGap={2}>
        {array.map((option: string | number, index) => {
          const filterDetail = filterDetails[type].filterOptions.filter(
            op => op.value === option,
          )[0];
          return (
            <Tag key={index} size="lg">
              <TagLabel>{filterDetail?.label}</TagLabel>
              <TagCloseButton onClick={() => removeFilterValue(type, option)} />
            </Tag>
          );
        })}
      </HStack>
    );
  };

  const removeFilterValue = (type: ISSUE_FILTER_TYPES, option: string | number) => {
    const removedFilters = (currentFilters[type] as (number | string)[]).filter(
      (o: string | number) => o.toString() !== option.toString(),
    );
    setCurrentFilters({
      ...currentFilters,
      [type]: removedFilters,
    });
  };

  const addFilterValue = (
    value: string | number,
    filterKey: ISSUE_FILTER_TYPES,
    isMulti: boolean,
  ) => {
    if (!isMulti) {
      setCurrentFilters({
        ...currentFilters,
        [filterKey]: [value],
      });
    } else if (!(currentFilters[filterKey] as (number | string)[]).includes(value)) {
      setCurrentFilters({
        ...currentFilters,
        [filterKey]: [...currentFilters[filterKey], value],
      });
    }
  };

  const getValues = (isMulti: boolean, key: ISSUE_FILTER_TYPES, value: (string | number)[]) => {
    if (isMulti) return [];
    return filterDetails[key].filterOptions.filter(
      (option: ISelectOption) => option.value === value[0],
    )[0];
  };
  return (
    <Box data-testid="filter-container">
      <VStack spacing={8} alignItems="flex-start" pb={20} px={6} pt={2}>
        {Object.values(ISSUE_FILTER_TYPES).map((filterKey: ISSUE_FILTER_TYPES) => {
          const isMultiSelect = filterKey !== ISSUE_FILTER_TYPES.TIME;
          const values = getValues(isMultiSelect, filterKey, currentFilters[filterKey]);
          return (
            <FormControl key={filterKey}>
              <VStack alignItems="flex-start" w="100%">
                <FormLabel mb={0}>{filterDetails[filterKey].label}</FormLabel>
                <Flex w="100%" data-testid="filter-select">
                  <Select
                    placeholder={'select'}
                    value={values}
                    options={filterDetails[filterKey].filterOptions}
                    name={filterKey}
                    onChange={(values: any) => {
                      addFilterValue(
                        isMultiSelect ? values[0].value : values.value,
                        filterKey,
                        isMultiSelect,
                      );
                    }}
                    isMulti={isMultiSelect}
                    closeMenuOnSelect
                    hideDropdownArrow
                    hideSelectedValues
                  />
                </Flex>
                {currentFilters[filterKey] &&
                  isMultiSelect &&
                  mapFilterToTags(currentFilters[filterKey], filterKey)}
              </VStack>
            </FormControl>
          );
        })}
      </VStack>
      <VStack alignItems="flex-start" bottom={0} position="fixed" bgColor={'white'} w="100%">
        <Divider />
        <HStack spacing={6} px={6} py={2}>
          <Button
            variant="default"
            onClick={() => {
              onFilterSubmit(currentFilters);
            }}
          >
            Apply
          </Button>
          <Button variant="invertedDefault" onClick={() => onClose()}>
            Cancel
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};
export default FilterIssue;
