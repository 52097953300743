import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#053447',
      }}
      d="M1.339 14.999 10 20l8.661-5.001v-10L10 0 1.773 4.751a.54.54 0 0 0 0 .932l7.853 4.534v3.961L1.339 9.393v5.606zm8.66 4.136-7.912-4.567v-3.879l3.533 2.039-.001.001 4.352 2.545 4.566-2.635V7.368L9.972 4.733 5.8 7.142 2.461 5.215 10 .864l7.91 4.569v9.135l-7.911 4.567zM6.548 7.574 10 9.567l3.424-1.978-3.452-1.992-3.424 1.977zm7.241.668-3.415 1.972v3.963l3.415-1.97V8.242z"
    />
  </svg>
);

export default SvgComponent;
