import { Grid } from 'uie/components';
import { IIncident } from 'core/interfaces/IIncidents';
import { IIntegration } from 'core/interfaces/IIntegration';
import { Table } from 'library/molecules';
import { Cell } from 'react-table';
import { INCIDENT_DATA } from 'views/main/organization/incident-list/common/types';
import { INCIDENT_CHILDREN_COLUMN_DEFS } from 'views/main/organization/incident-list/listing/column-defs';
import UnmergeChildIncident from '../../unMergeIncident';
import { useEffect, useMemo, useState } from 'react';

interface Props {
  IncidentChildrenAction: ({ rowData }: { rowData: Partial<Cell<INCIDENT_DATA>> }) => JSX.Element;
  alertSources: IIntegration[];
  children: IIncident[];
  unmergeModalProps: {
    onClose: () => void;
    isOpen: boolean;
    unMerge: (
      assignToMe: boolean,
      sendNotification: boolean,
      location: 'parent' | 'child',
    ) => Promise<void>;
    isUnmerging: boolean;
    latestAssigneeDeleted: boolean;
  };
}

export const ChildIncidents = ({
  IncidentChildrenAction,
  alertSources,
  children,
  unmergeModalProps,
}: Props) => {
  const [count, setCount] = useState(children.length);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const maxPages = useMemo(() => Math.ceil(count / limit), [count, limit]);
  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage !== maxPages;
  const offset = (currentPage - 1) * limit;
  const paginatedData = useMemo(
    () => children.slice(offset, offset + limit),
    [children, currentPage, limit],
  );

  useEffect(() => setCount(children.length), [children.length]);
  useEffect(() => {
    if (currentPage > maxPages) {
      setCurrentPage(maxPages);
    }
  }, [maxPages]);

  return (
    <Grid
      flexWidth={12}
      style={{
        marginTop: 'var(--chakra-space-3)',
      }}
    >
      <Table
        hoverView={<IncidentChildrenAction rowData={{}} />}
        hoverViewStyling={{
          right: '16px',
        }}
        width="100%"
        columns={INCIDENT_CHILDREN_COLUMN_DEFS(alertSources)}
        data={paginatedData as unknown as readonly Record<string, unknown>[]}
        maxPageSize={20}
        cursorPagination
        pageSize={limit}
        onPageChange={(_, pageLimit) => setLimit(pageLimit)}
        hidePagination={!(children.length > 5)}
        hasPrev={hasPreviousPage}
        hasNext={hasNextPage}
        onPrev={() => setCurrentPage(currentPage - 1)}
        onNext={() => setCurrentPage(currentPage + 1)}
        headerStyles={{ padding: '14px 24px 14px 24px' }}
        containerStyles={{
          overflow: 'unset',
          '& thead': {
            position: 'sticky',
            top: 0,
            zIndex: 1,
            background: 'white',
          },
        }}
      />
      <UnmergeChildIncident {...unmergeModalProps} location="parent" />
    </Grid>
  );
};
