import * as React from 'react';
import { SVGProps } from 'react';

const SvgRollbarLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#3569f3',
      }}
      d="M20 2.15a.522.522 0 0 0-.01-.096c0-.01-.004-.018-.007-.028l-.02-.064-.015-.031a.414.414 0 0 0-.03-.053l-.021-.031-.014-.018c-.008-.01-.016-.016-.024-.023l-.019-.023-.015-.011a.465.465 0 0 0-.044-.034l-.038-.024a.492.492 0 0 0-.05-.023l-.04-.016c-.019-.006-.038-.008-.056-.012l-.042-.009a.551.551 0 0 0-.068 0h-.038c-.257.023-3.65.347-7.462 2.048-2.29 1.018-4.063 2.582-5.178 4.48l-.287.124C2.439 10.128 0 13.671 0 17.782v.069a.498.498 0 0 0 .497.496H14.05a.44.44 0 0 0 .079-.007l.034-.008c.017-.005.033-.008.05-.014s.023-.011.036-.017c.012-.006.028-.011.042-.02a.547.547 0 0 0 .072-.05l5.46-4.605a.493.493 0 0 0 .177-.383V2.15H20zm-4.938 14.19-.524.441V6.985l4.468-3.766v9.796l-3.944 3.325zM6.14 13.742h7.404v3.611H1.856l4.284-3.61zm6.25-9.132a25.602 25.602 0 0 1 5.443-1.703l-3.994 3.368A25.344 25.344 0 0 0 8.43 7.548C9.42 6.341 10.754 5.34 12.39 4.61zM7.482 8.977a25.644 25.644 0 0 1 6.062-1.662v5.434H6.466a8.782 8.782 0 0 1 1.016-3.772zm-1.364.634a9.84 9.84 0 0 0-.657 3.402l-4.407 3.72c.333-3.022 2.128-5.55 5.064-7.122z"
    />
  </svg>
);

export default SvgRollbarLogo20X20;
