import * as React from 'react';
import { SVGProps } from 'react';

const SvgCoralogix = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <circle
      cx={10}
      cy={10}
      r={9}
      style={{
        fill: '#3cc48f',
      }}
    />
    <path
      d="M10 20C4.5 20 0 15.5 0 10S4.5 0 10 0s10 4.5 10 10-4.5 10-10 10zm0-18c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"
      style={{
        fill: '#aeffdc',
      }}
    />
  </svg>
);

export default SvgCoralogix;
