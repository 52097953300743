import { PAGE_TYPES } from '../constants/schema';
import { COMPONENT_TYPE, ComponentActionType, PAGE_STATUS } from '../constants/status.constants';
import { getComponentCount, getGroupOptions } from '../helpers/helper.details';
import { getPageInputDetails } from '../helpers/helper.service';
import {
  IComponentTreeItem,
  IfilterOption,
  IStatusPageComponent,
  IStatusPageGroup,
} from '../Interface';

// An interface for our actions
interface ComponentAction {
  type: ComponentActionType;
  componentList?: IComponentTreeItem[];
  componentForm?: any;
  updateGroupId?: number;
  updateComponentId?: number;
  groupName?: string;
  isEdit?: boolean;
  reassignGroupId?: number;
  isDragged?: boolean;
}
interface State {
  components: IComponentTreeItem[];
  selectedGroupId: number;
  isNewGroup: boolean;
  isNewComponent: boolean;
  groupOptions: IfilterOption[];
  componentForm: any;
  isDragged: boolean;
  componentCount: number;
}
const { initValues } = getPageInputDetails(PAGE_TYPES.ADDCOMPONENT);

export function ComponentReducer(state: State, action: ComponentAction) {
  const {
    type,
    componentList,
    groupName,
    updateGroupId,
    updateComponentId,
    componentForm,
    reassignGroupId,
    isDragged,
  } = action;
  switch (type) {
    case ComponentActionType.INIT_COMPONENT_LIST:
      return {
        ...state,
        components: componentList || [],
        groupOptions: getGroupOptions(componentList || []),
        isDragged: isDragged || false,
        selectedGroupId: -1,
        componentCount: getComponentCount(componentList || []),
      };

    case ComponentActionType.INIT_ADD_GROUP:
      const id = Date.now();
      const group: IStatusPageGroup = {
        id,
        name: '',
        type: COMPONENT_TYPE.GROUP,
        components: [],
      };
      return {
        ...state,
        components: [...state.components, group],
        selectedGroupId: id,
        isNewGroup: true,
      };
    case ComponentActionType.SAVE_GROUP:
      const selectedGroup = state.components.filter(
        (group: IComponentTreeItem) =>
          group.id === state.selectedGroupId && group.type === COMPONENT_TYPE.GROUP,
      )?.[0];
      if (selectedGroup) {
        selectedGroup.name = groupName || '';
        return {
          ...state,
          components: [...state.components],
          selectedGroupId: -1,
          isNewGroup: false,
          groupOptions: getGroupOptions(state.components || []),
        };
      } else {
        return state;
      }
    case ComponentActionType.INIT_EDIT_GROUP:
      return {
        ...state,
        selectedGroupId: updateGroupId || -1,
      };

    case ComponentActionType.CANCEL_GROUP_CHANGE:
      let com = [...state.components];
      if (state.isNewGroup) {
        com = state.components.filter((c: IComponentTreeItem) => c.id !== state.selectedGroupId);
      }
      return {
        ...state,
        components: com,
        selectedGroupId: -1,
      };
    case ComponentActionType.DELETE_GROUP:
      const selectedDeleteGroup: IStatusPageGroup = state.components.filter(
        (c: IComponentTreeItem) => c.id === updateGroupId,
      )[0];

      const filteredComponents: IComponentTreeItem[] = [];
      state.components.forEach((c: IComponentTreeItem) => {
        if (c.id === reassignGroupId) {
          (c as IStatusPageGroup).components?.push(...(selectedDeleteGroup.components || []));
        }
        if (c.id !== updateGroupId) {
          filteredComponents.push(c);
        }
      });
      return {
        ...state,
        components: [...filteredComponents],
        groupOptions: getGroupOptions(filteredComponents || []),
      };

    case ComponentActionType.INIT_ADD_COMPONENT:
      return {
        ...state,
        componentForm: {
          ...initValues,
          id: Date.now(),
          type: COMPONENT_TYPE.COMPONENT,
        },
        isNewComponent: true,
      };

    case ComponentActionType.ADD_COMPONENT: {
      const newComponent: IStatusPageComponent = {
        type: COMPONENT_TYPE.COMPONENT,
        name: componentForm.name,
        allowSubscribtion: true,
        id: componentForm.id,
        description: componentForm.description,
      };
      if (componentForm.componentToGroup && componentForm.groupId) {
        const group: IStatusPageGroup = state.components.filter(
          (c: IComponentTreeItem) => c.id === componentForm.groupId,
        )[0];
        group.components?.push(newComponent);
        return {
          ...state,
          isNewComponent: false,
        };
      }
      return {
        ...state,
        components: [...state.components, newComponent],
        isNewComponent: false,
      };
    }

    case ComponentActionType.INIT_EDIT_COMPONENT: {
      let com = { ...state.componentForm };
      if (updateGroupId) {
        const group: IStatusPageGroup = state.components.filter(
          (c: IStatusPageGroup) => c.id === updateGroupId && c.type === COMPONENT_TYPE.GROUP,
        )?.[0];
        com = group.components?.filter((c: IStatusPageComponent) => c.id === updateComponentId)[0];
        com.componentToGroup = true;
        com.groupId = updateGroupId;
        com.prevGroupId = updateGroupId;
      } else {
        com = state.components.filter(
          (c: IComponentTreeItem) =>
            c.id === updateComponentId && c.type === COMPONENT_TYPE.COMPONENT,
        )[0];
        com.componentToGroup = false;
      }
      return {
        ...state,
        componentForm: com,
        isNewComponent: false,
      };
    }

    case ComponentActionType.EDIT_COMPONENT:
      const comps: IComponentTreeItem[] = [];
      let isAdded = false;
      state.components.forEach((group: IStatusPageGroup) => {
        if (group.type === COMPONENT_TYPE.GROUP) {
          const com: IStatusPageComponent[] = [];
          (group as IStatusPageGroup).components?.forEach((c: IStatusPageComponent) => {
            if (c.id !== componentForm.id) {
              com.push(c);
            } else if (group.id === componentForm.groupId) {
              isAdded = true;
              com.push({
                type: COMPONENT_TYPE.COMPONENT,
                name: componentForm.name,
                allowSubscribtion: true,
                id: componentForm.id,
                description: componentForm.description,
                serviceID: componentForm.serviceID,
              });
            }
          });
          if (!isAdded && group.id === componentForm.groupId) {
            com.push({
              type: COMPONENT_TYPE.COMPONENT,
              name: componentForm.name,
              allowSubscribtion: true,
              id: componentForm.id,
              description: componentForm.description,
              serviceID: componentForm.serviceID,
            });
          }
          (group as IStatusPageGroup).components = com;
          comps.push(group);
        } else if (group.id !== componentForm.id) {
          comps.push(group);
        } else if (!isAdded && !componentForm.groupId && group.id === componentForm.id) {
          isAdded = true;
          comps.push({
            type: COMPONENT_TYPE.COMPONENT,
            name: componentForm.name,
            allowSubscribtion: true,
            id: componentForm.id,
            description: componentForm.description,
            serviceID: componentForm.serviceID,
          });
        }
      });
      if (!isAdded && !componentForm.groupId) {
        comps.push({
          type: COMPONENT_TYPE.COMPONENT,
          name: componentForm.name,
          allowSubscribtion: true,
          id: componentForm.id,
          description: componentForm.description,
          serviceID: componentForm.serviceID,
        });
      }
      return {
        ...state,
        components: comps,
      };

    case ComponentActionType.DELETE_COMPONENT:
      const comp: IComponentTreeItem[] = [];
      state.components.forEach((c: IComponentTreeItem) => {
        if (c.type === COMPONENT_TYPE.GROUP) {
          const comps: IStatusPageComponent[] = [];
          (c as IStatusPageGroup).components?.forEach((c: IStatusPageComponent) => {
            if (c.id !== updateComponentId) {
              comps.push(c);
            }
          });
          (c as IStatusPageGroup).components = comps;
          comp.push(c);
        } else if (c.id !== updateComponentId) {
          comp.push(c);
        }
      });
      return {
        ...state,
        components: comp,
      };

    default:
      return state;
  }
}
