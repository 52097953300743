import React from 'react';
import { Grid, Para, SpinLoader, TextButton, Theme } from 'uie/components';
import { IIncident } from '../../../../../../../core/interfaces/IIncidents';
import { IJiraServer } from '../../../../../../../core/interfaces/IJira';
import { exception } from '../../../../../../../core/exception';
import { JiraServerService } from '../../../../../../../core/services';
import { AppTracker } from '../../../../../../../shared/analytics/tracker';
import { T_WA_GS_INCIDENT_ACTIONS_JIRA_SERVER } from '../../../../../../../core/const/tracker';

const { theme } = Theme;

interface IProps {
  incident: IIncident;
  serviceName: string;
  getIncidentDetails: () => void;
}

interface IState {
  config: IJiraServer | null;
  serviceConfigured: boolean;
  loading: 'config' | 'ticket' | '';
  errorMessage: string;
  successfullyCreated: boolean;
}

class JiraServerAction extends React.Component<IProps, IState> {
  private _jiraServerService = new JiraServerService();

  constructor(props: IProps) {
    super(props);

    this.state = {
      config: null,
      serviceConfigured: false,
      loading: '',
      errorMessage: '',
      successfullyCreated: false,
    };
  }

  componentDidMount() {
    this.getConfig();
  }

  public getConfig = async () => {
    this.setState({ loading: 'config' });
    try {
      const { data }: { data: IJiraServer } = await this._jiraServerService.getJiraConfigs();
      this.setState(
        {
          loading: '',
          config: data,
          serviceConfigured: data.service_ids.some(s => s === this.props.incident.service_id),
        },
        () => this.checkForErrors(),
      );
    } catch (err: any) {
      exception.handle('E_JIRA_SERVER_GET_CONFIG', err);
      this.setState({ loading: '' }, () => this.checkForErrors());
    }
  };

  public checkForErrors = () => {
    let errorMessage = '';

    if (this.state.config === null) errorMessage = 'Please integrate with Jira DC';
    else if (!this.state.config.project)
      errorMessage = 'Please complete Jira DC Integration, no projects found';
    else if (!this.state.config.is_manual)
      errorMessage = 'Please configure Jira DC integration to manual mode';
    else if (!this.state.serviceConfigured)
      errorMessage = `Please configure Jira DC integration to handle incidents from ${this.props.serviceName}`;

    this.setState({ errorMessage });
  };

  public createTicket = async () => {
    this.setState({ loading: 'ticket' });
    try {
      await this._jiraServerService.postIncidentToJira(this.props.incident.id);
      this.setState({ loading: '', successfullyCreated: true });
    } catch (err: any) {
      exception.handle('E_JIRA_SERVER_CREATE_TICKET', err);
      this.setState({
        loading: '',
        successfullyCreated: false,
        errorMessage: exception.getErrorMsg(err),
      });
    } finally {
      this.props.getIncidentDetails();
    }
    AppTracker.track(T_WA_GS_INCIDENT_ACTIONS_JIRA_SERVER);
  };

  render() {
    return (
      <Grid className="mt-20 mb-20">
        {this.state.loading !== 'config' ? (
          !this.state.errorMessage ? (
            !this.state.successfullyCreated ? (
              <Grid justifyContent="space-around" width="100%">
                <TextButton
                  buttonType="inverted"
                  height="36px"
                  onClick={this.createTicket}
                  disabled={this.state.loading === 'ticket'}
                  style={{ padding: '0 44px' }}
                >
                  <Para fontWeight={500} fontSize={18} color={theme.primary.default}>
                    Create a Ticket in Jira
                  </Para>
                </TextButton>
              </Grid>
            ) : (
              <Para>
                A Jira ticket has been created for this incident in Jira Project{' '}
                <span className="font-bold">{this.state.config!.project.name}</span>
              </Para>
            )
          ) : (
            <Para>{this.state.errorMessage}</Para>
          )
        ) : (
          <Grid justifyContent="space-around" width="100%">
            <SpinLoader />
          </Grid>
        )}
      </Grid>
    );
  }
}

export default JiraServerAction;
