import { useState } from 'react';
import { Flex, VStack } from '@chakra-ui/layout';
import {
  Menu,
  Text,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { TAG_VARIANTS } from 'library/atoms/Tag/Tag';
import React from 'react';
import { Workflow } from '../../types/response/workflow';
import TagsForm from './TagsForm';
import { VerticalDots } from 'icons';
import { generatePath, useHistory } from 'react-router-dom';
import { WORKFLOWS_EDIT_PATH } from 'views/main/routes/routes';
import { useDeleteWorkflow } from '../hooks/useDeleteWorkflow';
import { useMutateWorkflow } from '../hooks/useMutateWorkflow';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../../constant';
import { workflowList } from '../../context/workflowListContext';
import { T_WA_WP_DELETED } from 'core/const/tracker';
import { AppTracker } from 'shared/analytics/tracker';
import qs, { ParsedQuery } from 'query-string';
import { useWorkflowFormContext } from '../../context/workflowFormContext';
import { Breadcrumb, FormButton, SmallButton, Tag, useToast } from 'library/atoms';
import { CustomDrawerComponent, Modal } from 'library/molecules';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { THEME_COLORS } from 'library/theme/colors';

type WorkflowHeaderProps = Pick<Workflow, 'id' | 'title' | 'description' | 'tags'>;

const WorkflowHeader: React.FC<WorkflowHeaderProps> = ({ id, title, description, tags }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [deleteWorkflow, setDeleteWorkflow] = useState<{ id: string; title: string } | null>(null);
  const dialogAction = useDisclosure();

  const workflowDeletePermission = useUserAccess().hasDeleteAccess('workflows', id);
  const workflowEditPermission = useUserAccess().hasUpdateAccess('workflows', id);

  const currentTags = tags.map(tag => {
    return { key: tag.key, value: tag.value, color: tag.color ?? THEME_COLORS.brand.blue };
  });

  const history = useHistory();
  const query = qs.parse(history.location.search) as ParsedQuery<string>;
  const [page, size] = [query['workflowPage'], query['workflowSize']];
  const { isFormDirty } = useWorkflowFormContext();

  const handleClickUpdate = () => {
    history.push(generatePath(WORKFLOWS_EDIT_PATH, { id: id }));
  };

  const deleteWorkflowMutation = useDeleteWorkflow({
    onSuccess: () => {
      AppTracker.track(T_WA_WP_DELETED, {
        workflow_id: [id],
      });
    },
  });

  const onConfirmDelete = () => {
    deleteWorkflowMutation.mutate({
      id: id,
    });
  };

  const handleDeleteClick = () => {
    dialogAction.onOpen();
    setDeleteWorkflow({ id, title });
  };
  const mutation = useMutateWorkflow();
  const queryClient = useQueryClient();
  const toast = useToast();

  const handleDeleteTag = async (index: number) => {
    try {
      const newTags = [...currentTags];
      newTags.splice(index, 1);
      await mutation.mutateAsync({ id, formDirty: isFormDirty, partialUpdate: { tags: newTags } });
      await queryClient.invalidateQueries([QUERY_KEYS.WORKFLOW, `${id}`]);
      await queryClient.invalidateQueries(workflowList);
      toast({ text: 'Tag removed successfully', status: 'success' });
    } catch (e) {
      toast({ text: 'Something went wrong!', status: 'error' });
      console.log(e);
    }
  };

  return (
    <>
      <VStack mt={6} bg={'container.background'} align="stretch" px="4" py="6">
        <Breadcrumb
          data={[
            {
              link: `/workflows?page=${page}&size=${size}`,
              text: 'Workflows',
            },
            { link: `/workflows/${id}?workflowPage=${page}&workflowSize=${size}`, text: title },
          ]}
        />
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="30" variant="h1">
            {title}
          </Text>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={
                <div>
                  <VerticalDots />
                </div>
              }
              variant="secondary"
              p={3}
            />
            <MenuList>
              <NoPermissionTooltip isDisabled={workflowEditPermission}>
                <MenuItem onClick={handleClickUpdate} isDisabled={!workflowEditPermission}>
                  Edit
                </MenuItem>
              </NoPermissionTooltip>
              <NoPermissionTooltip isDisabled={workflowDeletePermission}>
                <MenuItem onClick={handleDeleteClick} isDisabled={!workflowDeletePermission}>
                  Delete
                </MenuItem>
              </NoPermissionTooltip>
            </MenuList>
          </Menu>
        </Flex>
        <Text fontSize="14" variant="body">
          {description}
        </Text>
        <Flex justifyContent="flex-start" gap={1} alignItems="center">
          {currentTags.map((data, index) => (
            <Tag
              key={index}
              variant={TAG_VARIANTS.KEY_VALUE}
              keyStr={data.key}
              color={data.color}
              value={data.value}
              onClose={() => handleDeleteTag(index)}
              // defaultLength={5}
            />
          ))}
          <NoPermissionTooltip isDisabled={workflowEditPermission}>
            <SmallButton
              variant="primary"
              size="xs"
              onClick={() => setIsDrawerOpen(true)}
              title="Update Tags"
              isDisabled={!workflowEditPermission}
            />
          </NoPermissionTooltip>
        </Flex>
        <CustomDrawerComponent
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          title="Update Tags"
          size="lg"
          disableBodyPadding
        >
          <TagsForm currentTags={currentTags} closeDrawer={() => setIsDrawerOpen(false)} id={id} />
        </CustomDrawerComponent>
      </VStack>
      {deleteWorkflow && (
        <Modal
          isOpen={dialogAction.isOpen}
          onClose={dialogAction.onClose}
          title={`Delete Workflow`}
          body={
            <Text variant="modal" color="secondary.700">
              Deleting a workflow will remove all the configured actions and relevant data. This
              action cannot be undone. Are you sure you want to proceed?
            </Text>
          }
          primaryButton={
            <FormButton
              variant="danger"
              isLoading={deleteWorkflowMutation.isLoading}
              onClick={onConfirmDelete}
              title="Delete"
            />
          }
          secondaryButton={
            <FormButton variant="secondary" onClick={dialogAction.onClose} title="Cancel" />
          }
        />
      )}
    </>
  );
};

export { WorkflowHeader };
