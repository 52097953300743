import { Box, Icon, Link, Text } from '@chakra-ui/react';
import { AlertIcon } from 'library/icons';
import { FC } from 'react';

type Props = {
  label: string;
  hyperlinkUrl?: string;
  hideIcon?: boolean;
};

export const PageLevelErrorMessage: FC<Props> = ({ label, hyperlinkUrl, hideIcon }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      bg="pageLevelMessage.background"
      padding="6px"
      gap="3px"
      borderRadius="3px"
    >
      {!hideIcon ? <Icon w="13.5px" h="13.5px" as={AlertIcon} /> : null}
      <Text color="form.invalid" variant="hint">
        {label}
      </Text>
      {hyperlinkUrl && (
        <Link variant="default" href={hyperlinkUrl} isExternal>
          Read More
        </Link>
      )}
    </Box>
  );
};
