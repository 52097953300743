import React, {
  useCallback,
  useState,
  createContext,
  ReactNode,
  useContext,
  ReactChild,
} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { CloseIcon } from '../../utils/icons';

export interface IToast {
  id: number;
  child: ReactChild;
  duration: number;
}

interface IProps {
  children: ReactNode;
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const InnerToastContext = createContext<IToast>();

const CloseButton = styled.button`
  background: none;
  border: none;
  height: 28px;
  margin-top: 8px;

  &:hover {
    cursor: pointer;
  }
`;

const ToastContainer = styled.div`
  display: flex;
  margin-top: 8px;
  box-shadow: ${props => props.theme.shadow.hover};
`;

const ToastWrapper = styled.div`
  position: fixed;
  top: 68px;
  right: 16px;
  transition: all 0.17s ease;
  z-index: 999999 !important;

  & > .t__grp > .toast_transition-enter {
    opacity: 0;
    transform: translateY(-100%);
  }
  & > .t__grp > .toast_transition-enter-active {
    opacity: 1;
    transform: translateY(0%);
  }
  & > .t__grp > .toast_transition-exit {
    opacity: 1;
    transform: translateY(0%);
  }
  & > .t__grp > .toast_transition-exit-active {
    opacity: 0;
  }
  & > .t__grp > .toast_transition-enter-active,
  & > .t__grp > .toast_transition-exit-active {
    transition: opacity 0.17s, transform 0.17s ease;
  }

  & > .t__grp > * {
    pointer-events: auto;
  }
`;

const ToastContextProvider = ({ children }: IProps) => {
  const [toasts, setToasts] = useState<IToast[]>([]);

  const addToast = useCallback(
    (child: ReactChild, duration = 2500) => {
      const id = new Date().getTime();
      const timer = setTimeout(() => {
        setToasts(t => t.filter(t1 => t1.id !== id));
        clearTimeout(timer);
      }, duration);
      return setToasts(t => [...t, { child, id, duration }]);
    },
    [setToasts],
  );

  const closeToast = useCallback(
    (toastId: number) => () => {
      setToasts(t => t.filter(t1 => t1.id !== toastId));
    },
    [setToasts],
  );

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <InnerToastContext.Provider value={addToast}>
      {children}
      {
        <ToastWrapper className="t__grp">
          <TransitionGroup>
            {toasts.map(({ child, id, duration }, i) => (
              <CSSTransition
                in={true}
                timeout={300}
                classNames="toast_transition"
                unmountOnExit={true}
                key={i}
              >
                <ToastContainer>
                  {child}
                  {duration > 5000 && (
                    <CloseButton onClick={closeToast(id)}>
                      <CloseIcon width={16} />
                    </CloseButton>
                  )}
                </ToastContainer>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </ToastWrapper>
      }
    </InnerToastContext.Provider>
  );
};

/** @component */
export default ToastContextProvider;

export const ToastContext: () => (child: ReactChild, duration?: number) => void = () =>
  useContext(InnerToastContext) as any;
