import { IconButton, Para, TextButton } from 'uie/components';
import { requestOrganizationUsers } from 'core';
import { getUserOrganization } from 'core/api/organization/getOrganization';
import { T_WA_USER_ADDED_TO_TEAM } from 'core/const/tracker';
import { pluralize } from 'core/helpers/stringUtils';
import { IRole } from 'core/interfaces/IRole';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { DeleteIcon } from 'icons';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppTracker } from 'shared/analytics/tracker';
import { v4 } from 'uuid';
import { useAddMembers } from '../hook';
import { Member, Role, RoleText } from '../types';
import AddMemberModalFooter from './AddMemberModalFooter';
import { MemberDropDown } from './MemberDropDown';
import {
  CancelButton,
  ModalActions,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SquadMemberSelectionColumn,
  SquadMemberSelectionContainer,
  SquadMemberSelectionLabel,
  SquadRoleLabel,
  SquadRoleOptionContainer,
  SquadRoleOptionsContainer,
  SquadRoleOptionsWrapper,
  SquadRoleRadio,
  SquadRoleSelectionColumn,
  SquadRoleSelectionLabel,
} from './styles';

const SquadRoleOption: React.FC<{
  role: string;
  checked: boolean;
  onClick: (role: Role) => void;
  disableSelect?: boolean;
  show?: boolean;
}> = ({ role, checked, onClick, disableSelect = false, show = true }) => {
  if (!show) return null;
  return (
    <SquadRoleOptionContainer onClick={() => !disableSelect && onClick(role as Role)}>
      {!disableSelect ? <SquadRoleRadio checked={checked} /> : null}
      <SquadRoleLabel>{role}</SquadRoleLabel>
    </SquadRoleOptionContainer>
  );
};

type TeamMember = {
  user_id: string | null;
  role: Role;
  tempId: string;
  userOrgRole?: IRole['slug'];
};

type Props = {
  onClose: () => void;
  members: Member[];
  onHelpClick?: () => void;
};
const AddSquadMembersModal: React.FC<Props> = ({ onClose, members, onHelpClick }) => {
  const [newMembersList, setNewMembersList] = React.useState<TeamMember[]>([
    {
      role: Role.Member,
      user_id: null,
      tempId: v4(),
    },
  ]);

  const { id: teamId } = useParams<{ id: string }>();

  const updateRoleByIndex = (index: number, role: Role) => {
    const newMembersListCopy = [...newMembersList];
    newMembersListCopy[index].role = role;
    setNewMembersList(newMembersListCopy);
  };

  const addNewMemberRow = () => {
    setNewMembersList(prev => [
      ...prev,
      {
        role: Role.Member,
        user_id: null,
        tempId: v4(),
      },
    ]);
  };

  const updateNewMember = (index: number, user: IUsersOfOrganization | null) => {
    if (user === null) {
      setNewMembersList(prev => {
        const newMembersListCopy = [...prev];
        newMembersListCopy.splice(index, 1);
        return newMembersListCopy;
      });
      return;
    }
    const canAddNewMember = newMembersList[index].user_id === null;
    setNewMembersList(prev => {
      const newMembersListCopy = [...prev];
      let role = Role.Member;
      if (user.role === 'stakeholder') {
        role = Role.Stakeholder;
      }

      newMembersListCopy[index] = {
        ...newMembersListCopy[index],
        user_id: user.id,
        userOrgRole: user.role,
        role: role,
      };
      return newMembersListCopy;
    });
    if (canAddNewMember) {
      addNewMemberRow();
    }
  };

  const deleteNewMember = (index: number) => {
    setNewMembersList(prev => {
      const newMembersListCopy = [...prev];
      // remove item at index
      newMembersListCopy.splice(index, 1);
      return newMembersListCopy;
    });
  };

  const { mutate: addMemberToTeam } = useAddMembers({
    onSuccess: () => {
      AppTracker.track(T_WA_USER_ADDED_TO_TEAM, {
        'Team ID': teamId,
      });
      onClose();
    },
  });

  const newMembersFiltered: Member[] = newMembersList
    .filter(m => m.user_id !== null)
    .map(m => ({
      user_id: m.user_id!,
      role: m.role,
    }));

  const canSelectRole = (userOrgRole?: IRole['slug']) => {
    if (userOrgRole === undefined) return true;
    return userOrgRole === 'user' || userOrgRole === 'account_owner';
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(requestOrganizationUsers());
  }, []);

  return (
    <ModalContainer>
      <ModalHeader>Add Members</ModalHeader>
      {newMembersList.map((newMember, idx) => {
        return (
          <ModalContent key={newMember.tempId}>
            <SquadMemberSelectionContainer>
              <SquadMemberSelectionColumn>
                <SquadMemberSelectionLabel
                  style={{
                    color: '#09305A',
                  }}
                >
                  Select Member
                </SquadMemberSelectionLabel>
                <MemberDropDown
                  members={members}
                  onSelect={user => updateNewMember(idx, user)}
                  newMembersList={newMembersList}
                />
              </SquadMemberSelectionColumn>
              <SquadRoleSelectionColumn>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SquadRoleSelectionLabel>Select Team Role</SquadRoleSelectionLabel>
                  <IconButton
                    style={{ padding: 0, marginLeft: '6px', marginTop: '1px' }}
                    onClick={() => onHelpClick?.()}
                  ></IconButton>
                </div>
                <SquadRoleOptionsContainer>
                  <SquadRoleOptionsWrapper>
                    <SquadRoleOption
                      onClick={() => updateRoleByIndex(idx, Role.Owner)}
                      role={RoleText[Role.Owner]}
                      checked={newMember.role === Role.Owner}
                      show={canSelectRole(newMember.userOrgRole)}
                    />
                    <SquadRoleOption
                      onClick={() => updateRoleByIndex(idx, Role.Member)}
                      role={RoleText[Role.Member]}
                      checked={newMember.role === Role.Member}
                      show={canSelectRole(newMember.userOrgRole)}
                    />
                    <SquadRoleOption
                      onClick={() => updateRoleByIndex(idx, Role.Stakeholder)}
                      role={RoleText[Role.Stakeholder]}
                      checked={newMember.role === Role.Stakeholder}
                      disableSelect
                      show={newMember.userOrgRole ? newMember.userOrgRole === 'stakeholder' : true}
                    />
                  </SquadRoleOptionsWrapper>
                  {newMembersList.length > 1 && (
                    <IconButton
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                      }}
                      onClick={() => deleteNewMember(idx)}
                    >
                      <DeleteIcon height="18px" width="18px" color={'#D14C44'} />
                    </IconButton>
                  )}
                </SquadRoleOptionsContainer>
              </SquadRoleSelectionColumn>
            </SquadMemberSelectionContainer>
          </ModalContent>
        );
      })}
      <AddMemberModalFooter
        addMemberToTeam={(data: { team_id: string; members: Member[] }) => addMemberToTeam(data)}
        onClose={onClose}
        newMembersFiltered={newMembersFiltered}
        teamId={teamId}
      />
    </ModalContainer>
  );
};

export default AddSquadMembersModal;
