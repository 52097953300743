import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { pipeFromArray } from 'rxjs/internal/util/pipe';

const defaultProperties = {
  bg: 'primary.default',
  color: 'shades.white',
  size: 'sm',
};

const disabledProperties = {
  opacity: 0.5,
};

export const Button: ComponentStyleConfig = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: 'base',
  },
  // Two sizes: sm and md
  sizes: {
    xs: {
      fontSize: 'xs',
    },
    sm: {
      fontSize: 'sm',
    },
    md: {
      fontSize: 'md',
      px: 6, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
  },

  variants: {
    outline: {
      color: '#0f61dd',
      bg: '#0f61dd07',
      size: 'sm',
      _focus: { outline: 'none' },
      boxShadow: '0 0 0 1px #0f61dda1',
    },
    solid: {
      bg: 'purple.500',
      color: 'white',
      _focus: { outline: 'none' },
    },
    default: {
      ...defaultProperties,
      _disabled: {
        ...disabledProperties,
      },
      _hover: {
        _disabled: {
          ...defaultProperties,
          ...disabledProperties,
        },
      },
    },
    icon: {
      bg: 'gray.50',
      size: 'sm',
      _focus: { outline: 'none' },
    },
    iconDarker: {
      bg: 'gray.400',
      size: 'sm',
      _focus: { outline: 'none' },
    },
    iconOverFlow: {
      borderRadius: '50%',
      bg: 'gray.100',
      padding: 0,
      size: 'xs',
      _focus: { outline: 'none' },
    },
    warn: {
      bg: '#D14C44',
      color: 'white',
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'sm',
    variant: 'default',
  },
};
