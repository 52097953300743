import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const FormLabel: ComponentStyleConfig = {
  variants: {
    rotationPattern: {
      color: 'gray.700',
      size: 'xs',
    },
  },
};
