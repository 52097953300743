import { Box, HStack } from '@chakra-ui/react';
import { CloseButton, FormButton, Tab } from 'library/atoms';
import { CustomDrawerComponent } from 'library/molecules';
import { useCallback, useContext, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { FilterFormConnected } from './form';
import { FILTERS_TAB } from '../common/enums';
import { IncidentParamsContext } from '../hooks/useAllParams';
import { clearIncidentFilterObj } from './form/temp-store';
import { FilterList } from './list';
import { filterManager } from './manager';
import { invalSavedFilters } from './manager/util';
import { queryUpdate } from '../common/util';
import useQueryParams from 'core/hooks/useQueryParams';
import { useHistory } from 'react-router';

const INVAL_SAVED_FILTER_ID = -1;

export const FiltersComponent = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [tabIndex, setTabIndex] = useState(
    filterManager.getUIFilters().id ? FILTERS_TAB.LIST : FILTERS_TAB.FORM,
  );
  const queryClient = useQueryClient();
  const query = useQueryParams();
  const history = useHistory();
  const [selectedId, setSelectedId] = useState<number>(
    filterManager.getUIFilters()?.id ?? INVAL_SAVED_FILTER_ID,
  );
  const filtersListRef = useRef(null);
  const {
    params: { filterList, isLoading },
  } = useContext(IncidentParamsContext);

  const onFiltersClose = useCallback(() => {
    onClose();
    // it should clear after clsoing so that form component don't write obj again
    clearIncidentFilterObj();
  }, []);

  const onSuccess = useCallback(() => {
    clearIncidentFilterObj();
    invalSavedFilters(queryClient);
    setTabIndex(FILTERS_TAB.LIST);
  }, []);

  return (
    <CustomDrawerComponent
      size="md"
      renderHeader={false}
      isOpen={isOpen}
      onClose={onFiltersClose}
      title="Filter"
      disableBodyPadding
      footer={
        tabIndex === FILTERS_TAB.LIST ? (
          <HStack spacing={3}>
            <FormButton
              title="Apply"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onClick={() => {
                filtersListRef.current?.applyFilter(selectedId);
                queryUpdate(query, null, 'filterId');
                history.replace({ search: query.toString() });
              }}
              isDisabled={
                isLoading ||
                selectedId === INVAL_SAVED_FILTER_ID ||
                !filterList?.listIncidentGroups?.length
              }
            />
            <FormButton
              title="Clear All Filter"
              variant="ghost"
              isDisabled={selectedId === INVAL_SAVED_FILTER_ID}
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                filtersListRef.current?.resetFilterSelection();
                setSelectedId(INVAL_SAVED_FILTER_ID);
                queryUpdate(query, null, 'filterId');
                history.replace({ search: query.toString() });
                filterManager.clearFilters(queryClient);
                onClose();
              }}
            />
          </HStack>
        ) : null
      }
    >
      <Tab
        isLazy
        index={tabIndex}
        onChange={setTabIndex}
        pt="12px"
        labels={[
          {
            name: 'Filter',
          },
          {
            name: 'Saved Filter',
          },
        ]}
        variant="outline"
        customHeaderElement={
          <Box ml="auto" mr={4}>
            <CloseButton onClick={onFiltersClose} />
          </Box>
        }
      >
        <FilterFormConnected onClose={onFiltersClose} onSuccess={onSuccess} />
        <FilterList ref={filtersListRef} onSelection={setSelectedId} onClose={onFiltersClose} />
      </Tab>
    </CustomDrawerComponent>
  );
};
