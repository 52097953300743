import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { API } from '../../../../../core/api';

import { queryKeys, reactQueryConfig } from '../contants/service.contant';

import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/helper.service';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_UP_UPDATE_SERVICE_TAG } from '../../../../../core/const/tracker';

import { usePersistQuery } from './usePersistQuery';

interface Tags {
  key: string;
  value: string;
}
interface Paload {
  serviceId: string;
  tags: Tags[];
}

const getTagsList = async (serviceId: string | unknown) => {
  const {
    data: {
      data: { tags },
    },
  } = await axios.get<{
    data: {
      tags: Array<Tags>;
    };
  }>(`${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/tags`);
  return tags;
};

export function useTagsList(serviceID: string | unknown) {
  const tagsList = useQuery([queryKeys.GETTAGS], () => getTagsList(serviceID), {
    ...reactQueryConfig,
    onError: reactQueryConfigError,
  });

  return {
    ...tagsList,
  };
}

const addTags = async (payload: Paload) => {
  const { data } = await axios.put<{
    data: {
      tags: Array<Tags>;
    };
  }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/${payload.serviceId}/tags`,
    { tags: payload.tags },
  );
  return data;
};

export function useTagsEdit() {
  const queryClient = useQueryClient();
  const persistQuery = usePersistQuery();

  const navigateOnSuccess = () => {
    setTimeout(() => {
      persistQuery();
    }, 1000);
  };

  const { mutate: addOrEditTags } = useMutation((payload: Paload) => addTags(payload), {
    onError: reactQueryConfigError,
    onSuccess: data => {
      reactQueryConfigSuccess('Tags updated');
      AppTracker.track(T_WA_UP_UPDATE_SERVICE_TAG, { tags: data });
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.SERVICELIST);
      navigateOnSuccess();
      // queryClient.invalidateQueries(queryKeys.GETTAGS);
    },
  });
  return { addOrEditTags };
}

const getAllTagsList = async () => {
  const {
    data: {
      data: { all_tags },
    },
  } = await axios.get<{
    data: {
      all_tags: { [k: string]: Array<string> };
    };
  }>(`${API.config.batman}/organizations/${API.config.organizationId}/catalog-services/all-tags`);
  return all_tags;
};

export function useAllTagsList() {
  const tagsList = useQuery([queryKeys.GETALLTAGS], () => getAllTagsList(), {
    ...reactQueryConfig,
    onError: reactQueryConfigError,
  });

  return {
    ...tagsList,
  };
}
