import { Box, Button, Divider, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { FormikProvider, useFormikContext } from 'formik';
import React, { Dispatch, Fragment, SetStateAction, useMemo } from 'react';

import { DEFAULT_THEMES } from '../../constants/colors';
import { THEME_TYPE } from '../../constants/status.constants';
import { reactQueryConfigSuccess } from '../../helpers/helper.toast';
import { usePageTheme } from '../../hooks/usePageTheme';
import { IStatusPageDetails } from '../../Interface';
import CustomizePageForm from '../../statuspage.add/statuspage.customizePage/customizePageForm/customizePageForm';
import ThemeDisplay from '../../statuspage.add/statuspage.customizePage/customizePageForm/themeDisplay';

interface Props {
  pageId: number;
  isEdit: boolean;
  setIsEditFlow: Dispatch<SetStateAction<boolean>>;
  details: IStatusPageDetails;
  closeDrawer: () => void;
  refetch: () => void;
  canEdit: boolean;
}

interface FormProps {
  onCancel: () => void;
  isLoading: boolean;
}

const UpdateForm = (props: FormProps) => {
  const formik = useFormikContext();
  return (
    <form>
      <VStack px={0} py={3} alignItems="flex-start" overflow={'scroll'} mb={10}>
        <CustomizePageForm />
      </VStack>
      <VStack
        alignItems="flex-start"
        pb={4}
        bottom={0}
        position="absolute"
        bgColor={'white'}
        w="100%"
      >
        <Divider />
        <HStack pt={2} spacing={6}>
          <Button
            variant="default"
            onClick={formik.submitForm}
            data-testid="save-form"
            isLoading={props.isLoading || formik.isSubmitting}
          >
            Save
          </Button>
          <Button variant="invertedDefault" onClick={() => props.onCancel()}>
            Cancel
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};

const UpdatePageTheme = ({
  pageId,
  isEdit,
  setIsEditFlow,
  details,
  closeDrawer,
  refetch,
  canEdit,
}: Props) => {
  const isDefaulColor = useMemo(() => {
    return DEFAULT_THEMES.filter(theme => theme.primary === details.themeColor.primary)?.[0]
      ? THEME_TYPE.DEFAULT
      : THEME_TYPE.CUSTOM;
  }, [details.themeColor.primary]);

  const themeData = {
    primaryColor: details.themeColor.primary,
    secondaryColor: details.themeColor.secondary,
    logoUrl: details.logoUrl,
    isLogoChanged: false,
    schemaType: isDefaulColor,
  };
  const { formik, isLoading } = usePageTheme(
    () => {
      refetch();
      reactQueryConfigSuccess('Page theme updated');
      closeDrawer();
    },
    themeData,
    pageId,
  );

  return (
    <Box>
      <Box hidden={!isEdit} data-testid="config-form">
        <FormikProvider value={formik}>
          <UpdateForm onCancel={() => setIsEditFlow(false)} isLoading={isLoading} />
        </FormikProvider>
      </Box>

      <Box hidden={isEdit} data-testid="summary-info">
        <VStack px={0} py={3} spacing={8} alignItems="flex-start">
          <Flex direction="column">
            <Text fontSize={14} fontWeight="800" textTransform={'uppercase'}>
              company logo
            </Text>
            <Image src={details.logoUrl} width="200px" height={'200px'} objectFit={'cover'} />
          </Flex>

          <Flex flex={1} direction="column">
            <Text fontSize={14} fontWeight="800" textTransform={'uppercase'}>
              Color Theme
            </Text>
            <ThemeDisplay
              primary={details.themeColor.primary}
              secondary={details.themeColor.secondary}
              isSelected={false}
            />
          </Flex>
        </VStack>
        {canEdit ? (
          <VStack
            alignItems="flex-start"
            marginTop={4}
            marginBottom={4}
            bottom={0}
            position="absolute"
            w="100%"
          >
            <Divider />
            <HStack pt={2} spacing={6}>
              <Button variant="default" onClick={() => setIsEditFlow(true)} data-testid="edit-btn">
                Edit
              </Button>
            </HStack>
          </VStack>
        ) : null}
      </Box>
    </Box>
  );
};

export default React.memo(UpdatePageTheme);
