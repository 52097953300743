import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const DividerTheme: ComponentStyleConfig = {
  baseStyle: () => {
    return {
      bg: 'secondary.200',
      opacity: 1,
    };
  },
  sizes: {
    md: {
      height: '1px',
    },
    lg: {
      height: '2px',
    },
  },
  defaultProps: {
    size: 'md',
  },
};
