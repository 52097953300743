import React from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

interface Props {
  headerText: string;
  bodyText: string;
  confirmButtonText: string;
  cancelButtonText: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function DeleteConfirmation(props: Props) {
  const multiLineBodyText = props.bodyText.split(/\r?\n/);
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={20} fontWeight={500} fontStyle="normal">
          {props.headerText}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {multiLineBodyText.map((text, index) => {
            return <Text key={index}>{text}</Text>;
          })}
        </ModalBody>
        <ModalFooter>
          <Button
            className="ml-10"
            id="to_services"
            backgroundColor="white"
            border={`1px solid red`}
            color="red"
            onClick={() => props.onConfirm()}
            size="md"
            mr={3}
            loadingText="Save"
          >
            {props.confirmButtonText}
          </Button>
          <Button
            id="to_services"
            backgroundColor="primary.default"
            color="shades.white"
            onClick={() => props.onClose()}
            size="md"
            loadingText="Save"
          >
            {props.cancelButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
