import React, { ReactNode } from 'react';

import {
  Box,
  Heading,
  HStack,
  Link,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';

import { SchedulesHelpGuide } from '../../interface/schedule';

type Props = {
  helpGuide: SchedulesHelpGuide;
};

function HelpGuide({ helpGuide }: Props) {
  return (
    <>
      <Heading as="h6" variant="h6" px={6} py={6}>
        Help and Guide
      </Heading>

      <VStack px={6} alignItems="flex-start" gap={4}>
        {helpGuide.map((guide, i) => {
          if (guide.type === 'help') {
            return (
              <Box key={`guide${i}`}>
                <Heading as="h4" fontWeight="bold" size="xs" pb={1}>
                  {guide.title}
                </Heading>
                {guide.desc.map((tip, index) => (
                  <Text variant="helpAndGuide_help" key={`desc-${index}`}>
                    {tip}
                  </Text>
                ))}
                {guide.link.map(({ url, label }, index) => (
                  <Box key={`link-${index}`}>
                    <Link
                      color="default"
                      href={url}
                      fontSize={12}
                      fontStyle="italic"
                      textDecoration="underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {label}
                    </Link>
                  </Box>
                ))}
                {guide.note && (
                  <Box mt={2}>
                    <Text variant="helpAndGuide_help" as="span" fontWeight="bold">
                      Note:
                    </Text>
                    <Text variant="helpAndGuide_help" as="span">
                      {` ${guide.note}`}
                    </Text>
                  </Box>
                )}
              </Box>
            );
          } else if (guide.type === 'tips') {
            return (
              <Box key={`guide${i}`}>
                <Heading as="h4" fontWeight="bold" size="xs">
                  {guide.title}
                </Heading>
                <UnorderedList>
                  {guide.desc.map((tip, index) => (
                    <ListItem ml={4} key={index}>
                      <Text variant="helpAndGuide_help">{tip}</Text>
                    </ListItem>
                  ))}
                </UnorderedList>
              </Box>
            );
          } else {
            return (
              <Box key={`guide${i}`} pt={6}>
                <Text variant="helpAndGuide_query">{guide.title}</Text>
              </Box>
            );
          }
        })}
      </VStack>
    </>
  );
}

export default HelpGuide;
