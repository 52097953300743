import { Box, Flex, Input, Textarea } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { JiraTicketCreateAction } from 'views/main/organization/workflow/types';
import { useGetAllJiraAccounts } from 'views/main/organization/settings/integrations/jira-cloud/hooks/getAllJiraAccounts';
import { useGetJiraProjectsForAccount } from 'views/main/organization/settings/integrations/jira-cloud/hooks/getAllProjectOfAccount';
import { useGetIssuesForJiraProject } from 'views/main/organization/settings/integrations/jira-cloud/hooks/getAllIssueInJiraProjects';
import FormField from 'library/molecules/FormField/FormField';
import FrameWorkDropdown from 'library/atoms/FrameworkDropdown';

const JiraTicketForm = () => {
  const { setIsDirty } = useWorkflowFormContext();
  const {
    formState: { isDirty, errors },
    setValue,
    register,
    watch,
  } = useFormContext<JiraTicketCreateAction>();

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  const { data: jiraAccounts = [], isLoading } = useGetAllJiraAccounts();
  const { data: jiraProjects = [], isLoading: isConfigLoading } = useGetJiraProjectsForAccount(
    watch('data.account') || '',
  );

  const { data: jiraProjectsIssue = [], isLoading: isProjectIssueFetching } =
    useGetIssuesForJiraProject(watch('data.account') || '', watch('data.project') || '');

  const jiraAcc = useMemo(
    () =>
      jiraAccounts?.map(acc => {
        return {
          label: acc.account_name,
          value: acc.id.toString(),
        };
      }),
    [jiraAccounts],
  );

  const selectedJiraAcc = useMemo(
    () => jiraAcc.find(acc => acc.value === watch('data.account')),
    [jiraAcc, watch('data.account')],
  );

  const jiraProj = useMemo(
    () =>
      jiraProjects?.map(acc => {
        return {
          label: acc.name,
          value: acc.key,
        };
      }),
    [jiraProjects, jiraAccounts],
  );
  const selectedJiraProj =
    useMemo(
      () => jiraProj.find(acc => acc.value === watch('data.project')),
      [jiraProj, watch('data.project'), watch('data.account')],
    ) || null;

  const projIssue = useMemo(
    () =>
      jiraProjectsIssue?.map(acc => {
        return {
          label: acc.name,
          value: acc.name,
          //value is needed as acc.name at BE.
        };
      }),
    [jiraProjectsIssue, jiraAccounts, jiraProjects],
  );
  const selectedProjIssue =
    useMemo(
      () => projIssue.find(acc => acc.value === watch('data.issue_type')),
      [projIssue, watch('data.issue_type')],
    ) || null;

  return (
    <Flex px={6} pt={4} flexDirection="column">
      <Box mb={3}>
        <FormField
          label="Select Jira Account"
          error={errors?.data?.account && errors.data.account.message}
        >
          <FrameWorkDropdown
            options={jiraAcc}
            isLoading={isLoading}
            value={selectedJiraAcc}
            onChange={o => {
              setValue('data.account', o!.value, { shouldDirty: true });
              setValue('data.project', '', { shouldDirty: true });
              setValue('data.issue_type', '', { shouldDirty: true });
            }}
            placeholder={'Select jira account'}
          />
        </FormField>
      </Box>
      <Box mb={3}>
        <FormField
          label="Select Jira Project"
          error={errors?.data?.project && errors.data.project.message}
        >
          <FrameWorkDropdown
            options={jiraProj}
            isLoading={isConfigLoading}
            value={selectedJiraProj}
            onChange={o => {
              setValue('data.project', o!.value, { shouldDirty: true });

              setValue('data.issue_type', '', { shouldDirty: true });
            }}
            placeholder={'Select Jira Project'}
          />
        </FormField>
      </Box>
      <Box mb={3}>
        <FormField
          label="Select an Issue Type"
          error={errors?.data?.issue_type && errors.data.issue_type.message}
        >
          <FrameWorkDropdown
            options={projIssue}
            isLoading={isProjectIssueFetching}
            value={selectedProjIssue}
            onChange={o => {
              setValue('data.issue_type', o!.value, { shouldDirty: true });
            }}
            placeholder={'Select Jira Issue Type'}
          />
        </FormField>
      </Box>
      <Box mb={2}>
        <FormField label="Title" error={errors?.data?.title && errors.data.title.message}>
          <Input {...register('data.title')} placeholder="Eg: {{event.incident_id}} triggered" />
        </FormField>
      </Box>
      <Box mb={2}>
        <FormField
          label="Message"
          error={errors?.data?.description && errors.data.description.message}
        >
          <Textarea
            {...register('data.description')}
            placeholder="Eg: {{event.incident_id}} triggered"
            rows={8}
          />
        </FormField>
      </Box>
    </Flex>
  );
};

export default JiraTicketForm;
