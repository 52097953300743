import { Box, Button, Divider, Flex, VStack } from '@chakra-ui/react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { COMPONENT_TYPE } from '../../../constants/status.constants';

import { getGroupLevelStyle, getItemStyle } from '../../../helpers/dragDropStyleHelper';
import { IComponentTreeItem, IfilterOption } from '../../../Interface';
import { InlineGroupCreate } from '../addComponentsForm/createGroup';
import { ComponentsOrGroup } from './componentOrGroup';
type VoidAction = () => void;
type ValueAction = (value: number) => void;
interface IAddComponentFormProps {
  showAddComponent: VoidAction;
  components: IComponentTreeItem[];
  addGroup: VoidAction;
  selectedGroupId: number;
  saveGroup: (value: string) => void;
  editGroup: ValueAction;
  cancelGroupChange: VoidAction;
  deleteGroup: (v: number, w?: number) => void;
  showEditComponent: (v: number, w?: number) => void;
  deleteComponent: ValueAction;
  onDragEnd: (r: DropResult) => void;
  groupOptions: IfilterOption[];
  isEdit?: boolean;
  componentCount: number;
  canEdit: boolean;
  selectedTeam: string;
}

export default function ComponentList({
  showAddComponent,
  components,
  addGroup,
  selectedGroupId,
  saveGroup,
  editGroup,
  cancelGroupChange,
  deleteGroup,
  showEditComponent,
  deleteComponent,
  onDragEnd,
  groupOptions,
  isEdit,
  componentCount,
  canEdit,
  selectedTeam,
}: IAddComponentFormProps) {
  return (
    <Box pr={5} height="100%" justifyContent="flex-start" width={'100%'}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" type="group">
          {(provided, snapshot) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getGroupLevelStyle(snapshot.isDraggingOver)}
            >
              {components.map((el, index) => {
                return (
                  <Draggable
                    key={`${el.name.repeat(index ?? 1) + index}`}
                    draggableId={`${el.name.repeat(index + 1) + index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Flex
                        key={index}
                        gap={5}
                        width={'100%'}
                        alignItems={'center'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        {selectedGroupId === el.id && el.type === COMPONENT_TYPE.GROUP ? (
                          <>
                            <InlineGroupCreate
                              componentData={components}
                              value={el.name}
                              onSubmit={saveGroup}
                              cancelGroupChange={cancelGroupChange}
                            />
                          </>
                        ) : (
                          <ComponentsOrGroup
                            selectedTeam={selectedTeam}
                            editComponent={showEditComponent}
                            type={el.type}
                            componentInfo={el}
                            index={index}
                            editGroup={editGroup}
                            deleteGroup={deleteGroup}
                            deleteComponent={deleteComponent}
                            groupOptions={groupOptions}
                            isEdit={isEdit || false}
                            componentCount={componentCount}
                            canEdit={canEdit}
                          />
                        )}
                      </Flex>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      {canEdit ? (
        <Box width={'100%'}>
          <Divider mb={5} />
          <VStack alignItems={'flex-start'} width={'100%'}>
            <Button
              color="blue.900"
              bg="unset"
              textDecoration={'underline'}
              name="components"
              _focus={{ outline: 'none', boxShadow: 'unset' }}
              onClick={addGroup}
              disabled={selectedGroupId !== -1}
            >
              Add Component Group
            </Button>
            <Button
              color="blue.900"
              bg="unset"
              textDecoration={'underline'}
              name="components"
              _focus={{ outline: 'none', boxShadow: 'unset' }}
              onClick={showAddComponent}
              // disabled={typeof showAddGroup == 'number'}
            >
              Add A Component
            </Button>
          </VStack>
        </Box>
      ) : null}
    </Box>
  );
}
