import styled from 'styled-components';

export const ModalContainer = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(223, 225, 229, 1);
  background-color: #fff;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  padding: 9px 20px;
  margin-top: 15px;
`;

export const ModalHeader = styled.h2`
  color: #09305a;
  font: 600 14px/150% Mulish, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const ModalContent = styled.div`
  border-bottom: 1px solid rgba(223, 225, 229, 1);
  padding-bottom: 9px;
  padding-top: 18px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const SquadMemberSelectionContainer = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const SquadMemberSelectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 44%;
  margin-left: 0;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const SquadMemberSelectionLabel = styled.label`
  color: #09305a;
  font: 400 14px/132% Mulish, sans-serif;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

export const SquadMemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const SquadMemberEmail = styled.div`
  color: #8890a2;
  font: 12px Mulish, sans-serif;
`;

export const SquadMemberRoleSelector = styled.div`
  color: #8890a2;
  font: 12px Mulish, sans-serif;
  margin-top: 4px;
`;

export const SquadRoleSelectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 56%;
  margin-left: 20px;
  position: relative;
  top: -6px;

  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
    margin-top: 40px;
  }
`;

export const SquadRoleSelectionLabel = styled.label`
  color: #000;
  display: flex;
  gap: 3px;
  padding-right: 5px;
  font-size: 14px;
  position: relative;
  top: 4px;

  @media (max-width: 991px) {
    padding-right: 20px;
  }
`;

export const SquadRoleDropdownIcon = styled.img`
  width: 18px;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
`;

export const SquadRoleOptionsContainer = styled.div`
  color: #122146;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 10px;
  white-space: nowrap;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export const SquadRoleOptionsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 12px;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export const SquadRoleOptionContainer = styled.div`
  border: 1px solid rgba(151, 151, 151, 1);
  border-radius: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 8px;
  cursor: pointer;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export const SquadRoleRadio = styled.div<{ checked: boolean }>`
  align-self: start;
  background-color: #fff;
  border: ${props => (props.checked ? '5px' : '2px')} solid rgba(27, 127, 241, 1);
  border-radius: 9999px;
  height: 16px;
  width: 16px;
`;

export const SquadRoleLabel = styled.span`
  flex-grow: 1;
  font: 400 14px/132% Mulish, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export const ModalFooter = styled.footer`
  display: flex;
  flex-direction: column;
  font: 400 14px/132% Mulish, sans-serif;
  margin-top: 18px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

export const ModalActions = styled.div`
  align-self: start;
  display: flex;
  gap: 11px;
  margin-top: 33px;
  white-space: nowrap;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export const AddMembersButton = styled.button`
  background-color: #0e61dd;
  border-radius: 4px;
  color: #fff;
  flex-grow: 1;
  font: 400 16px/150% Mulish, sans-serif;
  justify-content: center;
  padding: 5px 8px;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export const CancelButton = styled.button`
  background-color: #fff;
  border: 1px solid rgba(182, 188, 200, 1);
  border-radius: 4px;
  color: #000;
  flex-grow: 1;
  font: 400 16px/150% Mulish, sans-serif;
  justify-content: center;
  padding: 5px 8px;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;
