import { IAppState } from 'core/interfaces/IAppState';
import { filterDropdownOptions } from '../../incident-list/interfaces/common';
import { useOrganizationConfig } from '../../schedules';
import { getDropdownOptionDetails } from '../helpers';
import { FilterEntity } from './useEntityOwnerFilter';

interface Props {
  organizationFromProps?: IAppState['organization'];
  queryParamsString: string;
  entity: FilterEntity;
}

export const useEntityAssigneeFilter = ({
  organizationFromProps,
  queryParamsString,
  entity,
}: Props) => {
  const { organization } = useOrganizationConfig();
  const effectiveOrganization = organizationFromProps || organization;
  const { currentUserOption, selectAllOption, squadsOfCurrentUser, otherSquads, otherUsersOption } =
    getDropdownOptionDetails(effectiveOrganization);
  const getOwnUserAndSquad = () => {
    return [
      selectAllOption,
      ...currentUserOption,
      ...squadsOfCurrentUser.map(({ type: assigneeType, ...rest }) => ({
        assigneeType,
        ...rest,
      })),
    ];
  };

  const getAssigneeOptions = (escalationPolicyList: filterDropdownOptions[]) => {
    const assignneOptions = [
      { label: 'YOU & YOUR SQUADS', value: '', options: getOwnUserAndSquad() },
      { label: 'Users', value: '', options: otherUsersOption },
      {
        label: 'Squads',
        value: '',
        options: otherSquads.map(({ type: assigneeType, ...rest }) => ({
          assigneeType,
          ...rest,
        })),
      },
      { label: 'Escalation Policy', value: '', options: escalationPolicyList },
    ];

    return assignneOptions;
  };

  return { getAssigneeOptions, currentUserOption, squadsOfCurrentUser };
};
