import { CustomDrawerComponent } from 'library/molecules';

import { useIncidentSummaryContext } from '../../context/hook';
import { IncidentSummaryDrawerState } from '../../types';
import IncidentSummaryDrawerError from './error/Error';
import IncidentSummaryDrawerLoading from './loading/Loading';
import IncidentSummaryDrawerSuccess from './success/Success';
import IncidentSummaryDrawerTitle from './Title';
import IncidentSummaryDrawerFeedback from './feedback/Feedback';
import IncidentSummaryDrawerFooter from './Footer';

const IncidentSummaryDrawer: React.FC = () => {
  const { summaryDrawerOpen, setSummaryDrawerOpen, drawerState, canUseIncidentSummary } =
    useIncidentSummaryContext();

  if (!canUseIncidentSummary) return null;

  return (
    <CustomDrawerComponent
      title={<IncidentSummaryDrawerTitle />}
      footer={<IncidentSummaryDrawerFooter />}
      isOpen={summaryDrawerOpen}
      onClose={() => setSummaryDrawerOpen(false)}
      disableBodyPadding
    >
      {drawerState === IncidentSummaryDrawerState.LOADING && <IncidentSummaryDrawerLoading />}
      {drawerState === IncidentSummaryDrawerState.ERROR && <IncidentSummaryDrawerError />}
      {drawerState === IncidentSummaryDrawerState.SUCCESS && <IncidentSummaryDrawerSuccess />}
      {drawerState === IncidentSummaryDrawerState.FEEDBACK && <IncidentSummaryDrawerFeedback />}
    </CustomDrawerComponent>
  );
};

export default IncidentSummaryDrawer;
