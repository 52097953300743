import { Accordion, FocusBlock, Para, SelectBox, IconButton } from 'uie/components';
import { SelectOption } from 'components/SecondaryFilter/types';
import { ExtensionService, ServiceService } from 'core';
import { getOrgTeams } from 'core/api/organization/getTeams';
import {
  IChannelsForTeamResp,
  IConnectedMSTeamsTeams,
  IConversation,
  IExtensionMSTeams,
  IMSTeamsChannelsConfiguration,
} from 'core/interfaces/IExtensions';
import { IService } from 'core/interfaces/IService';
import { ISQTeams } from 'core/interfaces/ISQTeams';
import SQTeams from 'core/services/service.sqteams';
import { FormikErrors } from 'formik';
import React, { useEffect, useMemo } from 'react';
import styles from './index.module.css';

export type MSTeamsChannelsState = Array<{
  msteams_channel_id: string;
  msteams_channel_name: string;
}>;
interface IProps {
  teamConfig: IConnectedMSTeamsTeams;
  onChange: (teamConfig: IConnectedMSTeamsTeams) => void;
  allMSTeamsChannels: MSTeamsChannelsState;
  addToMsTeamsChannels: (channels: MSTeamsChannelsState) => void;
  connectedTeamsError?: any;
}

const Channels: React.FC<IProps> = props => {
  const [showNode, setShowNode] = React.useState(false);
  const { teamConfig, onChange } = props;

  const [data, setData] = React.useState<{
    teams: ISQTeams[];
    services: SelectOption[];
    channels: IConversation[] | null;
  }>({
    teams: [],
    services: [],
    channels: [],
  });

  const [searchTeamName, setSearchTeamName] = React.useState('');
  const [searchService, setSearchServices] = React.useState('');
  const [searchChannel, setSearchChannel] = React.useState('');

  const [sqTeamsService, sqServicesService, extensionService] = useMemo(() => {
    return [new SQTeams(), new ServiceService(''), new ExtensionService()];
  }, []);

  const fetchTeams = async () => {
    const { data: apiRes } = await sqTeamsService.getAllTeams();
    setData(prevData => ({
      ...prevData,
      teams: apiRes.data,
    }));
  };

  const fetchServices = async (teamId: string) => {
    const { data: apiRes } = await sqServicesService.getAll(teamId);
    const Options: SelectOption[] = [
      { label: 'All services', value: '*' },
      ...apiRes.data.map(service => {
        return { label: service.name, value: service.id };
      }),
    ];
    setData(prevData => ({
      ...prevData,
      services: Options,
    }));
  };

  const fetchChannels = async (teamId: string) => {
    const { data: apiRes } = await extensionService.getMSTeamsChannels(teamId);
    setData(prevData => ({
      ...prevData,
      channels: apiRes.data.conversations,
    }));
    return apiRes.data.conversations;
  };

  const setUpInitialData = async () => {
    await fetchTeams();
    if (teamConfig.team_id) {
      const channels = await fetchChannels(teamConfig.team_id);
      if (channels) {
        const channelsForTeam = channels.map(channel => ({
          msteams_channel_id: channel.id,
          msteams_channel_name: `${teamConfig.team_name}-${channel.name ?? 'General'}`,
        }));
        props.addToMsTeamsChannels(channelsForTeam);
      }
    }
  };

  useEffect(() => {
    fetchTeams();
    if (teamConfig.team_id) {
      setUpInitialData();
    }
  }, []);

  const onSelectSQTeam = async (
    params: {
      squadcast_team_id: string;
      squadcast_team_name: string;
    },
    index: number,
  ) => {
    const { squadcast_team_id, squadcast_team_name } = params;
    const newValue = [
      ...teamConfig.channel_configurations.slice(0, index),
      {
        ...teamConfig.channel_configurations[index],
        squadcast_team_id,
        squadcast_team_name,
        is_all_services: false,
        services: [
          {
            squadcast_service_id: '',
            squadcast_service_name: '',
          },
        ],
      },
      ...teamConfig.channel_configurations.slice(index + 1),
    ];
    onChange({
      ...teamConfig,
      channel_configurations: newValue,
    });
    fetchServices(squadcast_team_id);
  };

  const onSelectMSTeamsChannel = async (
    params: {
      msteams_channel_name: string;
      msteams_channel_id: string;
    },
    index: number,
  ) => {
    const { msteams_channel_name, msteams_channel_id } = params;
    const newValue = [
      ...teamConfig.channel_configurations.slice(0, index),
      {
        ...teamConfig.channel_configurations[index],
        msteams_channel_name,
        msteams_channel_id,
      },
      ...teamConfig.channel_configurations.slice(index + 1),
    ];
    onChange({
      ...teamConfig,
      channel_configurations: newValue,
    });
  };

  const onSelectSQService = (
    params: {
      squadcast_service_id: string;
      squadcast_service_name: string;
    },
    index: number,
  ) => {
    const { squadcast_service_id, squadcast_service_name } = params;
    const newValue = [
      ...teamConfig.channel_configurations.slice(0, index),
      {
        ...teamConfig.channel_configurations[index],
        is_all_services: squadcast_service_id === '*' ? true : false,
        services:
          squadcast_service_id === '*'
            ? []
            : [
                {
                  squadcast_service_id,
                  squadcast_service_name,
                },
              ],
      },
      ...teamConfig.channel_configurations.slice(index + 1),
    ];
    onChange({
      ...teamConfig,
      channel_configurations: newValue,
    });
  };

  const onDeleteMSChannel = (index: number) => {
    const newValue = [
      ...teamConfig.channel_configurations.slice(0, index),
      ...teamConfig.channel_configurations.slice(index + 1),
    ];
    onChange({
      ...teamConfig,
      channel_configurations: newValue,
    });
  };

  return (
    <div
      style={{
        marginBottom: '8px',
      }}
    >
      <Accordion
        title={
          <div
            className={styles.accordianHeader}
            onClick={() => {
              setShowNode(showNode => !showNode);
            }}
          >
            <div>
              <div className={styles.accordianHeaderSubtitle}>Microsoft Team</div>
              <div className={styles.accordianHeaderTitle}>{teamConfig.team_name}</div>
            </div>
          </div>
        }
      >
        {showNode && (
          <div className={styles.teamConfiguration}>
            {teamConfig.channel_configurations?.map((channel, index) => {
              return (
                <div className={styles.channelItem} key={channel?.id}>
                  <div className={styles.SelectWrapper}>
                    <div>
                      <div className={styles.selectTitle}>Squadcast Team</div>
                      <SelectBox
                        width="248px"
                        height="auto"
                        maxHeight="200px"
                        hook={<Para fontSize={14}>{channel?.squadcast_team_name}</Para>}
                        onValueChange={(event, value) => {
                          onSelectSQTeam(value, index);
                        }}
                        searchHookProps={{
                          placeholder: 'Search SQ Teams',
                          value: searchTeamName,
                          height: '24px',
                          fontSize: '16px',
                          onChange: e => setSearchTeamName(e.target.value),
                        }}
                      >
                        {data ? (
                          data?.teams
                            ?.filter(team =>
                              searchTeamName
                                ? team.name.toLowerCase().includes(searchTeamName.toLowerCase())
                                : true,
                            )
                            ?.map((team: any) => {
                              return (
                                <FocusBlock
                                  key={team.id}
                                  value={{
                                    squadcast_team_id: team.id,
                                    squadcast_team_name: team.name,
                                  }}
                                >
                                  {team.name}
                                </FocusBlock>
                              );
                            })
                        ) : (
                          <FocusBlock key={-1} value={-1}>
                            Loading...
                          </FocusBlock>
                        )}
                      </SelectBox>
                      {props.connectedTeamsError?.[index]?.squadcast_team_id ? (
                        <div className={styles.fieldError}>
                          {props.connectedTeamsError[index].squadcast_team_id}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className={styles.SelectWrapper}>
                    <div>
                      <div className={styles.selectTitle}>Services</div>
                      <SelectBox
                        width="248px"
                        height="auto"
                        maxHeight="200px"
                        hook={
                          <Para fontSize={14}>
                            {' '}
                            {channel.is_all_services
                              ? 'All services'
                              : channel.services?.[0]?.squadcast_service_name ?? ''}
                          </Para>
                        }
                        onValueChange={(event: any, value: any) => {
                          onSelectSQService(value, index);
                        }}
                        searchHookProps={{
                          placeholder: 'Search SQ Services',
                          value: searchService,
                          height: '24px',
                          fontSize: '16px',
                          onChange: e => setSearchServices(e.target.value),
                        }}
                      >
                        {data ? (
                          data?.services
                            ?.filter(service =>
                              service
                                ? service.label.toLowerCase().includes(searchService.toLowerCase())
                                : true,
                            )
                            ?.map((service: SelectOption) => {
                              return (
                                <FocusBlock
                                  key={service.value}
                                  value={{
                                    squadcast_service_id: service.value,
                                    squadcast_service_name: service.label,
                                  }}
                                >
                                  {service.label}
                                </FocusBlock>
                              );
                            })
                        ) : (
                          <FocusBlock key={-1} value={-1}>
                            Loading...
                          </FocusBlock>
                        )}
                      </SelectBox>
                      {props.connectedTeamsError?.[index]?.services ? (
                        <div className={styles.fieldError}>
                          {props.connectedTeamsError[index].services}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {data?.channels ? (
                    <div className={styles.SelectWrapper}>
                      <div>
                        <div className={styles.selectTitle}>Channels</div>
                        <SelectBox
                          width="248px"
                          height="auto"
                          maxHeight="200px"
                          hook={<Para fontSize={14}>{channel.msteams_channel_name}</Para>}
                          onValueChange={(e: any, value: any) => {
                            onSelectMSTeamsChannel(value, index);
                          }}
                          searchHookProps={{
                            placeholder: 'Search Channels',
                            value: searchChannel,
                            height: '24px',
                            fontSize: '16px',
                            onChange: e => setSearchChannel(e.target.value),
                          }}
                        >
                          {data?.channels.length ? (
                            data?.channels
                              ?.filter(channel =>
                                channel.name
                                  ? channel.name.toLowerCase().includes(searchChannel.toLowerCase())
                                  : true,
                              )
                              ?.map(channel => {
                                return (
                                  <FocusBlock
                                    key={channel.id}
                                    value={{
                                      msteams_channel_id: channel.id,
                                      msteams_channel_name: channel.name ?? 'General',
                                    }}
                                  >
                                    {channel?.name ?? 'General'}
                                  </FocusBlock>
                                );
                              })
                          ) : (
                            <FocusBlock key={-1} value={-1}>
                              Loading...
                            </FocusBlock>
                          )}
                        </SelectBox>
                        {props.connectedTeamsError?.[index]?.msteams_channel_id ? (
                          <div className={styles.fieldError}>
                            {props.connectedTeamsError[index].msteams_channel_id}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  <IconButton
                    onClick={() => {
                      onDeleteMSChannel(index);
                    }}
                    base="32px"
                    borderType="rounded"
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    <img src={'/icons/delete-ico.png'} alt="Delete" />
                  </IconButton>
                </div>
              );
            })}
            <div
              onClick={() => {
                onChange({
                  ...teamConfig,
                  channel_configurations: [
                    ...(teamConfig?.channel_configurations ?? []),
                    {
                      id: '',
                      msteams_channel_name: '',
                      squadcast_team_id: '',
                      is_all_services: false,
                      services: [],
                      squadcast_team_name: '',
                      msteams_channel_id: '',
                    },
                  ],
                });
              }}
              className={styles.addNewChannel}
            >
              + Add New Channel
            </div>
          </div>
        )}
      </Accordion>
    </div>
  );
};

const ConnectedMSTeamsTeams = React.memo(Channels);
export { ConnectedMSTeamsTeams };
