import React from 'react';
import { Label, TextButton, Theme } from 'uie/components';
import styled from 'styled-components';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
const { theme } = Theme;

const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-top: 24px;
  margin-bottom: 20px;
`;

const StyledTextButton = styled(TextButton)`
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
`;

const Empty: React.FC<{
  handleAddNewClick: () => void;
}> = props => {
  const hasUpdateAccess = useUserAccess().hasUpdateAccess('incidents');
  return (
    <div>
      <Text>Add your video call, chatops and external links to this incident</Text>
      <NoPermissionTooltip isDisabled={hasUpdateAccess}>
        <StyledTextButton
          color={theme.primary.default}
          buttonType="inverted"
          onClick={props.handleAddNewClick}
          disabled={!hasUpdateAccess}
        >
          <Label
            style={{
              cursor: 'pointer',
            }}
            color={theme.primary.default}
            fontSize={12}
            fontWeight={400}
          >
            <span
              style={{
                marginRight: '4px',
              }}
            >
              +
            </span>
            Add Link
          </Label>
        </StyledTextButton>
      </NoPermissionTooltip>
    </div>
  );
};

export default Empty;
