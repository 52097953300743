import {
  Grid,
  Heading,
  Para,
  DropDown,
  FormBlock,
  Label,
  InputBlock,
  SelectBox,
  FocusBlock,
  IconButton,
  ErrorBlock,
  TextButton,
  DialogModalFrame,
  Theme,
} from 'uie/components';
import React from 'react';
import { AddUsers } from '.';
import UnsavedChangesGuard from '../../../../../../components/unsavedChangesGuard';
import { InfoIcon, DeleteIcon } from '../../../../../../icons';

const { theme } = Theme;

export function render(this: AddUsers) {
  const validInviteCount = this.props.users.filter(
    mu => mu.email !== '' && mu.first_name !== '' && mu.last_name !== '',
  ).length;

  return (
    <Grid
      style={{ padding: '16px 32px', overflow: 'auto', height: '100%' }}
      type="column"
      onDragOver={this.handleDrag}
      onDrop={this.handleFileDrop}
    >
      <Heading fontSize={24} fontWeight={400} height={24} className="mb-10">
        Add New User(s)
      </Heading>

      {this.props.selectedOrg.allowedDomains.length > 0 && (
        <Grid type="row" style={{ marginTop: '6px', marginBottom: '8px' }}>
          <Para fontSize={16} color="#09305A" style={{ marginRight: '8px' }}>
            Allowed email domains for your organization:
          </Para>
          <Para fontSize={16} fontWeight={600} color="#09305A">
            {this.props.selectedOrg.allowedDomains.join(', ')}
          </Para>
        </Grid>
      )}

      <Grid>
        <Para fontSize={16} fontWeight={400} color={theme.shades.grey} style={{ marginRight: 12 }}>
          Drag and drop .csv file or
          <label className="cursor-pointer ml-5 mr-5" style={{ color: theme.primary.default }}>
            select a file from your computer
            <input onChange={this.handleFileUpload} type="file" accept="text/csv" hidden={true} />
          </label>
          to bulk import users.
        </Para>
        <DropDown
          hook={<InfoIcon height={16} width={16} stroke={theme.shades.grey} />}
          trigger="hover"
          padding="0"
        >
          <Grid style={{ width: 150, padding: 16 }} type="column">
            <Para fontSize={14} fontWeight={500}>
              CSV File
            </Para>
            <Para fontSize={12} fontWeight={400} color={theme.shades.grey}>
              Import a CSV file with the main input field types as headers. New values will be
              automatically appended to the existing data.
            </Para>
          </Grid>
        </DropDown>
      </Grid>

      <FormBlock onFormSubmit={this.handleSubmit}>
        <Grid type="column">
          <Grid className="mb-10" style={{ marginTop: '48px' }}>
            <Grid flexWidth={3} className="mr-20">
              <Label fontWeight={400}>First Name</Label>
            </Grid>
            <Grid flexWidth={3} className="mr-20">
              <Label fontWeight={400}>Last Name</Label>
            </Grid>
            <Grid flexWidth={3} className="mr-20">
              <Label fontWeight={400}>Email</Label>
            </Grid>
            <Grid flexWidth={3} className="mr-20">
              <Label fontWeight={400}>User Type</Label>
            </Grid>
            <Grid flexWidth={1} />
          </Grid>

          {this.props.users.map((user, idx) => (
            <Grid key={idx} style={{ marginBottom: '24px' }}>
              <Grid flexWidth={3} className="mr-20">
                <InputBlock
                  placeholder="First Name"
                  onChange={this.handleValueUpdate('first_name', idx)}
                  error={!!this.state.error[`e_${idx}_firstName`]}
                  value={user.first_name}
                />
              </Grid>

              <Grid flexWidth={3} className="mr-20">
                <InputBlock
                  placeholder="Last Name"
                  onChange={this.handleValueUpdate('last_name', idx)}
                  error={!!this.state.error[`e_${idx}_lastName`]}
                  value={user.last_name}
                />
              </Grid>

              <Grid flexWidth={3} className="mr-20">
                <InputBlock
                  placeholder="Email"
                  error={!!this.state.error[`e_${idx}_email`]}
                  onChange={this.handleValueUpdate('email', idx)}
                  value={user.email}
                />
              </Grid>

              <Grid flexWidth={3} className="mr-20">
                <SelectBox
                  hook={<Para>{user.role.name}</Para>}
                  onValueChange={this.handleRoleChange(idx)}
                  height="auto"
                  width="100%"
                  padding="0"
                >
                  {this.props.roles.r
                    .filter(role => role.name !== 'Account Owner')
                    .map(role => (
                      <FocusBlock
                        key={role._id}
                        value={role}
                        isSelected={user.role._id === role._id}
                      >
                        {role.name}
                      </FocusBlock>
                    ))}
                </SelectBox>
              </Grid>

              <Grid flexWidth={1}>
                <IconButton
                  color={theme.danger.light}
                  style={{ marginTop: '6px' }}
                  onClick={this.handleDeleteUser(idx)}
                >
                  <DeleteIcon stroke={theme.danger.default} style={{ width: '18px' }} />
                </IconButton>
              </Grid>
            </Grid>
          ))}

          <Grid style={{ marginBottom: '24px' }}>
            <Grid flexWidth={3} className="mr-20">
              <InputBlock placeholder="First Name" disabled={true} />
            </Grid>

            <Grid flexWidth={3} className="mr-20">
              <InputBlock placeholder="Last Name" disabled={true} />
            </Grid>

            <Grid flexWidth={3} className="mr-20">
              <InputBlock placeholder="Email" disabled={true} />
            </Grid>

            <Grid flexWidth={3} className="mr-20">
              <SelectBox
                hook={<Para>Stakeholder</Para>}
                onValueChange={this.handleRoleChange(-1)}
                height="auto"
                width="100%"
                padding="0"
                disabled={true}
              >
                {this.props.roles.r
                  .filter(role => role.name !== 'Account Owner')
                  .map(role => (
                    <FocusBlock key={role._id} value={role}>
                      {role.name}
                    </FocusBlock>
                  ))}
              </SelectBox>
            </Grid>

            <Grid flexWidth={1}>
              <IconButton color={theme.danger.light} style={{ marginTop: '6px' }} disabled={true}>
                <DeleteIcon stroke={theme.danger.default} style={{ width: '18px' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        {this.state.error.message && <ErrorBlock>{this.state.error.message}</ErrorBlock>}

        <Grid className="mt-20" style={{ marginBottom: 150 }}>
          <Grid flexWidth={12} justifyContent="flex-start">
            <TextButton
              style={{ padding: '8px 20px' }}
              onClick={this.handleSubmit}
              disabled={this.state.loading}
            >
              <Para fontWeight={500} color={theme.shades.white}>
                Send {validInviteCount} Invite(s)
              </Para>
            </TextButton>
            <TextButton
              buttonType="ghost"
              style={{ padding: '8px 20px', marginLeft: 20 }}
              onClick={this.redirectToUsers}
            >
              <Para fontWeight={500} color={theme.primary.default}>
                Cancel
              </Para>
            </TextButton>
          </Grid>
        </Grid>
      </FormBlock>

      <DialogModalFrame
        id="users-invite-sent"
        onClose={this.redirectToUsers}
        padding="24px"
        width="400px"
      >
        {this.state.modal === 'invite_sent' && (
          <Grid type="column">
            <Grid justifyContent="space-between" alignItems="center" className="mb-20">
              <Heading height={35} fontSize={24}>
                Invite sent Successfully
              </Heading>
            </Grid>
            <Para fontWeight={400} color={theme.shades.grey}>
              {this.state.invitedUsers} users are invited to the platform.
            </Para>
            <TextButton style={{ padding: '8px 20px' }} onClick={this.redirectToUsers}>
              <Para fontWeight={500} color={theme.shades.white}>
                Close
              </Para>
            </TextButton>
          </Grid>
        )}
      </DialogModalFrame>

      <DialogModalFrame
        id="users-invite-sent"
        onClose={this.handleCancelMergeData}
        padding="24px"
        width="400px"
      >
        {this.state.modal === 'merge_data' && (
          <Grid type="column">
            <Grid justifyContent="space-between" alignItems="center" className="mb-20">
              <Heading height={35} fontSize={24}>
                Merge Data
              </Heading>
            </Grid>
            <Para fontWeight={400} color={theme.shades.grey}>
              Merge entered data from manual fields with the data from the CSV upload.{' '}
              {this.state.toMergeUsers.length} users will be merged{' '}
            </Para>
            <Grid justifyContent="flex-start" style={{ marginTop: '28px' }}>
              <TextButton style={{ padding: '8px 20px' }}>
                <Para
                  fontWeight={500}
                  color={theme.shades.white}
                  onClick={this.handleConfirmMergeData}
                >
                  Continue
                </Para>
              </TextButton>
              <TextButton
                style={{ padding: '8px 20px', marginLeft: '24px' }}
                buttonType="ghost"
                onClick={this.handleCancelMergeData}
              >
                <Para fontWeight={500} color={theme.primary.default}>
                  Cancel
                </Para>
              </TextButton>
            </Grid>
          </Grid>
        )}
      </DialogModalFrame>

      <UnsavedChangesGuard checkDirty={this.checkDirty} />
    </Grid>
  );
}
