import { isEmpty } from 'lodash';
import moment from 'moment';
import { DateTime } from 'luxon';

import { INIT_VALUES, PAGE_SCHEMA } from '../constants/schema';
import {
  FILTER_TYPES,
  filterDetails,
  PAGE_STATUS,
  ISSUE_FILTER_TYPES,
  COMPONENT_TYPE,
  PAGES_STATUS_TYPES,
  issueFilterDetails,
} from '../constants/status.constants';
import {
  IfilterObject,
  IFilterTag,
  IIssuefilterObject,
  IOptionComponent,
  IStatus,
  IStatusCode,
  IStatusIssueState,
  IStatusIssueStateMessage,
} from '../Interface';
import { issueFilterInit } from '../reducers/IssueListReducer';

type ComponentState = 'loading' | 'failed' | 'done';

const getLoadingState = (
  isLoading: boolean,
  isSuccess: boolean,
  isError: boolean,
): ComponentState => {
  if (isLoading) return 'loading';
  if (isSuccess) return 'done';
  if (isError) return 'failed';

  return 'loading';
};

const valueSeparator = ',';
const paramSeparator = '&';
const keyValueSeparator = ':';

const encodeFilterParam = (filters: IfilterObject) => {
  const filterParams: string[] = [];
  Object.values(FILTER_TYPES).forEach(key => {
    const values = filters[key].join(valueSeparator);
    if (values.length) {
      filterParams.push(`${key}${keyValueSeparator}${values}`);
    }
  });
  return encodeURI(filterParams.join(paramSeparator));
};

const decodeFilterParam = (param: string) => {
  const decodedString = decodeURI(param).split(paramSeparator);
  const filterParams: IfilterObject = {
    [FILTER_TYPES.PAGE_TYPE]: [],
    [FILTER_TYPES.STATUS]: [],
  };
  Object.values(FILTER_TYPES).forEach(key => {
    const keyValue: string[] = decodedString.filter(p => p.startsWith(key));
    if (keyValue.length) {
      const values: string = keyValue[0].split(keyValueSeparator)[1];
      filterParams[key] = values.split(valueSeparator);
    }
  });
  return filterParams;
};

const encodeIssueFilterParam = (filters: IIssuefilterObject) => {
  const filterParams: string[] = [];
  Object.values(ISSUE_FILTER_TYPES).forEach((key: ISSUE_FILTER_TYPES) => {
    const values = filters[key].join(valueSeparator);
    if (values.length) {
      filterParams.push(`${key}${keyValueSeparator}${values}`);
    }
  });
  return encodeURI(filterParams.join(paramSeparator));
};

const decodeIssueFilterParam = (param: string) => {
  const decodedString = decodeURI(param).split(paramSeparator);
  const filterParams: IIssuefilterObject = { ...issueFilterInit };
  Object.values(ISSUE_FILTER_TYPES).forEach((key: ISSUE_FILTER_TYPES) => {
    const keyValue: string[] = decodedString.filter(p => p.startsWith(key));
    if (keyValue.length) {
      const values: string = keyValue[0].split(keyValueSeparator)[1];
      if (key === ISSUE_FILTER_TYPES.TIME || key === ISSUE_FILTER_TYPES.COMPONENT_STATUS) {
        filterParams[key] = values.split(valueSeparator);
      } else {
        filterParams[key] = values.split(valueSeparator).map(val => parseInt(val));
      }
    }
  });
  return filterParams;
};

const getStructuredFilters = (filters: IfilterObject): IFilterTag[] => {
  const filter: IFilterTag[] = [];
  Object.values(FILTER_TYPES).forEach(key => {
    const values = filters[key];
    if (values.length) {
      values.forEach(value => {
        const textValue = filterDetails[key].filterOptions.filter(opt => opt.value === value)[0];
        filter.push({
          type: key,
          value,
          label: `${key} : ${textValue.label}`,
        });
      });
    }
  });
  return filter;
};

const getStructuredIssueFilters = (filters: IIssuefilterObject): IFilterTag[] => {
  const filter: IFilterTag[] = [];
  Object.values(ISSUE_FILTER_TYPES).forEach(key => {
    const values = filters[key];
    if (values.length) {
      values.forEach(value => {
        const textValue = issueFilterDetails[key].filterOptions.filter(
          opt => opt.value === value,
        )[0];
        filter.push({
          type: key,
          value: value.toString(),
          label: `${key} : ${textValue.label}`,
        });
      });
    }
  });
  return filter;
};

const getPageInputDetails = (pageType: string) => {
  const init = INIT_VALUES[pageType];
  const schema = PAGE_SCHEMA[pageType];

  return { initValues: init, schema };
};

const getComponent = (compList: any[], id: string) => {
  return compList.find((comp: any) => comp.id === id);
};

const getSelectedComponents = (componentOptions: IOptionComponent[], prevSelectedCom: any[]) => {
  const selectedComponents: any = [];
  componentOptions.forEach((group: IOptionComponent) => {
    if ((group.options || []).length > 0) {
      group.options?.forEach((comp: IOptionComponent) => {
        if (comp.checked) {
          const com = getComponent(prevSelectedCom, comp.value);
          if (!isEmpty(com)) {
            selectedComponents.push(com);
          } else {
            selectedComponents.push({
              label: `${group.label} / ${comp.label}`,
              id: comp.value,
              value: comp?.status,
              name: comp.label,
            });
          }
        }
      });
    } else if (group.checked) {
      const com = getComponent(prevSelectedCom, group.value);
      if (!isEmpty(com)) {
        selectedComponents.push(com);
      } else {
        selectedComponents.push({
          label: group.label,
          value: group.status,
          id: group.value,
          name: group.label,
        });
      }
    }
  });
  return selectedComponents;
};

const getStatusDetails = (statusOptions: IStatusCode[], statusCode: number) => {
  const status = statusOptions.filter((status: IStatusCode) => status.id === statusCode);
  return {
    value: status[0].id,
    label: status[0].message,
  };
};

const getIssueStateData = (states: IStatusIssueState[], timeZone: string) => {
  const issues: any[] = [];
  states.forEach((issueState: IStatusIssueState) => {
    const messages: any[] = [];
    issueState.issueMessages.forEach((message: IStatusIssueStateMessage) => {
      if (message.isDelete === '1') {
        messages.push({
          id: message.id,
        });
      } else if (message.tempText.length > 0 && message.isApiSavePending === '1') {
        messages.push({
          id: message.id,
          text: message.tempText,
          timestamp: DateTime.fromISO(`${message.date}T${message.time}`, { zone: timeZone })
            .toUTC()
            .toISO(),
        });
      }
    });

    if (messages.length > 0) {
      issues.push({
        stateID: issueState.id,
        stateMessages: messages,
      });
    }
  });
  return issues.length > 0 ? issues : undefined;
};

const formatIssueCreateData = (data: any, pageId: number, includeIssueStates = true) => {
  const components = data.selectedComponents.map((comp: any) => {
    return {
      id: comp.id,
      statusID: comp.value.value,
    };
  });

  const requestBody = {
    title: data.title,
    statusID: parseInt(data.status.value),
    pageID: pageId,
    components,
  };

  return requestBody;
};

const formatComponentToOption = (component: any): IOptionComponent => {
  return {
    checked: false,
    isCollapsible: component.type === COMPONENT_TYPE.GROUP,
    value: component.id,
    isOpen: true,
    label: component.componentName || component.name,
    isHidden:
      (component.type === COMPONENT_TYPE.GROUP && (component.components || []).length === 0) ||
      component.isHidden,
  };
};

const generateTimeIntervals = () => {
  const times = [moment().startOf('day').format('HH:mm')];
  let momentObj = moment().startOf('day');
  // eslint-disable-next-line no-constant-condition
  while (true) {
    momentObj = momentObj.add(15, 'minutes');
    if (momentObj.format('HH:mm') === times[0]) {
      break;
    } else {
      times.push(momentObj.format('HH:mm'));
    }
  }
  return times;
};

const getStatus = (
  currentStatus: IStatus,
  isUnderMaintenance: boolean,
  maintenanceStatus: IStatus,
) => {
  return currentStatus.slug === PAGES_STATUS_TYPES.OPERATIONAL && isUnderMaintenance
    ? maintenanceStatus
    : currentStatus;
};

export {
  getLoadingState,
  encodeFilterParam,
  decodeFilterParam,
  getStructuredFilters,
  getPageInputDetails,
  getSelectedComponents,
  getStatusDetails,
  formatIssueCreateData,
  encodeIssueFilterParam,
  decodeIssueFilterParam,
  formatComponentToOption,
  generateTimeIntervals,
  getIssueStateData,
  getStatus,
  getStructuredIssueFilters,
};
