import { Redirect, useLocation } from 'react-router-dom';
import { INCIDENT_DETAILS_PATH } from './routes';
import { generatePath } from 'react-router-dom';

function Reroute() {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const incidentId = searchParams.get('id');

  return incidentId ? (
    <Redirect to={generatePath(INCIDENT_DETAILS_PATH, { id: incidentId ?? '' })} />
  ) : null;
}

export default Reroute;
