import React from 'react';
import {
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useMultiStyleConfig,
  Button,
} from '@chakra-ui/react';

interface Props {
  items: React.ReactNode;
}
const SeeMore: React.FC<Props> = ({ items }) => {
  const styles = useMultiStyleConfig('SeeMore', {});
  if (!items) return null;
  return (
    <Popover>
      <PopoverTrigger>
        <Button variant="ghost" aria-label="See more" sx={styles.popoverButton}>
          <Text variant="h4" sx={styles.popoverText}>
            ...
          </Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent w="auto" sx={styles.popoverContent}>
        <PopoverArrow />
        <PopoverBody sx={styles.popoverBody}>
          <HStack sx={styles.popoverData}>{items}</HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export { SeeMore };
