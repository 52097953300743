import React, { Component, ChangeEvent } from 'react';
import { TextButton, InputBox } from '@squadcast/alchemy-ui/metal';
import { IStatusPage } from '../../../../../../core/interfaces/IStatusPage';
import { Row } from '@squadcast/alchemy-ui/carbon';
import { deepCopy, pick } from 'core/helpers';
import equal from 'fast-deep-equal/es6/react';
import { IComponentErrorState } from '../../../../../../core/interfaces/IComponentState';

interface IProps {
  hide: (isASave?: boolean, pageConfig?: any) => void;
  pageConfig: Pick<IStatusPage, 'pageConfig'>;
  checkAndSetDirty: () => void;
}

interface IState {
  pageConfig: Pick<IStatusPage, 'pageConfig'>;
  errors: IComponentErrorState;
}

class HeaderModal extends Component<IProps, IState> {
  public fileRefs: any = null;
  private _initialState?: IState;

  constructor(props: IProps) {
    super(props);
    this.state = {
      pageConfig: deepCopy(this.props.pageConfig), //to not modify the props incase user do discard changes
      errors: {},
    };
    this._initialState = deepCopy(this.state);
  }

  public onHeaderAliasChange = (value: string, index: number) => {
    this.setState(({ pageConfig }) => {
      const { pageConfig: newPageConfig } = pageConfig;
      newPageConfig.statusHeaders[index].alias = value;
      return { pageConfig: { pageConfig: newPageConfig } };
    });
  };

  public openUploadFile = () => {
    if (!this.fileRefs) return;

    this.fileRefs.click();
  };

  public onLogoUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    try {
      const logo = event.target.files[0];
      const fileExtensions = logo.type.split('/')[1];

      const allowedExtensions = /(jpg|jpeg|png|gif)$/i;

      if (logo.size / 1025 > 70) {
        alert('logo should be less than 70kb');
        return;
      }

      if (!allowedExtensions.exec(fileExtensions)) {
        alert('Please upload file having extensions .jpeg/.jpg/.png/.gif only.');
        return;
      }

      (file => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.setState(({ pageConfig }) => {
            pageConfig.pageConfig.logoUrl = reader.result as any;
            return { pageConfig };
          });
        };
        reader.onerror = error => {
          console.log('Error: ', error);
        };
      })(logo);
    } catch (err: any) {
      console.log(err);
    }
  };

  validate = () => {
    const { pageConfig } = this.state.pageConfig;
    return pageConfig.statusHeaders.reduce((errors: any, header, index) => {
      if (header.alias.trim() === '') {
        errors[`status_page_header_${index}`] = "status heading can't be empty";
      }
      return errors;
    }, {});
  };

  onSave = () => {
    const errors = this.validate();
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.props.hide(true, this.state.pageConfig.pageConfig);
  };
  deleteLogo = () => {
    this.setState(({ pageConfig }) => {
      pageConfig.pageConfig.logoUrl = '';
      return { pageConfig };
    });
  };
  componentDidUpdate() {
    if (
      this._initialState &&
      !equal(pick(this.state, 'pageConfig'), pick(this._initialState, 'pageConfig'))
    )
      this.props.checkAndSetDirty();
  }

  public render() {
    const {
      pageConfig: { pageConfig },
      errors,
    } = this.state;
    return (
      <div
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <div className="">
          <div className="float-left">
            <h1 className="modal-container-heading">Edit Header</h1>
          </div>
        </div>
        <div className="mt-20" style={{ clear: 'both' }}>
          <div>
            <p className="modal-container-sub-heading">Company Logo</p>
            <div
              style={{
                border: 'solid 1px var(--shades-smoke)',
                borderRadius: 6,
              }}
            >
              <Row flexWidth={12} alignItems="center">
                <Row flexWidth={9}>
                  <img
                    className="company-logo-input"
                    src={(pageConfig.logoUrl as string) || '/assets/statusPageDefaultLogo.png'}
                    alt="company logo"
                  />
                </Row>

                <Row flexWidth={!pageConfig.logoUrl ? 12 : 3}>
                  <div className="span-child-w-1-1">
                    <TextButton
                      id="upload_company_logo"
                      onClick={() => this.openUploadFile()}
                      type="ghost"
                      size="normal"
                      text={!pageConfig.logoUrl ? 'Upload Logo' : 'Change Logo'}
                    />
                    {pageConfig.logoUrl && (
                      <TextButton
                        id="upload_company_logo_delete_button"
                        onClick={() => this.deleteLogo()}
                        type="ghost"
                        size="normal"
                        text={'Delete Logo'}
                      />
                    )}
                  </div>
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={ref => (this.fileRefs = ref)}
                    onChange={this.onLogoUpload}
                    accept=".png, .jpg"
                  />
                </Row>
              </Row>
            </div>
            <p
              style={{
                margin: '2px 0 0 0',
                textAlign: 'right',
                fontSize: 12,
                color: 'var(--shades-cement)',
              }}
            >
              works best for 1300x250
            </p>
          </div>
          <div className="mt-10">
            {pageConfig.statusHeaders.map((header, index) => {
              return (
                <div key={index}>
                  <p
                    className="modal-container-sub-heading"
                    style={{ margin: 0, marginBottom: 10, color: 'black' }}
                  >
                    {header.label === 'maintenance'
                      ? `Status Heading (if system is under ${header.label})`
                      : `Status Heading (if system is ${header.label})`}{' '}
                    <span className="red-color-text">*</span>
                  </p>
                  <InputBox
                    id={`status_page_header_${index}`}
                    label=""
                    type="text"
                    name={`${header.label} status`}
                    value={header.alias as any}
                    placeHolder="Alias"
                    onChange={(_, value) => this.onHeaderAliasChange(value, index)}
                    error={errors[`status_page_header_${index}`]}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-20">
          <TextButton id="header_modal_save" onClick={this.onSave} size="normal" text="Save" />
        </div>
      </div>
    );
  }
}

export default HeaderModal;
