import React, { Component, ChangeEvent } from 'react';
import '../common.css';
import { Link } from 'react-router-dom';
import { IAppState } from '../../../core/interfaces/IAppState';
import { IComponentErrorState, IComponentState } from '../../../core/interfaces/IComponentState';
import { requestLogin, onLoginSuccess } from '../../../core/actions/auth';
import { connect } from 'react-redux';
import { Grid, PulseLoader, Heading, Theme, Para, Label, Radio } from 'uie/components';
import { isEmailValid, getProperty } from '../../../core/helpers';
import { REQUEST_LOGIN_FAIL } from '../../../core/const/auth';
import cx from 'classnames';
import { CommonAuth } from '../commonAuth';
import { IUserRequestLogin } from '../../../core/interfaces/IUserData';
import { API } from '../../../core/api';
import { AppConfig } from 'shared/app.config';
const { theme } = Theme;

interface IProps extends Pick<IAppState, 'auth'> {
  requestLogin: (params: IUserRequestLogin) => void;
  onLoginSuccess: (sessionId: string) => void;
}

interface IState {
  componentState: IComponentState;
  email: string;
  password: string;
  errors: IComponentErrorState;
  showPassword: boolean;
}

class LogIn extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      componentState: 'idle',
      email: '',
      password: '',
      showPassword: false,
      errors: {},
    };
  }

  private dataCenters = [
    { label: 'US', url: AppConfig.us_app_url },
    { label: 'EU', url: AppConfig.eu_app_url },
  ];

  componentDidMount() {
    const { email, redirectFrom } = CommonAuth.db;
    if ((redirectFrom === 'a0' || redirectFrom === 'register') && email.length > 0) {
      this.setState({ email });
    }
    CommonAuth.clearDb();
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.auth.action !== prevProps.auth.action) {
      this.onAuthActionChange();
    }
    window.addEventListener('online', this.onOnline);
    window.addEventListener('offline', this.onOffline);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.onOnline);
    window.removeEventListener('offline', this.onOffline);
  }

  onOnline = () => {
    this.setState(state => ({
      ...state,
      errors: {
        ...state.errors,
        network: undefined,
      } as any,
    }));
  };

  onOffline = () => {
    this.setState(state => ({
      ...state,
      errors: {
        ...state.errors,
        network: 'No Network connection, please connect to the internet.',
      } as any,
    }));
  };

  public checkValidationErrors = () => {
    const errors: any = {};
    if (!isEmailValid(this.state.email)) {
      errors.email = 'Please enter a valid Email-id';
    }
    if (!isPasswordValid(this.state.password)) {
      errors.password = "Password Doesn't seems right";
    }

    if (!navigator.onLine) {
      errors.network = 'No Network connection, please connect to the internet.';
    }

    this.setState(state => ({
      ...state,
      errors: {
        ...errors,
        ...state.errors,
      } as any,
    }));
    return Object.entries(errors).length > 0;
  };

  public login = (e?: any) => {
    if (e) {
      e.preventDefault();
    }

    if (this.checkValidationErrors()) {
      return;
    }

    this.setState({
      componentState: 'busy',
    });

    this.props.requestLogin({
      email: this.state.email,
      password: this.state.password,
    });
  };

  public onValueChange = (key: 'email' | 'password') => (e: ChangeEvent<HTMLInputElement>) => {
    const newState: any = {};
    newState[key] = e.target.value || '';
    newState.errors = {};
    this.setState(newState);
  };

  private onRedirectDC = (url: string) => () => {
    window.location.assign(url);
  };

  private isCurrentDC = (url: string) => {
    return window.location.origin.includes(url);
  };

  public onAuthActionChange = () => {
    if (
      this.state.componentState === 'busy' &&
      this.props.auth.error &&
      this.props.auth.error.type === REQUEST_LOGIN_FAIL
    ) {
      const error = this.props.auth.error?.reason ?? false;

      const [reason, message] = [
        error?.reason ?? 'password',
        error?.message ?? 'Error processing request. Please try again',
      ];

      this.setState({
        componentState: 'idle',
      });

      if (reason === 'N/A' || reason === 'email') {
        this.setState({
          errors: {
            email: message,
          },
        });
      } else if (reason === 'password') {
        this.setState({
          errors: {
            password: message,
          },
        });
      } else if (reason === 'sso') {
        this.setState({
          errors: {
            sso: message,
          },
        });
      } else if (reason === 'ratelimit') {
        this.setState({
          errors: {
            password: message,
          },
        });
      }
    }
  };

  public toggleShowPassword = (e: any) => {
    e.preventDefault();
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const { email, password, showPassword, errors, componentState } = this.state;

    return (
      <div className="login-container">
        <div className="login-interact" style={{ paddingTop: '15px' }}>
          <div
            style={{
              fontSize: '14px',
              lineHeight: '16px',
              textAlign: 'center',
              marginBottom: '40px',
            }}
          >
            <img
              className="banner-logo"
              src="/assets/new_logo.svg"
              alt="Squadcast-logo"
              style={{ width: '180px', marginBottom: '4px' }}
            />
            <div style={{ marginBottom: '24px' }}>Incident Response for SRE, Devops & IT teams</div>
          </div>
          <Heading
            fontSize={24}
            height={24}
            style={{
              lineHeight: '34px',
              display: 'block',
              textAlign: 'center',
              marginBottom: '36px',
            }}
          >
            Login to your account
          </Heading>

          <form onSubmit={this.login}>
            <div className="login-input-container login-m-t-60">
              <label className="login-label dark" htmlFor="login_email">
                Work Email
              </label>
              <input
                id="login_email"
                className={cx('login-input', { error: errors.email })}
                value={email}
                placeholder="example@company.com"
                onChange={this.onValueChange('email')}
                type="email"
              />
              <p className="login-error-message" style={{ marginTop: '4px' }}>
                {errors.email || ''}
              </p>
            </div>

            <div className="login-input-container" style={{ marginTop: '0px' }}>
              <Grid justifyContent="space-between" alignItems="center">
                <label className="login-label dark" htmlFor="login_password">
                  Password
                </label>
                <span style={{ marginBottom: 16 }}>
                  <Link className="login-forgot-password" to="/forgot-password">
                    Forgot Password ?
                  </Link>
                </span>
              </Grid>
              <div>
                <Grid justifyContent="flex-start" alignItems="center">
                  <input
                    id="login_password"
                    className={cx('login-input', { error: errors.password })}
                    style={{ paddingRight: '50px' }}
                    value={password}
                    placeholder="Enter your password"
                    onChange={this.onValueChange('password')}
                    type={showPassword ? 'text' : 'password'}
                  />
                  <img
                    onClick={this.toggleShowPassword}
                    style={{ marginLeft: '-40px', cursor: 'pointer' }}
                    src={`/icons/${showPassword ? 'hide_password' : 'show_password'}.svg`}
                    alt={showPassword ? 'hide password' : 'show password'}
                  />
                </Grid>
              </div>
              <p className="login-error-message" style={{ marginTop: '4px' }}>
                {errors.password || ''}
              </p>
            </div>

            <div className="login-input-container" style={{ display: 'inline' }}>
              <Para
                fontSize={16}
                style={{
                  marginBottom: '15px',
                  fontWeight: 400,
                  marginTop: '-10px',
                }}
              >
                Select the data centre region to store your squadcast specific product data
              </Para>
              {this.dataCenters.map(dc => (
                <label
                  htmlFor={dc.label}
                  key={dc.label}
                  style={{ fontWeight: 600, marginBottom: 10, marginRight: 80 }}
                >
                  <Radio
                    id={dc.label}
                    value={dc.label}
                    checked={this.isCurrentDC(dc.url)}
                    onChange={this.onRedirectDC(dc.url)}
                  />
                  <span className="ml-10">{dc.label}</span>
                </label>
              ))}
            </div>

            <span
              className="login-error-message"
              style={{ display: 'block', marginTop: '4px', marginBottom: '4px' }}
            >
              {errors.sso || errors.network || ''}
            </span>
            <button
              className="login-get-started"
              disabled={email === '' || password === '' || componentState === 'busy'}
              onClick={this.login}
              style={{ marginTop: 10 }}
            >
              {componentState === 'busy' ? (
                <Grid alignContent="center" justifyContent="center" data-testid="pulse_loader">
                  <PulseLoader base="8px" color={theme.shades.white} />
                </Grid>
              ) : (
                'LOGIN'
              )}
            </button>
            <div style={{ position: 'relative', textAlign: 'center', marginTop: '20px' }}>
              <hr
                style={{
                  position: 'absolute',
                  width: '100%',
                  backgroundColor: Theme.theme.shades.grey,
                }}
              />
              <div
                style={{
                  backgroundColor: Theme.theme.shades.white,
                  position: 'relative',
                  display: 'inline-block',
                  width: '30px',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <span>OR</span>
              </div>
            </div>
            <div
              className="login-input-container"
              style={{ padding: '10px', alignItems: 'center' }}
            >
              <Para
                fontSize={14}
                style={{
                  color: Theme.theme.shades.grey,
                  fontWeight: 400,
                }}
              >
                If your account is SSO enabled, then
              </Para>
              <Link
                className=""
                style={{
                  margin: '10px',
                  textDecoration: 'none',
                  display: 'block',
                  background: '#1D426B',
                  borderRadius: '4px',
                  padding: '14px',
                  fontSize: '16px',
                  border: 0,
                  width: '162px',
                  position: 'relative',
                }}
                to="/sso-login"
              >
                <Para fontSize={14} color="white" style={{ textAlign: 'center' }}>
                  Login via SSO
                </Para>
              </Link>
            </div>

            <div className="login-account-exists" style={{ textAlign: 'center', marginTop: '0px' }}>
              <Label>Don't have an account ?</Label>
              <Link
                className="login-signin-link new-color"
                to="/register"
                style={{ fontSize: '14px', fontWeight: 400 }}
              >
                Sign Up
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function isPasswordValid(password: string) {
  return password.length >= 10 && password.length <= 72;
}

export default connect(({ auth }: IAppState) => ({ auth }), {
  requestLogin,
  onLoginSuccess,
})(LogIn);
