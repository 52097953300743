import { Accordion } from 'library/molecules';
import ActionGroup from './ActionGroup';

const Actions = () => {
  return (
    <Accordion
      allowToggle
      items={[
        {
          title: 'Define Actions',
          content: <ActionGroup />,
        },
      ]}
    />
  );
};

export default Actions;
