import { truncate } from 'core/helpers/stringUtils';
import { Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Tooltip, usernameTooltipLabel } from 'library/atoms';

type Props = {
  name: string;
  maxLength?: number;
  isHyperlink?: boolean;
  username?: string;
};

export const NameComponent: FC<Props> = ({ name, maxLength, isHyperlink = false, username }) => {
  const label = (
    <Text variant="body" color={isHyperlink ? 'brand.blue' : 'secondary.1000'}>
      {truncate(name, maxLength)}
    </Text>
  );
  return username ? (
    <Tooltip label={usernameTooltipLabel(name, username)} placement="bottom">
      {label}
    </Tooltip>
  ) : maxLength && name.length > maxLength ? (
    <Tooltip label={usernameTooltipLabel(name, username)} placement="bottom">
      {label}
    </Tooltip>
  ) : (
    label
  );
};
