import { Tooltip } from 'library/atoms';
import { FC } from 'react';
import { Image } from '@chakra-ui/react';
import { ALERT_INFO } from '../types';

type Props = {
  alert: ALERT_INFO;
};

export const AlertIcon: FC<Props> = ({ alert }) => {
  return (
    <Tooltip key={alert.shortName} label={alert.name} placement="bottom">
      <Image src={alert.iconURL} width="28px" />
    </Tooltip>
  );
};
