import { IAuthLoginDb } from '../../core/interfaces/IAuth';

/**
 * in memory db to store emails between navigation
 */
class CommonAuth {
  private _db: IAuthLoginDb = {
    id: '',
    email: '',
    redirectFrom: '',
  };

  set db(val: IAuthLoginDb) {
    this._db = val;
  }

  get db() {
    return this._db;
  }

  public clearDb = () => {
    this._db = {
      id: '',
      email: '',
      redirectFrom: '',
    };
  };
}

const commonAuth = new CommonAuth();
export { commonAuth as CommonAuth };
