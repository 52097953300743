import React, { useEffect, useState } from 'react';
import { DialogModalFrame, Grid, Para, TextButton, Theme } from 'uie/components';
import { Location } from 'history';
import { Prompt, useHistory } from 'react-router-dom';

const { theme } = Theme;

/**
 * UnsavedChangesGuard
 *
 * Route Based Guard - Used where the change in route is the trigger
 * checkDirty: Gets executed when there is a change in route
 *
 * Manual Guard - Used where the trigger is not the route
 * isManual: true if manual mode is being used
 * showModal: state of the unsaved change modal
 * onModalClose: Gets called when the `Keep Editing` button is clicked
 * onDiscardChanges: Gets called when the `Discard Changes` button is clicked
 *
 */
interface IProps {
  // Route based guard
  checkDirty?: () => boolean;

  // Manual guard
  isManual?: boolean;
  showModal?: boolean;
  onModalClose?: () => void;
  onDiscardChanges?: () => void;
}

const UnsavedChangesGuard = ({
  checkDirty,
  isManual,
  showModal,
  onModalClose,
  onDiscardChanges,
}: IProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();

  const closeModal = () => setModalVisible(false);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && checkDirty && checkDirty()) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation);
    }
  }, [confirmedNavigation, lastLocation, history]);

  return (
    <>
      {!isManual && <Prompt message={handleBlockedNavigation} />}

      <DialogModalFrame
        root="unsaved-changes-confirm"
        id="modalFrame"
        onClose={isManual && onModalClose ? onModalClose : closeModal}
        padding="32px"
        width="520px"
      >
        {(modalVisible || showModal) && (
          <>
            <Para fontSize={26} fontWeight={500} className="mb-10 mt-10">
              Unsaved Changes
            </Para>

            <Para fontSize={16} fontWeight={400} color={theme.shades.cement}>
              You haven't saved your changes. Are you sure you want to discard the changes?
            </Para>

            <Grid style={{ marginTop: 28 }}>
              <TextButton
                onClick={isManual && onModalClose ? onModalClose : closeModal}
                style={{ padding: '4px 8px' }}
                className="mr-20"
              >
                <Para color={theme.font.white} fontWeight={500} fontSize={16}>
                  Keep Editing
                </Para>
              </TextButton>

              <TextButton
                buttonType="ghost"
                style={{ padding: '4px 8px' }}
                onClick={
                  isManual && onDiscardChanges ? onDiscardChanges : handleConfirmNavigationClick
                }
              >
                <Para color={theme.font.default} fontWeight={500} fontSize={16}>
                  Discard Changes
                </Para>
              </TextButton>
            </Grid>
          </>
        )}
      </DialogModalFrame>
    </>
  );
};
export default UnsavedChangesGuard;
