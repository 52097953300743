import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  HStack,
  Circle,
  VStack,
  CloseButton,
  Center,
  Spinner,
  Text,
  Box,
} from '@chakra-ui/react';

import { useAllTagsList, useTagsEdit, useTagsList } from '../../hooks/useTags';

import IllustrationBox from '../../common/IllustrationBox';
import TagsIllustration from '../../icons/Illustrations/UpdateTags.png';

import TagGroup from '../../components/CustomDropdown/tagGroup';

import { usePersistQuery } from '../../hooks/usePersistQuery';
import {
  checkDuplicateServiceTags,
  validateServiceTags,
} from '../../service.add/addAlertSourceForm/addServiceForm';

interface tag {
  key: string;
  value: string;
}

export default function Tags({ serviceId }: { serviceId: string }) {
  const [localTagsState, setTags] = useState<Array<tag> | null>(null);
  const [tagErrors, setTagErrors] = useState<string[]>([]);
  const [duplicateTags, setDuplicateTags] = useState<Record<string, number>>({});

  const { data: tagsList, isLoading, isSuccess } = useTagsList(serviceId);
  const { data: allTags } = useAllTagsList();
  const { addOrEditTags } = useTagsEdit();

  const persistQuery = usePersistQuery();

  useEffect(() => {
    isSuccess &&
      setTags([...(tagsList ? tagsList : []), ...(localTagsState ? localTagsState : [])]);
  }, [tagsList]);

  const handleTagChange = (tagIndex: number) => (key: string, value: string) => {
    const clonedTagsState = localTagsState ? [...localTagsState] : [];
    clonedTagsState[tagIndex].key = key;
    clonedTagsState[tagIndex].value = value;
    setTags(clonedTagsState);
    const duplicateTags = checkDuplicateServiceTags(clonedTagsState);
    delete duplicateTags[''];
    setDuplicateTags(duplicateTags);
    validateTags();
  };

  const withoutEmptyTags = (tags: tag[]) => {
    return tags?.filter(tag => tag.key && tag.value);
  };

  const validateTags = () => {
    if (!localTagsState) return false;

    const tagErrorMessages = validateServiceTags(localTagsState, { ignoreEmptyTags: true });
    const tagsHasError = tagErrorMessages.filter(tag => tag !== '').length > 0;

    setTagErrors([...tagErrorMessages]);

    return !tagsHasError;
  };

  return (
    <Flex
      height={'100%'}
      direction={'column'}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
    >
      {isLoading ? (
        <Center h="100px">
          <Spinner
            ml={10}
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="md"
          />
        </Center>
      ) : (
        <VStack w="full" spacing="5">
          {!localTagsState?.length && (
            <Box>
              <IllustrationBox
                width="200px"
                height="150px"
                image={TagsIllustration}
                name="tags"
                msg="No Tags Added"
              />
              <Text as="i" fontSize="xs">
                Tip: We suggest using tags like Type, Environment and Functionality to add more
                context to your services
              </Text>
            </Box>
          )}

          {localTagsState &&
            localTagsState.map(({ key, value }, index) => {
              return (
                <HStack w="full" key={index}>
                  <TagGroup
                    value={{ key, value }}
                    keysOptions={allTags ? Object.keys(allTags) : []}
                    valuesMap={allTags ? allTags : {}}
                    handleChange={handleTagChange(index)}
                    errorMsg={tagErrors[index] || ''}
                    duplicateTagKeys={duplicateTags}
                  />
                  <Circle
                    size="12px"
                    bg={value ? 'rgba(0, 163, 196, 1)' : 'rgba(196, 196, 196, 1)'}
                    color="white"
                  />
                  <CloseButton
                    size={'sm'}
                    onClick={() => {
                      setTags(localTagsState.filter((_, index_) => index_ != index));
                      validateTags();
                    }}
                  />
                </HStack>
              );
            })}

          <Button
            onClick={() => {
              setTags([...(localTagsState ? localTagsState : []), { key: '', value: '' }]);
            }}
            leftIcon={<>+</>}
            variant="outline"
          >
            Add Tag
          </Button>
        </VStack>
      )}

      <Flex justifyContent="space-around" alignItems="flex-end">
        <Button
          variant="default"
          mr={5}
          isDisabled={!localTagsState}
          onClick={() =>
            localTagsState &&
            validateTags() &&
            addOrEditTags({
              serviceId,
              tags: withoutEmptyTags(localTagsState),
            })
          }
        >
          {!localTagsState?.length ? 'Add Tag(s)' : 'Update Tag(s)'}
        </Button>
        <Button
          variant="outline"
          onClick={() => {
            persistQuery();
          }}
        >
          Cancel
        </Button>
      </Flex>
    </Flex>
  );
}
