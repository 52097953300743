import { useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';

type HistoryMethod = 'push' | 'replace';
type UseSearchParamReturn = [value: string | null, set: (value: string) => void, unset: () => void];

type HackyLocationType = Location<any> | { action: string; location: Location<any> };
export function useSearchParam(
  key: string,
  historyMethod: HistoryMethod = 'push',
): UseSearchParamReturn {
  const history = useHistory();
  const location = useLocation() as unknown as HackyLocationType;

  const searchParams = new URLSearchParams(
    'search' in location ? location.search : location.location.search,
  );

  const value = searchParams.get(key);

  const unset = () => {
    searchParams.delete(key);
    persist(searchParams, historyMethod);
  };

  const set = (value: string) => {
    searchParams.set(key, value);
    persist(searchParams, historyMethod);
  };

  const persist = (searchParams: URLSearchParams, method: HistoryMethod) => {
    let search = searchParams.toString();
    if (search) {
      search = `?${search}`;
    }
    method === 'push' ? history.push({ search }) : history.replace({ search });
  };

  return [value, set, unset];
}
