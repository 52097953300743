import './index.css';

import { createStandaloneToast } from '@chakra-ui/react';
import { requestIncidentDeatilTask, requestIncidentDetailRunbook } from 'core/actions';
import { IWRCMessage } from 'core/interfaces/IWarroom';
import qs from 'query-string';
import { Component } from 'react';
import { connect } from 'react-redux';
import { AUTO_GROUP_TAB_NAME } from './constants';

import { API } from '../../../../core/api';
import {
  T_WA_GS_INCIDENT_ACKNOWLEDGED,
  T_WA_GS_INCIDENT_REASSIGNED,
  T_WA_GS_INCIDENT_RESOLVED,
  T_WA_GS_INCIDENT_TAG_DELETED,
  T_WA_IDP_INCIDENT_MARKED_AS_NOT_TRANSIENT,
  T_WA_IDP_INCIDENT_MARKED_AS_TRANSIENT,
  T_WA_UP_INCIDENT_DETAILS_PAGE_VIEWED,
  T_WA_UP_INCIDENT_PRIORITY_UPDATED,
  T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENTS_UNMERGED,
} from '../../../../core/const/tracker';
import { exception } from '../../../../core/exception';
import { IAppState } from '../../../../core/interfaces/IAppState';
import {
  IComponentErrorState,
  IComponentNetworkState,
  IComponentState,
} from '../../../../core/interfaces/IComponentState';
import {
  IHeatmapData,
  IIAssigne,
  IIEvent,
  IIncident,
  IPastIncident,
  IPastIncidentListParameters,
  IPastIncidentStatsResponse,
  SloViolatingIncident,
} from '../../../../core/interfaces/IIncidents';
import { IIntegration } from '../../../../core/interfaces/IIntegration';
import { IOrganization, IOrganizationEvents } from '../../../../core/interfaces/IOrganization';
import { IPublicMessage } from '../../../../core/interfaces/IPublicMessage';
import { IService } from '../../../../core/interfaces/IService';
import { IStatusPage } from '../../../../core/interfaces/IStatusPage';
import {
  BillingService,
  FileUploadService,
  IncidentService,
  IncidentWarroomService,
  PublicMessageService,
  StatusPageService,
} from '../../../../core/services';
import { AppTracker } from '../../../../shared/analytics/tracker';
import {
  pastIncidentsDefaultSortState,
  pastIncidentsListParametersInitialState,
} from './constants';
import { PRIORITY } from 'library/common';
import { render } from './render.index';
import { RUNBOOK, TASK } from './renders/tasksAndRunbook/taskAndRunbook.constants';
import { updatePriority } from '../incident-list/common/commands';
import { truncate } from 'core/helpers/stringUtils';
import { IIncidentServiceNowMapping } from 'core/interfaces/IExtensions';
import { ServiceNowSvc } from 'core/services';
import { RouteComponentProps } from 'react-router-dom';
import { withCompareGlobalAndEntityTeamId } from 'core/hooks/useCompareGlobalAndEntityTeamId';

const toast = createStandaloneToast();

interface IProps
  extends Pick<
      IAppState,
      'organization' | 'integrations' | 'roles' | 'incidentRunbooks' | 'incidentTask'
    >,
    RouteComponentProps {
  selectedOrg: IOrganization;
  requestIncidentDetailRunbook: typeof requestIncidentDetailRunbook;
  requestIncidentDeatilTask: typeof requestIncidentDeatilTask;
  handleDifferentGlobalAndEntityTeamId: (entityTeamId: string) => boolean;
}

interface IState {
  componentState: IComponentState;
  componentNetworkState: IComponentNetworkState;
  componentErrorState: IComponentErrorState;
  incidentId: string;
  incident: IIncident | null;
  children: IIncident[];
  pastIncidents: IPastIncident[];
  dedupedEvents: IIEvent[];
  pastIncidentsHeatmap: IHeatmapData;
  pastIncidentsStats: IPastIncidentStatsResponse;
  sloViolatingIncident: SloViolatingIncident | null;
  focusMessageId: { id: string; index?: number; type?: string };
  statusPage: {
    [key: string]: IStatusPage;
  };
  publicMessages: {
    action: 'public_message';
    additionalInfo: IPublicMessage;
    time: Date;
  }[];
  selectedTab: string;
  selectedDetailsTab: string;
  sloData: any;
  sloDetails: any;
  opendSloModal: boolean;
  isOpenButton: boolean;
  openAffectsSloModal: any;
  showSnack: boolean;
  clickedClose: any;
  refreshPage: any;
  showUpgradeModal: boolean;
  runbookFeatDisabled: boolean;
  sloFeatdisabled: boolean;
  notes: IWRCMessage[];
  loadNotesComponent: boolean;
  loadChildIncidents: boolean;
  openUnmergeModal: boolean;
  incidentToBeUnmerged: string;
  isUnmerging: boolean;
  latestAssigneeDeleted: boolean;
  isUnmergeIncidentsUnavailable: boolean;
  isPastIncidentsUnavailable: boolean;
  isPastIncidentsDataLoaded: boolean;
  loadPastIncidentsStats: boolean;
  loadPastIncidentsHeatmap: boolean;
  areDedupEventsLoading: boolean;
  arePastIncidentsLoading: boolean;
  pastIncidentsListParameters: IPastIncidentListParameters;
  userTimezone: string;
  incidentServiceNowMapping: IIncidentServiceNowMapping | null;
}

export class IncidentDetails extends Component<IProps, IState> {
  private _incidentService = new IncidentService();
  public fileUploadService = new FileUploadService();
  private _soketi: any = {};
  public render = render;

  public _oService: {
    [key: string]: IService;
  } = {};
  public _oIntegration: {
    [key: string]: IIntegration;
  } = {};

  constructor(props: IProps) {
    super(props);

    const incidentId = (this.props as any).match.params?.incidentId;
    this.state = {
      incidentId,
      componentErrorState: {},
      componentNetworkState: 'idle',
      componentState: 'idle',
      incident: null,
      children: [],
      pastIncidents: [],
      dedupedEvents: [],
      pastIncidentsStats: {} as IPastIncidentStatsResponse,
      pastIncidentsHeatmap: [],
      sloViolatingIncident: null,
      focusMessageId: { id: '' },
      statusPage: {},
      publicMessages: [],
      selectedTab: 'Details',
      selectedDetailsTab: this.getSelectedTabName(),
      sloData: '',
      sloDetails: '',
      opendSloModal: false,
      openAffectsSloModal: {},
      isOpenButton: true,
      showSnack: false,
      clickedClose: '',
      refreshPage: '',
      showUpgradeModal: false,
      runbookFeatDisabled: false,
      sloFeatdisabled: false,
      notes: [],
      loadNotesComponent: false,
      loadChildIncidents: false,
      openUnmergeModal: false,
      incidentToBeUnmerged: '',
      isUnmerging: false,
      latestAssigneeDeleted: false,
      isUnmergeIncidentsUnavailable: false,
      isPastIncidentsUnavailable: false,
      isPastIncidentsDataLoaded: false,
      loadPastIncidentsStats: false,
      loadPastIncidentsHeatmap: false,
      areDedupEventsLoading: false,
      arePastIncidentsLoading: false,
      userTimezone: this.props.organization.currentUser.u?.time_zone ?? '',
      incidentServiceNowMapping: null,
      pastIncidentsListParameters: {
        ...pastIncidentsListParametersInitialState,
        onPageLimitChange: limit =>
          this.setState({
            pastIncidentsListParameters: {
              ...this.state.pastIncidentsListParameters,
              pageLimit: limit,
            },
          }),
        onPageChange: type =>
          this.setState({
            pastIncidentsListParameters: {
              ...this.state.pastIncidentsListParameters,
              pageOffset:
                type === 'next'
                  ? this.state.pastIncidentsListParameters.pageOffset + 1
                  : this.state.pastIncidentsListParameters.pageOffset - 1,
            },
          }),
        onSortChange: (id, desc) =>
          this.setState({
            pastIncidentsListParameters: {
              ...this.state.pastIncidentsListParameters,
              sortingState: { id, desc },
            },
          }),
      },
    };
    this.computeInternals();
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ componentState: 'busy' });
    await this.setState({
      runbookFeatDisabled: BillingService.isFeatureDisabled(this.props, 'runbooks'),
    });
    await this.setState({
      sloFeatdisabled: BillingService.isFeatureDisabled(this.props, 'slo'),
    });
    this.setState({
      showUpgradeModal: this.state.runbookFeatDisabled && this.state.selectedTab == 'Task',
    });
    this.setState({
      isUnmergeIncidentsUnavailable: this.props.organization
        ? BillingService.isFeatureDisabled(
            { organization: this.props.organization },
            'merge-incidents',
          )
        : false,
    });
    this.setState({
      isPastIncidentsUnavailable: this.props.organization
        ? BillingService.isFeatureDisabled(
            { organization: this.props.organization },
            'past-incidents',
          )
        : false,
    });
    this.computeInternals();
    this.getIncidentDetails();
    this.getStatusPages();
    this.bindPusherEvents();
    this.getNotes();
    this.getChildIncidents();
    this.getIncidentMapping();
    AppTracker.track(T_WA_UP_INCIDENT_DETAILS_PAGE_VIEWED, { Platform: 'Web' });
  }

  getSelectedTabName() {
    const tabName = qs.parse((this.props as any).location.search).tab;
    if (tabName === AUTO_GROUP_TAB_NAME) {
      return 'Auto Grouped';
    }

    return 'Description';
  }

  componentDidUpdate(prevProp: IProps, prevState: IState) {
    this.props.handleDifferentGlobalAndEntityTeamId(this.state.incident?.owner.id ?? '');
    this.computeInternals();

    const incidentId = (this.props as any).match.params.incidentId;
    const prevPastIncListParams = prevState.pastIncidentsListParameters;
    const currPastIncListParams = this.state.pastIncidentsListParameters;

    if (incidentId !== this.state.incidentId) {
      this.unbindPusherEvents();
      const tabName = this.getSelectedTabName();
      this.setState(
        {
          incidentId,
          selectedTab: 'Details',
          selectedDetailsTab: this.getSelectedTabName(),
          pastIncidentsListParameters: {
            ...this.state.pastIncidentsListParameters,
            ...pastIncidentsListParametersInitialState,
          },
          componentState: 'busy',
          latestAssigneeDeleted: false,
          isPastIncidentsDataLoaded: false,
        },
        () => {
          this.getIncidentDetails();
          this.getChildIncidents();
          this.getNotes();
          this.bindPusherEvents();
          this.getIncidentMapping();
        },
      );
    }
    if (this.state.selectedDetailsTab === 'Child' && this.state.children.length === 0) {
      this.setState({
        selectedDetailsTab: 'Description',
      });
    }

    //This condition handles the first loading of deduped events
    if (
      this.state.incident &&
      this.state.selectedDetailsTab === 'Deduped Events' &&
      !this.state.areDedupEventsLoading &&
      this.state.incident.event_count > 1 &&
      this.state.dedupedEvents.length === 0
    ) {
      this.getDedupedEvents();
    }

    //This condition handles the first load of past incidents
    if (
      this.state.selectedDetailsTab === 'Past Incidents' &&
      !this.state.isPastIncidentsDataLoaded &&
      !this.state.arePastIncidentsLoading
    ) {
      this.getPastIncidents();
      this.getPastIncidentStats();
      this.getPastIncidentHeatmap();
    }

    if (
      prevPastIncListParams.activeDate !== currPastIncListParams.activeDate ||
      prevPastIncListParams.pageLimit !== currPastIncListParams.pageLimit ||
      prevPastIncListParams.pageOffset !== currPastIncListParams.pageOffset ||
      prevPastIncListParams.sortingState !== currPastIncListParams.sortingState
    ) {
      this.getPastIncidents();
    }
  }

  componentWillUnmount() {
    this.unbindPusherEvents();
  }

  setNotes = (newResponse: IWRCMessage[]) => {
    const updatedResponse = newResponse.filter(note => note.incident_id === this.state.incidentId);
    this.setState({ notes: updatedResponse });
  };
  getNotes = async () => {
    try {
      const _warroomService = new IncidentWarroomService(this.state.incidentId);
      const {
        data: { data: responses },
      } = await _warroomService.getAllMessages();
      this.setState({ notes: responses, loadNotesComponent: true });
    } catch (err: any) {
      exception.handle('E_GET_INCIDENT_WR_MESSAGES', err);
      this.setState({
        componentState: 'error',
        loadNotesComponent: true,
      });
    }
  };

  getChildIncidents = async () => {
    try {
      const teamID = this.props?.organization?.selectedTeam?.teamId;
      const {
        data: {
          data: { children },
        },
      } = await this._incidentService.getChildIncidents(
        teamID,
        (this.props as any).match.params.incidentId,
      );
      this.setState({ children, loadChildIncidents: true });
    } catch (err: any) {
      exception.handle('E_GET_INCIDENT_WR_MESSAGES', err);
      this.setState({
        componentState: 'error',
        loadChildIncidents: true,
      });
    }
  };

  getPastIncidents = async () => {
    try {
      this.setState({ arePastIncidentsLoading: true });

      const { activeDate, pageLimit, pageOffset, sortingState, cursorMeta } =
        this.state.pastIncidentsListParameters;
      // Adjust offset for the API call, in case the page limit was change before the API call
      let maxPages = Math.ceil(cursorMeta.total_count / pageLimit);
      let updatedOffset = maxPages > 0 && pageOffset + 1 > maxPages ? maxPages - 1 : pageOffset;
      const sortbyKey = `sort_by_${
        sortingState.id === 'created_at' ? 'created_at' : 'activity_score'
      }`;
      const queryParamObj = {
        tz: this.state.userTimezone,
        ...(activeDate
          ? {
              date: activeDate,
              page_limit: `${pageLimit}`,
              page_offset: `${updatedOffset}`,
              [sortbyKey]: sortingState.desc ? 'desc' : 'asc',
            }
          : {}),
      };
      const {
        data: { data, meta },
      } = await this._incidentService.getPastIncidents(
        (this.props as any).match.params.incidentId,
        queryParamObj,
      );
      // Adjust offset per the result of the API call
      maxPages = Math.ceil(meta.total_count / pageLimit);
      updatedOffset = maxPages > 0 && pageOffset + 1 > maxPages ? maxPages - 1 : pageOffset;
      this.setState({
        pastIncidents: data,
        pastIncidentsListParameters: {
          ...this.state.pastIncidentsListParameters,
          cursorMeta: meta,
          maxPages,
          pageOffset: updatedOffset,
        },
      });
    } catch (err: any) {
      exception.handle('E_GET_INCIDENT_WR_MESSAGES', err);
      this.setState({
        componentState: 'error',
      });
    } finally {
      this.setState({
        arePastIncidentsLoading: false,
        isPastIncidentsDataLoaded: true,
      });
    }
  };

  getPastIncidentStats = async () => {
    try {
      const {
        data: { data },
      } = await this._incidentService.getPastIncidentStatistics(
        (this.props as any).match.params.incidentId,
      );
      this.setState({ pastIncidentsStats: data, loadPastIncidentsStats: true });
    } catch (err: any) {
      exception.handle('E_GET_INCIDENT_WR_MESSAGES', err);
      this.setState({
        componentState: 'error',
        loadPastIncidentsStats: true,
      });
    }
  };

  getPastIncidentHeatmap = async () => {
    try {
      const {
        data: { data },
      } = await this._incidentService.getPastIncidentsHeatmap(
        (this.props as any).match.params.incidentId,
        this.state.userTimezone,
      );

      const updatedData = data
        .filter(obj => obj.count > 0)
        .map(obj => ({ date: obj.date, value: obj.count }));
      this.setState({ pastIncidentsHeatmap: updatedData, loadPastIncidentsHeatmap: true });
    } catch (err: any) {
      exception.handle('E_GET_INCIDENT_WR_MESSAGES', err);
      this.setState({
        componentState: 'error',
        loadPastIncidentsHeatmap: true,
      });
    }
  };

  getDedupedEvents = async () => {
    const { incident, dedupedEvents } = this.state;
    if (incident) {
      if (
        !incident.event_count ||
        incident.event_count === 1 ||
        dedupedEvents.length === incident.event_count - 1
      ) {
        return;
      }

      this.setState({ areDedupEventsLoading: true });
      try {
        const {
          data: {
            data: { events: eventResults },
          },
        } = await this._incidentService.getIncidentEvents(incident.id, dedupedEvents.length);
        this.setState({ dedupedEvents: [...dedupedEvents, ...eventResults] });
      } catch (err: any) {
        exception.handle('E_GET_INCIDENT_DETAILS', err);
      } finally {
        this.setState({ areDedupEventsLoading: false });
      }
    }
  };

  getIncidentMapping = async () => {
    const _servicenowSvc = new ServiceNowSvc();
    try {
      const { data, status } = await _servicenowSvc.getIncidentMapping(this.state.incidentId);
      if (status === 200) {
        this.setState({ incidentServiceNowMapping: data.data });
      }
    } catch (error) {
      exception.handle('E_GET_INCIDENT_SERVICENOW_MAPPING', error);
    }
  };

  computeInternals = () => {
    this._oService = this.props.organization.services.s.reduce((c: any, n) => {
      c[n.id] = n;
      return c;
    }, {});

    this._oIntegration = this.props.integrations.i.reduce((c: any, n) => {
      c[n._id] = n;
      return c;
    }, {});
  };

  deleteTag = (tagKey: string) => async () => {
    if (!this.state.incident || !this.state.incident.tags) return;

    AppTracker.track(T_WA_GS_INCIDENT_TAG_DELETED);

    const tags = { ...this.state.incident.tags };

    delete tags[tagKey];

    try {
      await this._incidentService.updateTags(this.state.incidentId, tags);
      this.getIncidentDetails();
    } catch (err: any) {
      exception.handle('E_INCIDENT_DELETE_TAGS', err);
    }
  };

  sloDetails = async (incident: IIncident) => {
    try {
      const teamID = this.props?.organization?.selectedTeam?.teamId;

      if (incident && incident.id && incident.slo_id) {
        const {
          data: { data: sloViolatingIncident },
        } = await this._incidentService.getIncidentWithSlos(incident, teamID);

        if (sloViolatingIncident) {
          this.setState({ sloData: sloViolatingIncident });
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  getIncidentDetails = async () => {
    this.setState({ componentNetworkState: 'request' });
    try {
      const {
        data: { data: incident, result },
      } = await this._incidentService.getIncidentById(this.state.incidentId);
      if (!incident) {
        throw new Error('E_GET_INCIDENT_DETAILS');
      }
      this.setState({ incident, componentNetworkState: 'idle' });
      this.getPublicMessages(incident.id);
      this.props.selectedOrg.config?.['slo-tracker.enabled'] && this.sloDetails(incident);
      this.props.requestIncidentDetailRunbook(incident.id);
      this.props.requestIncidentDeatilTask(incident.id);
    } catch (err: any) {
      exception.handle('E_GET_INCIDENT_DETAILS', err);

      if (err?.response?.status === 402) {
        this.setState({
          componentErrorState: { outside_retention_period: 'Incident outside retention period' },
        });
      } else {
        this.setState({ componentErrorState: { get_incident: 'Failed to load incident' } });
      }
    } finally {
      this.setState({ componentState: 'idle', componentNetworkState: 'idle' });
    }
  };

  bindPusherEvents = () => {
    this._soketi = API.socket.subscribe(this.state.incidentId);

    this._soketi.bind('reload-event', (data: { message: IOrganizationEvents }) => {
      if (data.message === 'reload-incident') {
        this.getIncidentDetails();
      }
    });
  };

  unbindPusherEvents = () => {
    if (this._soketi && typeof this._soketi === 'function') {
      this._soketi.unbind('reload-event');
    }
  };

  acknowledgeIncident = async () => {
    const { incident } = this.state;

    if (!incident) return;

    this.setState({ componentNetworkState: 'request' });
    try {
      await this._incidentService.acknowledgeIncident(incident.id);
      await this.getIncidentDetails();

      AppTracker.track(T_WA_GS_INCIDENT_ACKNOWLEDGED, {
        'Incident ID': [incident.id],
      });
    } catch (err: any) {
      exception.handle('E_ACKNOWLEDGE_INCIDENT', err);
    } finally {
      this.setState({ componentNetworkState: 'idle' });
    }
  };

  onPriorityUpdate = async (priority: PRIORITY) => {
    const { incident } = this.state;

    if (!incident) return;

    this.setState({ componentNetworkState: 'request' });
    try {
      await updatePriority(incident.id, priority);

      toast({
        status: 'success',
        title: `Success: '${truncate(incident.message)}' priority updated`,
      });

      AppTracker.track(T_WA_UP_INCIDENT_PRIORITY_UPDATED, {
        'Incident Priority Chosen': priority,
        'Incident ID': [incident.id],
      });

      this.setState({
        incident: {
          ...incident,
          priority: priority,
        },
      });
    } catch (err: any) {
      exception.handle('E_UPDATE_PRIORITY_INCIDENT', err);
    } finally {
      this.setState({ componentNetworkState: 'idle' });
    }
  };

  reassignIncident = async (id: string, type: IIAssigne) => {
    const { incident } = this.state;
    if (!incident) return;

    this.setState({ componentNetworkState: 'request' });
    try {
      await this._incidentService.reAssignIncidentV3(
        {
          reassignTo: {
            id,
            type,
          },
        },
        incident.id,
      );
      this.setState({
        incident: {
          ...incident,
          status: 'triggered',
          assignedTo: [{ id, type, timeOfAssignment: new Date() }, ...incident.assignedTo],
        },
      });
      toast({
        status: 'success',
        title: 'Incident reassigned successfully',
      });

      AppTracker.track(T_WA_GS_INCIDENT_REASSIGNED, {
        'Incident ID': [incident.id],
      });
    } catch (err: any) {
      const errMsg = err?.response?.data?.meta.error_message ?? 'Unable to reassign incident';
      toast({
        status: 'error',
        title: `Error: ${errMsg}`,
      });

      exception.handle('E_RESOLVE_INCIDENT', err);
    } finally {
      this.setState({ componentNetworkState: 'idle' });
    }
  };

  resolveIncident = async () => {
    const { incident } = this.state;

    if (!incident) return;

    this.setState({ componentNetworkState: 'request' });
    try {
      await this._incidentService.resolveIncident(incident.id);
      await this.getIncidentDetails();

      AppTracker.track(T_WA_GS_INCIDENT_RESOLVED, {
        'Incident ID': [incident.id],
      });
    } catch (err: any) {
      exception.handle('E_RESOLVE_INCIDENT', err);
    } finally {
      this.setState({ componentNetworkState: 'idle' });
    }
  };

  markAsTransient = async () => {
    AppTracker.track(T_WA_IDP_INCIDENT_MARKED_AS_TRANSIENT, {
      Platform: 'Web',
      Email: this.props.organization.currentUser.u?.email,
    });

    const { incident } = this.state;

    if (!incident) return;

    this.setState({ componentNetworkState: 'request' });
    try {
      await this._incidentService.markAsTransient(incident.id);
      await this.getIncidentDetails();
    } catch (err: any) {
      exception.handle('E_MARK_INCIDENT_AS_TRANSIENT', err);
    } finally {
      this.setState({ componentNetworkState: 'idle' });
    }
  };

  markAsNotTransient = async () => {
    AppTracker.track(T_WA_IDP_INCIDENT_MARKED_AS_NOT_TRANSIENT, {
      Platform: 'Web',
      Email: this.props.organization.currentUser.u?.email,
    });

    const { incident } = this.state;

    if (!incident) return;

    this.setState({ componentNetworkState: 'request' });
    try {
      await this._incidentService.markAsNotTransient(incident.id);
      await this.getIncidentDetails();
    } catch (err: any) {
      exception.handle('E_MARK_INCIDENT_AS_NOT_TRANSIENT', err);
    } finally {
      this.setState({ componentNetworkState: 'idle' });
    }
  };

  setFocusMessageId = (id: string, type?: string, index?: number) => {
    const selectedTab = type === TASK || type === RUNBOOK ? 'Task' : 'Notes';
    this.props;
    this.setState(
      {
        selectedTab,
      },
      () => {
        setTimeout(() => {
          this.setState({ focusMessageId: { id, type, index } });
        }, 100);
      },
    );
  };

  getStatusPages = async () => {
    try {
      const _statusPageService = new StatusPageService();
      const {
        data: { data: statusPages },
      } = await _statusPageService.getAll();
      const statusPage = statusPages.reduce((c: { [key: string]: IStatusPage }, n: IStatusPage) => {
        if (n.id) {
          c[n.id] = n;
        }
        return c;
      }, {});
      this.setState({ statusPage });
    } catch (err: any) {
      exception.handle('E_GET_ALL_STATUS_PAGES', err);
    }
  };

  getPublicMessages = async (incidentId: string) => {
    try {
      const _publicMessageService = new PublicMessageService({ incidentId });
      const response = await _publicMessageService.getAllMessages();
      if (response.result) {
        const publicMessages = response.data.map((pm: IPublicMessage) => ({
          action: 'public_message',
          additionalInfo: pm,
          time: pm.time,
        }));
        this.setState({ publicMessages });
      }
    } catch (err: any) {
      exception.handle('E_GET_PUBLIC_MESSAGES', err);
    }
  };
  openAffectsSloModal = () => {
    if (this.state.sloFeatdisabled) {
      return;
    }
    this.setState({ opendSloModal: true });
  };
  closeModel = async () => {
    this.setState({ opendSloModal: false });
  };

  onClickUnmerge = (incidentToBeUnmerged: string) =>
    this.setState({ incidentToBeUnmerged, openUnmergeModal: true });

  unMergeIncident = async (
    assignToMe: boolean,
    sendNotification: boolean,
    location: 'parent' | 'child',
  ) => {
    try {
      this.setState({ isUnmerging: true });
      const teamID = this.props?.organization?.selectedTeam?.teamId;

      const { data } = await this._incidentService.unMergeIncident(
        teamID,
        this.state.incidentToBeUnmerged,
        assignToMe,
        sendNotification,
      );

      AppTracker.track(T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENTS_UNMERGED, {
        incident_unmerge_location: `${
          location[0].toUpperCase() + location.slice(1)
        } Incident Details Page`,
      });

      toast({
        status: 'success',
        title: `1 child incident successfully unmerged and re-triggered`,
      });
      this.getIncidentDetails();
      this.getChildIncidents();
      this.setState({
        incidentToBeUnmerged: '',
        latestAssigneeDeleted: false,
        openUnmergeModal: false,
      });
    } catch (err: any) {
      const errorMessage = err?.response?.data?.meta?.error_message ?? 'Unknown error';
      if (errorMessage.startsWith('Latest assignee of this incident is deleted')) {
        this.setState({ latestAssigneeDeleted: true });
      }
      toast({
        status: 'error',
        title: `An error has occured during merge: ${errorMessage}`,
      });
    } finally {
      this.setState({ isUnmerging: false });
    }
  };

  setPastIncidentsFilter = (activeDate: string) => {
    this.setState({
      pastIncidentsListParameters: {
        ...this.state.pastIncidentsListParameters,
        activeDate,
        pageOffset: 0,
      },
    });
  };

  clearPastIncidentsFilter = () => {
    this.setState({
      pastIncidentsListParameters: {
        ...this.state.pastIncidentsListParameters,
        activeDate: '',
        pageOffset: 0,
        sortingState: pastIncidentsDefaultSortState,
      },
    });
  };
}

export default connect(
  ({
    organization,
    integrations,
    roles,
    incidentRunbooks,
    incidentTask,
    userOrganizations,
    INIT_ORG,
  }: IAppState) => ({
    organization,
    integrations,
    roles,
    incidentRunbooks,
    incidentTask,
    selectedOrg: userOrganizations.o.find(
      o => o.organizationId === INIT_ORG.orgId,
    ) as IOrganization,
  }),
  {
    requestIncidentDetailRunbook,
    requestIncidentDeatilTask,
  },
)(withCompareGlobalAndEntityTeamId(IncidentDetails));
