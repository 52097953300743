import { Box, Stack } from '@chakra-ui/layout';
import { FormLabel } from '@chakra-ui/form-control';
import StatusStepper from './Step/Stepper';
import { ITemplateMessage } from 'views/main/organization/statuspage-v2/Interface';
import { ISSUES_STATES } from 'views/main/organization/statuspage-v2/constants/status.constants';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { StatusPageAction } from 'views/main/organization/workflow/types';
import { useEffect } from 'react';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { useUserAccess } from 'core/userAccess/UserAccessContext';

const StatusForm = ({ values }: { values: any }) => {
  const messageCount = parseInt(values.stateMessageCount);
  const canEdit = useUserAccess().hasUpdateAccess('status_pages');

  const isResolved = values.currentIssueState === ISSUES_STATES.RESOLVED;
  const { actionForm } = useWorkflowFormContext();

  const statusMessages = (actionForm as StatusPageAction)?.data.status_and_message;

  const { setValue } = useFormContext<StatusPageAction>();
  const { append, fields, replace } = useFieldArray<StatusPageAction>({
    name: `data.status_and_message`,
  });

  useEffect(() => {
    if (values?.issueStates) {
      if (values.issueStates && statusMessages.length !== 4) {
        //BE only sends status which has messages, but we need to map all 4 statuses from BE so, filling the array acccording to their heirarchy
        let fieldsToAdd = values.issueStates.map(
          ({ id }: { id: number }) =>
            //values are status list according to status page
            //statusMessages are BE data
            statusMessages.find(item => item.status_id === id) || {
              messages: [],
              status_id: id,
            },
        );
        //when there is no BE data for status_and_message, then every one will have -1 id,
        //in that case  putting the default "values prop" id status array
        if (fieldsToAdd.every(({ status_id }: { status_id: number }) => status_id === -1)) {
          fieldsToAdd = values.issueStates.map(({ name, id }: { name: string; id: string }) => ({
            name: name,
            status_id: id,
            messages: [],
          }));
        }
        replace([]);

        append(fieldsToAdd);
      } else {
        values.issueStates.map(({ id, name }: { id: number; name: string }, index: number) => {
          setValue(`data.status_and_message.${index}.status_id`, id);
          setValue(`data.status_and_message.${index}.name`, name);
        });
      }
    }
  }, [values]);

  return (
    <Box bg="bg-surface" py={4}>
      <FormLabel>Issue Status and its Message</FormLabel>
      <Stack spacing="0" direction={{ base: 'column', md: 'column' }}>
        {fields?.map((step, id: number) => {
          const template: ITemplateMessage = values.template.filter(
            (temp: ITemplateMessage) => temp.stepIndex === id,
          )?.[0];
          return (
            <StatusStepper
              key={id}
              idx={id}
              stateMessageCount={messageCount}
              template={template}
              isResolved={isResolved}
              readOnly={false}
              showDisclaimer={false}
            />
          );
        })}
      </Stack>
    </Box>
  );
};

export default StatusForm;
