import { HStack, Text, FormControl, ChakraProvider, extendTheme, Divider } from '@chakra-ui/react';
import libraryTheme from 'library/theme';
import { Switch } from 'library/atoms';
import chakraTheme from 'views/main/organization/schedules/theme';

interface Props {
  applyChakraStyle: boolean;
  showMyOnCall: boolean;
  onChange: () => void;
}

const OnCallSwitch = ({ applyChakraStyle, showMyOnCall, onChange }: Props) => {
  const theme = extendTheme(chakraTheme, libraryTheme);

  return (
    <ChakraProvider resetCSS={applyChakraStyle} theme={theme}>
      {' '}
      <FormControl>
        <HStack justifyContent="center" verticalAlign="middle">
          <Text variant="body" color="brand.black">
            All
          </Text>
          <Switch isOn={showMyOnCall} handleChange={onChange} variant="slider" />
          <Text variant="body" color="brand.black" m={5}>
            You & Your Squads
          </Text>
        </HStack>
        <Divider w="100%" borderWidth={0.5} />
      </FormControl>
    </ChakraProvider>
  );
};

export default OnCallSwitch;
