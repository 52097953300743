import {
  HStack,
  Image,
  Link,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react';
import Editor, { OnMount } from '@monaco-editor/react';
import { Grid, Para, SpinLoader, Tooltip } from 'uie/components';
import { MONACO_EDITOR_OPTIONS } from 'views/shared/constants';
import { IComponentState } from 'core/interfaces/IComponentState';

const AccordionActionMode = ({
  incidentDesc,
  incidentMessage,
  componentState,
  updateMessageCode,
  updateDesc,
  handleEditorDidMount,
}: {
  incidentMessage: string;
  incidentDesc: string;
  componentState: IComponentState;
  updateMessageCode: () => void;
  updateDesc: () => void;
  handleEditorDidMount: OnMount;
}) => {
  return (
    <>
      {componentState === 'error' && (
        <Grid flexWidth={12} justifyContent="center">
          <div className="mt-20 ml-20">
            <Grid justifyContent="center" alignItems="center">
              <small className="ml-10">
                Looks like there is a network error! please try again.
              </small>
            </Grid>
          </div>
        </Grid>
      )}
      {componentState === 'busy' && (
        <Grid flexWidth={12} justifyContent="center">
          <div className="mt-20 ml-20">
            <Grid justifyContent="center" alignItems="center">
              <SpinLoader />
              <small className="ml-10">Loading incident message and description</small>
            </Grid>
          </div>
        </Grid>
      )}
      {componentState === 'idle' && (
        <div className="w-1-1">
          <HStack mb={2}>
            <Para fontWeight={400} fontSize={12}>
              Select an alert source to define templates for custom incident message and description
            </Para>
            <Tooltip
              padding="8px"
              offset={{ top: '0px' }}
              width="300px"
              label="You can define either only one custom incident message or description templates,not necesarrily both"
            >
              <Image src="/icons/info_black.svg" alt="more info" style={{ height: 16 }} />
            </Tooltip>
          </HStack>
          <HStack mb={2}>
            <Para fontWeight={800} fontSize={12}>
              Define Templates
            </Para>

            <Popover trigger="hover">
              <PopoverTrigger>
                <img src="/icons/info_black.svg" alt="more info" style={{ height: 16 }} />
              </PopoverTrigger>
              <PopoverContent
                css={{
                  '&:focus': {
                    boxShadow: 'none',
                    outline: 'none',
                  },
                }}
                backgroundColor={'#F1F3F6'}
                fontSize={'12.5px'}
                fontWeight={400}
                width="270px"
              >
                <PopoverHeader>
                  For additional information on how to write templates, refer{' '}
                  <Link
                    as="a"
                    href="https://developer.hashicorp.com/nomad/tutorials/templates/go-template-syntax"
                    target="_blank"
                    variant="underlinedLink"
                    style={{ color: '#09305A', textDecoration: 'underline' }}
                  >
                    Go’s standard library
                  </Link>
                </PopoverHeader>
              </PopoverContent>
            </Popover>
          </HStack>
          <VStack borderRadius={'4px '} border="1px solid #DADADA" p={4} display={'flex-start'}>
            <HStack mb={2}>
              <Para fontWeight={800} fontSize={12}>
                Incident Message
              </Para>
              <Tooltip
                padding="8px"
                offset={{ top: '0px' }}
                width="300px"
                label="Do note that notifications going out for these incidents will read this custom incident message and will be subject to notification guidelines as applicable"
              >
                <Image src="/icons/info_black.svg" alt="more info" style={{ height: 16 }} />
              </Tooltip>
            </HStack>

            <Editor
              onMount={handleEditorDidMount}
              height={'213px'}
              value={incidentMessage}
              onChange={updateMessageCode}
              options={MONACO_EDITOR_OPTIONS}
              width="100%"
            />

            <Para fontWeight={800} fontSize={12} style={{ marginBottom: '8px' }}>
              Incident Description
            </Para>
            <Editor
              onMount={handleEditorDidMount}
              height={'213px'}
              value={incidentDesc}
              onChange={updateDesc}
              options={MONACO_EDITOR_OPTIONS}
              width="100%"
            />
          </VStack>
        </div>
      )}
    </>
  );
};

export default AccordionActionMode;
