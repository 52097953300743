import { filterDropdownOptions } from '../../incident-list/interfaces/common';

export const uniqueArrayObjects = (sourceArray: filterDropdownOptions[], value: any) => {
  const el = sourceArray.find((val: filterDropdownOptions) => val.value === value.value);
  if (!el) return [...sourceArray, value];
  return sourceArray;
};

export function removeDuplicates(obj: any) {
  // Helper function to check if two objects are equal
  function objectsEqual(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  // Iterate over each key in the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const array = obj[key];
      const uniqueArray: any = [];

      // Iterate over each object in the array
      array.forEach((item: any) => {
        // Check if item is not already in the uniqueArray
        if (!uniqueArray.some((existingItem: any) => objectsEqual(existingItem, item))) {
          uniqueArray.push(item);
        }
      });

      // Assign the unique array back to the object key
      obj[key] = uniqueArray;
    }
  }

  return obj;
}

export const cleanObject = (obj: any): any => {
  const newObj: any = {};

  Object.keys(obj).forEach(key => {
    const value = obj[key];

    if (key === 'Me and My Squads' && value === false) {
      return;
    }

    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const cleanedValue = cleanObject(value);
      if (Object.keys(cleanedValue).length > 0) {
        newObj[key] = cleanedValue;
      }
    } else if (Array.isArray(value)) {
      if (value.length > 0) {
        newObj[key] = value;
      }
    } else if (value !== null && value !== undefined && value !== '') {
      newObj[key] = value;
    }
  });

  return newObj;
};

export const removeElementFromArray = (elementId: string, array: filterDropdownOptions[]) =>
  array.filter((el: filterDropdownOptions) => el.value !== elementId);
