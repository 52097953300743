import { deepCopy } from 'core';
import { ActionKind, GERAction, GERState, INITIAL_GER_STATE } from '.';

export function GERReducer(state: GERState, action: GERAction) {
  const { type, payload } = action;
  const newState = deepCopy(state);

  const resetPageNumber = () => {
    newState.pageNumber = INITIAL_GER_STATE.pageNumber;
  };

  switch (type) {
    case ActionKind.SET_SEARCH_TERM:
      const searchTerm = payload?.searchTerm ?? '';
      newState.search.term = searchTerm;
      resetPageNumber();
      break;
    case ActionKind.SET_FILTER:
      newState.filter.ownerIds = payload?.ownerIds ?? [];
      resetPageNumber();
      break;
    case ActionKind.SET_LIMIT:
      newState.limit = payload?.limit;
      resetPageNumber();
      break;
    case ActionKind.SET_PAGE_INDEX:
      newState.pageNumber = payload?.pageNumber;
      break;
    case ActionKind.SET_PERMISSIONS:
      newState.permissions = { ...payload?.permissions };
      break;
  }
  return newState;
}
