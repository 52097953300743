import { HStack } from '@chakra-ui/react';
import { T_WA_INCIDENT_SUMMARY_NEGATIVE_FEEDBACK_GIVEN } from 'core/const/tracker';
import { FormButton } from 'library/atoms';
import { AppTracker } from 'shared/analytics/tracker';

import { useIncidentSummaryContext } from '../../../context/hook';

const IncidentSummaryDrawerFeedbackFooter: React.FC = () => {
  const { incidentId, setSummaryDrawerStateSuccess, sendNegativeFeedback, feedbackSubmitting } =
    useIncidentSummaryContext();

  return (
    <HStack spacing={3}>
      <FormButton
        title="Submit"
        onClick={() => {
          sendNegativeFeedback();
          AppTracker.track(T_WA_INCIDENT_SUMMARY_NEGATIVE_FEEDBACK_GIVEN, {
            'Incident ID': incidentId,
          });
        }}
        disabled={feedbackSubmitting}
      />
      <FormButton
        title="Cancel"
        variant="secondary"
        onClick={setSummaryDrawerStateSuccess}
        disabled={feedbackSubmitting}
      />
    </HStack>
  );
};

export default IncidentSummaryDrawerFeedbackFooter;
