import { useEffect, useState } from 'react';
import { IServiceList } from '../../service-catalog/hooks/useServiceList';
import { IServiceOption } from '../Interface';

function useServiceListOptions(data: IServiceList | undefined) {
  const [serviceData, setServiceData] = useState<Array<IServiceOption>>([]);

  useEffect(() => {
    if (!data) return;

    const serviceData: IServiceOption[] = data.data.services.map(service => ({
      value: service.id,
      label: service.name,
    }));

    const noService = { value: '', label: 'No Service' };
    serviceData.unshift(noService);

    setServiceData(serviceData);
  }, [data]);

  return serviceData;
}

export default useServiceListOptions;
