import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Circle, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { WorkflowLog } from '../../../types/response/workflow';
import { STATUS_COLORS, STATUS_LABELS } from '../../../constant';
import { useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Link } from 'react-router-dom';
import { THEME_COLORS } from 'library/theme/colors';
import { CopyBox, useToast } from 'library/atoms';

dayjs.extend(utc);
dayjs.extend(timezone);

export function useWorkflowLogColumns() {
  const timezone = useSelector((state: IAppState) => state.userInfo.d?.time_zone);
  const toast = useToast();

  const onCopy = () => {
    toast({ text: 'Copied! Log ID', status: 'success' });
  };

  const columns = useMemo<ColumnDef<WorkflowLog, any>[]>(
    () => [
      {
        header: 'Execution Date & Time' + (timezone ? ` (${timezone})` : ' (UTC)'),
        cell: ({ row }) => {
          if (!row.original.executed_at) return <Text></Text>;
          const date = dayjs
            .tz(dayjs.utc(row.original.executed_at?.slice(0, -10)), timezone)
            .format('YYYY-MM-DD  hh:mm:ss A');
          return (
            <VStack spacing={0} alignItems="flex-start">
              <Text>{date}</Text>
            </VStack>
          );
        },
      },
      {
        header: 'Log ID',
        cell: ({ row }) => {
          return (
            <VStack spacing={0} alignItems="flex-start">
              <HStack spacing={1}>
                <Text>{row.original.id}</Text>
                <CopyBox copyText={row.original.id} onTextCopied={onCopy} />
              </HStack>
            </VStack>
          );
        },
      },
      {
        header: 'Status',
        cell: ({ row }) => {
          return (
            <HStack spacing={0} alignItems="center">
              <Tooltip label={STATUS_LABELS[row.original.status]}>
                <>
                  <Circle bg={STATUS_COLORS[row.original.status]} size="12px" mr={'1'} />
                  <Text>{STATUS_LABELS[row.original.status]}</Text>
                </>
              </Tooltip>
            </HStack>
          );
        },
      },
      {
        header: 'Incident',
        cell: ({ row }) => {
          return row.original.incident_id == '' ? (
            'N/A'
          ) : (
            <Link
              to={`/incident/${row.original.incident_id}`}
              style={{ color: THEME_COLORS.brand.blue }}
            >
              View Incident
            </Link>
          );
        },
      },

      {
        header: 'Action Executions',
        cell: ({ row }) => {
          return (
            <VStack spacing={0} alignItems="flex-start">
              <Text>{row.original.action_execution}</Text>
            </VStack>
          );
        },
      },
      // {
      //   header: 'Incident Message',
      //   cell: ({ row }) => {
      //     return (
      //       <VStack spacing={0} alignItems="flex-start">
      //         <Tooltip label={row.original.message} placement="bottom">
      //           <Text isTruncated>{row.original.message}</Text>
      //         </Tooltip>
      //       </VStack>
      //     );
      //   },
      // },
    ],
    [],
  );

  return columns;
}
