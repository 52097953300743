import React from 'react';
import { DialogModalFrame, Grid, Para, TextButton, Theme } from 'uie/components';
import { AddRunbookIcon } from 'icons';
import RenderTable from '../addTaskOrRunbookTable';
import { IRunbook } from '../../../../../../../core/interfaces/IRunBook';

interface ImodalBtnProps {
  onCancel: () => void;
  onClickFn: () => void;
  btnTxt: string;
  isDisabled: boolean;
}

export const ModalBtns = ({ onCancel, onClickFn, isDisabled, btnTxt }: ImodalBtnProps) => {
  return (
    <Grid justifyContent="flex-end" alignContent="flex-end" style={{ paddingTop: '15px' }}>
      <TextButton
        buttonType="ghost"
        style={{ padding: '1em' }}
        className=" mr-5"
        onClick={onCancel}
        disabled={false}
      >
        Cancel
      </TextButton>
      <TextButton
        buttonType="normal"
        style={{ color: 'white', padding: '1em' }}
        className=" mr-5"
        onClick={onClickFn}
        disabled={isDisabled}
        color={Theme.theme.primary.default}
      >
        {btnTxt}
      </TextButton>
    </Grid>
  );
};

interface IProps_ {
  setEnableModal: (enableModal: boolean) => void;
  selectedRunbooksList: Array<IRunbook>;
  setSelectedRunbooksList: (list: Array<IRunbook>) => void;
  AddRunbook: () => void;
  enableRunbookModal: boolean;
  organizationrunbookList: IRunbook[];
}

const AddRunbookModal = ({ ...props }: IProps_) => {
  const onCancel = () => {
    props.setEnableModal(false);
  };

  const onClickFn = () => {
    props.AddRunbook();
  };

  const isDisabled = !props.selectedRunbooksList.length;

  return (
    <DialogModalFrame
      id="incident_details__description_confirm_resolve_reassign_modal"
      onClose={() => {
        props.setEnableModal(false);
      }}
      padding="16px"
      width="70vw"
    >
      {props.enableRunbookModal && (
        <>
          <Grid type="column" style={{ padding: '15px' }}>
            <div className="item-box-icon-heading pr-20 mb-10">
              <AddRunbookIcon />
              <Para style={{ paddingLeft: '8px' }} fontWeight={400} fontSize={24}>
                Attach Runbook
              </Para>
            </div>
            <div className="mb-10">
              <RenderTable
                runbook={props.organizationrunbookList}
                selectedRunbooksList={props.selectedRunbooksList}
                setSelectedRunbooksList={(runbook: IRunbook[]) =>
                  props.setSelectedRunbooksList(runbook)
                }
              />
            </div>
            <ModalBtns
              onCancel={onCancel}
              onClickFn={onClickFn}
              isDisabled={isDisabled}
              btnTxt={'Add Runbooks'}
            />
          </Grid>
        </>
      )}
    </DialogModalFrame>
  );
};
export default AddRunbookModal;
