import { useState } from 'react';
import { useQueries } from 'react-query';
import { ISloOverview, sliDistribution, IMetrics, IsloInSights } from '../slo.interface';
import axios from 'axios';
import { API } from '../../../../../core/api';
import { createStandaloneToast } from '@chakra-ui/react';
import { queryKeys } from '../slo.constants';
import moment from 'moment';
import { EntityACLMeta } from 'core/userAccess/types';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';

const toast = createStandaloneToast();

const getSLODetails = async (sloID: number, teamId: string) => {
  const { data } = await axios.get<{
    data: { slo: ISloOverview; insights: IsloInSights };
    meta: {
      acl: Record<string, EntityACLMeta>;
    };
  }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo/${sloID}?owner_id=${teamId}`,
  );
  setUserAccessStore({
    entityACLMap: {
      ...getUserAccessStore().entityACLMap,
      slos: {
        ...getUserAccessStore().entityACLMap?.slos,
        ...data.meta.acl,
      },
    },
  });
  return data.data;
};

const getSLIList = async (sloID: number, teamId: string) => {
  const { data } = await axios.get<{ data: sliDistribution }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo/${sloID}/sli-distribution?owner_id=${teamId}`,
  );
  return data.data;
};

const getMetrics = async (
  sloID: number,
  teamId: string,
  filters: { dateTime: { fromDate: Date; toDate: Date } },
) => {
  const params: { [key: string]: string } = {};
  params['fromDate'] = moment(filters.dateTime.fromDate).toISOString();
  params['toDate'] = moment(filters.dateTime.toDate).toISOString();
  const { data } = await axios.get<{ data: IMetrics }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo/${sloID}/metrics?owner_id=${teamId}`,
    {
      params,
    },
  );
  return data.data;
};

export function useSloDetails(sloId: number, teamId: string) {
  const [pageOffset_, setOffset_] = useState<number>(1);
  const [pageLimit_, setLimit_] = useState<number>(10);
  const [pageOffset, setOffset] = useState<number>(1);
  const [pageLimit, setLimit] = useState<number>(10);
  const [sloFalsePositiveFilters, setSloFalsePositiveFilters] = useState<{ [key: string]: string }>(
    {},
  );
  const [sloViolatingOverviewFilters, setSloViolatingOverviewFilters] = useState<{
    [key: string]: string;
  }>({});
  const [filters, setFilter] = useState<{
    dateTime: { fromDate: Date; toDate: Date };
    rest?: { [key: string]: string };
  }>({
    dateTime: { fromDate: moment().subtract(1, 'months').toDate(), toDate: new Date() },
  });

  const setPaginationFn = (total: number) => {
    const noOfPages = total / pageLimit;
    const leftPages = total % pageLimit;
    const totalNoOfPages = leftPages ? Math.floor(noOfPages) + 1 : noOfPages;
    const pagination =
      totalNoOfPages > 6
        ? [pageOffset, pageOffset + 1, pageOffset + 2, pageOffset + 3, pageOffset + 4]
        : Array.from({ length: totalNoOfPages }, (_, i) => i + 1);
    return { pagination, totalNoOfPages };
  };

  const config = {
    retry: 1,
    staleTime: 0,
    keepPreviousData: true,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  };

  const results = useQueries([
    {
      queryKey: [queryKeys.SLODETAIL, sloId, teamId],
      queryFn: () => getSLODetails(sloId, teamId),
      onError: (error: any) => {
        const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
        toast.closeAll();
        toast({ title, status: 'error', variant: 'subtle', isClosable: true });
      },
      ...config,
    },
    {
      queryKey: [queryKeys.SLILIST, sloId, teamId],
      queryFn: () => getSLIList(sloId, teamId),
      onError: (error: any) => {
        const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
        toast.closeAll();
        toast({ title, status: 'error', variant: 'subtle', isClosable: true });
      },
      ...config,
    },
    {
      queryKey: [
        queryKeys.SLOVIOLATING,
        queryKeys.SLOFALSEPOSITIVE,
        queryKeys.SLOMTTR,
        queryKeys.SLOMTTA,
        queryKeys.TOTALINCIDENTS,
        sloId,
        filters,
        teamId,
      ],
      queryFn: () => getMetrics(sloId, teamId, filters),
      onError: (error: any) => {
        const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
        toast.closeAll();
        toast({ title, status: 'error', variant: 'subtle', isClosable: true });
      },
      ...config,
    },
  ]);

  let totalErrorBudget = 0;
  const pagination = [1];
  const totalNoOfPages = 0;
  const pagination_ = [1];
  const totalNoOfPages_ = 0;

  if (results[1].isFetched && results[1].data && results[1].data) {
    const { sli_distribution } = results[1].data;
    totalErrorBudget =
      results[1] && Array.isArray(sli_distribution)
        ? sli_distribution.reduce((prev, curr) => prev + curr.error_budget_spent, 0)
        : 0;
  }

  return {
    results,
    setSloViolatingOverviewFilters,
    setSloFalsePositiveFilters,
    setOffset_,
    setLimit_,
    setOffset,
    setLimit,
    pageOffset_,
    pageOffset,
    pageLimit_,
    pageLimit,
    totalErrorBudget,
    filters,
    setFilter,
    pagination,
    pagination_,
    totalNoOfPages,
    totalNoOfPages_,
    sloViolatingOverviewFilters,
    setPaginationFn,
    sloFalsePositiveFilters,
  };
}
