import { API } from '../../core/api';
import { IUserInfo } from '../../core/interfaces/IUserData';
import { initLogs } from '../../shared/analytics';
import { AppTracker } from '../../shared/analytics/tracker';
import { exception } from '../../core/exception';
import {
  checkAllObjectsAreValid,
  checkIfActionChanged,
  checkIfActionHasNotChanged,
  checkIfAllActionsAreSuccess,
  checkIfKeyChanged,
  getProperty,
} from 'core/helpers';
import { Init, IProps, IState } from './index';

let REHYDRATE_STATE: 'INIT' | 'REHYDRATE_ORG' | 'DONE' = 'INIT';

export function shouldComponentUpdate(this: Init, props: IProps, state: IState) {
  const prevProps = this.props;
  const prevState = this.state;

  if (props.auth.action === 'REQUEST_USER_LOGOUT') {
    return true;
  }

  if (checkIfActionHasNotChanged(prevState, state, 'componentState', 'finished')) {
    if (checkIfKeyChanged(prevProps.INIT_ORG, props.INIT_ORG, 'orgId')) {
      return true;
    }
    return false;
  }

  if (
    checkIfActionHasNotChanged(prevState, state, 'componentState', 'new-login') ||
    checkIfActionHasNotChanged(prevState, state, 'componentState', 'refresh')
  ) {
    checkReduxIsHydrated.call(this, props, state);
    return false;
  }

  if (checkIfActionChanged(prevState, state, 'componentState', 'idle')) {
    return false;
  }

  return true;
}

export async function onComponentUpdate(this: Init, prevProps: IProps, prevState: IState) {
  const props = this.props;
  const state = this.state;
  let requestAppInit = false;

  try {
    if (checkIfActionChanged(prevProps.auth, this.props.auth, 'action', 'REQUEST_USER_LOGOUT')) {
      this.resetStates();
      return;
    }

    if (
      checkIfActionChanged(prevProps.userInfo, props.userInfo, 'action', 'REQUEST_USER_INFO_FAIL')
    ) {
      props.requestUserLogout();
      return;
    }

    if (
      checkIfActionChanged(
        prevProps.organization.plan,
        prevProps.organization.plan,
        'action',
        'REQUEST_ORG_PLAN_FAIL',
      )
    ) {
      REHYDRATE_STATE = 'DONE';
      this.done();
    }

    if (
      state.componentState === 'idle' &&
      props.auth.sessionId.length > 0 &&
      !this.ssoInitTokenIsDifferent()
    ) {
      const isRefresh = checkAllObjectsAreValid([
        this.props.userInfo.d,
        this.props.userOrganizations.o,
        this.props.organization.plan.p,
      ]);

      API.bearer = props.auth.sessionId;
      API.config.sessionId = props.auth.sessionId;
      API.config.teamId = props.organization.selectedTeam.teamId;

      if (isRefresh && !this.state.isOrgChange) {
        API.config.organizationId = props.INIT_ORG.orgId || '';
      }

      this.setState({
        sessionId: props.auth.sessionId,
        componentState: isRefresh ? 'refresh' : 'new-login',
      });
      requestAppInit = true;
    }

    if (state.componentState === 'finished') {
      // check if user requested logout
      if (props.auth.sessionId === '' || this.ssoInitTokenIsDifferent()) {
        props.requestUserLogout();
        return;
      }

      // check if user requested org change
      if (checkIfKeyChanged(prevProps.INIT_ORG, props.INIT_ORG, 'orgId')) {
        this.setState({
          sessionId: props.auth.sessionId,
          organizationId: props.INIT_ORG.orgId,
          isOrgChange: true,
          componentState: 'new-login',
          forceRoot: true,
        });
        requestAppInit = true;
      }
    }
  } catch (err: any) {
    exception.handle('E_INIT_INVALID_BEFORE_RENDER', err);
    props.requestUserLogout();
  }

  if (
    checkIfActionChanged(prevState, state, 'forceRoot', true) &&
    window.location.pathname === '/'
  ) {
    this.setState({ forceRoot: false });
    return true;
  }

  // check if there is a new init request
  if (requestAppInit) {
    REHYDRATE_STATE = 'INIT';
    props.requestInitApp(state.sessionId);
    return;
  }
}

/**
 * init app checks
 */
export async function checkReduxIsHydrated(this: Init, props: IProps, state: IState) {
  const prevProps = this.props;
  API.hostname = state.hostname || '';

  try {
    // check if user data is fetched
    if (
      REHYDRATE_STATE === 'INIT' &&
      props.userOrganizations.action === 'REQUEST_USER_ORG_SUCCESS' &&
      props.userInfo.d
    ) {
      if (props.userOrganizations.o.length === 0) {
        REHYDRATE_STATE = 'DONE';
        this.done();
        return;
      }
    }

    if (
      REHYDRATE_STATE === 'INIT' &&
      props.userInfo.action === 'REQUEST_USER_INFO_SUCCESS' &&
      props.userOrganizations.action === 'REQUEST_USER_ORG_SUCCESS' &&
      checkAllObjectsAreValid([props.userOrganizations.o, props.userInfo.d, props.userInfo.d?.id])
    ) {
      const defaultOrgId =
        props.userOrganizations.o.find(o => !o.deActivated) || props.userOrganizations.o[0];
      let selectedOrgId = props.INIT_ORG.orgId || defaultOrgId.organizationId;

      if (
        props.INIT_ORG.orgId.length > 0 &&
        props.userOrganizations.o.some(
          o => o.organizationId === props.INIT_ORG.orgId && o.deActivated,
        )
      ) {
        selectedOrgId = defaultOrgId.organizationId;
      }

      API.config.organizationId = selectedOrgId;
      API.config.userId = props.userInfo.d!.id;
      props.requestInitOrg(selectedOrgId);

      exception.userLog = {
        organizationId: selectedOrgId,
        userId: props.userInfo.d!.id,
        userEmail: props.userInfo.d!.email,
      };

      REHYDRATE_STATE = 'REHYDRATE_ORG';
    }

    // check if org has rehydrate redux
    if (
      REHYDRATE_STATE === 'REHYDRATE_ORG' &&
      checkIfAllActionsAreSuccess([
        props.organization.plan.action,
        props.organization.users.action,
      ]) &&
      checkAllObjectsAreValid([
        props.organization.plan.p,
        props.organization.users.u,
        props.organization.currentOrg.o,
      ])
    ) {
      const currentOrg = props.organization.currentOrg.o;

      if (!currentOrg && !this.state.ssoChecked) {
        const ssoEnabled = await this.orgHasEnabledSSO();
        this.setState({ ssoEnabled, ssoChecked: true });
        if (ssoEnabled) {
          return;
        }
      }

      if (state.ssoEnabled) {
        return;
      }
    }

    if (
      REHYDRATE_STATE === 'REHYDRATE_ORG' &&
      checkIfActionChanged(
        prevProps.organization.teams,
        props.organization.teams,
        'action',
        'REQUEST_ORG_TEAMS_SUCCESS',
      )
    ) {
      if (props.organization.teams.t.length === 0) {
        this.done();
        return;
      }

      if (
        checkAllObjectsAreValid([
          props.userInfo.d,
          props.organization.currentOrg.o,
          props.organization.teams.t,
        ])
      ) {
        const userAssignedTeam = props.organization.teams.t.filter(t =>
          t.members.some(m => m.user_id === props.userInfo.d?.id || ''),
        );
        const defaultTeamId = getProperty(userAssignedTeam[0], 'id', '');
        const selectedTeamId = props.organization.selectedTeam.teamId || defaultTeamId;

        if (
          !state.isOrgChange &&
          userAssignedTeam.find(
            t =>
              t.id === selectedTeamId &&
              t.members.some(m => m.user_id === props.organization.currentUser.u?.id || ''),
          )
        ) {
          API.config.teamId = selectedTeamId;
          props.requestOrganizationSelectedTeamChange(selectedTeamId);
        } else {
          API.config.teamId = defaultTeamId;
          props.requestOrganizationSelectedTeamChange(defaultTeamId);
        }
      }

      REHYDRATE_STATE = 'DONE';
    }

    if (
      REHYDRATE_STATE === 'DONE' &&
      checkIfAllActionsAreSuccess([
        props.organization.teams.action,
        props.organization.escalations.action,
        props.organization.squads.action,
        props.organization.services.action,
        props.organization.plan.action,
      ]) &&
      checkAllObjectsAreValid([props.organization.plan.p])
    ) {
      this.done();
    }
  } catch (err: any) {
    exception.handle('E_INIT_INVALID', err);
    this.props.requestUserLogout();
  }
}

/**
 * initialize app analytics and trackers
 */
export async function done(this: Init) {
  const coffeePot = () => {
    this.setState({
      isOrgChange: false,
      componentState: 'finished',
    });
  };

  if (
    this.props.organization.plan.action === 'REQUEST_ORG_PLAN_FAIL' &&
    this.props.organization.plan.error.reason.__sentry_xhr__.status_code === 403
  ) {
    return coffeePot();
  }

  if (
    this.props.organization.plan.action !== 'REQUEST_ORG_PLAN_FAIL' &&
    !this.props.organization.plan.p
  ) {
    return coffeePot();
  }

  if (this.props.userOrganizations.o.length === 0 || this.props.organization.teams.t.length === 0) {
    return coffeePot();
  }

  try {
    const { organizationId, organizationName, slug } = this.props.organization.currentOrg.o!;
    this.setState({
      organizationId,
      isOrgChange: false,
      componentState: 'finished',
    });

    const currentOrg = this.props.userOrganizations.o.find(
      o => o.organizationId === this.props.INIT_ORG.orgId,
    );

    initLogs(
      this.props.userInfo.d as IUserInfo,
      this.props.organization.plan.p!.metadata.chargebee.subscription_id,
      this.props.organization.plan.p!.plan_slug,
      this.props.organization.plan.p!.metadata.chargebee.subscription_status,
      this.props.organization.plan.p!.metadata.chargebee.card_status,
      organizationId,
      organizationName,
      slug,
      getProperty(
        this.props.roles.r.find(r => r._id === currentOrg?.roleId),
        'name',
        'undefined',
      ),
    );

    this.initPusherEvents();
    AppTracker.trackLogin();
  } catch (err: any) {
    exception.handle('E_INIT_INVALID_DONE', err);
    this.props.requestUserLogout();
  }
}
