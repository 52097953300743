import { getPaddedValue } from 'core/helpers/stringUtils';
import { DateTime, DateTimeUnit, Interval } from 'luxon';

const checkAndApplyTZ = (date: string | Date, timezone: string) =>
  timezone
    ? DateTime.fromJSDate(new Date(date)).setZone(timezone)
    : DateTime.fromJSDate(new Date(date));

const getWeekDateRange = (date: string | Date) => {
  const dt = DateTime.fromJSDate(new Date(date));
  const dateFromStr = dt.startOf('week');
  const dateToStr = dt.endOf('week');

  return [dateFromStr.toFormat('MMM dd, yyyy'), dateToStr.toFormat('MMM dd, yyyy')].join(' - ');
};

function* days(interval: Interval) {
  let cursor = interval.start.startOf('day');
  while (cursor < interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
  }
}

const getOneWeekDateRange = (date: string | Date, timezone: string) => {
  const dt = checkAndApplyTZ(date, timezone);
  const dateFrom = dt.startOf('week');
  const dateTo = dt.endOf('week');
  const interval = Interval.fromDateTimes(dateFrom, dateTo);

  return Array.from(days(interval));
};

const getTwoWeekDateRange = (date: string | Date, timezone: string) => {
  const dt = checkAndApplyTZ(date, timezone);
  const dateFrom = dt.startOf('week');
  const dateTo = dt.plus({ days: 7 }).endOf('week');
  const interval = Interval.fromDateTimes(dateFrom, dateTo);

  return Array.from(days(interval));
};

const getDateInterval = (dateFrom: string | Date, dateTo: string | Date, timeZone: string) => {
  const fromDT = DateTime.fromJSDate(new Date(dateFrom)).setZone(timeZone);
  const toDT = DateTime.fromJSDate(new Date(dateTo)).setZone(timeZone);
  if (!(fromDT.isValid && toDT.isValid)) return [];

  const interval = Interval.fromDateTimes(fromDT.startOf('day'), toDT.endOf('day'));

  return Array.from(days(interval));
};

/**
 *
 * @param date
 * @returns Array of dates between start & end time
 */
export function getOneMonthDateRange(date: Date, timezone: string): DateTime[] {
  const dateTime = checkAndApplyTZ(new Date(date), timezone);
  const dateFrom = dateTime.startOf('month');
  const dateTo = dateTime.endOf('month');
  const interval = Interval.fromDateTimes(dateFrom, dateTo);

  return Array.from(days(interval));
}

const getTZFormat = (tz: string, fmt: string) => {
  return DateTime.local().setZone(tz).toFormat(fmt);
};

const getTZTime = (tz: string, date: Date | string) => {
  return DateTime.fromJSDate(new Date(date)).setZone(tz).toLocaleString(DateTime.TIME_24_SIMPLE);
};

/**
 * Get hours difference between two dates
 * @param date1 ISO Date
 * @param date2 ISO Date
 * @param tz Timezone
 * @returns
 */
const getHoursDiff = (date1: string, date2: string, tz: string) => {
  return DateTime.fromISO(date2)
    .setZone(tz)
    .diff(DateTime.fromISO(date1).setZone(tz), ['hours'])
    .toObject().hours;
};

/**
 * Get minutes difference between two dates
 * @param date1 ISO Date
 * @param date2 ISO Date
 * @param tz Timezone
 * @returns
 */
const getMinutesDiff = (date1: string, date2: string, tz: string) => {
  return DateTime.fromISO(date2)
    .setZone(tz)
    .diff(DateTime.fromISO(date1).setZone(tz), ['minutes'])
    .toObject().minutes;
};

/**
 * Calculates the offset between two timezones
 * @param tz1 Timezone of comparision
 * @param tz2 Timezone to compare with
 * @returns
 */
const getTZDifference = (tz1: string, tz2: string) => {
  const offset = DateTime.local().setZone(tz1).offset - DateTime.local().setZone(tz2).offset;
  const absOffset = Math.abs(offset);
  const offsetInHrs = absOffset / 60,
    isOffsetInMins = absOffset < 60;
  let relativeString =
    (isOffsetInMins ? absOffset : offsetInHrs).toString() + (isOffsetInMins ? ' mins' : ' hrs');

  if (offset === 0) {
    relativeString = '';
  } else if (offset > 0) {
    relativeString += ' ahead';
  } else {
    relativeString += ' behind';
  }

  return {
    offset,
    relativeString,
  };
};

const isAllDayInTZ = (tz: string, startDate: Date, endDate: Date) => {
  const startDT = DateTime.fromJSDate(startDate).setZone(tz);
  const endDT = DateTime.fromJSDate(endDate).setZone(tz);

  return startDT === startDT.startOf('day') && endDT === endDT.endOf('day');
};

/**
 * Get TZ ISO supplied with hour and minutes
 * @param tz timezone
 * @param date JS Date
 * @param hour Hour
 * @param min Min
 * @returns
 */
const getTZWTimeISO = (tz: string, date: Date, hour = 0, min = 0) => {
  const startDT = getDateTime(date);
  return DateTime.local()
    .setZone(tz)
    .set({
      year: startDT.year,
      month: startDT.month,
      day: startDT.day,
      hour,
      minute: min,
      second: 0,
      millisecond: 0,
    })
    .toISO();
};

/**
 * Get luxon datetime supplied with UTC
 * @param date UTC Date string
 * @param tz timezone
 * @returns DateTime
 */
const getUTCDateTime = (date: string, tz: string) => {
  return DateTime.fromISO(date).setZone(tz);
};

const getWeekAndDay = (date: Date, timeZone: string) => {
  const dt = DateTime.fromJSDate(date).setZone(timeZone);
  return {
    week: dt.weekdayShort,
    day: dt.day,
  };
};

const isCurrentTime = (startTime: Date, endTime: Date) => {
  const currentTime = new Date();
  return currentTime > startTime && endTime > currentTime;
};

//helper function to get hour:min date (e.g. 16:15)
const getTime = (date: Date | string) => {
  const dt = DateTime.fromJSDate(new Date(date));
  return { hour: dt.hour as number, minute: dt.minute as number };
};

/**
 * @typedef {Object} DateInfo
 * @property {number} year - Year of the date
 * @property {number} month - Month of the date
 * @property {number} shortMonth - Short Month representation of the date (i.e. Oct)
 * @property {number} weekDay - Weekday representation of the date (i.e. 2022,April,4,Thursday)
 * @property {number} day - Day of the date
 */
/**
 * Get time in year, month, shortMonth (Apr), day, weekday (2022,April,4,Thursday)
 * @param date JS Date
 * @return {DateInfo}
 */
const getDateInfo = (date: Date) => {
  const dt = DateTime.fromJSDate(date);
  return {
    year: dt.year,
    month: dt.monthLong,
    shortMonth: dt.monthShort,
    weekDay: dt.weekdayLong,
    day: dt.day,
  };
};

const getDayMonthDate = (date: Date) =>
  DateTime.fromJSDate(date).toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' });
const getMediumDate = (date: Date, timezone: string) =>
  checkAndApplyTZ(date, timezone).toLocaleString(DateTime.DATE_MED);
const getFullDate = (date: Date) => DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL);

const getDateTime = (date: Date) => DateTime.fromJSDate(date);
const getSQLDate = (date: Date) => DateTime.fromJSDate(date).toSQLDate();
const getDateTimeFormat = (date: Date, format: string) =>
  DateTime.fromJSDate(date).toFormat(format);

/**
 * Generate start and end datetime for a given date and unit.
 * @param date JS Date
 * @param unit Date unit. e.g. day, week
 * @returns Object that has start and end datetime
 */
const getStartEndDateTime = (date: Date, unit: DateTimeUnit) => {
  return {
    start: getDateTime(date).startOf(unit).toJSDate(),
    end: getDateTime(date).endOf(unit).toJSDate(),
  };
};

const isSameDate = (dateX: Date, dateY: Date) =>
  getDateTime(dateX).toISODate() === getDateTime(dateY).toISODate();

/**
 * Get formatted start and end date separated by hypen
 * @param startDate
 * @param endDate
 * @returns
 */
const getEventDateRange = (startDT: DateTime, endDT?: DateTime) => {
  const isSameYear = endDT && startDT.year === endDT.year;

  let formattedEndDT = '';
  const formattedStartDT = [startDT.toFormat('MMM dd, yyyy'), isSameYear ? '' : startDT.year]
    .filter(value => value)
    .join(', ');

  if (endDT) {
    formattedEndDT = [endDT.toFormat('MMM dd, yyyy'), isSameYear ? '' : endDT.year]
      .filter(value => value)
      .join(', ');
  }

  return [formattedStartDT, formattedEndDT || 'Forever'].join(' - ');
};

/**
 * @typedef {Object} TZOffsetDetail
 * @property {boolean} shortName - offset in EDT or EST
 * @property {Object} shortOffset - Short offset i.e. +05:00
 */
/**
 * Get the short human name for the provided zone's current offset, for example "EST" or "EDT".
 * @param timeZone Timezone
 * @returns {TZOffsetDetail}
 */
const getTZOffset = (timeZone: string) => {
  const offsetNameLong = DateTime.local().setZone(timeZone).offsetNameLong;

  return {
    shortName: offsetNameLong
      .split(/\s/) //splitting the long representation e.g. Indian Standard Time
      .reduce((shortname, word) => (shortname += word.slice(0, 1)), ''), //returns the short representation e.g. IST
    shortOffset: getTZFormat(timeZone, 'ZZ'),
    longName: offsetNameLong,
  };
};

/**
 * @typedef {Object} TZDetail
 * @property {boolean} isSameDate - Comparator to check if the provided date and timezone are in sync
 * @property {boolean} isPast - Comparator to check if the provided date is in past
 * @property {boolean} isFuture - Comparator to check if the provided date is in future
 * @property {Object} tzTime - The timezone time in hour and minute
 */
/**
 * Compares the input date with today's date on the timezone provided
 * @param date JS Date
 * @param timeZone A timezone string e.g. Asia/Kolkata
 * @returns {TZDetail} Timezone time detail with compared results
 */
const compareTodayTZDate = (date: Date, timeZone: string) => {
  const tzDT = DateTime.fromJSDate(date).setZone(timeZone);
  const tzDTToday = DateTime.local().setZone(timeZone);

  return {
    isSameDate: tzDT.toSQLDate() === tzDTToday.toSQLDate(),
    isPast: tzDT < tzDTToday,
    isFuture: tzDT > tzDTToday,
    tzTime: { hr: tzDTToday.hour, min: tzDTToday.minute, sec: tzDTToday.second },
  };
};

const compareTwoDates = (date1: DateTime, date2: DateTime) => {
  return {
    isSameDate: date1.toSQLDate() === date2.toSQLDate(),
    isPast: date1 < date1,
    isFuture: date1 > date1,
    offset: Math.floor(date1.diff(date2, 'days').toObject().days ?? 0),
  };
};

const isPastDate = (dateX: Date, dateY: Date) =>
  getDateTime(dateX).toISODate() < getDateTime(dateY).toISODate();

const paddedUTCDate = (eventDate: string, timeZone: string) => {
  const date = getUTCDateTime(eventDate, timeZone);
  const timeString = `${getPaddedValue(date.hour)}:${getPaddedValue(date.minute || 0)}`;

  return timeString;
};
export {
  getWeekDateRange,
  getOneWeekDateRange,
  getTwoWeekDateRange,
  getDateInterval,
  getTZFormat,
  getTZTime,
  getHoursDiff,
  getMinutesDiff,
  getTZDifference,
  isAllDayInTZ,
  getTZWTimeISO,
  getUTCDateTime,
  getWeekAndDay,
  getDateTime,
  getSQLDate,
  getDateTimeFormat,
  getDateInfo,
  getTime,
  isCurrentTime,
  getDayMonthDate,
  getMediumDate,
  getFullDate,
  getStartEndDateTime,
  isSameDate,
  getEventDateRange,
  getTZOffset,
  compareTodayTZDate,
  compareTwoDates,
  isPastDate,
  paddedUTCDate,
};
