import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { API } from '../../../../../core/api';
import { createStandaloneToast } from '@chakra-ui/react';
import { queryKeys } from '../slo.constants';

const toast = createStandaloneToast();

const deleteSlo = async (deleteSloId: number, teamId: string) => {
  const { data } = await axios.delete(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo/${deleteSloId}?owner_id=${teamId}`,
  );
  return data;
};

export function useDeleteSlo(teamId: string) {
  const queryClient = useQueryClient();

  const { mutate: deleteSelectedSlo } = useMutation(
    (deleteSloId: number) => deleteSlo(deleteSloId, teamId),
    {
      onMutate: async (deleteSloId: number | null) => {},
      onError: (err: any) => {
        const error = err?.response?.data?.meta?.error_message || 'error connecting to server';
        toast({
          title: 'Delete SLO',
          description: error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
      onSuccess: data => {
        toast({
          title: 'Delete SLO',
          description: 'SLO successfully deleted',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.SLOLIST);
      },
    },
  );

  return deleteSelectedSlo;
}
