import request from '../commons';
import { API } from '../../api';
import { IAccountUserUpdateRequest, IChangePassword } from '../../interfaces/IAccount';
import { IUserNotificationRule } from '../../interfaces/IUserData';

const updateAccountInfo = (payload: IAccountUserUpdateRequest) => {
  const req = request.put(API.config.endpoint)('me', {
    session_token: API.config.sessionId,
    ...payload,
  });

  return req;
};

const changePassword = (payload: IChangePassword) => {
  const req = request.put(API.config.endpoint)('me/password', payload);

  return req;
};

const updateNotificationRules = (payload: IUserNotificationRule[]) => {
  const req = request.put(API.config.batman)('me/notification-rules', {
    rules: payload,
  });

  return req;
};

export { updateAccountInfo, changePassword, updateNotificationRules };
