import React, { ReactNode } from 'react';
import { IOrganizationRunbookReduxActions, IRunbook } from 'core/interfaces/IRunBook';
import { markupType, runbookList } from '../runbooks.constant';
import { DeleteIcon, EditIcon } from '../../../../../icons';
import { Link, generatePath } from 'react-router-dom';
import { Theme } from 'uie/components';
const { theme } = Theme;
import '../index.css';
import { Locale } from 'core/helpers/dateUtils';
import { USER_PROFILE_PATH } from 'views/main/routes/routes';
import { RESET_STATE } from 'core/const/init';
import { Box, Center, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import { Tooltip } from '../../../../../library/atoms/Tooltip';
import { EntityType, hasReadAccessToEntity } from '../../navigation-flows/helpers';
import useUINavigationFunctions from '../../navigation-flows/useUINavigationFunctionV2';
import { THEME_COLORS } from 'library/theme/colors';
import { truncateString } from '../../statuspage-v2/helpers/helper.details';
import { useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { truncate } from 'core/helpers/stringUtils';
interface IProps {
  runbooks: IRunbook[];
  hasDelete: boolean;
  hasUpdate: boolean;
  deleteRunbook: (id: string, name: string) => void;
  isLoading: IOrganizationRunbookReduxActions | typeof RESET_STATE;
}

const usernameTooltip = (
  type: string,
  name: string,
  username: string | undefined,
  node: ReactNode,
) => (
  <Tooltip
    label={
      type === 'user' && (
        <VStack
          spacing={0}
          p="8px"
          alignItems="flex-start"
          backgroundColor={THEME_COLORS.secondary[1400]}
        >
          <Text fontWeight={'bold'} fontSize={'12px'} maxW={'30ch'}>
            {name}
          </Text>
          {username && (
            <Text fontSize={'12px'} maxW={'30ch'}>
              {username}
            </Text>
          )}
        </VStack>
      )
    }
    placement="bottom"
  >
    {node}
  </Tooltip>
);

const RenderTable = (props: IProps) => {
  const { getEntityLink, getEntityName, getEntityUsername } = useUINavigationFunctions();

  const OBACEnabled = useSelector(
    (state: IAppState) => state.organization.currentOrg?.o?.config.obac_enabled,
  );

  const hasUpdateAccess = useUserAccess().hasUpdateAccess;
  const hasDeleteAccess = useUserAccess().hasDeleteAccess;

  const hasUpdate = (id: string) => hasUpdateAccess('runbooks', id);

  const hasDelete = (id: string) => hasDeleteAccess('runbooks', id);

  return (
    <div className="runbook_table_container">
      <table className="incident__details__runbook_list_table">
        <thead style={{ background: THEME_COLORS.primary[100], opacity: 0.8 }}>
          <tr>
            {runbookList.map(({ name, size }, index) => {
              return (
                <th
                  key={index}
                  style={{
                    width: `${size}`,
                    textAlign: 'left',
                    padding: '12px',
                    fontSize: 'small',
                    fontWeight: 600,
                    color: THEME_COLORS.secondary[700],
                  }}
                >
                  {name}
                </th>
              );
            })}
          </tr>
        </thead>
        {props.isLoading === 'REQUEST_ORG_RUNBOOK' ? (
          <tbody>
            <tr>
              <td colSpan={10}>
                <Center width={'inherit'} display={'flex'} height={'50px'}>
                  <Center display={'block'}>
                    <Box
                      flexDirection="row"
                      justifyContent="center"
                      width={'300px'}
                      display="flex"
                      marginTop="5px"
                    >
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        color="#3182ce"
                        height={'40px'}
                        width={'40px'}
                      />
                    </Box>
                  </Center>
                </Center>
              </td>
            </tr>
          </tbody>
        ) : props.runbooks.length > 0 ? (
          <tbody>
            {props.runbooks.map((runbook: IRunbook, index) => {
              const ownerComponent =
                runbook.entity_owner.type &&
                (hasReadAccessToEntity(runbook.entity_owner.type as EntityType)
                  ? (() => {
                      const username = getEntityUsername(
                        runbook.entity_owner.type as EntityType,
                        runbook.entity_owner.id,
                      );
                      const name = getEntityName(
                        runbook.entity_owner.type as EntityType,
                        runbook.entity_owner.id,
                      );
                      return usernameTooltip(
                        runbook.entity_owner.type,
                        name,
                        username,
                        <Link
                          to={getEntityLink(
                            runbook.entity_owner.type as EntityType,
                            runbook.entity_owner.id,
                          )}
                        >
                          {truncateString(name, 15)}
                        </Link>,
                      );
                    })()
                  : getEntityName(
                      runbook.entity_owner.type as EntityType,
                      runbook.entity_owner.id,
                    ));

              const canUpdate = hasUpdate(runbook.id ?? '');
              const canDelete = hasDelete(runbook.id ?? '');

              return (
                <tr key={index}>
                  <td style={{ paddingLeft: '12px' }}>
                    <Link to={`/runbooks/update/${runbook.id}/${markupType.VISUAL}`}>
                      <Tooltip label={runbook.name} placement="bottom-start">
                        <Text
                          sx={{
                            cursor: 'pointer',
                            fontSize: 'sm',
                            '&:hover': {
                              color: THEME_COLORS.brand.blue,
                            },
                          }}
                        >
                          {truncate(runbook.name, 40)}
                        </Text>
                      </Tooltip>
                    </Link>
                  </td>
                  <td style={{ paddingLeft: '12px' }}>
                    <Text fontSize="sm" color={THEME_COLORS.brand.blue}>
                      {ownerComponent}
                    </Text>
                  </td>
                  <td style={{ paddingLeft: '12px' }}>
                    <Text fontSize="sm">{Locale.toFullDateTime(runbook.created.at)}</Text>
                  </td>
                  <td style={{ paddingLeft: '12px' }}>
                    {usernameTooltip(
                      'user',
                      runbook.created.user_name,
                      runbook.created.username_for_display,
                      <Link to={generatePath(USER_PROFILE_PATH, { id: runbook.created.user_id })}>
                        <Text fontSize="sm" color={THEME_COLORS.brand.blue}>
                          {' '}
                          {runbook.created.user_name}
                        </Text>
                      </Link>,
                    )}
                  </td>
                  <td style={{ paddingLeft: '12px' }}>
                    <Text fontSize="sm">{Locale.toFullDateTime(runbook.updated.at)}</Text>
                  </td>
                  <td style={{ paddingLeft: '12px' }}>
                    {usernameTooltip(
                      'user',
                      runbook.updated.user_name,
                      runbook.updated.username_for_display,
                      <Link to={generatePath(USER_PROFILE_PATH, { id: runbook.updated.user_id })}>
                        <Text color={THEME_COLORS.brand.blue} fontSize="sm">
                          {' '}
                          {runbook.updated.user_name}
                        </Text>
                      </Link>,
                    )}
                  </td>
                  <td style={{ paddingLeft: '12px' }}>
                    <Text fontSize="sm">{runbook.used_count}</Text>
                  </td>
                  <td>
                    <HStack>
                      <NoPermissionTooltip isDisabled={canUpdate}>
                        <Link
                          to={canUpdate ? `/runbooks/update/${runbook.id}/Markdown` : '#'}
                          style={{ display: 'inline-block', paddingTop: '4px' }}
                        >
                          <EditIcon
                            stroke={theme.primary.default}
                            className={`runbook_edit_delete_btn ${!canUpdate && 'disabled'}`}
                          />
                        </Link>
                      </NoPermissionTooltip>
                      <NoPermissionTooltip isDisabled={canDelete}>
                        <DeleteIcon
                          onClick={() =>
                            canDelete && props.deleteRunbook(runbook.id || '', runbook.name)
                          }
                          stroke={theme.danger.default}
                          style={{ margin: 0 }}
                          className={`runbook_edit_delete_btn ${!canDelete && 'disabled'}`}
                        />
                      </NoPermissionTooltip>
                    </HStack>
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={10} style={{ textAlign: 'center' }}>
                <Text fontSize={14} textAlign="center">
                  No data
                </Text>
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default RenderTable;
