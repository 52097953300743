import {
  ILog,
  IRunbook,
  IRunbookAndTaskActivity,
} from '../../../../../../core/interfaces/IIncidents';
import { AxiosError } from 'axios';

import {
  ADD_OR_UPDATE_EVENTS,
  CHECK_EVENTS,
  TASK_EVENTS,
  RUNBOOK_EVENTS,
} from './taskAndRunbook.constants';

function checkForAttachment(content: string): string {
  const imageReg = new RegExp(/!\[(.*)\]\((.+)\)/g);
  const attachmentReg = new RegExp(/\[(.*)\]\((.+)\)/g);

  if (imageReg.test(content)) return 'Task With Image';
  if (attachmentReg.test(content)) return 'Task With Attachment';

  return content;
}

export const getAddOrUpdateEventsData = (
  log: ILog,
): Array<IRunbookAndTaskActivity> | Array<any> => {
  if (log['additionalInfo']) {
    const additionalInfo = log['additionalInfo'];

    if (ADD_OR_UPDATE_EVENTS.includes(log.action) || CHECK_EVENTS.includes(log.action)) {
      if (TASK_EVENTS.includes(log.action)) {
        if (typeof additionalInfo['task_id'] !== 'undefined' && additionalInfo['content']) {
          const content = checkForAttachment(additionalInfo['content'] || '');
          const slicedContent = content.length > 30 ? `${content.slice(0, 30)}...` : content;
          return [
            { txt: `${slicedContent}`, isRunbook: false, focusId: additionalInfo['task_id'] },
          ];
        }
      }

      if (RUNBOOK_EVENTS.includes(log.action)) {
        if (additionalInfo['runbooks']) {
          return additionalInfo['runbooks'].map((runbooks: IRunbook) => {
            return { txt: runbooks.name, isRunbook: true, focusId: runbooks.id };
          });
        }

        if (
          additionalInfo['runbookName'] &&
          additionalInfo['runbookID'] &&
          additionalInfo['index']
        ) {
          return [
            {
              txt: additionalInfo['runbookName'],
              isRunbook: true,
              focusId: additionalInfo['runbookID'],
              index: additionalInfo['index'],
            },
          ];
        }
      }
    }
  }
  return [];
};

export const isAxiosError = (err: any): err is AxiosError => {
  return err?.isAxiosError === true;
};
