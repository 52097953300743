import { Box } from '@chakra-ui/layout';
import { ActiveFilterTag, ActiveFilterTags } from '../../owner-filters/ActiveFilterTags';
import { Owner } from '../../owner-filters/hooks/useEntityOwnerFilter';

interface Props {
  activeFilterTags: ActiveFilterTag[];
  onClickActiveFilterTag: (owner: Owner, id: string) => void;
}

export function EPFilterTags(props: Props) {
  return (
    <>
      <Box style={{ paddingLeft: '0px', display: 'inline-block' }}>
        <ActiveFilterTags
          tags={props.activeFilterTags}
          onClose={props.onClickActiveFilterTag}
          visibleTagsCount={2}
          applyChakraStyle={false}
        />
      </Box>
    </>
  );
}
