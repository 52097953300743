import { Box } from '@chakra-ui/react';
import { IIncidentSummaryResponse } from 'core/interfaces/IIncidents';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import breaks from 'remark-breaks';
import IncidentSummarySection from '../Section';
import { Heading, Para } from 'uie/components';
import { THEME_COLORS } from 'library/theme/colors';

interface IProps {
  content: IIncidentSummaryResponse['content'];
}

const IncidentSummaryLLMResponse: React.FC<IProps> = props => {
  return (
    <IncidentSummarySection heading="" style={{ marginLeft: 16, marginTop: -16 }} withoutDivider>
      <ReactMarkdown className="incident_summary__mdr" plugins={[breaks, gfm]}>
        {props.content}
      </ReactMarkdown>
    </IncidentSummarySection>
  );
};

export default IncidentSummaryLLMResponse;
