import { Box } from '@chakra-ui/layout';
import { LCRListState, ILogsFilterDataTypes } from '../../../../types/LCR.types';
import { useLCRStore } from '../../../../hooks/lcr.state';
import { TAG_VARIANTS } from 'library/atoms/Tag/Tag';
import TagStack from 'library/atoms/Tag/TagStack';

const FilterPreview = () => {
  const lcrState = useLCRStore((state: LCRListState) => state);

  const lcrLogsFilter = lcrState.logsFilterApply ?? [];
  const renderUserMappings = (dropdownValue: ILogsFilterDataTypes[]) => {
    return (
      <TagStack
        variant={TAG_VARIANTS.PLAIN}
        defaultLength={10}
        onClose={(removeIndex, item) => {
          const updatedArr = dropdownValue.filter((_t: any, i: any) => i !== removeIndex);
          useLCRStore.getState().setLCRLogsApply(updatedArr);
          useLCRStore.getState().setLCRLogsFilter(updatedArr);
        }}
        tags={
          dropdownValue?.length
            ? dropdownValue.map((tag: ILogsFilterDataTypes) => {
                const prefix = tag.key === 'User' ? 'Assigned to' : 'Affected Services';

                return {
                  label: `${prefix}: ${tag.label}`,
                };
              })
            : []
        }
      />
    );
  };
  return <Box>{renderUserMappings(lcrLogsFilter)}</Box>;
};

export default FilterPreview;
