import axios from 'axios';
import { API } from '../api';
import { ICIBuild, ICIProject, ICIRebuildResponse } from '../interfaces/IActions';
import { IExtensionSchema } from '../interfaces/IExtensions';

class CircleCIService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/extensions/circleci`;
  private _incidentApi = `${API.config.batman}/organizations/${API.config.organizationId}/incidents`;

  public getProjects = () => axios.get<{ data: ICIProject[] }>(`${this._api}/projects`);

  public getBuilds = (project: ICIProject) =>
    axios.get<{ data: ICIBuild[] }>(
      `${this._api}/projects/${project.reponame}/builds?user_name=${project.username}&vcs_type=${project.vcs_type}&branch=${project.default_branch}`,
    );

  public checkEnabled = () =>
    axios.get(
      `${API.config.endpoint}/organizations/${API.config.organizationId}/extensions/name/circleci`,
    );

  public triggerAction = (incidentID: string, build: ICIBuild, project: ICIProject) =>
    axios.post<{ data: ICIRebuildResponse }>(
      `${this._incidentApi}/${incidentID}/actions/circleci/rebuild/${build.build_num}`,
      {
        vcs_type: project.vcs_type,
        username: project.username,
        reponame: project.reponame,
      },
    );

  public saveToken = (token: string) =>
    axios.post<{ data: IExtensionSchema }>(this._api, {
      token,
    });
}

export default CircleCIService;
