import React from 'react';
import {
  Theme,
  ErrorBlock,
  Grid,
  SpinLoader,
  InputBlock,
  TextButton,
  Para,
  FocusBlock,
  SelectBox,
  Heading,
  DialogModalFrame,
  IconButton,
  Tooltip,
  Checkbox,
  JSONCodeBlock,
} from 'uie/components';
import shortid from 'shortid';
import { DeduplicationRulesModal } from '.';
import cx from 'classnames';
import './index.css';
import { IAlertSource } from 'core/interfaces/IIntegration';
import { matchSorter } from 'match-sorter';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService } from 'core';
import { Button, Link, Image, Text } from '@chakra-ui/react';
import {
  flattenedObject,
  generateExpression,
  getLHSType,
} from 'views/main/organization/service-catalog/helpers/helper.automation-rule';
import {
  IRuleExpressionOperation,
  IRuleTimeUnit,
} from 'views/main/organization/service-catalog/Interfaces/automation-rule';

const { theme } = Theme;
const activeColor = theme.success.default;
const inactiveColor = theme.danger.default;

function render(this: DeduplicationRulesModal) {
  const {
    alertSourcesLoadState,
    alertSourceSearch,
    alertSource,
    alertSourceErrorMessage,
    swappedRuleIndexes,
    rules,
    globalSearch,
  } = this.state;

  let filteredRules = rules;

  try {
    const matchedRules = matchSorter(rules, globalSearch, {
      keys: ['description', 'expression', 'basic_expression.*.lhs', 'basic_expression.*.rhs'],
      baseSort: (r1, r2) => (r1.item.id < r2.item.id ? -1 : 1),
    });

    filteredRules = globalSearch ? matchedRules : filteredRules;
  } catch (_) {}

  const limit = BillingService.getLimit(this.props, 'deduplication-rules');

  return (
    <div>
      <div className="mb-10">
        <Grid justifyContent="space-between" alignItems="center">
          <Heading height={35} fontSize={24}>
            Deduplication Rules
          </Heading>
        </Grid>
      </div>
      <div className="mb-10">
        <Grid justifyContent="space-between" alignItems="center">
          <Heading
            height={35}
            fontSize={14}
            style={{
              fontSize: '12px',
              color: '#808080',
              fontWeight: 300,
            }}
          >
            Auto-group similar alerts to reduce alert noise. Learn more about deduplication rules{' '}
            <Link
              href="https://support.squadcast.com/services/alert-deduplication-rules/alert-deduplication-rules"
              target="_blank"
              rel="noopener noreferrer"
              variant="underlinedLink"
            >
              here
            </Link>
            .
          </Heading>
        </Grid>
      </div>
      <div>
        <Grid flexWidth={12} justifyContent="flex-start" alignItems="stretch">
          <Grid flexWidth={7}>
            {this.state.loading ? (
              <div className="w-1-1" style={{ height: 300, position: 'relative' }}>
                <Grid type="column" justifyContent="center">
                  <Grid justifyContent="center">
                    <SpinLoader />
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div className="w-1-1">
                {this.state.rules.length > 0 ? (
                  <div className="w-1-1">
                    {filteredRules
                      .filter(rule =>
                        this.state.editRuleId
                          ? rule.rule_id === this.state.editRuleId
                          : !rule.existing,
                      )
                      .map((rule, _, rules) => {
                        const index = rule.id;

                        return (
                          <div
                            id={`dedup_rule_${index + 1}`}
                            key={index}
                            className={cx('main-rule-rectangle', {
                              'deduplication-rule-swap-animate': swappedRuleIndexes === index,
                            })}
                            ref={e => e && this.state.swappedRuleIndexes === index && e.focus()}
                            tabIndex={0}
                            style={{ paddingRight: 35 }}
                          >
                            <div className="clearfix" style={{ marginBottom: '30px' }}>
                              <div className="field-parent-div">
                                <Grid type="column" className="mb-20">
                                  <Text variant="secondary" className="mb-10">
                                    Rule Description
                                  </Text>
                                  <InputBlock
                                    height="50px"
                                    fontSize="12px"
                                    placeholder="Example: This rule will help you [add rule action] to [add need for the rule]"
                                    value={rule.description}
                                    onChange={this.onDescriptionChange(index)}
                                  />
                                </Grid>
                                <Grid justifyContent="space-between" className="mb-10">
                                  <Grid alignItems="center">
                                    <Text variant="secondary">Deduplication Rule</Text>
                                    &nbsp;
                                    {rule.is_basic && (
                                      <Tooltip
                                        padding="8px"
                                        offset={{ top: '0px' }}
                                        width="300px"
                                        label="You can either write your Deduplication rule expression in the Deduplication Rule text box or use the GUI below
                                        to create simpler rules. What is input in the GUI will also be shown above in the text box as an expression"
                                      >
                                        <Image
                                          src="/icons/info_black.svg"
                                          alt="more info"
                                          height={4}
                                        />
                                      </Tooltip>
                                    )}
                                  </Grid>
                                  {rule.is_basic && (
                                    <TextButton
                                      buttonType="ghost"
                                      onClick={this.openWarning(index)}
                                      disabled={!this.state.alertSource}
                                    >
                                      <Text variant="primartFitContent">Edit</Text>
                                      &nbsp;
                                      <img
                                        style={{ height: '1rem' }}
                                        src="/icons/blues/edit.svg"
                                        alt="edit"
                                      />
                                    </TextButton>
                                  )}
                                </Grid>
                                <InputBlock
                                  type="text"
                                  id={`deduplication-rule-${index}`}
                                  name="deduplication rules input"
                                  placeholder={
                                    !rule.is_basic
                                      ? 'Enter deduplication rule'
                                      : 'Expression corresponding to conditions'
                                  }
                                  value={
                                    !rule.is_basic
                                      ? rule.expression
                                      : generateExpression(
                                          rule.basic_expression,
                                          this.getCurrentEvent(),
                                        )
                                  }
                                  onChange={this.onRuleExpressionChange(index)}
                                  height="50px"
                                  fontSize="12px"
                                  disabled={rule.is_basic}
                                  error={this.state.errors.includes(`rules[${index}].expression`)}
                                />
                              </div>
                            </div>

                            {rule.is_basic && (
                              <>
                                {rule.basic_expression.map((be, beIndex, beArray) => {
                                  return (
                                    <>
                                      <Grid justifyContent="space-between">
                                        <Grid>
                                          <Text
                                            m="auto"
                                            fontSize={14}
                                            height="24px"
                                            fontWeight={500}
                                          >
                                            If{' '}
                                          </Text>
                                          &nbsp;
                                          {!alertSource && (
                                            <Tooltip
                                              padding="8px"
                                              offset={{ top: '0px' }}
                                              width="max-content"
                                              label="Select an alert source"
                                            >
                                              <Image
                                                src="/icons/info_black.svg"
                                                alt="more info"
                                                height={4}
                                                mt="17px"
                                                ml="2px"
                                                mr="5px"
                                              />
                                            </Tooltip>
                                          )}
                                        </Grid>
                                        <Grid flexWidth={5}>
                                          <SelectBox
                                            hook={
                                              <Text
                                                fontSize={12}
                                                textAlign="left"
                                                overflow="auto"
                                                noOfLines={2}
                                              >
                                                {be.lhs ? be.lhs : 'Select Label'}
                                              </Text>
                                            }
                                            searchHookProps={{
                                              value:
                                                this.state.searchString[
                                                  `lhs_${index}_${beIndex}`
                                                ] || '',
                                              height: '24px',
                                              fontSize: '16px',
                                              onChange: this.setEntitySearch('lhs', index, beIndex),
                                            }}
                                            onValueChange={this.changeBasicExpressionLHS(
                                              index,
                                              beIndex,
                                            )}
                                            height="auto"
                                            maxHeight="200px"
                                            width="100%"
                                            maxWidth="100%"
                                            disabled={!this.state.alertSource}
                                            error={this.state.errors.includes(
                                              `rules[${index}].basic_expression[${beIndex}].lhs`,
                                            )}
                                          >
                                            {Object.keys(flattenedObject(this.getCurrentEvent()))
                                              .filter((v: string) =>
                                                v
                                                  .toLowerCase()
                                                  .includes(
                                                    (
                                                      this.state.searchString[
                                                        `lhs_${index}_${beIndex}`
                                                      ] || ''
                                                    ).toLowerCase(),
                                                  ),
                                              )
                                              .map((r: any, i) => {
                                                return (
                                                  <FocusBlock
                                                    height={'100%'}
                                                    key={i}
                                                    value={r}
                                                    isSelected={be.lhs === r}
                                                    style={{ fontSize: '12px' }}
                                                  >
                                                    <Text fontSize={12} textAlign="left">
                                                      {r}
                                                    </Text>
                                                  </FocusBlock>
                                                );
                                              })}
                                          </SelectBox>
                                        </Grid>
                                        <Grid flexWidth={3}>
                                          <SelectBox
                                            hook={
                                              <Text fontSize={12} textAlign="left">
                                                {be.op ? this._comparators.all[be.op] : 'Condition'}
                                              </Text>
                                            }
                                            onValueChange={this.changeBasicExpressionOperator(
                                              index,
                                              beIndex,
                                            )}
                                            height="auto"
                                            maxHeight="200px"
                                            width="100%"
                                            maxWidth="100%"
                                            error={this.state.errors.includes(
                                              `rules[${index}].basic_expression[${beIndex}].op`,
                                            )}
                                            disabled={!be.lhs || !this.state.alertSource}
                                          >
                                            {Object.keys(this.getComparators(be.lhs) || {}).map(
                                              v => {
                                                return (
                                                  <FocusBlock
                                                    key={v}
                                                    value={v}
                                                    isSelected={be.op === v}
                                                  >
                                                    <Text fontSize={12} textAlign="left">
                                                      {
                                                        this._comparators.all[
                                                          v as IRuleExpressionOperation
                                                        ]
                                                      }
                                                    </Text>
                                                  </FocusBlock>
                                                );
                                              },
                                            )}
                                          </SelectBox>
                                        </Grid>
                                        <Grid flexWidth={3} alignItems="center">
                                          {be.op === IRuleExpressionOperation.EOpFieldIs ? (
                                            <Grid className="dedupe-false-box">
                                              <Text fontSize={12} textAlign="left">
                                                {be.rhs}
                                              </Text>
                                            </Grid>
                                          ) : getLHSType(be.lhs, this.getCurrentEvent()) ===
                                            'boolean' ? (
                                            <SelectBox
                                              hook={
                                                <Text fontSize={12} textAlign="center">
                                                  {be.rhs === '' && 'Truth Value'}
                                                  {be.rhs === true && 'True'}
                                                  {be.rhs === false && 'False'}
                                                </Text>
                                              }
                                              onValueChange={this.changeBasicExpressionRHS(
                                                index,
                                                beIndex,
                                              )}
                                              height="auto"
                                              maxHeight="200px"
                                              width="100%"
                                              maxWidth="100%"
                                              error={this.state.errors.includes(
                                                `rules[${index}].basic_expression[${beIndex}].rhs`,
                                              )}
                                              disabled={!be.lhs || !this.state.alertSource}
                                            >
                                              {['True', 'False'].map(v => {
                                                return (
                                                  <FocusBlock
                                                    key={v}
                                                    value={v === 'True'}
                                                    isSelected={be.op === v}
                                                    style={{ textAlign: 'center' }}
                                                  >
                                                    <Text fontSize={12} textAlign="left">
                                                      {v}
                                                    </Text>
                                                  </FocusBlock>
                                                );
                                              })}
                                            </SelectBox>
                                          ) : (
                                            <InputBlock
                                              type={
                                                getLHSType(be.lhs, this.getCurrentEvent()) ===
                                                'number'
                                                  ? 'number'
                                                  : 'text'
                                              }
                                              id={`expression-${index}`}
                                              placeholder="Enter value"
                                              value={be.rhs}
                                              onChange={this.changeConditionInputBox(
                                                index,
                                                beIndex,
                                                'rhs',
                                              )}
                                              height="50px"
                                              fontSize="12px"
                                              error={this.state.errors.includes(
                                                `rules[${index}].basic_expression[${beIndex}].rhs`,
                                              )}
                                              disabled={!be.lhs || !this.state.alertSource}
                                            />
                                          )}
                                        </Grid>
                                        <Grid flexWidth={1}>
                                          <IconButton
                                            name="remove"
                                            onClick={this.removeCondition(index, beIndex)}
                                            base="32px"
                                            borderType="rounded"
                                            style={{ margin: 'auto' }}
                                          >
                                            <Text fontSize={12} height="24px" m="auto">
                                              <img src="/icons/remove.svg" alt="remove" />
                                            </Text>
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                      {beIndex < beArray.length - 1 && (
                                        <Grid
                                          justifyContent="center"
                                          style={{ margin: '2%', marginLeft: '10%' }}
                                        >
                                          <Text fontSize={14} height="24px" fontWeight={500}>
                                            AND
                                          </Text>
                                        </Grid>
                                      )}
                                    </>
                                  );
                                })}
                                <Grid justifyContent="flex-end" className="mt-10">
                                  <TextButton buttonType="ghost" onClick={this.addCondition(index)}>
                                    <Text variant="primartFitContent">+ Add Condition</Text>
                                  </TextButton>
                                </Grid>
                              </>
                            )}

                            <div className="clearfix">
                              <div className="field-parent-div">
                                <Grid>
                                  <Text
                                    fontSize={14}
                                    height="24px"
                                    fontWeight={500}
                                    className="mb-10"
                                  >
                                    Time
                                  </Text>
                                </Grid>
                                <Grid justifyContent="flex-start" alignItems="center">
                                  <Grid flexWidth={5} justifyContent="flex-start">
                                    <Grid flexWidth={6}>
                                      <InputBlock
                                        type="number"
                                        id={`deduplication-time-${index}`}
                                        name="deduplication_time_window"
                                        placeholder="N"
                                        value={rule.time_window.toString()}
                                        onChange={this.changeRuleInputBox(
                                          index,
                                          'time_window',
                                          'number',
                                        )}
                                        height="50px"
                                        fontSize="12px"
                                        width="100%"
                                        error={this.state.errors.includes(
                                          `rules[${index}].time_window`,
                                        )}
                                      />
                                    </Grid>
                                    <Grid flexWidth={5} className="ml-10">
                                      <SelectBox
                                        hook={
                                          <Text fontSize={12} textAlign="left">
                                            {`${rule.time_unit}(s)`}
                                          </Text>
                                        }
                                        onValueChange={this.changeRuleSelectBox(index, 'time_unit')}
                                        height="auto"
                                        maxHeight="200px"
                                        width="100%"
                                        maxWidth="100%"
                                      >
                                        {Object.values(IRuleTimeUnit).map(v => {
                                          return (
                                            <FocusBlock
                                              key={v}
                                              value={v}
                                              isSelected={rule.time_unit === v}
                                              style={{ textAlign: 'center' }}
                                            >
                                              <Text fontSize={12} textAlign="left">
                                                {`${v}(s)`}
                                              </Text>
                                            </FocusBlock>
                                          );
                                        })}
                                      </SelectBox>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>

                            <div className="clearfix" style={{ marginTop: '30px' }}>
                              <div>
                                <Grid>
                                  <label>
                                    <Checkbox
                                      checked={rule.dependency_deduplication}
                                      onChange={this.toggleDependencyDeduplication(index)}
                                    />
                                    <span style={{ marginLeft: '8px' }}>
                                      If this service and a service it depends on both have an
                                      incident, alert only once
                                    </span>
                                  </label>
                                </Grid>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <div className="error-block">{this.state.networkError}</div>
                  </div>
                ) : (
                  <div className="empty-state-container w-1-1" style={{ marginBottom: 15 }}>
                    <Image
                      src="/icons/empty-states/suppression rule.svg"
                      className="empty-state-img"
                      width={120}
                      alt="deduplicationnRule"
                    />
                    <Text className="empty-state-headline" mb={10} mt={20}>
                      No rules found
                    </Text>
                  </div>
                )}

                {this.state.errors.length > 0 && (
                  <div>
                    <ErrorBlock>Please provide value for all fields</ErrorBlock>
                  </div>
                )}
                <div>
                  <Button
                    onClick={this.save}
                    disabled={this.state.saving}
                    isLoading={this.state.saving}
                    loadingText="Save Rule"
                    variant="default"
                    size="sm"
                  >
                    Save Rule
                  </Button>
                </div>
              </div>
            )}
          </Grid>
          <Grid flexWidth={5} style={{ paddingLeft: 20 }}>
            {alertSourcesLoadState === 'loading' && (
              <Grid flexWidth={12} justifyContent="center">
                <div style={{ margin: '140px' }}>
                  <Grid justifyContent="center" alignItems="center">
                    <SpinLoader />
                    <small className="ml-10">Loading Alert Sources</small>
                  </Grid>
                </div>
              </Grid>
            )}

            {alertSourcesLoadState === 'error' && (
              <Grid flexWidth={12} justifyContent="center">
                <div style={{ margin: '159px' }}>
                  <Grid justifyContent="center" alignItems="center">
                    <ErrorBlock fontWeight={500} fontSize={14}>
                      {alertSourceErrorMessage}
                    </ErrorBlock>
                  </Grid>
                </div>
              </Grid>
            )}

            {alertSourcesLoadState === 'success' && (
              <div className="w-1-1 mb-20">
                <label className="item-box-tagline font-bold mb-10 block">Alert Source</label>
                <SelectBox
                  id="select-alert-source"
                  hook={
                    alertSource ? (
                      <Grid alignItems="center">
                        <div
                          className="mr-10"
                          style={{
                            borderRadius: '100%',
                            background: `${alertSource.isActive ? activeColor : inactiveColor}`,
                            height: 'fit-content',
                            width: 'fit-content',
                            border: 'none',

                            padding: '4px',
                            marginTop: '15px',
                            marginBottom: '15px',
                          }}
                        />
                        <div>{alertSource.type}</div>
                      </Grid>
                    ) : (
                      <Text fontSize={14} fontWeight={500}>
                        Please select an alert source
                      </Text>
                    )
                  }
                  searchHookProps={{
                    value: alertSourceSearch,
                    height: '24px',
                    fontSize: '16px',
                    onChange: this.onTextChange('alertSourceSearch'),
                  }}
                  onValueChange={this.onSelectChange}
                  height="auto"
                  maxHeight="200px"
                  width="100%"
                  maxWidth="100%"
                >
                  {this.alertSources
                    .filter((altSrc: IAlertSource) =>
                      alertSourceSearch
                        ? altSrc.type.toLowerCase().includes(alertSourceSearch.toLocaleLowerCase())
                        : true,
                    )
                    .map((altSrc: IAlertSource, index: number) => (
                      <FocusBlock
                        height={'100%'}
                        value={altSrc}
                        key={shortid.generate()}
                        isSelected={alertSource === altSrc}
                        style={{ fontSize: '12px' }}
                      >
                        <Grid alignItems="center">
                          <div
                            className="mr-10"
                            style={{
                              borderRadius: '100%',
                              background: `${altSrc.isActive ? activeColor : inactiveColor}`,
                              height: 'fit-content',
                              width: 'fit-content',
                              border: 'none',
                              padding: '4px',
                            }}
                          />
                          <Text className="item-box-tagline" style={{ margin: 0 }}>
                            {altSrc.type}
                          </Text>
                        </Grid>
                      </FocusBlock>
                    ))}
                </SelectBox>
                {this.state.eventState === 'loading' && (
                  <Grid flexWidth={12} justifyContent="center">
                    <div style={{ margin: '120px' }}>
                      <Grid justifyContent="center" alignItems="center">
                        <SpinLoader />
                        <small className="ml-10">Loading Event Payload</small>
                      </Grid>
                    </div>
                  </Grid>
                )}
                {this.state.eventState === 'noEvents' && (
                  <Grid flexWidth={12} justifyContent="center">
                    <div className="mt-20">
                      <Grid justifyContent="center" alignItems="center">
                        <Text className="ml-10">{`No events for ${alertSource?.type}`}</Text>
                      </Grid>
                    </div>
                  </Grid>
                )}
                {this.state.eventState === 'idle' && Object.keys(this.state.event).length > 0 && (
                  <div className="w-1-1 mt-10" style={{ position: 'sticky', top: 20 }}>
                    <JSONCodeBlock
                      code={this.state.event}
                      enableSearch={true}
                      shellProps={{
                        minHeight: '600px',
                        minWidth: 'calc(100% - 15px)',
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <DialogModalFrame
        id="warning"
        onClose={this.closeWarning(false)}
        style={{ width: '60%', margin: 'auto' }}
        onClick={e => e.stopPropagation()}
      >
        {this.state.warningIndex !== null && (
          <Grid type="column">
            <Grid className="mb-20">
              <Heading height={35} fontSize={18} fontWeight={450}>
                Edit Tagging Expression
              </Heading>
            </Grid>

            <Grid className="mb-20">
              <Text>
                Editing the rule will disable the GUI below for this rule. Click "
                <span className="font-bold">Proceed</span>" to continue
              </Text>
            </Grid>

            <Grid justifyContent="flex-end">
              <TextButton width="25%" onClick={this.closeWarning(true)}>
                <Text variant="proceed">Proceed</Text>
              </TextButton>
            </Grid>
          </Grid>
        )}
      </DialogModalFrame>
      {
        <UpgradePlanModal
          hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
          message={BillingService.getMessage(limit, 'deduplication-rules', this.props)}
          header={BillingService.getHeader(limit, 'deduplication-rules', this.props)}
          onCancel={() => this.setState({ showUpgradeModal: false })}
          onUpgrade={() => this.setState({ showUpgradeModal: false })}
          showModal={this.state.showUpgradeModal}
        />
      }
    </div>
  );
}

export default render;
