import axios from 'axios';
import { formatRFC3339 } from 'date-fns';
import { stringify } from 'query-string';

import { API } from '../api';
import {
  CUSTOM_PAYLOAD_TEMPLATE_TYPE,
  EVENT_TEMPLATE_TYPE,
  IEventHookServiceEntity,
  IEventTestWebhookPayload,
  IEventWebHook,
  IEventWebHookLogs,
  IEventWebhookValidatePayload,
  IManualEventWebhookByTeams,
  PAYLOAD_VALIDATION_RESPONSE,
  WEBHOOK_LIST_RESPONSE,
} from '../interfaces/IEventWebHooks';

class EventWebhookHooksService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/extensions/event-webhooks`;

  getServices = () => axios.get<{ data: IEventHookServiceEntity[] }>(`${this._api}/services`);

  getAll = () => axios.get<{ data: IEventWebHook[] }>(this._api);
  getPaginatedList = (pageOffset: number, pageLimit: number) =>
    axios.get<{
      data: WEBHOOK_LIST_RESPONSE;
    }>(`${this._api}?offset=${pageOffset}&page-limit=${pageLimit}`);

  get = (id: string) => axios.get<{ data: IEventWebHook }>(`${this._api}/${id}`);

  getLogs = (from: Date, to: Date, offset: number, limit: number, event_webhook_id: string) =>
    axios.get<IEventWebHookLogs>(
      `${this._api}/logs?${stringify({
        offset,
        limit,
        event_webhook_id,
        from: formatRFC3339(from),
        to: formatRFC3339(to),
      })}`,
    );

  triggerWebhookManually = (incidentId: string, eventWebhookID: string) =>
    axios.post<{ data: IEventWebHook }>(
      `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${incidentId}/actions/webhook/${eventWebhookID}`,
    );

  getManualWebhooksByTeam = () =>
    axios.get<{ data: IManualEventWebhookByTeams[] }>(
      `${this._api}/manual-webhooks-by-teams?team-id=${API.config.teamId}`,
    );

  create = (data: Pick<IEventWebHook, 'name'>) =>
    axios.post<{ data: IEventWebHook }>(this._api, data);

  update = (id: string, data: Partial<IEventWebHook>) =>
    axios.put<{ data: IEventWebHook }>(`${this._api}/${id}`, data);

  delete = (id: string) => axios.delete<{ data: IEventWebHook }>(`${this._api}/${id}`);

  getCustomPayloadTemplates = () =>
    axios.get<{ data: CUSTOM_PAYLOAD_TEMPLATE_TYPE[] }>(`${this._api}/custom-payload-templates`);

  getEventTemplates = () =>
    axios.get<{ data: EVENT_TEMPLATE_TYPE[] }>(`${this._api}/event-templates`);

  validatePayload = (data: IEventWebhookValidatePayload) =>
    axios.post<{ data: PAYLOAD_VALIDATION_RESPONSE[] }>(`${this._api}/validate-webhook`, data);

  testWebhook = (data: IEventTestWebhookPayload) =>
    axios.post<{ data: string }>(`${this._api}/test-webhook`, data);
}

export default EventWebhookHooksService;
