import { IAppState } from 'core/interfaces/IAppState';
import { useSelector } from 'react-redux';
import { EntityRecord, entity } from './types';

const useCreateEntityMap = (): EntityRecord => {
  const organization = useSelector((state: IAppState) => state.organization);
  return {
    users: organization.users.u.reduce((c: Record<string, entity>, u) => {
      c[u.id] = {
        name: `${u.first_name} ${u.last_name}`,
        _id: u.id,
        type: 'users',
        encoder: '@U',
      };
      return c;
    }, {}),
    squads: organization.squads.s.reduce((c: Record<string, entity>, squad) => {
      c[squad.id] = {
        name: `${squad.name}`,
        teamId: squad.owner.id,
        _id: squad.id,
        type: 'squads',
        encoder: '@SQ',
      };
      return c;
    }, {}),
    teams: [organization.selectedTeam].reduce((c: Record<string, entity>, team) => {
      c[team.teamId] = {
        name: `${team.team?.name}`,
        _id: team.teamId,
        type: 'teams',
        encoder: '@T',
      };
      return c;
    }, {}),

    stakeholders: organization.stakeholders.stk.reduce((c: Record<string, entity>, stakeholder) => {
      c[stakeholder.id] = {
        name: `${stakeholder.name}`,
        teamId: stakeholder.owner.id,
        _id: `${stakeholder.id}`,
        type: 'stakeholders',
        encoder: '@SG',
      };
      return c;
    }, {}),
  };
};

const transformMessage = (message: string, entityMap: EntityRecord): string => {
  // replace encoded user id to link
  const userReplaced = message.replace(/<@U\w{24}>/gm, (e: string) => {
    const userId = e.replace(/(<@U)|(>)/gm, '');
    return `[](/user/${userId})`;
  });
  // replace encoded squad id to link
  const squadReplaced = userReplaced.replace(/<@SQ\w{24}>/gm, (e: string) => {
    const squadId = e.replace(/(<@SQ)|(>)/gm, '');
    const { teamId, name } = entityMap.squads[squadId];
    const uriEncodedName = encodeURIComponent(name);
    return `[](/settings/teams/${teamId}/squads?name=${uriEncodedName})`;
  });
  // replace encoded team id to link
  const teamReplaced = squadReplaced.replace(/<@T\w{24}>/gm, (e: string) => {
    const teamId = e.replace(/(<@T)|(>)/gm, '');
    return `[](/settings/teams/${teamId})`;
  });

  // replace encoded stakeholder id to link
  const stakeholderReplaced = teamReplaced.replace(/<@SG[a-zA-Z0-9-]+>/gm, (e: string) => {
    const stakeholderId = e.replace(/(<@SG)|(>)/gm, '');
    const stakeHolderName = entityMap.stakeholders[stakeholderId].name;
    const teamId = entityMap.stakeholders[stakeholderId].teamId;
    return `[${stakeHolderName}](/settings/teams/${teamId}/stakeholder)`;
  });

  return stakeholderReplaced;
};

const useGetOnCallUserSet = () => {
  const organization = useSelector((state: IAppState) => state.organization);
  const onCallUsersSet = new Set<string>();
  Object.values(organization.calendar.onCall).forEach(users =>
    users.forEach(u => onCallUsersSet.add(u.id)),
  );
  return onCallUsersSet;
};

export { useCreateEntityMap, transformMessage, useGetOnCallUserSet };
