import { Accordion } from 'library/molecules/Accordion';
import FilterForm from './FilterForm';

const FilterContainer = () => {
  return (
    <Accordion
      allowToggle
      items={[
        {
          title: 'Define Trigger and it’s Filters',
          content: <FilterForm />,
        },
      ]}
    />
  );
};

export default FilterContainer;
