import {
  Box,
  Flex,
  Table as CTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { LibraryIcons } from 'library/icons';
import { isFunction, isBoolean } from 'lodash';
import { cloneElement, useState } from 'react';
import { useGlobalFilter, usePagination, useRowSelect, useTable, useSortBy } from 'react-table';

import { CircularProgress } from '../../atoms';
import PaginationBlock from './Pagination';
import { CursorPaginationBlock } from './Pagination/cursor';
import { TableProps } from './types';

const Fallback = 'No records found';
const emptyFunc = () => {};

export const Table = <T extends Record<string, unknown>>({
  columns = [],
  data = [],

  pageIndex = 0,
  pageSize = 5,
  isLoading = false,
  isPaginationDisabled = false,
  clientPagination,
  cursorPagination = false,
  hasNext,
  hasPrev,
  onNext,
  onPrev,

  totalCount = 0,
  onPageChange,
  headerStyles = {},
  bodyStyles = {},
  containerStyles = {},
  paginationStyles = {},
  hoverView,
  hoverViewStyling,
  hidePagination = false,
  emptyRowsFallbackRender = Fallback,
  initialSortByState = [],
  maxPageSize,
  onSortChange,
  canToggleColumnSort = true,
  sortedColumnId,
  additionalHeaderStyles,
  additionalTableStyles,
}: TableProps<T>) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: !clientPagination,
      autoResetPage: false,
      disableSortRemove: true,
      defaultCanSort: false,
      initialState: {
        pageSize: pageSize,
        pageIndex: pageIndex,
        sortBy: initialSortByState,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);

  const size = useBreakpointValue({ base: 'md', lg: 'lg' });
  const styles = useMultiStyleConfig('Table', {
    headerStyles,
    bodyStyles,
    size,
  });

  if (
    cursorPagination &&
    !(isFunction(onNext) && isFunction(onPrev) && isBoolean(hasPrev) && isBoolean(hasNext))
  ) {
    return <></>;
  }
  return (
    <Box w="inherit" h="inherit">
      <Box overflowY="auto" {...containerStyles}>
        <CTable
          {...tableInstance.getTableProps()}
          sx={{
            ...styles.table,
            ...additionalTableStyles,
          }}
        >
          <Thead>
            {tableInstance.headerGroups.map((headerGroup, i) => (
              <Tr
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
              >
                {headerGroup.headers.map((column, index) => {
                  const showSortableColumnHoveredState =
                    column.id === hoveredColumn && column.canSort;
                  const isColSorted = column.id === sortedColumnId;
                  return (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      {...(!canToggleColumnSort ? { title: '' } : {})}
                      key={column.id + index}
                      sx={{
                        ...styles.header,
                        ...additionalHeaderStyles,
                        ...(column.canSort && canToggleColumnSort
                          ? {
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              lineHeight: 'inherit',
                            }
                          : {}),
                        ...(showSortableColumnHoveredState
                          ? { background: 'var(--chakra-colors-secondary-200) !important' }
                          : {}),
                      }}
                      onMouseEnter={() => setHoveredColumn(column.id)}
                      onMouseLeave={() => setHoveredColumn(null)}
                      minW={column.minWidth}
                      onClick={() => {
                        if (column.canSort && !isLoading) {
                          onSortChange && onSortChange(column);
                          canToggleColumnSort && column.toggleSortBy();
                        }
                      }}
                    >
                      {column.render('Header')}
                      {column.canSort ? (
                        column.isSortedDesc || column.sortDescFirst ? (
                          <LibraryIcons.ArrowDownIcon
                            ml={2}
                            w={4}
                            h={4}
                            visibility={
                              column.isSorted || showSortableColumnHoveredState || isColSorted
                                ? 'visible'
                                : 'hidden'
                            }
                          />
                        ) : (
                          <LibraryIcons.ArrowUpIcon
                            ml={2}
                            w={4}
                            h={4}
                            visibility={
                              column.isSorted || showSortableColumnHoveredState || isColSorted
                                ? 'visible'
                                : 'hidden'
                            }
                          />
                        )
                      ) : null}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          <Tbody {...tableInstance.getTableBodyProps()} pos="relative">
            {isLoading && (
              <Tr
                pos="absolute"
                h="full"
                w="full"
                zIndex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Td
                  colSpan={columns.length}
                  sx={{
                    ...styles.bodyCell,
                    textAlign: 'center',
                    borderBottom: 'none',
                    boxShadow: 'none',
                  }}
                >
                  <Flex justifyContent={'center'} alignItems={'center'} m={3}>
                    <CircularProgress isIndeterminate />
                  </Flex>
                </Td>
              </Tr>
            )}

            {!!tableInstance.page.length &&
              tableInstance.page.map(row => {
                tableInstance.prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={row.id}
                    onMouseEnter={() => {
                      hoverView && setHoveredRow(row.id);
                    }}
                    onMouseLeave={() => hoverView && setHoveredRow(null)}
                    bgColor={hoverView && hoveredRow === row.id ? 'primary.200' : 'brand.white'}
                    opacity={isLoading ? 0.5 : 1}
                  >
                    {row.cells.map(cell => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={`${cell.column.id}`}
                          sx={{ ...styles.bodyCell }}
                        >
                          {cell.render('Cell')}
                        </Td>
                      );
                    })}
                    {/* Show this view on hover */}
                    {hoverView && hoveredRow === row.id ? (
                      <Td
                        p={0}
                        position={hoverView && hoveredRow === row.id ? 'relative' : 'static'}
                      >
                        <Flex
                          position="absolute"
                          right={hoverViewStyling?.right ?? '3rem'}
                          // table cells have 6px vertical padding
                          top="12px"
                          bgColor="primary.200"
                        >
                          {cloneElement(hoverView, {
                            rowData: { row },
                            onMouseEnter: () => {
                              setHoveredRow(row.id);
                            },
                          })}
                        </Flex>
                      </Td>
                    ) : null}
                  </Tr>
                );
              })}

            {!isLoading && !tableInstance.page.length && (
              <Tr>
                <Td
                  colSpan={columns.length}
                  sx={{
                    ...styles.bodyCell,
                    textAlign: 'center',
                    borderBottom: 'none',
                    boxShadow: 'none',
                    p: 4,
                  }}
                >
                  {emptyRowsFallbackRender}
                </Td>
              </Tr>
            )}
          </Tbody>
        </CTable>
      </Box>
      {/* pagination hidden for incident merge drawer table */}
      {hidePagination ? null : cursorPagination ? (
        <CursorPaginationBlock
          style={paginationStyles}
          loading={isLoading}
          disabled={isPaginationDisabled || isLoading}
          nextPage={onNext ?? emptyFunc}
          previousPage={onPrev ?? emptyFunc}
          canNextPage={!!hasNext}
          canPreviousPage={!!hasPrev}
          pageSize={pageSize}
          setPageSize={(size: number) => onPageChange(0, size)}
          maxPageSize={maxPageSize}
        />
      ) : (
        <PaginationBlock
          style={paginationStyles}
          loading={isLoading}
          disabled={isPaginationDisabled || isLoading}
          nextPage={
            clientPagination ? tableInstance.nextPage : () => onPageChange(pageIndex + 1, pageSize)
          }
          previousPage={
            clientPagination
              ? tableInstance.previousPage
              : () => onPageChange(pageIndex - 1, pageSize)
          }
          canNextPage={
            clientPagination
              ? tableInstance.canNextPage
              : pageIndex < Math.floor(totalCount / pageSize)
          }
          canPreviousPage={clientPagination ? tableInstance.canPreviousPage : pageIndex !== 0}
          pageIndex={clientPagination ? tableInstance.state.pageIndex : pageIndex}
          pageSize={clientPagination ? tableInstance.state.pageSize : pageSize}
          setPageSize={
            clientPagination
              ? tableInstance.setPageSize
              : (size: number) => onPageChange(pageIndex, size)
          }
          gotoPage={
            clientPagination
              ? tableInstance.gotoPage
              : (pageNo: number) => onPageChange(pageNo, pageSize)
          }
          totalCount={totalCount}
          pageCount={clientPagination ? tableInstance.pageCount : Math.ceil(totalCount / pageSize)}
          rowsCount={tableInstance.length}
          maxPageSize={maxPageSize}
        />
      )}
    </Box>
  );
};
