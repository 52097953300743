import { IconButton } from '@chakra-ui/react';
import { CustomDrawerComponent } from 'components/chakra/Drawer';
import { Filter } from 'icons';
import { DropdownOption, Option, Owner } from '../../owner-filters/hooks/useEntityOwnerFilter';
import { SelectedFilterTag } from '../../owner-filters/SelectedFilterTags';
import ServiceFilters from '../../service-catalog/service.home/service.list/ServiceFilters';

export type ClickActiveFilterTagType = (type: Owner, id: string) => void;
interface Props {
  setOpenDrawer: (openDrawer: boolean) => void;
  openDrawer: boolean;
  onCancel: () => void;
  ownerFilterSelected: boolean;
  onClickApplyFilters: () => void;
  onClearFilters: () => void;
  dropdownOptions: DropdownOption[];
  onChangeHandler: (values: Option) => void;
  filterTags: SelectedFilterTag[];
  handleSelectedFilterTagClick: (type: Owner, id: string) => void;
  filtersApplied: boolean;
}

export function EPFilter(props: Props) {
  return (
    <>
      <IconButton
        aria-label="Filter escalation policy"
        position="absolute"
        marginLeft={5}
        bgColor="#edf2f7"
        icon={<Filter style={{ fill: '#2D3748', stroke: '#2D3748' }} />}
        onClick={() => props.setOpenDrawer(!props.openDrawer)}
      />

      {props.openDrawer && (
        <CustomDrawerComponent
          isOpen={props.openDrawer}
          onClose={props.onCancel}
          title={<h1>Filter</h1>}
          disableBodyPadding={true}
          size="sm"
        >
          <>
            <ServiceFilters
              {...{
                filtersSelected: props.ownerFilterSelected,
                onClickApplyFilters: props.onClickApplyFilters,
                onCancel: props.onCancel,
                onClearFilters: props.onClearFilters,
                dropdownOptions: props.dropdownOptions,
                onChangeHandler: props.onChangeHandler,
                filterTags: props.filterTags,
                handleSelectedFilterTagClick: props.handleSelectedFilterTagClick,
                filtersApplied: props.filtersApplied,
                filterName: 'Escalation Policy Owner',
              }}
            />
          </>
        </CustomDrawerComponent>
      )}
    </>
  );
}
