import {
  ChakraProvider,
  Text,
  Box,
  DrawerFooter,
  Button,
  extendTheme,
  Spinner,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { UseMutationResult } from 'react-query';
import { CustomDrawerComponent } from 'components/chakra/Drawer';
import { fontTheme } from 'library/theme';
import { Tooltip } from 'library/atoms';
import { TooltipTheme } from 'library/theme/Tooltip.theme';
import { THEME_COLORS } from 'library/theme/colors';
import { THEME_COLORS as THEME } from 'library/theme/colors';
import ServiceNowConfiguration from './servicenow_configuration';
import { useSaveServiceNowConfig, useUpdateServiceNowConfig } from './hooks/saveServiceNowConfig';
import { SaveConfiguration, sanitizeDomain, initialConfiguration } from './helpers';

interface Props {
  onClose: (isDirty: boolean) => void;
  isOpen: boolean;
  serviceNowStatus: string;
}

const theme = extendTheme({
  colors: THEME,
  components: {
    Tooltip: TooltipTheme,
  },
});

function ServiceNow({ onClose, isOpen, serviceNowStatus }: Props) {
  const [saveConfiguration, setSaveConfiguration] = useState<SaveConfiguration>({
    is_enabled: true,
    ...initialConfiguration,
  });
  const [wasEnabled, setWasEnabled] = useState<boolean | null>(null);
  const [isEnabled, setIsEnabled] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const handleModalClose = () => onClose(false);
  const saveConfigMutation = useSaveServiceNowConfig(handleModalClose);
  const updateConfigMutation = useUpdateServiceNowConfig(wasEnabled ?? false, handleModalClose);

  const saveOrUpdateConfig = (mutation: UseMutationResult<any, any, any>) => {
    mutation.mutate({
      ...saveConfiguration,
      servicenow_domain: sanitizeDomain(saveConfiguration.servicenow_domain),
    });
  };

  const handleClick = () => {
    saveConfiguration.id === 0
      ? saveOrUpdateConfig(saveConfigMutation)
      : saveOrUpdateConfig(updateConfigMutation);
  };

  const onGetConfiguration = (config: SaveConfiguration) => setSaveConfiguration(config);

  return (
    <ChakraProvider theme={extendTheme(theme, fontTheme)} portalZIndex={40}>
      <CustomDrawerComponent
        isOpen={isOpen}
        onClose={() => onClose(hasChanged)}
        disableBodyPadding
        title={
          <Box>
            <Text fontSize="3xl">ServiceNow Integration</Text>
            <Text fontSize="md" color={THEME_COLORS.secondary[1000]}>
              Effortlessly integrate Servicenow with Squadcast. Learn more{' '}
              <a
                href="#"
                style={{
                  color: '#1B7FF1',
                  textDecoration: 'underline',
                  lineHeight: '1.5',
                }}
              >
                here
              </a>
            </Text>
          </Box>
        }
      >
        <Box maxH={`calc(100% - 73px)`} overflowY="auto">
          <ServiceNowConfiguration
            onSaveConfiguration={onGetConfiguration}
            setIsButtonEnabled={setIsEnabled}
            serviceNowStatus={serviceNowStatus}
            setHasChanged={setHasChanged}
            setWasEnabled={setWasEnabled}
          />
        </Box>
        <DrawerFooter
          justifyContent="flex-start"
          borderTopWidth="1px"
          position="absolute"
          bottom={0}
          width="100%"
        >
          <Tooltip
            placement="top"
            label={
              !isEnabled ? 'You need to test your connection before saving the integration' : ''
            }
          >
            <div>
              <Button
                mr={5}
                colorScheme="blue"
                onClick={handleClick}
                isDisabled={
                  !isEnabled || saveConfigMutation.isLoading || updateConfigMutation.isLoading
                }
                padding="9px"
                fontSize="sm"
                borderRadius="3px"
                width={
                  saveConfigMutation.isLoading || updateConfigMutation.isLoading ? '70px' : 'auto'
                }
              >
                {saveConfigMutation.isLoading || updateConfigMutation.isLoading ? (
                  <Spinner size="sm" />
                ) : saveConfiguration.id === 0 ? (
                  'Save'
                ) : (
                  'Update'
                )}
              </Button>
            </div>
          </Tooltip>
          <Button
            variant="outline"
            onClick={() => onClose(hasChanged)}
            padding="9px"
            fontSize="sm"
            borderRadius="3px"
            color={THEME_COLORS.brand.accentBlue}
            borderColor={THEME_COLORS.primary[500]}
          >
            Cancel
          </Button>
        </DrawerFooter>
      </CustomDrawerComponent>
    </ChakraProvider>
  );
}

export default ServiceNow;
