import * as React from 'react';
import { SVGProps } from 'react';

const SvgCloudtrailLogs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="cloudtrail-logs_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.cloudtrail-logs_svg__st0,.cloudtrail-logs_svg__st1,.cloudtrail-logs_svg__st2,.cloudtrail-logs_svg__st3{fill-rule:evenodd;clip-rule:evenodd;fill:#3c4929}.cloudtrail-logs_svg__st1,.cloudtrail-logs_svg__st2,.cloudtrail-logs_svg__st3{fill:#b7ca9d}.cloudtrail-logs_svg__st2,.cloudtrail-logs_svg__st3{fill:#4b612c}.cloudtrail-logs_svg__st3{fill:#759c3e}'
      }
    </style>
    <symbol id="cloudtrail-logs_svg__A" viewBox="-35 -40 70 80">
      <path className="cloudtrail-logs_svg__st0" d="m-25-4.5-2.3.2-7.7-.3 2.1-10L-25-4.5z" />
      <path className="cloudtrail-logs_svg__st1" d="m-25 4.8-2.3-.2-7.7.3 2.1 10L-25 4.8z" />
      <path className="cloudtrail-logs_svg__st2" d="m-32.9 5.1-2.1-.2v9.4l2.1.6V5.1z" />
      <path className="cloudtrail-logs_svg__st3" d="m-25 14-7.8 1V5.2l7.8-.3V14z" />
      <path className="cloudtrail-logs_svg__st1" d="m-10.7 5.6-6.3-.5-11 .5 5.9 18 11.4-18z" />
      <path className="cloudtrail-logs_svg__st2" d="m-32.9-4.8-2.1.2V-14l2.1-.6v9.8z" />
      <path className="cloudtrail-logs_svg__st3" d="m-25-13.7-7.8-1v9.8l7.8.3v-9.1z" />
      <path className="cloudtrail-logs_svg__st0" d="m-10.7-5.3-6.3.5-11-.5 5.9-17.9 11.4 17.9z" />
      <path className="cloudtrail-logs_svg__st2" d="m-28-21.4 5.9-1.9v17.4l-5.9.6v-16.1z" />
      <path className="cloudtrail-logs_svg__st3" d="m-10.7-20.9-11.4-2.4v17.4l11.4.6v-15.6z" />
      <path className="cloudtrail-logs_svg__st2" d="m-28 21.6 5.9 2.1V6.2l-5.9-.5v15.9z" />
      <path className="cloudtrail-logs_svg__st3" d="m-10.7 21.4-11.4 2.2V6.2l11.4-.6v15.8z" />
      <path className="cloudtrail-logs_svg__st2" d="M-14.8 33 0 40 34.7 0 0-40l-14.8 7v66z" />
      <path className="cloudtrail-logs_svg__st3" d="M35 23.4 0 40v-80l35 16.6v46.8z" />
    </symbol>
    <use
      xlinkHref="#cloudtrail-logs_svg__A"
      width={70}
      height={80}
      x={-35}
      y={-40}
      transform="matrix(.25 0 0 .25 10 10)"
      style={{
        overflow: 'visible',
      }}
    />
  </svg>
);

export default SvgCloudtrailLogs;
