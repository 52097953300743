import { Link } from 'react-router-dom';
import { Box } from '@chakra-ui/layout';
import { truncate } from 'core/helpers/stringUtils';
import { truncuateTextLength } from 'views/main/organization/incidentDetails/constants';
import { STATUSPAGE_V2_PATH } from 'views/main/routes/routes';
import { THEME_COLORS } from 'library/theme/colors';
import { IWorkflowMessages } from 'views/main/organization/navigation-flows/helpers';

const workflowLogActionMap = {
  wf_send_email_action_triggered: ({
    log,
    entityComponent,
    logMessageActivity,
  }: IWorkflowMessages) => (
    <>
      {logMessageActivity} {entityComponent}
      <br />
      <span>Subject: {log.additionalInfo!.subject} </span>
      <span>To: {log.additionalInfo!.to!.map(data => data)} </span>
    </>
  ),

  wf_http_call_action_triggered: ({
    log,
    entityComponent,
    logMessageActivity,
  }: IWorkflowMessages) => (
    <>
      {logMessageActivity} {entityComponent}
      <br />
      <span>url: {log.additionalInfo!.url} </span>
    </>
  ),

  wf_create_slack_channel_action_triggered: ({
    log,
    entityComponent,
  }: Omit<IWorkflowMessages, 'logMessageActivity'>) => (
    <>
      {entityComponent} <span>{'created' + log.reason.split(' created')[1]} </span>
    </>
  ),

  wf_message_slack_channel_action_triggered: ({
    log,
    entityComponent,
  }: Omit<IWorkflowMessages, 'logMessageActivity'>) => (
    <>
      {entityComponent} sent a direct message to a slack channel.
      <br />
      <span>Message: {truncate(log.additionalInfo!.message ?? '', truncuateTextLength)} </span>
    </>
  ),

  wf_message_slack_user_action_triggered: ({
    log,
    entityComponent,
  }: Omit<IWorkflowMessages, 'logMessageActivity'>) => (
    <>
      {entityComponent} sent a direct message to a slack user.
      <br />
      <span>Message: {truncate(log.additionalInfo!.message ?? '', truncuateTextLength)} </span>
    </>
  ),

  wf_archive_slack_channel_action_triggered: ({
    entityComponent,
  }: Pick<IWorkflowMessages, 'entityComponent'>) => (
    <>
      {entityComponent} archived the Incident Slack channel.
      <br />
    </>
  ),

  wf_create_jira_issue_action_triggered: ({
    entityComponent,
  }: Pick<IWorkflowMessages, 'entityComponent'>) => <>{entityComponent} created a Jira ticket.</>,
  wf_message_msteams_channel_action_triggered: ({
    log,
    entityComponent,
  }: Omit<IWorkflowMessages, 'logMessageActivity'>) => (
    <>
      {entityComponent} sent a message to a Microsoft Teams channel.
      <br />
      <span>Message: {truncate(log.additionalInfo!.message ?? '', truncuateTextLength)} </span>
    </>
  ),

  wf_message_msteams_user_action_triggered: ({
    log,
    entityComponent,
  }: Omit<IWorkflowMessages, 'logMessageActivity'>) => (
    <>
      {entityComponent} sent a Direct message to a Microsoft Teams user.
      <br />
      <span>Message: {truncate(log.additionalInfo!.message ?? '', truncuateTextLength)} </span>
    </>
  ),

  wf_add_status_page_issue_action_triggered: ({
    log,
    entityComponent,
  }: Omit<IWorkflowMessages, 'logMessageActivity'>) => (
    <Box>
      {entityComponent} added a Statuspage update: &nbsp;
      <Link
        style={{ color: THEME_COLORS.brand.blue }}
        to={`${STATUSPAGE_V2_PATH}/${log.additionalInfo?.status_page_request?.StatusPageID}`}
      >
        [{log.additionalInfo?.status_page_request?.IssueTitle}]
      </Link>
    </Box>
  ),
};

export default workflowLogActionMap;
