import { IAppState } from 'core/interfaces/IAppState';
import { Theme } from 'uie/components';
import { FollowLinkOnNewTab, JiraCloudIcon, MoreVerticalIcon } from 'icons';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Flex,
  Heading,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { IJiraAccDetail } from '../../../../../../../../core/interfaces/IJiraNew';
import { stringShortener } from 'core/helpers/stringUtils';

interface Props {
  account: IJiraAccDetail;
  onDeleteAccount: (accountId: string) => void;
  onEditAccount: (accountId: IJiraAccDetail) => void;
}

function JiraCloudExtensionDetailsBox(props: Props) {
  //react query hooks
  const { theme: squadcastTheme } = Theme;
  const userTeams = useSelector((state: IAppState) => state.organization.teams);

  const teamsOnExtension = React.useMemo(() => {
    return props.account.is_all_teams_configured
      ? userTeams.t.map((el, index) => el.name)
      : props.account.team_ids?.map(team => {
          return userTeams.t.find(t => t.id === team.team_id)?.name;
        });
  }, [props.account.team_ids, userTeams.t, props.account.is_all_teams_configured]);

  const startingTeams = teamsOnExtension?.slice(0, 2) || [];

  const remainingTeams = teamsOnExtension?.slice(2) || [];

  return (
    <Box paddingY="10px" border={'1px solid #DADADA'} borderRadius="4px">
      <Box display="flex" flexDir="row" justifyContent="space-between">
        <Stack direction={'row'} align={'center'} mb={3}>
          <JiraCloudIcon />
          <Stack direction={'column'}>
            <Heading size={'md'}>
              <Tooltip label={props.account.account_name}>
                {stringShortener(props.account.account_name, 26)}
              </Tooltip>
            </Heading>
            <Box display="flex" flexDir="row" gap={1}>
              {startingTeams.map((team, index) => {
                return (
                  <Tooltip label={team} key={index}>
                    <>
                      <Text>{stringShortener(team, 10)},</Text>{' '}
                    </>
                  </Tooltip>
                );
              })}
              <Popover>
                <PopoverTrigger>
                  <Box as="span" color="primary.default" cursor="pointer">
                    {remainingTeams?.length > 0 ? `+${remainingTeams.length}` : ''}
                  </Box>
                </PopoverTrigger>
                <PopoverContent width={'3xs'}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    <Box display={'flex'} flexDirection="column" gap={2}>
                      {remainingTeams?.map((el, ind) => {
                        return (
                          <Text key={ind} padding={'0.5'}>
                            {el}
                            {'  '}
                          </Text>
                        );
                      })}
                    </Box>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          </Stack>
        </Stack>
        <Popover size="sm" matchWidth>
          <PopoverTrigger>
            <IconButton
              variant=" unstyled"
              _focus={{ outline: 'none' }}
              aria-label="More"
              icon={<MoreVerticalIcon />}
            />
          </PopoverTrigger>
          <PopoverContent _focus={{ outline: 'none' }} width="15" bg="whiteAlpha.900">
            <PopoverBody padding={0}>
              {['Edit', 'Delete'].map((item, i) => (
                <Text
                  key={i}
                  fontSize={16}
                  pr={20}
                  pl={4}
                  py={2}
                  fontWeight={400}
                  cursor="pointer"
                  textAlign="start"
                  _hover={{ background: 'AliceBlue' }}
                  onClick={() => {
                    if (item === 'Edit') {
                      props.onEditAccount(props.account);
                    } else {
                      props.onDeleteAccount(props.account.id.toString());
                    }
                  }}
                >
                  {item}
                </Text>
              ))}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>

      {props.account.account_link ? (
        <Flex direction="row" justifyContent="center">
          <a
            target="_blank"
            href={props.account.account_link}
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: '0  auto',
              textDecoration: 'none',
              color: squadcastTheme.primary.default,
              background: squadcastTheme.shades.whiteSmoke,
              padding: '2px 10px',
            }}
          >
            {stringShortener(props.account.account_link, 30)}
            <FollowLinkOnNewTab />
          </a>
        </Flex>
      ) : null}
    </Box>
  );
}

export default JiraCloudExtensionDetailsBox;
