export const steps = [
  {
    step: 1,
    title: 'Form Configuration',
    description: 'Description',
  },
  {
    step: 2,
    title: 'Owner & Services',
    description: 'Description',
  },
  {
    step: 3,
    title: 'Input Configuration',
    description: 'Description',
  },
  {
    step: 4,
    title: 'Additional Details',
    description: 'Description',
  },
];
