import * as React from 'react';
import { SVGProps } from 'react';

const SvgHyperpingLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M12.9 8.6c-.8 1.8-1.5 3.4-2.2 5-.2.3-.3.7-.4 1-.2.5-.6.9-1.2.9s-.9-.3-1.2-.9c-.6-1.1-1.1-2.2-1.8-3.4-.4.7-.7 1.3-1 1.9-.3.7-.8 1-1.6 1H1.3c-.8 0-1.3-.5-1.3-1.2s.5-1.2 1.3-1.2h.1c1.4.3 2.2-.4 2.6-1.7.3-.6.7-1.2 1-1.9.2-.5.6-.8 1.1-.8.5 0 .9.3 1.1.8.5 1 1 2.1 1.6 3.1 0 0 .1.1.2.1.4-1 .9-2 1.3-2.9.5-1 .9-2.1 1.4-3.1.2-.5.6-.9 1.2-.9s.9.4 1.2.9c.9 1.9 1.7 3.7 2.6 5.5.2.4.4.6.9.5H19c.7.1 1.1.5 1.1 1.2 0 .6-.4 1.1-1.1 1.1h-2.8c-.6 0-.9-.4-1.1-.9-.8-1.1-1.4-2.5-2.2-4.1z"
      style={{
        fill: '#3657fe',
      }}
    />
  </svg>
);

export default SvgHyperpingLogo20X20;
