import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import Grid from '../Grid';
import { CautionIcon } from '../../utils/icons';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  height?: string | number | '50px';
  width?: string | number | '100%' | 'fit-content';
  fontSize?: string | '16px';
  fontWeight?: string | '400';
  padding?: string | '16px';
  error?: boolean;
  forwardRef?: any;
}

const calcShadows = (props: any) =>
  props.error
    ? `0 0 0 1px ${props.theme.danger.default}`
    : `0 0 0 1px ${props.theme.primary.default}`;

const InputHolder = styled.input<IProps>`
  font-family: ${props => props.theme.fontFamily};
  height: ${props => props.height || '50px'};
  width: ${props => props.width || '100%'};
  font-size: ${props => props.fontSize || '16px'};
  font-weight: ${props => props.fontWeight || '400'};
  padding: ${props => props.padding || '16px'};
  box-shadow: 0 0 0 1px
    ${props => (props.error ? props.theme.danger.default : props.theme.shades.lightGrey)};
  border: none;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: ${props => props.theme.shades.white};
  outline: none;
  transition: 0.17s greyscale, 0.17s box-shadow, 0.17s filter, 0.17s background ease;

  &:hover:not(:disabled),
  &:focus {
    box-shadow: ${calcShadows};
  }

  &:disabled,
  &.disabled {
    cursor: no-drop !important;
    filter: opacity(0.33);
    color: ${props => props.theme.font.disabled};
    box-shadow: 0 0 0 1px ${props => props.theme.shades.smoke};
  }

  &::placeholder {
    color: ${props => props.theme.font.disabled};
    font-weight: 300;
  }
`;

const InputBlock = (props: IProps) => (
  <Grid width={props.width || '100%'} alignItems="center">
    <InputHolder {...props} ref={props.forwardRef} />
    {props.error && <CautionIcon style={{ marginLeft: -34 }} height={16} width={16} />}
  </Grid>
);

/** @component */
export default InputBlock;
