import styled from 'styled-components';

interface IProps {
  fontSize?: 14 | 12 | number;
  height?: 16 | 14;
  fontWeight?: 400 | 500;
}

const ErrorBlock = styled.p<IProps>`
  color: ${props => props.theme.danger.default};
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.fontSize || 12}px;
  line-height: ${props => props.height || 14}px;
  font-weight: ${props => props.fontWeight || 400};
  height: auto;
  margin: 0;
  padding: 8px 0;
`;

/** @component */
export default ErrorBlock;
