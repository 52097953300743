import { createStandaloneToast } from '@chakra-ui/react';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { API } from '../../../../../core/api';
import { T_WA_GS_SERVICE_CREATED, T_WA_UP_DN_ENABLED } from '../../../../../core/const/tracker';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { queryKeys } from '../contants/service.contant';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/helper.service';
import { ServiceData } from '../Interfaces/intigration';
import { DelayNotificationConfig } from '../types/service-detail.types';

const toast = createStandaloneToast();

interface Tags {
  key: string;
  value: string;
}

export interface IServiceSave {
  owner_id?: string;
  name: string;
  tags: Tags[];
  maintainer: {
    id: string;
    type: string;
  } | null;
  escalation_policy_id: string;
  description: string;
  auto_pause_transient_alerts_config: IServiceAptaConfig;
  intelligent_alerts_grouping_config: IServiceIAGConfig;
  delay_notification_config: DelayNotificationConfig;
}

export type IServiceIAGConfig = {
  is_enabled: boolean;
  rolling_window_in_mins: number;
};

export type IServiceAptaConfig = {
  is_enabled: boolean;
  timeout_in_mins: number;
};

const createServiceCatalog = async (payload: IServiceSave) => {
  const { data } = await axios.post<{ data: ServiceData }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/catalog-services?owner_id=${payload.owner_id}`,
    payload,
  );
  return data.data;
};

export function useCreateServiceCatalog() {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const navigateOnSuccess = (data: ServiceData) => {
    // Refetch user ACL for the newly created service
    setTimeout(() => {
      history.push(`/service-catalog/add/service/${data?.id}`);
    }, 500);
  };

  const {
    mutate: createServiceCatalog_,
    data,
    isLoading,
  } = useMutation((payload: IServiceSave) => createServiceCatalog(payload), {
    onError: reactQueryConfigError,
    onSuccess: data => {
      reactQueryConfigSuccess('Service created');
      AppTracker.track(T_WA_GS_SERVICE_CREATED, {
        'APTA Enabled': data.auto_pause_transient_alerts_config.is_enabled,
        ...(data.auto_pause_transient_alerts_config.is_enabled && {
          'APTA Time Window': data.auto_pause_transient_alerts_config.timeout_in_mins,
        }),
        ...(data.auto_pause_transient_alerts_config.is_enabled && {
          'APTA Recommended Time Window Chosen':
            data.auto_pause_transient_alerts_config.timeout_in_mins === 5,
        }),
        'IAG Enabled': data.intelligent_alerts_grouping_config.is_enabled,
        ...(data.intelligent_alerts_grouping_config.is_enabled && {
          'IAG Time Window': data.intelligent_alerts_grouping_config.rolling_window_in_mins,
        }),
        [T_WA_UP_DN_ENABLED]: data.delay_notification_config.is_enabled,
        'Service ID': data.id,
      });
      queryClient.invalidateQueries(queryKeys.SERVICELIST, { refetchInactive: true });
      navigateOnSuccess(data);
      data;
    },
    retry: 1,
  });
  const creationServiceCatalogData = data;

  return { createServiceCatalog_, creationServiceCatalogData, isCreating: isLoading };
}
