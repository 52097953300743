import { Grid, Label, Para, Theme, Tooltip } from 'uie/components';
import { Locale } from 'core/helpers/dateUtils';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IIncident } from '../../../../../core/interfaces/IIncidents';
import { IIntegration } from '../../../../../core/interfaces/IIntegration';
import { IService } from '../../../../../core/interfaces/IService';
import { IUserInfo } from '../../../../../core/interfaces/IUserData';
import { InfoIcon, TimerIcon } from '../../../../../icons';

const { theme } = Theme;

interface IProps extends Pick<IAppState, 'organization' | 'integrations'> {
  incident: IIncident;
}

const IncidentDetailsResponsivenessBox = ({ incident, ...props }: IProps) => {
  let _oService: {
    [key: string]: IService;
  } = {};
  let _oIntegration: {
    [key: string]: IIntegration;
  } = {};
  const [elapsedTime, setElapsedTime] = useState<null | number>(null);
  const [snoozeTimeLeft, setSnoozeTimeLeft] = useState<any>(null);
  const [elapsedTimeSinceLastAck, setElapsedTimeSinceLastAck] = useState<null | number>(null);
  const [timeToFirstAcknowledge, setTimeToFirstAcknowledge] = useState<null | number>(null);

  const computeInternals = () => {
    _oService = props.organization.services.s.reduce((c: any, n) => {
      c[n.id] = n;
      return c;
    }, {});

    _oIntegration = props.integrations.i.reduce((c: any, n) => {
      c[n._id] = n;
      return c;
    }, {});
  };

  computeInternals();

  let user: any;
  if (
    (_oIntegration[incident.alert_source_id]?.shortName ?? 'Deleted entity') === 'email' &&
    incident.sender_email
  ) {
    user = props.organization.users.u.find((u: IUserInfo) => u.email === incident.sender_email);
  } else if (
    (_oIntegration[incident.alert_source_id]?.shortName ?? 'Deleted entity') === 'squadcastui' &&
    incident.created_by
  ) {
    user = props.organization.users.u.find((u: IUserInfo) => u.id === incident.created_by);
  }

  useEffect(() => {
    const updateElapsedTime = () => {
      if (incident.status === 'suppressed') {
        setElapsedTime(moment().diff(moment()));
        return;
      }

      if (incident.status === 'resolved' && incident.analytics?.ttr?.time) {
        const ttr = moment.duration(incident.analytics!.ttr.time, 'ms').abs().as('ms');

        setElapsedTime(ttr);
        return;
      }

      setElapsedTime(moment().diff(moment(incident.timeOfCreation)));
    };

    const interval = setInterval(updateElapsedTime, 1000);

    return () => {
      clearTimeout(interval);
    };
  }, [incident.status, incident.logs, incident.analytics, incident.timeOfCreation]);

  useEffect(() => {
    if (incident?.snooze_details?.is_snoozed) {
      const endTime = incident?.snooze_details?.end_time;

      const intervalId = setInterval(() => {
        const newRemainingTime = moment.duration(moment(endTime)?.diff(moment()));
        setSnoozeTimeLeft(newRemainingTime);

        if (newRemainingTime.asSeconds() <= 0) {
          clearInterval(intervalId);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    } else {
      setSnoozeTimeLeft(null);
    }
  }, [incident.snooze_details]);

  let lastAcknowledgeAt: moment.Moment | null = null;
  if (incident.last_acknowledged_at) {
    lastAcknowledgeAt = moment(incident.last_acknowledged_at);
  }

  useEffect(() => {
    const timeToFirstAcknowledge = () => {
      const firstAcknowledgeTime = incident.logs.find(l => l.actionType === 'acknowledged')?.time;
      if (!firstAcknowledgeTime) {
        setTimeToFirstAcknowledge(null);
        return;
      }
      setTimeToFirstAcknowledge(moment(firstAcknowledgeTime).diff(incident.timeOfCreation));
    };

    timeToFirstAcknowledge();
  }, [incident.status, incident.logs, incident.analytics, incident.last_acknowledged_at]);

  useEffect(() => {
    const updateAckElapsedTime = () => {
      if (
        lastAcknowledgeAt === null ||
        incident.logs.filter(l => l.actionType == 'reassigned').length == 0
      ) {
        setElapsedTimeSinceLastAck(null);
        return;
      }
      if (incident.status === 'resolved') {
        const resolvedAt = incident.logs.find(
          l => l.actionType === 'resolved' || l.actionType === 'auto_resolved',
        )?.time;
        const elapsedLatestAckToResolve = moment(resolvedAt).diff(incident.last_acknowledged_at);
        setElapsedTimeSinceLastAck(elapsedLatestAckToResolve);
        return;
      }
      setElapsedTimeSinceLastAck(moment().diff(moment(incident.last_acknowledged_at)));
    };

    const interval = setInterval(updateAckElapsedTime, 1000);

    return () => {
      clearTimeout(interval);
    };
  }, [incident.status, incident.logs, incident.analytics, incident.last_acknowledged_at]);

  const incidentTime = moment(incident.timeOfCreation);

  const getElapsedDays = (durationInMillis: number): number => {
    return Math.floor(moment.duration(durationInMillis).asDays());
  };

  const isIncidentSnoozed = useMemo(() => {
    return incident.snooze_details.is_snoozed;
  }, [incident?.snooze_details?.is_snoozed]);

  return (
    <Grid type="column" className="incident_details__soft_card invert">
      <Label fontSize={14} color={theme.shades.black} fontWeight={500}>
        Responsiveness
      </Label>

      <Grid alignItems="center" justifyContent="space-between" className="mt-10">
        <Label fontSize={14} fontWeight={500} className="incident_details__label_align_center">
          <TimerIcon className="mr-5" height={14} width={14} />
          Created {incidentTime.isBefore(moment().subtract(4, 'weeks')) && 'at'}
        </Label>
        <Para
          color={theme.shades.cement}
          fontSize={14}
          fontWeight={500}
          className="align_text__right"
          style={{ whiteSpace: 'pre' }}
        >
          {incidentTime.isAfter(moment().subtract(4, 'weeks')) ? (
            <Tooltip
              label={Locale.toFullDateTime(incident.timeOfCreation)}
              padding="2px 8px"
              offset={{ top: '-40px', left: '-150px' }}
            >
              {incidentTime.fromNow()}
            </Tooltip>
          ) : (
            Locale.toShortDateTime(incident.timeOfCreation)
          )}
        </Para>
      </Grid>

      {elapsedTimeSinceLastAck !== null && (
        <Grid alignItems="center" justifyContent="space-between" className="mt-10">
          <Label fontSize={14} fontWeight={500} className="incident_details__label_align_center">
            <TimerIcon className="mr-5" height={14} width={14} />
            Latest Ack&nbsp;
            <Tooltip
              padding="8px"
              offset={{ top: '-70px', left: '-100px' }}
              width="250px"
              label="Time elapsed since latest Acknowledgement. Value changes with user reassignment."
            >
              <InfoIcon style={{ width: '12px', height: '12px' }} />
            </Tooltip>
          </Label>
          <Para
            color={theme.shades.cement}
            fontSize={14}
            fontWeight={500}
            className="align_text__right"
          >
            {getElapsedDays(elapsedTimeSinceLastAck) > 0
              ? `${getElapsedDays(elapsedTimeSinceLastAck)}d `
              : ''}
            {moment.duration(elapsedTimeSinceLastAck).hours() > 0
              ? moment.duration(elapsedTimeSinceLastAck).hours() + 'h'
              : ''}{' '}
            {moment.duration(elapsedTimeSinceLastAck).minutes() > 0
              ? moment.duration(elapsedTimeSinceLastAck).minutes() + 'm'
              : ''}{' '}
            {moment.duration(elapsedTimeSinceLastAck).seconds()}s
          </Para>
        </Grid>
      )}

      {timeToFirstAcknowledge !== null && (
        <Grid alignItems="center" justifyContent="space-between" className="mt-10">
          <Label fontSize={14} fontWeight={500} className="incident_details__label_align_center">
            <TimerIcon className="mr-5" height={14} width={14} />
            TTA&nbsp;
            <Tooltip
              padding="8px"
              offset={{ top: '-60px', left: '-50px' }}
              width="240px"
              label="Time taken from Trigger to First Acknowledgement (TTA)"
            >
              <InfoIcon style={{ width: '12px', height: '12px' }} />
            </Tooltip>
          </Label>
          <Para
            color={theme.shades.cement}
            fontSize={14}
            fontWeight={500}
            className="align_text__right"
          >
            {getElapsedDays(timeToFirstAcknowledge) > 0
              ? `${getElapsedDays(timeToFirstAcknowledge)}d `
              : ''}
            {moment.duration(timeToFirstAcknowledge).hours()
              ? moment.duration(timeToFirstAcknowledge).hours() + 'h'
              : ''}{' '}
            {moment.duration(timeToFirstAcknowledge).minutes()
              ? moment.duration(timeToFirstAcknowledge).minutes() + 'm'
              : ''}{' '}
            {moment.duration(timeToFirstAcknowledge).seconds()}s
          </Para>
        </Grid>
      )}

      {incident.status !== 'suppressed' && (
        <Grid alignItems="center" justifyContent="space-between" className="mt-10">
          <Label fontSize={14} fontWeight={500} className="incident_details__label_align_center">
            <TimerIcon className="mr-5" height={14} width={14} />
            {incident.status === 'resolved' ? 'TTR' : 'Elapsed time'}
            {incident.status === 'resolved' ? (
              <Tooltip
                padding="8px"
                offset={{ top: '-60px', left: '-50px' }}
                width="240px"
                label="Time taken from Trigger to Resolution (TTR)"
              >
                <InfoIcon style={{ width: '12px', height: '12px' }} />
              </Tooltip>
            ) : (
              ''
            )}
          </Label>
          <Para
            color={theme.shades.cement}
            fontSize={14}
            fontWeight={500}
            className="align_text__right"
          >
            {elapsedTime !== null
              ? `${getElapsedDays(elapsedTime) > 0 ? `${getElapsedDays(elapsedTime)}d` : ''} ${
                  moment.duration(elapsedTime).hours() > 0
                    ? moment.duration(elapsedTime).hours() + 'h'
                    : ''
                } ${moment.duration(elapsedTime).minutes()}m ${moment
                  .duration(elapsedTime)
                  .seconds()}s`
              : '-h -m -s'}
          </Para>
        </Grid>
      )}
      {isIncidentSnoozed && snoozeTimeLeft !== null && (
        <Grid alignItems="center" justifyContent="space-between" className="mt-10">
          <Label fontSize={14} fontWeight={500} className="incident_details__label_align_center">
            <TimerIcon className="mr-5" height={14} width={14} />
            Snooze duration left
          </Label>
          <Para
            color={theme.shades.cement}
            fontSize={14}
            fontWeight={500}
            className="align_text__right"
          >
            {snoozeTimeLeft !== null
              ? `${
                  getElapsedDays(snoozeTimeLeft) > 0 ? `${getElapsedDays(snoozeTimeLeft)}d` : ''
                } ${
                  moment.duration(snoozeTimeLeft).hours() > 0
                    ? moment.duration(snoozeTimeLeft).hours() + 'h'
                    : ''
                } ${moment.duration(snoozeTimeLeft).minutes()}m ${moment
                  .duration(snoozeTimeLeft)
                  .seconds()}s`
              : '-h -m -s'}
          </Para>
        </Grid>
      )}
    </Grid>
  );
};

export default connect(
  ({ organization, integrations }: IAppState) => ({
    organization,
    integrations,
  }),
  {},
)(IncidentDetailsResponsivenessBox);
