import { FormikBag, FormikValues, withFormik } from 'formik';

export const FormikForm = ({
  validate,
  validationSchema,
  handleSubmit,
  mapPropsToValues,
  formTemplate,
  validateOnBlur = false,
  validateOnChange = false,
}: {
  validate?: (values: FormikValues) => void;
  validationSchema?: any | ((props: any) => any);
  handleSubmit: (values: any, formikBag?: FormikBag<any, FormikValues>) => void;
  mapPropsToValues: () => FormikValues;
  formTemplate: any;
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
}) => {
  const FormikEnhancer = withFormik({
    validateOnBlur: validateOnBlur,
    validateOnChange: validateOnChange,
    mapPropsToValues: mapPropsToValues,
    validate: validate,
    validationSchema: validationSchema,
    handleSubmit: handleSubmit,
    displayName: 'Form',
  })(formTemplate);

  return <FormikEnhancer />;
};
