import {
  REQUEST_ORG_ESCALATION,
  REQUEST_ORG_ESCALATION_FAIL,
  REQUEST_ORG_ESCALATION_SUCCESS,
} from '../../const/organization';
import { IEscalationPolicy } from '../../interfaces/IEscalationPolicy';

const requestOrganizationEscalation = () => ({
  type: REQUEST_ORG_ESCALATION,
  payload: {},
});

const onRequestOrganizationEscalationSuccess = (escalationPolicies: IEscalationPolicy[]) => ({
  type: REQUEST_ORG_ESCALATION_SUCCESS,
  payload: {
    escalationPolicies,
  },
});

const onRequestOrganizationEscalationFail = (error: any) => ({
  type: REQUEST_ORG_ESCALATION_FAIL,
  payload: {
    error,
  },
});

export {
  requestOrganizationEscalation,
  onRequestOrganizationEscalationSuccess,
  onRequestOrganizationEscalationFail,
};
