import { BroadcastChannel } from 'broadcast-channel';

const tzBroadcastChannel: BroadcastChannel = new BroadcastChannel('timezone_channel');

const TIMEZONE_CHANGED = 'TIMEZONE_CHANGED';

/**
 * Timezone changes broadcast listener
 */
const tzAuthBroadcastListener = () => {
  tzBroadcastChannel.addEventListener('message', e => {
    switch (e.event) {
      case TIMEZONE_CHANGED:
        window.location.reload();
    }
  });
};

/**
 * Broadcast timezone changes
 * @returns {BroadcastChannel}
 */
const broadcastTimezoneChanged = () => tzBroadcastChannel.postMessage({ event: TIMEZONE_CHANGED });

export { tzAuthBroadcastListener, broadcastTimezoneChanged };
