import React from 'react';
import { SPLIT_WORD_MAP } from '../../incidentDetails/constants';
import { JSXResponseForEntity } from '../helpers';

const ResponsesForManualMergeParentLog: React.FC<
  Omit<JSXResponseForEntity, 'logMessage' | 'getEntityName'>
> = ({ log, getEntityComponent, getEntityComponentWithTruncatedName }) => {
  const action = log.actionType.includes('_merged_') ? 'merge' : 'unmerge';
  const nameComponent = getEntityComponentWithTruncatedName({
    componentType: 'linkWPopover',
    type: 'user',
    id: log.id,
    popoverPlacement: 'left',
  });
  const splitWord = SPLIT_WORD_MAP[log.actionType as keyof typeof SPLIT_WORD_MAP];
  const childIncidentId = log.additionalInfo?.child ?? '';
  let childIncidentMsg = log.reason.split(splitWord)[1].replace(' incident', '');
  childIncidentMsg = `${
    childIncidentMsg.length >= 30 ? childIncidentMsg.slice(0, 30) : childIncidentMsg
  }${childIncidentMsg.length >= 30 ? '...' : ''}`;

  let messageComponent = <></>;

  if (action === 'merge') {
    messageComponent = (
      <>{`marked this as a parent incident by manually merging ${
        log.additionalInfo?.children_count
      } incident${(log.additionalInfo?.children_count as number) > 1 ? 's' : ''}`}</>
    );
  } else {
    messageComponent = (
      <>
        unmerged the incident{' '}
        {getEntityComponent({
          componentType: 'linkWOPopover',
          type: 'incident',
          id: childIncidentId,
          name: childIncidentMsg,
        })}
      </>
    );
  }
  return (
    <>
      {nameComponent} {messageComponent}
    </>
  );
};

export default ResponsesForManualMergeParentLog;
