import * as React from 'react';
import { SVGProps } from 'react';

const SvgBugsnagLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Bugsnag_Logo-20x20_svg__bc946d5f-d97b-424c-ad0c-eb6d5eeff970"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.Bugsnag_Logo-20x20_svg__st0{fill:#4949e4}'}</style>
    <path
      className="Bugsnag_Logo-20x20_svg__st0"
      d="M6.1 1c-.2.1-.3.2-.5.3-.4.3-.8.5-1.3.8-.1.1-.2.2-.2.4v4.2c0 .3-.2.5-.4.5-.3 0-.5-.2-.5-.5V2.2c0-.3.1-.6.4-.8C4.3.9 5 .5 5.7.1c.7-.4 1.4 0 1.4.8v7.8c0 .3 0 .3.3.3h2.7c2 0 3.7 1.6 3.8 3.6.2 2.5-1.8 4.4-4.2 4.3C7.6 16.8 6 15.1 6 13v-2.7c0-.2-.1-.3-.3-.3H4.2c-.2 0-.2.1-.2.2v3.2c.1 2.5 2 4.7 4.6 5.3 3.6.8 6.7-1.6 7.3-4.9.5-3.2-1.7-6.3-5-6.8-.5 0-1.1.1-1.7 0-.4 0-.6-.3-.5-.6.1-.2.2-.3.4-.3 4.2-.4 7.1 2.3 7.8 5.5.8 3.7-1.6 7.4-5.4 8.2-3.4.7-6.6-1.1-7.9-4.2-.3-.8-.5-1.6-.5-2.4V9.7c0-.5.2-.7.7-.7h2.1c.2 0 .3-.1.3-.3V1.1c-.1.1-.1 0-.1-.1zm2.7 9.1s0-.1 0 0H7.3c-.1 0-.2 0-.2.2v2.9c.1 1.8 1.8 3.1 3.6 2.7 1.3-.3 2.4-1.5 2.3-3.1-.1-1.3-1.1-2.5-2.4-2.7-.6-.1-1.2 0-1.8 0z"
    />
    <path
      className="Bugsnag_Logo-20x20_svg__st0"
      d="M10.1 12.2c.5 0 .8.3.8.8s-.3.8-.8.8-.8-.3-.8-.8.3-.8.8-.8z"
    />
  </svg>
);

export default SvgBugsnagLogo20X20;
