import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  REQUEST_USER_PERMISSION_ROLES,
  REQUEST_USER_PERMISSION_ROLES_FAIL,
} from '../../const/public';
import { AjaxResponse } from 'rxjs/ajax';
import { getUserPermissionRoles } from '../../api/organization/getUserPermissionRoles';
import { onRequestUserPermissionRolesSuccess } from '../../actions/organization/userPermissionRoles';
import { IUserPermissionRoles } from '../../interfaces/IUserPermissionRoles';

const userPermissionRolesEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_USER_PERMISSION_ROLES),
    switchMap(() => {
      return getUserPermissionRoles().pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ result, data }: { result: boolean; data: IUserPermissionRoles[] }) => {
          return onRequestUserPermissionRolesSuccess(data);
        }),
        catchError(error =>
          of({
            type: REQUEST_USER_PERMISSION_ROLES_FAIL,
            payload: error.xhr,
            error: true,
          }),
        ),
      );
    }),
  );

export default userPermissionRolesEpic;
