import { IAppErrorResponse } from 'api/errors';
import axios, { AxiosError } from 'axios';

type OrgId = string;
type TeamId = string;

export type AnalyticsPageData = {
  canUserAccessOrgLevelQueries: boolean;
  teams: Array<{ id: TeamId; name: string }>;
  cubeToken: string;
  // It is the minimum date that an org can select for the filters
  // It is in the SQL date format, e.g. 2022-11-06
  dataRetentionLimit?: string;
};

export type AnalyticsUpdatedData = {
  last_updated: string;
};

export async function analyticsPageBFF(apiBaseURL: string, orgId: OrgId) {
  try {
    const response = await axios.get<{ data: AnalyticsPageData }>(
      `${apiBaseURL}/organizations/${orgId}/bff/analytics`,
    );
    return response.data.data;
  } catch (e) {
    const appError = (e as AxiosError<IAppErrorResponse>).response?.data.meta;
    return Promise.reject(appError || e);
  }
}

export async function analyticsLastUpdated(apiBaseURL: string, orgId: OrgId) {
  try {
    const response = await axios.get<{ data: AnalyticsUpdatedData }>(
      `${apiBaseURL}/organizations/${orgId}/analyticsv2/last-updated`,
    );
    return response.data.data;
  } catch (e) {
    const appError = (e as AxiosError<IAppErrorResponse>).response?.data.meta;
    return Promise.reject(appError || e);
  }
}
