import * as React from 'react';
import { SVGProps } from 'react';

const SvgZendeskLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Zendesk_Logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.Zendesk_Logo-20x20_svg__st0{fill:#03363d}'}</style>
    <path
      className="Zendesk_Logo-20x20_svg__st0"
      d="M9.239 6.423v11.156H0zM9.239 2.421c0 2.549-2.065 4.615-4.615 4.615S0 4.97 0 2.421h9.239zM10.761 17.579c0-2.549 2.065-4.615 4.615-4.615s4.615 2.065 4.615 4.615h-9.23zM10.761 13.577V2.421H20z"
    />
  </svg>
);

export default SvgZendeskLogo20X20;
