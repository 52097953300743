// Get Started

export const T_WA_GS_SIGN_UP = 'Sign Up';
export const T_WA_GS_SIGN_IN = 'Sign In';
export const T_WA_GS_EMAIL_VERIFIED = 'Email Verified';
export const T_WA_GS_FIRST_SIGN_IN = 'First Sign In';
export const T_WA_GS_INCIDENT_ACKNOWLEDGED = 'Incident Acknowledged';
export const T_WA_GS_INCIDENT_REASSIGNED = 'Incident Reassigned';
export const T_WA_GS_INCIDENT_RESOLVED = 'Incident Resolved';
export const T_WA_GS_STATUSPAGE_CREATED = 'Statuspage Created';
export const T_WA_GS_STATUSPAGE_DELETED = 'Statuspage Deleted';
export const T_WA_GS_STATUSPAGE_VIEWED = 'StatusPage Viewed';
export const T_WA_GS_RUNBOOK_CREATED = 'Runbook Created';
export const T_WA_GS_RUNBOOK_UPDATED = 'Runbook Updated';
export const T_WA_GS_RUNBOOK_DELETED = 'Runbook Deleted';
export const T_WA_GS_RUNBOOK_VIEWED = 'Runbooks Page Viewed';
export const T_WA_GS_RUNBOOK_ATTACHED = 'Runbook Attached';
export const T_WA_GS_ATTACHED_RUNBOOK_REMOVED = 'Attached Runbook Removed';
export const T_WA_GS_TASK_ADDED = 'Task Added';
export const T_WA_GS_TASK_UPDATED = 'Task Updated';
export const T_WA_GS_TASK_DELETED = 'Task Deleted';
export const T_WA_GS_TIMELINE_DOWNLOADED = 'Timeline Downloaded';
export const T_WA_GS_POSTMORTEM_TEMPLATE_DELETED = 'Postmortem Template Deleted';
export const T_WA_GS_POSTMORTEM_TEMPLATE_UPDATED = 'Postmortem Template Updated';
export const T_WA_GS_POSTMORTEM_TEMPLATE_CREATED = 'Postmortem Template Created';
export const T_WA_GS_POSTMORTEM_CREATED_WEB_V1 = 'Postmortem Created Web v1';
export const T_WA_GS_POSTMORTEM_UPDATED_WEB_V1 = 'Postmortem Updated Web v1';
export const T_WA_GS_POSTMORTEM_DELETED_WEB_V1 = 'Postmortem Deleted Web v1';
export const T_WA_GS_POSTMORTEM_DOWNLOADED_WEB_V1 = 'Postmortem Downloaded Web v1';
export const T_WA_GS_CUSTOM_VIEW_CREATED = 'Custom View Created';
export const T_WA_GS_CUSTOM_VIEW_UPDATED = 'Custom View Updated';
export const T_WA_GS_CUSTOM_VIEW_DELETED = 'Custom View Deleted';
export const T_WA_GS_INCIDENT_LIST_EXPORTED = 'Incident List Exported';
export const T_WA_GS_TAGGING_RULES_MODIFIED = 'Tagging Rules Modified';
export const T_WA_GS_INCIDENT_TAG_DELETED = 'Incident Tag Deleted';
export const T_WA_GS_DEDUPLICATION_RULES_MODIFIED = 'Deduplication Rules Modified';
export const T_WA_GS_ROUTING_RULES_MODIFIED = 'Routing Rules Modified';
export const T_WA_GS_SUPPRESSION_RULES_MODIFIED = 'Suppression Rules Modified';
export const T_WA_GS_INCIDENT_LIST_VIEW_EXPANDED_CLOSED = 'Incident List View Expanded/Closed';
export const T_WA_GS_INCIDENT_CREATED_MANUALLY = 'Incident Created Manually';
export const T_WA_GS_SERVICE_CREATED = 'Service Created';
export const T_WA_GS_SERVICE_MODIFIED = 'Service Modified';
export const T_WA_GS_SERVICE_DELETED = 'Service Deleted';
export const T_WA_GS_ESCALATION_POLICY_CREATED = 'Escalation Policy Created';
export const T_WA_GS_ESCALATION_POLICY_MODIFIED = 'Escalation Policy Modified';
export const T_WA_GS_ESCALATION_POLICY_DELETED = 'Escalation Policy Deleted';
export const T_WA_GS_SCHEDULE_CREATED = 'Schedule Created';
export const T_WA_GS_SCHEDULE_DELETED = 'Schedule Deleted';
export const T_WA_GS_SHIFT_CREATED = 'Shift Created';
export const T_WA_GS_SHIFT_MODIFIED = 'Shift Modified';
export const T_WA_GS_SHIFT_DELETED = 'Shift Deleted';
export const T_WA_GS_SSO_ENABLED = 'SSO Enabled';
export const T_WA_GS_SSO_DISABLED = 'SSO Disabled';
export const T_WA_GS_SSO_LOGIN = 'SSO Login';
export const T_WA_GS_SQUAD_CREATED = 'Squad Created';
export const T_WA_GS_SQUAD_MODIFIED = 'Squad Modified';
export const T_WA_GS_SQUAD_DELETED = 'Squad Deleted';
export const T_WA_GS_SLACK_INTEGRATED = 'Slack Integrated';
export const T_WA_GS_SLACK_REVOKED = 'Slack Revoked';
export const T_WA_GS_GITHUB_INTEGRATED = 'Github Integrated';
export const T_WA_GS_GITHUB_REVOKED = 'Github Revoked';
export const T_WA_GS_CIRCLECI_INTEGRATED = 'CircleCI Integrated';
export const T_WA_GS_CIRCLECI_REVOKED = 'CircleCI Revoked';
export const T_WA_GS_JIRA_CLOUD_INTEGRATED = 'Jira Cloud Integrated';
export const T_WA_GS_JIRA_CLOUD_REVOKED = 'Jira Cloud Revoked';
export const T_WA_GS_JIRA_SERVER_INTEGRATED = 'Jira DC Integrated';
export const T_WA_GS_JIRA_SERVER_REVOKED = 'Jira DC Revoked';
export const T_WA_GS_GOOGLE_HANGOUTS_INTEGRATION_ATTEMPTED =
  'Google Hangouts Integration Attempted';
export const T_WA_GS_MICROSOFT_TEAMS_INTEGRATION_ATTEMPTED =
  'Microsoft Teams Integration Attempted';
export const T_WA_GS_USER_API_TOKEN_CREATED = 'User API Token Created';
export const T_WA_GS_USER_API_TOKEN_CHANGED = 'User API Token Changed';
export const T_WA_GS_USER_API_TOKEN_DELETED = 'User API Token Deleted';
export const T_WA_GS_ORGANIZATION_DEACTIVATED = 'Organization Deactivated';
export const T_WA_GS_ORGANIZATION_DELETED = 'Organization Deleted';
export const T_WA_GS_PLAN_ACTIVATED = 'Plan Activated';
export const T_WA_GS_PLAN_UNSUBSCRIBED = 'Plan Unsubscribed';
export const T_WA_GS_CARD_ADDED = 'Card Added';
export const T_WA_GS_CARD_REMOVED = 'Card Removed';
export const T_WA_GS_ANALYTICS_PAGE_VIEWED = 'Analytics Page Viewed';
export const T_WA_GS_ANALYTICS_PAGE_HEAT_MAP_CLICKED = 'Analytics Page Heat Map Clicked';
export const T_WA_GS_ANALYTICS_PAGE_STATUS_CLICKED = 'Analytics Page Status Clicked';
export const T_WA_GS_ANALYTICS_PAGE_SERVICE_CLICKED = 'Analytics Page Service Clicked';
export const T_WA_GS_ANALYTICS_PAGE_ALERTSOURCE_CLICKED = 'Analytics Page AlertSource Clicked';
export const T_WA_GS_USER_ADDED = 'User Added';
export const T_WA_GS_SERVICE_MAINTENANCE = 'Service Maintenance';
export const T_WA_GS_SERVICE_TAGGING = 'Service Tagging';
export const T_WA_GS_SERVICE_DEPENDENCIES = 'Service Dependencies';
export const T_WA_GS_QUERY_SEARCH = 'Query Search';
export const T_WA_GS_ESCALATION_NOTIFICATION_RULES_CUSTOM = 'Escalation Notification Rules Custom';
export const T_WA_GS_ESCALATION_NOTIFICATION_RULES = 'Escalation Notification Rules';
export const T_WA_GS_ESCALATION_RULES_GROUP = 'Escalation Rules Group';
export const T_WA_GS_INCIDENT_NOTES = 'Incident Notes';
export const T_WA_GS_INCIDENT_NOTES_STARRED = 'Incident Notes Starred';
export const T_WA_GS_BILLING_PAGE_VIEWED = 'Billing Page Viewed';
export const T_WA_GS_INCIDENT_ACTIONS_CIRCLE_CI = 'Incident Actions Circle CI';
export const T_WA_GS_INCIDENT_ACTIONS_JIRA_CLOUD = 'Incident Actions Jira Cloud';
export const T_WA_GS_INCIDENT_ACTIONS_JIRA_SERVER = 'Incident Actions Jira DC';
export const T_WA_GS_INCIDENT_ACTIONS_SERVICENOW = 'Incident Actions ServiceNow';
export const T_WA_GS_STATUS_POSTED = 'Status Posted';
export const T_WA_GS_INCIDENT_LIST_PAGE_VIEWED = 'Incident List Page Viewed';
export const T_WA_GS_USERS_PAGE_VIEWED = 'Users Page Viewed';
export const T_WA_GS_SQUADS_PAGE_VIEWED = 'Squads Page Viewed';
export const T_WA_GS_ESCALATION_POLICY_PAGE_VIEWED = 'Escalation Policy Page Viewed';
export const T_WA_GS_SERVICES_PAGE_VIEWED = 'Services Page Viewed';
export const T_WA_GS_SCHEDULES_PAGE_VIEWED = 'Schedules Page Viewed';
export const T_WA_GS_EXTENSIONS_PAGE_VIEWED = 'Extensions Page Viewed';
export const T_WA_GS_NOTIFICATION_RULES_MODIFIED = 'Notification Rules Modified';
export const T_WA_GS_POSTMORTEMS_PAGE_VIEWED = 'Postmortems Page Viewed';
export const T_WA_GS_PERMISSIONS_PAGE_VIEWED = 'Permissions Page Viewed';
export const T_WA_GS_PERMISSIONS_UPDATED = 'Permissions Updated';
export const T_WA_GS_TEAMS_CREATED = 'Team Created';
export const T_WA_GS_TEAMS_DELETED = 'Team Deleted';
export const T_WA_GS_NEW_TEAM_ROLE_ADDED = 'New Team Role Added';
export const T_WA_GS_DEFAULT_TEAM_ROLE_MODIFIED = 'Default Team Role Modified';
export const T_WA_GS_NEW_MEMBER_ADDED = 'New Member Added';
export const T_WA_GS_GLOBAL_TEAM_SWITCHED = 'Global Team Switched';
export const T_WA_GS_REGISTER_PAGE_VIEWED = 'Register Page Viewed';
export const T_WA_GS_EMAIL_ENTERED = 'Email Entered';
export const T_WA_GS_PASSWORD_ENTERED = 'Password Entered';
export const T_WA_GS_GET_STARTED_CLICKED = 'Get Started Clicked';
export const T_WA_GS_UPDATE_BUTTON_CLICKED = 'Update Button Clicked';
export const T_WA_GS_ON_CALL_REMINDER_REVOKED = 'On Call Reminder Revoked';
export const T_WA_GS_ON_CALL_REMINDER_MODIFIED = 'On Call Reminder Modified';
export const T_WA_GS_VERIFY_BUTTON_CLICKED = 'Verify Button Clicked';
export const T_WA_GS_SKIP_AND_VERIFY_LATER_BUTTON_CLICKED = 'Skip and Verify Later Button Clicked';
export const T_WA_GS_REFERRAL_LINK_CLICKED = 'Referral Link Clicked';

// DashBoard
export const T_WA_DASH_VISIT_INC = 'T_WA_DASH_VISIT_INC';
export const T_WA_DASH_VISIT_SRE = 'T_WA_DASH_VISIT_SRE';
export const T_WA_DASH_CLICK_LAST_WEEK = 'T_WA_DASH_CLICK_LAST_WEEK';
export const T_WA_DASH_CLICK_LAST_MONTH = 'T_WA_DASH_CLICK_LAST_MONTH';
export const T_WA_DASH_CLICK_LAST_YEAR = 'T_WA_DASH_CLICK_LAST_YEAR';
export const T_WA_DASH_CLICK_CUSTOM_RANGE = 'T_WA_DASH_CLICK_CUSTOM_RANGE';
export const T_WA_DASH_CLICK_PLUS = 'T_WA_DASH_CLICK_PLUS';
export const T_WA_DASH_CREATED_INC_USING_PLUS = 'T_WA_DASH_CREATED_INC_USING_PLUS';
export const T_WA_DASH_CLICK_FILTER_IMPACTON = 'T_WA_DASH_CLICK_FILTER_IMPACTON';
export const T_WA_DASH_CLICK_FILTER_VIA = 'T_WA_DASH_CLICK_FILTER_VIA';
export const T_WA_DASH_CLICK_FILTER_ASSIGNEDTO = 'T_WA_DASH_CLICK_FILTER_ASSIGNEDTO';
export const T_WA_DASH_ACTIONS_ACKNOWLEDGE = 'T_WA_DASH_ACTIONS_ACKNOWLEDGE';
export const T_WA_DASH_ACTIONS_RESOLVE = 'T_WA_DASH_ACTIONS_RESOLVE';
export const T_WA_DASH_CLICK_PAGINATION = 'T_WA_DASH_CLICK_PAGINATION';
// Incident Details
export const T_WA_IDP_INC_ACK = 'T_WA_IDP_INC_ACK';
export const T_WA_IDP_INC_RESOLVED = 'T_WA_IDP_INC_RESOLVED';
export const T_WA_IDP_INC_REASSIGNED = 'T_WA_IDP_INC_REASSIGNED';
export const T_WA_IDP_CLICK_MORE_ACTIONS = 'T_WA_IDP_CLICK_MORE_ACTIONS';
export const T_WA_IDP_SELECT_ACTION = 'T_WA_IDP_SELECT_ACTION';
export const T_WA_IDP_EXECUTE_ACTION = 'T_WA_IDP_EXECUTE_ACTION';
export const T_WA_IDP_WARROOM_CHAT = 'T_WA_IDP_WARROOM_CHAT';
export const T_WA_IDP_CLICK_SELECT_SLACK_CHANNEL = 'T_WA_IDP_CLICK_SELECT_SLACK_CHANNEL';
export const T_WA_IDP_SAVED_SLACK_CHANNEL = 'T_WA_IDP_SAVED_SLACK_CHANNEL';
export const T_WA_IDP_CLICK_UPDATE_STATUSPAGE = 'T_WA_IDP_CLICK_UPDATE_STATUSPAGE';
export const T_WA_IDP_PUBLISHED_STATUSPAGE_UPDATE = 'T_WA_IDP_PUBLISHED_STATUSPAGE_UPDATE';
export const T_WA_IDP_CLICK_UPDATE_TAG = 'T_WA_IDP_CLICK_UPDATE_TAG';
export const T_WA_IDP_UPDATED_TAGS_MANUALLY = 'T_WA_IDP_UPDATED_TAGS_MANUALLY';
export const T_WA_IDP_CLICK_START_POSTMORTEM = 'T_WA_IDP_CLICK_START_POSTMORTEM';
export const T_WA_IDP_PMW_CLICK_ADD_FOLLOWUP = 'T_WA_IDP_PMW_CLICK_ADD_FOLLOWUP';
export const T_WA_IDP_PMW_CLICK_CREATE = 'T_WA_IDP_PMW_CLICK_CREATE';
export const T_WA_IDP_CLICK_OPEN_POSTMORTEM = 'T_WA_IDP_CLICK_OPEN_POSTMORTEM';
export const T_WA_IDP_UPW_ADDED_FOLLOWUP = 'T_WA_IDP_UPW_ADDED_FOLLOWUP';
export const T_WA_IDP_CLICK_DELETE_POSTMORTEM = 'T_WA_IDP_CLICK_DELETE_POSTMORTEM';
export const T_WA_IDP_DELETED_POSTMORTEM = 'T_WA_IDP_DELETED_POSTMORTEM';
export const T_WA_IDP_DOWNLOADED_PM_PDF = 'T_WA_IDP_DOWNLOADED_PM_PDF';
export const T_WA_IDP_DOWNLOADED_PM_MD = 'T_WA_IDP_DOWNLOADED_PM_MD';
export const T_WA_IDP_DOWNLOADED_TL_PDF = 'T_WA_IDP_DOWNLOADED_TL_PDF';
export const T_WA_IDP_DOWNLOADED_TL_CSV = 'T_WA_IDP_DOWNLOADED_TL_CSV';
export const T_WA_IDP_INCIDENT_MARKED_AS_TRANSIENT = 'Incident marked as Transient';
export const T_WA_IDP_INCIDENT_MARKED_AS_NOT_TRANSIENT = 'Incident marked as NOT Transient';

// Squads
export const T_WA_SQP_CLICK_ADD_SQUAD = 'T_WA_SQP_CLICK_ADD_SQUAD';
export const T_WA_SQP_CREATED_SQUAD = 'T_WA_SQP_CREATED_SQUAD';
export const T_WA_SQP_SEARCHED_SQUAD = 'T_WA_SQP_SEARCHED_SQUAD';
export const T_WA_SQP_CLICKED_EDIT_SQUAD = 'T_WA_SQP_CLICKED_EDIT_SQUAD';
export const T_WA_SQP_CLICKED_DELETE_SQUAD = 'T_WA_SQP_CLICKED_DELETE_SQUAD';
export const T_WA_SQP_DELETED_SQUAD = 'T_WA_SQP_DELETED_SQUAD';
export const T_WA_SQP_EDIT_SAVE_SQUAD = 'T_WA_SQP_EDIT_SAVE_SQUAD';
export const T_WA_SQP_SSQ_CONFIG_COMMIT_CLICK = 'T_WA_SQP_SSQ_CONFIG_COMMIT_CLICK';
export const T_WA_SQP_SSQ_CONFIG_COMMIT_SAVED = 'T_WA_SQP_SSQ_CONFIG_COMMIT_SAVED';
// Escalation Policy
export const T_WA_EP_CLICK_ADD_ESCALATION_POLICY = 'T_WA_EP_CLICK_ADD_ESCALATION_POLICY';
export const T_WA_EP_CREATED_ESCALATION_POLICY = 'T_WA_EP_CREATED_ESCALATION_POLICY';
export const T_WA_EP_ADDED_RULE_ESCALATION_POLICY = 'T_WA_EP_ADDED_RULE_ESCALATION_POLICY';
export const T_WA_EP_SEARCHED_ESCALATION_POLICY = 'T_WA_EP_SEARCHED_ESCALATION_POLICY';
export const T_WA_EP_CLICK_DELETE_ESCALATION_POLICY = 'T_WA_EP_CLICK_DELETE_ESCALATION_POLICY';
export const T_WA_EP_DELETED_ESCALATION_POLICY = 'T_WA_EP_DELETED_ESCALATION_POLICY';
export const T_WA_EP_CLICK_EDIT_ESCALATION_POLICY = 'T_WA_EP_CLICK_EDIT_ESCALATION_POLICY';
export const T_WA_EP_EDIT_SAVED_ESCALATION_POILCY = 'T_WA_EP_EDIT_SAVED_ESCALATION_POILCY';
// workflow page
export const T_WA_WP_LIST_PAGE_VIEWED = 'workflows_listing_page_viewed';
export const T_WA_WP_CREATED = 'workflow_created';
export const T_WA_WP_EDITED = 'workflow_edited';
export const T_WA_WP_DELETED = 'workflow_deleted';
export const T_WA_WP_DETAILS_PAGE_VIEWED = 'workflow_details_page_viewed';
export const T_WA_WP_LOGS_EXPORTED = 'workflow_logs_exported';
export const T_WA_WP_ENABLED = 'workflow_enabled';
export const T_WA_WP_DISABLED = 'workflow_disabled';
export const T_WA_WP_LIST_FILTER_APPLIED = 'workflow_filter_applied';
export const T_WA_WP_LIST_SEARCH_APPLIED = 'workflow_search_performed';
export const T_WA_WP_CLONED = 'workflow_cloned';
export const T_WA_WP_LOG_DETAILS_VIEWED = 'workflow_log_details_viewed';
export const T_WA_WP_ACTION_LOG_PAYLOAD_VIEWED = 'workflow_action_response_payload_viewed';
// Users
export const T_WA_UP_SIDEBAR_CLICK = 'T_WA_UP_SIDEBAR_CLICK';
export const T_WA_UP_CLICK_FILTER = 'T_WA_UP_CLICK_FILTER';
export const T_WA_UP_APPLIED_FILTER = 'T_WA_UP_APPLIED_FILTER';
export const T_WA_UP_SEARCHED_USERS = 'T_WA_UP_SEARCHED_USERS';
export const T_WA_UP_CLICK_ADD_USER = 'T_WA_UP_CLICK_ADD_USER';
export const T_WA_UP_ADDED_USER = 'T_WA_UP_ADDED_USER';
export const T_WA_UP_CLICK_CHANGE_USER_ROLE = 'T_WA_UP_CLICK_CHANGE_USER_ROLE';
export const T_WA_UP_SAVED_USER_ROLE_CHANGE = 'T_WA_UP_SAVED_USER_ROLE_CHANGE';
export const T_WA_UP_SET_MULTIPLE_ALERT_FORWARDING = 'T_WA_UP_SET_MULTIPLE_ALERT_FORWARDING';
export const T_WA_UP_SAVED_ALERT_FORWARDING_RULES = 'T_WA_UP_SAVED_ALERT_FORWARDING_RULES';
export const T_WA_UP_DELETED_USER = 'T_WA_UP_DELETED_USER';
export const T_WA_UP_CLICK_RESEND_VERIFICATION_MAIL = 'T_WA_UP_CLICK_RESEND_VERIFICATION_MAIL';
export const T_WA_UP_ADD_USER = 'Add User';
export const T_WA_GS_SLACK_CHANNEL_CREATED = 'slack channel created';
export const T_WA_GS_CHATOPS_LINK_ADDED = 'chatops link added';
export const T_WA_GS_VIDEO_CONFERENCE_LINK_ADDED = 'video conference link added';
export const T_WA_GS_EXTERNAL_LINK_ADDED = 'external link added';
export const T_WA_UP_ANALYTICS_V2_PAGE_VIEWED = 'Analytics v2 Page Viewed';
export const T_WA_UP_MTTA_MTTR_OVER_TIME_REPORT_DOWNLOADED =
  'MTTA & MTTR Over Time Report Downloaded';
export const T_WA_UP_INICIDENT_COUNT_BY_DATE_REPORT_DOWNLOADED =
  'Incident Count by Date Report Downloaded';
export const T_WA_UP_INCIDENT_STATUS_BY_DATE_REPORT_DOWNLOADED =
  'Incident Status by Date Report Downloaded';
export const T_WA_UP_OPEN_INCIDENTS_BY_TEAM_REPORT_DOWNLOADED =
  'Open Incidents by Team Report Downloaded';
export const T_WA_UP_DEDUPLICATED_INCIDENT_BY_TEAM_REPORT_DOWNLOADED =
  'Deduplicated Incidents by Team Report Downloaded';
export const T_WA_UP_SUPPRESSED_INCIDENT_BY_TEAM_REPORT_DOWNLOADED =
  'Suppressed Incidents by Team Report Downloaded';
export const T_WA_UP_OPEN_INCIDENTS_BY_SERVICE_REPORT_DOWNLOADED =
  'Open Incidents by Service Report Downloaded';
export const T_WA_UP_OPEN_INCIDENTS_BY_USERS_REPORT_DOWNLOADED =
  'Open Incidents by Users Report Downloaded';
export const T_WA_UP_DEDUPLICATED_INCIDENT_BY_SERVICE_REPORT_DOWNLOADED =
  'Deduplicated Incidents by Service Report Downloaded';
export const T_WA_UP_DEDUPLICATED_INCIDENT_BY_ALERT_SOURCES_REPORT_DOWNLOADED =
  'Deduplicated Incidents by Alert Sources Report Downloaded';
export const T_WA_UP_SUPPRESSED_INCIDENT_BY_SERVICE_REPORT_DOWNLOADED =
  'Suppressed Incidents by Service Report Downloaded';
export const T_WA_UP_SUPPRESSED_INCIDENT_BY_ALERT_SOURCES_REPORT_DOWNLOADED =
  'Suppressed Incidents by Alert Sources Report Downloaded';
export const T_WA_UP_REASSIGNED_INCIDENT_BY_TEAM_REPORT_DOWNLOADED =
  'Reassigned Incidents by Teams Report Downloaded';
export const T_WA_UP_ADD_FILTERS_IN_ANALYTICS_PAGE_V2_USED = 'Add Filter in Analytics Page v2 Used';
export const T_WA_UP_SLO_PAGE_VIEWED = 'SLO Page Viewed';
export const T_WA_UP_NEW_SLO_CREATED = 'New SLO Created';
export const T_WA_UP_SLO_EDITED = 'SLO Edited';
export const T_WA_UP_SLO_DELETED = 'SLO Deleted';
export const T_WA_UP_SLO_OVERVIEW_PAGE_VISITED = 'SLO Overiew Page Visited';
export const T_WA_UP_SLO_OVERVIEW_INCIDENTS_PAGE_VISITED = 'SLO Overiew Incidents Page Visited';
export const T_WA_UP_SLO_OVERVIEW_INSIGHTS_PAGE_VISITED = 'SLO Overiew Insights Page Visited';
export const T_WA_UP_SLO_OVERVIEW_FALSE_POSITIVES_PAGE_VISITED =
  'SLO Overiew False Positives Page Visited';
export const T_WA_UP_SLO_OVERVIEW_MARKED_FALSE_POSITIVE = 'SLO Overview Marked False Positive';
export const T_WA_UP_MARKED_SLO_AFFECTED = 'Marked SLO Affected';
export const T_WA_UP_SLO_LIST_DOWNLOADED = 'SLO List Downloaded';
export const T_WA_UP_INCIDENT_SLO_AFFECTED = 'Incident SLO Affected';
export const T_WA_UP_MARKED_AS_FALSE_POSITIVE = 'Marked as False Positive';
export const T_WA_UP_UNDO_MARKED_AS_FALSE_POSITIVE = 'Undo Marked as False Positive';
export const T_WA_UP_CONTINUE_WITH_FREE_PLAN = 'Continue with free plan';
export const T_WA_UP_UPGRADE = 'Upgrade';
export const T_WA_UP_SUPPRESSED_BY_TIME_CHECKED = 'Suppressed by Time Checked';
export const T_WA_UP_ADD_SLOT_CLICKED = 'Add Slot Clicked';
export const T_WA_UP_DELETE_SLOT_CLICKED = 'Delete Slot Clicked';
export const T_WA_UP_WEBFORM_PAGE_VIEWED = 'Webform Page Viewed';
export const T_WA_UP_NEW_WEBFORM_CREATED = 'New Webform Created';
export const T_WA_UP_WEBFORM_EDITED = 'Webform Edited';
export const T_WA_UP_WEBFORM_DELETED = 'Webform Deleted';
export const T_WA_UP_UPDATE_BULK_MAINTENANCE_SERVICES = 'Update Bulk Maintenance Services';
export const T_WA_UP_UPDATE_SERVICE_TAG = 'Update Service Tag';
export const T_WA_UP_VIEW_DEPENDENCY = 'View Dependency';
export const T_WA_UP_SLACK_CHANNEL_MAPPED_TO_SERVICE = 'Slack Channel Mapped To Service';
export const T_WA_UP_JIRA_CLOUD_PROJECT_MAPPED_TO_SERVICE = 'Jira Cloud Project Mapped To Service';
export const T_WA_UP_INCIDENT_WATCHER_SUBSCRIBED = 'Incident Watcher Subscribed';
export const T_WA_UP_INCIDENT_WATCHER_UNSUBSCRIBED = 'Incident Watcher Unsubscribed';
export const T_WA_UP_INCIDENT_WATCHER_SUBSCRIBE_UPDATED_TO_INCIDENT_LIFECYCLE_WITH_NOTE =
  'Incident Watcher Subscribe Updated to Incident lifecycle with note';
export const T_WA_UP_INCIDENT_WATCHER_SUBSCRIBE_UPDATED_TO_ALL_ACTIVITY =
  'Incident Watcher Subscribe Updated to All Activity';
export const T_WA_UP_INCIDENT_WATCHER_SUBSCRIBE_UPDATED_TO_INCIDENT_LIFECYCLE =
  'Incident Watcher Subscribe Updated to Incident lifecycle';
export const T_WA_UP_ADD_ALERT_SOURCE = 'Add alert source';
export const T_WA_UP_INCIDENT_DETAILS_PAGE_VIEWED = 'Incident Details Page Viewed';
export const T_WA_UP_ANALYTICS_DATE_FILTER_APPLIED = 'Analytics Date Filter Applied';
export const T_WA_UP_WEBHOOK_PAGE_VIEWED = 'Webhook Page Viewed';
export const T_WA_UP_WEBHOOK_ADDED = 'Webhook Added';
export const T_WA_UP_WEBHOOK_EDITED = 'Webhook Edited';
export const T_WA_UP_WEBHOOK_DELETED = 'Webhook Deleted';
export const T_WA_UP_WEBHOOK_TESTED = 'Webhook Tested';
export const T_WA_UP_WEBHOOK_LOG_VIEWED = 'Webhook Log Viewed';
export const T_WA_UP_TEAM_LEVEL_ANALYTICS_VIEWED = 'Team level analytics viewed';
export const T_WA_UP_ANALYTICS_COMPARE_USED = 'Analytics Compare Used';
export const T_WA_UP_SCHEDULES_V2_PAGE_VIEWED = 'Schedules v2 Page Viewed';
export const T_WA_UP_SCHEDULES_V2_CREATED = 'Schedule v2 Created';
export const T_WA_UP_SCHEDULES_V2_EDITED = 'Schedule v2 Edited';
export const T_WA_UP_SCHEDULES_V2_OVERRIDEN = 'Schedule v2 Overriden';
export const T_WA_UP_SCHEDULES_V2_OVERRIDE_EDITED = 'Schedules v2 Override Edited';
export const T_WA_UP_SCHEDULES_V2_OVERRIDE_DELETED = 'Schedule v2 Overriden Deleted';
export const T_WA_UP_SCHEDULES_V2_PAUSED = 'Schedule v2 Paused';
export const T_WA_UP_SCHEDULES_V2_RESUMED = 'Schedule v2 Resumed';
export const T_WA_UP_SCHEDULES_V2_CLONED = 'Schedule v2 Cloned';
export const T_WA_UP_SCHEDULES_V2_ROTATION_EDITED = 'Schedules v2 Rotation Edited';
export const T_WA_UP_SCHEDULES_V2_DELETED = 'Schedule v2 Deleted';
export const T_WA_UP_SCHEDULES_V2_EXPORTED = 'Schedule v2 Exported';
export const T_WA_UP_SCHEDULES_V2_SEARCH_DONE = 'Schedules v2 Search Done';
export const T_WA_UP_SCHEDULES_V2_FILTER_APPLIED = 'Schedules v2 Filter Applied';
export const T_WA_UP_WHO_IS_ON_CALL_VIEWED = 'Who is On Call Viewed';
export const T_WA_UP_SCHEDULES_V2_PARTICIPANTS_EDITED = 'Schedule v2 Participants Edited';
export const T_WA_UP_SCHEDULES_V2_EXPANDED_IN_SCHEDULE_LIST_PAGE =
  'Schdeules v2 Expanded in Schedules List Page';
export const T_WA_UP_SCHEDULES_V2_VIEW_CHOSEN = 'Schedules v2 View Chosen';
export const T_WA_UP_SCHEDULE_V2_EXPORT_REFRESH_LINK_CLICKED =
  'Export Schedule Refresh Link Clikced';
export const T_WA_UP_SCHEDULE_V2_GAP_VIEWED = 'Schedule V2 Gaps Viewed';
export const T_WA_UP_SCHEDULE_V2_CUSTOM_ROTATION_ADDED = 'Custom Rotation Added';
export const T_WA_UP_SCHEDULE_V2_CREATION_STEP_1_PAGE_VIEWED =
  'Schedules V2 Creation Step 1 Page Viewed';
export const T_WA_UP_SCHEDULE_V2_CREATION_STEP_2_PAGE_VIEWED =
  'Schedules V2 Creation Step 2 Page Viewed';
export const T_WA_UP_SCHEDULE_V2_CREATION_STEP_3_PAGE_VIEWED =
  'Schedules V2 Creation Step 3 Page Viewed';
export const T_WA_UP_SCHEDULE_V2_DETAIL_PAGE_VIEWED = 'Schedules V2 Detail Page Viewed';
export const T_WA_UP_DEDUPE_KEYS_TAB_VIEWED = 'Dedupe Key Tab Viewed';
export const T_WA_UP_DEDUPE_KEYS_KEY_CREATED = 'Dedupe Key Created';
export const T_WA_UP_DEDUPE_KEYS_KEY_VIEWED = 'Dedupe Key Viewed';
export const T_WA_UP_DEDUPE_KEYS_KEY_DELETED = 'Dedupe Key Deleted';
export const T_WA_UP_DEDUPE_KEYS_SHOW_OUTPUT_BUTTON_CLICKED =
  'Dedupe Key Show Output Button Clicked';
export const T_WA_UP_DEDUPE_KEYS_KEY_COPIED = 'Dedupe Key Copied';
export const T_WA_UP_STATUS_PAGE_V2_CREATED = 'Status Page V2 Created';
export const T_WA_UP_STATUS_PAGE_V2_ISSUE_ADDED = 'Status Page V2 Issue Added';
export const T_WA_UP_STATUS_PAGE_V2_STATUS_UPDATED_MANUALLY =
  'Status Page V2 Status Updated Manually';
export const T_WA_UP_STATUS_PAGE_V2_EDITED = 'Status Page V2 Edited';
export const T_WA_UP_STATUS_PAGE_V2_CNAME_AND_TXT_VERIFIED =
  'Status Page V2 CNAME and TXT Verified';
export const T_WA_UP_STATUS_PAGE_V2_DELETED = 'Status Page V2 Deleted';
export const T_WA_UP_STATUS_PAGE_V2_MAINTENANCE_ADDED = 'Status Page V2 Maintenance Added';
export const T_WA_UP_STATUS_PAGE_V2_DETAILS_PAGE_VIEWED = 'Status Page V2 Details Page Viewed';
export const T_WA_UP_STATUS_PAGE_V2_LIST_PAGE_VIEWED = 'Status Page V2 List Page Viewed';
export const T_WA_UP_STATUS_PAGE_V2_CREATION_PAGE_1_VIEWED =
  'Status Page V2 Creation Page 1 Viewed';
export const T_WA_UP_STATUS_PAGE_V2_CREATION_PAGE_2_VIEWED =
  'Status Page V2 Creation Page 2 Viewed';
export const T_WA_UP_STATUS_PAGE_V2_CREATION_PAGE_3_VIEWED =
  'Status Page V2 Creation Page 3 Viewed';
export const T_WA_UP_STATUS_PAGE_V2_CREATION_PAGE_4_VIEWED =
  'Status Page V2 Creation Page 4 Viewed';
export const T_WA_UP_STATUS_PAGE_V2_SUBSCRIBERS_EXPORTED = 'Status Page V2 Subscribers Exported';
export const T_WA_UP_STATUS_PAGE_V2_MAINTENANCE_EDITED = 'Status Page V2 Maintenance Edited';
export const T_WA_UP_STATUS_PAGE_V2_MAINTENANCE_DELETED = 'Status Page V2 Maintenance Deleted';
export const T_WA_UP_STATUS_PAGE_V2_PAGE_STATUS_UPDATED_MANUALLY =
  'Status Page V2 Page Status Updated Manually';
export const T_WA_UP_STATUS_PAGE_V2_ISSUE_DELETED = 'Status Page V2 Issue Deleted';
export const T_WA_UP_STATUS_PAGE_V2_ISSUE_UPDATED = 'Status Page V2 Issue Updated';

export const T_WA_UP_INCIDENT_LIST_PAGE_V2_PAGE_VIEWED = 'Incident List Page V2 Viewed';
export const T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENT_ACKNOWLEDGED = 'Incident Acknowledged';
export const T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENT_REASSIGNED = 'Incident Reassigned';
export const T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENT_RESOLVED = 'Incident Resolved';
export const T_WA_UP_INCIDENT_PRIORITY_UPDATED = 'Incident Priority Updated';
export const T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENTS_MERGED = 'incidents_merged';
export const T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENTS_UNMERGED = 'incidents_unmerged';
export const T_WA_UP_INCIDENT_LIST_PAGE_V2_MERGE_PARENT_INCIDENTS =
  'merge_button_hover_for_2_parent_incidents';
export const T_WA_UP_INCIDENT_LIST_PAGE_INCIDENTS_DOWNLOADED = 'Incidents Downloaded';
export const T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_STARRED = 'Incident Starred';
export const T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_UNSTARRED = 'Incident Unstarred';
export const T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_SEARCH_PERFORMED = 'Incident Search Performed';
export const T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_SAVED_FILTER_CREATED = 'Saved Filter Created';
export const T_WA_UP_INCIDENT_LIST_PAGE_INCIDENT_FILTER_APPLIED = 'Incident Filter Applied';
export const T_WA_UP_CUSTOM_CONTENT_TEMPLATE_CREATED = 'Custom Content Template Created';
export const T_WA_UP_CUSTOM_CONTENT_TEMPLATE_EDITED = 'Custom Content Template Edited';
export const T_WA_UP_CUSTOM_CONTENT_TEMPLATE_DELETED = 'Custom Content Template Deleted';
export const T_WA_UP_PAST_INCIDENTS_TAB_VIEWED = 'Past Incidents Tab Viewed';
export const T_WA_UP_PAST_INCIDENTS_LIST_VIEWED = 'Past Incidents List Viewed';
export const T_WA_UP_PAST_INCIDENTS_LIST_SORTED = 'Past Incidents List Sorted';

// Auto Grouped Alerts (IAG)
export const T_WA_UP_IAG_VOTE_DOWN = 'IAG Alert Marked Negative';
export const T_WA_UP_IAG_VOTE_UP = 'IAG Alert Marked Positive';

// Global Event Rules (GER)
export const T_WA_UP_GER_LIST_PAGE_VIEWED = 'GER Listing Page Viewed';
export const T_WA_UP_GER_DETAIL_PAGE_VIEWED = 'GER Ruleset Details Page Viewed';
export const T_WA_UP_GER_RULESET_CREATED = 'GER Ruleset Created';
export const T_WA_UP_GER_RULESET_EDITED = 'GER Ruleset Edited';
export const T_WA_UP_GER_RULESET_DELETED = 'GER Ruleset Deleted';
export const T_WA_UP_GER_ALERT_SRC_CREATED = 'GER Alert Source Added';
export const T_WA_UP_GER_ALERT_SRC_DELETED = 'GER Alert Source Deleted';
export const T_WA_UP_GER_RULE_CREATED = 'GER Rule Created';
export const T_WA_UP_GER_RULE_EDITED = 'GER Rule Edited';
export const T_WA_UP_GER_RULE_DELETED = 'GER Rule Deleted';
export const T_WA_UP_GER_CATCH_ALL_RULE_CREATED = 'GER Catchall Rule Created';
export const T_WA_UP_GER_CATCH_ALL_RULE_EDITED = 'GER Catchall Rule Edited';
export const T_WA_UP_GER_CATCH_ALL_RULE_DELETED = 'GER Catchall Rule Deleted';
export const T_WA_UP_GER_RULE_REORDERED = 'GER Rules Reordered';

// Service Graph (SG)
export const T_WA_UP_SG_VIEWED = 'Service Graph Viewed';
export const T_WA_UP_SG_VIEW_REFRESH = 'Service Graph Refreshed';
export const T_WA_UP_SG_VIEW_RESET = 'Service Graph View Reset';
export const T_WA_UP_SG_VIEW_ZOOMED_IN = 'Service Graph View Zoomed In';
export const T_WA_UP_SG_VIEW_ZOOMED_OUT = 'Service Graph View Zoomed Out';
export const T_WA_UP_SG_NODE_MOVED = 'Service Graph Node Moved';
export const T_WA_UP_SG_NODE_DETAILS_VIEWED = 'Service Graph Node Details Viewed';
export const T_WA_UP_SG_FILTERED = 'Service Graph Filtered';
export const T_WA_UP_SG_SEARCHED = 'Service Graph Search Performed';

//Snooze Notifications (SN_INCIDENT)
export const T_WA_UP_SN_INCIDENT_SNOOZED = 'Incidents Snoozed';
export const T_WA_UP_SN_INCIDENT_UNSNOOZED = 'Incidents Unsnoozed';

// ServiceNow Extension (SNW_EXTENSION)
export const TA_WA_UP_SNW_EXTENSION_TESTED = 'ServiceNow Extension Tested';
export const TA_WA_UP_SNW_EXTENSION_INTEGRATED = 'ServiceNow Extension Integrated';
export const TA_WA_UP_SNW_EXTENSION_REVOKED = 'ServiceNow Extension Revoked';
export const TA_WA_UP_SNW_EXTENSION_EDITED = 'ServiceNow Extension Edited';

// Delayed Notifications (DN)
export const T_WA_UP_DN_ENABLED = 'Delayed Notifications Enabled';
export const T_WA_UP_DN_MODIFIED = 'Delayed Notification Config Changes';
//LCR
export const T_WA_UP_LCR_LIST_PAGE_VIEWED = 'LCR List Page Viewed';
export const T_WA_UP_LCR_CONFIG_CREATED = 'LCR Config Created';
export const T_WA_UP_LCR_CONFIG_UPDATED = 'LCR Config Edited';
export const T_WA_UP_LCR_CONFIG_DELETED = 'LCR Config Deleted';
export const T_WA_UP_LCR_CONFIG_ENABLED = 'LCR Enabled';
export const T_WA_UP_LCR_CONFIG_DISABLED = 'LCR Disabled';
export const T_WA_UP_LCR_RELOAD_BUTTON_CLICKED = 'LCR Routing Number Refresh Button Cliked';
export const T_WA_UP_LCR_DETAILS_PAGE_LOADED = 'LCR Details Page Viewed';
export const T_WA_UP_LCR__LOGS_PAGE_LOADED = 'LCR Logs Tab Viewed';
export const T_WA_UP_LCR__LOGS_FILTER_APPLIED = 'LCR Logs Filter Applied';
export const T_WA_UP_LCR__LOGS_SEARCH_PERFORMED = 'LCR Logs Search Performed';

//Stakeholders Group (SHG)
export const T_WA_UP_SHG_LIST_PAGE_VIEWED = 'Stakeholder Group List Page Viewed';
export const T_WA_UP_SHG_GROUP_CREATED = 'Stakeholder Group Created';
export const T_WA_UP_SHG_GROUP_MODIEFIED = 'Stakeholder Group Modified';
export const T_WA_UP_SHG_GROUP_DELETED = 'Stakeholder Group Deleted';

// Multiple Responders (MR)
export const T_WA_UP_MR_ADDED = 'Responders Added';
export const T_WA_UP_MR_REMOVED = 'Responders Removed';
export const T_WA_UP_MR_RESEND_NOTIFICATION = 'Responder Notification Resent';

//OBAC roles
export const T_WA_TEAMS_CREATED = 'Teams Created';
export const T_WA_USER_ADDED_TO_TEAM = 'Users Added to Team';
export const T_WA_USER_REMOVED_FROM_TEAM = 'Users Removed from Team';
export const T_WA_MEMBER_UPDATED_TO_OWNER = 'Team Member promoted to Team Owner';
export const T_WA_MEMBER_UPDATED_TO_MEMBER = 'Team Owner demoted to Team Member';
export const T_WA_TEAM_DELETED = 'Team Deleted';
export const T_WA_ENTITIES_TAB_VIEWED = 'Team Entities Tab Viewed';
export const T_WA_TEAM_ROLE_VIEWED = 'Team Roles Tab Viewed';
export const T_WA_SQUAD_CREATED = 'Squad Created';
export const T_WA_USER_ADDED_TO_SQUAD = 'Users Added to Squad';
export const T_WA_USER_REMOVED_FROM_SQUAD = 'Users Removed from Squad';
export const T_WA_SQUAD_MEMBER_UPDATED_TO_OWNER = 'Squad Member promoted to Squad Owner';
export const T_WA_SQUAD_MEMBER_UPDATED_TO_MEMBER = 'Squad Owner demoted to Squad Member';
export const T_WA_SQUAD_MODEL_VIEWED = 'Squad Roles Model Viewed';

//Audit Logs
export const T_WA_AUDIT_LOGS_PAGE_VIEWED = 'Audit Logs Viewed';
export const T_WA_AUDIT_LOGS_EXPORT_HISTORY_PAGE_VIEWED = 'Audit Logs Export History Viewed';
export const T_WA_AUDIT_LOGS_DETAILS_VIEWED = 'Audit Log Details Viewed';
export const T_WA_AUDIT_LOGS_EXPORTED = 'Audit Logs Exported';
export const T_WA_AUDIT_LOGS_FILTERED = 'Audit Logs Filtered';
export const T_WA_AUDIT_LOGS_EXPORT_DOWNLOADED = 'Audit Logs Export Downloaded';

// Incident Summary
export const T_WA_INCIDENT_SUMMARY_GENERATE_SUMMARY_BUTTON_CLICKED =
  'Generate Summary Button Clicked';
export const T_WA_INCIDENT_SUMMARY_INCIDENT_SUMMARY_GENERATED = 'Incident Summary Generated';
export const T_WA_INCIDENT_SUMMARY_THUMBS_UP_FEEDBACK_GIVEN =
  'Incident Summary Thumbs Up Feedback Given';
export const T_WA_INCIDENT_SUMMARY_THUMBS_DOWN_FEEDBACK_GIVEN =
  'Incident Summary Thumbs Down Feedback Given';
export const T_WA_INCIDENT_SUMMARY_NEGATIVE_FEEDBACK_GIVEN =
  'Incident Summary Negative Feedback Given';

// Statuspage component timeline override
export const T_WA_UP_STATUS_PAGE_V2_TIMELINE_OVERRIDE_COMPONENT_TIMELINE_EDITED =
  'Status Page V2 Component Timeline Edited';
export const T_WA_UP_STATUS_PAGE_V2_TIMELINE_OVERRIDE_EDIT_TIMELINE_MODAL_OPENED =
  'Status Page V2 Edit Timeline Modal Opened';
export const T_WA_UP_STATUS_PAGE_V2_TIMELINE_OVERRIDE_EDIT_TIMELINE_MODAL_RESET =
  'Status Page V2 Edit Timeline Modal Reset';
