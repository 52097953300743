import { API } from 'core';
import axios from 'axios';
import { AppConfig } from 'shared/app.config';
import { Member, MemberList } from '../types';

const url = AppConfig.api_url;

export const getAllMember = async (teamId: string, searchFilter?: string) => {
  const params: {
    team_id?: string;
    search_filter?: string;
  } = {};
  const data = await axios.get<MemberList>(
    `${url}/v3/organizations/${API.config.organizationId}/teams/${teamId}/members`,
    {
      params: params,
    },
  );
  return data;
};

export const addMembers = async (teamId: string, members: Member[]) => {
  const data = await axios.post(
    `${url}/v3/organizations/${API.config.organizationId}/teams/${teamId}/members/bulk`,
    {
      members,
    },
  );
  return data;
};

export const updateRole = async (
  teamId: string,
  memberId: string,
  role: string,
  replaceWith?: string,
) => {
  const data = await axios.patch(
    `${url}/v3/organizations/${API.config.organizationId}/teams/${teamId}/members/${memberId}`,
    {
      role,
      user_id: memberId,
      replace_with: replaceWith,
    },
  );
  return data;
};

export const deleteMember = async (teamId: string, memberId: string) => {
  const data = await axios.delete(
    `${url}/v3/organizations/${API.config.organizationId}/teams/${teamId}/members/${memberId}`,
  );
  return data;
};
