import React, { Fragment, useMemo } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import SQTooltip from 'components/chakra/Tooltip';
import { GetOverrideParticipantsQuery } from 'views/main/organization/schedules/graphql/query';
import ScheduleAvatarGroup from '../../common/AvatarGroup';
import { ScheduleActionTypes } from '../../constants/schedules.copy';
import { useGetAllPatternParticipants } from '../../helpers/helpers.customrotations';
import { getRotationInfos, mapParticipantToEventParticipant } from '../../helpers/helpers.event';
import { IRotationEventParticipant, IScheduleDetail } from '../../interface/schedule';
import { useScheduleActionsContext } from '../../schedules.actions/context';
import SchedulesDatepicker from '../../schedules.view/schedules.datepicker';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_SCHEDULE_V2_GAP_VIEWED } from 'core/const/tracker';
import useUINavigationFunctions from 'views/main/organization/navigation-flows/useUINavigationFunctionV2';
import { EntityType } from 'views/main/organization/navigation-flows/helpers';

import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { THEME_COLORS } from 'library/theme/colors';

interface ISchedulesInfoProps {
  schedule: IScheduleDetail | null;
  showGaps: boolean;
  onToggleGaps: () => void;
  selectedDate: Date;
  onDateSelect: (date: Date) => void;
  overrideParticipants?: NonNullable<GetOverrideParticipantsQuery['schedule']>['overrides'];
}

const dividerWidth = 'calc(100% + 2.5rem)';

function SchedulesInfo({
  schedule,
  showGaps,
  onToggleGaps,
  selectedDate,
  onDateSelect,
  overrideParticipants,
}: ISchedulesInfoProps) {
  const allParticipants = useGetAllPatternParticipants();
  const participants = useMemo(() => {
    const participants: Array<any> = [];
    return [
      ...((schedule?.rotations ?? [])
        .flatMap(r => (r.participantGroups ?? []).flatMap(pg => pg.participants))
        .filter(p => {
          //filter for unique participants only
          if (participants.includes((p as IRotationEventParticipant).ID)) {
            return false;
          } else {
            participants.push((p as IRotationEventParticipant).ID);
            return true;
          }
        }) ?? []),
      ...(overrideParticipants ?? []),
    ].map(p => mapParticipantToEventParticipant(p as IRotationEventParticipant, allParticipants));
  }, [schedule]);
  const { getEntityComponent } = useUINavigationFunctions();
  const rotationInfo = useMemo(() => (schedule ? getRotationInfos(schedule) : []), [schedule]);

  const { onActionClick, openEditSchedule } = useScheduleActionsContext();

  const hasUpdateAccess = useUserAccess().hasUpdateAccess;
  const hasDeleteAccess = useUserAccess().hasDeleteAccess;

  const hasUpdate = hasUpdateAccess('schedules', `${schedule?.ID ?? ''}`);

  const hasDelete = hasDeleteAccess('schedules', `${schedule?.ID ?? ''}`);

  return (
    <VStack alignItems="center" p={1} spacing={2} h="inherit">
      <SchedulesDatepicker inline date={selectedDate} setDate={onDateSelect} />

      <VStack px={4} w="100%" gap={6} alignItems="flex-start">
        <HStack justifyContent="space-between" w="inherit">
          <Text color="gray.700" fontWeight="semibold" fontSize="sm">
            Gaps
          </Text>

          <FormControl display="flex" alignItems="center" justifyContent="flex-end">
            <FormLabel
              htmlFor="view-gaps"
              mb="0"
              color="gray.700"
              fontSize="sm"
              fontWeight="normal"
            >
              View Gaps
            </FormLabel>
            <Switch
              id="view-gaps"
              size="sm"
              isChecked={showGaps}
              onChange={() => {
                onToggleGaps();
                AppTracker.track(T_WA_UP_SCHEDULE_V2_GAP_VIEWED);
              }}
            />
          </FormControl>
        </HStack>

        <VStack w="inherit" spacing={6}>
          <VStack w="inherit">
            {rotationInfo?.map((rinfo, index) => (
              <Fragment key={`rotation${index}`}>
                <HStack justifyContent="space-between" w="100%">
                  <SQTooltip text={rinfo.label} placement="top">
                    <Text color="gray.950" fontWeight="medium" maxW={150} isTruncated>
                      {rinfo.label}
                    </Text>
                  </SQTooltip>
                  <Text color="gray.950" fontWeight="medium">
                    {rinfo.time}
                  </Text>
                </HStack>
                <Divider w={dividerWidth} />
              </Fragment>
            ))}
          </VStack>
        </VStack>

        <VStack w="inherit" gap={4}>
          <HStack w="inherit" justifyContent="space-between">
            <Text color="gray.700" fontWeight="semibold" fontSize="sm">
              Participants
            </Text>
            <NoPermissionTooltip isDisabled={hasUpdate}>
              <Button
                variant="outlineBlue"
                onClick={() => {
                  schedule &&
                    onActionClick({
                      type: ScheduleActionTypes.EDIT_PARTICIPANTS,
                      params: {
                        scheduleId: schedule.ID,
                        scheduleName: schedule.name,
                      },
                    });
                  openEditSchedule();
                }}
                isDisabled={!hasUpdate}
              >
                Edit
              </Button>
            </NoPermissionTooltip>
          </HStack>

          <VStack w="inherit">
            {participants
              //filtering by name because IDs are still  there after editing participants but name won't , old code

              ?.filter(p => p.participant.name)
              .map(p => {
                const originalParticipantNames = p.isOverride
                  ? p.participant.originalParticipants?.map(p => p.name).join(', ')
                  : '';
                const simpleComponent = (
                  <Text
                    maxW={250}
                    isTruncated
                    color={THEME_COLORS.secondary[1300]}
                    fontWeight={'900'}
                    _hover={{ cursor: 'pointer' }}
                  >
                    {p.participant.name}
                  </Text>
                );

                const simpleComponentWithLinkStyles = (
                  <Text
                    maxW={250}
                    isTruncated
                    color={THEME_COLORS.secondary[1300]}
                    fontWeight={'800'}
                    _hover={{ cursor: 'pointer' }}
                  >
                    {p.participant.name}
                  </Text>
                );
                let entityComponent = simpleComponent;
                entityComponent = getEntityComponent({
                  componentType: p.type === 'squad' ? 'linkWOPopover' : 'linkWPopover',
                  type: p.type as EntityType,
                  id: p.ID,
                  popoverTrigger: simpleComponentWithLinkStyles,
                  popoverPlacement: 'left-start',
                });

                return (
                  <VStack w="inherit" key={p.ID}>
                    <HStack w="inherit">
                      <ScheduleAvatarGroup
                        size="sm"
                        avatars={
                          [p.type === 'squad' ? [{ name: p.participant.name }] : p.participant] as {
                            name: string;
                          }[]
                        }
                        wrapper={p.isOverride ? 'override' : undefined}
                      />

                      <Box>
                        {entityComponent}

                        {p.isOverride && (
                          <SQTooltip
                            text={`( originally on-call ${originalParticipantNames} )`}
                            placement="top"
                          >
                            <Text
                              fontSize="sm"
                              maxW={250}
                              isTruncated
                            >{`( originally on-call ${originalParticipantNames} )`}</Text>
                          </SQTooltip>
                        )}
                      </Box>
                    </HStack>
                    <Divider w={dividerWidth} />
                  </VStack>
                );
              })}
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
}

export default SchedulesInfo;
