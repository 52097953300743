export enum FilterTypes {
  SERVICE = 'service',
  ALERT_SOURCE = 'alert_source',
  TAGS = 'tag',
  PRIORITY = 'priority',
}

export enum CommunicationChannelTypes {
  VIDEO = 'video_conference',
  CHAT = 'chat_room',
  EXTERNAL = 'other',
}

export enum OperatorTypes {
  AND = 'and',
  IS = 'is',
  IS_NOT = 'is_not',
  CONTAINS = 'contains',
  CONTAINS_NOT = 'contains_not',
}

export enum LogicalOperatorTypes {
  AND = 'and',
  OR = 'or',
}

export enum ActionListActionType {
  GO_PREV,
  GO_NEXT,
  DELETE,
}

export enum ActionTypes {
  SQUADCAST = 'sq',
  SLACK = 'sl',
  MS_TEAMS = 'ms',
  JIRA = 'jr',
}

export enum SubActionTypes {
  COMMUNICATION_CHANNEL = 'sq_add_communication_channel',
  INCIDENT_NOTE = 'sq_add_incident_note',
  RESOLVE_INCIDENT = 'sq_resolve_incident',
  HTTP_CALL = 'sq_make_http_call',
  SLO_AFFECTED = 'sq_mark_incident_slo_affecting',
  RUNBOOKS = 'sq_attach_runbooks',
  REASSIGN_INCIDENT = 'sq_reassign_incident',
  TRIGGER_MANUAL_WEBHOOK = 'sq_trigger_manual_webhook',
  SEND_EMAIL = 'sq_send_email',
  PRIORITY = 'sq_update_incident_priority',
  STATUS_PAGE = 'sq_add_status_page_issue',
  SLACK_CHANNEL_ARCHIEVE = 'slack_archive_channel',
  SLACK_CHANNEL_CREATE = 'slack_create_incident_channel',
  SLACK_MESSAGE_CHANNEL = 'slack_message_channel',
  SLACK_MESSAGE_USER = 'slack_message_user',
  MS_TEAMS_MESSAGE_CHANNEL = 'msteams_message_channel',
  MS_TEAMS_MESSAGE_USER = 'msteams_message_user',
  MS_TEAMS_CREATE_MEETING_LINK = 'msteams_create_meeting_link',
  JIRA_CREATE_TICKET = 'jira_create_ticket',
  JIRA_CREATE = 'jira_create',
}

export enum WorkflowActionLogStatus {
  running = 'running',
  successful = 'successful',
  failed = 'failed',
  queued = 'queued',
}
