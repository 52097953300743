import React from 'react';
import { GridItem, Button, Grid, Box, IconButton } from '@chakra-ui/react';
import { CloseIcon } from 'icons';
import { Label } from 'uie/components';
import OnCallDuration from './onCallDuration';
import OnCallStartAndEndTime from './startAndEndTime';
import { rotationsCustomizePattern } from '../../../constants/rotations.customize';
import { ITimeSlot, InputMaybe } from '../../../interface/schedule';
import { DayOfWeek } from 'views/main/organization/schedules/graphql/types';

const DailyAndMonthlySlots = (props: {
  inputSlots: ITimeSlot[] | undefined;
  areSlotsValid: boolean;
  timeChangeHandler: (
    slotId: number | undefined,
    selectedValue: any,
    timefield: 'startTime' | 'endTime',
  ) => void;
  addSlotHandler: (weekday: InputMaybe<DayOfWeek> | undefined) => void;
  removeSlotHandler: (slotId: number | undefined) => void;
  timeOptions: Record<string, any>[];
}) => {
  const {
    inputSlots,
    areSlotsValid,
    timeChangeHandler,
    addSlotHandler,
    removeSlotHandler,
    timeOptions,
  } = props;
  const templateColumnsForFirstGrid = `${
    (inputSlots ?? []).length > 1 ? `0.92fr` : `0.96fr`
  } 0.5fr 0.2fr 0.2fr`;
  const templateRowsForFirstGrid = `repeat(1, 1fr)`;
  const templateColumnsForSecondGrid = `1.5fr 0.5fr 0.2fr 0.2fr`;
  const templateRowsForSecondGrid = `repeat(${(inputSlots ?? []).length}, 1fr)`;
  return (
    <>
      <Grid
        templateColumns={templateColumnsForFirstGrid}
        templateRows={templateRowsForFirstGrid}
        gap={2}
        alignItems="center"
        pl={3}
        pr={3}
        pt={3}
      >
        <GridItem>
          <Label>{rotationsCustomizePattern.labels.duration.onCallHours}</Label>
        </GridItem>
        <GridItem>
          <OnCallDuration inputSlots={inputSlots} />
        </GridItem>
        <GridItem></GridItem>
        <GridItem></GridItem>
      </Grid>

      <Box maxH={150} overflow="auto">
        <Grid
          templateColumns={templateColumnsForSecondGrid}
          templateRows={templateRowsForSecondGrid}
          gap={2}
          alignItems="center"
          p={3}
        >
          {inputSlots?.map((slot, index) => {
            return (
              <React.Fragment key={index}>
                {index === 0 ? (
                  <GridItem>
                    <Label>{rotationsCustomizePattern.labels.duration.onCallTime}</Label>
                  </GridItem>
                ) : (
                  <GridItem></GridItem>
                )}
                <GridItem>
                  <OnCallStartAndEndTime
                    onChangeStartTime={(selectedValue: any) =>
                      timeChangeHandler(slot.id, selectedValue, 'startTime')
                    }
                    onChangeEndTime={(selectedValue: any) =>
                      timeChangeHandler(slot.id, selectedValue, 'endTime')
                    }
                    startTimevalue={
                      typeof slot.startTime === 'string'
                        ? { label: slot.startTime, value: slot.startTime }
                        : slot.startTime
                    }
                    endTimevalue={
                      typeof slot.endTime === 'string'
                        ? { label: slot.endTime, value: slot.endTime }
                        : slot.endTime
                    }
                    startTimeOptions={timeOptions}
                    endTimeOptions={timeOptions}
                  />
                </GridItem>
                {index !== inputSlots.length - 1 ? (
                  <GridItem></GridItem>
                ) : (
                  <GridItem>
                    <Button
                      variant="link"
                      disabled={!areSlotsValid}
                      onClick={() => addSlotHandler(slot.dayOfWeek)}
                    >
                      {rotationsCustomizePattern.customRepeat.buttons.addSlot}
                    </Button>
                  </GridItem>
                )}
                {inputSlots.length > 1 ? (
                  <GridItem>
                    <IconButton
                      aria-label="remove-slot"
                      icon={<CloseIcon />}
                      variant="link"
                      color="red"
                      mt={2}
                      onClick={() => removeSlotHandler(slot.id)}
                      _hover={{ cursor: 'pointer' }}
                    />
                  </GridItem>
                ) : (
                  <GridItem></GridItem>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default DailyAndMonthlySlots;
