export const DASHBOARD_PATH = `/`;

export const ANALYTICS_V2_PATH = `/analytics-v2`;
export const ANALYTICS_V2_ORGANIZATION_PATH = `${ANALYTICS_V2_PATH}/organization`;
export const ANALYTICS_V2_TEAMS_PATH = `${ANALYTICS_V2_PATH}/teams`;
export const ANALYTICS_V2_TEAM_PATH = `${ANALYTICS_V2_PATH}/teams/:teamId`;
export const ANALYTICS_V2_SERVICES_PATH = `${ANALYTICS_V2_PATH}/services`;
export const ANALYTICS_V2_USERS_PATH = `${ANALYTICS_V2_PATH}/users`;

export const LEGACY_SCHEDULES_PATH = '/schedules';
export const SCHEDULES_V2_PATH = `/schedules-v2`;
export const SCHEDULES_V2_CREATE_PATH = `${SCHEDULES_V2_PATH}/create`;
export const SCHEDULES_V2_EDIT_PATH = `${SCHEDULES_V2_PATH}/edit/:scheduleId`;
export const SCHEDULES_V2_DETAIL_PATH = `${SCHEDULES_V2_PATH}/:scheduleId`;

export const SETTINGS_V2_PATH = `/settings-v2`;

//status page
export const STATUSPAGE_V2_PATH = `/statuspage-v2`;
export const STATUSPAGE_V2_CREATE_PATH = `${STATUSPAGE_V2_PATH}/create`;
export const STATUSPAGE_V2_DETAILS = `${STATUSPAGE_V2_PATH}/:id`;

//incident page
export const INCIDENT_DETAILS_PATH = `/incident/:id`;
export const INCIDENT_LIST_PATH = `/incident`;
export const INCIDENT_LIST_TAB = `/incident?tab=:tabNum`;

//Service catalog list page
export const SERVICE_LIST_PATH = `/service-catalog`;
export const SERVICE_DETAILS_PATH = `${SERVICE_LIST_PATH}/:id`;

//User profile page
export const USER_PROFILE_PATH = `/user/:id`;

//Escalation policy list page
export const ESCALATION_POLICIES_HOME_PATH = `/escalationpolicies`;
export const ESCALATION_POLICIES_LIST_PATH = `${ESCALATION_POLICIES_HOME_PATH}#:id`;

//Teams path
export const TEAMS_PATH = '/settings/teams/:id';

//Squads page
export const TEAM_SQUADS_PATH = `/settings/teams/:id/squads#:sqid`;

// Team Entities Path
export const TEAM_ENTITIES_PATH = `/settings/teams/:id/entities#:type`;
//team path was removed because generate path was not letting to use # for 2nd id

export const EXTENSION_PATH = '/settings/extension';

// workflow
export const WORKFLOWS_PATH = '/workflows';
export const WORKFLOWS_CREATE_PATH = `${WORKFLOWS_PATH}/create`;
export const WORKFLOWS_DETAILS_PATH = `${WORKFLOWS_PATH}/:id`;
export const WORKFLOWS_EDIT_PATH = `${WORKFLOWS_PATH}/:id/edit`;

// GER
export const GER_PATH = '/global-event-rulesets';
export const GER_CREATE_PATH = `${GER_PATH}/create`;
export const GER_EDIT_PATH = `${GER_PATH}/:id/edit`;
export const GER_DETAILS_PATH = `${GER_PATH}/:id`;

// Live call Routing

export const LCR_PATH = '/live-call-routing';
export const LCR_CREATE_PATH = `${LCR_PATH}/create`;
export const LCR_EDIT_PATH = `${LCR_PATH}/:id/edit`;
export const LCR_DETAILS_PATH = `${LCR_PATH}/:id`;
export const LCR_SESSIONS_PATH = `${LCR_PATH}/:id/:tabs`;
export const LCR_SESSIONS_DETAILS_PATH = `${LCR_PATH}/:id/:tabs/:sessionID`;

export const DETAIL_LISTING_PATH_MAPPINGS = {
  [ANALYTICS_V2_TEAM_PATH]: ANALYTICS_V2_PATH,
  [SCHEDULES_V2_DETAIL_PATH]: SCHEDULES_V2_PATH,
  [STATUSPAGE_V2_DETAILS]: STATUSPAGE_V2_PATH,
  [INCIDENT_DETAILS_PATH]: INCIDENT_LIST_PATH,
  [SERVICE_DETAILS_PATH]: SERVICE_LIST_PATH,
  [USER_PROFILE_PATH]: '/user', // Assuming the listing page for user profiles is '/user'
  [ESCALATION_POLICIES_LIST_PATH]: ESCALATION_POLICIES_HOME_PATH,
  [TEAM_SQUADS_PATH]: '/settings/teams', // Assuming the listing page for teams is '/settings/teams'
  [TEAM_ENTITIES_PATH]: '/settings/teams', // Assuming the listing page for team entities is '/settings/teams'
  [WORKFLOWS_DETAILS_PATH]: WORKFLOWS_PATH,
  [GER_DETAILS_PATH]: GER_PATH,
  [LCR_DETAILS_PATH]: LCR_PATH,
  [LCR_SESSIONS_PATH]: LCR_PATH,
  [LCR_SESSIONS_DETAILS_PATH]: LCR_PATH,
};
