import { Text, useBoolean } from '@chakra-ui/react';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService } from 'core';
import { Placeholder } from 'library/molecules';
import { THEME_COLORS } from 'library/theme/colors';
import { FC, useContext } from 'react';

import { OrganizationContext } from '../..';

type Props = {
  onCancel: () => void;
};

export const GraphPricing: FC<Props> = ({ onCancel }) => {
  const organization = useContext(OrganizationContext);
  const [showModal, setShowModal] = useBoolean(true);

  return (
    <>
      <Placeholder
        iconName="api-error.svg"
        description={
          <Text variant={'placeholder'} align="center" color="secondary.900">
            Upgrade your account to view Service Graph in action!
            <br />
            Reach out to{' '}
            <span style={{ color: THEME_COLORS.brand.blue }}>support@squadcast.com</span> to catch a
            live demo or for questions, if any.
          </Text>
        }
      />
      {organization && (
        <UpgradePlanModal
          hasBillingPermission={BillingService.hasManageBillingPermission({ organization })}
          showModal={showModal}
          message={BillingService.getMessage(0, 'service-graph', { organization })}
          onCancel={() => {
            setShowModal.off();
            onCancel();
          }}
          header={BillingService.getHeader(0, 'service-graph', { organization })}
          onUpgrade={setShowModal.off}
        />
      )}
    </>
  );
};
