import { Box, Divider, HStack, Text } from '@chakra-ui/react';
import { Formik } from 'formik';
import { useContext } from 'react';
import { HorzStepper } from 'views/shared/components';

import { OrganizationContext } from '../..';
import HelpGuide from '../../common/HelpGuide';
import {
  helpSupportWidth,
  SERVICE_CREATION_STEP,
  serviceFormWidth,
} from '../../contants/service.contant';
import { serviceHelpGuide } from '../../contants/service.guide';
import { IServiceSave, useCreateServiceCatalog } from '../../hooks/useCreateServiceCatalog';
import { useServiceOwnerQuery } from '../../hooks/useServiceOwner';
import AddServiceForm, { validateForm } from '../addAlertSourceForm/addServiceForm';
import { DEFAULT_DELAY_NOTIFICATION_CONFIG } from './delay-notifications/constant';

function StepperComp({ heading, subHeading }: { heading: string; subHeading: string }) {
  const { createServiceCatalog_, isCreating } = useCreateServiceCatalog();

  const organization = useContext(OrganizationContext);
  const { data: serviceOwners = [] } = useServiceOwnerQuery(
    organization?.selectedTeam.teamId ?? '',
  );

  const currentUser = organization?.currentUser?.u || ({} as any);

  const initialState = {
    owner_id: organization?.selectedTeam?.teamId,
    name: '',
    description: '',
    escalation_policy_id: '',
    tags: [
      { key: '', value: '' },
      { key: '', value: '' },
    ],
    duplicateTags: {} as Record<string, number>,
    maintainer: {
      id: currentUser!.id,
      type: 'user',
    },
    delay_notification_config_is_enabled: false,
    delay_notification_config: DEFAULT_DELAY_NOTIFICATION_CONFIG,
  };

  const onFormSubmit = (value: any) => {
    const formdata: IServiceSave = {
      owner_id: organization?.selectedTeam?.teamId,
      name: value.name,
      description: value.description,
      escalation_policy_id: value.escalation_policy_id,
      tags: value.tags,
      maintainer: value.maintainer.id
        ? {
            id: value.maintainer.id,
            type: value.maintainer.type,
          }
        : null,
      auto_pause_transient_alerts_config: {
        is_enabled: value.apta_is_enabled || false,
        timeout_in_mins: value.apta_is_enabled ? value.apta_timeout_in_mins || 0 : 0,
      },
      intelligent_alerts_grouping_config: {
        is_enabled: value.iag_is_enabled || false,
        rolling_window_in_mins: value.iag_is_enabled ? value.iag_rolling_window_in_mins || 0 : 0,
      },
      delay_notification_config: {
        ...value.delay_notification_config,
        is_enabled: value.delay_notification_config_is_enabled || false,
      },
    };

    createServiceCatalog_(formdata);
  };

  return (
    <>
      <HorzStepper steps={SERVICE_CREATION_STEP} currentStep={0} />
      <Divider variant="smokeShaded" />
      <HStack spacing={3} alignItems="flex-start">
        <Box
          w={serviceFormWidth}
          borderRightWidth="15px"
          borderRightStyle="solid"
          borderRightColor="gray.200"
        >
          <Box bg="white">
            <Box paddingX={6} paddingY={3} maxWidth="80%">
              <Text color="#000000" fontSize="24px">
                {heading}
              </Text>
              <Text fontSize="14px">{subHeading}</Text>
            </Box>
            <Formik
              validate={validateForm}
              initialValues={initialState}
              onSubmit={onFormSubmit}
              validateOnBlur={false}
              validateOnChange={false}
            >
              <AddServiceForm isLoading={isCreating} serviceOwners={serviceOwners} />
            </Formik>
          </Box>
        </Box>

        <HelpGuide helpSupportWidth={helpSupportWidth} helpGuide={serviceHelpGuide} />
      </HStack>
    </>
  );
}
export default StepperComp;
