import { PRIORITY, PRIORITY_OPTIONS } from 'library/common';
import { useMemo } from 'react';
import { MultiValue, OnChangeValue, SingleValue } from 'react-select';

import { FilterOptions } from '../const';
import { useSecondaryFilters } from '../context';
import { SelectOption } from '../types';
import DropdownContainer from './dropdown_container';
import { Select } from './select';

interface Props {
  selected: string[];
  isInitializedOpen?: boolean;
  isMulti?: boolean;
}

const getPriorityOptions = (
  options: Array<{
    label: string;
    value: PRIORITY;
  }>,
) => {
  return options.map(item => ({
    label: item.value !== PRIORITY.Unset ? item.value : item.label,
    value: item.value,
  }));
};

function Priority(props: Props): JSX.Element {
  const { updateFilter } = useSecondaryFilters();
  const { isInitializedOpen } = props;

  const priorityOptions = PRIORITY_OPTIONS;

  const [selectedOptions] = useMemo(() => {
    const sel = PRIORITY_OPTIONS.filter(t => {
      return props.selected.some(f => {
        return f === t.value;
      });
    });

    return [getPriorityOptions(sel)];
  }, [props.selected]);

  const handleChange = (newValue: OnChangeValue<SelectOption, boolean>) => {
    const arr: string[] = [];

    if (Array.isArray(newValue)) {
      const options = newValue as MultiValue<SelectOption>;
      options.map(o => {
        arr.push(o.value);
      });
    } else if (newValue) {
      const option = newValue as SingleValue<SelectOption>;
      if (option?.value) {
        arr.push(option.value);
      }
    }

    updateFilter('priority', arr);
  };

  return (
    <DropdownContainer
      selected={[]}
      filterKey="priority"
      label={FilterOptions['priority'].slice(0, props.isMulti ? undefined : -1)}
      isInitializedOpen={isInitializedOpen}
    >
      <Select
        options={priorityOptions}
        value={selectedOptions}
        onChange={handleChange}
        isLoading={!priorityOptions.length}
        isMulti={props.isMulti || undefined}
      />
    </DropdownContainer>
  );
}

export default Priority;
