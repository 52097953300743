import * as React from 'react';
import { SVGProps } from 'react';

const SvgRunscope = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#0a0b09',
      }}
      d="m17.205 9.356.009.048a.266.266 0 0 0 .26.214h1.554a.268.268 0 0 0 .26-.269 9.338 9.338 0 0 0-.603-2.243l.82-.67a.315.315 0 0 0 .067-.395l-.876-1.434a.315.315 0 0 0-.382-.121l-1.14.497a9.11 9.11 0 0 0-1.508-1.538l.464-1.138a.315.315 0 0 0-.13-.38l-1.456-.84a.315.315 0 0 0-.393.077l-.741.955a8.983 8.983 0 0 0-2.087-.567L11.146.265A.316.316 0 0 0 10.845 0h-1.68a.316.316 0 0 0-.303.265l-.176 1.282a8.991 8.991 0 0 0-2.089.56l-.744-.96a.315.315 0 0 0-.393-.077l-1.456.84a.315.315 0 0 0-.13.38l.463 1.135a9.105 9.105 0 0 0-1.514 1.532l-1.149-.491a.315.315 0 0 0-.381.123L.427 6.03a.315.315 0 0 0 .07.395l.807.649a9.31 9.31 0 0 0-.617 2.273c0 .147.116.269.26.271h1.52c.183-.006.292-.115.299-.252a7.343 7.343 0 0 1 7.222-6.04 7.33 7.33 0 0 1 7.217 6.03zM2.772 11.868a.261.261 0 0 0-.259-.211H.958a.265.265 0 0 0-.26.266C1.33 16.488 5.245 20 9.982 20c4.737 0 8.651-3.513 9.284-8.076v-.002a.264.264 0 0 0-.26-.264h-1.52c-.012-.002-.023-.004-.034-.004a.265.265 0 0 0-.265.252c-.001.003-.004.004-.004.006a7.315 7.315 0 0 1-14.402 0l-.009-.044"
    />
    <path
      style={{
        fill: '#3e64a0',
      }}
      d="M13.312 10.645a3.303 3.303 0 0 0-3.308-3.296 3.302 3.302 0 0 0-3.309 3.296 3.302 3.302 0 0 0 3.309 3.296 3.303 3.303 0 0 0 3.308-3.296"
    />
  </svg>
);

export default SvgRunscope;
