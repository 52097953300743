import {
  Box,
  Flex,
  Skeleton as SkeletonBox,
  Spinner as CircleSpinner,
  Progress as ProgressLoader,
} from '@chakra-ui/react';
import React, { Fragment, ReactNode } from 'react';

type SkeletonProps = {
  enabled: boolean;
  children: ReactNode;
  height?: string;
  maxW?: string;
  hasError?: boolean;
};

function Skeleton({ enabled, hasError, maxW, height, children }: SkeletonProps) {
  if (hasError) {
    return <>N/A</>;
  }
  return (
    <Fragment>
      {enabled ? (
        <Box maxW={maxW ?? '100%'}>
          <SkeletonBox height={height ?? 'auto'} />
        </Box>
      ) : (
        children
      )}
    </Fragment>
  );
}

interface SpinnerProps {
  centered?: boolean;
}
const Spinner = ({ centered }: SpinnerProps) => {
  return (
    <Flex
      justifyContent={centered ? 'center' : 'flex-start'}
      alignItems={centered ? 'center' : 'flex-start'}
    >
      <CircleSpinner speed="0.65s" emptyColor="gray.200" color="blue.500" size="lg" mt={2} />
    </Flex>
  );
};

const Progress = () => {
  return <ProgressLoader size="xs" isIndeterminate />;
};

const Loader = {
  Skeleton,
  Spinner,
  Progress,
};

export default Loader;
