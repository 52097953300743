import 'reactflow/dist/style.css';

import { useDisclosure } from '@chakra-ui/hooks';
import { Box, Button, ChakraProvider, HStack, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { API, deepCopy } from 'core';
import { T_WA_UP_SG_VIEWED } from 'core/const/tracker';
import { Tooltip } from 'library/atoms';
import {
  LibraryIcons,
  StatusAwaitingIcon,
  StatusHealthyIcon,
  StatusMaintenanceIcon,
  StatusUnhealthyIcon,
} from 'library/icons';
import { CustomDrawerComponent, Loader, Placeholder } from 'library/molecules';
import LibraryTheme from 'library/theme';
import { THEME_COLORS } from 'library/theme/colors';
import { FC, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { MarkerType } from 'reactflow';
import { AppTracker } from 'shared/analytics/tracker';

import { DEFAULT_GRAPH_COLOR, EDGE_STROKE, NODE_BORDER_WIDTH } from '../contants/service.contant';
import { invalGraphData } from '../helpers/helper.graph';
import { useGetServiceDependencies } from '../hooks/useGetServiceDependencies';
import { useGetServiceGraphData } from '../hooks/useGetServiceGraphData';
import {
  GRAPH_EDGE_LIST_TYPE,
  GRAPH_NODE_INTER_DATA,
  GRAPH_NODE_LIST_TYPE,
  GRAPH_SERVICES_LIST,
  SERVICE_HEALTH_STATUS,
  SERVICE_NODE_DETAIL_TYPE,
  SERVICE_NODE_TYPE,
} from '../Interfaces/graph';
import { ServiceGraphNodeDetail } from './detail-drawer';
import { Graph } from './graph';
import { ServicesInfoList } from './listing';

type Props = {
  onRefresh: () => void;
};

export const ServiceGraph: FC<Props> = ({ onRefresh }) => {
  const {
    services,
    isLoading,
    isFetching: isGraphDataFetching,
    isSuccess,
    isError,
  } = useGetServiceGraphData(API.config.teamId);
  const {
    deps,
    isLoading: isDepLoading,
    isFetching: isDepFetching,
    isSuccess: isDepSuccess,
    isError: isDepError,
  } = useGetServiceDependencies(API.config.teamId);
  const [nodes, setNodes] = useState<GRAPH_NODE_LIST_TYPE>([]);
  const [servicesData, setServicesData] = useState<GRAPH_SERVICES_LIST>({
    indepServices: [],
    depServices: [],
  });
  const [selectedNode, setSelectedNode] = useState<SERVICE_NODE_DETAIL_TYPE>();
  const [edges, setEdges] = useState<GRAPH_EDGE_LIST_TYPE>([]);

  const { isOpen, onToggle, onClose } = useDisclosure();
  const graphRef = useRef();
  const queryClient = useQueryClient();

  const onInval = () => {
    invalGraphData(queryClient);
  };

  const getGraphNodes = (servicesList: SERVICE_NODE_TYPE[]): GRAPH_NODE_INTER_DATA => {
    return servicesList.map(service => ({
      id: service.id,
      name: service.name,
      status: service.status,
      hasEdge: false,
      level: 0,
      depends: [],
    }));
  };

  const onSelectNode = (node: SERVICE_NODE_TYPE, isGraphNode?: boolean) => {
    setSelectedNode({ ...node, isGraphNode });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    graphRef.current?.setSelection(isGraphNode ? node.id : '');
  };

  const onGraphNodeSelect = (nodeId: string) => {
    const serviceNode = servicesData.depServices.find(({ id }) => id === nodeId);
    if (serviceNode) {
      setSelectedNode({ ...serviceNode, isGraphNode: true });
    }
  };

  const invalSelection = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    graphRef.current?.setSelection();
    setSelectedNode(undefined);
  };

  const getIcon = (status?: SERVICE_HEALTH_STATUS) => {
    switch (status) {
      case 'healthy':
        return StatusHealthyIcon;
      case 'unhealthy':
        return StatusUnhealthyIcon;
      case 'on_maintenance':
        return StatusMaintenanceIcon;
      default:
        return StatusAwaitingIcon;
    }
  };

  useEffect(() => {
    if (!isDepSuccess || !isSuccess) {
      return;
    }

    let hasEdges = false;
    if (deps?.length && services.length) {
      const graphData = getGraphNodes(services);
      const nodeEdges: GRAPH_EDGE_LIST_TYPE = [];
      let count = 0;

      deps.forEach(depItem => {
        if (depItem.depends.length) {
          const srcItem = graphData.find(item => item.id === depItem.id);
          if (srcItem) {
            srcItem.hasEdge = true;

            depItem.depends.forEach(target => {
              const targetItem = graphData.find(item => item.id === target);
              if (targetItem) {
                targetItem.hasEdge = true;
                targetItem.level = Math.max(srcItem.level + 1, targetItem.level);

                targetItem.depends.forEach(dependItem => {
                  dependItem.level = Math.max(targetItem.level + 1, dependItem.level);
                });

                srcItem.depends.push(targetItem);
              }
              nodeEdges.push({
                id: `edge-${count++}`,
                source: depItem.id,
                target,
                markerEnd: {
                  type: MarkerType.ArrowClosed,
                  color: DEFAULT_GRAPH_COLOR,
                },
                style: { strokeWidth: EDGE_STROKE },
              });
            });
          }
        }
      });
      hasEdges = !!nodeEdges.length;
      positionNodesAndEdges(graphData, nodeEdges);
    }

    AppTracker.track(T_WA_UP_SG_VIEWED, {
      'Service Graph Shown': hasEdges,
    });
  }, [deps, services]);

  useEffect(() => {
    const channelName = `${API.config.organizationId}-${API.config.teamId}-service`;
    const handler = (_event: any, _data: any) => {
      if (_data) {
        onRefresh();
      }
    };

    const soketi = API.socket.subscribe(channelName);
    soketi.bind_global(handler);

    return () => {
      soketi.unbind_global(handler);
    };
  }, []);

  useEffect(() => {
    if (!isGraphDataFetching && services?.length && nodes.length) {
      const cloneNodes: GRAPH_NODE_LIST_TYPE = deepCopy(nodes);

      cloneNodes.forEach(node => {
        const service = services.find(item => item.id === node.id);
        if (service) {
          node.data = getNodeData(service.name, service.status);
        }
      });

      setNodes(cloneNodes);
    }
  }, [isGraphDataFetching]);

  const getNodeData = (name: string, status?: SERVICE_HEALTH_STATUS) => {
    const icon = getIcon(status);
    return {
      label: (
        <Tooltip label={name} placement="right">
          <HStack gap={2} alignItems="center" cursor="pointer">
            <Icon w="24px" h="24px" as={icon} />
            <Text
              fontSize="14px"
              textAlign="left"
              textOverflow="ellipsis"
              noOfLines={1}
              fontWeight="800"
            >
              {name}
            </Text>
          </HStack>
        </Tooltip>
      ),
      type: status,
    };
  };

  const positionNodesAndEdges = (
    graphData: GRAPH_NODE_INTER_DATA,
    nodeEdges: GRAPH_EDGE_LIST_TYPE,
  ) => {
    const graphNodesData = graphData.filter(data => data.hasEdge).sort((a, b) => a.level - b.level);
    const indepServices = graphData.filter(data => !data.hasEdge);

    setServicesData({
      depServices: graphNodesData,
      indepServices,
    });

    const graphNodes: GRAPH_NODE_LIST_TYPE = [];
    graphNodesData.forEach(nodeData => {
      if (!nodeData) return;

      graphNodes.push({
        id: nodeData.id,
        data: getNodeData(nodeData.name, nodeData.status),
        position: {
          x: 0,
          y: 0,
        },
        style: {
          borderWidth: NODE_BORDER_WIDTH,
          boxShadow: 'none',
        },
      });
    });
    setNodes(graphNodes);
    setEdges(nodeEdges);
  };

  if (isLoading || isDepLoading || isDepFetching) {
    return <Loader />;
  }

  if (isError || isDepError) {
    return (
      <VStack>
        <Placeholder
          iconName="api-error.svg"
          description={
            <Text variant={'placeholder'} align="center" color="secondary.900">
              We’re experiencing an unexpected error. Try reloading this page.
              <br />
              Reach out to{' '}
              <span style={{ color: THEME_COLORS.brand.blue }}>support@squadcast.com</span> if you
              continue to face the same issue.
            </Text>
          }
        />
        <Button onClick={onInval} variant="default" size="sm">
          Refresh now
        </Button>
      </VStack>
    );
  }

  if (!edges.length) {
    return (
      <Placeholder
        iconName="no-dependency.svg"
        description={
          <Text variant={'placeholder'} align="center" color="secondary.900">
            There are no dependency relations to show yet!
            <br />
            Pro Tip: Use our{' '}
            <Link
              href="https://apidocs.squadcast.com/#dea510f2-c779-47b9-9d0b-18e06e6ad912"
              isExternal
              color={THEME_COLORS.brand.blue}
            >
              Dependencies API
            </Link>{' '}
            to quickly add all your service relationships.
          </Text>
        }
      />
    );
  }

  return (
    <ChakraProvider theme={LibraryTheme} portalZIndex={40}>
      <Box
        display="flex"
        flexDir="row-reverse"
        style={{ width: '100%', height: 'calc(100vh - 266px)', overflow: 'auto' }}
      >
        <Box
          style={{
            position: 'absolute',
            top: '52%',
            background: 'white',
            width: '28px',
            height: '56px',
            zIndex: 10,
            cursor: 'pointer',
            borderRadius: '50px 0px 0 50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px solid #E1E6EB',
            borderRight: 'none',
          }}
          onClick={onToggle}
        >
          <LibraryIcons.ChevronLeftIcon color="secondary.1000" width="24px" height="24px" />
        </Box>
        <Graph
          ref={graphRef}
          initialEdges={edges}
          initialNodes={nodes}
          onSelect={onGraphNodeSelect}
        />
      </Box>
      <CustomDrawerComponent
        size="sm"
        isOpen={isOpen}
        onClose={onClose}
        title="All Services"
        disableBodyPadding
        variant="alwaysOpen"
        trapFocus={false}
        renderHeaderDivider={false}
        lockFocusAcrossFrames={false}
      >
        <ServicesInfoList
          indepServices={servicesData.indepServices}
          depServices={servicesData.depServices}
          onClose={onClose}
          onSelect={onSelectNode}
        />
      </CustomDrawerComponent>
      {selectedNode && (
        <ServiceGraphNodeDetail
          isOpen={!!selectedNode}
          onClose={invalSelection}
          serviceNode={selectedNode}
        />
      )}
    </ChakraProvider>
  );
};
