import { createStandaloneToast } from '@chakra-ui/react';
import { EscalationIcon, ProfileIcon, SquadIcon } from 'icons';
import React from 'react';

type ComponentState = 'loading' | 'failed' | 'done';

export function getPager(totalRows: number, currentpage: number, pageLimit: number): Array<number> {
  if (totalRows <= pageLimit) return [1];
  const totalPages = Math.ceil(totalRows / pageLimit);
  const totalPageArray = Array.from(Array(totalPages), (_, i) => i + 1);
  const currentPagination = getCurrentPaginationList(totalPageArray, currentpage);
  return currentPagination;
}

function getCurrentPaginationList(totalPages: Array<number>, currentPage: number): Array<number> {
  return totalPages;
}

export const getLoadingState = (
  isLoading: boolean,
  isSuccess: boolean,
  isError: boolean,
): ComponentState => {
  if (isLoading) return 'loading';
  if (isSuccess) return 'done';
  if (isError) return 'failed';

  return 'loading';
};

export const commonAddAlertSource = (id: string, selected: any) => {
  let data: any = [];
  if (selected.includes(id)) {
    data = selected.filter((item: any) => item !== id);
  } else {
    data = [...selected, id];
  }
  return data;
};

export const getStatus = (status: 'on_maintenance' | 'healthy' | 'unhealthy' | string) => {
  if (status === 'on_maintenance') return 'Under Maintenance';
  if (status === 'healthy') return 'Healthy';
  if (status === 'unhealthy') return 'Needs Attention';
  return status;
};

export const getStatusTooltip = (status: 'on_maintenance' | 'healthy' | 'unhealthy' | string) => {
  if (status === 'on_maintenance')
    return 'Indicates that the service is currently under maintenance.';
  if (status === 'healthy') return 'Indicates that there are no open incidents for this service.';
  if (status === 'unhealthy')
    return 'Indicates that the service has open incidents that need your attention.';
  return status;
};

export const getStatusColor = (status: 'on_maintenance' | 'healthy' | 'unhealthy' | string) => {
  if (status === 'on_maintenance') return 'gray.400';
  if (status === 'healthy') return 'green.400';
  return 'orange.400';
};

const toast = createStandaloneToast();

export const reactQueryConfigError = (error: any) => {
  let title = error?.response?.data?.meta?.error_message;
  let description = '';

  if (title === 'Something went wrong') {
    title = 'There’s an unexpected error. Please refresh and try again.';
    description = 'If this doesn’t work, chat with us from the widget.';
  }

  toast.closeAll();
  toast({
    title,
    status: 'error',
    variant: 'subtle',
    isClosable: true,
    description,
    containerStyle: {
      fontSize: 14,
      lineHeight: 10,
    },
  });
};

export const reactQueryConfigSuccess = (message: string) => {
  toast({
    title: message,
    status: 'success',
    duration: 9000,
    isClosable: true,
  });
};

export const imgMap: any = {
  user: <ProfileIcon height={14} width={14} />,
  squad: <SquadIcon height={14} width={14} />,
  escalationpolicy: <EscalationIcon height={14} width={14} />,
  '': <></>,
};
