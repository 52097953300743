import React from 'react';
import { NewStatusPageModal, urlValidator } from '.';
import { Row, ErrorText, CheckBox, Input } from '@squadcast/alchemy-ui/carbon';
import { InputBox, TextButton } from '@squadcast/alchemy-ui/metal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AppConfig } from 'shared/app.config';

function renderPageConfig(this: NewStatusPageModal) {
  return (
    <div>
      <Row flexWidth={12}>
        <Row flexWidth={6}>
          <div className="w-1-1 mr-10">
            <InputBox
              id="sp_name"
              name="name"
              type="text"
              label="Name"
              placeHolder="Eg. Public Status Page"
              value={this.state.name}
              onChange={this.onInputChange}
              error={this.state.errors.name}
            />
          </div>
        </Row>
        <Row flexWidth={6}>
          <div className="w-1-1 ml-10">
            <InputBox
              id="sp_page_url"
              name="pageUrl"
              type="text"
              label="Page Hostname"
              placeHolder="Eg. status.newrelic.com"
              value={this.state.pageUrl}
              onChange={this.onInputChange}
              validator={urlValidator}
              error={this.state.errors.pageUrl}
            />
          </div>
        </Row>
      </Row>

      <Row flexWidth={12}>
        <div className="w-1-1 mt-10">
          <label className="item-box-tagline font-bold" htmlFor={'cname'}>
            CNAME
          </label>
          <div className="font-bold item-box-tagline cursor-pointer description-key mt-10">
            <p style={{ wordBreak: 'break-all', marginRight: 120 }}>{AppConfig.status_page_url}</p>
            <CopyToClipboard
              onCopy={() => {
                this.setState({
                  copied: true,
                });
              }}
              text={AppConfig.status_page_url}
            >
              <button className="copy-button">
                {this.state.copied ? <span>Copied!</span> : <span>Copy</span>}
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </Row>

      <div className="mt-20">
        <TextButton id="page_1_save" role="button" text="Save & Next" onClick={this.onNext} />
      </div>
    </div>
  );
}

function renderServicesConfig(this: NewStatusPageModal) {
  return (
    <div>
      <Row flexWidth={12} alignItems="center">
        <Row flexWidth={8}>
          <div className="field-parent-div w-1-1">
            <p className="modal-container-sub-heading mt-10 mb-10">Add Services</p>
            <div style={{ position: 'relative' }}>
              <input
                id="squad-member"
                type="text"
                autoComplete="off"
                className={'input-design '}
                placeholder="Search Services by Name"
                value={this.state.searchString}
                onChange={event => {
                  this.setState({ searchString: event.target.value });
                }}
                onFocus={() => {
                  this.setState({ showDropDown: true });
                }}
                onBlur={() => {
                  setTimeout(() => {
                    this.setState({ showDropDown: false });
                  }, 300);
                }}
              />

              {this.state.showDropDown && (
                <div className="search-drop-down">
                  {this.props.organization.services.s.length && (
                    <div>
                      {this.props.organization.services.s
                        .filter(
                          service => this.state.services.findIndex(s => s.id === service.id) === -1,
                        )
                        .filter(
                          service =>
                            service.name
                              .toLowerCase()
                              .indexOf(this.state.searchString.toLowerCase()) > -1,
                        )
                        .map((service, index) => {
                          return (
                            <div
                              onClick={() => {
                                this.addToServiceArray(service.id, service.name);
                              }}
                              className="search-drop-down-item-content no-select cursor-pointer"
                              key={index}
                            >
                              <img src="/icons/greys/services.svg" alt="services" />
                              &nbsp;
                              <span>{service.name}</span>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Row>
        <Row flexWidth={4}>
          <div className="mt-20 ml-20">
            <CheckBox
              id="user_agree"
              name="termsAndConditions"
              label="User Terms And Services"
              checked={this.state.selectAllServices}
              onChange={this.onSelectAllServices}
            >
              <span style={{ color: 'black' }}>
                {!this.state.selectAllServices ? 'Select' : 'De-select'} all services
              </span>
            </CheckBox>
          </div>
        </Row>
      </Row>
      <div className="mt-20">
        {this.state.services.length > 0 && (
          <p
            className="modal-container-sub-heading"
            style={{ marginTop: 20, marginBottom: 10, textTransform: 'none' }}
          >
            {this.state.services.length} service(s) selected
          </p>
        )}
        <div className="mt-10">
          {this.state.services.map((service, index) => {
            return (
              <div key={index} className="main-rule-rectangle">
                <div className="">
                  <Row flexWidth={12}>
                    <Row flexWidth={5}>
                      <div className="item-member" key={index} style={{ marginTop: 5 }}>
                        <img
                          src="/icons/greys/services.svg"
                          className="item-member-icon"
                          alt="services"
                        />
                        <span>{service.name}</span>
                      </div>
                    </Row>
                    <Row flexWidth={7}>
                      <div className="w-1-1 mr-20">
                        <Input
                          id={`${service.id}_alias`}
                          name={service.name}
                          type="text"
                          label={`${service.name} alias`}
                          placeHolder="alias"
                          value={service.alias}
                          onChange={(_, value) => {
                            this.onServiceAliasChange(service.id, value);
                          }}
                          invalid={this.state.errors.services.indexOf('Aliases') > -1}
                        />
                      </div>
                    </Row>
                  </Row>
                </div>
                <img
                  id="rule-delete"
                  src="/icons/delete-red@3x.png"
                  className="delete-icon-rule cursor-pointer"
                  onClick={() => {
                    this.removeService(service.id);
                  }}
                  alt="delete"
                />
              </div>
            );
          })}
        </div>

        <div className="mt-20">
          <TextButton
            id="save_create"
            onClick={this.createNewPage}
            text={this.state.componentState === 'new' ? 'Save & Create' : 'Save'}
            role="button"
            loading={this.state.loading}
          />
          {this.state.errors.services !== '' && <ErrorText text={this.state.errors.services} />}
          {this.state.errors.network !== '' && (
            <div style={{ marginTop: 10 }}>
              <ErrorText text={this.state.errors.network} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export { renderPageConfig, renderServicesConfig };
