import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.173 9.212c0 1.885-.751 3.413-1.678 3.413-.927 0-1.679-1.528-1.679-3.413 0-1.885.752-3.413 1.679-3.413s1.678 1.528 1.678 3.413ZM7.505 12.625c.926 0 1.678-1.528 1.678-3.413 0-1.885-.752-3.413-1.678-3.413-.927 0-1.679 1.528-1.679 3.413 0 1.885.752 3.413 1.679 3.413Z"
      fill="#1347FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.784 0 0 2.595v14.821L1.784 20h16.432L20 17.416V2.584L18.216 0H1.784Zm.467 3.812.697-.988h14.125l.672.99v12.39l-.672.989H2.948l-.697-.988V3.812Z"
      fill="#1347FF"
    />
  </svg>
);

export default SvgComponent;
