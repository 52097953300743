import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { EventWebhookHooksService, exception } from 'core';
import {
  IEventHookServiceEntity,
  IEventTestWebhookPayload,
  IEventWebHook,
} from 'core/interfaces/IEventWebHooks';
import { MoreVerticalIcon } from 'icons';
import { fontTheme } from 'library/theme';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Cell } from 'react-table';
import { CustomDrawerComponent, ErrorBoundary, Loader } from 'views/shared/components';
import { useChakraToast } from 'views/shared/hooks';

import {
  T_WA_UP_WEBHOOK_DELETED,
  T_WA_UP_WEBHOOK_PAGE_VIEWED,
} from '../../../../../core/const/tracker';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { AlertDialogComponent } from '../../../../shared/components';
import DataTable from '../../service-catalog/components/Table';
import { EVENT_TEMPLATES_CODE } from './choose-payload/event-templates';
import ViewLogs from './view-logs/view-logs';
import { WEBHOOK_LIST_COLUMN_DEFS } from './webhook-column-defs';
import { PAYLOAD_TYPES, WEBHOOK_STEPPER_INDEX, WEBHOOK_TYPES } from './webhook-const';
import styles from './webhookV2.module.css';

const WEBHOOK_INFO = {
  id: '',
  name: '',
};

const enum WEBHOOK_PAGE_ACTIONS {
  NONE,
  OPEN_DRAWER,
  OPEN_ALERT_DIALOG,
}

const WebhookV2 = () => {
  const _eventWebHookService = new EventWebhookHooksService();

  const [webhooksData, setwebhooksData] = useState([] as IEventWebHook[]);
  const [services, setServices] = useState<IEventHookServiceEntity[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [webhookInfo, setWebhookInfo] = useState(WEBHOOK_INFO);
  const [action, setAction] = useState(WEBHOOK_PAGE_ACTIONS.NONE);

  const [showErrorToast, showSuccessToast] = useChakraToast();

  useEffect(() => {
    _eventWebHookService
      .getServices()
      .then(({ data }) => {
        setServices(data.data);
      })
      .catch((err: any) => {
        exception.handle('E_GET_ALL_WEB_HOOKS', {
          message: 'unable to get services',
          err,
        });
      });
  }, []);

  const fetchWebhookData = () => {
    setIsLoading(true);
    const offset = (pageIndex - 1) * pageLimit;
    _eventWebHookService
      .getPaginatedList(offset, pageLimit)
      .then(({ data: { data } }) => {
        setIsLoading(false);
        setwebhooksData(data.result);
        setTotalCount(data.meta.total_count);
      })
      .catch((err: any) => {
        setIsLoading(false);
        exception.handle('E_GET_ALL_WEB_HOOKS', {
          message: 'unable to get all webhooks',
          err,
        });
      });
    AppTracker.track(T_WA_UP_WEBHOOK_PAGE_VIEWED);
  };

  useEffect(() => {
    fetchWebhookData();
  }, [pageIndex, pageLimit]);

  const testPayload = (webhookId: string) => {
    _eventWebHookService
      .get(webhookId)
      .then(({ data: { data } }) => {
        const testUrlObj = data.urls[0];
        const payload =
          data.payload_type === PAYLOAD_TYPES.CUSTOM
            ? data.custom_payload
            : EVENT_TEMPLATES_CODE[0].payload_content;

        let payloadData: IEventTestWebhookPayload = {
          method: testUrlObj.method,
          url: testUrlObj.url,
          payload,
          header: data.header,
          trigger_type: data.trigger_type ?? '',
          payload_type: data.payload_type ?? '',
        };

        if (data.trigger_type === WEBHOOK_TYPES.AUTOMATIC) {
          payloadData = { ...payloadData, event_trigger: data.triggers[0] };
        }

        _eventWebHookService
          .testWebhook(payloadData)
          .then(({ data: { data } }) => {
            showSuccessToast(data);
          })
          .catch(err => {
            const msg = err.response?.data?.meta?.error_message ?? 'Test Webhook failed';
            showErrorToast(msg);
          });
      })
      .catch(err => showErrorToast('error occurred while fetching webhook data!'));
  };

  const history = useHistory();

  const createWebhook = () => {
    history.push('/settings/webhook/create', {
      services,
    });
  };

  const editWebhook = (stepperIndex: WEBHOOK_STEPPER_INDEX, webhookId: string) => {
    history.push(`/settings/webhooks/${webhookId}`, {
      services,
      stepperIndex,
      webhookId,
    });
  };

  const closeWebhookAction = () => {
    setWebhookInfo(WEBHOOK_INFO);
    setAction(WEBHOOK_PAGE_ACTIONS.NONE);
  };

  const WebhookAction = ({ rowData }: { rowData: any }) => {
    const webhookId = rowData?.row?.original?.id || null;

    return (
      <Flex gap={3} alignSelf="center">
        <Button
          className={styles.cancelBtn}
          onClick={() => {
            setWebhookInfo({
              id: webhookId,
              name: rowData?.row?.original?.name,
            });
            setAction(WEBHOOK_PAGE_ACTIONS.OPEN_DRAWER);
          }}
        >
          View Logs
        </Button>
        <Button
          className={styles.cancelBtn}
          onClick={() => {
            editWebhook(WEBHOOK_STEPPER_INDEX.ADD_DETAILS, webhookId);
          }}
        >
          Edit Webhook
        </Button>
      </Flex>
    );
  };

  const deleteWebhook = () => {
    _eventWebHookService
      .delete(webhookInfo.id)
      .then(() => {
        setwebhooksData(webhooksData.filter(webhook => webhook.id !== webhookInfo.id));
        setTotalCount(totalCount - 1);
        showSuccessToast(`Webhook ${webhookInfo.name} deleted successfully!!`);
        closeWebhookAction();
        AppTracker.track(T_WA_UP_WEBHOOK_DELETED);
      })
      .catch(err => {
        showErrorToast(`An error occurred while deleting webhhook`);
        closeWebhookAction();
      });
  };

  const columnDefs = [
    ...WEBHOOK_LIST_COLUMN_DEFS,
    {
      Header: ' ',
      Cell: (cell: Cell<IEventWebHook>) => {
        return (
          <Menu isLazy>
            <MenuButton
              as={IconButton}
              className={styles.menuBtn}
              icon={<MoreVerticalIcon />}
              variant="unstyled"
            />
            <MenuList>
              <MenuItem
                onClick={() => {
                  setWebhookInfo({
                    id: cell.row.original.id,
                    name: cell.row.original.name,
                  });
                  setAction(WEBHOOK_PAGE_ACTIONS.OPEN_ALERT_DIALOG);
                }}
              >
                Delete
              </MenuItem>
              <MenuItem
                onClick={() => {
                  testPayload(cell.row.original.id);
                }}
              >
                Test Webhook
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
    },
  ];

  return (
    <ChakraProvider theme={fontTheme}>
      <ErrorBoundary>
        <HStack alignItems="center" justifyContent="space-between" marginRight="32px">
          <Box paddingX={6} paddingY={3} maxWidth="80%">
            <Text className={styles.heading}>Webhooks</Text>
            <Text className={styles.subheading}>
              Use outgoing webhooks to automate/integrate with third-party tools
            </Text>
          </Box>
          <Button className={styles.submitBtn} onClick={createWebhook} variant="solid">
            Add Webhook
          </Button>
        </HStack>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {!webhooksData.length ? (
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '100px',
                }}
              >
                <img
                  style={{
                    display: 'inline-block',
                    width: '300px',
                  }}
                  src="/icons/empty-states/webhooks.svg"
                  alt="No Webhooks"
                />
                <p
                  style={{
                    fontSize: '20px',
                    lineHeight: '24px',
                    fontWeight: 400,
                    color: '#000',
                    margin: '0px',
                  }}
                >
                  No webhooks found
                </p>
              </div>
            ) : (
              <>
                <DataTable
                  columns={columnDefs}
                  data={webhooksData}
                  hoverView={<WebhookAction rowData="." />}
                  paginationProps={{
                    queryPageIndex: pageIndex,
                    queryPageSize: pageLimit,
                    totalCount: totalCount,
                    pageChange: (queryPageIndex: number) => {
                      setPageIndex(queryPageIndex);
                    },
                    pageSizeChange: (queryPageSize: number) => {
                      setPageLimit(queryPageSize);
                      setPageIndex(1);
                    },
                  }}
                />
                <CustomDrawerComponent
                  onClose={closeWebhookAction}
                  isOpen={action === WEBHOOK_PAGE_ACTIONS.OPEN_DRAWER}
                  title="View Logs"
                  showBackIcon
                >
                  <Box className={styles.form}>
                    <ViewLogs webhookId={webhookInfo.id} webhookName={webhookInfo.name} />
                  </Box>
                </CustomDrawerComponent>
                <AlertDialogComponent
                  isOpen={action === WEBHOOK_PAGE_ACTIONS.OPEN_ALERT_DIALOG}
                  onClose={closeWebhookAction}
                  callbackFn={deleteWebhook}
                  msg={'Are you sure you want to delete this webhook?'}
                  title={'Delete Webhook'}
                  isDelete={true}
                />
              </>
            )}
          </>
        )}
      </ErrorBoundary>
    </ChakraProvider>
  );
};

export default WebhookV2;
