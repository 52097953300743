import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { EntityObjectType, EntityType, hasReadAccessToEntity } from '../helpers';
import React from 'react';
import { Link, generatePath } from 'react-router-dom';
import { ESCALATION_POLICIES_LIST_PATH, TEAM_SQUADS_PATH } from 'views/main/routes/routes';
import { linkStyles } from '../useUINavigationFunctionV2';
import { ServiceDetail } from '../../service-catalog/hooks/useServiceDetail';
import { useGetEntityName } from '../../ger/util';
import { IAppState } from 'core/interfaces/IAppState';
import { Box } from '@chakra-ui/layout';

const EntityName = (type: EntityType, id: string) => {
  return useGetEntityName(type, id);
};
export const PopoverSection = ({
  entityType,
  entity,
  getEntityLink,
  organization,
}: {
  entityType: EntityType;
  entity: EntityObjectType;
  getEntityLink: (type: EntityType, entityId: string | undefined) => string;
  organization: IAppState['organization'];
}) => {
  const getPopoverSections = () => {
    switch (entityType) {
      case 'user': {
        const user = entity as IUsersOfOrganization;
        const userEmail = user.email;
        const userContact = user.contact;

        const userSquads = organization.squads.s
          .filter(squads => squads.members.find(member => member === user.id))
          .map((squad, index) => {
            return (
              <React.Fragment key={squad.id}>
                {index > 0 && ', '}
                <Link
                  to={generatePath(TEAM_SQUADS_PATH, {
                    id: organization.selectedTeam.teamId,
                    sqid: squad.id,
                  })}
                  style={{ ...linkStyles }}
                >
                  {squad.name}
                </Link>
              </React.Fragment>
            );
          });

        return [
          { heading: 'Email', text: userEmail, link: `mailto:${userEmail}` },
          {
            heading: 'phone & sms',
            text: `${userContact.dial_code}-${userContact.phone_number}`,
          },
          {
            heading: 'squads',
            text:
              userSquads.length > 0 ? (
                <Box height="20dvh" overflow="auto">
                  {userSquads}
                </Box>
              ) : (
                <span>Not a member of any Squad</span>
              ),
          },
        ];
      }
      case 'service': {
        const service = entity as unknown as ServiceDetail;
        const owner = service.maintainer ? service.maintainer : service.owner;
        const ownerName = EntityName(owner.type as EntityType, owner.id);
        const ownerLink = hasReadAccessToEntity(owner.type as EntityType, organization)
          ? getEntityLink(owner.type as EntityType, owner.id)
          : '';
        const escalationPolicyDetails = service.escalation_policy;
        return [
          {
            heading: 'Owner',
            text: ownerName,
            link: ownerLink,
          },
          {
            heading: 'Default Escalation Policy',
            text: escalationPolicyDetails.name,
            link: hasReadAccessToEntity('escalationpolicy')
              ? generatePath(ESCALATION_POLICIES_LIST_PATH, { id: escalationPolicyDetails.id })
              : null,
          },
        ];
      }
      default:
        return [];
    }
  };
  return { getPopoverSections: getPopoverSections() };
};
