import { IIncidentStatus } from 'core/interfaces/IIncidents';
import { Grid, Para } from 'uie/components';
import { capitalizeFirstLetter } from 'views/main/organization/settings/audit-logs/helpers';

import IncidentSummarySection from '../Section';
import { THEME_COLORS } from 'library/theme/colors';

interface IProps {
  status: IIncidentStatus;
}

const IncidentSummaryStatus: React.FC<IProps> = props => {
  return (
    <IncidentSummarySection heading="STATUS">
      <Grid alignItems="center">
        <span
          style={{
            height: 16,
            width: 16,
            padding: 5,
            borderRadius: '50%',
            backgroundColor: THEME_COLORS.incidentStatusTag[props.status],
            marginRight: 8,
          }}
        ></span>
        <Para>{capitalizeFirstLetter(props.status)}</Para>
      </Grid>
    </IncidentSummarySection>
  );
};

export default IncidentSummaryStatus;
