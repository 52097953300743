import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(223, 225, 229, 1);
  background-color: #fff;
  display: flex;
  max-width: 457px;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  padding: 12px 19px;
  width: 450px;
`;

export const Title = styled.h2`
  color: #09305a;
  font-family: Mulish, sans-serif;
  margin: 0;
`;

export const Label = styled.label`
  color: #09305a;
  margin-top: 10px;
  font: 14px/132% Mulish, sans-serif;
`;

export const Input = styled.input`
  border-radius: 4px;
  border: 1px solid rgba(182, 188, 200, 1);
  margin-top: 10px;
  color: #000;
  padding: 9px;
  font: 14px/132% Mulish, sans-serif;

  &::placeholder {
    color: #8a92a3;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 11px;
  align-self: flex-start;
`;

export const Button = styled.button<{ variant?: 'primary' | 'secondary' }>`
  font-family: Mulish, sans-serif;
  justify-content: center;
  border-radius: 4px;
  padding: 5px 8px;
  cursor: pointer;

  ${({ variant }) =>
    variant === 'primary'
      ? `
          background-color: #0e61dd;
          color: #fff;
          border: none;
        `
      : `
          background-color: #fff;
          color: #000;
          border: 1px solid rgba(182, 188, 200, 1);
        `}
`;
