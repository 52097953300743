import axios from 'axios';
import { API } from '../api';
import { ISSO } from '../interfaces/IExtensions';

class SSOService {
  private _api = `${API.config.batman}/organizations`;

  public getSSOConfig = (organizationId: string) =>
    axios.get<{ data: ISSO }>(`${this._api}/${organizationId}/extensions/sso`);

  public updateSSOConfig = (
    organizationId: string,
    data: Omit<ISSO, 'id' | 'organization_id' | 'token' | 'new_user_role_id'>,
  ) => axios.put<{ data: ISSO }>(`${this._api}/${organizationId}/extensions/sso`, data);
}

export default SSOService;
