import Axios from 'axios';
import { API } from 'core';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys, reactQueryConfig } from '../constant';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/service.reactqery';

const deleteJiraAccount = (jiraAccId: string) => {
  return Axios.delete(
    `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud/new/${jiraAccId}`,
  );
};

const useDeleteJiraCloudAccount = () => {
  const queryClient = useQueryClient();

  return useMutation([queryKeys.CREATEJIRACLOUDSACCOUNTS], deleteJiraAccount, {
    ...reactQueryConfig,
    onSuccess: () => {
      queryClient.refetchQueries([queryKeys.GETALLJIRACLOUDACCOUNTS]);
      queryClient.refetchQueries([queryKeys.GETALLJIRACLOUDACCOUNTSCONFIG]);
      reactQueryConfigSuccess('Jira Cloud Account Deleted successfully!');
    },
    onError: (error: any) => {
      const description =
        error?.response?.data?.meta?.error_message || 'Failed to Delete Jira Cloud Account';
      reactQueryConfigError({ description: description, title: 'Delete Jira Account' });
    },
  });
};
export { useDeleteJiraCloudAccount };
