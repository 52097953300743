import { Box, FormLabel, Stack } from '@chakra-ui/react';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import * as React from 'react';
import { AlertDialogComponent } from '../../../components/Alert';
import { ISSUES_STATES } from '../../../constants/status.constants';
import { IMessageTemplate, ISelectedComponent, IStatusIssueState } from '../../../Interface';
import { Step } from './Step';

interface Props {
  isUpdateFlow?: boolean;
}

export const StateFormContainer = (props: Props) => {
  const { values, setFieldValue, submitForm }: any = useFormikContext();
  const [showModal, setShowModal] = React.useState(false);
  const [selectedForDelete, setSelectedForFelete] = React.useState([0, 0]);

  const messageCount = parseInt(values.stateMessageCount);
  const canEdit = useUserAccess().hasUpdateAccess('status_pages');

  React.useEffect(() => {
    if (messageCount === 0 && values.timezone) {
      const date = DateTime.local({ zone: values.timezone });
      const template: IMessageTemplate = values.template.filter(
        (temp: IMessageTemplate) => temp.stepIndex === 0,
      )?.[0];
      setFieldValue(`issueStates[0]issueMessages`, [
        {
          text: template.defaultMessage,
          tempText: template.defaultMessage,
          date: date.toISODate(),
          time: date.toFormat('HH:mm'),
          timestamp: date.toUTC().toISO(),
          isEdit: '1',
          isNew: true,
          isDelete: '0',
          isApiSavePending: props.isUpdateFlow ? '0' : '1',
          error: '',
        },
      ]);
      setFieldValue('stateMessageCount', (messageCount + 1).toString());
    }
  }, [values.issueStates]);

  const onDeleteClick = (stateIndex: number, messageIndex: number) => {
    setShowModal(true);
    setSelectedForFelete([stateIndex, messageIndex]);
  };

  const onDeleteConfirm = () => {
    setFieldValue(
      `issueStates.${selectedForDelete[0]}.issueMessages.${selectedForDelete[1]}.isDelete`,
      '1',
    );
    submitForm();
    setShowModal(false);
  };

  const hasNonOperationalComponent =
    values.selectedComponents.filter(
      (comp: ISelectedComponent) => comp.value.value !== values.operationStatusCode,
    ).length > 0;
  const hasNonOperationalElement =
    values.status.value !== values.operationStatusCode || hasNonOperationalComponent;
  const isResolved = values.currentIssueState === ISSUES_STATES.RESOLVED;

  return (
    <Box bg="bg-surface" py={4}>
      <FormLabel>Issue States & Message(s)</FormLabel>
      <Stack spacing="0" direction={{ base: 'column', md: 'column' }}>
        {values.issueStates.map((step: IStatusIssueState, id: number) => {
          const template: IMessageTemplate = values.template.filter(
            (temp: IMessageTemplate) => temp.stepIndex === id,
          )?.[0];
          return (
            <Step
              key={id}
              idx={id}
              stateMessageCount={messageCount}
              onClick={() => {
                if (
                  step.issueMessages.length === 0 &&
                  !values.isMigrated &&
                  canEdit &&
                  !isResolved
                ) {
                  const date = DateTime.local({ zone: values.timezone });
                  setFieldValue(`issueStates.${id}.issueMessages`, [
                    {
                      text: template.defaultMessage,
                      tempText: template.defaultMessage,
                      date: date.toISODate(),
                      time: date.toFormat('HH:mm'),
                      timestamp: date.toUTC().toISO(),
                      isEdit: '1',
                      isNew: true,
                      isDelete: '0',
                      isApiSavePending: props.isUpdateFlow ? '0' : '1',
                      error: '',
                    },
                  ]);
                  if (!props.isUpdateFlow) {
                    setFieldValue('stateMessageCount', (messageCount + 1).toString());
                  }
                }
              }}
              timezone={values.timezone}
              step={step}
              template={template}
              setFieldValue={setFieldValue}
              onIssueStateChange={submitForm}
              onDelete={onDeleteClick}
              isUpdateFlow={props.isUpdateFlow || false}
              readOnly={values.isMigrated || !canEdit}
              showDisclaimer={
                hasNonOperationalElement &&
                step.name === ISSUES_STATES.RESOLVED &&
                !isResolved &&
                step.issueMessages.length === 1
              }
              isResolved={isResolved}
            />
          );
        })}
      </Stack>
      <AlertDialogComponent
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        callbackFn={onDeleteConfirm}
        msg="A notification has / may have already been sent to subscribers and cannot be undone."
        title="Are you sure you want to delete this update?"
        isDelete
      />
    </Box>
  );
};
