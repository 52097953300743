import React, { useRef, Dispatch, SetStateAction } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  ChakraProvider,
} from '@chakra-ui/react';

interface IAlertDialog {
  isOpen: boolean;
  onClose: () => void;
  msg: string;
  callbackFn: () => void;
  title: string;
  isDelete: boolean;
  loading?: boolean;
}

export const AlertDialogComponent = ({
  isOpen,
  onClose,
  callbackFn,
  msg,
  title,
  isDelete,
  loading,
}: IAlertDialog) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <ChakraProvider cssVarsRoot="#serviceAlert">
      <AlertDialog
        id="serviceAlert"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        trapFocus={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{msg}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                isLoading={loading}
                colorScheme="red"
                onClick={() => callbackFn()}
                ml={3}
                _focus={{ border: 'none' }}
              >
                {isDelete ? 'Delete anyway' : 'Yes'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </ChakraProvider>
  );
};
