import { FocusBlock, Grid, Para, Theme, IconButton } from 'uie/components';
import cx from 'classnames';
import React from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useLocation,
  useHistory,
  generatePath,
} from 'react-router-dom';
import { TEAM_SQUADS_PATH } from 'views/main/routes/routes';

import { EditIcon } from 'icons';
import { OverflownText } from '../OverFlowText';

type SquadItemProps = {
  ID: string;
  name: string;
  onEditClick?: () => void;
};

function SquadItem({ ID, name, onEditClick }: SquadItemProps) {
  const { theme } = Theme;
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const location = useLocation();

  const hash = location.hash;

  const selectedId = hash.replace('#', '');

  const isSelected = selectedId === ID;

  return (
    <div
      onClick={() => {
        history.push(
          generatePath(TEAM_SQUADS_PATH, {
            id: params.id,
            sqid: ID,
          }),
        );
      }}
    >
      <FocusBlock
        value={ID}
        style={{
          paddingLeft: '8px',
          paddingRight: '8px',
          paddingTop: '12px',
          paddingBottom: '12px',
          marginTop: '12px',
          borderRadius: '2px',
        }}
        className={cx('org_settings__focus_tab__whiteBG', {
          org_settings__in_focus: isSelected,
        })}
      >
        <Grid
          flexWidth={12}
          style={{
            cursor: 'pointer',
          }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Para
            fontSize={14}
            fontWeight={400}
            color={theme.shades.cement}
            style={{
              maxWidth: '150px',
              lineHeight: '132%',
            }}
          >
            <OverflownText>{name}</OverflownText>
          </Para>

          {isSelected && (
            <IconButton onClick={onEditClick} style={{ padding: 0, paddingRight: '8px' }}>
              <EditIcon height="16px" width="16px" stroke={theme.shades.cement} />
            </IconButton>
          )}
        </Grid>
      </FocusBlock>
    </div>
  );
}

export default SquadItem;
