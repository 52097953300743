import React from 'react';
import Select, { components, CSSObjectWithLabel, MultiValue, StylesConfig } from 'react-select';
import {
  Checkbox,
  ChakraProvider,
  useTheme,
  useMultiStyleConfig,
  useBreakpointValue,
  Text,
  HStack,
} from '@chakra-ui/react';
import { AssigneeAvatar } from '../AssigneeAvatar';

interface Option {
  label: string;
  value: string;
}

interface MultiSelectProps {
  options: Option[];
  selectedValues: Option[];
  placeholder: string;
  onChange: (selected: Option[]) => void;
  variantType?: 'assignee' | 'others';
}

const CustomMultiSelect: React.FC<MultiSelectProps> = ({
  options,
  selectedValues,
  placeholder,
  onChange,
  variantType,
}) => {
  const theme = useTheme();
  const size = useBreakpointValue({ base: 'md', lg: 'lg' });
  const multiConfigs = useMultiStyleConfig('Dropdown', {
    size,
  }) as Record<keyof StylesConfig, CSSObjectWithLabel>;
  const variant = variantType ?? 'others';

  const compose = (component: keyof StylesConfig) => (provided: CSSObjectWithLabel) => ({
    ...provided,
    ...multiConfigs[component],
  });

  const styles: any = {
    container: compose('container'),
    input: (provided, { selectProps: { inputValue, isMulti } }) => ({
      ...provided,
      ...(!(inputValue || isMulti) ? { position: 'absolute' } : {}),
      ...compose('input'),
    }),
    menu: compose('menu'),
    menuList: compose('menuList'),
    option: compose('option'),
    control: compose('control'),
    dropdownIndicator: compose('dropdownIndicator'),
    valueContainer: compose('valueContainer'),
    indicatorSeparator: compose('indicatorSeparator'),
    indicatorsContainer: compose('indicatorsContainer'),
    noOptionsMessage: compose('noOptionsMessage'),
  };

  const MultiValueContainer = (props: any) => {
    return (
      <components.MultiValueContainer {...props}>
        <Checkbox isChecked={true} />
        {props.children}
      </components.MultiValueContainer>
    );
  };

  const OptionComponent = (props: any) => {
    return (
      <components.Option {...props}>
        <Checkbox
          isChecked={selectedValues?.some(val => val.value === props.data.value)}
          onChange={() => props.selectOption(props.data)}
        >
          {variant === 'assignee' ? (
            <HStack gap={2}>
              <AssigneeAvatar name={props.data.label} />
              <Text>{props.data.label}</Text>
            </HStack>
          ) : (
            <Text>{props.data.value}</Text>
          )}
        </Checkbox>
      </components.Option>
    );
  };

  const selectProps = {
    placeholder: placeholder ?? 'Select',
    styles,
  };

  return (
    <Select
      isMulti
      options={options}
      value={[]}
      onChange={onChange}
      components={{ MultiValueContainer, Option: OptionComponent }}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      {...selectProps}
    />
  );
};

export default CustomMultiSelect;
