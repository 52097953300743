import { checkIfKeyChanged, checkIfNotValid, objectIsEmpty } from 'core/helpers';
import { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { requestOrganizationEscalation } from '../../../../../../../core/actions/organization';
import { requestOrganizationTeams } from '../../../../../../../core/actions/organization/teams';
import { T_WA_USER_ADDED_TO_TEAM } from '../../../../../../../core/const/tracker';
import { exception } from '../../../../../../../core/exception';
import { IAppState } from '../../../../../../../core/interfaces/IAppState';
import {
  IComponentErrors,
  IComponentNetworkState,
} from '../../../../../../../core/interfaces/IComponentState';
import { ReduceEntity } from '../../../../../../../core/interfaces/IHelpers';
import {
  ITeam,
  ITeamMember,
  ITeamMemberDependencies,
  ITeamRoles,
} from '../../../../../../../core/interfaces/ITeams';
import TeamsService from '../../../../../../../core/services/rbac/service.teams';
import { AppTracker } from '../../../../../../../shared/analytics/tracker';
import { SettingPageACL, withSettingPageACL } from '../../acl';
import './index.css';
import { render } from './render.index';

interface IProps extends Pick<IAppState, 'organization'>, RouteComponentProps {
  team: ITeam;
  refreshId: number;
  search: string;
  updateChildInRequest: (request: boolean) => void;
  requestOrganizationTeams: typeof requestOrganizationTeams;
  requestOrganizationEscalation: typeof requestOrganizationEscalation;
  hasUnsavedChanges: boolean;
  sethasUnsavedOnChild: (unsavedExist: boolean) => void;
  aclStore: SettingPageACL;
}

interface IState {
  deleteMemberId: string;
  deleteState: 'confirm' | 'conflict' | 'checking_conflicts' | '';
  dependencies: null | ITeamMemberDependencies;
  editMemberId: string;
  networkState: IComponentNetworkState | 'save';
  errors: IComponentErrors<
    | 'network_err'
    | 'error__new_member_role'
    | 'error__new_member_user'
    | 'error__team_owner'
    | 'error__edit_member_role'
    | 'error__team_members'
  >;
  newMember: ITeamMember[];
  searchMap: { [key: string]: string };
  editMember: ITeamMember[];
  hasUnsavedChange: boolean;
}

export class OrganizationSettingsTeamsRenderMembers extends Component<IProps, IState> {
  private _teamService = new TeamsService();
  render = render;

  constructor(props: IProps) {
    super(props);

    this.state = {
      deleteMemberId: '',
      deleteState: '',
      dependencies: null,
      editMemberId: '',
      networkState: 'idle',
      newMember: [],
      searchMap: {},
      errors: {},
      editMember: [],
      hasUnsavedChange: false,
    };
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (checkIfKeyChanged(prevProps, this.props, 'refreshId')) {
      this.props.updateChildInRequest(false);
      this.setState({
        deleteMemberId: '',
        deleteState: '',
        editMemberId: '',
        networkState: 'idle',
        newMember: [],
        searchMap: {},
        errors: {},
        editMember: [],
      });
    }
    if (!this.state.hasUnsavedChange) {
      if (this.state.newMember !== prevState.newMember) {
        this.setState({ hasUnsavedChange: true });
      }
      if (this.state.editMember !== prevState.editMember) {
        this.setState({ hasUnsavedChange: true });
      }
    }
    if (this.state.hasUnsavedChange) {
      if (
        !this.state.newMember.length &&
        !this.state.editMember.length &&
        !this.state.editMemberId
      ) {
        this.setState({ hasUnsavedChange: false });
      }
    }
    if (
      prevProps.hasUnsavedChanges &&
      prevProps.hasUnsavedChanges !== this.props.hasUnsavedChanges
    ) {
      this.setState({ hasUnsavedChange: this.props.hasUnsavedChanges });
    } else {
      if (this.state.hasUnsavedChange && !this.props.hasUnsavedChanges)
        this.props.sethasUnsavedOnChild(true);
      if (prevState.hasUnsavedChange && !this.state.hasUnsavedChange)
        this.props.sethasUnsavedOnChild(false);
    }
  }

  onSave = async () => {
    const { newMember, editMember } = this.state;

    const {
      team: { id, name, description, members: teamMembers, roles },
    } = this.props;
    const teamRoleId = roles.find(r => r.default)!.id;

    const errors = checkIfNotValid([
      [
        newMember.some(m => m.user_id && m.role_ids.length === 0),
        true,
        {
          error__new_member_role: 'One role needs to be assigned',
        },
      ],
      [
        newMember.some(m => !m.user_id && m.role_ids.length > 0),
        true,
        {
          error__new_member_user: 'Member should be selected',
        },
      ],
      [
        teamMembers.some(m => m.role_ids.includes(teamRoleId)),
        false,
        { error__team_owner: 'One member should have Manage Team role' },
      ],
      [
        editMember.some(m => m.role_ids.length === 0),
        true,
        { error__edit_member_role: 'One role needs to be assigned' },
      ],
    ]);
    if (!objectIsEmpty(errors)) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: {}, networkState: 'save' });
    this.props.updateChildInRequest(true);
    try {
      const members = Object.entries(
        [...teamMembers, ...editMember, ...newMember.filter(m => m.user_id !== '')].reduce(
          (c: any, n) => {
            c[n.user_id] = n;
            return c;
          },
          {},
        ),
      ).map(([_, n]) => n) as ITeamMember[];
      await this._teamService.update(id, {
        name,
        description,
        members,
      });
      this.props.requestOrganizationTeams();
      const _tRoles = this.props.team.roles.reduce(
        (c: ReduceEntity<ITeamRoles, 'id' | 'name' | 'default' | 'abilities'>, r) => {
          c[r.id] = {
            id: r.id,
            name: r.name,
            default: r.default,
            abilities: r.abilities,
          };
          return c;
        },
        {},
      );
      this.setState({ newMember: [], editMember: [], editMemberId: '' });
      AppTracker.track(T_WA_USER_ADDED_TO_TEAM, {
        'Team ID': this.props.team.id,
      });
    } catch (err: any) {
      console.log(err);
      this.setState({ errors: { network_err: exception.handle('E_PUT_TEAMS_UPDATE', err) } });
    } finally {
      this.setState({ networkState: 'idle' });
    }
  };

  onDeleteConfirm = async () => {
    const { deleteMemberId } = this.state;
    const {
      team: { id, members: teamMembers, roles },
    } = this.props;
    const teamRoleId = roles.find(r => r.default)!.id;

    const members = teamMembers.filter(m => m.user_id !== deleteMemberId);

    const errors = checkIfNotValid([
      [members.length, 0, { error__team_members: 'Team needs a minimum of one members' }],
      [
        members.some(m => m.role_ids.includes(teamRoleId)),
        false,
        { error__team_owner: 'One member should have Manage Team role' },
      ],
    ]);

    if (!objectIsEmpty(errors)) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: {}, networkState: 'save' });
    this.props.updateChildInRequest(true);

    try {
      await this._teamService.deleteTeamMember(id, deleteMemberId);
      this.props.requestOrganizationTeams();
    } catch (err: any) {
      this.setState({ errors: { network_err: exception.handle('E_PUT_TEAMS_UPDATE', err) } });
    } finally {
      this.setState({ networkState: 'idle' });
    }
  };

  checkIfMemberIsDependant = async (deleteMemberId: string) => {
    const {
      team: { id },
    } = this.props;

    this.setState({ errors: {}, networkState: 'request', deleteState: 'checking_conflicts' });
    this.props.updateChildInRequest(true);

    try {
      const {
        data: { data: dependencies },
      } = await this._teamService.getDependencies(id, deleteMemberId);
      if (!this.isEmptyDependency(dependencies)) {
        this.props.updateChildInRequest(false);
        this.setState({ dependencies, deleteState: 'conflict' });
        return;
      }

      this.setState({ deleteState: 'confirm' });
    } catch (err: any) {
      this.setState({
        errors: { network_err: exception.handle('E_PUT_TEAMS_UPDATE', err) },
        deleteState: '',
      });
    } finally {
      this.setState({ networkState: 'idle' });
    }
  };

  isEmptyDependency = (obj: Record<string, any>) => {
    if (obj.is_last_member_with_manage_team_role === true || obj.incidents > 0) {
      return false;
    }

    for (const key in obj) {
      if (key !== 'is_last_member_with_manage_team_role' && key !== 'incidents') {
        if (Array.isArray(obj[key])) {
          if (obj[key].length !== 0) {
            return false;
          }
        } else {
          return false;
        }
      }
    }

    return true;
  };

  onToggleDelete = (deleteMemberId: string) => () => {
    if (deleteMemberId === '') {
      this.props.updateChildInRequest(false);
      this.setState({ deleteState: '' });
      return;
    }

    this.checkIfMemberIsDependant(deleteMemberId);

    this.setState({ deleteMemberId, errors: {} });
  };

  onHideConfirmDelete = (confirm: boolean) =>
    confirm ? this.onDeleteConfirm() : this.onToggleDelete('')();

  onAddNewMember = () => {
    this.setState({
      newMember: [
        {
          abilities: {},
          role_ids: [],
          user_id: '',
        },
      ],
      searchMap: {},
      editMember: [],
    });
  };

  onSearchMembers = (id: string) => (ev: ChangeEvent<HTMLInputElement>) =>
    this.setState({ searchMap: { [id]: ev.target.value } });

  onCancelAddNewOrEdit = () => {
    this.setState({ newMember: [], searchMap: {}, editMemberId: '', errors: {} });
    this.props.requestOrganizationTeams();
  };

  onNewMemberSelect = (index: number) => (_: any, userId: string) => {
    this.setState(({ newMember }) => {
      if (newMember.length === 0) return { newMember };

      newMember[index].user_id = userId;

      if (newMember[newMember.length - 1].user_id !== '') {
        newMember.push({
          abilities: {},
          role_ids: [],
          user_id: '',
        });
      }
      return { newMember };
    });
  };

  onDeleteNewMember = (index: number) => () => {
    this.setState(({ newMember }) => {
      if (newMember.length === 0) return { newMember };

      newMember.splice(index, 1);

      if (newMember.length === 0 || newMember[newMember.length - 1].user_id !== '') {
        newMember.push({
          abilities: {},
          role_ids: [],
          user_id: '',
        });
      }
      return { newMember };
    });
  };

  onNewMemberRoleSelect = (index: number, roleId: string) => () => {
    this.setState(({ newMember }) => {
      if (newMember.length === 0) return { newMember };

      const roles = new Set(newMember[index].role_ids);
      roles.has(roleId) ? roles.delete(roleId) : roles.add(roleId);

      newMember[index].role_ids = Array.from(roles);

      return { newMember };
    });
  };

  onMemberUpdateRole = (roleId: string) => () => {
    this.setState(({ editMember }) => {
      if (editMember.length === 0) return { editMember };

      const roles = new Set(editMember[0].role_ids);
      roles.has(roleId) ? roles.delete(roleId) : roles.add(roleId);

      editMember[0].role_ids = Array.from(roles);

      return { editMember };
    });
  };

  onEdit = (editMemberId: string) => () =>
    this.setState({
      editMemberId,
      editMember: Array.from(this.props.team.members.filter(m => m.user_id === editMemberId)),
    });

  closeReplaceModal = () => {
    this.setState({
      deleteMemberId: '',
      deleteState: '',
      dependencies: null,
      networkState: 'idle',
    });
    this.props.requestOrganizationTeams();
    this.props.requestOrganizationEscalation();
  };
}

const OrganizationSettingsTeamsRenderMembersWithHistory = withRouter(
  OrganizationSettingsTeamsRenderMembers,
);

export default connect(
  ({ organization }: IAppState) => ({
    organization,
  }),
  {
    requestOrganizationTeams,
    requestOrganizationEscalation,
  },
)(withSettingPageACL(OrganizationSettingsTeamsRenderMembersWithHistory));
