import { debounce } from 'core';
import { useToast } from 'library/atoms';
import { usePaginationParams } from 'library/molecules/Tablev2/Table';
import qs, { ParsedQuery } from 'query-string';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';

import { getWorkFlowLogsApi, getWorkFlowLogWebhook, workflowlogActionsApi } from '../api';
import { QUERY_KEYS } from '../constant';
import { WorkflowLogDetail } from '../types/response';
import { WorkflowLogsResponse } from '../types/response/workflow';

const WorkflowLogsContext = createContext({
  workflowLogs: { data: [], metadata: { total_count: 0 } } as WorkflowLogsResponse,
  isLoadingInitialWorkflowLogs: false,
  isLoadingWorkflowLogs: false,
  searchValue: '',
  onSearch: (value: string) => {},
  setPagination: (() => {}) as Dispatch<
    SetStateAction<{
      pageIndex: number;
      pageSize: number;
    }>
  >,
  paginationInfo: {
    pageIndex: 0,
    pageSize: 5,
  },
  showActionLog: false,
  showTriggerWebhook: false,
  onHideActionLog: () => {},
  onHideTriggerWebhook: () => {},
  actionLogLoading: false,
  actionLogs: [] as WorkflowLogDetail[],
  workflowWebhook: { data: '', action_name: '', created_at: '' },
  workflowWebhookLoading: false,
});

interface IProps {
  children: ReactNode;
  id: string;
}

const WorkflowLogsProvider = ({ children, id }: IProps) => {
  const history = useHistory();
  const query = qs.parse(history.location.search, { arrayFormat: 'comma' }) as ParsedQuery<string>;
  const selectedLog = query['log'];
  const selectedAction = query['actionId'];
  const searchTerm = query['search'];
  const [searchValue, setSearchValue] = useState<string>(
    typeof searchTerm === 'string' ? searchTerm : '',
  );
  const toast = useToast();

  const logId = useMemo(() => (typeof selectedLog === 'string' ? selectedLog : ''), [selectedLog]);
  const actionId = useMemo(
    () => (typeof selectedAction === 'string' ? selectedAction : ''),
    [selectedAction],
  );
  const { setPagination, paginationInfo } = usePaginationParams({
    enabled: true,
  });

  const {
    data: workflowLogs = { data: [], metadata: { total_count: 0 } },
    isLoading: isLoadingInitialWorkflowLogs,
    isFetching: isLoadingWorkflowLogs,
  } = useQuery<WorkflowLogsResponse>(
    [QUERY_KEYS.WORKFLOW_LOGS, id, paginationInfo.pageIndex, paginationInfo.pageSize],
    () =>
      getWorkFlowLogsApi(id, {
        page_number: paginationInfo.pageIndex + 1,
        page_size: paginationInfo.pageSize,
      }),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError: () => {
        toast({ text: 'Failed to fetch workflow data. Please try again later!', status: 'error' });
      },
    },
  );
  const {
    data: workflowLogWebhook = { data: '', created_at: '', action_name: '' },
    isFetching: isWorkflowWebhookLoading,
  } = useQuery([actionId], () => getWorkFlowLogWebhook(id, logId, actionId), {
    enabled: !!actionId,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onError: () => {
      toast({
        text: 'Failed to fetch workflow Triggered Webhook. Please try again later!',
        status: 'error',
      });
    },
  });

  const { data: workflowActionLogs = [], isLoading: isLoadingActionLogs } = useQuery(
    [QUERY_KEYS.WORKFLOW_ACTION_LOGS, id, logId],
    () =>
      workflowlogActionsApi({
        workflowId: id,
        logId: logId,
      }),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!logId,
    },
  );

  const updateSearchParam = useCallback(
    debounce((value: string) => {
      const routeConfig = {
        pathname: history.location.pathname,
        search: value.length
          ? qs.stringifyUrl({
              url: history.location.search,
              query: {
                search: value || undefined,
              },
            })
          : qs.exclude(history.location.search, ['search']),
      };
      history.push(routeConfig);
    }, 1000),
    [],
  );

  const onSearch = (value: string) => {
    setSearchValue(value);
    updateSearchParam(value);
  };

  const onHideActionLog = useCallback(
    () =>
      history.push({
        pathname: history.location.pathname,
        search: qs.exclude(history.location.search, ['log', 'actionId', 'detail', 'date']),
      }),
    [],
  );

  const onHideTriggerWebhook = useCallback(
    () =>
      history.push({
        pathname: history.location.pathname,
        search: qs.exclude(history.location.search, ['actionId']),
      }),
    [],
  );

  const values = {
    workflowLogs,
    isLoadingInitialWorkflowLogs,
    isLoadingWorkflowLogs,
    setPagination,
    paginationInfo,
    searchValue,
    onSearch,
    showActionLog: !!logId,
    showTriggerWebhook: !!actionId,
    onHideActionLog,
    onHideTriggerWebhook,
    actionLogLoading: isLoadingActionLogs,
    actionLogs: workflowActionLogs,
    workflowWebhook: workflowLogWebhook,
    workflowWebhookLoading: isWorkflowWebhookLoading,
  };

  return <WorkflowLogsContext.Provider value={values}>{children}</WorkflowLogsContext.Provider>;
};

const useWorkflowLogsContext = () => useContext(WorkflowLogsContext);
const WorkflowLogsConsumer = WorkflowLogsContext.Consumer;

export { useWorkflowLogsContext, WorkflowLogsConsumer, WorkflowLogsProvider };
