import { HStack, Text, VStack } from '@chakra-ui/react';
import { IAppState } from 'core/interfaces/IAppState';
import FormField from 'library/molecules/FormField/FormField';
import { THEME_COLORS } from 'library/theme/colors';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { components } from 'react-select';
import { getAllUserOptions, OwnerOption } from '../EntityOwnerDropdown/helper';
import FormDropdown from '../FormDropdown/FormDropdown';

interface Props {
  error?: string;
  label?: string;
  name: string;
  onClearField?: () => void;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: any;
  value?: any;
}

const EntityUserDropdown = ({
  label,
  name,
  error,
  onClearField,
  placeholder,
  style,
  value,
  onChange,
}: Props) => {
  const state = useSelector((state: IAppState) => state, shallowEqual);

  const ownerOptions: OwnerOption[] = useMemo(
    () => getAllUserOptions(state),
    [state.organization.selectedTeam],
  );

  const SingleValue = (props: any) => {
    return (
      <components.SingleValue {...props}>
        <HStack>
          <Text>{props.data.name}</Text>
          {props.data.username && <Text>({props.data.username})</Text>}
        </HStack>
      </components.SingleValue>
    );
  };

  return (
    <FormField
      label={label}
      error={error}
      onClearField={onClearField}
      textStyle={{ fontSize: 16 }}
      style={{ ...style }}
    >
      <FormDropdown<OwnerOption>
        options={ownerOptions}
        name={name}
        placeholder={placeholder ?? 'Select ...'}
        value={value}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        components={{ SingleValue }}
        onChange={(e: any) => onChange(e)}
        formatOptionLabel={option => (
          <VStack alignItems={'left'} spacing={0}>
            <Text>{option.name}</Text>
            <Text color={THEME_COLORS.secondary[1200]}>{option.username}</Text>
          </VStack>
        )}
      />
    </FormField>
  );
};

export default EntityUserDropdown;
