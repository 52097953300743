import { DateObjectUnits, DateTime } from 'luxon';

export const appDateFormats = ['DD/MM/YYYY', 'MM/DD/YYYY'];

interface ILuxonDateFormat {
  /** @example 02/25/2022 or 25/02/2022 */
  simple: string;
  /** @example 11:10 AM, 02/25/2022 or 11:10 AM, 25/02/2022 */
  simpleWTime: string;
  /** @example 02/25/2022, 11:10:15 AM or 25/02/2022, 11:10:15 AM */
  simpleWFullTime: string;
  /** @example 25 Feb, 2022 or Feb 25, 2022 */
  short: string;
  /** @example 25 Feb or Feb 25 */
  shortWoYear: string;
  /** @example 25 Feb, 2022, 11:10:15 AM or Feb 25, 2022, 11:10:15 AM */
  shortWFullTime: string;
  /** @example 25 February, 11:10 AM or February 25, 11:10 AM */
  shortDateTimeWoYear: string;

  /** @example 02-25-2022 or 25-02-2022 */
  datePickerFormat: string;
}

interface FormatConfig {
  disableTimezone: boolean;
}

const luxonDateFormats = {
  'MM/DD/YYYY': {
    simple: 'LL/dd/yyyy',
    simpleWTime: 'h:mm a, LL/dd/yyyy',
    simpleWFullTime: 'LL/dd/yyyy, h:mm:ss a',
    short: 'LLL dd, yyyy',
    shortWoYear: 'LLL dd',
    shortWFullTime: 'LLL dd, yyyy, h:mm:ss a',
    shortDateTimeWoYear: 'LLLL dd, h:mm a',
    datePickerFormat: 'MM/dd/yyyy',
  },
  'DD/MM/YYYY': {
    simple: 'dd/LL/yyyy',
    simpleWTime: 'h:mm a, dd/LL/yyyy',
    simpleWFullTime: 'dd/LL/yyyy, h:mm:ss a',
    short: 'dd LLL, yyyy',
    shortWoYear: 'dd LLL',
    shortWFullTime: 'dd LLL, yyyy, h:mm:ss a',
    shortDateTimeWoYear: 'dd LLLL, h:mm a',
    datePickerFormat: 'dd/MM/yyyy',
  },
} as Record<typeof appDateFormats[0], ILuxonDateFormat>;

class LocaleConfig {
  private _dateFormat = appDateFormats[0];
  private _DateTime = DateTime;
  private _timeZone = DateTime.local().zoneName;

  /**
   * Get DateTime with the active zone of the user
   * @returns DateTime
   */
  get timeZoneDateTime() {
    return this._DateTime.local().setZone(this._timeZone);
  }

  /**
   * Get timezone time diff
   * {@link https://moment.github.io/luxon/#/formatting?id=table-of-tokens}
   * @returns +05:30 for Asia/Kolkata
   */
  get zoneOffset() {
    return this.timeZoneDateTime.toFormat('ZZ');
  }

  /**
   * Get timezone offset
   * @returns -330 for Asia/Kolkata
   */
  get timeZoneOffset() {
    return this.timeZoneDateTime.offset;
  }

  /**
   * Get the active zone of the user
   */
  get userZone() {
    return this._timeZone;
  }

  /**
   * Set the zone of the user
   */
  set userZone(zone: string) {
    this._timeZone = zone;
  }

  /**
   * Get the printable active zone of the user without underscores
   */
  get printableUserZone() {
    return this._timeZone?.replace(/_/g, ' ');
  }

  /**
   * Get luxon short offset with named Offset
   * @example -7:00 PST
   */
  get namedOffsetWithAbbr() {
    return `${this.timeZoneDateTime.toFormat('ZZ')}
    ${this.timeZoneDateTime.toFormat('ZZZZ')}`;
  }

  /**
   * Get luxon named Offset
   * @example PST
   */
  get namedOffset() {
    return this.timeZoneDateTime.toFormat('ZZZZ');
  }

  /**
   * Get date format
   * @example MM/DD/YYYY or DD/MM/YYYY
   */
  get dateFormat() {
    return this._dateFormat;
  }

  /**
   * Set date format
   * @example MM/DD/YYYY or DD/MM/YYYY
   */
  set dateFormat(dateFormat: string) {
    this._dateFormat = dateFormat;
  }

  /**
   * Get short luxon date format
   * @example LLL dd, yyyy or dd LLL, yyyy
   */
  get shortDateFormat() {
    return luxonDateFormats[this._dateFormat].short;
  }

  /**
   * Converts JS Datetime to luxon datetime format
   * @param date JS Date object or string
   * @param disableTZ Disable Timezone format
   * @returns luxon DateTime
   */
  toDateTime = (date: Date | string, disableTZ?: boolean) => {
    return this._DateTime
      .fromJSDate(new Date(date))
      .setZone(disableTZ ? DateTime.local().zoneName : this._timeZone);
  };

  /**
   * Get selected timezone luxon datetime provided the year, month and date
   * @param year Year
   * @param month Month
   * @param date Date
   * @returns luxon DateTime
   */
  toTZDateTime = (year: number, month: number, date: number) => {
    return DateTime.local().setZone(this._timeZone).set({ year, month, day: date });
  };

  /**
   * Converts JS Datetime with time attribures to luxon datetime format
   * @param date JS Date object or string
   * @returns luxon DateTime
   */
  toDateTimeWTime = (date: Date | string, timeAttr: DateObjectUnits) => {
    return this.toDateTime(date).set(timeAttr);
  };

  /**
   * Returns a string representation of a this time relative to now, such as "in two days".
   * @example a few seconds ago
   * @param date JS Date object or string
   * @param style - the style of units, must be "long", "short", or "narrow". Defaults to long.
   * @returns string representation of a this time relative to now
   */
  toRelative = (date: Date | string, style: 'long' | 'short' | 'narrow' = 'long') => {
    return this.toDateTime(date).toRelative({ style });
  };

  /**
   * Returns an ISO 8601-compliant string representation of the Date.
   * @example 2022-06-03T04:25:32.928Z
   * @param date JS Date object or string
   * @returns ISO 8601-compliant string representation of the date
   */
  toISO = (date: Date | string) => {
    return this.toDateTime(date).toISO();
  };

  /**
   * Returns a string representation of this DateTime appropriate for use in SQL Date.
   * @example 2014-07-13
   * @param date JS Date object or string
   * @returns string representation of this DateTime appropriate for use in SQL Date
   */
  toSQLDate = (date: Date | string) => {
    return this.toDateTime(date).toSQLDate();
  };

  /**
   * Converts JS Datetime to locale time format
   * @example 4:50 PM
   * @param date JS Date object or string
   * @returns date format in `4:50 PM` for en-US
   */
  toSimpleTime = (date: Date | string) => {
    return this.toDateTime(date).toLocaleString(this._DateTime.TIME_SIMPLE);
  };

  /**
   * Converts JS Datetime to locale time format in 24 hr time with seconds
   * @example `16:50:36`
   * @param date JS Date object or string
   * @returns date format in `16:50:36` for en-US
   */
  to24HrTimeWithSec = (date: Date | string) => {
    return this.toDateTime(date).toLocaleString(this._DateTime.TIME_24_WITH_SECONDS);
  };

  /**
   * Converts JS Datetime to 24 hrs time format
   * @example 20:50
   * @param date JS Date object or string
   * @returns date format in `20:50` for en-US
   */
  to24HrTime = (date: Date | string) => {
    return this.toDateTime(date).toLocaleString(this._DateTime.TIME_24_SIMPLE);
  };

  /**
   * Converts JS Datetime to locale date followed by time format
   * @example 4:50 PM, 04/14/2022
   * @param date JS Date object or string
   * @returns date format in `4:50 PM, 04/14/2022` for en-US
   */
  toSimpleTimeDate = (date: Date | string) => {
    return this.toDateTime(date).toFormat(luxonDateFormats[this._dateFormat].simpleWTime);
  };

  /**
   * Converts JS Datetime to locale time followed by weekday format
   * @example Mon, 4:50 PM
   * @param date JS Date object or string
   * @returns date format in `Mon, 4:50 PM` for en-US
   */
  toSimpleWeekdayTime = (date: Date | string) => {
    return this.toDateTime(date).toFormat('ccc, h:mm a');
  };

  /**
   * Converts JS Datetime to locale time followed by date format
   * @example 04/14/2022, 4:50 PM
   * @param date JS Date object or string
   * @returns date format in `04/14/2022, 4:50 PM` for en-US
   */
  toSimpleDateTime = (date: Date | string) => {
    return this.toSimpleTimeDate(date).split(',').reverse().join(', ');
  };

  /**
   * Converts JS Datetime to locale date followed by time format
   * @example Oct 14, 1983, 4:50:26 PM
   * @param date JS Date object or string
   * @returns date format in `Oct 14, 1983, 4:50:26 PM` for en-US
   */
  toShortDateTime = (date: Date | string) => {
    return this.toDateTime(date).toFormat(luxonDateFormats[this._dateFormat].shortWFullTime);
  };

  /**
   * Converts JS Datetime to locale date followed by time format
   * @example Oct 14, 1983, 4:50:26 PM
   * @param date JS Date object or string
   * @returns date format in `MMM DD, YYYY` format. Example, `Oct 14, 1983` for en-US
   */
  toShortDate = (date: Date | string) => {
    return this.toDateTime(date).toFormat(luxonDateFormats[appDateFormats[1]].short);
  };

  /**
   * Converts JS Datetime to locale date followed by time format
   * @example October 14, 4:50 PM
   * @param date JS Date object or string
   * @returns date format in `October 14, 4:50 PM` for en-US
   */
  toShortDateTimeWoYear = (date: Date | string) => {
    return this.toDateTime(date).toFormat(luxonDateFormats[this._dateFormat].shortDateTimeWoYear);
  };

  /**
   * Converts JS Datetime to locale date without year
   * @example Oct 14
   * @param date JS Date object or string
   * @returns date format in `Oct 14` for en-US
   */
  toShortDateWoYear = (date: Date | string, formatConfig?: FormatConfig) => {
    return this.toDateTime(date, formatConfig?.disableTimezone).toFormat(
      luxonDateFormats[this._dateFormat].shortWoYear,
    );
  };

  /**
   * Converts JS Datetime to locale date without comma
   * @example Oct 14 1983
   * @param date JS Date object or string
   * @returns date format in `Oct 14` for en-US
   * @example LLL dd yyyy or dd LLL yyyy
   */
  toShortDateWoComma = (date: Date | string) => {
    return this.toDateTime(date).toFormat(
      luxonDateFormats[this._dateFormat].short.replace(/[,]/g, ''),
    );
  };

  /**
   * Converts JS Datetime to locale date followed by time format with offset
   * @example Oct 14, 1983, 4:50:26 PM (PST)
   * @param date JS Date object or string
   * @returns date format in `Oct 14, 1983, 4:50:26 PM (PST)` for en-US
   */
  toShortDateTimeWithOffset = (date: Date | string) => {
    return `${this.toShortDateTime(date)} (${this.namedOffset})`;
  };

  /**
   * Converts JS Datetime to locale date format
   * @example 04/14/2022
   * @param date JS Date object or string
   * @returns date format in `04/14/2022` for en-US
   */
  toFullDate = (date: Date | string) => {
    return this.toDateTime(date).toFormat(luxonDateFormats[this._dateFormat].simple);
  };

  /**
   * Converts JS Datetime to locale date followed by time format
   * @example 04/14/2022, 4:50:26 PM
   * @param date JS Date object or string
   * @returns date format in `04/14/2022, 4:50:26 PM` for en-US
   */
  toFullDateTime = (date: Date | string) => {
    return this.toDateTime(date).toFormat(luxonDateFormats[this._dateFormat].simpleWFullTime);
  };

  /**
   * Converts JS Datetime to be displayed in date field
   * @returns date format in `04/14/2022 or 14/04/2022` for en-US
   *
   */
  toDateFieldDisplay = () => {
    return luxonDateFormats[this._dateFormat].datePickerFormat;
  };
}

const Locale = new LocaleConfig();

export { Locale };
