import { HStack, Text, VStack } from '@chakra-ui/react';
import { API } from 'core/api';
import FormField from 'library/molecules/FormField/FormField';
import { THEME_COLORS } from 'library/theme/colors';
import { useEffect, useMemo } from 'react';
import { components } from 'react-select';
import { LCRFormType, ScheduleTypes } from 'views/main/organization/lcr/types/LCR.types';
import { useGetListOfSchedulesQuery } from 'views/main/organization/schedules/graphql/query';
import {
  defaultReactQueryConfig,
  reactQueryConfigError,
} from 'views/main/organization/schedules/helpers/helpers.schedule';
import FormDropdown from '../FormDropdown/FormDropdown';

interface Props {
  error?: string;
  name: string;
  isRequired?: boolean;
  value?: LCRFormType['Service'];
  onMount?: (ownerOptions: ScheduleTypes[]) => void;
  onClearField?: () => void;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: any;
  menuPortalTarget?: boolean;
}
const EntitySchduleDropDown = ({
  name,
  error,
  onClearField,
  placeholder,
  style,
  value,
  onMount,
  onChange,
  menuPortalTarget = false,
}: Props) => {
  const { data: services } = useGetListOfSchedulesQuery(
    {
      filters: {
        escalationPolicies: [],
        limit: 0,
        myOncall: false,
        noEscalationPolicies: false,
        offset: 0,
        participants: [],
        scheduleIDs: [],
        teamID: API.config.teamId,
        search: '',
      },
    },
    { ...defaultReactQueryConfig, cacheTime: 0, onError: reactQueryConfigError('Get Schedules') },
  );

  const servieList: any[] = services?.schedules ?? [];

  useEffect(() => {
    if (servieList.length > 0 && !value?.id) {
      onMount?.(servieList);
    }
  }, []);

  const SingleValue = (props: any) => {
    return (
      <components.SingleValue {...props}>
        <HStack>
          <Text style={{ color: 'black' }}>{props.data.name}</Text>
          {props.data.username && <Text>({props.data.username})</Text>}
        </HStack>
      </components.SingleValue>
    );
  };

  return (
    <FormField error={error} onClearField={onClearField} style={{ ...style }}>
      <FormDropdown<ScheduleTypes>
        options={servieList}
        name={name}
        value={value}
        menuPortalTarget={menuPortalTarget ? document.body : null}
        menuListWidth={'100px'}
        getOptionLabel={option => option.name}
        components={{ SingleValue }}
        getOptionValue={option => `${option.ID}`}
        placeholder={placeholder ?? 'Select ...'}
        onChange={(e: any) => {
          onChange(e);
        }}
        formatOptionLabel={option => (
          <VStack alignItems={'left'} spacing={0}>
            {option.name && (
              <Text
                style={{
                  color:
                    value && value?.find((ele: any) => ele.name === option.name)
                      ? THEME_COLORS.primary[100]
                      : 'black',
                }}
              >
                {option.name}
              </Text>
            )}
          </VStack>
        )}
      />
    </FormField>
  );
};

export default EntitySchduleDropDown;
