import { ofType } from 'redux-observable';
import { map, catchError, switchMap, mergeMap } from 'rxjs/operators';
import { Observable, of, forkJoin } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import {
  REQUEST_ORG_CALENDAR,
  REQUEST_ORG_CALENDAR_FAIL,
  REQUEST_ORG_CALENDAR_ON_CALL,
  REQUEST_ORG_CALENDAR_ON_CALL_FAIL,
} from '../../const/organization';
import { getOrgCalendar, getOrgCalendarOnCall } from '../../api/organization/getCalendar';
import { ISCalendar, ISCalendarOnCallResolve } from '../../interfaces/ICalendar';
import {
  requestOrganizationCalendarOnCall,
  onRequestOrganizationCalendarSuccess,
  onRequestOrganizationCalendarOnCallSuccess,
} from '../../actions/organization/calender';

const getOrganizationsCalendarEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ORG_CALENDAR),
    map(action => action.payload),
    mergeMap(() => {
      return getOrgCalendar().pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ data }: { result: boolean; data: ISCalendar[] }) => {
          return data;
        }),
        catchError((error: AjaxError) => {
          if (error.status === 403) {
            return of([]);
          }

          return of({
            type: REQUEST_ORG_CALENDAR_FAIL,
            payload: error.xhr,
            error: true,
          });
        }),
      );
    }),
    switchMap((data: any) => {
      if (data.type === REQUEST_ORG_CALENDAR_FAIL) {
        return of({
          type: REQUEST_ORG_CALENDAR_FAIL,
          payload: 'MAP',
          error: true,
        });
      }

      return [
        onRequestOrganizationCalendarSuccess(data),
        requestOrganizationCalendarOnCall(data.map((cid: any) => cid.id)),
      ];
    }),
  );

const getOrganizationsCalendarOnCallEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ORG_CALENDAR_ON_CALL),
    map(action => action.payload.calendarIds),
    mergeMap((calendarIds: string[]) => {
      return forkJoin(
        calendarIds.map(cid => {
          return getOrgCalendarOnCall(cid).pipe(
            map(({ response }: AjaxResponse) => response),
            map(({ data: { users } }: ISCalendarOnCallResolve) => {
              return {
                [cid.toString()]: users,
              };
            }),
            catchError((error: any) => {
              return of({
                type: REQUEST_ORG_CALENDAR_ON_CALL_FAIL,
                payload: error.xhr,
                error: true,
              });
            }),
          );
        }),
      );
    }),
    switchMap((data: any) => {
      if (data.some((d: any) => d.type === REQUEST_ORG_CALENDAR_ON_CALL_FAIL)) {
        return of({
          type: REQUEST_ORG_CALENDAR_ON_CALL_FAIL,
          payload: '_MAP',
          error: true,
        });
      }

      const onCall = data.reduce(
        (c: any, n: any) => ({
          ...c,
          ...n,
        }),
        {},
      );

      return of(onRequestOrganizationCalendarOnCallSuccess(onCall));
    }),
  );

export { getOrganizationsCalendarEpic, getOrganizationsCalendarOnCallEpic };
