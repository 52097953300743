import {
  ErrorBlock,
  FocusBlock,
  FormBlock,
  Grid,
  Heading,
  InputBlock,
  Label,
  Para,
  PulseLoader,
  SelectBox,
  TextButton,
  Theme,
  Tooltip,
} from 'uie/components';
import { InfoIcon } from 'icons';
import React from 'react';
import * as shortId from 'shortid';
import { Banner } from '../../../components/Banner';
import PageLoaderComponent from '../../../components/PageLoader';
import { IOrganization } from '../../../core/interfaces/IOrganization';
import { dialCodes } from '../../../store/countryCodes';
import { OnBoarding } from './index';
import './index.css';

export function render(this: OnBoarding) {
  const { theme } = Theme;
  const {
    appState,
    firstName,
    lastName,
    organization,
    mobile,
    errors,
    password,
    showPassword,
    componentState,
  } = this.state;

  if (appState === 'back') {
    return <PageLoaderComponent />;
  }

  if (appState === 'onboard' || appState === 'onboard-invite') {
    const selectedDial = dialCodes.find(
      ({ dial_code }: any) => dial_code === this.state.dialCode,
    ) || {
      dial_code: '',
      code: '',
    };

    return (
      <div className="onboard-flex-row">
        <Banner />
        <div className="onboard-main-container">
          <div className="onboard-main-content" style={{ minHeight: 500 }}>
            <div className="onboard-header" style={{ marginBottom: 20 }}>
              <Heading fontSize={24} height={24}>
                Welcome to Squadcast!
              </Heading>
              <Para style={{ maxWidth: 400, marginTop: 10 }}>
                Now manage your incidents without hassle! We need some basic information to setup
                your account.
              </Para>
            </div>
            <div className="onboard-form-container">
              <FormBlock onFormSubmit={this.onSubmitOnboard}>
                <Grid type="column">
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 10,
                      fontWeight: 300,
                    }}
                    htmlFor="onboard-input-first-name"
                  >
                    First Name *
                  </Label>
                  <InputBlock
                    id="onboard-input-first-name"
                    autoComplete="off"
                    error={!!errors.firstName}
                    height="40px"
                    width="100%"
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={this.onValueChange('firstName')}
                    required={true}
                  />
                  <ErrorBlock>{errors.firstName}</ErrorBlock>
                </Grid>
                <Grid type="column">
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 10,
                      fontWeight: 300,
                    }}
                    htmlFor="onboard-input-last-name"
                  >
                    Last Name
                  </Label>
                  <InputBlock
                    id="onboard-input-last-name"
                    autoComplete="off"
                    error={!!errors.lastName}
                    height="40px"
                    width="100%"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={this.onValueChange('lastName')}
                  />
                  <ErrorBlock>{errors.lastName}</ErrorBlock>
                </Grid>
                {appState === 'onboard' && (
                  <Grid type="column">
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 10,
                        fontWeight: 300,
                      }}
                      htmlFor="onboard-input-org-name"
                    >
                      Your organization *
                    </Label>
                    <InputBlock
                      id="onboard-input-org-name"
                      autoComplete="off"
                      error={!!errors.organization}
                      height="40px"
                      width="100%"
                      type="text"
                      placeholder="Organization Name"
                      value={organization}
                      onChange={this.onValueChange('organization')}
                      required={true}
                    />
                    <ErrorBlock>{errors.organization}</ErrorBlock>
                  </Grid>
                )}
                {appState === 'onboard-invite' && (
                  <Grid type="column">
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 10,
                        fontWeight: 300,
                      }}
                      htmlFor="onboard-input-password"
                    >
                      Password *
                    </Label>
                    <Grid>
                      <InputBlock
                        id="onboard-input-password"
                        autoComplete="new-password"
                        height="40px"
                        width="100%"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="password"
                        value={password}
                        onChange={this.onValueChange('password')}
                        required={true}
                      />
                      <img
                        onClick={this.toggleShowPassword}
                        style={{ marginLeft: -40, cursor: 'pointer', maxWidth: 20 }}
                        src={`/icons/${!showPassword ? 'show_password' : 'hide_password'}.svg`}
                        alt="show password"
                      />
                    </Grid>
                    <ErrorBlock>{errors.password}</ErrorBlock>
                  </Grid>
                )}
                {
                  <Grid type="column">
                    <Grid>
                      <Label
                        style={{
                          fontSize: 14,
                          marginBottom: 10,
                          marginRight: 5,
                          fontWeight: 300,
                        }}
                        htmlFor="onboard-input-password"
                      >
                        Phone Number
                      </Label>
                      <Tooltip
                        label="This field is optional. If you wish to receive incident notifications,
                                verify your mobile number post sign-up under your profile settings."
                        width="400px"
                        offset={{ left: '30px', top: '-50px' }}
                      >
                        <InfoIcon
                          style={{ width: 15, height: 15, color: theme.shades.lightGrey }}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid>
                      <div className="height-40" style={{ width: 200 }}>
                        <SelectBox
                          hook={
                            <div style={{ height: 20 }}>
                              <span>{selectedDial.dial_code}</span>
                              <span style={{ color: theme.shades.grey, marginLeft: 10 }}>
                                {selectedDial.code}
                              </span>
                            </div>
                          }
                          onValueChange={(_, v: string) => {
                            this.setState({ dialCode: v });
                          }}
                          searchHookProps={{
                            value: this.state.countryCodeSearch,
                            onChange: e => this.searchValueChange(e.target.value),
                          }}
                          height="200px"
                          width="100%"
                          maxWidth="100%"
                        >
                          {dialCodes
                            .filter(({ name, dial_code }: { name: string; dial_code: string }) => {
                              return (
                                this.state.countryCodeSearch === '' ||
                                name.toLowerCase().indexOf(this.state.countryCodeSearch) > -1 ||
                                dial_code.toLowerCase().indexOf(this.state.countryCodeSearch) > -1
                              );
                            })
                            .map(({ dial_code, code }: { dial_code: string; code: string }) => (
                              <FocusBlock
                                value={dial_code}
                                key={shortId.generate()}
                                isSelected={dial_code === this.state.dialCode}
                              >
                                <div>
                                  <span>{dial_code}</span>
                                  <span style={{ color: theme.shades.grey, marginLeft: 10 }}>
                                    {code}
                                  </span>
                                </div>
                              </FocusBlock>
                            ))}
                        </SelectBox>
                      </div>
                      <InputBlock
                        height="40px"
                        width="100%"
                        type="text"
                        value={mobile}
                        error={!!errors.mobile}
                        placeholder="Phone Number"
                        onChange={this.onValueChange('mobile')}
                        style={{ marginLeft: 10 }}
                      />
                    </Grid>
                    <ErrorBlock>{errors.mobile}</ErrorBlock>
                  </Grid>
                }
                <Grid>
                  <ErrorBlock style={{ maxWidth: 400 }}>{this.state.onBoardError}</ErrorBlock>
                </Grid>
                {
                  <TextButton
                    className="login-get-started onboard-update-button"
                    height="64px"
                    width="100%"
                    disabled={
                      componentState === 'busy' ||
                      this.state.firstName === '' ||
                      this.state.lastName === '' ||
                      (appState === 'onboard-invite' && this.state.password === '') ||
                      (appState === 'onboard' && this.state.organization === '')
                    }
                    onClick={
                      this.state.trialSignup ? this.onSubmitOnboard : this.onSubmitFreePlanOnboard
                    }
                  >
                    {componentState === 'busy' ? (
                      <Grid alignContent="center" justifyContent="center">
                        <PulseLoader color={theme.shades.white} />
                      </Grid>
                    ) : appState === 'onboard-invite' ? (
                      'UPDATE'
                    ) : this.state.trialSignup ? (
                      'START TRIAL'
                    ) : (
                      'START FREE PLAN'
                    )}
                  </TextButton>
                }
              </FormBlock>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (appState === 'noorg') {
    return (
      <div className="onboard-flex-row">
        <Banner />
        <div className="h-1-1 h-c-1-1 w-1-1 onboard-background">
          <Grid height="100%" justifyContent="center" alignItems="center">
            <div className="onboard-modal" style={{ width: '100%', maxWidth: 500 }}>
              <div className="onboard-headers">
                <Para fontSize={24}>No Organization exist in your account!!</Para>
                <img
                  src="/icons/empty-states/no-orgs.svg"
                  alt="no-orgs"
                  style={{ margin: '40px 0' }}
                />
              </div>
              <div className="onboard-form-container">
                <FormBlock onFormSubmit={this.onSubmitNewOrg}>
                  <Grid style={{ flexDirection: 'column' }}>
                    <Label htmlFor="ob_organization" style={{ marginBottom: 10 }}>
                      Organization Name
                    </Label>
                    <InputBlock
                      id="ob_organization"
                      autoComplete="off"
                      height="40px"
                      width="100%"
                      type="text"
                      placeholder="Organization Name"
                      value={organization}
                      onChange={this.onValueChange('organization')}
                      required={true}
                    />
                  </Grid>
                  <ErrorBlock>
                    {Object.entries(errors).map(([key, value], index) => (
                      <p className="w-1-1 m-0" key={index}>
                        {value}
                      </p>
                    ))}
                  </ErrorBlock>
                  <TextButton
                    className="login-get-started onboard-update-button"
                    height="64px"
                    width="100%"
                    disabled={componentState === 'busy'}
                    onClick={this.onSubmitNewOrg}
                    style={{ marginTop: 20 }}
                  >
                    {componentState === 'busy' ? (
                      <Grid alignContent="center" justifyContent="center">
                        <PulseLoader color={theme.shades.white} />
                      </Grid>
                    ) : (
                      'Create new organization'
                    )}
                  </TextButton>
                </FormBlock>
              </div>
            </div>
          </Grid>
        </div>
      </div>
    );
  }

  if (appState === 'deactivated') {
    const orgs = this.props.userOrganizations.o.length;
    return (
      <div className="onboard-flex-row">
        <Banner />
        <div className="h-1-1 h-c-1-1 w-1-1 onboard-background">
          <Grid height="100%" justifyContent="center" alignItems="center">
            <div className="onboard-modal p-40">
              <div className="onboard-headers w-1-1">
                <h4 className="m-0 onboard-welcome-h4 w-1-1">No active organizations</h4>
              </div>
              <Grid justifyContent="flex-start" alignItems="center" className="tob-bar-trial mt-10">
                <a
                  href="mailto:support@squadcast.com?subject=Request to activate our organization"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/icons/info_black.svg"
                    alt="more info"
                    style={{ height: 16, marginTop: '5px' }}
                  />
                </a>

                <Para className="ml-10" color={theme.shades.cement} fontWeight={500}>
                  <a
                    href="mailto:support@squadcast.com?subject=Request to activate our organization"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    To activate your organization contact&nbsp;
                  </a>
                  <a
                    href="mailto:support@squadcast.com?subject=Request to activate our organization"
                    rel="noopener noreferrer"
                  >
                    support@squadcast.com
                  </a>
                </Para>
              </Grid>
              <div>
                <div
                  className="onboard-deactivate-org-container"
                  style={{
                    overflowY: this.props.userOrganizations.o.length > 4 ? 'scroll' : 'hidden',
                  }}
                >
                  {this.props.userOrganizations.o.map((data: IOrganization, index: number) => {
                    return (
                      <div
                        key={index}
                        className="onboard-deactivate-org"
                        style={{
                          borderBottom: orgs !== index + 1 ? '2px solid var(--shades-grey)' : '',
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            width: '100%',
                          }}
                        >
                          <Para
                            fontWeight={500}
                            className="onboard-org-name"
                            color={theme.shades.grey}
                            fontSize={24}
                          >
                            {data.organizationName.slice(0, 23) +
                              (data.organizationName.length > 23 ? '...' : '')}
                          </Para>
                        </div>
                      </div>
                    );
                  })}
                  <button
                    className="login-signin-link btn nofocus"
                    onClick={this.props.requestUserLogout}
                  >
                    Logout
                  </button>
                </div>
                {this.state.cannotChangeOrg && (
                  <ErrorBlock>
                    You don't have permissions to activate this organization, please contact the
                    account owner
                  </ErrorBlock>
                )}
              </div>
            </div>
          </Grid>
        </div>
      </div>
    );
  }
}
