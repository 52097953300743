import {
  ChangeParticipantsInterval,
  CustomPeriodUnit,
  PeriodOptions,
  DayOfWeek,
} from 'views/main/organization/schedules/graphql/types';

export const rotationsCustomizePattern = {
  heading: 'Customize Rotation Pattern',
  labels: {
    rotationName: 'Rotation Name',
    participants: 'Participants in order of rotation',
    rotationColor: 'Select Rotation Display Colour',
    viewGaps: 'View Gaps',
    duration: {
      startDate: 'Start Date',
      repeats: 'Repeats',
      changeParticipants: 'Change Participants',
      onCallHours: 'On-call hrs / Day',
      onCallTime: 'On-call Start & End Time',
      daysAndTime: 'Days and Time',
    },
  },
  placeholders: {
    participants: 'Type participant name',
    rotationName: 'Type rotation name',
    rotationColor: 'Select rotation color',
  },
  weekDayFilterDefaultValues: [false, false, false, false, false, false, false],
  customRepeat: {
    heading: 'Custom Repeat',
    labels: {
      repeatEvery: 'Every',
      repeatOnWeekly: 'Repeat on',
      repeatOnMonthly: 'On',
      ends: 'End Date',
      addParticipants: 'Add Participant group',
      repeatHoursAndTime: 'Repeat hrs and time for all selected days',
      startTime: 'START TIME',
      endTime: 'END TIME',
    },
    buttons: {
      done: 'Done',
      cancel: 'Cancel',
      addSlot: '+ slot',
    },
    selectOptions: {
      interval: [
        { label: 'day', value: CustomPeriodUnit.Day },
        { label: 'week', value: CustomPeriodUnit.Week },
        // Deprecating custom monthly rotation option for now
      ],
      endsOn: [{ label: 'Nov 01,2022', value: '01Nov' }],
      endsAfter: [
        { label: '10 occurences', value: '10' },
        { label: '20 occurences', value: '20' },
        { label: '30 occurences', value: '30' },
      ],
      daysOfWeek: [
        DayOfWeek.Sunday,
        DayOfWeek.Monday,
        DayOfWeek.Tuesday,
        DayOfWeek.Wednesday,
        DayOfWeek.Thursday,
        DayOfWeek.Friday,
        DayOfWeek.Saturday,
      ],
      ranks: ['first', 'second', 'third', 'fourth', 'fifth', 'last'],
    },
    monthsOfYear: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },
  selectOptions: {
    repeats: [
      { label: 'None', value: PeriodOptions.None },
      { label: 'Daily', value: PeriodOptions.Daily },
      { label: 'Weekly', value: PeriodOptions.Weekly },
      { label: 'Monthly', value: PeriodOptions.Monthly },
      { label: 'Custom', value: PeriodOptions.Custom },
    ],
    changeParticipants: [
      { label: 'Day', value: ChangeParticipantsInterval.Day },
      { label: 'Week', value: ChangeParticipantsInterval.Week },
      { label: 'Month', value: ChangeParticipantsInterval.Month },
      { label: 'Rotation', value: ChangeParticipantsInterval.Rotation },
    ],
    onCallHours: [
      { label: '04:00 hrs', value: 240 },
      { label: '08:00 hrs', value: 480 },
      { label: '12:00 hrs', value: 720 },
      { label: '16:00 hrs', value: 960 },
      { label: '20:00 hrs', value: 1200 },
      { label: '24:00 hrs (all day)', value: 1440 },
    ],
  },
};

export const initialSlotValue = {
  startTime: '00:00',
  endTime: '00:30',
  duration: 30,
  id: 1,
  isSameDay: true,
};

export const initialCustomPeriodValue = {
  periodFrequency: 1,
  periodUnit: CustomPeriodUnit.Day,
  daysOfWeekFilter: rotationsCustomizePattern.weekDayFilterDefaultValues,
  repeatHoursAndTime: false,
  timeSlots: [
    {
      startTime: initialSlotValue.startTime,
      duration: initialSlotValue.duration,
      endTime: initialSlotValue.endTime,
      isSameDay: initialSlotValue.isSameDay,
      id: initialSlotValue.id,
    },
  ],
};

export enum ChangeParticipantsFreqLimit {
  Day = 366,
  Week = 53,
  Month = 12,
  Rotation = 30,
}

export enum CustomRepeatFreqLimit {
  Day = 366,
  Week = 53,
  Month = 12,
}
