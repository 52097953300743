import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { VerticalDots } from 'icons';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { DateTime } from 'luxon';
import React from 'react';
import { Cell } from 'react-table';
import NodePopover from 'views/main/organization/service-catalog/components/Popover';
import { truncateString } from '../../../helpers/helper.details';

import { IPageIssueList, IStatusPageComponentList } from '../../../Interface';

export const tableColumns = [
  {
    Header: 'ISSUE TITLE',
    accessor: (row: IPageIssueList) => row.title,
    Cell: (cell: Cell<IPageIssueList>) => {
      const {
        row: {
          original: { title },
        },
      } = cell;
      return <Text variant="tableData">{truncateString(title, 30)}</Text>;
    },
  },
  {
    Header: 'COMPONENTS',
    accessor: (row: IPageIssueList) => row.components,
    Cell: (cell: Cell<IPageIssueList>) => {
      const {
        row: {
          original: { components },
        },
      } = cell;
      const remainingItems = components.slice(2);
      const componentsText = components
        .slice(0, 2)
        .map((component: IStatusPageComponentList) => component.name)
        .join(' | ');
      return (
        <Flex align="center">
          <Text mr={4} variant="tableData">
            {componentsText}
          </Text>
          {remainingItems.length > 0 ? (
            <NodePopover
              triggerType="hover"
              trigger={
                <Tag size="md">
                  <TagLabel>+ {remainingItems.length}</TagLabel>
                </Tag>
              }
            >
              {remainingItems.map((component: IStatusPageComponentList) => (
                <Text key={component.id} variant="tableData">
                  {component.name}
                </Text>
              ))}
            </NodePopover>
          ) : null}
        </Flex>
      );
    },
  },
  {
    Header: 'STATE',
    accessor: (row: IPageIssueList) => row.currentState,
    Cell: (props: any) => {
      const {
        row: {
          original: { currentState },
        },
      } = props.cell;
      return (
        <Flex flex="1" align="center">
          <Box bgColor={currentState.color} w={3} h={3} borderRadius={6} mr={2} />
          <Box flex="1">
            <Text variant="tableData" color={currentState.color}>
              {currentState.name}
            </Text>
          </Box>
        </Flex>
      );
    },
  },

  {
    Header: 'TRIGGER TIME',
    accessor: (row: IPageIssueList) => row.createdAt,
    Cell: (props: any) => {
      const {
        row: {
          original: { createdAt, id },
        },
      } = props.cell;
      const { onDelete, timezone } = props;
      const fromUTC = DateTime.fromISO(createdAt, { zone: timezone });
      const canEdit = useUserAccess().hasUpdateAccess('status_pages', id);
      return (
        <Flex justifyContent={'space-between'} w={'100%'}>
          <Text variant="tableData">{fromUTC.toLocaleString(DateTime.DATETIME_MED)}</Text>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="options"
              icon={<VerticalDots />}
              variant="unstyled"
              display="flex"
              justifyContent="center"
            />
            <MenuList>
              <NoPermissionTooltip isDisabled={canEdit}>
                <MenuItem
                  onClick={() => {
                    onDelete(id);
                  }}
                  isDisabled={!canEdit}
                >
                  Delete
                </MenuItem>
              </NoPermissionTooltip>
            </MenuList>
          </Menu>
        </Flex>
      );
    },
  },
];
