import { FormikValues } from 'formik';
import {
  INVALID_TAGS_ERROR_MSG,
  DUPLICATE_TAGS_ERROR_MSG,
  VALID_TAG_KEYS,
  INVALID_TAG_KEY_ERROR_MSG,
} from '../common/constants';
import { INCIDENT_TAGS } from '../common/types';

import { FormValues } from '../interfaces/common';

type ValidationError = {
  title: string;
  services: string;
  assignedkey: string;
  assigned: string;
  tags: string;
};

export const isCreationFormComplete = (values: FormValues | FormikValues) => {
  const trimmedTitle = values.title.trim();

  if (!trimmedTitle) {
    return false;
  }

  if (!values.services) {
    return false;
  }

  if (!values.assigned || !values.assignedkey) {
    return false;
  }
  if (
    values.tags?.some((tag: INCIDENT_TAGS) => {
      return !tag.color || !tag.key || !tag.value;
    })
  ) {
    return false;
  }
  // check for unique tags
  const set = new Set(values.tags.map((tag: INCIDENT_TAGS) => `${tag.key}:${tag.value}`));
  if (set.size < values.tags.length) {
    return false;
  }
  return true;
};

export const IncidentCreateValidatorFunc = (values: FormValues | FormikValues) => {
  const errors: Partial<ValidationError> = {};
  const trimmedTitle = values.title.trim();

  if (!trimmedTitle) {
    errors.title = 'Title is required';
  }

  if (!values.services) {
    errors.services = 'Service is required';
  }
  if (!values.assigned || !values.assignedkey) {
    errors.assignedkey = '';
    errors.assigned = 'Assignee is required';
  }
  if (values.tags?.some((tag: INCIDENT_TAGS) => !tag.color || !tag.key || !tag.value)) {
    errors.tags = INVALID_TAGS_ERROR_MSG;
  } else if (values.tags?.some((tag: INCIDENT_TAGS) => !VALID_TAG_KEYS.test(tag.key))) {
    errors.tags = INVALID_TAG_KEY_ERROR_MSG;
  } else {
    // check for uniqueness
    const set = new Set(values.tags.map((tag: INCIDENT_TAGS) => `${tag.key}:${tag.value}`));
    if (set.size < values.tags.length) {
      errors.tags = DUPLICATE_TAGS_ERROR_MSG;
    }
  }
  return errors;
};
