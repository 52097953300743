import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IAppState } from '../../../core/interfaces/IAppState';
import { requestInitOrg } from '../../../core/actions/organization/initOrg';
import cx from 'classnames';
import './index.css';
import {
  Grid,
  Para,
  TextButton,
  Theme,
  Tooltip,
  Avatar,
  DropDown,
  FocusBlock,
  SpinLoader,
  Pill,
} from 'uie/components';
import { IWebAppRoutes, IWebAppVisibleRoutes } from '../../../core/interfaces/IAppRouts';
import { setSidebarState } from '../../../core/actions';
import {
  ChevronIcon,
  PhoneDisabledIcon,
  SmallLogoWhiteIcon,
  NewIcon,
  CrownSmallIcon,
  BetaIcon,
} from '../../../icons';
import { IComponentState } from '../../../core/interfaces/IComponentState';
import { checkIfActionChanged } from 'core/helpers';
import { filterManager } from '../organization/incident-list/filters/manager';
import { THEME_COLORS } from 'library/theme/colors';

interface IProps extends Pick<IAppState, 'organization' | 'userOrganizations' | 'APP_CONFIG'> {
  visibleRoutes: IWebAppVisibleRoutes[];
  subNavRoutes: IWebAppRoutes[];
  pathName: string;
  isRefresh: boolean;
  requestInitOrg: typeof requestInitOrg;
  setSidebarState: typeof setSidebarState;
}

interface IState {
  componentState: IComponentState;
}

class SideBar extends React.Component<IProps, IState> {
  _keyMod = navigator.platform.toLowerCase().includes('mac') ? 'opt' : 'alt';

  constructor(props: IProps) {
    super(props);

    this.state = {
      componentState: 'idle',
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const selectedRouteName =
      [...this.props.subNavRoutes, ...this.props.visibleRoutes].find(r =>
        r.focusedFor.test(this.props.pathName),
      )?.name || '';

    document.title = `Squadcast ${selectedRouteName !== '' ? `· ${selectedRouteName}` : ''}`;

    if (
      checkIfActionChanged(
        prevProps.organization.acl,
        this.props.organization.acl,
        'action',
        'REQUEST_ORG_USER_ACL',
      )
    ) {
      this.setState({ componentState: 'busy' });
    }

    if (
      checkIfActionChanged(
        prevProps.organization.acl,
        this.props.organization.acl,
        'action',
        'REQUEST_ORG_USER_ACL_SUCCESS',
      )
    ) {
      setTimeout(() => {
        this.setState({ componentState: 'idle' });
      }, 1000);
    }
  }

  onToggleSidebar = () =>
    this.props.setSidebarState(
      this.props.APP_CONFIG.sidebarState === 'expanded' ? 'collapsed' : 'expanded',
    );

  onSelectOrganization = (_: any, __: any, orgId: string) => {
    // clear all session related data (majorly used for IL)
    filterManager.clearFiltersFromStorage();
    this.props.requestInitOrg(orgId);
  };

  public render() {
    const { theme } = Theme;
    const { componentState } = this.state;
    const { organization, userOrganizations, visibleRoutes, APP_CONFIG, pathName, isRefresh } =
      this.props;

    const groupedVisibleRoutes = visibleRoutes.reduce(
      (c: Record<string, IWebAppVisibleRoutes[]>, n) => {
        if (!c[n.group]) {
          c[n.group] = [n];
        } else {
          c[n.group].push(n);
        }
        return c;
      },
      {},
    );

    const routeLink = (r: IWebAppVisibleRoutes) => {
      return (
        <Link
          key={r.route}
          to={r.route}
          className={cx('side_bar__route_link', [APP_CONFIG.sidebarState])}
        >
          <Tooltip
            label={`${r.name}`}
            offset={{ top: '-27px', left: '52px' }}
            disabled={APP_CONFIG.sidebarState === 'expanded'}
            delay={0}
          >
            <div
              className={cx('side_bar__route_tab__accessor', {
                selected: r.focusedFor.test(pathName),
              })}
            >
              <Grid
                className={cx('side_bar__route_tab', [APP_CONFIG.sidebarState])}
                alignItems="center"
                justifyContent="flex-start"
              >
                <r.icon height={20} width={20} />
                {r.proIcon ? (
                  <CrownSmallIcon
                    className="pro"
                    style={{
                      position: 'absolute',
                      left: '3px',
                      top: '-10px',
                      height: 14,
                      width: 14,
                    }}
                  />
                ) : (
                  ''
                )}
                {r.isNew && !r.proIcon ? (
                  <NewIcon
                    className="newFlag"
                    style={{ position: 'absolute', left: '25px', top: '15px' }}
                  />
                ) : (
                  ''
                )}
                {r.isBeta ? (
                  <BetaIcon
                    className="newFlag"
                    style={{ position: 'absolute', left: '25px', top: '15px' }}
                  />
                ) : (
                  ''
                )}

                <Para
                  className="side_bar__route_tab_label open_block"
                  fontSize={14}
                  fontWeight={400}
                >
                  {r.name}
                </Para>
              </Grid>
            </div>
          </Tooltip>
        </Link>
      );
    };

    const userAccessibleOrganizations = userOrganizations.o.filter(
      o => o.organizationId !== organization.currentOrg.o?.organizationId && !o.deActivated,
    );

    const currentOrg = (
      <Grid alignItems="center" className={cx('side_bar__org_select', [APP_CONFIG.sidebarState])}>
        <Tooltip
          label={organization.currentOrg.o?.organizationName || ''}
          delay={0}
          disabled={APP_CONFIG.sidebarState === 'expanded'}
          offset={{ top: '-32px', left: '42px' }}
        >
          {isRefresh || componentState !== 'idle' ? (
            <section className="side_bar__org_refresh">
              <SpinLoader base="12px" color={theme.shades.white} />
            </section>
          ) : (
            <Avatar
              type="rounded"
              backgroundColor={theme.primary.default}
              coatColor={theme.shades.white}
              base={8}
              attributeHook={
                organization.currentOrg.o?.sandbox && (
                  <PhoneDisabledIcon height={8} width={8} stroke={theme.danger.default} />
                )
              }
              attributeProps={{
                base: 6,
                backgroundColor: theme.shades.white,
              }}
            >
              {organization.currentOrg.o?.organizationName || ''}
            </Avatar>
          )}
        </Tooltip>

        <Grid type="column" className="side_bar__org_name">
          <Para fontSize={14} fontWeight={500}>
            {organization.currentOrg.o?.organizationName || ''}
          </Para>
          <Para fontSize={12} fontWeight={300}>
            {organization.currentUser.u?.first_name}
          </Para>
        </Grid>
      </Grid>
    );

    return (
      <div
        id="main_app_interface__sidebar"
        className={cx('side_bar__container', [APP_CONFIG.sidebarState])}
      >
        <Grid flexWidth={12} type="column" justifyContent="space-between" height="100%">
          <div className="side_bar__container_top">
            {userAccessibleOrganizations.length === 0 ? (
              currentOrg
            ) : (
              <DropDown
                offset="8px"
                hook={currentOrg}
                style={{ marginTop: -20 }}
                width="300px"
                maxWidth="200px"
                height="200px"
                maxHeight="400px"
                aria-disabled={true}
              >
                {userAccessibleOrganizations.map(o => {
                  return (
                    <FocusBlock
                      key={o.organizationId}
                      value={o.organizationId}
                      style={{ padding: '8px' }}
                      onSelectValue={this.onSelectOrganization}
                    >
                      <Grid
                        alignItems="center"
                        justifyContent="flex-start"
                        className="side_bar__org_select_dropdown"
                      >
                        <div>
                          <Avatar
                            type="rounded"
                            backgroundColor={theme.primary.default}
                            coatColor={theme.shades.white}
                            base={8}
                            attributeHook={
                              o?.sandbox && (
                                <PhoneDisabledIcon
                                  height={6}
                                  width={6}
                                  stroke={theme.danger.default}
                                />
                              )
                            }
                            attributeProps={{
                              base: 8,
                              backgroundColor: theme.shades.white,
                            }}
                          >
                            {o.organizationName}
                          </Avatar>
                        </div>
                        <Para fontSize={14} fontWeight={400} className="side_bar__org_name ml-10">
                          {o.organizationName}
                        </Para>
                      </Grid>
                    </FocusBlock>
                  );
                })}
              </DropDown>
            )}
            <Grid type="column">
              {(groupedVisibleRoutes?.[1] ?? []).length > 0 &&
                groupedVisibleRoutes['1'].map(routeLink)}

              {(groupedVisibleRoutes?.[2] ?? []).length > 0 && (
                <>
                  <hr className={cx('side_bar__route_divider', [APP_CONFIG.sidebarState])} />
                  {groupedVisibleRoutes[2].map(routeLink)}
                </>
              )}

              {(groupedVisibleRoutes?.[3] ?? []).length > 0 && (
                <>
                  <hr className={cx('side_bar__route_divider', [APP_CONFIG.sidebarState])} />
                  {groupedVisibleRoutes[3].map(routeLink)}
                </>
              )}

              {(groupedVisibleRoutes?.[4] ?? []).length > 0 && (
                <>
                  <hr className={cx('side_bar__route_divider', [APP_CONFIG.sidebarState])} />
                  {groupedVisibleRoutes[4].map(routeLink)}
                </>
              )}
            </Grid>
          </div>
          <Grid type="column" className="side_bar__container_bottom">
            <div className="side_bar__route_link_type">
              <div className="side_bar__route_tab__accessor squad_logo">
                <Grid
                  alignItems="center"
                  justifyContent="flex-start"
                  className={cx('side_bar__route_tab squad_logo', [APP_CONFIG.sidebarState])}
                >
                  <SmallLogoWhiteIcon
                    className="squad_logo_icon"
                    fill={APP_CONFIG.sidebarState === 'collapsed' ? theme.shades.white : '#4EC4CD'}
                    height={24}
                    width={24}
                  />
                  <Grid
                    type="column"
                    alignItems="flex-start"
                    className="open_block squad_logo_block"
                  >
                    <Para fontSize={16} fontWeight={500} color={THEME_COLORS['primary'][900]}>
                      Squadcast
                    </Para>
                    <Para fontSize={10} color={theme.shades.lightGrey}>
                      © Squadcast Inc. 2017-{new Date().getFullYear()}
                    </Para>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Tooltip
              label={'Expand'}
              delay={0}
              disabled={APP_CONFIG.sidebarState === 'expanded'}
              offset={{ top: '-40px', left: '52px' }}
            >
              <TextButton
                color="transparent"
                onClick={this.onToggleSidebar}
                className="side_bar__route_link_type"
              >
                <div className="side_bar__route_tab__accessor">
                  <Grid
                    alignItems="center"
                    justifyContent="flex-start"
                    className={cx('side_bar__route_tab', [APP_CONFIG.sidebarState])}
                  >
                    <ChevronIcon className="side_bar__expand_icon" height={20} width={20} />
                    <Para
                      fontSize={14}
                      fontWeight={400}
                      className="side_bar__route_tab_label open_block"
                    >
                      Collapse
                    </Para>
                  </Grid>
                </div>
              </TextButton>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(
  ({ organization, userOrganizations, APP_CONFIG }: IAppState) => ({
    organization,
    userOrganizations,
    APP_CONFIG,
  }),
  {
    requestInitOrg,
    setSidebarState,
  },
)(SideBar);
