import React from 'react';
import { IconButton, Para, TextButton, Theme } from 'uie/components';
import { CloseIcon } from 'icons';
import { Tooltip, usernameTooltipLabel } from 'library/atoms';
import { truncate } from 'core/helpers/stringUtils';

type Props = {
  label?: string;
  items: any[];
  onRemove?: (item: any) => void;
  showTooltip?: boolean;
  showUserTooltip?: boolean;
};

const { theme } = Theme;

function Badge({ items, label, onRemove, showUserTooltip, showTooltip }: Props) {
  const userItemWithTooltip = () => {
    const text = items[0] as string;
    const startIndex = text?.lastIndexOf('(');
    const endIndex = text?.lastIndexOf(')');
    const username = text?.substring(startIndex + 1, endIndex);
    const fullName = text?.replace(` (${username})`, '');
    const fullNameMaxLength = 20;
    const usernameMaxLength = 15;
    return (
      <Tooltip
        label={usernameTooltipLabel(fullName, username)}
        placement="bottom"
        isDisabled={!(fullName?.length > fullNameMaxLength || username?.length > usernameMaxLength)}
      >
        <Para fontSize={14}>{truncate(fullName, fullNameMaxLength)}</Para>
      </Tooltip>
    );
  };

  const removeFilters = () => {
    if (onRemove) onRemove(items[0]);
  };

  return (
    <TextButton
      style={{
        background: theme.shades.white,
        boxShadow: 'none',
        minWidth: 100,
        padding: '4px 6px',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '4px',
        wordSpacing: '2px',
        marginRight: '10px',
        height: '39px',
        width: '218px',
      }}
    >
      <Para fontSize={14} style={{ maxWidth: '30ch', cursor: 'default' }}>
        <span style={{ fontWeight: 600 }}>{label}</span>
        {items?.length !== 0 && (
          <>
            :
            {showUserTooltip || showTooltip ? (
              userItemWithTooltip()
            ) : (
              <Tooltip
                label={items[0]}
                isDisabled={String(items[0])?.length < 10}
                placement="bottom"
              >
                {truncate(items[0], 10)}
              </Tooltip>
            )}
            {items?.length - 1 !== 0 && (
              <span
                style={{
                  borderRadius: '8px',
                  fontSize: '12px',
                  backgroundColor: theme.shades.smoke,
                  padding: '0 6px',
                  marginLeft: '8px',
                  top: '-1px',
                  position: 'relative',
                }}
              >
                +{items?.length - 1}
              </span>
            )}
          </>
        )}
      </Para>
      <IconButton base="24px" onClick={() => removeFilters()}>
        <CloseIcon />
      </IconButton>
    </TextButton>
  );
}

export default Badge;
