import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xmlSpace="preserve" {...props}>
    <path d="M13.296 22.917v-2C17.904 20.917 21 16.307 21 12c0-4.962-4.037-9-9-9-4.473 0-9 2.886-9 8.401H1C1 4.646 6.668 1 12 1c6.065 0 11 4.935 11 11 0 5.918-4.444 10.917-9.704 10.917z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.905 15.873h-8a1 1 0 1 1 0-2h8a1 1 0 0 1 0 2zM9.905 18.873h-8a1 1 0 1 1 0-2h8a1 1 0 0 1 0 2zM9.905 21.873h-8a1 1 0 1 1 0-2h8a1 1 0 0 1 0 2z"
    />
    <path d="M18.37 12.623h-6.912a1 1 0 0 1-1-1v-6.33a1 1 0 0 1 2 0v5.33h5.911a1 1 0 1 1 0 2z" />
  </svg>
);

export default SvgComponent;
