import React, { createContext, ReactNode, useContext } from 'react';
import { useCreateRuleMutation, useUpdateRuleMutation } from '../../../hooks/useServiceRule';

export type AutomationRuleContextProps = {
  createRule: ReturnType<typeof useCreateRuleMutation>;
  updateRule: ReturnType<typeof useUpdateRuleMutation>;
};

interface Props {
  serviceId: string;
  children: ReactNode;
}

export const AutomationRuleContext = createContext<AutomationRuleContextProps | undefined>(
  undefined,
);

export const AutomationRuleProvider = ({ serviceId, children }: Props) => {
  const value = {
    createRule: useCreateRuleMutation(serviceId),
    updateRule: useUpdateRuleMutation(serviceId),
  };

  return <AutomationRuleContext.Provider value={value}>{children}</AutomationRuleContext.Provider>;
};

export const useAutomationRuleContext = () => useContext(AutomationRuleContext);
