import { SnackContextProvider, Theme, ToastContextProvider } from 'uie/components';
import { Component } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { ReactQueryDevtools } from 'react-query/devtools';

import Init from './init';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

class View extends Component {
  render() {
    return (
      <ThemeProvider theme={{ ...Theme.theme, revision: 0 }}>
        <ToastContextProvider>
          <SnackContextProvider>
            <QueryClientProvider client={queryClient}>
              <Init {...({} as any)} />
              <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            </QueryClientProvider>
          </SnackContextProvider>
        </ToastContextProvider>
      </ThemeProvider>
    );
  }
}

export default View;
