import { useRef } from 'react';
import { Box, Icon as ChakraIcon, Text, Tooltip, useDisclosure, VStack } from '@chakra-ui/react';
import { getAlertSource } from '../../helpers/helper.alert-source';
import AlertSourceDrawer from './AlertSourceDrawer';

interface Props {
  short_name?: string;
  id: string;
  type: string;
  version: string;
  supportDoc: string;
  shortName?: string;
  _id?: string;
  name: string;
  selected?: string[];
  api_key: string | undefined;
  title?: string;
  serviceId: string | undefined;
  addAlertSource?: (id: string) => void;
  email?: string;
  refetchKey: () => void;
}

export default function AlertSource(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef: any = useRef();
  const image = getAlertSource(props?.shortName).image;

  return (
    <Box borderWidth="0.2px" pt={5} boxShadow="md" rounded="md" _hover={{ boxShadow: 'xl' }}>
      <VStack ref={btnRef} onClick={onOpen} id={props?.id} shadow="">
        <ChakraIcon aria-label={props.shortName} as={image} height={8} width={'auto'} />
        <Tooltip
          label={props?.name}
          hasArrow
          bgColor="white"
          color="gray.700"
          fontWeight={400}
          size="xs"
        >
          <Text
            fontSize="14px"
            textAlign="center"
            maxWidth="100%"
            isTruncated={true}
            noOfLines={1}
            color="blackAlpha.700"
            whiteSpace="break-spaces"
            fontWeight="normal"
            px={2}
          >
            {props?.name}
          </Text>
        </Tooltip>

        <Text color="#38A169" fontSize="sm" textAlign="left" minHeight="26px">
          {props?.selected?.includes(props?.id) ? 'Added' : ''}
        </Text>
      </VStack>

      <AlertSourceDrawer
        name={props?.name}
        shortName={props?.shortName ?? props?.short_name}
        supportDoc={props?.supportDoc}
        type={props?.type}
        id={props?.id}
        version={props?.version}
        api_key={props?.api_key}
        serviceId={props?.serviceId}
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
        addAlertSourcefunc={props.addAlertSource}
        selected={props?.selected}
        image={image}
        email={props.email}
        refetch_API_key={props.refetchKey}
      />
    </Box>
  );
}
