import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1313_184)">
      <path fill="#42454B" d="M16.182 9.338l.004-.035-.002.035h-.002z"></path>
      <path
        fill="#CB2034"
        d="M13.086 8.56c-.343-.506-1.15-1.52-2.42-2.107-1.46-.673-2.431-.843-4.154-.628C1.738 6.42 1.058 10.662 1 11.099c.022-.354.142-2.02.498-2.832.398-.911 1.293-3.613 5.227-3.828 4.86-.268 6.337 4.05 6.36 4.12z"
      ></path>
      <path
        fill="#069B4F"
        d="M1.265 6.433S3.578 2.768 7.86 3.55c4.284.781 6.215 3.966 6.723 6.672 0 0 .444-2.22-.775-4.38-1.201-2.128-3.577-3.586-6.07-3.586-4.608 0-6.474 4.18-6.474 4.18v-.002z"
      ></path>
      <path
        fill="#0879B8"
        d="M6.21 1.549s5.944-1.145 8.65 5.416c1.926 4.666-.665 8.277-.665 8.277s2.56-1.928 2.56-6.672c0-4.743-4.528-8.033-8.407-7.517-1.877.25-2.138.496-2.138.496z"
      ></path>
      <path
        fill="#FFCE26"
        d="M18.658 12.565c-1.598 6.519-8.5 6.435-8.5 6.435s6.259-.907 7.193-8.403c.23-1.842.184-5.587-3.811-8.893 3.692 1.704 6.456 5.405 5.118 10.861z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_1313_184">
        <path fill="#fff" d="M0 0H18V18H0z" transform="translate(1 1)"></path>
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
