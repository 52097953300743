import { useState, useCallback } from 'react';
import { getCurrentDateTime } from '../common/util';

const useLastUpdatedDateTime = () => {
  const [lastUpdatedDateTime, setLastUpdatedDateTime] = useState(getCurrentDateTime());

  const updateTime = useCallback(() => {
    setLastUpdatedDateTime(getCurrentDateTime());
  }, []);

  return { lastUpdatedDateTime, updateTime };
};
export default useLastUpdatedDateTime;
