import {
  REQUEST_USER_INFO,
  REQUEST_USER_INFO_SUCCESS,
  REQUEST_USER_INFO_FAIL,
} from '../../const/auth';
import { INITIAL_STATE } from '../state';
import { DEFAULT_ERROR } from '../../const/defaults';
import {
  REQUEST_ACCOUNT_PROFILE_UPDATE,
  REQUEST_ACCOUNT_PROFILE_UPDATE_SUCCESS,
  REQUEST_ACCOUNT_PROFILE_UPDATE_FAIL,
  REQUEST_ACCOUNT_NOTIFICATION_UPDATE,
  REQUEST_ACCOUNT_NOTIFICATION_UPDATE_SUCCESS,
  REQUEST_ACCOUNT_NOTIFICATION_UPDATE_FAIL,
  REQUEST_ACCOUNT_TIMEZONE_UPDATE,
} from '../../const/account';
import { IAccountUserUpdateRequest } from '../../interfaces/IAccount';
import { IUserNotificationRule } from '../../interfaces/IUserData';
import { RESET_STATE } from '../../const/init';

const initialUserInfo = INITIAL_STATE.userInfo;

const userInfo = (state = initialUserInfo, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ action: action.type, d: null, error: { ...DEFAULT_ERROR } },
      };
    case REQUEST_USER_INFO:
      return {
        ...state,
        ...{ action: action.type, d: state.d, error: { ...DEFAULT_ERROR } },
      };
    case REQUEST_USER_INFO_SUCCESS:
      return {
        ...state,
        ...{ action: action.type, d: action.payload.userInfo, error: { ...DEFAULT_ERROR } },
      };
    case REQUEST_USER_INFO_FAIL:
      return {
        ...state,
        ...{
          action: action.type,
          d: null,
          error: { type: REQUEST_USER_INFO_FAIL, reason: action.payload },
        },
      };
    case REQUEST_ACCOUNT_PROFILE_UPDATE:
      return {
        ...state,
        ...{ action: action.type, d: state.d, error: state.error },
      };
    case REQUEST_ACCOUNT_PROFILE_UPDATE_SUCCESS:
      const { userUpdate: newUpdate }: { userUpdate: IAccountUserUpdateRequest } = action.payload;
      return {
        ...state,
        ...{ action: action.type, d: { ...state.d, ...newUpdate }, error: { ...DEFAULT_ERROR } },
      };
    case REQUEST_ACCOUNT_PROFILE_UPDATE_FAIL:
      return {
        ...state,
        ...{
          action: action.type,
          d: null,
          error: { type: REQUEST_ACCOUNT_PROFILE_UPDATE_FAIL, reason: action.payload },
        },
      };
    case REQUEST_ACCOUNT_NOTIFICATION_UPDATE:
      return {
        ...state,
        ...{ action: action.type, d: state.d, error: state.error },
      };
    case REQUEST_ACCOUNT_NOTIFICATION_UPDATE_SUCCESS:
      const { notificationRules }: { notificationRules: IUserNotificationRule[] } = action.payload;
      return {
        ...state,
        ...{
          action: action.type,
          d: { ...state.d, ...notificationRules },
          error: { ...DEFAULT_ERROR },
        },
      };
    case REQUEST_ACCOUNT_NOTIFICATION_UPDATE_FAIL:
      return {
        ...state,
        ...{
          action: action.type,
          d: null,
          error: { type: REQUEST_ACCOUNT_NOTIFICATION_UPDATE_FAIL, reason: action.payload },
        },
      };
    case REQUEST_ACCOUNT_TIMEZONE_UPDATE:
      const { timeZone }: { timeZone: string } = action.payload;
      return {
        ...state,
        ...{
          action: action.type,
          d: { ...state.d, timeZone, time_zone: timeZone },
          error: state.error,
        },
      };
    default:
      return state;
  }
};

export default userInfo;
