import { DialogModalFrame, Grid, IconButton, Para, TextButton, Theme } from 'uie/components';
import useGetAllServices from 'core/hooks/useGetAllServices';
import { IAppState } from 'core/interfaces/IAppState';
import { CloseIcon } from 'icons';
import React, { useState } from 'react';
import { CompareFilters } from '../types';
import FilterContainer from './filters/filterContainer';
import { connect } from 'react-redux';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_ANALYTICS_COMPARE_USED } from 'core/const/tracker';
import { PRIORITY_TYPE } from 'library/types';

const { theme } = Theme;

interface Props extends Pick<IAppState, 'organization'> {
  serviceId?: string;
  teamId: string;
  compareFilter: CompareFilters[];
  isDisabled: boolean;
  updateCompareFilter: (data: CompareFilters[]) => void;
}

const CompareFilter: React.FC<Props> = ({ ...props }) => {
  const [compareFilters, setCompareFilters] = React.useState<CompareFilters[]>(props.compareFilter);
  const [isOpen, setIsOpen] = useState(false);
  const [isAddFilter, setIsAddFilter] = useState(false);
  const servicesList = useGetAllServices(props.teamId);

  const addCompareData = (service: string, tag: string, user: string, priority: PRIORITY_TYPE) => {
    const newData = [...compareFilters];
    newData.push({
      service: service,
      tag: tag,
      user: user,
      priority: priority,
    });
    setCompareFilters(newData);
    setIsAddFilter(false);
  };

  const removeCompareData = (id: number) => {
    const newData = [...compareFilters];
    newData.splice(id, 1);
    setCompareFilters(newData);
  };

  const updateCompareFilter = () => {
    props.updateCompareFilter(compareFilters);
    setIsOpen(false);
    AppTracker.track(T_WA_UP_ANALYTICS_COMPARE_USED);
  };

  const serviceName = (service: string) => {
    return servicesList.find(s => s.id === service)?.name;
  };

  const userName = (user: string) => {
    const users = props.organization.users.u.find(u => u.id === user);
    if (users) return users.first_name + users.last_name;
    return '';
  };

  return (
    <>
      <TextButton
        color={theme.primary.default}
        buttonType="inverted"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        disabled={props.isDisabled}
      >
        <Para color={theme.primary.default}>Compare</Para>
      </TextButton>
      <DialogModalFrame
        root="compare-analytics"
        id="modalFrame"
        onClose={() => {
          setIsOpen(!isOpen);
          setIsAddFilter(false);
          setCompareFilters(props.compareFilter);
        }}
        padding="16px"
        width="404px"
        style={{ minHeight: '600px' }}
      >
        {isOpen && (
          <div style={{ minHeight: '600px', overflow: 'hidden' }}>
            <Grid
              width={'400px'}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0 0 12px 20px',
                }}
              >
                <Para
                  fontWeight={500}
                  fontSize={13}
                  style={{ lineHeight: '20px' }}
                  color={theme.shades.grey}
                >
                  Compare MTTA / MTTR of
                </Para>
              </Grid>
              {!compareFilters.length ? (
                <Grid
                  style={{
                    flex: 1,
                    marginBottom: '10px',
                    justifyContent: 'center',
                  }}
                >
                  <Para>No filters applied!</Para>
                </Grid>
              ) : (
                compareFilters.map((compare, id) => {
                  return (
                    <Grid
                      key={id}
                      style={{
                        flex: 1,
                        backgroundColor: 'ghostwhite',
                        marginBottom: '10px',
                      }}
                    >
                      <Grid
                        flexWidth={12}
                        style={{
                          height: '100px',
                          textAlign: 'center',
                          alignItems: 'center',
                          paddingLeft: '15px',
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <div style={{ marginRight: '15px' }}>
                            <Para>{id + 1}</Para>
                          </div>
                          <div>
                            <Para>
                              Incidents in Service:
                              <span style={{ fontWeight: 700 }}>
                                {' '}
                                {serviceName(compare.service)}
                              </span>
                            </Para>
                            <Para>
                              Tag: <span style={{ fontWeight: 700 }}> {compare.tag}</span>
                            </Para>
                            <Para>
                              User:
                              <span style={{ fontWeight: 700 }}> {userName(compare.user)}</span>
                            </Para>
                            <Para>
                              Priority:
                              <span style={{ fontWeight: 700 }}> {compare.priority}</span>
                            </Para>
                          </div>
                        </div>
                      </Grid>
                      <div style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                        <IconButton
                          base="24px"
                          onClick={() => {
                            removeCompareData(id);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </Grid>
                  );
                })
              )}
              {isAddFilter && (
                <Grid style={{ marginTop: '10px' }}>
                  <FilterContainer
                    teamId={props.teamId}
                    servicesList={servicesList}
                    addToCompareData={addCompareData}
                    closeContainerDropdown={() => {
                      setIsAddFilter(!isAddFilter);
                    }}
                  />
                </Grid>
              )}
              {!isAddFilter && compareFilters.length < 5 && (
                <Grid style={{ padding: '10px' }}>
                  <TextButton buttonType="ghost" onClick={() => setIsAddFilter(!isAddFilter)}>
                    <Para color={theme.primary.default}>
                      {compareFilters.length === 0 ? 'Add' : 'Add another'}
                    </Para>
                  </TextButton>
                </Grid>
              )}
            </Grid>
            {!isAddFilter && (
              <TextButton
                buttonType="ghost"
                style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '200px',
                  marginBottom: '10px',
                  marginTop: '15px',
                }}
                onClick={updateCompareFilter}
              >
                <Para>Done</Para>
              </TextButton>
            )}
          </div>
        )}
      </DialogModalFrame>
    </>
  );
};

export default connect(({ organization }: IAppState) => ({
  organization,
}))(CompareFilter);
