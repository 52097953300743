import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Divider: ComponentStyleConfig = {
  variants: {
    smokeShaded: {
      height: 0.5,
      backgroundColor: 'shades.smoke',
    },
  },
};
