import { Participant } from 'views/main/organization/schedules/graphql/types';
import {
  GetOverrideParticipantsQuery,
  GetOverrideQuery,
  GetSchedulesQuery,
} from 'views/main/organization/schedules/graphql/query';
import qs from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { IRotationDetail, IRotationEventParticipants } from '../interface/schedule';
import { getTZWTimeISO, getUTCDateTime } from './helpers.date';
import { RotationViewType } from '../constants/schedules.rotation-type';
import { DateTime } from 'luxon';

interface IMountOverride {
  pathname: string;
  search: string;
  overrideStartDate?: string;
  overrideEndDate?: string;
  overrideParticipants: string[];
  overrideStartTime?: string;
  overrideEndTime?: string;
}

type IMountOverrideRouteByEvent = IMountOverride & {
  overrideRotation: string;
  overrideId?: undefined;
};

type IMountOverrideRouteById = IMountOverride & {
  overrideRotation?: undefined;
  overrideId: string;
};

export type IMountOverrideRoute = IMountOverrideRouteByEvent | IMountOverrideRouteById;
type IMountRemoveOverrideRoute = {
  pathname: string;
  search: string;
  removeOverrideId: string;
  removeOverrideRotation: string;
};

const overrideModalKey = 'override';
const overrideIdKey = 'override-event-id';
const overrideRotationKey = 'override-rotation';
const overrideParticipantsKey = 'override-participant';
const overrideStartDateKey = 'override-start-date';
const overrideEndDateKey = 'override-end-date';
const overrideStartKey = 'override-start-time';
const overrideEndKey = 'override-end-time';

const removeOverrideModalKey = 'remove-override';
const removeOverrideIdKey = 'remove-override-event-id';
const removeOverrideRotationKey = 'remove-override-rotation';

const useGetOverrideParams = () => {
  const { search } = useLocation();
  const query = useMemo(() => qs.parse(search, { arrayFormat: 'comma' }), [search]);

  return {
    overrideModal: query[overrideModalKey] as unknown as boolean,
    overrideEventId: query[overrideIdKey] as unknown as string,
    overrideRotation: query[overrideRotationKey] as unknown as string,
    overrideParticipant: query[overrideParticipantsKey] as unknown as string[],
    overrideStartDate: query[overrideStartDateKey] as unknown as string,
    overrideEndDate: query[overrideEndDateKey] as unknown as string,
    overrideStartTime: query[overrideStartKey] as unknown as string,
    overrideEndTime: query[overrideEndKey] as unknown as string,
    removeOverrideModalOpen: !!query[removeOverrideModalKey] as unknown as boolean,
    removeOverrideId: query[removeOverrideIdKey] as unknown as string,
    removeOverrideRotation: query[removeOverrideRotationKey] as unknown as string,
  };
};

const mountOverrideRoute = ({
  pathname,
  search,
  overrideId,
  overrideRotation,
  overrideParticipants,
  overrideStartDate,
  overrideEndDate,
  overrideStartTime,
  overrideEndTime,
}: IMountOverrideRoute) => {
  return {
    pathname: pathname,
    search: qs.stringifyUrl(
      {
        url: search,
        query: {
          [overrideModalKey]: true,
          ...(overrideId
            ? { [overrideIdKey]: overrideId, [overrideParticipantsKey]: overrideParticipants }
            : {
                [overrideRotationKey]: overrideRotation,
                [overrideStartDateKey]: overrideStartDate,
                [overrideEndDateKey]: overrideEndDate,
                [overrideParticipantsKey]: overrideParticipants,
                [overrideStartKey]: overrideStartTime,
                [overrideEndKey]: overrideEndTime,
              }),
        },
      },
      {
        arrayFormat: 'comma',
      },
    ),
  };
};

const unmountOverrideRoute = (pathname: string, search: string) => {
  return {
    pathname: pathname,
    search: qs.exclude(search, [
      overrideModalKey,
      overrideRotationKey,
      overrideIdKey,
      overrideStartDateKey,
      overrideEndDateKey,
      overrideStartKey,
      overrideEndKey,
      overrideParticipantsKey,
    ]),
  };
};

const mountRemoveOverrideRoute = ({
  pathname,
  search,
  removeOverrideId,
  removeOverrideRotation,
}: IMountRemoveOverrideRoute) => {
  return {
    pathname: pathname,
    search: qs.stringifyUrl({
      url: search,
      query: {
        [removeOverrideModalKey]: true,
        [removeOverrideIdKey]: removeOverrideId,
        [removeOverrideRotationKey]: removeOverrideRotation,
      },
    }),
  };
};

const unmountRemoveOverrideRoute = (pathname: string, search: string) => {
  return {
    pathname: pathname,
    search: qs.exclude(search, [
      removeOverrideModalKey,
      removeOverrideIdKey,
      removeOverrideRotationKey,
    ]),
  };
};

const removeDuplicateParticipants = (scheduleParticipants: IRotationEventParticipants) => {
  return scheduleParticipants?.reduce((acc, participant) => {
    if (!acc?.find(p => p.ID === participant.ID)) {
      acc = [...(acc ?? []), participant];
    }
    return acc;
  }, [] as IRotationEventParticipants);
};

type TGetOverrideDetail = {
  overrideInfo: GetOverrideQuery | undefined;
  overrideModal: boolean;
  schedules: GetSchedulesQuery['schedules'];
  overrideEventId: string | null;
  overrideRotation: string | null;
  overrideParticipant: string[];
  overrideStartDate?: string;
  overrideEndDate?: string;
  overrideStartTime?: string;
  overrideEndTime?: string;
};
const getOverrideDetail = ({
  overrideModal,
  overrideEventId,
  overrideInfo,
  schedules,
  overrideRotation,
  overrideParticipant,
  overrideStartDate,
  overrideEndDate,
  overrideStartTime,
  overrideEndTime,
}: TGetOverrideDetail) => {
  if (overrideModal) {
    let overrideScheduleName = '';
    let overrideSchedule: number | null = null;
    let timeZone = '';

    if (overrideEventId && overrideInfo) {
      const eventInfo = overrideInfo.override;
      const overrideScheduleInfo = schedules?.find(s => s.ID === eventInfo?.scheduleID);
      const scheduleParticipants = removeDuplicateParticipants(
        (overrideScheduleInfo?.rotations?.flatMap(
          r => r.participantGroups?.flatMap(pg => pg.participants) || [],
        ) || []) as IRotationEventParticipants,
      );

      overrideScheduleName = overrideScheduleInfo?.name ?? '';
      timeZone = overrideScheduleInfo?.timeZone ?? '';
      overrideSchedule = eventInfo?.scheduleID || null;

      return {
        scheduleId: overrideSchedule,
        startTime: eventInfo?.startTime,
        endTime: eventInfo?.endTime,
        reason: eventInfo?.reason ?? '',
        overriddenParticipant: eventInfo?.overriddenParticipant?.participants ?? [],
        overrideWith: eventInfo?.overrideWith?.participants as IRotationEventParticipants,
        scheduleParticipants: scheduleParticipants,
        scheduleName: overrideScheduleName,
        timeZone,
      };
    }

    if (overrideRotation) {
      const overrideScheduleInfo = schedules?.find(
        s => !!s?.rotations?.find(r => r.ID === Number(overrideRotation)),
      );
      const scheduleParticipants = removeDuplicateParticipants(
        (overrideScheduleInfo?.rotations?.flatMap(
          r => r.participantGroups?.flatMap(pg => pg.participants) || [],
        ) || []) as IRotationEventParticipants,
      );
      const overriddenParticipant = (scheduleParticipants as IRotationEventParticipants)?.filter(
        p => overrideParticipant.includes(p.ID),
      );
      const eventRotation = schedules?.reduce((acc, schedule) => {
        const selectedRotation =
          schedule?.rotations?.find(r => r.ID === Number(overrideRotation)) || null;

        if (selectedRotation) {
          acc = selectedRotation;
          overrideSchedule = schedule.ID;
          overrideScheduleName = schedule.name;
          timeZone = schedule.timeZone;
        }
        return acc;
      }, null as IRotationDetail[number] | null);

      if (overrideSchedule && eventRotation) {
        const startDate = getUTCDateTime(
          overrideStartDate ? overrideStartDate : DateTime.now().toISO(),
          overrideScheduleInfo?.timeZone ?? '',
        ).toJSDate();
        const endDate = getUTCDateTime(
          overrideEndDate ? overrideEndDate : DateTime.now().toISO(),
          overrideScheduleInfo?.timeZone ?? '',
        ).toJSDate();
        const [startHr, startMin] = overrideStartTime?.split(':') ?? ['', ''];
        const [endHr, endMin] = overrideEndTime?.split(':') ?? ['', ''];
        return {
          scheduleId: overrideSchedule,
          startTime: getTZWTimeISO(
            overrideScheduleInfo?.timeZone ?? '',
            startDate,
            startHr ? Number.parseInt(startHr) : 0,
            startMin ? Number.parseInt(startMin) : 0,
          ),
          endTime: getTZWTimeISO(
            overrideScheduleInfo?.timeZone ?? '',
            endDate,
            endHr ? Number.parseInt(endHr) : 0,
            endMin ? Number.parseInt(endMin) : 0,
          ),
          reason: '',
          overriddenParticipant,
          overrideWith: [],
          scheduleParticipants: scheduleParticipants,
          scheduleName: overrideScheduleName,
          timeZone,
        };
      }
      return null;
    }
    return null;
  }
  return null;
};

const getOverrideInformation = (
  participant: Pick<Participant, 'ID' | 'type'>,
  overrides: NonNullable<GetOverrideParticipantsQuery['schedule']>['overrides'],
) => {
  let isParticipantFromOverride = false;
  let isParticipantOverriden = false;
  let overrideInfo: any = {};

  // Check if the participant has been overriden
  overrides?.forEach(o => {
    (o.overrideWith?.participants as IRotationEventParticipants)?.forEach(pt => {
      if (pt.ID === participant.ID) {
        isParticipantFromOverride = true;
        overrideInfo = { ...o };
        return;
      }
    });
    (o.overriddenParticipant?.participants as IRotationEventParticipants)?.forEach(pt => {
      if (pt.ID === participant.ID) {
        isParticipantOverriden = true;
        overrideInfo = { ...o };
        return;
      }
    });
  });

  return { isParticipantFromOverride, isParticipantOverriden, overrideInfo };
};

const includeOverridesInTheView = (
  isScheduleDetailsView: boolean,
  rotationViewType: RotationViewType,
) => {
  return isScheduleDetailsView
    ? [
        RotationViewType.onlyOverrides,
        RotationViewType.finalRotation,
        RotationViewType.defaultRotation,
      ].includes(rotationViewType)
    : true;
};

export {
  mountOverrideRoute,
  unmountOverrideRoute,
  useGetOverrideParams,
  mountRemoveOverrideRoute,
  unmountRemoveOverrideRoute,
  getOverrideDetail,
  getOverrideInformation,
  includeOverridesInTheView,
};
