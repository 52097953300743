import { FieldValues, Path, PathValue, UseFormWatch } from 'react-hook-form';
import { MultiValue, SingleValue } from 'react-select';
import { OperationOption, OperatorTypes } from '../../../types';

function extractWordAfterUnderscore(str: string) {
  if (str) {
    if (str.startsWith('alert_source_')) {
      const index = str.indexOf('_', 12); // Start searching after "alert_source_"
      return index !== -1 ? str.slice(index + 1) : '';
    } else {
      const index = str.indexOf('_');
      return index !== -1 ? str.slice(index + 1) : '';
    }
  } else {
    return '';
  }
}
export const selectedOperator = <TFormType extends FieldValues>(
  filterFieldName: string,
  watch: UseFormWatch<TFormType>,
  operatorOption: OperationOption[],
) => {
  const selectedEntityOption = watch(`${filterFieldName}.type` as Path<TFormType>);
  const selectedEntity = extractWordAfterUnderscore(selectedEntityOption);
  if (selectedEntity) {
    const selectedFilter = {
      value: selectedEntity as OperatorTypes,
      label:
        (operatorOption?.find(option => option.value === (selectedEntity as string))
          ?.label as string) ?? '',
    };
    return selectedFilter;
  }
};

export const selectedFilter = <TFormType extends FieldValues>(
  filterFieldName: string,
  watch: UseFormWatch<TFormType>,
  filterOption: { label: string; value: string }[],
) => {
  const selectedEntity = watch(`${filterFieldName}.fields.value` as Path<TFormType>);
  if (selectedEntity) {
    const selectedFilter = {
      value: selectedEntity,
      label: filterOption?.find(option => option.value === selectedEntity)?.label ?? selectedEntity,
    };
    return selectedFilter;
  }
  return null;
};

export const onChangeFilter = <TFormType extends FieldValues>(
  o: any,
  filterFieldName: string,
  setValue: (name: Path<TFormType>, value: PathValue<TFormType, Path<TFormType>>) => void,
) => {
  if (o && 'value' in o) {
    setValue(
      `${filterFieldName}.fields` as Path<TFormType>,
      {
        value: o!.value,
      } as PathValue<TFormType, Path<TFormType>>,
    );
  }
};

export const onChangeOperator = <TFormType extends FieldValues>(
  op: SingleValue<OperationOption> | MultiValue<OperationOption>,
  filterFieldName: string,
  filterName: string,
  setValue: (name: Path<TFormType>, value: PathValue<TFormType, Path<TFormType>>) => void,
) => {
  if (op && 'value' in op && op.value) {
    setValue(
      `${filterFieldName}.type` as Path<TFormType>,
      `${filterName}_${op.value}` as PathValue<TFormType, Path<TFormType>>,
    );
  }
};

export const selectedActivePriority = <TFormType extends FieldValues>(
  filterFieldName: string,
  watch: UseFormWatch<TFormType>,
  filterOption: any[],
) => {
  const selectedEntity = watch(`${filterFieldName}.fields.value` as Path<TFormType>);
  if (selectedEntity) {
    const selectedFilter = filterOption?.find(
      option => option.value === (selectedEntity as string),
    );

    return selectedFilter;
  }
  return null;
};

export const selectedFilterKey = <TFormType extends FieldValues>(
  filterFieldName: string,
  watch: UseFormWatch<TFormType>,
  filterOption: any[],
) => {
  const selectedEntity = watch(`${filterFieldName}.fields.key` as Path<TFormType>);
  if (selectedEntity) {
    const selectedFilter = {
      value: selectedEntity,
      label: filterOption?.find(option => option.value === selectedEntity)?.label ?? selectedEntity,
    };
    return selectedFilter;
  }
  return null;
};
