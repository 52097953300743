import { TAlertDialogParameters } from '../helpers/helpers.schedule';

export const schedulesTextCopy = {
  common: { cancel: 'Cancel', remove: 'Remove' },
  home: {
    title: 'Schedules',
    add: 'Add Schedule',
    label: {
      search: 'Search Schedules',
      filter: 'Filter Schedules',
    },
  },
  stepper: {
    step1: 'Add Schedule Details',
    step2: 'Choose Rotation Pattern',
    step3: 'Customize Rotations',
  },
  creation: {
    title: 'Add Schedule Details',
    desc: 'Create a Schedule and add customized rotations to determine who will be on-call',
    nextChooseRotation: 'Next: Choose Rotation Template',
    addTag: 'Add Tag',
    form: {
      nameLabel: 'Schedule Name',
      timeZoneLabel: 'Time Zone',
      timeZonePlaceholder: 'Schedule Timezone',
      descriptionLabel: 'Schedule Description',
      colorpickerLabel: 'Choose Schedule Display Colour',
      owner: 'Owner',
      ownerPlaceholder: 'Type a user name',
      tagsLabel: 'Tags',
    },
  },
  update: {
    buttons: {
      save: 'Save',
    },
    title: 'Edit Schedule Details',
    desc: 'Edit the Schedule and add customized rotations or update existing ones to determine who will be on-call',
    stepper: {
      step1: 'Edit Schedule Details',
      step2: 'Add a new Rotation',
      step3: 'Customize Rotations',
    },
  },
  template: {
    title: 'Choose Rotation Pattern',
    desc: 'Pick one or more rotations that closely matches your needs.',
    note: ' Note: You can change rotation timings & add users in the next step.',
    nextCustomizeRotation: 'Next: Customize Rotation Pattern',
    accordion: 'Add one or more custom rotations or events',
    button: {
      add: 'Add',
      remove: 'Remove',
    },
  },
  customization: {
    button: {
      save: 'Save',
    },
  },
  detail: {
    remove: 'Remove',
    edit: 'Edit',
  },
  rotation: {
    remove: {
      title: 'End Rotation',
      body: 'By ending this rotation, there will be no further notifications and events generated for this rotation. Are you sure you want to continue?',
    },
  },
  override: {
    remove: {
      title: 'Remove Override',
      body: 'By removing this override, the previous participant of this rotation will be reassigned. Are you sure you want to continue?',
    },
  },
  toasts: {
    created: 'Schedule has been created!',
    paused: 'Schedule has been paused!',
    resumed: 'Schedule has been resumed!',
    cloned: 'Schedule has been cloned!',
    deleted: 'Schedule has been deleted!',
    updated: 'Schedule has been updated!',
    exported: 'Calendar link has been copied!',
    overrideCreated: 'Override created successfully!',
    overrideUpdated: 'Override updated successfully!',
    overrideDeleted: 'Override deleted successfully!',
  },
  alertDialogs: {
    pause: {
      header: 'Pause Schedule',
      body: 'Are you sure you want to pause SCHEDULENAME?',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Pause',
    },
    resume: {
      header: 'Resume Schedule',
      body: 'Are you sure you want to resume SCHEDULENAME?',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Resume',
    },
    clone: {
      header: 'Clone Schedule',
      body: 'Are you sure you want to clone SCHEDULENAME?',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Clone',
    },
    delete: {
      header: 'Delete Schedule',
      bodyEscalationsPresent:
        'Replace the schedule SCHEDULENAME in the following escalation policies before deleting',
      bodyNoEscalations:
        'The schedule SCHEDULENAME is not attached with any escalation policy. Are you sure you want to proceed?',
      dropdownPlaceholder: 'Type a username, squad or schedule',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Delete',
    },
    export: {
      header: 'Export Schedule',
      body: `Use 'SCHEDULENAME' calendar link to sync calendar with Outlook, iCal, Google calendar and other calendars`,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Copy',
    },
    deleteRotation: {
      header: 'Delete Rotation',
      bodyFirstLine: 'Are you sure you want to delete the rotation?',
      bodySecondLine:
        'If you select delete the entire rotation, you will lose all the data of previous events. It will be preserved if you choose to end the rotation. Please make an appropriate selection.',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    },
  },
};

export enum ScheduleActionTypes {
  PAUSE = 'pause',
  RESUME = 'resume',
  CLONE = 'clone',
  DELETE = 'delete',
  EXPORT = 'export',
  EDIT_SCHEDULE = 'edit_schedule',
  EDIT_ROTATIONS = 'edit_rotations',
  EDIT_PARTICIPANTS = 'edit_participants',
  REMOVE_ROTATION = 'remove_rotation',
}

export const initialAlertDialogParameters: TAlertDialogParameters = {
  title: '',
  body: '',
  confirmButtonText: '',
  cancelButtonText: '',
  onConfirm: () => Promise.resolve(),
};

export enum ActionButtonLabels {
  PAUSE = 'Pause Schedule',
  RESUME = 'Resume Schedule',
  CLONE = 'Clone Schedule',
  EXPORT = 'Export Schedule',
  DELETE = 'Delete Schedule',
  EDIT_PARTICIPANTS = 'Edit Participants',
  EDIT_ROTATIONS = 'Edit Rotations',
}
