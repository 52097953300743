import {
  Box,
  HStack,
  Text,
  VStack,
  Link as CLink,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
} from '@chakra-ui/react';
import { Locale } from 'core/helpers/dateUtils';
import { AssigneeAvatar, Tooltip, usernameTooltipLabel } from 'library/atoms';
import { Link } from 'react-router-dom';
import { Cell, Row } from 'react-table';
import { getIncidentDetailsPath } from '../../common/util';
import TagList from '../TagList';
import { IPastIncident } from 'core/interfaces/IIncidents';
import { changeTTRTimeFormat } from '../../../incidentDetails/helpers';
import { Priority } from 'library/common';
import { getAssigneeComponent, getPriorityColumnHeader, getTextComponent } from '../column-utils';
import { ASSIGNEE_TOOLTIP, COLUMN_DEF_PADDING_LEFT_VALUE } from '../../common/constants';
import { InfoFilledIcon } from 'library/icons';

export const PAST_INCIDENTS_COLUMN_DEFS = [
  {
    Header: 'INCIDENT MESSAGE',
    Cell: (cell: Cell<IPastIncident>) => {
      const { id, message, tags } = cell.row.original;
      return (
        <VStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} pb={4} alignItems="flex-start">
          <Link to={getIncidentDetailsPath(id)} target="_blank">
            <Text variant="body" noOfLines={1}>
              {message}
            </Text>
          </Link>
          {!!tags.length && <TagList tags={tags} />}
        </VStack>
      );
    },
  },
  {
    id: 'priority',
    Header: () => {
      return getPriorityColumnHeader();
    },
    Cell: (cell: Cell<IPastIncident>) => {
      const priority = cell.row.original.priority;
      return (
        <Box pl={COLUMN_DEF_PADDING_LEFT_VALUE} mt={1}>
          <Priority value={priority} />
        </Box>
      );
    },
  },
  {
    Header: 'ALERT SOURCE',
    Cell: (cell: Cell<IPastIncident>) => {
      const {
        alert_source: { name, icon },
      } = cell.row.original;
      return (
        <Box pl={COLUMN_DEF_PADDING_LEFT_VALUE}>
          <Tooltip placement="top" label={name}>
            {icon && <img src={icon.svg} style={{ maxWidth: '40px', height: '24px' }} />}
          </Tooltip>
        </Box>
      );
    },
  },
  {
    id: 'assignee',
    Header: () => {
      return (
        <HStack>
          <Text color="secondary.700">ASSIGNEE</Text>
          <Tooltip label={ASSIGNEE_TOOLTIP} placement="top">
            <Box>
              <InfoFilledIcon height="18px" width="18px" />
            </Box>
          </Tooltip>
        </HStack>
      );
    },
    Cell: (cell: Cell<IPastIncident>) => {
      const { id, type, name, username_for_display: username } = cell.row.original.last_assigned_to;
      return (
        <HStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} columnGap="4px">
          {!name ? (
            <Text variant="body" color="secondary.1000" textTransform="capitalize">
              {`deleted ${type}`}
            </Text>
          ) : (
            <Tooltip
              label={usernameTooltipLabel(name, username)}
              isDisabled={type !== 'user'}
              placement="bottom"
            >
              <HStack>
                {' '}
                <AssigneeAvatar name={name} type={type} />
                {getAssigneeComponent(id, type, name)}
              </HStack>
            </Tooltip>
          )}
        </HStack>
      );
    },
    minWidth: 120,
  },
  {
    Header: () => <Text color="secondary.700">CREATED AT</Text>,
    accessor: 'created_at',
    sortDescFirst: true,
    Cell: (cell: Cell<IPastIncident>) => {
      const timeOfCreation = cell.row.original.created_at;
      return (
        <VStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} alignItems="flex-start">
          {getTextComponent(Locale.toShortDate(timeOfCreation))}
          {getTextComponent(Locale.to24HrTimeWithSec(timeOfCreation))}
        </VStack>
      );
    },
    minWidth: 120,
  },
  {
    Header: 'RESOLUTION',
    Cell: (cell: Cell<IPastIncident>) => {
      return (
        <Box pl={COLUMN_DEF_PADDING_LEFT_VALUE}>
          {getTextComponent(cell.row.original.resolution_type)}
        </Box>
      );
    },
  },
  {
    Header: 'TTR',
    Cell: (cell: Cell<IPastIncident>) => {
      const { ttr } = cell.row.original;
      const convertedTime = changeTTRTimeFormat(ttr).trim();
      return (
        <Box pl={COLUMN_DEF_PADDING_LEFT_VALUE}>
          <HStack gap={1}>{getTextComponent(convertedTime || '0s')}</HStack>
        </Box>
      );
    },
    minWidth: 37,
  },
  {
    Header: () => (
      <HStack>
        <Popover trigger="hover">
          <Text color="secondary.700">INCIDENT ACTIVITY</Text>
          <PopoverTrigger>
            <img src="/icons/info_black.svg" alt="more info" style={{ height: 16 }} />
          </PopoverTrigger>
          <PopoverContent
            css={{
              '&:focus': {
                boxShadow: 'none',
                outline: 'none',
              },
              textTransform: 'none',
              fontSize: '12.5px',
              lineHeight: 'normal',
              fontWeight: 'normal',
            }}
          >
            <PopoverHeader>
              This is a system generated measure based on the response and resolution activities
              taken during this incident. Click{' '}
              <CLink
                as="a"
                href="https://support.squadcast.com/incidents-page/past-incidents"
                target="_blank"
                // style={{ color: '#09305A' }}
              >
                here
              </CLink>{' '}
              for a detailed understanding of this.
            </PopoverHeader>
          </PopoverContent>
        </Popover>
      </HStack>
    ),
    accessor: 'incident_activity',
    sortDescFirst: true,
    sortType: (row1: Row<Record<string, unknown>>, row2: Row<Record<string, unknown>>) => {
      // Custom activity order
      const activityOrder = {
        Low: 0,
        Medium: 1,
        High: 2,
      };

      // Compare the numeric values of 'activity' property
      return (
        activityOrder[(row1.original as unknown as IPastIncident).incident_activity] -
        activityOrder[(row2.original as unknown as IPastIncident).incident_activity]
      );
    },
    Cell: (cell: Cell<IPastIncident>) => {
      return (
        <Box pl={COLUMN_DEF_PADDING_LEFT_VALUE}>
          {getTextComponent(cell.row.original.incident_activity)}
        </Box>
      );
    },
    minWidth: 140,
  },
];
