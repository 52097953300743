import { Box, Flex, HStack, Text } from '@chakra-ui/layout';
import { API } from 'core';
import { FC, useMemo } from 'react';
import { usePageDetails } from 'views/main/organization/statuspage-v2/hooks/usePageDetails';
import { useStausPageList } from 'views/main/organization/statuspage-v2/hooks/useStatusPageList';
import { IStatusCode } from 'views/main/organization/statuspage-v2/Interface';
import { StatusPageAction } from 'views/main/organization/workflow/types';

interface IProps {
  action: StatusPageAction;
}
const StatusPageCard: FC<IProps> = ({ action }) => {
  const { details } = usePageDetails(+action.data.status_page_id);
  const { list } = useStausPageList(API.config.teamId);

  const statusPageList = useMemo(() => {
    if (!list) return [];

    return list.map(status => ({
      label: status?.name as string,
      value: status?.id,
    }));
  }, [list]);
  const selectedStatusPage = statusPageList?.find(
    status => status.value === action.data.status_page_id,
  )?.label;

  const pageStatusList = useMemo(() => {
    if (!details?.statusCodes) return [];

    return details.statusCodes.map((status: IStatusCode) => ({
      label: status?.slug as string,
      value: status?.id,
    }));
  }, [details]);

  const selectedPageStatus = pageStatusList?.find(
    ({ value }: { value: number }) => value === action.data.page_status_id,
  )?.label;

  return (
    <Box width="40dvw" py={2} gap={4}>
      <Flex gap={2}>
        <Flex alignItems="start" maxW="32dvw">
          <Text>Issue:&nbsp;</Text>
          <Text variant="h3" color="secondary.1000" textStyle="bold_800">
            {' '}
            {action.data.issue_title}
          </Text>
          &nbsp;,
        </Flex>
        <Flex alignItems="start">
          <Text> State:&nbsp;</Text>
          <Text variant="h3" color="secondary.1000" textStyle="bold_800">
            {' '}
            {selectedPageStatus ?? '-'}
          </Text>
        </Flex>
      </Flex>

      <HStack>
        <Text>Page:</Text>
        <Text> {selectedStatusPage ?? '-'}</Text>
      </HStack>
    </Box>
  );
};

export default StatusPageCard;
