import React, { Component } from 'react';
import { InputBox, TextButton } from '@squadcast/alchemy-ui/metal';
import { IStatusPage } from '../../../../../../core/interfaces/IStatusPage';
import { Row } from '@squadcast/alchemy-ui/carbon';
import { TwitterPicker } from 'react-color';
import { deepCopy, pick } from 'core/helpers';
import equal from 'fast-deep-equal/es6/react';
import { IComponentErrorState } from '../../../../../../core/interfaces/IComponentState';

interface IProps {
  hide: (isASave?: boolean, pageConfig?: any) => void;
  pageConfig: Pick<IStatusPage, 'pageConfig'>;
  checkAndSetDirty: () => void;
}

interface IState {
  pageConfig: Pick<IStatusPage, 'pageConfig'>;
  showEditConfigId: string;
  errors: IComponentErrorState;
}

class ServiceStatusModal extends Component<IProps, IState> {
  private _initialState?: IState;

  constructor(props: IProps) {
    super(props);

    this.state = {
      pageConfig: this.props.pageConfig,
      showEditConfigId: '',
      errors: {},
    };

    this._initialState = deepCopy(this.state);
  }

  public onAliasChange = (alias: string, index: number) => {
    const { pageConfig } = this.state.pageConfig;
    pageConfig.status[index].alias = alias;
    this.setState({ pageConfig: { pageConfig } });
  };

  public onColorChange = (color: string, index: number) => {
    const { pageConfig } = deepCopy(this.state.pageConfig);
    pageConfig.status[index].color = color;
    this.setState({ pageConfig: { pageConfig } });
  };
  validate = () => {
    const { pageConfig } = this.state.pageConfig;
    return pageConfig.status.reduce((errors: any, status, index) => {
      if (status.alias.trim() === '') {
        errors[`service_status_alias_${index}`] = "status alias can't be empty";
      }
      return errors;
    }, {});
  };

  onSave = () => {
    const errors = this.validate();
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.props.hide(true, this.state.pageConfig.pageConfig);
  };

  componentDidUpdate() {
    if (
      this._initialState &&
      !equal(pick(this.state, 'pageConfig'), pick(this._initialState, 'pageConfig'))
    )
      this.props.checkAndSetDirty();
  }

  public render() {
    const {
      pageConfig: { pageConfig },
      errors,
    } = this.state;

    return (
      <div
        onClick={event => {
          this.setState({ showEditConfigId: '' });
          event.stopPropagation();
        }}
      >
        <div className="">
          <div className="float-left">
            <h1 className="modal-container-heading">Customise Service Status</h1>
          </div>
        </div>
        <div className="mt-20">
          {pageConfig.status.map((status, index) => {
            return (
              <div className="mt-10" key={index}>
                <Row flexWidth={12} alignItems="center">
                  <Row flexWidth={1}>
                    <div className="float-right" style={{ position: 'relative' }}>
                      <div
                        className="color-block cursor-pointer"
                        style={{ backgroundColor: status.color as any }}
                        onClick={event => {
                          this.setState({
                            showEditConfigId: index.toString() || '',
                          });
                          event.stopPropagation();
                        }}
                      />
                      {this.state.showEditConfigId === index.toString() && (
                        <div
                          className="config-modal animated fadeIn no-pointer"
                          style={{ width: 'auto', borderRadius: 6 }}
                          onClick={event => {
                            event.stopPropagation();
                          }}
                        >
                          <TwitterPicker
                            color={status.color as string}
                            onChange={({ hex }) => {
                              this.onColorChange(hex, index);
                            }}
                            triangle="hide"
                          />
                        </div>
                      )}
                    </div>
                  </Row>
                  <Row flexWidth={5}>
                    <div>
                      <p style={{ textTransform: 'capitalize' }}>
                        {status.label === 'good'
                          ? 'Operational'
                          : status.label === 'bad'
                          ? 'Degraded'
                          : 'Under Maintenance'}
                      </p>
                    </div>
                  </Row>
                  <Row flexWidth={6}>
                    <div className="alisa w-1-1">
                      <InputBox
                        id={`service_status_alias_${index}`}
                        name="service_status_alias"
                        label=""
                        type="text"
                        placeHolder="Alias"
                        value={status.alias as string}
                        onChange={(_, value) => {
                          this.onAliasChange(value, index);
                        }}
                        error={errors[`service_status_alias_${index}`]}
                      />
                    </div>
                  </Row>
                </Row>
              </div>
            );
          })}
        </div>
        <div className="mt-20">
          <TextButton
            id="service_status_modal_save"
            onClick={this.onSave}
            size="normal"
            text="Save"
          />
        </div>
      </div>
    );
  }
}

export default ServiceStatusModal;
