import { Box, useBoolean, useDisclosure } from '@chakra-ui/react';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { API, BillingService, debounce } from 'core';
import { PageHeader } from 'library/molecules';
import { useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { GER_CREATE_PATH } from 'views/main/routes/routes';

import { useGerPlan } from '../hooks';
import { GERListing } from '../listing';
import { GERFilter } from '../listing/filter';
import { useGerFilter } from '../listing/state';
import { useGerList } from '../query/useGerList';
import { useGetOrgLvlGerCount } from '../query/useGetOrgLvlGerCount';
import { ActionKind, GERContext } from '../store';
import { ADD_NEW_RULE_TITLE } from '../util';

export const GERHome = () => {
  const history = useHistory();
  const { isOpen: isFilterOpen, onOpen: onFilterOpen, onClose: onFilterClose } = useDisclosure();
  const [showUpgradeModal, setShowUpgradeModal] = useBoolean(false);
  const { gerFilterApply } = useGerFilter();

  const { state, dispatch } = useContext(GERContext);
  const { count } = useGerList(
    API.config.teamId,
    state.pageNumber,
    state.limit,
    state.search.term,
    gerFilterApply.map(data => data.value),
  );

  const { gerOrg } = useGerPlan();
  const gerLimit = BillingService.getLimit({ organization: gerOrg }, 'ger');

  const gerExistInPlan =
    !BillingService.isFeatureDisabled({ organization: gerOrg }, 'ger') && gerLimit !== count;
  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearch(value);
    }, 1000),
  ).current;

  const setSearch = (value: string) => {
    dispatch({ type: ActionKind.SET_SEARCH_TERM, payload: { searchTerm: value } });
  };

  const addRule = () => {
    if (gerExistInPlan) {
      history.push(GER_CREATE_PATH);
    } else {
      setShowUpgradeModal.on();
    }
  };

  return (
    <>
      <Box
        bg="brand.white"
        borderRadius="4px 4px 0px 0px"
        width="100%"
        height="100%"
        overflowX="scroll"
      >
        <PageHeader
          title="Global Event Rulesets"
          button={ADD_NEW_RULE_TITLE}
          onButtonClick={addRule}
          filter={state.permissions.canRead}
          search={state.permissions.canRead}
          searchValue={state.search.term}
          onSearch={debouncedSearch}
          onFilter={onFilterOpen}
          isFilterApplied={!!gerFilterApply.length}
          buttonDisabled={!state.permissions.canCreate}
        />
        <GERFilter isOpen={isFilterOpen} onClose={onFilterClose} />
        <GERListing onCreate={addRule} />
      </Box>
      <UpgradePlanModal
        hasBillingPermission={BillingService.hasManageBillingPermission({ organization: gerOrg })}
        showModal={showUpgradeModal}
        message={BillingService.getMessage(0, 'ger', { organization: gerOrg })}
        onCancel={setShowUpgradeModal.off}
        header={BillingService.getHeader(0, 'ger', { organization: gerOrg })}
        onUpgrade={setShowUpgradeModal.off}
      />
    </>
  );
};
