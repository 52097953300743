import { IAppState } from 'core/interfaces/IAppState';
import { useSelector } from 'react-redux';

const useWorkflowPlan = () => {
  const workflowPlan = useSelector(
    (state: IAppState) => state.organization.plan.p?.rules.workflows,
  );
  const workflowActionPlan = useSelector(
    (state: IAppState) => state.organization.plan.p?.rules['workflows-actions'],
  );
  const workflowOrg = useSelector((state: IAppState) => state.organization);

  return { workflowPlan, workflowActionPlan, workflowOrg };
};

export { useWorkflowPlan };
