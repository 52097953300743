import { Circle, Flex, HStack, Text } from '@chakra-ui/layout';
import SQTooltip from 'components/chakra/Tooltip';
import { API } from 'core';
import { AssigneeType } from 'library/enums';
import { useState, useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { truncate } from 'views/main/organization/incident-list/common/util';
import { hasReadAccessToEntity } from 'views/main/organization/navigation-flows/helpers';
import {
  ESCALATION_POLICIES_LIST_PATH,
  TEAM_SQUADS_PATH,
  USER_PROFILE_PATH,
} from 'views/main/routes/routes';
import {
  useGetAllPatternParticipants,
  IPatternParticipant,
} from '../../../helpers/helpers.customrotations';

const getNameComponent = (
  name: string,
  maxLength = 10,
  type: AssigneeType,
  displayName: string,
) => {
  const label = (
    <HStack
      px="1.5"
      py="0.5"
      borderRadius="3px"
      bgColor="#F1F3F6"
      alignItems="center"
      overflow="hidden"
      maxWidth="152px"
      textOverflow="ellipsis"
    >
      <Text
        fontSize="12px"
        fontWeight="700"
        color="#627C98"
        textOverflow="ellipsis"
        style={{ whiteSpace: 'nowrap' }}
      >
        {truncate(name, maxLength)}
      </Text>
      {displayName.length && (
        <Text display="inline-block" ml="1px" color="#627C98" fontSize="10px" fontWeight="400">
          ({truncate(displayName, maxLength)})
        </Text>
      )}
    </HStack>
  );
  return name.length > maxLength ? (
    <SQTooltip text={name} placement="bottom">
      {label}
    </SQTooltip>
  ) : (
    label
  );
};

const getAssigneeComponent = (
  id: string,
  type: AssigneeType,
  name: string,
  displayName: string,
) => {
  let assigneeLink = '';

  switch (type) {
    case AssigneeType.User:
      assigneeLink = generatePath(USER_PROFILE_PATH, { id });
      break;
    case AssigneeType.Squad:
      assigneeLink = generatePath(TEAM_SQUADS_PATH, { id: API.config.teamId, sqid: id });
      break;
    case AssigneeType.Escalationpolicy:
      assigneeLink = generatePath(ESCALATION_POLICIES_LIST_PATH, { id });
  }

  const nameComp = getNameComponent(name, 9, type, displayName);

  return hasReadAccessToEntity(type) ? <Link to={assigneeLink}>{nameComp}</Link> : nameComp;
};

type TParticipant =
  | {
      firstName: string;
      lastName: string;
      usernameForDisplay?: string;
    }
  | {
      name: string;
    };

export const Participants = ({
  participants,
  textColor,
}: {
  participants: Array<{
    ID: string;
    type: AssigneeType;
    participant: TParticipant;
  }>;
  textColor?: string;
}) => {
  const [showAll, setShowAll] = useState(false);

  const displayedParticipants = useMemo(
    () => (showAll ? participants : participants.slice(0, 1)),
    [participants, showAll],
  );

  const allParticipants = useGetAllPatternParticipants();

  const renderParticipant = ({
    participant,
    ID,
    type,
  }: {
    participant: TParticipant;
    ID: string;
    type: AssigneeType;
  }) => {
    if (!participant) return null;

    let name = '';
    let displayName = '';

    const isParticipantDeleted = !allParticipants.some((p: IPatternParticipant) => p.id === ID);

    if (isParticipantDeleted) {
      return (
        <Text
          fontSize="12px"
          fontWeight="700"
          color={'#a8a8a8'}
          textOverflow="ellipsis"
          textTransform="capitalize"
          style={{ whiteSpace: 'nowrap' }}
        >
          Deleted {type}
        </Text>
      );
    }

    if ('firstName' in participant) {
      name = `${participant.firstName} ${participant.lastName}`;
      displayName = participant.usernameForDisplay ?? '';
    } else {
      name = participant.name;
    }

    return <HStack key={ID}>{getAssigneeComponent(ID, type, name, displayName)}</HStack>;
  };

  const showMoreButton = useMemo(
    () => participants.length > 1 && !showAll,
    [participants, showAll],
  );

  if (!participants || !participants.length) {
    return null;
  }

  return (
    <Flex
      gap="4px"
      ml="1"
      alignItems={showAll ? 'flex-start' : 'center'}
      width="100%"
      flexWrap="wrap"
      maxWidth="172"
    >
      {displayedParticipants.map(renderParticipant)}
      {showMoreButton && (
        <Circle onClick={() => setShowAll(!showAll)} size="4" cursor="pointer" bgColor="#F1F3F6">
          <Text color={textColor ?? 'inherit'} variant="body">{`+${participants.length - 1}`}</Text>
        </Circle>
      )}
    </Flex>
  );
};
