export const INCIDENT_FILTER_OBJ = 'SQ_FILTER_FORM_OBJ';

export const setIncidentFilterObj = (key: string, value: any) => {
  const obj = getIncidentFilterObj();
  obj[key] = value;

  sessionStorage.setItem(INCIDENT_FILTER_OBJ, JSON.stringify(obj));
};

export const setIncidentFilterCompleteObj = (obj: string) => {
  sessionStorage.setItem(INCIDENT_FILTER_OBJ, JSON.stringify(obj));
};

export const getIncidentFilterObj = () => {
  const str = sessionStorage.getItem(INCIDENT_FILTER_OBJ);
  return str ? JSON.parse(str) : null;
};

export const clearIncidentFilterObj = () => {
  sessionStorage.removeItem(INCIDENT_FILTER_OBJ);
};
