import { VStack } from '@chakra-ui/layout';
import { Text } from 'library/atoms/Text';
import { IKeypadMapping } from 'views/main/organization/lcr/common/interface';
import ActionCard from './ActionCard';
const KeypadAction = ({ data }: { data: IKeypadMapping[] }) => {
  return (
    <VStack align="stretch" px={6} py={4} bg="primary.200" gap={2}>
      <Text variant="h2_800" color="secondary.1000">
        Keys
      </Text>
      {data.map((keymappingData, index) => {
        return <ActionCard key={index} actions={keymappingData} />;
      })}
    </VStack>
  );
};

export default KeypadAction;
