import { Text, VStack } from '@chakra-ui/react';
import { FormButton } from 'library/atoms';
import { Modal } from 'library/molecules';
import { FC } from 'react';

type Props = {
  onClose: () => void;
  onVoteDown: () => void;
  isOpen: boolean;
  isVoting: boolean;
};
export const VoteDownAlertFC: FC<Props> = ({ onClose, isOpen, onVoteDown, isVoting }) => {
  return (
    <Modal
      key="vote-down-alert"
      body={
        <VStack rowGap={1} alignItems="flex-start">
          <Text variant="formInput" color="secondary.700">
            1. This action cannot be undone later.
            <br />
            2. Once voted down, you can create a manual incident if you wish
          </Text>
        </VStack>
      }
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={
        <FormButton title="Vote Down" onClick={onVoteDown} isLoading={isVoting} variant="danger" />
      }
      secondaryButton={
        <FormButton title="Cancel" onClick={onClose} disabled={isVoting} variant="secondary" />
      }
      title="Are you sure you want to vote down?"
    />
  );
};
