import { Button, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import useQueryParams from 'core/hooks/useQueryParams';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { CollapsedIcon, ExpandIcon } from 'icons';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import moment from 'moment';
import React, { ChangeEvent, FC, Fragment, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Cell, Column, UseRowSelectInstanceProps } from 'react-table';
import { Header, Loader } from '../../components';
import DataTable from '../../components/Table';
import { IServiceList, SeviceObj } from '../../hooks/useServiceList';
import { Checkbox } from './checkbox';
import { ServiceDetail, tableColumns } from './columns';
import qs from 'query-string';

interface ServiceListProps {
  serviceListData: IServiceList | undefined;
  pageChange: (queryPageIndex: number) => void;
  pageSizeChange: (queryPageSize: number) => void;
  queryPageIndex: number;
  queryPageSize: number;
  setCheckedRows: (checkedRows: Array<string>) => void;
  checkedRows: Array<string> | null;
  sorting: { sort_by: string; sort_order: 'asc' | 'des' | string };
  isLoadingIncidents: boolean;
  lastUpdate: string | null;
}

const ServiceList = React.memo(
  ({
    serviceListData,
    pageChange,
    pageSizeChange,
    queryPageIndex,
    queryPageSize,
    setCheckedRows,
    checkedRows,
    sorting,
    isLoadingIncidents,
    lastUpdate,
  }: ServiceListProps) => {
    const [allExpanded, setAllExpanded] = useState(false);
    const history = useHistory();

    const serviceData = useMemo(
      () => (serviceListData ? serviceListData.data.services || [] : []) as Array<SeviceObj>,
      [serviceListData],
    );

    const HeaderCheck = ({
      getToggleAllRowsSelectedProps,
    }: UseRowSelectInstanceProps<ServiceDetail>) => {
      const { onChange, ...args } = getToggleAllRowsSelectedProps();
      const isAllChecked = serviceData.length === checkedRows?.length;
      const onSelectAllRows = (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);
        const allServices = serviceData.map((row: SeviceObj) => row.id);
        isAllChecked ? setCheckedRows([]) : setCheckedRows(allServices);
      };

      return <Checkbox {...args} checked={isAllChecked} onChange={onSelectAllRows} />;
    };

    const CellCheck = ({ row }: Cell<ServiceDetail>) => {
      const { onChange, ...args } = row.getToggleRowSelectedProps();
      const isChecked = checkedRows ? !!checkedRows.find(id => id === row.original.id) : false;
      const onSelectRow = (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);
        if (!isChecked) {
          setCheckedRows([...(checkedRows ? checkedRows : []), row.original.id]);
        } else {
          const removeUnchecked = checkedRows
            ? checkedRows.filter(checkedRow => checkedRow !== row.original.id)
            : [];
          setCheckedRows(removeUnchecked);
        }
      };

      return <Checkbox {...args} checked={isChecked} onChange={onSelectRow} />;
    };

    const columns = useMemo<Column<any>[]>(
      () => [
        {
          id: 'select-services',
          Header: HeaderCheck,
          Cell: CellCheck,
        },
        ...tableColumns.map(col => {
          if (col.id === 'open_incidents') {
            const colCell = tableColumns.find(col => col.id === 'open_incidents')?.Cell;

            if (isLoadingIncidents) {
              return {
                ...col,
                Cell: () => <Loader.Spinner />,
              };
            } else {
              return {
                ...col,
                Cell: colCell ? colCell : () => '',
              };
            }
          }
          return col;
        }),
      ],
      [isLoadingIncidents],
    );

    const initExpandAllRows = (expandedRows: boolean) => () => {
      setAllExpanded(expandedRows);
    };

    const ServiceAction = ({ rowData }: { rowData: any }) => {
      const serviceID = rowData?.row?.original?.id || null;

      const queryParams = qs.parse(history.location.search);

      const serviceACL = useUserAccess();
      const hasUpdateAccess = serviceACL.hasUpdateAccess;

      const hasUpdate = hasUpdateAccess('services', serviceID);

      return (
        <Flex
          gap={3}
          alignSelf="center"
          boxShadow="-10px 0px 10px 1px var(--chakra-colors-gray-100)"
        >
          <NoPermissionTooltip isDisabled={hasUpdate}>
            <Button
              onClick={() => {
                const routeConfig = {
                  pathname: history.location.pathname,
                  search: qs.stringifyUrl({
                    url: history.location.search,
                    query: {
                      ...queryParams,
                      serviceID: serviceID,
                      actionType: 'MAINTENANCE',
                    },
                  }),
                };

                history.push(routeConfig);
              }}
              isDisabled={!hasUpdate}
            >
              Maintenance Mode
            </Button>
          </NoPermissionTooltip>
          <NoPermissionTooltip isDisabled={hasUpdate}>
            <Button
              onClick={() => {
                const routeConfig = {
                  pathname: history.location.pathname,
                  search: qs.stringifyUrl({
                    url: history.location.search,
                    query: {
                      ...queryParams,
                      serviceID: serviceID,
                      actionType: 'TAGS',
                    },
                  }),
                };

                history.push(routeConfig);
              }}
              variant={'outline'}
              isDisabled={!hasUpdate}
            >
              {rowData?.row?.original?.tags?.length ? 'Update Tag(s)' : 'Add Tag(s)'}
            </Button>
          </NoPermissionTooltip>
        </Flex>
      );
    };

    return (
      <Fragment>
        <Header
          title={
            checkedRows && checkedRows.length ? (
              <Flex justifyContent="flex-end" gap={3}>
                <Flex
                  gap={3}
                  alignSelf="center"
                  boxShadow="-10px 0px 10px 1px var(--chakra-colors-gray-100)"
                >
                  <Button
                    onClick={() => {
                      history.push(`/service-catalog?actionType=MAINTENANCE`);
                    }}
                  >
                    Maintenance Mode
                  </Button>
                </Flex>
              </Flex>
            ) : (
              <HStack>
                <Text as="i" color="gray.500" fontSize={'14px'}>
                  Data updated every ~10 minutes
                </Text>
              </HStack>
            )
          }
          actions={
            <Flex gap={4} alignItems="center">
              {lastUpdate && (
                <Text as="i" color="gray.500" fontSize={'14px'}>
                  Data last updated on {moment(lastUpdate).format('MMMM Do YYYY, h:mm:ss a')}
                </Text>
              )}
              <IconButton
                title="Expand All"
                aria-label="Expand All"
                variant={allExpanded ? 'iconDarker' : 'icon'}
                icon={<ExpandIcon />}
                isActive={allExpanded}
                onClick={initExpandAllRows(true)}
              />
              <IconButton
                title="Collapse All"
                aria-label="Collapse All"
                variant={allExpanded ? 'icon' : 'iconDarker'}
                icon={<CollapsedIcon />}
                isActive={!allExpanded}
                onClick={initExpandAllRows(false)}
              />

              <Text fontSize="sm">
                Showing {serviceData ? serviceData.length : 0} of{' '}
                {serviceListData?.meta.total_count || 0}
              </Text>
            </Flex>
          }
        />
        <DataTable
          data={serviceData}
          columns={columns}
          isAllExpanded={allExpanded}
          hoverView={<ServiceAction rowData="." />}
          sortBy={(sort_by, sort_order) =>
            history.push(`/service-catalog?sortby=${sort_by}&sortorder=${sort_order}`)
          }
          sorted={sorting}
          paginationProps={{
            queryPageIndex,
            queryPageSize,
            totalCount: serviceListData?.meta.total_count || 0,
            pageChange: (queryPageIndex: number) => pageChange(queryPageIndex),
            pageSizeChange: (queryPageSize: number) => {
              pageSizeChange(queryPageSize);
            },
          }}
        />
      </Fragment>
    );
  },
);

export default ServiceList;
