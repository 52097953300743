import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SecondaryFiltersKeys, SecondaryFiltersType } from '../types';
import qs from 'query-string';

type ContextProps = {
  filters: SecondaryFiltersType;
  defaultFilters: SecondaryFiltersType;
  activeFilters: SecondaryFiltersKeys[];
  updateFilter: (type: SecondaryFiltersKeys, data: string[]) => void;
  updateAllFilterAtOnce: (filter: SecondaryFiltersType) => void;
  updateActiveFilters: (type: SecondaryFiltersKeys) => void;
  removeActiveFilters: (filter: SecondaryFiltersKeys) => void;
};

const SecondaryFilterContext = React.createContext<ContextProps>({} as ContextProps);

export const useSecondaryFilters = () => useContext(SecondaryFilterContext);

type ProviderProps = {
  children: React.ReactNode;
  defaultFilters: SecondaryFiltersType;
  isSingleSelection?: boolean;
};

const SecondaryFilterProvider: React.FC<ProviderProps> = ({
  children,
  defaultFilters,
  isSingleSelection,
}) => {
  const [filters, setFilters] = React.useState<SecondaryFiltersType>(defaultFilters);
  const [activeFilters, setActiveFilters] = React.useState<SecondaryFiltersKeys[]>([]);

  const updateFilterBySelection = (filters: SecondaryFiltersType) => {
    if (isSingleSelection) {
      setFilters(oldFilters => {
        const modifiedFilters = Object.keys({ ...oldFilters, ...filters }).reduce(
          (acc, filterKey) => {
            const newFilterValues = filters[filterKey as keyof SecondaryFiltersType];
            const oldFilterValues = oldFilters[filterKey as keyof SecondaryFiltersType];
            return {
              ...acc,
              [filterKey]: filterKey in filters ? newFilterValues : oldFilterValues,
            };
          },
          {} as SecondaryFiltersType,
        );
        // const modifiedFilters = { ...oldFilters, ...filters };
        const singleFilters = Object.entries(modifiedFilters).reduce(
          (acc, [key, values]) => ({ ...acc, [key]: values }),
          {} as SecondaryFiltersType,
        );
        return singleFilters;
      });
    } else {
      setFilters(oldFilters => ({ ...oldFilters, ...filters }));
    }
  };

  const updateActiveFilters = (filter: SecondaryFiltersKeys) => {
    if (activeFilters.includes(filter)) return;
    setActiveFilters([...activeFilters, filter]);
  };

  const removeActiveFilters = (key: SecondaryFiltersKeys) => {
    const newFilters = { ...filters };
    delete newFilters[key];
    if (isSingleSelection) {
      updateFilterBySelection(newFilters);
    } else {
      setFilters(newFilters);
    }
    setActiveFilters(oldActiveFilters =>
      oldActiveFilters.filter(activeFilter => activeFilter !== key),
    );
  };

  const isInitialMount = React.useRef(true);

  const history = useHistory();
  const queryParams = useLocation().search;

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    history.push({ search: `filters=${JSON.stringify(filters)}` });
  }, [filters]);

  useEffect(() => {
    const parseparams = qs.parse(queryParams);
    const parsefilters = parseparams.filters;

    if (!parsefilters) return;

    let urlfilters: SecondaryFiltersType = {};

    try {
      if (Array.isArray(parsefilters)) {
        urlfilters = JSON.parse(parsefilters[0]);
      } else {
        urlfilters = JSON.parse(parsefilters);
      }
    } catch (error) {
      console.error(error);
    }

    setActiveFilters(Object.keys(urlfilters) as SecondaryFiltersKeys[]);
    updateFilterBySelection(urlfilters);
  }, []);

  const updateFilter = (type: SecondaryFiltersKeys, data: string[]) => {
    updateFilterBySelection({ [type]: data });
  };

  const updateAllFilterAtOnce = (filters: SecondaryFiltersType) => {
    setFilters(filters);
  };

  return (
    <SecondaryFilterContext.Provider
      value={{
        filters,
        updateFilter,
        defaultFilters,
        activeFilters,
        updateActiveFilters,
        removeActiveFilters,
        updateAllFilterAtOnce,
      }}
    >
      {children}
    </SecondaryFilterContext.Provider>
  );
};

export default SecondaryFilterProvider;
