import { Span } from '@sentry/tracing';
import { Para, Checkbox, Tooltip, Grid, IconButton, Theme, InputBlock } from 'uie/components';
import {
  NotificationTypesInterface,
  AcknowledgeReminderState,
  IncidentReminderRule,
} from 'core/interfaces/IEscalationPolicy';
import { DeleteIcon } from 'icons';
import React, { useEffect, useState } from 'react';

const notificationTypes: NotificationTypesInterface[] = [
  {
    type: 'push',
    label: 'Push',
    isChecked: false,
  },
  {
    type: 'email',
    label: 'Email',
    isChecked: false,
  },
  {
    type: 'sms',
    label: 'SMS',
    isChecked: false,
  },
  {
    type: 'phone',
    label: 'Phone',
    isChecked: false,
  },
];

interface Props {
  sendRemindAcknowledgementDataToEscPolicy: (
    acknowledgementReminders: AcknowledgeReminderState[],
    enableIncidentReminders: boolean,
  ) => void;
  retriggerHandler: (retriggerAfter: number, enableIncidentReminders: boolean) => void;
  remindAcknowledgeIncidentDataFromEscPolic: IncidentReminderRule[] | undefined;
  isRemindAcknowledgeIncidentEnabled: boolean;
  enableIncidentRetrigger: boolean;
  retriggerAfter: number;
}

function RemindAndRetriggerAcknowledegedIncidents(props: Props) {
  const { theme } = Theme;
  const [acknowledgementReminders, setAcknowledgementReminder] = useState<
    AcknowledgeReminderState[]
  >([]);
  const [isAcknowledementReminderChecked, setIsAcknowledementReminderChecked] = useState(false);

  const [isIncidentRetriggerChecked, setIsIncidentRetriggered] = useState(
    props.enableIncidentRetrigger,
  );

  const [retriggerAfter, setRetriggerAfter] = useState(props.retriggerAfter);

  function notificationHandleOnChange(
    checkboxIndex: number,
    acknowledgementReminder: AcknowledgeReminderState,
  ) {
    acknowledgementReminder.notifications[checkboxIndex].isChecked =
      !acknowledgementReminder.notifications[checkboxIndex].isChecked;
    setAcknowledgementReminder([...acknowledgementReminders]);
  }

  function timeInputHandler(index: number, value: string) {
    acknowledgementReminders[index].time = value;
    setAcknowledgementReminder([...acknowledgementReminders]);
  }

  function removeAcknowledgementReminderHandler(index: number) {
    const removedAcknowledgementReminder = acknowledgementReminders.filter(
      (_, ackReminderIndex) => index !== ackReminderIndex,
    );
    setAcknowledgementReminder([...removedAcknowledgementReminder]);
  }

  function addNewAcknowledgementReminderHandler() {
    const newAcknowledgementReminder: AcknowledgeReminderState = {
      key: acknowledgementReminders.length + 1,
      time: '',
      notifications: notificationTypes.map(notification => ({ ...notification })),
    };
    setAcknowledgementReminder([...acknowledgementReminders, newAcknowledgementReminder]);
  }

  useEffect(() => {
    if (isAcknowledementReminderChecked && acknowledgementReminders.length === 0) {
      addNewAcknowledgementReminderHandler();
    } else if (!isAcknowledementReminderChecked) {
      acknowledgementReminders.length = 0;
      setAcknowledgementReminder([]);
    }
  }, [isAcknowledementReminderChecked]);

  useEffect(() => {
    props.retriggerHandler(retriggerAfter, isIncidentRetriggerChecked);
  }, [isIncidentRetriggerChecked, retriggerAfter]);

  useEffect(() => {
    props.sendRemindAcknowledgementDataToEscPolicy(
      [...acknowledgementReminders],
      isAcknowledementReminderChecked,
    );

    if (acknowledgementReminders.length === 0) setIsAcknowledementReminderChecked(false);
  }, [acknowledgementReminders]);

  useEffect(() => {
    if (
      props.remindAcknowledgeIncidentDataFromEscPolic &&
      props.remindAcknowledgeIncidentDataFromEscPolic.length > 0
    ) {
      const formattedRemindAcknowledgeIncidentDataFromEscPolic =
        props.remindAcknowledgeIncidentDataFromEscPolic.map((rule, index) => {
          const newNotificationTypes = notificationTypes.map(notification => ({ ...notification }));
          newNotificationTypes.forEach(notification => {
            if (rule.via.includes(notification.type)) {
              notification.isChecked = true;
            }
          });
          const formattedRemindAcknowledgeIncident: AcknowledgeReminderState = {
            notifications: newNotificationTypes,
            key: index + 1,
            time: rule.time_interval / 60 + '',
          };

          return formattedRemindAcknowledgeIncident;
        });
      setAcknowledgementReminder(formattedRemindAcknowledgeIncidentDataFromEscPolic);
    }
    setIsAcknowledementReminderChecked(props.isRemindAcknowledgeIncidentEnabled);
  }, []);

  return (
    <div
      style={{
        backgroundColor: '#f3f4f6',
        borderTop: `solid 1px #e7ecf5`,
        marginTop: '20px',
        marginBottom: '20px',
        paddingLeft: '13px',
        paddingTop: '17px',
        paddingBottom: '16px',
      }}
    >
      <Grid type="column">
        <Grid alignContent="center" style={{ marginLeft: 0 }}>
          <Para style={{ fontSize: 14, fontWeight: 600, fontFamily: 'Mulish', paddingBottom: 5 }}>
            <span className="font-bold">Actions for unresolved incidents</span>
          </Para>
        </Grid>
        <Grid alignContent="center" style={{ margin: 5, marginLeft: 0, marginTop: 10 }}>
          <Checkbox
            style={{ marginRight: '10px' }}
            checked={isAcknowledementReminderChecked}
            onChange={() => setIsAcknowledementReminderChecked(prevState => !prevState)}
          />
          <Grid type="column" style={{ width: '100%' }}>
            <div style={{ display: 'flex' }}>
              <Para fontWeight={300} fontSize={14} style={{}}>
                Send acknowledgement reminders &nbsp;
              </Para>
              <Tooltip
                offset={{ left: '-60px', top: '-90px' }}
                width="500px"
                label={`If an incident remains unresolved, send acknowledgement
              timeout notifications to the user who acknowledged the incident.
              These notifications can be configured to be sent up until 48 hours
              have passed since the incident was acknowledged. `}
              >
                <img
                  src="/icons/info_black.svg"
                  alt="more info"
                  style={{ height: 16, cursor: 'pointer' }}
                />
              </Tooltip>
            </div>
            {acknowledgementReminders.map((acknowledgementReminder, index) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
                key={acknowledgementReminder.key}
              >
                <Grid style={{ verticalAlign: 'center', alignItems: 'center', marginTop: '12px' }}>
                  <p
                    style={{
                      margin: '0 14px',
                      color: '#122146',
                      fontSize: '14px',
                      fontWeight: 300,
                    }}
                  >
                    Every
                  </p>
                  <InputBlock
                    style={{ width: '60px', height: '37px', marginRight: '10px' }}
                    id={'time-remind-ack-incidents'}
                    value={acknowledgementReminder.time}
                    type="number"
                    name={'time-remind-ack-incidents'}
                    onChange={event => timeInputHandler(index, event.target.value)}
                  />
                  <Para fontWeight={300} fontSize={14} style={{ margin: '0 7px' }}>
                    hr&nbsp;(s)
                  </Para>
                  <Para fontWeight={300} fontSize={14} style={{ marginRight: '7px' }}>
                    via
                  </Para>
                  {notificationTypes.map((notificationType, notificationIndex) => (
                    <div key={notificationType.type} style={{ display: 'flex', margin: '0 10px' }}>
                      <Checkbox
                        style={{ marginRight: '4px' }}
                        checked={acknowledgementReminder.notifications[notificationIndex].isChecked}
                        onChange={() =>
                          notificationHandleOnChange(notificationIndex, acknowledgementReminder)
                        }
                      />
                      <Para>{notificationType.label}</Para>
                    </div>
                  ))}
                </Grid>

                <IconButton
                  onClick={() => removeAcknowledgementReminderHandler(index)}
                  style={{ marginTop: 10 }}
                >
                  <DeleteIcon height={20} width={20} color={theme.danger.default} />
                </IconButton>
              </div>
            ))}
            {isAcknowledementReminderChecked && (
              <p
                className="item-box-blue-text no-select"
                style={{ cursor: 'pointer', marginTop: 20, width: 'fit-content' }}
                onClick={addNewAcknowledgementReminderHandler}
              >
                + Add Reminder
              </p>
            )}
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{
          width: 995,
          height: 0,
          border: '1px solid #B7BDC7',
          borderTop: '0',
          marginBottom: 19,
          marginTop: 12,
        }}
      />
      <Grid style={{ marginTop: '10px', marginBottom: '20px' }}>
        <Checkbox
          style={{ marginRight: '10px' }}
          checked={isIncidentRetriggerChecked}
          onChange={() => setIsIncidentRetriggered(prevState => !prevState)}
        />
        <Grid style={{ width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <Para fontWeight={300} fontSize={14} style={{ marginRight: '10px' }}>
              Re-trigger the incident{' '}
            </Para>
            <div style={{ display: 'flex', margin: '0 2px' }}>
              <InputBlock
                type="number"
                style={{
                  width: 60,
                  margin: '-10px 4px -10px -2px',
                  height: 37,
                  padding: 0,
                  textAlign: 'center',
                }}
                disabled={!isIncidentRetriggerChecked}
                value={retriggerAfter}
                width={50}
                onChange={event =>
                  isIncidentRetriggerChecked && setRetriggerAfter(parseInt(event.target.value))
                }
              />
            </div>

            <Para fontWeight={300} fontSize={14} style={{ margin: '0 1px' }}>
              hr (s) from the time of latest acknowledgement &nbsp;
            </Para>

            <Tooltip
              offset={{ left: '-60px', top: '-90px' }}
              width="200px"
              label={`An incident can be re-triggered only once. This value can be a maximum of 48 hours.`}
            >
              <img
                src="/icons/info_black.svg"
                alt="more info"
                style={{ height: 16, cursor: 'pointer' }}
              />
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default RemindAndRetriggerAcknowledegedIncidents;
