import Axios from 'axios';
import qs from 'query-string';
import { API } from 'core';
import { QUERY_CONFIG } from 'library/query-config';
import { QueryClient, useQuery } from 'react-query';
import { SERVICE_NODE_TYPE } from '../Interfaces/graph';
import { queryKeys } from '../contants/service.contant';

const getServiceGraphData = (teamId: string) => {
  const props = {
    owner_id: teamId,
  };
  return Axios.get(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/graph?${qs.stringify(
      props,
    )}`,
  );
};

export const useGetServiceGraphData = (teamId: string) => {
  const { data, isSuccess, isError, isLoading, isFetching } = useQuery({
    queryKey: [queryKeys.SERVICE_GRAPH_QUERY_KEY, teamId],
    queryFn: () => getServiceGraphData(teamId),
    ...{ ...QUERY_CONFIG, refetchInterval: 5000 },
  });
  const response = data?.data?.data ?? { services: [], graph_status: 'valid' };

  return {
    services: response.services as SERVICE_NODE_TYPE[],
    isSuccess,
    isError,
    isLoading,
    isFetching,
    status: response.graph_status,
  };
};

export const invalidateServiceGraphData = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(queryKeys.SERVICE_GRAPH_QUERY_KEY);
};
