import * as Types from './generated/types';
import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from './fetcher/index';
import { compact } from 'lodash';
import { GET_INCIDENT_ASSIGNABLE_ENTITIES_QUERY_KEY } from '../common/constants';
export type ListIncidentsQueryVariables = Types.Exact<{
  input: Types.IncidentListReq;
}>;

export type ListIncidentsQuery = {
  __typename?: 'Query';
  listIncidents: {
    __typename?: 'IncidentList';
    data: Array<{
      __typename?: 'Incident';
      id: string;
      status: Types.IncidentStatusType;
      message: string;
      eventCount: number;
      priority?: Types.IncidentPriority | null;
      timeOfCreation: any;
      hasNotes: boolean;
      hasRetrospectives: boolean;
      retrospectiveStatus?: string | null;
      isStarred: boolean;
      sloID: number;
      didAutoPause: boolean;
      groupedAlertCount: number;
      didNotificationDelay: boolean;
      alertSource: {
        __typename?: 'IncidentAlertSource';
        name: string;
        iconURL: { __typename?: 'IncidentAlertSourceIcon'; svg: string };
      };
      service: { __typename?: 'IncidentService'; id: string; name: string };
      tags: Array<{ __typename?: 'IncidentTag'; key: string; value: string; color: string }>;
      assignedTo: {
        __typename?: 'IncidentAssignee';
        id: string;
        type: string;
        name: string;
        usernameForDisplay?: string | null;
      };
      snoozeDetails: {
        __typename?: 'SnoozeDetails';
        isSnoozed: boolean;
        startTime?: any | null;
        endTime?: any | null;
        totalTime: any;
      };
      children: Array<{ __typename?: 'MergeIncidentEntityReference'; id: string }>;
    } | null>;
    meta?: {
      __typename?: 'CursorMetadata';
      hasNext?: boolean | null;
      next?: string | null;
      hasPrev?: boolean | null;
      prev?: string | null;
    } | null;
  };
};

export type CountIncidentsQueryVariables = Types.Exact<{
  input: Types.CountIncidentReq;
}>;

export type CountIncidentsQuery = {
  __typename?: 'Query';
  countIncidents: { __typename?: 'IncidentCount'; totalCount: number };
};

export type GetIncidentAssignableEntitiesQueryVariables = Types.Exact<{
  teamID: Types.Scalars['String'];
}>;

export type GetIncidentAssignableEntitiesQuery = {
  __typename?: 'Query';
  getIncidentAssignableEntities: {
    __typename?: 'IncidentAssignableEntities';
    users: Array<{
      __typename?: 'User';
      ID: string;
      name: string;
      usernameForDisplay?: string | null;
    }>;
    squads: Array<{ __typename?: 'Squad'; ID: string; name: string }>;
    escalationPolicies: Array<{ __typename?: 'EscalationPolicy'; ID: string; name: string }>;
  };
};

export type GetIncidentActiveTagsQueryVariables = Types.Exact<{
  teamID: Types.Scalars['String'];
}>;

export type GetIncidentActiveTagsQuery = {
  __typename?: 'Query';
  getIncidentActiveTags: Array<{
    __typename?: 'IncidentActiveTags';
    key: string;
    values: Array<string>;
  } | null>;
};

export type ListIncidentGroupsQueryVariables = Types.Exact<{
  teamID: Types.Scalars['String'];
}>;

export type ListIncidentGroupsQuery = {
  __typename?: 'Query';
  listIncidentGroups?: Array<{
    __typename?: 'IncidentGroup';
    incidentGroupID: number;
    name: string;
    isPrivate: boolean;
    userID: string;
    filters: {
      __typename?: 'IncidentGroupFilters';
      serviceIDs?: Array<string> | null;
      sloIDs?: Array<number> | null;
      isSLO?: Types.FilterChoice | null;
      isStarred?: Types.FilterChoice | null;
      dateFilter?: Types.DateFilterChoice | null;
      customDateTo?: string | null;
      customDateFrom?: string | null;
      lastNumberOfDays?: number | null;
      hasNotes?: Types.FilterChoice | null;
      hasRetrospectives?: Types.FilterChoice | null;
      retrospectiveStatus?: Array<string> | null;
      alertSourceIDs?: Array<string> | null;
      assignedToUserIDsAndTheirSquads: Array<string> | null;
      assignedToUserAndTheirSquads: {
        user: {
          id: string;
          name: string;
        };
        squads: {
          id: string;
          name: string;
        }[];
      }[];
      serviceOwner: {
        userIDsAndTheirSquads: Array<string> | null;
        userIDs: string[] | null;
        squadIDs: string[] | null;
      };
      serviceOwnerDetails: {
        usersAndTheirSquads: {
          user: {
            id: string;
            name: string;
          };
          squads: {
            id: string;
            name: string;
          }[];
        }[];
      };
      priority?: Array<Types.IncidentPriorityFilter> | null;
      responderIDs?: Array<string> | null;
      tags?: Array<{ __typename?: 'IncidentTagFilterType'; key: string; value: string }> | null;
    };
  }> | null;
};

export type GetIncidentGroupQueryVariables = Types.Exact<{
  incidentGroupID: Types.Scalars['Int'];
}>;

export type GetIncidentGroupQuery = {
  __typename?: 'Query';
  getIncidentGroup: {
    __typename?: 'IncidentGroup';
    incidentGroupID: number;
    name: string;
    isPrivate: boolean;
    userID: string;
    filters: {
      __typename?: 'IncidentGroupFilters';
      assignedToMe?: boolean | null;
      alertSourceIDs?: Array<string> | null;
      serviceIDs?: Array<string> | null;
      sloIDs?: Array<number> | null;
      isSLO?: Types.FilterChoice | null;
      isStarred?: Types.FilterChoice | null;
      dateFilter?: Types.DateFilterChoice | null;
      customDateTo?: string | null;
      customDateFrom?: string | null;
      lastNumberOfDays?: number | null;
      hasNotes?: Types.FilterChoice | null;
      hasRetrospectives?: Types.FilterChoice | null;
      priority?: Array<Types.IncidentPriorityFilter> | null;
    };
  };
};

export type ListServicesQueryVariables = Types.Exact<{
  teamID: Types.Scalars['String'];
  maintainerIDs?: any;
}>;

export type ListServicesQuery = {
  __typename?: 'Query';
  listServices: Array<{
    __typename?: 'Service';
    serviceID: string;
    name: string;
    apiKey: string;
    epID: string;
  }>;
};

export type ListActiveAlertSourcesQueryVariables = Types.Exact<{
  teamID: Types.Scalars['String'];
  fromDate?: Types.InputMaybe<Types.Scalars['Time']>;
  toDate?: Types.InputMaybe<Types.Scalars['Time']>;
}>;

export type ListActiveAlertSourcesQuery = {
  __typename?: 'Query';
  listActiveAlertSources: Array<{
    __typename?: 'IncidentAlertSource';
    id: string;
    name: string;
    iconURL: { __typename?: 'IncidentAlertSourceIcon'; svg: string };
  }>;
};

export type ListRecentIncidentsQueryVariables = Types.Exact<{
  input: Types.IncidentListReq;
}>;

export type ListRecentIncidentsQuery = {
  __typename?: 'Query';
  listIncidents: {
    __typename?: 'IncidentList';
    data: Array<{
      __typename?: 'Incident';
      id: string;
      status: Types.IncidentStatusType;
      message: string;
      timeOfCreation: any;
      lastAcknowledgedAt?: any | null;
      alertSource: {
        __typename?: 'IncidentAlertSource';
        iconURL: { __typename?: 'IncidentAlertSourceIcon'; svg: string };
      };
    } | null>;
  };
};

export const ListIncidentsDocument = `
    query listIncidents($input: IncidentListReq!) {
  listIncidents(input: $input) {
    data {
      id
      status
      message
      eventCount
      alertSource {
        name
        iconURL {
          svg
        }
      }
      service {
        id
        name
      }
      tags {
        key
        value
        color
      }
      assignedTo {
        id
        type
        name
        usernameForDisplay
      }
      priority
      timeOfCreation
      snoozeDetails {
        isSnoozed
        startTime
        endTime
        totalTime
      }
      hasNotes
      hasRetrospectives
      retrospectiveStatus
      isStarred
      sloID
      children {
        id
      }
      didAutoPause
      groupedAlertCount
      didNotificationDelay
    }
    meta {
      hasNext
      next
      hasPrev
      prev
    }
  }
}
    `;
export const useListIncidentsQuery = <TData = ListIncidentsQuery, TError = unknown>(
  variables: ListIncidentsQueryVariables,
  options?: UseQueryOptions<ListIncidentsQuery, TError, TData>,
) =>
  useQuery<ListIncidentsQuery, TError, TData>(
    ['listIncidents', variables],
    fetcher<ListIncidentsQuery, ListIncidentsQueryVariables>(ListIncidentsDocument, variables),
    options,
  );
export const CountIncidentsDocument = `
    query countIncidents($input: CountIncidentReq!) {
  countIncidents(input: $input) {
    totalCount
  }
}
    `;
export const useCountIncidentsQuery = <TData = CountIncidentsQuery, TError = unknown>(
  variables: CountIncidentsQueryVariables,
  options?: UseQueryOptions<CountIncidentsQuery, TError, TData>,
) =>
  useQuery<CountIncidentsQuery, TError, TData>(
    ['countIncidents', variables],
    fetcher<CountIncidentsQuery, CountIncidentsQueryVariables>(CountIncidentsDocument, variables),
    options,
  );
export const GetIncidentAssignableEntitiesDocument = `
    query getIncidentAssignableEntities($teamID: String!) {
  getIncidentAssignableEntities(teamID: $teamID) {
    users {
      ID
      name
      usernameForDisplay
    }
    squads {
      ID
      name
    }
    escalationPolicies {
      ID
      name
    }
  }
}
    `;
export const useGetIncidentAssignableEntitiesQuery = <
  TData = GetIncidentAssignableEntitiesQuery,
  TError = unknown,
>(
  variables: GetIncidentAssignableEntitiesQueryVariables,
  options?: UseQueryOptions<GetIncidentAssignableEntitiesQuery, TError, TData>,
) =>
  useQuery<GetIncidentAssignableEntitiesQuery, TError, TData>(
    [GET_INCIDENT_ASSIGNABLE_ENTITIES_QUERY_KEY, variables],
    fetcher<GetIncidentAssignableEntitiesQuery, GetIncidentAssignableEntitiesQueryVariables>(
      GetIncidentAssignableEntitiesDocument,
      variables,
    ),
    {
      ...options,
      refetchOnMount: true, // The query does not get refetched after invalidation, unless explicitly set to true
    },
  );
export const GetIncidentActiveTagsDocument = `
    query getIncidentActiveTags($teamID: String!) {
  getIncidentActiveTags(teamID: $teamID) {
    key
    values
  }
}
    `;
export const useGetIncidentActiveTagsQuery = <TData = GetIncidentActiveTagsQuery, TError = unknown>(
  variables: GetIncidentActiveTagsQueryVariables,
  options?: UseQueryOptions<GetIncidentActiveTagsQuery, TError, TData>,
) =>
  useQuery<GetIncidentActiveTagsQuery, TError, TData>(
    ['getIncidentActiveTags', variables],
    fetcher<GetIncidentActiveTagsQuery, GetIncidentActiveTagsQueryVariables>(
      GetIncidentActiveTagsDocument,
      variables,
    ),
    options,
  );
export const ListIncidentGroupsDocument = `
    query listIncidentGroups($teamID: String!) {
  listIncidentGroups(teamID: $teamID) {
    incidentGroupID
    name
    isPrivate
    userID
    filters {
      serviceIDs
      assignedToUserIDsAndTheirSquads
      assignedToUserAndTheirSquads {
      user {
          id
          name
        }
      squads {
          id
          name
        }
      }
      serviceOwner {
        userIDsAndTheirSquads
        userIDs
        squadIDs
      }
      serviceOwnerDetails {
        usersAndTheirSquads {
          user {
              id
              name
          }
          squads {
              id
              name
          }
        }
      }
      sloIDs
      isSLO
      isStarred
      dateFilter
      customDateTo
      customDateFrom
      lastNumberOfDays
      hasNotes
      hasRetrospectives
      retrospectiveStatus
      alertSourceIDs
      priority
      responderIDs
      tags {
        key
        value
      }
    }
  }
}
    `;
export const useListIncidentGroupsQuery = <TData = ListIncidentGroupsQuery, TError = unknown>(
  variables: ListIncidentGroupsQueryVariables,
  options?: UseQueryOptions<ListIncidentGroupsQuery, TError, TData>,
) =>
  useQuery<ListIncidentGroupsQuery, TError, TData>(
    ['listIncidentGroups', variables],
    fetcher<ListIncidentGroupsQuery, ListIncidentGroupsQueryVariables>(
      ListIncidentGroupsDocument,
      variables,
    ),
    options,
  );
export const GetIncidentGroupDocument = `
    query getIncidentGroup($incidentGroupID: Int!) {
  getIncidentGroup(incidentGroupID: $incidentGroupID) {
    incidentGroupID
    name
    isPrivate
    userID
    filters {
      assignedToMe
      alertSourceIDs
      serviceIDs
      sloIDs
      isSLO
      isStarred
      dateFilter
      customDateTo
      customDateFrom
      lastNumberOfDays
      hasNotes
      hasRetrospectives
      priority
    }
  }
}
    `;
export const useGetIncidentGroupQuery = <TData = GetIncidentGroupQuery, TError = unknown>(
  variables: GetIncidentGroupQueryVariables,
  options?: UseQueryOptions<GetIncidentGroupQuery, TError, TData>,
) =>
  useQuery<GetIncidentGroupQuery, TError, TData>(
    ['getIncidentGroup', variables],
    fetcher<GetIncidentGroupQuery, GetIncidentGroupQueryVariables>(
      GetIncidentGroupDocument,
      variables,
    ),
    options,
  );
export const ListServicesDocument = `
    query listServices($teamID: String!, $maintainerIDs: [String!]) {
  listServices(teamID: $teamID, maintainerIDs: $maintainerIDs) {
    serviceID
    name
    apiKey
    epID
  }
}
    `;
export const useListServicesQuery = <TData = ListServicesQuery, TError = unknown>(
  variables: ListServicesQueryVariables,
  options?: UseQueryOptions<ListServicesQuery, TError, TData>,
) =>
  useQuery<ListServicesQuery, TError, TData>(
    ['listServices', variables],
    fetcher<ListServicesQuery, ListServicesQueryVariables>(ListServicesDocument, variables),
    options,
  );
export const ListActiveAlertSourcesDocument = `
    query listActiveAlertSources($teamID: String!, $fromDate: Time, $toDate: Time) {
  listActiveAlertSources(teamID: $teamID, fromDate: $fromDate, toDate: $toDate) {
    id
    name
    iconURL {
      svg
    }
  }
}
    `;
export const useListActiveAlertSourcesQuery = <
  TData = ListActiveAlertSourcesQuery,
  TError = unknown,
>(
  variables: ListActiveAlertSourcesQueryVariables,
  options?: UseQueryOptions<ListActiveAlertSourcesQuery, TError, TData>,
) =>
  useQuery<ListActiveAlertSourcesQuery, TError, TData>(
    ['listActiveAlertSources', variables],
    fetcher<ListActiveAlertSourcesQuery, ListActiveAlertSourcesQueryVariables>(
      ListActiveAlertSourcesDocument,
      variables,
    ),
    options,
  );
export const ListRecentIncidentsDocument = `
    query listRecentIncidents($input: IncidentListReq!) {
  listIncidents(input: $input) {
    data {
      id
      status
      message
      alertSource {
        iconURL {
          svg
        }
      }
      timeOfCreation
      lastAcknowledgedAt
    }
  }
}
    `;
export const useListRecentIncidentsQuery = <TData = ListRecentIncidentsQuery, TError = unknown>(
  variables: ListRecentIncidentsQueryVariables,
  options?: UseQueryOptions<ListRecentIncidentsQuery, TError, TData>,
) =>
  useQuery<ListRecentIncidentsQuery, TError, TData>(
    ['listRecentIncidents', variables],
    fetcher<ListRecentIncidentsQuery, ListRecentIncidentsQueryVariables>(
      ListRecentIncidentsDocument,
      variables,
    ),
    options,
  );

export type GetactiveTagsQueryVariables = Types.Exact<{
  teamID: Types.Scalars['String'];
  serviceIDs?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>
  >;
}>;

export type GetactiveTagsQuery = {
  __typename?: 'Query';
  getIncidentActiveTags: Array<{
    __typename?: 'IncidentActiveTags';
    key: string;
    values: Array<string>;
  } | null>;
};

export const getActiveTags = `
    query getactiveTags($teamID: String!, $serviceIDs: [String]) {
  getIncidentActiveTags(teamID: $teamID, serviceIDs: $serviceIDs) {
    key
    values
  }
}
    `;
export const useGetactiveTagsQuery = <TData extends GetactiveTagsQuery, TError = unknown>(
  variables: GetactiveTagsQueryVariables,
  options?: UseQueryOptions<GetactiveTagsQuery, TError, TData>,
) => {
  const { data, isError, isLoading, error, isSuccess } = useQuery<
    GetactiveTagsQuery,
    TError,
    TData
  >(
    ['getactiveTags', variables],
    fetcher<GetactiveTagsQuery, GetactiveTagsQueryVariables>(getActiveTags, variables),
    options,
  );

  const tagsList = data
    ? compact(
        data.getIncidentActiveTags.map((tag, index) => {
          if (tag) return { label: tag.key, value: tag.key, child: tag.values };
        }),
      )
    : [];

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    tagsList,
  };
};
