import './unsnooze.module.css';

import { Box, HStack } from '@chakra-ui/react';
import { useSnooze } from 'components/Snooze/hooks';
import { IUnSnoozeIncidentRequest } from 'core/interfaces/IIncidents';
import { FormButton } from 'library/atoms';
import { CustomDrawerComponent, IncidentAssignableSearch } from 'library/molecules';
import React, { useCallback, useMemo, useState } from 'react';
import { EMPTY_STRING } from 'views/main/organization/incident-list/common/constants';
import { IncidentAssignToType } from 'views/main/organization/incident-list/graphql/generated/types';

import ReassignmentTile from './ReassignmentTile';
import { DrawerProps, UNSNOOZE_TYPES } from './type';
import { getDisableStatus } from './utils';

function UnsnoozeDrawer(props: DrawerProps) {
  const { onClose, isOpen, assigneeId, assigneeType, incidentId, incidentName, screenName } = props;
  const { unSnoozeIncident, unSnoozing } = useSnooze();

  const [selectedId, setSelectedId] = useState(EMPTY_STRING);
  const [userData, setSelectedUserData] = useState({
    name: '',
    type: IncidentAssignToType.User,
  });
  const [selectedReassignmentValue, setSelectedReassignmentValue] = useState(
    UNSNOOZE_TYPES.REASSIGN_TO_USER_WHO_SNOOZED,
  );

  const onSelectionChange = (e: string) => {
    setSelectedId(e);
  };

  const onUserChange = (name: string, type: IncidentAssignToType) => {
    setSelectedUserData({
      name: name,
      type: type,
    });
  };

  const handleReassignmentChange = useCallback((reassignmentType: UNSNOOZE_TYPES) => {
    setSelectedReassignmentValue(reassignmentType);
  }, []);

  const closeModal = useCallback(() => {
    onClose();
    setSelectedId(EMPTY_STRING);
    setSelectedReassignmentValue(UNSNOOZE_TYPES.REASSIGN_TO_USER_WHO_SNOOZED);
  }, [onClose]);

  const getUnSnoozerName = useMemo(() => {
    if (selectedReassignmentValue === UNSNOOZE_TYPES.REASSIGN_TO_SELF) return 'me';
    else if (selectedReassignmentValue === UNSNOOZE_TYPES.REASSIGN_TO_DIFFERENT_USER)
      return userData.name;
    return 'snoozer';
  }, [userData, selectedReassignmentValue]);

  const unSnoozeAndReassing = () => {
    let unSnoozePayload: IUnSnoozeIncidentRequest = {
      reassign_to: {
        id: '',
        type: 'snoozer',
      },
    };

    if (selectedReassignmentValue === UNSNOOZE_TYPES.REASSIGN_TO_SELF) {
      unSnoozePayload = {
        reassign_to: {
          ...unSnoozePayload.reassign_to,
          type: 'me',
        },
      };
    } else if (selectedReassignmentValue === UNSNOOZE_TYPES.REASSIGN_TO_DIFFERENT_USER) {
      unSnoozePayload = {
        reassign_to: {
          id: selectedId,
          type: userData.type,
        },
      };
    }
    unSnoozeIncident(
      incidentId,
      incidentName,
      unSnoozePayload.reassign_to.type,
      unSnoozePayload.reassign_to.id,
      getUnSnoozerName,
      screenName,
      closeModal,
    );
  };

  const assigneeList = useMemo(() => {
    if (selectedReassignmentValue === UNSNOOZE_TYPES.REASSIGN_TO_DIFFERENT_USER)
      return (
        <IncidentAssignableSearch
          assignees={[{ id: assigneeId ?? '', type: assigneeType ?? '' }]}
          onSelectionUpdate={assignees => {
            onSelectionChange(assignees[0].id);
            onUserChange(assignees[0].name, assignees[0].type);
          }}
        />
      );
  }, [assigneeId, assigneeType, onSelectionChange, onUserChange]);

  return (
    <CustomDrawerComponent
      onClose={closeModal}
      isOpen={isOpen}
      title="Unsnooze Incident"
      disableBodyPadding
      size="md"
      footer={
        <HStack spacing={5}>
          <FormButton
            title="Unsnooze & Reassign"
            isDisabled={getDisableStatus(selectedReassignmentValue, selectedId) || unSnoozing}
            isLoading={unSnoozing}
            onClick={unSnoozeAndReassing}
          />
          <FormButton
            variant="secondary"
            isDisabled={unSnoozing}
            title="Cancel"
            onClick={closeModal}
          />
        </HStack>
      }
    >
      <Box
        style={{
          flexDirection: 'column',
          flex: 1,
          flexGrow: 1,
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box paddingLeft={4}>
          <ReassignmentTile
            selectedReassignmentValue={selectedReassignmentValue}
            handleReassignmentChange={(value: UNSNOOZE_TYPES) => handleReassignmentChange(value)}
          />
        </Box>
        <Box style={{ height: '100%', overflow: 'auto' }}>{assigneeList}</Box>
      </Box>
    </CustomDrawerComponent>
  );
}

export default React.memo(UnsnoozeDrawer);
