import { HStack, Text, VStack } from '@chakra-ui/react';
import { truncate } from 'core/helpers/stringUtils';
import { AssigneeAvatar, Checkbox, Divider, Radio } from 'library/atoms';
import { AssigneeType } from 'library/enums';
import { ChangeEvent } from 'react';
import { USERNAME_MAX_LENGTH } from './constants';

type Props = {
  id: string;
  name: string;
  username?: string | null;
  isSelected?: boolean;
  type?: AssigneeType;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  allowMulti?: boolean;
};

export const Entity = ({
  id,
  name,
  username,
  isSelected,
  type,
  onChange,
  allowMulti = false,
}: Props) => {
  return (
    <>
      <HStack alignItems="center" p={2} columnGap={2}>
        {allowMulti ? (
          <Checkbox size="lg" value={id} isChecked={isSelected} onChange={onChange} />
        ) : (
          <Radio value={id} isChecked={!!isSelected} onChange={onChange} />
        )}
        {type && <AssigneeAvatar name={name} type={type} />}
        <VStack alignItems="left">
          <Text variant="body" color="secondary.1000">
            {name}
          </Text>
          {type === 'user' && username && (
            <Text variant="body" color="secondary.700">
              {truncate(username, USERNAME_MAX_LENGTH)}
            </Text>
          )}
        </VStack>
      </HStack>
      <Divider />
    </>
  );
};
