// An interface for our actions
interface SearchAction {
  type: 'EDIT_SERVICE' | 'RESET_SERVICE';
  isDrawerOpen?: boolean;
  drawerType?: 'EDIT' | null | string;
  serviceID?: string | null;
}

// An interface for our state
interface ServiceListState {
  drawer: {
    isDrawerOpen: boolean;
    drawerType: 'EDIT' | null | string;
    serviceID?: string | null;
  };
}

export const ServiceDetailReducer = (state: ServiceListState, action: SearchAction) => {
  const { type, drawerType = null, isDrawerOpen = false, serviceID = null } = action;
  switch (type) {
    case 'EDIT_SERVICE':
      return {
        ...state,
        drawer: {
          drawerType,
          serviceID,
          isDrawerOpen,
        },
      };

    default:
      return state;
  }
};
