import DrawerFooterWrapper from 'library/molecules/Drawer';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import { useCallback } from 'react';
import { reassignIncidentValidation } from 'views/main/organization/workflow/constant/validation';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { SqReassignIncidentAction } from 'views/main/organization/workflow/types';
import { FormFooter } from '../form-footer';
import ReassignIncidentForm from './ReassignIncidentForm';

const ReassignIncidentContainer = () => {
  const { actionForm, onActionSubmit, checkDirtyAndCloseDrawer } = useWorkflowFormContext();

  const handleFormButtonClick = useCallback(() => {
    checkDirtyAndCloseDrawer();
  }, [checkDirtyAndCloseDrawer]);

  return (
    <FormWrapper<SqReassignIncidentAction>
      onSubmit={onActionSubmit}
      defaultValues={actionForm! as SqReassignIncidentAction}
      validationSchema={reassignIncidentValidation}
    >
      <ReassignIncidentForm />
      <DrawerFooterWrapper>
        <FormFooter<SqReassignIncidentAction> checkDirtyAndCloseDrawer={checkDirtyAndCloseDrawer} />
      </DrawerFooterWrapper>
    </FormWrapper>
  );
};

export default ReassignIncidentContainer;
