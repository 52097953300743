import { requestOrganizationSelectedTeamChange } from 'core/actions/organization/selectedTeam';
import { IAppState } from 'core/interfaces/IAppState';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export const useCompareGlobalAndEntityTeamId = () => {
  const selectedTeamId = useSelector((state: IAppState) => state.organization.selectedTeam.teamId);
  const dispatch = useDispatch();
  const compareGlobalAndEntityTeamId = (entityTeamId: string) => {
    return selectedTeamId === entityTeamId;
  };

  const isDifferent = (entityTeamId: string) => {
    return selectedTeamId !== entityTeamId;
  };

  const handleDifferentGlobalAndEntityTeamId = (entityTeamId?: string) => {
    if (!entityTeamId?.length) return;
    if (isDifferent(entityTeamId)) {
      // change the selected team
      dispatch(requestOrganizationSelectedTeamChange(entityTeamId));
    }
  };

  return { compareGlobalAndEntityTeamId, handleDifferentGlobalAndEntityTeamId };
};

export const withCompareGlobalAndEntityTeamId = (Component: any) => {
  return (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { compareGlobalAndEntityTeamId, handleDifferentGlobalAndEntityTeamId } =
      useCompareGlobalAndEntityTeamId();
    return (
      <Component
        {...props}
        compareGlobalAndEntityTeamId={compareGlobalAndEntityTeamId}
        handleDifferentGlobalAndEntityTeamId={handleDifferentGlobalAndEntityTeamId}
      />
    );
  };
};

export const GlobalTeamIDGate = ({ entityTeamId }: { entityTeamId: string }) => {
  const { handleDifferentGlobalAndEntityTeamId } = useCompareGlobalAndEntityTeamId();
  useEffect(() => {
    handleDifferentGlobalAndEntityTeamId(entityTeamId);
  }, [entityTeamId]);

  return null;
};
