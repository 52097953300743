import 'reactflow/dist/style.css';

import { Divider, Flex } from '@chakra-ui/react';
import { T_WA_UP_SG_FILTERED } from 'core/const/tracker';
import dagre from 'dagre';
import { THEME_COLORS } from 'library/theme/colors';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import ReactFlow, {
  Background,
  BackgroundVariant,
  MarkerType,
  Position,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { AppTracker } from 'shared/analytics/tracker';

import {
  DEFAULT_GRAPH_COLOR,
  EDGE_STROKE,
  GRAPH_MAX_ZOOM,
  GRAPH_MIN_ZOOM,
  HIGHLIGHTED_EDGE_STROKE,
  NODE_HEIGHT,
  NODE_WIDTH,
} from '../../contants/service.contant';
import {
  getHealthStatus,
  getHealthStatusBgColor,
  getHealthStatusColor,
} from '../../helpers/helper.graph';
import {
  EDGE_TYPE,
  NODE_TYPE,
  SERVICE_HEALTH_STATUS,
  STATUS_NODES_MAP,
} from '../../Interfaces/graph';
import { FilterButton } from '../common/components';
import { ReactFlowControls } from './graph.control';

type Props = {
  initialNodes: NODE_TYPE;
  initialEdges: EDGE_TYPE;
  onSelect: (nodeId: string) => void;
};

enum EVENT_TYPE {
  CLICK = 'click',
  MOUSE_ENTER = 'mouseenter',
  MOUSE_LEAVE = 'mouseleave',
}

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

export const Graph = forwardRef(({ initialNodes, initialEdges, onSelect }: Props, ref) => {
  const getLayoutedElements = (nodes: NODE_TYPE, edges: EDGE_TYPE) => {
    dagreGraph.setGraph({
      rankdir: 'TB',
      edgesep: 200,
      nodesep: 200,
      ranksep: 400,
      ranker: 'tight-tree',
      acyclicer: 'greedy',
    });

    nodes.forEach(node => {
      dagreGraph.setNode(node.id, { width: NODE_WIDTH, height: NODE_HEIGHT });
    });

    edges.forEach(edge => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach(node => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = Position.Top;
      node.sourcePosition = Position.Bottom;

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - NODE_WIDTH / 2,
        y: nodeWithPosition.y - NODE_HEIGHT / 2,
      };

      return node;
    });

    return { nodes, edges };
  };
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initialNodes,
    initialEdges,
  );
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  useEffect(() => {
    setNodes(nds =>
      nds.map(node => {
        const serviceNode = initialNodes.find(item => item.id === node.id);
        if (serviceNode) {
          node.data = serviceNode.data;
        }
        return node;
      }),
    );
  }, [initialNodes]);

  const [nodeId, setNodeId] = useState<string>();
  const [selectedNodeId, setSelectedNodeId] = useState<string>();
  const [eventType, setEventType] = useState<string>();
  const [activeFilter, setActiveFilter] = useState<string>();
  const [statusNodesMap, setStatusNodesMap] = useState<STATUS_NODES_MAP>();

  const resetNodeId = () => setNodeId('');

  useEffect(() => {
    setNodes(nds =>
      nds.map(node => {
        const serviceNode = initialNodes.find(item => item.id === node.id);
        if (serviceNode) {
          node.data = serviceNode.data;
        }
        return node;
      }),
    );
  }, [initialNodes]);

  const setFilter = (status: string) => {
    const newStatus = activeFilter !== status ? status : undefined;
    const activeNodes = newStatus ? statusNodesMap?.[status] ?? [] : [];

    if (nodeId) {
      resetNodeId();
    } else {
      AppTracker.track(T_WA_UP_SG_FILTERED, {
        'Service Graph Filter': getHealthStatus(status as SERVICE_HEALTH_STATUS),
      });
    }
    setActiveFilter(newStatus);
    updateNodes(
      activeNodes,
      newStatus ? getHealthStatusColor(newStatus as SERVICE_HEALTH_STATUS) : DEFAULT_GRAPH_COLOR,
      newStatus ? getHealthStatusBgColor(newStatus as SERVICE_HEALTH_STATUS) : undefined,
    );
  };

  useImperativeHandle(ref, () => ({
    setSelection(id?: string) {
      const eventName = id ? EVENT_TYPE.CLICK : '';
      setEventType(eventName);
      setNodeId(id ?? '');
      if (id) {
        setSelectedNodeId(id);
      }
    },
  }));

  useEffect(() => {
    let nodes = [];
    const statusCount: STATUS_NODES_MAP = {};
    ['healthy', 'unhealthy', 'on_maintenance'].forEach(status => {
      nodes = (initialNodes.filter(node => node.data.type === status) ?? []).map(node => node.id);
      statusCount[status] = nodes;
    });
    setStatusNodesMap(statusCount);
  }, [initialNodes]);

  useEffect(() => {
    const targetNodes = new Set<string>();
    const updatedEdges = edges.map(edge => {
      const isSelectedEdge = edge.target === nodeId || edge.source === nodeId;
      if (isSelectedEdge) {
        targetNodes.add(edge.target);
        targetNodes.add(edge.source);
      }
      edge.animated = isSelectedEdge;
      edge.style = {
        ...edge.style,
        stroke: isSelectedEdge ? THEME_COLORS.brand.blue : DEFAULT_GRAPH_COLOR,
        strokeWidth: isSelectedEdge ? HIGHLIGHTED_EDGE_STROKE : EDGE_STROKE,
      };
      edge.markerEnd = {
        type: MarkerType.ArrowClosed,
        color: isSelectedEdge ? THEME_COLORS.brand.blue : DEFAULT_GRAPH_COLOR,
      };
      return edge;
    });
    setEdges(updatedEdges);
    if (!activeFilter) {
      updateNodes(Array.from(targetNodes), THEME_COLORS.brand.blue);
    }
  }, [nodeId]);

  const updateNodes = (
    highlightedNodes: Array<string>,
    highlightColor?: string,
    bgColor?: string,
  ) => {
    setNodes(nds =>
      nds.map(node => {
        const isHighlightedNode = highlightedNodes.includes(node.id);

        node.style = {
          ...node.style,
          borderColor: isHighlightedNode ? highlightColor : DEFAULT_GRAPH_COLOR,
          backgroundColor: bgColor && isHighlightedNode ? bgColor : THEME_COLORS.brand.white,
        };
        return node;
      }),
    );
  };

  const onNodeAction = useCallback(
    (event, node) => {
      const eventName = event.type;
      // if previously any service node is clicked or any filter is applied, we won't honor any hover events
      if ((eventType === EVENT_TYPE.CLICK || !!activeFilter) && eventName !== EVENT_TYPE.CLICK) {
        return;
      }
      if (eventName !== EVENT_TYPE.MOUSE_LEAVE) {
        setEventType(eventName);
        setNodeId(node.id);
        if (eventName === EVENT_TYPE.CLICK) {
          onSelect(node.id);
          setActiveFilter(undefined);
        }
      } else if (eventType === EVENT_TYPE.MOUSE_ENTER) {
        resetNodeId();
      }
    },
    [eventType, activeFilter],
  );

  return (
    <Flex flexDir="column" flex={1}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        fitView
        minZoom={GRAPH_MIN_ZOOM}
        maxZoom={GRAPH_MAX_ZOOM}
        nodesConnectable={false}
        onNodeClick={onNodeAction}
        onNodeMouseEnter={onNodeAction}
        onNodeMouseLeave={onNodeAction}
        defaultEdgeOptions={{
          markerEnd: MarkerType.ArrowClosed,
        }}
        proOptions={{
          hideAttribution: true,
        }}
      >
        <ReactFlowControls id={selectedNodeId} />
        <Background variant={BackgroundVariant.Dots} gap={16} size={1} />
      </ReactFlow>
      <Divider />
      <Flex gap={3} alignItems="center" justifyContent="center" my={2}>
        {['healthy', 'unhealthy', 'on_maintenance'].map(status => (
          <FilterButton
            key={status}
            status={status as SERVICE_HEALTH_STATUS}
            count={statusNodesMap?.[status]?.length}
            isActive={activeFilter === status}
            onClick={() => setFilter(status)}
          />
        ))}
      </Flex>
    </Flex>
  );
});
