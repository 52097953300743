import styled from 'styled-components';
import { HelpFilledIcon, InfoFilledIcon } from 'icons';

export const StyledHelpIcon = styled(HelpFilledIcon)`
  path {
    fill: #0f61dd;
  }
`;

export const StyledInfoFilledIcon = styled(InfoFilledIcon)`
  path {
    fill: #ff4d4f;
  }
`;
