import { HStack, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { NodePopover } from 'components/chakra/Popover';
import { IFilterTag } from '../../Interface';

type closeCB = (type: string, value: string) => void;
interface Props {
  filters: IFilterTag[];
  onCancel: closeCB;
}

interface TagProps {
  filter: IFilterTag;
  onCancel: closeCB;
}

const FilterTag = (props: TagProps) => {
  const { filter, onCancel } = props;
  return (
    <Tag key={filter.value} size="md">
      <TagLabel fontWeight="normal" textTransform={'capitalize'}>
        {filter.label}
      </TagLabel>
      <TagCloseButton
        _hover={{ cursor: 'pointer' }}
        onClick={() => onCancel(filter.type, filter.value)}
      />
    </Tag>
  );
};

const FilterList = (props: Props) => {
  const remainingItems = props.filters.slice(3);
  return props.filters.length ? (
    <HStack mb={4} px={6}>
      {props.filters.slice(0, 3).map(filter => (
        <FilterTag filter={filter} onCancel={props.onCancel} key={filter.value} />
      ))}
      {remainingItems.length ? (
        <NodePopover
          triggerType="hover"
          trigger={
            <Tag size="md">
              <TagLabel>+ {remainingItems.length}</TagLabel>
            </Tag>
          }
        >
          {remainingItems.map(filter => (
            <FilterTag filter={filter} onCancel={props.onCancel} key={filter.value} />
          ))}
        </NodePopover>
      ) : null}
    </HStack>
  ) : (
    <></>
  );
};

export default FilterList;
