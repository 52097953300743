import styled from 'styled-components';

interface IProps {
  type?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  width?: string | number | 'auto';
  height?: string | number | 'auto';
  flexWidth?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  flexWrap?:
    | 'wrap'
    | 'unset'
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'nowrap'
    | 'wrap-reverse';
  alignContent?:
    | 'stretch'
    | 'flex-start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'flex-end';
  alignSelf?: 'auto' | 'flex-start' | 'center' | 'baseline' | 'stretch' | 'flex-end';
  alignItems?: 'auto' | 'flex-start' | 'center' | 'baseline' | 'stretch' | 'flex-end';
  justifyContent?: 'flex-start' | 'center' | 'space-between' | 'space-around' | 'flex-end';
  reverse?: boolean | false;
}

const propsFlex = (props: IProps) => {
  const flexWidth = props.flexWidth ? `${(100 / 12) * props.flexWidth}%` : 'auto';

  return `
      width: ${props.width ? props.width : flexWidth}
    `;
};

const Grid = styled.div<IProps>`
  ${propsFlex};
  height: ${props => props.height || 'auto'};
  flex-direction: ${props => props.type || 'row'};
  flex-wrap: ${props => props.flexWrap || 'unset'};
  align-content: ${props => props.alignContent || 'stretch'};
  align-self: ${props => props.alignSelf || 'auto'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: ${props => props.alignItems || 'normal'};
  display: flex;
`;

/** @component */
export default Grid;
