import { API } from '../../../../core/api';
import { IAnalyticsData } from '../../../../core/interfaces/IAnalytics';
import { exception } from '../../../../core/exception';
import { debounce } from 'core/helpers';
import { getItem, setItem } from 'store/localStorage';

const updateAnalyticsCache = async (data: IAnalyticsData) => {
  const key = `analytics::${API.config.organizationId}`;

  try {
    await setItem(key, data);
  } catch (err: any) {
    exception.handle('E_SET_ANALYTICS_CACHE', err);
  }
};

export const getAnalyticsCache = async () => {
  const key = `analytics::${API.config.organizationId}`;

  try {
    const data = getItem(key) as IAnalyticsData;
    return data;
  } catch (err: any) {
    exception.handle('E_GET_ANALYTICS_CACHE', err);
    return null;
  }
};

export const debouncedUpdateAnalyticsCache = debounce(updateAnalyticsCache, 1000);
