import { ExtensionService } from 'core';
import { useQuery } from 'react-query';
import { reactQueryConfigError } from '../../jira-cloud/helpers/service.reactqery';

async function GetSlackChannelUser() {
  const _extensionService = new ExtensionService();
  const { data } = await _extensionService.getSlackV2ChannelUsers();
  return data;
}

const useGetSlackChannelUsers = () => {
  return useQuery('slack-channel-user', () => GetSlackChannelUser(), {
    retry: 1,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    select: data => data.data.users,
    onError: (error: any) => {
      const description =
        error?.response?.data?.meta?.error_message || 'Error connecting to server';
      reactQueryConfigError({ description: description, title: 'Get Slack Users' });
    },
  });
};

export { useGetSlackChannelUsers };
