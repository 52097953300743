import { messageSlackUserValidation } from 'views/main/organization/workflow/constant/validation';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import MessageUserForm from './MessageUserForm';
import { SlackUserAction } from 'views/main/organization/workflow/types';
import { FormFooter } from '../../squadcast/form-footer';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import DrawerFooterWrapper from 'library/molecules/Drawer';

const HttpCallContainer = () => {
  const { actionForm, onActionSubmit, checkDirtyAndCloseDrawer } = useWorkflowFormContext();
  return (
    <FormWrapper<SlackUserAction>
      onSubmit={onActionSubmit}
      defaultValues={actionForm! as SlackUserAction}
      validationSchema={messageSlackUserValidation}
    >
      <MessageUserForm />
      <DrawerFooterWrapper>
        <FormFooter<SlackUserAction> checkDirtyAndCloseDrawer={checkDirtyAndCloseDrawer} />
      </DrawerFooterWrapper>
    </FormWrapper>
  );
};

export default HttpCallContainer;
