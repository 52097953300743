import { useHistory } from 'react-router-dom';
import useQueryParams from 'core/hooks/useQueryParams';

export const usePersistQuery = () => {
  const query = useQueryParams();
  const history = useHistory();

  const persistQuery = () => {
    query.delete('serviceID');
    query.delete('actionType');
    history.push(`/service-catalog?${query.toString()}`);
  };

  return persistQuery;
};
