import { ChakraProvider } from '@chakra-ui/react';
import { useFlag } from '@unleash/proxy-client-react';
import { canQueryUnleash } from 'core';
import LibraryTheme from 'library/theme';
import { memo, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ErrorBoundary } from 'views/shared/components';

import { clearILSessionStore } from './common/util';
import { filterManager } from './filters/manager';
import IncidentListHome from './home';
import { isAllTimeLimit, setAllTimeLimit } from './store/persistent-storage';
import { SessionProvider } from './behaviors/Session';

const IncidentsListingPage = () => {
  const { path } = useRouteMatch();
  // feature flag status check for org
  const enabled = useFlag('IncidentListAllTimeData');

  useEffect(() => {
    if (!canQueryUnleash()) {
      return;
    }
    const prevState = isAllTimeLimit();
    if (prevState != enabled) {
      setAllTimeLimit(enabled);
      filterManager.rebuildFilters();
    }
    // reset session store temp data whenever IL mounts
    clearILSessionStore();
  }, []);

  return (
    <ChakraProvider theme={LibraryTheme}>
      <ErrorBoundary>
        <SessionProvider>
          <Switch>
            <Route exact path={path}>
              <IncidentListHome />
            </Route>
          </Switch>
        </SessionProvider>
      </ErrorBoundary>
    </ChakraProvider>
  );
};

export default memo(IncidentsListingPage);
