import equal from 'fast-deep-equal/es6/react';
import React, { Fragment, useState } from 'react';
import { CSVLink } from 'react-csv';

import { TimeDimensionComparison, ResultSet, TimeDimension } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { Grid, Para, Theme } from 'uie/components';

import { useFilters } from '../Filters/Filters.context';
import { BarsChart, Chart, CompareFilters, PanelObject } from '../types';
import {
  resultSetToBars,
  resultSetToCalendar,
  resultSetToLines,
  resultSetToPie,
  resultSetToStat as resultSetToStats,
} from '../utils/resultset.utils';
import BarsPanel from './BarsPanel';
import LinesPanel from './LinesPanel';
import StatsPanel from './StatsPanel';
import { DownloadIcon } from 'icons';
import styled from 'styled-components';
import CalendarPanel from './CalendarPanel';
import { useCalendarContext } from './CalendarPanel.context';
import PiePanel from './PiePanel';
import { DateTime } from 'luxon';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_UP_MTTA_MTTR_OVER_TIME_REPORT_DOWNLOADED } from '../../../../../core/const/tracker';
import { T_WA_UP_INICIDENT_COUNT_BY_DATE_REPORT_DOWNLOADED } from '../../../../../core/const/tracker';
import { T_WA_UP_INCIDENT_STATUS_BY_DATE_REPORT_DOWNLOADED } from '../../../../../core/const/tracker';
import { T_WA_UP_OPEN_INCIDENTS_BY_TEAM_REPORT_DOWNLOADED } from '../../../../../core/const/tracker';
import { T_WA_UP_DEDUPLICATED_INCIDENT_BY_SERVICE_REPORT_DOWNLOADED } from '../../../../../core/const/tracker';
import { T_WA_UP_DEDUPLICATED_INCIDENT_BY_ALERT_SOURCES_REPORT_DOWNLOADED } from '../../../../../core/const/tracker';
import { T_WA_UP_DEDUPLICATED_INCIDENT_BY_TEAM_REPORT_DOWNLOADED } from '../../../../../core/const/tracker';
import { T_WA_UP_SUPPRESSED_INCIDENT_BY_TEAM_REPORT_DOWNLOADED } from '../../../../../core/const/tracker';
import { T_WA_UP_OPEN_INCIDENTS_BY_SERVICE_REPORT_DOWNLOADED } from '../../../../../core/const/tracker';
import { T_WA_UP_OPEN_INCIDENTS_BY_USERS_REPORT_DOWNLOADED } from '../../../../../core/const/tracker';
import { T_WA_UP_SUPPRESSED_INCIDENT_BY_SERVICE_REPORT_DOWNLOADED } from '../../../../../core/const/tracker';
import { T_WA_UP_SUPPRESSED_INCIDENT_BY_ALERT_SOURCES_REPORT_DOWNLOADED } from '../../../../../core/const/tracker';
import { T_WA_UP_REASSIGNED_INCIDENT_BY_TEAM_REPORT_DOWNLOADED } from '../../../../../core/const/tracker';
import CompareFilter from '../Compare';
import schema from '../schema';
import {
  priorityFilter,
  servicesFilter,
  tagsFilter,
  teamsFilter,
  timeDimensions,
  usersFilter,
  wrapFilters,
} from '../utils/query.utils';
import { dateRangeGranularity } from '../utils/date.utils';
import { IAppState } from 'core/interfaces/IAppState';
import { useSelector, shallowEqual } from 'react-redux';

type Props = {
  panel: PanelObject;
  isTeamAnalytics?: boolean;
};

const { theme } = Theme;

const ExportLink = styled(CSVLink)`
  position: absolute;
  right: 1rem;
`;

const PieDateInfo = ({ date }: { date?: DateTime }) => {
  return <Para fontSize={12}>{`(${date?.toFormat('yyyy-LL-dd')})`}</Para>;
};

const CSVExport = ({
  resultSet,
  title,
  chart,
  isCompare,
  compareFilter,
  timeDimensions,
  organization,
}: {
  resultSet: ResultSet;
  title: string;
  chart?: Chart;
  isCompare?: boolean;
  compareFilter?: CompareFilters[];
  timeDimensions?: TimeDimension[];
  organization?: IAppState['organization'];
}) => {
  let exportData = resultSet.tablePivot();
  const userName = (userId: string) => {
    const users = organization?.users.u.find(u => u.id === userId);
    if (users) return users.first_name + users.last_name;
    return '';
  };

  if (isCompare) {
    const ed = resultSet.decompose().map((currentResultSet, i) => {
      const pt = currentResultSet.tablePivot();
      if (pt.length && compareFilter && compareFilter[i]) {
        return pt.map(pivot => ({
          ...pivot,
          Tag: `${compareFilter[i].tag || ''}`,
          'User.name': `${userName(compareFilter[i].user) || ''}`,
        }));
      } else {
        return pt;
      }
    });
    exportData = ed.flat(1);
  }

  if (chart?.type === 'bars') {
    exportData = exportData.map(pivot => ({
      'Incidents.date (UTC)': `${timeDimensions?.[0].dateRange?.[0]}T00:00:00.000 - ${timeDimensions?.[0].dateRange?.[1]}T00:00:00.000`,
      ...pivot,
    }));
  }

  const handleMixpanelTracking = (title: string) => {
    switch (title) {
      case 'MTTA & MTTR Over Time':
        AppTracker.track(T_WA_UP_MTTA_MTTR_OVER_TIME_REPORT_DOWNLOADED);
        break;
      case 'Incident Count by Date':
        AppTracker.track(T_WA_UP_INICIDENT_COUNT_BY_DATE_REPORT_DOWNLOADED);
        break;
      case 'Incident Status by Date':
        AppTracker.track(T_WA_UP_INCIDENT_STATUS_BY_DATE_REPORT_DOWNLOADED);
        break;
      case 'Open Incidents by Team':
        AppTracker.track(T_WA_UP_OPEN_INCIDENTS_BY_TEAM_REPORT_DOWNLOADED);
        break;
      case 'Deduplicated Incidents by Team':
        AppTracker.track(T_WA_UP_DEDUPLICATED_INCIDENT_BY_TEAM_REPORT_DOWNLOADED);
        break;
      case 'Suppressed Incidents by Team':
        AppTracker.track(T_WA_UP_SUPPRESSED_INCIDENT_BY_TEAM_REPORT_DOWNLOADED);
        break;
      case 'Deduplicated Incidents by Services':
        AppTracker.track(T_WA_UP_DEDUPLICATED_INCIDENT_BY_SERVICE_REPORT_DOWNLOADED);
        break;
      case 'Deduplicated Incidents by Alert Sources':
        AppTracker.track(T_WA_UP_DEDUPLICATED_INCIDENT_BY_ALERT_SOURCES_REPORT_DOWNLOADED);
        break;
      case 'Open Incidents by Service':
        AppTracker.track(T_WA_UP_OPEN_INCIDENTS_BY_SERVICE_REPORT_DOWNLOADED);
        break;
      case 'Open Incidents by Users':
        AppTracker.track(T_WA_UP_OPEN_INCIDENTS_BY_USERS_REPORT_DOWNLOADED);
        break;
      case 'Suppressed Incidents by Service':
        AppTracker.track(T_WA_UP_SUPPRESSED_INCIDENT_BY_SERVICE_REPORT_DOWNLOADED);
        break;
      case 'Suppressed Incidents by Alert Sources':
        AppTracker.track(T_WA_UP_SUPPRESSED_INCIDENT_BY_ALERT_SOURCES_REPORT_DOWNLOADED);
        break;
      case 'Reassigned Incidents by Teams':
        AppTracker.track(T_WA_UP_REASSIGNED_INCIDENT_BY_TEAM_REPORT_DOWNLOADED);
        break;
    }
  };

  return (
    <ExportLink
      data={exportData}
      filename={`${title.replace(/\s/g, '_')}_export.csv`}
      onClick={() => handleMixpanelTracking(title)}
      title="Export CSV"
    >
      <DownloadIcon fill={theme.primary.default} />
    </ExportLink>
  );
};

const Panel: React.FC<Props> = ({ panel, isTeamAnalytics }) => {
  const { filters, compareFilters, updateCompareFilter } = useFilters();
  const { activeDate } = useCalendarContext();
  const organization = useSelector((state: IAppState) => state.organization, shallowEqual);
  const [disablePrevTracking, setDisablePrevTracking] = useState(false);

  const compareQuerys = compareFilters.map(cf => {
    return {
      measures: [schema.IncidentsMTTA, schema.IncidentsMTTR],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date, {
        granularity: dateRangeGranularity(filters.date),
      }),
      dimensions: [schema.TeamsName, schema.ServicesName],
      filters: wrapFilters(
        usersFilter(
          [
            schema.IncidentsAcknowledgedByUserId,
            schema.IncidentsResolvedByUserId,
            schema.IncidentsAssignedToUserId,
          ],
          cf.user.length ? [cf.user] : [],
        ),
        tagsFilter(schema.IncidentsTags, cf.tag.length ? [cf.tag] : []),
        servicesFilter(schema.IncidentsServiceId, cf.service.length ? [cf.service] : []),
        priorityFilter(schema.IncidentsPriority, cf.priority?.length ? [cf.priority] : []),
        teamsFilter(schema.IncidentsTeamId, filters.teamIds),
      ),
    };
  });
  const compareResult = useCubeQuery(compareQuerys);
  const query = panel.query({ ...filters, customDate: activeDate });

  let { resultSet } = useCubeQuery(query);
  const { isLoading, error } = useCubeQuery(query);
  if (isLoading) {
    return (
      <Grid style={{ height: '100%' }}>
        <Para style={{ margin: 'auto' }}>Loading...</Para>
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid style={{ height: '100%' }}>
        s<Para style={{ margin: 'auto' }}>Error!</Para>
      </Grid>
    );
  }

  if (!resultSet) {
    return null;
  }

  //TODO: THIS IS JUST A HOT FIX , THIS LOGIC MUST BE REFACTORED WHEN ANALYTCS IS BEING REFACTORED IMPORTANT!!!!!
  if (filters?.serviceOwnerIds?.length !== 0 && filters?.serviceIds?.length == 0) {
    if (!disablePrevTracking) setDisablePrevTracking(true);
    const newObj: any = {
      ...resultSet,
      loadResponse: {
        ...resultSet?.loadResponse,
        results: resultSet?.loadResponse?.results.map((result: any) => {
          return {
            ...result,
            data: [],
          };
        }),
      },
      loadResponses: resultSet?.loadResponses?.map((field: any) => {
        return {
          ...field,
          data: [],
        };
      }),
    };
    Object.setPrototypeOf(newObj, Object.getPrototypeOf(resultSet));
    resultSet = newObj;
  } else {
    if (disablePrevTracking) setDisablePrevTracking(false);
  }

  switch (panel.chart.type) {
    case 'stats':
      return (
        <StatsPanel
          disablePrevTracking={disablePrevTracking}
          filters={filters}
          chart={panel.chart}
          dateRange={
            (query.timeDimensions as TimeDimensionComparison[])?.[0].compareDateRange || []
          }
          {...resultSetToStats(resultSet, panel.chart)}
        />
      );
    case 'lines':
      const linesData = !compareResult.resultSet
        ? resultSetToLines(resultSet, panel.chart)
        : resultSetToLines(compareResult.resultSet, panel.chart, true);
      return (
        <Fragment>
          {isTeamAnalytics && (
            <div style={{ position: 'absolute', right: '4em' }}>
              <CompareFilter
                compareFilter={compareFilters}
                teamId={filters.teamIds[0]}
                updateCompareFilter={updateCompareFilter}
                isDisabled={
                  filters.serviceIds.length || filters.tags.length || filters.userIds.length
                    ? true
                    : false
                }
              />
            </div>
          )}
          {compareFilters.length && compareResult.isLoading ? (
            <Grid style={{ height: '100%' }}>
              <Para style={{ margin: 'auto' }}>Loading...</Para>
            </Grid>
          ) : (
            <>
              {!linesData.length ? (
                <Grid style={{ height: '100%' }}>
                  <Para style={{ margin: 'auto' }}>No data found for this selection</Para>
                </Grid>
              ) : (
                <>
                  <CSVExport
                    resultSet={
                      compareFilters.length && compareResult.resultSet
                        ? compareResult.resultSet
                        : resultSet
                    }
                    title={panel.title}
                    compareFilter={compareFilters}
                    isCompare={!compareResult.resultSet ? false : true}
                    organization={organization}
                  />
                  <div style={{ height: '320px' }}>
                    <LinesPanel
                      filters={filters}
                      chart={panel.chart}
                      lines={linesData}
                      timeDimensions={query.timeDimensions}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </Fragment>
      );
    case 'bars':
      const barsData = resultSetToBars(resultSet, panel.chart);
      const hasAnyIndividualData = barsData.filter(
        bar => bar[(panel.chart as BarsChart).measures[0]] > 0,
      ).length;

      if (!barsData.length || !hasAnyIndividualData) {
        return (
          <Grid style={{ height: '100%' }}>
            <Para style={{ margin: 'auto' }}>No data found for this selection</Para>
          </Grid>
        );
      }
      return (
        <Fragment>
          <CSVExport
            resultSet={resultSet}
            title={panel.title}
            chart={panel.chart}
            timeDimensions={query.timeDimensions}
          />
          <BarsPanel filters={filters} chart={panel.chart} bars={barsData} />
        </Fragment>
      );
    case 'pie':
      const pieData = resultSetToPie(resultSet, panel.chart);
      if (!pieData.length) {
        return (
          <Grid style={{ height: '100%', flexDirection: 'column' }}>
            <PieDateInfo date={activeDate} />
            <Para style={{ margin: 'auto' }}>No data found for this selection</Para>
          </Grid>
        );
      }
      return (
        <Fragment>
          {activeDate && <PieDateInfo date={activeDate} />}
          <CSVExport resultSet={resultSet} title={panel.title} />
          <PiePanel data={pieData} />
        </Fragment>
      );
    case 'calendar':
      return (
        <Fragment>
          <CSVExport resultSet={resultSet} title={panel.title} />
          <div style={{ height: '320px', overflowX: 'auto', overflowY: 'hidden' }}>
            <CalendarPanel
              filters={filters}
              chart={panel.chart}
              calendar={resultSetToCalendar(resultSet, panel.chart)}
            />
          </div>
        </Fragment>
      );
    default:
      const unreachable: never = panel.chart;
      return unreachable;
  }
};

export default Panel;
