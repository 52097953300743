import { HStack, IconButton, Text } from '@chakra-ui/react';
import { IAppState } from 'core/interfaces/IAppState';
import { THEME_COLORS } from 'library/theme/colors';
import moment from 'moment';
import { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import useStatuspageComponentTimelineContext from '../hook';

export default function StatusPageTimelineOverrideFormDateSwitcher() {
  const userInfo = useSelector((state: IAppState) => state.userInfo);
  const {
    activeDate,
    prevDate,
    nextDate,
    overrideUnsaved,
    setUnsavedModalOpen,
    setUnsavedModalCallback,
  } = useStatuspageComponentTimelineContext();

  const [hasPrevDate, setPrevDateAsActive] = prevDate;
  const [hasNextDate, setNextDateAsActive] = nextDate;

  const setPrevActive = () => {
    if (overrideUnsaved) {
      setUnsavedModalOpen(true);
      setUnsavedModalCallback(() => setPrevDateAsActive);
    } else {
      setPrevDateAsActive();
    }
  };

  const setNextActive = () => {
    if (overrideUnsaved) {
      setUnsavedModalOpen(true);
      setUnsavedModalCallback(() => setNextDateAsActive);
    } else {
      setNextDateAsActive();
    }
  };

  if (!activeDate) return <></>;

  return (
    <HStack align="center" justify="space-between">
      <IconButton
        variant="unstyled"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="32px"
        height="32px"
        disabled={!hasPrevDate}
        onClick={setPrevActive}
        aria-label={'Previous Date'}
      >
        <FaChevronLeft color={THEME_COLORS.brand.blue} size="10px" />
      </IconButton>
      <Text fontSize="14px" fontWeight="400" color={THEME_COLORS.secondary[700]}>
        {moment(activeDate).format(userInfo?.d?.date_format)}
      </Text>
      <IconButton
        variant="unstyled"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="32px"
        height="32px"
        disabled={!hasNextDate}
        onClick={setNextActive}
        aria-label={'Next Date'}
      >
        <FaChevronRight color={THEME_COLORS.brand.blue} size="10px" />
      </IconButton>
    </HStack>
  );
}
