import React from 'react';
import { Box, ListItem, OrderedList, Text } from '@chakra-ui/layout';
import {
  CommunicationChannelTypes,
  SqCommunicationChannelAction,
} from 'views/main/organization/workflow/types';
import { groupBy } from 'lodash';
import { CHANNEL_LABEL } from 'views/main/organization/workflow/constant';
import { truncateString } from 'views/main/organization/workflow/utils';

interface Props {
  action: SqCommunicationChannelAction;
}
const CommunicationChannelCard = ({ action }: Props) => {
  const groupedlinks = groupBy(action.data.channels, link => link.channelType.value);
  return (
    <Box>
      {Object.keys(groupedlinks).map(linkType => {
        return (
          <Box key={linkType} mt={2}>
            <Text color="secondary.700" textStyle="bold_800" variant="h3">
              {CHANNEL_LABEL[linkType as CommunicationChannelTypes]}
            </Text>
            <Box ml={2}>
              <OrderedList>
                {groupedlinks[linkType].map((link, index) => (
                  <ListItem key={index}>
                    {link.displayText && (
                      <Text
                        display="inline"
                        color="secondary.1000"
                        textStyle="bold_800"
                        variant="h3"
                      >
                        {link.displayText} :{' '}
                      </Text>
                    )}
                    <Text as="a" href={link.link} target="_blank" color="brand.blue" variant="h4">
                      {truncateString(link.link, 40)}
                    </Text>
                  </ListItem>
                ))}
              </OrderedList>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default React.memo(CommunicationChannelCard);
