import { Circle, SquareProps } from '@chakra-ui/react';
import * as React from 'react';

interface RadioCircleProps extends SquareProps {
  isCompleted: boolean;
  isActive: boolean;
}

export const StepCircle = (props: RadioCircleProps) => {
  const { isCompleted, isActive } = props;
  return (
    <Circle
      size="4"
      bg={isCompleted ? 'accent' : 'inherit'}
      borderWidth={isCompleted ? '0' : '2px'}
      borderColor={isActive ? 'accent' : 'inherit'}
      {...props}
    >
      {isCompleted ? (
        <Circle bg={'rgba(27, 127, 241, 1)'} size="4" />
      ) : (
        <Circle bg={isActive ? 'rgba(27, 127, 241, 1)' : 'border'} size="4" />
      )}
    </Circle>
  );
};
