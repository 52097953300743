import { Para, TextButton } from 'uie/components';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import React from 'react';
import { useSettingPageACL } from '../../../../acl';
import { Member } from '../types';
import AddMemberModal from './AddMemberModal';

type AddMemberToTeamProps = {
  onSubmitted?: () => void;
  members: Member[];
  teamId: string;
};

function AddMemberToTeam(props: AddMemberToTeamProps) {
  const [addingNewMember, setAddingNewMember] = React.useState(false);

  React.useEffect(() => {
    setAddingNewMember(false);
  }, [props.members]);

  const hasUpdateAccess = useSettingPageACL(store => store.hasUpdateAccess);

  const canAddMember = hasUpdateAccess('teams', props.teamId);

  if (!addingNewMember) {
    return (
      <NoPermissionTooltip isDisabled={canAddMember}>
        <TextButton
          style={{ marginTop: '16px' }}
          onClick={() => setAddingNewMember(true)}
          disabled={!canAddMember}
        >
          <Para fontSize={16} color="#fff">
            Add New Members
          </Para>
        </TextButton>
      </NoPermissionTooltip>
    );
  }

  return (
    <AddMemberModal
      onClose={() => setAddingNewMember(false)}
      members={props.members}
      onHelpClick={() => {}}
    />
  );
}

export default AddMemberToTeam;
