import { Theme } from 'uie/components';

export const ActiveWorkflowTarget = (activeAction: string) => {
  const targetAction = window.location.hash.split('?')[0];
  const isactionTrigger = targetAction === activeAction;

  const { theme } = Theme;
  const styleActionOnTarget =
    targetAction === activeAction ? `1px solid ${theme.primary.default}` : 'secondary.200';
  return { isactionTrigger, styleActionOnTarget, targetAction };
};
