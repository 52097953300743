import { API } from '../api';
import Axios from 'axios';
import {
  ISCalendar,
  ISCalendarSeriesRequest,
  ISCalendarOnCallResolve,
  ISCalendarGap,
  ICalendarEventResponse,
} from '../interfaces/ICalendar';
import qs from 'query-string';

import { ITeamOwner, ITeamEntityBasicResponse } from '../interfaces/ITeams';

class CalendarService {
  private api = `${API.config.batman}/organizations/${API.config.organizationId}/calendar`;

  getAll = (teamId: string) => Axios.get<{ data: ISCalendar[] }>(`${this.api}?owner_id=${teamId}`);

  public getCalendarDetails(calendarId: string) {
    return Axios.get(`${this.api}/${calendarId}`);
  }

  public createCalendar(calendar: Pick<ISCalendar, 'colour' | 'name' | 'description'>) {
    return Axios.post(this.api, {
      owner_id: API.config.teamId,
      ...calendar,
    });
  }

  public updateCalendar(calendar: ISCalendar) {
    return Axios.put(`${this.api}/${calendar.id}`, calendar);
  }

  public deleteCalendar(calendarId: string) {
    return Axios.delete(`${this.api}/${calendarId}`);
  }
  public getCalendarGaps() {
    return Axios.get<{ data: ISCalendarGap[] }>(`${this.api}/gaps`);
  }

  public getCalendarEvent(calendarId: string, eventId: string) {
    return Axios.get(`${this.api}/${calendarId}/events/${eventId}`);
  }

  public getCalendarEventsAll(calendarId: string, date: Date) {
    return Axios.get(
      `${
        this.api
      }/${calendarId}/events?currentDate=${date.toISOString()}&daysBefore=0&daysAfter=42`,
    );
  }

  public getCalendarEventsBetweenStartTimes(fromDate: string, toDate: string, userid: string) {
    return Axios.get<{ data: ICalendarEventResponse[] }>(
      `${this.api}/oncall-shift-events?${qs.stringify({
        from: fromDate,
        to: toDate,
        user_id: userid,
      })}`,
    );
  }

  public getCalendarSeries(calendarId: string, seriesId: string) {
    return Axios.get(`${this.api}/${calendarId}/series/${seriesId}`);
  }

  public getCalendarSeriesAll(calendarId: string) {
    return Axios.get(`${this.api}/${calendarId}/series`);
  }

  public createACalendarEvent(calendarId: string, config: ISCalendarSeriesRequest) {
    return Axios.post(`${this.api}/${calendarId}/events`, {
      owner_id: API.config.teamId,
      ...config,
    });
  }

  public updateCalendarEvent(calendarId: string, eventId: string, config: ISCalendarSeriesRequest) {
    return Axios.put(`${this.api}/${calendarId}/events/${eventId}`, config);
  }

  /**
   * @param deleteType for deleting 'This event' | 'This and proceeding events'
   */
  public deleteCalendarSeries(calendarId: string, eventId: string, deleteType: '1' | '2') {
    return Axios({
      method: 'DELETE',
      url: `${this.api}/${calendarId}/events/${eventId}`,
      data: {
        delete_type: parseInt(deleteType as any, 10),
      },
    });
  }

  public getOnCallUsers = (calendarIds: string[]) =>
    Promise.all(
      calendarIds.map(calId => Axios.get<ISCalendarOnCallResolve>(`${this.api}/${calId}/on-call`)),
    );

  public transferOwnership = (id: string, payload: ITeamOwner) =>
    Axios.put<{ data: ITeamEntityBasicResponse }>(`${this.api}/${id}/owner`, payload);
}

export default CalendarService;
