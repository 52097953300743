import * as React from 'react';
import { SVGProps } from 'react';

const SvgSensuGoLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#88c867',
      }}
      d="M19.742 9.738c-.628-.617-1.245-1.234-1.862-1.85-2.57-2.581-5.15-5.162-7.719-7.742-.045-.045-.1-.09-.168-.146-.079.067-.157.135-.224.213L.176 9.806c-.18.18-.18.28 0 .46 1.504 1.492 2.996 2.995 4.488 4.499 1.694 1.694 3.388 3.388 5.082 5.093.202.202.303.18.482-.011 3.176-3.186 6.35-6.361 9.526-9.537.28-.291.269-.291-.012-.572zM6.582 6.092c1.077-1.077 2.154-2.154 3.22-3.231.101-.101.168-.18.314-.034 1.1 1.111 2.21 2.222 3.321 3.332a.618.618 0 0 1 .067.09c-1.178-.235-2.344-.381-3.534-.381-1.178 0-2.356.157-3.511.448 0-.145.078-.18.123-.224zm6.485 8.168c-.976.976-1.952 1.952-2.917 2.928-.146.146-.236.112-.359-.022-.92-.932-1.84-1.852-2.771-2.772-.067-.067-.146-.134-.225-.213-.067-.078 0-.123.056-.168 1.19-.629 2.469-.83 3.793-.73.841.068 1.626.315 2.378.685.157.09.19.157.045.292zm4.017-4.017c-.775.774-1.549 1.537-2.312 2.311-.1.101-.18.124-.303.045a8.47 8.47 0 0 0-4.33-1.245 8.502 8.502 0 0 0-4.566 1.212c-.169.1-.28.112-.427-.045-.718-.74-1.447-1.459-2.176-2.177-.157-.157-.18-.235.022-.381a11.921 11.921 0 0 1 5.576-2.098c2.3-.258 4.522.078 6.63 1.066.64.303 1.246.65 1.818 1.054.056.045.135.079.157.169-.033.022-.056.067-.09.09z"
    />
    <path
      style={{
        fill: '#a4d18c',
      }}
      d="M156.25 19.55c-.2.5-.3 1-.5 1.5-.4-.2-.8-.4-.7-.8.2-.6.7-.7 1.2-.7z"
    />
  </svg>
);

export default SvgSensuGoLogo20X20;
