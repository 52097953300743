import { Grid } from 'uie/components';
import { PRIORITY, PRIORITY_OPTIONS } from 'library/common';
import { useMemo } from 'react';
import { MultiValue, OnChangeValue, SingleValue } from 'react-select';

import { SecondaryFiltersKeys, SelectOption } from '../../types';
import { FilterItemList } from './dropdown_container';

interface Props {
  selected: string[];
  isMulti?: boolean;
  updateFilter: (type: SecondaryFiltersKeys, data: string[]) => void;
}

const getPriorityOptions = (
  options: Array<{
    label: string;
    value: PRIORITY;
  }>,
) => {
  return options.map(item => ({
    label: item.value !== PRIORITY.Unset ? item.value : item.label,
    value: item.value,
  }));
};

function Priority(props: Props): JSX.Element {
  const priorityOptions = useMemo(() => {
    return getPriorityOptions(PRIORITY_OPTIONS);
  }, []);

  const [selectedOptions] = useMemo(() => {
    const sel = PRIORITY_OPTIONS.filter(t => {
      return props.selected.some(f => {
        return f === t.value;
      });
    });

    return [getPriorityOptions(sel)];
  }, [props.selected]);

  const handleChange = (newValue: OnChangeValue<SelectOption, boolean>) => {
    const arr: string[] = [];

    if (Array.isArray(newValue)) {
      const options = newValue as MultiValue<SelectOption>;
      options.map(o => {
        arr.push(o.value);
      });
    } else if (newValue) {
      const option = newValue as SingleValue<SelectOption>;
      if (option?.value) {
        arr.push(option.value);
      }
    }

    props.updateFilter('priority', arr);
  };

  const checkPrioritySelected = (opt: SelectOption) => {
    if (selectedOptions.length) {
      return !!selectedOptions.find(option => option.value === opt.value);
    } else if (!opt.value) {
      return true;
    }
    return false;
  };

  return (
    <Grid style={{ flexDirection: 'column', rowGap: '5px', overflowY: 'auto' }}>
      <FilterItemList
        itemList={priorityOptions}
        checkItemSelected={checkPrioritySelected}
        onItemSelect={opt => handleChange(opt)}
      />
    </Grid>
  );
}

export default Priority;
