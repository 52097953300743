import { createStandaloneToast } from '@chakra-ui/react';
import Axios from 'axios';
import { API } from 'core';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys, reactQueryConfig } from '../constant';
import { IUpdateJiraAccountProps } from '../../../../../../../core/interfaces/IJiraNew';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/service.reactqery';

const updateJiraAccount = ({
  accounts_name,
  jiraClientKey,
  teamsIds: team_ids,
  accountId,
  account_link,
  is_all_teams_configured,
}: IUpdateJiraAccountProps) => {
  return Axios.put(
    `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud/new/${accountId}`,
    {
      account_name: accounts_name,
      jira_client_key: jiraClientKey,
      team_ids,
      account_link,
      is_all_teams_configured,
    },
  );
};
const toast = createStandaloneToast();

const useUpdateJiraAccount = () => {
  const queryClient = useQueryClient();

  return useMutation(updateJiraAccount, {
    ...reactQueryConfig,
    onSuccess: () => {
      queryClient.refetchQueries([queryKeys.GETALLJIRACLOUDACCOUNTS]);
      queryClient.refetchQueries([queryKeys.GETALLJIRACLOUDACCOUNTSCONFIG]);
      reactQueryConfigSuccess('Jira Cloud Account Updated successfully!');
    },
    onError: (error: any) => {
      const description =
        error?.response?.data?.meta?.error_message || 'Failed to Updated Jira Cloud Account';
      reactQueryConfigError({ description: description, title: 'Update Jira Account' });
    },
  });
};
export { useUpdateJiraAccount };
