import Axios from 'axios';

/**
 * app generic helper services
 */
class HelperService {
  /**
   * for setting dial-codes for phone in onboarding users. defaults to +1(US)
   */
  public getIp = () =>
    fetch('https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572').then(response =>
      response.json(),
    );

  public getAppStoreVersion = () =>
    Axios.get<{ v: string }>('https://support.squadcast.com/docs/version.json');
}

export default HelperService;
