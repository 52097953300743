import { useToast } from 'library/atoms';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { WORKFLOWS_PATH } from 'views/main/routes/routes';
import { workflowDeleteApi } from '../../api';
import { workflowList } from '../../context/workflowListContext';

type UseDeleteWorkflowParams = {
  onSuccess?: () => void;
  onError?: () => void;
};

function useDeleteWorkflow(params?: UseDeleteWorkflowParams) {
  const history = useHistory();
  const toast = useToast();
  const queryClient = useQueryClient();

  const deleteWorkflow = useMutation(workflowDeleteApi, {
    retry: 1,
    onSuccess: () => {
      queryClient.invalidateQueries(workflowList, { refetchInactive: true });
      queryClient.invalidateQueries('workflowCount', { refetchInactive: true });

      toast({
        text: 'Workflow deleted successfully',
        status: 'success',
      });

      params?.onSuccess?.();
      history.push(WORKFLOWS_PATH);
    },
    onError: () => {
      toast({
        text: 'Workflow delete failed',
        status: 'error',
      });
      params?.onError?.();
    },
  });

  return deleteWorkflow;
}

export { useDeleteWorkflow };
