import { ContainerLoad, Grid, Label, Para, Theme } from 'uie/components';
import { IAppState } from 'core/interfaces/IAppState';
import { ProfileIcon, SquadIcon, UsernameIcon } from 'icons';
import PaginationBlock from 'library/molecules/Table/Pagination';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGetEntitiesData } from '../hooks';
import { useEntityListStore } from '../store';
import { EntityIdentifiers, EntityNames } from '../types';
import { generateEntityDetailPageUrl } from '../utils';
import { EntitiesPageHeader } from './Header';

export function EntitiesTable({
  entityCount,
  selectedType,
}: {
  entityCount: number;
  selectedType: keyof typeof EntityNames;
}) {
  const entityName = EntityNames[selectedType as keyof typeof EntityNames];

  const searchValue = useEntityListStore(state => state.search_term);
  const setSearchValue = useEntityListStore(state => state.setSearchTerm);

  const pageIndex = useEntityListStore(state => state.page_number);
  const setPageIndex = useEntityListStore(state => state.setPageNumber);

  const pageSize = useEntityListStore(state => state.page_size);
  const setPageSize = useEntityListStore(state => state.setPageSize);

  const parsedEntityOwner = useEntityListStore(state => state.entity_owner);

  const { data, isLoading } = useGetEntitiesData({
    payload: {
      type: EntityIdentifiers[selectedType as keyof typeof EntityIdentifiers],
      text_filter: searchValue.length ? searchValue : undefined,
      entity_owner: parsedEntityOwner?.map(option => option.value),
      page_limit: pageSize,
      page_number: pageIndex + 1,
    },
  });
  const isFiltersApplied = (data?.pagination.total_count ?? Number.MAX_SAFE_INTEGER) < entityCount;

  const { theme } = Theme;

  const selectedTeamId = useSelector((state: IAppState) => state.organization.selectedTeam.teamId);
  const history = useHistory();

  const paginationProps = {
    pageIndex,
    pageSize: pageSize,
    maxPageSize: data?.pagination.total_count ?? 0,
  };

  return (
    <Grid
      type="column"
      width="100%"
      style={{
        paddingLeft: '18px',
        paddingRight: '0px',
        paddingTop: '15px',
      }}
    >
      <EntitiesPageHeader
        searchPlaceholder={`Search for ${entityName}`}
        title={entityName}
        setSearchValue={value => {
          setSearchValue(value);
        }}
        searchValue={searchValue}
        count={entityCount}
      />
      {isFiltersApplied && (
        <Para
          style={{
            fontSize: '14px',
            color: '#757F8F',
            marginBottom: '12px',
            fontWeight: 600,
          }}
        >
          Showing {data?.pagination.total_count} out of {entityCount} {entityName.toLowerCase()}
        </Para>
      )}
      {data?.data?.length === 0 ? (
        <div>No Entities found</div>
      ) : (
        <>
          <Grid flexWidth={12} alignItems="center">
            <Grid flexWidth={6} alignItems="center">
              <Label
                style={{
                  fontSize: '14px',
                  lineHeight: '150%',
                }}
              >
                Name
              </Label>
            </Grid>
            <Grid flexWidth={6}>
              <Label
                style={{
                  fontSize: '14px',
                  lineHeight: '150%',
                }}
              >
                Owner
              </Label>
            </Grid>
          </Grid>

          {isLoading ? (
            <ContainerLoad isLoading={isLoading} />
          ) : (
            data?.data?.map((entity, i) => {
              const user = entity.owner_meta;
              const ownerType = entity.entity_owner.type;
              const icon =
                ownerType === 'squad' ? (
                  <SquadIcon
                    height={18}
                    width={18}
                    stroke={'#3D485E'}
                    style={{ marginRight: 5, marginTop: 4 }}
                  />
                ) : (
                  <ProfileIcon
                    height={18}
                    width={18}
                    stroke={'#3D485E'}
                    style={{ marginRight: 5, marginTop: 4 }}
                  />
                );
              const canGoToEntityPage = entity.team_id === selectedTeamId;
              return (
                <Grid
                  key={i}
                  flexWidth={12}
                  className="mb-5"
                  height="80px"
                  style={{
                    alignItems: 'center',
                    cursor: canGoToEntityPage ? 'pointer' : 'default',
                  }}
                  onClick={() => {
                    if (!canGoToEntityPage) return;
                    // navigate to entity page
                    const path = generateEntityDetailPageUrl(
                      selectedType as keyof typeof EntityIdentifiers,
                      entity.id,
                    );
                    if (!path) return;
                    history.push(path);
                  }}
                >
                  <Grid flexWidth={6}>
                    <Para
                      style={{
                        color: '#3D485E',
                        fontFamily: 'Mulish',
                        fontSize: '14.5px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '150%',
                      }}
                    >
                      {entity.name}
                    </Para>
                  </Grid>
                  <Grid
                    flexWidth={6}
                    style={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {icon}
                      <Para
                        fontSize={16}
                        style={{
                          maxWidth: '30ch',
                          lineHeight: '150%',
                          fontSize: '14.5px',
                          fontStyle: 'normal',
                          color: '#3D485E',
                        }}
                      >
                        {user?.name ?? 'Deleted'}
                      </Para>
                    </div>

                    {user?.userNameForDisplay && (
                      <Grid alignItems="flex-start" style={{ marginTop: '8px' }}>
                        <UsernameIcon
                          height={16}
                          width={16}
                          style={{ marginRight: 5, marginTop: 4 }}
                        />
                        <Para
                          fontSize={12}
                          color={theme.font.label}
                          style={{
                            maxWidth: '30ch',
                            lineHeight: '150%',
                            fontSize: '14.5px',
                            fontStyle: 'normal',
                            color: '#8890A2',
                          }}
                        >
                          {user?.userNameForDisplay ?? ''}
                        </Para>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              );
            })
          )}
          <PaginationBlock
            style={{
              position: 'sticky',
              bottom: '0px',
              paddingLeft: '0px',
            }}
            loading={isLoading}
            disabled={isLoading}
            nextPage={() => {
              setPageIndex(pageIndex + 1);
            }}
            previousPage={() => {
              setPageIndex(pageIndex - 1);
            }}
            canNextPage={
              pageIndex < Math.floor(paginationProps.maxPageSize / paginationProps.pageSize)
            }
            canPreviousPage={pageIndex !== 0}
            pageIndex={pageIndex}
            pageSize={paginationProps.pageSize}
            setPageSize={(size: number) => {
              setPageSize(size);
              setPageIndex(0);
            }}
            gotoPage={(pageNo: number) => {
              setPageIndex(pageNo);
            }}
            totalCount={paginationProps.maxPageSize}
            pageCount={Math.ceil(paginationProps.maxPageSize / paginationProps.pageSize)}
            rowsCount={data?.data.length ?? 0}
            maxPageSize={paginationProps.maxPageSize}
          />
        </>
      )}
    </Grid>
  );
}
