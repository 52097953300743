import React, { useMemo } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import CreatableReactSelect from 'react-select/creatable';

import { customStyles } from 'components/chakra/Select/customStyles';
import { isSameDayEvent } from '../../../helpers/helpers.event';

const StartAndEndTime = (props: {
  onChangeStartTime: (selectedValue: any) => void;
  onChangeEndTime: (selectedValue: any) => void;
  startTimeOptions: Array<Record<string, any>>;
  endTimeOptions: Array<Record<string, any>>;
  startTimevalue: unknown;
  endTimevalue: unknown;
}) => {
  const {
    onChangeStartTime,
    onChangeEndTime,
    startTimeOptions,
    endTimeOptions,
    startTimevalue,
    endTimevalue,
  } = props;
  const isSameDay = useMemo(
    () => isSameDayEvent((startTimevalue as any).value, (endTimevalue as any).value),
    [(startTimevalue as any).value, (endTimevalue as any).value],
  );
  return (
    <HStack spacing={0}>
      <CreatableReactSelect
        value={startTimevalue}
        isSingleTimeDropdown
        disableLeftPaddingInValueContainer
        hideContainerBorder
        closeMenuOnSelect
        hideDropdownArrow
        options={startTimeOptions}
        onChange={(selectedValue: any) => onChangeStartTime(selectedValue)}
        styles={{
          ...customStyles,
          menuPortal: provided => ({
            ...provided,
            zIndex: 41,
          }),
        }}
        menuPortalTarget={document.body}
      />
      <Text>-</Text>
      <CreatableReactSelect
        value={endTimevalue}
        isSingleTimeDropdown
        disableLeftPaddingInValueContainer
        hideContainerBorder
        closeMenuOnSelect
        hideDropdownArrow
        options={endTimeOptions}
        onChange={(selectedValue: any) => onChangeEndTime(selectedValue)}
        styles={{
          ...customStyles,
          menuPortal: provided => ({
            ...provided,
            zIndex: 41,
          }),
        }}
        menuPortalTarget={document.body}
      />
      <Text as="span" ml={1} textColor="gray.700">
        {!isSameDay && '+1'}
      </Text>
    </HStack>
  );
};

export default StartAndEndTime;
