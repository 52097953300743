import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="80" height="40" viewBox="0 0 80 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M66.9975 35.6481C66.5412 35.6481 66.0654 35.6157 65.6097 35.4861C62.7753 34.9025 60.2775 32.5357 58.5131 28.8399C56.7489 25.1439 56.0155 20.5727 56.3921 15.969C56.7489 11.3328 58.196 7.24791 60.4559 4.36249C62.7155 1.54193 65.5107 0.277533 68.3255 0.89352C70.1886 1.31498 71.9332 2.44969 73.42 4.29766C73.6974 4.62186 73.7371 5.27027 73.5386 5.69173C73.3406 6.14561 72.9442 6.21046 72.6864 5.88626C71.378 4.23281 69.8319 3.22779 68.1667 2.83874C65.6689 2.32001 63.1718 3.42231 61.1889 5.91867C59.1872 8.41504 57.8987 12.0786 57.6013 16.1635C56.9472 24.6252 60.6141 32.3737 65.7685 33.476C68.2657 33.9947 70.7635 32.8924 72.7457 30.396C74.748 27.8997 76.0363 24.2362 76.3338 20.1512C76.4529 18.595 76.4328 17.0389 76.2746 15.5151C76.2147 14.9963 76.4529 14.4452 76.77 14.3804C77.087 14.2832 77.4242 14.6722 77.4639 15.1909C77.6423 16.9092 77.6618 18.6598 77.5427 20.4429C77.186 25.0791 75.7389 29.164 73.4793 32.0494C71.6162 34.4486 69.356 35.6481 66.9975 35.6481Z"
      fill="url(#paint0_linear_7_182)"
    />
    <path
      d="M66.9975 35.6481C66.5412 35.6481 66.0654 35.6157 65.6097 35.4861C62.7753 34.9025 60.2775 32.5357 58.5131 28.8399C56.7489 25.1439 56.0155 20.5727 56.3921 15.969C56.7489 11.3328 58.196 7.24791 60.4559 4.36249C62.7155 1.54193 65.5107 0.277533 68.3255 0.89352C70.1886 1.31498 71.9332 2.44969 73.42 4.29766C73.6974 4.62186 73.7371 5.27027 73.5386 5.69173C73.3406 6.14561 72.9442 6.21046 72.6864 5.88626C71.378 4.23281 69.8319 3.22779 68.1667 2.83874C65.6689 2.32001 63.1718 3.42231 61.1889 5.91867C59.1872 8.41504 57.8987 12.0786 57.6013 16.1635C56.9472 24.6252 60.6141 32.3737 65.7685 33.476C68.2657 33.9947 70.7635 32.8924 72.7457 30.396C74.748 27.8997 76.0363 24.2362 76.3338 20.1512C76.4529 18.595 76.4328 17.0389 76.2746 15.5151C76.2147 14.9963 76.4529 14.4452 76.77 14.3804C77.087 14.2832 77.4242 14.6722 77.4639 15.1909C77.6423 16.9092 77.6618 18.6598 77.5427 20.4429C77.186 25.0791 75.7389 29.164 73.4793 32.0494C71.6162 34.4486 69.356 35.6481 66.9975 35.6481Z"
      fill="url(#paint1_linear_7_182)"
    />
    <path
      d="M76.4529 15.7098C76.3937 15.7098 76.3741 15.7098 76.3344 15.7098C75.8384 15.6774 75.3827 15.2883 75.0657 14.6723C74.7486 14.0563 74.5703 13.2782 74.61 12.5002C74.6692 10.8468 75.5415 9.54992 76.572 9.6796C77.583 9.77687 78.3563 11.2034 78.2965 12.8892C78.2769 13.6997 78.0393 14.4454 77.6624 14.9641C77.3252 15.4504 76.9092 15.7098 76.4529 15.7098ZM75.8586 12.6299C75.8586 12.9217 75.9178 13.1485 76.0174 13.3755C76.1164 13.57 76.2746 13.6997 76.4334 13.6997C76.5922 13.6997 76.7504 13.6025 76.8891 13.4403C77.0082 13.2782 77.0876 13.0189 77.0876 12.7595C77.1072 12.1759 76.8298 11.7221 76.5128 11.6896C76.1561 11.6248 75.8586 12.0787 75.8586 12.6299Z"
      fill="#53BCE6"
    />
    <path
      d="M74.0941 15.8069C73.9353 15.0289 73.7172 14.3805 73.4399 13.7969C73.1625 13.2133 72.8058 12.8243 72.4289 12.5325C72.0325 12.2407 71.5963 12.1111 71.1205 12.1111C70.6446 12.1111 70.1889 12.2407 69.7925 12.5325C69.396 12.7919 69.0588 13.1809 68.7619 13.732C68.484 14.2508 68.2659 14.9316 68.1077 15.7421C67.9489 16.5202 67.8896 17.4604 67.8896 18.5303C67.8896 19.5029 67.9691 20.3782 68.1077 21.1563C68.2659 21.9344 68.484 22.6476 68.7619 23.1664C69.0393 23.7499 69.396 24.139 69.7925 24.4308C70.1889 24.7225 70.6251 24.8522 71.1009 24.8522C71.5768 24.8522 72.0129 24.7225 72.4094 24.4308C72.8058 24.139 73.1424 23.7499 73.4399 23.1988C73.7172 22.6801 73.9353 21.9668 74.0941 21.1887C74.2523 20.4107 74.3122 19.5029 74.3122 18.5303C74.3519 17.4604 74.2523 16.5527 74.0941 15.8069ZM71.1205 13.959C71.5371 13.959 71.9133 14.1535 72.1913 14.5426C72.4882 14.8992 72.7264 15.4504 72.8651 16.0987C73.0037 16.7472 73.0831 17.5252 73.1228 18.4006C73.1228 19.3084 73.0239 20.0864 72.8651 20.7673C72.7062 21.4481 72.4882 21.9993 72.1913 22.3559C71.8938 22.7125 71.5371 22.9394 71.1205 22.9394C70.7045 22.9394 70.3477 22.7449 70.0502 22.3559C69.7528 21.9993 69.5151 21.4481 69.3765 20.7997C69.2176 20.1189 69.1584 19.3084 69.1584 18.4654C69.1584 17.5577 69.2372 16.7795 69.3765 16.1312C69.5347 15.4828 69.7528 14.9316 70.0502 14.575C70.308 14.186 70.6843 13.959 71.1205 13.959Z"
      fill="url(#paint2_linear_7_182)"
    />
    <path
      d="M67.8898 17.3632C67.5331 17.039 67.1959 16.8768 66.7799 16.8768C66.3236 16.8768 65.9473 17.039 65.6297 17.3307L67.4341 12.1111H66.0065C65.4513 13.6997 64.3615 17.039 64.1434 17.7846C63.6474 19.7622 63.7867 21.4481 64.0836 22.6153C64.2821 23.3609 64.6388 23.8796 65.0353 24.3011C65.4317 24.6577 65.8874 24.8847 66.3834 24.8847C66.8391 24.8847 67.2551 24.7225 67.6516 24.3984C68.048 24.0742 68.3852 23.5879 68.6033 22.9719C68.8611 22.3559 68.9802 21.6102 68.9802 20.7997C68.9802 20.054 68.8806 19.3732 68.6625 18.7896C68.5043 18.2061 68.227 17.7198 67.8898 17.3632ZM67.6516 21.9993C67.5331 22.3559 67.3547 22.6153 67.1165 22.8098C66.8984 23.0043 66.6406 23.1016 66.3834 23.1016C66.1055 23.1016 65.8874 22.9719 65.6498 22.7773C65.4317 22.5828 65.2534 22.291 65.1147 21.9668C64.9956 21.6426 64.9162 21.2212 64.9162 20.8645V20.5403C64.9162 20.1837 64.9956 19.7947 65.1147 19.5029C65.2332 19.1787 65.4122 18.9193 65.6498 18.7572C65.8679 18.5951 66.1055 18.4654 66.3834 18.4654C66.6608 18.4654 66.8788 18.5626 67.1165 18.7572C67.3345 18.9193 67.5129 19.2111 67.6516 19.5353C67.7707 19.8595 67.8501 20.281 67.8501 20.7349C67.8501 21.2212 67.7908 21.6426 67.6516 21.9993Z"
      fill="url(#paint3_linear_7_182)"
    />
    <path
      d="M64.0038 17.4604C63.7858 17.1687 63.5286 16.9417 63.2507 16.8444C63.0723 16.7472 62.8939 16.7147 62.7155 16.7147L64.44 12.1111H60.3369V14.0238H62.6361L60.9911 18.2385L60.9709 18.2709L61.0705 18.4006H61.0901C61.2092 18.3033 61.3478 18.2709 61.4865 18.2061C61.6453 18.1736 61.8035 18.1088 62.0418 18.1088C62.4578 18.1088 62.8145 18.3033 63.0723 18.6924C63.3301 19.1138 63.4492 19.6326 63.4492 20.3458C63.4492 20.8645 63.3698 21.3185 63.2507 21.7075C63.1315 22.0641 62.9336 22.3883 62.696 22.6153C62.4779 22.8098 62.2201 22.9394 61.9422 22.9394C61.6649 22.9394 61.4071 22.8098 61.1695 22.6153C60.9117 22.4208 60.7132 22.1289 60.5946 21.8696L60.5745 21.7723L59.9404 23.4257L59.96 23.4582C60.2178 23.8796 60.5152 24.2039 60.8921 24.4308C61.2684 24.6902 61.6453 24.7874 62.0021 24.7874C62.4975 24.7874 62.9532 24.6253 63.3496 24.2687C63.7461 23.912 64.1034 23.3934 64.3215 22.7449C64.5787 22.0965 64.6978 21.3185 64.6978 20.443C64.6978 19.7622 64.6184 19.1463 64.4998 18.6276C64.3807 18.1413 64.2024 17.7846 64.0038 17.4604Z"
      fill="url(#paint4_linear_7_182)"
    />
    <path
      d="M3.30923 36.3932H1.76367V9.55981C3.07145 8.87925 4.35942 8.55518 5.62757 8.55518C6.55883 8.55518 7.39107 8.78203 8.14402 9.23573C8.89702 9.68943 9.55089 10.3376 10.0661 11.2125C10.6011 12.0551 11.0172 13.0598 11.2946 14.1941C11.572 15.3283 11.7107 16.6246 11.7107 17.9857C11.7107 19.6385 11.5522 21.0321 11.1955 22.2311C10.8389 23.3978 10.4029 24.37 9.82828 25.1478C9.25365 25.8931 8.61963 26.4765 7.88648 26.833C7.17311 27.1894 6.42016 27.3839 5.66719 27.3839C5.25108 27.3839 4.85478 27.3191 4.45849 27.2218C4.06219 27.1246 3.68571 26.995 3.2696 26.833L3.30923 36.3932ZM9.92736 17.9533C9.92736 15.8144 9.55089 14.0968 8.83759 12.7681C8.10443 11.4394 7.07403 10.7912 5.74645 10.7912C5.33034 10.7912 4.91423 10.8237 4.51794 10.921C4.12164 11.0181 3.70553 11.1477 3.30923 11.3746V24.4672C3.68571 24.694 4.04238 24.8237 4.43868 24.9857C4.83497 25.1153 5.19164 25.2126 5.54831 25.2126C6.89572 25.2126 7.94591 24.5644 8.73851 23.333C9.5311 22.0366 9.92736 20.2543 9.92736 17.9533Z"
      fill="black"
    />
    <path d="M14.8809 27.2217V1.3606H16.466V27.2217H14.8809Z" fill="black" />
    <path
      d="M29.366 24.5319C28.8311 25.4717 28.2168 26.1522 27.5431 26.6384C26.8694 27.1245 26.0174 27.3513 25.0266 27.3513C24.1151 27.3513 23.3226 27.1245 22.6686 26.6059C21.995 26.0874 21.4401 25.4393 20.9646 24.5643C20.5287 23.7217 20.172 22.717 19.954 21.6152C19.7361 20.5134 19.6172 19.3466 19.6172 18.1152C19.6172 16.4948 19.7559 15.1013 20.0333 13.8698C20.3107 12.6707 20.6872 11.6985 21.1429 10.9207C21.5986 10.143 22.1535 9.55962 22.7677 9.17074C23.382 8.78184 24.0359 8.5874 24.7294 8.5874C26.3938 8.5874 27.6223 9.39759 28.4744 11.0828C29.3264 12.7356 29.7227 15.1661 29.7029 18.3421H21.242C21.2816 20.4161 21.6581 22.1013 22.3516 23.3328C23.0253 24.5642 23.9764 25.18 25.1851 25.18C25.8588 25.18 26.4335 24.9855 26.9883 24.6291C27.5233 24.2726 28.0187 23.6893 28.4546 22.9115L29.366 24.5319ZM28.0979 16.1383C28.0979 15.393 28.0187 14.6476 27.8799 13.9995C27.7412 13.3189 27.5233 12.768 27.2657 12.3143C27.0081 11.8606 26.6514 11.4717 26.2353 11.2124C25.8192 10.9532 25.3239 10.7911 24.769 10.7911C24.1944 10.7911 23.699 10.9532 23.2829 11.2124C22.8668 11.4717 22.5498 11.8606 22.312 12.3143C22.0742 12.768 21.8562 13.3189 21.6383 13.9995C21.4401 14.68 21.3015 15.4253 21.242 16.2031L28.0979 16.1383Z"
      fill="black"
    />
    <path
      d="M40.0258 13.1246C39.6295 12.3144 39.2134 11.6987 38.7577 11.3422C38.3019 10.9533 37.7669 10.7589 37.1329 10.7589C36.3204 10.7589 35.7656 10.9857 35.4288 11.4394C35.0919 11.8931 34.9532 12.5089 34.9532 13.2219C34.9532 13.708 35.0325 14.1293 35.1712 14.4858C35.3099 14.8098 35.5278 15.1338 35.7656 15.3607C36.0034 15.5876 36.3006 15.8145 36.6177 16.0089C36.9347 16.2033 37.2914 16.3654 37.6084 16.5598C38.0245 16.7867 38.4604 17.0135 38.8766 17.2728C39.2927 17.532 39.6889 17.8885 40.0456 18.3098C40.4023 18.7311 40.6995 19.2496 40.9174 19.8654C41.1355 20.4811 41.2543 21.2265 41.2543 22.1339C41.2543 22.9765 41.1355 23.7218 40.9174 24.4024C40.6995 25.083 40.4023 25.6339 40.006 26.0875C39.6295 26.5413 39.1737 26.8978 38.6784 27.1246C38.1632 27.3514 37.6282 27.481 37.0338 27.481C36.1223 27.481 35.3297 27.2543 34.656 26.7682C33.9625 26.2821 33.3086 25.5043 32.6943 24.37L33.9228 22.5876C34.7352 24.3052 35.8053 25.1801 37.113 25.1801C37.9651 25.1801 38.5992 24.9209 38.9954 24.4024C39.3918 23.8839 39.6097 23.2681 39.6097 22.5227C39.6097 21.9394 39.5304 21.4857 39.3918 21.0968C39.253 20.7079 39.0351 20.4163 38.7775 20.1246C38.5199 19.8329 38.2226 19.6385 37.866 19.4441C37.5291 19.2496 37.1724 19.0876 36.796 18.8931C36.3798 18.6663 35.9439 18.4719 35.5477 18.2125C35.1315 17.9857 34.7551 17.6293 34.4182 17.2728C34.0814 16.8838 33.804 16.3977 33.6058 15.8145C33.3878 15.1986 33.2888 14.4858 33.2888 13.5459C33.2888 11.9255 33.6652 10.6616 34.3786 9.75423C35.0919 8.87924 36.043 8.42554 37.1923 8.42554C38.0047 8.42554 38.7378 8.65238 39.3719 9.10609C40.0258 9.55979 40.6203 10.3052 41.1949 11.4394L40.0258 13.157V13.1246Z"
      fill="black"
    />
    <path
      d="M44.2266 27.2218V1.39307H45.8117V27.1893L44.2266 27.2218ZM45.7919 17.694L51.2014 8.74955H53.3216L47.7734 17.6291L53.3017 27.2218H51.1221L45.7919 17.694Z"
      fill="black"
    />
    <path d="M20.3708 35.6481H10.7412V33.2407H20.3708V35.6481Z" fill="#53BAE4" />
    <defs>
      <linearGradient
        id="paint0_linear_7_182"
        x1="60.076"
        y1="7.2233"
        x2="77.2441"
        y2="17.5115"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AA6CC" />
        <stop offset="1" stopColor="#53BCE6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7_182"
        x1="66.9821"
        y1="6.8941"
        x2="66.9821"
        y2="12.8352"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AA6CC" />
        <stop offset="1" stopColor="#4AA6CC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_7_182"
        x1="68.6695"
        y1="15.2405"
        x2="74.3046"
        y2="18.0301"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AA6CC" />
        <stop offset="0.2069" stopColor="#4DAED5" />
        <stop offset="0.6101" stopColor="#52B8E2" />
        <stop offset="1" stopColor="#53BCE6" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_7_182"
        x1="64.1452"
        y1="15.9809"
        x2="69.0379"
        y2="17.9324"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AA6CC" />
        <stop offset="0.2069" stopColor="#4DAED5" />
        <stop offset="0.6101" stopColor="#52B8E2" />
        <stop offset="1" stopColor="#53BCE6" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_7_182"
        x1="60.245"
        y1="15.9273"
        x2="64.8272"
        y2="17.6154"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AA6CC" />
        <stop offset="0.2069" stopColor="#4DAED5" />
        <stop offset="0.6101" stopColor="#52B8E2" />
        <stop offset="1" stopColor="#53BCE6" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgComponent;
