import {
  CardBlock,
  CellBlock,
  Checkbox,
  DialogModalFrame,
  DropDown,
  DropDownOptionShell,
  ErrorBlock,
  FocusBlock,
  FormBlock,
  Grid,
  Heading,
  IconButton,
  Label,
  Para,
  SelectBox,
  SpinLoader,
  TextButton,
  Theme,
  Tooltip,
} from 'uie/components';
import RouteLeavingGuard from 'components/UnsavedChangeNavigation';
import { getUserAccessStore } from 'core/userAccess/UserAccessStore';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import React from 'react';
import ConfirmModal from '../../../../../../../components/confirm.modal';
import { ReduceEntity } from '../../../../../../../core/interfaces/IHelpers';
import {
  ITeamMember,
  ITeamRoleAbilities,
  ITeamRoles,
} from '../../../../../../../core/interfaces/ITeams';
import { IUsersOfOrganization } from '../../../../../../../core/interfaces/IUserData';
import {
  AlertTriangleIcon,
  DeleteIcon,
  MoreVerticalIcon,
  ProfileIcon,
  UsernameIcon,
  NotificationGreyEmail,
} from '../../../../../../../icons';
import { Note } from '../../../shared/note';
import { OrganizationSettingsTeamsRenderMembers } from './';
import UserDependencies from './userDependencies';
import { truncate } from 'core/helpers/stringUtils';

function editMember(
  this: OrganizationSettingsTeamsRenderMembers,
  member: ITeamMember,
  _oUsers: ReduceEntity<
    IUsersOfOrganization,
    'id' | 'email_verified' | 'phone_verified' | 'email' | 'role',
    'name'
  >,
  _tRoles: ReduceEntity<ITeamRoles, 'id' | 'name' | 'default' | 'abilities'>,
  index: number,
) {
  const { theme } = Theme;
  const { searchMap, newMember, errors, editMember } = this.state;

  const users = Object.entries(_oUsers)
    .filter(([id, u]) => {
      const filter =
        (u?.name ?? 'Deleted')
          .toLowerCase()
          .includes(searchMap[index] !== undefined ? searchMap[index].toLowerCase() : '') &&
        this.props.team.members.every(m => m.user_id !== id);

      if (newMember.length === 0) {
        return filter;
      }

      return filter && newMember.every(m => m.user_id !== id);
    })
    .sort((user1, user2) => {
      const name1 = String(user1[1].name).toUpperCase();
      const name2 = String(user2[1].name).toUpperCase();
      return name1.localeCompare(name2);
    });

  const user = _oUsers[editMember[0]?.user_id ?? ''] || {};
  const roles = Object.entries(_tRoles).reduce((c: any, [_, role]) => {
    const readOnlyRole = Object.values(role.abilities as ITeamRoleAbilities).every(operation =>
      Object.keys(operation).every(o => o.includes('read')),
    );

    if ((_oUsers[member.user_id]?.role ?? '') === 'stakeholder') {
      if (readOnlyRole) {
        c.push(role);
      }
      return c;
    }
    c.push(role);
    return c;
  }, []) as Pick<ITeamRoles, 'id' | 'name' | 'default'>[];

  return (
    <Grid key={index} className="mb-10 pb-10" alignItems="flex-start" width="700px">
      <Grid type="column" flexWidth={5}>
        {editMember.length > 0 && (
          <div>
            <Para fontSize={16}>{user?.name ?? 'Deleted'}</Para>
            <Tooltip
              label="User has not verified email"
              disabled={!!user?.email_verified}
              offset={{
                top: '-70px',
              }}
            >
              <Grid alignItems="center">
                <Para fontSize={12} color={theme.font.label}>
                  {user?.email ?? 'Deleted'}
                </Para>
                {!user?.email_verified && (
                  <AlertTriangleIcon
                    height={10}
                    width={10}
                    className="ml-5"
                    stroke={theme.danger.default}
                  />
                )}
              </Grid>
            </Tooltip>
            <Para fontSize={12} color={theme.font.label}>
              {((user?.role as string) ?? '').replace('_', ' ')}
            </Para>
          </div>
        )}
        {editMember.length === 0 && (
          <div className="mt-5 organization_settings_teams_members__select_drop">
            <SelectBox
              hook={
                <Para className="global--text-clip" fontSize={16}>
                  {_oUsers[member.user_id]?.name ?? 'Select member'}
                </Para>
              }
              error={
                !!(
                  errors.error__new_member_role &&
                  errors.error__new_member_user &&
                  member.user_id !== ''
                )
              }
              searchHookProps={{
                value: searchMap[index] || '',
                height: '24px',
                fontSize: '16px',
                padding: '4px',
                placeholder: 'Search users',
                onChange: this.onSearchMembers(index.toString()),
              }}
              onValueChange={this.onNewMemberSelect(index)}
              height="auto"
              maxHeight="200px"
              width="250px"
              maxWidth="250px"
            >
              {users.map(([id, u], i) => (
                <FocusBlock className="global--text-clip" key={i} value={id}>
                  <Para fontSize={14}>{u.name}</Para>
                </FocusBlock>
              ))}
              {users.length === 0 ? (
                <FocusBlock key={1} value={-1} disabled={true} isSelected={false}>
                  <Para fontSize={14}>None found!</Para>
                </FocusBlock>
              ) : (
                <></>
              )}
            </SelectBox>
            <Tooltip
              label="User has not verified email"
              disabled={!!(member.user_id === '' || _oUsers[member.user_id]?.email_verified)}
              offset={{
                top: '-70px',
              }}
            >
              <Grid alignItems="center">
                <Para fontSize={12} color={theme.font.label}>
                  {_oUsers[member.user_id]?.email ?? ''}
                </Para>
                {member.user_id !== '' && !_oUsers[member.user_id]?.email_verified && (
                  <AlertTriangleIcon
                    height={10}
                    width={10}
                    className="ml-5"
                    stroke={theme.danger.default}
                  />
                )}
              </Grid>
            </Tooltip>
            <Para fontSize={12} color={theme.font.label}>
              {((_oUsers[member.user_id]?.role as string) ?? '').replace('_', ' ')}
              {(_oUsers[member.user_id]?.role ?? '') === 'stakeholder' &&
                ' · can only be assigned to read-only roles'}
            </Para>
          </div>
        )}
      </Grid>
      <Grid type="column" flexWidth={6}>
        <Grid alignItems="center" flexWrap="wrap" style={{ marginTop: -5 }}>
          {roles.map((role, i) => {
            return (
              <CellBlock key={i} className="mr-10 mt-10">
                <label>
                  <Grid alignItems="center">
                    <Para fontSize={14} className="mr-10">
                      {role.name}
                    </Para>
                    <Checkbox
                      base="14px"
                      style={{ marginTop: 4 }}
                      onChange={
                        editMember.length === 0
                          ? this.onNewMemberRoleSelect(index, role.id)
                          : this.onMemberUpdateRole(role.id)
                      }
                      checked={member.role_ids.includes(role.id)}
                    />
                  </Grid>
                </label>
              </CellBlock>
            );
          })}
          {roles.length === 0 && (
            <Para fontSize={12} className="mt-10" color={theme.font.label}>
              No roles available
              {(_oUsers[member.user_id]?.role ?? '') === 'stakeholder' &&
                ' · create a role with read-only property to assign a stakeholder'}
            </Para>
          )}
        </Grid>
      </Grid>
      <Grid flexWidth={1} alignContent="flex-start" justifyContent="flex-end">
        {member.user_id !== '' && (
          <IconButton
            onClick={
              editMember.length > 0
                ? this.onToggleDelete(editMember[0].user_id)
                : this.onDeleteNewMember(index)
            }
          >
            <DeleteIcon height={16} width={16} color={theme.danger.default} />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}

export function render(this: OrganizationSettingsTeamsRenderMembers) {
  const { theme } = Theme;
  const { team, organization, search } = this.props;
  const {
    deleteMemberId,
    errors,
    networkState,
    newMember,
    editMemberId,
    deleteState,
    dependencies,
  } = this.state;

  const _uA = this.props.aclStore;
  const hasUpdate = _uA.hasUpdateAccess('teams', team.id);
  const hasDelete = _uA.hasDeleteAccess('teams', team.id);

  const _oUsers: ReduceEntity<
    IUsersOfOrganization,
    'id' | 'email_verified' | 'phone_verified' | 'email' | 'role' | 'username_for_display',
    'name'
  > = organization.users.u.reduce(
    (
      c: ReduceEntity<
        IUsersOfOrganization,
        'id' | 'email_verified' | 'phone_verified' | 'email' | 'role' | 'username_for_display',
        'name'
      >,
      u,
    ) => {
      c[u.id] = {
        id: u.id,
        name: `${u?.first_name ?? 'Deleted'} ${u?.last_name ?? ''}`,
        email: u.email,
        username_for_display: u.username_for_display,
        role: u.role,
        email_verified: u.email_verified,
        phone_verified: u.phone_verified,
      };

      return c;
    },
    {},
  );

  const _tRoles = team.roles.reduce(
    (c: ReduceEntity<ITeamRoles, 'id' | 'name' | 'default' | 'abilities'>, r) => {
      c[r.id] = {
        id: r.id,
        name: r.name,
        default: r.default,
        abilities: r.abilities,
      };
      return c;
    },
    {},
  );

  const filteredMembers = team.members.filter(m =>
    (_oUsers[m.user_id]?.name ?? '').toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <div>
      <div>
        {team.slug === 'default-team' && (
          <div>
            <Para
              fontSize={12}
              color={theme.font.label}
              style={{ paddingBottom: 10, paddingLeft: 8 }}
            >
              All users in the organization will be a part of the default team. The default team
              cannot be deleted.
            </Para>
          </div>
        )}
        {team.members.length === 0 && (
          <div>
            <Para fontSize={16} color={theme.font.label}>
              No members added
            </Para>
          </div>
        )}
        {team.members.length > 0 && filteredMembers.length === 0 && (
          <div>
            <Para fontSize={16} color={theme.font.label}>
              Search did not match any member
            </Para>
          </div>
        )}
        {team.members.length > 0 && filteredMembers.length > 0 && (
          <div>
            <Para
              fontSize={12}
              color={theme.font.label}
              style={{ fontStyle: 'italic', paddingBottom: 10, paddingLeft: 8 }}
            >
              {`${filteredMembers.length} Members in this team`}
            </Para>
          </div>
        )}
        {filteredMembers.length > 0 && (
          <Grid type="column" width="700px">
            <Grid flexWidth={12} alignItems="center" style={{ paddingLeft: 8 }}>
              <Grid flexWidth={5} alignItems="center">
                <Label fontWeight={500}>Name</Label>
              </Grid>
              <Grid flexWidth={6}>
                <Label fontWeight={500}>Team roles</Label>
              </Grid>
              <Grid flexWidth={1} />
            </Grid>
            <div className="mt-5 organization_settings_teams_members__list">
              {filteredMembers.map((m, i) => {
                const user = _oUsers[m.user_id];

                if (editMemberId === m.user_id) {
                  return (
                    <CardBlock
                      className="cardBlock mb-10"
                      borderStyle="sharp"
                      padding="7px"
                      animateOnHover
                      key={i}
                    >
                      <Grid type="column" width="700px">
                        <FormBlock onFormSubmit={this.onSave}>
                          {editMember.call(this, m, _oUsers, _tRoles, -1)}
                        </FormBlock>
                      </Grid>

                      <div className="mt-10">
                        <ErrorBlock>
                          {errors.error__new_member_role ||
                            errors.error__new_member_user ||
                            errors.network_err ||
                            errors.error__team_owner ||
                            errors.error__edit_member_role}
                        </ErrorBlock>
                        <Grid>
                          <Grid alignItems="center">
                            <TextButton
                              onClick={this.onSave}
                              color={theme.primary.default}
                              disabled={networkState === 'save' || !(newMember.length - 1)}
                              height="26px"
                            >
                              <Grid alignItems="center">
                                <Para color={theme.font.white}>Save</Para>
                                {networkState === 'save' && (
                                  <SpinLoader
                                    className="ml-10"
                                    color={theme.shades.white}
                                    base="8px"
                                  />
                                )}
                              </Grid>
                            </TextButton>
                            <TextButton
                              onClick={this.onCancelAddNewOrEdit}
                              buttonType="ghost"
                              className="ml-10"
                              disabled={networkState === 'save'}
                            >
                              <Para color={theme.font.default}>Cancel</Para>
                            </TextButton>
                          </Grid>
                        </Grid>
                      </div>
                    </CardBlock>
                  );
                }

                return (
                  <Grid
                    key={i}
                    flexWidth={12}
                    alignItems="flex-start"
                    className="mb-5"
                    style={{ padding: 8 }}
                  >
                    <Grid flexWidth={5}>
                      <div>
                        <Para fontSize={16} style={{ maxWidth: '30ch' }}>
                          {user?.name ?? 'Deleted'}
                        </Para>
                        <Grid alignItems="flex-start">
                          <NotificationGreyEmail
                            height={12}
                            width={12}
                            style={{ marginRight: 5, marginTop: 4 }}
                          />
                          <Para fontSize={12} color={theme.font.label} style={{ maxWidth: '30ch' }}>
                            {user?.email ?? 'Deleted'}
                          </Para>
                          {!user?.email_verified && (
                            <AlertTriangleIcon
                              height={10}
                              width={10}
                              className="ml-5"
                              stroke={theme.danger.default}
                            />
                          )}
                        </Grid>
                        {user?.username_for_display && (
                          <Grid alignItems="flex-start">
                            <UsernameIcon
                              height={12}
                              width={12}
                              style={{ marginRight: 5, marginTop: 4 }}
                            />
                            <Para
                              fontSize={12}
                              color={theme.font.label}
                              style={{ maxWidth: '30ch' }}
                            >
                              {user?.username_for_display ?? ''}
                            </Para>
                          </Grid>
                        )}
                        <Grid alignItems="flex-start">
                          <ProfileIcon
                            height={12}
                            width={12}
                            stroke={theme.shades.grey}
                            style={{ marginRight: 5, marginTop: 4 }}
                          />
                          <Para fontSize={12} color={theme.font.label}>
                            {((user?.role as string) ?? '').replace('_', ' ')}
                          </Para>
                        </Grid>
                      </div>
                    </Grid>
                    {!!m.role_ids && (
                      <Grid flexWidth={6}>
                        {m.role_ids.length === 0 && (
                          <Para fontSize={12} color={theme.font.disabled}>
                            No roles assigned
                          </Para>
                        )}
                        <Grid alignItems="center" flexWrap="wrap" style={{ marginTop: -10 }}>
                          {m.role_ids.map((r, j) => {
                            return (
                              <CellBlock key={j} className="mr-10 mt-10">
                                <Para fontSize={14}>{_tRoles[r]?.name ?? 'delete'}</Para>
                              </CellBlock>
                            );
                          })}
                        </Grid>
                      </Grid>
                    )}
                    <Grid flexWidth={1} justifyContent="flex-end">
                      {editMemberId === '' && newMember.length === 0 && (
                        <DropDown
                          hook={
                            <IconButton>
                              <MoreVerticalIcon
                                height="14px"
                                width="14px"
                                stroke={theme.shades.cement}
                              />
                            </IconButton>
                          }
                          width="100px"
                          maxWidth="100px"
                          height="auto"
                        >
                          <div>
                            <NoPermissionTooltip isDisabled={hasUpdate}>
                              <DropDownOptionShell
                                padding="8px"
                                onClick={this.onEdit(m.user_id)}
                                disabled={!hasUpdate}
                              >
                                <Para>Edit</Para>
                              </DropDownOptionShell>
                            </NoPermissionTooltip>
                            <NoPermissionTooltip isDisabled={hasDelete}>
                              <DropDownOptionShell
                                padding="8px"
                                onClick={this.onToggleDelete(m.user_id)}
                                disabled={!hasDelete}
                              >
                                <Para color={theme.danger.default}>Remove</Para>
                              </DropDownOptionShell>
                            </NoPermissionTooltip>
                          </div>
                        </DropDown>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          </Grid>
        )}
        {newMember.length > 0 && (
          <CardBlock borderStyle="sharp" padding="7px" animateOnHover>
            <Grid type="column" width="700px">
              <Heading fontSize={16} height={24}>
                New members
              </Heading>
              <Grid flexWidth={12} alignItems="center" className="mt-10">
                <Grid flexWidth={5} alignItems="center">
                  <Label fontWeight={500}>Name</Label>
                </Grid>
                <Grid flexWidth={6}>
                  <Label fontWeight={500}>Team roles</Label>
                </Grid>
                <Grid flexWidth={1} />
              </Grid>
              <FormBlock
                onFormSubmit={this.onSave}
                className="mt-10 organization_settings_teams_members__list"
              >
                {newMember.map((member, i) => editMember.call(this, member, _oUsers, _tRoles, i))}
              </FormBlock>
              <div className="mt-10">
                <ErrorBlock>
                  {errors.error__new_member_role ||
                    errors.error__new_member_user ||
                    errors.network_err}
                </ErrorBlock>
                <Grid>
                  <Grid alignItems="center">
                    <TextButton
                      onClick={this.onSave}
                      color={theme.primary.default}
                      disabled={networkState === 'save' || !(newMember.length - 1)}
                      height="26px"
                    >
                      <Grid alignItems="center">
                        <Para color={theme.font.white}>Add {newMember.length - 1} members</Para>
                        {networkState === 'save' && (
                          <SpinLoader className="ml-10" color={theme.shades.white} base="8px" />
                        )}
                      </Grid>
                    </TextButton>
                    <TextButton
                      onClick={this.onCancelAddNewOrEdit}
                      buttonType="ghost"
                      className="ml-10"
                      disabled={networkState === 'save'}
                    >
                      <Para color={theme.font.default}>Cancel</Para>
                    </TextButton>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </CardBlock>
        )}
        <div className="mt-20 pb-200">
          {newMember.length === 0 && editMemberId === '' && (
            <NoPermissionTooltip isDisabled={hasUpdate}>
              <TextButton onClick={this.onAddNewMember} disabled={!hasUpdate}>
                <Para color={theme.font.white}>Add new members</Para>
              </TextButton>
            </NoPermissionTooltip>
          )}
        </div>
      </div>
      <DialogModalFrame
        id="organization_team_settings__delete_members"
        onClose={this.onToggleDelete('')}
        width="400px"
      >
        {deleteMemberId.length > 0 && deleteState === 'confirm' && (
          <ConfirmModal
            displayText={
              <div style={{ wordBreak: 'break-word' }}>
                <Para>
                  Remove{' '}
                  <span className="font-bold">{_oUsers[deleteMemberId]?.name ?? 'Removed'}</span>{' '}
                  from <span className="font-bold">{team.name}</span> team?
                </Para>
                {_oUsers[deleteMemberId]?.role !== 'stakeholder' && <Note isConfirmationModal />}
                <div className="mt-5">
                  <ErrorBlock>
                    {errors.network_err || errors.error__team_members || errors.error__team_owner}
                  </ErrorBlock>
                </div>
              </div>
            }
            buttonText={`Remove ${truncate(_oUsers[deleteMemberId]?.name, 10) ?? ''}`}
            primaryButtonColor={theme.danger.default}
            isBusy={networkState === 'save'}
            cancelText="No"
            hide={this.onHideConfirmDelete}
          />
        )}
      </DialogModalFrame>

      <DialogModalFrame
        id="organization_team_settings__delete_member_conflicts"
        onClose={this.closeReplaceModal}
        width="500px"
      >
        {deleteMemberId.length > 0 && deleteState === 'conflict' && (
          <UserDependencies
            team={team}
            oldUser={_oUsers[deleteMemberId]}
            dependencies={dependencies!}
            updateChildInRequest={this.props.updateChildInRequest}
            hide={this.closeReplaceModal}
          />
        )}
        {deleteState === 'checking_conflicts' && (
          <Grid style={{ margin: '24px 0' }}>
            <Para fontSize={18}>Checking For Conflicts...</Para>
          </Grid>
        )}
      </DialogModalFrame>
      <RouteLeavingGuard
        when={this.state.hasUnsavedChange}
        shouldBlockNavigation={location => {
          if (this.state.hasUnsavedChange) return true;
          return false;
        }}
        navigate={path => this.props.history.push(path)}
      />
    </div>
  );
}
