import React, { useMemo } from 'react';
import moment from 'moment';

interface Iprops {
  date: Date;
  timeZone: string;
  handleChange: (time: string) => void;
}

function TimePicker(props: Iprops) {
  const time = moment(props.date).tz(props.timeZone).format('HH:mm');
  let momentObj = moment().startOf('day');

  const timeOptions = useMemo(() => {
    const startTime = [moment().startOf('day').format('HH:mm')];

    // eslint-disable-next-line no-constant-condition
    while (true) {
      momentObj = momentObj.add(15, 'minutes');
      if (momentObj.format('HH:mm') === startTime[0]) {
        break;
      } else {
        startTime.push(momentObj.format('HH:mm'));
      }
    }

    return startTime;
  }, []);

  return (
    <select
      className={'event-end-time select-design '}
      value={time}
      onChange={event => {
        props.handleChange(event.target.value);
      }}
      style={{ width: '110%', height: '42px', lineHeight: '42px' }}
      //onFocus={}
    >
      {timeOptions.map((time, index) => {
        return (
          <option key={index} value={time}>
            {time} ({moment().tz(props.timeZone).zoneAbbr()})
          </option>
        );
      })}
    </select>
  );
}

export default TimePicker;
