import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { truncate } from 'core/helpers/stringUtils';
import { IUserInfo } from 'core/interfaces/IUserData';
import { Tooltip, usernameTooltipLabel } from 'library/atoms';
import { THEME_COLORS } from 'library/theme/colors';
import { memo, useContext } from 'react';
import Select, { components, GroupBase, StylesConfig } from 'react-select';
import { OrganizationContext } from '../../..';

import { EscalationIcon, ProfileIcon, SquadIcon } from '../../../../../../../icons';
import { IServiceOwner } from '../../../hooks/useServiceOwner';

const { Option } = components;

type Option = {
  value: any;
  label: any;
};

const styles: StylesConfig<Option, false, GroupBase<Option>> = {
  input: (provided, { selectProps: { inputValue, isMulti } }) => ({
    ...provided,
    ...(!(inputValue || isMulti) ? { position: 'absolute' } : {}),
  }),
};

const SingleValue = (props: any) => {
  const labelWithoutUsername = props.data.label.replace(` (${props.data.username})`, '');
  return (
    <components.SingleValue {...props}>
      <Tooltip
        label={usernameTooltipLabel(labelWithoutUsername, props.data.username)}
        isDisabled={!props.data.username}
        placement="top"
      >
        <Text>
          {truncate(labelWithoutUsername, 30)}
          {props.data.username && ` (${truncate(props.data.username, 30)})`}
        </Text>
      </Tooltip>
    </components.SingleValue>
  );
};

const IconOption = (props: any) => (
  <Option {...props}>
    <VStack alignItems={'left'}>
      <HStack>
        <Icon
          mr={2}
          as={
            props.data.type === 'user'
              ? ProfileIcon
              : props.data.type === 'squad'
              ? SquadIcon
              : EscalationIcon
          }
        />
        <Text>{props.data.label}</Text>
      </HStack>
      {props.data.username && (
        <Text style={{ marginLeft: '32px', color: THEME_COLORS.secondary[1200] }}>
          {props.data.username}
        </Text>
      )}
    </VStack>
  </Option>
);

export const getEntityOwnerValue = (options: any, assigneeId?: string) => {
  const entity = options.find((u: any) => u.value === assigneeId);
  if (entity) {
    const usernameLabel = entity?.username
      ? `${entity?.label} (${entity?.username})`
      : `${entity?.label}`;

    return {
      label: usernameLabel,
      value: entity?.id,
      type: entity?.type,
      username: entity?.username,
    };
  }
  return '';
};

export const OwnerSelect = memo(
  ({
    onSelect,
    serviceOwners,
    assigneeId,
  }: {
    onSelect: (id: string, type: string) => void;
    serviceOwners: IServiceOwner[];
    assigneeId: string | undefined;
  }) => {
    const organization = useContext(OrganizationContext);
    const escalationPolicyData = organization?.escalations?.e;

    const options = [
      ...(organization?.users.u
        ? organization?.users?.u
            .filter(user => serviceOwners.find(owner => owner.user_id === user.id))
            .sort((comparingElement: IUserInfo, compareWithElement: IUserInfo) =>
              comparingElement.first_name.localeCompare(compareWithElement.first_name),
            )
            .map(({ first_name, last_name, id, username_for_display: username }: IUserInfo) => {
              return {
                value: id,
                label: `${first_name} ${last_name}`,
                username,
                type: 'user',
              };
            })
        : []),
      ...(organization?.squads?.s
        ? organization?.squads?.s
            .filter(squad => {
              return organization?.selectedTeam?.teamId === squad?.owner?.id;
            })
            .sort((comparingElement: any, compareWithElement: any) =>
              comparingElement.name.localeCompare(compareWithElement.name),
            )
            .map(({ name, id }: any) => {
              return { value: id, label: name, type: 'squad' };
            })
        : []),
      ...(escalationPolicyData
        ? escalationPolicyData?.map((el: any) => {
            return { value: el.id, label: el.name, type: 'escalationpolicy' };
          })
        : []),
    ];

    const handleChange = (value: any) => {
      onSelect(value.value, value.type);
    };

    return (
      <div style={{ margin: '1rem 0', paddingLeft: '22px' }}>
        <Select
          options={options}
          onChange={handleChange}
          placeholder="Select user, squad or escalation policy"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={getEntityOwnerValue(options, assigneeId)}
          components={{ Option: IconOption, SingleValue }}
          styles={styles}
        />
      </div>
    );
  },
);
