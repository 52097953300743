import React, { HTMLAttributes, ReactNode } from 'react';
import Theme from '../Theme';
import { hexToRgb, rgbToYIQ } from '../../utils/colorHash';
import styled from 'styled-components';
import { CloseCircleIcon } from '../../utils/icons';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

interface IShell extends HTMLAttributes<HTMLDivElement> {
  color: string | 'black';
  fontSize?: 12 | 14 | string;
  fontWeight?: 300 | 500 | number;
  monoFontFamily?: string;
  height?: '24px' | '16px' | 'fit-content' | string;
  width?: string | 'fit-content';
  padding?: string | '4px 8px';
  isDeletable?: boolean;
  onDelete?: () => void;
}

const Child = styled.div<{ color: string; background: string }>`
  background: ${props => props.background};
  color: ${props => props.color};

  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  & > a {
    background: ${props => props.background};
    color: ${props => props.color} !important;
    text-decoration: underline !important;
    text-decoration-color: ${props => props.theme.primary.default} !important;
  }
`;

const Shell = styled.div<IShell>`
  display: inline-flex;
  box-shadow: 0 0 0 1.5px ${props => props.color || props.theme.primary.default};
  border-radius: 3px;

  & > ${Child} {
    padding: ${props => props.padding || '2px 8px'};
    font-size: ${props => props.fontSize || 12}px;
    font-weight: ${props => props.fontWeight || 400};
    font-family: ${props => props.monoFontFamily || props.theme.monoFontFamily};
  }
`;

const DeleteButton = styled.button<{ color: string }>`
  background-color: ${props => props.color};
  border: 0;
  padding: 3px 4px 0 0;

  :hover {
    cursor: pointer;
  }
`;

interface IProps extends IShell {
  tag: ReactNode;
  value: ReactNode;
}

const IncidentTag = ({ tag, value, ...props }: IProps) => {
  const baseMapIQ = rgbToYIQ(hexToRgb(props.color));
  const valueColour = baseMapIQ >= 128 ? '#000' : '#fff';
  const { theme } = Theme;
  const hasUpdate = useUserAccess().hasUpdateAccess('incidents');

  return (
    <Shell {...props}>
      <Child color={baseMapIQ > 180 ? valueColour : props.color} background={theme.shades.white}>
        {tag}
      </Child>
      <Child background={props.color} color={valueColour}>
        {value}
      </Child>
      {props.isDeletable && (
        <DeleteButton color={props.color} onClick={props.onDelete} disabled={!hasUpdate}>
          <NoPermissionTooltip isDisabled={hasUpdate}>
            <CloseCircleIcon
              width={props.fontSize || 14}
              height={props.fontSize || 14}
              color={baseMapIQ > 180 ? valueColour : props.color}
            />
          </NoPermissionTooltip>
        </DeleteButton>
      )}
    </Shell>
  );
};

/** @component */
export default IncidentTag;
