import { ExtensionService } from 'core';
import { SlackConfigurationPayload } from 'core/interfaces/IExtensions';
import React from 'react';
import { useMutation } from 'react-query';
import {
  reactQueryConfigError,
  reactQueryConfigSuccess,
} from '../../jira-cloud/helpers/service.reactqery';

async function SaveSlackConfig(payload: SlackConfigurationPayload) {
  const _extensionService = new ExtensionService();
  const data = await _extensionService.updateSlackConfiguration(payload);
  return data.data.data;
}

const useSaveSlackConfig = () => {
  return useMutation(
    'save-configuration',
    (payload: SlackConfigurationPayload) => SaveSlackConfig(payload),
    {
      onSuccess: () => {
        reactQueryConfigSuccess('You have successfully updated the configuration.');
      },
      onError: (error: any) => {
        const description =
          error?.response?.data?.meta?.error_message || 'Could not update slack configuration';
        reactQueryConfigError({ description: description, title: 'Save Config' });
      },
    },
  );
};

export { useSaveSlackConfig };
