import {
  Box,
  Drawer as CDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Divider, FormButton, IconButton } from 'library/atoms';
import { LibraryIcons } from 'library/icons';
import { ComponentProps, ReactNode } from 'react';

interface Drawer extends Pick<ComponentProps<typeof FormButton>, 'isDisabled'> {
  isOpen?: boolean;
  onClose?: () => void;
  callbackFn?: () => void;
  title: string;
  renderHeaderDivider?: boolean;
  description?: string;
  renderHeader?: boolean;
  footer?: string | ReactNode;
  isFooterLeftAlign?: boolean;
  disableBodyPadding?: boolean;
  closeOnOverlayClick?: boolean;
  size?: 'lg' | 'md' | 'sm' | 'custom';
  children: ReactNode;
  showBackIcon?: boolean;
  variant?: 'alwaysOpen';
  trapFocus?: boolean;
  lockFocusAcrossFrames?: boolean;
}

export const CustomDrawerComponent = ({
  isOpen = false,
  onClose = () => {},
  callbackFn = () => {},
  title,
  renderHeaderDivider = true,
  description,
  footer = null,
  isFooterLeftAlign = true,
  size = 'lg',
  children,
  showBackIcon = false,
  renderHeader = true,
  disableBodyPadding = false,
  closeOnOverlayClick = true,
  variant,
  trapFocus = true,
  lockFocusAcrossFrames = true,
  ...rest
}: Drawer) => {
  return (
    <CDrawer
      isOpen={isOpen}
      size={size}
      placement="right"
      onClose={onClose}
      closeOnOverlayClick={variant === 'alwaysOpen' ? undefined : closeOnOverlayClick}
      trapFocus={trapFocus}
      lockFocusAcrossFrames={lockFocusAcrossFrames}
      variant={variant}
    >
      {variant === 'alwaysOpen' ? null : <DrawerOverlay backgroundColor={'#09305a80'} />}
      <DrawerContent>
        {renderHeader && (
          <>
            <DrawerCloseButton boxShadow="none !important" />
            <DrawerHeader sx={disableBodyPadding ? { p: 0 } : {}}>
              <VStack alignItems="flex-start" rowGap={1}>
                <Box pt={4} pb={2} px={4}>
                  <HStack alignItems="center" spacing={2} gap={2}>
                    {showBackIcon && (
                      <IconButton
                        variant="ghost"
                        onClick={onClose}
                        icon={
                          <LibraryIcons.ArrowBackIcon
                            color="secondary.1000"
                            width="24px"
                            height="24px"
                          />
                        }
                        aria-label="back-icon"
                      />
                    )}
                    <Text variant="h2" color="secondary.1000">
                      {title}
                    </Text>
                  </HStack>
                  {description && (
                    <Text variant="h4" color="secondary.1000">
                      {description}
                    </Text>
                  )}
                </Box>
                {title && renderHeaderDivider && <Divider />}
              </VStack>
            </DrawerHeader>
          </>
        )}
        <DrawerBody flex={1} sx={disableBodyPadding ? { p: 0 } : {}}>
          {children}
        </DrawerBody>
        {footer && (
          <>
            <Divider />
            <DrawerFooter gap="20px" justifyContent={isFooterLeftAlign ? 'flex-start' : 'flex-end'}>
              {footer}
            </DrawerFooter>
          </>
        )}
      </DrawerContent>
    </CDrawer>
  );
};

interface Props {
  children: React.ReactNode;
}

const DrawerFooterWrapper = (props: Props) => {
  return (
    <VStack
      alignItems="flex-start"
      bottom={0}
      position="absolute"
      bgColor={'white'}
      w="100%"
      zIndex={1}
    >
      <Divider />
      <HStack spacing={5} pb={4} px={6} pt={2}>
        {props.children}
      </HStack>
    </VStack>
  );
};

export default DrawerFooterWrapper;
