import { Para, Theme, ErrorBlock } from 'uie/components';
import { objectIsEmpty } from 'core';
import { ILog } from 'core/interfaces/IIncidents';
import { NewTabIcon } from 'icons';

const ServiceNowMessages = ({ log }: { log: ILog }) => {
  let servicenowMap: any = {};
  if (log.additionalInfo && log.action.toLowerCase().includes('servicenow')) {
    servicenowMap = log.additionalInfo;
  }
  const { theme } = Theme;

  return (
    <>
      {log.additionalInfo && log.action.toLowerCase().includes('servicenow') && (
        <>
          <Para fontSize={14} color={theme.shades.cement}>
            {(servicenowMap.errors && !objectIsEmpty(servicenowMap.errors)) ||
            (servicenowMap.error && !objectIsEmpty(servicenowMap.error)) ? (
              <ErrorBlock fontSize={14}>
                Failed to create jira issue:
                <br />
                {Object.values(
                  servicenowMap.errors ? servicenowMap.errors : servicenowMap.error,
                ).join('\n')}
              </ErrorBlock>
            ) : (
              <Para fontSize={14}>
                {!log.additionalInfo?.isManual ? (
                  <span>
                    {' '}
                    A new incident has been <span className="font-bold">Auto Created</span> in
                    ServiceNow{' '}
                  </span>
                ) : (
                  <span> A new incident has been Created in ServiceNow </span>
                )}
                {servicenowMap?.servicenow_incident_link && (
                  <>
                    <a
                      className="installation-guide-design"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${servicenowMap.servicenow_incident_link}`}
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '2px',
                      }}
                    >
                      #...{servicenowMap?.response?.result?.data?.number.slice(-3)}
                      <NewTabIcon />
                    </a>
                  </>
                )}
              </Para>
            )}
          </Para>
        </>
      )}
    </>
  );
};

export default ServiceNowMessages;
