import * as React from 'react';
import { SVGProps } from 'react';

const SvgJiraCloud = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="jira-cloud_svg__Logos"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.jira-cloud_svg__st2{fill:url(#jira-cloud_svg__SVGID_00000064326684150310048120000008446580790948693665_)}'
      }
    </style>
    <path
      d="M19.2 0H9.5c0 2.4 1.9 4.3 4.3 4.3h1.8V6c0 2.4 1.9 4.3 4.3 4.3V.8c.1-.4-.3-.8-.7-.8z"
      style={{
        fill: '#2684ff',
      }}
    />
    <linearGradient
      id="jira-cloud_svg__SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={15.02}
      y1={694.809}
      x2={10.923}
      y2={699.034}
      gradientTransform="translate(0 -690)"
    >
      <stop
        offset={0.18}
        style={{
          stopColor: '#0052cc',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#2684ff',
        }}
      />
    </linearGradient>
    <path
      d="M14.4 4.8H4.8c0 2.4 1.9 4.3 4.3 4.3h1.8v1.7c0 2.4 1.9 4.3 4.3 4.3V5.6c0-.4-.4-.8-.8-.8z"
      style={{
        fill: 'url(#jira-cloud_svg__SVGID_1_)',
      }}
    />
    <linearGradient
      id="jira-cloud_svg__SVGID_00000095334112241943760810000001288511872090694828_"
      gradientUnits="userSpaceOnUse"
      x1={10.537}
      y1={699.648}
      x2={5.799}
      y2={704.258}
      gradientTransform="translate(0 -690)"
    >
      <stop
        offset={0.18}
        style={{
          stopColor: '#0052cc',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#2684ff',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#jira-cloud_svg__SVGID_00000095334112241943760810000001288511872090694828_)',
      }}
      d="M9.6 9.6H0C0 12 1.9 14 4.3 14h1.8v1.7c0 2.4 1.9 4.3 4.3 4.3v-9.6c.1-.4-.3-.8-.8-.8z"
    />
  </svg>
);

export default SvgJiraCloud;
