import { useFormik } from 'formik';
import {
  UpdateStatusPageThemeMutation,
  UpdateStatusPageThemeMutationVariables,
  useUpdateStatusPageThemeMutation,
} from '../graphql/mutation';
import { UseMutationOptions } from 'react-query';

import { PAGE_TYPES } from '../constants/schema';
import { getPageInputDetails } from '../helpers/helper.service';
import { reactQueryConfigError } from '../helpers/helper.toast';
import FileUploadService, {
  FileUploadFeature,
  OperationType,
} from 'core/services/service.fileUpload';
import { useState } from 'react';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_EDITED } from 'core/const/tracker';

type onSubmit = (values: any) => void;

const fileUploadService = new FileUploadService();

const useUpdatePageTheme = <TError = unknown, TContext = unknown>(
  onSuccess: (data: any) => void,
  options?: UseMutationOptions<
    UpdateStatusPageThemeMutation,
    TError,
    UpdateStatusPageThemeMutationVariables,
    TContext
  >,
) => {
  return useUpdateStatusPageThemeMutation({
    ...options,
    onSuccess: data => {
      onSuccess(data as any);
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_EDITED, { 'Edit Type': 'Page Theme Updated' });
    },
    onError: reactQueryConfigError('Update theme'),
  });
};

const { initValues, schema } = getPageInputDetails(PAGE_TYPES.THEME);
export const usePageTheme = (onSubmitHandler: onSubmit, init = initValues, pageId = -1) => {
  const [isLogoUploading, setIsLogoUploading] = useState(false);
  const { mutateAsync: updateTheme, isLoading } = useUpdatePageTheme((data: any) => {
    onSubmitHandler(data);
  });

  const updateData = (values: any) => {
    if (pageId !== -1) {
      updateTheme({
        input: {
          pageID: pageId,
          logoUrl: values.logoUrl,
          themeColor: {
            primary: values.primaryColor,
            secondary: values.secondaryColor,
          },
        },
      });
    } else {
      onSubmitHandler(values);
    }
  };

  const onFormSubmit = (values: any) => {
    if (values.isLogoChanged) {
      const uploadImage = fileUploadService.getUploadFunctionForFeature(
        FileUploadFeature.STATUS_PAGE,
        OperationType.CREATE,
      );
      setIsLogoUploading(true);
      uploadImage(
        values.logo,
        url => {
          values.logoUrl = url;
          updateData(values);
          setIsLogoUploading(false);
        },
        reactQueryConfigError('Upload logo'),
      );
    } else updateData(values);
  };
  const formik = useFormik({
    initialValues: init,
    validationSchema: schema,
    onSubmit: onFormSubmit,
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: false,
  });

  return {
    formik,
    isLoading,
    isLogoUploading,
  };
};
