import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M17.317 11.003c0 .178-.07.348-.195.474a.661.661 0 0 1-.94 0 .672.672 0 0 1-.195-.474v-5.99c0-.178.07-.348.195-.474a.661.661 0 0 1 .94 0 .672.672 0 0 1 .195.474v5.99zm-.231 4.15c-.088.089-2.562 2.166-7.097 2.166s-6.993-2.067-7.097-2.155a.665.665 0 0 1-.202-.705.66.66 0 0 1 1.059-.297c.038.028 2.248 1.834 6.234 1.834 3.986 0 6.212-1.817 6.234-1.834a.673.673 0 0 1 .94.072.665.665 0 0 1-.066.925l-.005-.006zM2.655 5.013a.669.669 0 0 1 .703-.637.665.665 0 0 1 .627.637v5.978c0 .178-.07.348-.195.474a.661.661 0 0 1-.94 0 .672.672 0 0 1-.195-.474V5.013zm3.337-1.329a.669.669 0 0 1 .703-.637.665.665 0 0 1 .627.637v8.865c0 .178-.07.348-.195.474a.661.661 0 0 1-.94 0 .672.672 0 0 1-.195-.474V3.684zm3.354-.333c0-.178.07-.348.195-.474a.661.661 0 0 1 .94 0 .672.672 0 0 1 .195.474v9.641c0 .178-.07.348-.195.474a.661.661 0 0 1-.94 0 .672.672 0 0 1-.195-.474V3.351zm3.298.333c0-.178.07-.348.195-.474a.661.661 0 0 1 .94 0 .672.672 0 0 1 .195.474v8.865c0 .178-.07.348-.195.474a.661.661 0 0 1-.94 0 .672.672 0 0 1-.195-.474V3.684zM17.499.027H2.501A2.458 2.458 0 0 0 .738.751 2.508 2.508 0 0 0 0 2.52v14.96a2.508 2.508 0 0 0 1.548 2.309c.303.124.627.187.953.185h14.997a2.467 2.467 0 0 0 1.762-.722 2.512 2.512 0 0 0 .739-1.766V2.52a2.521 2.521 0 0 0-.735-1.768 2.461 2.461 0 0 0-1.761-.725"
      style={{
        fill: '#081d34',
      }}
    />
  </svg>
);

export default SvgComponent;
