import { Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { ListingButton } from 'library/atoms';
import { PriorityOption } from 'library/common';
import { LibraryIcons } from 'library/icons';
import { FC, useEffect } from 'react';
import { setCanAutoRefreshIL } from 'views/main/organization/incident-list/store/persistent-storage';

import { PRIORITY, PRIORITY_OPTIONS } from '../../common/constant';
import { NoPermissionTooltip } from '../NoPermissionTooltip';

type Props = {
  isLoading?: boolean;
  onClick: (option: PRIORITY) => void;
};

export const PriorityButton: FC<Props> = ({ onClick, isLoading }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    return () => {
      if (isOpen) {
        onMenuClose();
      }
    };
  }, [isOpen]);

  const onMenuOpen = () => {
    setCanAutoRefreshIL(false);
    onOpen();
  };

  const onMenuClose = () => {
    setCanAutoRefreshIL(true);
    onClose();
  };

  const userAccess = useUserAccess();

  const hasUpdate = userAccess.hasUpdateAccess('incidents');

  return (
    <Menu strategy="fixed" isOpen={isOpen}>
      <NoPermissionTooltip isDisabled={hasUpdate}>
        <MenuButton
          as={ListingButton}
          title="Update Priority"
          rightIcon={<LibraryIcons.ChevronDownIcon />}
          isLoading={isLoading}
          {...(isLoading ? { maxH: '30px' } : null)}
          onClick={onMenuOpen}
          isDisabled={!hasUpdate}
        />
      </NoPermissionTooltip>
      <MenuList zIndex={2} onMouseLeave={onMenuClose} w="140px" minW="0">
        {PRIORITY_OPTIONS.map(option => {
          return (
            <MenuItem
              key={option.value}
              onClick={() => {
                onClick(option.value);
              }}
            >
              <PriorityOption label={option.label} value={option.value} />
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
