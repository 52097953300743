import {
  Box,
  Button,
  Divider,
  HStack,
  Radio,
  RadioGroup,
  Select,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { AdvancedFilterBlock } from 'uie/components';
import { IAdvancedFilterFilterOperations } from 'uie/components/AdvancedFilterBlock/types';
import MultiSelect from 'components/chakra/Select';
import { toSentenceCase } from 'core/helpers/stringUtils';
import { IAppState } from 'core/interfaces/IAppState';
import { ITeam } from 'core/interfaces/ITeams';
import { HelpFilledIcon } from 'icons';
import { PRIORITY, PRIORITY_OPTIONS } from 'library/common';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { useChakraToast } from 'views/shared/hooks';

import { WebhookContext } from '../create/create-webhook';
import { INITIAL_FILTER_STATE, WEBHOOK_ACTIONS } from '../store';
import {
  TEAM_OPTIONS,
  TRIGGER_OPTIONS,
  VERSION_OPTIONS,
  WEBHOOK_TYPES,
  WEBHOOK_TYPES_OBJ,
} from '../webhook-const';
import styles from './choose-type.module.css';

export const ChooseType = (props: any) => {
  const { state, dispatch } = useContext(WebhookContext);

  const [webhookType, setWebhookType] = useState(
    (state.trigger_type ?? WEBHOOK_TYPES.MANUAL) as string,
  );
  const [team, setTeam] = useState(TEAM_OPTIONS.ALL_TEAMS as string);
  const [triggerOptions, setTriggerOptions] = useState(TRIGGER_OPTIONS as any);
  const [version, setVersion] = useState(state.version ?? 'v2');
  const [teams, setTeams] = useState([] as any[]);
  const [triggers, setTriggers] = useState([] as any[]);
  const [filter, setFilter] = useState(
    isEmpty(state.filters) ? INITIAL_FILTER_STATE : { ...state.filters },
  );
  const [filterOptions, setFilterOptions] = useState({});
  const organization = useSelector((state: IAppState) => state.organization, shallowEqual);
  const orgTeams = organization.teams.t.filter(team =>
    team.members.find(member => member.user_id === organization.currentUser.u?.id),
  ) as ITeam[];
  const teamOptions = orgTeams.map(team => {
    return {
      value: team.id,
      label: team.name,
    };
  });

  const [showErrorToast] = useChakraToast();

  useEffect(() => {
    setTeam(state.is_all_teams_configured ? TEAM_OPTIONS.ALL_TEAMS : TEAM_OPTIONS.SELECT_TEAMS);

    if (state.teams?.length) {
      const selectedTeams = teamOptions.filter(opt => state.teams.includes(opt.value));
      setTeams(selectedTeams);
    }

    if (state.triggers?.length) {
      const selectedTriggers = triggerOptions.filter(
        (opt: { event_class: any; event_type: any }) => {
          return state.triggers.some(
            trigger =>
              trigger.event_class === opt.event_class && trigger.event_type === opt.event_type,
          );
        },
      );
      setTriggers(selectedTriggers);
    }
  }, []);

  useEffect(() => {
    setFilterOptions({
      service_id: {
        name: 'Service',
        operations: ['eq', 'neq'] as IAdvancedFilterFilterOperations[],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options: props.services.reduce<{ [id: string]: string }>((c, n) => {
          c[n.id] = n.name;
          return c;
        }, {}),
      },

      alert_source_id: {
        name: 'Alerts Source',
        operations: ['eq', 'neq'] as IAdvancedFilterFilterOperations[],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options: props.integrations.i.reduce<{ [id: string]: string }>((c, n) => {
          c[n._id] = n.shortName;
          return c;
        }, {}),
      },

      team_id: {
        name: 'Team',
        operations: ['eq', 'neq'] as IAdvancedFilterFilterOperations[],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options: orgTeams.reduce<{ [id: string]: string }>((c, n) => {
          c[n.id] = n.name;
          return c;
        }, {}),
      },

      priority: {
        name: 'Priority',
        operations: ['eq', 'neq'] as IAdvancedFilterFilterOperations[],
        options: PRIORITY_OPTIONS.reduce<{ [id: string]: string }>((c, n) => {
          const hasValue = !!n.value && n.value !== PRIORITY.Unset;
          c[n.value] = hasValue ? n.value : n.label;
          return c;
        }, {}),
      },

      'tag.key': {
        name: 'Tag Key',
        operations: ['matches', 'nmatches'] as IAdvancedFilterFilterOperations[],
        options: {},
      },

      'tag.value': {
        name: 'Tag Value',
        operations: ['matches', 'nmatches'] as IAdvancedFilterFilterOperations[],
        options: {},
      },
    });
  }, [props.services]);

  const updateVersion = (ver: string) => {
    setVersion(ver);
    setTriggers([]);
    if (ver === 'v1') {
      setTriggerOptions(TRIGGER_OPTIONS.slice(0, 4));
    } else {
      setTriggerOptions(TRIGGER_OPTIONS);
    }
  };

  const moveToNextStep = () => {
    const triggerValues = triggers.map(trigger => {
      return {
        event_class: trigger.event_class,
        event_type: trigger.event_type,
      };
    });

    if (webhookType === WEBHOOK_TYPES.MANUAL) {
      if (team === TEAM_OPTIONS.SELECT_TEAMS && !teams.length) {
        showErrorToast('Please select at least a team to continue');
        return;
      }
    } else if (!triggers.length) {
      showErrorToast('Please select at least a trigger to continue');
      return;
    }

    const payload = {
      version,
      trigger_type: webhookType,
      filters: filter,
      triggers: triggerValues,
      is_all_teams_configured: team === TEAM_OPTIONS.ALL_TEAMS,
      teams: team === TEAM_OPTIONS.ALL_TEAMS ? [] : teams.map(team => team.value),
    };
    dispatch({ type: WEBHOOK_ACTIONS.SAVE_TYPE_INFO, payload });
    props.onSave();
  };

  const removeTagValue = (array: Array<any>, option: any) => {
    const filteredArray = array.filter(data => data.value !== option.value);
    array === teams ? setTeams([...filteredArray]) : setTriggers([...filteredArray]);
  };

  const mapValueToTags = (array: Array<any>) => {
    return (
      <HStack flexWrap="wrap" rowGap={2}>
        {array.map((option: any, index) => {
          return (
            <Tag key={index} size="lg">
              <TagLabel fontWeight="normal">{option.label}</TagLabel>
              <TagCloseButton onClick={() => removeTagValue(array, option)} />
            </Tag>
          );
        })}
      </HStack>
    );
  };

  return (
    <>
      <Box paddingX={6} paddingY={3} maxWidth="80%">
        <Text className={styles.heading}>Choose Webhook Type</Text>
        <Text className={styles.subheading}>Choose webhook type and configure your webhook</Text>
      </Box>
      <VStack spacing={4} align="flex-start" padding="20px">
        <RadioGroup
          defaultValue={WEBHOOK_TYPES.AUTOMATIC}
          value={webhookType}
          onChange={setWebhookType}
        >
          <HStack className={styles.horz} justifyContent="space-between" spacing={4}>
            {WEBHOOK_TYPES_OBJ.map(type => {
              return (
                <VStack key={type.value} alignItems="flex-start">
                  <Radio value={type.value}>
                    <Text className={styles.typeName}>{type.title}</Text>
                  </Radio>
                  <Text className={styles.description}>{type.description}</Text>
                </VStack>
              );
            })}
          </HStack>
        </RadioGroup>
        <Divider height={0.5} backgroundColor="shades.smoke" />
        <Text className={styles.title}>{toSentenceCase(webhookType)} Webhook Configuration</Text>
        {webhookType === WEBHOOK_TYPES.AUTOMATIC ? (
          <>
            <VStack width="100%" alignItems="flex-start" spacing={2}>
              <HStack alignItems="center">
                <Text className={styles.controlTitle}>Version*</Text>
                <Tooltip label="The event triggers will vary depending on the version you select">
                  <HelpFilledIcon width={16} />
                </Tooltip>
              </HStack>
              <Select
                onChange={event => updateVersion(event.target.value)}
                value={version}
                width="50%"
              >
                {VERSION_OPTIONS.map(version => (
                  <option key={version.value} value={version.value}>
                    {version.label}
                  </option>
                ))}
              </Select>
            </VStack>
            <VStack width="100%" alignItems="flex-start" spacing={2}>
              <Text className={styles.controlTitle}>Triggers*</Text>
              <div className={styles.select}>
                <MultiSelect
                  placeholder="Select Events to Trigger the Webhook"
                  options={triggerOptions}
                  value={triggers}
                  onChange={(values: any) => {
                    setTriggers(values);
                  }}
                  isMulti
                  closeMenuOnSelect
                  controlShouldRenderValue={false}
                  hideSelectedOptions={true}
                  hideSelectedValues
                />
              </div>
              {triggers && mapValueToTags(triggers)}
            </VStack>
            <VStack width="100%" alignItems="flex-start" spacing={2}>
              <Text className={styles.controlTitle}>Filters </Text>
              <Text className={styles.subheading}>
                Applying filters will allow the webhook to be triggered only for events that match
                the filter.
              </Text>
              <Text className={styles.filterCondText}>If the following condition(s) are met</Text>
              {!isEmpty(filterOptions) && (
                <AdvancedFilterBlock
                  emptyRender={<div>No filters applied</div>}
                  filter={filter}
                  updateFilter={setFilter}
                  options={filterOptions}
                  groupDepth={2}
                  cellHeight={32}
                />
              )}
            </VStack>
          </>
        ) : (
          <>
            <Text className={styles.controlTitle}>
              Select team(s) that will have access to this Webhook
            </Text>
            <RadioGroup onChange={val => setTeam(val)} value={team}>
              <VStack align="flex-start" spacing={2}>
                <Radio value={TEAM_OPTIONS.ALL_TEAMS}>
                  <Text>All Teams</Text>
                </Radio>
                <Radio value={TEAM_OPTIONS.SELECT_TEAMS}>
                  <Text>Select Teams</Text>
                </Radio>
              </VStack>
            </RadioGroup>
            {team === TEAM_OPTIONS.SELECT_TEAMS && (
              <>
                <div className={styles.select}>
                  <MultiSelect
                    placeholder="Select teams"
                    className={styles.teamSelectPopUp}
                    value={teams}
                    options={teamOptions}
                    onChange={(values: any) => {
                      setTeams(values);
                    }}
                    isMulti
                    closeMenuOnSelect
                    hideSelectedOptions={true}
                    hideSelectedValues
                    controlShouldRenderValue={false}
                  />
                </div>
                {teams && mapValueToTags(teams)}
              </>
            )}
          </>
        )}
        <Divider height={0.5} backgroundColor="shades.smoke" />
        <HStack>
          <Button
            className={styles.submitBtn}
            variant="solid"
            onClick={() => moveToNextStep()}
            type="submit"
          >
            Next: Configure Payload
          </Button>
          <Button className={styles.cancelBtn} variant="outline" onClick={() => props.onCancel()}>
            Cancel
          </Button>
        </HStack>
      </VStack>
    </>
  );
};

export default connect(({ integrations }: IAppState) => ({ integrations }))(ChooseType);
