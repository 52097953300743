import { deepCopy } from 'core';
import {
  ActionKind,
  ILCOLUMN,
  IncidentListAction,
  IncidentListState,
  INITIAL_INCIDENT_LIST_STATE,
} from '.';
import { INCIDENT_STATUS } from '../common/enums';
import { setCanAutoRefreshIL } from './persistent-storage';

export function IncidentListReducer(state: IncidentListState, action: IncidentListAction) {
  const { type, payload } = action;
  const newState = deepCopy(state);

  switch (type) {
    case ActionKind.BULK_SELECT:
      newState.bulk.select_for_all = payload?.select_for_all;
      break;

    case ActionKind.RESET_BULK_SELECTION:
      newState.bulk.select_for_all = [];
      break;

    case ActionKind.SET_SEARCH_TERM:
      newState.bulk.select_for_all = [];
      const searchTerm = payload?.searchTerm ?? '';
      newState.search.isEnabled = !!searchTerm;
      newState.search.term = searchTerm;
      newState.cursor = { ...INITIAL_INCIDENT_LIST_STATE.cursor };
      break;

    case ActionKind.RESET_CURSOR:
      newState.bulk.select_for_all = [];
      newState.cursor = { ...INITIAL_INCIDENT_LIST_STATE.cursor };
      break;

    case ActionKind.SET_SORT_BY:
      newState.bulk.select_for_all = [];
      newState.cursor = { ...INITIAL_INCIDENT_LIST_STATE.cursor };
      newState.sortBy = payload?.sortBy ?? ILCOLUMN.TIME;
      break;

    case ActionKind.SET_CURSOR_FOR_STATUS:
      newState.bulk.select_for_all = [];
      const { status, cursor } = payload ?? {};
      if (Object.values(INCIDENT_STATUS).includes(status) && cursor) {
        newState.cursor[status as INCIDENT_STATUS] = cursor;
      }
      break;

    case ActionKind.ALLOW_AUTO_REFRESH:
      newState.canAutoRefresh = payload?.canAutoRefresh ?? true;
      setCanAutoRefreshIL(newState.canAutoRefresh);
      break;
  }
  return newState;
}
