import { useFormikContext } from 'formik';
import React, { Fragment, useEffect } from 'react';

import { Box, Button, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react';

import { schedulesTextCopy } from '../../constants/schedules.copy';
import SchedulesForm from '../schedules.form';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import { SCHEDULES_V2_PATH, SCHEDULES_V2_DETAIL_PATH } from 'views/main/routes/routes';
import { INewSchedule } from '../../interface/schedule';
import HelpGuide from '../../common/HelpGuide';
import { schedulesHelpGuide } from '../../constants/schedules.guide';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_SCHEDULE_V2_CREATION_STEP_1_PAGE_VIEWED } from 'core/const/tracker';

interface Props {
  isScheduleEditMode: boolean;
}

function SchedulesCreate({ isScheduleEditMode }: Props) {
  const history = useHistory();
  const formik = useFormikContext<INewSchedule>();
  const { scheduleId } = useParams<{ scheduleId: string }>();
  useEffect(() => {
    !isScheduleEditMode && AppTracker.track(T_WA_UP_SCHEDULE_V2_CREATION_STEP_1_PAGE_VIEWED);
  }, [isScheduleEditMode]);

  return (
    <Fragment>
      <Box bg="white" position="relative">
        <VStack alignItems="flex-start" gap={8} p={6} maxH="calc(100vh - 255px)" overflowY="auto">
          <VStack alignItems="flex-start" w="75%">
            <Heading as="h6" variant="h6">
              {isScheduleEditMode
                ? schedulesTextCopy.update.title
                : schedulesTextCopy.creation.title}
            </Heading>
            <Text fontSize="sm">
              {isScheduleEditMode ? schedulesTextCopy.update.desc : schedulesTextCopy.creation.desc}
            </Text>
          </VStack>

          <form onSubmit={formik.handleSubmit} style={{ width: '60%' }}>
            <SchedulesForm />

            <Box w="100%" position="absolute" bottom={0} mx={-6}>
              <Divider />

              <HStack px={6} py={4} gap={4}>
                <Button
                  type="submit"
                  variant="default"
                  isDisabled={
                    !formik.values.name || !formik.values.timeZone || !formik.values.owner?.ID
                      ? true
                      : false
                  }
                >
                  {schedulesTextCopy.creation.nextChooseRotation}
                </Button>
                <Button
                  variant="invertedDefault"
                  onClick={() =>
                    history.push(
                      scheduleId
                        ? generatePath(SCHEDULES_V2_DETAIL_PATH, { scheduleId })
                        : SCHEDULES_V2_PATH,
                    )
                  }
                >
                  {schedulesTextCopy.common.cancel}
                </Button>
              </HStack>
            </Box>
          </form>
        </VStack>
      </Box>

      <Box bg="white" px={3} overflowY="auto">
        <HelpGuide helpGuide={schedulesHelpGuide} />
      </Box>
    </Fragment>
  );
}

export default SchedulesCreate;
