import React, { useState, ReactElement, useRef, InputHTMLAttributes, useEffect } from 'react';
import { Shell, Drop, IDrop, IShell } from './helper';
import Grid from '../Grid';
import TextButton from '../TextButton';
import { IFocusBlock } from '../FocusBlock';
import keyNav from '../../utils/keyNav';
import { ArrowDownIcon } from '../../utils/icons';
import InputBlock from '../InputBlock';
import Theme from '../Theme';

type IMix = React.HTMLAttributes<HTMLDivElement> & IDrop & IShell;
interface ISearchHookProps extends InputHTMLAttributes<HTMLInputElement> {
  height?: string | number | '50px';
  width?: string | number | '100%' | 'fit-content';
  fontSize?: string | '16px';
  padding?: string | '16px';
  error?: boolean;
}

interface ISelectBox extends IMix {
  hook: ReactElement;
  /**
   * Input element props
   */
  searchHookProps?: ISearchHookProps;
  open?: boolean;
  /**
   * elements inside dropdown
   */
  children: ReactElement<IFocusBlock>[] | any;
  width?: string | '200px';
  dropdownWidth?: string;
  disabled?: boolean;
  onValueChange: (event: React.KeyboardEvent<HTMLDivElement>, value: any) => void;
  hookRef?: (ref: HTMLButtonElement | null) => void;
  buttonHeight?: string;
}

const SelectBox = (props: ISelectBox) => {
  let selectedIndex = 0;
  const setSelectedItemIndex = () => {
    React.Children.map(props.children, (child: ReactElement<IFocusBlock>, i) => {
      if (child.props.isSelected) {
        selectedIndex = i;
      }
    });
  };
  setSelectedItemIndex();

  const { theme } = Theme;

  const [currentIndex, setCurrentIndex] = useState(selectedIndex);
  const [canScroll, setCanScroll] = useState(selectedIndex !== 0);
  const searchHookRef = useRef<HTMLInputElement>(null);
  const hookRef = useRef<HTMLButtonElement>(null);

  let dropDownListLength = 0;
  const disabledIndex: number[] = [];

  const focusSearchHook = () => {
    setTimeout(() => {
      if (searchHookRef.current) {
        (searchHookRef.current as unknown as HTMLInputElement).focus();
      }
    }, 200);
  };

  const onChildClick = (event: any, index: number, value: any) => {
    const newValue = value;
    props.onValueChange(event, newValue);
    setCurrentIndex(index);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (props.children.length === 0) {
      return;
    }
    setCanScroll(true);
    let index = currentIndex;
    let preventDefault = false;
    let cancelRoutine = false;

    const onTabOrEsc = () => {
      cancelRoutine = true;
      return;
    };

    keyNav(event)({
      onEsc: onTabOrEsc,
      onTab: onTabOrEsc,
      onEnter: () => {
        if (disabledIndex.includes(currentIndex)) {
          cancelRoutine = true;
          return;
        }

        hookRef.current?.blur();
        searchHookRef.current?.blur();
        const newValue = clonedChild[currentIndex].props.value;
        props.onValueChange(event, newValue);
      },
      onKeyUp: () => {
        if (currentIndex - 1 >= 0) {
          index -= 1;
        } else {
          index = dropDownListLength;
        }
        preventDefault = true;
      },
      onKeyDown: () => {
        if (currentIndex + 1 <= dropDownListLength) {
          index += 1;
        } else {
          index = 0;
        }
        preventDefault = true;
      },
    });

    if (cancelRoutine) return;
    if (preventDefault) event.preventDefault();
    setCurrentIndex(index);
  };

  const onKeyUp = () => {
    setCanScroll(false);
  };

  const clonedChild = React.Children.map(props.children, (child: ReactElement<IFocusBlock>, i) => {
    const focus = i === currentIndex && !child.props.disabled;
    if (child.props.disabled) {
      disabledIndex.push(i);
    }
    return React.cloneElement(child, {
      focus,
      tabIndex: focus ? 0 : -1,
      key: i,
      index: i,
      scrollTo: focus && canScroll,
      onSelectValue: onChildClick,
    });
  });

  dropDownListLength = clonedChild.length - 1;

  const { onValueChange, ...dropdownProps } = props;

  useEffect(() => {
    if (hookRef.current) {
      if (props.hookRef && hookRef.current) {
        props.hookRef(hookRef.current);
      }
    }
  });

  return (
    <Shell
      width={props.width}
      error={props.error}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      height={props.buttonHeight}
      tabIndex={0}
    >
      <TextButton
        style={{ outline: 'none' }}
        className="hook"
        width={props.width || '200px'}
        disabled={props.disabled}
        onFocus={focusSearchHook}
        ref={hookRef}
        tabIndex={0}
      >
        <Grid flexWidth={12} alignItems="center" justifyContent="space-between">
          <Grid flexWidth={12}>{props.hook}</Grid>
          <Grid style={{ minWidth: 14 }} justifyContent="flex-end">
            <ArrowDownIcon width={14} height={14} fill={theme.font.label} />
          </Grid>
        </Grid>
      </TextButton>
      <Drop
        tabIndex={0}
        {...dropdownProps}
        width={props.dropdownWidth || props.width}
        extend={props.searchHookProps ? (props.searchHookProps.height as any) || '24px' : '0px'}
      >
        <React.Fragment>
          {props.searchHookProps && (
            <div style={{ padding: '0 10px', margin: '8px 0' }}>
              <InputBlock {...props.searchHookProps} forwardRef={searchHookRef} />
            </div>
          )}
          <div className="renders">{clonedChild}</div>
        </React.Fragment>
      </Drop>
    </Shell>
  );
};

/** @component */
export default SelectBox;
