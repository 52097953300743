import React from 'react';
import { Cell, Column } from 'react-table';
import { VerticalDots } from 'icons';

import {
  HStack,
  Center,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

import { API } from 'core';
import StackLimit, { IconType } from '../../../components/StackLimit';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  DeleteIcon,
  EditIcon,
  CopyIcon,
  ViewIcon,
} from '@chakra-ui/icons';
import { getMediumDateTime } from '../../../helpers/helper.date';
import {
  IDedupeKeyData,
  IDeduplicationRule,
  IRoutingRule,
  IRule,
} from '../../../Interfaces/automation-rule';
import { generatePath } from 'react-router-dom';
import {
  ESCALATION_POLICIES_LIST_PATH,
  TEAM_SQUADS_PATH,
  USER_PROFILE_PATH,
} from 'views/main/routes/routes';
import { hasReadAccessToEntity } from 'views/main/organization/navigation-flows/helpers';
import { truncate } from 'core/helpers/stringUtils';
import { getNameComponent } from 'views/main/organization/ger/components';
import { Tooltip } from 'library/atoms';
import { ServiceTooltip } from 'views/main/organization/service-catalog/components';

interface IModifiedRoutingRule extends IRoutingRule {
  users: { id: string; first_name: string; last_name: string }[];
  squads: { id: string; name: string }[];
  escalations: { id: string; name: string }[];
}

interface IRuleCol {
  tagging: Column<any>[];
  routing: Column<any>[];
  deduplicationCols: Column<any>[];
  suppressionCols: Column<any>[];
  dedupeKeysCols: Column<any>[];
}

export enum MenuAction {
  MOVE_UP,
  MOVE_DOWN,
  MOVE_TOP,
  MOVE_BOTTOM,
  VIEW,
  COPY,
  EDIT,
  DELETE,
}

export type MenuActionDetail = {
  action: MenuAction;
  label: string;
  handler: (() => void) | (() => Promise<any>) | undefined;
};

interface MenuActionsProps {
  actions: MenuActionDetail[];
}

export const MenuActions = ({ actions }: MenuActionsProps) => {
  const menuActionIcons = {
    [MenuAction.MOVE_UP]: <ArrowUpIcon />,
    [MenuAction.MOVE_DOWN]: <ArrowDownIcon />,
    [MenuAction.MOVE_TOP]: <ArrowUpIcon />,
    [MenuAction.MOVE_BOTTOM]: <ArrowDownIcon />,
    [MenuAction.VIEW]: <ViewIcon />,
    [MenuAction.COPY]: <CopyIcon />,
    [MenuAction.EDIT]: <EditIcon />,
    [MenuAction.DELETE]: <DeleteIcon />,
  };
  const moveUpAction = actions.find(({ action }) => action === MenuAction.MOVE_UP);
  const moveDownAction = actions.find(({ action }) => action === MenuAction.MOVE_DOWN);

  const filteredMenuActions = actions.filter(
    ({ action }) => ![MenuAction.MOVE_UP, MenuAction.MOVE_DOWN].includes(action),
  );

  return (
    <Flex justifyContent="flex-end" gap={4}>
      {moveUpAction && (
        <IconButton
          aria-label="Move Up"
          variant="outline"
          icon={menuActionIcons[MenuAction.MOVE_UP]}
          onClick={moveUpAction?.handler}
        >
          {moveUpAction.label}
        </IconButton>
      )}
      {moveDownAction && (
        <IconButton
          aria-label="Move Down"
          variant="outline"
          icon={menuActionIcons[MenuAction.MOVE_DOWN]}
          onClick={moveDownAction?.handler}
        >
          {moveDownAction.label}
        </IconButton>
      )}

      <Menu>
        {filteredMenuActions.length > 0 && (
          <MenuButton
            as={IconButton}
            aria-label="Actions"
            icon={<VerticalDots />}
            variant="outline"
            size="sm"
            border="none"
            bg="white"
            _hover={{ bg: 'gray.100' }}
          />
        )}
        <MenuList>
          {filteredMenuActions.map(({ action, handler, label }) => (
            <MenuItem key={action} icon={menuActionIcons[action]} onClick={handler}>
              {label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export const stringifyBasicExp = (exp: Array<{ lhs: string; op: string; rhs: string }>) => {
  const rule_expression = exp?.map(({ lhs, op, rhs }) => {
    return `${lhs ?? ''} ${op ?? ''} ${rhs ?? ''}`;
  });
  return exp ? rule_expression.join(',') : '';
};

const ruleExpCol = {
  disableSortBy: true,
  Header: 'RULE EXPRESSION',
  accessor: (row: IRule) => row.expression + stringifyBasicExp(row.basic_expression),
  Cell: (cell: Cell<IRule>) => {
    const {
      row: {
        original: { basic_expression, expression },
      },
    } = cell;
    const cellValue = expression + ' ' + stringifyBasicExp(basic_expression);
    return (
      <ServiceTooltip text={cellValue} placement="top">
        <Text noOfLines={2} cursor="pointer" textAlign="left" wordBreak="break-word">
          {cellValue}
        </Text>
      </ServiceTooltip>
    );
  },
};

const dedupeKeyCol = {
  Header: 'DEDUPE KEY',
  accessor: 'overlay.template',
  disableSortBy: true,
  Cell: (cell: Cell) => {
    return (
      <ServiceTooltip text={cell.value} placement="top">
        <Text noOfLines={2} cursor="pointer" textAlign="left" wordBreak="break-word">
          {cell.value}
        </Text>
      </ServiceTooltip>
    );
  },
};

const alertSourceCol = {
  Header: 'ALERT SOURCE',
  accessor: (row: IDedupeKeyData) => row.alert_source_type + '@' + row.alert_source_shortname,
  disableSortBy: true,
  Cell: (cell: Cell) => {
    const [alert_source_type, alert_source_shortname] = cell.value.split('@');
    return (
      <HStack alignItems="flex-start">
        <StackLimit
          limit={2}
          type="icon"
          data={[
            {
              icon: alert_source_shortname as IconType,
              name: alert_source_type,
            },
          ]}
        />
        <Text fontSize="sm" fontWeight={400}>
          {alert_source_type}
        </Text>
      </HStack>
    );
  },
};

const dedupeKeyDurationCol = {
  Header: 'DURATION',
  accessor: 'overlay.duration',
  disableSortBy: true,
  Cell: (cell: Cell) => {
    const duration = cell.value;
    const isDurationInHours = duration % 60 === 0;
    const durationValue = isDurationInHours ? duration / 60 : duration;
    const durationText = `${durationValue} ${isDurationInHours ? 'hour' : 'minute'}${
      durationValue > 1 ? 's' : ''
    }`;
    return (
      <Text fontSize="sm" fontWeight={400}>
        {durationText}
      </Text>
    );
  },
};

const durationCol = {
  Header: 'DURATION',
  disableSortBy: true,
  accessor: (row: IDeduplicationRule) => row.time_window + ' ' + row.time_unit,
  Cell: (cell: Cell) => (
    <Text fontSize="sm" fontWeight={400}>
      {cell.value}
    </Text>
  ),
};

const priorityCol = {
  Header: 'PRIORITY',
  accessor: 'priority',
  disableSortBy: true,
  Cell: (cell: Cell) => (
    <Text fontSize="sm" fontWeight={400}>
      {cell.row.index + 1}
    </Text>
  ),
};

const createdOnCol = {
  Header: 'CREATED ON',
  disableSortBy: true,
  accessor: (row: IRule) => getMediumDateTime(row.created_at),
  Cell: (cell: Cell) => (
    <Text fontSize="sm" fontWeight={400}>
      {cell.value}
    </Text>
  ),
};

const getFormattedTags = (rulesTag: { [key: string]: { color: string; value: string } }) => {
  return Object.keys(rulesTag).map(tagKeys => {
    return { name: `${tagKeys} : ${rulesTag[tagKeys].value}` };
  });
};

const getRoutingValues = (
  route_to: IModifiedRoutingRule['route_to'],
  users: IModifiedRoutingRule['users'],
  squads: IModifiedRoutingRule['squads'],
  escalations: IModifiedRoutingRule['escalations'],
) => {
  let name = '';
  let route_type = 'user';
  let route_link = '';
  const route_to_ = route_to;

  if (route_to_.entity_type === 'user') {
    const userName = users.filter(user => user.id === route_to_.entity_id)[0];
    name = typeof userName === 'object' ? `${userName.first_name} ${userName.last_name}` : userName;
    route_link = generatePath(USER_PROFILE_PATH, { id: route_to_.entity_id });
  } else if (route_to_.entity_type === 'squad') {
    name = squads.find(squad => squad.id === route_to_.entity_id)?.name ?? '';
    route_type = 'squad';
    route_link = hasReadAccessToEntity('squad')
      ? generatePath(TEAM_SQUADS_PATH, { id: API.config.teamId, sqid: route_to_.entity_id })
      : '';
  } else if (route_to_.entity_type === 'escalationpolicy') {
    name = escalations.find(escalation => escalation.id === route_to_.entity_id)?.name ?? '';
    route_link = hasReadAccessToEntity('escalationpolicy')
      ? generatePath(ESCALATION_POLICIES_LIST_PATH, { id: route_to_.entity_id })
      : '';
    route_type = 'escalation';
  }

  return { name, route_type, route_link };
};

export const rulesColumns: IRuleCol = {
  tagging: [
    ruleExpCol,
    {
      Header: 'TAGS',
      disableSortBy: true,
      accessor: (row: IRule) => {
        const tags_ = getFormattedTags(row.tags ?? {});
        return tags_.map(tag => tag.name).join(' ');
      },
      Cell: (cell: Cell<any, string[]>) => {
        const {
          row: {
            original: { tags },
          },
        } = cell;

        const tags_ = getFormattedTags(tags ?? {});

        return (
          <Center>
            <StackLimit limit={2} type="tag" data={tags_ || []} />
          </Center>
        );
      },
    },
    priorityCol,
    createdOnCol,
  ],
  routing: [
    ruleExpCol,
    {
      Header: 'Route',
      disableSortBy: true,
      accessor: (row: IModifiedRoutingRule) => {
        const { name } = getRoutingValues(row.route_to, row.users, row.squads, row.escalations);
        return name;
      },
      Cell: (cell: Cell<IModifiedRoutingRule, string[]>) => {
        const {
          row: {
            original: { route_to, users, squads, escalations },
          },
        } = cell;

        const { name, route_type, route_link } = getRoutingValues(
          route_to,
          users,
          squads,
          escalations,
        );

        return (
          <Center>
            <StackLimit
              direction="column"
              limit={2}
              type="icon-text"
              data={[{ name: name, icon: route_type as IconType, to: route_link }]}
            />
          </Center>
        );
      },
    },
    priorityCol,
    createdOnCol,
  ],
  deduplicationCols: [ruleExpCol, durationCol, priorityCol, createdOnCol],
  suppressionCols: [ruleExpCol, priorityCol, createdOnCol],
  dedupeKeysCols: [dedupeKeyCol, alertSourceCol, dedupeKeyDurationCol, createdOnCol],
};
