import { Para } from 'uie/components';
import { StakeHolderGroupType } from 'core/interfaces/IStakeHolder';
import React, { useMemo, useState } from 'react';
import { IStakeHolderProps } from '../../types/stakeholder.types';
import StakeholderGroupListTabList from './stakeholderGroupList.tabList';

function StakeHolderGroupList(props: IStakeHolderProps) {
  const { disableEditTab, stakeHolderGroupData, refetch } = props;

  const [expandedId, setExpandedId] = useState<number>(0);

  const addExpandedId = (id: number) => {
    setExpandedId(id);
  };

  const renderTabList = useMemo(() => {
    return stakeHolderGroupData.map((stakeHoldersData: StakeHolderGroupType) => {
      return (
        <StakeholderGroupListTabList
          key={stakeHoldersData?.id}
          {...props}
          {...stakeHoldersData}
          onEditClick={id => addExpandedId(id)}
          isExpanded={stakeHoldersData.id === expandedId && !disableEditTab}
          refetch={refetch}
        />
      );
    });
  }, [stakeHolderGroupData, expandedId, disableEditTab, props, refetch]);

  return (
    <>
      <Para
        style={{ marginBottom: '10px', fontSize: '12px', color: '#808080', fontStyle: 'italic' }}
      >
        {stakeHolderGroupData.length} Stakeholder groups belong to this team
      </Para>
      {renderTabList}
    </>
  );
}

export default React.memo(StakeHolderGroupList);
