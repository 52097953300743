import {
  REQUEST_USER_PERMISSION_ROLES,
  REQUEST_USER_PERMISSION_ROLES_SUCCESS,
  REQUEST_USER_PERMISSION_ROLES_FAIL,
} from '../../const/public';
import { IUserPermissionRoles } from '../../interfaces/IUserPermissionRoles';

const requestUserPermissionRoles = () => ({
  type: REQUEST_USER_PERMISSION_ROLES,
  payload: {},
});

const onRequestUserPermissionRolesSuccess = (userRoles: IUserPermissionRoles[]) => ({
  type: REQUEST_USER_PERMISSION_ROLES_SUCCESS,
  payload: {
    userRoles,
  },
});

const onRequestUserPermissionRolesFail = (error: any) => ({
  type: REQUEST_USER_PERMISSION_ROLES_FAIL,
  payload: {
    error,
  },
});

export {
  requestUserPermissionRoles,
  onRequestUserPermissionRolesSuccess,
  onRequestUserPermissionRolesFail,
};
