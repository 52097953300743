import * as React from 'react';
import { SVGProps } from 'react';

const SvgDeadMansSintchLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="DeadMansSintch_Logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.DeadMansSintch_Logo-20x20_svg__st0{fill:#747474}'}</style>
    <path
      className="DeadMansSintch_Logo-20x20_svg__st0"
      d="M5.8 9.1c.2.3.3.5.6.7.2.1.2.3.3.5 0 .2.1.3.2.4.3.4.4.9.5 1.3.1.4.3.7.6.9.4.3.8.5 1.2.7.6.1 1.3.2 1.9-.1.4-.2.7-.4 1-.6.3-.2.5-.5.6-.9.1-.5.3-.9.5-1.4.1-.2.2-.4.2-.6.1.4.1.7 0 1-.2 2.1-.5 4.2-1.1 6.3-.2.8-.5 1.6-1 2.3-.1.2-.3.3-.5.3-.6.1-1.2.1-1.8 0-.2 0-.3-.1-.4-.2-.4-.5-.6-1.2-.8-1.8-.4-1.2-.7-2.5-.9-3.7-.2-.9-.3-1.8-.4-2.7v-.4c0-.3-.1-.5-.2-.8 0-.1-.1-.1-.1-.2-.4-.2-.5-.6-.6-1 0-.1-.1-.2-.2-.3-.2 0-.3-.2-.5-.2 0-.1-.1-.2-.2-.2-.5-.3-1-.7-1.3-1.2-.5-.6-.4-1.1.2-1.6.4-.3.9-.5 1.5-.6.2-.1.3-.1.3-.3 0-.3.1-.6.1-.8.1-.4 0-.8 0-1.2 0-.3.1-.6.2-.9.2-.5.5-.8 1-1 2.2-.9 4.5-.9 6.7 0 .8.3 1.2.9 1.2 1.9 0 .8.1 1.5.2 2.3 0 .1.1.1.2.1.4.1.8.3 1.2.5.2.1.5.3.6.5.3.3.3.6.2 1-.2.4-.5.7-.8 1-.8.6-1.6 1.1-2.6 1.4-.6.2-1.1.4-1.7.5-.2 0-.4.1-.6.1-.2 0-.5.1-.7.1H10c-.1 0-.2 0-.2.2-.1.5-.2.9-.2 1.4v.1c-.1-.3-.2-.5-.4-.7-.1-.1 0-.2.1-.3.1-.2.3-.4.4-.6 0-.1.1-.1-.1-.1-1.5-.4-2.6-.6-3.8-1.1.1 0 0 0 0 0zM10 6.3c1.5 0 2.8-.4 4-1.3.2-.1.3-.3.2-.5-.1-.5-.2-1.1-.1-1.6v-.7c-.1-.5-.3-.9-.7-1.1-.8-.3-1.5-.5-2.4-.6-.7-.1-1.4-.1-2.1 0-.8.1-1.5.3-2.2.6-.3.1-.5.3-.6.6-.1.2-.2.5-.2.7v1.2c0 .3-.1.7-.1 1 0 .2 0 .3.2.4 1.1.8 2.5 1.2 4 1.3zm-.1 3.2c1.9 0 3.6-.5 5.2-1.5.4-.2.9-.6 1.2-1 .3-.4.2-.7-.2-1-.4-.3-.8-.5-1.3-.6-.2-.1-.2-.1-.3.2 0 .2 0 .5-.2.7-.3.3-.6.5-.9.7-.9.5-1.9.7-2.9.8-.7 0-1.5-.1-2.3-.3-.8-.1-1.5-.4-2.1-.9-.4-.2-.8-.5-.7-1.1v-.1c0-.1 0-.1-.1-.1-.6.2-1.1.4-1.5.8-.4.2-.4.5-.1.8.2.3.5.6.8.8C6.1 9 8 9.5 9.9 9.5z"
    />
    <path
      className="DeadMansSintch_Logo-20x20_svg__st0"
      d="M15.2 8.6c.5.4.8.9 1.1 1.5.6 1.2.9 2.4.8 3.7 0 .2-.1.4-.1.7-.1.2-.3.3-.5.5-.3.3-.6.5-.8.8v.1c.2.5.3.9.5 1.3.1.2.1.3 0 .4-.3.4-.7.7-1.1.9-.4.2-.8.4-1.3.6l-.9.3c-.1 0-.1.1-.2 0 0-.1.1-.1.2-.1.7-.4 1.5-.8 2.1-1.3l.6-.6c.1-.1.1-.1 0-.2l-.6-1.5c-.1-.2 0-.3.1-.5.3-.3.6-.7 1-.9.3-.2.4-.6.4-.9.2-1.6-.3-3.2-1.3-4.8 0 .1 0 .1 0 0zM4.9 8.6c-.6.8-1 1.7-1.2 2.6-.2.9-.3 1.8-.2 2.7 0 .2.1.4.3.5.4.3.7.7 1.1 1 .2.1.2.3.1.5-.2.5-.4.9-.7 1.3l-.2.2c-.1-.4 0-.9.1-1.3.1-.3.1-.3-.2-.5-.3-.2-.5-.5-.8-.7-.3-.3-.4-.6-.4-1-.1-1.7.4-3.2 1.4-4.6.2-.2.3-.4.5-.6 0 0 .1-.1.2-.1zM9 12.5h1c.1 0 .1.1.1.1 0 .1 0 .1-.1 0-.4-.1-.7-.1-1-.1z"
    />
    <path d="M5.8 9.1s.1 0 0 0c.1 0 0 0 0 0z" />
    <path
      className="DeadMansSintch_Logo-20x20_svg__st0"
      d="M7.4 2.4c.1-.1.1 0 .2 0 .4.1.7.2 1.1.3.4.2.6.5.7 1V4s0 .1-.1.1-.1 0-.2-.1c-.2-.3-.5-.6-.8-.9-.2-.2-.6-.4-.9-.7 0 .1 0 .1 0 0z"
    />
  </svg>
);

export default SvgDeadMansSintchLogo20X20;
