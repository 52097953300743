import { Grid, Para, TextButton } from 'uie/components';
import React from 'react';
import SquadForm from './AddNewSquadForm';

function EmptySquad() {
  const [addingNewSquad, setAddingNewSquad] = React.useState(false);

  return (
    <Grid
      style={{
        flexDirection: 'column',
      }}
    >
      <Para
        fontSize={16}
        color="#122146"
        style={{
          lineHeight: '132%',
        }}
      >
        Squads (0)
      </Para>
      <Para
        fontSize={16}
        color="#8890A2"
        style={{
          lineHeight: '132%',
          marginTop: '16px',
          marginBottom: '20px',
        }}
      >
        No squads added
      </Para>
      {!addingNewSquad ? (
        <TextButton onClick={() => setAddingNewSquad(true)}>
          <Para fontSize={16} color="#fff">
            Add New Squad
          </Para>
        </TextButton>
      ) : (
        <SquadForm
          onClose={() => {
            setAddingNewSquad(false);
          }}
        />
      )}
    </Grid>
  );
}

export { EmptySquad };
