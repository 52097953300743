import * as React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IUserNotificationRule } from '../../../../../core/interfaces/IUserData';
import {
  CardBlock,
  ErrorBlock,
  FocusBlock,
  Grid,
  Heading,
  IconButton,
  InputBlock,
  Para,
  SelectBox,
  TextButton,
  Theme,
} from 'uie/components';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import {
  T_WA_GS_ON_CALL_REMINDER_MODIFIED,
  T_WA_GS_ON_CALL_REMINDER_REVOKED,
} from '../../../../../core/const/tracker';
import ProfileService from '../../../../../core/services/service.editprofile';
import { exception } from '../../../../../core/exception';
import { IComponentErrors } from '../../../../../core/interfaces/IComponentState';
import { DeleteIcon } from 'icons';

interface IProps extends Pick<IAppState, 'userInfo'> {
  hide: () => void;
  editReminderRules: IUserNotificationRule[];
}
interface IState {
  saving: boolean;
  reminderRules: IUserNotificationRule[];
  error: IComponentErrors<'edit_notification_rule'>;
}

const notificationTypes: IUserNotificationRule['type'][] = ['Push', 'Email'];
const notificationTimeTypes: IUserNotificationRule['timeUnit'][] = ['minutes', 'hours', 'days'];
class EditOncallReminderRules extends React.Component<IProps, IState> {
  _profileService = new ProfileService();
  constructor(props: IProps) {
    super(props);
    this.state = {
      saving: false,
      reminderRules: this.props.editReminderRules || [],
      error: { edit_notification_rule: '' },
    };
  }

  removeRule = (index: number) => {
    this.setState(prevState => ({
      reminderRules: prevState.reminderRules.filter((_, i) => i !== index),
    }));
  };

  addRule = () => {
    this.setState(prevState => ({
      reminderRules: [...prevState.reminderRules, { time: 10, type: 'Email', timeUnit: 'minutes' }],
    }));
  };

  // return false if any rule has validation error
  validate = (reminderRules: IUserNotificationRule[]) => {
    reminderRules = reminderRules.map(rule => {
      rule.validationError = '';
      if (isNaN(rule.time) || rule.time < 0) {
        rule.validationError = 'Invalid time';
      }
      if (rule.timeUnit === 'minutes' && rule.time > 59) {
        rule.validationError = 'minutes has to be less than 60';
      }
      if (rule.timeUnit === 'hours' && rule.time > 23) {
        rule.validationError = 'hours has to be less than 24';
      }
      if (rule.timeUnit === 'days' && rule.time > 7) {
        rule.validationError = 'Could not be reminded before 7 days';
      }
      if (this.toMinutes(rule.time, rule.timeUnit) < 10) {
        rule.validationError = 'minimum of 10 minutes has to be chosen ';
      }
      return rule;
    });
    // Check for duplicates
    reminderRules = reminderRules.map((rule1, index1) => {
      if (
        reminderRules.some(
          (rule2, index2) =>
            index1 !== index2 &&
            this.toMinutes(rule1.time, rule1.timeUnit) ===
              this.toMinutes(rule2.time, rule2.timeUnit) &&
            rule1.type === rule2.type,
        )
      )
        rule1.validationError = 'Cannot have duplicate rules';
      return rule1;
    });

    this.setState({ reminderRules });
    return reminderRules.some(rule => rule.validationError !== '');
  };

  save = async () => {
    this.setState({ error: { edit_notification_rule: '' } });
    if (this.validate(this.state.reminderRules)) return;

    const rules: IUserNotificationRule[] = this.state.reminderRules.map(rule => {
      return {
        time: this.toMinutes(rule.time, rule.timeUnit),
        type: rule.type,
      };
    });
    this.setState({
      saving: true,
    });
    try {
      await this._profileService.updateOncallReminderRules(rules);
      this.props.hide();
      this.state.reminderRules.length < 1
        ? AppTracker.track(T_WA_GS_ON_CALL_REMINDER_REVOKED)
        : AppTracker.track(T_WA_GS_ON_CALL_REMINDER_MODIFIED, {
            'Reminder Notification Type': this.state.reminderRules.map(rules => rules.type),
            'Reminder Notification Frequency': this.state.reminderRules.map(
              rules => rules.timeUnit,
            ),
          });
    } catch (err: any) {
      this.setState({
        error: {
          edit_notification_rule: exception.handle('E_UPDATE_ONCALL_REMINDER_RULES', err),
        },
      });
    } finally {
      this.setState({ saving: false });
    }
  };

  onTypeChange = (index: number, _: React.KeyboardEvent<HTMLDivElement>, value: any) => {
    this.setState(prevState => {
      prevState.reminderRules[index].type = value;
      return prevState;
    });
  };

  onTimeValueChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => {
      prevState.reminderRules[index].time = parseInt(event.target.value);
      return prevState;
    });
  };

  onTimeUnitChange = (index: number, _: React.KeyboardEvent<HTMLDivElement>, value: any) => {
    this.setState(prevState => {
      prevState.reminderRules[index].timeUnit = value;
      return prevState;
    });
  };

  // Convert time in days/hours/minutes to minutes
  toMinutes = (time: number, unit: IUserNotificationRule['timeUnit']) => {
    if (unit === 'days') return 24 * 60 * time;
    else if (unit == 'hours') return 60 * time;
    else return time;
  };

  // Convert time in minutes to days/hours/minutes
  fromMinutes = (time: number, unit: IUserNotificationRule['timeUnit']) => {
    if (unit === 'days') return Math.floor(time / 60 / 24);
    else if (unit == 'hours') return Math.floor(time / 60);
    else return time;
  };

  public componentDidMount() {
    // set rules in minutes/hours/days format
    const reminderRules: IUserNotificationRule[] = this.state.reminderRules.map(rule => {
      let unit: IUserNotificationRule['timeUnit'] = 'minutes';
      if (rule.time >= 60 * 24) unit = 'days';
      else if (rule.time >= 60) unit = 'hours';
      return {
        time: this.fromMinutes(rule.time, unit),
        timeUnit: unit,
        type: rule.type,
      };
    });
    this.setState({ reminderRules });
  }

  public render() {
    const { theme } = Theme;
    return (
      <div>
        <Heading fontSize={20} style={{ lineHeight: 1.2 }}>
          Edit On-Call Reminder notification rules
        </Heading>
        <div style={{ marginTop: 30, marginBottom: 10 }}>
          <Para style={{ marginBottom: 20 }}>
            {this.state.reminderRules.length === 0
              ? ''
              : 'if you have an upcoming on-call shift, you will be notified via'}
          </Para>
          {this.state.reminderRules.length === 0 && (
            <CardBlock style={{ backgroundColor: theme.danger.light, marginBottom: 20 }}>
              You won't be notified about your shift. Add a rule if you wish to be notified.
            </CardBlock>
          )}
          {this.state.reminderRules.map((reminderRule, index) => {
            return (
              <div key={index}>
                <Grid alignItems="center" justifyContent="space-between">
                  <div className="select-box-container">
                    <SelectBox
                      hook={<Para>{reminderRule.type}</Para>}
                      onValueChange={this.onTypeChange.bind(this, index)}
                      error={!!reminderRule.validationError}
                      height="auto"
                      style={{ padding: 4 }}
                    >
                      {notificationTypes.map((type, index) => {
                        return (
                          <FocusBlock
                            key={index}
                            value={type}
                            isSelected={reminderRule.type === type}
                          >
                            {type}
                          </FocusBlock>
                        );
                      })}
                    </SelectBox>
                  </div>
                  <InputBlock
                    type="number"
                    value={reminderRule.time}
                    onChange={this.onTimeValueChange.bind(this, index)}
                    style={{
                      maxWidth: 100,
                      textAlign: 'center',
                      boxShadow: reminderRule.validationError
                        ? `0 0 0 1px ${theme.danger.default}`
                        : '',
                    }}
                    width={100}
                  />
                  <div className="select-box-container">
                    <SelectBox
                      hook={<Para>{reminderRule.timeUnit}</Para>}
                      onValueChange={this.onTimeUnitChange.bind(this, index)}
                      height="auto"
                      error={!!reminderRule.validationError}
                      style={{ padding: 4 }}
                    >
                      {notificationTimeTypes.map((type, index) => {
                        return (
                          <FocusBlock
                            key={index}
                            value={type}
                            isSelected={reminderRule.timeUnit === type}
                          >
                            {type || ''}
                          </FocusBlock>
                        );
                      })}
                    </SelectBox>
                  </div>
                  <Para>before it starts</Para>
                  <IconButton onClick={this.removeRule.bind(null, index)}>
                    <DeleteIcon color={theme.danger.default} />
                  </IconButton>
                </Grid>
                <ErrorBlock> {reminderRule.validationError}</ErrorBlock>
              </div>
            );
          })}
          <TextButton
            buttonType="ghost"
            onClick={this.addRule}
            style={{ color: theme.primary.default, fontWeight: 600 }}
          >
            Add More Rules
          </TextButton>
        </div>
        <ErrorBlock> {this.state.error.edit_notification_rule || ''}</ErrorBlock>
        <TextButton
          onClick={this.save}
          style={{
            color: theme.shades.white,
            padding: 10,
            width: 120,
            fontSize: 14,
            fontWeight: 600,
            marginTop: 10,
          }}
          disabled={this.state.saving}
        >
          Save
        </TextButton>
      </div>
    );
  }
}
export default connect((state: IAppState) => ({
  userInfo: state.userInfo,
}))(EditOncallReminderRules);
