import { T_WA_UP_SN_INCIDENT_SNOOZED, T_WA_UP_SN_INCIDENT_UNSNOOZED } from 'core/const/tracker';
import { exception } from 'core/exception';
import { IIAssigneUnsnooze } from 'core/interfaces/IIncidents';
import { IncidentService } from 'core/services';
import { useToast } from 'library/atoms';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { AppTracker } from 'shared/analytics/tracker';
import { invalIncidentsListingOnly } from 'views/main/organization/incident-list/common/util';
import { getSnoozeValueInHours, reassignmentValue } from './helper';
import { SnoozeTimer_Values } from './type';

export function useSnooze() {
  const _incidentService = new IncidentService();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [unSnoozing, setUnSnoozing] = useState<boolean>(false);

  const getSnoozeIntervals = () => {
    const snoozeIntervalsValues: number[] = [0.5, 1, 4, 8, 24];

    const snoozeInteval: SnoozeTimer_Values[] = snoozeIntervalsValues.map((val: number) => {
      if (val === 0.5)
        return {
          title: '30 Mins',
          value: val * 60,
        };
      else
        return {
          title: `${val} ${val === 1 ? 'Hr' : 'Hrs'}`,
          value: val * 60,
        };
    });

    return snoozeInteval;
  };

  const snoozeIncident = async (
    incidentId: string,
    snoozeInterval: number,
    incidentName: string,
    screenName: string,
    callback: () => void,
  ) => {
    try {
      setLoading(true);
      if (!incidentId) return;
      const res = await _incidentService.snoozeIncident(incidentId, {
        snooze_duration_in_mins: snoozeInterval,
      });
      if (res?.data) {
        AppTracker.track(T_WA_UP_SN_INCIDENT_SNOOZED, {
          'Snooze Duration': getSnoozeValueInHours(snoozeInterval),
          'Incident ID': incidentId,
          'Incident Snooze Location': screenName,
        });
        toast({
          status: 'success',
          text: `'${incidentName}' snoozed for ${getSnoozeValueInHours(snoozeInterval)}`,
        });
        invalIncidentsListingOnly(queryClient);
      }
    } catch (err: any) {
      toast({
        status: 'error',
        text: 'Error: Unable to snooze incident',
      });
      exception.handle('E_SNOOZE_INCIDENT', err);
    } finally {
      setLoading(false);
      callback();
    }
  };

  const unSnoozeIncident = async (
    incidentId: string,
    incidentName: string,
    type: IIAssigneUnsnooze,
    id: string,
    name: string,
    screenName: string,
    callback: () => void,
  ) => {
    try {
      setUnSnoozing(true);
      if (!incidentId) return;
      const res = await _incidentService.unSnoozeIncident(incidentId, {
        reassign_to: {
          id: id,
          type: type,
        },
      });
      if (res?.data) {
        AppTracker.track(T_WA_UP_SN_INCIDENT_UNSNOOZED, {
          'Unsnooze Reassign To': reassignmentValue(type),
          'Incident ID': incidentId,
          'Incident Unsnooze Location': screenName,
        });
        toast({
          status: 'success',
          text: `'${incidentName}' has been succesfully unsnoozed and Reassigned to  ${name}`,
        });
        invalIncidentsListingOnly(queryClient);
        callback();
      }
    } catch (err: any) {
      toast({
        status: 'error',
        text: 'Error: Unable to unsnooze incident',
      });
      exception.handle('E_SNOOZE_INCIDENT', err);
    } finally {
      setUnSnoozing(false);
    }
  };

  return { getSnoozeIntervals, snoozeIncident, unSnoozeIncident, loading, unSnoozing };
}
