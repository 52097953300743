import { Option } from 'views/main/organization/owner-filters/hooks/useEntityOwnerFilter';
import { create } from 'zustand';

export type EntityListStore = {
  page_number: number;
  page_size: number;
  search_term: string;
  entity_owner: Option[];

  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  setSearchTerm: (searchTerm: string) => void;
  setEntityOwner: (entityOwner: Option[]) => void;
};

export const useEntityListStore = create<EntityListStore>(set => ({
  page_number: 0,
  page_size: 5,
  search_term: '',
  entity_owner: [],

  setPageNumber: (pageNumber: number) => {
    set((state: EntityListStore) => ({ ...state, page_number: pageNumber }));
  },
  setPageSize: (pageSize: number) => {
    set((state: EntityListStore) => ({ ...state, page_size: pageSize }));
  },
  setSearchTerm: (searchTerm: string) => {
    set((state: EntityListStore) => ({ ...state, search_term: searchTerm }));
  },
  setEntityOwner: (entityOwner: Option[]) => {
    set((state: EntityListStore) => ({ ...state, entity_owner: entityOwner }));
  },
}));
