import './index.css';
import cx from 'classnames';
import { searchJson } from 'core/helpers';
import { RoutingRulesModal } from '.';
import {
  Grid,
  InputBlock,
  TextButton,
  IconButton,
  FocusBlock,
  SelectBox,
  SpinLoader,
  Heading,
  ErrorBlock,
  DialogModalFrame,
  Tooltip,
  Theme,
  JSONCodeBlock,
} from 'uie/components';
import { ProfileIcon, SquadIcon, EscalationIcon } from 'icons';
import { matchSorter } from 'match-sorter';
import UpgradePlanModal from 'components/upgradeplan.modal';
import BillingService from 'core/services/service.billing';
import { Button, Link, Text } from '@chakra-ui/react';
import {
  DropdownContainer,
  ICreatableOption,
} from 'views/main/organization/incidentDetails/renders/updateTags/tagGroup';

const { theme } = Theme;

const imgMap: any = {
  user: <ProfileIcon height={14} width={14} />,
  squad: <SquadIcon height={14} width={14} />,
  escalationpolicy: <EscalationIcon height={14} width={14} />,
  '': <></>,
};

function render(this: RoutingRulesModal) {
  const { rules, globalSearch } = this.state;

  let filteredRules = rules;

  try {
    const matchedRules = matchSorter(rules, globalSearch, {
      keys: ['expression', 'basic_expression.*.lhs', 'basic_expression.*.rhs'],
      baseSort: (r1, r2) => (r1.item.id < r2.item.id ? -1 : 1),
    });

    filteredRules = globalSearch ? matchedRules : filteredRules;
  } catch (_) {}

  const limit = BillingService.getLimit(this.props, 'routing-rules');

  return (
    <div>
      <div className="mb-10">
        <Grid justifyContent="space-between" alignItems="center">
          <Heading height={35} fontSize={24}>
            Incident Routing Rules
          </Heading>
        </Grid>
      </div>
      <div className="mb-10">
        <Grid justifyContent="space-between" alignItems="center">
          <Heading
            height={35}
            fontSize={14}
            style={{
              fontSize: '12px',
              color: '#808080',
              fontWeight: 300,
            }}
          >
            Auto-route alerts to the right responders based on the event tags attached to each
            alert. This will override the escalation policy attached to the service. Learn more
            about routing rules{' '}
            <Link
              href="https://support.squadcast.com/services/alert-routing"
              target="_blank"
              rel="noopener noreferrer"
              variant="underlinedLink"
            >
              here
            </Link>
            .
          </Heading>
        </Grid>
      </div>
      <div>
        <Grid flexWidth={12} justifyContent="flex-start" alignItems="stretch">
          <Grid flexWidth={7}>
            {this.state.componentState === 'error' && (
              <Grid flexWidth={12} justifyContent="center">
                <div className="mt-20 ml-20">
                  <Grid justifyContent="center" alignItems="center">
                    <small className="ml-10">
                      Looks like there is a network error! please try again.
                    </small>
                  </Grid>
                </div>
              </Grid>
            )}
            {this.state.componentState === 'busy' && (
              <Grid flexWidth={12} justifyContent="center">
                <div className="mt-20 ml-20">
                  <Grid justifyContent="center" alignItems="center">
                    <SpinLoader />
                    <small className="ml-10">Loading Tags</small>
                  </Grid>
                </div>
              </Grid>
            )}
            {this.state.componentState === 'idle' && (
              <div className="w-1-1">
                {this.state.rules.length === 0 ? (
                  <div className="empty-state-container w-1-1" style={{ marginBottom: 15 }}>
                    <img
                      src="/icons/empty-states/suppression rule.svg"
                      className="empty-state-img"
                      style={{ width: 120 }}
                      alt="deduplicationnRule"
                    />
                    <Text className="empty-state-headline" mb={10} mt={20}>
                      No rules found
                    </Text>
                  </div>
                ) : (
                  <>
                    {filteredRules
                      .filter(rule =>
                        this.state.editRuleId
                          ? rule.rule_id === this.state.editRuleId
                          : !rule.existing,
                      )
                      .map((rule, idx) => {
                        const index = rule.id;

                        return (
                          <div
                            key={index}
                            className={cx('main-rule-rectangle', {
                              'routing-rule-swap-animate':
                                this.state.swappedRuleIndexes[
                                  this.state.swappedRuleIndexes.length - 1
                                ] === index,
                            })}
                            style={{ paddingRight: 35 }}
                            tabIndex={0}
                            ref={e =>
                              e &&
                              this.state.swappedRuleIndexes[
                                this.state.swappedRuleIndexes.length - 1
                              ] === index &&
                              e.focus()
                            }
                          >
                            <div className="" style={{ marginBottom: '30px' }}>
                              <div className="field-parent-div">
                                <Grid
                                  justifyContent="space-between"
                                  className="mb-10"
                                  alignItems="center"
                                >
                                  <Grid alignItems="center">
                                    <Text variant="secondary">Routing Expression</Text>
                                    &nbsp;
                                    {rule.is_basic && (
                                      <Tooltip
                                        padding="8px"
                                        offset={{ top: '0px' }}
                                        width="300px"
                                        label="You can either write your Routing rule expression in the Routing Expression text box
                                     or use the GUI below to create simpler rules. What is input in the GUI will also be shown above in the text box as an expression"
                                      >
                                        <img
                                          src="/icons/info_black.svg"
                                          alt="more info"
                                          style={{ height: 16 }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Grid>
                                  {rule.is_basic && (
                                    <TextButton
                                      buttonType="ghost"
                                      onClick={this.openWarning(index)}
                                    >
                                      <Text variant="primartFitContent">Edit</Text>
                                      &nbsp;
                                      <img
                                        style={{ height: '1rem' }}
                                        src="/icons/blues/edit.svg"
                                        alt="edit"
                                      />
                                    </TextButton>
                                  )}
                                </Grid>
                                <InputBlock
                                  id={`expression-${index}`}
                                  placeholder={
                                    !rule.is_basic
                                      ? 'Enter routing rule'
                                      : 'Expression corresponding to conditions'
                                  }
                                  value={
                                    !rule.is_basic
                                      ? rule.expression
                                      : this.generateExpression(rule.basic_expression)
                                  }
                                  onChange={this.onRuleExpressionChange(index)}
                                  error={this.state.errors.includes(`rules[${index}].expression`)}
                                  height="50px"
                                  fontSize="14px"
                                  disabled={rule.is_basic}
                                />
                              </div>
                            </div>

                            {rule.is_basic && (
                              <>
                                {rule.basic_expression.map(
                                  (be: any, beIndex: number, array: any) => {
                                    return (
                                      <div key={beIndex}>
                                        <Grid justifyContent="space-between">
                                          <Grid>
                                            <Text
                                              m="auto"
                                              fontSize={14}
                                              height="24px"
                                              fontWeight={500}
                                            >
                                              If{' '}
                                            </Text>
                                          </Grid>
                                          <Grid flexWidth={5}>
                                            <DropdownContainer
                                              isMulti={false}
                                              defaultValue={{
                                                label:
                                                  rule.basic_expression[beIndex].lhs.split('.')[1],
                                                value: rule.basic_expression[beIndex].lhs,
                                              }}
                                              type="key"
                                              selected={
                                                rule.basic_expression[beIndex].lhs.split('.')[1]
                                              }
                                              menuPlaceholder="Select Tag"
                                              onChange={this.changeConditionSelectBox(
                                                index,
                                                beIndex,
                                                'lhs',
                                              )}
                                              options={
                                                this.state.tags.tags
                                                  ? Object.keys(this.state.tags.tags)
                                                      .map((t: any) => `tags.${t}`)
                                                      .map((r: string, i: any) => {
                                                        return {
                                                          value: r,
                                                          label: r.split('.')[1],
                                                        };
                                                      })
                                                  : []
                                              }
                                              placeholder="Search Existing Keys"
                                            />
                                          </Grid>
                                          <Grid>
                                            <Text
                                              m="auto"
                                              fontSize={14}
                                              height="24px"
                                              fontWeight={500}
                                            >
                                              Is{' '}
                                            </Text>
                                          </Grid>
                                          <Grid flexWidth={5}>
                                            <DropdownContainer
                                              isMulti={false}
                                              defaultValue={{
                                                value: rule.basic_expression[beIndex].rhs,
                                                label: rule.basic_expression[beIndex].rhs,
                                              }}
                                              type="value"
                                              selected={rule.basic_expression[beIndex].rhs}
                                              menuPlaceholder="Select Value"
                                              onChange={this.changeConditionSelectBox(
                                                index,
                                                beIndex,
                                                'rhs',
                                              )}
                                              options={
                                                this.state.tags.tags
                                                  ? Array.from(
                                                      searchJson(
                                                        this.state.tags,
                                                        rule.basic_expression[beIndex].lhs,
                                                      ),
                                                    ).map((r: any): ICreatableOption => {
                                                      return { value: r, label: r };
                                                    })
                                                  : []
                                              }
                                              placeholder="Search Existing Values"
                                            />
                                          </Grid>

                                          <Grid flexWidth={1}>
                                            <IconButton
                                              name="remove"
                                              onClick={this.removeCondition(index, beIndex)}
                                              base="32px"
                                              borderType="rounded"
                                              style={{ margin: 'auto' }}
                                            >
                                              <Text
                                                fontSize={14}
                                                height="24px"
                                                style={{ margin: 'auto' }}
                                              >
                                                <img src="/icons/remove.svg" alt="remove" />
                                              </Text>
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                        {beIndex < array.length - 1 && (
                                          <Grid
                                            justifyContent="center"
                                            style={{
                                              marginRight: '50px',
                                              marginTop: '20px',
                                              marginBottom: '20px',
                                            }}
                                          >
                                            <Text fontSize={14} height="24px" fontWeight={500}>
                                              AND
                                            </Text>
                                          </Grid>
                                        )}
                                      </div>
                                    );
                                  },
                                )}
                                <Grid justifyContent="flex-end" className="mt-20">
                                  {globalSearch.length === 0 && (
                                    <TextButton
                                      buttonType="ghost"
                                      onClick={this.addCondition(index)}
                                    >
                                      <Text variant="primartFitContent">+ Add Condition</Text>
                                    </TextButton>
                                  )}
                                </Grid>
                              </>
                            )}

                            <div className=" mt-20">
                              <div>
                                <div className="mb-10">
                                  <Text variant="secondary">Route to</Text>
                                </div>
                                <SelectBox
                                  hook={
                                    <div>
                                      {rule.route_to.entity_id.length > 0 ? (
                                        <Grid alignItems="center">
                                          {imgMap[rule.route_to.entity_type]}
                                          <Text className="ml-10" fontSize={14}>
                                            {rule.route_to.name}
                                          </Text>
                                        </Grid>
                                      ) : (
                                        <Text
                                          className="ml-10"
                                          fontSize={14}
                                          color={theme.shades.grey}
                                        >
                                          Users, escalation, squads
                                        </Text>
                                      )}
                                    </div>
                                  }
                                  searchHookProps={{
                                    value: rule.searchString,
                                    height: '24px',
                                    fontSize: '16px',
                                    onChange: e => {
                                      const val = e.target.value;
                                      this.setState(({ rules }) => {
                                        rules[index].searchString = val;
                                        return { rules };
                                      });
                                    },
                                  }}
                                  onValueChange={this.onRoutingvalueChange(index)}
                                  height="auto"
                                  maxHeight="200px"
                                  width="100%"
                                  maxWidth="100%"
                                >
                                  {this._searchMap
                                    .filter(e =>
                                      e.name
                                        .toLowerCase()
                                        .includes(rule.searchString.toLowerCase()),
                                    )
                                    .map((e, eIndex) => {
                                      return (
                                        <FocusBlock height={'100%'} key={eIndex} value={e}>
                                          <Grid alignItems="center">
                                            {imgMap[e.type]}
                                            <Text className="ml-10" fontSize={14}>
                                              {e.name}
                                            </Text>
                                          </Grid>
                                        </FocusBlock>
                                      );
                                    })}
                                </SelectBox>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
                {this.state.saveState === 'error' && (
                  <div>
                    <ErrorBlock>{this.state.networkError}</ErrorBlock>
                  </div>
                )}
                <ErrorBlock>{this.state.errors.length > 0 ? this.state.errors[0] : ''}</ErrorBlock>
                {this.state.errors.includes('rule_route') && (
                  <div>
                    <ErrorBlock>{'Please provide all Forward rules'}</ErrorBlock>
                  </div>
                )}
                <div>
                  <Grid justifyContent="flex-start" alignItems="center">
                    <Button
                      onClick={this.save}
                      disabled={this.state.saveState === 'saving'}
                      isLoading={this.state.saveState === 'saving'}
                      loadingText="Save Rule"
                      variant="default"
                      size="sm"
                    >
                      Save Rule
                    </Button>

                    {this.state.saveState === 'saved' && (
                      <small className="ml-20">All Changes Saved!</small>
                    )}
                  </Grid>
                </div>
              </div>
            )}
          </Grid>
          <Grid flexWidth={5}>
            {this.state.eventState === 'loading' && (
              <Grid flexWidth={12} justifyContent="center">
                <div className="mt-20 ml-20">
                  <Grid justifyContent="center" alignItems="center">
                    <SpinLoader />
                    <small className="ml-10">Loading event payload</small>
                  </Grid>
                </div>
              </Grid>
            )}
            {this.state.eventState === 'noEvents' && (
              <Grid flexWidth={12} justifyContent="center">
                <div className="mt-20 ml-20">
                  <Grid justifyContent="center" alignItems="center">
                    <small className="ml-10">No tags defined Yet</small>
                  </Grid>
                </div>
              </Grid>
            )}
            {this.state.eventState === 'idle' && Object.keys(this.state.tags).length > 0 && (
              <div className="w-1-1 mt-10" style={{ position: 'sticky', top: 20 }}>
                <JSONCodeBlock
                  code={this.state.tags}
                  enableSearch={true}
                  shellProps={{
                    minHeight: '600px',
                    minWidth: 'calc(100% - 15px)',
                  }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>

      <DialogModalFrame
        id="warning"
        onClose={this.closeWarning(false)}
        style={{ width: '50%', margin: 'auto' }}
        onClick={this.stopPropagation()}
      >
        {this.state.warningIndex !== null && (
          <Grid type="column">
            <Grid className="mb-20">
              <Heading height={35} fontSize={18} fontWeight={450}>
                Edit Routing Expression
              </Heading>
            </Grid>

            <Grid className="mb-20">
              <Text>
                Editing the rule will disable the GUI below for this rule. Click "
                <span className="font-bold">Proceed</span>" to continue
              </Text>
            </Grid>

            <Grid justifyContent="flex-end">
              <TextButton width="25%" onClick={this.closeWarning(true)}>
                <Text variant="proceed">Proceed</Text>
              </TextButton>
            </Grid>
          </Grid>
        )}
      </DialogModalFrame>
      {
        <UpgradePlanModal
          hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
          message={BillingService.getMessage(limit, 'routing-rules', this.props)}
          header={BillingService.getHeader(limit, 'routing-rules', this.props)}
          onCancel={() => this.setState({ showUpgradeModal: false })}
          showModal={this.state.showUpgradeModal}
        />
      }
    </div>
  );
}

export default render;
