import Axios from 'axios';
import { API } from 'core';
import { T_WA_UP_MR_REMOVED } from 'core/const/tracker';
import { toSentenceCase } from 'core/helpers/stringUtils';
import { IIAssigne } from 'core/interfaces/IIncidents';
import { DEFAULT_REACT_QUERY_CONFIG } from 'library/query-config';
import { useMutation, useQueryClient } from 'react-query';
import { AppTracker } from 'shared/analytics/tracker';

import { ResponderAPIProps } from '../types';
import { invalidateRespondersList, onAPIError, reactQueryConfigSuccess } from './helper';

const deleteResponder = ({ incidentId, responderId }: ResponderAPIProps) => {
  const url = `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${incidentId}/additional-responders/${responderId}`;
  return Axios.delete(url);
};

export const useDeleteResponderMutation = (
  incidentId: string,
  type: IIAssigne,
  name: string,
  onDeleteClose: () => void,
) => {
  const queryClient = useQueryClient();

  return useMutation(deleteResponder, {
    ...DEFAULT_REACT_QUERY_CONFIG,
    onSuccess: () => {
      invalidateRespondersList(queryClient);
      reactQueryConfigSuccess(`${name} removed successfully from responders list`);
      AppTracker.track(T_WA_UP_MR_REMOVED, {
        'Incident ID': incidentId,
        'Type of responder removed': toSentenceCase(type),
      });
      onDeleteClose();
    },
    onError: err => {
      onAPIError(err);
      onDeleteClose();
    },
  });
};
