export const changeTTRTimeFormat = (milliseconds: number) => {
  // Convert milliseconds to seconds
  const totalSeconds = milliseconds / 1000;

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return `${hours > 0 ? hours + 'h' : ''} ${minutes > 0 ? minutes + 'm' : ''} ${
    seconds > 0 ? seconds + 's' : ''
  }`;
};
