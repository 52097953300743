import { Grid, Para } from 'uie/components';
import { FC } from 'react';

type Props = {
  isConfirmationModal?: boolean;
};

export const Note: FC<Props> = ({ isConfirmationModal }) => {
  return (
    <Grid style={isConfirmationModal ? { marginTop: '8px' } : { marginBottom: '8px' }}>
      <Para fontSize={14}>
        Note: Please know that if a user is added as an additional responder for certain incidents,
        they will be removed from the responder list.
      </Para>
    </Grid>
  );
};
