import { AssigneeType } from 'library/enums';
import { IDedupData } from './IDedupData';
import { IAttachmentRequest } from './ISignUpload';
import { IPinnedMessage } from './IWarroom';
import { pastIncidentsDefaultSortState } from 'views/main/organization/incidentDetails/constants';
import { PRIORITY_TYPE } from 'library/types';
import { IncidentPriority } from 'library/common';
import { ILCOLUMN } from 'views/main/organization/incident-list/store';
import { CardType as CommunicationCardType } from './ICommunicationCard';

export type IIncidentStatus = 'triggered' | 'acknowledged' | 'resolved' | 'suppressed';
export type IIAssigne = 'user' | 'squad' | 'escalationpolicy';
export type IIAssigneUnsnooze = 'user' | 'squad' | 'escalationpolicy' | 'me' | 'snoozer';
export type PostmortemOptions = 'included' | 'excluded';
export type SloAffectingOptions = 'yes' | 'no';
export type NotesOptions = 'present' | 'absent';

export interface IIncidentMetric {
  triggered: number;
  acknowledged: number;
  resolved: number;
  suppressed: number;
  mtta: number;
  mttr: number;
}

export interface IITags {
  [key: string]: {
    value: string;
    color: string;
  };
}

export interface IIncidentCreatePayload {
  message: string;
  description: string;
  assignee: {
    type: IIAssigne;
    id: string;
  };
  tags: IITags;
  serviceApiKey: string;
  created_by: string;
  attachments: IAttachmentRequest[];
  priority: IncidentPriority | null;
}

export interface IIAnalytics {
  tta: {
    time: number;
    userId: string;
  };
  ttr: {
    time: number;
    userId: string;
  };
}

export interface SloViolatingIncident {
  error_budget_spent: number;
  id: number;
  incident_id: string;
  is_false_positive: boolean;
  slis: string[];
  slo_id: number;
  slo_name: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: string | undefined;
  org_id: string;
  owner_id: string;
  owner_type: string;
  start_time: Date;
  end_time: Date;
}
export interface SloArray {
  slos: SloObject[];
}
export interface SloObject {
  id: number;
  name: string;
  description: string;
  time_interval_type: string;
  service_ids: Array<string>;
  slis: Array<string>;
  target_slo: number;
  current_slo: number;
  start_time: string;
  end_time: string;
  remaining_error_budget: number;
  allocated_error_budget: number;
  is_active: boolean;
  tags: { [k: string]: any };
  incident_count: number;
  false_positive_count: number;
  slo_monitoring_checks: Array<IMonitoringChecks> | null;
  slo_actions: Array<ISlo_actions> | null;
  is_healthy: boolean;
  duration_in_days: number;
}
export interface ISlo_actions {
  org_id: string;
  owner_id: string;
  owner_type?: string;
  service_id?: string;
  squad_id?: string;
  type: string;
  user_id?: string;
}
export interface IMonitoringChecks {
  name: string;
  org_id: string;
  owner_id: string;
  owner_type?: string;
  threshold: number;
}

export interface slo {
  allocated_error_budget: number;
  description: string;
  false_positive_count: number;
  id: number;
  incident_count: number;
  is_active: boolean;
  is_healthy: boolean;
  name: string;
  org_id: string;
  owner_id: string;
  owner_type: string;
  remaining_error_budget: number;
  service_ids: Array<any>;
  slis: string[];
  slo_actions: string;
  slo_monitoring_checks: string;
  tags: { Type: string; Owner: string; Environment: string };
  target_slo: number;
  time_interval_type: string;
}

export interface ISnoozeType {
  is_snoozed: boolean;
  start_time: Date | null;
  end_time: Date | null;
  total_time: number;
}
export interface IIncident {
  id: string;
  service_id: string;
  message: string;
  event_count: number;
  organization_id: string;
  description: string;
  status: IIncidentStatus;
  timeOfCreation: Date;
  last_acknowledged_at: Date;
  logs: ILog[];
  pinned_messages: IPinnedMessage[];
  snooze_details: ISnoozeType;
  responseNotes: any[];
  assignedTo: IAssignedTo[];
  additional_responders: IAssignedTo[] | null;
  alert_source_id: string;
  priority: PRIORITY_TYPE;
  relevantUsers: string[];
  relevantPeopleLogs: any[];
  tags?: IITags;
  analytics?: IIAnalytics;
  created_by: string;
  sender_email: string;
  slo_id: number;
  updated_at: Date;
  dedup_data: IDedupData;
  is_child: boolean;
  children: {
    id: string;
  }[];
  did_auto_pause: boolean;
  manually_marked_transient_alert_feedback_type: 'negative' | 'positive' | '';
  parent?: {
    id: string;
    message: string;
    status: string;
  };
  grouped_alert_count: number;
  did_notification_delay: boolean;
  owner: {
    id: string;
    type: string;
  };
}

export interface IChildIncident {
  id: string;
  message: string;
  service: {
    id: string;
    name: string;
  };
  alert_source_id: string;
  timeOfCreation: string;
  tags: IITags;
  priority: PRIORITY_TYPE;
}

export type VOTE_TYPE = 'negative' | 'positive' | null;
export interface IAutoGroupAlert {
  id: string;
  message: string;
  tags: {
    key: string;
    value: string;
    color: string;
  }[];
  alert_source: {
    id: string;
    name: string;
    icon: {
      svg: string;
    };
  };
  created_at: string;
  vote_type: VOTE_TYPE;
}

export interface IAutoGroupAlertDetail extends IAutoGroupAlert {
  description: string;
}
export interface IAutoGroupData {
  data: IAutoGroupAlert[];
  meta: {
    count: number;
  };
}

export interface IPastIncident {
  id: string;
  message: string;
  tags: {
    key: string;
    value: string;
    color: string;
  }[];
  alert_source: {
    id: string;
    name: string;
    icon: {
      svg: string;
    };
  };
  last_assigned_to: {
    id: string;
    type: AssigneeType;
    name: string;
    username_for_display?: string;
  };
  created_at: string;
  resolution_type: 'Manual' | 'Automatic';
  ttr: number; // milliseconds
  incident_activity: 'Low' | 'Medium' | 'High';
  priority: PRIORITY_TYPE;
}

type IPastIncidentStat = {
  count: number;
  mttr: number;
};

export enum PastIncidentsDataRanges {
  Days_180 = '180_days',
  Days_90 = '90_days',
  Days_7 = '7_days',
}

export type IPastIncidentStatsResponse = {
  [PastIncidentsDataRanges.Days_180]: IPastIncidentStat;
  [PastIncidentsDataRanges.Days_90]: IPastIncidentStat;
  [PastIncidentsDataRanges.Days_7]: IPastIncidentStat;
};

export type IHeatmapAPIResponse = {
  date: string;
  count: number;
}[];

export type IHeatmapData = {
  date: string;
  value: number;
}[];

export type IPastIncidentListCursorMeta = {
  page_limit: number;
  page_offset: number;
  total_count: number;
};

export type IPastIncidentListParameters = {
  activeDate: string;
  sortingState: typeof pastIncidentsDefaultSortState;
  pageOffset: number;
  pageLimit: number;
  onPageLimitChange: (limit: number) => void;
  onPageChange: (type: 'prev' | 'next') => void;
  onSortChange: (id: 'created_at' | 'incident_activity', desc: boolean) => void;
  maxPages: number;
  cursorMeta: IPastIncidentListCursorMeta;
};

export interface IAssignedTo {
  type: IIAssigne;
  id: string;
  timeOfAssignment: Date;
}

export interface ILog {
  action: string;
  actionType: string;
  assignedTo: string;
  assignedBy: string;
  id: string;
  time: Date;
  reason: string;
  additionalInfo?: IAdditionalInfo;
  workflow_id?: number;
  type: 'activity' | 'action';
}

export interface IRunbook {
  id: string;
  name: string;
  runbook_id: string;
}

export interface ICheckActivities {
  type: string;
  id: string;
  index: number;
}
export interface IRunbookAndTaskActivity {
  txt: string;
  isRunbook: boolean;
  focusId: string;
  index: number;
}

export interface IAdditionalInfo {
  action_id: string;
  payload: any;
  response: any;
  isManual?: any;
  statusPageId?: any;
  status?: string;
  userId?: string;
  message?: string;
  note_id?: string;
  note?: string;
  routing_rule_applied?: any;
  suppression_rule_applied?: any;
  added_task?: string;
  content?: string;
  no_of_tasks?: number;
  tasks_id?: string;
  count?: number;
  runbookIDs?: Array<string>;
  checked?: boolean;
  index?: number;
  no_of_steps?: number;
  runbookID?: string;
  name?: string;
  task_index?: number;
  new_content?: string;
  runbooks?: Array<IRunbook>;
  task_id?: string;
  runbookName?: string;
  children_count?: number;
  parent?: string;
  child?: string;
  action_exec_id?: number;
  wf_exec_id?: string;
  workflow_name?: string;
  is_unsnooze_action?: boolean;
  old_user_id?: string;
  to?: string[];
  subject?: string;
  url?: string;
  channel_name?: string;
  channel_id?: string;
  member_id?: string;
  status_page_request?: {
    StatusPageID: number;
    IssueTitle: string;
  };
  meeting_link?: string;
  comm_card_type?: CommunicationCardType;
  comm_card_title?: string;
  comm_card_url?: string;
  snooze_start_time?: string;
  snooze_end_time?: string;
  slack_channel_url?: string;
  global_rule_expression_matched?: string;
  servicenow_incident_link: string;
}

export interface IIncidentRequestParams {
  selectedUnit: number;
  incidentsFor: 'all' | 'yours';
  limit: number;
  from: number;
  status: IIncidentStatus[];
  services: string[];
  postmortem: string;
  sloAffecting: string;
  slo: number[];
  notes: string;
  assignedTo: string[];
  integrationTypes: string[];
  customDates: boolean;
  fromDate: string | Date;
  toDate: string | Date;
  tag: string[];
  textFilter: string;
  owner_id: string;
}

export interface IActiveAlertSourceRequestParams {
  fromDate: string;
  toDate: string;
}

export interface IReassignIncidentRequest {
  incidentId: string;
  reassignTo: {
    type: IIAssigne;
    id: string;
  };
}

export interface ISnoozeIncidentRequest {
  snooze_duration_in_mins: number;
}

export interface IUnSnoozeIncidentRequest {
  reassign_to: {
    type: IIAssigneUnsnooze;
    id: string;
  };
}
export interface IReassignIncidentRequestV3 {
  reassignTo: {
    type: IIAssigne;
    id: string;
  };
}

export interface IIncidentList {
  result: boolean;
  currentData: IIncidentMetric;
  previousData: IIncidentMetric;
  incidents: IIncident[];
}

export interface IIncidentCount {
  currentData: IIncidentMetric;
  previousData: IIncidentMetric;
}

export interface IIEvent {
  incident_id: string;
  alert_source_id: string;
  organization_id: string;
  service_id: string;
  message: string;
  description: string;
  time_of_creation: Date;
  event_type: string;
  payload: any;
  deduplication_reason: IIDedupReason;
  is_deduped: boolean;
  dedup_data: IDedupData;
}

export interface IIDedupReason {
  matched_event_id: string;
  evaluated_expression: string;
  time_window: number;
  env?: IIEnv;
}

export interface IIEnv {
  rhs: any;
}

export interface IIPayload {
  Key: string;
  Value: IIPayloadValue;
}

export type IIPayloadValue = IIPayloadEvents[] | string;

export interface IIPayloadEvents {
  Key: string;
  Value: string;
}

export interface IIncidentSearch {
  took: number;
  timed_out: boolean;
  hits: {
    total: {
      value: number;
    };
    max_score: number;
    hits: {
      _index: string;
      _id: string;
      _score: number;
      _source: IIncident;
    }[];
  };
}

// Incident Filter interfaces

export interface IIFilter {
  key: FilterTypes;
  title: string;
  value?: FilterTypeValues;
}

export interface IIFilterOption {
  key: FilterTypes;
  name: string;
}

export type FilterTypes =
  | 'time'
  | 'service'
  | 'alert_source'
  | 'assigned_to'
  | 'tags'
  | 'status'
  | 'postmortem'
  | 'slo'
  | 'notes';

export type DateOptions =
  | 'Today'
  | 'This Week'
  | 'This Month'
  | 'Last 7 Days'
  | 'Last 30 Days'
  | 'Last 365 Days'
  | 'Custom Range';

export const ogOptions: IIFilterOption[] = [
  { name: 'Date & Time', key: 'time' },
  { name: 'Service', key: 'service' },
  { name: 'Alert Source', key: 'alert_source' },
  { name: 'Assigned To', key: 'assigned_to' },
  { name: 'Tags', key: 'tags' },
  { name: 'Status', key: 'status' },
  { name: 'Postmortem', key: 'postmortem' },
  { name: 'SLO Affecting ', key: 'slo' },
  { name: 'Incident Notes', key: 'notes' },
];

export interface IIFilters {
  time?: IIFilterTime;
  service?: IIFilterService;
  alert_source?: IIFilterAlertSource;
  assigned_to?: IIFilterAssignedTo;
  tags?: IIFilterTags;
  status?: IIFilterStatus;
  postmortem?: IIFilterPostmortem;
  slo?: IIFilterSlo;
  notes?: IIFilterNotes;
}

export type FilterTypeValues =
  | IIFilterTime
  | IIFilterService
  | IIFilterAlertSource
  | IIFilterAssignedTo
  | IIFilterTags
  | IIFilterStatus
  | IIFilterPostmortem
  | IIFilterSlo
  | IIFilterNotes;

export interface IIFilterTime {
  range?: number;
  custom: boolean;
  startDate?: Date;
  endDate?: Date;
  name?: string;
  rangeType?: 'week' | 'month';
}

export interface IIFilterService {
  values: string[];
}

export interface IIFilterAlertSource {
  values: string[];
}

export interface IIFilterAssignedTo {
  values: string[];
}

export interface IIFilterTags {
  values: string[];
}

export interface IIFilterStatus {
  value: IIncidentStatus[];
}

export interface IIFilterPostmortem {
  value: PostmortemOptions;
}
export interface IIFilterSlo {
  type: SloAffectingOptions;
  value: number[];
}
export interface IIFilterNotes {
  value: NotesOptions;
}
export interface IIncidentExportParams {
  start_time: string;
  end_time: string;
  services: string[];
  sources: string[];
  assigned_to: string[];
  assignedToUserIDsAndTheirSquads: string[];
  // follows entityOwner query param format
  service_owner: string;
  status: string[];
  tags: string[];
  type: IIncidentExportFormat;
  textFilter: string;
  owner_id: string;
  slo_affecting: string;
  slos: number[];
  notes: string;
  sort_by: ILCOLUMN;
}

export type ServiceOwner = {
  userIDsAndTheirSquads?: string[];
  userIDs?: string[];
  squadIDs?: string[];
};

export type IIncidentExportFormat = 'csv' | 'json';

export interface IIncidentTags {
  service: string;
  tags: {
    key: string;
    values: string[];
  }[];
}

export interface INotificationLog {
  status: 'scheduled' | 'dispatched' | 'sent' | 'canceled' | 'failed' | 'delivered';
  cause: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  device_id: string;
  type: 'push' | 'sms' | 'phone' | 'email';
  sent: boolean;
  time_of_notification: Date;
  time_of_delivery: Date;
  user_id?: string;
}

export interface SloOptions {
  label: string;
  value: number;
}

export interface IIncidentOverviewResponse {
  status: IIncidentStatus;
  service: {
    id: string;
    name: string;
    owner: {
      id: string;
      type: string;
      name: string;
      username: string;
    };
  };
  participants: {
    id: string;
    type: string;
    name: string;
    username: string;
  }[];
}

export interface IIncidentSummaryResponse {
  content: string;
}

export interface IIncidentSummaryResponseMetadata {
  generated_at: string;
}

export interface IIncidentSummaryFeedbackRequestOptions {
  feedback: 'positive' | 'negative';
  comment: string;
}

export interface IIncidentSummaryFeedbackResponse {
  message: string;
}
