import { Circle, Icon, SquareProps, Text } from '@chakra-ui/react';
import { HiCheck } from 'react-icons/hi';
import React from 'react';
interface RadioCircleProps extends SquareProps {
  isCompleted: boolean;
  isActive: boolean;
  stepNumber: number;
}

export const StepCircle = (props: RadioCircleProps) => {
  const { isCompleted, isActive } = props;
  return (
    <Circle
      size="8"
      bg={isActive ? 'primary.default' : 'rgba(231, 233, 236, 1)'}
      borderWidth={isCompleted ? '0' : '2px'}
      borderColor={isActive ? 'inherit' : 'accent'}
      {...props}
    >
      <Text color={isActive ? 'white' : 'black'} fontWeight={500} fontSize={12} fontStyle="normal">
        {props.stepNumber + 1}
      </Text>
    </Circle>
  );
};
