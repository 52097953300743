import { ContainerLoad, FocusBlock, FocusedSearch, Grid, Para, Tabs } from 'uie/components';
import React, { ChangeEvent, Component, useMemo } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteChildrenProps } from 'react-router';
import { IAppState } from '../../../../../../core/interfaces/IAppState';
import { ITeam } from '../../../../../../core/interfaces/ITeams';
import './index.css';
import OrganizationSettingsTeamsRenderSettings from './settings';
import OrganizationSettingsTeamsRenderRoles from './roles';
import OrganizationSettingsTeamsRenderSquads from './squads';
import OrganizationSettingsTeamsRenderEntities from './entities';
import OrganizationSettingsTeamsRenderMembers from './members';
import { checkIfActionChanged } from 'core/helpers';
import { requestOrganizationSelectedTeamChange } from '../../../../../../core/actions/organization/selectedTeam';
import { Link } from 'react-router-dom';
import { RouterGuardProvider, RouterGuardContext } from 'components/UnsavedChangeNavigation';
import { OrganizationSettingsStakeHolderGroupRenderRoles } from './stakeholders';
import { CrownIcon } from 'icons';
import { BillingService } from 'core';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { SquadBasedPermissionSquadPage } from './squads/components';
import libraryTheme from 'library/theme';
import { SquadBasedPermissionRoles } from './roles/SquadBasedPermissionRoles';
import { EntityList } from './entities/EntityList';
import { ObacMember } from './members/OBACMember';
import { UserAccessContextValue, withUserAccess } from 'core/userAccess/UserAccessContext';

type ITab = 'members' | 'roles' | 'entities' | 'squads' | 'stakeholder' | 'settings';
type IMix = Pick<IAppState, 'organization'> & RouteChildrenProps<{ id: string }>;
interface IProps extends IMix {
  requestOrganizationSelectedTeamChange: typeof requestOrganizationSelectedTeamChange;
  tab: ITab;
  userAccess: UserAccessContextValue;
}

interface IState {
  tab: ITab;
  teamId: string;
  team: ITeam | null;
  refreshId: number;
  isDeleted: boolean;
  isChildRequest: boolean;
  searchString: string;
  hasUnsavedOnChild: boolean;
}

class OrganizationSettingsTeamsRender extends Component<IProps, IState> {
  static contextType = RouterGuardContext;
  constructor(props: IProps) {
    super(props);

    const teamId = props.match?.params.id || '';

    this.state = {
      tab: this.props.tab !== undefined ? this.props.tab : 'members',
      teamId,
      team: props.organization.teams.t.find(t => t.id === teamId) || null,
      refreshId: new Date().getTime(),
      isDeleted: false,
      isChildRequest: false,
      searchString: '',
      hasUnsavedOnChild: false,
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const teamId = this.props.match?.params.id || '';
    const navChange = this.props.match?.params.id !== prevProps.match?.params.id;

    if (
      navChange ||
      checkIfActionChanged(
        prevProps.organization.teams,
        this.props.organization.teams,
        'action',
        'REQUEST_ORG_TEAMS_SUCCESS',
      )
    ) {
      const availableTeam = this.props.organization.teams.t.find(t => t.id === teamId);
      const defaultTeam = availableTeam || this.props.organization.teams.t.find(t => t.default)!;

      if (!availableTeam) {
        this.props.requestOrganizationSelectedTeamChange(defaultTeam.id);
      }

      this.setState(({ tab }) => ({
        tab: navChange ? 'members' : tab,
        teamId: defaultTeam.id,
        team: defaultTeam,
        refreshId: new Date().getTime(),
        isDeleted: !availableTeam,
        searchString: '',
      }));
    }
  }

  onTabSelect = (tab: any) => {
    if ((tab as string) !== this.state.tab) {
      this.props.history.push(`/settings/teams/${this.state.teamId}/${tab}`);
    }
    if (this.state.hasUnsavedOnChild) {
      return this.context.setGuardRelease(() => {
        this.setState({ hasUnsavedOnChild: false }, () => {
          this.onTabSelect?.(tab);
        });
      });
    }
    return this.setState({ tab, searchString: '' });
  };

  sethasUnsavedOnChild = (unsavedExist: boolean) => {
    this.setState({ hasUnsavedOnChild: unsavedExist });
  };

  onChildRequest = (isChildRequest: boolean) => this.setState({ isChildRequest });

  onSearch = (ev: ChangeEvent<HTMLInputElement>) =>
    this.setState({ searchString: ev.target.value });

  render() {
    const { team, tab, refreshId, isDeleted, isChildRequest, searchString } = this.state;
    const isEntityOwnerRBACEnabled = this.props.organization.currentOrg.o?.config?.obac_enabled;
    const { organization } = this.props;
    const { teams } = organization;
    const userAccess = this.props.userAccess;
    const tabMaps: Record<ITab, boolean> = {
      members: true,
      roles: true,
      entities: true,
      squads: userAccess.hasReadAccess('squads'),
      stakeholder: userAccess.hasReadAccess('stakeholder_groups'),
      settings: !team?.default && userAccess.hasReadAccess('teams'),
    };

    const tabs = Object.entries(tabMaps).filter(([_, render]) => render);

    if (isDeleted) {
      return <Redirect to={`/settings/teams/${team?.id}`} />;
    }

    let selectedSquadName = '';
    if (tab == 'squads') {
      const params = new URL(window.location.href).searchParams;
      const squadName = params.get('name');
      if (squadName !== null && squadName !== undefined) {
        selectedSquadName = squadName;
      }
    }

    const isStakeHolderThisFeatureAvailable = () => {
      return organization
        ? !BillingService.isFeatureDisabled(
            { organization } as unknown as Pick<IAppState, 'organization'>,
            'stakeholder-groups',
          )
        : true;
    };

    return (
      <>
        <Grid
          type="column"
          flexWidth={12}
          className="org_settings_teams__container_padding global--soft-scroll"
        >
          <Grid alignItems="center" justifyContent="space-between" style={{ marginTop: 8 }}>
            <Tabs
              initialTab={tab}
              onTabSelect={this.onTabSelect}
              tabWidth="110px"
              shellWidth={`calc(110px * ${tabs.length})`}
              isManual={true}
            >
              {tabs.map(([key], i) => (
                <FocusBlock value={key} key={i} style={{ minWidth: 'max-content' }}>
                  <Box display={'flex'}>
                    <Para className="global--capitalize-first-letter" fontWeight={400}>
                      {key === 'stakeholder' ? 'Stakeholder Groups' : key}
                    </Para>
                    {key === 'stakeholder' && !isStakeHolderThisFeatureAvailable() && (
                      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        <CrownIcon style={{ marginLeft: 4 }} />
                      </Box>
                    )}
                  </Box>
                </FocusBlock>
              ))}
            </Tabs>
            {(tab === 'members' || tab === 'roles' || tab === 'squads') &&
              !isEntityOwnerRBACEnabled && (
                <FocusedSearch
                  value={searchString}
                  onChange={this.onSearch}
                  height="32px"
                  width="150px"
                  fontSize="14px"
                  placeholder={`Search ${tab}`}
                />
              )}
          </Grid>
          <div style={{ width: `calc(110px * ${tabs.length})` }}>
            <ContainerLoad isLoading={teams.action === 'REQUEST_ORG_TEAMS' || isChildRequest} />
          </div>
          <div style={{ padding: '16px 0px 0 0' }}>
            {tab === 'members' ? (
              isEntityOwnerRBACEnabled ? (
                <ChakraProvider theme={libraryTheme}>
                  <ObacMember />
                </ChakraProvider>
              ) : (
                <OrganizationSettingsTeamsRenderMembers
                  team={team!}
                  refreshId={refreshId}
                  updateChildInRequest={this.onChildRequest}
                  search={searchString}
                  hasUnsavedChanges={this.state.hasUnsavedOnChild}
                  sethasUnsavedOnChild={this.sethasUnsavedOnChild}
                />
              )
            ) : null}
            {tab === 'roles' ? (
              isEntityOwnerRBACEnabled ? (
                <SquadBasedPermissionRoles />
              ) : (
                <OrganizationSettingsTeamsRenderRoles
                  updateChildInRequest={this.onChildRequest}
                  team={team!}
                  refreshId={refreshId}
                  search={searchString}
                  hasUnsavedChanges={this.state.hasUnsavedOnChild}
                  sethasUnsavedOnChild={this.sethasUnsavedOnChild}
                />
              )
            ) : null}

            {tab === 'squads' &&
              (isEntityOwnerRBACEnabled ? (
                <ChakraProvider theme={libraryTheme}>
                  <SquadBasedPermissionSquadPage />
                </ChakraProvider>
              ) : (
                <OrganizationSettingsTeamsRenderSquads
                  team={team!}
                  refreshId={refreshId}
                  updateChildInRequest={this.onChildRequest}
                  search={this.getSearchStringForSquads(searchString, selectedSquadName)}
                  hasUnsavedChanges={this.state.hasUnsavedOnChild}
                  sethasUnsavedOnChild={this.sethasUnsavedOnChild}
                />
              ))}
            {tab === 'entities' && (
              <ChakraProvider theme={libraryTheme}>
                <EntityList />
              </ChakraProvider>
            )}
            {tab === 'stakeholder' && (
              <OrganizationSettingsStakeHolderGroupRenderRoles
                organization={this.props.organization as unknown as Pick<IAppState, 'organization'>}
                team={team!}
                disableEditTab={false}
                stakeHolderGroupData={[]}
                refetch={() => {}}
              />
            )}
            {tab === 'settings' && (
              <OrganizationSettingsTeamsRenderSettings team={team!} refreshId={refreshId} />
            )}
          </div>
        </Grid>
      </>
    );
  }

  private getSearchStringForSquads(searchString: string, selectedSquadName: string): string {
    if (searchString.length > 0) {
      return searchString;
    }
    return selectedSquadName;
  }
}

export default connect(({ organization }: IAppState) => ({ organization }), {
  requestOrganizationSelectedTeamChange,
})(withUserAccess(OrganizationSettingsTeamsRender));
