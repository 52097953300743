import DrawerFooterWrapper from 'library/molecules/Drawer';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import { incidentNoteValidation } from 'views/main/organization/workflow/constant/validation';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { IncidentNoteAction } from 'views/main/organization/workflow/types';
import { FormFooter } from '../form-footer';
import IncidentNoteForm from './IncidentNoteForm';

const IncidentNoteContainer = () => {
  const { actionForm, onActionSubmit, checkDirtyAndCloseDrawer } = useWorkflowFormContext();
  return (
    <FormWrapper<IncidentNoteAction>
      onSubmit={onActionSubmit}
      defaultValues={actionForm! as IncidentNoteAction}
      validationSchema={incidentNoteValidation}
    >
      <IncidentNoteForm />
      <DrawerFooterWrapper>
        <FormFooter<IncidentNoteAction> checkDirtyAndCloseDrawer={checkDirtyAndCloseDrawer} />
      </DrawerFooterWrapper>
    </FormWrapper>
  );
};

export default IncidentNoteContainer;
