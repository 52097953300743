import momentTimeZone from 'moment-timezone';
import { DateTime } from 'luxon';
import moment from 'moment';
import { DETAILS_TABS } from './status.constants';
import { StatusHelpGuide } from '../Interface';
export const statusPageTextCopy = {
  stepper: {
    step1: 'Status Page Details',
    step2: 'Add Component',
    step3: 'Customize your page',
    step4: 'Add Issue',
  },
};

export const timezones = momentTimeZone.tz
  .names()
  .sort((a, b) => {
    return DateTime.local().setZone(a).offset - DateTime.local().setZone(b).offset;
  })
  .map(timezone => ({
    label: `${moment.tz(timezone).zoneAbbr()}
  (${DateTime.local().setZone(timezone).toFormat('ZZZZ')})
  ${timezone.replace(/_/g, ' ')}`,
    value: timezone,
  }));

export const DETAILS_TAB_LABELS = {
  [DETAILS_TABS.ISSUES]: 'Issues',
  [DETAILS_TABS.MAINTENANCE]: 'Maintenance',
  [DETAILS_TABS.SUBSCRIBERS]: 'Subscribers',
};

export const statusConfigHelpGuide: StatusHelpGuide = [
  {
    title: 'How does your name and description show up?',
    desc: [
      'The Status Page name is utilized when we send notifications to your customers, ensuring consistent branding and communication.',
    ],
    link: [
      {
        url: 'https://support.squadcast.com/status-page/status-page#1.-add-status-page-details',
        label: 'Read More',
      },
    ],
    image: [
      {
        path: '/assets/statuspage-header.png',
        alt: 'page header',
      },
    ],
    type: 'help',
  },
  {
    title: 'What does the timezone affect?',
    desc: [
      'Choose a timezone for your page to display updates and notifications accurately. Opt for UTC as a standard practice or select a timezone that aligns with the majority of your users.',
    ],
    link: [
      {
        url: 'https://support.squadcast.com/status-page/status-page#1.-add-status-page-details',
        label: 'Read More',
      },
    ],
    type: 'help',
  },
  {
    title: 'Why give a description?',
    desc: [
      'We recommend utilizing the description field to inform your users about the best way to reach out to you in case of any issues. You can include links to your support email, website, or provide a brief guide on the preferred method of communication.',
    ],
    link: [],
    type: 'help',
  },
  {
    title: 'How to pick between public or private?',
    desc: [
      'Choose the Public option for anyone with the URL to access and subscribe to your page. Opt for Private to restrict access to authenticated Squadcast users with necessary permissions.',
    ],
    link: [],
    note: 'This cannot be changed later.',
    type: 'help',
  },
  {
    title: 'How can you customize your URL?',
    desc: [
      "You have the flexibility to customize your URL in the Page Configuration settings at a later time. To activate the custom URL, you'll need to verify ownership of the domain by creating new CNAME and TXT records in your DNS.",
    ],
    link: [],
    type: 'help',
  },
];

export const statusComponentHelpGuide: StatusHelpGuide = [
  {
    title: 'What is a Component?',
    desc: [
      'Components are the functional units of your service or infrastructure, like your websites, API endpoints, and databases. We also recommend creating components for resources or services used by the end-user.',
    ],
    type: 'help',
  },
  {
    title: 'Why add a description?',
    desc: [
      "Assign meaningful one-liner descriptions to your components, which appear alongside their names. These descriptions offer additional context, enhancing the understanding of each component's functionality.",
    ],
    link: [
      {
        url: 'https://support.squadcast.com/status-page/status-page#2.-add-component',
        label: 'Read More',
      },
    ],
    type: 'help',
    image: [
      {
        path: '/assets/statuspage-component.png',
        alt: 'component graph',
      },
    ],
  },
  {
    title: 'What is a Group?',
    desc: [
      'Groups are a logical collection of components that helps in visually classifying the functionalities of your product. It gives users an easy way to understand information that is essential to them',
    ],
    link: [
      {
        label: 'Read More',
        url: 'https://support.squadcast.com/status-page/components-and-groups#components-and-component-groups',
      },
    ],

    type: 'help',
  },
];

export const statusThemeHelpGuide: StatusHelpGuide = [
  {
    title: 'How would this look on the actual Status Page?',
    desc: [],
    image: [
      {
        path: '/assets/statuspage-theme.png',
        alt: 'page theme',
      },
    ],
    link: [
      {
        label: 'Read more',
        url: 'https://support.squadcast.com/status-page/status-page#3.-customize-your-page',
      },
    ],
    type: 'help',
  },
];

export const statusIssueHelpGuide: StatusHelpGuide = [
  {
    title: 'What is an Issue?',
    desc: [
      'An Issue is created to communicate a change in functionality or availability of your services. An Issue has the following fields:',
    ],
    list: ['Affected Component', 'Affected Component Impact', 'Status Message', 'Issue Message(s)'],
    link: [
      {
        label: 'Read more',
        url: 'https://support.squadcast.com/status-page/status-page#4.-add-your-first-issue',
      },
    ],
    type: 'help',
  },
  {
    title: 'What is a Status Message and how does it show up?',
    desc: [
      'The Status Message serves as the prominent header on your status page, effectively communicating the current status of your product or service.',
    ],
    link: [
      {
        label: 'Read more',
        url: 'https://support.squadcast.com/status-page/status-page#4.-add-your-first-issue',
      },
    ],
    image: [
      {
        path: '/assets/statuspage-issue.png',
        alt: 'page issue',
      },
    ],
    type: 'help',
  },
  {
    title: 'What is an Update and Issue Status?',
    desc: [
      'Issue Status refers to the current stage of resolution for an issue. It is accompanied by an Update, which provides a concise description of the issue, including the cause and, if known, the planned resolution.',
      'Each Update is associated with one of the following four Issue States: Investigating, Identified, Monitoring, and Resolved. You can follow the link below to know more on these states.',
    ],
    link: [
      {
        label: 'Read more',
        url: 'https://support.squadcast.com/status-page/status-page#4.-add-your-first-issue',
      },
    ],
    type: 'help',
  },
];
