import { useDisclosure } from '@chakra-ui/hooks';
import { Grid, Para, TextButton, Theme } from 'uie/components';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import '../../../../../components/upgradeonly.tooltip/index.css';
import { getHeader, getMessage, hasManageBillingPermission, isFeatureDisabled } from './Billing';
import DialogModalFrame from './LegacyDialogModalFrame';
import { useSession } from './Session';
import { BillingGateProps, ModalProps, UseBillingModalProps } from './types.BillingGate';
import { useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';

const { theme } = Theme;

export const BillingModal = ({
  isOpen,
  message,
  header,
  onUpgrade,
  onCancel,
  onClose,
  hasBillingPermission,
}: ModalProps) => {
  if (!isOpen) return null;

  return (
    <>
      <DialogModalFrame
        root="unsaved-changes-confirm"
        id="modalFrame"
        onClose={onClose}
        padding="24px"
        width="520px"
        openOnRender
      >
        <>
          <Para
            fontSize={24}
            fontWeight={500}
            className="mb-10 mt-10"
            style={{
              fontStyle: 'normal',
              fontFamily: 'Mulish',
              lineHeight: '40px',
              color: '#2D3964',
            }}
          >
            {header}
          </Para>

          <Para
            fontSize={16}
            fontWeight={500}
            style={{
              fontFamily: 'Mulish',
              fontStyle: 'normal',
              lineHeight: '24px',
              color: '#969AA0',
            }}
            color={theme.shades.cement}
          >
            {message}
            <br />
            <br />
            {!hasBillingPermission ? (
              <>
                You do not seem to have the right permissions to upgrade the current plan. Reach out
                to your Account Owner for the same.
              </>
            ) : (
              <>
                To use this feature,{' '}
                <Link
                  to="/settings/billings"
                  target="_blank"
                  rel="noreferrer"
                  className="modal_link"
                >
                  upgrade to one of our higher plans
                </Link>{' '}
                or learn more about all our plans{' '}
                <a
                  href="https://www.squadcast.com/pricing"
                  target="_blank"
                  rel="noreferrer"
                  className="modal_link"
                >
                  here
                </a>
              </>
            )}
          </Para>

          <div style={{ marginTop: 15 }}>
            <Grid justifyContent="flex-end">
              <TextButton
                buttonType="ghost"
                onClick={onCancel}
                aria-label="cancel"
                style={{ padding: '4px 8px' }}
                className="mr-20"
              >
                <Para color={theme.font.default} fontWeight={500} fontSize={16}>
                  Cancel
                </Para>
              </TextButton>

              <TextButton
                style={{ padding: '4px 8px' }}
                onClick={onUpgrade}
                aria-label="upgrade"
                disabled={!hasBillingPermission}
              >
                <Para color={theme.font.white} fontWeight={500} fontSize={16}>
                  Upgrade
                </Para>
              </TextButton>
            </Grid>
          </div>
        </>
      </DialogModalFrame>
    </>
  );
};

export function BillingGate({ pricingEntity, children }: BillingGateProps) {
  const { session } = useSession();

  const defaultIsOpen = isFeatureDisabled(session.plan, pricingEntity);
  const props = useBillingModal({
    pricingEntity,
    limit: 0,
    defaultIsOpen,
  });

  return (
    <>
      {children}
      <BillingModal {...props} />
    </>
  );
}

export function useBillingModal({
  pricingEntity,
  limit,
  defaultIsOpen,
  ...callbacks
}: UseBillingModalProps) {
  const disclosure = useDisclosure({
    defaultIsOpen,
  });
  const { session } = useSession();
  const history = useHistory();
  const currentUser = useSelector((state: IAppState) => state.organization.currentUser.u);

  const header = getHeader(limit, pricingEntity);
  const message = getMessage(limit, pricingEntity, session.plan);
  const hasBillingPermission = hasManageBillingPermission(currentUser);

  const onUpgrade = () => {
    history.push('/settings/billings');
    callbacks.onUpgrade?.();
  };

  const onCancel = () => {
    disclosure.onClose();
    callbacks.onCancel?.();
  };

  const onClose = () => {
    disclosure.onClose();
    callbacks.onClose?.();
  };

  return {
    header,
    message,
    isOpen: disclosure.isOpen,
    onUpgrade,
    onCancel,
    onClose,
    hasBillingPermission,
  };
}
