import React from 'react';
import { IStakeholderDependencies } from 'core/interfaces/ITeams';
import { CardBlock, CellBlock, Grid, Heading, Label, Para } from 'uie/components';
import { IAppState } from 'core/interfaces/IAppState';
import { connect } from 'react-redux';
import TeamDependencies from './teamDependencies';
import { Link } from 'react-router-dom';

interface IProps extends Pick<IAppState, 'organization'> {
  userId: string;
  dependencies: IStakeholderDependencies[];
  closeModal: () => void;
  getStakeholderConflicts: (userId: string) => void;
  header: string;
}

const StakeholderDependencies: React.FC<IProps> = (props: IProps) => {
  const oldUser = props.organization.users.u.find(u => u.id === props.userId);
  return (
    <Grid type="column">
      <Heading height={35} fontSize={24} style={{ marginBottom: 20 }}>
        Convert to {props.header}
      </Heading>
      <Label>
        Resolve the following dependencies to convert{' '}
        {oldUser?.first_name + ' ' + oldUser?.last_name} into a stakeholder
      </Label>
      {props.dependencies.map(teamDeps => {
        const team = props.organization.teams.t.find(t => t.id === teamDeps.team_id);
        if (!team || !oldUser) {
          return;
        }
        return (
          <CardBlock key={team.id} style={{ margin: 10, width: 'auto' }}>
            <Heading height={30} fontSize={20}>
              {team.name}:
            </Heading>
            <Grid type="column" style={{ marginTop: 10 }}>
              {teamDeps.dependency_exists && (
                <TeamDependencies
                  dependencies={teamDeps.dependencies}
                  team={team}
                  oldUser={oldUser}
                  organization={props.organization}
                  getStakeholderConflicts={props.getStakeholderConflicts}
                />
              )}
              {teamDeps.nra_roles.length > 0 && (
                <Grid type="column" style={{ marginTop: 20 }}>
                  <Grid>
                    <Para>
                      User has following Non Read-Only roles in the team. Stakeholder cannot have
                      these roles:
                    </Para>
                  </Grid>
                  <Grid style={{ padding: 16 }}>
                    {teamDeps.nra_roles.map(role => (
                      <CellBlock key={role.slug} style={{ margin: 8 }}>
                        <Para>{role.name}</Para>
                      </CellBlock>
                    ))}
                  </Grid>
                  <Grid>
                    <Para>
                      You can edit roles for {oldUser?.first_name + ' ' + oldUser?.last_name} in{' '}
                      {team.name}{' '}
                      <Link to={'/settings/teams/' + team.id} target={'_blank'}>
                        here
                      </Link>
                    </Para>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardBlock>
        );
      })}
    </Grid>
  );
};

export default connect(({ organization }: IAppState) => ({
  organization,
}))(StakeholderDependencies);
