export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Int64: any;
  ObjectID: any;
  Time: any;
};

export type Acl = {
  __typename?: 'ACL';
  hasDeleteAccess: Scalars['Boolean'];
  hasUpdateAccess: Scalars['Boolean'];
  hasUpdateOwnerAccess: Scalars['Boolean'];
};

export type AdditionalResponder = {
  __typename?: 'AdditionalResponder';
  id: Scalars['String'];
  name: Scalars['String'];
  timeOfAssignment: Scalars['Time'];
  type: Scalars['String'];
};

export type Analytics = {
  __typename?: 'Analytics';
  tta?: Maybe<Scalars['Int64']>;
  ttr?: Maybe<Scalars['Int64']>;
};

export type AssignableEntity = Squad | Team | User;

export type AssignedToInput = {
  id: Scalars['String'];
  type: Scalars['String'];
};

export type Attachment = {
  fileSize: Scalars['Int'];
  key: Scalars['String'];
  mimeType: Scalars['String'];
};

export type BulkReAssignIncidentResponse = {
  __typename?: 'BulkReAssignIncidentResponse';
  errMsg: Scalars['String'];
  errors: Array<BulkReassignIncidentError>;
  isSuccess: Scalars['Boolean'];
};

export type BulkReassignIncidentError = {
  __typename?: 'BulkReassignIncidentError';
  errMsg: Scalars['String'];
  incidentID: Scalars['String'];
};

export type BulkReassignIncidentInput = {
  incidentIDs: Array<Scalars['String']>;
  reAssignToID: Scalars['String'];
  reAssignToType: IncidentAssignToType;
};

export enum ChangeParticipantsInterval {
  Day = 'day',
  Month = 'month',
  Rotation = 'rotation',
  Week = 'week',
}

export type ComponentDayLog = {
  __typename?: 'ComponentDayLog';
  date: Scalars['Time'];
  overallStatus: OverallComponentStatus;
  statusDurations: StatusDurations;
};

export type CountIncidentReq = {
  filters?: InputMaybe<IncidentFilters>;
  statuses?: InputMaybe<Array<InputMaybe<IncidentStatusType>>>;
  teamID: Scalars['String'];
};

export type CoverageRotations = {
  __typename?: 'CoverageRotations';
  ID: Scalars['Int'];
  changeParticipantsFrequency: Scalars['Int'];
  changeParticipantsUnit: ChangeParticipantsInterval;
  color?: Maybe<Scalars['String']>;
  customPeriod?: Maybe<CustomPeriod>;
  customPeriodFrequency?: Maybe<Scalars['Int']>;
  customPeriodUnit?: Maybe<CustomPeriodUnit>;
  endDate?: Maybe<Scalars['Time']>;
  endsAfterIterations?: Maybe<Scalars['Int']>;
  events?: Maybe<Array<ScheduleEvent>>;
  name: Scalars['String'];
  participantGroups?: Maybe<Array<ParticipantGroup>>;
  period: PeriodOptions;
  scheduleID: Scalars['Int'];
  shiftTimeSlot?: Maybe<TimeSlot>;
  shiftTimeSlots: Array<TimeSlot>;
  startDate: Scalars['Time'];
};

export type CreateIncidentNoteReq = {
  attachments: Array<InputMaybe<Attachment>>;
  incidentID: Scalars['String'];
  message: Scalars['String'];
};

export type CreateIncidentReq = {
  assignedTo: AssignedToInput;
  attachments: Array<Attachment>;
  description: Scalars['String'];
  message: Scalars['String'];
  priority?: InputMaybe<IncidentPriority>;
  serviceID: Scalars['String'];
  tags: Array<IncidentTagInput>;
};

export type CreateStatusPageSubscriberRes = {
  __typename?: 'CreateStatusPageSubscriberRes';
  message?: Maybe<Scalars['String']>;
  subscriber: StatusPageSubscriber;
};

export type CursorMetadata = {
  __typename?: 'CursorMetadata';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  next?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  prev?: Maybe<Scalars['String']>;
};

export type CustomPeriod = {
  __typename?: 'CustomPeriod';
  periodFrequency: Scalars['Int'];
  periodUnit: CustomPeriodUnit;
  timeSlots: Array<TimeSlot>;
};

export enum CustomPeriodUnit {
  Day = 'day',
  Month = 'month',
  Week = 'week',
}

export enum DateFilterChoice {
  Days = 'days',
  Month = 'month',
  Range = 'range',
  Week = 'week',
}

export enum DayOfWeek {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
}

export type DeleteScheduleResponse = {
  __typename?: 'DeleteScheduleResponse';
  ID: Scalars['Int'];
  conflictingEscalationPolicies?: Maybe<Array<EscalationPolicy>>;
  name: Scalars['String'];
  schedule: Schedule;
};

export type DeleteStatusPageComponentRes = {
  __typename?: 'DeleteStatusPageComponentRes';
  allowSubscription: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type DeleteStatusPageGroupRes = {
  __typename?: 'DeleteStatusPageGroupRes';
  allowSubscription: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type DeleteStatusPageIssueRes = {
  __typename?: 'DeleteStatusPageIssueRes';
  id: Scalars['Int'];
  pageID: Scalars['Int'];
  title: Scalars['String'];
};

export type DeleteStatusPageRes = {
  __typename?: 'DeleteStatusPageRes';
  id: Scalars['Int'];
  name: Scalars['String'];
  organizationID: Scalars['String'];
  teamID: Scalars['String'];
};

export type DomainRecord = {
  __typename?: 'DomainRecord';
  isVerified: Scalars['Boolean'];
  value: Scalars['String'];
};

export enum EntityType {
  User = 'user',
  Workflow = 'workflow',
}

export type EscalationPolicy = {
  __typename?: 'EscalationPolicy';
  ID: Scalars['String'];
  name: Scalars['String'];
};

export type EscalationPolicyToReplacementMapping = {
  escalationPolicyID: Scalars['String'];
  scheduleIDs?: InputMaybe<Array<Scalars['Int']>>;
  squadIDs?: InputMaybe<Array<Scalars['String']>>;
  userIDs?: InputMaybe<Array<Scalars['String']>>;
};

export type ExportStatusPageSubscribersRes = {
  __typename?: 'ExportStatusPageSubscribersRes';
  message: Scalars['String'];
};

export type ExtensionsIntegrated = {
  __typename?: 'ExtensionsIntegrated';
  jiraCloud: Scalars['Boolean'];
  jiraServer: Scalars['Boolean'];
  serviceNow: Scalars['Boolean'];
};

export enum FilterChoice {
  No = 'no',
  Yes = 'yes',
}

export type Gap = {
  __typename?: 'Gap';
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
};

export type GetStatusPageIssueRes = {
  __typename?: 'GetStatusPageIssueRes';
  components?: Maybe<Array<Maybe<IssueComponent>>>;
  currentIssueState: Status;
  id: Scalars['Int'];
  isMigrated: Scalars['Boolean'];
  issues: Array<Maybe<Issues>>;
  lastUpdatedAt: Scalars['Time'];
  pageID: Scalars['Int'];
  pageStatus: Status;
  statusMaintenance: ListStatusPagesStatus;
  title: Scalars['String'];
  underMaintenance: Scalars['Boolean'];
};

export type Incident = {
  __typename?: 'Incident';
  alertSource: IncidentAlertSource;
  alertSourceID: Scalars['String'];
  assignedTo: IncidentAssignee;
  children: Array<MergeIncidentEntityReference>;
  description: Scalars['String'];
  didAutoPause: Scalars['Boolean'];
  didNotificationDelay: Scalars['Boolean'];
  eventCount: Scalars['Int'];
  groupedAlertCount: Scalars['Int'];
  hasNotes: Scalars['Boolean'];
  hasRetrospectives: Scalars['Boolean'];
  id: Scalars['String'];
  isStarred: Scalars['Boolean'];
  lastAcknowledgedAt?: Maybe<Scalars['Time']>;
  message: Scalars['String'];
  orgID: Scalars['String'];
  priority?: Maybe<IncidentPriority>;
  retrospectiveStatus?: Maybe<Scalars['String']>;
  service: IncidentService;
  serviceID: Scalars['String'];
  sloID: Scalars['Int'];
  snoozeDetails: SnoozeDetails;
  status: IncidentStatusType;
  tags: Array<IncidentTag>;
  timeOfCreation: Scalars['Time'];
};

export type IncidentActiveTags = {
  __typename?: 'IncidentActiveTags';
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type IncidentActivity = {
  __typename?: 'IncidentActivity';
  action: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  time: Scalars['Time'];
  type: Scalars['String'];
};

export type IncidentAlertSource = {
  __typename?: 'IncidentAlertSource';
  iconURL: IncidentAlertSourceIcon;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type IncidentAlertSourceIcon = {
  __typename?: 'IncidentAlertSourceIcon';
  png: Scalars['String'];
  svg: Scalars['String'];
};

export enum IncidentAssignToType {
  Escalationpolicy = 'escalationpolicy',
  Squad = 'squad',
  User = 'user',
}

export type IncidentAssignableEntities = {
  __typename?: 'IncidentAssignableEntities';
  escalationPolicies: Array<EscalationPolicy>;
  squads: Array<Squad>;
  teamID: Scalars['String'];
  users: Array<User>;
};

export type IncidentAssignee = {
  __typename?: 'IncidentAssignee';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  usernameForDisplay?: Maybe<Scalars['String']>;
};

export type IncidentCount = {
  __typename?: 'IncidentCount';
  totalCount: Scalars['Int'];
};

export type IncidentDetails = {
  __typename?: 'IncidentDetails';
  activities: Array<IncidentActivity>;
  additionalResponders: Array<AdditionalResponder>;
  alertSource: IncidentAlertSource;
  alertSourceID: Scalars['String'];
  analytics: Analytics;
  assignedTo: IncidentAssignee;
  children: Array<MergeIncidentEntityReference>;
  description: Scalars['String'];
  didAutoPause: Scalars['Boolean'];
  didNotificationDelay: Scalars['Boolean'];
  eventCount: Scalars['Int'];
  groupedAlertCount: Scalars['Int'];
  id: Scalars['String'];
  lastAcknowledgedAt?: Maybe<Scalars['Time']>;
  message: Scalars['String'];
  notes: Array<IncidentNote>;
  orgID: Scalars['String'];
  ownerID: Scalars['String'];
  parent?: Maybe<MergeIncidentEntityReference>;
  payload: Scalars['String'];
  priority?: Maybe<IncidentPriority>;
  responders: Array<Responder>;
  service: IncidentService;
  serviceID: Scalars['String'];
  serviceNowIncidentMapping?: Maybe<ServiceNowIncidentMapping>;
  snoozeDetails: SnoozeDetails;
  status: IncidentStatusType;
  tags: Array<IncidentTag>;
  timeOfCreation: Scalars['Time'];
};

export type IncidentDetailsNotesArgs = {
  createdByMe: Scalars['Boolean'];
  isStarred: Scalars['Boolean'];
};

export type IncidentFilters = {
  alertSourceIDs?: InputMaybe<Array<Scalars['String']>>;
  assignedToMe?: InputMaybe<Scalars['Boolean']>;
  customDateFrom?: InputMaybe<Scalars['String']>;
  customDateTo?: InputMaybe<Scalars['String']>;
  dateFilter?: InputMaybe<DateFilterChoice>;
  hasNotes?: InputMaybe<FilterChoice>;
  hasRetrospectives?: InputMaybe<FilterChoice>;
  isSLO?: InputMaybe<FilterChoice>;
  isStarred?: InputMaybe<FilterChoice>;
  lastNumberOfDays?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Array<IncidentPriorityFilter>>;
  responderIDs?: InputMaybe<Array<Scalars['String']>>;
  retrospectiveStatus?: InputMaybe<Array<Scalars['String']>>;
  serviceIDs?: InputMaybe<Array<Scalars['String']>>;
  sloIDs?: InputMaybe<Array<Scalars['Int']>>;
  tags?: InputMaybe<Array<InputMaybe<IncidentTagFilter>>>;
  text?: InputMaybe<Scalars['String']>;
};

export type IncidentGroup = {
  __typename?: 'IncidentGroup';
  filters: IncidentGroupFilters;
  incidentGroupID: Scalars['Int'];
  isPrivate: Scalars['Boolean'];
  name: Scalars['String'];
  userID: Scalars['String'];
};

export type IncidentGroupFilters = {
  __typename?: 'IncidentGroupFilters';
  alertSourceIDs?: Maybe<Array<Scalars['String']>>;
  alertSources?: Maybe<Array<IncidentAlertSource>>;
  assignedToMe?: Maybe<Scalars['Boolean']>;
  customDateFrom?: Maybe<Scalars['String']>;
  customDateTo?: Maybe<Scalars['String']>;
  dateFilter?: Maybe<DateFilterChoice>;
  hasNotes?: Maybe<FilterChoice>;
  hasRetrospectives?: Maybe<FilterChoice>;
  id: Scalars['Int'];
  isSLO?: Maybe<FilterChoice>;
  isStarred?: Maybe<FilterChoice>;
  lastNumberOfDays?: Maybe<Scalars['Int']>;
  priority?: Maybe<Array<IncidentPriorityFilter>>;
  responderIDs?: Maybe<Array<Scalars['String']>>;
  responders?: Maybe<Array<IncidentAssignee>>;
  retrospectiveStatus?: Maybe<Array<Scalars['String']>>;
  serviceIDs?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<IncidentService>>;
  sloIDs?: Maybe<Array<Scalars['Int']>>;
  slos?: Maybe<Array<Slo>>;
  tags?: Maybe<Array<IncidentTagFilterType>>;
  text?: Maybe<Scalars['String']>;
};

export type IncidentList = {
  __typename?: 'IncidentList';
  data: Array<Maybe<Incident>>;
  meta?: Maybe<CursorMetadata>;
};

export type IncidentListReq = {
  cursorValue?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<IncidentFilters>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  paginationType?: InputMaybe<PaginationType>;
  sortBy?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<IncidentStatusType>>>;
  teamID: Scalars['String'];
};

export type IncidentNote = {
  __typename?: 'IncidentNote';
  attachments: Array<Scalars['String']>;
  createdAt: Scalars['Time'];
  createdByEntityType: EntityType;
  date: Scalars['String'];
  id: Scalars['String'];
  isStarred: Scalars['Boolean'];
  message: Scalars['String'];
  metadata?: Maybe<NoteMetadata>;
  replacedMessage: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Time'];
  user: User;
  userID: Scalars['String'];
};

export enum IncidentPriority {
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P5 = 'P5',
}

export enum IncidentPriorityFilter {
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P5 = 'P5',
  Unset = 'UNSET',
}

export type IncidentResponse = {
  __typename?: 'IncidentResponse';
  assignedToID: Scalars['String'];
  assignedToType: Scalars['String'];
  id: Scalars['String'];
  status: Scalars['String'];
};

export type IncidentService = {
  __typename?: 'IncidentService';
  delayedNotificationsEnabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type IncidentStatusStats = {
  __typename?: 'IncidentStatusStats';
  acknowledged: Scalars['Int'];
  open: Scalars['Int'];
  resolved: Scalars['Int'];
  suppressed: Scalars['Int'];
  total: Scalars['Int'];
  triggered: Scalars['Int'];
};

export type IncidentStatusStatsReq = {
  filters?: InputMaybe<IncidentFilters>;
  teamID: Scalars['String'];
};

export enum IncidentStatusType {
  Acknowledged = 'acknowledged',
  Resolved = 'resolved',
  Suppressed = 'suppressed',
  Triggered = 'triggered',
}

export type IncidentTag = {
  __typename?: 'IncidentTag';
  color: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type IncidentTagFilter = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type IncidentTagFilterType = {
  __typename?: 'IncidentTagFilterType';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type IncidentTagInput = {
  color: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type IssueComponent = {
  __typename?: 'IssueComponent';
  id: Scalars['Int'];
  status: Status;
  statusMaintenance: ListStatusPagesStatus;
  underMaintenance: Scalars['Boolean'];
};

export type IssueComponentWithName = {
  __typename?: 'IssueComponentWithName';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type IssueHistoryComponents = {
  __typename?: 'IssueHistoryComponents';
  group?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  status: Scalars['String'];
  type: NewStatusPageComponentType;
};

export type IssueHistoryData = {
  __typename?: 'IssueHistoryData';
  date: Scalars['Time'];
  issues?: Maybe<Array<Maybe<IssueHistoryIssue>>>;
};

export type IssueHistoryIssue = {
  __typename?: 'IssueHistoryIssue';
  components: Array<IssueHistoryComponents>;
  createdAt: Scalars['Time'];
  currentState: Scalars['String'];
  id: Scalars['Int'];
  resolvedAt?: Maybe<Scalars['Time']>;
  states: Array<IssueHistoryState>;
  title: Scalars['String'];
};

export type IssueHistoryRequest = {
  duration?: InputMaybe<IssuesFilterTimeType>;
  pageID: Scalars['Int'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type IssueHistoryResponse = {
  __typename?: 'IssueHistoryResponse';
  data: Array<IssueHistoryData>;
  meta: PageMetaData;
};

export type IssueHistoryState = {
  __typename?: 'IssueHistoryState';
  messages: Array<StatusPageIssueMessageRes>;
  name: Scalars['String'];
  stateID: Scalars['Int'];
};

export type IssueStateTemplate = {
  defaultMessage?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type IssueStateTemplateRes = {
  __typename?: 'IssueStateTemplateRes';
  defaultMessage: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Issues = {
  __typename?: 'Issues';
  state: Status;
  stateMessages: Array<Maybe<StatusPageIssueMessageRes>>;
};

export enum IssuesFilterTimeType {
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
}

export type IssuesFilters = {
  componentGroupID: Array<Scalars['Int']>;
  componentID: Array<Scalars['Int']>;
  componentStatusSlugs: Array<Scalars['String']>;
  currentIssueState: Array<Scalars['Int']>;
  time?: InputMaybe<IssuesFilterTimeType>;
};

export type LegacySchedule = {
  __typename?: 'LegacySchedule';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type LegacyScheduleEvent = {
  __typename?: 'LegacyScheduleEvent';
  endTime: Scalars['Time'];
  id: Scalars['String'];
  isOverride: Scalars['Boolean'];
  override: ScheduleOverride;
  name: Scalars['String'];
  participants?: Maybe<Array<Participant>>;
  schedule: LegacySchedule;
  startTime: Scalars['Time'];
};

export type LegacyScheduleFilters = {
  endTime: Scalars['Time'];
  scheduleIDs?: InputMaybe<Array<Scalars['String']>>;
  startTime: Scalars['Time'];
};

export type LegacyWhoIsOnCallForSchedule = {
  __typename?: 'LegacyWhoIsOnCallForSchedule';
  onCallParticipants?: Maybe<Array<Participant>>;
  schedule: LegacySchedule;
};

export type ListStatusPageIssueRes = {
  __typename?: 'ListStatusPageIssueRes';
  data: Array<Maybe<StatusPageIssueRes>>;
  meta: PageMetaData;
};

export type ListStatusPageIssues = {
  filters?: InputMaybe<IssuesFilters>;
  pageID: Scalars['Int'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type ListStatusPageMaintenanceRes = {
  __typename?: 'ListStatusPageMaintenanceRes';
  data: Array<Maybe<StatusPageMaintenanceDetail>>;
  meta: PageMetaData;
};

export type ListStatusPageMaintenances = {
  endTime: Scalars['Time'];
  pageID: Scalars['Int'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Time'];
};

export type ListStatusPageSubscriber = {
  __typename?: 'ListStatusPageSubscriber';
  componentList: Array<Maybe<StatusPageSubscriberComponent>>;
  emailID: Scalars['String'];
  id: Scalars['Int'];
  status: Scalars['String'];
  subscribedOn: Scalars['Time'];
  type: StatusPageSubscriberType;
  webhookURL: Scalars['String'];
};

export type ListStatusPageSubscriberMeta = {
  __typename?: 'ListStatusPageSubscriberMeta';
  lastWeekSubscribersCount: Scalars['Int'];
  maximumSubscribersLimit: Scalars['Int'];
  totalSubscribersCount: PageMetaData;
};

export type ListStatusPageSubscribersReq = {
  pageID: Scalars['Int'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type ListStatusPageSubscribersRes = {
  __typename?: 'ListStatusPageSubscribersRes';
  data: Array<Maybe<ListStatusPageSubscriber>>;
  meta: ListStatusPageSubscriberMeta;
};

export type ListStatusPagesComponent = {
  __typename?: 'ListStatusPagesComponent';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ListStatusPagesFilter = {
  isPublic?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  statusSlugs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListStatusPagesReq = {
  filters?: InputMaybe<ListStatusPagesFilter>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  teamID: Scalars['String'];
};

export type ListStatusPagesRes = {
  __typename?: 'ListStatusPagesRes';
  data: Array<Maybe<StatusPageRes>>;
  meta: PageMetaDataWithOrgTotalCount;
};

export type ListStatusPagesStatus = {
  __typename?: 'ListStatusPagesStatus';
  color: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type ListUsers = {
  __typename?: 'ListUsers';
  data: Array<User>;
  meta: PageMetaData;
};

export type ListUsersFilters = {
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Array<InputMaybe<UserRole>>>;
};

export type ListUsersName = {
  __typename?: 'ListUsersName';
  data: Array<UserName>;
};

export enum MaintenanceState {
  Completed = 'completed',
  Ongoing = 'ongoing',
  Upcoming = 'upcoming',
}

export type MentionEntities = {
  __typename?: 'MentionEntities';
  squads: Array<Squad>;
  stakeholderGroups: Array<StakeholderGroup>;
  team: Team;
  teamID: Scalars['String'];
  users: Array<UserName>;
};

export type MergeIncidentEntityReference = {
  __typename?: 'MergeIncidentEntityReference';
  id: Scalars['String'];
};

export type MockEvent = {
  __typename?: 'MockEvent';
  endTime: Scalars['Time'];
  mockParticipants?: Maybe<Array<Maybe<MockParticipant>>>;
  startTime: Scalars['Time'];
};

export type MockEvents = {
  __typename?: 'MockEvents';
  events?: Maybe<Array<MockEvent>>;
  onCallDurationPerParticipant: Scalars['Float'];
  rotationIndex: Scalars['Int'];
};

export type MockEventsOnCallDurationPerParticipantArgs = {
  In?: InputMaybe<TimeDurationUnit>;
};

export type MockEventsData = {
  __typename?: 'MockEventsData';
  gaps?: Maybe<Array<Gap>>;
  mockEvents?: Maybe<Array<MockEvents>>;
};

export type MockParticipant = {
  __typename?: 'MockParticipant';
  ID: Scalars['String'];
  type: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acknowledgeIncident: IncidentResponse;
  bulkReassignIncident: BulkReAssignIncidentResponse;
  changePassword: Scalars['String'];
  cloneSchedule: Schedule;
  createICalURL: Scalars['String'];
  createIncident: Scalars['String'];
  createIncidentGroup: Scalars['Int'];
  createIncidentNote: Scalars['String'];
  createJiraCloudTicket: Scalars['String'];
  createJiraServerTicket: Scalars['String'];
  createOverride: ScheduleOverride;
  createRotation: Rotation;
  createSchedule: Schedule;
  createStatusPage: StatusPage;
  createStatusPageComponent: StatusPageComponentRes;
  createStatusPageGroup: StatusPageGroupRes;
  createStatusPageIssue: StatusPageIssue;
  createStatusPageMaintenance: StatusPageMaintenance;
  createStatusPageSubscriber: CreateStatusPageSubscriberRes;
  deactivateOrganization: Organization;
  deleteICalURL: Scalars['String'];
  deleteIncidentGroup: Scalars['Int'];
  deleteIncidentNote: Scalars['String'];
  deleteOrganization: Organization;
  deleteOverride: ScheduleOverride;
  deleteRotation?: Maybe<Rotation>;
  deleteSchedule: DeleteScheduleResponse;
  deleteStatusPage: DeleteStatusPageRes;
  deleteStatusPageComponent: DeleteStatusPageComponentRes;
  deleteStatusPageGroup: StatusPageGroupRes;
  deleteStatusPageIssue: DeleteStatusPageIssueRes;
  deleteStatusPageMaintenance: StatusPageMaintenance;
  pauseSchedule: Schedule;
  reassignIncident: IncidentResponse;
  refreshICalURL: Scalars['String'];
  resolveIncident: IncidentResponse;
  resumeSchedule: Schedule;
  starIncident: Scalars['String'];
  starIncidentNote: Scalars['String'];
  updateIncidentGroup: Scalars['Int'];
  updateIncidentNote: Scalars['String'];
  updateIncidentTags: Array<IncidentTag>;
  updateNotificationRules: Scalars['String'];
  updateOnCallReminderRules: Scalars['String'];
  updateOrganizationName: Organization;
  updateOrganizationOwner: Organization;
  updateOverride: ScheduleOverride;
  updateRotation: Rotation;
  updateSchedule: Schedule;
  updateStatusPageComponent: UpdateStatusPageComponentRes;
  updateStatusPageComponentListOrder: Array<UpdateStatusPageComponentListOrderRes>;
  updateStatusPageConfiguration: UpdateStatusPageConfigurationRes;
  updateStatusPageGroup: StatusPageGroupRes;
  updateStatusPageIssue: StatusPageIssue;
  updateStatusPageIssueStateTemplate?: Maybe<Array<IssueStateTemplateRes>>;
  updateStatusPageMaintenance: StatusPageMaintenance;
  updateStatusPageStatus: StatusPageStatus;
  updateStatusPageStatusMessage: Array<Maybe<UpdatedStatusPageStatus>>;
  updateStatusPageSubscriptionSettings: StatusPageSubscriptionSettings;
  updateStatusPageTheme: StatusPageTheme;
  updateUserProfile: Scalars['String'];
  updateUsersPermissions: Array<UserPermissions>;
  verifyStatusPageCustomDomain: VerifyStatusPageCustomDomainResponse;
};

export type MutationAcknowledgeIncidentArgs = {
  incidentID: Scalars['String'];
};

export type MutationBulkReassignIncidentArgs = {
  input: BulkReassignIncidentInput;
};

export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export type MutationCloneScheduleArgs = {
  ID: Scalars['Int'];
};

export type MutationCreateICalUrlArgs = {
  myOncallOnly?: InputMaybe<Scalars['Boolean']>;
  scheduleID: Scalars['Int'];
};

export type MutationCreateIncidentArgs = {
  input: CreateIncidentReq;
};

export type MutationCreateIncidentGroupArgs = {
  filters?: InputMaybe<IncidentFilters>;
  isPrivate: Scalars['Boolean'];
  name: Scalars['String'];
  teamID: Scalars['String'];
};

export type MutationCreateIncidentNoteArgs = {
  input: CreateIncidentNoteReq;
};

export type MutationCreateJiraCloudTicketArgs = {
  incidentID: Scalars['String'];
};

export type MutationCreateJiraServerTicketArgs = {
  incidentID: Scalars['String'];
};

export type MutationCreateOverrideArgs = {
  input: NewScheduleOverride;
};

export type MutationCreateRotationArgs = {
  input: NewRotation;
  scheduleID: Scalars['Int'];
};

export type MutationCreateScheduleArgs = {
  input: NewSchedule;
};

export type MutationCreateStatusPageArgs = {
  input: NewStatusPage;
};

export type MutationCreateStatusPageComponentArgs = {
  input: NewStatusPageComponentReq;
};

export type MutationCreateStatusPageGroupArgs = {
  input: NewStatusPageGroupReq;
};

export type MutationCreateStatusPageIssueArgs = {
  input: NewStatusPageIssue;
};

export type MutationCreateStatusPageMaintenanceArgs = {
  input: NewStatusPageMaintenance;
};

export type MutationCreateStatusPageSubscriberArgs = {
  input: NewStatusPageSubscriber;
};

export type MutationDeactivateOrganizationArgs = {
  reason?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteICalUrlArgs = {
  myOncallOnly?: InputMaybe<Scalars['Boolean']>;
  scheduleID: Scalars['Int'];
};

export type MutationDeleteIncidentGroupArgs = {
  incidentGroupID: Scalars['Int'];
};

export type MutationDeleteIncidentNoteArgs = {
  noteID: Scalars['String'];
};

export type MutationDeleteOrganizationArgs = {
  password: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteOverrideArgs = {
  ID: Scalars['Int'];
};

export type MutationDeleteRotationArgs = {
  ID: Scalars['Int'];
};

export type MutationDeleteScheduleArgs = {
  ID: Scalars['Int'];
  input?: InputMaybe<Array<EscalationPolicyToReplacementMapping>>;
};

export type MutationDeleteStatusPageArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteStatusPageComponentArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteStatusPageGroupArgs = {
  id: Scalars['Int'];
  reassignGroupID?: InputMaybe<Scalars['Int']>;
};

export type MutationDeleteStatusPageIssueArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteStatusPageMaintenanceArgs = {
  id: Scalars['Int'];
};

export type MutationPauseScheduleArgs = {
  ID: Scalars['Int'];
};

export type MutationReassignIncidentArgs = {
  input: ReassignIncident;
};

export type MutationRefreshICalUrlArgs = {
  myOncallOnly?: InputMaybe<Scalars['Boolean']>;
  scheduleID: Scalars['Int'];
};

export type MutationResolveIncidentArgs = {
  incidentID: Scalars['String'];
};

export type MutationResumeScheduleArgs = {
  ID: Scalars['Int'];
};

export type MutationStarIncidentArgs = {
  incidentID: Scalars['String'];
  starred: Scalars['Boolean'];
};

export type MutationStarIncidentNoteArgs = {
  noteID: Scalars['String'];
  starred: Scalars['Boolean'];
};

export type MutationUpdateIncidentGroupArgs = {
  filters?: InputMaybe<IncidentFilters>;
  incidentGroupID: Scalars['Int'];
  name: Scalars['String'];
};

export type MutationUpdateIncidentNoteArgs = {
  noteID: Scalars['String'];
  updatedMessage: Scalars['String'];
};

export type MutationUpdateIncidentTagsArgs = {
  incidentID: Scalars['String'];
  tags: Array<IncidentTagInput>;
};

export type MutationUpdateNotificationRulesArgs = {
  notificationRules: Array<NotificationRule>;
};

export type MutationUpdateOnCallReminderRulesArgs = {
  onCallReminderRules: Array<NotificationRule>;
};

export type MutationUpdateOrganizationNameArgs = {
  name: Scalars['String'];
};

export type MutationUpdateOrganizationOwnerArgs = {
  userID: Scalars['String'];
};

export type MutationUpdateOverrideArgs = {
  ID: Scalars['Int'];
  input: UpdateScheduleOverride;
};

export type MutationUpdateRotationArgs = {
  ID: Scalars['Int'];
  input: NewRotation;
};

export type MutationUpdateScheduleArgs = {
  ID: Scalars['Int'];
  input: UpdateSchedule;
};

export type MutationUpdateStatusPageComponentArgs = {
  input: UpdateStatusPageComponentReq;
};

export type MutationUpdateStatusPageComponentListOrderArgs = {
  input: UpdateStatusPageComponentListOrderReq;
};

export type MutationUpdateStatusPageConfigurationArgs = {
  input?: InputMaybe<UpdateStatusPageConfigurationReq>;
};

export type MutationUpdateStatusPageGroupArgs = {
  input: UpdateStatusPageGroupReq;
};

export type MutationUpdateStatusPageIssueArgs = {
  input: UpdateStatusPageIssue;
};

export type MutationUpdateStatusPageIssueStateTemplateArgs = {
  input: UpdateStatusPageIssueStateTemplate;
};

export type MutationUpdateStatusPageMaintenanceArgs = {
  input?: InputMaybe<UpdateStatusPageMaintenanceReq>;
};

export type MutationUpdateStatusPageStatusArgs = {
  id: Scalars['Int'];
  statusID: Scalars['Int'];
};

export type MutationUpdateStatusPageStatusMessageArgs = {
  input?: InputMaybe<UpdateStatusPageStatusMessage>;
};

export type MutationUpdateStatusPageSubscriptionSettingsArgs = {
  input?: InputMaybe<UpdateStatusPageSubscriptionSettings>;
};

export type MutationUpdateStatusPageThemeArgs = {
  input?: InputMaybe<UpdateStatusPageTheme>;
};

export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileReq;
};

export type MutationUpdateUsersPermissionsArgs = {
  input: Array<UpdateUserPermissions>;
};

export type MutationVerifyStatusPageCustomDomainArgs = {
  pageID: Scalars['Int'];
};

export type NewCustomPeriod = {
  periodFrequency: Scalars['Int'];
  periodUnit: CustomPeriodUnit;
  timeSlots: Array<NewTimeSlot>;
};

export type NewOwner = {
  ID: Scalars['String'];
  type: OwnerType;
};

export type NewParticipant = {
  ID: Scalars['String'];
  type: Scalars['String'];
};

export type NewParticipantGroup = {
  everyone?: InputMaybe<Scalars['Boolean']>;
  participants?: InputMaybe<Array<InputMaybe<NewParticipant>>>;
};

export type NewRotation = {
  changeParticipantsFrequency: Scalars['Int'];
  changeParticipantsUnit: ChangeParticipantsInterval;
  color?: InputMaybe<Scalars['String']>;
  customPeriod?: InputMaybe<NewCustomPeriod>;
  customPeriodFrequency?: InputMaybe<Scalars['Int']>;
  customPeriodUnit?: InputMaybe<CustomPeriodUnit>;
  endDate?: InputMaybe<Scalars['Time']>;
  endsAfterIterations?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  participantGroups?: InputMaybe<Array<InputMaybe<NewParticipantGroup>>>;
  period: PeriodOptions;
  shiftTimeSlot?: InputMaybe<NewTimeSlot>;
  shiftTimeSlots?: InputMaybe<Array<NewTimeSlot>>;
  startDate: Scalars['Time'];
};

export type NewSchedule = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  owner: NewOwner;
  rotations?: InputMaybe<Array<NewRotation>>;
  tags?: InputMaybe<Array<NewTag>>;
  teamID: Scalars['String'];
  timeZone: Scalars['String'];
};

export type NewScheduleOverride = {
  endTime: Scalars['Time'];
  overriddenParticipant?: InputMaybe<NewParticipantGroup>;
  overrideWith?: InputMaybe<NewParticipantGroup>;
  reason?: InputMaybe<Scalars['String']>;
  scheduleID: Scalars['Int'];
  startTime: Scalars['Time'];
};

export type NewStatusPage = {
  allowComponentsSubscription?: InputMaybe<Scalars['Boolean']>;
  allowMaintenanceSubscription?: InputMaybe<Scalars['Boolean']>;
  allowWebhookSubscription?: InputMaybe<Scalars['Boolean']>;
  components: Array<InputMaybe<NewStatusPageComponentList>>;
  contactEmail: Scalars['String'];
  customDomainName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  domainName: Scalars['String'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  ownerID: Scalars['String'];
  ownerType: NewStatusPageOwnerType;
  teamID: Scalars['String'];
  themeColor: NewStatusPageThemeColor;
  timezone: Scalars['String'];
};

export type NewStatusPageComponent = {
  allowSubscription: Scalars['Boolean'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  serviceID?: InputMaybe<Scalars['String']>;
  type: NewStatusPageComponentType;
};

export type NewStatusPageComponentList = {
  allowSubscription?: InputMaybe<Scalars['Boolean']>;
  components?: InputMaybe<Array<InputMaybe<NewStatusPageComponent>>>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type: NewStatusPageComponentType;
};

export type NewStatusPageComponentReq = {
  allowSubscription?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  groupID?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  pageID: Scalars['Int'];
  serviceID?: InputMaybe<Scalars['String']>;
};

export enum NewStatusPageComponentType {
  Component = 'component',
  Group = 'group',
}

export type NewStatusPageGroupReq = {
  allowSubscription?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  pageID: Scalars['Int'];
};

export type NewStatusPageIssue = {
  components?: InputMaybe<Array<InputMaybe<StatusPageIssueComponent>>>;
  issues?: InputMaybe<Array<InputMaybe<StatusPageIssueState>>>;
  pageID: Scalars['Int'];
  statusID: Scalars['Int'];
  title: Scalars['String'];
};

export type NewStatusPageMaintenance = {
  components: Array<InputMaybe<Scalars['Int']>>;
  endTime: Scalars['Time'];
  note?: InputMaybe<Scalars['String']>;
  pageID: Scalars['Int'];
  startTime: Scalars['Time'];
  title: Scalars['String'];
};

export enum NewStatusPageOwnerType {
  Squad = 'squad',
  Team = 'team',
  User = 'user',
}

export type NewStatusPageSubscriber = {
  componentID?: InputMaybe<Scalars['Int']>;
  emailID?: InputMaybe<Scalars['String']>;
  isAllComponentsSubscribed?: InputMaybe<Scalars['Boolean']>;
  isMaintenanceSubscribed?: InputMaybe<Scalars['Boolean']>;
  isPageSubscribed?: InputMaybe<Scalars['Boolean']>;
  pageID: Scalars['Int'];
  type: StatusPageSubscriberType;
  webhookFailureEmail?: InputMaybe<Scalars['String']>;
  webhookURL?: InputMaybe<Scalars['String']>;
};

export type NewStatusPageThemeColor = {
  primary?: InputMaybe<Scalars['String']>;
  secondary?: InputMaybe<Scalars['String']>;
};

export type NewTag = {
  color?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  value: Scalars['String'];
};

export type NewTimeSlot = {
  dayOfWeek?: InputMaybe<DayOfWeek>;
  duration: Scalars['Int'];
  startHour: Scalars['Int'];
  startMin: Scalars['Int'];
};

export type NoteMetadata = {
  __typename?: 'NoteMetadata';
  source: Scalars['String'];
};

export type NotificationRule = {
  time: Scalars['Int'];
  type: Scalars['String'];
};

export type OnCallReminderRules = {
  __typename?: 'OnCallReminderRules';
  time: Scalars['Int'];
  type: UserOnCallReminderRuleType;
};

export type OncallCoverage = {
  __typename?: 'OncallCoverage';
  endTime: Scalars['Time'];
  overrides?: Maybe<Array<ScheduleOverride>>;
  rotations?: Maybe<Array<CoverageRotations>>;
  startTime: Scalars['Time'];
  type: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  deActivated: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type OverallComponentStatus = {
  __typename?: 'OverallComponentStatus';
  color: Scalars['String'];
  slug: Scalars['String'];
};

export type OverridesFilters = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  participantID?: InputMaybe<Scalars['String']>;
};

export type Owner = {
  __typename?: 'Owner';
  ID: Scalars['String'];
  owner?: Maybe<AssignableEntity>;
  type: OwnerType;
};

export enum OwnerType {
  Squad = 'squad',
  Team = 'team',
  User = 'user',
}

export type PageMetaData = {
  __typename?: 'PageMetaData';
  totalCount: Scalars['Int'];
};

export type PageMetaDataWithOrgTotalCount = {
  __typename?: 'PageMetaDataWithOrgTotalCount';
  orgTotalCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export enum PaginationType {
  Cursor = 'cursor',
  Offset = 'offset',
}

export type Participant = {
  __typename?: 'Participant';
  ID: Scalars['String'];
  participant: ScheduleParticipant;
  type: Scalars['String'];
};

export type ParticipantGroup = {
  __typename?: 'ParticipantGroup';
  everyone?: Maybe<Scalars['Boolean']>;
  participants?: Maybe<Array<Maybe<Participant>>>;
};

export enum PeriodOptions {
  Custom = 'custom',
  Daily = 'daily',
  Monthly = 'monthly',
  None = 'none',
  Weekly = 'weekly',
}

export type Permissions = {
  __typename?: 'Permissions';
  apiTokens: Scalars['Boolean'];
  billing: Scalars['Boolean'];
  extensions: Scalars['Boolean'];
  organizationAnalytics: Scalars['Boolean'];
  retrospectiveTemplate: Scalars['Boolean'];
  teams: Scalars['Boolean'];
  users: Scalars['Boolean'];
  webhooks: Scalars['Boolean'];
};

export type PermissionsList = {
  apiTokens?: InputMaybe<Scalars['Boolean']>;
  billing?: InputMaybe<Scalars['Boolean']>;
  extensions?: InputMaybe<Scalars['Boolean']>;
  organizationAnalytics?: InputMaybe<Scalars['Boolean']>;
  retrospectiveTemplate?: InputMaybe<Scalars['Boolean']>;
  teams?: InputMaybe<Scalars['Boolean']>;
  users?: InputMaybe<Scalars['Boolean']>;
  webhooks?: InputMaybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  countIncidents: IncidentCount;
  event?: Maybe<ScheduleEvent>;
  exportStatusPageSubscribers: ExportStatusPageSubscribersRes;
  getCurrentUserPermissions: UserPermissions;
  getExtensionsIntegrated: ExtensionsIntegrated;
  getIncidentActiveTags: Array<Maybe<IncidentActiveTags>>;
  getIncidentAssignableEntities: IncidentAssignableEntities;
  getIncidentDetails: IncidentDetails;
  getIncidentGroup: IncidentGroup;
  getIncidentStatusStats: IncidentStatusStats;
  getIncidentStatusStatsES: IncidentStatusStats;
  getOrganization: Organization;
  getStatusPageComponentTimeline: StatusPageComponentTimeline;
  getStatusPageCustomDomainConfig: StatusPageCustomDomainConfig;
  getStatusPageDetails: StatusPageDetails;
  getStatusPageIssue: GetStatusPageIssueRes;
  getStatusPageIssueHistory: IssueHistoryResponse;
  getStatusPageMaintenanceDetails: StatusPageMaintenanceDetail;
  getStatusPageSubscriptionSettings: StatusPageSubscriptionSettings;
  getUser: User;
  getUserProfile: UserProfile;
  legacyWhoIsOnCall?: Maybe<Array<LegacyWhoIsOnCallForSchedule>>;
  listActiveAlertSources: Array<IncidentAlertSource>;
  listActiveScheduleTags: Array<Maybe<ScheduleActiveTags>>;
  listIncidentGroups?: Maybe<Array<IncidentGroup>>;
  listIncidents: IncidentList;
  listIncidentsES: IncidentList;
  listLegacySchedulesEvents?: Maybe<Array<LegacyScheduleEvent>>;
  listMentionEntities: MentionEntities;
  listServices: Array<Service>;
  listSquadMembers: Array<UserName>;
  listStatusPageComponents: StatusPageComponentsList;
  listStatusPageIssues: ListStatusPageIssueRes;
  listStatusPageMaintenances: ListStatusPageMaintenanceRes;
  listStatusPageStatuses: Array<Maybe<StatusPageStatuses>>;
  listStatusPageSubscribers: ListStatusPageSubscribersRes;
  listStatusPages: ListStatusPagesRes;
  listTeamUsers: Array<TeamUser>;
  listTeams: TeamList;
  listUserPermissions: UserPermissionsList;
  listUserSquads: Array<Squad>;
  listUserTeamRBACAbilities: Array<RbacEntityAbility>;
  listUsers: Array<User>;
  listUsersName: Array<UserName>;
  mockEvents: MockEventsData;
  override?: Maybe<ScheduleOverride>;
  rotation?: Maybe<Rotation>;
  rotationByName?: Maybe<Rotation>;
  schedule?: Maybe<Schedule>;
  schedules?: Maybe<Array<Schedule>>;
  whoIsOncall?: Maybe<Array<WhoIsOncallForSchedule>>;
};

export type QueryCountIncidentsArgs = {
  input?: InputMaybe<CountIncidentReq>;
};

export type QueryEventArgs = {
  ID: Scalars['Int'];
};

export type QueryExportStatusPageSubscribersArgs = {
  pageID: Scalars['Int'];
  teamID: Scalars['String'];
};

export type QueryGetIncidentActiveTagsArgs = {
  serviceIDs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  teamID: Scalars['String'];
};

export type QueryGetIncidentAssignableEntitiesArgs = {
  teamID: Scalars['String'];
};

export type QueryGetIncidentDetailsArgs = {
  incidentID: Scalars['String'];
};

export type QueryGetIncidentGroupArgs = {
  incidentGroupID: Scalars['Int'];
};

export type QueryGetIncidentStatusStatsArgs = {
  input?: InputMaybe<IncidentStatusStatsReq>;
};

export type QueryGetIncidentStatusStatsEsArgs = {
  input?: InputMaybe<IncidentStatusStatsReq>;
};

export type QueryGetStatusPageComponentTimelineArgs = {
  id: Scalars['Int'];
};

export type QueryGetStatusPageCustomDomainConfigArgs = {
  pageID: Scalars['Int'];
};

export type QueryGetStatusPageDetailsArgs = {
  id: Scalars['Int'];
};

export type QueryGetStatusPageIssueArgs = {
  id: Scalars['Int'];
  pageID: Scalars['Int'];
};

export type QueryGetStatusPageIssueHistoryArgs = {
  input: IssueHistoryRequest;
};

export type QueryGetStatusPageMaintenanceDetailsArgs = {
  id: Scalars['Int'];
};

export type QueryGetStatusPageSubscriptionSettingsArgs = {
  pageID: Scalars['Int'];
};

export type QueryGetUserArgs = {
  id: Scalars['String'];
};

export type QueryGetUserProfileArgs = {
  currentTeamID?: InputMaybe<Scalars['String']>;
  userID: Scalars['String'];
};

export type QueryLegacyWhoIsOnCallArgs = {
  teamID: Scalars['String'];
};

export type QueryListActiveAlertSourcesArgs = {
  fromDate?: InputMaybe<Scalars['Time']>;
  teamID: Scalars['String'];
  toDate?: InputMaybe<Scalars['Time']>;
};

export type QueryListActiveScheduleTagsArgs = {
  teamID: Scalars['String'];
};

export type QueryListIncidentGroupsArgs = {
  teamID: Scalars['String'];
};

export type QueryListIncidentsArgs = {
  input?: InputMaybe<IncidentListReq>;
};

export type QueryListIncidentsEsArgs = {
  input?: InputMaybe<IncidentListReq>;
};

export type QueryListLegacySchedulesEventsArgs = {
  filters: LegacyScheduleFilters;
  teamID: Scalars['String'];
};

export type QueryListMentionEntitiesArgs = {
  teamID: Scalars['String'];
};

export type QueryListServicesArgs = {
  teamID: Scalars['String'];
};

export type QueryListSquadMembersArgs = {
  squadID: Scalars['String'];
};

export type QueryListStatusPageComponentsArgs = {
  pageID: Scalars['Int'];
};

export type QueryListStatusPageIssuesArgs = {
  input?: InputMaybe<ListStatusPageIssues>;
};

export type QueryListStatusPageMaintenancesArgs = {
  input?: InputMaybe<ListStatusPageMaintenances>;
};

export type QueryListStatusPageStatusesArgs = {
  pageID: Scalars['Int'];
};

export type QueryListStatusPageSubscribersArgs = {
  input?: InputMaybe<ListStatusPageSubscribersReq>;
};

export type QueryListStatusPagesArgs = {
  input?: InputMaybe<ListStatusPagesReq>;
};

export type QueryListTeamUsersArgs = {
  teamID: Scalars['String'];
};

export type QueryListUserPermissionsArgs = {
  input?: InputMaybe<UserPermissionsReq>;
};

export type QueryListUserSquadsArgs = {
  teamID: Scalars['String'];
};

export type QueryListUserTeamRbacAbilitiesArgs = {
  teamID: Scalars['String'];
};

export type QueryListUsersArgs = {
  filter?: InputMaybe<ListUsersFilters>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryMockEventsArgs = {
  from: Scalars['Time'];
  schedule: NewSchedule;
  till: Scalars['Time'];
};

export type QueryOverrideArgs = {
  ID: Scalars['Int'];
};

export type QueryRotationArgs = {
  ID: Scalars['Int'];
};

export type QueryRotationByNameArgs = {
  rotationName: Scalars['String'];
  scheduleName: Scalars['String'];
  teamID: Scalars['String'];
};

export type QueryScheduleArgs = {
  ID: Scalars['Int'];
};

export type QuerySchedulesArgs = {
  filters?: InputMaybe<SchedulesFilters>;
};

export type QueryWhoIsOncallArgs = {
  filters?: InputMaybe<WhoIsOncallFilters>;
};

export type RbacAbility = {
  __typename?: 'RBACAbility';
  ability: Scalars['String'];
  value: Scalars['Boolean'];
};

export type RbacEntityAbility = {
  __typename?: 'RBACEntityAbility';
  entity: Scalars['String'];
  rbacAbilities: Array<RbacAbility>;
};

export type ReassignIncident = {
  incidentID: Scalars['String'];
  reAssignToID: Scalars['String'];
  reAssignToType: IncidentAssignToType;
};

export type Responder = {
  __typename?: 'Responder';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type Rotation = {
  __typename?: 'Rotation';
  ID: Scalars['Int'];
  changeParticipantsFrequency: Scalars['Int'];
  changeParticipantsUnit: ChangeParticipantsInterval;
  color?: Maybe<Scalars['String']>;
  customPeriod?: Maybe<CustomPeriod>;
  customPeriodFrequency?: Maybe<Scalars['Int']>;
  customPeriodUnit?: Maybe<CustomPeriodUnit>;
  endDate?: Maybe<Scalars['Time']>;
  endsAfterIterations?: Maybe<Scalars['Int']>;
  events?: Maybe<Array<ScheduleEvent>>;
  name: Scalars['String'];
  participantGroups?: Maybe<Array<ParticipantGroup>>;
  period: PeriodOptions;
  scheduleID: Scalars['Int'];
  shiftTimeSlot?: Maybe<TimeSlot>;
  shiftTimeSlots: Array<TimeSlot>;
  startDate: Scalars['Time'];
};

export type RotationEventsArgs = {
  filters?: InputMaybe<ScheduleEventsFilters>;
};

export type Slo = {
  __typename?: 'SLO';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Schedule = {
  __typename?: 'Schedule';
  ID: Scalars['Int'];
  acl?: Maybe<Acl>;
  description?: Maybe<Scalars['String']>;
  escalationPolicies?: Maybe<Array<EscalationPolicy>>;
  gaps?: Maybe<Array<Gap>>;
  iCalLink?: Maybe<Scalars['String']>;
  iCalLinkForMyOncalls?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  oncallCoverage?: Maybe<Array<OncallCoverage>>;
  orgID: Scalars['String'];
  overrides?: Maybe<Array<ScheduleOverride>>;
  owner: Owner;
  paused?: Maybe<Scalars['Boolean']>;
  rotations?: Maybe<Array<Rotation>>;
  slug: Scalars['String'];
  tags?: Maybe<Array<Tag>>;
  teamID: Scalars['String'];
  timeZone: Scalars['String'];
};

export type ScheduleGapsArgs = {
  from: Scalars['Time'];
  till: Scalars['Time'];
};

export type ScheduleOncallCoverageArgs = {
  from: Scalars['Time'];
  till: Scalars['Time'];
};

export type ScheduleOverridesArgs = {
  filters?: InputMaybe<OverridesFilters>;
  from: Scalars['Time'];
  till: Scalars['Time'];
};

export type ScheduleActiveTags = {
  __typename?: 'ScheduleActiveTags';
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type ScheduleEvent = {
  __typename?: 'ScheduleEvent';
  ID: Scalars['Int'];
  endTime: Scalars['Time'];
  participants?: Maybe<Array<Participant>>;
  rotation: Rotation;
  rotationID: Scalars['Int'];
  schedule: Schedule;
  scheduleID: Scalars['Int'];
  startTime: Scalars['Time'];
};

export type ScheduleEventsFilters = {
  endTime: Scalars['Time'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  participantIDs?: InputMaybe<Array<Scalars['String']>>;
  startTime: Scalars['Time'];
};

export type ScheduleOverride = {
  __typename?: 'ScheduleOverride';
  ID: Scalars['Int'];
  endTime: Scalars['Time'];
  overriddenParticipant?: Maybe<ParticipantGroup>;
  overrideWith?: Maybe<ParticipantGroup>;
  reason?: Maybe<Scalars['String']>;
  schedule: Schedule;
  override: ScheduleOverride;
  scheduleID: Scalars['Int'];
  startTime: Scalars['Time'];
  teamID: Scalars['String'];
};

export type ScheduleParticipant = Squad | User;

export type SchedulesFilters = {
  entityOwner?: InputMaybe<Scalars['String']>;
  escalationPolicies?: InputMaybe<Array<Scalars['String']>>;
  hidePaused?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  myOncall?: InputMaybe<Scalars['Boolean']>;
  noEscalationPolicies?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  participants?: InputMaybe<Array<Scalars['String']>>;
  scheduleIDs?: InputMaybe<Array<Scalars['Int']>>;
  scheduleName?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  teamID?: InputMaybe<Scalars['String']>;
  youAndYourSquads?: InputMaybe<Scalars['Boolean']>;
};

export type Service = {
  __typename?: 'Service';
  apiKey: Scalars['String'];
  epID: Scalars['String'];
  name: Scalars['String'];
  serviceID: Scalars['String'];
};

export type ServiceNowIncidentMapping = {
  __typename?: 'ServiceNowIncidentMapping';
  servicenowIncidentLink: Scalars['String'];
  servicenowIncidentNumber: Scalars['String'];
};

export type SnoozeDetails = {
  __typename?: 'SnoozeDetails';
  endTime?: Maybe<Scalars['Time']>;
  isSnoozed: Scalars['Boolean'];
  startTime?: Maybe<Scalars['Time']>;
  totalTime: Scalars['Int64'];
};

export type Squad = {
  __typename?: 'Squad';
  ID: Scalars['String'];
  memberIDs?: Maybe<Array<Scalars['String']>>;
  members?: Maybe<Array<User>>;
  name: Scalars['String'];
};

export type StakeholderGroup = {
  __typename?: 'StakeholderGroup';
  ID: Scalars['String'];
  memberIDs: Array<Scalars['String']>;
  members: Array<User>;
  name: Scalars['String'];
};

export type Status = {
  __typename?: 'Status';
  color: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type StatusDurations = {
  __typename?: 'StatusDurations';
  degraded?: Maybe<StatusSlugDuration>;
  majorOutage?: Maybe<StatusSlugDuration>;
  operational?: Maybe<StatusSlugDuration>;
  partialOutage?: Maybe<StatusSlugDuration>;
  underMaintenance?: Maybe<StatusSlugDuration>;
};

export type StatusPage = {
  __typename?: 'StatusPage';
  components: Array<Maybe<StatusPageComponentList>>;
  domainName: Scalars['String'];
  id: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  organizationID: Scalars['String'];
  ownerID: Scalars['String'];
  ownerType: NewStatusPageOwnerType;
  teamID: Scalars['String'];
  themeColor: StatusPageThemeColor;
  timezone: Scalars['String'];
};

export type StatusPageCode = {
  __typename?: 'StatusPageCode';
  color: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['Int'];
  message: Scalars['String'];
  slug: Scalars['String'];
};

export type StatusPageComponent = {
  __typename?: 'StatusPageComponent';
  allowSubscription: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isHidden: Scalars['Boolean'];
  name: Scalars['String'];
  serviceID?: Maybe<Scalars['String']>;
  status: ListStatusPagesStatus;
  statusMaintenance: ListStatusPagesStatus;
  type: NewStatusPageComponentType;
  underMaintenance: Scalars['Boolean'];
};

export type StatusPageComponentList = {
  __typename?: 'StatusPageComponentList';
  allowSubscription?: Maybe<Scalars['Boolean']>;
  components?: Maybe<Array<Maybe<StatusPageComponent>>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isHidden: Scalars['Boolean'];
  name: Scalars['String'];
  serviceID?: Maybe<Scalars['String']>;
  status?: Maybe<ListStatusPagesStatus>;
  statusMaintenance: ListStatusPagesStatus;
  type: NewStatusPageComponentType;
  underMaintenance: Scalars['Boolean'];
};

export type StatusPageComponentListOrderItem = {
  components?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  id: Scalars['Int'];
  type: NewStatusPageComponentType;
};

export type StatusPageComponentRes = {
  __typename?: 'StatusPageComponentRes';
  allowSubscription: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  groupID?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  pageID: Scalars['Int'];
  serviceID?: Maybe<Scalars['String']>;
};

export type StatusPageComponentTimeline = {
  __typename?: 'StatusPageComponentTimeline';
  data?: Maybe<Array<Maybe<ComponentDayLog>>>;
  meta: PageMetaData;
};

export type StatusPageComponentsList = {
  __typename?: 'StatusPageComponentsList';
  data: Array<Maybe<StatusPageComponentList>>;
};

export type StatusPageCustomDomainConfig = {
  __typename?: 'StatusPageCustomDomainConfig';
  aRecord: DomainRecord;
  txtRecord: DomainRecord;
};

export type StatusPageDetails = {
  __typename?: 'StatusPageDetails';
  aRecord: Scalars['String'];
  components: Array<Maybe<StatusPageComponentList>>;
  customDomainName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  domainName: Scalars['String'];
  hideFromSearchEngines: Scalars['Boolean'];
  id: Scalars['Int'];
  isAutomaticCompStatusUpdateEnabled?: Maybe<Scalars['Boolean']>;
  isCustomARecordVerified: Scalars['Boolean'];
  isCustomDomainEnabled: Scalars['Boolean'];
  isCustomTxtVerified: Scalars['Boolean'];
  isPublic?: Maybe<Scalars['Boolean']>;
  logoUrl: Scalars['String'];
  maximumSubscribersLimit: Scalars['Int'];
  messageTemplates: Array<Maybe<StatusPageMessageTemplate>>;
  name: Scalars['String'];
  organizationID: Scalars['String'];
  ownerID: Scalars['String'];
  ownerType: NewStatusPageOwnerType;
  status: ListStatusPagesStatus;
  statusCodes: Array<Maybe<StatusPageCode>>;
  statusMaintenance: ListStatusPagesStatus;
  subscribersCount: Scalars['Int'];
  teamID: Scalars['String'];
  themeColor: StatusPageThemeColor;
  timezone: Scalars['String'];
  txtRecord: Scalars['String'];
  underMaintenance: Scalars['Boolean'];
};

export type StatusPageDetailsComponent = {
  __typename?: 'StatusPageDetailsComponent';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type StatusPageGroupRes = {
  __typename?: 'StatusPageGroupRes';
  allowSubscription: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  pageID: Scalars['Int'];
};

export type StatusPageIssue = {
  __typename?: 'StatusPageIssue';
  componentIDs: Array<Maybe<Scalars['Int']>>;
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type StatusPageIssueComponent = {
  id: Scalars['Int'];
  statusID: Scalars['Int'];
};

export type StatusPageIssueMessage = {
  id?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['Time']>;
};

export type StatusPageIssueMessageRes = {
  __typename?: 'StatusPageIssueMessageRes';
  id: Scalars['Int'];
  text: Scalars['String'];
  timestamp: Scalars['Time'];
};

export type StatusPageIssueRes = {
  __typename?: 'StatusPageIssueRes';
  components: Array<IssueComponentWithName>;
  createdAt: Scalars['Time'];
  currentState: Status;
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type StatusPageIssueState = {
  stateID: Scalars['Int'];
  stateMessages?: InputMaybe<Array<StatusPageIssueMessage>>;
};

export type StatusPageMaintenance = {
  __typename?: 'StatusPageMaintenance';
  components: Array<Maybe<Scalars['Int']>>;
  endTime: Scalars['Time'];
  id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  pageID: Scalars['Int'];
  startTime: Scalars['Time'];
  title: Scalars['String'];
};

export type StatusPageMaintenanceComponent = {
  __typename?: 'StatusPageMaintenanceComponent';
  groupName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type StatusPageMaintenanceDetail = {
  __typename?: 'StatusPageMaintenanceDetail';
  components: Array<Maybe<StatusPageMaintenanceComponent>>;
  endTime: Scalars['Time'];
  id: Scalars['Int'];
  maintenanceState: MaintenanceState;
  note?: Maybe<Scalars['String']>;
  pageID: Scalars['Int'];
  startTime: Scalars['Time'];
  title: Scalars['String'];
};

export type StatusPageMessageTemplate = {
  __typename?: 'StatusPageMessageTemplate';
  color: Scalars['String'];
  defaultMessage: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  stepIndex: Scalars['Int'];
};

export type StatusPageRes = {
  __typename?: 'StatusPageRes';
  aRecord: Scalars['String'];
  acl?: Maybe<Acl>;
  components: Array<Maybe<ListStatusPagesComponent>>;
  customDomainName: Scalars['String'];
  domainName: Scalars['String'];
  id: Scalars['Int'];
  isCustomARecordVerified: Scalars['Boolean'];
  isCustomDomainEnabled: Scalars['Boolean'];
  isCustomTxtVerified: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  ownerID: Scalars['String'];
  ownerType: Scalars['String'];
  status: ListStatusPagesStatus;
  statusMaintenance: ListStatusPagesStatus;
  subscribersCount: Scalars['Int'];
  timezone: Scalars['String'];
  txtRecord: Scalars['String'];
  underMaintenance: Scalars['Boolean'];
};

export type StatusPageStatus = {
  __typename?: 'StatusPageStatus';
  id: Scalars['Int'];
  name: Scalars['String'];
  statusID: Scalars['Int'];
};

export type StatusPageStatusMessageData = {
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

export type StatusPageStatuses = {
  __typename?: 'StatusPageStatuses';
  color: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['String'];
};

export type StatusPageSubscriber = {
  __typename?: 'StatusPageSubscriber';
  emailID: Scalars['String'];
  id: Scalars['Int'];
  pageID: Scalars['Int'];
  status: Scalars['String'];
  subscribedOn: Scalars['String'];
  type: StatusPageSubscriberType;
  webhookFailureEmail: Scalars['String'];
  webhookURL: Scalars['String'];
};

export type StatusPageSubscriberComponent = {
  __typename?: 'StatusPageSubscriberComponent';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum StatusPageSubscriberType {
  Email = 'email',
  Webhook = 'webhook',
}

export type StatusPageSubscriptionSettings = {
  __typename?: 'StatusPageSubscriptionSettings';
  allowComponentsSubscription: Scalars['Boolean'];
  allowMaintenanceSubscription: Scalars['Boolean'];
  allowWebhookSubscription: Scalars['Boolean'];
  componentsSubscription: SubscriptionDetails;
  contactEmail: Scalars['String'];
  maintenanceSubscription: SubscriptionDetails;
  sendOnlyEmailID: Scalars['String'];
};

export type StatusPageTheme = {
  __typename?: 'StatusPageTheme';
  logoUrl: Scalars['String'];
  pageID: Scalars['Int'];
  themeColor: StatusPageThemeColor;
};

export type StatusPageThemeColor = {
  __typename?: 'StatusPageThemeColor';
  primary: Scalars['String'];
  secondary: Scalars['String'];
};

export type StatusSlugDuration = {
  __typename?: 'StatusSlugDuration';
  duration: Scalars['Int'];
  slug: Scalars['String'];
};

export type SubscriptionDetails = {
  __typename?: 'SubscriptionDetails';
  count: Scalars['Int'];
  enabled: Scalars['Boolean'];
};

export type Tag = {
  __typename?: 'Tag';
  color?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Team = {
  __typename?: 'Team';
  ID: Scalars['String'];
  Name: Scalars['String'];
};

export type TeamList = {
  __typename?: 'TeamList';
  teams?: Maybe<Array<Maybe<Team>>>;
};

export type TeamRole = {
  __typename?: 'TeamRole';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type TeamUser = {
  __typename?: 'TeamUser';
  teamRoles: Array<TeamRole>;
  user: User;
};

export enum TimeDurationUnit {
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Seconds = 'seconds',
}

export type TimeSlot = {
  __typename?: 'TimeSlot';
  dayOfWeek?: Maybe<DayOfWeek>;
  duration: Scalars['Int'];
  startHour: Scalars['Int'];
  startMin: Scalars['Int'];
};

export type UpdateSchedule = {
  clearTags?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<NewOwner>;
  rotations?: InputMaybe<Array<NewRotation>>;
  tags?: InputMaybe<Array<NewTag>>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type UpdateScheduleOverride = {
  endTime?: InputMaybe<Scalars['Time']>;
  overriddenParticipant?: InputMaybe<NewParticipantGroup>;
  overrideWith?: InputMaybe<NewParticipantGroup>;
  reason?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type UpdateStatusPageComponentListOrderReq = {
  componentList: Array<StatusPageComponentListOrderItem>;
  pageID: Scalars['Int'];
};

export type UpdateStatusPageComponentListOrderRes = {
  __typename?: 'UpdateStatusPageComponentListOrderRes';
  components?: Maybe<Array<Scalars['Int']>>;
  id: Scalars['Int'];
  type: NewStatusPageComponentType;
};

export type UpdateStatusPageComponentReq = {
  allowSubscription?: InputMaybe<Scalars['Boolean']>;
  belongsToGroup?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  groupID?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  serviceID?: InputMaybe<Scalars['String']>;
};

export type UpdateStatusPageComponentRes = {
  __typename?: 'UpdateStatusPageComponentRes';
  allowSubscription: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  groupID?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  pageID: Scalars['Int'];
  serviceID?: Maybe<Scalars['String']>;
};

export type UpdateStatusPageConfigurationReq = {
  customDomainName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  domainName?: InputMaybe<Scalars['String']>;
  hideFromSearchEngines?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  isAutomaticCompStatusUpdateEnabled?: InputMaybe<Scalars['Boolean']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ownerID?: InputMaybe<Scalars['String']>;
  ownerType?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type UpdateStatusPageConfigurationRes = {
  __typename?: 'UpdateStatusPageConfigurationRes';
  customDomainName: Scalars['String'];
  description: Scalars['String'];
  domainName: Scalars['String'];
  hideFromSearchEngines?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  isAutomaticCompStatusUpdateEnabled?: Maybe<Scalars['Boolean']>;
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  ownerID: Scalars['String'];
  ownerType: Scalars['String'];
  timezone: Scalars['String'];
};

export type UpdateStatusPageGroupReq = {
  allowSubscription?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  pageID: Scalars['Int'];
};

export type UpdateStatusPageGroupRes = {
  __typename?: 'UpdateStatusPageGroupRes';
  allowSubscription: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  pageID: Scalars['Int'];
};

export type UpdateStatusPageIssue = {
  components?: InputMaybe<Array<InputMaybe<StatusPageIssueComponent>>>;
  id: Scalars['Int'];
  issues?: InputMaybe<Array<InputMaybe<StatusPageIssueState>>>;
  pageID: Scalars['Int'];
  statusID: Scalars['Int'];
  title: Scalars['String'];
};

export type UpdateStatusPageIssueStateTemplate = {
  data?: InputMaybe<Array<InputMaybe<IssueStateTemplate>>>;
  pageID: Scalars['Int'];
};

export type UpdateStatusPageMaintenanceReq = {
  components: Array<InputMaybe<Scalars['Int']>>;
  endTime: Scalars['Time'];
  id: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  pageID: Scalars['Int'];
  startTime: Scalars['Time'];
  title: Scalars['String'];
};

export type UpdateStatusPageStatusMessage = {
  data?: InputMaybe<Array<InputMaybe<StatusPageStatusMessageData>>>;
  pageID: Scalars['Int'];
};

export type UpdateStatusPageSubscriptionSettings = {
  allowComponentsSubscription?: InputMaybe<Scalars['Boolean']>;
  allowMaintenanceSubscription?: InputMaybe<Scalars['Boolean']>;
  allowWebhookSubscription?: InputMaybe<Scalars['Boolean']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  pageID: Scalars['Int'];
};

export type UpdateStatusPageTheme = {
  logoUrl?: InputMaybe<Scalars['String']>;
  pageID: Scalars['Int'];
  themeColor?: InputMaybe<NewStatusPageThemeColor>;
};

export type UpdateUserPermissions = {
  permissions: PermissionsList;
  userID: Scalars['String'];
};

export type UpdateUserPermissionsRes = {
  __typename?: 'UpdateUserPermissionsRes';
  permissions: Permissions;
  userID: Scalars['String'];
};

export type UpdateUserProfileReq = {
  countryCode?: InputMaybe<Scalars['String']>;
  dateFormat?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type UpdatedStatusPageStatus = {
  __typename?: 'UpdatedStatusPageStatus';
  color: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['Int'];
  status: Scalars['String'];
  statusMessage: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  ID: Scalars['String'];
  avatarURL?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  dateFormat: Scalars['String'];
  dialCode?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  phoneVerified: Scalars['Boolean'];
  role?: Maybe<UserRole>;
  timeZone: Scalars['String'];
  usernameForDisplay?: Maybe<Scalars['String']>;
};

export type UserName = {
  __typename?: 'UserName';
  ID: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export enum UserNotificationRuleType {
  Email = 'Email',
  Phone = 'Phone',
  Push = 'Push',
  Sms = 'SMS',
}

export type UserNotificationRules = {
  __typename?: 'UserNotificationRules';
  time: Scalars['Int'];
  type: UserNotificationRuleType;
};

export enum UserOnCallReminderRuleType {
  Email = 'Email',
  Push = 'Push',
}

export type UserPermissions = {
  __typename?: 'UserPermissions';
  permissions: Permissions;
  user: User;
};

export type UserPermissionsFilter = {
  userSearch?: InputMaybe<Scalars['String']>;
};

export type UserPermissionsList = {
  __typename?: 'UserPermissionsList';
  data: Array<UserPermissions>;
  meta: PageMetaData;
};

export type UserPermissionsReq = {
  filters?: InputMaybe<UserPermissionsFilter>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  isGlobalOnCallReminderRulesEnabled: Scalars['Boolean'];
  notificationRules: Array<UserNotificationRules>;
  onCallReminderRules: Array<OnCallReminderRules>;
  teams: Array<UserTeam>;
  user: User;
};

export enum UserRole {
  AccountOwner = 'accountOwner',
  Stakeholder = 'stakeholder',
  User = 'user',
}

export type UserTeam = {
  __typename?: 'UserTeam';
  roles: Array<TeamRole>;
  team: Team;
};

export type VerificationStatus = {
  __typename?: 'VerificationStatus';
  isVerified: Scalars['Boolean'];
  message: Scalars['String'];
};

export type VerifyStatusPageCustomDomainResponse = {
  __typename?: 'VerifyStatusPageCustomDomainResponse';
  isCustomARecordVerified: VerificationStatus;
  isCustomDomainVerified: Scalars['Boolean'];
  isCustomTxtVerified: VerificationStatus;
};

export type WhoIsOncallFilters = {
  ScheduleIDs?: InputMaybe<Array<Scalars['Int']>>;
  entityOwner?: InputMaybe<Scalars['String']>;
  teamID: Scalars['String'];
  youAndYourSquads?: InputMaybe<Scalars['Boolean']>;
};

export type WhoIsOncallForSchedule = {
  __typename?: 'WhoIsOncallForSchedule';
  oncallParticipants?: Maybe<Array<Participant>>;
  schedule: Schedule;
};
