export const runbookList = [
  { name: 'Runbook Name', size: '20%' },
  { name: 'Owner', size: '10%' },
  { name: 'Created At', size: '15%' },
  { name: 'Created By', size: '15%' },
  { name: 'Last Modified At', size: '15%' },
  { name: 'Last Modified By', size: '10%' },
  { name: 'Instances Used', size: '10%' },
  { name: '', size: '10%' },
];

export const dateFormat = 'MMM DD YYYY h:mm:ss A';

export const confirmation = { YES: 'YES' };

export const snackMsg = {
  deletRunbook: 'Runbook deleted Successfully!',
  errDeletRunbook: 'Error occured while deleting the runbook',
  errRunbookDetail: 'Error occured while recieving data, Please try again',
  runbookSave: 'Runbook Saved Successfully!',
  errRunbookSave: 'Error while creating runbook',
  errFileUpload: 'Error while uploading file, please try again',
};

export const markupType = {
  VISUAL: 'Visual',
  MARKDOWN: 'Markdown',
};

export const TD = 'TD';
export const SPAN = 'SPAN';

export const CreateBtn = {
  BLOCK: 'block',
  INLINE: 'inline-block',
};

export const switchOptions = [markupType.VISUAL, markupType.MARKDOWN];

export const RUNBOOKS = 'runbooks';

export const runbookAction = {
  UPDATE: 'Update',
  CREATE: 'Create',
  SAVE: 'Save',
};

export const componentStateConst = {
  ACTIVE: 'ACTIVE',
  BUSY: 'BUSY',
  INIT: 'INIT',
};

export const runbookHtmlText = {
  deleteConfirmationBox: `Are you sure you want to delete?`,
};

export const taskHtmlText = {
  deleteConfirmationBox: `Are you sure you want to delete?`,
};

export const runbook_delete = {
  YES: 'YES',
  NO: 'NO',
};

export const runbook_index = {
  CREATE: 'create',
  UPDATE: 'update',
};

export const uploadType = {
  IMAGE: 'IMAGE',
};

export const placeholders = {
  stepPlaceholder: 'Add instruction here',
};

export const bgDrag = {
  isDragging: 'rgba(245,245,245, 0.75)',
  notDragging: 'none',
};

export const addStepPlaceholder = 'Add instruction here';
