import { useQuery } from 'react-query';
import LCRService from 'core/services/service.lcr';
import { useLCRStore } from './lcr.state';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_LCR_LIST_PAGE_VIEWED } from 'core/const/tracker';
import { API } from 'core';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';

async function getLCRList() {
  const _extensionService = new LCRService();
  const { data } = await _extensionService.getLCRData();
  setUserAccessStore({
    entityACLMap: {
      ...getUserAccessStore().entityACLMap,
      routing_numbers: {
        ...getUserAccessStore().entityACLMap?.routing_numbers,
        ...data.metadata.acl,
      },
    },
  });
  return data;
}

const useGetLCRList = () => {
  return useQuery(['lcr-fetch', API.config.teamId], () => getLCRList(), {
    onSuccess: data => {
      useLCRStore.getState().setLCRData(data);
      AppTracker.track(T_WA_UP_LCR_LIST_PAGE_VIEWED);
    },

    keepPreviousData: true,
  });
};

async function getLCRLimit() {
  const _extensionService = new LCRService();
  const { data } = await _extensionService.getLCRLimit();
  return data;
}

const useGetLCRLimit = () => {
  return useQuery(
    ['lcr-limit', API.config.teamId, API.config.organizationId],
    () => getLCRLimit(),
    {
      cacheTime: 0,
    },
  );
};

export { useGetLCRList, useGetLCRLimit };
