import { Box, Button, Flex } from '@chakra-ui/react';
import { IAppState } from 'core/interfaces/IAppState';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import DataTable from '../components/Table';
import { tableColumns } from '../components/Table/columns';
import { getAllPatternParticipants } from '../helpers/helper.details';
import { IStatusPageList } from '../Interface';

type action = (id: number) => void;
interface IProps {
  data: IStatusPageList[];
  pageChange: (queryPageIndex: number) => void;
  pageSizeChange: (queryPageSize: number) => void;
  queryPageIndex: number;
  queryPageSize: number;
  totalCount: number;
  onDelete: action;
  onStatusUpdate: action;
  onIssue: action;
  onMaintenace: action;
  canEdit: boolean;
}

interface IPropsAction {
  rowData: any;
  onStatusUpdate: action;
  onIssue: action;
  onMaintenace: action;
  canEdit: boolean;
}

const StatusAction = (props: IPropsAction) => {
  const { rowData, onStatusUpdate, onIssue, onMaintenace, canEdit } = props;
  const pageID = rowData?.row?.original?.id || null;

  const OBACEnabled = useSelector(
    (state: IAppState) => state.organization.currentOrg.o?.config?.obac_enabled ?? false,
  );

  const hasUpdateAccess = useUserAccess().hasUpdateAccess;

  const hasUpdate = hasUpdateAccess('status_pages', pageID);

  return (
    <Flex gap={3} align="center" boxShadow="-10px 0px 10px 1px var(--chakra-colors-gray-100)">
      <Button
        onClick={() => {
          onMaintenace(pageID);
        }}
        variant={'outline'}
      >
        View Maintenance
      </Button>
      <NoPermissionTooltip isDisabled={hasUpdate}>
        <Button
          isDisabled={!hasUpdate}
          onClick={() => {
            onStatusUpdate(pageID);
          }}
          variant={'outline'}
        >
          Update Status Manually
        </Button>
      </NoPermissionTooltip>
      <Button
        onClick={() => {
          onIssue(pageID);
        }}
      >
        View Issues
      </Button>
    </Flex>
  );
};

const StautsList = (props: IProps) => {
  const {
    queryPageIndex,
    queryPageSize,
    pageSizeChange,
    pageChange,
    totalCount,
    onDelete,
    onStatusUpdate,
    onIssue,
    onMaintenace,
    canEdit,
  } = props;
  const ownerOptions = getAllPatternParticipants();
  return (
    <Box>
      <DataTable
        data={props.data}
        columns={tableColumns}
        hoverView={
          <StatusAction
            rowData={{}}
            canEdit={canEdit}
            onStatusUpdate={onStatusUpdate}
            onIssue={onIssue}
            onMaintenace={onMaintenace}
          />
        }
        ownerOptions={ownerOptions}
        onDelete={onDelete}
        paginationProps={{
          queryPageIndex,
          queryPageSize,
          totalCount: totalCount || 0,
          pageChange: (queryPageIndex: number) => pageChange(queryPageIndex),
          pageSizeChange: (queryPageSize: number) => {
            pageSizeChange(queryPageSize);
          },
        }}
      />
    </Box>
  );
};

export default StautsList;
