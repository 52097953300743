import styled from 'styled-components';

interface IProps {
  base?: '24px' | '40px' | 'fit-content' | string;
  borderType?: 'rounded' | 'square';
  buttonType?: 'inverted' | 'normal';
  /** Background color of the button */
  color?: string;
}

const calcProps = (props: any) => {
  const color = props.color || props.theme.shades.lightGrey;

  return `
    height: ${props.base || 'fit-content'};
    width: ${props.base || 'fit-content'};
    background: ${props.buttonType === 'inverted' ? color : 'transparent'};
    transition: 0.17s box-shadow, 0.17s filter, 0.17s background, 0.17s opacity ease;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      opacity: 0.33;
      background: ${props.theme.background.disabled};
      cursor: no-drop !important;
    }

    &:hover:not(:disabled), &:focus:not(:disabled) {
      opacity: 1;
      background: ${color}${props.buttonType === 'inverted' ? '' : '47'};
    }

    &:active:not(:disabled) {
      opacity: 0.77;
      background: ${color}${props.buttonType === 'inverted' ? '' : '47'};
    }
  `;
};

const IconButton = styled.button<IProps>`
  ${calcProps}
  border-radius: ${props => (props.borderType === 'rounded' ? '100%' : '3px')};
  font-family: ${props => props.theme.fontFamily};
  outline: none;
  display: flex;
  cursor: pointer;
  border: none;
  padding: 5px 5px;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    outline: ${props => props.theme.focus.default};
    outline-offset: 4px;
  }
`;

/** @component */
export default IconButton;
