import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import {
  REQUEST_ACCOUNT_NOTIFICATION_UPDATE,
  REQUEST_ACCOUNT_NOTIFICATION_UPDATE_FAIL,
} from '../../const/account';
import { updateNotificationRules } from '../../api/account';
import {
  onRequestNotificationUpdateFail,
  onRequestNotificationUpdateSuccess,
} from '../../actions/account';
import { IUserInfo, IUserNotificationRule } from '../../interfaces/IUserData';

const updateAccountNotificationRulesEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ACCOUNT_NOTIFICATION_UPDATE),
    map((action: any) => action.payload),
    switchMap(({ notificationRules }: { notificationRules: IUserNotificationRule[] }) => {
      return updateNotificationRules(notificationRules).pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ data }: { data: IUserInfo }) => {
          if (!data) {
            return onRequestNotificationUpdateFail('invalid Notification Rules');
          }
          return onRequestNotificationUpdateSuccess(notificationRules);
        }),
        catchError(error =>
          of({
            type: REQUEST_ACCOUNT_NOTIFICATION_UPDATE_FAIL,
            payload: error.xhr,
            error: true,
          }),
        ),
      );
    }),
  );

export default updateAccountNotificationRulesEpic;
