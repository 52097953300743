import * as React from 'react';
import { SVGProps } from 'react';

const SvgCircleCiLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Circle-CI_Logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.Circle-CI_Logo-20x20_svg__st0{fill:#161616}'}</style>
    <g transform="translate(38.606 59.797) scale(1.39572)">
      <circle className="Circle-CI_Logo-20x20_svg__st0" cx={-20.6} cy={-35.7} r={1.7} />
      <path
        className="Circle-CI_Logo-20x20_svg__st0"
        d="M-20.6-42.8c-3.3 0-6.1 2.3-6.9 5.4v.1c0 .2.2.3.3.3h2.9c.1 0 .3-.1.3-.2.6-1.3 1.9-2.2 3.4-2.2 2.1 0 3.8 1.7 3.8 3.8 0 2.1-1.7 3.8-3.8 3.8-1.5 0-2.8-.9-3.4-2.2-.1-.1-.2-.2-.3-.2h-2.9c-.2 0-.3.2-.3.3v.1c.8 3.1 3.6 5.4 6.9 5.4 4 0 7.2-3.2 7.2-7.2s-3.2-7.2-7.2-7.2z"
      />
    </g>
  </svg>
);

export default SvgCircleCiLogo20X20;
