import { Center, Spinner } from '@chakra-ui/react';
import { IAppState } from 'core/interfaces/IAppState';
import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { AppConfig } from 'shared/app.config';

import { ChakraWrapper } from '..';
import { getNonce } from '../helpers/api';
import useQueryParams from '../hooks/useQueryParams';

const AuthStatusPage = () => {
  const query = useQueryParams();
  const organization = useSelector((state: IAppState) => state.organization, shallowEqual);

  useEffect(() => {
    const url = query.get('redirect_url') ?? '';
    const redirectUrl = url.split('?')[0];
    const pageSlug = query.get('ps') ?? '';
    const userId = organization.currentUser.u?.id ?? '';
    const orgSlug = organization.currentOrg.o?.slug ?? '';

    getNonce(
      userId,
      redirectUrl,
      orgSlug,
      pageSlug,
      nonce => {
        const url = new URL(`https://${redirectUrl}`);
        url.searchParams.set('nonce', nonce);
        window.location.replace(url.toString());
      },
      () =>
        window.location.replace(
          pageSlug ? `${AppConfig.status_page_host}/403` : `https://${redirectUrl}/403`,
        ),
    );
  }, []);

  return (
    <ChakraWrapper>
      <div>
        <Center h="60vh" data-testid="loader">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      </div>
    </ChakraWrapper>
  );
};

export default AuthStatusPage;
