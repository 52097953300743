import { DialogModalFrame, Para, TextButton } from 'uie/components';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import React from 'react';
import { SquadMember } from '../../types';
import ConflictModalContent from './Content';

type Props = {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  memberName: string;
  members: SquadMember[];
  onConfirm: (selectedMember: IUsersOfOrganization) => void;
  attemptedAction?: 'remove' | 'updateRole';
};
function MemberConflict({
  openModal,
  setOpenModal,
  memberName,
  members,
  onConfirm,
  attemptedAction,
}: Props) {
  return (
    <DialogModalFrame
      root="rsg-root"
      id="modalFrame"
      onClose={() => setOpenModal(false)}
      padding="24px"
      width="500px"
    >
      {openModal && (
        <ConflictModalContent
          memberName={memberName}
          members={members}
          onCancel={() => setOpenModal(false)}
          onConfirm={onConfirm}
          attemptedAction={attemptedAction}
        />
      )}
    </DialogModalFrame>
  );
}

export default MemberConflict;
