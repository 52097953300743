import * as React from 'react';
import { SVGProps } from 'react';

const SvgOsnexusLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#93bf2c',
      }}
      d="M.25 1.72c.15-.36.36-.69.66-.99C2.56-.8 5.2.4 6.82 1.36c1.02.63 2.04 1.38 2.97 2.19 1.47-1.14 4.5-3.27 6.96-3.48-1.47.3-3.69 1.59-6.6 4.17 9.9-6.24 5.85.42 2.97 4.32C6.91 2.08 1.45-.02.25 1.72zm9.96 14.73c-1.47 1.11-4.5 3.27-6.96 3.48 1.47-.3 3.69-1.59 6.6-4.17-9.9 6.24-5.82-.39-2.97-4.32 6.21 6.51 11.67 8.61 12.87 6.84-.15.36-.36.69-.66.99-1.65 1.53-4.29.33-5.88-.63-1.05-.6-2.04-1.35-3-2.19z"
    />
    <path
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#0d0d10',
      }}
      d="M1.72 19.75c-.36-.15-.69-.36-.99-.66-1.53-1.65-.33-4.29.63-5.88.63-1.02 1.38-2.04 2.19-2.97C2.44 8.77.28 5.74.07 3.28c.3 1.47 1.59 3.69 4.17 6.6-6.24-9.93.39-5.85 4.32-3-6.48 6.21-8.58 11.67-6.84 12.87zM18.28.25c.36.15.69.36.99.66 1.53 1.65.33 4.29-.63 5.88-.63 1.02-1.38 2.04-2.19 2.97 1.11 1.47 3.27 4.5 3.48 6.96-.3-1.47-1.59-3.69-4.17-6.6 6.24 9.9-.39 5.82-4.32 2.97C17.95 6.91 20.02 1.45 18.28.25z"
    />
  </svg>
);

export default SvgOsnexusLogo20X20;
