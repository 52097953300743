import { Box } from '@chakra-ui/react';
import { ColorResult } from '@uiw/color-convert';
import Wheel from '@uiw/react-color-wheel';
import { TERTIARY_COLORS } from 'library/theme/colors';
import { useState } from 'react';

interface IProps {
  onchange: (color: string) => void;
}

export const ColorWheel = ({ onchange }: IProps) => {
  const [rgb, setRgb] = useState(TERTIARY_COLORS[0]);

  const handler = (color: ColorResult) => {
    onchange(color.hex);
    setRgb(color.hex);
  };

  return (
    <Box>
      <Wheel color={rgb} onChange={handler} />
    </Box>
  );
};
