import {
  Input as InputChakra,
  InputGroup,
  InputLeftElement,
  InputProps as InputPropsChakra,
  InputRightElement,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import { Avatar } from './Avatar';

interface InputProps extends Omit<InputPropsChakra, 'variant' | 'size'> {}

interface AddonInput extends InputProps {
  variant: 'addon';
  addon: ReactNode;
  addon2: ReactNode;
  addonPosition?: 'right' | 'left';
}

interface IconInput extends InputProps {
  variant: 'icon';
  icon: ReactNode;
}

interface AvatartInput extends InputProps {
  variant: 'avatar';
}

type Props = AvatartInput | AddonInput | IconInput;

export function Input({ ...props }: Partial<Props>) {
  const size = useBreakpointValue({ base: 'md', lg: 'lg' });
  const Component = <InputChakra {...props} size={size} />;

  if (props.variant === 'icon') {
    const [width, height] = size === 'lg' ? ['20px', '20px'] : ['15px', '15px'];
    return (
      <InputGroup size={size} variant={props.variant}>
        <InputLeftElement zIndex={1} pointerEvents="none" sx={{ '& > *': { width, height } }}>
          {props.icon}
        </InputLeftElement>
        {Component}
      </InputGroup>
    );
  }

  if (props.variant === 'addon') {
    const hasaddOn2 = !!props.addon2;
    return (
      <InputGroup size={size} variant={props.variant}>
        {props.addonPosition !== 'right' && (
          <InputLeftElement zIndex={1} pointerEvents="none">
            {props.addon}
          </InputLeftElement>
        )}
        {Component}
        {(hasaddOn2 || props.addonPosition === 'right') && (
          <InputRightElement zIndex={1} pointerEvents="none">
            {hasaddOn2 ? props.addon2 : props.addon}
          </InputRightElement>
        )}
      </InputGroup>
    );
  }

  if (props.variant === 'avatar') {
    const [width, height] = size === 'lg' ? ['24px', '24px'] : ['18px', '18px'];
    return (
      <InputGroup size={size} variant={props.variant}>
        <InputLeftElement zIndex={1} pointerEvents="none" sx={{ '& > *': { width, height } }}>
          <Avatar size="2xs" name={props.value?.toString() ?? ''} />
        </InputLeftElement>
        {Component}
      </InputGroup>
    );
  }

  return Component;
}
