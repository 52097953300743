import { HStack, Text, VStack } from '@chakra-ui/react';
import FormField from 'library/molecules/FormField/FormField';
import { THEME_COLORS } from 'library/theme/colors';
import { components } from 'react-select';
import { getDurations } from 'views/main/organization/lcr/screens/create-or-update/helper';
import {
  DurationType,
  LCRFormType,
  ROUTING_BEHAVIOR,
  ScheduleTypes,
} from 'views/main/organization/lcr/types/LCR.types';
import FormDropdown from '../FormDropdown/FormDropdown';
import { IVariant } from 'unleash-proxy-client';

interface Props {
  error?: string;
  name: string;
  isRequired?: boolean;
  value?: LCRFormType['Service'];
  onMount?: (ownerOptions: ScheduleTypes[]) => void;
  onClearField?: () => void;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: any;
  type: ROUTING_BEHAVIOR;
  extraDurationMinutes: Partial<IVariant>;
}
const EntityDurationDropDown = ({
  name,
  error,
  onClearField,
  placeholder,
  isRequired,
  style,
  value,
  onMount,
  onChange,
  type,
  extraDurationMinutes,
}: Props) => {
  const SingleValue = (props: any) => {
    return (
      <components.SingleValue {...props}>
        <HStack>
          <Text style={{ color: 'black' }}>{props.data.label}</Text>
          {props.data.label}
        </HStack>
      </components.SingleValue>
    );
  };

  return (
    <FormField error={error} onClearField={onClearField} style={{ ...style }}>
      <FormDropdown<DurationType>
        options={getDurations(type, extraDurationMinutes.payload?.value)}
        name={name}
        value={value}
        getOptionLabel={option => option.label}
        components={{ SingleValue }}
        getOptionValue={option => `${option.value}`}
        placeholder={placeholder ?? 'Select ...'}
        onChange={(e: any) => {
          onChange(e);
        }}
        formatOptionLabel={option => (
          <VStack alignItems={'left'} spacing={0}>
            {option.label && (
              <Text
                style={{
                  color: value?.label === option.label ? THEME_COLORS.primary[100] : 'black',
                }}
              >
                {option.label}
              </Text>
            )}
            P
          </VStack>
        )}
      />
    </FormField>
  );
};

export default EntityDurationDropDown;
