import { HStack, Image, Text } from '@chakra-ui/react';
import { ListingButton, Tooltip } from 'library/atoms';
import { ServiceIconNew } from 'icons';
import { generatePath, useHistory } from 'react-router-dom';
import { SERVICE_DETAILS_PATH } from 'views/main/routes/routes';
import { hasReadAccessToEntity } from '../../helpers';

interface Props {
  serviceName: string;
  serviceId: string;
}

const ServiceHeader = ({ serviceName, serviceId }: Props) => {
  const history = useHistory();

  const clickHandler = () => {
    history.push(generatePath(SERVICE_DETAILS_PATH, { id: serviceId }));
  };

  const canNavigateToService = hasReadAccessToEntity('service');

  return (
    <HStack
      w="100%"
      padding="12px 16px 8px"
      alignItems="center"
      justifyContent="space-between"
      boxSizing="border-box"
    >
      <HStack>
        <ServiceIconNew />
        <Tooltip label={serviceName} placement="top">
          <Text
            isTruncated
            maxW="160px"
            color="secondary.900"
            fontWeight={800}
            ml="12px !important"
          >
            {serviceName}
          </Text>
        </Tooltip>
      </HStack>
      {canNavigateToService && (
        <ListingButton variant="secondary" size="xs" title="View Service" onClick={clickHandler} />
      )}
    </HStack>
  );
};

export default ServiceHeader;
