import React, { useEffect, useState } from 'react';
import { Grid, Para, SpinLoader } from 'uie/components';
import {
  IAnalyticsPanel,
  IAnalyticsPanelData,
  IAnalyticsQuery,
  IAnalyticsTabularRequest,
} from '../../../../../core/interfaces/IAnalytics';
import { getData, isCacheValid, renderPanel, transformDataToChartData } from './helpers';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { exception } from '../../../../../core/exception';
import { useAnalyticsContext } from '../analyticsContext';
import { convertDateString } from '../../../../../core/const/analytics';

interface IProps extends Pick<IAppState, 'organization' | 'integrations'> {
  panel: IAnalyticsPanel;
  data: IAnalyticsPanelData;
  updatePanelData: (
    panelID: string,
    data: any[],
    owner?: string,
    dateString?: string,
    timeRange?: { gt: string; lt: string },
  ) => void;
}

const Panel: React.FC<IProps> = ({ panel, data, ...props }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const ignoreCustomPanel = ['date_connected', 'prime_line'].includes(panel.panelType);

  const {
    config: { timeRange },
    maps,
  } = useAnalyticsContext();

  useEffect(() => {
    if (ignoreCustomPanel) return;

    const gtString = convertDateString(timeRange.gt);
    const ltString = convertDateString(timeRange.lt);

    if (
      panel.query.filter_by?.owner &&
      panel.query.filter_by?.owner === data.owner &&
      data.timeRange?.gt === gtString &&
      data.timeRange?.lt === ltString &&
      isCacheValid(data.updatedAt)
    ) {
      return;
    }

    (async () => {
      setLoading(true);

      try {
        const panelQuery: IAnalyticsQuery = {
          ...panel.query,
          time_range: {
            gt: gtString,
            lt: ltString,
          },
        };

        const rawData = await getData(panelQuery);

        let chartData: any[] = [];

        if (panelQuery.kind === 'tabular_data') {
          const query = panelQuery as IAnalyticsTabularRequest;
          chartData = transformDataToChartData(
            panel.kind,
            panel.chart,
            rawData,
            query.group_by,
            maps,
          );
        } else {
          chartData = transformDataToChartData(panel.kind, panel.chart, rawData);
        }

        props.updatePanelData(panel.id, chartData, panel.query.filter_by?.owner, '', {
          gt: gtString,
          lt: ltString,
        });
      } catch (err: any) {
        exception.handle('E_GET_ANALYTICS_DATA', err);
      } finally {
        setLoading(false);
      }
    })();
  }, [timeRange, panel.query.filter_by?.owner, maps]);

  return (
    <Grid className="analytics__panel-container" type="column">
      {!ignoreCustomPanel ? (
        <Grid className="analytics__panel-content" type="column">
          <Para fontSize={20} style={{ paddingBottom: 10 }}>
            {panel.name}
          </Para>
          {loading ? (
            <Grid style={{ height: '100%' }}>
              <SpinLoader style={{ margin: 'auto' }} />
            </Grid>
          ) : (
            renderPanel(panel, data, props.updatePanelData)
          )}
        </Grid>
      ) : (
        renderPanel(panel, data, props.updatePanelData)
      )}
    </Grid>
  );
};

export default connect(({ organization, integrations }: IAppState) => ({
  organization,
  integrations,
}))(Panel);
