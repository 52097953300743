import { FormikValues } from 'formik';
import moment from 'moment';
import { DateFilterChoice } from '../../graphql/generated/types';
import { Filters } from '../../interfaces/filters';

export type FilterFormError = Partial<{ created: string; tagsValue: string; name: string }>;

export const validateFilterForm = (values: Filters | FormikValues) => {
  const errors: FilterFormError = {};

  if (values.created?.value === DateFilterChoice.Range) {
    if (values?.startDate && values?.endDate) {
      const start = moment(values?.startDate);
      const end = moment(values?.endDate);
      if (start.diff(end) > 0) {
        errors.created = 'Start date cannot be greater than end date';
      }
    } else {
      errors.created = 'For custome date rage Start Date & End Date is required';
    }
  }
  if (values.tagsKey && !values.tagsValue.length) {
    errors.tagsValue = 'Please select tags value along with tags key';
  }
  if (values.id && !values.name) {
    errors.name = 'Name is required for save filter';
  }
  return errors;
};
