import {
  L_REQUEST_AB_USER_ADD_TO_TEST,
  L_REQUEST_AB_USER_REMOVE_FROM_TEST,
  L_REQUEST_AB_ADD_TO_TEST,
  L_REQUEST_AB_REMOVE_FROM_TEST,
} from '../../const/abTest';
import { IABTest } from '../../interfaces/IABTest';

const addToUserTest = (test: IABTest) => ({
  type: L_REQUEST_AB_USER_ADD_TO_TEST,
  payload: {
    test,
  },
});

const removeFromUserTest = (test: IABTest) => ({
  type: L_REQUEST_AB_USER_REMOVE_FROM_TEST,
  payload: {
    test,
  },
});

const addToTest = (test: IABTest[]) => ({
  type: L_REQUEST_AB_ADD_TO_TEST,
  payload: {
    test,
  },
});

const removeFromTest = (test: IABTest[]) => ({
  type: L_REQUEST_AB_REMOVE_FROM_TEST,
  payload: {
    test,
  },
});

export { addToUserTest, removeFromUserTest, addToTest, removeFromTest };
