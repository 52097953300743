import { DateTime } from 'luxon';
import React, { SVGAttributes, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { TimeDimension, TimeDimensionGranularity } from '@cubejs-client/core';
import { DatumValue, ResponsiveWrapper } from '@nivo/core';
import { Line, LineSvgProps, PointTooltipProps } from '@nivo/line';
import { AxisTickProps } from '@nivo/axes';
import { colorSchemes } from '@nivo/colors';
import { Theme } from 'uie/components';

import { Filters, LineData, LinesChart } from '../types';
import { differenceInDays } from '../utils/date.utils';
import {
  convertToHourlyData,
  formatDateOverRange,
  formatDateValue,
  formatDurationValue,
  getYTickValues,
} from '../utils/graphs.utils';

const isoSpecifier = '%Y-%m-%dT%H:%M:%S.%L';

type Props = {
  filters: Filters;
  chart: LinesChart;
  lines: LineData[];
  timeDimensions?: TimeDimension[];
};

const { theme } = Theme;

const CustomTooltip = styled('div')<{ itemColor: string; isLastPoint: boolean }>`
  background: ${theme.shades.white};
  color: inherit;
  font-size: inherit;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25);
  padding: 5px 9px;
  border: 1px solid ${theme.shades.smoke};

  position: ${({ isLastPoint }) => (isLastPoint ? 'absolute' : 'static')};
  right: ${({ isLastPoint }) => (isLastPoint ? '-4rem' : 'unset')};

  & > div {
    white-space: pre;
    display: flex;
    align-items: center;

    & > span:first-child {
      display: block;
      background-color: ${({ itemColor }) => itemColor};
      width: 12px;
      height: 12px;
      margin-right: 7px;
    }

    & > div {
      display: flex;
      flex-direction: column;
    }
  }
`;

const formatTooltip = (
  {
    point: {
      index,
      color,
      data: { xFormatted, yFormatted },
      serieId,
    },
  }: PointTooltipProps,
  numPoints: number,
) => {
  const isLastPoint = (index % numPoints) + 1 === numPoints;
  return (
    <CustomTooltip itemColor={color} isLastPoint={isLastPoint}>
      <div>
        <span></span>
        <div>
          <span>
            <strong>{serieId}</strong>
          </span>
          <span>
            x: <strong>{xFormatted}</strong>
          </span>
          <span>
            y: <strong>{yFormatted}</strong>
          </span>
        </div>
      </div>
    </CustomTooltip>
  );
};

const getCustomTick = ({
  textAnchor,
  textBaseline,
  textX,
  textY,
  value,
  x,
  y,
  format,
}: AxisTickProps<string>) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <line
        x1="0"
        x2="0"
        y1="0"
        y2="5"
        style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: 1 }}
      ></line>
      <text
        alignmentBaseline={textBaseline as SVGAttributes<SVGElement>['alignmentBaseline']}
        textAnchor={textAnchor}
        transform={`translate(${textX},${textY})`}
        style={{
          fill: 'rgb(51, 51, 51)',
          fontSize: '10px',
          fontFamily: 'sans-serif',
          transform: 'translate(-10px, 15px) rotate(-25deg)',
        }}
      >
        {format?.(value) ?? value}
      </text>
    </g>
  );
};

const TRUNCATE_LEGEND_THRESHOLD_CHARS = 10;

const LinesPanel: React.FC<Props> = ({ chart, lines, timeDimensions }) => {
  const [dimensionGranularity, isWithinWeek] = useMemo(() => {
    const [chartData] = lines;
    let is2to6days = false;
    let [tdGranularity, dateRange] = [undefined, undefined] as [
      TimeDimensionGranularity | undefined,
      [string, string] | undefined,
    ];

    if (timeDimensions && chartData?.data.length) {
      const [{ granularity }] = timeDimensions;

      dateRange = [
        chartData.data[0].x as TimeDimensionGranularity,
        chartData.data[chartData.data.length - 1].x as TimeDimensionGranularity,
      ];

      const days = Math.floor(
        differenceInDays(
          DateTime.fromJSDate(new Date(dateRange[0])),
          DateTime.fromJSDate(new Date(dateRange[1])),
        ),
      );

      is2to6days = days > 1 && days < 7;
      tdGranularity = granularity;
    }

    return [tdGranularity, is2to6days];
  }, [timeDimensions]);

  const linesData = useMemo(() => {
    const modifiedData = [...lines] as LineData[];

    if (dimensionGranularity) {
      lines.forEach((chartData, i) => {
        if (dimensionGranularity === 'hour' && chartData?.data.length && isWithinWeek) {
          const specifiedHourlyData = convertToHourlyData([...chartData.data], 8);
          modifiedData[i] = { ...chartData, data: specifiedHourlyData };
          return;
        }
        modifiedData[i] = { ...chartData };
      });
    }

    return modifiedData;
  }, [lines, dimensionGranularity, isWithinWeek]);

  const formatLabel = useCallback(
    (date: DatumValue) => formatDateOverRange(date as Date, dimensionGranularity, isWithinWeek),
    [dimensionGranularity, isWithinWeek],
  );

  const yProps: Partial<LineSvgProps> = {
    yFormat: chart.yFormat,
    axisLeft: {
      tickValues: getYTickValues(linesData),
      tickSize: 5,
      tickPadding: 10,
      tickRotation: 0,
      format: (time: DatumValue) => {
        const formattedValue = formatDurationValue(time as number)
          .split(',')
          .splice(0, 2)
          .join(', ');

        return formattedValue.length > TRUNCATE_LEGEND_THRESHOLD_CHARS ? (
          <tspan>
            {`${formattedValue.substring(0, TRUNCATE_LEGEND_THRESHOLD_CHARS)}...`}
            <title>{formattedValue}</title>
          </tspan>
        ) : (
          formattedValue
        );
      },
    },
  };

  const xTickValues = linesData[0]?.data.length || 12;

  const xProps: Partial<LineSvgProps> = {
    xScale: {
      type: 'time',
      format: isoSpecifier,
    },
    xFormat: (date: DatumValue) => formatDateValue(date as Date, dimensionGranularity),
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      format: formatLabel,
      tickValues: xTickValues,
      renderTick: tick => getCustomTick(tick),
    },
  };
  const colors = [...colorSchemes.dark2, '#15BECF', '#1F76B4'];
  return (
    <ResponsiveWrapper>
      {({ width }) => (
        <Line
          {...yProps}
          {...xProps}
          data={linesData}
          width={width}
          height={390}
          margin={{ top: 30, right: 40, bottom: 150, left: 80 }}
          tooltip={value => formatTooltip(value, xTickValues)}
          // pointSize={10}
          // pointColor={{ theme: 'background' }}
          // pointBorderWidth={2}
          // pointBorderColor={{ from: 'serieColor', modifiers: [] }}
          // pointLabelYOffset={-12}
          colors={colors}
          useMesh={true}
          legends={[
            {
              anchor: 'bottom-left',
              direction: 'row',
              justify: false,
              translateX: -6,
              translateY: 55,
              itemsSpacing: 1,
              itemDirection: 'left-to-right',
              itemWidth: 90,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 10,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      )}
    </ResponsiveWrapper>
  );
};

export default LinesPanel;
