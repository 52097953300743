import { DateTime } from 'luxon';

export const CustomDateRange = 'custom';
export type PresetDateRanges = '3_days' | '7_days' | '1_month' | '3_months';
export type ValidDateRanges = PresetDateRanges | typeof CustomDateRange;

type DateRangesMap = {
  [key in PresetDateRanges]: {
    label: string;
    fn: (now: DateTime) => [DateTime, DateTime];
    previousFn: (now: DateTime) => [DateTime, DateTime];
  };
};

export const DateRangesMap: DateRangesMap = {
  '3_days': {
    label: '3 days',
    fn: now => [now.minus({ days: 2 }).startOf('day'), now],
    previousFn: now => [now.minus({ days: 5 }).startOf('day'), now.minus({ days: 3 }).endOf('day')],
  },
  '7_days': {
    label: '7 days',
    fn: now => [now.minus({ days: 6 }).startOf('day'), now],
    previousFn: now => [
      now.minus({ days: 13 }).startOf('day'),
      now.minus({ days: 7 }).endOf('day'),
    ],
  },
  '1_month': {
    label: '1 month',
    fn: now => [now.minus({ month: 1 }).startOf('day'), now],
    previousFn: now => [
      now.minus({ months: 2, days: 1 }).startOf('day'),
      now.minus({ month: 1, days: 1 }).endOf('day'),
    ],
  },
  '3_months': {
    label: '3 months',
    fn: now => [now.minus({ month: 3 }).startOf('day'), now],
    previousFn: now => [
      now.minus({ months: 6, days: 1 }).startOf('day'),
      now.minus({ month: 3, days: 1 }).endOf('day'),
    ],
  },
};
