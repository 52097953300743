import React from 'react';
import { EntityType, JSXResponseForEntity } from '../helpers';

const ResponsesForDelayedNotificationResume: React.FC<
  Omit<JSXResponseForEntity, 'logMessage' | 'getEntityComponent'>
> = ({ log, getEntityName, getEntityComponentWithTruncatedName }) => {
  const type = log.assignedTo as EntityType;
  const name = getEntityName(type, log.id);
  return getEntityComponentWithTruncatedName({
    componentType: 'linkWPopover',
    name,
    type,
    id: log.id,
    popoverPlacement: 'left',
  });
};

export default ResponsesForDelayedNotificationResume;
