import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { SearchIcon } from '../../utils/icons';
import InputBlock from '../InputBlock';
import Theme from '../Theme';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  height?: string | number | '50px';
  width?: string | number | '100%' | 'fit-content';
  fontSize?: string | '16px';
  padding?: string | '16px';
  error?: boolean;
  onClear?: () => void;
}

const FocusedSearchShell = styled.div`
  height: fit-content;
  width: fit-content;
  box-shadow: 0 0 0 1px ${props => props.theme.shades.lightGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;

  & > div > input {
    box-shadow: none !important;
    padding: 0;
  }

  & > svg {
    margin: 0 8px;
  }

  & > kbd {
    visibility: visible;
    background: ${props => props.theme.shades.whiteSmoke};
    padding: 4px 8px;
    border-radius: 3px;
    margin-right: 8px;
    font-size: 12px;
  }

  &:hover:not(:disabled),
  &:focus-within {
    box-shadow: 0 0 0 1px ${props => props.theme.primary.default};
  }

  &:hover:not(:disabled) > svg,
  &:focus-within > svg {
    stroke: ${props => props.theme.primary.default};
  }

  &:focus-within > kbd {
    visibility: hidden;
  }

  &:disabled,
  &.disabled {
    cursor: no-drop !important;
    filter: opacity(0.33);
    color: ${props => props.theme.font.disabled};
    box-shadow: 0 0 0 1px ${props => props.theme.shades.smoke};
  }
`;

const FocusedSearch = (props: IProps) => {
  const { theme } = Theme;
  const inputRef = useRef<HTMLInputElement>();

  const focus = (ev: KeyboardEvent) => {
    if (document.activeElement === inputRef.current && ev.key === 'Escape') {
      if (inputRef.current) {
        inputRef.current.blur();
        if (props.onClear) {
          props.onClear();
        }
      }
      return;
    }

    if (['INPUT', 'TEXTAREA'].includes(document.activeElement?.nodeValue?.toUpperCase() || '')) {
      return;
    }

    if (ev.key !== '/') {
      return;
    }

    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.scrollIntoView({
        block: 'nearest',
        behavior: 'auto',
        inline: 'nearest',
      });
      ev.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', focus);
    return () => {
      document.removeEventListener('keydown', focus, true);
    };
  });

  return (
    <FocusedSearchShell>
      <SearchIcon
        height={props.fontSize || '16px'}
        width={props.fontSize || '16px'}
        style={{ minWidth: props.fontSize || '16px' }}
        stroke={theme.shades.lightGrey}
      />
      <InputBlock {...props} forwardRef={inputRef} />
      <kbd>/</kbd>
    </FocusedSearchShell>
  );
};

/** @component */
export default FocusedSearch;
