import './index.css';

import {
  Avatar,
  DropDownContainer,
  DropDownHook,
  FocusBlock,
  Grid,
  IconButton,
  Para,
  Theme,
} from 'uie/components';
import { matchSorter } from 'match-sorter';
import React, { Component } from 'react';
import { fileUploadViewConfig } from 'views/config/fileUploadConfig';

import { AutoSuggestionDisplayConfig, IProps, IState, MsgRef, NewLineState } from './types';
import { MDEWithMaxHeight, MDEWrapper } from './wrapper';

const { theme } = Theme;
const { imageTexts, ...fileUploadConfig } = fileUploadViewConfig;

class MdBlock extends Component<IProps, IState> {
  private _mdeRef = React.createRef();
  private _inputComboBox: any = React.createRef();
  private _mdeInstance: EasyMDE | null = null;
  private _autoSuggestionDisplayConfig: AutoSuggestionDisplayConfig = {
    users: {
      displayAs: 'User',
      icon: '/icons/greys/profile.svg',
    },
    teams: {
      displayAs: 'Team',
      icon: '/icons/greys/team.svg',
    },
    squads: {
      displayAs: 'Squad',
      icon: '/icons/greys/squad.svg',
    },
    stakeholders: {
      displayAs: 'Stakeholders Group',
      icon: '/icons/greys/stakeholders.svg',
    },
  };
  private _tagMatch = /@\w*/i;

  constructor(props: IProps) {
    super(props);

    this.state = {
      message: this.props.initialMessage || '',
      suggestions: [],
      autoSuggestFocusState: { currentIndex: 0, maxIndex: 0 },
      messageBoxHeight: '100%',
      cursorPos: { ch: 0, line: 0 },
      messageRefs: {},
      position: this.props.position || 'top',
    };
  }

  componentDidMount() {
    this.initialSubstitution();
  }

  resetStates = () => {
    this.setState({
      message: '',
      suggestions: [],
      cursorPos: { ch: 0, line: 0 },
      messageRefs: {},
      autoSuggestFocusState: { currentIndex: 0, maxIndex: 0 },
    });
    ((this._mdeRef.current as any).elementWrapperRef as HTMLElement).scrollTop = 0;
    ((this._mdeRef.current as any).elementWrapperRef as HTMLElement).scrollLeft = 0;
    this._mdeInstance?.value('');

    // if isEditCase
    if (this.props.setEditMessageId) this.props.setEditMessageId('')();
  };

  resetSuggestions = () => {
    this.setState({
      suggestions: [],
      autoSuggestFocusState: { currentIndex: 0, maxIndex: 0 },
    });
  };

  suggestOn = (str: string) => {
    if (str.length === 0) {
      this.resetSuggestions();
      return;
    }

    const words = str.split(' ').slice(0);
    let lastWord = words[words.length - 1];

    if (lastWord === '') {
      lastWord = words[words.length - 2];
    }

    if (!lastWord.match(this._tagMatch)) {
      this.resetSuggestions();
      return;
    }

    const searchName = lastWord.split('@')[1].toLowerCase();

    if (searchName.length < 1) {
      this.resetSuggestions();
      return;
    }

    const autoComplete = Object.values(this.props.entities)
      .map(entity => {
        return matchSorter(Object.values(entity), searchName, {
          threshold: matchSorter.rankings.WORD_STARTS_WITH,
          keys: ['name'],
        });
      })
      .flat();
    const ci =
      this.state.autoSuggestFocusState.currentIndex > autoComplete.length
        ? 0
        : this.state.autoSuggestFocusState.currentIndex;
    this.setState({
      suggestions: autoComplete as any,
      autoSuggestFocusState: { currentIndex: ci, maxIndex: autoComplete.length },
    });
  };

  formatMessage = (message: string) => {
    const { messageRefs } = this.state;
    const inputs = message.split('\n').slice(0);
    Object.values(messageRefs).forEach((replacers, i) => {
      const pos = replacers.pos;
      const currentLine = inputs[pos.line];
      const regex = new RegExp(`\\${replacers.replace}\\b`, 'gi');
      inputs[pos.line] = currentLine.replace(regex, replacers.replacer);
    });
    return inputs.join('\n');
  };

  onMessageChange = (message: string) => {
    const cursorPos = this._mdeInstance?.codemirror.getDoc().getCursor() || { line: 0, ch: 0 };
    if (message === this.state.message) {
      if (
        cursorPos.ch !== this.state.cursorPos.ch &&
        cursorPos.line !== this.state.cursorPos.line
      ) {
        this.setState({
          suggestions: [],
        });
      }
      return;
    }

    if (this.props.onMessageChange) {
      this.props.onMessageChange(this.formatMessage(message));
    }

    const isMultipleLine = message.split('\n').length > 1;
    const numberOfLines = message.split('\n').length;
    const previousNumberOfLines = this.state.message.split('\n').length;

    let newLineState = NewLineState.NoChange;
    if (numberOfLines < previousNumberOfLines) {
      newLineState = NewLineState.Deleted;
    } else if (numberOfLines > previousNumberOfLines) {
      newLineState = NewLineState.Added;
    }

    if (!isMultipleLine) {
      ((this._mdeRef.current as any).elementWrapperRef as HTMLElement).scrollTop = 0;
    }

    const inputs = message.split('\n');
    const words = inputs[cursorPos.line].slice(0, cursorPos.ch || 0);
    this.suggestOn(words);
    this.setState({
      cursorPos,
      message,
      messageRefs: this.recomputeReferences(cursorPos.line, inputs.length, newLineState),
    });
  };

  //zero indexed fromThisLineNumber
  recomputeReferences = (
    currentCursorLine: number,
    numberOfLines: number,
    newLineState: NewLineState,
  ) => {
    if (newLineState == NewLineState.NoChange || numberOfLines == 0) {
      return this.state.messageRefs;
    }

    let fromThisLineNumber =
      newLineState == NewLineState.Added ? currentCursorLine - 1 : currentCursorLine;

    if (fromThisLineNumber < 0) {
      fromThisLineNumber = 0;
    }

    const codemirrorDoc = this._mdeInstance?.codemirror.getDoc();
    const msgRefs = this.state.messageRefs;

    const msgRefsByLine = Object.keys(msgRefs)
      .map(m => msgRefs[m])
      .reduce(function (refs, ref) {
        refs[ref.lineNumber] = refs[ref.lineNumber] || [];
        refs[ref.lineNumber].push(ref);
        return refs;
      }, Object.create(null));

    const hasRefMoved = (ref: MsgRef, codemirrorDoc: CodeMirror.Doc | undefined) => {
      //ref isn't moved if the ref.replace i.e @Strings can be found in the same line after the onMessageChange
      return !(
        ref.replace ==
        codemirrorDoc?.getLine(ref.lineNumber)?.slice(ref.pos.ch, ref.pos.ch + ref.replace.length)
      );
    };

    const movedRefCalculator = (msgRefsInLine: MsgRef[]) => {
      if (!msgRefsInLine) {
        return [];
      }
      return msgRefsInLine.filter((oldRef: MsgRef) => hasRefMoved(oldRef, codemirrorDoc));
    };

    const updatedRefs: any = {};
    const updater = (movedRef: MsgRef, lineCandidate: number) => {
      const foundAt = codemirrorDoc?.getLine(lineCandidate).indexOf(movedRef.replace);
      if (foundAt != -1) {
        updatedRefs[`${lineCandidate}-${foundAt}`] = {
          ...movedRef,
          pos: { ch: foundAt, line: lineCandidate },
          lineNumber: lineCandidate,
        };
      }
      return foundAt;
    };

    const updateRefsForward = (movedRefCalculator: (msgRefsByLine: MsgRef[]) => MsgRef[]) => {
      const movedRefs: MsgRef[] = [];

      for (let line = fromThisLineNumber; line < numberOfLines; line++) {
        movedRefCalculator(msgRefsByLine[line]).forEach(ref => {
          movedRefs.push(ref);
        });
      }

      movedRefs.forEach(movedRef => {
        for (let line = movedRef.lineNumber + 1; line < numberOfLines; line++) {
          if (updater(movedRef, line) != -1) {
            break;
          }
        }
        delete msgRefs[this.getRefKey(movedRef)];
      });
    };

    const updateRefsBackward = (movedRefCalculator: (msgRefsByLine: MsgRef[]) => MsgRef[]) => {
      const movedRefs: MsgRef[] = [];
      for (let line = numberOfLines; line >= fromThisLineNumber; line--) {
        movedRefCalculator(msgRefsByLine[line]).forEach(ref => {
          movedRefs.push(ref);
        });
      }
      movedRefs.forEach(movedRef => {
        for (let line = movedRef.lineNumber - 1; line >= fromThisLineNumber; line--) {
          if (updater(movedRef, line) != -1) {
            break;
          }
        }
        delete msgRefs[this.getRefKey(movedRef)];
      });
    };

    newLineState == NewLineState.Added
      ? updateRefsForward(movedRefCalculator)
      : updateRefsBackward(movedRefCalculator);

    return { ...msgRefs, ...updatedRefs };
  };

  getRefKey = (ref: MsgRef) => {
    return `${ref.lineNumber}-${ref.pos.ch}`;
  };

  onMessageSend = () => {
    const { message } = this.state;
    this.props.sendMessage(this.formatMessage(message));
    this.resetStates();
  };

  initialSubstitution = () => {
    // convert backend data to replace mention
    const { message, messageRefs } = this.state;
    const inputs = message.split('\n').slice(0);

    inputs.forEach((input, line) => {
      inputs[line] = input.replace(/<@U([a-f0-9]{24})>/gm, (_, userId, ch) => {
        const user = this.props.entities.users[userId];
        if (!user) return '@Unknown User';
        const replaceString = `@${user.name}`;
        messageRefs[`${line}-${ch}`] = {
          lineNumber: line,
          _id: userId,
          name: user.name,
          pos: { ch, line },
          replace: replaceString,
          type: 'users',
          replacer: `<@U${userId}>`,
        };
        return replaceString;
      });
      inputs[line] = inputs[line].replace(/<@SQ([a-f0-9]{24})>/gm, (_, squadId, ch) => {
        const squad = this.props.entities.squads[squadId];
        if (!squad) return '@Unknown squad';

        const replaceString = `@${squad.name}`;
        messageRefs[`${line}-${ch}`] = {
          lineNumber: line,
          _id: squadId,
          name: squad.name,
          pos: { ch, line },
          replace: replaceString,
          type: 'squads',
          replacer: `<@SQ${squadId}>`,
        };
        return replaceString;
      });
      inputs[line] = inputs[line].replace(/<@T([a-f0-9]{24})>/gm, (_, teamId, ch) => {
        const team = this.props.entities.teams[teamId];
        if (!team) return '@Unknown team';

        const replaceString = `@${team.name}`;
        messageRefs[`${line}-${ch}`] = {
          lineNumber: line,
          _id: teamId,
          name: team.name,
          pos: { ch, line },
          replace: replaceString,
          type: 'teams',
          replacer: `<@T${teamId}>`,
        };
        return replaceString;
      });
      inputs[line] = inputs[line].replace(/<@SG[a-zA-Z0-9-]+>/gm, (e: string, _, ch) => {
        const stkGroupId = e.replace(/(<@SG)|(>)/gm, '');
        const stakeHolders = this.props.entities.stakeholders[stkGroupId];
        if (!stakeHolders) return '@Unknown stakeHolders group';
        const replaceString = `@${stakeHolders.name}`;
        messageRefs[`${line}-${ch}`] = {
          lineNumber: line,
          _id: stkGroupId,
          name: stakeHolders.name,
          pos: { ch, line },
          replace: replaceString,
          type: 'teams',
          replacer: `<@SG${stkGroupId}>`,
        };
        return replaceString;
      });
    });

    this.setState({ messageRefs, message: inputs.join('\n') });
  };

  handleFocusIndex = (type: 'up' | 'down') => () => {
    const { autoSuggestFocusState, suggestions } = this.state;
    const cursor = this._mdeInstance?.codemirror?.getCursor();

    switch (type) {
      case 'up': {
        if (!suggestions.length) {
          this._mdeInstance?.codemirror.execCommand('goLineUp');
        }

        if (autoSuggestFocusState.currentIndex - 1 < 0) {
          return;
        }
        this.setState({
          autoSuggestFocusState: {
            currentIndex: autoSuggestFocusState.currentIndex - 1,
            maxIndex: autoSuggestFocusState.maxIndex,
          },
        });
        break;
      }
      case 'down': {
        if (!suggestions.length) {
          this._mdeInstance?.codemirror.execCommand('goLineDown');
        }

        if (autoSuggestFocusState.currentIndex + 1 === autoSuggestFocusState.maxIndex) {
          return;
        }
        this.setState({
          autoSuggestFocusState: {
            currentIndex: autoSuggestFocusState.currentIndex + 1,
            maxIndex: autoSuggestFocusState.maxIndex,
          },
        });
        break;
      }
      default:
        break;
    }
  };

  onSuggestionClick = (index: number) => (e: any, i: number, v: any) => {
    this.setState(
      ({ autoSuggestFocusState }) => ({
        autoSuggestFocusState: { currentIndex: index, maxIndex: autoSuggestFocusState.maxIndex },
      }),
      this.replaceTextWithSuggestion,
    );
  };

  replaceTextWithSuggestion = () => {
    const { message, suggestions, messageRefs } = this.state;
    const i = this.state.autoSuggestFocusState.currentIndex;

    const cursorPos = this._mdeInstance?.codemirror.getDoc().getCursor() || { line: 0, ch: 0 };
    const inputs = message.split('\n');

    const words = inputs[cursorPos.line]
      .slice(0, cursorPos.ch || 0)
      .split(' ')
      .slice(0);
    let lastWord = words[words.length - 1];

    if (lastWord === '') {
      lastWord = words[words.length - 2];
    }

    if (!lastWord.match(this._tagMatch)) {
      this.resetSuggestions();
      return;
    }

    const calcCursorPos = { ch: cursorPos.ch - lastWord.length, line: cursorPos.line };
    messageRefs[`${cursorPos.line}-${cursorPos.ch - lastWord.length}`] = {
      lineNumber: cursorPos.line,
      replace: `@${suggestions[i].name}`,
      pos: calcCursorPos,
      ...suggestions[i],
      replacer: `<${suggestions[i].encoder}${suggestions[i]._id}>`,
    };

    const replaceString = `@${suggestions[i].name} `;
    this._mdeInstance?.codemirror.getDoc().replaceRange(replaceString, calcCursorPos, cursorPos);

    const finalCursorPos = {
      ch: calcCursorPos.ch + replaceString.length,
      line: calcCursorPos.line,
    };

    this.setState(
      {
        message: this._mdeInstance?.codemirror.getValue() || inputs.join('\n'),
        messageRefs: messageRefs,
        suggestions: [],
        autoSuggestFocusState: { currentIndex: 0, maxIndex: 0 },
        cursorPos: finalCursorPos,
      },
      () => {
        this._mdeInstance?.codemirror.focus();
        this._mdeInstance?.codemirror.setCursor(finalCursorPos);
      },
    );
    if (this.props.onMessageChange) {
      this.props.onMessageChange(
        this.formatMessage(this._mdeInstance?.codemirror.getValue() || inputs.join('\n')),
      );
    }
  };

  setMdeInstance = (i: EasyMDE) => {
    this._mdeInstance = i;
    this._mdeInstance.codemirror.addKeyMap({
      Up: this.handleFocusIndex('up'),
      Down: this.handleFocusIndex('down'),
      Tab: () => {
        if (this.state.suggestions.length === 0) {
          return;
        }
        this.replaceTextWithSuggestion();
      },
      Enter: () => {
        if (this.state.suggestions.length === 0) {
          this.onMessageSend();
          return;
        }
        this.replaceTextWithSuggestion();
      },
      'Shift-Enter': () => {
        this._mdeInstance?.codemirror.getDoc().replaceSelection('\n');
      },
    });
  };

  calcEditorOffset = () => {
    const { cursorPos, suggestions } = this.state;
    const tagHeight = 48;
    const maxEditorWidth = 400;
    const editorWidth =
      this._mdeInstance?.codemirror.getWrapperElement().offsetWidth ?? maxEditorWidth;
    /**
     * Checks if the tagDropdown overflows max editor width
     */
    const tagListOverflow = editorWidth - cursorPos.ch * 5 < maxEditorWidth;
    /**
     * Default offset top for tagDropdown
     */
    if (this.state.position === 'bottom') {
      return {
        tagListOverflow,
        editorWidth,
        offsetTop: '220px',
      };
    }
    const tagsOffset = Math.min(suggestions.length, 4) * tagHeight;
    const editorBlock = this._mdeInstance?.codemirror
      .getWrapperElement()
      .closest('.response-message-container') as HTMLElement;
    if (editorBlock) {
      return {
        tagListOverflow,
        editorWidth,
        offsetTop:
          editorBlock.offsetTop > tagsOffset
            ? `-${33 + tagsOffset}px`
            : `${editorBlock.clientHeight - 40}px`,
      };
    }
    return { tagListOverflow, editorWidth, offsetTop: `-${33 + tagsOffset}px` };
  };

  render() {
    const { autoSuggestFocusState, cursorPos, message, messageBoxHeight, suggestions } = this.state;

    if (this._mdeInstance?.codemirror) {
      this._mdeInstance.codemirror.setSize('100%', 'inherit');
    }

    const { tagListOverflow, editorWidth, offsetTop } = this.calcEditorOffset();

    return (
      <>
        <DropDownHook>
          <Grid flexWidth={12} type="column">
            <div className="w-1-1" ref={this._inputComboBox} />
            <MDEWrapper maxHeight={144}>
              <MDEWithMaxHeight
                ref={this._mdeRef}
                value={message}
                height={messageBoxHeight}
                alignItems="flex-end"
                noDivider={true}
                onChange={this.onMessageChange}
                className="message-block-chat-interface"
                inputClassName="message-block-chat-interface-input"
                elementHook={
                  this.props.hideSendButton ? (
                    <div />
                  ) : (
                    <Grid>
                      <IconButton onClick={this.onMessageSend} color={theme.success.default}>
                        <img src="/icons/send.svg" alt="send" />
                      </IconButton>
                    </Grid>
                  )
                }
                options={{
                  spellChecker: false,
                  placeholder: this.props.placeHolder || 'Add your notes here, supports markdown',
                  indentWithTabs: false,
                  imageUploadFunction: this.props?.fileUploadService.getUploadFunctionForFeature(
                    this.props.featureName,
                    this.props.operationType,
                  ),
                  imageTexts: {
                    ...imageTexts,
                    sbInit: ``,
                  },
                  ...fileUploadConfig,
                  renderImageUploadsAsLinks: false,
                }}
                getMdeInstance={this.setMdeInstance}
              />
            </MDEWrapper>
            <div style={{ height: 12 }}>
              <Para
                fontSize={12}
                style={{ marginTop: 8, marginBottom: -8, lineHeight: '12px', textAlign: 'right' }}
              >
                {this.state.message.length > 0 && (
                  <span>
                    <span className="font-bold">Shift + Enter</span> for new line
                  </span>
                )}
              </Para>
            </div>
            {suggestions.length > 0 && (
              <DropDownContainer
                offset={tagListOverflow ? `${(editorWidth - 400) / 2}px` : `${cursorPos.ch * 5}px`}
                width="70%"
                maxWidth="400px"
                maxHeight="200px"
                top={offsetTop}
                invertAnimation={true}
                style={{ display: 'block' }}
                className="message-block-suggestions-container"
              >
                {suggestions.map((e: any, i) => {
                  return (
                    <FocusBlock
                      isSelected={autoSuggestFocusState.currentIndex === i}
                      scrollTo={autoSuggestFocusState.currentIndex === i}
                      value={e._id}
                      key={i}
                      onSelectValue={this.onSuggestionClick(i)}
                      style={{ opacity: 1 }}
                    >
                      <Grid alignItems="center" justifyContent="flex-start" width="100%">
                        <Avatar
                          base={16}
                          reduceColorString={e._id}
                          attributeHook={this.props.onCallUsers.has(e._id) as any}
                          attributeProps={{
                            backgroundColor: theme.success.default,
                            base: 4,
                          }}
                        >
                          {e.name}
                        </Avatar>
                        <div>
                          <Para fontSize={16} className="ml-10">
                            {e.name} &nbsp; ({this._autoSuggestionDisplayConfig[e.type].displayAs}
                            &nbsp;
                            <img
                              color={theme.shades.smoke}
                              width={14}
                              src={this._autoSuggestionDisplayConfig[e.type].icon}
                              alt={e.type}
                              style={{
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                marginBottom: '1px',
                              }}
                            />
                            )
                          </Para>
                        </div>
                      </Grid>
                    </FocusBlock>
                  );
                })}
              </DropDownContainer>
            )}
          </Grid>
        </DropDownHook>
      </>
    );
  }
}

export default MdBlock;
