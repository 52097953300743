import { Text } from '@chakra-ui/react';
import { toSentenceCase } from 'core/helpers/stringUtils';
import { IEventWebHook } from 'core/interfaces/IEventWebHooks';
import { Cell } from 'react-table';

import { PAYLOAD_TYPES } from './webhook-const';

export const WEBHOOK_LIST_COLUMN_DEFS = [
  {
    Header: 'WEBHOOK NAME',
    Cell: (cell: Cell<IEventWebHook>) => {
      return <Text fontWeight="500">{cell.row.original.name}</Text>;
    },
  },
  {
    Header: 'WEBHOOK TYPE',
    Cell: (cell: Cell<IEventWebHook>) => {
      return <Text>{toSentenceCase(cell.row.original.trigger_type)}</Text>;
    },
  },
  {
    Header: 'PAYLOAD TYPE',
    Cell: (cell: Cell<IEventWebHook>) => {
      const text =
        cell.row.original.payload_type === PAYLOAD_TYPES.CUSTOM
          ? 'Custom Payload'
          : 'Standard Squadcast Payload';
      return <Text>{text}</Text>;
    },
  },
];
