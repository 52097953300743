import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react';
import { IAppState } from 'core/interfaces/IAppState';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { components } from 'react-select';
import { SelectOwnerDropdown } from 'views/main/organization/owner-filters/Dropdown';
import { getDropdownOptionDetailsForTeam } from 'views/main/organization/owner-filters/helpers';
import {
  DropdownOption,
  DropdownSection,
  Option,
  Owner,
} from 'views/main/organization/owner-filters/hooks/useEntityOwnerFilter';
import { SelectedFilterTag } from 'views/main/organization/owner-filters/SelectedFilterTags';
import { useSquadListing } from '../../../squads/hooks/useSquad';
import { useEntityListStore } from '../store';
import { ActiveFilterTags } from './ActiveFilterTags';
import { ClearSearchButton } from './ClearSearchButton';
import { SearchInput } from './Searchinput';
import { Actions, Header, Title, Wrapper } from './style';

const Group = (props: any) => {
  const {
    children,
    label,
    selectProps: { inputValue },
  } = props;

  return (
    <Accordion
      allowToggle={true}
      defaultIndex={label === DropdownSection.YOU_AND_YOUR_SQUADS ? 0 : -1}
      {...(inputValue ? { index: 0 } : {})}
    >
      <AccordionItem>
        {({ isExpanded = false }) => (
          <>
            <AccordionButton
              _expanded={{ background: 'white !important' }}
              padding="8px 12px"
              sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}
            >
              <HStack p={0}>
                <Text color="#646464" fontWeight={700}>
                  {label}
                </Text>
                {isExpanded ? (
                  <ChevronDownIcon width={6} height={6} color="#646464" />
                ) : (
                  <ChevronRightIcon width={6} height={6} color="#646464" />
                )}
              </HStack>
            </AccordionButton>
            <AccordionPanel p={0}>{children}</AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

const Menu = (props: any) => {
  const { children } = props;
  return (
    <components.Menu {...props}>
      <Flex
        sx={{
          ...{
            boxShadow: '0px 4px 10px 0px #0000000D',
          },
          ...{
            boxShadow: '2px 0px 10px 0px #0000000D',
          },
          width: '100%',
        }}
      >
        {children}
      </Flex>
    </components.Menu>
  );
};

type EntitiesPageHeaderProps = {
  title: string;
  searchPlaceholder: string;
  searchValue?: string;
  setSearchValue: (value: string) => void;
  count?: number;
};

export const EntitiesPageHeader: React.FC<EntitiesPageHeaderProps> = ({
  title,
  searchPlaceholder,
  setSearchValue,
  searchValue,
  count,
}) => {
  const organization = useSelector((state: IAppState) => state.organization);
  const teamID = useParams<{ id: string }>().id ?? '';

  const selectedEntitiesOwner = useEntityListStore(state => state.entity_owner);
  const setSelectedEntitiesOwner = useEntityListStore(state => state.setEntityOwner);

  const { data } = useSquadListing();

  const { currentUserOption, squadsOfCurrentUser, otherSquads, otherUsersOption, selectAllOption } =
    getDropdownOptionDetailsForTeam(
      organization,
      teamID,
      data?.data ?? {
        data: [],
      },
    );

  const allDropdownOptions = [
    currentUserOption.length > 0 || squadsOfCurrentUser.length > 0
      ? {
          label: DropdownSection.YOU_AND_YOUR_SQUADS,
          options: [selectAllOption, ...currentUserOption, ...squadsOfCurrentUser].filter(
            option => selectedEntitiesOwner.findIndex(o => o.value === option.value) === -1,
          ),
        }
      : null,
    otherSquads.length > 0
      ? {
          label: DropdownSection.OTHER_SQUADS,
          options: [...otherSquads].filter(
            option => selectedEntitiesOwner.findIndex(o => o.value === option.value) === -1,
          ),
        }
      : null,
    otherUsersOption.length > 0
      ? {
          label: DropdownSection.USERS,
          options: [...otherUsersOption].filter(
            option => selectedEntitiesOwner.findIndex(o => o.value === option.value) === -1,
          ),
        }
      : null,
  ].filter(Boolean) as DropdownOption[];

  const onChangeHandler = (value: Option) => {
    if (value.value === 'select-all') {
      setSelectedEntitiesOwner([...currentUserOption, ...squadsOfCurrentUser]);
      return;
    }
    setSelectedEntitiesOwner([...selectedEntitiesOwner, value]);
  };

  const handleActiveFilterTagClick = (_: Owner, id: string) => {
    const filteredOption = selectedEntitiesOwner.filter(option => option.value !== id);
    setSelectedEntitiesOwner(filteredOption);
  };

  const activeFilterTags: SelectedFilterTag[] = selectedEntitiesOwner.map(option => ({
    type: option.type,
    id: option.value,
    title: option.label,
    username: option.username,
  }));

  return (
    <Wrapper>
      <Header>
        <Title>
          {title} ({count})
        </Title>
        <Actions>
          <div
            style={{
              position: 'relative',
            }}
          >
            <SelectOwnerDropdown
              variant="avatar"
              teamOptionLabelStyles={{
                color: '#3D485E',
                fontSize: 14,
              }}
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  minHeight: '38px',
                }),
                control: (provided, state) => ({
                  ...provided,
                  minHeight: '38px',
                }),
                placeholder: (provided, state) => ({
                  ...provided,
                  color: '#3D485E',
                  fontSize: 14,
                }),

                indicatorsContainer: (provided, state) => ({
                  ...provided,
                  marginRight: selectedEntitiesOwner?.length > 0 ? 30 : 8,
                  color: '#3D485E',
                }),
              }}
              menuPortalTarget={document.body}
              options={allDropdownOptions}
              value={[]}
              placeholder="Filter by Owner"
              components={{
                Group,
                Menu,
              }}
              onChange={onChangeHandler as any}
              applyChakraStyle={false}
              closeMenuOnSelect
              isClearable
              controlShouldRenderValue={false}
            />
            <ClearSearchButton
              onClick={() => setSelectedEntitiesOwner([])}
              numberOfSelectedItems={selectedEntitiesOwner.length}
            />
          </div>
          <SearchInput
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder={searchPlaceholder}
          />
        </Actions>
      </Header>
      <ActiveFilterTags
        activeFilterTags={activeFilterTags}
        removeFilter={handleActiveFilterTagClick}
      />
    </Wrapper>
  );
};
