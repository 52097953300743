import React, { useCallback } from 'react';
import styled from 'styled-components';

import { Bar, BarDatum, BarTooltipProps } from '@nivo/bar';
import { ResponsiveWrapper } from '@nivo/core';
import { Theme } from 'uie/components';

import { BarsChart, Filters } from '../types';

type Props = { filters: Filters; chart: BarsChart; bars: BarDatum[] };

const { theme } = Theme;

const CustomTooltip = styled('div')<{ itemColor: string }>`
  background: ${theme.shades.white};
  color: inherit;
  font-size: inherit;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25);
  padding: 5px 9px;
  border: 1px solid ${theme.shades.smoke};

  & > div {
    white-space: pre;
    display: flex;
    align-items: center;

    & > span:first-child {
      display: block;
      background-color: ${({ itemColor }) => itemColor};
      width: 12px;
      height: 12px;
      margin-right: 7px;
    }
  }
`;

const formatTooltip = ({ indexValue, value, color }: BarTooltipProps<BarDatum>) => {
  return (
    <CustomTooltip itemColor={color}>
      <div>
        <span></span>
        <span>
          {indexValue}: <strong>{value}</strong>
        </span>
      </div>
    </CustomTooltip>
  );
};

const TRUNCATE_LEGEND_THRESHOLD_CHARS = 10;

const BarsPanel: React.FC<Props> = ({ filters, chart, bars }) => {
  const getBarValue = useCallback(
    (barLabel: string) => {
      return bars.find(b => b.x === barLabel)?.[chart.measures[0]];
    },
    [bars, chart],
  );

  return (
    <ResponsiveWrapper>
      {({ width }) => (
        <Bar
          width={width}
          height={350}
          data={bars}
          keys={chart.measures}
          tooltip={formatTooltip}
          indexBy="x"
          colors={{ scheme: 'dark2' }}
          layout="horizontal"
          margin={{ top: 20, right: 40, bottom: 50, left: 80 }}
          padding={0.7}
          labelSkipWidth={0}
          labelSkipHeight={12}
          colorBy="indexValue"
          labelTextColor={theme.shades.black}
          enableGridX={false}
          enableGridY={false}
          axisBottom={{
            legendOffset: 10,
            tickRotation: -25,
          }}
          axisLeft={{
            format: (v: string) => {
              return v.length > TRUNCATE_LEGEND_THRESHOLD_CHARS ? (
                <tspan>
                  {`${v.substring(0, TRUNCATE_LEGEND_THRESHOLD_CHARS)}...`}
                  <title>
                    {v} - {getBarValue(v)}
                  </title>
                </tspan>
              ) : (
                <tspan>
                  {v}
                  <title>
                    {v} - {getBarValue(v)}
                  </title>
                </tspan>
              );
            },
          }}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: theme.shades.grey,
                  strokeWidth: 1,
                },
              },
            },
          }}
        />
      )}
    </ResponsiveWrapper>
  );
};

export default BarsPanel;
