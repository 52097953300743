import { IAdvancedFilterFilterOperations, IAdvancedFilterGroupOperations } from './types';

export const selectableList = ['eq', 'neq'];
export const inputableList = ['matches', 'nmatches'];

export const isInstanceofGroup = (d: any) => 'conditions' in d;
export const isInstanceofFilter = (d: any) => 'lhs' in d && 'rhs' in d;

export const availableGroupOperations: { [key in IAdvancedFilterGroupOperations]: string } = {
  and: 'And',
  or: 'Or',
};

export const availableFilterOperations: { [key in IAdvancedFilterFilterOperations]: string } = {
  eq: 'is',
  neq: 'is not',
  matches: 'matches',
  nmatches: 'does not match',
};
