import React from 'react';
import { IRunbook } from '../../../../../../core/interfaces/IRunBook';
import { runbookListTable, DateFormats } from './taskAndRunbook.constants';
import { Checkbox } from 'uie/components';
import moment from 'moment';

interface IProps {
  runbook: IRunbook[];
  selectedRunbooksList: IRunbook[];
  setSelectedRunbooksList: (runbook: IRunbook[]) => void;
}

const RenderTable = (props: IProps) => {
  const listOfChekedRunbook = (runbook: IRunbook) => {
    return props.selectedRunbooksList.filter(runbook_ => {
      return runbook_.id === runbook.id;
    });
  };

  return (
    <table className="incident__details__runbook_list_table">
      <thead>
        <tr>
          {runbookListTable.map(({ name, size }, index) => {
            return (
              <th key={index} style={{ width: `${size}`, textAlign: 'left' }}>
                {name}
              </th>
            );
          })}
        </tr>
      </thead>

      {props.runbook.length > 0 && (
        <tbody>
          {props.runbook.map((runbook: IRunbook, index) => {
            return (
              <tr key={index}>
                <td>
                  <Checkbox
                    className="mr-20"
                    checked={!!listOfChekedRunbook(runbook).length}
                    onChange={e => {
                      if (listOfChekedRunbook(runbook).length) {
                        const selectedExcept = props.selectedRunbooksList.filter(
                          _ => _.id != runbook.id,
                        );
                        props.setSelectedRunbooksList(selectedExcept);
                      } else {
                        const selectedWith = [...props.selectedRunbooksList, runbook];
                        props.setSelectedRunbooksList(selectedWith);
                      }
                    }}
                  />
                </td>
                <td>{runbook.name}</td>
                <td>{moment(runbook.created.at).format(DateFormats.create_task_runbook)}</td>
                <td>{runbook.created.user_name}</td>
                <td>{moment(runbook.updated.at).format(DateFormats.create_task_runbook)}</td>
                <td>{runbook.updated.user_name}</td>
                <td>{runbook.used_count}</td>
              </tr>
            );
          })}
        </tbody>
      )}
    </table>
  );
};

export default RenderTable;
