import { AccordionTheme } from './Accordion.theme';
import { BreadcrumbTheme } from './Breadcrumb.theme';
import { CheckboxTheme } from './Checkbox.theme';
import { CircularProgressTheme } from './CircularProgress.theme';
import { CloseButtonTheme } from './CloseButton.theme';
import { DatePickerTheme } from './DatePicker.theme';
import { DividerTheme } from './Divider.theme';
import { DropdownTheme } from './Dropdown.theme';
import { FormButtonTheme } from './button-theme/FormButton.theme';
import { IconButtonTheme } from './IconButton.theme';
import { InputTheme } from './Input.theme';
import { LinearProgressTheme } from './LinearProgress.theme';
import { LinkTheme } from './Link.theme';
import { ListingButtonTheme } from './button-theme/ListingButton.theme';
import { NumberInputTheme } from './NumberInput.theme';
import { PageHeaderTheme } from './PageHeader.theme';
import { RadioTheme } from './Radio.theme';
import { SearchTheme } from './Search.theme';
import { SmallButtonTheme } from './button-theme/SmallButton.theme';
import { SpinnerTheme } from './Spinner.theme';
import { SwitchTheme } from './Switch.Theme';
import { THEME_COLORS } from './colors';
import { TabTheme } from './Tab.theme';
import { TableTheme } from './Table.theme';
import { TagTheme } from './Tag.theme';
import { TextTheme } from './Text.theme';
import { TextareaTheme } from './Textarea.theme';
import { ToastTheme } from './Toast.theme';
import { TooltipTheme } from './Tooltip.theme';
import { extendTheme } from '@chakra-ui/react';
import { ButtonTheme } from './button-theme/Button.theme';

import {
  THEME_SPACES,
  THEME_RADIUS,
  THEME_SIZES,
  THEME_BORDER,
  THEME_BORDER_WIDTHS,
} from './Spacing';
import { LayoutTheme } from './Layout.theme';
import { DrawerTheme } from './Drawer.theme';
import { TableThemeV2 } from './TableV2.theme';
import { EntityUITheme } from './EntityUI.theme';
import { SeeMoreTheme } from './SeeMore.theme';
import { TagThemeV2 } from './TagV2.theme';
import { TagStackTheme } from './TagStack.theme';
import { FormFieldTheme } from './FormField.theme';
import { FrameWorkDropdownTheme } from './FrameWorkDropdown.theme';
import { PaginationTheme } from './Pagination.theme';

export const fontTheme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: 'Mulish',
      },
      heading: {
        fontFamily: 'Mulish',
      },
      table: {
        fontFamily: 'Mulish',
        '& *': {
          fontFamily: 'Mulish !important',
        },
      },
    },
  },
});

const libraryTheme = extendTheme({
  breakpoints: {
    md: '1440px',
    lg: '1920px',
  },
  fonts: {
    heading: `'Mulish', sans-serif`,
    mono: `'Mulish', sans-serif`,
  },
  colors: THEME_COLORS,
  sizes: THEME_SIZES,
  space: THEME_SPACES,
  radii: THEME_RADIUS,
  borders: THEME_BORDER,
  borderWidths: THEME_BORDER_WIDTHS,
  styles: {
    global: {
      body: {
        fontFamily: 'Mulish',
      },
    },
  },
  components: {
    // NOTE: please arrange components alphabetically for easier search/read
    Accordion: AccordionTheme,
    Breadcrumb: BreadcrumbTheme,
    Button: ButtonTheme,
    Checkbox: CheckboxTheme,
    CircularProgress: CircularProgressTheme,
    CloseButton: CloseButtonTheme,
    DatePicker: DatePickerTheme,
    Divider: DividerTheme,
    Dropdown: DropdownTheme,
    Drawer: DrawerTheme,
    EntityUI: EntityUITheme,
    FormField: FormFieldTheme,
    FormButton: FormButtonTheme,
    FrameWorkDropdown: FrameWorkDropdownTheme,
    IconButton: IconButtonTheme,
    Input: InputTheme,
    Layout: LayoutTheme,
    Link: LinkTheme,
    ListingButton: ListingButtonTheme,
    NumberInput: NumberInputTheme,
    PageHeader: PageHeaderTheme,
    Pagination: PaginationTheme,
    Progress: LinearProgressTheme,
    Radio: RadioTheme,
    Search: SearchTheme,
    SmallButton: SmallButtonTheme,
    Spinner: SpinnerTheme,
    Switch: SwitchTheme,
    SeeMore: SeeMoreTheme,
    Tag: TagTheme,
    Text: TextTheme,
    Textarea: TextareaTheme,
    Toast: ToastTheme,
    Tooltip: TooltipTheme,
    Tab: TabTheme,
    Table: TableTheme,
    TableV2: TableThemeV2,
    TagThemeV2: TagThemeV2,
    TagStack: TagStackTheme,
  },
});

export default libraryTheme;
