import {
  useGetListOfSchedulesQuery,
  useGetSchedulesFromOverridesForProfileQuery,
} from 'views/main/organization/schedules/graphql/query';
import React, { useEffect, useMemo, useState } from 'react';
import { defaultReactQueryConfig, reactQueryConfigError } from '../../helpers/helpers.schedule';
import { IAppState } from 'core/interfaces/IAppState';
import { DateTime } from 'luxon';

export type ScheduleInfoForProfile = {
  __typename?: 'Schedule';
  ID: number;
  name: string;
  teamID: string;
};

export function withSchedulesForProfile<T>(InputComponent: React.ComponentType<T>) {
  return (props: T) => {
    const [schedulesList, setScheduleslist] = useState<ScheduleInfoForProfile[]>([]);
    const [schedulesListFromOverrides, setSchedulesListFromOverrides] = useState<
      ScheduleInfoForProfile[]
    >([]);
    const organization = (props as unknown as IAppState).organization;
    const profileUserId = (props as unknown as any).match.params.userId ?? '';
    const profileUserInfo = organization.users.u.find(user => user.id === profileUserId);
    const profileUserTimezone =
      profileUserInfo?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzDTToday = DateTime.local();
    const tzDTAfterTwoWeeks = tzDTToday.plus({ days: 14 });

    const { data: allSchedules } = useGetListOfSchedulesQuery(
      {
        filters: {
          ...(profileUserId ? { participants: [profileUserId] } : {}),
        },
      },
      { ...defaultReactQueryConfig, onError: reactQueryConfigError('Get Schedules') },
    );

    const { data: allSchedulesFromOverrides } = useGetSchedulesFromOverridesForProfileQuery(
      {
        from: tzDTToday.setZone(profileUserTimezone).startOf('day').toUTC().toISO(),
        till: tzDTAfterTwoWeeks.setZone(profileUserTimezone).endOf('day').toUTC().toISO(),
        overrideFilters: {
          participantID: profileUserId,
        },
      },
      { ...defaultReactQueryConfig, onError: reactQueryConfigError('Get Schedules') },
    );

    useEffect(() => {
      if (allSchedules) {
        setScheduleslist(allSchedules.schedules ?? []);
      }
    }, [allSchedules]);

    useEffect(() => {
      if (allSchedulesFromOverrides) {
        setSchedulesListFromOverrides(
          (allSchedulesFromOverrides.schedules ?? []).filter(
            schedule => schedule.overrides && schedule.overrides.length > 0,
          ),
        );
      }
    }, [allSchedulesFromOverrides]);

    const uniqueSchedules: ScheduleInfoForProfile[] = useMemo(() => {
      const result: ScheduleInfoForProfile[] = [];
      [...schedulesList, ...schedulesListFromOverrides].forEach(schedule => {
        if (!result.find(sch => sch.ID === schedule.ID)) {
          result.push(schedule);
        }
      });
      return result;
    }, [schedulesList, schedulesListFromOverrides]);

    return <InputComponent {...props} schedulesList={uniqueSchedules} />;
  };
}

export default withSchedulesForProfile;
