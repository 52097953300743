import { Box, Grid } from '@chakra-ui/react';
import { useParams } from 'react-router';
import { BoxLoader } from '../components';
import { useGetGerDetail } from '../query/useGetGerDetail';

import { AddRulesetDetails } from './add-ruleset';
import { HelpAndGuide } from './help-and-guide';
import { gerHelpAndGuideData } from './help-and-guide/data';

export const GERCreate = () => {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useGetGerDetail(id);

  if (isLoading) {
    return <BoxLoader />;
  }

  return (
    <Grid templateColumns="2.25fr 1fr" gap={3} overflowY="auto" h="inherit">
      <Box width="full" bg="container.background" display="flex" flexDir="column">
        <AddRulesetDetails gerData={data} />
      </Box>
      <HelpAndGuide data={gerHelpAndGuideData} />
    </Grid>
  );
};
