import React from 'react';
import { SPLIT_WORD_MAP } from '../../incidentDetails/constants';
import { JSXResponseForEntity } from '../helpers';

const ResponsesForManualMergeChildLog: React.FC<
  Omit<JSXResponseForEntity, 'logMessage' | 'getEntityName'>
> = ({ log, getEntityComponent, getEntityComponentWithTruncatedName }) => {
  const action = log.actionType.includes('_merged_') ? 'merge' : 'unmerge';
  const nameComponent = getEntityComponentWithTruncatedName({
    componentType: 'linkWPopover',
    type: 'user',
    id: log.id,
    popoverPlacement: 'left',
  });
  const splitWord = SPLIT_WORD_MAP[log.actionType as keyof typeof SPLIT_WORD_MAP];
  const parentIncidentId = log.additionalInfo?.parent ?? '';
  let parentIncidentMsg = log.reason.split(splitWord)[1].replace(' incident', '');
  parentIncidentMsg = `${
    parentIncidentMsg.length >= 30 ? parentIncidentMsg.slice(0, 30) : parentIncidentMsg
  }${parentIncidentMsg.length >= 30 ? '...' : ''}`;

  let messageComponent = <></>;

  if (action === 'merge') {
    messageComponent = (
      <>Incident suppressed due to manual merge by {nameComponent}. The parent incident is </>
    );
  } else {
    messageComponent = <>{nameComponent} unmerged this incident from </>;
  }
  return (
    <>
      {messageComponent}
      {getEntityComponent({
        componentType: 'linkWOPopover',
        type: 'incident',
        id: parentIncidentId,
        name: parentIncidentMsg,
      })}
    </>
  );
};

export default ResponsesForManualMergeChildLog;
