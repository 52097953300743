import { create } from 'zustand';
import { ACL, EntityACLMeta } from './types';
import Axios from 'axios';

interface UserAccessStore {
  teamLevelACL: ACL | null;
  setTeamLevelACL: (acl: ACL) => void;
  entityACLMap: Partial<Record<keyof ACL, Record<string, EntityACLMeta>>>;
}

export const useUserAccessStore = create<UserAccessStore>((set, get) => ({
  teamLevelACL: null,
  entityACLMap: {},
  setTeamLevelACL: acl => set({ teamLevelACL: acl }),
}));

export const {
  getState: getUserAccessStore,
  subscribe: subscribeToUserAccessStore,
  setState: setUserAccessStore,
} = useUserAccessStore;
