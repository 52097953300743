import {
  REQUEST_ORG_CALENDAR,
  REQUEST_ORG_CALENDAR_SUCCESS,
  REQUEST_ORG_CALENDAR_FAIL,
  REQUEST_ORG_CALENDAR_CONSUMED,
  REQUEST_ORG_CALENDAR_ON_CALL,
  REQUEST_ORG_CALENDAR_ON_CALL_SUCCESS,
  REQUEST_ORG_CALENDAR_ON_CALL_FAIL,
} from '../../const/organization';
import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';
import { ISCalendar } from '../../interfaces/ICalendar';

const initialOrganizationSchedules = INITIAL_STATE.organization.calendar;

const organizationCalendar = (state = initialOrganizationSchedules, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{
          action: RESET_STATE,
          onCall: {},
          c: [],
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ORG_CALENDAR:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_CALENDAR,
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ORG_CALENDAR_SUCCESS:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_CALENDAR_SUCCESS,
          c: action.payload.calendar.sort((a: ISCalendar, b: ISCalendar) =>
            a.name.localeCompare(b.name),
          ),
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ORG_CALENDAR_CONSUMED:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_CALENDAR_CONSUMED,
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ORG_CALENDAR_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_CALENDAR_FAIL,
          c: [],
          error: { type: REQUEST_ORG_CALENDAR_FAIL, reason: action.payload },
        },
      };
    case REQUEST_ORG_CALENDAR_ON_CALL:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_CALENDAR_ON_CALL,
          onCall: {},
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ORG_CALENDAR_ON_CALL_SUCCESS:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_CALENDAR_ON_CALL_SUCCESS,
          onCall: action.payload.onCall,
          error: { type: '', reason: {} },
        },
      };

    case REQUEST_ORG_CALENDAR_ON_CALL_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_CALENDAR_ON_CALL_FAIL,
          onCall: {},
          error: { type: REQUEST_ORG_CALENDAR_ON_CALL_FAIL, reason: action.payload },
        },
      };
    default:
      return state;
  }
};

export default organizationCalendar;
