import {
  REQUEST_ORG_CURRENT_USER,
  REQUEST_ORG_CURRENT_USER_SUCCESS,
  REQUEST_ORG_CURRENT_USER_FAIL,
} from '../../const/organization';
import { IUserProfile } from '../../interfaces/IUserData';

const requestOrganizationCurrentUser = () => ({
  type: REQUEST_ORG_CURRENT_USER,
  payload: {},
});

const requestOrganizationCurrentUserChangeTeam = (teamId: string) => ({
  type: REQUEST_ORG_CURRENT_USER,
  payload: {
    teamId,
  },
});

const onRequestOrganizationCurrentUserSuccess = (user: IUserProfile) => ({
  type: REQUEST_ORG_CURRENT_USER_SUCCESS,
  payload: {
    user,
  },
});

const onRequestOrganizationCurrentUserFail = (error: any) => ({
  type: REQUEST_ORG_CURRENT_USER_FAIL,
  payload: {
    error,
  },
});

export {
  requestOrganizationCurrentUser,
  onRequestOrganizationCurrentUserSuccess,
  onRequestOrganizationCurrentUserFail,
  requestOrganizationCurrentUserChangeTeam,
};
