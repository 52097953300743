import Axios from 'axios';
import { API } from 'core';

export function fetcher<TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
) {
  return async (): Promise<TData> => {
    const body = JSON.stringify({ query, variables });

    const { data } = await Axios.post(
      `${API.config.batman}/organizations/${API.config.organizationId}/graphql`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          ...(options ?? {}),
        },
      },
    );
    if (data.errors) {
      let message = '';
      const errorMessage = data.errors[0];

      if (errorMessage.message || errorMessage.path || errorMessage.extensions) {
        message =
          'Please share the following details with our support team - ' +
          `${
            errorMessage.extensions && errorMessage.extensions.code
              ? 'Extension code: ' + errorMessage.extensions.code + ', '
              : ''
          }` +
          'message: ' +
          errorMessage.message +
          `${errorMessage.path ? ' and path: ' + errorMessage.path.join(',') : ''}`;
      } else {
        message =
          'Something went wrong. Please refresh and try again. If this doesn’t work, chat with us from the widget.';
      }
      throw new Error(message);
    }

    return data.data;
  };
}
