import { API } from '../../api';
import Axios from 'axios';
import {
  ITasksResponse,
  ITaskReq,
  IPatchReq,
  IRunbookIds,
  IRunbookResponse,
  IOrganizationRunbookList,
  IPatchReqRunbook,
} from '../../interfaces/ITask';
import { IAttachmentRequest } from 'core/interfaces/ISignUpload';

class TaskAndRunbookService {
  private _api = '';

  constructor(incidentId: string) {
    this._api = `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${incidentId}`;
  }

  public addTask = (task: ITaskReq) => Axios.post<ITasksResponse>(`${this._api}/tasks`, task);

  public getAllTasks = () => Axios.get<ITasksResponse>(`${this._api}/tasks`);

  public deleteTask = (index: number) => Axios.delete(`${this._api}/tasks/${index}`);

  public updateTask = (update: IPatchReq) =>
    Axios.patch<ITasksResponse>(`${this._api}/tasks`, { ...update });

  public updateTaskCheck = (update: IPatchReq) =>
    Axios.patch<ITasksResponse>(`${this._api}/tasks/complete`, { ...update });

  public addRunbook = (runbookList: IRunbookIds) =>
    Axios.post<IRunbookResponse>(`${this._api}/runbooks`, runbookList);

  public runbookList = () => Axios.get<IRunbookResponse>(`${this._api}/runbooks`);

  public deleteRunbook = (runbook_id: string) =>
    Axios.delete(`${this._api}/runbooks/${runbook_id}`);

  public updateRunbook = (update: IPatchReqRunbook, runbook_id: string) =>
    Axios.patch<ITasksResponse>(`${this._api}/runbooks/${runbook_id}`, { ...update });
  public fetchOrganizationRunbook = () =>
    Axios.get<IOrganizationRunbookList>(
      `${API.config.batman}/organizations/${API.config.organizationId}/runbooks?owner_id=${API.config.teamId}`,
    );
}

export default TaskAndRunbookService;
