import {
  Box,
  Button,
  HStack,
  Select,
  Stack,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { perPage } from 'views/main/organization/slo/slo.constants';

import { MemberTable } from './MemberTable';

interface IProps {
  pageOffset: number;
  pageLimit: number;
  pagination: Array<any>;
  changeLimit: (value: number) => void;
  changeOffset: (value: number) => void;
  children?: JSX.Element | JSX.Element[];
  tableHeader: Array<any>;
  totalPages: number;
}

export const CustomTable = ({
  pageOffset,
  pageLimit,
  pagination,
  changeLimit,
  changeOffset,
  tableHeader,
  ...props
}: IProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box
      bg="bg-surface"
      boxShadow={{ base: 'none', md: useColorModeValue('sm', 'sm-dark') }}
      borderRadius={useBreakpointValue({ base: 'none', md: 'lg' })}
      mt={2}
    >
      <Stack spacing="5">
        <Box overflowX="auto">
          <MemberTable tableHead={tableHeader}>{props.children}</MemberTable>
        </Box>
        {/* Pagination */}
        <Box px={{ base: '4', md: '6' }} pb="5">
          <HStack spacing={60}>
            {!isMobile && (
              <Select
                value={pageLimit}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  changeLimit(parseInt(e.target.value));
                }}
                borderRadius={0}
                w="xm"
              >
                {perPage.map(({ value, name }, index) => {
                  return (
                    <option key={index} value={value}>
                      {name}
                    </option>
                  );
                })}
              </Select>
            )}
            <HStack spacing={5}>
              <Button
                variant="outline"
                fontSize="14px"
                fontWeight="medium"
                borderRadius={0}
                disabled={pageOffset == 1}
                onClick={() => {
                  changeOffset(pageOffset - 1);
                }}
              >
                {'<'}
              </Button>
              {pagination.map((page, index) => {
                return (
                  <Button
                    key={index}
                    variant="outline"
                    fontSize="14px"
                    fontWeight="medium"
                    borderRadius={0}
                    onClick={() => {
                      changeOffset(page);
                    }}
                    bgColor={pageOffset === page ? 'rgba(245, 245, 245, 1)' : 'white'}
                  >
                    {page}
                  </Button>
                );
              })}
              <Button
                variant="outline"
                fontSize="14px"
                fontWeight="medium"
                borderRadius={0}
                disabled={props.totalPages === 0 || pageOffset == props.totalPages}
                onClick={() => {
                  changeOffset(pageOffset + 1);
                }}
              >
                {'>'}
              </Button>
            </HStack>
          </HStack>
        </Box>
      </Stack>
    </Box>
  );
};
