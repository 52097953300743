import Axios from 'axios';
import { API } from 'core/api';
import { CreateStakeHolderGroupType, StakeHolderGroupType } from 'core/interfaces/IStakeHolder';

class StakeHolderService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/stakeholder-groups`;

  public getAllStakeHolderGroups = (teamId: string) => Axios.get(`${this._api}?owner_id=${teamId}`);

  public createStakeHolderGroup = (
    createStakeHolderParams: CreateStakeHolderGroupType,
    teamId: string,
  ) =>
    Axios.post(`${this._api}`, {
      ...createStakeHolderParams,
      owner_id: teamId,
    });

  public deleteStakeHolderGroup = (stakeHolderGroupId: string) =>
    Axios.delete(`${this._api}/${stakeHolderGroupId}`);

  public updateStakeHolderGroup = (
    createStakeHolderParams: CreateStakeHolderGroupType,
    teamId: string,
    stakeHolderGroupId: string,
  ) =>
    Axios.put(`${this._api}/${stakeHolderGroupId}`, {
      ...createStakeHolderParams,
      owner_id: teamId,
    });
}

export default StakeHolderService;
