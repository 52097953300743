import {
  REQUEST_ORG_INTEGRATIONS,
  REQUEST_ORG_INTEGRATIONS_SUCCESS,
  REQUEST_ORG_INTEGRATIONS_FAIL,
} from '../../const/organization';
import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';

const initialOrganizationIntegrations = INITIAL_STATE.organization.integrations;

const organizationIntegrations = (state = initialOrganizationIntegrations, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ i: [], error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_INTEGRATIONS:
      return {
        ...state,
        ...{ error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_INTEGRATIONS_SUCCESS:
      const extensions = new Set([...state.i, ...action.payload.extensions]);
      return {
        ...state,
        ...{ i: Array.from(extensions), error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_INTEGRATIONS_FAIL:
      return {
        ...state,
        ...{ i: [], error: { type: REQUEST_ORG_INTEGRATIONS_FAIL, reason: action.payload } },
      };
    default:
      return state;
  }
};

export default organizationIntegrations;
