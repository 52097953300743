import * as React from 'react';
import { SVGProps } from 'react';

const SvgAzureActiveDirectoryLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Azure_ActiveDirectory_Logo_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.Azure_ActiveDirectory_Logo_svg__st0{fill:#50e6ff}'}</style>
    <path
      className="Azure_ActiveDirectory_Logo_svg__st0"
      d="M1.1 11.3 9.9 17l9-5.7 1.1 1.3-10.1 6.5L0 12.6l1.1-1.3z"
    />
    <path
      d="M1.8 10.6 9.9.9l8.3 9.7-8.3 5.2-8.1-5.2z"
      style={{
        fill: '#fff',
      }}
    />
    <path className="Azure_ActiveDirectory_Logo_svg__st0" d="M9.9.9v14.9l-8.1-5.3L9.9.9z" />
    <linearGradient
      id="Azure_ActiveDirectory_Logo_svg__SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={14.728}
      y1={7.536}
      x2={9.583}
      y2={17.281}
      gradientTransform="matrix(1 0 0 -1 0 22)"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#1988d9',
        }}
      />
      <stop
        offset={0.9}
        style={{
          stopColor: '#54aef0',
        }}
      />
    </linearGradient>
    <path
      d="M9.9.9v14.9l8.3-5.2L9.9.9z"
      style={{
        fill: 'url(#Azure_ActiveDirectory_Logo_svg__SVGID_1_)',
      }}
    />
    <path
      d="m9.9 8.6 8.3 2-8.3 5.2V8.6z"
      style={{
        fill: '#53b1e0',
      }}
    />
    <path
      d="m9.9 15.8-8.1-5.3 8.1-2v7.3z"
      style={{
        fill: '#9cebff',
      }}
    />
    <linearGradient
      id="Azure_ActiveDirectory_Logo_svg__SVGID_00000126320639678094195060000011326081383826173111_"
      gradientUnits="userSpaceOnUse"
      x1={12.513}
      y1={10.368}
      x2={16.068}
      y2={4.234}
      gradientTransform="matrix(1 0 0 -1 0 22)"
    >
      <stop
        offset={0.1}
        style={{
          stopColor: '#54aef0',
        }}
      />
      <stop
        offset={0.29}
        style={{
          stopColor: '#4fabee',
        }}
      />
      <stop
        offset={0.51}
        style={{
          stopColor: '#41a2e9',
        }}
      />
      <stop
        offset={0.74}
        style={{
          stopColor: '#2a93e0',
        }}
      />
      <stop
        offset={0.88}
        style={{
          stopColor: '#1988d9',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#Azure_ActiveDirectory_Logo_svg__SVGID_00000126320639678094195060000011326081383826173111_)',
      }}
      d="M9.9 19.1 20 12.6l-1.1-1.3-9 5.7v2.1z"
    />
  </svg>
);

export default SvgAzureActiveDirectoryLogo;
