import { TAG_MAX_LENGTH } from 'core/const/immutables';
import { truncate } from 'core/helpers/stringUtils';
import DOMPurify from 'core/dompurify';

import { FC } from 'react';

type Props = {
  value: string;
  maxLength?: number;
};

export const TagValue: FC<Props> = ({ value, maxLength }) => {
  const isUrl = (word: string) => {
    const urlPattern =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm; /* eslint-disable-line */
    return word.match(urlPattern);
  };

  const getTruncatedText = (text: string) => truncate(text, maxLength || TAG_MAX_LENGTH);

  const addMarkup = (word: string) => {
    return isUrl(word)
      ? `<a target="_blank" href="${word}" rel="noopener noreferrer">${getTruncatedText(word)}</a>`
      : getTruncatedText(word);
  };

  const valueLabel = addMarkup(value);
  const html = DOMPurify.sanitize(valueLabel);
  return <span dangerouslySetInnerHTML={{ __html: html }} />;
};
