import { IStakeholders } from 'core/interfaces/IStakeholders';
import {
  REQUEST_ORG_STAKEHOLDERS,
  REQUEST_ORG_STAKEHOLDERS_SUCCESS,
  REQUEST_ORG_STAKEHOLDERS_FAIL,
} from '../../const/organization';
import { IUserInfo } from '../../interfaces/IUserData';

const requestOrganizationStakeholders = () => ({
  type: REQUEST_ORG_STAKEHOLDERS,
  payload: {},
});

const onRequestOrganizationStakeholdersSuccess = (users: IStakeholders[]) => ({
  type: REQUEST_ORG_STAKEHOLDERS_SUCCESS,
  payload: {
    users,
  },
});

const onRequestOrganizationStakeholdersFail = (error: any) => ({
  type: REQUEST_ORG_STAKEHOLDERS_FAIL,
  payload: {
    error,
  },
});

export {
  requestOrganizationStakeholders,
  onRequestOrganizationStakeholdersSuccess,
  onRequestOrganizationStakeholdersFail,
};
