import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.208 1.17V9.45H4.871v7.796l4.578 1.653 2.754-9.449L9.45 0l-3.24 1.17z"
      fill="#9d5025"
    />
    <path d="M9.449 0v18.898l3.24-1.171V9.449h1.338V1.653L9.449 0z" fill="#f58536" />
    <path d="m18.898 7.034-5.792.395-1.903-.172 6.036-4.443 1.659 4.22z" fill="#6b3a19" />
    <path d="m17.24 2.814 1.658.6v3.62l-1.659-.239v-3.98z" fill="#f58536" />
    <path d="m11.203 7.257 6.036-.462v-3.98l-6.036 1.18v3.262z" fill="#9d5025" />
    <path d="m0 11.864 5.792-.395 1.903.172-6.036 4.442L0 11.863z" fill="#fbbf93" />
    <path d="M1.659 16.083 0 15.484v-3.62l1.659.252v3.967z" fill="#9d5025" />
    <path d="m7.695 11.641-6.037.475v3.967l6.037-1.18v-3.262z" fill="#f58536" />
  </svg>
);

export default SvgComponent;
