import React, { HTMLAttributes, ReactChild, useRef, useEffect } from 'react';
import styled from 'styled-components';

interface IFocusButton extends HTMLAttributes<HTMLButtonElement> {
  isSelected?: boolean;
  children: ReactChild;
  height?: string | '100%';
  width?: string | '100%';
  focus?: boolean;
  padding?: string | '8px 16px';
  /**
   * @override internal use
   */
  index?: number;
  disabled?: boolean;
  scrollTo?: boolean;
  focusColor?: string | 'theme.shades.smoke';
  onSelectValue?: (event: React.MouseEvent<HTMLButtonElement>, index: number, value: any) => void;
  preventCloseOnSelect?: boolean;
}

const calcSelected = (props: any) => {
  const isFocus = props.isSelected || props.focus;
  return `
    opacity: ${isFocus ? 1 : 0.7};
    background: ${
      isFocus ? props.focusColor || props.theme.primary.default : props.theme.shades.white
    }${props.focus ? '2e' : '0e'};
  `;
};

const FocusButton = styled.button<IFocusButton>`
  ${calcSelected};
  font-family: ${props => props.theme.fontFamily};
  height: ${props => props.height || 'fit-content'};
  width: ${props => props.width || '100%'};
  padding: ${props => props.padding || '8px 16px'};
  font-size: 16px;
  border: none;
  transition: 0.17s opacity, 0.17s box-shadow, 0.17s filter, 0.17s background ease-in-out;
  display: flex;
  outline: none;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;

  &:disabled {
    opacity: 0.33;
    cursor: no-drop !important;
  }

  &:hover:not(:disabled) {
    opacity: 1;
    background: ${props => props.focusColor || `${props.theme.primary.default}0e`};
  }

  &:active:not(:disabled) {
    opacity: 1;
    background: ${props => props.focusColor || `${props.theme.primary.default}1e`};
  }

  &:focus {
    background: ${props => props.focusColor || `${props.theme.primary.default}1e`};
    opacity: 1;
  }

  &:focus-visible {
    outline: ${props => props.theme.focus.default};
    outline-offset: 4px;
  }
`;

export interface IFocusBlock extends IFocusButton {
  value: any;
}

const FocusBlock = (props: IFocusBlock) => {
  const focusRef = useRef<HTMLButtonElement>(null);

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onSelectValue) {
      if (!props.preventCloseOnSelect) focusRef.current?.blur();
      props.onSelectValue(event, props.index || 0, props.value);
    }
  };

  useEffect(() => {
    if (focusRef.current && props.scrollTo) {
      (focusRef.current as unknown as HTMLDivElement).scrollIntoView({
        block: 'nearest',
        behavior: 'auto',
        inline: 'nearest',
      });
    }
  });

  const { value, onSelectValue, ...focusProps } = props;

  return (
    <FocusButton {...focusProps} onClick={onClick} ref={focusRef}>
      {props.children}
    </FocusButton>
  );
};

/** @component */
export default FocusBlock;
