import { requestUserLogout } from '../actions/auth/index';
import { Dispatch } from 'redux';
import { BroadcastChannel } from 'broadcast-channel';

const broadcastChannel: BroadcastChannel = new BroadcastChannel('auth_channel');

const EVENT_LOGGED_OUT = 'USER_LOGGED_OUT';

const initAuthBroadcastListener = (dispatch: Dispatch<any>) => {
  broadcastChannel.addEventListener('message', e => {
    switch (e.event) {
      case EVENT_LOGGED_OUT:
        dispatch(requestUserLogout());
    }
  });
};

export { initAuthBroadcastListener, EVENT_LOGGED_OUT };
export default broadcastChannel;
