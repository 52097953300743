import {
  REQUEST_ORG_RUNBOOK,
  REQUEST_ORG_RUNBOOK_SUCCESS,
  REQUEST_ORG_RUNBOOK_FAIL,
} from '../../const/organization';
import { IFetchRunbook, IRunbook } from '../../interfaces/IRunBook';

const requestOrganizationRunbook = (runbookParams?: IFetchRunbook) => ({
  type: REQUEST_ORG_RUNBOOK,
  payload: runbookParams
    ? {
        entity_owner: runbookParams.entity_owner,
      }
    : {},
});

const onRequestOrganizationRunbookSuccess = (runbook: IRunbook[]) => ({
  type: REQUEST_ORG_RUNBOOK_SUCCESS,
  payload: {
    runbook,
  },
});

const onRequestOrganizationRunbookFail = (error: any) => ({
  type: REQUEST_ORG_RUNBOOK_FAIL,
  payload: {
    error,
  },
});

export {
  requestOrganizationRunbook,
  onRequestOrganizationRunbookSuccess,
  onRequestOrganizationRunbookFail,
};
