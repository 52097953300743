import { AxiosResponse } from 'axios';
import {
  T_WA_SQUAD_CREATED,
  T_WA_SQUAD_MEMBER_UPDATED_TO_MEMBER,
  T_WA_SQUAD_MEMBER_UPDATED_TO_OWNER,
  T_WA_USER_ADDED_TO_SQUAD,
  T_WA_USER_REMOVED_FROM_SQUAD,
} from 'core/const/tracker';
import { useToast } from 'library/atoms';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { AppTracker } from 'shared/analytics/tracker';
import {
  addMemberToSquad,
  createSquad,
  CreateSquadPayload,
  getAllSquad,
  removeMemberFromSquad,
  removeSquad,
  updateMemberRole,
  updateSquadName,
} from '../api';
import { Member, SquadMemberRole } from '../types';
import { useSearchSquad } from './useSearchSquad';

export const useSquadListing = () => {
  const params = useParams<{ id: string }>();
  const [searchTerm] = useSearchSquad();

  return useQuery({
    queryFn: () => getAllSquad(params.id, searchTerm),
    queryKey: ['squadListing', params.id, searchTerm],
    refetchOnMount: true,
  });
};

type MutateCB = {
  onSuccess?: (data: AxiosResponse) => void;
  onError?: (error: any) => void;
};
/**
 * Custom hook to create a squad with provided payload and callbacks for success or error handling.
 * @param {MutateCB} cb - Callback object with onSuccess and onError functions.
 * @returns Mutation object to create a squad.
 */
export const useCreateSquad = (cb: MutateCB) => {
  const toast = useToast();

  const params = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: CreateSquadPayload) => createSquad(params.id, payload),
    onSettled: () => {
      queryClient.invalidateQueries('squadListing');
    },

    onError: (error: any) => {
      toast({
        status: 'error',
        text: error?.response?.data?.meta?.error_message ?? 'Something went wrong',
      });
      cb.onError?.(error);
    },

    onSuccess: response => {
      toast({
        status: 'success',
        text: 'Squad created successfully',
      });
      AppTracker.track(T_WA_SQUAD_CREATED, {
        'Squad ID': response.data.data?.id,
      });
      cb.onSuccess?.(response);
    },
  });
};

/**
 * Custom hook to update a squad's name with provided payload and callbacks for success or error handling.
 * @param {MutateCB} cb - Callback object with onSuccess and onError functions.
 * @returns Mutation object to update a squad's name.
 */
export const useUpdateSquadName = (cb: MutateCB) => {
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: { squadId: string; name: string }) =>
      updateSquadName(payload.squadId, { name: payload.name }),
    onSettled: () => {
      queryClient.invalidateQueries('squadListing');
    },

    onError: (error: any) => {
      toast({
        status: 'error',
        text: error?.response?.data?.meta?.error_message ?? 'Something went wrong',
      });
      cb.onError?.(error);
    },

    onSuccess: response => {
      toast({
        status: 'success',
        text: 'Squad name updated successfully',
      });
      cb.onSuccess?.(response);
    },
  });
};

/**
 * Custom hook to add members to a squad with provided payload and callbacks for success or error handling.
 * @param {MutateCB} cb - Callback object with onSuccess and onError functions.
 * @returns Mutation object to add members to a squad.
 */
export const useAddMembersToSquad = (cb: MutateCB) => {
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: { squadId: string; members: Member[] }) =>
      addMemberToSquad(payload.squadId, payload.members),
    onSettled: () => {
      queryClient.invalidateQueries('squadListing');
    },

    onError: (error: any) => {
      toast({
        status: 'error',
        text: error?.response?.data?.meta?.error_message ?? 'Something went wrong',
      });
      cb.onError?.(error);
    },

    onSuccess: response => {
      toast({
        status: 'success',
        text: 'Member added successfully',
      });
      AppTracker.track(T_WA_USER_ADDED_TO_SQUAD, {
        'Squad ID': response.data.data.id,
      });
      cb.onSuccess?.(response);
    },
  });
};

/**
 * Custom hook to remove a member from a squad with provided payload and callbacks for success or error handling.
 * @param {MutateCB} cb - Callback object with onSuccess and onError functions.
 * @returns Mutation object to remove a member from a squad.
 */
export const useRemoveMemberFromSquad = (cb: MutateCB) => {
  const toast = useToast();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: { squadId: string; memberId: string; replaceWith?: string }) =>
      removeMemberFromSquad(payload.squadId, payload.memberId, payload.replaceWith),
    onSettled: () => {
      queryClient.invalidateQueries('squadListing');
    },

    onError: (error: any) => {
      if (error?.response?.status !== 409) {
        toast({
          status: 'error',
          text: error?.response?.data?.meta?.error_message ?? 'Something went wrong',
        });
      }
      cb.onError?.(error);
    },

    onSuccess: response => {
      toast({
        status: 'success',
        text: 'Member removed successfully',
      });
      AppTracker.track(T_WA_USER_REMOVED_FROM_SQUAD, {
        squad_id: response.data.data?.id,
      });
      cb.onSuccess?.(response);
    },
  });
};
/**
 * Custom hook to update a squad member's role with provided payload and callbacks for success or error handling.
 * Utilizes a mutation function to perform the update operation and manages the UI feedback through toasts.
 * It also ensures the squad listing is refreshed upon any mutation to keep the data consistent.
 *
 * @param {MutateCB} cb - Callback object with onSuccess and onError functions to handle the mutation result.
 * @returns A mutation object that can be used to trigger the update member role operation.
 */
export const useUpdateMemberRole = (cb: MutateCB) => {
  const toast = useToast(); // Hook to show toast notifications

  const queryClient = useQueryClient(); // Hook to manage query cache
  return useMutation({
    mutationFn: (payload: {
      squadId: string;
      memberId: string;
      role: SquadMemberRole;
      replaceWith?: string;
    }) =>
      updateMemberRole(payload.squadId, payload.memberId, payload.role, payload.replaceWith).then(
        res => {
          if (res.status === 200) {
            if (payload.role === 'owner') {
              AppTracker.track(T_WA_SQUAD_MEMBER_UPDATED_TO_OWNER, {
                'Squad ID': payload.squadId,
              });
            } else if (payload.role === 'member') {
              AppTracker.track(T_WA_SQUAD_MEMBER_UPDATED_TO_MEMBER, {
                'Squad ID': payload.squadId,
              });
            }
          }
          return res;
        },
      ), // Function to call the API to update the member's role
    onSettled: () => {
      queryClient.invalidateQueries('squadListing'); // Refresh the squad listing on any mutation result
    },

    onError: (error: any) => {
      // Handle error scenario with a toast notification and call the onError callback if provided

      if (error?.response?.status !== 409) {
        toast({
          status: 'error',
          text: error?.response?.data?.meta?.error_message ?? 'Something went wrong',
        });
      }
      cb.onError?.(error);
    },

    onSuccess: response => {
      // Handle success scenario with a toast notification and call the onSuccess callback if provided
      toast({
        status: 'success',
        text: 'Member role updated successfully',
      });
      cb.onSuccess?.(response);
    },
  });
};

/**
 * Custom hook to remove a squad by its ID. It handles UI feedback through toasts and ensures the squad listing is refreshed upon successful removal.
 * This hook encapsulates the mutation operation for removing a squad and provides callbacks for success or error handling.
 *
 * @param {MutateCB} cb - An object containing optional onSuccess and onError callback functions to handle the mutation result.
 * @returns A mutation object that can be used to trigger the squad removal operation.
 */
export const useRemoveSquad = (cb: MutateCB) => {
  const toast = useToast(); // Hook to show toast notifications
  const queryClient = useQueryClient(); // Hook to manage query cache

  return useMutation({
    mutationFn: (squadId: string) => removeSquad(squadId), // Function to call the API to remove the squad
    onSettled: () => {
      queryClient.invalidateQueries('squadListing'); // Refresh the squad listing on any mutation result
    },
    onError: (error: any) => {
      // Handle error scenario with a toast notification and call the onError callback if provided
      if (error?.response?.status === 409) {
        cb.onError?.(error);
        return;
      }
      toast({
        status: 'error',
        text: error?.response?.data?.meta?.error_message ?? 'Something went wrong',
      });
      cb.onError?.(error);
    },
    onSuccess: response => {
      // Handle success scenario with a toast notification and call the onSuccess callback if provided
      toast({
        status: 'success',
        text: 'Squad removed successfully',
      });
      cb.onSuccess?.(response);
    },
  });
};
