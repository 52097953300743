import { Para, Theme } from 'uie/components';
import { NewTabIcon } from 'icons';
import { IGeneralMessages } from '../GeneralMessages';

const CircleCIRebuild = ({ log, userName }: Pick<IGeneralMessages, 'log' | 'userName'>) => {
  const { theme } = Theme;

  return (
    <>
      {' '}
      {log.additionalInfo ? (
        <Para fontSize={14} color={theme.shades.cement}>
          <span>
            {log.additionalInfo.payload.username} / {log.additionalInfo.payload.reponame}
          </span>
          <a
            className="installation-guide-design"
            target="_blank"
            rel="noopener noreferrer"
            href={log.additionalInfo.response.build_url}
          >
            {' '}
            (#{log.additionalInfo.payload.build_num}){' '}
          </a>
          <span>
            {'has been rebuilt by '}
            <span
              style={{
                color: '#3D6DD8',
                cursor: 'pointer',
              }}
            >
              {userName ? `${userName.first_name} ${userName.last_name}` : ''}
            </span>
            <a
              className="installation-guide-design"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: 5 }}
              href={log.additionalInfo.response.build_url}
            >
              <NewTabIcon />
            </a>
          </span>
        </Para>
      ) : null}
    </>
  );
};

export default CircleCIRebuild;
