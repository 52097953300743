import React from 'react';
import styled from 'styled-components';
import Grid from './Grid';
interface IProps {
  isLoading: boolean;
  color?: string | 'theme.primary.default';
}

const Shell = styled.div<IProps>`
  & > .header_load_progress {
    position: relative;
    height: 2px;
    display: block;
    width: 100%;
    background-color: transparent;
    border-radius: 3px;
    background-clip: padding-box;
    overflow: hidden;
  }

  & > .header_load_progress .header_load_progress__indeterminate {
    display: ${props => (props.isLoading ? 'block' : 'none')};
    background-color: transparent;
    height: 2px !important;
  }
  & > .header_load_progress .header_load_progress__indeterminate:before {
    content: '';
    position: absolute;
    background-color: ${props => props.color || props.theme.primary.default};
    height: 2px;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }
  & > .header_load_progress .header_load_progress__indeterminate:after {
    content: '';
    position: absolute;
    background-color: ${props => props.color || props.theme.primary.default};
    height: 2px;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s;
  }

  @keyframes indeterminate {
    0% {
      left: -35%;
      right: 100%;
    }
    60% {
      left: 100%;
      right: -90%;
    }
    100% {
      left: 100%;
      right: -90%;
    }
  }

  @keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
      left: 107%;
      right: -8%;
    }
    100% {
      left: 107%;
      right: -8%;
    }
  }
`;

const ContainerLoad = (props: IProps) => {
  return (
    <Shell {...props}>
      <Grid className="header_load_progress">
        <Grid className="header_load_progress__indeterminate" />
      </Grid>
    </Shell>
  );
};

/** @component */
export default ContainerLoad;
