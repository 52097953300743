import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { REQUEST_ORG_PLAN, REQUEST_ORG_PLAN_FAIL } from '../../const/organization';
import { onRequestOrganizationPlanSuccess } from '../../actions/organization/plan';
import { IBillingPlan } from '../../interfaces/IBillingPlan';
import { getOrgPlan } from '../../api/organization/getPlan';

const getOrganizationsBillingEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ORG_PLAN),
    map(action => action.payload),
    switchMap(() => {
      return getOrgPlan().pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ data }: { data: IBillingPlan }) => {
          return onRequestOrganizationPlanSuccess(data);
        }),
        catchError(error =>
          of({
            type: REQUEST_ORG_PLAN_FAIL,
            payload: error.xhr,
            error: true,
          }),
        ),
      );
    }),
  );

export default getOrganizationsBillingEpic;
