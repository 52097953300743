import * as React from 'react';
import { SVGProps } from 'react';

const SvgHerokuLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Heroku_logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.Heroku_logo-20x20_svg__st1{fill:#fff}'}</style>
    <path
      d="M17.2 0H2.8C1.8 0 1 .8 1 1.8v16.4c0 1 .8 1.8 1.8 1.8h14.4c1 0 1.8-.8 1.8-1.8V1.8c0-1-.8-1.8-1.8-1.8z"
      style={{
        fill: '#6762a6',
      }}
    />
    <path
      className="Heroku_logo-20x20_svg__st1"
      d="m5.5 17 2.3-2-2.3-2zM13.6 8.9c-.4-.4-1.1-.9-2.4-.9-1.3 0-2.7.4-3.7.7V3h-2v8.6l1.4-.6s2.3-1.1 4.3-1.1c1 0 1.2.6 1.2 1.1v6h2v-6c.1-.1.1-1.2-.8-2.1zM11 6.2h2c.9-1.1 1.3-2.1 1.5-3.2h-2c-.2 1.2-.7 2.2-1.5 3.2z"
    />
  </svg>
);

export default SvgHerokuLogo20X20;
