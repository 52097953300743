import { IService } from 'core/interfaces/IService';
import React, { useMemo } from 'react';
import { MultiValue, OnChangeValue, SingleValue } from 'react-select';
import { IAppState } from '../../../core/interfaces/IAppState';
import { FilterOptions } from '../const';
import { useSecondaryFilters } from '../context';
import { SelectOption } from '../types';
import DropdownContainer from './dropdown_container';
import { Select } from './select';
import { useSelector } from 'react-redux';

interface Props {
  selected: string[];
  isInitializedOpen?: boolean;
  isMulti?: boolean;
}

function Services(props: Props): JSX.Element {
  const organization = useSelector((state: IAppState) => state.organization);
  const services = organization.services.s as IService[];
  const { updateFilter } = useSecondaryFilters();
  const { isInitializedOpen } = props;

  const serviceOptions = useMemo(
    () =>
      services.map((t: IService) => {
        return {
          label: t.name,
          value: t.id,
        };
      }),
    [services],
  );

  const [selectedOptions, selectedNames] = useMemo(() => {
    const sel = services.filter(t => {
      return props.selected.some(f => {
        return f === t.id;
      });
    });

    return [
      sel.map(t => {
        return {
          label: t.name,
          value: t.id,
        };
      }),
      sel.map(t => t.name),
    ];
  }, [services, props.selected]);

  const handleChange = (newValue: OnChangeValue<SelectOption, boolean>) => {
    const arr: string[] = [];

    if (Array.isArray(newValue)) {
      const options = newValue as MultiValue<SelectOption>;
      options.map(o => {
        arr.push(o.value);
      });
    } else if (newValue) {
      const option = newValue as SingleValue<SelectOption>;
      if (option?.value) {
        arr.push(option.value);
      }
    }

    updateFilter('services', arr);
  };

  return (
    <DropdownContainer
      selected={selectedNames}
      filterKey="services"
      label={FilterOptions['services'].slice(0, props.isMulti ? undefined : -1)}
      isInitializedOpen={isInitializedOpen}
    >
      <Select
        options={serviceOptions}
        value={selectedOptions}
        onChange={handleChange}
        isLoading={!serviceOptions.length}
        isMulti={props.isMulti || undefined}
      />
    </DropdownContainer>
  );
}

export default Services;
