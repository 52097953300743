import axios from 'axios';
import { EntityACLMeta } from 'core/userAccess/types';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';
import { API } from '../api';
import { IRunbook, IRunbookCreate } from '../interfaces/IRunBook';

class RunbookService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/runbooks`;
  private _apiOld = `${API.config.endpoint}/organizations/${API.config.organizationId}/services`;

  constructor() {}

  public getAll = () =>
    axios.get<{ data: IRunbook[] }>(`${this._api}?owner_id=${API.config.teamId}`);

  public create = (runbookRequest: IRunbookCreate) =>
    axios.post(this._api, {
      ...runbookRequest,
    });

  public update = (runbookRequest: IRunbookCreate, _runbookId: string) =>
    axios.put(`${this._api}/${_runbookId}`, runbookRequest);

  public delete = (_runbookId: string) => axios.delete(`${this._api}/${_runbookId}`);

  public getRunbookDetail = async (runbookId: string) => {
    const response = await axios.get<{
      data: IRunbook;
      meta: { acl: Record<string, EntityACLMeta> };
    }>(`${this._api}/${runbookId}`);

    setUserAccessStore({
      entityACLMap: {
        ...getUserAccessStore().entityACLMap,
        runbooks: {
          ...getUserAccessStore().entityACLMap?.runbooks,
          ...response.data.meta.acl,
        },
      },
    });
    return response;
  };
}

export default RunbookService;
