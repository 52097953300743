import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const LinearProgressTheme: ComponentStyleConfig = {
  baseStyle: {
    width: '100%',
    filledTrack: {},
    track: {
      borderRadius: '9px',
    },
  },
  sizes: {
    xs: {
      track: {
        height: '3px',
      },
    },
    sm: {
      track: {
        height: '6px',
      },
    },
    md: {
      track: {
        height: '9px',
      },
    },
    lg: {
      track: {
        height: '12px',
      },
    },
  },
  variants: {
    green: {
      track: {
        bg: '#EDF2F7',
      },
      filledTrack: {
        bg: 'brand.green',
      },
    },
    orange: {
      filledTrack: {
        bg: '#E05000',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'green',
  },
};
