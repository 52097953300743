import { FilterTypeOption, FilterTypes, OperatorTypes, ServiceFilter } from '../types';

export const truncateString = (string = '', maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

export const safeEncodeUriComponent = (url: string) => {
  try {
    return encodeURIComponent(url);
  } catch (err) {
    return url;
  }
};

export const getServiceEntityId = (service?: ServiceFilter) => {
  if (!service) {
    return;
  }
  const { entity, operator } = service.filters;

  if ([OperatorTypes.IS, OperatorTypes.IS_NOT].includes(operator.value)) {
    return entity?.id;
  } else {
    return;
  }
};

export const isServiceFilterPresent = (filterType: FilterTypeOption[]) => {
  return filterType?.some((filter: FilterTypeOption) => filter.value === FilterTypes.SERVICE);
};
