import React from 'react';
import { InternalStatusPage } from './index';
import { Col, Row, SpinLoader, CheckBox } from '@squadcast/alchemy-ui/carbon';
import ThemeModal from './theme.modal';
import ReactMarkdownWithHtml from 'react-markdown/with-html';
import ServiceStatusModal from './service.modal';
import NewStatusPageModal from '../modify.modal';
import SubscriptionsModal from './subscriptions.modal';
import HeaderModal from './header.modal';
import { Redirect } from 'react-router';
import UnsavedChangesGuard from '../../../../../components/unsavedChangesGuard';
import { ContainerLoad, DialogModalFrame, IconButton, Para, Theme } from 'uie/components';
import moment from 'moment';
import { IServiceStatus } from '../../../../../core/interfaces/IStatusPage';
import { AppConfig } from 'shared/app.config';
import EditMessage from './changneMessage.modal';
import { DeleteIcon, EditIcon } from 'icons';
import ConfirmModal from 'components/confirm.modal';
import { getUserAccessStore } from 'core/userAccess/UserAccessStore';

function render(this: InternalStatusPage) {
  const _uA = this.props.userAccess;

  const hasRead = _uA.hasReadAccess('status_pages');
  const hasUpdate = _uA.hasUpdateAccess('status_pages');
  const hasDelete = _uA.hasDeleteAccess('status_pages');

  if (!hasRead) {
    return <Redirect to="/" push={true} />;
  }

  const {
    componentState,
    statusPage,
    showEditConfig,
    editModal,
    serviceStatus,
    incidentHistory,
    statusHeader,
  } = this.state;

  if (componentState !== 'finished' || statusPage === null) {
    return (
      <div style={{ height: '100%' }}>
        <Col height="100%">
          <Row justifyContent="center" alignItems="center">
            <Col width={500}>
              <div style={{ marginTop: 80 }}>
                <Row alignItems="center" justifyContent="center">
                  <SpinLoader size="normal" />
                  <p className="ml-10">Loading</p>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </div>
    );
  }

  const colorMap = statusPage.pageConfig.status.reduce((c: any, n: any) => {
    c[n.label] = n.color;
    return c;
  }, {});

  return (
    <div
      className="main-container shade"
      style={{ padding: 0 }}
      onClick={() => this.setState({ showEditConfig: false })}
    >
      <div className="page-container">
        <div
          className="header-wrapper"
          style={{
            backgroundColor: statusPage!.themeColor as any,
          }}
        >
          <Row justifyContent="flex-end">
            {hasUpdate && (
              <div className="float-right" style={{ position: 'relative' }}>
                <div
                  className="cursor-pointer edit-dropdown"
                  onClick={event => {
                    this.setState({ showEditConfig: true });
                    event.stopPropagation();
                  }}
                >
                  Edit
                </div>
                {showEditConfig && (
                  <div
                    className="config-modal animated fadeIn"
                    style={{
                      width: 190,
                      marginRight: 40,
                      marginTop: 10,
                      borderRadius: 5,
                    }}
                  >
                    {Object.entries({
                      header: 'Header',
                      service: 'Service Status',
                      page: 'Page Configuration',
                      subscriptions: 'Subscriptions',
                      theme: 'Theme',
                    }).map(([key, value], index) => {
                      return (
                        <div
                          className=" no-select cursor-pointer"
                          onClick={() => {
                            this.onModalOpen(key as any);
                          }}
                          key={index}
                        >
                          <div className="float-left">
                            <p className="item-box-tagline" style={{ margin: 0 }}>
                              {value}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                    <div
                      className=" no-select cursor-pointer"
                      key={7}
                      style={{ padding: '5px 15px' }}
                      onClick={e => e.stopPropagation()}
                    >
                      <div className="float-left">
                        <div style={{ margin: 0 }}>
                          <CheckBox
                            id="make_public"
                            checked={statusPage!.isPublic}
                            label="Make Public"
                            name="make_public"
                            onChange={() =>
                              this.setState(
                                ({ statusPage }) => {
                                  statusPage!.isPublic = !statusPage!.isPublic;
                                  return { statusPage };
                                },
                                () => {
                                  this.updateStatusPage();
                                },
                              )
                            }
                          >
                            <span style={{ color: 'black' }}>Make Public</span>
                          </CheckBox>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Row>
        </div>

        <div className="content-wrapper">
          <div className="header p-h-20">
            <div className="row justify-space-between h-1-1">
              <div className="row w-2-4">
                <div
                  className="header-logo"
                  style={{
                    backgroundImage: `url(${
                      statusPage.pageConfig.logoUrl || '/assets/statusPageDefaultLogo.png'
                    })`,
                  }}
                />
                {statusPage.pageConfig.logoUrl && <hr className="logo-divider" />}
                <h1 className="header-font">{statusPage.name}</h1>
              </div>
              <div className="row justify-flex-end w-2-4">
                <h1
                  className={`header-font header-status ${statusHeader.status}`}
                  style={{ color: colorMap[statusHeader.status] }}
                >
                  {statusHeader.status_text}
                </h1>
              </div>
            </div>
          </div>

          {this.state.isStatusError && (
            <div className="mt-20 pa-20">
              <Row justifyContent="center" alignItems="center">
                <p>Please map your domain name</p>
                <span className="item-member ml-10">{this.state.statusPage!.url}</span>
                <p>to CNAME</p>
                <span className="item-member ml-10">{AppConfig.status_page_url}</span>
              </Row>
            </div>
          )}

          {!this.state.isStatusError && this.state.serviceStatus.length === 0 && (
            <div className="mt-20 pa-20">
              <Row justifyContent="center" alignItems="center">
                <SpinLoader size="normal" /> <span className="ml-10">loading</span>
              </Row>
            </div>
          )}

          {!this.state.isStatusError && this.state.serviceStatus.length > 0 && (
            <div className="service-status">
              {serviceStatus.map((ss, index) => {
                return (
                  <div key={index}>
                    <div className="row align-stretch">
                      <div className="status">
                        <div
                          className={`cell ${ss.status}`}
                          style={{
                            background: `${colorMap[ss.status]}1e`,
                            color: colorMap[ss.status],
                          }}
                        >
                          <p>{ss.status_text}</p>
                        </div>
                      </div>
                      <div className="service">
                        <div className="col">
                          <p className="name">{ss.name}</p>
                        </div>
                      </div>
                    </div>
                    {ss.depends !== null &&
                      ss.depends
                        .sort((a: IServiceStatus, b: IServiceStatus) => {
                          const svc1: string = a.name;
                          const svc2: string = b.name;
                          return svc1.localeCompare(svc2);
                        })
                        .map((ssd: any, i: number) => (
                          <div className="row align-stretch" key={i}>
                            <div className="status">
                              <div
                                className={`cell ${ssd.Status}`}
                                style={{
                                  background: `${colorMap[ssd.status]}1e`,
                                  color: colorMap[ssd.status],
                                }}
                              >
                                <p>{ssd.status_text}</p>
                              </div>
                            </div>
                            <div className="service is-a-dependency">
                              <div className="row align-stretch">
                                <div className="col borders w-40 align-stretch">
                                  <div className="upper h-1-2" />
                                  {ss.depends.length - 1 !== i && (
                                    <div className="has-followups h-1-2" />
                                  )}
                                </div>
                                <div
                                  className="align-stretch"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: '5px',
                                  }}
                                >
                                  <p className="name">{ssd.name}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                );
              })}
            </div>
          )}

          {!this.state.isStatusError && this.state.serviceStatus.length > 0 && (
            <div className="public-messages p-t-20 mb-20">
              <h1 className="header-font p-h-20">Incident History</h1>
              <ContainerLoad isLoading={this.state.loading} />
              <div className="history m-t-10">
                {incidentHistory.map((ih, index) => {
                  return (
                    <div key={index}>
                      <p className="name p-a-20">{ih.date}</p>
                      {ih.messages !== null ? (
                        ih.messages.map((publicMessage: any, idx: number) => {
                          return publicMessage.messages.map((message: any, index: number) => (
                            <div
                              key={message.time}
                              style={{ display: 'flex' }}
                              className="icon-btton-wrapper"
                            >
                              <div
                                className="row align-stretch p-h-20"
                                style={{
                                  display: 'flex',
                                  flex: '1',
                                }}
                              >
                                <div className="pin w-40 col align-center">
                                  <div className="circle " style={{ minWidth: '15px' }} />
                                  <div
                                    className={`followup ${
                                      publicMessage.messages.length - 1 === index ? 'none' : ''
                                    }`}
                                  />
                                </div>
                                <div
                                  className="comment col"
                                  style={{
                                    display: 'block',
                                    marginBottom: '15px',
                                    paddingBottom: '15px',
                                  }}
                                >
                                  {message.deleted ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        gap: '5px',
                                        background: Theme.theme.shades.smoke,
                                      }}
                                    >
                                      <div style={{ paddingLeft: '5px' }}>
                                        <DeleteIcon height={14} width={14} />
                                      </div>
                                      <span>
                                        <small style={{ fontSize: '15px', fontStyle: 'italic' }}>
                                          This update was deleted{' '}
                                        </small>
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="comment " style={{ display: 'block  ' }}>
                                      <p className="comment-status">{message.status}</p>
                                      <ReactMarkdownWithHtml
                                        allowDangerousHtml
                                        className="markdown-renderer-status-page"
                                      >
                                        {`${message.message} ${
                                          message.is_updated
                                            ? "<span class = 'edited-message-span'>(edited)</span>"
                                            : ''
                                        }`}
                                      </ReactMarkdownWithHtml>
                                    </div>
                                  )}
                                  <small className="time mt-5" style={{ fontSize: '13px' }}>
                                    {moment(
                                      message.deleted ? message.deleted_at : message.time,
                                    ).format('Do MMM YYYY, HH:mm ') + '(UTC)'}
                                  </small>
                                </div>
                              </div>

                              {!message.deleted && (
                                <div
                                  style={{
                                    width: 'fit-content',
                                    display: 'flex',
                                    gap: '6px',
                                    marginRight: '20px',
                                  }}
                                >
                                  <IconButton
                                    buttonType="inverted"
                                    color={Theme.theme.shades.whiteSmoke}
                                    onClick={() => {
                                      this.messageEditModal({
                                        ...message,
                                        statusPageId: this.state.statusPage?.id,
                                        incidentId: publicMessage.incident_id,
                                      });
                                    }}
                                  >
                                    <EditIcon height={16} width={16} />
                                  </IconButton>
                                  <div></div>
                                  <IconButton
                                    buttonType="inverted"
                                    color={Theme.theme.danger.light}
                                    onClick={() => {
                                      this.setState({
                                        deleteMesage: {
                                          messageId: message._id,
                                          incidentId: publicMessage.incident_id,
                                        },
                                      });
                                    }}
                                  >
                                    <DeleteIcon height={16} width={16} />
                                  </IconButton>
                                </div>
                              )}
                            </div>
                          ));
                        })
                      ) : (
                        <div className="comment p-h-20 m-t-5 m-b-20">
                          <p className="status_renders">No incidents reported.</p>
                        </div>
                      )}
                      <hr className="comment-separator" />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      <DialogModalFrame
        id="status_page_theme_modal"
        width="800px"
        onClose={this.checkDirtyAndCloseModal}
      >
        {editModal === 'theme' && (
          <ThemeModal
            checkAndSetDirty={this.checkAndSetDirty}
            theme={statusPage.themeColor as any}
            hide={this.hideThemeModal}
            onThemeChange={(theme: string) => {
              this.setState(({ statusPage }) => {
                statusPage!.themeColor = theme;
                return { statusPage };
              });
            }}
          />
        )}
      </DialogModalFrame>

      <DialogModalFrame
        id="status_page_service_status_modal"
        width="800px"
        onClose={this.checkDirtyAndCloseModal}
      >
        {editModal === 'service' && (
          <ServiceStatusModal
            checkAndSetDirty={this.checkAndSetDirty}
            pageConfig={{ pageConfig: this.state.statusPage!.pageConfig as any }}
            hide={this.hideEditModal}
          />
        )}
      </DialogModalFrame>
      <DialogModalFrame
        id="status_page_service_status_modal"
        width="800px"
        onClose={this.checkDirtyAndCloseModal}
      >
        {editModal === 'change-message' && (
          <EditMessage
            messageObject={this.state.messageToEdit}
            checkAndSetDirty={this.checkAndSetDirty}
            fileUploadService={this.fileUploadService}
            onCancel={this.checkDirtyAndCloseModal}
            onSave={this.editMessageSubmit}
          />
        )}
      </DialogModalFrame>

      <DialogModalFrame
        id="new_status_page_modal_internal"
        width="800px"
        onClose={this.checkDirtyAndCloseModal}
      >
        {editModal === 'page' && (
          <NewStatusPageModal
            checkAndSetDirty={this.checkAndSetDirty}
            pageId={this.state.statusPage!.id || ''}
            hide={this.hideNewStatusPageModal}
          />
        )}
      </DialogModalFrame>

      <DialogModalFrame
        id="status_page_header_modal"
        width="800px"
        onClose={this.checkDirtyAndCloseModal}
      >
        {editModal === 'header' && (
          <HeaderModal
            checkAndSetDirty={this.checkAndSetDirty}
            pageConfig={{ pageConfig: this.state.statusPage!.pageConfig }}
            hide={this.hideEditModal}
          />
        )}
      </DialogModalFrame>

      <DialogModalFrame
        id="status_page_subscription_modal"
        width="800px"
        onClose={this.checkDirtyAndCloseModal}
      >
        {editModal === 'subscriptions' && (
          <SubscriptionsModal
            checkAndSetDirty={this.checkAndSetDirty}
            pageConfig={{ pageConfig: this.state.statusPage!.pageConfig as any }}
            statusPageUrl={statusPage.url as any}
            hide={this.hideEditModal}
          />
        )}
      </DialogModalFrame>

      <UnsavedChangesGuard
        isManual={true}
        showModal={this.state.showUnsavedChangesModal}
        onDiscardChanges={this.onDiscardChanges}
        onModalClose={this.closeUnsavedChangesModal}
      />
      <DialogModalFrame
        id="ep_delete_confirm"
        width="400px"
        onClose={() => this.deleteMessage(false)}
      >
        {this.state.deleteMesage.messageId && (
          <ConfirmModal
            hide={this.deleteMessage}
            buttonText={'Delete'}
            displayText={'Are you sure you want to delete this message?'}
          />
        )}
      </DialogModalFrame>
    </div>
  );
}

export default render;
