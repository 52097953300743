import { FC, useMemo } from 'react';
import { JiraTicketCreateAction } from '../../../types';
import { Box, HStack, Text } from '@chakra-ui/react';
import { useGetAllJiraAccounts } from 'views/main/organization/settings/integrations/jira-cloud/hooks/getAllJiraAccounts';
import { useGetJiraProjectsForAccount } from 'views/main/organization/settings/integrations/jira-cloud/hooks/getAllProjectOfAccount';
interface IProps {
  action: JiraTicketCreateAction;
}
const JiraTicketCard: FC<IProps> = ({ action }) => {
  const { data: jiraAccounts = [] } = useGetAllJiraAccounts();
  const { data: jiraProjects = [] } = useGetJiraProjectsForAccount(action.data.account || '');

  const selectedJiraAcc = useMemo(
    () => jiraAccounts.find(acc => acc.id.toString() === action.data.account)?.account_name,
    [jiraAccounts, action.data.account],
  );

  const selectedJiraProj = useMemo(
    () => jiraProjects.find(acc => acc.key === action.data.project)?.name,
    [jiraProjects, action.data.project],
  );

  return (
    <>
      <HStack justifyContent={'space-between'} width="30dvw" py={2}>
        <Box>
          <Text variant="h3" color="secondary.700" textStyle="bold_800">
            ACCOUNT
          </Text>
          <Text> {selectedJiraAcc}</Text>
        </Box>
        <Box>
          <Text variant="h3" color="secondary.700" textStyle="bold_800">
            PROJECT
          </Text>
          <Text> {selectedJiraProj}</Text>
        </Box>
        <Box>
          <Text variant="h3" color="secondary.700" textStyle="bold_800">
            ISSUE TYPE
          </Text>
          <Text> {action.data.issue_type}</Text>
        </Box>
      </HStack>
      <Box py={2}>
        <Text variant="h3" color="secondary.700" textStyle="bold_800">
          TITLE
        </Text>
        <Text> {action.data.title}</Text>
      </Box>
      <Box py={2}>
        <Text variant="h3" color="secondary.700" textStyle="bold_800">
          DESCRIPTION
        </Text>
        <Text> {action.data.description}</Text>
      </Box>
    </>
  );
};

export default JiraTicketCard;
