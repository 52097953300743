import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API } from 'core';
import { queryKeys, reactQueryConfig } from '../constant';
import { IJiraIssue } from '../../../../../../../core/interfaces/IJiraNew';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/service.reactqery';

const getIssuesForJiraProject = (accountId: string, projectId: string) => async () => {
  const { data } = await axios.get<IJiraIssue>(
    `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud/new/${accountId}/projects/${projectId}/issue-types`,
    //{acountID}/projects
  );
  return data.data;
};

export const useGetIssuesForJiraProject = (accountId: string, projectId: string) => {
  return useQuery(
    [queryKeys.GETISSUEFORJIRAPROJECT, accountId, projectId],
    getIssuesForJiraProject(accountId, projectId),
    {
      ...reactQueryConfig,
      enabled: !!accountId && !!projectId,
      retry: 1,
      onError: (error: any) => {
        const description =
          error?.response?.data?.meta?.error_message || 'Failed to Get Issue For Project';
        reactQueryConfigError({ description: description, title: 'Get Issue For Project' });
      },
    },
  );
};
