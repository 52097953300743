import {
  CreateStatusPageComponentMutation,
  CreateStatusPageComponentMutationVariables,
  CreateStatusPageGroupMutation,
  CreateStatusPageGroupMutationVariables,
  DeleteStatusPageComponentMutation,
  DeleteStatusPageComponentMutationVariables,
  DeleteStatusPageGroupMutation,
  DeleteStatusPageGroupMutationVariables,
  UpdateStatusPageComponentListOrderMutation,
  UpdateStatusPageComponentListOrderMutationVariables,
  UpdateStatusPageComponentMutation,
  UpdateStatusPageComponentMutationVariables,
  UpdateStatusPageGroupMutation,
  UpdateStatusPageGroupMutationVariables,
  useCreateStatusPageComponentMutation,
  useCreateStatusPageGroupMutation,
  useDeleteStatusPageComponentMutation,
  useDeleteStatusPageGroupMutation,
  useUpdateStatusPageComponentListOrderMutation,
  useUpdateStatusPageComponentMutation,
  useUpdateStatusPageGroupMutation,
} from '../graphql/mutation';
import { UseMutationOptions } from 'react-query';
import { reactQueryConfigError } from '../helpers/helper.toast';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_EDITED } from 'core/const/tracker';

const useCreateComponent = <TError = unknown, TContext = unknown>(
  onSuccess: () => void,
  options?: UseMutationOptions<
    CreateStatusPageComponentMutation,
    TError,
    CreateStatusPageComponentMutationVariables,
    TContext
  >,
) => {
  return useCreateStatusPageComponentMutation({
    ...options,
    onSuccess: data => {
      onSuccess();
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_EDITED, {
        'Edit Type': 'Page Component Created',
      });
    },
    onError: reactQueryConfigError('Create Component'),
  });
};

const useUpdateComponent = <TError = unknown, TContext = unknown>(
  onSuccess: () => void,
  options?: UseMutationOptions<
    UpdateStatusPageComponentMutation,
    TError,
    UpdateStatusPageComponentMutationVariables,
    TContext
  >,
) => {
  return useUpdateStatusPageComponentMutation({
    ...options,
    onSuccess: data => {
      onSuccess();
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_EDITED, {
        'Edit Type': 'Page Component Updated',
      });
    },
    onError: reactQueryConfigError('Update Component'),
  });
};

const useDeleteComponent = <TError = unknown, TContext = unknown>(
  onSuccess: () => void,
  options?: UseMutationOptions<
    DeleteStatusPageComponentMutation,
    TError,
    DeleteStatusPageComponentMutationVariables,
    TContext
  >,
) => {
  return useDeleteStatusPageComponentMutation({
    ...options,
    onSuccess: data => {
      onSuccess();
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_EDITED, {
        'Edit Type': 'Page Component Deleted',
      });
    },
    onError: reactQueryConfigError('Delete Component'),
  });
};

const useCreateGroup = <TError = unknown, TContext = unknown>(
  onSuccess: () => void,
  options?: UseMutationOptions<
    CreateStatusPageGroupMutation,
    TError,
    CreateStatusPageGroupMutationVariables,
    TContext
  >,
) => {
  return useCreateStatusPageGroupMutation({
    ...options,
    onSuccess: data => {
      onSuccess();
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_EDITED, {
        'Edit Type': 'Page Component Group Created',
      });
    },
    onError: reactQueryConfigError('Create Group'),
  });
};

const useUpdateGroup = <TError = unknown, TContext = unknown>(
  onSuccess: () => void,
  options?: UseMutationOptions<
    UpdateStatusPageGroupMutation,
    TError,
    UpdateStatusPageGroupMutationVariables,
    TContext
  >,
) => {
  return useUpdateStatusPageGroupMutation({
    ...options,
    onSuccess: data => {
      onSuccess();
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_EDITED, {
        'Edit Type': 'Page Component Group Updated',
      });
    },
    onError: reactQueryConfigError('Update Group'),
  });
};

const useDeleteGroup = <TError = unknown, TContext = unknown>(
  onSuccess: () => void,
  options?: UseMutationOptions<
    DeleteStatusPageGroupMutation,
    TError,
    DeleteStatusPageGroupMutationVariables,
    TContext
  >,
) => {
  return useDeleteStatusPageGroupMutation({
    ...options,
    onSuccess: data => {
      onSuccess();
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_EDITED, {
        'Edit Type': 'Page Component Group Deleted',
      });
    },
    onError: reactQueryConfigError('Delete Group'),
  });
};

const useChangeComponentOrder = <TError = unknown, TContext = unknown>(
  onSuccess: () => void,
  options?: UseMutationOptions<
    UpdateStatusPageComponentListOrderMutation,
    TError,
    UpdateStatusPageComponentListOrderMutationVariables,
    TContext
  >,
) => {
  return useUpdateStatusPageComponentListOrderMutation({
    ...options,
    onSuccess: data => {
      onSuccess();
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_EDITED, {
        'Edit Type': 'Page Component Order Changed',
      });
    },
    onError: reactQueryConfigError('Change component order'),
  });
};

export {
  useCreateComponent,
  useUpdateComponent,
  useDeleteComponent,
  useCreateGroup,
  useUpdateGroup,
  useDeleteGroup,
  useChangeComponentOrder,
};
