import { Box, Flex, FormLabel, Heading, Link, Switch, Text, Button } from '@chakra-ui/react';
import { CrownIcon, TransientAlertIcon } from 'icons';
import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import Tippy from '@tippy.js/react';
import UpgradeOnlyModal from 'components/upgradeonly.tooltip';
import { API, BillingService } from 'core';
import Axios from 'axios';
import { Modal } from 'library/molecules';

type Props = {
  isEnabled: boolean;
  onToggle: (isEnabled: boolean) => void;
  timeoutWindow: number;
  onChangeTimeoutWindow: (timeoutWindow: number) => void;
  isEditMode?: boolean;
  serviceID?: string;
  setAptaRecommendedSelected?: (state: boolean) => void;
};

type TimeoutWindowOption = {
  value: number;
  label: string;
};

const AptaConfigPanel: React.FC<Props> = ({
  isEnabled,
  onToggle: onToggleProp,
  timeoutWindow,
  onChangeTimeoutWindow,
  isEditMode,
  serviceID,
  setAptaRecommendedSelected,
}) => {
  const [showDisableWarningModal, setShowDisableWarningModal] = React.useState<boolean>(false);
  const [recommendedTimeoutWindow, setRecommendedTimeoutWindow] = React.useState<number>(0);

  const timeoutWindowOptions: TimeoutWindowOption[] = useMemo(() => {
    const defaultTimeoutOptions = [
      {
        value: 2,
        label: '2 mins',
      },
      {
        value: 3,
        label: '3 mins',
      },
      {
        value: 5,
        label: '5 mins',
      },
      {
        value: 10,
        label: '10 mins',
      },
      {
        value: 15,
        label: '15 mins',
      },
    ];

    return defaultTimeoutOptions.map(option => {
      if (option.value === recommendedTimeoutWindow) {
        return {
          ...option,
          label: `${option.label} (Recommended)`,
        };
      }

      return option;
    });
  }, [recommendedTimeoutWindow]);

  useEffect(() => {
    if (isEditMode && serviceID) {
      if (isEnabled) {
        (async () => {
          try {
            const response = await getAPTARecommendedTimeoutWindow(serviceID);

            if (response?.data.recommended_timeout_window) {
              setRecommendedTimeoutWindow(response.data.recommended_timeout_window);
            }
          } catch (e) {
            console.log('Failed to get APTA Recommended Timeout Window', e);
          }
        })();
      }
      return;
    } else {
      // Default Recommended for Create Flow is 5 mins
      setRecommendedTimeoutWindow(5);
    }
  }, [isEditMode, isEnabled, serviceID, setRecommendedTimeoutWindow]);

  const onToggle = () => {
    if (isEnabled && isEditMode) {
      setShowDisableWarningModal(true);
      return;
    }

    onToggleProp(!isEnabled);
  };

  const { aptaRules, organizationProps } = useSelector((state: IAppState) => ({
    aptaRules: state.organization.plan.p?.rules['auto-pause-transient-alerts'],
    organizationProps: { organization: state.organization },
  }));

  const { isAptaEnabled, planLimit, isPlanRenewable } = useMemo(() => {
    if (!aptaRules) {
      return {
        isAptaEnabled: false,
        planLimit: undefined,
        isPlanRenewable: false,
      };
    }

    const limit: number | undefined | 'unlimited' = aptaRules.unlimited
      ? 'unlimited'
      : aptaRules.quantity || undefined;

    return {
      isAptaEnabled: true,
      planLimit: limit,
      isPlanRenewable: aptaRules.renewable,
    };
  }, [aptaRules]);

  return (
    <Tippy
      content={
        !isAptaEnabled ? (
          <UpgradeOnlyModal
            hasBillingPermission={BillingService.hasManageBillingPermission(organizationProps)}
            align="left"
            showModal={true}
            message={BillingService.getMessage(
              planLimit,
              'auto-pause-transient-alerts',
              organizationProps,
              isPlanRenewable,
            )}
            header={BillingService.getHeader(
              planLimit,
              'auto-pause-transient-alerts',
              organizationProps,
            )}
          />
        ) : (
          ''
        )
      }
      interactive={true}
      animation={'scale'}
      placement="top-end"
    >
      <Box bg="#F3F9FF" px={3} py={3}>
        <Flex>
          <Box width="24px" height="24px" mt="6px">
            <TransientAlertIcon />
          </Box>

          <Box width={5} />

          <Box>
            <Flex>
              <Heading as="h3" fontSize="20px" color="gray.700" fontWeight="600">
                Auto Pause Transient Alerts (APTA)
              </Heading>

              <Box width={4} />

              <Switch
                marginTop={1}
                isChecked={isEnabled}
                onChange={() => onToggle()}
                disabled={!isAptaEnabled}
              />

              {!isAptaEnabled && (
                <>
                  <Box width={4} />

                  <CrownIcon width={24} height={24} />
                </>
              )}
            </Flex>

            <AptaConfigInfoCard />

            {isEnabled && (
              <Box>
                <FormLabel fontWeight={600}>Timeout Window</FormLabel>

                <Select
                  name="Timeout Window"
                  id="timeout-window"
                  options={timeoutWindowOptions}
                  value={timeoutWindowOptions.find(_ => _.value === timeoutWindow)}
                  onChange={option => {
                    if (setAptaRecommendedSelected && option?.value === recommendedTimeoutWindow) {
                      setAptaRecommendedSelected(true);
                    }

                    onChangeTimeoutWindow(option?.value || 5);
                  }}
                />
              </Box>
            )}

            <Modal
              title="Do you want to disable Auto Pause Transient Alerts (APTA)?"
              isOpen={showDisableWarningModal}
              onClose={() => setShowDisableWarningModal(false)}
              primaryButton={
                <Button
                  onClick={() => {
                    onToggleProp(!isEnabled);
                    setShowDisableWarningModal(false);
                  }}
                  variant="default"
                >
                  <Text>Confirm</Text>
                </Button>
              }
              secondaryButton={
                <Button
                  onClick={() => {
                    setShowDisableWarningModal(false);
                  }}
                  variant="outline"
                >
                  <Text>Cancel</Text>
                </Button>
              }
              body={
                <Text color="gray.500">
                  Automatic detection of transient alerts and pausing of notifications will be
                  disabled, which may result in higher notification volume.
                </Text>
              }
            ></Modal>
          </Box>
        </Flex>
      </Box>
    </Tippy>
  );
};

type APTARecommendedTimeoutWindowResponse = {
  recommended_timeout_window: number;
  service_median_ttr: number;
};

const getAPTARecommendedTimeoutWindow = async (serviceId: string) => {
  const { data } = await Axios.get<{ data: APTARecommendedTimeoutWindowResponse }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/catalog-services/${serviceId}/apta-recommended-timeout-window`,
  );

  return data;
};

const AptaConfigInfoCard: React.FC = () => (
  <Box py={4} maxWidth="420px">
    <Text color="gray.500">
      This feature automatically detects Transient Alerts and pauses their notifications.{' '}
      <Link
        color="primary.default"
        href="https://support.squadcast.com/services/auto-pause-transient-alerts-apta"
        target="_blank"
      >
        Read More
      </Link>
    </Text>
  </Box>
);

export default AptaConfigPanel;
