import React, { HTMLAttributes, ReactChild } from 'react';
import styled from 'styled-components';

const Shell = styled.div`
  padding: 8px;
  height: 100%;
  overflow-y: auto;
  background: ${props => props.theme.shades.whiteSmoke};
`;

interface IContainerProps extends HTMLAttributes<HTMLDivElement> {
  height?: string | 'fit-content';
  marginBottom?: string | '150px';
}

const Container = styled.div<IContainerProps>`
  height: ${props => props.height || 'fit-content'};
  width: inherit;
  padding: 0;
  margin-bottom: ${props => props.marginBottom || '20px'};
  border-radius: 3px;
  background: ${props => props.theme.shades.white};
`;

interface IProps {
  ShellProps?: HTMLAttributes<HTMLDivElement>;
  ContainerProps?: IContainerProps;
  children: ReactChild;
}

const AppFrame = (props: IProps) => (
  <Shell {...props.ShellProps}>
    <Container {...props.ContainerProps}>{props.children}</Container>
  </Shell>
);

/** @component */
export default AppFrame;
