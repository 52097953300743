import axios from 'axios';
import { API } from 'core';
import { IAlertSource } from 'core/interfaces/IIntegration';
import { AssigneeType } from 'library/enums';
import { compact, isArray } from 'lodash';
import { createContext } from 'react';
import { QueryClient, useQueries } from 'react-query';

import { QUERY_CONFIG, QUERY_KEYS } from '../common/constants';
import { createFilterFromQuery } from '../filters/manager/query-checker';
import {
  useGetIncidentAssignableEntitiesQuery,
  useGetactiveTagsQuery,
  useListIncidentGroupsQuery,
  useListServicesQuery,
} from '../graphql/query';
import { filterDropdownOptions, IncidentParamsContextType, InitParams } from '../interfaces/common';
import { IGetSlo, ISlo } from '../interfaces/slo';
import { useHistory } from 'react-router-dom';
import { useEntityOwnerFilter, FilterEntity } from '../../owner-filters/hooks/useEntityOwnerFilter';

const getSLOList = async (teamId: string | unknown) => {
  const { data } = await axios.get<{ data: IGetSlo }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo?owner_id=${teamId}`,
  );
  return data.data;
};

const getAlertSourceList = async () => {
  const { data } = await axios.get<{ data: IAlertSource[] }>(
    `${API.config.endpoint}/public/integrations`,
  );
  return data;
};

export const useAllParameters = (queryClient: QueryClient, query: URLSearchParams) => {
  const teamID = API.config.teamId;
  const config = { ...QUERY_CONFIG, staleTime: 1000 };
  const history = useHistory();

  const { data: filterList, isLoading: filterListLoading } = useListIncidentGroupsQuery(
    { teamID },
    QUERY_CONFIG,
  );

  const {
    tagsList: tagsData,
    data: tagData,
    isLoading: tagsIsLoading,
  } = useGetactiveTagsQuery({ teamID }, config);

  const { isLoading: assignIsLoading, data: assigneeList } = useGetIncidentAssignableEntitiesQuery(
    { teamID },
    config,
  );

  const { data: services, isLoading: isServicesLoading } = useListServicesQuery({ teamID }, config);

  const [slos, alertSources] = useQueries([
    {
      queryKey: [QUERY_KEYS.SLOLIST, teamID],
      queryFn: () => getSLOList(teamID),
      ...{ ...QUERY_CONFIG, notifyOnChangeProps: ['data', 'error'], staleTime: 1000000 },
    },
    {
      queryKey: QUERY_KEYS.ALERTSOURCELISTDATA,
      queryFn: () => getAlertSourceList(),
      ...{ ...QUERY_CONFIG, notifyOnChangeProps: ['data', 'error'], staleTime: 1000000 },
    },
  ]);

  const alertSourceList: filterDropdownOptions[] =
    alertSources.data && isArray(alertSources.data.data)
      ? alertSources.data.data.map((alert: IAlertSource) => {
          return { label: alert.type, value: alert._id };
        })
      : [];

  const servicesData = services && isArray(services.listServices) ? services.listServices : [];

  const serviceListForFilters: filterDropdownOptions[] = servicesData.map(service => {
    return { label: service.name, value: service.serviceID, meta: { ep: service.epID } };
  });

  const serviceList: filterDropdownOptions[] = servicesData.map(service => {
    return { label: service.name, value: service.serviceID };
  });

  const slolist: filterDropdownOptions[] = slos.data
    ? isArray(slos.data?.slos)
      ? compact(
          slos.data.slos.map((slo: ISlo) => {
            if (slo.name && slo.id) return { label: slo.name, value: slo.id.toString() };
          }),
        )
      : []
    : [];

  const getAssigneeList = (
    type: AssigneeType,
    list: Array<{ ID: string; name: string; usernameForDisplay?: string | null }> = [],
  ) => {
    return list.reduce((acc, item) => {
      acc.push({
        label: item.name,
        value: item.ID,
        assigneeType: type,
        username: item.usernameForDisplay,
      });
      return acc;
    }, [] as filterDropdownOptions[]);
  };

  const users = getAssigneeList(
    AssigneeType.User,
    assigneeList?.getIncidentAssignableEntities.users,
  );

  const squads = getAssigneeList(
    AssigneeType.Squad,
    assigneeList?.getIncidentAssignableEntities.squads,
  );

  const escalationPolicy = getAssigneeList(
    AssigneeType.Escalationpolicy,
    assigneeList?.getIncidentAssignableEntities.escalationPolicies,
  );
  const assignedTo = [...users, ...squads, ...escalationPolicy];

  const isLoading =
    tagsIsLoading || assignIsLoading || slos.isLoading || isServicesLoading || filterListLoading;

  const filterQueryParam = query.get('entity_owner') ?? '';

  const { dropdownOptions } = useEntityOwnerFilter({
    queryParamsString: filterQueryParam,
    entity: FilterEntity.ESCALATION_POLICY,
    selectedTeamId: API.config.teamId,
  });
  // check for filters in query
  createFilterFromQuery(
    {
      serviceList,
      alertSourceList,
      slolist,
      assignedTo,
      tagsData,
      dropdownOptions,
      isLoading,
    },
    queryClient,
    query,
    history,
    filterList,
  );

  // return all params needed for IL creation/filtering
  return {
    serviceListForFilters,
    serviceList,
    alertSourceList,
    slolist,
    users,
    squads,
    escalationPolicy,
    tagsData,
    assigneeList,
    tagData,
    filterList,
    isLoading,
  };
};

export const IncidentParamsContext = createContext<IncidentParamsContextType>({
  params: InitParams,
});

export const useGetServiceListForMaintainer = (
  maintainerIDs: string[],
  serviceOwner?: any,
  teamData?: any,
) => {
  const teamID = API.config.teamId;
  const config = { ...QUERY_CONFIG, staleTime: 1000 };

  const selectedTeamId = teamData ? teamData : teamID;
  const { data, isLoading } = useListServicesQuery(
    { teamID: selectedTeamId, maintainerIDs },
    config,
  );
  const servicesData = data && isArray(data.listServices) ? data.listServices : [];
  const serviceList = servicesData.map(service => {
    return { label: service.name, value: service.serviceID };
  });
  return serviceList;
};
