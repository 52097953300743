import axios from 'axios';
import { API } from '../api';
import { IRoutingRule, IRoutingRuleDetail } from '../interfaces/IRoutingRule';

type RoutingRule = IRoutingRule & IRoutingRuleDetail;

class RoutingRulesService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/services`;
  private orgRoutingRulesCountApi = `${API.config.batman}/organizations/${API.config.organizationId}/routing-rules/count`;

  constructor(serviceId: string) {
    this._api = `${this._api}/${serviceId}/routing-rules`;
  }

  public getOrgCount = () => axios.get<{ data: { count: number } }>(this.orgRoutingRulesCountApi);

  public get = () =>
    axios.get<{
      data: {
        id: string;
        created_at: string;
        updated_at: string;
        deleted_at: any;
        service_id: string;
        organization_id: string;
        rules: RoutingRule[];
      };
    }>(this._api);

  public save = (rules: IRoutingRule[]) => axios.post(this._api, { rules });
}

export default RoutingRulesService;
