import React from 'react';

interface Props {
  visiblePages: number[];
  activePage: number;
  changePage(pageIndex: number): void;
  pageCount: number;
}

function PaginationArrow(props: Props): JSX.Element {
  return (
    <>
      <div style={{ textAlign: 'center' }} className="pagination-container-div">
        <button
          style={{ border: 'none', backgroundColor: 'transparent' }}
          onClick={() => {
            if (props.activePage === 1) return;
            props.changePage(1);
          }}
        >
          <img
            src="/icons/carousel-double-left.svg"
            className="no-select cursor-pointer"
            alt="left"
            style={{
              marginRight: 33,
              verticalAlign: 'middle',
              pointerEvents: props.activePage !== 1 ? 'all' : 'none',
              opacity: props.activePage !== 1 ? 1 : 0.3,
            }}
          />
        </button>
        <button
          style={{ border: 'none', backgroundColor: 'transparent' }}
          onClick={() => {
            if (props.activePage === 1) return;
            props.changePage(props.activePage - 1);
          }}
        >
          <img
            className="no-select cursor-pointer"
            alt="next"
            src="/icons/arrow-back.svg"
            style={{
              width: 14,
              height: 14,
              position: 'relative',
              top: 2,
              marginRight: 33,
              pointerEvents: props.activePage !== 1 ? 'all' : 'none',
              opacity: props.activePage !== 1 ? 1 : 0.3,
            }}
          />
        </button>

        {props.visiblePages.map((page, index, array) => (
          <PageNumber
            index={index}
            key={index}
            page={page}
            changePage={props.changePage}
            activePage={props.activePage}
          />
        ))}
        <button
          style={{ border: 'none', backgroundColor: 'transparent' }}
          onClick={() => {
            if (props.activePage === props.pageCount) return;
            props.changePage(props.activePage + 1);
          }}
        >
          <img
            className="no-select cursor-pointer"
            alt="forward"
            src="/icons/arrow-forward.svg"
            style={{
              width: 14,
              height: 14,
              position: 'relative',
              top: 2,
              marginLeft: 33,
              pointerEvents: props.activePage !== props.pageCount ? 'all' : 'none',
              opacity: props.activePage !== props.pageCount ? 1 : 0.3,
            }}
          />
        </button>

        <button
          style={{ border: 'none', backgroundColor: 'transparent' }}
          onClick={() => {
            if (props.activePage === props.pageCount) return;
            props.changePage(props.pageCount);
          }}
        >
          <img
            src="/icons/carousel-double-right.svg"
            alt="forward"
            className="no-select cursor-pointer"
            style={{
              marginLeft: 33,
              verticalAlign: 'middle',
              pointerEvents: props.activePage !== props.pageCount ? 'all' : 'none',
              opacity: props.activePage !== props.pageCount ? 1 : 0.3,
            }}
          />
        </button>
      </div>
    </>
  );
}

interface PageNumberProps {
  index: number;
  changePage(pageIndex: number): void;
  page: number;
  activePage: number;
}

function PageNumber({ index, changePage, page, activePage }: PageNumberProps) {
  return (
    <span
      className="page-number item-box-tagline font-bold no-select cursor-pointer"
      onClick={() => changePage(page)}
      style={activePage == page ? { backgroundColor: '#f3f4f6' } : {}}
      key={index}
    >
      {page}
    </span>
  );
}

export default PaginationArrow;
