// keys
export const KEY_SPACE = ' ';
export const KEY_ESC = 'Escape';
export const KEY_ENTER = 'Enter';
export const KEY_TAB = 'Tab';
export const KEY_CTRL = 'Ctrl';
export const KEY_SHIFT = 'Shift';

// arrows
export const KEY_DOWN = 'ArrowDown';
export const KEY_UP = 'ArrowUp';
export const KEY_RIGHT = 'ArrowRight';
export const KEY_LEFT = 'ArrowLeft';

// event origin
export const CLICK = 'click';
export const KEYDOWN = 'keydown';
export const KEYPRESS = 'keypress';

export const has = (object: any, property: string) =>
  Object.prototype.hasOwnProperty.call(object, property);

export const ariaClick = (event: any) => event.detail !== 0 && event.type === CLICK;

export const ariaKeyDown = (event: any, keys: string[], shiftKey = false, ctrlKey = false) =>
  event.type === KEYDOWN &&
  event.key !== undefined &&
  keys.includes(event.key) &&
  (shiftKey ? event.shiftKey : true && ctrlKey ? event.ctrlKey : true);
