import React, { useMemo } from 'react';
import { Text, Box } from '@chakra-ui/react';
import { generatePath, Link, useParams } from 'react-router-dom';

import DataTable from 'components/chakra/Table';
import { SCHEDULES_V2_DETAIL_PATH } from 'views/main/routes/routes';
import ScheduleTags from '../../schedules.tags';
import { maxTagsCount } from '../../../constants/schedules.view';
import { CalendarViewType } from '../../../interface/schedule';
import { IListEventInfo } from '../../schedules.list/listView.table';
import { IPatternParticipant } from '../../../helpers/helpers.customrotations';
import initializeColumns from '../../schedules.list/listView.table/columns';
import SQTooltip from 'components/chakra/Tooltip';

type InputEvent = {
  eventInfo: IListEventInfo;
};
interface Props {
  events: InputEvent[];
  allParticipants: IPatternParticipant[];
  isScheduleDetailsView: boolean;
  timeZone: string;
}

export const ScheduleDetails = ({
  events,
  allParticipants,
  isScheduleDetailsView,
  timeZone,
}: Props) => {
  const columns = useMemo(
    () =>
      initializeColumns({
        allParticipants,
        timeZone,
      }).filter(col =>
        ['rotation_time', 'rotation_participants', 'rotation_name'].includes(col.id),
      ),
    [allParticipants],
  );

  return (
    <Box p={4}>
      {events.map((event: InputEvent, index: number) => {
        const [eventsData] = Object.values(event.eventInfo);
        const scheduleName = eventsData[0].scheduleName;
        const scheduleID = eventsData[0].scheduleID;
        const scheduleTags = eventsData[0].scheduleTags.filter(tag => !!tag.key && !!tag.value);

        return (
          <Box key={index} mt={index > 0 ? 10 : 4}>
            {!isScheduleDetailsView && (
              <>
                <Link
                  to={
                    generatePath(SCHEDULES_V2_DETAIL_PATH, { scheduleId: scheduleID }) +
                    `?view=${CalendarViewType.dayGridMonth}`
                  }
                >
                  <SQTooltip text={scheduleName}>
                    <Text color={'blue.900'} maxW={250} isTruncated>
                      {scheduleName}
                    </Text>
                  </SQTooltip>
                </Link>
                <ScheduleTags tags={scheduleTags ?? []} maxTagsCount={maxTagsCount.dateClick} />
              </>
            )}
            <DataTable
              columns={columns}
              rowBottomBorder={true}
              headerBackgroundColor="#ECEFF3;"
              data={[event.eventInfo]}
              width="100%"
              containerStyles={{
                marginTop: 2,
                '& td': {
                  padding: 0,
                  paddingY: 2,
                },
                '& tr>:nth-child(1)': {
                  minW: 130,
                  maxW: 130,
                },
                '& tr>:nth-child(2)': {
                  minW: 180,
                  maxW: 180,
                },
                '& tr>:nth-child(3)': {
                  minW: 150,
                  maxW: 150,
                },
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};
