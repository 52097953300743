import { JiraIcon, SlackHash } from 'icons';
import React, { Fragment } from 'react';

import { HStack, Link, Stack, Text, VStack } from '@chakra-ui/react';

import { OverlayModal } from '../../common';
import NodePopover from '../../components/Popover';
import StackLimit from '../../components/StackLimit';
import { IExtensions, ServiceDetail } from '../../hooks/useServiceDetail';
import { IExtensionType } from '../../Interfaces/extension';
import { JiraCloudExtensionModal, JiraCloudExtensionModalHoc } from './extension.jiraCloud.modal';
import SlackExtensionModal from './extension.slack.modal';

type Props = {
  enabledExtensions: IExtensions;
  setEnabledExtensionModal: (extension: IExtensionType) => void;
};

function ServiceExtensions({ enabledExtensions, setEnabledExtensionModal }: Props) {
  return (
    <Fragment>
      <Stack direction="row" spacing="5px">
        {enabledExtensions.slack && (
          <NodePopover
            enableTriggerWrapper
            trigger={<StackLimit type="icon" data={[{ icon: 'slack' }]} />}
          >
            <VStack>
              <Text>Slack</Text>
              <Link onClick={() => setEnabledExtensionModal('slack')}>
                <HStack spacing={0}>
                  <SlackHash width={24} />
                  <Text>{enabledExtensions.slack.name}</Text>
                </HStack>
              </Link>
            </VStack>
          </NodePopover>
        )}

        {enabledExtensions.jira_cloud && (
          <NodePopover
            enableTriggerWrapper
            trigger={<StackLimit type="icon" data={[{ icon: 'jira_cloud' }]} />}
          >
            <VStack>
              <Text>Jira Cloud</Text>
              <Link onClick={() => setEnabledExtensionModal('jira')}>
                <HStack spacing={0}>
                  <JiraIcon width={24} />
                  <Text>{enabledExtensions.jira_cloud.project_name}</Text>
                </HStack>
              </Link>
            </VStack>
          </NodePopover>
        )}
      </Stack>
    </Fragment>
  );
}

export default ServiceExtensions;

interface ServiceExtensionsModalProps {
  enabledExtensionModal: 'slack' | 'jira';
  hideExtensionModal: () => void;
  serviceId: string;
  serviceDetail: ServiceDetail;
  refetchServiceDetail?: () => void;
}
export const ServiceExtensionsModal = ({
  enabledExtensionModal,
  hideExtensionModal,
  serviceId,
  serviceDetail,
  refetchServiceDetail,
}: ServiceExtensionsModalProps) => {
  return (
    <Fragment>
      <OverlayModal
        maxW="600px"
        disableFooter
        isOpen={enabledExtensionModal === 'slack'}
        onClose={hideExtensionModal}
        content={
          <SlackExtensionModal
            checkAndSetDirty={() => {}}
            hide={hideExtensionModal}
            serviceId={serviceId}
            service={serviceDetail}
            refetch={refetchServiceDetail}
          />
        }
      />

      <OverlayModal
        maxW="800px"
        disableFooter
        isOpen={enabledExtensionModal === 'jira'}
        onClose={hideExtensionModal}
        content={
          <JiraCloudExtensionModalHoc
            checkAndSetDirty={() => {}}
            hide={hideExtensionModal}
            setIsDirty={() => {}}
            serviceId={serviceId}
            service={serviceDetail}
          />
        }
      />
    </Fragment>
  );
};
