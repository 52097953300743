import DataTable from 'components/chakra/Table';
import React, { useMemo } from 'react';
import { useGetAllPatternParticipants } from '../../../helpers/helpers.customrotations';
import { IRotationTable } from './types';
import { getColumns, getScheduleRotations } from './utils';

function RotationTable({
  timeZone,
  rotations,
  gaps,
  overrides,
  visibleDates,
  onCallCoverage,
}: IRotationTable) {
  const allParticipants = useGetAllPatternParticipants();
  const scheduleRotations = useMemo(
    () =>
      getScheduleRotations({
        rotations,
        gaps,
        overrides,
        visibleDates,
        allParticipants,
        onCallCoverage,
      }),
    [rotations, gaps, visibleDates, allParticipants, overrides, onCallCoverage],
  );

  const columns = useMemo(
    () =>
      getColumns({
        visibleDates,
        timeZone,
        allParticipants,
      }),
    [visibleDates, timeZone],
  );

  return (
    <DataTable
      columns={columns}
      data={scheduleRotations}
      width="100%"
      containerStyles={{
        '& th:first-of-type': {
          width: '10%',
        },
        '& th': {
          width: `${90 / 14}%`,
        },
      }}
    />
  );
}

export default RotationTable;
