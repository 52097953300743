// tslint:disable: no-invalid-template-strings
/* eslint-disable no-template-curly-in-string */
export interface IAutoCompletes {
  name: string;
  meta: string;
  snippet: string;
  caption: string;
  score: number;
}

export const transformTypings =
  (meta: string, snippet?: string, caption?: string) => (name: string, score: number) => ({
    name,
    meta,
    score,
    snippet: snippet || name,
    caption: caption || name,
  });

export const startMaps: IAutoCompletes[] = [
  {
    name: 'help',
    meta: 'help',
    snippet: 'help: (${0})',
    caption: 'help: (integration)',
    score: 1,
  },
  {
    name: 'service',
    meta: 'service name',
    snippet: 'service: (${0})',
    caption: 'service: ()',
    score: 3,
  },
  {
    name: 'before',
    meta: 'before',
    snippet: 'before: (${1:YYYY}-${2:MM}-${3:DD})',
    caption: 'before: (YYYY-MM-DD)',
    score: 4,
  },
  {
    name: 'after',
    meta: 'after',
    snippet: 'after: (${1:YYYY}-${2:MM}-${3:DD})',
    caption: 'after: (YYYY-MM-DD)',
    score: 5,
  },
  {
    name: 'in',
    meta: 'incidents | postmortems',
    snippet: 'in: ',
    caption: 'in: ',
    score: 6,
  },
  {
    name: 'integration',
    meta: 'integration',
    snippet: 'integration: (${0})',
    caption: 'integration: ()',
    score: 7,
  },
  {
    name: 'sort',
    meta: 'sort',
    snippet: 'sort: (${0})',
    caption: 'sort: ()',
    score: 8,
  },
  {
    name: 'status',
    meta: 'ACK|TRI|RES|SUP',
    snippet: 'status: ',
    caption: 'status: ',
    score: 10,
  },
  {
    name: 'message',
    meta: 'incident message',
    snippet: 'message: "${0}"',
    caption: 'message: ""',
    score: 11,
  },
];

export const sortMaps: IAutoCompletes[] = ['timeOfCreation']
  .map(e => {
    return ['-', ''].map(s => ({
      name: e,
      snippet: `${s}${e}`,
      caption: `${e} (${s === '-' ? 'desc' : 'asc'})`,
    }));
  })
  .flat()
  .map((t, i) => transformTypings('sort', t.snippet, t.caption)(t.name, i));

export const statusMap: IAutoCompletes[] = [
  'triggered',
  'acknowledged',
  'resolved',
  'suppressed',
].map(transformTypings('status'));

export const inMap: IAutoCompletes[] = ['incidents', 'postmortems'].map(transformTypings('in'));
