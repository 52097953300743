import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const TextTheme: ComponentStyleConfig = {
  baseStyle: {
    color: 'secondary.900',
    fontFamily: 'Mulish',
    m: '0px !important',
    p: '0px !important',
  },
  variants: {
    display1: {
      fontSize: '54px',
      fontWeight: 400,
    },
    display1_800: {
      fontSize: '54px',
      fontWeight: 800,
    },
    display2: {
      fontSize: '43px',
      fontWeight: 400,
    },
    display2_800: {
      fontSize: '43px',
      fontWeight: 800,
    },
    placeholder: {
      fontSize: '30px',
      fontWeight: 400,
    },
    placeholder_800: {
      fontSize: '30px',
      fontWeight: 800,
    },
    display3: {
      fontSize: '32.5px',
      fontWeight: 400,
    },
    display3_800: {
      fontSize: '32.5px',
      fontWeight: 800,
    },
    spinner_text: {
      fontSize: '24px',
      fontWeight: 400,
    },
    h1: {
      fontSize: '27px',
      fontWeight: 400,
    },
    h1_800: {
      fontSize: '27px',
      fontWeight: 800,
    },
    h2: {
      fontSize: '21.5px',
      fontWeight: 400,
    },
    h2_600: {
      fontSize: '21.5px',
      fontWeight: 600,
    },
    h2_800: {
      fontSize: '21.5px',
      fontWeight: 800,
    },
    h3: {
      fontSize: '18px',
      fontWeight: 400,
    },
    h3_800: {
      fontSize: '18px',
      fontWeight: 800,
    },
    h4: {
      fontSize: '16px',
      fontWeight: 400,
    },
    h4_800: {
      fontSize: '16px',
      fontWeight: 800,
    },
    formInput_small: {
      color: 'secondary.900',
      fontSize: '0.75rem',
      lineHeight: '100%',
      height: '0.8125rem',
    },
    formInput: {
      fontSize: '14.5px',
      fontWeight: 400,
    },
    formInput_800: {
      fontSize: '14.5px',
      fontWeight: 800,
    },
    stats_number: {
      fontSize: '24px',
      fontWeight: 800,
    },
    stats_label: {
      fontSize: '14px',
      fontWeight: 800,
      color: 'secondary.700',
    },
    body: {
      fontSize: '12.5px',
      fontWeight: 400,
    },
    // used in key-value tags
    body_700: {
      fontSize: '12.5px',
      fontWeight: 700,
    },
    body_800: {
      fontSize: '12.5px',
      fontWeight: 800,
    },
    // used for squad avatar, specification taken from Chakra avatar text
    avatar_600: {
      fontSize: '12px',
      fontWeight: 600,
    },
    hint: {
      fontSize: '10.5px',
      fontWeight: 400,
    },
    hint_700: {
      fontSize: '10.5px',
      fontWeight: 700,
    },
    hint_800: {
      fontSize: '10.5px',
      fontWeight: 800,
    },
    listingButtonText: {
      fontSize: '12.5px',
      fontWeight: 800,
      height: '13px',
      lineHeight: '100%',
    },
    formButtonText: {
      fontSize: '14.5px',
      fontWeight: 800,
      height: '21.5px',
      lineHeight: '150%',
    },
    smallButtonText: {
      fontSize: '10.5px',
      fontWeight: 800,
      height: '10.5px',
      lineHeight: '100%',
    },
  },
};
