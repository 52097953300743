import { Box, Flex, VStack, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { THEME_COLORS as THEME } from 'library/theme/colors';

export type SaveConfiguration = {
  id: number;
  is_enabled: boolean;
  servicenow_domain: string;
  servicenow_username: string;
  servicenow_password: string;
  sync_mode: string;
};

export const sanitizeDomain = (domain: string): string => {
  let sanitizedDomain = domain.startsWith('https://') ? domain : `https://${domain}`;
  sanitizedDomain = sanitizedDomain.endsWith('/') ? sanitizedDomain.slice(0, -1) : sanitizedDomain;
  return sanitizedDomain;
};

export const isServiceNowIntegrated = (status: string): boolean => {
  const allowedStatuses = ['integrated', 'partially_integrated'];
  return allowedStatuses.includes(status);
};

export const getVerificationStatus = (status: string) => {
  const getStatusComponent = (
    backgroundColor: string,
    icon: ReactNode,
    text: string,
    textColor: string,
  ) => (
    <VStack py="6px" backgroundColor="brand.white" alignItems="flex-start" id="verification-status">
      <Box
        backgroundColor={backgroundColor}
        borderRadius="3px"
        py="3px"
        px="6px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex alignItems="center" gap={1}>
          {icon}
          <Text fontSize="sm" fontWeight={400} color={textColor}>
            {text}
          </Text>
        </Flex>
      </Box>
    </VStack>
  );

  switch (status) {
    case 'verified':
      return getStatusComponent(
        '#DBF6CC',
        <CheckCircleIcon color={THEME.brand.green} fontSize={18} />,
        'Successful',
        '#09305A',
      );
    case 'failed':
      return getStatusComponent(
        '#FFF6BF',
        <WarningIcon color={'#E05000'} fontSize={18} />,
        'ServiceNow test configuration failed. Please retry.',
        '#E05000',
      );
    default:
      return null;
  }
};

export const verifyInputData = ({
  servicenow_domain,
  servicenow_username,
  servicenow_password,
  sync_mode,
}: Pick<
  SaveConfiguration,
  'servicenow_domain' | 'servicenow_username' | 'servicenow_password' | 'sync_mode'
>) => {
  return (
    servicenow_domain.trim() !== '' &&
    servicenow_username.trim() !== '' &&
    servicenow_password.trim() !== '' &&
    sync_mode.trim() !== ''
  );
};

export const initialConfiguration = {
  id: 0,
  servicenow_domain: '',
  servicenow_username: '',
  servicenow_password: '',
  sync_mode: '',
};

export const inputIconStyles: React.CSSProperties = {
  position: 'absolute',
  right: '1px',
  top: '1px',
  height: 'calc(100% - 2px)',
  width: '30px',
  cursor: 'pointer',
  backgroundColor: '#F1F3F6',
  padding: '0.75px 7.5px',
  borderRadius: '0px 0.375rem 0.375rem 0px',
  borderLeft: '1px solid #E2E8F0',
};
