import React from 'react';
import {
  Avatar,
  Checkbox,
  ErrorBlock,
  FocusBlock,
  Grid,
  Heading,
  Label,
  Para,
  Pill,
  SpinLoader,
  TextButton,
  Theme,
} from 'uie/components';
import { UserDependencies } from '.';
import Select from 'react-select';
import {
  EscalationIcon,
  IncidentsIcon,
  RoutingRuleIcon,
  ScheduleIcon,
  SquadIcon,
  ServiceIcon,
  WebformsIcon,
  SloTrackerIcon,
  RunbookIcon,
  GerColoredIcon,
  StatusPageIcon,
  WorkflowsIcon,
  StakeholderIcon,
  LCRConflictIcon,
  TeamIcon,
  AlternateEyeFilledIcon,
} from '../../../../../../../../icons';
import { DelayedNotificationsIcon } from 'library/icons';
import { Note } from '../../../../shared/note';
import entities from '../../entities';
import { Text } from 'library/atoms/Text';

const { theme } = Theme;
export function render(this: UserDependencies) {
  const networkState = this.state.componentNetworkState;
  return <>{networkState === 'success' ? this.renderSuccess() : this.renderDefault()}</>;
}

export function renderSuccess(this: UserDependencies) {
  const { oldUser, dependencies } = this.props;
  const { newUserId } = this.state;
  const getNewUser = () => {
    if (dependencies.stakeholder_groups.length) {
      let user = {
        name: '',
        email: '',
      };
      this._stakeholders.filter((ele: any) => {
        if (ele.id === newUserId)
          user = {
            name: `${ele.first_name} ${ele.last_name}`,
            email: `${ele.email}`,
          };
      });
      return user;
    }
    return this._oUsers[newUserId];
  };
  const newUser = getNewUser();

  return (
    <Grid type="column">
      <Grid className="mb-20">
        <Grid alignItems="flex-start" type="column">
          <Heading height={35} fontSize={24}>
            Member Removed
          </Heading>
        </Grid>
      </Grid>

      <Grid type="column" className="mt-10">
        <Grid alignItems="center" className="mb-20">
          <Grid className="mr-10">
            <Avatar base={10} fontSize={14} reduceColorString={oldUser.id as string}>
              {oldUser.name}
            </Avatar>
          </Grid>
          <Grid>
            <Label
              fontSize={16}
              fontWeight={500}
              color={theme.shades.black}
            >{`${oldUser.name} (${oldUser.email})`}</Label>
          </Grid>
        </Grid>
        <Grid className="mb-20">
          <Label fontSize={14} fontWeight={500}>
            is swapped with
          </Label>
        </Grid>
        <Grid alignItems="center" className="mb-20">
          <Grid className="mr-10">
            <Avatar base={10} fontSize={14} reduceColorString={newUser?.id as string}>
              {newUser?.name}
            </Avatar>
          </Grid>
          <Grid>
            <Label
              fontSize={16}
              fontWeight={500}
              color={theme.shades.black}
            >{`${newUser?.name} (${newUser?.email})`}</Label>
          </Grid>
        </Grid>
        <Grid className="mb-20">
          <Label fontSize={14} fontWeight={500}>
            and removed successfully
          </Label>
        </Grid>
      </Grid>

      <Grid className="mt-10" justifyContent="flex-end">
        <TextButton
          color={theme.primary.default}
          onClick={this.props.hide}
          height="32px"
          width="16%"
        >
          <Grid alignItems="center">
            <Para color={theme.shades.white} fontWeight={500}>
              Done
            </Para>
          </Grid>
        </TextButton>
      </Grid>
    </Grid>
  );
}

export function renderDefault(this: UserDependencies) {
  const { oldUser, dependencies } = this.props;
  const { suppressIncidents, newUserId, componentNetworkState, errors } = this.state;
  const isBusy = componentNetworkState === 'request';

  const renderEntities = [
    {
      title: 'Escalation Policies',
      icon: <EscalationIcon width={18} height={18} />,
      entities: dependencies.escalation_policies,
    },
    {
      title: 'Escalation Policies Owned',
      icon: <EscalationIcon width={18} height={18} />,
      entities: dependencies.esc_policies_with_owners,
    },
    {
      title: 'Global Event Rulesets Owned',
      icon: <GerColoredIcon width={18} height={18} />,
      entities: dependencies.global_event_rules_owner,
    },
    {
      title: 'Delayed Notifications Configured',
      icon: <DelayedNotificationsIcon width={18} height={18} />,
      entities: dependencies.services_with_delay_notifications_config,
    },
    {
      title: 'Schedules Legacy',
      icon: <ScheduleIcon width={18} height={18} />,
      entities: dependencies.schedules,
    },
    {
      title: 'Schedules',
      icon: <ScheduleIcon width={18} height={18} />,
      entities: dependencies.schedulesV2,
    },
    {
      title: 'Schedules Owned',
      icon: <ScheduleIcon width={18} height={18} />,
      entities: dependencies.schedules_owner,
    },
    {
      title: 'Squads',
      icon: <SquadIcon width={18} height={18} />,
      entities: dependencies.squads,
    },
    {
      title: 'Services Owned',
      icon: <ServiceIcon width={18} height={18} />,
      entities: dependencies.services,
    },
    {
      title: 'Webforms Owned',
      icon: <WebformsIcon width={18} height={18} />,
      entities: dependencies.webforms,
    },
    {
      title: 'Slos Owned',
      icon: <SloTrackerIcon width={18} height={18} />,
      entities: dependencies.slos,
    },
    {
      title: 'Runbooks Owned',
      icon: <RunbookIcon width={18} height={18} />,
      entities: dependencies.runbooks_owner,
    },
    {
      title: 'Status Pages Owned',
      icon: <StatusPageIcon width={18} height={18} />,
      entities: dependencies.status_pages_owner,
    },
    {
      title: 'Workflows owned',
      icon: <WorkflowsIcon width={18} height={18} />,
      entities: dependencies.workflows,
    },
    {
      title: 'Incident saved filters',
      icon: <IncidentsIcon stroke={theme.shades.black} width={18} height={18} />,
      entities: dependencies.incident_groups,
    },
    {
      title: 'Stakeholder group',
      icon: <StakeholderIcon stroke={theme.shades.black} width={18} height={18} />,
      entities: dependencies.stakeholder_groups,
    },
    {
      title: 'Routing Number',
      icon: <LCRConflictIcon stroke={theme.shades.black} width={18} height={18} />,
      entities: dependencies.routing_numbers,
    },
    {
      title: 'Incident Watcher',
      icon: <AlternateEyeFilledIcon stroke={theme.shades.black} width={18} height={18} />,
      entities: dependencies.incident_watchers,
    },
  ];

  const routingRules = dependencies.routing_rules.reduce((acc, curr) => acc + curr.count, 0);

  return (
    <Grid type="column">
      <Grid className="mb-20">
        <Grid alignItems="flex-start" type="column">
          <Heading height={35} fontSize={24}>
            Remove Member
          </Heading>
        </Grid>
      </Grid>

      <Grid alignItems="baseline" style={{ paddingBottom: 30, wordBreak: 'break-all' }}>
        <Grid>
          <Avatar base={10} fontSize={14} reduceColorString={oldUser.id as string}>
            {oldUser.name}
          </Avatar>
        </Grid>

        <Grid type="column" style={{ paddingLeft: 8 }}>
          <Grid>
            <Label fontSize={16} fontWeight={500} color={theme.shades.black}>
              {oldUser.name}
            </Label>
          </Grid>
          <Grid className="mt-10">
            <Label fontSize={16} fontWeight={500}>
              {`(${oldUser.email}) is part of the following:`}{' '}
            </Label>
          </Grid>
        </Grid>
      </Grid>

      <Grid flexWidth={10} type="column">
        {renderEntities.map((re, idx) => (
          <>
            {re.entities.length > 0 && (
              <Grid className="mb-20" alignItems="flex-start">
                <Grid>{re.icon}</Grid>

                <Grid type="column" style={{ paddingLeft: 8 }}>
                  <Grid>
                    <Para fontSize={14}>{re.title}</Para>
                  </Grid>
                  <Grid className="mt-10" justifyContent="flex-start" flexWrap="wrap">
                    {re.entities.map(e => {
                      return (
                        <Pill key={e.id} className="mr-10 mb-10">
                          <Para fontSize={14}>{e.name}</Para>
                        </Pill>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        ))}

        {/* Incidents */}
        {dependencies.incidents > 0 && (
          <Grid className="mb-20" alignItems="flex-start">
            <Grid>
              <IncidentsIcon stroke={theme.shades.black} width={18} height={18} />
            </Grid>

            <Grid type="column" style={{ paddingLeft: 8 }}>
              <Grid>
                <Para fontSize={14}>Incidents</Para>
              </Grid>
              <Grid type="column">
                <Grid className="mt-10" alignItems="center">
                  <Para fontSize={14}>Open Incidents:</Para>
                  <Pill className="ml-10">
                    <Para fontSize={14}>{`${dependencies.incidents} Incident(s)`}</Para>
                  </Pill>
                </Grid>
                <Grid className="mt-10">
                  <Label style={{ height: 'auto' }} className="mb-10 cursor-pointer">
                    <Grid alignItems="flex-start">
                      <Checkbox
                        checked={suppressIncidents}
                        onChange={this.toggleSuppressIncidents}
                        base="14px"
                      />
                      <Para className="ml-10" fontSize={14} fontWeight={400}>
                        Suppress the open incidents
                      </Para>
                    </Grid>
                  </Label>
                </Grid>
                <Grid>
                  <Para fontSize={12} color={theme.shades.lightGrey}>
                    If <strong>checked</strong>: incidents move to Suppressed state.
                    <br />
                    If <strong>unchecked</strong>: incidents are reassigned to user and will move
                    back to Triggered state. To avoid alert fatigue, they will only receive{' '}
                    <strong>one Email</strong> notification for all the reassigned incidents.
                  </Para>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* Team */}
        {dependencies.is_last_member_with_manage_team_role && (
          <Grid className="mb-20" alignItems="flex-start">
            <Grid>
              <TeamIcon width={18} height={18} />
            </Grid>
            <Grid type="column" style={{ paddingLeft: 8 }}>
              <Grid>
                <Para fontSize={14}>Team</Para>
              </Grid>
              <Grid className="mt-10">
                <Label style={{ height: 'auto' }} className="mb-10">
                  <Para fontSize={14} fontWeight={400}>
                    This is the last member with the Manage Team role in this team. Removing this
                    member will remove manage team abilities.
                  </Para>
                </Label>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* Routing Rules */}
        {routingRules > 0 && (
          <Grid className="mb-20" alignItems="center">
            <Grid>
              <RoutingRuleIcon width={18} height={18} />
            </Grid>
            <Grid type="column" style={{ paddingLeft: 8 }}>
              <Grid alignItems="center">
                <Para fontSize={14}>Routing Rules</Para>
                <Pill className="ml-10">
                  <Para>{`${routingRules} Rule(s)`}</Para>
                </Pill>
              </Grid>
            </Grid>
          </Grid>
        )}

        {oldUser.role !== 'stakeholder' && <Note />}
      </Grid>

      <Grid type="column">
        <Grid alignItems="center" className="mb-10">
          <Grid>
            <Label fontSize={16} fontWeight={500} color={theme.shades.black}>
              Swap
            </Label>
          </Grid>
          <Grid className="ml-10 mr-10">
            <Avatar base={10} fontSize={14} reduceColorString={oldUser.id as string}>
              {oldUser.name}
            </Avatar>
          </Grid>
          <Grid style={{ wordBreak: 'break-all' }}>
            <Label
              fontSize={16}
              fontWeight={500}
              color={theme.shades.black}
            >{`${oldUser.name} (${oldUser.email})`}</Label>
          </Grid>
        </Grid>
        <Grid className="mb-10">
          <Label fontSize={16} fontWeight={500}>
            With
          </Label>
        </Grid>
        <Grid className="mb-10" flexWidth={5}>
          <Select
            options={Object.values(
              dependencies.stakeholder_groups.length ? this._stakeholders : this._oUsers,
            )
              .filter(u => u.id !== oldUser.id)
              .map((user, i) => {
                return {
                  label: dependencies.stakeholder_groups.length
                    ? `${user.first_name} ${user.last_name}`
                    : user.name,
                  value: user.id,
                };
              })}
            onChange={this.selectNewUser}
            isMulti={false}
            styles={{
              container: styles => ({
                ...styles,
                width: '100%',
              }),
            }}
          />
        </Grid>
      </Grid>

      <Grid>
        <ErrorBlock>{errors.network_err}</ErrorBlock>
      </Grid>

      <Grid className="mt-10" justifyContent="flex-end">
        <TextButton
          color={theme.primary.default}
          onClick={this.replaceAndDelete}
          disabled={newUserId === '' || isBusy}
          height="32px"
        >
          <Grid alignItems="center">
            <Para color={theme.shades.white} fontWeight={500}>
              Swap & Remove
            </Para>
            {isBusy && <SpinLoader className="ml-5" color={theme.shades.white} base="8px" />}
          </Grid>
        </TextButton>

        <TextButton
          buttonType="ghost"
          className="ml-10"
          height="32px"
          onClick={this.props.hide}
          disabled={isBusy}
        >
          <Para color={theme.shades.black}>Cancel</Para>
        </TextButton>
      </Grid>
    </Grid>
  );
}
