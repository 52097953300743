import { Box, Container, HStack, Stack } from '@chakra-ui/react';
import * as React from 'react';
import { Step } from './Step';
import { useStep } from './useStep';

interface IProp {
  steps: {
    title: string;
    description: string;
  }[];
  onChangeStep: (step: number) => void;
  sliderValue: number;
}

export const ProgressStepsJira = (props: IProp) => {
  const [currentStep, { setStep, goToNextStep, goToPrevStep, canGoToNextStep, canGoToPrevStep }] =
    useStep({ maxStep: props.steps.length, initialStep: 2 });

  React.useEffect(() => {
    setStep(props.sliderValue);
  }, [props.sliderValue]);

  return (
    <Container
      maxW="container.lg"
      centerContent
      bg="bg-surface"
      padding={0}
      paddingStart={0}
      paddingEnd={0}
    >
      <HStack spacing="0" p={0} m={0} width="100%">
        {props.steps.map((step, id) => (
          <Step
            key={id}
            stepNumber={id}
            cursor="pointer"
            title={step.title}
            description={step.description}
            isActive={currentStep === id}
            isCompleted={currentStep > id}
            isFirstStep={id === 0}
            isLastStep={props.steps.length === id + 1}
          />
        ))}
      </HStack>
    </Container>
  );
};
