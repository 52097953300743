import { truncate } from 'core/helpers/stringUtils';
import React, { ReactNode } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 0.2px solid #e5e5e5;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  &:hover {
    background-color: #f3f7fd;
  }
`;

const Card: React.FC<{
  icon: ReactNode;
  title: string;
  onClick: () => void;
  disableLinkifying?: boolean;
}> = props => {
  return (
    <Wrapper onClick={props?.onClick}>
      {props.icon}
      <span
        style={
          !props.disableLinkifying
            ? {
                textDecoration: 'underline',
                color: '#1061e5',
              }
            : undefined
        }
      >
        {truncate(props.title, 35)}
      </span>
    </Wrapper>
  );
};

export default Card;
