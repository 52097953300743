import { Text, VStack } from '@chakra-ui/layout';
import ActionList from './ActionList';

import ActionTypeList from './ActionTypeList';

const ActionGroup = () => {
  return (
    <VStack alignItems="flex-start" gap="0.5rem">
      <Text fontSize="14px">Actions will be executed one after the other</Text>
      <ActionList />

      <ActionTypeList />
    </VStack>
  );
};

export default ActionGroup;
