import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Divider, Popover } from 'library/atoms';
import { LibraryIcons } from 'library/icons';
import { ColorPicker } from 'library/molecules';
import { useState } from 'react';

export const ColorSelection = ({
  onChange,
  options,
  colorValue,
}: {
  onChange: (color: string) => void;
  options: string[];
  colorValue?: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isPopoverOpen, onOpen: onPopoverOpen, onClose: onPopoverClose } = useDisclosure();
  const [color, setColor] = useState<string>(colorValue ?? options[0]);

  const isColorFromOptions =
    color &&
    options.find(val => {
      return val === color;
    });

  const togglePopover = () => {
    (isPopoverOpen ? onPopoverClose : onPopoverOpen)();
  };

  const isEditabled = color && !isColorFromOptions;
  const ColorBlock = ({ value }: { value: string }) => (
    <Flex
      justifyContent={'center'}
      alignItems={'center'}
      // 50% opacity to border as of tag
      borderColor={`${value}50`}
      borderWidth="1px"
      borderRadius={'4px'}
      height={'20px'}
      width={'20px'}
      cursor="pointer"
      onClick={() => {
        setColor(value);
        onChange(value);
        togglePopover();
      }}
    >
      {<Text color={value}>T</Text>}
    </Flex>
  );

  const COLOR_BLOCK = (
    <Box
      borderWidth="1px"
      borderColor="secondary.200"
      borderRadius={4}
      pl={3}
      pr={2}
      py={0.5}
      columnGap={2}
      justifyContent="space-between"
      alignItems="center"
      display="flex"
      onClick={togglePopover}
    >
      <ColorBlock value={color} />
      <LibraryIcons.ChevronDownIcon />
    </Box>
  );

  return (
    <>
      <Popover
        display="flex"
        height={4}
        enableTriggerWrapper={true}
        disablePortal={true}
        trigger={COLOR_BLOCK}
        isOpen={isPopoverOpen}
      >
        <VStack spacing={2} p={2} alignItems="flex-start">
          <Grid templateColumns="repeat(4, 1fr)" gap={2}>
            {options.map((color: string, index) => (
              <GridItem key={index}>
                <ColorBlock value={color} />
              </GridItem>
            ))}
          </Grid>
          <Divider orientation="horizontal" />
          {isEditabled && (
            <HStack>
              <ColorBlock value={color} />
              <Spacer />
              <Text variant="body_700">{color}</Text>
            </HStack>
          )}
          <Spacer />
          <Text
            variant="formInput_800"
            onClick={onOpen}
            cursor="pointer"
            color="brand.blue"
            fontSize="sm"
          >
            {isEditabled ? 'Edit Color' : 'Add New'}
          </Text>
        </VStack>
      </Popover>
      <ColorPicker
        onSelection={(color: string) => {
          setColor(color);
          onChange(color);
        }}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          togglePopover();
        }}
      />
    </>
  );
};
