import React from 'react';

import { Layout } from '../../../../shared/components';
import StepperComp from './components';

function AddServiceCatalogComp() {
  return (
    <Layout customheight>
      <StepperComp
        heading={'Define Service'}
        subHeading={
          'Create a service and add alert source integrations to begin receiving incidents'
        }
      />
    </Layout>
  );
}

export default AddServiceCatalogComp;
