import { Flex, Text, VStack } from '@chakra-ui/react';
import { FormikProps } from 'formik';
import { FormInput, FormSelect, FormTextArea } from 'library/molecules';
import { memo } from 'react';

import { DROPDOWN_OPTIONS, RULE_FORM } from '../../types';
import { RULESET_RULE_FORM_ID } from '../../util';
import { setRuleObj } from './temp-store';

export const RuleTemplate = memo(
  ({
    values,
    errors,
    handleSubmit,
    setFieldValue,
    services,
    isReadOnly,
  }: FormikProps<RULE_FORM> & {
    services: DROPDOWN_OPTIONS[];
    isReadOnly: boolean;
  }) => {
    const setObjFieldValue = (field: string, value: any) => {
      setFieldValue(field, value);
      setRuleObj(field, value);
    };

    const getTitle = (title: string) => (isReadOnly ? title : `${title}*`);

    return (
      <form id={RULESET_RULE_FORM_ID} onSubmit={handleSubmit}>
        <VStack alignItems="flex-start" p={4} gap={2}>
          <FormInput
            label={getTitle('Rule Description')}
            fieldName="description"
            placeholder="Enter Rule Description"
            onChange={setObjFieldValue}
            value={values.description}
            error={errors.description}
            isReadOnly={isReadOnly}
            autoFocus={!isReadOnly}
          />
          <FormTextArea
            label={getTitle('Rule Expression')}
            fieldName="expression"
            onChange={setObjFieldValue}
            placeholder="Enter Rule Expression"
            value={values.expression}
            error={errors.expression}
            height="200px"
            isReadOnly={isReadOnly}
          />
          <Flex flexDir="column" gap={1}>
            <Text color="secondary.1000" variant="formInput">
              If the rule expression matches, route to
            </Text>
            <FormSelect
              options={services}
              value={values.service}
              label={getTitle('Route to')}
              onChange={setObjFieldValue}
              placeholder="Select Service"
              error={errors.service}
              fieldName="service"
              isClearable={false}
              controlShouldRenderValue
              isReadOnly={isReadOnly}
            />
          </Flex>
        </VStack>
      </form>
    );
  },
);
