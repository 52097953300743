import { API } from '../api';
import Axios from 'axios';
import {
  IApiTokenResponseAdminGetAll,
  IApiTokenResponseAdminCreate,
  IApiTokenResponseUserCreate,
  IApiTokenResponseUserGet,
} from '../interfaces/IApiToken';

class ApiTokenService {
  private _apiUser = `${API.config.batman}/me/organizations/${API.config.organizationId}/api/refresh-token`;
  private _apiAdmin = `${API.config.batman}/organizations/${API.config.organizationId}/refresh-token`;

  public adminCreateUserToken = (userId: string) =>
    Axios.post<IApiTokenResponseAdminCreate>(this._apiAdmin, {
      user_id: userId,
    });

  public adminGetAllTokens = () => Axios.get<IApiTokenResponseAdminGetAll>(this._apiAdmin);

  public adminDeleteToken = (tokenId: string) => Axios.delete(`${this._apiAdmin}/${tokenId}`);

  public userCreateToken = () => Axios.post<IApiTokenResponseUserCreate>(this._apiUser);

  public userGetToken = () => Axios.get<IApiTokenResponseUserGet>(this._apiUser);

  public userRefreshToken = (tokenId: string) =>
    Axios.put<IApiTokenResponseUserCreate>(`${this._apiUser}/${tokenId}`);

  public userDeleteToken = (tokenId: string) => Axios.delete(`${this._apiUser}/${tokenId}`);
}

export default ApiTokenService;
