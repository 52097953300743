import {
  DeleteStatusPageIssueMutation,
  DeleteStatusPageIssueMutationVariables,
  useDeleteStatusPageIssueMutation,
} from '../graphql/mutation';
import React, { useReducer, useState } from 'react';
import { UseMutationOptions, useQueryClient } from 'react-query';

import {
  ISSUE_FILTER_TYPES,
  issueFilterDetails,
  queryKeys,
  reactQueryConfig,
} from '../constants/status.constants';
import { useGetStatusIssueListQuery } from '../graphql/query';
import { IssuesFilterTimeType } from '../graphql/types';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/helper.toast';
import { IssueListReducer } from '../reducers/IssueListReducer';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_ISSUE_DELETED } from 'core/const/tracker';

const useDeleteIssue = <TError = unknown, TContext = unknown>(
  onSuccess: () => void,
  options?: UseMutationOptions<
    DeleteStatusPageIssueMutation,
    TError,
    DeleteStatusPageIssueMutationVariables,
    TContext
  >,
) => {
  const queryClient = useQueryClient();
  return useDeleteStatusPageIssueMutation({
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.GET_ISSUE_LIST]);
      onSuccess();
    },
    onError: reactQueryConfigError('Delete issue'),
  });
};

export function useIssueList(pageID: number) {
  const [state, dispatch] = useReducer(IssueListReducer, {
    search: {
      searchText: '',
      enabled: false,
    },
    pagination: {
      queryPageIndex: 1,
      queryPageSize: 5,
    },
    drawer: {
      isDrawerOpen: false,
      drawerType: null,
      issueId: -1,
      prevDrawer: '',
    },
    filters: {
      [ISSUE_FILTER_TYPES.ISSUE_STATUS]: [],
      [ISSUE_FILTER_TYPES.COMPONENT_STATUS]: [],
      [ISSUE_FILTER_TYPES.COMPONENT_GROUP]: [],
      [ISSUE_FILTER_TYPES.COMPONENT]: [],
      [ISSUE_FILTER_TYPES.TIME]: [],
    },
    deleteIssue: {
      showModal: false,
      selectedIssueeID: -1,
    },
    filterCount: 0,
  });
  const issueListData = useGetStatusIssueListQuery(
    {
      input: {
        pageID: pageID,
        pageNumber: state.pagination.queryPageIndex,
        pageSize: state.pagination.queryPageSize,
        search: state.search.searchText,
        filters: {
          time: state.filters[ISSUE_FILTER_TYPES.TIME]?.[0] as IssuesFilterTimeType,
          currentIssueState: state.filters[ISSUE_FILTER_TYPES.ISSUE_STATUS],
          componentStatusSlugs: state.filters[ISSUE_FILTER_TYPES.COMPONENT_STATUS],
          componentGroupID: state.filters[ISSUE_FILTER_TYPES.COMPONENT_GROUP],
          componentID: state.filters[ISSUE_FILTER_TYPES.COMPONENT],
        },
      },
    },
    { ...reactQueryConfig, onError: reactQueryConfigError('Get issue list') },
  );

  const { mutateAsync: deleteIssueApi } = useDeleteIssue(() => {
    if (typeof issueListData.refetch === 'function') {
      issueListData.refetch();
    }
    reactQueryConfigSuccess('Issue deleted');

    AppTracker.track(T_WA_UP_STATUS_PAGE_V2_ISSUE_DELETED);
  });

  const deleteIssuePageAction = (id: number) => {
    return deleteIssueApi({ id: id });
  };

  return {
    list: issueListData?.data?.listStatusPageIssues?.data || [],
    totalCount: issueListData?.data?.listStatusPageIssues?.meta?.totalCount || 0,
    isIssueListLoading: issueListData.isLoading,
    isFetched: issueListData.isFetched,
    isIssueListError: issueListData.isError,
    isSuccess: issueListData.isSuccess,
    refetchIssueList: issueListData.refetch,
    state,
    dispatch,
    issueFilterDetails,
    deleteIssuePageAction,
  };
}
