export const DEFAULT_THEMES = [
  {
    primary: '#0C8F31',
    text: '#E7FEDF',
  },
  {
    primary: '#065381',
    text: '#A7D6F2',
  },
  {
    primary: '#34B2E4',
    text: '#06516F',
  },
  {
    primary: '#77F1F0',
    text: '#129897',
  },
  {
    primary: '#8B103E',
    text: '#F8D2E0',
  },
  {
    primary: '#E34856',
    text: '#FDC6CB',
  },
  {
    primary: '#000000',
    text: '#FFFFFF',
  },
];
