import Axios from 'axios';
import qs from 'query-string';
import { API } from 'core';
import { QUERY_CONFIG } from 'library/query-config';
import { QueryClient, useQuery } from 'react-query';

import { QUERY_KEY } from './queryKeys';

const getRulesetList = (
  gerId: string,
  alertVersion: string,
  alertName: string,
  page: number,
  limit: number,
  search?: string,
) => {
  const props = {
    page_size: limit,
    page_number: page + 1,
    ...(search ? { 'filters.search': search } : null),
  };
  return Axios.get(
    `${API.config.batman}/organizations/${
      API.config.organizationId
    }/global-event-rules/${gerId}/rulesets/${alertVersion}/${alertName}/rules?${qs.stringify(
      props,
    )}`,
  );
};

export const useGetGerRulesetList = (
  gerId: string,
  alertVersion: string,
  alertName: string,
  page: number,
  limit: number,
  search?: string,
) => {
  const { data, isSuccess, isError, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEY.GER_RULESET_LIST, gerId, alertVersion, alertName, page, limit, search],
    queryFn: () => getRulesetList(gerId, alertVersion, alertName, page, limit, search),
    ...QUERY_CONFIG,
  });
  const response = data?.data ?? { data: {}, meta: {} };
  return {
    rules: response.data,
    isSuccess,
    isError,
    isLoading,
    isFetching,
    count: response.meta?.total_count ?? 0,
  };
};

export const invalidateGERRulesetList = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(QUERY_KEY.GER_RULESET_LIST);
};
