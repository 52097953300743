/* eslint-disable prettier/prettier */
import { ChatOpsIcon, GenericAttachmentIcon, SlackHashColor, VideoIcon, MsTeamsIcon } from 'icons';
import React, { useMemo } from 'react';
import Card from './Card';
import styled from 'styled-components';
import CardForm from './CardForm';
import { CardType, ICommunicationCard } from 'core/interfaces/ICommunicationCard';
import { useParams } from 'react-router-dom';
import { ExtensionService, IncidentCommunicationCardsService } from 'core';
import useShowToast from './hooks/useShowToast';
import { AppTracker } from '../../../../../../../shared/analytics/tracker';
import { T_WA_GS_SLACK_CHANNEL_CREATED } from '../../../../../../../core/const/tracker';
import { T_WA_GS_CHATOPS_LINK_ADDED } from '../../../../../../../core/const/tracker';
import { T_WA_GS_VIDEO_CONFERENCE_LINK_ADDED } from '../../../../../../../core/const/tracker';
import { T_WA_GS_EXTERNAL_LINK_ADDED } from '../../../../../../../core/const/tracker';

const Wrapper = styled.div`
  margin-top: 18px;
`;

const ShowAddingNewCard: React.FC<{
  canShowSlackCard: boolean;
  canShowCreateMSTeamsMeetingLinkCard: null | boolean;
  handleAddNewCardSuccess: (card: ICommunicationCard) => void;
  resetAddNewCard: () => void;
  isSlackV2Enabled?: boolean;
}> = props => {
  const [isFormOpen, setIsFormOpen] = React.useState(false);
  const [isSlackNameTakenError, setIsSlackNameTakenError] = React.useState(false);
  const [slackChannelNameSuggestions, setSlackChannelNameSuggestions] = React.useState<string[]>(
    [],
  );
  const [activeFormType, setActiveFormType] = React.useState<CardType>(CardType.other);
  const attachmentIconProps = {
    fontSize: 12,
    style: {
      marginRight: '12px',
    },
  };

  const isSlackNameTaken = (errorMessage: string) => {
    return errorMessage === 'name_taken';
  };

  const { showErrorToast } = useShowToast();

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const handleCardClick = (cardType: CardType) => {
    setActiveFormType(cardType);
    setIsFormOpen(true);
  };

  const route: {
    incidentId: string;
  } = useParams();

  const communicationCardService = useMemo(
    () => new IncidentCommunicationCardsService(route.incidentId),
    [route.incidentId],
  );

  const extensionService = useMemo(() => new ExtensionService(), []);

  const onCardFormSubmit = async (card: ICommunicationCard) => {
    let channelName;
    try {
      if (card.type === CardType.slack) {
        channelName = card.title?.trim()?.toLowerCase()?.replaceAll(' ', '-') ?? route.incidentId;
        const {
          data: { data: newCard },
        } = !props.isSlackV2Enabled
          ? await extensionService.createNewSlackChannel({
              channel_name: channelName,
              incident_id: route.incidentId,
            })
          : await extensionService.createNewSlackV2Channel({
              channel_name: channelName,
              incident_id: route.incidentId,
            });
        props.handleAddNewCardSuccess(newCard);
        AppTracker.track(T_WA_GS_SLACK_CHANNEL_CREATED, {});
      } else if (card.type === CardType.msTeamsMeetingLink) {
        const {
          data: { data: newCard },
        } = await communicationCardService.createMSTeamsMeetingLink();
        props.handleAddNewCardSuccess(newCard);
      } else {
        const {
          data: { data: newCard },
        } = await communicationCardService.addCommunicationCard(card);
        props.handleAddNewCardSuccess(newCard);
        if (card.type === CardType.chatOps) {
          AppTracker.track(T_WA_GS_CHATOPS_LINK_ADDED, {});
        } else if (card.type === CardType.video) {
          AppTracker.track(T_WA_GS_VIDEO_CONFERENCE_LINK_ADDED, {});
        } else if (card.type === CardType.other) {
          AppTracker.track(T_WA_GS_EXTERNAL_LINK_ADDED, {});
        }
      }
    } catch (e: any) {
      if (isSlackNameTaken(e?.response?.data?.meta?.error_message)) {
        showErrorToast('Slack channel name is already taken');
        setSlackChannelNameSuggestions([
          `${channelName}-${route.incidentId?.substring(route.incidentId?.length - 4)}`,
        ]);
        setIsSlackNameTakenError(true);
        return;
      }
      showErrorToast("Couldn't add new card");
      props.resetAddNewCard();
    }
    closeForm();
  };

  if (!isFormOpen) {
    return (
      <Wrapper>
        {props.canShowSlackCard ? (
          <Card
            disableLinkifying
            icon={<SlackHashColor {...attachmentIconProps} />}
            title="Create slack channel"
            onClick={() => handleCardClick(CardType.slack)}
          />
        ) : null}
        {props.canShowCreateMSTeamsMeetingLinkCard ? (
          <Card
            disableLinkifying
            icon={<MsTeamsIcon {...attachmentIconProps} width={16} />}
            title="Create Microsoft Teams Meeting"
            onClick={() => handleCardClick(CardType.msTeamsMeetingLink)}
          />
        ) : null}
        <Card
          disableLinkifying
          icon={<VideoIcon {...attachmentIconProps} />}
          title="Add video conference link"
          onClick={() => handleCardClick(CardType.video)}
        />
        <Card
          disableLinkifying
          icon={<ChatOpsIcon {...attachmentIconProps} />}
          title="Add Chat room Link"
          onClick={() => handleCardClick(CardType.chatOps)}
        />
        <Card
          disableLinkifying
          icon={<GenericAttachmentIcon {...attachmentIconProps} />}
          title="Add External link"
          onClick={() => handleCardClick(CardType.other)}
        />
      </Wrapper>
    );
  } else
    return (
      <CardForm
        onCancel={closeForm}
        onSubmit={onCardFormSubmit}
        communicationCard={{
          type: activeFormType,
          title: '',
          url: '',
        }}
        type={activeFormType}
        error={
          isSlackNameTakenError
            ? {
                slack: {
                  channelNameSuggestions: slackChannelNameSuggestions,
                  message: 'Slack channel name is already taken',
                },
              }
            : undefined
        }
        resetChannelNameSuggestions={() => setSlackChannelNameSuggestions([])}
        isEditMode={false}
      />
    );
};

export default ShowAddingNewCard;
