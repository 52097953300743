import { ComponentProps } from 'react';
import {
  FilterEntity,
  useEntityOwnerFilter,
} from 'views/main/organization/owner-filters/hooks/useEntityOwnerFilter';
import useQueryParams from 'views/main/organization/service-catalog/helpers/helper.query-params';

import Badge from './badge';

const useSquadSelector = (teamId: string) => {
  const query = useQueryParams();

  const filterQueryParam = query.get('entity_owner') ?? '';
  const { dropdownOptions } = useEntityOwnerFilter({
    queryParamsString: filterQueryParam,
    entity: FilterEntity.ESCALATION_POLICY,
    selectedTeamId: teamId,
  });
  return dropdownOptions;
};

export const ServiceOwnerBadge = (
  props: ComponentProps<typeof Badge> & {
    selectedTeam?: string;
    showAccumlatedTags?: boolean;
    onRemoveTag: any;
  },
) => {
  const options = useSquadSelector(props.selectedTeam ?? '');
  let serviceOwners: any = [];
  options.map((val: any) => {
    serviceOwners = [...serviceOwners, ...val.options];
  });

  function onlyUnique(value: any, index: any, array: any) {
    return array.indexOf(value) === index;
  }
  const itemList = props.items
    .map(it => {
      const SOS = serviceOwners.find((s: any) => s.value === it);
      if (SOS) {
        return `${SOS?.label}`;
      }
      return `Service Owner Not Found`;
    })
    .filter(onlyUnique);

  const removeTag = (name: string) => {
    const itemList = props.items.map(it => {
      const SOS = serviceOwners.find((s: any) => s.value === it);
      if (SOS) {
        return SOS;
      }
      return `Squad Not Found`;
    });
    props.onRemoveTag(itemList?.filter((val: any) => val.label === name)[0]);
  };

  if (props.showAccumlatedTags) return <Badge {...props} items={itemList} showTooltip />;
  else
    return itemList?.map((list: any) => (
      <Badge
        key={props.label}
        onRemove={val => {
          removeTag(val);
        }}
        {...props}
        items={[list]}
        showTooltip
      />
    ));
};
