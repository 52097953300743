import {
  REQUEST_ORG_SQUADS,
  REQUEST_ORG_SQUADS_SUCCESS,
  REQUEST_ORG_SQUADS_FAIL,
} from '../../const/organization';
import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';
import { ISquad } from '../../interfaces/ISquad';

const initialOrganizationSquads = INITIAL_STATE.organization.squads;

const organizationSquads = (state = initialOrganizationSquads, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ action: RESET_STATE, s: [], error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_SQUADS:
      return {
        ...state,
        ...{ action: REQUEST_ORG_SQUADS, error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_SQUADS_SUCCESS:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_SQUADS_SUCCESS,
          s: action.payload.squads.sort((a: ISquad, b: ISquad) => a.name.localeCompare(b.name)),
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ORG_SQUADS_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_SQUADS_FAIL,
          s: [],
          error: { type: REQUEST_ORG_SQUADS_FAIL, reason: action.payload },
        },
      };
    default:
      return state;
  }
};

export default organizationSquads;
