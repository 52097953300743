import { LockIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  IconButton,
  Link as A,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import { IAppState } from 'core/interfaces/IAppState';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { VerticalDots } from 'icons';
import { PageLevelErrorMessage } from 'library/atoms';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { THEME_COLORS } from 'library/theme/colors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import NodePopover from 'views/main/organization/service-catalog/components/Popover';
import { STATUSPAGE_V2_PATH } from 'views/main/routes/routes';

import { getSquadcastUrl, truncateString } from '../../helpers/helper.details';
import { getStatus } from '../../helpers/helper.service';
import { IStatusPageComponentList, IStatusPageList } from '../../Interface';
import { ParticipantOptionLabel } from '../Avatar';

export const tableColumns = [
  {
    Header: 'STATUS PAGE NAME',
    accessor: (row: IStatusPageList) => row.name,
    Cell: (cell: Cell<IStatusPageList>) => {
      const {
        row: {
          original: { name, id },
        },
      } = cell;
      return (
        <Link
          to={`${STATUSPAGE_V2_PATH}/${id}`}
          style={{ color: THEME_COLORS.brand.blue, fontSize: '14px' }}
        >
          {truncateString(name, 30)}
        </Link>
      );
    },
  },
  {
    Header: 'PUBLIC/PRIVATE',
    accessor: (row: IStatusPageList) => row.isPublic,
    Cell: (cell: Cell<IStatusPageList>) => {
      const {
        row: {
          original: { isPublic },
        },
      } = cell;
      return isPublic ? (
        <Text fontSize="sm">Public</Text>
      ) : (
        <Flex>
          <LockIcon />
          <Text fontSize="sm" ml={2}>
            Private
          </Text>
        </Flex>
      );
    },
  },
  {
    Header: 'COMPONENTS',
    accessor: (row: IStatusPageList) => row.components,
    Cell: (cell: Cell<IStatusPageList>) => {
      const {
        row: {
          original: { components },
        },
      } = cell;
      const remainingItems = components.slice(2);
      const componentsText = components
        .slice(0, 2)
        .map((component: IStatusPageComponentList) => component.name)
        .join(' | ');
      return (
        <Flex align="center">
          <Text fontSize="sm" flex={1} mr={4}>
            {componentsText}
          </Text>
          {remainingItems.length > 0 ? (
            <NodePopover
              triggerType="hover"
              trigger={
                <Tag size="md">
                  <TagLabel>+{remainingItems.length}</TagLabel>
                </Tag>
              }
            >
              {remainingItems.map((component: IStatusPageComponentList) => (
                <Text fontSize="sm" key={component.id}>
                  {component.name}
                </Text>
              ))}
            </NodePopover>
          ) : null}
        </Flex>
      );
    },
  },
  {
    Header: 'TIME ZONE',
    accessor: (row: IStatusPageList) => <Text fontSize="sm">{row.timezone}</Text>,
  },
  {
    Header: 'URL',
    accessor: (row: IStatusPageList) => row.domainName,
    Cell: (cell: Cell<IStatusPageList>) => {
      const {
        row: {
          original: {
            domainName,
            customDomainName,
            isCustomDomainEnabled,
            isCustomARecordVerified,
            isCustomTxtVerified,
          },
        },
      } = cell;
      const canVisitUrl = isCustomDomainEnabled
        ? isCustomARecordVerified && isCustomTxtVerified
        : true;

      const sqUrl = getSquadcastUrl(domainName);
      const url = isCustomDomainEnabled ? `https://${customDomainName}` : sqUrl;

      return canVisitUrl ? (
        <A href={url} isExternal style={{ color: THEME_COLORS.brand.blue, fontSize: '14px' }}>
          {truncateString(url, 30)}
        </A>
      ) : (
        <Box>
          <Text fontSize="md" color={'red'}>
            {url}
          </Text>
          <PageLevelErrorMessage label="verification pending" hideIcon />
        </Box>
      );
    },
  },
  {
    Header: 'OWNER',
    accessor: (row: IStatusPageList) => row.ownerID,
    Cell: (props: any) => {
      const {
        row: {
          original: { ownerID, ownerType },
        },
      } = props.cell;
      const { ownerOptions } = props;
      const ownerValue = ownerOptions.find(
        (option: any) => option.id === ownerID && option.type === ownerType,
      );
      return (
        <ParticipantOptionLabel
          label={ownerValue?.label || ''}
          username={ownerValue?.username}
          type={ownerValue?.type}
          showTooltip={true}
        />
      );
    },
  },
  {
    Header: 'SUBSCRIBERS #',
    accessor: (row: IStatusPageList) => <Text fontSize="sm">{row.subscribersCount}</Text>,
  },
  {
    Header: 'CURRENT STATUS',
    accessor: (row: IStatusPageList) => row.status,
    Cell: (props: any) => {
      const OBACEnabled = useSelector(
        (state: IAppState) => state.organization.currentOrg.o?.config.obac_enabled ?? false,
      );

      const hasDeleteAccess = useUserAccess().hasDeleteAccess;
      const hasDelete = hasDeleteAccess('status_pages', props.cell.row.original.id);
      const {
        row: {
          original: { status, id, underMaintenance, statusMaintenance },
        },
      } = props.cell;
      const { onDelete } = props;
      const currentStatus = getStatus(status, underMaintenance, statusMaintenance);
      return (
        <Flex flex="1" align="center">
          <Box bgColor={currentStatus?.color} w={3} h={3} borderRadius={6} mr={2} />
          <Box flex="1">
            <Text fontSize="sm">{currentStatus.name}</Text>
          </Box>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="options"
              icon={<VerticalDots />}
              variant="unstyled"
              display="flex"
              justifyContent="center"
            />
            <MenuList>
              <NoPermissionTooltip isDisabled={hasDelete}>
                <MenuItem
                  onClick={() => {
                    onDelete(id);
                  }}
                  isDisabled={!hasDelete}
                >
                  Delete
                </MenuItem>
              </NoPermissionTooltip>
            </MenuList>
          </Menu>
        </Flex>
      );
    },
  },
];
