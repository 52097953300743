import { Box, HStack, VStack } from '@chakra-ui/react';
import React, { useMemo, useState, useCallback, useLayoutEffect, useEffect } from 'react';
import AddStakeHolderGroup from '../add';
import { IStakeHolderProps } from '../../types/stakeholder.types';
import StakeHolderGroupList from '../stakeHolderGroupList';
import { Grid, Para, SpinLoader, TextButton, Theme } from 'uie/components';
import { useStakeHolders } from '../../hooks/stakeholders.hooks';
import { BillingService } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { getFilteredUsers } from '../../utils/utils.stakeholders';
import { findNonCommonObjects } from '../../helpers';
import { useSettingPageACL } from '../../../../acl';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

function StakeHolderComponent(props: IStakeHolderProps) {
  const { organization, team } = props;
  const { theme } = Theme;

  const { fetchAllStakeHolderGroups, fetchingStakeHolders } = useStakeHolders();
  const [stakeHolderGroupData, setStakeHolderGroupData] = useState([]);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState<boolean>(false);

  const fetchData = async () => {
    const res = await fetchAllStakeHolderGroups(props.team?.id);
    if (res) setStakeHolderGroupData(res);
  };

  const isThisFeatureAvailable = useMemo(() => {
    return organization
      ? !BillingService.isFeatureDisabled(
          { organization } as unknown as Pick<IAppState, 'organization'>,
          'stakeholder-groups',
        )
      : true;
  }, [organization, BillingService]);

  const stakeholderGroupLimit = useMemo(() => {
    if (organization)
      return BillingService.getLimit(
        { organization } as unknown as Pick<IAppState, 'organization'>,
        'stakeholder-group-members',
      );
  }, [organization]);

  const userAccess = useSettingPageACL(state => state);
  const hasCreate = userAccess.hasCreateAccess('stakeholder_groups');

  const canCreateStakeHoldersGroup = useMemo(() => {
    return (
      hasCreate &&
      (stakeHolderGroupData.length < Number(stakeholderGroupLimit) ||
        stakeholderGroupLimit === 'unlimited')
    );
  }, [team, hasCreate, stakeHolderGroupData, stakeholderGroupLimit]);

  useEffect(() => {
    fetchData();
  }, []);

  const [addingNewStakeHolder, setAddingNewStakeHolder] = useState<boolean>(false);

  const exhsitingStakeHolderGroup = useMemo(() => {
    return Boolean(stakeHolderGroupData.length);
  }, [stakeHolderGroupData]);

  const [showAddStakeholderGroupCart, setShowAddStakeholderGroupCard] = useState<boolean>(
    !exhsitingStakeHolderGroup,
  );

  useEffect(() => {
    if (stakeHolderGroupData.length) setShowAddStakeholderGroupCard(false);
  }, [stakeHolderGroupData]);

  const toggleAddCard = useCallback(() => {
    if (isThisFeatureAvailable) {
      setShowAddStakeholderGroupCard(true);
      setAddingNewStakeHolder(true);
    } else {
      setShowUpgradePlanModal(true);
    }
  }, [isThisFeatureAvailable]);

  const closeAddCard = useCallback(() => {
    setShowAddStakeholderGroupCard(false);
    setAddingNewStakeHolder(false);
  }, []);

  const toggleUpgradePlanModal = useCallback(() => {
    setShowUpgradePlanModal(!showUpgradePlanModal);
  }, [showUpgradePlanModal]);
  return (
    <Box
      style={{
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
      }}
    >
      {fetchingStakeHolders ? (
        <Grid alignItems="center">
          <SpinLoader />
          <Para fontSize={14} className="ml-10">
            Loading Stakeholders group for <span className="font-bold">{props.team.name}</span>
          </Para>
        </Grid>
      ) : (
        <>
          <StakeHolderGroupList
            {...props}
            disableEditTab={addingNewStakeHolder}
            stakeHolderGroupData={stakeHolderGroupData}
            refetch={fetchData}
          />
          {showAddStakeholderGroupCart &&
            isThisFeatureAvailable &&
            canCreateStakeHoldersGroup &&
            !fetchingStakeHolders && (
              <AddStakeHolderGroup
                {...props}
                isEditMode={false}
                closeAddCard={closeAddCard}
                refetch={fetchData}
              />
            )}
          {!showAddStakeholderGroupCart && (
            <NoPermissionTooltip isDisabled={hasCreate}>
              <TextButton
                onClick={toggleAddCard}
                height="26px"
                style={{ marginBottom: '10px' }}
                disabled={!hasCreate}
              >
                <Grid alignItems="center">
                  <Para color={theme.font.white}>Add new stakeholder group</Para>
                </Grid>
              </TextButton>
            </NoPermissionTooltip>
          )}
        </>
      )}
    </Box>
  );
}

export default React.memo(StakeHolderComponent);
