import { useState, useCallback } from 'react';
import autoAnimate from './autoAnimate';

export interface AutoAnimateOptions {
  duration: number;

  easing: 'linear' | 'ease-in' | 'ease-out' | 'ease-in-out' | string;

  disrespectUserMotionPreference?: boolean;
}

export interface AnimationController {
  parent: any;
  enable: () => void;
  disable: () => void;
  isEnabled: () => boolean;
}

function useAutoAnimate(options?: AutoAnimateOptions) {
  const [controller, setController] = useState<AnimationController>();
  const element = useCallback((node: HTMLElement | null) => {
    if (node instanceof HTMLElement) {
      setController(autoAnimate(node, options));
    } else {
      setController(undefined);
    }
  }, []);
  const setEnabled = (enabled: boolean) => {
    if (controller) {
      enabled ? controller.enable() : controller.disable();
    }
  };
  return { element, setEnabled };
}

export { useAutoAnimate };
