import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { REQUEST_ORG_SERVICES, REQUEST_ORG_SERVICES_FAIL } from '../../const/organization';
import { onRequestOrganizationServiceSuccess } from '../../actions/organization/services';
import { IService } from '../../interfaces/IService';
import { getOrgServices } from '../../api/organization/getServices';

const getOrganizationsServicesEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ORG_SERVICES),
    map(action => action.payload),
    switchMap(() => {
      return getOrgServices().pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ result, data }: { result: boolean; data: IService[] }) => {
          return onRequestOrganizationServiceSuccess(data);
        }),
        catchError((error: AjaxError) => {
          if (error.status === 403) {
            return of(onRequestOrganizationServiceSuccess([]));
          }

          return of({
            type: REQUEST_ORG_SERVICES_FAIL,
            payload: error.xhr,
            error: true,
          });
        }),
      );
    }),
  );

export default getOrganizationsServicesEpic;
