import { Box, HStack, Radio, Text, VStack } from '@chakra-ui/react';
import { Tag, Tooltip } from 'library/atoms';
import { generatePath, Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { SERVICE_DETAILS_PATH } from 'views/main/routes/routes';

import { hasReadAccessToEntity } from '../../../navigation-flows/helpers';
import { INCIDENT_DATA } from '../../common/types';
import { getIncidentDetailsPath } from '../../common/util';
import TagList from '../TagList';
import { Dispatch, SetStateAction } from 'react';
import { Priority } from 'library/common';
import { getNameComponent, getPriorityColumnHeader } from '../column-utils';
import { COLUMN_DEF_PADDING_LEFT_VALUE } from '../../common/constants';

export const INCIDENT_MERGE_COLUMN_DEFS = (
  setParentIncidentID: Dispatch<SetStateAction<string>>,
) => [
  //setParentIncidentID is done so that selected row ID could be accessed outside table columns
  {
    id: 'select-incidents',

    Cell: (cell: Cell<INCIDENT_DATA>) => {
      const { id } = cell.row.original;

      return (
        <HStack justifyContent={'Center'} mt={1}>
          <Radio
            value={id}
            checked
            onChange={() => {
              setParentIncidentID(id);
            }}
          />
        </HStack>
      );
    },
    maxWidth: 40,
  },
  {
    Header: 'INCIDENT MESSAGE',
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      const { id, message, tags } = cell.row.original;
      return (
        <VStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} pb={4} flexGrow={1} alignItems="flex-start">
          <Link to={getIncidentDetailsPath(id)} target="_blank">
            {getNameComponent(message, 50)}
          </Link>
          {!!tags.length && <TagList tags={tags} />}
        </VStack>
      );
    },
    minWidth: 200,
  },
  {
    id: 'priority',
    Header: () => {
      return getPriorityColumnHeader();
    },
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      const priority = cell.row.original.priority;
      return (
        <Box pl={COLUMN_DEF_PADDING_LEFT_VALUE}>
          <Priority value={priority} />
        </Box>
      );
    },
  },
  {
    Header: 'DEDUP EVENTS',
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      const { eventCount } = cell.row.original;
      return (
        <Box pl={COLUMN_DEF_PADDING_LEFT_VALUE}>
          <Text variant="body">{eventCount > 1 ? eventCount - 1 : '-'}</Text>
        </Box>
      );
    },
  },
  {
    Header: 'SERVICE',
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      const { id, name } = cell.row.original.service;
      let nameComp = (
        <Text pl={COLUMN_DEF_PADDING_LEFT_VALUE} variant="body">
          {name}
        </Text>
      );
      if (hasReadAccessToEntity('service')) {
        nameComp = (
          <Link target="_blank" to={generatePath(SERVICE_DETAILS_PATH, { id })}>
            {nameComp}
          </Link>
        );
      }
      return <Box pl={COLUMN_DEF_PADDING_LEFT_VALUE}>{nameComp}</Box>;
    },
  },
  {
    Header: 'SLO AFFECTED',
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      const sloId = cell.row.original.sloID;
      return sloId > 0 ? (
        <Box pl={COLUMN_DEF_PADDING_LEFT_VALUE}>
          <Tag variant="default" title="SLO Affected" />
        </Box>
      ) : (
        <></>
      );
    },
  },
];
