import React from 'react';
import { ILog } from 'core/interfaces/IIncidents';
import { Grid, Para, Theme } from 'uie/components';
import ActionHasSlo from '../ActionHasSlo';
import { Locale } from 'core/helpers/dateUtils';
import useUINavigationFunctionsV2 from 'views/main/organization/navigation-flows/useUINavigationFunctionV2';
import { IActivityMessages } from 'views/main/organization/navigation-flows/helpers';
import { IAppState } from 'core/interfaces/IAppState';
import ActivityIcon from '../ActivityIcon';
import ServiceNowMessages from './ServiceNowMessages';
import MessageBasedonAction from './messageBasedonAction/MessageBasedOnAction';
import MessageBasedonActionType from './messageBasedonActionType/MessageBasedonActionType';
import MessageBasedOnMultipleAction from './messageBasedOnMultipleAction/MessageBasedOnMultipleAction';

const ActivityMessages: React.FC<IActivityMessages & Pick<IAppState, 'organization'>> = ({
  incident,
  organization,
  logs,
  oUser,
  statusPages,
  setFocusMessageId,
}) => {
  const { theme } = Theme;

  const { getDetailsFromLogActivity } = useUINavigationFunctionsV2(organization);
  return (
    <>
      {logs.map((log: ILog, index) => {
        if (log.action === 'Comment') return null;
        if (log.action === 'note_created') return null;

        const userName = oUser[log.id] || '';
        const { logMessageActivity, entityComponent, logChanges } = getDetailsFromLogActivity(log);
        const data = {
          log: log,
          logMessageActivity: logMessageActivity,
          entityComponent: entityComponent,
        };
        return (
          <Grid flexWidth={12} className="verticalLine" alignItems="flex-start" key={index}>
            <ActivityIcon log={log} />
            <Grid type="column" className="ml-10">
              <ActionHasSlo logChanges={logChanges} {...data} />
              <ServiceNowMessages log={log} />

              <MessageBasedonAction
                setFocusMessageId={setFocusMessageId}
                statusPages={statusPages}
                userName={userName}
                data={data}
              />
              <MessageBasedonActionType {...data} />

              {/* add your messages either in  MessageBasedonAction or  MessageBasedonActionType*/}
              {/* if your messages need  multiple action/actionType validation use  MessageBasedOnMultipleAction*/}

              {/* If your message is shown different in UI check useUINavigationFunctionV2 - getDetailsFromLogActivity */}
              <MessageBasedOnMultipleAction
                incident={incident}
                organization={organization}
                {...data}
              />
              <Para fontSize={12} color={theme.shades.grey} className="mb-10">
                {Locale.toFullDateTime(log.time)}
              </Para>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default ActivityMessages;
