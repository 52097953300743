import { Box, HStack, Skeleton, Text } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import useStatuspageComponentTimelineContext from '../hook';
import OverrideSymbol from './OverrideSymbol';
import StatusPageComponentTimeline from './Timeline';
import StatusPageTimelineOverrideForm from './OverrideForm';
import StatuspageOverrideUnsavedChangesModal from './UnsavedChangesModal';

export default function StatusPageComponentTimelineCard() {
  const {
    component,
    timeline,
    timelineIsLoading,
    renderTimeline,
    overrideOpen,
    unsavedModalCallback,
  } = useStatuspageComponentTimelineContext();

  return (
    <Skeleton
      isLoaded={!timelineIsLoading}
      startColor={THEME_COLORS.secondary[100]}
      endColor={THEME_COLORS.secondary[200]}
    >
      <Box border={`1px solid ${THEME_COLORS.secondary[200]}`} borderRadius="6px" padding="16px">
        <HStack justify="space-between" align="center">
          <Text fontSize="16px" fontWeight="400" color={THEME_COLORS.secondary[950]}>
            {component.name}
          </Text>
          <Text fontSize="14px" fontWeight="400" color={component.status?.color}>
            {component.status?.name}
          </Text>
        </HStack>
        {renderTimeline && <StatusPageComponentTimeline timelineData={timeline?.data || []} />}
        {overrideOpen && <StatusPageTimelineOverrideForm />}
        <HStack justify="space-between">
          <Text fontSize="12px" fontWeight="400" color={THEME_COLORS.secondary[700]}>
            Showing timeline for last 90 days
          </Text>
          <HStack>
            <OverrideSymbol size={6} />
            <Text
              fontSize="12px"
              fontWeight="400"
              color={THEME_COLORS.secondary[700]}
              style={{ marginLeft: '4px' }}
            >
              Timeline overridden
            </Text>
          </HStack>
        </HStack>
        <StatuspageOverrideUnsavedChangesModal callback={unsavedModalCallback} />
      </Box>
    </Skeleton>
  );
}
