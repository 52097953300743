import { MenuItem, useBoolean } from '@chakra-ui/react';
import { Grid } from 'uie/components';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import { IPricingEntity } from 'core/interfaces/IBillingPlan';
import { CrownSmallIcon } from 'icons';
import { FC, ReactNode } from 'react';

type Props = {
  organization: Pick<IAppState, 'organization'>;
  isDisabled: boolean;
  children: ReactNode;
  planName: IPricingEntity;
  onClick: () => void;
  limit?: number | 'unlimited';
  renewable?: boolean;
};

export const MenuItemWithUpgradeFlow: FC<Props> = ({
  organization,
  isDisabled,
  children,
  planName,
  onClick,
  limit = 0,
  renewable,
}) => {
  const [showModal, setShowModal] = useBoolean();
  const org = {
    organization,
  } as unknown as Pick<IAppState, 'organization'>;

  return (
    <>
      <MenuItem onClick={isDisabled ? setShowModal.on : onClick}>
        <Grid alignItems="center" justifyContent="center">
          {children}
          {isDisabled && <CrownSmallIcon className="ml-5 pro" />}
        </Grid>
      </MenuItem>
      <UpgradePlanModal
        hasBillingPermission={BillingService.hasManageBillingPermission(org)}
        onCancel={() => {
          setShowModal.off();
        }}
        showModal={showModal}
        message={BillingService.getMessage(limit, planName, org, renewable)}
        header={BillingService.getHeader(limit, planName, org)}
      />
    </>
  );
};
