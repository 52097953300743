import * as React from 'react';
import { Grid, Heading, Para, Tabs, FocusBlock } from 'uie/components';
import {
  IExtensionStatus,
  ISlackChannel,
  ISlackChannelResponse,
} from '../../../../../../core/interfaces/IExtensions';
import SlackAuth from './auth';
import SlackChannelSelect from './channel.select';

interface IProps {
  status: IExtensionStatus;
  selectedChannel: ISlackChannel | null;
  width: number;
  close: () => void;
  integrateError: string | null;
  showEmptyChannelError: boolean;
  resetShowEmptyChannelError: () => void;
  checkAndSetDirty: () => void;
}

type ISlackTab = 'authorize' | 'select_channel';

interface IState {
  selectedTab: ISlackTab;
}

class Slack extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedTab: props.status === 'not_integrated' ? 'authorize' : 'select_channel',
    };
  }

  onTabChange = (selectedTab: string) => this.setState({ selectedTab: selectedTab as ISlackTab });

  get isAuthorized() {
    return this.props.status !== 'not_integrated';
  }

  render() {
    const {
      width,
      close,
      selectedChannel,
      integrateError,
      showEmptyChannelError,
      resetShowEmptyChannelError,
    } = this.props;
    const { selectedTab } = this.state;
    return (
      <>
        <Grid className="mb-20">
          <Grid alignItems="flex-start" type="column">
            <Heading height={35} fontSize={24}>
              Slack Integration
            </Heading>
          </Grid>
        </Grid>

        <Tabs
          initialTab={selectedTab}
          onTabSelect={this.onTabChange}
          shellWidth="fit-content"
          tabWidth={`${width / 2}px`}
          style={{ marginBottom: 30 }}
        >
          <FocusBlock value="authorize">
            <Para>Authorize</Para>
          </FocusBlock>
          <FocusBlock value="select_channel">
            <Para>Select Channel</Para>
          </FocusBlock>
        </Tabs>

        <SlackAuth
          authorized={this.isAuthorized}
          display={selectedTab === 'authorize'}
          close={close}
          integrateError={integrateError}
        />
        <SlackChannelSelect
          authorized={this.isAuthorized}
          display={selectedTab === 'select_channel'}
          close={close}
          selectedChannel={selectedChannel}
          showEmptyChannelError={showEmptyChannelError}
          resetShowEmptyChannelError={resetShowEmptyChannelError}
          checkAndSetDirty={this.props.checkAndSetDirty}
        />
      </>
    );
  }
}
export default Slack;
