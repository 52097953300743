import { Box } from '@chakra-ui/react';
import React from 'react';
import { Grid, Para, TextButton, Theme } from 'uie/components';
import { useIncidentSummaryContext } from '../../../context/hook';

const { theme } = Theme;

const IncidentSummaryDrawerError: React.FC = () => {
  const { refetchSummaryAndOverview } = useIncidentSummaryContext();

  return (
    <Grid height="100%" alignItems="center" justifyContent="center">
      <Box>
        <Para style={{ textAlign: 'center', lineHeight: '27px' }} fontSize={18} fontWeight={400}>
          Some error occured while generating the summary.
        </Para>
        <Para style={{ textAlign: 'center', lineHeight: '27px' }} fontSize={18} fontWeight={400}>
          Please try again
        </Para>
        <TextButton
          color={theme.primary.default}
          style={{ margin: '8px auto 0px', padding: '4px 8px' }}
          onClick={() => {
            refetchSummaryAndOverview();
          }}
        >
          <Para color={theme.shades.white} fontSize={12.5} fontWeight={800}>
            Try Again
          </Para>
        </TextButton>
      </Box>
    </Grid>
  );
};

export default IncidentSummaryDrawerError;
