import Axios from 'axios';
import { API } from 'core';
import {
  IIncidentOverviewResponse,
  IIncidentSummaryFeedbackRequestOptions,
  IIncidentSummaryFeedbackResponse,
  IIncidentSummaryResponse,
  IIncidentSummaryResponseMetadata,
} from 'core/interfaces/IIncidents';

export const getIncidentOverview = (incidentId: string) =>
  Axios.get<{
    data: IIncidentOverviewResponse;
  }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${incidentId}/overview`,
  );

export const getIncidentSummary = (incidentId: string) =>
  Axios.get<{
    data: IIncidentSummaryResponse;
    meta: IIncidentSummaryResponseMetadata;
  }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${incidentId}/summarize`,
  );

export const sendIncidentSummaryFeedback = (
  incidentId: string,
  feedbackOptions: IIncidentSummaryFeedbackRequestOptions,
) =>
  Axios.post<{ data: IIncidentSummaryFeedbackResponse }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${incidentId}/summarize/feedback`,
    feedbackOptions,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
