import React, { memo, useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { IconButton, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { isDisabled } from '@testing-library/user-event/dist/utils';

export const Search = memo(
  ({
    setSearchTerm,
    searchTerm,
    searchEnabled,
    setSearchEnabled,
    isDisabled,
  }: {
    setSearchTerm: (searchText: string) => void;
    searchTerm: string;
    searchEnabled: boolean;
    setSearchEnabled: (isEnable: boolean) => void;
    isDisabled: boolean;
  }) => {
    return (
      <>
        {searchEnabled ? (
          <InputGroup width={'auto'}>
            <InputLeftElement height={8} pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>

            <Input
              autoFocus
              height={8}
              fontSize={14}
              type="text"
              value={searchTerm}
              placeholder="Search for keywords"
              onChange={({ target: { value } }) => setSearchTerm(value)}
              onBlur={({ target: { value } }) => {
                if (!value) {
                  setSearchEnabled(false);
                }
              }}
              isDisabled={isDisabled}
            />
          </InputGroup>
        ) : (
          <IconButton
            aria-label="Search services"
            variant={'icon'}
            icon={<SearchIcon />}
            onClick={() => setSearchEnabled(true)}
          />
        )}
      </>
    );
  },
);
