import {
  Checkbox,
  FocusBlock,
  Grid,
  IconButton,
  InputBlock,
  Para,
  Pill,
  SelectBox,
  Theme,
} from 'uie/components';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService, exception } from 'core';
import { deepCopy } from 'core/helpers';
import { ISCalendar } from 'core/interfaces/ICalendar';
import * as React from 'react';
import { connect } from 'react-redux';
import { GetListOfSchedulesQuery } from 'views/main/organization/schedules/graphql/query';
import { requestOrganizationEscalation } from '../../../../core/actions';
import {
  T_WA_GS_ESCALATION_NOTIFICATION_RULES,
  T_WA_GS_ESCALATION_NOTIFICATION_RULES_CUSTOM,
  T_WA_GS_ESCALATION_POLICY_CREATED,
  T_WA_GS_ESCALATION_POLICY_MODIFIED,
} from '../../../../core/const/tracker';
import { IAppState } from '../../../../core/interfaces/IAppState';
import {
  AcknowledgeReminderState,
  IEntityOwner,
  IEscalationEntity,
  IEscalationPolicy,
  IEscalationPolicyRules,
  IncidentReminderRule,
} from '../../../../core/interfaces/IEscalationPolicy';
import { ISquad } from '../../../../core/interfaces/ISquad';
import { IUsersOfOrganization } from '../../../../core/interfaces/IUserData';
import EscalationService from '../../../../core/services/service.escalation';
import {
  CloseIcon,
  CrownIcon,
  DeleteIcon,
  ProfileIcon,
  ScheduleIcon,
  SquadIcon,
  TeamIcon,
} from '../../../../icons';
import { AppTracker } from '../../../../shared/analytics/tracker';
import { getAssignableTeamMembers } from '../../../../shared/reducers';
import { OwnerEntitiesSelect } from './components/owner_entities_select';
import SortableComponent from './entities.reorder';
import RemindAndRetriggerAcknowledegedIncidents from './remindAcknowledgeIncidents';
import { HStack, VStack, Text } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';

const { theme } = Theme;

interface IProps extends Pick<IAppState, 'organization' | 'userInfo'> {
  showModalType: string;
  hide: () => void;
  escalationPolicy?: IEscalationPolicy;
  checkAndSetDirty: () => void;
  requestOrganizationEscalation: typeof requestOrganizationEscalation;
  schedulesList: GetListOfSchedulesQuery['schedules'];
}

interface IRuleExtension extends IEscalationPolicyRules {
  showDropDown: boolean;
  showViaDropDown: boolean;
  searchString: string;
  viaSearchString: string;
  memberError: boolean;
  timeValidationMessage?: string;
  isViaPersonal: boolean;
  viaError: boolean;
  repetitionError: string;
  repeatAfterRR: number;
}

interface IState {
  name: string;
  description: string;
  entityOwner?: IEntityOwner;
  repetition: number;
  repeatAfter: number;
  rules: IRuleExtension[];
  policyNameError: boolean;
  saving: boolean;
  addRuleError: boolean;
  timeValidationMessage: string;
  networkError: string;
  isMounted: boolean;
  roundRobinAccess: boolean;
  repeatRuleAccess: boolean;
  showUpgradeModal: boolean;
  remindAcknowledgmentData: AcknowledgeReminderState[];
  enableIncidentReminders: boolean;
  enableIncidentRetrigger: boolean;
  retriggerAfter: number;
}

class EscalationPolicyModal extends React.Component<IProps, IState> {
  private _escalationService = new EscalationService();
  public via = ['Email', 'Push', 'SMS', 'Phone'];

  constructor(props: IProps) {
    super(props);
    this.state = {
      name: this.props.showModalType === 'edit' ? this.props.escalationPolicy!.name : '',
      description:
        this.props.showModalType === 'edit' ? this.props.escalationPolicy!.description : '',
      repetition: this.props.showModalType === 'edit' ? this.props.escalationPolicy!.repetition : 0,
      rules:
        this.props.showModalType === 'edit'
          ? this.props.escalationPolicy!.rules.map(rule => {
              return {
                showDropDown: false,
                showViaDropDown: false,
                searchString: '',
                viaSearchString: '',
                memberError: false,
                isViaPersonal: rule.via.length === 0 ? true : false,
                viaError: false,
                repetitionError: '',
                repeatAfterRR: rule.repeat_after,
                ...rule,
              };
            })
          : [
              {
                entities: [],
                via: [],
                escalationTime: 5,
                showDropDown: false,
                showViaDropDown: false,
                searchString: '',
                viaSearchString: '',
                memberError: false,
                isViaPersonal: true,
                viaError: false,
                roundrobin_enabled: false,
                roundrobin_next_index: 0,
                escalate_within_roundrobin: false,
                repetition: 0,
                repeat_after: 0,
                repeatAfterRR: 1,
                repetitionError: '',
              },
            ],
      policyNameError: false,
      saving: false,
      addRuleError: false,
      timeValidationMessage: '',
      networkError: '',
      isMounted: false,
      repeatAfter: this.props.escalationPolicy?.repeat_after || 0,
      roundRobinAccess: this.props.organization.plan.p?.rules['roundrobin-escalation']
        ? true
        : false,
      repeatRuleAccess: this.props.organization.plan.p?.rules['repeat-esc-policy-rule']
        ? true
        : false,
      showUpgradeModal: false,
      remindAcknowledgmentData: [],
      enableIncidentReminders: false,
      enableIncidentRetrigger: false,
      entityOwner: this.props.escalationPolicy?.entity_owner ?? {
        id: this.props.organization.currentUser.u?.id ?? '',
        type: 'user',
      },
      retriggerAfter: 0,
    };
  }

  getCalendarMapping = () => {
    return this.props.organization.calendar.c.reduce(
      (c: { [key: string]: ISCalendar }, n: ISCalendar) => {
        c[n.id] = n;
        return c;
      },
      {},
    );
  };

  getUsersMapping = () => {
    const organization = this.props.organization;
    return getAssignableTeamMembers(organization.selectedTeam.team!, organization.users.u).reduce(
      (c: { [key: string]: IUsersOfOrganization }, n: IUsersOfOrganization) => {
        c[n.id] = n;
        return c;
      },
      {},
    );
  };

  getSquadsMapping = () => {
    return this.props.organization.squads.s.reduce((c: { [key: string]: ISquad }, n: ISquad) => {
      c[n.id] = n;
      return c;
    }, {});
  };

  public oCalender = this.getCalendarMapping();
  public oUsers = this.getUsersMapping();
  public oSquads = this.getSquadsMapping();

  addEntity = (ruleIndex: number, id: string, type: IEscalationEntity['type']) => {
    this.setState({
      rules: this.state.rules.map((rule, index) => {
        if (index === ruleIndex) {
          rule.entities = [
            ...rule.entities,
            {
              type,
              ...(type === 'schedulev2' ? { pid: Number.parseInt(id) } : { id }),
            },
          ];
          rule.roundrobin_next_index = 0;
          rule.searchString = '';
          rule.memberError = false;
        }
        return rule;
      }),
    });
  };

  removeEntity = (ruleIndex: number, id: string, pid?: number) => {
    this.setState({
      rules: this.state.rules.map((rule, index) => {
        if (index === ruleIndex) {
          rule.entities = rule.entities.filter(entity =>
            pid ? entity.pid !== pid : entity.id !== id,
          );
          rule.roundrobin_next_index = 0;
        }
        return rule;
      }),
    });
    this.props.checkAndSetDirty();
  };

  updateEntityList = (ruleIndex: number, entities: IEscalationEntity[]) => {
    this.setState({
      rules: this.state.rules.map((rule, index) => {
        if (index === ruleIndex) {
          rule.entities = entities;
          rule.roundrobin_next_index = 0;
        }
        return rule;
      }),
    });
    this.props.checkAndSetDirty();
  };

  removeFromVia = (index: number, mediumIndex: number) => {
    this.setState({
      rules: this.state.rules.map((rule, i) => {
        if (i === index) {
          rule.via = rule.via.filter((_, mi) => mi !== mediumIndex);
        }
        return rule;
      }),
    });
    this.props.checkAndSetDirty();
  };

  changeSearchString = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const rules = this.state.rules;
    rules[index].searchString = event.target.value;
    this.setState({
      rules,
    });
  };

  changeViaSearchString = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const rules = this.state.rules;
    rules[index].viaSearchString = event.target.value;
    this.setState({
      rules,
    });
  };

  changeEscalationTime = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const rules = this.state.rules;
    rules[index].escalationTime = parseInt(event.target.value);
    rules[index].timeValidationMessage = '';
    this.setState({
      rules,
    });
    this.props.checkAndSetDirty();
  };

  removeRule = (index: number) => {
    this.setState({
      rules: this.state.rules.filter((_, i) => i !== index),
    });
    this.props.checkAndSetDirty();
  };

  addRule = () => {
    const rules = this.state.rules;
    rules.push({
      entities: [],
      via: [],
      escalationTime: 5,
      showDropDown: false,
      showViaDropDown: false,
      searchString: '',
      viaSearchString: '',
      memberError: false,
      isViaPersonal: true,
      viaError: false,
      roundrobin_enabled: false,
      roundrobin_next_index: 0,
      escalate_within_roundrobin: false,
      repetition: 0,
      repeat_after: 0,
      repeatAfterRR: 1,
      repetitionError: '',
    });
    this.setState({
      rules,
      addRuleError: false,
    });
    this.props.checkAndSetDirty();
  };

  changeEscalationPolicyRepetition = (event: React.ChangeEvent<HTMLInputElement>) => {
    const repetitionValue = parseInt(event.target.value);
    this.setState({
      repetition: repetitionValue,
      timeValidationMessage:
        repetitionValue > 3 ? 'You can only repeat Escalation Policy a maximum of 3 times' : '',
    });
  };

  changeEscalationPolicyRepeatAfter = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      repeatAfter: parseInt(event.target.value),
    });
  };

  changeRuleRepetition = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.state.repeatRuleAccess) {
      this.setState({ showUpgradeModal: true });
      return;
    }
    const val = parseInt(event.target.value);
    this.setState({
      rules: this.state.rules.map((rule, i) => {
        if (i === index) {
          rule.repetition = val;
          rule.repetitionError =
            isNaN(val) || val > 20 ? `repetition could'nt be greater than 20` : '';
        }
        return rule;
      }),
    });
  };

  changeRuleRepeatAfter = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.state.repeatRuleAccess) {
      this.setState({ showUpgradeModal: true });
      return;
    }
    this.setState({
      rules: this.state.rules.map((rule, i) => {
        if (i === index) {
          rule.repeat_after = parseInt(event.target.value);
        }
        return rule;
      }),
    });
  };

  changeRuleRepeatAfterRR = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.state.repeatRuleAccess) {
      this.setState({ showUpgradeModal: true });
      return;
    }
    this.setState({
      rules: this.state.rules.map((rule, i) => {
        if (i === index) {
          rule.repeatAfterRR = parseInt(event.target.value);
        }
        return rule;
      }),
    });
  };

  handleRoundrobinCheck = (i: number) => {
    if (!this.state.roundRobinAccess) {
      this.setState({ showUpgradeModal: true });
      return;
    }
    this.setState({
      rules: this.state.rules.map((rule, index) => {
        if (i === index) {
          rule.roundrobin_enabled = !rule.roundrobin_enabled;
        }
        return rule;
      }),
    });
    this.props.checkAndSetDirty();
  };

  handleWithinRoundrobinEscalationCheck = (i: number) => {
    if (!this.state.roundRobinAccess) {
      this.setState({ showUpgradeModal: true });
      return;
    }
    this.setState({
      rules: this.state.rules.map((rule, index) => {
        if (i === index) {
          rule.escalate_within_roundrobin = !rule.escalate_within_roundrobin;
        }
        return rule;
      }),
    });
    this.props.checkAndSetDirty();
  };

  getRemindAcknowledgementDataHandler = (
    acknowledgementReminders: AcknowledgeReminderState[],
    enableIncidentReminders: boolean,
  ) => {
    this.setState({
      remindAcknowledgmentData: acknowledgementReminders,
      enableIncidentReminders: enableIncidentReminders,
    });
  };

  incidentRetriggerStateHandler = (triggerAfter: number, enableIncidentRetrigger: boolean) => {
    this.setState({
      retriggerAfter: enableIncidentRetrigger ? triggerAfter : 0,
      enableIncidentRetrigger: enableIncidentRetrigger,
    });
  };

  onEntityOwnerChange = (entityOwner: IEntityOwner) => {
    this.setState({ entityOwner: entityOwner });
  };

  save = async () => {
    this.setState({ networkError: '' });

    this.props.organization.squads.s;
    this.props.organization.users.u;
    this.props.organization.selectedTeam.team;

    const remindAcknowledgmentRules = this.state.remindAcknowledgmentData.map(rule => {
      const notifications = rule.notifications;
      const formattedNotifications: string[] = [];
      notifications.forEach(notification => {
        if (notification.isChecked) {
          formattedNotifications.push(notification.type);
        }
      });

      const till = 0;
      const formattedData: IncidentReminderRule = {
        till: till,
        time_interval: +rule.time * 60,
        via: formattedNotifications,
      };

      return formattedData;
    });

    // First check for the name
    if (this.state.name.trim() === '') {
      this.setState({
        policyNameError: true,
      });
      return;
    }

    if (!this.state.rules.length) {
      this.setState({
        addRuleError: true,
      });
      return;
    }

    // Check all the rules for added items
    const rules = this.state.rules;
    for (let i = 0; i < rules.length; i++) {
      if (!rules[i].entities.length) {
        rules[i].memberError = true;
        this.setState({
          rules,
        });

        return;
      }
      if (isNaN(rules[i].escalationTime) || rules[i].escalationTime < 0) {
        rules[i].timeValidationMessage = 'Enter valid time';
        this.setState({
          rules,
        });
        return;
      }

      if (!rules[i].isViaPersonal && rules[i].via.length === 0) {
        rules[i].viaError = true;
        this.setState({ rules });
        return;
      }

      if (rules[i].repeat_after < 0 || rules[i].repeatAfterRR < 0 || rules[i].repetition < 0) {
        rules[i].timeValidationMessage = 'must be greater than or equal to 0';
        this.setState({ rules });
        return;
      }
    }

    if (this.state.repetition < 0 || this.state.repetition > 3 || isNaN(this.state.repetition)) {
      this.setState({
        timeValidationMessage: 'You can only repeat Escalation Policy a maximum of 3 times',
      });
      return;
    }

    this.setState({
      saving: true,
    });
    const data: Partial<IEscalationPolicy> = {
      name: this.state.name,
      description: this.state.description,
      entity_owner: this.state.entityOwner,
      repetition: this.state.repetition,
      repeat_after: this.state.repeatAfter,
      is_using_new_fields: true,
      rules: this.state.rules.map((r: IRuleExtension) => {
        if (r.isViaPersonal) {
          r.via = [];
        }
        r.repeat_after =
          r.roundrobin_enabled && r.escalate_within_roundrobin ? r.repeatAfterRR : r.repeat_after;
        return r;
      }),
      incident_reminder_rules: remindAcknowledgmentRules,
      enable_incident_reminders: this.state.enableIncidentReminders,
      enable_incident_retrigger: this.state.enableIncidentRetrigger,
      retrigger_after: this.state.retriggerAfter,
    };

    try {
      await (this.props.showModalType === 'edit'
        ? this._escalationService.update(this.props.escalationPolicy!.id, data)
        : this._escalationService.create(data));
      this.props.hide();
    } catch (err: any) {
      this.setState({
        networkError: exception.handle('E_CREATE_OR_DELETE_ESCALATION_POLICY', err),
      });
      this.setState({ saving: false });
    } finally {
      this.props.requestOrganizationEscalation();
    }

    this.props.showModalType === 'edit'
      ? AppTracker.track(T_WA_GS_ESCALATION_POLICY_MODIFIED, {
          'Escalation Type': this.state.name,
          'Escalate Repeat Time': this.state.repetition,
        })
      : AppTracker.track(T_WA_GS_ESCALATION_POLICY_CREATED, {
          'Escalation Type': this.state.name,
          'Escalate Repeat Time': this.state.repetition,
        });
    this.state.rules.forEach((rule: IRuleExtension) => {
      rule.isViaPersonal === true
        ? AppTracker.track(T_WA_GS_ESCALATION_NOTIFICATION_RULES, { 'Rules Type': 'Personal' })
        : AppTracker.track(T_WA_GS_ESCALATION_NOTIFICATION_RULES, { 'Rules Type': 'Custom' });
      rule.via.forEach((notificationChannel: string) => {
        AppTracker.track(T_WA_GS_ESCALATION_NOTIFICATION_RULES_CUSTOM, {
          'Custom Notification Type': notificationChannel,
        });
      });
    });
  };

  squadHasUnverifiedUser = (squadId: string): boolean =>
    this.oSquads[squadId].members.some(u => !this.oUsers[u].email_verified);

  squadHasUnverifiedPhoneUser = (squadId: string): boolean =>
    this.oSquads[squadId].members.some(u => !this.oUsers[u].phone_verified);

  public render() {
    const isOnPro =
      BillingService.isSomeProductTrialPlan(this.props) ||
      (BillingService.isFeatureDisabled(this.props, 'roundrobin-escalation') &&
        BillingService.isFeatureDisabled(this.props, 'repeat-esc-policy-rule'));

    const schedulesList = this.props.schedulesList;

    return (
      <div
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <div className="clearfix">
          <div className="float-left">
            <Grid alignItems="center">
              <h1 className="modal-container-heading">
                {this.props.showModalType === 'edit' ? 'Edit' : 'Add'} Escalation Policy
              </h1>
              <Pill className="ml-10">
                <Grid alignItems="center">
                  <TeamIcon height={16} width={16} />
                  <Para className="ml-5">{this.props.organization.selectedTeam.team?.name}</Para>
                </Grid>
              </Pill>
            </Grid>
          </div>
        </div>
        <div className="field-parent-div" style={{ marginTop: 30 }}>
          <p className="modal-container-sub-heading">
            Policy Name <span className="red-color-text">*</span>
          </p>
          <input
            id="policy-name"
            type="text"
            autoComplete="off"
            onFocus={() => {
              this.setState({ policyNameError: false });
            }}
            className={'input-design ' + (this.state.policyNameError ? 'text-field-border' : '')}
            placeholder="Enter Policy Name"
            value={this.state.name}
            onChange={event => {
              this.setState({ name: event.target.value });
              this.props.checkAndSetDirty();
            }}
          />
          <div className="error-block">
            {this.state.policyNameError ? 'Please enter the policy name' : ' '}
          </div>
        </div>
        <div className="field-parent-div">
          <p className="modal-container-sub-heading">Policy Description</p>
          <input
            type="text"
            autoComplete="off"
            className={'input-design '}
            placeholder="Enter Policy Description"
            value={this.state.description}
            onChange={event => {
              this.setState({ description: event.target.value });
              this.props.checkAndSetDirty();
            }}
          />
        </div>
        <div className="field-parent-div" style={{ marginTop: '30px', marginBottom: '30px' }}>
          <p className="modal-container-sub-heading">
            Policy Owner <span className="red-color-text">*</span>{' '}
          </p>
          {this.props.organization?.selectedTeam.team && (
            <OwnerEntitiesSelect
              currentTeam={this.props.organization?.selectedTeam?.team}
              squads={this.props.organization.squads.s}
              users={this.props.organization.users.u}
              entityOwner={this.state.entityOwner}
              onEntityOwnerChange={this.onEntityOwnerChange}
            />
          )}
        </div>
        {this.state.rules.length ? (
          <p className="item-box-central-heading" style={{ margin: 0 }}>
            Rules <span className="red-color-text">*</span>
          </p>
        ) : null}
        {this.state.rules && this.state.rules.length > 0 ? (
          <div>
            {this.state.rules.map((rule, index) => {
              rule.entities = rule.entities || [];
              const users = rule.searchString
                ? Object.values(this.oUsers).filter(
                    user =>
                      !rule.entities
                        .filter(entity => entity.type === 'user')
                        .map(entity => entity.id)
                        .includes(user.id) &&
                      (user.first_name + ' ' + user.last_name)
                        .toLowerCase()
                        .includes(rule.searchString.toLowerCase().trim()),
                  )
                : Object.values(this.oUsers).filter(
                    user =>
                      !rule.entities
                        .filter(entity => entity.type === 'user')
                        .map(entity => entity.id)
                        .includes(user.id),
                  );
              const squads = rule.searchString
                ? Object.values(this.oSquads).filter(
                    s =>
                      !rule.entities
                        .filter(m => m.type === 'squad')
                        .map(entity => entity.id)
                        .includes(s.id) &&
                      s.name.toLowerCase().includes(rule.searchString.toLowerCase().trim()),
                  )
                : Object.values(this.oSquads).filter(
                    s =>
                      !rule.entities
                        .filter(m => m.type === 'squad')
                        .map(entity => entity.id)
                        .includes(s.id),
                  );
              const schedules = rule.searchString
                ? Object.values(this.oCalender).filter(
                    c =>
                      !rule.entities
                        .filter(m => m.type === 'schedule')
                        .map(entity => entity.id)
                        .includes(c.id) &&
                      c.name.toLowerCase().includes(rule.searchString.toLowerCase().trim()),
                  )
                : Object.values(this.oCalender).filter(
                    c =>
                      !rule.entities
                        .filter(m => m.type === 'schedule')
                        .map(entity => entity.id)
                        .includes(c.id),
                  );

              const filteredSchedulesList = rule.searchString
                ? schedulesList?.filter(sch =>
                    sch.name.toLowerCase().includes(rule.searchString.toLowerCase().trim()),
                  )
                : schedulesList;

              const via = rule.viaSearchString
                ? this.via.filter(medium => {
                    return (
                      rule.via.indexOf(medium) === -1 &&
                      medium
                        .toLowerCase()
                        .trim()
                        .indexOf(rule.viaSearchString.toLowerCase().trim()) !== -1
                    );
                  })
                : this.via.filter(medium => {
                    return rule.via.indexOf(medium) === -1;
                  });

              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: theme.shades.whiteSmoke,
                    padding: 10,
                    paddingTop: 5,
                    borderRadius: 5,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <Grid style={{ justifyContent: 'space-between' }}>
                    <div className="rule-partition-1">
                      <p className="user-schedule-squad-heading">Escalate in</p>
                      <Grid alignItems="center">
                        <input
                          id="after-escalation"
                          className={
                            'input-design ' +
                            (rule.timeValidationMessage ? 'text-field-border' : '')
                          }
                          placeholder="#"
                          style={{
                            display: 'inline-block',
                            maxWidth: 60,
                            textAlign: 'center',
                            padding: 0,
                          }}
                          type="number"
                          value={rule.escalationTime}
                          onChange={event => this.changeEscalationTime(index, event)}
                        />
                        &nbsp;
                        <Para fontSize={12}>min(s)</Para>
                      </Grid>
                      <Para fontSize={12} style={{ marginTop: 10 }}>
                        from the time of incident trigger
                      </Para>
                      <div className="error-block">{rule.timeValidationMessage}</div>
                    </div>
                    <div className="rule-partition-2">
                      <div className="rule-member-container field-parent-div">
                        <p className="user-schedule-squad-heading">User or Squad or Schedule</p>
                        <div style={{ position: 'relative' }}>
                          <input
                            id="user-squad-schedule"
                            type="text"
                            autoComplete="off"
                            className={
                              'input-design ' + (rule.memberError ? 'text-field-border' : '')
                            }
                            placeholder="Search for a user or squad or schedule"
                            value={rule.searchString}
                            onChange={event => this.changeSearchString(index, event)}
                            onFocus={() => {
                              const rules = deepCopy(this.state.rules);
                              rules[index].showDropDown = true;
                              this.setState({
                                rules,
                              });
                            }}
                            onBlur={() => {
                              setTimeout(() => {
                                const rules = deepCopy(this.state.rules);
                                rules[index].showDropDown = false;
                                this.setState({
                                  rules,
                                });
                              }, 300);
                            }}
                          />
                          {rule.showDropDown &&
                          (users.length ||
                            squads.length ||
                            schedules.length ||
                            filteredSchedulesList?.length) ? (
                            <div className="search-drop-down" style={{ zIndex: 1001 }}>
                              {users.length ? (
                                <div>
                                  <div className="search-drop-down-category-heading">Users</div>
                                  {users.map((user, userIndex) => {
                                    return (
                                      <div
                                        onClick={() => {
                                          this.addEntity(index, user.id, 'user');
                                        }}
                                        className="search-drop-down-item-content no-select cursor-pointer"
                                        key={userIndex}
                                        style={{ padding: 10 }}
                                      >
                                        <Grid>
                                          <VStack alignItems={'left'}>
                                            <HStack>
                                              <ProfileIcon height={12} width={12} />
                                              <Text isTruncated maxWidth={'40ch'}>
                                                {user.first_name + ' ' + user.last_name}
                                              </Text>
                                            </HStack>
                                            <Text
                                              style={{
                                                marginLeft: '20px',
                                                color: THEME_COLORS.secondary[1200],
                                              }}
                                              maxW={'40ch'}
                                            >
                                              {user.username_for_display}
                                            </Text>
                                          </VStack>
                                        </Grid>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                              {squads.length ? (
                                <div>
                                  <div className="search-drop-down-category-heading">Squads</div>
                                  {squads.map((squad, squadIndex) => {
                                    return (
                                      <div
                                        onClick={() => {
                                          this.addEntity(index, squad.id, 'squad');
                                        }}
                                        className="search-drop-down-item-content no-select cursor-pointer"
                                        style={{ padding: 10 }}
                                        key={squadIndex}
                                      >
                                        <Grid alignItems="center">
                                          <SquadIcon height={12} width={12} />
                                          <Para className="ml-10" fontSize={14}>
                                            {squad.name}
                                          </Para>
                                        </Grid>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                              {schedules.length || filteredSchedulesList?.length ? (
                                <div>
                                  <div className="search-drop-down-category-heading">Schedules</div>
                                  {schedules.map((schedule, scheduleIndex) => {
                                    return (
                                      <div
                                        onClick={() => {
                                          this.addEntity(index, schedule.id, 'schedule');
                                        }}
                                        className="search-drop-down-item-content no-select cursor-pointer"
                                        style={{ padding: 10 }}
                                        key={scheduleIndex}
                                      >
                                        <Grid alignItems="center">
                                          <ScheduleIcon height={12} width={12} />
                                          <Para className="ml-10" fontSize={14}>
                                            {schedule.name}
                                          </Para>
                                        </Grid>
                                      </div>
                                    );
                                  })}
                                  {filteredSchedulesList?.map((schedule, scheduleIndex) => {
                                    return (
                                      <div
                                        onClick={() => {
                                          this.addEntity(index, `${schedule.ID}`, 'schedulev2');
                                        }}
                                        className="search-drop-down-item-content no-select cursor-pointer"
                                        style={{ padding: 10 }}
                                        key={scheduleIndex}
                                      >
                                        <Grid alignItems="center">
                                          <ScheduleIcon height={12} width={12} />
                                          <Para className="ml-10" fontSize={14}>
                                            {schedule.name}
                                          </Para>
                                        </Grid>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                        {rule.entities.length ? null : (
                          <div className="error-block">
                            {rule.memberError ? 'Please add a user or squad or schedule' : ''}
                          </div>
                        )}

                        <div
                          className="rule-members clearfix"
                          style={{ margin: 5, display: 'flex', flexWrap: 'wrap' }}
                        >
                          <SortableComponent
                            entities={rule.entities.map((member, i) => {
                              let emailUnverified = false;
                              let phoneUnverified = false;
                              let name = 'Deleted User';
                              switch (member.type) {
                                case 'user':
                                  emailUnverified = !(
                                    this.oUsers[member.id as string]?.email_verified ?? false
                                  );
                                  phoneUnverified =
                                    (rule.via.includes('SMS') || rule.via.includes('Phone')) &&
                                    !(this.oUsers[member.id as string]?.phone_verified ?? false);
                                  const user = this.oUsers[member.id as string];
                                  if (user) {
                                    name = user.first_name + ' ' + user.last_name;
                                    if (user.username_for_display) {
                                      name += ' (' + user.username_for_display + ')';
                                    }
                                  }
                                  break;
                                case 'squad':
                                  emailUnverified = this.squadHasUnverifiedUser(
                                    member.id as string,
                                  );
                                  phoneUnverified =
                                    (rule.via.includes('SMS') || rule.via.includes('Phone')) &&
                                    this.squadHasUnverifiedPhoneUser(member.id as string);
                                  name = this.oSquads[member.id as string]?.name || 'Deleted Squad';
                                  break;
                                case 'schedule':
                                  name =
                                    this.oCalender[member.id as string]?.name || 'Deleted Calendar';
                                  break;
                                case 'schedulev2':
                                  name =
                                    schedulesList?.find(sch => sch.ID === member.pid)?.name ||
                                    'Deleted Schedule';
                                  break;
                              }
                              return {
                                type: member.type,
                                id: member.id,
                                pid: member.pid,
                                name: name,
                                email_verified: !emailUnverified,
                                phone_verified: !phoneUnverified,
                                highlight:
                                  rule.roundrobin_enabled && i === rule.roundrobin_next_index
                                    ? true
                                    : false,
                              };
                            })}
                            updateEntityList={this.updateEntityList}
                            removeEntity={this.removeEntity}
                            ruleIndex={index}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="rule-partition-3">
                      <div className="rule-member-container field-parent-div">
                        <p className="user-schedule-squad-heading">Notification Rules</p>
                        <div id="notification_type_select">
                          <SelectBox
                            height="auto"
                            width="120px"
                            hook={
                              <Para style={{ textAlign: 'left', fontSize: 14 }}>
                                {rule.isViaPersonal ? 'Personal' : 'Custom'}
                              </Para>
                            }
                            onValueChange={(_, v) => {
                              const rules = deepCopy(this.state.rules);
                              rules[index].isViaPersonal = v;
                              this.setState({
                                rules,
                              });
                            }}
                          >
                            <FocusBlock
                              className="rule-notification-type-select-item"
                              value={true}
                              isSelected={rule.isViaPersonal}
                            >
                              <Para fontSize={12}> Personal</Para>
                            </FocusBlock>
                            <FocusBlock
                              className="rule-notification-type-select-item"
                              value={false}
                              isSelected={!rule.isViaPersonal}
                            >
                              <Para fontSize={12}> Custom</Para>
                            </FocusBlock>
                          </SelectBox>
                        </div>
                      </div>
                    </div>
                    <div className="rule-partition-4">
                      {!rule.isViaPersonal && (
                        <div className="rule-member-container field-parent-div">
                          <div style={{ position: 'relative' }}>
                            <input
                              id="user-squad-schedule"
                              type="text"
                              autoComplete="off"
                              className="input-design"
                              placeholder="Channel"
                              style={{ padding: '0 17px' }}
                              value={rule.viaSearchString}
                              onChange={event => this.changeViaSearchString(index, event)}
                              onFocus={() => {
                                const rules = deepCopy(this.state.rules);
                                rules[index].showViaDropDown = true;
                                this.setState({
                                  rules,
                                });
                              }}
                              onBlur={() => {
                                setTimeout(() => {
                                  const rules = deepCopy(this.state.rules);
                                  rules[index].showViaDropDown = false;
                                  this.setState({
                                    rules,
                                  });
                                }, 300);
                              }}
                              disabled={rule.isViaPersonal}
                            />
                            {rule.viaError && <div className="error-block">Via can't be empty</div>}

                            {rule.showViaDropDown && via.length ? (
                              <div className="search-drop-down">
                                <div>
                                  <div className="search-drop-down-category-heading">Via</div>
                                  {via.map((medium, mediumIndex) => {
                                    return (
                                      <div
                                        onClick={() => {
                                          const rules = deepCopy(this.state.rules);
                                          rules[index].via.push(medium);
                                          rules[index].viaSearchString = '';
                                          this.setState({
                                            rules,
                                          });
                                        }}
                                        className="search-drop-down-item-content no-select cursor-pointer"
                                        key={mediumIndex}
                                        style={{
                                          padding: 5,
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <img
                                          src={`/icons/greys/${
                                            medium.toLowerCase() === 'push'
                                              ? 'notification'
                                              : medium.toLowerCase()
                                          }.svg`}
                                          alt={medium}
                                          style={{
                                            width: 12,
                                            height: 12,
                                            marginLeft: 10,
                                            marginRight: 10,
                                          }}
                                        />
                                        <Para fontSize={14}>{medium}</Para>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div
                            style={{
                              marginTop: 5,
                              display: 'flex',
                              flexWrap: 'wrap',
                            }}
                          >
                            {rule.via.map((medium, mediumIndex) => {
                              return (
                                <div
                                  key={mediumIndex}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: 5,
                                    padding: '4px 8px',
                                    backgroundColor: theme.shades.white,
                                    height: 'fit-content',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  <img
                                    src={`/icons/greys/${
                                      medium.toLowerCase() === 'push'
                                        ? 'notification'
                                        : medium.toLowerCase()
                                    }.svg`}
                                    className="item-member-icon"
                                    style={{ marginRight: 5 }}
                                    alt={medium}
                                  />
                                  <Para fontSize={12}>{medium}</Para>
                                  &nbsp; &nbsp;
                                  <CloseIcon
                                    height={14}
                                    width={14}
                                    stroke={theme.shades.cement}
                                    onClick={() => this.removeFromVia(index, mediumIndex)}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="rule-partition-5">
                      {this.state.rules.length > 1 ? (
                        <IconButton
                          onClick={() => this.removeRule(index)}
                          style={{ marginTop: 10 }}
                        >
                          <DeleteIcon height={20} width={20} color={theme.danger.default} />
                        </IconButton>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid alignContent="center" style={{ margin: 5 }}>
                    <Para style={{ fontSize: 14 }}>
                      <span className="font-bold">Advanced</span>
                    </Para>
                    {isOnPro ? <CrownIcon style={{ marginLeft: '2px' }} /> : ''}
                  </Grid>
                  {
                    <>
                      {
                        <Grid alignContent="center" style={{ margin: 5, marginLeft: 20 }}>
                          <Checkbox
                            checked={rule.roundrobin_enabled}
                            onChange={() => this.handleRoundrobinCheck(index)}
                          />
                          <Para style={{ marginLeft: 10 }} fontSize={14}>
                            Enable <span style={{ fontWeight: 600 }}>Round Robin assignment</span>{' '}
                            for this layer
                          </Para>
                        </Grid>
                      }
                      {rule.roundrobin_enabled && (
                        <Grid alignContent="center" style={{ margin: 5, marginLeft: 20 }}>
                          <Checkbox
                            checked={rule.escalate_within_roundrobin}
                            onChange={() => this.handleWithinRoundrobinEscalationCheck(index)}
                          />
                          <Para style={{ marginLeft: 10 }} fontSize={14}>
                            Enable rotation within the Assignment Ring
                          </Para>
                        </Grid>
                      )}

                      {rule.roundrobin_enabled && rule.escalate_within_roundrobin && (
                        <Grid alignItems="center" style={{ margin: 5, marginLeft: 50 }}>
                          <Para fontSize={14}>
                            {' '}
                            Rotate within the Assignment Ring with a gap of{' '}
                          </Para>
                          <InputBlock
                            type="number"
                            placeholder="#"
                            style={{
                              maxWidth: 60,
                              margin: '0px 10px',
                              height: 30,
                              padding: 0,
                              textAlign: 'center',
                            }}
                            width={50}
                            value={rule.repeatAfterRR}
                            onChange={e => this.changeRuleRepeatAfterRR(index, e)}
                          />{' '}
                          <Para fontSize={14}> min(s)</Para>
                        </Grid>
                      )}

                      {!rule.roundrobin_enabled && (
                        <Grid alignItems="center" style={{ margin: 5, marginLeft: 20 }}>
                          <Para fontSize={14}>
                            If no one acknowledges, repeat this layer an additional{' '}
                          </Para>
                          <InputBlock
                            type="number"
                            placeholder="#"
                            style={{
                              maxWidth: 60,
                              margin: '0px 10px',
                              height: 30,
                              padding: 0,
                              textAlign: 'center',
                            }}
                            width={50}
                            value={rule.repetition}
                            onChange={e => this.changeRuleRepetition(index, e)}
                          />
                          <Para fontSize={14}>time(s) after</Para>
                          <InputBlock
                            type="number"
                            placeholder="#"
                            style={{
                              maxWidth: 60,
                              margin: '0px 10px',
                              height: 30,
                              padding: 0,
                              textAlign: 'center',
                            }}
                            width={50}
                            value={rule.repeat_after}
                            onChange={e => this.changeRuleRepeatAfter(index, e)}
                          />{' '}
                          <Para fontSize={14}>min(s)</Para>
                        </Grid>
                      )}
                    </>
                  }
                </div>
              );
            })}
          </div>
        ) : null}
        {this.state.rules.length < 12 ? (
          <div
            className="item-box-blue-text no-select"
            style={{ cursor: 'pointer', marginTop: 20, width: 'fit-content' }}
            onClick={this.addRule}
          >
            + Add Rule
          </div>
        ) : null}
        <div className="error-block">
          {this.state.addRuleError ? 'Please add at least one rule' : ''}
        </div>
        <div
          style={{
            backgroundColor: '#f3f4f6',
            marginTop: 20,
            paddingLeft: 10,
            paddingRight: 30,
            marginBottom: 20,
            boxSizing: 'border-box',
          }}
        >
          <div className="item-box-tagline" style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Grid justifyContent="flex-start" type="column">
              <Grid alignContent="center" style={{ margin: 5, marginLeft: 0 }}>
                <Para style={{ fontSize: 14, fontWeight: 600, fontFamily: 'Mulish' }}>
                  <span className="font-bold">Action for unacknowledged incidents</span>
                </Para>
              </Grid>
              <Grid alignContent="center" style={{ margin: 5, marginLeft: 0, marginTop: 8 }}>
                Repeat this policy an additional{' '}
                <InputBlock
                  type="number"
                  id="something-2"
                  placeholder="#"
                  style={{
                    maxWidth: 60,
                    margin: 10,
                    marginTop: -5,

                    height: 30,
                    padding: 0,
                    textAlign: 'center',
                  }}
                  width={50}
                  value={this.state.repetition}
                  onChange={this.changeEscalationPolicyRepetition}
                />{' '}
                time(s)
                {
                  <>
                    {' '}
                    after
                    <InputBlock
                      type="number"
                      id="something-1"
                      placeholder="#"
                      style={{
                        maxWidth: 60,
                        margin: 10,
                        marginTop: -5,
                        height: 30,
                        padding: 0,
                        textAlign: 'center',
                      }}
                      width={50}
                      value={this.state.repeatAfter}
                      onChange={this.changeEscalationPolicyRepeatAfter}
                    />{' '}
                    min(s)
                  </>
                }
              </Grid>
            </Grid>
            {this.state.timeValidationMessage != '' && (
              <div className="error-block" style={{ paddingLeft: '320px', paddingTop: '5px' }}>
                {this.state.timeValidationMessage}
              </div>
            )}
          </div>
        </div>

        <RemindAndRetriggerAcknowledegedIncidents
          sendRemindAcknowledgementDataToEscPolicy={this.getRemindAcknowledgementDataHandler}
          remindAcknowledgeIncidentDataFromEscPolic={
            this.props.escalationPolicy?.incident_reminder_rules
          }
          isRemindAcknowledgeIncidentEnabled={
            this.props.escalationPolicy?.enable_incident_reminders || false
          }
          enableIncidentRetrigger={this.props.escalationPolicy?.enable_incident_retrigger || false}
          retriggerAfter={this.props.escalationPolicy?.retrigger_after || 0}
          retriggerHandler={this.incidentRetriggerStateHandler}
        />

        {this.state.networkError !== '' && (
          <div className="error-block" style={{ marginTop: 5, marginBottom: 10 }}>
            {this.state.networkError}
          </div>
        )}

        <button className="main-button no-select" onClick={this.save}>
          {this.state.saving ? <i className="fa fa-spinner fa-spin" /> : 'Save'}
        </button>
        <UpgradePlanModal
          hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
          showModal={this.state.showUpgradeModal}
          message={BillingService.getMessage(0, 'roundrobin-escalation', this.props)}
          onCancel={() => {
            this.setState({ showUpgradeModal: false });
          }}
          header={BillingService.getHeader(0, 'roundrobin-escalation', this.props)}
          onUpgrade={() => this.setState({ showUpgradeModal: false })}
        />
      </div>
    );
  }
}

export default connect(
  (state: IAppState) => ({
    organization: state.organization,
    userInfo: state.userInfo,
  }),
  { requestOrganizationEscalation },
)(EscalationPolicyModal);
