import React, { Component, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../core/interfaces/IAppState';
import { SnackContext, Grid, Para, SnackBar, TextButton } from 'uie/components';
import cx from 'classnames';
import { resetAppLimits } from '../../core/actions/app/limits';
import { calcRoutes, render } from './render.index';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import './index.css';
import { exception } from '../../core/exception';
import { AppTracker } from '../../shared/analytics/tracker';
import { checkIfActionChanged } from 'core/helpers';
import { BillingService, updatedUnleashContext } from 'core';
import { Locale } from 'core/helpers/dateUtils';
import { useUnleashContext } from '@unleash/proxy-client-react';
import withUnleash from './withUnleash';
import { UserAccessContextValue, withUserAccess } from 'core/userAccess/UserAccessContext';

type IMix = Pick<IAppState, 'auth' | 'organization' | 'APP_CONFIG' | 'APP_PREVIEW'> &
  RouteComponentProps;
interface IProps extends IMix {
  networkState: 'online' | 'offline';
  isRefresh: boolean;
  featureEnabled?: { workflowEnabled: boolean };
  userAccess: UserAccessContextValue;
}

interface IState {
  notOnSettingsPage: boolean;
  isProductTrialExpired: boolean;
  showUpgradeModal: boolean;
  showMyOnCall: boolean;
}

export class OrganizationRoutesComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      notOnSettingsPage: false,
      isProductTrialExpired: false,
      showUpgradeModal: false,
      showMyOnCall: false,
    };
  }

  async componentDidUpdate(prevProps: IProps) {
    if (this.props.history.location.pathname == '/settings/billings') {
      if (this.state.showUpgradeModal) {
        this.setState({ showUpgradeModal: false });
      }
    } else if (this.props.history.location.pathname !== '/') {
      if (this.state.isProductTrialExpired && !this.state.showUpgradeModal) {
        this.setState({ showUpgradeModal: true });
      }
    } else {
      if (this.state.showUpgradeModal) {
        this.setState({ showUpgradeModal: false });
      }
    }
  }
  async componentDidMount() {
    await this.setState({
      isProductTrialExpired: BillingService.isProductTrialExpired(this.props),
    });
    await this.setState({
      notOnSettingsPage: this.props.history.location.pathname !== '/settings/billings',
    });
    if (this.state.isProductTrialExpired && this.state.notOnSettingsPage) {
      this.setState({ showUpgradeModal: true });
    } else {
      this.setState({ showUpgradeModal: false });
    }

    if (
      this.props.organization.currentOrg.o?.config['show_default_owner_filters'] &&
      !this.state.showMyOnCall
    ) {
      this.setState({ showMyOnCall: true });
    }

    if (this.state.showUpgradeModal) {
      return <Redirect to="/" push={true} />;
    }
    const last10: string[] = [];
    document.addEventListener('keydown', (ev: KeyboardEvent) => {
      last10.push(ev.key);

      if (
        last10.join('') ===
        'ArrowUpArrowUpArrowDownArrowDownArrowLeftArrowRightArrowLeftArrowRightba'
      ) {
        AppTracker.track('E_EST_EGG');
        exception.handle('E_EST_EGG', {
          response: {
            status: 402,
            data: {
              meta: {
                error_details: {
                  description: 'I have 99 incidents \n I resolve 1, got 147 more...',
                },
                error_message: '99 incidents',
              },
            },
          },
        });
      }
    });
  }

  calcRoutes = calcRoutes;
  render = render;

  shouldComponentUpdate(nextProps: IProps) {
    if (checkIfActionChanged(this.props.auth, nextProps.auth, 'action', 'REQUEST_USER_LOGOUT')) {
      return true;
    }

    if (
      checkIfActionChanged(
        this.props.organization.acl,
        nextProps.organization.acl,
        'action',
        'REQUEST_ORG_USER_ACL_SUCCESS',
      )
    ) {
      return true;
    }

    if (this.props.isRefresh && nextProps.isRefresh) {
      return false;
    }

    return true;
  }
}

const OrganizationRoutesComponentConnect = connect(
  ({ auth, organization, APP_PREVIEW, APP_CONFIG }: IAppState) => ({
    auth,
    organization,
    APP_PREVIEW,
    APP_CONFIG,
  }),
)(withUserAccess(OrganizationRoutesComponent));

interface IOrganizationRoutesHoc extends Pick<IAppState, 'organization' | 'APP_LIMITS'> {
  resetAppLimits: () => void;
}

const OrganizationRoutsHoc = (props: IOrganizationRoutesHoc) => {
  const currentUser = props.organization.currentUser.u;
  const currentUserRole = props.organization.currentUser.u?.role;
  const _createSnack = SnackContext();
  const updateContext = useUnleashContext();

  useEffect(() => {
    updatedUnleashContext();
    // context is updated with userId
    updateContext({ userId: props.organization.currentOrg.o?.slug ?? '' });
  }, [props.organization.currentOrg.o?.slug]);

  const {
    APP_LIMITS: { message, description, time },
    resetAppLimits,
  } = props;

  const emptyLimits = useCallback(() => {
    _createSnack(null);
    resetAppLimits();
  }, [_createSnack, resetAppLimits]);

  const createSnack = useCallback(() => {
    if (message === '' || time === -1) return;
    const isAccountOwner = currentUserRole === 'account_owner';

    _createSnack(
      <SnackBar maxWidth="400px">
        <Grid alignItems="flex-start" type="column">
          <Para
            color="var(--shades-white)"
            fontWeight={500}
            className="mt-10 global--capitalize-first-letter"
          >
            {message}
          </Para>
          <Para
            color="var(--shades-white)"
            fontSize={12}
            className="mt-5 global--capitalize-first-letter"
          >
            {description}
          </Para>
          <Grid className="mt-20 mb-10" alignItems="center">
            {isAccountOwner && (
              <a href="/settings/billings">
                <TextButton buttonType="normal" color="var(--shades-white)" onClick={emptyLimits}>
                  <Para color="var(--shades-cement)">Upgrade</Para>
                </TextButton>
              </a>
            )}
            <TextButton
              buttonType="ghost"
              className={cx('global--capitalize-first-letter', isAccountOwner ? 'ml-10' : '')}
              onClick={emptyLimits}
            >
              <Para color="var(--shades-white)">{isAccountOwner ? 'Not now' : 'Close'}</Para>
            </TextButton>
          </Grid>
        </Grid>
      </SnackBar>,
    );
  }, [time, message, _createSnack, emptyLimits, description, currentUserRole]);

  const [time_zone, date_format] = [currentUser?.time_zone, currentUser?.date_format] || [];

  if (time_zone) {
    Locale.userZone = time_zone;
  }
  if (date_format) {
    Locale.dateFormat = date_format;
  }

  useEffect(() => {
    if (time !== -1) {
      createSnack();
      setTimeout(emptyLimits, 5000);
    } else {
      _createSnack(null);
    }
    return () => _createSnack(null);
  }, [time, _createSnack, createSnack, resetAppLimits, emptyLimits]);

  return <OrganizationRoutesComponentConnect {...(props as any)} />;
};

const OrganizationRoutes = withUnleash(
  withRouter(
    connect(
      ({ APP_LIMITS, organization }: IAppState) => ({
        APP_LIMITS,
        organization,
      }),
      { resetAppLimits },
    )(withUserAccess(OrganizationRoutsHoc)),
  ),
);

export { OrganizationRoutes };
