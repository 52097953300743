import React from 'react';
import { SPLIT_WORD_MAP } from '../../incidentDetails/constants';
import { GetEntityComponentProps, JSXResponseForEntity } from '../helpers';

const ResponsesForWfSloWebhook: React.FC<Pick<JSXResponseForEntity, 'getEntityComponent' | 'log'>> =
  ({ log, getEntityComponent }) => {
    let splitWord = '';
    splitWord = SPLIT_WORD_MAP[log.actionType as keyof typeof SPLIT_WORD_MAP];
    if (log.actionType.startsWith('slo_')) splitWord = ' has ';
    if (log.actionType.includes('slo_error_budget')) splitWord = ' has ';

    if (log.actionType.startsWith('workflow_triggered')) splitWord = ' has been';
    if (log.actionType.startsWith('wf')) splitWord = 'Workflow';
    let entityName = log.reason.split(splitWord)[0];

    if (
      log.actionType.startsWith('Webhook_') ||
      (log.actionType.startsWith('wf') && log.assignedTo === 'workflow')
    ) {
      entityName = `Workflow (${log.additionalInfo?.workflow_name as string})`;
    }
    const entityProps: GetEntityComponentProps =
      log.assignedTo === 'workflow'
        ? {
            componentType: 'linkWPopover' as const,
            id: log.id,
            name: `${entityName}`,
            type: 'workflow' as const,
            workflowId: log.workflow_id,
            workflowExecutionId: log.additionalInfo?.wf_exec_id,
            workflowActionIndex: log.additionalInfo?.action_exec_id,
          }
        : {
            componentType: 'linkWPopover' as const,
            id: log.id,
            name: entityName,
            type: 'user' as const,
          };
    return entityName !== 'Deleted Entity' ? getEntityComponent(entityProps) : <>{entityName}</>;
  };

export default ResponsesForWfSloWebhook;
