import axios from 'axios';
import { API } from '../api';
import { IStatusPage, IStatusPageResponse } from '../interfaces/IStatusPage';
import { ITeamOwner, ITeamEntityBasicResponse } from '../interfaces/ITeams';

interface IUpdateStatusPage {
  id: string;
  name: string;
  url: string;
  tls: {
    publicCertificate: string;
    privateKey: string;
  };
  services: {
    serviceId: string;
    alias: string;
  }[];
}

class StatusPageService {
  private organizationId = API.config.organizationId;
  private apiNew = `${API.config.batman}/organizations/${this.organizationId}/statuspages`;

  public getStatus = (statusUrl: string) =>
    axios.get<{ data: IStatusPageResponse }>(`${statusUrl}/api/v1/status`);

  getAll = () => axios.get<{ data: IStatusPage[] }>(`${this.apiNew}?owner_id=${API.config.teamId}`);

  public getStatusPage = (pageId: string) =>
    axios.get<{ data: IStatusPage }>(`${this.apiNew}/${pageId}`);

  public createStatusPage = (payload: Pick<IStatusPage, 'name' | 'url' | 'services'>) =>
    axios.post<{ data: IStatusPage }>(this.apiNew, {
      owner_id: API.config.teamId,
      ...payload,
    });

  public updateStatusPage = (payload: IUpdateStatusPage | IStatusPage) =>
    axios.put<{ data: IStatusPage }>(`${this.apiNew}/${payload.id}`, payload);

  public deletePage = (id: string) => axios.delete(`${this.apiNew}/${id}`);

  public transferOwnership = (id: string, payload: ITeamOwner) =>
    axios.put<{ data: ITeamEntityBasicResponse }>(`${this.apiNew}/${id}/owner`, payload);
}

export default StatusPageService;
