import * as React from 'react';
import { SVGProps } from 'react';

const SvgMongoAtlas = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#001e2b',
      }}
      d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"
    />
    <path
      style={{
        fill: '#00ed64',
      }}
      d="M11.216 4.56a24.52 24.52 0 0 1-1.197-1.552.026.026 0 0 0-.038 0A24.52 24.52 0 0 1 8.784 4.56c-5.043 6.432.794 10.773.794 10.773l.049.033c.044.67.153 1.634.153 1.634h.436s.109-.959.152-1.634l.049-.038c.005.005 5.842-4.335.799-10.768zM9.997 15.235s-.261-.223-.332-.338v-.011l.316-7.004c0-.022.033-.022.033 0l.316 7.004v.011c-.072.115-.333.338-.333.338z"
    />
  </svg>
);

export default SvgMongoAtlas;
