import React, { lazy, Suspense } from 'react';
import { Grid } from 'uie/components';
import { GenAiIcon } from 'icons';
import GenAiSparkleAnimation from 'icons/lottie/gen-ai-sparkle.json';

const Lottie = lazy(() => import('lottie-react'));

const IncidentSummaryDrawerLoading: React.FC = () => {
  return (
    <Grid height="100%" alignItems="center" justifyContent="center">
      <Suspense fallback={<GenAiIcon height={66} width={66} />}>
        <Lottie
          animationData={GenAiSparkleAnimation}
          loop={true}
          style={{ height: 66, width: 66 }}
        />
      </Suspense>
    </Grid>
  );
};

export default IncidentSummaryDrawerLoading;
