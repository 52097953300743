import { Box, Button, Divider, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { FormikProvider, useFormikContext } from 'formik';
import React, { Dispatch, Fragment, SetStateAction } from 'react';

import { SEND_EMAIL_ID } from '../../constants/status.constants';
import { timezones } from '../../constants/statuspage.copy';
import { getSquadcastUrl } from '../../helpers/helper.details';
import { reactQueryConfigSuccess } from '../../helpers/helper.toast';
import { usePageConfig } from '../../hooks/usePageConfig';
import { IStatusPageDetails, IVerifyStatusPageCustomDomain } from '../../Interface';
import PageConfigForm from '../../statuspage.add/statuspage.detail/pageConfigurationForm/pageConfigurationForm';

interface Props {
  pageId: number;
  isEdit: boolean;
  setIsEditFlow: Dispatch<SetStateAction<boolean>>;
  details: IStatusPageDetails;
  closeDrawer: () => void;
  refetch: () => void;
  canEdit: boolean;
}

interface FormProps {
  onCancel: () => void;
  showConfigDetails: boolean;
  onVerifyDomain: () => void;
  domainStatus: IVerifyStatusPageCustomDomain;
  isDomainVerifying: boolean;
}

const UpdateForm = (props: FormProps) => {
  const formik = useFormikContext();
  return (
    <form>
      <VStack px={0} py={3} alignItems="flex-start" overflow={'scroll'} mb={10}>
        <PageConfigForm
          isEdit
          showConfigDetails={props.showConfigDetails}
          onVerifyDomain={props.onVerifyDomain}
          isDomainVerifying={props.isDomainVerifying}
          domainStatus={props.domainStatus}
        />
      </VStack>
      <VStack
        alignItems="flex-start"
        pb={4}
        bottom={0}
        position="absolute"
        bgColor={'white'}
        w="100%"
      >
        <Divider />
        <HStack pt={2} spacing={6}>
          <Button variant="default" onClick={formik.submitForm} data-testid="save-form">
            Save
          </Button>
          <Button variant="invertedDefault" onClick={() => props.onCancel()}>
            Cancel
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};

const UpdatePageConfig = ({
  pageId,
  isEdit,
  setIsEditFlow,
  details,
  closeDrawer,
  refetch,
  canEdit,
}: Props) => {
  const time = timezones.filter((t: any) => t.value === details.timezone)[0];
  const data = {
    statusPageName: details.name,
    domainSettings: details.isCustomDomainEnabled ? '2' : '1',
    description: details.description || '',
    privatePage: !details.isPublic,
    squadcastDomainName: details.domainName,
    customPageHostName: details.customDomainName,
    timezone: time,
    sendOnlyEmailID: SEND_EMAIL_ID,
    contactEmail: SEND_EMAIL_ID,
    aRecord: details.aRecord,
    txtRecord: details.txtRecord,
    isCustomDomainEnabled: details.isCustomDomainEnabled,
    isCustomARecordVerified: details.isCustomARecordVerified,
    isCustomTxtVerified: details.isCustomTxtVerified,
    owner: { id: details.ownerID, type: details.ownerType },
    hideFromSearchEngines: details.hideFromSearchEngines,
  };
  const { formik, onVerifyDomain, domainStatus, isDomainVerifying } = usePageConfig(
    () => {
      refetch();
      reactQueryConfigSuccess('Page configuration updated');
      closeDrawer();
    },
    data,
    pageId,
    refetch,
  );

  const sqUrl = getSquadcastUrl(details.domainName);
  const url = details.isCustomDomainEnabled ? details.customDomainName : sqUrl;

  return (
    <Box>
      <Box hidden={!isEdit} data-testid="config-form">
        <FormikProvider value={formik}>
          <UpdateForm
            onCancel={() => setIsEditFlow(false)}
            showConfigDetails={details.isCustomDomainEnabled}
            onVerifyDomain={onVerifyDomain}
            domainStatus={domainStatus}
            isDomainVerifying={isDomainVerifying}
          />
        </FormikProvider>
      </Box>

      <Box hidden={isEdit} data-testid="summary-info">
        <VStack px={0} py={3} spacing={8} alignItems="flex-start">
          <Flex w="100%" direction="row">
            <Flex flex={1} direction="column">
              <Text fontSize={14} fontWeight="800" textTransform={'uppercase'}>
                Name
              </Text>
              <Text>{details.name}</Text>
            </Flex>
            <Flex flex={1} direction="column">
              <Text fontSize={14} fontWeight="800" textTransform={'uppercase'}>
                timezone
              </Text>
              <Text>{details.timezone}</Text>
            </Flex>
          </Flex>
          {details.description ? (
            <Flex direction="column">
              <Text fontSize={14} fontWeight="800" textTransform={'uppercase'}>
                Status Page Description
              </Text>
              <Text>{details.description}</Text>
            </Flex>
          ) : null}
          <Flex direction="column">
            <Text fontSize={14} fontWeight="800" textTransform={'uppercase'}>
              PRIVATE STATUS PAGE
            </Text>
            <Text>{details.isPublic ? 'No' : 'Yes'}</Text>
          </Flex>
          <Flex direction="column">
            <Text fontSize={14} fontWeight="800" textTransform={'uppercase'}>
              Domain settings
            </Text>
            <Text>{url}</Text>
          </Flex>
          {details.isPublic && (
            <Flex direction="column">
              <Text fontSize={14} fontWeight="800" textTransform={'uppercase'}>
                Search Engines
              </Text>
              <Text>
                {details.hideFromSearchEngines
                  ? 'This page is hidden from search engines'
                  : 'This page can be crawled and indexed by search engines'}
              </Text>
            </Flex>
          )}
        </VStack>
        {canEdit ? (
          <VStack
            alignItems="flex-start"
            marginTop={4}
            marginBottom={4}
            bottom={0}
            position="absolute"
            w="100%"
          >
            <Divider />
            <HStack pt={2} spacing={6}>
              <Button variant="default" onClick={() => setIsEditFlow(true)} data-testid="edit-btn">
                Edit
              </Button>
            </HStack>
          </VStack>
        ) : null}
      </Box>
    </Box>
  );
};

export default React.memo(UpdatePageConfig);
