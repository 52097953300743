import { Component, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { requestUserOrganization } from '../../../../../core/actions';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import {
  IComponentErrorState,
  IComponentNetworkState,
} from '../../../../../core/interfaces/IComponentState';
import { IOrganization } from '../../../../../core/interfaces/IOrganization';
import { IUserInfo } from '../../../../../core/interfaces/IUserData';
import OrganizationService from '../../../../../core/services/service.organization';
import { exception } from '../../../../../core/exception';
import { render } from './render.index';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IProps
  extends Pick<IAppState, 'organization' | 'roles' | 'userInfo'>,
    RouteComponentProps {
  selectedOrg: IOrganization;
  requestUserOrganization: () => void;
}

interface IState {
  organizationName: string;
  componentNetworkState: IComponentNetworkState | 'update-name' | 'update-owner';
  error: IComponentErrorState;
  showTransferOwnerShip: boolean;
  newOwner: IUserInfo | null;
  acceptTransferOwner: boolean;
  acceptDeactivateOrg: boolean;
  acceptDeleteOrg: boolean;
  ownerSearch: string;
  showModal: 'deactivate-organization' | 'delete-organization' | '';
  hasUnsavedChanged: boolean;
}

export class OrganizationSettingsTab extends Component<IProps, IState> {
  private _orgService = new OrganizationService();

  constructor(props: IProps) {
    super(props);

    this.state = {
      organizationName: props.selectedOrg.organizationName,
      componentNetworkState: 'idle',
      error: {},
      showTransferOwnerShip: false,
      newOwner: null,
      acceptTransferOwner: false,
      ownerSearch: '',
      acceptDeactivateOrg: false,
      acceptDeleteOrg: false,
      showModal: '',
      hasUnsavedChanged: false,
    };
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.selectedOrg.organizationName !== this.props.selectedOrg.organizationName) {
      this.setState({ organizationName: this.props.selectedOrg.organizationName });
    }

    if (!this.state.hasUnsavedChanged) {
      if (this.props.selectedOrg.organizationName !== this.state.organizationName) {
        this.setState({ hasUnsavedChanged: true });
      }
      if (this.state.showTransferOwnerShip) {
        this.setState({ hasUnsavedChanged: true });
      }
      if (this.state.acceptDeactivateOrg !== prevState.acceptDeactivateOrg) {
        this.setState({ hasUnsavedChanged: true });
      }
      if (this.state.acceptDeleteOrg !== prevState.acceptDeleteOrg) {
        this.setState({ hasUnsavedChanged: true });
      }
    }
    if (this.state.hasUnsavedChanged) {
      if (
        this.props.selectedOrg.organizationName === this.state.organizationName &&
        !this.state.showTransferOwnerShip &&
        !this.state.acceptDeactivateOrg &&
        !this.state.acceptDeleteOrg
      ) {
        this.setState({ hasUnsavedChanged: false });
      }
    }
  }

  render = render;

  openModal = (showModal: IState['showModal']) => () => this.setState({ showModal });

  closeModal = () =>
    this.setState({ showModal: '', acceptDeactivateOrg: false, acceptDeleteOrg: false });

  onChangeOrgName = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ organizationName: event.target.value });
  };

  onCancelOrgNameChange = () =>
    this.setState({ organizationName: this.props.selectedOrg.organizationName, error: {} });

  onUpdateName = async () => {
    if (
      this.state.organizationName === this.props.selectedOrg.organizationName ||
      this.state.organizationName === ''
    )
      return;

    this.setState({ componentNetworkState: 'update-name', error: {} });
    try {
      const {
        data: { result, message },
      } = await this._orgService.renameOrganization(this.state.organizationName);
      if (result) {
        this.props.requestUserOrganization();
      } else {
        this.setState({
          error: {
            rename_org: message,
          },
        });
      }
    } catch (err: any) {
      exception.handle('E_ORGANIZATION_RENAME', err);
    } finally {
      this.setState({ componentNetworkState: 'idle' });
    }
  };

  onShowTransferOwnership = () =>
    this.setState({
      showTransferOwnerShip: true,
      newOwner: null,
      acceptTransferOwner: false,
      ownerSearch: '',
    });
  onCancelTransferOwnership = () => this.setState({ showTransferOwnerShip: false });
  onSearchOwner = (event: ChangeEvent<HTMLInputElement>) =>
    this.setState({ ownerSearch: event.target.value });

  onSelectOwner = (_: any, v: IUserInfo) => {
    this.setState({ newOwner: v });
  };

  onAcceptTransferOwnership = () =>
    this.setState(({ acceptTransferOwner }) => ({ acceptTransferOwner: !acceptTransferOwner }));

  onTransferOwnership = async () => {
    const { newOwner } = this.state;

    if (newOwner === null || newOwner.email === undefined) {
      return;
    }

    this.setState({ componentNetworkState: 'update-owner' });
    try {
      await this._orgService.transferOwnership(newOwner.id);
      window.location.reload();
    } catch (err: any) {
      this.setState({
        error: {
          transfer_owner: exception.handle('E_UPDATE_OWNERSHIP', err),
        },
      });
    } finally {
      this.setState({
        componentNetworkState: 'idle',
      });
    }
  };

  onAcceptDeactivateOrg = () =>
    this.setState(({ acceptDeactivateOrg }) => ({ acceptDeactivateOrg: !acceptDeactivateOrg }));

  onAcceptDeleteOrg = () =>
    this.setState(({ acceptDeleteOrg }) => ({ acceptDeleteOrg: !acceptDeleteOrg }));

  onDeactivateOrganization = async (confirm = false) => {
    if (!confirm) {
      this.closeModal();
      return;
    }
    try {
      await this._orgService.toggleOrganizationActiveStatus(!this.props.selectedOrg.deActivated);
      window.location.href = '/';
      window.location.reload();
    } catch (err: any) {
      exception.handle('E_ORGANIZATION_DEACTIVATE', err);
    }
  };
}

const OrganizationSettingsTabWithHistory = withRouter(OrganizationSettingsTab);

export default connect(
  ({ organization, roles, userOrganizations, INIT_ORG, userInfo }: IAppState) => ({
    organization,
    roles,
    userInfo,
    selectedOrg: userOrganizations.o.find(
      o => o.organizationId === INIT_ORG.orgId,
    ) as IOrganization,
  }),
  {
    requestUserOrganization,
  },
)(OrganizationSettingsTabWithHistory);
