import { Box, Flex, VStack, Text } from '@chakra-ui/layout';
import { RadioGroup } from '@chakra-ui/radio';
import { IAppState } from 'core/interfaces/IAppState';
// import { Radio } from 'library/atoms';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { SqReassignIncidentAction } from 'views/main/organization/workflow/types';

import { getReassignEntityOptions } from './helper';
import { debounce } from 'core';
import FormField from 'library/molecules/FormField/FormField';
import { AssigneeAvatar, Input, Radio } from 'library/atoms';

const ReassignIncidentForm = () => {
  const [searchText, setSearchText] = useState('');
  const { formState, setValue, watch } = useFormContext<SqReassignIncidentAction>();
  const { setIsDirty } = useWorkflowFormContext();

  const state = useSelector((state: IAppState) => state, shallowEqual);
  const options = useMemo(() => getReassignEntityOptions(state), [state.organization.selectedTeam]);

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  const handleChange = useCallback(
    (value: string) => {
      const entity = options.find(option => option.id === value);
      if (entity) setValue('data', entity, { shouldDirty: true });
    },
    [options],
  );

  const selectedEntity = watch('data');
  const debouncedSearch = useCallback(debounce(setSearchText, 400), []);

  const filteredOptions = useMemo(() => {
    if (searchText.length)
      return options.filter(option => option.name.toLowerCase().includes(searchText.toLowerCase()));
    return options;
  }, [searchText, options]);

  return (
    <Box pb={20} p={6}>
      <Box mb={2} p={6} backgroundColor="primary.100">
        <FormField label="Reassign To">
          <Input
            onChange={e => debouncedSearch(e.target.value)}
            placeholder="Type an escalation policy, squad or user to reassign"
            autoFocus
          />
        </FormField>
      </Box>
      {formState.errors.data?.message ? (
        <Text color="brand.red" variant="h3" mb={2}>
          {formState.errors.data?.message}
        </Text>
      ) : null}

      <RadioGroup display="flex" flex={1} onChange={handleChange} value={selectedEntity?.id}>
        <VStack alignItems="flex-start" w="full">
          {filteredOptions.map(option => (
            <Flex borderBottomWidth={1} key={option.id} w="full" px={3}>
              <Radio value={option.id} w="full">
                <Flex py={5}>
                  <AssigneeAvatar type={option.type} name={option.name} />
                </Flex>
              </Radio>
            </Flex>
          ))}
        </VStack>
      </RadioGroup>
    </Box>
  );
};

export default ReassignIncidentForm;
