import React from 'react';
import {
  ContainerLoad,
  Grid,
  Heading,
  InputBlock,
  TextButton,
  Para,
  SpinLoader,
  IconButton,
  ErrorBlock,
  Accordion,
  SelectBox,
  FocusBlock,
  Checkbox,
  Theme,
  Label,
  DialogModalFrame,
} from 'uie/components';
import { IRole } from '../../../../../core/interfaces/IRole';
import { CloseIcon } from '../../../../../icons';
import { OrganizationSettingsTab } from './index';
import ConfirmModal from '../../../../../components/confirm.modal';
import DeleteOrgConfirmModal from './confirm.delete.modal';
import RouteLeavingGuard from 'components/UnsavedChangeNavigation';

export function render(this: OrganizationSettingsTab) {
  const { theme } = Theme;
  const {
    organizationName,
    componentNetworkState,
    error,
    showTransferOwnerShip,
    newOwner,
    acceptTransferOwner,
    ownerSearch,
    acceptDeactivateOrg,
    acceptDeleteOrg,
    showModal,
  } = this.state;
  const { selectedOrg } = this.props;

  const accountOwnerId = this.props.roles.r.find((uRole: IRole) => {
    return uRole.name.toLowerCase().trim() === 'account owner';
  })!._id;

  const owner = this.props.organization.users.u.find(u => u.role_id === accountOwnerId);

  return (
    <>
      <ContainerLoad isLoading={componentNetworkState !== 'idle'} />
      <Grid
        type="column"
        flexWidth={12}
        className="org_settings__container_padding global--soft-scroll"
      >
        <Heading fontSize={20} height={32} fontWeight={500} color={theme.shades.black}>
          Organization settings
        </Heading>
        <div className="mt-20">
          <Grid type="column">
            <Label fontSize={14}>Organization Name</Label>
            <Grid alignItems="center" className="mt-10">
              <InputBlock
                padding="4px"
                value={organizationName}
                width="250px"
                height="28px"
                placeholder={selectedOrg.organizationName}
                onChange={this.onChangeOrgName}
                error={!!error.rename_org}
              />
              {organizationName !== selectedOrg.organizationName && (
                <>
                  <TextButton
                    className="ml-10"
                    onClick={this.onUpdateName}
                    disabled={componentNetworkState === 'update-name'}
                  >
                    <Grid alignItems="center">
                      <Para color={theme.shades.white} fontWeight={500}>
                        Update
                      </Para>
                      {componentNetworkState === 'update-name' && (
                        <SpinLoader color={theme.shades.white} base="8px" className="ml-5" />
                      )}
                    </Grid>
                  </TextButton>
                  {componentNetworkState !== 'update-name' && (
                    <IconButton
                      base="24px"
                      className="ml-10"
                      color={theme.shades.whiteSmoke}
                      buttonType="inverted"
                      onClick={this.onCancelOrgNameChange}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </>
              )}
            </Grid>
            <ErrorBlock>{error.rename_org}</ErrorBlock>
          </Grid>
        </div>
        <div className="mt-20">
          <Label fontSize={14}>Account Owner</Label>
          <Para fontSize={16} fontWeight={500} className="mt-10">
            {owner?.first_name} {owner?.last_name}
          </Para>
          <Para fontSize={14} className="mt-5 mb-10" color={theme.shades.grey}>
            {owner?.email}
          </Para>
          <Accordion
            title={
              !showTransferOwnerShip ? (
                <TextButton buttonType="inverted" onClick={this.onShowTransferOwnership}>
                  <Para color={theme.primary.default}>Transfer ownership</Para>
                </TextButton>
              ) : (
                <div className="mt-20">
                  <Label fontSize={14}>Transfer ownership</Label>
                </div>
              )
            }
            tabProps={{
              style: {
                padding: 0,
                backgroundColor: 'transparent',
              },
            }}
          >
            {showTransferOwnerShip && (
              <>
                {componentNetworkState === 'update-owner' && (
                  <Grid alignItems="center" className="mt-10">
                    <SpinLoader />
                    <Para>Transferring ownership</Para>
                  </Grid>
                )}
                {componentNetworkState === 'idle' && (
                  <div
                    className="br-3 mt-10"
                    style={{ background: theme.shades.whiteSmoke, padding: 16 }}
                  >
                    <Label fontSize={14}>New owner</Label>
                    <div className="mt-10">
                      <SelectBox
                        hook={
                          <Para fontSize={16}>
                            {newOwner?.first_name ?? 'Select new owner'} {newOwner?.last_name ?? ''}
                          </Para>
                        }
                        onValueChange={this.onSelectOwner}
                        height="auto"
                        maxHeight="200px"
                        searchHookProps={{
                          value: ownerSearch,
                          height: '24px',
                          fontSize: '16px',
                          onChange: this.onSearchOwner,
                          placeholder: 'Search',
                        }}
                      >
                        {this.props.organization.users.u
                          .filter(
                            user => user.role !== 'stakeholder' && user.role != 'account_owner',
                          )
                          .filter(({ first_name, last_name }) => {
                            const { ownerSearch } = this.state;
                            return (
                              ownerSearch === '' ||
                              `${first_name} ${last_name}`
                                .toLowerCase()
                                .includes(ownerSearch.toLowerCase())
                            );
                          })
                          .map((user, idx) => (
                            <FocusBlock value={user} key={idx}>
                              <Para>{`${user.first_name} ${user.last_name}`}</Para>
                            </FocusBlock>
                          ))}
                      </SelectBox>
                    </div>
                    {newOwner !== null && (
                      <div className="mt-10">
                        <label>
                          <Checkbox
                            background={theme.danger.default}
                            lineFill="white"
                            checked={acceptTransferOwner}
                            onChange={this.onAcceptTransferOwnership}
                          />
                          <span style={{ marginLeft: '8px' }}>
                            I understand that I will no longer be the account owner of this
                            organization
                          </span>
                        </label>
                      </div>
                    )}
                    <Grid alignItems="center" className="mt-20">
                      {acceptTransferOwner && (
                        <TextButton
                          color={theme.danger.default}
                          onClick={this.onTransferOwnership}
                          className="mr-10"
                        >
                          <Para color={theme.shades.white}>
                            Transfer ownership to {newOwner?.email}
                          </Para>
                        </TextButton>
                      )}
                      <TextButton
                        buttonType="inverted"
                        color={theme.shades.grey}
                        onClick={this.onCancelTransferOwnership}
                      >
                        <Para>Cancel</Para>
                      </TextButton>
                    </Grid>
                    <ErrorBlock>{error.transfer_owner}</ErrorBlock>
                  </div>
                )}
              </>
            )}
          </Accordion>
        </div>
        {!this.props.selectedOrg.deActivated && (
          <div style={{ marginTop: 30 }}>
            <Label fontSize={14} color={theme.danger.default}>
              Deactivate {this.props.selectedOrg.organizationName}
            </Label>
            <div className="mt-10">
              <label>
                <Checkbox
                  background={theme.danger.default}
                  lineFill="white"
                  checked={acceptDeactivateOrg}
                  onChange={this.onAcceptDeactivateOrg}
                />
                <span style={{ marginLeft: '8px' }}>
                  I understand, once I deactivate{' '}
                  <span className="font-bold">{this.props.selectedOrg.organizationName}</span>, I
                  won't be able to receive incident notifications.
                </span>
              </label>
            </div>
            {acceptDeactivateOrg && (
              <TextButton
                className="mt-10"
                color={theme.danger.default}
                onClick={this.openModal('deactivate-organization')}
              >
                <Para color={theme.shades.white}>
                  Deactivate {this.props.selectedOrg.organizationName}
                </Para>
              </TextButton>
            )}
          </div>
        )}
        <div style={{ marginTop: 30 }}>
          <Label fontSize={14} color={theme.danger.default}>
            Delete {this.props.selectedOrg.organizationName}
          </Label>
          <div className="mt-10">
            <label>
              <Checkbox
                background={theme.danger.default}
                lineFill="white"
                checked={acceptDeleteOrg}
                onChange={this.onAcceptDeleteOrg}
              />
              <span style={{ marginLeft: '8px' }}>
                I understand, this will delete my organization{' '}
                <span className="font-bold">{this.props.selectedOrg.organizationName}</span> and all
                relevant incident data.
              </span>
            </label>
          </div>
          {acceptDeleteOrg && (
            <TextButton
              className="mt-10"
              color={theme.danger.default}
              onClick={this.openModal('delete-organization')}
            >
              <Para color={theme.shades.white}>
                Delete {this.props.selectedOrg.organizationName}
              </Para>
            </TextButton>
          )}
        </div>
      </Grid>
      <DialogModalFrame id="settings__org_deactivate" width="400px" onClose={this.closeModal}>
        {showModal === 'deactivate-organization' && (
          <ConfirmModal
            hide={this.onDeactivateOrganization}
            buttonText={'Confirm'}
            displayText={`Are you sure you want to deactivate ${this.props.selectedOrg.organizationName} organization?`}
          />
        )}
      </DialogModalFrame>
      <DialogModalFrame id="settings__org_delete" width="400px" onClose={this.closeModal}>
        {showModal === 'delete-organization' && (
          <DeleteOrgConfirmModal
            hide={this.closeModal}
            organizationId={selectedOrg.organizationId}
          />
        )}
      </DialogModalFrame>
      <RouteLeavingGuard
        when={this.state.hasUnsavedChanged}
        shouldBlockNavigation={location => {
          if (this.state.hasUnsavedChanged) return true;
          return false;
        }}
        navigate={path => this.props.history.push(path)}
      />
    </>
  );
}
