import { Text } from '@chakra-ui/react';
import { T_WA_UP_GER_RULE_DELETED } from 'core/const/tracker';
import { FormButton, useToast } from 'library/atoms';
import { Modal } from 'library/molecules';
import { FC, useState } from 'react';
import { AppTracker } from 'shared/analytics/tracker';

import { deleteGerRulesetRule } from '../../query/useDeleteGerRulesetRule';
import { ALERT_API_INFO } from '../../types';

type Props = {
  gerId: string;
  id: string;
  alert: ALERT_API_INFO;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const DeleteRule: FC<Props> = ({ gerId, id, alert, isOpen, onSuccess, onClose }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const onDelete = () => {
    setIsLoading(true);
    deleteGerRulesetRule(gerId, alert.version, alert.shortName, id)
      .then(() => {
        onDeleteSuccess();
      })
      .catch(error => {
        onError(error);
      });
  };

  const onDeleteSuccess = () => {
    setIsLoading(false);
    AppTracker.track(T_WA_UP_GER_RULE_DELETED, {
      'GER Ruleset ID': gerId,
      'GER Rule ID': id,
      'Alert Source Name': alert.shortName,
    });
    onSuccess();
    toast({
      status: 'success',
      text: 'Rule deleted successfully',
    });
  };

  const onError = (err: any) => {
    setIsLoading(false);
    const errMsg = err?.response?.data?.meta.error_message ?? `Unable to delete rule`;

    toast({
      status: 'error',
      text: `Error: ${errMsg}`,
    });
    console.error(err);
  };

  return (
    <Modal
      key="delete-ger-ruleset-rule"
      body={
        <Text variant="formInput" color="secondary.700">
          Are you sure you want to delete the rule?
        </Text>
      }
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={
        <FormButton title="Delete" onClick={onDelete} isLoading={isLoading} variant="danger" />
      }
      secondaryButton={
        <FormButton title="Cancel" onClick={onClose} disabled={isLoading} variant="secondary" />
      }
      title="Delete Rule"
    />
  );
};
