import request from '../commons';
import { API } from '../../api';

function getOrgServices() {
  const req = request.get(API.config.batman)(
    `organizations/${API.config.organizationId}/services?owner_id=${API.config.teamId}`,
  );
  return req;
}

export { getOrgServices };
