import Axios from 'axios';
import qs from 'query-string';
import { API } from 'core';
import { QUERY_CONFIG } from 'library/query-config';
import { QueryClient, useQuery } from 'react-query';
import { SERVICE_DEP_TYPE } from '../Interfaces/graph';
import { queryKeys } from '../contants/service.contant';

const getServiceDependencies = (teamId: string) => {
  const props = {
    owner_id: teamId,
  };
  return Axios.get(
    `${API.config.batman}/organizations/${
      API.config.organizationId
    }/services/dependencies?${qs.stringify(props)}`,
  );
};

export const useGetServiceDependencies = (teamId: string) => {
  const { data, isSuccess, isError, isLoading, isFetching } = useQuery({
    queryKey: [queryKeys.SERVICE_GRAPH_DEP_QUERY_KEY, teamId],
    queryFn: () => getServiceDependencies(teamId),
    ...QUERY_CONFIG,
  });
  const response = data?.data ?? { data: [] };

  return {
    deps: response.data as SERVICE_DEP_TYPE[],
    isSuccess,
    isError,
    isLoading,
    isFetching,
  };
};

export const invalidateServiceDependencies = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(queryKeys.SERVICE_GRAPH_DEP_QUERY_KEY);
};
