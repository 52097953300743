import React, { useRef, Dispatch, SetStateAction } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  ChakraProvider,
  Flex,
} from '@chakra-ui/react';

interface IAlertDialog {
  isOpen: boolean;
  onClose: () => void;
  onCancel?: (() => void) | null;
  msg: string;
  callbackFn: () => void;
  title: string;
  isDelete: boolean;
  loading?: boolean;
  showActionButtonFirst?: boolean;
  cancelButtonText?: string;
  actionButtonText?: string;
}

export const AlertDialogComponent = ({
  isOpen,
  onClose,
  onCancel,
  callbackFn,
  msg,
  title,
  isDelete,
  loading,
  showActionButtonFirst,
  cancelButtonText,
  actionButtonText,
}: IAlertDialog) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <ChakraProvider cssVarsRoot="#serviceAlert">
      <AlertDialog
        id="serviceAlert"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCancel ?? onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{msg}</AlertDialogBody>

            <AlertDialogFooter>
              <Flex direction={showActionButtonFirst ? 'row-reverse' : 'row'}>
                <Button ref={cancelRef} onClick={onCancel ?? onClose}>
                  {cancelButtonText ?? 'Cancel'}
                </Button>
                <Button
                  isLoading={loading}
                  colorScheme="red"
                  onClick={() => callbackFn()}
                  {...(showActionButtonFirst ? { mr: 3 } : { ml: 3 })}
                >
                  {actionButtonText ?? (isDelete ? 'Delete anyway' : 'Yes')}
                </Button>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </ChakraProvider>
  );
};
