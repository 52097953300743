import * as React from 'react';
import { SVGProps } from 'react';

const SvgCiscoMerakiLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Cisco-Meraki_logo-20x20_svg__\u56FE\u5C42_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.Cisco-Meraki_logo-20x20_svg__st1{fill:#fbfcfa}'}</style>
    <path
      style={{
        fill: '#67b347',
      }}
      d="M0 0h20v20H0z"
    />
    <path
      className="Cisco-Meraki_logo-20x20_svg__st1"
      d="M6.3 10v3.1c0 1-.7 1.7-1.6 1.7-.9 0-1.6-.7-1.6-1.7V6.9c0-.9.7-1.7 1.6-1.6.9 0 1.6.7 1.6 1.6V10zM16.9 13.1c0 .8-.5 1.4-1.2 1.6-.7.2-1.5-.2-1.8-.8-.7-1.5-1.5-3-2.2-4.6-.4-.9-.1-1.8.7-2.2.8-.4 1.7-.1 2.2.8.7 1.5 1.4 3 2.1 4.4.1.3.2.6.2.8zM10.5 14.7c-.5 0-1-.3-1.3-.9-.7-1.5-1.4-3-2.1-4.6-.4-.7-.1-1.7.7-2 .8-.4 1.7-.1 2.1.7.7 1.5 1.5 3.1 2.2 4.6.5 1.1-.3 2.2-1.6 2.2z"
    />
  </svg>
);

export default SvgCiscoMerakiLogo20X20;
