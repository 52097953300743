const schema = {
  // Measures
  IncidentsCount: 'Incidents.count',
  IncidentsEventCount: 'Incidents.eventCount',
  IncidentsDuplicatesCount: 'Incidents.duplicatesCount',
  IncidentsMTTA: 'Incidents.mtta',
  IncidentsMTTR: 'Incidents.mttr',

  // Dimensions
  AlertSourcesName: 'AlertSources.name',
  IncidentsAcknowledgedByUserId: 'Incidents.acknowledgedByUserId',
  IncidentsCreatedAt: 'Incidents.createdAt',
  IncidentsAssignedToUserId: 'Incidents.assignedToList',
  IncidentsReassignedCount: 'Incidents.reassignedCount',
  IncidentsResolvedByUserId: 'Incidents.resolvedByUserId',
  IncidentsServiceId: 'Incidents.serviceId',
  IncidentServiceOwneId: 'Incidents.serviceOwner',
  IncidentsStatus: 'Incidents.status',
  IncidentsTags: 'Incidents.tags',
  IncidentsPriority: 'Incidents.priority',
  IncidentsTeamId: 'Incidents.teamId',
  IncidentsIsChild: 'Incidents.isChild',
  ServicesName: 'Services.name',
  TeamsName: 'Teams.name',
  UsersName: 'Users.name',
};

export default schema;
