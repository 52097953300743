import { Box, HStack, Text } from '@chakra-ui/react';
import { truncate } from 'core/helpers/stringUtils';
import { IconButton } from 'library/atoms';
import { LibraryIcons } from 'library/icons';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';

import { useGetAssigneeComponent, getNameComponent } from '../../components';
import { RULESET_RULE } from '../../types';
import {
  useGetEntityName,
  getGerPath,
  QUERY_PARAM_KEYS,
  ROWS_INFO_MAX_LENGTH,
  SHIFT_TO,
} from '../../util';

type ColumnProps = {
  pageNum: number;
  limit: number;
  count: number;
  canReorder: boolean;
  reorder: (id: string, shiftTo: SHIFT_TO) => void;
  alertVersion: string;
  alertShortName: string;
};

export const RULESET_COLUMN_DEFS = ({
  pageNum,
  limit,
  count,
  canReorder,
  reorder,
  alertVersion,
  alertShortName,
}: ColumnProps) => [
  {
    Header: 'RULE DESCRIPTION',
    Cell: (cell: Cell<RULESET_RULE>) => {
      const { id, global_event_rule_id: gerId, description } = cell.row.original;
      return (
        <Box pl={6} py={2}>
          <Link
            to={getGerPath(gerId, {
              [QUERY_PARAM_KEYS.ALERT_VERSION]: alertVersion,
              [QUERY_PARAM_KEYS.ALERT_NAME]: alertShortName,
              [QUERY_PARAM_KEYS.RULE]: id,
            })}
          >
            {getNameComponent(description, ROWS_INFO_MAX_LENGTH)}
          </Link>
        </Box>
      );
    },
  },
  {
    Header: 'RULE EXPRESSION',
    Cell: (cell: Cell<RULESET_RULE>) => {
      return (
        <Box pl={6}>
          <Text variant="body" color="secondary.1000">
            {truncate(cell.row.original.expression, ROWS_INFO_MAX_LENGTH)}
          </Text>
        </Box>
      );
    },
  },
  {
    Header: 'ROUTE TO',
    Cell: (cell: Cell<RULESET_RULE>) => {
      const { route_to: id } = cell.row.original.action;
      const name = useGetEntityName('service', id);
      return <Box pl={6}>{useGetAssigneeComponent(id, name, false, 'service')}</Box>;
    },
  },
  {
    id: 'action',
    Header: '',
    Cell: (cell: Cell<RULESET_RULE>) => {
      const rowIndex = cell.row.index;
      const isFirstRow = rowIndex === 0 && pageNum === 0;
      const isLastRow = pageNum * limit + rowIndex + 1 === count;
      return (
        <>
          {canReorder && (
            <HStack pl={6} gap={1}>
              <IconButton
                variant="secondary"
                aria-label="move-up"
                icon={<LibraryIcons.ArrowUpIcon />}
                disabled={isFirstRow}
                onClick={() => reorder(cell.row.original.id, SHIFT_TO.TOP)}
              />
              <IconButton
                variant="secondary"
                aria-label="move-down"
                icon={<LibraryIcons.ArrowDownIcon />}
                disabled={isLastRow}
                onClick={() => reorder(cell.row.original.id, SHIFT_TO.BOTTOM)}
              />
            </HStack>
          )}
        </>
      );
    },
  },
];
