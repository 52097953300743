import { useQueryClient } from 'react-query';
import { useSearchParam } from 'core/hooks/useSearchParam';
import TogglePostmortemModal from './TogglePostmortemModal';

export function PostmortemDetail() {
  const queryClient = useQueryClient();
  const [incidentId, _, unsetIncidentId] = useSearchParam('incident-id', 'replace');
  if (!incidentId) {
    return null;
  }

  const refresh = () => {
    queryClient.invalidateQueries({ queryKey: ['postmortems'] });
  };

  return (
    <TogglePostmortemModal
      refreshTable={refresh}
      closeModal={() => unsetIncidentId()}
      incidentId={incidentId}
    />
  );
}
