import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API } from 'core';
import { queryKeys, reactQueryConfig } from '../constant';
import { IConfigDetailResponse } from '../../../../../../../core/interfaces/IJiraNew';
import { reactQueryConfigError } from '../helpers/service.reactqery';

const getJiraConfigDetailById = (accountId: number | null, configId: number | null) => async () => {
  const { data } = await axios.get<IConfigDetailResponse>(
    `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud/new/${accountId}/jira-cloud-configs/${configId}`,
  );
  return data.data;
};

export const useGetJiraConfigDetailById = (accountId: number | null, configId: number | null) => {
  return useQuery(
    [queryKeys.GETDETAILOFJIRACLOUDCONFIG, accountId, configId],
    getJiraConfigDetailById(accountId, configId),
    {
      ...reactQueryConfig,
      enabled: !!accountId && !!configId,
      retry: 1,
      onError: (error: any) => {
        const description =
          error?.response?.data?.meta?.error_message || 'Failed to Get Jira Config Detail';
        reactQueryConfigError({ description: description, title: 'Get Jira Config Detail' });
      },
    },
  );
};
