import React, { ReactNode } from 'react';

import {
  Box,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';

interface PopoverProps {
  trigger: ReactNode;
  triggerType?: 'hover';
  enableTriggerWrapper?: boolean;
  disablePortal?: boolean;
  children: ReactNode;
  isOpen?: boolean;
  placement?: PlacementWithLogical;
  hasArrow?: boolean;
}

export const NodePopover = ({
  trigger,
  triggerType,
  enableTriggerWrapper,
  disablePortal,
  children,
  isOpen,
  placement,
  hasArrow = true,
}: PopoverProps) => {
  return (
    <Popover trigger={triggerType ?? 'click'} isOpen={isOpen} isLazy placement={placement}>
      {({ isOpen: isPopoverOpen }) => (
        <>
          <PopoverTrigger>{enableTriggerWrapper ? <Box>{trigger}</Box> : trigger}</PopoverTrigger>
          {children &&
            isPopoverOpen &&
            (disablePortal ? (
              <PopoverContent _focus={{ outline: 'none' }} width={'auto'}>
                {hasArrow && <PopoverArrow />}
                <PopoverBody p={1}>{children}</PopoverBody>
              </PopoverContent>
            ) : (
              <Portal>
                <PopoverContent _focus={{ outline: 'none' }} width={'auto'}>
                  {hasArrow && <PopoverArrow />}

                  <PopoverBody p={1} style={{ backgroundColor: !hasArrow ? '#EEF2F6' : 'null' }}>
                    {children}
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            ))}
        </>
      )}
    </Popover>
  );
};
