import { Box, Button, Center, Divider, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import { FormikProvider } from 'formik';
import React, { Fragment, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { STATUSPAGE_V2_DETAILS } from 'views/main/routes/routes';

import HelpGuide from '../../components/HelpGuide';
import { statusIssueHelpGuide } from '../../constants/statuspage.copy';
import { reactQueryConfigSuccess } from '../../helpers/helper.toast';
import { usePageIssue } from '../../hooks/usePageIssue';
import IssueForm from './issueForm/issueForm';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_CREATION_PAGE_4_VIEWED } from 'core/const/tracker';
import { THEME_COLORS } from 'library/theme/colors';

type Props = {
  pageId: number;
};

const StatuPageAddIssue: React.FC<any> = (props: Props) => {
  const history = useHistory();
  const { formik, isSuccess, isCreateIssueProgress } = usePageIssue(props.pageId, () => {
    reactQueryConfigSuccess('Issue Added');
    history.push(generatePath(STATUSPAGE_V2_DETAILS, { id: props.pageId }));
  });
  useEffect(() => {
    AppTracker.track(T_WA_UP_STATUS_PAGE_V2_CREATION_PAGE_4_VIEWED);
  }, []);
  return (
    <Fragment>
      {isSuccess ? (
        <div style={{ backgroundColor: 'white', paddingTop: '24px' }}>
          <Text color={THEME_COLORS.secondary[900]} fontSize="24px">
            Add Your First Issue
          </Text>
          <Text color={THEME_COLORS.secondary[900]} fontSize="14px">
            Give it a spin! you can always edit or delete any issues in your status pages
          </Text>
          <form style={{ width: '80%', paddingTop: '12px' }}>
            <FormikProvider value={formik}>
              <IssueForm />
            </FormikProvider>
            <Box w="100%" bottom={0} mx={-6}>
              <Divider />
              <HStack px={6} py={4} gap={4} sx={{ position: 'static', bottom: 0 }}>
                <Button
                  onClick={formik.submitForm}
                  variant="default"
                  isLoading={isCreateIssueProgress}
                >
                  Save
                </Button>
                <Button
                  variant="invertedDefault"
                  onClick={() =>
                    history.push(generatePath(STATUSPAGE_V2_DETAILS, { id: props.pageId }))
                  }
                >
                  Skip
                </Button>
              </HStack>
            </Box>
          </form>
        </div>
      ) : (
        <Center h="60vh">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      )}
      <VStack
        // divider={<StackDivider width="20px" borderColor="black" />}
        spacing={4}
        align="stretch"
        h="100%"
        backgroundColor={'white'}
      >
        <HelpGuide helpGuide={statusIssueHelpGuide} />
      </VStack>
    </Fragment>
  );
};

export default StatuPageAddIssue;
