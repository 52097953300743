import { Box } from '@chakra-ui/layout';
import { AssigneeAvatar } from 'library/atoms';
import React from 'react';
import { SqReassignIncidentAction } from 'views/main/organization/workflow/types';

interface Props {
  action: SqReassignIncidentAction;
}
const ReassignIncidentCard = ({ action }: Props) => {
  if (!action.data) {
    return null;
  }
  return (
    <Box mt={3}>
      <AssigneeAvatar type={action.data.type} name={action.data.name} />
    </Box>
  );
};

export default React.memo(ReassignIncidentCard);
