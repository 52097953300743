import { defaultConfig, defaultDevConfig } from '../const/endpoints';
import Pusher from 'pusher-js';
import { IAPIConfig, IAPIState } from '../interfaces/IAPIConfig';
import Axios from 'axios';
import { AppConfig } from 'shared/app.config';
import { REQUEST_USER_LOGOUT } from 'core/const/auth';
import { initV3Client, v3 } from 'views/main/organization/postmortems/api/base';
import { clear } from 'store/localStorage';

Axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.request?.url) {
      const url = new URL(error.request.url);
      if (url.pathname == '/v2/accounts/logout') {
        return;
      }
    }
    if (error.response) {
      const url = new URL(error.response.config.url);
      if (error.response.status === 401 && url.pathname != '/v3/accounts/login') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window._store.dispatch({ type: REQUEST_USER_LOGOUT });
        clear();
      }
    }
    return Promise.reject(error);
  },
);

const mapEnvToApi = (_assignableApi: IAPIConfig, env: string): IAPIState => ({
  endpoint: `${_assignableApi.api.endPoint}/${_assignableApi.api.version}`,
  batman: `${_assignableApi.batman.endPoint}/${_assignableApi.batman.version}`,
  ingester: `${_assignableApi.ingester.endPoint}/${_assignableApi.ingester.version}`,
  internalApi: `${_assignableApi.internalApi.endPoint}`,
  sqerberos: `${_assignableApi.sqerberos.endPoint}`,
  frontend: _assignableApi.frontend,
  deus: {
    endpoint: _assignableApi.deusApi,
    socket: _assignableApi.deusApiSocket,
    apiKey: '',
  },
  dex: {
    endpoint: _assignableApi.dexApi,
  },
  billingPortal: _assignableApi.billingPortal,
  squadcastSupport: _assignableApi.squadcastSupport,
  squadcastWebsite: _assignableApi.squadcastWebsite,
  sessionId: '',
  userId: '',
  teamId: '',
  organizationId: '',
  hostname: '',
  oneSignalApi: _assignableApi.oneSignalKey || '',
  integration: _assignableApi.integration,
  environment: env,
  tld: _assignableApi.tld,
});

const mapEnvToApiEuDataCenter = (_assignableApi: IAPIConfig, env: string): IAPIState => ({
  endpoint: `${_assignableApi.api.euEndPoint}/${_assignableApi.api.version}`,
  batman: `${_assignableApi.batman.euEndPoint}/${_assignableApi.batman.version}`,
  ingester: `${_assignableApi.ingester.euEndPoint}/${_assignableApi.ingester.version}`,
  internalApi: `${_assignableApi.internalApi.euEndPoint}`,
  sqerberos: `${_assignableApi.sqerberos.euEndPoint}`,
  frontend: _assignableApi.frontend,
  deus: {
    endpoint: _assignableApi.deusApi,
    socket: _assignableApi.deusApiSocket,
    apiKey: '',
  },
  dex: {
    endpoint: _assignableApi.dexApi,
  },
  billingPortal: _assignableApi.billingPortal,
  squadcastSupport: _assignableApi.squadcastSupport,
  squadcastWebsite: _assignableApi.squadcastWebsite,
  sessionId: '',
  userId: '',
  teamId: '',
  organizationId: '',
  hostname: '',
  oneSignalApi: _assignableApi.oneSignalKey || '',
  integration: _assignableApi.integration,
  environment: env,
  tld: _assignableApi.tld,
});

/**
 * @description sets the default endpoints
 * @default {env} 'prod' | 'dev' | 'stage' | 'internal'
 */
export default class APIConfig {
  private apiService: IAPIState = mapEnvToApi(defaultConfig, 'prod');
  private apiServiceUSDC: IAPIState = mapEnvToApi(defaultConfig, 'prod');
  private apiServiceEUDC: IAPIState = mapEnvToApiEuDataCenter(defaultConfig, 'prod');
  private soketiService: Pusher | null = null;
  private pusherService: Pusher | null = null;
  private _appVersion = '0.0.0';
  private _bearer = 'Bearer';

  set bearer(token: string) {
    this._bearer = `Bearer ${token}`;
    Axios.defaults.headers.common.Authorization = this._bearer;
    initV3Client(this._bearer);
  }

  get bearer() {
    return this._bearer;
  }

  get appVersion() {
    return this._appVersion;
  }

  set appVersion(v: string) {
    this._appVersion = v;
  }

  get config() {
    return this.apiService;
  }

  get env() {
    return this.apiService.environment;
  }

  set env(env: 'dev' | 'prod' | 'stage' | 'internal' | 'eu-prod' | string) {
    const _assignableApi = (() => {
      switch (env) {
        case 'dev':
          return defaultDevConfig();
        case 'prod':
          return defaultConfig;
        case 'eu-prod':
          return defaultConfig;
        case 'stage':
          return defaultConfig;
        case 'internal':
          return defaultConfig;
        default:
          return defaultDevConfig(env);
      }
    })();

    this.apiService = mapEnvToApi(_assignableApi, env);
  }

  get socket() {
    return this.soketiService!;
  }

  /**
   * to manually set the hostname to a private instance
   * @ignore for mobile only
   */
  set hostname(hostname: string) {
    if (hostname !== '') {
      const hn = hostname.toLowerCase();
      this.apiService.hostname = hn;
      this.apiService.endpoint = `https://platform-backend.${hn}/${defaultConfig.api.version}`;
    }
  }

  /**
   * @ignore for mobile only
   */
  get hostname() {
    return this.apiService.hostname;
  }

  /**
   * @ignore for mobile only
   */
  set tld(tld: string) {
    const _assignableApi = defaultConfig;
    this.apiService = mapEnvToApi(_assignableApi, 'stage');
    this.apiService.oneSignalApi = _assignableApi.oneSignalKey;
  }

  /**
   * @ignore for mobile only
   */
  get tld() {
    return this.apiService.tld;
  }

  /**
   * @ignore for mobile only
   */
  set setApiService(dataCenter: 'eu' | 'us') {
    switch (dataCenter) {
      case 'eu':
        this.apiService = this.apiServiceEUDC;
        break;
      case 'us':
        this.apiService = this.apiServiceUSDC;
        break;
    }
  }

  initPusher = (pusherKey: string, pusher: typeof Pusher) => {
    this.soketiService = new pusher(pusherKey, {
      wsHost: AppConfig.pusher_host,
      httpHost: AppConfig.pusher_host,
      wssPort: 443,
      enabledTransports: ['ws', 'wss'],
      activityTimeout: 6000,
    });
  };
}

const api = new APIConfig();
api.env = 'prod';

export { api as API };
