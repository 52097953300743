import { FilterOptionsLabel } from 'components/SecondaryFilter/types';

export const CompareOptions: FilterOptionsLabel[] = [
  {
    key: 'services',
    label: 'Service',
  },
  {
    key: 'tags',
    label: 'Tag',
  },
  {
    key: 'users',
    label: 'User',
  },
  {
    key: 'priority',
    label: 'Priority',
  },
];
