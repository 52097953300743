import { createStandaloneToast } from '@chakra-ui/react';

const toast = createStandaloneToast();

export const reactQueryConfigError = ({
  description,
  title,
}: {
  description: string;
  title: string;
}) => {
  toast.closeAll();
  toast({
    title,
    status: 'error',
    variant: 'subtle',
    isClosable: true,
    description,
    duration: 5000,
    containerStyle: {
      fontSize: 14,
      lineHeight: 10,
    },
  });
};

export const reactQueryConfigSuccess = (message: string) => {
  toast({
    title: message,
    status: 'success',
    duration: 4000,
    isClosable: true,
  });
};

export const reactQueryConfigWarning = ({
  description,
  title,
}: {
  description: string;
  title: string;
}) => {
  toast({
    title,
    status: 'warning',
    variant: 'subtle',
    isClosable: true,
    description,
    duration: 5000,
    containerStyle: {
      fontSize: 14,
      lineHeight: 10,
    },
  });
};
