import { CSSObject } from '@chakra-ui/react';
import chakraUiTheme from '@chakra-ui/theme';

export const rotationStyles = (): CSSObject => ({
  '&': {
    '.react-date-picker, .react-time-picker': {
      height: '40px',
      lineHeight: '40px',
    },
    '.react-date-picker__wrapper': {
      alignItems: 'center',
      verticalAlign: 'middle',
    },
  },
});
