import { IAppState } from 'core/interfaces/IAppState';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Session } from '../common/types';

type ContextType = {
  session: Session;
};

const SessionContext = createContext<ContextType | undefined>(undefined);

type Props = {
  orgState: IAppState['organization'];
};

const SessionProviderImpl = ({ children, orgState }: PropsWithChildren<Props>) => {
  const user = orgState.currentUser.u;
  const org = orgState.currentOrg.o;
  const team = orgState.selectedTeam.team;

  /*

  Start of Billing Plan hack.

  Billing plan is loaded weirdly in the app. It is stored in Redux.
  Redux persists its entire state to SessionStorage.

  This is the simplified flow on first load of the app:

  1. Load the plan from the API
  2. Persist in SessionStorage
  3. Render the page.

  This is the simplified flow on successive loads of the app:

  1. Load Redux state from SessionStorage
  2. Render the page with the plan from SessionStorage
  3. Set plan in Redux to null.
  4. Render the page with the plan being null
  5. Load the plan from the API
  6. Persist in SessionStorage
  7. Re-render the page with the plan from API

  Our legacy Redux/Rx code is unable to wait for the plan to be loaded before rendering.
  This is the reason why core/service.billing.ts accepts a null plan object.

  We prevent this by putting together a hack ensuring the plan can never be null.
  */

  const _incomingPlan = orgState.plan.p;

  const [plan, setPlan] = useState(_incomingPlan);

  useEffect(() => {
    if (_incomingPlan) {
      setPlan(_incomingPlan);
    }
  }, [_incomingPlan]);

  const isLoaded = !!(user && org && team && plan);

  if (!isLoaded) {
    return null;
  }

  /* End of Billing Plan hack */

  const session: Session = {
    user: user,
    userId: user.id,
    orgState,
    org: org,
    orgId: org.organizationId,
    team: team,
    teamId: team.id,
    plan: plan,
  };

  return <SessionContext.Provider value={{ session }}>{children}</SessionContext.Provider>;
};

export const useSession = () => {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error('useSession must be used inside the SessionProvider');
  }

  return context;
};

export const SessionProvider = connect(({ organization }: IAppState) => ({
  orgState: organization,
}))(SessionProviderImpl);
