import { Box, Button, Center, Divider, HStack, Spinner, Text } from '@chakra-ui/react';
import { FormikProvider } from 'formik';
import React from 'react';

import { reactQueryConfigSuccess } from '../../../helpers/helper.toast';
import { usePageIssue } from '../../../hooks/usePageIssue';
import { IssueDetailsForm } from '../../../statuspage.add/statuspage.issue/issueForm/detailsForm';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_ISSUE_UPDATED } from 'core/const/tracker';
import { useUserAccess } from 'core/userAccess/UserAccessContext';

interface Props {
  pageId: number;
  issueId: number;
  onCancel: () => void;
  onSuccess: () => void;
}

const IssueDetailsUpdate = (props: Props) => {
  const { formik, isSuccess, isUpdateIssueProgress } = usePageIssue(
    props.pageId,
    () => {
      props.onSuccess();
      reactQueryConfigSuccess('Issue Updated');

      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_ISSUE_UPDATED, {
        'Status Page V2 Issue Update Type': 'Issue Details',
      });

      props.onCancel();
    },
    props.issueId,
  );
  const canEdit = useUserAccess().hasUpdateAccess('status_pages', `${props.pageId}`);

  return (
    <>
      {isSuccess ? (
        <Box bgColor="white" pt={2}>
          <form>
            <Box mb={10}>
              {formik.values.isMigrated && (
                <Text fontSize="sm" color={'red.500'} mx={6} mb={4}>
                  We only support editing the Issue Title for historical issues.
                </Text>
              )}
              <FormikProvider value={formik}>
                <Box px={6}>
                  <IssueDetailsForm />
                </Box>
              </FormikProvider>
            </Box>
            <Box
              w="100%"
              bottom={0}
              position="fixed"
              bgColor={'white'}
              zIndex="2"
              hidden={!canEdit}
            >
              <Divider />
              <HStack px={6} py={2} gap={4}>
                <Button
                  onClick={formik.submitForm}
                  variant="default"
                  isLoading={isUpdateIssueProgress}
                >
                  Save
                </Button>
                <Button variant="invertedDefault" onClick={() => props.onCancel()}>
                  Cancel
                </Button>
              </HStack>
            </Box>
          </form>
        </Box>
      ) : (
        <Center h="60vh">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      )}
    </>
  );
};

export default IssueDetailsUpdate;
