import { IAppState } from 'core/interfaces/IAppState';
import { ISquad } from 'core/interfaces/ISquad';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { isEmpty } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { AppConfig } from 'shared/app.config';

import { COMPONENT_TYPE, DETAILS_MENU_ITEMS, DRAWER_TYPES } from '../constants/status.constants';
import { NewStatusPageOwnerType } from '../graphql/types';
import {
  IComponentTreeItem,
  IfilterOption,
  IPatternParticipant,
  IStatusPageComponent,
  IStatusPageComponentList,
  IStatusPageGroup,
} from '../Interface';

const getDrawerHeader = (drawerType: string, isEdit = false) => {
  if (drawerType === DRAWER_TYPES.ADD_ISSUE) {
    return 'Add issue';
  }
  if (drawerType === DRAWER_TYPES.THEME) {
    return DETAILS_MENU_ITEMS.EDIT_THEME.title;
  }
  if (drawerType === DRAWER_TYPES.COMPONENTS) {
    return 'Edit Components and Groups';
  }
  const tab = Object.values(DETAILS_MENU_ITEMS).filter(item => item.id === drawerType);
  if (tab.length > 0) {
    const title = tab[0].title;
    return isEdit ? `Edit ${title}` : title;
  }
  return '';
};

const getComponentOptions = (components: IStatusPageGroup[]) => {
  const componentOptions: IfilterOption[] = [];
  const groupOptions: IfilterOption[] = [];
  components.forEach((group: IStatusPageGroup) => {
    if (group.type === COMPONENT_TYPE.GROUP) {
      groupOptions.push({
        value: group.id,
        label: group.name,
      });
      (group.components || []).forEach((comp: IStatusPageComponent) => {
        componentOptions.push({
          value: comp.id,
          label: comp.name,
        });
      });
    } else {
      componentOptions.push({
        value: group.id,
        label: group.name,
      });
    }
  });
  return { componentOptions, groupOptions };
};

const getComponentList = (components: IStatusPageGroup[]) => {
  const componentList: IStatusPageComponentList[] = [];
  components.forEach((group: IStatusPageGroup) => {
    if (group.type === COMPONENT_TYPE.GROUP) {
      (group.components || []).forEach((comp: IStatusPageComponent) => {
        componentList.push({
          id: comp.id,
          name: comp.name,
          isHidden: comp.isHidden || false,
        });
      });
    } else {
      componentList.push({
        id: group.id,
        name: group.name,
        isHidden: group.isHidden || false,
      });
    }
  });
  return componentList;
};

const getGroupOptions = (components: IComponentTreeItem[]) => {
  const groupOptions: IfilterOption[] = [];
  components.forEach((c: IComponentTreeItem) => {
    if (c.type === COMPONENT_TYPE.GROUP) {
      groupOptions.push({ value: c.id, label: c.name });
    }
  });
  return groupOptions;
};

const getComponentCount = (components: IComponentTreeItem[]) => {
  let count = 0;
  components.forEach((c: IStatusPageGroup) => {
    if (c.type === COMPONENT_TYPE.GROUP) {
      count += (c?.components || [])?.length;
    } else {
      count += 1;
    }
  });
  return count;
};

const getComponentData = (components: IComponentTreeItem[]) => {
  const comp: any = [];
  let componentCount = 0;
  components.forEach((c: IStatusPageGroup) => {
    if (c.type === COMPONENT_TYPE.GROUP) {
      if (!isEmpty(c.name)) {
        const group: any = {
          type: c.type,
          name: c.name,
          components: [],
        };
        group.components = c.components?.map((com: IStatusPageComponent) => {
          componentCount += 1;
          return {
            type: com.type,
            name: com.name,
            description: (com as IStatusPageComponent).description,
            allowSubscription: true,
          };
        });
        comp.push(group);
      }
    } else {
      componentCount += 1;
      comp.push({
        type: c.type,
        name: c.name,
        description: (c as IStatusPageComponent).description,
        allowSubscription: true,
      });
    }
  });
  return { components: comp, componentCount };
};

const getPageTypeFilterValue = (values: string[]) => {
  if (values.length === 0 || values.length === 2) {
    return null;
  }
  return values[0] === 'public' ? true : false;
};

const truncateString = (string = '', maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

const useGetSquadcastHostPrefix = () => {
  const orgSlug = useSelector(
    (state: IAppState) => state.organization.currentOrg.o?.slug,
    shallowEqual,
  );
  return AppConfig.status_page_host + '/' + orgSlug + '/';
};

const useGetSquadcastUrl = (pageSlug: string) => {
  return useGetSquadcastHostPrefix() + pageSlug;
};

const useGetAllPatternParticipants = (): Array<IPatternParticipant> => {
  // generates array of current team, squads, and users for Owner dropdown
  // uses redux store value as data source
  const organization = useSelector((state: IAppState) => state.organization, shallowEqual);
  const selectedTeam = organization?.selectedTeam?.team;
  const membersOfSelectedTeam = selectedTeam?.members.map(member => member.user_id);
  return [
    ...(organization?.users.u
      ? organization?.users?.u
          .filter(user => membersOfSelectedTeam?.includes(user.id))
          .filter(user => user.role !== 'stakeholder')
          .sort((comparingElement: any, compareWithElement: any) =>
            comparingElement.first_name.localeCompare(compareWithElement.first_name),
          )
          .map(
            ({
              first_name,
              last_name,
              id,
              time_zone,
              username_for_display: username,
            }: IUsersOfOrganization) => {
              return {
                value: id,
                label: `${first_name} ${last_name}`,
                type: NewStatusPageOwnerType.User,
                id: id,
                timeZone: time_zone,
                username,
              };
            },
          )
      : []),
    ...(organization?.squads.s
      ? organization?.squads?.s
          .filter(squad => {
            return squad.members?.some(member => membersOfSelectedTeam?.includes(member));
          })
          .sort((comparingElement: any, compareWithElement: any) =>
            comparingElement.name.localeCompare(compareWithElement.name),
          )
          .map(({ id, name, members }: ISquad, index: number) => {
            return {
              value: id,
              label: name,
              type: NewStatusPageOwnerType.Squad,
              id: id,
              members: members,
            };
          })
      : []),
  ];
};

function parseDateStringToUTCDate(dateString: string): Date {
  const [year, month, day] = dateString.split('-').map(Number);

  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    throw new Error(
      'Invalid date string. Ensure the date string is in the format YYYY-MM-DD and contains valid numbers.',
    );
  }
  //parsing dates
  const date = new Date(year, month - 1, day, 0, 0, 0);

  return date;
}

export {
  getDrawerHeader,
  getComponentOptions,
  getComponentList,
  getGroupOptions,
  getComponentData,
  getPageTypeFilterValue,
  truncateString,
  getComponentCount,
  useGetSquadcastHostPrefix as getSquadcastHostPrefix,
  useGetSquadcastUrl as getSquadcastUrl,
  useGetAllPatternParticipants as getAllPatternParticipants,
  parseDateStringToUTCDate,
};
