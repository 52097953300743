import { InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  HStack,
  ListItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';

import { colorPallet, tooltipStyle } from '../slo.constants';
import { getPercentage, getServicesList } from '../slo.helper';
import { sliDistribution } from '../slo.interface';
import { IServices } from '../sloOverview.component';

interface IProps {
  sliList: sliDistribution | undefined;
  totalErrBudget: number;
  isError: boolean;
  serviceIds: Array<string>;
  orgServices: IServices;
}

export const SliListing = ({
  sliList,
  totalErrBudget,
  serviceIds,
  orgServices,
  isError,
}: IProps) => {
  const isSliDistribution = sliList && Array.isArray(sliList.sli_distribution);
  const sliListArray = isSliDistribution ? sliList.sli_distribution : [];
  const [isShowBreakdown, SetIsShowBreakdown] = useState(false);
  const showBreakdown = () => {
    SetIsShowBreakdown(!isShowBreakdown);
  };
  return (
    <>
      {
        <Box p={5} mt={2} boxShadow="md" rounded="md" bg="white">
          <Heading fontSize={16} fontWeight="bold">
            Error Budget Consumption by SLIs
            <Tooltip
              hasArrow
              label={
                'Indicates the consumption of error budget across different SLIs, including the number of incidents affecting each of the SLIs.'
              }
              {...tooltipStyle}
              size={'small'}
            >
              <InfoIcon ml={1} />
            </Tooltip>
          </Heading>

          {sliListArray.length ? (
            <>
              <HStack spacing={0} mt={5} mb={2}>
                {sliListArray.map((sli, index) => {
                  return (
                    <Tooltip
                      key={index}
                      hasArrow
                      label={
                        <VStack>
                          <Text>{sli.sli}</Text>
                          <Text fontWeight={'bold'}>
                            {getPercentage(sli.error_budget_spent, totalErrBudget)}
                            {'%'}
                          </Text>
                          <Text>{`(${sli.count_of_incidents})`}</Text>
                        </VStack>
                      }
                      {...tooltipStyle}
                    >
                      {
                        <Box
                          key={index}
                          m={0}
                          borderLeftRadius={index == 0 ? 50 : 0}
                          borderRightRadius={index == sliListArray.length - 1 ? 50 : 0}
                          bg={colorPallet[index]}
                          w={`${getPercentage(sli.error_budget_spent, totalErrBudget)}%`}
                          p={1}
                        ></Box>
                      }
                    </Tooltip>
                  );
                })}
                {!totalErrBudget && (
                  <Box m={0} borderRightRadius={50} bg="gray.100" w="100%" p={1}></Box>
                )}
              </HStack>
              <HStack spacing="3">
                {sliListArray.map((sli, index) => {
                  return (
                    <HStack key={index}>
                      <Circle size="10px" color="white" bg={colorPallet[index]} />
                      <Text fontSize={14} color="muted">
                        {sli.sli}
                      </Text>
                    </HStack>
                  );
                })}
              </HStack>
              <Flex justifyContent={'end'}>
                <Button
                  fontSize={14}
                  textDecoration={'underline'}
                  color={'#145686'}
                  variant="link"
                  onClick={showBreakdown}
                >
                  {isShowBreakdown ? 'Hide' : 'Show'} Breakdown
                </Button>
              </Flex>

              {isShowBreakdown && (
                <Box>
                  <Box
                    padding="5"
                    border="1px solid"
                    borderColor="#A6B5C5"
                    borderRadius="md"
                    width={'745px'}
                  >
                    <TableContainer>
                      <Table size="sm">
                        <Thead>
                          <Tr>
                            <Th color={'#627C98'}>SLI</Th>
                            <Th color={'#627C98'} isNumeric>
                              Error Budget Consumed(%)
                            </Th>
                            {/* <Th color={'#627C98'} isNumeric>
                              Error Budget Consumed(mins)
                            </Th> */}
                            <Th color={'#627C98'} isNumeric>
                              INCIDENTS
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {sliListArray.map((sli, index) => {
                            return (
                              <Tr key={index}>
                                <Td>{sli.sli}</Td>
                                <Td isNumeric>
                                  {getPercentage(sli.error_budget_spent, totalErrBudget)}%
                                </Td>
                                {/* <Td isNumeric>{sli.error_budget_spent.toFixed(2)}</Td> */}
                                <Td isNumeric>{sli.count_of_incidents}</Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box mt={4}>
                    <Text color={'#627C98'}>Services Associated with this SLO</Text>
                    <UnorderedList fontSize={14} marginLeft={2} paddingLeft={4} paddingTop={2}>
                      {getServicesList(serviceIds, orgServices).map(_ => (
                        <ListItem key={_}>{_}</ListItem>
                      ))}
                    </UnorderedList>
                  </Box>
                </Box>
              )}
            </>
          ) : null}

          {(sliListArray.length == 0 || isError) && (
            <HStack spacing={0} mt={5} mb={2}>
              <Box m={0} borderRightRadius={50} bg="gray.100" w="100%" p={1}></Box>
            </HStack>
          )}
        </Box>
      }
    </>
  );
};
