import { INIT_ORG, INIT_ORG_FAIL, INIT_ORG_SUCCESS, RESET_STATE } from '../../const/init';
import { INITIAL_STATE } from '../state';

const initialOrgState = INITIAL_STATE.INIT_ORG;

const initOrg = (state = initialOrgState, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{
          orgId: '',
        },
      };
    case INIT_ORG:
      return {
        ...state,
        ...{
          orgId: action.payload.orgId,
        },
      };
    case INIT_ORG_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case INIT_ORG_FAIL:
      return {
        ...state,
        ...action.payload.error,
      };
    default:
      return state;
  }
};

export default initOrg;
