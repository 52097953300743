import React, { useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';

import { IDataItem } from 'core/interfaces/IRunBook';
import { DragAndDropIcon, DeleteIcon } from '../../../../icons';

import ReactMarkdown from 'react-markdown';
import {
  Grid,
  Theme,
  ErrorBlock,
  InputComboBlockWithMde,
  CopyIconButton,
  MdBlock,
} from 'uie/components';

import FileUploadService, {
  FileUploadFeature,
  OperationType,
} from 'core/services/service.fileUpload';

import { fileUploadViewConfig } from 'views/config/fileUploadConfig';
import { uploadType, placeholders, markupType, bgDrag } from './runbooks.constant';
import gfm from 'remark-gfm';
import breaks from 'remark-breaks';
import './index.css';
import FileUploadInput from './common/fileuploadInput';
import { Box } from '@chakra-ui/react';

const { imageTexts } = fileUploadViewConfig;
const { theme } = Theme;

interface IProps {
  items: Array<{ content: string; type: string }>;
  fileUploadService: FileUploadService;
  updateState: (tableData: Array<{ content: string; type: string }>) => void;
  currentState: string;
  errorMsg: { title: boolean; steps: boolean; step: Array<number> };
  hasUpdate: boolean;
  hasCreate: boolean;
  showSnackBar: (msg: string, bg: string, timeout: number) => void;
}

interface IState {
  items: Array<{ content: string; type: string }>;
  message: string;
  autoSuggestFocusState: { currentIndex: number; maxIndex: number };
  suggestions: {
    name: string;
    _id: string;
    type: 'users';
  }[];
  contentType: string;
  errorMsg: { title: boolean; steps: boolean; step: Array<number> };
}

const renderMdLink = (props: any) => (
  <a href={props.href} target="_blank" rel="noopener noreferrer">
    {props.children}
  </a>
);

const CustomCodeRenderer = (props: any) => {
  return (
    <div className="incident_details__code-container">
      <pre className="incident_details__raw-code">{props.value}</pre>
      <div className="incident_details__code-copy">
        <CopyIconButton type="transparent">{props.value}</CopyIconButton>
      </div>
    </div>
  );
};

export class RunbookSteps extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const items = props.items;
    this.state = {
      items,
      message: '',
      autoSuggestFocusState: { currentIndex: 0, maxIndex: 0 },
      suggestions: [],
      contentType: markupType.VISUAL,
      errorMsg: { title: false, steps: false, step: [] },
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({ items: this.props.items });
    }

    if (prevProps.errorMsg !== this.props.errorMsg) {
      this.setState({ errorMsg: this.props.errorMsg });
    }
  }

  updateParentState = (updatedItems: Array<{ content: string; type: string }>) => {
    this.props.updateState(updatedItems);
  };

  handleDragEnd = (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    this.setState(({ items, errorMsg }) => {
      const temp = items;
      const steps = errorMsg.step;
      const d = temp[result.destination!.index];
      const errorSrc = steps.includes(result.source.index);
      const errorDest = steps.includes(result.destination!.index);
      const step_ = [
        ...steps.filter(item => {
          return errorSrc ? item !== result.source.index : item !== result.destination!.index;
        }),
        ...(errorSrc ? [result.destination!.index] : errorDest ? [result.source!.index] : []),
      ];

      temp[result.destination!.index] = temp[result.source.index];
      temp[result.source.index] = d;

      return { items, errorMsg: { ...errorMsg, ...(!errorMsg.steps ? { step: step_ } : []) } };
    });
  };

  onHandleMessageChange = (item: IDataItem, index: number) => (content: string) => {
    this.setState(({ items }) => {
      const selected = items[index];
      selected.content = content;
      return { items };
    });
  };

  onDeleteStep = (item: IDataItem, index: number) => () => {
    const { items, errorMsg } = this.state;
    const updatedItems = items.filter((_, i) => i !== index);
    const steps = errorMsg.step;
    const step_ = steps
      .filter(_ => _ !== index)
      .map(item => {
        if (item > index) return item - 1;
        return item;
      });
    this.setState({
      items: updatedItems,
      errorMsg: { ...errorMsg, ...(!errorMsg.steps ? { step: step_ } : []) },
    });

    this.updateParentState(updatedItems);
  };

  onFileUpload =
    (item: IDataItem, index: number, type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      let selectedFile = null;
      if (files && files.length) selectedFile = files[0];
      const onSuccess = (e: string) => {
        const isImage = type === uploadType.IMAGE;
        const content = isImage
          ? `${item.content} ![Image](${e})`
          : `${item.content} [Attached File](${e})`;
        this.onHandleMessageChange(item, index)(content);
      };
      const onFail = (err: string) => {
        alert(err);
      };
      selectedFile &&
        this.props?.fileUploadService.getUploadFunctionForFeature(
          FileUploadFeature.NOTES,
          OperationType.CREATE,
        )(selectedFile, onSuccess, onFail);
    };

  render() {
    const { theme } = Theme;
    const { items, errorMsg } = this.state;
    const { currentState, hasUpdate, hasCreate } = this.props;
    return (
      <div>
        <div>
          <DragDropContext onDragEnd={this.handleDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(droppableProvided: DroppableProvided) => (
                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                  {items.map((item: IDataItem, index: number) => (
                    <Draggable key={index} draggableId={`Step_${index}`} index={index}>
                      {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                        return (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            style={{
                              ...draggableProvided.draggableProps.style,
                              width: snapshot.isDragging ? '80%' : '100%',
                              background: snapshot.isDragging
                                ? bgDrag.isDragging
                                : bgDrag.notDragging,
                            }}
                          >
                            <div>
                              <div>
                                <Grid
                                  flexWidth={12}
                                  justifyContent="flex-start"
                                  alignItems="flex-start"
                                >
                                  {(hasUpdate || hasCreate) && (
                                    <div
                                      {...draggableProvided.dragHandleProps}
                                      className="runbook_display_drag_icon"
                                    >
                                      <DragAndDropIcon />
                                    </div>
                                  )}
                                  {currentState === markupType.MARKDOWN ? (
                                    <Box zIndex={0} width={'100%'}>
                                      <MdBlock
                                        value={item.content}
                                        height="100%"
                                        width="97%"
                                        className={
                                          errorMsg.step.includes(index) ? 'runbook_stepClass' : ''
                                        }
                                        onChange={this.onHandleMessageChange(item, index)}
                                        options={{
                                          spellChecker: false,
                                          placeholder: placeholders.stepPlaceholder,
                                          indentWithTabs: false,
                                          imageUploadFunction:
                                            this.props?.fileUploadService.getUploadFunctionForFeature(
                                              FileUploadFeature.NOTES,
                                              OperationType.CREATE,
                                            ),
                                          ...fileUploadViewConfig,
                                          imageTexts: {
                                            sbInit: ``,
                                            ...imageTexts,
                                          },
                                          renderImageUploadsAsLinks: false,
                                        }}
                                      />
                                    </Box>
                                  ) : (
                                    <div className="runbook__markdown">
                                      <ReactMarkdown
                                        className="runbook_reactMD incident_details__mde"
                                        plugins={[breaks, gfm]}
                                        renderers={{ link: renderMdLink, code: CustomCodeRenderer }}
                                        source={item.content}
                                      />
                                    </div>
                                  )}

                                  {(hasUpdate || hasCreate) && (
                                    <DeleteIcon
                                      onClick={this.onDeleteStep(item, index)}
                                      stroke={theme.danger.default}
                                      className="runbook_edit_delete_btn"
                                    />
                                  )}
                                </Grid>
                                {errorMsg.step.includes(index) && (
                                  <ErrorBlock className="runbook_step_err">
                                    Runbook should not have empty steps.
                                  </ErrorBlock>
                                )}
                                <br />
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    );
  }
}
