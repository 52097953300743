import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Circle, Flex, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import { generatePath, Link } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import { hasReadAccessToEntity } from 'views/main/organization/navigation-flows/helpers';
import useUINavigationFunctions from 'views/main/organization/navigation-flows/useUINavigationFunctionV2';
import { ESCALATION_POLICIES_LIST_PATH } from 'views/main/routes/routes';
import { ServiceTooltip } from '../../components';
import StackLimit, { IconType } from '../../components/StackLimit';
import { durationUnits } from '../../helpers/helper.date';
import { getStatus, getStatusColor } from '../../helpers/helper.service';
import { IServiceList } from '../../hooks/useServiceList';

export type ServiceDetail = IServiceList['data']['services'][0];
export type ServiceTableColumns = Partial<Column<any>>;
export type status = 'on_maintenance' | 'healthy' | 'unhealthy';

export const tableColumns = [
  {
    Header: 'NAME',
    accessor: (row: ServiceDetail) => row.name,
    Cell: (cell: Cell<ServiceDetail>) => {
      const {
        row: {
          original: { name, tags, id },
        },
      } = cell;
      return (
        <VStack justifyContent="flex-start" alignItems={'flex-start'}>
          <Link to={`/service-catalog/${id}?tab=summary`}>
            <ServiceTooltip text={name}>
              <Text fontSize="sm" fontWeight={500} noOfLines={1} maxW={{ lg: 200, xl: 500 }}>
                {name.length > 30 ? `${name.substr(0, 30)}...` : name}
              </Text>
            </ServiceTooltip>
          </Link>

          <StackLimit
            limit={1}
            type="tag"
            data={tags?.map(tag => ({ name: `${tag.key} : ${tag.value}` })) || []}
          />
        </VStack>
      );
    },
  },
  {
    Header: 'ALERT SOURCE',
    Cell: (cell: Cell<ServiceDetail>) => {
      const {
        row: {
          original: { active_alert_sources },
        },
      } = cell;

      return active_alert_sources.length ? (
        <Box alignItems="flex-start">
          <StackLimit
            limit={2}
            type="icon"
            data={
              active_alert_sources?.map(alert_source => ({
                icon: alert_source.short_name as IconType,
                name: alert_source.type,
              })) || []
            }
          />
        </Box>
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'EXTENSION',
    Cell: (cell: Cell<ServiceDetail>) => {
      const {
        row: {
          original: { extensions },
        },
      } = cell;

      const isExtension = !!(extensions && Object.values(extensions).filter(Boolean).length);

      return isExtension ? (
        <StackLimit
          limit={2}
          type="icon"
          data={
            Object.keys(extensions)
              ?.filter(ext => extensions[ext as keyof typeof extensions])
              ?.map(ext => ({ icon: ext as IconType })) || []
          }
        />
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'OWNER & ESCALATION POLICY',
    Cell: (cell: Cell<ServiceDetail>) => {
      const {
        row: {
          original: { escalation_policy, maintainer },
        },
      } = cell;
      const { getEntityLink, getEntityName } = useUINavigationFunctions();

      let maintainerLink = '';
      if (maintainer) {
        maintainerLink = getEntityLink(maintainer.type, maintainer.id) ?? '';
      }
      let escalationPolicyLink = '';
      if (hasReadAccessToEntity('escalationpolicy')) {
        escalationPolicyLink = generatePath(ESCALATION_POLICIES_LIST_PATH, {
          id: escalation_policy.id,
        });
      }

      return (
        <StackLimit
          direction="column"
          limit={2}
          type="icon-text"
          data={[
            ...(maintainer
              ? [
                  {
                    name: maintainer?.name ?? getEntityName(maintainer.type, maintainer.id),
                    username: maintainer?.username_for_display ?? '',
                    icon: maintainer.type as IconType,
                    to: maintainerLink,
                  },
                ]
              : []),

            {
              name: escalation_policy.name,
              icon: 'escalation' as IconType,
              to: escalationPolicyLink,
            },
          ]}
        />
      );
    },
  },

  {
    id: 'open_incidents',
    Header: 'LIFETIME OPEN INCIDENTS',
    Cell: (cell: Cell<ServiceDetail>) => {
      const {
        row: {
          original: { metrics },
        },
      } = cell;

      return metrics ? (
        <StackLimit
          direction="column"
          limit={2}
          type="count-text"
          data={Object.entries(metrics.incident_metrics)
            .slice(0, 2)
            .map(([k, v], i) => ({ name: k.charAt(0).toUpperCase() + k.slice(1), count: v }))}
        />
      ) : (
        <></>
      );
    },
  },
  {
    id: 'mtta',
    Header: 'LIFETIME MTTA',
    accessor: (row: ServiceDetail) =>
      row.metrics ? row.metrics.responsiveness.mtta.toFixed(2) : '',
    Cell: function comp(row: any) {
      return (
        <HStack>
          <Text fontSize="sm">{durationUnits(row.cell.value).display}</Text>
        </HStack>
      );
    },
  },
  {
    id: 'mttr',
    Header: 'LIFETIME MTTR',
    accessor: (row: ServiceDetail) =>
      row.metrics ? row.metrics.responsiveness.mttr.toFixed(2) : '',
    Cell: function comp(row: any) {
      return (
        <HStack>
          <Text fontSize="sm">{durationUnits(row.cell.value).display}</Text>
        </HStack>
      );
    },
  },
  {
    id: 'status',
    Header: 'STATUS',
    Cell: (cell: Cell<ServiceDetail>) => {
      const {
        row: {
          original: { status, metrics },
        },
      } = cell;

      let serviceStatus = status;
      if (
        (metrics.incident_metrics.triggered || metrics.incident_metrics.acknowledged) &&
        serviceStatus != 'on_maintenance'
      ) {
        serviceStatus = 'unhealthy';
      }

      return (
        <Flex gap={2} alignItems="center">
          <Circle size="12px" bg={getStatusColor(serviceStatus)} color="white" />
          <Text variant="smCapitalize">{getStatus(serviceStatus)}</Text>
        </Flex>
      );
    },
  },
  {
    id: 'expand-view',
    Cell: (cell: Cell<ServiceDetail>) => (
      <div
        {...cell.row.getToggleRowExpandedProps()}
        title={cell.row.isExpanded ? 'Collapse' : 'Expand'}
      >
        <IconButton
          bgColor="transparent"
          color={'blackAlpha.700'}
          aria-label={cell.row.isExpanded ? 'Collapse' : 'Expand'}
          icon={cell.row.isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        />
      </div>
    ),
  },
];

export const depencencyColumns = [
  {
    Header: 'NAME',
    accessor: (row: ServiceDetail) => row.name,
    Cell: (cell: Cell<ServiceDetail>) => {
      const {
        row: {
          original: { name, tags },
        },
      } = cell;
      return (
        <VStack alignItems={'flex-start'}>
          <Text fontSize="sm" fontWeight={500}>
            {name}
          </Text>
          <StackLimit
            limit={1}
            type="tag"
            data={tags?.map(tag => ({ name: `${tag.key} : ${tag.value}` })) || []}
          />
        </VStack>
      );
    },
  },
  {
    Header: 'ALERT SOURCE',
    Cell: (cell: Cell<ServiceDetail>) => {
      const {
        row: {
          original: { active_alert_sources },
        },
      } = cell;

      return active_alert_sources.length ? (
        <StackLimit
          limit={2}
          type="icon"
          data={
            active_alert_sources?.map(alert_source => ({ icon: alert_source.type as IconType })) ||
            []
          }
        />
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'EXTENSION',
    Cell: (cell: Cell<ServiceDetail>) => {
      const {
        row: {
          original: { extensions },
        },
      } = cell;

      const isExtension = !!(extensions && Object.values(extensions).filter(Boolean).length);

      return isExtension ? (
        <StackLimit
          limit={2}
          type="icon"
          data={Object.keys(extensions)?.map(ext => ({ icon: ext as IconType })) || []}
        />
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'OWNER & ESCALATION POLICY',
    Cell: (cell: Cell<ServiceDetail>) => {
      const {
        row: {
          original: { escalation_policy, owner },
        },
      } = cell;

      return (
        <StackLimit
          direction="column"
          limit={2}
          type="icon-text"
          data={[
            ...(owner
              ? [
                  {
                    name: owner?.name,
                    icon: 'user' as IconType,
                    to: `/settings/teams/${owner?.id}`,
                  },
                ]
              : []),
            { name: escalation_policy.name, icon: 'user' as IconType, to: `/escalationpolicies` },
          ]}
        />
      );
    },
  },

  {
    id: 'open_incidents',
    Header: 'LIFETIME OPEN INCIDENTS',
    Cell: (cell: Cell<ServiceDetail>) => {
      const {
        row: {
          original: { metrics },
        },
      } = cell;
      return metrics ? (
        <StackLimit
          direction="column"
          limit={2}
          type="count-text"
          data={Object.entries(metrics.incident_metrics)
            .slice(0, 2)
            .map(([k, v], i) => ({ name: k.charAt(0).toUpperCase() + k.slice(1), count: v }))}
        />
      ) : (
        <></>
      );
    },
  },
  {
    id: 'mtta',
    Header: 'MTTA',
    accessor: (row: ServiceDetail) =>
      row.metrics ? row.metrics.responsiveness.mtta.toFixed(2) : '',
    Cell: function comp(row: any) {
      return (
        <HStack>
          <Text fontSize="sm">{row.cell.value} hr</Text>
        </HStack>
      );
    },
  },
  {
    id: 'mttr',
    Header: 'MTTR',
    accessor: (row: ServiceDetail) =>
      row.metrics ? row.metrics.responsiveness.mttr.toFixed(2) : '',
    Cell: function comp(row: any) {
      return (
        <HStack>
          <Text fontSize="sm">{row.cell.value} hr</Text>
        </HStack>
      );
    },
  },
  {
    id: 'status',
    Header: 'STATUS',
    accessor: (row: ServiceDetail) => row.status,
    Cell: (cell: Cell<ServiceDetail>) => {
      const {
        row: {
          original: { status },
        },
      } = cell;
      const bgColor = status
        ? status === 'healthy'
          ? 'green.400'
          : status.includes('maintenance')
          ? 'gray.400'
          : 'orange.400'
        : 'gray.400';
      return (
        <Flex gap={2} alignItems="center">
          <Circle size="12px" bg={bgColor} color="white" />
          <Text variant="smCapitalize">{status}</Text>
        </Flex>
      );
    },
  },
  {
    id: 'expand-view',
    Cell: (cell: Cell<ServiceDetail>) => (
      <div
        {...cell.row.getToggleRowExpandedProps()}
        title={cell.row.isExpanded ? 'Collapse' : 'Expand'}
      >
        <IconButton
          bgColor="transparent"
          aria-label={cell.row.isExpanded ? 'Collapse' : 'Expand'}
          icon={cell.row.isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        />
      </div>
    ),
  },
];
