import { ChevronRightIcon, InfoIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Circle,
  Flex,
  Grid,
  Heading,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  ScaleFade,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Locale } from 'core/helpers/dateUtils';
import { useCompareGlobalAndEntityTeamId } from 'core/hooks/useCompareGlobalAndEntityTeamId';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { Formik } from 'formik';
import { VerticalDots } from 'icons';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import qs from 'query-string';
import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import {
  EntityType,
  hasReadAccessToEntity,
} from 'views/main/organization/navigation-flows/helpers';
import { ESCALATION_POLICIES_LIST_PATH } from 'views/main/routes/routes';
import { CustomDrawerComponent, Layout } from 'views/shared/components';
import { OrganizationContext } from '..';
import useUINavigationFunctions from '../../navigation-flows/useUINavigationFunctionV2';
import { OverlayModal } from '../common';
import { Header, Loader, ServiceTooltip } from '../components';
import { AlertDialogComponent } from '../components/Alert';
import StackLimit from '../components/StackLimit';
import { linkStyles, serviceListUrl } from '../contants/service.contant';
import useQueryParams from '../helpers/helper.query-params';
import { getStatus, getStatusColor, getStatusTooltip } from '../helpers/helper.service';
import { useDeleteService } from '../hooks/useDeleteService';
import { useEditService } from '../hooks/useEditService';
import {
  useServiceDetailQuery,
  useServiceMetricsByRangeQuery,
  useServiceMetricsQuery,
  useServiceStatsQuery,
} from '../hooks/useServiceDetail';
import { useServiceOwnerQuery } from '../hooks/useServiceOwner';
import EditServiceForm, { validateForm } from '../service.add/addAlertSourceForm/addServiceForm';
import ServiceDetailAttribute from './service.attribute';
import ServiceMetrics from './service.attribute/service.metrics';
import { ServiceDeleteErrorInfo } from './service.delete.popup';
import ServiceExtensions, { ServiceExtensionsModal } from './service.extensions';
import { ServiceDetailReducer } from './servicedetail.reducer';

type State = 'loading' | 'failed' | 'done';
const getLoadingState = (isLoading: boolean, isSuccess: boolean, isError: boolean): State => {
  if (isLoading) return 'loading';
  if (isSuccess) return 'done';
  if (isError) return 'failed';

  return 'loading';
};

const editServiceQueryParam = 'edit';

function ServiceDetail() {
  const history = useHistory();
  const query = useQueryParams();
  const organization = useContext(OrganizationContext);
  const { data: serviceOwners = [] } = useServiceOwnerQuery(
    organization?.selectedTeam.teamId ?? '',
  );

  const { serviceId } = useParams<{ serviceId: string }>();

  // alert dialog for delete confirmation
  const { isOpen, onOpen, onClose } = useDisclosure();
  // delete error dialog- shows info
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [entityNames, setEntityNames] = useState<string[]>([]);

  const showModal = (names: string[]) => {
    setEntityNames(names);
    onClose();
    onDeleteOpen();
  };

  const teamName = organization?.selectedTeam.team?.name;
  const { deleteSelectedService, isDeleting } = useDeleteService(showModal);

  const editQueryParam = query.get(editServiceQueryParam);

  const {
    data: serviceDetail,
    isLoading: isDetailLoading,
    isSuccess: isDetailSuccess,
    isError: isDetailError,
    refetch: refetchServiceDetail,
  } = useServiceDetailQuery(serviceId);

  const hasUpdateAccess = useUserAccess().hasUpdateAccess;
  const hasDeleteAccess = useUserAccess().hasDeleteAccess;

  const hasUpdate = hasUpdateAccess('services', serviceId);
  const hasDelete = hasDeleteAccess('services', serviceId);
  const { handleDifferentGlobalAndEntityTeamId } = useCompareGlobalAndEntityTeamId();

  const teamId = useMemo(() => serviceDetail?.owner?.id || '', [serviceDetail?.owner?.id]);

  useEffect(() => {
    handleDifferentGlobalAndEntityTeamId(teamId);
  }, [teamId]);

  const [
    { data: serviceDetailStatsMonthly, isLoading: isStatsMonthlyLoading },
    { data: serviceDetailStats, isLoading: isStatsLoading },
  ] = [
    useServiceStatsQuery(serviceId, teamId, isDetailSuccess, 'month'),
    useServiceStatsQuery(serviceId, teamId, isDetailSuccess, 'lifelong'),
  ];
  const { data: serviceDetailMetricsMonthly, isLoading: isMetricsMonthlyLoading } =
    useServiceMetricsQuery(serviceId, teamId, isDetailSuccess);
  const { data: serviceDetailMetricsLifelong, isLoading: isMetricsLifelongLoading } =
    useServiceMetricsByRangeQuery(serviceId);

  const [deleteServiceId, setDeleteServiceID] = useState<string | null>(null);
  const [enabledExtensionModal, setEnabledExtensionModal] = useState<'slack' | 'jira' | null>(null);
  const hideExtensionModal = () => setEnabledExtensionModal(null);

  const {
    acceptableEntityTypesForPopover,
    getEntityComponent,
    getEntityLink,
    getEntityName,
    userHasReadSquadsPermission,
  } = useUINavigationFunctions();

  const [state, dispatch] = useReducer(ServiceDetailReducer, {
    drawer: {
      isDrawerOpen: false,
      drawerType: null,
      serviceID: null,
    },
  });

  const initialState = {
    owner_id: organization?.selectedTeam?.teamId,
    name: serviceDetail?.name ?? '',
    escalation_policy_id: serviceDetail?.escalation_policy?.id ?? '',
    maintainer: serviceDetail?.maintainer
      ? { id: serviceDetail?.maintainer?.id, type: serviceDetail?.maintainer?.type ?? '' }
      : null,
    description: serviceDetail?.description ?? '',
    tags: serviceDetail?.tags ? serviceDetail?.tags : [{ key: '', value: '' }],
    duplicateTags: {} as Record<string, number>,
    apta_is_enabled: serviceDetail?.auto_pause_transient_alerts_config?.is_enabled ?? false,
    apta_timeout_in_mins: serviceDetail?.auto_pause_transient_alerts_config?.timeout_in_mins ?? 0,
    iag_is_enabled: serviceDetail?.intelligent_alerts_grouping_config?.is_enabled ?? false,
    iag_rolling_window_in_mins:
      serviceDetail?.intelligent_alerts_grouping_config?.rolling_window_in_mins ?? 0,
    delay_notification_config: serviceDetail?.delay_notification_config,
    delay_notification_config_is_enabled:
      serviceDetail?.delay_notification_config?.is_enabled ?? false,
  };

  const { editService_, isEditing, setAptaRecommendedSelected } = useEditService(initialState);

  const onFormSubmit = async (value: typeof initialState) => {
    try {
      if (value.delay_notification_config) {
        value.delay_notification_config.is_enabled = value.delay_notification_config_is_enabled;
      }
      await editService_({
        ...value,
        auto_pause_transient_alerts_config: {
          is_enabled: value.apta_is_enabled || false,
          timeout_in_mins: value.apta_is_enabled ? value.apta_timeout_in_mins || 0 : 0,
        },
        intelligent_alerts_grouping_config: {
          is_enabled: value.iag_is_enabled || false,
          rolling_window_in_mins: value.iag_is_enabled ? value.iag_rolling_window_in_mins || 0 : 0,
        },
        delay_notification_config: value.delay_notification_config,
        serviceId: serviceDetail?.id,
        maintainer: value.maintainer?.id ? value.maintainer : null,
      });
      onEditService(false)();
    } catch (error) {
      console.error(error);
    }
  };

  const onEditService = (openEditForm: boolean) => () => {
    history.replace({
      pathname: history.location.pathname,
      search: openEditForm
        ? qs.stringifyUrl({
            url: history.location.search,
            query: {
              [editServiceQueryParam]: serviceId,
            },
          })
        : qs.exclude(history.location.search, [editServiceQueryParam]),
    });
  };

  useEffect(() => {
    if (editQueryParam) {
      dispatch({
        type: 'EDIT_SERVICE',
        isDrawerOpen: true,
        drawerType: 'EDIT',
        serviceID: serviceId,
      });
    } else {
      dispatch({
        type: 'EDIT_SERVICE',
      });
    }
  }, [editQueryParam]);

  /** This useEffect was needed because the scroll state from previous page does not change when
  the user navigates to the service details page (essentially because it only loads a new view)
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const serviceData = useMemo(() => {
    const { status, maintainer, escalation_policy, next_maintenance_window, extensions } =
      serviceDetail || {};

    /** Properties have been added to the Text component manually because
    the popover uses library theme and it is overriding the chakra theme defined by the
    service catalog */

    const maintainerName = maintainer
      ? maintainer.name ?? getEntityName(maintainer.type as EntityType, maintainer.id)
      : 'N/A';

    const simpleComponent = (
      <Text fontSize="sm" fontFamily="inherit" width="max-content" textTransform="capitalize">
        {maintainerName}
      </Text>
    );

    let maintainerComponent = simpleComponent;

    if (maintainer?.name && maintainer?.type && maintainer?.id) {
      const simpleComponentWithLinkStyles = (
        <Text
          fontSize="sm"
          fontFamily="inherit"
          width="max-content"
          textTransform="capitalize"
          style={linkStyles}
        >
          {maintainerName}
        </Text>
      );

      if (maintainer && hasReadAccessToEntity(maintainer?.type as EntityType)) {
        maintainerComponent = getEntityComponent({
          componentType: acceptableEntityTypesForPopover.includes(maintainer?.type)
            ? 'linkWPopover'
            : 'linkWOPopover',
          id: maintainer?.id,
          type: maintainer?.type as EntityType,
          popoverTrigger: simpleComponentWithLinkStyles,
          popoverPlacement: 'bottom',
        });
      }
    }

    let escalationPolicyComponent = <Text variant="smCapitalize">{escalation_policy?.name}</Text>;

    if (hasReadAccessToEntity('escalationpolicy')) {
      escalationPolicyComponent = (
        <Link
          style={linkStyles}
          to={generatePath(ESCALATION_POLICIES_LIST_PATH, { id: escalation_policy?.id ?? 0 })}
        >
          {escalationPolicyComponent}
        </Link>
      );
    }

    return [
      {
        key: 'status',
        title: 'STATUS',
        body: (
          <Flex gap={2} alignItems="center">
            <Circle size="12px" bg={getStatusColor(status ?? '')} color="white" />
            <Text fontSize="sm" cursor="default">
              {getStatus(status ?? '')}
            </Text>

            <ServiceTooltip text={getStatusTooltip(status ?? '')} placement="top">
              <InfoOutlineIcon w={3.5} h={3.5} ml={0.5} />
            </ServiceTooltip>
          </Flex>
        ),
      },
      {
        key: 'owner',
        title: 'OWNER',
        body: maintainerComponent,
      },
      {
        key: 'escalation_policy',
        title: 'ESCALATION POLICY',
        body: escalationPolicyComponent,
      },
      {
        key: 'maintenance',
        title: 'NEXT MAINTENANCE',
        body: next_maintenance_window ? (
          <Stack direction="row" alignItems="center">
            <Text cursor="default" fontSize="sm">
              <Tooltip
                hasArrow
                bgColor="white"
                color="gray.700"
                fontWeight={400}
                label={Locale.toShortDateTimeWithOffset(
                  next_maintenance_window.maintenance_from ?? '',
                )}
              >
                {Locale.toShortDateTimeWoYear(next_maintenance_window.maintenance_from ?? '')}
              </Tooltip>
            </Text>

            <Text>-</Text>

            <Text cursor="default" fontSize="sm">
              <Tooltip
                hasArrow
                bgColor="white"
                color="gray.700"
                fontWeight={400}
                label={Locale.toShortDateTimeWithOffset(
                  next_maintenance_window.maintenance_till ?? '',
                )}
              >
                {Locale.toShortDateTimeWoYear(next_maintenance_window.maintenance_till ?? '')}
              </Tooltip>
            </Text>
          </Stack>
        ) : (
          <Text fontSize="sm">N/A</Text>
        ),
      },
      {
        key: 'extensions',
        title: 'EXTENSIONS',
        body:
          extensions && Object.values(extensions).some(ext => ext) ? (
            <ServiceExtensions
              enabledExtensions={extensions}
              setEnabledExtensionModal={ext => setEnabledExtensionModal(ext)}
            />
          ) : (
            'N/A'
          ),
      },
    ];
  }, [serviceDetail, enabledExtensionModal]);

  const analyticsFilter = `{"services":["${serviceId}"],"teams":["${serviceDetail?.owner?.id}"],"showServices":["true"]}`;

  const ServiceDetailPageMemo = useCallback(() => {
    return (
      <Fragment>
        <Box m={5}>
          <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to={serviceListUrl}>
                Services
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink as={Link} to={`${serviceListUrl}/${serviceId}`}>
                <Loader.Skeleton enabled={isDetailLoading} hasError={isDetailError}>
                  <Text
                    fontSize={'14px'}
                    fontWeight={400}
                    noOfLines={1}
                    maxW={150}
                    title={serviceDetail?.name ?? ''}
                  >
                    {serviceDetail?.name ?? ''}
                  </Text>
                </Loader.Skeleton>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>

        <Header
          padding={6}
          title={
            <Box>
              <Heading
                as="h2"
                size="lg"
                noOfLines={1}
                maxW={{ lg: '50vw', xl: '50vw', '2xl': '70vw' }}
              >
                <Loader.Skeleton enabled={isDetailLoading} hasError={isDetailError}>
                  <Tooltip
                    hasArrow
                    bgColor="white"
                    color="gray.700"
                    fontWeight={400}
                    label={serviceDetail?.name ?? ''}
                  >
                    {serviceDetail?.name ?? ''}
                  </Tooltip>
                </Loader.Skeleton>
              </Heading>

              <Flex direction="column" gap={2}>
                <Text
                  fontSize={16}
                  fontWeight={400}
                  paddingTop={2}
                  noOfLines={2}
                  maxW={{ lg: '50vw', xl: '50vw', '2xl': '70vw' }}
                >
                  <Loader.Skeleton enabled={isDetailLoading} hasError={isDetailError}>
                    <Tooltip
                      hasArrow
                      bgColor="white"
                      color="gray.700"
                      fontWeight={400}
                      label={serviceDetail?.description ?? ''}
                    >
                      {serviceDetail?.description ?? ''}
                    </Tooltip>
                  </Loader.Skeleton>
                </Text>

                <StackLimit
                  limit={2}
                  type="tag"
                  data={
                    serviceDetail?.tags?.map(tag => ({ name: `${tag.key}: ${tag.value}` })) || []
                  }
                />
              </Flex>
            </Box>
          }
          actions={
            serviceDetail && (
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignSelf="flex-start"
                gap={3}
                flexGrow={1}
              >
                <Button
                  variant="outline"
                  size="sm"
                  as={Link}
                  to={`/analytics-v2/teams?filters=${analyticsFilter}`}
                >
                  View Analytics
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  as={Link}
                  to={`/incident?filter=true&services=${serviceId}`}
                >
                  View Incidents
                </Button>
                <NoPermissionTooltip isDisabled={hasUpdate}>
                  <Button
                    variant="default"
                    size="sm"
                    onClick={() =>
                      history.push(`/service-catalog/add/alertSource?service=${serviceId}`)
                    }
                    isDisabled={!hasUpdate}
                  >
                    Add Alert Source
                  </Button>
                </NoPermissionTooltip>

                <Popover size="sm" matchWidth>
                  <PopoverTrigger>
                    <IconButton
                      bgColor="whiteAlpha.900"
                      size="sm"
                      aria-label="service action"
                      icon={<VerticalDots />}
                    />
                  </PopoverTrigger>
                  <PopoverContent _focus={{ outline: 'none' }} width="15" bg="whiteAlpha.900">
                    <PopoverArrow />
                    <PopoverBody p={0} m={0}>
                      <VStack spacing={'0'}>
                        {[
                          {
                            label: 'Slack Channel',
                            action: () => setEnabledExtensionModal('slack'),
                            enabled: hasUpdate,
                          },
                          {
                            label: 'Jira Cloud Project',
                            action: () => setEnabledExtensionModal('jira'),
                            enabled: hasUpdate,
                          },
                          {
                            label: 'Edit Service',
                            action: onEditService(true),
                            enabled: hasUpdate,
                          },

                          {
                            label: 'Delete Service',
                            action: () => {
                              setDeleteServiceID(serviceId);
                              onOpen();
                            },
                            enabled: hasDelete,
                          },
                        ].map(option => (
                          <NoPermissionTooltip
                            key={option.label}
                            isDisabled={option.enabled}
                            shouldWrapChildren={false}
                          >
                            <Button
                              key={option.label}
                              w="full"
                              _hover={{ backgroundColor: 'rgba(237, 242, 247, 1)' }}
                              borderRadius={0}
                              variant={'outline'}
                              bg="white"
                              color="gray.600"
                              boxShadow="0 0 0 1px gray.200"
                              onClick={option.action}
                              isDisabled={!option.enabled}
                            >
                              {option.label}
                            </Button>
                          </NoPermissionTooltip>
                        ))}
                      </VStack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Stack>
            )
          }
        />

        <Grid
          templateColumns={{ md: '1fr 1fr 1fr', lg: '1fr 1fr 1fr 1.25fr 1.25fr' }}
          columnGap={1}
          pr={3}
          pl={3}
        >
          {serviceData.map((service, i) => (
            <Flex direction="column" py={3} px={5} key={i}>
              <Heading as="h4" size="xs" paddingBottom={3}>
                {service.title}
              </Heading>
              <Loader.Skeleton
                enabled={isDetailLoading}
                hasError={isDetailError}
                maxW="25%"
                height="16px"
              >
                {service.body}
              </Loader.Skeleton>
            </Flex>
          ))}
        </Grid>
      </Fragment>
    );
  }, [serviceId, serviceDetail, isDetailLoading, isDetailError, serviceData]);

  const ServiceDetailPage = (
    {
      failed: () => <ServiceDetailPageMemo />,
      loading: () => (
        <Center h="300px">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      ),
      done: () => <ServiceDetailPageMemo />,
    } as Record<State, FC>
  )[getLoadingState(isDetailLoading, isDetailSuccess, isDetailError)];

  if (serviceDetail && organization?.selectedTeam.teamId !== serviceDetail?.owner.id) {
    return (
      <OverlayModal
        isOpen
        enableOverlay
        content={
          <VStack justifyContent="center" mt={8}>
            <Icon as={InfoIcon} boxSize={8} color="default" />
            <Text fontSize={20}>
              Service not found on <strong>{teamName}</strong>!
            </Text>
          </VStack>
        }
        onClose={() => history.push(serviceListUrl)}
      />
    );
  }

  return (
    <Fragment>
      <Layout customheight>
        <Box m={2}>
          <ServiceDetailPage />

          <ServiceMetrics
            isDisabled={isDetailError}
            stats={serviceDetailStats ?? null}
            isStatsLoading={isDetailLoading || isStatsLoading}
            statsMonthly={serviceDetailStatsMonthly ?? null}
            isStatsMonthlyLoading={isDetailLoading || isStatsMonthlyLoading}
            metrics={serviceDetailMetricsLifelong ?? null}
            isMetricsLoading={isDetailLoading || isMetricsLifelongLoading}
            metricsMonthly={serviceDetailMetricsMonthly ?? null}
            isMetricsMonthlyLoading={isDetailLoading || isMetricsMonthlyLoading}
          />
        </Box>
      </Layout>

      <Layout customheight>
        <Box m={2}>
          <ScaleFade initialScale={0.9} in={isDetailSuccess}>
            <ServiceDetailAttribute />
          </ScaleFade>
        </Box>
      </Layout>

      <AlertDialogComponent
        isOpen={isOpen}
        onClose={onClose}
        loading={isDeleting}
        callbackFn={() => {
          deleteServiceId && deleteSelectedService(deleteServiceId);
        }}
        msg={`All the data for this service will get deleted. This is an irreversible action and you cannot restore the deleted data.`}
        title={`Are you sure you want to delete this service?`}
        isDelete={true}
      />
      {isDeleteOpen && (
        <ServiceDeleteErrorInfo
          name={serviceDetail?.name ?? ''}
          entityNames={entityNames}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
        />
      )}

      <CustomDrawerComponent
        onClose={onEditService(false)}
        isOpen={state.drawer.isDrawerOpen && state.drawer.drawerType === 'EDIT'}
        title={'Edit Service'}
        disableBodyPadding
      >
        {isDetailLoading && (
          <VStack alignItems="center" justifyContent="center" h="100%">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </VStack>
        )}
        {!isDetailLoading &&
          (isDetailError ? (
            <VStack alignItems="center" justifyContent="center" h="100%">
              <Text color="red.400">Error loading service detail</Text>
            </VStack>
          ) : (
            <Box h="100%">
              <Formik
                validate={validateForm}
                enableReinitialize
                initialValues={initialState}
                onSubmit={onFormSubmit}
              >
                <EditServiceForm
                  isEditMode
                  serviceID={serviceId}
                  setAptaRecommendedSelected={setAptaRecommendedSelected}
                  onCancel={onEditService(false)}
                  isLoading={isEditing}
                  serviceOwners={serviceOwners}
                />
              </Formik>
            </Box>
          ))}
      </CustomDrawerComponent>

      {serviceDetail && enabledExtensionModal && (
        <ServiceExtensionsModal
          enabledExtensionModal={enabledExtensionModal}
          hideExtensionModal={hideExtensionModal}
          serviceId={serviceDetail.id}
          serviceDetail={serviceDetail}
          refetchServiceDetail={refetchServiceDetail}
        />
      )}
    </Fragment>
  );
}
export default ServiceDetail;
