import { ILog } from 'core/interfaces/IIncidents';
import { DateTime } from 'luxon';
import { EntityComponent, GetEntityComponentProps, EntityType } from './helpers';
import { IAppState } from 'core/interfaces/IAppState';
import { SPLIT_WORD_MAP } from '../incidentDetails/constants';
import ResponseForPublicMessages from './jsxEntityResponse/ResponseForPublicMessage';
import ResponsesForReassigned from './jsxEntityResponse/ResponseForReassigned';
import ResponsesForDelayedNotificationResume from './jsxEntityResponse/ResponseForDelayedNotificationResume';
import ResponsesForIncidentSnoozed from './jsxEntityResponse/ResponseForIncidentSnoozed';
import ResponsesForActionIncidentUnsnooze from './jsxEntityResponse/ResponseForActionIncidentUnsnooze';
import ResponsesForReassignUserReplacement from './jsxEntityResponse/ResponseForReassignUserReplacement';
import ResponsesForWfSloWebhook from './jsxEntityResponse/ResponseForWfSloWebhook';
import ResponsesForSuppresedAtUserReplace from './jsxEntityResponse/ResponseForSuppresedAtUserReplace';
import ResponsesForManualMergeChildLog from './jsxEntityResponse/ResponseForManualMergeChildLog';
import ResponsesForManualMergeParentLog from './jsxEntityResponse/ResponseForManualMergeParentLog';
import ResponsesForIagAdditionalandRespondersAction from './jsxEntityResponse/ResponseForIagAction';
import ResponsesForMSTeamMeetingLink from './jsxEntityResponse/ResponseForMSTeamMeetingLink';
import ResponsesForNoActionMatched from './jsxEntityResponse/ResponseForNoActionMatched';
import { truncate } from 'core/helpers/stringUtils';

//all message response for activity timeline actions
export const messageResponse = (log: ILog, organization: IAppState['organization']) => {
  const { actionType, reason } = log;
  const userTimezone = organization.currentUser.u?.time_zone ?? 'utc';

  const messageForDelayedNotification = () => {
    const dateTimeStr = reason.split(': ')[1];
    const dateTime = DateTime.fromJSDate(new Date(dateTimeStr))
      .setZone(userTimezone)
      .toFormat('dd-LL-yyyy, hh:mm a');
    return `Incident Notifications are delayed till ${dateTime}`;
  };
  const messageForSloWebhookWf = () => {
    let splitWord = '';
    splitWord = SPLIT_WORD_MAP[actionType as keyof typeof SPLIT_WORD_MAP];
    if (actionType.startsWith('slo_')) splitWord = ' has ';
    if (actionType.startsWith('workflow_triggered')) splitWord = ' has been';
    if (actionType.startsWith('wf')) splitWord = 'Workflow';
    if (actionType.startsWith('wf')) {
      return reason.split(splitWord)[0];
    } else {
      return truncate(splitWord + reason.split(splitWord)[1], 70);
    }
  };
  return { messageForDelayedNotification, messageForSloWebhookWf };
};
//all jsx response for activity timeline action
export const entityResponse = (
  log: ILog,
  getEntityComponent: EntityComponent,
  logMessage: string,
  getEntityName: (type: EntityType, entityId: string | undefined) => string,
) => {
  const { additionalInfo } = log;

  const getEntityComponentWithTruncatedName = (props: GetEntityComponentProps) =>
    getEntityComponent({ ...props, truncateName: true, nameMaxLength: 30 });

  const ResponseForPublicMessage = () => {
    return (
      <ResponseForPublicMessages
        additionalInfo={additionalInfo}
        getEntityComponentWithTruncatedName={getEntityComponentWithTruncatedName}
      />
    );
  };

  const ResponseForReassigned = () => {
    return (
      <ResponsesForReassigned
        log={log}
        logMessage={logMessage}
        getEntityComponentWithTruncatedName={getEntityComponentWithTruncatedName}
      />
    );
  };

  const ResponseForDelayedNotificationResume = () => {
    return (
      <ResponsesForDelayedNotificationResume
        log={log}
        getEntityName={getEntityName}
        getEntityComponentWithTruncatedName={getEntityComponentWithTruncatedName}
      />
    );
  };
  const ResponseForIncidentSnoozed = () => {
    return (
      <ResponsesForIncidentSnoozed
        log={log}
        logMessage={logMessage}
        getEntityComponentWithTruncatedName={getEntityComponentWithTruncatedName}
      />
    );
  };

  const ResponseForActionIncidentUnsnooze = ({ triggerType }: { triggerType: string }) => {
    return (
      <ResponsesForActionIncidentUnsnooze
        log={log}
        triggerType={triggerType}
        getEntityComponentWithTruncatedName={getEntityComponentWithTruncatedName}
      />
    );
  };

  const ResponseForReassignUserReplacement = () => {
    return (
      <ResponsesForReassignUserReplacement
        log={log}
        logMessage={logMessage}
        getEntityComponentWithTruncatedName={getEntityComponentWithTruncatedName}
      />
    );
  };

  const ResponseForManualMergeChildLog = () => {
    return (
      <ResponsesForManualMergeChildLog
        log={log}
        getEntityComponent={getEntityComponent}
        getEntityComponentWithTruncatedName={getEntityComponentWithTruncatedName}
      />
    );
  };

  const ResponseForManualMergeParentLog = () => {
    return (
      <ResponsesForManualMergeParentLog
        log={log}
        getEntityComponent={getEntityComponent}
        getEntityComponentWithTruncatedName={getEntityComponentWithTruncatedName}
      />
    );
  };

  const ResponseForWfSloWebhook = () => {
    return <ResponsesForWfSloWebhook log={log} getEntityComponent={getEntityComponent} />;
  };

  const ResponseForSuppresedAtUserReplace = () => {
    return (
      <ResponsesForSuppresedAtUserReplace
        log={log}
        logMessage={logMessage}
        getEntityComponentWithTruncatedName={getEntityComponentWithTruncatedName}
      />
    );
  };

  const ResponseForIagAdditionalandRespondersAction = () => {
    return (
      <ResponsesForIagAdditionalandRespondersAction
        log={log}
        getEntityComponent={getEntityComponent}
        getEntityComponentWithTruncatedName={getEntityComponentWithTruncatedName}
      />
    );
  };
  const ResponseForMSTeamMeetingLink = () => {
    return (
      <ResponsesForMSTeamMeetingLink
        log={log}
        getEntityComponent={getEntityComponent}
        getEntityComponentWithTruncatedName={getEntityComponentWithTruncatedName}
      />
    );
  };
  const ResponseForNoActionMatched = ({
    action,
    entityName,
  }: {
    action: string;
    entityName: string | undefined;
  }) => {
    return (
      <ResponsesForNoActionMatched
        log={log}
        logMessage={logMessage}
        action={action}
        entityName={entityName}
        getEntityComponentWithTruncatedName={getEntityComponentWithTruncatedName}
        getEntityName={getEntityName}
      />
    );
  };
  return {
    ResponseForPublicMessage,
    ResponseForReassigned,
    ResponseForIncidentSnoozed,
    ResponseForSuppresedAtUserReplace,
    ResponseForDelayedNotificationResume,
    ResponseForActionIncidentUnsnooze,
    ResponseForReassignUserReplacement,
    ResponseForManualMergeChildLog,
    ResponseForManualMergeParentLog,
    ResponseForWfSloWebhook,
    ResponseForIagAdditionalandRespondersAction,
    ResponseForMSTeamMeetingLink,
    ResponseForNoActionMatched,
  };
};
