import { Box, Flex } from '@chakra-ui/layout';
import { useEffect, useMemo } from 'react';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { OperatorOptions } from '../../../constant';
import { OperationOption, OperatorTypes } from '../../../types';
import { onChangeOperator, selectedActivePriority, selectedOperator } from '../utility';
import { PriorityOption, PRIORITY_OPTIONS } from 'library/common';
import FormField from 'library/molecules/FormField/FormField';
import FormDropdown from 'library/molecules/Form/FormDropdown/FormDropdown';

interface Props {
  priorityError?: string;
  filterName: string;
  filterFieldName: string;
}

const PriorityFilter = <TFormType extends FieldValues>({
  priorityError,
  filterFieldName,
  filterName,
}: Props) => {
  const { watch, setValue } = useFormContext<TFormType>();

  // fetch operator options
  const operatorOptions = useMemo(() => {
    return OperatorOptions.filter(option => option.value.startsWith(OperatorTypes.IS));
  }, []);

  const activeOperator = selectedOperator(filterFieldName, watch, operatorOptions);
  const activePriority = selectedActivePriority(filterFieldName, watch, PRIORITY_OPTIONS);

  // set default option if we dont have any value
  useEffect(() => {
    if (!activeOperator && !activePriority) {
      setValue(
        `${filterFieldName}.type` as Path<TFormType>,
        'priority_is' as PathValue<TFormType, Path<TFormType>>,
      );
      setValue(
        `${filterFieldName}.fields.value` as Path<TFormType>,
        PRIORITY_OPTIONS[0]?.value as PathValue<TFormType, Path<TFormType>>,
      );
    }
  }, []);

  return (
    <Flex flex={1} pt={2} gap={4} flexDirection="row">
      <>
        <Box flex={1}>
          <FormDropdown<OperationOption>
            options={operatorOptions}
            name={`${filterFieldName}.operator`}
            value={activeOperator}
            onChange={op => {
              onChangeOperator(
                op,
                filterFieldName,
                filterName,
                setValue as (name: Path<TFormType>, value: any) => void,
              );
            }}
          />
        </Box>
        <Flex flex={3} flexDirection="column">
          <FormField error={priorityError} variant="floating">
            <FormDropdown
              options={PRIORITY_OPTIONS}
              name={`${filterFieldName}.entity`}
              formatOptionLabel={option => (
                <Flex gap={2}>
                  <PriorityOption value={option.value} label={option.label} />
                </Flex>
              )}
              getOptionValue={option => option.label}
              value={activePriority}
              onChange={o => {
                if (o && 'value' in o) {
                  setValue(
                    `${filterFieldName}.fields` as Path<TFormType>,
                    {
                      value: o?.value,
                    } as PathValue<TFormType, Path<TFormType>>,
                  );
                }
              }}
              placeholder="Type or Select Priority"
            />
          </FormField>
        </Flex>
      </>
    </Flex>
  );
};

export default PriorityFilter;
