import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { REQUEST_ROLES, REQUEST_ROLES_FAIL } from '../../const/public';
import { AjaxResponse } from 'rxjs/ajax';
import { getRoles } from '../../api/public/getRoles';
import { IRole } from '../../interfaces/IRole';
import { onRequestRolesSuccess } from '../../actions/public/roles';

const rolesEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ROLES),
    switchMap(() => {
      return getRoles().pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ result, data }: { result: boolean; data: IRole[] }) => {
          return onRequestRolesSuccess(data);
        }),
        catchError(error =>
          of({
            type: REQUEST_ROLES_FAIL,
            payload: error.xhr,
            error: true,
          }),
        ),
      );
    }),
  );

export default rolesEpic;
