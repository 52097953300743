import { SelectedIncident, SnoozeDetails } from '../common/types';
import { useAcknowledgeIncidentMutation } from '../graphql/mutation';
import { exception } from 'core';
import { useToast } from 'library/atoms';
import {
  T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENT_ACKNOWLEDGED,
  T_WA_UP_INCIDENT_PRIORITY_UPDATED,
} from 'core/const/tracker';
import { AppTracker } from 'shared/analytics/tracker';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { INCIDENT_ACTION_ID, INCIDENT_STATUS } from '../common/enums';
import { truncate } from '../common/util';
import { PRIORITY } from 'library/common';
import { updatePriority } from '../common/commands';
import { setCanAutoRefreshIL } from '../store/persistent-storage';
import { AnyObject } from 'library/types';

type Props = {
  setInActionBtnId: Dispatch<SetStateAction<INCIDENT_ACTION_ID>>;
  setSelectedIncident: Dispatch<SetStateAction<SelectedIncident>>;
  onSave: () => void;
  onError: (error: any, field?: string) => void;
  incidentData: AnyObject;
};

const useIncidentInlineActions = ({
  incidentData,
  setInActionBtnId,
  onSave,
  onError,
  setSelectedIncident,
}: Props) => {
  const incidentID = incidentData.id;
  const name = incidentData.message;
  const rowStatus: INCIDENT_STATUS = incidentData.status;
  const snoozeDetails: SnoozeDetails = incidentData.snoozeDetails;
  const { mutateAsync: acknowledgeIncidentMutation } = useAcknowledgeIncidentMutation();
  const toast = useToast();

  const ackIncident = () => {
    setInActionBtnId(INCIDENT_ACTION_ID.INLINE_ACKNOWLEDGE);
    acknowledgeIncidentMutation({ incidentID })
      .then(() => {
        onSave();
        toast({
          status: 'success',
          text: `Success: '${truncate(name)}' marked as Acknowledged`,
        });

        AppTracker.track(T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENT_ACKNOWLEDGED, {
          'Incident ID': [incidentID],
        });
      })
      .catch(error => {
        exception.handle('E_ACKNOWLEDGE_INCIDENT', error);
        onError(error);
      });
  };

  const onPriorityUpdate = (priority: PRIORITY) => {
    setInActionBtnId(INCIDENT_ACTION_ID.INLINE_UPDATE_PRIORITY);
    updatePriority(incidentID, priority)
      .then(() => {
        onSave();
        toast({
          status: 'success',
          text: `Success: '${truncate(name)}' priority updated`,
        });

        AppTracker.track(T_WA_UP_INCIDENT_PRIORITY_UPDATED, {
          'Incident Priority Chosen': priority,
          'Incident ID': [incidentID],
        });
      })
      .catch(error => {
        exception.handle('E_UPDATE_PRIORITY_INCIDENT', error);
        onError(error, 'priority');
      });
  };

  const setAction = (actionId: INCIDENT_ACTION_ID) => {
    setSelectedIncident({
      id: incidentID,
      name,
      ...(actionId === INCIDENT_ACTION_ID.INLINE_REASSIGN
        ? { assigneeId: incidentData.assignedTo?.id, assigneeType: incidentData.assignedTo?.type }
        : null),
    });
    setInActionBtnId(actionId);
    setCanAutoRefreshIL(false);
  };

  const isSnoozed = useMemo(() => {
    return snoozeDetails.isSnoozed;
  }, [snoozeDetails]);

  const dropdownTitle = useMemo(() => {
    if (isSnoozed) return 'Unsnooze';
    return 'Snooze';
  }, [isSnoozed]);

  return {
    incidentID,
    dropdownTitle,
    ackIncident,
    onPriorityUpdate,
    setAction,
    rowStatus,
    isSnoozed,
    name,
  };
};

export default useIncidentInlineActions;
