import { Circle, Icon, SquareProps, Text } from '@chakra-ui/react';
import { HiCheck } from 'react-icons/hi';

interface RadioCircleProps extends SquareProps {
  isCompleted: boolean;
  isActive: boolean;
  step: number;
}

export const StepCircle = (props: RadioCircleProps) => {
  const { isCompleted, isActive, step } = props;
  return (
    <Circle
      size="7"
      bg={
        isCompleted
          ? 'rgba(15, 97, 221, 1)'
          : isActive
          ? 'rgba(15, 97, 221, 1)'
          : 'rgba(231, 233, 236, 1)'
      }
      {...props}
    >
      {isCompleted ? (
        <Icon as={HiCheck} color="white" boxSize="5" />
      ) : (
        <Text color={'white'}>{step}</Text>
      )}
    </Circle>
  );
};
