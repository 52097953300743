import { CalendarViewType } from '../interface/schedule';
import { RotationViewType } from './schedules.rotation-type';

export const scheduleViews = {
  home: {
    defaultViewType: CalendarViewType.twoGridWeek,
    disabledView: [
      CalendarViewType.gridWeek,
      CalendarViewType.timeGridWeek,
      CalendarViewType.twotimeGridWeek,
    ],
  },
  preview: {
    defaultViewType: CalendarViewType.timeGridWeek,
    disabledView: [
      CalendarViewType.gridWeek,
      CalendarViewType.twoGridWeek,
      CalendarViewType.twotimeGridWeek,
      CalendarViewType.listView,
    ],
  },
  detail: {
    defaultViewType: CalendarViewType.twotimeGridWeek,
    disabledView: [CalendarViewType.gridWeek, CalendarViewType.twoGridWeek],
    defaultRotationViewType: RotationViewType.finalRotation,
  },
};

export const listViewOpacities = {
  pastEvent: 0.5,
  otherEvent: 1,
  overlappedByOverride: 0.5,
};

export const rotationGapLabel = 'Gaps';
export const rotationGapID = 0;

export const rotationOverridesLabel = 'Overrides';
export const rotationOverrideID = -1;

export const maxTagsCount = {
  detail: 5,
  home: 3,
  list: 1,
  dateClick: 2,
};

export const paginationOptions = [
  { label: '3', value: 3 },
  { label: '5', value: 5 },
  { label: '7', value: 7 },
];
export enum eventWidthThreshold {
  SINGLE_PARTICIPANT = 25,
  TWO_PARTICIPANTS = 45,
  OTHER_PARTICIPANTS = 70,
}
export enum minutesDiffThreshold {
  PARTICIPANTS = 45,
  TIME = 90,
  NAME = 150,
}

export const maxEscalationsCount = {
  details: 3,
  home: 4,
  list: 1,
};
