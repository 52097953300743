import { Box, HStack, Text } from '@chakra-ui/react';
import { truncate } from 'core/helpers/stringUtils';
import { AssigneeAvatar } from 'library/atoms';
import { AssigneeType } from 'library/enums';
import { compact } from 'lodash';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';

import { useGetAssigneeComponent, getNameComponent, Timestamp } from '../components';
import { ALERT_INFO, GER_DATA, ORG, RULESET } from '../types';
import { ROWS_INFO_MAX_LENGTH } from '../util';
import { useGetEntityName, getGerPath } from '../util/functions';
import { AlertsList } from './alerts';

export const GER_LIST_COLUMN_DEFS = (alertsList: ALERT_INFO[], organization?: ORG) => [
  {
    Header: 'NAME',
    Cell: (cell: Cell<GER_DATA>) => {
      const { id, name } = cell.row.original;
      return (
        <Box pl={6}>
          <Link to={getGerPath(id)}>{getNameComponent(name, ROWS_INFO_MAX_LENGTH)}</Link>
        </Box>
      );
    },
  },
  {
    Header: 'DESCRIPTION',
    Cell: (cell: Cell<GER_DATA>) => {
      const { description } = cell.row.original;
      return (
        <Box pl={6}>
          <Text variant="body" color="secondary.1000">
            {truncate(description, ROWS_INFO_MAX_LENGTH)}
          </Text>
        </Box>
      );
    },
  },
  {
    Header: 'OWNER',
    Cell: (cell: Cell<GER_DATA>) => {
      const { id: ownerId, type } = cell.row.original.entity_owner;
      const name = useGetEntityName(type, ownerId);
      return (
        <HStack pl={6} columnGap="4px">
          <AssigneeAvatar type={type as AssigneeType} name={name} />
          {useGetAssigneeComponent(ownerId, name, true, type, organization)}
        </HStack>
      );
    },
  },
  {
    Header: 'ALERT SOURCES',
    Cell: (cell: Cell<GER_DATA>) => {
      const configuredAlerts: RULESET[] = cell.row.original.rulesets ?? [];
      if (!configuredAlerts.length) {
        return <></>;
      }

      const iconAlertSrc = compact(
        configuredAlerts.map(alert =>
          alertsList.find(
            item =>
              item.shortName === alert.alert_source_shortname &&
              item.version === alert.alert_source_version,
          ),
        ),
      );

      return <AlertsList alerts={iconAlertSrc} />;
    },
    minWidth: 150,
  },
  {
    Header: 'CREATED BY',
    Cell: (cell: Cell<GER_DATA>) => {
      const { created_by: userId } = cell.row.original;
      const name = useGetEntityName('user', userId);
      return (
        <HStack pl={6} columnGap="4px">
          <AssigneeAvatar name={name} />
          {useGetAssigneeComponent(userId, name, true)}
        </HStack>
      );
    },
  },
  {
    Header: 'CREATED AT',
    Cell: (cell: Cell<GER_DATA>) => {
      return <Timestamp timestamp={cell.row.original.created_at} renderVertically />;
    },
    minWidth: 120,
  },
];
