import styled from 'styled-components';

interface IProps {
  height?: '24px' | '16px' | 'fit-content' | string;
  backgroundColor?: string;
  width?: string | 'fit-content';
}

const CellBlock = styled.div<IProps>`
  height: ${props => props.height || 'fit-content'};
  color: ${props => props.color || props.theme.font.default};
  background-color: ${props => props.backgroundColor || 'none'};
  box-shadow: 0 0 0 1px ${props => props.backgroundColor || props.theme.shades.grey};
  border: none;
  width: ${props => props.width || 'fit-content'};
  text-align: left;
  border-radius: 3px;
  padding: 4px 8px;
  margin: 0;

  & > p {
    color: ${props => props.theme.shades.cement};
  }
`;

/** @component */
export default CellBlock;
