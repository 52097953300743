import {
  REQUEST_ORG_ESCALATION,
  REQUEST_ORG_ESCALATION_SUCCESS,
  REQUEST_ORG_ESCALATION_FAIL,
} from '../../const/organization';
import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';
import { IEscalationPolicy } from '../../interfaces/IEscalationPolicy';

const initialOrganizationEscalation = INITIAL_STATE.organization.escalations;

const organizationEscalations = (state = initialOrganizationEscalation, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ action: RESET_STATE, e: [], error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_ESCALATION:
      return {
        ...state,
        ...{ action: REQUEST_ORG_ESCALATION, error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_ESCALATION_SUCCESS:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_ESCALATION_SUCCESS,
          e: action.payload.escalationPolicies.sort((a: IEscalationPolicy, b: IEscalationPolicy) =>
            a.name.localeCompare(b.name),
          ),
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ORG_ESCALATION_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_ESCALATION_FAIL,
          e: [],
          error: { type: REQUEST_ORG_ESCALATION_FAIL, reason: action.payload },
        },
      };
    default:
      return state;
  }
};

export default organizationEscalations;
