import { INITIAL_STATE } from '../state';
import { RESET_STATE, INIT_ORG } from '../../const/init';
import {
  APP_CONFIG__SET_MODAL_STATE,
  APP_CONFIG__SET_DASH_CONFIGS,
  APP_CONFIG__SET_LIST_CONFIGS,
  APP_CONFIG__SET_SIDEBAR_STATE,
  APP_CONFIG__SET_ANALYTICS_CONFIGS,
} from '../../const/appConfigs';

const initialAppState = INITIAL_STATE.APP_CONFIG;

const appConfigs = (state = initialAppState, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ modal: 'closed', dashboard: null, incidentList: null },
      };
    case INIT_ORG:
      return {
        ...state,
        ...{ modal: 'closed', dashboard: null, incidentList: null },
      };
    case APP_CONFIG__SET_MODAL_STATE:
      return {
        ...state,
        ...{ modal: action.payload.modal },
      };
    case APP_CONFIG__SET_DASH_CONFIGS:
      return {
        ...state,
        ...{ dashboard: action.payload.dash },
      };
    case APP_CONFIG__SET_LIST_CONFIGS:
      return {
        ...state,
        ...{ incidentList: action.payload.list },
      };
    case APP_CONFIG__SET_ANALYTICS_CONFIGS:
      return {
        ...state,
        ...{ analyticsConfigs: action.payload.config },
      };
    case APP_CONFIG__SET_SIDEBAR_STATE:
      return {
        ...state,
        ...{ sidebarState: action.payload.sidebarState },
      };
    default:
      return state;
  }
};

export default appConfigs;
