import { indexOf } from 'lodash';
import moment from 'moment';
import { DateFilterChoice } from '../../graphql/generated/types';

import { ListIncidentGroupsQuery } from '../../graphql/query';
import { filterDropdownOptions } from '../../interfaces/common';
import { SavedFilter } from '../../interfaces/filters';
import { CREATED_OPTIONS } from '../form/options';
import { POSTMORTEM_STATUS_OPTIONS } from './constant';
import { IPostmortemStatus } from 'core/interfaces/IPostmortems';
import { PRIORITY_OPTIONS } from 'library/common';
import { getIntersection, getResponders } from './util';

export const buildSavedFilterObj = (
  filterList: ListIncidentGroupsQuery['listIncidentGroups'] | undefined,
  filterDatalist: {
    serviceList: filterDropdownOptions[];
    alertSourceList: filterDropdownOptions[];
    slolist: filterDropdownOptions[];
    assignedTo: {
      meAndMySquads: boolean;
      users: filterDropdownOptions[];
      squads: filterDropdownOptions[];
      myUsers: filterDropdownOptions[];
      mySquads: filterDropdownOptions[];
    };
    serviceOwner: {
      meAndMySquads: boolean;
      users: filterDropdownOptions[];
      squads: filterDropdownOptions[];
      myUsers: filterDropdownOptions[];
      mySquads: filterDropdownOptions[];
    };
    dropdownOptions: {
      label: string;
      options: { label: string; value: string; type: string; username?: string }[];
    }[];
    tagsData: { label: string; value: string; child: string[] }[];
  },
): SavedFilter[] => {
  if (!filterList?.length) {
    return [];
  }
  const findValues = (type: 'squad' | 'user') => {
    let searchArray: any = [];
    if (type === 'squad') {
      filterDatalist?.dropdownOptions?.map((val: any) => {
        if (val.label === 'You & Your Squads' || val.label === 'Other Squads') {
          searchArray = [...searchArray, ...val.options];
        }
      });
    }
    if (type === 'user') {
      filterDatalist?.dropdownOptions?.map((val: any) => {
        if (val.label === 'You & Your Squads' || val.label === 'Users') {
          searchArray = [...searchArray, ...val.options];
        }
      });
    }
    return searchArray;
  };

  const getServiceOwnerFilter = (
    filterObj: {
      meAndMySquads: boolean;
      users?: any;
      squads?: any;
    },
    type: 'squad' | 'user',
  ) => {
    let filterArray: any = [];
    const serviceOwnerFilter = filterObj;
    if (type === 'squad') {
      const searchArray = findValues('squad');
      const requiredArray = getIntersection(
        searchArray,
        serviceOwnerFilter.squads.map((ele: string) => {
          return {
            value: ele,
          };
        }),
        'value',
      );
      filterArray = [...filterArray, ...requiredArray];
    }
    if (type === 'user') {
      const searchArray = findValues('user');
      const requiredArray = getIntersection(
        searchArray,
        serviceOwnerFilter.users.map((ele: string) => {
          return {
            value: ele,
          };
        }),
        'value',
      );
      filterArray = [...filterArray, ...requiredArray];
    }
    const mutatedArray = filterArray.map((ele: any) => {
      return {
        label: ele.label,
        value: ele.value,
      };
    });
    return mutatedArray;
  };

  return filterList.map(filter => {
    const filterData = filter.filters;
    const alert = filterData?.alertSourceIDs
      ? filterDatalist.alertSourceList.filter(obj => {
          return indexOf(filterData.alertSourceIDs, obj.value) > -1;
        })
      : [];

    const assignedTo =
      filterData && filterData.responderIDs
        ? filterDatalist.assignedTo.filter(obj => {
            return indexOf(filterData.responderIDs, obj.value) > -1;
          })
        : [];

    const services =
      filterData && filterData.serviceIDs
        ? filterDatalist.serviceList.filter(obj => {
            return indexOf(filterData.serviceIDs, obj.value) > -1;
          })
        : [];

    const priority: filterDropdownOptions[] =
      filterData && filterData.priority
        ? PRIORITY_OPTIONS.filter(obj => {
            return indexOf(filterData.priority, obj.value) > -1;
          })
        : [];

    const sloList =
      filterData && filterData.sloIDs
        ? filterDatalist.slolist.filter(obj => {
            return filterData.sloIDs?.includes(Number(obj.value));
          })
        : [];
    const retroStatus =
      filterData && filterData.retrospectiveStatus
        ? filterData.retrospectiveStatus.map(obj => {
            return {
              label: POSTMORTEM_STATUS_OPTIONS[obj as IPostmortemStatus].label,
              value: obj,
            };
          })
        : [];

    const tagsKeys = [] as { label: string; value: string; child: Array<string> }[];
    filter.filters && filter.filters.tags
      ? filter.filters.tags.forEach(({ key, value }) => {
          tagsKeys.push({ label: key, value: key, child: [value] });
        })
      : null;

    const tagsValues = [] as filterDropdownOptions[];
    filter.filters && filter.filters.tags
      ? filter.filters.tags.forEach(({ value, key }) => {
          tagsValues.push({ label: `${key}:${value}`, value });
        })
      : [];
    const keyValue = [] as filterDropdownOptions[];
    filter.filters && filter.filters.tags
      ? filter.filters.tags.forEach(({ value, key }) => {
          keyValue.push({ label: `${key}:${value}`, value });
        })
      : [];

    let dateFilterValue: string = filterData?.dateFilter ?? '';
    if (dateFilterValue == DateFilterChoice.Days) {
      dateFilterValue = `${filterData.lastNumberOfDays}`;
    }
    const created = CREATED_OPTIONS.find(opt => opt.value === dateFilterValue) ?? null;
    const repondersObject = getResponders(
      filterData?.responderIDs || [],
      filterDatalist.dropdownOptions,
    );
    return {
      name: filter.name,
      id: filter.incidentGroupID,
      isPrivate: filter.isPrivate,
      filters: {
        assignedTo: {
          meAndMySquads: Boolean(filterData?.assignedToUserIDsAndTheirSquads?.length),
          ...repondersObject,
          mySquads: filterData?.assignedToUserAndTheirSquads[0]?.squads
            ? filterData?.assignedToUserAndTheirSquads[0]?.squads?.map(
                (squad: { id: string; name: string }) => {
                  return {
                    label: squad.name,
                    value: squad.id,
                  };
                },
              )
            : [],
          myUsers: filterData?.assignedToUserAndTheirSquads[0]?.user
            ? [
                {
                  label: filterData?.assignedToUserAndTheirSquads[0]?.user?.name,
                  value: filterData?.assignedToUserAndTheirSquads[0]?.user?.id,
                },
              ]
            : [],
        },
        created,
        services,
        alert,
        priority,
        serviceOwner: {
          meAndMySquads: Boolean(
            filterData?.serviceOwnerDetails?.usersAndTheirSquads[0]?.user?.name ||
              filterData?.serviceOwnerDetails?.usersAndTheirSquads[0]?.squads?.length,
          ),
          users: getServiceOwnerFilter(
            {
              meAndMySquads: Boolean(
                filterData?.serviceOwnerDetails?.usersAndTheirSquads[0]?.user?.name ||
                  filterData?.serviceOwnerDetails?.usersAndTheirSquads[0]?.squads?.length,
              ),
              users: filterData?.serviceOwner?.userIDs ?? [],
              squads: [],
            },
            'user',
          ),
          squads: getServiceOwnerFilter(
            {
              meAndMySquads: Boolean(
                filterData?.serviceOwnerDetails?.usersAndTheirSquads[0]?.user?.name ||
                  filterData?.serviceOwnerDetails?.usersAndTheirSquads[0]?.squads?.length,
              ),
              squads: filterData?.serviceOwner?.squadIDs ?? [],
              users: [],
            },
            'squad',
          ),
          mySquads: filterData?.serviceOwnerDetails.usersAndTheirSquads.length
            ? filterData?.serviceOwnerDetails.usersAndTheirSquads[0]?.squads?.map(
                (squad: { id: string; name: string }) => {
                  return {
                    label: squad.name,
                    value: squad.id,
                  };
                },
              )
            : [],
          myUsers: filterData?.serviceOwnerDetails.usersAndTheirSquads[0]?.user
            ? [
                {
                  label: filterData?.serviceOwnerDetails.usersAndTheirSquads[0]?.user?.name,
                  value: filterData?.serviceOwnerDetails.usersAndTheirSquads[0]?.user?.id,
                },
              ]
            : [],
        },
        isSlo: filterData.isSLO as string,
        withRetro: filterData.hasRetrospectives as string,
        retroStatus: retroStatus,
        withNotes: filterData.hasNotes as string,
        isStarred: filterData.isStarred as string,
        sloList,
        startDate: moment(filterData.customDateFrom).toISOString(),
        endDate: moment(filterData.customDateTo).toISOString(),
        tagsKey: tagsKeys[0],
        tagsValue: tagsValues,
        keyValue,
        lastNumberOfDays: filterData.lastNumberOfDays,
        temprorayServices: [],
      },
    };
  });
};
