import { exception } from 'core';
import {
  T_WA_UP_SHG_GROUP_CREATED,
  T_WA_UP_SHG_GROUP_DELETED,
  T_WA_UP_SHG_GROUP_MODIEFIED,
} from 'core/const/tracker';
import StakeHolderService from 'core/services/services.stakeholder';
import { useState } from 'react';
import { AppTracker } from 'shared/analytics/tracker';
import { StakeHolderFormStateType } from '../types/stakeholder.types';

export function useStakeHolders() {
  const _stakeHolderService = new StakeHolderService();

  const [addingStakeHolders, addsetAddingStakeHoldersing] = useState<boolean>(false);
  const [deletingStakeHolders, setDeletingStakeHoldersing] = useState<boolean>(false);
  const [fetchingStakeHolders, setFetchingStakeHolders] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const fetchAllStakeHolderGroups = async (teamId: string) => {
    try {
      setFetchingStakeHolders(true);
      const res = await _stakeHolderService.getAllStakeHolderGroups(teamId);
      if (res?.data) {
        return res?.data?.data;
      }
    } catch (error) {
      exception.handle('E_FETCH_STAKEHOLDER_GROUP', error);
    } finally {
      setFetchingStakeHolders(false);
    }
  };

  const addStakeHolderGroup = async (
    groupName: string,
    stakeHolders: any[],
    teamId: string,
    cb: () => void,
  ) => {
    try {
      addsetAddingStakeHoldersing(true);
      const membersIds = stakeHolders.map(val => {
        return val.user_id;
      });
      const res = await _stakeHolderService.createStakeHolderGroup(
        {
          name: groupName,
          members: membersIds,
          owner_type: 'team',
        },
        teamId,
      );
      if (res?.data) {
        AppTracker.track(T_WA_UP_SHG_GROUP_CREATED, {
          'Stakeholder group ID': res?.data?.data?.id,
        });
        cb();
      }
    } catch (error: any) {
      setErrorMessage(error.response?.data?.meta?.error_message);
      exception.handle('E_CREATE_STAKEHOLDER_GROUP', error);
    } finally {
      addsetAddingStakeHoldersing(false);
    }
  };

  const deleteStakeHolderGroup = async (
    stakeholderGroupId: string,
    temamId: string,
    callback: () => void,
  ) => {
    try {
      setDeletingStakeHoldersing(true);
      const res = await _stakeHolderService.deleteStakeHolderGroup(stakeholderGroupId);
      if (res?.data) {
        callback();

        AppTracker.track(T_WA_UP_SHG_GROUP_DELETED, {
          'Stakeholder group ID': stakeholderGroupId,
        });
      }
    } catch (error) {
      exception.handle('E_DELETE_STAKEHOLDER_GROUP', error);
    } finally {
      setDeletingStakeHoldersing(false);
    }
  };

  const updateStakeHolderGroup = async (
    stakeholderGroupId: string,
    groupName: string,
    stakeHolders: any[],
    teamId: string,
    cb: () => void,
  ) => {
    try {
      addsetAddingStakeHoldersing(true);
      const membersIds = stakeHolders.map(val => {
        return val.user_id;
      });
      const res = await _stakeHolderService.updateStakeHolderGroup(
        {
          name: groupName,
          members: membersIds,
          owner_type: 'team',
        },
        teamId,
        stakeholderGroupId,
      );
      if (res?.data) {
        cb();
        AppTracker.track(T_WA_UP_SHG_GROUP_MODIEFIED, {
          'Stakeholder group ID': stakeholderGroupId,
        });
      }
    } catch (error: any) {
      setErrorMessage(error.response?.data?.meta?.error_message);
      exception.handle('E_CREATE_STAKEHOLDER_GROUP', error);
    } finally {
      addsetAddingStakeHoldersing(false);
    }
  };

  return {
    fetchAllStakeHolderGroups,
    fetchingStakeHolders,
    addStakeHolderGroup,
    addingStakeHolders,
    deleteStakeHolderGroup,
    deletingStakeHolders,
    updateStakeHolderGroup,
    errorMessage,
  };
}
