import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.st0{fill:none;stroke:#3670fe;stroke-linejoin:round;stroke-miterlimit:10}.st1{fill:#3670fe}'
      }
    </style>
    <path
      className="st0"
      d="M9.745 8.241V2.389c0-.237.203-.44.44-.44h6.562c.237 0 .44.203.44.44v7.103c0 .237-.203.44-.44.44h-4.161M14.025 13.889h3.162v3.162h-3.162zM2.814 2.95h3.162v3.162H2.814z"
    />
    <path
      className="st1"
      d="M6.676 9.587c-.296 0-.617.024-.963.08-1.437.229-2.396.94-3.227 2.229-.773 1.196-1.305 4.396.885 5.091.146.029.284.043.414.043 1.792 0 2.105-2.66 2.681-4.256l.043-.089c.425-.924 1.201-1.733 2.341-1.733.255 0 .528.04.82.129.094.021.169.031.227.031.591 0-.551-.993-1.672-1.304a5.903 5.903 0 0 0-.532-.116 4.753 4.753 0 0 0-1.017-.105z"
    />
    <path
      className="st1"
      d="M9.842 11.607c-1.792 0-2.105 2.66-2.681 4.256l-.043.089c-.425.924-1.201 1.733-2.341 1.733-.255 0-.528-.04-.82-.129a1.092 1.092 0 0 0-.228-.031c-.594 0 .548.993 1.669 1.304.19.05.369.089.532.116.297.063.636.103 1.018.103.296 0 .618-.024.966-.08 1.437-.229 2.396-.94 3.227-2.229.773-1.196 1.305-4.396-.885-5.091a2.283 2.283 0 0 0-.414-.041z"
    />
  </svg>
);

export default SvgComponent;
