import { ChevronDownIcon } from '@chakra-ui/icons';
import { Menu, MenuButton, MenuItem, MenuList, Text, VStack } from '@chakra-ui/react';
import {
  DialogModalFrame,
  FocusBlock,
  Grid,
  Para,
  SelectBox,
  TextButton,
  Theme,
  Tooltip,
} from 'uie/components';
import Snooze from 'components/Snooze';
import { FileUploadService } from 'core';
import { truncate } from 'core/helpers/stringUtils';
import { IService } from 'core/interfaces/IService';
import { ActionsIcon, AddRespondersBlackIcon, KebabIcon } from 'library/icons';
import { Modal } from 'library/molecules';
import { THEME_COLORS } from 'library/theme/colors';
import { ReactNode, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IIAssigne, IIncident } from '../../../../../core/interfaces/IIncidents';
import {
  AckIcon,
  BulEye,
  CrownSmallIcon,
  EscalationIcon,
  ReassignIcon,
  SnoozeIconBlue,
  SquadIcon,
  TransientAlertCrossedBlueIcon,
  TransientAlertCrossedIcon,
  TransientAlertIcon,
  UnSnoozeIconBlue,
  UserIcon,
} from '../../../../../icons';
import { getAssignableTeamMembers } from '../../../../../shared/reducers';
import UpdateStatusPage from '../renders/updateStatusPage';
import IncidentDetailsActions from './actions';
import { MenuItemWithUpgradeFlow } from './menuItemWithUpgradeFlow';
import Postmortem from './postmortem';
import { MultipleResponders } from './responders';

const { theme } = Theme;

const iconMap: {
  [key in IIAssigne]: ReactNode;
} = {
  user: <UserIcon height={16} width={16} />,
  squad: <SquadIcon height={16} width={16} />,
  escalationpolicy: <EscalationIcon height={16} width={16} />,
};

interface IProps extends Pick<IAppState, 'organization'> {
  incident: IIncident;
  acknowledgeIncident: () => void;
  resolveIncident: () => void;
  markAsTransient: () => void;
  markAsNotTransient: () => void;
  reassignIncident: (id: string, type: IIAssigne) => void;
  getIncidentDetails: any;
  getIncidentMapping: () => void;
  onClickUnmerge: () => void;
  disableUnmerge: boolean;
  isUnmergeIncidentsUnavailable: boolean;
  parentIncidentStatus: string;
  incidentService: IService;
  canUpdateStatusPage: boolean;
  onSLOAffect: () => void;
  showAffectsSLO: boolean;
  isRunbooksDisabled: boolean;
}

const IncidentDetailsResponseBox = ({ incident, ...props }: IProps) => {
  const fileUploadService = new FileUploadService();

  const [openModal, setOpenModal] = useState<
    'reassign' | 'resolve' | 'not-transient' | 'mark-transient' | ''
  >('');
  const [reassign, setReassign] = useState<{
    id: string;
    type: IIAssigne;
    name: string;
    username?: string;
  }>({
    id: '',
    type: 'user',
    name: '',
    username: '',
  });
  const [reassignSearch, setReassignSearch] = useState('');
  const _oUser: any = getAssignableTeamMembers(
    props.organization.selectedTeam.team!,
    props.organization.users.u,
  ).reduce((c: any, n) => {
    c[n.id] = {
      _id: n.id,
      name: `${n.first_name} ${n.last_name}`,
      type: 'user',
      username: n.username_for_display,
    };
    return c;
  }, {});

  const _oSquad: any = props.organization.squads.s.reduce((c: any, n) => {
    c[n.id] = {
      _id: n.id,
      name: n.name,
      type: 'squad',
    };
    return c;
  }, {});

  const _oEscalation: any = props.organization.escalations.e.reduce((c: any, n) => {
    c[n.id] = {
      _id: n.id,
      name: n.name,
      type: 'escalationpolicy',
    };
    return c;
  }, {});

  const assigne: {
    id: string;
    name: string;
    type: IIAssigne;
    username: string;
  }[] = [
    ...(Object.values(_oUser) as any),
    ...(Object.values(_oSquad) as any),
    ...(Object.values(_oEscalation) as any),
  ];

  const lastAssigneeId = incident.assignedTo[incident.assignedTo.length - 1].id;

  const toggleOpenModal = (m: 'reassign' | 'resolve' | 'not-transient' | 'mark-transient') => () =>
    setOpenModal(m);
  const closeOpenModal = () => setOpenModal('');

  const resolveIncident = () => {
    closeOpenModal();
    props.resolveIncident();
  };

  const onSetReassign = (
    _: any,
    {
      _id: id,
      type,
      name,
      username,
    }: { _id: string; type: IIAssigne; name: string; username?: string },
  ) => {
    setReassign({ id, type, name, username });
  };

  const onReassignIncident = () => {
    const { id, type } = { ...reassign };
    if (id === '') return;

    closeOpenModal();
    props.reassignIncident(id, type);
    setReassign({ id: '', type: 'user', name: '', username: '' });
  };

  const isMergedIncident = incident.is_child;
  const containsChildren = incident.children.length > 0;
  const isTransientAlert = incident.status === 'suppressed' && incident.did_auto_pause;

  const markIncidentAsTransient = () => {
    props.markAsTransient();
    closeOpenModal();
  };

  const markIncidentAsNotTransient = () => {
    props.markAsNotTransient();
    closeOpenModal();
  };

  const unMergeTooltipProps = props.disableUnmerge
    ? {
        padding: '8px',
        offset: { top: '-50px', left: '-150px' },
        width: '150px',
        label: `Cannot unmerge as parent incident is ${props.parentIncidentStatus}`,
      }
    : { label: '', padding: '0px' };

  const reAssignSelectText = reassign.name + (reassign.username ? ` (${reassign.username})` : '');
  const reAssignSelectMaxLength = 40;

  const reassignTooltipProps =
    reassign.username && reAssignSelectText.length > reAssignSelectMaxLength
      ? {
          padding: '8px',
          offset: { top: '-50px', left: '-150px' },
          width: '150px',
          label: reAssignSelectText,
        }
      : { label: '', padding: '0px' };
  const aptaRules = useSelector(
    (state: IAppState) => state.organization.plan.p?.rules['auto-pause-transient-alerts'],
  );

  const organization = props.organization as unknown as Pick<IAppState, 'organization'>;

  const { isAptaEnabled, aptaPlanLimit, isAptaPlanRenewable, isServiceAptaEnabled } =
    useMemo(() => {
      if (!aptaRules) {
        return {
          isAptaEnabled: false,
          aptaPlanLimit: undefined,
          isAptaPlanRenewable: false,
          isServiceAptaEnabled: false,
        };
      }

      const limit: number | undefined | 'unlimited' = aptaRules.unlimited
        ? 'unlimited'
        : aptaRules.quantity || undefined;

      return {
        isAptaEnabled: true,
        aptaPlanLimit: limit,
        isAptaPlanRenewable: aptaRules.renewable,
        isServiceAptaEnabled: props.incidentService?.auto_pause_transient_alerts_config?.is_enabled,
      };
    }, [aptaRules, props.incidentService?.auto_pause_transient_alerts_config?.is_enabled]);

  return (
    <>
      <Grid flexWidth={12} alignItems="flex-start">
        {incident.status !== 'suppressed' && !isMergedIncident && (
          <>
            <Grid alignItems="center">
              {incident.status === 'triggered' && (
                <TextButton
                  color={theme.primary.default}
                  className="mr-20"
                  style={{ padding: '8px 16px' }}
                  onClick={props.acknowledgeIncident}
                >
                  <AckIcon
                    height={14}
                    width={14}
                    stroke={theme.shades.white}
                    fill="transparent"
                    className="mr-5 incident_details__ackicon"
                  />
                  <Para color={theme.shades.white} fontSize={14} fontWeight={500}>
                    Acknowledge
                  </Para>
                </TextButton>
              )}

              {incident.status !== 'resolved' && (
                <TextButton
                  color={theme.primary.default}
                  buttonType="inverted"
                  className="mr-20"
                  style={{ padding: '8px 16px' }}
                  onClick={toggleOpenModal('reassign')}
                >
                  <Grid alignItems="center" justifyContent="center">
                    <ReassignIcon height={16} width={16} className="mr-5" />
                    <Para color={theme.primary.default} fontSize={14} fontWeight={400}>
                      Reassign
                    </Para>
                  </Grid>
                </TextButton>
              )}
            </Grid>

            {incident.status === 'triggered' && (
              <TextButton
                buttonType="inverted"
                color={theme.primary.default}
                style={{ padding: '8px 16px' }}
                onClick={toggleOpenModal('resolve')}
              >
                <Grid alignItems="center" justifyContent="center">
                  <Para color={theme.primary.default} fontSize={14} fontWeight={400}>
                    Resolve
                  </Para>
                </Grid>
              </TextButton>
            )}
            {incident.status === 'acknowledged' && (
              <TextButton
                color={theme.primary.default}
                style={{ padding: '8px 16px' }}
                onClick={toggleOpenModal('resolve')}
              >
                <Para color={theme.shades.white} fontSize={16} fontWeight={500}>
                  Resolve
                </Para>
              </TextButton>
            )}
            {incident.status === 'resolved' && <Postmortem incident={incident} />}
            {(incident.status === 'acknowledged' || incident.status === 'triggered') && (
              <Grid className="ml-20">
                <Snooze
                  isSnoozed={incident.snooze_details?.is_snoozed}
                  disabled={false}
                  incidentId={incident.id}
                  incidentName={incident.message}
                  screenName={'Incident Details'}
                  organization={organization}
                >
                  <TextButton
                    color={theme.primary.default}
                    buttonType="inverted"
                    style={{ padding: '8px 16px', height: 37, width: 112 }}
                  >
                    <Grid alignItems="center" justifyContent="center">
                      {incident.snooze_details?.is_snoozed ? (
                        <UnSnoozeIconBlue height={16} width={16} className="mr-5" />
                      ) : (
                        <SnoozeIconBlue height={16} width={16} className="mr-5" />
                      )}
                      <Para color={theme.primary.default} fontSize={14} fontWeight={400}>
                        {incident.snooze_details?.is_snoozed ? 'Unsnooze' : 'Snooze'}
                      </Para>
                      {!incident.snooze_details?.is_snoozed && (
                        <ChevronDownIcon stroke={theme.primary.default} />
                      )}
                    </Grid>
                  </TextButton>
                </Snooze>
              </Grid>
            )}
          </>
        )}
        {isMergedIncident && (
          <Tooltip {...unMergeTooltipProps}>
            <Grid style={{ position: 'relative' }}>
              <TextButton
                color={theme.primary.default}
                style={{ padding: '8px 16px' }}
                onClick={props.onClickUnmerge}
                disabled={props.disableUnmerge}
              >
                <Para color={theme.shades.white} fontSize={14} fontWeight={500}>
                  Unmerge
                </Para>
              </TextButton>
              {props.isUnmergeIncidentsUnavailable && (
                <CrownSmallIcon
                  className="pro"
                  style={{
                    position: 'absolute',
                    left: '12px',
                    top: '-8px',
                    height: 16,
                    width: 16,
                  }}
                />
              )}
            </Grid>
          </Tooltip>
        )}
        <Menu isLazy lazyBehavior="keepMounted">
          <MenuButton className="ml-20">
            <TextButton
              buttonType="ghost"
              color={theme.primary.default}
              style={{
                padding: '4px 12px',
                minHeight: '40px',
                border: `1px solid ${THEME_COLORS['settings'].pageBackgroundColor}`,
              }}
            >
              <KebabIcon />
            </TextButton>
          </MenuButton>
          <MenuList>
            {incident.status !== 'suppressed' &&
              !isMergedIncident &&
              isServiceAptaEnabled &&
              incident.manually_marked_transient_alert_feedback_type !== 'positive' && (
                <MenuItemWithUpgradeFlow
                  organization={organization}
                  isDisabled={!isAptaEnabled}
                  planName="auto-pause-transient-alerts"
                  onClick={() => setOpenModal('mark-transient')}
                  limit={aptaPlanLimit}
                  renewable={isAptaPlanRenewable}
                >
                  <TransientAlertIcon height={16} width={16} className="mr-5" />
                  Mark as Transient
                </MenuItemWithUpgradeFlow>
              )}
            {isServiceAptaEnabled &&
              isTransientAlert &&
              incident.manually_marked_transient_alert_feedback_type !== 'negative' && (
                <MenuItemWithUpgradeFlow
                  organization={organization}
                  isDisabled={!isAptaEnabled}
                  planName="auto-pause-transient-alerts"
                  onClick={() => setOpenModal('not-transient')}
                  limit={aptaPlanLimit}
                  renewable={isAptaPlanRenewable}
                >
                  <TransientAlertCrossedIcon height={16} width={16} className="mr-5" />
                  Not Transient
                </MenuItemWithUpgradeFlow>
              )}
            {incident.status !== 'resolved' && (
              <MenuItem>
                <MultipleResponders incident={incident} organization={organization}>
                  <Grid alignItems="center" justifyContent="center">
                    <AddRespondersBlackIcon height={16} width={16} className="mr-5" />
                    Add Responders
                  </Grid>
                </MultipleResponders>
              </MenuItem>
            )}
            {props.showAffectsSLO && (
              <MenuItemWithUpgradeFlow
                organization={organization}
                isDisabled={props.isRunbooksDisabled}
                planName="slo"
                onClick={props.onSLOAffect}
              >
                <BulEye height={16} width={16} className="mr-5" />
                Affects SLO
              </MenuItemWithUpgradeFlow>
            )}
            {incident.status !== 'resolved' && (
              <IncidentDetailsActions
                incident={incident}
                getIncidentDetails={props.getIncidentDetails}
                getIncidentMapping={props.getIncidentMapping}
              >
                <MenuItem>
                  <Grid alignItems="center" justifyContent="center">
                    <ActionsIcon height={16} width={16} className="mr-5" />
                    Actions
                  </Grid>
                </MenuItem>
              </IncidentDetailsActions>
            )}
            {props.canUpdateStatusPage && (
              <MenuItem>
                <UpdateStatusPage
                  incidentId={incident?.id ?? ''}
                  fileUploadService={fileUploadService}
                />
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Grid>

      <DialogModalFrame
        id="incident_details__description_confirm_resolve_reassign_modal"
        onClose={closeOpenModal}
        padding="16px"
        width="480px"
      >
        {openModal === 'resolve' && (
          <Grid type="column">
            <Para fontWeight={500} color={theme.shades.cement} fontSize={20} className="pr-20">
              Are you sure want to resolve this incident?
            </Para>
            <Para
              fontWeight={500}
              color={theme.shades.cement}
              fontSize={16}
              className="pt-20 pr-20"
            >
              {containsChildren ? 'This incident contains 1 or more child incidents. ' : ''}This
              action cannot be reversed.
            </Para>

            <Grid className="mt-20" type="row-reverse">
              <TextButton
                color={theme.primary.default}
                style={{ padding: '8px 16px' }}
                onClick={resolveIncident}
              >
                <Para color={theme.shades.white} fontSize={16} fontWeight={500}>
                  Yes, Resolve
                </Para>
              </TextButton>
              <TextButton
                buttonType="ghost"
                color={theme.shades.cement}
                style={{ padding: '8px 16px' }}
                onClick={closeOpenModal}
                className="mr-20"
              >
                <Para color={theme.shades.cement} fontSize={16} fontWeight={500}>
                  No
                </Para>
              </TextButton>
            </Grid>
          </Grid>
        )}
        {openModal === 'reassign' && (
          <Grid type="column">
            <Para fontWeight={500} color={theme.shades.cement} fontSize={20} className="pr-20">
              Reassign this incident to
            </Para>

            <Tooltip {...reassignTooltipProps}>
              <Grid className="mt-20">
                <SelectBox
                  hook={
                    reassign.id !== '' ? (
                      <Grid alignItems="center">
                        {(iconMap as any)[reassign.type]}
                        <Para fontSize={16} className="ml-10">
                          {truncate(reAssignSelectText, reAssignSelectMaxLength)}
                        </Para>
                      </Grid>
                    ) : (
                      <Para fontSize={16} className="ml-10">
                        Select a User, Squad or Escalation Policy
                      </Para>
                    )
                  }
                  searchHookProps={{
                    value: reassignSearch,
                    height: '24px',
                    fontSize: '16px',
                    onChange: e => setReassignSearch(e.target.value),
                  }}
                  onValueChange={onSetReassign}
                  height="auto"
                  maxHeight="200px"
                  width="100%"
                  maxWidth="100%"
                >
                  {assigne
                    .filter((s: any) => {
                      return (
                        s._id !== lastAssigneeId &&
                        (reassignSearch
                          ? s.name.toLowerCase().includes(reassignSearch.toLocaleLowerCase())
                          : true)
                      );
                    })
                    .map((s: any, i: number) => (
                      <FocusBlock key={i} value={s} isSelected={s._id === reassign.id}>
                        <VStack alignItems="flex-start">
                          <Grid alignItems="center">
                            {(iconMap as any)[s.type]}
                            <Para fontSize={16} className="ml-10">
                              {s.name}
                            </Para>
                          </Grid>
                          {s.username && (
                            <Para
                              fontSize={16}
                              color={THEME_COLORS.secondary[1200]}
                              style={{ marginLeft: 25, maxWidth: '35ch' }}
                            >
                              {s.username}
                            </Para>
                          )}
                        </VStack>
                      </FocusBlock>
                    ))}
                </SelectBox>
              </Grid>
            </Tooltip>

            <Grid className="mt-20" type="row-reverse">
              {reassign.id !== '' && (
                <TextButton
                  color={theme.primary.default}
                  style={{ padding: '8px 16px' }}
                  onClick={onReassignIncident}
                >
                  <Para color={theme.shades.white} fontSize={16} fontWeight={500}>
                    Reassign
                  </Para>
                </TextButton>
              )}
            </Grid>
          </Grid>
        )}
      </DialogModalFrame>

      {/* Apta Modals */}

      <Modal
        title="Consider similar incidents as not Transient"
        isOpen={openModal === 'not-transient'}
        onClose={closeOpenModal}
        primaryButton={
          <TextButton
            color={theme.primary.default}
            style={{ padding: '8px 16px' }}
            onClick={markIncidentAsNotTransient}
          >
            <Para color={theme.shades.white} fontSize={16} fontWeight={600}>
              Confirm
            </Para>
          </TextButton>
        }
        secondaryButton={
          <TextButton
            buttonType="inverted"
            color={theme.primary.default}
            style={{ padding: '8px 16px' }}
            onClick={closeOpenModal}
            className="mr-20"
          >
            <Para color={theme.primary.default} fontSize={16} fontWeight={600}>
              Cancel
            </Para>
          </TextButton>
        }
        body={
          <Text color="gray.500">
            Future incidents similar to this, will not be considered as transient.
          </Text>
        }
      />

      <Modal
        title="Consider similar incidents as Transient"
        isOpen={openModal === 'mark-transient'}
        onClose={closeOpenModal}
        primaryButton={
          <TextButton
            color={theme.primary.default}
            style={{ padding: '8px 16px' }}
            onClick={markIncidentAsTransient}
          >
            <Para color={theme.shades.white} fontSize={16} fontWeight={600}>
              Confirm
            </Para>
          </TextButton>
        }
        secondaryButton={
          <TextButton
            buttonType="inverted"
            color={theme.primary.default}
            style={{ padding: '8px 16px' }}
            onClick={closeOpenModal}
            className="mr-20"
          >
            <Para color={theme.primary.default} fontSize={16} fontWeight={600}>
              Cancel
            </Para>
          </TextButton>
        }
        body={
          <Text color="gray.500">
            Future incidents similar to this, will be considered as transient and their
            notifications will be auto paused.
          </Text>
        }
      />
    </>
  );
};

export default connect(({ organization }: IAppState) => ({
  organization,
}))(IncidentDetailsResponseBox);
