import { Grid, Para } from 'uie/components';

import { useIncidentSummaryContext } from '../../context/hook';
import { IncidentSummaryDrawerState } from '../../types';
import IncidentSummaryDrawerFeedbackFooter from './feedback/Footer';
import IncidentSummaryDrawerSuccessFooter from './success/Footer';
import { HStack } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import { GenAiIcon } from 'icons';

const IncidentSummaryDrawerFooter: React.FC = () => {
  const { drawerState } = useIncidentSummaryContext();

  return (
    <Grid height="32px" alignItems="center">
      <HStack spacing={3}>
        {drawerState === IncidentSummaryDrawerState.SUCCESS && (
          <IncidentSummaryDrawerSuccessFooter />
        )}
        {drawerState === IncidentSummaryDrawerState.FEEDBACK && (
          <IncidentSummaryDrawerFeedbackFooter />
        )}
        <Para>
          <span style={{ color: THEME_COLORS.secondary[700] }}>Feature uses</span>{' '}
          <a
            target="_blank"
            href="https://www.squadcast.com/security/generative-ai-guidelines"
            rel="noreferrer"
            style={{ color: THEME_COLORS.brand.blue }}
          >
            Generative AI
          </a>
        </Para>
      </HStack>
    </Grid>
  );
};

export default IncidentSummaryDrawerFooter;
