import React, { FC, useEffect, useState, useRef } from 'react';
import { CustomDrawerComponent } from 'views/shared/components';
import { useHistory, useLocation } from 'react-router-dom';
import { encodeFilterParam } from '../owner-filters/helpers';
import { DropdownSection, FilterObject, Option } from '../owner-filters/hooks/useEntityOwnerFilter';
import { SelectedFilterTags } from 'views/main/organization/owner-filters/SelectedFilterTags';
import { components } from 'react-select';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  HStack,
  AccordionPanel,
  Flex,
  Box,
  VStack,
  FormControl,
  FormLabel,
  Button,
  Text,
  ChakraProvider,
} from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import useQueryParams from 'core/hooks/useQueryParams';
import { SelectOwnerDropdown } from '../owner-filters/Dropdown';
import { ActiveFilterTag } from '../owner-filters/ActiveFilterTags';
import { OwnerFilter } from './getOwnerFilters';
import LibraryTheme from 'library/theme';
import { ListingButton } from 'library/atoms';

interface FilterProps {
  isFilterOpen: boolean;
  onClose: () => void;
  ownerFilter: OwnerFilter;
  applyChakraStyle: boolean;
}

const Group = (props: any) => {
  const {
    children,
    label,
    selectProps: { inputValue },
  } = props;

  return (
    <Accordion
      allowToggle={true}
      defaultIndex={label === DropdownSection.YOU_AND_YOUR_SQUADS ? 0 : -1}
      {...(inputValue ? { index: 0 } : {})}
    >
      <AccordionItem>
        {({ isExpanded = false }) => (
          <>
            <AccordionButton
              _expanded={{ background: 'white !important' }}
              padding="8px 12px"
              sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}
            >
              <HStack p={0}>
                <Text color="secondary.900" fontWeight={800}>
                  {label}
                </Text>
                {isExpanded ? (
                  <ChevronDownIcon width={6} height={6} color="secondary.900" />
                ) : (
                  <ChevronRightIcon width={6} height={6} color="secondary.900" />
                )}
              </HStack>
            </AccordionButton>
            <AccordionPanel p={0}>{children}</AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

const Menu = (props: any) => {
  const { children } = props;
  return (
    <components.Menu {...props}>
      <Flex
        sx={{
          ...{
            boxShadow: '0px 4px 10px 0px #0000000D',
          },
          ...{
            boxShadow: '2px 0px 10px 0px #0000000D',
          },
          width: '100%',
        }}
      >
        {children}
      </Flex>
    </components.Menu>
  );
};

export const Filters: FC<FilterProps> = props => {
  const history = useHistory();
  const query = useQueryParams();

  const applyEntityOwnerFilters = (filterObject: FilterObject) => {
    const filterUrlParams = encodeFilterParam(filterObject);
    query.delete('entity_owner');
    filterUrlParams && query.append('entity_owner', filterUrlParams);
    history.push(`/webforms?${query}`);
    props.onClose();
  };

  useEffect(() => {
    applyEntityOwnerFilters(props.ownerFilter.activeFilterObject);
  }, [props.ownerFilter.activeFilterObject]);

  useEffect(() => {
    if (
      props.isFilterOpen &&
      !props.ownerFilter.ownerFilterSelected &&
      props.ownerFilter.ownerFilterApplied
    ) {
      /** For scenario when we clear all selected tags from drawer and a filter is applied.
      Without this useEffect, we will not be able to clear out the filters */
      onClearFilters();
    }
  }, [
    props.isFilterOpen,
    props.ownerFilter.ownerFilterSelected,
    props.ownerFilter.ownerFilterApplied,
  ]);

  const onClickApplyFilters = () => {
    applyEntityOwnerFilters(props.ownerFilter.selectedFilterObject);
  };

  const onClearFilters = () => {
    props.ownerFilter.resetOwnerFilters();
    query.delete('entity_owner');
    history.push(`/webforms?${query}`);
    props.onClose();
  };

  const onCancel = () => {
    props.onClose();
  };

  return (
    <ChakraProvider theme={LibraryTheme} resetCSS={props.applyChakraStyle}>
      <CustomDrawerComponent
        onClose={props.onClose}
        isOpen={props.isFilterOpen}
        title={'Filter'}
        disableBodyPadding={true}
        {...{ size: 'sm' }}
      >
        <Box height="100%" pos="relative">
          <VStack spacing={4} align="flex-start" py={4} px={6}>
            <FormControl width="100%">
              <FormLabel>Webform Owner</FormLabel>
              <SelectOwnerDropdown
                variant="avatar"
                teamOptionLabelStyles={{
                  color: 'secondary.900',
                  fontSize: 'md',
                  fontWeight: 800,
                }}
                options={props.ownerFilter.dropdownOptions}
                value={[]}
                placeholder="Search for users or squads"
                components={{ Group, Menu }}
                onChange={value => props.ownerFilter.onChangeHandler(value as Option)}
                applyChakraStyle={false}
              />
            </FormControl>
            <SelectedFilterTags
              tags={props.ownerFilter.selectedFilterTags}
              visibleTagsCount={4}
              applyChakraStyle={false}
              onClose={props.ownerFilter.handleSelectedFilterTagClick}
            />
          </VStack>
          <HStack
            width="100%"
            paddingY={4}
            paddingX={6}
            gap={3}
            sx={{ borderTop: '1px solid #CBD5E0', position: 'absolute', bottom: 0 }}
          >
            <ListingButton
              title="Apply"
              variant="primary"
              onClick={onClickApplyFilters}
              isDisabled={!props.ownerFilter.ownerFilterSelected}
            >
              Apply
            </ListingButton>
            <ListingButton title="Cancel" variant="secondary" onClick={onCancel}>
              Cancel
            </ListingButton>
            <ListingButton
              title="Clear all filters"
              variant="ghost"
              onClick={onClearFilters}
              color={THEME_COLORS.brand.blue}
              _hover={{ textDecoration: 'none' }}
              isDisabled={!props.ownerFilter.ownerFilterSelected}
            >
              Clear all filters
            </ListingButton>
          </HStack>
        </Box>
      </CustomDrawerComponent>
    </ChakraProvider>
  );
};
