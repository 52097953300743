import { Box } from '@chakra-ui/layout';
import { Tooltip } from 'library/atoms';
import { ReactNode, useEffect, useRef, useState } from 'react';

type OverflownTextProps = {
  children: ReactNode;
};

export function OverflownText({ children, ...props }: OverflownTextProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);
  useEffect(() => {
    if (!ref.current) return;
    const element = ref.current!;
    setIsOverflown(element.scrollWidth > element.clientWidth);
  }, []);

  return (
    <Tooltip label={children} placement="bottom" isDisabled={!isOverflown}>
      <Box position="relative" isTruncated ref={ref} {...props}>
        {children}
      </Box>
    </Tooltip>
  );
}
