import { ComponentStyleConfig } from '@chakra-ui/theme';

export const ButtonTheme: ComponentStyleConfig = {
  sizes: {
    md: {
      height: '2.5rem',
      px: '0.75rem',
    },
    sm: {
      height: '2rem',
      px: '0.75rem',
    },
    xs: {
      px: '0.5rem',
      height: '1.5rem',
    },
  },
  variants: {
    primary: {
      color: 'brand.white',
      bg: 'brand.blue',
      border: '1.5px solid',
      borderColor: 'brand.blue',
      height: '2em',

      _hover: {
        bg: 'brand.lightBlue',
        borderColor: 'primary.400',
      },

      _disabled: {
        bg: 'brand.blueInactive',
        borderColor: 'brand.blueInactive',
        pointerEvents: 'none',
      },

      _pressed: {
        bg: 'button.press',
        borderColor: 'primary.400',
      },
    },
    secondary: {
      color: 'brand.blue',
      bg: 'brand.white',
      border: '0.75px solid',
      borderColor: 'primary.500',

      _hover: {
        bg: 'primary.100',
      },

      _disabled: {
        borderColor: 'brand.blueInactive',
        p: {
          color: 'brand.blueInactive',
        },
        pointerEvents: 'none',
      },

      _pressed: {
        bg: 'primary.200',
      },
    },
    ghost: {
      bg: 'brand.white',
      color: 'brand.blue',

      _hover: {
        bg: 'primary.100',
      },

      _disabled: {
        bg: 'brand.white',
        p: {
          color: 'brand.blueInactive',
        },
        pointerEvents: 'none',
      },

      _pressed: {
        bg: 'primary.200',
      },
    },
    danger: {
      bg: 'brand.red',
      color: 'brand.white',

      _hover: {
        bg: 'brand.red',
      },

      _disabled: {
        opacity: 0.5,
        pointerEvents: 'none',
      },

      _pressed: {
        bg: 'brand.red',
      },
    },
    'ghost-danger': {
      bg: 'brand.white',
      color: 'brand.red',

      _hover: {
        bg: 'brand.white',
      },

      _pressed: {
        bg: 'brand.white',
      },
    },
    'outline-danger': {
      bg: 'brand.white',
      color: 'brand.red',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'brand.red',
      height: '2em',
      '& p': {
        color: 'brand.red',
      },

      _hover: {
        bg: 'brand.white',
        boxShadow: '0 0 1.5px 1.5px #fad7c3',
        WebkitBoxShadow: ' 0 0 1.5px 1.5px #fad7c3',
      },

      _pressed: {
        bg: 'brand.white',
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'primary',
  },
};
