import { IAppExceptions } from '../interfaces/IAppExceptions';
import { API } from '../api';
import { limits } from '../Limits';

/**
 * Exceptions handler for .catch() or catch(err)
 * logs to sentry with email and exceptionTag
 * tags are defined in IAppExceptions
 * use `verbose=true` to debug errors
 */
class Exception {
  private _userLog = {
    organizationId: '',
    userId: '',
    userEmail: '',
    appVersion: '',
  };

  private _verbose = false;

  private _captureMessage = (message: string, o: any) => {
    return;
  };

  set capture(captureMessage: (message: string, o: any) => void) {
    this._captureMessage = captureMessage;
  }

  set userLog(v: { organizationId: string; userId: string; userEmail: string }) {
    const { appVersion } = API;
    this._userLog = {
      ...v,
      appVersion,
    };
  }

  /**
   * logs errors to console
   */
  verbose() {
    this._verbose = true;
  }

  /**
   *
   * @param message IAppExceptions
   * @param error any
   * @returns {any | string} error_message | Network error, please try again | ''
   */
  handle(message: IAppExceptions, error: any): any {
    if (this._verbose) {
      console.error(message, error);
    }

    const responseStatus = error?.response?.status;
    if (responseStatus === 400 || responseStatus === 401 || responseStatus === 403) {
      return error?.response?.data?.meta?.error_message ?? '';
    }

    const payload = {
      error,
      ...this._userLog,
    };

    limits.distill(error);
    this._captureMessage(message, {
      extra: payload,
      user: {
        email: this._userLog.userEmail,
        id: this._userLog.userId,
      },
    });
    return error?.response?.data?.meta?.error_message || 'Network error, please try again';
  }

  public getErrorMsg(error: any): string {
    return error?.response?.data?.meta?.error_message ?? 'Network error, please try again';
  }
}

const exception = new Exception();

export { exception };
