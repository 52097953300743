import { IAppState } from 'core/interfaces/IAppState';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { addMembers, deleteMember, getAllMember, updateRole } from '../api';
import { useToast } from 'library/atoms';
import { AddMemberToTeamPayload, MemberRowProps, PatchMemberRolePayload } from '../types';
import { AxiosResponse } from 'axios';
import { requestOrganizationTeams, TeamsService } from 'core';
import { ITeamMemberDependencies } from 'core/interfaces/ITeams';

export const useGetAllMembers = (teamId: string) => {
  const users = useSelector((state: IAppState) => state.organization.users.u);

  return useQuery({
    queryKey: ['getAllMembers', teamId],
    queryFn: () => getAllMember(teamId),
    select: data =>
      data.data.data.map(member => {
        const user = users.find(u => u.id === member.user_id);
        return {
          ...member,
          name: `${user?.first_name} ${user?.last_name}`,
          email: user?.email,
          email_verified: user?.email_verified,
          username_for_display: user?.username_for_display,
        } as MemberRowProps;
      }),
  });
};

type MutateCB<T> = {
  onSuccess?: (data: T) => void;
  onError?: (error: any) => void;
};

export const useAddMembers = (cb: MutateCB<AxiosResponse<any>>) => {
  const toast = useToast();

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (payload: AddMemberToTeamPayload) => addMembers(payload.team_id, payload.members),
    onSettled: () => {
      queryClient.invalidateQueries('getAllMembers');
      dispatch(requestOrganizationTeams());
    },

    onError: (error: any) => {
      toast({
        status: 'error',
        text: error?.response?.data?.meta?.error_message ?? 'Something went wrong',
      });
      cb.onError?.(error);
    },

    onSuccess: response => {
      toast({
        status: 'success',
        text: 'Members added successfully',
      });
      cb.onSuccess?.(response);
    },
  });
};

export const useUpdateRole = (cb: MutateCB<AxiosResponse<any>>) => {
  const toast = useToast();

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (payload: PatchMemberRolePayload) => {
      return updateRole(payload.team_id, payload.user_id, payload.role, payload.replace_with);
    },
    onSettled: () => {
      queryClient.invalidateQueries('getAllMembers');
      dispatch(requestOrganizationTeams());
    },

    onError: (error: any) => {
      toast({
        status: 'error',
        text: error?.response?.data?.meta?.error_message ?? 'Something went wrong',
      });
      cb.onError?.(error);
    },

    onSuccess: response => {
      toast({
        status: 'success',
        text: 'Members role updated successfully',
      });
      cb.onSuccess?.(response);
    },
  });
};

export const useDeleteMember = (cb: MutateCB<AxiosResponse<any>>) => {
  const toast = useToast();

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (payload: { teamId: string; memberId: string }) =>
      deleteMember(payload.teamId, payload.memberId),
    onSettled: () => {
      queryClient.invalidateQueries('getAllMembers');
      dispatch(requestOrganizationTeams());
    },

    onError: (error: any) => {
      toast({
        status: 'error',
        text: error?.response?.data?.meta?.error_message ?? 'Something went wrong',
      });
      cb.onError?.(error);
    },

    onSuccess: response => {
      toast({
        status: 'success',
        text: 'Members deleted successfully',
      });
      cb.onSuccess?.(response);
    },
  });
};

export const useGetDependencies = (
  teamId: string,
  memberId: string,
  cb: MutateCB<
    AxiosResponse<{
      data: ITeamMemberDependencies;
    }>
  >,
) => {
  const toast = useToast();

  return useQuery({
    queryKey: ['getDependencies', teamId, memberId],
    queryFn: () => new TeamsService().getDependencies(teamId, memberId),
    enabled: false,

    onSuccess: response => {
      cb.onSuccess?.(response);
    },

    onError: (error: any) => {
      cb.onError?.(error);
      toast({
        status: 'error',
        text: error?.response?.data?.meta?.error_message ?? 'Something went wrong',
      });
    },
  });
};

export const useRefreshMembers = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return () => {
    queryClient.invalidateQueries('getAllMembers');
    dispatch(requestOrganizationTeams());
  };
};
