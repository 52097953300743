import React, { useRef, useState } from 'react';

import ReactMarkdown from 'react-markdown';
import { MdBlock, SwitchBlock, CopyIconButton } from 'uie/components';

import FileUploadService, {
  FileUploadFeature,
  OperationType,
} from 'core/services/service.fileUpload';

import { markupType, addStepPlaceholder } from '../../../runbooks/runbooks.constant';

import { fileUploadViewConfig } from 'views/config/fileUploadConfig';

const { imageTexts, ...fileUploadConfig } = fileUploadViewConfig;
import gfm from 'remark-gfm';
import breaks from 'remark-breaks';

interface IProps {
  fileUploadService: FileUploadService;
  contentUpdate: any;
  content: string | undefined;
  isUpdate: boolean;
}

const renderMdLink = (props: any) => (
  <a href={props.href} target="_blank" rel="noopener noreferrer">
    {props.children}
  </a>
);

const CustomCodeRenderer = (props: any) => {
  return (
    <div className="incident_details__code-container">
      <pre className="incident_details__raw-code">{props.value}</pre>
      <div className="incident_details__code-copy">
        <CopyIconButton type="transparent">{props.value}</CopyIconButton>
      </div>
    </div>
  );
};

const TaskUnit = ({ ...props }: IProps) => {
  const ref = useRef<HTMLElement>(null);
  const content_ = props.isUpdate && props.content ? props.content : '';
  const [currentState, setCurrentState] = useState<string>('Markdown');
  const [content, setContent] = useState<string>(content_);

  const onHandleMessageChange = (content: string) => {
    setContent(content);
    props.contentUpdate(content);
  };
  return (
    <div>
      {currentState === markupType.MARKDOWN ? (
        <div>
          <MdBlock
            inputref={ref}
            value={content}
            onChange={onHandleMessageChange}
            className="incident_details__runbook__addTask_modal"
            options={{
              maxHeight: '130px',
              spellChecker: false,
              placeholder: addStepPlaceholder,
              imageUploadFunction: props.fileUploadService.getUploadFunctionForFeature(
                FileUploadFeature.NOTES,
                OperationType.CREATE,
              ),
              imageTexts: {
                sbInit: ``,
                ...imageTexts,
              },
              ...fileUploadConfig,
            }}
          ></MdBlock>
        </div>
      ) : (
        <div className="runbook__markdown">
          <ReactMarkdown
            className="incident_details__runbook__addTask_modal__Markdown incident_details__mde"
            plugins={[breaks, gfm]}
            renderers={{ link: renderMdLink, code: CustomCodeRenderer }}
            source={content}
          />
        </div>
      )}
    </div>
  );
};

export default TaskUnit;
