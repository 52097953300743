import { ServiceNowSvc } from 'core';
import { useMutation } from 'react-query';

async function CreateServiceNowIncident(incidentId: string) {
  const _servicenowSvc = new ServiceNowSvc();
  const data = await _servicenowSvc.createServiceNowIncident(incidentId);
  return data.data.data;
}

type Params = {
  onSuccess?: () => void;
  onError?: (err: any) => Promise<void>;
};

const useCreateServiceNowIncident = (params: Params) => {
  return useMutation(
    'save-configuration',
    (incidentId: string) => CreateServiceNowIncident(incidentId),
    {
      onSuccess: () => {
        params.onSuccess?.();
      },
      onError: (err: any) => {
        params.onError?.(err);
      },
    },
  );
};

export { useCreateServiceNowIncident };
