import React, { Fragment, useMemo } from 'react';

import { Flex, VStack } from '@chakra-ui/react';

import { GetSchedulesQuery } from 'views/main/organization/schedules/graphql/query';
import ListViewTable, { IListEvent, IRotationTable } from './listView.table';
import { DateTime } from 'luxon';
import { getUTCDateTime, isSameDate } from '../../helpers/helpers.date';
import { getGapAsRotation, getOverridesAsRotation } from '../../helpers/helpers.schedule';
import { useScheduleHeaderContext } from '../schedules.header/context';
import { includeOverridesInTheView } from '../../helpers/helpers.override';
import { splitEventsForTheListView } from '../../helpers/helpers.event';
import { rotationGapID } from '../../constants/schedules.view';
import { ScheduleEvent } from '../../graphql/types';

interface IListViewProps {
  schedules: GetSchedulesQuery['schedules'];
  visibleDates: DateTime[];
  overflowHeight: string;
  isScheduleDetailsView: boolean;
  timeZone: string;
}

const ListView = ({
  schedules,
  visibleDates,
  overflowHeight,
  isScheduleDetailsView,
  timeZone,
}: IListViewProps) => {
  const { activeRotViewType } = useScheduleHeaderContext();
  const includeOverrides = useMemo(() => {
    return includeOverridesInTheView(isScheduleDetailsView ?? false, activeRotViewType.value);
  }, [isScheduleDetailsView, activeRotViewType]);

  const events = useMemo(
    () =>
      (schedules?.flatMap(schedule =>
        [
          ...(schedule.rotations ?? []),
          {
            ...getOverridesAsRotation(schedule.ID, schedule.name, timeZone),
            events:
              schedule.overrides?.map(o => ({
                ID: o.ID,
                startTime: o.startTime,
                endTime: o.endTime,
                isOverride: true,
                overrideID: o.ID,
                participants: o.overrideWith?.participants,
                override: o,
              })) ?? ([] as Event[]),
          },
          {
            ...getGapAsRotation(schedule.ID, schedule.name, timeZone),
            events:
              schedule.gaps?.map(g => ({
                ...g,
                ID: 0,
                isOverride: false,
                overrideID: undefined,
                participants: undefined,
                override: undefined,
              })) ?? ([] as Event[]),
          },
        ]
          .filter(r => (includeOverrides ? true : r.ID !== -1))
          .flatMap(rotation => [
            ...(rotation.events?.map(event => {
              const startDT = getUTCDateTime(
                (event as unknown as ScheduleEvent).startTime,
                schedule.timeZone,
              );
              const endDT = getUTCDateTime(
                (event as unknown as ScheduleEvent).endTime,
                schedule.timeZone,
              );
              const overlappedByOverride =
                rotation.ID > rotationGapID
                  ? !!schedule.overrides?.find(
                      o =>
                        getUTCDateTime(o.startTime, timeZone) <= startDT &&
                        endDT <= getUTCDateTime(o.endTime, timeZone),
                    )
                  : false;
              return {
                ...rotation,
                ...event,
                scheduleID: schedule.ID,
                rotationName: rotation.name,
                scheduleName: schedule.name,
                scheduleTimeZone: schedule.timeZone,
                scheduleTags: schedule.tags?.filter(tag => !!tag.key && !!tag.value) ?? [],
                scheduleEscalations: schedule.escalationPolicies?.filter(ep => !!ep.name) ?? [],
                overlappedByOverride,
              };
            }) ?? []),
          ]),
      ) ?? []) as IListEvent[],
    [schedules],
  );

  const dayWiseEvents = useMemo(() => {
    return visibleDates.map(dt => {
      const dateWithTz = dt.setZone(timeZone);
      const {
        eventsBelongingToToday,
        eventsStartedBeforeEndingToday,
        eventsStartedTodayEndingLater,
      } = splitEventsForTheListView(events, dateWithTz, timeZone);

      const sortedEvent = [
        ...eventsStartedBeforeEndingToday,
        ...eventsBelongingToToday,
        ...eventsStartedTodayEndingLater,
      ].sort(
        (a, b) => new Date(a?.startTime || '').getTime() - new Date(b?.startTime || '').getTime(),
      );

      return { [dateWithTz.toSQLDate()]: sortedEvent };
    });
  }, [visibleDates, events]);

  const oHeight = useMemo(() => overflowHeight, [overflowHeight]);

  const tZone = useMemo(() => timeZone, [timeZone]);

  return (
    <Fragment>
      <Flex rowGap={5} direction="column">
        <VStack w="100%" spacing={0}>
          <ListViewTable events={dayWiseEvents} overflowHeight={oHeight} timeZone={tZone} />
        </VStack>
      </Flex>
    </Fragment>
  );
};

export default React.memo(ListView);
