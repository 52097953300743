import React from 'react';
import { Box, HStack, VStack } from '@chakra-ui/layout';
import qs from 'query-string';
import { WorkflowLog } from '../../../types/response/workflow';
import { useWorkflowLogColumns } from './useWorkflowLogs';
import { HoverComponentProps, Table } from 'library/molecules/Tablev2/Table';
import { CircularProgress, Text } from '@chakra-ui/react';
import { useWorkflowLogsContext } from '../../../context/workflowLogsContext';
import WorkflowActionLog from './WorkflowLogDetail';
import { useHistory, useParams } from 'react-router';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_WP_LOG_DETAILS_VIEWED } from 'core/const/tracker';
import { FormButton } from 'library/atoms';
import { Placeholder } from 'library/molecules';

type WorkflowLogsHoverComponentProps = HoverComponentProps<WorkflowLog>;

export const HoverComponent: React.FC<WorkflowLogsHoverComponentProps> = props => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const onViewLogDetail = () => {
    AppTracker.track(T_WA_WP_LOG_DETAILS_VIEWED, {
      workflow_id: id,
    });
    history.push({
      pathname: history.location.pathname,
      search: qs.stringifyUrl({
        url: history.location.search,
        query: {
          log: props.row.original.id,
        },
      }),
    });
  };
  return (
    <HStack gap="0.5rem">
      <FormButton onClick={onViewLogDetail} title="View Log Detail" />
    </HStack>
  );
};

const WorkflowLogs: React.FC = () => {
  const {
    workflowLogs,
    isLoadingInitialWorkflowLogs,
    isLoadingWorkflowLogs,
    paginationInfo,
    setPagination,
    onSearch,
    searchValue,
  } = useWorkflowLogsContext();
  const columns = useWorkflowLogColumns();
  const onFilter = () => {};

  if (isLoadingInitialWorkflowLogs) {
    return (
      <VStack h="inherit" justifyContent="center">
        <CircularProgress isIndeterminate />
      </VStack>
    );
  }

  if (!workflowLogs?.data?.length) {
    return <Placeholder description="No Logs Found" size="md" />;
  }

  return (
    <VStack flex={1} mt="4">
      {/* <HStack justify="end" w="100%">
        <PageHeader searchValue={searchValue} onSearch={onSearch} onFilter={onFilter} />
      </HStack> */}
      <Table
        data={workflowLogs?.data ?? []}
        columns={columns}
        pageCount={workflowLogs?.metadata?.total_count}
        hoverComponent={props => <HoverComponent {...props} />}
        pageIndex={paginationInfo?.pageIndex}
        pageSize={paginationInfo?.pageSize}
        onPaginationChange={setPagination}
        isLoading={isLoadingWorkflowLogs}
      />
      <WorkflowActionLog />
    </VStack>
  );
};

export { WorkflowLogs };
