import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#3B4554">
      <path d="M3.242 19.814V16.44l.613-.263v-3.48H1.947v3.48l.613.263v3.374H0V16.44l.612-.263V5.21L0 4.91V1.543h2.56v3.374l-.613.29v4.116h1.91V5.208l-.613-.291V1.543h2.56v3.374l-.613.29v10.97l.613.262v3.374H3.242v.001Z" />
      <path d="M8.743 17.768s-.218 2.233-1.163 2.233c-.923 0-1.336-1.991-1.336-4.702v-4.247l-.464-.263V7.41h1.77v6.986c0 1.355.155 2.046.543 2.046.317 0 .45-.663.489-.954v-4.434l-.396-.263V7.41h1.699v8.764l.459.263v3.374H8.93l-.155-2.046h-.032v.003ZM12.404 9.455h.03s.219-2.233 1.164-2.233c.924 0 1.336 1.991 1.336 4.702v4.247l.464.262v3.375H13.63v-6.98c0-1.355-.155-2.046-.543-2.046-.326 0-.45.719-.489.982v4.405l.396.263v3.375h-2.157v-3.375l.458-.263v-5.125l-.458-.263V7.407h1.412l.155 2.048ZM17.703 3.375V.001h1.839v16.175l.458.263v3.374h-1.412l-.155-2.046h-.03S18.13 20 17.284 20c-.885 0-1.552-1.7-1.552-6.376 0-4.384.667-6.404 1.576-6.404.69 0 .939 1.3.953 1.383h.03l-.054-1.383V3.637l-.535-.262Zm.527 7.912c-.092-.263-.24-.504-.489-.504-.472 0-.72 1.009-.72 2.814 0 1.729.21 2.843.745 2.843.31 0 .464-.691.464-.691v-4.462Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
