import {
  REQUEST_ORG_CALENDAR,
  REQUEST_ORG_CALENDAR_SUCCESS,
  REQUEST_ORG_CALENDAR_FAIL,
  REQUEST_ORG_CALENDAR_CONSUMED,
  REQUEST_ORG_CALENDAR_ON_CALL_SUCCESS,
  REQUEST_ORG_CALENDAR_ON_CALL,
} from '../../const/organization';
import { ISCalendar, ISCalendarOnCallUser } from '../../interfaces/ICalendar';

const requestOrganizationCalendar = () => ({
  type: REQUEST_ORG_CALENDAR,
  payload: {},
});

const onRequestOrganizationCalendarSuccess = (calendar: ISCalendar[]) => ({
  type: REQUEST_ORG_CALENDAR_SUCCESS,
  payload: {
    calendar,
  },
});

const onRequestOrganizationCalendarConsumed = () => ({
  type: REQUEST_ORG_CALENDAR_CONSUMED,
  payload: {},
});

const onRequestOrganizationCalendarFail = (error: any) => ({
  type: REQUEST_ORG_CALENDAR_FAIL,
  payload: {
    error,
  },
});

const requestOrganizationCalendarOnCall = (calendarIds: string[]) => ({
  type: REQUEST_ORG_CALENDAR_ON_CALL,
  payload: {
    calendarIds,
  },
});

const onRequestOrganizationCalendarOnCallSuccess = (onCall: {
  [key: string]: ISCalendarOnCallUser[];
}) => ({
  type: REQUEST_ORG_CALENDAR_ON_CALL_SUCCESS,
  payload: {
    onCall,
  },
});

const onRequestOrganizationCalendarOnCallFail = (error: any) => ({
  type: REQUEST_ORG_CALENDAR_ON_CALL,
  payload: {
    error,
  },
});

export {
  requestOrganizationCalendar,
  onRequestOrganizationCalendarSuccess,
  onRequestOrganizationCalendarConsumed,
  onRequestOrganizationCalendarFail,
  requestOrganizationCalendarOnCall,
  onRequestOrganizationCalendarOnCallSuccess,
  onRequestOrganizationCalendarOnCallFail,
};
