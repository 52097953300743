import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { ISloAggrigator } from '../slo.interface';
import axios from 'axios';
import { API } from '../../../../../core/api';
import { createStandaloneToast } from '@chakra-ui/react';
import { getSloAggrigatedData } from '../slo.helper';
import { queryKeys } from '../slo.constants';

const toast = createStandaloneToast();

const getSLOAggregatedData = async (teamId: string) => {
  const { data } = await axios.get<{ data: ISloAggrigator }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo/aggregate-metrics?owner_id=${teamId}`,
  );
  return data;
};

export function useSloAgregated(teamId: string) {
  const [selectedTeamId, setTeamId] = useState<string>(teamId);

  const { data, isError, isLoading, error, isSuccess } = useQuery(
    [queryKeys.SLOAGGREGATED, selectedTeamId],
    () => getSLOAggregatedData(selectedTeamId),
    {
      retry: 1,
      staleTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
        toast.closeAll();
        toast({ title, status: 'error', variant: 'subtle', isClosable: true });
      },
      select: data => {
        if (data.data.slo_aggregate) return getSloAggrigatedData(data.data.slo_aggregate);
      },
    },
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    setTeamId,
  };
}
