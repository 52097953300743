import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Text, useMultiStyleConfig } from '@chakra-ui/react';
import { buildPageLabels } from 'library/molecules/Table/Pagination/util';
import { FC, useMemo } from 'react';
import { FormButton, IconButton } from 'library/atoms';
import Dropdown, { DropdownOverrideStyle } from 'library/atoms/FrameworkDropdown';

type Option = { label: number; value: number };
const pageSizeOptions = [5, 10, 20, 30, 50, 100].map(val => ({
  label: val,
  value: val,
})) as Option[];

type PaginationProps = {
  pageIndex: number;
  pageSize?: number;
  loading?: boolean;
  disabled?: boolean;
  canNextPage?: boolean;
  onNextPage?: () => void;
  canPreviousPage?: boolean;
  onPreviousPage?: () => void;
  onPageSizeChange?: (pageSize: number) => void;
  pageCount?: number;
  gotoPage: (page: number) => any;
  showNumberBasedPagination?: boolean;
};

const Pagination: FC<PaginationProps> = props => {
  const styles = useMultiStyleConfig('Pagination', {
    loading: props.loading,
    disabled: props.disabled,
    canNextPage: props.canNextPage,
    canPreviousPage: props.canPreviousPage,
  });

  const showNumbers = props?.showNumberBasedPagination ?? false;

  const labels = useMemo(() => {
    const pageLabels = buildPageLabels(props.pageIndex, props.pageCount || 0);
    return pageLabels.map((label, i) => {
      const page = label;
      if (!label) {
        return (
          <Box as="span" key={`dot-${i}`} pointerEvents={'none'} userSelect={'none'}>
            ...
          </Box>
        );
      }

      return (
        <FormButton
          key={`${i}th-page-${page}`}
          variant="ghost"
          sx={{
            color: page === props.pageIndex + 1 ? 'secondary.1000' : 'brand.blue',
          }}
          aria-label={`Go to page ${page}`}
          disabled={page === props.pageIndex + 1}
          onClick={() => {
            props.gotoPage(page);
          }}
          title={page.toString()}
        />
      );
    });
  }, [props.pageCount, props.pageIndex, props.pageSize]);

  return (
    <HStack sx={styles.container}>
      <HStack>
        <Text variant="h4" color="secondary.700">
          Show rows per page
        </Text>

        <Box>
          <Dropdown<Option>
            onChange={option => option && props.onPageSizeChange?.(option.value)}
            options={pageSizeOptions}
            value={pageSizeOptions.find(option => option?.value === props.pageSize) || null}
            isDisabled={props.disabled}
            isMulti={false}
            overrideStyles={{
              control: styles.sizeContainer as DropdownOverrideStyle,
              menu: styles.sizeContainer as DropdownOverrideStyle,
            }}
            menuPlacement="top"
          />
        </Box>
      </HStack>

      <Flex gap={2} justifyContent={'center'} alignItems={'center'}>
        <IconButton
          variant="ghost"
          aria-label="Go to Previous"
          disabled={!props.canPreviousPage || props.disabled}
          icon={<ChevronLeftIcon sx={styles.icon} />}
          onClick={props.onPreviousPage}
        />
        {showNumbers && labels}
        <IconButton
          variant="ghost"
          aria-label="Go to Next"
          disabled={!props.canNextPage || props.disabled}
          icon={<ChevronRightIcon sx={styles.icon} />}
          onClick={props.onNextPage}
        />
      </Flex>
    </HStack>
  );
};

export default Pagination;
