import * as React from 'react';
import { SVGProps } from 'react';

const SvgSentry = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#362d59',
      }}
      d="M11.591 2.088a1.87 1.87 0 0 0-3.203 0L5.753 6.601a12.897 12.897 0 0 1 7.108 10.675h-1.85a11.083 11.083 0 0 0-6.19-9.098l-2.44 4.217a6.375 6.375 0 0 1 3.697 4.873H1.829a.304.304 0 0 1-.248-.445l1.177-2.002a4.3 4.3 0 0 0-1.345-.76L.248 16.061a1.818 1.818 0 0 0 .676 2.499c.277.156.588.238.905.24h5.818a7.768 7.768 0 0 0-3.203-6.931l.925-1.602a9.558 9.558 0 0 1 4.124 8.533h4.93A14.367 14.367 0 0 0 7.85 6.068l1.87-3.203a.308.308 0 0 1 .42-.108c.213.116 8.125 13.922 8.273 14.082a.304.304 0 0 1-.272.453h-1.906c.024.51.024 1.018 0 1.525h1.914A1.838 1.838 0 0 0 20 16.992v-.02c0-.322-.086-.637-.248-.913l-8.16-13.971z"
    />
  </svg>
);

export default SvgSentry;
