import SQButton from 'components/chakra/Button';
import Header from 'components/chakra/Header';
import { CollapsedIcon, ExpandIcon } from 'icons';
import React, { useEffect, useMemo } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Button, ButtonGroup, Heading, HStack, IconButton } from '@chakra-ui/react';

import { rotationViewType } from '../../constants/schedules.rotation-type';
import { useScheduleHeaderContext } from './context';
import { CalendarViewType } from '../../interface/schedule';
import { getMediumDate, getDateInfo } from '../../helpers/helpers.date';
import { useHistory, useLocation } from 'react-router-dom';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_SCHEDULES_V2_VIEW_CHOSEN } from 'core/const/tracker';

const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

function SchedulesHeader({ enableRotationSelection }: { enableRotationSelection?: boolean }) {
  const {
    schedulesViewTypes,
    allExpanded,
    onExpandAll,
    activeRotViewType,
    onChangeActiveRotation,
    activeViewType,
    onChangeViewType,
    visibleDates,
    onPrevious,
    onNext,
    onToday,
    activeDate,
    currentScheduleTimezone,
  } = useScheduleHeaderContext();
  const history = useHistory();
  const query = useQueryParams();

  useEffect(() => {
    if (activeViewType) {
      onChangeViewType(activeViewType.value);
    }
  }, [activeViewType]);

  const initExpandAllRows = (expandedRows: boolean) => () => {
    onExpandAll(expandedRows);
  };

  return (
    <Header
      title={
        <HStack gap={2}>
          <Button variant="outline" name="Today" onClick={(event: any) => onToday(event)}>
            Today
          </Button>
          <IconButton
            aria-label="Previous"
            variant="outline"
            name="Previous"
            icon={<ChevronLeftIcon boxSize={5} />}
            onClick={(event: any) => onPrevious(event)}
          />
          <IconButton
            aria-label="Next"
            variant="outline"
            name="Next"
            icon={<ChevronRightIcon boxSize={5} />}
            onClick={(event: any) => onNext(event)}
          />
          {[
            CalendarViewType.twotimeGridWeek,
            CalendarViewType.listView,
            CalendarViewType.twoGridWeek,
          ].includes(activeViewType.value) && (
            <Button variant="outline" data-testid="duration">
              {getMediumDate(visibleDates.twoWeeksView[0]?.toJSDate(), currentScheduleTimezone)} -{' '}
              {getMediumDate(
                visibleDates.twoWeeksView.slice(-1)[0]?.toJSDate(),
                currentScheduleTimezone,
              )}
            </Button>
          )}
          {CalendarViewType.timeGridWeek === activeViewType.value && (
            <Button variant="outline" data-testid="duration">
              {getMediumDate(visibleDates.oneWeekView[0]?.toJSDate(), currentScheduleTimezone)} -{' '}
              {getMediumDate(
                visibleDates.oneWeekView.slice(-1)[0]?.toJSDate(),
                currentScheduleTimezone,
              )}
            </Button>
          )}
          {activeViewType.value === CalendarViewType.dayGridMonth && (
            <Button variant="outline" data-testid="duration">
              {`${getDateInfo(activeDate).month} ${getDateInfo(activeDate).year}`}
            </Button>
          )}
          {enableRotationSelection && (
            <SQButton.Menu
              label="rotation-type-selection"
              activeItem={activeRotViewType}
              itemList={rotationViewType}
              onMenuClick={type => {
                if (type.value !== activeRotViewType.value) {
                  onChangeActiveRotation(type);
                }
              }}
            />
          )}
        </HStack>
      }
      actions={
        <HStack justifyContent="flex-end" gap={2}>
          {[CalendarViewType.gridWeek, CalendarViewType.twoGridWeek].includes(
            activeViewType.value,
          ) && (
            <ButtonGroup size="sm" isAttached variant="outline">
              <IconButton
                variant="icon"
                title="Collapse All"
                aria-label="Collapse All"
                sx={{
                  '& svg *': {
                    fill: 'default',
                  },
                  bg: allExpanded ? 'white' : 'blue.100',
                }}
                icon={<CollapsedIcon style={{ width: '12px', height: '12px' }} />}
                isActive={!allExpanded}
                onClick={initExpandAllRows(false)}
              />

              <IconButton
                variant="icon"
                title="Expand All"
                aria-label="Expand All"
                sx={{
                  '& svg *': {
                    fill: 'default',
                  },
                  bg: allExpanded ? 'blue.100' : 'white',
                }}
                icon={<ExpandIcon style={{ width: '12px', height: '12px' }} />}
                isActive={allExpanded}
                onClick={initExpandAllRows(true)}
              />
            </ButtonGroup>
          )}

          <SQButton.Menu
            label="schedules-view-selection"
            activeItem={activeViewType}
            itemList={schedulesViewTypes}
            onMenuClick={type => {
              if (type.value !== activeViewType.value) {
                query.delete('view');
                query.append('view', type.value);
                AppTracker.track(T_WA_UP_SCHEDULES_V2_VIEW_CHOSEN);
                history.push(`${history.location.pathname}?${query}`);
              }
            }}
          />
        </HStack>
      }
    />
  );
}

interface ISchedulesPreviewProps {
  title?: JSX.Element;
}

function SchedulesPreview({ title }: ISchedulesPreviewProps) {
  const {
    schedulesViewTypes,
    activeViewType,
    onChangeViewType,
    onPrevious,
    onNext,
    visibleDates,
    onToday,
    activeDate,
    currentScheduleTimezone,
  } = useScheduleHeaderContext();

  useEffect(() => {
    if (activeViewType) {
      onChangeViewType(activeViewType.value);
    }
  }, [activeViewType]);

  return (
    <Header
      paddingY={3}
      title={
        <HStack gap={2}>
          {title ? title : <Heading>Preview</Heading>}
          <Button variant="outline" name="Today" onClick={(event: any) => onToday(event)}>
            Today
          </Button>
          <IconButton
            aria-label="Previous"
            name="Previous"
            variant="outline"
            icon={<ChevronLeftIcon boxSize={5} />}
            onClick={(event: any) => onPrevious(event)}
          />

          <IconButton
            aria-label="Next"
            name="Next"
            variant="outline"
            icon={<ChevronRightIcon boxSize={5} />}
            onClick={(event: any) => onNext(event)}
          />

          {activeViewType.value === CalendarViewType.timeGridWeek && (
            <Button variant="outline">
              {getMediumDate(visibleDates.oneWeekView[0]?.toJSDate(), currentScheduleTimezone)} -{' '}
              {getMediumDate(
                visibleDates.oneWeekView.slice(-1)[0]?.toJSDate(),
                currentScheduleTimezone,
              )}
            </Button>
          )}

          {activeViewType.value === CalendarViewType.dayGridMonth && (
            <Button variant="outline">
              {`${getDateInfo(activeDate).month} ${getDateInfo(activeDate).year}`}
            </Button>
          )}
        </HStack>
      }
      actions={
        <HStack justifyContent="flex-end" gap={2}>
          <SQButton.Menu
            activeItem={activeViewType}
            itemList={schedulesViewTypes}
            onMenuClick={type => {
              if (type.value !== activeViewType.value) {
                onChangeViewType(type.value);
                AppTracker.track(T_WA_UP_SCHEDULES_V2_VIEW_CHOSEN);
              }
            }}
          />
        </HStack>
      }
    />
  );
}

SchedulesHeader.Preview = SchedulesPreview;

export default SchedulesHeader;
