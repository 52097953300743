import { Box } from '@chakra-ui/layout';
import { useMemo } from 'react';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { Jira_ACTIONS } from '../../../constant';
import ActionItem from './ActionItem';
import IntegrateActionsUI from '../IntegrateActionsUI';
import { useCheckJiraStatus } from '../../../details/hooks/useCheckJiraStatus';

const JiraActionList = () => {
  const { data: jiraStatus } = useCheckJiraStatus();

  const { handleSubActionClick, actions, triggerType } = useWorkflowFormContext();
  const addedActionTypes = useMemo(() => actions.map(action => action.name), [actions]);

  const availableActions = useMemo(
    () =>
      Jira_ACTIONS.filter(
        action => !action.disabledForTriggers?.includes(triggerType) && !action.disabled,
      ),
    [triggerType],
  );
  if (!jiraStatus?.length) {
    return <IntegrateActionsUI action={'Jira'} />;
  }
  return (
    <Box>
      {availableActions.map(action => (
        <ActionItem
          action={action}
          onClick={handleSubActionClick}
          key={action.name}
          isAdded={addedActionTypes.includes(action.name)}
        />
      ))}
    </Box>
  );
};

export default JiraActionList;
