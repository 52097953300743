import { ActionTypes, SubActionTypes } from 'views/main/organization/workflow/types';

import {
  MsTeamsIcon,
  JiraBlueIcon,
  SlackIcon,
  SquadcastIcon,
  WorkflowsCommChannelIcon,
  WorkflowsEmailIcon,
  WorkflowsHttpCallIcon,
  WorkflowsIncidentNoteIcon,
  WorkflowsReassignIcon,
  WorkflowsResolveIcon,
  WorkflowsRunbooksIcon,
  WorkflowsSLOAffectIcon,
  WorkflowsWebhookIcon,
  JiraaIcon,
  PriorityActionIcon,
  WfStatusPageIcon,
} from 'icons';

const getActionIcon = (size = 24) => ({
  [ActionTypes.SQUADCAST]: <SquadcastIcon height={size} width={size} />,
  [SubActionTypes.COMMUNICATION_CHANNEL]: <WorkflowsCommChannelIcon height={size} width={size} />,
  [SubActionTypes.RUNBOOKS]: <WorkflowsRunbooksIcon height={size} width={size} />,
  [SubActionTypes.REASSIGN_INCIDENT]: <WorkflowsReassignIcon height={size} width={size} />,
  [SubActionTypes.RESOLVE_INCIDENT]: <WorkflowsResolveIcon height={size} width={size} />,
  [SubActionTypes.INCIDENT_NOTE]: <WorkflowsIncidentNoteIcon height={size} width={size} />,
  [SubActionTypes.SLO_AFFECTED]: <WorkflowsSLOAffectIcon height={size} width={size} />,
  [SubActionTypes.TRIGGER_MANUAL_WEBHOOK]: <WorkflowsWebhookIcon height={size} width={size} />,
  [SubActionTypes.HTTP_CALL]: <WorkflowsHttpCallIcon height={size} width={size} />,
  [SubActionTypes.SEND_EMAIL]: <WorkflowsEmailIcon height={size} width={size} />,
  [SubActionTypes.PRIORITY]: <PriorityActionIcon height={size} width={size} />,
  [SubActionTypes.STATUS_PAGE]: <WfStatusPageIcon height={size} width={size} />,

  [SubActionTypes.MS_TEAMS_MESSAGE_CHANNEL]: <MsTeamsIcon height={size} width={size} />,
  [SubActionTypes.MS_TEAMS_MESSAGE_USER]: <MsTeamsIcon height={size} width={size} />,
  [SubActionTypes.MS_TEAMS_CREATE_MEETING_LINK]: <MsTeamsIcon height={size} width={size} />,
  [SubActionTypes.SLACK_CHANNEL_ARCHIEVE]: <SlackIcon height={size} width={size} />,
  [SubActionTypes.SLACK_CHANNEL_CREATE]: <SlackIcon height={size} width={size} />,
  [SubActionTypes.SLACK_MESSAGE_USER]: <SlackIcon height={size} width={size} />,
  [SubActionTypes.SLACK_MESSAGE_CHANNEL]: <SlackIcon height={size} width={size} />,
  [SubActionTypes.JIRA_CREATE_TICKET]: <JiraBlueIcon height={size} width={size} />,
  [SubActionTypes.JIRA_CREATE]: <JiraaIcon height={size} width={size} />, //TODO: temporary fix for Icon bug
});

export const ACTION_ICONS_MAP = {
  sm: getActionIcon(),
  md: getActionIcon(32),
};
