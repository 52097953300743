import {
  REQUEST_INTEGRATIONS,
  REQUEST_INTEGRATIONS_SUCCESS,
  REQUEST_INTEGRATIONS_FAIL,
} from '../../const/public';
import { IIntegration } from '../../interfaces/IIntegration';

const requestIntegrations = () => ({
  type: REQUEST_INTEGRATIONS,
  payload: {},
});

const onRequestIntegrationsSuccess = (integrations: IIntegration[]) => ({
  type: REQUEST_INTEGRATIONS_SUCCESS,
  payload: {
    integrations,
  },
});

const onRequestIntegrationsFail = (error: any) => ({
  type: REQUEST_INTEGRATIONS_FAIL,
  payload: {
    error,
  },
});

export { requestIntegrations, onRequestIntegrationsSuccess, onRequestIntegrationsFail };
