import * as React from 'react';
import { SVGProps } from 'react';

const SvgMezmoLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <image
      style={{
        overflow: 'visible',
      }}
      width={93}
      height={134}
      xlinkHref="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEASABIAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGSAAABngAAAb//2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAIYAXQMBIgACEQEDEQH/ xABfAAEBAAAAAAAAAAAAAAAAAAAABgEBAQAAAAAAAAAAAAAAAAAAAAIQAQEAAAAAAAAAAAAAAAAA AFBwEQEAAAAAAAAAAAAAAAAAAABwEgEAAAAAAAAAAAAAAAAAAABQ/9oADAMBAAIRAxEAAACaCQAA AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP/2gAIAQIAAQUAW//aAAgBAwAB BQBb/9oACAEBAAEFAKZ//9oACAECAgY/AFv/2gAIAQMCBj8AW//aAAgBAQEGPwBM/9k="
      transform="translate(-7894 -7771)"
    />
    <path
      style={{
        fill: '#303935',
      }}
      d="M0 0h20v20H0z"
    />
    <path
      style={{
        fill: '#e9ff92',
      }}
      d="M9.208 7.845v5.461c0 .696.313 1.182 1.043 1.182.555 0 .938-.417 1.147-1.008l1.947-5.635c.156-.469.4-.73.886-.73.366 0 .678.261.678.73v6.401a.105.105 0 0 0 .003.043.08.08 0 0 0 .023.037.09.09 0 0 0 .08.026h1.378a.105.105 0 0 0 .043-.003.08.08 0 0 0 .037-.023.09.09 0 0 0 .026-.08V7.807c0-1.287-.991-2.296-2.226-2.296-1.2 0-1.999.742-2.382 1.878l-.921 2.713c-.035.106-.052.14-.106.14a.067.067 0 0 1-.028-.003.06.06 0 0 1-.024-.015.06.06 0 0 1-.015-.024.065.065 0 0 1-.003-.028V7.807c0-1.287-.991-2.296-2.226-2.296-1.2 0-1.999.742-2.382 1.878l-.921 2.713c-.035.106-.052.14-.106.14a.055.055 0 0 1-.028-.004.051.051 0 0 1-.023-.015.06.06 0 0 1-.015-.024.065.065 0 0 1-.003-.028V5.754c.002-.014.001-.029-.003-.043s-.011-.027-.022-.037a.09.09 0 0 0-.08-.026H3.607a.09.09 0 0 0-.08.026.09.09 0 0 0-.026.08v7.548c0 .696.318 1.182 1.043 1.182.556 0 .939-.417 1.148-1.009L7.639 7.84c.156-.435.435-.73.886-.73.369 0 .683.261.683.73v.005z"
    />
  </svg>
);

export default SvgMezmoLogo20X20;
