import {
  ChakraProvider,
  Circle,
  Container,
  extendTheme,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  WrapItem,
} from '@chakra-ui/react';
import SQTooltip from 'components/chakra/Tooltip';
import { Popover, usernameTooltipLabel } from 'library/atoms';
import libraryTheme from 'library/theme';
import chakraTheme from '../../schedules/theme';
import { Owner } from '../hooks/useEntityOwnerFilter';

export type ActiveFilterTag = {
  key: string;
  type: Owner;
  id: string;
  value: string;
  color?: string;
  username?: string;
};

interface Props {
  tags: ActiveFilterTag[];
  visibleTagsCount: number;
  onClose: (type: Owner, id: string) => void;
  applyChakraStyle: boolean;
}

export function ActiveFilterTags<T>({ tags, visibleTagsCount, onClose, applyChakraStyle }: Props) {
  const renderTags = (tags: ActiveFilterTag[]) => {
    return tags.map((tag, index) => {
      const { type, value, key, id, username } = tag;
      const tagText = [key, value].join(' : ');
      const valueWithoutUsername = value.replace(`(${username})`, '');
      return (
        <SQTooltip
          key={index}
          text={usernameTooltipLabel(valueWithoutUsername, username)}
          placement="top"
        >
          <WrapItem m={2}>
            <Tag background="#EEF2F6" border="none" px={2} py={1}>
              <TagLabel>
                <Text isTruncated maxW={150}>
                  {tagText}
                </Text>
              </TagLabel>
              <TagCloseButton
                onClick={() => {
                  onClose(type, id);
                }}
              />
            </Tag>
          </WrapItem>
        </SQTooltip>
      );
    });
  };
  const showTagsWhenWithinLimit = () => renderTags(tags);
  const showTagsWhenOverTheLimit = () => {
    const visibleTags = tags.slice(0, visibleTagsCount);
    const tagsInsidePopover = tags.slice(visibleTagsCount);
    return (
      <>
        {renderTags(visibleTags)}
        <Popover
          trigger={
            <Circle cursor="pointer" size="19px" bg="#C8E2FF">
              <Text variant="hint" color="#09305A">
                +{tagsInsidePopover.length}
              </Text>
            </Circle>
          }
          triggerType="hover"
          disablePortal
          hasArrow
        >
          <Container maxW="md">{renderTags(tagsInsidePopover)}</Container>
        </Popover>
      </>
    );
  };
  return (
    <ChakraProvider resetCSS={applyChakraStyle} theme={extendTheme(chakraTheme, libraryTheme)}>
      <HStack columnGap={1}>
        {tags.length <= visibleTagsCount ? showTagsWhenWithinLimit() : showTagsWhenOverTheLimit()}
      </HStack>
    </ChakraProvider>
  );
}
