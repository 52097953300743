import React, { Component } from 'react';
import { ChromePicker } from 'react-color';
import { TextButton } from '@squadcast/alchemy-ui/metal';
import equal from 'fast-deep-equal/es6/react';
import { deepCopy, pick } from 'core/helpers';

interface IProps {
  hide: (isASave?: boolean) => void;
  theme: string;
  onThemeChange: (theme: string) => void;
  checkAndSetDirty: () => void;
}

interface IState {
  theme: string;
}

class ThemeModal extends Component<IProps, IState> {
  private _initialState?: IState;

  constructor(props: IProps) {
    super(props);

    this.state = {
      theme: deepCopy(this.props.theme),
    };

    this._initialState = deepCopy(this.state);
  }

  componentDidUpdate() {
    if (this._initialState && !equal(pick(this.state, 'theme'), pick(this._initialState, 'theme')))
      this.props.checkAndSetDirty();
  }

  public render() {
    return (
      <div
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <div className="">
          <div className="float-left">
            <h1 className="modal-container-heading">Edit Theme</h1>
            <small>Customize the color of the background, buttons and links</small>
          </div>
        </div>
        <div className="mt-20 cp">
          <ChromePicker
            color={this.state.theme}
            onChange={color => {
              this.setState({ theme: color.hex });
              this.props.onThemeChange(color.hex);
            }}
          />
        </div>
        <div className="mt-20">
          <TextButton
            id="theme_modal_save"
            onClick={() => this.props.hide(true)}
            size="normal"
            text="Save"
          />
        </div>
      </div>
    );
  }
}

export default ThemeModal;
