import {
  DurationType,
  FormState,
  MappingTableTypes,
  ROUTING_BEHAVIOR,
  ROUTING_TYPE,
} from '../../../types/LCR.types';

export const updateIncides = (sourceArr: MappingTableTypes[]) => {
  const updatedArraay = sourceArr.map((ele: MappingTableTypes, index: number) => {
    return {
      ...ele,
      keypadNumber: index + 1,
    };
  });
  console.log('updateIncides', updatedArraay);
  return updatedArraay;
};

export const moveElement = (
  sourceArr: MappingTableTypes[],
  keypadNumber: number,
  opration: 'up' | 'down',
) => {
  const arr = sourceArr;
  let sourceIndex = 0;
  let targetIndex = 0;
  if (opration === 'down') {
    sourceIndex = keypadNumber - 1;
    targetIndex = keypadNumber;
  } else if (opration === 'up') {
    sourceIndex = keypadNumber - 1;
    targetIndex = keypadNumber - 2;
  }
  const temp = arr[sourceIndex];
  arr[sourceIndex] = arr[targetIndex];
  arr[targetIndex] = temp;
  console.log(arr);
  return updateIncides(arr);
};

export const generateKeyWordMap = (sourceArray: MappingTableTypes[]) => {
  const resultArray = sourceArray.map((ele: MappingTableTypes) => {
    if (ele?.schedule)
      return {
        alias: ele.alias,
        service_id: ele?.service?.serviceID,
        schedule_ids: ele?.schedule?.map((s: any) => s.ID),
      };
    return {
      alias: ele.alias,
      service_id: ele?.service?.serviceID,
    };
  });

  return resultArray;
};

export const genrateFormErrorEntityObject = (entity: FormState) => {
  return {
    name: entity.name.trim().length ? '' : 'Name cannot be empty',
    routingType: entity?.routingType ? '' : 'Please select the type of routing number',
    routingBehavior: entity?.routingBehavior
      ? ''
      : 'Please select the behaviour of the routing number*',
    greetingMessage: entity?.greetingMessage.trim().length
      ? ''
      : 'Greetings message cannot be empty',
    routingNumber:
      entity?.country?.id === 'IN' ||
      entity?.country?.id === 'BE' ||
      entity?.routingNumber?.trim().length
        ? ''
        : 'Please genearate a routing number',
    messageStructure: entity?.messageStructure?.trim()?.length
      ? ''
      : 'Please input how you would like to structure your message*',
    callDuration: entity?.callDuration?.value ? '' : 'Please select max duration of call',
    owner: entity?.owner ? '' : 'NO owner',
  };
};

export function containsObject(obj: any, list: any) {
  for (let i = 0; i < list.length; i++) {
    if (list[i].ID === obj.ID) {
      return true;
    }
  }

  return false;
}

export const getDurations = (type: ROUTING_BEHAVIOR, extraMinutes: string | undefined) => {
  const baseIntervals = type === ROUTING_BEHAVIOR.VOICEMAIL ? [3, 4, 5] : [3, 4, 5, 6, 7];
  const extraIntervalsMap: { [key: string]: number[] } = {
    '10': [10],
    '15': [10, 15],
    '20': [10, 15, 20],
  };

  const extraIntervals =
    extraMinutes && type !== ROUTING_BEHAVIOR.VOICEMAIL
      ? extraIntervalsMap[extraMinutes] || []
      : [];
  const secondsInterval = [...baseIntervals, ...extraIntervals];

  return secondsInterval.map(ele => ({
    label: `${ele} mins`,
    value: ele * 60,
  }));
};

export const getAvailableCountries = () => {
  return [
    {
      name: 'USA',
      id: 'US',
    },
    {
      name: 'Canada',
      id: 'CA',
    },
    {
      name: 'Australia',
      id: 'AU',
    },
    {
      name: 'India',
      id: 'IN',
    },
    {
      name: 'Belgium',
      id: 'BE',
    },
    {
      name: 'United Kingdom',
      id: 'GB',
    },
  ];
};
