import FrameWorkDropdown, { Props as DropdownProps } from 'library/atoms/FrameworkDropdown';
import { forwardRef } from 'react';
import { useController } from 'react-hook-form';

interface Props<Option> extends DropdownProps<Option, boolean> {
  name: string;
}

const FormDropdown = forwardRef(function <Option>({ name, ...rest }: Props<Option>) {
  const { field } = useController({ name });
  return (
    <FrameWorkDropdown
      value={field.value}
      {...rest}
      onChange={(...args) => {
        field.onChange(...args);
        if (typeof rest.onChange === 'function') {
          rest?.onChange(...args);
        }
      }}
    />
  );
});

export default FormDropdown;
