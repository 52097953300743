import React, { HTMLAttributes, ReactChild } from 'react';
import styled from 'styled-components';

interface IShellProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onMouseEnter' | 'onMouseLeave'> {
  padding?: string | '4px';
  height?: string | 'fit-content';
  setEditable?: boolean | false;
}

const Shell = styled.div<IShellProps>`
  min-height: ${props => props.height || 'fit-content'};
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  outline: none;
  position: relative;
  background-color: ${props => (props.setEditable ? props.theme.shades.whiteSmoke : 'inherit')};

  & > {
    transition: background 0.17s ease;
  }

  &:hover,
  &:focus,
  &:focus-within,
  &.showEditable {
    background-color: ${props => props.theme.shades.whiteSmoke};
  }

  & > .EditableRow_editable_shell {
    display: ${props => (props.setEditable ? 'block' : 'none')};
    min-height: ${props => props.height || 'fit-content'};
    height: 100%;
    padding: 0 4px;
    margin-left: 2px;
    box-shadow: 0 0 0 2px ${props => props.theme.shades.white};
    position: absolute;
    right: 0;
  }

  &:hover > .EditableRow_editable_shell,
  &:focus-within > .EditableRow_editable_shell {
    display: block;
  }
`;

interface IProps extends IShellProps {
  children: ReactChild;
  editableChildren: ReactChild;
}

const EditableRow = ({ children, editableChildren, ...props }: IProps) => {
  return (
    <Shell {...props} tabIndex={0}>
      {children}
      <div className="EditableRow_editable_shell">{editableChildren}</div>
    </Shell>
  );
};

/** @component */
export default EditableRow;
