import {
  T_WA_UP_SG_VIEW_RESET,
  T_WA_UP_SG_VIEW_ZOOMED_IN,
  T_WA_UP_SG_VIEW_ZOOMED_OUT,
} from 'core/const/tracker';
import { IconButton } from 'library/atoms';
import { FitControl, LibraryIcons } from 'library/icons';
import { THEME_COLORS } from 'library/theme/colors';
import { FC, useEffect } from 'react';
import { ControlButton, Controls, useReactFlow, useStoreApi, useViewport } from 'reactflow';
import { AppTracker } from 'shared/analytics/tracker';

import {
  GRAPH_MAX_ZOOM,
  GRAPH_MIN_ZOOM,
  NODE_HEIGHT,
  NODE_WIDTH,
} from '../../contants/service.contant';

const styles = (disabled: boolean) => ({
  border: `1px solid ${disabled ? THEME_COLORS.secondary[200] : THEME_COLORS.primary[500]}`,
  backgroundColor: disabled ? THEME_COLORS.brand.white : THEME_COLORS.primary[100],
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: disabled ? 'not-allowed' : 'pointer',
});

type Props = {
  id: string | null | undefined;
};

export const ReactFlowControls: FC<Props> = ({ id }) => {
  const flow = useReactFlow();
  const { zoom } = useViewport();
  const store = useStoreApi();
  const { setCenter } = useReactFlow();

  const focusNode = (nodeId: string) => {
    const { nodeInternals } = store.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);

    if (nodes.length > 0) {
      const node = nodes.find(n => n.id === nodeId);

      if (node) {
        const x = node.position.x + NODE_WIDTH / 2;
        const y = node.position.y + NODE_HEIGHT / 2;

        setCenter(x, y, { zoom: 1, duration: 500 });
      }
    }
  };

  const track = (eventName: string) => {
    AppTracker.track(eventName);
  };

  useEffect(() => {
    if (id) {
      focusNode(id);
    }
  }, [id]);

  return (
    <Controls
      style={{
        display: 'flex',
        gap: '8px',
        border: 'none',
        boxShadow: 'none',
      }}
      showZoom={false}
      showFitView={false}
      showInteractive={false}
    >
      <ControlButton
        onClick={() => {
          track(T_WA_UP_SG_VIEW_ZOOMED_IN);
          flow.zoomIn();
        }}
        style={{ ...styles(zoom >= GRAPH_MAX_ZOOM) }}
        disabled={zoom >= GRAPH_MAX_ZOOM}
      >
        <IconButton
          variant="ghost"
          aria-label="services"
          icon={
            <LibraryIcons.AddIcon
              color={
                zoom >= GRAPH_MAX_ZOOM ? THEME_COLORS.secondary[1000] : THEME_COLORS.brand.blue
              }
            />
          }
        />
      </ControlButton>
      <ControlButton
        onClick={() => {
          track(T_WA_UP_SG_VIEW_ZOOMED_OUT);
          flow.zoomOut();
        }}
        style={{ ...styles(zoom <= GRAPH_MIN_ZOOM) }}
        disabled={zoom <= GRAPH_MIN_ZOOM}
      >
        <IconButton
          variant="ghost"
          aria-label="services"
          icon={
            <LibraryIcons.MinusIcon
              color={
                zoom <= GRAPH_MIN_ZOOM ? THEME_COLORS.secondary[1000] : THEME_COLORS.brand.blue
              }
            />
          }
        />
      </ControlButton>
      <ControlButton
        onClick={() => {
          track(T_WA_UP_SG_VIEW_RESET);
          flow.fitView();
        }}
        style={{ ...styles(false) }}
      >
        <IconButton
          variant="ghost"
          aria-label="services"
          icon={<FitControl color={THEME_COLORS.brand.blue} />}
        />
      </ControlButton>
    </Controls>
  );
};
