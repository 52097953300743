import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  ContainerLoad,
  DropDown,
  ErrorToastBlock,
  Grid,
  Label,
  Para,
  SpinLoader,
  TextButton,
  Theme,
  ToastContext,
  Tooltip,
} from 'uie/components';
import {
  IAnalyticsPanel,
  IAnalyticsPanelData,
  IAnalyticsQuery,
  ISelectedLine,
  ITimeSeriesDataResponse,
} from '../../../../../core/interfaces/IAnalytics';
import { AlertSourcesService, AnalyticsService } from '../../../../../core/services';
import { Datum, Line, PointTooltipProps, Serie } from '@nivo/line';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { ResponsiveWrapper } from '@nivo/core';
import { useAnalyticsContext } from '../analyticsContext';
import { exception } from '../../../../../core/exception';
import { isCacheValid, getStatusColors } from './helpers';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CloseIcon } from '../../../../../icons';
import { IIntegration } from '../../../../../core/interfaces/IIntegration';
import { convertDateString, last3Months } from '../../../../../core/const/analytics';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import {
  T_WA_GS_ANALYTICS_PAGE_STATUS_CLICKED,
  T_WA_GS_ANALYTICS_PAGE_SERVICE_CLICKED,
  T_WA_GS_ANALYTICS_PAGE_ALERTSOURCE_CLICKED,
} from '../../../../../core/const/tracker';
import { API } from 'core';

const { theme } = Theme;

const SELECTED_LINE_LIMIT = 10;

const useActiveAlertSources = (integrations: IIntegration[], teamId: string) => {
  const _alertSourcesService = new AlertSourcesService();
  const [activeAlertSources, setActiveSources] = useState(integrations);

  const toDate = new Date();
  const fromDate = last3Months;

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { data: activeSourceIDs },
        } = await _alertSourcesService.getActive(
          { fromDate: fromDate.toJSON(), toDate: toDate.toJSON() },
          teamId,
        );
        const newActiveSources = activeAlertSources.filter(i => activeSourceIDs.includes(i._id));
        setActiveSources(newActiveSources);
      } catch (err: any) {
        exception.handle('E_ANALYTICS_GET_ACTIVE_ALERT_SOURCES', err);
      }
    })();
    // eslint-disable-next-line
  }, [teamId]);

  return activeAlertSources;
};

interface IProps extends Pick<IAppState, 'organization' | 'integrations'> {
  panel: IAnalyticsPanel;
  data: IAnalyticsPanelData;
  updatePanelData: (
    panelID: string,
    data: any[],
    owner?: string,
    dateString?: string,
    timeRange?: { gt: string; lt: string },
  ) => void;
}

const getData = async (query: IAnalyticsQuery) => {
  const analyticsService = new AnalyticsService();

  try {
    const {
      data: { data },
    } = await analyticsService.executeQuery(query);
    return (data as ITimeSeriesDataResponse[]).map(d => ({ x: d.date, y: d.value })) as Datum[];
  } catch (err: any) {
    exception.handle('E_GET_ANALYTICS_DATA', err);
    return [];
  }
};

interface IMultipleLinesQuery {
  id: string;
  query: IAnalyticsQuery;
}

const getMultipleLines = (lines: IMultipleLinesQuery[]) => {
  return from(lines).pipe(
    mergeMap(async l => {
      const data = await getData(l.query);
      return {
        data,
        id: l.id,
      };
    }),
  );
};

const customLineTooltip = ({ point }: PointTooltipProps) => {
  return (
    <Grid
      type="column"
      style={{
        background: 'white',
        padding: '4px 12px',
        boxShadow: `3px 3px 5px 0px ${theme.shades.lightGrey}`,
        borderRadius: '4px',
      }}
    >
      <Grid>
        <Grid
          style={{ width: '14px', height: '14px', background: point.color, marginTop: '2px' }}
          className="mr-5"
        />
        <Para fontWeight={500}>{point.serieId}</Para>
      </Grid>
      <Grid>
        <Para className="mr-5">
          <span className="font-bold">x:</span> {point.data.xFormatted}
        </Para>
        <Para>
          <span className="font-bold">y:</span> {point.data.yFormatted}
        </Para>
      </Grid>
    </Grid>
  );
};

const PrimeLine: React.FC<IProps> = ({ panel, data, updatePanelData, ...props }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [newLineLoading, setNewLineLoading] = useState(false);

  const _createToast = ToastContext();

  const {
    config: { selectedLines, timeRange },
    updateConfig,
  } = useAnalyticsContext();

  const gtString = convertDateString(timeRange.gt);
  const ltString = convertDateString(timeRange.lt);

  useEffect(() => {
    if (
      panel.query.filter_by?.owner &&
      panel.query.filter_by?.owner === data.owner &&
      data.timeRange?.gt === gtString &&
      data.timeRange?.lt === ltString &&
      isCacheValid(data.updatedAt) &&
      data.data.length === selectedLines.length + 1
    ) {
      return;
    }

    setLoading(true);

    const selectedLinesQueries: IMultipleLinesQuery[] = selectedLines.map(line => ({
      id: line.name,
      query: {
        ...panel.query,
        filter_by: {
          ...panel.query.filter_by,
          [line.type]: line.id,
        },
        time_range: {
          gt: gtString,
          lt: ltString,
        },
      },
    }));

    const queries = [
      {
        id: 'incident_count',
        query: {
          ...panel.query,
          time_range: {
            gt: gtString,
            lt: ltString,
          },
        },
      },
      ...selectedLinesQueries,
    ];
    const newLines: Serie[] = [];

    getMultipleLines(queries).subscribe({
      next: lineData => {
        newLines.push(lineData);
      },
      error: err => {
        exception.handle('E_GET_ANALYTICS_DATA', err);
      },
      complete: () => {
        updatePanelData(panel.id, newLines, panel.query.filter_by?.owner, '', {
          gt: gtString,
          lt: ltString,
        });
        setLoading(false);
      },
    });
    // eslint-disable-next-line
  }, [timeRange, panel.query.filter_by?.owner]);

  const addLine = (line: Serie) => {
    updatePanelData(panel.id, [...data.data, line], panel.query.filter_by?.owner, '', {
      gt: gtString,
      lt: ltString,
    });
  };

  const removeLine = (line: ISelectedLine) => {
    const removedData = data.data.filter(d => d.id !== line.name);
    updatePanelData(panel.id, [...removedData], panel.query.filter_by?.owner, '', {
      gt: gtString,
      lt: ltString,
    });
  };

  const onClearLines = () => {
    if (newLineLoading) return;

    const removedData = data.data.filter(d => d.id === 'incident_count');
    updatePanelData(panel.id, [...removedData], panel.query.filter_by?.owner, '', {
      gt: gtString,
      lt: ltString,
    });
    updateConfig({
      selectedLines: [],
    });
  };

  const handleSelect = (line: ISelectedLine) => async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (newLineLoading) return;

    const newSelected = [...selectedLines];

    if (e.target.checked) {
      if (newSelected.length >= SELECTED_LINE_LIMIT - 1) {
        _createToast(
          <ErrorToastBlock style={{ backgroundColor: theme.danger.light }} maxWidth="400px">
            <Para fontWeight={400}>
              You can add only {SELECTED_LINE_LIMIT} filters at once. Please deselect some filters
              to continue.
            </Para>
          </ErrorToastBlock>,
        );
        return;
      }
      if (line.type === 'status') {
        AppTracker.track(T_WA_GS_ANALYTICS_PAGE_STATUS_CLICKED, { Status: line.name });
      } else if (line.type === 'service') {
        AppTracker.track(T_WA_GS_ANALYTICS_PAGE_SERVICE_CLICKED, { Service: line.name });
      } else {
        AppTracker.track(T_WA_GS_ANALYTICS_PAGE_ALERTSOURCE_CLICKED, { 'Alert Source': line.name });
      }
      newSelected.push(line);
    } else {
      const selectedIndex = selectedLines.findIndex((l: ISelectedLine) => {
        return l.id === line.id;
      });
      newSelected.splice(selectedIndex, 1);
      removeLine(line);
      updateConfig({ selectedLines: newSelected });
      return;
    }

    updateConfig({ selectedLines: newSelected });
    setNewLineLoading(true);

    const query = {
      ...panel.query,
      filter_by: {
        ...panel.query.filter_by,
        [line.type]: line.id,
      },
      time_range: {
        gt: gtString,
        lt: ltString,
      },
    };

    try {
      const lineData = await getData(query);
      addLine({
        id: line.name,
        data: lineData,
      });
    } catch (err: any) {
      exception.handle('E_GET_ANALYTICS_DATA', err);
    } finally {
      setNewLineLoading(false);
    }
  };

  const statusOptions = ['resolved', 'suppressed', 'acknowledged', 'triggered'].map(s => ({
    id: s,
    name: s,
  }));

  const serviceOptions = props.organization.services.s.map(s => ({
    id: s.id,
    name: s.name,
  }));

  const activeAlertSources = useActiveAlertSources(props.integrations.i, API.config.teamId);

  const alertSourceOptions = activeAlertSources.map(i => ({
    id: i._id,
    name: i.type,
  }));

  return (
    <div className="analytics__prime_line-content">
      <Grid justifyContent="space-between" alignItems="baseline" flexWidth={12}>
        <Para fontSize={20}>{panel.name}</Para>

        <Grid
          width="550px"
          alignItems="center"
          justifyContent="flex-end"
          style={{ marginRight: 24 }}
        >
          <Grid type="column" style={{ margin: '4px 20px' }}>
            <Para>Create Line By: </Para>
            <ContainerLoad isLoading={newLineLoading} />
          </Grid>

          <DropDownSelect
            name="Status"
            type="status"
            selected={selectedLines}
            options={statusOptions}
            loading={newLineLoading}
            handleSelect={handleSelect}
          />

          <DropDownSelect
            name="Service"
            type="service"
            selected={selectedLines}
            options={serviceOptions}
            loading={newLineLoading}
            handleSelect={handleSelect}
          />

          <DropDownSelect
            name="AlertSource"
            type="integrationType"
            selected={selectedLines}
            options={alertSourceOptions}
            loading={newLineLoading}
            handleSelect={handleSelect}
          />

          {selectedLines.length !== 0 && (
            <Grid className="ml-10">
              <Tooltip
                label={`Clear ${selectedLines.length} Selected Lines`}
                offset={{ left: '-100px', top: '5px' }}
              >
                <TextButton
                  buttonType="ghost"
                  style={{ height: '28px' }}
                  onClick={onClearLines}
                  disabled={newLineLoading}
                >
                  <CloseIcon width={14} height={14} />
                </TextButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>

      {loading ? (
        <Grid style={{ height: '100%' }}>
          <SpinLoader style={{ margin: 'auto' }} />
        </Grid>
      ) : (
        <ResponsiveWrapper>
          {({ width }) => (
            <Line
              data={data.data}
              width={width}
              height={480}
              margin={{ top: 20, right: 200, bottom: 70, left: 40 }}
              useMesh={true}
              enablePoints={false}
              curve="catmullRom"
              xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
              }}
              enableArea={true}
              colors={getStatusColors()}
              xFormat="time:%b %d"
              axisBottom={{
                tickValues: 'every week',
                format: '%b %d',
              }}
              tooltip={customLineTooltip}
              legends={[
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 90,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          )}
        </ResponsiveWrapper>
      )}
    </div>
  );
};

export default connect(({ organization, integrations }: IAppState) => ({
  organization,
  integrations,
}))(PrimeLine);

interface IDropDownProps {
  name: string;
  options: { id: string; name: string }[];
  selected: ISelectedLine[];
  type: 'service' | 'status' | 'integrationType';
  loading: boolean;
  handleSelect: (line: ISelectedLine) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DropDownSelect: React.FC<IDropDownProps> = ({
  name,
  options,
  type,
  selected,
  handleSelect,
  loading,
}) => (
  <DropDown
    hook={
      <div>
        <Para
          style={{
            borderRadius: '2px',
            boxShadow: `0 0 0 1px ${theme.shades.lightGrey}`,
            padding: '4px 12px',
            marginLeft: '16px',
            cursor: 'pointer',
          }}
        >
          {name}
        </Para>
      </div>
    }
    maxHeight="300px"
    width="auto"
    maxWidth="200px"
  >
    <Grid type="column" style={{ padding: '8px' }}>
      {options.map(o => {
        const sel = selected.some(s => s.id === o.id);
        return (
          <Label
            fontWeight={500}
            color={sel ? theme.primary.default : theme.shades.grey}
            fontSize={14}
            key={o.id}
            style={{ height: 'auto' }}
            className="mb-10 cursor-pointer"
          >
            <Grid alignItems="flex-start">
              <Checkbox
                checked={sel}
                onChange={handleSelect({ type, name: o.name, id: o.id })}
                disabled={!sel && loading}
              />
              <span className="ml-10 capatilize" style={{ textAlign: 'left' }}>
                {o.name}
              </span>
            </Grid>
          </Label>
        );
      })}
    </Grid>
  </DropDown>
);
