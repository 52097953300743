import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import {
  Box,
  Button,
  Flex,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
} from '@chakra-ui/react';
import PatternName from './patternName';
import PatternDuration from './patternDuration';
import PatternParticipants from './patternParticipants';
import PatternEnd from './patternEnd';
import PatternColor from './patternColor';
import { ICustomPeriod, INewSchedule, InputMaybe } from '../../interface/schedule';
import CustomRepeat from './rotations.repeat.custom';
import OverlayModal from 'components/chakra/Modal';
import { rotationsCustomizePattern } from '../../constants/rotations.customize';
import { useScheduleActionsContext } from '../../schedules.actions/context';
import { rotationTemplates, customRotationSlug } from '../../constants/schedules.rotation-template';
import { ScheduleActionTypes, schedulesTextCopy } from '../../constants/schedules.copy';
import { CustomPeriodUnit } from 'views/main/organization/schedules/graphql/types';
import SQTooltip from 'components/chakra/Tooltip';

interface Props {
  rotationId: string;
  onRemoveRotation: () => void;
  rotationBackgroundColor: string;
}

const RotationPattern = ({ rotationId, onRemoveRotation, rotationBackgroundColor }: Props) => {
  const {
    values: { rotations },
    errors,
    touched,
    handleChange,
  } = useFormikContext<INewSchedule>();
  const {
    scheduleAction,
    onClickRemoveRotation,
    deleteRotationType,
    confirmDeleteRotation,
    resetDeleteRotationValues,
    rotationToEndOrRemove,
  } = useScheduleActionsContext();
  const currentRotation = rotations.find(rt => rt.id === rotationId);
  const currentRotationIndex = rotations.findIndex(rt => rt.id === rotationId);
  const rotationCustomPeriodSettings = rotations.find(rt => rt.id === rotationId)?.customPeriod;
  const daysOfWeekFilter = rotationCustomPeriodSettings?.daysOfWeekFilter;

  const functionForRemoveRotation = useMemo(() => {
    if (
      scheduleAction &&
      [ScheduleActionTypes.EDIT_ROTATIONS, ScheduleActionTypes.EDIT_SCHEDULE].includes(
        scheduleAction.type,
      )
    ) {
      const rotationTemplatesIDs = rotationTemplates.map(rt => rt.id);
      const isRotationNewlyAdded =
        rotationTemplatesIDs.includes(rotationId ?? '') || rotationId.includes(customRotationSlug);
      if (isRotationNewlyAdded) {
        return onRemoveRotation;
      } else {
        return () => onClickRemoveRotation(rotationId);
      }
    } else {
      return onRemoveRotation;
    }
  }, [rotationId, scheduleAction, onClickRemoveRotation, onRemoveRotation]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [previousCustomPeriod, setPreviousCustomPeriod] = useState<
    InputMaybe<ICustomPeriod> | undefined
  >();

  if (!daysOfWeekFilter || daysOfWeekFilter.length === 0) {
    handleChange({
      target: {
        name: `rotations.${currentRotationIndex}.customPeriod.daysOfWeekFilter`,
        value: [...rotationsCustomizePattern.weekDayFilterDefaultValues],
      },
    });
  }

  const initializeCustomPeriod = useCallback(
    (customPeriod: InputMaybe<ICustomPeriod> | undefined) => {
      setPreviousCustomPeriod(customPeriod);
    },
    [],
  );

  //This needs to be checked and corrected
  const modalHandler = (object: any) => {
    if (object.target) {
      //Modal handler triggered by custom repeat component (either by Done or cancel)
      if (object.target.value === 'done') {
        handleChange({
          target: {
            name: `rotations.${currentRotationIndex}.period`,
            value: 'custom',
          },
        });
      } else {
        handleChange({
          target: {
            name: `rotations.${currentRotationIndex}.customPeriod`,
            value: { ...previousCustomPeriod },
          },
        });
      }
      setIsModalOpen(false);
    } else {
      //Modal handler triggered by PatternDuration component (selected custom as the interval)
      if (object.value === 'custom') {
        setIsModalOpen(true);
      } else {
        handleChange({
          target: {
            name: `rotations.${currentRotationIndex}.period`,
            value: object.value,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (
      deleteRotationType &&
      confirmDeleteRotation &&
      currentRotation?.id === rotationToEndOrRemove
    ) {
      if (deleteRotationType === 'end') {
        handleChange({
          target: {
            name: `rotations.${currentRotationIndex}.ends`,
            value: true,
          },
        });
        handleChange({
          target: {
            name: `rotations.${currentRotationIndex}.endDate`,
            value: new Date(),
          },
        });
      } else {
        onRemoveRotation();
      }
      resetDeleteRotationValues();
    }
  }, [deleteRotationType, confirmDeleteRotation]);

  return (
    <>
      <Accordion allowToggle w="inherit" defaultIndex={0}>
        <AccordionItem border="none">
          <Flex w="100%" background={rotationBackgroundColor}>
            <AccordionButton
              color="default"
              p={2}
              pt={0}
              w="100%"
              backgroundColor="inherit"
              _hover={{ bgColor: rotationBackgroundColor }}
              _focus={{ boxShadow: 'none' }}
              justifyContent="space-between"
            >
              <Flex ml={5}>
                <SQTooltip text={currentRotation?.name?.toUpperCase() ?? ''}>
                  <Text
                    fontSize="md"
                    textAlign="start"
                    fontWeight="bold"
                    color="blue.900"
                    maxW={300}
                    isTruncated
                  >
                    {currentRotation?.name?.toUpperCase()}
                  </Text>
                </SQTooltip>
                <AccordionIcon ml={1.5} />
              </Flex>
            </AccordionButton>
            {scheduleAction &&
            ([ScheduleActionTypes.EDIT_PARTICIPANTS].includes(scheduleAction.type) ||
              ([ScheduleActionTypes.EDIT_ROTATIONS, ScheduleActionTypes.EDIT_SCHEDULE].includes(
                scheduleAction.type,
              ) &&
                rotations.length === 1)) ? (
              <></>
            ) : (
              <Button
                m={2}
                onClick={functionForRemoveRotation}
                alignSelf="flex-end"
                variant="invertedDefault"
              >
                {schedulesTextCopy.common.remove}
              </Button>
            )}
          </Flex>
          <AccordionPanel py={0} px={0}>
            <Box bg="white" p={6} ml={0.5}>
              <PatternParticipants rotationId={rotationId} />
              {scheduleAction &&
              [ScheduleActionTypes.EDIT_PARTICIPANTS].includes(scheduleAction.type) ? (
                <></>
              ) : (
                <>
                  <PatternDuration rotationId={rotationId} modalHandler={modalHandler} />
                  <PatternEnd rotationId={rotationId} />
                  <PatternName rotationId={rotationId} />
                  <PatternColor rotationId={rotationId} />
                </>
              )}
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      {isModalOpen && (
        <>
          <OverlayModal
            isOpen
            size={
              rotationCustomPeriodSettings &&
              rotationCustomPeriodSettings.periodUnit === CustomPeriodUnit.Week &&
              !rotationCustomPeriodSettings.repeatHoursAndTime
                ? 'lg'
                : 'md'
            }
            disableFooter
            enableOverlay={false}
            content={
              <CustomRepeat
                rotationId={rotationId}
                modalHandler={modalHandler}
                initializeCustomPeriod={initializeCustomPeriod}
              />
            }
            onClose={() => {
              handleChange({
                target: {
                  name: `rotations.${currentRotationIndex}.customPeriod`,
                  value: { ...previousCustomPeriod },
                },
              });
              setIsModalOpen(false);
            }}
            //modalPositionObject={{ position: 'fixed', right: '3%', margin: 'auto' }} Enabling this will bring modal to the right
            unsetTopAndBottomPadding
            unsetLeftAndRightPadding
            modalOverlayBackgroundColor="modalOverlay"
          />
        </>
      )}
    </>
  );
};

export default RotationPattern;
