import { Divider, Flex, Radio, Tag, TagLabel, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { Locale } from 'core/helpers/dateUtils';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { IAppState } from '../../../../../core/interfaces/IAppState';
import { tooltipStyle } from '../slo.constants';
import { getServicesList, getUserList } from '../slo.helper';
import { ISloVoilatingIncidentUnit } from '../slo.interface';

export type IOrganization = IAppState['organization'];
const tdStyle = {
  'text-align': 'left',
  'vertical-align': 'center',
  'max-width': '150px',
  padding: 2,
};

interface IProps {
  key: number;
  member: ISloVoilatingIncidentUnit;
  checkedInc: Array<number>;
  checkInc: (incIds: Array<number>) => void;
  organization: IOrganization;
  type: 'violating' | 'falsePositive';
  isDisabled: boolean;
}

export const TableRows = ({
  member,
  organization,
  checkedInc,
  checkInc,
  type,
  isDisabled,
}: IProps) => {
  return (
    <Tr>
      <Td maxW="100px" p={0}>
        <Radio
          isChecked={checkedInc.includes(member.id)}
          disabled={isDisabled}
          onChange={e => {
            checkedInc.includes(member.id)
              ? checkInc([...checkedInc.filter(_ => _ !== member.id)])
              : checkInc([member.id]);
          }}
        ></Radio>
      </Td>
      <Td maxW="170px" p={2}>
        <Link to={`/incident/${member.incident_id}`}>
          <Text fontSize={14} fontFamily="Mulish" color="blue.500" isTruncated cursor={'pointer'}>
            {`...${member.incident_id.substring(20)}`}
          </Text>
        </Link>
      </Td>
      <Td sx={tdStyle}>
        <Text fontSize={14} color="muted">
          {member.message}
        </Text>
      </Td>
      {type === 'violating' && (
        <Td sx={tdStyle}>
          <Tooltip
            hasArrow
            label={member.slis.map(_ => (
              <>
                <Text fontSize="11px" fontWeight="normal" key={_}>
                  {_}
                  {','}
                </Text>
                <Divider orientation="vertical" />
              </>
            ))}
            {...tooltipStyle}
          >
            <Flex>
              {member.slis.map((value, index) => {
                if (index <= 2) {
                  return (
                    <Tag key={index} size="sm" borderRadius="full">
                      <TagLabel fontWeight="normal">{value}</TagLabel>
                    </Tag>
                  );
                }
              })}
              {member.slis.length > 3 && (
                <Text m={1} fontWeight="semibold" fontSize="11px">
                  {'+'}
                  {member.slis.length - 3}
                </Text>
              )}
            </Flex>
          </Tooltip>
        </Td>
      )}
      {type === 'violating' && (
        <Td sx={tdStyle}>
          <Text fontSize={14} color="muted">
            {member.error_budget_spent.toFixed(2)}
          </Text>
        </Td>
      )}
      <Td sx={tdStyle}>
        <Text fontSize={14} color="muted">
          {getServicesList([member.service_id], organization.services)[0]}
        </Text>
      </Td>
      <Td sx={tdStyle}>
        <Text fontSize={14} color="muted">
          {getUserList([member.assigned_to.id], organization.users)[0]}
        </Text>
      </Td>
      <Td sx={tdStyle}>
        <Text fontSize={14} color="muted">
          {_.capitalize(member.status)}
        </Text>
      </Td>
      <Td maxW="100px" p={2}>
        <Text fontSize={14} color="muted">
          {member.start_time ? Locale.toSimpleTimeDate(new Date(member.start_time)) : '--'}
        </Text>
      </Td>
      <Td maxW="100px" p={2}>
        <Text fontSize={14} color="muted">
          {member.end_time ? Locale.toSimpleTimeDate(new Date(member.end_time)) : '--'}
        </Text>
      </Td>
    </Tr>
  );
};
