import * as React from 'react';
import { SVGProps } from 'react';

const SvgOhDearLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#e32929',
      }}
      d="M11.27 8.232c1.682 0 3.04 1.325 3.04 2.949s-1.37 2.936-3.04 2.936c-1.462 0-2.695-1.002-2.983-2.35h-4.18v-1.185h4.18c.277-1.336 1.509-2.35 2.983-2.35zM0 10.582h2.645v1.186H0v-1.186zm16.119-4.7v2.569a2.64 2.64 0 0 1 1.416-.392c1.14 0 2.465.76 2.465 2.914v2.948h-1.22v-2.948c0-.68-.162-1.163-.496-1.44-.253-.218-.564-.264-.772-.264-.633 0-1.393.3-1.393 1.704v2.948h-1.221V5.883h1.22zm-4.849 3.56c-.99 0-1.796.782-1.796 1.738s.806 1.74 1.796 1.74 1.797-.784 1.797-1.74-.806-1.739-1.797-1.739z"
    />
  </svg>
);

export default SvgOhDearLogo20X20;
