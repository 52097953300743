import { Grid, Heading } from 'uie/components';
import CalendarRangePicker from 'components/CalendarRangePicker';
import SecondaryFilter from 'components/SecondaryFilter';
import { useSecondaryFilters } from 'components/SecondaryFilter/context';
import React from 'react';

interface IProps {
  onUpdateTimeChange: (from: Date, to: Date) => void;
  toDate: Date;
  fromDate: Date;
  minDate: Date;
}

function Filters(props: IProps): JSX.Element {
  const secondaryFilter = useSecondaryFilters();
  secondaryFilter;

  return (
    <>
      <Grid
        alignItems="center"
        flexWrap="wrap"
        style={{
          paddingTop: '10px',
          paddingBottom: '20px',
          width: '95%',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Grid>
          <Heading fontSize={27} color="#1D426B">
            All Postmortems
          </Heading>
          <div style={{ marginLeft: '20px', marginTop: '10px' }}>
            <SecondaryFilter isMulti />
          </div>
        </Grid>
        <CalendarRangePicker
          startDate={props.fromDate}
          endDate={props.toDate}
          onUpdateRange={props.onUpdateTimeChange}
          minDate={props.minDate}
          maxDate={new Date()}
        />
      </Grid>
      <Grid>
        <Heading
          fontSize={14}
          height={28}
          style={{
            fontWeight: 300,
            paddingBottom: '10px',
            marginBottom: '10px',
            fontSize: '12px',
            color: '#808080',
          }}
        >
          Postmortems help teams document incident timelines, failures and fixes to create a
          knowledge base of learnings that can be shared across the organization. Learn more about
          it{' '}
          <a
            href="https://support.squadcast.com/postmortems/create-postmortems"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </Heading>
      </Grid>
    </>
  );
}

export default Filters;
