import { useSecondaryFilters } from 'components/SecondaryFilter/context';
import { DateTime } from 'luxon';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { CompareFilters, Filters } from '../types';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_UP_ANALYTICS_DATE_FILTER_APPLIED } from '../../../../../core/const/tracker';

const FiltersContext = createContext<ContextProps | undefined>(undefined);

type ContextProps = {
  filters: Filters;
  compareFilters: CompareFilters[];
  updateDateFilter: (start: Date, end: Date) => void;
  updateCompareFilter: (data: CompareFilters[]) => void;
};

type Props = {
  filters: Filters;
};

// eslint-disable-next-line react/prop-types
export const FiltersProvider: React.FC<Props> = ({ filters: initialFilters, children }) => {
  const [filters, setFilters] = useState(initialFilters);
  const { filters: secondaryFilters } = useSecondaryFilters();
  const [compareFilters, setCompareFilters] = useState<CompareFilters[]>([]);

  const updateCompareFilter = (data: CompareFilters[]) => {
    setCompareFilters(data);
  };

  const updateDateFilter = (startDate: Date, endDate: Date) => {
    setFilters({
      ...filters,
      date: {
        preset: 'custom',
        startDate: DateTime.fromJSDate(startDate),
        endDate: DateTime.fromJSDate(endDate),
        //Retain the data retention limit during change of filters
        dataRetentionLimit: filters.date.dataRetentionLimit,
      },
    });
    AppTracker.track(T_WA_UP_ANALYTICS_DATE_FILTER_APPLIED);
  };

  useEffect(() => {
    if (
      initialFilters.date.dataRetentionLimit &&
      initialFilters.date.dataRetentionLimit !== filters.date.dataRetentionLimit
    ) {
      // Update the input filters once data retention limit date is fetched
      setFilters({
        ...filters,
        date: { ...filters.date, dataRetentionLimit: initialFilters.date.dataRetentionLimit },
      });
    } else {
      setFilters(prev => ({
        ...prev,
        teamIds: secondaryFilters.teams || [],
        serviceIds: secondaryFilters.services || [],
        tags: secondaryFilters.tags || [],
        userIds: secondaryFilters.users || [],
        priority: secondaryFilters.priority || [],
        serviceOwnerIds: secondaryFilters.serviceOwner || [],
      }));
      setCompareFilters([]);
    }
  }, [initialFilters, secondaryFilters, filters.date.dataRetentionLimit]);

  return (
    <FiltersContext.Provider
      value={{ filters, updateDateFilter, compareFilters, updateCompareFilter }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => {
  const filtersContext = useContext(FiltersContext);

  if (!filtersContext) throw new Error('No FiltersProvider found when calling useFilters.');

  return filtersContext;
};
