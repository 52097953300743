import {
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react';
import DOMPurify from 'core/dompurify';
import { Dropdown, Input, Textarea } from 'library/atoms';
import { THEME_COLORS } from 'library/theme/colors';
import { AnyObject, DROPDOWN_VARIANT } from 'library/types';
import { memo } from 'react';
import { fileUploadViewConfig } from 'views/config/fileUploadConfig';

import { MdBlock } from './MdBlock';

const { imageTexts, ...fileUploadConfig } = fileUploadViewConfig;
export interface DropdownOptions {
  value: string;
  options?: AnyObject[];
  label: string;
  meta?: AnyObject;
}

export const FormSelect = memo(
  ({
    value,
    onChange,
    onBlur,
    error,
    label,
    isMulti = false,
    fieldName,
    hideLabel,
    options,
    isDisabled,
    isReadOnly,
    maxLimit,
    controlShouldRenderValue = false,
    defaultValue,
    isClearable = true,
    styles,
    variant,
    placeholder = '',
    useCustomValue,
    customValue,
  }: {
    value: DropdownOptions | DropdownOptions[] | null;
    onChange: (field: string, value: DropdownOptions | DropdownOptions[] | null) => void;
    onBlur?: (field: string, value: boolean) => void;
    error: any;
    label: string;
    hideLabel?: boolean;
    isMulti?: boolean;
    fieldName: string;
    options: Array<DropdownOptions>;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    maxLimit?: number;
    controlShouldRenderValue?: boolean;
    defaultValue?: DropdownOptions | DropdownOptions[] | null;
    isClearable?: boolean;
    styles?: AnyObject;
    variant?: DROPDOWN_VARIANT;
    placeholder?: string;
    useCustomValue?: boolean;
    customValue?: 'single' | 'multi';
  }) => {
    const handleChange = (value: any) => {
      onChange(fieldName, value);
    };

    const handleBlur = (value: any) => {
      onBlur?.(fieldName, true);
    };
    return (
      <FormControl isInvalid={error}>
        {!hideLabel && (
          <HStack alignItems="center" mb="8px">
            <FormLabel my={0} mx="2px" fontSize="12px" color="secondary.700" fontWeight="800">
              {label}
            </FormLabel>
            {maxLimit && <Text fontSize="10px">(you can select upto {maxLimit} values)</Text>}
          </HStack>
        )}
        <Dropdown
          name={fieldName}
          size="md"
          value={value}
          options={options}
          isMulti={isMulti}
          onChange={handleChange}
          onBlur={handleBlur}
          controlShouldRenderValue={controlShouldRenderValue}
          isClearable={isClearable}
          backspaceRemovesValue={isClearable}
          placeholder={placeholder || `Type or select the ${label}`}
          isDisabled={isDisabled}
          defaultValue={defaultValue}
          styles={styles}
          variant={variant}
          isReadOnly={isReadOnly}
          useCustomValue={useCustomValue}
          customValue={customValue}
        />
        {error && (
          <Text mt="5" color={THEME_COLORS.brand.red} variant="body">
            {error}
          </Text>
        )}
      </FormControl>
    );
  },
);

export const FormRadio = memo(
  ({
    value,
    onChange,
    label,
    fieldName,
    fields,
  }: {
    value: any;
    onChange: (filed: string, value: any) => void;
    label?: string;
    fieldName: string;
    fields: Array<{ val: string; label: string }>;
  }) => {
    const handleChange = (value: any) => {
      onChange(fieldName, value);
    };

    return (
      <FormControl as="fieldset" mb="8px">
        {label && (
          <FormLabel as="legend" fontSize="12px" color="secondary.700" fontWeight="800">
            {label}
          </FormLabel>
        )}
        <RadioGroup onChange={handleChange} value={value}>
          <HStack spacing="24px">
            {fields.map(({ val, label }: { val: string; label: string }) => {
              return (
                <Radio key={val} value={val} isChecked={value === val}>
                  {label}
                </Radio>
              );
            })}
          </HStack>
        </RadioGroup>
      </FormControl>
    );
  },
);

export const FormCheckBox = memo(
  ({
    onChange,
    label,
    fieldName,
  }: {
    onChange: (filed: string, value: any) => void;
    label?: string;
    fieldName: string;
  }) => {
    const handleChange = (value: any) => {
      onChange(fieldName, value);
    };

    return (
      <FormControl as="fieldset" mb="8px">
        {label && (
          <FormLabel as="legend" fontSize="12px" color="secondary.700" fontWeight="800">
            {label}
          </FormLabel>
        )}
        <Checkbox onChange={e => handleChange(e.target.checked)} />
      </FormControl>
    );
  },
);

export const FormInput = memo(
  ({
    value,
    onChange,
    error,
    label,
    fieldName,
    isReadOnly,
    autoFocus,
    placeholder = '',
  }: {
    value: any;
    onChange: (filed: string, value: any) => void;
    error?: any;
    label?: string;
    fieldName: string;
    isReadOnly?: boolean;
    autoFocus?: boolean;
    placeholder?: string;
  }) => {
    const handleChange = (value: React.ChangeEvent<HTMLInputElement>) => {
      onChange(fieldName, value.target.value);
    };
    return (
      <FormControl as="fieldset" mb="8px" isInvalid={error}>
        {label && (
          <FormLabel as="legend" fontSize="12px" color="secondary.700" fontWeight="800">
            {label}
          </FormLabel>
        )}
        <Input
          onChange={handleChange}
          value={value}
          placeholder={placeholder}
          isReadOnly={isReadOnly}
          autoFocus={autoFocus}
        />
        {error && (
          <Text mt="5" color={THEME_COLORS.brand.red} variant="body">
            {error}
          </Text>
        )}
      </FormControl>
    );
  },
);

export const FormMDE = memo(
  ({
    value,
    onChange,
    error,
    label,
    fieldName,
    fileUploadHandler = () => {},
    placeholder = '',
  }: {
    value: any;
    onChange: (filed: string, value: any) => void;
    error?: any;
    label?: string;
    fieldName: string;
    fileUploadHandler?: any;
    placeholder?: string;
  }) => {
    const handleChange = (value: any) => {
      onChange(fieldName, value);
    };
    return (
      <FormControl as="fieldset" mb="8px" isInvalid={error}>
        {label && (
          <FormLabel as="legend" fontSize="12px" color="secondary.700" fontWeight="800">
            {label}
          </FormLabel>
        )}
        <MdBlock
          id="mde"
          onChange={handleChange}
          value={value}
          options={{
            spellChecker: true,
            maxHeight: '144px',
            placeholder,
            imageUploadFunction: fileUploadHandler,
            imageTexts: {
              ...imageTexts,
              sbInit: '',
            },
            ...fileUploadConfig,
            renderImageUploadsAsLinks: false,
            renderingConfig: {
              sanitizerFunction: description => {
                return DOMPurify.sanitize(description);
              },
            },
          }}
        />
        {error && (
          <Text mt="5" color={THEME_COLORS.brand.red} variant="body">
            {error}
          </Text>
        )}
      </FormControl>
    );
  },
);

export const FormTextArea = memo(
  ({
    value,
    onChange,
    error,
    label,
    fieldName,
    height,
    isReadOnly,
    placeholder = '',
  }: {
    value: any;
    onChange: (filed: string, value: any) => void;
    error?: any;
    label?: string;
    fieldName: string;
    placeholder?: string;
    isReadOnly?: boolean;
    height?: string;
  }) => {
    const handleChange = (value: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(fieldName, value.target.value);
    };
    const props = height ? { height } : {};
    return (
      <FormControl as="fieldset" mb="8px !important" isInvalid={error}>
        {label && (
          <FormLabel as="legend" fontSize="12px" color="secondary.700" fontWeight="800">
            {label}
          </FormLabel>
        )}
        <Textarea
          {...props}
          onChange={handleChange}
          value={value}
          placeholder={placeholder}
          isReadOnly={isReadOnly}
        />
        {error && (
          <Text mt="5" color={THEME_COLORS.brand.red} variant="body">
            {error}
          </Text>
        )}
      </FormControl>
    );
  },
);
