import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#7F3F97" d="M0 0h90v90H0z" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M80.502 57.757c-2.84-19.25-19.382-33.02-38.045-31.665C23.795 27.446 9.247 43.47 9 62.945h10.334c.13-14.592 11.584-26.32 25.6-26.214a24.916 24.916 0 0 1 10.492 2.266c-11.316 1.82-19.623 12.029-19.488 23.948h10.308c-.133-7.171 5.285-13.128 12.17-13.38 5.723.12 10.628 4.29 11.882 10.104.136 1.104.206 2.217.21 3.331H81c0-1.745-.185-3.485-.55-5.188"
      />
    </g>
  </svg>
);

export default SvgComponent;
