import * as React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IUserInfo } from '../../../../../core/interfaces/IUserData';
import { ISquad } from '../../../../../core/interfaces/ISquad';
import './index.css';
import { IEscalationPolicy } from '../../../../../core/interfaces/IEscalationPolicy';
import { IService } from '../../../../../core/interfaces/IService';
import { IIntegration } from '../../../../../core/interfaces/IIntegration';
import { IIncident } from '../../../../../core/interfaces/IIncidents';
import moment from 'moment';

interface IProps extends Pick<IAppState, 'organization' | 'integrations'> {
  hide: (result: boolean) => void;
  buttonText: string;
  displayText: string;
  incidents: IIncident[];
}

class BulkActionModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.getConcernedItemsName = this.getConcernedItemsName.bind(this);
  }

  public getConcernedItemsName(type: string, id: string) {
    const organization = this.props.organization;
    try {
      if (type === 'user') {
        return (
          organization.users.u[
            window._.findIndex(organization.users.u, (user: IUserInfo) => {
              return user.id === id;
            })
          ].first_name +
          ' ' +
          organization.users.u[
            window._.findIndex(organization.users.u, (user: IUserInfo) => {
              return user.id === id;
            })
          ].last_name
        );
      }
      if (type === 'squad') {
        return organization.squads.s[
          window._.findIndex(organization.squads.s, (squad: ISquad) => {
            return squad.id === id;
          })
        ].name;
      }
      return organization.escalations.e[
        window._.findIndex(organization.escalations.e, (escalationPolicy: IEscalationPolicy) => {
          return escalationPolicy.id === id;
        })
      ].name;
    } catch (err: any) {
      return 'Entity Deleted';
    }
  }

  public render() {
    if (!this.props.incidents) return null;
    return (
      <div
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <div className="">
          <div className="float-left bulkaction-model-confirmation-heading">
            <h1 className="modal-container-heading">Confirmation</h1>
          </div>
        </div>
        <div style={{ marginTop: 30 }} className="incidents-container">
          {this.props.incidents.map((incident: IIncident, index: number) => {
            return (
              <div key={index} className="incident-box">
                <div className="">
                  <div className="float-left">
                    <span className={incident.status + '-box'}>{incident.status}</span>
                  </div>
                  <div className="float-right incident-time">
                    {moment(incident.timeOfCreation).fromNow()}
                  </div>
                </div>
                <div>
                  <p className="item-box-tagline" style={{ fontWeight: 600 }}>
                    {incident.id} : {incident.message}
                  </p>
                </div>
                <div className="details-elements-container">
                  <div className="details-elements-box">
                    <img
                      className="details-element-icon"
                      src="/icons/greys/services.svg"
                      alt="services"
                    />
                    <span className="item-box-tagline">
                      {
                        this.props.organization.services.s[
                          window._.findIndex(
                            this.props.organization.services.s,
                            (service: IService) => {
                              return service.id === incident.service_id;
                            },
                          )
                        ].name
                      }
                    </span>
                  </div>
                  <div className="details-elements-box">
                    <img
                      src="/icons/greys/integrations.svg"
                      className="details-element-icon"
                      alt="integrations"
                    />
                    <span className="item-box-tagline">
                      {
                        this.props.integrations.i[
                          window._.findIndex(
                            this.props.integrations.i,
                            (integrationType: IIntegration) => {
                              return integrationType._id === incident.alert_source_id;
                            },
                          )
                        ].type
                      }
                    </span>
                  </div>
                  <div className="details-elements-box">
                    <img
                      className="details-element-icon"
                      alt="squads"
                      src={
                        '/icons/greys/' +
                        (incident.assignedTo[incident.assignedTo.length - 1].type === 'user'
                          ? 'profile'
                          : incident.assignedTo[incident.assignedTo.length - 1].type === 'squad'
                          ? 'squad'
                          : 'escalation') +
                        '.svg'
                      }
                    />
                    <span className="item-box-tagline">
                      {this.getConcernedItemsName(
                        incident.assignedTo[incident.assignedTo.length - 1].type,
                        incident.assignedTo[incident.assignedTo.length - 1].id,
                      )}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <p className="item-box-tagline">{this.props.displayText}</p>
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
          <button className="main-button" onClick={this.props.hide.bind(null, true)}>
            {this.props.buttonText}
          </button>
          &nbsp;&nbsp;
          <button className="main-button-inverse" onClick={this.props.hide.bind(null, false)}>
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

export default connect((state: IAppState) => ({
  organization: state.organization,
  integrations: state.integrations,
}))(BulkActionModal);
