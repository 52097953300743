import { useContext, useEffect, useMemo, useState } from 'react';
import { INCIDENT_ACTION_ID, INCIDENT_STATUS, MERGE_ELIGIBILITY } from '../common/enums';
import { IncidentStatusType } from '../graphql/generated/types';
import { getUIIncidentStatus, invalIncidentsListData } from '../common/util';
import { bulkAckIncident, bulkUpdatePriority } from '../common/commands';
import { setCanAutoRefreshIL } from '../store/persistent-storage';
import { ActionKind, IncidentListContext } from '../store';
import { useQueryClient } from 'react-query';
import { useToast } from 'library/atoms';
import { AppTracker } from 'shared/analytics/tracker';
import {
  T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENT_ACKNOWLEDGED,
  T_WA_UP_INCIDENT_PRIORITY_UPDATED,
} from 'core/const/tracker';
import { PRIORITY } from 'library/common';
import { exception } from 'core';
import { INCIDENT_DATA, IncidentListProps } from '../common/types';
import { MERGE_INCIDENT_MAX_CHILD_INCIDENTS_COUNT } from '../common/constants';

const useBulkActions = ({ rows, status, selectedRowIds, setSelectedRowIds }: IncidentListProps) => {
  const [commonStatus, setCommonStatus] = useState(status);
  const [inActionBtnId, setInActionBtnId] = useState(INCIDENT_ACTION_ID.NONE);
  const [mergeTooltipText, setMergeTooltipText] = useState<string>('');
  const [mergeEligibility, setMergeEligibility] = useState<MERGE_ELIGIBILITY>(
    MERGE_ELIGIBILITY.NOT_SELECTED,
  );
  const { dispatch } = useContext(IncidentListContext);
  const queryClient = useQueryClient();
  const toast = useToast();
  const showBulkActionButtons = selectedRowIds.length > 0;

  const updateCommonStatus = (rowIds: string[]) => {
    // common status is same for status specific tab
    if ([INCIDENT_STATUS.TRIGGERED, INCIDENT_STATUS.ACKNOWLEDGED].includes(status)) {
      return;
    }

    let newStatus = INCIDENT_STATUS.ALL;
    if (rowIds.length) {
      const statusArray = rows
        .filter(row => rowIds.includes(row?.id ?? ''))
        .map(data => data?.status ?? IncidentStatusType.Triggered);
      if (statusArray.every(status => status === statusArray[0])) {
        newStatus = getUIIncidentStatus(statusArray[0]);
      } else if (
        statusArray.every(status =>
          [IncidentStatusType.Triggered, IncidentStatusType.Acknowledged].includes(status),
        )
      ) {
        // as in case of mixture of both then acknowledged state bulk actions need to be shown
        newStatus = INCIDENT_STATUS.ACKNOWLEDGED;
      }
    }

    if (commonStatus !== newStatus) {
      setCommonStatus(newStatus);
    }
  };

  const setCanAutoRefresh = (value: boolean) => {
    dispatch({ type: ActionKind.ALLOW_AUTO_REFRESH, payload: { canAutoRefresh: value } });
  };

  const resetPageAction = () => {
    if (
      [
        INCIDENT_ACTION_ID.BULK_REASSIGN,
        INCIDENT_ACTION_ID.INLINE_REASSIGN,
        INCIDENT_ACTION_ID.INLINE_UPDATE_TAGS,
      ].includes(inActionBtnId)
    ) {
      setCanAutoRefresh(true);
    }
    setInActionBtnId(INCIDENT_ACTION_ID.NONE);
  };

  const updateListingData = () => {
    queryClient.setQueryData('listIncidents', {
      listIncidents: {
        data: rows,
      },
    });
  };

  const invalListingData = () => {
    invalIncidentsListData(queryClient);
    resetPageAction();
  };

  const onSave = () => {
    const isBulkOperation = [
      INCIDENT_ACTION_ID.BULK_ACKNOWLEDGE,
      INCIDENT_ACTION_ID.BULK_REASSIGN,
      INCIDENT_ACTION_ID.BULK_RESOLVE,
    ].includes(inActionBtnId);

    if (isBulkOperation) {
      setSelectedRowIds([]);
    }

    setCanAutoRefreshIL(true);
    // inval list data
    invalListingData();
  };

  const onError = (error: any, field?: string) => {
    toast({
      status: 'error',
      text: `Error: Unable to update Incident ${field || 'status'}`,
    });
    resetPageAction();
    console.error(error);
  };

  const bulkAckStatus = () => {
    if (selectedRowIds?.length) {
      const length = selectedRowIds.length;
      setInActionBtnId(INCIDENT_ACTION_ID.BULK_ACKNOWLEDGE);

      bulkAckIncident(selectedRowIds)
        .then(() => {
          setSelectedRowIds([]);
          onSave();
          toast({
            status: 'success',
            text: `Success: ${length} incidents marked as Acknowledged`,
          });
          AppTracker.track(T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENT_ACKNOWLEDGED, {
            'Incident ID': selectedRowIds,
          });
        })
        .catch(error => onError(error));
    }
  };

  const onBulkUpdatePriority = (priority: PRIORITY) => {
    if (selectedRowIds?.length) {
      const length = selectedRowIds.length;
      setInActionBtnId(INCIDENT_ACTION_ID.BULK_UPDATE_PRIORITY);

      bulkUpdatePriority(selectedRowIds, priority)
        .then(() => {
          setSelectedRowIds([]);
          onSave();
          toast({
            status: 'success',
            text: `Success: ${length} incidents priority updated`,
          });

          AppTracker.track(T_WA_UP_INCIDENT_PRIORITY_UPDATED, {
            'Incident Priority Chosen': priority,
            'Incident ID': selectedRowIds,
          });
        })
        .catch(error => {
          exception.handle('E_BULK_UPDATE_PRIORITY_INCIDENT', error);
          onError(error, 'priority');
        });
    }
  };

  const checkAndSetMergeEligibility = (arr: Array<INCIDENT_DATA>) => {
    if (arr.length > 1) {
      //Check if the list has more than 1 parent incident
      const parentIncidents = arr.filter(inc => inc.children.length > 0);
      if (parentIncidents.length > 1) {
        setMergeTooltipText('2 or more parent incidents cannot be merged together');
        setMergeEligibility(MERGE_ELIGIBILITY.PARENT_INCIDENTS);
        return;
      }

      //Check if count of child incidents exceeds the maximum count
      if (
        arr.length - 1 + (parentIncidents.length === 1 ? parentIncidents[0].children.length : 0) >
        MERGE_INCIDENT_MAX_CHILD_INCIDENTS_COUNT
      ) {
        setMergeTooltipText(
          `You cannot merge more than ${MERGE_INCIDENT_MAX_CHILD_INCIDENTS_COUNT} child incidents into a parent`,
        );
        setMergeEligibility(MERGE_ELIGIBILITY.MAX_COUNT);
        return;
      }

      //Eligible for merge if none of the above conditions are met
      setMergeTooltipText('');
      setMergeEligibility(MERGE_ELIGIBILITY.ENABLED);
    } else {
      setMergeTooltipText('Select 2 or more incidents to merge');
      setMergeEligibility(MERGE_ELIGIBILITY.NOT_SELECTED);
    }
  };

  const [incidentsForMerge, parentIncidentIDs] = useMemo(() => {
    // Consider all selected incidents with triggered or acknowledged status as available to merge
    const incidentsForMerge = rows.filter(
      row =>
        row &&
        selectedRowIds.includes(row.id) &&
        [IncidentStatusType.Acknowledged, IncidentStatusType.Triggered].includes(row.status),
    ) as unknown as INCIDENT_DATA[];
    const parentIncidentIDs = incidentsForMerge
      .filter(inc => inc.children.length > 0)
      .map(inc => inc.id);
    checkAndSetMergeEligibility(incidentsForMerge);
    return [incidentsForMerge, parentIncidentIDs];
  }, [rows, selectedRowIds]);

  useEffect(() => {
    updateCommonStatus(selectedRowIds);
  }, []);

  return {
    commonStatus,
    inActionBtnId,
    setInActionBtnId,
    bulkAckStatus,
    setCanAutoRefresh,
    mergeEligibility,
    mergeTooltipText,
    onBulkUpdatePriority,
    showBulkActionButtons,
    onSave,
    onError,
    updateCommonStatus,
    incidentsForMerge,
    parentIncidentIDs,
    invalListingData,
    updateListingData,
  };
};

export default useBulkActions;
