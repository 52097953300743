export enum RULE_MODE {
  ADD,
  VIEW,
  EDIT,
}

export enum CATCH_ALL_MODE {
  ADD,
  EDIT,
  DELETE,
}

export enum SHIFT_TO {
  TOP = -1,
  BOTTOM = 1,
}

export enum QUERY_PARAM_KEYS {
  ALERT_NAME = 'alertName',
  ALERT_VERSION = 'alertVersion',
  RULE = 'rule',
  OWNER = 'owner',
}

export enum GER_ERROR {
  API_FAILURE,
  NO_READ_PERMISSION,
  NO_DATA,
}
