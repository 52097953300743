import React, { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#F25733"
      d="M10.028 19.835C4.457 19.835 0 15.465 0 10 0 4.59 4.457.165 10.028.165h6.295C18.384.165 20 1.804 20 3.77V10c0 5.464-4.457 9.835-9.972 9.835z"
      opacity="0.9"
    ></path>
    <path
      fill="#F9F2F9"
      d="M9.95 5.742c-4.162 0-6.506 3.446-6.604 3.593a1.07 1.07 0 000 1.19c.098.146 2.442 3.592 6.603 3.592s6.505-3.446 6.603-3.593a1.07 1.07 0 000-1.19c-.098-.146-2.442-3.592-6.603-3.592zm-6.028 4.399a.38.38 0 010-.423C4.001 9.6 5.67 7.15 8.653 6.561A3.615 3.615 0 006.34 9.93c0 1.534.962 2.846 2.314 3.368-2.983-.588-4.652-3.04-4.73-3.157zm4.364-.211a.346.346 0 01-.692 0 2.358 2.358 0 012.355-2.356.346.346 0 110 .692c-.917 0-1.663.746-1.663 1.664zm1.663.84a.842.842 0 010-1.682.842.842 0 010 1.683zm6.027-.63c-.079.119-1.747 2.57-4.731 3.158a3.615 3.615 0 002.314-3.368 3.615 3.615 0 00-2.314-3.369c2.984.588 4.652 3.04 4.731 3.157a.38.38 0 010 .423z"
    ></path>
  </svg>
);
export default SvgComponent;
