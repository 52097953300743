import { EntityACLMeta } from 'core/userAccess/types';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, map, switchMap } from 'rxjs/operators';
import { onRequestOrganizationEscalationSuccess } from '../../actions/organization/escalation';
import { getOrgEscalations } from '../../api/organization/getEscalations';
import { REQUEST_ORG_ESCALATION, REQUEST_ORG_ESCALATION_FAIL } from '../../const/organization';
import { IEscalationPolicy } from '../../interfaces/IEscalationPolicy';

const getOrganizationsEscalationsEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ORG_ESCALATION),
    map(action => action.payload),
    switchMap(() => {
      return getOrgEscalations().pipe(
        map(({ response }: AjaxResponse) => response),
        map(
          ({
            result,
            data,
            meta: { acl },
          }: {
            result: boolean;
            data: IEscalationPolicy[];
            meta: { acl: Record<string, EntityACLMeta> };
          }) => {
            setUserAccessStore({
              entityACLMap: {
                ...getUserAccessStore().entityACLMap,
                escalation_policies: {
                  ...getUserAccessStore().entityACLMap?.escalation_policies,
                  ...acl,
                },
              },
            });
            return onRequestOrganizationEscalationSuccess(data);
          },
        ),
        catchError((error: AjaxError) => {
          if (error.status === 403) {
            return of(onRequestOrganizationEscalationSuccess([]));
          }
          return of({
            type: REQUEST_ORG_ESCALATION_FAIL,
            payload: error.xhr,
            error: true,
          });
        }),
      );
    }),
  );

export default getOrganizationsEscalationsEpic;
