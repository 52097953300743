import React from 'react';
import { Avatar, Flex, VStack, Box, Text } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';

const ParticipantOptionLabel = ({ label, type, username }: any) => {
  return (
    <Flex>
      <Avatar size="xs" name={label} />
      <VStack spacing={1} align="stretch">
        <Box>
          <Text ml="5px" size="sm" maxW="35ch" color={THEME_COLORS.secondary[1000]}>
            {label}
          </Text>
        </Box>
        {type === 'user' && (
          <Box>
            <Text ml="5px" size="sm" maxW="35ch" color={THEME_COLORS.secondary[700]}>
              {username}
            </Text>
          </Box>
        )}
      </VStack>
    </Flex>
  );
};

export default ParticipantOptionLabel;
