import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
} from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';

import Select from '../../components/Select';
import { FILTER_TYPES } from '../../constants/status.constants';
import { IfilterDetails, IfilterObject, ISelectOption } from '../../Interface';

interface Props {
  filters: IfilterObject;
  filterDetails: IfilterDetails;
  onFilterSubmit: (filters: IfilterObject) => void;
  onClose: () => void;
}

const FilterStatus: React.FC<Props> = (props: Props) => {
  const { filters, filterDetails, onFilterSubmit, onClose } = props;
  const [currentFilters, setCurrentFilters] = useState(filters);

  const mapFilterToTags = (array: string[], type: FILTER_TYPES) => {
    return (
      <HStack flexWrap="wrap" rowGap={2}>
        {array.map((option: string, index) => {
          const filterDetail = filterDetails[type].filterOptions.filter(
            op => op.value === option,
          )[0];
          return (
            <Tag key={index} size="lg">
              <TagLabel>{filterDetail?.label}</TagLabel>
              <TagCloseButton onClick={() => removeFilterValue(type, option)} />
            </Tag>
          );
        })}
      </HStack>
    );
  };

  const removeFilterValue = (type: FILTER_TYPES, option: string) => {
    const removedFilters = currentFilters[type].filter((o: string) => o !== option);
    setCurrentFilters({
      ...currentFilters,
      [type]: removedFilters,
    });
  };

  const handleCheckBox = (value: string, isChecked: boolean) => {
    const filter = { ...currentFilters };
    if (isChecked) {
      filter[FILTER_TYPES.PAGE_TYPE] = [...currentFilters[FILTER_TYPES.PAGE_TYPE], value];
    } else {
      filter[FILTER_TYPES.PAGE_TYPE] = currentFilters[FILTER_TYPES.PAGE_TYPE].filter(
        option => option !== value,
      );
    }
    setCurrentFilters(filter);
  };

  const addFilterValue = (value: string) => {
    if (!currentFilters[FILTER_TYPES.STATUS].includes(value))
      setCurrentFilters({
        ...currentFilters,
        [FILTER_TYPES.STATUS]: [...currentFilters[FILTER_TYPES.STATUS], value],
      });
  };
  return (
    <Box data-testid={'filter-container'}>
      <VStack px={0} py={3} spacing={8} alignItems="flex-start">
        <FormControl>
          <VStack alignItems="flex-start" w="100%">
            {/* {' '} */}
            <FormLabel mb={0}>{filterDetails[FILTER_TYPES.STATUS].label}</FormLabel>
            <Flex w="100%">
              <Select
                placeholder={'select'}
                value={[]}
                options={filterDetails[FILTER_TYPES.STATUS].filterOptions}
                name="status"
                onChange={(values: any) => {
                  addFilterValue(values[0].value);
                }}
                isMulti
                closeMenuOnSelect
                hideDropdownArrow
                hideSelectedValues
              />
            </Flex>
            {currentFilters[FILTER_TYPES.STATUS] &&
              mapFilterToTags(currentFilters[FILTER_TYPES.STATUS], FILTER_TYPES.STATUS)}
          </VStack>
        </FormControl>
        <FormControl>
          <FormLabel mb={0}>{filterDetails[FILTER_TYPES.PAGE_TYPE].label}</FormLabel>
          <HStack spacing={5}>
            {filterDetails[FILTER_TYPES.PAGE_TYPE].filterOptions.map((option: ISelectOption) => {
              return (
                <Checkbox
                  key={option.value}
                  value={option.value}
                  isChecked={currentFilters[FILTER_TYPES.PAGE_TYPE].includes(
                    option.value.toString(),
                  )}
                  onChange={e => handleCheckBox(e.target.value, e.target.checked)}
                >
                  {option.label}
                </Checkbox>
              );
            })}
          </HStack>
        </FormControl>
      </VStack>
      <Box w="100%" bottom={0} position="fixed" bgColor={'white'}>
        <Divider />
        <HStack my={2} spacing={6}>
          <Button
            variant="default"
            onClick={() => {
              onFilterSubmit(currentFilters);
            }}
          >
            Apply
          </Button>
          <Button variant="invertedDefault" onClick={onClose}>
            Cancel
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};
export default FilterStatus;
