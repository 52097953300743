import {
  CreateStatusPageMutation,
  CreateStatusPageMutationVariables,
  useCreateStatusPageMutation,
} from '../graphql/mutation';
import { UseMutationOptions, useQueryClient } from 'react-query';

import { queryKeys } from '../constants/status.constants';
import { reactQueryConfigError } from '../helpers/helper.toast';
import { IStatusPageList } from '../Interface';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_CREATED } from 'core/const/tracker';

export const useCreatePage = <TError = unknown, TContext = unknown>(
  onSuccess: (data: IStatusPageList) => void,
  options?: UseMutationOptions<
    CreateStatusPageMutation,
    TError,
    CreateStatusPageMutationVariables,
    TContext
  >,
) => {
  const queryClient = useQueryClient();
  return useCreateStatusPageMutation({
    ...options,
    onSuccess: data => {
      queryClient.invalidateQueries([queryKeys.GET_STATUS_PAGE_LIST]);
      onSuccess(data.createStatusPage as any);
      const statusPageType = data.createStatusPage.isPublic ? 'Public' : 'private';
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_CREATED, {
        'Status Page Type': statusPageType,
      });
    },
    onError: reactQueryConfigError('Create status page'),
  });
};
