import * as React from 'react';
import { ChangeEvent, Component, createRef } from 'react';
import { connect } from 'react-redux';
import './index.css';
import { IAppState } from '../../../core/interfaces/IAppState';
import { requestUserLogout } from '../../../core/actions/auth';
import { API } from '../../../core/api';
import { BillingService, IncidentService } from '../../../core/services';
import { exception } from '../../../core/exception';
import { requestOrganizationSelectedTeamChange } from '../../../core/actions/organization/selectedTeam';
import { render } from './renders.index';
import { IOrganizationEvents } from '../../../core/interfaces/IOrganization';
import { IIncident } from '../../../core/interfaces/IIncidents';
import { IWebAppVisibleRoutes } from '../../../core/interfaces/IAppRouts';
import { AppTracker } from '../../../shared/analytics/tracker';
import {
  T_WA_GS_GLOBAL_TEAM_SWITCHED,
  T_WA_GS_REFERRAL_LINK_CLICKED,
} from '../../../core/const/tracker';
import { RouteComponentProps, withRouter, matchPath } from 'react-router-dom';
import userflow from 'userflow.js';
import withCurrentOnCallUsers from '../organization/schedules/common/GetCurrentOnCallUsers';
import { WhoIsOncallQuery } from 'views/main/organization/schedules/graphql/query';
import { ISCalendarOnCallUser } from 'core/interfaces/ICalendar';
import { DETAIL_LISTING_PATH_MAPPINGS } from '../routes/routes';

export interface ITopBarProps
  extends Pick<IAppState, 'organization' | 'APP_PREVIEW'>,
    RouteComponentProps {
  visibleRoutes: IWebAppVisibleRoutes[];
  pathName: string;
  isRefresh: boolean;
  requestUserLogout: typeof requestUserLogout;
  requestOrganizationSelectedTeamChange: typeof requestOrganizationSelectedTeamChange;
  showMyOnCall: boolean;
  onToggleOnCallView: () => void;
  whoIsOnCallInfo?: WhoIsOncallQuery['whoIsOncall'];
  isWhoIsOnCallLoading?: boolean;
}
interface IState {
  incidentData: { triggerCount: number } & Pick<IIncident, 'id' | 'message'>;
  openModal: 'experiment' | 'search' | '';
  redirectKey: {
    group: string;
    key: string;
  };
  teamSearch: string;
  productTrial: boolean;
  trialExpired: boolean;
  onCallWidgetSearch: string;
  onCallSchedules: WhoIsOncallQuery['whoIsOncall'];
  onCallUsers: {
    [key: string]: ISCalendarOnCallUser[];
  };
}

export class TopBar extends Component<ITopBarProps, IState> {
  private _incidentService = new IncidentService();
  private _isMac = navigator.platform.toLowerCase().includes('mac');
  _keyMod = this._isMac ? '⌘' : 'ctrl';
  _onCallUserLinkRef = createRef<HTMLAnchorElement>();
  _profileUserLinkRef = createRef<HTMLAnchorElement>();
  _teamSelectRef: HTMLButtonElement | null = null;
  _callGetIncidents = false;
  _mountedInterval: any = 0;

  constructor(props: ITopBarProps) {
    super(props);
    this.state = {
      incidentData: {
        id: '',
        message: '',
        triggerCount: 0,
      },
      openModal: '',
      redirectKey: {
        group: '',
        key: '',
      },
      onCallWidgetSearch: '',
      teamSearch: '',
      productTrial: false,
      trialExpired: false,
      onCallSchedules: [],
      onCallUsers: {},
    };

    this.attachSearchModal();
  }

  componentDidMount() {
    API.socket.subscribe(API.config.organizationId);
    API.socket.bind('reload-event', ({ message }: { message: IOrganizationEvents }) => {
      if (message === 'reload-incidents') {
        this._callGetIncidents = true;
      }
    });
    this._mountedInterval = setInterval(() => {
      if (this._callGetIncidents) {
        this.getIncidents();
        this._callGetIncidents = false;
      }
    }, 10000);

    this.initHeadway();
    this.getIncidents();
    this.setState({ productTrial: BillingService.isSomeProductTrialPlan(this.props) });
    this.setState({ trialExpired: BillingService.isProductTrialExpired(this.props) });
    this.setState({ onCallUsers: this.props.organization.calendar.onCall });
  }

  componentDidUpdate(prevProps: Readonly<ITopBarProps>): void {
    if (this.props.whoIsOnCallInfo !== prevProps.whoIsOnCallInfo) {
      this.setState({ onCallSchedules: this.props.whoIsOnCallInfo });
    }
  }

  componentWillUnmount() {
    clearInterval(this._mountedInterval);
  }

  attachSearchModal = () => document.addEventListener('keydown', this.navigationListener);
  removeSearchListener = () => document.removeEventListener('keydown', this.navigationListener);

  onAssignHookRef = (current: HTMLButtonElement | null) => (this._teamSelectRef = current);

  onTeamSearch = (ev: ChangeEvent<HTMLInputElement>) =>
    this.setState({ teamSearch: ev.target.value });

  navigationListener = async (event: KeyboardEvent) => {
    if (['INPUT', 'TEXTAREA'].includes(document.activeElement?.nodeName?.toUpperCase() || '')) {
      return;
    }

    const { ctrlKey: isCtrl, metaKey: isMeta, key, altKey: isAlt } = event;

    if ((this._isMac ? isCtrl : isAlt) && /\d/i.test(key)) {
      this.setState(({ redirectKey }) => ({
        redirectKey: {
          group: redirectKey.group || key,
          key: redirectKey.group ? key : '',
        },
      }));
      return;
    }

    if ((this._isMac ? isMeta : isCtrl) && /\./i.test(key)) {
      this._teamSelectRef?.focus();
      return;
    }

    if ((this._isMac ? isMeta : isCtrl) && /k/i.test(key)) {
      event.preventDefault();

      this.setState({ openModal: 'search' });
      this.removeSearchListener();
    }
  };

  onSearchSwitcherClose = () => {
    this.setState({ openModal: '' });
    this.attachSearchModal();
  };

  toggleModal = (openModal: IState['openModal']) => () => this.setState({ openModal });

  getIncidents = async () => {
    this._callGetIncidents = false;
    try {
      const {
        data: { data },
      } = await this._incidentService.getIncidentList({
        selectedUnit: 7,
        limit: 1,
        status: ['triggered'],
        owner_id: this.props.organization.selectedTeam.teamId,
      });

      const triggerCount = await this.getIncidentsCount();

      if (triggerCount > 0) {
        this.setState({
          incidentData: {
            triggerCount,
            id: triggerCount === 1 ? data.incidents[0].id : '',
            message:
              triggerCount === 1
                ? data.incidents[0].message.slice(0, 80) +
                  (data.incidents[0].message.length > 80 ? '...' : '')
                : '',
          },
        });
        return;
      }
      this.setState({
        incidentData: {
          id: '',
          message: '',
          triggerCount: 0,
        },
      });
    } catch (e) {
      exception.handle('E_GET_INCIDENTS', e);
    }
  };

  getIncidentsCount = async () => {
    try {
      const {
        data: { data },
      } = await this._incidentService.getIncidentsCount({
        selectedUnit: 7,
        status: ['triggered'],
        owner_id: this.props.organization.selectedTeam.teamId,
      });

      return data.currentData.triggered;
    } catch (err: any) {
      exception.handle('E_GET_INCIDENTS_COUNT', err);
      return 0;
    }
  };

  public initHeadway = () => {
    if (import.meta.env.DEV) {
      return;
    }

    try {
      window.Headway.init({
        selector: '.top_bar__new-release',
        account: '7Q9257',
        translations: {
          title: "What's new",
          readMore: 'Read more',
          footer: 'more 👉',
        },
      });
    } catch (err: any) {
      exception.handle('WHATSNEW_DISABLED', err);
    }
  };

  openUrl = (url: string) => () => {
    if (url === 'userflow') {
      this.startProductTour();
    } else if (url === 'referral') {
      AppTracker.track(T_WA_GS_REFERRAL_LINK_CLICKED);
      window.open('https://www.squadcast.com/squadcast-referral-program', '_blank');
    } else {
      window.open(url, '_blank');
    }
  };

  startProductTour = () => userflow.start('65b1648d-f8b0-4ee7-827a-b3a1c487d412');

  blurDropdownReferences = () => {
    this._onCallUserLinkRef.current?.blur();
    this._profileUserLinkRef.current?.blur();
  };

  onSelectTeam = (_: any, teamId: string) => {
    this.props.requestOrganizationSelectedTeamChange(teamId);
    this.setState({ teamSearch: '' });
    AppTracker.track(T_WA_GS_GLOBAL_TEAM_SWITCHED);
    Object.keys(DETAIL_LISTING_PATH_MAPPINGS).forEach(
      (key: keyof typeof DETAIL_LISTING_PATH_MAPPINGS) => {
        if (matchPath(this.props.pathName, { path: key as string })) {
          this.props.history.push(DETAIL_LISTING_PATH_MAPPINGS[key]);
        }
      },
    );
  };

  dismissIncidentNotification = () => {
    this.setState({
      incidentData: {
        id: '',
        message: '',
        triggerCount: 0,
      },
    });
  };

  render = render;
}

const TopBarWithStore = connect(
  ({ organization, APP_PREVIEW }: IAppState) => ({
    organization,
    APP_PREVIEW,
  }),
  {
    requestUserLogout,
    requestOrganizationSelectedTeamChange,
  },
)(TopBar);

const TopBarWithOnCallUsers = withCurrentOnCallUsers(TopBarWithStore);

const TopBarWithRouter = withRouter(TopBarWithOnCallUsers);

export default TopBarWithRouter;
