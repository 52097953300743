import { Grid, Para, Theme, Tooltip } from 'uie/components';
import { truncate } from 'core/helpers/stringUtils';
import { ReactNode } from 'react';

import { IIAssigne } from '../../../../../../../core/interfaces/IIncidents';
import { EscalationIcon, SquadIcon, UserIcon } from '../../../../../../../icons';
import { hasReadAccessToEntity } from '../../../../navigation-flows/helpers';
import useUINavigationFunctions from '../../../../navigation-flows/useUINavigationFunctionV2';
import { linkStyles } from '../../../constants';
import { ASSIGNEE_NAME_MAX_LENGTH } from '../const';
import { AssigneeProps } from '../types';

const { theme } = Theme;

const ICON_MAP: Record<IIAssigne, ReactNode> = {
  user: <UserIcon height={16} width={16} />,
  squad: <SquadIcon height={16} width={16} />,
  escalationpolicy: <EscalationIcon height={16} width={16} />,
};

export const ResponderAssignee = ({
  organization,
  assignee,
  incident,
  showCurrentAssignee = false,
  nameLength = ASSIGNEE_NAME_MAX_LENGTH,
}: AssigneeProps) => {
  const { type, id } = assignee;

  const { acceptableEntityTypesForPopover, getEntityComponent, getEntityName } =
    useUINavigationFunctions(organization);

  const entityName = truncate(getEntityName(type, id), nameLength);

  const simpleComponent = (
    <Para className="ml-5" fontSize={14} fontWeight={500}>
      {entityName}
    </Para>
  );

  let entityComponent = simpleComponent;
  const canNavigateToEntity = entityName !== 'Deleted Entity' && hasReadAccessToEntity(type);

  if (canNavigateToEntity) {
    const simpleComponentWithLinkStyles = (
      <Para className="ml-5" fontSize={14} fontWeight={500} style={linkStyles}>
        {entityName}
      </Para>
    );
    entityComponent = getEntityComponent({
      componentType: acceptableEntityTypesForPopover.includes(type)
        ? 'linkWPopover'
        : 'linkWOPopover',
      type,
      id,
      popoverTrigger: simpleComponentWithLinkStyles,
      popoverPlacement: 'left-start',
    });
  }
  if (['Deleted User', 'Deleted Entity'].includes(entityName)) return null;

  return (
    <Grid alignItems="center" justifyContent="space-between" width="100%">
      <Grid alignItems="center">
        {ICON_MAP[type]}
        {entityComponent}
      </Grid>
      {showCurrentAssignee &&
      id === incident.assignedTo[incident.assignedTo.length - 1].id &&
      incident.status !== 'resolved' ? (
        <Tooltip label="Currently assigned to" offset={{ left: '-130px' }}>
          <div className="incident_details__responders_current_assignee" />
        </Tooltip>
      ) : (
        <Para className="ml-5" color={theme.shades.black} fontSize={12}></Para>
      )}
    </Grid>
  );
};
