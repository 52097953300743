import Axios from 'axios';
import { API } from '../api';
import { IUserNotificationRule } from '../interfaces/IUserData';

class OncallReminderService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/oncall-reminder`;

  public getOncallReminder = (teamId: string) =>
    Axios.get<{
      data: {
        rules: IUserNotificationRule[];
        is_global: boolean;
      };
    }>(`${this._api}?team_id=${teamId}`);
}

export default OncallReminderService;
