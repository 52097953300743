import axios from 'axios';
import { API } from '../api';
import { ISquad, ISquadRequest, ISquadResponse } from '../interfaces/ISquad';

class SquadsService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/squads`;

  getAll = (teamId: string) => axios.get<{ data: ISquad[] }>(`${this._api}?owner_id=${teamId}`);

  create = (teamId: string, squadRequest: ISquadRequest) =>
    axios.post<ISquadResponse>(this._api, {
      owner_id: teamId,
      ...squadRequest,
    });

  update = (squadID: string, squadRequest: ISquadRequest) =>
    axios.put<ISquadResponse>(`${this._api}/${squadID}`, squadRequest);

  delete = (squadID: string) => axios.delete(`${this._api}/${squadID}`);
}

export default SquadsService;
