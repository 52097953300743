import { Form } from 'formik';
import React from 'react';

import { StateFormContainer } from '../states/StateFormContainer';
import { IssueDetailsForm } from './detailsForm';

const IssueForm = () => {
  return (
    <Form style={{ overflowY: 'auto', width: '100%' }}>
      <IssueDetailsForm />
      <StateFormContainer />
    </Form>
  );
};
export default IssueForm;
