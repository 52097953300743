import { Avatar as ChakraAvatar, AvatarBadge } from '@chakra-ui/react';

type Props = {
  size: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full';
  src: string;
  showBadge: boolean;
  name: string;
  icon: React.ReactElement;
  showBorder: boolean;
};

export const Avatar = (props: Partial<Props>) => {
  const { showBadge, ...avatarProps } = props;
  avatarProps.size = avatarProps.size ?? 'md';

  return (
    <ChakraAvatar marginInlineStart={0} size={avatarProps.size} {...avatarProps}>
      {showBadge && <AvatarBadge boxSize="1.25em" bg="green.500" />}
    </ChakraAvatar>
  );
};
