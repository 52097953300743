import React from 'react';
import { VStack, FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';
import { FormikTouched, useFormikContext } from 'formik';
import { rotationsCustomizePattern } from '../../constants/rotations.customize';
import { INewSchedule, IRotation } from '../../interface/schedule';

type ErrorMessageObject = {
  name: string;
};

const PatternName = ({ rotationId }: Record<string, string>) => {
  const {
    values: { rotations },
    errors,
    touched,
    handleChange,
  } = useFormikContext<INewSchedule>();

  const currentRotationName = rotations.find(rt => rt.id === rotationId)?.name;
  const currentRotationIndex = rotations.findIndex(rt => rt.id === rotationId);

  const rotationNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rotationName = event.target.value.trim();
    if (rotationName.length > 0) {
      handleChange({
        target: {
          name: `rotations.${currentRotationIndex}.name`,
          value: event.currentTarget.value,
        },
      });
    } else {
      handleChange({ target: { name: `rotations.${currentRotationIndex}.name`, value: '' } });
    }
  };

  return (
    <FormControl
      isInvalid={
        !!(
          (errors.rotations && errors.rotations[currentRotationIndex]
            ? (errors.rotations[currentRotationIndex] as ErrorMessageObject).name
            : false) &&
          (touched.rotations && touched.rotations[currentRotationIndex]
            ? (touched.rotations[currentRotationIndex] as FormikTouched<IRotation>).name
            : false)
        )
      }
    >
      <VStack align="left" mt={6}>
        <FormLabel variant="rotationPattern">
          {rotationsCustomizePattern.labels.rotationName}
        </FormLabel>
        <Input
          value={currentRotationName}
          onChange={rotationNameChangeHandler}
          placeholder={rotationsCustomizePattern.placeholders.rotationName}
          name={`rotations.${currentRotationIndex}.name`}
        />
      </VStack>
      <FormErrorMessage>
        {errors.rotations && errors.rotations[currentRotationIndex]
          ? (errors.rotations[currentRotationIndex] as ErrorMessageObject).name
          : ''}
      </FormErrorMessage>
    </FormControl>
  );
};

export default PatternName;
