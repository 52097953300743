import { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import { requestOrganizationTeams } from '../../../../../../../core/actions/organization/teams';
import { IAppState } from '../../../../../../../core/interfaces/IAppState';
import {
  IComponentErrors,
  IComponentNetworkState,
} from '../../../../../../../core/interfaces/IComponentState';
import { ITeam, ITeamRoles } from '../../../../../../../core/interfaces/ITeams';
import TeamsRolesService from '../../../../../../../core/services/rbac/service.roles';
import { exception } from '../../../../../../../core/exception';
import { checkIfKeyChanged, checkIfNotValid, hasProperty, objectIsEmpty } from 'core/helpers';
import './index.css';
import { render } from './render.index';
import { AppTracker } from '../../../../../../../shared/analytics/tracker';
import {
  T_WA_ENTITIES_TAB_VIEWED,
  T_WA_GS_DEFAULT_TEAM_ROLE_MODIFIED,
  T_WA_GS_NEW_TEAM_ROLE_ADDED,
  T_WA_TEAM_ROLE_VIEWED,
} from '../../../../../../../core/const/tracker';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import equal from 'fast-deep-equal/es6/react';
import { BillingService, deepCopy } from 'core';
import { UserAccessContextValue, withUserAccess } from 'core/userAccess/UserAccessContext';

interface IProps extends Pick<IAppState, 'organization'>, RouteComponentProps {
  team: ITeam;
  refreshId: number;
  search: string;
  updateChildInRequest: (request: boolean) => void;
  requestOrganizationTeams: typeof requestOrganizationTeams;
  sethasUnsavedOnChild: (unsavedExist: boolean) => void;
  hasUnsavedChanges: boolean;
  userAccess: UserAccessContextValue;
}

interface IState {
  deleteRoleId: string;
  editRoleId: string;
  editRole: Pick<ITeamRoles, 'abilities' | 'name' | 'id'> | null;
  newRole: Pick<ITeamRoles, 'abilities' | 'name'> | null;
  errors: IComponentErrors<
    | 'delete_role'
    | 'new_role__name'
    | 'new_role__abilities'
    | 'edit_role__name'
    | 'edit_role__abilities'
    | 'network_err'
  >;
  networkState: IComponentNetworkState | 'save';
  customRbacFeatureAccess: boolean;
  modifyRBAC: boolean;
  showCustomRbacUpgradeModal: boolean;
  showDefaultRBACUpgradeModal: boolean;
  hasUnsavedChanged: boolean;
}

export class OrganizationSettingsTeamsRenderRoles extends Component<IProps, IState> {
  private _roleService = new TeamsRolesService(this.props.team.id);
  render = render;

  constructor(props: IProps) {
    super(props);

    this.state = {
      deleteRoleId: '',
      editRoleId: '',
      newRole: null,
      editRole: null,
      errors: {},
      networkState: 'idle',
      hasUnsavedChanged: false,
      customRbacFeatureAccess: false,
      modifyRBAC: false,
      showCustomRbacUpgradeModal: false,
      showDefaultRBACUpgradeModal: false,
    };
  }

  componentDidMount() {
    AppTracker.track(T_WA_TEAM_ROLE_VIEWED, {
      'Team ID': this.props.team.id,
    });
    this.setState({
      modifyRBAC: !BillingService.isFeatureDisabled(this.props, 'modify-default-rbac'),
    });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (checkIfKeyChanged(prevProps, this.props, 'refreshId')) {
      this._roleService = new TeamsRolesService(this.props.team.id);
      this.props.updateChildInRequest(false);
      this.setState({
        deleteRoleId: '',
        editRoleId: '',
        networkState: 'idle',
        errors: {},
      });
    }
    if (!this.state.hasUnsavedChanged) {
      if (prevState.newRole !== null) {
        if (!equal(this.state.newRole, prevState.newRole)) {
          this.setState({ hasUnsavedChanged: true });
        }
      }
      if (prevState.editRole !== null) {
        if (!equal(this.state.editRole, prevState.editRole)) {
          this.setState({ hasUnsavedChanged: true });
        }
      }
    }
    if (this.state.hasUnsavedChanged) {
      if (
        this.state.newRole === null &&
        this.state.editRole === null &&
        this.state.editRoleId === ''
      ) {
        this.setState({ hasUnsavedChanged: false });
      }
    }
    if (
      prevProps.hasUnsavedChanges &&
      prevProps.hasUnsavedChanges !== this.props.hasUnsavedChanges
    ) {
      this.setState({ hasUnsavedChanged: this.props.hasUnsavedChanges });
    } else {
      if (this.state.hasUnsavedChanged && !this.props.hasUnsavedChanges)
        this.props.sethasUnsavedOnChild(true);
      if (prevState.hasUnsavedChanged && !this.state.hasUnsavedChanged)
        this.props.sethasUnsavedOnChild(false);
    }
  }

  onCreateNewRole = async () => {
    const { newRole } = this.state;

    if (newRole === null) return;

    const errors = checkIfNotValid([
      [newRole.name, '', { new_role__name: 'Name is required' }],
      [
        objectIsEmpty(newRole.abilities) || Object.values(newRole.abilities).every(objectIsEmpty),
        true,
        { new_role__abilities: 'One operation on one entity is required' },
      ],
    ]);

    if (!objectIsEmpty(errors)) {
      this.setState({ errors });
      return;
    }

    this.props.updateChildInRequest(true);
    this.setState({ networkState: 'save', errors: {} });

    try {
      await this._roleService.create(newRole);
      this.setState({ newRole: null }); //newRole
      this.props.requestOrganizationTeams();
      AppTracker.track(T_WA_GS_NEW_TEAM_ROLE_ADDED, {
        'Role Name': newRole.name,
        'Entities_Escalation Policy': newRole.abilities.escalation_policies,
        Entities_Postmortems: newRole.abilities.postmortems,
        Entities_Schedules: newRole.abilities.schedules,
        Entities_Services: newRole.abilities.services,
        Entities_Squads: newRole.abilities.squads,
        'Entities_Status Page': newRole.abilities.status_pages,
        'Entities_Team Analytics': newRole.abilities.team_analytics,
        'Entities_Webforms Webforms': newRole.abilities.webforms,
      });
    } catch (err: any) {
      this.setState({ errors: { network_err: exception.handle('E_CREATE_TEAMS_ROLE', err) } });
    } finally {
      this.setState({ networkState: 'idle' });
    }
  };

  onUpdateRole = async () => {
    const { editRole } = this.state;

    if (editRole === null) return;

    const errors = checkIfNotValid([
      [editRole.name, '', { edit_role__name: 'Name is required' }],
      [
        objectIsEmpty(editRole.abilities) || Object.values(editRole.abilities).every(objectIsEmpty),
        true,
        { edit_role__abilities: 'One operation on one entity is required' },
      ],
    ]);

    if (!objectIsEmpty(errors)) {
      this.setState({ errors });
      return;
    }

    this.props.updateChildInRequest(true);
    this.setState({ networkState: 'save', errors: {} });

    try {
      await this._roleService.update(editRole.id, editRole);
      this.setState({ editRole: null, editRoleId: '' }); //, hasUnsavedChanged: false
      this.props.requestOrganizationTeams();
      if (editRole.name === 'Admin' || editRole.name === 'User' || editRole.name === 'Observer') {
        AppTracker.track(T_WA_GS_DEFAULT_TEAM_ROLE_MODIFIED, {
          'Role Name': editRole.name,
          'Entities_Escalation Policy': editRole.abilities.escalation_policies,
          Entities_Postmortems: editRole.abilities.postmortems,
          Entities_Schedules: editRole.abilities.schedules,
          Entities_Services: editRole.abilities.services,
          Entities_Squads: editRole.abilities.squads,
          'Entities_Status Page': editRole.abilities.status_pages,
          'Entities_Webforms Webforms': editRole.abilities.webforms,
        });
      }
    } catch (err: any) {
      this.setState({ errors: { network_err: exception.handle('E_UPDATE_TEAMS_ROLE', err) } });
    } finally {
      this.setState({ networkState: 'idle' });
    }
  };

  onConfirmDelete = async () => {
    const { deleteRoleId } = this.state;

    if (!this.state.modifyRBAC) {
      this.setState({ showDefaultRBACUpgradeModal: true });
      return;
    }

    this.setState({ networkState: 'save' });

    try {
      await this._roleService.delete(deleteRoleId);
      this.props.requestOrganizationTeams();
      this.setState({ deleteRoleId: '' });
    } catch (err: any) {
      this.setState({ errors: { delete_role: exception.handle('E_DELETE_TEAMS_ROLE', err) } });
    } finally {
      this.setState({ networkState: 'idle' });
    }
  };

  onToggleDelete = (deleteRoleId: string) => () => this.setState({ deleteRoleId, errors: {} });

  onHideConfirmDelete = (confirm: boolean) =>
    confirm ? this.onConfirmDelete() : this.onToggleDelete('')();

  onEditRole = (editRoleId: string) => () => {
    if (!this.state.modifyRBAC) {
      this.setState({ showDefaultRBACUpgradeModal: true });
      return;
    }
    const currentRole = this.props.team.roles.find(r => r.id === editRoleId);

    if (!currentRole) {
      return { editRoleId: '' };
    }

    this.setState({
      editRoleId,
      editRole: {
        id: currentRole.id,
        name: currentRole.name,
        abilities: currentRole.abilities,
      },
    });
  };

  onAddNewRole = () => {
    if (!this.state.modifyRBAC) {
      this.setState({ showCustomRbacUpgradeModal: true });
      return;
    }
    this.setState({
      newRole: {
        name: '',
        abilities: {
          escalation_policies: {},
          postmortems: {},
          schedules: {},
          services: {},
          squads: {},
          status_pages: {},
        },
      },
      // hasUnsavedChanged: true,
    });
  };

  onNewRoleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState(({ newRole }) => {
      if (newRole === null) {
        return { newRole };
      }
      return { newRole: { ...newRole, name: value } };
    });
  };

  onEditRoleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState(({ editRole }) => {
      if (editRole === null) {
        return { editRole };
      }

      return { editRole: { ...editRole, name: value } };
    });
  };

  onEditRoleOperationChange = (entity: string, operation: string) => () => {
    this.setState(({ editRole }) => {
      const editableRole = deepCopy(editRole) as Pick<
        ITeamRoles,
        'abilities' | 'name' | 'id'
      > | null;
      if (editableRole === null) {
        return { editRole: editableRole };
      }

      const ability = editableRole.abilities as any;

      if (ability[entity] && hasProperty(ability[entity], operation)) {
        delete (ability[entity] as any)[operation];
      } else {
        (ability[entity] as any) = {
          ...(ability[entity] || {}),
          ...{ [operation]: true },
        };
      }
      return { editRole: editableRole };
    });
  };

  onNewRoleOperationChange = (entity: string, operation: string) => () => {
    this.setState(({ newRole }) => {
      const editableNewRole = deepCopy(newRole) as Pick<ITeamRoles, 'abilities' | 'name'> | null;
      if (editableNewRole === null) {
        return { newRole: editableNewRole };
      }

      const ability = editableNewRole.abilities as any;

      if (ability[entity] && hasProperty(ability[entity], operation)) {
        delete (ability[entity] as any)[operation];
      } else {
        (ability[entity] as any) = {
          ...(ability[entity] || {}),
          ...{ [operation]: true },
        };
      }
      return { newRole: editableNewRole };
    });
  };

  onCancelEditOrSaveRole = () => {
    this.setState({ editRoleId: '', newRole: null, editRole: null });
    this.props.requestOrganizationTeams();
  };
}

const OrganizationSettingsTeamsRenderRolesWithHistory = withRouter(
  OrganizationSettingsTeamsRenderRoles,
);

export default connect(
  ({ organization }: IAppState) => ({
    organization,
  }),
  { requestOrganizationTeams },
)(withUserAccess(OrganizationSettingsTeamsRenderRolesWithHistory));
