import { IAppState } from 'core/interfaces/IAppState';
import { FilterObject, FilterObjectKeys, Owner } from './hooks/useEntityOwnerFilter';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { ISquad } from 'core/interfaces/ISquad';
import { SquadList } from '../settings/teams/render/squads/types';

const valueSeparator = ',';
const paramSeparator = '|';
const keyValueSeparator = ':';

export const getDropdownOptionDetails = (
  organization: IAppState['organization'],
  selectedTeamId?: string,
  squads?: any,
) => {
  let selectedTeam = organization?.selectedTeam?.team;
  const currentUser = organization.currentUser.u;
  if (selectedTeamId) {
    selectedTeam = organization.teams.t.find(team => team.id === selectedTeamId);
  }
  const membersOfSelectedTeam = selectedTeam?.members.map(member => member.user_id);
  const allUserOptions = organization?.users.u
    ? organization?.users?.u
        .filter(user => membersOfSelectedTeam?.includes(user.id))
        .filter(user => user.role !== 'stakeholder')
        .sort((comparingElement: any, compareWithElement: any) =>
          comparingElement.first_name.localeCompare(compareWithElement.first_name),
        )
        .map(
          ({ first_name, last_name, id, username_for_display: username }: IUsersOfOrganization) => {
            return {
              value: id,
              label: `${first_name} ${last_name}`,
              type: Owner.USER,
              username,
            };
          },
        )
    : [];
  const varFac = squads ? squads : organization?.squads.s ?? [];
  const allSquadOptions = varFac
    .filter(squad => {
      return squad.members?.some(member => membersOfSelectedTeam?.includes(member));
    })
    .sort((comparingElement: any, compareWithElement: any) =>
      comparingElement.name.localeCompare(compareWithElement.name),
    )
    .map(({ id, name, members }: ISquad) => {
      return {
        value: id,
        label: name,
        type: Owner.SQUAD,
        members,
      };
    });
  const currentUserOption = allUserOptions
    .filter(opt => opt.value === currentUser?.id)
    .map(opt => ({ ...opt, label: `${opt.label} (You)` }));
  const otherUsersOption = allUserOptions.filter(opt => opt.value !== currentUser?.id);
  const teamOption = selectedTeam
    ? {
        value: selectedTeam.id,
        label: `Current Team (${selectedTeam.name})`,
        type: Owner.TEAM,
        tagLabel: `${selectedTeam.name} (Current Team)`,
      }
    : null;
  const squadsOfCurrentUser = allSquadOptions.filter(
    squad => squad.members.indexOf(currentUser?.id ?? '') > -1,
  );
  const otherSquads = allSquadOptions.filter(
    squad => !squadsOfCurrentUser.find(s => s.value === squad.value),
  );
  const selectAllOption = {
    value: 'select-all',
    label: 'Select All',
    type: 'select-all',
  };

  return {
    currentUser,
    currentUserOption,
    allUserOptions,
    allSquadOptions,
    teamOption,
    squadsOfCurrentUser,
    otherUsersOption,
    otherSquads,
    selectAllOption,
  };
};

export const getDropdownOptionDetailsForTeam = (
  organization: IAppState['organization'],
  teamID: string,
  squad: SquadList,
) => {
  const selectedTeam = organization?.teams.t.find(team => team.id === teamID);
  const currentUser = organization.currentUser.u;
  const membersOfSelectedTeam = selectedTeam?.members.map(member => member.user_id);
  const allUserOptions = organization?.users.u
    ? organization?.users?.u
        .filter(user => membersOfSelectedTeam?.includes(user.id))
        .filter(user => user.role !== 'stakeholder')
        .sort((comparingElement: any, compareWithElement: any) =>
          comparingElement.first_name.localeCompare(compareWithElement.first_name),
        )
        .map(
          ({ first_name, last_name, id, username_for_display: username }: IUsersOfOrganization) => {
            return {
              value: id,
              label: `${first_name} ${last_name}`,
              type: Owner.USER,
              username,
            };
          },
        )
    : [];
  const allSquadOptions = squad.data
    ? squad.data
        .filter(squad => squad.team_id === teamID)
        .filter(squad => {
          return squad.members?.some(member => membersOfSelectedTeam?.includes(member.user_id));
        })
        .sort((comparingElement: any, compareWithElement: any) =>
          comparingElement.name.localeCompare(compareWithElement.name),
        )
        .map(({ id, name, members }) => {
          return {
            value: id,
            label: name,
            type: Owner.SQUAD,
            members: members.map(member => member.user_id),
          };
        })
    : [];
  const currentUserOption = allUserOptions
    .filter(opt => opt.value === currentUser?.id)
    .map(opt => ({ ...opt, label: `${opt.label} (You)` }));
  const otherUsersOption = allUserOptions.filter(opt => opt.value !== currentUser?.id);
  const teamOption = selectedTeam
    ? {
        value: selectedTeam.id,
        label: `Current Team (${selectedTeam.name})`,
        type: Owner.TEAM,
        tagLabel: `${selectedTeam.name} (Current Team)`,
      }
    : null;
  const squadsOfCurrentUser = allSquadOptions.filter(
    squad => squad.members.indexOf(currentUser?.id ?? '') > -1,
  );
  const otherSquads = allSquadOptions.filter(
    squad => !squadsOfCurrentUser.find(s => s.value === squad.value),
  );
  const selectAllOption = {
    value: 'select-all',
    label: 'Select All',
    type: 'select-all',
  };

  return {
    currentUser,
    currentUserOption,
    allUserOptions,
    allSquadOptions,
    teamOption,
    squadsOfCurrentUser,
    otherUsersOption,
    otherSquads,
    selectAllOption,
  };
};

export const encodeFilterParam = (filters: FilterObject) => {
  const filterParams: string[] = [];
  Object.values(FilterObjectKeys).forEach(key => {
    const filterValue: Array<string> | boolean | undefined | null = filters[key];
    if (filterValue instanceof Array) {
      //for array values
      const values = Array(filterValue).join(valueSeparator);
      if (values.length) {
        filterParams.push(`${key}${keyValueSeparator}${values}`);
      }
    } else {
      //for boolean values
      filterValue && filterParams.push(`${key}`);
    }
  });
  return encodeURI(filterParams.join(paramSeparator));
};

export const decodeFilterParam = (params: string) => {
  const filterParams = {
    [FilterObjectKeys.USER]: [],
    [FilterObjectKeys.SQUAD]: [],
    [FilterObjectKeys.CURRENT_TEAM]: false,
  } as FilterObject;
  const paramKeyValues = decodeURI(params).split(paramSeparator);
  paramKeyValues.forEach(param => {
    if (param === FilterObjectKeys.CURRENT_TEAM) {
      filterParams[FilterObjectKeys.CURRENT_TEAM] = true;
    } else {
      const [paramKey, paramValue] = param.split(keyValueSeparator);
      const filterObjectKey = paramKey as FilterObjectKeys;

      if (filterObjectKey === FilterObjectKeys.USER || filterObjectKey === FilterObjectKeys.SQUAD) {
        filterParams[filterObjectKey] = paramValue.split(valueSeparator);
      }
    }
  });
  return filterParams;
};
