import React, { forwardRef } from 'react';
import { StackProps, HStack } from '@chakra-ui/layout';

const Wrapper = forwardRef((props: StackProps, ref: React.Ref<HTMLDivElement>) => {
  return (
    <HStack
      bg="brand.white"
      p={4}
      borderStyle="solid"
      borderColor="secondary.200"
      align="center"
      borderRadius={2}
      ref={ref}
      gap={2}
      {...props}
    >
      {props.children}
    </HStack>
  );
});

export default Wrapper;
