import { Box, Flex, FormLabel, Heading, Link, Switch, Text, Button } from '@chakra-ui/react';
import { CrownIcon, IAGGrayIcon } from 'icons';
import React, { useMemo } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import Tippy from '@tippy.js/react';
import UpgradeOnlyModal from 'components/upgradeonly.tooltip';
import { BillingService } from 'core';
import { defaultGroupingOptions } from './iagConst';
import { Modal } from 'library/molecules';

type Props = {
  isEnabled: boolean;
  onToggle: (isEnabled: boolean) => void;
  groupingWindow: number;
  onChangeGroupingWindow: (groupingWindow: number) => void;
  isEditMode?: boolean;
};

const IagConfigPanel: React.FC<Props> = ({
  isEnabled,
  onToggle: onToggleProp,
  groupingWindow,
  onChangeGroupingWindow,
  isEditMode,
}) => {
  const [showDisableWarningModal, setShowDisableWarningModal] = React.useState<boolean>(false);

  const onToggle = () => {
    if (isEnabled && isEditMode) {
      setShowDisableWarningModal(true);
      return;
    }

    onToggleProp(!isEnabled);
  };

  const { iagRules, organizationProps } = useSelector((state: IAppState) => ({
    iagRules: state.organization.plan.p?.rules['auto-pause-transient-alerts'],
    organizationProps: { organization: state.organization },
  }));

  const { isIagEnabled, planLimit, isPlanRenewable } = useMemo(() => {
    if (!iagRules) {
      return {
        isIagEnabled: false,
        planLimit: undefined,
        isPlanRenewable: false,
      };
    }

    const limit: number | undefined | 'unlimited' = iagRules.unlimited
      ? 'unlimited'
      : iagRules.quantity || undefined;

    return {
      isIagEnabled: true,
      planLimit: limit,
      isPlanRenewable: iagRules.renewable,
    };
  }, [iagRules]);

  return (
    <Tippy
      content={
        !isIagEnabled ? (
          <UpgradeOnlyModal
            hasBillingPermission={BillingService.hasManageBillingPermission(organizationProps)}
            align="left"
            showModal={true}
            message={BillingService.getMessage(
              planLimit,
              'auto-pause-transient-alerts',
              organizationProps,
              isPlanRenewable,
            )}
            header={BillingService.getHeader(
              planLimit,
              'auto-pause-transient-alerts',
              organizationProps,
            )}
          />
        ) : (
          ''
        )
      }
      interactive={true}
      animation={'scale'}
      placement="top-end"
    >
      <Box bg="#F3F9FF" px={3} py={3}>
        <Flex>
          <Box width="24px" height="24px" mt="6px">
            <IAGGrayIcon />
          </Box>

          <Box width={5} />

          <Box>
            <Flex>
              <Heading as="h3" fontSize="20px" color="gray.700" fontWeight="600">
                Intelligent Alert Grouping
              </Heading>

              <Box width={4} />

              <Switch
                marginTop={1}
                isChecked={isEnabled}
                onChange={() => onToggle()}
                disabled={!isIagEnabled}
              />

              {!isIagEnabled && (
                <>
                  <Box width={4} />

                  <CrownIcon width={24} height={24} />
                </>
              )}
            </Flex>

            <IagConfigInfoCard />

            {isEnabled && (
              <Box>
                <FormLabel fontWeight={600}>Grouping Window</FormLabel>

                <Select
                  name="Grouping Window"
                  id="grouping-window"
                  options={defaultGroupingOptions}
                  value={defaultGroupingOptions.find(_ => _.value === groupingWindow)}
                  onChange={option => {
                    onChangeGroupingWindow(option?.value || 20);
                  }}
                />
              </Box>
            )}

            <Modal
              title="Do you want to disable Intelligent Alert Grouping?"
              isOpen={showDisableWarningModal}
              onClose={() => setShowDisableWarningModal(false)}
              primaryButton={
                <Button
                  onClick={() => {
                    onToggleProp(!isEnabled);
                    setShowDisableWarningModal(false);
                  }}
                  variant="default"
                >
                  <Text>Yes, Disable</Text>
                </Button>
              }
              secondaryButton={
                <Button
                  onClick={() => {
                    setShowDisableWarningModal(false);
                  }}
                  variant="outline"
                >
                  <Text>Cancel</Text>
                </Button>
              }
              body={
                <Text color="gray.500">
                  Intelligent Alert Grouping will be disabled and which may result in higher
                  notification volume.
                </Text>
              }
            />
          </Box>
        </Flex>
      </Box>
    </Tippy>
  );
};

const IagConfigInfoCard: React.FC = () => (
  <Box py={4} maxWidth="420px">
    <Text color="gray.500">
      Uses real-time machine learning to group related alerts into a single incident.{' '}
      <Link
        color="primary.default"
        href="https://support.squadcast.com/services/intelligent-alert-grouping-iag"
        target="_blank"
      >
        Read More
      </Link>
    </Text>
  </Box>
);

export default IagConfigPanel;
