import { createStandaloneToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { QueryClient } from 'react-query';
import { QUERY_KEY } from '../const';

const toast = createStandaloneToast();

export const reactQueryConfigSuccess = (message: string) => {
  toast({
    title: message,
    status: 'success',
    duration: 9000,
    isClosable: true,
  });
};

export const onAPIError = (error: unknown) => {
  const responseError = error as AxiosError<{ meta: { error_message: string } }>;
  const title = responseError?.response?.data?.meta?.error_message || 'Error connecting to server';
  toast.closeAll();
  toast({ title, status: 'error', variant: 'subtle', isClosable: true });
};

export const invalidateRespondersList = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(QUERY_KEY.ADDITIONAL_RESPONDERS);
};
