import { Text } from '@chakra-ui/layout';
import React from 'react';
import EventCard from './event-card';
import { Participants } from './participants';
import { TimeDisplay } from './time-display';
import { OnCallCoverage } from './types';
import { getAllParticipants, parseDateTimeInTimezone } from './utils';

interface RotationsCardProps {
  rotations: OnCallCoverage['rotations'];
  timeZone: string;
}

const RenderRotation = ({
  rotation,
  timeZone,
}: {
  rotation: NonNullable<RotationsCardProps['rotations']>[number];
  timeZone: string;
}) => {
  const { startDateTime, endDateTime } = parseDateTimeInTimezone({
    startTime: rotation.startDate,
    endTime: rotation.endDate,
    timeZone,
  });

  const allParticipants = getAllParticipants(rotation);

  return (
    <EventCard.Wrapper bgColor={rotation.color ?? 'primary.200'} key={rotation.ID}>
      <Text fontSize={12} fontWeight="bold" color="#1D426B" lineHeight="150%">
        {rotation.name}
      </Text>
      <EventCard.Row
        title="Repeats "
        content={` : ${rotation.period}`}
        additionalContentProps={{
          textTransform: 'capitalize',
        }}
      />
      <EventCard.Row
        title="Start & End Date : "
        content={`${startDateTime.toFormat('MMM dd, yyyy')} - ${
          endDateTime?.toFormat('MMM dd, yyyy') ?? 'Forever'
        }`}
      />
      <EventCard.Row
        title="Start & End Time : "
        content={<TimeDisplay data={rotation} timeZone={timeZone} />}
        alignItems="flex-start"
      />
      {allParticipants?.length > 0 && (
        <EventCard.Row
          additionTitleProps={{ minWidth: '96px' }}
          additionalContentProps={{ marginTop: '-2px' }}
          title="Participants : "
          alignItems="flex-start"
          content={<Participants participants={allParticipants as any} />}
        />
      )}
    </EventCard.Wrapper>
  );
};

export const RotationsCard: React.FC<RotationsCardProps> = ({ rotations, timeZone }) => {
  if (!rotations) return null;
  return (
    <EventCard>
      {rotations.map(rotation => {
        if (!rotation) return null;
        return <RenderRotation rotation={rotation} timeZone={timeZone} key={rotation.ID} />;
      })}
    </EventCard>
  );
};
