import { DateRange, TimeDimensionGranularity } from '@cubejs-client/core';
import { DateTime } from 'luxon';
import { CustomDateRange, DateRangesMap } from '../date-ranges';
import { Filters } from '../types';

export function differenceInDays(startDate: DateTime, endDate: DateTime): number {
  return endDate.diff(startDate, 'days').days + 1;
}

const toSQLDates = ([a, b]: [DateTime, DateTime]): [string, string] => [
  a.toSQLDate(),
  b.toSQLDate(),
];

export const toDateTime = (date: Date) => {
  return DateTime.fromJSDate(date);
};

export const dateRange = (filter: Filters['date']): DateRange => {
  if (filter.preset === CustomDateRange) {
    let [sd, ed] = [filter.startDate, filter.endDate];
    if (sd > ed) {
      [sd, ed] = [ed, sd];
    }
    return toSQLDates([sd.startOf('day'), ed.endOf('day')]);
  } else {
    const now = DateTime.now();
    const { fn } = DateRangesMap[filter.preset];
    return toSQLDates(fn(now));
  }
};

export const compareDateRange = (filter: Filters['date']): DateRange[] => {
  if (filter.preset === CustomDateRange) {
    let [sd, ed] = [filter.startDate, filter.endDate];
    if (sd > ed) {
      [sd, ed] = [ed, sd];
    }
    const days = differenceInDays(sd.startOf('day'), ed.endOf('day'));
    return [
      toSQLDates([sd.startOf('day'), ed.endOf('day')]),
      toSQLDates([sd.minus({ days }).startOf('day'), ed.minus({ days }).endOf('day')]),
    ];
  } else {
    const now = DateTime.now();
    const { fn, previousFn } = DateRangesMap[filter.preset];
    return [toSQLDates(fn(now)), toSQLDates(previousFn(now))];
  }
};

export function dateRangeGranularity(filter: Filters['date']): TimeDimensionGranularity {
  const days = differenceInDays(filter.startDate, filter.endDate);

  if (days <= 1) {
    // yesterday, today
    return 'hour';
  } else if (days > 1 && days < 7) {
    // between 2 and 6 days
    return 'hour';
  } else if (days > 6 && days <= 30) {
    // between 1 week and 1 month
    return 'day';
  } else if (days > 30 && days <= 180) {
    // more than a month to 6 months (2 quarters)
    return 'week';
  } else {
    // beyond 36 months
    return 'month';
  }
}

export function dateRangeGranularityForCalendar(filter: Filters['date']): TimeDimensionGranularity {
  const granularity = dateRangeGranularity(filter);
  return granularity === 'hour' ? 'day' : granularity;
}
