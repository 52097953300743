import { Text, Box, Flex, Divider } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { THEME_COLORS } from 'library/theme/colors';
import React from 'react';
import { ISelectedComponent } from '../../../Interface';

export const IssueDetails = () => {
  const { values }: any = useFormikContext();
  return (
    <Box bgColor={THEME_COLORS.primary[100]} px={6} py={4}>
      <Flex direction="column" mb={5}>
        <Text
          fontSize={14}
          color={THEME_COLORS.secondary[700]}
          textTransform={'uppercase'}
          fontWeight={800}
        >
          Issue Title
        </Text>
        <Text>{values.title}</Text>
      </Flex>
      <Flex w="100%" direction="row" mb={5}>
        <Flex flex={1} direction="column">
          <Text
            fontSize={14}
            color={THEME_COLORS.secondary[700]}
            textTransform={'uppercase'}
            fontWeight={800}
          >
            Status Page
          </Text>
          <Text>{values.pageName}</Text>
        </Flex>
        <Flex flex={1} direction="column">
          <Text
            fontSize={14}
            textTransform={'uppercase'}
            color={THEME_COLORS.secondary[700]}
            fontWeight={800}
          >
            Page status message
          </Text>
          <Text>{values.status?.label}</Text>
        </Flex>
      </Flex>
      <Divider />

      <Flex direction="column" mt={5}>
        <Text
          fontSize={14}
          color={THEME_COLORS.secondary[700]}
          textTransform={'uppercase'}
          fontWeight={800}
          pb={2}
        >
          Select Component and Impact
        </Text>
        {(values.selectedComponents || []).map((c: ISelectedComponent) => {
          return (
            <Flex key={c.id} mt={2}>
              <Flex flex={1}>
                <Text
                  fontSize={14}
                  textTransform={'uppercase'}
                  color={THEME_COLORS.secondary[700]}
                  fontWeight={800}
                >
                  {c.label}
                </Text>
              </Flex>
              <Flex flex={2}>
                <Text>{c.value.label}</Text>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
};
