import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  createStandaloneToast,
  Divider,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Text,
  Link,
} from '@chakra-ui/react';
import { IIntegration } from 'core/interfaces/IIntegration';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { HorzStepper, Layout } from 'views/shared/components';

import { OrganizationContext } from '../..';
import HelpGuide from '../../common/HelpGuide';
import { SERVICE_CREATION_STEP } from '../../contants/service.contant';
import { alertSourceHelpGuide } from '../../contants/service.guide';
import { commonAddAlertSource } from '../../helpers/helper.service';
import { useAddAlertSource } from '../../hooks/useAddAlertSource';
import { useAlertSourceList } from '../../hooks/useAlerSourceList';
import { useIntegrationList } from '../../hooks/useIntegrationList';
import { useServiceDetailQuery } from '../../hooks/useServiceDetail';
import { IAlertSource, Integrations } from '../../Interfaces/intigration';
import AddAlertSourceListing from '../addAlertSource/addAlertSourceListingCards';
import AlertSource from './AlertSource';

interface Props {
  integrations: Integrations;
}

const AddAlertSource = (props: Props) => {
  const history = useHistory();
  const toast = createStandaloneToast();
  const { serviceId } = useParams<{ serviceId: string }>();

  const organization = useContext(OrganizationContext);
  const [selected, setSelected] = useState<string[]>([]);
  const [integrationData, setIntegrationData] = useState<any>();
  const [topFiltered, setTopFiltered] = useState<IAlertSource[] | IIntegration[]>([]);
  const { addalertsource_, addAlertSourceData } = useAddAlertSource();
  const { integrationListApiData } = useIntegrationList();
  const { alertSourceList }: { alertSourceList: IAlertSource[] } = useAlertSourceList();
  const { data: serviceDetail, refetch: refetchKey } = useServiceDetailQuery(serviceId);

  const alertSources: IAlertSource[] | IIntegration[] = props?.integrations?.integrations?.i
    .filter((as: IAlertSource | IIntegration) => !(as.deprecated || as.isPrivate))
    .map((as: IAlertSource | IIntegration) => ({
      isActive: false,
      ...as,
    }));
  const [showableAlertSource, setShowableAlertSource] = useState<any>(alertSources);
  const [alertSourcesList, setAlertSourcesList] = useState<IAlertSource[] | IIntegration[]>([]);
  const [alertSourcesListFilteration, setAlertSourcesListFilteration] = useState<IIntegration[]>();

  /** Search Result render  */
  const filteredAlertSourcessData = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && alertSourcesListFilteration) {
      const filtered = alertSourcesListFilteration.filter((el: any, idx: number) => {
        return el.type.toUpperCase().includes(e.target.value.toUpperCase());
      });

      const filteredEmailAndWebhook = alertSourcesListFilteration.filter((el: any, idx: number) => {
        return ['email', 'api'].includes(el.shortName);
      });

      const filteredResult = filtered.length ? filtered : filteredEmailAndWebhook;

      setShowableAlertSource(filteredResult);
    } else {
      if (alertSourcesListFilteration) {
        setAlertSourcesList(alertSourcesListFilteration);
        setShowableAlertSource(alertSourcesListFilteration);
      } else {
        setShowableAlertSource(alertSourceList.filter((as: any) => !as.isPrivate));
      }
    }
  };

  useEffect(() => {
    if (alertSources) {
      setAlertSourcesList(alertSources);
      setShowableAlertSource(alertSources);
      setAlertSourcesListFilteration(alertSources);

      const filteredArr = [
        'Incident Webhook',
        'Amazon CloudWatch',
        'Prometheus',
        'Datadog',
        'Grafana',
        'Uptime Robot',
        'New Relic',
        'Sentry',
        'Google Cloud Operations (formerly Stackdriver)',
        'Freshping',
        'Slack',
        'Email',
      ]
        .map((data: string) => alertSources.find((alert: any) => alert.type === data))
        .filter(Boolean);
      setTopFiltered(filteredArr as IIntegration[]);
    }
  }, []);

  const goToServiceListingPage = () => {
    const payload = {
      alert_sources: selected,
      teamId: organization?.selectedTeam?.teamId,
      api_key: serviceDetail?.api_key,
      serviceId: serviceDetail?.id,
    };
    addalertsource_(payload);
  };

  const addAlertSource = (id: string) => {
    const data: any = commonAddAlertSource(id, selected);
    if (data.length > 0) {
      setSelected(data);
      setAlertSourcesList(alertSourcesList);
    } else {
      setSelected(data);
      setAlertSourcesList(alertSourcesList);
    }
  };

  const isFallbackSearchForAlerts = (showableAlertSource: any): boolean => {
    const isEmail = showableAlertSource.find((alert: any) => alert.shortName === 'email');
    const isWebhook = showableAlertSource.find((alert: any) => alert.shortName === 'api');
    return showableAlertSource && showableAlertSource.length == 2 && isEmail && isWebhook;
  };

  useEffect(() => {
    let integrationFilteredData: IAlertSource[] | IIntegration[] = [];
    if (integrationData && alertSourceList) {
      integrationFilteredData = alertSourceList?.filter(
        (data: IAlertSource) =>
          !data.isPrivate && !!data?.categories?.find((item: string) => item == integrationData),
      );
      setShowableAlertSource(integrationFilteredData);
      setAlertSourcesListFilteration(integrationFilteredData);
    } else {
      setShowableAlertSource(alertSources);
      setAlertSourcesListFilteration(alertSources);
    }
  }, [integrationData, integrationListApiData]);
  const filterMapdata = useMemo(() => {
    return (
      <AddAlertSourceListing
        alertSourcesList={showableAlertSource}
        api_key={serviceDetail?.api_key}
        serviceId={serviceDetail?.id}
        addAlertSource={addAlertSource}
        selected={selected}
        email={serviceDetail?.email}
        refetchKey={refetchKey}
      />
    );
  }, [showableAlertSource, selected, serviceDetail]);

  const helpSupportWidth = 385;
  const serviceFormWidth = `calc(100% - ${helpSupportWidth + 12}px)`;

  return (
    <Layout>
      <HorzStepper steps={SERVICE_CREATION_STEP} currentStep={1} />
      <Divider variant="smokeShaded" />

      <HStack spacing={3} alignItems="flex-start">
        <Box
          pos="relative"
          sx={{
            width: serviceFormWidth,
            '&': {
              borderRightWidth: '15px',
              borderRightStyle: 'solid',
              borderRightColor: 'gray.200',
            },
          }}
        >
          <Box maxH="calc(100vh - 190px)" overflow="auto">
            <Box paddingY={4} paddingX={6}>
              <Box paddingY={6}>
                <Box fontSize="24px" fontWeight={400}>
                  Configure Your Alert Sources
                </Box>
                <Box fontSize="14px" fontWeight={400}>
                  Configure your alert sources to send in actionable and meaningful alerts. Use the
                  integration guides to understand how to set them up.
                </Box>
              </Box>
              <Box fontSize="20px" fontWeight={400}>
                Commonly Configured Alert Sources
              </Box>
              <Box fontSize="16px" fontWeight={400} color="#4A5568">
                Showing {topFiltered && topFiltered?.length} out of{' '}
                {alertSources ? alertSources.length : null} alert sources
              </Box>
              <Box width="100%" marginTop="20px" display="flex" flexDirection="row" flexWrap="wrap">
                {!!topFiltered &&
                  topFiltered.map((item: IAlertSource | IIntegration, indx: number) => {
                    return (
                      <Box key={indx} width="20%" padding="5px">
                        <AlertSource
                          name={item.type}
                          shortName={item.shortName}
                          id={item._id}
                          version={item.version}
                          api_key={serviceDetail?.api_key}
                          serviceId={serviceDetail?.id}
                          supportDoc={item.supportDoc}
                          type={item.type}
                          addAlertSource={addAlertSource}
                          selected={selected}
                          email={serviceDetail?.email}
                          refetchKey={refetchKey}
                        />
                      </Box>
                    );
                  })}
              </Box>
            </Box>

            <Divider />

            <Box
              display="flex"
              flexDirection="row"
              paddingY={4}
              paddingX={6}
              paddingTop="20px"
              paddingBottom="30px"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box paddingRight="20px">
                <Text whiteSpace="nowrap">All Alert Sources</Text>
              </Box>
              <Box display="flex" flexDirection="row">
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>
                  <Input placeholder="Search" onChange={filteredAlertSourcessData} />
                </InputGroup>

                <Select
                  placeholder="All Integrations"
                  marginLeft={5}
                  variant="filled"
                  onChange={e => {
                    if (e.target.value) setIntegrationData(e.target.value);
                    else setIntegrationData('');
                  }}
                >
                  {integrationListApiData ? (
                    integrationListApiData?.map(
                      (item: { name: string; slug: string }, indx: number) => {
                        return (
                          <option key={indx} value={item.slug}>
                            {item.name}
                          </option>
                        );
                      },
                    )
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="row"
                      py="8"
                    >
                      <Text sx={{ padding: '10px' }}>
                        Do you need an integration that isn't listed here?{' '}
                        <Link
                          className="installation-guide-design no-select"
                          target="_blank"
                          href="mailto:support@squadcast.com"
                          rel="noopener noreferrer"
                        >
                          Drop us a note
                        </Link>{' '}
                        and we'll get back to you with an update!
                      </Text>
                    </Box>
                  )}
                </Select>
              </Box>
            </Box>

            <Box
              paddingX={6}
              height={showableAlertSource && showableAlertSource?.length > 0 ? '48vh' : 'auto'}
              overflowY="auto"
              overflowX="hidden"
              paddingBottom="50px"
            >
              {showableAlertSource && showableAlertSource?.length ? (
                <>
                  {filterMapdata}
                  {isFallbackSearchForAlerts(showableAlertSource) && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="row"
                      py="8"
                    >
                      <Text sx={{ padding: '10px' }}>
                        Have more questions around alert sources? Use the chat widget below to reach
                        us.
                      </Text>
                    </Box>
                  )}
                </>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="row"
                  py="8"
                >
                  <Text sx={{ padding: '10px' }}>
                    Have more questions around alert sources? Use the chat widget below to reach us.
                  </Text>
                </Box>
              )}
            </Box>
          </Box>

          <HStack
            paddingY={2}
            paddingX={2}
            borderTopWidth={1}
            borderTopStyle="solid"
            borderTopColor="gray.300"
            pos="absolute"
            bgColor="white"
            bottom="0"
            w="95%"
          >
            <Button onClick={goToServiceListingPage} variant="default" size="sm">
              Save All
            </Button>
            <Button
              onClick={() => history.push('/service-catalog')}
              variant="outline"
              ml={3}
              size="sm"
            >
              Cancel
            </Button>
          </HStack>
        </Box>

        <HelpGuide helpSupportWidth={helpSupportWidth} helpGuide={alertSourceHelpGuide} />
      </HStack>
    </Layout>
  );
};

export default connect((integrations: Integrations) => ({
  integrations,
}))(AddAlertSource);
