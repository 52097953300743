const SQ_UNLEASH_CONTEXT = 'SQ_UNLEASH_CONTEXT';

enum UNLEASH_SETUP {
  INITIALIZED = 'INITIALIZED',
  CONTEXT_UPDATED = 'CONTEXT_UPDATED',
}

export const initializedUnleash = () => {
  localStorage.setItem(SQ_UNLEASH_CONTEXT, UNLEASH_SETUP.INITIALIZED);
};

export const updatedUnleashContext = () => {
  localStorage.setItem(SQ_UNLEASH_CONTEXT, UNLEASH_SETUP.CONTEXT_UPDATED);
};

export const canQueryUnleash = () => {
  return localStorage.getItem(SQ_UNLEASH_CONTEXT) === UNLEASH_SETUP.CONTEXT_UPDATED;
};
