import { IAppPreviewType } from '../../../../core/interfaces/IAppPreview';

interface IAppPreviewData {
  type: IAppPreviewType;
  name: string;
  desc: string;
  image: string;
}

export const appPreviewsData: IAppPreviewData[] = [];
