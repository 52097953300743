import { AnyObject } from 'library/types';
import { createContext, Dispatch } from 'react';

export enum ActionKind {
  SET_SEARCH_TERM = 'SET_SEARCH_TERM',
  SET_FILTER = 'SET_FILTER',
  SET_PAGE_INDEX = 'SET_PAGE_INDEX',
  SET_LIMIT = 'SET_LIMIT',
  SET_PERMISSIONS = 'SET_PERMISSIONS',
}

// An interface for our actions
export interface GERAction {
  type: ActionKind;
  payload?: AnyObject;
}

// An interface for our state
export interface GERState {
  search: {
    term: string;
  };
  filter: {
    ownerIds: string[];
  };
  pageNumber: number;
  limit: number;
  permissions: {
    canCreate: boolean;
    canRead: boolean;
    canUpdate: boolean;
    canDelete: boolean;
  };
}

export const INITIAL_GER_STATE = {
  search: {
    term: '',
  },
  filter: {
    ownerIds: [],
  },
  pageNumber: 0,
  limit: 10,
  permissions: {
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
};

export interface GERContextType {
  state: GERState;
  dispatch: Dispatch<{
    type: ActionKind;
    payload?: AnyObject;
  }>;
}

export const GERContext = createContext<GERContextType>({
  state: INITIAL_GER_STATE,
  dispatch: () => null,
});
