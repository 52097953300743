import { HStack, Text, VStack } from '@chakra-ui/layout';
import { THEME_COLORS } from 'library/theme/colors';
import { FC } from 'react';
import { isSameDayEvent } from 'views/main/organization/schedules/helpers/helpers.event';
import { TimeSelector } from './timeSelector';

type Props = {
  startTime?: string;
  endTime?: string;
  onStartTimeUpdate: (time: string) => void;
  onEndTimeUpdate: (time: string) => void;
  renderlabel: boolean;
};
export const SlotPicker: FC<Props> = ({
  startTime,
  endTime,
  onStartTimeUpdate,
  onEndTimeUpdate,
  renderlabel = false,
}) => {
  const isSameDay = isSameDayEvent(startTime, endTime);
  const onTimeUpdate = (timeStamp: string, isStartTime: boolean) => {
    isStartTime ? onStartTimeUpdate(timeStamp) : onEndTimeUpdate(timeStamp);
  };

  return (
    <HStack alignItems="flex-end" spacing={2}>
      <VStack alignItems="flex-start">
        {renderlabel && (
          <Text fontWeight="800" fontSize="12px" color={THEME_COLORS.secondary[600]}>
            START TIME
          </Text>
        )}
        <TimeSelector time={startTime} onTimeUpdate={time => onTimeUpdate(time, true)} />
      </VStack>
      <VStack alignItems="flex-start">
        {renderlabel && (
          <Text fontWeight="800" fontSize="12px" color={THEME_COLORS.secondary[600]}>
            END TIME
          </Text>
        )}
        <TimeSelector time={endTime} onTimeUpdate={time => onTimeUpdate(time, false)} />
      </VStack>
      <Text
        pb="8px"
        visibility={isSameDay ? 'hidden' : 'visible'}
        color={THEME_COLORS.secondary[700]}
      >
        +1
      </Text>
    </HStack>
  );
};
