import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Checkbox, Flex } from '@chakra-ui/react';
import React from 'react';
import { IOption } from '../../Interface';

type IRender = (opt: IOption[]) => JSX.Element;

interface Props {
  options: IOption[];
  setOptions: (opt: IOption[]) => void;
  customRender?: IRender;
  testId?: string;
}

const updateChildOptions = (options: IOption[] = [], isChecked: boolean) => {
  if (options.length > 0) {
    options.forEach((opt: IOption) => {
      opt.checked = isChecked;
      updateChildOptions(opt.options, isChecked);
    });
  }
};

const updateState = (
  options: IOption[] = [],
  value: string,
  isChecked: boolean,
  parentIndices: number[],
  isCollapsilble = false,
) => {
  if (parentIndices.length === 0) {
    const op: IOption = (options || []).filter(opt => opt.value === value)[0];
    if (isCollapsilble) {
      op.isOpen = !op.isOpen;
    } else {
      op.checked = isChecked;
      updateChildOptions(op?.options, isChecked);
    }
  } else {
    const currentParentId = parentIndices.shift() || 0;
    updateState(options[currentParentId].options, value, isChecked, parentIndices, isCollapsilble);
    if (!isCollapsilble) {
      options[currentParentId].checked = (options[currentParentId].options || []).every(
        opt => opt.checked,
      );
    }
  }
};
export const GroupSelect = (props: Props) => {
  const { options, setOptions, customRender } = props;

  const checkIndeterminate = (options: IOption[] = []): boolean => {
    if (options.length > 0) {
      return options.some(o => o.checked) && !options.every(o => o.checked);
    }
    return false;
  };
  const onChange = (
    value: string,
    isChecked: boolean,
    parentIndices: number[] = [],
    isCollapsilble = false,
  ) => {
    const updatedOptions = [...options];
    updateState(updatedOptions, value, isChecked, parentIndices, isCollapsilble);

    setOptions(updatedOptions);
  };

  const renderOption = (opt: IOption[], parentIndices: number[]) => {
    return (
      <Flex direction="column" ml={6}>
        {opt.map((o: IOption, index: number) => {
          return !o.isHidden ? (
            <Box key={o.value}>
              <Flex mb={2}>
                <Checkbox
                  onChange={e => {
                    onChange(o.value, e.target.checked, parentIndices);
                  }}
                  isChecked={o.checked}
                  isIndeterminate={checkIndeterminate(o.options)}
                >
                  {o.label}
                </Checkbox>
                {o.isCollapsible ? (
                  <Box
                    onClick={e => {
                      e.stopPropagation();
                      onChange(o.value, false, parentIndices, true);
                    }}
                    ml={1}
                  >
                    {o.isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
                  </Box>
                ) : null}
              </Flex>
              {o.options && o.isOpen ? renderOption(o.options, [...parentIndices, index]) : null}
            </Box>
          ) : null;
        })}
      </Flex>
    );
  };

  const defaultRender: IRender = (opt: IOption[]) => {
    return renderOption(opt, []);
  };

  const render: IRender = customRender || defaultRender;

  return <div data-testid={props.testId}>{render(options)}</div>;
};
