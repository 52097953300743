import * as React from 'react';
import { SVGProps } from 'react';

const SvgSumoLogic = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#009',
      }}
      d="M0 0h20v20H0z"
    />
    <path
      d="M6.163 4.792c-.877 0-1.462.378-1.662.899v.889c.153.405.569.618 1.462.813l.611.139c.174.05.325.128.386.26v.285c-.086.194-.348.296-.696.296a1.64 1.64 0 0 1-.677-.13c-.204-.081-.448-.244-.75-.487l-.612.684c.66.587 1.223.791 2.014.791.901 0 1.517-.361 1.716-.933v-.884c-.169-.45-.638-.674-1.549-.876a12.473 12.473 0 0 1-.611-.148c-.171-.048-.288-.111-.335-.208v-.239c.076-.183.326-.294.677-.294.448 0 .758.106 1.248.487l.57-.696c-.574-.476-1.074-.648-1.792-.648zm8.401.14v2.512c0 .563-.326.864-.856.864-.457 0-.696-.261-.696-.696v-2.68h-1.057v2.976c0 .807.487 1.323 1.476 1.323.595 0 1.003-.212 1.223-.571v.44h.97V4.931l-1.06.001zm-5.481 5.841c-.547 0-.963.204-1.248.611-.228-.391-.627-.611-1.157-.611-.547 0-.946.204-1.191.604v-.465h-.986v4.166h1.06v-2.56c0-.487.269-.832.734-.832.44 0 .66.269.66.799v2.593h1.06v-2.56c0-.487.269-.832.734-.832.465 0 .66.302.66.799v2.593h1.06v-2.911c-.001-.857-.506-1.395-1.386-1.394zm6.694 1.339c-.307-.824-1.058-1.34-2.009-1.34s-1.71.515-2.019 1.34v1.756c.308.825 1.06 1.34 2.019 1.34s1.702-.515 2.009-1.34v-1.756zm-2.998.265c.165-.439.519-.707.99-.707s.816.268.981.707v1.235c-.165.439-.517.696-.98.696s-.824-.26-.99-.696l-.001-1.235z"
      style={{
        fill: '#fff',
      }}
    />
  </svg>
);

export default SvgSumoLogic;
