import { Box, Center, Spinner } from '@chakra-ui/react';
import { FocusBlock, Para, SelectBox } from 'uie/components';
import { IExtensionMSTeams, IExtensionMsTeamsGraphApiUser } from 'core/interfaces/IExtensions';
import ExtensionService from 'core/services/service.extension';
import { FormikErrors } from 'formik';
import { useState } from 'react';
import { useQuery } from 'react-query';
import styles from './index.module.css';

export interface IMSTeamsMeetingLinkUserDropdownProps {
  from_id: string;
  checkAndSetDirty: () => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<IExtensionMSTeams>> | Promise<void>;
}

const MSTeamsMeetingLinkUserDropdown = (props: IMSTeamsMeetingLinkUserDropdownProps) => {
  const [msteamsUserSearch, setMsteamsUserSearch] = useState<string>('');

  const userSearchFilter = (user: IExtensionMsTeamsGraphApiUser) => {
    if (msteamsUserSearch === '') {
      return true;
    } else {
      const query = msteamsUserSearch.toLowerCase();
      const name = user.displayName.toLowerCase();
      const email = user.mail.toLowerCase();

      return name.includes(query) || email.includes(query);
    }
  };

  const searchFilteredMSTeamsUsers: () => IExtensionMsTeamsGraphApiUser[] = () => {
    if (msteamsUsers) {
      return msteamsUsers.filter(userSearchFilter).slice(0, 5);
    }

    return [];
  };

  const getMSTeamsUsers = async () => {
    const res = await new ExtensionService().getMSTeamsGraphAPIUserList();

    return res.data.data;
  };

  const { data: msteamsUsers, isLoading } = useQuery('msteams-extension-users', getMSTeamsUsers);
  const findUserById = () => {
    if (props.from_id !== '' && msteamsUsers?.length) {
      return msteamsUsers?.find(user => user.id === props.from_id);
    }
    return { displayName: 'No user selected' };
  };

  if (isLoading) {
    return (
      <Center display={'block'}>
        <Box
          flexDirection="row"
          justifyContent="center"
          width={'300px'}
          display="flex"
          marginTop="20px"
        >
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" />
        </Box>
      </Center>
    );
  }

  return (
    <div className={styles.meetingLinkUserDropdown}>
      <SelectBox
        width="400px"
        height="auto"
        maxHeight="200px"
        hook={<Para fontSize={14}>{findUserById()?.displayName}</Para>}
        searchHookProps={{
          placeholder: 'Search Microsoft Teams Users',
          value: msteamsUserSearch,
          height: '24px',
          fontSize: '16px',
          onChange: e => setMsteamsUserSearch(e.target.value),
        }}
        onValueChange={(e: any, value: any) => {
          props.setFieldValue('from_id', value);
          props.checkAndSetDirty();
        }}
      >
        {msteamsUsers ? (
          searchFilteredMSTeamsUsers().map(user => (
            <FocusBlock key={user.id} value={user.id}>
              {`${user.displayName} (${user.mail})`}
            </FocusBlock>
          ))
        ) : (
          <FocusBlock key={-1} value={-1}>
            Loading...
          </FocusBlock>
        )}
      </SelectBox>
    </div>
  );
};

export default MSTeamsMeetingLinkUserDropdown;
