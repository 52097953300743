import request from '../commons';
import { API } from '../../api';
import { AppConfig } from 'shared/app.config';

function getLoginSession(email: string, password: string) {
  const req = request.post(API.config.batman)('accounts/login', {
    email,
    password,
  });
  return req;
}

function getUserInfo() {
  const req = request.getWithCredentials(API.config.batman)('me');

  return req;
}

function postDeviceId(deviceId: string, userId: string, deviceOS: string) {
  const req = request.post(`${AppConfig.internal_api_url}.com/save-device-id-for-a-user`)('', {
    deviceId,
    deviceOS,
    _id: userId,
  });
  return req;
}

function logoutUser() {
  const req = request.post(API.config.endpoint)('accounts/logout', {
    session_token: API.config.sessionId,
  });

  return req;
}

export { getLoginSession, getUserInfo, logoutUser, postDeviceId };
