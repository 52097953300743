import { Box, DrawerFooter, Text, Flex, Divider } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useMutateWorkflow } from '../../hooks/useMutateWorkflow';
import { UseMutationResult, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../../../constant';
import { workflowList } from '../../../context/workflowListContext';
import { tagsValidation } from './validation';
import { WorkFlowUpdateAPIParams } from '../../../api';
import { useWorkflowTagsQuery } from '../../../api/hook';
import { useWorkflowFormContext } from '../../../context/workflowFormContext';
import { FormButton, useToast } from 'library/atoms';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import { FormTag } from 'library/molecules/Form/TagInput/FormTag';
import { Tag } from 'library/molecules/Form/TagInput/useFormTag';

const FORM_TAG_WIDTH = {
  key: 'full',
  value: 'full',
  color: 100,
};

interface TagsFormProps {
  currentTags: Tag[];
  closeDrawer: () => void;
  id: string;
}
type TTagsForm = {
  tags: Tag[];
};

const TagsForm: React.FC<TagsFormProps> = ({ currentTags, closeDrawer, id }) => {
  const { isFormDirty } = useWorkflowFormContext();

  const mutation = useMutateWorkflow();
  const queryClient = useQueryClient();
  const toast = useToast();

  const onSubmit = async ({ tags }: { tags: Tag[] }) => {
    try {
      await mutation.mutateAsync({ id, formDirty: isFormDirty, partialUpdate: { tags: tags } });
      await queryClient.invalidateQueries([QUERY_KEYS.WORKFLOW, `${id}`]);
      await queryClient.invalidateQueries(workflowList);
      await queryClient.invalidateQueries('workflowTags');

      toast({ text: 'Tag updated successfully', status: 'success' });
      closeDrawer();
    } catch (e) {
      toast({ text: 'Something went wrong!', status: 'error' });
    }
    mutation.mutate({ id, partialUpdate: { tags } });
  };

  return (
    <FormWrapper<TTagsForm>
      defaultValues={{
        tags: currentTags,
      }}
      validationSchema={tagsValidation}
      onSubmit={onSubmit}
    >
      <FormContent mutation={mutation} closeDrawer={closeDrawer} />
    </FormWrapper>
  );
};

export default TagsForm;

function FormContent({
  mutation,
  closeDrawer,
}: {
  mutation: UseMutationResult<any, unknown, WorkFlowUpdateAPIParams, unknown>;
  closeDrawer: () => void;
}) {
  const { setValue, formState, watch } = useFormContext<TTagsForm>();
  const { data: tagsList = [] } = useWorkflowTagsQuery();

  const errors = formState?.errors?.tags?.map?.(err => {
    return {
      key: err?.message || err?.key?.message,
      value: err?.value?.message,
    };
  });

  const handleTagUpdate = (tags: Tag[]) => {
    setValue('tags', tags, {
      shouldDirty: true,
    });
  };
  const watchTags = watch('tags');

  const sanitizedTags = tagsList.filter(tag => tag.key && tag.values.length > 0);

  return (
    <Flex height="100%" justifyContent="space-between" flexDirection="column">
      <Box p={6}>
        <FormTag
          width={FORM_TAG_WIDTH}
          onTagUpdate={handleTagUpdate}
          initialValue={watchTags}
          isTagCreatable
          error={errors}
          tagsList={sanitizedTags}
        />
      </Box>
      <Flex direction="column">
        <Divider />
        <DrawerFooter gap="10px" justifyContent="flex-start">
          <FormButton
            variant="primary"
            type="submit"
            aria-label="submit"
            isLoading={mutation.isLoading}
            disabled={!formState.isDirty}
            data-testid="submit"
            title="Save"
          />
          <FormButton variant="secondary" onClick={closeDrawer} title="Cancel" />
        </DrawerFooter>
      </Flex>
    </Flex>
  );
}
