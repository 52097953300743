import React, { useState, useMemo } from 'react';
import { FocusBlock, Grid, InputBlock, Para, SelectBox } from '..';
import { availableFilterOperations, inputableList, selectableList } from './helper';
import {
  IAdvancedFilterBlockFilter,
  IAdvancedFilterBlockOptions,
  IAdvancedFilterFilterOperations,
} from './types';

interface IFilterProps {
  filter: IAdvancedFilterBlockFilter;
  updateFilter: (filter: IAdvancedFilterBlockFilter) => void;
  options: IAdvancedFilterBlockOptions;
  cellHeight: number | 36;
}

const FilterBlock: React.FC<IFilterProps> = ({ filter, options, updateFilter, cellHeight }) => {
  const searchOptions = useMemo(() => {
    const filterOptions = options[filter.lhs]?.options;

    if (!filterOptions) return [];

    return Object.keys(filterOptions).reduce<{ name: string; id: string }[]>((acc, key) => {
      acc.push({
        name: filterOptions[key],
        id: key,
      });

      return acc;
    }, []);
  }, [options, filter.lhs]);

  const [searchTerm, setSearchTerm] = useState('');
  const [inputVal, setInputVal] = useState(filter.rhs);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const onUpdateFilter = (type: keyof IAdvancedFilterBlockFilter) => (_: any, value: string) => {
    const newFilter = { ...filter };

    if (type === 'op') {
      newFilter.op = value as IAdvancedFilterFilterOperations;

      if (inputableList.includes(value)) {
        newFilter.rhs = '';
      } else if (selectableList.includes(value)) {
        if (options[filter.lhs]) newFilter.rhs = Object.keys(options[filter.lhs].options)[0];
      }
    } else if (type === 'lhs') {
      newFilter.lhs = value;
      if (options[value]) {
        const newOp = options[value].operations[0];
        newFilter.rhs = inputableList.includes(newOp) ? '' : Object.keys(options[value].options)[0];
        newFilter.op = newOp;
      }
    } else {
      newFilter[type] = value;
    }

    updateFilter(newFilter);
  };

  const onRhsInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdateFilter('rhs')('', event.target.value);
  };

  const onInputUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputVal(event.target.value);
  };

  const selectedOption = options[filter.lhs];

  const rhsBlock = selectableList.includes(filter.op) ? (
    <SelectBox
      hook={<Para fontSize={16}>{selectedOption.options[filter.rhs]}</Para>}
      onValueChange={onUpdateFilter('rhs')}
      height="auto"
      maxHeight="200px"
      buttonHeight={`${cellHeight ?? 32}px`}
    >
      <InputBlock
        value={searchTerm}
        onChange={onSearch}
        height="32px"
        style={{ margin: '8px' }}
        placeholder="Search Values"
        autoFocus={true}
      />
      {searchOptions
        .filter(op => (op.name?.toLowerCase() ?? '')?.includes(searchTerm.toLowerCase()))
        .map(op => (
          <FocusBlock key={op.id} value={op.id} isSelected={op.id === filter.rhs}>
            {op.name ?? ''}
          </FocusBlock>
        ))}
    </SelectBox>
  ) : (
    <InputBlock
      value={inputVal}
      onChange={onInputUpdate}
      height={`${cellHeight ?? 32}px`}
      onBlur={onRhsInputChange}
      placeholder="Value"
    />
  );

  return (
    <Grid>
      <Grid style={{ marginRight: '12px' }}>
        <SelectBox
          hook={<Para fontSize={16}>{selectedOption.name}</Para>}
          onValueChange={onUpdateFilter('lhs')}
          height="auto"
          maxHeight="200px"
          buttonHeight={`${cellHeight ?? 32}px`}
        >
          {Object.keys(options).map(op => (
            <FocusBlock key={op} value={op} isSelected={op === filter.lhs}>
              {options[op].name}
            </FocusBlock>
          ))}
        </SelectBox>
      </Grid>

      <Grid>
        <SelectBox
          hook={<Para fontSize={16}>{availableFilterOperations[filter.op]}</Para>}
          onValueChange={onUpdateFilter('op')}
          height="auto"
          maxHeight="200px"
          width="auto"
          dropdownWidth="150px"
          buttonHeight={`${cellHeight ?? 32}px`}
        >
          {selectedOption.operations.map(o => (
            <FocusBlock key={o} value={o} isSelected={o === filter.op}>
              {availableFilterOperations[o]}
            </FocusBlock>
          ))}
        </SelectBox>
      </Grid>

      <Grid style={{ marginLeft: '12px' }}>{rhsBlock}</Grid>
    </Grid>
  );
};

export default FilterBlock;
