import { Box, Flex, Text } from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { HttpCallAction } from 'views/main/organization/workflow/types';
import { CrossIcon } from 'icons';
import FormField from 'library/molecules/FormField/FormField';
import { Input } from 'library/atoms';

const HttpCallFormHeader = () => {
  const {
    formState: { errors },
    register,
    watch,
    setValue,
  } = useFormContext<HttpCallAction>();
  const { fields, append, remove } = useFieldArray<HttpCallAction>({
    name: 'data.headers',
  });
  return (
    <Box mt={4}>
      <FormField label="Additional Header">
        {fields.map((headers, index) => {
          return (
            <Flex key={index} gap={3} mb={3} flex={1}>
              <FormField
                error={
                  errors?.data?.headers?.[index]?.message ??
                  errors.data?.headers?.[index]?.key?.message
                }
              >
                <Input
                  defaultValue={watch(`data.headers.${index}.key`)}
                  onChange={e =>
                    setValue(`data.headers.${index}.key`, e.target.value, { shouldDirty: true })
                  }
                  placeholder="Enter Key"
                />
              </FormField>

              <FormField
                error={
                  errors?.data?.headers?.[index]?.message ??
                  errors?.data?.headers?.[index]?.value?.message
                }
              >
                <Input
                  defaultValue={watch(`data.headers.${index}.value`)}
                  onChange={e =>
                    setValue(`data.headers.${index}.value`, e.target.value, { shouldDirty: true })
                  }
                  placeholder="Enter Value"
                />
              </FormField>
              <CrossIcon
                onClick={() => {
                  if (fields.length === 1) {
                    setValue(`data.headers.${0}.key`, '');
                    setValue(`data.headers.${0}.value`, '', { shouldDirty: true });
                  } else {
                    remove(index);
                  }
                }}
                cursor={'pointer'}
              />
            </Flex>
          );
        })}
        <Box onClick={() => append({ key: '', value: '' })} cursor={'pointer'}>
          <Text color="brand.blue" fontWeight={'800'} fontSize="12px">
            Add Additional Header
          </Text>
        </Box>
      </FormField>
    </Box>
  );
};

export default HttpCallFormHeader;
