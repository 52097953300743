export enum AUDITLOGS_TABS {
  LIVE_LOGS = 'live logs',
  EXPORT_HISTORY = 'export history',
}

export interface AuditLogsData {
  data: {
    id: string;
    resource: string;
    action: string;
    actor: {
      userID: string;
      userEmail: string;
      userName: string;
      fullName: string;
    };
    client: string;
    team: {
      id: string;
      name: string;
    };
    timeStamp: string;
  }[];
  metadata: {
    totalCount: number;
  };
}
