import Axios from 'axios';
import { API } from 'core';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys, reactQueryConfig } from '../constant';
import { IUpdateConfigForAccountProps } from '../../../../../../../core/interfaces/IJiraNew';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/service.reactqery';

const updateConfigForAccount = ({
  config_id,
  account_id,
  ...data
}: IUpdateConfigForAccountProps) => {
  return Axios.put(
    `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud/new/${account_id}/jira-cloud-configs/${config_id}`,
    {
      ...data,
    },
  );
};

const useUpdateConfigForAccount = () => {
  const queryClient = useQueryClient();

  return useMutation([queryKeys.CREATEJIRACLOUDSACCOUNTSCONFIG], updateConfigForAccount, {
    ...reactQueryConfig,
    onSuccess: () => {
      queryClient.refetchQueries([queryKeys.GETALLJIRACLOUDACCOUNTSCONFIG]);
      reactQueryConfigSuccess('Jira Cloud Account config Updated successfully!');
    },
    onError: (error: any) => {
      const description =
        error?.response?.data?.meta?.error_message || 'Failed to Updated Jira Cloud Account config';
      reactQueryConfigError({ description: description, title: 'Update Jira Account Config' });
    },
  });
};
export { useUpdateConfigForAccount };
