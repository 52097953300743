import { Flex } from '@chakra-ui/layout';
import { Text } from 'library/atoms/Text';
import { CountriesKey, NumberTypeKeys, RoutingTypeKeys } from '../../../common/constant';
import { ILCRData } from '../../../common/interface';

interface IProps {
  data: ILCRData;
}

const LCRHeaderSummary = ({ data }: IProps) => {
  const tabHeader = [
    {
      key: 'COUNTRY',
      value: CountriesKey[data.country],
    },
    {
      key: 'ROUTING NUMBER',
      value: data.number,
    },
    {
      key: 'ROUTING TYPE',
      value: RoutingTypeKeys[data.routing_type],
    },
    {
      key: 'NUMBER TYPE',
      value: NumberTypeKeys[data.type],
    },
    {
      key: 'INCIDENTS',
      value: data.incident_count,
    },
  ];
  return (
    <Flex
      borderTop="1px"
      borderTopColor="secondary.200"
      borderTopStyle="solid"
      px="6"
      py="6"
      bg={'container.background'}
      justifyContent="space-between"
    >
      {tabHeader.map((data, i) => (
        <Flex key={i} flexDirection="column" alignItems="self-start">
          <Text variant="h4_800" color="secondary.700">
            {data.key}
          </Text>
          <Text variant="h4" color="secondary.1000">
            {data.value}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default LCRHeaderSummary;
