import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.st0{fill-rule:evenodd;clip-rule:evenodd;fill:url(#Rectangle_00000052101455046709242580000004498654453358693815_)}'
      }
    </style>
    <g id="Icon-Architecture_x2F_64_x2F_Arch_x5F_Amazon-EC2-Auto-Scaling_x5F_64">
      <g id="Icon-Architecture-BG_x2F_64_x2F_Compute">
        <linearGradient
          id="Rectangle_00000155108627635854869410000017590581449879399351_"
          gradientUnits="userSpaceOnUse"
          x1={-1297.575}
          y1={-20.8}
          x2={-1297.325}
          y2={-21.05}
          gradientTransform="matrix(80 0 0 80 103806 1684)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#c8511b',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#f90',
            }}
          />
        </linearGradient>
        <path
          id="Rectangle"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'url(#Rectangle_00000155108627635854869410000017590581449879399351_)',
          }}
          d="M0 0h20v20H0z"
        />
      </g>
      <path
        id="Amazon-EC2-Auto-Scaling_Icon_64_Squid"
        d="M17 9.751h-2.842l1.394-1.398-.351-.352-1.925 1.931 1.925 1.931.351-.352-1.258-1.262H17v-.498zM4.514 8.353l1.395 1.398H3.25v.498h2.523l-1.259 1.262.351.352L6.79 9.932 4.865 8.001l-.351.352zm3.874 3.887h3.723V8.507H8.388v3.733zm4.181-3.982h.534v.498h-.496v.747h.496V10h-.496v.747h.496v.498h-.496v.747h.496v.498h-.534a.42.42 0 0 1-.21.211v.535h-.496v-.498h-.745v.498h-.496v-.498h-.745v.498h-.496v-.498h-.745v.498H8.14V12.7a.425.425 0 0 1-.211-.211h-.534v-.498h.496v-.747h-.496v-.498h.496V10h-.496v-.498h.496v-.746h-.496v-.498h.534a.417.417 0 0 1 .211-.211v-.536h.496v.498h.744v-.498h.496v.498h.745v-.498h.496v.498h.745v-.498h.496v.535a.423.423 0 0 1 .211.212zm-2.196 7.789v-2.563h-.496v2.563l-1.326-1.33-.351.352L10.125 17l1.925-1.931-.35-.352-1.327 1.33zM8.55 5.283l-.351-.352L10.125 3l1.925 1.931-.35.352-1.326-1.33v2.782h-.497V3.953L8.55 5.283z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);

export default SvgComponent;
