import * as React from 'react';
import { SVGProps } from 'react';

const SvgCiscoDnac = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="cisco-dnac_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.cisco-dnac_svg__st1{fill:#3a4f73}.cisco-dnac_svg__st2{fill:#abc235}.cisco-dnac_svg__st3{fill:#707072}.cisco-dnac_svg__st4{fill:#5c5c5f}'
      }
    </style>
    <path
      d="M0 7.4V1C.1-.4 2 .2 3 .1c1.7 0 3.4.7 4.8 1.8 1.2 1.2 2 2.7 3 4.1 1.4 1.9 2.5 4.1 4.3 5.6 1.1 1 2.5 1.6 4 1.6 1.1.1 1.1 1.7-.1 1.7-2.7 0-5.3-1.6-6.8-3.9-1-1.5-2.1-3.1-3.2-4.7-.5-.8-1-1.5-1.6-2.2-1.4-1.3-3.1-2.3-5-2.3-.3 0-.5.2-.5.5v11.5c.1 1.3-1.9 1.4-1.9.1V7.4z"
      style={{
        fill: '#069fd8',
      }}
    />
    <path
      className="cisco-dnac_svg__st1"
      d="M9 8.8c.2.4 1.1 1.2.9 1.6-.8 1.1-1.5 2.2-2.5 3-.9.7-2 1.2-3.2 1.4-.9.1-1.3-1.4-.4-1.7 2.4-.6 4-2.4 5.2-4.3z"
    />
    <path
      className="cisco-dnac_svg__st2"
      d="M17.2 7.5V4.1c0-.3.1-.5.4-.6.4-.2.8 0 .9.4V10.8c0 .5-.3.7-.7.7-.3 0-.5-.2-.6-.5V7.5zM3.4 7.5V4.1c0-.3.1-.5.4-.6.3-.2.7 0 .9.3 0 .1.1.2.1.3v6.7c0 .3-.1.5-.3.6-.3.2-.4.1-.7 0-.2-.1-.3-.3-.3-.6-.1-1-.1-2.2-.1-3.3zM6.1 7.5V6c0-.3.1-.5.4-.6.4-.2.9.1.9.4V9c0 .4-.3.7-.7.7-.2 0-.5-.2-.6-.5V7.5zM15.9 7.6v1.5c0 .3-.1.5-.4.7-.3.2-.8 0-.9-.4V6.1c0-.3.2-.6.5-.6.3-.1.6.1.7.4 0 .1.1.2.1.3v1.4z"
    />
    <path
      className="cisco-dnac_svg__st3"
      d="M15.2 19.9c-.1-.2 0-.3 0-.5v-1c0-.3-.2-.5-.5-.5s-.6.3-.7.6v1.4h-.1v-2s0-.1.1-.1 0 .1.1.1v.1c.1 0 .1 0 .1-.1.3-.3.9-.3 1.1.1.1.1.1.2.1.3v1.3c-.1.2-.2.2-.2.3.1 0 0 0 0 0zM19.1 19.9V18c0-.1 0-.1.1-.1s.1.1.1.1v.1c.1 0 0 0 .1-.1s.3-.2.5-.2h.1v.1h-.1c-.2-.1-.4.1-.5.2-.1.2-.1.3-.1.5v.9c0 .2 0 .3-.2.4.1 0 .1 0 0 0 .1 0 0 0 0 0z"
    />
    <path
      className="cisco-dnac_svg__st4"
      d="M3.3 18v1.6c0 .1 0 .1-.1.1h-.4c-.1 0-.1 0-.1-.1v-2.5c0-.1 0-.1.1-.1h.5c.1 0 .1 0 .1.1.2.4.4.7.6 1.1.1.1.2.3.2.4 0 0 0 .1.1.1v-1.6c0-.1 0-.1.1-.1h.3c.1 0 .1 0 .1.1v2.5c0 .1 0 .1-.1.1h-.5c-.1 0-.1 0-.1-.1-.3-.4-.5-.9-.8-1.4.1.1.1-.1 0-.2zM0 18.5v-1.2c0-.1 0-.1.1-.1h1c.4 0 .8.1 1 .5.1.2.1.4.2.6 0 .3 0 .6-.1.9-.1.3-.3.4-.5.6-.2.1-.5.1-.7.1H.1c-.1 0-.1 0-.1-.1v-1.3zm.6 0v.8c0 .1 0 .1.1.1H1c.4 0 .6-.3.6-.6v-.6c0-.2-.1-.4-.3-.5-.2-.1-.4-.1-.6-.1-.1 0-.1 0-.1.1v.8zM6.5 17.1c.4 0 .4 0 .5.3.1.4.3.7.4 1.1.1.4.3.7.4 1.1v.1h-.6s-.1 0-.1-.1c0-.2-.1-.3-.1-.4 0-.1-.1-.1-.2-.1h-.7c-.1 0-.1 0-.2.1 0 .1-.1.3-.1.4 0 .1 0 .1-.1.1h-.5c-.1 0-.1 0-.1-.1.1-.3.2-.6.3-.8.1-.2.2-.5.2-.7 0-.1.1-.3.1-.4.3-.1.3-.3.4-.4 0-.1.1-.2.2-.1 0-.1.1-.1.2-.1zm0 1.7h.3c.1 0 .1 0 .1-.1v-.2c-.1-.2-.2-.5-.2-.7 0 0 0-.1-.1-.1s0 0-.1.1c0 .1 0 .2-.1.3-.1.2-.1.4-.2.6 0 .1 0 .1.1.1h.2z"
    />
    <path
      className="cisco-dnac_svg__st3"
      d="M9 18.5c0-.3.1-.7.3-1 .2-.2.3-.3.6-.4.3-.1.6-.1.9.1.2.1.4.3.5.6v.2s-.1 0-.1-.1-.1-.3-.2-.4c-.3-.3-.6-.3-1-.2-.4.1-.6.4-.7.7-.1.3-.1.7 0 1 .1.3.3.6.7.7.3.1.8.1 1-.3.1-.1.2-.3.2-.4 0-.1.1-.1.1-.1.1 0 .1.1 0 .1-.1.2-.2.5-.4.6-.1.1-.3.2-.4.2-.3 0-.5 0-.8-.1-.2-.1-.4-.3-.6-.5-.1-.2-.2-.4-.1-.7zM17.8 18.9h-.7c-.1 0-.1 0-.1.1 0 .3.1.5.4.6.3.1.8.1.9-.3 0-.1.1-.1.2-.1v.1c-.1.3-.4.5-.7.5-.2 0-.4 0-.6-.1-.2-.1-.3-.3-.4-.5 0-.2-.1-.3-.1-.5 0-.3.2-.7.5-.8.3-.2.6-.1.9.1.2.2.3.4.3.6 0 .3 0 .3-.3.3h-.3zm-.1-.2h.6c.1 0 .1 0 .1-.1s0-.3-.1-.4c-.1-.1-.3-.2-.5-.2-.3 0-.5.1-.6.3-.1.1-.1.2-.1.3 0 .1 0 .1.1.1h.5zM12.6 18.9h-.7c-.1 0-.1 0-.1.1.1.3.2.5.4.6.3.1.8.1.9-.3 0-.1.1-.1.1-.1.1 0 0 .1 0 .1 0 .2-.2.4-.4.4-.2.1-.3.1-.5.1-.3 0-.7-.3-.7-.6 0-.2-.1-.4 0-.5 0-.3.2-.6.5-.8.3-.2.7-.1 1 .2.2.2.3.5.3.8 0 .1 0 .1-.1.1-.3-.1-.5-.1-.7-.1zm-.1-.2h.6c.1 0 .1 0 .1-.1s-.1-.2-.1-.4c-.1-.2-.5-.4-.8-.3-.3.1-.5.4-.5.7 0 .1 0 .1.1.1h.6zM15.9 18.8v-.7c0-.1 0-.1-.1-.1s-.2.1-.2-.1c0-.1.1-.1.2-.1s.1 0 .1-.1v-.3c0-.1 0-.1.1-.1s.1 0 .1.1v.3c0 .1 0 .1.1.1h.2c.1 0 .1 0 .1.1s-.1.1-.1.1h-.2c-.1 0-.1 0-.1.1v1.4c0 .2.2.3.3.2h.2v.1h-.4c-.1 0-.2-.2-.2-.4-.1-.1-.1-.4-.1-.6z"
    />
    <path
      className="cisco-dnac_svg__st1"
      d="M19.9.7c-.5-1-1.9-.4-2.8-.3-2.3.6-4.1 2.3-5.3 4.3-.2.1 1 1.8 1 1.4.6-1 1.4-2 2.3-2.7 2.1-2.1 5.4-1 4.8-2.7z"
    />
  </svg>
);

export default SvgCiscoDnac;
