import { Flex, HStack, useDisclosure } from '@chakra-ui/react';
import { Grid, Label, Para, TextButton, Theme } from 'uie/components';
import { BillingService } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import { AddRespondersIcon, DeleteRedIcon, EmailReminderIcon } from 'library/icons';
import { THEME_COLORS } from 'library/theme/colors';
import { useMemo, useState } from 'react';
import { MultipleResponders } from '..';

import { IAssignedTo } from '../../../../../../../core/interfaces/IIncidents';
import {
  ASSIGNEE_NAME_HOVER_MAX_LENGTH,
  DEFAULT_LIST_SIZE,
  RESPONDERS_FEAT_NAME,
  RESPONDER_NAME_MAX_LENGTH,
} from '../const';
import { RESPONDER_LIST_TYPE } from '../enums';
import { useSendReminderMutation } from '../hooks/usePostReminder';
import { RespondersListProps } from '../types';
import { ResponderAssignee } from './assignee';
import { DeleteResponder } from './delete';
import { HoverButton } from './hover-button';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

const { theme } = Theme;

export const RespondersList = ({
  assigneeList,
  type,
  organization,
  incident,
}: RespondersListProps) => {
  const assignees: { [key: string]: boolean } = {};
  const org = organization as unknown as Pick<IAppState, 'organization'>;

  const isUnique = (key: string) => {
    if (!assignees[key]) {
      assignees[key] = true;
      return true;
    }
    return false;
  };

  const uniqueAssignee = useMemo(() => {
    return assigneeList.reduce((acc, assignee) => {
      if (isUnique(assignee.id)) {
        acc.push(assignee);
      }
      return acc;
    }, [] as IAssignedTo[]);
  }, [assigneeList]);

  const canShowHide = useMemo(() => uniqueAssignee.length > DEFAULT_LIST_SIZE, [uniqueAssignee]);
  const length = useMemo(() => uniqueAssignee.length, [uniqueAssignee]);
  const [entityLength, setEntityLength] = useState(DEFAULT_LIST_SIZE);
  const [focusedAssigneeKey, setFocusedAssigneeKey] = useState<string>('');
  const [selectedAssignee, setSelectedAssignee] = useState<IAssignedTo>();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { mutate: sendReminder, isLoading } = useSendReminderMutation(incident.id);

  const isFeatureAvailable = useMemo(() => {
    return organization
      ? !BillingService.isFeatureDisabled(
          { organization } as unknown as Pick<IAppState, 'organization'>,
          RESPONDERS_FEAT_NAME,
        )
      : true;
  }, [organization, BillingService]);

  const onRemind = (assignee: IAssignedTo) => {
    sendReminder({ incidentId: incident.id, responderId: assignee.id });
  };

  const onDelete = (assignee: IAssignedTo) => {
    setSelectedAssignee(assignee);
    onDeleteOpen();
  };

  const hasUpdateAccess = useUserAccess().hasUpdateAccess('incidents');

  return (
    <>
      <Label
        fontSize={15}
        color={THEME_COLORS.brand.oldStyleLabel}
        fontWeight={700}
        style={{ padding: '8px 0px 4px' }}
      >
        {type === RESPONDER_LIST_TYPE.ASSIGNEE
          ? 'Assignee History'
          : `Additional Responders (${length})`}
      </Label>
      {type === RESPONDER_LIST_TYPE.RESPONDER && (
        <Grid className="mt-10 mb-10">
          <MultipleResponders organization={org} incident={incident} addInlineCrownStyle>
            <NoPermissionTooltip isDisabled={hasUpdateAccess}>
              <TextButton
                color={theme.primary.default}
                buttonType="inverted"
                style={{ padding: '8px 28px 8px 16px' }}
                disabled={!hasUpdateAccess}
              >
                <Grid alignItems="center" justifyContent="center">
                  <AddRespondersIcon height={16} width={16} className="mr-5" />
                  <Para color={theme.primary.default} fontSize={14} fontWeight={400}>
                    Add Responders
                  </Para>
                </Grid>
              </TextButton>
            </NoPermissionTooltip>
          </MultipleResponders>
        </Grid>
      )}
      {[...uniqueAssignee.slice(0, entityLength)].map(assignee => {
        const key = assignee.id;
        return (
          <Flex key={key} height="34px" minHeight="34px" alignItems="center">
            {type === RESPONDER_LIST_TYPE.ASSIGNEE ? (
              <ResponderAssignee
                organization={organization}
                assignee={assignee}
                incident={incident}
                showCurrentAssignee={true}
              />
            ) : (
              <HStack
                onMouseEnter={() => isFeatureAvailable && setFocusedAssigneeKey(key)}
                onMouseLeave={() => setFocusedAssigneeKey('')}
                alignItems="center"
                backgroundColor={
                  focusedAssigneeKey === key ? THEME_COLORS.primary[200] : THEME_COLORS.brand.white
                }
                height="34px"
                justifyContent="space-between"
                w="100%"
              >
                <ResponderAssignee
                  organization={organization}
                  assignee={assignee}
                  incident={incident}
                  nameLength={
                    focusedAssigneeKey === key
                      ? ASSIGNEE_NAME_HOVER_MAX_LENGTH
                      : RESPONDER_NAME_MAX_LENGTH
                  }
                />
                {focusedAssigneeKey === key && (
                  <HStack columnGap="2px">
                    <NoPermissionTooltip isDisabled={hasUpdateAccess}>
                      <HoverButton
                        isDisabled={isLoading || !hasUpdateAccess}
                        onClick={() => onDelete(assignee)}
                        label="Remove Responder"
                      >
                        <DeleteRedIcon />
                      </HoverButton>
                    </NoPermissionTooltip>
                    <NoPermissionTooltip isDisabled={hasUpdateAccess}>
                      <HoverButton
                        isDisabled={isLoading || !hasUpdateAccess}
                        onClick={() => onRemind(assignee)}
                        label="Resend Notification"
                      >
                        <EmailReminderIcon />
                      </HoverButton>
                    </NoPermissionTooltip>
                  </HStack>
                )}
              </HStack>
            )}
          </Flex>
        );
      })}
      {canShowHide && (
        <Label
          fontSize={12}
          color={THEME_COLORS.brand.blue}
          fontWeight={800}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setEntityLength(entityLength === length ? DEFAULT_LIST_SIZE : length);
          }}
        >
          {entityLength < length ? 'Show More' : 'Hide'}
        </Label>
      )}
      {selectedAssignee && (
        <DeleteResponder
          organization={organization}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          incidentId={incident.id}
          responder={selectedAssignee}
        />
      )}
    </>
  );
};
