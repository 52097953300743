import {
  CardBlock,
  DialogModalFrame,
  DropDown,
  DropDownOptionShell,
  Grid,
  IconButton,
  Para,
  Pill,
  Theme,
} from 'uie/components';
import ConfirmModal from 'components/confirm.modal';
import { MoreVerticalIcon, SquadIcon, UserIcon } from 'icons';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import React, { useCallback, useMemo, useState } from 'react';
import { useSettingPageACL } from '../../../../acl';
import { useStakeHolders } from '../../hooks/stakeholders.hooks';
import { IStakeHolderProps, TabListProps } from '../../types/stakeholder.types';
import AddStakeHolderGroup from '../add';

function TabList(props: TabListProps & IStakeHolderProps) {
  const { name, members, id, onEditClick, isExpanded, refetch, team } = props;
  const { theme } = Theme;

  const [editMode, setEditMode] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const { deleteStakeHolderGroup, deletingStakeHolders } = useStakeHolders();

  const toggleEditMode = useCallback(() => {
    setEditMode(!editMode);
    onEditClick(id);
  }, [editMode, id]);

  const toggleDeleteModal = useCallback(() => {
    setOpenDeleteModal(!openDeleteModal);
  }, [openDeleteModal]);

  const deleteStakeHolderGroupFun = useCallback(() => {
    deleteStakeHolderGroup(`${props.id}`, props.team.id, () => {
      setOpenDeleteModal(false);
      refetch();
    });
  }, [props.id, props.team]);

  const userAccess = useSettingPageACL();
  const hasDelete = userAccess.hasDeleteAccess('stakeholder_groups');
  const hasUpdate = userAccess.hasUpdateAccess('stakeholder_groups');

  const renderUserList = useMemo(() => {
    return members.map((m: any, i: any) => {
      return (
        <Pill className="mt-10 mr-10" key={m.user_id}>
          <Grid alignItems="center">
            <UserIcon height={14} width={14} />
            <Para className="ml-5">{m.name}</Para>
          </Grid>
        </Pill>
      );
    });
  }, [members]);

  if (editMode && isExpanded)
    return (
      <AddStakeHolderGroup
        closeAddCard={toggleEditMode}
        isEditMode={true}
        stakeHolders={members}
        groupName={name}
        stakeHolderGroupdId={`${props?.id}`}
        {...props}
        refetch={refetch}
      />
    );
  return (
    <>
      <CardBlock
        key={-99}
        className="mb-10 mt-10"
        padding="8px"
        animateOnHover={true}
        borderStyle="sharp"
      >
        <Grid type="column" width="700px">
          <Grid alignItems="center" justifyContent="space-between">
            <Grid alignItems="center">
              <SquadIcon height="14px" width="14px" />
              <Para className="ml-10" fontSize={16} height="24px">
                {name}
              </Para>
            </Grid>
            {!editMode && (
              <div>
                <DropDown
                  hook={
                    <IconButton>
                      <MoreVerticalIcon height="14px" width="14px" stroke={theme.shades.cement} />
                    </IconButton>
                  }
                  width="100px"
                  maxWidth="100px"
                  height="auto"
                >
                  <div>
                    <NoPermissionTooltip isDisabled={hasUpdate}>
                      <DropDownOptionShell
                        padding="8px"
                        onClick={toggleEditMode}
                        disabled={!hasUpdate}
                      >
                        <Para>Edit</Para>
                      </DropDownOptionShell>
                    </NoPermissionTooltip>
                    <NoPermissionTooltip isDisabled={hasDelete}>
                      <DropDownOptionShell
                        padding="8px"
                        onClick={toggleDeleteModal}
                        disabled={!hasDelete}
                      >
                        <Para color={theme.danger.default}>Delete</Para>
                      </DropDownOptionShell>
                    </NoPermissionTooltip>
                  </div>
                </DropDown>
              </div>
            )}
          </Grid>
          <Grid type="row" alignItems="center" justifyContent="flex-start" flexWrap="wrap">
            {renderUserList}
          </Grid>
        </Grid>
      </CardBlock>
      <DialogModalFrame
        id="organization_team_settings__delete_squad"
        onClose={toggleDeleteModal}
        width="400px"
      >
        {openDeleteModal && (
          <ConfirmModal
            displayText={
              <Para>
                Delete <span className="font-bold">{name}</span> stake holder group with{' '}
                <span className="font-bold">{members.length} Stakeholder</span> ?
              </Para>
            }
            buttonText={`Delete ${name}`}
            primaryButtonColor={theme.danger.default}
            isBusy={deletingStakeHolders}
            cancelText="No"
            hide={deleteStakeHolderGroupFun}
          />
        )}
      </DialogModalFrame>
    </>
  );
}

export default React.memo(TabList);
