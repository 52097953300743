import { Box, Text } from '@chakra-ui/layout';
import React, { FC, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { MSTeamMessageUserAction, MsTeamOption } from 'views/main/organization/workflow/types';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import { useCreateEntityMap, transformMessage } from 'library/atoms/MdBlock/helper';
import { useGetMsTeamUserList } from 'views/main/organization/workflow/details/hooks/useCheckMsTeamStatus';

interface IProps {
  action: MSTeamMessageUserAction;
}

const MessageTeamUserCard: FC<IProps> = ({ action }) => {
  const entities = useCreateEntityMap();

  const teamUsersList = useGetMsTeamUserList();

  const userOptions = useMemo(() => {
    return teamUsersList?.data?.map(team => {
      const option: MsTeamOption = {
        label: team.name + ' ' + `(${team.email})`,
        value: team.id,
      };
      return option;
    });
  }, []);
  const selectedUser = userOptions?.find(team => team.value === action.data.member_id);
  return (
    <React.Fragment>
      <Box py={4}>
        <Text variant="h3" color="secondary.700" textStyle="bold_800">
          USER
        </Text>
        <Text> {selectedUser?.label}</Text>
      </Box>
      <Box>
        <Text variant="h3" color="secondary.700" textStyle="bold_800">
          MESSAGE
        </Text>
        <Text color="secondary.1000" variant="h4" fontWeight="400" textTransform="capitalize">
          {' '}
          <ReactMarkdown className="mde" plugins={[breaks, gfm]}>
            {transformMessage(action.data.message.replace(/ /g, '\u00A0'), entities)}
          </ReactMarkdown>
        </Text>
      </Box>
    </React.Fragment>
  );
};

export default MessageTeamUserCard;
