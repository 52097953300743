import { PlacementWithLogical, Tooltip } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  text: string | ReactNode;
  placement?: PlacementWithLogical;
  isDisabled?: boolean;
  shouldWrapChildren?: boolean;
};

function SQTooltip({ children, text, placement, isDisabled, shouldWrapChildren }: Props) {
  return (
    <Tooltip
      hasArrow
      bgColor="blue.50"
      color="black"
      fontWeight="medium"
      fontSize="xs"
      label={text}
      placement={placement}
      zIndex={10}
      isDisabled={isDisabled}
      shouldWrapChildren={shouldWrapChildren}
    >
      {children}
    </Tooltip>
  );
}

export default SQTooltip;
