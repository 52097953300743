import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const CircularProgressTheme: ComponentStyleConfig = {
  baseStyle: () => {
    return {
      fontFamily: 'Mulish',
      fontWeight: '400',
      trackColor: 'primary.200',
      color: 'brand.blue',
    };
  },
  sizes: {
    // for 1440 screen size
    md: {
      size: '80px',
      fontSize: '14px',
    },
    // for 1920 screen size
    lg: {
      size: '120px',
      fontSize: '16px',
    },
  },
  variants: {
    default: {},
  },
  defaultProps: {
    size: 'md',
    variant: 'default',
  },
};
