import {
  CutTick,
  ErrorBudgetIcon,
  SloDetailIcon,
  SnoozeIcon,
  StarIcon,
  TaskIcon,
  TransientAlertCrossedIcon,
  TransientAlertIcon,
  TransientAlertViolatedIcon,
  UnSnoozeIcon,
} from 'icons';
import { Theme } from 'uie/components';
import { ILog } from 'core/interfaces/IIncidents';

type IconForAction =
  | 'note_or_incident_starred'
  | 'marked_as_transient_by_squadcast'
  | 'manually_marked_positive_transient_alert_feedback'
  | 'manually_marked_negative_transient_alert_feedback'
  | 'incident_snoozed'
  | 'incident_unsnoozed_automatically'
  | 'incident_unsnoozed_manually';
const getLogIcon = (log: ILog) => {
  const { theme } = Theme;

  const activityIcon = (log: ILog) => {
    {
      if (log.action.split('_').pop() === 'true') {
        return <CutTick width={16} height={16} />;
      } else if (log.action === 'update_slo_error_budget_spent') {
        return <ErrorBudgetIcon width={16} height={16} />;
      } else {
        return <TaskIcon width={16} height={16} />;
      }
    }
  };
  const IconMap = {
    note_or_incident_starred: (
      <StarIcon stroke={theme.shades.grey} fill={theme.shades.grey} width={16} height={16} />
    ),
    marked_as_transient_by_squadcast: <TransientAlertIcon width={16} height={16} />,
    manually_marked_positive_transient_alert_feedback: (
      <TransientAlertIcon width={16} height={16} />
    ),
    apta_violated: <TransientAlertViolatedIcon width={16} height={16} />,
    manually_marked_negative_transient_alert_feedback: (
      <TransientAlertCrossedIcon width={16} height={16} />
    ),
    incident_snoozed: <SnoozeIcon width={16} height={16} />,
    incident_unsnoozed_automatically: <UnSnoozeIcon width={16} height={16} />,
    incident_unsnoozed_manually: <UnSnoozeIcon width={16} height={16} />,
  };
  let logIcon = <></>;

  if (['note_starred', 'incident_starred'].includes(log.actionType)) {
    logIcon = IconMap['note_or_incident_starred'];
  }

  if (log.type === 'activity') {
    logIcon = activityIcon(log);
  }
  logIcon = IconMap[log.action as IconForAction] || <SloDetailIcon width={16} height={16} />;

  return logIcon;
};

export default getLogIcon;
