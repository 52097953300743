import {
  REQUEST_ORG_SELECTED_TEAM_CHANGE,
  REQUEST_ORG_SELECTED_TEAM_CHILDREN,
  REQUEST_ORG_SELECTED_TEAM_CHILDREN_FAIL,
  REQUEST_ORG_SELECTED_TEAM_CHILDREN_SUCCESS,
} from '../../const/selectedTeam';
import { ITeamChildEntities } from '../../interfaces/ITeams';

const requestOrganizationSelectedTeamChange = (teamId: string) => ({
  type: REQUEST_ORG_SELECTED_TEAM_CHANGE,
  payload: {
    teamId,
  },
});

const requestOrganizationSelectedTeamChildren = () => ({
  type: REQUEST_ORG_SELECTED_TEAM_CHILDREN,
  payload: {},
});

const onRquestOrganizationSelectedTeamChildrenSuccess = (children: ITeamChildEntities) => ({
  type: REQUEST_ORG_SELECTED_TEAM_CHILDREN_SUCCESS,
  payload: {
    children,
  },
});

const onRquestOrganizationSelectedTeamChildrenFail = (error: any) => ({
  type: REQUEST_ORG_SELECTED_TEAM_CHILDREN_FAIL,
  payload: {
    error,
  },
});

export {
  requestOrganizationSelectedTeamChange,
  requestOrganizationSelectedTeamChildren,
  onRquestOrganizationSelectedTeamChildrenSuccess,
  onRquestOrganizationSelectedTeamChildrenFail,
};
