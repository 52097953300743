import { HStack } from '@chakra-ui/react';
import { FileUploadService } from 'core';
import { T_WA_GS_INCIDENT_CREATED_MANUALLY } from 'core/const/tracker';
import { FileUploadFeature, OperationType } from 'core/services/service.fileUpload';
import { FormikProps, FormikValues } from 'formik';
import { FormButton, useToast } from 'library/atoms';
import { getPriorityValue } from 'library/common';
import { CustomDrawerComponent, FormikForm } from 'library/molecules';
import { memo, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { AppTracker } from 'shared/analytics/tracker';

import { QUERY_CONFIG } from '../common/constants';
import { IAttachmentRequest, OtherProps } from '../common/interfaces';
import { invalIncidentsListData } from '../common/util';
import { Attachment } from '../graphql/generated/types';
import { useCreateIncidentMutation } from '../graphql/mutation';
import { IncidentParamsContext } from '../hooks/useAllParams';
import { FormValues } from '../interfaces/common';
import CreateIncidentTemplate from './formTemplate';
import { clearIncidentObj, getIncidentObj } from './temp-store';
import { IncidentCreateValidatorFunc } from './validator';

export const CREATE_INCIDENT_FORM_ID = 'create-incident-form';

export const CreateIncident = memo(
  ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const {
      params: { isLoading, serviceListForFilters, assigneeList, tagData },
    } = useContext(IncidentParamsContext);

    const { mutateAsync: createIncidentMutation, isLoading: isCreating } =
      useCreateIncidentMutation();
    const fileUploadService = new FileUploadService();
    const toast = useToast();
    const queryClient = useQueryClient();

    const closeDrawer = () => {
      clearIncidentObj();
      onClose();
    };

    const fileUploadHandler = fileUploadService.getUploadFunctionForFeature(
      FileUploadFeature.INCIDENT,
      OperationType.CREATE,
    );

    return (
      <CustomDrawerComponent
        title="Create New Incident"
        description="Create a custom Incident manually with no associated Alerts."
        isOpen={isOpen}
        onClose={closeDrawer}
        disableBodyPadding
        footer={
          <HStack spacing={3}>
            <FormButton
              type="submit"
              form={CREATE_INCIDENT_FORM_ID}
              title="Save"
              isLoading={isCreating}
            />
            <FormButton
              title="Cancel"
              variant="secondary"
              onClick={closeDrawer}
              isDisabled={isCreating}
            />
          </HStack>
        }
      >
        <FormikForm
          validate={IncidentCreateValidatorFunc}
          handleSubmit={(values: FormikValues | FormValues) => {
            const priorityValue = getPriorityValue(values.priority.value);
            const input = {
              message: values.title,
              description: values.description ?? '',
              assignedTo: {
                type: values.assignedkey.value,
                id: values.assigned.value,
              },
              priority: priorityValue,
              serviceID: values.services.value,
              tags: values.tags ?? [],
              attachments: fileUploadService
                .accessAttachmentArrayForIncident()
                .reduce((acc: Attachment[], item: IAttachmentRequest) => {
                  acc.push({
                    fileSize: item.file_size,
                    mimeType: item.mime_type,
                    key: item.key,
                  });
                  return acc;
                }, []),
            };

            // empty catch block is required as mutation hook handles toast messages but still throws error
            createIncidentMutation(
              { input },
              {
                ...QUERY_CONFIG,
                onSuccess: () => {
                  toast({
                    status: 'success',
                    text: 'Success: Incident has been created successfully',
                  });
                  AppTracker.track(T_WA_GS_INCIDENT_CREATED_MANUALLY, {
                    'Incident Priority Chosen': priorityValue,
                  });
                  invalIncidentsListData(queryClient);
                  closeDrawer();
                },
                onError: () => {
                  toast({
                    status: 'error',
                    text: 'Error: Unable to create incident',
                  });
                },
              },
            );
          }}
          mapPropsToValues={(): FormValues => getIncidentObj()}
          formTemplate={(props: OtherProps & FormikProps<FormValues>) => (
            <CreateIncidentTemplate
              {...props}
              {...{
                isLoading,
                assigneeList,
                fileUploadHandler,
                serviceListForFilters,
                tagData,
              }}
            />
          )}
        ></FormikForm>
      </CustomDrawerComponent>
    );
  },
);
