import {
  REQUEST_USER_PERMISSION_ROLES,
  REQUEST_USER_PERMISSION_ROLES_SUCCESS,
  REQUEST_USER_PERMISSION_ROLES_FAIL,
} from '../../const/public';
import { INITIAL_STATE } from '../state';
import { DEFAULT_ERROR } from '../../const/defaults';
import { IUserPermissionRoles } from '../../interfaces/IUserPermissionRoles';
import { RESET_STATE } from '../../const/init';

const initialRoles = INITIAL_STATE.organization.userPermissionRoles;

const userPermissionRoles = (state = initialRoles, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ action: action.type, r: [], error: { ...DEFAULT_ERROR } },
      };
    case REQUEST_USER_PERMISSION_ROLES:
      return {
        ...state,
        ...{ action: action.type, r: [], error: { ...DEFAULT_ERROR } },
      };
    case REQUEST_USER_PERMISSION_ROLES_SUCCESS:
      return {
        ...state,
        ...{
          action: action.type,
          r: action.payload.userRoles as IUserPermissionRoles[],
          error: { ...DEFAULT_ERROR },
        },
      };
    case REQUEST_USER_PERMISSION_ROLES_FAIL:
      return {
        ...state,
        ...{
          action: action.type,
          r: [],
          error: { type: REQUEST_USER_PERMISSION_ROLES_FAIL, reason: action.payload },
        },
      };
    default:
      return state;
  }
};

export default userPermissionRoles;
