import { CheckIcon, CopyIcon } from '@chakra-ui/icons';
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import React from 'react';

interface ISliderPorps {
  apiToken: string;
  jiraAccountName: string;
  teamsIds: string[];
}
interface IAccountDetailsSliderProps {
  apiToken: string;
  // setName: (name: string) => void;
  name: string;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  formikError: string | undefined;
}

function AccountDetailsSlider(props: IAccountDetailsSliderProps) {
  const { hasCopied, onCopy } = useClipboard(props.apiToken);

  return (
    <Box>
      <Text marginTop="20px" fontSize={'sm'}>
        Choose a name for your Jira account
      </Text>
      <Stack spacing={7} marginTop="20px">
        <Stack direction={'row'} width="550px">
          <Text width={'100px'}>Name:</Text>
          <Stack direction={'column'} spacing={2} width="100%">
            <Input
              name="jiraAccountName"
              onChange={e => {
                props.handleChange(e);
              }}
              defaultValue={props.name}
            />
            <Text fontSize={12} color="red.500" fontWeight={500}>
              {props.formikError && props.formikError}
            </Text>
          </Stack>
        </Stack>
        <Stack direction={'row'} width="550px" alignItems="center">
          <Text width={'100px'}>API Token:</Text>
          <InputGroup>
            <Input variant="filled" value={props.apiToken} isReadOnly isDisabled />
          </InputGroup>
          {hasCopied ? (
            <CheckIcon color="primary.default" />
          ) : (
            <CopyIcon cursor={'pointer'} onClick={onCopy} color="primary.default" w={5} h={5} />
          )}
        </Stack>
      </Stack>
      <Text fontSize={'sm'} width="480px" marginTop={'10px'} marginLeft="90px">
        A script or other process can use this API for basic authentication with Jira Cloud
        application
      </Text>
    </Box>
  );
}

export default AccountDetailsSlider;
