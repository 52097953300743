import axios from 'axios';
import { API } from '../api';
import { IDeduplicationRule } from '../interfaces/IService';

class DeduplicationRulesService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/services`;
  private orgDedupCountApi = `${API.config.batman}/organizations/${API.config.organizationId}/deduplication-rules/count`;

  constructor(serviceId: string) {
    this._api = `${this._api}/${serviceId}/deduplication-rules`;
  }

  public getOrgCount = () => axios.get<{ data: { count: number } }>(this.orgDedupCountApi);

  public get = () => axios.get(this._api);

  public save = (rules: IDeduplicationRule[]) => axios.post(this._api, { rules });
}

export default DeduplicationRulesService;
