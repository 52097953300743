import { ICurrentUser } from 'core/interfaces/IAppState';
import { IBillingPlan, IPricingEntity } from 'core/interfaces/IBillingPlan';
import BillingService, { Limit, plurals, Result } from 'core/services/service.billing';

export const actions = (pricingEntity: IPricingEntity) => {
  switch (pricingEntity) {
    case 'postmortem-templates':
    case 'status-page':
    case 'postmortem':
    case 'runbooks':
      return 'use';
    case 'export-incident-timeline':
      return 'export';

    case 'service':
    case 'user':
    case 'event-hooks':
    case 'tagging-rules':
    case 'suppression-rules':
    case 'routing-rules':
    case 'deduplication-rules':
      return 'Add more';
    case 'teams':
    case 'stakeholder':
      return 'Add';
  }
};

export function getPlanDisplayName(planSlug: string) {
  return BillingService.PLANS_CONFIG.hasOwnProperty(planSlug)
    ? BillingService.PLANS_CONFIG[planSlug].plan_name
    : null;
}

export function getPlanSuggestionsToUpgrade(plan: IBillingPlan | null): string[] {
  const planSlug = plan?.active ? plan?.plan_slug : '';
  switch (planSlug) {
    case BillingService.ENTERPRISE_ANNUAL_SLUG:
    case BillingService.NEW_ENTERPRISE_ANNUAL_SLUG:
      return [];
    case BillingService.ENTERPRISE_MONTHLY_SLUG:
    case BillingService.NEW_ENTERPRISE_MONTHLY_SLUG:
      return [BillingService.ENTERPRISE_ANNUAL_SLUG];
    case BillingService.PREMIUM_ANNUAL_SLUG:
    case BillingService.PREMIUM_QUARTERLY_SLUG:
    case BillingService.PREMIUM_MONTHLY_SLUG:
      return [BillingService.ENTERPRISE_ANNUAL_SLUG, BillingService.ENTERPRISE_MONTHLY_SLUG];
    default:
      return [BillingService.PREMIUM_ANNUAL_SLUG, BillingService.PREMIUM_MONTHLY_SLUG];
  }
}

export function isUpgradeable(plan: IBillingPlan | null, toUpgradePlanSlug: string): boolean {
  const activePlanSlug = plan?.active ? plan?.plan_slug : '';
  return activePlanSlug === ''
    ? true
    : BillingService.ORDER_OF_PLANS.findIndex(
        plan => plan === BillingService.PLANS_CONFIG[toUpgradePlanSlug].plan_group,
      ) >
        BillingService.ORDER_OF_PLANS.findIndex(
          plan => plan === BillingService.PLANS_CONFIG[activePlanSlug].plan_group,
        );
}

export function isLimitExceeded(
  plan: IBillingPlan | null,
  pricingEntity: IPricingEntity,
  currentCount: () => number,
) {
  const planActive = plan?.active || false;
  if (!planActive) {
    return true;
  }
  const rules = plan?.rules?.[pricingEntity] || null;
  if (rules == null) {
    return true;
  }

  if (rules.unlimited) {
    return false;
  }
  if (currentCount() >= rules.quantity) {
    return true;
  }
  return false;
}

export function isSomeLimitedPlan(plan: IBillingPlan | null) {
  const planSlug = plan?.plan_slug || '';
  return BillingService.LIMITED_PLANS_DISPLAY_NAME.hasOwnProperty(planSlug);
}

export function isOldProPlanActive(plan: IBillingPlan | null) {
  const planSlug = plan?.plan_slug || '';
  const isActive = plan?.active;
  return (
    (planSlug === BillingService.GRANDFATHERED_PRO_ANNUAL_SLUG ||
      planSlug === BillingService.GRANDFATHERED_PRO_MONTHLY_SLUG) &&
    isActive
  );
}

export function getLimit(plan: IBillingPlan | null, pricingEntity: IPricingEntity) {
  const planActive = plan?.active || false;
  if (!planActive) {
    return undefined;
  }
  const rules = plan?.rules?.[pricingEntity] || null;
  if (rules == null) {
    return undefined;
  }
  return rules.unlimited ? 'unlimited' : rules.quantity;
}

export function getPlanLimit(
  plan: IBillingPlan | null,
  pricingEntity: IPricingEntity,
): Result<Limit> {
  if (!plan) {
    return Result.loading();
  }
  if (!plan.active) {
    return Result.data(undefined);
  }
  const rules = plan.rules[pricingEntity];
  if (!rules) {
    return Result.data(undefined);
  }
  return Result.data(rules.unlimited ? 'unlimited' : rules.quantity);
}

export function getRule(plan: IBillingPlan | null, pricingEntity: IPricingEntity) {
  const planActive = plan?.active || false;
  if (!planActive) {
    return undefined;
  }
  const rules = plan?.rules?.[pricingEntity] || null;
  if (rules == null) {
    return undefined;
  }
  return rules;
}

export function isFeatureDisabled(plan: IBillingPlan | null, pricingEntity: IPricingEntity) {
  const limit = getLimit(plan, pricingEntity);
  return limit === 0 || limit === undefined;
}

export function isFeatDisabled(limit: Limit): boolean {
  return limit === 0 || limit === undefined;
}

export function getMessage(
  limit: number | undefined | 'unlimited',
  entity: IPricingEntity,
  plan: IBillingPlan | null,
  renewable?: boolean,
) {
  if (!plan?.active) {
    return 'You do not have an active plan and hence can not use this feature';
  }
  const planText = getLimitedPlanName(plan);
  const renewText = renewable ? `in a billing month` : '';

  switch (limit) {
    case undefined:
    case 0:
      switch (entity) {
        case 'custom-rbac':
          return `On the ${planText} plan, you do not have access to add/update custom roles`;
        case 'modify-default-rbac':
          return `On the ${planText} plan, you do not have access to modify default roles`;
        case 'roundrobin-escalation':
          return `On the ${planText} plan, you do not have access to advanced Escalation Rule Settings`;
        case 'postmortem-templates':
        case 'status-page':
        case 'runbooks':
        case 'postmortem':
          return `On the ${planText} plan, you do not have access to ${plurals[entity]}`;
        case 'slo':
          return `On the ${planText} plan, you do not have access to SLO feature`;
        case 'export-incident-timeline':
          return `On the ${planText} plan, you do not have access to export ${plurals[entity]}`;
        case 'stakeholder':
          return `On the ${planText} plan, you do not have access to add ${plurals[entity]}`;
        case 'webform':
          return `On the ${planText} plan, you do not have access to Webform feature`;
        case 'merge-incidents':
          return `On the ${planText} plan, you do not have access to Merge Incidents feature`;
        case 'ger':
          return `Upgrade to use Global Event Rulesets`;
        case 'custom-content-templates':
          return `On the ${planText} plan, you do not have access to Custom Content Template feature`;
        case 'workflows':
          return `On the ${planText} plan, you do not have access to Workflows feature`;
        case 'past-incidents':
          return `On the ${planText} plan, you do not have access to Past Incidents feature`;
        case 'auto-pause-transient-alerts':
          return `On the ${planText} plan, you do not have access to Auto Pause Transient Alerts feature`;
        case 'service-graph':
          return `On the ${planText} plan, you do not have access to Service Graph feature`;
        case 'delayed-notification':
        case 'additional-responders':
          return `On the ${planText} plan, you do not have access to ${plurals[entity]} feature`;
        default:
          return `On the ${planText} plan, you do not have access to ${plurals[entity]}`;
      }
      break;

    case 1:
      switch (entity) {
        case 'teams':
          return `On the ${planText} plan, you do not have access to add more ${plurals[entity]}`;
        default:
          return renewable
            ? `On the ${planText} plan, you have access to add only ${limit} ${plurals[entity]} ${renewText}`
            : `On the ${planText} plan, you do not have access to add more than ${limit} ${plurals[entity]}`;
      }
    default:
      return renewable
        ? `On the ${planText} plan, you have access to add only ${limit} ${plurals[entity]} ${renewText}`
        : `On the ${planText} plan, you do not have access to add more than ${limit} ${plurals[entity]}`;
  }
}

export function getHeader(limit: number | undefined | 'unlimited', entity: IPricingEntity) {
  if (entity === 'export-incident-timeline') {
    return 'Upgrade to Export Timelines';
  }
  switch (limit) {
    case undefined:
    case 0:
      switch (entity) {
        case 'custom-rbac':
          return 'Upgrade to add/update custom-roles ';
        case 'modify-default-rbac':
          return 'Upgrade to modify default-roles';
        case 'roundrobin-escalation':
          return `Upgrade to use advance Escalation Rules`;
        case 'postmortem-templates':
        case 'status-page':
        case 'runbooks':
        case 'postmortem':
        case 'delayed-notification':
          return `Upgrade to use ${plurals[entity]}`;
        case 'slo':
          return `Upgrade to use SLOs`;
        case 'webform':
          return `Upgrade to use Webforms`;
        case 'stakeholder':
          return `Upgrade to Add Stakeholder`;
        case 'merge-incidents':
          return `Upgrade to use Merge Incidents`;
        case 'custom-content-templates':
          return 'Upgrade to use Custom Content Templates';
        case 'workflows':
          return 'Upgrade to use Workflows';
        case 'past-incidents':
          return `Upgrade to use Past Incidents`;
        case 'ger':
          return `Upgrade to use Global Event Rulesets`;
        case 'auto-pause-transient-alerts':
          return `Upgrade to use Auto Pause Transient Alerts`;
        case 'service-graph':
          return `Upgrade to use Service Graph`;
        default:
          return `Upgrade to Add more ${plurals[entity]}`;
      }
      break;

    case 1:
      switch (entity) {
        case 'teams':
          return `Upgrade to Add Teams`;
        default:
          return `Upgrade to Add more ${plurals[entity]}`;
      }
    default:
      return `Upgrade to Add more ${plurals[entity]}`;
  }
}

export function isSomeProductTrialPlan(plan: IBillingPlan | null) {
  const planSlug = plan?.plan_slug;
  return (
    planSlug === BillingService.PRODUCT_TRIAL_PLAN_SLUG ||
    planSlug === BillingService.PRODUCT_TRIAL_EXTENDED_PLAN_SLUG
  );
}

export function isOnPremiumPlan(plan: IBillingPlan | null) {
  const planSlug = plan?.plan_slug;
  return (
    planSlug === BillingService.PREMIUM_MONTHLY_SLUG ||
    planSlug === BillingService.PREMIUM_ANNUAL_SLUG ||
    planSlug === BillingService.PREMIUM_QUARTERLY_SLUG
  );
}

export function getDiscountInfo(plan: string) {
  switch (plan.toLowerCase()) {
    case 'pro':
      return 'Pay annually & save 25%';
    case 'premium':
      return 'Pay annually & save 16%';
    case 'enterprise':
      return 'Pay annually & save 19%';
  }
  return '';
}

export function getLimitedPlanName(plan: IBillingPlan | null) {
  const planSlug = plan?.plan_slug;
  if (!planSlug) {
    return 'Current';
  }
  return BillingService.LIMITED_PLANS_DISPLAY_NAME.hasOwnProperty(planSlug)
    ? BillingService.LIMITED_PLANS_DISPLAY_NAME[planSlug]
    : 'Current';
}

export function isOnNormalProductTrialPlan(plan: IBillingPlan | null) {
  const planSlug = plan?.plan_slug;
  return planSlug === BillingService.PRODUCT_TRIAL_PLAN_SLUG;
}

export function isOnExtendedProductTrialPlan(plan: IBillingPlan | null) {
  const planSlug = plan?.plan_slug;
  return planSlug === BillingService.PRODUCT_TRIAL_EXTENDED_PLAN_SLUG;
}

export function isProductTrialExpired(plan: IBillingPlan | null) {
  const planActive = plan?.active;
  return isSomeProductTrialPlan(plan) && !planActive;
}

export function hasManageBillingPermission(currentUser: ICurrentUser | null) {
  const currentUserAbilitiesSlug = currentUser?.abilities.map(a => a.slug) || [];
  return currentUser?.role === 'account_owner'
    ? true
    : currentUserAbilitiesSlug.includes('manage-billing');
}
