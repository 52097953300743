import { INITIAL_STATE } from '../state';
import {
  APP_LIMITS__RESET_LIMIT_MESSAGE,
  APP_LIMITS__SET_LIMIT_MESSAGE,
} from '../../const/appLimits';

const initialAppState = INITIAL_STATE.APP_LIMITS;

const appLimits = (state = initialAppState, action: any) => {
  switch (action.type) {
    case APP_LIMITS__SET_LIMIT_MESSAGE:
      return {
        ...state,
        ...{
          time: new Date().getTime(),
          message: action.payload.message,
          description: action.payload.description,
        },
      };
    case APP_LIMITS__RESET_LIMIT_MESSAGE:
      return {
        ...state,
        ...{
          time: -1,
          message: '',
          shouldUpgrade: false,
        },
      };
    default:
      return state;
  }
};

export default appLimits;
