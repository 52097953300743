import * as React from 'react';
import { SVGProps } from 'react';

const SvgServiceNowLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#032d42',
      }}
      d="M0 0h20v20H0z"
    />
    <path
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#62d84e',
      }}
      d="M10 2.79c-4.48 0-8 3.52-8 8 0 2.346.96 4.48 2.453 5.973.534.533 1.494.533 2.134.106.853-.746 2.133-1.173 3.413-1.173 1.387 0 2.453.427 3.413 1.173.64.533 1.494.427 2.134-.213C17.04 15.163 18 13.136 18 10.789c-.107-4.373-3.627-8-8-8m-.107 12.16c-2.453 0-4.053-1.813-4.053-4.053s1.6-4.053 4.053-4.053 4.054 1.813 4.054 4.053-1.6 4.053-4.054 4.053"
    />
  </svg>
);

export default SvgServiceNowLogo20X20;
