import * as React from 'react';
import { SVGProps } from 'react';

const SvgCheckmk = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M18.7 10V5.4c0-.3-.1-.4-.3-.6-.5-.3-1.1-.6-1.6-.9-.7-.4-1.3-.8-2-1.2l-3-1.8c-.5-.3-1-.6-1.6-.9h-.3c-.7.4-1.4.8-2.1 1.3-1 .5-1.9 1-2.8 1.6-1.1.7-2.3 1.3-3.4 2-.2 0-.3.2-.3.5v8.8c0 .6.1.9.6 1.2.9.4 1.8 1 2.6 1.5.9.5 1.9 1.1 2.8 1.6.9.5 1.7 1 2.6 1.5h.2l2.1-1.2c.9-.5 1.8-1.1 2.7-1.6 1.1-.7 2.3-1.3 3.4-2 .2-.1.3-.3.3-.5.1-1.6.1-3.2.1-4.7zm-3.2.2c0 .2-.1.2-.2.1-.4-.2-.8-.5-1.1-.7-.8-.5-1.5-1-2.3-1.4-.1-.1-.2-.2-.3-.2-.1.1-.1.2-.1.3v6.1c0 .2-.1.3-.2.4-.4.2-.7.4-1.1.6-.2.1-.3.1-.5.1-.3-.3-.7-.4-1-.6-.2-.1-.3-.2-.3-.5V8.2c0-.1 0-.2-.1-.3 0 .1-.1.2-.2.2-1.1.8-2.3 1.5-3.5 2.3-.2.1-.3.1-.3-.1V7.5c0-.1.1-.2.2-.2 1-.6 2-1.2 2.9-1.8C8.2 4.9 9 4.4 9.8 4c.1-.1.3-.1.4 0 .3.2.5.3.8.5.6.4 1.2.8 1.8 1.1.9.5 1.7 1.1 2.6 1.6.1.1.1.1.1.2v2.8z"
      style={{
        fill: '#14d289',
      }}
    />
  </svg>
);

export default SvgCheckmk;
