import { FilterOptionsType } from './types';

export const FilterOptions: FilterOptionsType = {
  alert_sources: 'Alert Sources',
  tags: 'Tags',
  services: 'Services',
  teams: 'Teams',
  users: 'Assignee',
  postmortem_status: 'Status',
  priority: 'Priority',
  squads: 'Squads',
};
