import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API } from 'core';
import { queryKeys, reactQueryConfig } from '../constant';
import { IJiraAccForTeam } from '../../../../../../../core/interfaces/IJiraNew';
import { reactQueryConfigError } from '../helpers/service.reactqery';

const getAllJiraCloudAccountsOfTeam = (teamId: string) => async () => {
  const { data } = await axios.get<IJiraAccForTeam>(
    `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud/new/jira-accounts-by-team/${teamId}`,
  );
  return data.data;
};

export const useGetAllJiraAccountsOfTeam = (teamId: string) => {
  return useQuery(
    [queryKeys.GETALLJIRACLOUDACCOUNTSOFTEAM, teamId],
    getAllJiraCloudAccountsOfTeam(teamId),
    {
      ...reactQueryConfig,
      enabled: !!teamId,
      retry: 1,
      onError: (error: any) => {
        const description =
          error?.response?.data?.meta?.error_message || 'Failed to Get Jira Account Of Team';
        reactQueryConfigError({ description: description, title: 'Get Jira Account Of Team' });
      },
    },
  );
};
