import { PropsWithChildren } from 'react';
import { create } from 'zustand';
import PageLoaderComponent from '../../components/PageLoader';

type InitOrgState = {
  initOrgSuccess: boolean;
  setIsBillingInfoLoaded: (loaded: boolean) => void;
};

export const useInitOrg = create<InitOrgState>((set, get) => ({
  initOrgSuccess: false,
  setIsBillingInfoLoaded: (loaded: boolean) =>
    set({
      initOrgSuccess: loaded,
    }),
}));

export const {
  getState: getInitOrgState,
  subscribe: subscribeToInitOrgState,
  setState: setInitOrgState,
} = useInitOrg;

type Props = any;
export const InitOrgGate = (props: PropsWithChildren<Props>) => {
  const isBillingInfoLoaded = useInitOrg(state => state.initOrgSuccess);
  return isBillingInfoLoaded ? props.children : <PageLoaderComponent />;
};
