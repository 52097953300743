import React, { useEffect, useState } from 'react';
import {
  TextButton,
  Theme,
  Para,
  Grid,
  DialogModalFrame,
  JSONCodeBlock,
  CopyIconButton,
} from 'uie/components';
import { IIEvent } from '../../../../../core/interfaces/IIncidents';
import { IncidentService } from '../../../../../core/services';
import { exception } from '../../../../../core/exception';
import { EyeFilledBlueIcon } from 'library/icons';

const { theme } = Theme;

const IncidentDetailsViewPayload = (props: { incidentId: string }) => {
  const _incidentService = new IncidentService();
  const [payload, setPayload] = useState<IIEvent | null>(null);
  const [modal, setModal] = useState<'open' | ''>('');

  const getPayload = async () => {
    if (payload !== null) return;

    try {
      const {
        data: {
          data: { events: eventResults },
        },
      } = await _incidentService.getIncidentPayload(props.incidentId);
      setPayload(eventResults[0]);
    } catch (err: any) {
      exception.handle('E_GET_INCIDENT_PAYLOAD', err);
    }
  };

  const openPayloadModal = () => setModal('open');
  const closePayloadModal = () => setModal('');

  useEffect(() => {
    getPayload();
  }, []);

  return (
    <>
      {payload && payload.payload !== null && (
        <>
          <TextButton
            buttonType="ghost"
            color={theme.primary.default}
            onClick={openPayloadModal}
            width={'140px'}
          >
            <Grid alignItems="center">
              <EyeFilledBlueIcon height={16} width={16} />
              <Para fontSize={12} className="ml-5" color={theme.primary.default} fontWeight={500}>
                View Payload
              </Para>
            </Grid>
          </TextButton>
          <DialogModalFrame
            id="incident_details__payload_view"
            onClose={closePayloadModal}
            padding="16px"
          >
            {modal !== '' && (
              <Grid type="column">
                <Grid alignItems="center">
                  <Para
                    fontWeight={500}
                    color={theme.shades.cement}
                    fontSize={24}
                    className="pr-20"
                  >
                    Incident payload
                  </Para>
                  <div className="incident_details__copy-incident">
                    <CopyIconButton label="Copy incident payload" type="transparent">
                      {JSON.stringify((payload || {}).payload || {})}
                    </CopyIconButton>
                  </div>
                </Grid>
                <div className="mt-20">
                  <JSONCodeBlock
                    code={(payload || {}).payload || {}}
                    enableSearch={true}
                    shellProps={{
                      minHeight: '700px',
                      minWidth: 'calc(100% - 15px)',
                    }}
                  />
                </div>
              </Grid>
            )}
          </DialogModalFrame>
        </>
      )}
    </>
  );
};

export default IncidentDetailsViewPayload;
