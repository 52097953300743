import {
  APP_CONFIG__SET_MODAL_STATE,
  APP_CONFIG__SET_DASH_CONFIGS,
  APP_CONFIG__SET_LIST_CONFIGS,
  APP_CONFIG__SET_SIDEBAR_STATE,
  APP_CONFIG__SET_ANALYTICS_CONFIGS,
  APP_STATE_SET_FILTER,
  APP_STATE_SET_CURRENT_INCIDENT,
} from '../../const/appConfigs';
import { IAnalyticsConfig } from '../../interfaces/IAnalytics';
import { IModalState } from '../../interfaces/IAppConfigs';
import { IAppState } from '../../interfaces/IAppState';
import { IIncident } from '../../interfaces/IIncidents';

/**
 * @deprecated do not use this action. in support for older codes
 * @param modal
 */
const setModalState = (modal: IModalState) => ({
  type: APP_CONFIG__SET_MODAL_STATE,
  payload: {
    modal,
  },
});

/**
 * store state of dashboard on navigation
 * @param dash IState of src/views/main/organization/dashboard
 */
const setDashConfigs = (dash: any) => ({
  type: APP_CONFIG__SET_DASH_CONFIGS,
  payload: {
    dash,
  },
});

/**
 * store state of incidentList on navigation
 * @param list IState of src/views/main/organization/incidentList
 */
const setIncidentListConfigs = (list: any) => ({
  type: APP_CONFIG__SET_LIST_CONFIGS,
  payload: {
    list,
  },
});

/**
 * store state of incidentList on navigation
 * @param config IState of src/views/main/organization/analytics
 */
const setAnalyticsConfigs = (config: IAnalyticsConfig) => ({
  type: APP_CONFIG__SET_ANALYTICS_CONFIGS,
  payload: {
    config,
  },
});

/**
 * store state of sidebar on navigation
 * @param sidebarState IState of src/views/main
 */
const setSidebarState = (sidebarState: IAppState['APP_CONFIG']['sidebarState']) => ({
  type: APP_CONFIG__SET_SIDEBAR_STATE,
  payload: {
    sidebarState,
  },
});

const setAppStateFilter = (filter: 7 | 84) => ({
  type: APP_STATE_SET_FILTER,
  payload: {
    filter,
  },
});

const setAppStateCurrentIncident = (
  updateMethod: 'reload-incident' | 'reload-incident-warroom' | '',
  incident: IIncident | null,
) => ({
  type: APP_STATE_SET_CURRENT_INCIDENT,
  payload: {
    incident,
    updateMethod,
  },
});

export {
  setModalState,
  setDashConfigs,
  setIncidentListConfigs,
  setSidebarState,
  setAnalyticsConfigs,
  setAppStateFilter,
  setAppStateCurrentIncident,
};
