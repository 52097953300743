import * as React from 'react';
import { SVGProps } from 'react';

const SvgProgressWhatsupgoldLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#5ce500',
      }}
      d="M5.178 3.029 10.256 0l9.042 5.19V15.5l-5.077 2.829V7.84z"
    />
    <path
      style={{
        fill: '#5ce500',
      }}
      d="m.702 7.75 4.053-2.405 7.238 4.143v8.24L7.94 20v-8.396z"
    />
    <path
      style={{
        fill: '#5ce500',
      }}
      d="M5.58 13.051v5.279l-4.544-2.628z"
    />
  </svg>
);

export default SvgProgressWhatsupgoldLogo20X20;
