import { Box, Flex, Tag, TagLabel, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React from 'react';
import { Cell } from 'react-table';
import NodePopover from 'views/main/organization/service-catalog/components/Popover';

import { SUBSCRIPTION_STATUS_TYPE, subscriptionStatus } from '../../../constants/status.constants';
import { IPageSubscriberList, IStatusPageComponentList } from '../../../Interface';

export const tableColumns = [
  {
    Header: 'TYPE',
    accessor: (row: IPageSubscriberList) => row.type,
    Cell: (cell: Cell<IPageSubscriberList>) => {
      const {
        row: {
          original: { type },
        },
      } = cell;
      return <Text variant="tableData">{type}</Text>;
    },
  },
  {
    Header: 'EMAIL',
    accessor: (row: IPageSubscriberList) => row.emailID,
    Cell: (cell: Cell<IPageSubscriberList>) => {
      const {
        row: {
          original: { emailID },
        },
      } = cell;
      return <Text variant="tableData">{emailID || ''}</Text>;
    },
  },
  {
    Header: 'STATUS',
    accessor: (row: IPageSubscriberList) => row.status,
    Cell: (props: any) => {
      const {
        row: {
          original: { status },
        },
      } = props.cell;

      return (
        <Flex flex="1" align="center">
          <Box
            bgColor={subscriptionStatus[status as SUBSCRIPTION_STATUS_TYPE].color}
            w={3}
            h={3}
            borderRadius={6}
            mr={2}
          />
          <Box flex="1">
            <Text
              variant="tableData"
              color={subscriptionStatus[status as SUBSCRIPTION_STATUS_TYPE].color}
            >
              {subscriptionStatus[status as SUBSCRIPTION_STATUS_TYPE].text}
            </Text>
          </Box>
        </Flex>
      );
    },
  },
  {
    Header: 'WEBHOOK',
    accessor: (row: IPageSubscriberList) => row.webhookURL,
    Cell: (cell: Cell<IPageSubscriberList>) => {
      const {
        row: {
          original: { webhookURL },
        },
      } = cell;
      return <Text variant="tableData">{webhookURL || ''}</Text>;
    },
  },
  {
    Header: 'SUBSCRIBED COMPONENTS',
    accessor: (row: IPageSubscriberList) => row.componentList,
    Cell: (cell: Cell<IPageSubscriberList>) => {
      const {
        row: {
          original: { componentList },
        },
      } = cell;
      const remainingItems = componentList.slice(2);
      const componentsText = componentList
        .slice(0, 2)
        .map((component: IStatusPageComponentList) => component.name)
        .join(' | ');
      return (
        <Flex align="center">
          <Text mr={4} variant="tableData">
            {componentsText}
          </Text>
          {remainingItems.length > 0 ? (
            <NodePopover
              triggerType="hover"
              trigger={
                <Tag size="md">
                  <TagLabel>+ {remainingItems.length}</TagLabel>
                </Tag>
              }
            >
              {remainingItems.map((component: IStatusPageComponentList) => (
                <Text key={component.id} variant="tableData">
                  {component.name}
                </Text>
              ))}
            </NodePopover>
          ) : null}
        </Flex>
      );
    },
  },
  {
    Header: 'SUBSCRIBED ON',
    accessor: (row: IPageSubscriberList) => row.subscribedOn,
    Cell: (cell: Cell<IPageSubscriberList>) => {
      const {
        row: {
          original: { subscribedOn },
        },
      } = cell;
      return (
        <Text variant="tableData">
          {DateTime.fromISO(subscribedOn).toLocaleString(DateTime.DATE_MED)}
        </Text>
      );
    },
  },
];
