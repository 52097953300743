import { CustomPeriodUnit } from 'views/main/organization/schedules/graphql/types';
import { IfilterObject } from '../interface/schedule';

export const maxFilterValues = 5;

export const maxActiveFilters = 4;

export const filterSchedules = {
  heading: 'Filter',
  labels: {
    participants: 'Participants',
    escalationPolicy: 'Escalation Policy',
    schedules: 'Schedules',
    myOnCall: 'Show My On-Call',
    owner: 'Schedule Owner',
    withoutEscalationPolicy: 'Schedules without Escalation Policy',
    limitOnValue: '(you can select upto ' + maxFilterValues + ' values)',
  },
  placeholders: {
    participants: 'Type or select the user or squad',
    escalationPolicy: 'Type or select the escalation policy',
    schedule: 'Type or select the schedule',
    owner: 'Search for users or squads',
  },
  buttonTexts: {
    apply: 'Apply',
    clearFilters: 'Clear Filters',
    cancel: 'Cancel',
  },
  selectOptions: {
    rotationType: [
      { id: '1', label: 'Daily', value: CustomPeriodUnit.Day },
      { id: '2', label: 'Weekly', value: CustomPeriodUnit.Week },
      { id: '3', label: 'Monthly', value: CustomPeriodUnit.Month },
    ],
  },
};

export enum FILTER_TYPES {
  PARTICIPANT = 'p',
  ESCALATION_POLICY = 'ep',
  MY_ON_CALL = 'moc',
  NO_ESCALATION_POLICY = 'nep',
  SCHEDULE = 'sch',
}

export enum activeFilterKeys {
  PARTICIPANT = 'Participant',
  ESCALATION_POLICY = 'Escalation Policy',
  MY_ON_CALL = 'moc',
  NO_ESCALATION_POLICY = 'nep',
  SCHEDULE = 'Schedule',
}

export const filterObjectWithoutFilters: IfilterObject = {
  [FILTER_TYPES.MY_ON_CALL]: false,
  [FILTER_TYPES.NO_ESCALATION_POLICY]: false,
  [FILTER_TYPES.PARTICIPANT]: [],
  [FILTER_TYPES.ESCALATION_POLICY]: [],
  [FILTER_TYPES.SCHEDULE]: [],
};
