import { InfoIcon } from '@chakra-ui/icons';
import { Table, TableProps, Tbody, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { tooltipStyle } from 'views/main/organization/slo/slo.constants';

interface ITableCustomProps extends TableProps {
  tableHead: Array<{ name: string; tooltip: string }>;
}

export const MemberTable = (props: ITableCustomProps) => (
  <Table variant="simple" {...props}>
    <Thead bg="#f5f5f5">
      <Tr>
        {props.tableHead.map((head, index) => {
          return (
            <Th
              textAlign={'left'}
              color={'#2d3964'}
              whiteSpace="break-spaces"
              overflowX={'auto'}
              p={2}
              fontWeight="extrabold"
              fontSize={12}
              key={index}
            >
              {head.tooltip ? (
                <div>
                  {head.name}
                  <Tooltip hasArrow label={head.tooltip} {...tooltipStyle}>
                    <InfoIcon ml={1} />
                  </Tooltip>
                </div>
              ) : (
                head.name
              )}
            </Th>
          );
        })}
      </Tr>
    </Thead>
    <Tbody>{props.children}</Tbody>
  </Table>
);
