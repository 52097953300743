export interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  assigneeId?: string;
  assigneeType?: string;
  incidentId: string;
  incidentName: string;
  screenName: string;
}

export interface UnSnoozeType {
  title: string;
  type: UNSNOOZE_TYPES;
}

export interface ReassignmentTileProps {
  handleReassignmentChange: (arg0: UNSNOOZE_TYPES) => void;
  selectedReassignmentValue: UNSNOOZE_TYPES;
}

export enum UNSNOOZE_TYPES {
  NONE = '',
  REASSIGN_TO_USER_WHO_SNOOZED = 'REASSIGN_TO_USER_WHO_SNOOZED',
  REASSIGN_TO_SELF = 'REASSIGN_TO_SELF',
  REASSIGN_TO_DIFFERENT_USER = 'REASSIGN_TO_DIFFERENT_USER',
}
