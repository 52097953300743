import { requestOrganizationStakeholders } from 'core/actions/organization/stakeholders';
import { InitOrgGate } from 'core/hooks/useInitOrgGate';
import { UserAccessProvider } from 'core/userAccess/UserAccessContext';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import PageLoaderComponent from '../../components/PageLoader';
import { onLoginSuccess, requestSaveUserDevice, requestUserLogout } from '../../core/actions/auth';
import { requestInitApp } from '../../core/actions/init';
import { requestOrganizationCalendar } from '../../core/actions/organization/calender';
import { requestOrganizationEscalation } from '../../core/actions/organization/escalation';
import { requestInitOrg } from '../../core/actions/organization/initOrg';
import { onRequestUserOrganizationSuccess } from '../../core/actions/organization/initUserOrg';
import { requestOrganizationRunbook } from '../../core/actions/organization/runbooks';
import { requestOrganizationSelectedTeamChange } from '../../core/actions/organization/selectedTeam';
import { requestOrganizationService } from '../../core/actions/organization/services';
import { requestOrganizationSquad } from '../../core/actions/organization/squads';
import { requestOrganizationTeams } from '../../core/actions/organization/teams';
import { requestOrganizationUsers } from '../../core/actions/organization/users';
import { IAppState } from '../../core/interfaces/IAppState';
import { AuthRoutes } from '../auth';
import { OrganizationRoutes } from '../main';
import { done, onComponentUpdate, shouldComponentUpdate } from './appInit.helpers';
import {
  checkIfBrowserIsOnline,
  initPusherEvents,
  orgHasEnabledSSO,
  resetStates,
  setMountStates,
  ssoInitTokenIsDifferent,
} from './helpers';
import Mobile from './mobile';
import OnBoard from './onboard';

export interface IProps extends IAppState {
  requestInitApp: typeof requestInitApp;
  requestInitOrg: typeof requestInitOrg;
  onLoginSuccess: typeof onLoginSuccess;
  requestUserLogout: typeof requestUserLogout;
  requestOrganizationUsers: typeof requestOrganizationUsers;
  requestOrganizationSquad: typeof requestOrganizationSquad;
  requestOrganizationEscalation: typeof requestOrganizationEscalation;
  requestOrganizationService: typeof requestOrganizationService;
  requestOrganizationCalendar: typeof requestOrganizationCalendar;
  onRequestUserOrganizationSuccess: typeof onRequestUserOrganizationSuccess;
  requestOrganizationTeams: typeof requestOrganizationTeams;
  requestOrganizationSelectedTeamChange: typeof requestOrganizationSelectedTeamChange;
  requestOrganizationRunbook: typeof requestOrganizationRunbook;
  requestOrganizationStakeholders: typeof requestOrganizationStakeholders;
}

export interface IState {
  networkState: 'offline' | 'online';
  componentState: 'idle' | 'new-login' | 'refresh' | 'finished';
  sessionId: string;
  organizationId: string;
  hostname: string;
  deviceId: string;
  organizationNameEdit: string;
  forceRoot: boolean;
  cannotChangeOrg: boolean;
  isOrgChange: boolean;
  ssoEnabled: boolean;
  ssoChecked: boolean;
}

/**
 * responsible to hydrate internal store from scratch, start redux fetch cycles,
 * init logs, pusher
 * and manage route access
 */
export class Init extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      networkState: 'online',
      componentState: 'idle',
      sessionId: '',
      organizationId: '',
      hostname: '',
      deviceId: '',
      organizationNameEdit: '',
      forceRoot: false,
      cannotChangeOrg: false,
      isOrgChange: false,
      ssoEnabled: false,
      ssoChecked: false,
    };

    window.addEventListener('online', this.checkIfBrowserIsOnline, { once: true });
    window.addEventListener('offline', this.checkIfBrowserIsOnline, { once: true });
  }

  setMountStates = setMountStates;
  ssoInitTokenIsDifferent = ssoInitTokenIsDifferent;
  checkIfBrowserIsOnline = () => {
    checkIfBrowserIsOnline.call(this);
  };
  resetStates = resetStates;
  orgHasEnabledSSO = orgHasEnabledSSO;
  initPusherEvents = initPusherEvents;

  componentDidMount = setMountStates;
  shouldComponentUpdate = shouldComponentUpdate;
  componentDidUpdate = onComponentUpdate;
  done = done;

  public render() {
    const { componentState, networkState, isOrgChange, ssoEnabled, sessionId } = this.state;

    if (window.location.pathname === '/sso/enabled') {
      return <AuthRoutes {...{ networkState }} />;
    }

    if (ssoEnabled) {
      return (
        <BrowserRouter>
          <Redirect to="/sso/enabled" exact={true} />
        </BrowserRouter>
      );
    }

    if (this.state.forceRoot && window.location.pathname !== '/') {
      return (
        <BrowserRouter>
          <Redirect to="/" exact={true} />
        </BrowserRouter>
      );
    }

    if (componentState === 'new-login' || isOrgChange) {
      return <PageLoaderComponent />;
    }

    if (
      sessionId !== '' &&
      (componentState === 'finished' || componentState === 'refresh') &&
      !this.ssoInitTokenIsDifferent()
    ) {
      if (
        (this.props.userInfo.d && this.props.userInfo.d!.time_zone === '') ||
        this.props.userOrganizations.o.length === 0 ||
        this.props.userOrganizations.o.every(o => o.deActivated)
      ) {
        return <OnBoard />;
      }

      if (window.isMobile) {
        return <Mobile />;
      }

      return (
        <BrowserRouter>
          <UserAccessProvider>
            <InitOrgGate>
              <OrganizationRoutes
                {...{ networkState, isRefresh: this.state.componentState === 'refresh' }}
              />
            </InitOrgGate>
          </UserAccessProvider>
        </BrowserRouter>
      );
    }

    if (
      (this.props.auth.sessionId === '' || this.ssoInitTokenIsDifferent()) &&
      componentState === 'idle'
    ) {
      return <AuthRoutes {...{ networkState }} />;
    }

    return <PageLoaderComponent />;
  }
}

export default connect((state: IAppState) => state, {
  requestInitApp,
  requestInitOrg,
  requestOrganizationUsers,
  requestOrganizationSquad,
  requestOrganizationEscalation,
  requestOrganizationService,
  requestOrganizationCalendar,
  onLoginSuccess,
  requestSaveUserDevice,
  requestUserLogout,
  requestOrganizationSelectedTeamChange,
  requestOrganizationTeams,
  onRequestUserOrganizationSuccess,
  requestOrganizationRunbook,
})(Init);
