import React from 'react';

import { ErrorToastBlock, Para, SuccessToastBlock, Theme, ToastContext } from 'uie/components';

const { theme } = Theme;

function useShowToast() {
  const _createToast = ToastContext();

  const showErrorToast = (message: string) => {
    _createToast(
      <ErrorToastBlock style={{ backgroundColor: theme.danger.light }} maxWidth="400px">
        <Para fontWeight={400}>{message}</Para>
      </ErrorToastBlock>,
    );
  };

  const showSuccessToast = (message: string) => {
    _createToast(
      <SuccessToastBlock style={{ backgroundColor: theme.success.light }} maxWidth="250px">
        <Para fontWeight={400} style={{ whiteSpace: 'pre-wrap', textTransform: 'capitalize' }}>
          {message}
        </Para>
      </SuccessToastBlock>,
      3000,
    );
  };

  return {
    showErrorToast,
    showSuccessToast,
  };
}

export default useShowToast;
