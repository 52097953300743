import { Box, Circle, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { toSentenceCase, truncate } from 'core/helpers/stringUtils';
import { EscalationIcon } from 'icons';
import { Tag } from 'library/atoms';
import { THEME_COLORS } from 'library/theme/colors';
import { FC, useContext, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { getIncidentDetailsPath, toDate } from 'views/main/organization/incident-list/common/util';
import { IncidentStatusType } from 'views/main/organization/incident-list/graphql/generated/types';
import {
  ListRecentIncidentsQuery,
  RecentIncident,
} from 'views/main/organization/incident-list/graphql/query';
import TagList from 'views/main/organization/incident-list/listing/TagList';
import {
  EntityType,
  hasReadAccessToEntity,
} from 'views/main/organization/navigation-flows/helpers';
import useUINavigationFunctions from 'views/main/organization/navigation-flows/useUINavigationFunctionV2';
import { INCIDENT_LIST_PATH, SERVICE_DETAILS_PATH } from 'views/main/routes/routes';

import { OrganizationContext } from '../..';
import { getHealthStatus, getHealthStatusColor } from '../../helpers/helper.graph';
import { ServiceDetail } from '../../hooks/useServiceDetail';
import { SERVICE_NODE_DETAIL_TYPE } from '../../Interfaces/graph';
import { DetailBox, Heading, LinkTitle } from '../common/components';

type Props = {
  serviceNode: SERVICE_NODE_DETAIL_TYPE;
  data?: ServiceDetail;
  incidents?: ListRecentIncidentsQuery['listIncidents']['data'];
  incidentsLoading: boolean;
};

export const ServiceNodeDetails: FC<Props> = ({
  serviceNode,
  data,
  incidents,
  incidentsLoading,
}) => {
  const { getEntityLink, getEntityObject } = useUINavigationFunctions();
  const organization = useContext(OrganizationContext);

  const getNavLink = (type?: string, id?: string) => {
    if (!type || !id) {
      return;
    }
    const entityType = type as EntityType;
    if (hasReadAccessToEntity(entityType, organization) && !!getEntityObject(entityType, id)) {
      return getEntityLink(entityType, id);
    }
  };

  const dependenciesPath = useMemo(
    () => `${generatePath(SERVICE_DETAILS_PATH, { id: serviceNode.id })}?tab=dependencies`,
    [serviceNode.id],
  );

  const incidentsListPath = useMemo(
    () => `${INCIDENT_LIST_PATH}?filter=true&services=${serviceNode.id}`,
    [serviceNode.id],
  );

  const epPath = useMemo(
    () => getNavLink('escalationpolicy', data?.escalation_policy?.id),
    [serviceNode.id, data?.escalation_policy.id],
  );

  const ownerPath = useMemo(
    () => getNavLink(data?.maintainer?.type, data?.maintainer?.id),
    [serviceNode.id, data?.maintainer],
  );

  const getRelativeTime = (incident: RecentIncident) => {
    return incident.status === IncidentStatusType.Acknowledged
      ? toDate(incident.lastAcknowledgedAt)
      : toDate(incident.timeOfCreation);
  };

  const tags =
    data?.tags?.map(tag => ({
      ...tag,
      color: THEME_COLORS.brand.blueActive,
    })) ?? [];

  if (!data) {
    return <></>;
  }

  return (
    <Box overflowX="hidden">
      <DetailBox>
        <Text color="secondary.700" variant="formInput">
          {data.description}
        </Text>
        <Box>
          <Tag
            variant="filter"
            title={`Service with${serviceNode.isGraphNode ? '' : 'out'} dependency`}
          />
        </Box>
      </DetailBox>
      <DetailBox>
        <Heading title="service status" />
        <HStack columnGap={2} alignItems="center">
          <Circle size="12px" bg={getHealthStatusColor(serviceNode.status)} />
          <Text color="secondary.700" variant="formInput">
            {getHealthStatus(serviceNode.status)}
          </Text>
        </HStack>
      </DetailBox>
      {!!tags.length && (
        <DetailBox>
          <Heading title="tags" />
          <TagList tags={tags} honorTagsPreference={false} />
        </DetailBox>
      )}
      <DetailBox>
        <Heading title="owner" />
        <LinkTitle path={ownerPath} title={data.maintainer?.name} />
      </DetailBox>
      <DetailBox>
        <Heading title="escalation policy" />
        <HStack alignItems="flex-start">
          <EscalationIcon />
          <LinkTitle path={epPath} title={data.escalation_policy?.name} />
        </HStack>
      </DetailBox>
      <DetailBox>
        <Heading title="recent incidents" />
        {!!incidents?.length && (
          <>
            {incidents.map(incident => {
              return incident ? (
                <HStack key={incident.id} gap={2}>
                  {incident.alertSource.iconURL.svg && (
                    <Box p={2} borderRadius="4px" borderWidth="1px">
                      <Image src={incident.alertSource.iconURL.svg} width="32px" />
                    </Box>
                  )}
                  <VStack alignItems="flex-start">
                    <LinkTitle
                      path={getIncidentDetailsPath(incident.id)}
                      title={truncate(incident.message, 70)}
                    />
                    <Text variant="body" color="secondary.700">
                      {toSentenceCase(incident.status)} {getRelativeTime(incident)}
                    </Text>
                  </VStack>
                </HStack>
              ) : (
                <></>
              );
            })}
          </>
        )}
        <LinkTitle path={incidentsListPath} title="View All Incidents" isActiveLink />
      </DetailBox>
      <DetailBox>
        <LinkTitle path={dependenciesPath} title="Manage Service Dependencies" isActiveLink />
      </DetailBox>
    </Box>
  );
};
