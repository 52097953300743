import { useFormik } from 'formik';
import { orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { UseMutationOptions } from 'react-query';

import { PAGE_TYPES } from '../constants/schema';
import { ISSUES_STATES, PAGES_STATUS_TYPES, reactQueryConfig } from '../constants/status.constants';
import {
  CreateStatusPageIssueMutation,
  CreateStatusPageIssueMutationVariables,
  UpdateStatusPageIssueMutation,
  UpdateStatusPageIssueMutationVariables,
  useCreateStatusPageIssueMutation,
  useUpdateStatusPageIssueMutation,
} from '../graphql/mutation';
import { useGetStatusIssueDetailsQuery } from '../graphql/query';
import {
  formatComponentToOption,
  formatIssueCreateData,
  getIssueStateData,
  getPageInputDetails,
} from '../helpers/helper.service';
import { reactQueryConfigError } from '../helpers/helper.toast';
import {
  IfilterOption,
  IMessageTemplate,
  IOptionComponent,
  ISelectedComponent,
  IStatusCode,
  IStatusIssueState,
} from '../Interface';
import { usePageDetails } from './usePageDetails';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_ISSUE_ADDED } from 'core/const/tracker';

type onSubmit = (values: any) => void;

const { initValues, schema } = getPageInputDetails(PAGE_TYPES.ISSUE);

const useCreateIssue = <TError = unknown, TContext = unknown>(
  onSuccess: (data: any) => void,
  options?: UseMutationOptions<
    CreateStatusPageIssueMutation,
    TError,
    CreateStatusPageIssueMutationVariables,
    TContext
  >,
) => {
  return useCreateStatusPageIssueMutation({
    ...options,
    onSuccess: data => {
      onSuccess(data as any);
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_ISSUE_ADDED);
    },
    onError: reactQueryConfigError('Create issue'),
  });
};

const useUpdateIssue = <TError = unknown, TContext = unknown>(
  onSuccess: (data: any) => void,
  options?: UseMutationOptions<
    UpdateStatusPageIssueMutation,
    TError,
    UpdateStatusPageIssueMutationVariables,
    TContext
  >,
) => {
  return useUpdateStatusPageIssueMutation({
    ...options,
    onSuccess: data => {
      onSuccess(data as any);
    },
    onError: reactQueryConfigError('Update issue'),
  });
};

export const usePageIssue = (pageId: number, onSubmitHandler: onSubmit, issueId = -1) => {
  const [isDataCalculated, setDataCalculated] = useState(false);
  const {
    details,
    isSuccess,
    refetch: refetchPageDetails,
    isRefetchingDetails,
  } = usePageDetails(pageId);

  const { mutateAsync: createIssue, isLoading: isCreateIssueProgress } = useCreateIssue(
    (data: any) => {
      onSubmitHandler(data);
      refetchPageDetails();
    },
  );
  const useIssueDetail = (issueId: number) => {
    const queryResult = useGetStatusIssueDetailsQuery(
      {
        id: issueId,
        pageID: pageId,
      },
      {
        ...reactQueryConfig,
        enabled: issueId !== -1, // Only run the query if issueId is not -1
      },
    );

    return {
      data: queryResult.data?.getStatusPageIssue || null,
      isSuccess: queryResult.isSuccess,
      refetch: queryResult.refetch,
      isRefetching: queryResult.isRefetching,
    };
  };
  const {
    data: issueDetails,
    isSuccess: isIssueDetailsAvailable,
    refetch,
    isRefetching,
  } = useIssueDetail(issueId);

  const { mutateAsync: updateIssue, isLoading: isUpdateIssueProgress } = useUpdateIssue(
    (data: any) => {
      if (refetch) {
        refetch();
      }
      refetchPageDetails();
      onSubmitHandler(data);
    },
  );

  const allDetailsAvilable = issueId === -1 ? isSuccess : isSuccess && isIssueDetailsAvailable;
  const isReInitiateRequired = allDetailsAvilable && !isRefetching && !isRefetchingDetails;

  const onSubmit = (values: any) => {
    const requestBody = formatIssueCreateData(values, pageId);
    const issueBody = getIssueStateData(values.issueStates, values.timezone);
    if (issueId === -1) {
      createIssue({
        input: {
          ...requestBody,
          issues: issueBody,
        },
      });
    } else {
      updateIssue({
        input: {
          ...requestBody,
          issues: issueBody,
          id: issueId,
        },
      });
    }
  };

  useEffect(() => {
    if (isReInitiateRequired) {
      const selectedCom: ISelectedComponent[] = [];
      initValues.allComponents = details.components.map((group: any) => {
        const comp: IOptionComponent = formatComponentToOption(group);
        if ((group.components || []).length > 0) {
          comp.options = group.components.map((c: any) => {
            const com = formatComponentToOption(c);
            if (issueDetails) {
              const filterResult = (issueDetails?.components || []).filter(
                (com: any) => com.id === c.id,
              )?.[0];
              if (filterResult) {
                com.checked = true;
                selectedCom.push({
                  id: c.id,
                  value: {
                    label: c.status.name,
                    value: c.status.id,
                  },
                  label: `${group.name} / ${c.name}`,
                });
              }
            }
            com.status = {
              label: c.status.name,
              value: c.status.id,
            };
            return com;
          });
        } else {
          if (issueDetails) {
            const filterResult = (issueDetails?.components || []).filter(
              (com: any) => com.id === group.id,
            )?.[0];
            if (filterResult) {
              comp.checked = true;
              selectedCom.push({
                id: group.id,
                value: {
                  label: group.status.name,
                  value: group.status.id,
                },
                label: group.name,
              });
            }
          }
          comp.status = {
            label: group.status.name,
            value: group.status.id,
          };
        }
        return comp;
      });

      (initValues.selectedComponents as ISelectedComponent[]) = selectedCom;

      initValues.title = issueDetails?.title || '';

      initValues.status = {
        label: details.status.name,
        value: details.status.id,
      };
      initValues.currentIssueState =
        issueDetails?.currentIssueState.name || ISSUES_STATES.INVESTIGATING;
      initValues.timezone = details.timezone;
      initValues.template = details.messageTemplates;
      initValues.statusCodes = details.statusCodes.map((status: IStatusCode) => {
        if (status.slug === PAGES_STATUS_TYPES.OPERATIONAL) {
          initValues.operationStatusCode = status.id;
        }
        return {
          value: status.id,
          label: status.message,
        };
      });

      if (initValues.currentIssueState === ISSUES_STATES.RESOLVED) {
        initValues.statusCodes = initValues.statusCodes.filter(
          (s: IfilterOption) => s.value === initValues.operationStatusCode,
        );
      }

      initValues.pageName = details.name;
      initValues.isMigrated = issueDetails?.isMigrated || false;
      let stateMessageCount = 0;
      initValues.issueStates = orderBy<IMessageTemplate>(details.messageTemplates, [
        'stepIndex',
      ]).map((state: IMessageTemplate) => {
        const stateObj: IStatusIssueState = {
          name: state.name,
          id: state.id,
          issueMessages: [],
        };
        const existingState = (issueDetails?.issues || []).filter(
          (s: any) => s.state.id === state.id,
        )?.[0];
        if (existingState) {
          stateObj.issueMessages = (existingState?.stateMessages || []).map((m: any) => {
            const fromUTC = DateTime.fromISO(m.timestamp, { zone: details.timezone });
            stateMessageCount = stateMessageCount + 1;
            return {
              text: m.text,
              tempText: m.text,
              isEdit: '0',
              isNew: false,
              date: fromUTC.toISODate(),
              time: fromUTC.toFormat('HH:mm'),
              timestamp: m.timestamp,
              id: m.id,
              isDelete: '0',
              isApiSavePending: '0',
              error: '',
            };
          });
        }
        return stateObj;
      });
      if (issueDetails?.isMigrated) {
        initValues.issueStates = (issueDetails?.issues || []).map((issue: any) => {
          const stateObj: IStatusIssueState = {
            name: issue?.state?.name,
            id: issue?.state?.id,
            issueMessages: [],
          };
          stateObj.issueMessages = (issue?.stateMessages || []).map((m: any) => {
            const fromUTC = DateTime.fromISO(m.timestamp, { zone: details.timezone });
            stateMessageCount = stateMessageCount + 1;
            return {
              text: m.text,
              tempText: m.text,
              isEdit: '0',
              isNew: false,
              date: fromUTC.toISODate(),
              time: fromUTC.toFormat('HH:mm'),
              timestamp: m.timestamp,
              id: m.id,
              isDelete: '0',
              isApiSavePending: '0',
              error: '',
            };
          });
          return stateObj;
        });
      }
      initValues.stateMessageCount = stateMessageCount.toString();
      setDataCalculated(true);
      formik.setValues({
        ...initValues,
      });
    }
  }, [isReInitiateRequired]);

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: schema,
    onSubmit: onSubmit,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnMount: false,
  });

  return {
    formik,
    isSuccess: allDetailsAvilable && isDataCalculated,
    isCreateIssueProgress,
    isUpdateIssueProgress,
    isRefetching: isRefetching,
  };
};
