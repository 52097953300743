import { useHistory, useParams } from 'react-router';
import LCRDetailsHeader from './LCRHeader/LCRDetailsHeader';
import LCRHeaderSummary from './LCRHeader/LCRHeaderSummary';
import { VStack, Center, usePrevious } from '@chakra-ui/react';
import PageLoader from 'components/PageLoader';
import LCRDetailsBody from './LCRBody';
import { useGetLCRDetails } from '../../hooks/lcrDetails';
import { LCRDefaultValue } from '../../common/constant';
import { useEffect, useRef } from 'react';
import { GlobalTeamIDGate } from 'core/hooks/useCompareGlobalAndEntityTeamId';
import { LCR_PATH } from 'views/main/routes/routes';
function LCRDetails() {
  const params = useParams<{ id: string }>();

  const { data, isLoading } = useGetLCRDetails(params.id);

  if (isLoading)
    return (
      <Center h="100vh">
        <PageLoader />
      </Center>
    );

  if (!data) {
    return <div>Not found</div>;
  }
  return (
    <>
      <GlobalTeamIDGate entityTeamId={data?.team_id} />
      <LCRDetailsHeader data={data ?? LCRDefaultValue} />
      <LCRHeaderSummary data={data ?? LCRDefaultValue} />
      <VStack mt={4} bg={'container.background'} align="stretch" pl="4" pt="4">
        <LCRDetailsBody />
      </VStack>
    </>
  );
}

export default LCRDetails;
