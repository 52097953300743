import {
  REQUEST_ORG_CURRENT_USER,
  REQUEST_ORG_CURRENT_USER_FAIL,
  REQUEST_ORG_CURRENT_USER_SUCCESS,
  REQUEST_ORG_USERS_SUCCESS,
} from '../../const/organization';
import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';
import { API } from '../../api';
import { IUsersOfOrganization } from '../../interfaces/IUserData';

const initialOrganizationCurrentUser = INITIAL_STATE.organization.currentUser;

const organizationCurrentUser = (state = initialOrganizationCurrentUser, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ u: null, error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_CURRENT_USER:
      return {
        ...state,
        ...{ error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_CURRENT_USER_SUCCESS:
      return {
        ...state,
        ...{ u: { ...state.u, ...action.payload.user }, error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_USERS_SUCCESS:
      return {
        ...state,
        ...{
          u: {
            ...state.u,
            ...action.payload.users.find((u: IUsersOfOrganization) => u.id === API.config.userId),
          },
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ORG_CURRENT_USER_FAIL:
      return {
        ...state,
        ...{ error: { type: REQUEST_ORG_CURRENT_USER_FAIL, reason: action.payload.error } },
      };
    default:
      return state;
  }
};

export default organizationCurrentUser;
