import { AssigneeType } from 'library/enums';

import { ORG, OWNER_OPTIONS } from '../types';

const getTeamOptions = (org: ORG): OWNER_OPTIONS[] => {
  const selectedTeam = org.selectedTeam.team;

  if (!selectedTeam) {
    return [];
  }

  const options = [
    {
      label: selectedTeam.name,
      value: selectedTeam.id,
      assigneeType: AssigneeType.Team,
    },
  ];

  return options;
};

const getAllUserOptions = (org: ORG): OWNER_OPTIONS[] => {
  const selectedTeam = org.selectedTeam.team;

  if (!org.users.u || !selectedTeam) {
    return [];
  }

  const membersOfSelectedTeam = selectedTeam.members.map(member => member.user_id);

  const options = org.users.u
    .filter(user => membersOfSelectedTeam.includes(user.id))
    .filter(user => user.role !== 'stakeholder')
    .sort((a, b) => a.first_name.localeCompare(b.first_name))
    .map(({ first_name, last_name, id, username_for_display: username }) => ({
      label: `${first_name} ${last_name}`,
      value: id,
      assigneeType: AssigneeType.User,
      username: username,
    }));

  return options;
};

const getAllSquadOptions = (org: ORG): OWNER_OPTIONS[] => {
  const selectedTeam = org.selectedTeam.team;

  if (!org.squads.s || !selectedTeam) {
    return [];
  }

  const membersOfSelectedTeam = selectedTeam.members.map(member => member.user_id);

  const options = org.squads.s
    .filter(squad => {
      return squad.members.some(member => membersOfSelectedTeam.includes(member));
    })
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name }) => ({
      label: name,
      value: id,
      assigneeType: AssigneeType.Squad,
    }));

  return options;
};

export const getAllOwnerOptions = (org?: ORG) => {
  // generates array of current team, squads, and users for Owner dropdown
  // uses redux store value as data source
  if (org) {
    const allSquadOptions = getAllSquadOptions(org);

    return [
      { label: 'Users', value: '', options: getAllUserOptions(org) },
      { label: 'Squads', value: '', options: allSquadOptions },
    ];
  }

  return [];
};
