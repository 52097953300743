import * as React from 'react';
import ProfileService from '../../../../../core/services/service.editprofile';

interface IProps {
  hide: () => void;
}

interface IState {
  saving: boolean;
  currentPassword: string;
  currentPasswordError: string;
  newPassword: string;
  newPasswordError: string;
  reenterNewPassword: string;
  reenterNewPasswordError: string;
}

class ChangePassword extends React.Component<IProps, IState> {
  private _profileService = new ProfileService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      saving: false,
      currentPassword: '',
      currentPasswordError: '',
      newPassword: '',
      newPasswordError: '',
      reenterNewPassword: '',
      reenterNewPasswordError: '',
    };
    this.save = this.save.bind(this);
  }

  public async save() {
    if (!this.state.currentPassword.trim()) {
      this.setState({
        currentPasswordError: 'Enter your current password',
      });
      return;
    }
    if (this.state.currentPassword.trim().length < 10) {
      this.setState({
        currentPasswordError: 'Password should be atleast 10 characters',
      });
      return;
    }
    if (!this.state.newPassword.trim()) {
      this.setState({
        newPasswordError: 'Enter your new password',
      });
      return;
    }
    if (this.state.newPassword.trim().length < 10 || this.state.newPassword.trim().length > 72) {
      this.setState({
        newPasswordError: 'New password should be between 10 and 72 characters',
      });
      return;
    }
    if (this.state.currentPassword.trim() === this.state.newPassword.trim()) {
      this.setState({
        newPasswordError: 'New password and current password cannot be the same',
      });

      return;
    }
    if (this.state.reenterNewPassword.trim() !== this.state.newPassword.trim()) {
      this.setState({
        reenterNewPasswordError: "The two new passwords don't match",
      });
      return;
    }
    this.setState({
      saving: true,
      newPasswordError: '',
      currentPasswordError: '',
    });
    try {
      await this._profileService.changePassword(this.state.currentPassword, this.state.newPassword);
      this.props.hide();
    } catch (e) {
      this.setState({
        currentPasswordError: 'Invalid current password',
        saving: false,
      });
    }
  }

  public render() {
    return (
      <div
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <div className="clearfix">
          <div className="float-left">
            <h1 className="modal-container-heading">Change Password</h1>
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div className="clearfix">
            <div className="field-parent-div" style={{ width: '100%' }}>
              <p className="modal-container-sub-heading" style={{ margin: 0, marginBottom: 10 }}>
                Current Password
              </p>
              <input
                id="current-password"
                type="password"
                onFocus={() => {
                  this.setState({ currentPasswordError: '' });
                }}
                onKeyUp={event => {
                  if (event.keyCode === 13) {
                    this.save();
                  }
                }}
                className={
                  'input-design ' + (this.state.currentPasswordError ? 'text-field-border' : '')
                }
                placeholder="Enter Current Password"
                value={this.state.currentPassword}
                onChange={event => {
                  this.setState({ currentPassword: event.target.value.trim() });
                }}
              />
              <div id="current-password-error-block" className="error-block">
                {this.state.currentPasswordError}
              </div>
            </div>
          </div>
          <div className="">
            <div className="field-parent-div" style={{ width: '100%' }}>
              <p className="modal-container-sub-heading" style={{ margin: 0, marginBottom: 10 }}>
                New Password
              </p>
              <input
                id="new-password"
                type="password"
                onFocus={() => {
                  this.setState({ newPasswordError: '' });
                }}
                onKeyUp={event => {
                  if (event.keyCode === 13) {
                    this.save();
                  }
                }}
                className={
                  'input-design ' + (this.state.newPasswordError ? 'text-field-border' : '')
                }
                placeholder="Enter New Password"
                value={this.state.newPassword}
                onChange={event => {
                  this.setState({ newPassword: event.target.value.trim() });
                }}
              />
              <div id="new-password-error-block" className="error-block">
                {this.state.newPasswordError}
              </div>
            </div>
          </div>
          <div className="">
            <div className="field-parent-div" style={{ width: '100%' }}>
              <p className="modal-container-sub-heading" style={{ margin: 0, marginBottom: 10 }}>
                Re-enter New Password
              </p>
              <input
                id="confirm-new-password"
                type="password"
                onFocus={() => {
                  this.setState({ reenterNewPasswordError: '' });
                }}
                onKeyUp={event => {
                  if (event.keyCode === 13) {
                    this.save();
                  }
                }}
                className={
                  'input-design ' + (this.state.reenterNewPasswordError ? 'text-field-border' : '')
                }
                placeholder="Re-Enter New Password"
                value={this.state.reenterNewPassword}
                onChange={event => {
                  this.setState({ reenterNewPassword: event.target.value.trim() });
                }}
              />
              <div id="confirm-new-password-error-block" className="error-block">
                {this.state.reenterNewPasswordError}
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <button className="main-button" onClick={this.save} disabled={this.state.saving}>
            {!this.state.saving ? 'Save' : <i className="fa fa-spin fa-spinner" />}
          </button>
        </div>
      </div>
    );
  }
}
export default ChangePassword;
