import * as React from 'react';
import { SVGProps } from 'react';

const SvgSysdigMonitor = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M12.132 17.929a4.81 4.81 0 0 1-4.271-2.498c-.371-.65-1.346-2.377-1.393-2.47-.427-.761.715-1.392 1.142-.64.009.019 1.012 1.811 1.383 2.451.919 1.597 2.609 2.201 4.513 1.625.836-.251 1.207 1.003.381 1.253a5.607 5.607 0 0 1-1.755.279zM12.28 20c-2.488 0-4.819-1.161-5.989-3.194l-2.674-4.633a.643.643 0 0 1 .241-.891l1.82-1.049a.648.648 0 0 1 .891.232.643.643 0 0 1-.241.891l-1.253.724 2.349 4.067c1.272 2.201 4.299 3.12 7.075 2.163 2.219-1.922 2.934-5.005 1.662-7.205l-2.247-3.881a.662.662 0 0 1 .232-.901.643.643 0 0 1 .891.241l2.247 3.89c1.606 2.776.724 6.62-2.043 8.942a.665.665 0 0 1-.195.111A7.975 7.975 0 0 1 12.28 20zm-.204-4.188c-1.031 0-1.903-.687-2.349-1.458L2.011.984c-.427-.752.696-1.402 1.133-.65l7.716 13.371c.214.362.808.994 1.56.752.826-.269 1.226.975.399 1.244a2.822 2.822 0 0 1-.743.111zm-1.671-6.898a.654.654 0 0 1-.566-.325L7.675 4.837c-.427-.752.696-1.402 1.133-.65l1.838 3.185 1.17-.678c.752-.427 1.402.696.65 1.133L10.73 8.83a.741.741 0 0 1-.325.084z"
      style={{
        fill: '#00abc7',
      }}
    />
  </svg>
);

export default SvgSysdigMonitor;
