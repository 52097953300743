import * as React from 'react';
import { connect } from 'react-redux';
import { setModalState } from '../../core/actions/app/configs';
import { IModalState } from '../../core/interfaces/IAppConfigs';

interface IProps {
  hide: () => void;
  displayText: string;
  show: boolean;
  setModalState: (state: IModalState) => void;
}

class MessageModal extends React.Component<IProps> {
  public componentDidMount() {
    this.props.setModalState('open');
  }

  public componentWillUnmount() {
    this.props.setModalState('closed');
  }

  public stopPropagationHandler = (event: any) => {
    event.stopPropagation();
  };

  public render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div
        className="modal-background faster animated fadeIn"
        onClick={this.props.hide.bind(null, false)}
      >
        <div
          className="modal-container faster animated slideInDown"
          onClick={this.stopPropagationHandler}
          style={{ width: 400 }}
        >
          <div className="">
            <div className="float-left">
              <h1 className="modal-container-heading">Message</h1>
            </div>
            <div className="float-right">
              <img
                className="cursor-pointer"
                src="/icons/close.svg"
                onClick={this.props.hide.bind(null, false)}
                alt="close"
              />
            </div>
          </div>
          <p className="item-box-tagline font-bold">{this.props.displayText}</p>
        </div>
      </div>
    );
  }
}
export default connect(null, {
  setModalState,
})(MessageModal);
