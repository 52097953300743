import { Box, Button, Center, Divider, HStack, Spinner } from '@chakra-ui/react';
import { FormikProvider } from 'formik';
import React, { useState } from 'react';

import { reactQueryConfigSuccess } from '../../../helpers/helper.toast';
import { usePageMaintenance } from '../../../hooks/usePageMaintenance';
import MaintenanceForm from '../form';

interface Props {
  pageId: number;
  onClose: () => void;
  refetchMaintenanceList: () => void;
}

const AddMaintenance: React.FC<any> = (props: Props) => {
  const [disableButton, setDisableButton] = useState(false);
  const { formik, isSuccess, loadingCreateMaintenance } = usePageMaintenance(props.pageId, () => {
    props.onClose();

    props.refetchMaintenanceList();
    reactQueryConfigSuccess('Maintenance window is created');
  });

  return isSuccess ? (
    <FormikProvider value={formik}>
      <MaintenanceForm newForm />

      <Box w="100%" bottom={0} position="fixed" bgColor={'white'} zIndex="2">
        <Divider />
        <HStack py={2} spacing={6}>
          <Button
            onClick={() => {
              !formik.errors && setDisableButton(true);
              formik.submitForm();
            }}
            variant="default"
            isDisabled={disableButton}
            isLoading={loadingCreateMaintenance}
          >
            Save
          </Button>
          <Button variant="invertedDefault" onClick={props.onClose}>
            Cancel
          </Button>
        </HStack>
      </Box>
    </FormikProvider>
  ) : (
    <Center h="60vh">
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
    </Center>
  );
};
export default AddMaintenance;
