export type SquadList = {
  data: Squads;
};

export type Squads = Squad[];

export type Squad = {
  id: string;
  org_id: string;
  team_id: string;
  name: string;
  members: Member[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
};

export type SquadMemberRole = 'owner' | 'member';
export type Member = {
  user_id: string;
  role: SquadMemberRole;
};

export type SquadAPIError = {
  meta: Meta;
};

export type Meta = {
  status: number;
  error_message: string;
};

export const SquadMemberRoleString = {
  owner: 'Squad Owner',
  member: 'Squad Member',
};

export type SquadMember = {
  user_id: string;
  role: SquadMemberRole;
  username_for_display?: string;
  email_verified?: boolean;
  name?: string;
  email?: string;
};

export type ConflictEntityData = {
  id: number;
  name: string;
};

export const ConflictEntityTypes = [
  'escalation_policies',
  'esc_policies_with_owners',
  'schedules',
  'routing_rules',
  'services',
  'services_with_delay_notifications_config',
  'webforms',
  'runbooks_owner',
  'slos',
  'schedules_owner',
  'schedulesV2',
  'global_event_rules_owner',
  'workflows',
  'status_pages_owner',
  'incident_groups',
  'incidents',
] as const;

export const ConflictEntityTypesString: Record<string, string> = {
  escalation_policies: 'Escalation Policies',
  esc_policies_with_owners: 'Escalation Policies Owners',
  schedules: 'Schedules',
  routing_rules: 'Routing Rules',
  services: 'Services',
  services_with_delay_notifications_config: 'Services With Delay Notifications Config',
  webforms: 'Webforms',
  runbooks_owner: 'Runbooks',
  slos: 'SLOs',
  schedules_owner: 'Schedules Owners',
  schedulesV2: 'Schedules V2',
  global_event_rules_owner: 'Global Event Rules Owner',
  workflows: 'Workflows',
  status_pages_owner: 'Status Pages',
  incident_groups: 'Incident Groups',
};

export type DeleteSquadConflictData = Record<string, Array<ConflictEntityData> | number>;
