import React, { useMemo } from 'react';
import { FilterOptionsLabel, SecondaryFiltersKeys, SelectOption } from './types';
import DropdownContainer from './filters/dropdown_container';
import { Select } from './filters/select';
import { OnChangeValue } from 'react-select';

interface IProps {
  options: FilterOptionsLabel[];
  activeOptions: SecondaryFiltersKeys[];
  chooseOption: (filter: SecondaryFiltersKeys) => void;
}

const AddFilterBtn = (props: IProps) => {
  const filterOptions = useMemo(
    () =>
      props.options.map(t => {
        return {
          label: t.label,
          value: t.key,
        };
      }),
    [props.options],
  );

  const selectedOptions = useMemo(
    () =>
      props.activeOptions.map(t => {
        return {
          label: t,
          value: t,
        };
      }),
    [props.activeOptions],
  );

  const handleChange = (newValue: OnChangeValue<SelectOption, true>) => {
    const arr: any = [];

    newValue.map(o => {
      arr.push(o.value);
    });
    arr.map((t: any) => {
      props.chooseOption(t);
    });
  };

  return (
    <DropdownContainer
      selected={[]}
      filterKey="alert_sources"
      label={'+Add Filter'}
      isPrimaryButton={true}
    >
      <Select
        options={filterOptions}
        value={selectedOptions}
        onChange={handleChange}
        controlShouldRenderValue={false}
        isClearable={false}
        closeDropdownOnSelect
        isMulti
        hideSelectedOptions
      />
    </DropdownContainer>
  );
};

export default AddFilterBtn;
