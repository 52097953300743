import { VStack, Text, HStack, Checkbox } from '@chakra-ui/react';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { FormButton } from 'library/atoms';
import { Modal } from 'library/molecules';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { useState } from 'react';

const UnmergeChildIncident = ({
  onClose,
  isOpen,
  unMerge,
  isUnmerging,
  latestAssigneeDeleted,
  location,
}: {
  onClose: () => void;
  unMerge: (assignToMe: boolean, sendNotification: boolean, location: 'parent' | 'child') => void;
  isOpen: boolean;
  isUnmerging: boolean;
  latestAssigneeDeleted: boolean;
  location: 'parent' | 'child';
}) => {
  const [assignToMe, setAssignToMe] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);

  const onClickUnmerge = () => {
    unMerge(assignToMe, sendNotification, location);
  };

  const hasUpdate = useUserAccess().hasUpdateAccess('incidents');

  return (
    <Modal
      key="resolve-incident"
      body={
        <VStack rowGap={1} alignItems="flex-start">
          <Text variant="formInput" color="secondary.700">
            {!latestAssigneeDeleted
              ? `Unmerging will re-trigger the incident and will be assigned to latest assignee. If
            latest assignee has been deleted, you have to assign incident to yourself`
              : `Latest assignee of this incident is deleted. Assign incident to yourself to unmerge and proceed`}
          </Text>
          <HStack gap={2.5}>
            <Checkbox checked={assignToMe} onChange={() => setAssignToMe(!assignToMe)} />
            <Text variant="formInput_800" color="secondary.700">
              Assign incident to me
            </Text>
          </HStack>
          <HStack gap={2.5}>
            <Checkbox
              checked={sendNotification}
              onChange={() => setSendNotification(!sendNotification)}
            />
            <Text variant="formInput_800" color="secondary.700">
              Send notification to latest assignee
            </Text>
          </HStack>
        </VStack>
      }
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={
        <NoPermissionTooltip isDisabled={hasUpdate}>
          <FormButton
            title="Unmerge"
            onClick={onClickUnmerge}
            isLoading={isUnmerging}
            variant="primary"
            isDisabled={!hasUpdate}
          />
        </NoPermissionTooltip>
      }
      secondaryButton={
        <NoPermissionTooltip isDisabled={hasUpdate}>
          <FormButton
            title="Cancel"
            onClick={onClose}
            disabled={isUnmerging}
            variant="secondary"
            isDisabled={!hasUpdate}
          />
        </NoPermissionTooltip>
      }
      title="Are you sure you want to unmerge?"
    />
  );
};

export default UnmergeChildIncident;
