import { Box, CircularProgress, HStack, Text, VStack } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/table-core';
import EntityUI, { EntityType } from 'library/molecules/EntityUI/EntityUI';
import { Checkbox, FormButton, Search } from 'library/atoms';
import Table from 'library/molecules/Tablev2/Table';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { runbooksApi } from '../../../../api';
import { useWorkflowFormContext } from '../../../../context/workflowFormContext';
import { ActionRunBook, RunbookAction, SubActionTypes } from '../../../../types';

const RunbookForm = () => {
  const { actionForm, setIsDirty } = useWorkflowFormContext();
  const { formState, setValue, watch } = useFormContext<RunbookAction>();

  const { data: runbookList = [], isLoading } = useQuery('runbooks', runbooksApi);

  const [searchValue, setSearchValue] = useState('');

  const selectedRunbooks = watch('data.runbooks');
  const onSelectRunbooks = useCallback((runbooks: RunbookAction['data']['runbooks']) => {
    setValue('data.runbooks', runbooks, { shouldDirty: true });
  }, []);
  const formatRunbooks = useCallback(
    (runbooks: typeof runbookList) => runbooks.map(r => ({ id: r.id, name: r.name })),
    [],
  );
  const onSelectRunbook = useCallback(
    (type: 'all' | 'single', runbook?: ActionRunBook) => (e: ChangeEvent<HTMLInputElement>) => {
      if (type === 'all') {
        onSelectRunbooks(e.target.checked ? runbookList : []);
      } else {
        if (runbook) {
          onSelectRunbooks(
            e.target.checked
              ? [...selectedRunbooks, ...formatRunbooks([runbook])]
              : selectedRunbooks.filter(r => r.id !== runbook.id),
          );
        }
      }
    },
    [runbookList, selectedRunbooks],
  );

  const columns = useMemo<ColumnDef<typeof runbookList[number], any>[]>(
    () => [
      {
        id: 'select-all',
        header: ({ table }) => {
          const isAllSelected = selectedRunbooks.length === table.getRowModel().rows.length;
          return (
            <Checkbox
              size="lg"
              isChecked={isAllSelected}
              onChange={onSelectRunbook('all')}
              isIndeterminate={!!selectedRunbooks.length && !isAllSelected}
            />
          );
        },
        cell: ({ row }) => {
          const isSelected = selectedRunbooks.some(r => r.id === row.original.id);
          return (
            <Checkbox
              size="lg"
              isChecked={isSelected}
              onChange={onSelectRunbook('single', row.original)}
            />
          );
        },
      },
      {
        header: 'Runbook Name',
        accessorKey: 'name',
        cell: ({ row }) => <Text>{row.original.name}</Text>,
      },
      {
        header: 'Steps',
        cell: ({ row }) => <Text>{row.original.steps.length}</Text>,
      },
      {
        header: 'Instances Used',
        cell: ({ row }) => <Text>{row.original.used_count}</Text>,
      },
      {
        header: 'Owner',
        cell: ({ row }) => (
          <EntityUI
            renderType="link-popover"
            type={row.original.entity_owner.type as EntityType}
            entityId={row.original.entity_owner.id}
          />
        ),
      },
      {
        header: 'Modified By',
        cell: ({ row }) => (
          <EntityUI renderType="link-popover" type="user" entityId={row.original.updated.user_id} />
        ),
      },
    ],
    [selectedRunbooks, onSelectRunbooks],
  );

  const onSearch = (value: string) => setSearchValue(value);

  useEffect(() => {
    if (!selectedRunbooks.length && actionForm && actionForm.name === SubActionTypes.RUNBOOKS) {
      const runbookIds = actionForm.data.runbooks.map(d => d.id);
      onSelectRunbooks(formatRunbooks(runbookList.filter(r => runbookIds.includes(r.id))));
    }
  }, []);

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <VStack>
      <HStack
        justifyContent="space-between"
        w="full"
        py={3}
        px={6}
        pos="sticky"
        top={0}
        bg="container.background"
        zIndex={2}
      >
        <Box flex={1}>
          {selectedRunbooks.length ? (
            <FormButton type="submit" title="Attach" />
          ) : (
            <Text variant="h5">Select one or more runbooks to attach</Text>
          )}
        </Box>
        <Box>
          <Search
            iconStyle={{ height: '36px', width: '36px' }}
            onSearch={onSearch}
            isDisabled={!runbookList.length}
            searchValue={searchValue}
          />
        </Box>
      </HStack>
      {isLoading ? (
        <VStack h="inherit" justifyContent="center">
          <CircularProgress isIndeterminate />
        </VStack>
      ) : (
        <Table
          data={runbookList}
          columns={columns}
          isLoading={isLoading}
          globalFilterValue={searchValue}
          headerStyle={{ pos: 'sticky' }}
          hidePagination
        />
      )}{' '}
    </VStack>
  );
};

export default RunbookForm;
