import React from 'react';
import styled from 'styled-components';
import Para from '../Para';
import Theme from '../Theme';

interface IProps {
  fontSize?: 16 | 14 | number;
  fontWeight?: 400 | 500;
  color?: string;
  width?: string | 'fit-content';
}

const StatusBlockDiv = styled.div<IProps>`
  height: fit-content;
  width: fit-content;
  text-align: left;
  text-transform: capitalize;
  margin: 0;
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 4px 8px;
  background: ${props => props.color || props.theme.shades.whiteSmoke}27;
  border-left: 12px solid ${props => props.color || props.theme.shades.whiteSmoke};

  & > p {
    font-size: ${props => props.fontSize || 14}px;
    font-weight: ${props => props.fontWeight || 400};
    width: ${props => props.width || 'fit-content'};
  }
`;

interface IStatusBlockProps extends IProps {
  status: 'triggered' | 'acknowledged' | 'resolved' | 'suppressed' | string;
}

const StatusBlock = (props: IStatusBlockProps) => {
  const { theme } = Theme;
  const context: any = {
    triggered: theme.trigger,
    acknowledged: theme.acknowledge,
    resolved: theme.success,
    suppressed: {
      default: theme.shades.cement,
      light: theme.shades.whiteSmoke,
    },
  };

  const color = context[props.status]
    ? context[props.status].default
    : props.color || theme.font.default;

  return (
    <StatusBlockDiv {...props} color={color}>
      <Para>{props.status}</Para>
    </StatusBlockDiv>
  );
};

export default StatusBlock;
