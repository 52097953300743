import { IIAssigneUnsnooze } from 'core/interfaces/IIncidents';

export const getSnoozeValueInHours = (snoozeInterval: number) => {
  if (snoozeInterval === 30) return '30 mins';
  else if (snoozeInterval === 60) return '1 hour';
  return `${snoozeInterval / 60} hours`;
};

export const reassignmentValue = (newAssigneeType: IIAssigneUnsnooze) => {
  switch (newAssigneeType) {
    case 'me':
      return 'Reassign to me';
    case 'escalationpolicy':
      return 'Reassign to different EP';
    case 'squad':
      return 'Reassign to different Squad';

    case 'user':
      return 'Reassign to different User';

    case 'snoozer':
      return 'Reassign to the user who snoozed the incident';
    default:
      return '';
  }
};
