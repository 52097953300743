import React, { ReactNode } from 'react';

import { Box, Container, ContainerProps } from '@chakra-ui/react';

type Props = {
  children: ReactNode;
  height?: ContainerProps['minHeight'];
  py?: number;
  overflow?: string;
};

function Layout({ children, height, py, overflow }: Props) {
  return (
    <Container
      display="flex"
      bg="gray.200"
      px="8px"
      py={py ?? 2}
      maxW="container.xxl"
      minHeight={height ?? 'calc(100vh - 64px)'}
      overflow={overflow ?? 'auto'}
    >
      <Box bg="shades.white" flex="1" borderRadius={4} position="relative">
        {children}
      </Box>
    </Container>
  );
}

export default Layout;
