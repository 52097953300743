import { Text } from '@chakra-ui/layout';
import { API } from 'core';
import { Tooltip } from 'library/atoms';
import { AssigneeType } from 'library/enums';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { ESCALATION_POLICIES_LIST_PATH, USER_PROFILE_PATH } from 'views/main/routes/routes';
import { hasReadAccessToEntity } from '../../navigation-flows/helpers';

export const truncate = (input: string, maxLength = 25) =>
  input.length > maxLength ? `${input.substring(0, maxLength)}...` : input;

const getNameComponent = (name: string, maxLength = 20, type?: AssigneeType) => {
  const label = (
    <Text variant="body" fontSize="15px">
      {truncate(name, maxLength)}
    </Text>
  );
  return name.length > maxLength && type !== AssigneeType.User ? (
    <Tooltip label={name}>{label}</Tooltip>
  ) : (
    label
  );
};

export const getAssigneeComponent = (id: string, type: AssigneeType, name: string) => {
  let assigneeLink = '';

  switch (type) {
    case AssigneeType.User:
      assigneeLink = generatePath(USER_PROFILE_PATH, { id });
      break;
    case AssigneeType.Escalationpolicy:
      assigneeLink = generatePath(ESCALATION_POLICIES_LIST_PATH, { id });
  }

  const nameComp = getNameComponent(name, 15, type);

  return hasReadAccessToEntity(type) ? <Link to={assigneeLink}>{nameComp}</Link> : nameComp;
};
