import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { REQUEST_ORG_STAKEHOLDERS, REQUEST_ORG_STAKEHOLDERS_FAIL } from '../../const/organization';
import { getStakeHolders } from '../../api/organization/getStakeHolders';
import { onRequestOrganizationStakeholdersSuccess } from 'core/actions/organization/stakeholders';
import { IStakeholders } from 'core/interfaces/IStakeholders';

const getStakeHoldersForTeamEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ORG_STAKEHOLDERS),
    map(action => action.payload),
    switchMap(() => {
      return getStakeHolders().pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ result, data }: { result: boolean; data: IStakeholders[] }) => {
          return onRequestOrganizationStakeholdersSuccess(data);
        }),
        catchError((error: AjaxError) => {
          if (error.status === 403) {
            return of(onRequestOrganizationStakeholdersSuccess([]));
          }
          return of({
            type: REQUEST_ORG_STAKEHOLDERS_FAIL,
            payload: error,
            error: true,
          });
        }),
      );
    }),
  );

export default getStakeHoldersForTeamEpic;
