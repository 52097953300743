import { InputBlock } from 'uie/components';
import { CardType } from 'core/interfaces/ICommunicationCard';
import { ChatOpsIcon, GenericAttachmentIcon, MsTeamsIcon, VideoIcon, SlackHashColor } from 'icons';
import React from 'react';
import styled from 'styled-components';

interface IProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  type?: CardType;
  icon?: React.ReactNode;
  error?: string;
}

const Wrapper = styled.div<{ error?: boolean }>`
  margin-top: 20px;
  margin-bottom: 24px;

  position: relative;

  .iconWrapper {
    position: absolute;
    top: 32px;
    left: 12px;
  }

  .labelWrapper {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .inputWrapper {
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    padding-left: 36px;
    border: ${props => (props.error ? '1px solid red' : '')};
  }
`;

export const DynamicIcon = ({ type, ...props }: { type?: CardType }) => {
  if (type === 'chat_room') {
    return <ChatOpsIcon height={16} width={16} {...props} />;
  }
  if (type === 'video_conference') {
    return <VideoIcon height={16} width={16} {...props} />;
  }
  if (type === 'slack') {
    return <SlackHashColor height={16} width={16} {...props} />;
  }
  if (type === CardType.msTeamsMeetingLink) {
    return <MsTeamsIcon height={16} width={16} {...props} />;
  }
  return <GenericAttachmentIcon height={16} width={16} {...props} />;
};

export const InputwithLabel: React.FC<IProps> = props => {
  return (
    <Wrapper>
      <span className="iconWrapper">
        {props.icon ? props.icon : <DynamicIcon type={props.type} />}
      </span>
      <span className="labelWrapper">{props.label}</span>
      <InputBlock
        className="inputWrapper"
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        error={props.error ? props.error.length > 0 : false}
      />
      {props.error ? <div className="errorForm">{props.error}</div> : null}
    </Wrapper>
  );
};
