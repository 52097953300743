import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { ISlo } from '../slo.interface';
import axios from 'axios';
import { API } from '../../../../../core/api';
import { queryKeys } from '../slo.constants';

const fetchAlreadySloName = async (name: string, teamID: string) => {
  const { data } = await axios.get<{
    data: {
      slo: ISlo;
    };
  }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo/by-name?name=${name}&owner_id=${teamID}`,
  );
  return data.data;
};

export function useAlreadyExistSloName(teamId: string) {
  const [sloName, setSloName] = useState<string>('');
  const { data, isSuccess, isError } = useQuery(
    [queryKeys.ALREADYEXISTSLONAME, sloName, teamId],
    () => fetchAlreadySloName(sloName, teamId),
    {
      enabled: true,
      retry: 1,
    },
  );
  const alreadySloData = data;
  return {
    alreadySloData,
    isSuccess,
    isError,
    setSloName,
  };
}
