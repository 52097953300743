import { ButtonGroup, Flex } from '@chakra-ui/react';
import { FormButton, IconButton, Tooltip } from 'library/atoms';
import { CollapseIcon, ExpandIcon, LibraryIcons } from 'library/icons';

export type DownloadAndTagActionsProps = {
  setCanAutoRefresh: (value: boolean) => void;
  onOpen: () => void;
  isDisabled: boolean;
  setTagsExpandedPreference: (isExpanded: boolean) => void;
  tagsExpanded: boolean;
};

const DownloadAndTagActions = ({
  setCanAutoRefresh,
  onOpen,
  isDisabled,
  setTagsExpandedPreference,
  tagsExpanded,
}: DownloadAndTagActionsProps) => {
  return (
    <Flex gap={2} alignItems="center">
      <FormButton
        title="Download All"
        variant="ghost"
        leftIcon={<LibraryIcons.DownloadIcon boxSize={3} color="brand.blue" />}
        isDisabled={isDisabled}
        onClick={() => {
          setCanAutoRefresh(false);
          onOpen();
        }}
      />
      <ButtonGroup isAttached variant="outline">
        <Tooltip placement="top" label="Collapse tags">
          <IconButton
            aria-label="Collapse tags"
            icon={<CollapseIcon />}
            isActive={!tagsExpanded}
            onClick={() => setTagsExpandedPreference(false)}
          />
        </Tooltip>
        <Tooltip placement="top" label="Expand tags">
          <IconButton
            aria-label="Expand tags"
            icon={<ExpandIcon />}
            isActive={tagsExpanded}
            onClick={() => setTagsExpandedPreference(true)}
          />
        </Tooltip>
      </ButtonGroup>
    </Flex>
  );
};

export default DownloadAndTagActions;
