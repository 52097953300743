import {
  REQUEST_ORG_STAKEHOLDERS,
  REQUEST_ORG_STAKEHOLDERS_FAIL,
  REQUEST_ORG_STAKEHOLDERS_SUCCESS,
} from '../../const/organization';
import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';
import { IStakeholders } from 'core/interfaces/IStakeholders';

const initialOrganizationStakeholders = INITIAL_STATE.organization.stakeholders;

const organizationStakeholdersGroup = (state = initialOrganizationStakeholders, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ action: RESET_STATE, stk: [], error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_STAKEHOLDERS:
      return {
        ...state,
        ...{ action: REQUEST_ORG_STAKEHOLDERS, error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_STAKEHOLDERS_SUCCESS:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_STAKEHOLDERS_SUCCESS,
          stk: action.payload.users.sort((a: IStakeholders, b: IStakeholders) =>
            a.name || b.name ? -1 : a.name.localeCompare(b.name),
          ),
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ORG_STAKEHOLDERS_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_STAKEHOLDERS_FAIL,
          stk: [],
          error: { type: REQUEST_ORG_STAKEHOLDERS_FAIL, reason: action?.payload?.error },
        },
      };
    default:
      return state;
  }
};

export default organizationStakeholdersGroup;
