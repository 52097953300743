import {
  REQUEST_ORG_SQUADS,
  REQUEST_ORG_SQUADS_SUCCESS,
  REQUEST_ORG_SQUADS_FAIL,
} from '../../const/organization';
import { ISquad } from '../../interfaces/ISquad';

const requestOrganizationSquad = () => ({
  type: REQUEST_ORG_SQUADS,
  payload: {},
});

const onRequestOrganizationSquadSuccess = (squads: ISquad[]) => ({
  type: REQUEST_ORG_SQUADS_SUCCESS,
  payload: {
    squads,
  },
});

const onRequestOrganizationSquadFail = (error: any) => ({
  type: REQUEST_ORG_SQUADS_FAIL,
  payload: {
    error,
  },
});

export {
  requestOrganizationSquad,
  onRequestOrganizationSquadSuccess,
  onRequestOrganizationSquadFail,
};
