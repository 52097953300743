import { Box, Button, Flex, HStack, Tag, Text, VStack } from '@chakra-ui/react';
import SQTooltip from 'components/chakra/Tooltip';
import { GetOverrideParticipantsQuery } from 'views/main/organization/schedules/graphql/query';
import React, { useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { SCHEDULES_V2_DETAIL_PATH, USER_PROFILE_PATH } from 'views/main/routes/routes';

import { IListEventInfo } from '.';
import ScheduleAvatarGroup from '../../../common/AvatarGroup';
import {
  listViewOpacities,
  maxEscalationsCount,
  maxTagsCount,
  rotationGapID,
} from '../../../constants/schedules.view';
import { IPatternParticipant } from '../../../helpers/helpers.customrotations';
import {
  compareTwoDates,
  getDateInfo,
  getUTCDateTime,
  isCurrentTime,
  isPastDate,
} from '../../../helpers/helpers.date';
import { getPaddedValue, mapParticipantToEventParticipant } from '../../../helpers/helpers.event';
import ScheduleEscalationPolicies from '../../schedules.escalations';
import ScheduleTags from '../../schedules.tags';
import { getOverrideInformation } from '../../../helpers/helpers.override';
import { CalendarViewType, EventParticipant } from '../../../interface/schedule';
import useUINavigationFunctions from 'views/main/organization/navigation-flows/useUINavigationFunctionV2';
import { NodePopover } from 'components/chakra/Popover';
import { EntityType } from 'views/main/organization/navigation-flows/helpers';
import { EntityDetailsPopover } from 'views/main/organization/navigation-flows/Popover';
import PopoverContent from 'views/main/organization/navigation-flows/Popover/popoverContent';
import { getPopoverHeader } from 'views/main/organization/navigation-flows/Popover/PopoverHeader';
import { PopoverSection } from 'views/main/organization/navigation-flows/Popover/PopoverSection';
import { useOrganizationConfig } from '../../..';
import { ISquad } from 'core/interfaces/ISquad';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { IEscalationPolicy } from 'core/interfaces/IEscalationPolicy';
import { ITeam } from 'core/interfaces/ITeams';
import { IService } from 'core/interfaces/IService';
import { THEME_COLORS } from 'library/theme/colors';

const HeaderText = ({ text }: { text: string }) => {
  return (
    <Text
      fontSize="xs"
      fontWeight={800}
      textTransform="none"
      textAlign="left"
      color="gray.700"
      text-transform="uppercase"
    >
      {text}
    </Text>
  );
};

type TColumnInitializer = {
  allParticipants: IPatternParticipant[];
  timeZone: string;
  onSetScroll?: (ref: HTMLElement) => void;
};

/** The code below involves conditional rendering for gaps and other events.
The event ID is zero for a gap andit is non-zero for other events */

const useInitializeColumns = ({ allParticipants, timeZone, onSetScroll }: TColumnInitializer) => {
  return [
    {
      id: 'rotation_date',
      Header: () => <HeaderText text="DATE / DAY" />,
      Cell: (cell: Cell<IListEventInfo>) => {
        const [date] = Object.keys(cell.row.original);
        const { day, month, weekDay, year } = getDateInfo(
          new Date(
            parseInt(date.split('-')[0]),
            parseInt(date.split('-')[1]) - 1,
            parseInt(date.split('-')[2]),
          ),
        );
        const isPastEvent = isPastDate(new Date(date), new Date());

        return (
          <Flex
            position="sticky"
            top={0}
            gap={2.5}
            alignItems="center"
            pl={1}
            opacity={isPastEvent ? listViewOpacities.pastEvent : listViewOpacities.otherEvent}
            justifyContent="center"
            wrap="wrap"
          >
            <Text fontSize="5xl" minW={20} textAlign="right">
              {day}
            </Text>

            <SQTooltip text={`${month} ${year} ${weekDay}`}>
              <Flex direction="column">
                <Text fontSize="sm" fontWeight="medium" ml={0} noOfLines={1} maxW={150}>
                  {month} {year}
                </Text>
                <Text fontSize="sm" fontWeight="medium" ml={0} noOfLines={1} maxW={150}>
                  {weekDay}
                </Text>
              </Flex>
            </SQTooltip>
          </Flex>
        );
      },
    },
    {
      id: 'rotation_time',
      Header: () => <HeaderText text="TIME" />,
      accessor: 'name',
      Cell: (cell: Cell<IListEventInfo>) => {
        const [date] = Object.keys(cell.row.original);
        const isPastEvent = isPastDate(new Date(date), new Date());
        const events = Object.values<IListEventInfo[string]>(cell.row.original).flat();
        const timeEvents = events.map(event => {
          const startDT = getUTCDateTime(event.startTime, timeZone);
          const endDT = getUTCDateTime(event.endTime, timeZone);
          const isOnGoing = isCurrentTime(new Date(event.startTime), new Date(event.endTime));
          const startTimeString = `${getPaddedValue(startDT.hour)}:${getPaddedValue(
            startDT.minute || 0,
          )}`;
          const endTimeString = `${getPaddedValue(endDT.hour)}:${getPaddedValue(
            endDT.minute || 0,
          )}`;
          const eventOffset = compareTwoDates(endDT, startDT).offset;

          return {
            range: [startTimeString, endTimeString].join(' - '),
            isOngoing: isOnGoing,
            offset: eventOffset,
            overlappedByOverride: event.overlappedByOverride,
          };
        });

        return (
          <VStack
            alignItems="flex-start"
            gap={4}
            spacing={0}
            ref={ref => (onSetScroll ? ref && timeEvents[0]?.isOngoing && onSetScroll?.(ref) : ref)}
            opacity={isPastEvent ? listViewOpacities.pastEvent : listViewOpacities.otherEvent}
          >
            {timeEvents.map((event, index) => (
              <HStack
                key={index}
                alignItems="center"
                height={7}
                w="100%"
                {...(events?.[index].ID === rotationGapID ? { bgColor: 'red.900' } : {})}
                {...(event.overlappedByOverride
                  ? { opacity: listViewOpacities.overlappedByOverride }
                  : {})}
              >
                <Text {...(events?.[index].ID === rotationGapID ? { color: 'white' } : {})} pl={2}>
                  {event.range}
                  <Text
                    as="span"
                    ml={1}
                    textColor={events?.[index].ID === rotationGapID ? '' : 'gray.700'}
                  >
                    {event.offset > 0 ? `+${event.offset}` : ``}
                  </Text>
                </Text>

                {!event.overlappedByOverride && event.isOngoing && (
                  <Text pl={1} color={events?.[index].ID === rotationGapID ? 'green.200' : 'green'}>
                    Ongoing
                  </Text>
                )}
              </HStack>
            ))}
          </VStack>
        );
      },
    },
    {
      id: 'rotation_participants',
      Header: () => <HeaderText text="PARTICIPANTS" />,
      Cell: (cell: Cell<IListEventInfo>) => {
        const events = Object.values<IListEventInfo[string]>(cell.row.original).flat() ?? [];
        const [date] = Object.keys(cell.row.original);
        const isPastEvent = isPastDate(new Date(date), new Date());
        const { getEntityLink, getEntityObject } = useUINavigationFunctions();

        const participantComponentWithLinkStyles = (
          type: EntityType,
          trigger: React.ReactNode,
          entityLink: string,
          entityObject:
            | IUsersOfOrganization
            | ISquad
            | IEscalationPolicy
            | ITeam
            | IService
            | undefined,
          getPopoverSections: any,
        ) =>
          type === 'squad' ? (
            <Link
              to={entityLink}
              style={{
                fontSize: '14px',
                marginLeft: '0px',
                textDecoration: 'none',
              }}
            >
              {trigger}
            </Link>
          ) : (
            <EntityDetailsPopover
              applyChakraStyle={false}
              placement={'right-start'}
              trigger={trigger}
              disablePortal
            >
              <PopoverContent
                header={getPopoverHeader(type as EntityType, entityObject)}
                sections={getPopoverSections}
              />
            </EntityDetailsPopover>
          );
        const useMapUserToUserComponents = (user: EventParticipant, index: number) => {
          const entityObject = getEntityObject(user.type as EntityType, user.ID);
          const entityLink = getEntityLink(user.type as EntityType, user.ID);
          const trigger = (
            <Text
              fontWeight={user.type === 'squad' ? 500 : 900}
              maxW={250}
              isTruncated
              color={THEME_COLORS.secondary[1300]}
              _hover={{ cursor: 'pointer' }}
            >
              {user.participant.name ?? 'Deleted User'}
            </Text>
          );
          const { getPopoverSections } = PopoverSection({
            entityType: user.type as EntityType,
            entity: entityObject,
            getEntityLink,
            organization: useOrganizationConfig().organization,
          });

          return participantComponentWithLinkStyles(
            user.type as EntityType,
            trigger,
            entityLink,
            entityObject,
            getPopoverSections,
          );
        };

        const useMapOverrideUserToUserComponents = ({
          ID,
          type,
          name,
        }: {
          name: string | undefined;
          ID?: string;
          type?: string;
        }) => {
          const trigger = (
            <Text
              fontWeight={type === 'squad' ? 500 : 900}
              maxW={250}
              isTruncated
              color={THEME_COLORS.secondary[1300]}
              _hover={{ cursor: 'pointer' }}
            >
              {name}
            </Text>
          );
          const entityObject = getEntityObject(type as EntityType, ID);
          const entityLink = getEntityLink(type as EntityType, ID);

          const { getPopoverSections } = PopoverSection({
            entityType: type as EntityType,
            entity: entityObject,
            getEntityLink,
            organization: useOrganizationConfig().organization,
          });
          return participantComponentWithLinkStyles(
            type as EntityType,
            trigger,
            entityLink,
            entityObject,
            getPopoverSections,
          );
        };

        return (
          <VStack alignItems="flex-start" gap={4} spacing={0}>
            {events.map((event, index) => {
              const eventParticipants: EventParticipant[] = [];
              event.participants && event.participants.length > 0 && !event.isOverride
                ? event.participants.forEach(participant => {
                    if (event.isOverride) {
                      const { isParticipantFromOverride, overrideInfo } = getOverrideInformation(
                        participant,
                        [event.override] as NonNullable<
                          GetOverrideParticipantsQuery['schedule']
                        >['overrides'],
                      );
                      isParticipantFromOverride
                        ? eventParticipants.push(
                            mapParticipantToEventParticipant(
                              participant,
                              allParticipants,
                              overrideInfo,
                            ),
                          )
                        : eventParticipants.push(
                            mapParticipantToEventParticipant(participant, allParticipants),
                          );
                    } else {
                      eventParticipants.push(
                        mapParticipantToEventParticipant(participant, allParticipants),
                      );
                    }
                  })
                : event.isOverride &&
                  eventParticipants.push(
                    mapParticipantToEventParticipant(
                      { ID: '0', type: 'user' },
                      allParticipants,
                      event.override as NonNullable<
                        NonNullable<GetOverrideParticipantsQuery['schedule']>['overrides']
                      >[number],
                    ),
                  );

              const participantNames =
                eventParticipants.map(p => p.participant.name).join(', ') || ' - ';
              let originalParticipantNames = '';
              event.isOverride &&
                eventParticipants
                  .filter(p => p.isOverride)
                  .forEach(
                    p =>
                      (originalParticipantNames += p.participant.originalParticipants
                        ?.map(p => p.name)
                        .join(', ')),
                  );
              return (
                <HStack
                  gap={1}
                  key={index}
                  alignItems="center"
                  height={7}
                  w="100%"
                  pl={2}
                  {...(event.ID === rotationGapID
                    ? {
                        bgColor: 'red.900',
                        opacity: isPastEvent ? listViewOpacities.overlappedByOverride : '',
                      }
                    : {})}
                >
                  <ScheduleAvatarGroup
                    avatars={
                      eventParticipants?.flatMap(p =>
                        p.type === 'squad' ? p.participant.members : p.participant,
                      ) as { name: string }[]
                    }
                    wrapper={
                      event.isOverride && eventParticipants.length === 1 ? 'override' : undefined
                    }
                  />
                  <HStack alignItems="flex-start" spacing={0} maxW="calc(100% - 72px)">
                    <VStack>
                      {event.ID !== 0 && !event.isOverride
                        ? eventParticipants.slice(0, 1).map(useMapUserToUserComponents)
                        : ' '}
                      <HStack>
                        {event.ID !== 0 && event.isOverride
                          ? eventParticipants[0].participant.members
                              ?.slice(0, 1)
                              .map(useMapOverrideUserToUserComponents)
                          : ' '}
                        {event.ID !== 0 &&
                          event.isOverride &&
                          (eventParticipants[0].participant.members ?? []).length > 1 && (
                            <NodePopover
                              hasArrow
                              trigger={
                                <Button backgroundColor="transparent" _focus={{}} height={'auto'}>
                                  <Tag size="sm" borderRadius="full" _hover={{ cursor: 'pointer' }}>
                                    +{(eventParticipants[0].participant.members ?? []).length - 1}
                                  </Tag>
                                </Button>
                              }
                            >
                              {(eventParticipants[0].participant.members ?? [])
                                .slice(1)
                                .map(useMapOverrideUserToUserComponents)}
                            </NodePopover>
                          )}
                      </HStack>
                    </VStack>

                    {eventParticipants.length > 1 && (
                      <NodePopover
                        hasArrow
                        trigger={
                          <Button backgroundColor="transparent" _focus={{}} height={'auto'}>
                            <Tag size="sm" borderRadius="full" _hover={{ cursor: 'pointer' }}>
                              +{eventParticipants.length - 1}
                            </Tag>
                          </Button>
                        }
                      >
                        {eventParticipants.slice(1).map(useMapUserToUserComponents)}
                      </NodePopover>
                    )}
                    {event.isOverride && (
                      <SQTooltip
                        text={`( originally on-call ${originalParticipantNames} )`}
                        placement="bottom"
                      >
                        <Text
                          fontSize="sm"
                          maxW={150}
                          isTruncated
                        >{`( originally on-call ${originalParticipantNames} )`}</Text>
                      </SQTooltip>
                    )}
                  </HStack>
                </HStack>
              );
            })}
          </VStack>
        );
      },
    },
    {
      id: 'rotation_schedule_name',
      Header: () => <HeaderText text="SCHEDULE NAME" />,
      Cell: (cell: Cell<IListEventInfo>) => {
        const events = useMemo(
          () => Object.values<IListEventInfo[string]>(cell.row.original).flat(),
          [Object.values<IListEventInfo[string]>(cell.row.original)],
        );
        const [date] = Object.keys(cell.row.original);
        const isPastEvent = isPastDate(new Date(date), new Date());

        return (
          <VStack alignItems="flex-start" gap={4} spacing={0}>
            {events.map((event, index) => {
              const timeZoneQueryParam = encodeURIComponent(event.scheduleTimeZone);
              return (
                <HStack
                  gap={1}
                  key={index}
                  alignItems="center"
                  height={7}
                  opacity={
                    event.overlappedByOverride
                      ? listViewOpacities.overlappedByOverride
                      : isPastEvent
                      ? listViewOpacities.pastEvent
                      : listViewOpacities.otherEvent
                  }
                  w="100%"
                  {...(event.ID === rotationGapID ? { bgColor: 'red.900' } : {})}
                >
                  <Link
                    to={
                      generatePath(SCHEDULES_V2_DETAIL_PATH, { scheduleId: event.scheduleID }) +
                      `?view=${CalendarViewType.listView}&timezone=${timeZoneQueryParam}`
                    }
                    style={
                      isPastEvent || event.overlappedByOverride ? { pointerEvents: 'none' } : {}
                    }
                  >
                    <SQTooltip text={event.scheduleName ?? '-'}>
                      <Text
                        color={event.ID === rotationGapID ? 'blue.200' : 'blue.900'}
                        pl={2}
                        maxW={150}
                        isTruncated
                      >
                        {event.scheduleName ?? '-'}
                      </Text>
                    </SQTooltip>
                  </Link>
                </HStack>
              );
            })}
          </VStack>
        );
      },
    },
    {
      id: 'schedule_tags',
      Header: () => <HeaderText text="TAGS" />,
      Cell: (cell: Cell<IListEventInfo>) => {
        const events = Object.values<IListEventInfo[string]>(cell.row.original).flat();
        const [date] = Object.keys(cell.row.original);
        const isPastEvent = isPastDate(new Date(date), new Date());

        return (
          <VStack
            alignItems="flex-start"
            gap={4}
            spacing={0}
            opacity={isPastEvent ? listViewOpacities.pastEvent : listViewOpacities.otherEvent}
          >
            {events.map((event, index) => (
              <HStack
                gap={1}
                key={index}
                alignItems="center"
                height={7}
                w="100%"
                {...(event.ID === rotationGapID ? { bgColor: 'red.900' } : {})}
                {...(event.overlappedByOverride
                  ? { opacity: listViewOpacities.overlappedByOverride }
                  : {})}
              >
                {event.scheduleTags.length > 0 && event.ID !== 0 ? (
                  <ScheduleTags tags={event.scheduleTags} maxTagsCount={maxTagsCount.list} />
                ) : (
                  <Text pl={2}>{event.ID !== 0 ? ' - ' : ''}</Text>
                )}
              </HStack>
            ))}
          </VStack>
        );
      },
    },
    {
      id: 'rotation_escalation_policy',
      Header: () => <HeaderText text="ESCALATION POLICY" />,
      Cell: (cell: Cell<IListEventInfo>) => {
        const events = Object.values<IListEventInfo[string]>(cell.row.original).flat();
        const [date] = Object.keys(cell.row.original);
        const isPastEvent = isPastDate(new Date(date), new Date());

        return (
          <VStack
            alignItems="flex-start"
            gap={4}
            spacing={0}
            opacity={isPastEvent ? listViewOpacities.pastEvent : listViewOpacities.otherEvent}
          >
            {events.map((event, index) => (
              <HStack
                gap={1}
                key={index}
                alignItems="center"
                height={7}
                w="100%"
                {...(event.ID === rotationGapID ? { bgColor: 'red.900' } : {})}
                {...(event.overlappedByOverride
                  ? { opacity: listViewOpacities.overlappedByOverride }
                  : {})}
              >
                {event.scheduleEscalations.length > 0 && event.ID !== 0 ? (
                  <ScheduleEscalationPolicies
                    escalationPolicies={event.scheduleEscalations}
                    maxEscalationsCount={maxEscalationsCount.list}
                    fontSize={14}
                    disableLink={event.overlappedByOverride}
                  />
                ) : (
                  <Text pl={2}>{event.ID !== 0 ? ' - ' : ''}</Text>
                )}
              </HStack>
            ))}
          </VStack>
        );
      },
    },
    {
      id: 'rotation_name',
      Header: () => <HeaderText text="ROTATION NAME" />,
      accessor: 'name',
      Cell: (cell: Cell<IListEventInfo>) => {
        const events = Object.values<IListEventInfo[string]>(cell.row.original).flat();
        const rotationName = events.map(el => el.rotationName);
        const [date] = Object.keys(cell.row.original);
        const isPastEvent = isPastDate(new Date(date), new Date());

        return (
          <VStack
            alignItems="flex-start"
            gap={4}
            spacing={0}
            opacity={isPastEvent ? listViewOpacities.pastEvent : listViewOpacities.otherEvent}
          >
            {rotationName.map((name, index) => (
              <HStack
                gap={1}
                key={index}
                alignItems="center"
                height={7}
                w="100%"
                {...(events?.[index].ID === rotationGapID ? { bgColor: 'red.900' } : {})}
                {...(events?.[index].overlappedByOverride
                  ? { opacity: listViewOpacities.overlappedByOverride }
                  : {})}
              >
                <SQTooltip text={name ?? '-'}>
                  <Text
                    {...(events?.[index].ID === rotationGapID ? { color: 'white' } : {})}
                    pl={2}
                    maxW={150}
                    isTruncated
                  >
                    {name ?? '-'}
                  </Text>
                </SQTooltip>
              </HStack>
            ))}
          </VStack>
        );
      },
    },
  ];
};

export default useInitializeColumns;
