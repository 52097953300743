import { API } from 'core';
import {
  GetListOfSchedulesQuery,
  useGetListOfSchedulesQuery,
  useWhoIsOncallQuery,
} from 'views/main/organization/schedules/graphql/query';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetAllPatternParticipants } from '../../helpers/helpers.customrotations';
import { mapParticipantToEventParticipant } from '../../helpers/helpers.event';
import { defaultReactQueryConfig } from '../../helpers/helpers.schedule';
import { EventParticipant } from '../../interface/schedule';
import { useOrganizationConfig } from '../..';
import { ITopBarProps } from 'views/main/topBar';

type onCallInfo = {
  whoIsOncall:
    | {
        schedule: {
          __typename?: 'Schedule';
          ID: number;
          name: string;
        };
        oncallParticipants: Array<EventParticipant> | null | undefined;
      }[]
    | null
    | undefined;
};

export function withCurrentOnCallUsers<T>(InputComponent: React.ComponentType<T>) {
  return (props: T) => {
    const [schedulesList, setScheduleslist] = useState<GetListOfSchedulesQuery>({ schedules: [] });
    const [whoIsOnCallInfo, setWhoIsOnCallInfo] = useState<onCallInfo>({ whoIsOncall: [] });
    const allParticipants = useGetAllPatternParticipants();
    const organization = useOrganizationConfig();
    const organizationConfig = organization.organization.currentOrg.o?.config;
    const currentUserId = organization.organization.currentUser.u?.id;
    const [entityOwnerParam, setEntityOwnerParam] = useState<string>(
      organizationConfig &&
        organizationConfig['show_default_owner_filters'] &&
        (props as unknown as ITopBarProps).showMyOnCall &&
        currentUserId
        ? 'user:' + currentUserId + '|squadsof:' + currentUserId
        : '',
    );

    useEffect(() => {
      setEntityOwnerParam(
        (props as unknown as ITopBarProps).showMyOnCall && currentUserId
          ? 'user:' + currentUserId + '|squadsof:' + currentUserId
          : '',
      );
    }, [(props as unknown as ITopBarProps).showMyOnCall]);

    const { data: allSchedules } = useGetListOfSchedulesQuery(
      {
        filters: { teamID: API.config.teamId },
      },
      defaultReactQueryConfig,
    );

    const { data: onCallUsers, isLoading: isWhoIsOnCallLoading } = useWhoIsOncallQuery(
      {
        filters: {
          youAndYourSquads: (props as unknown as ITopBarProps).showMyOnCall,
          teamID: API.config.teamId,
          ScheduleIDs: schedulesList?.schedules?.map(s => s.ID),
        },
      },
      {
        ...defaultReactQueryConfig,
        enabled: !!schedulesList?.schedules?.length,
      },
    );

    const memoizedWhoIsOnCallInfo = useMemo(() => whoIsOnCallInfo.whoIsOncall, [whoIsOnCallInfo]);

    useEffect(() => {
      if (allSchedules) {
        setScheduleslist(allSchedules);
      }
    }, [allSchedules]);

    useEffect(() => {
      if (onCallUsers) {
        setWhoIsOnCallInfo({
          whoIsOncall: onCallUsers.whoIsOncall
            ?.filter(obj => !!obj.oncallParticipants?.length && !obj.schedule.paused)
            .map(obj => ({
              schedule: obj.schedule,
              oncallParticipants: obj.oncallParticipants?.map(p =>
                mapParticipantToEventParticipant({ ID: p.ID, type: p.type }, allParticipants),
              ),
            })),
        });
      }
    }, [onCallUsers]);

    return (
      <InputComponent
        {...props}
        whoIsOnCallInfo={memoizedWhoIsOnCallInfo}
        isWhoIsOnCallLoading={isWhoIsOnCallLoading}
      />
    );
  };
}

export default withCurrentOnCallUsers;
