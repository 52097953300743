import styles from './styles.module.css';

import * as React from 'react';
import styled from 'styled-components';
import { SearchIcon } from 'icons';

import {
  CardBlock,
  CellBlock,
  Checkbox,
  DialogModalFrame,
  DropDown,
  DropDownOptionShell,
  ErrorBlock,
  FocusBlock,
  FormBlock,
  Grid,
  Heading,
  IconButton,
  Label,
  Para,
  SelectBox,
  SpinLoader,
  TextButton,
  Theme,
  Tooltip,
} from 'uie/components';
import { pluralize } from 'core/helpers/stringUtils';

interface SearchInputProps {
  placeholder: string;
  searchValue?: string;
  setSearchValue: (value: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ placeholder, searchValue, setSearchValue }) => (
  <SearchInputWrapper>
    <SearchIcon height="16" width="16" />
    <SearchInputField
      type="text"
      placeholder={placeholder}
      value={searchValue}
      onChange={e => setSearchValue(e.target.value)}
      aria-label={placeholder}
    />
  </SearchInputWrapper>
);

interface SquadHeaderProps {
  title: string;
  searchPlaceholder: string;
  onDeleteClick: () => void;
  membersInSquad?: number;
  searchValue?: string;
  setSearchValue: (value: string) => void;
}

export const SquadHeader: React.FC<SquadHeaderProps> = ({
  title,
  searchPlaceholder,
  onDeleteClick,
  membersInSquad,
  setSearchValue,
  searchValue,
}) => (
  <Wrapper>
    <Header>
      <Title>{title}</Title>
      <Actions>
        <SearchInput
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder={searchPlaceholder}
        />
        <Divider />
        <TextButton
          color="#ff4b4b"
          buttonType="inverted"
          style={{
            padding: '4px 8px',
            borderRadius: '4px',
          }}
          onClick={onDeleteClick}
        >
          <Para fontSize={16} style={{ lineHeight: '150%' }} color="#ff4b4b">
            Delete Squad
          </Para>
        </TextButton>
      </Actions>
    </Header>
    <Para
      fontSize={12}
      style={{
        fontStyle: 'italic',
        fontWeight: 300,
        color: '#8A92A3',
        marginTop: 12,
      }}
    >
      {membersInSquad} {pluralize(membersInSquad ?? 0, 'Member', 'Members')} in this squad
    </Para>
  </Wrapper>
);

const Wrapper = styled.div`
  margin-bottom: 12px;
`;

const Header = styled.header`
  display: flex;
  gap: 11px;
  align-items: center;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Title = styled.h1`
  color: #122146;
  margin: 0px 0 0;
  flex: 1 1 auto;
  font: 400 18px/150% Mulish, sans-serif;
`;

const Actions = styled.div`
  display: flex;
  gap: 12px;
  white-space: nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const SearchInputWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(230, 231, 236, 1);
  background: #fff;
  display: flex;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  align-items: center;
  color: #b6bcc8;

  @media (max-width: 991px) {
    white-space: initial;
  }

  &:focus-within {
    border-color: #0e61dd;
    color: #0e61dd;
  }
`;

const SearchInputField = styled.input`
  font: 400 14px/150% Mulish, sans-serif;
  flex: 1;
  border: none;
  color: rgb(61, 72, 94);

  &::placeholder {
    color: #b6bcc8;
    font-size: 14px;
  }

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Divider = styled.div`
  background: var(--gray-200, #e2e8f0);
  width: 1px;
  height: 32px;
`;
