import axios from 'axios';
import { API } from '../api';
import { IPostmortemTemplate } from '../interfaces/IPostmortems';

class PostmortemTemplateService {
  private api = '';

  constructor(organizationId: string) {
    this.api = `${API.config.batman}/organizations/${organizationId}/postmortem-templates`;
  }

  public get = () => axios.get(this.api);

  public create = (postmortemTemplate: IPostmortemTemplate) =>
    axios.post(this.api, postmortemTemplate);

  public update = (postmortemTemplate: IPostmortemTemplate) =>
    axios.put(`${this.api}/${postmortemTemplate.id}`, postmortemTemplate);

  public delete = (postmortemTemplateID: string) =>
    axios.delete(`${this.api}/${postmortemTemplateID}`);
}

export default PostmortemTemplateService;
