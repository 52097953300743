import { Box, Divider } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import { CSSProperties } from 'react';
import { Para } from 'uie/components';

interface IProps {
  heading: React.ReactNode;
  children?: React.ReactNode;
  withoutDivider?: boolean;
  style?: CSSProperties | undefined;
}

const IncidentSummarySection: React.FC<IProps> = (props: IProps) => {
  return (
    <Box style={props.style}>
      <Box px={6} py={3}>
        {typeof props.heading === 'string' ? (
          <Para
            fontWeight={800}
            fontSize={14.5}
            color={THEME_COLORS.secondary[700]}
            style={{ lineHeight: '21.75px', marginBottom: '4px' }}
          >
            {props.heading}
          </Para>
        ) : (
          props.heading
        )}
        {props.children}
      </Box>
      {!props.withoutDivider && <Divider size={1} />}
    </Box>
  );
};

export default IncidentSummarySection;
