import * as React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { API } from '../../../../../core/api';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IUserInfo } from '../../../../../core/interfaces/IUserData';
import { IOrganization } from '../../../../../core/interfaces/IOrganization';
import { requestUserLogout } from '../../../../../core/actions/auth';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_GS_ORGANIZATION_DELETED } from '../../../../../core/const/tracker';
import {
  Heading,
  Theme,
  InputBlock,
  TextButton,
  Para,
  Grid,
  SpinLoader,
  ErrorBlock,
  Label,
} from 'uie/components';
import { clear } from 'store/localStorage';

interface IProps extends Pick<IAppState, 'userOrganizations' | 'userInfo'> {
  hide: () => void;
  organizationId: string;
  requestUserLogout: () => void;
}

interface IState {
  status: string;
  showDeleteOrganisationPostConfirm: boolean;
  deleteConfirmState:
    | 'initial'
    | 'confirmed'
    | 'credsConfirmed'
    | 'incorrectPassword'
    | 'incorrectOrgName';
  org_name: string;
  password: string;
  passwordError: string;
  email?: string;
  saving: boolean;
  user?: IUserInfo;
  allow: boolean;
  loading: boolean;
  name: string;
  organization: IOrganization | undefined;
}

class DeleteOrgConfirmModal extends React.Component<IProps, IState> {
  constructor(props: Readonly<IProps>) {
    super(props);

    const orgId = this.props.organizationId;
    const selectedOrg = this.props.userOrganizations.o.find(o => o.organizationId === orgId);

    this.state = {
      status: '',
      showDeleteOrganisationPostConfirm: false,
      deleteConfirmState: 'initial',
      org_name: '',
      password: '',
      saving: false,
      passwordError: '',
      email: this.props.userInfo.d!.email,
      user: this.props.userInfo.d as IUserInfo,
      allow: false,
      loading: true,
      name: '',
      organization: selectedOrg,
    };
    this.onOrgNameChange = this.onOrgNameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
  }

  public async componentWillUnmount() {
    if (this.state.deleteConfirmState === 'confirmed') {
      clear();
      this.props.requestUserLogout();
    }
  }

  public onPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ password: event.target.value });
  }

  public onOrgNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ org_name: event.target.value });
  }

  public checkPassword = () => {
    const { org_name, password } = this.state;
    if (org_name.length === 0 || password.length === 0) {
      return;
    }

    this.setState({ deleteConfirmState: 'credsConfirmed' });
    const data: any = {
      organizationName: this.state.org_name,
      password: this.state.password,
    };
    const orgId = this.props.organizationId;
    AppTracker.track(T_WA_GS_ORGANIZATION_DELETED);
    axios
      .delete(API.config.endpoint + `/organizations/${orgId}/delete`, { data })
      .then(response => {
        if (response.data.result === true) {
          this.setState({ deleteConfirmState: 'confirmed' });
        }
      })
      .catch(err => {
        if (err.response.data.reason === 'password') {
          this.setState({ deleteConfirmState: 'incorrectPassword' });
        }
        if (err.response.data.reason === 'orgName') {
          this.setState({ deleteConfirmState: 'incorrectOrgName' });
        }
      });
  };

  public render() {
    const { theme } = Theme;

    return (
      <div
        onClick={event => {
          event.stopPropagation();
        }}
      >
        {this.state.deleteConfirmState === 'initial' && (
          <div>
            <Heading fontSize={24} height={36} fontWeight={500}>
              Delete Organization
            </Heading>
            <Para fontSize={14} className="mt-10">
              Are you sure you want to delete your organization{' '}
              <span style={{ fontWeight: 600 }}>{this.state.organization!.organizationName}</span>?
              This will delete your organization and all relevant incident data. This is an
              irreversible action.
            </Para>
            <Grid type="column" flexWidth={12} className="mt-20">
              <Label htmlFor="confirm_del__org_name">Organization Name</Label>
              <InputBlock
                id="confirm_del__org_name"
                className="mt-5 mb-20"
                autoComplete="organization_name"
                type="text"
                placeholder="Enter Organization Name"
                value={this.state.org_name}
                onChange={this.onOrgNameChange}
              />

              <Label htmlFor="confirm_del__user_password">Your password</Label>
              <InputBlock
                id="confirm_del__user_password"
                className="mt-5"
                autoComplete="new-password"
                type="password"
                placeholder="Enter Password"
                value={this.state.password}
                onChange={this.onPasswordChange}
              />
            </Grid>
            <Grid alignItems="center" className="mt-20">
              {this.state.password !== '' && (
                <TextButton
                  className="mr-10"
                  onClick={this.checkPassword}
                  color={theme.danger.default}
                >
                  <Para color={theme.shades.white}>
                    Delete {this.state.organization!.organizationName}
                  </Para>
                </TextButton>
              )}

              <TextButton buttonType="ghost" onClick={this.props.hide}>
                <Para>Cancel</Para>
              </TextButton>
            </Grid>
          </div>
        )}
        {this.state.deleteConfirmState === 'credsConfirmed' && (
          <div>
            <Heading fontSize={24} height={36} fontWeight={500}>
              Delete Organization
            </Heading>
            <Grid alignItems="center" className="mt-10">
              <SpinLoader base="16px" />
              <Para className="ml-10">Checking...</Para>
            </Grid>
          </div>
        )}
        {this.state.deleteConfirmState === 'incorrectPassword' && (
          <div>
            <Heading fontSize={24} height={36} fontWeight={500}>
              Delete Organization
            </Heading>
            <ErrorBlock className="mt-20">Incorrect password!</ErrorBlock>
          </div>
        )}
        {this.state.deleteConfirmState === 'incorrectOrgName' && (
          <div>
            <Heading fontSize={24} height={36} fontWeight={500}>
              Delete Organization
            </Heading>
            <ErrorBlock className="mt-20">Please enter the correct organization name.</ErrorBlock>
          </div>
        )}
        {this.state.deleteConfirmState === 'confirmed' && (
          <div>
            <Heading fontSize={24} height={36} fontWeight={500}>
              Delete Organization
            </Heading>
            <Grid alignItems="center" flexWidth={10} className="mt-10">
              <Para fontSize={14}>
                We have received your request to delete your account. Your organization is
                deactivated with immediate effect. We will permanently delete the organization and
                associated data after 7 days.
              </Para>
            </Grid>
            <div
              className="mt-20 br-3"
              style={{
                marginLeft: -8,
                background: theme.primary.light,
                padding: 8,
              }}
            >
              <Para fontSize={14} fontWeight={500}>
                In case you want to revert, please contact Squadcast Support at{' '}
                <span style={{ fontWeight: 600, color: '#0f61dd' }}>
                  <a href="mailto: support@squadcast.com">support@squadcast.com</a>
                </span>{' '}
                within 7 days.
              </Para>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state: IAppState) => ({
    userOrganizations: state.userOrganizations,
    userInfo: state.userInfo,
  }),
  {
    requestUserLogout,
  },
)(DeleteOrgConfirmModal);
