import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 141 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114 10h2.3l-.001 5H119v2.3h-2.7V20a2.7 2.7 0 0 0 2.7 2.7V25a5 5 0 0 1-5-5V10Zm22 7.3V15l-.217.005A5 5 0 0 0 131 20v5h2.3v-5l.005-.17A2.7 2.7 0 0 1 136 17.3ZM112.65 25h-2.3V15h2.3v10ZM93 15a5 5 0 1 1 0 10 5 5 0 0 1 0-10Zm37 5a5 5 0 1 0-10 0 5 5 0 0 0 10 0Zm-47.575-5a4.576 4.576 0 0 1 4.555 4.142l.015.213.005.205V25h-2.3v-5.43l-.005-.15-.016-.154a2.275 2.275 0 0 0-4.523.149l-.006.155V25h-2.3v-5.44l-.005-.14-.016-.154a2.275 2.275 0 0 0-4.523.149l-.006.155V25H71v-5.44l.005-.2.015-.213A4.576 4.576 0 0 1 79 16.542 4.56 4.56 0 0 1 82.426 15ZM109 20a5 5 0 0 0-10 0v5h2.3v-5l.005-.17a2.7 2.7 0 0 1 5.395.17v5h2.3v-5Zm-16-2.7a2.7 2.7 0 1 0 0 5.4 2.7 2.7 0 0 0 0-5.4Zm29.3 2.7a2.7 2.7 0 1 1 5.4 0 2.7 2.7 0 0 1-5.4 0ZM69 19v2h-3v-2h3Zm72-3a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm-2-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm.583.658c-.133-.105-.317-.158-.552-.158h-.781v2h.478v-.706h.259l.351.706h.512v-.02l-.414-.807a.596.596 0 0 0 .262-.222.653.653 0 0 0 .084-.347.536.536 0 0 0-.199-.446Zm-.552.214h-.303v.55h.303l.064-.005a.235.235 0 0 0 .141-.067.28.28 0 0 0 .069-.202.28.28 0 0 0-.07-.202c-.046-.05-.114-.074-.204-.074ZM113 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"
      fill="#29B6F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 25a5 5 0 0 0 5-5V10H7.7v5.791A5 5 0 1 0 5 25Zm19.3-15-.001 5H27v2.3h-2.7V20a2.7 2.7 0 0 0 2.7 2.7V25a5 5 0 0 1-5-5V10h2.3ZM16 15a5 5 0 1 1 0 10 5 5 0 0 1 0-10Zm20.358 1.296a5 5 0 1 0-.001 7.41 6.687 6.687 0 0 1-.983-2.418 2.7 2.7 0 1 1-.001-2.576 6.68 6.68 0 0 1 .985-2.416ZM42 15a5 5 0 1 1 0 10 5 5 0 0 1 0-10Zm17.425 0a4.576 4.576 0 0 1 4.555 4.142l.015.213.005.205V25h-2.3v-5.43l-.005-.15-.016-.154a2.275 2.275 0 0 0-4.523.149l-.006.155V25h-2.3v-5.44l-.005-.14-.016-.154a2.275 2.275 0 0 0-4.523.149l-.006.155V25H48v-5.44l.005-.2.015-.213A4.576 4.576 0 0 1 56 16.542 4.56 4.56 0 0 1 59.426 15ZM13.3 20a2.7 2.7 0 1 1 5.4 0 2.7 2.7 0 0 1-5.4 0ZM42 17.3a2.7 2.7 0 1 0 0 5.4 2.7 2.7 0 0 0 0-5.4ZM2.3 20a2.7 2.7 0 1 1 5.4 0 2.7 2.7 0 0 1-5.4 0Z"
      fill="#4CAF50"
    />
  </svg>
);

export default SvgComponent;
