import { PLS } from 'library/molecules/Table/types';
import { AnyObject } from 'library/types';
import { createContext, Dispatch } from 'react';
import { INCIDENT_STATUS } from '../common/enums';

import { getILPageLimit } from './persistent-storage';

export enum ActionKind {
  BULK_SELECT = 'BULK_SELECT',
  RESET_BULK_SELECTION = 'RESET_BULK_SELECTION',
  SET_SEARCH_TERM = 'SET_SEARCH_TERM',
  ALLOW_AUTO_REFRESH = 'ALLOW_AUTO_REFRESH',
  RESET_CURSOR = 'RESET_CURSOR',
  SET_CURSOR_FOR_STATUS = 'SET_CURSOR_FOR_STATUS',
  SET_SORT_BY = 'SET_SORT_BY',
}

export enum ILCOLUMN {
  TIME = 'time',
  PRIORITY = 'priority',
}

// An interface for our actions
export interface IncidentListAction {
  type: ActionKind;
  payload?: AnyObject;
}

// An interface for our state
export interface IncidentListState {
  bulk: {
    select_for_all: Array<string>;
  };
  search: {
    isEnabled: boolean;
    term: string;
  };
  cursor: Record<INCIDENT_STATUS, string>;
  pageLimit: PLS;
  sortBy: ILCOLUMN;
  canAutoRefresh: boolean;
}

export const INITIAL_INCIDENT_LIST_STATE = {
  bulk: {
    select_for_all: [],
  },
  search: {
    isEnabled: false,
    term: '',
  },
  cursor: {
    [INCIDENT_STATUS.ALL]: '',
    [INCIDENT_STATUS.OPEN]: '',
    [INCIDENT_STATUS.TRIGGERED]: '',
    [INCIDENT_STATUS.ACKNOWLEDGED]: '',
    [INCIDENT_STATUS.RESOLVED]: '',
    [INCIDENT_STATUS.SUPPRESSED]: '',
  },
  pageLimit: getILPageLimit(),
  sortBy: ILCOLUMN.TIME,
  canAutoRefresh: true,
};

export interface IncidentListContextType {
  state: IncidentListState;
  dispatch: Dispatch<{
    type: ActionKind;
    payload?: AnyObject;
  }>;
}

export const IncidentListContext = createContext<IncidentListContextType>({
  state: INITIAL_INCIDENT_LIST_STATE,
  dispatch: () => null,
});
