import styled from 'styled-components';
interface IProps extends React.HTMLAttributes<HTMLButtonElement> {
  buttonType?: 'normal' | 'inverted' | 'ghost';
  height?: string | '64px' | '40px' | 'fit-content';
  width?: string | 'fit-content';
  /**
   * changes the background of the button
   */
  color?: string;
}

const calcProps = (props: any) => {
  const isInverted = props.buttonType === 'inverted' || props.buttonType === 'ghost';
  const isGhost = props.buttonType === 'ghost';
  const color =
    (isGhost ? props.theme.shades.whiteSmoke : props.color) || props.theme.primary.default;
  const borderColor = isGhost ? props.theme.shades.whiteSmoke : color;

  return `
    background: ${isInverted ? `${color}07` : color};
    box-shadow: 0 0 0 1px ${borderColor}${isInverted ? 'a1' : ''};
    transition: box-shadow 0.17s, filter 0.17s, background 0.17s, opacity 0.17s ease;

    &:disabled {
      opacity: 0.33;
      cursor: no-drop !important;
    }

    &:focus, &:active:not(:disabled) {
      background: ${isInverted ? `${color}27` : color};
    }

    &:active:not(:disabled) {
      opacity: 0.77;
    }
  `;
};

const TextButton = styled.button<IProps>`
  ${calcProps}
  font-family: ${props => props.theme.fontFamily};
  height: ${props => props.height || 'fit-content'};
  width: ${props => props.width || 'fit-content'};
  display: flex;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    outline: ${props => props.theme.focus.default};
    outline-offset: 4px;
  }
`;

/** @component */
export default TextButton;
