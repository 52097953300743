import { Box, Button, Center, Divider, Flex, IconButton, Spinner, Text } from '@chakra-ui/react';
import { debounce } from 'core';
import { isEmpty } from 'lodash';
import React, { ComponentState, FC, useContext, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useIssueListContext } from '..';
import { AlertDialogComponent } from '../../components/Alert';
import { CustomDrawerComponent } from '../../components/Drawer';
import { Search } from '../../components/Search';
import {
  ISSUE_DRAWER_TYPES,
  ISSUE_FILTER_TYPES,
  SearchActionKind,
} from '../../constants/status.constants';
import {
  decodeIssueFilterParam,
  encodeIssueFilterParam,
  getLoadingState,
  getStructuredIssueFilters,
} from '../../helpers/helper.service';
import useQueryParams from '../../hooks/useQueryParams';
import { FilterIcon } from 'library/icons';
import { IIssuefilterObject, IPageIssueList } from '../../Interface';
import FilterList from '../../statuspage.list/filter/filterList';
import IssueAdd from './add';
import FilterIssue from './filter';
import IssueList from './table';
import IssueUpdate from './update';
import IssueDetailsUpdate from './update/IssueDetailsUpdate';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

interface Props {
  pageId: number;
}

const IssueTab = (props: Props) => {
  const {
    isIssueListLoading,
    isFetched,
    isIssueListError,
    list,
    totalCount,
    isSuccess,
    refetchIssueList,
    issueFilterDetails,
    timezone,
    deleteIssuePageAction,
    state: {
      pagination: { queryPageIndex, queryPageSize },
      search: { enabled: isSearchEnabled },
      drawer: { isDrawerOpen, drawerType, issueId },
      filters,
      deleteIssue: { showModal, selectedIssueeID },
      filterCount,
    },
    dispatch,
  } = useIssueListContext();

  const query = useQueryParams();
  const history = useHistory();

  const getTitle = (drawerType: string) => {
    if (drawerType === ISSUE_DRAWER_TYPES.EDIT_ISSUE) {
      return (
        <Flex justifyContent={'space-between'} mr={10} alignItems="center">
          <Text>{drawerType}</Text>
          <Button
            onClick={() =>
              dispatch({
                type: SearchActionKind.OPEN_DRAWER,
                issueId: issueId,
                drawerType: ISSUE_DRAWER_TYPES.EDIT_ISSUE_DETAILS,
                prevDrawer: ISSUE_DRAWER_TYPES.EDIT_ISSUE,
              })
            }
          >
            Edit issue details
          </Button>
        </Flex>
      );
    }
    return <Text>{drawerType}</Text>;
  };
  const canEdit = useUserAccess().hasUpdateAccess('status_pages', `${props.pageId}`);

  const IssueListTable = (
    {
      failed: () => <></>,
      loading: () => (
        <Center h="60vh" data-testid="loader-issue">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      ),
      done: () => {
        return (
          <IssueList
            list={list as IPageIssueList[]}
            canEdit={canEdit}
            timezone={timezone}
            pageChange={(queryPageIndex: number) =>
              dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex })
            }
            pageSizeChange={(queryPageSize: number) => {
              dispatch({ type: SearchActionKind.PAGE_SIZE_CHANGED, queryPageSize });
              dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex: 1 });
            }}
            onIssueUpdate={(id: number) => {
              dispatch({
                type: SearchActionKind.OPEN_DRAWER,
                issueId: id,
                drawerType: ISSUE_DRAWER_TYPES.EDIT_ISSUE,
              });
            }}
            queryPageIndex={queryPageIndex}
            queryPageSize={queryPageSize}
            totalCount={totalCount}
            onDetailsUpdate={(id: number) => {
              dispatch({
                type: SearchActionKind.OPEN_DRAWER,
                issueId: id,
                drawerType: ISSUE_DRAWER_TYPES.EDIT_ISSUE_DETAILS,
              });
            }}
            onDelete={(id: number) =>
              dispatch({
                type: SearchActionKind.SELECT_PAGE_DELETE,
                issueId: id,
              })
            }
          />
        );
      },
    } as Record<ComponentState, FC>
  )[getLoadingState(isIssueListLoading, isFetched, isIssueListError)];

  const searchQueryParam = query.get('search');
  const filterParams = query.get('filters');

  const debouncedSearch = useRef(
    debounce((searchParam: string) => {
      dispatch({ type: SearchActionKind.SEARCHED, searchText: searchParam || '' });
      dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex: 1 });
    }, 1000),
  ).current;

  useEffect(() => {
    const hasSearchParam = typeof searchQueryParam === 'string';
    const hasFilter = typeof filterParams === 'string';

    if (hasSearchParam) {
      dispatch({
        type: SearchActionKind.SEARCH_ENABLED_OR_DISABLED,
        isEnabled: true,
      });

      debouncedSearch(searchQueryParam);
    } else {
      dispatch({
        type: SearchActionKind.SEARCH_ENABLED_OR_DISABLED,
        isEnabled: false,
      });
    }
    if (hasFilter) {
      const filterObj = decodeIssueFilterParam(filterParams);
      dispatch({
        type: SearchActionKind.APPLY_FILTER,
        filters: filterObj,
      });
    }
  }, [query.get('search'), query.get('filters')]);

  const MemoizedIssueListTable = useMemo(
    () => IssueListTable,
    [isIssueListLoading, isFetched, isIssueListError, list, timezone],
  );

  const structuredFilters = getStructuredIssueFilters(filters);

  const closeDeleteModal = () => {
    dispatch({
      type: SearchActionKind.REJECT_PAGE_DELETE,
    });
  };

  const onFilterCancel = (type: string, option: string | number) => {
    const removedFilters = (filters[type as ISSUE_FILTER_TYPES] as (number | string)[]).filter(
      (o: string | number) => o.toString() !== option.toString(),
    );
    const filterObj = {
      ...filters,
      [type]: removedFilters,
    };
    const filterUrlParams = encodeIssueFilterParam(filterObj);
    query.delete('filters');
    if (!isEmpty(filterUrlParams)) {
      query.append('filters', filterUrlParams);
    } else {
      dispatch({
        type: SearchActionKind.APPLY_FILTER,
        filters: filterObj,
      });
    }
    history.push({ search: query.toString() });
  };

  const onIssueDeleted = () => {
    deleteIssuePageAction(selectedIssueeID);
    closeDeleteModal();
  };

  return (
    <Box pt={4}>
      <Flex justifyContent="space-between" gap={5} mb={6}>
        <FilterList filters={structuredFilters} onCancel={onFilterCancel} />
        <Flex gap={5}>
          <Search
            setSearchTerm={searchTerm => {
              query.delete('search');
              query.append('search', searchTerm);
              history.push({ search: query.toString() });
            }}
            searchTerm={searchQueryParam || ''}
            searchEnabled={isSearchEnabled}
            setSearchEnabled={isEnabled => {
              if (isEnabled) {
                query.append('search', '');
              } else {
                query.delete('search');
              }
              history.push({ search: query.toString() });
            }}
            isDisabled={!isSuccess}
          />
          <Box position={'relative'}>
            <IconButton
              aria-label="Filter status"
              variant="icon"
              data-testid="filter-icon"
              onClick={() => {
                dispatch({
                  type: SearchActionKind.OPEN_DRAWER,
                  drawerType: ISSUE_DRAWER_TYPES.FILTER,
                });
              }}
              icon={<FilterIcon />}
            />
            {filterCount > 0 ? (
              <Box
                bgColor={'red.500'}
                w={3}
                h={3}
                borderRadius={6}
                mr={2}
                position="absolute"
                top={-1}
                right={-3}
              />
            ) : null}
          </Box>

          <>
            <Center height={8}>
              <Divider orientation="vertical" />
            </Center>
            <NoPermissionTooltip isDisabled={canEdit}>
              <Button
                variant="default"
                size="sm"
                onClick={() => {
                  dispatch({
                    type: SearchActionKind.OPEN_DRAWER,
                    drawerType: ISSUE_DRAWER_TYPES.ADD_ISSUE,
                  });
                }}
                isDisabled={!canEdit}
              >
                Add Issue
              </Button>
            </NoPermissionTooltip>
          </>
        </Flex>
      </Flex>
      <MemoizedIssueListTable />
      <CustomDrawerComponent
        onClose={() => {
          dispatch({ type: SearchActionKind.CLOSE_DRAWER });
        }}
        isOpen={isDrawerOpen}
        title={getTitle(drawerType || '')}
        disableBodyPadding
      >
        {drawerType === ISSUE_DRAWER_TYPES.FILTER ? (
          <FilterIssue
            filters={filters}
            filterDetails={issueFilterDetails}
            onClose={() => dispatch({ type: SearchActionKind.CLOSE_DRAWER })}
            onFilterSubmit={(selectedFilters: IIssuefilterObject) => {
              const filterUrlParams = encodeIssueFilterParam(selectedFilters);
              dispatch({ type: SearchActionKind.CLOSE_DRAWER });
              query.delete('filters');
              if (!isEmpty(filterUrlParams)) {
                query.append('filters', filterUrlParams);
              } else {
                dispatch({
                  type: SearchActionKind.APPLY_FILTER,
                  filters: selectedFilters,
                });
              }

              history.push({ search: query.toString() });
            }}
          />
        ) : null}
        {drawerType === ISSUE_DRAWER_TYPES.ADD_ISSUE ? (
          <IssueAdd
            pageId={props.pageId}
            onCancel={() => dispatch({ type: SearchActionKind.CLOSE_DRAWER })}
            onSuccess={refetchIssueList}
          />
        ) : null}
        {drawerType === ISSUE_DRAWER_TYPES.EDIT_ISSUE ? (
          <IssueUpdate pageId={props.pageId} issueId={issueId} onSuccess={refetchIssueList} />
        ) : null}
        {drawerType === ISSUE_DRAWER_TYPES.EDIT_ISSUE_DETAILS ? (
          <IssueDetailsUpdate
            pageId={props.pageId}
            issueId={issueId}
            onSuccess={refetchIssueList}
            onCancel={() => dispatch({ type: SearchActionKind.CLOSE_DRAWER })}
          />
        ) : null}
      </CustomDrawerComponent>
      <AlertDialogComponent
        isOpen={showModal}
        onClose={closeDeleteModal}
        callbackFn={onIssueDeleted}
        msg="Are you sure you want to delete?"
        title="Delete Issue"
        isDelete
      />
    </Box>
  );
};

export default IssueTab;
