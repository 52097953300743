import schema from '../schema';
import { PanelObject } from '../types';
import { dateRangeGranularity, dateRangeGranularityForCalendar } from '../utils/date.utils';
import { formatDateValue, formatDurationValue } from '../utils/graphs.utils';
import {
  priorityFilter,
  servicesFilter,
  tagsFilter,
  teamsFilter,
  timeDimensions,
  usersFilter,
  wrapFilters,
} from '../utils/query.utils';
import { durationUnits, textUnits } from '../utils/stats.utils';

export const panels: PanelObject[] = [
  {
    id: 'incidents',
    title: 'Incidents',
    gridPos: { x: 0, y: 0, w: 4, h: 1 },
    query: filters => ({
      measures: [schema.IncidentsCount],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date, { compare: true }),
      filters: wrapFilters(
        usersFilter(
          [
            schema.IncidentsAcknowledgedByUserId,
            schema.IncidentsResolvedByUserId,
            schema.IncidentsAssignedToUserId,
          ],
          filters.userIds,
        ),
        tagsFilter(schema.IncidentsTags, filters.tags),
        servicesFilter(schema.IncidentsServiceId, filters.serviceIds),
        priorityFilter(schema.IncidentsPriority, filters.priority),
        teamsFilter(schema.IncidentsTeamId, filters.teamIds),
      ),
    }),
    chart: { type: 'stats', unitsFn: textUnits('incident') },
  },
  {
    id: 'mtta',
    title: 'MTTA',
    // Template literal for multiline tooltip
    helpText: `It shows how quickly your team is able to respond to an incident.
    This is calculated by their time to Acknowledge an incident.`,
    gridPos: { x: 4, y: 0, w: 4, h: 1 },
    query: filters => ({
      measures: [schema.IncidentsMTTA],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date, { compare: true }),
      filters: wrapFilters(
        usersFilter(
          [
            schema.IncidentsAcknowledgedByUserId,
            schema.IncidentsResolvedByUserId,
            schema.IncidentsAssignedToUserId,
          ],
          filters.userIds,
        ),
        tagsFilter(schema.IncidentsTags, filters.tags),
        servicesFilter(schema.IncidentsServiceId, filters.serviceIds),
        priorityFilter(schema.IncidentsPriority, filters.priority),
        teamsFilter(schema.IncidentsTeamId, filters.teamIds),
      ),
    }),
    chart: { type: 'stats', unitsFn: durationUnits() },
  },
  {
    id: 'mttr',
    title: 'MTTR',
    // Template literal for multiline tooltip
    helpText: `It shows how quickly your team is able to resolve incidents as they arise.
    This is calculated by their time to Resolve an incident.`,
    gridPos: { x: 8, y: 0, w: 4, h: 1 },
    query: filters => ({
      measures: [schema.IncidentsMTTR],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date, { compare: true }),
      filters: wrapFilters(
        usersFilter(
          [
            schema.IncidentsAcknowledgedByUserId,
            schema.IncidentsResolvedByUserId,
            schema.IncidentsAssignedToUserId,
          ],
          filters.userIds,
        ),
        tagsFilter(schema.IncidentsTags, filters.tags),
        servicesFilter(schema.IncidentsServiceId, filters.serviceIds),
        priorityFilter(schema.IncidentsPriority, filters.priority),
        teamsFilter(schema.IncidentsTeamId, filters.teamIds),
      ),
    }),
    chart: { type: 'stats', unitsFn: durationUnits() },
  },
  {
    id: 'mtta_mttr_over_time',
    title: 'MTTA & MTTR Over Time',
    gridPos: { x: 0, y: 1, w: 12, h: 2.25 },
    query: filters => ({
      measures: [schema.IncidentsMTTA, schema.IncidentsMTTR],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date, {
        granularity: dateRangeGranularity(filters.date),
      }),
      dimensions: [schema.TeamsName],
      filters: wrapFilters(
        usersFilter(
          [
            schema.IncidentsAcknowledgedByUserId,
            schema.IncidentsResolvedByUserId,
            schema.IncidentsAssignedToUserId,
          ],
          filters.userIds,
        ),
        tagsFilter(schema.IncidentsTags, filters.tags),
        servicesFilter(schema.IncidentsServiceId, filters.serviceIds),
        priorityFilter(schema.IncidentsPriority, filters.priority),
        teamsFilter(schema.IncidentsTeamId, filters.teamIds),
      ),
    }),
    chart: {
      type: 'lines',
      yFormat: value => formatDurationValue(value as number),
      xFormat: date => formatDateValue(date as Date),
      seriesNames: {
        [schema.IncidentsMTTA]: 'MTTA',
        [schema.IncidentsMTTR]: 'MTTR',
      },
    },
  },
  {
    id: 'incident_count_by_date',
    title: 'Incident Count by Date',
    gridPos: { x: 0, y: 1, w: 8, h: 2.25 },
    query: filters => ({
      measures: [schema.IncidentsCount],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date, {
        granularity: 'day',
      }),
      filters: wrapFilters(
        usersFilter(
          [
            schema.IncidentsAcknowledgedByUserId,
            schema.IncidentsResolvedByUserId,
            schema.IncidentsAssignedToUserId,
          ],
          filters.userIds,
        ),
        tagsFilter(schema.IncidentsTags, filters.tags),
        servicesFilter(schema.IncidentsServiceId, filters.serviceIds),
        priorityFilter(schema.IncidentsPriority, filters.priority),
        teamsFilter(schema.IncidentsTeamId, filters.teamIds),
      ),
    }),
    chart: {
      type: 'calendar',
      measures: [schema.IncidentsCount],
      dimension: schema.ServicesName, // TODO: unused for now, fix later to generate better bar names/tooltips
    },
  },
  {
    id: 'incident_status_by_date',
    title: 'Incident Status by Date',
    gridPos: { x: 8, y: 1, w: 4, h: 2.25 },
    query: filters => ({
      measures: [schema.IncidentsCount],
      dimensions: [schema.IncidentsStatus],
      timeDimensions: timeDimensions(
        schema.IncidentsCreatedAt,
        {
          preset: 'custom',
          // Depends upon the `incident_count_by_date` for date
          startDate: filters.customDate ?? filters.date.endDate,
          endDate: filters.customDate ?? filters.date.endDate,
        },
        {
          granularity: 'day',
        },
      ),
      filters: wrapFilters(
        usersFilter(
          [
            schema.IncidentsAcknowledgedByUserId,
            schema.IncidentsResolvedByUserId,
            schema.IncidentsAssignedToUserId,
          ],
          filters.userIds,
        ),
        tagsFilter(schema.IncidentsTags, filters.tags),
        servicesFilter(schema.IncidentsServiceId, filters.serviceIds),
        priorityFilter(schema.IncidentsPriority, filters.priority),
        teamsFilter(schema.IncidentsTeamId, filters.teamIds),
      ),
    }),
    chart: {
      type: 'pie',
      measures: [schema.IncidentsCount],
      dimension: schema.ServicesName, // TODO: unused for now, fix later to generate better bar names/tooltips
    },
  },
  {
    id: 'open_incidents_by_service',
    title: 'Open Incidents by Service',
    gridPos: { x: 0, y: 5.5, w: 6, h: 2.5 },
    query: filters => ({
      measures: [schema.IncidentsCount],
      order: { [schema.IncidentsCount]: 'desc' },
      dimensions: [schema.ServicesName],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date),
      filters: wrapFilters(
        {
          member: schema.IncidentsStatus,
          operator: 'equals',
          values: ['acknowledged', 'triggered'],
        },
        usersFilter(
          [
            schema.IncidentsAcknowledgedByUserId,
            schema.IncidentsResolvedByUserId,
            schema.IncidentsAssignedToUserId,
          ],
          filters.userIds,
        ),
        tagsFilter(schema.IncidentsTags, filters.tags),
        servicesFilter(schema.IncidentsServiceId, filters.serviceIds),
        priorityFilter(schema.IncidentsPriority, filters.priority),
        teamsFilter(schema.IncidentsTeamId, filters.teamIds),
      ),
      limit: 10,
    }),
    chart: {
      type: 'bars',
      measures: [schema.IncidentsCount],
      dimension: schema.ServicesName, // TODO: unused for now, fix later to generate better bar names/tooltips
    },
  },
  {
    id: 'open_incidents_by_users',
    title: 'Open Incidents by Users',
    gridPos: { x: 6, y: 5.5, w: 6, h: 2.5 },
    query: filters => ({
      measures: [schema.IncidentsCount],
      order: { [schema.IncidentsCount]: 'desc' },
      dimensions: [schema.UsersName],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date),
      filters: wrapFilters(
        {
          member: schema.IncidentsStatus,
          operator: 'equals',
          values: ['acknowledged', 'triggered'],
        },
        usersFilter(
          [
            schema.IncidentsAcknowledgedByUserId,
            schema.IncidentsResolvedByUserId,
            schema.IncidentsAssignedToUserId,
          ],
          filters.userIds,
        ),
        tagsFilter(schema.IncidentsTags, filters.tags),
        servicesFilter(schema.IncidentsServiceId, filters.serviceIds),
        priorityFilter(schema.IncidentsPriority, filters.priority),
        teamsFilter(schema.IncidentsTeamId, filters.teamIds),
      ),
      limit: 10,
    }),
    chart: {
      type: 'bars',
      measures: [schema.IncidentsCount],
      dimension: schema.ServicesName, // TODO: unused for now, fix later to generate better bar names/tooltips
    },
  },
  {
    id: 'deduped_incidents_by_services',
    title: 'Deduplicated Incidents by Services',
    gridPos: { x: 0, y: 8, w: 6, h: 2.5 },
    query: filters => ({
      measures: [schema.IncidentsDuplicatesCount],
      order: { [schema.IncidentsDuplicatesCount]: 'desc' },
      dimensions: [schema.ServicesName],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date),
      filters: wrapFilters(
        usersFilter(
          [
            schema.IncidentsAcknowledgedByUserId,
            schema.IncidentsResolvedByUserId,
            schema.IncidentsAssignedToUserId,
          ],
          filters.userIds,
        ),
        tagsFilter(schema.IncidentsTags, filters.tags),
        servicesFilter(schema.IncidentsServiceId, filters.serviceIds),
        priorityFilter(schema.IncidentsPriority, filters.priority),
        teamsFilter(schema.IncidentsTeamId, filters.teamIds),
      ),
      limit: 10,
    }),
    chart: {
      type: 'bars',
      measures: [schema.IncidentsDuplicatesCount],
      dimension: schema.ServicesName, // TODO: unused for now, fix later to generate better bar names/tooltips
    },
  },
  {
    id: 'deduped_incidents_by_alert_sources',
    title: 'Deduplicated Incidents by Alert Sources',
    gridPos: { x: 6, y: 8, w: 6, h: 2.5 },
    query: filters => ({
      measures: [schema.IncidentsDuplicatesCount],
      order: { [schema.IncidentsDuplicatesCount]: 'desc' },
      dimensions: [schema.AlertSourcesName],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date),
      filters: wrapFilters(
        { member: 'AlertSources.id', operator: 'set' }, // required for local dev because data gen sometimes generates a non-existent alert source
        usersFilter(
          [
            schema.IncidentsAcknowledgedByUserId,
            schema.IncidentsResolvedByUserId,
            schema.IncidentsAssignedToUserId,
          ],
          filters.userIds,
        ),
        tagsFilter(schema.IncidentsTags, filters.tags),
        servicesFilter(schema.IncidentsServiceId, filters.serviceIds),
        priorityFilter(schema.IncidentsPriority, filters.priority),
        teamsFilter(schema.IncidentsTeamId, filters.teamIds),
      ),
      limit: 10,
    }),
    chart: {
      type: 'bars',
      measures: [schema.IncidentsDuplicatesCount],
      dimension: schema.ServicesName, // TODO: unused for now, fix later to generate better bar names/tooltips
    },
  },
  {
    id: 'suppressed_incidents_by_service',
    title: 'Suppressed Incidents by Service',
    gridPos: { x: 0, y: 10.5, w: 6, h: 2.5 },
    query: filters => ({
      measures: [schema.IncidentsCount],
      order: { [schema.IncidentsCount]: 'desc' },
      dimensions: [schema.ServicesName],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date),
      filters: wrapFilters(
        { member: schema.IncidentsStatus, operator: 'equals', values: ['suppressed'] },
        { member: schema.IncidentsIsChild, operator: 'equals', values: ['false'] },
        usersFilter(
          [
            schema.IncidentsAcknowledgedByUserId,
            schema.IncidentsResolvedByUserId,
            schema.IncidentsAssignedToUserId,
          ],
          filters.userIds,
        ),
        tagsFilter(schema.IncidentsTags, filters.tags),
        servicesFilter(schema.IncidentsServiceId, filters.serviceIds),
        priorityFilter(schema.IncidentsPriority, filters.priority),
        teamsFilter(schema.IncidentsTeamId, filters.teamIds),
      ),
      limit: 10,
    }),
    chart: {
      type: 'bars',
      measures: [schema.IncidentsCount],
      dimension: schema.ServicesName, // TODO: unused for now, fix later to generate better bar names/tooltips
    },
  },
  {
    id: 'suppressed_incidents_by_alert_sources',
    title: 'Suppressed Incidents by Alert Sources',
    gridPos: { x: 6, y: 10.5, w: 6, h: 2.5 },
    query: filters => ({
      measures: [schema.IncidentsCount],
      order: { [schema.IncidentsCount]: 'desc' },
      dimensions: [schema.AlertSourcesName],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date),
      filters: wrapFilters(
        { member: schema.IncidentsStatus, operator: 'equals', values: ['suppressed'] },
        { member: schema.IncidentsIsChild, operator: 'equals', values: ['false'] },
        { member: 'AlertSources.id', operator: 'set' }, // required for local dev because data gen sometimes generates a non-existent alert source
        usersFilter(
          [
            schema.IncidentsAcknowledgedByUserId,
            schema.IncidentsResolvedByUserId,
            schema.IncidentsAssignedToUserId,
          ],
          filters.userIds,
        ),
        tagsFilter(schema.IncidentsTags, filters.tags),
        servicesFilter(schema.IncidentsServiceId, filters.serviceIds),
        priorityFilter(schema.IncidentsPriority, filters.priority),
        teamsFilter(schema.IncidentsTeamId, filters.teamIds),
      ),
      limit: 10,
    }),
    chart: {
      type: 'bars',
      measures: [schema.IncidentsCount],
      dimension: schema.ServicesName, // TODO: unused for now, fix later to generate better bar names/tooltips
    },
  },
  {
    id: 'reassigned_incidents_by_teams',
    title: 'Reassigned Incidents by Teams',
    gridPos: { x: 0, y: 13, w: 6, h: 2.5 },
    query: filters => ({
      measures: [schema.IncidentsCount],
      order: { [schema.IncidentsCount]: 'desc' },
      dimensions: [schema.TeamsName],
      timeDimensions: timeDimensions(schema.IncidentsCreatedAt, filters.date),
      filters: wrapFilters(
        { member: schema.IncidentsReassignedCount, operator: 'gt', values: ['0'] },
        usersFilter(
          [
            schema.IncidentsAcknowledgedByUserId,
            schema.IncidentsResolvedByUserId,
            schema.IncidentsAssignedToUserId,
          ],
          filters.userIds,
        ),
        tagsFilter(schema.IncidentsTags, filters.tags),
        servicesFilter(schema.IncidentsServiceId, filters.serviceIds),
        priorityFilter(schema.IncidentsPriority, filters.priority),
        teamsFilter(schema.IncidentsTeamId, filters.teamIds),
      ),
      limit: 10,
    }),
    chart: {
      type: 'bars',
      measures: [schema.IncidentsCount],
      dimension: schema.ServicesName, // TODO: unused for now, fix later to generate better bar names/tooltips
    },
  },
];
