import { ChakraProvider, Text, Tooltip, VStack } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { T_WA_WP_LIST_PAGE_VIEWED } from 'core/const/tracker';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppTracker } from 'shared/analytics/tracker';
import { WORKFLOWS_CREATE_PATH } from 'views/main/routes/routes';
import libraryTheme from 'library/theme';
import FilterDrawer from './filter/Drawer';
import { useWorkflowListContext, WorkflowListProvider } from '../context/workflowListContext';
import WorkflowTable from './Table';
import FilterPreview from './filter/Preview';
import { BillingService } from 'core';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { useWorkflowPlan } from '../store/useWorkflowPlan';
import { useWorkflowCountQuery } from '../api/hook';
import { Layout, PageHeader } from 'library/molecules';
import { ErrorBoundary } from 'views/shared/components';
import { useUserAccess } from 'core/userAccess/UserAccessContext';

const WorkflowDashboard = () => {
  return (
    <ChakraProvider theme={libraryTheme}>
      <Layout>
        <ErrorBoundary>
          <WorkflowListProvider>
            <WorkflowList />
          </WorkflowListProvider>
        </ErrorBoundary>
      </Layout>
    </ChakraProvider>
  );
};

const WorkflowList = () => {
  const compareWorkflowLimit = useWorkflowCountQuery();
  const workflowCreatePermission = useUserAccess().hasCreateAccess('workflows');
  const workflowContext = useWorkflowListContext();
  const { workflowOrg } = useWorkflowPlan();

  const workflowLimit = BillingService.getLimit({ organization: workflowOrg }, 'workflows');
  const workflowsExistInPlan =
    workflowLimit === 'unlimited' || workflowLimit! > compareWorkflowLimit?.data?.count;
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const history = useHistory();
  useEffect(() => {
    AppTracker.track(T_WA_WP_LIST_PAGE_VIEWED);
  }, []);

  return (
    <>
      <VStack h="inherit" spacing={0} bg="container.background">
        <PageHeader
          title="Workflows"
          searchValue={workflowContext.searchValue}
          onSearch={workflowContext.onSearch}
          search={true}
          filter={true}
          onFilter={workflowContext.onOpenFilter}
          isFilterApplied={workflowContext.isFilterApplied}
          button={'Create Workflow'}
          onButtonClick={() => {
            if (!workflowsExistInPlan) {
              setShowUpgradeModal(true);
            } else {
              history.push(WORKFLOWS_CREATE_PATH);
            }
          }}
          buttonDisabled={!workflowCreatePermission}
        >
          <FilterPreview />
        </PageHeader>
        <WorkflowTable
          showUpgradeModal={workflowsExistInPlan}
          setShowUpgradeModal={setShowUpgradeModal}
        />
      </VStack>
      <FilterDrawer />
      <UpgradePlanModal
        hasBillingPermission={BillingService.hasManageBillingPermission({
          organization: workflowOrg,
        })}
        showModal={showUpgradeModal}
        message={BillingService.getMessage(0, 'workflows', { organization: workflowOrg })}
        onCancel={() => {
          setShowUpgradeModal(false);
        }}
        header={BillingService.getHeader(0, 'workflows', { organization: workflowOrg })}
        onUpgrade={() => setShowUpgradeModal(false)}
      />
    </>
  );
};

export default WorkflowDashboard;
