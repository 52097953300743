import {
  REQUEST_ACCOUNT_PROFILE_UPDATE,
  REQUEST_ACCOUNT_PROFILE_UPDATE_SUCCESS,
  REQUEST_ACCOUNT_PROFILE_UPDATE_FAIL,
  REQUEST_ACCOUNT_PASSWORD_CHANGE,
  REQUEST_ACCOUNT_PASSWORD_CHANGE_SUCCESS,
  REQUEST_ACCOUNT_PASSWORD_CHANGE_FAIL,
  REQUEST_ACCOUNT_NOTIFICATION_UPDATE,
  REQUEST_ACCOUNT_NOTIFICATION_UPDATE_SUCCESS,
  REQUEST_ACCOUNT_NOTIFICATION_UPDATE_FAIL,
  REQUEST_ACCOUNT_TIMEZONE_UPDATE,
} from '../../const/account';
import { IAccountUserUpdateRequest, IChangePassword } from '../../interfaces/IAccount';
import { IUserNotificationRule } from '../../interfaces/IUserData';

const requestUserAccountUpdate = (data: IAccountUserUpdateRequest) => ({
  type: REQUEST_ACCOUNT_PROFILE_UPDATE,
  payload: {
    data,
  },
});

const onRequestUserAccountUpdateSuccess = (userUpdate: IAccountUserUpdateRequest) => ({
  type: REQUEST_ACCOUNT_PROFILE_UPDATE_SUCCESS,
  payload: {
    userUpdate,
  },
});

const onRequestUserAccountUpdateFail = (error: any) => ({
  type: REQUEST_ACCOUNT_PROFILE_UPDATE_FAIL,
  payload: {
    error,
  },
});

const requestPasswordChange = (data: IChangePassword) => ({
  type: REQUEST_ACCOUNT_PASSWORD_CHANGE,
  payload: {
    data,
  },
});

const onRequestPasswordChangeSuccess = () => ({
  type: REQUEST_ACCOUNT_PASSWORD_CHANGE_SUCCESS,
  payload: {},
});

const onRequestPasswordChangeFail = (error: any) => ({
  type: REQUEST_ACCOUNT_PASSWORD_CHANGE_FAIL,
  payload: {
    error,
  },
});

const requestNotificationUpdate = (notificationRules: IUserNotificationRule[]) => ({
  type: REQUEST_ACCOUNT_NOTIFICATION_UPDATE,
  payload: {
    notificationRules,
  },
});

const onRequestNotificationUpdateSuccess = (notificationRules: IUserNotificationRule[]) => ({
  type: REQUEST_ACCOUNT_NOTIFICATION_UPDATE_SUCCESS,
  payload: {
    notificationRules,
  },
});

const onRequestNotificationUpdateFail = (error: any) => ({
  type: REQUEST_ACCOUNT_NOTIFICATION_UPDATE_FAIL,
  payload: {
    error,
  },
});

const updateUserTimeZone = (timeZone: string) => ({
  type: REQUEST_ACCOUNT_TIMEZONE_UPDATE,
  payload: {
    timeZone,
  },
});

export {
  requestUserAccountUpdate,
  onRequestUserAccountUpdateSuccess,
  onRequestUserAccountUpdateFail,
  requestPasswordChange,
  onRequestPasswordChangeSuccess,
  onRequestPasswordChangeFail,
  requestNotificationUpdate,
  onRequestNotificationUpdateSuccess,
  onRequestNotificationUpdateFail,
  updateUserTimeZone,
};
