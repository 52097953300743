import { FILTER_TYPES, SearchActionKind } from '../constants/status.constants';
import { IfilterObject } from '../Interface';

// An interface for our actions
interface SearchAction {
  type: SearchActionKind;
  searchText?: string;
  isEnabled?: boolean;
  queryPageIndex?: number;
  queryPageSize?: number;
}

// An interface for our state
interface SubscribersListState {
  search: {
    searchText: string;
    enabled: boolean;
  };
  pagination: {
    queryPageIndex: number;
    queryPageSize: number;
  };
}

export function SubscribersListReducer(state: SubscribersListState, action: SearchAction) {
  const {
    type,
    isEnabled = false,
    searchText = '',
    queryPageIndex = 1,
    queryPageSize = 10,
  } = action;
  switch (type) {
    case SearchActionKind.SEARCH_ENABLED_OR_DISABLED:
      return {
        ...state,
        search: {
          ...state.search,
          enabled: isEnabled,
        },
      };
    case SearchActionKind.SEARCHED:
      return {
        ...state,
        search: {
          ...state.search,
          searchText: searchText,
        },
      };

    case SearchActionKind.PAGE_INDEX_CHANGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          queryPageIndex,
        },
      };
    case SearchActionKind.PAGE_SIZE_CHANGED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          queryPageSize,
        },
      };

    default:
      return state;
  }
}
