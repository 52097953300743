import Axios from 'axios';
import { API, IncidentService } from 'core';
import { IIncidentList, IIncidentRequestParams } from 'core/interfaces/IIncidents';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { createStandaloneToast } from '@chakra-ui/react';

import { queryKeys, reactQueryConfig } from '../contants/service.contant';
import { IJIraCloudServiceNew, IServiceList } from './useServiceList';
import moment from 'moment';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/helper.service';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_CUSTOM_CONTENT_TEMPLATE_DELETED } from 'core/const/tracker';
import { DelayNotificationConfig } from '../types/service-detail.types';
import { AppConfig } from 'shared/app.config';
import { EntityACLMeta } from 'core/userAccess/types';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';

export interface ServiceDetail {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  escalation_policy: EscalationPolicy;
  api_key: string;
  description: string;
  tags: Tag[];
  maintainer: Maintainer;
  owner: Owner;
  status: string;
  active_alert_sources: ActiveAlertSource[];
  extensions: IExtensions;
  dependencies: string[];
  email: string;
  next_maintenance_window: {
    maintenance_from: string;
    maintenance_till: string;
  };
  auto_pause_transient_alerts_config: ServiceAptaConfig;
  intelligent_alerts_grouping_config: ServiceIAGConfig;
  delay_notification_config: DelayNotificationConfig;
}

export type ServiceAptaConfig = {
  is_enabled: boolean;
  timeout_in_mins: number;
};

export type ServiceIAGConfig = {
  is_enabled: boolean;
  rolling_window_in_mins: number;
};

export interface ICustomTemplate {
  created_at: string;
  updated_at: string;
  deleted_at: any;
  org_id: string;
  service_id: string;
  alert_source_version: string;
  alert_source_shortname: string;
  overlay_template_type: string;
  overlay: Overlay;
  created_by: string;
  updated_by: string;
  alert_source_type: string;
}

export interface Overlay {
  message: string;
  description: string;
}

interface EscalationPolicy {
  id: string;
  name: string;
  description: string;
  slug: string;
}

interface Tag {
  key: string;
  value: string;
}

interface Maintainer {
  id: string;
  type: string;
  name: string;
}

interface Owner {
  id: string;
  type: string;
  name: string;
}

export interface ActiveAlertSource {
  id: string;
  type: string;
  short_name: string;
}

export interface IExtensions {
  jira_cloud: IJIraCloudServiceNew | null;
  slack: Slack | null;
}

export interface Slack {
  channel_id: string;
  name: string;
  time: number;
}

export interface JiraCloud {
  jira_client_key: string;
  project: Project;
  issue_type: IssueType;
  statusmaps: Statusmap[];
  is_manual: boolean;
}

interface Project {
  id: string;
  key: string;
  name: string;
}

interface IssueType {
  id: string;
  name: string;
  statuses: Status[];
}

interface Status {
  id: string;
  name: string;
}

interface Statusmap {
  jira_status: string;
  system_status: string;
}

const toast = createStandaloneToast();
const serviceDefaultParams = {
  selectedUnit: 30,
  incidentsFor: 'all',
  from: 0,
  status: ['acknowledged', 'resolved', 'suppressed', 'triggered'],
  customDates: false,
};

const getServiceDetail = (serviceId: string | null) => async () => {
  const { data } = await Axios.get<{
    data: ServiceDetail;
    meta: { acl: Record<string, EntityACLMeta> };
  }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/catalog-services/${serviceId}`,
  );
  setUserAccessStore({
    entityACLMap: {
      ...getUserAccessStore().entityACLMap,
      services: {
        ...getUserAccessStore().entityACLMap?.services,
        ...data.meta.acl,
      },
    },
  });
  return data.data;
};

const useServiceDetailQuery = (serviceId: string | null) => {
  return useQuery([queryKeys.SERVICEDETAIL, serviceId], getServiceDetail(serviceId), {
    ...reactQueryConfig,
    enabled: !!serviceId,
    staleTime: 30 * 1000,
    onError: reactQueryConfigError,
  });
};

const getServiceDependency = (serviceId: string, teamID: string | unknown) => async () => {
  const { data } = await Axios.get<IServiceList>(
    `${API.config.batman}/organizations/${API.config.organizationId}/catalog-services/${serviceId}/dependencies?page_limit=100&offset=1&owner_id=${teamID}`,
  );
  return data;
};

const useServiceDependencyQuery = (serviceId: string, teamID: string | unknown) => {
  return useQuery(
    [queryKeys.SERVICEDEPENCENCY, serviceId, teamID],
    getServiceDependency(serviceId, teamID),
    {
      ...reactQueryConfig,
      enabled: !!serviceId,
      staleTime: 30 * 1000,
      keepPreviousData: true,
      onError: reactQueryConfigError,
    },
  );
};

const updateServiceDependency = (serviceId: string) => async (dependencies: string[]) => {
  const { data } = await Axios.post(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/services/${serviceId}/dependencies`,
    { data: dependencies },
  );

  return data;
};

const useUpdateDependencyMutation = (serviceId: string, teamID: string) => {
  const queryClient = useQueryClient();

  return useMutation(updateServiceDependency(serviceId), {
    onSuccess: () => {
      queryClient.refetchQueries([queryKeys.SERVICEDEPENCENCY, serviceId, teamID]);
      reactQueryConfigSuccess('Dependency updated');
    },
    onError: (err: any) => {
      toast({
        title: err?.response?.data?.meta?.error_message ?? 'Failed to update dependency',
        status: 'error',
        variant: 'subtle',
        isClosable: true,
      });
    },
  });
};

const getServiceMetrics = (params: Partial<IIncidentRequestParams>) => async () => {
  const incidentInstance = new IncidentService();
  const { data } = await incidentInstance.getIncidentsMetrics(params);
  return data.data;
};

/**
 * Get Service Metrics
 * @param serviceId Service ID
 * @param owner_id Team ID
 * @param enabled enable query
 * @param range range
 * @returns Service Metrics
 */
const useServiceMetricsQuery = (
  serviceId: string,
  owner_id: string,
  enabled?: boolean,
  range?: 'month' | 'lifelong',
) => {
  let params = {
    ...serviceDefaultParams,
    services: [serviceId],
    owner_id: owner_id,
  } as Partial<IIncidentRequestParams>;

  if (range === 'lifelong') {
    params = {
      ...params,
      customDates: true,
      fromDate: moment('2017').toISOString(true),
      toDate: moment().endOf('day').toISOString(true),
    };
  }

  return useQuery([queryKeys.SERVICEDETAILMETRICS, params], getServiceMetrics(params), {
    ...reactQueryConfig,
    enabled: !!serviceId && !!owner_id && enabled,
    onError: reactQueryConfigError,
  });
};

const getServiceStats = (params: Partial<IIncidentRequestParams>) => async () => {
  const incidentInstance = new IncidentService();
  const { data } = await incidentInstance.getIncidentsCount(params);
  return data.data;
};

/**
 * Get Service Stats
 * @param serviceId Service ID
 * @param owner_id Team ID
 * @param enabled enable query
 * @param range range
 * @returns Service Stats
 */
const useServiceStatsQuery = (
  serviceId: string,
  owner_id: string,
  enabled?: boolean,
  range?: 'month' | 'lifelong',
) => {
  let params = {
    ...serviceDefaultParams,
    services: [serviceId],
    owner_id: owner_id,
  } as Partial<IIncidentRequestParams>;

  if (range === 'lifelong') {
    params = {
      ...params,
      customDates: true,
      fromDate: moment('2017').toISOString(true),
      toDate: moment().endOf('day').toISOString(true),
    };
  }

  return useQuery([queryKeys.SERVICEDETAILSTATS, params, range], getServiceStats(params), {
    ...reactQueryConfig,
    enabled: !!serviceId && !!owner_id && enabled,
    onError: reactQueryConfigError,
  });
};

const getServiceMetricsByRange =
  (params: { from_date: string; to_date: string; service_id: string }) => async () => {
    const { data } = await Axios.get<{ data: { mttr: number; mtta: number } }>(
      `${API.config.batman}/organizations/${API.config.organizationId}/catalog-services/${params.service_id}/metrics/responsiveness`,
      {
        params: {
          from_date: params.from_date,
          to_date: params.to_date,
        },
      },
    );
    return data.data;
  };

/**
 * Get Service Stats and Metrics by date range
 * @param serviceId Service ID
 * @returns Service Stats
 */
const useServiceMetricsByRangeQuery = (serviceId: string) => {
  const params = {
    service_id: serviceId,
    from_date: moment('2017').toISOString(true),
    to_date: moment().endOf('day').toISOString(true),
  };

  return useQuery([queryKeys.SERVICEDETAILSTATS, params], getServiceMetricsByRange(params), {
    ...reactQueryConfig,
    enabled: !!serviceId,
    select: res => ({
      currentData: { mtta: res.mtta, mttr: res.mttr },
      previousData: { mtta: res.mtta, mttr: res.mttr },
    }),
    onError: reactQueryConfigError,
  });
};

const getTodayServiceIncidents = (params: Partial<IIncidentRequestParams>) => async () => {
  const { data } = await Axios.get<{ data: IIncidentList }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents`,
    {
      params,
    },
  );
  return data.data;
};

/**
 * Get Service Incidents
 * @param serviceId Service ID
 * @param owner_id Team ID
 * @returns Service Incidents
 */
const useTodayServiceIncidentsQuery = (serviceId: string, owner_id: string) => {
  const fromDate = moment().startOf('day').toISOString(true);
  const toDate = moment().endOf('day').toISOString(true);

  const params = {
    ...serviceDefaultParams,
    services: [serviceId],
    owner_id: owner_id,
    customDates: true,
    limit: 100,
    fromDate,
    toDate,
  } as Partial<IIncidentRequestParams>;

  return useQuery([queryKeys.SERVICEINCIDENTSTODAY, params], getTodayServiceIncidents(params), {
    ...reactQueryConfig,
    enabled: !!serviceId && !!owner_id,
    onError: reactQueryConfigError,
  });
};

const getServiceCustomTemplate = (serviceId: string | null) => async () => {
  const { data } = await Axios.get<{ data: Array<ICustomTemplate> }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/overlays/custom-content`,
  );
  return data.data;
};

const useServiceCustomTemplateQuery = (
  serviceId: string | null,
  isCCTDisabled: boolean,
  viewPermission: boolean,
) => {
  return useQuery([queryKeys.CUSTOMTEMPLATE, serviceId], getServiceCustomTemplate(serviceId), {
    ...reactQueryConfig,
    enabled: !!serviceId && !isCCTDisabled && viewPermission,
    refetchOnMount: true,
    onError: reactQueryConfigError,
  });
};

const getOneCustomTemplate = (serviceId: string | null, alertSource: string) => async () => {
  const { data } = await Axios.get<{ data: ICustomTemplate }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/overlays/custom-content/${alertSource}`,
  );
  return data.data;
};

const useGetOneCustomTemplateQuery = (
  serviceId: string | null,
  alertSource: string,
  hasCustomTemplate: string | null,
) => {
  return useQuery(
    [queryKeys.CUSTOMTEMPLATE, serviceId, alertSource],
    getOneCustomTemplate(serviceId, alertSource),
    {
      ...reactQueryConfig,
      enabled: !!serviceId && !!alertSource && !!hasCustomTemplate,
      staleTime: 30 * 1000,
      onError: reactQueryConfigError,
    },
  );
};

const deleteCustomTemplate = async ({
  serviceId,
  alertSource,
}: {
  serviceId: string;
  alertSource: string;
}): Promise<ICustomTemplate> => {
  const { data } = await Axios.delete<{ data: ICustomTemplate }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/overlays/custom-content/${alertSource}`,
  );
  return data.data;
};

const useDeleteCustomTemplateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<ICustomTemplate, unknown, { serviceId: string; alertSource: string }>(
    deleteCustomTemplate,
    {
      onSuccess: () => {
        queryClient.refetchQueries([queryKeys.CUSTOMTEMPLATE]);
        reactQueryConfigSuccess('Custom Template deleted');
        AppTracker.track(T_WA_UP_CUSTOM_CONTENT_TEMPLATE_DELETED);
      },
      onError: () => {
        toast({
          title: 'Failed to delete Custom Template',
          status: 'error',
          variant: 'subtle',
          isClosable: true,
        });
      },
    },
  );
};

export {
  useServiceDetailQuery,
  useServiceStatsQuery,
  useServiceMetricsQuery,
  useServiceMetricsByRangeQuery,
  useTodayServiceIncidentsQuery,
  useUpdateDependencyMutation,
  useServiceDependencyQuery,
  useServiceCustomTemplateQuery,
  useGetOneCustomTemplateQuery,
  useDeleteCustomTemplateMutation,
};
