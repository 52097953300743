import {
  REQUEST_ORG_PLAN_SUCCESS,
  REQUEST_ORG_USERS_SUCCESS,
  REQUEST_ORG_TEAMS_SUCCESS,
  REQUEST_ORG_CURRENT_USER_SUCCESS,
} from 'core/const/organization';
import { REQUEST_USER_PERMISSION_ROLES_SUCCESS } from 'core/const/public';
import { getInitOrgState, setInitOrgState } from 'core/hooks/useInitOrgGate';
import { IAppActions } from 'core/interfaces/IAppState';

export const tasks = [
  REQUEST_USER_PERMISSION_ROLES_SUCCESS,
  REQUEST_ORG_PLAN_SUCCESS,
  REQUEST_ORG_USERS_SUCCESS,
  REQUEST_ORG_TEAMS_SUCCESS,
  REQUEST_ORG_CURRENT_USER_SUCCESS,
];

export const allInitOrgActions = (store: any) => (next: any) => (action: IAppActions) => {
  // don't block any actions here
  if (tasks.includes(action.type)) {
    tasks.splice(tasks.indexOf(action.type), 1);
  }

  if (tasks.length === 0) {
    setInitOrgState({
      initOrgSuccess: true,
    });
  }
  return next(action);
};
