import axios from 'axios';
import { number } from 'yup/lib/locale';
import { API } from '../api';
import { ISuppressionRule } from '../interfaces/IService';

class SuppressionRulesService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/services`;

  private orgSuppressionCountApi = `${API.config.batman}/organizations/${API.config.organizationId}/suppression-rules/count`;

  constructor(serviceId: string) {
    this._api = `${this._api}/${serviceId}/suppression-rules`;
  }

  public getOrgCount = () => axios.get<{ data: { count: number } }>(this.orgSuppressionCountApi);

  public get = () => axios.get<{ data: { rules: ISuppressionRule[] } }>(this._api);

  public save = (rules: ISuppressionRule[]) => axios.post(this._api, { rules });
}

export default SuppressionRulesService;
