import React from 'react';
import { JiraCloudModal } from '.';
import { TextButton, SelectBox, InputBox } from '@squadcast/alchemy-ui/metal';
import { Row, FocusBlock, ErrorText, CheckBox } from '@squadcast/alchemy-ui/carbon';
import shortid from 'shortid';

function renders(this: JiraCloudModal) {
  const {
    componentTabState,
    appState,
    jiraProjects,
    projectIssues,
    projectSearchString,
    issuesSearchString,
    isCreated,
  } = this.state;

  return (
    <div>
      {componentTabState === 'token' && (
        <div>
          <InputBox
            id="jira_cloud_api_token"
            label="Jira API token"
            name="jira api token"
            type="text"
            placeHolder="Paste your Jira API token here"
            value={this.state.apiToken}
            disabled={isCreated}
            onChange={(_, value) => {
              this.setState({ apiToken: value });
              this.props.checkAndSetDirty();
            }}
            error={this.state.errors.token ? this.state.errors.token : ''}
          />

          <div className="mt-20">
            <TextButton
              id="to_projects"
              disabled={this.state.apiToken.length === 0}
              onClick={this.saveToken}
              text={`${appState === 'no-token' ? 'Save & ' : ''}Next`}
            />
          </div>
        </div>
      )}

      {componentTabState === 'project' && (
        <div>
          <div>
            <p className="item-box-tagline font-bold m-0" style={{ marginBottom: 5 }}>
              Select a Jira Project
            </p>
            <SelectBox
              id="Jira_projects"
              name="Jira projects"
              label="Jira projects"
              onChange={this.onProjectSelect}
              selected={
                this.state.selectedProject.id !== '' ? (
                  <div>{this.state.selectedProject.name}</div>
                ) : (
                  <div>Please Select a project</div>
                )
              }
              enableSearchBox={true}
              searchText={this.state.projectSearchString}
              onSearchValueChange={searchValue => {
                this.setState({ projectSearchString: searchValue });
              }}
            >
              {jiraProjects
                .filter((project: any) =>
                  projectSearchString.length > 0
                    ? project.name.toLowerCase().indexOf(projectSearchString.toLowerCase()) > -1
                    : true,
                )
                .map((project: any, index: number) => (
                  <FocusBlock value={project} key={shortid.generate()}>
                    <p className="item-box-tagline" style={{ margin: 0 }}>
                      {project.name} | ({project.key})
                    </p>
                  </FocusBlock>
                ))}
            </SelectBox>
          </div>
          {this.state.selectedProject.id.length > 0 && projectIssues.length > 0 && (
            <div className="mt-20">
              <p className="item-box-tagline font-bold m-0" style={{ marginBottom: 5 }}>
                Select an Issue for {this.state.selectedProject.name}
              </p>
              <SelectBox
                id="jira_project_issue"
                name="Jira projects issues"
                label="Jira projects issues"
                onChange={this.onIssueSelect}
                selected={
                  this.state.selectedIssue.id !== '' ? (
                    <div>{this.state.selectedIssue.name}</div>
                  ) : (
                    <div>Please Select Project Issue</div>
                  )
                }
                enableSearchBox={true}
                searchText={this.state.issuesSearchString}
                onSearchValueChange={searchValue => {
                  this.setState({ issuesSearchString: searchValue });
                }}
              >
                {projectIssues
                  .filter((issues: any) =>
                    issuesSearchString.length > 0
                      ? issues.name.toLowerCase().indexOf(issuesSearchString.toLowerCase()) > -1
                      : true,
                  )
                  .map((issue: any, index: number) => (
                    <FocusBlock value={issue} key={shortid.generate()}>
                      <p className="item-box-tagline" style={{ margin: 0 }}>
                        {issue.name}
                      </p>
                    </FocusBlock>
                  ))}
              </SelectBox>
            </div>
          )}
          <div className="mt-20">
            <TextButton
              id="to_projects"
              disabled={this.state.selectedIssue.id.length === 0}
              onClick={() => this.setState({ componentTabState: 'map' })}
              text="Next"
            />
          </div>
        </div>
      )}
      {componentTabState === 'map' && (
        <div>
          <div>
            <p className="item-box-tagline font-bold ma-0" style={{ marginBottom: 5 }}>
              Map Jira Issue to Squadcast Status
            </p>
          </div>

          <div>
            {this.state.selectedIssue.id.length === 0 && (
              <div>
                <p>Please select a Jira project and a relevant Issue Map</p>
              </div>
            )}
            {this.state.selectedIssue.id.length !== 0 && this.state.issuesMap.length === 0 && (
              <div>
                <p>Please Add default issue status to your Jira project</p>
              </div>
            )}
            {this.state.issuesMap.map((issue, index) => {
              return (
                <div className="mt-10" key={index}>
                  <Row flexWidth={12} justifyContent="center" alignItems="center">
                    <Row flexWidth={6}>{issue.jira_status}</Row>
                    <Row flexWidth={6}>
                      <div className="w-1-1">
                        <SelectBox
                          id="jira_issue_to_status_map"
                          name="issue Map"
                          label="issue Map"
                          onChange={(_, value) => this.onIssueMapSelect(value, index)}
                          selected={
                            this.state.selectedIssue.id !== '' ? (
                              <div>{issue.system_status}</div>
                            ) : (
                              <div>Please Map status</div>
                            )
                          }
                        >
                          {this.incidentStatus.map((status: any, index: number) => (
                            <FocusBlock value={status} key={shortid.generate()}>
                              <p className="item-box-tagline" style={{ margin: 0 }}>
                                {status}
                              </p>
                            </FocusBlock>
                          ))}
                        </SelectBox>
                      </div>
                    </Row>
                  </Row>
                </div>
              );
            })}
          </div>

          <div className="mt-20">
            <TextButton
              id="to_services"
              disabled={this.state.selectedIssue.id.length === 0}
              onClick={() => this.setState({ componentTabState: 'service' })}
              text="Next"
            />
          </div>
        </div>
      )}
      {componentTabState === 'service' && (
        <div>
          <div>
            <p className="item-box-tagline font-bold ma-0" style={{ marginBottom: 5 }}>
              Add incidents to Jira
            </p>
            <Row alignItems="center">
              <CheckBox
                id="is_jira_manual"
                name="is manual"
                label="is_manual"
                checked={this.state.isManual}
                onChange={() => {
                  this.setState({ isManual: true });
                  this.props.checkAndSetDirty();
                }}
              >
                Manually
              </CheckBox>
              <span className="ml-10" />
              <CheckBox
                id="is_jira_automatic"
                name="is manual"
                label="is_manual"
                checked={!this.state.isManual}
                onChange={() => {
                  this.setState({ isManual: false });
                  this.props.checkAndSetDirty();
                }}
              >
                Automatic
              </CheckBox>
            </Row>
          </div>
          <div className="mt-20">
            <p className="item-box-tagline font-bold ma-0" style={{ marginBottom: 5 }}>
              Add Services to Jira
            </p>

            <div style={{ position: 'relative' }}>
              <input
                id="squad-member"
                type="text"
                autoComplete="off"
                className={'input-design '}
                placeholder="Search Services by Name"
                value={this.state.searchString}
                onChange={event => {
                  this.setState({ searchString: event.target.value });
                }}
                onFocus={() => {
                  this.setState({ showDropDown: true });
                }}
                onBlur={() => {
                  setTimeout(() => {
                    this.setState({ showDropDown: false });
                  }, 300);
                }}
              />

              {this.state.showDropDown && (
                <div className="search-drop-down">
                  {Object.values(this.serviceMap).length && (
                    <div>
                      {Object.values(this.serviceMap)
                        .filter(
                          service =>
                            this.state.selectedServices.findIndex(s => s?.id === service.id) === -1,
                        )
                        .filter(
                          service =>
                            (service?.name || '')
                              .toLowerCase()
                              .indexOf(this.state.searchString.toLowerCase()) > -1,
                        )
                        .map((service, index) => {
                          return (
                            <div
                              onClick={() =>
                                this.addToServiceArray(service?.id || '', service?.name || '')
                              }
                              className="search-drop-down-item-content no-select cursor-pointer"
                              key={index}
                            >
                              <img src="/icons/greys/services.svg" alt="service" />
                              &nbsp;
                              <span>{service?.name || ''}</span>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="mt-20">
              <Row flexWrap="wrap">
                {this.state.selectedServices.map((service, index) => {
                  return (
                    <div className="item-member" style={{ margin: 5 }} key={index}>
                      <img
                        src="/icons/greys/services.svg"
                        className="item-member-icon"
                        alt="service"
                      />
                      <span style={{ verticalAlign: 'top' }}>
                        {service?.name || 'Service Deleted'}
                      </span>
                      <img
                        className="cursor-pointer close-icon"
                        src="/icons/close.svg"
                        alt="delete service"
                        onClick={() => this.removeService(service?.id || '')}
                      />
                    </div>
                  );
                })}
              </Row>
            </div>
          </div>
          <div className="mt-20">
            <Row alignItems="center">
              <TextButton
                id="to_done"
                loading={this.state.loading}
                disabled={
                  this.state.selectedIssue.id.length === 0 ||
                  this.state.selectedServices.length === 0
                }
                onClick={() => this.saveIntegrations()}
                text="Save & Integrate"
              />

              {this.state.updated && <span className="ml-10">Saved!</span>}
              {this.state.errors.save && this.state.errors.save.length > 0 && (
                <span className="ml-10">
                  <ErrorText text={`Failed! ${this.state.errors.save}`} />
                </span>
              )}
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}

export default renders;
