import React, { useState } from 'react';
import { AddRunbookIcon, AddTaskIcon } from '../../../../../../icons';
import { IRunbook } from '../../../../../../core/interfaces/IRunBook';

import { Label, Grid } from 'uie/components';
import { IPatchReq } from '../../../../../../core/interfaces/ITask';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

interface IProps {
  setSelectedRunbooksList: (list: Array<IRunbook>) => void;
  setEnableRunbookModal: (enable: boolean) => void;
  isSuppressedIncident: boolean;
}
interface ITextButtonProps {
  setSelectedTask: (task: IPatchReq | null) => void;
  setEnableTaskModal: (enable: boolean) => void;
  isSuppressedIncident: boolean;
}
const RunbookButtons = ({
  setSelectedRunbooksList,
  setEnableRunbookModal,
  isSuppressedIncident,
}: IProps) => {
  const hasUpdatePermission = useUserAccess().hasUpdateAccess('incidents');
  return (
    <Grid flexWidth={12} justifyContent="space-between" className="mb-20 ml-20 mt-20 mr-25">
      <Label fontWeight={500}>Runbooks </Label>
      <div className="incident_details__runbook_icons">
        {!isSuppressedIncident && (
          <NoPermissionTooltip isDisabled={hasUpdatePermission}>
            <div
              className="incident_details__runbook_icons mr-20"
              onClick={() => {
                if (!hasUpdatePermission) return;
                setSelectedRunbooksList([]);
                setEnableRunbookModal(true);
              }}
              style={
                !hasUpdatePermission
                  ? {
                      opacity: 0.6,
                      cursor: 'not-allowed',
                    }
                  : {}
              }
            >
              <AddRunbookIcon className="mr-5" />
              <Label fontWeight={400} fontSize={15}>
                Attach Runbook
              </Label>
            </div>
          </NoPermissionTooltip>
        )}
      </div>
    </Grid>
  );
};

const TaskButton = ({
  setSelectedTask,
  setEnableTaskModal,
  isSuppressedIncident,
}: ITextButtonProps) => {
  const hasUpdatePermission = useUserAccess().hasUpdateAccess('incidents');
  return (
    <Grid flexWidth={12} justifyContent="space-between" className="mb-20 ml-20 mt-20 mr-25">
      <Label fontWeight={500}>Task </Label>
      <div className="incident_details__runbook_icons">
        {!isSuppressedIncident && (
          <NoPermissionTooltip isDisabled={hasUpdatePermission}>
            <div
              className="incident_details__runbook_icons mr-20"
              onClick={() => {
                if (!hasUpdatePermission) return;
                setSelectedTask(null);
                setEnableTaskModal(true);
              }}
              style={
                !hasUpdatePermission
                  ? {
                      opacity: 0.6,
                      cursor: 'not-allowed',
                    }
                  : {}
              }
            >
              <AddTaskIcon className="mr-5" width={22} height={24} />
              <Label fontWeight={400} fontSize={15}>
                Add Task
              </Label>
            </div>
          </NoPermissionTooltip>
        )}
      </div>
    </Grid>
  );
};

export { TaskButton, RunbookButtons };
