import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { IOption } from '../../Interface';
import { GroupSelect } from '../GroupSelect';

function NestedDropdown({
  componentGroupOption,
  setOption,
  onTrigger,
  disabled,
}: {
  componentGroupOption: any[];
  setOption: (opt: IOption[]) => void;
  onTrigger?: () => void;
  disabled?: boolean;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (
        wrapperRef.current &&
        wrapperRef.current.contains &&
        !wrapperRef.current.contains(event.target)
      ) {
        setIsEditing(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <Box position={'relative'} ref={wrapperRef}>
      <Flex
        _focus={{ outline: 'none' }}
        color="gray.500"
        w={'100%'}
        bgColor={disabled ? '#f2f2f2' : 'white'}
        px={4}
        py={2}
        borderWidth={1}
        borderEndRadius={6}
        borderStartRadius={6}
        borderColor={'gray.300'}
        onClick={() => {
          if (!disabled) {
            setIsEditing(!isEditing);
            if (onTrigger) onTrigger();
          }
        }}
      >
        <Flex flex={1} align="center">
          <Flex flex={1}>
            <Text fontSize={'md'}>Select or add a component group</Text>
          </Flex>
          <ChevronDownIcon />
        </Flex>
      </Flex>
      {isEditing ? (
        <Box
          position={'absolute'}
          zIndex={3}
          bgColor="white"
          w="100%"
          borderWidth={1}
          borderEndRadius={6}
          borderStartRadius={6}
          borderColor={'gray.300'}
          py={4}
        >
          <GroupSelect setOptions={setOption} options={componentGroupOption} />
        </Box>
      ) : null}
    </Box>
  );
}

export default NestedDropdown;
