import React from 'react';
import { Grid, InputBlock, FocusBlock, SelectBox } from 'uie/components';
import { HStack, Text, Box } from '@chakra-ui/react';
import {
  IDedupKey,
  IRuleTimeUnit,
} from 'views/main/organization/service-catalog/Interfaces/automation-rule';

interface Props {
  dedupeKey: IDedupKey;
  isViewMode: boolean;
  onChangeDedupeKeyDuration: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors: string[];
  onChangeDedupeKeyTimeUnit: (_: any, value: any) => void;
}

const Duration = ({
  dedupeKey,
  isViewMode,
  onChangeDedupeKeyDuration,
  errors,
  onChangeDedupeKeyTimeUnit,
}: Props) => {
  return (
    <div className="field-parent-div">
      <Grid>
        <Text fontSize={14} height="24px" fontWeight={500} marginBottom="6px">
          Dedup Time
        </Text>
      </Grid>
      <HStack>
        {!isViewMode && (
          <>
            <InputBlock
              type="number"
              id={`deduplication-time-window`}
              name="deduplication_time_window"
              placeholder="N"
              value={dedupeKey.time_window.toString()}
              onChange={onChangeDedupeKeyDuration}
              height="50px"
              fontSize="12px"
              width="100%"
              error={errors.includes(`dedupeKey.time_window`)}
            />
            <SelectBox
              hook={
                <Text fontSize={12} textAlign="left">
                  {`${dedupeKey.time_unit}(s)`}
                </Text>
              }
              onValueChange={onChangeDedupeKeyTimeUnit}
              height="auto"
              maxHeight="200px"
              width="100%"
            >
              {Object.values(IRuleTimeUnit).map(v => {
                return (
                  <FocusBlock
                    key={v}
                    value={v}
                    isSelected={dedupeKey.time_unit === v}
                    style={{ textAlign: 'center' }}
                  >
                    <Text fontSize={12} textAlign="left">
                      {`${v}(s)`}
                    </Text>
                  </FocusBlock>
                );
              })}
            </SelectBox>
          </>
        )}
        {isViewMode && (
          <Text className="item-box-tagline">
            {!dedupeKey.key
              ? 'Loading...'
              : `${dedupeKey.time_window.toString()} ${dedupeKey.time_unit}${
                  dedupeKey.time_window > 1 ? 's' : ''
                }`}
          </Text>
        )}
      </HStack>
    </div>
  );
};

export default Duration;
