import { Button, Text } from '@chakra-ui/react';
import { Modal } from 'library/molecules';

import { THEME_COLORS } from 'library/theme/colors';
import { FC } from 'react';

type Props = {
  showWarning: boolean;
  setShowWarning: (show: boolean) => void;
  onDisable: () => void;
};

export const DisableConfirmation: FC<Props> = ({ showWarning, setShowWarning, onDisable }) => {
  return (
    <Modal
      title="Disable Delayed Notifications"
      isOpen={showWarning}
      onClose={() => setShowWarning(false)}
      primaryButton={
        <Button onClick={onDisable} variant="default" backgroundColor={THEME_COLORS.brand.red}>
          <Text>Yes, Disable</Text>
        </Button>
      }
      secondaryButton={
        <Button
          onClick={() => {
            setShowWarning(false);
          }}
          variant="outline"
        >
          <Text>Cancel</Text>
        </Button>
      }
      body={<Text color="gray.500">Are you sure you want to disable Delayed Notifications?</Text>}
    />
  );
};
