import * as React from 'react';
import {
  Checkbox,
  ErrorToastBlock,
  FocusBlock,
  Heading,
  Para,
  SelectBox,
  SuccessToastBlock,
  TextButton,
  Theme,
  ToastContext,
} from 'uie/components';
import {
  IExtensionMSTeams,
  IncidentAlertsMappings,
} from '../../../../../../core/interfaces/IExtensions';
import styles from './index.module.css';
import { useFormik } from 'formik';
import { ConnectedMSTeamsTeams, MSTeamsChannelsState } from './Channels';
import { API, ExtensionService } from 'core';
import * as Yup from 'yup';
import MSTeamsMeetingLinkUserDropdown from './MeetingLinkUserDropdown';
import { useQueryClient } from 'react-query';
import { AppConfig } from 'shared/app.config';
const { theme } = Theme;

const channelConfigSchema = Yup.array()
  .of(
    Yup.object().shape({
      msteams_channel_id: Yup.string().required('Channels ID is required'),
      msteams_channel_name: Yup.string().required('Channels Name is required'),
      squadcast_team_id: Yup.string().required('Squadcast Team ID is required'),
      squadcast_team_name: Yup.string().required('Squadcast Team Name is required'),
      services: Yup.array().of(
        Yup.object()
          .shape({
            squadcast_service_id: Yup.string().required('Squadcast Service ID is required'),
            squadcast_service_name: Yup.string().required('Squadcast Service Name is required'),
          })
          .required('Service is required'),
      ),
    }),
  )
  .nullable();

const ValidationSchema = Yup.object().shape({
  connected_teams: Yup.array().of(
    Yup.object().shape({
      team_id: Yup.string().required('Team ID is required'),
      channel_configurations: channelConfigSchema,
    }),
  ),
});

interface IProps {
  config: IExtensionMSTeams | null;
  error?: string;
  onClose: () => void;
  refresh: () => void;
  checkAndSetDirty: () => void;
  checkDirtyAndCloseModal: () => void;
}

const MSTeams: React.FC<IProps> = ({ config, ...props }) => {
  const _createToast = ToastContext();

  const showErrorToast = (message: string) => {
    _createToast(
      <ErrorToastBlock style={{ backgroundColor: theme.danger.light }} maxWidth="400px">
        <Para fontWeight={400}>{message}</Para>
      </ErrorToastBlock>,
    );
  };

  if (props.error) {
    showErrorToast(props.error);
    props.onClose();
  }

  if (!config) {
    return null;
  }

  return <MSTeamsRender config={config} {...props} />;
};
export default MSTeams;

const MSTeamsRender = ({
  config,
  ...props
}: {
  config: IExtensionMSTeams;
  onClose: () => void;
  refresh: () => void;
  checkAndSetDirty: () => void;
  checkDirtyAndCloseModal: () => void;
}) => {
  const [defaultChannelSearchValue, setDefaultChannelSearchValue] = React.useState('');
  const [meetingLinkEnabled, setMeetingLinkEnabled] = React.useState(
    config.from_id.length > 0 ?? false,
  );
  const queryClient = useQueryClient();

  const _createToast = ToastContext();

  const showErrorToast = (message: string) => {
    _createToast(
      <ErrorToastBlock style={{ backgroundColor: theme.danger.light }} maxWidth="400px">
        <Para fontWeight={400}>{message}</Para>
      </ErrorToastBlock>,
    );
  };

  const showSuccessToast = (message: string) => {
    _createToast(
      <SuccessToastBlock style={{ backgroundColor: theme.success.light }} maxWidth="250px">
        <Para fontWeight={400} style={{ whiteSpace: 'pre-wrap', textTransform: 'capitalize' }}>
          {message}
        </Para>
      </SuccessToastBlock>,
      3000,
    );
  };

  const [extensionService] = React.useMemo(() => {
    return [new ExtensionService()];
  }, []);

  const form = useFormik<IExtensionMSTeams>({
    initialValues: config,
    validationSchema: ValidationSchema,
    validateOnChange: false,

    onSubmit: async values => {
      try {
        await extensionService.postMSTeamsConfig(values);
        props.refresh();
        showSuccessToast('Successfully saved Config');
        queryClient.invalidateQueries('msteam-status');
        props.onClose();
      } catch (e: any) {
        console.log('Error', e?.response?.data);
        showErrorToast(e?.response?.data?.meta?.error_message || 'Something went wrong');
      }
    },
  });

  const onClickAddMore = () => {
    const redirectUrl = API.config.integration.msteams.redirects;
    window.open(redirectUrl, '_blank');
  };

  const [allMSTeamChannels, setAllMSTeamChannels] = React.useState<MSTeamsChannelsState>([
    {
      msteams_channel_name: form.values.default_conversation_name,
      msteams_channel_id: form.values.default_conversation_id,
    },
  ]);

  const addToMsTeamsChannels = (
    teams: Array<{
      msteams_channel_id: string;
      msteams_channel_name: string;
    }>,
  ) => {
    setAllMSTeamChannels(prevState => {
      if (prevState) {
        const newState = prevState.concat(teams);
        return newState;
      }
      return teams;
    });
  };

  const updateIncidentAlerts = (name: keyof IncidentAlertsMappings, value: boolean) => {
    const incidentAlerts = form.values.custom_incident_alert_state;
    const newIncidentAlerts = {
      ...incidentAlerts,
      [name]: value,
    };
    form.setFieldValue('custom_incident_alert_state', newIncidentAlerts);
    props.checkAndSetDirty();
  };

  const updateAllIncidentAlerts = (value: boolean) => {
    const incidentAlerts = form.values.triggers;
    const newIncidentAlerts = {
      ...incidentAlerts,
      all_active: value,
    };
    form.setFieldValue('triggers', newIncidentAlerts);
    props.checkAndSetDirty();
  };

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.HeaderWrapper}>
        <Heading height={35} fontSize={24}>
          Microsoft Teams Integration
        </Heading>
        <button onClick={onClickAddMore} className="main-button">
          Configure More Teams
        </button>
      </div>
      <div className={styles.bodyWrapper}>
        <div className={styles.actionWrapper}>
          <input
            type="checkbox"
            className={styles.checkbox}
            onChange={() => {
              props.checkAndSetDirty();
              form.setFieldValue('is_default_active', !form.values.is_default_active);
            }}
            checked={form.values.is_default_active}
          />
          <div className={styles.actionText}>
            <div className={styles.actionTitle}>Default Channel</div>
            <div className={styles.actionSubtitle}>
              When enabled, the channel will receive all notifications except team/service specific{' '}
            </div>

            <div
              className={styles.actionTitle}
              style={{
                marginTop: '16px',
                fontWeight: 'normal',
              }}
            >
              Default Notification Channel
            </div>
            <div>
              <SelectBox
                width="300px"
                height="auto"
                maxHeight="200px"
                hook={<Para fontSize={14}>{form.values.default_conversation_name}</Para>}
                searchHookProps={{
                  placeholder: 'Search MS Teams Channels',
                  value: defaultChannelSearchValue,
                  height: '24px',
                  fontSize: '16px',
                  onChange: e => setDefaultChannelSearchValue(e.target.value),
                }}
                onValueChange={(e: any, value: any) => {
                  form.setFieldValue('default_conversation_name', value.msteams_channel_name);
                  form.setFieldValue('default_conversation_id', value.msteams_channel_id);
                  props.checkAndSetDirty();
                }}
              >
                {allMSTeamChannels.length ? (
                  allMSTeamChannels
                    ?.filter(({ msteams_channel_name }) =>
                      msteams_channel_name
                        ? msteams_channel_name
                            .toLowerCase()
                            .includes(defaultChannelSearchValue.toLowerCase())
                        : true,
                    )
                    ?.map(channel => {
                      return (
                        <FocusBlock
                          key={channel.msteams_channel_id}
                          value={{
                            msteams_channel_id: channel.msteams_channel_id,
                            msteams_channel_name: channel.msteams_channel_name ?? 'General',
                          }}
                        >
                          {channel?.msteams_channel_name ?? 'General'}
                        </FocusBlock>
                      );
                    })
                ) : (
                  <FocusBlock key={-1} value={-1}>
                    Loading...
                  </FocusBlock>
                )}
              </SelectBox>
            </div>
          </div>
        </div>

        <div
          className={styles.actionWrapper}
          style={{
            marginTop: '24px',
          }}
        >
          <Checkbox
            checked={form.values?.triggers?.all_active ?? true}
            onChange={e => {
              updateAllIncidentAlerts(e.target.checked);
            }}
          />
          <div className={styles.actionText}>
            <div className={styles.actionTitle}>Send all incident action alerts </div>
            <div className={styles.actionSubtitle}>
              When enabled, alerts for all the supported action such as acknowledge, resolve,
              reassign will be sent to Microsoft Teams configured channel.{' '}
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                *Enabling this will override the individual alert settings.*
              </span>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: '16px',
            marginBottom: '30px',
            marginLeft: '24px',
          }}
        >
          <div className={styles.actionTitle}>Individual Incident action alert</div>
          <div className={styles.actionSubtitle}>
            Individual incident actions for which you want to send notifications to Microsoft teams.
          </div>
          <div className={styles.configActions}>
            <div>Incident triggered</div>
            <div>
              <Checkbox
                checked={form.values?.custom_incident_alert_state?.is_trigger_active ?? false}
                onChange={e => {
                  updateIncidentAlerts('is_trigger_active', e.target.checked);
                }}
              />
            </div>
          </div>

          <div className={styles.configActions}>
            <div>Incident retriggered </div>
            <div>
              <Checkbox
                onChange={e => {
                  updateIncidentAlerts('is_retrigger_active', e.target.checked);
                }}
                checked={form.values?.custom_incident_alert_state?.is_retrigger_active ?? false}
              />
            </div>
          </div>

          <div className={styles.configActions}>
            <div>Incident acknowledged</div>
            <div>
              <Checkbox
                onChange={e => {
                  updateIncidentAlerts('is_acknowledge_active', e.target.checked);
                }}
                checked={form.values?.custom_incident_alert_state?.is_acknowledge_active ?? false}
              />
            </div>
          </div>
          <div className={styles.configActions}>
            <div>Incident resolved</div>
            <div>
              <Checkbox
                onChange={e => {
                  updateIncidentAlerts('is_resolve_active', e.target.checked);
                }}
                checked={form.values?.custom_incident_alert_state?.is_resolve_active ?? false}
              />
            </div>
          </div>
          <div className={styles.configActions}>
            <div>Incident reassigned</div>
            <div>
              <Checkbox
                onChange={e => {
                  updateIncidentAlerts('is_reassign_active', e.target.checked);
                }}
                checked={form.values?.custom_incident_alert_state?.is_reassign_active || false}
              />
            </div>
          </div>
          <div className={styles.configActions}>
            <div>Notes added</div>
            <div>
              <Checkbox
                onChange={e => {
                  updateIncidentAlerts('is_notes_added_active', e.target.checked);
                }}
                checked={form.values?.custom_incident_alert_state?.is_notes_added_active || false}
              />
            </div>
          </div>
          <div className={styles.configActions}>
            <div>Postmortem started</div>
            <div>
              <Checkbox
                onChange={e => {
                  updateIncidentAlerts('is_postmortem_active', e.target.checked);
                }}
                checked={form.values?.custom_incident_alert_state?.is_postmortem_active || false}
              />
            </div>
          </div>
          <div className={styles.configActions}>
            <div>Priority updated</div>
            <div>
              <Checkbox
                onChange={e => {
                  updateIncidentAlerts('is_priority_updated_active', e.target.checked);
                }}
                checked={
                  form.values?.custom_incident_alert_state?.is_priority_updated_active || false
                }
              />
            </div>
          </div>
        </div>

        <div
          className={styles.actionWrapper}
          style={{
            marginTop: '24px',
          }}
        >
          <input
            type="checkbox"
            className={styles.checkbox}
            onChange={() => {
              form.setFieldValue(
                'is_custom_channels_active',
                !form.values.is_custom_channels_active,
              );
              props.checkAndSetDirty();
            }}
            checked={form.values.is_custom_channels_active}
          />
          <div className={styles.actionText}>
            <div className={styles.actionTitle}>Team/Service specific channel</div>
            <div className={styles.actionSubtitle}>
              When configured, it will override the default notification channel for that
              team/service.
            </div>
          </div>
        </div>
        <div className={styles.channelConfigurations}>
          {form.values.connected_teams.map((team, index) => (
            <ConnectedMSTeamsTeams
              connectedTeamsError={
                (form.errors?.connected_teams?.[index] as any)?.channel_configurations
              }
              key={index}
              onChange={(value: any) => {
                const newValue = [
                  ...form.values.connected_teams.slice(0, index),
                  value,
                  ...form.values.connected_teams.slice(index + 1),
                ];
                form.setFieldValue('connected_teams', newValue);
                props.checkAndSetDirty();
              }}
              teamConfig={team}
              addToMsTeamsChannels={addToMsTeamsChannels}
              allMSTeamsChannels={allMSTeamChannels}
            />
          ))}
        </div>
        <div
          className={styles.actionWrapper}
          style={{
            marginTop: '24px',
          }}
        >
          <input
            type="checkbox"
            className={styles.checkbox}
            checked={form.values?.from_id.length > 0 ?? false}
            onChange={e => {
              if (e.target.checked) {
                setMeetingLinkEnabled(() => true);
              } else {
                setMeetingLinkEnabled(() => false);
                form.setFieldValue('from_id', '');
              }
              props.checkAndSetDirty();
            }}
          />
          <div className={styles.actionText}>
            <div className={styles.actionTitle}>Meeting Link User</div>
            <div className={styles.actionSubtitle}>
              When enabled, Microsoft Teams meetings will be added to this user's calendar.
            </div>
          </div>
        </div>
        {meetingLinkEnabled && (
          <div style={{ marginLeft: '24px', marginTop: '8px' }}>
            <MSTeamsMeetingLinkUserDropdown
              from_id={form.values.from_id}
              checkAndSetDirty={props.checkAndSetDirty}
              setFieldValue={form.setFieldValue}
            />
            <div className={styles.actionSubtitle} style={{ marginTop: '16px' }}>
              In order to create Microsoft Teams meeting links, please provide additional
              permissions below:
            </div>
            <a href={AppConfig.ms_teams_bot_reauthorization_url}>
              <button className="small-button-inverse" style={{ marginTop: '8px' }} type="button">
                Grant admin consent
              </button>
            </a>
          </div>
        )}
      </div>

      <div className={styles.footerWrappper}>
        <button
          className="main-button-inverse"
          style={{
            marginRight: '10px',
          }}
          onClick={props.checkDirtyAndCloseModal}
        >
          Cancel
        </button>
        <button
          className="main-button"
          onClick={() => {
            form.submitForm();
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};
