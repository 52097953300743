import { FC } from 'react';
import { Text } from '@chakra-ui/react';

type Props = {
  title: string;
};

export const Heading: FC<Props> = ({ title }) => {
  return (
    <Text color="secondary.700" variant="formInput_800" textTransform="uppercase">
      {title}
    </Text>
  );
};
