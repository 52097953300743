import {
  ISignUploadResponse,
  ISignUploadRequest,
  IAttachmentRequest,
} from 'core/interfaces/ISignUpload';
import { exception } from '../exception';
import { API } from '../api';
import Axios, { AxiosRequestConfig } from 'axios';
import mime from 'mime';

export enum OperationType {
  CREATE = 'create',
  UPDATE = 'update',
}

export enum FileUploadFeature {
  INCIDENT = 'incident',
  NOTES = 'notes',
  STATUS_PAGE = 'status-page',
  POSTMORTEM_TEMPLATE = 'postmortem-template',
  POSTMORTEM = 'postmortem',
  EMAIL = 'email',
}

interface IFileUploadInfo {
  operationType: OperationType;
  feature: FileUploadFeature;
  entityId: string;
  file: File;
}

class FileUploadService {
  private attachmentRequestArrayForIncident: IAttachmentRequest[] = [];
  private attachmentRequestArrayForNotes: IAttachmentRequest[] = [];
  private attachmentRequestArrayForStatusPage: IAttachmentRequest[] = [];
  private attachmentRequestArrayForPostmortem: IAttachmentRequest[] = [];
  private attachmentRequestArrayForPostmortemTemplates: IAttachmentRequest[] = [];

  private static inlineMimeTypes = ['application/pdf', 'image/png', 'image/jpeg'];

  private getSignedUrl = (
    url: string,
    signUploadRequest: ISignUploadRequest,
    config: AxiosRequestConfig,
  ) => Axios.post<{ data: ISignUploadResponse }>(url, signUploadRequest, config);

  private uploadFile = (url: string, file: File, config: AxiosRequestConfig) =>
    Axios.put<void>(url, file, config);

  public accessAttachmentArrayForIncident() {
    return this.attachmentRequestArrayForIncident;
  }

  public accessAttachmentArrayForNotes() {
    return this.attachmentRequestArrayForNotes;
  }

  public accessAttachmentArrayForStatusPage() {
    return this.attachmentRequestArrayForStatusPage;
  }

  public accessAttachmentArrayForPostmortem() {
    return this.attachmentRequestArrayForPostmortem;
  }

  public accessAttachmentArrayForPostmortemTemplates() {
    return this.attachmentRequestArrayForPostmortemTemplates;
  }

  public emptyAttachmentArrayForIncident() {
    this.attachmentRequestArrayForIncident = [];
  }

  public emptyAttachmentArrayForNotes() {
    this.attachmentRequestArrayForNotes = [];
  }

  public emptyAttachmentArrayForStatusPage() {
    this.attachmentRequestArrayForStatusPage = [];
  }

  public emptyAttachmentArrayForPostmortem() {
    this.attachmentRequestArrayForPostmortem = [];
  }

  public emptyAttachmentArrayForPostmortemTemplates() {
    this.attachmentRequestArrayForPostmortemTemplates = [];
  }

  public getUploadFunctionForFeature(
    feature: FileUploadFeature,
    operationType: OperationType,
    entityId: string | undefined = '',
  ) {
    const fileUploadFunction = async (
      file: File,
      onSuccess: (url: string, fileName: string) => void,
      onError: (error: string) => void,
    ) => {
      if (this.theOperationCantBePerformed(operationType, entityId)) {
        onError('A valid entity is required for uploading files');
        return;
      }
      const onFetchSignedUrlError = (err: any) => {
        onError(`Error uploading file: ${file.name}` + '\n' + exception.getErrorMsg(err));
      };

      const onFetchSignedUrlComplete = async (signedUrlResponse: ISignUploadResponse) => {
        await this.uploadFileUsingSignedUrl(file, signedUrlResponse, onSuccess, onError, feature);
      };

      await this.getSignedUploadUrl(
        { operationType, entityId, feature, file },
        onFetchSignedUrlComplete,
        onFetchSignedUrlError,
      );
    };
    return fileUploadFunction;
  }

  private getSignedUploadUrl = async (
    fileUploadInfo: IFileUploadInfo,
    onSuccess: (signedUrlResponse: ISignUploadResponse) => Promise<void>,
    onError: (errorMsg: string) => void,
  ) => {
    const fileName = fileUploadInfo.file.name;
    const mime_type = mime.getType(fileName) || 'application/octet-stream';
    const encodedFileName = encodeURIComponent(fileName);

    const signUploadRequest = {
      feature: fileUploadInfo.feature,
      file_name: encodedFileName,
      content_length: fileUploadInfo.file.size,
      mime_type: mime_type,
      content_disposition: `${
        FileUploadService.inlineMimeTypes.includes(mime_type) ? 'inline' : 'attachment'
      };filename=${encodedFileName}`,
    };

    const signEndpoint = SignEndPointBuilder.buildSignEndPoint(
      fileUploadInfo.feature,
      fileUploadInfo.operationType,
      fileUploadInfo.entityId,
    );

    let signedUrlResponse: ISignUploadResponse;
    try {
      signedUrlResponse = await this.fetchSignedUrl(signEndpoint, signUploadRequest);
    } catch (err: any) {
      exception.handle('E_FETCH_SIGNED_URL_ERROR', err);
      onError(err);
      return;
    }
    await onSuccess(signedUrlResponse);
  };

  private fetchSignedUrl = async (signEndpoint: string, signUploadRequest: ISignUploadRequest) => {
    const {
      data: { data },
    } = await this.getSignedUrl(signEndpoint, signUploadRequest, {
      withCredentials: true,
    });
    return data;
  };

  private uploadFileUsingSignedUrl = async (
    file: File,
    signUploadResponse: ISignUploadResponse,
    onSuccess: (url: string, fileName: string) => void,
    onError: (error: string) => void,
    feature: FileUploadFeature,
  ) => {
    try {
      const fileUploadResponse = await this.uploadFile(signUploadResponse.signed_url, file, {
        headers: {
          ...signUploadResponse.meta.required_headers,
          'Access-Control-Allow-Origin': '*',
        },
      });

      if (fileUploadResponse.status === 200) {
        onSuccess(signUploadResponse.file_path, file.name);
        const attachment = {
          key: new URL(signUploadResponse.file_path).pathname,
          file_size: file.size,
          mime_type: file.type,
        };
        switch (feature) {
          case FileUploadFeature.INCIDENT:
            this.attachmentRequestArrayForIncident.push(attachment);
            break;
          case FileUploadFeature.NOTES:
            this.attachmentRequestArrayForNotes.push(attachment);
            break;
          case FileUploadFeature.POSTMORTEM:
            this.attachmentRequestArrayForPostmortem.push(attachment);
            break;
          case FileUploadFeature.POSTMORTEM_TEMPLATE:
            this.attachmentRequestArrayForPostmortemTemplates.push(attachment);
            break;
          case FileUploadFeature.STATUS_PAGE:
            this.attachmentRequestArrayForStatusPage.push(attachment);
        }
      } else {
        onError('error uploading file: ' + fileUploadResponse.statusText);
      }
    } catch (err: any) {
      onError('error uploading file: ' + file.name + ',' + err);
    }
  };

  private theOperationCantBePerformed(operationType: OperationType, entityId: string) {
    return operationType === OperationType.UPDATE && (entityId === undefined || entityId == '');
  }
}
class SignEndPointBuilder {
  public static buildSignEndPoint(
    feature: FileUploadFeature,
    operationType: OperationType,
    entityId = '',
  ) {
    const baseSignEndPointURI = `${API.config.batman}/organizations/${API.config.organizationId}/sign-upload?&entity-type=${feature}&team-id=${API.config.teamId}&operation-type=${operationType}`;
    return operationType === OperationType.UPDATE
      ? this.appendQueryParam(baseSignEndPointURI, this.buildQueryParam('entity-id', entityId))
      : baseSignEndPointURI;
  }

  private static buildQueryParam(queryParamName: string, value: string) {
    return `${queryParamName}=${value}`;
  }

  private static appendQueryParam(url: string, queryParam: string) {
    return url.concat('&', queryParam);
  }
}
export default FileUploadService;
