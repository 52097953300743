import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';
import { APP_STATE_SET_CURRENT_INCIDENT, APP_STATE_SET_FILTER } from '../../const/appConfigs';

const initialAppState = INITIAL_STATE.APP_STATE;

const appState = (state = initialAppState, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{
          incidentsFilterLength: 7,
          openIncident: {
            updateTime: '',
            updateMethod: '',
            incident: null,
          },
        },
      };
    case APP_STATE_SET_FILTER:
      return {
        ...state,
        ...{ incidentsFilterLength: action.payload.filter },
      };
    case APP_STATE_SET_CURRENT_INCIDENT:
      return {
        ...state,
        ...{
          openIncident: {
            updateTime: new Date().getTime().toString(),
            updateMethod: action.payload.updateMethod,
            incident: action.payload.incident,
          },
        },
      };
    default:
      return state;
  }
};

export default appState;
