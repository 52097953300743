import { ColumnDef } from '@tanstack/table-core';
import { Text } from 'library/atoms/Text';
import { useMemo } from 'react';
import { LCRDataLogsResponse } from 'views/main/organization/lcr/common/interface';
type SessionData = LCRDataLogsResponse['data'][number]['call_relations'][number];
export const useLCRRelationTableAction = () => {
  const columns = useMemo<ColumnDef<SessionData, any>[]>(
    () => [
      {
        header: 'FROM',
        cell: ({ row }) => <Text>{row.original.from} </Text>,
      },
      {
        header: 'TO',
        cell: ({ row }) => <Text>{row.original.to} </Text>,
      },

      {
        header: 'DURATION',
        cell: ({ row }) => <Text>{row.original.duration_in_seconds} Sec</Text>,
      },
      {
        header: 'TYPE',
        cell: ({ row }) => <Text textTransform="capitalize">{row.original.direction}</Text>,
      },
      {
        header: 'STATUS',
        cell: ({ row }) => <Text textTransform="capitalize">{row.original.status}</Text>,
      },
    ],
    [],
  );

  return columns;
};
