import React from 'react';
import { IComponentState } from 'core/interfaces/IComponentState';
import AccordionViewMode from './AccordionViewMode';
import AccordionActionMode from './AccordionActionMode';
import { OnMount } from '@monaco-editor/react';

interface EditorProps {
  isViewMode: boolean;
  incidentMessage: string;
  incidentDesc: string;
  componentState: IComponentState;
  updateMessageCode: () => void;
  updateDesc: () => void;
  handleEditorDidMount: OnMount;
}

const EditorField: React.FC<EditorProps> = ({
  isViewMode,
  incidentMessage,
  incidentDesc,
  componentState,
  updateMessageCode,
  updateDesc,
  handleEditorDidMount,
}) => {
  return (
    <>
      {isViewMode ? (
        <AccordionViewMode
          incidentMessage={incidentMessage}
          incidentDesc={incidentDesc}
          handleEditorDidMount={handleEditorDidMount}
        />
      ) : (
        <AccordionActionMode
          incidentMessage={incidentMessage}
          incidentDesc={incidentDesc}
          componentState={componentState}
          updateMessageCode={updateMessageCode}
          updateDesc={updateDesc}
          handleEditorDidMount={handleEditorDidMount}
        />
      )}
    </>
  );
};

export default EditorField;
