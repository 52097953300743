import { useToast } from 'library/atoms';
import { useQuery } from 'react-query';
import { getWorkFlowByIdApi } from '../../api';
import { QUERY_KEYS } from '../../constant';

export function useWorkflowDetails(workFlowId: string) {
  const toast = useToast();

  const query = useQuery([QUERY_KEYS.WORKFLOW, workFlowId], () => getWorkFlowByIdApi(workFlowId), {
    retry: 1,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onError: () => {
      toast({ text: 'Fetch Workflow failed', status: 'error' });
    },
  });
  return query;
}
