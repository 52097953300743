import React, { createContext, ReactNode, useContext } from 'react';
import { useSubscribeUser, useWatchData } from '../renders/watcher/FetchApi';

export type IncidentWatcherContextProps = {
  createWatcher: ReturnType<typeof useSubscribeUser>;
  watcherDetail: ReturnType<typeof useWatchData>;
};

interface Props {
  incidentID: string;
  children: ReactNode;
}

export const IncidentWatcherContext = createContext<IncidentWatcherContextProps | undefined>(
  undefined,
);

export const IncidentWatcherProvider = ({ incidentID, children }: Props) => {
  const value = {
    createWatcher: useSubscribeUser(),
    watcherDetail: useWatchData({ incidentID }),
  };

  return (
    <IncidentWatcherContext.Provider value={value}>{children}</IncidentWatcherContext.Provider>
  );
};
