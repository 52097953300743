import React from 'react';
import { connect } from 'react-redux';
import { Grid, Heading, Para, TextButton, Theme } from 'uie/components';
import Select from 'react-select';
import { IAppError, IAppState } from 'core/interfaces/IAppState';
import { IOrgUsersReduxAction, IUsersOfOrganization } from 'core/interfaces/IUserData';
import { RESET_STATE } from 'core/const/init';
import './index.css';
interface ReassignUserConfirmation {
  action: IOrgUsersReduxAction | typeof RESET_STATE;
  u: IUsersOfOrganization[];
  error: IAppError;
}
function ReassignUserConfirmation({
  organization,
  dispatch,
  Close,
}: {
  organization: ReassignUserConfirmation;
  dispatch: any;
  Close: () => void | ((value: React.SetStateAction<boolean>) => void);
}) {
  const users = organization.u;
  const Options = users.map((el: IUsersOfOrganization, _: number) => {
    return {
      label: el.first_name + ' ' + el.last_name,
      value: el.id,
    };
  });
  return (
    <Grid width={'100%'} className="reassign_modal__container">
      <Grid className="reassign_modal__container">
        <Heading fontSize={16}>Select A User To Reassign</Heading>
      </Grid>
      <Grid width={'100%'}>
        <Select
          options={Options}
          placeholder="Select a user"
          styles={{
            container: style => ({
              ...style,
              width: '100%',
            }),
            option: style => ({
              ...style,
              cursor: 'pointer',
            }),
            input: style => ({
              ...style,
              cursor: 'pointer',
            }),
          }}
        />
      </Grid>
      <Grid style={{ marginTop: 20, marginBottom: 10 }}>
        <TextButton>
          <Para fontSize={16} color={Theme.theme.shades.white}>
            Confirm & Reassign
          </Para>
        </TextButton>
        &nbsp;&nbsp;
        <TextButton buttonType="inverted" onClick={Close}>
          <Para fontSize={16} color={Theme.theme.primary.default}>
            Cancel
          </Para>
        </TextButton>
      </Grid>
    </Grid>
  );
}

export default connect((state: IAppState) => ({
  organization: state.organization.users,
}))(ReassignUserConfirmation);
