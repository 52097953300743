/*EasyMDE interface doen't provide interface for options and typescript doesn't allow to access
the prototype of function(i.e uploadImagesUsingCustomFunction) so we have to extend the interface
*/
interface EasyMDEProtoExtend extends EasyMDE {
  options?: EasyMDE.Options;
  uploadImagesUsingCustomFunction?: (
    imageUploadFunction: (
      file: File,
      onSuccess: (url: string, fileName: string) => void,
      onError: (error: string) => void,
    ) => void,
    files: FileList,
  ) => void;
}
interface IToolbarOptions {
  name: string;
  className: string;
  title: string;
  action: (editor: EasyMDE) => void;
}
interface IToolbar {
  [key: string]: HTMLElement;
}

// Handles custom heading(h4 to h6 directly) and normal text
const actionCustomHeading = (editor: EasyMDE, size: number, clear = false) => {
  const cm = editor.codemirror;
  const wrapperChildren = cm.getWrapperElement().children;
  if (
    /editor-preview-active/.test(wrapperChildren.item(wrapperChildren.length - 1)?.className || '')
  ) {
    return;
  }
  const startPoint = cm.getCursor('start');
  const endPoint = cm.getCursor('end');
  for (let i = startPoint.line; i <= endPoint.line; i += 1) {
    ((j: number) => {
      let text = cm.getLine(j);
      const currHeadingLevel = text.search(/[^#]/);
      if (currHeadingLevel === size || clear) {
        if (currHeadingLevel > 0) text = text.slice(currHeadingLevel + 1);
      } else {
        text = `${'#'.repeat(size)} ${text.slice(currHeadingLevel + 1)}`;
      }
      cm.replaceRange(
        text,
        {
          line: j,
          ch: 0,
        },
        {
          line: j,
          ch: 99999999999999,
        },
      );
    })(i);
  }
  cm.focus();
};

// when a file has been choosen to upload this function handles the rest
const customAssetUploadAction = (editor: EasyMDEProtoExtend, type: 'file' | 'image') => {
  const fileInput = document
    .getElementById(editor.options?.element?.id + '-wrapper' ?? '')
    ?.getElementsByClassName(type + 'Input');
  if (!fileInput) return;
  const fileInputHtml = fileInput[0] as HTMLInputElement;

  fileInputHtml.click();

  function onChange(event: any) {
    if (editor?.options?.imageUploadFunction && editor.uploadImagesUsingCustomFunction) {
      editor.uploadImagesUsingCustomFunction(
        editor.options.imageUploadFunction,
        event.target.files,
      );
    }
    fileInputHtml.removeEventListener('change', onChange);
  }

  fileInputHtml.value = '';
  fileInputHtml.addEventListener('change', onChange);
};

// inorder to allow choosing any file type we create a hidden input element
const createFileUploadIcon = (uploadFileButton: HTMLElement) => {
  const fileInput = document.createElement('input');
  fileInput.className = 'fileInput';
  fileInput.type = 'file';
  fileInput.multiple = true;
  fileInput.name = 'file';
  fileInput.style.display = 'none';
  fileInput.style.opacity = '0';
  uploadFileButton.after(fileInput);
};

// inorder to dynamically show which text style has been selected from dropdown
const dynamicDropdownHandler = (mde: any, giveParentToolbar: () => any) => {
  const dropdownOptions = mde?.options.toolbar
    .filter((el: HTMLElement, _: number) => el.title === 'Text Formating')[0]
    .children.map((el: IToolbarOptions, _: number) => el.name.toLowerCase());
  const dropdownIcon = giveParentToolbar().querySelector('i.fa-normal-text');
  const dropdownChildrens = giveParentToolbar().querySelector(
    'div.easymde-dropdown-content',
  ).children;
  const toolbarData: IToolbar = {};
  dropdownOptions.forEach(
    (el: string, index: number) => (toolbarData[el] = dropdownChildrens[index]),
  );
  mde?.codemirror.on('cursorActivity', (editor: CodeMirror.Editor) => {
    const stat = mde?.getState(editor);
    Object.keys(toolbarData).forEach((idendifier, _) => {
      const el = toolbarData[idendifier];
      if (stat[idendifier]) {
        el.className += ' active';
        dropdownIcon.classList.add(`${idendifier}-change`);
        dropdownIcon.classList.remove('fa-normal-text');
      } else if (el.className.split(' ').includes('active')) {
        el.className = el.className.replace(/\s*active\s*/g, '');
        dropdownIcon.classList.remove(`${idendifier}-change`);
        dropdownIcon.classList.add('fa-normal-text');
      }
    });
  });
};

export {
  actionCustomHeading,
  customAssetUploadAction,
  createFileUploadIcon,
  dynamicDropdownHandler,
};
