import React from 'react';
import { Flex, VStack } from '@chakra-ui/layout';
import WorkflowActions from './WorkflowActions';
import { WorkflowConditions } from './WorkFlowConditions/';
import WorkflowIndex from './WorkflowIndex';
import WorkflowTrigger from './WorkflowTrigger';
import { Workflow } from '../../types/response/workflow';

type WorkflowDetailsTabProps = Pick<Workflow, 'trigger' | 'actions' | 'filters'>;
const WorkflowDetailsTab: React.FC<WorkflowDetailsTabProps> = props => {
  return (
    <Flex flex={1} mt="4">
      <VStack flex={1} align="stretch">
        <WorkflowIndex trigger={props.trigger} filters={props.filters} actions={props.actions} />
      </VStack>
      <VStack flex={3} align="stretch" bg="primary.100" py={4}>
        <WorkflowTrigger trigger={props.trigger} />s
        <WorkflowConditions filters={props.filters} />
        <WorkflowActions actions={props.actions} />
      </VStack>
    </Flex>
  );
};

export { WorkflowDetailsTab };
