import { Flex, Text } from '@chakra-ui/layout';
import { FileUploadService } from 'core';
import { FileUploadFeature, OperationType } from 'core/services/service.fileUpload';
import { useCreateEntityMap, useGetOnCallUserSet } from 'library/atoms/MdBlock/helper';
import MdBlock from 'library/atoms/MdBlock/MdBlock';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { IncidentNoteAction } from 'views/main/organization/workflow/types';

const IncidentNoteForm = () => {
  const { setIsDirty, onActionSubmit } = useWorkflowFormContext();
  const { formState, getValues, setValue, handleSubmit } = useFormContext<IncidentNoteAction>();
  const entities = useCreateEntityMap();

  const fileUploadService = new FileUploadService();

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <Flex px={6} pt={4} flexDirection="column">
      <MdBlock
        entities={entities}
        sendMessage={content => {
          setValue('data.note', content, { shouldDirty: true });
          handleSubmit(onActionSubmit)();
        }}
        onMessageChange={content => {
          setValue('data.note', content, { shouldDirty: true });
        }}
        initialMessage={getValues('data.note')}
        onCallUsers={useGetOnCallUserSet()}
        fileUploadService={fileUploadService}
        position="bottom"
        hideSendButton={true}
        featureName={FileUploadFeature.NOTES}
        operationType={OperationType.CREATE}
      />
      {formState.errors.data?.note?.message && (
        <Text color="brand.red">{formState.errors.data?.note?.message}</Text>
      )}
    </Flex>
  );
};

export default IncidentNoteForm;
