import React, { useState } from 'react';
import {
  Box,
  VStack,
  HStack,
  Button,
  Switch,
  FormControl,
  FormLabel,
  Divider,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
} from '@chakra-ui/react';
import Select from 'components/chakra/Select';

import { SchedulesFilters } from 'views/main/organization/schedules/graphql/types';
import { filterSchedules, maxFilterValues } from '../../constants/schedulers.filter';
import { FILTER_TYPES } from '../../constants/schedulers.filter';
import { IfilterObject, IfilterOption } from '../../interface/schedule';
import SQTooltip from 'components/chakra/Tooltip';
import {
  DropdownOption,
  Option,
  Owner,
} from 'views/main/organization/owner-filters/hooks/useEntityOwnerFilter';
import { SelectedFilterTag } from 'views/main/organization/owner-filters/SelectedFilterTags';
import EntityOwnerDropdown, { avatarOptionLabel } from './entityOwnerDropdown';
import { usernameTooltipLabel } from 'library/atoms';

type OwnerFilterProps = {
  ownerOptions: Array<DropdownOption>;
  selectedFilterTags: SelectedFilterTag[];
  ownerDropdownChangeHandler: (values: Option) => void;
  handleSelectedFilterTagClick: (type: Owner, id: string) => void;
  ownerFilterSelected: boolean;
  onClearFilters: () => void;
};

interface Props {
  activeFilters: Omit<SchedulesFilters, 'teamID'>;
  onSubmit: (obj: IfilterObject) => void;
  onCancel: () => void;
  allScheduleOptions: Array<IfilterOption>;
  allFilterParticipants: Array<IfilterOption>;
  allFilterEscalationPolicies: Array<IfilterOption>;
  ownerFilterProps: OwnerFilterProps;
}

const FilterSchedules = ({
  activeFilters,
  onSubmit,
  onCancel,
  allScheduleOptions,
  allFilterEscalationPolicies,
  allFilterParticipants,
  ownerFilterProps: {
    ownerOptions,
    onClearFilters,
    selectedFilterTags,
    ownerDropdownChangeHandler,
    handleSelectedFilterTagClick,
    ownerFilterSelected,
  },
}: Props) => {
  const [participants, setParticipants] = useState<Array<IfilterOption>>(
    allFilterParticipants.filter(
      participant => activeFilters.participants?.indexOf(participant.value) !== -1,
    ),
  );
  const [escalationPolicies, setEscalationPolicies] = useState<Array<IfilterOption>>(
    allFilterEscalationPolicies.filter(
      escalationPolicy => activeFilters.escalationPolicies?.indexOf(escalationPolicy.value) !== -1,
    ),
  );
  const [schedules, setSchedules] = useState<Array<IfilterOption>>(
    allScheduleOptions.filter(
      schedule => activeFilters.scheduleIDs?.indexOf(schedule.value) !== -1,
    ),
  );
  const [myOnCall, setMyOnCall] = useState<boolean>(
    activeFilters.myOncall ? activeFilters.myOncall : false,
  );
  const [noEscalationPolicies, setNoEscalationPolicies] = useState<boolean>(
    activeFilters.noEscalationPolicies ? activeFilters.noEscalationPolicies : false,
  );

  const participantsOptions = allFilterParticipants
    ? allFilterParticipants.filter(participant => !participants.find(p => p.id === participant.id))
    : [];
  const escalationPoliciesOptions = allFilterEscalationPolicies
    ? allFilterEscalationPolicies.filter(
        escalationPolicy => !escalationPolicies.find(ep => ep.id === escalationPolicy.id),
      )
    : [];
  const schedulesOptions = allScheduleOptions
    ? allScheduleOptions.filter(schedule => !schedules.find(sch => sch.id === schedule.id))
    : [];

  const removeFilterValue = (array: Array<IfilterOption>, option: IfilterOption) => {
    if (array === participants) {
      const filteredArray = participants.filter(participant => participant.value !== option.value);
      setParticipants([...filteredArray]);
    } else if (array === escalationPolicies) {
      const filteredArray = escalationPolicies.filter(
        escalationPolicy => escalationPolicy.value !== option.value,
      );
      setEscalationPolicies([...filteredArray]);
    } else if (array === schedules) {
      const filteredArray = schedules.filter(schedule => schedule.value !== option.value);
      setSchedules([...filteredArray]);
    }
  };

  const mapFilterToTags = (array: Array<IfilterOption>) => {
    return (
      <HStack flexWrap="wrap" rowGap={2}>
        {array.map((option: IfilterOption, index) => {
          const { label, username } = option;
          const labelText = `${label}${username ? ` (${username})` : ''}`;
          return (
            <Tag key={index} size="lg">
              <SQTooltip text={usernameTooltipLabel(label, username)}>
                <TagLabel fontWeight="normal" maxW={150} isTruncated>
                  {labelText}
                </TagLabel>
              </SQTooltip>
              <TagCloseButton onClick={() => removeFilterValue(array, option)} />
            </Tag>
          );
        })}
      </HStack>
    );
  };

  return (
    <>
      <Box height="92%" overflow="scroll">
        <Divider w="100%" borderWidth={0.5} borderColor="gray.160" />
        <Box>
          <FormControl>
            <EntityOwnerDropdown
              {...{
                ownerOptions,
                selectedFilterTags,
                ownerDropdownChangeHandler,
                handleSelectedFilterTagClick,
              }}
            />
          </FormControl>
          <Divider w="100%" borderWidth={0.5} borderColor="gray.160" />
          <VStack px={5} py={3} spacing={10} alignItems="flex-start">
            <FormControl>
              <HStack mt={4} verticalAlign="middle">
                <FormLabel htmlFor="filter-my-oncall" alignContent="center" mb={0}>
                  {filterSchedules.labels.myOnCall}
                </FormLabel>
                <Switch
                  id="filter-my-oncall"
                  isChecked={myOnCall}
                  onChange={() => {
                    setMyOnCall(!myOnCall);
                    setParticipants([]);
                  }}
                />
              </HStack>
            </FormControl>
            <FormControl>
              <VStack alignItems="flex-start" w="100%">
                <HStack spacing={0}>
                  <FormLabel mb={0} mr={1}>
                    {filterSchedules.labels.participants}
                  </FormLabel>
                  <Text fontSize="xs">{filterSchedules.labels.limitOnValue}</Text>
                </HStack>
                <Select
                  placeholder={filterSchedules.placeholders.participants}
                  value={[]}
                  isDisabled={myOnCall || participants.length === maxFilterValues}
                  options={participantsOptions}
                  name="participants"
                  onChange={(values: any) => {
                    setParticipants([...participants, ...values]);
                  }}
                  formatOptionLabel={avatarOptionLabel as (data: unknown) => React.ReactNode}
                  isMulti
                  closeMenuOnSelect
                  hideDropdownArrow
                  hideSelectedValues
                />
                {participants && mapFilterToTags(participants)}
              </VStack>
            </FormControl>
          </VStack>
        </Box>
        <Divider w="100%" borderWidth={0.5} borderColor="gray.160" />
        <Box>
          <VStack px={5} py={3} spacing={10} mt={4} alignItems="flex-start">
            <FormControl>
              <HStack verticalAlign="middle">
                <FormLabel htmlFor="filter-escalation-policy" alignContent="center" mb={0}>
                  {filterSchedules.labels.withoutEscalationPolicy}
                </FormLabel>
                <Switch
                  id="filter-escalation-policy"
                  isChecked={noEscalationPolicies}
                  onChange={() => {
                    setNoEscalationPolicies(!noEscalationPolicies);
                    setEscalationPolicies([]);
                  }}
                />
              </HStack>
            </FormControl>
            <FormControl>
              <VStack alignItems="flex-start" w="100%">
                <HStack spacing={0}>
                  <FormLabel mb={0} mr={1}>
                    {filterSchedules.labels.escalationPolicy}
                  </FormLabel>
                  <Text fontSize="xs">{filterSchedules.labels.limitOnValue}</Text>
                </HStack>
                <Select
                  placeholder={filterSchedules.placeholders.escalationPolicy}
                  value={[]}
                  isDisabled={noEscalationPolicies || escalationPolicies.length === maxFilterValues}
                  options={escalationPoliciesOptions}
                  name="escalation-policy"
                  onChange={(values: any) => {
                    setEscalationPolicies([...escalationPolicies, ...values]);
                  }}
                  isMulti
                  closeMenuOnSelect
                  hideDropdownArrow
                  hideSelectedValues
                />
                {escalationPolicies && mapFilterToTags(escalationPolicies)}
              </VStack>
            </FormControl>
          </VStack>
        </Box>
        <Divider w="100%" borderWidth={0.5} borderColor="gray.160" />
        <Box>
          <VStack px={5} py={3} spacing={10} mt={4} alignItems="flex-start">
            <FormControl>
              <VStack alignItems="flex-start" w="100%">
                <HStack spacing={0}>
                  <FormLabel mb={0} mr={1}>
                    {filterSchedules.labels.schedules}
                  </FormLabel>
                  <Text fontSize="xs">{filterSchedules.labels.limitOnValue}</Text>
                </HStack>
                <Select
                  placeholder={filterSchedules.placeholders.schedule}
                  value={[]}
                  isDisabled={schedules.length === maxFilterValues}
                  options={schedulesOptions}
                  name="schedule"
                  onChange={(values: any) => {
                    setSchedules([...schedules, ...values]);
                  }}
                  isMulti
                  closeMenuOnSelect
                  hideDropdownArrow
                  hideSelectedValues
                />
                {schedules && mapFilterToTags(schedules)}
              </VStack>
            </FormControl>
          </VStack>
        </Box>
      </Box>
      <VStack
        alignItems="flex-start"
        marginTop={4}
        marginBottom={4}
        marginLeft={4}
        bottom={0}
        position="absolute"
      >
        <HStack mt={2} spacing={3}>
          <Button
            variant="default"
            onClick={() => {
              onSubmit({
                [FILTER_TYPES.PARTICIPANT]: participants,
                [FILTER_TYPES.ESCALATION_POLICY]: escalationPolicies,
                [FILTER_TYPES.MY_ON_CALL]: myOnCall,
                [FILTER_TYPES.NO_ESCALATION_POLICY]: noEscalationPolicies,
                [FILTER_TYPES.SCHEDULE]: schedules,
              });
              onCancel();
            }}
          >
            {filterSchedules.buttonTexts.apply}
          </Button>
          <Button
            variant="default"
            onClick={() => {
              onSubmit({
                [FILTER_TYPES.PARTICIPANT]: [],
                [FILTER_TYPES.ESCALATION_POLICY]: [],
                [FILTER_TYPES.SCHEDULE]: [],
                [FILTER_TYPES.MY_ON_CALL]: false,
                [FILTER_TYPES.NO_ESCALATION_POLICY]: false,
              });
              onClearFilters();
              onCancel();
            }}
            isDisabled={
              !participants.length &&
              !escalationPolicies.length &&
              !schedules.length &&
              !myOnCall &&
              !noEscalationPolicies &&
              !ownerFilterSelected
            }
          >
            {filterSchedules.buttonTexts.clearFilters}
          </Button>
        </HStack>
      </VStack>
    </>
  );
};

export default FilterSchedules;
