import { ChakraProvider, Flex, HStack, Link, Text } from '@chakra-ui/react';
import { API } from 'core';
import { T_WA_UP_SG_NODE_DETAILS_VIEWED } from 'core/const/tracker';
import { CloseButton } from 'library/atoms';
import { ExternalLinkIcon } from 'library/icons';
import { CustomDrawerComponent, Loader } from 'library/molecules';
import { DEFAULT_REACT_QUERY_CONFIG } from 'library/query-config';
import LibraryTheme from 'library/theme';
import { FC, useEffect, useMemo } from 'react';
import { generatePath } from 'react-router';
import { AppTracker } from 'shared/analytics/tracker';
import { IncidentStatusType } from 'views/main/organization/incident-list/graphql/generated/types';
import { useListRecentIncidentsQuery } from 'views/main/organization/incident-list/graphql/query';
import { SERVICE_DETAILS_PATH } from 'views/main/routes/routes';

import { useServiceDetailQuery } from '../../hooks/useServiceDetail';
import { SERVICE_NODE_DETAIL_TYPE } from '../../Interfaces/graph';
import { ServiceNodeDetails } from './service-node-details';

type Props = {
  serviceNode: SERVICE_NODE_DETAIL_TYPE;
  isOpen: boolean;
  onClose: () => void;
};

export const ServiceGraphNodeDetail: FC<Props> = ({ serviceNode, isOpen, onClose }) => {
  const { isLoading, data, refetch } = useServiceDetailQuery(serviceNode.id);

  const serviceDetailsPath = useMemo(
    () => generatePath(SERVICE_DETAILS_PATH, { id: serviceNode.id }),
    [serviceNode.id],
  );

  useEffect(() => {
    AppTracker.track(T_WA_UP_SG_NODE_DETAILS_VIEWED);
  }, []);

  const { isLoading: isILLoading, data: incidents } = useListRecentIncidentsQuery(
    {
      input: {
        teamID: API.config.teamId,
        pageSize: 3,
        filters: {
          serviceIDs: [serviceNode.id],
        },
        statuses: [IncidentStatusType.Acknowledged, IncidentStatusType.Triggered],
        cursorValue: '',
      },
    },
    {
      ...DEFAULT_REACT_QUERY_CONFIG,
    },
  );

  return (
    <ChakraProvider theme={LibraryTheme}>
      <CustomDrawerComponent
        size="sm"
        isOpen={isOpen}
        onClose={onClose}
        title={serviceNode.name}
        disableBodyPadding
        renderHeader={false}
        variant="alwaysOpen"
        trapFocus={false}
        lockFocusAcrossFrames={false}
      >
        <HStack width="100%" justifyContent="space-between" alignItems="flex-start" p={4}>
          <Text color="brand.blue" variant="h3_800" maxW="80%">
            {serviceNode.name}
          </Text>
          <Flex gap={3} alignItems="center">
            <Link href={serviceDetailsPath} isExternal>
              <ExternalLinkIcon />
            </Link>
            <CloseButton onClick={onClose} />
          </Flex>
        </HStack>
        {isLoading ? (
          <Loader />
        ) : (
          <ServiceNodeDetails
            serviceNode={serviceNode}
            data={data}
            incidents={incidents?.listIncidents.data}
            incidentsLoading={isILLoading}
          />
        )}
      </CustomDrawerComponent>
    </ChakraProvider>
  );
};
