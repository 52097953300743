import { HStack, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';

import React from 'react';
import { Workflow } from '../../../types/response/workflow';
import ActionsCard from './ActionsCard';

type WorkflowActionsProps = Pick<Workflow, 'actions'>;

const WorkflowActions: React.FC<WorkflowActionsProps> = ({ actions }) => {
  if (!actions) return null;
  if (!actions.length) return null;

  return (
    <VStack align="stretch" px={6} pb={12}>
      <Text variant="h2" as="h2" fontWeight="bold">
        ACTIONS
      </Text>
      <VStack
        bg="brand.white"
        p={4}
        borderWidth="1px"
        borderStyle="solid"
        borderColor="secondary.200"
        align="stretch"
        borderRadius={2}
        id="actions"
      >
        {actions.map((action, index) => {
          return <ActionsCard key={index} actions={action} />;
        })}
      </VStack>
    </VStack>
  );
};

export default WorkflowActions;
