import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { createStandaloneToast } from '@chakra-ui/react';
import { queryKeys, serviceListUrl } from '../contants/service.contant';
import { reactQueryConfigSuccess } from '../helpers/helper.service';
import { API } from 'core';
import { useHistory } from 'react-router-dom';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_GS_SERVICE_DELETED } from '../../../../../core/const/tracker';
import { compact } from 'lodash';

const toast = createStandaloneToast();

const deleteService = async (deleteServiceID: string) => {
  const { data } = await axios.delete(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/${deleteServiceID}`,
  );
  return data;
};

export function useDeleteService(showModal: (names: string[]) => void) {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate: deleteSelectedService, isLoading: isDeleting } = useMutation(
    (deleteServiceId: string) => deleteService(deleteServiceId),
    {
      onMutate: async (deleteServiceId: string | null) => {},
      onError: (err: any) => {
        let showToast = true;
        const error = err?.response?.data?.meta ?? {};
        const errMsg = error.error_message || 'error connecting to server';

        if (Array.isArray(error.conflict_data?.global_rulesets)) {
          const entityNames: string[] = compact(
            error.conflict_data.global_rulesets.map((ger: { name: string }) => ger.name),
          );

          if (entityNames.length) {
            showToast = false;
            showModal(entityNames);
          }
        }

        if (showToast) {
          toast({
            title: 'Delete Service',
            description: errMsg,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      },
      onSuccess: data => {
        reactQueryConfigSuccess('Service removed');
        queryClient.invalidateQueries(queryKeys.SERVICELIST, { refetchInactive: true });
        AppTracker.track(T_WA_GS_SERVICE_DELETED);
        history.push(serviceListUrl);
      },
    },
  );

  return { deleteSelectedService, isDeleting };
}
