import { REQUEST_ROLES, REQUEST_ROLES_SUCCESS, REQUEST_ROLES_FAIL } from '../../const/public';
import { INITIAL_STATE } from '../state';
import { DEFAULT_ERROR } from '../../const/defaults';
import { IRole } from '../../interfaces/IRole';

const initialRoles = INITIAL_STATE.roles;

const appRoles = (state = initialRoles, action: any) => {
  switch (action.type) {
    case REQUEST_ROLES:
      return {
        ...state,
        ...{ error: { ...DEFAULT_ERROR } },
      };
    case REQUEST_ROLES_SUCCESS:
      return {
        ...state,
        ...{
          r: (action.payload.roles as IRole[]).filter(r => (r.name as any) !== 'Admin'),
          error: { ...DEFAULT_ERROR },
        },
      };
    case REQUEST_ROLES_FAIL:
      return {
        ...state,
        ...{ r: [], error: { type: REQUEST_ROLES_FAIL, reason: action.payload } },
      };
    default:
      return state;
  }
};

export default appRoles;
