import { HStack, Text, Tooltip, VStack, Box, Switch, Checkbox } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/table-core';
import EntityUI, { EntityType } from 'library/molecules/EntityUI/EntityUI';
import { TAG_VARIANTS } from 'library/atoms/Tag/Tag';
import { useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { WORKFLOWS_DETAILS_PATH } from 'views/main/routes/routes';
import { ACTION_TITLE, TRIGGER_LABELS } from '../constant';

import { WorkflowDataResponse } from '../types';
import { WorkflowTriggerTypes } from '../types/triggers';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { IAppState } from 'core/interfaces/IAppState';
import { FormButton } from 'library/atoms';
import { Ellipsis } from 'library/atoms/Elipsis';
import { SeeMore } from 'library/atoms/SeeMore';
import { HoverComponentProps } from 'library/molecules/Tablev2/Table';
import { useSelector } from 'react-redux';

import { useWorkflowListContext } from '../context/workflowListContext';

import { Tag } from 'library/atoms';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { ACTION_ICONS_MAP } from 'library/atoms/ActionsStack/constant';

dayjs.extend(utc);
dayjs.extend(timezone);

type WorkflowData = WorkflowDataResponse['data'][number];
type WorkflowColumnProps = {
  isAllCellSelected: boolean;
  selectedCells: string[];
  onCellCheck: (id: string, enabled: boolean, checked: boolean) => void;
  onAllCellCheck: (cellId: { id: string; enabled: boolean }[], checked: boolean) => void;
  onToggleEnableWorkflow: (id: string, enabled: boolean) => void;
};
type Props = {
  onDelete: ({ id, title }: { id: string; title: string }) => void;
  onClone: (id: string, workflow_name: string) => () => void;
};

export const HoverComponent = (props: HoverComponentProps<WorkflowData> & Props) => {
  const workflowDeletePermission = useUserAccess().hasDeleteAccess(
    'workflows',
    props.row.original.id,
  );

  const workflowClonePermission = useUserAccess().hasCreateAccess('workflows');
  return (
    <HStack gap="0.5rem">
      <NoPermissionTooltip isDisabled={workflowDeletePermission}>
        <FormButton
          variant="outline-danger"
          title="Delete"
          onClick={() =>
            props.onDelete({ id: props.row.original.id, title: props.row.original.title })
          }
          isDisabled={!workflowDeletePermission}
          className={workflowDeletePermission ? '' : 'disabled'}
        />
      </NoPermissionTooltip>

      <NoPermissionTooltip isDisabled={workflowClonePermission}>
        <FormButton
          onClick={props.onClone(props.row.original.id, props.row.original.title)}
          isDisabled={!workflowClonePermission}
          className={workflowClonePermission ? '' : 'disabled'}
          title="Clone"
        />
      </NoPermissionTooltip>
    </HStack>
  );
};

const ActionIcons = ({ actions }: { actions: WorkflowData['actions'] }) => {
  if (!actions.length) return null;
  const viewActions = actions.slice(0, 1);
  const remainingActions = actions.slice(1);
  return (
    <HStack>
      {viewActions.map(action => {
        if (
          action.name.startsWith('sq') ||
          action.name.startsWith('sl') ||
          action.name.startsWith('ms') ||
          action.name.startsWith('ji')
        ) {
          return (
            <Tooltip
              label={ACTION_TITLE[action.name as keyof typeof ACTION_TITLE]}
              key={action.name}
            >
              <Box>{ACTION_ICONS_MAP['sm'][action.name]}</Box>
            </Tooltip>
          );
        }
        return null;
      })}
      {remainingActions.length ? (
        <SeeMore
          items={remainingActions.map((action, index) => {
            if (
              action.name.startsWith('sq') ||
              action.name.startsWith('sl') ||
              action.name.startsWith('ms') ||
              action.name.startsWith('ji')
            ) {
              return (
                <Tooltip
                  label={ACTION_TITLE[action.name as keyof typeof ACTION_TITLE]}
                  key={action.name + index}
                >
                  <Box>{ACTION_ICONS_MAP['sm'][action.name]}</Box>
                </Tooltip>
              );
            }
            return null;
          })}
        />
      ) : null}
    </HStack>
  );
};

export const useWorkflowColumn = ({
  isAllCellSelected,
  selectedCells,
  onCellCheck,
  onAllCellCheck,
  onToggleEnableWorkflow,
}: WorkflowColumnProps) => {
  const workflowContext = useWorkflowListContext();
  const useAppTimezone = useSelector((state: IAppState) => state.userInfo.d?.time_zone) || 'UTC';
  const HasWorkflowEnableUpdatePermission = (id: string) =>
    useUserAccess().hasUpdateAccess('workflows', id);

  const columns = useMemo<ColumnDef<WorkflowData, any>[]>(
    () => [
      {
        id: 'select-all',
        header: ({ table }) => (
          <Checkbox
            size={'lg'}
            {...{
              isChecked: isAllCellSelected,
              onChange: e => {
                onAllCellCheck(
                  table
                    .getRowModel()
                    .rows.map(r => ({ id: r.original.id, enabled: r.original.enabled })),
                  !isAllCellSelected,
                );
                table.getToggleAllRowsSelectedHandler()(e);
              },
              isIndeterminate: table.getIsSomePageRowsSelected(),
            }}
          />
        ),
        cell: ({ row }) => {
          const isRowSelected = selectedCells.includes(row.original.id);
          return (
            <Checkbox
              size={'lg'}
              {...{
                isChecked: isRowSelected,
                isDisabled: !row.getCanSelect(),
                onChange: e => {
                  onCellCheck(row.original.id, row.original.enabled, !isRowSelected);
                  row.getToggleSelectedHandler()(e);
                },
              }}
            />
          );
        },
      },
      {
        header: 'Title',
        cell: ({ row }) => {
          const pathWithQuery = generatePath(WORKFLOWS_DETAILS_PATH, { id: row.original.id });
          const queryParams = new URLSearchParams();
          queryParams.append('workflowPage', workflowContext.paginationInfo.pageIndex.toString());
          queryParams.append('workflowSize', workflowContext.paginationInfo.pageSize.toString());

          // Combine the path and query parameters
          const finalPath = `${pathWithQuery}?${queryParams.toString()}`;
          const viewTags = row.original.tags.slice(0, 3);
          const remainingTags = row.original.tags.slice(3);
          return (
            <VStack spacing={1} alignItems="flex-start">
              <Link to={finalPath}>
                <Text>
                  <Ellipsis text={row.original.title} maxLength={20} />
                </Text>
              </Link>
              <HStack gap={1}>
                {viewTags.map((data, index) => (
                  <Tag
                    key={index}
                    variant={TAG_VARIANTS.KEY_VALUE}
                    keyStr={data.key}
                    color={data.color}
                    value={data.value}
                  />
                ))}
                {remainingTags.length && (
                  <SeeMore
                    items={remainingTags.map((data, index) => {
                      return (
                        <Tag
                          key={index}
                          variant={TAG_VARIANTS.KEY_VALUE}
                          keyStr={data.key}
                          color={data.color}
                          value={data.value}
                        />
                      );
                    })}
                  />
                )}
              </HStack>
            </VStack>
          );
        },
      },
      {
        header: 'Enable',
        cell: ({ row }) => {
          return (
            <NoPermissionTooltip isDisabled={HasWorkflowEnableUpdatePermission(row.original.id)}>
              <Switch
                size="sm"
                isChecked={row.original.enabled}
                onChange={e => onToggleEnableWorkflow(row.original.id, e.target.checked)}
                isDisabled={!HasWorkflowEnableUpdatePermission(row.original.id)}
              />
            </NoPermissionTooltip>
          );
        },
      },
      {
        header: 'Trigger',
        cell: ({ row }) => (
          <Text>{TRIGGER_LABELS[row.original.trigger as WorkflowTriggerTypes]}</Text>
        ),
      },
      {
        header: 'Actions',
        cell: ({ row }) => <ActionIcons actions={row.original.actions || []} />,
      },
      {
        header: 'Owner',
        cell: ({ row }) => (
          <EntityUI
            renderType="link-popover"
            type={row.original.owner_type as EntityType}
            entityId={row.original.owner_id}
          />
        ),
      },
      {
        header: 'Created',
        cell: ({ row }) => {
          const timezone = useAppTimezone;

          const date = dayjs
            .tz(dayjs.utc(row.original.created_at?.slice(0, -10)), timezone)
            .format('MMM DD, YYYY');
          return (
            <VStack spacing={0} alignItems="flex-start">
              <EntityUI
                renderType="link-popover"
                type={'user'}
                entityId={row.original.created_by}
              />
              <Box pl={8}>
                <Text isTruncated noOfLines={1}>
                  {date}
                </Text>
              </Box>
            </VStack>
          );
        },
      },
      {
        header: 'Updated',
        cell: ({ row }) => {
          const timezone = useAppTimezone;

          // FIXME: This is a hack to fix the timezone issue in the table cell for now. We need to fix this properly.
          // TODO: Remove this hack once we have a proper fix for this from backend and use luxon instead of dayjs.
          // Recieve time in ISO format and convert it to local time
          const date = dayjs
            .tz(dayjs.utc(row.original.updated_at?.slice(0, -10)), timezone)
            .format('MMM DD, YYYY');
          return (
            <VStack spacing={0} alignItems="flex-start">
              <EntityUI
                renderType="link-popover"
                type={'user'}
                entityId={row.original.updated_by}
              />
              <Box pl={8}>
                <Text isTruncated noOfLines={1}>
                  {date}
                </Text>
              </Box>
            </VStack>
          );
        },
      },
      {
        id: 'execution_count',
        header: ({ table }) => (
          <Text>
            <Tooltip label="# of Execution">#</Tooltip>
          </Text>
        ),

        cell: ({ row }) => <Text>{row.original.execution_count}</Text>,
      },
    ],
    [isAllCellSelected, selectedCells, onCellCheck, onAllCellCheck],
  );

  return columns;
};
