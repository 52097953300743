import { useState } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import axios from 'axios';
import { API } from '../../../../../core/api';
import { createStandaloneToast } from '@chakra-ui/react';
import { queryKeys } from '../slo.constants';
import moment from 'moment';
import { ISloVoilatingIncidents } from '../slo.interface';
import qs, { ParsedQuery } from 'query-string';
import { useHistory } from 'react-router';

const toast = createStandaloneToast();

const markViolating = async (sloID: number, incidentId: number, teamId: string) => {
  const data = axios.patch(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo/${sloID}/incident/${incidentId}/false-positive/false?owner_id=${teamId}`,
  );
  return data;
};

const markFalsePositive = async (sloID: number, incidentId: number, teamId: string) => {
  const data = axios.patch(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo/${sloID}/incident/${incidentId}/false-positive/true?owner_id=${teamId}`,
  );
  return data;
};

export function useMarkViolating(teamId: string) {
  const queryClient = useQueryClient();
  const [checkedfalseInc, setFalsePositive] = useState<Array<number>>([]);

  const { mutate: markAssloViolating } = useMutation(
    (incident: { sloId: number; incidentId: number }) =>
      markViolating(incident.sloId, incident.incidentId, teamId),
    {
      onError: (error: any) => {
        const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
        toast.closeAll();
        toast({ title, status: 'error', variant: 'subtle', isClosable: true });
      },
      onSuccess: data => {
        setFalsePositive([]);
        toast({
          title: 'Marked as false positive',
          description: 'Incident successfully marked as false posititve',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.SLOFALSEPOSITIVE);
        queryClient.invalidateQueries(queryKeys.SLOVIOLATING);
        queryClient.invalidateQueries(queryKeys.SLODETAIL);
      },
    },
  );

  return { markAssloViolating, setFalsePositive, checkedfalseInc };
}

const getViolatingIncident = async (
  sloID: number,
  pageOffset: number,
  pageLimit: number,
  params: { [key: string]: string },
  filters: { dateTime: { fromDate: Date; toDate: Date } },
  teamId: string,
) => {
  params['fromDate'] = moment(filters.dateTime.fromDate).toISOString();
  params['toDate'] = moment(filters.dateTime.toDate).toISOString();

  const { data } = await axios.get<{ data: ISloVoilatingIncidents }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo/${sloID}/incident?owner_id=${teamId}&false-positive=false&offset=${pageOffset}&limit=${pageLimit}`,
    { params },
  );
  return data.data;
};

export const useSloViolating = (
  sloID: number,
  pageOffset_: number,
  pageLimit_: number,
  sloViolatingOverviewFilters: { [key: string]: string },
  filters: { dateTime: { fromDate: Date; toDate: Date } },
  teamId: string,
  pagination: Array<number>,
  totalNoOfPages: number,
  setPaginationFn: (total: number) => {
    pagination: number[];
    totalNoOfPages: number;
  },
) => {
  return useQuery(
    [queryKeys.SLOVIOLATING, teamId, sloID, pageOffset_, pageLimit_, filters],
    () =>
      getViolatingIncident(
        sloID,
        pageOffset_,
        pageLimit_,
        sloViolatingOverviewFilters,
        filters,
        teamId,
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        const { pagination: paginations, totalNoOfPages: totalPages } = setPaginationFn(
          data.meta.total,
        );
        pagination = [...paginations];
        totalNoOfPages = totalPages;
      },
      onError: (error: any) => {
        const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
        toast.closeAll();
        toast({ title, status: 'error', variant: 'subtle', isClosable: true });
      },
    },
  );
};

const getFalsePositiveIncident = async (
  sloID: number,
  pageOffset: number,
  pageLimit: number,
  params: { [key: string]: string },
  filters: { dateTime: { fromDate: Date; toDate: Date } },
  teamId: string,
) => {
  params['fromDate'] = moment(filters.dateTime.fromDate).toISOString();
  params['toDate'] = moment(filters.dateTime.toDate).toISOString();

  const { data } = await axios.get<{ data: ISloVoilatingIncidents }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/slo/${sloID}/incident?owner_id=${teamId}&&false-positive=true&offset=${pageOffset}&limit=${pageLimit}`,
    {
      params,
    },
  );
  return data.data;
};

export const useFalsePositiveIncident = (
  sloID: number,
  pageOffset: number,
  pageLimit: number,
  sloFalsePositiveFilters: { [key: string]: string },
  filters: { dateTime: { fromDate: Date; toDate: Date } },
  teamId: string,
  pagination_: Array<number>,
  totalNoOfPages_: number,
  setPaginationFn: (total: number) => {
    pagination: number[];
    totalNoOfPages: number;
  },
) => {
  const history = useHistory();
  const query = qs.parse(history.location.search, { arrayFormat: 'comma' }) as ParsedQuery<string>;
  const getFalseIncident = query['queryType'] === '1';
  return useQuery(
    [queryKeys.SLOFALSEPOSITIVE, sloID, pageOffset, pageLimit, teamId, filters],
    () =>
      getFalsePositiveIncident(
        sloID,
        pageOffset,
        pageLimit,
        sloFalsePositiveFilters,
        filters,
        teamId,
      ),
    {
      enabled: getFalseIncident,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        const { pagination: paginations, totalNoOfPages: totalPages } = setPaginationFn(
          data.meta.total,
        );
        pagination_ = [...paginations];
        totalNoOfPages_ = totalPages;
      },
      onError: (error: any) => {
        const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
        toast.closeAll();
        toast({ title, status: 'error', variant: 'subtle', isClosable: true });
      },
    },
  );
};

export function useMarkFalsePositive(teamId: string) {
  const [checkedSloInc, setForSloAffected] = useState<Array<number>>([]);
  const queryClient = useQueryClient();

  const { mutate: markAsFalsePositive } = useMutation(
    (incident: { sloId: number; incidentId: number }) =>
      markFalsePositive(incident.sloId, incident.incidentId, teamId),
    {
      onError: (error: any) => {
        const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
        toast.closeAll();
        toast({ title, status: 'error', variant: 'subtle', isClosable: true });
      },
      onSuccess: data => {
        setForSloAffected([]);
        toast({
          title: 'Marked as false positive',
          description: 'Incident successfully marked as false posititve',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.SLOVIOLATING);
        queryClient.invalidateQueries(queryKeys.SLOFALSEPOSITIVE);
        queryClient.invalidateQueries(queryKeys.SLODETAIL);
      },
    },
  );

  return { markAsFalsePositive, checkedSloInc, setForSloAffected };
}
