import UnsavedChangesGuard from 'components/unsavedChangesGuard';
import { GlobalTeamIDGate } from 'core/hooks/useCompareGlobalAndEntityTeamId';
import { useState } from 'react';
import PostmortemModal from '../incidentDetails/renders/postmortem/postmortem.modal';
import { getIncidentById } from './api/incidents.api';
import { getPostmortemByIncidentId } from './api/postmortems.api';
import DialogModalFrame from './behaviors/LegacyDialogModalFrame';
import { Session } from './behaviors/Session';
import { useDetail } from './behaviors/useDetail';

interface IProps {
  incidentId: string;
  refreshTable: () => void;
  closeModal: () => void;
}

async function getPostmortemAndIncident(_: Session, id: string) {
  return Promise.all([getPostmortemByIncidentId(id), getIncidentById(id)]);
}

export default function TogglePostmortemModal(props: IProps): JSX.Element {
  const { query } = useDetail({
    baseQueryKey: ['postmortem'],
    id: props.incidentId,
    queryFn: getPostmortemAndIncident,
  });

  const [isModalDirty, setIsModalDirty] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const closeModal = () => {
    if (isModalDirty) setShowUnsavedChangesModal(true);
    else {
      setShowUnsavedChangesModal(false);
      props.closeModal();
    }
  };
  const handleChangeInPostmortemModal = (modalIsDirty: boolean) => {
    if (modalIsDirty) {
      if (!isModalDirty) setIsModalDirty(true);
    } else {
      setIsModalDirty(false);
    }
  };
  const onSaveUpdate = () => {
    setIsModalDirty(false);
    setShowUnsavedChangesModal(false);
    props.closeModal();
  };
  const closeUnsavedChangesModal = () => setShowUnsavedChangesModal(false);

  const onDiscardChanges = () => {
    setShowUnsavedChangesModal(false);
    setIsModalDirty(false);
    props.closeModal();
  };

  let children = null;
  if (query.isLoading || query.isIdle) {
    children = <div>Loading</div>;
  } else if (query.isError) {
    children = <div>Error: {query.error.message}</div>;
  } else {
    const [postmortem, incident] = query.data;

    const postmortemOwnerId = postmortem?.owner?.id;
    children = (
      <>
        <GlobalTeamIDGate entityTeamId={postmortemOwnerId ?? ''} />
        <PostmortemModal
          postmortem={postmortem}
          incidentId={props.incidentId}
          incident={incident}
          hideModal={closeModal}
          getPostmortem={() => {
            query.refetch();
          }}
          onRefreshTable={props.refreshTable}
          isModalDirty={isModalDirty}
          setModalIsDirty={handleChangeInPostmortemModal}
          onSaveUpdate={onSaveUpdate}
        />
      </>
    );
  }

  return (
    <>
      <DialogModalFrame
        id="incident_details__postmortem"
        onClose={closeModal}
        padding="32px"
        width="800px"
        openOnRender
      >
        {children}
      </DialogModalFrame>
      <UnsavedChangesGuard
        isManual={true}
        showModal={showUnsavedChangesModal}
        onDiscardChanges={onDiscardChanges}
        onModalClose={closeUnsavedChangesModal}
      />
    </>
  );
}
