import {
  Flex,
  HStack,
  Image,
  Text,
  useMultiStyleConfig,
  useToast as useChakraToast,
} from '@chakra-ui/react';

import type { ToastProps } from '../types';
import { IconButton } from './IconButton';

export const useToast = () => {
  const toast = useChakraToast();
  const styles = useMultiStyleConfig('Toast', {});

  const renderToast = ({ text, status, onClose, link }: ToastProps & { onClose: () => void }) => {
    const onLinkClick = () => {
      link?.onClick?.();
      onClose();
    };
    return (
      <Flex sx={{ ...styles.cardStyle, ...styles[status] }}>
        <HStack>
          <Image src={`/icons/${status}-icon.svg`} sx={{ ...styles.iconStyle }} />
          <Text sx={{ ...styles.textStyle }}>{text}</Text>
          {link && (
            <Text cursor="pointer" textDecoration="underline" onClick={onLinkClick}>
              {link.text}
            </Text>
          )}
        </HStack>
        <IconButton
          aria-label="Filter"
          icon={<Image src={`/icons/${status}-close.svg`} sx={{ ...styles.closeIconStyle }} />}
          onClick={onClose}
          sx={{ ...styles.closeIconButton }}
        />
      </Flex>
    );
  };

  function toastAction(toastConfigs: ToastProps) {
    toast({
      isClosable: true,
      duration: 5000,
      title: toastConfigs.text,
      status: toastConfigs.status,
      render: ({ onClose }) => renderToast({ ...toastConfigs, onClose }),
      position: 'top',
      containerStyle: {
        maxWidth: '100%',
      },
    });
  }

  return toastAction;
};
