import { AlertSourceIcons } from '../icons/icons';
import NoImage from '../icons/AlertSources/NoIMage';

export const getAlertSource = (iconName?: string) => {
  const name = iconName ? iconName[0].toUpperCase() + iconName.substring(1) : '';
  const image = AlertSourceIcons[name] ?? NoImage;

  return {
    name,
    image,
  };
};
