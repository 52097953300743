import { calculateTime } from './utils';
import { Box, HStack, VStack, Text, Circle } from '@chakra-ui/react';

export const ShiftTime = ({
  shift,
  idx,
  showMore,
  setShowMore,
  shouldShowMoreButton,
}: {
  shift: { startHour: number; startMin: number; duration: number };
  idx: number;
  showMore: boolean;
  setShowMore: React.Dispatch<React.SetStateAction<boolean>>;
  shouldShowMoreButton: boolean;
}) => {
  const { startTime, endTime, isNextDay } = calculateTime(
    shift.startHour,
    shift.startMin,
    shift.duration,
  );
  if (idx > 0 && !showMore) return null;
  return (
    <HStack key={idx}>
      <Text mt={0.5} fontSize={10} width="100%" color="#627C98" fontWeight="700">
        {startTime} - {endTime} {isNextDay ? ' +1' : ''}
      </Text>
      {shouldShowMoreButton && (
        <Circle onClick={() => setShowMore(!showMore)} size="4" cursor="pointer" bgColor="#F1F3F6">
          <Text variant="body">{`...`}</Text>
        </Circle>
      )}
    </HStack>
  );
};
