import { THEME_COLORS } from 'library/theme/colors';
import useStatuspageComponentTimelineContext from '../hook';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

interface IProps {
  callback: (confirm: boolean) => void;
}

export default function StatuspageOverrideUnsavedChangesModal(props: IProps) {
  const { unsavedModalOpen, setUnsavedModalOpen, setUnsavedModalCallback } =
    useStatuspageComponentTimelineContext();

  return (
    <Modal
      id="timeline-override-unsaved-changes-modal"
      isOpen={unsavedModalOpen}
      onClose={() => setUnsavedModalOpen(false)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontWeight={800} fontSize="18px" color={THEME_COLORS.secondary[950]}>
            Do you want to switch to a different date?
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontWeight={400} fontSize="18px" color={THEME_COLORS.secondary[700]}>
            Switching to a different date will not save changes.
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="end">
          <Button variant="invertedDefault" mr={3} onClick={() => setUnsavedModalOpen(false)}>
            Cancel
          </Button>
          <Button
            bg={THEME_COLORS.brand.red}
            onClick={() => {
              props.callback(true);
              setUnsavedModalCallback(() => () => null);
              setUnsavedModalOpen(false);
            }}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
