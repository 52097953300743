import React from 'react';
import { Link, Text } from '@chakra-ui/react';

import { ServiceHelpGuide } from '../common/HelpGuide';

export const serviceHelpGuide: ServiceHelpGuide = [
  {
    title: 'What’s a Service?',
    desc: [
      'Services are the logical units that map to core components of your infrastructure or application. Services connect to alert sources and bring all your relevant alerts in one place.',
    ],
    link: [
      { url: 'https://support.squadcast.com/docs/adding-a-service-1', label: 'Read More' },
      {
        url: 'https://www.squadcast.com/blog/how-to-configure-services-in-squadcast-best-practices-to-reduce-mttr',
        label: 'Best Practices',
      },
    ],
    type: 'help',
  },
  {
    title: 'How to pick an Owner?',
    desc: [
      'The owner of a service is well versed with the architecture, design and dependencies of the service. This helps others in the team know who can help them during on-call issues.',
    ],
    link: [{ url: 'https://support.squadcast.com/services/adding-a-service', label: 'Read More' }],
    type: 'help',
  },
  {
    title: 'How to use Tags?',
    desc: [
      'Use tags to organize, classify and add context to your services. Adding tags like Type, Environment, Functionality and Priority can help structure and add more context to your services.',
    ],
    link: [{ url: 'https://support.squadcast.com/services/adding-a-service', label: 'Read More' }],
    type: 'help',
  },
  {
    title: 'Tips:',
    desc: [
      'Give your services meaningful names that reflect the actual component name or functionality',
      'You can also assign a Squad as the owner of a service',
      'You can use tags to differentiate between business and technical services',
    ],
    link: [],
    type: 'tips',
  },
  {
    title: 'If you need help with something, use the chat widget below to reach us.',
    desc: [''],
    link: [],
    type: 'queries',
  },
];

export const alertSourceHelpGuide: ServiceHelpGuide = [
  {
    title: 'What do alert sources do?',
    desc: [
      'Alert sources are webhook-based integrations that send alerts to your service. You can add one or more alert source integrations to your service.',
    ],
    link: [],
    type: 'help',
  },
  {
    title: 'Don’t see an alert source here?',
    desc: [
      <Text lineHeight={1.2} fontSize={14} key="alert-source-desc" pt={1.5}>
        In cases where you want to send custom alerts that don’t come from any tools listed here,
        you can use our&nbsp;
        <Link
          variant="italic"
          href={'https://support.squadcast.com/docs/apiv2'}
          target="_blank"
          rel="noopener noreferrer"
        >
          Incident Webhook
        </Link>
        &nbsp; or our&nbsp;
        <Link
          variant="italic"
          href={'https://support.squadcast.com/docs/email'}
          target="_blank"
          rel="noopener noreferrer"
        >
          Email Integration
        </Link>
        .
      </Text>,
    ],
    link: [],
    type: 'help',
  },
  {
    title: 'Tips:',
    desc: [
      'Incident auto-resolution is enabled by default provided the alert source supports it.',
      'Email as an alert source does not support incident auto-resolution.',
      'Reduce alert noise by sending in actionable alerts from the source that you want to be notified on.',
      'When not possible at the source, use automation rules to group, suppress, tag and route incidents intelligently.',
    ],
    link: [],
    type: 'tips',
  },
  {
    title: 'Have more questions around alert sources? Use the chat widget below to reach us.',
    desc: [''],
    link: [],
    type: 'queries',
  },
];
