import React, { Component } from 'react';
// import { View, EventApi } from '@fullcalendar/core';
import FullCalendar, { EventApi, ViewApi } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listViewPlugin from '@fullcalendar/list';
import timeViewPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentCalendar from '@fullcalendar/moment';
import momentTimeZone from '@fullcalendar/moment-timezone';

import './index.css';
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/list/main.css';
// import '@fullcalendar/timegrid/main.css';

import { Locale } from 'core/helpers/dateUtils';

interface IProps {
  events: any;
  onSelectEvent: (event: { event: EventApi; view: ViewApi }) => void;
  onSelectSlot: (event: any) => void;
  onRangeChange: ({ view }: { view: ViewApi }) => void;
}

class Calendar extends Component<IProps> {
  private _calendarRef = React.createRef();
  private _dateFormat = Locale.shortDateFormat.replace(/L/g, 'M').replace(/d/g, 'D');

  render() {
    return (
      <FullCalendar
        timeZone={Locale.userZone}
        selectable={true}
        // allDayDefault={true}
        select={this.props.onSelectSlot}
        nowIndicator={true}
        datesSet={this.props.onRangeChange}
        dayMaxEvents={true}
        titleFormat={{ month: 'long', year: 'numeric' }}
        listDayFormat={this._dateFormat}
        eventDisplay="block"
        events={this.props.events.map((e: any) => ({ ...e, eventColor: e.color })) || []}
        plugins={[
          dayGridPlugin,
          timeViewPlugin,
          listViewPlugin,
          interactionPlugin,
          momentCalendar,
          momentTimeZone,
        ]}
        initialView="dayGridMonth"
        eventClick={this.props.onSelectEvent}
        headerToolbar={{
          left: 'title',
          right: 'today prev,next dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        }}
        ref={this._calendarRef as any}
        height="parent"
      />
    );
  }
}

export default Calendar;
