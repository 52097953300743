import { Flex, Text } from '@chakra-ui/react';
import { NodePopover } from 'components/chakra/Popover';
import SQTooltip from 'components/chakra/Tooltip';
import { DateTime } from 'luxon';
import { Cell } from 'react-table';
import { ScheduleOverride } from 'views/main/organization/schedules/graphql/types';
import ScheduleAvatarGroup from '../../../common/AvatarGroup';
import {
  onCallCoverageRowID,
  schedulesOverrideColor,
} from '../../../constants/schedules.rotation-template';
import { rotationGapID, rotationGapLabel } from '../../../constants/schedules.view';
import { DayOfWeek } from '../../../graphql/types';
import { IPatternParticipant } from '../../../helpers/helpers.customrotations';
import {
  compareTodayTZDate,
  compareTwoDates,
  getDateTime,
  getEventDateRange,
  getUTCDateTime,
} from '../../../helpers/helpers.date';
import {
  calculateEventWidth,
  calculateTimelineOffset,
  getEventBorderColor,
  getPaddedValue,
  mapParticipantToEventParticipant,
  showEventDetails,
} from '../../../helpers/helpers.event';
import {
  getOnCallCoverageAsRotation,
  getOverridesAsRotation,
} from '../../../helpers/helpers.schedule';
import { EventDetails } from '../../schedules.events/events.details';
import OnCallCoverageRow from './on-call-coverage-row';
import { TimelineDivider } from './timeline-divider';
import { Event, IRotationTable, IWeekScheduleRotation, OnCallCoverage } from './types';

export const getScheduleRotations = ({
  rotations,
  gaps,
  overrides,
  visibleDates,
  allParticipants,
  onCallCoverage,
}: {
  rotations: IRotationTable['rotations'];
  gaps: IRotationTable['gaps'];
  overrides: IRotationTable['overrides'];
  visibleDates: IRotationTable['visibleDates'];
  allParticipants: IPatternParticipant[];
  onCallCoverage: IRotationTable['onCallCoverage'];
}) => {
  const rotationWGaps = (
    rotations.length > 0
      ? [
          ...rotations,
          {
            ...getOverridesAsRotation(
              rotations[0].scheduleID,
              rotations[0].scheduleName,
              rotations[0].scheduleTimeZone,
            ),
            events:
              overrides?.map(o => ({
                ID: o.ID,
                startTime: o.startTime,
                endTime: o.endTime,
                isOverride: true,
                overrideID: o.ID,
                participants: o.overrideWith?.participants,
                override: o,
              })) ?? ([] as Event[]),
          },
          {
            ...getOnCallCoverageAsRotation(
              rotations[0].scheduleID,
              rotations[0].scheduleName,
              rotations[0].scheduleTimeZone,
            ),
            events:
              onCallCoverage?.map(o => ({
                ...o,
                ID: 1,
                onCallCoverage: o,
              })) ?? ([] as Event[]),
          },
        ]
      : []
  ) as IRotationTable['rotations'] & IRotationTable['onCallCoverage'];

  const rotationsWithGaps = rotationWGaps.map(r => {
    let rotationInfoByDate = {} as IWeekScheduleRotation;
    if (r.events?.length) {
      rotationInfoByDate = r.events.reduce((acc, event: Event) => {
        const eventParticipants = event.participants || [];
        let startTime = event.startTime;
        const endTime = event.endTime;

        const startVisibleDate = visibleDates[0];
        const originalStartDT = getUTCDateTime(startTime, r.scheduleTimeZone);
        /**
         * If the event spans from the previous date than visible dates
         * fill the visible date event with that of previous date
         */
        const visibleTZDate = getDateTime(startVisibleDate.toJSDate())
          .setZone(r.scheduleTimeZone)
          .set({
            year: startVisibleDate.year,
            month: startVisibleDate.month,
            day: startVisibleDate.day,
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
        if (
          getUTCDateTime(startTime, r.scheduleTimeZone) < visibleTZDate &&
          visibleTZDate < getUTCDateTime(endTime, r.scheduleTimeZone)
        ) {
          startTime = getDateTime(new Date(startTime))
            .setZone(r.scheduleTimeZone)
            .set({
              year: startVisibleDate.year,
              month: startVisibleDate.month,
              day: startVisibleDate.day,
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toUTC()
            .toISO();
        }
        const startDT = getUTCDateTime(startTime, r.scheduleTimeZone);
        const endDT = getUTCDateTime(endTime, r.scheduleTimeZone);
        const overlappedByOverride =
          r.ID > rotationGapID
            ? !!overrides?.find(
                o =>
                  getUTCDateTime(o.startTime, r.scheduleTimeZone) <= startDT &&
                  endDT <= getUTCDateTime(o.endTime, r.scheduleTimeZone),
              )
            : false;
        const eventStartDate = startDT.toSQLDate();
        const eventEndDate = endDT.toSQLDate();

        const [eventStart, eventEnd, startDateWoTime] = [
          {
            hour: getUTCDateTime(startTime, r.scheduleTimeZone).hour as number,
            minute: getUTCDateTime(startTime, r.scheduleTimeZone).minute as number,
          },
          {
            hour: getUTCDateTime(endTime, r.scheduleTimeZone).hour as number,
            minute: getUTCDateTime(endTime, r.scheduleTimeZone).minute as number,
          },
          eventStartDate,
        ];

        const eventOffset = compareTwoDates(endDT, startDT).offset;

        const eventColIndex = visibleDates.findIndex(date => date.toSQLDate() === startDateWoTime);
        const remainingDaysColCount = 14 - eventColIndex - 1 ?? 0;

        const { offset, width } = calculateEventWidth({
          ...r,
          remainingDaysColCount,
          startTime,
          endTime,
          timeZone: r.scheduleTimeZone,
        });

        if (`${r.ID}` === onCallCoverageRowID) {
          // convert the event start and end time to width and offset
          // and add it to the acc

          const offSet = calculateTimelineOffset(eventStart.hour, eventStart.minute);
          const width = calculateTimelineOffset(eventEnd.hour, eventEnd.minute) - offSet;
        }

        const eventDetailInfo = {
          participants: eventParticipants,
          allParticipants: allParticipants,
          schedule: r.scheduleName,
          rotation: r.name,
          dateRange: getEventDateRange(originalStartDT, endDT),
          repeats: r.period,
          startDate: eventStartDate,
          endDate: eventEndDate,
          startTime,
          endTime,
          startTimeString: [
            getPaddedValue(eventStart.hour),
            getPaddedValue(eventStart.minute),
          ].join(':'),
          endTimeString: [getPaddedValue(eventEnd.hour), getPaddedValue(eventEnd.minute)].join(':'),
          timeZone: r.scheduleTimeZone,
          eventId: event.ID.toString(),
          rotationId: r.ID,
          override: {
            enabled: event.isOverride ?? false,
            ...(event.override as ScheduleOverride),
          },
          eventOffset,
          scheduleID: r.scheduleID,
        };
        acc[eventStartDate] = [
          ...(acc[eventStartDate] ?? []),
          {
            rotationName: r.name,
            offset,
            width,
            eventDetailInfo,
            remainingDaysColCount,
            color: event.isOverride ? schedulesOverrideColor : r.color ?? '',
            isSchedulePaused: r.isSchedulePaused,
            overlappedByOverride,
            onCallCoverage: event.onCallCoverage,
          },
        ];

        return acc;
      }, {} as IWeekScheduleRotation);
    }

    return {
      ...r,
      ...rotationInfoByDate,
    };
  });

  return rotationsWithGaps;
};

export const getColumns = ({
  visibleDates,
  timeZone,
  allParticipants,
}: {
  visibleDates: IRotationTable['visibleDates'];
  timeZone: string;
  allParticipants: IPatternParticipant[];
}) => {
  return [
    {
      id: 'rotation_name',
      Header: () => (
        <Text fontSize="xs" fontWeight={400} textTransform="none" textAlign="right">
          Rotation Name
        </Text>
      ),
      accessor: 'name',
      Cell: (cell: Cell) => (
        <SQTooltip text={cell.value}>
          <Text
            fontSize="xs"
            fontWeight={400}
            textTransform="none"
            ml="auto"
            noOfLines={1}
            maxW={150}
          >
            {cell.value}
          </Text>
        </SQTooltip>
      ),
    },
    ...visibleDates.map(date => ({
      id: date.toSQLDate(),
      Header: () => (
        <Text
          fontSize="xs"
          fontWeight={400}
          textTransform="none"
          textAlign="center"
          minW={70}
          maxW={115}
        >
          {date.toFormat('dd LLL, ccc')}
        </Text>
      ),
      accessor: date.toSQLDate(),
      Cell: (cell: Cell<IWeekScheduleRotation>) => {
        const rowData = cell.row.original;
        const dayEventsInfo = rowData[date.toSQLDate()] || [];

        const isOnCallCoverageRow = (rowData.ID as unknown as string) === onCallCoverageRowID;

        if (isOnCallCoverageRow) {
          return <OnCallCoverageRow dayEventsInfo={dayEventsInfo} timeZone={timeZone} />;
        }

        const isInitialRow = cell.row.index === 0;
        const isTodayDate = compareTodayTZDate(date.toJSDate(), timeZone);
        const timelinePos = calculateTimelineOffset(isTodayDate.tzTime.hr, isTodayDate.tzTime.min);
        const showTimelineUI = date.toSQLDate() === DateTime.local().setZone(timeZone).toSQLDate();
        const timelineUI = (isSchedulePaused: boolean) =>
          showTimelineUI &&
          isInitialRow && (
            <TimelineDivider
              leftOffset={timelinePos}
              noOfRows={cell.data.length}
              isSchedulePaused={isSchedulePaused}
            />
          );

        return !dayEventsInfo.length
          ? timelineUI(false)
          : dayEventsInfo.map(
              (
                {
                  offset,
                  width,
                  eventDetailInfo,
                  color,
                  isSchedulePaused,
                  rotationName,
                  overlappedByOverride,
                },
                index,
              ) => {
                if (!eventDetailInfo) {
                  return timelineUI(false);
                } else {
                  const isPastEvent = compareTodayTZDate(
                    new Date(eventDetailInfo.endTime),
                    timeZone,
                  ).isPast;
                  const eventParticipants = eventDetailInfo.participants
                    ?.map(p => ({ ID: p.ID, type: p.type }))
                    .map(p => mapParticipantToEventParticipant(p, allParticipants));

                  const showEventParticipants = showEventDetails(width, eventParticipants);
                  return (
                    <>
                      {timelineUI(isSchedulePaused ?? false)}
                      <NodePopover
                        key={index}
                        placement={'auto'}
                        trigger={
                          <Flex
                            bgColor={
                              (isSchedulePaused || isPastEvent || overlappedByOverride) &&
                              rotationName !== rotationGapLabel
                                ? 'gray.120'
                                : color ?? 'blue.50'
                            }
                            p={1}
                            borderWidth={1}
                            borderColor={getEventBorderColor(
                              isSchedulePaused || isPastEvent || overlappedByOverride
                                ? 'gray.120'
                                : color ?? 'blue.50',
                            )}
                            overflow="hidden"
                            borderRadius={4}
                            columnGap={1}
                            justifyContent="center"
                            pos="absolute"
                            top="50%"
                            left={offset + '%'}
                            transform="translate(0, -50%)"
                            width={`${width}%`}
                            visibility={width ? 'visible' : 'hidden'}
                            minH={8}
                            _hover={{
                              cursor: 'pointer',
                              borderWidth: 2,
                              borderColor: 'blue.400',
                            }}
                            zIndex={1}
                            data-testid={
                              eventDetailInfo.override?.enabled
                                ? 'event-override-info'
                                : 'event-info'
                            }
                          >
                            {showEventParticipants && (
                              <ScheduleAvatarGroup
                                avatars={
                                  eventParticipants?.flatMap(p =>
                                    p.type === 'squad' ? p.participant.members : p.participant,
                                  ) as { name: string }[]
                                }
                                avatarBGColor={
                                  isSchedulePaused || isPastEvent || overlappedByOverride
                                    ? 'GrayText'
                                    : ''
                                }
                              />
                            )}
                          </Flex>
                        }
                      >
                        {rotationName !== rotationGapLabel && (
                          <EventDetails
                            {...eventDetailInfo}
                            disableEventModifiers={!!isSchedulePaused || overlappedByOverride}
                            showScheduleName={false}
                          />
                        )}
                      </NodePopover>
                    </>
                  );
                }
              },
            );
      },
    })),
  ];
};

export const calculateTime = (startHour: number, startMin: number, durationInMinutes: number) => {
  const startTime = `${startHour < 10 ? '0' : ''}${startHour}:${
    startMin < 10 ? '0' : ''
  }${startMin}`;

  const endHour = Math.floor((startHour * 60 + startMin + durationInMinutes) / 60);
  const endHourIn24HourFormat = endHour % 24;
  const endMin = (startHour * 60 + startMin + durationInMinutes) % 60;
  const endTime = `${endHourIn24HourFormat < 10 ? '0' : ''}${endHourIn24HourFormat}:${
    endMin < 10 ? '0' : ''
  }${endMin}`;

  const isNextDay = endHour >= 24;

  return { startTime, endTime, isNextDay };
};

export const parseDateTimeInTimezone = ({
  startTime,
  endTime,
  timeZone,
  useTimeZone = false,
}: {
  startTime: string;
  endTime?: string | null;
  timeZone: string;
  useTimeZone?: boolean;
}) => {
  const startDateTime = DateTime.fromISO(startTime, { zone: useTimeZone ? timeZone : 'UTC' });
  const endDateTime = endTime
    ? DateTime.fromISO(endTime, { zone: useTimeZone ? timeZone : 'UTC' })
    : null;

  return {
    startDateTime,
    endDateTime,
  };
};

export const groupShiftsByDay = (
  shiftTimeSlots: {
    dayOfWeek?: DayOfWeek | null;
    duration: number;
    startHour: number;
    startMin: number;
  }[],
) => {
  return shiftTimeSlots?.reduce(
    (
      acc: Record<string, { duration: number; startHour: number; startMin: number }[]>,
      { dayOfWeek, duration, startHour, startMin },
    ) => {
      if (!dayOfWeek) return acc;
      if (!acc[dayOfWeek]) acc[dayOfWeek] = [];
      acc[dayOfWeek].push({ duration, startHour, startMin });
      return acc;
    },
    {},
  );
};

export const getAllParticipants = (rotation: NonNullable<OnCallCoverage['rotations']>[number]) => {
  if (!rotation) return [];

  if (!rotation.participantGroups) return [];

  const participants = rotation?.participantGroups
    ?.flatMap(pg => pg?.participants?.map(p => p))
    .filter(Boolean);

  return participants;
};
