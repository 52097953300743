import { HStack, Text, VStack } from '@chakra-ui/react';
import { requestOrganizationSquad } from 'core/actions/organization/squads';
import { requestOrganizationTeams } from 'core/actions/organization/teams';
import { requestOrganizationUsers } from 'core/actions/organization/users';
import { truncate } from 'core/helpers/stringUtils';
import { IAppState } from 'core/interfaces/IAppState';
import FormField from 'library/molecules/FormField/FormField';
import { Tooltip, usernameTooltipLabel } from 'library/atoms';
import { Avatar } from 'library/atoms/Avatar';
import { SquadAvatar } from 'library/atoms/SquadAvatar';
import { THEME_COLORS } from 'library/theme/colors';
import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { components, GroupBase, SingleValueProps } from 'react-select';
import { WorkflowForm } from 'views/main/organization/workflow/types';
import FormDropdown from '../FormDropdown/FormDropdown';
import { getAllOwnerOptions, OwnerOption } from './helper';

interface Props {
  error?: string;
  name: string;
  isRequired?: boolean;
  value?: WorkflowForm['owner'];
  onMount?: (ownerOptions: OwnerOption[]) => void;
  onClearField?: () => void;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: any;
  source?: 'LCR' | 'other';
  label?: string;
}

const EntityOwnerDropdown = ({
  name,
  error,
  isRequired,
  onClearField,
  value,
  onMount,
  placeholder,
  style,
  onChange,
  source,
  label,
}: Props) => {
  const state = useSelector((state: IAppState) => state, shallowEqual);
  const dispatch = useDispatch();
  const componentSource = source ?? 'other';

  const ownerOptions: OwnerOption[] = useMemo(
    () => getAllOwnerOptions(state, componentSource),
    [
      state.organization.selectedTeam,
      state.organization.squads,
      state.organization.users,
      state.organization.teams,
    ],
  );

  useEffect(() => {
    if (ownerOptions.length > 0 && !value?.id) {
      onMount?.(ownerOptions);
    }
  }, []);

  useEffect(() => {
    dispatch(requestOrganizationTeams());
    dispatch(requestOrganizationUsers());
    dispatch(requestOrganizationSquad());
  }, []);

  const formValue = value ? ownerOptions.find(option => option.id === value.id) : null;

  const SingleValue = (props: SingleValueProps<OwnerOption, boolean, GroupBase<OwnerOption>>) => (
    <components.SingleValue {...props}>
      <Tooltip
        label={usernameTooltipLabel(props.data.name, props.data.username)}
        isDisabled={!props.data.username}
        placement="top"
      >
        <HStack gap={2}>
          <Avatar size="xs" name={props.data.name} />
          <Text>{truncate(props.data.name, 30)}</Text>
          {props.data.username && <Text>({truncate(props.data.username, 30)})</Text>}
        </HStack>
      </Tooltip>
    </components.SingleValue>
  );

  return (
    <FormField
      label={label ?? `Owner${isRequired ? ' *' : ''}`}
      error={error}
      onClearField={onClearField}
      style={{ ...style }}
    >
      <FormDropdown<OwnerOption>
        options={ownerOptions}
        name={name}
        value={formValue}
        getOptionLabel={option => option.name}
        components={{ SingleValue }}
        onChange={(e: any) => {
          onChange(e);
        }}
        getOptionValue={option => option.id}
        placeholder={placeholder ?? 'Select ...'}
        formatOptionLabel={option => (
          <VStack alignItems={'left'} spacing={0}>
            <HStack gap={2}>
              {option.type !== 'user' ? (
                <SquadAvatar name={option.name ?? 'New Team'} />
              ) : (
                <Avatar size="xs" name={option.name} />
              )}
              <VStack>
                <Text>{option.name}</Text>
                {option.username && (
                  <Text alignSelf="start" style={{ color: THEME_COLORS.secondary[1200] }}>
                    {option.username}
                  </Text>
                )}
              </VStack>
            </HStack>
          </VStack>
        )}
      />
    </FormField>
  );
};

export default EntityOwnerDropdown;
