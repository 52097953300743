import { isValidUrl } from 'core';
import { CardType, ICommunicationCard } from 'core/interfaces/ICommunicationCard';
import { useFormik } from 'formik';
import { MsTeamsIcon, SlackHashColor, TextIcon } from 'icons';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Grid, Para, SpinLoader, TextButton } from 'uie/components';

import { InputwithLabel } from './InputwithLabel';

interface IProps {
  communicationCard: ICommunicationCard;
  type: CardType;
  error?: {
    slack?: {
      message: string;
      channelNameSuggestions: string[];
    };
  };
  isEditMode: boolean;
  onSubmit: (communicationCard: ICommunicationCard) => void;
  resetChannelNameSuggestions?: () => void;
  onCancel: () => void;
}

const Wrapper = styled.div`
  position: relative;
  .buttonWrapper {
    color: white;
    position: absolute;
    right: 0px;
    bottom: -30px;
  }

  .errorForm {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }

  .suggestions {
    display: inline-flex;

    .suggestion {
      font-size: 12px;
      cursor: pointer;
      color: #1061e5;
    }
  }
`;

const CardForm: React.FC<IProps> = props => {
  const { communicationCard, onSubmit, type, error } = props;

  const isUrlFieldRequired = ['chat_room', 'video_conference', 'other'].includes(type);
  const isTitleFieldRequired = ['slack'].includes(type);

  const formik = useFormik<ICommunicationCard>({
    initialValues: {
      type: communicationCard?.type,
      url: communicationCard?.url,
      title: communicationCard?.title,
    },
    onSubmit: values => {
      onSubmit(values);
    },
    validate: values => {
      const errors: any = {};

      if (isUrlFieldRequired && !values.url) {
        errors.url = 'Url is required';
      } else if (values.url && !isValidUrl(values.url)) {
        errors.url = 'Url is invalid';
      }
      if (isTitleFieldRequired && !values.title) {
        errors.title = 'Title is required';
      }

      return errors;
    },
  });

  useEffect(() => {
    formik.setFieldError('title', error?.slack?.message);
  }, [error?.slack?.message]);

  useEffect(() => {
    if (type == CardType.msTeamsMeetingLink && !props.isEditMode) {
      createMSTeamsMeetingLink();
    }
  }, []);

  const createMSTeamsMeetingLink = () => {
    onSubmit({
      type: CardType.msTeamsMeetingLink,
      url: '',
    });
  };

  const labelText = {
    video_conference: {
      urlLabel: 'Video conference link',
      titleLabel: 'Text to display (Optional)',
      icon: <TextIcon width={16} height={16} />,
    },
    chat_room: {
      urlLabel: 'Chat room link',
      titleLabel: 'Text to display (Optional)',
      icon: <TextIcon width={16} height={16} />,
    },
    other: {
      urlLabel: 'External link',
      titleLabel: 'Text to display (Optional)',
      icon: <TextIcon width={16} height={16} />,
    },
    slack: {
      urlLabel: '',
      titleLabel: 'Channel name',
      icon: <SlackHashColor width={16} height={16} />,
    },
    msteams_meeting_link: {
      urlLabel: '',
      titleLabel: '',
      icon: <MsTeamsIcon width={16} style={{ position: 'absolute', top: -28 }} />,
    },
  };

  if (type == CardType.msTeamsMeetingLink && !props.isEditMode) {
    return (
      <Wrapper style={{ padding: '16px' }}>
        <Para fontSize={12} style={{ textAlign: 'center', marginBottom: '1rem' }}>
          Creating Microsoft Teams Meeting
        </Para>
        <Grid justifyContent="center" alignItems="center">
          <SpinLoader base="16px" />
        </Grid>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {isUrlFieldRequired ? (
        <InputwithLabel
          label={labelText[formik.values.type].urlLabel}
          type={formik.values.type}
          onChange={value => formik.setFieldValue('url', value)}
          value={formik.values.url}
          error={formik.errors.url}
        />
      ) : null}

      <InputwithLabel
        label={labelText[formik.values.type].titleLabel}
        onChange={value => formik.setFieldValue('title', value)}
        value={formik.values.title ?? ''}
        icon={labelText[formik.values.type].icon}
        error={formik.errors.title}
      />

      <div className="suggestions">
        {error?.slack?.channelNameSuggestions?.map(s => (
          <div
            className="suggestion"
            key={s}
            onClick={() => {
              formik.setFieldValue('title', s);
              if (props.resetChannelNameSuggestions) {
                props.resetChannelNameSuggestions();
              }
            }}
          >
            {s}
          </div>
        ))}
      </div>

      <TextButton className="buttonWrapper" onClick={formik.submitForm}>
        Save
      </TextButton>
    </Wrapper>
  );
};

export default CardForm;
