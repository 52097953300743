import React from 'react';
import { Container, HStack, Tag, TagLabel, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { EscalationPolicy } from 'views/main/organization/schedules/graphql/types';
import { NodePopover } from 'components/chakra/Popover';

const ScheduleEscalationPolicies = (props: {
  escalationPolicies: Array<EscalationPolicy>;
  maxEscalationsCount: number;
  tagSize?: string;
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  popoverContainerMaxWidth?: string;
  disableLink?: boolean;
}) => {
  const {
    escalationPolicies,
    maxEscalationsCount,
    tagSize,
    fontSize,
    fontWeight,
    lineHeight,
    popoverContainerMaxWidth,
    disableLink = false,
  } = props;

  const visibleEscalations =
    escalationPolicies.length > maxEscalationsCount
      ? escalationPolicies.slice(0, maxEscalationsCount)
      : escalationPolicies;
  const remainingEscalations =
    escalationPolicies.length > maxEscalationsCount
      ? escalationPolicies.slice(maxEscalationsCount)
      : [];

  return (
    <HStack>
      {visibleEscalations.map((ep, i) => (
        <Link
          key={ep.ID}
          to={`/escalationPolicies#${ep.ID}`}
          style={disableLink ? { pointerEvents: 'none' } : {}}
        >
          <Text
            color="blue.900"
            fontSize={fontSize ?? 14}
            fontWeight={fontWeight}
            lineHeight={lineHeight}
          >
            {ep.name}
            {i < escalationPolicies.length - 1 && ','}{' '}
          </Text>
        </Link>
      ))}
      {remainingEscalations.length && (
        <NodePopover
          triggerType="hover"
          trigger={
            <Tag
              size={tagSize ?? 'sm'}
              backgroundColor="blue.50"
              borderRadius="6px"
              color="blue.900"
              fontWeight={fontWeight}
              lineHeight={lineHeight}
            >
              <TagLabel>+ {remainingEscalations.length}</TagLabel>
            </Tag>
          }
        >
          <Container maxW={popoverContainerMaxWidth ?? 'md'}>
            {remainingEscalations.map((ep, i) => (
              <Link
                key={ep.ID}
                to={`/escalationPolicies#${ep.ID}`}
                style={disableLink ? { pointerEvents: 'none' } : {}}
              >
                <Text
                  color="blue.900"
                  fontSize={fontSize ?? 14}
                  fontWeight={fontWeight}
                  lineHeight={lineHeight}
                >
                  {ep.name}
                  {i < remainingEscalations.length - 1 && ','}{' '}
                </Text>
              </Link>
            ))}
          </Container>
        </NodePopover>
      )}
    </HStack>
  );
};

export default ScheduleEscalationPolicies;
