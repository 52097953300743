import React from 'react';
import { Row, SpinLoader } from '@squadcast/alchemy-ui/carbon';
import { EventModal } from '..';
import shiftCreateOrUpdate from './createUpdateForm';

function render(this: EventModal) {
  const { componentState } = this.state;
  return (
    <div onClick={this.openModalClick}>
      <div className="clearfix">
        <div className="float-left">
          <h1 className="modal-container-heading">
            {this.state.id === '' ? 'Create new' : 'Update'} on-call shift
          </h1>
        </div>
      </div>
      {componentState === 'busy' && (
        <div>
          <Row justifyContent="center" alignItems="center">
            <SpinLoader size="large" />
          </Row>
        </div>
      )}
      {componentState !== 'busy' && (
        <div>
          <div className="mt-20">{shiftCreateOrUpdate.call(this)}</div>
        </div>
      )}
    </div>
  );
}

export default render;
