import {
  ErrorBlock,
  Grid,
  Heading,
  InputBlock,
  Label,
  Para,
  PulseLoader,
  SpinLoader,
  TextButton,
  Theme,
} from 'uie/components';
import * as React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IUserInfo } from '../../../../../core/interfaces/IUserData';
import { dialCodes } from '../../../../../store/countryCodes';
import { AuthService } from '../../../../../core/services';
import { exception } from '../../../../../core/exception';
import ProfileService from '../../../../../core/services/service.editprofile';
import { API } from '../../../../../core/api';
import { requestOrganizationCurrentUser } from 'core/actions/organization/currentUser';

interface IProps extends Pick<IAppState, 'userInfo'> {
  hide: () => void;
  UserInfo: IUserInfo;
  requestOrganizationCurrentUser: () => void;
}

interface IState {
  activeScreen: 'sendingOtp' | 'enterOtp';
  dialCode: string;
  mobileNumber: string;
  sendingOTP: boolean;
  counter: number;
  intervalID: any;
  resendable: boolean;
  sendOTPError: string | undefined;
  OTP: string;
  verifyingOTP: boolean;
  verifyOTPError: string | undefined;
  resending: boolean;
}

class VerifyPhoneNumberModal extends React.Component<IProps, IState> {
  private _authService = new AuthService();
  private _profileService = new ProfileService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      activeScreen: 'sendingOtp',
      dialCode:
        this.props.UserInfo && this.props.UserInfo.contact
          ? this.props.UserInfo.contact.dial_code
          : dialCodes[0].dial_code,
      mobileNumber:
        this.props.UserInfo && this.props.UserInfo.contact
          ? this.props.UserInfo.contact.phone_number
          : '',
      sendingOTP: false,
      counter: 60,
      intervalID: null,
      resendable: false,
      sendOTPError: '',
      OTP: '',
      verifyingOTP: false,
      verifyOTPError: '',
      resending: false,
    };
    this.verifyOTP = this.verifyOTP.bind(this);
    this.tick = this.tick.bind(this);
    this.resendOTP = this.resendOTP.bind(this);
  }

  // Send Request to send OTP to the mentioned phone number
  public requestOTP = async () => {
    if (this.state.sendingOTP) return;
    this.setState({ sendOTPError: '' });

    try {
      await this._authService.sendOtp(API.config.organizationId);
      this.setState({ activeScreen: 'enterOtp', intervalID: setInterval(this.tick, 1000) });
    } catch (err: any) {
      this.setState({
        sendOTPError:
          err?.response?.data?.meta?.error_message ?? 'Error processing request, try again later.',
      });
      if ((err?.response?.data?.meta?.status ?? 0) !== 400)
        exception.handle('E_SEND_OTP_USER', err);
    } finally {
      this.setState({ sendingOTP: false });
    }
  };

  // Send Request to verify otp
  public verifyOTP = async () => {
    if (this.state.verifyingOTP) return;
    this.setState({ verifyOTPError: '' });
    if (this.state.OTP.length !== 6) {
      this.setState({ verifyOTPError: 'Enter six digits' });
      return;
    }
    this.setState({ verifyingOTP: true });

    try {
      await this._authService.verifyOtp(this.state.OTP);
      this.props.requestOrganizationCurrentUser();
      this.props.hide();
    } catch (err: any) {
      this.setState({
        verifyOTPError:
          err?.response?.data?.meta?.error_message ?? 'Error processing request, try again later.',
      });
      if ((err?.response?.data?.meta?.status ?? 0) !== 400)
        exception.handle('E_VERIFY_OTP_USER', err);
    } finally {
      this.setState({ verifyingOTP: false });
    }
  };

  public resendOTP = async () => {
    if (!this.state.resendable) return;
    this.setState({ resending: true });

    try {
      await this._authService.sendOtp(API.config.organizationId);
      this.setState({
        resendable: false,
        counter: 60,
      });
    } catch (err: any) {
      this.setState({
        verifyOTPError:
          err?.response?.data?.meta?.error_message ?? 'Error processing request, try again later.',
      });
      if ((err?.response?.data?.meta?.status ?? 0) !== 400)
        exception.handle('E_SEND_OTP_USER', err);
    } finally {
      this.setState({ resending: false });
    }
  };

  public getPhoneNumber = async () => {
    try {
      const res = await this._profileService.getUserInfo();
      this.setState({
        dialCode: res?.data?.data?.contact?.dial_code ?? this.state.dialCode,
        mobileNumber: res?.data?.data?.contact?.phone_number ?? this.state.mobileNumber,
      });
    } catch (err: any) {
      exception.handle('E_GET_USER_INFO', err);
    }
  };

  // Executes every second for countdown after sending otp
  public tick() {
    if (this.state.counter === 0) {
      this.setState({ resendable: true });
      return;
    }
    this.setState({
      counter: this.state.counter - 1,
    });
  }

  public handleOTPChange = (event: React.ChangeEvent<any>) => {
    if (!event.target.value.match(/^[0-9]*$/)) return;
    this.setState({ OTP: event.target.value });
  };

  public componentWillUnmount() {
    if (this.state.intervalID != null) {
      clearInterval(this.state.intervalID);
    }
  }

  public componentDidMount() {
    this.getPhoneNumber();
    this.requestOTP();
  }

  public render() {
    const { theme } = Theme;
    return (
      <div>
        {this.state.activeScreen === 'sendingOtp' ? (
          <div>
            <Heading fontSize={20} style={{ lineHeight: 1.2 }}>
              Verify Phone Number
            </Heading>
            {this.state.sendOTPError === '' ? (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: 150,
                  }}
                >
                  <SpinLoader base="64px" />
                </div>
                <Para style={{ textAlign: 'center' }}>Requesting OTP...</Para>
              </div>
            ) : (
              <Grid alignItems="center" justifyContent="space-between" style={{ marginTop: 40 }}>
                <ErrorBlock>{this.state.sendOTPError}</ErrorBlock>
                <TextButton buttonType="inverted" onClick={this.requestOTP}>
                  <Para color={theme.primary.default}>Retry</Para>
                </TextButton>
              </Grid>
            )}
          </div>
        ) : (
          <div>
            <Heading fontSize={20} style={{ lineHeight: 1.2 }}>
              Enter OTP
            </Heading>
            <Para style={{ marginTop: 20 }}>
              A six digit OTP has been sent to your phone:{' '}
              {this.state.dialCode + ' ' + this.state.mobileNumber}
            </Para>
            <div style={{ marginTop: 20 }}>
              <Label>Enter OTP</Label>
              <InputBlock
                value={this.state.OTP}
                maxLength={6}
                onChange={this.handleOTPChange}
                autoFocus={true}
                style={{
                  letterSpacing: 10,
                  width: 150,
                  marginTop: 10,
                }}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    this.verifyOTP();
                  }
                }}
              />
              <ErrorBlock>{this.state.verifyOTPError}</ErrorBlock>
            </div>

            {this.state.counter === 0 ? (
              <Grid alignItems="center" justifyContent="space-between">
                <Label>Did not receive OTP?</Label>
                <TextButton
                  style={{
                    color: theme.primary.default,
                    fontWeight: 600,
                  }}
                  buttonType="ghost"
                  disabled={!this.state.resendable}
                  onClick={this.resendOTP}
                >
                  {this.state.resending ? 'Sending ...' : 'Send Again'}
                </TextButton>
              </Grid>
            ) : (
              <Para color={theme.shades.grey}>
                {
                  // convert counter to time format
                  new Date(this.state.counter * 1000).toISOString().substr(14, 5)
                }
              </Para>
            )}
            <TextButton
              style={{
                width: '100%',
                minHeight: 40,
                marginTop: 20,
              }}
              onClick={this.verifyOTP}
              disabled={this.state.OTP.length !== 6}
            >
              {this.state.verifyingOTP ? (
                <PulseLoader color={theme.shades.white} />
              ) : (
                <Para fontSize={16} color={theme.shades.white}>
                  SUBMIT
                </Para>
              )}
            </TextButton>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state: IAppState) => ({
    userInfo: state.userInfo,
  }),
  {
    requestOrganizationCurrentUser,
  },
)(VerifyPhoneNumberModal);
