import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { MdBlock } from 'uie/components';
import { FileUploadFeature, OperationType } from 'core/services/service.fileUpload';
import { Field, FormikProvider, getIn, useFormikContext } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { fileUploadViewConfig } from 'views/config/fileUploadConfig';

import { FileUploadService } from '../../../../../../core/services';
import { reactQueryConfigSuccess } from '../../helpers/helper.toast';
import { useMessageTemplate } from '../../hooks/useMessageTemplate';
import { IMessageTemplate } from '../../Interface';

const { imageTexts, ...fileUploadConfig } = fileUploadViewConfig;

interface Props {
  pageId: number;
  isEdit: boolean;
  setIsEditFlow: Dispatch<SetStateAction<boolean>>;
  templates: IMessageTemplate[];
  closeDrawer: () => void;
  refetch: () => void;
  canEdit: boolean;
}

interface FormProps {
  onCancel: () => void;
  isUpdateTemplateProgress: boolean;
}

interface Context {
  template: IMessageTemplate[];
}

const UpdateForm = (props: FormProps) => {
  const fileUploadService = new FileUploadService();
  const { values, submitForm, setFieldValue } = useFormikContext<Context>();
  return (
    <form>
      <VStack px={0} py={3} alignItems="flex-start">
        {values.template.map((temp: IMessageTemplate, index: number) => {
          return (
            <Flex key={temp.id} direction="column" width={'100%'} pb={10}>
              <FormControl mb={4}>
                <FormLabel htmlFor="name">STEP 0{index + 1}</FormLabel>
                <Input value={values.template[index].name} readOnly />
              </FormControl>
              <Box mx={1}>
                <MdBlock
                  value={values.template[index].defaultMessage}
                  onChange={content => setFieldValue(`template[${index}].defaultMessage`, content)}
                  className="incident_details__runbook__addTask_modal"
                  options={{
                    maxHeight: '130px',
                    spellChecker: false,
                    imageUploadFunction: fileUploadService.getUploadFunctionForFeature(
                      FileUploadFeature.STATUS_PAGE,
                      OperationType.CREATE,
                    ),
                    imageTexts: {
                      sbInit: ``,
                      ...imageTexts,
                    },
                    ...fileUploadConfig,
                  }}
                />
              </Box>
            </Flex>
          );
        })}
      </VStack>
      <VStack
        alignItems="flex-start"
        pb={4}
        bottom={0}
        position="absolute"
        bgColor={'white'}
        w="100%"
        zIndex="2"
      >
        <Divider />
        <HStack pt={2} spacing={6}>
          <Button
            variant="default"
            onClick={submitForm}
            data-testid="save-form"
            isLoading={props.isUpdateTemplateProgress}
          >
            Save
          </Button>
          <Button variant="invertedDefault" onClick={() => props.onCancel()}>
            Cancel
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};

const UpdateMessageTemplate = ({
  pageId,
  isEdit,
  setIsEditFlow,
  templates,
  closeDrawer,
  refetch,
  canEdit,
}: Props) => {
  const { formik, isUpdateTemplateProgress } = useMessageTemplate(pageId, templates, () => {
    refetch();
    reactQueryConfigSuccess('Message template updated');
    closeDrawer();
  });

  return (
    <Box mb={10}>
      {isEdit ? (
        <Box data-testid="template-form">
          <FormikProvider value={formik}>
            <UpdateForm
              onCancel={() => setIsEditFlow(false)}
              isUpdateTemplateProgress={isUpdateTemplateProgress}
            />
          </FormikProvider>
        </Box>
      ) : (
        <Box data-testid="summary-info">
          <VStack px={0} py={3} alignItems="flex-start" display={'flex'}>
            {templates.map((template: IMessageTemplate, index: number) => {
              return (
                <Flex
                  key={template.id}
                  py={4}
                  borderBottomWidth={1}
                  data-testid="templates"
                  direction={'column'}
                  width={'100%'}
                >
                  <Flex flex={1} direction="column">
                    <Text color={'gray.500'} fontSize={14} mb={2} fontWeight="bold">
                      STEP 0{index + 1}
                    </Text>
                    <Flex flex={1} direction="row" align={'center'}>
                      <Box bgColor={template.color} w={3} h={3} borderRadius={6} mr={2} />
                      <Text fontSize={18}>{template.name}</Text>
                    </Flex>
                  </Flex>
                  <Text color={'gray.600'} fontSize={14} ml={5}>
                    {template.defaultMessage}
                  </Text>
                </Flex>
              );
            })}
          </VStack>
          {canEdit ? (
            <Box w="100%" bottom={0} position="fixed" bgColor={'white'} zIndex="2">
              <Divider />
              <HStack py={2} spacing={6}>
                <Button
                  variant="default"
                  onClick={() => setIsEditFlow(true)}
                  data-testid="edit-btn"
                >
                  Edit
                </Button>
              </HStack>
            </Box>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default UpdateMessageTemplate;
