import { Flex, useDisclosure } from '@chakra-ui/react';
import { API } from 'core';
import { T_WA_UP_GER_LIST_PAGE_VIEWED } from 'core/const/tracker';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { ListingButton } from 'library/atoms';
import { Loader, Placeholder, Table } from 'library/molecules';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Cell } from 'react-table';
import { AppTracker } from 'shared/analytics/tracker';
import { Tag } from 'library/atoms';
import { Wrap, WrapItem } from '@chakra-ui/react';

import { AppContext } from '..';
import { GERError } from '../components';
import { GERDelete } from '../delete';
import { invalidateGERList, useGerList } from '../query/useGerList';
import { ActionKind, GERContext } from '../store';
import { ALERT_INFO, GER_DATA } from '../types';
import { ADD_NEW_RULE_TITLE } from '../util';
import { getAlertsList, getGerEditPath } from '../util/functions';
import { GER_LIST_COLUMN_DEFS } from './column-defs';
import { useGerFilter } from './state';

type Props = {
  onCreate: () => void;
};

export const GERListing = ({ onCreate }: Props) => {
  const context = useContext(AppContext);
  const { gerFilterApply, removeGerFilter, removeGerFilterApply } = useGerFilter();
  const { state, dispatch } = useContext(GERContext);
  const queryClient = useQueryClient();
  const history = useHistory();

  useEffect(() => {
    AppTracker.track(T_WA_UP_GER_LIST_PAGE_VIEWED);
  }, []);

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [selectedId, setSelectedId] = useState('');

  const alertSrcList: ALERT_INFO[] = useMemo(() => {
    return getAlertsList(context?.integrations);
  }, [context?.integrations]);

  const handler = (page: number, limit: number) => {
    if (state.limit != limit) {
      dispatch({ type: ActionKind.SET_LIMIT, payload: { limit } });
    } else if (state.pageNumber != page) {
      dispatch({ type: ActionKind.SET_PAGE_INDEX, payload: { pageNumber: page } });
    }
  };

  const afterDelete = () => {
    onDeleteClose();
    invalidateGERList(queryClient);
  };

  // hover view elements
  const HoverView = ({ rowData }: { rowData: Partial<Cell<GER_DATA>> }) => {
    const gerId = rowData.row?.original.id ?? '';
    const onDelete = () => {
      setSelectedId(gerId);
      onDeleteOpen();
    };
    const onEdit = () => {
      history.push(getGerEditPath(gerId));
    };

    const canDelete = useUserAccess().hasDeleteAccess('ger', gerId);
    const canUpdate = useUserAccess().hasUpdateAccess('ger', gerId);

    return (
      <Flex gap={5} alignSelf="center" width="max-content">
        <ListingButton
          variant="secondary-danger"
          title="Delete"
          onClick={onDelete}
          isDisabled={!canDelete}
          isPermissionPresent={canDelete}
        />
        <ListingButton
          title="Edit"
          onClick={onEdit}
          isDisabled={!canUpdate}
          isPermissionPresent={canUpdate}
        />
      </Flex>
    );
  };

  const { isLoading, isFetching, isError, gerData, count } = useGerList(
    API.config.teamId,
    state.pageNumber,
    state.limit,
    state.search.term,
    gerFilterApply.map(data => data.value),
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <GERError />;
  }

  const removeFilter = (value: string) => {
    removeGerFilter(value);
    removeGerFilterApply(value);
  };

  return (
    <>
      <Wrap m={3}>
        {gerFilterApply.map(entity => {
          return (
            <WrapItem key={entity.label.toString()}>
              <Tag
                size="md"
                variant="filter"
                title={entity.label}
                onClose={() => removeFilter(entity.value)}
                username={entity.username}
              />
            </WrapItem>
          );
        })}
      </Wrap>
      {count && gerData ? (
        <>
          <Table
            hoverView={<HoverView rowData={{}} />}
            width="100%"
            columns={GER_LIST_COLUMN_DEFS(alertSrcList, context?.organization)}
            data={gerData}
            onPageChange={handler}
            headerStyles={{ padding: '14px 24px 14px 24px' }}
            paginationStyles={{
              position: 'sticky',
              bottom: '0px',
            }}
            isLoading={isLoading}
            totalCount={count}
            pageSize={state.limit}
            pageIndex={state.pageNumber}
          />
          <GERDelete
            isOpen={isDeleteOpen}
            onSuccess={afterDelete}
            onClose={onDeleteClose}
            id={selectedId}
          />
        </>
      ) : (
        <Placeholder
          onCreate={onCreate}
          iconName="global-event-rules.svg"
          addBtnTitle={state.permissions.canCreate ? ADD_NEW_RULE_TITLE : ''}
          description="No Ruleset"
        />
      )}
    </>
  );
};
