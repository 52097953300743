import { Para, Theme, ErrorBlock } from 'uie/components';
import { objectIsEmpty } from 'core';
import { ILog } from 'core/interfaces/IIncidents';
import { NewTabIcon } from 'icons';

const JiraMessages = ({ log }: { log: ILog }) => {
  let jiraMap: any = {};
  if (
    !log.action.toLocaleLowerCase().includes('wf_') &&
    log.additionalInfo &&
    (log.action.toLocaleLowerCase().includes('jira_') ||
      log.action.toLocaleLowerCase().includes('jira ticket created'))
  ) {
    jiraMap = log.additionalInfo.response.data ?? log.additionalInfo.response;
  }
  const { theme } = Theme;
  return (
    <>
      {!log.action.toLocaleLowerCase().includes('wf_') &&
        log.additionalInfo &&
        (log.action.toLocaleLowerCase().includes('jira_') ||
          log.action.toLocaleLowerCase().includes('jira ticket created')) && (
          <>
            <Para fontSize={14} color={theme.shades.cement}>
              {(jiraMap.errors && !objectIsEmpty(jiraMap.errors)) ||
              (jiraMap.error && !objectIsEmpty(jiraMap.error)) ? (
                <ErrorBlock fontSize={14}>
                  Failed to create jira issue:
                  <br />
                  {Object.values(jiraMap.errors ? jiraMap.errors : jiraMap.error).join('\n')}
                </ErrorBlock>
              ) : (
                <Para>
                  {!log.additionalInfo.isManual ? (
                    <span>
                      {' '}
                      A new ticket has been <span className="font-bold text-xs">
                        Auto Created
                      </span>{' '}
                      in Jira on{' '}
                      <span className="font-bold text-xs">{jiraMap.key.split('-')[0]}</span>{' '}
                    </span>
                  ) : (
                    <span>
                      {' '}
                      A new ticket has been Created on{' '}
                      <span className="font-bold">{jiraMap.key.split('-')[0]}</span>
                    </span>
                  )}
                  <a
                    className="installation-guide-design"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${jiraMap.self.split('/rest')[0]}/browse/${jiraMap.key}`}
                  >
                    {' '}
                    (#{jiraMap.key.split('-')[1]}){' '}
                  </a>
                  <a
                    className="installation-guide-design"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: 5 }}
                    href={`${jiraMap.self.split('/rest')[0]}/browse/${jiraMap.key}`}
                  >
                    <NewTabIcon />
                  </a>
                </Para>
              )}
            </Para>
          </>
        )}
    </>
  );
};

export default JiraMessages;
