import styled from 'styled-components';

interface IProps {
  height?: '24px' | 'string' | 'fit-content';
  width?: '40px' | 'fit-content';
  fontSize?: 16 | 14;
  fontWeight?: 300 | 500;
}

const NumberCount = styled.div<IProps>`
  height: ${props => props.height || 'fit-content'};
  color: ${props => props.theme.font.default};
  background-color: ${props => props.theme.shades.whiteSmoke};
  width: ${props => props.width || 'fit-content'};
  font-family: ${props => props.theme.fontFamily};
  font-weight: ${props => props.fontWeight || '400'};
  font-size: ${props => props.fontSize || '12px'};
  text-align: left;
  border-radius: 3px;
  padding: 4px 8px;
  margin: 0;
`;

/** @component */
export default NumberCount;
