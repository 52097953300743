import { Grid, Para, SpinLoader, TextButton, Theme } from 'uie/components';
import { EventWebhookHooksService, exception } from 'core';
import { IIncident } from 'core/interfaces/IIncidents';
import { useEffect, useState } from 'react';
import Select from 'react-select';

const { theme } = Theme;

interface Props {
  incident: IIncident;
  getIncidentDetails: () => void;
}

function ManualWebhookTriggers(props: Props) {
  const _eventWebHookService = new EventWebhookHooksService();
  const [manualWebhook, setManualWebhook] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [webhookOptions, setManualWebhookOptions] = useState<
    | {
        value: string;
        label: string;
      }[]
    | null
  >(null);

  const [webhookDisplayMsg, setWebhookDisplayMsg] = useState('');
  const [showTriggerButton, setShowTriggerButton] = useState(true);

  useEffect(() => {
    getManualWebhooks();
  }, []);

  const getManualWebhooks = async () => {
    const data = await _eventWebHookService.getManualWebhooksByTeam();
    const webhookValues = data.data.data.map(webhook => {
      return { value: webhook.id, label: webhook.name };
    });
    setManualWebhookOptions(webhookValues);
  };

  async function onTriggerWebhookManually() {
    if (!manualWebhook?.value) return;
    setShowTriggerButton(false);

    try {
      const result = await _eventWebHookService.triggerWebhookManually(
        props.incident.id,
        manualWebhook?.value,
      );

      if (result.status == 200) {
        setWebhookDisplayMsg('The Webhook is successfully triggered.');
      }
    } catch (e) {
      setWebhookDisplayMsg(exception.getErrorMsg(e));
    } finally {
      props.getIncidentDetails();
    }
  }

  if (!webhookOptions)
    return (
      <Grid justifyContent="space-around" width="100%">
        <SpinLoader />
      </Grid>
    );

  return (
    <div>
      {webhookOptions?.length > 0 ? (
        <div>
          <div>
            <Select
              options={webhookOptions || ([] as any)}
              onChange={option => {
                setManualWebhook(option);
              }}
              value={manualWebhook}
            />
          </div>
          {showTriggerButton && manualWebhook != null ? (
            <TextButton
              style={{ marginTop: '15px', marginLeft: '270px' }}
              color={theme.primary.default}
              onClick={onTriggerWebhookManually}
            >
              <Para style={{ color: 'white' }}>Trigger</Para>
            </TextButton>
          ) : (
            <></>
          )}
          {webhookDisplayMsg !== '' ? <Para fontSize={15}>{webhookDisplayMsg}</Para> : <></>}
        </div>
      ) : (
        <Para fontSize={15}>There are no configured webhooks for this team.</Para>
      )}
    </div>
  );
}

export default ManualWebhookTriggers;
