import type { ComponentStyleConfig } from '@chakra-ui/react';

export const ToastTheme: ComponentStyleConfig = {
  baseStyle: {
    cardStyle: {
      borderRadius: '6px',
      color: 'white',
      padding: '15px',
      justifyContent: 'space-between',
      width: '800px',
      fontSize: '18px',
      background: status,
    },
    textStyle: {
      color: 'brand.white',
      padding: '0 20px',
      margin: 0,
    },
    iconstyle: {
      width: '40px',
      height: '40px',
    },
    closeIconStyle: {
      height: '32px',
      width: '32px',
      borderRadius: '4.5px',
      cursor: 'pointer',
      alignSelf: 'center',
      _hover: {
        opacity: '79%',
      },
    },
    closeIconButton: {
      border: 'none',
      background: 'transparent',
      _hover: {
        border: 'none',
        background: 'transparent',
      },
    },
    success: {
      bg: 'brand.green',
    },
    error: {
      bg: 'brand.red',
    },
    warning: {
      bg: 'severity.2',
    },
    info: {
      bg: 'override.bg',
    },
  },
  sizes: {
    md: {
      cardStyle: {
        fontSize: '14px',
        padding: '12px',
      },
      textStyle: {
        padding: '0 15px',
      },
      iconStyle: {
        width: '30px',
        height: '30px',
      },
      closeIconStyle: {
        height: '24px',
        width: '24px',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
