import { IfilterDetails, IIssuefilterDetails } from '../Interface';

export const queryKeys = {
  GET_STATUS_PAGE_LIST: 'statusPageList',
  GET_STATUS_DETAILS: 'getStatusPageDetails',
  GET_COMPONENT_GROUP_LIST: 'componentList',
  GET_STATUS_TYPE_LIST: 'statusTypes',
  GET_PAGE_DETAILS: 'pageDetails',
  GET_ISSUE_LIST: 'issueList',
  GET_ISSUE_DETAILS: 'issueDetails',
  GET_SUBSCRIBERS_LIST: 'subscribers',
  GET_SUBSCRIBERS_SETTINGS: 'settings',
  GET_DOMAIN_CONFIG: 'configRecords',
  GET_MAINTENANCE_LIST: 'maintenance',
  GET_MAINTENANCE_DETAILS: 'maintenanceDetails',
};
export const SEND_EMAIL_ID = 'updates@status.squadcast.com';

export const reactQueryConfig = {
  enabled: true,
  retry: 1,
  staleTime: 0,
  keepPreviousData: true,
  cacheTime: 0,
  refetchOnWindowFocus: false,
};

export enum FILTER_TYPES {
  STATUS = 'status',
  PAGE_TYPE = 'show',
}

export const PAGES_STATUS_TYPES = {
  OPERATIONAL: 'operational',
  DEGRADED: 'degraded',
  MAINTENANCE: 'under-maintenance',
  MAJOR_OUTAGE: 'major-outage',
  PARTIAL_OUTAGE: 'partial-outage',
};

export const ISSUES_STATES = {
  INVESTIGATING: 'Investigating',
  RESOLVED: 'Resolved',
};

export const PAGE_STATUS_LABELS = {
  [PAGES_STATUS_TYPES.OPERATIONAL]: 'Operational',
  [PAGES_STATUS_TYPES.DEGRADED]: 'Degraded',
  [PAGES_STATUS_TYPES.MAINTENANCE]: 'Under Maintenance',
  [PAGES_STATUS_TYPES.MAJOR_OUTAGE]: 'Major Outage',
  [PAGES_STATUS_TYPES.PARTIAL_OUTAGE]: 'Partial Outage',
};

export const PAGE_STATUS = Object.values(PAGES_STATUS_TYPES).map(v => {
  return {
    value: v,
    label: PAGE_STATUS_LABELS[v],
  };
});

export const filterDetails: IfilterDetails = {
  [FILTER_TYPES.STATUS]: {
    label: 'Status Page Status',
    filterOptions: PAGE_STATUS,
  },
  [FILTER_TYPES.PAGE_TYPE]: {
    label: 'Visibility',
    filterOptions: [
      {
        value: 'public',
        label: 'Public',
      },
      {
        value: 'private',
        label: 'Private',
      },
    ],
  },
};

export enum ISSUE_FILTER_TYPES {
  ISSUE_STATUS = 'state',
  COMPONENT_STATUS = 'status',
  COMPONENT_GROUP = 'group',
  COMPONENT = 'component',
  TIME = 'time',
}

export enum ISSUE_DRAWER_TYPES {
  FILTER = 'Filter',
  ADD_ISSUE = 'Add Issue',
  EDIT_ISSUE = 'Update Issue',
  EDIT_ISSUE_DETAILS = 'Edit Issue Details',
}

export enum MAINTENANCE_DRAWER_TYPES {
  ADD = 'Add New Maintenance Mode',
  EDIT = 'Edit Maintenance Mode',
}

export const issueFilterDetails: IIssuefilterDetails = {
  [ISSUE_FILTER_TYPES.ISSUE_STATUS]: {
    label: 'Issue Status',
    filterOptions: [],
  },
  [ISSUE_FILTER_TYPES.COMPONENT_STATUS]: {
    label: 'Component Status',
    filterOptions: PAGE_STATUS,
  },
  [ISSUE_FILTER_TYPES.COMPONENT_GROUP]: {
    label: 'Component Group',
    filterOptions: [],
  },
  [ISSUE_FILTER_TYPES.COMPONENT]: {
    label: 'Component',
    filterOptions: [],
  },
  [ISSUE_FILTER_TYPES.TIME]: {
    label: 'Time',
    filterOptions: [
      {
        value: 'week',
        label: 'Last 7 days',
      },
      {
        value: 'month',
        label: 'Last 30 days',
      },
      {
        value: 'quarter',
        label: 'Last 90 days',
      },
    ],
  },
};

export enum SearchActionKind {
  SEARCH_ENABLED_OR_DISABLED = 'SEARCH_ENABLED_OR_DISABLED',
  SEARCHED = 'SEARCHED',
  PAGE_INDEX_CHANGE = 'PAGE_INDEX_CHANGE',
  PAGE_CHANGED = 'PAGE_CHANGED',
  PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED',
  TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED',
  OPEN_DRAWER = 'OPEN_DRAWER',
  CLOSE_DRAWER = 'CLOSE_DRAWER',
  APPLY_FILTER = 'APPLY_FILTER',
  SELECT_PAGE_DELETE = 'SELECT_PAGE_DELETE',
  REJECT_PAGE_DELETE = 'REJECT_PAGE_DELETE',
  INCREAMENT_MAINTENANCE_MONTH = 'INCREAMENT_MAINTENANCE_MONTH',
  DECREASE_MAINTENANCE_MONTH = 'DECREASE_MAINTENANCE_MONTH',
  RESET_MAINTENANCE_MONTH = 'RESET_MAINTENANCE_MONTH',
}

export enum ComponentActionType {
  INIT_ADD_COMPONENT,
  INIT_EDIT_COMPONENT,
  ADD_COMPONENT,
  INIT_COMPONENT_LIST,
  EDIT_COMPONENT,
  INIT_ADD_GROUP,
  INIT_EDIT_GROUP,
  SAVE_GROUP,
  DELETE_COMPONENT,
  DELETE_GROUP,
  CANCEL_GROUP_CHANGE,
}

export const MAINTENANCE_RANGE_OPTIONS = [
  { label: 'day', value: 'day' },
  { label: 'month', value: 'month' },
  { label: 'week', value: 'week' },
  { label: 'year', value: 'year' },
];

export const COMPONENT_TYPE = {
  COMPONENT: 'component',
  GROUP: 'group',
};

export const DETAILS_TABS = {
  ISSUES: 'issue',
  MAINTENANCE: 'maintenance',
  SUBSCRIBERS: 'subscribers',
};

export const DRAWER_TYPES = {
  CONFIG: 'config',
  THEME: 'theme',
  TEMPLATE: 'template',
  COMPONENTS: 'component',
  STATUS: 'status',
  SUBSCRIBER: 'subscriber',
  ADD_ISSUE: 'issue',
};

export const PAGE_LIST_DRAWER_TYPES = {
  FILTER: 'Filter',
  ADD_ISSUE: 'View Issues',
  ADD_MAINTENANCE: 'View Maintenace',
  STATUS: 'Update Status Manually',
};

export const DETAILS_MENU_ITEMS = {
  PAGE_CONFIGURATION: {
    id: DRAWER_TYPES.CONFIG,
    title: 'Page Configuration',
    heading: '',
  },
  EDIT_THEME: {
    id: DRAWER_TYPES.THEME,
    title: 'Edit Theme',
  },
  MESSAGE_TEMPLATE: {
    id: DRAWER_TYPES.TEMPLATE,
    title: 'Message Template',
  },
  PAGE_COMPONENTS: {
    id: DRAWER_TYPES.COMPONENTS,
    title: 'Page Components',
  },
  STATUS_MESSAGE: {
    id: DRAWER_TYPES.STATUS,
    title: 'Status Message',
  },
  SUBSCRIBER_SETTINGS: {
    id: DRAWER_TYPES.SUBSCRIBER,
    title: 'Subscriber Settings',
  },
};

export enum SUBSCRIPTION_STATUS_TYPE {
  VERIFIED = 'verified',
  NOT_VERIFIED = 'pending',
}

export const subscriptionStatus = {
  [SUBSCRIPTION_STATUS_TYPE.VERIFIED]: {
    color: 'green.400',
    text: 'Verified',
  },
  [SUBSCRIPTION_STATUS_TYPE.NOT_VERIFIED]: {
    color: 'orange.500',
    text: 'Not Verified',
  },
};

export enum THEME_TYPE {
  DEFAULT = '1',
  CUSTOM = '2',
}
