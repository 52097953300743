import { Box } from '@chakra-ui/react';
import { ACTION_TITLE } from '../../../constant';
import { useWorkflowFormContext } from '../../../context/workflowFormContext';
import { ActionTypeDetails, SubActionTypes } from '../../../types';
import IncidentNoteCard from '../squadcast/incident-note/IncidentNoteCard';
import ReassignIncidentCard from '../squadcast/reassign-incident/ReassignIncidentCard';
import CommunicationChannelCard from '../squadcast/communication-channel/CommunicationChannelCard';
import RunbookCard from '../squadcast/runbook/RunbookCard';
import SloAffectingCard from '../squadcast/slo-affected/SloAffectingCard';
import ActionCardContainer from './ActionCardContainer';
import ActionCardWrapper from './ActionCardWrapper';
import TriggeredWebhookCard from '../squadcast/trigger-webhook/TriggeredWebhookCard';
import HttpCallCard from '../squadcast/http-call/HttpCallCard';
import EmailCard from '../squadcast/send-email/EmailCard';
import MessageTeamChannelCard from '../microsoft/messageChannel/MessageTeamChannelCard';
import SlackCard from '../slack/channelCreate/SlackCard';
import MessageUserCard from '../slack/messageUser/MessageUserCard';
import MessageChannelCard from '../slack/messageChannel/MessageChannelCard';
import MessageTeamUserCard from '../microsoft/messageTeam/messageTeamUserCard';
import JiraTicketCard from '../jira/JiraTicketCard';
import PriorityCard from '../squadcast/priority/PriorityCard';
import StatusPageCard from '../squadcast/statuspage/StatusPageCard';
import { ACTION_ICONS_MAP } from 'library/atoms/ActionsStack/constant';

const ActionCard = ({ action }: { action: ActionTypeDetails }) => {
  switch (action.name) {
    case SubActionTypes.RUNBOOKS: {
      return (
        <ActionCardContainer
          title={`${ACTION_TITLE[action.name]}  (${action.data.runbooks.length})`}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <RunbookCard action={action} />
        </ActionCardContainer>
      );
    }
    case SubActionTypes.COMMUNICATION_CHANNEL:
      return (
        <ActionCardContainer
          title={`${ACTION_TITLE[action.name]} (${action.data.channels.length})`}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <CommunicationChannelCard action={action} />
        </ActionCardContainer>
      );
    case SubActionTypes.INCIDENT_NOTE:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <IncidentNoteCard action={action} />
        </ActionCardContainer>
      );
    case SubActionTypes.SLO_AFFECTED:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <SloAffectingCard action={action} />
        </ActionCardContainer>
      );
    case SubActionTypes.RESOLVE_INCIDENT:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <div />
        </ActionCardContainer>
      );
    case SubActionTypes.REASSIGN_INCIDENT:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <ReassignIncidentCard action={action} />
        </ActionCardContainer>
      );
    case SubActionTypes.TRIGGER_MANUAL_WEBHOOK:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <TriggeredWebhookCard action={action} />
        </ActionCardContainer>
      );
    case SubActionTypes.HTTP_CALL:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <HttpCallCard action={action} />
        </ActionCardContainer>
      );
    case SubActionTypes.SEND_EMAIL:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <EmailCard action={action} />
        </ActionCardContainer>
      );
    case SubActionTypes.PRIORITY:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <PriorityCard action={action} />
        </ActionCardContainer>
      );
    case SubActionTypes.STATUS_PAGE:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <StatusPageCard action={action} />
        </ActionCardContainer>
      );
    case SubActionTypes.MS_TEAMS_MESSAGE_CHANNEL:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <MessageTeamChannelCard action={action} />
        </ActionCardContainer>
      );
    case SubActionTypes.MS_TEAMS_MESSAGE_USER:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <MessageTeamUserCard action={action} />
        </ActionCardContainer>
      );
    case SubActionTypes.MS_TEAMS_CREATE_MEETING_LINK:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <div />
        </ActionCardContainer>
      );
    case SubActionTypes.SLACK_CHANNEL_ARCHIEVE:
      return (
        <ActionCardContainer
          icon={ACTION_ICONS_MAP['md'][action.name]}
          title={ACTION_TITLE[action.name]}
        >
          <div />
        </ActionCardContainer>
      );
    case SubActionTypes.SLACK_CHANNEL_CREATE:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <SlackCard action={action} />
        </ActionCardContainer>
      );
    case SubActionTypes.SLACK_MESSAGE_USER:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <MessageUserCard action={action} />
        </ActionCardContainer>
      );
    case SubActionTypes.SLACK_MESSAGE_CHANNEL:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <MessageChannelCard action={action} />
        </ActionCardContainer>
      );
    case SubActionTypes.JIRA_CREATE_TICKET:
      return (
        <ActionCardContainer
          title={ACTION_TITLE[action.name]}
          icon={ACTION_ICONS_MAP['md'][action.name]}
        >
          <JiraTicketCard action={action} />
        </ActionCardContainer>
      );
    default:
      const _exhaustiveCheck: never = action;
      return _exhaustiveCheck;
  }
};

const ActionList = () => {
  const { actions } = useWorkflowFormContext();
  return (
    <Box w="80%">
      {actions.map((action, index) => {
        return (
          <ActionCardWrapper
            key={`${action.name}${index}`}
            order={index}
            isFirstStep={index === 0}
            isLastStep={index === actions.length - 1}
            isEditHidden={
              action.name === SubActionTypes.RESOLVE_INCIDENT ||
              action.name === SubActionTypes.SLACK_CHANNEL_ARCHIEVE ||
              action.name === SubActionTypes.MS_TEAMS_CREATE_MEETING_LINK
            }
          >
            <ActionCard action={action} />
          </ActionCardWrapper>
        );
      })}
    </Box>
  );
};

export default ActionList;
