import { API } from 'core';
import {
  GetListOfSchedulesQuery,
  useGetListOfSchedulesQuery,
} from 'views/main/organization/schedules/graphql/query';
import React, { useEffect, useState } from 'react';
import { defaultReactQueryConfig } from '../../helpers/helpers.schedule';
import { ITeamsEntitiesProps } from 'views/main/organization/settings/teams/render/entities';

export function withListOfSchedules<T>(InputComponent: React.ComponentType<T>) {
  return (props: T) => {
    const [schedulesList, setScheduleslist] = useState<GetListOfSchedulesQuery>({ schedules: [] });

    const { data: allSchedules } = useGetListOfSchedulesQuery(
      {
        filters: {
          teamID: (props as unknown as ITeamsEntitiesProps).team?.id || API.config.teamId,
        },
      },
      defaultReactQueryConfig,
    );

    useEffect(() => {
      if (allSchedules) {
        setScheduleslist(allSchedules);
      }
    }, [allSchedules]);

    return <InputComponent {...props} schedulesList={schedulesList} />;
  };
}

export default withListOfSchedules;
