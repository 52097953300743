import * as React from 'react';
import { SVGProps } from 'react';

const SvgConvivaLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Conviva_Logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {'.Conviva_Logo-20x20_svg__st0{fill:#6f7273}.Conviva_Logo-20x20_svg__st1{fill:#85c300}'}
    </style>
    <path
      className="Conviva_Logo-20x20_svg__st0"
      d="M12.8 7.4c-.2-.3-.1-.6.2-.8L15.8 5c.1 0 .2-.2.1-.4l-.1-.1-5.5-3.2c-.2-.1-.4-.1-.6 0L4.2 4.5c-.2.1-.2.3-.1.4 0 .1 0 .1.1.1L7 6.7c.3.2.4.5.2.7-.2.3-.5.4-.8.2l-4-2.3c-.3-.2-.4-.5-.2-.8.1-.1.1-.2.2-.2L9.7.1c.2-.1.4-.1.6 0l7.3 4.2c.3.2.4.5.2.8-.1.1-.1.2-.2.2l-4 2.3c-.3.2-.6.1-.8-.2z"
    />
    <path
      className="Conviva_Logo-20x20_svg__st1"
      d="m15.9 13.1-5.1-2.9c-.2-.1-.3-.3-.3-.5V3.8c0-.3.3-.6.6-.6.1 0 .2 0 .3.1l1.8 1.1c.3.2.4.5.2.8-.2.3-.5.4-.8.2l-.5-.4c-.1-.1-.3 0-.4.1V9c0 .2.1.4.3.5l3.7 2.1v-1.2c0-.3.2-.6.6-.6.3 0 .6.2.6.6v2.1c0 .3-.2.6-.6.6-.2.1-.3 0-.4 0z"
    />
    <path
      className="Conviva_Logo-20x20_svg__st0"
      d="M10.6 19.4v-4.6c0-.3.2-.5.6-.5.3 0 .5.2.5.6V18c0 .2.1.3.3.3h.1l5.5-3.2c.2-.1.3-.3.3-.5V8.2c0-.2-.1-.3-.3-.3h-.1l-2.8 1.6c-.3.2-.6.1-.7-.2-.2-.3-.1-.6.2-.8l4-2.3c.3-.2.6-.1.8.2v8.8c0 .2-.1.4-.3.5l-7.3 4.2c-.3.2-.6.1-.8-.2v-.3z"
    />
    <path
      className="Conviva_Logo-20x20_svg__st1"
      d="M3.2 12.6v-2.1c0-.3.2-.6.6-.6.3 0 .5.2.5.6v1.2L8 9.5c.2-.1.3-.3.3-.5V5.2c0-.2-.1-.3-.3-.3l-.1.1-.6.3c-.3.2-.6.1-.8-.2-.1-.2-.1-.6.2-.7l1.8-1.1c.3-.2.6-.1.8.2.1.1.1.2.1.3v5.8c0 .2-.1.4-.3.5L4 13c-.3.2-.6.1-.8-.2v-.2z"
    />
    <path
      className="Conviva_Logo-20x20_svg__st0"
      d="m8.6 19.9-7.3-4.2c-.2-.1-.3-.3-.3-.5V6.8c0-.3.3-.6.6-.6.1 0 .2 0 .3.1l4 2.3c.3.2.4.5.2.8-.2.2-.5.3-.8.1L2.5 7.9c-.1 0-.3 0-.4.1v6.5c0 .2.1.4.3.5l5.5 3.2c.1.1.3 0 .4-.1V14.8c0-.3.2-.6.6-.6.3 0 .6.2.6.5v4.6c0 .3-.3.6-.6.6-.1.1-.3.1-.3 0z"
    />
    <path
      className="Conviva_Logo-20x20_svg__st1"
      d="M12.7 15.9c-.2-.3-.1-.6.2-.8l.6-.4c.1-.1.2-.3.1-.4l-.1-.1-3.3-1.9c-.2-.1-.4-.1-.6 0l-3.3 1.9c-.1.1-.2.3-.1.4l.1.1.7.5c.3.2.4.5.2.8-.2.3-.5.4-.8.2l-1.8-1.1c-.3-.2-.4-.5-.2-.8.1-.1.1-.2.2-.2l5.1-2.9c.2-.1.4-.1.6 0l5.1 2.9c.3.2.4.5.2.8-.1.1-.1.2-.2.2l-1.8 1.1c-.4.1-.7 0-.9-.3z"
    />
  </svg>
);

export default SvgConvivaLogo20X20;
