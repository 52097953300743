import { HelpCircleIcon } from 'icons';
import React, { CSSProperties, ReactNode } from 'react';

import { Grid, Para, Tooltip } from 'uie/components';
import styled from 'styled-components';

export const AnalyticsTooltip = styled(Tooltip)<{ style?: CSSProperties }>`
  & {
    right: 4px;
    white-space: pre-line;
  }
`;

type Props = {
  title: string;
  helpText?: string;
  children: ReactNode;
};

const PanelContainer: React.FC<Props> = ({ title, helpText, children }) => {
  return (
    <Grid className="analytics__panel--container" type="column" height="inherit">
      <Grid type="column" height="inherit" style={{ margin: 12 }}>
        <Grid type="row" style={{ justifyContent: 'space-between' }}>
          <Para fontSize={20} style={{ paddingBottom: 10 }}>
            {title}
          </Para>
          {helpText ? (
            <AnalyticsTooltip label={helpText} offset={{ top: '-70px' }}>
              <HelpCircleIcon width={18} height={18} />
            </AnalyticsTooltip>
          ) : null}
        </Grid>
        {children}
      </Grid>
    </Grid>
  );
};

export default PanelContainer;
