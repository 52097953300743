import { Grid, GridItem, Text, VStack } from '@chakra-ui/react';
import { CloseButton } from 'library/atoms';
import { ColorSelection } from 'library/molecules';
import { TERTIARY_COLORS } from 'library/theme/colors';
import { Fragment, memo, useEffect, useState } from 'react';

import { TagDropdown } from './TagDropdown';

export type Tags = {
  key: string;
  value: string;
  color: string;
};

export const TagGroup = memo(
  ({
    tags,
    allTags,
    getUpdatedTags,
    showError,
  }: {
    tags: Tags[];
    allTags:
      | ({
          key: string;
          values: string[];
        } | null)[]
      | undefined;
    getUpdatedTags: (tags: Tags[]) => void;
    showError: string | undefined;
  }) => {
    const [incidentTags, setIncidentTags] = useState<Tags[]>(tags);
    const [keys, setKeys] = useState<string[]>([]);
    const [keyValue, setKeyValue] = useState<Record<string, string[]>>({});

    useEffect(() => {
      if (allTags?.length) {
        const keyOptions: typeof keys = [];
        const valuesMap: typeof keyValue = {};

        allTags.forEach(tag => {
          if (tag) {
            keyOptions.push(tag.key);
            valuesMap[tag.key] = tag.values;
          }
        });
        setKeys(keyOptions);
        setKeyValue(valuesMap);
      }
    }, [allTags]);

    const addTag = () => {
      const newTag: Tags = {
        key: '',
        value: '',
        color: TERTIARY_COLORS[0],
      };

      setIncidentTags([...incidentTags, newTag]);
      getUpdatedTags([...incidentTags, newTag]);
    };

    const deleteTag = (inputIndex: number) => () => {
      const updatedTags = incidentTags.filter((_value, index) => {
        return index != inputIndex;
      });
      setIncidentTags(updatedTags);
      getUpdatedTags(updatedTags);
    };

    const keyValueUpdate = (index: number) => (key: string, value: string) => {
      const updatedTags = [...incidentTags];
      updatedTags[index].key = key;
      updatedTags[index].value = value;
      setIncidentTags(updatedTags);
      getUpdatedTags(updatedTags);
    };

    const colorChange = (index: number) => (selected: string) => {
      if (selected) {
        const updatedTags = [...incidentTags];
        updatedTags[index].color = selected;
        setIncidentTags(updatedTags);
        getUpdatedTags(updatedTags);
      }
    };

    return (
      <VStack alignItems="flex-start" rowGap={2}>
        <Grid templateColumns="1fr 0.4fr 1fr" gap={6} alignItems="center">
          {incidentTags.map((tag, index) => (
            <Fragment key={`${tag.key} > ${tag.value} > ${tag.color} > ${index}`}>
              <GridItem>
                <TagDropdown
                  key={index}
                  value={tag}
                  keysOptions={keys}
                  valuesMap={keyValue}
                  handleChange={keyValueUpdate(index)}
                />
              </GridItem>
              <GridItem>
                <ColorSelection
                  options={TERTIARY_COLORS}
                  colorValue={tag.color}
                  onChange={(color: string) => {
                    colorChange(index)(color);
                  }}
                />
              </GridItem>
              <GridItem>
                <CloseButton onClick={deleteTag(index)} />
              </GridItem>
            </Fragment>
          ))}
        </Grid>
        <VStack alignItems="flex-start" mt={2} rowGap={2}>
          <Text color="brand.blue" variant="formInput_800" cursor="pointer" onClick={addTag}>
            Add Tag
          </Text>
          {showError && (
            <Text variant="body" color="brand.red">
              {showError}
            </Text>
          )}
        </VStack>
      </VStack>
    );
  },
);
