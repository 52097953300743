import * as React from 'react';
import { SVGProps } from 'react';

const SvgSlackLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Slack_Logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.Slack_Logo-20x20_svg__st0{fill:#e01e5a}.Slack_Logo-20x20_svg__st1{fill:#36c5f0}.Slack_Logo-20x20_svg__st2{fill:#2eb67d}.Slack_Logo-20x20_svg__st3{fill:#ecb22e}'
      }
    </style>
    <path
      className="Slack_Logo-20x20_svg__st0"
      d="M4.216 12.641c0 1.161-.939 2.1-2.1 2.1s-2.1-.939-2.1-2.1.939-2.1 2.1-2.1h2.1v2.1zM5.267 12.641c0-1.161.939-2.1 2.1-2.1s2.1.939 2.1 2.1v5.251c0 1.161-.939 2.1-2.1 2.1s-2.1-.939-2.1-2.1v-5.251z"
    />
    <path
      className="Slack_Logo-20x20_svg__st1"
      d="M7.367 4.208c-1.161 0-2.1-.939-2.1-2.1s.939-2.1 2.1-2.1 2.1.939 2.1 2.1v2.1h-2.1zM7.367 5.274c1.161 0 2.1.939 2.1 2.1s-.939 2.1-2.1 2.1H2.1c-1.161 0-2.1-.939-2.1-2.1s.939-2.1 2.1-2.1h5.267z"
    />
    <path
      className="Slack_Logo-20x20_svg__st2"
      d="M15.784 7.375c0-1.161.939-2.1 2.1-2.1s2.1.939 2.1 2.1-.939 2.1-2.1 2.1h-2.1v-2.1zM14.733 7.375c0 1.161-.939 2.1-2.1 2.1s-2.1-.939-2.1-2.1V2.108c0-1.161.939-2.1 2.1-2.1s2.1.939 2.1 2.1v5.267z"
    />
    <path
      className="Slack_Logo-20x20_svg__st3"
      d="M12.633 15.792c1.161 0 2.1.939 2.1 2.1s-.939 2.1-2.1 2.1-2.1-.939-2.1-2.1v-2.1h2.1zM12.633 14.741c-1.161 0-2.1-.939-2.1-2.1s.939-2.1 2.1-2.1H17.9c1.161 0 2.1.939 2.1 2.1s-.939 2.1-2.1 2.1h-5.267z"
    />
  </svg>
);

export default SvgSlackLogo20X20;
