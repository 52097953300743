import { BoxProps, Divider, Stack, Text, useBreakpointValue } from '@chakra-ui/react';

import { StepCircle } from './StepCircle';

interface StepProps extends BoxProps {
  title: string;
  description: string;
  isCompleted: boolean;
  isActive: boolean;
  isLastStep: boolean;
  isFirstStep: boolean;
  stepNumber: number;
}

export const Step = (props: StepProps) => {
  const { isActive, isCompleted, isLastStep, isFirstStep, title, description, ...stackProps } =
    props;
  const isMobile = useBreakpointValue({ base: true, md: false });

  const orientation = useBreakpointValue<'horizontal' | 'vertical'>({
    base: 'vertical',
    md: 'horizontal',
  });

  return (
    <Stack spacing="5" direction={{ base: 'row', md: 'column' }} flex="3" {...stackProps}>
      <Stack
        spacing="0.5"
        pb={isMobile && !isLastStep ? '8' : '0'}
        align={{ base: 'start', md: 'center' }}
      >
        <Text
          color="emphasized"
          fontWeight="medium"
          alignSelf={props.isFirstStep ? 'flex-start' : props.isLastStep ? 'flex-end' : 'center'}
        >
          {title}
        </Text>
      </Stack>
      <Stack spacing="0" align="center" direction={{ base: 'column', md: 'row' }}>
        {!isFirstStep ? (
          <Divider
            width="100%"
            display={isMobile ? 'none' : 'initial'}
            borderColor={isFirstStep ? 'transparent' : 'inherit'}
            orientation={orientation}
            borderWidth="1px"
          />
        ) : null}
        <StepCircle isActive={isActive} isCompleted={isCompleted} stepNumber={props.stepNumber} />

        {!isLastStep ? (
          <Divider
            width="100%"
            orientation={orientation}
            borderWidth="1px"
            borderColor={isLastStep ? 'transparent' : 'inherit'}
          />
        ) : null}
      </Stack>
    </Stack>
  );
};
