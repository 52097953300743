import * as React from 'react';
import { SVGProps } from 'react';

const SvgCronitorLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M0 0h20v20H0V0zm14 5.8c-1.8-1.7-5.5-2.1-7.8.6-1.9 2.2-1.8 5.8.6 7.9 2.4 2 6 1.5 7.6-.5-.3-.3-.6-.6-.9-.8-.3-.3-.6-.6-.9-.8-1.4 1.4-3.3 1.2-4.4.2-1.1-1-1.3-2.9-.3-4.2 1-1.2 2.7-1.7 4.5-.5.5-.7 1.1-1.3 1.6-1.9zM8.9 10c0 .9.7 1.6 1.6 1.6.8 0 1.6-.7 1.6-1.6 0-.8-.7-1.6-1.6-1.6-.9 0-1.6.7-1.6 1.6z"
      style={{
        fill: '#0b0021',
      }}
    />
    <path
      d="M14 5.8c-.5.6-1 1.2-1.6 1.8-1.7-1.2-3.5-.7-4.5.5-1 1.3-.8 3.2.3 4.2s3 1.2 4.4-.2c.3.3.6.6.9.8.3.3.6.6.9.8-1.6 1.9-5.2 2.5-7.6.5-2.5-2.1-2.6-5.7-.6-7.9 2.3-2.6 6-2.2 7.8-.5z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M8.9 10c0-.9.7-1.6 1.6-1.5.8 0 1.6.7 1.6 1.6 0 .8-.7 1.6-1.6 1.6-.9-.1-1.6-.8-1.6-1.7z"
      style={{
        fill: '#81d7a0',
      }}
    />
  </svg>
);

export default SvgCronitorLogo20X20;
