import { EntityRecord } from './types';
import { Link } from 'react-router-dom';

interface LinkProps {
  href: string;
  children: React.ReactNode;
  entities: EntityRecord;
  onCallUsers: Set<string>;
}

const linkStyles = { textDecoration: 'none', borderBottom: 'none' };

const MdLink = (props: LinkProps) => {
  // regex to match user link pattern
  if (props.href.match(/^\/user\/\w{24}$/gim)) {
    const userId = props.href.replace('/user/', '');
    const name = props.entities.users[userId]?.name ?? 'Deleted User';
    const isOnCall = props.onCallUsers.has(userId);
    return (
      <Link to={props.href} className="response-message-tagged-user" style={linkStyles}>
        @{name} {isOnCall && <div className="response-message-user-on-call" />}
      </Link>
    );
  }
  // regex to match squad link pattern
  if (props.href.match(/^\/settings\/teams\/\w{24}\/squads\?name=/gim)) {
    const queryString = props.href.split('?')[1];
    const queryParams = new URLSearchParams(queryString);
    const name = queryParams.get('name');
    return (
      <Link to={props.href} className="response-message-tagged-user" style={linkStyles}>
        @{name}
      </Link>
    );
  }
  // regex to match user team pattern
  if (props.href.match(/^\/settings\/teams\/\w{24}$/gim)) {
    const teamId = props.href.replace('/settings/teams/', '');
    const name = props.entities.teams[teamId]?.name ?? 'Deleted Team';
    return (
      <Link to={props.href} className="response-message-tagged-user" style={linkStyles}>
        @{name}
      </Link>
    );
  }

  return (
    <a
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
    >
      {props.children}
    </a>
  );
};

export default MdLink;
