import * as React from 'react';
import { connect } from 'react-redux';
import { TextButton } from '@squadcast/alchemy-ui/metal';
import { dialCodes } from '../../../../../store/countryCodes';
import { isEmailValid, getProperty } from '../../../../../core/helpers';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { requestUserAccountUpdate, updateUserTimeZone } from '../../../../../core/actions/account';

import { IAccountUserUpdateRequest } from '../../../../../core/interfaces/IAccount';
import { IUserInfo } from '../../../../../core/interfaces/IUserData';
import ProfileService from '../../../../../core/services/service.editprofile';
import { ErrorBlock, FocusBlock, Para, SelectBox } from 'uie/components';
import { exception } from '../../../../../core/exception';
import { matchSorter } from 'match-sorter';
import momentTimeZone from 'moment-timezone';
import { appDateFormats, Locale } from 'core/helpers/dateUtils';
import { DateTime } from 'luxon';
import { broadcastTimezoneChanged } from 'core/broadcast/timezoneBroadcastChannel';
import { requestOrganizationCurrentUser } from 'core';
import { filterManager } from '../../incident-list/filters/manager';
import { THEME_COLORS } from 'library/theme/colors';

interface IProps extends Pick<IAppState, 'userInfo' | 'organization'> {
  hide: () => void;
  editUser: IUserInfo;
  requestUserAccountUpdate: (data: IAccountUserUpdateRequest) => Record<string, any>;
  requestOrganizationCurrentUser: typeof requestOrganizationCurrentUser;
  updateUserTimeZone: typeof updateUserTimeZone;
}
interface IState {
  saving: boolean;
  firstName: string;
  firstNameError: string;
  lastName: string;
  lastNameError: string;
  emailId: string;
  emailIdError: string;
  timezone: string;
  timezoneError: string;
  dateFormat: string;
  dateFormatError: string;
  error: string;
  dialCode: string;
  mobileNumber: string;
  mobileNumberError: string;
  showCountryDropDown: boolean;
  countryCodeSearch: string;
}

class EditUserModal extends React.Component<IProps, IState> {
  private _ProfileService = new ProfileService();
  private _dateFormats = appDateFormats.map(format => ({
    name: format,
    value: format,
  }));
  private _timezones = momentTimeZone.tz.names()?.map(timezone => ({
    name: `(${DateTime.local().setZone(timezone).toFormat('ZZ')}
    ${DateTime.local().setZone(timezone).toFormat('ZZZZ')})
    ${timezone.replace(/_/g, ' ')}`,
    value: timezone,
  }));

  constructor(props: IProps) {
    super(props);
    this.state = {
      saving: false,
      firstName: this.props.editUser.first_name as string,
      firstNameError: '',
      lastName: this.props.editUser.last_name as string,
      lastNameError: '',
      emailId: this.props.editUser.email as string,
      emailIdError: '',
      timezone: (this.props.editUser.time_zone as string) || '',
      timezoneError: '',
      dateFormat: this.props.editUser.date_format || this._dateFormats[0].value,
      dateFormatError: '',
      dialCode:
        this.props.editUser && this.props.editUser.contact
          ? this.props.editUser.contact.dial_code
          : dialCodes[0].dial_code,
      mobileNumber:
        this.props.editUser && this.props.editUser.contact
          ? this.props.editUser.contact.phone_number
          : '',
      mobileNumberError: '',
      showCountryDropDown: false,
      countryCodeSearch: '',
      error: '',
    };
    this.save = this.save.bind(this);
  }
  public async save() {
    if (this.state.firstName.trim() === '') {
      this.setState({
        firstNameError: 'Enter first name',
      });
      return;
    }
    if (!isEmailValid(this.state.emailId.trim()) || this.state.emailId.trim() === '') {
      this.setState({
        emailIdError: 'Enter a valid email id',
      });
      return;
    }
    this.setState({
      saving: true,
    });
    try {
      await this._ProfileService.update({
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        time_zone: this.state.timezone,
        date_format: this.state.dateFormat,
      });
      Locale.userZone = this.state.timezone;
      Locale.dateFormat = this.state.dateFormat;
      this.props.requestOrganizationCurrentUser();
      this.props.updateUserTimeZone(this.state.timezone);
      broadcastTimezoneChanged();
      filterManager.rebuildDateFilters();
    } catch (err: any) {
      this.setState({
        error:
          err?.response?.data?.meta?.error_message ?? 'Error processing request, try again later.',
      });
      exception.handle('E_UPDATE_USER', err);
    } finally {
      this.setState({ saving: false });
      this.props.hide();
    }
  }

  public render() {
    const org = this.props.organization.currentOrg.o;

    return (
      <div
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <div className="clearfix">
          <div className="float-left">
            <h1 className="modal-container-heading">Edit Details</h1>
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div className="clearfix">
            <div className="w-1-1">
              <p className="modal-container-sub-heading" style={{ margin: 0, marginBottom: 10 }}>
                First Name
              </p>
              <input
                id="first-name"
                type="text"
                onFocus={() => {
                  this.setState({ firstNameError: '' });
                }}
                className={'input-design' + (this.state.firstNameError ? 'text-field-border' : '')}
                placeholder="Enter First Name"
                value={this.state.firstName}
                onChange={event => {
                  this.setState({ firstName: event.target.value });
                }}
              />
              <ErrorBlock>{this.state.firstNameError}</ErrorBlock>
            </div>
            <div className="w-1-1">
              <p className="modal-container-sub-heading" style={{ margin: 0, marginBottom: 10 }}>
                Last Name
              </p>
              <input
                id="last-name"
                type="text"
                onFocus={() => {
                  this.setState({ lastNameError: '' });
                }}
                className={'input-design ' + (this.state.lastNameError ? 'text-field-border' : '')}
                placeholder="Enter Last Name"
                value={this.state.lastName}
                onChange={event => {
                  this.setState({ lastName: event.target.value });
                }}
              />
              <ErrorBlock>{this.state.lastNameError}</ErrorBlock>
            </div>
          </div>
          <div className="clearfix">
            <div className=" float-left" style={{ width: '100%' }}>
              <p className="modal-container-sub-heading" style={{ margin: 0, marginBottom: 10 }}>
                Email Id
              </p>
              <div className="input-disabled">{this.state.emailId}</div>
              <span style={{ color: THEME_COLORS.secondary[1200], fontSize: 12 }}>
                Your username will be similar to the prefix of your email address
              </span>
            </div>
          </div>

          <div className="w-1-1">
            <p
              className="modal-container-sub-heading"
              style={{ margin: 0, marginTop: 16, marginBottom: 10 }}
            >
              Timezone
            </p>
            <SelectInput
              placeholder="Timezone"
              options={this._timezones}
              defaultSelected={this.state.timezone}
              onSelect={timezone => this.setState({ timezone })}
            />
          </div>

          <div className="w-1-1">
            <p
              className="modal-container-sub-heading"
              style={{ margin: 0, marginTop: 16, marginBottom: 10 }}
            >
              Date Format
            </p>
            <SelectInput
              placeholder="Date Format"
              options={this._dateFormats}
              defaultSelected={this.state.dateFormat}
              onSelect={format => this.setState({ dateFormat: format })}
            />
          </div>
        </div>
        <ErrorBlock>{this.state.error}</ErrorBlock>
        <div style={{ marginTop: 20 }}>
          <TextButton
            id="save_profile"
            loading={this.state.saving}
            text="Save"
            onClick={this.save}
          />
        </div>
      </div>
    );
  }
}
export default connect(
  (state: IAppState) => ({
    userInfo: state.userInfo,
    organization: state.organization,
  }),
  {
    requestUserAccountUpdate,
    requestOrganizationCurrentUser,
    updateUserTimeZone,
  },
)(EditUserModal);

interface SelectInputProps {
  placeholder: string;
  options: { name: string; value: string }[];
  onSelect: (value: string) => void;
  defaultSelected: string;
}

const SelectInput: React.FC<SelectInputProps> = ({
  placeholder,
  options,
  onSelect,
  defaultSelected,
}) => {
  const [searchText, setSearchText] = React.useState('');
  const [selected, setSelected] = React.useState('');

  const filteredOptions = React.useMemo(
    () => matchSorter(options, searchText, { keys: ['name', 'value'] }),
    [options, searchText],
  );

  const onSelectValue = (_: any, value: string) => {
    setSelected(value);
    onSelect(value);
  };

  React.useEffect(() => {
    if (defaultSelected) {
      setSelected(defaultSelected);
    } else {
      setSelected('');
    }
  }, [defaultSelected]);

  return (
    <SelectBox
      hook={
        <Para fontSize={14} style={{ textAlign: 'left' }} fontWeight={300}>
          {filteredOptions.find(option => option.value === selected)?.name || placeholder}
        </Para>
      }
      onValueChange={onSelectValue}
      height="auto"
      width="100%"
      searchHookProps={{
        value: searchText,
        height: '24px',
        fontSize: '16px',
        onChange: ({ target: { value } }) => setSearchText(value),
      }}
    >
      {filteredOptions.map(option => (
        <FocusBlock
          key={option.value}
          value={option.value}
          isSelected={selected === option.value}
          style={{ height: '45px' }}
        >
          <Para fontSize={12} style={{ textAlign: 'left' }}>
            {option.name}
          </Para>
        </FocusBlock>
      ))}
    </SelectBox>
  );
};
