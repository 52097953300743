import {
  ChakraProvider,
  extendTheme,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

const theme = extendTheme({
  components: {
    Modal: {
      baseStyle: () => ({
        dialog: {
          maxWidth: ['95%', '95%', '95%'],
          maxHeight: ['95%', '95%', '95%'],
          overflow: 'auto',
        },
      }),
    },
  },
});

export const ImagePreview = ({
  src,
  onClose,
  alt,
  applyChakraStyle,
}: {
  src: string;
  onClose: () => void;
  alt?: string;
  /** Pass false to disable ChakraProvider global styles */
  applyChakraStyle?: boolean;
}) => {
  return (
    <>
      {!!src && (
        <ChakraProvider resetCSS={applyChakraStyle} theme={theme}>
          <Modal isCentered isOpen={!!src} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{alt ?? ''}</ModalHeader>
              <ModalCloseButton
                tabIndex={-1}
                sx={
                  applyChakraStyle ? {} : { bg: 'transparent', borderWidth: 0, cursor: 'pointer' }
                }
              />
              <ModalBody display="flex" justifyContent="center">
                <Image objectFit="contain" src={src} alt={alt} />
              </ModalBody>
            </ModalContent>
          </Modal>
        </ChakraProvider>
      )}
    </>
  );
};
