import DrawerFooterWrapper from 'library/molecules/Drawer';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { messageSlackChannelValidation } from 'views/main/organization/workflow/constant/validation';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { SlackMessageAction } from 'views/main/organization/workflow/types';
import { FormFooter } from '../../squadcast/form-footer';
import MessageChannelForm from './MessageChannelForm';

const MessageChannelContainer = () => {
  const { actionForm, onActionSubmit, checkDirtyAndCloseDrawer, setIsDirty } =
    useWorkflowFormContext();
  const { formState } = useFormContext<SlackMessageAction>();
  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty]);
  return (
    <FormWrapper<SlackMessageAction>
      onSubmit={onActionSubmit}
      defaultValues={actionForm! as SlackMessageAction}
      validationSchema={messageSlackChannelValidation}
    >
      <MessageChannelForm />
      <DrawerFooterWrapper>
        <FormFooter<SlackMessageAction> checkDirtyAndCloseDrawer={checkDirtyAndCloseDrawer} />
      </DrawerFooterWrapper>
    </FormWrapper>
  );
};

export default MessageChannelContainer;
