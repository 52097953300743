import {
  DialogModalFrame,
  ErrorBlock,
  FocusBlock,
  Grid,
  Heading,
  IconButton,
  Para,
  SpinLoader,
  TextButton,
  Theme,
  Tooltip,
} from 'uie/components';
import { Locale } from 'core/helpers/dateUtils';
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  DownloadIcon,
  NotificationGreyEmail,
  NotificationGreyPhone,
  NotificationGreyPush,
  NotificationGreySms,
  Refresh,
} from 'icons';
import moment from 'moment';
import React, { FC, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { exception } from '../../../../../../core/exception';
import { IAppState } from '../../../../../../core/interfaces/IAppState';
import { INotificationLog } from '../../../../../../core/interfaces/IIncidents';
import IncidentNotificationLogsService from '../../../../../../core/services/Incident/service.notificationLogs';
import useShowToast from '../actions/communicationCard/hooks/useShowToast';
import { Multiselect } from './multi-select';
import {
  CAUSE_FILTER_OPTIONS,
  EMPTY_FILTER_STATE,
  FILTER_TYPE,
  NOTIFICATION_CAUSE,
  SELECT_BOX_OPTIONS,
  STATUS_FILTER_OPTIONS,
  TYPE_FILTER_OPTIONS,
} from './notification-helper';

interface IProps extends Pick<IAppState, 'organization'> {
  incidentId: string;
}

const { theme } = Theme;
const EXPORT_LOGS_ID = 'notification-logs-export';

const IncidentNotificationLogs = ({ incidentId, ...props }: IProps) => {
  const _notifLogsService = new IncidentNotificationLogsService();

  const [openLogs, setOpenLogs] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [pageNo, setPageNo] = useState<number>(0);
  const [filter, setFilter] = useState(EMPTY_FILTER_STATE);
  const [filteredLogs, setFilteredLogs] = useState<INotificationLog[]>([]);
  const [notificationLogs, setNotificationLogs] = useState<INotificationLog[]>([]);

  const { showErrorToast } = useShowToast();

  const logsPerPage = 12;
  const channelImages = {
    push: <NotificationGreyPush height={15} width={15} />,
    sms: <NotificationGreySms height={15} width={15} />,
    phone: <NotificationGreyPhone height={15} width={15} />,
    email: <NotificationGreyEmail height={15} width={15} />,
  };

  const openLogsModal = async () => {
    setOpenLogs(true);
    if (notificationLogs.length === 0 || errorMsg !== '') {
      getNotificationLogsData();
    }
  };

  const closeLogsModal = () => {
    setOpenLogs(false);
  };

  const getNotificationLogsData = async () => {
    if (loading) return;
    setLoading(true);
    setErrorMsg('');
    try {
      const response = await _notifLogsService.getLogs(incidentId);
      const logs: INotificationLog[] = (response?.data?.data ?? [])
        .map(log => {
          // find user to get name
          let user;

          if (log.email) {
            user = props.organization.users.u.find(user => user.email === log.email);
          }
          if (!user && log.user_id) {
            user = props.organization.users.u.find(user => user.id === log.user_id);
          }
          log.first_name = user?.first_name ?? 'Deleted User';
          log.last_name = user?.last_name ?? '';
          return log;
        })
        .sort((a, b) =>
          // sort based on time of notification
          moment.utc(a.time_of_notification).diff(moment.utc(b.time_of_notification)),
        );

      setNotificationLogs(logs);
      setFilteredLogs(logs);
    } catch (err: any) {
      setErrorMsg(exception.handle('E_GET_NOTIFICATION_LOGS', err));
    } finally {
      setLoading(false);
    }
  };

  const prevPage = () => {
    if (pageNo > 0) {
      setPageNo(pageNo - 1);
    }
  };

  const nextPage = () => {
    if ((pageNo + 1) * logsPerPage < filteredLogs.length) {
      setPageNo(pageNo + 1);
    }
  };

  const getColorForStatus = (status: INotificationLog['status']) => {
    if (status === 'delivered') return theme.success.default;
    if (status === 'failed') return theme.danger.default;
    if (status === 'dispatched' || status === 'sent') return theme.acknowledge.default;
    return theme.shades.grey;
  };

  // format date
  const getTimeAsString = (time: Date) => {
    if (!time) {
      return '';
    }

    // show only time if its today
    if (moment(time).isSame(new Date(), 'day')) return Locale.toSimpleTime(time);
    // show as yesterday if its the next day
    if (moment(time).isSame(moment().subtract(1, 'days').startOf('day'), 'day'))
      return `Yesterday, ${Locale.toSimpleTime(time)}`;
    // default format otherwise
    return Locale.toShortDateTimeWoYear(time);
  };

  const downloadReport = async () => {
    setIsDownloading(true);
    try {
      let queryStr = '';
      if (filter.cause.length) {
        queryStr = `causes=${JSON.stringify(filter.cause.map(item => item.value))}`;
      }
      if (filter.type.length) {
        queryStr = `${queryStr ? queryStr + '&' : ''}types=${JSON.stringify(
          filter.type.map(item => item.value),
        )}`;
      }
      if (filter.status.length) {
        queryStr = `${queryStr ? queryStr + '&' : ''}statuses=${JSON.stringify(
          filter.status.map(item => item.value),
        )}`;
      }
      const response = await _notifLogsService.exportLogs(incidentId, queryStr);
      const type = '.csv';
      const fileName = `${moment(new Date()).format(
        'DD-MM-YYYY-HH:mm',
      )}-notifications_for_incident-${
        props.organization.currentOrg.o?.organizationId
      }-${incidentId}-report${type}`;
      const report =
        typeof File === 'function'
          ? new File([response.data.toString()], fileName, { type })
          : new Blob([response.data.toString()], { type });
      const URL = window.URL || (window as any).webkitURL;
      const download = URL.createObjectURL(report);
      const linkElement = document.getElementById(EXPORT_LOGS_ID);
      if (linkElement) {
        linkElement.setAttribute('href', download);
        linkElement.setAttribute('download', fileName);
        linkElement.click();

        setTimeout(() => {
          window.URL.revokeObjectURL(download);
        }, 100);
      }
    } catch (err: any) {
      showErrorToast(
        err?.response?.data?.meta?.error_message ?? 'An error occurred while downloading report',
      );
    } finally {
      setIsDownloading(false);
    }
  };

  const updateFilter = (name: keyof FILTER_TYPE, values: SELECT_BOX_OPTIONS[]) => {
    const newFilter = { ...filter };
    newFilter[name] = values;
    setFilter(newFilter);

    let logs = [...notificationLogs];
    if (newFilter.cause?.length) {
      logs = logs.filter(log => newFilter.cause.map(c => c.value).includes(log.cause));
    }
    if (newFilter.type?.length) {
      logs = logs.filter(log => newFilter.type.map(c => c.value).includes(log.type));
    }
    if (newFilter.status?.length) {
      logs = logs.filter(log => newFilter.status.map(c => c.value).includes(log.status));
    }
    setPageNo(0);
    setFilteredLogs(logs);
  };

  const renderIcon = (icon: FC) => {
    const ImageComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = icon;
    return <ImageComponent stroke={theme.shades.black} width={15} height={15} />;
  };

  const emptyLogs = () => {
    return (
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '24px',
          paddingTop: '24px',
          alignItems: 'center',
        }}
      >
        <img
          src="/icons/empty-states/incident.svg"
          alt="incident"
          style={{ display: 'inline-block', width: 150 }}
        />
        <Para>
          {!notificationLogs.length
            ? 'No notifications were sent for this Incident'
            : 'No notification logs match these filters'}
        </Para>
      </Grid>
    );
  };

  return (
    <>
      <TextButton buttonType="ghost" style={{ padding: 2 }} onClick={openLogsModal}>
        <Para fontSize={12} fontWeight={500} color={theme.primary.default}>
          Notification Logs
        </Para>
      </TextButton>
      <DialogModalFrame
        id="incident_details_notification_logs"
        onClose={closeLogsModal}
        padding="32px"
        width="1000px"
      >
        {openLogs && (
          <>
            <Grid alignItems="center">
              <Heading fontSize={20} style={{ lineHeight: 1.2 }}>
                Notification Logs
              </Heading>
              <IconButton
                base="24px"
                borderType="rounded"
                onClick={getNotificationLogsData}
                style={{ marginLeft: 5 }}
              >
                <Refresh />
              </IconButton>
              {isDownloading ? (
                <SpinLoader base="8px" />
              ) : (
                <IconButton
                  base="24px"
                  borderType="rounded"
                  onClick={downloadReport}
                  disabled={!filteredLogs.length}
                  style={{ marginLeft: 5 }}
                >
                  <DownloadIcon
                    className="cursor-pointer"
                    height={16}
                    width={16}
                    fill={theme.shades.black}
                  />
                </IconButton>
              )}
              <a id={EXPORT_LOGS_ID} target="_blank" rel="noreferrer" />
            </Grid>
            <div
              style={{
                padding: '8px 16px',
                fontWeight: 600,
                backgroundColor: theme.shades.whiteSmoke,
                marginTop: 30,
                marginBottom: 5,
              }}
            >
              <Grid flexWidth={12} justifyContent="space-between">
                <Grid alignItems="center" flexWidth={1}>
                  <Para fontSize={14} fontWeight={500}>
                    CAUSE
                  </Para>
                  <Multiselect
                    options={CAUSE_FILTER_OPTIONS}
                    value={filter.cause}
                    isDisabled={!notificationLogs.length}
                    onChange={(values: any) => {
                      updateFilter('cause', values);
                    }}
                  />
                </Grid>
                <Grid alignItems="center" flexWidth={3}>
                  <Para style={{ paddingLeft: '8px' }} fontSize={14} fontWeight={500}>
                    TO
                  </Para>
                </Grid>
                <Grid alignItems="center" flexWidth={4}>
                  <Para fontSize={14} fontWeight={500}>
                    TYPE
                  </Para>
                  <Multiselect
                    options={TYPE_FILTER_OPTIONS}
                    value={filter.type}
                    isDisabled={!notificationLogs.length}
                    onChange={(values: any) => {
                      updateFilter('type', values);
                    }}
                  />
                </Grid>
                <Grid alignItems="center" flexWidth={2}>
                  <Para fontSize={14} fontWeight={500}>
                    STATUS
                  </Para>
                  <Multiselect
                    options={STATUS_FILTER_OPTIONS}
                    value={filter.status}
                    isDisabled={!notificationLogs.length}
                    onChange={(values: any) => {
                      updateFilter('status', values);
                    }}
                  />
                </Grid>
                <Grid alignItems="center" flexWidth={2}>
                  <Para fontSize={14} fontWeight={500}>
                    TIME
                  </Para>
                </Grid>
              </Grid>
            </div>
            {loading ? (
              <Grid
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: 150,
                }}
              >
                <SpinLoader base="54px" />
              </Grid>
            ) : errorMsg !== '' ? (
              <Grid
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: 150,
                  flexDirection: 'column',
                }}
              >
                <ErrorBlock>{errorMsg}</ErrorBlock>
                <TextButton buttonType="ghost" onClick={getNotificationLogsData}>
                  <Para style={{ fontSize: 12, color: theme.shades.cement }}> Retry </Para>
                </TextButton>
              </Grid>
            ) : (
              <>
                {filteredLogs.length ? (
                  <>
                    {filteredLogs
                      .slice(pageNo * logsPerPage, (pageNo + 1) * logsPerPage)
                      .map((log, index) => {
                        return (
                          <div
                            key={index}
                            id={index.toString()}
                            className="notification_log_container"
                            style={{
                              borderBottom: `solid 2px ${theme.shades.whiteSmoke}`,
                            }}
                          >
                            <FocusBlock value={log} style={{ padding: '0px 0px' }}>
                              <Grid
                                flexWidth={12}
                                justifyContent="space-between"
                                style={{ textAlign: 'left', padding: '8px 16px' }}
                              >
                                <Grid flexWidth={1}>
                                  <Grid style={{ marginLeft: 15 }}>
                                    <Tooltip
                                      label={NOTIFICATION_CAUSE[log.cause].toolTip}
                                      offset={{ left: '20px', top: '-40px' }}
                                    >
                                      {renderIcon(NOTIFICATION_CAUSE[log.cause].icon)}
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                                <Grid flexWidth={3}>
                                  <Para
                                    fontSize={14}
                                    style={{ wordBreak: 'break-word', paddingRight: 10 }}
                                  >{`${log.first_name} ${log.last_name}`}</Para>
                                </Grid>
                                <Grid flexWidth={4} alignItems="center">
                                  {channelImages[log.type]}
                                  <Para
                                    style={{
                                      display: 'block',
                                      maxWidth: '100%',
                                      wordBreak: 'break-word',
                                      paddingRight: 10,
                                      marginLeft: 5,
                                    }}
                                    fontSize={14}
                                  >
                                    {log.type === 'email'
                                      ? log.email
                                      : log.type === 'push'
                                      ? 'push'
                                      : log.phone_number}
                                  </Para>
                                </Grid>
                                <Grid flexWidth={2}>
                                  <Tooltip
                                    label={
                                      log.status === 'sent' || log.status === 'delivered'
                                        ? 'Time of delivery: ' +
                                          getTimeAsString(log.time_of_delivery)
                                        : getTimeAsString(log.time_of_notification)
                                    }
                                    delay={300}
                                    offset={{ left: '50px', top: '-50px' }}
                                  >
                                    <Para fontSize={14} color={getColorForStatus(log.status)}>
                                      &bull; {log.status}
                                    </Para>
                                  </Tooltip>
                                </Grid>
                                <Grid flexWidth={2}>
                                  <Tooltip
                                    label={Locale.toSimpleDateTime(log.time_of_notification)}
                                    delay={300}
                                    offset={{ left: '50px', top: '-50px' }}
                                  >
                                    <Para fontSize={14}>
                                      {getTimeAsString(log.time_of_notification)}
                                    </Para>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </FocusBlock>
                          </div>
                        );
                      })}
                    {filteredLogs.length > logsPerPage && (
                      <Grid
                        style={{
                          width: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 20,
                        }}
                      >
                        <IconButton style={{ width: 20 }} onClick={prevPage}>
                          <ArrowBackIcon />
                        </IconButton>
                        <Para
                          fontSize={16}
                          style={{
                            marginLeft: 30,
                            marginRight: 30,
                          }}
                        >
                          {pageNo + 1}
                          {'/'}
                          {Math.ceil(filteredLogs.length / logsPerPage)}
                        </Para>
                        <IconButton style={{ width: 20 }} onClick={nextPage}>
                          <ArrowForwardIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>{emptyLogs()}</>
                )}
              </>
            )}
          </>
        )}
      </DialogModalFrame>
    </>
  );
};

export default connect(({ organization }: IAppState) => ({ organization }))(
  IncidentNotificationLogs,
);
