export const MONACO_EDITOR_OPTIONS = {
  scrollBeyondLastLine: false,
  fontSize: '18px',
  fontWeight: 200,
  fontFamily: 'monospace',
  fontLigatures: true,
  scrollbar: {
    vertical: 'auto',
  },
  minimap: { enabled: false },
};

export const READONLY_MONACO_EDITOR_OPTIONS = {
  ...MONACO_EDITOR_OPTIONS,
  readOnly: true,
};
