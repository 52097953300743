import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Flex, Spinner, Text } from '@chakra-ui/react';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { ComponentState, FC, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { AlertDialogComponent } from '../../components/Alert';
import { CustomDrawerComponent } from '../../components/Drawer';
import { Search } from '../../components/Search';
import { MAINTENANCE_DRAWER_TYPES, SearchActionKind } from '../../constants/status.constants';
import { getLoadingState } from '../../helpers/helper.service';
import { useMaintenanceList } from '../../hooks/useMaintenanceList';
import useQueryParams from '../../hooks/useQueryParams';
import { IMaintenance } from '../../Interface';
import AddMaintenance from './add';
import MaintenanceList from './table';
import UpdateMaintenance from './update';

interface Props {
  pageId: number;
  timezone: string;
}

const MaintenanceTab = (props: Props) => {
  const {
    isLoading,
    isError,
    list,
    totalCount,
    isSuccess,
    state: {
      pagination: { queryPageIndex, queryPageSize },
      drawer: { isDrawerOpen, drawerType, maintenanceId },
      filter,
      deleteMaintenace: { showModal, selectedMaintenanceId },
      search: { enabled: isSearchEnabled },
    },
    dispatch,
    deletePageMaintenanceAction,
    refetchMaintenanceList,
  } = useMaintenanceList(props.pageId);
  const query = useQueryParams();
  const history = useHistory();

  const canEdit = useUserAccess().hasUpdateAccess('status_pages', `${props.pageId}`);

  const MaintenanceListTable = (
    {
      failed: () => <></>,
      loading: () => (
        <Center h="60vh" data-testid="loader-issue">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      ),
      done: () => {
        return (
          <MaintenanceList
            list={list as IMaintenance[]}
            timezone={props.timezone}
            canEdit={canEdit}
            pageChange={(queryPageIndex: number) =>
              dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex })
            }
            pageSizeChange={(queryPageSize: number) => {
              dispatch({ type: SearchActionKind.PAGE_SIZE_CHANGED, queryPageSize });
            }}
            onEdit={(id: number) => {
              dispatch({
                type: SearchActionKind.OPEN_DRAWER,
                maintenanceId: id,
                drawerType: MAINTENANCE_DRAWER_TYPES.EDIT,
              });
            }}
            queryPageIndex={queryPageIndex}
            queryPageSize={queryPageSize}
            totalCount={totalCount}
            onDelete={(id: number) =>
              dispatch({
                type: SearchActionKind.SELECT_PAGE_DELETE,
                maintenanceId: id,
              })
            }
          />
        );
      },
    } as Record<ComponentState, FC>
  )[getLoadingState(isLoading, isSuccess, isError)];

  const searchQueryParam = query.get('search');
  const debouncedSearch = useRef(
    debounce((searchParam: string) => {
      dispatch({ type: SearchActionKind.SEARCHED, searchText: searchParam || '' });
      dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex: 1 });
    }, 1000),
  ).current;

  useEffect(() => {
    const hasSearchParam = typeof searchQueryParam === 'string';

    if (hasSearchParam) {
      dispatch({
        type: SearchActionKind.SEARCH_ENABLED_OR_DISABLED,
        isEnabled: true,
      });

      debouncedSearch(searchQueryParam);
    } else {
      dispatch({
        type: SearchActionKind.SEARCH_ENABLED_OR_DISABLED,
        isEnabled: false,
      });
    }
  }, [query.get('search')]);

  const MemoizedMaintenanceListTable = useMemo(
    () => MaintenanceListTable,
    [isLoading, isSuccess, isError, list],
  );

  const closeDrawer = () => {
    dispatch({ type: SearchActionKind.CLOSE_DRAWER });
  };

  const closeDeleteModal = () => {
    dispatch({
      type: SearchActionKind.REJECT_PAGE_DELETE,
    });
  };

  return (
    <Box pt={4}>
      <Flex justifyContent={'space-between'} gap={5} mb={6}>
        <Flex align={'center'}>
          <Button
            onClick={() => dispatch({ type: SearchActionKind.RESET_MAINTENANCE_MONTH })}
            variant="outline"
            mr={4}
          >
            This Month
          </Button>
          <Button
            onClick={() => dispatch({ type: SearchActionKind.DECREASE_MAINTENANCE_MONTH })}
            variant="outline"
            mr={4}
          >
            <ChevronLeftIcon />
          </Button>
          <Button
            onClick={() => dispatch({ type: SearchActionKind.INCREAMENT_MAINTENANCE_MONTH })}
            variant="outline"
            mr={4}
          >
            <ChevronRightIcon />
          </Button>
          <Text>{moment(`${filter.year}${filter.month + 1}`, 'YYYYMM').format('MMMM YYYY')}</Text>
        </Flex>
        <Flex>
          <Search
            setSearchTerm={searchTerm => {
              query.delete('search');
              query.append('search', searchTerm);
              history.push({ search: query.toString() });
            }}
            searchTerm={searchQueryParam || ''}
            searchEnabled={isSearchEnabled}
            setSearchEnabled={isEnabled => {
              if (isEnabled) {
                query.append('search', '');
              } else {
                query.delete('search');
              }
              history.push({ search: query.toString() });
            }}
            isDisabled={!isSuccess}
          />
          <NoPermissionTooltip isDisabled={canEdit}>
            <Button
              variant="default"
              size="sm"
              ml={4}
              onClick={() => {
                dispatch({
                  type: SearchActionKind.OPEN_DRAWER,
                  drawerType: MAINTENANCE_DRAWER_TYPES.ADD,
                });
              }}
              isDisabled={!canEdit}
            >
              Add Schedule Maintenance
            </Button>
          </NoPermissionTooltip>
        </Flex>
      </Flex>
      <MemoizedMaintenanceListTable />
      <CustomDrawerComponent onClose={closeDrawer} isOpen={isDrawerOpen} title={drawerType || ''}>
        {drawerType === MAINTENANCE_DRAWER_TYPES.ADD ? (
          <AddMaintenance
            pageId={props.pageId}
            onClose={closeDrawer}
            refetchMaintenanceList={refetchMaintenanceList}
          />
        ) : (
          <UpdateMaintenance
            pageId={props.pageId}
            onClose={closeDrawer}
            maintenanceId={maintenanceId}
            refetchMaintenanceList={refetchMaintenanceList}
            disabled={!canEdit}
          />
        )}
      </CustomDrawerComponent>
      <AlertDialogComponent
        isOpen={showModal}
        onClose={closeDeleteModal}
        callbackFn={() => {
          deletePageMaintenanceAction({
            id: selectedMaintenanceId,
          });
          closeDeleteModal();
        }}
        msg="Are you sure you want to delete?"
        title="Delete Maintenance"
        isDelete
      />
    </Box>
  );
};

export default MaintenanceTab;
