import { DialogModalFrame, Para } from 'uie/components';
import ConfirmModal from 'components/confirm.modal';
import React, { useEffect } from 'react';

type Props = {
  openModal: boolean;
  handleCloseModal: () => void;
  teamName: string;
  userName?: string;
  onConfirm: (value: boolean) => void;
};
export function ConfirmDeleteMemberModal({
  openModal,
  handleCloseModal,
  teamName,
  userName,
  onConfirm,
}: Props) {
  return (
    <DialogModalFrame
      root="rsg-root"
      id="modalFrame_ConfirmDeleteMemberModal"
      onClose={() => handleCloseModal()}
      padding="24px"
      width="500px"
    >
      {openModal && (
        <ConfirmModal
          displayText={
            <Para>
              Remove
              <span style={{ fontWeight: 'bold' }}> {userName} </span>
              from <span style={{ fontWeight: 'bold' }}> {teamName} </span> team?
            </Para>
          }
          buttonText="Yes, Remove"
          cancelText="No"
          primaryButtonColor="#FF4D4F"
          hide={(confirm: boolean) => {
            if (confirm) {
              onConfirm(true);
            } else {
              handleCloseModal();
            }
          }}
        />
      )}
    </DialogModalFrame>
  );
}

export function ConfirmConvertToMembersModal({
  openModal,
  handleCloseModal,
  memberName,
  onConfirm,
}: {
  openModal: boolean;
  handleCloseModal: () => void;
  memberName: string;
  onConfirm: (value: boolean) => void;
}) {
  return (
    <DialogModalFrame
      root="rsg-root"
      id="modalFrame_ConfirmConvertToMembersModal"
      onClose={() => handleCloseModal()}
      padding="24px"
      width="500px"
    >
      {openModal && (
        <ConfirmModal
          displayText={
            <>
              <Para
                style={{
                  color: '#09305A',
                  fontSize: '16px',
                  lineHeight: '150%',
                }}
              >
                Are you sure you want to convert
                <span style={{ fontWeight: 'bold' }}> {memberName} </span>
                to a team member?
              </Para>
              <Para
                style={{
                  color: '#09305A',
                  fontSize: '16px',
                  lineHeight: '150%',
                }}
              >
                Doing so will revoke their ability to manage the team.
              </Para>
            </>
          }
          buttonText="Yes, Convert"
          cancelText="No"
          primaryButtonColor="#FF4D4F"
          hide={(confirm: boolean) => {
            if (confirm) {
              onConfirm(true);
            } else {
              handleCloseModal();
            }
          }}
        />
      )}
    </DialogModalFrame>
  );
}
