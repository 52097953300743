import { ListItem, OrderedList, Text } from '@chakra-ui/layout';
import React, { FC } from 'react';
import { RunbookAction } from 'views/main/organization/workflow/types';

type Props = {
  action: RunbookAction;
};

const RunbookCard: FC<Props> = ({ action }) => {
  return (
    <OrderedList w="full">
      {action.data.runbooks?.map(value => (
        <ListItem key={value.id}>
          <Text color="secondary.1000" variant="h3">
            {value.name}
          </Text>
        </ListItem>
      ))}
    </OrderedList>
  );
};
export default RunbookCard;
