import React, { Component } from 'react';
import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Container,
  Divider,
  Flex,
  Heading,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  createStandaloneToast,
  VStack,
  Tr,
  Spinner,
  IconButton,
  HStack,
  InputGroup,
  InputLeftElement,
  Tooltip as CTooltip,
} from '@chakra-ui/react';
import { generatePath } from 'react-router-dom';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService, checkIfKeyChanged, exception, ServiceService, SquadsService } from 'core';
import { T_WA_UP_WEBFORM_DELETED, T_WA_UP_WEBFORM_PAGE_VIEWED } from 'core/const/tracker';
import { IAppState } from 'core/interfaces/IAppState';
import { IComponentNetworkState } from 'core/interfaces/IComponentState';
import { IOrganization } from 'core/interfaces/IOrganization';
import { IWebForm } from 'core/interfaces/IWebform';
import WebFormService from 'core/services/service.webform';
import { SearchIcon } from '@chakra-ui/icons';
import { CircleIcon, Filter, MoreVerticalIcon } from 'icons';
import { connect } from 'react-redux';
import { Link as ReachLink } from 'react-router-dom';
import { AppTracker } from 'shared/analytics/tracker';
import { AlertDialogComponent } from 'views/shared/components';
import NoData from '../../../../icons/webforms_nodata.png';
import ModifyWebformsModal from './modal';
import { Filters } from './filters';
import { Owner } from '../owner-filters/hooks/useEntityOwnerFilter';
import { ActiveFilterTags } from '../owner-filters/ActiveFilterTags';
import { OwnerFilter, withOwnerFilter } from './getOwnerFilters';
import { NodePopover } from 'components/chakra/Popover';
import {
  USER_PROFILE_PATH,
  TEAM_SQUADS_PATH,
  SERVICE_DETAILS_PATH,
} from 'views/main/routes/routes';
import { Header } from '../service-catalog/components';
import { fontTheme } from 'library/theme';
import { THEME_COLORS } from 'library/theme/colors';
import { UserAccessContextValue, withUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { EntityACLMeta } from 'core/userAccess/types';
import { subscribeToUserAccessStore, getUserAccessStore } from 'core/userAccess/UserAccessStore';
import PaginationBar from 'components/PaginationBar';

interface IProps extends Pick<IAppState, 'organization'> {
  ownerFilter: OwnerFilter;
  filterQueryParam?: string;
  userAccess: UserAccessContextValue;
}

interface IState {
  openModal: 'add_new' | 'edit' | '';
  componentNetworkState: IComponentNetworkState | 'request-webforms';
  webforms: IWebForm[];
  totalCount: number;
  pagination: {
    size: number; // default value is 10
    number: number; // default starts from 1
  };
  editWebformId: number;
  isDelete: boolean;
  deleteWebformId: number | null;
  ownerOptions: Options[];
  serviceOptions: Options[];
  searchTerm: string;
  selectedFilter: string[];
  isWebformDisabled: boolean;
  showUpgradeModal: boolean;
  isLoading: boolean;
  isFilterDrawerOpen: boolean;
  acl: Record<string, EntityACLMeta>;
}

export interface Options {
  value: any;
  label: any;
  type: string;
}
const toast = createStandaloneToast();
const DEFAULT_PAGE_NUMBER = 1;
class OrganizationWebformsTab extends Component<IProps, IState> {
  public WFService: WebFormService = new WebFormService();
  public SquadService: SquadsService = new SquadsService();
  public ServiceService: ServiceService = new ServiceService('');
  private unsubscribe: any = () => {};

  constructor(props: IProps) {
    super(props);
    this.state = {
      componentNetworkState: 'idle',
      editWebformId: 0,
      webforms: [],
      pagination: {
        size: 10,
        number: DEFAULT_PAGE_NUMBER,
      },
      totalCount: 0,
      openModal: '',
      isDelete: false,
      deleteWebformId: null,
      ownerOptions: [],
      serviceOptions: [],
      searchTerm: '',
      selectedFilter: [],
      isWebformDisabled: false,
      showUpgradeModal: false,
      isLoading: true,
      isFilterDrawerOpen: false,
      acl: {},
    };
  }

  public async componentDidMount() {
    this.setState({ isLoading: true });
    await this.getWebforms(
      this.state.pagination.number,
      this.state.pagination.size,
      this.props.filterQueryParam,
    );
    await this.getOptions();
    setTimeout(async () => {
      await this.upgradePlan();
      if (this.state.isWebformDisabled) {
        this.setState({ showUpgradeModal: true });
      }
    }, 300);

    this.unsubscribe = subscribeToUserAccessStore(state => {
      this.setState({ acl: state.entityACLMap.webforms ?? {} });
    });
    AppTracker.track(T_WA_UP_WEBFORM_PAGE_VIEWED);
  }

  public async componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      checkIfKeyChanged(
        prevProps.organization.selectedTeam,
        this.props.organization.selectedTeam,
        'refresh',
      )
    ) {
      await this.getWebforms(
        this.state.pagination.number,
        this.state.pagination.size,
        this.props.filterQueryParam,
      );
      await this.getOptions();
    }
    if (prevProps.filterQueryParam != this.props.filterQueryParam) {
      await this.getWebforms(
        this.state.pagination.number,
        this.state.pagination.size,
        this.props.filterQueryParam,
      );
    }
  }

  upgradePlan = () => {
    this.setState({
      isWebformDisabled: BillingService.isFeatureDisabled(this.props, 'webform'),
    });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  public getWebforms = async (
    pageNumber: number,
    pageSize: number,
    entityOwner = '',
    setAcl = true,
  ) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await this.WFService.getWebformsData(
        pageNumber,
        pageSize,
        setAcl,
        entityOwner || '',
      );
      this.setState({ webforms: data.data, isLoading: false, totalCount: data.meta.total_count });
    } catch (err: any) {
      this.setState({ isLoading: false });
      const title = err?.response?.data?.meta?.error_message || 'error connecting to server';
      toast.closeAll();
      toast({ title, status: 'error', variant: 'subtle', isClosable: true });
      exception.handle('E_GET_ALL_WEBFORMS', err);
    }
  };

  public setPageSize = (size: number) => {
    this.setState({
      pagination: {
        size,
        number: DEFAULT_PAGE_NUMBER,
      },
    });
    this.getWebforms(DEFAULT_PAGE_NUMBER, size, this.props.filterQueryParam, false);
  };

  public setPageNumber = (pageNumber: number) => {
    const number = pageNumber + 1;
    this.setState({
      pagination: {
        ...this.state.pagination,
        number,
      },
    });
    this.getWebforms(number, this.state.pagination.size, this.props.filterQueryParam, false);
  };

  public getOptions = async () => {
    const ownerOptions = [
      ...(this.props.organization?.users.u
        ? this.props.organization?.users?.u
            .filter(user => {
              return this.props.organization?.selectedTeam?.team?.members?.find(member => {
                return (
                  member.user_id === user.id &&
                  (user.role === 'user' || user.role === 'account_owner')
                );
              });
            })
            .filter(user => user.role !== 'stakeholder')
            .sort((comparingElement: any, compareWithElement: any) =>
              comparingElement.first_name.localeCompare(compareWithElement.first_name),
            )
            .map(
              (
                { first_name, last_name, id, username_for_display: username }: any,
                index: number,
              ) => {
                return {
                  value: id,
                  label: `${first_name} ${last_name}`,
                  username: username,
                  type: 'user',
                };
              },
            )
        : []),
      ...(this.props.organization?.squads?.s
        ? this.props.organization?.squads?.s
            .filter(squad => {
              return this.props.organization?.selectedTeam?.teamId === squad?.owner?.id;
            })
            .sort((comparingElement: any, compareWithElement: any) =>
              comparingElement.name.localeCompare(compareWithElement.name),
            )
            .map(({ name, id }: any, index: number) => {
              return { value: id, label: name, type: 'squad' };
            })
        : []),
    ];

    const serviceOption = this.props.organization?.services?.s
      ? this.props.organization?.services?.s
          .sort((comparingElement: any, compareWithElement: any) =>
            comparingElement.name.localeCompare(compareWithElement.name),
          )
          .map(service => {
            return { value: service.id, type: '', label: service.name };
          })
      : [];

    this.setState({
      ownerOptions: [...ownerOptions],
      serviceOptions: [...serviceOption],
    });
  };

  public hideNewWebformsModal = () => {
    this.setState({ openModal: '', editWebformId: 0 });
    this.getWebforms(this.state.pagination.number, this.state.pagination.size);
  };

  public getUsername = (id: string) => {
    return this.props.organization?.users?.u.find(user => {
      return user.id === id;
    })?.username_for_display;
  };

  public deleteWebform = async (id: number) => {
    try {
      await this.WFService.deleteWebform(id);
      this.getWebforms(this.state.pagination.number, this.state.pagination.size);
      toast({
        title: 'Delete Webform',
        description: 'Webform successfully deleted',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (err: any) {
      exception.handle('E_DELETE_WEBFORM', err);
      const error = err?.response?.data?.meta?.error_message || 'error connecting to server';
      toast({
        title: 'Delete Webform',
        description: error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    AppTracker.track(T_WA_UP_WEBFORM_DELETED);
  };

  public addWebforms = () => {
    this.setState({ openModal: 'add_new' });
  };
  public editWebforms = (id?: number) => {
    this.setState({ openModal: 'edit', editWebformId: id || 0 });
  };

  public openDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };
  onInputChange = (e: any) => {
    this.setState({ searchTerm: e.target.value });
  };

  public onFilterClose = () => {
    if (!this.props.ownerFilter.ownerFilterApplied) {
      this.props.ownerFilter.clearSelectedFilterValues();
    } else {
      this.props.ownerFilter.syncSelectedFilterWithActiveFilter();
    }
    this.setState({ isFilterDrawerOpen: false });
  };

  public onClickActiveFilterTag = (type: Owner, id: string) => {
    this.props.ownerFilter.handleActiveFilterTagClick(type, id);
  };

  render() {
    const {
      webforms,
      openModal,
      editWebformId,
      isDelete,
      deleteWebformId,
      ownerOptions,
      serviceOptions,
      showUpgradeModal,
      isLoading,
      isFilterDrawerOpen,
    } = this.state;

    const filteredWebforms = this.state.webforms.filter(webform =>
      webform.name.toLowerCase().includes(this.state.searchTerm.toLowerCase()),
    );

    const hasReadAccess = this.props.userAccess.hasReadAccess('webforms');

    const hasCreateAccess = this.props.userAccess.hasCreateAccess('webforms');

    const hasUpdateAccess = this.props.userAccess.hasUpdateAccess;
    const hasDeleteAccess = this.props.userAccess.hasDeleteAccess;

    return (
      <ChakraProvider theme={fontTheme}>
        <Header
          title={
            <HStack>
              <Heading fontSize="27px" color={THEME_COLORS.secondary[900]} fontWeight={400}>
                Webforms
              </Heading>
              <ActiveFilterTags
                tags={this.props.ownerFilter.activeFilterTags}
                onClose={this.onClickActiveFilterTag}
                visibleTagsCount={2}
                applyChakraStyle={false}
              />
            </HStack>
          }
          actions={
            <Flex justifyContent={'flex-end'} gap={5}>
              <Flex position="relative">
                <IconButton
                  aria-label="Filter webforms"
                  marginLeft={5}
                  icon={<Filter style={{ fill: '#2D3748', stroke: '#2D3748' }} />}
                  onClick={() => {
                    this.setState({ isFilterDrawerOpen: true });
                  }}
                />
                {this.props.ownerFilter.ownerFilterApplied && (
                  <CircleIcon
                    style={{
                      fill: 'red',
                      stroke: 'red',
                      position: 'absolute',
                      top: '-4',
                      right: '-10',
                    }}
                    height="10px"
                  />
                )}
              </Flex>
              <InputGroup width={'auto'}>
                <InputLeftElement height={10} pointerEvents="none">
                  <SearchIcon />
                </InputLeftElement>

                <Input
                  height={10}
                  fontSize={14}
                  type="text"
                  value={this.state.searchTerm}
                  placeholder="Search for keywords"
                  onChange={this.onInputChange}
                />
              </InputGroup>
              <Center height={10}>
                <Divider orientation="vertical" />
              </Center>
              {(webforms.length != 0 || this.props.ownerFilter.ownerFilterApplied) && (
                <NoPermissionTooltip isDisabled={hasCreateAccess}>
                  <Button
                    bgColor={THEME_COLORS.brand.blue}
                    color={'white'}
                    fontWeight={'500'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    onClick={this.addWebforms}
                    isDisabled={!hasCreateAccess}
                  >
                    Create Webform
                  </Button>
                </NoPermissionTooltip>
              )}
            </Flex>
          }
        />
        <div style={{ paddingLeft: '24px', marginBottom: '24px' }}>
          <Text variant="grayedCaption" fontSize="xs" color="#808080">
            Create and manage Webforms to give your stakeholders and customers a way to report
            issues through a publicly hosted form. Know more about webforms{' '}
            <Link
              href="https://support.squadcast.com/webforms/webforms"
              target="_blank"
              rel="noopener noreferrer"
              variant="underlinedLink"
              textDecoration={'underline'}
              color={THEME_COLORS.secondary[1300]}
            >
              here
            </Link>
            .
          </Text>
        </div>
        {webforms.length === 0 && !this.props.ownerFilter.ownerFilterApplied ? (
          <Center width={'inherit'} display={'flex'} height={'500px'}>
            {isLoading ? (
              <Center display={'block'}>
                <Box
                  flexDirection="row"
                  justifyContent="center"
                  width={'300px'}
                  display="flex"
                  marginTop="20px"
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                </Box>
              </Center>
            ) : (
              <Center display={'block'}>
                <Box
                  flexDirection="row"
                  justifyContent="center"
                  width={'300px'}
                  display="flex"
                  marginTop="20px"
                >
                  <Image src={NoData} alt="No Data Found" />
                </Box>
                <Text
                  textAlign={'center'}
                  padding={'10px'}
                  fontSize="24px"
                  lineHeight="24px"
                  fontWeight={'300'}
                >
                  No Webforms found
                </Text>
                <Box
                  flexDirection="row"
                  justifyContent="center"
                  width={'300px'}
                  display="flex"
                  marginTop="20px"
                >
                  <NoPermissionTooltip isDisabled={hasCreateAccess}>
                    <Button
                      width={'150px'}
                      height={'50px'}
                      bgColor={'rgba(15, 97, 221, 1)'}
                      color={'white'}
                      fontWeight={'500'}
                      fontSize={'14px'}
                      lineHeight={'20px'}
                      onClick={this.addWebforms}
                      justifyContent="center"
                      isDisabled={!hasCreateAccess}
                    >
                      Create Webform
                    </Button>
                  </NoPermissionTooltip>
                </Box>
              </Center>
            )}
          </Center>
        ) : (
          <Box marginTop={'5'}>
            <Container maxW="inherit">
              <Table marginTop={'15px'}>
                <Thead bgColor={'#F5F5F5'}>
                  <Tr>
                    {/* <Th>
                      <Checkbox> </Checkbox>
                    </Th> */}
                    <Th>Form Name</Th>
                    <Th>Form Owner</Th>
                    <Th>Incidents Created</Th>
                    <Th>Services</Th>
                    <Th isNumeric sx={{ textAlign: 'left!important' }}>
                      MTTR of Incidents (Mins)
                    </Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                {isLoading ? (
                  <Tbody>
                    <Tr>
                      <Td colSpan={6}>
                        <Center width={'inherit'} display={'flex'} height={'200px'}>
                          <Center display={'block'}>
                            <Box
                              flexDirection="row"
                              justifyContent="center"
                              width={'300px'}
                              display="flex"
                              marginTop="20px"
                            >
                              <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                              />
                            </Box>
                          </Center>
                        </Center>
                      </Td>
                    </Tr>
                  </Tbody>
                ) : (
                  <>
                    {filteredWebforms.length === 0 ? (
                      <Tbody>
                        <Tr>
                          <Td colSpan={6}>
                            <Text fontSize={14} textAlign="center">
                              No data
                            </Text>
                          </Td>
                        </Tr>
                      </Tbody>
                    ) : (
                      <>
                        {filteredWebforms.map((webform, index) => {
                          return (
                            <Tbody key={index}>
                              <Tr
                                _hover={{
                                  '> td a': {
                                    color: THEME_COLORS.secondary[1300],
                                    textDecoration: 'none',
                                  },
                                  backgroundColor: '#f3f7fd',
                                }}
                                style={{
                                  color: webform.is_deleted ? '#D9D9D9' : '',
                                }}
                              >
                                {/* <Td>
                          <Checkbox disabled={webform.is_deleted}> </Checkbox>
                        </Td> */}
                                <Td>
                                  <Text fontSize="sm" fontWeight={500} wordBreak="break-all">
                                    {webform.name}
                                  </Text>
                                  <br />
                                  {webform.is_deleted ? (
                                    <Text>
                                      {(webform.is_cname
                                        ? webform.host_name
                                        : webform.public_url
                                      ).substring(0, 30)}
                                      {(webform.is_cname && webform.host_name.length > 30) ||
                                      (!webform.is_cname && webform.public_url.length > 30)
                                        ? '...'
                                        : ''}
                                    </Text>
                                  ) : (
                                    <Link
                                      width={10}
                                      href={`https://${
                                        webform.is_cname ? webform.host_name : webform.public_url
                                      }`}
                                      isExternal
                                    >
                                      {(webform.is_cname
                                        ? webform.host_name
                                        : webform.public_url
                                      ).substring(0, 30)}
                                      {(webform.is_cname && webform.host_name.length > 30) ||
                                      (!webform.is_cname && webform.public_url.length > 30)
                                        ? '...'
                                        : ''}
                                    </Link>
                                  )}
                                </Td>
                                <Td>
                                  <Link
                                    as={ReachLink}
                                    to={
                                      webform.form_owner_type === 'user'
                                        ? generatePath(USER_PROFILE_PATH, {
                                            id: webform.form_owner_id,
                                          })
                                        : generatePath(TEAM_SQUADS_PATH, {
                                            id: webform.owner_id,
                                            sqid: webform.form_owner_id,
                                          })
                                    }
                                  >
                                    <CTooltip
                                      label={
                                        <VStack alignItems={'left'} spacing={0}>
                                          <Text
                                            fontWeight={'bold'}
                                            color={THEME_COLORS.secondary[1000]}
                                          >
                                            {webform.form_owner_name}
                                          </Text>
                                          <Text color={THEME_COLORS.secondary[700]}>
                                            {this.getUsername(webform.form_owner_id)}
                                          </Text>
                                        </VStack>
                                      }
                                      placement="bottom"
                                      bg={THEME_COLORS.secondary[100]}
                                    >
                                      <Text isTruncated maxW={'15ch'}>
                                        {webform.form_owner_name}
                                      </Text>
                                    </CTooltip>
                                  </Link>
                                </Td>
                                <Td textAlign={'center'}>
                                  {webform.incident_count === 0 ? (
                                    webform.incident_count
                                  ) : (
                                    <Link as={ReachLink} to={'/incident'} marginLeft="5">
                                      {webform.incident_count}
                                    </Link>
                                  )}
                                </Td>
                                <Td>
                                  {webform.services ? (
                                    <NodePopover
                                      triggerType="hover"
                                      hasArrow
                                      trigger={
                                        <Flex>
                                          <Link
                                            as={ReachLink}
                                            to={generatePath(SERVICE_DETAILS_PATH, {
                                              id: webform.services[0].service_id,
                                            })}
                                          >
                                            {webform.services[0].name}
                                          </Link>

                                          {webform.services.length > 1 && (
                                            <Tag size="sm" borderRadius="full">
                                              +{webform.services.length - 1}
                                            </Tag>
                                          )}
                                        </Flex>
                                      }
                                    >
                                      {webform.services.slice(1).map(_ => (
                                        <Box key={_.service_id} backgroundColor="#EEF2F6">
                                          <Link
                                            as={ReachLink}
                                            to={`/service-catalog/${_.service_id}`}
                                            color={THEME_COLORS.secondary[1300]}
                                            _hover={{
                                              textDecoration: 'none',
                                            }}
                                          >
                                            <Text
                                              fontSize="11px"
                                              fontWeight="bold"
                                              key={_.service_id}
                                            >
                                              {_.name}{' '}
                                            </Text>
                                          </Link>
                                          <Divider orientation="vertical" />
                                        </Box>
                                      ))}
                                    </NodePopover>
                                  ) : (
                                    <Text fontSize="sm" fontWeight={500} wordBreak="break-all">
                                      -
                                    </Text>
                                  )}
                                </Td>
                                <Td textAlign={'center'}>
                                  {webform.mttr ? webform.mttr.toFixed(2) : 0}
                                </Td>
                                <Td>
                                  <Menu>
                                    <MenuButton disabled={webform.is_deleted}>
                                      <MoreVerticalIcon />
                                    </MenuButton>
                                    <MenuList>
                                      <NoPermissionTooltip
                                        isDisabled={hasUpdateAccess(
                                          'webforms',
                                          `${webform.id ?? ''}`,
                                        )}
                                      >
                                        <MenuItem
                                          onClick={() => this.editWebforms(webform.id)}
                                          isDisabled={
                                            !hasUpdateAccess('webforms', `${webform.id ?? ''}`)
                                          }
                                        >
                                          Edit
                                          {openModal === 'edit' && editWebformId === webform.id && (
                                            <ModifyWebformsModal
                                              webformId={webform.id}
                                              ownerOptions={ownerOptions}
                                              serviceOptions={serviceOptions}
                                              onclose={this.hideNewWebformsModal}
                                            />
                                          )}
                                        </MenuItem>
                                      </NoPermissionTooltip>
                                      <NoPermissionTooltip
                                        isDisabled={hasDeleteAccess(
                                          'webforms',
                                          `${webform.id ?? ''}`,
                                        )}
                                      >
                                        <MenuItem
                                          isDisabled={
                                            !hasDeleteAccess('webforms', `${webform.id ?? ''}`)
                                          }
                                          onClick={() => {
                                            this.setState({ deleteWebformId: webform.id || null });
                                            this.openDeleteModal();
                                          }}
                                        >
                                          Delete
                                        </MenuItem>
                                      </NoPermissionTooltip>
                                    </MenuList>
                                  </Menu>
                                </Td>
                              </Tr>
                            </Tbody>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </Table>
              <PaginationBar
                pageIndex={this.state.pagination.number - 1}
                pageCount={Math.ceil(this.state.totalCount / this.state.pagination.size)}
                pageSize={this.state.pagination.size}
                totalCount={this.state.totalCount}
                setPageIndex={this.setPageNumber}
                setPageSize={this.setPageSize}
              />
              <Filters
                isFilterOpen={isFilterDrawerOpen}
                onClose={this.onFilterClose}
                ownerFilter={this.props.ownerFilter}
                applyChakraStyle={false}
              />
            </Container>
          </Box>
        )}
        {openModal === 'add_new' && (
          <ModifyWebformsModal
            webformId={0}
            ownerOptions={ownerOptions}
            serviceOptions={serviceOptions}
            onclose={this.hideNewWebformsModal}
          />
        )}
        <AlertDialogComponent
          isOpen={isDelete}
          onClose={this.openDeleteModal}
          callbackFn={() => {
            deleteWebformId && this.deleteWebform(deleteWebformId);
          }}
          msg={`Are you sure? You can't undo this action afterwards.`}
          title={'Delete Webform'}
          isDelete={true}
        />
        <UpgradePlanModal
          hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
          showModal={showUpgradeModal}
          message={BillingService.getMessage(0, 'webform', this.props)}
          onCancel={() => {
            this.setState({ showUpgradeModal: false });
            (this.props as any).history.goBack();
          }}
          header={BillingService.getHeader(0, 'webform', this.props)}
          onUpgrade={() => {
            this.setState({ showUpgradeModal: false });
          }}
        />
      </ChakraProvider>
    );
  }
}

export default connect(({ organization, userOrganizations, INIT_ORG }: IAppState) => ({
  selectedOrg: userOrganizations.o.find(o => o.organizationId === INIT_ORG.orgId) as IOrganization,
  organization,
}))(withOwnerFilter(withUserAccess(OrganizationWebformsTab)));
