import './index.css';

import EventDateRange from 'components/EventDateRangePicker';
import UpgradePlanModal from 'components/upgradeplan.modal';
import {
  flattenedObject,
  generateExpression,
  getLHSType,
} from '../../../../helpers/helper.automation-rule';
import { IAlertSource } from 'core/interfaces/IIntegration';
import { IServiceExpressionOperation } from 'core/interfaces/IService';
import BillingService from 'core/services/service.billing';
import { matchSorter } from 'match-sorter';
import React, { Fragment } from 'react';
import shortid from 'shortid';

import {
  Checkbox,
  DialogModalFrame,
  ErrorBlock,
  FocusBlock,
  Grid,
  Heading,
  IconButton,
  InputBlock,
  JSONCodeBlock,
  Para,
  SelectBox,
  SpinLoader,
  TextButton,
  Theme,
  Tooltip,
} from 'uie/components';

import { SuppressionRulesModal } from '.';
import { Button, Link, Text, Image } from '@chakra-ui/react';

const { theme } = Theme;
const activeColor = theme.success.default;
const inactiveColor = theme.danger.default;

function render(this: SuppressionRulesModal) {
  const {
    alertSourcesLoadState,
    alertSourceSearch,
    alertSource,
    alertSourceErrorMessage,
    rules,
    globalSearch,
  } = this.state;

  let filteredRules = rules;

  try {
    const matchedRules = matchSorter(rules, globalSearch, {
      keys: ['description', 'expression', 'basic_expression.*.lhs', 'basic_expression.*.rhs'],
      baseSort: (r1, r2) => (r1.item.id < r2.item.id ? -1 : 1),
    });

    filteredRules = globalSearch ? matchedRules : filteredRules;
  } catch (_) {}

  const limit = BillingService.getLimit(this.props, 'suppression-rules');

  return (
    <div>
      <div className="mb-10">
        <Grid justifyContent="space-between" alignItems="center">
          <Heading height={35} fontSize={24}>
            Suppression Rules
          </Heading>
        </Grid>
      </div>
      <div className="mb-10">
        <Grid justifyContent="space-between" alignItems="center">
          <Heading
            height={35}
            fontSize={14}
            style={{
              fontSize: '12px',
              color: '#808080',
              fontWeight: 300,
            }}
          >
            Auto-suppress notifications for non-actionable alerts to avoid alert fatigue. Learn more
            about suppression rules{' '}
            <Link
              href="https://support.squadcast.com/services/alert-suppression"
              target="_blank"
              rel="noopener noreferrer"
              variant="underlinedLink"
            >
              here
            </Link>
            .
          </Heading>
        </Grid>
      </div>
      <div>
        <Grid flexWidth={12} justifyContent="flex-start" alignItems="stretch">
          <Grid flexWidth={7}>
            {this.state.componentState === 'error' && (
              <Grid flexWidth={12} justifyContent="center">
                <div className="mt-20 ml-20">
                  <Grid justifyContent="center" alignItems="center">
                    <small className="ml-10">
                      Looks like there is a network error! please try again.
                    </small>
                  </Grid>
                </div>
              </Grid>
            )}
            {this.state.componentState === 'busy' && (
              <Grid flexWidth={12} justifyContent="center">
                <div className="mt-20 ml-20">
                  <Grid justifyContent="center" alignItems="center">
                    <SpinLoader />
                    <small className="ml-10">Loading Suppression rule</small>
                  </Grid>
                </div>
              </Grid>
            )}
            {this.state.componentState === 'idle' && (
              <div className="w-1-1">
                {this.state.rules.length === 0 ? (
                  <div className="empty-state-container w-1-1" style={{ marginBottom: 15 }}>
                    <Image
                      src="/icons/empty-states/suppression rule.svg"
                      className="empty-state-img"
                      width={120}
                      alt="suppressionRule"
                    />
                    <Text className="empty-state-headline" mb={10} mt={20}>
                      No rules found
                    </Text>
                  </div>
                ) : (
                  <>
                    {filteredRules.length === 0 && (
                      <div className="mt-20 mb-20">
                        <Text color={theme.font.disabled}>Search returned no results</Text>
                      </div>
                    )}
                    {filteredRules
                      .filter(rule =>
                        this.state.editRuleId
                          ? rule.rule_id === this.state.editRuleId
                          : !rule.existing,
                      )
                      .map(rule => {
                        const index = rule.id;
                        return (
                          <div
                            key={index}
                            className="main-rule-rectangle"
                            style={{ paddingRight: 40 }}
                          >
                            <div className="clearfix" style={{ marginBottom: '30px' }}>
                              <div className="field-parent-div">
                                <Grid type="column" className="mb-20">
                                  <Text className="mb-10" variant="secondary">
                                    Rule Description
                                  </Text>
                                  <InputBlock
                                    height="50px"
                                    fontSize="12px"
                                    placeholder="Example: This rule suppresses events that [add need for the rule]"
                                    value={rule.description}
                                    onChange={this.onDescriptionChange(index)}
                                  />
                                </Grid>
                                <Grid justifyContent="space-between" className="mb-10">
                                  <Grid alignItems="center">
                                    <Text variant="secondary">Suppression Expression</Text>
                                    &nbsp;
                                    {rule.is_basic && (
                                      <Tooltip
                                        padding="8px"
                                        offset={{ top: '0px' }}
                                        width="300px"
                                        label="You can either write your Suppression rule expression in the Suppression Expression text box
                                     or use the GUI below to create simpler rules. What is input in the GUI will also be shown above in the text box as an expression"
                                      >
                                        <Image
                                          src="/icons/info_black.svg"
                                          alt="more info"
                                          height={4}
                                        />
                                      </Tooltip>
                                    )}
                                  </Grid>
                                  {rule.is_basic && (
                                    <TextButton
                                      buttonType="ghost"
                                      onClick={this.openWarning(index)}
                                      disabled={!this.state.alertSource}
                                    >
                                      <Text variant="primartFitContent">Edit</Text>
                                      &nbsp;
                                      <Image height="1rem" src="/icons/blues/edit.svg" alt="edit" />
                                    </TextButton>
                                  )}
                                </Grid>
                                <InputBlock
                                  id={`expression-${index}`}
                                  placeholder={
                                    !rule.is_basic
                                      ? 'Enter suppression rule'
                                      : 'Expression corresponding to conditions'
                                  }
                                  value={
                                    !rule.is_basic
                                      ? rule.expression
                                      : generateExpression(rule.basic_expression, this.state.event)
                                  }
                                  onChange={this.onRuleExpressionChange(index)}
                                  error={this.state.errors.includes(`rules[${index}].expression`)}
                                  height="50px"
                                  fontSize="14px"
                                  disabled={rule.is_basic}
                                />
                              </div>
                            </div>

                            {rule.is_basic && (
                              <>
                                {rule.basic_expression &&
                                  rule.basic_expression.map((be, beIndex, array) => {
                                    return (
                                      <Fragment key={beIndex}>
                                        <Grid justifyContent="space-between">
                                          <Grid>
                                            <Text
                                              m="auto"
                                              fontSize={14}
                                              height="24px"
                                              fontWeight={500}
                                            >
                                              If{' '}
                                            </Text>
                                            &nbsp;
                                            {!alertSource && (
                                              <Tooltip
                                                padding="8px"
                                                offset={{ top: '0px' }}
                                                width="max-content"
                                                label="Select an alert source"
                                              >
                                                <Image
                                                  src="/icons/info_black.svg"
                                                  alt="more info"
                                                  height="4"
                                                  mt="17px"
                                                  ml="2px"
                                                  mr="5px"
                                                />
                                              </Tooltip>
                                            )}
                                          </Grid>
                                          <Grid flexWidth={5}>
                                            <SelectBox
                                              hook={
                                                <Text fontSize={12} overflow="auto" noOfLines={2}>
                                                  {be.lhs ? be.lhs : 'Select Label'}
                                                </Text>
                                              }
                                              searchHookProps={{
                                                value:
                                                  this.state.searchString[
                                                    `lhs_${index}_${beIndex}`
                                                  ] || '',
                                                height: '24px',
                                                fontSize: '16px',
                                                onChange: this.setEntitySearch(
                                                  'lhs',
                                                  index,
                                                  beIndex,
                                                ),
                                              }}
                                              onValueChange={this.changeConditionSelectBox(
                                                index,
                                                beIndex,
                                                'lhs',
                                              )}
                                              height="auto"
                                              maxHeight="200px"
                                              width="100%"
                                              maxWidth="100%"
                                              disabled={!this.state.alertSource}
                                              error={this.state.errors.includes(
                                                `rules[${index}].basic_expression[${beIndex}].lhs`,
                                              )}
                                            >
                                              {Object.keys(flattenedObject(this.state.event))
                                                .filter((v: string) =>
                                                  v
                                                    .toLowerCase()
                                                    .includes(
                                                      (
                                                        this.state.searchString[
                                                          `lhs_${index}_${beIndex}`
                                                        ] || ''
                                                      ).toLowerCase(),
                                                    ),
                                                )
                                                .map((r: any, i) => {
                                                  return (
                                                    <FocusBlock
                                                      height={'100%'}
                                                      key={i}
                                                      value={r}
                                                      isSelected={be.lhs === r}
                                                      style={{ fontSize: '12px' }}
                                                    >
                                                      <Text fontSize={12} textAlign="left">
                                                        {r}
                                                      </Text>
                                                    </FocusBlock>
                                                  );
                                                })}
                                            </SelectBox>
                                          </Grid>
                                          <Grid flexWidth={3}>
                                            <SelectBox
                                              hook={
                                                <Text fontSize={12} textAlign="center">
                                                  {be.op
                                                    ? this._comparators.all[be.op]
                                                    : 'Condition'}
                                                </Text>
                                              }
                                              onValueChange={this.changeConditionSelectBox(
                                                index,
                                                beIndex,
                                                'op',
                                              )}
                                              height="auto"
                                              maxHeight="200px"
                                              width="100%"
                                              maxWidth="100%"
                                              error={this.state.errors.includes(
                                                `rules[${index}].basic_expression[${beIndex}].op`,
                                              )}
                                              disabled={!be.lhs || !this.state.alertSource}
                                            >
                                              {Object.keys(
                                                this._comparators[
                                                  getLHSType(be.lhs, this.state.event)
                                                ] || {},
                                              ).map(v => {
                                                return (
                                                  <FocusBlock
                                                    key={v}
                                                    value={v}
                                                    isSelected={be.op === v}
                                                    style={{ textAlign: 'center' }}
                                                  >
                                                    <Text fontSize={12} textAlign="left">
                                                      {
                                                        this._comparators.all[
                                                          v as IServiceExpressionOperation
                                                        ]
                                                      }
                                                    </Text>
                                                  </FocusBlock>
                                                );
                                              })}
                                            </SelectBox>
                                          </Grid>
                                          <Grid flexWidth={3}>
                                            {getLHSType(be.lhs, this.state.event) === 'boolean' ? (
                                              <SelectBox
                                                hook={
                                                  <Text fontSize={12} textAlign="center">
                                                    {be.rhs === '' && 'Truth Value'}
                                                    {be.rhs === true && 'True'}
                                                    {be.rhs === false && 'False'}
                                                  </Text>
                                                }
                                                onValueChange={this.changeConditionSelectBox(
                                                  index,
                                                  beIndex,
                                                  'rhs',
                                                )}
                                                height="auto"
                                                maxHeight="200px"
                                                width="100%"
                                                maxWidth="100%"
                                                error={this.state.errors.includes(
                                                  `rules[${index}].basic_expression[${beIndex}].rhs`,
                                                )}
                                                disabled={!be.lhs || !this.state.alertSource}
                                              >
                                                {['True', 'False'].map(v => {
                                                  return (
                                                    <FocusBlock
                                                      key={v}
                                                      value={v === 'True'}
                                                      isSelected={be.op === v}
                                                      style={{ textAlign: 'center' }}
                                                    >
                                                      {v}
                                                    </FocusBlock>
                                                  );
                                                })}
                                              </SelectBox>
                                            ) : (
                                              <InputBlock
                                                type={
                                                  getLHSType(be.lhs, this.state.event) === 'number'
                                                    ? 'number'
                                                    : 'text'
                                                }
                                                id={`expression-${index}`}
                                                placeholder="Enter value"
                                                value={be.rhs}
                                                onChange={this.changeConditionInputBox(
                                                  index,
                                                  beIndex,
                                                  'rhs',
                                                )}
                                                height="50px"
                                                fontSize="12px"
                                                error={this.state.errors.includes(
                                                  `rules[${index}].basic_expression[${beIndex}].rhs`,
                                                )}
                                                disabled={!be.lhs || !this.state.alertSource}
                                              />
                                            )}
                                          </Grid>
                                          <Grid flexWidth={1}>
                                            <IconButton
                                              name="remove"
                                              onClick={this.removeCondition(index, beIndex)}
                                              base="32px"
                                              borderType="rounded"
                                              style={{ margin: 'auto' }}
                                            >
                                              <Text fontSize={12} height="24px" m="auto">
                                                <img src="/icons/remove.svg" alt="remove" />
                                              </Text>
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                        {beIndex < array.length - 1 && (
                                          <Grid
                                            justifyContent="center"
                                            style={{ margin: '2%', marginLeft: '10%' }}
                                          >
                                            <Text fontSize={14} height="24px" fontWeight={500}>
                                              AND
                                            </Text>
                                          </Grid>
                                        )}
                                      </Fragment>
                                    );
                                  })}
                                <Grid justifyContent="flex-end" className="mt-10">
                                  <TextButton buttonType="ghost" onClick={this.addCondition(index)}>
                                    <Text variant="primartFitContent">+Add Condition</Text>
                                  </TextButton>
                                </Grid>
                              </>
                            )}

                            <Grid justifyContent="flex-start" className="mt-10">
                              <Checkbox
                                checked={rule.is_timebased}
                                onChange={this.onClickTime(index)}
                              />
                              <span
                                className="ml-10"
                                style={{ textAlign: 'left', fontSize: '14px', fontWeight: 600 }}
                              >
                                Suppress by time
                              </span>
                            </Grid>
                            {rule.is_timebased && (
                              <div>
                                {rule.timeslots?.length === 0 || !rule.timeslots ? (
                                  <Text>No slots found</Text>
                                ) : (
                                  rule.timeslots.map((timeslot, timeIndex) => {
                                    return (
                                      <div
                                        key={timeIndex}
                                        className="main-rule-rectangle"
                                        style={{ marginTop: 10, paddingRight: 40 }}
                                      >
                                        <Grid className="mb-10">
                                          <Text
                                            fontSize={14}
                                            height="24px"
                                            fontWeight={500}
                                            className="mb-10"
                                          >{`Slot #${timeIndex + 1}`}</Text>
                                        </Grid>
                                        <EventDateRange
                                          slot={timeslot}
                                          handleChange={this.handleTimeSlotChange(index, timeIndex)}
                                        />
                                        <img
                                          id="slot-delete"
                                          src="/icons/delete-red@3x.png"
                                          className="delete-icon-rule cursor-pointer"
                                          style={{ width: 50, height: 50 }}
                                          onClick={this.deleteTimeslot.bind(null, index, timeIndex)}
                                          alt="delete"
                                        />
                                      </div>
                                    );
                                  })
                                )}
                                <Grid justifyContent="flex-end" className="mt-10">
                                  <TextButton
                                    id="add_new_time"
                                    buttonType="ghost"
                                    onClick={this.addNewTimeSlot.bind(null, index)}
                                  >
                                    <Text variant="primartFitContent">+Add slot</Text>
                                  </TextButton>
                                </Grid>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </>
                )}

                {this.state.saveState === 'error' && (
                  <div>
                    <ErrorBlock>{this.state.networkError}</ErrorBlock>
                  </div>
                )}
                {this.state.errors.length > 0 && (
                  <div>
                    <ErrorBlock>Please provide value for all fields</ErrorBlock>
                  </div>
                )}
                <div>
                  <Grid justifyContent="flex-start" alignItems="center">
                    <Button
                      onClick={this.save}
                      disabled={this.state.saveState === 'saving'}
                      isLoading={this.state.saveState === 'saving'}
                      loadingText="Save Rule"
                      variant="default"
                      size="sm"
                    >
                      Save Rule
                    </Button>
                    {this.state.saveState === 'saved' && (
                      <small className="ml-20">All Changes Saved!</small>
                    )}
                  </Grid>
                </div>
              </div>
            )}
          </Grid>
          <Grid flexWidth={5}>
            {alertSourcesLoadState === 'loading' && (
              <Grid flexWidth={12} justifyContent="center">
                <div style={{ margin: '125px' }}>
                  <Grid justifyContent="center" alignItems="center">
                    <SpinLoader />
                    <small className="ml-10">Loading Alert Sources</small>
                  </Grid>
                </div>
              </Grid>
            )}

            {alertSourcesLoadState === 'error' && (
              <Grid flexWidth={12} justifyContent="center">
                <div style={{ margin: '135px' }}>
                  <Grid justifyContent="center" alignItems="center">
                    <ErrorBlock fontWeight={500} fontSize={14}>
                      {alertSourceErrorMessage}
                    </ErrorBlock>
                  </Grid>
                </div>
              </Grid>
            )}

            {alertSourcesLoadState === 'success' && (
              <div className="mb-20 ml-20" style={{ width: 'calc(100% - 20px)' }}>
                <label className="item-box-tagline font-bold mb-10 block">Alert Source</label>
                <SelectBox
                  id="select-alert-source"
                  hook={
                    alertSource ? (
                      <Grid alignItems="center">
                        <div
                          className="mr-10"
                          style={{
                            borderRadius: '100%',
                            background: `${alertSource.isActive ? activeColor : inactiveColor}`,
                            height: 'fit-content',
                            width: 'fit-content',
                            border: 'none',

                            padding: '4px',
                            marginTop: '15px',
                            marginBottom: '15px',
                          }}
                        />
                        <div>{alertSource.type}</div>
                      </Grid>
                    ) : (
                      <Text fontSize={14} fontWeight={500}>
                        Please select an alert source
                      </Text>
                    )
                  }
                  searchHookProps={{
                    value: alertSourceSearch,
                    height: '24px',
                    fontSize: '16px',
                    onChange: this.onTextChange('alertSourceSearch'),
                  }}
                  onValueChange={this.onSelectChange}
                  height="auto"
                  maxHeight="200px"
                  width="100%"
                  maxWidth="100%"
                >
                  {this.alertSources
                    .filter((altSrc: IAlertSource) =>
                      alertSourceSearch
                        ? altSrc.type.toLowerCase().includes(alertSourceSearch.toLocaleLowerCase())
                        : true,
                    )
                    .map((altSrc: IAlertSource, index: number) => (
                      <FocusBlock
                        height={'100%'}
                        value={altSrc}
                        key={shortid.generate()}
                        isSelected={alertSource === altSrc}
                        style={{ fontSize: '12px' }}
                      >
                        <Grid alignItems="center">
                          <div
                            className="mr-10"
                            style={{
                              borderRadius: '100%',
                              background: `${altSrc.isActive ? activeColor : inactiveColor}`,
                              height: 'fit-content',
                              width: 'fit-content',
                              border: 'none',
                              padding: '4px',
                            }}
                          />
                          <Text className="item-box-tagline" style={{ margin: 0 }}>
                            {altSrc.type}
                          </Text>
                        </Grid>
                      </FocusBlock>
                    ))}
                </SelectBox>
                {this.state.eventState === 'loading' && (
                  <Grid flexWidth={12} justifyContent="center">
                    <div style={{ margin: '120px' }}>
                      <Grid justifyContent="center" alignItems="center">
                        <SpinLoader />
                        <small className="ml-10">Loading Event Payload</small>
                      </Grid>
                    </div>
                  </Grid>
                )}
                {this.state.eventState === 'noEvents' && (
                  <Grid flexWidth={12} justifyContent="center">
                    <div style={{ margin: '120px' }}>
                      <Grid justifyContent="center" alignItems="center">
                        <small className="ml-10">{`No events for ${alertSource?.type}`}</small>
                      </Grid>
                    </div>
                  </Grid>
                )}
                {this.state.eventState === 'idle' && Object.keys(this.state.event).length > 0 && (
                  <div className="w-1-1 mt-10" style={{ position: 'sticky', top: 20 }}>
                    <JSONCodeBlock
                      code={this.state.event}
                      enableSearch={true}
                      shellProps={{
                        minHeight: '600px',
                        minWidth: 'calc(100% - 15px)',
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <DialogModalFrame
        id="warning"
        onClose={this.closeWarning(false)}
        style={{ width: '50%', margin: 'auto' }}
        onClick={e => e.stopPropagation()}
      >
        {this.state.warningIndex !== null && (
          <Grid type="column">
            <Grid className="mb-20">
              <Heading height={35} fontSize={18} fontWeight={450}>
                Edit Suppression Expression
              </Heading>
            </Grid>

            <Grid className="mb-20">
              <Text>
                Editing the rule will disable the GUI below for this rule. Click "
                <span className="font-bold">Proceed</span>" to continue
              </Text>
            </Grid>

            <Grid justifyContent="flex-end">
              <TextButton width="25%" onClick={this.closeWarning(true)}>
                <Text variant="proceed">Proceed</Text>
              </TextButton>
            </Grid>
          </Grid>
        )}
      </DialogModalFrame>
      {
        <UpgradePlanModal
          hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
          message={BillingService.getMessage(limit, 'suppression-rules', this.props)}
          header={BillingService.getHeader(limit, 'suppression-rules', this.props)}
          onCancel={() => this.setState({ showUpgradeModal: false })}
          onUpgrade={() => this.setState({ showUpgradeModal: false })}
          showModal={this.state.showUpgradeModal}
        />
      }
    </div>
  );
}

export default render;
