import { Box, HStack, Text } from '@chakra-ui/react';
import { AssigneeAvatar, Divider } from 'library/atoms';
import { Stats } from 'library/molecules';
import { FC } from 'react';

import { useGetAssigneeComponent, Timestamp } from '../components';
import { GER_DATA, ORG } from '../types';
import { useGetEntityName } from '../util/functions';

type Props = {
  gerData: GER_DATA;
  organization?: ORG;
};

export const GERStats: FC<Props> = ({ gerData, organization }) => {
  const { id: ownerId, type } = gerData.entity_owner;
  const userId = gerData.created_by;

  const name = useGetEntityName('user', userId);
  const ownerName = useGetEntityName(type, ownerId);

  const infoStats = [
    {
      title: 'CREATED BY',
      description: (
        <HStack columnGap="4px">
          <AssigneeAvatar name={name} />
          {useGetAssigneeComponent(userId, name, true)}
        </HStack>
      ),
    },
    {
      title: 'CREATED AT',
      description: (
        <Box pt={1}>
          <Timestamp timestamp={gerData.created_at} pl={0} />
        </Box>
      ),
    },
    {
      title: 'ROUTING KEY',
      description: (
        <Box pt={1}>
          <Text variant="body" color="secondary.1000">
            {gerData.routing_key}
          </Text>
        </Box>
      ),
    },
    {
      title: 'OWNER',
      description: (
        <HStack columnGap="4px">
          <AssigneeAvatar name={ownerName} />
          {useGetAssigneeComponent(ownerId, ownerName, true, type, organization)}
        </HStack>
      ),
    },
  ];

  return (
    <>
      <Box mx="-24px !important">
        <Divider />
      </Box>
      <Box w="full">
        <HStack align="flex-start">
          {infoStats.map((item, index) => {
            return <Stats key={index} padding={'16px 0px 0px'} {...item} />;
          })}
        </HStack>
      </Box>
    </>
  );
};
