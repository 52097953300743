import { CSSObject } from '@chakra-ui/react';
import chakraUiTheme from '@chakra-ui/theme';

const grayStyles = { borderColor: chakraUiTheme.colors.gray['200'] + '!important' };

export const calendarStyles = (
  timeZone: string,
  height?: string,
  loading?: boolean,
): CSSObject => ({
  '&': {
    backgroundColor: 'white',
    width: '100%',
    height,
    overflow: 'hidden',

    '.fc': {
      visibility: loading ? 'hidden' : 'visible',
      tr: grayStyles,
      td: grayStyles,
      th: grayStyles,
      tbody: grayStyles,
      '.fc-scrollgrid': grayStyles,
      '.fc-event-main': {
        overflow: 'hidden',
        padding: 0,
      },
      '.fc-event-container': {
        margin: 'auto',
      },
      '.fc-timegrid-now-indicator-arrow': {
        borderColor: chakraUiTheme.colors.green['400'] + '!important',
        borderTopColor: 'transparent !important',
        borderBottomColor: 'transparent !important',
      },
      '.fc-col-header-cell-cushion': {
        width: '100%',
      },
      '.fc-timegrid-axis-frame': {
        height: '100%',
        width: '100%',
        px: 2,
        ':before': {
          width: '100%',
          content: `"${timeZone}"`,
          fontSize: 14,
          fontWeight: 400,
          textAlign: 'right',
        },
      },
    },
    '.fc-timegrid-now-indicator-line': {
      position: 'relative',
      borderColor: chakraUiTheme.colors.green['500'] + '!important',
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '6px',
        height: '6px',
        background: chakraUiTheme.colors.green['500'],
        borderRadius: '50%',
        left: '3px',
        top: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    '.fc-event': {
      backgroundColor: 'transparent !important',
    },
    '.fc-h-event': {
      backgroundColor: 'transparent',
      background: 'none',
    },
    '.fc-daygrid-day-top': {
      marginLeft: '0 !important',
    },
    '.fc-daygrid-day-events': {
      margin: '0 !important',
      filter: 'none',
    },
    '.fc-daygrid-event-harness': {
      backgroundColor: 'transparent',
    },
    '.fc-day-grid-event, .fc-time-grid-event': {
      padding: '0 2px !important',
      margin: '0 !important',
      filter: 'none',
      border: '1px solid' + chakraUiTheme.colors.blue['200'] + '!important',
      overflow: 'hidden',
    },
    '.fc-col-header colgroup col': { width: '7rem !important' },
    '.fc-timegrid-slots': {
      tbody: {
        tr: {
          '&:first-child': {
            '.fc-timegrid-slot-label-cushion': {
              visibility: 'hidden',
            },
          },
          'td:first-child': {
            backgroundColor: 'white',
          },
        },
      },
      'colgroup col': { width: '7rem !important' },
    },
    '.fc-timegrid-cols colgroup col': { width: '7rem !important' },

    '.fc .fc-timegrid-slot-minor': { borderTop: 'none' },
    '.fc .fc-timegrid-slot-label': { borderTop: 'none' },
    '.fc .fc-timegrid-slot': {
      position: 'relative',
      boxShadow: 'none',
      '.fc-timegrid-slot-label-cushion': {
        position: 'absolute',
        top: '-12px',
        right: '5%',
      },
    },
    '.fc-timegrid-event:focus, .fc-timegrid-event:hover': {
      boxShadow: `0px 0 0 1px ${chakraUiTheme.colors.gray['600']} !important`,
    },
    '.fc-timegrid-col-events': {
      margin: 0,
    },
    '.fc-more-popover': {
      overflowY: 'auto',
      maxHeight: '50%',
    },
    '.fc-popover-header ': {
      top: 0,
      position: 'sticky',
      zIndex: 100,
      filter: 'opacity(1)',
      background: `${chakraUiTheme.colors.gray['200']}`,
    },
    '.fc-popover': {
      zIndex: 10,
    },
  },
});
