import chakraUiTheme from '@chakra-ui/theme';

export const colors = {
  colors: {
    black: '#000',
    white: '#fff',
    blue: {
      ...chakraUiTheme.colors.blue,
      50: '#f3f9ff',
      100: '#a5cbfa',
      200: '#e5f2ff',
      300: '#dceeff',
      310: '#0000FF',
      900: '#1b7ff1',
    },
    red: {
      900: '#D14C44',
    },
    gray: {
      700: '#627C98',
      900: '#1D426B',
      120: '#ECEFF3',
      160: '#E1E6EB',
      180: '#8A89AA',
    },
    modalOverlay: '#0d214947',
  },
};
