import { HStack } from '@chakra-ui/layout';
import { useFormContext } from 'react-hook-form';
import { WorkflowForm, WorkFlowGroupRecur } from '../../types';
import { useWorkflowFormContext } from '../../context/workflowFormContext';
import useServiceList from '../filter/service/useServiceList';
import useAlertSourcesList from '../filter/alertSource/useAlertSourceList';
import { useTags } from '../filter/tags/useTags';
import { FormButton } from 'library/atoms';

interface Props {
  isFormSubmitting: boolean;
  handleCancelClick: () => void;
  isCreateFlow: boolean;
}
export const FormFooter = ({ isFormSubmitting, handleCancelClick, isCreateFlow }: Props) => {
  const { enableGroup } = useWorkflowFormContext();
  const { watch } = useFormContext<WorkflowForm>();

  const tagHasValue = watch('group.fields.children')?.every(item => {
    if (item.filterType === 'group' && (item.fields as WorkFlowGroupRecur).children?.length) {
      return (item.fields as WorkFlowGroupRecur).children.every(filter => {
        if (filter.value !== 'tag') return true;
        return filter.fields && 'value' in filter.fields;
      });
    } else {
      if (item.value !== 'tag') return true;
      return item.fields && 'value' in item.fields;
    }
  });

  const isGroupEmpty = watch('group.fields.children')?.some(
    item =>
      item.filterType === 'group' && (item.fields as WorkFlowGroupRecur).children?.length === 0,
  );

  const { isLoading: isServiceLoading } = useServiceList();
  const { isLoading: isAlertSourceLoading } = useAlertSourcesList();
  const { isLoading: isTagLoading } = useTags();

  const disableButton =
    isServiceLoading ||
    isAlertSourceLoading ||
    isTagLoading ||
    isGroupEmpty ||
    !enableGroup ||
    !tagHasValue;

  return (
    <HStack py={2}>
      <FormButton
        type={'submit'}
        variant="primary"
        isLoading={isFormSubmitting}
        disabled={disableButton}
        title={isCreateFlow ? 'Done' : 'Update'}
      />

      <FormButton onClick={handleCancelClick} variant="secondary" title="Cancel" />
    </HStack>
  );
};
