import { useDisclosure } from '@chakra-ui/hooks';
import { FC, useEffect } from 'react';
import { DialogModalFrame } from 'uie/components';

type Props = {
  isPrimary: boolean;
};

const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.charset = 'utf-8';
    script.async = true;
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.onload = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.hbspt) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '6026860',
          formId: '93322395-ecc1-4a66-8110-2c4b2ed3a526',
          target: '#hubspot-modal', // Specify the target element within the modal
        });
      } else {
        console.error('HubSpot script not loaded properly.');
      }
    };

    return () => {
      // Clean up script tag on component unmount
      document.body.removeChild(script);
    };
  }, []);

  return <div id="hubspot-modal">{/* Form will be rendered here */}</div>;
};

export const ContactUs: FC<Props> = ({ isPrimary }) => {
  const { onClose, isOpen, onOpen } = useDisclosure();

  return (
    <>
      <button
        className={
          'main-button' + (isPrimary ? ' btn-subscribe--main' : ' btn-subscribe--secondary')
        }
        onClick={onOpen}
      >
        Contact us
      </button>
      <DialogModalFrame
        root="contact-us"
        id="hubspot-modal-frame"
        onClose={onClose}
        padding="32px"
        width="800px"
      >
        {isOpen && <HubSpotForm />}
      </DialogModalFrame>
    </>
  );
};
