import { PaginationState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { QueryKey, useQuery } from 'react-query';
import { Failure } from './Failure';
import { key } from './ReactQuery';
import { Session, useSession } from './Session';

type QueryFn<TData, TFilters> = (
  session: Session,
  pagination: PaginationState,
  filters: TFilters,
) => Promise<TData>;

type CountFn<TCount, TFilters> = (
  session: Session,
  pagination: PaginationState,
  filters: TFilters,
) => Promise<TCount>;

type UseListingProps<TData, TCount, TFilters> = {
  baseQueryKey: QueryKey;
  queryFn: QueryFn<TData, TFilters>;
  countQueryFn: CountFn<TCount, TFilters>;
  initialPageSize?: number;
  initialFilters?: TFilters;
};

export function useListing<TData, TCount, TFilters>({
  baseQueryKey,
  queryFn,
  countQueryFn,
  initialPageSize = 10,
  initialFilters = {} as TFilters,
}: UseListingProps<TData, TCount, TFilters>) {
  const { session } = useSession();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: initialPageSize,
  });
  const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);

  const [filters, setFilters] = useState<TFilters>(initialFilters);

  const queryKey = key(baseQueryKey, pagination, filters);
  const countQueryKey = key(queryKey, 'count');
  const query = useQuery<TData, Failure>(queryKey, () => queryFn(session, pagination, filters));
  const countQuery = useQuery<TCount, Failure>(countQueryKey, () =>
    countQueryFn(session, pagination, filters),
  );

  return {
    query,
    countQuery,
    pagination,
    setPagination,
    filters,
    setFilters,
  };
}
