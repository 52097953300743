/* eslint-disable no-constant-condition */
import { IUserInfo } from '../../core/interfaces/IUserData';
import LogRocket from 'logrocket';
import { configureScope } from '@sentry/react';
import { AppTracker } from './tracker';
import { exception } from '../../core/exception';
import { API } from '../../core/api';
import userflow from 'userflow.js';
import { AppConfig } from 'shared/app.config';
import UsersService from 'core/services/service.users';
// import { getTimeFromObjectId } from '../../core/helpers/mongoObjectId';
// import { differenceInMonths } from 'date-fns';

/**
 * inits default logging for loggedIn users
 */

const initLogs = (
  d: IUserInfo,
  planId: string,
  planName: string,
  planStatus: string,
  cardStatus: string,
  organizationId: string,
  organizationName: string,
  organizationSlug: string,
  userRole: string,
) => {
  const { first_name, last_name, email, id } = d;
  const name = `${first_name} ${last_name}`;

  try {
    AppTracker.trackerInfo = {
      organizationId,
      organizationName,
      planId,
      planName,
      cardStatus,
      planStatus,
      organizationSlug,
      userRole,
      userEmailVerified: d!.email_verified,
      userId: d!.id,
      userName: `${d!.first_name} ${d!.last_name}`,
      userEmail: d!.email,
      platform: {
        os: window.navigator.platform,
        version: window.navigator.userAgent,
        isMobile: window.isMobile,
      },
      appVersion: API.appVersion,
      phoneVerified: d.phone_verified,
    };

    AppTracker.contact = d.contact;

    exception.userLog = {
      organizationId,
      userId: d!.id,
      userEmail: d!.email,
    };
  } catch (e: any) {
    exception.handle('E_DISABLED_APP_ANALYTICS', e);
  }

  if (import.meta.env.DEV) {
    return;
  }

  try {
    LogRocket.identify(id, {
      name,
      email,
      planId,
      planName,
    });
    LogRocket.getSessionURL(sid => configureScope(scope => scope.setExtra('@SID', sid)));

    const userService = new UsersService();

    const intercomSettings = {
      name,
      email,
      user_id: id,
      app_id: AppConfig.intercom,
      organizationSlug,
      organizationId,
      userRole,
      planName,
      planId,
      planStatus,
      cardStatus,
      userEmailVerified: d!.email_verified,
      phoneVerified: d.phone_verified,
      phoneNumber: d.contact.phone_number,
      countyCode: d.contact.dial_code,
      logRocket: `https://app.logrocket.com/${AppConfig.log_rocket}/sessions?u=${id}`,
    };

    userService
      .getUserHMACById(id)
      .then(({ data }) => {
        window.intercomSettings = {
          ...intercomSettings,
          user_hash: data?.data?.user_hash,
        };
        window.Intercom('update');
      })
      .catch(e => {
        window.intercomSettings = intercomSettings;
        window.Intercom('update');
      });

    // if (differenceInMonths(new Date(), getTimeFromObjectId(d!.id)) < 1) {
    userflow.init(AppConfig.userflow);
    setTimeout(() => {
      userflow.identify(d!.id, {
        organizationId,
        organizationName,
        planId,
        planName,
        name: `${d!.first_name} ${d!.last_name}`,
        email: d!.email,
        appVersion: API.appVersion,
        phoneVerified: d.phone_verified,
      });
    }, 1500);
  } catch (e: any) {
    exception.handle('E_DISABLED_APP_ANALYTICS', e);
  }
};

export { initLogs };
