import axios from 'axios';
import {
  ILCRData,
  LCRDataLogs,
  LCRDataLogsResponse,
  LCRDataResponse,
  LCRLimitResponse,
} from 'views/main/organization/lcr/common/interface';
import { COUNTRY_TYPE, ROUTING_TYPE } from 'views/main/organization/lcr/types/LCR.types';
import { API } from '../api';

class LCRService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}`;

  private _teamsId = API.config.teamId;

  public getLCRData = () =>
    axios.get<LCRDataResponse>(`${this._api}/routing-number?team_id=${this._teamsId}`);

  public getLCRDataById = (id: string) => axios.get<ILCRData>(`${this._api}/routing-number/${id}`);

  public deleteLCRData = (id: string) => axios.delete(`${this._api}/routing-number/${id}`);

  public updateLCRStatus = (id: string, enabled: boolean) =>
    axios.patch<ILCRData>(`${this._api}/routing-number/${id}`, {
      enabled: enabled,
    });

  public getRoutingNumber = (orgId: string, country: string, phone_number_type: string) =>
    axios.get(
      `${this._api}/routing-numbers/phone-number?country=${country}&phone_number_type=${phone_number_type}`,
      {},
    );

  public createRotutingNumber = (params: any) =>
    axios.post(`${this._api}/routing-number?team_id=${API.config.teamId}`, params);

  public getSessionLogs = (
    routingNumber: string,
    pageIndex: number,
    pageSize: number,
    assigned_to: string[],
    service_id: string[],
    search: string,
  ) => {
    const serviceIdParams = service_id.map(id => `&service_id=${id}`).join('');
    const assignedToParams = assigned_to.map(id => `&assigned_to_id=${id}`).join('');
    const searchParam = search ? `&search=${search}` : '';

    return axios.get<LCRDataLogsResponse>(
      `${this._api}/routing-number/${routingNumber}/call-sessions?page_number=${pageIndex}&page_size=${pageSize}${serviceIdParams}${assignedToParams}${searchParam}`,
    );
  };

  public getSessionLogsById = (routingNumber: string, sessionId: string) =>
    axios.get<LCRDataLogs>(
      `${this._api}/routing-number/${routingNumber}/call-sessions/${sessionId}`,
    );

  public getRoutingNumberById = (id: number) => axios.get(`${this._api}/routing-number/${id}`);

  public updateRotutingNumber = (params: any, id: any) =>
    axios.patch(`${this._api}/routing-number/${id}`, params);

  public getLCRLimit = () => axios.get<LCRLimitResponse>(`${this._api}/routing-numbers/limits`);
}

export default LCRService;
