import { Box, Center, Flex, HStack, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import { FormikProps, FormikValues } from 'formik';
import { Divider, FormButton, ListingButton, Tooltip, useToast } from 'library/atoms';
import { CustomDrawerComponent, FormikForm, Table } from 'library/molecules';
import { memo, useContext, useEffect, useState } from 'react';
import { AnyObject } from 'yup/lib/types';

import { IAttachmentRequest, OtherProps } from '../common/interfaces';
import { INCIDENT_DATA } from '../common/types';
import { IncidentParamsContext } from '../hooks/useAllParams';
import { FormValues } from '../interfaces/common';
import { INCIDENT_MERGE_COLUMN_DEFS } from './column-defs';
import { Cell } from 'react-table';
import { API, FileUploadService, IncidentService } from 'core';
import { IncidentCreateValidatorFunc, isCreationFormComplete } from '../create/validator';
import { generatePath, Link } from 'react-router-dom';
import { INCIDENT_DETAILS_PATH } from 'views/main/routes/routes';
import { FileUploadFeature, OperationType } from 'core/services/service.fileUpload';
import { getIncidentObj } from '../create/temp-store';
import { AppTracker } from 'shared/analytics/tracker';
import { linkStyles } from '../../navigation-flows/useUINavigationFunctionV2';
import { T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENTS_MERGED } from 'core/const/tracker';
import CreateIncidentTemplate from '../create/formTemplate';
import { truncate } from '../common/util';
import {
  MERGE_INCIDENT_MAX_CHILD_INCIDENTS_COUNT,
  MERGE_INCIDENT_PARENT_TEXT_MAX_LENGTH,
} from '../common/constants';
import { getPriorityValue } from 'library/common';
import { IncidentTag } from '../graphql/generated/types';

export const MergeIncident = memo(
  ({
    isOpen,
    onClose,
    incidents,
    setSelectedRowIds,
    invalListingData,
  }: {
    isOpen: boolean;
    incidents: Array<INCIDENT_DATA>;
    onClose: () => void;
    setSelectedRowIds: (checkedRows: Array<string>) => void;
    invalListingData: () => void;
  }) => {
    const [drawerData, setDrawerData] = useState<INCIDENT_DATA[]>([]);
    const {
      params: { isLoading, serviceListForFilters, assigneeList, tagData },
    } = useContext(IncidentParamsContext);
    const fileUploadService = new FileUploadService();
    const toast = useToast();

    const [parentIncidentID, setParentIncidentID] = useState<string>('');
    const [parentIncidentMessage, setParentIncidentMessage] = useState<string>('');
    const [hasSLOAffectingIncident, setHasSLOAffectingIncident] = useState<boolean>(false);
    const [parentIncidentExists, setParentIncidentExists] = useState<boolean>(false);
    const [disableCreateOption, setDisableCreateOption] = useState<boolean>(false);
    const [isMerging, setIsMerging] = useState<boolean>(false);
    const _incidentService = new IncidentService();

    const [mergeType, setMergeType] = useState<'existing-parent' | 'new-parent'>('existing-parent');

    const fileUploadHandler = fileUploadService.getUploadFunctionForFeature(
      FileUploadFeature.INCIDENT,
      OperationType.CREATE,
    );
    //this useEffect is done so that if in drawer we remove incident and close that drawer and again open drawer, there would be original props array data,not the data that was manipulated
    useEffect(() => {
      if (isOpen) {
        setDrawerData(incidents);
      } else {
        setDrawerData([]);
      }
    }, [isOpen, incidents]);

    useEffect(() => {
      const parentIncidentFromList = drawerData.find(incident => incident.children.length > 0);

      if (
        drawerData.filter(
          incident => incident.sloID > 0 && incident.id !== parentIncidentFromList?.id,
        ).length > 0
      ) {
        setHasSLOAffectingIncident(true);
      } else {
        setHasSLOAffectingIncident(false);
      }

      if (parentIncidentFromList) {
        setParentIncidentID(parentIncidentFromList.id);
        setParentIncidentMessage(parentIncidentFromList.message);
        setParentIncidentExists(true);
      } else {
        setParentIncidentID('');
        setParentIncidentMessage('');
        setParentIncidentExists(false);
      }

      if (drawerData.length > MERGE_INCIDENT_MAX_CHILD_INCIDENTS_COUNT) {
        setDisableCreateOption(true);
      } else {
        setDisableCreateOption(false);
      }
    }, [drawerData, mergeType]);

    const IncidentMergeAction = ({ rowData }: { rowData: Partial<Cell<INCIDENT_DATA>> }) => {
      const data: AnyObject = rowData?.row?.original ?? {};
      const onFilterData = () => {
        const filteredData = drawerData.filter(
          incident => incident.id !== rowData?.row?.original?.id,
        );
        if (parentIncidentID === data.id) {
          setParentIncidentID('');
        }
        setDrawerData(filteredData);
        if (
          filteredData.length === 0 ||
          (filteredData.length === 1 &&
            filteredData.filter(inc => inc.children.length > 0).length === 1)
        ) {
          onClose();
        }
      };
      return (
        <Flex gap={5} alignSelf="center" width="max-content">
          <ListingButton variant="secondary" title="Remove" onClick={onFilterData} />
        </Flex>
      );
    };
    const mergeFromExistingIncident = async () => {
      const childIncidents = drawerData
        .map(incident => incident.id)
        .filter(id => id !== parentIncidentID);
      const teamID = API.config.teamId;
      try {
        setIsMerging(true);
        const {
          data: { data },
        } = await _incidentService.mergeIncidentsInExistingParent(
          teamID,
          parentIncidentID,
          childIncidents,
        );
        if (data.message === 'merged incidents successfully') {
          AppTracker.track(T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENTS_MERGED, {
            parent_incident_type: parentIncidentExists
              ? 'Existing Parent Incident'
              : 'Existing Regular Incident',
            'Incident ID': [parentIncidentID, ...childIncidents],
          });
          setSelectedRowIds([]);
          toast({
            status: 'success',
            text: `Parent incident merged with ${data.children.length} incidents`,
          });

          onClose();
          invalListingData();
        }
      } catch (e: any) {
        toast({
          status: 'error',
          text: `An error has occured during merge: ${
            e?.response?.data?.meta?.error_message ?? 'Unknown error'
          }`,
        });
      } finally {
        setIsMerging(false);
      }
    };

    const mergeIntoNewIncident = async (incidentObj: Record<string, unknown>) => {
      const childIncidents = drawerData.map(incident => incident.id);

      const teamID = API.config.teamId;
      try {
        setIsMerging(true);
        const {
          data: { data },
        } = await _incidentService.mergeIncidentsInANewParent({
          owner_id: teamID,
          children: childIncidents,
          new_incident: incidentObj,
        });
        if (data.message === 'merged incidents successfully') {
          AppTracker.track(T_WA_UP_INCIDENT_LIST_PAGE_V2_INCIDENTS_MERGED, {
            parent_incident_type: 'New Incident',
            'Incident ID': childIncidents,
          });
          setSelectedRowIds([]);
          toast({
            status: 'success',
            text: `Parent incident merged with ${data.children.length} incidents`,
          });

          onClose();
          invalListingData();
        }
      } catch (e: any) {
        toast({
          status: 'error',
          text: `An error has occured during merge: ${
            e?.response?.data?.meta?.error_message ?? 'Unknown error'
          }`,
        });
      } finally {
        setIsMerging(false);
      }
    };

    const incidentsToBeMerged =
      mergeType === 'existing-parent' ? drawerData.length : drawerData.length + 1;

    const sloAffectingIncidentText = (
      <Text color="brand.white" fontWeight={800} fontSize={'14px'}>
        Merging Incidents will mark SLO affected child incidents as false positive
      </Text>
    );

    let parentIncidentText = (
      <Text style={{ ...linkStyles }}>
        {truncate(parentIncidentMessage, MERGE_INCIDENT_PARENT_TEXT_MAX_LENGTH)}
      </Text>
    );
    parentIncidentText =
      parentIncidentMessage.length > MERGE_INCIDENT_PARENT_TEXT_MAX_LENGTH ? (
        <Tooltip label={parentIncidentMessage} placement="bottom">
          {parentIncidentText}
        </Tooltip>
      ) : (
        parentIncidentText
      );

    return (
      <CustomDrawerComponent
        title={`Merge Incidents (${incidentsToBeMerged} incident${
          incidentsToBeMerged === 1 ? '' : 's'
        })`}
        isOpen={isOpen}
        onClose={onClose}
        disableBodyPadding
      >
        {!parentIncidentExists && (
          <VStack alignItems="flex-start" p={4} pb={0}>
            <Text>
              Select or create a parent incident. The others will be merged into the parent incident
              and marked as suppressed
            </Text>
            <Text color="secondary.700" fontWeight={800} mt={'var(--chakra-space-8) !important'}>
              PARENT INCIDENT
            </Text>
            <RadioGroup
              name="changeSelection"
              value={mergeType}
              onChange={(value: 'existing-parent' | 'new-parent') => {
                if (!(disableCreateOption && value === 'new-parent')) {
                  setMergeType(value);
                }
              }}
              mt={'var(--chakra-space-1) !important'}
              mb={'var(--chakra-space-2) !important'}
            >
              <Center>
                <Radio value="existing-parent" checked mr={5}>
                  <Text>Select an existing incident</Text>
                </Radio>
                <Tooltip
                  label={
                    disableCreateOption
                      ? `You cannot merge more than ${MERGE_INCIDENT_MAX_CHILD_INCIDENTS_COUNT} child incidents into a new parent`
                      : ''
                  }
                  placement="top"
                >
                  <Center>
                    <Radio value="new-parent" checked disabled={disableCreateOption}>
                      <Text>Create an Incident</Text>
                    </Radio>
                  </Center>
                </Tooltip>
              </Center>
            </RadioGroup>
          </VStack>
        )}
        {mergeType === 'existing-parent' ? (
          <Box
            maxH={`calc(100% - ${hasSLOAffectingIncident ? '45' : '35'}vh)`}
            overflowY="scroll"
            my={4}
          >
            {parentIncidentExists && (
              <>
                <VStack backgroundColor={'#F3F9FF'} m={4} p={6} gap={3} alignItems={'flex-start'}>
                  <Text fontWeight={800} color="#627C98">
                    PARENT INCIDENT
                  </Text>
                  <Link
                    to={generatePath(INCIDENT_DETAILS_PATH, { id: parentIncidentID })}
                    target="_blank"
                    style={{ marginTop: '0px' }}
                  >
                    {parentIncidentText}
                  </Link>
                </VStack>
                <Box m={4}>
                  <Text fontWeight={600} color="#09305A" fontSize={'18px'}>
                    Incidents
                  </Text>
                  <Text>
                    Below Incidents will be merged into parent incident and marked as suppressed
                  </Text>
                </Box>
              </>
            )}

            <RadioGroup value={parentIncidentID}>
              <Table
                hidePagination={true}
                hoverView={<IncidentMergeAction rowData={{}} />}
                hoverViewStyling={{
                  right: '16px',
                }}
                width="100%"
                columns={
                  parentIncidentExists
                    ? INCIDENT_MERGE_COLUMN_DEFS(setParentIncidentID).filter(
                        col => col.id !== 'select-incidents',
                      )
                    : INCIDENT_MERGE_COLUMN_DEFS(setParentIncidentID)
                }
                data={
                  parentIncidentExists
                    ? drawerData.filter(inc => inc.id !== parentIncidentID)
                    : drawerData
                }
                totalCount={10}
                onPageChange={async (a, b) => {}}
                headerStyles={{ padding: '14px 24px 14px 24px' }}
                containerStyles={{
                  overflow: 'unset',
                  '& thead': {
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    background: 'white',
                  },
                }}
              />
            </RadioGroup>

            <VStack
              alignItems="flex-start"
              bottom={0}
              position="absolute"
              width="100%"
              backgroundColor="brand.white"
            >
              {hasSLOAffectingIncident && (
                <VStack m={4} mb={0} backgroundColor="brand.white" alignItems="flex-start">
                  <Box
                    backgroundColor="#f7766d"
                    py={2}
                    px={3}
                    width={'fit-content'}
                    borderRadius={'4px'}
                    marginBottom={2}
                  >
                    {sloAffectingIncidentText}
                  </Box>
                </VStack>
              )}

              <Divider />
              <HStack spacing={3} p={4}>
                <FormButton
                  disabled={parentIncidentID === '' || isMerging}
                  type="submit"
                  title="Merge Incidents"
                  onClick={mergeFromExistingIncident}
                  isLoading={isMerging}
                />
                <FormButton
                  title="Cancel"
                  variant="secondary"
                  onClick={onClose}
                  isDisabled={isMerging}
                />
              </HStack>
            </VStack>
          </Box>
        ) : (
          <Box maxH={`calc(100% - ${hasSLOAffectingIncident ? '40' : '30'}vh)`} overflowY="auto">
            <FormikForm
              validate={IncidentCreateValidatorFunc}
              handleSubmit={(values: FormikValues | FormValues) => {
                const tagObject: Record<string, unknown> = {};
                values.tags.forEach((tag: IncidentTag) => {
                  if (tag.key && tag.value) {
                    tagObject[tag.key] = { value: tag.value, color: tag.color };
                  }
                });
                const input = {
                  message: values.title,
                  description: values.description ?? '',
                  assignee: {
                    type: values.assignedkey.value,
                    id: values.assigned.value,
                  },
                  service_id: values.services.value,
                  tags: tagObject,
                  priority: getPriorityValue(values.priority.value),
                  attachments: fileUploadService
                    .accessAttachmentArrayForIncident()
                    .reduce((acc: any[], item: IAttachmentRequest) => {
                      acc.push({
                        file_size: item.file_size,
                        mime_type: item.mime_type,
                        key: item.key,
                      });
                      return acc;
                    }, []),
                };

                mergeIntoNewIncident(input);
              }}
              mapPropsToValues={(): FormValues => getIncidentObj()}
              formTemplate={(props: OtherProps & FormikProps<FormValues>) => (
                <CreateIncidentTemplate
                  {...props}
                  {...{
                    isLoading,
                    assigneeList,
                    fileUploadHandler,
                    serviceListForFilters,
                    tagData,
                    additionalFormElements: (
                      <VStack
                        alignItems="flex-start"
                        bottom={0}
                        position="absolute"
                        width="100%"
                        backgroundColor="brand.white"
                      >
                        {hasSLOAffectingIncident && (
                          <VStack
                            m={4}
                            mb={0}
                            backgroundColor="brand.white"
                            alignItems="flex-start"
                          >
                            <Box
                              backgroundColor="#f7766d"
                              py={2}
                              px={3}
                              width={'fit-content'}
                              borderRadius={'4px'}
                              marginBottom={2}
                            >
                              {sloAffectingIncidentText}
                            </Box>
                          </VStack>
                        )}
                        <Divider />
                        <HStack spacing={3} p={4}>
                          <FormButton
                            type="submit"
                            title="Create and Merge Incidents"
                            isLoading={isMerging}
                            isDisabled={
                              !isCreationFormComplete(props.values) || disableCreateOption
                            }
                          />
                          <FormButton
                            title="Cancel"
                            variant="secondary"
                            onClick={onClose}
                            isDisabled={isMerging}
                          />
                        </HStack>
                      </VStack>
                    ),
                  }}
                />
              )}
            ></FormikForm>
          </Box>
        )}
      </CustomDrawerComponent>
    );
  },
);
