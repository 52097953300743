import { IAppState } from 'core/interfaces/IAppState';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { planFeatures } from 'store/billingPlans';

type BillingPlan = 'Free' | 'Pro' | 'Enterprise';

function useOrganizationPlan() {
  const organization = useSelector((state: IAppState) => state.organization);

  const planType = useMemo(
    () =>
      (organization?.plan.p?.active
        ? planFeatures.plans.find(plan =>
            Object.values(plan).includes(organization.plan.p?.plan_slug),
          )?.name ?? 'Free'
        : 'Free') as BillingPlan,
    [organization],
  );

  return planType;
}

export default useOrganizationPlan;
