import { useBoolean } from '@chakra-ui/hooks';
import { T_WA_UP_GER_RULE_CREATED, T_WA_UP_GER_RULE_EDITED } from 'core/const/tracker';
import { Formik } from 'formik';
import { useToast } from 'library/atoms';
import { FormikForm } from 'library/molecules';
import { FC, useContext, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { AppTracker } from 'shared/analytics/tracker';

import { AppContext } from '../..';
import { createGerRulesetRule } from '../../query/useCreateGerRulesetRule';
import { invalidateGERDetail } from '../../query/useGetGerDetail';
import { invalidateGERRulesetList } from '../../query/useGetGerRulesetRules';
import { updateGerRulesetRule } from '../../query/useUpdateGerRulesetRule';
import { ALERT_API_INFO, DROPDOWN_OPTIONS, RULE_FORM, RULESET_RULE } from '../../types';
import { getServiceOptions } from '../../util';
import { EMPTY_RULE } from './constants';
import { GERRuleValidation } from './rule-validator';
import { serializeRuleObj } from './temp-store';
import { RuleTemplate } from './template';

type Props = {
  gerId: string;
  alert: ALERT_API_INFO;
  rule?: RULESET_RULE;
  isReadOnly?: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const RuleForm: FC<Props> = ({ gerId, alert, rule, onClose, onSuccess, isReadOnly }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const context = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useBoolean();

  const services: DROPDOWN_OPTIONS[] = useMemo(
    () => getServiceOptions(context?.organization),
    [context?.organization],
  );

  const Rule = (props: any) => (
    <RuleTemplate services={services} isReadOnly={isReadOnly} {...props} />
  );

  const onAPISuccess = (messageOp: string, eventName: string, ruleId: string) => {
    toast({
      status: 'success',
      text: `Success: Rule ${messageOp} successfully`,
    });
    AppTracker.track(eventName, {
      'GER Ruleset ID': gerId,
      'GER Rule ID': ruleId,
      'Alert Source Name': alert.shortName,
    });
    invalidateGERDetail(queryClient);
    invalidateGERRulesetList(queryClient);
    onSuccess();
  };

  const onError = (err: any) => {
    const errMsg =
      err?.response?.data?.meta.error_message ?? 'Some error occurred while mutating ruleset';

    toast({
      status: 'error',
      text: `Error: ${errMsg}`,
    });
    console.error(err);
  };

  const handleSubmit = (values: RULE_FORM) => {
    if (isSubmitting) return;

    const trimmedValues: RULE_FORM = {
      description: values.description.trim(),
      expression: values.expression.trim(),
      service: values.service,
    };
    setIsSubmitting.on();
    if (rule) {
      // update
      updateGerRulesetRule(gerId, alert.version, alert.shortName, rule.id, trimmedValues)
        .then(() => {
          onAPISuccess('updated', T_WA_UP_GER_RULE_EDITED, rule.id);
        })
        .catch(err => onError(err))
        .finally(setIsSubmitting.off);
    } else {
      // create
      createGerRulesetRule(gerId, alert.version, alert.shortName, trimmedValues)
        .then(({ data }) => {
          onAPISuccess('added', T_WA_UP_GER_RULE_CREATED, data.data.id);
        })
        .catch(err => onError(err))
        .finally(setIsSubmitting.off);
    }
  };

  const getRule = (): RULE_FORM => {
    //previous guard clause is removed because it couldn't load new session data on edit drawer
    if (rule) {
      const {
        description,
        expression,
        action: { route_to: id },
      } = rule;
      const service = services.find(service => service.value == id) ?? null;
      const data = {
        description,
        expression,
        service,
      };
      serializeRuleObj(data);
      return data;
    }

    return EMPTY_RULE;
  };

  return (
    // mapProps was causing  microsec delay in loading data causing old data to show then loading new data in form initial values, so replaced wit with formik form.
    <Formik initialValues={getRule()} validationSchema={GERRuleValidation} onSubmit={handleSubmit}>
      {formik => {
        return <RuleTemplate services={services} isReadOnly={!!isReadOnly} {...formik} />;
      }}
    </Formik>
  );
};
