import { Box, Text } from '@chakra-ui/react';
import { FormButton, useToast } from 'library/atoms';
import { Modal } from 'library/molecules';
import { useQueryClient } from 'react-query';

import { BaseModalProps } from '../../common/types';
import { truncate } from '../../common/util';
import { useDeleteIncidentGroupMutation } from '../../graphql/mutation';
import { filterManager } from '../manager';

type Props = BaseModalProps & {
  name: string;
  id: number;
};

export const DeleteSavedFilter = ({ isOpen, onClose, id, name }: Props) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteFilters, isLoading } = useDeleteIncidentGroupMutation();

  const handleDelete = () => {
    deleteFilters({ incidentGroupID: id })
      .then(() => {
        // applied filter is deleted
        if (id === filterManager.getUIFilters().id) {
          filterManager.clearFilters(queryClient);
        }
        toast({
          status: 'success',
          text: `Success: '${truncate(name, 25)}' deleted`,
        });
        queryClient.invalidateQueries('listIncidentGroups');
        onClose();
      })
      .catch(() => {
        toast({
          status: 'error',
          text: `Error: '${truncate(name, 25)}' could not be deleted`,
        });
      });
  };

  return (
    <Modal
      key="delete-saved-filter"
      title={`Delete '${truncate(name, 25)}'`}
      body={
        <Box display="flex" flexDirection="column" gap="10px">
          <Text variant="formInput">
            {`Are you sure you want to delete '${truncate(name, 100)}'?`}
            <br />
            You can’t undo this action afterwards.
          </Text>
        </Box>
      }
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={
        <FormButton isLoading={isLoading} title="Delete" onClick={handleDelete} variant="danger" />
      }
      secondaryButton={
        <FormButton onClick={onClose} title="Cancel" variant="secondary" isDisabled={isLoading} />
      }
    />
  );
};
