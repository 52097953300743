import React, { memo, useMemo } from 'react';

import { Box, Button, Grid, GridItem, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import ScheduleAvatarGroup from '../../../common/AvatarGroup';
import { IRotationEventParticipants } from '../../../interface/schedule';
import {
  IMountOverrideRoute,
  mountRemoveOverrideRoute,
  mountOverrideRoute,
} from '../../../helpers/helpers.override';
import { mountEndRotationRoute } from '../../../helpers/helpers.endRotation';
import { getTZDifference } from '../../../helpers/helpers.date';
import { Locale } from 'core/helpers/dateUtils';
import { isSameDayEvent, mapParticipantToEventParticipant } from '../../../helpers/helpers.event';
import { IPatternParticipant } from '../../../helpers/helpers.customrotations';
import { ScheduleOverride } from 'views/main/organization/schedules/graphql/types';
import SQTooltip from 'components/chakra/Tooltip';
import { TextButton } from 'uie/components';
import { Theme } from 'uie/components';
import { USER_PROFILE_PATH, TEAM_SQUADS_PATH } from 'views/main/routes/routes';
import { useOrganizationConfig } from '../../..';
import { ClockIcon, UsernameIcon } from 'icons';
import { THEME_COLORS } from 'library/theme/colors';
import { truncate } from 'core/helpers/stringUtils';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

interface Props {
  participants: IRotationEventParticipants;
  allParticipants: IPatternParticipant[];
  schedule: string;
  rotation: string;
  dateRange: string;
  repeats: string;
  startTime: string;
  endTime: string;
  startTimeString?: string;
  endTimeString?: string;
  disableEventModifiers?: boolean | undefined;
  timeZone: string;
  rotationId: number;
  eventId: string;
  override?: ScheduleOverride & { enabled: boolean };
  startDate?: string;
  endDate?: string;
  showScheduleName?: boolean;
  eventOffset: number;
  scheduleID: number;
}

export const EventDetails = memo(
  ({
    participants,
    allParticipants,
    schedule,
    rotation,
    dateRange,
    repeats,
    startTime,
    endTime,
    startTimeString,
    endTimeString,
    disableEventModifiers = false,
    rotationId,
    eventId,
    override,
    startDate,
    endDate,
    showScheduleName = true,
    eventOffset,
    scheduleID,
  }: Props) => {
    const history = useHistory();

    const params = useParams<{ scheduleId: 'string' }>();

    const hasUpdate = useUserAccess().hasUpdateAccess(
      'schedules',
      `${scheduleID ? scheduleID : params.scheduleId}`,
    );

    const organization = useOrganizationConfig().organization;

    const onRemoveOverride = () => {
      const params = mountRemoveOverrideRoute({
        pathname: history.location.pathname,
        search: history.location.search,
        removeOverrideId: override?.ID.toString() ?? '',
        removeOverrideRotation: rotationId.toString(),
      });
      history.push(params);
    };

    const onOverride = () => {
      let params = {
        pathname: history.location.pathname,
        search: history.location.search,
        overrideParticipants: participants?.flatMap(p => p.ID),
      } as IMountOverrideRoute;

      if (override?.enabled) {
        params = {
          ...params,
          overrideId: override.ID.toString() ?? '',
          overrideRotation: undefined,
          overrideStartDate: undefined,
          overrideEndDate: undefined,
        };
      } else {
        params = {
          ...params,
          overrideId: undefined,
          overrideStartDate: startDate,
          overrideEndDate: endDate,
          overrideRotation: rotationId.toString(),
          overrideStartTime: startTimeString ?? startTime,
          overrideEndTime: endTimeString ?? endTime,
        };
      }

      const overrideParams = mountOverrideRoute(params);
      history.push(overrideParams);
    };

    const TEXT_MAX_LENGTH = 30;

    const allParticipantDeleted = participants?.every(
      p => !allParticipants.find(participant => p.ID === participant.id),
    );

    return (
      <Box
        py={2}
        px={3}
        onMouseDown={evt => {
          evt.stopPropagation();
          evt.preventDefault();
        }}
      >
        <VStack rowGap={1} alignItems="flex-start" maxH={40} overflow="auto">
          {participants?.map(p => {
            const participantTimeZone =
              allParticipants.find(participant => p.ID === participant.id)?.timeZone ?? '';
            const participantTZDiff = participantTimeZone
              ? getTZDifference(participantTimeZone, Locale.userZone).relativeString
              : '';
            const mappedParticipant = (
              override?.overrideWith?.participants as IRotationEventParticipants
            )?.find(pt => pt.ID === p.ID)
              ? mapParticipantToEventParticipant(p, allParticipants, override)
              : mapParticipantToEventParticipant(p, allParticipants);
            const fullName =
              mappedParticipant.participant.name ??
              (mappedParticipant.type == 'squad' ? 'Deleted Squad' : 'Deleted User');
            const truncatedName = truncate(
              mappedParticipant.participant.name ??
                (mappedParticipant.type == 'squad' ? 'Deleted Squad' : 'Deleted User'),
              TEXT_MAX_LENGTH,
            );

            return (
              <HStack key={p.ID} width={'100%'} alignItems="flex-start">
                <ScheduleAvatarGroup
                  avatars={
                    [
                      mappedParticipant.type === 'squad'
                        ? mappedParticipant.participant.members
                        : mappedParticipant.participant,
                    ] as {
                      name: string;
                    }[]
                  }
                  size="md"
                  wrapper={mappedParticipant.isOverride ? 'override' : undefined}
                />
                <VStack spacing={0} alignItems="flex-start" flex={1}>
                  <HStack width={'100%'} justifyContent={'space-between'} alignItems="flex-start">
                    <Text fontSize="md" fontWeight="bold">
                      <Tooltip
                        label={fullName}
                        color={THEME_COLORS.secondary[1000]}
                        bg={THEME_COLORS.secondary[100]}
                        isDisabled={fullName.length < TEXT_MAX_LENGTH}
                        placement="top"
                        hasArrow
                      >
                        <Text>{truncatedName}</Text>
                      </Tooltip>
                      {mappedParticipant.participant.username && (
                        <HStack alignItems="flex-start">
                          <UsernameIcon style={{ marginTop: 3 }} />
                          <Text
                            fontSize="sm"
                            color="gray.700"
                            ml={'var(--chakra-space-1) !important'}
                            maxW={`${TEXT_MAX_LENGTH}ch`}
                            fontWeight="normal"
                          >
                            {mappedParticipant.participant.username}
                          </Text>
                        </HStack>
                      )}
                    </Text>
                    {mappedParticipant.participant.name && (
                      <TextButton
                        buttonType="inverted"
                        style={{
                          fontSize: '12px',
                          height: '20px',
                          color: '#0f61dd',
                          marginRight: 2,
                          marginTop: 2,
                        }}
                        onClick={() =>
                          history.push(
                            mappedParticipant.type === 'squad'
                              ? generatePath(TEAM_SQUADS_PATH, {
                                  id: organization.selectedTeam.teamId,
                                  sqid: mappedParticipant.ID,
                                })
                              : generatePath(USER_PROFILE_PATH, {
                                  id: mappedParticipant.ID,
                                }),
                          )
                        }
                      >
                        View Profile
                      </TextButton>
                    )}
                  </HStack>
                  {participantTimeZone && (
                    <HStack>
                      <ClockIcon />
                      <Text
                        fontSize="sm"
                        color="gray.700"
                        ml={'var(--chakra-space-1) !important'}
                        maxW={`${TEXT_MAX_LENGTH}ch`}
                      >
                        {participantTimeZone}
                        {participantTZDiff ? ` | ${participantTZDiff}` : ''}
                      </Text>
                    </HStack>
                  )}
                </VStack>
              </HStack>
            );
          })}
        </VStack>

        {showScheduleName && (
          <VStack alignItems="flex-start" spacing={0} p={2}>
            <Text color="gray.700">SCHEDULE</Text>
            <SQTooltip text={schedule}>
              <Text fontWeight="bold" maxW={250} isTruncated>
                {schedule}
              </Text>
            </SQTooltip>
          </VStack>
        )}

        <VStack alignItems="flex-start" spacing={0} p={2}>
          {!override?.enabled ? (
            <>
              <Text color="gray.700">ROTATION</Text>
              <SQTooltip text={rotation}>
                <Text fontWeight="bold" maxW={250} isTruncated>
                  {rotation}
                </Text>
              </SQTooltip>
            </>
          ) : (
            <Text fontWeight="bold">Override</Text>
          )}
        </VStack>

        <Grid
          templateColumns="1.5fr 1.5fr"
          templateRows={`repeat(2, 1fr)`}
          rowGap={5}
          columnGap={5}
          p={2}
          alignItems="center"
        >
          <GridItem colSpan={2}>
            <VStack alignItems="flex-start" spacing={0}>
              <Text color="gray.700">{startDate === endDate ? 'DATE' : 'START - END DATE'}</Text>
              <Text fontWeight="bold">
                {startDate === endDate ? dateRange.split(' - ')[1] : dateRange}
              </Text>
            </VStack>
          </GridItem>

          <GridItem>
            <VStack alignItems="flex-start" spacing={0}>
              <Text color="gray.700">REPEATS</Text>
              <Text fontWeight="bold" textTransform="capitalize">
                {override?.enabled ? '-' : repeats}
              </Text>
            </VStack>
          </GridItem>

          <GridItem>
            <VStack alignItems="flex-start" spacing={0}>
              <Text color="gray.700">START - END TIME</Text>
              <Text fontWeight="bold">
                {[startTimeString ?? startTime, endTimeString ?? endTime].join(' - ')}
                <Text as="span" ml={1} textColor="gray.700">
                  {eventOffset > 0 ? `+${eventOffset}` : ``}
                </Text>
              </Text>
            </VStack>
          </GridItem>
        </Grid>

        {override?.enabled && (
          <Box bg="blue.200" borderRadius={5} p={3} justifyContent="flex-start">
            <VStack alignItems="flex-start">
              {(override.overriddenParticipant?.participants as IRotationEventParticipants)?.map(
                (p, index) => {
                  const mappedParticipant = mapParticipantToEventParticipant(p, allParticipants);
                  const fullName = mappedParticipant.participant.name ?? '';
                  return (
                    <HStack alignItems="flex-start" key={p.ID}>
                      <ScheduleAvatarGroup
                        avatars={
                          [
                            mappedParticipant.type === 'squad'
                              ? mappedParticipant.participant.members
                              : mappedParticipant.participant,
                          ] as {
                            name: string;
                          }[]
                        }
                        size="sm"
                      />
                      <VStack alignItems="flex-start" spacing={0}>
                        {index === 0 && (
                          <Text fontSize="xs" color="gray.700">
                            Originally Oncall
                          </Text>
                        )}
                        <Tooltip
                          label={fullName}
                          color={THEME_COLORS.secondary[1000]}
                          bg={THEME_COLORS.secondary[100]}
                          isDisabled={fullName.length < TEXT_MAX_LENGTH}
                          placement="top"
                          hasArrow
                        >
                          <Text fontSize="md">{truncate(fullName ?? '', TEXT_MAX_LENGTH)}</Text>
                        </Tooltip>

                        {mappedParticipant.participant.username && (
                          <HStack alignItems="flex-start">
                            <UsernameIcon style={{ marginTop: 3 }} />
                            <Text
                              maxW={`${TEXT_MAX_LENGTH}ch`}
                              color={THEME_COLORS.secondary[600]}
                              ml={'var(--chakra-space-1) !important'}
                              fontSize="sm"
                            >
                              {mappedParticipant.participant.username}
                            </Text>
                          </HStack>
                        )}

                        <Text fontSize="xs">{override.reason}</Text>
                      </VStack>
                    </HStack>
                  );
                },
              )}
            </VStack>
          </Box>
        )}

        {!disableEventModifiers && (
          <HStack gap={1} mt={3} p={2}>
            <NoPermissionTooltip isDisabled={hasUpdate}>
              <Button
                onClick={onOverride}
                isDisabled={(!override?.enabled && allParticipantDeleted) || !hasUpdate}
              >
                {override?.enabled ? 'Edit Override' : 'Override'}
              </Button>
            </NoPermissionTooltip>
            {override?.enabled && (
              <NoPermissionTooltip isDisabled={hasUpdate}>
                <Button
                  variant="invertedDefault"
                  isDisabled={!hasUpdate}
                  onClick={onRemoveOverride}
                >
                  Remove Override
                </Button>
              </NoPermissionTooltip>
            )}
            {/* TODO: Enable when API intergrated */}
            {/* <Button variant="outline_danger" onClick={onEndRotation}>
              End Rotation
            </Button> */}
          </HStack>
        )}
      </Box>
    );
  },
);
