import Axios from 'axios';
import { useQuery } from 'react-query';

import { API } from 'core';

interface IntegrationData {
  id: number;
  name: string;
  description: string;
  slug: string;
}

const fetchIntegrationList = async () => {
  const { data } = await Axios.get<{ data: IntegrationData[] }>(
    `${API.config.batman}/public/integration-categories`,
  );
  return data.data;
};

export function useIntegrationList() {
  const { data, isSuccess, isError, isLoading } = useQuery(
    ['integration-categories'],
    () => fetchIntegrationList(),
    {
      enabled: true,
      retry: 1,
    },
  );
  const integrationOptions = data?.map(integration => ({
    label: integration.name,
    value: integration.slug,
  }));

  return {
    integrationOptions,
    isSuccess,
    isLoading,
  };
}
