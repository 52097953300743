import { ILog } from 'core/interfaces/IIncidents';
import { IStatusPage } from 'core/interfaces/IStatusPage';
import JiraMessages from '../customMessages/JiraMessages';
import GeneralMessages from '../GeneralMessages';
import WorkflowMessages from '../workflowMessages/WorkflowMessages';

const MessageBasedonAction = ({
  data,
  userName,
  statusPages,
  setFocusMessageId,
}: {
  data: {
    log: ILog;
    logMessageActivity: string;
    entityComponent: JSX.Element;
  };
  userName: any;
  statusPages: {
    [key: string]: IStatusPage;
  };
  setFocusMessageId: (id: string, type?: string | undefined, index?: number | undefined) => void;
}) => {
  return (
    <>
      <JiraMessages log={data.log} />
      <WorkflowMessages {...data} />
      <GeneralMessages
        setFocusMessageId={setFocusMessageId}
        statusPages={statusPages}
        userName={userName}
        {...data}
      />
    </>
  );
};

export default MessageBasedonAction;
