import styled from 'styled-components';

export const Shell = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  width: 100%;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  will-change: opacity, transform;
  pointer-events: none;

  &.fly {
    transform: translateY(-30px) !important;
    opacity: 1 !important;
  }
`;

export const Flyer = styled.div`
  background: ${props => props.theme.shades.cement};
  box-shadow: 0 0 2px 0 rgba(2, 2, 2, 0.16);
  width: 300px;
  padding: 20px;
  color: ${props => props.theme.font.white};
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
`;
