import { Box, Flex } from '@chakra-ui/layout';
import { useFormContext } from 'react-hook-form';
import { MSTeamMessageChannelAction, MsTeamOption } from 'views/main/organization/workflow/types';
import ReactSelect from 'react-select';
import { MdBlock } from 'uie/components';
import { useCheckMsTeamStatus } from 'views/main/organization/workflow/details/hooks/useCheckMsTeamStatus';
import { useMemo } from 'react';
import FormField from 'library/molecules/FormField/FormField';

const MessageTeamChannelForm = () => {
  const {
    formState: { errors },
    setValue,
    trigger,
    watch,
    getValues,
  } = useFormContext<MSTeamMessageChannelAction>();

  const teamChannel = watch('data.channel_id');
  const msTeamData = useCheckMsTeamStatus();
  const channelName = msTeamData.data?.connected_teams;

  const channelOptions = useMemo(() => {
    return (
      channelName?.flatMap(team => {
        return (
          team.channel_configurations?.map(channel => {
            const option: MsTeamOption = {
              label: channel.msteams_channel_name + '-' + `${team.team_name}`,
              value: channel.msteams_channel_id,
            };
            return option;
          }) || []
        );
      }) || []
    );
  }, [msTeamData.isSuccess, msTeamData.isRefetching, channelName]);

  const selectedChannel = channelOptions?.find(channel => channel.value === teamChannel);
  return (
    <Flex px={6} pt={4} flexDirection="column">
      <Box mb={3} style={{ position: 'relative', zIndex: 2 }}>
        <FormField
          label="Select Microsoft Teams Channel"
          error={errors?.data?.channel_id && errors.data.channel_id.message}
        >
          <Box w="20rem" mr={2}>
            <ReactSelect
              onChange={o => {
                setValue('data.channel_id', o!.value, { shouldDirty: true });
                trigger(`data.channel_id`);
              }}
              value={selectedChannel}
              options={channelOptions}
              placeholder="Select Channel"
            />
          </Box>
        </FormField>
      </Box>
      <Box mb={2}>
        <FormField label="Message" error={errors?.data?.message && errors.data.message.message}>
          <MdBlock
            value={getValues('data.message')}
            onChange={content => setValue('data.message', content, { shouldDirty: true })}
            options={{
              hideIcons: ['upload-image', 'upload-file'],
              maxHeight: '130px',
              spellChecker: false,
              placeholder: 'Eg: {{event.incident_id}} triggered',
            }}
          />
        </FormField>
      </Box>
    </Flex>
  );
};

export default MessageTeamChannelForm;
