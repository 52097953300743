import 'react-datepicker/dist/react-datepicker.css';

import React, { forwardRef } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { Box, Button, HStack, Input, Text } from '@chakra-ui/react';

import { useDatepickerStyles } from './style';

type Props = Omit<ReactDatePickerProps, 'selected' | 'onChange'> & {
  date: Date;
  setDate: (date: Date) => void;
  dateFormat?: string;
};

function ChakraDatepicker({ date, setDate, dateFormat, ...args }: Props) {
  const getYear = (date: Date) => date.getFullYear();
  const getMonthName = (date: Date) =>
    new Date(date).toLocaleDateString('default', { month: 'long' });

  const styles = useDatepickerStyles({});

  return (
    <Box sx={styles}>
      <DatePicker
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <HStack mx={3} my={1}>
            <Text color="gray.700" fontWeight="semibold" fontSize="sm" minW={20}>
              {getMonthName(date)}
            </Text>
            <Text color="gray.700" fontWeight="semibold" fontSize="sm">
              {getYear(date)}
            </Text>

            <Button
              variant="link"
              minW={3}
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </Button>

            <Button
              variant="link"
              minW={3}
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </Button>
          </HStack>
        )}
        {...args}
        selected={date}
        onChange={setDate}
        customInput={<DatePickerInput />}
        dateFormat={dateFormat ?? 'MM/dd/yyyy'}
      />
    </Box>
  );
}

const DatePickerInput = forwardRef<HTMLInputElement, { value?: string; onClick?: () => void }>(
  (props, ref) => <Input className="datepicker-input" ref={ref} {...props} />,
);

export default ChakraDatepicker;
