import Axios from 'axios';
import { API } from 'core';
import { AssigneeType } from 'library/enums';

export const updateGer = (
  gerId: string,
  name: string,
  id: string,
  type: AssigneeType,
  desc?: string,
) => {
  const body = {
    name,
    description: desc,
    entity_owner: {
      id,
      type,
    },
  };
  return Axios.patch(
    `${API.config.batman}/organizations/${API.config.organizationId}/global-event-rules/${gerId}`,
    body,
  );
};
