import React, { Component, ChangeEvent } from 'react';
import { IComponentErrorState } from '../../../core/interfaces/IComponentState';
import { AuthService } from '../../../core/services';
import cx from 'classnames';
import { Redirect } from 'react-router';
import { Heading } from 'uie/components';
/* eslint-disable react/prop-types */
interface IProps {}

interface IState {
  password1: string;
  password2: string;
  errors: IComponentErrorState;
  routeBack: '/' | '/login' | '/forgot-password' | '';
  resetsuccess: boolean;
  passwordShown: boolean;
  confirmPasswordShown: boolean;
}

const errorMessage = [
  { error_code: 'E1000', msg: 'Invalid Token', type: 'PASSWORD_RESET_LINK' },
  { error_code: 'E1001', msg: 'Internal Error. Please try again', type: 'PASSWORD_RESET_LINK' },
  { error_code: 'E1002', msg: 'Recovery Link expired', type: 'PASSWORD_RESET_LINK' },
];
class ResetPassword extends Component<IProps, IState> {
  private _authService = new AuthService();
  private queryParams = new URLSearchParams(window.location.search);

  constructor(props: IProps) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
      errors: {},
      routeBack: '',
      resetsuccess: false,
      passwordShown: false,
      confirmPasswordShown: false,
    };
  }

  async componentDidMount() {
    const parseparams = this.queryParams.get('error_code');
    const ch = errorMessage.find(({ error_code }) => {
      return error_code === parseparams;
    });
    if (parseparams != null) {
      this.setState({
        errors: {
          validate: ch?.msg || '',
        },
      });
      setTimeout(() => {
        this.setState({ routeBack: '/forgot-password' });
      }, 2000);
    }
  }

  public onValueChange = (key: 'password1' | 'password2') => (e: ChangeEvent<HTMLInputElement>) => {
    const newState: any = {};
    newState[key] = e.target.value || '';
    newState.errors = {};
    this.setState(newState);
  };

  public isValid = () => {
    const { password1, password2 } = this.state;
    const errors: any = {};

    if (passwordVerification(password1).length > 0) {
      errors.password1 = 'Password length should be between 10 and 72 characters';
    }

    if (password1 !== password2) {
      errors.password2 = 'Passwords donot match. Please try again.';
    }

    this.setState({ errors });

    return Object.entries(errors).length === 0;
  };

  public reset = async (e?: any) => {
    if (e) {
      e.preventDefault();
    }

    if (!this.isValid()) {
      return;
    }

    try {
      const { data } = await this._authService.resetPassword(this.state.password1);
      if (data.data.result) {
        this.setState({
          resetsuccess: true,
        });
      }

      setTimeout(() => {
        this.setState({ routeBack: '/' });
      }, 2000);
    } catch (err: any) {
      if (err?.response?.data?.meta?.message === 'Recovery Link expired') {
        this.setState({
          errors: {
            reset: 'Recovery Link expired',
          },
        });
        setTimeout(() => {
          this.setState({ routeBack: '/forgot-password' });
        }, 2000);
      } else {
        this.setState({
          errors: {
            reset: 'Network error. Please try again.',
          },
        });
      }
    } finally {
      this.setState({
        password1: '',
        password2: '',
      });
    }
  };

  public togglePassword = () => {
    this.setState({ passwordShown: !this.state.passwordShown });
  };

  public confirmTogglePassword = () => {
    this.setState({ confirmPasswordShown: !this.state.confirmPasswordShown });
  };

  render() {
    if (this.state.routeBack !== '') {
      return <Redirect to={this.state.routeBack} exact={true} />;
    }

    const { password1, password2, errors, resetsuccess } = this.state;

    return (
      <div className="login-container">
        <div className="login-interact">
          {errors.validate ? (
            <p className="login-error-message">{errors.validate}</p>
          ) : (
            <>
              <div
                style={{
                  fontSize: '14px',
                  lineHeight: '16px',
                  textAlign: 'center',
                  marginBottom: '100px',
                }}
              >
                <img
                  className="banner-logo"
                  src="/assets/new_logo.svg"
                  alt="Squadcast-logo"
                  style={{ width: '180px', marginBottom: '4px' }}
                />
                <div style={{ marginBottom: '24px' }}>
                  Incident Response for SRE, Devops & IT teams
                </div>
              </div>
              <div style={{ marginBottom: 24, textAlign: 'center' }}>
                <div>
                  <img
                    src="/assets/reset-password.svg"
                    alt="Reset Password"
                    style={{ width: '42px', marginBottom: '20px' }}
                  />
                </div>
                <Heading fontSize={24} height={24}>
                  Reset password
                </Heading>
              </div>

              <form onSubmit={this.reset}>
                <div
                  className="login-input-container login-m-t-60"
                  style={{ position: 'relative' }}
                >
                  <label className="login-label dark" htmlFor="login_password_1">
                    New Password
                  </label>
                  <input
                    id="login_password_1"
                    className={cx('login-input', {
                      error: errors.password1 && errors.password1.length > 0,
                    })}
                    value={password1}
                    placeholder="10+ Characters"
                    onChange={this.onValueChange('password1')}
                    type={this.state.passwordShown ? 'text' : 'password'}
                  />
                  <img
                    src={
                      this.state.passwordShown ? '/assets/eye-open.svg' : '/assets/eye-closed.svg'
                    }
                    onClick={this.togglePassword}
                    style={{
                      width: '5%',
                      position: 'absolute',
                      top: '37%',
                      right: '2%',
                      cursor: 'pointer',
                    }}
                  />
                  <p className="login-error-message">{errors.password1 || ''}</p>
                </div>

                <div className="login-input-container" style={{ position: 'relative' }}>
                  <label className="login-label dark" htmlFor="login_password_2">
                    <span>Confirm Password</span>
                  </label>
                  <input
                    id="login_password_2"
                    className={cx('login-input', {
                      error: errors.password2 && errors.password2.length > 0,
                    })}
                    value={password2}
                    placeholder="Confirm your password"
                    onChange={this.onValueChange('password2')}
                    type={this.state.confirmPasswordShown ? 'text' : 'password'}
                  />
                  <img
                    src={
                      this.state.confirmPasswordShown
                        ? '/assets/eye-open.svg'
                        : '/assets/eye-closed.svg'
                    }
                    onClick={this.confirmTogglePassword}
                    style={{
                      width: '5%',
                      position: 'absolute',
                      top: '37%',
                      right: '2%',
                      cursor: 'pointer',
                    }}
                  />
                  <p className="login-error-message">{errors.password2 || ''}</p>
                </div>

                {resetsuccess ? (
                  <p className="login-error-message safe mt-10">
                    Password has been reset. Login again with new credentials.
                  </p>
                ) : (
                  <React.Fragment>
                    <button
                      className="login-get-started"
                      disabled={password1 === '' || password2 === ''}
                      onClick={this.reset}
                    >
                      Reset
                    </button>
                    <p className="login-error-message mt-10">{errors.reset || ''}</p>
                  </React.Fragment>
                )}
              </form>
            </>
          )}
        </div>
      </div>
    );
  }
}

function passwordVerification(password: string): string {
  if (password.length >= 10 && password.length <= 72) {
    return '';
  }
  return 'Password should be between 10 and 72 characters';
}

export default ResetPassword;
