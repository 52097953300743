import { Box, Link, Text } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import { FC } from 'react';

export const InfoCard: FC = () => (
  <Box py={4}>
    <Text color="gray.500">
      This feature enables users to delay incident notifications during non-working hours by setting
      Service working hours.{' '}
      <Link
        color="primary.default"
        href="https://support.squadcast.com/services/delayed-notifications"
        target="_blank"
      >
        Read More
      </Link>
    </Text>
  </Box>
);

export const FooterNote: FC = () => (
  <Text maxW="800px" fontSize="14px" fontWeight="700" color={THEME_COLORS.secondary[700]}>
    Note :<br /> Users will receive a notification digest via Email and Push at the start of the
    next service working hours
    <br /> All users in the selected Escalation Policy will simultaneously receive a notification
    digest via Email & Push at the start of the next service working hours
  </Text>
);
