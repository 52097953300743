import React, { Component, HTMLAttributes, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { Shell, Block } from './helper';
import cx from 'classnames';
import IconButton from '../IconButton';
import { CloseIcon } from '../../utils/icons';
import FocusTrap from 'focus-trap-react';
import hasChildren from '../../utils/hasChildrens';
import ContainerLoad from '../ContainerLoad';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * if the app root is different from react default ('root')
   */
  root?: string | 'root';
  id: string;
  onClose: () => void;
  children: ReactNode;
  height?: string | 'auto';
  width?: string | '800px';
  padding?: string | '32px';
  hideClose?: boolean | false;
  closeIconTop?: string | 'props.padding';
  openOnRender?: boolean | false;
  isLoading?: boolean | false;
  skipFocusTrap?: boolean | false;
}

interface IState {
  hide: boolean;
  mountTrap: boolean;
}

class DialogModalFrame extends Component<IProps, IState> {
  _modalId = `${this.props.id}_${new Date().getTime()}`;
  _modalRoot = document.createElement('div');
  _isOpen = !!this.props.openOnRender;

  constructor(props: IProps) {
    super(props);

    this._modalRoot.id = this._modalId;

    this.state = {
      hide: false,
      mountTrap: false,
    };
  }

  componentDidMount() {
    if (this.props.openOnRender) {
      this.open();
    }
  }

  open() {
    this._isOpen = true;
    this.setState({ hide: false });
    window.requestAnimationFrame(() => {
      document.body.appendChild(this._modalRoot);
      document.body.style.overflowY = 'hidden';
      setTimeout(() => {
        this.setState({ mountTrap: true });
      }, 100);
    });
  }

  triggerClose = () => this.props.onClose();

  closeModal = () => {
    this._isOpen = false;
    this.setState({ hide: true, mountTrap: false });

    setTimeout(() => {
      document.body.style.overflow = 'auto';
      this._modalRoot.remove();
    }, 200);
  };

  onClose = (e: React.MouseEvent<any>) => {
    const elementId = (e.target as HTMLDivElement).id;
    if (elementId !== `${this._modalId}_shell`) {
      return;
    }
    this.triggerClose();
  };

  componentDidUpdate(prevProps: IProps) {
    const hadChildBefore = hasChildren(prevProps.children);
    const isChildNotEmpty = hasChildren(this.props.children);

    if (hadChildBefore && !isChildNotEmpty) {
      this.closeModal();
    }

    if (isChildNotEmpty !== this._isOpen) {
      this._isOpen = isChildNotEmpty;
      if (this._isOpen) {
        this.open();
      } else {
        this.triggerClose();
      }
    }
  }

  render() {
    const { hide, mountTrap } = this.state;
    const { root, id, onClose, children, hideClose, padding, skipFocusTrap, ...blockProps } =
      this.props;
    const isChildNotEmpty = hasChildren(children);

    const rawShell = (
      <Shell id={`${this._modalId}_shell`} className={cx({ close: hide })} onClick={this.onClose}>
        <div style={{ margin: '100px 0' }}>
          {isChildNotEmpty && (
            <div style={{ zIndex: 999, position: 'relative' }}>
              <ContainerLoad isLoading={this.props.isLoading || false} />
            </div>
          )}
          <Block
            tabIndex={0}
            id={`${this._modalId}_block`}
            className={cx({ close: hide })}
            padding={isChildNotEmpty ? padding : '0'}
            {...blockProps}
          >
            {isChildNotEmpty && (
              <React.Fragment>
                {children}
                {hideClose !== true && (
                  <IconButton
                    id={`${this._modalId}_close`}
                    onClick={this.props.onClose}
                    style={{
                      position: 'absolute',
                      right: padding || '32px',
                      top: blockProps.closeIconTop || padding || '32px',
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </React.Fragment>
            )}
          </Block>
        </div>
      </Shell>
    );

    return ReactDOM.createPortal(
      skipFocusTrap ? rawShell : <FocusTrap active={mountTrap}>{rawShell}</FocusTrap>,
      this._modalRoot,
    );
  }
}

/** @component */
export default DialogModalFrame;
