import { Button, Center, Stack, Text, Image } from '@chakra-ui/react';
import { API } from 'core';
import { LocalStore } from 'store/local';

const SLACK_AUTH_URL = 'https://slack.com/oauth/v2/authorize';

export default function SlackAuth() {
  const _slackIntegration = API.config.integration.slackv2;

  async function integrateWithSlack() {
    const client_id = _slackIntegration.id;
    const redirect_uri = _slackIntegration.redirects + `/setup-organization-slackv2`;

    const scopes = [
      // bot token scopes
      'app_mentions:read',
      'channels:join',
      'channels:read',
      'chat:write',
      'commands',
      'groups:read',
      'groups:write',
      'im:read',
      'im:write',
      'mpim:read',
      'mpim:write',
      'team:read',
      'users:read',
      'users:read.email',
    ];

    // user token scopes
    const userScopes = [
      'channels:read',
      'channels:write',
      'groups:read',
      'groups:write',
      // 'identify',
      'users:read',
      'users:read.email',
    ];

    const slackState = new Date().getTime().toString();
    await LocalStore.setSlackState(slackState);
    const joinedScoped = scopes.join(',');
    const userJoinedScoped = userScopes.join(',');

    if (window != null) {
      window.location.href = `${SLACK_AUTH_URL}?client_id=${client_id}&redirect_uri=${redirect_uri}&state=${slackState}&scope=${joinedScoped}&user_scope=${userJoinedScoped}`;
    }
  }

  return (
    <Center height={'100%'}>
      <Stack alignItems={'center'}>
        <Image boxSize="150px" fit={'contain'} src={'/icons/integrations/slack.png'} alt="Slack" />
        <Text color="#09305A" fontSize="16px" fontWeight={800}>
          Please authorise to integrate Slack to Squadcast
        </Text>
        <Button
          bgColor={'rgba(15, 97, 221, 1)'}
          color={'white'}
          width={'fit-content'}
          mt={20}
          onClick={integrateWithSlack}
        >
          Authorise
        </Button>
      </Stack>
    </Center>
  );
}
