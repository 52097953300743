import React, { ReactChild } from 'react';
import styled from 'styled-components';
import Para from '../Para';
import colourHash, { range } from '../../utils/colorHash';

interface IProps {
  base?: number | 32;
  backgroundColor?: string | 'auto';
  backgroundGradient?: string | 'none';
  coatColor?: string | 'auto';
  backgroundUrl?: string | 'none';
  type?: 'squared' | 'rounded';
  fontSize?: number | 16;
  reduceColorString?: string;
}

const Shell = styled.div<IProps>`
  height: fit-content;
  width: fit-content;
  display: flex;
  position: relative;
  outline: 0px;
`;

const Avt = styled.div<IProps>`
  height: ${props => props.base || 32}px;
  width: ${props => props.base || 32}px;
  background-color: ${props => props.backgroundColor || props.theme.shades.whiteSmoke};
  background-image: ${props =>
    props.backgroundUrl ? `url(${props.backgroundUrl})` : props.backgroundGradient};

  border-radius: ${props => (props.type === 'rounded' ? '100%' : '3px')};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  flex-wrap: nowrap;

  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }
`;

interface IAttributeProps {
  backgroundColor?: string | 'theme.shades.white';
  base?: number | 16;
  /**
   * internal use
   */
  parentBase?: number;
  isSquare?: boolean | false;
}

const AttribHook = styled.div<IAttributeProps>`
  height: ${props => props.base || 12}px;
  width: ${props => props.base || 12}px;
  bottom: ${props => (props.isSquare ? -45 : props.parentBase || 32) / 15}px;
  right: ${props => (props.isSquare ? -45 : props.parentBase || 32) / 15}px;
  background: ${props => props.backgroundColor || props.theme.shades.white};
  box-shadow: 0 0 0 2px ${props => props.theme.shades.whiteSmoke};
  padding: 2px;
  position: absolute;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IAvatar extends IProps {
  attributeHook?: SVGElement | ReactChild | boolean;
  attributeProps?: IAttributeProps;
  children: string;
}

const Avatar = ({
  children,
  backgroundColor,
  backgroundGradient,
  coatColor,
  fontSize,
  attributeHook,
  attributeProps,
  reduceColorString,
  ...props
}: IAvatar) => {
  const [base, coat, baseCompliment, hash] = colourHash(reduceColorString || children || '');
  const textRender = (children || '')
    .split(' ')
    .slice(0, 2)
    .map(e => e.charAt(0).toUpperCase())
    .join(' ');

  const deg = range(hash, 0, 360);
  const gradient = backgroundGradient || `linear-gradient(${deg}deg, ${base}, ${baseCompliment})`;

  return (
    <Shell {...props}>
      <Avt
        backgroundGradient={backgroundColor ? undefined : gradient}
        backgroundColor={backgroundColor || base}
        {...{ ...props, children }}
      >
        <Para
          color={coatColor || coat}
          fontSize={fontSize || (props.base || 32) / 1.75}
          fontWeight={400}
        >
          {textRender}
        </Para>
      </Avt>
      {attributeHook && (
        <AttribHook parentBase={props.base} isSquare={props.type !== 'rounded'} {...attributeProps}>
          {attributeHook}
        </AttribHook>
      )}
    </Shell>
  );
};

/** @component */
export default Avatar;
