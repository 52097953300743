import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M9.709 1.564c-1.127 0-2.218 0-3.236.036-1.455.036-2.691.109-3.346.145C2 1.891 1.273 2.364.836 3.091c-.509.582-.764 1.382-.8 2.327C0 6.4 0 7.636 0 8.909v.509c0 2.691.036 5.273.036 5.273 0 1.964 1.345 3.309 2.982 3.527 1.309.182 4.218.218 7.055.218 1.164 0 2.364 0 3.418-.036 1.455-.036 2.691-.109 3.345-.145 1.127-.109 1.855-.582 2.327-1.309.509-.582.764-1.382.8-2.327C20 13.636 20 12.4 20 11.127v-.509c0-2.691-.036-5.273-.036-5.273 0-1.964-1.345-3.309-2.982-3.527-1.309-.182-4.218-.218-7.055-.218-.109-.036-.182-.036-.218-.036z"
      style={{
        fill: '#09311b',
      }}
    />
    <path
      d="M10.218 2.982H8.327l-1.382 9.673-.182-1.345H3.018l-.182 1.2h2.836l.255 1.745H8l1.273-9.127.218 1.564h1.236l-.509-3.71z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M11.055 9.018H9.818l1.091 7.745h1.636l.618-4.255h2.8l.182-1.2h-4.036l-.364 2.545-.69-4.835z"
      style={{
        fill: '#22bc66',
      }}
    />
  </svg>
);

export default SvgComponent;
