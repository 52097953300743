export function setItem(key: string, item: any) {
  try {
    const str = JSON.stringify(item);
    localStorage.setItem(key, str);
  } catch (err) {
    console.error(`Error occurred while setting ${item} for ${key}`);
  }
}

export function getItem(key: string): any {
  try {
    const str = localStorage.getItem(key);
    if (str === null) {
      return undefined;
    }
    return JSON.parse(str);
  } catch (err) {
    return undefined;
  }
}

export function clear() {
  localStorage.clear();
}
