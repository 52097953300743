import { AssigneeType } from 'library/enums';
import { INCIDENT_STATUS } from '../common/enums';
import { Box, Icon, Text } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import { Tooltip } from 'library/atoms';
import { truncate } from '../common/util';
import { Link, generatePath } from 'react-router-dom';
import {
  ESCALATION_POLICIES_LIST_PATH,
  TEAM_SQUADS_PATH,
  USER_PROFILE_PATH,
} from 'views/main/routes/routes';
import { API } from 'core';
import { hasReadAccessToEntity } from '../../navigation-flows/helpers';

const getStatusColor = (status: INCIDENT_STATUS) => {
  switch (status) {
    case INCIDENT_STATUS.TRIGGERED:
      return THEME_COLORS.incidentStatusTag.triggered;
    case INCIDENT_STATUS.ACKNOWLEDGED:
      return THEME_COLORS.incidentStatusTag.acknowledged;
    case INCIDENT_STATUS.RESOLVED:
      return THEME_COLORS.incidentStatusTag.resolved;
    case INCIDENT_STATUS.SUPPRESSED:
      return THEME_COLORS.incidentStatusTag.suppressed;
  }
};

const getNameComponent = (name: string, maxLength = 20, type?: AssigneeType) => {
  const label = (
    <Text variant="body" color="secondary.1000">
      {truncate(name, maxLength)}
    </Text>
  );
  return name.length > maxLength && type !== AssigneeType.User ? (
    <Tooltip label={name} placement="bottom">
      {label}
    </Tooltip>
  ) : (
    label
  );
};

const getAssigneeComponent = (id: string, type: AssigneeType, name: string) => {
  let assigneeLink = '';

  switch (type) {
    case AssigneeType.User:
      assigneeLink = generatePath(USER_PROFILE_PATH, { id });
      break;
    case AssigneeType.Squad:
      assigneeLink = generatePath(TEAM_SQUADS_PATH, { id: API.config.teamId, sqid: id });
      break;
    case AssigneeType.Escalationpolicy:
      assigneeLink = generatePath(ESCALATION_POLICIES_LIST_PATH, { id });
  }

  const nameComp = getNameComponent(name, 15, type);

  return hasReadAccessToEntity(type) ? <Link to={assigneeLink}>{nameComp}</Link> : nameComp;
};

const getIconWithTooltip = (label: string, icon: React.ElementType) => (
  <Tooltip label={label} placement="bottom">
    <Box>
      <Icon as={icon} />
    </Box>
  </Tooltip>
);

const getTextComponent = (text: string) => (
  <Text variant="body" color="secondary.1000">
    {text}
  </Text>
);

const getPriorityColumnHeader = ({ asSpan }: { asSpan?: boolean } = {}) => (
  <Tooltip label="PRIORITY" placement="top">
    <Text {...(asSpan ? { as: 'span' } : {})} color="#627C98" cursor="default">
      PRI...
    </Text>
  </Tooltip>
);

export {
  getStatusColor,
  getNameComponent,
  getAssigneeComponent,
  getIconWithTooltip,
  getTextComponent,
  getPriorityColumnHeader,
};
