import axios, { AxiosRequestConfig } from 'axios';
import { IJIssueType, IJProject, IJStatusmap } from 'core/interfaces/IJira';
import {
  ICreateJiraServiceProps,
  ICreateServiceJiraCloudResponse,
  IUpdateJiraCloudForService,
} from 'views/main/organization/service-catalog/service.detail/service.extensions/extension.jiraCloud.modal/types';
import { API } from '../api';
import {
  IService,
  IServiceExtension,
  IServiceRequest,
  IActiveServiceTags,
  IJiraCloudServiceExtension,
} from '../interfaces/IService';
import { ITeamOwner, ITeamEntityBasicResponse } from '../interfaces/ITeams';

class ServiceService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/services`;
  private _apiOld = `${API.config.endpoint}/organizations/${API.config.organizationId}/services`;
  private _serviceId = '';

  constructor(serviceId: string) {
    this._serviceId = serviceId;
  }

  getAll = (teamId: string) => axios.get<{ data: IService[] }>(`${this._api}?owner_id=${teamId}`);

  public create = (serviceRequest: IServiceRequest) =>
    axios.post(this._api, {
      owner_id: API.config.teamId,
      ...serviceRequest,
    });

  public getActiveTags = () =>
    axios.get<{ data: IActiveServiceTags }>(`${this._api}/${this._serviceId}/active-tags`);

  public update = (serviceRequest: IServiceRequest) =>
    axios.put(`${this._api}/${this._serviceId}`, serviceRequest);

  public delete = () => axios.delete(`${this._api}/${this._serviceId}`);

  public getActiveAlertSources = () =>
    axios.get(`${this._api}/${this._serviceId}/alert-source/active`);

  public getAlertSourceLatestEvent = (alertSourceId: string) =>
    axios.get(`${this._api}/${this._serviceId}/alert-source/${alertSourceId}/event`);

  public getServiceExtension = () =>
    axios.get<IServiceExtension>(`${this._apiOld}/${this._serviceId}/extensions`);

  public updateServiceExtension = (channelId: string) =>
    axios.put<{ data: IServiceExtension }>(`${this._api}/${this._serviceId}/extensions`, {
      channel_id: channelId,
    });

  public getDedupeKeyOutput = (template: string, payload: string) =>
    axios.post(`${this._api}/${this._serviceId}/overlays/dedup-key/render`, {
      overlay_template_type: 'go',
      template,
      payload,
    } as AxiosRequestConfig);

  public getDedupeKeyForAlertSource = (alertSourceName: string) =>
    axios.get(`${this._api}/${this._serviceId}/overlays/dedup-key/${alertSourceName}`);

  public deleteDedupeKeyForAlertSource = (alertSourceName: string) =>
    axios.delete(`${this._api}/${this._serviceId}/overlays/dedup-key/${alertSourceName}`);

  public setJiraCloudServiceExtension = (
    jiraCloudServicePayload: {
      is_manual: boolean;
      project: IJProject;
      issue_type: IJIssueType;
      status_maps: IJStatusmap[];
    },
    id: string,
  ) => {
    return axios.put(
      `${this._api}/${this._serviceId}/extensions/jira-cloud/${id}`,
      jiraCloudServicePayload,
    );
  };

  public getJiraCloudServiceExtension = (id: string) =>
    axios.get<{ data: IJiraCloudServiceExtension }>(
      `${this._api}/${this._serviceId}/extensions/jira-cloud/${id}`,
    );

  public transferOwnership = (id: string, payload: ITeamOwner) =>
    axios.put<{ data: ITeamEntityBasicResponse }>(`${this._api}/${id}/owner`, payload);

  public getCustomTeplateOutput = (payload: {
    overlay_template_type: string;
    template: string;
    payload: string;
  }) => {
    return axios.post(`${this._api}/${this._serviceId}/overlays/custom-content/render`, payload);
  };
  public getCustomTemplate = (alertSource: string) =>
    axios.get<{ data: IActiveServiceTags }>(
      `${this._api}/${this._serviceId}/overlays/custom-content/${alertSource}`,
    );

  public updateNotificationTemplate = (
    notificationPayload: {
      overlay_template_type: string;
      message_overlay: {
        template: string;
      };
      description_overlay: {
        template: string;
      };
    },
    alertSource: string,
  ) => {
    return axios.put(
      `${this._api}/${this._serviceId}/overlays/custom-content/${alertSource}`,
      notificationPayload,
    );
  };

  public deleteNotificationTemplate = (alertSource: string) =>
    axios.delete<{ data: IActiveServiceTags }>(
      `${this._api}/${this._serviceId}/overlays/custom-content/${alertSource}`,
    );
}

class ServiceJiraIntegration {
  private serviceApi = '';

  constructor(private serviceId: string) {
    this.serviceId = serviceId;
    this.serviceApi = `${API.config.batman}/organizations/${API.config.organizationId}/services/${this.serviceId}/extensions/new`;
  }

  public createJiraCloudForService({ account_id, ...configDetail }: ICreateJiraServiceProps) {
    return axios
      .post<ICreateServiceJiraCloudResponse>(`${this.serviceApi}/${account_id}`, {
        ...configDetail,
      })
      .then(({ data }) => data);
  }

  public getJiraConfigDetailOfService(accountId: string, configId: string) {
    return axios.get(`${this.serviceApi}/${accountId}/jira-service-config/${configId}`);
  }

  public updateJiraCloudForService({
    account_id,
    config_id,
    ...configDetail
  }: Omit<IUpdateJiraCloudForService, 'is_all_teams_configured'>) {
    return axios
      .put(`${this.serviceApi}/${account_id}/jira-service-config/${config_id}`, {
        ...configDetail,
      })
      .then(({ data }) => data);
  }

  public deleteJiraCloudForService(accountId: string, configId: string) {
    return axios.delete(`${this.serviceApi}/${accountId}/jira-service-config/${configId}`);
  }
}

export { ServiceJiraIntegration };
export default ServiceService;
