import { createStandaloneToast } from '@chakra-ui/react';
import axios from 'axios';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { API } from '../../../../../core/api';
import { T_WA_GS_SERVICE_MODIFIED, T_WA_UP_DN_MODIFIED } from '../../../../../core/const/tracker';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { queryKeys } from '../contants/service.contant';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/helper.service';
import { ServiceData } from '../Interfaces/intigration';
import { DelayNotificationConfig } from '../types/service-detail.types';

const toast = createStandaloneToast();
interface Tags {
  key: string;
  value: string;
}
interface Payload {
  owner_id: string;
  name: string;
  description: string;
  escalation_policy_id: string;
  maintainer: {
    id: string;
    type: string;
  } | null;
  serviceId: string;
  tags: Tags[];
  email_prefix?: string;
  auto_pause_transient_alerts_config: ServiceAptaConfig;
  intelligent_alerts_grouping_config: ServiceIAGConfig;
  delay_notification_config: DelayNotificationConfig;
}

export type ServiceAptaConfig = {
  is_enabled: boolean;
  timeout_in_mins: number;
};

export type ServiceIAGConfig = {
  is_enabled: boolean;
  rolling_window_in_mins: number;
};

const editService = async (payload: Partial<Payload>) => {
  const { data } = await axios.put<{ data: ServiceData }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/catalog-services/${payload.serviceId}`,
    payload,
  );
  return data;
};

export function useEditService(initialState?: any) {
  const queryClient = useQueryClient();

  const [aptaRecommenedSelected, setAptaRecommendedSelected] = useState<boolean>(false);

  const {
    mutateAsync: editService_,
    data,
    isLoading,
  } = useMutation((payload: Partial<Payload>) => editService(payload), {
    onError: reactQueryConfigError,
    onSuccess: data => {
      let is_apta_updated = false;

      // Mixpanel tracking
      let apta_config_change = 'None';
      let apta_time_window_change = null;

      if (
        initialState?.apta_is_enabled !== data.data.auto_pause_transient_alerts_config.is_enabled
      ) {
        apta_config_change = data.data.auto_pause_transient_alerts_config.is_enabled
          ? 'APTA Enabled'
          : 'APTA Disabled';

        is_apta_updated = true;
      } else if (
        initialState?.apta_timeout_in_mins !==
        data.data.auto_pause_transient_alerts_config.timeout_in_mins
      ) {
        apta_config_change = 'APTA Time Window Changed';
        apta_time_window_change = data.data.auto_pause_transient_alerts_config.timeout_in_mins;

        is_apta_updated = true;
      }

      let success_message = 'Service updated';

      if (is_apta_updated) {
        success_message = 'APTA updated';
      }

      let iag_config_change = 'None';
      let iag_time_window_change = null;

      if (
        initialState?.iag_is_enabled !== data.data.intelligent_alerts_grouping_config.is_enabled
      ) {
        iag_config_change = data.data.intelligent_alerts_grouping_config.is_enabled
          ? 'IAG Enabled'
          : 'IAG Disabled';
      } else if (
        initialState?.iag_rolling_window_in_mins !==
        data.data.intelligent_alerts_grouping_config.rolling_window_in_mins
      ) {
        iag_config_change = 'IAG Time Window Changed';
        iag_time_window_change =
          data.data.intelligent_alerts_grouping_config.rolling_window_in_mins;
      }

      const initialDN = initialState?.delay_notification_config ?? {};
      const updatedDN = data.data.delay_notification_config ?? {};
      let delayNotificationUpdateType = 'Other Changes';

      if (initialState?.delay_notification_config_is_enabled !== updatedDN.is_enabled) {
        delayNotificationUpdateType = updatedDN.is_enabled
          ? 'Delayed Notifications Enabled'
          : 'Delayed Notifications Disabled';
      } else if (isEqual(initialDN, updatedDN)) {
        delayNotificationUpdateType = 'None';
      }

      reactQueryConfigSuccess(success_message);

      const mixpanelAdditionalData = {
        'APTA Config Changes': apta_config_change,
        ...(apta_time_window_change && { 'APTA Time Window Change': apta_time_window_change }),
        ...(apta_time_window_change && {
          'APTA Recommended Time Window Chosen': aptaRecommenedSelected,
        }),
        'IAG Config Changes': iag_config_change,
        ...(iag_time_window_change && { 'IAG Time Window Change': iag_time_window_change }),
        [T_WA_UP_DN_MODIFIED]: delayNotificationUpdateType,
        'Service ID': data.data.id,
      };

      AppTracker.track(T_WA_GS_SERVICE_MODIFIED, mixpanelAdditionalData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.SERVICEDETAIL);
    },
  });
  const editServiceData = data;
  return { editService_, editServiceData, isEditing: isLoading, setAptaRecommendedSelected };
}
