import { Button, ChakraProvider, DrawerHeader, Heading, HStack, Text } from '@chakra-ui/react';
import { SlackNotifyEvents } from 'core/interfaces/IExtensions';
import { useState } from 'react';
import { CustomDrawerComponent } from 'components/chakra/Drawer';
import { useSaveSlackConfig } from './hooks/saveSlackConfig';
import SlackAuth from './slack_auth';
import SlackConfiguration from './slack_configuration';
import { fontTheme } from 'library/theme';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  slackStatus: string;
}

function Slack(props: Props) {
  const [saveConfiguration, setSaveConfiguration] = useState({
    notifyAllEvents: true,
    channelCreationMode: '',
    channelId: '',
    slackNotifyEvents: [] as SlackNotifyEvents[],
    notifyChannel: true,
  });

  const [isDisabled, setIsDisabled] = useState(true);

  const saveConfigurationMutation = useSaveSlackConfig();

  async function onGetConfiguration(
    notifyAllEvents: boolean,
    channelCreationMode: string,
    channelId: string,
    slackNotifyEvents: SlackNotifyEvents[],
    notifyChannel: boolean,
  ) {
    setSaveConfiguration({
      channelCreationMode: channelCreationMode,
      channelId: channelId,
      notifyAllEvents: notifyAllEvents,
      slackNotifyEvents: slackNotifyEvents,
      notifyChannel: notifyChannel,
    });
  }

  async function onSaveConfiguration() {
    saveConfigurationMutation
      .mutateAsync({
        channel_creation_mode: saveConfiguration.channelCreationMode,
        channel_id: saveConfiguration.channelId,
        notify_all_events: saveConfiguration.notifyAllEvents,
        notify_events: saveConfiguration.slackNotifyEvents,
        notify_channel: saveConfiguration.notifyChannel,
      })
      .then(() => {
        props.onClose();
      });
  }

  return (
    <ChakraProvider theme={fontTheme}>
      <CustomDrawerComponent
        isOpen={props.isOpen}
        onClose={props.onClose}
        title={<Text fontSize="4xl">Slack Integration</Text>}
        isFooter={props.slackStatus != 'not_integrated'}
        footerButtons={[
          {
            text: 'Save Slack Configuration',
            type: 'button',
            colorScheme: 'blue',
            isLoading: saveConfigurationMutation.isLoading,
            disabled: saveConfigurationMutation.isLoading || isDisabled,
            onClick: onSaveConfiguration,
          },
          {
            text: 'Cancel',
            type: 'button',
            variant: 'outline',
            onClick: props.onClose,
          },
        ]}
      >
        {props.slackStatus === 'not_integrated' ? (
          <SlackAuth />
        ) : (
          <SlackConfiguration
            onSaveConfiguration={onGetConfiguration}
            onClose={props.onClose}
            setisButtonDisabled={setIsDisabled}
          />
        )}
      </CustomDrawerComponent>
    </ChakraProvider>
  );
}

export default Slack;
