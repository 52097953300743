import { Box, Container, Flex, HStack, Progress } from '@chakra-ui/react';
import { isFunction } from 'lodash';
import { useMemo } from 'react';
import { PROGRESS_STEPPER_TYPE } from '../types';

export const HorzStepper = ({
  steps,
  currentStep = 0,
  clickHandler,
}: {
  steps: PROGRESS_STEPPER_TYPE[];
  currentStep: number;
  clickHandler?: (stepIndex: number) => void;
}) => {
  const isClickable = useMemo(() => isFunction(clickHandler), [clickHandler]);
  return (
    <Flex justifyContent="space-around" borderRadius="2px" backgroundColor="#fff" py="24px">
      <Container maxW="container.lg">
        <HStack>
          {steps.map((stepper, i) => {
            return (
              <Box
                key={i}
                w="50%"
                px="15px"
                onClick={() => {
                  if (isClickable && i < currentStep) {
                    clickHandler?.(i);
                  }
                }}
                cursor={isClickable ? (i < currentStep ? 'pointer' : 'not-allowed') : 'default'}
              >
                <Progress
                  value={100}
                  colorScheme="default"
                  bg={i <= currentStep ? '#0f61dd' : '#ccc'}
                  h="4px"
                />
                <Box display="flex" fontWeight={600} fontSize="12px" marginTop="16px">
                  Step {stepper.index}
                </Box>
                <Box fontSize="14px" fontWeight={500}>
                  {stepper.title}
                </Box>
              </Box>
            );
          })}
        </HStack>
      </Container>
    </Flex>
  );
};
