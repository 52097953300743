import { Center } from '@chakra-ui/react';
import { WorkflowFormProvider } from '../context/workflowFormContext';
import WorkflowFormContainer from './WorkflowFormContainer';
import { useParams } from 'react-router-dom';
import { useWorkflowDetails } from '../details/hooks/useWorkflowDetails';
import { getActionsFromWorkflow, mapWorkflowResponseToWorkflowForm } from '../helper';

import PageLoader from 'components/PageLoader';
import WorkflowProviderWrapper from './WorkflowProviderWrapper';

const WorkflowUpdate = () => {
  const params = useParams<{ id: string }>();

  const { data, isLoading } = useWorkflowDetails(params.id);

  if (isLoading) {
    return (
      <Center h="100vh">
        <PageLoader />
      </Center>
    );
  }

  if (!data) {
    return <div>Not found</div>;
  }

  const workflowFormData = mapWorkflowResponseToWorkflowForm(data);
  const initialActions = getActionsFromWorkflow(data);

  return (
    <WorkflowProviderWrapper title="Edit Workflow">
      <WorkflowFormProvider
        isCreateFlow={false}
        workflowFormData={workflowFormData}
        initialActions={initialActions}
      >
        <WorkflowFormContainer />
      </WorkflowFormProvider>
    </WorkflowProviderWrapper>
  );
};

const WorkflowCreate = () => {
  return (
    <WorkflowProviderWrapper title="Create Workflow">
      <WorkflowFormProvider isCreateFlow={true}>
        <WorkflowFormContainer />
      </WorkflowFormProvider>
    </WorkflowProviderWrapper>
  );
};

const WorkFlowCreateOrUpdate = () => {
  const params = useParams<{ id: string }>();

  return params.id ? <WorkflowUpdate /> : <WorkflowCreate />;
};

export default WorkFlowCreateOrUpdate;
