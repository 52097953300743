import { Box, Circle, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { Locale } from 'core/helpers/dateUtils';
import { toSentenceCase } from 'core/helpers/stringUtils';
import { AssigneeAvatar, Tooltip, usernameTooltipLabel } from 'library/atoms';
import { NotesIcon, AutoGroupedIcon, DelayedNotificationsIcon } from 'library/icons';
import { generatePath, Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { SERVICE_DETAILS_PATH } from 'views/main/routes/routes';

import { hasReadAccessToEntity } from '../../../navigation-flows/helpers';
import { INCIDENT_DATA } from '../../common/types';
import { getIncidentDetailsPath, toDate } from '../../common/util';
import TagList from '../TagList';
import { IncidentMergeIcon, SnoozeIcon, TransientAlertIcon } from 'icons';
import { useMemo } from 'react';
import { IPostmortemStatus } from 'core/interfaces/IPostmortems';
import { POSTMORTEM_STATUS_OPTIONS } from '../../filters/manager/constant';
import { Priority } from 'library/common';
import { InfoFilledIcon } from 'library/icons';
import {
  getAssigneeComponent,
  getNameComponent,
  getStatusColor,
  getIconWithTooltip,
  getTextComponent,
  getPriorityColumnHeader,
} from '../column-utils';
import {
  ASSIGNEE_TOOLTIP,
  ASSIGNEE_TOOLTIP_FILTER_INFO,
  COLUMN_DEF_PADDING_LEFT_VALUE,
} from '../../common/constants';

export const INCIDENT_LIST_COLUMN_DEFS = [
  {
    Header: 'INCIDENT MESSAGE',
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      const {
        id,
        message,
        eventCount,
        tags,
        children,
        didAutoPause,
        status,
        groupedAlertCount,
        didNotificationDelay,
      } = cell.row.original;
      const parentIncidentTooltipLabel = `${children.length} incident${
        children.length > 1 ? 's' : ''
      } merged`;

      const snoozedIncidentTooltipLable = 'Incident is snoozed';

      const isSnoozed = useMemo(() => {
        return cell.row.original.snoozeDetails.isSnoozed;
      }, [cell.row.original.snoozeDetails.isSnoozed]);

      return (
        <VStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} flexGrow={1} alignItems="flex-start">
          <HStack>
            {isSnoozed && getIconWithTooltip(snoozedIncidentTooltipLable, SnoozeIcon)}
            {children.length > 0 &&
              getIconWithTooltip(parentIncidentTooltipLabel, IncidentMergeIcon)}
            {didAutoPause &&
              status === 'suppressed' &&
              getIconWithTooltip('Transient Alert', TransientAlertIcon)}
            {groupedAlertCount && getIconWithTooltip('Auto Grouped', AutoGroupedIcon)}
            {didNotificationDelay &&
              getIconWithTooltip('Delayed Notifications', DelayedNotificationsIcon)}
            <Link to={getIncidentDetailsPath(id)}>{getNameComponent(message, 50)}</Link>
            {eventCount > 1 && (
              <Circle size="19px" bg="primary.400">
                <Text variant="hint" color="secondary.1000">
                  {eventCount - 1}
                </Text>
              </Circle>
            )}
          </HStack>
          {!!tags.length && <TagList tags={tags} honorTagsPreference />}
        </VStack>
      );
    },
  },
  {
    Header: 'SERVICE',
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      const { id, name } = cell.row.original.service;
      let nameComp = getNameComponent(name);
      if (hasReadAccessToEntity('service')) {
        nameComp = <Link to={generatePath(SERVICE_DETAILS_PATH, { id })}>{nameComp}</Link>;
      }
      return <Box pl={COLUMN_DEF_PADDING_LEFT_VALUE}>{nameComp}</Box>;
    },
  },
  {
    Header: 'ALERT SOURCE',
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      const alertSrc = cell.row.original.alertSource ?? {};
      return (
        <HStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} columnGap="8px">
          {alertSrc.iconURL.svg && <Image src={alertSrc.iconURL.svg} width="24px" />}
          {getNameComponent(alertSrc.name)}
        </HStack>
      );
    },
    minWidth: 150,
  },
  {
    id: 'assignee',
    Header: () => {
      return (
        <HStack>
          <Text color="secondary.700">ASSIGNEE</Text>
          <Tooltip label={`${ASSIGNEE_TOOLTIP} ${ASSIGNEE_TOOLTIP_FILTER_INFO}`} placement="top">
            <Box>
              <InfoFilledIcon height="18px" width="18px" />
            </Box>
          </Tooltip>
        </HStack>
      );
    },
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      const { id, type, name, usernameForDisplay: username } = cell.row.original.assignedTo;
      return (
        <>
          {type !== 'user' ? (
            <HStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} columnGap="4px">
              <AssigneeAvatar name={name} type={type} />
              {getAssigneeComponent(id, type, name)}
            </HStack>
          ) : (
            <Tooltip label={usernameTooltipLabel(name, username)} placement="bottom">
              <HStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} columnGap="4px">
                <AssigneeAvatar name={name} type={type} />
                {getAssigneeComponent(id, type, name)}
              </HStack>
            </Tooltip>
          )}
        </>
      );
    },
  },
  {
    Header: 'STATUS',
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      const status = cell.row.original.status;
      return (
        <HStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} columnGap={2}>
          <Circle size="10px" bg={getStatusColor(status)} />
          {getTextComponent(toSentenceCase(status))}
        </HStack>
      );
    },
  },
  {
    id: 'priority',
    accessor: 'priority',
    sortDescFirst: false,
    canSort: true,
    Header: () => {
      return getPriorityColumnHeader({ asSpan: true });
    },
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      const priority = cell.row.original.priority;
      return (
        <Box pl={COLUMN_DEF_PADDING_LEFT_VALUE}>
          <Priority value={priority} />
        </Box>
      );
    },
    minWidth: 81,
  },
  {
    Header: 'CREATED AT',
    accessor: 'time',
    sortDescFirst: false,
    canSort: true,
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      return (
        <VStack pl={COLUMN_DEF_PADDING_LEFT_VALUE} alignItems="flex-start">
          {getTextComponent(Locale.toShortDate(cell.row.original.timeOfCreation))}
          {getTextComponent(Locale.to24HrTimeWithSec(cell.row.original.timeOfCreation))}
        </VStack>
      );
    },
    minWidth: 120,
  },
  {
    Header: 'ELAPSED',
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      return (
        <Box pl={COLUMN_DEF_PADDING_LEFT_VALUE}>
          {getTextComponent(toDate(cell.row.original.timeOfCreation) ?? '')}
        </Box>
      );
    },
  },
  {
    id: 'postmortems',
    Header: () => {
      return (
        <Tooltip label="POSTMORTEMS" placement="top">
          <Text color="#627C98" cursor="default">
            POST...
          </Text>
        </Tooltip>
      );
    },
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      if (!cell.row.original.retrospectiveStatus) {
        return <></>;
      }
      const status = cell.row.original.retrospectiveStatus as IPostmortemStatus;
      const StatusIcon = POSTMORTEM_STATUS_OPTIONS[status].Icon;

      return (
        <Box pl={8}>
          <StatusIcon />
        </Box>
      );
    },
  },
  {
    Header: 'NOTES',
    Cell: (cell: Cell<INCIDENT_DATA>) => {
      return cell.row.original.hasNotes ? (
        <Box pl={8}>
          <NotesIcon />
        </Box>
      ) : (
        <></>
      );
    },
  },
];
