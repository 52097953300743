import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Tooltip: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {
    // sm: {
    //   item: {
    //     fontSize: '0.75rem',
    //     px: 2,
    //     py: 1,
    //   },
    // },
    // md: {
    //   item: {
    //     fontSize: '0.875rem',
    //     px: 3,
    //     py: 2,
    //   },
    // },
  },
  variants: {
    default: {
      bg: 'black.400',
      color: 'white',
      size: 'sm',
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'default',
  },
};
