import React, { useRef, Dispatch, SetStateAction, Children, ReactNode } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Divider,
} from '@chakra-ui/react';

interface Drawer {
  isOpen: boolean;
  onClose: () => void;
  callbackFn?: () => void;
  title: string | ReactNode;
  isFooter?: boolean;
  isFooterLeftAllign?: boolean;
  disableBodyPadding?: boolean;
  size?: 'lg' | 'md' | 'sm';
  children: ReactNode;
}

interface ILeftButtonFooter {
  onClose: () => void;
  callbackFn?: () => void;
}

const LeftButtonFooter = ({ onClose, callbackFn }: ILeftButtonFooter) => {
  return (
    <>
      <Button colorScheme="blue" mr={3} onClick={callbackFn}>
        Save
      </Button>
      <Button variant="outline" onClick={onClose}>
        Cancel
      </Button>
    </>
  );
};

const RightButtonFooter = ({ onClose, callbackFn }: ILeftButtonFooter) => {
  return (
    <>
      <Button variant="outline" mr={3} onClick={onClose}>
        Cancel
      </Button>
      <Button colorScheme="blue" onClick={callbackFn}>
        Save
      </Button>
    </>
  );
};

export const CustomDrawerComponent = ({
  isOpen,
  onClose,
  callbackFn,
  title,
  isFooter,
  isFooterLeftAllign = false,
  size = 'lg',
  disableBodyPadding,
  children,
}: Drawer) => {
  return (
    <>
      <Drawer onClose={() => onClose()} isOpen={isOpen} size={size}>
        <DrawerOverlay />
        <DrawerContent data-testid="drawer" transform="none !important">
          <DrawerCloseButton boxShadow="none !important" />
          <DrawerHeader
            fontFamily="Mulish"
            fontSize={24}
            fontWeight={400}
            data-testid="drawer-title"
          >
            {title}
          </DrawerHeader>
          <Divider />
          <DrawerBody sx={disableBodyPadding ? { p: 0 } : {}}>{children}</DrawerBody>
          {isFooter && (
            <DrawerFooter justifyContent={isFooterLeftAllign ? 'flex-start' : 'flex-end'}>
              {isFooterLeftAllign ? (
                <LeftButtonFooter onClose={onClose} callbackFn={callbackFn} />
              ) : (
                <RightButtonFooter onClose={onClose} callbackFn={callbackFn} />
              )}
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};
