import {
  IWebAppConfigRoutes,
  IWebAppRoutes,
  IWebAppVisibleRoutes,
  RequiresKeys,
} from '../../../core/interfaces/IAppRouts';
import {
  AnalyticsIcon,
  DashboardIcon,
  EscalationIcon,
  GerIcon,
  IncidentsIcon,
  LCRIcon,
  PostmortemIcon,
  RunbookIcon,
  ScheduleIcon,
  ServiceIcon,
  SettingsIcon,
  SloTrackerIcon,
  StatusPageIcon,
  WebformsIcon,
  WorkflowsIcon,
} from '../../../icons';
import Analytics from '../organization/analytics';
import AnalyticsV2 from '../organization/analytics-v2';
import Schedules from '../organization/calendar';
import Dashboard from '../organization/dashboard';
import EscalationPolicies from '../organization/escalationPolicies';
import GERComponent from '../organization/ger';
import IncidentList from '../organization/incident-list';
import IncidentDetails from '../organization/incidentDetails';
import CreateIncident from '../organization/dashboard/createIncident';
import Postmortems from '../organization/postmortems';
import Profile from '../organization/profile';
import Runbooks from '../organization/runbooks';
import SchedulesV2 from '../organization/schedules';
import ServiceCatalog from '../organization/service-catalog';
import Settings from '../organization/settings';
import MSteamsIntegrationHelper from '../organization/settings/integrations/helpers/msteams';
import SlackAuthRedirect from '../organization/settings/integrations/helpers/redirects.slack';
import SlackIntegrationHelper from '../organization/settings/integrations/helpers/slack';
import JiraCloudSettings from '../organization/settings/integrations/jira-cloud';
import { IntegrateWithSlackRedirect } from '../organization/settings/integrations/slack-v2/integrate_with_slack_redirect';
import OrganizationTeams from '../organization/settings/teams';
import AddUsersHOC from '../organization/settings/users/add';
import CreateSlo from '../organization/slo/createSlo.component';
import SloTracker from '../organization/slo/sloList.component';
import sloOverView from '../organization/slo/sloOverview.component';
import StatusPage from '../organization/statuspage';
import StatusPageV2 from '../organization/statuspage-v2';
import AuthStatusPage from '../organization/statuspage-v2/auth';
import InternalStatusPage from '../organization/statuspage/internal';
import Webforms from '../organization/webforms';
import CreateOrUpdateWorkflow from '../organization/workflow/create-or-update';
import WorkflowDetails from '../organization/workflow/details';
import Reroute from './reroute';
import {
  ANALYTICS_V2_PATH,
  DASHBOARD_PATH,
  GER_DETAILS_PATH,
  GER_PATH,
  LCR_CREATE_PATH,
  LCR_DETAILS_PATH,
  LCR_EDIT_PATH,
  LCR_PATH,
  LCR_SESSIONS_DETAILS_PATH,
  LCR_SESSIONS_PATH,
  STATUSPAGE_V2_PATH,
  WORKFLOWS_CREATE_PATH,
  WORKFLOWS_DETAILS_PATH,
  WORKFLOWS_EDIT_PATH,
  WORKFLOWS_PATH,
} from './routes';
import LCRDashboard from '../organization/lcr/screens/list';
import CreateOrUpdateLCR from '../organization/lcr/screens/create-or-update';
import LCRDetails from '../organization/lcr/screens/details';
import LCRLogsDetails from '../organization/lcr/screens/details/LCRBody/LCRLogs/LCRLogsDetails';
import LogsTab from '../organization/lcr/screens/details/LCRBody/LCRLogs';
import LCRComponent from '../organization/lcr';
import WorkflowDashboard from '../organization/workflow/list';

export const isSQLocalEnv = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const env = window._sq_env?.VITE_ENV ?? 'prod';
  return !['eu-prod', 'prod'].includes(env);
};

const visibleRoutes = (
  featureEnabled?: { workflowEnabled: boolean },
  lcrActive?: boolean | undefined,
): IWebAppVisibleRoutes[] => [
  {
    viewOrder: 1,
    name: 'Dashboard',
    route: DASHBOARD_PATH,
    group: '1',
    key: '1',
    requires: 'none',
    requiresActiveOrg: true,
    role: ['account_owner', 'user', 'stakeholder'],
    focusedFor: /^\/$/,
    isExact: true,
    component: Dashboard,
    icon: DashboardIcon,
  },
  {
    viewOrder: 2,
    name: 'Incidents',
    route: '/incident',
    group: '1',
    key: '2',
    requires: 'none',
    requiresActiveOrg: true,
    role: ['account_owner', 'user', 'stakeholder'],
    focusedFor: /^\/incident/,
    isExact: true,
    component: IncidentList,
    icon: IncidentsIcon,
  },
  {
    viewOrder: 3,
    name: 'SLO',
    route: '/slo',
    group: '1',
    key: '3',
    requires: 'slos',
    requiresActiveOrg: false,
    role: ['account_owner', 'user'],
    focusedFor: /^\/slo/,
    isExact: false,
    component: SloTracker,
    icon: SloTrackerIcon,
    showIf: org => !!org.config?.['slo-tracker.enabled'],
    pricingEntity: 'slo',
    isNew: false,
  },
  {
    viewOrder: 4,
    name: 'Escalation policies',
    route: '/escalationpolicies',
    group: '2',
    key: '1',
    requires: 'escalation_policies',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/escalationpolicies/,
    isExact: true,
    component: EscalationPolicies,
    icon: EscalationIcon,
  },
  {
    viewOrder: 5,
    name: 'Services',
    route: '/service-catalog',
    group: '2',
    key: '2',
    requires: 'services',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/service-catalog/,
    isExact: false,
    component: ServiceCatalog,
    icon: ServiceIcon,
    pricingEntity: 'service',
  },
  {
    viewOrder: 6,
    name: 'Legacy Schedules',
    route: '/schedules',
    group: '2',
    key: '3',
    requires: 'schedules',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/schedules$/,
    isExact: true,
    component: Schedules,
    icon: ScheduleIcon,
    showIf: org => !org.config?.disable_old_schedules,
  },
  {
    viewOrder: 6,
    name: 'New Schedules',
    route: '/schedules-v2',
    group: '2',
    key: '3',
    requires: 'schedules',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/schedules-v2/,
    isExact: false,
    component: SchedulesV2,
    icon: ScheduleIcon,
    isBeta: true,
  },
  {
    viewOrder: 6,
    name: 'Runbooks',
    route: '/runbooks',
    group: '2',
    key: '4',
    requires: 'runbooks',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/runbooks/,
    isExact: true,
    component: Runbooks,
    icon: RunbookIcon,
    pricingEntity: 'runbooks',
  },
  {
    viewOrder: 7,
    name: 'Postmortems',
    route: '/postmortems',
    group: '3',
    key: '1',
    requires: 'postmortems',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/postmortems/,
    isExact: true,
    component: Postmortems,
    icon: PostmortemIcon,
    pricingEntity: 'postmortem',
  },
  {
    viewOrder: 8,
    name: 'Status Page',
    route: '/statuspage',
    group: '3',
    key: '2',
    requires: 'status_pages',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/statuspage$/,
    isExact: true,
    component: StatusPage,
    icon: StatusPageIcon,
    pricingEntity: 'status-page',
    showIf: org => !!org.config?.['statuspagev1.enabled'],
  },
  {
    viewOrder: 8,
    name: 'Status Page',
    route: STATUSPAGE_V2_PATH,
    group: '3',
    key: '2',
    requires: 'status_pages',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/statuspage-v2$/,
    isExact: false,
    component: StatusPageV2,
    icon: StatusPageIcon,
    pricingEntity: 'status-page',
    showIf: org => !org.config?.['statuspagev1.enabled'],
  },
  {
    viewOrder: 9,
    name: 'Webforms',
    route: '/webforms',
    group: '3',
    key: '3',
    requires: 'webforms',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/webforms/,
    isExact: true,
    component: Webforms,
    icon: WebformsIcon,
    isNew: false,
    pricingEntity: 'webform',
  },
  {
    viewOrder: 10,
    name: 'Global Event Rulesets',
    route: GER_PATH,
    group: '3',
    key: '5',
    requires: 'ger',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/global-event-rulesets/,
    isExact: false,
    component: GERComponent,
    icon: GerIcon,
    pricingEntity: 'ger',
    isNew: false,
  },
  {
    viewOrder: 11,
    name: 'Workflows',
    route: WORKFLOWS_PATH,
    group: '3',
    key: '4',
    requires: 'workflows',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/workflows$/,
    isExact: true,
    component: WorkflowDashboard,
    icon: WorkflowsIcon,
    showIf: org => !!org.config?.['workflow_enabled'] || !!featureEnabled?.workflowEnabled,
    pricingEntity: 'workflows',
    isNew: true,
  },
  {
    viewOrder: 12,
    name: 'Analytics',
    route: '/analytics',
    group: '4',
    key: '1',
    requires: 'none',
    requiresActiveOrg: true,
    role: ['account_owner', 'user', 'stakeholder'],
    focusedFor: /^\/analytics$/,
    isExact: true,
    component: Analytics,
    icon: AnalyticsIcon,
    showIf: org => !org.config['analyticsv2.enabled'],
  },
  {
    viewOrder: 12,
    name: 'Analytics',
    route: ANALYTICS_V2_PATH,
    group: '4',
    key: '2',
    requires: 'none',
    requiresOneOf: ['team_analytics', 'manage-organization-analytics'],
    requiresActiveOrg: true,
    role: ['account_owner', 'user', 'stakeholder'],
    focusedFor: /^\/analytics-v2/,
    isExact: false,
    component: AnalyticsV2,
    icon: AnalyticsIcon,
    showIf: org => !!org.config?.['analyticsv2.enabled'],
    isNew: false,
  },
  {
    viewOrder: 13,
    name: 'Settings',
    route: '/settings',
    group: '4',
    key: '3',
    requires: 'none',
    requiresActiveOrg: false,
    role: ['account_owner', 'user'],
    focusedFor: /^\/settings/,
    isExact: false,
    component: Settings,
    icon: SettingsIcon,
  },
  {
    viewOrder: 14,
    name: 'Live Call Routing',
    route: LCR_PATH,
    group: '3',
    key: '4',
    requires: 'routing_numbers',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/live-call-routing$/,
    isExact: false,
    component: LCRComponent,
    icon: LCRIcon,
    isNew: true,
    showIf: org => (lcrActive ? true : false),
  },
];

const subNav: IWebAppRoutes[] = [
  {
    name: 'Create incident',
    route: '/incident/create',
    requires: 'none',
    requiresActiveOrg: true,
    role: ['account_owner', 'user', 'stakeholder'],
    focusedFor: /^\/incident\/create$/,
    isExact: true,
    component: CreateIncident,
  },
  {
    name: 'Incident Details Reroute',
    route: '/incident/details',
    requires: 'none',
    requiresActiveOrg: true,
    role: ['account_owner', 'user', 'stakeholder'],
    focusedFor: /^\/incident\/details/,
    isExact: true,
    component: Reroute,
  },
  {
    name: 'Incident details',
    route: '/incident/:incidentId/:type?/:id?',
    requires: 'none',
    requiresActiveOrg: true,
    role: ['account_owner', 'user', 'stakeholder'],
    focusedFor: /^\/incident\/.{24}$/,
    isExact: true,
    component: IncidentDetails,
  },
  {
    name: 'Profile',
    route: '/user/:userId',
    requires: 'none',
    requiresActiveOrg: true,
    role: ['account_owner', 'user', 'stakeholder'],
    focusedFor: /^\/user\/.{24}/,
    isExact: true,
    component: Profile,
  },
  {
    name: 'Add users',
    route: '/add-users',
    requires: 'none',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/add-users/,
    isExact: true,
    component: AddUsersHOC,
  },
  {
    name: 'Workflow create',
    route: WORKFLOWS_CREATE_PATH,
    requires: 'none',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/workflow\/create/,
    isExact: true,
    component: CreateOrUpdateWorkflow,
  },
  {
    name: 'Workflow details',
    route: WORKFLOWS_DETAILS_PATH,
    requires: 'none',
    requiresOneOf: ['workflows'],
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/workflow/,
    isExact: true,
    component: WorkflowDetails,
  },
  {
    name: 'Workflow Edit',
    route: WORKFLOWS_EDIT_PATH,
    requires: 'none',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/workflow/,
    isExact: true,
    component: CreateOrUpdateWorkflow,
  },
  {
    name: 'StatusPage',
    route: '/statuspage/:id',
    requires: 'status_pages',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /\/statuspage\/.{24}/,
    isExact: true,
    component: InternalStatusPage,
  },
  {
    name: 'Teams',
    route: '/settings/teams',
    requires: 'manage-teams',
    requiresActiveOrg: true,
    role: ['account_owner'],
    focusedFor: /^\/settings\/teams/,
    isExact: false,
    component: OrganizationTeams,
  },
  {
    name: 'SLO',
    route: '/slo/create',
    requires: 'none',
    requiresActiveOrg: true,
    role: ['account_owner'],
    focusedFor: /^\/slo/,
    isExact: false,
    component: CreateSlo,
  },
  {
    name: 'SLO',
    route: '/slo/edit/:sloid',
    requires: 'none',
    requiresActiveOrg: true,
    role: ['account_owner'],
    focusedFor: /^\/slo/,
    isExact: false,
    component: CreateSlo,
  },
  {
    name: 'SLO',
    route: '/slo/:sloid',
    requires: 'none',
    requiresOneOf: ['slos'],
    requiresActiveOrg: true,
    role: ['account_owner'],
    focusedFor: /^\/slo\/sloOverview/,
    isExact: false,
    component: sloOverView,
  },
  {
    name: 'Create Runbook',
    route: '/runbooks/create',
    requires: 'runbooks',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/runbooks\/create$/,
    isExact: true,
    component: Runbooks,
  },
  {
    name: 'Update Runbook',
    route: '/runbooks/update/:id/:mode',
    requires: 'runbooks',
    requiresActiveOrg: true,
    role: ['account_owner', 'user'],
    focusedFor: /^\/runbooks\/.{24}$/,
    isExact: true,
    component: Runbooks,
  },
  {
    name: 'Jira Cloud Extension',
    route: '/settings/extension/jira-cloud',
    requires: 'none',
    requiresActiveOrg: true,
    role: ['account_owner'],
    focusedFor: /^\/settings\/extension\/jira-cloud/,
    isExact: true,
    component: JiraCloudSettings,
  },
];

const configRoutes: IWebAppConfigRoutes[] = [
  {
    route: '/auth/statuspage',
    isExact: true,
    component: AuthStatusPage,
  },
  {
    route: '/setup-organization-slack',
    isExact: true,
    component: SlackIntegrationHelper,
  },
  {
    route: '/setup-organization-slackv2',
    isExact: true,
    component: IntegrateWithSlackRedirect,
  },
  {
    route: '/oauth/slack',
    isExact: true,
    component: SlackAuthRedirect,
  },
  {
    route: '/setup-organization-msteams',
    isExact: true,
    component: MSteamsIntegrationHelper,
  },
  {
    route: '/activate-user/:_id',
    isExact: true,
    component: Dashboard,
  },
];

export { visibleRoutes, subNav, configRoutes };
