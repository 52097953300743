import React from 'react';
import { ITask } from 'core/interfaces/ITask';
import { DeleteIcon, EditIcon } from 'icons';
import { Theme } from 'uie/components';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

interface IEditOrDeleteTaskProps {
  index: number;
  element: ITask | null;
  setUpdateTaskFn: (task: ITask, index: number) => void;
  enableDelete: number;
  setEnableDeleteModal: (value: boolean) => void;
  setDeleteTaskIndex: (value: number) => void;
  isSuppressedIncident: boolean;
}

interface IEditOrDeleteRunbookProps {
  index: number;
  id: string;
  enableDelete: number;
  setEnableDeleteModal: (value: boolean) => void;
  setDeleteRunbookId: (value: string) => void;
  isSuppressedIncident: boolean;
}

const EditOrDeleteTask = (props: IEditOrDeleteTaskProps) => {
  const hasUpdateAccess = useUserAccess().hasUpdateAccess('incidents');
  return (
    <div
      className="incident_details__task_or_runbook__delete"
      style={{
        visibility:
          props.enableDelete === props.index && !props.isSuppressedIncident ? 'visible' : 'hidden',
      }}
    >
      <NoPermissionTooltip isDisabled={hasUpdateAccess}>
        <EditIcon
          onClick={() => {
            if (!hasUpdateAccess) return;
            props.setUpdateTaskFn(props.element as ITask, props.index);
          }}
          className="incident_details__runbook__edit_Delete"
          style={
            !hasUpdateAccess
              ? {
                  opacity: 0.6,
                  cursor: 'not-allowed',
                }
              : {}
          }
        />
      </NoPermissionTooltip>

      <NoPermissionTooltip isDisabled={hasUpdateAccess}>
        <DeleteIcon
          onClick={() => {
            if (!hasUpdateAccess) return;
            props.setEnableDeleteModal(true);
            props.setDeleteTaskIndex(props.index);
          }}
          stroke={Theme.theme.danger.default}
          className="incident_details__runbook__edit_Delete"
          style={
            !hasUpdateAccess
              ? {
                  opacity: 0.6,
                  cursor: 'not-allowed',
                }
              : {}
          }
        />
      </NoPermissionTooltip>
    </div>
  );
};

const EditOrDeleteRunbook = (props: IEditOrDeleteRunbookProps) => {
  const hasUpdatePermission = useUserAccess().hasUpdateAccess('incidents');

  return (
    <div className="incident_details__task_or_runbook__delete">
      <NoPermissionTooltip isDisabled={hasUpdatePermission}>
        <DeleteIcon
          onClick={() => {
            if (!hasUpdatePermission) return;
            props.setEnableDeleteModal(true);
            props.setDeleteRunbookId(props.id);
          }}
          stroke={Theme.theme.danger.default}
          className="incident_details__runbook__edit_Delete"
          style={
            !hasUpdatePermission
              ? {
                  opacity: 0.6,
                  cursor: 'not-allowed',
                  visibility: !props.isSuppressedIncident ? 'visible' : 'hidden',
                }
              : {
                  visibility: !props.isSuppressedIncident ? 'visible' : 'hidden',
                }
          }
        />
      </NoPermissionTooltip>
    </div>
  );
};
export { EditOrDeleteTask, EditOrDeleteRunbook };
