import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { REQUEST_INTEGRATIONS, REQUEST_INTEGRATIONS_FAIL } from '../../const/public';
import { AjaxResponse } from 'rxjs/ajax';
import { getIntegrations } from '../../api/public/getIntegrations';
import { IIntegration } from '../../interfaces/IIntegration';
import { onRequestIntegrationsSuccess } from '../../actions/public/integrations';

const integrationEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_INTEGRATIONS),
    switchMap(() => {
      return getIntegrations().pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ result, data }: { result: boolean; data: IIntegration[] }) => {
          return onRequestIntegrationsSuccess(data);
        }),
        catchError(error =>
          of({
            type: REQUEST_INTEGRATIONS_FAIL,
            payload: error.xhr,
            error: true,
          }),
        ),
      );
    }),
  );

export default integrationEpic;
