import React from 'react';
import ReactSelect, { GroupBase, Props } from 'react-select';

import { customStyles } from './customStyles';

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    size?: 'sm' | 'md' | 'lg';
    /** If the Select Component has Custom Label Component */
    hasInputAddon?: boolean;
    hideDropdownArrow?: boolean;
    hideSelectedValues?: boolean;
    hideContainerBorder?: boolean;
    isSingleTimeDropdown?: boolean;
    disableLeftPaddingInValueContainer?: boolean;
    isParticipantGroupContainer?: boolean;
    inheritMultiValueBG?: boolean;
    disableMultiValueRemove?: boolean;
    inheritControlBG?: boolean;
    disableControlBorder?: boolean;
    isPaginationDropdown?: boolean;
    isOwnerFilterDropdown?: boolean;
    menuWidth?: string;
    menuListWidth?: string;
  }
}

type SelectProps = Props & {
  size?: 'sm' | 'md' | 'lg';
};

function Select({ size = 'sm', ...args }: SelectProps) {
  return (
    <ReactSelect
      name="colors"
      closeMenuOnSelect={false}
      styles={{
        ...customStyles,
      }}
      size={size}
      {...args}
    />
  );
}

export default Select;
