import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ChakraProvider,
} from '@chakra-ui/react';
import { useRef } from 'react';

interface IAlertDialog {
  isOpen: boolean;
  onClose: () => void;
  msg: string;
  callbackFn: () => void;
  title: string;
  isDelete: boolean;
  /** Pass false to disable ChakraProvider global styles */
  applyChakraStyle?: boolean;
}

export const AlertDialogComponent = ({
  isOpen,
  onClose,
  callbackFn,
  msg,
  title,
  isDelete,
  applyChakraStyle = true,
}: IAlertDialog) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <ChakraProvider resetCSS={applyChakraStyle} cssVarsRoot="#sloAlert">
      <AlertDialog id="sloAlert" isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{msg}</AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                sx={applyChakraStyle ? {} : { borderWidth: 0, cursor: 'pointer' }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  callbackFn();
                  onClose();
                }}
                ml={3}
                sx={applyChakraStyle ? {} : { borderWidth: 0, cursor: 'pointer' }}
              >
                {isDelete ? 'Delete' : 'Yes'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </ChakraProvider>
  );
};
