import { FocusBlock, Grid, Para, Theme } from 'uie/components';
import cx from 'classnames';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import { TEAM_ENTITIES_PATH } from 'views/main/routes/routes';
import { OverflownText } from './OverFlowText';

type EntityItemProps = {
  ID: string;
  name: string;
  count: number;
};

export function EntityItem({ ID, name, count }: EntityItemProps) {
  const { theme } = Theme;
  const history = useHistory();
  const location = useLocation();

  const handleClick = () => {
    history.push({
      search: location.search,
      hash: '#' + ID,
    });
  };

  const hash = location.hash;

  const selectedId = hash.replace('#', '');
  const isSelected = selectedId === ID;

  return (
    <div onClick={handleClick}>
      <FocusBlock
        value={ID}
        style={{
          paddingLeft: '8px',
          paddingRight: '8px',
          paddingTop: '12px',
          paddingBottom: '12px',
          marginTop: '12px',
          borderRadius: '2px',
        }}
        className={cx('org_settings__focus_tab__whiteBG', {
          org_settings__in_focus: isSelected,
        })}
      >
        <Grid
          flexWidth={12}
          style={{
            cursor: 'pointer',
          }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Para
            fontSize={14}
            fontWeight={400}
            color={theme.shades.cement}
            style={{
              maxWidth: '150px',
              lineHeight: '132%',
            }}
          >
            <OverflownText>
              {name} ({count})
            </OverflownText>
          </Para>
        </Grid>
      </FocusBlock>
    </div>
  );
}
