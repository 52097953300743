import {
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  IconButton,
  Link,
  Spinner,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { BillingService, debounce } from 'core';
import { T_WA_UP_SG_VIEW_REFRESH } from 'core/const/tracker';
import useQueryParams from 'core/hooks/useQueryParams';
import { CircleIcon, Filter } from 'icons';
import { InfoToolTip, Tab } from 'library/atoms';
import { RefreshIcon } from 'library/icons';
import { Loader } from 'library/molecules';
import { THEME_COLORS } from 'library/theme/colors';
import React, { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { AppTracker } from 'shared/analytics/tracker';
import { CustomDrawerComponent, Layout } from 'views/shared/components';

import { OrganizationContext } from '..';
import { ActiveFilterTags } from '../../owner-filters/ActiveFilterTags';
import { encodeFilterParam } from '../../owner-filters/helpers';
import {
  FilterEntity,
  FilterObject,
  Owner,
  useEntityOwnerFilter,
} from '../../owner-filters/hooks/useEntityOwnerFilter';
import { Header } from '../components';
import { Search } from '../components/Search';
import { SearchActionKind } from '../contants/service.contant';
import { invalGraphData } from '../helpers/helper.graph';
import {
  getServiceTabIndex,
  getServiceTabName,
  OVERVIEW_TAB_INDEX,
} from '../helpers/helper.query-params';
import { getLoadingState } from '../helpers/helper.service';
import { useMentainance, useMentainanceBulk } from '../hooks/useMentainance';
import { IServiceList, useLastUpdatedServiceQuery, useServiceList } from '../hooks/useServiceList';
import { useServicesOpenIncidentsQuery } from '../hooks/useServiceOpenIncidents';
import { ServiceGraph } from '../service.graph';
import { GraphPricing } from '../service.graph/pricing';
import ServiceList from './service.list';
import MaintenanceModal from './service.list/Mentainance';
import ServiceFilters from './service.list/ServiceFilters';
import { Limit, Result } from 'core/services/service.billing';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import qs from 'query-string';
import { usePersistQuery } from '../hooks/usePersistQuery';
import Tags from './service.list/tags';

export type ComponentState = 'loading' | 'failed' | 'done';
const TAB_INDEX_QUERY_KEY = 'tab';

const ServiceHome = React.memo(() => {
  const organization = React.useContext(OrganizationContext);
  const selectedTeam = organization?.selectedTeam.teamId;
  const _uA = useUserAccess();
  const hasCreate = _uA.hasCreateAccess('services');
  const queryClient = useQueryClient();
  const [isGraphRefreshing, setIsGraphRefreshing] = useBoolean();

  const [loadablePlanLimit, setIsLoadablePlanLimit] = useState(Result.loading<Limit>());

  if (organization?.plan?.p) {
    const newLoadedPlan = BillingService.getPlanLimit({ organization }, 'service-graph');
    if (newLoadedPlan.isLoaded && !loadablePlanLimit.isLoaded) {
      setIsLoadablePlanLimit(newLoadedPlan);
    }
  }

  const [isGraphDisabled, setIsGraphDisabled] = useState(true);

  useEffect(() => {
    if (loadablePlanLimit.isLoaded) {
      if (!BillingService.isFeatDisabled(loadablePlanLimit.get()) && isGraphDisabled) {
        setIsGraphDisabled(false);
      }
    }
  }, [loadablePlanLimit]);

  const {
    isLoading,
    isSuccess,
    isError,
    isFetched,
    state: {
      search: { enabled: isSearchEnabled },
      pagination: { queryPageIndex, queryPageSize },
      drawer: { isDrawerOpen, drawerType, serviceID: hoveredServiceID },
      isChecked: { checkedRows },
      sorting,
    },
    data,
    dispatch,
    refetch,
  } = useServiceList(selectedTeam);
  const serviceLastUpdated = useLastUpdatedServiceQuery();
  const updateMentainancefn = useMentainance();
  const updateMentainanceBulkfn = useMentainanceBulk();

  const { data: openIncidentsData = {}, isLoading: isLoadingIncidents } =
    useServicesOpenIncidentsQuery(data?.data.services.map(s => s.id) ?? [], selectedTeam ?? '');

  const serviceListData = useMemo(() => {
    if (!data) return data;
    const serviceData = {
      ...data,
      data: {
        ...data.data,
        services: data.data.services.map(s => ({
          ...s,
          metrics: {
            ...s.metrics,
            incident_metrics: {
              triggered: openIncidentsData[s.id]?.triggered ?? 0,
              acknowledged: openIncidentsData[s.id]?.acknowledged ?? 0,
              suppressed: 0,
              resolved: 0,
            },
          },
        })),
      },
    } as IServiceList;
    return serviceData;
  }, [data, openIncidentsData]);

  const history = useHistory();
  const query = useQueryParams();
  const persistQuery = usePersistQuery();

  const searchQueryParam = query.get('search');
  const filterQueryParam = query.get('entity_owner') ?? '';
  const isOpenTypeQueryParam = query.get('actionType');
  const hoveredServiceid = query.get('serviceID');
  const sortby = query.get('sortby');
  const sortorder = query.get('sortorder');

  const [tabIndex, setTabIndex] = useState(
    getServiceTabIndex(query.get(TAB_INDEX_QUERY_KEY) ?? ''),
  );

  useEffect(() => {
    const value = getServiceTabName(tabIndex);

    query.get(TAB_INDEX_QUERY_KEY)
      ? query.set(TAB_INDEX_QUERY_KEY, value)
      : query.append(TAB_INDEX_QUERY_KEY, value);
    history.push({ search: query.toString() });
  }, [tabIndex]);

  const [showRefreshInfo, setShowRefreshInfo] = useBoolean();

  const debouncedSearch = useRef(
    debounce((searchParam: string) => {
      dispatch({ type: SearchActionKind.SEARCHED, searchText: searchParam || '' });
    }, 1000),
  ).current;

  useEffect(() => {
    dispatch({ type: SearchActionKind.FILTERED, filterText: filterQueryParam || '' });
    if (typeof searchQueryParam === 'string') {
      dispatch({
        type: SearchActionKind.SEARCH_ENABLED_OR_DISABLED,
        isEnabled: true,
      });

      debouncedSearch(searchQueryParam);
      dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex: 1 });
    } else if (typeof isOpenTypeQueryParam === 'string') {
      dispatch({
        type: SearchActionKind.OPEN_DRAWER,
        drawerType: isOpenTypeQueryParam,
        isDrawerOpen: true,
        serviceID: hoveredServiceid,
      });
    } else if (typeof sortby === 'string' && typeof sortorder === 'string') {
      dispatch({
        type: SearchActionKind.SORTING,
        sort_by: sortby,
        sort_order: sortorder,
      });
      dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex: 1 });
    } else {
      dispatch({
        type: SearchActionKind.SEARCH_ENABLED_OR_DISABLED,
        isEnabled: false,
      });
      dispatch({ type: SearchActionKind.CLOSE_DRAWER });
      dispatch({
        type: SearchActionKind.ROW_SELECTION,
        checkedRows: [],
      });
      refetch();
    }
  }, [
    query.get('search'),
    query.get('actionType'),
    query.get('sortby'),
    query.get('sortorder'),
    filterQueryParam,
  ]);

  const {
    activeFilterObject,
    clearSelectedFilterValues,
    selectedFilterObject,
    activeFilterTags,
    dropdownOptions,
    onChangeHandler,
    selectedFilterTags,
    handleActiveFilterTagClick,
    handleSelectedFilterTagClick,
    ownerFilterApplied,
    ownerFilterSelected,
    resetOwnerFilters,
    syncSelectedFilterWithActiveFilter,
  } = useEntityOwnerFilter({ queryParamsString: filterQueryParam, entity: FilterEntity.SERVICE });

  const onDrawerClose = () => {
    if (!ownerFilterApplied) {
      clearSelectedFilterValues();
    } else {
      syncSelectedFilterWithActiveFilter();
    }

    persistQuery();

    dispatch({
      type: SearchActionKind.OPEN_DRAWER,
      isDrawerOpen: false,
    });
  };

  const applyEntityOwnerFilters = (filterObject: FilterObject) => {
    const filterUrlParams = encodeFilterParam(filterObject);
    query.delete('entity_owner');
    filterUrlParams && query.append('entity_owner', filterUrlParams);
    history.push(`/service-catalog?${query}`);
    dispatch({
      type: SearchActionKind.OPEN_DRAWER,
      isDrawerOpen: false,
    });
  };

  const onClickActiveFilterTag = (type: Owner, id: string) => {
    handleActiveFilterTagClick(type, id);
  };

  useEffect(() => {
    applyEntityOwnerFilters(activeFilterObject);
  }, [activeFilterObject]);

  useEffect(() => {
    if (isDrawerOpen && !ownerFilterSelected && ownerFilterApplied) {
      /** For scenario when we clear all selected tags from drawer and a filter is applied.
      Without this useEffect, we will not be able to clear out the filters */
      onClearFilters();
    }
  }, [isDrawerOpen, ownerFilterSelected, ownerFilterApplied]);

  const onClickApplyFilters = () => applyEntityOwnerFilters(selectedFilterObject);

  const onClearFilters = () => {
    resetOwnerFilters();
    query.delete('entity_owner');
    history.push(`/service-catalog?${query}`);
    dispatch({
      type: SearchActionKind.OPEN_DRAWER,
      isDrawerOpen: false,
    });
  };

  const onCancel = () => {
    if (!ownerFilterApplied) {
      clearSelectedFilterValues();
    } else {
      syncSelectedFilterWithActiveFilter();
    }
    dispatch({
      type: SearchActionKind.OPEN_DRAWER,
      isDrawerOpen: false,
    });
  };

  const actionType = query.get('actionType');
  const serviceID = query.get('serviceID');
  const entityOwner = query.get('entity_owner');

  useEffect(() => {
    if (actionType && serviceID) {
      const queryParams = qs.parse(history.location.search);

      const routeConfig = {
        pathname: history.location.pathname,
        search: qs.stringifyUrl({
          url: history.location.search,
          query: {
            ...queryParams,
            serviceID: serviceID,
            actionType: actionType,
          },
        }),
      };

      history.push(routeConfig);

      dispatch({
        type: SearchActionKind.OPEN_DRAWER,
        isDrawerOpen: true,
        drawerType: actionType,
        serviceID: serviceID,
      });
    }
  }, [actionType, serviceID, entityOwner]);

  const ServiceListTable = (
    {
      failed: () => <></>,
      loading: () => (
        <Center h="60vh">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      ),
      done: () => {
        return (
          <ServiceList
            serviceListData={serviceListData}
            isLoadingIncidents={isLoadingIncidents}
            pageChange={(queryPageIndex: number) =>
              dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex })
            }
            pageSizeChange={(queryPageSize: number) => {
              dispatch({ type: SearchActionKind.PAGE_SIZE_CHANGED, queryPageSize });
              dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex: 1 });
            }}
            queryPageIndex={queryPageIndex}
            queryPageSize={queryPageSize}
            setCheckedRows={(rowsSelected: Array<string>) => {
              dispatch({ type: SearchActionKind.ROW_SELECTION, checkedRows: rowsSelected });
            }}
            checkedRows={checkedRows}
            sorting={sorting}
            lastUpdate={
              serviceLastUpdated.isSuccess && serviceLastUpdated.data.last_updated
                ? serviceLastUpdated.data.last_updated
                : null
            }
          />
        );
      },
    } as Record<ComponentState, FC>
  )[getLoadingState(isLoading, isFetched, isError)];

  const MemoizedServiceListTable = useMemo(
    () => ServiceListTable,
    [isLoading, isFetched, isError, checkedRows, serviceListData],
  );

  const getServiceName = (serviceList: Array<string>) => {
    return data && serviceList
      ? serviceList
          .map(serviceId => {
            return data.data.services.filter((serviceObj, index) => {
              return serviceObj.id === serviceId;
            })[0]?.name;
          })
          .filter(Boolean)
      : null;
  };

  const onClickSearch = (searchTerm?: string) => {
    query.delete('search');
    query.append('search', searchTerm ?? searchQueryParam ?? '');
    history.push(`/service-catalog?${query}`);
  };

  const onRefresh = () => {
    invalGraphData(queryClient);
    setShowRefreshInfo.off();
    AppTracker.track(T_WA_UP_SG_VIEW_REFRESH);

    // NOTE: needs this make shifter as we need to remove graph components from memory
    // otherwise it keeps old data references for edges and nodes
    setIsGraphRefreshing.on();
    setTimeout(() => {
      setIsGraphRefreshing.off();
    }, 1000);
  };

  return (
    <Layout>
      <Fragment>
        <Header
          pb={2}
          title={
            <HStack>
              <Text fontSize="27px" color="#1D426B" fontWeight="400">
                Services
              </Text>
              {tabIndex === OVERVIEW_TAB_INDEX && (
                <ActiveFilterTags
                  tags={activeFilterTags}
                  onClose={onClickActiveFilterTag}
                  visibleTagsCount={2}
                  applyChakraStyle={false}
                />
              )}
            </HStack>
          }
          actions={
            tabIndex === OVERVIEW_TAB_INDEX && (
              <Flex justifyContent={'flex-end'} gap={5}>
                <Flex position="relative">
                  <IconButton
                    aria-label="Filter services"
                    variant={'icon'}
                    icon={<Filter style={{ fill: '#2D3748', stroke: '#2D3748' }} />}
                    onClick={() =>
                      dispatch({
                        type: SearchActionKind.OPEN_DRAWER,
                        isDrawerOpen: true,
                        drawerType: 'Filter',
                      })
                    }
                  />
                  {ownerFilterApplied && (
                    <CircleIcon
                      style={{
                        fill: 'red',
                        stroke: 'red',
                        position: 'absolute',
                        top: '-5',
                        right: '-10',
                      }}
                      height="10px"
                    />
                  )}
                </Flex>
                <Search
                  setSearchTerm={onClickSearch}
                  searchTerm={searchQueryParam ?? ''}
                  searchEnabled={isSearchEnabled}
                  setSearchEnabled={isEnabled => onClickSearch(searchQueryParam ?? '')}
                  isDisabled={!isSuccess}
                />
                <Center height={8}>
                  <Divider orientation="vertical" />
                </Center>
                <NoPermissionTooltip isDisabled={hasCreate}>
                  <Button
                    onClick={() => history.push('/service-catalog/add/service')}
                    variant="default"
                    size="sm"
                    isDisabled={!hasCreate}
                  >
                    Add New Service
                  </Button>
                </NoPermissionTooltip>
              </Flex>
            )
          }
        />
        <div style={{ paddingLeft: '24px', marginBottom: '24px' }}>
          <Text variant="grayedCaption">
            Services are logical units of your product/service and are mapped to alert sources.
            Choose from the Catalog and Graph view to
            <br /> understand your services, health metrics and their dependencies in detail.{' '}
            <Link
              href="https://support.squadcast.com/services/adding-a-service"
              target="_blank"
              rel="noopener noreferrer"
              variant="underlinedLink"
            >
              Read More
            </Link>
          </Text>
        </div>
        <Divider variant="smokeShaded" />
        <Tab
          isLazy
          index={tabIndex}
          onChange={setTabIndex}
          pt="12px"
          labels={[
            {
              name: 'Overview',
            },
            {
              name: 'Graph',
              isNew: true,
              isLocked: loadablePlanLimit.isLoaded && isGraphDisabled,
            },
          ]}
          variant="outline"
          customHeaderElement={
            showRefreshInfo && (
              <HStack ml="auto" columnGap={0.5} mr={4}>
                <Text fontSize="10.5px" color={THEME_COLORS.secondary[700]}>
                  We’ve detected some new changes to this graph. Refresh to see the new updates
                </Text>
                <IconButton
                  variant="primary"
                  aria-label="refresh"
                  onClick={onRefresh}
                  icon={<RefreshIcon />}
                />
                <InfoToolTip
                  label="Note: If you’ve moved your nodes around, refreshing this page would move it back to their default positions."
                  color={THEME_COLORS.secondary[700]}
                  pl={0}
                  size="lg"
                />
              </HStack>
            )
          }
        >
          <MemoizedServiceListTable />
          {!loadablePlanLimit.isLoaded ? (
            <Loader />
          ) : isGraphDisabled ? (
            <GraphPricing
              onCancel={() => {
                setTabIndex(OVERVIEW_TAB_INDEX);
              }}
            />
          ) : isGraphRefreshing ? (
            <Loader />
          ) : (
            <ServiceGraph onRefresh={setShowRefreshInfo.on} />
          )}
        </Tab>
        {/* Drawer for service listing page */}
        <CustomDrawerComponent
          onClose={onDrawerClose}
          isOpen={isDrawerOpen}
          title={
            drawerType === 'TAGS'
              ? 'Add/Update Tags'
              : drawerType === 'Filter'
              ? 'Filter'
              : 'Maintenance Mode'
          }
          disableBodyPadding={drawerType === 'Filter'}
          {...(drawerType === 'Filter' ? { size: 'sm' } : {})}
        >
          {drawerType === 'TAGS' && hoveredServiceID && <Tags serviceId={hoveredServiceID} />}

          {drawerType === 'MAINTENANCE' && (
            <MaintenanceModal
              checkAndSetDirty={() => {}}
              hide={() => {
                history.push(`/service-catalog`);
              }}
              onSave={(payload: any) => {
                hoveredServiceID
                  ? updateMentainancefn({ serviceId: hoveredServiceID, payload })
                  : checkedRows && updateMentainanceBulkfn({ serviceIds: checkedRows, payload });
              }}
              serviceId={hoveredServiceID}
              serviceList={
                checkedRows && checkedRows.length
                  ? getServiceName(checkedRows)
                  : hoveredServiceID
                  ? getServiceName([hoveredServiceID])
                  : null
              }
            ></MaintenanceModal>
          )}

          {drawerType === 'Filter' && (
            <ServiceFilters
              {...{
                onClickApplyFilters,
                onCancel,
                onClearFilters,
                dropdownOptions,
                onChangeHandler,
                filterTags: selectedFilterTags,
                handleSelectedFilterTagClick,
                filtersSelected: ownerFilterSelected,
                filterName: 'Service Owner',
              }}
            />
          )}
        </CustomDrawerComponent>
      </Fragment>
    </Layout>
  );
});

export default ServiceHome;
