import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Tag,
  TagLabel,
  VStack,
} from '@chakra-ui/react';
import { Locale } from 'core/helpers/dateUtils';
import FileUploadService, {
  FileUploadFeature,
  OperationType,
} from 'core/services/service.fileUpload';
import { Field, getIn, useFormikContext } from 'formik';
import { MdBlock } from 'library/molecules';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-date-picker';
import NestedDropdown from '../../../components/NestedDropdown';
import { fileUploadViewConfig } from '../../../constants/schema';
import { parseDateStringToUTCDate } from '../../../helpers/helper.details';
import { generateTimeIntervals, getSelectedComponents } from '../../../helpers/helper.service';
import { IMaintenanceForm, IOption } from '../../../Interface';

const { imageTexts, ...fileUploadConfig } = fileUploadViewConfig;

interface FormProps {
  disabled?: boolean;
  newForm?: boolean;
}

const MaintenanceForm = (props: FormProps) => {
  const { values, errors, setFieldValue } = useFormikContext<IMaintenanceForm>();
  const [addNote, setAddNote] = useState(!!values.note);

  const timeOptions = useMemo(() => {
    return generateTimeIntervals();
  }, []);

  const handleComponentSelect = (componentOptions: IOption[]) => {
    const selectedComponentsForMaintenance = getSelectedComponents(
      componentOptions,
      values.selectedComponentsForMaintenance || [],
    );
    setFieldValue('selectedComponentsForMaintenance', selectedComponentsForMaintenance);
  };
  useEffect(() => {
    if (props.newForm) {
      setFieldValue('note', '');
      setFieldValue('selectedAddNote', false);
      setAddNote(false);
    }
  }, [props.newForm]);

  const fileUploadService = new FileUploadService();

  return (
    <VStack pl={0} pr={2} py={3} spacing={6} alignItems="flex-start">
      <FormControl>
        <VStack alignItems="flex-start" w="100%">
          <FormControl isInvalid={errors.title ? true : false} mb={6}>
            <FormLabel>Maintenance Name*</FormLabel>
            <Field
              width="100%"
              as={Input}
              type="text"
              name="title"
              disabled={props.disabled}
              placeholder="Maintenance Title"
            />
            {errors?.title && <FormErrorMessage>{errors.title}</FormErrorMessage>}
          </FormControl>
          <Flex width={'100%'}>
            <Flex width={'50%'}>
              <FormControl pb={6} pr={6}>
                <FormLabel>Start Date*</FormLabel>
                <DatePicker
                  onChange={(value: Date) => {
                    const date = moment(value).format('YYYY-MM-DD');
                    setFieldValue('startDate', date);
                  }}
                  value={parseDateStringToUTCDate(
                    moment.utc(values.startDate).format('YYYY-MM-DD'),
                  )}
                  calendarIcon={null}
                  clearIcon={null}
                  disabled={props.disabled}
                  format={Locale.toDateFieldDisplay()}
                  name="startDate"
                />
              </FormControl>
            </Flex>
            <Flex width={'50%'}>
              <FormControl>
                <FormLabel>Start Time* ({moment.tz(values.timezone).zoneAbbr()})</FormLabel>
                <Select
                  onChange={event => {
                    setFieldValue('startTime', event.target.value);
                    event.preventDefault();
                  }}
                  disabled={props.disabled}
                >
                  {timeOptions.map((time, index) => {
                    return (
                      <option key={index} value={time} selected={values.startTime === time}>
                        {time}
                      </option>
                    );
                  })}
                </Select>
                {errors?.startTime && <FormErrorMessage>{errors.startTime}</FormErrorMessage>}
              </FormControl>
            </Flex>
          </Flex>
          <Flex width={'100%'}>
            <Flex width={'50%'}>
              <FormControl pb={6} pr={6}>
                <FormLabel>End Date*</FormLabel>
                <DatePicker
                  onChange={(value: Date) => {
                    const date = moment(value).format('YYYY-MM-DD');
                    setFieldValue('endDate', date);
                  }}
                  value={parseDateStringToUTCDate(moment.utc(values.endDate).format('YYYY-MM-DD'))}
                  calendarIcon={null}
                  clearIcon={null}
                  format={Locale.toDateFieldDisplay()}
                  name="endDate"
                  disabled={props.disabled}
                />
              </FormControl>
            </Flex>
            <Flex width={'50%'}>
              <FormControl isInvalid={(errors?.endTime?.length || 0) > 0}>
                <FormLabel>End Time* ({moment.tz(values.timezone).zoneAbbr()})</FormLabel>
                <Select
                  onChange={event => {
                    setFieldValue('endTime', event.target.value);
                    event.preventDefault();
                  }}
                  disabled={props.disabled}
                >
                  {timeOptions.map((time, index) => {
                    return (
                      <option key={index} value={time} selected={values.endTime === time}>
                        {time}
                      </option>
                    );
                  })}
                </Select>
                {errors?.endTime && <FormErrorMessage>{errors.endTime}</FormErrorMessage>}
              </FormControl>
            </Flex>
          </Flex>

          <Flex
            justifyContent="flex-start"
            direction="column"
            bg="rgb(165 203 250 / 23%)"
            p={5}
            width="100%"
          >
            <FormControl>
              <FormLabel htmlFor="statusPageName">Status Page</FormLabel>
              <Field
                width="70%"
                as={Input}
                type="text"
                name="pageName"
                backgroundColor="white"
                readOnly
                marginBottom={4}
              />
              <FormControl
                isInvalid={errors.selectedComponentsForMaintenance ? true : false}
                width="70%"
              >
                <FormLabel>Select Component for Maintenance*</FormLabel>
                <NestedDropdown
                  componentGroupOption={values.components}
                  setOption={handleComponentSelect}
                  disabled={props.disabled}
                />
                {values.selectedComponentsForMaintenance.length === 0 &&
                  errors.selectedComponentsForMaintenance && (
                    <FormErrorMessage>
                      {getIn(errors, 'selectedComponentsForMaintenance')}
                    </FormErrorMessage>
                  )}

                {values.selectedComponentsForMaintenance.length > 0 ? (
                  <HStack flexWrap="wrap" rowGap={2} mt={3}>
                    {values.selectedComponentsForMaintenance.map((option: any) => {
                      return (
                        <Tag key={option.id} size="lg" backgroundColor={'white'}>
                          <TagLabel>{option.name}</TagLabel>
                        </Tag>
                      );
                    })}
                  </HStack>
                ) : null}
              </FormControl>
            </FormControl>
          </Flex>
          <FormControl pt={6} pb={10} px={1}>
            <Checkbox
              isChecked={values.selectedAddNote}
              onChange={e => {
                setAddNote(!addNote);
                setFieldValue('selectedAddNote', e.target.checked);
              }}
              pb={2}
              disabled={props.disabled}
            >
              Add Note
            </Checkbox>
          </FormControl>
          {addNote && (
            <>
              <FormControl isInvalid={errors.note ? true : false}>
                <MdBlock
                  value={values.note}
                  onChange={content => {
                    setFieldValue('note', content);
                  }}
                  options={{
                    maxHeight: '130px',
                    spellChecker: false,
                    placeholder: '',
                    imageUploadFunction: fileUploadService.getUploadFunctionForFeature(
                      FileUploadFeature.STATUS_PAGE,
                      OperationType.CREATE,
                    ),
                    imageTexts: {
                      sbInit: ``,
                      ...imageTexts,
                    },
                    ...fileUploadConfig,
                  }}
                />
                {errors?.note && <FormErrorMessage color="red.500">{errors.note}</FormErrorMessage>}
              </FormControl>
            </>
          )}
        </VStack>
      </FormControl>
    </VStack>
  );
};

export default MaintenanceForm;
