import Axios from 'axios';
import qs from 'query-string';
import { API } from 'core';
import { QUERY_CONFIG } from 'library/query-config';
import { QueryClient, useQuery } from 'react-query';

import { QUERY_KEY } from './queryKeys';

const getList = (incidentId: string, page: number, limit: number) => {
  const props = {
    page_limit: limit,
    page_number: page,
  };
  return Axios.get(
    `${API.config.batman}/organizations/${
      API.config.organizationId
    }/incidents/${incidentId}/alerts?${qs.stringify(props)}`,
  );
};

export const useGetAutoGroupedAlertsList = (incidentId: string, page: number, limit: number) => {
  const { data, isSuccess, isError, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEY.AUTO_GROUPED_ALERTS_LIST, incidentId, page, limit],
    queryFn: () => getList(incidentId, page, limit),
    ...QUERY_CONFIG,
  });
  const response = data?.data ?? { data: {}, meta: {} };
  return {
    alertsData: response.data,
    isSuccess,
    isError,
    isLoading,
    isFetching,
    count: response.meta.count ?? 0,
  };
};

export const invalidateAutoGroupedAlertsList = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(QUERY_KEY.AUTO_GROUPED_ALERTS_LIST);
};
