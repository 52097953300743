const ace = window.ace;

ace.define(
  'ace/mode/stokens_highlight_rules',
  [
    'require',
    'exports',
    'module',
    'ace/lib/oop',
    'ace/mode/doc_comment_highlight_rules',
    'ace/mode/text_highlight_rules',
  ],
  function (require, exports, module) {
    var oop = require('../lib/oop');

    var TextHighlightRules = require('./text_highlight_rules').TextHighlightRules;
    var identifierRe = '[a-zA-Z\\$_\u00a1-\uffff][a-zA-Z\\d\\$_\u00a1-\uffff]*';

    var stokensHighlightRules = function (options) {
      var keywordMapper = this.createKeywordMapper(
        {
          keyword:
            'message|user|service|status|in|help|integration|profile|sort|incidentNumber|after|before',
        },
        'identifier',
      );

      this.$rules = {
        no_regex: [
          {
            token: keywordMapper,
            regex: identifierRe,
          },
          {
            token: 'storage.type',
            regex: /:/,
            next: 'start',
          },
          {
            token: 'keyword.operator',
            regex: /AND|OR/,
            next: 'start',
          },
        ],
        start: [
          {
            token: 'string.regexp',
            regex: '\\/',
            next: 'regex',
          },
          {
            token: 'text',
            regex: '\\s+|^$',
            next: 'start',
          },
          {
            token: 'empty',
            regex: '',
            next: 'no_regex',
          },
        ],
      };

      this.normalizeRules();
    };

    oop.inherits(stokensHighlightRules, TextHighlightRules);
    exports.stokensHighlightRules = stokensHighlightRules;
  },
);

ace.define(
  'ace/mode/matching_brace_outdent',
  ['require', 'exports', 'module', 'ace/range'],
  function (require, exports, module) {
    var Range = require('../range').Range;

    var MatchingBraceOutdent = function () {};

    (function () {
      this.checkOutdent = function (line, input) {
        if (!/^\s+$/.test(line)) return false;

        return /^\s*\}/.test(input);
      };

      this.autoOutdent = function (doc, row) {
        var line = doc.getLine(row);
        var match = line.match(/^(\s*\})/);

        if (!match) return 0;

        var column = match[1].length;
        var openBracePos = doc.findMatchingBracket({ row: row, column: column });

        if (!openBracePos || openBracePos.row === row) return 0;

        var indent = this.$getIndent(doc.getLine(openBracePos.row));
        doc.replace(new Range(row, 0, row, column - 1), indent);
      };

      this.$getIndent = function (line) {
        return line.match(/^\s*/)[0];
      };
    }.call(MatchingBraceOutdent.prototype));

    exports.MatchingBraceOutdent = MatchingBraceOutdent;
  },
);

ace.define(
  'ace/mode/stokens',
  [
    'require',
    'exports',
    'module',
    'ace/lib/oop',
    'ace/mode/text',
    'ace/mode/stokens_highlight_rules',
    'ace/mode/matching_brace_outdent',
  ],
  function (require, exports, module) {
    var oop = require('../lib/oop');
    var TextMode = require('./text').Mode;
    var stokensHighlightRules = require('./stokens_highlight_rules').stokensHighlightRules;
    var MatchingBraceOutdent = require('./matching_brace_outdent').MatchingBraceOutdent;

    var Mode = function () {
      this.HighlightRules = stokensHighlightRules;
      this.$outdent = new MatchingBraceOutdent();
    };
    oop.inherits(Mode, TextMode);

    (function () {
      this.$id = 'ace/mode/stokens';
      this.snippetFileId = 'ace/snippets/stokens';
    }.call(Mode.prototype));

    exports.Mode = Mode;
  },
);
(function () {
  ace.require(['ace/mode/stokens'], function (m) {
    if (typeof module == 'object' && typeof exports == 'object' && module) {
      module.exports = m;
    }
  });
})();
