import React from 'react';

import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
} from '@chakra-ui/react';

interface InputNumberProps {
  size?: string | 'sm' | 'md' | 'lg' | 'xs';
  w?: NumberInputProps['w'];
  maxW?: NumberInputProps['maxW'];
  defaultValue?: number;
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
  value?: number;
  name?: string;
  isDisabled?: boolean;
}
function InputNumber({
  size = 'md',
  maxW = 24,
  w,
  defaultValue,
  min,
  max,
  onChange,
  name,
  value,
  isDisabled,
}: InputNumberProps) {
  return (
    <NumberInput
      size={size}
      w={w}
      maxW={maxW}
      defaultValue={defaultValue}
      min={min}
      max={max}
      onChange={(_str, value) => onChange?.(value)}
      name={name}
      value={value}
      isDisabled={isDisabled}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper fontSize={10} />
        <NumberDecrementStepper fontSize={10} />
      </NumberInputStepper>
    </NumberInput>
  );
}

export default InputNumber;
