import * as React from 'react';
import { SVGProps } from 'react';

const SvgRedashLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#fe7964',
      }}
      d="M11.033 0c.41.188.863.214 1.299.342 2.913.846 5.194 3.528 5.545 6.544.034.265.094.53.06.803-.146 1.76-.94 3.221-2.094 4.512-1.298 1.452-2.554 2.939-3.836 4.4-.478.547-.931 1.11-1.435 1.632-.376.384-.641.794-.547 1.358.017.128 0 .256-.017.385-.29.102-.162-.145-.171-.222-.008-.795-.008-1.59 0-2.384 0-.359-.017-.71.009-1.06.017-.29-.035-.495-.376-.504.324-.23.265-.572.239-.888-.017-.24-.222-.274-.41-.282-1.803-.111-3.358-.846-4.69-2.016-1.308-1.162-2.171-2.615-2.461-4.366a5.79 5.79 0 0 0-.069-.556c.094-2.563 1.196-4.605 3.23-6.143A7.565 7.565 0 0 1 8.479.162c.145-.025.316.009.418-.145h.513c.17.069.342.069.512 0h.171c.171.069.342.069.513 0C10.743 0 10.888 0 11.033 0z"
    />
    <path
      style={{
        fill: '#fe8673',
      }}
      d="M9.922 0c-.17.128-.341.128-.512 0h.512zM10.606 0c-.171.128-.342.128-.513 0h.513z"
    />
    <path
      style={{
        fill: '#ee6c58',
      }}
      d="M10.008 19.976c-.248-.675-.06-1.23.418-1.76 1.3-1.462 2.555-2.948 3.845-4.418.71-.811 1.444-1.597 2.102-2.443.82-1.043 1.247-2.239 1.486-3.52.009-.052.043-.103.069-.154.068 2.11-.641 3.947-2.017 5.536l-5.775 6.647c-.034.043-.085.077-.128.111zM2.233 8.194a7.732 7.732 0 0 0 1.615 3.46c1.281 1.572 2.956 2.46 4.93 2.837a.86.86 0 0 0 .213.025c1.051 0 .812.12.837 1.051.009.273-.205.205-.358.24-2.974-.035-5.947-2.29-6.972-5.298-.188-.547-.368-1.11-.359-1.7-.009-.214-.137-.436.094-.615z"
    />
    <path
      style={{
        fill: '#f0705d',
      }}
      d="M2.233 8.194c-.162.188-.043.41-.094.615-.128-.368-.077-.744-.068-1.12.06.171.11.342.162.505z"
    />
    <path
      style={{
        fill: '#fefefe',
      }}
      d="M15.04 8.023v2.64c0 .512-.18.675-.692.683h-.769c-.564 0-.735-.17-.743-.735V6.314c0-.325-.009-.65 0-.983.008-.47.18-.64.64-.649.3-.008.599 0 .898 0 .504.009.666.162.666.658v2.683zM9.572 8.988c0 .572.009 1.136 0 1.709-.008.47-.18.65-.64.658-.317.008-.624 0-.94 0-.402 0-.616-.205-.616-.598a251.291 251.291 0 0 1 0-3.58c0-.35.205-.556.556-.581.35-.017.709-.017 1.068 0 .367.017.572.222.572.598v1.794zM12.289 9.723c0 .325.009.658 0 .982-.017.453-.205.641-.658.65a18.93 18.93 0 0 1-.982 0c-.35-.009-.556-.197-.564-.564a96.021 96.021 0 0 1 0-2.213c0-.359.196-.547.555-.555.342 0 .684.008 1.025 0 .41-.009.607.213.616.598.017.359.008.726.008 1.102zM4.694 10.253c0-1.094.008-1.103 1.127-1.103.154 0 .316-.008.47 0 .368.026.581.197.573.59-.009.359 0 .71 0 1.068 0 .325-.18.513-.487.53a9.192 9.192 0 0 1-1.188 0c-.29-.017-.47-.188-.478-.496-.026-.188-.018-.393-.018-.59z"
    />
  </svg>
);

export default SvgRedashLogo20X20;
