import { Box, Text } from '@chakra-ui/layout';
import React, { FC, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { MSTeamMessageChannelAction, MsTeamOption } from 'views/main/organization/workflow/types';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import { useCreateEntityMap, transformMessage } from 'library/atoms/MdBlock/helper';
import { useCheckMsTeamStatus } from 'views/main/organization/workflow/details/hooks/useCheckMsTeamStatus';

interface IProps {
  action: MSTeamMessageChannelAction;
}

const MessageTeamChannelCard: FC<IProps> = ({ action }) => {
  const entities = useCreateEntityMap();

  const msTeamData = useCheckMsTeamStatus();
  const channelName = msTeamData.data?.connected_teams;

  const channelOptions = useMemo(() => {
    return (
      channelName?.flatMap(team => {
        return (
          team.channel_configurations?.map(channel => {
            const option: MsTeamOption = {
              label: channel.msteams_channel_name + '-' + `${team.team_name}`,
              value: channel.msteams_channel_id,
            };
            return option;
          }) || []
        );
      }) || []
    );
  }, [msTeamData.isSuccess, msTeamData.isRefetching, channelName]);
  const selectedChannel = channelOptions?.find(channel => channel.value === action.data.channel_id);

  return (
    <React.Fragment>
      <Box py={4}>
        <Text variant="h3" color="secondary.700" textStyle="bold_800">
          CHANNEL
        </Text>
        <Text> {selectedChannel?.label}</Text>
      </Box>
      <Box>
        <Text variant="h3" color="secondary.700" textStyle="bold_800">
          MESSAGE
        </Text>
        <Text color="secondary.1000" variant="h4" fontWeight="400" textTransform="capitalize">
          {' '}
          <ReactMarkdown className="mde" plugins={[breaks, gfm]}>
            {transformMessage(action.data.message.replace(/ /g, '\u00A0'), entities)}
          </ReactMarkdown>
        </Text>
      </Box>
    </React.Fragment>
  );
};

export default MessageTeamChannelCard;
