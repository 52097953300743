import * as React from 'react';
import { SVGProps } from 'react';

const SvgAwsSsoLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="AWS_SSO_logo_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 40 40"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.AWS_SSO_logo_svg__st1{fill-rule:evenodd;clip-rule:evenodd;fill:#f90}'}</style>
    <path
      d="M11.3 16.7c0 .5.1.9.1 1.2.1.3.2.6.4 1 .1.1.1.2.1.3 0 .1-.1.3-.3.4l-.8.6c-.1.1-.2.1-.3.1-.1 0-.3-.1-.4-.2-.2-.2-.3-.4-.5-.6-.1-.2-.3-.5-.4-.8-1 1.2-2.3 1.8-3.9 1.8-1.1 0-2-.3-2.7-1-.7-.6-1-1.5-1-2.6s.4-2 1.2-2.7 1.9-1 3.3-1c.5 0 .9 0 1.4.1l1.5.3v-1c0-1-.2-1.7-.6-2.1-.4-.4-1.1-.6-2.1-.6-.5 0-.9.1-1.4.2s-1 .3-1.4.5c-.2.1-.4.1-.5.2h-.2c-.2 0-.3-.1-.3-.4v-.7c0-.2 0-.4.1-.5 0-.1.1-.2.3-.3.5-.2 1-.4 1.7-.6.7-.2 1.3-.3 2.1-.3 1.6 0 2.7.4 3.5 1.1.7.7 1.1 1.8 1.1 3.3v4.3zm-5.4 2c.4 0 .9-.1 1.4-.2s.9-.5 1.3-.9c.2-.3.4-.5.5-.9s.1-.7.1-1.2v-.6c-.4-.1-.8-.2-1.2-.2-.4-.1-.8-.1-1.3-.1-.9 0-1.5.2-2 .5-.5.6-.7 1.1-.7 1.7 0 .6.2 1.1.5 1.4.3.4.8.5 1.4.5zm10.7 1.5c-.2 0-.4 0-.5-.1-.1-.1-.2-.3-.3-.5L12.6 9.3c-.1-.3-.1-.4-.1-.5 0-.2.1-.3.3-.3h1.3c.3 0 .4 0 .5.1.1.1.2.3.3.5l2.2 8.8L19.2 9c.1-.3.1-.4.3-.5.1-.1.3-.1.5-.1h1.1c.3 0 .4 0 .5.1.1.1.2.3.3.5l2.1 9 2.3-9c.1-.3.2-.4.3-.5.1-.1.3-.1.5-.1h1.2c.2 0 .3.1.3.3v.2c0 .1 0 .2-.1.3l-3.2 10.3c-.1.3-.2.4-.3.5-.1.1-.3.1-.5.1h-1.1c-.3 0-.4 0-.5-.1-.1-.1-.2-.3-.3-.5L20.5 11l-2 8.6c-.1.3-.1.4-.3.5-.1.1-.3.1-.5.1h-1.1zm17.1.3c-.7 0-1.4-.1-2-.2-.7-.2-1.2-.3-1.5-.5-.2-.1-.4-.3-.4-.4-.1-.1-.1-.3-.1-.4v-.7c0-.3.1-.4.3-.4h.2s.2.1.3.1c.5.2.9.4 1.5.5.5.1 1.1.2 1.6.2.8 0 1.5-.1 1.9-.4s.7-.7.7-1.3c0-.4-.1-.7-.4-.9-.2-.3-.7-.5-1.3-.7l-1.9-.6c-1-.3-1.7-.8-2.1-1.4-.4-.6-.7-1.2-.7-1.9 0-.6.1-1.1.4-1.5.2-.4.6-.8 1-1.1.4-.3.9-.5 1.4-.7s.9-.2 1.5-.2c.3 0 .6 0 .9.1.3 0 .6.1.9.1.3.1.5.1.8.2.2.1.4.2.6.2.2.1.3.2.4.3.1.1.1.3.1.4v.6c0 .3-.1.4-.3.4-.1 0-.3-.1-.5-.2-.8-.3-1.6-.5-2.6-.5-.8 0-1.4.1-1.8.4s-.6.6-.6 1.2c0 .4.1.7.4.9.3.3.8.5 1.5.7l1.9.6c1 .3 1.6.7 2.1 1.3.4.5.6 1.2.6 1.9 0 .6-.1 1.1-.3 1.5-.2.5-.6.9-1 1.2-.4.3-.9.6-1.5.7-.7.5-1.4.5-2 .5z"
      style={{
        fill: '#252f3e',
      }}
    />
    <path
      className="AWS_SSO_logo_svg__st1"
      d="M36.2 27c-4.4 3.2-10.8 5-16.2 5-7.7 0-14.6-2.8-19.8-7.5-.4-.4 0-.9.5-.6 5.6 3.3 12.6 5.3 19.8 5.3 4.9 0 10.2-1 15.1-3.1.7-.4 1.3.4.6.9z"
    />
    <path
      className="AWS_SSO_logo_svg__st1"
      d="M38 24.9c-.6-.7-3.7-.3-5.1-.2-.4.1-.5-.3-.1-.6 2.5-1.8 6.6-1.3 7.1-.7.5.6-.1 4.7-2.5 6.7-.4.3-.7.1-.5-.3.5-1.2 1.6-4.2 1.1-4.9z"
    />
  </svg>
);

export default SvgAwsSsoLogo;
