import * as React from 'react';
import { SVGProps } from 'react';

const SvgGoogleHangoutsLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Google-Hangouts_Logo_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.Google-Hangouts_Logo_svg__st2{fill:#eee}.Google-Hangouts_Logo_svg__st4{opacity:.2;fill:#263238;enable-background:new}'
      }
    </style>
    <path
      d="M18.6 8.6C18.6 3.9 14.8 0 10 0S1.4 3.9 1.4 8.6c0 4.5 4.1 8.2 8.6 8.2V20c5.2-2.7 8.6-6.9 8.6-11.4z"
      style={{
        fill: '#0f9d58',
      }}
    />
    <linearGradient
      id="Google-Hangouts_Logo_svg__SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={10.103}
      y1={-2319.712}
      x2={16.022}
      y2={-2313.793}
      gradientTransform="translate(0 2328)"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#263238',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#000',
          stopOpacity: 0,
        }}
      />
    </linearGradient>
    <path
      d="m14.3 6.4-2.5 2.5-2.5-2.5L5.7 10l7.8 7.8c2.6-2.1 4.4-4.6 5-7.3l-4.2-4.1z"
      style={{
        opacity: 0.2,
        fill: 'url(#Google-Hangouts_Logo_svg__SVGID_1_)',
      }}
    />
    <path
      className="Google-Hangouts_Logo_svg__st2"
      d="M10.7 6.4V10h2v2c.9 0 1.6-1.1 1.6-2.3V6.4h-3.6zM5.7 6.4V10h2v2c.9 0 1.6-1.1 1.6-2.3V6.4H5.7z"
    />
    <path
      d="M5.7 6.4h3.6v.1H5.7v-.1zm5 0h3.6v.1h-3.6v-.1z"
      style={{
        opacity: 0.4,
        fill: '#fff',
      }}
    />
    <path
      className="Google-Hangouts_Logo_svg__st4"
      d="M12.7 12v.1c.9 0 1.6-1.1 1.6-2.3 0 1.1-.7 2.2-1.6 2.2zm0-2h-2v.1h2V10zm-5 2 .1.1c.8-.1 1.5-1.2 1.5-2.3 0 1.1-.7 2.2-1.6 2.2zm0-2h-2v.1h2V10z"
    />
    <path
      d="M10 .1c4.8 0 8.6 3.9 8.6 8.6v-.1C18.6 3.9 14.8 0 10 0S1.4 3.9 1.4 8.6v.1C1.4 3.9 5.2.1 10 .1z"
      style={{
        opacity: 0.2,
        fill: '#fff',
      }}
    />
    <path
      className="Google-Hangouts_Logo_svg__st4"
      d="M10 16.8v-.1c-4.5 0-8.6-3.6-8.6-8.1v.1c0 4.5 4.1 8.1 8.6 8.1zm8.6-8.2c-.1 4.4-3.4 8.6-8.6 11.2v.2c5.2-2.7 8.6-6.9 8.6-11.4z"
    />
  </svg>
);

export default SvgGoogleHangoutsLogo;
