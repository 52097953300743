import React, { useEffect, useState } from 'react';
import './index.css';

interface IProps {
  usagePercentage: number;
}

const ProgressBar = ({ usagePercentage }: IProps) => {
  const maxUsage = Math.min(usagePercentage, 100);
  return (
    <div className="progress-bar">
      <div
        className={'progress-bar__content' + (maxUsage >= 100 ? ' progress-bar__content--red' : '')}
        style={{ width: maxUsage + '%' }}
      ></div>
    </div>
  );
};

export default ProgressBar;
