import React, { useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
} from '@chakra-ui/react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<any>;
  onCancel: () => void;
  confirmButtonText: string;
  cancelButtonText: string;
  title: string | JSX.Element;
  body: string | JSX.Element;
  disableBodyPadding?: boolean;
  isCentered?: boolean;
  enableOverlay?: boolean;
  modalOverlayBackgroundColor?: string;
  isLoading?: boolean;
  disableFooter?: boolean;
  showCloseButton?: boolean;
  returnFocusOnClose?: boolean;
}

export const CustomAlertDialog = ({
  isOpen,
  onConfirm,
  onCancel,
  onClose,
  title,
  body,
  cancelButtonText,
  confirmButtonText,
  isCentered,
  enableOverlay,
  modalOverlayBackgroundColor,
  isLoading,
  disableFooter,
  showCloseButton,
  returnFocusOnClose,
}: Props) => {
  const cancelRef = useRef();

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef.current}
        onClose={onClose}
        isCentered={isCentered}
        returnFocusOnClose={returnFocusOnClose}
      >
        <AlertDialogOverlay
          bg={modalOverlayBackgroundColor ? modalOverlayBackgroundColor : 'blackAlpha.200'}
          backdropFilter={enableOverlay ? 'blur(2px) saturate(80%)' : ''}
        >
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>
            {showCloseButton && <AlertDialogCloseButton />}

            <AlertDialogBody>{body}</AlertDialogBody>

            {!disableFooter && (
              <AlertDialogFooter>
                <Button ref={cancelRef.current} variant="invertedDefault" onClick={onCancel}>
                  {cancelButtonText}
                </Button>
                <Button
                  variant="default"
                  isLoading={isLoading}
                  onClick={() => {
                    onConfirm().then(onClose);
                  }}
                  ml={3}
                >
                  {confirmButtonText}
                </Button>
              </AlertDialogFooter>
            )}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
