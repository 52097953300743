import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const TooltipTheme: ComponentStyleConfig = {
  baseStyle: () => {
    return {
      fontFamily: 'Mulish',
      fontWeight: '400',
      bg: 'secondary.100',
      color: '#09305A',
      '--tooltip-bg': '#F1F3F6',
    };
  },
  sizes: {
    // for 1440 screen size
    md: {
      padding: '6px',
      fontSize: '12.5px',
      '--popper-arrow-size': '14.5px',
    },
    // for 1920 screen size
    lg: {
      padding: '8px',
      fontSize: '17px',
      '--popper-arrow-size': '19px',
    },
  },
  defaultProps: {
    size: 'md',
    variant: null,
  },
};
