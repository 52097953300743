import { Box, Text } from '@chakra-ui/layout';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { FormButton } from 'library/atoms';
import { CustomDrawerComponent, Modal } from 'library/molecules';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import { useHistory, useParams } from 'react-router';
import { generatePath } from 'react-router-dom';
import { WORKFLOWS_DETAILS_PATH, WORKFLOWS_PATH } from 'views/main/routes/routes';

import { ACTION_TITLE } from '../constant';
import { workflowFormValidation } from '../constant/validation';
import { useWorkflowFormContext } from '../context/workflowFormContext';
import { WorkflowForm } from '../types';
import ActionWrapper from './actions/ActionWrapper';
import Actions from './actions/list/Actions';
import BasicForm from './basic/BasicForm';
import { FormFooter } from './cta/form-button';
import FilterContainer from './filter/FilterContainer';

const WorkflowFormContainer = () => {
  const {
    workflowForm,
    onFormSubmit,
    isFormSubmitting,
    checkDirtyAndCloseDrawer,
    drawerInfo,
    showGuardModal,
    closeGuardModal,
    discardChanges,
    isCreateFlow,
  } = useWorkflowFormContext();

  const params = useParams<{ id: string }>();
  const workflowDeletePermission = useUserAccess().hasDeleteAccess('workflows', params.id);
  const workflowEditPermission = useUserAccess().hasUpdateAccess('workflows', params.id);
  const workflowCreatePermission = useUserAccess().hasCreateAccess('workflows');
  const handleCancelClick = () => {
    if (!isCreateFlow) {
      history.push(generatePath(WORKFLOWS_DETAILS_PATH, { id: params?.id }));
    } else {
      history.push(generatePath(WORKFLOWS_PATH));
    }
  };

  const history = useHistory();
  if (!workflowDeletePermission && !workflowCreatePermission && !workflowEditPermission) {
    history.go(-1);
    return null;
  }

  return (
    <>
      <FormWrapper<WorkflowForm>
        onSubmit={onFormSubmit}
        defaultValues={workflowForm}
        validationSchema={workflowFormValidation}
      >
        <BasicForm />
        <Box mt={6} mb={2}>
          <Text color="secondary.900" fontSize="16px" fontWeight="600">
            Set Workflow
          </Text>
        </Box>
        <FilterContainer />

        <Box mt={6}>
          <Actions />
        </Box>

        <FormFooter
          isFormSubmitting={isFormSubmitting}
          handleCancelClick={handleCancelClick}
          isCreateFlow={isCreateFlow}
        />

        {drawerInfo.drawerType ? (
          <CustomDrawerComponent
            isOpen={drawerInfo.isOpen}
            disableBodyPadding
            title={ACTION_TITLE[drawerInfo.drawerType]}
            onClose={checkDirtyAndCloseDrawer}
            showBackIcon={Boolean(drawerInfo.prevDrawerType)}
          >
            <ActionWrapper type={drawerInfo.drawerType} />
          </CustomDrawerComponent>
        ) : null}
      </FormWrapper>

      <Modal
        key="route-guard"
        closeOnOverlayClick={true}
        isOpen={showGuardModal}
        onClose={closeGuardModal}
        title="Discard Changes?"
        primaryButton={
          <FormButton onClick={closeGuardModal} variant="primary" title="Keep Editing" />
        }
        secondaryButton={
          <FormButton onClick={discardChanges} variant="secondary" title="Discard Changes" />
        }
        body={
          <Text variant="h4">
            You haven’t saved any changes. Are you sure you want to discard the changes?
          </Text>
        }
      />
    </>
  );
};

export default WorkflowFormContainer;
