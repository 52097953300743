import Axios from 'axios';
import { API } from 'core';

export const deleteGerRulesetRule = (
  gerId: string,
  alertVersion: string,
  alertName: string,
  ruleId: string,
) => {
  return Axios.delete(
    `${API.config.batman}/organizations/${API.config.organizationId}/global-event-rules/${gerId}/rulesets/${alertVersion}/${alertName}/rules/${ruleId}`,
  );
};
