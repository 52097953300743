import { ITeam, ITeamMember } from '../../core/interfaces/ITeams';
import { IUsersOfOrganization } from '../../core/interfaces/IUserData';

const getAssignableTeamMembers = (team: ITeam, users: IUsersOfOrganization[]) => {
  const memberUsers = ((team?.members ?? []) as ITeamMember[]).map(m => m.user_id);
  return (users || []).filter(u => u.role !== 'stakeholder' && memberUsers.includes(u.id));
};

const getAssignableStakeHolders = (team: ITeam, users: IUsersOfOrganization[]) => {
  const memberUsers = ((team?.members ?? []) as ITeamMember[]).map(m => m.user_id);
  return (users || []).filter(u => u.role === 'stakeholder' && memberUsers.includes(u.id));
};

export { getAssignableTeamMembers, getAssignableStakeHolders };
