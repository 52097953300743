import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UpdateStatusPageComponentMutation } from 'views/main/organization/statuspage-v2/graphql/mutation';
import {
  GetStatusPageDetailsQuery,
  useGetStatusPageComponentTimelineQuery,
} from 'views/main/organization/statuspage-v2/graphql/query';
import {
  ComponentDayLog,
  StatusPageComponentTimeline,
} from 'views/main/organization/statuspage-v2/graphql/types';
import { useUpdateComponent } from 'views/main/organization/statuspage-v2/hooks/components';
import { IComponentsForm, TimelineOverride } from 'views/main/organization/statuspage-v2/Interface';

export interface IStatuspageComponentTimelineContext {
  component: IComponentsForm;
  statusCodes: GetStatusPageDetailsQuery['getStatusPageDetails']['statusCodes'];
  timeline: StatusPageComponentTimeline | undefined;
  renderTimeline?: boolean;
  activeDate: string | null;
  setActiveDate: React.Dispatch<React.SetStateAction<string | null>>;
  overrideOpen: boolean;
  setOverrideOpen: React.Dispatch<React.SetStateAction<boolean>>;
  componentDayLogMap: Record<string, ComponentDayLog>;
  prevDate: [boolean, () => void];
  nextDate: [boolean, () => void];
  override: (newOverride: TimelineOverride) => Promise<UpdateStatusPageComponentMutation | null>;
  overrideUnsaved: boolean;
  setOverrideUnsaved: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedModalOpen: boolean;
  setUnsavedModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedModalCallback: () => void;
  setUnsavedModalCallback: React.Dispatch<React.SetStateAction<() => void>>;
  timelineIsLoading: boolean;
  statusPageId: number;
}

export const StatuspageComponentTimelineContext = createContext<
  IStatuspageComponentTimelineContext | undefined
>(undefined);

interface IStatuspageComponentTimelineProps {
  children: ReactNode;
  component: IComponentsForm;
  statusCodes: GetStatusPageDetailsQuery['getStatusPageDetails']['statusCodes'];
  renderTimeline?: boolean;
}

export const StatuspageComponentTimelineProvider: React.FC<IStatuspageComponentTimelineProps> = ({
  children,
  component,
  statusCodes,
  renderTimeline,
}) => {
  const timeline = useGetStatusPageComponentTimelineQuery({
    id: component.id,
  });
  const [componentDayLogMap, setComponentDayLogMap] = useState<Record<string, ComponentDayLog>>({});
  const [activeDate, setActiveDate] = useState<string | null>(null);
  const [overrideOpen, setOverrideOpen] = useState<boolean>(false);
  const [overrideUnsaved, setOverrideUnsaved] = useState<boolean>(false);
  const [unsavedModalOpen, setUnsavedModalOpen] = useState<boolean>(false);
  const [unsavedModalCallback, setUnsavedModalCallback] = useState<() => void>(() => null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [prevDate, setPrevDate] = useState<[boolean, () => void]>([false, () => null]);
  const [nextDate, setNextDate] = useState<[boolean, () => void]>([false, () => null]);
  const { mutateAsync: updateComponent } = useUpdateComponent(() => {
    timeline.refetch();
  });
  const { id } = useParams<{ id: string }>();
  const statusPageId = parseInt(id);

  const override = async (
    newOverride: TimelineOverride,
  ): Promise<UpdateStatusPageComponentMutation | null> => {
    const newTimelineOverrides: TimelineOverride[] = component.timelineOverrides
      ? component.timelineOverrides
      : [];
    newTimelineOverrides.push(newOverride);
    try {
      return await updateComponent({
        input: {
          id: component.id,
          name: component.name,
          timelineOverrides: newTimelineOverrides,
        },
      });
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setOverrideUnsaved(false);
      setUnsavedModalCallback(() => () => null);
    }
  };

  useEffect(() => {
    if (timeline.data?.getStatusPageComponentTimeline) {
      const newMap: Record<string, ComponentDayLog> = {};

      let newStartDate: Date | null = null;
      let newEndDate: Date | null = null;
      timeline.data?.getStatusPageComponentTimeline.data?.forEach(item => {
        if (item?.date) {
          const isoDate = new Date(item.date).toISOString();
          newMap[isoDate] = item;
        }
        if (item) {
          if (!newStartDate && item.overallStatus?.slug !== 'not-created') {
            newStartDate = new Date(item.date);
          }
          if (newStartDate && item.overallStatus?.slug !== 'not-created') {
            newEndDate = new Date(item.date);
          }
        }
      });

      setStartDate(newStartDate);
      setEndDate(newEndDate);
      setComponentDayLogMap(newMap);
    }
  }, [timeline.data?.getStatusPageComponentTimeline]);

  useEffect(() => {
    if (activeDate) {
      const _prevDate = new Date(activeDate);
      _prevDate.setDate(_prevDate.getDate() - 1);
      const _nextDate = new Date(activeDate);
      _nextDate.setDate(_nextDate.getDate() + 1);
      const prevDateString = _prevDate.toISOString();
      const nextDateString = _nextDate.toISOString();

      if (componentDayLogMap[prevDateString]) {
        const prevDayLog = componentDayLogMap[prevDateString];
        if (
          prevDayLog.overallStatus?.slug === 'not-created' ||
          (startDate ? _prevDate < startDate : false)
        ) {
          setPrevDate(() => [false, () => null]);
        } else {
          setPrevDate(() => [true, () => setActiveDate(prevDateString)]);
        }
      } else {
        setPrevDate(() => [false, () => null]);
      }

      if (componentDayLogMap[nextDateString]) {
        const nextDayLog = componentDayLogMap[nextDateString];
        if (
          nextDayLog.overallStatus?.slug === 'not-created' ||
          (endDate ? _nextDate > endDate : false)
        ) {
          setNextDate(() => [false, () => null]);
        } else {
          setNextDate(() => [true, () => setActiveDate(nextDateString)]);
        }
      } else {
        setNextDate(() => [false, () => null]);
      }
    }
  }, [activeDate]);

  const value: IStatuspageComponentTimelineContext = {
    component,
    statusCodes: statusCodes,
    timeline: timeline.data?.getStatusPageComponentTimeline,
    timelineIsLoading: timeline.isLoading,
    renderTimeline,
    activeDate,
    setActiveDate,
    overrideOpen,
    setOverrideOpen,
    componentDayLogMap,
    prevDate,
    nextDate,
    override,
    overrideUnsaved,
    setOverrideUnsaved,
    unsavedModalOpen,
    setUnsavedModalOpen,
    unsavedModalCallback,
    setUnsavedModalCallback,
    statusPageId,
  };

  return (
    <StatuspageComponentTimelineContext.Provider value={value}>
      {children}
    </StatuspageComponentTimelineContext.Provider>
  );
};
