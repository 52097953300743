import { Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

type Props = {
  title: ReactNode;
  actions?: ReactNode;
  paddingX?: number;
  paddingY?: number;
  size?: string;
  wrap?: boolean;
  bgColor?: string;
};

function Header({ title, actions, paddingX, paddingY, wrap, bgColor }: Props) {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      w="100%"
      px={paddingX ?? 6}
      py={paddingY ?? 6}
      bgColor={bgColor}
      wrap={wrap ? { sm: 'wrap', md: 'wrap', xl: 'nowrap' } : 'nowrap'}
      rowGap={5}
    >
      {title}
      {actions}
    </Flex>
  );
}

export default Header;
