import { useFormik } from 'formik';
import {
  UpdateStatusPageIssueStateTemplateMutation,
  UpdateStatusPageIssueStateTemplateMutationVariables,
  useUpdateStatusPageIssueStateTemplateMutation,
} from '../graphql/mutation';
import { UseMutationOptions } from 'react-query';

import { PAGE_TYPES } from '../constants/schema';
import { getPageInputDetails } from '../helpers/helper.service';
import { reactQueryConfigError } from '../helpers/helper.toast';
import { IMessageTemplate } from '../Interface';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_EDITED } from 'core/const/tracker';

type onSubmit = () => void;

const { schema } = getPageInputDetails(PAGE_TYPES.MESSAGE_TEMPLATE);

const useUpdateTemplate = <TError = unknown, TContext = unknown>(
  onSuccess: () => void,
  options?: UseMutationOptions<
    UpdateStatusPageIssueStateTemplateMutation,
    TError,
    UpdateStatusPageIssueStateTemplateMutationVariables,
    TContext
  >,
) => {
  return useUpdateStatusPageIssueStateTemplateMutation({
    ...options,
    onSuccess: data => {
      onSuccess();
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_EDITED, {
        'Edit Type': 'Message Template Updated',
      });
    },
    onError: reactQueryConfigError('Update message template'),
  });
};

export const useMessageTemplate = (
  pageId: number,
  template: IMessageTemplate[],
  onSubmitHandler: onSubmit,
) => {
  const { mutateAsync: updateMessageTemplate, isLoading: isUpdateTemplateProgress } =
    useUpdateTemplate(onSubmitHandler);

  const onFormSubmit = (values: any) => {
    const temp = values.template.map((m: IMessageTemplate) => {
      return {
        id: m.id,
        defaultMessage: m.defaultMessage,
      };
    });
    updateMessageTemplate({
      input: {
        pageID: pageId,
        data: temp,
      },
    });
  };
  template.sort((a, b) => a.stepIndex - b.stepIndex);
  const formik = useFormik({
    initialValues: {
      template,
    },
    validationSchema: schema,
    onSubmit: onFormSubmit,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnMount: false,
  });
  return {
    formik,
    isUpdateTemplateProgress,
  };
};
