import { Flex } from '@chakra-ui/react';
import { Placeholder } from 'library/molecules';
import { FC } from 'react';

type Props = {
  description: string;
};

export const ServicePlaceholder: FC<Props> = ({ description }) => {
  return (
    <Flex flexDir="column" mt={16}>
      <Placeholder size="md" iconName="no-services.svg" description={description} />
    </Flex>
  );
};
