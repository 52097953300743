import { Box } from '@chakra-ui/layout';
import React from 'react';
import { THEME_COLORS } from 'library/theme/colors';

const GroupUI = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      width="full"
      my={4}
      backgroundColor="whiteAlpha.800"
      p={4}
      gap={4}
      sx={{ border: `1px solid ${THEME_COLORS.primary[400]} ` }}
    >
      {children}
    </Box>
  );
};

export default GroupUI;
