import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

interface Props {
  body: JSX.Element;
  closeOnOverlayClick?: boolean;
  isOpen: boolean;
  onClose: () => void;
  primaryButton?: JSX.Element;
  secondaryButton?: JSX.Element;
  title: string;
}

export function Modal({
  body,
  closeOnOverlayClick,
  isOpen,
  onClose,
  primaryButton,
  secondaryButton,
  title,
}: Props): JSX.Element {
  return (
    <ChakraModal
      closeOnOverlayClick={closeOnOverlayClick}
      isCentered
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <ModalOverlay sx={{ background: '#09305A', opacity: '0.5 !important' }} />
      <ModalContent>
        <ModalHeader p={3} pl={6} pr={7}>
          <Text variant="h4_800">{title}</Text>
        </ModalHeader>
        <ModalCloseButton onClick={() => onClose()} />
        <ModalBody px={6} py={2}>
          {body}
        </ModalBody>
        <ModalFooter px={6} py={4} gap={3}>
          {secondaryButton}
          {primaryButton}
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
}
