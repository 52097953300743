import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { REQUEST_ORG_SQUADS, REQUEST_ORG_SQUADS_FAIL } from '../../const/organization';
import { getOrgSquads } from '../../api/organization/getSquads';
import { onRequestOrganizationSquadSuccess } from '../../actions/organization/squads';
import { ISquad } from '../../interfaces/ISquad';

const getOrganizationsSquadsEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ORG_SQUADS),
    map(action => action.payload),
    switchMap(() => {
      return getOrgSquads().pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ result, data }: { result: boolean; data: ISquad[] }) => {
          return onRequestOrganizationSquadSuccess(data);
        }),
        catchError((error: AjaxError) => {
          if (error.status === 403) {
            return of(onRequestOrganizationSquadSuccess([]));
          }
          return of({
            type: REQUEST_ORG_SQUADS_FAIL,
            payload: error.xhr,
            error: true,
          });
        }),
      );
    }),
  );

export default getOrganizationsSquadsEpic;
