import React, { Component, ErrorInfo } from 'react';
import { Box, Text } from '@chakra-ui/layout';
import { OverridesCard } from './overrides-card';
import { RotationsCard } from './rotation-card';
import { OnCallCoverage } from './types';
import { captureException } from '@sentry/react';

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error caught by ErrorBoundary: ', error, errorInfo);
    captureException(error, {
      user: window._store.getState().userInfo,
      extra: {
        errorInfo,
      },
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box p={2} maxHeight={'512px'} overflowY="auto">
          <Text>Something went wrong.</Text>
        </Box>
      );
    }

    return this.props.children;
  }
}

function EventsList({
  onCallCoverage,
  timeZone,
}: {
  onCallCoverage: OnCallCoverage;
  timeZone: string;
}) {
  if (!onCallCoverage) {
    return null;
  }
  const { rotations, overrides } = onCallCoverage;

  return (
    <ErrorBoundary>
      <Box p={2} maxHeight={'512px'} overflowY="auto">
        <RotationsCard rotations={rotations} timeZone={timeZone} />
        <OverridesCard overrides={overrides} timeZone={timeZone} />
      </Box>
    </ErrorBoundary>
  );
}

export default EventsList;
