import { Box, Text } from '@chakra-ui/react';
import { Locale } from 'core/helpers/dateUtils';
import { FC } from 'react';

type Props = {
  timestamp: string;
  renderVertically?: boolean;
  pl?: number;
};

export const Timestamp: FC<Props> = ({ timestamp, pl, renderVertically }) => {
  const date = Locale.toShortDate(timestamp);
  const time = Locale.to24HrTimeWithSec(timestamp);

  return (
    <Box pl={pl ?? 6} alignItems="flex-start">
      {renderVertically ? (
        <>
          <Text variant="body" color="secondary.1000">
            {date}
          </Text>
          <Text variant="body" color="secondary.1000">
            {time}
          </Text>
        </>
      ) : (
        <Text variant="body" color="secondary.1000">
          {date}, {time}
        </Text>
      )}
    </Box>
  );
};
