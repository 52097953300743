import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import { FocusBlock, Grid, Heading, Label, Para, Theme } from 'uie/components';
import { IRole } from 'core/interfaces/IRole';
import { IUserAbilitySlugMap } from 'core/interfaces/IUserData';
import './Sidebar.css';

const { theme } = Theme;

export type ISidebarItem = {
  slug: string;
  name: string;
  type?: 'divider';
  /**
   * Regex needed for slug match
   */
  match?: RegExp;
  isChild?: boolean;
  tag?: string;
  requiresAbility?: 'none' | keyof IUserAbilitySlugMap;
  requiresUserRole?: 'inherit' | IRole['slug'];
  requiresActiveOrg?: boolean;
};

type IProps = {
  baseUrl: string;
  title: string;
  items: ISidebarItem[];
};

const Sidebar = (props: IProps) => {
  return (
    <div id="org_settings__sidebar">
      <Grid type="column" className="org_settings__sidebar_container">
        <Heading
          fontSize={20}
          height={32}
          fontWeight={400}
          color={theme.shades.black}
          style={{ marginTop: 2 }}
        >
          {props.title}
        </Heading>
        {props.items.length ? (
          <Grid flexWidth={12} type="column" className="mt-20">
            <ul className="sidebar__list">
              {props.items.map(item => {
                return (
                  <li key={item.slug} className={'mb-5'}>
                    {item.type === 'divider' ? (
                      <Grid style={{ padding: '2px 8px' }}>
                        <Para
                          fontSize={14}
                          fontWeight={400}
                          color={theme.shades.cement}
                          style={{ opacity: item.match?.test(location.href) ? 1 : 0.7 }}
                        >
                          {item.name}
                        </Para>
                      </Grid>
                    ) : (
                      <NavLink to={`${props.baseUrl}/${item.slug}`} className="no_underline">
                        <FocusBlock
                          value={item.slug}
                          padding="8px"
                          className={cx('org_settings__focus_tab', {
                            org_settings__in_focus:
                              location.pathname.includes(item.slug) ||
                              item.match?.test(location.href),
                          })}
                        >
                          <Grid justifyContent="center">
                            <Para
                              fontSize={item.isChild ? 12 : 14}
                              fontWeight={400}
                              color={theme.shades.cement}
                            >
                              {item.name}
                            </Para>
                            {item.tag && (
                              <Label role="label" style={{ lineHeight: '18px', marginLeft: 5 }}>
                                {item.tag}
                              </Label>
                            )}
                          </Grid>
                        </FocusBlock>
                      </NavLink>
                    )}
                  </li>
                );
              })}
            </ul>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default Sidebar;
