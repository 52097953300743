import { HStack } from '@chakra-ui/react';
import {
  T_WA_INCIDENT_SUMMARY_THUMBS_DOWN_FEEDBACK_GIVEN,
  T_WA_INCIDENT_SUMMARY_THUMBS_UP_FEEDBACK_GIVEN,
} from 'core/const/tracker';
import { ThumbsDownIcon, ThumbsUpIcon } from 'icons';
import { AppTracker } from 'shared/analytics/tracker';
import { TextButton } from 'uie/components';

import { useIncidentSummaryContext } from '../../../context/hook';

const IncidentSummaryDrawerSuccessFooter: React.FC = () => {
  const { incidentId, setSummaryDrawerStateFeedback, sendPositiveFeedback, feedbackSubmitting } =
    useIncidentSummaryContext();

  return (
    <HStack spacing={3}>
      <TextButton
        buttonType="inverted"
        onClick={() => {
          sendPositiveFeedback();
          AppTracker.track(T_WA_INCIDENT_SUMMARY_THUMBS_UP_FEEDBACK_GIVEN, {
            'Incident ID': incidentId,
          });
        }}
        disabled={feedbackSubmitting}
        height="36px"
        width="36px"
      >
        <ThumbsUpIcon width={15} height={15} />
      </TextButton>
      <TextButton
        buttonType="inverted"
        onClick={() => {
          setSummaryDrawerStateFeedback();
          AppTracker.track(T_WA_INCIDENT_SUMMARY_THUMBS_DOWN_FEEDBACK_GIVEN, {
            'Incident ID': incidentId,
          });
        }}
        disabled={feedbackSubmitting}
        height="36px"
        width="36px"
      >
        <ThumbsDownIcon width={15} height={15} />
      </TextButton>
    </HStack>
  );
};

export default IncidentSummaryDrawerSuccessFooter;
