import * as React from 'react';
import { SVGProps } from 'react';

const SvgOktaLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#00297a',
      }}
      d="M10 0C4.473 0 0 4.473 0 10s4.473 10 10 10 10-4.473 10-10S15.527 0 10 0zm0 15.01c-2.763 0-5.01-2.247-5.01-5.01S7.237 4.99 10 4.99s5.01 2.247 5.01 5.01-2.247 5.01-5.01 5.01z"
    />
  </svg>
);

export default SvgOktaLogo20X20;
