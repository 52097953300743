import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const TagStackTheme: ComponentStyleConfig = {
  baseStyle: {
    container: { flexDir: 'row', alignItems: 'center', wrap: 'wrap' },
    overflowContainer: { columnGap: '0.125rem', alignItems: 'flex-start' },
    popoverButton: {
      minW: 'auto',
      width: '1.125rem',
      height: '1.125rem',
      borderRadius: '50%',
      p: 0,
      pb: '0.375rem',
      bg: 'primary.400',
    },
    popoverText: {
      color: 'secondary.1000',
    },
    popoverContent: { w: 'auto' },
    popoverBody: { p: 0 },
    popoverData: {
      p: '0.25rem',
      spacing: '0.5rem',
      alignItems: 'flex-start',
      overflowY: 'auto',
      maxH: '25rem',
    },
  },
};
