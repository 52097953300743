import { Avatar, AvatarGroup, AvatarGroupProps } from '@chakra-ui/react';
import React from 'react';
import { OverrideParticipantWrapper } from './overrideWrapper';

type Props = {
  avatars: { name: string }[];
  size?: AvatarGroupProps['size'];
  avatarBGColor?: string;
  maxCount?: number;
  avatarSpacing?: string;
  wrapper?: 'override';
};

function ScheduleAvatarGroup({
  avatars,
  size,
  avatarBGColor,
  maxCount,
  avatarSpacing,
  wrapper,
}: Props) {
  const avatarWrapper = (children: JSX.Element) => {
    switch (wrapper) {
      case 'override': {
        return <OverrideParticipantWrapper>{children}</OverrideParticipantWrapper>;
      }
      default: {
        return <>{children}</>;
      }
    }
  };

  const ScheduleAvatar = (props: { key?: number; name: string }) => {
    return avatarWrapper(
      <Avatar
        size={size ?? 'xs'}
        name={props.name}
        key={props.key}
        bgColor={avatarBGColor ?? ''}
      />,
    );
  };

  return (
    <AvatarGroup size={size ?? 'xs'} max={maxCount ?? 2} spacing={avatarSpacing ?? '2'} dir="left">
      {avatars?.map((member, index) =>
        Array.isArray(member) ? (
          member.map(m => <ScheduleAvatar key={index} name={m.name} />)
        ) : member ? (
          <ScheduleAvatar name={member.name} />
        ) : null,
      )}
    </AvatarGroup>
  );
}

export default ScheduleAvatarGroup;
