import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { HttpCallAction } from 'views/main/organization/workflow/types';

import HttpCallHeader from './HttpCallHeader';
import HttpCallPayload from './HttpCallPayload';

interface IProps {
  action: HttpCallAction;
}
const HttpCallCard: FC<IProps> = ({ action }) => {
  return (
    <>
      <Box py={4}>
        <Text color="secondary.700" textStyle="bold_800" variant="h3">
          URL{' '}
        </Text>
        <Text>
          {action.data.method} - {action.data.url}
        </Text>
      </Box>
      <HttpCallHeader headers={action.data.headers} />
      <HttpCallPayload payload={action.data.body} />
    </>
  );
};

export default HttpCallCard;
