const parseObjectWithSearch = (
  obj: Record<string, unknown>,
  search: string,
): { data: Record<string, unknown>; keys: string[] | null } => {
  try {
    const parsedSearch = search.split(/\.|\[(.*?)\]/gm).filter(v => v !== undefined);
    let isLastKnow = '';

    const resolvedObject = parsedSearch.reduce((c: any, n: string, i: number, a: string[]) => {
      if (n === undefined || n === '') return c;
      let retVal = c;

      if (c) {
        if (c[n]) {
          retVal = c[n];
        } else {
          isLastKnow = a.splice(-1, 1)[0];
          retVal = c[a.splice(-1, 1) as any] || c;
        }
      }

      return retVal;
    }, obj || {});

    let retObj = search === '' ? obj : resolvedObject;

    if (search !== '' && isLastKnow !== '' && typeof retObj !== 'string') {
      retObj = Object.entries(retObj).reduce((c: any, [key, value]) => {
        if (
          key.toLowerCase().includes(isLastKnow.toLowerCase()) ||
          isLastKnow.toLowerCase().includes(key.toLowerCase())
        ) {
          c[key] = value;
          return c;
        }
        return c;
      }, {});
    }

    return {
      keys: typeof retObj === 'string' ? null : Object.keys(retObj),
      data: retObj,
    };
  } catch (err) {
    return {
      keys: Object.keys(obj),
      data: obj,
    };
  }
};

export default parseObjectWithSearch;
