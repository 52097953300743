import { FocusBlock, Para } from 'uie/components';
import { memo } from 'react';
import { useQueryClient } from 'react-query';

interface LogoutProps {
  isRefresh: boolean;
  onLogout: () => void;
}
export const Logout = memo((props: LogoutProps) => {
  const { isRefresh, onLogout } = props;
  const queryClient = useQueryClient();

  const onLogoutClick = () => {
    queryClient.removeQueries();
    queryClient.clear();
    onLogout();
  };

  return (
    <FocusBlock
      value={2}
      onSelectValue={onLogoutClick}
      className="top_bar__profile_link"
      disabled={isRefresh}
    >
      <Para fontSize={14}>Logout</Para>
    </FocusBlock>
  );
});
