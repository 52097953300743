import { ChakraProvider } from '@chakra-ui/react';
import { Col, Row, SpinLoader } from '@squadcast/alchemy-ui/carbon';
import axios from 'axios';
import { CustomDrawerComponent } from 'components/chakra/Drawer';
import { fontTheme } from 'library/theme';
import { Component } from 'react';
import { API } from '../../../../../../core/api';
import { exception } from '../../../../../../core/exception';
import { CircleCIService } from '../../../../../../core/services';
import './index.css';
import { THEME_COLORS } from 'library/theme/colors';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IState {
  loading: boolean;
  validationShow: boolean;
  integrationError: boolean;
  saved: string;
  responseError: string;
  circleciPath: string;
}

class CircleciIntegrationModal extends Component<IProps, IState> {
  private _circleCIService = new CircleCIService();
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      integrationError: false,
      validationShow: false,
      saved: '',
      circleciPath: '',
      responseError: '',
    };
    this.save = this.save.bind(this);
    this.getToken = this.getToken.bind(this);
  }

  public getToken() {
    axios
      .get(
        API.config.endpoint +
          `/organizations/${API.config.organizationId}/extensions/name/circleci`,
      )
      .then(response => {
        if (response.data.result) {
          this.setState({
            saved: response.data.token,
            loading: false,
          });
        }
      })
      .catch(error => {
        this.setState({
          saved: '',
          loading: false,
        });
      });
  }
  public async save() {
    if (this.state.circleciPath.trim() === '') {
      this.setState({
        integrationError: true,
      });
      return;
    }

    try {
      await this._circleCIService.saveToken(this.state.circleciPath);
      this.props.onClose();
    } catch (error: any) {
      if (error.response.status >= 400 && error.response.status < 500) {
        this.setState({
          responseError: error.response.data.meta.error_message,
        });
      } else {
        this.setState({
          responseError: 'Something went wrong',
        });
        exception.handle('E_CIRCLECI_SAVE_TOKEN', error);
      }
    }
  }
  public componentDidMount() {
    this.getToken();
  }

  public render() {
    return (
      <ChakraProvider theme={fontTheme}>
        <CustomDrawerComponent
          isOpen={this.props.isOpen}
          onClose={this.props.onClose}
          title={<h1>CircleCI Extension</h1>}
          isFooter={!this.state.loading}
          footerButtons={
            this.state.saved?.length > 0
              ? [
                  {
                    text: 'Close',
                    type: 'button',
                    variant: 'outline',
                    onClick: this.props.onClose,
                  },
                ]
              : [
                  {
                    text: 'Save',
                    type: 'button',
                    colorScheme: 'blue',
                    onClick: this.save,
                  },
                ]
          }
        >
          <div
            onClick={event => {
              event.stopPropagation();
            }}
          >
            {this.state.loading ? (
              <div style={{ height: 150, position: 'relative' }}>
                <Col flexHeight={12} justifyContent="center">
                  <Row justifyContent="center">
                    <SpinLoader size="normal" />
                  </Row>
                </Col>
              </div>
            ) : (
              <div style={{ position: 'relative' }}>
                {this.state.saved ? (
                  <div>
                    <h2
                      style={{
                        color: THEME_COLORS.secondary[900],
                        fontSize: 18,
                        fontWeight: 'bold',
                        marginBottom: 8,
                      }}
                    >
                      Integration Key
                    </h2>
                    <p className="input-design">{this.state.saved}</p>
                    <p style={{ marginTop: 10, color: THEME_COLORS.secondary[1000] }}>
                      Integrate CircleCI with Squadcast. Learn more{' '}
                      <a
                        href="https://support.squadcast.com/integrations/extensions/circleci"
                        style={{
                          color: THEME_COLORS.brand.blue,
                          textDecoration: 'underline',
                          lineHeight: '1.5',
                        }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        here
                      </a>
                    </p>
                  </div>
                ) : (
                  <div>
                    <h2
                      style={{
                        color: THEME_COLORS.secondary[900],
                        fontSize: 18,
                        fontWeight: 'bold',
                        marginBottom: 8,
                      }}
                    >
                      Integration Key
                    </h2>
                    <input
                      onFocus={() => {
                        this.setState({
                          integrationError: false,
                        });
                      }}
                      onChange={event => {
                        this.setState({ circleciPath: event.target.value });
                      }}
                      className={
                        'input-design ' +
                        (this.state.integrationError || this.state.responseError
                          ? 'text-field-border'
                          : '')
                      }
                      placeholder="Enter your CircleCI Integration key"
                    />
                    <p className={this.state.responseError ? 'error-block' : 'no-display'}>
                      {' '}
                      {this.state.responseError}
                    </p>
                    <div className="error-block">
                      {this.state.integrationError
                        ? 'Please enter the CircleCI Integration key'
                        : ''}
                    </div>
                    <p style={{ marginTop: 10, color: THEME_COLORS.secondary[1000] }}>
                      Integrate CircleCI with Squadcast. Learn more{' '}
                      <a
                        href="https://support.squadcast.com/integrations/extensions/circleci"
                        style={{
                          color: THEME_COLORS.brand.blue,
                          textDecoration: 'underline',
                          lineHeight: '1.5',
                        }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        here
                      </a>
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </CustomDrawerComponent>
      </ChakraProvider>
    );
  }
}

export default CircleciIntegrationModal;
