import React from 'react';
import {
  ErrorBlock,
  Grid,
  SpinLoader,
  FocusBlock,
  SelectBox,
  Heading,
  JSONCodeBlock,
} from 'uie/components';
import shortid from 'shortid';
import { IAlertSource } from 'core/interfaces/IIntegration';
import { Text, HStack } from '@chakra-ui/react';
import StackLimit, {
  IconType,
} from 'views/main/organization/service-catalog/components/StackLimit';

interface Props {
  alertSourceSearch: string;
  alertSource: IAlertSource | null;
  alertSources: IAlertSource[];
  alertSourcesLoadState: 'loading' | 'success' | 'error';
  alertSourcesConfiguredWithDedupeKeys: string[] | undefined;
  alertSourceErrorMessage: string;
  isCreateMode: boolean;
  event: any;
  eventState: 'loading' | 'noEvents' | 'idle' | 'default' | 'notConfigured';
  onTextChange: (type: 'alertSourceSearch') => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectChange: (_: any, alertSource: IAlertSource) => void;
}

const AlertSourceSelection = ({
  alertSourceSearch,
  alertSource,
  alertSources,
  alertSourcesLoadState,
  alertSourcesConfiguredWithDedupeKeys,
  alertSourceErrorMessage,
  isCreateMode,
  event,
  eventState,
  onTextChange,
  onSelectChange,
}: Props) => {
  const alertSourceFilterFunction = (altSrc: IAlertSource) => {
    if (alertSourcesConfiguredWithDedupeKeys && alertSourceSearch) {
      /** If there are any alert sources already configured with dedup key and an alert source is seached,
          return only those which are not a part of the already configured list */
      return (
        altSrc.type.toLowerCase().includes(alertSourceSearch.toLocaleLowerCase()) &&
        !alertSourcesConfiguredWithDedupeKeys.includes(altSrc.shortName)
      );
    } else if (alertSourcesConfiguredWithDedupeKeys) {
      /** If there are any alert sources already configured with dedup key,
          return only those which are not a part of the already configured list */
      return !alertSourcesConfiguredWithDedupeKeys.includes(altSrc.shortName);
    } else if (alertSourceSearch) {
      /** If there are no alert sources already configured with dedup key,
          return those which contain a part of the search phrase */
      return altSrc.type.toLowerCase().includes(alertSourceSearch.toLocaleLowerCase());
    } else {
      /** If none of the above conditions are met, return all alert sources */
      return true;
    }
  };

  const isAlertSourceNotConfigured = eventState === 'notConfigured';

  return (
    <Grid flexWidth={5} style={{ marginLeft: 40 }}>
      {alertSourcesLoadState === 'loading' && (
        <Grid flexWidth={12} justifyContent="center">
          <div style={{ margin: '140px' }}>
            <Grid justifyContent="center" alignItems="center">
              <SpinLoader />
              <small className="ml-10">Loading Alert Sources</small>
            </Grid>
          </div>
        </Grid>
      )}

      {alertSourcesLoadState === 'error' && (
        <Grid flexWidth={12} justifyContent="center">
          <div style={{ margin: '159px' }}>
            <Grid justifyContent="center" alignItems="center">
              <ErrorBlock fontWeight={500} fontSize={14}>
                {alertSourceErrorMessage}
              </ErrorBlock>
            </Grid>
          </div>
        </Grid>
      )}

      {alertSourcesLoadState === 'success' && (
        <div className="w-1-1">
          <label className="item-box-tagline font-bold mb-10 block">Alert Source</label>
          {alertSource && !isCreateMode ? (
            <HStack
              w="100%"
              height="auto"
              gap={1}
              alignItems="unset !important"
              border="1px solid"
              borderRadius="3px"
              px="9px"
              py="6px"
            >
              <StackLimit
                limit={1}
                type="icon"
                data={[
                  {
                    icon: alertSource.shortName as IconType,
                  },
                ]}
              />
              <Text className="item-box-tagline" style={{ margin: 0 }}>
                {alertSource.type}
              </Text>
            </HStack>
          ) : (
            <SelectBox
              id="select-alert-source"
              hook={
                alertSource ? (
                  <HStack gap={1} alignItems="unset !important">
                    <StackLimit
                      limit={1}
                      type="icon"
                      data={[
                        {
                          icon: alertSource.shortName as IconType,
                        },
                      ]}
                    />
                    <Text className="item-box-tagline" style={{ margin: 0 }}>
                      {alertSource.type}
                    </Text>
                  </HStack>
                ) : (
                  <Text fontSize={14} fontWeight={500}>
                    Please select an alert source
                  </Text>
                )
              }
              searchHookProps={{
                value: alertSourceSearch,
                height: '24px',
                fontSize: '16px',
                onChange: onTextChange('alertSourceSearch'),
              }}
              onValueChange={onSelectChange}
              height="auto"
              maxHeight="200px"
              width="100%"
              maxWidth="100%"
            >
              {alertSources
                .filter(alertSourceFilterFunction)
                .map((altSrc: IAlertSource, index: number) => (
                  <FocusBlock
                    height={'100%'}
                    value={altSrc}
                    key={shortid.generate()}
                    isSelected={alertSource === altSrc}
                    style={{ fontSize: '12px' }}
                  >
                    <HStack gap={1} alignItems="unset !important">
                      <StackLimit
                        limit={1}
                        type="icon"
                        data={[
                          {
                            icon: altSrc.shortName as IconType,
                          },
                        ]}
                      />
                      <Text className="item-box-tagline" style={{ margin: 0 }}>
                        {altSrc.type}
                      </Text>
                    </HStack>
                  </FocusBlock>
                ))}
            </SelectBox>
          )}
          <Heading
            fontSize={10.5}
            style={{
              color: '#627C98',
              fontWeight: 400,
              lineHeight: 'normal',
            }}
          >
            You can configure only one dedup key per alert source
          </Heading>
          {eventState === 'loading' && (
            <Grid flexWidth={12} justifyContent="center">
              <div style={{ margin: '120px' }}>
                <Grid justifyContent="center" alignItems="center">
                  <SpinLoader />
                  <small className="ml-10">Loading Event Payload</small>
                </Grid>
              </div>
            </Grid>
          )}
          {eventState === 'noEvents' && (
            <Grid flexWidth={12} justifyContent="center">
              <div className="mt-10">
                <Grid justifyContent="center" alignItems="center">
                  <Text className="ml-10">{`No events for ${alertSource?.type}`}</Text>
                </Grid>
              </div>
            </Grid>
          )}
          {isAlertSourceNotConfigured && isCreateMode && (
            <Grid flexWidth={12} justifyContent="center">
              <div>
                <Grid justifyContent="center" alignItems="center">
                  <ErrorBlock>
                    Selected alert source is not configured for this service. For easier set up,
                    integrate the alert source first.
                  </ErrorBlock>
                </Grid>
              </div>
            </Grid>
          )}
          {eventState === 'idle' && Object.keys(event.past).length > 0 && (
            <div className="w-1-1 mt-10" style={{ position: 'sticky', top: 20 }}>
              <JSONCodeBlock
                code={event.past}
                enableSearch={true}
                shellProps={{
                  minHeight: '600px',
                  minWidth: '100%',
                }}
              />
            </div>
          )}
        </div>
      )}
    </Grid>
  );
};

export default AlertSourceSelection;
