import * as React from 'react';
import { SVGProps } from 'react';

const SvgHoneyBadger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="honey-badger_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.honey-badger_svg__st0{fill:#ea5937}'}</style>
    <path
      className="honey-badger_svg__st0"
      d="m6.3 14.2-3-3c-.4-.4-.4-1 0-1.4l6-6.1c.4-.4 1-.4 1.4 0l.4.4.9-.9-.9-.9c-.6-.6-1.6-.6-2.2 0l-7 7.1c-.6.6-.6 1.6 0 2.2l4 4 .4-1.4zm11.9-4.8L14 5.3l-.5 1.3 3.2 3.2c.4.4.4 1 0 1.4l-6 6.1c-.4.4-1 .4-1.4 0l-.7-.7-.9.9 1.2 1.2c.6.6 1.6.6 2.2 0l7-7.1c.7-.6.7-1.6.1-2.2z"
    />
    <path
      className="honey-badger_svg__st0"
      d="M15.5 0 12 9.6c0 .1 0 .3.1.4.1.1.2.2.3.2l1.8-.2-9.3 10 2.7-8.6c0-.1 0-.2-.1-.4-.1-.1-.2-.2-.3-.2l-1.9.2L15.5 0z"
    />
  </svg>
);

export default SvgHoneyBadger;
