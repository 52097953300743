import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import Tippy from '@tippy.js/react';
import SelectTimeZone from 'components/EventDateRangePicker/timezone';
import UpgradeOnlyModal from 'components/upgradeonly.tooltip';
import { BillingService, deepCopy } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import { CrownIcon } from 'icons';
import { DelayedNotificationsGrayIcon } from 'library/icons';
import { THEME_COLORS } from 'library/theme/colors';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { IServiceOwner } from '../../../hooks/useServiceOwner';
import {
  DelayConfigAssigneeType,
  DelayNotificationConfig,
} from '../../../types/service-detail.types';
import { OwnerSelect } from '../common/owner-select';
import { SlotPicker } from '../slot-picker';
import { DEFAULT_SELECTED_TIME, WORKING_DAYS_OPTIONS } from './constant';
import { DisableConfirmation } from './disableConfirmation';
import { FooterNote, InfoCard } from './info';

type Props = {
  isEnabled: boolean;
  onToggle: (isEnabled: boolean) => void;
  delayConfig: DelayNotificationConfig;
  onDelayConfigUpdate: (config: DelayNotificationConfig) => void;
  serviceOwners: IServiceOwner[];
  isEditMode?: boolean;
  errorMsg?: string;
};

const DELAY_NOTIF_PLAN = 'delayed-notification';

export const DelayedNotificationsConfigPanel: FC<Props> = ({
  isEnabled,
  onToggle: onToggleProp,
  delayConfig,
  onDelayConfigUpdate,
  serviceOwners,
  isEditMode,
  errorMsg,
}) => {
  const [config, setConfig] = useState<DelayNotificationConfig>(delayConfig);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showDisableWarningModal, setShowDisableWarningModal] = useState<boolean>(false);
  const [selectedDays, setSelectedDays] = useState<Array<number>>([]);
  const [assigneeRadioType, setAssigneeRadioType] = useState<string>('');

  const updateConfig = (newConfig: DelayNotificationConfig) => {
    setConfig(newConfig);
    onDelayConfigUpdate(newConfig);
  };

  const getConfigClone = () => {
    return deepCopy(config);
  };

  const updateSelectedDays = (day: number) => {
    let days: number[] = [];
    let isDayAdded = false;

    if (day >= 0 && day <= 6) {
      if (selectedDays.includes(day)) {
        days = selectedDays.filter(val => val !== day);
      } else {
        isDayAdded = true;
        days = [...selectedDays, day];
      }
    }
    setSelectedDays(days);
    const newConfig = getConfigClone();
    if (newConfig.custom_timeslots_enabled) {
      if (!newConfig.custom_timeslots) {
        newConfig.custom_timeslots = {};
      }
      if (isDayAdded) {
        newConfig.custom_timeslots[day] = [
          {
            start_time: DEFAULT_SELECTED_TIME,
            end_time: DEFAULT_SELECTED_TIME,
          },
        ];
      } else if (days.length) {
        // day unselected
        delete newConfig.custom_timeslots[day];
      } else {
        // reset case
        newConfig.custom_timeslots = {};
      }
    } else {
      if (!newConfig.fixed_timeslot_config) {
        newConfig.fixed_timeslot_config = getDefaultFixedTimeslotConfig();
      }
      newConfig.fixed_timeslot_config.repeat_days = days;
    }
    updateConfig(newConfig);
  };

  useEffect(() => {
    // basically needs this for edit case where we could have predefined config data for days
    let preselectedDays = config.fixed_timeslot_config?.repeat_days ?? [];
    if (config.custom_timeslots_enabled) {
      const record = config.custom_timeslots ?? {};
      preselectedDays = Object.keys(record).map(val => +val);
    }

    setSelectedDays(preselectedDays);

    if (config.assigned_to?.type) {
      let radioType = 'other';
      if (config.assigned_to.type === 'default_escalation_policy') {
        radioType = config.assigned_to.type;
      }
      setAssigneeRadioType(radioType);
    }
  }, []);

  const CustomTimeslotsConfig = () => {
    const dayOptions = WORKING_DAYS_OPTIONS.filter(opt => selectedDays.includes(opt.value));
    const timeSlot = config.custom_timeslots ?? {};

    return (
      <VStack rowGap={1} mb={'18px'} alignItems="flex-start">
        {dayOptions.map((opt, index) => (
          <Grid templateColumns="1fr 2fr" gap={2} key={opt.value} alignItems="end">
            <Text color={THEME_COLORS.secondary[700]}>{opt.descLabel}</Text>
            <SlotPicker
              startTime={timeSlot[opt.value]?.[0].start_time}
              endTime={timeSlot[opt.value]?.[0].end_time}
              onStartTimeUpdate={time => onSlotSelect(time, true, opt.value)}
              onEndTimeUpdate={time => onSlotSelect(time, false, opt.value)}
              renderlabel={index === 0}
            />
          </Grid>
        ))}
      </VStack>
    );
  };

  const onToggle = () => {
    if (isEnabled && isEditMode) {
      setShowDisableWarningModal(true);
      return;
    }

    setShowDetails(!isEnabled);
    onToggleProp(!isEnabled);
  };

  const onCustomTimeslotsSwitchChange = () => {
    const newConfig = getConfigClone();
    const isCustomTSEnabled = !config.custom_timeslots_enabled;
    newConfig.custom_timeslots_enabled = isCustomTSEnabled;
    if (!isCustomTSEnabled) {
      newConfig.fixed_timeslot_config = getDefaultFixedTimeslotConfig();
      if (config.fixed_timeslot_config?.start_time) {
        newConfig.fixed_timeslot_config.start_time = config.fixed_timeslot_config.start_time;
      }
      if (config.fixed_timeslot_config?.end_time) {
        newConfig.fixed_timeslot_config.end_time = config.fixed_timeslot_config.end_time;
      }
    }
    updateConfig(newConfig);
  };

  const handleTimezoneChange = (timezone: string) => {
    const newConfig = { ...config };
    newConfig.timezone = timezone;
    updateConfig(newConfig);
  };

  const onOwnerSelect = (id: string, assigneeType: string) => {
    const newConfig = { ...config };
    newConfig.assigned_to = { id, type: assigneeType as DelayConfigAssigneeType };
    updateConfig(newConfig);
  };

  const onAssigneeUpdate = (assigneeType: string) => {
    const newConfig = { ...config };
    newConfig.assigned_to = { type: assigneeType as DelayConfigAssigneeType };
    updateConfig(newConfig);
  };

  const getDefaultFixedTimeslotConfig = () => {
    return {
      start_time: DEFAULT_SELECTED_TIME,
      end_time: DEFAULT_SELECTED_TIME,
      repeat_days: [...selectedDays],
    };
  };

  const onSlotSelect = (timeStamp: string, isStartTime: boolean, customDay = -1) => {
    const newConfig = getConfigClone();
    let timeObj = { start_time: '', end_time: '' };
    if (customDay === -1) {
      if (!newConfig.fixed_timeslot_config) {
        newConfig.fixed_timeslot_config = getDefaultFixedTimeslotConfig();
      }
      timeObj = newConfig.fixed_timeslot_config;
    } else {
      if (!newConfig.custom_timeslots) {
        newConfig.custom_timeslots = {};
      }
      newConfig.custom_timeslots[customDay] = [
        {
          start_time:
            newConfig.custom_timeslots[customDay]?.[0]?.start_time || DEFAULT_SELECTED_TIME,
          end_time: newConfig.custom_timeslots[customDay]?.[0]?.end_time || DEFAULT_SELECTED_TIME,
        },
      ];
      timeObj = newConfig.custom_timeslots[customDay][0];
    }

    if (isStartTime) {
      timeObj.start_time = timeStamp;
    } else {
      timeObj.end_time = timeStamp;
    }
    updateConfig(newConfig);
  };

  const { delayedNotifications, organizationProps } = useSelector((state: IAppState) => ({
    delayedNotifications: state.organization.plan.p?.rules[DELAY_NOTIF_PLAN],
    organizationProps: { organization: state.organization },
  }));

  const { isDelayedNotificationsEnabled, planLimit, isPlanRenewable } = useMemo(() => {
    if (!delayedNotifications) {
      return {
        isDelayedNotificationsEnabled: false,
        planLimit: undefined,
        isPlanRenewable: false,
      };
    }

    return {
      isDelayedNotificationsEnabled: true,
      planLimit: undefined,
      isPlanRenewable: delayedNotifications.renewable,
    };
  }, [delayedNotifications]);

  return (
    <Tippy
      content={
        !isDelayedNotificationsEnabled ? (
          <UpgradeOnlyModal
            hasBillingPermission={BillingService.hasManageBillingPermission(organizationProps)}
            align="left"
            showModal={true}
            message={BillingService.getMessage(
              planLimit,
              DELAY_NOTIF_PLAN,
              organizationProps,
              isPlanRenewable,
            )}
            header={BillingService.getHeader(planLimit, DELAY_NOTIF_PLAN, organizationProps)}
          />
        ) : (
          ''
        )
      }
      interactive={true}
      animation={'scale'}
      placement="top-end"
    >
      <Box bg="#F3F9FF" px={3} py={3}>
        <Flex>
          <Box width="24px" height="24px">
            <DelayedNotificationsGrayIcon />
          </Box>

          <Box width={5} />

          <Box>
            <Flex>
              <Heading as="h3" fontSize="20px" color="gray.700" fontWeight="600">
                Delayed Notifications
              </Heading>

              <Box width={4} />

              <Switch
                marginTop={1}
                isChecked={isEnabled}
                onChange={onToggle}
                disabled={!isDelayedNotificationsEnabled}
              />

              {!isDelayedNotificationsEnabled && (
                <>
                  <Box width={4} />
                  <CrownIcon width={24} height={24} />
                </>
              )}

              {isEnabled && (
                <Text
                  color={THEME_COLORS.brand.blue}
                  cursor="pointer"
                  marginLeft="auto !important"
                  fontWeight="800"
                  fontSize="14px"
                  onClick={() => setShowDetails(!showDetails)}
                >
                  {showDetails ? 'Hide' : 'Show'} Details
                </Text>
              )}
            </Flex>

            <InfoCard />

            {isEnabled && showDetails && (
              <Box>
                <Text size="16px" color={THEME_COLORS.secondary[900]}>
                  Define Service working days and hours
                </Text>
                <FormLabel mt={'18px'} fontWeight={600}>
                  Timezone*
                </FormLabel>
                <SelectTimeZone
                  timezone={config.timezone}
                  handleChange={handleTimezoneChange}
                  hideDefaultTimezone={true}
                />
                <HStack mt={5}>
                  <FormLabel fontWeight={600}>Select Day*</FormLabel>
                  <ButtonGroup variant="outline" spacing="2">
                    {WORKING_DAYS_OPTIONS.map(option => (
                      <Button
                        borderRadius="50%"
                        height="32px"
                        width="32px"
                        key={option.value}
                        isActive={selectedDays.includes(option.value)}
                        onClick={() => updateSelectedDays(option.value)}
                        _active={{
                          bg: THEME_COLORS.primary[300],
                        }}
                        bg="white"
                      >
                        {option.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                  <Text
                    color={THEME_COLORS.brand.blue}
                    cursor="pointer"
                    fontSize="12px"
                    onClick={() => updateSelectedDays(-1)}
                  >
                    Reset
                  </Text>
                </HStack>
                <HStack mb={'18px'} mt={'9px'}>
                  <Text color="gray.500">Repeat start and end time for all selected days</Text>
                  <Switch
                    isChecked={!config.custom_timeslots_enabled}
                    onChange={onCustomTimeslotsSwitchChange}
                  />
                </HStack>
                {!!selectedDays.length && (
                  <Box mb={'18px'}>
                    {config.custom_timeslots_enabled ? (
                      <>{CustomTimeslotsConfig()}</>
                    ) : (
                      <SlotPicker
                        startTime={config.fixed_timeslot_config?.start_time}
                        endTime={config.fixed_timeslot_config?.end_time}
                        onStartTimeUpdate={time => onSlotSelect(time, true)}
                        onEndTimeUpdate={time => onSlotSelect(time, false)}
                        renderlabel={true}
                      />
                    )}
                  </Box>
                )}
                <FormLabel fontWeight={600}>
                  Assign / notify incoming incidents during non-working hours to*
                </FormLabel>
                <RadioGroup
                  value={assigneeRadioType}
                  onChange={value => {
                    setAssigneeRadioType(value);
                    onAssigneeUpdate(value);
                  }}
                  mr="10px"
                  mb={3}
                >
                  <Stack>
                    <Radio
                      mr="10px"
                      value="default_escalation_policy"
                      borderColor={THEME_COLORS.brand.blue}
                    >
                      Default Escalation Policy
                    </Radio>

                    <Radio borderColor={THEME_COLORS.brand.blue} value="other">
                      User or Squad or Escalation Policy
                    </Radio>
                    {assigneeRadioType === 'other' && (
                      <OwnerSelect
                        assigneeId={config.assigned_to?.id}
                        onSelect={onOwnerSelect}
                        serviceOwners={serviceOwners}
                      />
                    )}
                  </Stack>
                </RadioGroup>
                <FooterNote />
              </Box>
            )}
            {!!isEnabled && (
              <Box mt={'18px'}>
                <Text fontSize="14px" color={THEME_COLORS.form.error}>
                  {errorMsg}
                </Text>
              </Box>
            )}
            <DisableConfirmation
              showWarning={showDisableWarningModal}
              setShowWarning={setShowDisableWarningModal}
              onDisable={() => {
                onToggleProp(!isEnabled);
                setShowDisableWarningModal(false);
              }}
            />
          </Box>
        </Flex>
      </Box>
    </Tippy>
  );
};
