import { DialogModalFrame, Para, TextButton } from 'uie/components';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import React, { useEffect } from 'react';
import { MemberRowProps } from '../../types';
import ConflictModalContent from './Content';

type Props = {
  openModal: boolean;
  closeModal: () => void;
  memberName: string;
  members: MemberRowProps[];
  onConfirm: (selectedMember: IUsersOfOrganization) => void;
  attemptedAction?: 'remove' | 'updateRole';
};
export function MemberConflictModal({
  openModal,
  closeModal,
  memberName,
  members,
  onConfirm,
  attemptedAction,
}: Props) {
  return (
    <DialogModalFrame
      root="rsg-root"
      id="modalFrame_ConflictModal"
      onClose={() => closeModal()}
      padding="24px"
      width="500px"
    >
      {openModal && (
        <ConflictModalContent
          memberName={memberName}
          members={members}
          onCancel={() => closeModal()}
          onConfirm={(selectedMember: IUsersOfOrganization) => {
            onConfirm(selectedMember);
          }}
          attemptedAction={attemptedAction}
        />
      )}
    </DialogModalFrame>
  );
}
