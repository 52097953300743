import { useFlag } from '@unleash/proxy-client-react';
import React from 'react';

export function withUnleash<T>(InputComponent: React.ComponentType<T>) {
  return (props: T) => {
    const workflowEnabled = useFlag('workflow_enabled');
    return <InputComponent {...props} featureEnabled={{ workflowEnabled }} />;
  };
}

export default withUnleash;
