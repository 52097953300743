import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const TabTheme: ComponentStyleConfig = {
  baseStyle: props => {
    return {
      bg: '#FFFFFF',
      boxShadow: 'none',
      outline: 'none',
      borderRadius: 0,
    };
  },
  variants: {
    filled: props => {
      return {
        borderRadius: '0px',
        margin: '0 1.5px',

        '&[aria-selected="true"]': {
          backgroundColor: '#DCEEFF',
          color: '#1B7FF1',
          borderColor: '#A5CBFA',
          '&:hover': {
            color: '#1B7FF1',
          },
        },
        '&[aria-selected="false"]': {
          backgroundColor: '#FFFF',
          color: '#09305A',
          borderColor: '#F1F3F6',
          '&:hover': {
            borderColor: '#A5CBFA',
            color: '#1B7FF1',
          },
        },
        '&[disabled]': {
          opacity: 0.5,
          pointerEvents: 'none',
        },
        _focus: {
          boxShadow: 'none',
        },
      };
    },
    outline: props => {
      return {
        fontWeight: '800',
        '&[aria-selected]': {
          '&:focus': {
            outline: 'none',
            boxShadow: 'none',
          },
        },
        '&[disabled]': {
          pointerEvents: 'none',
          opacity: 0.5,
          cursor: 'not-allowed',
        },
        '&[aria-selected="true"]': {
          borderBottomColor: 'transparent',
          color: '#127DF6',
          borderTopColor: '#127DF6',
          borderLeftColor: '#127DF6',
          borderRightColor: '#127DF6',
          '&:hover': {
            color: '#09305A',
            borderColor: '#E1E6EB',
            borderBottomColor: 'transparent',
          },
        },
        '&[aria-selected="false"]': {
          borderBottomColor: '#E1E6EB',
          color: 'initial',
          borderTopColor: 'transparent',
          borderLeftColor: 'transparent',
          borderRightColor: 'transparent',
          '&:hover': {
            color: '#127DF6',
            borderColor: 'transparent',
            borderBottomColor: '#E1E6EB',
          },
        },
      };
    },
  },
  sizes: {
    // 1440px
    filled_md: {
      border: '0.75px solid #A5CBFA',
      padding: '4.5px 12px',
      fontSize: '10.5px',
    },
    filled_lg: {
      border: '1px solid #A5CBFA',
      padding: '6px 16px',
    },
    outline_md: {
      border: '0.75px solid transparent',
      borderRadius: '3px 3px 0 0',
      minHeight: '30px',
      fontSize: '12px',
      px: '18px',
      py: '6px',
    },
    outline_lg: {
      border: '1px solid transparent',
      borderRadius: '4px 4px 0 0',
      minHeight: '40px',
      fontSize: '16px',
      px: '24px',
      py: '8px',
    },
  },
  defaultProps: {
    variant: 'outline',
    size: 'outline_md',
  },
};
