import { Circle, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { Popover, Tag } from 'library/atoms';
import { showExpandedTags } from '../store/persistent-storage';

export type TagType = {
  key: string;
  value: string;
  color: string;
};

export type Props = {
  tags: TagType[];
  honorTagsPreference?: boolean;
};

const TagList = ({ tags, honorTagsPreference = false }: Props) => {
  const [first, second, ...rest] = tags;

  const listExpandedTags = honorTagsPreference && showExpandedTags();
  const showFullTags = !honorTagsPreference;

  const expandedTag = (tagItem: TagType) => (
    <Tag
      variant="keyValue"
      key={tagItem.key}
      keyStr={tagItem.key}
      value={tagItem.value}
      color={tagItem.color}
      showExpanded
    />
  );

  return (
    <Flex flexDir="row" alignItems="flex-start" gap={2} wrap="wrap">
      {listExpandedTags ? (
        <>{tags.map(expandedTag)}</>
      ) : (
        <>
          <Tag
            variant="keyValue"
            keyStr={first.key}
            value={first.value}
            color={first.color}
            showExpanded={showFullTags}
          />
          <HStack columnGap={1} alignItems="flex-start">
            {second && (
              <Tag
                variant="keyValue"
                keyStr={second.key}
                value={second.value}
                color={second.color}
                showExpanded={showFullTags}
              />
            )}
            {!!rest.length && (
              <Popover
                trigger={
                  <Circle cursor="pointer" size="19px" pb="6px" bg="primary.400">
                    <Text variant="hint" color="secondary.1000">
                      ...
                    </Text>
                  </Circle>
                }
                disablePortal
              >
                <Flex p={2} flexDir="column" overflowY="auto" maxH="40vh">
                  <VStack spacing={2} alignItems="flex-start">
                    {rest.map(expandedTag)}
                  </VStack>
                </Flex>
              </Popover>
            )}
          </HStack>
        </>
      )}
    </Flex>
  );
};

export default TagList;
