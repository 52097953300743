import { ComponentStyleConfig } from '@chakra-ui/theme';

export const EntityUITheme: ComponentStyleConfig = {
  baseStyle: {
    container: {
      padding: '0.75rem 1rem 0.5rem',
    },
    userButton: {
      width: 'fit-content',
      cursor: 'pointer',
      _hover: {
        textDecoration: 'none',
      },
    },
    userName: {
      maxW: '10.625rem',
    },
    popoverColor: {
      color: 'popover.entity',
    },
  },
};
