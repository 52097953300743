import axios from 'axios';
import { useFormik } from 'formik';
import { useMemo, useReducer } from 'react';
import { UseMutationOptions, useQuery } from 'react-query';
import { PAGE_TYPES } from '../constants/schema';

import { queryKeys, reactQueryConfig } from '../constants/status.constants';
import {
  UpdateStatusPageSubscriptionSettingsMutation,
  UpdateStatusPageSubscriptionSettingsMutationVariables,
  useUpdateStatusPageSubscriptionSettingsMutation,
} from '../graphql/mutation';
import {
  GetStatusPageSubscriptionSettingsQuery,
  useGetStatusPageSubscriptionSettingsQuery,
} from '../graphql/query';
import { getPageInputDetails } from '../helpers/helper.service';
import { reactQueryConfigError } from '../helpers/helper.toast';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_EDITED } from 'core/const/tracker';

const { initValues, schema } = getPageInputDetails(PAGE_TYPES.SUBSCRIBERS_SETTINGS);

const useUpdateSettings = <TError = unknown, TContext = unknown>(
  onSuccess: () => void,
  options?: UseMutationOptions<
    UpdateStatusPageSubscriptionSettingsMutation,
    TError,
    UpdateStatusPageSubscriptionSettingsMutationVariables,
    TContext
  >,
) => {
  return useUpdateStatusPageSubscriptionSettingsMutation({
    ...options,
    onSuccess: data => {
      onSuccess();
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_EDITED, {
        'Edit Type': 'Subscriber Settings Updated',
      });
    },
    onError: reactQueryConfigError('Update subscriber settings'),
  });
};

type onSubmit = () => void;
export function useSubscribersSettings(pageId: number, onSuccessHandler: onSubmit) {
  const { data, isSuccess } = useGetStatusPageSubscriptionSettingsQuery(
    { pageID: pageId },
    {
      ...reactQueryConfig,
      onError: reactQueryConfigError('Get subscriber settings'),
    },
  );

  const { mutateAsync: updateSettings, isLoading } = useUpdateSettings(() => {
    onSuccessHandler();
  });

  const onFormSubmit = (values: any) => {
    updateSettings({
      input: {
        pageID: pageId,
        allowComponentsSubscription: values.componentSubscriptionEnabled,
        allowMaintenanceSubscription: values.maintenanceSubscriptionEnabled,
        contactEmail: values.contactEmail,
        allowWebhookSubscription: values.allowWebhookSubscription,
      },
    });
  };

  const formikObject = useMemo(() => {
    if (isSuccess) {
      const res = (data as GetStatusPageSubscriptionSettingsQuery)
        ?.getStatusPageSubscriptionSettings;
      initValues.emailFrom = res?.sendOnlyEmailID;
      initValues.contactEmail = res?.contactEmail;
      initValues.allowWebhookSubscription = res?.allowWebhookSubscription;
      initValues.componentSubscriptionEnabled = res?.componentsSubscription.enabled;
      initValues.maintenanceSubscriptionEnabled = res?.maintenanceSubscription.enabled;
      initValues.componentSubscriptionCount = res?.componentsSubscription.count;
      initValues.maintenanceSubscriptionCount = res?.maintenanceSubscription.count;
      initValues.showComponentMessage = res?.componentsSubscription.enabled;
      initValues.showMaintenanceMessage = res?.maintenanceSubscription.enabled;
    }
    return {
      initialValues: initValues,
      validationSchema: schema,
      onSubmit: onFormSubmit,
      validateOnChange: false,
      validateOnBlur: true,
      validateOnMount: false,
    };
  }, [isSuccess]);

  const formik = useFormik(formikObject);

  return {
    formik,
    isSuccess: isSuccess,
    isUpdating: isLoading,
  };
}
