import { Box, Text } from '@chakra-ui/layout';
import React, { FC } from 'react';
import { SlackCreateAction } from 'views/main/organization/workflow/types';

interface IProps {
  action: SlackCreateAction;
}

const SlackCard: FC<IProps> = ({ action }) => {
  return (
    <React.Fragment>
      <Box mt={2}>
        <Text variant="h4" color="secondary.700" textStyle="bold_800">
          CHANNEL NAME{action.data.auto_name !== true && ' FORMAT'}
        </Text>
        <Text color="secondary.1000" variant="h4" fontWeight="400" textTransform="capitalize">
          {' '}
          {action.data.auto_name === true
            ? '(Set to Auto)'
            : `${action.data.channel_name} (Manual)`}
        </Text>
      </Box>
    </React.Fragment>
  );
};

export default SlackCard;
