import { extendTheme } from '@chakra-ui/react';
import { Theme } from 'uie/components';

//Responsive styles
import { breakpoints } from './styles/responsive-styles/breakpoints';

//Other styles overrides
import { colors } from './styles/colors';

//Component styles overrides
import { Button } from './components/button';
import { Input } from './components/input';
import { Tooltip } from './components/tooltip';
import { IconButton } from './components/iconbutton';
import { Select } from './components/select';
import { Heading } from './components/heading';
import { NumberInput } from './components/numberinput';
import { FormLabel } from './components/formlabel';
import { Text } from './components/text';
import { Link } from './components/link';

const { theme: squadcastTheme } = Theme;

const overrides = {
  colors: {
    ...squadcastTheme,
    ...colors.colors,
    default: squadcastTheme.primary.default,
  },
  bg: {
    default: squadcastTheme.primary.default,
  },
  fonts: {
    body: 'Mulish',
    heading: 'Mulish',
  },
  breakpoints,
  components: {
    Button,
    Tooltip,
    IconButton,
    Select,
    Heading,
    NumberInput,
    FormLabel,
    Input,
    Text,
    Link,
  },
};

export default extendTheme(overrides);
