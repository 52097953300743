import * as React from 'react';
import { SVGProps } from 'react';

const SvgSignalfx = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#5aa200',
      }}
      d="M0 0h20v20H0z"
    />
    <path
      style={{
        fill: '#fdfdfc',
      }}
      d="M14.3 11.034c0-.568.004-1.137-.003-1.706a.517.517 0 0 1 .13-.36c.298-.363.59-.732.877-1.104a.291.291 0 0 1 .262-.129c.477.007.954.003 1.43.003.02.079-.04.107-.073.146-.834 1.014-1.67 2.026-2.506 3.038-.034.041-.056.099-.12.11l.003.002z"
    />
    <path
      style={{
        fill: '#050807',
      }}
      d="M5.275 12.937a3.437 3.437 0 0 1-2.148-.745c-.133-.103-.184-.182-.043-.329.198-.208.378-.434.554-.663.088-.115.148-.12.265-.032.453.343.95.573 1.536.54.157-.008.308-.037.446-.111a.526.526 0 0 0 .07-.907c-.148-.11-.319-.177-.487-.25-.374-.159-.753-.308-1.124-.478-1.026-.469-1.44-1.546-.945-2.458.314-.579.828-.891 1.454-1.014.91-.18 1.725.043 2.448.623.122.098.153.173.036.303a8.136 8.136 0 0 0-.491.605c-.082.11-.144.116-.255.037-.439-.314-.918-.482-1.466-.365a.622.622 0 0 0-.39.23c-.19.256-.111.56.205.744.335.195.706.31 1.06.462.207.088.418.168.621.262 1.37.638 1.287 2.412.195 3.123-.47.306-.987.427-1.54.423zM11.53 10.393c-.65 0-1.24.01-1.829-.005-.21-.005-.256.063-.252.262.012.66 0 1.321.008 1.982.002.16-.05.206-.205.202a23.73 23.73 0 0 0-1.033-.001c-.127.002-.171-.042-.171-.17.003-1.983.003-3.966 0-5.948 0-.131.049-.168.173-.167 1.202.003 2.404.004 3.606-.002.147 0 .179.06.176.187-.005.275-.008.549.002.823.005.15-.051.189-.195.188-.71-.007-1.42.004-2.13-.008-.193-.003-.238.059-.23.24.013.343.009.688 0 1.032-.002.14.049.185.184.181.267-.007.534-.002.802-.001.076 0 .158-.012.213.06.283.366.564.733.88 1.145z"
    />
    <path
      style={{
        fill: '#050807',
      }}
      d="M14.297 11.031c.001.08.063.123.104.178.523.705 1.05 1.408 1.573 2.113.048.065.117.12.13.24-.417 0-.83-.02-1.238.006-.287.019-.462-.075-.618-.315-.231-.358-.504-.69-.774-1.054-.302.407-.596.79-.875 1.185-.096.137-.202.185-.366.181-.461-.01-.923-.003-1.43-.003.077-.102.128-.173.183-.242.532-.672 1.06-1.346 1.601-2.011.102-.125.09-.201 0-.32-.398-.519-.786-1.045-1.177-1.568-.046-.061-.09-.124-.131-.183.045-.072.107-.05.159-.05.428-.002.857.003 1.285-.003a.305.305 0 0 1 .28.141c.374.51.753 1.017 1.132 1.523.05.066.08.15.164.185l-.002-.003z"
    />
  </svg>
);

export default SvgSignalfx;
