import Axios from 'axios';
import { API } from 'core';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys, reactQueryConfig } from '../constant';
import { ICreateConfigForAccountProps } from '../../../../../../../core/interfaces/IJiraNew';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/service.reactqery';

const createConfigForAccount = ({ account_id, ...data }: ICreateConfigForAccountProps) => {
  return Axios.post(
    `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud/new/${account_id}/jira-cloud-configs/`,
    {
      ...data,
    },
  );
};

const useCreateConfigForAccount = () => {
  const queryClient = useQueryClient();

  return useMutation([queryKeys.CREATEJIRACLOUDSACCOUNTSCONFIG], createConfigForAccount, {
    ...reactQueryConfig,
    onSuccess: () => {
      queryClient.refetchQueries([queryKeys.GETALLJIRACLOUDACCOUNTSCONFIG]);
      reactQueryConfigSuccess('Jira Cloud Account config created successfully!');
    },
    onError: (error: any) => {
      const description =
        error?.response?.data?.meta?.error_message || 'Error While Creating Config';
      reactQueryConfigError({ description: description, title: 'Create Config' });
    },
  });
};
export { useCreateConfigForAccount };
