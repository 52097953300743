import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { IGetSlo } from '../slo.interface';
import axios from 'axios';
import { API } from '../../../../../core/api';
import { createStandaloneToast } from '@chakra-ui/react';
import { queryKeys } from '../slo.constants';
import { EntityACLMeta } from 'core/userAccess/types';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';

const toast = createStandaloneToast();

const transformRequestOptions = (params: { [key: string]: any }) => {
  let options = '';
  for (const key in params) {
    if (
      typeof params[key] !== 'object' &&
      params[key] != undefined &&
      params[key] != null &&
      params[key] !== ''
    ) {
      options += `${key}=${params[key]}&`;
    } else if (typeof params[key] === 'object' && params[key] && params[key].length) {
      params[key].forEach((el: string) => {
        options += `${key}=${el}&`;
      });
    }
  }
  return options ? options.slice(0, -1) : options;
};

const getSLOList = async (
  offset: number,
  limit: number,
  sloListFilters: { [key: string]: string | boolean | string[] },
  teamId: string,
) => {
  const { data } = await axios.get<{ data: IGetSlo; meta: { acl: Record<string, EntityACLMeta> } }>(
    `${API.config.batman}/organizations/${
      API.config.organizationId
    }/slo?offset=${offset}&limit=${limit}&owner_id=${teamId}${
      sloListFilters['owner'] ? `&entity_owner=${sloListFilters['owner']}` : ''
    }`,
    {
      params: sloListFilters,
      paramsSerializer: function (params) {
        return transformRequestOptions(params);
      },
    },
  );
  setUserAccessStore({
    entityACLMap: {
      ...getUserAccessStore().entityACLMap,
      slos: {
        ...getUserAccessStore().entityACLMap?.slos,
        ...data.meta.acl,
      },
    },
  });
  return data.data;
};

export function useSloList(teamId: string = API.config.teamId, hasRead: boolean) {
  const [pageOffset, setOffset] = useState<number>(1);
  const [pageLimit, setLimit] = useState<number>(10);
  const [pagination, setPagination] = useState<Array<any>>([]);
  const [sloListFilters, setSloListFilters] = useState<{
    [key: string]: string | boolean | string[];
  }>({});
  const [totalNoOfPages, setTotalPages] = useState<number>(0);
  const [selectedTeamId, setTeamId] = useState<string>(teamId);

  const setPaginationFn = (total: number) => {
    const noOfPages = total / pageLimit;
    const leftPages = total % pageLimit;
    const totalNoOfPages = leftPages ? Math.floor(noOfPages) + 1 : noOfPages;
    const pagination =
      totalNoOfPages > 6
        ? [pageOffset, pageOffset + 1, pageOffset + 2, pageOffset + 3, pageOffset + 4]
        : Array.from({ length: totalNoOfPages }, (_, i) => i + 1);
    setPagination(pagination);
    setTotalPages(totalNoOfPages);
  };

  const { data, isError, isLoading, error, isSuccess } = useQuery(
    [queryKeys.SLOLIST, pageOffset, pageLimit, sloListFilters, selectedTeamId],
    () => getSLOList(pageOffset, pageLimit, sloListFilters, selectedTeamId),
    {
      enabled: hasRead,
      retry: 1,
      keepPreviousData: true,
      refetchOnMount: true,
      onError: (error: any) => {
        const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
        toast.closeAll();
        toast({ title, status: 'error', variant: 'subtle', isClosable: true });
      },
      onSuccess: data => {
        setPaginationFn(data.meta.total);
      },
    },
  );
  return {
    data,
    isLoading,
    isSuccess,
    pageOffset,
    pageLimit,
    setLimit,
    setOffset,
    pagination,
    setSloListFilters,
    isError,
    setPaginationFn,
    totalNoOfPages,
    setTeamId,
    sloListFilters,
  };
}
