import { Button, Flex, HStack, Text } from '@chakra-ui/react';
import DataTable from '../../../components/Table';
import { Cell } from 'react-table';
import { DialogModalFrame } from 'uie/components';
import CustomTemplate from './customTemplates.modal';
import React, { useMemo, useState } from 'react';
import UnsavedChangesGuard from 'components/unsavedChangesGuard';
import { useParams, useHistory } from 'react-router-dom';
import {
  ICustomTemplate,
  useDeleteCustomTemplateMutation,
  useGetOneCustomTemplateQuery,
  useServiceCustomTemplateQuery,
} from '../../../hooks/useServiceDetail';
import StackLimit from '../../../components/StackLimit';
import { IconType } from '../../../components/StackLimit';
import { MenuActions, MenuAction, MenuActionDetail } from '../service.rule/columns';
import { AlertDialogComponent } from '../../../components/Alert';
import qs from 'query-string';
import useQueryParams from '../../../helpers/helper.query-params';
import { truncateString } from 'views/main/organization/statuspage-v2/helpers/helper.details';
import { OrganizationContext } from '../../..';
import { useServiceAlertSourcesQuery } from '../../../hooks/useExpandedView';
import { BillingService } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import { useSelector } from 'react-redux';
import { useUserAccess } from 'core/userAccess/UserAccessContext';

const CustomTemplates = ({ orgDetail }: { orgDetail: Pick<IAppState, 'organization'> }) => {
  const history = useHistory();
  const query = useQueryParams();
  const organization = React.useContext(OrganizationContext);
  const activeTeamId = organization?.selectedTeam.teamId ?? '';

  const { serviceId } = useParams<{ serviceId: string }>();
  const currentUserRole = useMemo(() => {
    const result: any = {};

    for (const key in organization?.acl.a?.services) {
      if (key === serviceId) {
        result[key] = organization?.acl.a?.services[key];

        break;
      }
    }
    return result[serviceId] ?? {};
  }, [organization?.acl.a?.services, serviceId]);
  const viewPermission = currentUserRole['read-services'] ?? true;
  const createPermission = currentUserRole['create-services'] ?? true;
  const deletePermission = currentUserRole['delete-services'] ?? true;
  const updatePermission = currentUserRole['update-services'] ?? true;
  const showCTA = createPermission && updatePermission;

  const OBACEnabled = useSelector(
    (state: IAppState) => state.organization.currentOrg?.o?.config.obac_enabled,
  );

  const hasUpdateAccess = useUserAccess().hasUpdateAccess;

  const hasUpdate = hasUpdateAccess('services', serviceId);

  const isCCTDisabled = BillingService.isFeatureDisabled(orgDetail, 'custom-content-templates');

  const [isDirty, setIsDirty] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const modeParamKey = 'mode';
  const alertSourceParamKey = 'alert-source';
  const hasCustomTemplateParamKey = 'hasCustomTemplate';

  const mode = (query.get(modeParamKey) ?? '') as 'view' | 'edit' | 'create' | undefined;
  const alertSource = query.get(alertSourceParamKey ?? '') as string;
  const hasCustomTemplate = query.get(hasCustomTemplateParamKey);

  const { data: customTemplates, refetch } = useServiceCustomTemplateQuery(
    serviceId,
    isCCTDisabled,
    viewPermission,
  );
  const { data: customDetail, isFetching } = useGetOneCustomTemplateQuery(
    serviceId,
    alertSource as string,
    hasCustomTemplate as string,
  );
  const { refetch: refetchAlertSources } = useServiceAlertSourcesQuery(serviceId, activeTeamId);
  const { mutateAsync: deleteTemplate, isLoading } = useDeleteCustomTemplateMutation();
  const data = useMemo(() => customTemplates ?? [], [customTemplates]);

  const hideModal = () => {
    setIsDirty(false);
    history.push({
      pathname: history.location.pathname,
      search: qs.exclude(history.location.search, [
        alertSourceParamKey,
        modeParamKey,
        hasCustomTemplateParamKey,
      ]),
    });
  };
  const checkDirtyAndCloseModal = () => {
    if (isDirty) {
      setHasUnsavedChanges(true);
    } else {
      hideModal();
    }
  };
  const checkAndSetDirty = (isModalFormDirty?: boolean) => {
    setIsDirty(isModalFormDirty ?? true);
  };
  const editTemplate = () => {
    history.push({
      pathname: history.location.pathname,
      search: qs.stringifyUrl({
        url: history.location.search,
        query: {
          [alertSourceParamKey]: alertSource,
          [hasCustomTemplateParamKey]: 'false',
          [modeParamKey]: 'edit',
        },
      }),
    });
  };
  const columnDefs = [
    {
      id: 'header',
      Header: 'Message Template',
      Cell: (cell: Cell<ICustomTemplate>) => {
        const {
          row: {
            original: {
              overlay: { message },
            },
          },
        } = cell;
        return <Text>{message ? truncateString(message, 80) : '-'}</Text>;
      },
    },
    {
      id: 'desc',
      Header: 'Description Template',
      Cell: (cell: Cell<ICustomTemplate>) => {
        const {
          row: {
            original: {
              overlay: { description },
            },
          },
        } = cell;
        return <Text>{description ? truncateString(description, 150) : '-'}</Text>;
      },
    },
    {
      id: 'alert_source',
      Header: 'Alert Source',
      Cell: (cell: Cell<ICustomTemplate>) => {
        const {
          row: {
            original: { alert_source_shortname },
          },
        } = cell;
        return (
          <HStack columnGap={0.25}>
            <StackLimit
              limit={2}
              type="icon"
              data={[{ icon: alert_source_shortname as IconType, name: alert_source_shortname }]}
            />
            <Text>{alert_source_shortname}</Text>
          </HStack>
        );
      },
    },
    {
      id: 'actions',
      Cell: (cell: Cell<ICustomTemplate>) => {
        const {
          row: {
            original: { alert_source_shortname },
          },
        } = cell;

        const actions: MenuActionDetail[] = [];

        if (viewPermission) {
          actions.push({
            action: MenuAction.VIEW,
            label: `View Template`,
            handler: () =>
              history.push({
                pathname: history.location.pathname,
                search: qs.stringifyUrl({
                  url: history.location.search,
                  query: {
                    [alertSourceParamKey]: alert_source_shortname,
                    [hasCustomTemplateParamKey]: 'true',
                    [modeParamKey]: 'view',
                  },
                }),
              }),
          });
        }

        if (deletePermission) {
          actions.push({
            action: MenuAction.DELETE,
            label: `Delete Template`,
            handler: () => {
              history.push({
                pathname: history.location.pathname,
                search: qs.stringifyUrl({
                  url: history.location.search,
                  query: {
                    [alertSourceParamKey]: alert_source_shortname,
                  },
                }),
              });
              setOpenDeleteModal(true);
            },
          });
        }

        if (updatePermission) {
          actions.push({
            action: MenuAction.EDIT,
            label: `Edit Template`,
            handler: () =>
              history.push({
                pathname: history.location.pathname,
                search: qs.stringifyUrl({
                  url: history.location.search,
                  query: {
                    [alertSourceParamKey]: alert_source_shortname,
                    [hasCustomTemplateParamKey]: 'true',
                    [modeParamKey]: 'edit',
                  },
                }),
              }),
          });
        }

        return <MenuActions {...{ actions }} />;
      },
    },
  ];
  const filteredColumns = showCTA ? columnDefs : columnDefs.filter(col => col.id !== 'actions');
  const activeAlertSourceNames = useMemo(
    () => data.map(alertSourceName => alertSourceName.alert_source_shortname),
    [customTemplates],
  );
  const commonConfig = {
    serviceId,
    customDetail,
    alertSource,
    mode,
    activeAlertSourceNames,
    editTemplate,
    updatePermission,
    deletePermission,
    refetchRule: () => {
      refetch();
      hideModal();
    },
  };
  return (
    <>
      <Flex justifyContent={'flex-end'} my={3}>
        <Button
          variant="default"
          size="sm"
          isDisabled={isCCTDisabled || !hasUpdate}
          onClick={() => {
            history.push({
              pathname: history.location.pathname,
              search: qs.stringifyUrl({
                url: history.location.search,
                query: {
                  [modeParamKey]: 'create',
                },
              }),
            });
          }}
        >
          + Template
        </Button>
      </Flex>
      <DataTable data={data} columns={filteredColumns} isClientPagination disableOverflow />
      <DialogModalFrame
        id={'custom-template'}
        width="1000px"
        onClose={checkDirtyAndCloseModal}
        skipFocusTrap
      >
        {mode && !isFetching && (
          <CustomTemplate
            {...commonConfig}
            refetchAlert={() => {
              refetchAlertSources();
            }}
            setIsDirty={checkAndSetDirty}
          />
        )}
      </DialogModalFrame>
      <AlertDialogComponent
        isOpen={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        callbackFn={async () => {
          deleteTemplate({ serviceId, alertSource })
            .then(() => {
              refetchAlertSources();
              setOpenDeleteModal(false);
              history.push({
                pathname: history.location.pathname,
                search: qs.exclude(history.location.search, [alertSourceParamKey]),
              });
            })
            .catch(error => {
              console.log(error);
            });
        }}
        msg={`Deleting the template would mean incoming alerts here on will follow the default incident message and description templates. Do you want to proceed?`}
        title={`
        Are you sure you want to delete this Custom Content Template ?

        `}
        isDelete={true}
        loading={isLoading}
      />
      <UnsavedChangesGuard
        isManual={true}
        showModal={hasUnsavedChanges}
        onModalClose={() => {
          setHasUnsavedChanges(false);
        }}
        onDiscardChanges={() => {
          hideModal();
          setHasUnsavedChanges(false);
        }}
      />
    </>
  );
};

export default CustomTemplates;
