import * as React from 'react';
import { SVGProps } from 'react';

const SvgHoneycombLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M16.2 11.9H15c-.2 0-.4 0-.5.1-.2 0-.3.1-.4.2v-1.9h-.5v6h.5v-2.8c0-.3.1-.6.3-.8.2-.2.5-.3.8-.3h.7c.2 0 .3 0 .4.1.1.1.3.1.4.3.1.1.2.2.3.4.1.1.1.3.1.4v2.7h.5v-3c0-.2 0-.4-.1-.5-.1-.2-.2-.3-.3-.5-.1-.1-.3-.2-.4-.3-.2 0-.4-.1-.6-.1z"
      style={{
        fill: '#4b3486',
      }}
    />
    <path
      d="m10.3 13.4 1.7 3-1.7 3H6.9l-1.7-3 1.7-3h3.4z"
      style={{
        fill: '#ffb000',
      }}
    />
    <path
      d="m10.3 6.1 1.7 3-1.7 3H6.9l-1.7-3 1.7-3h3.4z"
      style={{
        fill: '#64ba00',
      }}
    />
    <path
      d="m4.2 10.3 1.4 2.5-1.4 2.5H1.4L0 12.8l1.4-2.5h2.8z"
      style={{
        fill: '#f96e10',
      }}
    />
    <path
      d="m17.8.5 2.2 4-2.2 4h-4.5l-2.2-4 2.2-4h4.5z"
      style={{
        fill: '#0298ec',
      }}
    />
  </svg>
);

export default SvgHoneycombLogo20X20;
