import { Dispatch } from 'redux';
import { setAppLimits } from '../actions/app/limits';
import { exception } from '../exception';
import { IAppLimitsError } from '../interfaces/IAppLimits';
import { IAppActions } from '../interfaces/IAppState';

class Limits {
  private _dispatcher: Dispatch | null = null;

  private dispatch(message: string, description: string) {
    if (this._dispatcher === null) return;

    this._dispatcher(setAppLimits(message, description));
  }

  public reducer = (store: any) => (next: any) => (action: IAppActions) => {
    this._dispatcher = store.dispatch ?? null;
    // don't block any actions here
    return next(action);
  };

  public distill(error: any) {
    if (!error || !error.response || error.response.status !== 402) return;

    try {
      const {
        meta: {
          error_details: { description },
          error_message,
        },
      } = error.response.data as IAppLimitsError;

      this.dispatch(error_message, description);
    } catch (err: any) {
      exception.handle('E_PARSE_HTTP_ERRORS__LIMITS', err);
    }
  }
}

const limits = new Limits();

export { limits };
