import {
  Box,
  Popover as CPopover,
  PlacementWithLogical,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { AnyObject } from 'library/types';
import { ReactNode } from 'react';

export type PopoverProps = AnyObject & {
  trigger: ReactNode;
  triggerType?: 'hover';
  enableTriggerWrapper?: boolean;
  disablePortal?: boolean;
  children: ReactNode;
  isOpen?: boolean;
  placement?: PlacementWithLogical;
  hasArrow?: boolean;
};

export const Popover = ({
  trigger,
  triggerType,
  enableTriggerWrapper,
  disablePortal,
  children,
  isOpen,
  placement,
  hasArrow,
  ...rest
}: PopoverProps) => {
  return (
    <CPopover
      trigger={triggerType ?? 'click'}
      isOpen={isOpen}
      isLazy
      placement={placement}
      {...rest}
    >
      {({ isOpen: isPopoverOpen }) => (
        <>
          <PopoverTrigger>{enableTriggerWrapper ? <Box>{trigger}</Box> : trigger}</PopoverTrigger>
          {children &&
            isPopoverOpen &&
            (disablePortal ? (
              //Wrapped in box so that popover could open over drawer then table
              <Box
                style={{
                  position: 'fixed',
                  zIndex: 20,
                }}
              >
                <PopoverContent _focus={{ outline: 'none' }} width={'auto'}>
                  {hasArrow && <PopoverArrow />}
                  <PopoverBody p={0}>{children}</PopoverBody>
                </PopoverContent>
              </Box>
            ) : (
              <Portal>
                <PopoverContent _focus={{ outline: 'none' }} width={'auto'}>
                  {hasArrow && <PopoverArrow />}
                  <PopoverBody p={0}>{children}</PopoverBody>
                </PopoverContent>
              </Portal>
            ))}
        </>
      )}
    </CPopover>
  );
};
