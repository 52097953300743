import { Box, Collapse, HStack } from '@chakra-ui/react';
import { IIncidentOverviewResponse } from 'core/interfaces/IIncidents';
import { InfoFilledIcon } from 'icons';
import { THEME_COLORS } from 'library/theme/colors';
import React from 'react';
import { Para, TextButton, Tooltip } from 'uie/components';

import IncidentSummarySection from '../Section';
import IncidentSummaryParticipantProfile from '../ParticipantProfile';
import { useIncidentSummaryContext } from '../../context/hook';

const IncidentSummaryParticipants: React.FC = () => {
  const { overview, getParticipants } = useIncidentSummaryContext();
  const [collapsed, setCollapsed] = React.useState(true);

  if (!overview.data) return null;

  const participants = getParticipants();

  return (
    <IncidentSummarySection
      heading={
        <HStack alignItems="flex-start" spacing={1}>
          <Para
            fontWeight={800}
            fontSize={14.5}
            color={THEME_COLORS.secondary[700]}
            style={{ lineHeight: '21.75px', marginBottom: '8px' }}
          >
            PARTICIPANTS ({participants.length})
          </Para>
          <Tooltip
            label="List of users, squads, escalation policies who have been assigned, manually added as responders, or have performed actions on this incident"
            offset={{ left: '24px', top: '-28px' }}
            width="300px"
          >
            <InfoFilledIcon width={22} height={22} />
          </Tooltip>
        </HStack>
      }
    >
      {participants.slice(0, 3).map((participant, idx) => (
        <IncidentSummaryParticipantProfile
          key={participant.id}
          id={participant.id}
          name={participant.name}
          username={participant.username}
          rightSection={
            overview.data?.data?.status !== 'resolved' && idx === 0 && '| Current Assignee'
          }
          type={participant.type}
        />
      ))}
      {participants.length > 3 && (
        <Box>
          <Collapse in={!collapsed} animateOpacity>
            {participants.slice(3).map((participant, idx) => (
              <IncidentSummaryParticipantProfile
                key={participant.id}
                id={participant.id}
                name={participant.name}
                username={participant.username}
                type={participant.type}
              />
            ))}
          </Collapse>
          <TextButton
            buttonType="inverted"
            color={THEME_COLORS.brand.white}
            className="cursor-pointer"
            onClick={() => setCollapsed(!collapsed)}
            style={{
              color: THEME_COLORS.brand.blue,
              fontWeight: 800,
              fontSize: 10.5,
              marginTop: '16px',
            }}
          >
            {collapsed ? 'View More' : 'View Less'}
          </TextButton>
        </Box>
      )}
    </IncidentSummarySection>
  );
};

export default IncidentSummaryParticipants;
