import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, generatePath, useParams } from 'react-router-dom';
import { SCHEDULES_V2_PATH, SCHEDULES_V2_DETAIL_PATH } from 'views/main/routes/routes';

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  VStack,
  Text,
  FormLabel,
  Switch,
} from '@chakra-ui/react';

import Loader from 'components/chakra/Loader';
import { errorMessages } from '../../constants/errormessage';
import { rotationsCustomizePattern } from '../../constants/rotations.customize';
import { schedulesTextCopy } from '../../constants/schedules.copy';
import { mapMockEventsToCalendarEvents, mapGapsToCalendarEvent } from '../../helpers/helpers.event';
import { defaultReactQueryConfig, formatScheduleRequest } from '../../helpers/helpers.schedule';
import {
  useGetAllPatternParticipants,
  IPatternParticipant,
} from '../../helpers/helpers.customrotations';
import {
  GetMockEventsQuery,
  useGetMockEventsQuery,
} from 'views/main/organization/schedules/graphql/query';
import { INewSchedule, CalendarViewType } from '../../interface/schedule';
import SchedulesCalendar from '../../schedules.view/schedules.calendar';
import SchedulesHeader from '../../schedules.view/schedules.header';
import {
  ScheduleHeaderConsumer,
  useScheduleHeaderContext,
} from '../../schedules.view/schedules.header/context';
import { rotationStyles } from './rotation.styles';
import RotationPattern from './rotationPattern';
import { NewSchedule, TimeDurationUnit } from 'views/main/organization/schedules/graphql/types';

const CustomizeRotationPattern = () => {
  const history = useHistory();
  const formik = useFormikContext<INewSchedule>();
  const { scheduleId } = useParams<{ scheduleId: string }>();
  const { calendarRef, visibleDates, activeViewType, onSyncCalendar } = useScheduleHeaderContext();

  const {
    values: { showGaps, timeZone, rotations: existingRotations },
    errors,
    isSubmitting,
    handleChange,
  } = formik;

  const previewDateRange =
    activeViewType.value === CalendarViewType.dayGridMonth
      ? visibleDates.oneMonthView
      : visibleDates.oneWeekView;

  const { data, isFetching: isFetchingMockEvents } = useGetMockEventsQuery(
    {
      schedule: formatScheduleRequest(formik.values, true) as NewSchedule,
      from: previewDateRange[0].setZone(timeZone).startOf('day').toUTC().toISO(),
      till: previewDateRange
        .slice(-1)[0]
        .setZone(timeZone)
        .plus({ days: 1 })
        .endOf('day')
        .toUTC()
        .toISO(),
      In: TimeDurationUnit.Hours,
    },
    defaultReactQueryConfig,
  );

  const allParticipants = useGetAllPatternParticipants();

  useEffect(() => {
    if (data) {
      existingRotations.forEach((rt, index) => {
        const mockEvent = data?.mockEvents?.mockEvents?.find(
          mockEvent => mockEvent.rotationIndex === index,
        );
        if (mockEvent) {
          handleChange({
            target: {
              name: `rotations.${index}.onCallHoursPerParticipant`,
              value: mockEvent?.onCallDurationPerParticipant ?? 0,
            },
          });
        }
      });
    }
  }, [data]);

  const mappedMockEvents = useMemo(() => {
    if (!isFetchingMockEvents) {
      return [
        ...mapMockEventsToCalendarEvents(
          formik.values,
          data?.mockEvents.mockEvents as GetMockEventsQuery['mockEvents']['mockEvents'] | undefined,
          allParticipants as Array<IPatternParticipant>,
        ),
        ...(showGaps ? mapGapsToCalendarEvent(data?.mockEvents.gaps ?? [], timeZone) : []),
      ];
    } else {
      return [];
    }
  }, [isFetchingMockEvents, formik.values, data, timeZone, showGaps]);

  const areMockEventsEmpty =
    existingRotations.length > 0 && !isFetchingMockEvents && !mappedMockEvents.length;

  const onRemoveRotation = (id: string | undefined) => {
    if (!id) return;

    const rotationToRemove = existingRotations.find(rt => rt.id === id);
    if (rotationToRemove) {
      const filteredRotations = existingRotations.filter(rt => rt.id !== id);
      formik.setFieldValue('rotations', filteredRotations, true);
    }
  };

  const areRotationNamesDuplicate = errors.rotations === errorMessages.rotations.duplicateNames;
  const isValidEndDate = errors.rotations === errorMessages.rotations.startEndDate;

  return (
    <>
      <Box bg="white" position="relative" h="inherit">
        <>
          <SchedulesHeader.Preview />

          <ScheduleHeaderConsumer>
            {({ activeViewType }) => (
              <SchedulesCalendar
                ref={calendarRef}
                viewType={activeViewType.value}
                timeZone={formik.values.timeZone}
                events={areMockEventsEmpty ? [] : mappedMockEvents}
                disableScheduleDrawer
                disableEventModifiers
                height="calc(100% - 88px)"
                onSyncSelectedDate={onSyncCalendar}
              />
            )}
          </ScheduleHeaderConsumer>
        </>
      </Box>

      <Box bg="white" position="relative" sx={rotationStyles()}>
        <HStack w="100%">
          <Heading px={5} py={3} variant="h6">
            {rotationsCustomizePattern.heading}
          </Heading>
          <HStack position="absolute" spacing={1} right={2}>
            <FormLabel
              htmlFor="view-gaps"
              alignContent="center"
              mb={0}
              fontSize="sm"
              fontWeight="normal"
            >
              {rotationsCustomizePattern.labels.viewGaps}
            </FormLabel>
            <Switch
              id="view-gaps"
              size="sm"
              name="showGaps"
              isChecked={showGaps}
              onChange={handleChange}
            />
          </HStack>
        </HStack>
        <VStack
          w="100%"
          mt={1}
          spacing={4}
          maxH="calc(100vh - 340px)"
          position="relative"
          overflow="auto"
        >
          {existingRotations.map((rotation, index) => (
            <RotationPattern
              key={rotation.id}
              rotationId={rotation.id}
              onRemoveRotation={() => onRemoveRotation(rotation.id)}
              rotationBackgroundColor={rotation.color}
            />
          ))}
        </VStack>
        <FormControl
          isInvalid={!!errors.rotations}
          alignItems="flex-start"
          marginTop={4}
          marginBottom={4}
          marginLeft={4}
          bottom={0}
          position="absolute"
        >
          <FormErrorMessage>
            {errors.rotations
              ? areRotationNamesDuplicate
                ? errorMessages.rotations.duplicateNames
                : isValidEndDate
                ? errorMessages.rotations.startEndDate
                : errorMessages.rotations.submit
              : errorMessages.rotations.noPreview}
          </FormErrorMessage>
          <HStack mt={2}>
            <Button
              variant="default"
              type="submit"
              onClick={() => formik.handleSubmit()}
              isDisabled={isFetchingMockEvents}
              isLoading={isSubmitting}
            >
              {schedulesTextCopy.customization.button.save}
            </Button>

            <Button
              variant="invertedDefault"
              onClick={() =>
                history.push(
                  scheduleId
                    ? generatePath(SCHEDULES_V2_DETAIL_PATH, { scheduleId })
                    : SCHEDULES_V2_PATH,
                )
              }
            >
              {schedulesTextCopy.common.cancel}
            </Button>
          </HStack>
        </FormControl>
      </Box>
    </>
  );
};

export default CustomizeRotationPattern;
