import {
  REQUEST_ORG_RUNBOOK,
  REQUEST_ORG_RUNBOOK_SUCCESS,
  REQUEST_ORG_RUNBOOK_FAIL,
} from '../../const/organization';
import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';
import { IRunbook } from '../../interfaces/IRunBook';

const initialOrganizationRunbook = INITIAL_STATE.organization.runbooks;

const organizationRunbook = (state = initialOrganizationRunbook, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{
          action: RESET_STATE,
          runbook: [],
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ORG_RUNBOOK:
      return {
        ...state,
        ...{ action: REQUEST_ORG_RUNBOOK, error: { type: '', reason: {} } },
      };
    case REQUEST_ORG_RUNBOOK_SUCCESS:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_RUNBOOK_SUCCESS,
          runbook: action.payload.runbook.sort((a: IRunbook, b: IRunbook) => {
            return a.name.localeCompare(b.name);
          }),
          error: { type: '', reason: {} },
        },
      };
    case REQUEST_ORG_RUNBOOK_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_ORG_RUNBOOK_FAIL,
          runbook: [],
          error: { type: REQUEST_ORG_RUNBOOK_FAIL, reason: action.payload },
        },
      };
    default:
      return state;
  }
};

export default organizationRunbook;
