import styled from 'styled-components';
import { keyframes } from 'styled-components';

export const slideDownOpen = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -5%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const slideDownClose = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, -5%, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
`;

export const slideUpOpen = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, -5%, 0);
  }
`;

export const slideUpClose = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -5%, 0);
  }
`;

export const pulse = keyframes`
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

export const fadeIn = keyframes`
  from {
    background: transparent;
  }
  to {
    background: #0d214947;
  }
`;

const fadeOut = keyframes`
  from {
    background: #0d214927;
  }
  to {
    background: transparent;
  }
`;

export const Shell = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 990;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;
  animation-duration: 0.17s;
  animation-fill-mode: both;
  animation-name: ${fadeIn};

  &.close {
    animation-name: ${fadeOut};
  }
`;

interface IBlockProps {
  height?: string | 'auto';
  width?: string | '800px';
  padding?: string | '32px';
}

export const Block = styled.div<IBlockProps>`
  height: ${props => props.height || 'auto'};
  width: ${props => props.width || '800px'};
  padding: ${props => props.padding || '32px'};
  background: ${props => props.theme.shades.white};
  margin-top: -2px;
  border-radius: 3px;
  box-shadow: ${props => props.theme.shadow.default};
  animation-duration: 0.17s;
  animation-fill-mode: both;
  animation-name: ${slideDownOpen};

  &:focus {
    outline: none;
  }

  &.close {
    animation-name: ${slideUpClose};
  }
`;
