import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const TagThemeV2: ComponentStyleConfig = {
  baseStyle: {
    _focus: {
      boxShadow: 'none',
    },
    px: '0.5rem',
    closeButton: {
      fontSize: '0.5625rem',
      width: '0.5625rem',
      height: '0.5625rem',
    },
  },
  variants: {
    plain: () => ({
      label: {
        pr: '0.1875rem',
      },
      container: {
        minHeight: '0.9375rem',
        bg: 'secondary.100',
        p: {
          color: 'secondary.900',
        },
      },
      closeButton: {
        _focus: {
          boxShadow: 'none',
          bg: 'none',
        },
        margin: 0,
        color: 'button.close',
      },
      _hover: {
        boxShadow: 'none',
        container: {
          bg: 'secondary.200',
        },
      },
    }),
    keyValue: ({ color }) => ({
      container: {
        minHeight: 'auto',
        borderRadius: '3px',
        border: '1px solid',
        paddingInlineStart: 0,
        paddingInlineEnd: 0,
        overflow: 'hidden',
        borderColor: color,
        '& > span': {
          paddingInlineStart: 2,
          paddingInlineEnd: 2,
          py: '2px',
          backgroundColor: 'brand.white',
          color: color,
          '&:nth-of-type(2)': {
            bg: color,
            color: 'brand.white',
          },
        },
      },
      closeButton: {
        _focus: {
          boxShadow: 'none',
          bg: 'none',
        },
        marginTop: '1px',
        ml: '0.125rem',
        color: color || 'button.close',
        opacity: 1,
      },
    }),
  },
  defaultProps: {
    size: 'md',
  },
};
