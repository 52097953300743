import React from 'react';
import { ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { Workflow } from '../../types/response/workflow';
import { Link, useLocation } from 'react-router-dom';
import { safeEncodeUriComponent } from '../../utils';

type TriggersAndPropertiesIndexProps = {
  search?: string;
  setSearchFound: React.Dispatch<React.SetStateAction<boolean>>;
} & Pick<Workflow, 'trigger' | 'filters'>;

const getTriggerName = (trigger: Workflow['trigger']) => {
  return 'Trigger';
};

const getConditions = (filters: Workflow['filters']) => {
  if (filters?.type === 'empty') return '-';
  return 'Conditions';
};

const TriggersAndPropertiesIndex: React.FC<TriggersAndPropertiesIndexProps> = ({
  trigger,
  filters,
  search,
  setSearchFound,
}) => {
  const triggerName = getTriggerName(trigger);
  const conditions = getConditions(filters);
  const path = useLocation();

  const items = [triggerName, conditions].filter(
    item => item !== '-' && (search ? item.toLowerCase().includes(search.toLowerCase()) : true),
  );

  //since these component returns null, so there is no other way to check the component visibility except using state
  if (!items.length) {
    setSearchFound(false);
    return null;
  }
  setSearchFound(true);

  return (
    <>
      <Text pt={6} variant="h3" fontWeight="bold">
        TRIGGER AND ITS PROPERTIES
      </Text>
      <UnorderedList mt={0}>
        {items.map((item, index) => (
          <ListItem key={index} ml={5} color={'brand.blue'}>
            <Link to={`${path.pathname}#${safeEncodeUriComponent(item)}`}>{item}</Link>
          </ListItem>
        ))}
      </UnorderedList>
    </>
  );
};

export default TriggersAndPropertiesIndex;
