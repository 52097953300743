export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS';
export const REQUEST_LOGIN_FAIL = 'REQUEST_LOGIN_FAIL';

export const REQUEST_USER_INFO = 'REQUEST_USER_INFO';
export const REQUEST_USER_INFO_SUCCESS = 'REQUEST_USER_INFO_SUCCESS';
export const REQUEST_USER_INFO_FAIL = 'REQUEST_USER_INFO_FAIL';

export const REQUEST_SAVE_USER_DEVICE_ID = 'REQUEST_SAVE_USER_DEVICE_ID';
export const REQUEST_SAVE_USER_DEVICE_ID_SUCCESS = 'REQUEST_SAVE_USER_DEVICE_ID_SUCCESS';
export const REQUEST_SAVE_USER_DEVICE_ID_FAIL = 'REQUEST_SAVE_USER_DEVICE_ID_FAIL';

export const REQUEST_USER_LOGOUT = 'REQUEST_USER_LOGOUT';
export const REQUEST_USER_LOGOUT_FAIL = 'REQUEST_USER_LOGOUT_FAIL';

export const ROLE__ACCOUNT_OWNER = 'Account Owner';
export const ROLE__ADMIN = 'Admin';
export const ROLE__USER = 'User';
export const ROLE__STAKEHOLDER = 'Stakeholder';

export const NULL_ORG = '__NULL_ORG__';
export const NULL_PASSWORD = '__NULL_PASSWORD__';
