import { Calendar, CalendarDatum } from '@nivo/calendar';
import { ResponsiveWrapper } from '@nivo/core';
import React from 'react';

interface IProps {
  data: CalendarDatum[];
}

const CalendarChart: React.FC<IProps> = ({ data, ...props }) => {
  return (
    <ResponsiveWrapper>
      {({ width }) => (
        <Calendar
          data={data}
          width={width}
          height={300}
          from="2020-01-01"
          to="2020-12-31"
          emptyColor="#eeeeee"
          colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
          margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          monthBorderColor="#ffffff"
          dayBorderWidth={2}
          dayBorderColor="#ffffff"
        />
      )}
    </ResponsiveWrapper>
  );
};

export default CalendarChart;
