import React from 'react';
import { Grid, Heading, Para, TextButton } from 'uie/components';
import { runbookHtmlText, runbook_delete, taskHtmlText } from '../runbooks.constant';
import '../index.css';

export interface IProps {
  action: (a: string) => void;
  runbookName: string;
  isRunbook?: boolean;
}

const ConfirmationBox = ({ ...props }: IProps) => {
  return (
    <div>
      <Grid
        justifyContent="space-between"
        alignItems="center"
        className="runbook__delete_confirmation__model_container"
      >
        <Heading height={20} fontSize={18}>
          Delete {props.isRunbook ? 'Runbook' : 'Task'}
        </Heading>
      </Grid>
      <Grid className="runbook__delete_confirmation__model_container">
        <Para>
          {props.isRunbook
            ? runbookHtmlText.deleteConfirmationBox
            : taskHtmlText.deleteConfirmationBox}
        </Para>
      </Grid>
      <Grid className="mt-20" justifyContent="flex-end" alignItems="center">
        <TextButton
          buttonType="inverted"
          width="80px"
          className="mr-10 runbook__delete_confirmation__model_btn"
          onClick={() => {
            props.action(runbook_delete.NO);
          }}
        >
          <Para fontSize={16}>Cancel</Para>
        </TextButton>
        <br />
        <TextButton
          color="#ff4b4b"
          width="80px"
          className="runbook__delete_confirmation__model_btn"
          onClick={() => {
            props.action(runbook_delete.YES);
          }}
        >
          <Para fontSize={16} color="#fff">
            Delete
          </Para>
        </TextButton>
      </Grid>
    </div>
  );
};

export default ConfirmationBox;
