import { Box, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Text } from '@chakra-ui/layout';
import { FocusBlock, Para } from 'uie/components';
import UnsnoozeDrawer from 'components/UnsnoozeDrawer';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import { CrownSmallIcon } from 'icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnooze } from './hooks';
import './snooze.module.css';
import { SnoozeComponentPropsType, SnoozeTimer_Values } from './type';
import { ListingButton } from 'library/atoms';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

function Snooze(props: SnoozeComponentPropsType) {
  const [showSnoozeModal, setShowSnoozeModal] = useState<boolean>(false);
  const [showUnSnoowDrawer, setShowUnsnoozeDrawer] = useState<boolean>(false);
  const [showDropwdown, setShowDropdown] = useState<boolean>(false);

  const { isSnoozed, disabled, children, organization, incidentId, incidentName, screenName } =
    props;

  const { getSnoozeIntervals, snoozeIncident, loading } = useSnooze();

  const isSnoozeFeatureUnAvailable = useMemo(() => {
    return organization
      ? BillingService.isFeatureDisabled(
          { organization } as unknown as Pick<IAppState, 'organization'>,
          'snooze-notifications',
        )
      : false;
  }, [organization]);

  const snoozeIntervals = useMemo(() => {
    return getSnoozeIntervals();
  }, [getSnoozeIntervals]);

  const snoozeNotification = useCallback(
    (snoozeTimeData: SnoozeTimer_Values) => {
      snoozeIncident(incidentId, snoozeTimeData.value, incidentName, screenName, () =>
        setShowDropdown(false),
      );
    },
    [snoozeIncident, incidentName, incidentId],
  );

  const toggleUpgradePlanModal = useCallback(() => {
    if (isSnoozeFeatureUnAvailable) setShowSnoozeModal(!showSnoozeModal);
    if (isSnoozed && !isSnoozeFeatureUnAvailable) openUnSnoozeDrawer();
    else setShowDropdown(true);
  }, [showSnoozeModal, isSnoozed, isSnoozeFeatureUnAvailable]);

  const renderDropDown = useMemo(() => {
    return snoozeIntervals.map((interval: SnoozeTimer_Values, index: number) => {
      return (
        <MenuItem
          className="snooze_tab_link"
          key={interval.value}
          onClick={() => snoozeNotification(interval)}
        >
          <Text fontSize={14} color="secondary.1000" variant="formInput" lineHeight="100%">
            {interval.title}
          </Text>
        </MenuItem>
      );
    });
  }, [snoozeIntervals]);

  const renderCrown = useMemo(() => {
    if (isSnoozeFeatureUnAvailable)
      return (
        <CrownSmallIcon
          className="pro"
          style={{
            position: 'absolute',
            left: '-4px',
            top: '-8px',
            height: 14,
            width: 14,
          }}
        />
      );
    return null;
  }, [isSnoozeFeatureUnAvailable]);

  const openUnSnoozeDrawer = useCallback(() => {
    setShowUnsnoozeDrawer(true);
  }, []);

  const closeUnSnoozeModal = useCallback(() => {
    setShowUnsnoozeDrawer(!showUnSnoowDrawer);
  }, [showUnSnoowDrawer]);

  const userAccess = useUserAccess();

  const hasUpdate = userAccess.hasUpdateAccess('incidents');

  return (
    <React.Fragment>
      <Box position="relative" style={{ pointerEvents: 'auto' }}>
        <Menu isLazy strategy="fixed">
          {screenName === 'Incident List' ? (
            <NoPermissionTooltip isDisabled={hasUpdate}>
              <MenuButton
                as={ListingButton}
                title={isSnoozed ? 'Unsnooze' : 'Snooze'}
                style={{ padding: '5px 18px', height: 29, width: 85, pointerEvents: 'auto' }}
                rightIcon={!isSnoozed ? <ChevronDownIcon /> : <></>}
                onClick={toggleUpgradePlanModal}
                isDisabled={!hasUpdate}
              >
                {children}
              </MenuButton>
            </NoPermissionTooltip>
          ) : (
            <MenuButton onClick={toggleUpgradePlanModal}>{children}</MenuButton>
          )}
          {showDropwdown && !isSnoozeFeatureUnAvailable && !isSnoozed && (
            <MenuList w={screenName === 'Incident List' ? '85px' : '112px'} minW="0" zIndex={2}>
              {renderDropDown}
            </MenuList>
          )}
        </Menu>
        {renderCrown}
      </Box>
      <UpgradePlanModal
        hasBillingPermission={BillingService.hasManageBillingPermission({
          organization,
        } as unknown as Pick<IAppState, 'organization'>)}
        showModal={showSnoozeModal}
        onCancel={toggleUpgradePlanModal}
        message={BillingService.getMessage(0, 'snooze-notifications', {
          organization,
        } as unknown as Pick<IAppState, 'organization'>)}
        header={BillingService.getHeader(0, 'snooze-notifications', {
          organization,
        } as unknown as Pick<IAppState, 'organization'>)}
        onUpgrade={toggleUpgradePlanModal}
      />
      <UnsnoozeDrawer
        isOpen={showUnSnoowDrawer}
        onClose={closeUnSnoozeModal}
        incidentId={incidentId}
        incidentName={incidentName}
        screenName={screenName}
      />
    </React.Fragment>
  );
}
export default React.memo(Snooze);
