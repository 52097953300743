import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  background?: string | 'theme.primary.default';
  lineFill?: 'white' | 'black';
  base?: '16px' | '14px' | string;
}

const CheckboxContainer = styled.label`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:focus-visible ~ * {
    outline: ${props => props.theme.focus.default};
    outline-offset: 4px;
  }
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  width: 100%;
  height: 100%;
`;

const StyledCheckbox = styled.div<IProps>`
  display: inline-block;
  width: ${props => props.base || '16px'};
  height: ${props => props.base || '16px'};
  border-radius: 3px;
  background: ${props =>
    props.checked ? props.background || props.theme.primary.default : props.theme.shades.white};
  border: 1px solid
    ${props =>
      props.checked
        ? props.background || props.theme.primary.default
        : props.theme.shades.lightGrey};
  transition: opacity, borer, background 0.17s ease;
  opacity: ${props => (props.disabled ? '0.33' : '1')};

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
    stroke: ${props => props.lineFill || props.theme.shades.white};
  }

  &:hover,
  ${HiddenCheckbox}:focus + & {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    border: 1px solid ${props => props.background || props.theme.primary.default};
    outline: ${props => props.theme.focus.default}
    outline-offset: 4px;
  }

  &:active {
    opacity: 0.77;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    border: 1px solid ${props => props.background || props.theme.primary.default};
  }
`;

const Checkbox = (props: IProps) => (
  <CheckboxContainer>
    <HiddenCheckbox type="checkbox" {...props} />
    <StyledCheckbox {...props}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" strokeWidth={4} />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

/** @component */
export default Checkbox;
