import { Squad } from '../../types';
import { useState } from 'react';

export type Props = {
  squads: Squad[];
};

export const useSearchSquad = ({ squads }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const filteredSquads = squads.filter(squad =>
    searchTerm ? squad.name.toLowerCase().includes(searchTerm) : true,
  );

  return { searchTerm, setSearchTerm, filteredSquads };
};
