import { Box, Flex, HStack } from '@chakra-ui/layout';
import { useEffect, useMemo } from 'react';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { OperatorOptions } from '../../../constant';
import { EntityOption, OperationOption } from '../../../types';
import useAlertSourceList from './useAlertSourceList';
import { Text } from '@chakra-ui/react';
import { onChangeFilter, onChangeOperator, selectedFilter, selectedOperator } from '../utility';
import FormField from 'library/molecules/FormField/FormField';
import FormDropdown from 'library/molecules/Form/FormDropdown/FormDropdown';

interface Props {
  alertSourceFilterError?: string;
  filterFieldName: string;
  filterName: string;
}

const AlertSourceFilter = <TFormType extends FieldValues>({
  alertSourceFilterError,
  filterFieldName,
  filterName,
}: Props) => {
  const { watch, setValue } = useFormContext<TFormType>();
  const { data: alertSources, isLoading } = useAlertSourceList();

  // fetching filter options
  const entityObjects = useMemo(
    () =>
      alertSources?.map(alertSource => ({
        value: alertSource._id,
        label: alertSource.type,
      })) as EntityOption[],
    [alertSources],
  );

  // fetching operator options
  const operatorOptions = useMemo(() => {
    return OperatorOptions.filter(option => option.label.startsWith('Is'));
  }, []);

  const activeOperator = selectedOperator(filterFieldName, watch, operatorOptions);
  const activeAlertSource = selectedFilter(filterFieldName, watch, entityObjects);

  // set default option if we dont have any value

  useEffect(() => {
    if (!activeOperator && !activeAlertSource) {
      setValue(
        `${filterFieldName}.type` as Path<TFormType>,
        'alert_source_is' as PathValue<TFormType, Path<TFormType>>,
      );
      setValue(
        `${filterFieldName}.fields.value` as Path<TFormType>,
        entityObjects[0].value as PathValue<TFormType, Path<TFormType>>,
      );
    }
  }, []);

  return (
    <Flex flex={1} pt={2} gap={4} flexDirection="row">
      <Box flex={1}>
        <FormDropdown<OperationOption>
          options={operatorOptions}
          name={`${filterFieldName}.operator`}
          value={activeOperator}
          onChange={op => {
            onChangeOperator(
              op,
              filterFieldName,
              filterName,
              setValue as (name: Path<TFormType>, value: any) => void,
            );
          }}
        />
      </Box>
      <Flex flex={3} flexDirection="column">
        <FormField error={alertSourceFilterError}>
          <FormDropdown<EntityOption>
            options={entityObjects}
            name={`${filterFieldName}.entity`}
            isLoading={isLoading}
            formatOptionLabel={option => (
              <HStack>
                <Text>{option.label}</Text>
              </HStack>
            )}
            value={activeAlertSource}
            onChange={o => {
              onChangeFilter(
                o,
                filterFieldName,
                setValue as (name: Path<TFormType>, value: any) => void,
              );
            }}
          />
        </FormField>
      </Flex>
    </Flex>
  );
};

export default AlertSourceFilter;
