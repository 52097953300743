import { REQUEST_USER_LOGOUT } from 'core/const/auth';
import { AjaxResponse } from 'rxjs/ajax';
import axios from 'axios';
import { Observable } from 'rxjs';
import { AnyObject } from 'library/types';
import { API } from '../api';
import { clear } from 'store/localStorage';

/**
 * wrapper for api actions with default url
 */
const curryMethod =
  (
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    endpoint: string,
    withCredentials = false,
  ) =>
  (path: string, body?: any) => {
    const config: AnyObject = {
      method,
      url: `${endpoint}/${path}`,
      headers: {
        Authorization: API.config.sessionId ? `Bearer ${API.config.sessionId}` : '',
        'Content-Type': 'application/json',
      },
      withCredentials,
    };
    if (method === 'GET') {
      config.params = body;
    } else {
      config.data = body;
    }

    const r = axios(config);

    r.catch(error => {
      if (error) {
        if (error.response?.config?.url) {
          const url = new URL(error.response.config.url);
          if (url.pathname === '/v2/accounts/logout' || url.pathname === '/v3/accounts/login') {
            return;
          }
        }
        if (error.response?.status === 401) {
          window._store.dispatch({ type: REQUEST_USER_LOGOUT });
          window.localStorage.clear();
          clear();
        }
      }
      return error;
    });

    return new Observable<AjaxResponse>(observer => {
      r.then(response => {
        const ajaxResponse = {
          originalEvent: null,
          xhr: null,
          response: response.data,
          responseText: JSON.stringify(response.data),
          responseType: 'json',
        } as unknown as AjaxResponse;
        observer.next(ajaxResponse);
        observer.complete();
      }).catch(error => {
        observer.error(error);
      });
    });
  };

const get = (endpoint: string) => curryMethod('GET', endpoint);
const getWithCredentials = (endpoint: string) => curryMethod('GET', endpoint, true);
const post = (endpoint: string) => curryMethod('POST', endpoint);
const put = (endpoint: string) => curryMethod('PUT', endpoint);
const patch = (endpoint: string) => curryMethod('PATCH', endpoint);
const $delete = (endpoint: string) => curryMethod('DELETE', endpoint);

const request = {
  get,
  getWithCredentials,
  post,
  put,
  patch,
  delete: $delete,
};

export default request;
