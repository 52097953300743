import { useMemo } from 'react';
import { HStack, Text, Flex, Box, Circle, VStack } from '@chakra-ui/react';
import moment from 'moment';
import { ColumnDef } from '@tanstack/react-table';
import DotComponent from '../components/auditLogsList/DotComponent';
import { buildFilterObjectForTable, capitalizeFirstLetter, toUpperCase } from '../helpers';
import { AssigneeType } from 'library/enums';
import { AssigneeAvatar, Popover, Tooltip } from 'library/atoms';
import { getAssigneeComponent } from '../helpers/utils';
import { truncate } from 'core/helpers/stringUtils';

export const useAuditLogsTable = () => {
  const AuditLogsColumns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'resource',
        header: 'RESOURCE',
        cell: ({ row }) => (
          <Box>
            <Text>{capitalizeFirstLetter(row.original.resource)}</Text>
          </Box>
        ),
      },
      {
        id: 'toc',
        header: 'TYPE OF ACTION',
        cell: ({ row }) => (
          <Box>
            <Text>{capitalizeFirstLetter(row.original.action)}</Text>
          </Box>
        ),
      },
      {
        id: 'actors',
        header: 'ACTORS',
        cell: ({ row }) => (
          <HStack gap={2}>
            {row.original.actor?.userID.trim().length ? (
              <>
                <AssigneeAvatar name={row.original.actor.fullName} type={AssigneeType.User} />
                {getAssigneeComponent(
                  row.original.actor.userID,
                  AssigneeType.User,
                  row.original.actor.fullName,
                )}
              </>
            ) : (
              <Text>-</Text>
            )}
          </HStack>
        ),
      },
      {
        id: 'timestamp',
        header: 'TIMESTAMP',
        cell: ({ row }) => (
          <Box>
            <Text>
              {moment(row?.original?.timestamp, 'YYYY-MM-DD HH:mm:ss.SSSSSS Z').format(
                'MMM DD, YYYY hh:mm:ss',
              )}
            </Text>
          </Box>
        ),
      },
      {
        id: 'team',
        header: 'Team',
        cell: ({ row }) => (
          <Box>
            <Text>
              {row?.original?.team?.isDeleted ? (
                <Text>Deleted Team </Text>
              ) : row?.original?.team?.name.trim().length ? (
                getAssigneeComponent(
                  row?.original?.team.id,
                  AssigneeType.Team,
                  row?.original?.team.name,
                )
              ) : (
                '-'
              )}
            </Text>{' '}
          </Box>
        ),
      },
      {
        id: 'client',
        header: 'Client',
        cell: ({ row }) => (
          <Box>
            <Text>{row?.original?.client}</Text>{' '}
          </Box>
        ),
      },
    ],
    [],
  );

  return AuditLogsColumns;
};

export const useExportHistoryTable = () => {
  const ExportHistoryColumns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'name',
        header: 'EXPORT NAME',
        cell: ({ row }) => (
          <Box style={{ width: '140px' }}>
            {row.original.name.length >= 100 ? (
              <Tooltip label={row.original.name} placement="bottom">
                <Text variant="hint" isTruncated maxWidth={'25ch'}>
                  {truncate(row.original.name, 100)}
                </Text>
              </Tooltip>
            ) : (
              <Text>{truncate(row.original.name, 100)}</Text>
            )}
          </Box>
        ),
      },
      {
        id: 'desc',
        header: 'EXPORT DESCRIPTION',
        cell: ({ row }) => (
          <Box style={{ width: '140px' }}>
            <Text>
              {row.original.description.length ? (
                row.original.description.length >= 100 ? (
                  <Tooltip label={row.original.description} placement="bottom">
                    <Text variant="hint" isTruncated maxWidth={'25ch'}>
                      {truncate(row.original.description, 100)}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text>{truncate(row.original.description, 100)}</Text>
                )
              ) : (
                '-'
              )}
            </Text>
          </Box>
        ),
      },
      {
        id: 'REQUESTED BY',
        header: 'REQUESTED BY',
        cell: ({ row }) => (
          <HStack gap={2}>
            <AssigneeAvatar name={row.original.requestedBy.fullName} type={AssigneeType.User} />
            {getAssigneeComponent(
              row.original.requestedBy.userID,
              AssigneeType.User,
              row.original.requestedBy.fullName,
            )}
          </HStack>
        ),
      },
      {
        id: 'filters',
        header: 'FILTERS',
        cell: ({ row }) => (
          <HStack gap={1}>
            {buildFilterObjectForTable(row?.original?.filters).filterTags.length ? (
              <>
                {buildFilterObjectForTable(row?.original?.filters).filterTags.map(
                  (ele: any, index: number) => {
                    return (
                      <>
                        <Text key={ele.key}>{`${truncate(ele, 20)}`}</Text>
                        <Text>
                          {index !==
                            buildFilterObjectForTable(row?.original?.filters).filterTags.length -
                              1 && `|`}
                        </Text>
                      </>
                    );
                  },
                )}
                {Boolean(
                  buildFilterObjectForTable(row?.original?.filters).overflowElements.length,
                ) && (
                  <Popover
                    hasArrow={false}
                    enableTriggerWrapper={true}
                    disablePortal={true}
                    trigger={
                      <Circle cursor="pointer" size="19px" bg="primary.400">
                        <Text variant="hint" color="secondary.1000">
                          +
                          {
                            buildFilterObjectForTable(row?.original?.filters).overflowElements
                              .length
                          }
                        </Text>
                      </Circle>
                    }
                  >
                    <VStack
                      p="2"
                      spacing={2}
                      maxH="60vh"
                      overflowY="scroll"
                      alignItems="flex-start"
                    >
                      {buildFilterObjectForTable(row?.original?.filters).overflowElements.map(
                        (ele: any, index: number) => {
                          return (
                            <Text key={ele.key}>
                              {`${ele}`}{' '}
                              {index !==
                                buildFilterObjectForTable(row?.original?.filters).overflowElements
                                  .length -
                                  1 && `|`}
                            </Text>
                          );
                        },
                      )}
                    </VStack>
                  </Popover>
                )}
              </>
            ) : (
              <Text>-</Text>
            )}
          </HStack>
        ),
      },
      {
        id: 'DOE',
        header: 'DATE OF EXPORT',
        cell: ({ row }) => (
          <Box>
            <Text>
              {moment(row?.original?.exportedAt, 'YYYY-MM-DD HH:mm:ss.SSSSSS Z').format(
                'MMM DD, YYYY hh:mm:ss',
              )}
            </Text>{' '}
          </Box>
        ),
      },
      {
        id: 'DOE',
        header: 'EXPORT STATUS',
        cell: ({ row }) => (
          <HStack>
            <DotComponent status={row?.original?.status} />
            <Text>{` ${toUpperCase(row?.original?.status)}`}</Text>{' '}
          </HStack>
        ),
      },
    ],
    [],
  );

  return ExportHistoryColumns;
};
