import { Box, Button, Flex } from '@chakra-ui/react';
import React, { useContext } from 'react';

import DataTable from '../../../components/Table';
import { IMaintenance } from '../../../Interface';
import { tableColumns } from './column';

// import { tableColumns } from '../components/Table/columns';
type action = (id: number) => void;
interface IProps {
  list: IMaintenance[];
  pageChange: (queryPageIndex: number) => void;
  pageSizeChange: (queryPageSize: number) => void;
  queryPageIndex: number;
  queryPageSize: number;
  totalCount: number;
  onDelete: action;
  onEdit: action;
  timezone: string;
  canEdit: boolean;
}

interface IPropsAction {
  rowData: any;
  onEdit: action;
  onDelete: action;
  canEdit: boolean;
}

const MaintenanceAction = (props: IPropsAction) => {
  const { rowData, onEdit, onDelete, canEdit } = props;
  const maintenanceId = rowData?.row?.original?.id || -1;

  return (
    <Flex gap={3} align="center" boxShadow="-10px 0px 10px 1px var(--chakra-colors-gray-100)">
      <Button
        hidden={!canEdit}
        onClick={() => {
          onDelete(maintenanceId);
        }}
        variant={'outline'}
      >
        Delete
      </Button>
      <Button
        onClick={() => {
          onEdit(maintenanceId);
        }}
      >
        {canEdit ? 'Edit' : 'View'}
      </Button>
    </Flex>
  );
};

const MaintenanceList = (props: IProps) => {
  const {
    queryPageIndex,
    queryPageSize,
    pageSizeChange,
    pageChange,
    totalCount,
    onDelete,
    onEdit,
    timezone,
    canEdit,
  } = props;
  return (
    <Box>
      <DataTable
        data={props.list}
        columns={tableColumns}
        timezone={timezone}
        hoverView={
          <MaintenanceAction rowData={{}} onEdit={onEdit} onDelete={onDelete} canEdit={canEdit} />
        }
        paginationProps={{
          queryPageIndex,
          queryPageSize,
          totalCount: totalCount || 0,
          pageChange: (queryPageIndex: number) => pageChange(queryPageIndex),
          pageSizeChange: (queryPageSize: number) => {
            pageSizeChange(queryPageSize);
          },
        }}
      />
    </Box>
  );
};

export default MaintenanceList;
