import { createStandaloneToast } from '@chakra-ui/react';
import Axios from 'axios';
import { API } from 'core';
import { useQuery } from 'react-query';
import { queryKeys, reactQueryConfig } from '../contants/service.contant';
import { reactQueryConfigError } from '../helpers/helper.service';

const toast = createStandaloneToast();

const getOpenIncidents = async (serviceIds: string[], teamId: string) => {
  const { data } = await Axios.put<{
    data: { open_incidents: { [serviceId: string]: { triggered: number; acknowledged: number } } };
  }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/catalog-services/metrics/open-incidents?owner_id=${teamId}`,
    {
      services: serviceIds,
    },
  );
  return data.data;
};

/**
 * Get Open Incidents of multiple services
 * @param serviceIds Service Id List
 * @param teamId Team Id
 * @returns Open incident of multiple services
 */
export const useServicesOpenIncidentsQuery = (serviceIds: string[], teamId: string) => {
  return useQuery(
    [queryKeys.SERVICEDOPENINCIDENTS, serviceIds, teamId],
    () => getOpenIncidents(serviceIds, teamId),
    {
      ...reactQueryConfig,
      enabled: !!teamId && !!serviceIds.length,
      select: res => res.open_incidents,
      onError: reactQueryConfigError,
    },
  );
};
