import React, { useState, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Label } from 'uie/components';
import { Button, Grid, GridItem } from '@chakra-ui/react';
import Select from 'components/chakra/Select';
import { INewSchedule } from '../../../interface/schedule';
import {
  rotationsCustomizePattern,
  CustomRepeatFreqLimit,
} from '../../../constants/rotations.customize';
import {
  getRepeatMonthlyDropDownOptions,
  mapWeekdayFilterToSelection,
  getCustomPeriodFrequencyDropdownOptions,
} from '../../../helpers/helpers.customrotations';
import InputNumber from 'components/chakra/NumberInput';
import { CustomPeriodUnit, DayOfWeek } from 'views/main/organization/schedules/graphql/types';

const IntervalAndFrequency = ({ rotationId }: any) => {
  const {
    values: { rotations },
    handleChange,
  } = useFormikContext<INewSchedule>();

  const currentRotationIndex = rotations.findIndex(rt => rt.id === rotationId);

  const currentRotationStartDate = rotations.find(rt => rt.id === rotationId)?.startDate;
  const customRepeatPeriod = rotations.find(rt => rt.id === rotationId)?.customPeriod;

  const customRepeatFreqLimit = useMemo(() => {
    if (!customRepeatPeriod?.periodUnit) return 1;

    switch (customRepeatPeriod?.periodUnit) {
      case CustomPeriodUnit.Day: {
        return CustomRepeatFreqLimit.Day;
      }
      case CustomPeriodUnit.Week: {
        return CustomRepeatFreqLimit.Week;
      }
      case CustomPeriodUnit.Month: {
        return CustomRepeatFreqLimit.Month;
      }
    }
  }, [customRepeatPeriod?.periodUnit]);

  useEffect(() => {
    handleChange({
      target: {
        name: `rotations.${currentRotationIndex}.customPeriod.periodUnit`,
        value: customRepeatPeriod?.periodUnit ? customRepeatPeriod?.periodUnit : 'day',
      },
    });
    handleChange({
      target: {
        name: `rotations.${currentRotationIndex}.customPeriod.periodFrequency`,
        value: customRepeatPeriod?.periodFrequency ? customRepeatPeriod?.periodFrequency : 1,
      },
    });
  }, []);

  const [repeatMonthlyOption, setRepeatMonthlyOption] = useState({ label: '', value: '' });

  const daysOfWeekFilter = customRepeatPeriod?.daysOfWeekFilter;

  const activeWeekDays: Array<DayOfWeek> = mapWeekdayFilterToSelection(daysOfWeekFilter);

  const handleWeekdayButtonClick = (event: any) => {
    const buttonValue = event.target.value;
    const indexOfButton = rotationsCustomizePattern.customRepeat.selectOptions.daysOfWeek.findIndex(
      (weekday: DayOfWeek) => weekday === buttonValue,
    );
    handleChange({
      target: {
        name: `rotations.${currentRotationIndex}.customPeriod.daysOfWeekFilter.${indexOfButton}`,
        value: !daysOfWeekFilter?.[indexOfButton],
      },
    });
  };

  return (
    <>
      <Grid
        templateColumns="1fr 0.8fr 2.2fr"
        templateRows={
          customRepeatPeriod?.periodUnit === 'week' || customRepeatPeriod?.periodUnit === 'month'
            ? `repeat(2, 1fr)`
            : '1fr'
        }
        gap={2}
        alignItems="center"
        p={3}
      >
        <GridItem>
          <Label>{rotationsCustomizePattern.customRepeat.labels.repeatEvery}</Label>
        </GridItem>
        <GridItem>
          <InputNumber
            defaultValue={1}
            min={1}
            max={customRepeatFreqLimit}
            value={customRepeatPeriod?.periodFrequency}
            onChange={(value: any) =>
              handleChange({
                target: {
                  name: `rotations.${currentRotationIndex}.customPeriod.periodFrequency`,
                  value: Number.parseInt(value),
                },
              })
            }
          />
        </GridItem>
        <GridItem>
          <Select
            value={{
              label: `${customRepeatPeriod?.periodUnit}${
                (customRepeatPeriod?.periodFrequency ?? 1) > 1 ? 's' : ''
              }`,
              value: customRepeatPeriod?.periodUnit,
            }}
            options={getCustomPeriodFrequencyDropdownOptions(customRepeatPeriod)}
            size="sm"
            onChange={(selectedValue: any) => {
              handleChange({
                target: {
                  name: `rotations.${currentRotationIndex}.customPeriod.periodUnit`,
                  value: selectedValue.value,
                },
              });
            }}
            closeMenuOnSelect
          />
        </GridItem>
        {(customRepeatPeriod?.periodUnit === 'week' ||
          customRepeatPeriod?.periodUnit === 'month') && (
          <GridItem>
            <Label>
              {customRepeatPeriod?.periodUnit === 'week'
                ? rotationsCustomizePattern.customRepeat.labels.repeatOnWeekly
                : rotationsCustomizePattern.customRepeat.labels.repeatOnMonthly}
            </Label>
          </GridItem>
        )}
        {customRepeatPeriod?.periodUnit === 'month' && (
          <GridItem colSpan={2}>
            <Select
              value={
                getRepeatMonthlyDropDownOptions(
                  currentRotationStartDate ? currentRotationStartDate : new Date(),
                ).filter(option => option.label)[0]
              }
              options={getRepeatMonthlyDropDownOptions(
                currentRotationStartDate ? currentRotationStartDate : new Date(),
              ).filter(option => option.label)}
              onChange={(selectedValue: any) => setRepeatMonthlyOption(selectedValue)}
              closeMenuOnSelect
            />
          </GridItem>
        )}
        {customRepeatPeriod?.periodUnit === 'week' && (
          <GridItem colSpan={2}>
            <GridItem>
              {rotationsCustomizePattern.customRepeat.selectOptions.daysOfWeek.map(
                (weekday, index) => {
                  const firstChOfWeekday = weekday[0].toUpperCase();

                  return (
                    <Button
                      key={index}
                      size="xs"
                      variant={
                        activeWeekDays.indexOf(weekday) > -1
                          ? 'weekdayIconSelected'
                          : 'weekdayIconUnselected'
                      }
                      value={weekday}
                      onClick={handleWeekdayButtonClick}
                      mr={2}
                    >
                      {firstChOfWeekday}
                    </Button>
                  );
                },
              )}
            </GridItem>
          </GridItem>
        )}
      </Grid>
    </>
  );
};

export default IntervalAndFrequency;
