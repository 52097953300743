import {
  ErrorBlock,
  FocusBlock,
  Grid,
  JSONCodeBlock,
  SelectBox,
  SpinLoader,
  Theme,
} from 'uie/components';
import { HStack, Text } from '@chakra-ui/react';
import { IAlertSource } from 'core/interfaces/IIntegration';
import React from 'react';
import shortid from 'shortid';
import StackLimit, {
  IconType,
} from 'views/main/organization/service-catalog/components/StackLimit';

interface IAlertSourceDropdown {
  alertSourcesLoadState: 'loading' | 'success' | 'error';
  alertSourceErrorMessage: string;
  eventState: 'loading' | 'noEvents' | 'idle' | 'default';
  event: any;
  alertSource: IAlertSource | null;
  alertSourceSearch: string;
  onSelectChange: (_: any, alertSource: IAlertSource) => void;
  onTextChange: (type: 'alertSourceSearch') => (event: React.ChangeEvent<HTMLInputElement>) => void;
  activeAlertSourceNames: string[] | undefined;
  alertSrc?: string;
  alertSources: IAlertSource[];
}
const { theme } = Theme;
const activeColor = theme.success.default;
const inactiveColor = theme.danger.default;

const AlertSourceDropdown: React.FC<IAlertSourceDropdown> = ({
  alertSourcesLoadState,
  alertSourceErrorMessage,
  eventState,
  event,
  alertSource,
  alertSourceSearch,
  onSelectChange,
  onTextChange,
  activeAlertSourceNames,
  alertSrc,
  alertSources,
}) => {
  const alertSourceFunction = (altSrc: IAlertSource) => {
    if (activeAlertSourceNames && alertSourceSearch) {
      return (
        altSrc.type.toLowerCase().includes(alertSourceSearch.toLocaleLowerCase()) &&
        !activeAlertSourceNames?.includes(altSrc.shortName)
      );
    } else if (alertSrc) {
      // if alertsource has already template and mode is edit/view, it shows only the aletsource that should be viewed,if activeAlertSourceNames is not present all aleertsource will be seen in edit/view mode
      return altSrc.shortName === alertSrc;
    } else if (activeAlertSourceNames) {
      // if alerstsource are already configured with template removing those alrtsource from options
      return !activeAlertSourceNames?.includes(altSrc.shortName);
    } else if (alertSourceSearch) {
      // onsearch returns those alert source
      return altSrc.type.toLowerCase().includes(alertSourceSearch.toLocaleLowerCase());
    } else {
      return true;
    }
  };

  return (
    <>
      <Grid flexWidth={5}>
        {alertSourcesLoadState === 'loading' && (
          <Grid flexWidth={12} justifyContent="center">
            <div style={{ margin: '125px' }}>
              <Grid justifyContent="center" alignItems="center">
                <SpinLoader />
                <small className="ml-10">Loading Alert Sources</small>
              </Grid>
            </div>
          </Grid>
        )}

        {alertSourcesLoadState === 'error' && (
          <Grid flexWidth={12} justifyContent="center">
            <div style={{ margin: '135px' }}>
              <Grid justifyContent="center" alignItems="center">
                <ErrorBlock fontWeight={500} fontSize={14}>
                  {alertSourceErrorMessage}
                </ErrorBlock>
              </Grid>
            </div>
          </Grid>
        )}

        {alertSourcesLoadState === 'success' && (
          <div className="mb-20 ml-20" style={{ width: 'calc(100% - 20px)' }}>
            <label className="item-box-tagline font-bold mb-10 block">Alert Source</label>
            <SelectBox
              id="select-alert-source"
              hook={
                alertSource ? (
                  <HStack gap={1} alignItems="unset !important">
                    <StackLimit
                      limit={1}
                      type="icon"
                      data={[
                        {
                          icon: alertSource.shortName as IconType,
                        },
                      ]}
                    />
                    <Text className="item-box-tagline" style={{ margin: 0 }}>
                      {alertSource.type}
                    </Text>
                  </HStack>
                ) : (
                  <Text fontSize={14} fontWeight={500}>
                    Please select an alert source
                  </Text>
                )
              }
              searchHookProps={{
                value: alertSourceSearch,
                height: '24px',
                fontSize: '16px',
                onChange: onTextChange('alertSourceSearch'),
              }}
              onValueChange={onSelectChange}
              height="auto"
              maxHeight="200px"
              width="100%"
              maxWidth="100%"
            >
              {alertSources
                .filter(alertSourceFunction)
                .map((altSrc: IAlertSource, index: number) => (
                  <FocusBlock
                    height={'100%'}
                    value={altSrc}
                    key={shortid.generate()}
                    isSelected={alertSource === altSrc}
                    style={{ fontSize: '12px' }}
                  >
                    <HStack gap={1} alignItems="unset !important">
                      <StackLimit
                        limit={1}
                        type="icon"
                        data={[
                          {
                            icon: altSrc.shortName as IconType,
                          },
                        ]}
                      />
                      <Text className="item-box-tagline" m={0}>
                        {altSrc.type}
                      </Text>
                    </HStack>
                  </FocusBlock>
                ))}
            </SelectBox>
            {eventState === 'loading' && (
              <Grid flexWidth={12} justifyContent="center">
                <div style={{ margin: '120px' }}>
                  <Grid justifyContent="center" alignItems="center">
                    <SpinLoader />
                    <small className="ml-10">Loading Event Payload</small>
                  </Grid>
                </div>
              </Grid>
            )}
            {eventState === 'noEvents' && (
              <Grid flexWidth={12} justifyContent="center">
                <div style={{ marginTop: '20px' }}>
                  <Grid justifyContent="center" alignItems="center">
                    <small
                      className="ml-10"
                      style={{ color: inactiveColor }}
                    >{`Selected alert source: ${alertSource?.type} is not configured for the service. For easier set up integrate the alertsource first`}</small>
                  </Grid>
                </div>
              </Grid>
            )}
            {eventState === 'idle' && Object.keys(event).length > 0 && (
              <div className="w-1-1 mt-10" style={{ position: 'sticky', top: 20 }}>
                <JSONCodeBlock
                  code={event.payload}
                  enableSearch={true}
                  shellProps={{
                    minHeight: '600px',
                    minWidth: 'calc(100% - 15px)',
                  }}
                />
              </div>
            )}
          </div>
        )}
      </Grid>
    </>
  );
};

export default AlertSourceDropdown;
