import Axios from 'axios';
import { API } from 'core';
import { T_WA_UP_MR_ADDED } from 'core/const/tracker';
import { DEFAULT_REACT_QUERY_CONFIG } from 'library/query-config';
import { useMutation, useQueryClient } from 'react-query';
import { AppTracker } from 'shared/analytics/tracker';
import { QUERY_KEY } from '../const';

import { AddRespondersAPIProps } from '../types';
import { invalidateRespondersList, onAPIError, reactQueryConfigSuccess } from './helper';

const addResponders = ({ incidentId, responders }: AddRespondersAPIProps) => {
  const url = `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${incidentId}/additional-responders`;
  return Axios.put(url, {
    additional_responders: responders,
  });
};

export const useAddRespondersMutation = (incidentId: string, onAPISuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(addResponders, {
    ...DEFAULT_REACT_QUERY_CONFIG,
    onSuccess: () => {
      invalidateRespondersList(queryClient);
      queryClient.invalidateQueries([QUERY_KEY.WATCHER, incidentId]);
      reactQueryConfigSuccess('Responders added successfully');
      onAPISuccess();
    },
    onError: err => {
      onAPIError(err);
    },
  });
};
