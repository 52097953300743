import { Circle, Icon, SquareProps } from '@chakra-ui/react';
import { HiCheck } from 'react-icons/hi';
import React from 'react';
interface RadioCircleProps extends SquareProps {
  isCompleted: boolean;
  isActive: boolean;
}

export const StepCircle = (props: RadioCircleProps) => {
  const { isCompleted, isActive } = props;
  return (
    <Circle
      size="8"
      bg={isCompleted ? 'rgba(17, 34, 70, 1)' : 'rgba(231, 233, 236, 1)'}
      borderWidth={isCompleted ? '0' : '2px'}
      borderColor={isActive ? 'inherit' : 'accent'}
      {...props}
    >
      {isCompleted ? (
        <Icon as={HiCheck} color="white" boxSize="5" />
      ) : (
        <Circle bg={isActive ? 'rgba(17, 34, 70, 1)' : 'border'} size="3" />
      )}
    </Circle>
  );
};
