import { useFormContext } from 'react-hook-form';
import { sloAffectingValidation } from 'views/main/organization/workflow/constant/validation';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { SloAffectingAction } from 'views/main/organization/workflow/types';
import { FormFooter } from '../form-footer';
import SloAffectingForm from './SloAffectingForm';
import { WorkflowForm } from '../../../../types';
import { getServiceEntityId, isServiceFilterPresent } from 'views/main/organization/workflow/utils';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import DrawerFooterWrapper from 'library/molecules/Drawer';

const SloAffectingContainer = () => {
  const { actionForm, onActionSubmit, checkDirtyAndCloseDrawer } = useWorkflowFormContext();
  const { watch } = useFormContext<WorkflowForm>();

  const serviceFilter = watch('serviceFilter');
  const filterType = watch('filterType');

  const serviceId = isServiceFilterPresent(filterType)
    ? getServiceEntityId(serviceFilter)
    : undefined;

  return (
    <FormWrapper<SloAffectingAction>
      onSubmit={onActionSubmit}
      defaultValues={actionForm! as SloAffectingAction}
      validationSchema={sloAffectingValidation}
    >
      <SloAffectingForm serviceId={serviceId} />
      <DrawerFooterWrapper>
        <FormFooter<SloAffectingAction> checkDirtyAndCloseDrawer={checkDirtyAndCloseDrawer} />
      </DrawerFooterWrapper>
    </FormWrapper>
  );
};

export default SloAffectingContainer;
