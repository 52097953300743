import {
  Badge,
  Box,
  Button,
  HStack,
  Icon as ChakraIcon,
  SimpleGrid,
  Stack,
  StackDirection,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { EscalationIcon, JiraaIcon, PeopleIcon, SlackIcon, SquadIcon, TeamIcon } from 'icons';
import { Link, useHistory } from 'react-router-dom';
import { hasReadAccessToEntity } from 'views/main/organization/navigation-flows/helpers';
import { getAlertSource } from '../../helpers/helper.alert-source';

import NodePopover from '../Popover';
import { alerts } from './alerts.constant';

export type IconType =
  | 'slack'
  | 'jira_cloud'
  | 'user'
  | 'squad'
  | 'Squadcast UI'
  | 'escalation'
  | 'team'
  | typeof alerts[number];

interface StackItemProps {
  direction?: StackDirection;
  limit?: number;
  isDiabled?: boolean;
}

interface TagStackItem extends StackItemProps {
  type: 'tag';
  data: { name: string }[];
}

interface IconStackItem extends StackItemProps {
  type: 'icon';
  data: { name?: string; icon: IconType }[];
}

interface IconWithTextStackItem extends StackItemProps {
  type: 'icon-text';
  data: { name: string; icon: IconType; to?: string; username?: string }[];
}

interface CountWithTextStackItem extends StackItemProps {
  type: 'count-text';
  data: { name: string; count: number }[];
}

interface IStackIcon {
  iconName: IconType;
  title?: string;
}

const StackIcon = ({ iconName, title }: IStackIcon) => {
  let a11yTitle = title || ''; //|| IconName[iconName];
  let Icon = null;
  switch (iconName) {
    case 'jira_cloud':
      Icon = JiraaIcon;
      break;
    case 'slack':
      Icon = SlackIcon;
      break;
    case 'user':
      Icon = PeopleIcon;
      break;
    case 'squad':
      Icon = SquadIcon;
      break;
    case 'escalation':
      Icon = EscalationIcon;
      break;
    case 'team':
      Icon = TeamIcon;
      break;
    default:
      const alertSource = getAlertSource(iconName);
      a11yTitle = a11yTitle || alertSource.name;
      Icon = alertSource.image;
  }

  return (
    <Tooltip label={a11yTitle} hasArrow bgColor="white" color="gray.700" fontWeight={400} size="xs">
      <Box>
        <ChakraIcon aria-label={a11yTitle} boxSize={5} as={Icon} />
      </Box>
    </Tooltip>
  );
};

type StackLimitProps =
  | TagStackItem
  | IconStackItem
  | IconWithTextStackItem
  | CountWithTextStackItem;
function StackLimit({ data, limit = data.length, type, direction }: StackLimitProps) {
  const [renderData, excessData] = [data.slice(0, limit), data.slice(limit)];

  const useRenderStackItems = (item: StackLimitProps['data'][0], i: number) => {
    const history = useHistory();
    switch (type) {
      case 'tag': {
        const tagItem = item as TagStackItem['data'][0];
        const tageKeyValue = tagItem.name.split(':')[1];
        const tageKeyKey = tagItem.name.split(':')[0];
        return (
          <HStack spacing={0}>
            <Badge
              borderLeftRadius="5px"
              textTransform="none"
              variant="solid"
              color="white"
              colorScheme="blue"
              m={0}
              fontSize="10px"
              title={tageKeyKey}
              borderRightRadius={0}
            >
              {tageKeyKey}
            </Badge>
            <Badge
              borderRightRadius="5px"
              textTransform="none"
              borderLeftRadius={0}
              variant="outline"
              bg="white"
              colorScheme="blue"
              fontSize="10px"
              title={tageKeyValue}
            >
              {tageKeyValue}
            </Badge>
          </HStack>
        );
      }
      case 'icon': {
        const iconItem = item as IconStackItem['data'][0];
        return <StackIcon key={i} iconName={iconItem.icon} title={iconItem.name} />;
      }
      case 'icon-text': {
        const iconItem = item as IconWithTextStackItem['data'][0];
        let component = (
          <Text maxWidth="24" noOfLines={1} fontSize="sm">
            {iconItem.name}
          </Text>
        );
        if (iconItem.to) {
          if (
            !iconItem.to.includes('squad') ||
            (iconItem.to.includes('squad') && hasReadAccessToEntity('squad'))
          ) {
            component = (
              <Link to={iconItem.to}>
                <Text maxWidth="24" noOfLines={1} fontSize="sm" cursor="pointer">
                  {iconItem.name}
                </Text>
              </Link>
            );
          }
        }
        const tooltipLabel = (
          <VStack alignItems="flex-start" spacing={0}>
            <span
              style={{
                fontWeight: 'bold',
                maxWidth: '30ch',
              }}
            >
              {iconItem.name}
            </span>
            <span
              style={{
                fontWeight: 'normal',
                maxWidth: '30ch',
              }}
            >
              {iconItem.username}
            </span>
          </VStack>
        );
        return (
          <HStack key={i} display={'flex'} alignItems={'center'}>
            <StackIcon iconName={iconItem.icon} title={iconItem.name} />
            <Tooltip
              label={tooltipLabel}
              hasArrow
              bgColor="white"
              color="gray.700"
              fontWeight={400}
              size="xs"
            >
              {component}
            </Tooltip>
          </HStack>
        );
      }
      case 'count-text': {
        const countItem = item as CountWithTextStackItem['data'][0];
        return (
          <HStack key={i} display={'flex'} alignItems={'center'}>
            <Box
              bgColor="gray.100"
              height="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontSize="sm">{countItem.count}</Text>
            </Box>
            <Text fontSize="sm">{countItem.name}</Text>
          </HStack>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Stack direction={direction || 'row'} spacing="5px">
      {renderData.map(useRenderStackItems)}
      {!!excessData.length && (
        <NodePopover
          trigger={
            <Button _focus={{ outline: 'none' }} size={'xs'} variant={'iconOverFlow'}>
              +{excessData.length}
            </Button>
          }
        >
          <SimpleGrid
            columns={type === 'icon' ? (excessData.length < 6 ? excessData.length : 8) : 1}
            spacing={5}
          >
            {excessData.map(useRenderStackItems)}
          </SimpleGrid>
        </NodePopover>
      )}
    </Stack>
  );
}

export default StackLimit;
