import * as React from 'react';
import { SVGProps } from 'react';

const SvgErrorceptionLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142.7 91.7" xmlSpace="preserve" {...props}>
    <path d="M22.4 46.7c3.4 1.1 5.8 2.8 7.2 5.2 1.4 2.4 2.1 6.5 2.1 12.2v7.6c0 3.6.4 6 1.1 7.2.8 1.2 2.1 2.1 4.1 2.5.4.1 1.1.2 1.9.3 3 .4 4.6 1.8 4.6 4.2 0 1.2-.4 2-1.2 2.7-.8.7-1.9 1-3.4 1-5.8 0-9.9-1.4-12.3-4.3-2.4-2.9-3.6-8.3-3.6-16.2v-6c0-4-.6-6.9-1.7-8.5-1.1-1.7-3.9-2.9-8.4-3.6-1.3-.2-2.3-.6-2.9-1.3-.6-.6-.9-1.6-.9-3 0-1.1.2-2 .7-2.6.5-.6 1.3-1.1 2.3-1.3.5-.1 1.3-.3 2.2-.5C20.1 41 23 37.1 23 30.4v-6.1c0-8 1.2-13.4 3.6-16.3S33.1 3.6 39 3.6c1.4 0 2.5.3 3.3 1 .8.7 1.2 1.6 1.2 2.8 0 2.4-1.5 3.8-4.6 4.2-.6.1-1.1.2-1.4.2-2.2.4-3.7 1.3-4.5 2.5s-1.3 3.8-1.3 7.5v7.6c0 5.7-.7 9.8-2.1 12.2-1.4 2.4-3.8 4.1-7.2 5.1z" />
    <path
      d="M54.4 48.9c.3 4.9 2 8.8 5 11.5 3.1 2.7 7.3 4.1 12.6 4.1 4 0 8.4-1.1 13.1-3.2s7.6-3.2 8.6-3.2 1.9.3 2.6 1c.7.7 1 1.5 1 2.4 0 1-.4 1.9-1.1 2.7-.7.8-1.9 1.6-3.5 2.4-3.4 1.7-7 3-10.6 3.9-3.7.9-7.3 1.3-10.9 1.3-7.8 0-14.1-2.3-18.8-6.8-4.7-4.5-7.1-10.5-7.1-18 0-7.6 2.5-13.8 7.5-18.5 5-4.7 11.5-7.1 19.5-7.1 7.3 0 13.3 2.2 18 6.6 4.8 4.4 7.2 9.7 7.2 16 0 1.9-.4 3.2-1.2 3.9-.8.7-3 1-6.6 1H54.4zm.3-6.7h33.6c-.5-4.1-2.2-7.4-5.1-9.9-2.9-2.5-6.5-3.7-10.7-3.7-4.6 0-8.4 1.2-11.6 3.6-3.2 2.4-5.2 5.7-6.2 10z"
      style={{
        fill: 'red',
      }}
    />
    <path d="M120.7 46.6c-3.5-1-5.9-2.7-7.3-5.1-1.4-2.4-2.1-6.5-2.1-12.2v-7.6c0-3.6-.4-6.1-1.1-7.3-.8-1.3-2.1-2.1-4-2.6-.5-.1-1.1-.2-2-.3-3-.4-4.5-1.7-4.5-3.9 0-1.3.4-2.3 1.1-2.9.7-.7 1.8-1 3.3-1 5.8 0 9.9 1.5 12.4 4.4 2.4 2.9 3.6 8.3 3.6 16.2v6.1c0 6.7 2.8 10.6 8.4 11.8 1.1.2 1.9.4 2.4.5 1.1.3 1.9.8 2.4 1.4.5.6.7 1.5.7 2.6 0 1.2-.3 2.1-.8 2.8-.5.7-1.4 1.2-2.4 1.4-.4.1-.9.2-1.6.3-6.1 1.2-9.1 5.2-9.1 12v6c0 8-1.2 13.4-3.6 16.3-2.4 2.9-6.5 4.3-12.3 4.3-1.5 0-2.6-.3-3.3-1-.7-.7-1.1-1.7-1.1-3 0-2.2 1.5-3.5 4.5-3.9.6-.1 1.1-.1 1.3-.2 2.2-.4 3.8-1.2 4.6-2.4.8-1.2 1.2-3.7 1.2-7.4v-7.6c0-5.7.7-9.7 2.1-12.2 1.3-2.7 3.8-4.4 7.2-5.5z" />
  </svg>
);

export default SvgErrorceptionLogo20X20;
