import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  ChakraProvider,
  Collapse,
  Flex,
  FormLabel,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Select from 'react-select';
import { colors } from '../../../theme/styles/colors';
import { AlertDialogComponent } from '../../../components/Alert';
import { COMPONENT_TYPE } from '../../../constants/status.constants';
import { getItemStyle, getNestedComponentStyle } from '../../../helpers/dragDropStyleHelper';
import { DoubleVertical } from '../../../icons';
import { IfilterOption, IStatusPageComponent, IStatusPageGroup } from '../../../Interface';
import { StatusServiceIcon } from '../../../../../../../icons';
import { useServiceList } from 'views/main/organization/service-catalog/hooks/useServiceList';
import useServiceListOptions from '../../../hooks/useServiceListData';
import { truncate } from 'core/helpers/stringUtils';
import { THEME_COLORS } from 'library/theme/colors';
interface IAlertDialog {
  isOpen: boolean;
  onClose: () => void;
  callbackFn: (id?: number) => void;
  componentInfo: IStatusPageGroup;
  groupOptions: IfilterOption[];
}

const DeleteGroupAlertDialog = ({
  isOpen,
  onClose,
  callbackFn,
  componentInfo,
  groupOptions,
}: IAlertDialog) => {
  const [reassignGroup, setReassignGroup] = useState<IfilterOption>({
    value: 0,
    label: '',
  });
  const cancelRef = useRef<HTMLButtonElement>(null);
  const hasComponents = (componentInfo.components || []).length > 0;
  const options = groupOptions.filter((op: IfilterOption) => op.value !== componentInfo.id);
  const title = hasComponents
    ? 'Reassign and Delete'
    : `Delete ${componentInfo.name} Component Group`;
  return (
    <ChakraProvider cssVarsRoot="#serviceAlert">
      <AlertDialog
        id="serviceAlert"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>
              {hasComponents ? (
                <Box>
                  <Text>Reassign the below components before deleting backend component group</Text>
                  <UnorderedList>
                    {(componentInfo.components || []).map((c: IStatusPageComponent) => (
                      <ListItem key={c.id}>{c.name}</ListItem>
                    ))}
                  </UnorderedList>
                  <FormLabel>Reassign</FormLabel>
                  <Select
                    options={options}
                    value={reassignGroup}
                    placeholder="Select a component group"
                    onChange={value => {
                      setReassignGroup(value as IfilterOption);
                    }}
                  />
                </Box>
              ) : (
                <Text>
                  Are you sure you want to delete the component group? You cannot undo the action
                  later
                </Text>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  callbackFn(hasComponents ? reassignGroup.value : undefined);
                  setReassignGroup({ value: 0, label: '' });
                }}
                ml={3}
                disabled={reassignGroup.value === 0 && hasComponents}
              >
                Delete anyway
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </ChakraProvider>
  );
};

type ValueAction = (value: number) => void;

export function ComponentsOrGroup({
  componentInfo,
  index,
  editComponent,
  type,
  editGroup,
  deleteGroup,
  deleteComponent,
  groupOptions,
  isEdit,
  componentCount,
  canEdit,
  selectedTeam,
}: {
  componentInfo: IStatusPageGroup;
  index: number;
  editComponent: (i: number, j?: number) => void;
  type: string;
  editGroup: ValueAction;
  deleteGroup: (i: number, j?: number) => void;
  deleteComponent: ValueAction;
  groupOptions: IfilterOption[];
  isEdit: boolean;
  componentCount: number;
  canEdit: boolean;
  selectedTeam: string;
}) {
  const [showCtas, setShowCtas] = React.useState(false);
  const [showCtas_, setShowCtas_] = React.useState<number | undefined>();

  const { isOpen, onToggle } = useDisclosure();
  const { data: serviceList } = useServiceList(selectedTeam, 1000);

  const serviceListData = useServiceListOptions(serviceList);

  const [toDelete, setToDelete] = React.useState<
    { name: string; id: number; type: string } | undefined
  >();

  const showCtasOnMouseEnter = () => {
    setShowCtas(true);
  };
  const hideCtasOnMouseLeave = () => {
    if (showCtas) setShowCtas(false);
  };

  const showCtasOnMouseEnter_ = (index: number) => {
    setShowCtas_(index);
  };

  const hideCtasOnMouseLeave_ = () => {
    setShowCtas_(undefined);
  };

  const discardDelete = () => {
    setToDelete(undefined);
  };
  const serviceForStatus = serviceListData.find(
    service => componentInfo.serviceID === service.value,
  );

  return (
    <VStack width={'100%'}>
      <HStack
        width={'100%'}
        onMouseEnter={() => {
          showCtasOnMouseEnter();
        }}
        onMouseLeave={() => {
          hideCtasOnMouseLeave();
        }}
        position={'relative'}
        _hover={{ background: THEME_COLORS.primary[200] }}
        px={6}
        borderTopWidth={1}
        borderTopColor={THEME_COLORS.secondary[200]}
        borderBottomWidth={isOpen ? 1 : 0}
        borderBottomColor={THEME_COLORS.secondary[200]}
      >
        <DoubleVertical />
        <HStack width={'100%'} py={4} px={6}>
          {componentInfo.type === COMPONENT_TYPE.COMPONENT ? (
            <HStack sx={{ justifyContent: 'space-between', width: '100%' }}>
              <Text fontSize={14}>{componentInfo.name}</Text>

              {serviceForStatus && (
                <Flex gap={2} p={1} backgroundColor={colors.colors.smoke_white} borderRadius={1}>
                  <>
                    <StatusServiceIcon />
                    <Text fontSize={14}>{serviceForStatus.label}</Text>
                  </>
                </Flex>
              )}
            </HStack>
          ) : (
            <>
              <Button
                bg={'transparent'}
                _focus={{ boxShadow: 'none' }}
                _hover={{ boxShadow: 'none' }}
                borderWidth={0}
                px={0}
                onClick={() => {
                  onToggle();
                  setShowCtas(false);
                }}
                width={'100%'}
              >
                <Flex
                  flex="1"
                  py={0}
                  gap={5}
                  textAlign="left"
                  alignItems={'center'}
                  width={'200px'}
                >
                  <Text fontWeight={800} fontSize={14} fontStyle={'normal'} color="#2D3748">
                    {componentInfo.name.length > 60
                      ? truncate(componentInfo.name, 60)
                      : componentInfo.name}
                    ({componentInfo?.components?.length || 0})
                  </Text>
                  {!isOpen ? <ChevronDownIcon color="blue" /> : <ChevronUpIcon color="blue" />}
                </Flex>
              </Button>
            </>
          )}
        </HStack>
        {showCtas && canEdit && (
          <ButtonGroup
            height="100%"
            position="absolute"
            right="1rem"
            top="70%"
            transform="translateY(-50%)"
            bgColor="transparent"
          >
            <Button
              variant="outline"
              colorScheme={'blue'}
              color={THEME_COLORS.brand.blue}
              onClick={e => {
                e.preventDefault();
                setToDelete({
                  name: componentInfo.name,
                  type: componentInfo.type,
                  id: componentInfo.id,
                });
              }}
            >
              Delete {type == COMPONENT_TYPE.GROUP ? 'Group' : ''}
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={e => {
                if (componentInfo.type === COMPONENT_TYPE.GROUP) {
                  editGroup(componentInfo.id);
                } else {
                  editComponent(componentInfo.id);
                }
              }}
            >
              Edit {componentInfo.type === COMPONENT_TYPE.GROUP ? 'Title' : ''}
            </Button>
          </ButtonGroup>
        )}
      </HStack>
      <VStack width={'100%'} pl={10} mt={'0px'}>
        <Collapse in={isOpen} animateOpacity style={{ width: '100%' }}>
          {componentInfo.type === 'group' && componentInfo.components?.length ? (
            <Droppable droppableId={`droppable${componentInfo.name}${index}`} type={`${index}`}>
              {(provided, snapshot) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getNestedComponentStyle(snapshot.isDraggingOver)}
                  width={'100%'}
                >
                  {componentInfo.components?.map((el, i, arr) => {
                    const serviceForStatusGroup = serviceListData.find(
                      service => el.serviceID === service.value,
                    );
                    return (
                      <Draggable
                        key={el.name}
                        draggableId={`${componentInfo.name + el.name.repeat(i + 1)}`}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <Flex
                            gap={5}
                            p={3}
                            key={i}
                            alignItems={'center'}
                            borderTopColor={'gray.200'}
                            borderTopStyle={'solid'}
                            borderBottom={arr.length - 1 == i ? 0 : 1}
                            borderBottomColor={'gray.200'}
                            borderBottomStyle={'solid'}
                            mt={i == 0 ? 0 : 2}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onMouseEnter={() => {
                              showCtasOnMouseEnter_(i);
                            }}
                            onMouseLeave={() => {
                              hideCtasOnMouseLeave_();
                            }}
                            justifyContent={'space-between'}
                            position={'relative'}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <HStack gap={2} width="100%">
                              <DoubleVertical />
                              <HStack sx={{ justifyContent: 'space-between', width: '100%' }}>
                                <Text fontSize={14}>{el.name}</Text>
                                {serviceForStatusGroup && (
                                  <Flex
                                    gap={2}
                                    p={1}
                                    backgroundColor={colors.colors.smoke_white}
                                    borderRadius={1}
                                  >
                                    <StatusServiceIcon />
                                    <Text fontSize={14}>{serviceForStatusGroup?.label}</Text>
                                  </Flex>
                                )}
                              </HStack>
                            </HStack>
                            {showCtas_ == i && canEdit ? (
                              <ButtonGroup
                                height="100%"
                                position="absolute"
                                right="1rem"
                                top="50%"
                                transform="translateY(-50%)"
                                bgColor="transparent"
                              >
                                <Button
                                  variant="outline"
                                  colorScheme={'blue'}
                                  color={THEME_COLORS.brand.blue}
                                  onClick={e => {
                                    setToDelete({
                                      name: el.name,
                                      type: el.type,
                                      id: el.id,
                                    });
                                  }}
                                >
                                  Delete
                                </Button>
                                <Button
                                  colorScheme="blue"
                                  mr={3}
                                  onClick={e => {
                                    e.preventDefault();
                                    editComponent(el.id, componentInfo.id);
                                  }}
                                >
                                  Edit
                                </Button>
                              </ButtonGroup>
                            ) : null}
                          </Flex>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          ) : (
            <Text>No component added to this group</Text>
          )}
        </Collapse>
      </VStack>

      <DeleteGroupAlertDialog
        isOpen={toDelete?.type === COMPONENT_TYPE.GROUP}
        onClose={discardDelete}
        groupOptions={groupOptions}
        callbackFn={(id?: number) => {
          deleteGroup(toDelete?.id || -1, id);
          setToDelete(undefined);
        }}
        componentInfo={componentInfo}
      />
      <AlertDialogComponent
        isOpen={toDelete?.type === COMPONENT_TYPE.COMPONENT}
        onClose={discardDelete}
        callbackFn={() => {
          deleteComponent(toDelete?.id || -1);
          setToDelete(undefined);
        }}
        msg={`Are you sure you want to delete the component? You cannot undo the action later`}
        title={`Delete ${toDelete?.name}`}
        isDelete={true}
      />
    </VStack>
  );
}
