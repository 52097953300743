const rgbToYIQ = ({ r, g, b }: any) => (r * 299 + g * 587 + b * 114) / 1000;

const hexToRgb = (hex = '#ffffff') => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : {
        r: 255,
        g: 255,
        b: 255,
      };
};

const hexToCSSRgba = (hex: string, opacity: number) => {
  const { r, g, b } = hexToRgb(hex);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const hslToHex = (h: number, s: number, l: number) => {
  const ln = l / 100;
  const a = (s * Math.min(ln, 1 - ln)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = ln - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0');
  };
  return `${f(0)}${f(8)}${f(4)}`;
};

const range = (newHash: number, min: number, max: number) => {
  const diff = max - min;
  return (((newHash % diff) + diff) % diff) + min;
};

const complimentHex = (hex: string) => {
  const padZero = (str: number) => `00${str}`.slice(-2);

  const { r, g, b } = hexToRgb(hex);
  return `#${padZero(255 - r)}${padZero(255 - g)}${padZero(255 - b)}`;
};

const colourHash = (str: string): [string, string, string, number] => {
  let hash = 0;
  for (let i = 0; i < str.length; i = i + 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  const [hue, sat, lit] = [range(hash, 0, 360), range(hash, 60, 65), range(hash, 55, 60)];

  // tslint:disable-next-line: no-bitwise

  const baseColour = `#${hslToHex(hue, sat, lit)}`;
  const hexCompliment = complimentHex(baseColour);
  const coat = rgbToYIQ(hexToRgb(hexCompliment)) >= 128 ? '#000' : '#fff';

  return [baseColour, coat, hexCompliment, hash];
};

export default colourHash;
export { rgbToYIQ, hexToRgb, hexToCSSRgba, range, hslToHex };
