import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  FileUploadService,
  PublicMessageService,
  StatusPageService,
} from '../../../../../core/services';
import { IStatusPage } from '../../../../../core/interfaces/IStatusPage';
import render from './render.index';
import './index.css';
import './renders.index.css';
import { API } from '../../../../../core/api';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { exception } from '../../../../../core/exception';
import { ErrorToastBlock, ToastContext, Theme, Para, SuccessToastBlock } from 'uie/components';
import { deepCopy, pick } from 'core';
import equal from 'fast-deep-equal/es6/react';
import { UserAccessContextValue, withUserAccess } from 'core/userAccess/UserAccessContext';

const { theme } = Theme;

interface IProps extends Pick<IAppState, 'organization'> {
  showToast: (success: boolean, msg: string) => void;
  userAccess: UserAccessContextValue;
}

interface IMessage {
  _id: string;
  message: string;
  status: string;
  time: Date;
  statusPageId: string;
  incidentId: string;
}
interface IState {
  componentState: 'init' | 'busy' | 'finished';
  editModal: 'header' | 'service' | 'page' | 'subscriptions' | 'theme' | 'change-message' | '';
  statusPage: IStatusPage | null;
  tempStatusPage: IStatusPage | null;
  showEditConfig: boolean;
  serviceStatus: any[];
  incidentHistory: any[];
  isStatusError: boolean;
  statusHeader: {
    name: string;
    status: string;
    status_text: string;
  };
  showUnsavedChangesModal: boolean;
  isDirty: boolean;
  messageToEdit: IMessage;
  deleteMesage: {
    messageId: string;
    incidentId: string;
  };
  loading: boolean;
}

export class InternalStatusPage extends Component<IProps, IState> {
  public _env = API.env;
  public statusPageId!: string;
  public SPService = new StatusPageService();
  public fileUploadService = new FileUploadService();

  public render = render;

  constructor(props: IProps) {
    super(props);

    this.state = {
      componentState: 'busy',
      editModal: '',
      statusPage: null,
      showEditConfig: false,
      tempStatusPage: null,
      serviceStatus: [],
      incidentHistory: [],
      isStatusError: false,
      statusHeader: {
        name: '',
        status: '',
        status_text: '',
      },
      showUnsavedChangesModal: false,
      isDirty: false,
      messageToEdit: {
        _id: '',
        message: 'string',
        status: '',
        time: new Date(0),
        statusPageId: '',
        incidentId: '',
      },
      deleteMesage: {
        messageId: '',
        incidentId: '',
      },
      loading: false,
    };
  }

  public componentDidMount() {
    this.statusPageId = (this.props as any).match.params.id || '';
    this.getStatusPage();
  }

  public onModalOpen = (
    editModal: 'header' | 'service' | 'page' | 'subscriptions' | 'theme' | 'change-message',
  ) => {
    this.setState(({ statusPage }) => ({
      editModal,
      tempStatusPage: deepCopy({ ...(statusPage as any) }),
    }));
  };

  public onModalCloseWithoutSave = () => {
    this.setState(({ tempStatusPage }) => ({
      statusPage: tempStatusPage,
      tempStatusPage: null,
    }));
  };

  public getStatus = async () => {
    const { statusPage } = this.state;
    if (!statusPage || statusPage.url.length === 0) {
      return;
    }
    this.setState({ loading: true });
    try {
      const response = await this.SPService.getStatus(
        `${this._env === 'dev' ? 'http' : 'https'}://${statusPage.url}` as string,
      );

      if (!response.data) {
        throw new Error('no data');
      }

      this.setState({
        serviceStatus: response.data.data.service_status,
        incidentHistory: response.data.data.incident_history,
        statusHeader: response.data.data.status_header,
      });
    } catch (err: any) {
      exception.handle('E_GET_ALL_STATUS_PAGES', err);
      this.setState({ isStatusError: true });
    } finally {
      this.setState({ loading: false });
    }
  };

  public getStatusPage = async () => {
    try {
      const {
        data: { data: statusPage },
      } = await this.SPService.getStatusPage(this.statusPageId);
      this.setState(
        {
          statusPage: statusPage,
          componentState: 'finished',
        },
        () => {
          this.getStatus();
        },
      );
    } catch (err: any) {
      exception.handle('E_GET_SINGLE_STATUS_PAGE', err);
    }
  };

  public updateStatusPage = async () => {
    const { statusPage } = this.state;

    try {
      await this.SPService.updateStatusPage(statusPage as IStatusPage);
      this.setState({ tempStatusPage: null });
      this.props.showToast(true, 'Successfully updated');
    } catch (err: any) {
      exception.handle('E_UPDATE_STATUS_PAGE', err);
      this.props.showToast(false, 'Error While updating. Please try again.');
    }
  };

  public setUnsavedChanges = (showUnsavedChangesModal: boolean) =>
    this.setState({ showUnsavedChangesModal });
  public closeUnsavedChangesModal = () => this.setState({ showUnsavedChangesModal: false });
  public onDiscardChanges = () => {
    this.setState({ showUnsavedChangesModal: false, editModal: '', isDirty: false });
    if (this.state.statusPage && this.state.tempStatusPage) {
      if (
        !equal(
          pick(this.state.statusPage, 'themeColor'),
          pick(this.state.tempStatusPage, 'themeColor'),
        )
      ) {
        this.onModalCloseWithoutSave();
      }
    }
  };

  checkAndSetDirty = () => {
    if (!this.state.isDirty) this.setState({ isDirty: true });
  };

  checkDirtyAndCloseModal = () => {
    if (this.state.isDirty) {
      this.setUnsavedChanges(true);
    } else {
      this.setState({
        isDirty: false,
        editModal: '',
      });
    }
  };

  public hideEditModal = (isASave?: boolean, pageConfig?: any) => {
    this.setState({ editModal: '', isDirty: false });
    if (isASave) {
      this.setState(({ statusPage }) => {
        statusPage!.pageConfig = pageConfig;
        return { statusPage };
      });
      this.updateStatusPage();
    }
  };

  public hideNewStatusPageModal = () => {
    this.setState({ editModal: '', isDirty: false });
    this.getStatusPage();
  };

  public hideThemeModal = (isASave?: boolean) => {
    this.setState({ editModal: '', isDirty: false });
    !isASave ? this.onModalCloseWithoutSave() : this.updateStatusPage();
  };
  public messageEditModal = (message: IMessage) => {
    if (message) {
      this.setState({ messageToEdit: message });
      this.onModalOpen('change-message');
    }
  };

  public deleteMessage = async (confirmation: boolean) => {
    const _PMService = new PublicMessageService({
      incidentId: this.state.deleteMesage.incidentId,
    });
    this.setState({ deleteMesage: { messageId: '', incidentId: '' } });
    let toastBool = true,
      toastMessage = 'Successfully Deleted';
    if (confirmation) {
      try {
        await _PMService.deleteMessage(this.state.deleteMesage.messageId);
      } catch (err) {
        toastBool = false;
        toastMessage = 'Error While Deleting. Please try again.';
      } finally {
        this.props.showToast(toastBool, toastMessage);
        this.getStatus();
      }
    }
  };
  public editMessageSubmit = async (success: boolean, successMessage: string) => {
    if (success) {
      this.setState({
        messageToEdit: {
          _id: '',
          message: '',
          status: '',
          time: new Date(0),
          statusPageId: '',
          incidentId: '',
        },
        editModal: '',
        isDirty: false,
      });
      this.getStatus();
    }
    this.props.showToast(success, successMessage);
  };
}

const InternalStatusPageComponent = connect((state: IAppState) => ({
  organization: state.organization,
}))(withUserAccess(InternalStatusPage));

const InternalStatusPageHOC = (props: IProps) => {
  const _createToast = ToastContext();

  const showToast = (success: boolean, msg: string) => {
    if (success) {
      _createToast(
        <SuccessToastBlock style={{ backgroundColor: theme.success.light }} maxWidth="250px">
          <Para fontWeight={400} style={{ whiteSpace: 'pre-wrap', textTransform: 'capitalize' }}>
            {msg}
          </Para>
        </SuccessToastBlock>,
        3000,
      );
    } else {
      _createToast(
        <ErrorToastBlock style={{ backgroundColor: theme.danger.light }} maxWidth="250px">
          <Para fontWeight={400} style={{ whiteSpace: 'pre-wrap', textTransform: 'capitalize' }}>
            {msg}
          </Para>
        </ErrorToastBlock>,
        3000,
      );
    }
  };

  return <InternalStatusPageComponent {...props} showToast={showToast} />;
};

export default InternalStatusPageHOC;
