import {
  REQUEST_INTEGRATIONS,
  REQUEST_INTEGRATIONS_SUCCESS,
  REQUEST_INTEGRATIONS_FAIL,
} from '../../const/public';
import { INITIAL_STATE } from '../state';
import { DEFAULT_ERROR } from '../../const/defaults';

const initialIntegrations = INITIAL_STATE.integrations;

const appIntegrations = (state = initialIntegrations, action: any) => {
  switch (action.type) {
    case REQUEST_INTEGRATIONS:
      return {
        ...state,
        ...{ error: { ...DEFAULT_ERROR } },
      };
    case REQUEST_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        ...{ i: action.payload.integrations, error: { ...DEFAULT_ERROR } },
      };
    case REQUEST_INTEGRATIONS_FAIL:
      return {
        ...state,
        ...{ i: [], error: { type: REQUEST_INTEGRATIONS_FAIL, reason: action.payload } },
      };
    default:
      return state;
  }
};

export default appIntegrations;
