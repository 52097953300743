import { createStandaloneToast } from '@chakra-ui/react';
import Axios from 'axios';
import { API } from 'core';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys, reactQueryConfig } from '../constant';
import { ICreateJiraCloudAccountProps } from '../../../../../../../core/interfaces/IJiraNew';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/service.reactqery';

const createJiraCloudAccount = ({
  accounts_name,
  jiraClientKey,
  teamsIds,
  account_link,
  is_all_teams_configured,
}: ICreateJiraCloudAccountProps) => {
  return Axios.post(
    `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud/new`,
    {
      account_name: accounts_name,
      jira_client_key: jiraClientKey,
      team_ids: teamsIds,
      account_link,
      is_all_teams_configured,
    },
  );
};
const toast = createStandaloneToast();

const useCreateJiraCloudAccount = () => {
  const queryClient = useQueryClient();

  return useMutation([queryKeys.CREATEJIRACLOUDSACCOUNTS], createJiraCloudAccount, {
    ...reactQueryConfig,
    onSuccess: () => {
      queryClient.refetchQueries([queryKeys.GETALLJIRACLOUDACCOUNTS]);
      reactQueryConfigSuccess('Jira Cloud Account created successfully!');
    },
    onError: (error: any) => {
      const description =
        error?.response?.data?.meta?.error_message || 'Failed to create Jira Cloud Account';
      reactQueryConfigError({ description: description, title: 'Create Jira Account' });
    },
  });
};
export { useCreateJiraCloudAccount };
