import styled, { keyframes } from 'styled-components';
import SimpleMDEEditor, { SimpleMDEEditorProps } from '@SquadcastHub/react-simplemde-editor';
import { renders } from './helper';

const blink = keyframes`
  50% {
    background-color: transparent;
  }
`;
const calcShadows = (props: any) =>
  props.error
    ? `0 0 0 1px ${props.theme.danger.default}`
    : `0 0 0 1px ${props.theme.primary.default}`;

export interface IProps extends SimpleMDEEditorProps {
  height?: string | '100%';
  width?: string | '100%';
  padding?: string | '16px';
  error?: boolean;
  fontFamily?: string | 'theme.fontFamily';
  monoFontFamily?: string | 'theme.monospaceFontFamily';
  hideborders?: string | false;
  inputref?: any;
  minHeight?: string;
  maxHeight?: string;
}

export const MDE = styled(SimpleMDEEditor)<IProps>`
  ${renders()};
  height: ${props => props.height || 'auto'};
  width: ${props => props.width || '100%'};
  font-family: ${props => props.fontFamily || props.theme.fontFamily};
  box-shadow: 0 0 0 1px
    ${props => (props.error ? props.theme.danger.default : props.theme.shades.lightGrey)};
  ${props => (props.hideborders ? 'box-shadow: none;' : '')}
  border: none;
  overflow-y: unset;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: ${props => props.theme.shades.white};
  outline: none;
  transition: 0.17s greyscale, 0.17s box-shadow, 0.17s filter, 0.17s background ease;
  &:hover,
  &:focus {
    box-shadow: ${calcShadows};
    ${props => (props.hideborders ? 'box-shadow: none;' : '')}
  }
  &:focus-within {
    box-shadow: ${calcShadows};
    ${props => (props.hideborders ? 'box-shadow: none;' : '')}
  }
  &.snippet-focus {
    box-shadow: ${calcShadows};
    ${props => (props.hideborders ? 'box-shadow: none;' : '')}
  }
  & > textarea:focus + div {
    box-shadow: ${calcShadows};
    ${props => (props.hideborders ? 'box-shadow: none;' : '')}
  }
  .cm-s-easymde .cm-comment {
    font-family: ${props => props.monoFontFamily || props.theme.monoFontFamily};
    font-size: 16px;
  }
  .CodeMirror {
    font-family: ${props => props.fontFamily || props.theme.fontFamily};
    min-height: ${props => props.minHeight || '144px'};
    height: ${props => props.height || '100%'};
    color: #000;
    direction: ltr;
  }
  .CodeMirror pre {
    padding: 0 4px;
    font-family: ${props => props.fontFamily || props.theme.fontFamily};
  }
  .cm-fat-cursor-mark {
    background-color: rgba(20, 255, 20, 0.5);
    -webkit-animation: ${blink} 1.06s steps(1) infinite;
    -moz-animation: ${blink} 1.06s steps(1) infinite;
    animation: ${blink} 1.06s steps(1) infinite;
  }
  .cm-animate-fat-cursor {
    width: auto;
    border: 0;
    -webkit-animation: ${blink} 1.06s steps(1) infinite;
    -moz-animation: ${blink} 1.06s steps(1) infinite;
    animation: ${blink} 1.06s steps(1) infinite;
    background-color: #7e7;
  }
  .CodeMirror pre {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-width: 0;
    background: 0 0;
    font-family: ${props => props.fontFamily || props.theme.fontFamily};
    font-size: inherit;
    margin: 0;
    white-space: pre-wrap;
    word-wrap: normal;
    line-height: inherit;
    color: inherit;
    z-index: 2;
    position: relative;
    overflow: visible;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-variant-ligatures: contextual;
    font-variant-ligatures: contextual;
  }
  .EasyMDEContainer .CodeMirror-scroll {
    overflow: scroll !important;
    margin-bottom: -30px;
    margin-right: -30px;
    padding-bottom: 30px;
    height: ${props => props.height ?? '100%'} !important;
    min-height: ${props => props.minHeight ?? '144px'} !important;
    outline: 0;
    position: relative;
  }
  .CodeMirror:not(.CodeMirror-fullscreen) > .CodeMirror-scroll {
    max-height: ${props => props.maxHeight ?? '200px'};
  }
  .run-editor-snippet-title {
    padding: 5px 0;
    font-size: 24px;
    border: none;
    border-radius: 3px;
    width: 100%;
    margin-bottom: 5px;
    font-family: ${props => props.fontFamily || props.theme.fontFamily};
  }
  .edit-code-block > pre > code {
    font-family: ${props => props.monoFontFamily || props.theme.monoFontFamily};
    font-size: 16px;
    margin: 0;
  }
  .view-line > * {
    font-family: ${props => props.fontFamily || props.theme.fontFamily};
  }
  .code-lang {
    color: var(--shades-white);
    font-size: 14px;
    font-family: ${props => props.monoFontFamily || props.theme.monoFontFamily};
    margin: 5px;
  }
  .pre-code {
    font-family: ${props => props.monoFontFamily || props.theme.monoFontFamily};
    margin-right: 20px;
    width: 105%;
  }

  & > .EasyMDEContainer {
    height: ${props => props.height || '100%'};
    min-width: ${props => props.width || '100%'};
    padding: ${props => props.padding || '0px'};
    padding-right: 0;
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  .CodeMirror-vscrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .CodeMirror-vscrollbar::-webkit-scrollbar-track {
    background: transparent;
  }
  .CodeMirror-vscrollbar::-webkit-scrollbar-thumb {
    background: ${props => props.theme.shades.smoke};
    border-radius: 5px;
    border: 3px solid ${props => props.theme.shades.white};
  }
  .editor-toolbar button.heading-1::before {
    content: 'Heading ';
  }
  .editor-toolbar button.heading-1 {
    font-size: 1.71429em;
    font-style: inherit;
    line-height: 1.16667;
    font-weight: 600;
    color: var(--ds-text, var(--ds-text, #172b4d));
    padding-left: 10px !important;
    letter-spacing: -0.01em;
    padding: 6px 10px 5px;
    text-align: start !important;
  }
  .editor-toolbar button.heading-1:after {
    content: ' 1';
  }

  .editor-toolbar button.heading-2:before {
    content: 'Heading ';
  }
  .editor-toolbar button.heading-2 {
    font-size: 1.42857em;
    font-style: inherit;
    line-height: 1.2;
    padding-left: 10px !important;
    font-weight: 600;
    color: var(--ds-text, var(--ds-text, #172b4d));
    letter-spacing: -0.008em;
    padding: 6px 10px 5px;
    text-align: start !important;
  }
  .editor-toolbar button.heading-2:after {
    content: '  2';
  }
  .editor-toolbar button.heading-3:before {
    content: 'Heading ';
  }
  .editor-toolbar button.heading-3 {
    font-size: 1.14286em;
    font-style: inherit;
    line-height: 1.25;
    font-weight: 600;
    color: var(--ds-text, var(--ds-text, #172b4d));
    padding-left: 10px !important;
    letter-spacing: -0.006em;
    padding: 6px 10px 5px;
    text-align: start !important;
  }
  .editor-toolbar button.heading-3:after {
    content: '  3';
  }
  .editor-toolbar button.heading-4::before {
    content: 'Heading';
  }
  .editor-toolbar button.heading-4 {
    font-size: 1em;
    font-style: inherit;
    line-height: 1.14286;
    padding-left: 10px !important;
    font-weight: 600;
    color: var(--ds-text, var(--ds-text, #172b4d));
    letter-spacing: -0.003em;
    padding: 6px 10px 5px;
    text-align: start !important;
  }

  .editor-toolbar button.heading-4:after {
    content: ' 4';
  }
  .editor-toolbar button.heading-5 {
    font-size: 0.857143em;
    font-style: inherit;
    line-height: 1.33333;
    padding-left: 10px !important;
    font-weight: 600;
    color: var(--ds-text, var(--ds-text, #172b4d));
    padding: 6px 10px 5px;
    text-transform: none;
    text-align: start !important;
  }

  .active-easymde {
    background-color: #172b4d !important;
    color: #fff;
  }
  .editor-toolbar button.heading-5:after {
    content: ' 5';
  }

  .editor-toolbar button.heading-5:before {
    content: 'Heading';
  }
  .editor-toolbar button.heading-6 {
    font-size: 0.785714em;
    font-style: inherit;
    line-height: 1.45455;
    padding-left: 10px !important;
    font-weight: 700;
    color: var(--ds-text, var(--ds-text, #172b4d));
    padding: 6px 10px 5px;
    text-align: start !important;
  }
  .editor-toolbar button.heading-6:after {
    content: ' 6';
  }

  .editor-toolbar button.heading-6:before {
    content: 'Heading';
  }
  .editor-toolbar button.normal-text {
    text-align: start !important;
    padding-left: 10px !important;
    padding: 6px 10px 5px;
    color: var(--ds-text, var(--ds-text, #172b4d));
  }
  .editor-toolbar button.normal-text:after {
    content: ' Normal Text';
  }

  .easymde-dropdown-content {
    display: block;
    visibility: hidden;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 8px;
    z-index: 2;
    top: 30px;
    min-width: 150px;
  }
  .easymde-dropdown-content button {
    display: block !important;
    min-width: 50px;
    height: auto;
    width: 100% !important;
  }

  .easymde-dropdown:active .easymde-dropdown-content,
  .easymde-dropdown:focus .easymde-dropdown-content,
  .easymde-dropdown:focus-within .easymde-dropdown-content {
    visibility: visible;
  }

  .fa-caret-down::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: rotate(0deg);
    transition: transform 0.25s ease-out;
    transition-delay: 0s;
    transition-duration: 0.25s;
    transition-property: transform;
    transition-timing-function: ease-out;
    color: #333;
    margin-left: 5px;
  }
  /* .replace-dropdown-icon::before {
    content:
  } */
  .editor-toolbar .heading-1-change::before,
  .editor-toolbar .heading-2-change::before,
  .editor-toolbar .heading-3-change::before,
  .editor-toolbar .heading-4-change::before,
  .editor-toolbar .heading-5-change::before,
  .editor-toolbar .heading-6-change::before {
    content: 'Heading';
  }
  .editor-toolbar .normal-text-change::before,
  .fa-normal-text::after {
    content: 'Normal';
  }
  .editor-toolbar .heading-1-change::after {
    content: ' 1';
  }
  .editor-toolbar .heading-2-change::after {
    content: ' 2';
  }
  .editor-toolbar .heading-3-change::after {
    content: ' 3';
  }
  .editor-toolbar .heading-4-change::after {
    content: ' 4';
  }
  .editor-toolbar .heading-5-change::after {
    content: ' 5';
  }
  .editor-toolbar .heading-6-change::after {
    content: ' 6';
  }
  .editor-toolbar button.easymde-dropdown {
    width: auto !important;
    min-width: 67.5px;
    padding: 5px !important;
    position: relative;
  }
  .editor-toolbar button:hover {
    border-radius: 0px;
    background: var(--ds-background-neutral-subtle-hovered, rgba(9, 30, 66, 0.08)) !important;
    text-decoration: inherit;
    transition-duration: 0s, 0.15s;
    border-color: transparent !important;
  }
  .editor-toolbar button.easymde-dropdown:focus {
    background-color: #172b4d !important;
    color: #fff;
  }
  .fa {
    color: #2d3964;
  }
  .editor-toolbar button.active {
    border-color: none !important;
    background-color: rgb(13, 33, 73) !important;
    border-color: transparent !important;
    color: #f4f5f7 !important;
  }
  .editor-toolbar button.active .fa {
    color: #f4f5f7 !important;
  }
  .editor-toolbar .easymde-dropdown:active {
    background-color: rgb(37, 56, 88) !important;
    color: #f4f5f7;
  }
  #simplemde-editor-1-wrapper {
    overflow-y: unset !important;
    height: auto;
  }
  .editor-toolbar .easymde-dropdown:active .fa,
  .editor-toolbar .easymde-dropdown:focus .fa,
  .editor-toolbar .easymde-dropdown:focus-within .fa {
    color: #f4f5f7 !important;
  }
  .EasyMDEContainer .editor-toolbar {
    background: #f2f2f2;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .EasyMDEContainer .editor-toolbar:before,
  .editor-toolbar:after {
    display: unset;
  }
  .editor-preview pre {
    background: #eee !important;
    margin-bottom: 10px !important;
  }
`;
