import { useUserAccess } from 'core/userAccess/UserAccessContext';
import React from 'react';
import { EntityType, hasReadAccessToEntity, JSXResponseForEntity } from '../helpers';

type NoActionMatchedProps = {
  action: string;
  entityName: string | undefined;
};
const ResponsesForNoActionMatched: React.FC<
  Omit<JSXResponseForEntity, 'getEntityComponent'> & NoActionMatchedProps
> = ({
  log,
  logMessage,
  action,
  entityName,
  getEntityComponentWithTruncatedName,
  getEntityName,
}) => {
  const acceptableEntityTypesForPopover = ['user', 'service'];
  const userHasReadSquadsPermission = useUserAccess().hasReadAccess('squads');

  if (log.actionType === 'tags_modified') logMessage = action;

  const splitWord = logMessage.includes(' to ') ? ' to ' : ' by ';
  const isRuleActivity =
    logMessage.startsWith('Routing rule executed') ||
    logMessage.startsWith('Suppression rule executed') ||
    log.actionType === 'suppressed';
  if (isRuleActivity) {
    entityName = getEntityName(log.assignedTo as EntityType, log.id);
  } else if (log.actionType === 'tags_modified') {
    entityName = logMessage.split(splitWord)[1].split('\n')[0];
  } else {
    entityName = logMessage.split(splitWord)[1];
  }
  if (
    action === 'manually_marked_negative_transient_alert_feedback' ||
    action === 'manually_marked_positive_transient_alert_feedback'
  ) {
    logMessage = log.reason;
  }
  if (log.actionType === 'auto_resolved') {
    return <>{entityName}</>;
  }

  const canNavigateToEntity =
    entityName !== 'Deleted Entity' && hasReadAccessToEntity(log.assignedTo as EntityType);
  return canNavigateToEntity ? (
    getEntityComponentWithTruncatedName({
      componentType: acceptableEntityTypesForPopover.includes(log.assignedTo)
        ? 'linkWPopover'
        : log.assignedTo === 'squad'
        ? userHasReadSquadsPermission
          ? 'linkWOPopover'
          : 'simple'
        : 'linkWOPopover',
      name: entityName,
      type: log.assignedTo as EntityType,
      id: log.id,
      popoverPlacement: 'left',
    })
  ) : (
    <>{entityName}</>
  );
};

export default ResponsesForNoActionMatched;
