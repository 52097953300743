import { EyeIcon, IncidentReminderIcon, IncidentsIcon, MentionIcon } from 'icons';
import { AddRespondersGrayIcon, DelayedNotificationsIcon } from 'library/icons';
import { FC } from 'react';

export type FILTER_TYPE = {
  cause: SELECT_BOX_OPTIONS[];
  status: SELECT_BOX_OPTIONS[];
  type: SELECT_BOX_OPTIONS[];
};

export type SELECT_BOX_OPTIONS = {
  label: string;
  value: string;
};

export const EMPTY_FILTER_STATE: FILTER_TYPE = {
  cause: [],
  status: [],
  type: [],
};

const enum INCIDENT_CAUSE {
  INCIDENT_ALERT = 'Incident alert',
  INCIDENT_ACK_ALERT = 'Acknowledgement reminder alert',
  INCIDENT_WATCHER_ALERT = 'Incident watcher notifications',
  MENTION_NOTIF_ALERT = '@ mentions',
  INCIDENT_RETRIGGER = 'Incident retrigger',
  DELAYED_NOTIFICATION = 'Delayed Notifications',
  RESPONDER_NOTIFICATION = 'Responder Notifications',
}

const NOTIFICATION_OBJECT = {
  incident_alert: { toolTip: INCIDENT_CAUSE.INCIDENT_ALERT, icon: IncidentsIcon },
  incident_retrigger: { toolTip: INCIDENT_CAUSE.INCIDENT_RETRIGGER, icon: IncidentsIcon },
  incident_reminder_alert: {
    toolTip: INCIDENT_CAUSE.INCIDENT_ACK_ALERT,
    icon: IncidentReminderIcon,
  },
  incident_watcher_alert: {
    toolTip: INCIDENT_CAUSE.INCIDENT_WATCHER_ALERT,
    icon: EyeIcon,
  },
  note_mention: { toolTip: INCIDENT_CAUSE.MENTION_NOTIF_ALERT, icon: MentionIcon },
  digest_notification: {
    toolTip: INCIDENT_CAUSE.DELAYED_NOTIFICATION,
    icon: DelayedNotificationsIcon,
  },
  responder_notification: {
    toolTip: INCIDENT_CAUSE.RESPONDER_NOTIFICATION,
    icon: AddRespondersGrayIcon,
  },
};

export const NOTIFICATION_CAUSE: Record<
  string,
  {
    toolTip: string;
    icon: FC;
  }
> = new Proxy(NOTIFICATION_OBJECT, {
  get: (
    target: Record<
      string,
      {
        toolTip: string;
        icon: FC;
      }
    >,
    key: string,
  ) => {
    return target[key]
      ? target[key]
      : {
          toolTip: (key.charAt(0).toUpperCase() + key.slice(1)).replace('_', ' '),
          icon: IncidentsIcon,
        };
  },
});

export const CAUSE_FILTER_OPTIONS = [
  {
    label: INCIDENT_CAUSE.INCIDENT_ALERT,
    value: 'incident_alert',
  },
  {
    label: INCIDENT_CAUSE.INCIDENT_RETRIGGER,
    value: 'incident_retrigger',
  },
  {
    label: INCIDENT_CAUSE.INCIDENT_ACK_ALERT,
    value: 'incident_reminder_alert',
  },
  {
    label: INCIDENT_CAUSE.INCIDENT_WATCHER_ALERT,
    value: 'incident_watcher_alert',
  },
  {
    label: INCIDENT_CAUSE.MENTION_NOTIF_ALERT,
    value: 'note_mention',
  },
  {
    label: INCIDENT_CAUSE.DELAYED_NOTIFICATION,
    value: 'digest_notification',
  },
  {
    label: INCIDENT_CAUSE.RESPONDER_NOTIFICATION,
    value: 'responder_notification',
  },
];

export const TYPE_FILTER_OPTIONS = [
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'E-mail',
    value: 'email',
  },
  {
    label: 'SMS',
    value: 'sms',
  },
  {
    label: 'Push',
    value: 'push',
  },
];

export const STATUS_FILTER_OPTIONS = [
  {
    label: 'scheduled',
    value: 'scheduled',
  },
  {
    label: 'dispatched',
    value: 'dispatched',
  },
  {
    label: 'sent',
    value: 'sent',
  },
  {
    label: 'delivered',
    value: 'delivered',
  },
  {
    label: 'canceled',
    value: 'canceled',
  },
  {
    label: 'failed',
    value: 'failed',
  },
];
