import styled from 'styled-components';
import { slideDownOpen, slideUpClose } from '../../utils/Animations';

export interface IDrop {
  dropdownHeight?: string | '300px';
  dropdownWidth?: string | '200px';
  dropdownMaxHeight?: string | '300px';
  dropdownMaxWidth?: string | '200px';
  dropdownTop?: string | '10px';
  dropdownPadding?: string | '0';
  dropdownExtend?: number | 0;
  dropdownOffset?: string | '0px';
}

export const Drop = styled.div<IDrop>`
  left: ${props => props.dropdownOffset || '0px'};
  height: calc(
    ${props => props.dropdownHeight || '300px'} +
      ${props => `${props.dropdownExtend ? `${props.dropdownExtend} + 32px` : ''}`};
  );
  max-height: calc(
    ${props => props.dropdownHeight || '300px'} +
      ${props => `${props.dropdownExtend ? `${props.dropdownExtend} + 32px` : ''}`};
  );
  width: ${props => props.dropdownWidth || '200px'};
  max-width: ${props => props.dropdownWidth || '200px'};
  margin-top: ${props => props.dropdownTop || '10px'};
  background-color: ${props => props.theme.shades.white};
  display: none;
  border-radius: 3px;
  box-shadow: ${props => props.theme.shadow.default};
  position: absolute;
  padding: ${props => props.dropdownPadding || '0'};
  z-index: 999;
  animation-duration: 0.16s;
  animation-fill-mode: both;
  animation-name: ${slideDownOpen};

  &.close {
    animation-name: ${slideUpClose};
  }

  & > .renders {
    height: ${props => props.dropdownHeight || '300px'};
    max-height: ${props => props.dropdownMaxHeight || '300px'};
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 3px;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      min-height: 64px;
      background: ${props => props.theme.shades.smoke};
      border-radius: 5px;
      border: 3px solid ${props => props.theme.shades.white};
    }
  }

  &:focus {
    outline: none;
  }
`;

export interface IShell {
  isOpen?: boolean;
  isFocused?: boolean;
  shellWidth?: string | '200px';
  shellHeight?: string | 'fit-content';
  error?: boolean;
  shellPadding?: string | '16px';
}

export const Shell = styled.div<IShell>`
  width: fit-content;
  height: fit-content;
  border: none;
  display: block;
  position: relative;

  & > .hook {
    cursor: pointer;
    width: ${props => props.shellWidth || 'fit-content'};
    max-width: ${props => props.shellWidth || 'fit-content'};
    height: ${props => props.shellHeight || 'fit-content'};
    background-color: transparent;
    padding: ${props => props.shellPadding || '0'};
    box-sizing: border-box;
    border-radius: 3px;
    transition: 0.17s greyscale, 0.17s box-shadow, 0.17s filter, 0.17s background ease;
    opacity: 1;

    &:focus,
    &:hover {
      background-color: transparent !important;
      opacity: 0.72;
    }

    &:disabled,
    &.disabled {
      cursor: no-drop !important;
      opacity: 0.33;
      color: ${props => props.theme.font.default};
    }

    &:focus-visible {
      outline: none;
    }
  }

  &:focus-within ${Drop} {
    display: block;
  }

  &:focus {
    outline: none;
  }
`;
