import {
  REQUEST_USER_ORG,
  REQUEST_USER_ORG_FAIL,
  REQUEST_USER_ORG_SUCCESS,
} from '../../const/organization';
import { INITIAL_STATE } from '../state';
import { RESET_STATE } from '../../const/init';
import { IOrganization } from '../../interfaces/IOrganization';

const initialUserOrganizations = INITIAL_STATE.userOrganizations;

const userOrganizations = (state = initialUserOrganizations, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ action: RESET_STATE, o: [], error: { type: '', reason: {} } },
      };
    case REQUEST_USER_ORG:
      return {
        ...state,
        ...{ action: REQUEST_USER_ORG, error: { type: '', reason: {} } },
      };
    case REQUEST_USER_ORG_SUCCESS: {
      const organizations: IOrganization[] = action.payload.organizations;
      return {
        ...state,
        ...{
          action: REQUEST_USER_ORG_SUCCESS,
          o: organizations.sort((a, b) => Number(a.deActivated) - Number(b.deActivated)),
          error: { type: '', reason: {} },
        },
      };
    }
    case REQUEST_USER_ORG_FAIL:
      return {
        ...state,
        ...{
          action: REQUEST_USER_ORG_FAIL,
          o: [],
          error: { type: REQUEST_USER_ORG_FAIL, reason: action.payload },
        },
      };
    default:
      return state;
  }
};

export default userOrganizations;
