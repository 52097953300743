import { ChakraProvider } from '@chakra-ui/react';
import libraryTheme from 'library/theme';
import { Dropdown, DropdownProps } from 'library/atoms';

export function SelectOwnerDropdown<T>({
  applyChakraStyle,
  ...rest
}: DropdownProps & { applyChakraStyle?: boolean }) {
  return (
    <ChakraProvider resetCSS={applyChakraStyle} theme={libraryTheme}>
      <Dropdown {...rest} />
    </ChakraProvider>
  );
}
