import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API } from 'core';
import { queryKeys, reactQueryConfig } from '../constant';

export interface IValidateJiraKeyResponse {
  data: IValidateJiraDetail;
}

export interface IValidateJiraDetail {
  jira_client_key: string;
  account_url: string;
}

const validateJiraKey = (jiraClientKey: string) => async () => {
  const { data } = await axios.get<IValidateJiraKeyResponse>(
    `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud/new/validate-jira-client-key/${jiraClientKey}`,
    //{acountID}/projects
  );
  return data.data;
};

export const useValidateJiraKey = (jiraClientKey: string, enableValidate: boolean) => {
  return useQuery(
    [queryKeys.GETJIRAPROJECTSFORACCOUNT, jiraClientKey],
    validateJiraKey(jiraClientKey),
    {
      ...reactQueryConfig,
      enabled: enableValidate && !!jiraClientKey,
      retry: 1,
    },
  );
};
