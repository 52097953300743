import { RRule, Weekday } from 'rrule';

import {
  DayOfWeek,
  PeriodOptions,
  CustomPeriodUnit,
  ScheduleEvent,
  MockEvent,
  Participant,
  Maybe,
  MockParticipant,
  ScheduleOverride,
  LegacyScheduleEvent,
} from 'views/main/organization/schedules/graphql/types';
import {
  EventParticipant,
  INewSchedule,
  IOverridesDetail,
  IRotation,
  IRotationDetail,
  IRotationEventParticipant,
  IRotationEventParticipants,
  IScheduleDetail,
  IScheduleEvents,
  ISchedulesDetail,
  ITimeSlot,
} from '../interface/schedule';
import {
  getDateTime,
  getHoursDiff,
  getTwoWeekDateRange,
  getTZWTimeISO,
  getUTCDateTime,
} from './helpers.date';
import { getDurationForTimeStamp, IPatternParticipant } from './helpers.customrotations';
import {
  GetMockEventsQuery,
  GetOverrideParticipantsQuery,
} from 'views/main/organization/schedules/graphql/query';
import {
  schedulesColorHues,
  schedulesGapColor,
  schedulesOverrideBorderColor,
  schedulesOverrideColor,
} from '../constants/schedules.rotation-template';
import { IListEvent } from '../schedules.view/schedules.list/listView.table';
import { RotationViewType } from '../constants/schedules.rotation-type';
import { eventWidthThreshold, rotationGapID } from '../constants/schedules.view';
import { DateTime } from 'luxon';

/**
 * Adds 0 to the provided number to make it 2-digit
 * @param timeValue Any numeric value
 * @returns
 */
const getPaddedValue = (timeValue: number) => timeValue.toString().padStart(2, '0');

/**
 * Returns Day of week
 * @param weekdayIndex Weekday index starting from Sunday
 * @returns
 */
const mapWeekDayToText = (weekdayIndex: number) => {
  switch (weekdayIndex) {
    case 0:
      return DayOfWeek.Sunday;
    case 1:
      return DayOfWeek.Monday;
    case 2:
      return DayOfWeek.Tuesday;
    case 3:
      return DayOfWeek.Wednesday;
    case 4:
      return DayOfWeek.Thursday;
    case 5:
      return DayOfWeek.Friday;
    default:
      return DayOfWeek.Saturday;
  }
};

/**
 * Return RRule weekday for weekdays
 * @param weekdayIndex Weekday index starting from Monday
 * @returns
 */
const getWeekDay = (weekdayIndex: number) => {
  switch (weekdayIndex) {
    case 0:
      return RRule.MO;
    case 1:
      return RRule.TU;
    case 2:
      return RRule.WE;
    case 3:
      return RRule.TH;
    case 4:
      return RRule.FR;
    case 5:
      return RRule.SA;
    default:
      return RRule.SU;
  }
};

const mapWeekDays = (array: boolean[]) => {
  const weekDaysCopy = [...array];
  const firstDay = weekDaysCopy.shift();
  const weekDays: Weekday[] = [...weekDaysCopy, firstDay]
    .map((weekDay, weekDayIndex) => (weekDay ? getWeekDay(weekDayIndex) : undefined))
    .filter((wd): wd is Weekday => !!wd);

  return weekDays;
};

const getCycleDays = (daysOfWeeks: boolean[]) => {
  let label = '';
  const weekDaysLabel = [] as string[];
  const [firstActive, lastActive] = [daysOfWeeks.indexOf(true), daysOfWeeks.lastIndexOf(true)];
  const hasConsecutiveActiveDays = daysOfWeeks.slice(firstActive, lastActive).every(dow => dow);

  daysOfWeeks.forEach((dow, index) => {
    switch (index) {
      case 0:
        label = 'Sun';
        break;
      case 1:
        label = 'Mon';
        break;
      case 2:
        label = 'Tue';
        break;
      case 3:
        label = 'Wed';
        break;
      case 4:
        label = 'Thu';
        break;
      case 5:
        label = 'Fri';
        break;
      default:
        label = 'Sat';
    }

    if (dow) {
      weekDaysLabel.push(label);
    }
  });

  if (hasConsecutiveActiveDays) {
    const [first, last] = [weekDaysLabel[0], weekDaysLabel.slice(-1)];
    return `${first} - ${last}`;
  }
  return weekDaysLabel.join(', ');
};

const getEventFrequency = (rotation: IRotation) => {
  const [interval, customInterval] = [rotation.period, rotation.customPeriod?.periodUnit];

  let frequency = RRule.DAILY;

  if (interval !== 'custom') {
    switch (interval) {
      case PeriodOptions.Daily:
        frequency = RRule.DAILY;
        break;
      case PeriodOptions.Weekly:
        frequency = RRule.WEEKLY;
        break;
      case PeriodOptions.Monthly:
        frequency = RRule.MONTHLY;
    }
  } else {
    switch (customInterval) {
      case CustomPeriodUnit.Day:
        frequency = RRule.DAILY;
        break;
      case CustomPeriodUnit.Week:
        frequency = RRule.WEEKLY;
        break;
      case CustomPeriodUnit.Month:
        frequency = RRule.MONTHLY;
        break;
    }
  }
  return frequency;
};

const getEventInterval = (rotation: IRotation) => {
  const interval = rotation.period;
  let result = 1;
  if (interval !== PeriodOptions.Custom) {
    result = 1;
  } else {
    result = rotation.customPeriod?.periodFrequency ? rotation.customPeriod?.periodFrequency : 1;
  }
  return result;
};

const getEventCount = (rotation: IRotation) => {
  let result = {};
  if (rotation.ends && rotation?.endsAfterIterations) {
    result = { ...result, count: rotation?.endsAfterIterations };
  }
  if (rotation.period === PeriodOptions.None) {
    result = { ...result, count: 1 };
  }
  return result;
};

const getEventUntil = (rotation: IRotation) => {
  let result = {};
  if (rotation.ends && rotation?.endDate) {
    result = { ...result, until: rotation?.endDate };
  }
  return result;
};

const getEventByWeekday = (rotation: IRotation) => {
  let result = {};
  if (
    rotation?.period === PeriodOptions.Custom &&
    rotation?.customPeriod?.periodUnit === CustomPeriodUnit.Week &&
    rotation?.customPeriod.daysOfWeekFilter
  ) {
    result = {
      ...result,
      byweekday: mapWeekDays(rotation?.customPeriod.daysOfWeekFilter),
    };
  }
  return result;
};

const formatRotationToEvent = (
  rotations: IRotation[],
  timeZone: string,
  hideRotationBackgroundColor: boolean,
  isTemplate = false,
): IScheduleEvents => {
  return rotations.map((r, index) => {
    const [startHour, startMin] = r.shiftTimeSlot.startTime.split(':');
    const duration =
      getPaddedValue(Math.floor((r.shiftTimeSlot as ITimeSlot).duration / 60)) +
      ':' +
      getPaddedValue((r.shiftTimeSlot as ITimeSlot).duration % 60);

    const startDate = isTemplate
      ? getTwoWeekDateRange(r.startDate, timeZone)[0].toJSDate()
      : r.startDate;
    return {
      title: r.name,
      duration,
      rrule: {
        interval: getEventInterval(r),
        freq: getEventFrequency(r),
        dtstart: getTZWTimeISO(timeZone, startDate, Number(startHour), Number(startMin)),
        ...getEventByWeekday(r),
        ...getEventCount(r),
        ...getEventUntil(r),
      },
      participants: r.participantGroups ?? [],
      backgroundColor: hideRotationBackgroundColor ? '#FFFFFFFF' : r.color,
    };
  });
};

/**
 *
 * @param hr Time in hour
 * @param min Time in minute
 * @returns
 */
const calculateTimelineOffset = (hr: number, min: number) => {
  const hourEventWidth = 100 / 48;
  const totalHours = Math.round(hr + (min > 29 ? 0.5 : 0)) * 2;
  const offset = totalHours * hourEventWidth;
  return offset;
};

type ICalculateEventWidth = IRotationDetail[0] & {
  remainingDaysColCount: number;
  startTime: string;
  endTime: string;
  timeZone: string;
};

/**
 * Calculate event width for a 2 week view
 * @param param0
 * @returns
 */
const calculateEventWidth = ({
  remainingDaysColCount,
  startTime,
  endTime,
  timeZone,
}: ICalculateEventWidth) => {
  let width = 0,
    offset = 0;
  const totalEventChunk = 48,
    hourEventWidth = 100 / totalEventChunk;
  const totalHours = getHoursDiff(endTime, startTime, timeZone);
  const posTotalHr = Math.abs(totalHours ?? 0),
    totalMin = (posTotalHr - Math.floor(posTotalHr)) * 60 >= 30 ? 0.5 : 0,
    startTimeInfo = getUTCDateTime(startTime, timeZone),
    /** A single event block is divided into 48 chunks to accomodate the half hour visualization */
    startChunk = Math.round(startTimeInfo.hour + (startTimeInfo.minute >= 30 ? 0.5 : 0)) * 2,
    eventChunk = (posTotalHr + totalMin) * 2;

  offset = startChunk * hourEventWidth;
  const actualWidth = (eventChunk / totalEventChunk) * 100;
  const eventWidth = offset + actualWidth;
  const maxEventWidth = (remainingDaysColCount + 1) * 100;
  width = eventWidth > maxEventWidth ? maxEventWidth - offset : actualWidth;

  return { width, offset };
};

/**
 * Get the border color depending on the event color
 * @param eventColor color of the event
 */
const getEventBorderColor = (eventColor: string) => {
  if (eventColor === schedulesOverrideColor) {
    return schedulesOverrideBorderColor;
  }

  const colorWoOpacity = eventColor.substring(0, eventColor.length - 2);
  return `${colorWoOpacity}${schedulesColorHues[3]}`;
};

const mapRotationToEventObject = (
  rotation: {
    color?: string | null;
    startDate: Date;
    name: string;
    period: string;
    ID: number;
    endDate?: Date | null;
  },
  events:
    | NonNullable<ISchedulesDetail>[number]['rotations'][number]['events']
    | MockEvent[]
    | undefined,
  schedule: NonNullable<ISchedulesDetail>[number] | INewSchedule,
) => {
  return {
    events: [...(events || [])],
    color: rotation.color || '',
    scheduleName: schedule.name,
    rotationName: rotation.name,
    repeats: rotation.period,
    scheduleTimeZone: schedule.timeZone,
    startDate: rotation.startDate,
    endDate: rotation.endDate,
    rotationId: rotation.ID,
    scheduleId: (schedule as NonNullable<ISchedulesDetail>[number]).ID,
  };
};

const mapGapsToCalendarEvent = (
  gaps: { startTime: string; endTime: string }[],
  timeZone: string,
): IScheduleEvents => {
  return gaps.map(event => ({
    ID: 0,
    title: '',
    start: event.startTime,
    end: event.endTime,
    participants: [],
    backgroundColor: schedulesGapColor,
    borderColor: schedulesGapColor,
    textColor: 'white',
    scheduleName: '',
    repeats: '',
    scheduleTimeZone: timeZone,
    startDate: event.startTime,
    endDate: event.endTime,
    isAGap: true,
  }));
};

const mapOverridesToCalendarEvent = (
  overrides: NonNullable<IOverridesDetail>,
  scheduleName: string,
  timeZone: string,
  allParticipants: IPatternParticipant[],
): IScheduleEvents => {
  return overrides.map(o => {
    let eventParticipants: Array<any> | null | undefined = [];
    if (o.overrideWith?.participants) {
      eventParticipants = o.overrideWith?.participants?.map(participant =>
        mapParticipantToEventParticipant(participant, allParticipants),
      );
    }
    return {
      title: '',
      start: o.startTime,
      end: o.endTime,
      participants: [{ participants: eventParticipants }],
      backgroundColor: schedulesOverrideColor,
      borderColor: schedulesOverrideBorderColor,
      textColor: 'white',
      scheduleName: scheduleName,
      repeats: '',
      scheduleTimeZone: timeZone,
      startDate: o.startTime,
      endDate: o.endTime,
      isOverride: true,
      override: {
        enabled: true,
        ...(o as ScheduleOverride),
      },
    };
  });
};

const mapParticipantToEventParticipant = (
  participant: Participant | Maybe<MockParticipant> | IRotationEventParticipant,
  allParticipants: IPatternParticipant[],
  overrideInfo?: NonNullable<
    NonNullable<GetOverrideParticipantsQuery['schedule']>['overrides']
  >[number],
): EventParticipant => {
  const participantInfo = {
    name: allParticipants.find(p => p.id === participant?.ID)?.label,
    username: allParticipants.find(p => p.id === participant?.ID)?.username ?? '',
    ...(participant?.type === 'user'
      ? { timeZone: allParticipants.find(p => p.id === participant?.ID)?.timeZone }
      : {}),
    ...(participant?.type === 'squad'
      ? {
          members: allParticipants
            ?.find(p => p.id === participant.ID)
            ?.members?.map(member => ({
              name: allParticipants?.find(p => p.id === member)?.label,
            })),
        }
      : {}),
  };
  if (overrideInfo) {
    const originalParticipants = (
      overrideInfo.overriddenParticipant?.participants as IRotationEventParticipants
    )?.map(participant => ({
      name: allParticipants.find(p => p.id === participant.ID)?.label ?? '',
    }));
    return participant
      ? {
          ID: participant.ID,
          type: participant.type,
          isOverride: true,
          participant: {
            ...participantInfo,
            originalParticipants: originalParticipants,
          },
        }
      : ({} as EventParticipant);
  }

  /**
    If the override information is not passed, return the
    participant information with the name, the timezone (for
    the event details popover in 2 weeks view) and with
    the members if the participant is a squad
   */
  return participant
    ? {
        ID: participant.ID,
        type: participant.type,
        isOverride: false,
        participant: {
          ...participantInfo,
        },
      }
    : ({} as EventParticipant);
};

const mapEventObjectToCalendarEvent = (
  eventObj: any,
  allParticipants: IPatternParticipant[],
): IScheduleEvents => {
  return eventObj.events.map((event: ScheduleEvent | MockEvent) => {
    let eventParticipants: Array<any> | null | undefined = [];

    if ((event as ScheduleEvent).participants) {
      eventParticipants = (event as ScheduleEvent).participants?.map(participant =>
        mapParticipantToEventParticipant(participant, allParticipants),
      );
    } else {
      eventParticipants = (event as MockEvent).mockParticipants?.map(mockParticipant =>
        mapParticipantToEventParticipant(
          { ID: mockParticipant?.ID ?? '', type: mockParticipant?.type ?? '' },
          allParticipants,
        ),
      );
    }

    return {
      title: eventObj.rotationName,
      start: `${event.startTime}`,
      end: `${event.endTime}`,
      participants: [{ participants: eventParticipants }],
      backgroundColor: (event as LegacyScheduleEvent).isOverride
        ? schedulesOverrideColor
        : eventObj.color,
      borderColor: eventObj.color,
      textColor: (event as LegacyScheduleEvent).isOverride ? 'white' : undefined,
      scheduleName: eventObj.scheduleName,
      repeats: eventObj.repeats,
      scheduleTimeZone: eventObj.scheduleTimeZone,
      startDate: eventObj.startDate,
      endDate: eventObj.endDate,
      eventId: 'ID' in event ? event.ID : null,
      rotationId: eventObj.rotationId,
      scheduleId: eventObj.scheduleId,
      isOverride: (event as LegacyScheduleEvent).isOverride,
      override: {
        enabled: (event as LegacyScheduleEvent).isOverride ?? false,
        ...((event as LegacyScheduleEvent).override as ScheduleOverride),
      },
    };
  });
};

const mapSchedulesToCalendarEvents = (
  schedules: NonNullable<ISchedulesDetail>,
  allParticipants: IPatternParticipant[],
  includeOverrides: boolean,
): IScheduleEvents => {
  const scheduleEvents: any = [];
  const mappedScheduleEvents: any = [];
  schedules.forEach(schedule => {
    schedule?.rotations?.forEach(rotation => {
      scheduleEvents.push(mapRotationToEventObject(rotation, rotation.events, schedule));
    });
    includeOverrides &&
      schedule.overrides &&
      schedule.overrides.length > 0 &&
      mappedScheduleEvents.push(
        ...mapOverridesToCalendarEvent(
          schedule.overrides,
          schedule.name,
          schedule.timeZone,
          allParticipants,
        ),
      );
  });

  scheduleEvents.forEach((eventObj: any) => {
    mappedScheduleEvents.push(...mapEventObjectToCalendarEvent(eventObj, allParticipants));
  });
  return mappedScheduleEvents;
};

const mapMockEventsToCalendarEvents = (
  schedule: INewSchedule,
  mockEvents: GetMockEventsQuery['mockEvents']['mockEvents'] | undefined,
  allParticipants: IPatternParticipant[],
) => {
  const scheduleEvents: any = [];
  const mappedScheduleEvents: IScheduleEvents = [];
  schedule.rotations.forEach((rotation, index) => {
    scheduleEvents.push(
      mapRotationToEventObject({ ...rotation, ID: 0 }, mockEvents?.[index]?.events ?? [], schedule),
    );
  });
  scheduleEvents.forEach((eventObj: any) => {
    mappedScheduleEvents.push(...mapEventObjectToCalendarEvent(eventObj, allParticipants));
  });

  return mappedScheduleEvents;
};

const getRotationTime = (
  period: PeriodOptions,
  shiftSlot: IRotationDetail[number]['shiftTimeSlot'],
) => {
  switch (period) {
    case PeriodOptions.Daily:
    case PeriodOptions.Weekly:
    case PeriodOptions.Monthly:
      if (shiftSlot) {
        const startHour = shiftSlot.startHour?.toString().padStart(2, '0');
        const startMin = shiftSlot.startMin?.toString().padStart(2, '0');
        const durHour = Number(((shiftSlot.duration ?? 0) / 60).toFixed().split(':')[0]),
          durMin = Number(shiftSlot.duration - Number(durHour) * 60);
        const endHour = (
          shiftSlot.startHour + durHour >= 24
            ? shiftSlot.startHour + durHour - 24
            : shiftSlot.startHour + durHour
        )
          .toString()
          .padStart(2, '0');
        const endMin = (shiftSlot.startMin + durMin)?.toString().padStart(2, '0');

        return `${startHour}:${startMin} - ${endHour}:${endMin}`;
      }
      return '';
  }
};

const getRotationInfos = (schedule: NonNullable<IScheduleDetail>) => {
  return schedule?.rotations?.map(rotation => {
    return {
      label: rotation.name,
      time: getRotationTime(rotation.period, rotation.shiftTimeSlot),
    };
  });
};
const filterEventPerTheRotationType = (
  e: IListEvent,
  rotationViewType: RotationViewType,
  allParticipants: IPatternParticipant[],
  currentUserId: string | undefined,
) => {
  if (rotationViewType === RotationViewType.gaps) {
    return e.ID === rotationGapID;
  }
  if (rotationViewType === RotationViewType.defaultRotation) {
    return !e.isOverride;
  }
  if (rotationViewType === RotationViewType.onlyOverrides) {
    return !!e.isOverride;
  }
  if (rotationViewType === RotationViewType.myOnCall) {
    return !!(e.participants || []).find(p =>
      p.type === 'squad'
        ? !!allParticipants
            .find(participant => participant.id === p.ID)
            ?.members?.find(m => m === currentUserId)
        : p.ID === currentUserId,
    );
  }
  return true;
};

const showEventDetails = (
  eventWidth: number,
  eventParticipants: EventParticipant[] | undefined,
) => {
  const participantsToDisplay = eventParticipants?.flatMap(p =>
    p.type === 'squad' ? p.participant.members : p.participant,
  ) as { name: string }[];
  switch (participantsToDisplay?.length) {
    case 0:
      return true;
    case 1:
      return eventWidth > eventWidthThreshold.SINGLE_PARTICIPANT;
    case 2:
      return eventWidth > eventWidthThreshold.TWO_PARTICIPANTS;
    default:
      return eventWidth > eventWidthThreshold.OTHER_PARTICIPANTS;
  }
};

const isSameDayEvent = (startTime: string | undefined, endTime: string | undefined) => {
  const startTimeDuration = getDurationForTimeStamp(startTime);
  const endTimeDuration = getDurationForTimeStamp(endTime);

  return !(endTimeDuration < startTimeDuration || endTimeDuration === startTimeDuration);
};

const splitEventsForTheListView = (events: IListEvent[], pivotDate: DateTime, timeZone: string) => {
  const eventsStartedBeforeEndingToday: IListEvent[] = [];
  const eventsBelongingToToday: IListEvent[] = [];
  const eventsStartedTodayEndingLater: IListEvent[] = [];

  events.forEach(event => {
    const eventStartDT = getUTCDateTime(event.startTime, timeZone);
    const eventEndDT = getUTCDateTime(event.endTime, timeZone);

    if (
      (eventStartDT.toSQLDate() === pivotDate.toSQLDate() &&
        eventEndDT.toSQLDate() === pivotDate.toSQLDate()) ||
      (Math.floor(eventStartDT.diff(pivotDate, 'days').toObject().days ?? 0) < 0 &&
        Math.floor(eventEndDT.diff(pivotDate, 'days').toObject().days ?? 0) > 0)
    ) {
      if (
        eventStartDT.toSQLDate() === pivotDate.toSQLDate() &&
        eventEndDT.toSQLDate() === pivotDate.toSQLDate()
      ) {
        eventsBelongingToToday.push(event);
      } else {
        eventsBelongingToToday.push({
          ...event,
          startTime: getDateTime(new Date(event.startTime))
            .setZone(timeZone)
            .set({
              year: pivotDate.year,
              month: pivotDate.month,
              day: pivotDate.day,
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toUTC()
            .toISO(),
          endTime: getDateTime(new Date(event.endTime))
            .setZone(timeZone)
            .set({
              year: pivotDate.year,
              month: pivotDate.month,
              day: pivotDate.day,
              hour: 23,
              minute: 59,
              second: 0,
              millisecond: 0,
            })
            .toUTC()
            .toISO(),
        });
      }
    } else if (
      Math.floor(eventStartDT.diff(pivotDate, 'days').toObject().days ?? 0) < 0 &&
      eventEndDT.toSQLDate() === pivotDate.toSQLDate()
    ) {
      eventsStartedBeforeEndingToday.push({
        ...event,
        startTime: getDateTime(new Date(event.startTime))
          .setZone(timeZone)
          .set({
            year: pivotDate.year,
            month: pivotDate.month,
            day: pivotDate.day,
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .toUTC()
          .toISO(),
      });
    } else if (
      eventStartDT.toSQLDate() === pivotDate.toSQLDate() &&
      Math.ceil(eventEndDT.diff(pivotDate, 'days').toObject().days ?? 0) > 0
    ) {
      eventsStartedTodayEndingLater.push({
        ...event,
        endTime: getDateTime(new Date(event.endTime))
          .setZone(timeZone)
          .set({
            year: pivotDate.year,
            month: pivotDate.month,
            day: pivotDate.day,
            hour: 23,
            minute: 59,
            second: 0,
            millisecond: 0,
          })
          .toUTC()
          .toISO(),
      });
    }
  });

  return {
    eventsBelongingToToday,
    eventsStartedBeforeEndingToday: eventsStartedBeforeEndingToday.filter(
      event =>
        eventsBelongingToToday.findIndex(
          e => e.scheduleName === event.scheduleName && e.ID === event.ID,
        ) === -1,
    ),
    eventsStartedTodayEndingLater: eventsStartedTodayEndingLater.filter(
      event =>
        eventsBelongingToToday.findIndex(
          e => e.scheduleName === event.scheduleName && e.ID === event.ID,
        ) === -1,
    ),
  };
};

export {
  getPaddedValue,
  mapWeekDayToText,
  formatRotationToEvent,
  getCycleDays,
  calculateTimelineOffset,
  calculateEventWidth,
  mapGapsToCalendarEvent,
  getEventBorderColor,
  mapSchedulesToCalendarEvents,
  mapMockEventsToCalendarEvents,
  getRotationInfos,
  mapParticipantToEventParticipant,
  filterEventPerTheRotationType,
  showEventDetails,
  isSameDayEvent,
  splitEventsForTheListView,
};
