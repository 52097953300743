import RetroStatusInProgress from 'library/icons/status_in_progress.svg';
import RetroStatusUnderReview from 'library/icons/status_under_review.svg';
import RetroStatusPublished from 'library/icons/status_published.svg';
import { filterDropdownOptions } from '../../interfaces/common';
import { IPostmortemStatus } from 'core/interfaces/IPostmortems';
import { InProgressIcon, Published, UnderReview } from '../../../../../../library/icons';

export const POSTMORTEM_STATUS_OPTIONS = {
  [IPostmortemStatus.InProgress]: { label: 'In Progress', Icon: InProgressIcon },
  [IPostmortemStatus.UnderReview]: { label: 'Under Review', Icon: UnderReview },
  [IPostmortemStatus.Published]: { label: 'Published', Icon: Published },
};

export const RETRO_STATUS_DROPDOWN_OPTIONS: filterDropdownOptions[] = [
  {
    label: POSTMORTEM_STATUS_OPTIONS[IPostmortemStatus.InProgress].label,
    value: IPostmortemStatus.InProgress,
    imageUrl: RetroStatusInProgress,
  },
  {
    label: POSTMORTEM_STATUS_OPTIONS[IPostmortemStatus.UnderReview].label,
    value: IPostmortemStatus.UnderReview,
    imageUrl: RetroStatusUnderReview,
  },
  {
    label: POSTMORTEM_STATUS_OPTIONS[IPostmortemStatus.Published].label,
    value: IPostmortemStatus.Published,
    imageUrl: RetroStatusPublished,
  },
];

export const INITIAL_FILTERS_STATE = {
  assignedTo: {
    myUsers: [],
    mySquads: [],
    users: [],
    squads: [],
    meAndMySquads: false,
  },
  created: null,
  services: [],
  temprorayServices: [],
  alert: [],
  priority: [],
  tagsKey: null,
  tagsValue: [],
  isSlo: null,
  withRetro: null,
  retroStatus: [],
  withNotes: null,
  isStarred: null,
  sloList: [],
  startDate: null,
  endDate: null,
  tags: null,
  keyValue: [],
  serviceOwner: {
    myUsers: [],
    mySquads: [],
    users: [],
    squads: [],
    meAndMySquads: false,
  },
};
