import { useDisclosure } from '@chakra-ui/hooks';
import { API } from 'core';
import {
  T_WA_WP_CLONED,
  T_WA_WP_DELETED,
  T_WA_WP_DISABLED,
  T_WA_WP_ENABLED,
} from 'core/const/tracker';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AppTracker } from 'shared/analytics/tracker';

import {
  workflowBulkEnableApi,
  workflowCloneApi,
  workflowDeleteApi,
  workflowListApi,
  workflowUpdateApi,
} from '../api';
import { WorkflowDataResponse } from '../types/response';

import { useSearchFilterParams } from '../list/hooks/useSearchFilterParams';
import { WorkflowFilterForm } from '../types';
import { AxiosError } from 'axios';
import { useToast } from 'library/atoms';
import { usePaginationParams } from 'library/molecules/Tablev2/Table';

const filterFormValues: WorkflowFilterForm = {
  event: null,
  action: null,
  tag: null,
  tags: null,
  owner: null,
  created_by: null,
  updated_by: null,
  enabled: null,
};

const WorkflowListContext = createContext({
  workflowData: { data: [], metadata: { total_count: 0 } } as WorkflowDataResponse,
  isLoadingInitialWorkflowData: false,
  isLoadingWorkflowData: false,
  setPagination: (() => {}) as Dispatch<
    SetStateAction<{
      pageIndex: number;
      pageSize: number;
    }>
  >,
  paginationInfo: {
    pageIndex: 0,
    pageSize: 10,
  },
  onToggleEnableWorkflow: (id: string, enabled: boolean) => {},
  onDeleteWorkflow: (id: string, onSuccess: () => void) => async () => {},
  onCloneWorkflow: (workflow_id: string, workflow_name: string) => async () => {},
  onBulkToggleEnableWorkflow: async (
    enabled: boolean,
    workflow_ids: string[],
    onSuccess: () => void,
    onFail: () => void,
  ) => {},
  isDeletingWorkflow: false,
  isFilterApplied: false,
  isFilterOpen: false,
  onOpenFilter: () => {},
  onCloseFilter: () => {},
  onApplyFilter: (filters: WorkflowFilterForm) => {},
  onClearFilter: () => {},
  onSearch: (value: string) => {},
  searchValue: '',
  filterFormValues,
});

export const workflowList = 'workflow-list';
export const workflowTags = 'workflow-tag';
interface IProps {
  children: ReactNode;
}

const WorkflowListProvider = ({ children }: IProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const searchFilter = useSearchFilterParams();
  const filterActions = useDisclosure();

  const { setPagination, paginationInfo } = usePaginationParams({
    enabled: true,
  });

  const [filterForm, setFilterForm] = useState(filterFormValues);

  const {
    data: workflowData = { data: [], metadata: { total_count: 0 } },
    isLoading: isLoadingInitialWorkflowData,
    isFetching: isLoadingWorkflowData,
  } = useQuery(
    [
      workflowList,
      API.config.teamId,
      paginationInfo.pageIndex,
      paginationInfo.pageSize,
      searchFilter.querySearchValue,
      JSON.stringify(searchFilter.filters),
    ],
    workflowListApi({
      page_number: paginationInfo.pageIndex + 1,
      page_size: paginationInfo.pageSize,
      search: searchFilter.querySearchValue || undefined,
      event: searchFilter.filters.params.event ? [searchFilter.filters.params.event] : [],
      actions: searchFilter.filters.params.action ? [searchFilter.filters.params.action] : [],
      tags: searchFilter.filters.params.tags || [],
      owner: searchFilter.filters.params.owner ? [searchFilter.filters.params.owner] : [],
      created_by: searchFilter.filters.params.created_by
        ? [searchFilter.filters.params.created_by]
        : [],
      updated_by: searchFilter.filters.params.updated_by
        ? [searchFilter.filters.params.updated_by]
        : [],
      enabled: searchFilter.filters.params.enabled
        ? searchFilter.filters.params.enabled === 'yes'
        : undefined,
    }),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError: (error: AxiosError) => {
        if (error?.response?.status !== 402) {
          toast({ text: 'Failed to fetch workflows. Please try again later!', status: 'error' });
        }
      },
    },
  );

  const bulkToggleEnableWorkflow = useMutation(workflowBulkEnableApi);
  const cloneWorkflow = useMutation(workflowCloneApi);
  const deleteWorkflow = useMutation(workflowDeleteApi);
  const updateWorkflow = useMutation(workflowUpdateApi);
  const onToggleEnableWorkflow = useCallback(async (id: string, enabled: boolean) => {
    try {
      const eventName = enabled ? T_WA_WP_ENABLED : T_WA_WP_DISABLED;
      await updateWorkflow.mutateAsync({
        id,
        formDirty: false,
        partialUpdate: {
          enabled,
        },
      });
      AppTracker.track(eventName, { workflow_id: [id] });
      queryClient.invalidateQueries(workflowList);

      toast({
        text: `Workflow ${enabled ? 'enabled' : 'disabled'} successfully!`,
        status: 'success',
      });
    } catch (err) {
      const error = err as AxiosError<{ meta: { error_message: string } }>;

      toast({
        text:
          error.response?.data?.meta?.error_message ||
          `Failed to ${enabled ? 'enable' : 'disable'} workflow. Please try again later!`,
        status: 'error',
      });
    }
  }, []);

  const isFilterApplied = useMemo(
    () => Object.values(searchFilter.filters.params).some(f => f !== null),
    [searchFilter.filters.params],
  );

  const onCloseFilter = useCallback(filterActions.onClose, []);
  const onOpenFilter = useCallback(filterActions.onOpen, []);
  const onApplyFilter = useCallback(filters => {
    searchFilter.updateFilters(filters);
    filterActions.onClose();
  }, []);
  const onClearFilter = useCallback(() => {
    searchFilter.updateFilters(filterFormValues);
    filterActions.onClose();
  }, []);

  const onSearch = useCallback(searchFilter.updateSearch, []);

  const onBulkToggleEnableWorkflow = useCallback(
    async (enabled: boolean, workflow_ids: string[], onSuccess: () => void, onFail: () => void) => {
      try {
        const eventName = enabled ? T_WA_WP_ENABLED : T_WA_WP_DISABLED;
        await bulkToggleEnableWorkflow.mutateAsync({ enabled, workflow_ids });
        AppTracker.track(eventName, { workflow_id: workflow_ids });
        onSuccess();
        queryClient.invalidateQueries(workflowList);
        const message = `${workflow_ids.length} Workflows ${
          enabled ? 'enabled' : 'disabled'
        } successfully!`;
        toast({
          text: workflow_ids.length > 1 ? message : message.replace('Workflows', 'Worlflow'),
          status: 'success',
        });
      } catch (err) {
        onFail();
        const message = `Failed to ${enabled ? 'enable' : 'disable'} ${
          workflow_ids.length
        } workflows. Please try again later!`;
        toast({
          text: workflow_ids.length > 1 ? message : message.replace('Workflows', 'Workflow'),
          status: 'error',
        });
      }
    },
    [],
  );

  const onCloneWorkflow = useCallback(
    (workflow_id: string, workflow_name: string) => async () => {
      try {
        await cloneWorkflow.mutateAsync({ workflow_id });
        AppTracker.track(T_WA_WP_CLONED, { workflow_id: [workflow_id] });
        queryClient.invalidateQueries(workflowList);
        queryClient.invalidateQueries('workflowCount');

        toast({ text: `'${workflow_name}' cloned successfully!`, status: 'success' });
      } catch (err) {
        toast({
          text:
            (err as AxiosError).response?.data?.message ??
            'Failed to clone workflow. Please try again later!',
          status: 'error',
        });
      }
    },
    [],
  );

  const onDeleteWorkflow = useCallback(
    (id: string, onSuccess: () => void) => async () => {
      try {
        await deleteWorkflow.mutateAsync({ id });
        AppTracker.track(T_WA_WP_DELETED, { workflow_id: [id] });
        onSuccess();
        queryClient.invalidateQueries(workflowList);
        queryClient.invalidateQueries('workflowCount');
        toast({ text: 'Workflow deleted successfully!', status: 'success' });
      } catch (err) {
        toast({ text: 'Failed to delete workflow. Please try again later!', status: 'error' });
      }
    },
    [],
  );

  useEffect(() => {
    setFilterForm(searchFilter.filters.form);
  }, [searchFilter.filters.form]);

  const value = {
    workflowData,
    isLoadingInitialWorkflowData,
    isLoadingWorkflowData,
    setPagination,
    paginationInfo,
    onToggleEnableWorkflow,
    onDeleteWorkflow,
    onCloneWorkflow,
    onBulkToggleEnableWorkflow,
    isBulkEnableLoading: bulkToggleEnableWorkflow.isLoading,
    isDeletingWorkflow: deleteWorkflow.isLoading,
    isFilterApplied,
    isFilterOpen: filterActions.isOpen,
    onOpenFilter,
    onCloseFilter,
    onApplyFilter,
    onClearFilter,
    searchValue: searchFilter.searchValue,
    onSearch,
    filterFormValues: filterForm,
  };

  return <WorkflowListContext.Provider value={value}>{children}</WorkflowListContext.Provider>;
};

const useWorkflowListContext = () => useContext(WorkflowListContext);
const WorkflowListConsumer = WorkflowListContext.Consumer;

export { WorkflowListProvider, WorkflowListConsumer, useWorkflowListContext };
