import React, { ReactNode } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  ButtonProps,
  HStack,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

interface Drawer {
  isOpen: boolean;
  onClose: () => void;
  onBack?: () => void;
  title: string | JSX.Element;
  isFooter?: boolean;
  disableBodyPadding?: boolean;
  children: ReactNode;
  footerButtons?: Array<ButtonProps & { text: string }>;
  returnFocusOnClose?: boolean;
  size?: string;
}

export const CustomDrawerComponent = ({
  isOpen,
  onClose,
  onBack,
  title,
  isFooter,
  disableBodyPadding,
  children,
  footerButtons,
  returnFocusOnClose,
  size,
}: Drawer) => {
  return (
    <>
      <Drawer
        onClose={() => onClose()}
        isOpen={isOpen}
        size={size ?? 'lg'}
        returnFocusOnClose={returnFocusOnClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton boxShadow="none !important" />
          <DrawerHeader fontFamily="Mulish" fontSize={24} fontWeight={400} borderBottomWidth="1px">
            <HStack>
              {onBack && <ArrowBackIcon onClick={onBack} _hover={{ cursor: 'pointer' }} />}
              {title}
            </HStack>
          </DrawerHeader>
          <DrawerBody sx={disableBodyPadding ? { p: 0 } : {}}>{children}</DrawerBody>
          {isFooter && (
            <DrawerFooter justifyContent="flex-start" borderTopWidth="1px">
              {footerButtons &&
                footerButtons.map((button, index) => (
                  <Button key={index} mr={5} {...button}>
                    {button.text}
                  </Button>
                ))}
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};
