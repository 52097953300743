import {
  Button,
  Center,
  Circle,
  Divider,
  Flex,
  Spacer,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { DialogModalFrame } from 'uie/components';
import { Locale } from 'core/helpers/dateUtils';
import { IAppState } from 'core/interfaces/IAppState';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import React, { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { hasReadAccessToEntity } from 'views/main/organization/navigation-flows/helpers';
import { ESCALATION_POLICIES_LIST_PATH, SERVICE_DETAILS_PATH } from 'views/main/routes/routes';
import useUINavigationFunctions from '../../../../navigation-flows/useUINavigationFunctionV2';
import { PlaceholderText } from '../../../common';
import IllustrationBox from '../../../common/IllustrationBox';
import { Header, Loader } from '../../../components';
import { AlertDialogComponent } from '../../../components/Alert';
import { Search } from '../../../components/Search';
import StackLimit, { IconType } from '../../../components/StackLimit';
import DataTable from '../../../components/Table';
import { linkStyles } from '../../../contants/service.contant';
import { getStatus, getStatusColor } from '../../../helpers/helper.service';
import { IMaintenace } from '../../../hooks/useMentainance';
import { IServiceList, SeviceObj } from '../../../hooks/useServiceList';
import DependencyIllustration from '../../../icons/Illustrations/Dependencies.png';
import { MenuAction, MenuActions } from '../service.rule/columns';
import DependenciesModal from './dependencies.modal';

interface DependencyAutomation {
  suppressed?: number;
  duplicated?: number;
  serviceId?: string;
  loading?: boolean;
}

type IIncidentMetrics = {
  metrics: {
    [serviceId: string]: {
      triggered: number;
      acknowledged: number;
    };
  };
  loading: boolean;
};

type Props = {
  serviceList: SeviceObj[];
  serviceDependency?: IServiceList;
  dependencyAutomations: (DependencyAutomation | undefined)[];
  dependencyMetrics: IIncidentMetrics;
  dependencyMaintenance: {
    data: { service: string; data: IMaintenace[] } | undefined;
    loading: boolean;
  }[];
  serviceId: string;
  dependencyLoading: boolean;
  dependencyFetching: boolean;
  refetchDependency: () => void;
  deletingDependency: boolean;
  updateDependency: (dependencyServices: string[], callbackFn: () => void) => void;
};

type ServiceDetail = SeviceObj & {
  automation: DependencyAutomation;
  maintenance: { data: { data: IMaintenace[] }; loading: boolean };
  incidentMetrics: IIncidentMetrics;
};

function ServiceDependency({
  serviceList,
  serviceDependency,
  dependencyAutomations,
  dependencyMaintenance,
  dependencyMetrics,
  serviceId,
  dependencyLoading,
  dependencyFetching,
  refetchDependency,
  deletingDependency,
  updateDependency,
}: Props) {
  const [dependencyModalOpen, setDependencyModalOpen] = useState(false);
  const [deleteDependencyModalOpen, setDeleteDependencyModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [deleteDependencyDetail, setDeleteDependencyDetail] = useState<
    { name: string; id: string }[]
  >([]);

  const dependencyData = useMemo(() => {
    return (
      serviceDependency?.data?.services?.map(dependency => ({
        ...dependency,
        incidentMetrics: dependencyMetrics,
        automation: dependencyAutomations.find(stat => stat?.serviceId === dependency.id),
        maintenance:
          dependencyMaintenance.find(maintenance => maintenance?.data?.service === dependency.id) ??
          [],
      })) ?? []
    );
  }, [serviceDependency, dependencyAutomations, dependencyMetrics]);

  const { getEntityLink } = useUINavigationFunctions();

  const hasUpdateAccess = useUserAccess().hasUpdateAccess;

  const hasUpdate = hasUpdateAccess('services', serviceId);

  const depencencyColumns = useMemo(
    () =>
      [
        {
          disableSortBy: true,
          Header: 'NAME',
          accessor: (row: ServiceDetail) =>
            row.name + row.tags?.map(tag => tag.key + tag.value).join(','),
          Cell: (cell: Cell<ServiceDetail>) => {
            const {
              row: {
                original: { id, name, tags },
              },
            } = cell;
            let component = (
              <Text fontSize="sm" fontWeight={500} _hover={{ ...linkStyles }}>
                {name}
              </Text>
            );
            if (hasReadAccessToEntity('service')) {
              component = <Link to={generatePath(SERVICE_DETAILS_PATH, { id })}>{component}</Link>;
            }
            return (
              <VStack alignItems={'flex-start'}>
                {component}
                <StackLimit
                  limit={1}
                  type="tag"
                  data={tags?.map(tag => ({ name: `${tag.key} : ${tag.value}` })) || []}
                />
              </VStack>
            );
          },
        },
        {
          disableSortBy: true,
          Header: 'OWNER & ESCALATION POLICY',
          accessor: (row: ServiceDetail) => row.owner?.name + row.escalation_policy.name,
          Cell: (cell: Cell<ServiceDetail>) => {
            const {
              row: {
                original: { escalation_policy, maintainer },
              },
            } = cell;

            const escalationPolicyPath = hasReadAccessToEntity('escalationpolicy')
              ? generatePath(ESCALATION_POLICIES_LIST_PATH, {
                  id: escalation_policy.id,
                })
              : '';

            const ownerLink =
              maintainer && hasReadAccessToEntity(maintainer.type)
                ? getEntityLink(maintainer.type, maintainer.id)
                : '';

            return (
              <StackLimit
                direction="column"
                limit={2}
                type="icon-text"
                data={[
                  ...(maintainer
                    ? [
                        {
                          name: maintainer?.name,
                          icon: maintainer.type ?? ('user' as IconType),
                          username: maintainer?.username_for_display ?? '',
                          to: ownerLink,
                        },
                      ]
                    : []),
                  {
                    name: escalation_policy.name,
                    icon: 'escalation' as IconType,
                    to: escalationPolicyPath,
                  },
                ]}
              />
            );
          },
        },
        {
          disableSortBy: true,
          id: 'open_incidents',
          Header: 'LIFETIME OPEN INCIDENTS',
          Cell: (cell: Cell<ServiceDetail>) => {
            const {
              row: {
                original: { id, incidentMetrics },
              },
            } = cell;
            return !incidentMetrics.loading ? (
              <StackLimit
                direction="column"
                limit={2}
                type="count-text"
                data={Object.entries({
                  triggered: incidentMetrics.metrics?.[id]?.triggered ?? 0,
                  acknowledged: incidentMetrics.metrics?.[id]?.acknowledged ?? 0,
                })
                  .slice(0, 2)
                  .map(([k, v], i) => ({ name: k.charAt(0).toUpperCase() + k.slice(1), count: v }))}
              />
            ) : (
              <></>
            );
          },
        },
        {
          disableSortBy: true,
          id: 'automation_stats',
          Header: 'AUTOMATION STATS',
          Cell: (cell: Cell<ServiceDetail>) => {
            const {
              row: {
                original: { automation },
              },
            } = cell;

            if (automation?.loading) {
              return <Loader.Spinner />;
            }

            return automation ? (
              <StackLimit
                direction="column"
                limit={2}
                type="count-text"
                data={Object.entries({
                  supressed: automation.suppressed,
                  deduplicated: automation.duplicated,
                }).map(([k, v], i) => ({
                  name: k.charAt(0).toUpperCase() + k.slice(1),
                  count: Number(v),
                }))}
              />
            ) : (
              <Text fontSize="sm">N/A</Text>
            );
          },
        },
        {
          disableSortBy: true,
          id: 'maintenance',
          Header: 'MAINTENANCE',
          Cell: (cell: Cell<ServiceDetail>) => {
            const {
              row: {
                original: { maintenance },
              },
            } = cell;

            const maintenanceDetail = maintenance?.data?.data?.[0];

            if (maintenance?.loading) {
              return <Loader.Spinner />;
            }

            return maintenanceDetail ? (
              <Stack direction="column" alignItems="center">
                <Text cursor="default" fontSize="sm">
                  <Tooltip
                    hasArrow
                    bgColor="white"
                    color="gray.700"
                    fontWeight={400}
                    label={Locale.toShortDateTimeWithOffset(maintenanceDetail.maintenanceFrom)}
                  >
                    {Locale.toShortDateTimeWoYear(maintenanceDetail.maintenanceFrom)}
                  </Tooltip>
                </Text>

                <Text>to</Text>

                <Text cursor="default" fontSize="sm">
                  <Tooltip
                    hasArrow
                    bgColor="white"
                    color="gray.700"
                    fontWeight={400}
                    label={Locale.toShortDateTimeWithOffset(maintenanceDetail.maintenanceTill)}
                  >
                    {Locale.toShortDateTimeWoYear(maintenanceDetail.maintenanceTill)}
                  </Tooltip>
                </Text>
              </Stack>
            ) : (
              <Text fontSize="sm">N/A</Text>
            );
          },
        },
        {
          disableSortBy: true,
          id: 'status',
          Header: 'STATUS',
          accessor: (row: ServiceDetail) => row.status,
          Cell: (cell: Cell<ServiceDetail>) => {
            const {
              row: {
                original: { id, status, incidentMetrics },
              },
            } = cell;

            let serviceStatus = status;
            if (
              (incidentMetrics.metrics?.[id]?.triggered ||
                incidentMetrics.metrics?.[id]?.acknowledged) &&
              serviceStatus != 'on_maintenance'
            ) {
              serviceStatus = 'unhealthy';
            }

            return (
              <Flex gap={2} alignItems="center">
                <Circle size="12px" bg={getStatusColor(serviceStatus ?? '')} color="white" />
                <Text textTransform={'capitalize'} fontSize="sm">
                  {getStatus(serviceStatus ?? '')}
                </Text>
              </Flex>
            );
          },
        },
        {
          id: 'actions',
          Cell: (cell: Cell<ServiceDetail>) => {
            const {
              row: {
                original: { name, id },
              },
            } = cell;

            return (
              <MenuActions
                actions={[
                  {
                    action: MenuAction.DELETE,
                    label: 'Delete Dependency',
                    handler: () => {
                      setDeleteDependencyModalOpen(true);
                      setDeleteDependencyDetail([{ name, id }]);
                    },
                  },
                ]}
              />
            );
          },
        },
      ].filter(col => (col.id === 'actions' ? hasUpdate : true)),
    [hasUpdate],
  );

  const activeDependencyList = useMemo(() => {
    const dependenciesToDelete = deleteDependencyDetail.map(d => d.id);
    const activeDependencies = serviceDependency?.data.services
      .filter(s => dependenciesToDelete.includes(s.id))
      .map(s => s.id);

    return activeDependencies ?? [];
  }, [deleteDependencyDetail, serviceDependency]);

  const closeDependencyModal = () => setDeleteDependencyModalOpen(false);

  return (
    <>
      <Header
        padding={4}
        title={<Spacer />}
        actions={
          <Flex justifyContent={'flex-end'} gap={5}>
            {!!dependencyData.length && (
              <Fragment>
                <Search
                  setSearchTerm={searchTerm => setSearchText(searchTerm)}
                  searchTerm={searchText}
                  searchEnabled={searchEnabled}
                  setSearchEnabled={isEnabled => setSearchEnabled(isEnabled)}
                  isDisabled={!dependencyData.length}
                />
                {hasUpdate && (
                  <Center height={8}>
                    <Divider orientation="vertical" />
                  </Center>
                )}
              </Fragment>
            )}
            <NoPermissionTooltip isDisabled={hasUpdate}>
              <Button
                onClick={() => setDependencyModalOpen(true)}
                variant={'default'}
                isDisabled={!hasUpdate}
              >
                Add Dependency
              </Button>
            </NoPermissionTooltip>
          </Flex>
        }
      />

      {dependencyLoading && <Loader.Spinner centered />}
      {!dependencyLoading && dependencyFetching && <Loader.Progress />}

      {!dependencyLoading &&
        (dependencyData.length ? (
          <DataTable
            searchText={searchText}
            data={dependencyData}
            columns={depencencyColumns}
            isClientPagination
          />
        ) : (
          <IllustrationBox
            width="300px"
            image={DependencyIllustration}
            msg={
              <PlaceholderText
                message="No dependencies added"
                tip={
                  'Tip: We suggest adding dependencies to let your team know what the upstream services are.'
                }
              />
            }
            name="dependency"
          />
        ))}

      <DialogModalFrame id="service_dependencies" onClose={() => setDependencyModalOpen(false)}>
        {dependencyModalOpen && (
          <DependenciesModal
            dependencies={serviceDependency?.data.services.map(s => s.id) ?? []}
            checkAndSetDirty={() => {}}
            serviceId={serviceId}
            hide={() => {
              setDependencyModalOpen(false);
              refetchDependency();
            }}
          />
        )}
      </DialogModalFrame>

      <AlertDialogComponent
        isOpen={deleteDependencyModalOpen}
        onClose={closeDependencyModal}
        callbackFn={() => updateDependency(activeDependencyList, closeDependencyModal)}
        msg={`The dependency ${deleteDependencyDetail
          .map(d => d.name)
          .join(', ')} will get deleted.`}
        title={`Are you sure you want to delete this dependency ?`}
        isDelete={true}
        loading={deletingDependency}
      />
    </>
  );
}

export default ServiceDependency;
