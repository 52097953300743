import { IAppState } from 'core/interfaces/IAppState';
import { IUserInfo } from 'core/interfaces/IUserData';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API } from 'core';
import axios from 'axios';

export const useUsersSelector = (teamId?: string) => {
  const organization = useSelector((state: IAppState) => state.organization);
  const usersInTeam = organization.teams.t
    .find(team => team.id === teamId)
    ?.members.map(m => m.user_id);
  return organization.users.u.filter(user => usersInTeam?.includes(user.id)) as IUserInfo[];
};

export const useGetSqauds = (selectedTeamId: string) => {
  const [squads, setSquads] = useState<any>([]);

  const fetchSquads = async () => {
    try {
      const req = await axios.get(
        `${API.config.batman}/organizations/${API.config.organizationId}/squads?owner_id=${selectedTeamId}`,
      );
      if (req?.data) {
        setSquads(req?.data?.data);
        return req?.data?.data;
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (selectedTeamId) {
      setSquads([]);
      fetchSquads();
    }
  }, [selectedTeamId]);
  return { fetchSquads, squads };
};
