import {
  REQUEST_ORG_USER_ACL,
  REQUEST_ORG_USER_ACL_FAIL,
  REQUEST_ORG_USER_ACL_SUCCESS,
} from '../../const/acl';
import { IUserAcl } from '../../interfaces/IUserAcl';

const requestOrganizationUserAcl = () => ({
  type: REQUEST_ORG_USER_ACL,
  payload: {},
});

const onRequestOrganizationUserAclSuccess = (acl: IUserAcl) => ({
  type: REQUEST_ORG_USER_ACL_SUCCESS,
  payload: {
    acl,
  },
});

const onRequestOrganizationUserAclFail = (error: any) => ({
  type: REQUEST_ORG_USER_ACL_FAIL,
  payload: {
    error,
  },
});

export {
  requestOrganizationUserAcl,
  onRequestOrganizationUserAclSuccess,
  onRequestOrganizationUserAclFail,
};
