import React, { ReactChild, ReactElement } from 'react';
import { DropDownContainer, DropDownHook, IDrop, DropDownOptionShell } from './helper';

type IMix = React.HTMLAttributes<HTMLDivElement> & IDrop;
interface IDropDown extends IMix {
  trigger?: 'click' | 'hover';
  /**
   * hooked component
   */
  hook: ReactElement;
  open?: boolean;
  /**
   * elements inside dropdown
   */
  children: ReactChild | any;
  offset?: string | '0px';
}

const DropDown = (props: IDropDown) => {
  const hook = React.cloneElement(props.hook, {
    tabIndex: 0,
  });

  return (
    <DropDownHook>
      {hook}
      <DropDownContainer tabIndex={0} {...props}>
        {props.children}
      </DropDownContainer>
    </DropDownHook>
  );
};

/** @component */
export default DropDown;
export { DropDownHook, DropDownContainer, DropDownOptionShell };
