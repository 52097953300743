import axios from 'axios';
import { ISQTeams } from 'core/interfaces/ISQTeams';
import { API } from '../api';

class SQTeams {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/teams`;
  private _apiOld = `${API.config.endpoint}/organizations/${API.config.organizationId}`;

  public getAllTeams = () => axios.get<{ data: [ISQTeams] }>(`${this._api}`);
}

export default SQTeams;
