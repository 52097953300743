import { Flex, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import React from 'react';
import { Workflow } from '../../types/response/workflow';
import { ActionsStack } from 'library/atoms/ActionsStack';
import EntityUI, { EntityType } from 'library/molecules/EntityUI/EntityUI';

type WorkflowSummaryProps = Pick<
  Workflow,
  'actions' | 'owner_id' | 'owner_type' | 'enabled' | 'execution_count'
>;

const WorkflowSummary: React.FC<WorkflowSummaryProps> = ({
  actions,
  owner_id,
  owner_type,
  enabled,
  execution_count,
}) => {
  const actionNames = actions?.map(action => action.name) ?? [];

  return (
    <Flex
      borderTop="1px"
      borderTopColor="secondary.200"
      borderTopStyle="solid"
      px="6"
      py="6"
      bg={'container.background'}
      justifyContent="space-between"
      alignItems="center"
    >
      <VStack align="flex-start">
        <Text variant="body" fontWeight="bold">
          OWNER
        </Text>
        <EntityUI
          renderType="link-popover"
          type={owner_type as EntityType}
          entityId={owner_id}
          textVariant="h2"
        />
      </VStack>
      <VStack align="flex-start">
        <Text variant="body" fontWeight="bold">
          ACTIONS
        </Text>
        <ActionsStack actionTypes={actionNames} actionNames={actionNames} defaultLength={5} />
      </VStack>
      <VStack align="flex-start">
        <Text variant="body" fontWeight="bold">
          STATUS
        </Text>
        <Text variant="h3">{enabled ? 'Enabled' : 'Disabled'}</Text>
      </VStack>
      <VStack align="flex-start">
        <Text variant="body" fontWeight="bold">
          # OF EXECUTIONS
        </Text>
        <Text variant="h3">{execution_count}</Text>
      </VStack>
    </Flex>
  );
};

export { WorkflowSummary };
