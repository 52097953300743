import { DialogModalFrame, Grid, Label, Para, TextButton } from 'uie/components';
import { T_WA_SQUAD_MODEL_VIEWED } from 'core/const/tracker';

import { TickBadge } from 'icons';
import React, { useEffect } from 'react';
import { AppTracker } from 'shared/analytics/tracker';
import styled from 'styled-components';

type Props = {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
};
const ROLE_ABILITIES = [
  {
    ability: 'View Squad',
    member: true,
    owner: true,
  },
  {
    ability: 'Create Entities within Squad',
    member: true,
    owner: true,
  },
  {
    ability: 'Edit Entities within Squad',
    member: true,
    owner: true,
  },
  {
    ability: 'Delete Entities within Squad',
    member: false,
    owner: true,
  },
  {
    ability: 'Transfer Entity Ownership',
    member: false,
    owner: true,
  },
  {
    ability: 'Add Users to Squad',
    member: false,
    owner: true,
  },
  {
    ability: 'Remove Users from Squad',
    member: false,
    owner: true,
  },
  {
    ability: 'Convert Squad Member to Squad Owner',
    member: false,
    owner: true,
  },
  {
    ability: 'Convert Squad Owner to Squad Member',
    member: false,
    owner: true,
  },
];
export function RoleAbilitiesModal({ openModal, setOpenModal }: Props) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(openModal);
    if (openModal) {
      AppTracker.track(T_WA_SQUAD_MODEL_VIEWED);
    }
  }, [openModal]);

  return (
    <DialogModalFrame
      root="rsg-root"
      id="modalFrame"
      onClose={() => setOpenModal(false)}
      padding="18px"
      width="637px"
    >
      {openModal && (
        <div>
          <Para
            style={{
              fontSize: '24px',
              lineHeight: '150%',
              marginBottom: '30px',
              paddingLeft: '18px',
            }}
          >
            Squad Roles
          </Para>
          <Grid flexWidth={12} alignItems="center">
            <Grid flexWidth={6} alignItems="center">
              <Label
                style={{
                  fontSize: '14px',
                  lineHeight: '150%',
                  paddingLeft: '18px',
                  paddingRight: '18px',
                  paddingTop: '9px',
                  paddingBottom: '9px',
                }}
              >
                Action
              </Label>
            </Grid>
            <Grid flexWidth={3}>
              <Label
                style={{
                  fontSize: '14px',
                  lineHeight: '150%',
                }}
              >
                Squad Member
              </Label>
            </Grid>
            <Grid flexWidth={3}>
              <Label
                style={{
                  fontSize: '14px',
                  lineHeight: '150%',
                }}
              >
                Squad Owner
              </Label>
            </Grid>
          </Grid>
          {ROLE_ABILITIES.map((ra, index) => (
            <Grid flexWidth={12} alignItems="center" key={ra.ability}>
              <Grid flexWidth={6} alignItems="center">
                <Label
                  style={{
                    fontSize: '15px',
                    lineHeight: '150%',
                    color: '#122146',
                    paddingLeft: '18px',
                    paddingRight: '18px',
                    paddingTop: '9px',
                    paddingBottom: '9px',
                  }}
                >
                  {ra.ability}
                </Label>
              </Grid>
              <Grid flexWidth={3}>
                {ra.member && <StyledTickIcon style={{ height: '10px', width: '14px' }} />}
              </Grid>
              <Grid flexWidth={3}>
                <Label
                  style={{
                    fontSize: '14px',
                    lineHeight: '150%',
                  }}
                >
                  {ra.owner && (
                    <StyledTickIcon fill="red" style={{ height: '10px', width: '14px' }} />
                  )}
                </Label>
              </Grid>
            </Grid>
          ))}
        </div>
      )}
    </DialogModalFrame>
  );
}

const StyledTickIcon = styled(TickBadge)`
  path {
    fill: #122146;
  }
`;
