import { T_WA_USER_REMOVED_FROM_TEAM } from 'core/const/tracker';
import { exception } from 'core/exception';
import { ITeam, ITeamMemberDependencies } from 'core/interfaces/ITeams';
import TeamsService from 'core/services/rbac/service.teams';
import { Component } from 'react';
import { queryClient } from 'views';
import { connect } from 'react-redux';
import { SingleValue } from 'react-select';
import { AppTracker } from 'shared/analytics/tracker';
import { getAssignableTeamMembers } from 'shared/reducers';
import { IAppState } from '../../../../../../../../core/interfaces/IAppState';
import {
  IComponentErrors,
  IComponentNetworkState,
} from '../../../../../../../../core/interfaces/IComponentState';
import { ReduceEntity, ReduceEntityType } from '../../../../../../../../core/interfaces/IHelpers';
import { IUsersOfOrganization } from '../../../../../../../../core/interfaces/IUserData';
import { getFilteredUsers } from '../../stakeholders/utils/utils.stakeholders';
import { render, renderDefault, renderSuccess } from './render.index';

interface IProps extends Pick<IAppState, 'organization'> {
  team: ITeam;
  oldUser: ReduceEntityType<
    IUsersOfOrganization,
    'id' | 'email_verified' | 'phone_verified' | 'email' | 'role',
    'name'
  >;
  dependencies: ITeamMemberDependencies;
  updateChildInRequest: (request: boolean) => void;
  hide: () => void;
  onUpdate?: () => void;
}

interface IState {
  componentNetworkState: IComponentNetworkState;
  errors: IComponentErrors<'network_err'>;
  newUserId: string;
  suppressIncidents: boolean;
}

export class UserDependencies extends Component<IProps, IState> {
  private _teamService = new TeamsService();

  render = render;
  renderSuccess = renderSuccess;
  renderDefault = renderDefault;

  constructor(props: IProps) {
    super(props);
    this.state = {
      componentNetworkState: 'idle',
      errors: {},
      newUserId: '',
      suppressIncidents: false,
    };
  }

  _oUsers = getAssignableTeamMembers(this.props.team, this.props.organization.users.u).reduce(
    (c: ReduceEntity<IUsersOfOrganization, 'id' | 'email', 'name'>, u) => {
      c[u.id] = {
        id: u.id,
        name: `${u.first_name} ${u.last_name}`,
        email: u.email,
      };

      return c;
    },
    {},
  );

  _stakeholders = getFilteredUsers(this.props.team, this.props.organization, '') ?? {};

  toggleSuppressIncidents = () =>
    this.setState(({ suppressIncidents }) => ({ suppressIncidents: !suppressIncidents }));

  selectNewUser = (
    newValue: SingleValue<{
      label: string;
      value: string;
    }>,
  ) => {
    if (newValue?.value) this.setState({ newUserId: newValue?.value }, () => {});
  };

  replaceAndDelete = async () => {
    try {
      this.setState({ componentNetworkState: 'request', errors: {} });
      this.props.updateChildInRequest(true);

      const { newUserId, suppressIncidents } = this.state;
      const { dependencies, oldUser, team } = this.props;

      await this._teamService.replaceTeamMember(team.id, oldUser.id as string, {
        new_user_id: newUserId,
        squad_ids: dependencies.squads.map(sq => sq.id),
        escalation_policy_ids: dependencies.escalation_policies.map(ep => ep.id),
        schedule_ids: dependencies.schedules.map(ep => ep.id),
        schedulev2_ids: dependencies.schedulesV2.map(ep => Number(ep.id)),
        ger_ids: dependencies.global_event_rules_owner.map(ger => Number(ger.id)),
        workflow_ids: dependencies.workflows.map(wf => Number(wf.id)),
        status_page_ids: dependencies.status_pages_owner.map(sp => Number(sp.id)),
        incident_group_ids: dependencies.incident_groups.map(ig => Number(ig.id)),
        stakeholder_group_ids: dependencies.stakeholder_groups.map(stk => stk.id),
        routing_number_ids: dependencies.routing_numbers.map(rn => Number(rn.id)),
        replace_incidents: true,
        replace_routing_rules: true,
        replace_service_maintainer: true,
        replace_user_with_service_delay_notification_config: true,
        replace_webforms_entity_owner: true,
        replace_schedules_entity_owner: true,
        replace_slos_entity_owner: true,
        replace_rbooks_entity_owner: true,
        replace_esc_policy_owner: true,
        replace_ger_owners: true,
        suppress_incidents: suppressIncidents,
        replace_schedules_v2_participants: true,
        replace_status_page_owner: true,
        replace_incident_groups: true,
        replace_workflows_owner: true,
        replace_stakeholder_groups: Boolean(dependencies?.stakeholder_groups.length),
        replace_routing_numbers_owner: true,
        replace_incident_watchers: true,
      });
      await this._teamService.deleteTeamMember(team.id, oldUser.id as string);
      AppTracker.track(T_WA_USER_REMOVED_FROM_TEAM, {
        'Team ID': this.props.team.id,
      });
      this.setState({ componentNetworkState: 'success' });
      this?.props?.onUpdate?.();
      queryClient.invalidateQueries('whoIsOncall');
    } catch (err: any) {
      this.setState({
        componentNetworkState: 'idle',
        errors: { network_err: exception.handle('E_DELETE_TEAM_MEMBER', err) },
      });
    } finally {
      this.props.updateChildInRequest(false);
    }
  };
}

export default connect(({ organization }: IAppState) => ({
  organization,
}))(UserDependencies);
