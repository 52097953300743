import React, { ChangeEvent } from 'react';

import { ArrowUpIcon } from '@chakra-ui/icons';
import { AspectRatio, Box, Input, Stack, Text, VStack } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';

interface IUploadLogoProps {
  setFieldValue: any;
  areaHidden: boolean;
}

const onLogoUpload = (logo: File, setFieldValue: any) => {
  if (!logo) return;

  try {
    (file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFieldValue('logo', file);
        setFieldValue('isLogoChanged', true);
        setFieldValue('logoUrl', reader.result as any, true);
      };
      reader.onerror = error => {
        console.log('Error: ', error);
      };
    })(logo);
  } catch (err: any) {
    console.log(err);
  }
};
function UploadLogo(props: IUploadLogoProps, ref: React.ForwardedRef<HTMLInputElement>) {
  return (
    <AspectRatio width="200px" ratio={1} display={props.areaHidden ? 'none' : 'block'}>
      <Box
        borderColor="gray.300"
        borderStyle="dashed"
        borderWidth="2px"
        rounded="md"
        shadow="sm"
        role="group"
      >
        <Box position="relative" height="100%" width="100%">
          <Box
            position="absolute"
            top="0"
            left="0"
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
          >
            <Stack
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justify="center"
              spacing="4"
            >
              <VStack p="8" textAlign="center" spacing="1">
                <ArrowUpIcon height={5} width={5} color={THEME_COLORS.brand.blue} />
                <Text fontWeight="light" color="gray.300" fontSize={'sm'}>
                  image size to be under 500kb .jpg .jpeg .png formats allowed
                </Text>
              </VStack>
            </Stack>
          </Box>
          <Input
            ref={ref}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const target = e.target as HTMLInputElement;
              const file: File = (target.files as FileList)[0];
              onLogoUpload(file, props.setFieldValue);
            }}
            cursor={'pointer'}
            type="file"
            accept=".jpg,.jpeg, .png"
            name="logo"
            height="100%"
            width="100%"
            position="absolute"
            top="0"
            left="0"
            opacity="0"
            aria-hidden="true"
          />
        </Box>
      </Box>
    </AspectRatio>
  );
}

const UploadLogoWithRef = React.forwardRef(UploadLogo);

export default UploadLogoWithRef;
