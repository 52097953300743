import React, { useState } from 'react';
import {
  Grid,
  Theme,
  Para,
  SpinLoader,
  DialogModalFrame,
  Label,
  TextButton,
  SwitchBlock,
  EditableRow,
  IconButton,
  CopyIconButton,
  JSONCodeBlock,
  CardBlock,
} from 'uie/components';
import { IIEvent } from '../../../../../../core/interfaces/IIncidents';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import breaks from 'remark-breaks';
import moment from 'moment';
import { MaximizeIcon } from '../../../../../../icons';
import { replaceProperty } from 'core/helpers';

const { theme } = Theme;

interface IProps {
  events: IIEvent[];
  getEvents: () => void;
  isLoading: boolean;
  showLoadMoreButton: boolean;
}

type IDescriptionView = 'Details' | 'Payload';

const CustomCodeRenderer = (props: any) => {
  return (
    <div className="incident_details__code-container description">
      <pre className="incident_details__raw-code">{props.value}</pre>
      <div className="incident_details__code-copy">
        <CopyIconButton type="transparent">{props.value}</CopyIconButton>
      </div>
    </div>
  );
};

export const DedupedEvents = ({ events, getEvents, isLoading, showLoadMoreButton }: IProps) => {
  const [viewDeduped, setViewDeduped] = useState<null | IIEvent>(null);
  const [descriptionView, setDescriptionView] = useState<IDescriptionView>('Details');

  const renderMdLink = (props: any) => (
    // eslint-disable-next-line react/prop-types
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {/* eslint-disable-next-line react/prop-types*/}
      {props.children}
    </a>
  );

  const openDedupedModal = (e: IIEvent) => () => setViewDeduped(e);

  const closeDedupeModal = () => setViewDeduped(null);

  const handleDisplayChange = (v: any) => {
    setDescriptionView(v);
  };

  const dedupDisplayParams = (e: IIEvent) => {
    if (e.deduplication_reason) {
      return {
        display: 'Deduplication Reason',
        value: replaceProperty(
          e.deduplication_reason.env?.rhs,
          e.deduplication_reason.evaluated_expression,
          e.deduplication_reason.evaluated_expression,
        ),
      };
    }

    if (e.dedup_data) {
      return {
        display: 'Dedup Key',
        value: e.dedup_data.key,
      };
    }

    return false;
  };

  const DedupReasonComponent = (e: IIEvent) => {
    if (!e) {
      return <></>;
    }

    const dd = dedupDisplayParams(e);
    if (!dd) {
      return <></>;
    }

    return (
      <CardBlock className="mb-10" animateOnHover={true} style={{ marginTop: '10px' }}>
        <Para className="mr-5" style={{ fontFamily: theme.monoFontFamily }}>
          <Label style={{ marginRight: '10px', color: theme.shades.black, fontWeight: 600 }}>
            {dd.display}
          </Label>
          {dd.value}
        </Para>
      </CardBlock>
    );
  };

  return (
    <Grid flexWidth={12}>
      <Grid type="column" className="incident_details__block">
        <Grid type="column" className="mt-10" style={{ overflow: 'auto' }}>
          <Grid type="column" className="mt-10">
            {events.map((e, i) => (
              <EditableRow
                key={i}
                editableChildren={
                  <IconButton onClick={openDedupedModal(e)}>
                    <MaximizeIcon height="16" width="16" />
                  </IconButton>
                }
                height="40px"
              >
                <button className="incident_details__hidden_button" onClick={openDedupedModal(e)}>
                  <Grid
                    flexWidth={12}
                    alignItems="baseline"
                    justifyContent="center"
                    className="mb-10"
                  >
                    <Grid flexWidth={1}>
                      <Para fontSize={14}>{i + 1}</Para>
                    </Grid>
                    <Grid flexWidth={3} className="ml-5" type="column">
                      <Para fontSize={14}>{moment(e.time_of_creation).format('MMM DD YYYY')}</Para>
                      <Para fontSize={14}>{moment(e.time_of_creation).format('h:mm:ss A')}</Para>
                    </Grid>
                    <Grid flexWidth={8} className="ml-5">
                      <Para fontSize={14}>{e.message}</Para>
                    </Grid>
                  </Grid>
                </button>
              </EditableRow>
            ))}
            {showLoadMoreButton && (
              <TextButton onClick={getEvents} buttonType="ghost" className="ml-10 mb-10">
                <Para fontSize={14} fontWeight={500} color={theme.primary.default}>
                  Load more
                </Para>
              </TextButton>
            )}
            {isLoading && (
              <Grid alignItems="center">
                <SpinLoader base="14px" />
                <Para className="ml-10">Loading events</Para>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <DialogModalFrame
        id="incident_details__description_event_modal"
        onClose={closeDedupeModal}
        padding="16px"
      >
        {viewDeduped !== null && (
          <Grid type="column">
            <Para fontWeight={500} color={theme.shades.cement} fontSize={24} className="pr-20">
              {viewDeduped.message}
            </Para>
            <Para className="mt-10" fontSize={14}>
              {moment(viewDeduped.time_of_creation).format('MMM DD YYYY h:mm:ss a')}
            </Para>
            {DedupReasonComponent(viewDeduped)}
            <div
              className="mt-20"
              style={{ color: 'var(--shades-cement)', textDecoration: 'none', overflow: 'auto' }}
            >
              {viewDeduped.payload && (
                <Grid alignItems="center">
                  <SwitchBlock
                    options={['Details', 'Payload']}
                    selected={[descriptionView]}
                    onSelectValue={handleDisplayChange}
                  />
                  <div className="incident_details__copy-incident ml-10">
                    <CopyIconButton label="Copy event payload" type="transparent">
                      {JSON.stringify(viewDeduped.payload)}
                    </CopyIconButton>
                  </div>
                </Grid>
              )}
              {descriptionView === 'Details' ? (
                <ReactMarkdown
                  className="incident_details__mde"
                  plugins={[breaks, gfm]}
                  renderers={{ link: renderMdLink, code: CustomCodeRenderer }}
                  source={viewDeduped.description}
                />
              ) : (
                <div className="mt-10">
                  <JSONCodeBlock
                    code={viewDeduped.payload}
                    enableSearch={true}
                    shellProps={{
                      minHeight: '700px',
                      minWidth: 'calc(100% - 15px)',
                    }}
                  />
                </div>
              )}
            </div>
          </Grid>
        )}
      </DialogModalFrame>
    </Grid>
  );
};
