import { EMPTY_STRING } from 'views/main/organization/incident-list/common/constants';
import { UNSNOOZE_TYPES } from './type';

export const getDisableStatus = (selectedReassignmentValue: UNSNOOZE_TYPES, selectedId: string) => {
  if (selectedReassignmentValue === EMPTY_STRING) return true;
  else if (
    Boolean(selectedReassignmentValue === UNSNOOZE_TYPES.REASSIGN_TO_SELF) ||
    Boolean(selectedReassignmentValue === UNSNOOZE_TYPES.REASSIGN_TO_USER_WHO_SNOOZED)
  )
    return false;
  else if (
    Boolean(selectedReassignmentValue === UNSNOOZE_TYPES.REASSIGN_TO_DIFFERENT_USER) &&
    selectedId.length !== 0
  )
    return false;
  return true;
};
