import React, { useEffect, useState } from 'react';
import { Row } from '@squadcast/alchemy-ui/carbon';
import { InputBox, TextButton } from '@squadcast/alchemy-ui/metal';
import { Label, MdBlock } from 'uie/components';
import { exception, FileUploadService, PublicMessageService } from 'core';
import { FileUploadFeature, OperationType } from 'core/services/service.fileUpload';
import { fileUploadViewConfig, fileUploadHelperText } from 'views/config/fileUploadConfig';
import { IComponentErrorState } from 'core/interfaces/IComponentState';
const { imageTexts, ...fileUploadConfig } = fileUploadViewConfig;

interface IMessage {
  _id: string;
  message: string;
  status: string;
  time: Date;
  statusPageId: string;
  incidentId: string;
}
interface IProps {
  messageObject: IMessage;
  checkAndSetDirty: () => void;
  fileUploadService: FileUploadService;
  onCancel: () => void;
  onSave: (success: boolean, successMessage: string) => void;
}

export default function EditMessage(props: IProps) {
  const _PMService = new PublicMessageService({
    incidentId: props.messageObject.incidentId,
  });
  const fileUploadService = new FileUploadService();
  const [message, setMessage] = useState<string>(' ');
  const [status, setStatus] = useState<string>(' ');
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<IComponentErrorState>({});

  useEffect(() => {
    if (props.messageObject) {
      const propsMessage = props.messageObject;
      setMessage(() => propsMessage.message);
      setStatus(() => propsMessage.status);
    }
  }, []);
  useEffect(() => {
    if (isDirty) props.checkAndSetDirty();
  }, [isDirty]);
  function changeHandler(changeString: string, field: 'm' | 's') {
    !isDirty && setIsDirty(true);
    if (field === 'm') {
      setMessage(changeString);
    }
    if (field === 's') {
      setStatus(changeString);
    }
  }
  const editMessage = async () => {
    if (!message) {
      setErrors({ message: 'Message is required' });
      return;
    }
    if (!status) {
      setErrors({ status: 'Status is required' });
      return;
    }
    let success = true,
      successMessage = 'Successfully Updated';
    setLoading(true);
    try {
      await _PMService.editMessage({
        messageId: props.messageObject._id,
        status: status,
        message: message,
        statusPageId: props.messageObject.statusPageId,
        attachments: fileUploadService.accessAttachmentArrayForStatusPage(),
      });
      fileUploadService.emptyAttachmentArrayForStatusPage();
    } catch (err) {
      success = false;
      successMessage = 'Error While updating. Please try again.';
      exception.handle('E_UPDATE_STATUS_PAGE', err);
    } finally {
      setLoading(false);
      props.onSave(success, successMessage);
    }
  };
  return (
    <div>
      <div className="clearfix" style={{ paddingBottom: '10px' }}>
        <div className="float-left">
          <h1 className="modal-container-heading">Edit Message</h1>
        </div>
      </div>
      <Row flexWidth={12}>
        <div className="w-1-1 mr-10">
          <InputBox
            id="sp_name"
            name="Header"
            error={errors.status}
            type="text"
            label="Status"
            placeHolder="Incident Update Status"
            value={status}
            onChange={e => {
              changeHandler(e.target.value, 's');
            }}
          />
        </div>
      </Row>

      <div className="mt-20">
        <Label fontSize={14} fontWeight={400}>
          Custom Message
        </Label>
        <MdBlock
          value={message}
          onChange={e => {
            changeHandler(e, 'm');
          }}
          className="mt-10 mb-20"
          error={!!errors.e_message}
          options={{
            spellChecker: true,
            placeholder: '(Supports markdown)',
            imageUploadFunction: props.fileUploadService.getUploadFunctionForFeature(
              FileUploadFeature.STATUS_PAGE,
              OperationType.UPDATE,
              props.messageObject?.statusPageId,
            ),
            imageTexts: {
              ...imageTexts,
              sbInit: '',
            },
            ...fileUploadConfig,
            renderImageUploadsAsLinks: false,
          }}
        />
      </div>
      <div className="mt-20" style={{ display: 'flex' }}>
        <TextButton
          id="header_modal_save"
          onClick={editMessage}
          size="normal"
          text="Save"
          loading={loading}
        />
        <TextButton type="ghost" id="header_modal_save" text="Cancel" onClick={props.onCancel} />
      </div>
    </div>
  );
}
