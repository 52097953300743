import React, { useReducer } from 'react';
import { UseMutationOptions, useQueryClient } from 'react-query';

import {
  FILTER_TYPES,
  filterDetails,
  queryKeys,
  reactQueryConfig,
} from '../constants/status.constants';
import {
  DeleteStatusPageMutation,
  DeleteStatusPageMutationVariables,
  useDeleteStatusPageMutation,
} from '../graphql/mutation';
import { useGetStatusPageListQuery } from '../graphql/query';
import { getPageTypeFilterValue } from '../helpers/helper.details';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/helper.toast';
import { StatusListReducer } from '../reducers/StatusListReducer';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_DELETED } from 'core/const/tracker';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';
import { EntityACLMeta } from 'core/userAccess/types';

const useDeletePage = <TError = unknown, TContext = unknown>(
  onSuccess: () => void,
  options?: UseMutationOptions<
    DeleteStatusPageMutation,
    TError,
    DeleteStatusPageMutationVariables,
    TContext
  >,
) => {
  const queryClient = useQueryClient();
  return useDeleteStatusPageMutation({
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.GET_STATUS_PAGE_LIST]);
      onSuccess();
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_DELETED);
    },
    onError: reactQueryConfigError('Delete status page'),
  });
};

export function useStausPageList(teamId: string) {
  const [state, dispatch] = useReducer(StatusListReducer, {
    search: {
      searchText: '',
      enabled: false,
    },
    pagination: {
      queryPageIndex: 1,
      queryPageSize: 5,
    },
    drawer: {
      isDrawerOpen: false,
      drawerType: null,
      pageId: -1,
    },
    filters: {
      [FILTER_TYPES.PAGE_TYPE]: [],
      [FILTER_TYPES.STATUS]: [],
    },
    deleteStatusPage: {
      showModal: false,
      selectedPageID: -1,
    },
    filterCount: 0,
  });

  const statusPageData = useGetStatusPageListQuery(
    {
      input: {
        pageNumber: state.pagination.queryPageIndex,
        pageSize: state.pagination.queryPageSize,
        teamID: teamId,
        filters: {
          statusSlugs: state.filters[FILTER_TYPES.STATUS],
          search: state.search.searchText,
          isPublic: getPageTypeFilterValue(state.filters[FILTER_TYPES.PAGE_TYPE]),
        },
      },
    },
    {
      ...reactQueryConfig,
      onSuccess: data => {
        const acl = data.listStatusPages.data?.reduce((acc, curr) => {
          acc[curr?.id ?? ''] = {
            has_delete_access: curr?.acl?.hasDeleteAccess ?? false,
            has_update_access: curr?.acl?.hasUpdateAccess ?? false,
            has_update_owner_access: curr?.acl?.hasUpdateOwnerAccess ?? false,
          };
          return acc;
        }, {} as Record<string, EntityACLMeta>);
        setUserAccessStore({
          entityACLMap: {
            ...getUserAccessStore().entityACLMap,
            status_pages: {
              ...getUserAccessStore().entityACLMap?.status_pages,
              ...acl,
            },
          },
        });
      },
    },
  );

  const { mutateAsync: deleteIssueApi } = useDeletePage(() => {
    if (typeof statusPageData.refetch === 'function') {
      statusPageData.refetch();
    }
    reactQueryConfigSuccess('Status page deleted');
  });

  const deleteStatusPageAction = (id: number) => {
    deleteIssueApi({ id: id });
  };

  return {
    list: statusPageData?.data?.listStatusPages?.data || [],
    totalCount: statusPageData?.data?.listStatusPages.meta.totalCount || 0,
    orgTotalCount: statusPageData?.data?.listStatusPages.meta.orgTotalCount || 0,
    isLoading: statusPageData.isLoading,
    isFetched: statusPageData.isFetched,
    isError: statusPageData.isError,
    isSuccess: statusPageData.isSuccess,
    refetch: statusPageData.refetch,
    state,
    dispatch,
    filterDetails,
    deleteStatusPageAction,
  };
}
