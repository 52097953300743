import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Link: ComponentStyleConfig = {
  variants: {
    underlinedLink: {
      color: 'blue.310',
      textDecoration: 'underline',
    },
    italic: {
      fontSize: 12,
      fontStyle: 'italic',
      textDecoration: 'underline',
      color: 'default',
    },
  },
};
