import { useEffect } from 'react';
import { Box, BoxProps, Divider, Flex, HStack, Text, VStack } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { ITemplateMessage } from 'views/main/organization/statuspage-v2/Interface';
import { StepCircle } from './StepperCircle';
import { MdBlock } from 'uie/components';
import { debounce, FileUploadService } from 'core';
import { FileUploadFeature, OperationType } from 'core/services/service.fileUpload';
import { fileUploadViewConfig } from 'views/main/organization/statuspage-v2/constants/schema';
import { StatusPageAction } from 'views/main/organization/workflow/types';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { THEME_COLORS } from 'library/theme/colors';
import FormField from 'library/molecules/FormField/FormField';

interface StepperProps extends BoxProps {
  template: ITemplateMessage;
  idx: number;
  stateMessageCount: number;
  readOnly: boolean;
  showDisclaimer: boolean;
  isResolved: boolean;
}

const { imageTexts, ...fileUploadConfig } = fileUploadViewConfig;

const StatusStepper = (props: StepperProps) => {
  const { idx, template, readOnly, isResolved } = props;
  const {
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<StatusPageAction>();

  const statusData = getValues('data.status_and_message');
  const statusMessages = watch(`data.status_and_message.${idx}.messages`);

  const statusName: { [key: number]: string } = {
    0: 'Investigating',
    1: 'Identified',
    2: 'Monitoring',
    3: 'Resolved',
  };

  const orientation = useBreakpointValue<'horizontal' | 'vertical'>({
    base: 'vertical',
    md: 'vertical',
  });

  const { append, replace } = useFieldArray({
    name: `data.status_and_message.${idx}.messages`,
  });

  const renderMdLink = (props: any) => (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );

  const fileUploadService = new FileUploadService();

  const debounceInput = debounce((value: string, index: number) => {
    setValue(`data.status_and_message.${idx}.messages.${index}`, value, { shouldDirty: true });
  }, 800);

  const totalMessagesCount = () => {
    let totalMessageLength = 0;
    statusData.map(status => {
      totalMessageLength += status.messages.length;
    });
    return totalMessageLength;
  };

  useEffect(() => {
    if (totalMessagesCount() === 0) {
      append(template.defaultMessage);
    }
    if (watch(`data.status_and_message.${idx}.messages`).length === 0) {
      replace([]);
    }
  }, [statusData]);

  return (
    <HStack spacing="5" align="stretch" flex="1">
      <VStack spacing="0" align="center" justify={'start'}>
        <StepCircle
          isStatusActive={getValues(`data.status_and_message.${idx}.messages`)?.length > 0}
        />

        <Divider orientation={orientation} borderWidth="1px" />
      </VStack>
      <VStack spacing="0" align="flex-start" flex="1">
        <HStack
          mb={2}
          cursor="pointer"
          onClick={() => {
            if (statusMessages?.length < 1) {
              append(template.defaultMessage);
            }
          }}
        >
          <Text color="emphasized" fontWeight="medium">
            {statusName[idx]}
          </Text>
        </HStack>

        {idx === 3 &&
        statusMessages.length < 2 &&
        getValues(`data.status_and_message.${idx}.messages`)?.length > 0 ? (
          <Text fontSize="sm" color={'red.500'}>
            Note: Resolving this would mean you are closing this issue and you will no longer be
            able to add new updates to this issue.
          </Text>
        ) : null}

        <Box w="98%">
          {getValues(`data.status_and_message.${idx}.messages`)?.map((_, index: number) => {
            return (
              <Flex key={index} mb={4} direction="column" flex="1" ml={3}>
                <Box>
                  <FormField
                    error={errors?.data?.status_and_message?.[idx]?.messages?.[index]?.message}
                  >
                    <MdBlock
                      value={
                        getValues(`data.status_and_message.${idx}.messages.${index}`) ??
                        template.defaultMessage
                      }
                      onChange={content => {
                        debounceInput(content, index);
                      }}
                      options={{
                        maxHeight: '130px',
                        spellChecker: false,
                        placeholder: '',
                        imageUploadFunction: fileUploadService.getUploadFunctionForFeature(
                          FileUploadFeature.STATUS_PAGE,
                          OperationType.CREATE,
                        ),
                        imageTexts: {
                          sbInit: ``,
                          ...imageTexts,
                        },
                        ...fileUploadConfig,
                      }}
                    />
                  </FormField>
                  <Box mt={4}>
                    <Button
                      variant="secondary"
                      hidden={totalMessagesCount() < 2 || readOnly || isResolved}
                      onClick={() => {
                        setValue(
                          `data.status_and_message.${idx}.messages`,
                          getValues(`data.status_and_message.${idx}.messages`)?.filter(
                            (_, id) => id !== index,
                          ),
                        );
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Flex>
            );
          })}
          {statusData?.filter(status => status.status_id === template?.id)[0]?.messages?.length >
          0 ? (
            <Button
              style={{
                color: THEME_COLORS.brand.blue,
                marginBottom: 15,
                background: 'unset',
                border: 'none',
              }}
              hidden={readOnly || isResolved}
              disabled={statusMessages?.length === 5 || false}
              onClick={() => {
                append(template.defaultMessage);
              }}
            >
              Add another update
            </Button>
          ) : null}
        </Box>
      </VStack>
    </HStack>
  );
};

export default StatusStepper;
