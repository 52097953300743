import { IEntityOwner } from 'core/interfaces/IEscalationPolicy';
import { ISquad } from 'core/interfaces/ISquad';
import { ITeam, ITeamMember } from 'core/interfaces/ITeams';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { SquadIcon, TeamIcon, UserIcon } from 'icons';
import Select, { components, GroupBase, StylesConfig } from 'react-select';
import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { Tooltip, usernameTooltipLabel } from 'library/atoms';
import { truncate } from 'core/helpers/stringUtils';
import { useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';

interface EntityOwnerParams {
  currentTeam: ITeam;
  squads: ISquad[];
  users: IUsersOfOrganization[];
  entityOwner?: IEntityOwner;
}

interface Props extends EntityOwnerParams {
  onEntityOwnerChange: (entityOwner: IEntityOwner) => void;
}

interface ReactSelectOptions {
  label: string;
  value: string;
  type: string;
  username?: string;
}

const { Option } = components;

const styles: StylesConfig<ReactSelectOptions, false, GroupBase<ReactSelectOptions>> = {
  input: (provided, { selectProps: { inputValue, isMulti } }) => ({
    ...provided,
    ...(!(inputValue || isMulti) ? { position: 'absolute' } : {}),
  }),
};

export function OwnerEntitiesSelect(props: Props) {
  const options: ReactSelectOptions[] = [];

  const currentUser = useSelector((state: IAppState) => state.organization.currentUser.u);

  const label = currentUser?.username_for_display
    ? `${currentUser?.first_name} ${currentUser?.last_name} (${currentUser?.username_for_display})`
    : `${currentUser?.first_name} ${currentUser?.last_name}`;
  const defaultValue = {
    label: label,
    value: currentUser?.id ?? '',
    username: currentUser?.username_for_display ?? '',
    type: 'user',
  };
  const squadsOptionsArray = props.squads
    .map<ReactSelectOptions>(squad => {
      return {
        label: squad.name,
        type: 'squad',
        value: squad.id,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const usersOptionsArray = getUserOptionsArray(props.currentTeam.members, props.users);

  options.push(...usersOptionsArray, ...squadsOptionsArray);

  return (
    <Select
      options={options}
      components={{ Option: IconOptions, SingleValue }}
      value={getEntityOwnerValue(props)}
      onChange={entity => props.onEntityOwnerChange({ id: entity!.value, type: entity!.type })}
      styles={styles}
      defaultValue={defaultValue}
    />
  );
}

function mapUserIdWithName(users: IUsersOfOrganization[]) {
  const userMap = new Map<string, { name: string; username: string }>();
  for (const user of users) {
    if (!userMap.has(user.id) && user.role !== 'stakeholder') {
      userMap.set(user.id, {
        name: `${user.first_name} ${user.last_name}`,
        username: user.username_for_display,
      });
    }
  }

  return userMap;
}

function IconOptions(props: any) {
  return (
    <Option {...props}>
      <VStack alignItems={'left'}>
        <HStack>
          <Icon
            mr={2}
            as={
              props.data.type === 'user'
                ? UserIcon
                : props.data.type === 'squad'
                ? SquadIcon
                : TeamIcon
            }
          />
          <Text>{props.data.label}</Text>
        </HStack>
        {props.data.username && (
          <span style={{ marginLeft: '32px', color: '#8890A2' }}>{props.data.username}</span>
        )}
      </VStack>
    </Option>
  );
}

function SingleValue(props: any) {
  const labelWithoutUsername = props.data.label.replace(` (${props.data.username})`, '');
  return (
    <components.SingleValue {...props}>
      <Tooltip
        label={usernameTooltipLabel(labelWithoutUsername, props.data.username)}
        isDisabled={!props.data.username}
        placement="top"
      >
        <Text>
          {truncate(labelWithoutUsername, 30)}
          {props.data.username && ` (${truncate(props.data.username, 30)})`}
        </Text>
      </Tooltip>
    </components.SingleValue>
  );
}

function getUserOptionsArray(members: ITeamMember[], users: IUsersOfOrganization[]) {
  const userMap = mapUserIdWithName(users);
  const usersOptionsArray: ReactSelectOptions[] = [];
  for (const member of members) {
    const userObject = userMap.get(member.user_id);
    if (!userObject?.name) {
      continue;
    }
    usersOptionsArray.push({
      label: userObject?.name,
      type: 'user',
      username: userObject?.username,
      value: member.user_id,
    });
  }

  return usersOptionsArray.sort((a, b) => a.label.localeCompare(b.label));
}

export function getEntityOwnerValue(props: EntityOwnerParams) {
  switch (props.entityOwner?.type) {
    case 'team':
      return {
        label: props.currentTeam.name,
        value: props.currentTeam.id,
        type: 'team',
      };
    case 'squad':
      const squadEntity = props.squads.find(squad => squad.id === props.entityOwner?.id);
      if (squadEntity) {
        return {
          label: squadEntity.name,
          value: squadEntity.id,
          type: 'squad',
        };
      }
      break;

    case 'user':
      const userEntity = props.users.find(user => user.id === props.entityOwner?.id);
      const label = userEntity?.username_for_display
        ? `${userEntity?.first_name} ${userEntity?.last_name} (${userEntity?.username_for_display})`
        : `${userEntity?.first_name} ${userEntity?.last_name}`;
      if (userEntity) {
        return {
          label: label,
          value: userEntity.id,
          username: userEntity.username_for_display,
          type: 'user',
        };
      }
      break;
  }
}
