import { Grid, Label } from 'uie/components';
import { T_WA_ENTITIES_TAB_VIEWED, T_WA_TEAM_ROLE_VIEWED } from 'core/const/tracker';
import { TickBadge } from 'icons';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppTracker } from 'shared/analytics/tracker';
import styled from 'styled-components';
import { Container } from './styles';

const ROLE_ABILITIES = [
  {
    ability: 'View Team',
    member: true,
    owner: true,
    stakeholder: true,
  },
  {
    ability: 'Create Entities',
    member: true,
    owner: true,
    stakeholder: false,
  },
  {
    ability: 'Create Squads',
    member: true,
    owner: true,
    stakeholder: false,
  },
  {
    ability: 'Add Team Members',
    member: false,
    owner: true,
    stakeholder: false,
  },
  {
    ability: 'Remove Team Members',
    member: false,
    owner: true,
    stakeholder: false,
  },
  {
    ability: 'Create Stakeholder Groups',
    member: false,
    owner: true,
    stakeholder: false,
  },
  {
    ability: 'Modify Stakeholder Groups',
    member: false,
    owner: true,
    stakeholder: false,
  },
  {
    ability: 'Delete Stakeholder Groups',
    member: false,
    owner: true,
    stakeholder: false,
  },
  {
    ability: 'Convert Team Member to Team Owner',
    member: false,
    owner: true,
    stakeholder: false,
  },
  {
    ability: 'Convert Team Owner to Team Member',
    member: false,
    owner: true,
    stakeholder: false,
  },
];

export function SquadBasedPermissionRoles() {
  const teamId = useParams<{ id: string }>().id;

  useEffect(() => {
    AppTracker.track(T_WA_TEAM_ROLE_VIEWED, {
      'Team ID': teamId,
    });
  }, [teamId]);

  return (
    <Container>
      <div className={'main_title'}>
        Roles help you assign permissions for features within Squadcast. Learn more about it{' '}
        <a
          style={{
            color: '#0E61DD',
          }}
          href="https://support.squadcast.com/manage-teams/squads"
          target="_blank"
          rel="noreferrer"
        >
          {' '}
          here
        </a>
      </div>

      <div>
        <Grid flexWidth={12} alignItems="center">
          <Grid flexWidth={3} alignItems="center">
            <Label
              style={{
                fontSize: '14px',
                lineHeight: '150%',
                paddingRight: '18px',
                paddingTop: '9px',
                paddingBottom: '9px',
              }}
            >
              Action
            </Label>
          </Grid>
          <Grid flexWidth={2}>
            <Label
              style={{
                fontSize: '14px',
                lineHeight: '150%',
              }}
            >
              Stakeholder
            </Label>
          </Grid>
          <Grid flexWidth={2}>
            <Label
              style={{
                fontSize: '14px',
                lineHeight: '150%',
              }}
            >
              Team Member
            </Label>
          </Grid>
          <Grid flexWidth={2}>
            <Label
              style={{
                fontSize: '14px',
                lineHeight: '150%',
              }}
            >
              Team Owner
            </Label>
          </Grid>
        </Grid>
        {ROLE_ABILITIES.map((ra, index) => (
          <Grid flexWidth={12} alignItems="center" key={ra.ability}>
            <Grid flexWidth={3} alignItems="center">
              <Label
                style={{
                  fontSize: '15px',
                  lineHeight: '150%',
                  color: '#122146',
                  paddingRight: '18px',
                  paddingTop: '9px',
                  paddingBottom: '9px',
                }}
              >
                {ra.ability}
              </Label>
            </Grid>
            <Grid flexWidth={2}>
              {ra.stakeholder && <StyledTickIcon style={{ height: '10px', width: '14px' }} />}
            </Grid>
            <Grid flexWidth={2}>
              {ra.member && <StyledTickIcon style={{ height: '10px', width: '14px' }} />}
            </Grid>
            <Grid flexWidth={2}>
              <Label
                style={{
                  fontSize: '14px',
                  lineHeight: '150%',
                }}
              >
                {ra.owner && (
                  <StyledTickIcon fill="red" style={{ height: '10px', width: '14px' }} />
                )}
              </Label>
            </Grid>
          </Grid>
        ))}
      </div>
    </Container>
  );
}

const StyledTickIcon = styled(TickBadge)`
  path {
    fill: #122146;
  }
`;
