import { DialogModalFrame, Para, TextButton } from 'uie/components';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import React, { useEffect } from 'react';
import { DeleteSquadConflictData, SquadMember } from '../../types';
import ConflictModalContent from './Content';
import { DeleteSquadModalContent } from './EntitiesConflictContent';

type Props = {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  squadName: string;
  entities: DeleteSquadConflictData;
};
export function DeleteSquadConflict({ openModal, setOpenModal, entities, squadName }: Props) {
  return (
    <DialogModalFrame
      root="rsg-root"
      id="modalFrame"
      onClose={() => setOpenModal(false)}
      padding="24px"
      width="500px"
    >
      {openModal && <DeleteSquadModalContent entities={entities} squadName={squadName} />}
    </DialogModalFrame>
  );
}
