import { Box } from '@chakra-ui/react';
import { Tooltip } from 'library/atoms';
import { InfoIcon } from 'library/icons';

type Props = {
  label: string;
  color?: string;
  pl?: number;
  size?: 'md' | 'lg';
};

export const InfoToolTip = ({ label, color = 'brand.black', pl = 2, size = 'md' }: Props) => {
  const sizeValue = size === 'md' ? 12 : 18;

  return (
    <Tooltip label={label} placement="top">
      <Box pl={pl}>
        <InfoIcon color={color} width={sizeValue} height={sizeValue} />
      </Box>
    </Tooltip>
  );
};
