import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const SwitchTheme: ComponentStyleConfig = {
  baseStyle: props => {
    const { size, isDisabled, active } = props;
    return {
      '--scale-factor': '5',
      '--switch-offset-x': active ? '1' : '0',
      borderRadius: '1000px',
      position: 'relative',
      backgroundColor: active
        ? isDisabled
          ? '#8CBEF7'
          : '#1B7FF1'
        : isDisabled
        ? '#ECEFF3'
        : '#E1E6EB',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      _hover: {
        backgroundColor: !isDisabled ? (active ? '#127DF6' : '#A7B4C3') : undefined,
      },
    };
  },
  variants: {
    default: {},
    slider: {
      backgroundColor: 'brand.blue',
      boxSizing: 'border-box',
      padding: '2px',
      width: '25px',
      _after: {
        transform: 'translateY(-50%) translateX(calc(var(--switch-offset-x) * 12px))',
      },
      _hover: {
        backgroundColor: 'brand.lightBlue',
      },
    },
  },
  sizes: {
    // 1440px
    md: {
      '--switch-offset-y': '1.5px',
      height: '12px',
      width: '19.5px',
      padding: '1.5px',
      _after: {
        position: 'absolute',
        content: '""',
        transition: '.25s linear transform',
        borderRadius: '50%',
        top: '50%',
        transform: 'translateY(-50%) translateX(calc(var(--switch-offset-x) * calc(50% + 2.5px)))',
        height: '9px',
        width: '9px',
        outline: 'none',
        backgroundColor: 'white',
      },
    },
    // 1920
    lg: {
      '--switch-offset-y': '2px',
      height: '16px',
      width: '26px',
    },
  },
  defaultProps: {
    size: 'md',
  },
};
