import { IPostmortemTemplate } from 'core/interfaces/IPostmortems';
import React from 'react';
import { FocusBlock, Grid, Label, Para, SelectBox } from 'uie/components';
type Props = {
  postmortemTemplates: IPostmortemTemplate[];
  selectedPostmortemTemplate: IPostmortemTemplate | null;
  loading:
    | 'get_templates'
    | 'creating_postmortem'
    | 'updating'
    | 'deleting'
    | 'downloading_pdf'
    | 'downloading_md'
    | '';
  handleSelectPostmortemTemplate: (_: any, pt: IPostmortemTemplate) => void;
  onSearch: (value: string) => void;
  searchValue: string;
  show: boolean;
};
export function SelectPostMortemTemplate({
  loading,
  handleSelectPostmortemTemplate,
  postmortemTemplates,
  selectedPostmortemTemplate,
  onSearch,
  searchValue,
  show,
}: Props) {
  if (!show) return null;
  return (
    <Grid className="mb-20" type="column">
      <Label fontWeight={400} fontSize={14} className="mb-10">
        Select Postmortem Template
      </Label>
      <SelectBox
        hook={
          <Para className="ml-10" fontWeight={500} style={{ textAlign: 'left' }}>
            {postmortemTemplates.length === 0 && loading !== 'get_templates'
              ? 'No Templates Found'
              : selectedPostmortemTemplate
              ? selectedPostmortemTemplate.name
              : 'Please Select a Template'}
          </Para>
        }
        onValueChange={handleSelectPostmortemTemplate}
        searchHookProps={{
          value: searchValue,
          height: '24px',
          fontSize: '16px',
          onChange: e => onSearch(e.target.value),
        }}
        disabled={postmortemTemplates.length === 0}
        width="100%"
        maxWidth="100%"
        padding="0"
        height="auto"
        maxHeight="200px"
      >
        {postmortemTemplates
          .filter(pt =>
            searchValue ? pt.name.toLowerCase().includes(searchValue.toLowerCase()) : true,
          )
          .map((pt, idx) => (
            <FocusBlock
              key={idx}
              value={pt}
              isSelected={pt.id === selectedPostmortemTemplate?.name}
            >
              <Para className="ml-10">{pt.name}</Para>
            </FocusBlock>
          ))}
      </SelectBox>
    </Grid>
  );
}
