import * as Types from './generated/types';
import { useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from './fetcher/index';
export type AcknowledgeIncidentMutationVariables = Types.Exact<{
  incidentID: Types.Scalars['String'];
}>;

export type AcknowledgeIncidentMutation = {
  __typename?: 'Mutation';
  acknowledgeIncident: { __typename?: 'IncidentResponse'; id: string; status: string };
};

export type ResolveIncidentMutationVariables = Types.Exact<{
  incidentID: Types.Scalars['String'];
}>;

export type ResolveIncidentMutation = {
  __typename?: 'Mutation';
  resolveIncident: { __typename?: 'IncidentResponse'; id: string; status: string };
};

export type ReassignIncidentMutationVariables = Types.Exact<{
  input: Types.ReassignIncident;
}>;

export type ReassignIncidentMutation = {
  __typename?: 'Mutation';
  reassignIncident: {
    __typename?: 'IncidentResponse';
    id: string;
    status: string;
    assignedToID: string;
    assignedToType: string;
  };
};

export type UpdateIncidentTagsMutationVariables = Types.Exact<{
  incidentID: Types.Scalars['String'];
  tags: Array<Types.IncidentTagInput> | Types.IncidentTagInput;
}>;

export type UpdateIncidentTagsMutation = {
  __typename?: 'Mutation';
  updateIncidentTags: Array<{
    __typename?: 'IncidentTag';
    key: string;
    value: string;
    color: string;
  }>;
};

export type StarIncidentMutationVariables = Types.Exact<{
  incidentID: Types.Scalars['String'];
  starred: Types.Scalars['Boolean'];
}>;

export type StarIncidentMutation = { __typename?: 'Mutation'; starIncident: string };

export type CreateIncidentGroupMutationVariables = Types.Exact<{
  teamID: Types.Scalars['String'];
  name: Types.Scalars['String'];
  isPrivate: Types.Scalars['Boolean'];
  filters?: Types.InputMaybe<Types.IncidentFilters>;
}>;

export type CreateIncidentGroupMutation = { __typename?: 'Mutation'; createIncidentGroup: number };

export type UpdateIncidentGroupMutationVariables = Types.Exact<{
  incidentGroupID: Types.Scalars['Int'];
  name: Types.Scalars['String'];
  filters?: Types.InputMaybe<Types.IncidentFilters>;
}>;

export type UpdateIncidentGroupMutation = { __typename?: 'Mutation'; updateIncidentGroup: number };

export type DeleteIncidentGroupMutationVariables = Types.Exact<{
  incidentGroupID: Types.Scalars['Int'];
}>;

export type DeleteIncidentGroupMutation = { __typename?: 'Mutation'; deleteIncidentGroup: number };

export type BulkReassignIncidentMutationVariables = Types.Exact<{
  input: Types.BulkReassignIncidentInput;
}>;

export type BulkReassignIncidentMutation = {
  __typename?: 'Mutation';
  bulkReassignIncident: {
    __typename?: 'BulkReAssignIncidentResponse';
    isSuccess: boolean;
    errMsg: string;
    errors: Array<{ __typename?: 'BulkReassignIncidentError'; errMsg: string; incidentID: string }>;
  };
};

export type CreateIncidentMutationVariables = Types.Exact<{
  input: Types.CreateIncidentReq;
}>;

export type CreateIncidentMutation = { __typename?: 'Mutation'; createIncident: string };

export const AcknowledgeIncidentDocument = `
    mutation acknowledgeIncident($incidentID: String!) {
  acknowledgeIncident(incidentID: $incidentID) {
    id
    status
  }
}
    `;
export const useAcknowledgeIncidentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AcknowledgeIncidentMutation,
    TError,
    AcknowledgeIncidentMutationVariables,
    TContext
  >,
) =>
  useMutation<AcknowledgeIncidentMutation, TError, AcknowledgeIncidentMutationVariables, TContext>(
    ['acknowledgeIncident'],
    (variables?: AcknowledgeIncidentMutationVariables) =>
      fetcher<AcknowledgeIncidentMutation, AcknowledgeIncidentMutationVariables>(
        AcknowledgeIncidentDocument,
        variables,
      )(),
    options,
  );
export const ResolveIncidentDocument = `
    mutation resolveIncident($incidentID: String!) {
  resolveIncident(incidentID: $incidentID) {
    id
    status
  }
}
    `;
export const useResolveIncidentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ResolveIncidentMutation,
    TError,
    ResolveIncidentMutationVariables,
    TContext
  >,
) =>
  useMutation<ResolveIncidentMutation, TError, ResolveIncidentMutationVariables, TContext>(
    ['resolveIncident'],
    (variables?: ResolveIncidentMutationVariables) =>
      fetcher<ResolveIncidentMutation, ResolveIncidentMutationVariables>(
        ResolveIncidentDocument,
        variables,
      )(),
    options,
  );
export const ReassignIncidentDocument = `
    mutation reassignIncident($input: ReassignIncident!) {
  reassignIncident(input: $input) {
    id
    status
    assignedToID
    assignedToType
  }
}
    `;
export const useReassignIncidentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ReassignIncidentMutation,
    TError,
    ReassignIncidentMutationVariables,
    TContext
  >,
) =>
  useMutation<ReassignIncidentMutation, TError, ReassignIncidentMutationVariables, TContext>(
    ['reassignIncident'],
    (variables?: ReassignIncidentMutationVariables) =>
      fetcher<ReassignIncidentMutation, ReassignIncidentMutationVariables>(
        ReassignIncidentDocument,
        variables,
      )(),
    options,
  );
export const UpdateIncidentTagsDocument = `
    mutation updateIncidentTags($incidentID: String!, $tags: [IncidentTagInput!]!) {
  updateIncidentTags(incidentID: $incidentID, tags: $tags) {
    key
    value
    color
  }
}
    `;
export const useUpdateIncidentTagsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateIncidentTagsMutation,
    TError,
    UpdateIncidentTagsMutationVariables,
    TContext
  >,
) =>
  useMutation<UpdateIncidentTagsMutation, TError, UpdateIncidentTagsMutationVariables, TContext>(
    ['updateIncidentTags'],
    (variables?: UpdateIncidentTagsMutationVariables) =>
      fetcher<UpdateIncidentTagsMutation, UpdateIncidentTagsMutationVariables>(
        UpdateIncidentTagsDocument,
        variables,
      )(),
    options,
  );
export const StarIncidentDocument = `
    mutation starIncident($incidentID: String!, $starred: Boolean!) {
  starIncident(incidentID: $incidentID, starred: $starred)
}
    `;
export const useStarIncidentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    StarIncidentMutation,
    TError,
    StarIncidentMutationVariables,
    TContext
  >,
) =>
  useMutation<StarIncidentMutation, TError, StarIncidentMutationVariables, TContext>(
    ['starIncident'],
    (variables?: StarIncidentMutationVariables) =>
      fetcher<StarIncidentMutation, StarIncidentMutationVariables>(
        StarIncidentDocument,
        variables,
      )(),
    options,
  );
export const CreateIncidentGroupDocument = `
    mutation createIncidentGroup($teamID: String!, $name: String!, $isPrivate: Boolean!, $filters: IncidentFilters) {
  createIncidentGroup(
    teamID: $teamID
    name: $name
    isPrivate: $isPrivate
    filters: $filters
  )
}
    `;
export const useCreateIncidentGroupMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateIncidentGroupMutation,
    TError,
    CreateIncidentGroupMutationVariables,
    TContext
  >,
) =>
  useMutation<CreateIncidentGroupMutation, TError, CreateIncidentGroupMutationVariables, TContext>(
    ['createIncidentGroup'],
    (variables?: CreateIncidentGroupMutationVariables) =>
      fetcher<CreateIncidentGroupMutation, CreateIncidentGroupMutationVariables>(
        CreateIncidentGroupDocument,
        variables,
      )(),
    options,
  );
export const UpdateIncidentGroupDocument = `
    mutation updateIncidentGroup($incidentGroupID: Int!, $name: String!, $filters: IncidentFilters) {
  updateIncidentGroup(
    incidentGroupID: $incidentGroupID
    name: $name
    filters: $filters
  )
}
    `;
export const useUpdateIncidentGroupMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateIncidentGroupMutation,
    TError,
    UpdateIncidentGroupMutationVariables,
    TContext
  >,
) =>
  useMutation<UpdateIncidentGroupMutation, TError, UpdateIncidentGroupMutationVariables, TContext>(
    ['updateIncidentGroup'],
    (variables?: UpdateIncidentGroupMutationVariables) =>
      fetcher<UpdateIncidentGroupMutation, UpdateIncidentGroupMutationVariables>(
        UpdateIncidentGroupDocument,
        variables,
      )(),
    options,
  );
export const DeleteIncidentGroupDocument = `
    mutation deleteIncidentGroup($incidentGroupID: Int!) {
  deleteIncidentGroup(incidentGroupID: $incidentGroupID)
}
    `;
export const useDeleteIncidentGroupMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteIncidentGroupMutation,
    TError,
    DeleteIncidentGroupMutationVariables,
    TContext
  >,
) =>
  useMutation<DeleteIncidentGroupMutation, TError, DeleteIncidentGroupMutationVariables, TContext>(
    ['deleteIncidentGroup'],
    (variables?: DeleteIncidentGroupMutationVariables) =>
      fetcher<DeleteIncidentGroupMutation, DeleteIncidentGroupMutationVariables>(
        DeleteIncidentGroupDocument,
        variables,
      )(),
    options,
  );
export const BulkReassignIncidentDocument = `
    mutation bulkReassignIncident($input: BulkReassignIncidentInput!) {
  bulkReassignIncident(input: $input) {
    isSuccess
    errMsg
    errors {
      errMsg
      incidentID
    }
  }
}
    `;
export const useBulkReassignIncidentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    BulkReassignIncidentMutation,
    TError,
    BulkReassignIncidentMutationVariables,
    TContext
  >,
) =>
  useMutation<
    BulkReassignIncidentMutation,
    TError,
    BulkReassignIncidentMutationVariables,
    TContext
  >(
    ['bulkReassignIncident'],
    (variables?: BulkReassignIncidentMutationVariables) =>
      fetcher<BulkReassignIncidentMutation, BulkReassignIncidentMutationVariables>(
        BulkReassignIncidentDocument,
        variables,
      )(),
    options,
  );
export const CreateIncidentDocument = `
    mutation createIncident($input: CreateIncidentReq!) {
  createIncident(input: $input)
}
    `;
export const useCreateIncidentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateIncidentMutation,
    TError,
    CreateIncidentMutationVariables,
    TContext
  >,
) =>
  useMutation<CreateIncidentMutation, TError, CreateIncidentMutationVariables, TContext>(
    ['createIncident'],
    (variables?: CreateIncidentMutationVariables) =>
      fetcher<CreateIncidentMutation, CreateIncidentMutationVariables>(
        CreateIncidentDocument,
        variables,
      )(),
    options,
  );
