export const DEFAULT_LIST_SIZE = 2;
export const ASSIGNEE_NAME_MAX_LENGTH = 25;
export const ASSIGNEE_NAME_HOVER_MAX_LENGTH = 10;
export const RESPONDER_NAME_MAX_LENGTH = 15;
export const RESPONDERS_FEAT_NAME = 'additional-responders';

export const QUERY_KEY = {
  ADDITIONAL_RESPONDERS: 'additionalResponders',
  WATCHER: 'watch',
};
