import { Box, Container, HStack, Stack } from '@chakra-ui/react';
import * as React from 'react';
import { steps } from './data';
import { Step } from './Step';
import { useStep } from './useStep';

interface IProp {
  onChangeStep: (step: number) => void;
  setSliderValue: (step: number) => void;
  sliderValue: number;
}

export const ProgressSteps = (props: IProp) => {
  const [currentStep, { setStep, goToNextStep, goToPrevStep, canGoToNextStep, canGoToPrevStep }] =
    useStep({ maxStep: steps.length, initialStep: 2 });

  React.useEffect(() => {
    setStep(props.sliderValue);
  }, [props.sliderValue]);

  return (
    <Container maxW="container.md" centerContent bg="bg-surface">
      <HStack spacing="0" p={0} m={0}>
        {steps.map((step, id) => (
          <Step
            key={id}
            cursor="pointer"
            title={step.title}
            description={step.description}
            isActive={currentStep === id}
            isCompleted={currentStep > id}
            isFirstStep={id === 0}
            isLastStep={steps.length === id + 1}
            step={step.step}
            setSliderValue={props.setSliderValue}
          />
        ))}
      </HStack>
    </Container>
  );
};
