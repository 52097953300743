import { exception, IncidentService, ServiceService } from 'core';
import { IService } from 'core/interfaces/IService';
import React, { useEffect } from 'react';

const useGetAllServices = (teamId?: string) => {
  const _serviceService = new ServiceService('');
  const [services, setServices] = React.useState<IService[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (teamId) {
        try {
          const {
            data: { data: services },
          } = await _serviceService.getAll(teamId);

          setServices(services);
        } catch (err: any) {
          exception.handle('E_GET_ALL_SERVICES', err);
        }
      }
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 100);

    return () => clearTimeout(timer);
  }, [teamId]);

  return services;
};

export default useGetAllServices;
