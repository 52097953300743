import { INITIAL_STATE } from '../state';
import {
  APP_ACTION__GET,
  APP_ACTION__NEW_INCIDENT,
  APP_ACTION__NEW_INCIDENT_CONSUMED,
} from '../../const/appActions';

const initialAppState = INITIAL_STATE.APP_ACTION;

const appActions = (state = initialAppState, action: any) => {
  switch (action.type) {
    case APP_ACTION__GET:
      return {
        ...state,
        ...{ action: APP_ACTION__GET },
      };
    case APP_ACTION__NEW_INCIDENT:
      return {
        ...state,
        ...{ action: APP_ACTION__NEW_INCIDENT, act: action.payload.action },
      };
    case APP_ACTION__NEW_INCIDENT_CONSUMED:
      return {
        ...state,
        ...{ action: APP_ACTION__NEW_INCIDENT_CONSUMED, act: null },
      };
    default:
      return state;
  }
};

export default appActions;
