import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Text,
  useMultiStyleConfig,
  VStack,
} from '@chakra-ui/react';
import { SOKETI_RELOAD_EVENTS } from 'core/const/soketi';
import {
  T_WA_AUDIT_LOGS_EXPORT_HISTORY_PAGE_VIEWED,
  T_WA_AUDIT_LOGS_PAGE_VIEWED,
} from 'core/const/tracker';
import { Locale } from 'core/helpers/dateUtils';
import useSoketiBinding from 'core/hooks/useSoketiBinding';
import { IOrganizationEvents } from 'core/interfaces/IOrganization';
import { ThrottleConfig } from 'core/interfaces/IOrgConfig';
import { FormButton, IconButton } from 'library/atoms';
import { FilterIcon } from 'library/icons';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppTracker } from 'shared/analytics/tracker';
import { DEFAULT_THROTTLE_CONFIG } from 'views/main/organization/incident-list/common/constants';
import { useOrganizationConfig } from 'views/main/organization/schedules';
import { useAuditLogsListContext } from '../../context/auditLogsContext';
import { useAuditLogs } from '../../hooks';
import { AUDITLOGS_TABS } from '../../types/index.auditLogs';
import FilterDrawer from '../Drawer/FilterDrawer';
import LogDetails from '../Drawer/LogDetails';
import FilterTags from '../FilterTags';
import AuditLogsTable from './AuditLogsTable';
import ExportModal from './ExportModal';

interface AuditLogsListPropsType {
  dateFilterValue: string;
  status: AUDITLOGS_TABS;
}

function AuditLogsList(props: AuditLogsListPropsType) {
  const { dateFilterValue, status } = props;
  const styles = useMultiStyleConfig('PageHeader', {});
  const context = useAuditLogsListContext();
  const org = useOrganizationConfig();
  const throttleConfig: ThrottleConfig = DEFAULT_THROTTLE_CONFIG;

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);

  const { getAuditLog, getAuditLogsHistory } = useAuditLogs({
    pageNumber: context.paginationInfo.pageIndex + 1,
    pageSize: context.paginationInfo.pageSize,
    startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    endDate: moment(Date.now()).format('YYYY-MM-DD'),
  });

  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };

  const toggleExportModal = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };

  useEffect(() => {
    context.toggleActiveTab(status);
  }, [status]);

  useEffect(() => {
    if (status === AUDITLOGS_TABS.LIVE_LOGS) {
      AppTracker.track(T_WA_AUDIT_LOGS_PAGE_VIEWED, {
        'Date Range': 'Last 24 hours',
      });
    } else if (status === AUDITLOGS_TABS.EXPORT_HISTORY) {
      AppTracker.track(T_WA_AUDIT_LOGS_EXPORT_HISTORY_PAGE_VIEWED);
    }
  }, [status]);

  const debounceAndAutoReload = useCallback(
    debounce(
      () => {
        if (status === AUDITLOGS_TABS.LIVE_LOGS) {
          getAuditLog();
        } else {
          getAuditLogsHistory();
        }
      },
      throttleConfig.debounce_interval,
      { maxWait: throttleConfig.max_wait },
    ),
    [],
  );

  const auditLogsSocketBinding = useSoketiBinding({
    eventName: SOKETI_RELOAD_EVENTS,
    eventHandler: ({ message }: { message: IOrganizationEvents }) => {
      if (message === 'reload-audit-logs' && window.document.visibilityState === 'visible') {
        debounceAndAutoReload();
      }
    },
  });

  useEffect(() => {
    return auditLogsSocketBinding();
  }, []);

  const renderHeader = () => {
    if (status === AUDITLOGS_TABS.LIVE_LOGS)
      return (
        <Box p={4} pl={6} display="flex" justifyContent="space-between" width="100%">
          <VStack justify={'center'}>
            <Text>Showing Logs {dateFilterValue}</Text>
          </VStack>
          <Flex gap={2} alignItems="center">
            <HStack>
              <Text>Timezone: </Text>
              <Text>
                {`(${
                  Locale.namedOffsetWithAbbr
                }) ${org?.organization?.currentUser?.u?.time_zone?.replace(/_/g, ' ')}`}
              </Text>
            </HStack>
            <IconButton
              aria-label="Filter"
              variant="header"
              style={{ height: '24px', width: '24px', backgroundColor: '#A5CBFA4D' }}
              icon={<FilterIcon style={{ width: 12, height: 12, margin: 'auto' }} />}
              onClick={() => {
                toggleFilterDrawer();
              }}
              isDisabled={false}
            />
            <Center sx={{ ...styles.verticalDivider }}>
              <Divider orientation="vertical" />
            </Center>
            <FormButton
              onClick={toggleExportModal}
              style={{ minWidth: '65px', height: '36px' }}
              title="Export"
            />
          </Flex>
        </Box>
      );
  };

  const renderFilters = useMemo(() => {
    if (status === AUDITLOGS_TABS.LIVE_LOGS)
      return (
        <Box pl={6} width="100%">
          <FilterTags />
        </Box>
      );
  }, [context.appliedFilters]);

  return (
    <React.Fragment>
      {renderHeader()}
      {renderFilters}
      <AuditLogsTable status={status} />
      {isFilterDrawerOpen && (
        <FilterDrawer open={isFilterDrawerOpen} onClose={toggleFilterDrawer} />
      )}
      <LogDetails />
      {isExportModalOpen && <ExportModal isOpen={isExportModalOpen} onClose={toggleExportModal} />}
    </React.Fragment>
  );
}

export default AuditLogsList;
