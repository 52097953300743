import { Grid, Heading, Label, Para, PulseLoader, Radio, Theme } from 'uie/components';
import React, { useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { API } from 'core/api';
import { AppConfig } from 'shared/app.config';
import { isDomainValid, getProperty } from '../../../core/helpers';
import Axios from 'axios';

function SsoLogin() {
  const dataCenters = [
    { label: 'US', url: AppConfig.us_app_url + '/sso-login' },
    { label: 'EU', url: AppConfig.eu_app_url + '/sso-login' },
  ];
  const [domain, setDomain] = useState('');
  const [errors, setErrors] = useState<any>({});
  const [componentState, setComponentState] = useState('');
  const [chooseOrg, setChooseOrg] = useState(false);
  const [redirectDetails, setRedirectDetails] = useState<any>([]);

  const onRedirect = (url: string) => () => {
    window.location.assign(url);
  };

  const isCurrentDC = (url: string) => {
    return window.location.href === url;
  };

  const checkValidationErrors = () => {
    const errors: any = {};
    if (!isDomainValid(domain)) {
      errors.domain = 'Please enter a valid Domain';
    }
    setErrors(errors);
    return Object.entries(errors).length > 0;
  };

  const redirect = (url: string) => {
    window.location.href = url;
  };

  const login = async (e: any) => {
    if (e) {
      e.preventDefault();
    }
    if (checkValidationErrors()) {
      return;
    }

    try {
      const response = await Axios.get(`${API.config.sqerberos}/sso/login?domain=${domain}`);
      const ssoDetails = response.data.data.ssoDetails;
      if (ssoDetails.length === 1) {
        redirect(ssoDetails[0].redirectUrl);
      } else {
        setChooseOrg(true);
        setRedirectDetails(ssoDetails);
      }
    } catch (err: any) {
      const errors: any = {};
      errors.sso = err?.response?.data?.meta?.error_message;
      setErrors(errors);
    }
  };

  return (
    <div className="login-container">
      <div className="login-interact">
        <Heading
          fontSize={24}
          height={24}
          style={{ lineHeight: '34px', display: 'block', textAlign: 'center' }}
        >
          Login via SSO
        </Heading>
        {chooseOrg === false ? (
          <form style={{ marginTop: 40 }}>
            <div className="login-input-container login-m-t-60">
              <label className="login-label" htmlFor="login_email">
                Your Organization Domain
              </label>
              <input
                id="login_email"
                className={cx('login-input', { error: errors.domain })}
                value={domain}
                placeholder="company.com"
                onChange={e => setDomain(e.target.value)}
                type="domain"
              />
              <p className="login-error-message">{errors.domain || ''}</p>
            </div>
            <div className="login-input-container mb-20" style={{ display: 'inline' }}>
              <Para
                fontSize={14}
                style={{
                  color: Theme.theme.font.label,
                  marginBottom: '16px',
                  fontWeight: 300,
                }}
              >
                Data Center Location
              </Para>
              {dataCenters.map(dc => (
                <label
                  htmlFor={dc.label}
                  key={dc.label}
                  style={{ fontWeight: 600, marginBottom: 10, marginRight: 20 }}
                >
                  <Radio
                    id={dc.label}
                    value={dc.label}
                    checked={isCurrentDC(dc.url)}
                    onChange={onRedirect(dc.url)}
                  />
                  <span className="ml-10">{dc.label}</span>
                </label>
              ))}
            </div>
            <p className="login-error-message" style={{ marginBottom: '-30px' }}>
              {errors.sso || ''}
            </p>
            <button
              className="login-get-started"
              disabled={domain === '' || componentState === 'busy'}
              style={{ fontSize: '22px', marginTop: 40 }}
              onClick={login}
            >
              {componentState === 'busy' ? (
                <Grid alignContent="center" justifyContent="center" data-testid="pulse_loader">
                  <PulseLoader base="8px" color={Theme.theme.shades.white} />
                </Grid>
              ) : (
                'LOGIN via SSO'
              )}
            </button>
          </form>
        ) : (
          <div className="login-input-container mb-20" style={{ marginTop: '30px' }}>
            <Para
              fontSize={14}
              style={{
                color: Theme.theme.font.label,
                marginBottom: '16px',
                fontWeight: 300,
              }}
            >
              Select the organization you want to login
            </Para>
            {redirectDetails.map((dc: any) => (
              <label
                htmlFor={dc.orgName}
                key={dc.orgName}
                style={{ fontWeight: 600, marginBottom: 10, marginRight: 20 }}
              >
                <Radio id={dc.orgName} value={dc.orgName} onChange={onRedirect(dc.redirectUrl)} />
                <span className="ml-10">{dc.orgName}</span>
              </label>
            ))}
          </div>
        )}

        <Grid
          style={{
            marginTop: '30px',
            padding: '10px',
          }}
        >
          <Para
            fontSize={14}
            style={{
              textAlign: 'center',
              color: Theme.theme.shades.grey,
            }}
          >
            If you have trouble logging in via SSO, contact:
            <a
              href={`mailto:${API.config.squadcastSupport}`}
              rel="noreferrer"
              style={{
                color: '#09325E',
              }}
            >
              {'    '}
              support@squadcast.com.
            </a>
          </Para>
        </Grid>
        <div style={{ position: 'relative', textAlign: 'center', marginTop: '20px' }}>
          <hr
            style={{
              position: 'absolute',
              width: '100%',
              backgroundColor: Theme.theme.shades.grey,
            }}
          />
          <div
            style={{
              backgroundColor: Theme.theme.shades.white,
              position: 'relative',
              display: 'inline-block',
              width: '30px',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <span>OR</span>
          </div>
        </div>
        <div className="login-account-exists">
          <Para
            fontSize={14}
            fontWeight={400}
            style={{
              textAlign: 'center',
              color: Theme.theme.shades.grey,
            }}
          >
            Switch back to
            <br />
            <Link
              className="login-signin-link"
              to="/"
              style={{
                fontSize: '14px',
                fontWeight: 600,
                color: '#4370A0',
                textDecoration: 'none',
                textAlign: 'center',
              }}
            >
              Password Login
            </Link>
          </Para>
        </div>
        <div className="login-account-exists" style={{ textAlign: 'center' }}>
          <Label>Don't have an account ?</Label>
          <Link
            className="login-signin-link"
            to="/register"
            style={{ fontSize: '14px', fontWeight: 400, color: 'var(--font-label)' }}
          >
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SsoLogin;
