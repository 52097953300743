import React, { useState } from 'react';
import { TextButton, Theme, DialogModalFrame, Para } from 'uie/components';
import { Link } from 'react-router-dom';
import { IOrganizationRunbookReduxActions, IRunbook } from 'core/interfaces/IRunBook';
import { RunbookService as services } from '../../../../core/services';
import ConfirmationBox from './common/deleteConfirmation';
import RenderTable from './common/runbookTable';
import { confirmation, snackMsg, CreateBtn, RUNBOOKS } from './runbooks.constant';
import { AxiosError } from 'axios';
import { RenderForNoRunbook } from './index';
import { AppTracker } from '../../../../shared/analytics/tracker';
import { T_WA_GS_RUNBOOK_DELETED } from '../../../../core/const/tracker';
const { theme } = Theme;
import './index.css';
import { Box, Center, Divider, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { CircleIcon, Filter } from 'icons';
import { Header } from '../service-catalog/components';
import { Filters } from './filter';
import { Owner } from '../owner-filters/hooks/useEntityOwnerFilter';
import { ActiveFilterTags } from '../owner-filters/ActiveFilterTags';
import { OwnerFilter } from './getOwnerFilters';
import { RESET_STATE } from 'core/const/init';
import { BillingService } from 'core';
import { useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

interface IRunbookList_Props {
  runbooks: IRunbook[];
  dataState: IOrganizationRunbookReduxActions | typeof RESET_STATE;

  callParentComp: () => void;
  showSnackBar: (msg: string, bg: string, timeout: number) => void;
  ownerFilter: OwnerFilter;
  filterQueryParam: string;
}

export const isAxiosError = (err: any): err is AxiosError => {
  return err.isAxiosError === true;
};

const RunbookList = (props: IRunbookList_Props) => {
  const organization = useSelector((state: IAppState) => state.organization);
  const RunbookService = new services();
  const _uA = useUserAccess();
  const hasRead = _uA.hasReadAccess('runbooks');
  const hasCreate = _uA.hasCreateAccess('runbooks');
  const hasUpdate = _uA.hasUpdateAccess('runbooks');
  const hasDelete = _uA.hasDeleteAccess('runbooks');
  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [deleteRunbookId, setDeleteRunbookId] = useState<string>('');
  const [deleteRunbookName, setDeleteRunbookName] = useState<string>('runbook');
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);

  const deleteRunbookService = async (runbookId: string) => {
    try {
      await RunbookService.delete(runbookId);
      props.showSnackBar(snackMsg.deletRunbook, theme.success.default, 3000);
      AppTracker.track(T_WA_GS_RUNBOOK_DELETED, {});
    } catch (err) {
      if (isAxiosError(err)) {
        const errMsg = err?.response?.data?.meta?.error_message ?? snackMsg.errDeletRunbook;
        props.showSnackBar(errMsg, theme.danger.default, 8000);
      } else {
        props.showSnackBar(snackMsg.errDeletRunbook, theme.danger.default, 8000);
      }
    } finally {
      props.callParentComp();
    }
  };

  const deleteRunbook = (id: string, name: string, model = true) => {
    setDeleteRunbookId(id);
    setDeleteModel(model);
    setDeleteRunbookName(name);
  };

  const deleteConfirmation = (type: string) => {
    if (type === confirmation.YES && deleteRunbookId.length) deleteRunbookService(deleteRunbookId);
    deleteRunbook('', '', false);
  };
  const onClickActiveFilterTag = (type: Owner, id: string) => {
    props.ownerFilter.handleActiveFilterTagClick(type, id);
  };

  const renderr = () => {
    return (
      <div className="runbook_table_container">
        <Header
          title={
            <HStack>
              <Text className="runbook_heading">Runbooks</Text>
              <ActiveFilterTags
                tags={props.ownerFilter.activeFilterTags}
                onClose={onClickActiveFilterTag}
                visibleTagsCount={2}
                applyChakraStyle={false}
              />
            </HStack>
          }
          actions={
            <Flex justifyContent={'flex-end'} gap={10}>
              <Flex position="relative">
                <IconButton
                  aria-label="Filter runbooks"
                  marginLeft={5}
                  width={'40px'}
                  height={'38px'}
                  border={'none'}
                  borderRadius={'8px'}
                  bgColor="#edf2f7"
                  icon={<Filter style={{ fill: '#2D3748', stroke: '#2D3748' }} />}
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => {
                    setFilterDrawerOpen(true);
                  }}
                />
                {props.ownerFilter.ownerFilterApplied && (
                  <CircleIcon
                    style={{
                      fill: 'red',
                      stroke: 'red',
                      position: 'absolute',
                      top: '-4',
                      right: '-10',
                    }}
                    height="10px"
                  />
                )}
              </Flex>
              <Center height={10}>
                <Divider orientation="vertical" />
              </Center>
              {createRunbookButton(CreateBtn.INLINE)}
            </Flex>
          }
        />
        <Filters
          isFilterOpen={isFilterDrawerOpen}
          onClose={onFilterClose}
          getFilterValues={() => {}}
          ownerFilter={props.ownerFilter}
        />

        <Text
          style={{
            marginBottom: '10px',
            fontSize: '12px',
            color: '#808080',
            fontWeight: 300,
          }}
        >
          Create and attach Runbooks while working on critical incidents. It helps document routine
          procedures and operations for referencing. Learn more about runbooks{' '}
          <a
            href="https://support.squadcast.com/runbooks/runbooks"
            target="_blank"
            rel="noopener noreferrer"
            className="anchor"
          >
            here
          </a>
          .
        </Text>
      </div>
    );
  };
  const onFilterClose = () => {
    setFilterDrawerOpen(false);
  };
  const renderTable = () => {
    return (
      <RenderTable
        runbooks={props.runbooks}
        deleteRunbook={(id: string, name: string) => {
          deleteRunbook(id, name);
        }}
        hasDelete={hasDelete}
        hasUpdate={hasUpdate}
        isLoading={props.dataState}
      />
    );
  };

  const renderModel = () => {
    return (
      <DialogModalFrame
        id="delete_runbook_modal"
        onClose={() => {
          setDeleteModel(false);
        }}
        width="300px"
      >
        {deleteModel && (
          <ConfirmationBox action={deleteConfirmation} runbookName={deleteRunbookName} />
        )}
      </DialogModalFrame>
    );
  };

  const createRunbookButton = (displayType: string) => {
    const disableButton = BillingService.isFeatureDisabled({ organization }, 'runbooks');

    return (
      <Link to="/runbooks/create" style={{ display: displayType }}>
        <NoPermissionTooltip isDisabled={hasCreate}>
          <TextButton style={{ padding: '8px 22px' }} disabled={disableButton || !hasCreate}>
            <Para color={theme.font.white} fontWeight={500} fontSize={14}>
              Create Runbook
            </Para>
          </TextButton>
        </NoPermissionTooltip>
      </Link>
    );
  };

  if (hasRead) {
    return (
      <>
        {renderr()}
        {renderTable()}
        {renderModel()}
      </>
    );
  } else {
    return <RenderForNoRunbook />;
  }
};

export default RunbookList;
