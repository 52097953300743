import { toSentenceCase } from 'core/helpers/stringUtils';
import { CardType as CommunicationCardType } from 'core/interfaces/ICommunicationCard';
import { ILog } from 'core/interfaces/IIncidents';
import { generatePath } from 'react-router-dom';
import { EntityType } from 'views/main/organization/navigation-flows/helpers';
import {
  INCIDENT_DETAILS_PATH,
  STATUSPAGE_V2_DETAILS,
  WORKFLOWS_DETAILS_PATH,
} from 'views/main/routes/routes';

const getWorkflowHyperlink = (
  workflowName: string,
  workflowId: number,
  workflowExecutionId?: string,
) => {
  let workflowLink = generatePath(WORKFLOWS_DETAILS_PATH, { id: workflowId });
  if (workflowExecutionId) workflowLink += `?tab=logs&log=${workflowExecutionId}`;
  return `[${workflowName}](${workflowLink})`;
};

/**
 * Utility function to parse (most of the) logs, and to return meaningful timeline event messages and details
 * @param log The raw incident log entity
 * @param getEntityName [Optional] A handler function that takes in `entityType` and `entityId` returns the name of entity that caused the event
 * @returns The variable subtitutes `timeline_event_title` and `timeline_event_detail` to be rendered in the postmortem template
 */
export const formatLog = (
  log: ILog,
  getEntityName?: (type: EntityType, entityId?: string) => string,
  formatTimestamp?: (date: string | Date) => string,
): {
  timeline_event_title: string;
  timeline_event_detail?: string;
  timeline_event_action_type: string;
} => {
  const formattedOutput: { title: string; actionType: string; description?: string } = {
    title: '',
    description: '',
    actionType: '',
  };

  if (log.action.includes('_')) {
    if (log.additionalInfo?.workflow_name && log.workflow_id) {
      log.reason = log.reason.replace(
        /Workflow\s?\(([^)]+)\)/,
        `Workflow (${getWorkflowHyperlink(
          log.additionalInfo.workflow_name,
          log.workflow_id,
          log.additionalInfo.wf_exec_id,
        )})`,
      );
    }

    formattedOutput.title = log.reason;
    formattedOutput.actionType = log.action;

    switch (log.action) {
      case 'task_added':
      case 'task_checked':
      case 'task_unchecked':
        formattedOutput.description = `Task: ${log.additionalInfo?.content}`;
        break;

      case 'task_updated':
        formattedOutput.description = `Updated Task: ${log.additionalInfo?.content}`;
        break;

      case 'task_deleted':
        break;

      case 'runbook_attached':
        formattedOutput.description = `Runbook(s): ${log.additionalInfo?.runbooks
          ?.map(rb => rb.name)
          .join(', ')}`;
        break;

      case 'runbook_detached':
        break;
      case 'runbook_step_checked':
      case 'runbook_step_unchecked':
        formattedOutput.description = `Runbook step: ${log.additionalInfo?.content}`;
        break;

      case 'communication_card_created':
        if (!log.additionalInfo?.comm_card_type) break;
        const communicationChannel =
          {
            [CommunicationCardType.video]: 'Video Conference',
            [CommunicationCardType.chatOps]: 'Chat Room',
            [CommunicationCardType.msTeamsMeetingLink]: 'MS Teams Meeting',
            [CommunicationCardType.slack]: 'Slack Channel',
            [CommunicationCardType.other]: 'Communication Channel',
          }[log.additionalInfo?.comm_card_type] || 'Communication Channel';
        formattedOutput.description = `${communicationChannel} link: ${log.additionalInfo?.comm_card_url}`;
        break;

      case 'communication_card_updated':
      case 'communication_card_deleted':
        break;

      case 'workflow_triggered':
        if (!log.workflow_id || !log.additionalInfo?.workflow_name) break;
        formattedOutput.title = `Workflow (${getWorkflowHyperlink(
          log.additionalInfo.workflow_name,
          log.workflow_id,
          log.additionalInfo.wf_exec_id,
        )}) has been triggered for this incident`;
        break;

      case 'additional_responders_added':
      case 'additional_responders_removed':
        break;

      case 'incident_snoozed':
        if (
          !log.additionalInfo?.snooze_start_time ||
          !log.additionalInfo?.snooze_end_time ||
          !formatTimestamp
        )
          break;
        formattedOutput.description = `Snooze duration: ${formatTimestamp(
          log.additionalInfo.snooze_start_time,
        )} to ${formatTimestamp(log.additionalInfo.snooze_end_time)}`;
        break;

      case 'incident_unsnoozed_manually':
      case 'incident_unsnoozed_automatically':
        if (!log.additionalInfo?.snooze_end_time || !formatTimestamp) break;
        formattedOutput.description = `Unsnoozed at: ${formatTimestamp(
          log.additionalInfo?.snooze_end_time,
        )}`;
        break;

      case 'incident_priority_updated':
        break;

      // note_created, note_updated, note_deleted, note_unstarred do not have logs. So no need to handle those cases
      // in case of note_starred log, we show the note_created log
      case 'note_created':
        formattedOutput.title = log.reason.split('created')[0].trim() + ' added a note';
        formattedOutput.description = `Note: ${log.additionalInfo?.note}`;
        formattedOutput.actionType = 'note_added';
        break;

      case 'delayed_notification':
        break;

      case 'delayed_notification_resumed':
        formattedOutput.title = `Incident assigned to _${log.assignedTo}_ after resuming delayed notifications`;
        break;

      case 'reassigned_at_user_replacement':
      case 'suppressed_at_user_replacement':
      case 'incident_starred':
        break;
      // incident_unstarred is unhandled as the log is removed if an incident is unstarred

      case 'slack_channel_added':
        formattedOutput.description = `Slack Channel link: ${log.additionalInfo?.slack_channel_url}`;
        break;

      case 'slack_channel_archived':
        break;

      case 'manually_merged_incidents_child':
      case 'manually_unmerged_incident_child':
        if (!log.additionalInfo?.parent) break;
        const parentIncidentLink = generatePath(INCIDENT_DETAILS_PATH, {
          id: log.additionalInfo?.parent,
        });
        formattedOutput.description = `Parent Incident [link](${parentIncidentLink})`;
        break;

      case 'manually_merged_incidents_parent':
        formattedOutput.title = log.reason + ' and marked this incident as parent';
        break;

      case 'manually_unmerged_incident_parent':
        if (!log.additionalInfo?.child) break;
        const childIncidentLink = generatePath(INCIDENT_DETAILS_PATH, {
          id: log.additionalInfo?.child,
        });
        formattedOutput.description = `Child Incident [link](${childIncidentLink})`;
        break;

      case 'global_rules_executed':
        formattedOutput.description = `Rule executed: \`${log.additionalInfo?.global_rule_expression_matched}\``;
        break;

      case 'slo_incident_promoted':
      case 'slo_false_positive_true':
      case 'marked_as_transient_by_squadcast':
      case 'manually_marked_negative_transient_alert_feedback':
      case 'manually_marked_positive_transient_alert_feedback':
      case 'apta_violated':
      case 'iag_vote_down':
      case 'iag_vote_up':
        break;

      case 'circleci_rebuild':
        if (!log.additionalInfo) {
          formattedOutput.title = log.reason;
          break;
        }
        const { userName, repoName } = log.additionalInfo.payload;
        const { build_num: buildNumber, build_url: jobLink } =
          log.additionalInfo.response.build_num;
        formattedOutput.title = `CircleCI: ${userName}/${repoName} (#${buildNumber}) has been rebuilt`;
        formattedOutput.description = `CircleCI job link: ${jobLink}`;
        break;

      case 'jira_cloud':
        if (!log.additionalInfo) {
          formattedOutput.title = 'A new ticket has been created in Jira Cloud';
          break;
        }
        const isManual = log.additionalInfo.isManual ? 'manually' : 'auto';
        const projectKey = log.additionalInfo.payload.projectKey;
        const jiraBaseUrl = (log.additionalInfo.response.data.self ?? ('' as string)).split(
          '/rest',
        )[0];
        const jiraIssueKey = log.additionalInfo.response.data.key;
        formattedOutput.title = `A new ticket has been ${isManual} created in Jira on ${projectKey}`;
        formattedOutput.description = `Jira Ticket link: ${jiraBaseUrl}/browse/${jiraIssueKey}`;
        break;

      case 'jira_server':
        if (!log.additionalInfo) {
          formattedOutput.title = 'A new ticket has been created in Jira Server';
          break;
        }
        const isManualServer = log.additionalInfo.isManual ? 'manually' : 'auto';
        const projectKeyServer = log.additionalInfo.payload.fields.project.key;
        const jiraServerBaseUrl = (log.additionalInfo.response.self ?? ('' as string)).split(
          '/rest',
        )[0];
        const jiraIssueKeyServer = log.additionalInfo.response.key;
        formattedOutput.title = `A new ticket has been ${isManualServer} created in Jira on ${projectKeyServer}`;
        formattedOutput.description = `Jira Ticket link: ${jiraServerBaseUrl}/browse/${jiraIssueKeyServer}`;
        break;

      case 'wf_http_call_action_triggered':
        if (!log.additionalInfo?.url) break;

        formattedOutput.description = `Request URL: ${log.additionalInfo.url}`;
        break;

      case 'wf_send_email_action_triggered':
        if (!log.additionalInfo?.subject || !log.additionalInfo?.to) break;

        formattedOutput.description = `Subject: ${
          log.additionalInfo?.subject
        }; To: ${log.additionalInfo?.to?.join(', ')}`;
        break;

      case 'wf_archive_slack_channel_action_triggered':
      case 'wf_create_slack_channel_action_triggered':
        break;

      case 'wf_message_slack_user_action_triggered':
      case 'wf_message_slack_channel_action_triggered':
      case 'wf_message_msteams_channel_action_triggered':
      case 'wf_message_msteams_user_action_triggered':
        if (!log.additionalInfo?.message) break;
        formattedOutput.description = `Message: ${log.additionalInfo.message}`;
        break;

      case 'wf_create_jira_issue_action_triggered':
        break;

      case 'wf_add_status_page_issue_action_triggered':
        if (!log.additionalInfo?.status_page_request?.StatusPageID) break;
        const statuspageLink = generatePath(STATUSPAGE_V2_DETAILS, {
          id: log.additionalInfo.status_page_request.StatusPageID,
        });
        formattedOutput.description = `Statuspage [Link](${statuspageLink})`;
        break;

      default:
        break;
    }
  } else if (log.action.includes(' ')) {
    formattedOutput.title = log.action;
    formattedOutput.actionType = log.actionType;

    switch (log.actionType) {
      case 'triggered':
      case 'retriggered':
      case 'acknowledged':
      case 'resolved':
      case 'auto_resolved':
      case 'reassigned':
      case 'suppressed':
      case 'postmortem_created':
      case 'postmortem_deleted':
      case 'postmortem_updated':
      case 'postmortem_status_updated':
        break;

      case 'servicenow_auto_create':
      case 'servicenow_manual_create':
        if (!log.additionalInfo) break;
        const isManual = log.additionalInfo.isManual ? 'manually' : 'auto';
        formattedOutput.title = `A new incident has been ${isManual} created in ServiceNow`;
        formattedOutput.description = `ServiceNow Incident [Link](${log.additionalInfo.servicenow_incident_link})`;
        break;

      case 'msteams_meeting_link':
        if (log.assignedTo === 'workflow') {
          if (!log.workflow_id || !log.additionalInfo?.workflow_name) break;
          formattedOutput.title = `Workflow (${getWorkflowHyperlink(
            log.additionalInfo.workflow_name,
            log.workflow_id,
            log.additionalInfo.wf_exec_id,
          )})${log.action}`;
        } else {
          if (!getEntityName) break;
          const entityName: string = toSentenceCase(
            getEntityName(log.assignedTo as EntityType, log.assignedBy),
          );
          formattedOutput.title = `${entityName}${log.action}`;
        }

        if (!log.additionalInfo?.meeting_link) break;
        formattedOutput.description = `MS Teams Meeting [Link](${log.additionalInfo.meeting_link})`;
        break;

      case 'tags_modified':
        const [title, ...description] = log.action.split('\n');
        formattedOutput.title = title;
        // description can have 2 elements in array:
        // description = ['x:y,a:b added', 'x:z,z:q removed']
        // actual log description should be:
        // Tag(s) x:y,a:b added
        // Tag(s) x:z,z:q removed
        formattedOutput.description = `Tag(s): ${description.join('\n Tag(s): ')}`;
        break;

      default:
        break;
    }

    if (log.reason === 'routing_rule_executed') {
      formattedOutput.actionType = log.reason;
      let routingExpression = '';
      if (log.additionalInfo?.routing_rule_applied.is_basic) {
        routingExpression = `${log.additionalInfo?.routing_rule_applied.basic_expression
          .map((el: { lhs: string; rhs: string }) => `${el.lhs} == ${el.rhs}`)
          .join(' && ')}`;
      } else routingExpression = log.additionalInfo?.routing_rule_applied.expression;
      formattedOutput.description = `Rule executed: \`${routingExpression}\``;
    }
  } else {
    formattedOutput.title = log.action;
  }
  return {
    timeline_event_title: formattedOutput.title,
    timeline_event_detail: formattedOutput.description,
    timeline_event_action_type: formattedOutput.actionType,
  };
};
