import * as React from 'react';
import { SVGProps } from 'react';

const SvgAwsEventBridgeLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="AWS_EventBridge_Logo_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.AWS_EventBridge_Logo_svg__st0{fill-rule:evenodd;clip-rule:evenodd;fill:url(#AWS_EventBridge_Logo_svg__Rectangle_00000029763913843563084440000005423552555941021065_)}'
      }
    </style>
    <g id="AWS_EventBridge_Logo_svg__Icon-Architecture_x2F_48_x2F_Arch_x5F_Amazon-EventBridge_x5F_48">
      <g id="AWS_EventBridge_Logo_svg__Icon-Architecture-BG_x2F_48_x2F_Application-Integration">
        <linearGradient
          id="AWS_EventBridge_Logo_svg__Rectangle_00000168800263071479623460000016334388303335975040_"
          gradientUnits="userSpaceOnUse"
          x1={-282.516}
          y1={425.281}
          x2={-282.203}
          y2={425.594}
          gradientTransform="matrix(64 0 0 -64 18081 27238)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#b0084d',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ff4f8b',
            }}
          />
        </linearGradient>
        <path
          id="AWS_EventBridge_Logo_svg__Rectangle"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'url(#AWS_EventBridge_Logo_svg__Rectangle_00000168800263071479623460000016334388303335975040_)',
          }}
          d="M0 0h20v20H0z"
        />
      </g>
      <path
        id="AWS_EventBridge_Logo_svg__AWS-EventBridge_Icon_48_Squid"
        d="M13.5 16.2c-.5 0-.9-.4-.9-.9s.4-.9.9-.9.9.4.9.9c0 .6-.4.9-.9.9zm-2.1-4H8.9L7.6 10l1.3-2.2h2.5l1.3 2.2-1.3 2.2zM7.1 5.5c-.5 0-.9-.4-.9-.9s.4-.9.9-.9c.5.1.9.4.9.9s-.4.9-.9.9zm6.4 8.4c-.2 0-.3 0-.5.1l-1-1.6 1.3-2.2c.1-.1.1-.2 0-.3l-1.5-2.5c-.1-.1-.2-.2-.3-.2H9l-.9-1.5c.3-.3.5-.7.5-1.1 0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5.7 1.5 1.5 1.5c.2 0 .3 0 .5-.1l.8 1.3L7 9.8c-.1.1-.1.2 0 .3l1.5 2.5c.1.1.2.2.3.2h2.9l.9 1.5c-.3.3-.5.7-.5 1.1 0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5c-.1-.9-.7-1.5-1.6-1.5zM15 8c-.5 0-.9-.4-.9-.9s.4-.9.9-.9.9.4.9.9-.4.9-.9.9zm.9.3c.3-.3.6-.7.6-1.2 0-.8-.7-1.5-1.5-1.5-.2 0-.4 0-.6.1l-.9-1.2c-.1-.1-.2-.1-.3-.1h-2.8V5h2.7l.8 1.1c-.3.3-.4.6-.4 1 0 .8.7 1.5 1.5 1.5.1 0 .3 0 .4-.1l.8 1.4-1.2 2.2.5.3 1.3-2.2c.1-.1.1-.2 0-.3l-.9-1.6zM5.5 13.6c-.5 0-.9-.4-.9-.9s.4-.9.9-.9.9.4.9.9-.4.9-.9.9zm.9.4c.4-.3.6-.7.6-1.2 0-.8-.7-1.5-1.5-1.5-.2 0-.4 0-.6.1L4.1 10l1.4-2.4-.5-.3-1.5 2.5c-.1.1-.1.2 0 .3l.9 1.6c-.2.3-.4.7-.4 1.1 0 .8.7 1.5 1.5 1.5h.3l.9 1.3c.1.1.2.1.3.1h2.8V15H7.2l-.8-1z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);

export default SvgAwsEventBridgeLogo;
