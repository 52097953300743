import React from 'react';
import { Grid, Heading } from 'uie/components';
import { Link } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';

interface Props {
  isViewMode: boolean;
}

const Header = ({ isViewMode }: Props) => {
  return (
    <>
      <div className="mb-10">
        <Grid justifyContent="space-between" alignItems="center">
          <Heading height={35} fontSize={24}>
            {`${isViewMode ? 'View ' : ''}Key Based Deduplication`}
          </Heading>
        </Grid>
      </div>
      {!isViewMode && (
        <div className="mb-20">
          <Grid justifyContent="space-between" alignItems="center">
            <Heading
              height={14}
              fontSize={14}
              style={{
                fontSize: '12px',
                fontWeight: 300,
              }}
            >
              Define a dedup key using templates for configured alert sources of this service and
              auto-group similar incidents. Learn more{' '}
              {
                <Link
                  color={THEME_COLORS.brand.blue}
                  href="https://support.squadcast.com/services/alert-deduplication-rules/key-based-deduplication"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </Link>
              }
              .
            </Heading>
          </Grid>
        </div>
      )}
    </>
  );
};

export default Header;
