import * as React from 'react';
import { SVGProps } from 'react';

const SvgElastic = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M20 10.5c0-1.7-1-3.1-2.6-3.7.1-.4.1-.7.1-1.1C17.5 2.6 15 0 11.9 0 10 0 8.3.9 7.3 2.4c-.6-.4-1.2-.6-1.9-.6-1.7 0-3 1.3-3 3 0 .4.1.7.2 1C1.1 6.4 0 7.9 0 9.5c0 1.7 1 3.2 2.6 3.7-.1.3-.1.7-.1 1.1C2.5 17.4 5 20 8.1 20c1.8 0 3.5-.9 4.6-2.3.5.4 1.2.6 1.8.6 1.7 0 3-1.3 3-3 0-.4-.1-.7-.2-1 1.6-.7 2.7-2.2 2.7-3.8"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="m7.9 8.6 4.4 2 4.4-3.9c.1-.3.1-.6.1-1C16.8 3 14.6.8 11.9.8c-1.6 0-3.1.8-4.1 2.1L7 6.8l.9 1.8z"
      style={{
        fill: '#fed10a',
      }}
    />
    <path
      d="M3.3 13.3c-.1.3-.1.7-.1 1 0 2.7 2.2 4.9 5 4.9 1.6 0 3.2-.8 4.1-2.2l.7-3.8-1-1.9-4.4-2-4.3 4z"
      style={{
        fill: '#24bbb1',
      }}
    />
    <path
      d="m3.3 5.7 3 .7L7 3c-.4-.3-.9-.5-1.5-.5-1.3 0-2.4 1.1-2.4 2.4.1.2.1.5.2.8"
      style={{
        fill: '#ef5098',
      }}
    />
    <path
      d="M3 6.4C1.7 6.8.8 8.1.8 9.5c0 1.4.9 2.6 2.1 3.1l4.2-3.8-.8-1.6L3 6.4z"
      style={{
        fill: '#17a8e0',
      }}
    />
    <path
      d="M13 17c.4.3.9.5 1.4.5 1.3 0 2.4-1.1 2.4-2.4 0-.3-.1-.6-.1-.8l-3-.7L13 17z"
      style={{
        fill: '#93c83e',
      }}
    />
    <path
      d="m13.7 12.8 3.3.8c1.3-.4 2.3-1.7 2.3-3.1 0-1.4-.9-2.6-2.1-3.1l-4.3 3.8.8 1.6z"
      style={{
        fill: '#0779a1',
      }}
    />
  </svg>
);

export default SvgElastic;
