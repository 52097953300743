import { Box } from '@chakra-ui/layout';
import { useMemo } from 'react';
import { MS_TEAMS_ACTIONS } from '../../../constant';
import ActionItem from './ActionItem';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { useCheckMsTeamStatus } from '../../../details/hooks/useCheckMsTeamStatus';
import IntegrateActionsUI from '../IntegrateActionsUI';
import { SubActionTypes } from '../../../types';

const MSTeamActionList = () => {
  const { handleSubActionClick, actions, triggerType } = useWorkflowFormContext();
  const addedActionTypes = useMemo(() => actions.map(action => action.name), [actions]);

  const { data: msTeamIntegrated } = useCheckMsTeamStatus();
  const isMeetingLinkUserConfigured =
    msTeamIntegrated?.from_id && msTeamIntegrated?.from_id.length > 0;
  const availableActions = useMemo(
    () =>
      MS_TEAMS_ACTIONS.filter(action => {
        if (action.name === SubActionTypes.MS_TEAMS_CREATE_MEETING_LINK) {
          if (!isMeetingLinkUserConfigured) return false;
        }

        const actionIsDisabledForSelectedTrigger =
          action.disabledForTriggers?.includes(triggerType);
        const actionisDisabled = action.disabled;

        return !actionIsDisabledForSelectedTrigger && !actionisDisabled;
      }),
    [triggerType],
  );

  if (msTeamIntegrated === undefined) {
    return <IntegrateActionsUI action={'Microsoft Teams'} />;
  }

  return (
    <Box>
      {availableActions.map(action => (
        <ActionItem
          action={action}
          onClick={handleSubActionClick}
          key={action.name}
          isAdded={addedActionTypes.includes(action.name)}
        />
      ))}
    </Box>
  );
};

export default MSTeamActionList;
