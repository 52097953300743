import { Box, Text } from '@chakra-ui/layout';
import { useCreateEntityMap, transformMessage } from 'library/atoms/MdBlock/helper';
import React, { FC, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { SlackMessageAction, SlackOption } from 'views/main/organization/workflow/types';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import { useGetSlackChannels } from 'views/main/organization/settings/integrations/slack-v2/hooks/getSlackChannels';
interface IProps {
  action: SlackMessageAction;
}

const MessageChannelCard: FC<IProps> = ({ action }) => {
  const entities = useCreateEntityMap();
  const slackChannelRes = useGetSlackChannels();

  const channelOptions = useMemo(() => {
    return slackChannelRes.data?.channels.map(channel => {
      const option: SlackOption = {
        label: '#' + channel.name,
        value: channel.id,
      };
      return option;
    });
  }, [slackChannelRes.isSuccess, slackChannelRes.isRefetching]);

  const selectedChannel = channelOptions?.find(channel => channel.value === action.data.channel_id);

  return (
    <React.Fragment>
      <Box py={4}>
        <Text variant="h3" color="secondary.700" textStyle="bold_800">
          SLACK CHANNEL
        </Text>
        <Text> {selectedChannel?.label ?? 'Slack Channel not matched'}</Text>
      </Box>
      <Box>
        <Text variant="h3" color="secondary.700" textStyle="bold_800">
          MESSAGE
        </Text>
        <Text color="secondary.1000" variant="h4" fontWeight="400" textTransform="capitalize">
          {' '}
          <ReactMarkdown className="mde" plugins={[breaks, gfm]}>
            {transformMessage(action.data.message.replace(/ /g, '\u00A0'), entities)}
          </ReactMarkdown>
        </Text>
      </Box>
    </React.Fragment>
  );
};

export default MessageChannelCard;
