import {
  REQUEST_LOGIN_SUCCESS,
  REQUEST_LOGIN,
  REQUEST_LOGIN_FAIL,
  REQUEST_USER_INFO_FAIL,
  REQUEST_USER_INFO_SUCCESS,
  REQUEST_USER_INFO,
  REQUEST_USER_LOGOUT,
  REQUEST_SAVE_USER_DEVICE_ID,
  REQUEST_SAVE_USER_DEVICE_ID_SUCCESS,
  REQUEST_SAVE_USER_DEVICE_ID_FAIL,
  REQUEST_USER_LOGOUT_FAIL,
} from '../../const/auth';
import { IUserInfo, IUserRequestLogin } from '../../interfaces/IUserData';
import { RESET_STATE } from '../../const/init';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_GS_SIGN_IN } from 'core/const/tracker';
import { API } from 'core/api';

const requestLogin = ({ email, password }: IUserRequestLogin) => ({
  type: REQUEST_LOGIN,
  payload: {
    email,
    password,
  },
});

const onLoginSuccess = (sessionId: string) => {
  AppTracker.track(T_WA_GS_SIGN_IN);

  return {
    type: REQUEST_LOGIN_SUCCESS,
    payload: {
      sessionId,
    },
  };
};

const onLoginFail = (error: any) => ({
  type: REQUEST_LOGIN_FAIL,
  payload: {
    error,
  },
});

const requestUserInfo = () => ({
  type: REQUEST_USER_INFO,
  payload: {},
});

const onRequestUserInfoSuccess = (userInfo: IUserInfo) => ({
  type: REQUEST_USER_INFO_SUCCESS,
  payload: {
    userInfo,
  },
});

const onRequestUserInfoFail = (error: any) => ({
  type: REQUEST_USER_INFO_FAIL,
  payload: {
    error,
  },
});

/**
 * @implements only in mobile devices
 */
const requestSaveUserDevice = (deviceId: string, userId: string, deviceOS: string) => ({
  type: REQUEST_SAVE_USER_DEVICE_ID,
  payload: {
    deviceId,
    userId,
    deviceOS,
  },
});

/**
 * @implements only in mobile devices
 */
const onRequestSaveUserDeviceSuccess = () => ({
  type: REQUEST_SAVE_USER_DEVICE_ID_SUCCESS,
  payload: {},
});

/**
 * @implements only in mobile devices
 */
const onRequestSaveUserDeviceFail = () => ({
  type: REQUEST_SAVE_USER_DEVICE_ID_FAIL,
  payload: {},
});

const requestUserLogout = () => {
  AppTracker.reset();
  // clear out local and session storage cache
  if (API.config.sessionId?.length) {
    sessionStorage.clear();
    localStorage.clear();

    return {
      type: REQUEST_USER_LOGOUT,
      payload: {},
    };
  } else {
    return {};
  }
};

const onRequestUserLogoutFail = () => ({
  type: REQUEST_USER_LOGOUT_FAIL,
  payload: {},
});

const onRequestUserLogoutSuccess = () => ({
  type: RESET_STATE,
  payload: {
    sessionId: RESET_STATE,
  },
});

export {
  requestLogin,
  onLoginFail,
  onLoginSuccess,
  requestUserInfo,
  onRequestUserInfoFail,
  onRequestUserInfoSuccess,
  requestUserLogout,
  onRequestUserLogoutSuccess,
  onRequestUserLogoutFail,
  requestSaveUserDevice,
  onRequestSaveUserDeviceSuccess,
  onRequestSaveUserDeviceFail,
};
