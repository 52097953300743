import {
  Box,
  Button,
  CloseButton,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Select,
  Text,
  Textarea,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { Field, FieldArray, Form, Formik, FormikErrors, getIn } from 'formik';
import { useContext } from 'react';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

import { IWebhookContext, WEBHOOK_ACTIONS } from '../store';
import { EMPTY_HEADER_OBJ, EMPTY_URL_OBJ, WEBHOOK_STEPPER_PROPTYPE } from '../webhook-const';
import { WebhookContext } from '../create/create-webhook';
import styles from './add-details.module.css';
import { InfoFilledIcon, InfoIcon } from 'icons';
import { WEBHOOK_METHODS } from '../shared';

export const AddWebhookDetails = ({ onSave, onCancel }: WEBHOOK_STEPPER_PROPTYPE) => {
  const { state, dispatch } = useContext(WebhookContext);

  const onFormSubmit = (data: AnyObject) => {
    dispatch({ type: WEBHOOK_ACTIONS.SAVE_DETAILS, payload: data });
    onSave();
  };

  const ErrorMessage = ({ name }: { name: string }) => (
    <Field
      name={name}
      render={({ form }: { form: any }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? <div className={styles.errorMsg}>{error}</div> : null;
      }}
    />
  );

  const UrlArrayErrors = ({ errors }: { errors: FormikErrors<IWebhookContext> }) =>
    typeof errors.urls === 'string' ? <div className={styles.errorMsg}>{errors.urls}</div> : null;

  return (
    <>
      <Box paddingX={5} paddingY={3} maxWidth="80%">
        <Text className={styles.heading}>{`${state.id ? 'Edit' : 'Add'} Webhook Details`}</Text>
        <Text className={styles.subheading}>Enter the webhook details</Text>
      </Box>
      <Formik
        enableReinitialize
        validationSchema={AddWebhookDetailsSchema}
        initialValues={state}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onFormSubmit(values);
          setSubmitting(false);
        }}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({ errors, touched, handleSubmit, isSubmitting }) => (
          <Form
            onSubmit={handleSubmit}
            style={{
              overflowY: 'auto',
            }}
          >
            <VStack spacing={4} align="flex-start" padding="20px">
              <FormControl width="80%" isInvalid={!!errors.name && !!touched.name}>
                <FormLabel className={styles.controlTitle}>Webhook Name*</FormLabel>
                <Field as={Input} name="name" type="Text" placeholder="Enter Webhook Name here" />
                {!!touched.name && errors?.name && (
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl width="80%">
                <FormLabel className={styles.controlTitle}>Webhook Description</FormLabel>
                <Field
                  as={Textarea}
                  name="description"
                  placeholder="What does this outgoing webhook do?"
                />
              </FormControl>
              <FormControl width="80%" isInvalid={!!errors.email && !!touched.email}>
                <FormLabel className={styles.controlTitle}>Failure Notification Email</FormLabel>
                <Field as={Input} name="email" type="Text" placeholder="Enter Email here" />
                {!!touched.email && errors?.email && (
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl width="80%">
                <FormLabel className={styles.controlTitle}>URL*</FormLabel>
                <VStack alignItems="flex-start" w="100%">
                  <FieldArray
                    name="urls"
                    render={arrayHelpers => (
                      <>
                        {arrayHelpers.form.values?.urls?.map((_obj: any, index: number) => {
                          return (
                            <Box width="100%" key={index}>
                              <FormControl>
                                <HStack flex="1" alignItems="flex-start">
                                  <VStack alignItems="flex-start">
                                    <Field as={Select} name={`urls[${index}].method`}>
                                      {WEBHOOK_METHODS.map((method: string) => (
                                        <option key={method} value={method.toLowerCase()}>
                                          {method}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage name={`urls[${index}].method`} />
                                  </VStack>
                                  <VStack alignItems="flex-start" flex="1">
                                    <Field
                                      as={Input}
                                      name={`urls[${index}].url`}
                                      type="Text"
                                      placeholder="https://example.com"
                                    />
                                    <ErrorMessage name={`urls[${index}].url`} />
                                  </VStack>
                                  <CloseButton
                                    onClick={e => {
                                      arrayHelpers.remove(index);
                                    }}
                                    fontSize={10}
                                    color="#2D3748"
                                    variant="ghost"
                                  />
                                </HStack>
                              </FormControl>
                            </Box>
                          );
                        })}
                        <Text
                          onClick={() => arrayHelpers.push(EMPTY_URL_OBJ)}
                          className={styles.addUrl}
                        >
                          Add URL
                        </Text>
                      </>
                    )}
                  />
                  <UrlArrayErrors errors={errors} />
                </VStack>
              </FormControl>
              <FormControl width="80%">
                <FormLabel className={styles.controlTitle}>Additional Headers</FormLabel>
                <VStack alignItems="flex-start" w="100%">
                  <FieldArray
                    name="headers"
                    render={arrayHelpers => (
                      <>
                        {arrayHelpers.form.values?.headers?.map((_obj: any, index: number) => {
                          return (
                            <Box width="100%" key={index}>
                              <FormControl>
                                <HStack alignItems="center">
                                  <Field
                                    as={Input}
                                    name={`headers[${index}].key`}
                                    isDisabled={index === 0}
                                    type="Text"
                                    placeholder="Key"
                                  />
                                  <span>:</span>
                                  <Field
                                    as={Input}
                                    name={`headers[${index}].value`}
                                    isDisabled={index === 0}
                                    type="Text"
                                    placeholder="value"
                                  />
                                  {index === 0 ? (
                                    <Tooltip label="Content-Type: application/json is added by default">
                                      <Icon w={8} as={InfoFilledIcon} />
                                    </Tooltip>
                                  ) : (
                                    <CloseButton
                                      isDisabled={index === 0}
                                      onClick={e => {
                                        arrayHelpers.remove(index);
                                      }}
                                      fontSize={10}
                                      color="#2D3748"
                                      variant="ghost"
                                    />
                                  )}
                                </HStack>
                              </FormControl>
                            </Box>
                          );
                        })}
                        <Text
                          onClick={() => arrayHelpers.push(EMPTY_HEADER_OBJ)}
                          className={`${styles.addUrl} ${styles.addHeaders}`}
                        >
                          Add Additional Headers
                        </Text>
                      </>
                    )}
                  />
                </VStack>
              </FormControl>
              <HStack alignItems="center" spacing={2}>
                <InfoIcon width="20px" className={styles.retryText} />
                <Text className={styles.retryText}>We will retry 3 times on failure</Text>
              </HStack>
              <Divider height={0.5} backgroundColor="shades.smoke" />
              <HStack spacing={4}>
                <Button
                  className={styles.submitBtn}
                  isDisabled={isSubmitting}
                  variant="solid"
                  type="submit"
                >
                  {'Next: Choose Webhook Type'}
                </Button>
                <Button className={styles.cancelBtn} variant="outline" onClick={() => onCancel()}>
                  Cancel
                </Button>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </>
  );
};

const AddWebhookDetailsSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').required('Name is required'),
  email: Yup.string().email('Invalid email'),
  urls: Yup.array()
    .of(
      Yup.object().shape({
        method: Yup.string().required('Method is required'),
        url: Yup.string().required('Url is required').url('Enter a valid url'),
      }),
    )
    .required('Urls are required')
    .min(1, 'At least 1 URL required'),
});
