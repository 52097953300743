import { useSecondaryFilters } from '../context';
import React, { useMemo } from 'react';
import { POSTMORTEM_STATUS_OPTIONS } from 'views/main/organization/incident-list/filters/manager/constant';
import { MultiValue, OnChangeValue, SingleValue } from 'react-select';
import { SelectOption } from '../types';
import DropdownContainer from './dropdown_container';
import { FilterOptions } from '../const';
import { Select } from './select';

interface Props {
  selected: string[];
  isInitializedOpen?: boolean;
  isMulti?: boolean;
}

function PostmortemStatus(props: Props): JSX.Element {
  const { updateFilter } = useSecondaryFilters();
  const { isInitializedOpen } = props;

  const postmortemStatusOptions = useMemo(
    () =>
      Object.entries(POSTMORTEM_STATUS_OPTIONS).map(([key, val], i) => {
        return {
          value: key,
          label: val.label,
          Icon: val.Icon,
        };
      }),
    [],
  );

  const [selectedOptions, selectedNames] = useMemo(() => {
    const sel = Object.entries(POSTMORTEM_STATUS_OPTIONS).filter(([key, val]) => {
      return props.selected.some(f => {
        return f === key;
      });
    });

    return [
      sel.map(([key, val]) => {
        return {
          value: key,
          label: val.label,
          Icon: val.Icon,
        };
      }),
      sel.map(([key, val]) => val.label),
    ];
  }, [props.selected]);

  const handleChange = (newValue: OnChangeValue<SelectOption, boolean>) => {
    const arr: string[] = [];

    if (Array.isArray(newValue)) {
      const options = newValue as MultiValue<SelectOption>;
      options.map(o => {
        arr.push(o.value);
      });
    } else if (newValue) {
      const option = newValue as SingleValue<SelectOption>;
      if (option?.value) {
        arr.push(option.value);
      }
    }

    updateFilter('postmortem_status', arr);
  };

  return (
    <DropdownContainer
      selected={selectedNames}
      filterKey="postmortem_status"
      label={FilterOptions['postmortem_status'].slice(0, props.isMulti ? undefined : -1)}
      isInitializedOpen={isInitializedOpen}
    >
      <Select
        options={postmortemStatusOptions}
        value={selectedOptions}
        onChange={handleChange}
        isLoading={!postmortemStatusOptions.length}
        isMulti={props.isMulti || undefined}
      />
    </DropdownContainer>
  );
}

export default PostmortemStatus;
