import { IAppState } from 'core/interfaces/IAppState';
import { ITeam } from 'core/interfaces/ITeams';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { ISquad } from 'core/interfaces/ISquad';
import { IEscalationPolicy } from 'core/interfaces/IEscalationPolicy';
import { IService } from 'core/interfaces/IService';
import { ILog, IIAssigne, IIncident } from 'core/interfaces/IIncidents';
import { PlacementWithLogical } from '@chakra-ui/react';
import { IStatusPage } from 'core/interfaces/IStatusPage';
import { MSTEAMS_MEETING_LINK_LOG_ACTION_TYPE } from '../incidentDetails/constants';

export type EntityType =
  | IIAssigne
  | 'team'
  | 'service'
  | 'routing_rule'
  | 'suppression_rule'
  | 'schedules'
  | 'incident'
  | 'workflow'
  | undefined;

export type ActionTypeForEntity =
  | 'auto_resolved'
  | 'slo_false_positive_true'
  | 'reassigned'
  | 'incident_snoozed'
  | 'suppressed_at_user_replacement'
  | 'delayed_notification_resumed'
  | 'manually_merged_incidents_parent'
  | 'manually_unmerged_incident_parent'
  | 'manually_merged_incidents_child'
  | 'manually_unmerged_incident_child'
  | 'action_for_workflow_slo_webhook'
  | 'msteams_meeting_link'
  | 'incident_priority_updated'
  | 'additional_responders_added'
  | 'additional_responders_removed'
  | 'iag_vote_down'
  | 'iag_vote_up';

export type ActionTypeForMessage =
  | 'postmortem_status_updated'
  | 'delayed_notification_resumed'
  | 'delayed_notification'
  | 'tags_modified'
  | 'manually_marked_negative_transient_alert_feedback'
  | 'manually_marked_positive_transient_alert_feedback'
  | 'incident_priority_updated'
  | 'additional_responders_added'
  | 'additional_responders_removed'
  | 'iag_vote_down'
  | 'iag_vote_up'
  | 'action_for_workflow_slo_webhook'
  | 'update_slo_error_budget_spent';

export type WorkflowActionTypes =
  | 'wf_send_email_action_triggered'
  | 'wf_http_call_action_triggered'
  | 'wf_create_slack_channel_action_triggered'
  | 'wf_message_slack_channel_action_triggered'
  | 'wf_message_slack_user_action_triggered'
  | 'wf_archive_slack_channel_action_triggered'
  | 'wf_create_jira_issue_action_triggered'
  | 'wf_message_msteams_user_action_triggered'
  | 'wf_add_status_page_issue_action_triggered';

export type IWorkflowMessages = {
  log: ILog;
  logMessageActivity: string;
  entityComponent: JSX.Element;
};

export type EntityName = 'user' | 'squad' | 'escalationpolicy' | 'team' | 'service';

export type EntityObjectType =
  | IUsersOfOrganization
  | ISquad
  | IEscalationPolicy
  | IService
  | ITeam
  | null
  | undefined;

export type EntityComponent = ({
  componentType,
  id,
  name,
  popoverTrigger,
  popoverPlacement,
  type,
  workflowId,
  workflowActionIndex,
  workflowExecutionId,
  truncateName,
  nameMaxLength,
}: GetEntityComponentProps) => JSX.Element;

export type JSXResponseForEntity = {
  log: ILog;
  logMessage: string;
  getEntityComponent: EntityComponent;
  getEntityComponentWithTruncatedName: (props: GetEntityComponentProps) => JSX.Element;
  getEntityName: (type: EntityType, entityId: string | undefined) => string;
};

export type IActivityMessages = {
  logs: ILog[];
  oUser: any;
  incident: IIncident;
  statusPages: { [key: string]: IStatusPage };
  setFocusMessageId: (id: string, type?: string | undefined, index?: number | undefined) => void;
};
export type GetEntityComponentProps = {
  componentType: 'simple' | 'linkWPopover' | 'linkWOPopover';
  id?: string;
  name?: string;
  popoverTrigger?: JSX.Element;
  popoverPlacement?: PlacementWithLogical;
  type?: EntityType;
  workflowId?: number;
  workflowExecutionId?: string;
  workflowActionIndex?: number;
  truncateName?: boolean;
  nameMaxLength?: number;
};

export type GeneralActionTypes =
  | 'incident_snoozed'
  | 'incident_unsnoozed_automatically'
  | 'incident_unsnoozed_manually'
  | 'global_rules_executed'
  | 'delayed_notification'
  | 'delayed_notification_resumed'
  | 'workflow_triggered'
  | 'iag_vote_down'
  | 'iag_vote_up'
  | 'apta_violated'
  | 'marked_as_transient_by_squadcast'
  | 'incident_priority_updated'
  | 'note_starred'
  | 'public_message'
  | 'circleci_rebuild'
  | 'additional_responders_added'
  | 'additional_responders_removed';

export const verifiedResponse = [
  'incident_snoozed',
  'reassigned',
  'incident_unsnoozed_automatically',
  'incident_unsnoozed_manually',
  'msteams_meeting_link',
  'reassigned_at_user_replacement',
  'incident_priority_updated',
  'additional_responders_added',
  'manually_unmerged_incident_child',
  'manually_merged_incidents_child',
  'manually_merged_incidents_parent',
  'manually_unmerged_incident_parent',
  'suppressed_at_user_replacement',
  'iag_vote_down',
  'iag_vote_up',
  'delayed_notification',
  'delayed_notification_resumed',
  'update_slo_error_budget_spent',
  'additional_responders_removed',
];
import { getUserAccessStore } from 'core/userAccess/UserAccessStore';

//Util function for checking access in the current team
export const hasReadAccessToEntity = (
  entity: EntityType,
  organization?: IAppState['organization'] | null,
) => {
  switch (entity) {
    case 'escalationpolicy':
      return getUserAccessStore().teamLevelACL?.escalation_policies.read;
    case 'service':
      return getUserAccessStore().teamLevelACL?.services.read;
    case 'schedules':
      return getUserAccessStore().teamLevelACL?.schedules.read;
    case 'squad':
      return getUserAccessStore().teamLevelACL?.squads.read;
    case 'team':
      if (organization) {
        const currentUser = organization.currentUser;
        const currentUserAbilities = currentUser.u?.abilities.map(a => a.slug) || [];
        return currentUserAbilities.includes('manage-teams');
      }
      return getUserAccessStore().teamLevelACL?.teams.read;
    case 'user':
      return true;
    default:
      return false;
  }
};

//Util function for checking access in a given team
export const hasReadAccessToEntityInATeam = (entity: EntityType, team: ITeam | undefined) => {
  if (!team) return false;
  switch (entity) {
    case 'escalationpolicy':
      return getUserAccessStore().teamLevelACL?.escalation_policies.read;
    case 'schedules':
      return getUserAccessStore().teamLevelACL?.schedules.read;
    case 'squad':
      return getUserAccessStore().teamLevelACL?.squads.read;
    default:
      return false;
  }
};
