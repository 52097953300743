import {
  CircularProgress as ChakraCircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react';

type Props = {
  value: number;
  valueText: string;
  isIndeterminate: boolean;
  min: number;
  max: number;
};

const progressColor = { trackColor: '#E5F2FF', color: '#1B7FF1' };

export const CircularProgress = (props: Partial<Props>) => {
  if (props.isIndeterminate) {
    const { isIndeterminate, ...rest } = props;
    return <ChakraCircularProgress {...progressColor} isIndeterminate {...rest} />;
  }

  const { value, valueText, ...rest } = props;
  return (
    <ChakraCircularProgress {...progressColor} value={value} {...rest}>
      {valueText && (
        <CircularProgressLabel sx={{ fontFamily: 'Mulish', fontWeight: 400, color: '#2D3748' }}>
          {valueText}
        </CircularProgressLabel>
      )}
    </ChakraCircularProgress>
  );
};
