import { Dispatch, SetStateAction } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { CrownSmallIcon } from 'icons';
import { ListingButton, Tooltip } from 'library/atoms';
import { PriorityButton } from 'library/molecules';
import { INCIDENT_ACTION_ID, INCIDENT_STATUS, MERGE_ELIGIBILITY } from '../common/enums';
import { PRIORITY } from 'library/common';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

export type BulkActionsButtonsProps = {
  bulkAckStatus: () => void;
  commonStatus: INCIDENT_STATUS;
  inActionBtnId: INCIDENT_ACTION_ID;
  isMergeIncidentsUnavailable: boolean;
  mergeEligibility: MERGE_ELIGIBILITY;
  mergeTooltipText: string;
  onClickMergeIncidents: () => void;
  onBulkUpdatePriority: (priority: PRIORITY) => void;
  setCanAutoRefresh: (value: boolean) => void;
  setInActionBtnId: Dispatch<SetStateAction<INCIDENT_ACTION_ID>>;
  trackMergeParentsEvent: () => void;
};

const BulkActionButtons = ({
  bulkAckStatus,
  commonStatus,
  inActionBtnId,
  isMergeIncidentsUnavailable,
  mergeEligibility,
  mergeTooltipText,
  onClickMergeIncidents,
  onBulkUpdatePriority,
  setCanAutoRefresh,
  setInActionBtnId,
  trackMergeParentsEvent,
}: BulkActionsButtonsProps) => {
  const isDisabled = inActionBtnId !== '';
  const isMergeButtonDisabled = mergeEligibility !== MERGE_ELIGIBILITY.ENABLED;

  const hasUpdate = useUserAccess().hasUpdateAccess('incidents');

  return (
    <Flex gap={4} alignSelf="center">
      {commonStatus === INCIDENT_STATUS.TRIGGERED && (
        <NoPermissionTooltip isDisabled={hasUpdate}>
          <ListingButton
            title="Acknowledge"
            id={INCIDENT_ACTION_ID.BULK_ACKNOWLEDGE}
            isDisabled={isDisabled || !hasUpdate}
            isLoading={inActionBtnId === INCIDENT_ACTION_ID.BULK_ACKNOWLEDGE}
            onClick={() => bulkAckStatus()}
          />
        </NoPermissionTooltip>
      )}
      <PriorityButton
        isLoading={inActionBtnId === INCIDENT_ACTION_ID.BULK_UPDATE_PRIORITY}
        onClick={onBulkUpdatePriority}
      />
      {[INCIDENT_STATUS.TRIGGERED, INCIDENT_STATUS.ACKNOWLEDGED].includes(commonStatus) && (
        <>
          <NoPermissionTooltip isDisabled={hasUpdate}>
            <ListingButton
              title="Resolve"
              id={INCIDENT_ACTION_ID.BULK_RESOLVE}
              isDisabled={isDisabled || !hasUpdate}
              onClick={() => setInActionBtnId(INCIDENT_ACTION_ID.BULK_RESOLVE)}
            />
          </NoPermissionTooltip>
          <NoPermissionTooltip isDisabled={hasUpdate}>
            <ListingButton
              title="Reassign"
              id={INCIDENT_ACTION_ID.BULK_REASSIGN}
              isDisabled={isDisabled || !hasUpdate}
              onClick={() => {
                setInActionBtnId(INCIDENT_ACTION_ID.BULK_REASSIGN);
                setCanAutoRefresh(false);
              }}
            />
          </NoPermissionTooltip>
          <Tooltip label={mergeTooltipText} placement="bottom">
            <Box
              position="relative"
              onMouseEnter={
                mergeEligibility === MERGE_ELIGIBILITY.PARENT_INCIDENTS
                  ? trackMergeParentsEvent
                  : () => {}
              }
            >
              <NoPermissionTooltip isDisabled={hasUpdate}>
                <ListingButton
                  variant="secondary"
                  title="Merge Incidents"
                  id={INCIDENT_ACTION_ID.INCIDENT_MERGE}
                  isDisabled={isMergeButtonDisabled || !hasUpdate}
                  onClick={onClickMergeIncidents}
                />
              </NoPermissionTooltip>
              {isMergeIncidentsUnavailable && (
                <CrownSmallIcon
                  className="pro"
                  style={{
                    position: 'absolute',
                    left: '-3px',
                    top: '-8px',
                    height: 14,
                    width: 14,
                  }}
                />
              )}
            </Box>
          </Tooltip>
        </>
      )}
    </Flex>
  );
};

export default BulkActionButtons;
