import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const BreadcrumbTheme: ComponentStyleConfig = {
  baseStyle: {
    icon: {
      color: 'secondary.900',
    },
    hyperText: {
      _hover: {
        textDecoration: 'none',
      },
    },
    previousPages: {
      color: 'brand.blue',
      fontSize: '12px',
    },
    currentPage: {
      color: 'black',
      fontSize: '12px',

      _hover: {
        color: 'brand.blue',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
