import { Box, useBoolean } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import { CSSProperties, FC, memo, ReactElement } from 'react';

type Props = {
  children: ReactElement;
  style?: CSSProperties;
};

export const HoverBox: FC<Props> = memo(({ children, style }) => {
  const [isHovering, setIsHovering] = useBoolean();

  return (
    <Box
      width={'100%'}
      onMouseEnter={setIsHovering.on}
      onMouseLeave={setIsHovering.off}
      bgColor={isHovering ? THEME_COLORS.primary[200] : THEME_COLORS.brand.white}
      style={style}
    >
      {children}
    </Box>
  );
});
