import React from 'react';
import { Box, Grid, GridItem, Switch, Button, IconButton } from '@chakra-ui/react';
import { Label } from 'uie/components';
import OnCallDuration from './onCallDuration';
import OnCallStartAndEndTime from './startAndEndTime';
import { validateSlots } from '../../../helpers/helpers.customrotations';
import { rotationsCustomizePattern } from '../../../constants/rotations.customize';
import { ITimeSlot, InputMaybe } from '../../../interface/schedule';
import { DayOfWeek } from 'views/main/organization/schedules/graphql/types';
import { CloseIcon } from 'icons';

interface DayWiseSlots {
  day: InputMaybe<DayOfWeek> | undefined;
  slots: ITimeSlot[];
}

const WeeklySlots = (props: {
  inputSlots: ITimeSlot[] | DayWiseSlots[] | undefined;
  timeChangeHandler: (
    slotId: number | undefined,
    selectedValue: any,
    timefield: 'startTime' | 'endTime',
  ) => void;
  addSlotHandler: (weekday: InputMaybe<DayOfWeek> | undefined) => void;
  removeSlotHandler: (slotId: number | undefined) => void;
  repeatHoursAndTime: boolean;
  switchChangeHandler: () => void;
  timeOptions: Record<string, any>[];
}) => {
  const {
    inputSlots,
    timeChangeHandler,
    addSlotHandler,
    removeSlotHandler,
    repeatHoursAndTime,
    switchChangeHandler,
    timeOptions,
  } = props;
  const templateColumns = repeatHoursAndTime
    ? `1.5fr 0.5fr 0.2fr 0.2fr`
    : `0.5fr 0.8fr 1fr 0.2fr 0.2fr`;
  const templateRowsForFirstGrid = `repeat(2, 1fr)`;
  const templateRowsForSecondGrid = `repeat(${(inputSlots ?? []).length}, 1fr)`;
  return (
    <>
      <Grid
        templateColumns={templateColumns}
        templateRows={templateRowsForFirstGrid}
        gap={2}
        alignItems="center"
        p={3}
      >
        <GridItem colSpan={repeatHoursAndTime ? 2 : 3}>
          <Label htmlFor="repeat">
            {rotationsCustomizePattern.customRepeat.labels.repeatHoursAndTime}
          </Label>
        </GridItem>
        <GridItem>
          <Switch id="repeat" isChecked={repeatHoursAndTime} onChange={switchChangeHandler} />
        </GridItem>
        <GridItem></GridItem>
        {!repeatHoursAndTime && <GridItem></GridItem>}
        {repeatHoursAndTime && (
          <>
            <GridItem>
              <Label>{rotationsCustomizePattern.labels.duration.onCallHours}</Label>
            </GridItem>
            <GridItem>
              <OnCallDuration inputSlots={inputSlots as ITimeSlot[]} />
            </GridItem>
            <GridItem></GridItem>
            <GridItem></GridItem>
          </>
        )}
        {!repeatHoursAndTime && (
          <>
            <GridItem p={0} textAlign="center">
              <Label>{rotationsCustomizePattern.labels.duration.onCallHours}</Label>
            </GridItem>
            <GridItem textAlign="center">
              <Label>{rotationsCustomizePattern.labels.duration.onCallTime}</Label>
            </GridItem>
            <GridItem></GridItem>
            <GridItem></GridItem>
          </>
        )}
      </Grid>
      <Box maxH={150} overflow="auto">
        <Grid
          templateColumns={templateColumns}
          templateRows={templateRowsForSecondGrid}
          gap={2}
          alignItems="center"
          pl={3}
          pr={3}
          pb={3}
        >
          {repeatHoursAndTime && (
            <>
              {(inputSlots as ITimeSlot[]).map((slot, index) => (
                <React.Fragment key={index}>
                  {index === 0 ? (
                    <GridItem>
                      <Label>{rotationsCustomizePattern.labels.duration.onCallTime}</Label>
                    </GridItem>
                  ) : (
                    <GridItem></GridItem>
                  )}
                  <GridItem>
                    <OnCallStartAndEndTime
                      onChangeStartTime={(selectedValue: any) =>
                        timeChangeHandler(slot.id, selectedValue, 'startTime')
                      }
                      onChangeEndTime={(selectedValue: any) =>
                        timeChangeHandler(slot.id, selectedValue, 'endTime')
                      }
                      startTimevalue={
                        typeof slot.startTime === 'string'
                          ? { label: slot.startTime, value: slot.startTime }
                          : slot.startTime
                      }
                      endTimevalue={
                        typeof slot.endTime === 'string'
                          ? { label: slot.endTime, value: slot.endTime }
                          : slot.endTime
                      }
                      startTimeOptions={timeOptions}
                      endTimeOptions={timeOptions}
                    />
                  </GridItem>
                  {index !== (inputSlots as ITimeSlot[])?.length - 1 ? (
                    <GridItem></GridItem>
                  ) : (
                    <GridItem>
                      <Button
                        variant="link"
                        disabled={!validateSlots(inputSlots as ITimeSlot[]).areSlotsValid}
                        onClick={() => addSlotHandler(slot.dayOfWeek)}
                      >
                        {rotationsCustomizePattern.customRepeat.buttons.addSlot}
                      </Button>
                    </GridItem>
                  )}
                  {(inputSlots?.length ?? 1) > 1 ? (
                    <GridItem>
                      <IconButton
                        aria-label="remove-slot"
                        icon={<CloseIcon />}
                        variant="link"
                        color="red"
                        mt={2}
                        onClick={() => removeSlotHandler(slot.id)}
                        _hover={{ cursor: 'pointer' }}
                      />
                    </GridItem>
                  ) : (
                    <GridItem></GridItem>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
          {!repeatHoursAndTime && (
            <>
              {(inputSlots as DayWiseSlots[])?.map((selectedDaySlot, dayIndex: number) => {
                const slotDay = `${selectedDaySlot.day?.[0].toUpperCase()}${selectedDaySlot.day
                  ?.split('')
                  .splice(1)
                  .join('')}`;
                return (
                  <React.Fragment key={dayIndex}>
                    <GridItem>{slotDay}</GridItem>
                    {selectedDaySlot.slots.map((slot: any, slotIndex: number) => {
                      return (
                        <React.Fragment key={slotIndex}>
                          {slotIndex > 0 && <GridItem></GridItem>}
                          {slotIndex === 0 ? (
                            <GridItem>
                              <OnCallDuration inputSlots={selectedDaySlot.slots} />
                            </GridItem>
                          ) : (
                            <GridItem></GridItem>
                          )}

                          <GridItem>
                            <OnCallStartAndEndTime
                              onChangeStartTime={(selectedValue: any) =>
                                timeChangeHandler(slot.id, selectedValue, 'startTime')
                              }
                              onChangeEndTime={(selectedValue: any) =>
                                timeChangeHandler(slot.id, selectedValue, 'endTime')
                              }
                              startTimevalue={
                                typeof slot.startTime === 'string'
                                  ? { label: slot.startTime, value: slot.startTime }
                                  : slot.startTime
                              }
                              endTimevalue={
                                typeof slot.endTime === 'string'
                                  ? { label: slot.endTime, value: slot.endTime }
                                  : slot.endTime
                              }
                              startTimeOptions={timeOptions}
                              endTimeOptions={timeOptions}
                            />
                          </GridItem>
                          {slotIndex !== selectedDaySlot.slots.length - 1 ? (
                            <GridItem></GridItem>
                          ) : (
                            <GridItem>
                              <Button
                                variant="link"
                                disabled={!validateSlots(selectedDaySlot.slots).areSlotsValid}
                                onClick={() => addSlotHandler(slot.dayOfWeek)}
                              >
                                {rotationsCustomizePattern.customRepeat.buttons.addSlot}
                              </Button>
                            </GridItem>
                          )}
                          {selectedDaySlot.slots.length > 1 ? (
                            <GridItem>
                              <IconButton
                                aria-label="remove-slot"
                                icon={<CloseIcon />}
                                variant="link"
                                color="red"
                                mt={2}
                                onClick={() => removeSlotHandler(slot.id)}
                                _hover={{ cursor: 'pointer' }}
                              />
                            </GridItem>
                          ) : (
                            <GridItem></GridItem>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default WeeklySlots;
