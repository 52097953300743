import { Tooltip as ChakraTooltip, VStack, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = {
  label: string | ReactNode;
  children: ReactNode;
  isDisabled?: boolean;
  'aria-label'?: string;
  placement?:
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-start'
    | 'bottom'
    | 'bottom-end'
    | 'left-start'
    | 'left'
    | 'left-end';
  shouldWrapChildren?: boolean;
};

export const Tooltip = ({
  placement,
  label,
  'aria-label': ariaLabel,
  children,
  isDisabled,
  shouldWrapChildren,
}: Props) => {
  placement = placement ?? 'auto';
  return (
    <ChakraTooltip
      hasArrow
      label={label}
      aria-label={ariaLabel ?? 'label'}
      isDisabled={isDisabled}
      placement={placement}
      shouldWrapChildren={shouldWrapChildren}
    >
      {children}
    </ChakraTooltip>
  );
};

export const usernameTooltipLabel = (label: string, username?: string) => (
  <VStack>
    <Text fontWeight="bold" maxW="30ch">
      {label}
      {username && (
        <Text fontWeight="normal" maxW="30ch">
          {username}
        </Text>
      )}
    </Text>
  </VStack>
);
