import { VStack } from '@chakra-ui/react';
import { T_WA_AUDIT_LOGS_DETAILS_VIEWED } from 'core/const/tracker';
import { CircularProgress } from 'library/atoms';
import { CustomDrawerComponent } from 'library/molecules';
import React, { useEffect, useMemo } from 'react';
import { AppTracker } from 'shared/analytics/tracker';
import { useAuditLogsListContext } from '../../context/auditLogsContext';
import { AuditLogsIdSpecficData, DRAWER_TYPE } from '../../types/index.auditLogs';
import DrawerCell from './DrawerCell';

function LogDetails() {
  const context = useAuditLogsListContext();
  const auditLogsData = context.auditLogsIdSpecficData?.data;

  useEffect(() => {
    if (auditLogsData)
      AppTracker.track(T_WA_AUDIT_LOGS_DETAILS_VIEWED, {
        'Log ID': auditLogsData?.id,
      });
  }, [auditLogsData]);

  const renderDataCells = useMemo(() => {
    if (context.logDetailsLoading)
      return (
        <VStack style={{ height: '100%', justifyContent: 'center' }}>
          <CircularProgress />;
        </VStack>
      );
    else if (auditLogsData) {
      return Object.keys(auditLogsData).map((data: keyof AuditLogsIdSpecficData['data']) => {
        const body = auditLogsData[data];
        switch (data) {
          case 'action':
            return <DrawerCell title="Type of Action" body={body} type={DRAWER_TYPE.GENERIC} />;
          case 'timestamp':
            return <DrawerCell title="Timestamp" body={body} type={DRAWER_TYPE.TIMESTAMP} />;
          case 'timezone':
            return <DrawerCell title="Timezone" body={body} type={DRAWER_TYPE.GENERIC} />;
          case 'additionalInfo':
            return <DrawerCell title="Additional Info" body={body} type={DRAWER_TYPE.GENERIC} />;
          case 'client':
            return <DrawerCell title="Client" body={body} type={DRAWER_TYPE.GENERIC} />;
          case 'tokenType':
            return <DrawerCell title="Token Type" body={body} type={DRAWER_TYPE.GENERIC} />;
          case 'ipAddress':
            return <DrawerCell title="IP Address" body={body} type={DRAWER_TYPE.GENERIC} />;
          case 'actor':
            return <DrawerCell title="Actors" body={body} type={DRAWER_TYPE.ASSIGNEE} />;
          case 'meta':
            return <DrawerCell title="Meta" body={body} type={DRAWER_TYPE.META} />;
          case 'team':
            return <DrawerCell title="Team" body={body} type={DRAWER_TYPE.LINK} />;
          case 'resource':
            return <DrawerCell title="Resource" body={body} type={DRAWER_TYPE.GENERIC} />;
          case 'userAgent':
            return <DrawerCell title="User Agent" body={body} type={DRAWER_TYPE.GENERIC} />;
        }
      });
    }
  }, [auditLogsData, context.logDetailsLoading]);
  if (auditLogsData) {
    return (
      <CustomDrawerComponent
        disableBodyPadding
        title="View Log"
        size="sm"
        isOpen={context.showDetailsDrawer}
        onClose={context.toggleLogsDrawer}
      >
        {renderDataCells}
      </CustomDrawerComponent>
    );
  }
  return <></>;
}

export default React.memo(LogDetails);
