import { InfoRedIcon } from 'icons';
import * as React from 'react';
import {
  ConflictEntityData,
  ConflictEntityTypesString,
  DeleteSquadConflictData,
} from '../../types';
import {
  BadgeContainer,
  ConflictItemWrapper,
  HeaderContent,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
  SectionTitle,
  WarningMessage,
} from './styles';

const MAX_ENTITIES_TO_SHOW = 2;

interface DeleteSquadModalProps {
  entities: DeleteSquadConflictData;
  squadName: string;
}

interface ConflictItemProps {
  serviceName: string;
}

function BadgeButton({ count }: { count: number }) {
  return <BadgeContainer>+{count}</BadgeContainer>;
}

const ConflictItem: React.FC<ConflictItemProps> = ({ serviceName }) => {
  return <ConflictItemWrapper>{serviceName}</ConflictItemWrapper>;
};

const ConflictEntityList = ({
  entityName,
  conflictEntityData,
}: {
  entityName: string;
  conflictEntityData: Array<ConflictEntityData>;
}) => {
  const [showAllEntities, setShowAllEntities] = React.useState(false);

  const conflictEntityDataToShow =
    conflictEntityData?.slice?.(
      0,
      showAllEntities ? conflictEntityData.length : MAX_ENTITIES_TO_SHOW,
    ) ?? [];

  const remainingEntitiesCount = conflictEntityData.length - MAX_ENTITIES_TO_SHOW;

  return (
    <>
      <SectionTitle>{entityName}</SectionTitle>
      {conflictEntityDataToShow.map((conflict, index) => {
        const isLastEntity = index === conflictEntityDataToShow.length - 1;
        const canShowAllEntities = remainingEntitiesCount > 0 && !showAllEntities && isLastEntity;
        return (
          <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => {
              setShowAllEntities(true);
            }}
            key={conflict.name}
          >
            <ConflictItem serviceName={conflict.name} />
            {canShowAllEntities && <BadgeButton count={remainingEntitiesCount} />}
          </div>
        );
      })}
    </>
  );
};

export const DeleteSquadModalContent: React.FC<DeleteSquadModalProps> = ({
  entities,
  squadName,
}) => {
  return (
    <ModalWrapper>
      <ModalHeader>
        <HeaderContent>
          <InfoRedIcon width="24px" height="24px" />
          <ModalTitle>Delete Squad</ModalTitle>
        </HeaderContent>
      </ModalHeader>
      <ModalContent>
        <WarningMessage>
          <strong>{squadName}</strong> owns the below entities and thus cannot be deleted. Transfer
          ownership of these entities before deleting the squad.
        </WarningMessage>
        <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
          {Object.keys(entities).map(entity => {
            console.log('entity', entities[entity]);
            if (typeof entities[entity] === 'undefined' || entities[entity] === null) return null;
            if (typeof entities[entity] === 'number' && entities[entity] > 0) {
              return (
                <div key={entity}>
                  <SectionTitle>{entity}</SectionTitle>
                  <ConflictItem serviceName={entities[entity] + ''} />
                </div>
              );
            }
            if (Array.isArray(entities[entity])) {
              const entityArray = entities[entity] as Array<ConflictEntityData>;
              if (entityArray.length === 0) {
                return null;
              }
              return (
                <ConflictEntityList
                  key={entity}
                  entityName={ConflictEntityTypesString[entity]}
                  conflictEntityData={entityArray}
                />
              );
            }
          })}
        </div>
      </ModalContent>
    </ModalWrapper>
  );
};
