import { EventModal } from '..';
import React from 'react';
import { Row, IconButton, FocusBlock, ErrorText } from '@squadcast/alchemy-ui/carbon';
import shortid from 'shortid';
import { TextButton } from '@squadcast/alchemy-ui/metal';
import { InfoIcon } from '../../../../../../icons';
import { Checkbox, Grid, Theme, Tooltip } from 'uie/components';
const { theme } = Theme;

function rotation(this: EventModal) {
  const { repeats } = this.state;
  const orgConfig = this.props.organization.currentOrg.o?.config;
  const editSchedulesDisabled = !!orgConfig?.disable_edit_old_schedules;

  const isVerified = (entityId: string): boolean => {
    const user = this.oUsers[entityId];
    if (user) return user?.emailVerified ?? false;

    const squad = this.oSquads[entityId];
    if (squad) return squad.members.every((u: any) => this.oUsers[u]?.emailVerified ?? false);
    return false;
  };

  const nonStakeHolderUsers = Object.values(this.oUsers).filter(
    (u: any) => u.role !== 'stakeholder',
  );
  return (
    <div>
      {this.isSingleEventUpdate ? (
        <>
          <label className="item-box-tagline font-bold block mb-10">Assignee</label>
          <div className="field-parent-div">
            <div>
              <div className="tags">
                <Row alignItems="center" flexWrap="wrap">
                  {[...(this.state.user_ids || []), ...(this.state.squad_ids || [])].map(
                    (ids: string, index) => {
                      return !isVerified(ids) || !(this.oUsers[ids]?.phoneVerified ?? false) ? (
                        <Tooltip
                          key={index}
                          padding="8px"
                          offset={{ top: '-90px', left: '0' }}
                          width="300px"
                          label={
                            !isVerified(ids)
                              ? 'Unverified users detected. Users need to verify email to start receiving notifications.'
                              : 'User needs to verify phone number to get SMS and Phone notifications'
                          }
                        >
                          <div className="item-member" style={{ marginTop: 5 }}>
                            <img
                              src="/icons/not_verified.svg"
                              className="item-member-icon"
                              alt="service"
                            />
                            <InfoIcon
                              className="item-member-icon"
                              height={14}
                              width={14}
                              fill={theme.shades.grey}
                              stroke={theme.shades.white}
                            />
                            <span>
                              {{ ...this.oUsers, ...this.oSquads }[ids]?.name ?? '(Deleted Entity)'}
                            </span>
                            <img
                              className="cursor-pointer close-icon"
                              src="/icons/close.svg"
                              onClick={this.onRemoveFromEventClick(ids)}
                              alt="close"
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <div className="item-member" key={index} style={{ marginTop: 5 }}>
                          <img
                            src={
                              this.state.user_ids.includes(ids)
                                ? '/icons/greys/profile.svg'
                                : '/icons/greys/squad.svg'
                            }
                            className="item-member-icon"
                            alt="service"
                          />
                          <span>
                            {{ ...this.oUsers, ...this.oSquads }[ids]?.name ?? '(Deleted Entity)'}
                          </span>
                          <img
                            className="cursor-pointer close-icon"
                            src="/icons/close.svg"
                            onClick={this.onRemoveFromEventClick(ids)}
                            alt="close"
                          />
                        </div>
                      );
                    },
                  )}
                </Row>
                <div style={{ position: 'relative' }}>
                  <input
                    id="squad-member"
                    type="text"
                    className="input-design hidden"
                    placeholder="Search for users or squads"
                    value={this.state.userSearch[0].value}
                    onClick={this.searchUsersClick}
                    onChange={this.searchUserOnChange(0)}
                    onFocus={this.onSearchUserFocus(0)}
                    autoComplete="off"
                  />
                  {this.state.userSearchFocusIndex === 0 && (
                    <div className="search-drop-down">
                      <div>
                        {[...Object.values(nonStakeHolderUsers), ...Object.values(this.oSquads)]
                          .filter(
                            (e: any) =>
                              ![
                                ...(this.state.user_ids || []),
                                ...(this.state.squad_ids || []),
                              ].includes(e._id),
                          )
                          .filter(
                            (e: any) =>
                              e.name
                                .toLowerCase()
                                .indexOf(this.state.userSearch[0].value.toLowerCase()) > -1,
                          )
                          .map((e: any, index) => {
                            return (
                              <FocusBlock
                                onClick={this.onEventFocusBlockClick(e)}
                                value={e}
                                key={shortid.generate()}
                              >
                                <Row alignItems="center">
                                  <div>
                                    {e.type === 'u' && (
                                      <img src="/icons/greys/profile.svg" alt="user" />
                                    )}
                                    {e.type === 's' && (
                                      <img src="/icons/greys/squad.svg" alt="user" />
                                    )}
                                  </div>
                                  <small className="ml-10">{e.name}</small>
                                </Row>
                              </FocusBlock>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <label className="item-box-tagline font-bold block">Assignee Groups</label>
          {this.state.rotations.map((group, rotationIndex) => {
            const { user_ids, squad_ids } = group;
            const { starting_group } = this.state;

            return (
              <div className="field-parent-div" key={rotationIndex}>
                <Row justifyContent="space-between">
                  <p className="modal-container-sub-heading" style={{ marginBottom: 10 }}>
                    #{rotationIndex + 1} Group
                  </p>
                  {repeats && (
                    <Grid>
                      {this.state.id && this.state.update_type === '2' && (
                        <Grid style={{ marginTop: '10px' }}>
                          <Tooltip label="Starting Shift" offset={{ left: '-60px', top: '-45px' }}>
                            <Checkbox
                              checked={starting_group === rotationIndex}
                              onChange={this.changeStartingGroup(rotationIndex)}
                            />
                          </Tooltip>
                        </Grid>
                      )}
                      <div>
                        <IconButton
                          id="event_remove_rotations"
                          overRideBorder={true}
                          name="remove rotations"
                          ariaLabel="remove rotations"
                          onClick={this.onRemoveRotationClick(rotationIndex)}
                          icon="/icons/remove.svg"
                          size="small"
                        />
                      </div>
                    </Grid>
                  )}
                </Row>
                <div>
                  <div className="tags">
                    <Row alignItems="center" flexWrap="wrap">
                      {[...(user_ids || []), ...(squad_ids || [])].map((ids: string, index) => {
                        return !isVerified(ids) || !(this.oUsers[ids]?.phoneVerified ?? false) ? (
                          <Tooltip
                            key={index}
                            padding="8px"
                            offset={{ top: '-90px', left: '0' }}
                            width="300px"
                            label={
                              !isVerified(ids)
                                ? 'Unverified users detected. Users need to verify email to start receiving notifications.'
                                : 'User needs to verify phone number to get SMS and Phone notifications'
                            }
                          >
                            <div className="item-member" style={{ marginTop: 5 }}>
                              <img
                                src="/icons/not_verified.svg"
                                className="item-member-icon"
                                alt="service"
                              />
                              <InfoIcon
                                className="item-member-icon"
                                height={14}
                                width={14}
                                fill={theme.shades.grey}
                                stroke={theme.shades.white}
                              />
                              <span>
                                {{ ...this.oUsers, ...this.oSquads }[ids]?.name ??
                                  '(Deleted Entity)'}
                              </span>
                              <img
                                className="cursor-pointer close-icon"
                                src="/icons/close.svg"
                                onClick={this.onRemoveFromCalendarClick(ids, rotationIndex)}
                                alt="close"
                              />
                            </div>
                          </Tooltip>
                        ) : (
                          <div className="item-member" key={index} style={{ marginTop: 5 }}>
                            <img
                              src={
                                user_ids.includes(ids)
                                  ? '/icons/greys/profile.svg'
                                  : '/icons/greys/squad.svg'
                              }
                              className="item-member-icon"
                              alt="service"
                            />
                            <span>
                              {{ ...this.oUsers, ...this.oSquads }[ids]?.name ?? '(Deleted Entity)'}
                            </span>
                            <img
                              className="cursor-pointer close-icon"
                              src="/icons/close.svg"
                              onClick={this.onRemoveFromCalendarClick(ids, rotationIndex)}
                              alt="close"
                            />
                          </div>
                        );
                      })}
                    </Row>
                    <div style={{ position: 'relative' }}>
                      <input
                        id={`squad-member-${rotationIndex}`}
                        type="text"
                        className="input-design hidden"
                        placeholder="Search for users or squads"
                        value={this.state.userSearch[rotationIndex].value}
                        onClick={this.searchUsersClick}
                        onChange={this.searchUserOnChange(rotationIndex)}
                        onFocus={this.onSearchUserFocus(rotationIndex)}
                        autoComplete="off"
                      />
                      {this.state.userSearchFocusIndex === rotationIndex && (
                        <div className="search-drop-down">
                          <div>
                            {[...Object.values(nonStakeHolderUsers), ...Object.values(this.oSquads)]
                              .filter(
                                (e: any) =>
                                  ![...(user_ids || []), ...(squad_ids || [])].includes(e._id),
                              )
                              .filter(
                                (e: any) =>
                                  e.name
                                    .toLowerCase()
                                    .indexOf(
                                      this.state.userSearch[rotationIndex].value.toLowerCase(),
                                    ) > -1,
                              )
                              .map((e: any, index) => {
                                return (
                                  <FocusBlock
                                    onClick={this.onRotationFocusBlockClick(e, rotationIndex)}
                                    value={e}
                                    key={shortid.generate()}
                                  >
                                    <Row alignItems="center">
                                      <div>
                                        {e.type === 'u' && (
                                          <img src="/icons/greys/profile.svg" alt="user" />
                                        )}
                                        {e.type === 's' && (
                                          <img src="/icons/greys/squad.svg" alt="user" />
                                        )}
                                      </div>
                                      <small className="ml-10">{e.name}</small>
                                    </Row>
                                  </FocusBlock>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {group.user_ids.length === 0 &&
                  group.squad_ids.length === 0 &&
                  !!this.state.errors.rotations && <ErrorText text={this.state.errors.rotations} />}
                {!!this.state.errors.stakeholder && (
                  <ErrorText text={this.state.errors.stakeholder} />
                )}
              </div>
            );
          })}

          {repeats && (
            <div className="pt-10">
              <TextButton
                id="rotation_add_new"
                onClick={this.onAddRotation}
                text="Add Group"
                type="ghost"
                size="small"
              />
            </div>
          )}
          {this.state.rotations.length > 1 && (
            <div className="mt-20">
              <p className="modal-container-sub-heading" style={{ marginBottom: 10 }}>
                <label className="item-box-tagline font-bold block">Change Assigned Group</label>
              </p>
              <Row alignItems="center">
                <input
                  id="single_ahift"
                  type="radio"
                  className="cursor-pointer radio-design no-select no-select"
                  name="single_shift"
                  value="1"
                  checked={this.state.change_assigned_group === 'single_shift'}
                  onChange={this.onShiftInputChange('single_shift', 1)}
                  style={{ marginRight: 10, marginBottom: 10 }}
                />
                <label
                  htmlFor="single_ahift"
                  className="cursor-pointer item-box-tagline cursor-pointer block"
                  style={{ position: 'relative', marginRight: 30, marginBottom: 10 }}
                >
                  Every Shift
                </label>
              </Row>
              <Row alignItems="center">
                <input
                  id="custom_shift"
                  type="radio"
                  className="cursor-pointer radio-design no-select no-select"
                  name="custom_shift"
                  value="2"
                  checked={this.state.change_assigned_group === 'custom_shift'}
                  onChange={this.onShiftInputChange('custom_shift', 2)}
                  style={{ marginRight: 10 }}
                />
                <label
                  htmlFor="custom_shift"
                  className="cursor-pointer item-box-tagline cursor-pointer block"
                  style={{ position: 'relative', marginRight: 25 }}
                >
                  Every
                </label>
                <input
                  className="input-design"
                  id="rotation_shifts"
                  name="rotation_shifts"
                  type="number"
                  style={{ width: 80, marginRight: 15 }}
                  disabled={this.state.change_assigned_group === 'single_shift'}
                  value={
                    this.state.change_assigned_group === 'custom_shift'
                      ? this.state.rotationFrequency
                      : 2
                  }
                  onChange={this.onRotationShiftFrequencyChange}
                />
                <label
                  htmlFor="custom_shift"
                  className="cursor-pointer item-box-tagline cursor-pointer block"
                  style={{ position: 'relative' }}
                >
                  Shifts
                </label>
              </Row>
            </div>
          )}
        </>
      )}

      {!!this.state.errors.rotationFrequency && (
        <ErrorText text={this.state.errors.rotationFrequency} />
      )}
      {this.state.id !== '' && this.state.end_time >= new Date() && (
        <div>
          <div className="mt-20">
            <p className="modal-container-sub-heading" style={{ marginBottom: 10 }}>
              Update method
            </p>
            <Row alignItems="center">
              <input
                id="update_method_1"
                type="radio"
                className="cursor-pointer radio-design no-select no-select"
                name="Update this event only"
                value="1"
                checked={this.state.update_type === '1'}
                onChange={this.onUpdateMethodChange}
                style={{ marginRight: 10 }}
              />
              <label
                htmlFor="update_method_1"
                className="cursor-pointer item-box-tagline cursor-pointer block"
                style={{ position: 'relative', marginRight: 30 }}
              >
                This Event only
              </label>
            </Row>
            <Row alignItems="center">
              <input
                id="update_method_2"
                type="radio"
                className="cursor-pointer radio-design no-select no-select"
                name="Update this event only"
                value="2"
                checked={this.state.update_type === '2'}
                onChange={this.onUpdateMethodChange}
                style={{ marginRight: 10 }}
              />
              <label
                htmlFor="update_method_2"
                className="cursor-pointer item-box-tagline cursor-pointer block"
                style={{ position: 'relative', marginRight: 30 }}
              >
                This and proceeding events
              </label>
            </Row>
          </div>
        </div>
      )}

      <Row flexWidth={12} alignItems="center" justifyContent="space-between">
        {this.state.id !== '' && this.state.end_time < new Date() ? (
          <div />
        ) : !editSchedulesDisabled ? (
          <div className="mt-20">
            {!!this.state.errors.network && (
              <div className="mb-10">
                <ErrorText text={this.state.errors.network} />
              </div>
            )}
            <TextButton
              id="event_save"
              onClick={this.onSave}
              loading={this.state.networkState === 'request'}
              text={this.state.id === '' ? 'Create' : 'Update'}
            />
          </div>
        ) : null}
        {this.state.id !== '' && !editSchedulesDisabled && (
          <div className="mt-20">
            <Row alignItems="center" justifyContent="space-between">
              {this.state.id.length > 0 && (
                <div style={{ position: 'relative', display: 'inline' }}>
                  <button
                    onClick={this.onDeleteButtonClick}
                    className="filter-button-small-design delete-button--dropdown"
                  >
                    Delete &nbsp;&nbsp;
                    <i className={`fa fa-angle-${this.state.showDeleteOptions ? 'up' : 'down'}`} />
                  </button>
                  {this.state.showDeleteOptions && (
                    <div
                      className="filter-drop-left delete-button--dropdown-container"
                      style={{ top: 60, width: 200 }}
                    >
                      <button className="delete-option" value="1" onClick={this.onDeleteEvent}>
                        This event only
                      </button>

                      <button className="delete-option" value="2" onClick={this.onDeleteEvent}>
                        This and proceeding events
                      </button>
                    </div>
                  )}
                </div>
              )}
            </Row>
          </div>
        )}
      </Row>
    </div>
  );
}

export default rotation;
