import * as React from 'react';
import { SVGProps } from 'react';

const SvgCloudtrailCloudwatch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="cloudtrail-cloudwatch_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.cloudtrail-cloudwatch_svg__st0{fill:#b7ca9d}.cloudtrail-cloudwatch_svg__st1{fill:#4c622c}.cloudtrail-cloudwatch_svg__st2{fill:#759c3f}'
      }
    </style>
    <path className="cloudtrail-cloudwatch_svg__st0" d="m10 20 8.8-6.2-8.8-1.3-8.8 1.3L10 20z" />
    <path
      className="cloudtrail-cloudwatch_svg__st1"
      d="M2.9 3.9v9.7l1.5.4 3.5-5.4-3.5-5.3-1.5.6z"
    />
    <path className="cloudtrail-cloudwatch_svg__st2" d="M9.7 4.5v8.7l-5.3.9V3.3l5.3 1.2z" />
    <path
      className="cloudtrail-cloudwatch_svg__st1"
      d="M13.5 7.9 7.4 15l-2.1-.6V1l2.1-1 6.1 7.9z"
    />
    <path className="cloudtrail-cloudwatch_svg__st2" d="M14.4 2.8v10.6l-7 1.6V0l7 2.8z" />
    <path className="cloudtrail-cloudwatch_svg__st1" d="M10 20v-3.1l-8.8-3.1v1.8L10 20z" />
    <path className="cloudtrail-cloudwatch_svg__st2" d="M18.8 13.8v1.8L10 20v-3.1l8.8-3.1z" />
    <path className="cloudtrail-cloudwatch_svg__st0" d="m10 15.7 6.4-5h-2.1l-6.1.4 1.8 4.6z" />
    <path className="cloudtrail-cloudwatch_svg__st1" d="M8.1 11.2v4l1.9.6v-4.4l-1.9-.2z" />
    <path className="cloudtrail-cloudwatch_svg__st2" d="M16.4 13.8v-3.1l-6.4.7v4.4l6.4-2z" />
  </svg>
);

export default SvgCloudtrailCloudwatch;
