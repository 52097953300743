import { Box, Link, VStack } from '@chakra-ui/layout';
import { Text } from 'library/atoms/Text';
import { PageHeader } from 'library/molecules';

const HelpAndGuide = () => {
  return (
    <VStack backgroundColor="white" alignItems="flex-start" justifyContent="flex-start">
      <PageHeader title="Help and Guide" noButton />
      <Box px={6}>
        <Text variant="h2">What’s a Workflow?</Text>
        <Text mt={3}>
          Workflow represents a predefined series of actions initiated by specific conditions. These
          workflows are instrumental for automating responses to various incidents. For example, you
          can establish a Workflow for addressing P1/P2 incidents and another for managing security
          incidents.
        </Text>
        <Box mt={1}>
          <Link
            href="https://support.squadcast.com/workflows/workflows"
            sx={{
              fontSize: 'sm',
            }}
          >
            Read More
          </Link>
        </Box>
        <Text variant="h2" mt={4}>
          Components of a Workflow
        </Text>
        <Text>
          A Workflow comprises two fundamental components: Triggers and Actions. By tailoring these
          <strong> triggers and actions </strong> to your specific requirements, you can tailor
          Workflows to suit your unique use case.
        </Text>
        <Text mt={1}>
          <strong> Triggers: </strong>
          Triggers are events that dictate when actions should be executed. You can add multiple
          trigger conditions within each Workflow.
          <br />
          <Text variant="h4">
            <strong> Note: </strong>
            In the case of multiple trigger conditions, all the conditions should be met in order to
            set off the actions.
          </Text>
        </Text>
        <Text mt={1}>
          <strong> Actions: </strong>
          Each Workflow includes a predefined sequence of actions that determine the progression of
          the Workflow. These actions may involve tasks such as attaching Runbook(s) or marking an
          incident as SLO affecting.
          <br />
          <Text variant="h4">
            <strong> Note: </strong>
            Each action will be executed serially, and all actions executed by workflows will be
            recorded in the Incident timeline.
            <br />
            The workflow will continue to run irrespective of success / failure of a specific
            action.
          </Text>
        </Text>
        <Text variant="h2" mt={4}>
          How to pick an Owner?
        </Text>
        <Text mt={1}>
          A Workflow Owner is an individual who can be reached out to for clarifications,
          adjustments, or removal of a Workflow.
        </Text>
        <Text variant="h4">
          Note: By default, the team is set as the owner. You may change it using the drop-down.
        </Text>
        <Text variant="h2" mt={4}>
          How to use Tags?
        </Text>
        <Text mt={1}>
          Pick tags to organize, classify and add context to your Workflows. For instance, adding
          tags like Environment, Cluster/Team name and Priority can help structure and add more
          context to your Workflow.
        </Text>
        <Text variant="h4" mt={8} color="brand.blue">
          If you need help with something, use the chat widget below to reach us.
        </Text>
      </Box>
    </VStack>
  );
};

export default HelpAndGuide;
