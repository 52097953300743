import React, { ReactNode, useMemo } from 'react';
import { Avatar, AvatarGroup, Box, HStack, Text, VStack } from '@chakra-ui/react';

import { NodePopover } from 'components/chakra/Popover';
import ScheduleAvatarGroup from '../../../common/AvatarGroup';
import SQTooltip from 'components/chakra/Tooltip';

interface Props {
  view: string;
  children: ReactNode;
  backgroundColor: string;
  textColor: string;
  title: string;
  startTime: string;
  endTime: string;
  disableEventPopover: boolean | undefined;
  eventParticipants: Array<any>;
  isAllDayTZ: boolean;
  isAGap: boolean;
  isPastEvent: boolean;
  showEventParticipants: boolean;
  showEventName: boolean;
  showEventTime: boolean;
  eventOffset: number;
  overlappedByOverride: boolean;
}

export const CalendarEvent = ({
  view,
  children,
  backgroundColor,
  textColor,
  startTime,
  endTime,
  disableEventPopover,
  eventParticipants,
  title,
  isAllDayTZ,
  isAGap,
  isPastEvent,
  showEventParticipants,
  showEventName,
  showEventTime,
  eventOffset,
  overlappedByOverride,
}: Props) => {
  const eventDetailsMonthView = useMemo(() => {
    return (
      <Box bg="white">
        <HStack p={1} w="100%" backgroundColor={backgroundColor}>
          <Text color={textColor} className={isAGap ? 'schedules-event-gap' : ''}>
            {`${startTime} - ${endTime}`}
            <Text as="span" ml={1} textColor={isAGap ? '' : 'gray.700'}>
              {eventOffset > 0 ? `+${eventOffset}` : ``}
            </Text>
          </Text>
          {eventParticipants.length > 0 && (
            <>
              <Text color={textColor} fontWeight="bold" ml={2}>{`|`}</Text>
              <ScheduleAvatarGroup
                avatars={eventParticipants.map(p =>
                  p.type === 'squad' ? p.participant.members : p.participant,
                )}
                avatarBGColor={isPastEvent || overlappedByOverride ? 'GrayText' : ''}
              />
            </>
          )}
        </HStack>
      </Box>
    );
  }, [startTime, endTime, eventParticipants]);

  const eventDetailsOtherViews = useMemo(() => {
    return (
      <Box bg="white" h="100%">
        <VStack
          ml="-2px"
          mr="-4px"
          h="100%"
          px={2}
          py={0.5}
          alignItems="flex-start"
          bg={backgroundColor}
          spacing={1}
        >
          {eventParticipants.length > 0 && showEventParticipants && (
            <ScheduleAvatarGroup
              avatars={eventParticipants.map(p =>
                p.type === 'squad' ? p.participant.members : p.participant,
              )}
              avatarBGColor={isPastEvent || overlappedByOverride ? 'GrayText' : ''}
            />
          )}
          {showEventName && (
            <SQTooltip text={title}>
              <Text
                fontSize="xs"
                color={textColor}
                className={isAGap ? 'schedules-event-gap' : ''}
                maxW={55}
                isTruncated
              >
                {title}
              </Text>
            </SQTooltip>
          )}
          {showEventTime && (
            <>
              <Text color={textColor}>
                {isAllDayTZ ? 'All Day' : `${startTime} - ${endTime}`}
                <Text as="span" ml={1} textColor={isAGap ? '' : 'gray.700'}>
                  {eventOffset > 0 ? `+${eventOffset}` : ``}
                </Text>
              </Text>
            </>
          )}
        </VStack>
      </Box>
    );
  }, [startTime, endTime, eventParticipants, title, isAllDayTZ]);

  switch (view) {
    case 'dayGridMonth': {
      if (disableEventPopover) {
        return eventDetailsMonthView;
      }
      return (
        <NodePopover trigger={eventDetailsMonthView} placement="auto">
          {children}
        </NodePopover>
      );
    }
    default: {
      if (disableEventPopover) {
        return eventDetailsOtherViews;
      }
      return (
        <NodePopover trigger={eventDetailsOtherViews} placement="left">
          {children}
        </NodePopover>
      );
    }
  }
};
