import { IAdditionalInfo } from 'core/interfaces/IIncidents';
import { JSXResponseForEntity } from '../helpers';

type ResponseForPublicMessageProps = {
  additionalInfo: IAdditionalInfo | undefined;
};
const ResponseForPublicMessages: React.FC<
  Pick<JSXResponseForEntity, 'getEntityComponentWithTruncatedName'> & ResponseForPublicMessageProps
> = ({ additionalInfo, getEntityComponentWithTruncatedName }) => {
  if (additionalInfo?.userId) {
    return getEntityComponentWithTruncatedName({
      componentType: 'linkWPopover',
      type: 'user',
      id: additionalInfo.userId,
      popoverPlacement: 'left',
    });
  } else return <>Deleted Entity</>;
};
export default ResponseForPublicMessages;
