import { DateTime } from 'luxon';

import {
  CustomPeriodUnit,
  DayOfWeek,
  OwnerType,
} from 'views/main/organization/schedules/graphql/types';
import { useOrganizationConfig } from '..';
import { initialSlotValue, rotationsCustomizePattern } from '../constants/rotations.customize';
import { ICustomPeriod, InputMaybe, IRotation, ITimeSlot } from '../interface/schedule';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { ISquad } from 'core/interfaces/ISquad';
import { ITeam } from 'core/interfaces/ITeams';
import { getPaddedValue } from './helpers.event';

export interface IPatternParticipant {
  value: string;
  label: string;
  type: OwnerType;
  id: string;
  timeZone?: string;
  members?: string[];
  username?: string;
}

const useGetAllPatternParticipants = (): Array<IPatternParticipant> => {
  const { organization } = useOrganizationConfig();
  const selectedTeam = organization?.selectedTeam?.team;
  const membersOfSelectedTeam = selectedTeam?.members.map(member => member.user_id);
  return [
    ...(organization?.users.u
      ? organization?.users?.u
          .filter(user => membersOfSelectedTeam?.includes(user.id) && user.role !== 'stakeholder')
          .sort((comparingElement: any, compareWithElement: any) =>
            comparingElement.first_name.localeCompare(compareWithElement.first_name),
          )
          .map(
            (
              {
                first_name,
                last_name,
                id,
                time_zone,
                username_for_display: username,
              }: IUsersOfOrganization,
              index: number,
            ) => {
              return {
                value: id,
                label: `${first_name} ${last_name}`,
                type: OwnerType.User,
                id: id,
                timeZone: time_zone,
                username,
              };
            },
          )
      : []),
    ...(organization?.squads.s
      ? organization?.squads?.s
          .filter(squad => {
            return squad.members?.some(member => membersOfSelectedTeam?.includes(member));
          })
          .sort((comparingElement: any, compareWithElement: any) =>
            comparingElement.name.localeCompare(compareWithElement.name),
          )
          .map(({ id, name, members }: ISquad, index: number) => {
            return {
              value: id,
              label: name,
              type: OwnerType.Squad,
              id: id,
              members: members,
            };
          })
      : []),
  ];
};

const getCustomRepeatsText = (
  customPeriod: InputMaybe<ICustomPeriod> | undefined,
  rotationStartDate: Date | undefined,
) => {
  let customRepeatsText = `Changes `;
  if (customPeriod) {
    const frequency = customPeriod.periodFrequency;
    const periodUnit = customPeriod.periodUnit;
    const repeatHoursAndTime = customPeriod.repeatHoursAndTime;
    switch (periodUnit) {
      case CustomPeriodUnit.Day: {
        customRepeatsText += `${frequency > 1 ? ' every ' + frequency + ' days' : 'daily'}`;
        break;
      }
      case CustomPeriodUnit.Week: {
        customRepeatsText += `${frequency > 1 ? ' every ' + frequency + ' weeks' : 'weekly'}`;
        customRepeatsText += repeatHoursAndTime
          ? ` on ${mapWeekdayFilterToSelection(customPeriod.daysOfWeekFilter)
              .map(weekday => weekday[0].toUpperCase() + weekday.split('').splice(1).join(''))
              .join(', ')}`
          : ' as follows: ';
        break;
      }
      case CustomPeriodUnit.Month: {
        customRepeatsText += `${
          frequency > 1 ? ' every ' + frequency + ' months' : 'monthly'
        } on day ${DateTime.fromJSDate(rotationStartDate ?? new Date()).day}`;
        break;
      }
    }
    return customRepeatsText;
  } else {
    return '';
  }
};

const getCustomTimeSlotsText = (customPeriod: InputMaybe<ICustomPeriod> | undefined) => {
  if (customPeriod) {
    let customTimeSlotsText = `On-call Start and End Time: `;
    const timeSlots = customPeriod.timeSlots;
    const periodUnit = customPeriod.periodUnit;
    const firstSlot = timeSlots[0];

    if (!firstSlot || !timeSlots || !timeSlots.length) return '';

    const repeatHoursAndTime = customPeriod.repeatHoursAndTime;
    if (timeSlots.length === 1 && periodUnit !== CustomPeriodUnit.Week) {
      customTimeSlotsText = customTimeSlotsText + `${firstSlot.startTime} - ${firstSlot.endTime}`;
    } else if (periodUnit === CustomPeriodUnit.Week) {
      const weekdaysFromSlots = timeSlots.map(slot => slot.dayOfWeek);
      const uniqueWeekDays = Array.from(new Set(weekdaysFromSlots));
      if (repeatHoursAndTime) {
        if (uniqueWeekDays.length === weekdaysFromSlots.length) {
          customTimeSlotsText =
            customTimeSlotsText + `${firstSlot.startTime} - ${firstSlot.endTime}`;
        } else {
          customTimeSlotsText = `Multiple on-call slots selected`;
        }
      } else {
        return uniqueWeekDays.map(weekday => {
          if (!weekday) return;

          let outputString = `${weekday[0].toUpperCase() + weekday.split('').splice(1).join('')}| `;
          const countOfSlots = timeSlots.filter(slot => slot.dayOfWeek === weekday).length;
          if (countOfSlots > 1) {
            outputString += `Multiple on-call slots selected`;
          } else {
            const firstSlot = timeSlots.find(slot => slot.dayOfWeek === weekday);
            if (!firstSlot) return;
            outputString += `${firstSlot.startTime} - ${firstSlot.endTime}`;
          }
          return outputString;
        });
      }
    } else {
      customTimeSlotsText = `Multiple on-call slots selected`;
    }
    return customTimeSlotsText;
  } else {
    return '';
  }
};

const getRepeatMonthlyDropDownOptions = (rotationStartDate: Date) => {
  //Get Date
  const date = rotationStartDate.getDate();

  //Get Day
  const day = rotationStartDate.getDay();

  //Get name of the day
  const dayName = rotationsCustomizePattern.customRepeat.selectOptions.daysOfWeek[day];

  //Get total weeks in the month
  const firstDay = new Date(new Date(rotationStartDate.toDateString()).setDate(1)).getDay();
  const totalDays = new Date(
    rotationStartDate.getFullYear(),
    rotationStartDate.getMonth() + 1,
    0,
  ).getDate();
  const totalWeeksOfMonth = Math.ceil((firstDay + totalDays) / 7);

  const currentWeekOfMonth = Math.ceil((date - 1 - day) / 7) + 1;

  //Get the rank of the week
  let rankOfTheWeek = '';
  let rankChanged = false;
  if (currentWeekOfMonth <= 5) {
    rankOfTheWeek =
      rotationsCustomizePattern.customRepeat.selectOptions.ranks[currentWeekOfMonth - 1];
  }

  //Additional checks to get the correct rank
  if (rankOfTheWeek === 'second') {
    //Check if the date 7 days before falls in the current month
    const backDate = new Date(rotationStartDate).getDate() - 7;
    if (backDate < 1) {
      rankOfTheWeek = 'first';
      rankChanged = true;
    }
  } else if (rankOfTheWeek === 'third') {
    //Check if the date 14 days before falls in the current month
    const backDate = new Date(rotationStartDate).getDate() - 14;
    if (backDate < 1) {
      rankOfTheWeek = 'second';
      rankChanged = true;
    }
  } else if (rankOfTheWeek === 'fourth') {
    //Check if the date 21 days before falls in the current month
    const backDate = new Date(rotationStartDate).getDate() - 21;
    if (backDate < 1) {
      rankOfTheWeek = 'third';
      rankChanged = true;
    }
  } else if (rankOfTheWeek === 'fifth') {
    //Check if the date 28 days before falls in the current month
    const backDate = new Date(rotationStartDate).getDate() - 28;
    if (backDate < 1) {
      rankOfTheWeek = 'fourth';
    }
  }

  //Get the dropdownOptions =
  return [{ label: `Monthly on day ${date}`, value: `monthly on day ${date}` }];
};

const getCustomPeriodFrequencyDropdownOptions = (
  customPeriod: InputMaybe<ICustomPeriod> | undefined,
) => {
  const dropdownOptions =
    (customPeriod?.periodFrequency ?? 1) > 1
      ? rotationsCustomizePattern.customRepeat.selectOptions.interval.map(interval => ({
          ...interval,
          label: `${interval.label}s`,
        }))
      : [...rotationsCustomizePattern.customRepeat.selectOptions.interval];
  return dropdownOptions;
};

const getParticipantFrequencyDropdownOptions = (rotation: IRotation | undefined) => {
  const dropdownOptions =
    (rotation?.changeParticipantsFrequency ?? 1) > 1
      ? rotationsCustomizePattern.selectOptions.changeParticipants.map(interval => ({
          ...interval,
          label: `${interval.label}s`,
        }))
      : [...rotationsCustomizePattern.selectOptions.changeParticipants];
  return dropdownOptions;
};

const mapWeekdayFilterToSelection = (daysOfWeekFilter: Array<boolean> | undefined) => {
  const activeWeekDays: Array<DayOfWeek> = [];
  if (daysOfWeekFilter) {
    daysOfWeekFilter.forEach((value: boolean, index: number) => {
      value
        ? activeWeekDays.push(
            rotationsCustomizePattern.customRepeat.selectOptions.daysOfWeek[index],
          )
        : null;
    });
  }
  return activeWeekDays;
};

const getStartTimeDropDownValues = () => {
  let hours, minutes;
  const dropdownValues: Array<Record<string, any>> = [];
  for (let i = 0; i <= 1425; i += 15) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (hours < 10) {
      hours = '0' + hours; // adding leading zero
    }
    if (minutes < 10) {
      minutes = '0' + minutes; // adding leading zero
    }
    dropdownValues.push({
      value: `${hours}:${minutes}`,
      label: `${hours}:${minutes}`,
      duration: i,
    });
  }
  return dropdownValues;
};

const getFormatForDatePicker = (date: Date | undefined) => {
  if (date) {
    const todayPrefix = date.toDateString() === new Date().toDateString() ? 'Today, ' : '';
    return todayPrefix + DateTime.fromJSDate(new Date(date.toString())).toFormat('d LLL yy, (ccc)');
  } else {
    return 'dd/MM/yyyy';
  }
};

const getOnCallHoursValue = (interval: number | undefined) => {
  if (interval) {
    const Hours = (Math.floor(interval / 60) < 10 ? '0' : '') + Math.floor(interval / 60);
    const Minutes = (interval % 60 < 10 ? '0' : '') + (interval % 60);
    return { label: `${Hours}:${Minutes} hrs`, value: interval };
  }
  return { label: `00:00 hrs`, value: 0 };
};

const getEndTimeTimeStamp = (currentRotationStartTime: string, currentRotationInterval: number) => {
  const startTimeHours = Number.parseInt(currentRotationStartTime?.split(':')[0]);
  const startTimeMinutes = Number.parseInt(currentRotationStartTime?.split(':')[1]);
  let differenceInterval = 0;
  const baseDate = new Date();
  baseDate.setHours(startTimeHours);
  baseDate.setMinutes(startTimeMinutes);
  baseDate.setSeconds(0);
  baseDate.setMilliseconds(0);
  const startTimeInterval = startTimeHours * 60 + startTimeMinutes;
  if (startTimeInterval + currentRotationInterval > 1440) {
    differenceInterval = startTimeInterval + currentRotationInterval - 1440;
    baseDate.setHours(0);
    baseDate.setMinutes(0);
  }
  const targetDate = new Date(
    baseDate.getTime() +
      (differenceInterval === 0 ? currentRotationInterval : differenceInterval) * 60000,
  );
  const targetTimeStamp = [
    getPaddedValue(targetDate.getHours()),
    getPaddedValue(targetDate.getMinutes()),
  ].join(':');
  return targetTimeStamp;
};

const getEndDateFromStartDate = (date: Date, days: number) => {
  const endDate = new Date(date);
  endDate.setDate(date.getDate() + days);
  return endDate;
};

const reOrderParticipants = (sourceArray: any, startIndex: number, endIndex: number) => {
  const result = [...sourceArray];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getSlotDuration = (startTimeDuration: number, endTimeDuration: number) => {
  let duration = 0;
  let isSameDay = true;
  if (endTimeDuration < startTimeDuration) {
    duration = 1440 - startTimeDuration + endTimeDuration;
    isSameDay = false;
  } else if (endTimeDuration === startTimeDuration) {
    duration = 1440;
    isSameDay = false;
  } else {
    duration = endTimeDuration - startTimeDuration;
  }
  return { duration: duration, isSameDay: isSameDay };
};

const getDurationForTimeStamp = (timeStamp: string | undefined) => {
  if (!timeStamp) return 0;
  const hoursPart = timeStamp.split(':')[0];
  const minutesPart = timeStamp.split(':')[1];
  const hours = Number.parseInt(hoursPart);
  const minutes = Number.parseInt(minutesPart);
  return hours * 60 + minutes;
};

const sortTimeSlots = (slot1: ITimeSlot, slot2: ITimeSlot) => {
  const slot1StartTimeDuration = getDurationForTimeStamp(slot1.startTime);
  const slot2StartTimeDuration = getDurationForTimeStamp(slot2.startTime);
  return slot1StartTimeDuration - slot2StartTimeDuration;
};

const validateSlots = (inputSlots: ITimeSlot[] | undefined) => {
  if (!inputSlots) return { areSlotsValid: true, errorType: '' };

  const sortedSlotsArray = [...inputSlots].sort((slot1, slot2) => sortTimeSlots(slot1, slot2));

  //Validation 1 - Sum of all durations of all slots should not exceed 24 hours
  const sumOfAllDurations = sortedSlotsArray.reduce(
    (sum: number, option: any) => sum + option.duration,
    0,
  );
  if (sumOfAllDurations > 1440) return { areSlotsValid: false, errorType: 'over24Hours' };

  //Validation 2 - Slots should not overlap
  let slotsOverlap = false;
  sortedSlotsArray.forEach((slot: ITimeSlot, index: any) => {
    if (index > 0) {
      const currentSlotStartTime = sortedSlotsArray[index].startTime;
      const currentSlotStartTimeDuration = getDurationForTimeStamp(currentSlotStartTime);
      const currentSlotEndTime = sortedSlotsArray[index].endTime;
      const currentSlotEndTimeDuration = getDurationForTimeStamp(currentSlotEndTime);
      const previousSlotStartTime = sortedSlotsArray[index - 1].startTime;
      const previousSlotStartTimeDuration = getDurationForTimeStamp(previousSlotStartTime);
      const previousSlotEndTime = sortedSlotsArray[index - 1].endTime;
      const previousSlotEndTimeDuration = getDurationForTimeStamp(previousSlotEndTime);
      if (currentSlotStartTimeDuration < previousSlotEndTimeDuration) {
        //Slot 1: 00:00-00:30 and Slot 2: 00:15-00:30
        slotsOverlap = true;
      }
      if (
        currentSlotStartTimeDuration > previousSlotEndTimeDuration &&
        currentSlotEndTimeDuration > previousSlotStartTimeDuration &&
        !sortedSlotsArray[index].isSameDay
      ) {
        //Slot 1: 00:00 - 00:15 and Slot 2: 22:00 - 03:00
        slotsOverlap = true;
      }
    }
  });
  if (slotsOverlap) return { areSlotsValid: false, errorType: 'overlappingSlots' };

  //Validation 3 - Slots should have unique end times (Another check for overlapping slots)
  const allSlotEndTimes = sortedSlotsArray.map((slot: ITimeSlot) =>
    getDurationForTimeStamp(slot.endTime),
  );
  const uniqueSlotEndTimes = new Set(allSlotEndTimes);
  if (allSlotEndTimes.length !== uniqueSlotEndTimes.size)
    return { areSlotsValid: false, errorType: 'overlappingSlots' };

  return { areSlotsValid: true, errorType: '' };
};

const getUpdatedTimeSlot = (
  slot: ITimeSlot,
  selectedValue: any,
  timefield: 'startTime' | 'endTime',
) => {
  const updatedSlot = { ...slot };
  const dropdownValue = selectedValue.value;
  const hoursPart = dropdownValue.split(':')[0];
  const minutesPart = dropdownValue.split(':')[1];
  const hours = Number.parseInt(hoursPart);
  const minutes = Number.parseInt(minutesPart);
  const updatedTimeStamp = [getPaddedValue(hours), getPaddedValue(minutes)].join(':');
  if (timefield === 'startTime') {
    updatedSlot.startTime = updatedTimeStamp;
  } else {
    updatedSlot.endTime = updatedTimeStamp;
  }
  const startTimeDuration = getDurationForTimeStamp(updatedSlot.startTime);
  const endTimeDuration = getDurationForTimeStamp(updatedSlot.endTime);
  const { duration, isSameDay } = getSlotDuration(startTimeDuration, endTimeDuration);
  updatedSlot.duration = duration;
  updatedSlot.isSameDay = isSameDay;
  return updatedSlot;
};

export {
  useGetAllPatternParticipants,
  getCustomRepeatsText,
  getCustomTimeSlotsText,
  getRepeatMonthlyDropDownOptions,
  getCustomPeriodFrequencyDropdownOptions,
  getParticipantFrequencyDropdownOptions,
  mapWeekdayFilterToSelection,
  getStartTimeDropDownValues,
  getFormatForDatePicker,
  getOnCallHoursValue,
  getEndTimeTimeStamp,
  getEndDateFromStartDate,
  reOrderParticipants,
  getSlotDuration,
  validateSlots,
  getDurationForTimeStamp,
  sortTimeSlots,
  getUpdatedTimeSlot,
};
