import React, { useState, useMemo, useEffect } from 'react';
import {
  Input,
  Box,
  RadioGroup,
  Radio,
  ScaleFade,
  Button,
  HStack,
  Center,
  IconButton,
  useClipboard,
} from '@chakra-ui/react';
import { useScheduleActionsContext } from './context';
import {
  useCreateICalUrlMutation,
  useRefreshICalUrlMutation,
} from 'views/main/organization/schedules/graphql/mutation';
import { useGetICalLinksQuery } from 'views/main/organization/schedules/graphql/query';
import Loader from 'components/chakra/Loader';
import { Refresh } from 'icons';
import { useQueryClient } from 'react-query';
import { reactQueryConfig } from '../helpers/helpers.schedule';
import { AppTracker } from 'shared/analytics/tracker';
import {
  T_WA_UP_SCHEDULES_V2_EXPORTED,
  T_WA_UP_SCHEDULE_V2_EXPORT_REFRESH_LINK_CLICKED,
} from 'core/const/tracker';

const ExportSelection = () => {
  const [activeSelection, setActiveSelection] = useState('moc');
  const { scheduleAction, resetScheduleAction } = useScheduleActionsContext();

  const { mutateAsync: createLink, isLoading: isCreatingLink } = useCreateICalUrlMutation({
    ...reactQueryConfig.mutation.generateICalLink,
  });
  const { mutateAsync: refreshLink, isLoading: isRefreshingLink } = useRefreshICalUrlMutation({
    ...reactQueryConfig.mutation.refreshICalLink,
  });

  const scheduleID = scheduleAction?.params.scheduleId as number;
  const {
    data: { schedule } = {},
    isLoading,
    isSuccess,
  } = useGetICalLinksQuery({
    ID: scheduleID,
  });

  const scheduleInfo = useMemo(() => schedule, [schedule]);

  const calendarLink = useMemo(
    () =>
      activeSelection === 'moc'
        ? scheduleInfo?.iCalLinkForMyOncalls ?? ''
        : scheduleInfo?.iCalLink ?? '',
    [activeSelection, scheduleInfo],
  );
  const { hasCopied, onCopy } = useClipboard(calendarLink);
  const queryClient = useQueryClient();

  const DisplayOrGenerateLinks = useMemo(() => {
    return () => (
      <>
        {calendarLink && (
          <HStack w="100%" justifySelf="auto">
            <Input mt={2} disabled value={calendarLink} bgColor="gray.160" fontSize="sm" />
            <IconButton
              aria-label="refresh-link"
              variant="outline"
              isLoading={isRefreshingLink}
              icon={<Refresh />}
              onClick={async () => {
                try {
                  await refreshLink({
                    scheduleID: scheduleID,
                    myOncallOnly: activeSelection === 'moc',
                  });
                  AppTracker.track(T_WA_UP_SCHEDULE_V2_EXPORT_REFRESH_LINK_CLICKED);
                  queryClient.invalidateQueries(['getICalLinks']);
                } catch (error: any) {
                  console.log(error);
                }
              }}
              _hover={{ cursor: 'pointer' }}
            />
          </HStack>
        )}
        {!calendarLink && (
          <Button
            alignSelf="center"
            isLoading={isCreatingLink}
            onClick={async () => {
              try {
                await createLink({
                  scheduleID: scheduleID,
                  myOncallOnly: activeSelection === 'moc',
                });
                queryClient.invalidateQueries(['getICalLinks']);
              } catch (error: any) {
                console.log(error);
              }
            }}
          >
            Generate Link
          </Button>
        )}
      </>
    );
  }, [calendarLink, isCreatingLink, isRefreshingLink, activeSelection, scheduleID]);

  return (
    <Loader.Spinner
      isLoading={isLoading}
      loadingMessage="Loading the export options ..."
      centered
      spinnerProps={{ size: 'lg' }}
    >
      <ScaleFade initialScale={0.9} in={isSuccess}>
        <Box mt={5}>
          <RadioGroup
            name="changeSelection"
            value={activeSelection}
            onChange={value => {
              setActiveSelection(value);
            }}
          >
            <Radio value="moc" checked mr={5}>
              {' '}
              My On-Call Schedule
            </Radio>
            <Radio value="entire" checked>
              {' '}
              Entire Schedule
            </Radio>
          </RadioGroup>
          <Center mt={2}>
            <DisplayOrGenerateLinks />
          </Center>
          <HStack w="100%" mt={4} justifyContent="flex-end">
            <Button variant="invertedDefault" onClick={resetScheduleAction}>
              Cancel
            </Button>
            <Button
              variant="default"
              isLoading={isCreatingLink || isRefreshingLink}
              onClick={() => {
                onCopy();
                AppTracker.track(T_WA_UP_SCHEDULES_V2_EXPORTED);
              }}
              ml={3}
              isDisabled={!calendarLink}
            >
              {hasCopied ? 'Copied!' : 'Copy'}
            </Button>
          </HStack>
        </Box>
      </ScaleFade>
    </Loader.Spinner>
  );
};

export default ExportSelection;
