import {
  useCreateEntityMap,
  useGetOnCallUserSet,
  transformMessage,
} from 'library/atoms/MdBlock/helper';
import MdLink from 'library/atoms/MdBlock/MdLink';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import { IncidentNoteAction } from 'views/main/organization/workflow/types';

interface Props {
  action: IncidentNoteAction;
}

const IncidentNoteCard = ({ action }: Props) => {
  const entities = useCreateEntityMap();
  const onCallUsers = useGetOnCallUserSet();

  return (
    <div>
      <ReactMarkdown
        className="mde"
        plugins={[breaks, gfm]}
        renderers={{
          link: props => <MdLink {...props} entities={entities} onCallUsers={onCallUsers} />,
        }}
      >
        {transformMessage(action.data.note.replace(/ /g, '\u00A0'), entities)}
      </ReactMarkdown>
    </div>
  );
};

export default IncidentNoteCard;
