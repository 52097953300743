import { IRotation } from '../interface/schedule';
import {
  PeriodOptions,
  ChangeParticipantsInterval,
  CustomPeriodUnit,
  DayOfWeek,
} from 'views/main/organization/schedules/graphql/types';
import chakraUiTheme from '@chakra-ui/theme';

const hueIndex = 2;
export const schedulesOverrideColor = '#09325E';
export const schedulesOverrideBorderColor = '#09325E';
export const schedulesGapColor = chakraUiTheme.colors.red[400];
export const schedulesColorHues = ['4D', '80', 'B3', 'E6', 'F9'];
export const schedulesColorPalette = [
  { value: `#D4ECF9${schedulesColorHues[hueIndex]}`, label: 'Blue' },
  { value: `#FFD9C7${schedulesColorHues[hueIndex]}`, label: 'Orange' },
  { value: `#D4D4F9${schedulesColorHues[hueIndex]}`, label: 'Purple' },
  { value: `#DBF6CC${schedulesColorHues[hueIndex]}`, label: 'Green' },
  { value: `#FFF6BF${schedulesColorHues[hueIndex]}`, label: 'Yellow' },
];

export const onCallCoverageColor = schedulesColorPalette[0].value;

export const onCallCoverageRowID = 'on-call-coverage';
export const onCallCoverageRowName = 'On-Call Coverage';

const colorIndex = Math.floor(Math.random() * schedulesColorPalette.length);
const rotationBackgroundColor = `${schedulesColorPalette[colorIndex].value}`;

export const customRotationSlug = 'custom-rotation-';

export const blankRotationTemplate: IRotation = {
  id: '',
  name: '',
  color: rotationBackgroundColor,
  scheduleId: '',
  participantGroups: [],
  startDate: new Date(),
  period: PeriodOptions.None,
  changeParticipantsFrequency: 1,
  changeParticipantsUnit: ChangeParticipantsInterval.Rotation,
  shiftTimeSlot: {
    startTime: '00:00',
    duration: 24 * 60,
  },
  ends: false,
};

export const rotationTemplates: IRotation[] = [
  {
    id: 'daily24x7',
    name: 'Daily 24 x 7',
    color: rotationBackgroundColor,
    scheduleId: '',
    participantGroups: [],
    startDate: new Date(),
    period: PeriodOptions.Daily,
    changeParticipantsFrequency: 1,
    changeParticipantsUnit: ChangeParticipantsInterval.Rotation,
    shiftTimeSlot: {
      startTime: '00:00',
      duration: 24 * 60,
    },
    ends: false,
    showOnCalendar: true,
  },
  {
    id: 'daily-business-hours',
    name: 'Daily Business Hours',
    color: rotationBackgroundColor,
    scheduleId: '',
    participantGroups: [],
    startDate: new Date(),
    period: PeriodOptions.Daily,
    changeParticipantsFrequency: 1,
    changeParticipantsUnit: ChangeParticipantsInterval.Rotation,
    shiftTimeSlot: {
      startTime: '09:00',
      duration: 8 * 60,
    },
    ends: false,
    showOnCalendar: true,
  },
  {
    id: 'daily-non-business-hours',
    name: 'Daily Non - Business Hours',
    color: rotationBackgroundColor,
    scheduleId: '',
    participantGroups: [],
    startDate: new Date(),
    period: PeriodOptions.Daily,
    changeParticipantsFrequency: 1,
    changeParticipantsUnit: ChangeParticipantsInterval.Rotation,
    shiftTimeSlot: {
      startTime: '17:00',
      duration: 7 * 60,
    },
    ends: false,
    showOnCalendar: true,
  },
  {
    id: 'weekly-business-hours',
    name: 'Weekly Business Hours',
    color: rotationBackgroundColor,
    scheduleId: '',
    participantGroups: [],
    startDate: new Date(),
    period: PeriodOptions.Custom,
    changeParticipantsFrequency: 1,
    changeParticipantsUnit: ChangeParticipantsInterval.Week,
    showOnCalendar: true,
    shiftTimeSlot: {
      startTime: '09:00',
      duration: 8 * 60,
    },
    customPeriod: {
      periodUnit: CustomPeriodUnit.Week,
      periodFrequency: 1,
      repeatHoursAndTime: false,
      daysOfWeekFilter: [false, true, true, true, true, true, false],
      timeSlots: [
        {
          startTime: '09:00',
          duration: 8 * 60,
          endTime: '17:00',
          isSameDay: true,
          dayOfWeek: DayOfWeek.Monday,
          id: 1,
        },
        {
          startTime: '09:00',
          duration: 8 * 60,
          endTime: '17:00',
          isSameDay: true,
          dayOfWeek: DayOfWeek.Tuesday,
          id: 2,
        },
        {
          startTime: '09:00',
          duration: 8 * 60,
          endTime: '17:00',
          isSameDay: true,
          dayOfWeek: DayOfWeek.Wednesday,
          id: 3,
        },
        {
          startTime: '09:00',
          duration: 8 * 60,
          endTime: '17:00',
          isSameDay: true,
          dayOfWeek: DayOfWeek.Thursday,
          id: 4,
        },
        {
          startTime: '09:00',
          duration: 8 * 60,
          endTime: '17:00',
          isSameDay: true,
          dayOfWeek: DayOfWeek.Friday,
          id: 5,
        },
      ],
    },
    ends: false,
  },
];
