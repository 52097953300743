import { createStandaloneToast } from '@chakra-ui/react';

const toast = createStandaloneToast();

export const reactQueryConfigError = (operationType: string) => (err: unknown) => {
  const error = err as any;
  const title = `The operation ${operationType} could not be completed`;

  toast.closeAll();
  toast({
    title,
    status: 'error',
    variant: 'subtle',
    isClosable: true,
    description: error.message,
    containerStyle: {
      fontSize: 14,
      lineHeight: 10,
    },
  });
};

export const reactQueryConfigSuccess = (message: string) => {
  toast({
    title: message,
    status: 'success',
    duration: 5000,
    isClosable: true,
  });
};
