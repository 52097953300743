import { useSecondaryFilters } from 'components/SecondaryFilter/context';
import { SecondaryFiltersType } from 'components/SecondaryFilter/types';
import { IAppState } from 'core/interfaces/IAppState';
import { ITeam } from 'core/interfaces/ITeams';
import qs from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { UserBelongsToNoTeamsAndNoOrgLevelPermissions } from '../';
import { useFilters } from '../Filters/Filters.context';
import Loader from '../Loader/Loader';
import Page from '../Page/Page';
import { panels } from './panels';
import {
  T_WA_UP_ADD_FILTERS_IN_ANALYTICS_PAGE_V2_USED,
  T_WA_UP_TEAM_LEVEL_ANALYTICS_VIEWED,
} from '../../../../../core/const/tracker';
import { AppTracker } from '../../../../../shared/analytics/tracker';

interface TeamAnalyticsProps {
  analyticsTeamList: { id: string; name: string }[];
}

const TeamAnalytics: React.FC<TeamAnalyticsProps> = ({ analyticsTeamList }) => {
  const queryParams = useLocation().search;
  const { filters, updateFilter, updateActiveFilters } = useSecondaryFilters();
  const { filters: cubeFilters, updateCompareFilter } = useFilters();
  const teams = useSelector(
    (state: IAppState) => state.organization.teams.t as ITeam[],
    shallowEqual,
  );

  const [selectedTeam] = filters.teams || [];
  const selectedTeamDetail = useMemo(() => teams.find(t => t.id === selectedTeam), [selectedTeam]);

  const urlFilters = useMemo(() => {
    const parseparams = qs.parse(queryParams);
    const parsefilters = parseparams.filters;

    let urlfilters: SecondaryFiltersType = {};

    try {
      if (Array.isArray(parsefilters)) {
        urlfilters = JSON.parse(parsefilters[0]);
      } else {
        urlfilters = JSON.parse(parsefilters!);
      }
    } catch (error) {
      console.error(error);
    }

    return urlfilters;
  }, [queryParams]);

  const hasAnalyticsEnabled = useMemo(
    () => analyticsTeamList.find(team => urlFilters.teams?.includes(team.id)),
    [analyticsTeamList, urlFilters.teams],
  );
  const isSelectedTeamInFilter = useMemo(
    () => cubeFilters.teamIds.find(team => !!filters.teams?.includes(team)),
    [cubeFilters.teamIds, filters.teams],
  );

  useEffect(() => {
    // Check if the team in url is not in filters list (When redirecting from one team to another)
    const noUrlTeamInFilter = !urlFilters.teams?.find(team => filters.teams?.includes(team));
    AppTracker.track(T_WA_UP_TEAM_LEVEL_ANALYTICS_VIEWED);

    // If no team filter
    if (!urlFilters.teams?.length) {
      const defaultTeam = teams.find(team => team.default)?.id;
      if (defaultTeam) {
        updateFilter('teams', [defaultTeam]);
        updateActiveFilters('teams');
      }
    } else if (noUrlTeamInFilter && hasAnalyticsEnabled) {
      // Reset all cached filters
      updateFilter('services', []);
      updateFilter('users', []);
      updateFilter('tags', []);
      updateFilter('priority', []);
      updateFilter('serviceOwner', []);
      updateFilter('squads', []);
      updateFilter('teams', urlFilters.teams);
      updateCompareFilter([]);
    }
  }, [urlFilters, teams]);

  return hasAnalyticsEnabled ? (
    isSelectedTeamInFilter ? (
      <Page
        title={selectedTeamDetail ? `Analytics: ${selectedTeamDetail?.name}` : 'Team Analytics'}
        panels={panels}
        isTeamAnalytics={true}
      />
    ) : (
      <Loader />
    )
  ) : (
    <UserBelongsToNoTeamsAndNoOrgLevelPermissions />
  );
};

export default TeamAnalytics;
