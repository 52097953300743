import React, { useMemo } from 'react';
import { IUserProfile } from 'core/interfaces/IUserData';
import { DateTime } from 'luxon';
import { useGetSchedulesForUserProfileQuery } from '../../graphql/query';
import { API } from 'core';
import { defaultReactQueryConfig, getOverridesAsRotation } from '../../helpers/helpers.schedule';
import { getDateInterval, getUTCDateTime } from '../../helpers/helpers.date';
import { IListEvent } from '../../schedules.view/schedules.list/listView.table';
import { ScheduleEvent } from '../../graphql/types';
import { ICalendarEventResponse } from 'core/interfaces/ICalendar';
import { splitEventsForTheListView } from '../../helpers/helpers.event';

export const useGetUserShiftsForProfile = (
  user: IUserProfile,
  date: { fromDate: string; toDate: string },
) => {
  const timezone = user.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Setting default values for the date range
  const startDate = date.fromDate ? new Date(date.fromDate) : new Date();
  const endDate = date.toDate ? new Date(date.toDate) : startDate;

  const eventsFilter = useMemo(
    () => ({
      startTime: DateTime.fromJSDate(startDate).setZone(timezone).startOf('day').toUTC().toISO(),
      endTime: DateTime.fromJSDate(endDate).setZone(timezone).endOf('day').toUTC().toISO(),
    }),
    [startDate, endDate, timezone],
  );

  // Get a date interval based on input data
  const dateInterval = useMemo(
    () => getDateInterval(startDate.toISOString(), endDate.toISOString(), timezone),
    [startDate, endDate, timezone],
  );

  // Fetch schedules
  const {
    data: scheduleList,
    isLoading,
    isFetching,
  } = useGetSchedulesForUserProfileQuery(
    {
      filters: {
        teamID: API.config.teamId,
        participants: [user.id],
      },
      from: eventsFilter.startTime,
      till: eventsFilter.endTime,
      eventsFilter,
      overrideFilters: {
        participantID: user.id,
      },
    },
    defaultReactQueryConfig,
  );

  // Spinner to show loading state
  const showSpinner = isLoading || isFetching;

  const newSchedulesData = scheduleList?.schedules || [];

  // Flatten the rotations and overrides from the schedules data
  const userEventsFromNewSchedules = (
    (newSchedulesData.flatMap(schedule =>
      [
        ...(schedule.rotations ?? []),
        {
          ...getOverridesAsRotation(schedule.ID, schedule.name, timezone),
          events:
            schedule.overrides?.map(o => ({
              ID: o.ID,
              startTime: o.startTime,
              endTime: o.endTime,
              isOverride: true,
              overrideID: o.ID,
              participants: o.overrideWith?.participants ?? [],
              override: o,
            })) ?? [],
        },
      ].flatMap(rotation => [
        ...(rotation.events?.map(event => {
          return {
            ...rotation,
            ...event,
            scheduleID: schedule.ID,
            rotationName: rotation.name,
            scheduleName: schedule.name,
            scheduleTimeZone: timezone,
          };
        }) ?? []),
      ]),
    ) ?? []) as IListEvent[]
  ).filter(event =>
    // Filter only those events which the user is a part of
    event.isOverride
      ? event.override &&
        (event.override.overrideWith?.participants?.findIndex(p => p?.ID === user.id) ?? 0) > -1
      : event.participants && event.participants.findIndex(p => p.ID === user.id) > -1,
  );

  const userOverrideEvents = userEventsFromNewSchedules.filter(e => !!e.isOverride);

  const filteredEvents = userEventsFromNewSchedules.filter(event => {
    if (!event.isOverride) {
      const startDT = getUTCDateTime((event as unknown as ScheduleEvent).startTime, timezone);
      const endDT = getUTCDateTime((event as unknown as ScheduleEvent).endTime, timezone);
      const overlappedByOverride = !!userOverrideEvents.find(
        e =>
          getUTCDateTime(e.startTime, timezone) <= startDT &&
          endDT <= getUTCDateTime(e.endTime, timezone) &&
          e.scheduleID === event.scheduleID,
      );
      return !overlappedByOverride;
    }
    return true; // If it is an override, include it
  });

  const newSchedulesEvents = {} as Record<string, ICalendarEventResponse[]>;

  // Convert the data in a suitable format to be displayed on the user profile
  dateInterval.forEach(dt => {
    const dtISOString = dt.toJSDate().toISOString();
    const {
      eventsBelongingToToday,
      eventsStartedBeforeEndingToday,
      eventsStartedTodayEndingLater,
    } = splitEventsForTheListView(filteredEvents, dt, timezone);

    newSchedulesEvents[dtISOString] = [
      ...eventsStartedBeforeEndingToday,
      ...eventsBelongingToToday,
      ...eventsStartedTodayEndingLater,
    ].map(event => ({
      id: `${event.ID}`,
      schedule_id: `${event.scheduleID}`,
      date_to_be_shown: dt.toJSDate(),
      calendar_id: `${event.scheduleID}`,
      start_time: event.startTime,
      end_time: event.endTime,
      name: event.rotationName,
      user_ids: [user.id],
      series_id: '',
      squad_ids: [],
      is_override: !!event.isOverride,
      calendar_name: event.scheduleName,
      calendar_color: event.color ?? '',
    }));
  });

  return { showSpinner, newSchedulesEvents };
};
