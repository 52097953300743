import React from 'react';
import { ErrorIcon } from '../../../../../../icons';
import { Grid, Para } from 'uie/components';
interface IProps {
  text: string;
  showErrorIcon: boolean;
}
const PendingVerification = (props: IProps) => {
  return (
    <Grid
      style={{
        color: '#E05000',
        backgroundColor: '#FFF6BF',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 6px 0px 6px',
        borderRadius: '3px',
        width: 'fit-content',
      }}
    >
      {props.showErrorIcon && <ErrorIcon />}
      <Para fontSize={12} fontWeight={400} color="#E05000">
        {props.text}
      </Para>
    </Grid>
  );
};

export default PendingVerification;
