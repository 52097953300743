import { Grid, Heading, Para, TextButton, Theme } from 'uie/components';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { requestUserLogout } from '../../../core/actions';
import { IAppState } from '../../../core/interfaces/IAppState';

const { theme } = Theme;

interface IProps extends Pick<IAppState, 'userInfo' | 'auth' | 'userOrganizations'> {
  requestUserLogout: () => Record<string, any>;
}

class Mobile extends Component<IProps> {
  openApp = () => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window._sq_env?.VITE_ENV === 'eu-prod') {
        window.open(`sqdapp://app.eu.squadcast.com?session_token=${this.props.auth.sessionId}`);
      } else {
        window.open(`sqdapp://app.squadcast.com?session_token=${this.props.auth.sessionId}`);
      }
    }, 0);
  };

  render() {
    let isNewUser = false;
    if (
      (this.props.userInfo.d && this.props.userInfo.d!.contact.dial_code.length === 0) ||
      this.props.userOrganizations.o.length === 0 ||
      this.props.userOrganizations.o.every(o => o.deActivated)
    ) {
      isNewUser = true;
    }

    return (
      <Grid height="100%" type="column" justifyContent="center">
        <Grid
          type="column"
          alignItems="flex-start"
          height="100%"
          style={{
            padding: 32,
            marginTop: 150,
          }}
        >
          <img width={100} src="/assets/new_logo.svg" alt="logo" />
          <Heading fontSize={24}>
            Hello {this.props.userInfo.d?.first_name} {this.props.userInfo.d?.last_name},{' '}
          </Heading>
          <Para>
            {isNewUser
              ? 'To experience Squadcast with its full suite of capabilities, please finish initial set-up on our Web app.'
              : 'Click on the link to open the Mobile App.'}
          </Para>

          {isNewUser && (
            <>
              <Para fontWeight={500} className="mt-20">
                Step 1
              </Para>
              <Para>Get onboarded on our platform through our WebApp on any desktop browser.</Para>

              <Para fontWeight={500} className="mt-20">
                Step 2
              </Para>
              <Para>
                Once you are done with the initial set-up on our Web app, download our Mobile App to
                manage incidents on the go.
              </Para>
            </>
          )}

          {!isNewUser && (
            <TextButton onClick={this.openApp} style={{ marginTop: 20 }}>
              <Para color={theme.shades.white} fontWeight={400}>
                Open in mobile app
              </Para>
            </TextButton>
          )}

          <div style={{ marginTop: 50 }}>
            {!isNewUser && (
              <Para fontSize={16}>
                If you are yet to download our Mobile App, you can try it out now.
              </Para>
            )}
            <Grid alignItems="flex-start" type="column" style={{ marginTop: 20 }}>
              <a
                href="https://apps.apple.com/us/app/squadcast-incident-management/id1501689101"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://uploads-ssl.webflow.com/5c51758c58939b30a6fd3d73/5c5edd7ad885ee423ecf1599_app_store.svg"
                  alt="app store"
                  style={{ width: 135 }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.squadcast.incidents&hl=en"
                style={{ marginTop: 10, marginLeft: -10 }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://uploads-ssl.webflow.com/5c51758c58939b30a6fd3d73/5c5edd4ed885ee2d7ccf157e_google_play.png"
                  alt="google play"
                  style={{ width: 165 }}
                />
              </a>
            </Grid>
          </div>
          <TextButton
            color={theme.shades.white}
            onClick={this.props.requestUserLogout}
            style={{ marginTop: 20 }}
          >
            <Para color={theme.shades.grey} fontWeight={400}>
              Sign out
            </Para>
          </TextButton>
        </Grid>
      </Grid>
    );
  }
}

export default connect(
  (state: IAppState) => ({
    userInfo: state.userInfo,
    auth: state.auth,
    userOrganizations: state.userOrganizations,
  }),
  {
    requestUserLogout,
  },
)(Mobile);
