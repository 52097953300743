import Axios from 'axios';
import { API } from 'core';
import { QUERY_CONFIG } from 'library/query-config';
import { QueryClient, useQuery } from 'react-query';

import { QUERY_KEY } from './queryKeys';
import { IAutoGroupAlertDetail } from 'core/interfaces/IIncidents';

const getDetails = (incidentId: string, alertId: string) => {
  return Axios.get(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${incidentId}/alerts/${alertId}`,
  );
};

export const useGetAGAlertDetails = (incidentId: string, alertId: string) => {
  const { data, isSuccess, isError, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEY.AUTO_GROUPED_ALERT_DETAIL, incidentId, alertId],
    queryFn: () => getDetails(incidentId, alertId),
    ...QUERY_CONFIG,
  });
  const response = data?.data ?? { data: {} };
  return {
    alert: response.data as IAutoGroupAlertDetail,
    isSuccess,
    isError,
    isLoading,
    isFetching,
  };
};

export const invalidateAGAlertDetails = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(QUERY_KEY.AUTO_GROUPED_ALERT_DETAIL);
};
