import request from '../commons';
import { API } from '../../api';

function getOrgRunbooks(entityOwner?: string) {
  const req = request.get(API.config.batman)(
    `organizations/${API.config.organizationId}/runbooks?owner_id=${API.config.teamId}${
      entityOwner !== '' ? `&entity_owner=${entityOwner}` : ''
    }`,
  );
  return req;
}

export { getOrgRunbooks };
