import { useMutation, useQueryClient, useQuery, useQueries } from 'react-query';
import axios from 'axios';
import { API } from '../../../../../core/api';
import { queryKeys } from '../contants/service.contant';
import { useHistory } from 'react-router-dom';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/helper.service';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_UP_UPDATE_BULK_MAINTENANCE_SERVICES } from '../../../../../core/const/tracker';
import { T_WA_GS_SERVICE_MAINTENANCE } from '../../../../../core/const/tracker';

import { usePersistQuery } from './usePersistQuery';

const updateMentainance = async (serviceId: string, payload: any) => {
  const data = axios.post(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/${serviceId}/maintenance`,
    payload,
  );
  return data;
};

export function useMentainance() {
  const queryClient = useQueryClient();
  const persistQuery = usePersistQuery();
  const navigateOnSuccess = () => {
    setTimeout(() => {
      persistQuery();
    }, 1000);
  };

  const { mutate: updateMentainancefn } = useMutation(
    (mentainance: { serviceId: string; payload: any }) =>
      updateMentainance(mentainance.serviceId, mentainance.payload),
    {
      onError: reactQueryConfigError,
      onSuccess: data => {
        reactQueryConfigSuccess('Maintenance window updated');
        AppTracker.track(T_WA_GS_SERVICE_MAINTENANCE);
        navigateOnSuccess();
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.SERVICELIST);
        // queryClient.invalidateQueries(queryKeys.SLOVIOLATING);
      },
    },
  );

  return updateMentainancefn;
}

export interface IMaintenace {
  deleted: boolean;
  maintenanceFrom: string;
  maintenanceTill: string;
  repeatTill: string;
  repetitionDaily: boolean;
  repetitionMonthly: boolean;
  repetitionThreeWeekly: boolean;
  repetitionTwoWeekly: boolean;
  repetitionWeekly: boolean;
}

const getMaintenance = async (serviceId: string) => {
  const { data } = await axios.get<{ data: IMaintenace[] }>(
    `${API.config.endpoint}/organizations/${API.config.organizationId}/services/${serviceId}/maintenance`,
  );
  return data.data;
};

/**
 * Get Maintenance of multiple services
 * @param serviceIds Service Id List
 * @param teamId Team Id
 * @returns Maintenance of multiple services
 */
export const useMultiServiceMaintenanceQuery = (serviceIds: string[]) => {
  return useQueries(
    serviceIds.map(service => ({
      queryKey: ['maintenance', service],
      queryFn: () => getMaintenance(service),
      select: (res: IMaintenace[]) => ({ data: res, service: service }),
      enabled: !!service,
      retry: 1,
      staleTime: 30 * 1000,
    })),
  );
};

const updateMentainanceBulk = async (serviceId: Array<string>, payload: any) => {
  const data = axios.post(
    `${API.config.batman}/organizations/${API.config.organizationId}/services/maintenance`,
    {
      serviceIds: serviceId,
      serviceMaintenance: payload.data.serviceMaintenance,
      organizationId: payload.organizationId,
    },
  );
  return data;
};

export function useMentainanceBulk() {
  const queryClient = useQueryClient();
  const history = useHistory();

  const navigateOnSuccess = () => {
    setTimeout(() => {
      history.push('/service-catalog');
    }, 1000);
  };

  const { mutate: updateMentainanceBulkfn } = useMutation(
    (mentainance: { serviceIds: Array<string>; payload: any }) =>
      updateMentainanceBulk(mentainance.serviceIds, mentainance.payload),
    {
      onError: reactQueryConfigError,
      onSuccess: data => {
        reactQueryConfigSuccess('Maintenance window updated');
        AppTracker.track(T_WA_UP_UPDATE_BULK_MAINTENANCE_SERVICES);
        navigateOnSuccess();
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.SERVICELIST);
        // queryClient.invalidateQueries(queryKeys.SLOVIOLATING);
      },
    },
  );

  return updateMentainanceBulkfn;
}
