import { useEffect } from 'react';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { useFormContext } from 'react-hook-form';
import { MSTeamMessageChannelAction } from 'views/main/organization/workflow/types';
import { FormFooter } from '../../squadcast/form-footer';
import { messageTeamChannelValidation } from 'views/main/organization/workflow/constant/validation';
import MessageTeamChannelForm from './MessageTeamChannelForm';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import DrawerFooterWrapper from 'library/molecules/Drawer';

const MessageTeamsChannelContainer = () => {
  const { actionForm, onActionSubmit, checkDirtyAndCloseDrawer, setIsDirty } =
    useWorkflowFormContext();
  const { formState } = useFormContext<MSTeamMessageChannelAction>();

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <FormWrapper<MSTeamMessageChannelAction>
      onSubmit={onActionSubmit}
      defaultValues={actionForm! as MSTeamMessageChannelAction}
      validationSchema={messageTeamChannelValidation}
    >
      <MessageTeamChannelForm />
      <DrawerFooterWrapper>
        <FormFooter<MSTeamMessageChannelAction>
          checkDirtyAndCloseDrawer={checkDirtyAndCloseDrawer}
        />
      </DrawerFooterWrapper>
    </FormWrapper>
  );
};
export default MessageTeamsChannelContainer;
