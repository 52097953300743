import { HStack, Wrap, WrapItem, Text } from '@chakra-ui/react';
import { Tag } from 'library/atoms';
import React from 'react';
import { truncate } from '../../helpers';

function TagStack(props: { tags: any; onClose: any; showLabel: boolean }) {
  const { tags, onClose, showLabel } = props;
  return (
    <HStack alignSelf="start" sx={{ '& *': { fontSize: 10.5 } }}>
      {tags.length ? (
        <Wrap mt={3}>
          {tags.map(
            (
              {
                value,
                label,
                username,
              }: {
                value: string;
                label: string;
                username?: string | null;
              },
              index: number,
            ) => {
              return (
                <WrapItem key={value.toString()}>
                  <Tag
                    size="md"
                    variant="filter"
                    label={label}
                    tooltipText={showLabel ? label : value}
                    title={
                      value ? (
                        <HStack>
                          <Text fontWeight={'bold'}>
                            {showLabel ? truncate(label) : truncate(value)}
                          </Text>
                        </HStack>
                      ) : (
                        <Text>{label}</Text>
                      )
                    }
                    onClose={() => {
                      onClose({
                        value,
                        label,
                        username,
                      });
                    }}
                  />
                </WrapItem>
              );
            },
          )}
        </Wrap>
      ) : null}
    </HStack>
  );
}

export default TagStack;
