import { Grid, Heading, Para, SpinLoader, TextButton, Theme } from 'uie/components';
import * as React from 'react';
import { eventHelpers } from 'core/helpers/events';

interface IProps {
  hide: (result: boolean) => void;
  buttonText: string;
  cancelText?: string;
  displayText: React.ReactNode | string;
  primaryButtonColor?: string;
  isBusy?: boolean;
  hideCancelBtn?: boolean;
  header?: string;
}

class ConfirmModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp, false);
  }

  public componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp, false);
  }

  handleKeyUp(e: any) {
    if (e.keyCode === 27) {
      this.props.hide(false);
    }
  }
  public render() {
    const { theme } = Theme;
    const {
      buttonText,
      displayText,
      cancelText,
      isBusy,
      hide,
      primaryButtonColor,
      hideCancelBtn: hideCancelBtn,
      header: confirmText,
    } = this.props;

    return (
      <div onClick={eventHelpers.stopPropagation}>
        <Heading fontSize={24} fontWeight={400} height={32}>
          {confirmText || 'Confirmation'}
        </Heading>
        <Para className="mt-10">{displayText}</Para>
        <Grid style={{ padding: 0 }} className="mt-20">
          <TextButton
            color={primaryButtonColor || theme.primary.default}
            onClick={hide.bind(null, true)}
            disabled={isBusy}
            height="28px"
          >
            <Grid alignItems="center">
              <Para color={theme.shades.white} fontWeight={500}>
                {buttonText}
              </Para>
              {isBusy && <SpinLoader className="ml-5" color={theme.shades.white} base="8px" />}
            </Grid>
          </TextButton>

          {!hideCancelBtn ? (
            <TextButton
              buttonType="ghost"
              className="ml-10"
              onClick={hide.bind(null, false)}
              disabled={isBusy}
              height="28px"
            >
              <Para color={theme.shades.black}>{cancelText || 'Cancel'}</Para>
            </TextButton>
          ) : (
            ''
          )}
        </Grid>
      </div>
    );
  }
}
export default ConfirmModal;
