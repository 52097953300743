import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { IComponentState } from 'core/interfaces/IComponentState';
import { deepCopy, pick } from 'core/helpers';
import {
  allOperators,
  booleanComparators,
  numberComparators,
  stringComparators,
} from 'core/const/comparators';

import { BillingService, ServiceService, TaggingRulesService } from 'core/services';
import render from './render.index';
import { IAlertSource } from 'core/interfaces/IIntegration';
import equal from 'fast-deep-equal/es6/react';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_GS_SERVICE_TAGGING } from 'core/const/tracker';
import { AutomationRuleContext, AutomationRuleContextProps } from '../context';
import { RoutingRules } from '..';
import {
  IRuleExpressionOperation,
  ITaggingRule,
} from 'views/main/organization/service-catalog/Interfaces/automation-rule';
import {
  generateExpression,
  getLHSType,
} from 'views/main/organization/service-catalog/helpers/helper.automation-rule';

interface IIndexedTagRule extends ITaggingRule {
  id: number;
  rule_id: string;
  existing: boolean;
}

interface IProps extends Pick<IAppState, 'organization' | 'integrations'> {
  serviceId: string;
  hide: () => void;
  checkAndSetDirty: () => void;
  setIsDirty: (isDirty: boolean) => void;
  refetchRule: () => void;
  editRuleId?: string;
  rules?: ITaggingRule[];
}

interface IState {
  componentState: IComponentState;
  rules: IIndexedTagRule[];
  saveState: 'idle' | 'saving' | 'saved' | 'error';
  networkError: '';
  eventState: 'loading' | 'noEvents' | 'idle' | 'default';
  event: any;
  errors: string[];
  alertSourceSearch: string;
  alertSource: IAlertSource | null;
  alertSourcesLoadState: 'loading' | 'success' | 'error';
  alertSourceErrorMessage: string;
  searchString: { [key: string]: string };
  warningIndex: number | null;
  globalSearch: string;
  showUpgradeModal: boolean;
  planTaggingRulesLimitExceeded: boolean;
  orgTaggingRulesCount: number;
  existingDeleted: number;
  editRuleId: string | undefined;
}

export class TaggingRulesModal extends Component<IProps, IState, AutomationRuleContextProps> {
  static contextType = AutomationRuleContext;
  context!: AutomationRuleContextProps;

  private ServiceService = new ServiceService(this.props.serviceId);
  private TaggingRulesService = new TaggingRulesService(this.props.serviceId);

  public _service = this.props.organization.services.s.find(s => s.id === this.props.serviceId);

  public alertSources: IAlertSource[] = this.props.integrations.i
    .filter(as => !as.hideAutomation && !as.deprecated)
    .map(as => ({
      isActive: false,
      ...as,
    }));

  public _comparators: any = {
    string: stringComparators,
    number: numberComparators,
    boolean: booleanComparators,
    all: allOperators,
  };

  private initialState?: IState;

  constructor(props: IProps) {
    super(props);
    this.state = {
      componentState: 'busy',
      rules: [],
      eventState: 'default',
      event: {},
      saveState: 'idle',
      errors: [],
      networkError: '',
      alertSourceSearch: '',
      alertSource: null,
      alertSourcesLoadState: 'loading',
      alertSourceErrorMessage: '',
      searchString: {},
      warningIndex: null,
      globalSearch: '',
      showUpgradeModal: false,
      planTaggingRulesLimitExceeded: false,
      orgTaggingRulesCount: 0,
      existingDeleted: 0,
      editRuleId: props.editRuleId,
    };
  }

  public render = render;

  public async componentDidMount() {
    this.getActiveAlertSources();
    await this.getTaggingRules();
    this.updateOrgTaggingRulesCount();

    if (!this.props.editRuleId) {
      this.checkPlanLimitsAndCreateNewTaggingRule();
    }
  }

  public updatePlanTaggingRulesLimitExceeded() {
    this.setState({
      planTaggingRulesLimitExceeded: BillingService.isLimitExceeded(
        this.props,
        'tagging-rules',
        () =>
          this.state.orgTaggingRulesCount +
          this.state.rules.filter(r => r.existing === false).length -
          this.state.existingDeleted,
      ),
    });
  }

  updateOrgTaggingRulesCount = async () => {
    try {
      const {
        data: {
          data: { count: orgCount },
        },
      } = await this.TaggingRulesService.getOrgCount();
      await this.setState({ orgTaggingRulesCount: orgCount });
      this.updatePlanTaggingRulesLimitExceeded();
    } catch (err: any) {
      console.log(err);
    }
  };

  checkPlanLimitsAndCreateNewTaggingRule = async () => {
    if (this.state.planTaggingRulesLimitExceeded) {
      this.setState({ showUpgradeModal: true });
    } else {
      await this.addNewRule();
      this.updateOrgTaggingRulesCount();
    }
  };

  public getActiveAlertSources = async () => {
    try {
      const { data } = await this.ServiceService.getActiveAlertSources();
      const activeAlertSources = data.data as string[];

      this.alertSources.forEach((as: IAlertSource) => {
        if (activeAlertSources.includes(as._id)) {
          as.isActive = true;
        }
      });

      // sorting according to type
      this.alertSources.sort((as1: IAlertSource, as2: IAlertSource) => {
        const firstAlertSourceName = as1.type ?? '';
        const secondAlertSourceName = as2.type ?? '';
        if (!firstAlertSourceName || !secondAlertSourceName) return 0;
        if (firstAlertSourceName.toLowerCase() < secondAlertSourceName.toLowerCase()) {
          return -1;
        }
        if (firstAlertSourceName.toLowerCase() > secondAlertSourceName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      // sorting according to active status
      this.alertSources.sort((as1: IAlertSource, as2: IAlertSource) => {
        if (as1.isActive && !as2.isActive) {
          return -1;
        }
        if (!as1.isActive && as2.isActive) {
          return 1;
        }
        return 0;
      });
      const as = this.alertSources.filter((a: IAlertSource) => a.isActive);
      if (as.length === 1) {
        this.setState({ alertSourcesLoadState: 'success' });
        this.getAlertSourceConfig(as[0]);
        return;
      }
      this.setState({ alertSourcesLoadState: 'success' });
    } catch (err: any) {
      this.setState({
        alertSourceErrorMessage: `Network Error: ${
          err?.response?.data?.meta?.error_message ?? 'Network Error'
        }`,
        alertSourcesLoadState: 'error',
      });
    }
  };

  public getAlertSourceConfig = async (alertSource: IAlertSource) => {
    this.setState({
      alertSource,
      eventState: 'loading',
    });
    try {
      const eventResponse = await this.ServiceService.getAlertSourceLatestEvent(alertSource._id);
      this.setState({
        eventState: 'idle',
        event: {
          payload: eventResponse.data.data.payload,
          source: alertSource.shortName,
        },
      });
    } catch (err: any) {
      this.setState({
        eventState: 'noEvents',
        event: {},
      });
    }
  };

  public getTaggingRules = async () => {
    this.setState({ componentState: 'busy' });
    try {
      const isRulesEmpty = this.props.rules?.length === 0;

      this.setState(
        {
          rules: isRulesEmpty
            ? []
            : this.props.rules?.map(
                (r: any, id: number): IIndexedTagRule => ({
                  id,
                  rule_id: r.rule_id,
                  expression: r.expression || '',
                  tags: Object.entries(r.tags ?? {}).map((t: any) => ({
                    key: t[0],
                    value: t[1].value,
                    color: t[1].color,
                  })),
                  is_basic: r.is_basic,
                  basic_expression: r.is_basic ? r.basic_expression : [],
                  existing: true,
                }),
              ) ?? [],
          componentState: 'idle',
        },
        () => (this.initialState = deepCopy(this.state)),
      );
    } catch (err: any) {
      console.log(err);
      this.setState({ componentState: 'error' });
    }
  };

  public addNewRule = async () => {
    this.setState(({ rules }) => {
      rules.push({
        id: rules.length,
        rule_id: '',
        expression: '',
        tags: [
          {
            key: '',
            value: '',
            color: '#0f61dd',
          },
        ],
        is_basic: true,
        basic_expression: [
          {
            lhs: '',
            op: IRuleExpressionOperation.EOpNoOp,
            rhs: '',
          },
        ],
        existing: false,
      });

      return { rules };
    });
  };

  public addNewRuleTag = (index: number) => {
    this.setState(({ rules }) => {
      rules[index].tags.push({
        key: '',
        value: '',
        color: '#0f61dd',
      });

      return { rules };
    });
  };

  public deleteRule = async (index: number) => {
    const deleted = this.state.rules.filter(
      (r, idx) => idx === index && r.existing === true,
    ).length;
    this.setState(({ rules }) => {
      rules.splice(index, 1);
      this.updateOrgTaggingRulesCount();
      return {
        rules,
        existingDeleted: this.state.existingDeleted + deleted,
      };
    });
  };

  public onTagsDelete = (index: number, tIndex: number) => {
    this.setState(({ rules }) => {
      rules[index].tags.splice(tIndex, 1);
      return { rules };
    });
  };

  public verify = () => {
    const errors: string[] = [];
    const { rules } = this.state;
    let firstErrIndex = -1;

    rules
      .filter(rule =>
        this.props.editRuleId ? rule.rule_id === this.props.editRuleId : !rule.existing,
      )
      .forEach((rule, index) => {
        if (!rule.is_basic) {
          if (rule.expression === '') {
            errors.push(`rules[${index}].expression`);
          }
        } else {
          rule.basic_expression.forEach((be, beIndex) => {
            if (be.lhs === '') {
              errors.push(`rules[${index}].basic_expression[${beIndex}].lhs`);
            }

            if (be.op === IRuleExpressionOperation.EOpNoOp) {
              errors.push(`rules[${index}].basic_expression[${beIndex}].op`);
            }

            if (be.rhs === '') {
              errors.push(`rules[${index}].basic_expression[${beIndex}].rhs`);
            }
          });
        }

        rule.tags.forEach((tag, tIndex) => {
          if (tag.key === '') {
            errors.push(`rules[${index}].tags[${tIndex}].key`);
          }

          if (tag.value === '') {
            errors.push(`rules[${index}].tags[${tIndex}].value`);
          }
        });

        if (errors.length !== 0 && firstErrIndex === -1) {
          firstErrIndex = index;
        }
      });

    this.setState({ errors });
    return errors;
  };

  public save = async () => {
    const { editRuleId } = this.props;

    this.setState({ errors: [], saveState: 'saving' });
    const errors = this.verify();
    if (errors.length > 0) {
      this.setState({ saveState: 'idle' });
      return;
    }

    const rules = this.state.rules.filter((r, i, arr) =>
      editRuleId ? r.rule_id === editRuleId : i === arr.length - 1,
    );
    const payloadRules = rules.map(r => {
      return {
        expression: r.expression,
        is_basic: r.is_basic,
        basic_expression: r.basic_expression,
        tags: r.tags.reduce((c: any, nt: any) => {
          c[nt.key] = {
            value: nt.value,
            color: nt.color,
          };
          return c;
        }, {}),
      };
    }) as ITaggingRule[];

    try {
      // await this.TaggingRulesService.save(payloadRules);
      if (editRuleId) {
        await this.context.updateRule.mutateAsync({
          ruleType: RoutingRules.tagging,
          ruleId: rules[0].rule_id,
          ruleDetail: payloadRules[0],
        });
      } else {
        await this.context.createRule.mutateAsync({
          ruleType: RoutingRules.tagging,
          ruleDetail: payloadRules[0],
        });
      }

      this.setState({
        saveState: 'saved',
      });
      this.setState({
        rules: rules.map(r => {
          return { ...r, existing: true };
        }),
        existingDeleted: 0,
      });
      this.props.setIsDirty(false);

      this.props.refetchRule();
    } catch (err: any) {
      this.setState({
        saveState: 'error',
        networkError: err?.response?.data?.meta?.error_message ?? 'Network Error',
      });
    }

    AppTracker.track(T_WA_GS_SERVICE_TAGGING, {
      'Tagging Alert Source': this.state.alertSource?.type,
      'Tagging Rules': this.state.rules.length > 0,
    });
  };

  public onRuleExpressionChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      this.setState(({ rules }) => {
        rules[index].expression = value;
        return { rules };
      });
    };

  public onRuleTagChange = (
    index: number,
    tIndex: number,
    name: 'key' | 'value',
    value: string,
  ) => {
    this.setState(({ rules }) => {
      rules[index].tags[tIndex][name] = value;
      return { rules };
    });
  };

  onSelectChange = (_: any, alertSource: IAlertSource) => {
    this.getAlertSourceConfig(alertSource);
  };

  onTextChange = (type: 'alertSourceSearch') => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [type]: event.target.value });
  };

  changeConditionSelectBox =
    (ruleIndex: number, beIndex: number, field: 'lhs' | 'op' | 'rhs') => (_: any, value: any) => {
      this.setState(({ rules }) => {
        rules[ruleIndex].basic_expression[beIndex][field] = value;
        if (field === 'lhs') {
          rules[ruleIndex].basic_expression[beIndex].rhs = '';
          rules[ruleIndex].basic_expression[beIndex].op = IRuleExpressionOperation.EOpNoOp;
        }
        return { rules };
      });
    };

  changeConditionInputBox =
    (ruleIndex: number, beIndex: number, field: 'rhs') =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value: any = event.target.value;

      this.setState(({ rules, event }) => {
        const lhs: string = rules[ruleIndex].basic_expression[beIndex].lhs;
        value = getLHSType(lhs, event) === 'number' ? parseFloat(value) || '' : value;
        rules[ruleIndex].basic_expression[beIndex][field] = value;
        return { rules };
      });
    };

  addCondition = (ruleIndex: number) => () => {
    this.setState(({ rules }) => {
      rules[ruleIndex].basic_expression.push({
        lhs: '',
        op: IRuleExpressionOperation.EOpNoOp,
        rhs: '',
      });

      return { rules };
    });
  };

  removeCondition = (ruleIndex: number, beIndex: number) => () => {
    this.setState(({ rules }) => {
      rules[ruleIndex].basic_expression.splice(beIndex, 1);
      return { rules };
    });
  };

  openWarning = (ruleIndex: number) => () => this.setState({ warningIndex: ruleIndex });

  closeWarning = (flag: boolean) => () => {
    if (!flag) {
      this.setState({ warningIndex: null });
    } else {
      this.setState(({ rules, warningIndex }) => {
        const rule = rules[warningIndex as number];
        rule.is_basic = false;
        rule.expression = generateExpression(rule.basic_expression, this.state.event);
        rule.basic_expression = [];
        return { rules, warningIndex: null };
      });
    }
  };

  setEntitySearch =
    (entitiyType: string, ruleIndex: number, beIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      this.setState(({ searchString }) => {
        searchString[`${entitiyType}_${ruleIndex}_${beIndex}`] = value;
        return { searchString };
      });
    };

  hide = () => this.state.warningIndex === null && this.props.hide();

  onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    this.setState({
      globalSearch: value,
    });
  };

  componentDidUpdate() {
    if (
      this.initialState &&
      this.state.saveState !== 'saved' &&
      !equal(
        pick(this.state, 'rules', 'alertSource'),
        pick(this.initialState, 'rules', 'alertSource'),
      )
    )
      this.props.checkAndSetDirty();
  }
}

export default connect(({ organization, integrations }: IAppState) => ({
  organization,
  integrations,
}))(TaggingRulesModal);
