import Axios from 'axios';
import { IStakeholderDependencies } from 'core/interfaces/ITeams';
import { API } from '../api';
import {
  IUserHMAC,
  IUserInfo,
  IUserProfile,
  IUserReq,
  IUserUpdateReq,
  IUserVerificationReq,
} from '../interfaces/IUserData';

class UsersService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/users`;
  public bulkCreate = (users: IUserReq[]) =>
    Axios.post<IUserInfo[]>(`${this._api}/bulk`, { data: users });

  public update = (user: IUserUpdateReq, userID: string) =>
    Axios.put<IUserInfo>(`${this._api}/${userID}`, user);

  public delete = (userID: string) => Axios.delete<{ result: boolean }>(`${this._api}/${userID}`);

  public getUserById = (userId: string) =>
    Axios.get<{ data: IUserProfile }>(`${this._api}/${userId}`);

  public getUserHMACById = (userId: string) =>
    Axios.get<{ data: IUserHMAC }>(`${this._api}/${userId}/hmac`);

  public sendVerificationMail = (user: IUserVerificationReq) =>
    Axios.post(
      `${API.config.endpoint}/me/verification-mail?organization_id=${API.config.organizationId}`,
      user,
    );

  public sendVerificationReminderMail = (userId: string) =>
    Axios.post(
      `${API.config.batman}/organizations/${API.config.organizationId}/users/${userId}/send-verification-reminder`,
    );

  public updateUserPermissions = (
    data: { user_id: string; abilities: IUserUpdateReq['abilities'] }[],
  ) => Axios.put(`${this._api}/abilities`, { data });

  public getStakeholderDependencies = (userId: string) =>
    Axios.get<{ data: IStakeholderDependencies[] }>(
      `${this._api}/${userId}/stakeholder-dependencies`,
    );
}

export default UsersService;
