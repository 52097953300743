import axios from 'axios';
import { API } from '../api';
import { ITagRuleRaw } from '../interfaces/IService';

class TaggingRulesService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/services`;
  private orgTaggingRulesCount = `${API.config.batman}/organizations/${API.config.organizationId}/tagging-rules/count`;

  constructor(serviceId: string) {
    this._api = `${this._api}/${serviceId}/tagging-rules`;
  }

  public getOrgCount = () => axios.get<{ data: { count: number } }>(this.orgTaggingRulesCount);

  public get = () => axios.get(this._api);

  public save = (rules: ITagRuleRaw[]) => axios.post(this._api, { rules });
}

export default TaggingRulesService;
