import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { FormButton, Input, Tag } from 'library/atoms';
import { TERTIARY_COLORS, THEME_COLORS } from 'library/theme/colors';
import { useState } from 'react';

import { ColorWheel } from './ColorWheel';
import { Modal } from './Modal';

export const ColorPicker = ({
  onSelection,
  isOpen,
  onClose,
}: {
  onSelection: (color: string) => void;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [selectedColor, selectColor] = useState(TERTIARY_COLORS[0]);

  return (
    <Modal
      body={
        <VStack alignItems="flex-start" width="70%" spacing={5}>
          <HStack>
            <Text fontSize="sm" color={THEME_COLORS.secondary[700]}>
              Preview
            </Text>
            <Tag variant="keyValue" keyStr="Key" value="value" color={selectedColor} />
          </HStack>
          <VStack alignItems="flex-start">
            <Text fontSize="sm" color={THEME_COLORS.secondary[700]}>
              Background
            </Text>
            <Input
              addon="#"
              addon2={
                <Box width="4" borderRadius="sm" bg={selectedColor} color="brand.white" p={2}></Box>
              }
              value={selectedColor.slice(1)}
              variant="addon"
            />
          </VStack>
          <ColorWheel
            onchange={(color: string) => {
              selectColor(color);
            }}
          />
        </VStack>
      }
      closeOnOverlayClick
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={
        <FormButton
          onClick={() => {
            onClose();
            onSelection(selectedColor);
          }}
          title="Save"
          variant="primary"
        />
      }
      secondaryButton={<FormButton onClick={onClose} title="Cancel" variant="secondary" />}
      title="New Tag Color"
    />
  );
};
