import { Box, Flex, HStack, StackItem, Text } from '@chakra-ui/react';
import { FC, useEffect, useMemo, useRef } from 'react';

import { FormButton, IconButton } from '../../../atoms';
import { LibraryIcons } from '../../../icons';
import { IPagination, PageSizePaginationProps, PaginationProps } from '../types';
import { PageSize } from './PageSize';
import useMinMax from './useMinMax';
import { buildPageLabels } from './util';

const Pagination: FC<IPagination> = ({
  canNextPage,
  canPreviousPage,
  nextPage,
  pageCount,
  pageSize,
  previousPage,
  pageIndex,
  gotoPage,
  disabled,
}) => {
  const handleLeftNavigate = () => canPreviousPage && previousPage();
  const handleRightNavigate = () => canNextPage && nextPage();

  const labels = useMemo(() => {
    const pageLabels = buildPageLabels(pageIndex, pageCount);
    return pageLabels.map((label, i) => {
      const isPageLabel = label > 0;
      const page = label;
      if (!isPageLabel) {
        return (
          <Box as="span" key={`dot-${i}`} pointerEvents={'none'} userSelect={'none'}>
            ...
          </Box>
        );
      }

      return (
        <FormButton
          key={`${i}th-page-${page}`}
          variant="ghost"
          title={page.toString()}
          sx={{
            color: page === pageIndex + 1 ? 'secondary.1000' : 'brand.blue',
          }}
          aria-label={`Go to page ${page}`}
          disabled={page === pageIndex + 1 || disabled}
          onClick={() => gotoPage(page - 1)}
        />
      );
    });
  }, [pageCount, pageIndex, pageSize, disabled]);

  return (
    <Flex gap={2} justifyContent={'center'} alignItems={'center'}>
      <IconButton
        variant="ghost"
        title="Go to Previous"
        aria-label="Previous"
        disabled={!canPreviousPage || disabled}
        sx={{
          '&[disabled]': {
            opacity: '.5',
          },
        }}
        icon={
          <LibraryIcons.ChevronLeftIcon color={canPreviousPage ? 'brand.blue' : 'secondary.1000'} />
        }
        onClick={handleLeftNavigate}
      />

      <Box>
        <Flex gap={2} alignItems={'center'}>
          {labels}
        </Flex>
      </Box>

      <IconButton
        variant="ghost"
        title="Go to Next"
        aria-label="Next"
        disabled={!canNextPage || disabled}
        sx={{
          '&[disabled]': {
            opacity: '.5',
          },
        }}
        icon={
          <LibraryIcons.ChevronRightIcon color={canNextPage ? 'brand.blue' : 'secondary.1000'} />
        }
        onClick={handleRightNavigate}
      />
    </Flex>
  );
};

const PaginationBlock: React.FC<PaginationProps> = ({
  style,
  loading,
  disabled,
  nextPage,
  previousPage,
  canNextPage,
  canPreviousPage,
  pageIndex,
  pageSize,
  setPageSize,
  gotoPage,
  totalCount,
  pageCount,
  rowsCount,
  maxPageSize,
}) => {
  const pageSizeProps: PageSizePaginationProps = {
    loading,
    setPageSize,
    pageSize,
    maxPageSize,
  };

  const paginationprops: IPagination = {
    canNextPage,
    canPreviousPage,
    pageIndex,
    pageCount,
    pageSize,
    rowsCount,
    gotoPage,
    nextPage,
    previousPage,
    disabled,
  };

  const prevMin = useRef<number>(0);
  const prevMax = useRef<number>(0);

  const [min, max] = useMinMax({ visibleItems: rowsCount, pageIndex, pageSize });

  useEffect(() => {
    prevMin.current = min;
    prevMax.current = max;
  }, [min, max]);

  return (
    <Box
      opacity={loading ? 0.3 : 1}
      bg="brand.white"
      py="8px"
      px="24px"
      borderTopWidth="1px"
      borderTopStyle="solid"
      borderTopColor="secondary.200"
      zIndex={loading ? 'auto' : 1}
      {...style}
    >
      <HStack justifyContent={'space-between'} alignItems={'center'}>
        <PageSize {...pageSizeProps} />
        <StackItem>
          {pageCount > 1 && (
            <HStack>
              {min <= max && (
                <StackItem>
                  <Text variant="h4" color={'secondary.1000'}>
                    {loading ? (
                      <>
                        {prevMin.current}-{prevMax.current}
                      </>
                    ) : (
                      <>
                        {min}-{max}
                      </>
                    )}
                    <Text as="span" color="secondary.700">
                      {' '}
                      of {totalCount}
                    </Text>
                  </Text>
                </StackItem>
              )}
              <StackItem>
                <Pagination {...paginationprops} />
              </StackItem>
            </HStack>
          )}
        </StackItem>
      </HStack>
    </Box>
  );
};

export default PaginationBlock;
