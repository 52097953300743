import { Grid } from 'uie/components';
import { IService } from 'core/interfaces/IService';
import { matchSorter } from 'match-sorter';
import React, { ComponentProps, useMemo } from 'react';
import { MultiValue, OnChangeValue, SingleValue } from 'react-select';

import { SecondaryFiltersKeys, SelectOption } from '../../types';
import Badge from './badge';
import { FilterItem, FilterItemList } from './dropdown_container';

interface Props {
  selected: string[];
  filteredTerm?: string;
  services?: IService[];
  isMulti?: boolean;
  updateFilter: (type: SecondaryFiltersKeys, data: string[]) => void;
}

export const ServiceBadge = (
  props: ComponentProps<typeof Badge> & {
    services: IService[];
    showAccumlatedTag: boolean;
    onRemovaTag: any;
  },
) => {
  const { items, services, showAccumlatedTag } = props;
  const itemList = items.map(it => services.find(s => s.id === it)?.name);

  const removeTag = (name: string) => {
    const mappedItems = items.map(it => services.find(s => s.name === name));
    if (props.onRemovaTag) props.onRemovaTag(mappedItems[0]);
  };

  if (showAccumlatedTag)
    return (
      <Badge
        {...props}
        onRemove={val => {
          removeTag(val);
        }}
        items={itemList}
        showTooltip
      />
    );
  else
    return itemList?.map((list: any) => (
      <Badge
        key={props.label}
        onRemove={val => {
          removeTag(val);
        }}
        {...props}
        items={[list]}
        showTooltip
      />
    ));
};

const noneItem = { label: 'None', value: '' };

function Services(props: Props): JSX.Element {
  const { services = [], filteredTerm, selected, updateFilter } = props;

  const serviceOptions = useMemo(
    () =>
      matchSorter(services, filteredTerm || '', { keys: ['name'] }).map((t: IService) => {
        return {
          label: t.name,
          value: t.id,
        };
      }),
    [services, filteredTerm],
  );

  const [selectedOptions, selectedNames] = useMemo(() => {
    const sel = services.filter(t => {
      return selected.some(f => {
        return f === t.id;
      });
    });

    return [
      sel.map(t => {
        return {
          label: t.name,
          value: t.id,
        };
      }),
      sel.map(t => t.name),
    ];
  }, [services, selected]);

  const handleChange = (newValue: OnChangeValue<SelectOption, boolean>) => {
    const arr: string[] = [];

    if (Array.isArray(newValue)) {
      const options = newValue as MultiValue<SelectOption>;
      options.map(o => {
        arr.push(o.value);
      });
    } else if (newValue) {
      const option = newValue as SingleValue<SelectOption>;
      if (option?.value) {
        arr.push(option.value);
      }
    }

    updateFilter('services', arr);
  };

  const checkServiceSelected = (service: SelectOption) => {
    if (selectedOptions?.length) {
      return !!selectedOptions.find(option => option.value === service.value);
    } else if (!service.value) {
      return true;
    }
    return false;
  };

  return (
    <Grid style={{ flexDirection: 'column', overflowY: 'auto' }}>
      <FilterItem
        item={noneItem}
        checkItemSelected={checkServiceSelected}
        onItemSelect={service => handleChange(service)}
      />
      <FilterItemList
        itemList={serviceOptions}
        checkItemSelected={checkServiceSelected}
        onItemSelect={service => handleChange(service)}
      />
    </Grid>
  );
}

export default Services;
