import * as React from 'react';
import { SVGProps } from 'react';

const SvgMicrosoftTeamsLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <circle
      style={{
        fill: '#7b83eb',
      }}
      cx={10.625}
      cy={3.75}
      r={2.917}
    />
    <path
      style={{
        opacity: 0.1,
      }}
      d="M10.417 4.375H7.775l.013.058.001.005.014.054A2.92 2.92 0 0 0 11.25 6.6V5.208a.836.836 0 0 0-.833-.833z"
    />
    <path
      style={{
        opacity: 0.2,
      }}
      d="M9.792 5H7.989a2.918 2.918 0 0 0 2.636 1.667v-.834A.836.836 0 0 0 9.792 5z"
    />
    <circle
      style={{
        fill: '#5059c9',
      }}
      cx={17.188}
      cy={4.688}
      r={2.188}
    />
    <path
      style={{
        fill: '#5059c9',
      }}
      d="M19.062 7.5h-4.913a.4.4 0 0 0-.4.4v5.069a3.2 3.2 0 0 0 2.479 3.177A3.129 3.129 0 0 0 20 13.086V8.438a.938.938 0 0 0-.938-.938z"
    />
    <path
      style={{
        fill: '#7b83eb',
      }}
      d="M15.625 8.438v5.938a4.997 4.997 0 0 1-9.325 2.5c-.12-.2-.225-.409-.312-.625a5.134 5.134 0 0 1-.206-.625 5.12 5.12 0 0 1-.156-1.25V8.438A.935.935 0 0 1 6.56 7.5h8.128a.937.937 0 0 1 .938.935l-.001.003z"
    />
    <path
      style={{
        opacity: 0.2,
      }}
      d="M9.792 5H7.989a2.918 2.918 0 0 0 2.636 1.667v-.834A.836.836 0 0 0 9.792 5z"
    />
    <path
      style={{
        opacity: 0.1,
      }}
      d="M11.25 7.5v7.919a.825.825 0 0 1-.65.806.618.618 0 0 1-.181.025H5.988a5.134 5.134 0 0 1-.206-.625 5.12 5.12 0 0 1-.156-1.25V8.438A.935.935 0 0 1 6.56 7.5h4.69z"
    />
    <path
      style={{
        opacity: 0.2,
      }}
      d="M10.625 7.5v8.544a.618.618 0 0 1-.025.181.825.825 0 0 1-.806.65H6.3c-.12-.2-.225-.409-.312-.625a5.134 5.134 0 0 1-.206-.625 5.12 5.12 0 0 1-.156-1.25V8.438A.935.935 0 0 1 6.56 7.5h4.065z"
    />
    <path
      style={{
        opacity: 0.2,
      }}
      d="M10.625 7.5v7.294a.835.835 0 0 1-.831.831H5.781a5.12 5.12 0 0 1-.156-1.25V8.438A.936.936 0 0 1 6.56 7.5h4.065z"
    />
    <path
      style={{
        opacity: 0.2,
      }}
      d="M6.562 7.5a.936.936 0 0 0-.937.935v5.941c.001.421.053.841.156 1.25h3.387a.835.835 0 0 0 .831-.831V7.5H6.562z"
    />
    <path
      style={{
        fill: '#4b53bc',
      }}
      d="M.833 5h8.334c.46 0 .833.373.833.833v8.334c0 .46-.373.833-.833.833H.833A.833.833 0 0 1 0 14.167V5.833C0 5.373.373 5 .833 5z"
    />
    <path
      style={{
        fill: '#fff',
      }}
      d="M7.487 8.109H5.619v5.013H4.392V8.109h-1.88V6.878h4.975v1.231z"
    />
    <path
      style={{
        fill: 'none',
      }}
      d="M0 0h20v20H0V0z"
    />
  </svg>
);

export default SvgMicrosoftTeamsLogo;
