import { PlacementWithLogical, Tooltip } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  text: string;
  placement?: PlacementWithLogical;
};

function ServiceTooltip({ children, text, placement }: Props) {
  return (
    <Tooltip
      hasArrow
      bgColor="white"
      color="gray.700"
      fontWeight={400}
      label={text}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
}

export default ServiceTooltip;
