import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.st0{fill-rule:evenodd;clip-rule:evenodd;fill:url(#Rectangle_00000035522561604963130500000013683263076889001897_)}'
      }
    </style>
    <g id="Icon-Architecture_x2F_64_x2F_Arch_x5F_Amazon-CodeGuru_x5F_64">
      <g id="Icon-Architecture-BG_x2F_64_x2F_Machine-Learning">
        <linearGradient
          id="Rectangle_00000043457975203770207020000010548413333180051895_"
          gradientUnits="userSpaceOnUse"
          x1={-1297.575}
          y1={-20.8}
          x2={-1297.325}
          y2={-21.05}
          gradientTransform="matrix(80 0 0 80 103806 1684)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#055f4e',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#56c0a7',
            }}
          />
        </linearGradient>
        <path
          id="Rectangle"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'url(#Rectangle_00000043457975203770207020000010548413333180051895_)',
          }}
          d="M0 0h20v20H0z"
        />
      </g>
      <path
        id="Amazon-CodeGuru_Icon_64_Squid"
        d="M6.659 5.381 5.7 4.422l-.354.353.959.959.354-.353zm7.995-.606-.354-.353-.959.959.354.353.959-.959zm-4.086 2.097-.495-.071-.64 4.477.495.071.64-4.477zm1.87 2.354a.249.249 0 0 0 0-.3l-.959-1.279-.4.3.847 1.129-.847 1.129.4.3.959-1.279zm-3.517.979-.847-1.129.847-1.129-.4-.3-.959 1.279a.249.249 0 0 0 0 .3l.959 1.279.4-.3zm-.439 5.545h3.036l-.529.75H9.01l-.528-.75zm2.841 1.144.881-1.25a.247.247 0 0 0 .017-.259.248.248 0 0 0-.221-.135H8a.25.25 0 0 0-.205.394l.881 1.25a.253.253 0 0 0 .205.106h2.238a.25.25 0 0 0 .204-.106zm-3.311-4.706a.249.249 0 0 0-.104-.203c-.885-.638-1.414-1.756-1.414-2.991A3.469 3.469 0 0 1 10 5.488a3.469 3.469 0 0 1 3.506 3.506c0 1.235-.529 2.353-1.414 2.991a.252.252 0 0 0-.104.203v1.754H8.012v-1.754zm4.476 2.004v-1.879c.953-.742 1.518-1.972 1.518-3.319 0-2.246-1.759-4.006-4.006-4.006a3.964 3.964 0 0 0-4.006 4.006c0 1.347.565 2.577 1.517 3.319v1.879c0 .138.112.25.25.25h4.477a.25.25 0 0 0 .25-.25zm2.309-4.942h1.453v-.5h-1.453v.5zm-11.047 0h1.453v-.5H3.75v.5zm6-4.75h.5V3h-.5v1.5z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);

export default SvgComponent;
