import * as React from 'react';
import { SVGProps } from 'react';

const SvgZabbix = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M0 0h20v20H0V0z"
      style={{
        fill: '#d40000',
      }}
    />
    <path
      style={{
        fill: '#fff',
      }}
      d="M4.479 3.048h11.042v1.436L6.633 15.37h9.105v1.582H4.262v-1.436L13.151 4.63H4.479z"
    />
  </svg>
);

export default SvgZabbix;
