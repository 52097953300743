export enum RotationViewType {
  defaultRotation = 'default_rotation',
  onlyOverrides = 'only_override',
  myOnCall = 'my_on_call',
  gaps = 'gaps',
  finalRotation = 'final_rotation',
}

export const rotationViewType = [
  { label: 'Default Rotation', value: RotationViewType.defaultRotation },
  { label: 'Only Overrides', value: RotationViewType.onlyOverrides },
  { label: 'My On-Call', value: RotationViewType.myOnCall },
  { label: 'Gaps', value: RotationViewType.gaps },
  { label: 'Final Rotation', value: RotationViewType.finalRotation },
];
