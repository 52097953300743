import { Avatar } from '@chakra-ui/avatar';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/react';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { truncate } from 'core/helpers/stringUtils';
import { IAppState } from 'core/interfaces/IAppState';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import { TEAMS_PATH, TEAM_SQUADS_PATH, USER_PROFILE_PATH } from 'views/main/routes/routes';

export type EntityType = 'user' | 'squad' | 'team';

type Props = {
  type: EntityType;
  entityId: string;
  renderType?: 'link-popover';
  showDeletedEntity?: boolean;
  textVariant?: string;
};

const EntityUI: FC<Props> = props => {
  const styles = useMultiStyleConfig('EntityUI', {});
  const organization = useSelector((state: IAppState) => state.organization);

  const userSquads = useMemo(() => {
    if (props.type !== 'user') return [];
    return organization.squads.s
      .filter(squads => squads.members.find(member => member === props.entityId))
      .map(s => ({ id: s.id, name: s.name }));
  }, [props.type, props.entityId, organization.squads.s]);

  const entityDetails = useMemo(() => {
    switch (props.type) {
      case 'user': {
        const entityInfo = organization.users.u.find(user => user.id === props.entityId);
        if (!entityInfo)
          return { name: 'Deleted User', entity: null, type: props.type, path: null };
        return {
          name: [entityInfo.first_name, entityInfo.last_name].join(' ').trim(),
          entity: entityInfo,
          username: entityInfo.username_for_display,
          type: props.type,
          path: generatePath(USER_PROFILE_PATH, { id: props.entityId }),
        };
      }
      case 'squad': {
        const entityInfo = organization.squads.s.find(squad => squad.id === props.entityId);
        if (!entityInfo)
          return { name: 'Deleted Squad', entity: null, type: props.type, path: null };
        return {
          name: entityInfo.name,
          entity: entityInfo,
          type: props.type,
          path: generatePath(TEAM_SQUADS_PATH, {
            id: organization.selectedTeam.teamId,
            sqid: props.entityId,
          }),
        };
      }
      case 'team': {
        const entityInfo = organization.teams.t.find(team => team.id === props.entityId);
        if (!entityInfo)
          return { name: 'Deleted Team', entity: null, type: props.type, path: null };
        return {
          name: entityInfo.name,
          entity: entityInfo,
          type: props.type,
          path: generatePath(TEAMS_PATH, { id: props.entityId }),
        };
      }
      default:
        return { name: 'Deleted Entity', entity: null, type: props.type, path: null };
    }
  }, [props.type, props.entityId, organization]);

  if (props.renderType === 'link-popover') {
    if (!entityDetails.entity) {
      if (props.showDeletedEntity) return <Text variant="formInput">{entityDetails.name}</Text>;
      return null;
    }

    return (
      <Link to={entityDetails.path}>
        <Tooltip
          label={
            <VStack alignItems={'left'} spacing={0}>
              <Text fontWeight={'bold'}>{entityDetails.name}</Text>
              <Text>{entityDetails.username}</Text>
            </VStack>
          }
          hasArrow
          placement="bottom"
        >
          <HStack gap={1}>
            {(entityDetails.type === 'user' || entityDetails.type === 'team') && (
              <Avatar name={entityDetails.name} size="xs" />
            )}
            <Text isTruncated noOfLines={1} color={'brand.blue'}>
              {truncate(entityDetails.name, 15)}
            </Text>
          </HStack>
        </Tooltip>
      </Link>
    );
  }

  return null;
};

export default EntityUI;
