import { Box, Flex, Text } from '@chakra-ui/layout';
import FormDropdown from 'library/molecules/Form/FormDropdown/FormDropdown';
import FormField from 'library/molecules/FormField/FormField';
import { useEffect, useMemo } from 'react';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { OperatorOptions } from '../../../constant';
import { OperationOption, TagKey, TagKeyDropDownOption, TagValue } from '../../../types';
import { onChangeOperator, selectedFilter, selectedFilterKey, selectedOperator } from '../utility';
import { useTags } from './useTags';

interface Props {
  filterFieldName: string;
  filterName: string;
}

const TagFilter = <TFormType extends FieldValues>({ filterFieldName, filterName }: Props) => {
  const { isLoading, data } = useTags();
  const { watch, setValue } = useFormContext<TFormType>();
  const activeTagValue = watch(`${filterFieldName}.fields.key` as Path<TFormType>);

  const operatorOptions = useMemo(() => {
    return OperatorOptions.filter(option => option.label.startsWith('Is'));
  }, []);

  const activeOperator = selectedOperator(filterFieldName, watch, OperatorOptions);

  const tagKeyOptions: TagKeyDropDownOption[] = useMemo(
    () =>
      data
        ? Object.keys(data).map(key => ({
            label: key,
            value: key,
          }))
        : [],
    [data],
  );

  const tagValueOptions = (selectedKey?: string) => {
    return data && selectedKey
      ? data[selectedKey]
          ?.map(value => ({
            label: value,
            value: value,
          }))
          .filter((value, index, self) => self.findIndex(v => v.value === value.value) === index)
      : [];
  };

  const activeTagKey = selectedFilterKey(filterFieldName, watch, tagKeyOptions);
  const activeTagEntity = selectedFilter(filterFieldName, watch, tagValueOptions(activeTagValue));

  useEffect(() => {
    if (!activeOperator && !activeTagKey && !activeTagEntity) {
      setValue(
        `${filterFieldName}.type` as Path<TFormType>,
        'tag_is' as PathValue<TFormType, Path<TFormType>>,
      );
      setValue(
        `${filterFieldName}.fields.key` as Path<TFormType>,
        tagKeyOptions[0]?.value as PathValue<TFormType, Path<TFormType>>,
      );
      setValue(
        `${filterFieldName}.fields.value` as Path<TFormType>,
        tagValueOptions(tagKeyOptions[0]?.value)[0]?.value as PathValue<TFormType, Path<TFormType>>,
      );
    }

    if (activeTagKey && !activeTagEntity) {
      const existingTag = tagKeyOptions.some(({ value }) => value === activeTagValue);
      if (existingTag) {
        setValue(
          `${filterFieldName}.fields.value` as Path<TFormType>,
          tagValueOptions(activeTagValue)[0]?.value as PathValue<TFormType, Path<TFormType>>,
        );
      }
    }
  }, [activeTagKey]);

  return (
    <Flex flex={1} pt={2} gap={4} flexDirection="row">
      <Box flex={1}>
        <FormDropdown<OperationOption>
          options={operatorOptions}
          name={`${filterFieldName}.operator`}
          value={activeOperator}
          onChange={op => {
            onChangeOperator(
              op,
              filterFieldName,
              filterName,
              setValue as (name: Path<TFormType>, value: any) => void,
            );
          }}
        />
      </Box>

      <Flex flex={2} gap={4} align="center">
        <FormField variant="floating" error={''}>
          <FormDropdown<TagKey>
            options={tagKeyOptions}
            name={`${filterFieldName}.keyEntity`}
            isLoading={isLoading}
            value={activeTagKey}
            formatCreateLabel={label => (
              <Text color="brand.blue" variant="formInput">{`+ ${label}`}</Text>
            )}
            isCreatable
            onChange={tag => {
              if (tag && 'value' in tag) {
                setValue(
                  `${filterFieldName}.fields` as Path<TFormType>,
                  {
                    key: tag.value,
                  } as PathValue<TFormType, Path<TFormType>>,
                );
              }
            }}
            placeholder="Select Tag Key"
          />
        </FormField>
        <FormField variant="floating" error={!activeTagEntity ? 'Value is required' : ''}>
          <FormDropdown<TagValue>
            options={tagValueOptions(activeTagValue)}
            name={`${filterFieldName}.valueEntity`}
            isLoading={isLoading}
            value={activeTagEntity}
            placeholder="Select Tag Value"
            formatCreateLabel={label => (
              <Text color="brand.blue" variant="formInput">{`+ ${label}`}</Text>
            )}
            isCreatable
            onChange={tag => {
              if (tag && 'value' in tag) {
                setValue(
                  `${filterFieldName}.fields.value` as Path<TFormType>,

                  tag.value as PathValue<TFormType, Path<TFormType>>,
                );
              }
            }}
          />
        </FormField>
      </Flex>
    </Flex>
  );
};

export default TagFilter;
