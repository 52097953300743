import { VStack, Box } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { AlertSource, ServiceIcon, Tags, PriorityIcon } from 'icons';
import { focusCard } from 'core';
import React, { useEffect, useRef } from 'react';
import { Workflow } from 'views/main/organization/workflow/types/response/workflow';
import { BE_FieldType, FilterTypes, WorkFlowGroupRecur } from '../../../types';
import { AlertSourcesCondition } from './AlertSourcesCondition';
import { ServiceConditions } from './ServicesConditions';
import { TagsConditions } from './TagsConditions';
import { PriorityCondition } from './PriorityCondition';
import Separator from 'library/atoms//Separator';
import { ActiveWorkflowTarget } from './ActiveWorkflowTarget';

type WorkflowConditionsProps = Pick<Workflow, 'filters'>;
export const CONDITION_ICONS = {
  [FilterTypes.ALERT_SOURCE]: AlertSource,
  [FilterTypes.SERVICE]: ServiceIcon,
  [FilterTypes.TAGS]: Tags,
  [FilterTypes.PRIORITY]: PriorityIcon,
};

export const CONDITIONS_COMPONENTS = {
  [FilterTypes.ALERT_SOURCE]: AlertSourcesCondition,
  [FilterTypes.SERVICE]: ServiceConditions,
  [FilterTypes.TAGS]: TagsConditions,
  [FilterTypes.PRIORITY]: PriorityCondition,
};

const WorkflowConditions: React.FC<WorkflowConditionsProps> = ({ filters }) => {
  const flexRef = useRef(null);
  const { isactionTrigger, targetAction } = ActiveWorkflowTarget('#Conditions');

  useEffect(() => {
    if (flexRef.current && targetAction === '#Conditions') {
      focusCard(flexRef.current);
    }
  }, [isactionTrigger]);

  const allFilters =
    filters.type.includes('and_filter') || filters.type.includes('or_filter')
      ? filters?.fields?.children
      : [filters];

  if (!filters) return null;
  if (filters.type === 'empty') return null;

  return (
    <VStack align="stretch" bg="primary.200" px={6} pt={4} pb={12} ref={flexRef}>
      <Text variant="h2" as="h2" fontWeight="bold">
        CONDITIONS
      </Text>
      {allFilters?.map((filterData, index) => {
        const type = filterData.type.replace(
          /_is(_not)?|_contains(_not)?/g,
          '',
        ) as keyof typeof CONDITIONS_COMPONENTS;

        const isGroupData = filterData.type.includes('and_') || filterData.type.includes('or_');
        let filterProps;
        if (!isGroupData) {
          if (filterData.type === 'AlwaysTrue' || filterData.type === 'empty') {
            return null;
          }
          if ((filterData as BE_FieldType).fields.key) {
            filterProps = {
              fields: {
                value: (filterData as BE_FieldType).fields.value,
                key: (filterData as BE_FieldType).fields.key,
              },
              type: filterData.type,
            };
          } else {
            filterProps = {
              fields: { value: (filterData as BE_FieldType).fields.value },
              type: filterData.type,
            };
          }
        }

        const ConditionsComponent = CONDITIONS_COMPONENTS[type];

        return (
          <React.Fragment key={index}>
            {isGroupData ? (
              <>
                {index !== 0 && (
                  <Box px={4} width="50dvw">
                    <Separator
                      showMargin={true}
                      text={filters.type === 'and_filter' ? 'And' : 'Or'}
                    />
                  </Box>
                )}
                <Box bg="white" borderRadius="lg" width="50dvw">
                  {(filterData.fields as WorkFlowGroupRecur).children.map(
                    (groupFilter, groupIndex) => {
                      const Filtertype = groupFilter.type.replace(
                        /_is(_not)?|_contains(_not)?/g,
                        '',
                      ) as keyof typeof CONDITIONS_COMPONENTS;
                      let groupFilterProps;
                      if ((groupFilter as BE_FieldType).fields.key) {
                        groupFilterProps = {
                          fields: {
                            value: (groupFilter as BE_FieldType).fields.value,
                            key: (groupFilter as BE_FieldType).fields.key,
                          },
                          type: groupFilter.type,
                        };
                      } else {
                        groupFilterProps = {
                          fields: {
                            value: (groupFilter as BE_FieldType).fields.value,
                          },
                          type: (groupFilter as BE_FieldType).type,
                        };
                      }
                      const ConditionsGroupComponent = CONDITIONS_COMPONENTS[Filtertype];

                      return (
                        <>
                          {groupIndex !== 0 && (
                            <Box px={4} width="50dvw">
                              <Separator text={filterData.type === 'and_filter' ? 'And' : 'Or'} />
                            </Box>
                          )}

                          <ConditionsGroupComponent
                            key={groupIndex}
                            alertSources={groupFilterProps}
                            services={groupFilterProps}
                            tags={groupFilterProps}
                            priority={groupFilterProps}
                          />
                        </>
                      );
                    },
                  )}
                </Box>
              </>
            ) : (
              <>
                {index !== 0 && (
                  <Box px={4} width="50dvw">
                    <Separator
                      showMargin={true}
                      text={filters.type === 'and_filter' ? 'And' : 'Or'}
                    />
                  </Box>
                )}
                <Box width="50dvw">
                  <ConditionsComponent
                    key={index}
                    alertSources={filterProps}
                    services={filterProps}
                    tags={filterProps}
                    priority={filterProps}
                  />
                </Box>
              </>
            )}
          </React.Fragment>
        );
      })}
    </VStack>
  );
};

export { WorkflowConditions };
