import { Box, HStack } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { ComponentDayLog, Maybe } from 'views/main/organization/statuspage-v2/graphql/types';

import StatusPageComponentTimelineItem from './TimelineItem';
import useStatuspageComponentTimelineContext from '../hook';

interface IProps {
  timelineData: Maybe<Array<Maybe<ComponentDayLog>>>;
}

export default function StatusPageComponentTimeline(props: IProps) {
  const [scrollProgress, setScrollProgress] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);

  // Add event listener for wheel when the component mounts
  useEffect(() => {
    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener('wheel', handleWheelScroll, { passive: true });
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('wheel', handleWheelScroll);
      }
    };
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    async function addTimeout() {
      return await new Promise(resolve => {
        timeout = setTimeout(async () => {
          if (scrollRef.current) {
            // Scroll to the end of the timeline
            scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
            calculateScrollProgress();
          }
          resolve(null);
        }, 0);
      });
    }

    addTimeout();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [props.timelineData]);

  const calculateScrollProgress = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;

      // Calculate the scroll progress as a ratio
      const totalScrollableWidth = scrollWidth - clientWidth;
      const newScrollProgress = totalScrollableWidth > 0 ? scrollLeft / totalScrollableWidth : 0;

      setScrollProgress(newScrollProgress);
    }
  };

  // Function to handle scrolling via buttons
  const scroll = (scrollOffset: number) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
      calculateScrollProgress();
    }
  };

  // Function to handle mouse wheel scrolling
  const handleWheelScroll = (event: WheelEvent) => {
    if (scrollRef.current) {
      // Scroll left or right based on the wheel delta
      scrollRef.current.scrollLeft += event.deltaY;
      calculateScrollProgress();
    }
  };

  if (!props.timelineData) {
    return null;
  }

  return (
    <Box position="relative" display="flex" alignItems="center" height="56px">
      <Box
        overflowX="scroll"
        ref={scrollRef}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&': {
            scrollbarWidth: 'none',
            '-ms-overflow-style': 'none',
          },
        }}
      >
        <HStack spacing="0px" w="fit-content">
          {props.timelineData?.map((item, idx) => {
            return (
              <StatusPageComponentTimelineItem
                key={`${idx}-${item?.date}`}
                componentDayLog={item ? item : undefined}
              />
            );
          })}
        </HStack>
      </Box>
      {scrollProgress > 0.001 && (
        <Box
          aria-label="scroll left"
          cursor="pointer"
          onClick={() => scroll(-200)}
          position="absolute"
          border={`1px solid ${THEME_COLORS.secondary[200]}`}
          backgroundColor="white"
          borderRadius="0px 26px 26px 0px"
          height="56px"
          width="28px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          left="-17px"
        >
          <FaChevronLeft color={THEME_COLORS.secondary[1000]} size="12px" />
        </Box>
      )}
      {scrollProgress < 0.999 && (
        <Box
          aria-label="scroll right"
          cursor="pointer"
          onClick={() => scroll(200)}
          position="absolute"
          border={`1px solid ${THEME_COLORS.secondary[200]}`}
          backgroundColor="white"
          borderRadius="26px 0px 0px 26px"
          height="56px"
          width="28px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          right="-17px"
        >
          <FaChevronRight color={THEME_COLORS.secondary[1000]} size="12px" />
        </Box>
      )}
    </Box>
  );
}
