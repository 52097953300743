import { PLS } from 'library/molecules/Table/types';
import { DEFAULT_QUERY_DAYS } from '../common/constants';

const INCIDENT_LIST_PAGE_LIMIT_KEY = 'SQ_IL_PAGE_LIMIT';
const INCIDENT_LIST_QUERY_ALL_TIME = 'SQ_IL_ALL_TIME';
const INCIDENT_LIST_DEFAULT_DAYS = 'SQ_IL_DEFAULT_DAYS_OVERRIDE';
const INCIDENT_LIST_CAN_AUTO_REFRESH = 'SQ_IL_CAN_AUTO_REFRESH';
const INCIDENT_LIST_TAGS_EXPANDED = 'SQ_IL_EXPANDED_TAGS';

const storage = localStorage;

export const setILPageLimit = (limit: PLS) => {
  storage.setItem(INCIDENT_LIST_PAGE_LIMIT_KEY, `${limit}`);
};

export const getILPageLimit = () => {
  const limit = storage.getItem(INCIDENT_LIST_PAGE_LIMIT_KEY);
  return +(limit ?? 20) as PLS;
};

export const setAllTimeLimit = (allTime: boolean) => {
  storage.setItem(INCIDENT_LIST_QUERY_ALL_TIME, `${allTime}`);
};

export const setDefaultDaysOverride = (numberOfDays: number) => {
  storage.setItem(INCIDENT_LIST_DEFAULT_DAYS, `${numberOfDays}`);
};

export const getListDefaultDaysOverride = () => {
  const defaultOverride = storage.getItem(INCIDENT_LIST_DEFAULT_DAYS);
  return +(defaultOverride ?? DEFAULT_QUERY_DAYS);
};

export const removeListDefaultDaysOverride = () => {
  storage.removeItem(INCIDENT_LIST_DEFAULT_DAYS);
};

export const isAllTimeLimit = () => {
  return storage.getItem(INCIDENT_LIST_QUERY_ALL_TIME)?.toLowerCase() === 'true';
};

export const setILTagsPreference = (showExpandedTags: boolean) => {
  storage.setItem(INCIDENT_LIST_TAGS_EXPANDED, `${showExpandedTags}`);
};

export const showExpandedTags = () => {
  return storage.getItem(INCIDENT_LIST_TAGS_EXPANDED)?.toLowerCase() === 'true';
};

// AUTO REFRESH should be limited to session storage
export const setCanAutoRefreshIL = (canRefresh: boolean) => {
  sessionStorage.setItem(INCIDENT_LIST_CAN_AUTO_REFRESH, `${canRefresh}`);
};

export const canAutoRefreshIL = () => {
  return sessionStorage.getItem(INCIDENT_LIST_CAN_AUTO_REFRESH)?.toLowerCase() !== 'false';
};

export const resetCanAutoRefreshIL = () => {
  sessionStorage.removeItem(INCIDENT_LIST_CAN_AUTO_REFRESH);
};
