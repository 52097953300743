import { IService } from 'core/interfaces/IService';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { EntityObjectType, EntityType } from '../helpers';
import ServiceHeader from './headers/service';
import UserHeader from './headers/users';

export const getPopoverHeader = (entityType: EntityType, entity: EntityObjectType): JSX.Element => {
  switch (entityType) {
    case 'user': {
      const user = entity as IUsersOfOrganization;
      return (
        <UserHeader
          userId={user.id}
          userFullName={`${user.first_name} ${user.last_name}`}
          userName={`${user.username_for_display}`}
          userTimezone={user.time_zone}
        />
      );
    }
    case 'service': {
      const service = entity as IService;
      return <ServiceHeader serviceId={service.id} serviceName={service.name} />;
    }
    default:
      return <></>;
  }
};
