import Axios from 'axios';
import { API } from 'core';
import { useQuery } from 'react-query';

import { createStandaloneToast } from '@chakra-ui/react';

import { queryKeys, reactQueryConfig } from '../contants/service.contant';
import { reactQueryConfigError } from '../helpers/helper.service';
import { DateTime } from 'luxon';
import { AnalyticsRange } from '../service.detail/service.attribute/service.analytics';
import useOrganizationPlan from './useOrganizationPlan';
import { getSQLDate } from '../helpers/helper.date';

const toast = createStandaloneToast();

interface ServiceAnalyticsParams {
  serviceId: string;
  from_date: string;
  to_date: string;
  granularity: string;
}

export interface IServiceAnalytics {
  acknowledged: number;
  granularity_date: string;
  resolved: number;
  triggered: number;
}

const getServiceAnalytics =
  ({ from_date, to_date, serviceId, granularity }: ServiceAnalyticsParams) =>
  async () => {
    const params = {
      from_date,
      to_date,
      granularity,
    };

    const { data } = await Axios.get<{ data: IServiceAnalytics[] }>(
      `${API.config.batman}/organizations/${API.config.organizationId}/catalog-services/${serviceId}/metrics/incident-graph`,
      {
        params,
      },
    );
    return data.data;
  };

const useServiceAnalyticsQuery = (
  serviceId: string,
  granularity: AnalyticsRange['granularity'],
) => {
  const orgPlan = useOrganizationPlan();

  const to_date = DateTime.now().toISO();
  let from_date = DateTime.now().minus({ month: 1 }).toISO();

  if (granularity === 'week') {
    from_date = DateTime.now().minus({ month: 3 }).toISO();
  } else if (granularity === 'month') {
    switch (orgPlan) {
      case 'Free':
        from_date = DateTime.now().minus({ month: 3 }).toISO();
        break;
      case 'Pro':
        from_date = DateTime.now().minus({ month: 6 }).toISO();
        break;
      case 'Enterprise':
        from_date = DateTime.now().minus({ month: 12 }).toISO();
        break;
    }
  }

  return useQuery(
    [queryKeys.SERVICEANALYTICS, serviceId, granularity],
    getServiceAnalytics({ serviceId, from_date, to_date, granularity }),
    {
      ...reactQueryConfig,
      enabled: !!serviceId,
      select: analyticsRes =>
        analyticsRes
          .map(data => ({
            date: getSQLDate(data.granularity_date),
            Triggered: data.triggered,
            Acknowledged: data.acknowledged,
            Resolved: data.resolved,
          }))
          .sort((data1, data2) =>
            new Date(data1.date).getTime() > new Date(data2.date).getTime() ? 1 : -1,
          ),
      onError: reactQueryConfigError,
    },
  );
};

export { useServiceAnalyticsQuery };
