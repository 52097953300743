import { Grid, CopyIconButton } from 'uie/components';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import breaks from 'remark-breaks';

interface IProps {
  description: string;
}

const CustomCodeRenderer = (props: any) => {
  return (
    <div className="incident_details__code-container description">
      <pre className="incident_details__raw-code">{props.value}</pre>
      <div className="incident_details__code-copy">
        <CopyIconButton type="transparent">{props.value}</CopyIconButton>
      </div>
    </div>
  );
};

export const IncidentDescription = ({ description }: IProps) => {
  const renderMdLink = (props: any) => {
    return (
      // eslint-disable-next-line react/prop-types
      <>
        <a href={props.href} target="_blank" rel="noopener noreferrer" className="nnnnn">
          {/* eslint-disable-next-line react/prop-types*/}
          {props.children}
        </a>
        {props.href?.startsWith('https://storage.squadcast') && props.href?.endsWith('.mp3') && (
          <audio
            controls
            style={{
              marginTop: '5px',
            }}
          >
            <source src={props.href} type="audio/mpeg" />
          </audio>
        )}
      </>
    );
  };

  return (
    <Grid flexWidth={12}>
      <Grid type="column" className="incident_details__block">
        <Grid type="column" className="mt-10 ml-10">
          <div style={{ color: 'var(--shades-cement)', textDecoration: 'none' }}>
            <ReactMarkdown
              className="incident_details__mde"
              plugins={[breaks, gfm]}
              renderers={{ link: renderMdLink, code: CustomCodeRenderer }}
              source={description}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
