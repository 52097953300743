import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { REQUEST_USER_ORG, REQUEST_USER_ORG_FAIL } from '../../const/organization';
import { onRequestUserOrganizationSuccess } from '../../actions/organization/initUserOrg';
import { getUserOrganization } from '../../api/organization/getOrganization';
import { IOrganization } from '../../interfaces/IOrganization';

const getUserOrganizationsEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_USER_ORG),
    map(action => action),
    switchMap(() => {
      return getUserOrganization().pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ result, organizations }: { result: boolean; organizations: IOrganization[] }) => {
          return onRequestUserOrganizationSuccess(organizations);
        }),
        catchError(error =>
          of({
            type: REQUEST_USER_ORG_FAIL,
            payload: error.xhr,
            error: true,
          }),
        ),
      );
    }),
  );

export default getUserOrganizationsEpic;
