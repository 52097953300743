import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { Box, Button, HStack, Text } from '@chakra-ui/react';

import { getDateTimeFormat } from '../../helpers/helpers.date';
import { datepickerStyles } from './datepicker.styles';

type Props = Omit<ReactDatePickerProps, 'selected' | 'onChange'> & {
  date: Date;
  setDate: (date: Date) => void;
};

function SchedulesDatepicker({ date, setDate, ...args }: Props) {
  const getYear = (date: Date) => date.getFullYear();
  const getMonthName = (date: Date) => getDateTimeFormat(date, 'MMM');

  return (
    <Box sx={datepickerStyles}>
      <DatePicker
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <HStack mx={3} my={1}>
            <Text color="gray.700" fontWeight="semibold" fontSize="sm">
              {getMonthName(date)}
            </Text>
            <Text color="gray.700" fontWeight="semibold" fontSize="sm">
              {getYear(date)}
            </Text>

            <Button
              variant="link"
              minW={3}
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </Button>

            <Button
              variant="link"
              minW={3}
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </Button>
          </HStack>
        )}
        {...args}
        selected={date}
        onChange={setDate}
      />
    </Box>
  );
}

export default SchedulesDatepicker;
