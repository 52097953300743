import React, { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API } from '../../../../../core/api';
import { queryKeys, reactQueryConfig } from '../contants/service.contant';

const alertSourceListFunc = async () => {
  const { data } = await axios.get(`${API.config.endpoint}/public/integrations`);
  return data.data;
};
export function useAlertSourceList() {
  const { data, isSuccess, isError } = useQuery(
    queryKeys.ALERTSOURCELISTDATA,
    () => alertSourceListFunc(),
    {
      enabled: true,
      retry: 1,
    },
  );
  const alertSourceList = data;
  return {
    alertSourceList,
    isSuccess,
    isError,
  };
}
