import * as React from 'react';
import { SVGProps } from 'react';

const SvgLinearLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Linear_Logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.Linear_Logo-20x20_svg__st0{fill:#5e6ad1}'}</style>
    <path
      className="Linear_Logo-20x20_svg__st0"
      d="M9.3 0c4.8 0 8.4 2.5 10 6.3 1.5 3.7.6 8-2.1 10.8-.4.4-.7.5-1.2 0-4.3-4.4-8.7-8.7-13-13-.5-.5-.4-.7 0-1.2C4.9 1 7.3 0 9.3 0zM1.5 4.8c.2.1.3.2.4.3L14.8 18c.1.1.5.3.2.6-.4.2-.7.7-1.3.4l-.2-.2L1.2 6.5C1 6.3.7 6.1.9 5.7c.2-.3.3-.6.6-.9zM.2 7.9c4 3.9 7.9 7.9 11.8 11.8-1 .5-1.4.4-2.3-.4C6.9 16.4 4 13.4 1 10.6.2 9.8-.3 9 .2 7.9z"
    />
    <path
      d="M7.9 19.7C4.5 19.5.7 15.7.2 12c2.5 2.6 5.2 5.2 7.7 7.7z"
      style={{
        fill: '#5d69d1',
      }}
    />
  </svg>
);

export default SvgLinearLogo20X20;
