import * as React from 'react';
import { SVGProps } from 'react';

const SvgFreshserviceLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M10 0h7.5C18.9 0 20 1.1 20 2.5V10c0 5.5-4.5 10-10 10S0 15.5 0 10 4.5.1 10 0z"
      style={{
        fill: '#148fcc',
      }}
    />
    <path
      d="M9.9 15c.1.1.3.1.4-.1l3.2-4.4.7-1c.1-.2.1-.4-.1-.5-.1-.1-.1-.1-.3-.1H9.1l1.2-3.5c.1-.1 0-.3-.1-.4-.2 0-.3 0-.4.2L6.6 9.6l-.8.9c-.1.2-.1.4.1.5.1.1.1.1.3.1H11l-1.2 3.5c-.1.1-.1.3.1.4z"
      style={{
        fill: '#fff',
      }}
    />
  </svg>
);

export default SvgFreshserviceLogo;
