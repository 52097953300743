import { CheckCircleIcon } from '@chakra-ui/icons';
import { IIncident } from 'core/interfaces/IIncidents';
import { useCallback, useEffect } from 'react';
import { Grid, Para, SpinLoader, TextButton, Theme } from 'uie/components';
import { THEME_COLORS } from 'library/theme/colors';
import { Flex } from '@chakra-ui/react';
import { NewTabIcon } from 'icons';
import { useServiceNow } from './hooks';
import { IIncidentServiceNowMapping } from 'core/interfaces/IExtensions';

const { theme } = Theme;

export interface ServiceNowTriggerProps {
  incident: IIncident;
  getIncidentDetails: () => void;
  getIncidentMapping: () => void;
}

const renderCreateIncidentButton = (
  loading: string | undefined,
  handleIncidentCreate: () => void,
) => (
  <Grid justifyContent="space-around" width="100%">
    <TextButton
      buttonType="inverted"
      height="36px"
      onClick={handleIncidentCreate}
      disabled={loading === 'incident'}
      style={{ padding: '0 44px' }}
    >
      <Para fontWeight={500} fontSize={18} color={theme.primary.default}>
        Create an Incident
      </Para>
    </TextButton>
  </Grid>
);

const renderIncidentMapping = (incidentMapping: IIncidentServiceNowMapping) => (
  <Para>
    A ServiceNow Incident has already been created{' '}
    <a
      className="installation-guide-design"
      target="_blank"
      rel="noopener noreferrer"
      href={`${incidentMapping.servicenow_incident_link}`}
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: '5px',
      }}
    >
      (#...{incidentMapping.servicenow_incident_number.slice(-3)})
      <NewTabIcon />
    </a>
  </Para>
);

function ServiceNowTrigger(props: ServiceNowTriggerProps) {
  const { loading, config, createIncident, successfullyCreated, errorMessage, incidentMapping } =
    useServiceNow(props);

  const handleIncidentCreate = useCallback(() => {
    if (config) createIncident(config);
  }, [config]);

  let content = null;

  if (loading === 'config')
    content = (
      <Grid justifyContent="space-around" width="100%">
        <SpinLoader />
      </Grid>
    );
  else if (incidentMapping) content = renderIncidentMapping(incidentMapping);
  else if (errorMessage) content = <Para>{errorMessage}</Para>;
  else if (!successfullyCreated)
    content = renderCreateIncidentButton(loading, handleIncidentCreate);
  else
    content = (
      <Flex alignItems="flex-start" gap={2.5}>
        <CheckCircleIcon color={THEME_COLORS.brand.darkGreen} fontSize={30} mt={1} />
        <Para>A new incident has been created in Servicenow</Para>
      </Flex>
    );
  return <Grid className="mt-20 mb-20">{content}</Grid>;
}

export default ServiceNowTrigger;
