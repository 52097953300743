import { SearchActionKind } from '../constants/status.constants';

// An interface for our actions
interface SearchAction {
  type: SearchActionKind;
  queryPageIndex?: number;
  queryPageSize?: number;
  drawerType?: string | null;
  maintenanceId?: number;
  searchText?: string;
  isEnabled?: boolean;
}

interface MaintenanceListState {
  search: {
    searchText: string;
    enabled: boolean;
  };
  pagination: {
    queryPageIndex: number;
    queryPageSize: number;
  };
  drawer: {
    isDrawerOpen: boolean;
    drawerType: string | null;
    maintenanceId: number;
  };
  deleteMaintenace: {
    showModal: boolean;
    selectedMaintenanceId: number;
  };
  filter: {
    month: number;
    year: number;
  };
}

export function MaintenanceListReducer(state: MaintenanceListState, action: SearchAction) {
  const {
    type,
    searchText = '',
    isEnabled = false,
    queryPageIndex = 1,
    queryPageSize = 10,
    drawerType = null,
    maintenanceId = -1,
  } = action;
  switch (type) {
    case SearchActionKind.SEARCH_ENABLED_OR_DISABLED:
      return {
        ...state,
        search: {
          ...state.search,
          enabled: isEnabled,
        },
      };
    case SearchActionKind.SEARCHED:
      return {
        ...state,
        search: {
          ...state.search,
          searchText: searchText,
        },
        pagination: {
          ...state.pagination,
          queryPageIndex: 1,
        },
      };
    case SearchActionKind.PAGE_INDEX_CHANGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          queryPageIndex,
        },
      };
    case SearchActionKind.PAGE_SIZE_CHANGED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          queryPageSize,
          queryPageIndex: 1,
        },
      };

    case SearchActionKind.OPEN_DRAWER:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          drawerType,
          isDrawerOpen: true,
          maintenanceId,
        },
      };

    case SearchActionKind.CLOSE_DRAWER:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          drawerType: null,
          isDrawerOpen: false,
          maintenanceId: -1,
        },
      };
    case SearchActionKind.SELECT_PAGE_DELETE:
      return {
        ...state,
        deleteMaintenace: {
          showModal: true,
          selectedMaintenanceId: maintenanceId,
        },
      };

    case SearchActionKind.REJECT_PAGE_DELETE:
      return {
        ...state,
        deleteMaintenace: {
          showModal: false,
          selectedMaintenanceId: -1,
        },
      };

    case SearchActionKind.DECREASE_MAINTENANCE_MONTH:
      const prevMonth = state.filter.month === 0 ? 11 : state.filter.month - 1;
      const prevYear = state.filter.month === 0 ? state.filter.year - 1 : state.filter.year;
      return {
        ...state,
        filter: {
          month: prevMonth,
          year: prevYear,
        },
      };
    case SearchActionKind.INCREAMENT_MAINTENANCE_MONTH:
      const nextMonth = state.filter.month === 11 ? 0 : state.filter.month + 1;
      const nextYear = state.filter.month === 11 ? state.filter.year + 1 : state.filter.year;
      return {
        ...state,
        filter: {
          month: nextMonth,
          year: nextYear,
        },
      };
    case SearchActionKind.RESET_MAINTENANCE_MONTH:
      return {
        ...state,
        filter: {
          month: new Date().getMonth(),
          year: new Date().getFullYear(),
        },
      };
    default:
      return state;
  }
}
