import React from 'react';
import { Pie, DefaultRawDatum } from '@nivo/pie';
import { Theme } from 'uie/components';
import { ResponsiveWrapper } from '@nivo/core';

const { theme } = Theme;

interface IProps {
  data: DefaultRawDatum[];
}

const PiePanel: React.FC<IProps> = ({ data }) => {
  return (
    <ResponsiveWrapper>
      {({ width }) => (
        <Pie
          data={data}
          width={width}
          height={250}
          enableArcLinkLabels={false}
          margin={{ top: 30, right: 100, bottom: 30, left: 10 }}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 80,
              translateY: -10,
              itemsSpacing: 2,
              itemWidth: 80,
              itemHeight: 18,
              itemTextColor: theme.shades.black,
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 14,
              symbolShape: 'circle',
            },
          ]}
        />
      )}
    </ResponsiveWrapper>
  );
};

export default PiePanel;
