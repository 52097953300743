import React from 'react';
import { Box } from '@chakra-ui/react';
import DataTable from '../../../components/Table';
import { IPageSubscriberList } from '../../../Interface';
import { tableColumns } from './columns';

type action = (id: string) => void;
interface IProps {
  list: IPageSubscriberList[];
  pageChange: (queryPageIndex: number) => void;
  pageSizeChange: (queryPageSize: number) => void;
  queryPageIndex: number;
  queryPageSize: number;
  totalCount: number;
}

const SubscriberList = (props: IProps) => {
  const { queryPageIndex, queryPageSize, pageSizeChange, pageChange, totalCount } = props;
  return (
    <Box>
      <DataTable
        data={props.list}
        columns={tableColumns}
        paginationProps={{
          queryPageIndex,
          queryPageSize,
          totalCount: totalCount || 0,
          pageChange: (queryPageIndex: number) => pageChange(queryPageIndex),
          pageSizeChange: (queryPageSize: number) => {
            pageSizeChange(queryPageSize);
          },
        }}
      />
    </Box>
  );
};

export default SubscriberList;
