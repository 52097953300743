import { API } from 'core';
import { useReducer } from 'react';

import { reactQueryConfig } from '../constants/status.constants';
import {
  useExportStatusPageSubscribersQuery,
  useListStatusPageSubscribersQuery,
} from '../graphql/query';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/helper.toast';
import { ISubscribersMeta } from '../Interface';
import { SubscribersListReducer } from '../reducers/SubscribersListReducer';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_SUBSCRIBERS_EXPORTED } from 'core/const/tracker';

export function useSubscribersList(pageId: number) {
  const [state, dispatch] = useReducer(SubscribersListReducer, {
    search: {
      searchText: '',
      enabled: false,
    },
    pagination: {
      queryPageIndex: 1,
      queryPageSize: 5,
    },
  });
  const subscribersData = useListStatusPageSubscribersQuery(
    {
      input: {
        pageID: pageId,
        search: state.search.searchText,
        pageNumber: state.pagination.queryPageIndex,
        pageSize: state.pagination.queryPageSize,
      },
    },
    { ...reactQueryConfig, onError: reactQueryConfigError('Get subscriber list') },
  );

  const { refetch } = useExportStatusPageSubscribersQuery(
    {
      pageID: pageId,
      teamID: API.config.teamId,
    },
    {
      onSuccess: (data: any) => {
        reactQueryConfigSuccess(
          data?.exportStatusPageSubscribers?.message || 'An email will be sent to your inbox soon',
        );

        AppTracker.track(T_WA_UP_STATUS_PAGE_V2_SUBSCRIBERS_EXPORTED);
      },
      enabled: false,
    },
  );

  return {
    list: subscribersData?.data?.listStatusPageSubscribers?.data || [],
    meta: (subscribersData?.data?.listStatusPageSubscribers?.meta || {}) as ISubscribersMeta,
    isLoading: subscribersData.isLoading,
    isFetched: subscribersData.isFetched,
    isError: subscribersData.isError,
    isSuccess: subscribersData.isSuccess,
    refetch: subscribersData.refetch,
    state,
    dispatch,
    onSubscribersListExport: refetch,
  };
}
