import { SearchActionKind } from '../../contants/service.contant';

export type sort_by = 'name' | 'mtta' | 'mttr' | 'status' | 'open_incidents';

export type sort_order = 'asc' | 'des';

// An interface for our actions
interface SearchAction {
  type: SearchActionKind;
  searchText?: string;
  isEnabled?: boolean;
  queryPageIndex?: number;
  queryPageSize?: number;
  isDrawerOpen?: boolean;
  drawerType?: 'MAINTENANCE' | 'TAGS' | null | string;
  serviceID?: string | null;
  sort_by?: sort_by | string;
  sort_order?: sort_order | string;
  checkedRows?: Array<string>;
  resetState?: number;
  isFilterEnabled?: boolean;
  filterText?: string;
}

// An interface for our state
interface ServiceListState {
  search: {
    searchText: string;
    enabled: boolean;
  };
  filters: {
    filterText: string;
    enabled: boolean;
  };
  pagination: {
    queryPageIndex: number;
    queryPageSize: number;
  };
  drawer: {
    isDrawerOpen: boolean;
    drawerType: 'MAINTENANCE' | 'TAGS' | null | string;
    serviceID?: string | null;
  };
  sorting: { sort_by: sort_by | string; sort_order: sort_order | string };
  isChecked: {
    checkedRows: Array<string> | null;
  };
  reset: {
    resetState: number;
  };
}

export function ServiceListReducer(state: ServiceListState, action: SearchAction) {
  const {
    type,
    isEnabled = false,
    searchText = '',
    queryPageIndex = 1,
    queryPageSize = 10,
    drawerType = null,
    isDrawerOpen = false,
    serviceID = null,
    sort_by = 'name',
    sort_order = 'asc',
    checkedRows = null,
    resetState = 0,
    isFilterEnabled = false,
    filterText = '',
  } = action;
  switch (type) {
    case SearchActionKind.SEARCH_ENABLED_OR_DISABLED:
      return {
        ...state,
        search: {
          ...state.search,
          enabled: isEnabled,
        },
      };
    case SearchActionKind.SEARCHED:
      return {
        ...state,
        search: {
          ...state.search,
          searchText: searchText,
        },
      };

    case SearchActionKind.PAGE_INDEX_CHANGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          queryPageIndex,
        },
      };
    case SearchActionKind.PAGE_SIZE_CHANGED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          queryPageSize,
        },
      };

    case SearchActionKind.OPEN_DRAWER:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          drawerType,
          isDrawerOpen,
          serviceID,
        },
      };

    case SearchActionKind.CLOSE_DRAWER:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          drawerType: null,
          isDrawerOpen: false,
          serviceID: null,
        },
      };

    case SearchActionKind.SORTING:
      return {
        ...state,
        sorting: {
          ...state.sorting,
          sort_by,
          sort_order,
        },
      };

    case SearchActionKind.ROW_SELECTION:
      return {
        ...state,
        isChecked: {
          ...state.isChecked,
          checkedRows,
        },
      };

    case SearchActionKind.RESET_DATA:
      return {
        ...state,
        reset: {
          ...state.reset,
          resetState: state.reset.resetState + 1,
        },
      };
    case SearchActionKind.FILTER_ENABLED_OR_DISABLED:
      return {
        ...state,
        filters: {
          ...state.filters,
          enabled: isFilterEnabled,
        },
      };
    case SearchActionKind.FILTERED:
      return {
        ...state,
        filters: {
          ...state.filters,
          filterText: filterText,
        },
      };

    default:
      return state;
  }
}
