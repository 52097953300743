import { useQuery } from 'react-query';
import LCRService from 'core/services/service.lcr';
import { useLCRStore } from './lcr.state';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_LCR__LOGS_SEARCH_PERFORMED } from 'core/const/tracker';
import { useHistory } from 'react-router';
import { LCR_PATH } from 'views/main/routes/routes';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';

async function getLCRDetails(id: string) {
  const _extensionService = new LCRService();
  const { data } = await _extensionService.getLCRDataById(id);

  setUserAccessStore({
    entityACLMap: {
      ...getUserAccessStore().entityACLMap,
      routing_numbers: {
        ...getUserAccessStore().entityACLMap?.routing_numbers,
        ...(data?.metadata?.acl ?? {}),
      },
    },
  });

  return data;
}
const useGetLCRDetails = (id: string) => {
  const history = useHistory();
  return useQuery(`lcr-details`, () => getLCRDetails(id), {
    onSuccess: data => {
      useLCRStore.getState().setLCRDataById(data);
    },
    onError: () => {
      history.push(LCR_PATH);
    },
    retry: 1,
    cacheTime: 0,
  });
};

async function getSessionDetails(
  id: string,
  pageIndex: number,
  pageSize: number,
  assigned_to: string[],
  service_id: string[],
  search: string,
) {
  const _extensionService = new LCRService();
  const { data } = await _extensionService.getSessionLogs(
    id,
    pageIndex + 1,
    pageSize,
    assigned_to,
    service_id,
    search,
  );
  return data;
}

const useGetSessionDetails = ({
  id,
  pageIndex,
  pageSize,
  assigned_to,
  service_id,
  search,
}: {
  id: string;
  pageIndex: number;
  pageSize: number;
  assigned_to: string[];
  service_id: string[];
  search: string;
}) => {
  return useQuery(
    ['lcr-session', pageIndex, pageSize, assigned_to, service_id, search],
    () => getSessionDetails(id, pageIndex, pageSize, assigned_to, service_id, search),

    {
      onSuccess: () => {
        if (search.length) {
          AppTracker.track(T_WA_UP_LCR__LOGS_SEARCH_PERFORMED, {
            'LCR ID': id,
          });
        }
      },
      retry: 0,
      cacheTime: 0,
    },
  );
};

async function getSessionDetailsById(routingNumber: string, sessionId: string) {
  const _extensionService = new LCRService();
  const { data } = await _extensionService.getSessionLogsById(routingNumber, sessionId);
  return data;
}

const useGetSessionsDetailsById = (routingNumber: string, sessionId: string) => {
  return useQuery(
    `lcr-session-${routingNumber}-${sessionId}`,
    () => getSessionDetailsById(routingNumber, sessionId),

    {
      enabled: !!sessionId,
      onSuccess: data => {
        useLCRStore.getState().setLCRSessionDataById(data);
      },
    },
  );
};

export { useGetLCRDetails, useGetSessionDetails, useGetSessionsDetailsById };
