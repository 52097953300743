import { IAppPreviewType } from '../interfaces/IAppPreview';
import { addUserToPreview, removeUserFromPreview, addToAppPreview } from '../actions/appPreview';
import { Dispatch } from 'react';

/**
 * @description Adds AppPreview tokens to redux
 */
class AppPreview {
  private _dispatcher: any = () => {
    throw new Error('Store Not Inited');
  };

  public init = (dispatcher: Dispatch<any>) => {
    this._dispatcher = dispatcher;
  };

  public enablePreview = (previewType: IAppPreviewType) =>
    this._dispatcher(addUserToPreview(previewType));

  public disablePreview = (previewType: IAppPreviewType) =>
    this._dispatcher(removeUserFromPreview(previewType));

  /**
   * @param enabledPreview enable selectable previews from IAppPreviewTypes[]
   */
  public addToAppPreview = (enabledPreview: IAppPreviewType[]) =>
    this._dispatcher(addToAppPreview(enabledPreview));
}

const appPreview = new AppPreview();
export { appPreview as AppPreview };
