import { SeparatorProps } from './types';
import { Flex, Text, Divider } from '@chakra-ui/react';

function Separator(props: SeparatorProps) {
  const { text, showMargin } = props;
  return (
    <Flex justify={'center'} align={'center'} my={showMargin ? 2 : 0} gap={2}>
      <Divider height="1px" flex={1} />
      <Text mr={5} ml={5} variant="P_Bold">
        {text}
      </Text>
      <Divider height="1px" flex={1} />
    </Flex>
  );
}

export default Separator;
