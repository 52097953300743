import { HStack, useMultiStyleConfig } from '@chakra-ui/react';

import { SeeMore } from '../SeeMore';
import Tag, { KeyValueTag, PlainTag, TAG_VARIANTS, TagProps } from './Tag';

type KeyValueProps = {
  variant: TAG_VARIANTS.KEY_VALUE;
  tags: KeyValueTag[];
};
type PlainProps = {
  variant: TAG_VARIANTS.PLAIN;
  tags: PlainTag[];
};
export type TagVariants = KeyValueProps | PlainProps;
export type Props = TagVariants &
  Omit<TagProps, 'onClose'> & {
    defaultLength?: number;
    onClose?: (index: number) => void;
  };

type RenderTagProps = TagProps & {
  variant: TAG_VARIANTS;
  tag: KeyValueTag | PlainTag;
};
const RenderTag = ({ variant, tag, ...restProps }: RenderTagProps) => {
  if (variant === TAG_VARIANTS.KEY_VALUE) {
    return <Tag size="md" variant={variant} {...(tag as KeyValueTag)} {...restProps} />;
  }
  return <Tag size="md" variant={variant} {...(tag as PlainTag)} {...restProps} />;
};

const TagStack = ({ tags, defaultLength = 2, onClose, variant, ...restTagProps }: Props) => {
  const styles = useMultiStyleConfig('TagStack', {});

  if (!tags.length) return null;
  const viewTags = tags.slice(0, defaultLength);
  const remainingTags = tags.slice(defaultLength);

  return (
    <HStack sx={styles.container}>
      {viewTags.map((tagItem, index) => {
        return (
          <RenderTag
            tag={tagItem}
            variant={variant}
            key={index}
            {...restTagProps}
            onClose={onClose && (() => onClose(index))}
          />
        );
      })}
      {!!remainingTags.length && (
        <SeeMore
          items={remainingTags.map((tagItem, index) => {
            return (
              <RenderTag
                tag={tagItem}
                variant={variant}
                key={index}
                {...restTagProps}
                onClose={onClose && (() => onClose(index + defaultLength))}
              />
            );
          })}
        />
      )}
    </HStack>
  );
};

export default TagStack;
