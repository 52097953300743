import { Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useWorkflowWebhook } from 'views/main/organization/workflow/api/hook';
import { ManualTriggerWebhook } from 'views/main/organization/workflow/types';

interface IProps {
  action: ManualTriggerWebhook;
}
const TriggeredWebhookCard: FC<IProps> = ({ action }) => {
  const { data } = useWorkflowWebhook();
  const webhook = data
    ?.filter(webhook => webhook.id === action.data.id)
    .map(webhook => {
      return webhook.name;
    });

  return <Text pt={2}>{webhook}</Text>;
};

export default TriggeredWebhookCard;
