import { ArrowDownIcon, ArrowUpIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, Text } from '@chakra-ui/layout';
import { Check } from 'icons';
import { IconButton } from 'library/atoms';
import { ReactNode } from 'react';

import { useWorkflowFormContext } from '../../../context/workflowFormContext';
import { ActionListActionType } from '../../../types';

interface Props {
  order: number;
  isFirstStep: boolean;
  isLastStep: boolean;
  isEditHidden: boolean;
  children: ReactNode;
}

const ActionCardWrapper = ({ order, isFirstStep, isLastStep, children, isEditHidden }: Props) => {
  const { actionHandler, initEditActionForm } = useWorkflowFormContext();
  return (
    <Flex direction="row" flex={1} w="100%">
      <Flex direction="column" align="center">
        <Box>
          <Check width="20" height="20" />
        </Box>
        {!isLastStep ? (
          <Divider orientation="vertical" borderWidth="1px" borderStyle="dotted" />
        ) : null}
      </Flex>
      <Flex ml={3} direction="column" flex={1} mb={4}>
        <Flex alignItems="center" mb={2}>
          <Flex>
            <Text w="max-content">{`Action ${order + 1}`}</Text>
          </Flex>
          <Flex flex={1}>
            <Divider orientation="horizontal" borderWidth="1px" mx={2} />
          </Flex>
          <Flex gap={2}>
            <IconButton
              icon={<ArrowUpIcon />}
              aria-label="move up"
              hidden={isFirstStep}
              onClick={() => actionHandler(order, ActionListActionType.GO_PREV)}
            />
            <IconButton
              icon={<ArrowDownIcon />}
              aria-label="move down"
              hidden={isLastStep}
              onClick={() => actionHandler(order, ActionListActionType.GO_NEXT)}
            />
            <IconButton
              icon={<EditIcon />}
              hidden={isEditHidden}
              aria-label="edit"
              onClick={() => initEditActionForm(order)}
            />
            <IconButton
              icon={<DeleteIcon />}
              aria-label="delete"
              onClick={() => actionHandler(order, ActionListActionType.DELETE)}
            />
          </Flex>
        </Flex>
        {children}
      </Flex>
    </Flex>
  );
};

export default ActionCardWrapper;
