import { FocusBlock, Para, SelectBox } from 'uie/components';
import { getPriorityOption, PRIORITY, PRIORITY_OPTIONS } from 'library/common';
import { PRIORITY_COLORS, THEME_COLORS } from 'library/theme/colors';
import { PRIORITY_TYPE } from 'library/types';
import { FC } from 'react';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

type OptionProp = {
  value: PRIORITY_TYPE;
  label: string;
  showLabel: boolean;
};

type Props = {
  priority: PRIORITY;
  onChange: (priority: PRIORITY) => void;
  showFullOption?: boolean;
  applyMinHeight?: boolean;
  disablePermissionCheck?: boolean;
};

const PriorityOption: FC<OptionProp> = ({ value, label, showLabel }) => {
  const hasValue = !!value && value !== PRIORITY.Unset;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          padding: '3px 6px',
          backgroundColor: PRIORITY_COLORS(value),
          borderRadius: '3px',
          textAlign: 'center',
          marginRight: '8px',
          minWidth: '27px',
        }}
      >
        <Para
          fontSize={10.5}
          fontWeight={700}
          style={{ textAlign: 'center', lineHeight: '10.5px' }}
          color={hasValue ? THEME_COLORS.brand.white : THEME_COLORS.secondary[1000]}
        >
          {hasValue ? value : '-'}
        </Para>
      </div>
      {showLabel && (
        <Para color={THEME_COLORS.secondary[1000]} fontSize={14.5}>
          {label}
        </Para>
      )}
    </div>
  );
};
export const PrioritySelectBox: FC<Props> = ({
  priority,
  onChange,
  showFullOption = true,
  applyMinHeight = false,
  disablePermissionCheck = false,
}) => {
  const selectedOption = getPriorityOption(priority);
  const { hasUpdateAccess } = useUserAccess();
  const canUpdate = disablePermissionCheck ? true : hasUpdateAccess('incidents');

  const onPriorityChange = (val: PRIORITY) => {
    onChange(val);
  };

  return (
    <NoPermissionTooltip isDisabled={canUpdate}>
      <SelectBox
        hook={
          <PriorityOption
            value={selectedOption.value}
            label={selectedOption.label}
            showLabel={showFullOption}
          />
        }
        {...(applyMinHeight ? { buttonHeight: '24px' } : null)}
        onValueChange={(_: any, selection: PRIORITY) => {
          onPriorityChange(selection);
        }}
        height="auto"
        width="100%"
        disabled={!canUpdate}
      >
        {canUpdate
          ? PRIORITY_OPTIONS.map(({ value, label }) => {
              return (
                <FocusBlock
                  key={value}
                  value={value}
                  isSelected={selectedOption?.value === value}
                  style={{ opacity: 1 }}
                >
                  <PriorityOption value={value} label={label} showLabel={true} />
                </FocusBlock>
              );
            })
          : []}
      </SelectBox>
    </NoPermissionTooltip>
  );
};
