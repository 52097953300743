import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { REQUEST_ORG_TEAMS } from '../../const/organization';
import { getOrgTeams } from '../../api/organization/getTeams';
import { ITeam } from '../../interfaces/ITeams';
import {
  onRequestOrganizationTeamsSuccess,
  onRequestOrganizationTeamsFail,
} from '../../actions/organization/teams';

const getOrganizationsTeamsEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ORG_TEAMS),
    map(action => action.payload),
    switchMap(() => {
      return getOrgTeams().pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ result, data }: { result: boolean; data: ITeam[] }) => {
          return onRequestOrganizationTeamsSuccess(data);
        }),
        catchError(error => of(onRequestOrganizationTeamsFail(error.xhr))),
      );
    }),
  );

export default getOrganizationsTeamsEpic;
