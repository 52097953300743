import {
  Box,
  Button,
  Center,
  Circle,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { Field, FieldArray, Form, useFormikContext } from 'formik';
import React, { useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { SquadIcon, ProfileIcon, EscalationIcon, TeamIcon } from '../../../../../../icons/index';
import { serviceListUrl } from '../../contants/service.contant';
import TagGroup from '../../components/CustomDropdown/tagGroup';
import { useAllTagsList } from '../../hooks/useTags';
import { IServiceOwner } from '../../hooks/useServiceOwner';
import Select, { components, StylesConfig, GroupBase } from 'react-select';
import { OrganizationContext } from '../../index';
import { THEME_COLORS } from 'library/theme/colors';
import { Tooltip, usernameTooltipLabel } from 'library/atoms';
import { truncate } from 'core/helpers/stringUtils';
import { IUserInfo } from 'core/interfaces/IUserData';
import AptaConfigPanel from '../components/aptaConfigPanel';
import IagConfigPanel from '../components/iagConfigPanel';
import { DelayedNotificationsConfigPanel } from '../components/delay-notifications/delayedNotificationsConfigPanel';
import { DEFAULT_DELAY_NOTIFICATION_CONFIG } from '../components/delay-notifications/constant';
import { DelayNotificationConfig } from '../../types/service-detail.types';
import { isEmpty } from 'lodash';

export interface IServiceFormValues {
  owner_id: string | undefined;
  name: string;
  description: string;
  escalation_policy_id: string;
  tags: {
    key: string;
    value: string;
  }[];
  maintainer: {
    type: string;
    id: string;
  } | null;
  delay_notification_config_is_enabled: boolean;
  delay_notification_config?: DelayNotificationConfig;
}

interface Props {
  isEditMode?: boolean;
  serviceID?: string;
  setAptaRecommendedSelected?: (state: boolean) => void;
  onCancel?: () => void;
  isLoading?: boolean;
  serviceOwners: IServiceOwner[];
}

type Option = {
  value: any;
  label: any;
};

const actionElementHeight = 65;

const { Option } = components;

const styles: StylesConfig<Option, false, GroupBase<Option>> = {
  input: (provided, { selectProps: { inputValue, isMulti } }) => ({
    ...provided,
    ...(!(inputValue || isMulti) ? { position: 'absolute' } : {}),
  }),
};

const SingleValue = (props: any) => {
  const labelWithoutUsername = props.data.label.replace(` (${props.data.username})`, '');
  return (
    <components.SingleValue {...props}>
      <Tooltip
        label={usernameTooltipLabel(labelWithoutUsername, props.data.username)}
        isDisabled={!props.data.username}
        placement="top"
      >
        <Text>
          {truncate(labelWithoutUsername, 30)}
          {props.data.username && ` (${truncate(props.data.username, 30)})`}
        </Text>
      </Tooltip>
    </components.SingleValue>
  );
};

const IconOption = (props: any) => (
  <Option {...props}>
    <VStack alignItems={'left'}>
      <HStack>
        <Icon
          mr={2}
          as={
            props.data.type === 'user'
              ? ProfileIcon
              : props.data.type === 'squad'
              ? SquadIcon
              : props.data.type === 'team'
              ? TeamIcon
              : EscalationIcon
          }
        />
        <Text>{props.data.label}</Text>
      </HStack>
      {props.data.username && (
        <Text style={{ marginLeft: '32px', color: THEME_COLORS.secondary[1200] }}>
          {props.data.username}
        </Text>
      )}
    </VStack>
  </Option>
);

const getEntityOwnerValue = (props: any, options: any) => {
  if (!props.value) {
    const entity = options?.[0];
    if (!entity) return null;
    const usernameLabel = entity?.username
      ? `${entity.label} (${entity.username})`
      : `${entity.label}`;
    return {
      label: usernameLabel,
      value: entity?.id,
      type: entity?.type,
      username: entity?.username,
    };
  }
  const entity = options.find((u: any) => u.value === props.value?.id);
  if (!entity) return null;
  const usernameLabel = entity?.username
    ? `${entity.label} (${entity.username})`
    : `${entity.label}`;
  return {
    label: usernameLabel,
    value: entity?.id,
    type: entity?.type,
    username: entity?.username,
  };
};

export const checkDuplicateServiceTags = (tags: IServiceFormValues['tags']) => {
  const duplicateTagsCounter: Record<string, number> = {};
  tags?.forEach((tag: { key: string; value: string }, index: number) => {
    if (duplicateTagsCounter[tag.key]) {
      duplicateTagsCounter[tag.key] = duplicateTagsCounter[tag.key] + 1;
    } else {
      duplicateTagsCounter[tag.key] = 1;
    }
  });

  return duplicateTagsCounter;
};

export const validateServiceTags = (
  tags: IServiceFormValues['tags'],
  options = { ignoreEmptyTags: false },
) => {
  const duplicateTagsCounter = checkDuplicateServiceTags(tags);
  const validKey = new RegExp('^[a-zA-Z0-9_]+$');
  const tagErrors: string[] = [];

  tags?.forEach((tag: { key: string; value: string }, index: number) => {
    if (!tag.key && !tag.value) {
      if (!options?.ignoreEmptyTags) {
        tagErrors.push('Tag cannot be empty');
      } else {
        tagErrors.push('');
      }
    } else if (!tag.key && tag.value) {
      tagErrors.push('Tag key cannot be empty');
    } else {
      if (!validKey.test(tag.key)) {
        tagErrors.push('Invalid tag key (Should not contain special characters)');
      } else if (duplicateTagsCounter[tag.key] > 1) {
        tagErrors.push('Duplicate tag key');
      } else if (!tag.value) {
        tagErrors.push('Tag value cannot be empty');
      } else {
        tagErrors.push('');
      }
    }
  });

  return tagErrors;
};

export const validateForm = (values: IServiceFormValues) => {
  const error = {} as any;

  error.tags = [] as string[];
  if (!values['escalation_policy_id'])
    error['escalation_policy_id'] = 'Escalation policy is required!';
  if (!values['name']) error['name'] = 'Name is required!';
  if (!values['maintainer']?.id) error['maintainer'] = 'Owner is required!';

  if (values['tags']) {
    const tagErrorMessages = validateServiceTags(values['tags']);
    const tagsHasError = tagErrorMessages.filter(tag => tag !== '').length > 0;
    if (tagsHasError) {
      error.tags.push(...tagErrorMessages);
    } else {
      delete error.tags;
    }
  }
  if (values['delay_notification_config_is_enabled']) {
    const config: DelayNotificationConfig =
      values['delay_notification_config'] ?? DEFAULT_DELAY_NOTIFICATION_CONFIG;

    let isError = !!(
      !config.timezone ||
      !config.assigned_to.type ||
      (config.assigned_to.type !== 'default_escalation_policy' && !config.assigned_to.id)
    );

    if (!isError) {
      if (config.custom_timeslots_enabled) {
        isError = isEmpty(config.custom_timeslots);
      } else {
        isError =
          isEmpty(config.fixed_timeslot_config) ||
          !config.fixed_timeslot_config?.repeat_days.length;
      }
    }

    if (isError) {
      error['delay_notification_config'] = 'Please fill in all mandatory fields';
    }
  }
  return error;
};

const FormikForm = ({
  isEditMode,
  onCancel,
  isLoading,
  serviceOwners,
  serviceID,
  ...props
}: Props) => {
  const history = useHistory();
  const { data: allTags } = useAllTagsList();
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const {
    handleSubmit,
    touched,
    errors,
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  }: any = useFormikContext();
  const organization = useContext(OrganizationContext);
  const escalationPolicyData = organization?.escalations?.e;

  const OwnerSelect = React.memo((props: any) => {
    const options = [
      ...(organization?.users.u
        ? organization?.users?.u
            .filter(user => serviceOwners.find(owner => owner.user_id === user.id))
            .filter(user => user.role !== 'stakeholder')
            .sort((comparingElement: IUserInfo, compareWithElement: IUserInfo) =>
              comparingElement.first_name.localeCompare(compareWithElement.first_name),
            )
            .map(
              (
                { first_name, last_name, id, username_for_display: username }: IUserInfo,
                index: number,
              ) => {
                return {
                  value: id,
                  label: `${first_name} ${last_name}`,
                  username,
                  type: 'user',
                };
              },
            )
        : []),
      ...(organization?.squads?.s
        ? organization?.squads?.s
            .filter(squad => {
              return organization?.selectedTeam?.teamId === squad?.owner?.id;
            })
            .sort((comparingElement: any, compareWithElement: any) =>
              comparingElement.name.localeCompare(compareWithElement.name),
            )
            .map(({ name, id }: any, index: number) => {
              return { value: id, label: name, type: 'squad' };
            })
        : []),
    ];

    const handleChange = (value: any) => {
      props.onChange('maintainer', { id: value.value, type: value.type });
    };

    const onBlur = (value: any) => {
      props.touched('maintainer', true, true);
    };

    return (
      <div style={{ margin: '1rem 0' }}>
        <Select
          name={'maintainer'}
          id="maintainer"
          options={options}
          onChange={handleChange}
          value={getEntityOwnerValue(props, options)}
          components={{ Option: IconOption, SingleValue }}
          styles={styles}
        />
      </div>
    );
  });

  const EscalationSelect = React.memo((props: any) => {
    const options = escalationPolicyData
      ? escalationPolicyData?.map((el: any) => {
          return { value: el.id, label: el.name };
        })
      : [];

    const handleChange = (value: any) => {
      props.onChange('escalation_policy_id', value.value);
    };

    const onBlur = (value: any) => {
      props.touched('escalation_policy_id', true, true);
    };

    return (
      <div style={{ margin: '1rem 0' }}>
        <Select
          id="escalationPolicy"
          name="escalation_policy_id"
          placeholder="Select"
          options={options}
          onChange={handleChange}
          value={options.find(_ => _.value === props.value)}
          components={{ Option: IconOption }}
        />
        {props.error && <FormErrorMessage>{props.error}</FormErrorMessage>}
      </div>
    );
  });

  return (
    <Box height="100%" pos="relative" borderTop={isEditMode ? '1px solid #CBD5E0' : 'none'}>
      <Form
        onSubmit={handleSubmit}
        style={{
          height: `calc(100% - ${actionElementHeight}px)`,
          padding: isEditMode ? 24 : 10,
          paddingBottom: actionElementHeight,
          overflowY: 'auto',
        }}
      >
        <VStack spacing={4} align="flex-start" padding={isEditMode ? '0px' : '20px'}>
          <FormControl width={isEditMode ? '100%' : '80%'} isInvalid={!!errors.teamName}>
            <FormLabel>Team Name</FormLabel>
            <Input isReadOnly value={organization?.selectedTeam?.team?.name} bg="#EDF2F7" />
          </FormControl>
          <FormControl
            width={isEditMode ? '100%' : '80%'}
            isInvalid={!!errors.name && touched.name}
          >
            <FormLabel>
              <HStack>
                <Text>Service Name</Text>
                <Text color="red">*</Text>
              </HStack>
            </FormLabel>
            <Field as={Input} id="serviceName" name="name" type="Text" placeholder="Service Name" />
            {touched.name && errors?.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
          </FormControl>
          <FormControl width={isEditMode ? '100%' : '80%'}>
            <FormLabel>Service Description</FormLabel>
            <Field
              as={Textarea}
              id="serviceDescription"
              name="description"
              placeholder="Service Description"
            />
          </FormControl>
          <FormControl
            width={isEditMode ? '100%' : '80%'}
            isInvalid={!!errors.escalation_policy_id && touched.escalation_policy_id}
          >
            <FormLabel>
              <HStack>
                <Text>Escalation Policy</Text> <Text color="red">*</Text>
              </HStack>
            </FormLabel>
            <EscalationSelect
              value={values.escalation_policy_id}
              onChange={setFieldValue}
              error={errors.escalation_policy_id}
              touched={setFieldTouched}
              errored={setFieldError}
              isTouched={touched?.escalation_policy_id}
            />
          </FormControl>
          <FormControl
            width={isEditMode ? '100%' : '80%'}
            isInvalid={!values.maintainer && touched.maintainer}
          >
            <FormLabel>
              <HStack>
                <Text>Owner</Text> <Text color="red">*</Text>
              </HStack>
            </FormLabel>
            <OwnerSelect
              value={values.maintainer}
              onChange={setFieldValue}
              error={errors.maintainer}
            />
          </FormControl>
          <FormControl width="100%">
            <FormLabel>
              <HStack>
                <Text>Tags</Text>
              </HStack>
            </FormLabel>
            <VStack alignItems="flex-start" w="100%">
              <FieldArray
                name="tags"
                render={arrayHelpers =>
                  arrayHelpers.form.values?.tags?.map((_obj: any, index: number) => {
                    return (
                      <Box key={index}>
                        <FormControl
                          mb={2}
                          isInvalid={touched.tags && !!errors.tags && errors.tags.length}
                        >
                          <HStack w="lg" alignItems="flex-start">
                            <TagGroup
                              value={_obj}
                              keysOptions={allTags ? Object.keys(allTags) : []}
                              valuesMap={allTags ? allTags : {}}
                              handleChange={(key: string, value: string) => {
                                const clonedTagsState = values.tags ? [...values.tags] : [];
                                clonedTagsState[index].key = key;
                                clonedTagsState[index].value = value;
                                setFieldValue('tags', clonedTagsState);
                                setFieldTouched('tags', true);
                                setFieldValue(
                                  'duplicateTags',
                                  checkDuplicateServiceTags(clonedTagsState),
                                );
                              }}
                              errorMsg={errors?.tags?.[index] || ''}
                              duplicateTagKeys={
                                errors?.tags?.[index]?.length > 0 && values.duplicateTags
                              }
                            />
                            <HStack>
                              <Circle
                                size="12px"
                                bg={
                                  _obj.key && _obj.value
                                    ? 'rgba(0, 163, 196, 1)'
                                    : 'rgba(196, 196, 196, 1)'
                                }
                                color="white"
                              />
                              <CloseButton
                                onClick={e => {
                                  arrayHelpers.remove(index);
                                }}
                                fontSize={10}
                                color="#2D3748"
                                variant="ghost"
                                aria-label="Delete member"
                              />
                            </HStack>
                          </HStack>
                        </FormControl>
                      </Box>
                    );
                  })
                }
              />
              <Center w="lg">
                <Button
                  variant="outline"
                  onClick={() => {
                    const clonedTagsState = [...values.tags, { key: '', value: '' }];
                    setFieldValue('tags', clonedTagsState);
                  }}
                  leftIcon={<>+</>}
                >
                  Add Tag
                </Button>
              </Center>
            </VStack>
          </FormControl>

          {/* Delayed Notifications */}
          <Box height={2} />
          <FormControl width={isEditMode ? '100%' : '80%'}>
            <DelayedNotificationsConfigPanel
              serviceOwners={serviceOwners}
              isEnabled={values.delay_notification_config_is_enabled || false}
              delayConfig={values.delay_notification_config || DEFAULT_DELAY_NOTIFICATION_CONFIG}
              onToggle={() => {
                let delay_notification_config_is_enabled = false;

                if (!values.delay_notification_config_is_enabled) {
                  delay_notification_config_is_enabled = true;
                }

                setFieldValue(
                  'delay_notification_config_is_enabled',
                  delay_notification_config_is_enabled,
                );
              }}
              onDelayConfigUpdate={value => {
                setFieldTouched('delay_notification_config');
                setFieldValue('delay_notification_config', value);
              }}
              isEditMode={isEditMode}
              errorMsg={errors?.delay_notification_config ?? ''}
            />
            {touched.delay_notification_config && errors?.delay_notification_config && (
              <FormErrorMessage>{errors.delay_notification_config}</FormErrorMessage>
            )}
          </FormControl>

          {/* IAG */}
          <Box height={2} />
          <FormControl width={isEditMode ? '100%' : '80%'}>
            <IagConfigPanel
              isEnabled={values.iag_is_enabled || false}
              groupingWindow={values.iag_rolling_window_in_mins || 20}
              onToggle={() => {
                let iag_is_enabled = false;

                if (!values.iag_is_enabled) {
                  iag_is_enabled = true;
                  setFieldValue('iag_rolling_window_in_mins', 20);
                }

                setFieldValue('iag_is_enabled', iag_is_enabled);
              }}
              onChangeGroupingWindow={value => {
                setFieldValue('iag_rolling_window_in_mins', value);
              }}
              isEditMode={isEditMode}
            />
          </FormControl>

          {/* APTA */}
          <Box height={2} />
          <FormControl width={isEditMode ? '100%' : '80%'}>
            <AptaConfigPanel
              serviceID={serviceID}
              setAptaRecommendedSelected={props.setAptaRecommendedSelected}
              isEnabled={values.apta_is_enabled || false}
              timeoutWindow={values.apta_timeout_in_mins || 5}
              onToggle={() => {
                let apta_is_enabled = false;

                if (!values.apta_is_enabled) {
                  apta_is_enabled = true;
                  setFieldValue('apta_timeout_in_mins', 5);
                }

                setFieldValue('apta_is_enabled', apta_is_enabled);
              }}
              onChangeTimeoutWindow={value => {
                setFieldValue('apta_timeout_in_mins', value);
              }}
              isEditMode={isEditMode}
            />
          </FormControl>
        </VStack>

        <button ref={submitButtonRef} type="submit" hidden></button>
      </Form>

      <HStack
        width="100%"
        paddingY={4}
        paddingX={6}
        sx={{ borderTop: '1px solid #CBD5E0', position: 'absolute', bottom: 0 }}
      >
        <Button
          variant="default"
          size="sm"
          type="button"
          isLoading={isLoading}
          loadingText="Save Changes"
          onClick={() => {
            submitButtonRef.current?.click();
          }}
        >
          {isEditMode ? 'Save Changes' : 'Save and Continue'}
        </Button>
        <Button
          variant="outline"
          onClick={() => {
            isEditMode ? onCancel?.() : history.push(serviceListUrl);
          }}
        >
          Cancel
        </Button>
      </HStack>
    </Box>
  );
};
export default FormikForm;
