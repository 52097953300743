import React from 'react';
import { ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { Workflow } from '../../types/response/workflow';
import { ACTION_TITLE } from '../../constant';
import { Link, useLocation } from 'react-router-dom';

type ActionsIndexProps = {
  search?: string;
  setSearchFound: React.Dispatch<React.SetStateAction<boolean>>;
} & Pick<Workflow, 'actions'>;
const getActionsName = (trigger: Workflow['actions']) => {
  return (
    trigger?.map(action => ({
      displayName: ACTION_TITLE[action.name as keyof typeof ACTION_TITLE],
      id: action.name,
    })) ?? []
  );
};

const ActionsIndex: React.FC<ActionsIndexProps> = ({ actions, search, setSearchFound }) => {
  const path = useLocation();
  if (!actions) return null;
  if (!actions.length) return null;

  const actionName = getActionsName(actions);
  const items = [...actionName].filter(item => {
    if (!search) return true;
    return item.displayName.toLowerCase().includes(search.toLowerCase());
  });

  if (!items.length) {
    setSearchFound(false);
    return null;
  }
  setSearchFound(true);

  return (
    <>
      <Text pt={6} variant="h3" fontWeight="bold">
        ACTIONS
      </Text>
      <UnorderedList mt={0}>
        {items.map((item, index) => (
          <ListItem key={`${item.id}+ ${index}`} ml={5} color={'brand.blue'}>
            <Link to={`${path.pathname}#${encodeURIComponent(item.displayName)}`}>
              {item.displayName}
            </Link>
          </ListItem>
        ))}
      </UnorderedList>
    </>
  );
};

export default ActionsIndex;
