import { HStack, Text, VStack } from '@chakra-ui/react';
import FormField from 'library/molecules/FormField/FormField';
import { THEME_COLORS } from 'library/theme/colors';
import { useEffect, useMemo } from 'react';
import { components } from 'react-select';
import { getAvailableCountries } from 'views/main/organization/lcr/screens/create-or-update/helper';
import { LCRFormType } from 'views/main/organization/lcr/types/LCR.types';
import FormDropdown from '../FormDropdown/FormDropdown';
import { CountryType } from './type';

interface Props {
  error?: string;
  name: string;
  isRequired?: boolean;
  value?: LCRFormType['Country'];
  onMount?: (ownerOptions: CountryType[]) => void;
  onClearField?: () => void;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: any;
  disabled: boolean;
}
const EntityCountryDropdown = ({
  name,
  error,
  onClearField,
  placeholder,
  isRequired,
  style,
  value,
  onChange,
  disabled,
}: Props) => {
  const contryOptions: CountryType[] = useMemo(() => {
    const conuntries: CountryType[] = getAvailableCountries();

    return conuntries;
  }, []);

  const SingleValue = (props: any) => {
    return (
      <components.SingleValue {...props}>
        <HStack>
          <Text style={{ color: 'black' }}>{props.data.name}</Text>
          {props.data.username && <Text style={{ color: 'black' }}>({props.data.username})</Text>}
        </HStack>
      </components.SingleValue>
    );
  };

  return (
    <FormField
      label={`${name}${isRequired ? ' *' : ''}`}
      error={error}
      onClearField={onClearField}
      style={{ ...style }}
    >
      <FormDropdown<CountryType>
        options={contryOptions}
        name={name}
        value={value}
        isDisabled={disabled}
        getOptionLabel={option => option.name}
        onChange={(e: any) => {
          onChange(e);
        }}
        components={{ SingleValue }}
        getOptionValue={option => option.name}
        placeholder={placeholder ?? 'Select ...'}
        formatOptionLabel={option => (
          <VStack alignItems={'left'} spacing={0}>
            {option.name && (
              <Text
                style={{ color: value?.name === option.name ? THEME_COLORS.primary[100] : 'black' }}
              >
                {option.name}
              </Text>
            )}
          </VStack>
        )}
      />
    </FormField>
  );
};

export default EntityCountryDropdown;
