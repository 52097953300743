import * as React from 'react';
import { SVGProps } from 'react';

const SvgGhostInspector = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="ghost-inspector_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.ghost-inspector_svg__st2{fill:#fff}'}</style>
    <radialGradient
      id="ghost-inspector_svg__SVGID_1_"
      cx={10}
      cy={12}
      r={10}
      gradientTransform="matrix(1 0 0 -1 0 22)"
      gradientUnits="userSpaceOnUse"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#4beabd',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#00cc92',
        }}
      />
    </radialGradient>
    <path
      style={{
        fill: 'url(#ghost-inspector_svg__SVGID_1_)',
      }}
      d="M0 0h20v20H0z"
    />
    <defs>
      <path
        id="ghost-inspector_svg__SVGID_00000108987275312312786520000013907160443243399843_"
        d="M3 3.3h14v13.4H3z"
      />
    </defs>
    <clipPath id="ghost-inspector_svg__SVGID_00000164510223767155858970000008165023209334940604_">
      <use
        xlinkHref="#ghost-inspector_svg__SVGID_00000108987275312312786520000013907160443243399843_"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath:
          'url(#ghost-inspector_svg__SVGID_00000164510223767155858970000008165023209334940604_)',
      }}
    >
      <path
        className="ghost-inspector_svg__st2"
        d="M6.1 11.9c-.3-.7-.5-1.5-.6-2.3 0-.1-.1-.2-.2-.2s-.5.1-1.2.2c-.4.2-.5.4-.5.6-.1.3.1.7.4 1 .2.2.8.5 1.8 1H6.1v-.3z"
      />
      <path
        className="ghost-inspector_svg__st2"
        d="M16 9c-.2-.2-.6-.2-.9-.3-.1 0-.2 0-.2-.1-.1-2.6-2.3-4.7-4.9-4.7-2.7 0-4.9 2.2-4.9 4.9 0 2.3 1 4.6 2.6 6 1.4 1.2 2.9 1.5 4 1.5 1.4 0 2.4-.4 2.4-.4.1 0 .1-.1.1-.2s0-.2-.1-.2c0 0-1.1-.5-1.1-1.9 0-.9.3-1.3.8-1.8l.1-.1c.8-.2 1.5-.6 2.1-1.1.3-.2.4-.5.4-.8 0-.4-.1-.6-.4-.8z"
      />
      <radialGradient
        id="ghost-inspector_svg__SVGID_00000085250832844839651020000006087268632691544235_"
        cx={-565.131}
        cy={-37.981}
        r={0.373}
        gradientTransform="matrix(-18.1352 20.5017 -18.933 -16.7475 -10953.666 10956.548)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: '#fff',
            stopOpacity: 0,
          }}
        />
        <stop
          offset={0.823}
          style={{
            stopColor: '#fff',
            stopOpacity: 0.5,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#000',
          }}
        />
      </radialGradient>
      <path
        style={{
          opacity: 0.15,
          fill: 'url(#ghost-inspector_svg__SVGID_00000085250832844839651020000006087268632691544235_)',
        }}
        d="M16 9c-.2-.2-.6-.2-.9-.3-.1 0-.2 0-.2-.1-.1-2.6-2.3-4.7-4.9-4.7-2.7 0-4.9 2.2-4.9 4.9 0 2.3 1 4.6 2.6 6 1.4 1.2 2.9 1.5 4 1.5 1.4 0 2.4-.4 2.4-.4.1 0 .1-.1.1-.2s0-.2-.1-.2c0 0-1.1-.5-1.1-1.9 0-.9.3-1.3.8-1.8l.1-.1c.8-.2 1.5-.6 2.1-1.1.3-.2.4-.5.4-.8 0-.4-.1-.6-.4-.8z"
      />
      <path d="M5.6 12.3c-.1 0-.1 0 0 0l-2-1c-.4-.3-.6-.8-.5-1.1 0-.2.2-.5.6-.6s.8-.2 1.3-.3c.2 0 .3.1.4.3.1.8.3 1.6.6 2.3.1.2 0 .3-.2.4h-.2zm-.9-2.4c-.3.1-.6.1-.9.2-.1 0-.1 0-.2.1 0 .1.1.3.3.5.1.1.3.2 1.1.6-.2-.4-.3-.9-.3-1.4z" />
      <path d="M11.8 16.5c-1.2 0-2.8-.3-4.3-1.6-1.7-1.5-2.8-3.8-2.8-6.3 0-2.9 2.3-5.2 5.2-5.2 2.8 0 5.1 2.2 5.2 5h.2c.3.1.7.2 1 .3.3.2.5.5.5.8s-.1.6-.5.9c-.6.5-1.4.9-2.1 1.2l-.1.1c-.5.6-.8.9-.8 1.8 0 1.4 1 1.9 1 1.9.1.1.2.2.2.3s-.1.2-.2.3c0 0-.9.4-2.2.5h-.3zM5.4 8.7c0 2.3.9 4.5 2.5 5.8 1.2 1 2.6 1.5 4.2 1.4.5 0 1-.1 1.5-.2-.4-.4-.9-1-.9-2.1s.4-1.6.9-2.2c0-.1.1-.1.2-.2 0 0 .1-.1.2-.1.7-.2 1.4-.6 2-1.1.2-.1.3-.3.3-.4 0-.1-.1-.2-.2-.3-.2-.1-.5-.2-.8-.2-.3-.1-.4-.1-.6-.1-.1 0-.2-.2-.2-.3 0-2.5-2-4.6-4.6-4.6-2.5 0-4.5 2-4.5 4.6z" />
      <path d="M7.1 9.3c-.2 0-.4-.2-.4-.4v-.7c0-.2.2-.4.4-.4s.4.2.4.4v.7c.1.2-.1.4-.4.4zm3.1 0c-.2 0-.4-.2-.4-.4v-.7c0-.2.2-.4.4-.4s.4.2.4.4v.7c.1.2-.1.4-.4.4z" />
    </g>
  </svg>
);

export default SvgGhostInspector;
