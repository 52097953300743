import { IAppState } from '../interfaces/IAppState';
import { DEFAULT_ERROR } from '../const/defaults';

export const INITIAL_STATE: IAppState = {
  auth: {
    action: 'RESET_STATE',
    sessionId: '',
    invite: {
      first_name: '',
      last_name: '',
      email: '',
    },
    error: { ...DEFAULT_ERROR },
  },
  userInfo: {
    action: 'RESET_STATE',
    d: null,
    error: { ...DEFAULT_ERROR },
  },
  userOrganizations: {
    action: 'RESET_STATE',
    o: [],
    error: { ...DEFAULT_ERROR },
  },
  organization: {
    currentOrg: {
      o: null,
      error: { ...DEFAULT_ERROR },
    },
    currentUser: {
      u: null,
      error: { ...DEFAULT_ERROR },
    },
    users: {
      action: 'RESET_STATE',
      u: [],
      error: { ...DEFAULT_ERROR },
    },
    squads: {
      action: 'RESET_STATE',
      s: [],
      error: { ...DEFAULT_ERROR },
    },
    teams: {
      action: 'RESET_STATE',
      t: [],
      error: { ...DEFAULT_ERROR },
    },
    stakeholders: {
      action: 'RESET_STATE',
      stk: [],
      error: { ...DEFAULT_ERROR },
    },
    escalations: {
      action: 'RESET_STATE',
      e: [],
      error: { ...DEFAULT_ERROR },
    },
    services: {
      action: 'RESET_STATE',
      s: [],
      error: { ...DEFAULT_ERROR },
    },
    calendar: {
      action: 'RESET_STATE',
      c: [],
      onCall: {},
      error: { ...DEFAULT_ERROR },
    },
    plan: {
      action: 'RESET_STATE',
      p: null,
      error: { ...DEFAULT_ERROR },
    },
    integrations: {
      i: [],
      error: { ...DEFAULT_ERROR },
    },
    acl: {
      a: null,
      action: 'RESET_STATE',
      error: { ...DEFAULT_ERROR },
    },
    selectedTeam: {
      action: 'RESET_STATE',
      refresh: 0,
      team: null,
      children: null,
      teamId: '',
    },
    userPermissionRoles: {
      action: 'RESET_STATE',
      r: [],
    },
    runbooks: {
      action: 'RESET_STATE',
      runbook: [],
      error: { ...DEFAULT_ERROR },
    },
  },
  incidentRunbooks: {
    action: 'RESET_STATE',
    loading: false,
    runbook: [],
    error: { ...DEFAULT_ERROR },
  },
  incidentTask: {
    action: 'RESET_STATE',
    tasks: undefined,
    loading: false,
    error: { ...DEFAULT_ERROR },
  },
  roles: {
    r: [],
    error: { ...DEFAULT_ERROR },
  },
  integrations: {
    i: [],
    error: { ...DEFAULT_ERROR },
  },
  INTI_APP: {
    sessionId: '',
  },
  INIT_ORG: {
    orgId: '',
  },
  APP_ACTION: {
    action: 'RESET_STATE',
    act: null,
  },
  APP_CONFIG: {
    modal: 'closed',
    dashboard: null,
    incidentList: null,
    analyticsConfigs: null,
    sidebarState: 'collapsed',
  },
  APP_AB_TEST: {
    tests: [],
  },
  APP_PREVIEW: {
    preview: [],
    appPreviews: [],
  },
  APP_LIMITS: {
    message: '',
    description: false,
    time: -1,
  },
  APP_STATE: {
    incidentsFilterLength: 7,
    openIncident: {
      updateTime: '',
      updateMethod: '',
      incident: null,
    },
  },
};
