import { useEffect } from 'react';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { FormFooter } from '../../squadcast/form-footer';
import CreateIncidentForm from './CreateIncidentForm';
import { SlackCreateAction } from 'views/main/organization/workflow/types';
import { slackWorkFlowCreateChannelValidation } from 'views/main/organization/workflow/constant/validation';
import { useFormContext } from 'react-hook-form';
import DrawerFooterWrapper from 'library/molecules/Drawer';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';

const ChannelCreateContainer = () => {
  const { actionForm, onActionSubmit, checkDirtyAndCloseDrawer, setIsDirty } =
    useWorkflowFormContext();
  const { formState } = useFormContext<SlackCreateAction>();
  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <FormWrapper<SlackCreateAction>
      onSubmit={onActionSubmit}
      defaultValues={actionForm! as SlackCreateAction}
      validationSchema={slackWorkFlowCreateChannelValidation}
    >
      <CreateIncidentForm />{' '}
      <DrawerFooterWrapper>
        <FormFooter<SlackCreateAction> checkDirtyAndCloseDrawer={checkDirtyAndCloseDrawer} />
      </DrawerFooterWrapper>
    </FormWrapper>
  );
};

export default ChannelCreateContainer;
