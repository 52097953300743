import {
  ChakraProvider,
  Circle,
  HStack,
  Text,
  Container,
  Tag,
  TagLabel,
  TagCloseButton,
  extendTheme,
} from '@chakra-ui/react';
import libraryTheme from 'library/theme';
import chakraTheme from '../../schedules/theme';
import { Popover, usernameTooltipLabel } from 'library/atoms';
import { Owner } from '../hooks/useEntityOwnerFilter';
import SQTooltip from 'components/chakra/Tooltip';
import { truncate } from 'core/helpers/stringUtils';

export type SelectedFilterTag = {
  type: Owner;
  id: string;
  title: string;
  color?: string;
  username?: string;
};

interface Props {
  tags: SelectedFilterTag[];
  visibleTagsCount: number;
  onClose: (type: Owner, id: string) => void;
  applyChakraStyle: boolean;
}

export function SelectedFilterTags<T>({
  tags,
  visibleTagsCount,
  onClose,
  applyChakraStyle,
}: Props) {
  const renderTags = (tags: SelectedFilterTag[]) => {
    return tags.map((tag, index) => {
      const { type, id, title, username } = tag;
      const titleWithoutUsername = title.replace(`(${username})`, '');
      const truncatedTitle = truncate(title, 20);
      const truncatedUsername = username ? `(${truncate(username ?? '', 20)})` : '';
      const tagLabel = [truncatedTitle, truncatedUsername].join(' ');
      return (
        <SQTooltip key={index} text={usernameTooltipLabel(titleWithoutUsername, username)}>
          <Tag background="#EEF2F6" border="none" px={2} py={1} m={1}>
            <TagLabel>
              <Text isTruncated maxW={250}>
                {tagLabel}
              </Text>
            </TagLabel>
            <TagCloseButton onClick={() => onClose(type, id)} />
          </Tag>
        </SQTooltip>
      );
    });
  };
  const showTagsWhenWithinLimit = () => renderTags(tags);
  const showTagsWhenOverTheLimit = () => {
    const visibleTags = tags.slice(0, visibleTagsCount);
    const tagsInsidePopover = tags.slice(visibleTagsCount);
    return (
      <>
        {renderTags(visibleTags)}
        <Popover
          trigger={
            <Circle cursor="pointer" size="19px" bg="#C8E2FF">
              <Text variant="hint" color="#09305A">
                +{tagsInsidePopover.length}
              </Text>
            </Circle>
          }
          triggerType="hover"
          disablePortal
          hasArrow
        >
          <Container maxW="md" zIndex={50}>
            {renderTags(tagsInsidePopover)}
          </Container>
        </Popover>
      </>
    );
  };
  return (
    <ChakraProvider resetCSS={applyChakraStyle} theme={extendTheme(chakraTheme, libraryTheme)}>
      <HStack columnGap={1} w="100%" wrap="wrap" gap={2}>
        {tags.length <= visibleTagsCount ? showTagsWhenWithinLimit() : showTagsWhenOverTheLimit()}
      </HStack>
    </ChakraProvider>
  );
}
