import request from '../commons';
import { API } from '../../api';

function getOrgSquads() {
  const req = request.get(API.config.batman)(
    `organizations/${API.config.organizationId}/squads?owner_id=${API.config.teamId}`,
  );
  return req;
}

export { getOrgSquads };
