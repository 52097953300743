import { UnSnoozeType, UNSNOOZE_TYPES } from './type';

export const unSnoozeOption: UnSnoozeType[] = [
  {
    title: 'Reassign to the user who snoozed the incident',
    type: UNSNOOZE_TYPES.REASSIGN_TO_USER_WHO_SNOOZED,
  },
  {
    title: 'Reassign to me',
    type: UNSNOOZE_TYPES.REASSIGN_TO_SELF,
  },
  {
    title: 'Reassign to different user / squad / escalation policy',
    type: UNSNOOZE_TYPES.REASSIGN_TO_DIFFERENT_USER,
  },
];
