import Axios from 'axios';
import { IAttachmentRequest } from 'core/interfaces/ISignUpload';
import { API } from '../api';

class PublicMessageService {
  private api = API.config.endpoint;
  private organizationId = API.config.organizationId;
  private incidentId = '';

  constructor({ incidentId }: { incidentId: string }) {
    this.incidentId = incidentId;
  }

  public getAllMessages() {
    return Axios.get(
      `${this.api}/organizations/${this.organizationId}/incidents/${this.incidentId}/public-message/`,
    ).then(({ data }) => data);
  }

  public postMessage(payload: {
    message: string;
    status: string;
    statusPageId: string;
    attachments: IAttachmentRequest[];
  }) {
    return Axios.post(
      `${this.api}/organizations/${this.organizationId}/incidents/${this.incidentId}/public-message/`,
      payload,
    ).then(({ data }) => data);
  }
  public editMessage(payload: {
    messageId: string;
    message: string;
    status: string;
    statusPageId: string;
    attachments: IAttachmentRequest[];
  }) {
    const { messageId, ...restPayload } = payload;
    return Axios.put(
      `${this.api}/organizations/${this.organizationId}/incidents/${this.incidentId}/public-message/${payload.messageId}`,
      restPayload,
    ).then(({ data }) => data);
  }
  public deleteMessage(messageId: string) {
    return Axios.delete(
      `${this.api}/organizations/${this.organizationId}/incidents/${this.incidentId}/public-message/${messageId}`,
    ).then(({ data }) => data);
  }
}

export default PublicMessageService;
