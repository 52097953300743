import { Flex } from '@chakra-ui/react';
import FrameWorkDropdown from 'library/atoms/FrameworkDropdown';
import FormField from 'library/molecules/FormField/FormField';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useWorkflowWebhook } from 'views/main/organization/workflow/api/hook';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { ManualTriggerWebhook, WebhookOption } from 'views/main/organization/workflow/types';

const TriggerWebhookForm = () => {
  const { setIsDirty } = useWorkflowFormContext();
  const { data, isLoading } = useWorkflowWebhook();
  const {
    formState: { isDirty, errors },
    setValue,
    watch,
  } = useFormContext<ManualTriggerWebhook>();

  const webhookOptions = data
    ?.filter(webhook => webhook.trigger_type === 'manual')
    .map(webhook => {
      return { label: webhook.name, value: webhook.id };
    });
  const webhookId = watch('data.id');

  const webhookValue = webhookOptions?.find(webhook => webhook.value === webhookId);
  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);
  return (
    <Flex px={6} pt={4} flexDirection="column">
      <FormField label="Trigger a webhook (manually)" error={errors.data?.message}>
        <FrameWorkDropdown<WebhookOption>
          options={webhookOptions}
          isLoading={isLoading}
          onChange={o => {
            setValue('data.id', o!.value.toString(), { shouldDirty: true });
          }}
          value={webhookValue}
        />
      </FormField>
    </Flex>
  );
};

export default TriggerWebhookForm;
