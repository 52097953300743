import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useStateSyncedWithQueryParams<T>(
  initialState: T,
  paramsName: string,
  serialize: (state: T) => string,
  deserialize: (state: string) => T,
): [T, (state: T) => void] {
  const location = useLocation();
  const history = useHistory();
  const search = new URLSearchParams(location.search);

  const existingValue = search.get(paramsName);
  const [state, setState] = useState<T>(existingValue ? deserialize(existingValue) : initialState);

  useEffect(() => {
    const existingValue = search.get(paramsName);

    if (!existingValue) {
      setState(initialState);
    } else {
      setState(deserialize(existingValue));
    }
  }, [location.search, paramsName]);

  const onChange = (s: T) => {
    setState(s);
    const searchParams = new URLSearchParams(location.search);

    if (Array.isArray(s) && s.length === 0) {
      searchParams.delete(paramsName);
      const pathname = history.location.pathname;
      history.push({
        pathname,
        search: searchParams.toString(),
        hash: location.hash,
      });
      return;
    }
    if (typeof s === 'string' && s === '') {
      searchParams.delete(paramsName);
      const pathname = history.location.pathname;
      history.push({ pathname, search: searchParams.toString(), hash: location.hash });
      return;
    }
    searchParams.set(paramsName, serialize(s));
    const pathname = history.location.pathname;
    history.push({ pathname, search: searchParams.toString(), hash: location.hash });
  };

  return [state, onChange];
}
