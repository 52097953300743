import { Flex, HStack } from '@chakra-ui/react';
import { Input, ListingButton, useToast } from 'library/atoms';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { truncate } from '../../common/util';

import { useUpdateIncidentGroupMutation } from '../../graphql/mutation';
import { SavedFilter } from '../../interfaces/filters';
import { filterManager } from '../manager';
import { invalSavedFilters } from '../manager/util';

type Props = {
  filterData: SavedFilter;
  onClose: () => void;
};

export const RenameFilter = ({ filterData, onClose }: Props) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const [name, setName] = useState(filterData.name);

  const { mutateAsync: updateIncidentGroup, isLoading } = useUpdateIncidentGroupMutation();
  const renameFilter = () => {
    const prevName = filterData.name;
    const filters = filterManager.buildAPIFilterModel(filterData.filters);
    const payload = {
      incidentGroupID: filterData.id,
      name,
      filters,
    };

    updateIncidentGroup(payload)
      .then(() => {
        // applied filter renaming
        if (filterData.id === filterManager.getUIFilters().id) {
          filterManager.applyFilters(
            {
              ...filterData.filters,
              id: filterData.id,
              name,
            },
            queryClient,
          );
        }
        toast({
          status: 'success',
          text: `Success: '${truncate(prevName, 25)}' filter renamed to '${truncate(name, 25)}'`,
        });
        invalSavedFilters(queryClient);
        onClose();
      })
      .catch(error => {
        toast({
          status: 'error',
          text: `${error}` ?? `Error: ${truncate(prevName, 25)} could not be renamed`,
        });
      });
  };

  return (
    <Flex width="100%" justifyContent="space-between" p={4}>
      <Input
        mr={3}
        onChange={e => setName(e.target.value)}
        value={name}
        isDisabled={isLoading}
        autoFocus
      />
      <HStack spacing={2}>
        <ListingButton
          onClick={renameFilter}
          variant="secondary"
          type="submit"
          title="Save"
          isLoading={isLoading}
          isDisabled={!name}
        />
        <ListingButton
          isDisabled={isLoading}
          variant="secondary"
          onClick={onClose}
          title="Cancel"
        />
      </HStack>
    </Flex>
  );
};
