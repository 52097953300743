import * as React from 'react';
import { SVGProps } from 'react';

const SvgGraylog = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M10 16.6c3.6 0 6.6-3 6.6-6.6s-3-6.6-6.6-6.6-6.6 3-6.6 6.6 3 6.6 6.6 6.6zm0 3.4c5.5 0 10-4.5 10-10S15.5 0 10 0 0 4.5 0 10s4.5 10 10 10z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#f44040',
      }}
    />
    <path
      d="M10.5 5.6c.1-.2.3-.2.4 0l1.8 3.7c0 .1.1.1.2.1h1.5c.4 0 .6.3.6.6s-.3.6-.6.6h-2.3c-.1 0-.2 0-.2-.1L11 8.6l-1.6 5.8c-.1.2-.3.2-.4 0l-1.8-3.7c0-.1-.1-.1-.2-.1H5.5c-.4 0-.6-.3-.6-.6s.3-.6.6-.6h2.3c.1 0 .2 0 .2.1l1 1.9 1.5-5.8z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#697586',
      }}
    />
  </svg>
);

export default SvgGraylog;
