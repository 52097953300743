import { Box, Flex, HStack, Text, useBoolean, VStack } from '@chakra-ui/react';
import { IconButton, ListingButton, Radio, Tooltip, useToast } from 'library/atoms';
import { LibraryIcons } from 'library/icons';
import { CustomDrawerComponent } from 'library/molecules';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { copyURL, truncate } from '../../common/util';
import { SavedFilter } from '../../interfaces/filters';
import { FilterForm } from '../form';
import { clearIncidentFilterObj } from '../form/temp-store';
import { filterManager } from '../manager';
import { RenderFilterTags } from '../tags';
import { DeleteSavedFilter } from './DeleteSavedFilter';
import { RenameFilter } from './RenameFilter';

type Props = {
  filterData: SavedFilter;
  isSelected: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const FilterEntity = ({ filterData, isSelected, onChange }: Props) => {
  const { id, name, isPrivate, filters } = filterData;

  const toast = useToast();
  const queryClient = useQueryClient();

  const [isExpanded, setIsExpanded] = useBoolean();
  const [isFocused, setIsFocused] = useBoolean();
  const [isRenaming, setIsRenaming] = useBoolean();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditDrawerOpen, setIsEditModalOpen] = useState(false);

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const toggleEditDrawer = () => {
    setIsEditModalOpen(!isEditDrawerOpen);
  };

  const onEditFilterSuccess = useCallback(
    (savedFilter?: SavedFilter) => {
      // applied filter renaming
      if (savedFilter && filterData.id === filterManager.getUIFilters().id) {
        filterManager.applyFilters(
          {
            ...savedFilter.filters,
            id: savedFilter.id,
            name: savedFilter.name,
          },
          queryClient,
        );
      }
      onEditClose();
    },
    [filterData],
  );

  const onEdit = () => {
    clearIncidentFilterObj();
    toggleEditDrawer();
  };

  const onEditClose = useCallback(() => {
    clearIncidentFilterObj();
    setIsEditModalOpen(false);
  }, []);

  const cloneFilter = () => {
    toast({
      status: 'success',
      text: `Success: '${name}' URL copied to clipboard`,
    });
    copyURL(`/incident?filterId=${id}`);
  };

  return (
    <>
      <VStack
        alignItems="flex-start"
        onMouseEnter={setIsFocused.on}
        onMouseLeave={() => {
          setIsFocused.off();
          setIsRenaming.off();
        }}
        bgColor={isFocused ? 'primary.200' : 'white'}
      >
        {isFocused && isRenaming ? (
          <RenameFilter filterData={filterData} onClose={setIsRenaming.off} />
        ) : (
          <>
            {isPrivate && (
              <Box p={4} pb={0}>
                <Text variant="hint" color="brand.green">
                  Private
                </Text>
              </Box>
            )}
            <Flex
              width="100%"
              p={4}
              pt={isPrivate ? 0 : 4}
              mt="0 !important"
              alignItems="center"
              justifyContent="space-between"
            >
              <HStack columnGap={2} alignItems="center">
                <Radio value={id} isChecked={!!isSelected} onChange={onChange} />
                <Tooltip label={name}>
                  <Text variant="formInput_800" color="secondary.1000">
                    {truncate(name, 25)}
                  </Text>
                </Tooltip>
              </HStack>
              <HStack columnGap={1}>
                {isExpanded ? (
                  <ListingButton variant="secondary" title="Hide" onClick={setIsExpanded.off} />
                ) : (
                  <>
                    {isFocused && (
                      <>
                        <ListingButton
                          variant="secondary"
                          title="Rename Filter"
                          onClick={setIsRenaming.toggle}
                        />
                        <ListingButton
                          variant="secondary"
                          title="View"
                          onClick={setIsExpanded.on}
                        />
                      </>
                    )}
                  </>
                )}
              </HStack>
            </Flex>
          </>
        )}
        {isExpanded && (
          <VStack p={4} pt={0} alignItems="flex-start">
            <RenderFilterTags filtersData={filters} readOnly />
            <HStack py={2}>
              <Tooltip placement="bottom" label="Edit">
                <IconButton
                  aria-label="Edit Filter"
                  variant="secondary"
                  icon={<LibraryIcons.EditIcon />}
                  onClick={onEdit}
                />
              </Tooltip>
              <Tooltip placement="bottom" label="Copy URL">
                <IconButton
                  aria-label="Clone Filter"
                  variant="secondary"
                  icon={<LibraryIcons.CopyIcon />}
                  onClick={cloneFilter}
                />
              </Tooltip>
              <Tooltip placement="bottom" label="Delete">
                <IconButton
                  aria-label="Delete Filter"
                  variant="secondary"
                  icon={<LibraryIcons.DeleteIcon />}
                  onClick={() => toggleDeleteModal()}
                />
              </Tooltip>
            </HStack>
          </VStack>
        )}
      </VStack>
      <DeleteSavedFilter
        id={id}
        name={name}
        isOpen={isDeleteModalOpen}
        onClose={toggleDeleteModal}
      />
      <CustomDrawerComponent
        size="md"
        isOpen={isEditDrawerOpen}
        onClose={() => onEditClose()}
        title="Edit"
        disableBodyPadding
        showBackIcon
      >
        <FilterForm onClose={onEditClose} onSuccess={onEditFilterSuccess} filterObj={filterData} />
      </CustomDrawerComponent>
    </>
  );
};
