import axios from 'axios';
import { IAppState } from 'core/interfaces/IAppState';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppConfig } from 'shared/app.config';
import { ACL } from './types';
import { useUserAccessStore } from './UserAccessStore';
import PageLoaderComponent from '../../components/PageLoader';

const url = AppConfig.api_url;

export type UserAccessContextValue = {
  hasReadAccess: (entityType: keyof ACL) => boolean;
  hasCreateAccess: (entityType: keyof ACL) => boolean;
  hasDeleteAccess: (entityType: keyof ACL, id?: string) => boolean;
  hasUpdateAccess: (entityType: keyof ACL, id?: string) => boolean;
  hasUpdateOwnerAccess?: (entityType: keyof ACL, id?: string) => boolean;
};

export const UserAccessContext = createContext<UserAccessContextValue>({
  hasReadAccess: (entityType: keyof ACL) => false,
  hasCreateAccess: (entityType: keyof ACL) => false,
  hasDeleteAccess: (entityType: keyof ACL, id?: string) => false,
  hasUpdateAccess: (entityType: keyof ACL, id?: string) => false,
  hasUpdateOwnerAccess: (entityType: keyof ACL, id?: string) => false,
});

const getACL = async (organization: string, currentTeam: string) => {
  return axios.get<{ data: ACL }>(
    `${url}/v3/organizations/${organization}/teams/${currentTeam}/acl`,
  );
};

type Props = any;
export const UserAccessProvider = (props: PropsWithChildren<Props>) => {
  const organization = useSelector(
    (state: IAppState) => state.organization.currentOrg?.o?.organizationId,
  );
  const currentTeam = useSelector((state: IAppState) => state?.organization?.selectedTeam?.teamId);

  const obacEnabled = useSelector(
    (state: IAppState) => state.organization.currentOrg?.o?.config.obac_enabled ?? false,
  );

  const setTeamLevelACL = useUserAccessStore(store => store.setTeamLevelACL);
  const teamLevelACL = useUserAccessStore(store => store.teamLevelACL);
  const entityACLMap = useUserAccessStore(store => store.entityACLMap);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (organization && currentTeam) {
      setIsLoading(true);
      getACL(organization, currentTeam)
        .then(res => {
          setTeamLevelACL(res.data.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [organization, currentTeam]);

  const hasReadAccess = (entityType: keyof ACL) => {
    if (obacEnabled) return true;
    if (isLoading) return true;
    if (!teamLevelACL) return true;
    return teamLevelACL[entityType]?.read ?? false;
  };
  const hasCreateAccess = (entityType: keyof ACL) => {
    if (obacEnabled) {
      return teamLevelACL?.[entityType]?.create ?? false;
    }
    if (isLoading) return true;
    if (!teamLevelACL) return true;
    return teamLevelACL[entityType]?.create ?? false;
  };

  const hasDeleteAccess = (entityType: keyof ACL, id?: string) => {
    if (obacEnabled) {
      return id !== undefined && id !== null
        ? entityACLMap?.[entityType]?.[id]?.has_delete_access ?? false
        : teamLevelACL?.[entityType]?.delete ?? false;
    }
    if (!teamLevelACL) return true;
    return teamLevelACL?.[entityType]?.delete ?? false;
  };

  const hasUpdateAccess = (entityType: keyof ACL, id?: string) => {
    if (obacEnabled) {
      return id !== undefined && id !== null
        ? entityACLMap?.[entityType]?.[id]?.has_update_access ?? false
        : teamLevelACL?.[entityType]?.update ?? false;
    }
    if (!teamLevelACL) return true;
    return teamLevelACL?.[entityType]?.update ?? false;
  };

  const hasUpdateOwnerAccess = (entityType: keyof ACL, id?: string) => {
    if (obacEnabled) {
      return id !== undefined && id !== null
        ? entityACLMap?.[entityType]?.[id]?.has_update_owner_access ?? false
        : teamLevelACL?.[entityType]?.update ?? false;
    }
    if (!teamLevelACL) return true;
    return teamLevelACL?.[entityType]?.update ?? false;
  };

  return (
    <UserAccessContext.Provider
      value={{
        hasReadAccess,
        hasCreateAccess,
        hasDeleteAccess,
        hasUpdateAccess,
      }}
    >
      {!isLoading ? props.children : <PageLoaderComponent />}
    </UserAccessContext.Provider>
  );
};

export const UserAccessConsumer = UserAccessContext.Consumer;

export const useUserAccess = () => {
  return useContext(UserAccessContext);
};

export function withUserAccess<P>(Component: React.ComponentType<P>) {
  return (props: Omit<P, 'userAccess'>) => {
    return (
      <UserAccessConsumer>
        {value => <Component {...(props as P)} userAccess={value} />}
      </UserAccessConsumer>
    );
  };
}

export type PropsWithUserAccess<P> = P & { userAccess: UserAccessContextValue };

export default UserAccessContext;
