import { exception } from 'core';
import { API } from 'core/api';
import { T_WA_UP_LCR_CONFIG_CREATED } from 'core/const/tracker';
import LCRService from 'core/services/service.lcr';
import { useToast } from 'library/atoms';
import { useState } from 'react';
import { AppTracker } from 'shared/analytics/tracker';
import { COUNTRY_TYPE, LCRListState, ROUTING_TYPE } from '../types/LCR.types';
import { useLCRStore } from './lcr.state';

export const useLCRCreate = () => {
  const _lcrService = new LCRService();
  const toast = useToast();

  const setFormData = useLCRStore((state: any) => state.setFormData);
  const formState = useLCRStore((state: LCRListState) => state.formState);

  const [loadinNumber, setLoadingNumber] = useState<boolean>(false);
  const [creatingLCR, setCreatingLCR] = useState<boolean>(false);
  const [loadinNumberError, setLoadingNumberError] = useState('');

  const getUniqueRoutingNumber = async (country: string, phoneNumberType: string) => {
    const orgId = API.config.organizationId;

    try {
      setLoadingNumber(true);
      setLoadingNumberError('');
      const res = await _lcrService.getRoutingNumber(orgId, country, phoneNumberType);
      if (res?.data) {
        toast({
          status: 'success',
          text: `New routing number ‘${res?.data?.number}' successfully generated`,
        });
      }
      setFormData({
        ...formState,
        routingNumber: `+${res?.data?.number}`,
      });
    } catch (e: any) {
      toast({
        status: 'error',
        text: e.response?.data?.message,
      });
      exception.handle('E_GET_LCR_NUMBER_ERROR', e);
      setLoadingNumberError(e?.message);
    } finally {
      setLoadingNumber(false);
    }
  };

  const createLCR = async (params: any, cb: () => void) => {
    try {
      setCreatingLCR(true);
      const res = await _lcrService.createRotutingNumber(params);
      if (res?.data) {
        toast({
          status: 'success',
          text: `LCR has been successfully created`,
        });
        AppTracker.track(T_WA_UP_LCR_CONFIG_CREATED, {
          'LCR ID': res?.data?.id, //todo
        });
        cb();
      }
    } catch (error: any) {
      toast({
        status: 'error',
        text: error.response?.data?.message,
      });
      exception.handle('E_CREATE_LCR_ERROR', error);
    } finally {
      setCreatingLCR(false);
    }
  };
  return {
    getUniqueRoutingNumber,
    createLCR,
    loadinNumber,
    loadinNumberError,
    creatingLCR,
  };
};
