import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Tag,
  TagLabel,
  HStack,
  Flex,
  Tooltip,
  TagCloseButton,
  Container,
} from '@chakra-ui/react';
import moment from 'moment';
import CalendarRangePicker from '../../../../../components/CalendarRangePicker';
import { IGetSlo } from '../slo.interface';
import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { JSONToCSVConvertor } from '../slo.helper';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { ISelectedFilterOptions } from '../slo.interface';
import { tooltipStyle, visibleTagsCount } from '../slo.constants';
import { DownloadIcon } from '../../../../../icons';
import { T_WA_UP_SLO_LIST_DOWNLOADED } from '../../../../../core/const/tracker';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { DropdownOption, Option, Owner } from '../../owner-filters/hooks/useEntityOwnerFilter';
import OwnerDropdown from './ownerDropdown';
import { ActiveFilterTag } from '../../owner-filters/ActiveFilterTags';
import SQTooltip from 'components/chakra/Tooltip';
import { NodePopover } from 'components/chakra/Popover';
import { usernameTooltipLabel } from 'library/atoms/Tooltip';

export type IService = IAppState['organization']['services'];

interface IProps {
  sloData: IGetSlo | null;
  services: IService;
  getSloList: (selectedFilter: Array<string>, selectedFilterOpt: ISelectedFilterOptions) => void;
  filterList: Array<{ id: string; name: string }>;
  ownerQueryParam: string;
  ownerDropdownOptions: DropdownOption[];
  ownerDropdownChangeHandler: (values: Option) => void;
  ownerFilterTags: ActiveFilterTag[];
  onClickActiveOwnerFilterTag: (type: Owner, id: string) => void;
  resetOwnerFilters: () => void;
}

export const SLOListFilter = ({
  sloData,
  services,
  getSloList,
  filterList,
  ownerQueryParam,
  ownerDropdownOptions,
  ownerDropdownChangeHandler,
  ownerFilterTags,
  onClickActiveOwnerFilterTag,
  resetOwnerFilters,
}: IProps) => {
  const ref = useRef(null);
  const [selectedFilter, setFilter] = useState<Array<string>>(ownerQueryParam ? ['owner'] : []);
  const [selectedFilterOpt, setFilterOpt] = useState<ISelectedFilterOptions>({
    service: [],
    IsActive: [],
    dateTime: { fromDate: new Date(), toDate: new Date() },
  });
  const isActive = selectedFilter.some(filter => filter == 'IsActive');
  const isService = selectedFilter.some(filter => filter == 'service');
  const isDateTime = selectedFilter.some(filter => filter == 'dateTime');
  const isOwnerFilterSelected = selectedFilter.includes('owner') || ownerFilterTags.length > 0;

  const isActiveSelected = isActive && selectedFilterOpt.IsActive.length;
  const isServiceSelected = isService && selectedFilterOpt.service.length;
  const isDateTimeSelected =
    isDateTime && selectedFilterOpt.dateTime.fromDate && selectedFilterOpt.dateTime.toDate;

  useEffect(() => {
    getSloList && getSloList(selectedFilter, selectedFilterOpt);
    if (!selectedFilter.includes('owner')) {
      resetOwnerFilters();
    }
  }, [selectedFilterOpt, selectedFilter]);

  const renderServiceFilterTags = () => {
    const filterValues = services.s.filter(
      service =>
        Array.isArray(selectedFilterOpt.service) && selectedFilterOpt.service.includes(service.id),
    );
    const visibleTags = filterValues.slice(0, visibleTagsCount);
    const tagsInsidePopover = filterValues.slice(visibleTagsCount);
    return isServiceSelected ? (
      <HStack m={2} justifyContent="start" height="20px" direction="row">
        <Text>Service:</Text>
        {visibleTags.map((service, index) => (
          <Tag size={'sm'} key={'sm'} borderRadius="full" variant="solid" colorScheme="gray">
            <TagLabel>{service.name}</TagLabel>
          </Tag>
        ))}
        {tagsInsidePopover.length > 0 && (
          <NodePopover
            triggerType="hover"
            trigger={
              <Tag size={'sm'} borderRadius="full" variant="solid" colorScheme="gray">
                <TagLabel>+ {tagsInsidePopover.length}</TagLabel>
              </Tag>
            }
          >
            <Container maxW="md">
              {tagsInsidePopover.map((service, index) => (
                <Tag size={'sm'} key={'sm'} borderRadius="full" variant="solid" colorScheme="gray">
                  <TagLabel>{service.name}</TagLabel>
                </Tag>
              ))}
            </Container>
          </NodePopover>
        )}
      </HStack>
    ) : null;
  };

  const renderOwnerFilterTags = () => {
    const visibleTags = ownerFilterTags.slice(0, visibleTagsCount);
    const tagsInsidePopover = ownerFilterTags.slice(visibleTagsCount);
    return ownerFilterTags.length > 0 ? (
      <HStack m={2} justifyContent="start" height="20px" direction="row">
        <Text>SLO Owner:</Text>
        {visibleTags.map((tag, index) => {
          return (
            <SQTooltip
              text={usernameTooltipLabel(tag.value, tag.username)}
              key={index}
              placement="top"
            >
              <Tag size={'sm'} borderRadius="full" variant="solid" colorScheme="gray">
                <TagLabel maxW={100} isTruncated>
                  {tag.value}
                </TagLabel>
                <TagCloseButton
                  cursor="pointer"
                  onClick={() => onClickActiveOwnerFilterTag(tag.type, tag.id)}
                />
              </Tag>
            </SQTooltip>
          );
        })}
        {tagsInsidePopover.length > 0 && (
          <NodePopover
            triggerType="hover"
            trigger={
              <Tag size={'sm'} borderRadius="full" variant="solid" colorScheme="gray">
                <TagLabel>+ {tagsInsidePopover.length}</TagLabel>
              </Tag>
            }
          >
            <Container maxW="md">
              {tagsInsidePopover.map((tag, index) => {
                return (
                  <SQTooltip text={tag.value} key={index} placement="top">
                    <Tag m={1} size={'sm'} borderRadius="full" variant="solid" colorScheme="gray">
                      <TagLabel maxW={100} isTruncated>
                        {tag.value}
                      </TagLabel>
                      <TagCloseButton
                        cursor="pointer"
                        onClick={() => onClickActiveOwnerFilterTag(tag.type, tag.id)}
                      />
                    </Tag>
                  </SQTooltip>
                );
              })}
            </Container>
          </NodePopover>
        )}
      </HStack>
    ) : null;
  };

  return (
    <Box>
      <HStack height="70px" spacing={5} mb={2}>
        {
          <Text width="7%" fontSize={18} fontWeight="medium">
            {sloData ? (sloData.meta.total ? sloData.meta.total : sloData.slos.length) : 0} SLO(s)
          </Text>
        }
        <Flex w="93%" dir="row" alignItems="center" justifyContent="space-between">
          <Box mr={5}>
            <HStack ref={ref}>
              {
                <Menu closeOnSelect={true}>
                  <MenuButton
                    color={'black.200'}
                    textAlign={'start'}
                    p={2}
                    maxW="fit-content"
                    borderWidth="1px"
                    borderRadius="md"
                    minWidth="100px"
                  >
                    <AddIcon fontSize="10px" />
                    Add Filter
                  </MenuButton>
                  <MenuList minWidth="240px">
                    <MenuOptionGroup
                      onChange={filters_ => {
                        const filters = (Array.isArray(filters_) && filters_) || [];
                        setFilter(filters);
                      }}
                      value={selectedFilter}
                      type="checkbox"
                    >
                      {filterList.map(({ id, name }, index) => {
                        return (
                          <MenuItemOption key={index} value={id}>
                            {name}
                          </MenuItemOption>
                        );
                      })}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              }
              {isOwnerFilterSelected && (
                <Flex minW="155px">
                  <OwnerDropdown
                    dropdownOptions={ownerDropdownOptions}
                    onChangeHandler={ownerDropdownChangeHandler}
                  />
                </Flex>
              )}

              {isService && (
                <Menu closeOnSelect={false}>
                  <MenuButton
                    color={'gray.500'}
                    textAlign={'start'}
                    w="100%"
                    p={2}
                    borderWidth="1px"
                    borderRadius="md"
                  >
                    <HStack>
                      <Text>Services</Text> <ChevronDownIcon />
                    </HStack>
                  </MenuButton>
                  <MenuList minWidth="240px" maxH="160px" overflowY="scroll">
                    <MenuOptionGroup
                      onChange={service => {
                        Array.isArray(service)
                          ? setFilterOpt({ ...selectedFilterOpt, service: [...service] })
                          : setFilterOpt({ ...selectedFilterOpt, service: [] });
                      }}
                      value={selectedFilterOpt['service']}
                      type="checkbox"
                    >
                      {services.s.map(({ id, name }, index) => {
                        return (
                          <MenuItemOption key={index} value={id}>
                            {name}
                          </MenuItemOption>
                        );
                      })}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              )}
              {isActive && (
                <Menu closeOnSelect={false}>
                  <MenuButton
                    color={'gray.500'}
                    textAlign={'start'}
                    w="100%"
                    p={2}
                    borderWidth="1px"
                    borderRadius="md"
                  >
                    <HStack>
                      <Text>Status</Text> <ChevronDownIcon />
                    </HStack>
                  </MenuButton>
                  <MenuList minWidth="240px">
                    <MenuOptionGroup
                      onChange={item => {
                        typeof item == 'string' &&
                          setFilterOpt({ ...selectedFilterOpt, IsActive: [item] });
                      }}
                      value={selectedFilterOpt['IsActive'][0]}
                      type="radio"
                    >
                      {['Active', 'Inactive'].map((name, index) => {
                        return (
                          <MenuItemOption key={index} value={name}>
                            {name}
                          </MenuItemOption>
                        );
                      })}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              )}
              {isDateTime && (
                <Box minW="200px">
                  <CalendarRangePicker
                    startDate={selectedFilterOpt.dateTime.fromDate}
                    endDate={selectedFilterOpt.dateTime.toDate}
                    onUpdateRange={(fromDate: Date, toDate: Date) => {
                      return setFilterOpt({
                        ...selectedFilterOpt,
                        dateTime: { fromDate, toDate },
                      });
                    }}
                    minDate={moment().subtract(1, 'years').toDate()}
                    maxDate={moment().add(1, 'years').toDate()}
                    offset={'0px'}
                    isFuture={true}
                  />
                </Box>
              )}
            </HStack>
          </Box>
          <Box cursor="pointer" border="1px" borderColor="gray.300" borderRadius="md" p={1}>
            <Tooltip hasArrow label={' Download'} {...tooltipStyle}>
              <DownloadIcon
                onClick={() => {
                  sloData && sloData.slos && JSONToCSVConvertor(sloData.slos, services);
                  AppTracker.track(T_WA_UP_SLO_LIST_DOWNLOADED);
                }}
                fontWeight={'bold'}
              />
            </Tooltip>
          </Box>
        </Flex>
      </HStack>
      {isActiveSelected ? (
        <HStack m={2} justifyContent="start" height="20px" direction="row">
          <Text>Status:</Text>
          {
            <Tag size={'sm'} key={'sm'} borderRadius="full" variant="solid" colorScheme="gray">
              <TagLabel>{selectedFilterOpt.IsActive[0]}</TagLabel>
            </Tag>
          }
        </HStack>
      ) : null}

      {renderServiceFilterTags()}

      {isDateTimeSelected ? (
        <HStack m={2} justifyContent="start" height="20px" direction="row">
          <Text>Date Range:</Text>
          <Tag size={'sm'} key={'sm'} borderRadius="full" variant="solid" colorScheme="gray">
            <TagLabel>
              {'From: '}
              {moment(selectedFilterOpt.dateTime.fromDate).format('DD/MM/YYYY')}
            </TagLabel>
          </Tag>

          <Tag size={'sm'} key={'sm'} borderRadius="full" variant="solid" colorScheme="gray">
            <TagLabel>
              {'To: '}
              {moment(selectedFilterOpt.dateTime.toDate).format('DD/MM/YYYY')}
            </TagLabel>
          </Tag>
        </HStack>
      ) : null}

      {renderOwnerFilterTags()}
    </Box>
  );
};
