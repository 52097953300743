import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Logos"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.st2{fill:url(#SVGID_00000158743353835080186510000009246298196244845222_)}'}</style>
    <path
      d="M19.2 0H9.5c0 2.4 1.9 4.3 4.3 4.3h1.8V6c0 2.4 1.9 4.3 4.3 4.3V.8c.1-.4-.3-.8-.7-.8z"
      style={{
        fill: '#2684ff',
      }}
    />
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={15.002}
      y1={-672.791}
      x2={10.905}
      y2={-677.017}
      gradientTransform="matrix(1 0 0 -1 0 -668)"
    >
      <stop
        offset={0.18}
        style={{
          stopColor: '#0052cc',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#2684ff',
        }}
      />
    </linearGradient>
    <path
      d="M14.4 4.8H4.8c0 2.4 1.9 4.3 4.3 4.3h1.8v1.7c0 2.4 1.9 4.3 4.3 4.3V5.6c0-.4-.4-.8-.8-.8z"
      style={{
        fill: 'url(#SVGID_1_)',
      }}
    />
    <linearGradient
      id="SVGID_00000141415187543390538800000005448965935432007359_"
      gradientUnits="userSpaceOnUse"
      x1={10.516}
      y1={-677.627}
      x2={5.778}
      y2={-682.237}
      gradientTransform="matrix(1 0 0 -1 0 -668)"
    >
      <stop
        offset={0.18}
        style={{
          stopColor: '#0052cc',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#2684ff',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#SVGID_00000141415187543390538800000005448965935432007359_)',
      }}
      d="M9.6 9.6H0C0 12 1.9 14 4.3 14h1.8v1.7c0 2.4 1.9 4.3 4.3 4.3v-9.6c.1-.4-.3-.8-.8-.8z"
    />
  </svg>
);

export default SvgComponent;
