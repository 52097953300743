import { VStack, Grid, Text, GridItem, Link as ChakraLink } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

const stackStyles = {
  alignItems: 'flex-start',
  paddingLeft: '16px',
  paddingTop: '9px',
  paddingBottom: '9px',
};
const headingStyles = {
  color: THEME_COLORS.entityPopover.heading,
  fontWeight: 400,
};
const contentStyles = { fontWeight: 800, marginTop: '0px', maxWidth: '45ch' };
const linkStyles = {
  ...contentStyles,
  color: THEME_COLORS.brand.blue,
  cursor: 'pointer',
  textDecoration: 'none',
};

export type Section = {
  heading: string;
  text?: string | ReactNode;
  link?: string | null;
};

interface Props {
  header: ReactNode;
  sections: Section[];
}

const PopoverContent = ({ header, sections }: Props) => {
  return (
    <VStack alignItems="flex-start">
      {header}
      <Grid
        w="100%"
        display="block"
        templateRows={`repeat(${sections.length}, 1fr)`}
        mt={'0px !important'}
        alignItems="center"
      >
        {sections.map(({ heading, text, link }, index) => {
          const isLinkPresent = !!link;
          const isEmailLink = isLinkPresent && link.startsWith('mailto');

          const content = isEmailLink ? (
            <ChakraLink href={link ?? ''} style={linkStyles}>
              {text}
            </ChakraLink>
          ) : isLinkPresent ? (
            <Link to={link ?? ''} style={linkStyles}>
              {text}
            </Link>
          ) : (
            <Text style={contentStyles}>{text}</Text>
          );

          return (
            <GridItem key={index} colSpan={2}>
              <VStack style={stackStyles}>
                <Text style={headingStyles} textTransform="uppercase">
                  {heading}
                </Text>
                {content}
              </VStack>
            </GridItem>
          );
        })}
      </Grid>
    </VStack>
  );
};

export default PopoverContent;
