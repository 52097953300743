import axios from 'axios';
import { API } from '../api';
import qs from 'query-string';
import { IActiveAlertSourceRequestParams } from '../interfaces/IIncidents';

class AlertSourcesService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/alert-sources`;

  public getActive = (params: IActiveAlertSourceRequestParams, teamId?: string) =>
    axios.get<{ data: string[] }>(
      `${this._api}/active?${qs.stringify({
        fromDate: params.fromDate,
        toDate: params.toDate,
        owner_id: teamId,
      })}`,
    );
}

export default AlertSourcesService;
