import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Location } from 'history';
import { Prompt } from 'react-router-dom';
import WarningDialog from './warningModal';
interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}

type RouterGuardContextProps = {
  onGuardRelease?: () => void;
  setGuardRelease?: (guardReleaseAction: () => void) => void;
};

export const RouterGuardContext = createContext<RouterGuardContextProps>({
  onGuardRelease: () => {},
} as RouterGuardContextProps);

export const RouterGuardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [onGuardRelease, setOnGuardRelease] = useState<() => void>(() => () => {});

  const setGuardRelease = (guardReleaseAction: () => void) => {
    setOnGuardRelease(() => guardReleaseAction);
  };

  return (
    <RouterGuardContext.Provider value={{ onGuardRelease, setGuardRelease }}>
      {children}
    </RouterGuardContext.Provider>
  );
};

export const useRouterGuardContext = () => useContext(RouterGuardContext);

const RouteLeavingGuard = ({ when, navigate, shouldBlockNavigation }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const routerGuard = useRouterGuardContext();

  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    routerGuard?.onGuardRelease?.();
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {/* Your own alert/dialog/modal component */}
      <WarningDialog
        open={modalVisible}
        titleText="Close without saving?"
        contentText="You have unsaved changes. Are you sure you want to leave this page without saving?"
        // cancelButtonText="DISMISS"
        // confirmButtonText="CONFIRM"
        onCancel={closeModal}
        onConfirm={handleConfirmNavigationClick}
      />
    </>
  );
};
export default RouteLeavingGuard;
