import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const TextareaTheme: ComponentStyleConfig = {
  baseStyle: () => {
    return {
      bg: 'brand.white',
      fontFamily: 'Mulish',
      fontWeight: 400,
      color: '#09305A',
      boxShadow: 'none',
      outline: 'none',
    };
  },
  sizes: {
    md: {
      borderRadius: '3px',
      minHeight: '60px',
      fontSize: '12px',
      px: '12px',
      py: '6px',
    },
    lg: {
      borderRadius: '4px',
      minHeight: '80px',
      fontSize: '16px',
      px: '16px',
      py: '8px',
    },
  },
  variants: {
    outline: {
      borderColor: 'secondary.200',
      // needs to add border width in variant, can't customise it in size
      border: '0.75px solid',
      boxShadow: 'none',

      _disabled: {
        color: '#0000005c',
        borderColor: 'E2E8F0',
        opacity: 0.5,
        pointerEvents: 'none',
      },
      _hover: {
        borderColor: 'brand.blue',
      },
      _active: {
        borderColor: 'primary.400',
        border: '1px solid',
      },
      _focus: {
        borderColor: 'primary.400',
      },
      _invalid: {
        borderColor: '#E53E3E',
        boxShadow: 'none',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
};
