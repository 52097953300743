import styled from 'styled-components';

interface IProps {
  fontSize?: 48 | 24 | number;
  height?: 65 | 35 | number;
  fontWeight?: 500 | 400 | number;
}

const Heading = styled.h1<IProps>`
  height: auto;
  color: ${props => props.color || props.theme.font.default};
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.fontSize || 46}px;
  font-weight: ${props => props.fontWeight || 500};
  line-height: ${props => (props.height || 65) - 4}px;
  display: inline;
  margin: 0;
`;

/** @component */
export default Heading;
