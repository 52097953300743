import { Skeleton } from '@chakra-ui/react';
import { AlertSource } from 'icons';
import Wrapper from './Wrapper';

function Loader() {
  return (
    <Wrapper>
      <Skeleton flex={1}>
        <Wrapper flex={1}>
          <AlertSource width={24} height={24} />
        </Wrapper>
      </Skeleton>
    </Wrapper>
  );
}

export { Loader };
