import * as React from 'react';
import { SVGProps } from 'react';

const SvgCreate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path fill="url(#create_svg__a)" d="M0 0h30v30H0z" />
    <defs>
      <pattern id="create_svg__a" patternContentUnits="objectBoundingBox" width={1} height={1}>
        <use xlinkHref="#create_svg__b" transform="scale(.02)" />
      </pattern>
      <image
        id="create_svg__b"
        width={50}
        height={50}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAADQElEQVRoge2aTWsTQRjHf88mBRFfbh4FD9qXaOqxl6LJQQ9Sv4GQUqzgF/ADeFLoqRcRqbUVPBQKpSBFsV9ALwlubNqC4ksrvUhFgsLujIeaNLbN7uzubIzQ/2l2eZjZH888/92dGThUd0nS6jhfqg756PsCF4HjMbt5r7U8GKj3T8zNiR8UmI05QKByo25Rab0ocDRhV2dE9L3q0XcA94MCnYQD7VO+5A6LYoHkEE2J6FthMVZBcqNuUcESwjGb/QJnwgKs1Uhu1C2KZpGgTGh+IEyg1PzPk0dq65Nnf9ka3wqIIcR6RjvXyjP9qzbG3KvEIKYQTo9XqDwa/JyWmyVyragQabpZ7GI3dKe1jM5e3slEum4WC8TQndYyKlsoz/R+6YSbRQYxzsQfiDQy0aJmjUcCyZfcYaV5bpoJw3grMgYxmR4aVu1NJ3mBqNOIOg3yIizayLVM3EnDqudkCu5074aRm4VIK33TnbnwCaB/rDqe8fkQFB+akSgQtak+KxAAqkciveMCM/KvIAAyPg/7x6rjjXZYfFuQfMkdVooFJBgiq7JFd7p3wyQ+mvSVsOnUqgNBTNymtSY66U7ttK9GokDUpvq6AgL2gPyvENAytfKl6pBCL4XM8ZrypFB72re58wHIor2aSKZmRpRmkmC3WfE9Kbx7OrBp051saXdqiT4XELfie1LsOgiN12i21sjLNuHdCQEgbDeaTRDH924DK3tCuxcCECg32k2QyuzgloYCuzDftOJqt0IAde2oO42Lv+zXnc59bcBo0XfdmdzHfKk61JUQwsjbqQtvGjf2vRCbMFqegZGbdVp1LYy4j3PLrTdDvzDPl9xt4ERqjxVNB0KA2Y9VOzfrtNpCgAFIGzfrtAIhwACkMju45fjeJQHX7rMZq+5orgdBQISVxvyN8imdyS5ryCV/NmPVHc31ypPcq7DASL+THYYxhoAYa78dgokEATEXsVOGiQwBCVbjU4KJBQEJtxUsw8SGAAv7I5ZgEkGApR2rhDCJIcDiHmJMGCsQYPnAQEQYaxCQwskHQxirEJDSEY4QGOsQkOZZlINhUoGAFI5wNFSZHdwS3yuCzAPfgddKKKYBcahu1G/XjBlRvf9gnwAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);

export default SvgCreate;
