import { DialogModalFrame, Grid, Para } from 'uie/components';
import { IAppState } from 'core/interfaces/IAppState';
import { ITeam, ITeamMemberDependencies } from 'core/interfaces/ITeams';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import UserDependencies from '../../../userDependencies';
import { useGetDependencies, useRefreshMembers, useDeleteMember } from '../../hook';
import { useOBACMemberStore } from '../../store';
import { useSelector } from 'react-redux';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { ReduceEntity } from 'core/interfaces/IHelpers';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_USER_REMOVED_FROM_TEAM } from 'core/const/tracker';

export function DepsModal({ isOpen, team }: { isOpen: boolean; team: ITeam }) {
  const deleteState = useOBACMemberStore(store => store.deleteState);
  const setDeleteState = useOBACMemberStore(store => store.setDeleteState);

  const closeReplaceModal = () => {
    setDeleteState();
  };

  const isEmptyDependency = (deps: ITeamMemberDependencies) =>
    Object.entries(deps).every(([_, val]) => {
      if (Array.isArray(val)) return val.length === 0;
      else if (typeof val === 'string') return val === '';
      else if (typeof val === 'boolean') return val === false;
      else return val === 0;
    });

  const organization = useSelector((state: IAppState) => state.organization);

  const actingMemberId = useOBACMemberStore(store => store.actingMemberId) ?? '';

  const {
    mutate: deleteMember,
    isLoading: isDeleting,
    isError: isDeleteError,
  } = useDeleteMember({
    onSuccess: () => {
      AppTracker.track(T_WA_USER_REMOVED_FROM_TEAM, {
        'Team ID': team.id,
      });
      closeReplaceModal();
    },
  });

  const { data, isLoading, isFetching, isError, refetch } = useGetDependencies(
    team.id,
    actingMemberId ?? '',
    {
      onSuccess: response => {
        if (!isEmptyDependency(response.data.data)) {
          setDeleteState('conflict');
        } else {
          deleteMember({ teamId: team.id, memberId: actingMemberId });
        }
      },
      onError: () => {
        setDeleteState();
      },
    },
  );

  const refreshMembers = useRefreshMembers();

  const _oUsers: ReduceEntity<
    IUsersOfOrganization,
    'id' | 'email_verified' | 'phone_verified' | 'email' | 'role' | 'username_for_display',
    'name'
  > = useMemo(
    () =>
      organization.users.u.reduce(
        (
          c: ReduceEntity<
            IUsersOfOrganization,
            'id' | 'email_verified' | 'phone_verified' | 'email' | 'role' | 'username_for_display',
            'name'
          >,
          u,
        ) => {
          c[u.id] = {
            id: u.id,
            name: `${u?.first_name ?? 'Deleted'} ${u?.last_name ?? ''}`,
            email: u.email,
            username_for_display: u.username_for_display,
            role: u.role,
            email_verified: u.email_verified,
            phone_verified: u.phone_verified,
          };

          return c;
        },
        {},
      ),
    [organization.users.u],
  );

  useEffect(() => {
    if (isOpen && deleteState === 'checking_conflicts') {
      refetch();
    }
  }, [isOpen, deleteState === 'checking_conflicts', actingMemberId, team.id]);

  let render = null;

  if (isLoading && deleteState === 'checking_conflicts') {
    render = (
      <Grid style={{ margin: '24px 0' }}>
        <Para fontSize={18}>Checking For Conflicts...</Para>
      </Grid>
    );
  } else if (isError && deleteState === 'error') {
    render = (
      <Grid style={{ margin: '24px 0' }}>
        <Para fontSize={18}>An error occurred while checking for conflicts. Please try again.</Para>
      </Grid>
    );
  } else if (data?.data?.data && deleteState === 'conflict') {
    render = (
      <UserDependencies
        team={team}
        oldUser={_oUsers[actingMemberId]}
        dependencies={data.data.data}
        updateChildInRequest={() => {}}
        hide={closeReplaceModal}
        onUpdate={() => {
          refreshMembers();
        }}
      />
    );
  }

  return (
    <DialogModalFrame
      id="organization_team_settings__delete_member_conflicts"
      onClose={closeReplaceModal}
      width="500px"
    >
      {render}
    </DialogModalFrame>
  );
}
