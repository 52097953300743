import * as React from 'react';
import { SVGProps } from 'react';

const SvgAirbrakeLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M9.5 15.4c-1.1 0-2-.1-3-.2-.5-.1-.7 0-.9.5-.3 1.1-.7 2.2-1.1 3.3-.1.4-.3.5-.6.5H.3c-.4 0-.5 0-.3-.4 2.1-5.5 4.1-11 6.1-16.4.3-.7.6-1.2.7-1.8.2-.4.5-.3.8-.3.3 0 .4.1.5.4l1.2 3.6c.1.3 0 .5-.1.8-.7 2.1-1.5 4.2-2.2 6.4-.2.5-.1.6.4.6 1.4.2 2.8.2 4.2.1.4 0 .5.1.6.4.2.7.4 1.3.6 2 .1.3 0 .4-.3.4-1 0-2.1.1-3 .1z"
      style={{
        opacity: 0.5,
        fill: '#fea400',
      }}
    />
    <path
      d="M20 19.4h-4.4c-.4 0-.4-.3-.4-.5L11 6.6C10.3 4.8 9.7 2.9 9 1c-.1-.3-.1-.5.4-.5h3.2c.3 0 .4 0 .5.3C15.4 7 17.7 13.1 20 19.2v.2z"
      style={{
        fill: '#fea400',
      }}
    />
  </svg>
);

export default SvgAirbrakeLogo20X20;
