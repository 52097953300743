import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import useQueryParams from 'core/hooks/useQueryParams';
import { ActiveFilterTag } from '../owner-filters/ActiveFilterTags';
import { SelectedFilterTag } from '../owner-filters/SelectedFilterTags';
import {
  FilterObject,
  DropdownOption,
  FilterEntity,
  useEntityOwnerFilter,
  Option,
  Owner,
} from '../owner-filters/hooks/useEntityOwnerFilter';

export interface OwnerFilter {
  activeFilterObject: FilterObject;
  selectedFilterObject: FilterObject;
  activeFilterTags: ActiveFilterTag[];
  dropdownOptions: DropdownOption[];
  onChangeHandler: (values: Option) => void;
  selectedFilterTags: SelectedFilterTag[];
  handleActiveFilterTagClick: (type: Owner, id: string) => void;
  handleSelectedFilterTagClick: (type: Owner, id: string) => void;
  ownerFilterApplied: boolean;
  ownerFilterSelected: boolean;
  resetOwnerFilters: () => void;
  clearSelectedFilterValues: () => void;
  syncSelectedFilterWithActiveFilter: () => void;
}

export function withOwnerFilter<T>(InputComponent: React.ComponentType<T>) {
  return (props: T) => {
    const query = useQueryParams();
    const filterQueryParam = query.get('entity_owner') ?? '';
    const ownerFilter: OwnerFilter = useEntityOwnerFilter({
      queryParamsString: filterQueryParam,
      entity: FilterEntity.WEBFORM,
    });
    return (
      <InputComponent {...props} ownerFilter={ownerFilter} filterQueryParam={filterQueryParam} />
    );
  };
}

export default withOwnerFilter;
