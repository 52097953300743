import React, { useState, useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  DialogModalFrame,
  DropDown,
  ErrorBlock,
  FormBlock,
  Grid,
  Heading,
  IconButton,
  Label,
  Para,
  TextButton,
  Theme,
} from 'uie/components';
import { ColorResult, TwitterPicker } from 'react-color';
import { DeleteIcon } from 'icons';
import { IITags } from 'core/interfaces/IIncidents';
import TagGroup from './tagGroup';
import useGetAllTags from 'core/hooks/useGetAllTags';
import { IncidentService } from 'core/services';
import { T_WA_GS_TAGGING_RULES_MODIFIED } from 'core/const/tracker';
import { AppTracker } from 'shared/analytics/tracker';
import { exception } from 'core';

const { theme } = Theme;

type IProps = {
  tags: IITags;
  incidentId: string;
};

type TagType = {
  key: string;
  value: string;
  color: string;
};

const emptyTag: TagType = {
  key: '',
  value: '',
  color: '#0f61dd',
};

const convertTagsObjToArr = (tags: IITags) => {
  const tagsMap = Object.entries(tags).map(t => {
    return {
      key: t[0],
      value: t[1].value,
      color: t[1].color,
    };
  });

  if (tagsMap.length > 0) {
    return [...tagsMap, { ...emptyTag }];
  }

  return [{ ...emptyTag }];
};

const UpdateTags: React.FC<IProps> = ({ tags: tagsObj, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tags, setTags] = useState<TagType[]>([{ ...emptyTag }]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [invalidKeys, setInvalidKeys] = useState<{ [key: string]: string }>({});

  const orgTags = useGetAllTags('', '');

  const updateColor = (tagIndex: number) => (color: ColorResult) => {
    const { hex } = color;
    const newTags = [...tags];
    newTags[tagIndex].color = hex;
    setTags(newTags);
  };

  const handleTagChange = (tagIndex: number) => (key: string, value: string) => {
    const newTags = [...tags];
    newTags[tagIndex].key = key;
    newTags[tagIndex].value = value;

    if (tagIndex === tags.length - 1) newTags.push({ ...emptyTag });

    setTags(newTags);
  };

  const handleDeleteTag = (tagIndex: number) => () => {
    const newTags = [...tags];
    newTags.splice(tagIndex, 1);
    if (newTags.length === 0) newTags.push(emptyTag);
    setTags(newTags);
  };

  useEffect(() => {
    setTags(convertTagsObjToArr(tagsObj));
  }, [tagsObj, setTags]);

  const closeModal = () => {
    setTags(convertTagsObjToArr(tagsObj));
    setInvalidKeys({});
    onClose();
  };

  const validateInput = () => {
    const invalid: { [key: string]: string } = {};
    const validKey = new RegExp('^[a-zA-Z0-9_]+$');
    const tagsArr: string[] = [];

    tags.forEach((tag, index) => {
      if (tag.key === '' && tag.value === '') return;

      if (tag.key === '' || tag.value === '') {
        invalid[`tag_${index}`] = "Can't leave key or value empty";
      }

      if (!validKey.test(tag.key)) {
        invalid[`tag_${index}`] = 'Invalid tag key (Should not contain special characters)';
      }

      if (tagsArr.includes(`${tag.key}:${tag.value}`)) {
        invalid[`tag_${index}`] = 'Selected tag already exists. Choose different value';
      } else {
        tagsArr.push(`${tag.key}:${tag.value}`);
      }
    });

    setInvalidKeys(invalid);
    return Object.keys(invalid).length === 0;
  };

  const handleSave = async () => {
    const _incidentService = new IncidentService();

    AppTracker.track(T_WA_GS_TAGGING_RULES_MODIFIED);
    if (!validateInput()) {
      return;
    }

    setLoading(true);

    const tagsData = tags.reduce((c: IITags, nt: TagType) => {
      if (nt.key === '' && nt.value === '') return c;

      c[nt.key] = {
        value: nt.value,
        color: nt.color,
      };
      return c;
    }, {});

    try {
      await _incidentService.updateTags(props.incidentId, tagsData);
      onClose();
    } catch (err: any) {
      setErrorMessage(err?.response?.data?.meta?.error_message ?? 'Network Error');
      exception.handle('E_INCIDENT_UPDATE_TAGS', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid>
      <TextButton
        color={theme.primary.default}
        buttonType="inverted"
        onClick={onOpen}
        className="mr-10"
      >
        <Para fontSize={12} color={theme.primary.default} fontWeight={500}>
          &#43; Update Tags
        </Para>
      </TextButton>

      <DialogModalFrame
        id="incident_details__update_tags_modal"
        onClose={closeModal}
        padding="32px"
        width="500px"
      >
        {isOpen && (
          <Grid type="column">
            <Heading
              fontWeight={500}
              color={theme.shades.cement}
              fontSize={24}
              className="pr-20 mb-10"
            >
              Update Tags
            </Heading>

            <FormBlock onFormSubmit={handleSave}>
              <Grid flexWidth={12} alignItems="center" className="mb-10 mt-20">
                <Grid flexWidth={1} />
                <Grid flexWidth={5} className="mr-20">
                  <Label fontWeight={400}>Key</Label>
                </Grid>
                <Grid flexWidth={5} className="mr-10">
                  <Label fontWeight={400}>Value</Label>
                </Grid>
                <Grid flexWidth={1} />
              </Grid>

              {tags.map((tag: TagType, idx) => (
                <Grid
                  flexWidth={12}
                  key={`${tag.key}:${tag.value}`}
                  style={{ marginBottom: '20px' }}
                >
                  <Grid
                    flexWidth={1}
                    style={{ marginTop: '12px' }}
                    className="update_tags–dropdown-tp"
                  >
                    <DropDown
                      style={{ boxShadow: 'none', backgroundColor: 'rgba(0, 0, 0, 0)' }}
                      offset="-10px"
                      padding="0px"
                      height="auto"
                      maxHeight="auto"
                      hook={
                        <IconButton
                          buttonType="inverted"
                          borderType="rounded"
                          base="14px"
                          color={tag.color}
                        />
                      }
                    >
                      <TwitterPicker color={tag.color} onChange={updateColor(idx)} />
                    </DropDown>
                  </Grid>

                  <Grid flexWidth={10}>
                    <TagGroup
                      value={{ key: tag.key, value: tag.value }}
                      keysOptions={orgTags.keys}
                      valuesMap={orgTags.valuesMap}
                      handleChange={handleTagChange(idx)}
                      errorMsg={invalidKeys[`tag_${idx}`]}
                    />
                  </Grid>

                  <Grid flexWidth={1} style={{ marginLeft: '12px' }}>
                    <IconButton color={theme.danger.light} onClick={handleDeleteTag(idx)}>
                      <DeleteIcon stroke={theme.danger.default} style={{ width: '18px' }} />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}

              {errorMessage && (
                <ErrorBlock fontWeight={500} className="mt-20">
                  {errorMessage}
                </ErrorBlock>
              )}

              <TextButton style={{ marginTop: '40px' }} onClick={handleSave} disabled={loading}>
                <Para fontWeight={400} fontSize={16} color={theme.shades.white}>
                  Update
                </Para>
              </TextButton>
            </FormBlock>
          </Grid>
        )}
      </DialogModalFrame>
    </Grid>
  );
};

export default UpdateTags;
