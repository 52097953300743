import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API } from 'core';
import { queryKeys, reactQueryConfig } from '../constant';
import { IGetALLJiraAccResponse } from '../../../../../../../core/interfaces/IJiraNew';
import { reactQueryConfigError } from '../helpers/service.reactqery';

const getAllJiraCloudAccounts = async () => {
  const { data } = await axios.get<IGetALLJiraAccResponse>(
    `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud/new`,
  );
  return data.data;
};

export const useGetAllJiraAccounts = () => {
  return useQuery([queryKeys.GETALLJIRACLOUDACCOUNTS], () => getAllJiraCloudAccounts(), {
    ...reactQueryConfig,
    enabled: true,
    retry: 1,
    onError: (error: any) => {
      const description =
        error?.response?.data?.meta?.error_message || 'Failed to Get Jira All Account';
      reactQueryConfigError({ description: description, title: 'Get Jira All Account' });
    },
  });
};
