import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Theme } from 'uie/components';
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { IAppState } from '../../../../core/interfaces/IAppState';
import { ErrorBoundary } from '../../../shared/components';
import {
  addalertSourceDuringCreationUrl,
  addAlertSourceUrl,
  serviceCreateUrl,
  serviceDetailUrl,
} from './contants/service.contant';
import AddServiceCatalog from './service.add';
import AddAlertSourceListing from './service.add/addAlertSourceList/alertSourceList';
import AddAlertSource from './service.add/components/addAlertSource';
import ServiceDetail from './service.detail';
import ServiceHome from './service.home';
import { Button } from './theme/button';
import { Link } from './theme/link';
import { Text } from './theme/text';
import { Divider } from './theme/divider';
//Responsive styles
import { breakpoints } from './theme/style/responsive-styles/breakpoints';
import { useUserAccess } from 'core/userAccess/UserAccessContext';

const { theme: squadcastTheme } = Theme;

export const chakraTheme = extendTheme({
  colors: {
    ...squadcastTheme,
    default: squadcastTheme.primary.default,
  },
  bg: {
    default: squadcastTheme.primary.default,
  },
  fonts: {
    body: squadcastTheme.fontFamily,
    heading: squadcastTheme.fontFamily,
  },
  components: {
    Button,
    Link,
    Text,
    Divider,
  },
  breakpoints,
});

export type IMix = Pick<IAppState, 'organization'>;

export const OrganizationContext = React.createContext<IMix['organization'] | null>(null);

const ServiceCatalog = (props: IMix) => {
  const _uA = useUserAccess();
  const hasRead = _uA.hasReadAccess('services');
  const hasCreate = _uA.hasCreateAccess('services');
  const hasUpdate = _uA.hasUpdateAccess('services');
  const hasDelete = _uA.hasDeleteAccess('services');
  const { path, url } = useRouteMatch();
  return (
    <ChakraProvider theme={chakraTheme}>
      <ErrorBoundary>
        <OrganizationContext.Provider value={props.organization}>
          <Switch>
            <Route exact path={path}>
              <ServiceHome />
            </Route>
            <Route exact path={serviceDetailUrl} component={ServiceDetail} />
            <Route exact path={serviceCreateUrl}>
              <AddServiceCatalog />
            </Route>
            <Route exact path={addAlertSourceUrl}>
              <AddAlertSourceListing />
            </Route>
            <Route exact path={addalertSourceDuringCreationUrl}>
              <AddAlertSource />
            </Route>
          </Switch>
        </OrganizationContext.Provider>
      </ErrorBoundary>
    </ChakraProvider>
  );
};

export default connect(({ organization, APP_CONFIG }: IAppState) => ({
  organization,
  APP_CONFIG,
}))(memo(ServiceCatalog));
