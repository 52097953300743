import React from 'react';
import { ISloAggrigatorData, sloAgg, IGetSlo, ISlo, sloKeys } from './slo.interface';
import { slo_aggregate, tableHeader, perPage } from './slo.constants';
import { IServices } from './sloList.component';
import { IUsers } from './sloOverview.component';

export const getSloAggrigatedData = (aggrigateddata: ISloAggrigatorData) => {
  return slo_aggregate.map(sloData => {
    const aggItems = sloData.items.map(({ id, label }) => {
      if (id == 'total_incidents') {
        return {
          id,
          label,
          value:
            aggrigateddata['total_incidents'] - aggrigateddata['total_slo_affected_incidents_slos'],
        };
      }
      return { id, label, value: aggrigateddata[id as sloAgg] };
    });

    return { ...sloData, items: aggItems };
  });
};

export const getTargetSlo = (targetSlo: number | null, remainingError: number | null) => {
  if (targetSlo && remainingError) {
    return targetSlo - remainingError;
  }
  return targetSlo;
};

export const getServicesList = (service_ids: Array<string> | null, services: IServices) => {
  if (!Array.isArray(service_ids)) return [];

  return (
    services.s
      .map(({ id, name }, index) => {
        if (service_ids.find(service => service === id)) {
          return name;
        }
      })
      .filter(Boolean) || []
  );
};

export const getUserList = (user_ids: Array<string> | null, users: IUsers) => {
  if (!Array.isArray(user_ids)) return [];

  return (
    users.u
      .map(({ id, first_name }, index) => {
        if (user_ids.find(user => user === id)) {
          return first_name;
        }
      })
      .filter(Boolean) || []
  );
};

export const getIsHealthy = (isHealthy: boolean) => {
  return isHealthy
    ? { color: 'green', value: 'Healthy' }
    : { color: 'red', value: 'Needs Attention' };
};

export const getIsActive = (isActive: boolean) => {
  return isActive
    ? { color: 'rgba(15, 97, 221, 1)', value: 'Active' }
    : { color: 'rgba(45, 57, 100, 1)', value: 'Inactive' };
};

export const getSloTags = (tags: { [key: string]: string } | null) => {
  const returnArr = [];
  if (!tags) return [];

  for (const [key, value] of Object.entries(tags)) {
    returnArr.push(`${key}: ${value}`);
  }
  return returnArr;
};

function convertToCSV(objArray: any) {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (const index in array[i]) {
      if (line != '') line += ',';

      line += array[i][index];
    }

    str += line + '\r\n';
  }

  return str;
}

export const JSONToCSVConvertor = (JSONData: Array<ISlo>, services: IServices) => {
  const jsonObject = JSONData.map(obj => {
    const serviceArray =
      obj.service_ids &&
      obj.service_ids?.map((serviceId, index) => {
        const servicesNames = services.s.find(({ id, name }) => {
          if (serviceId === id) return name;
        });

        return servicesNames ? servicesNames.name : '';
      });

    return {
      name: obj.name ? obj.name.replace(/,/g, '') : '',
      target_slo: obj.target_slo ? obj.target_slo : '',
      remaining_error_budget: obj.remaining_error_budget ? obj.remaining_error_budget : '',
      is_healthy: obj.is_healthy ? 'Healthy' : 'Un Healthy',
      is_Active: obj.is_active ? 'Active' : 'InActive',
      service_ids: serviceArray ? JSON.stringify(serviceArray).replace(/,/g, ' ') : '',
      incident_count: obj.incident_count ? obj.incident_count : '',
      start_time: obj.start_time ? obj.start_time.replace(/,/g, '') : '',
      tags: obj.tags ? JSON.stringify(obj.tags).replace(/,/g, '') : '',
    };
  });

  jsonObject.unshift({
    name: 'Name',
    target_slo: 'Target Slo',
    remaining_error_budget: 'Remaning Err Budget',
    is_healthy: 'Slo Burning Rate',
    is_Active: 'Status',
    service_ids: 'services',
    incident_count: 'Incident Counts',
    start_time: 'Start Time',
    tags: 'tags',
  });

  const csv = convertToCSV(jsonObject);
  const exportedFilenmae = 'SloList.csv';
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', exportedFilenmae);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const getPercentage = (sli: number, totalErrBudget: number) => {
  return totalErrBudget && sli ? ((sli / totalErrBudget) * 100).toFixed(2) : 0;
};
