import { Box, Flex, VStack } from '@chakra-ui/layout';
import { Text } from 'library/atoms/Text';
import { PageHeader } from 'library/molecules';

const HelpAndGuide = () => {
  return (
    <VStack backgroundColor="white" alignItems="flex-start" justifyContent="flex-start">
      <PageHeader title="Help and Guide" noButton />
      <Box px={6}>
        <Flex gap={2} flexDirection="column">
          <Text variant="h1">What is Live Call Routing (LCR)?</Text>

          <Text variant="h4">
            Live Call Routing allows end users/internal employees to dial a number and report
            incidents or leave voicemails for issues they are experiencing. These calls or
            voicemails are routed to the on-call engineers directly for quick remediation of
            reported issues. The direct channel established between end users and on-call engineers
            eases the process of reporting issues.
          </Text>

          <Text variant="h4">
            Based on the severity or the frequency of the reported issues, on-call engineers can
            triage and ensure quick resolutions, ensuring the MTTR for customer-reported issues is
            always in check.
          </Text>

          <Text variant="h4">
            We support local and toll-free numbers (where available) for the USA, India, Canada,
            Belgium, United Kingdom, Australia and other countries. In case you are looking for a
            country that is not listed in the drop-down, reach out to us.
          </Text>

          <Text variant="h4">
            Once you have started receiving calls for the configured Routing Number, you can easily
            keep track of logs and sessions for it. The Sessions section displays the sessions of
            the routing number that have taken place and all of their corresponding details (listed
            in the table below). For a Routing Number, open the Routing Number details page,
            navigate to the Sessions section.
          </Text>

          <Text variant="h4">
            To learn more about the configuration and what each field requires you to do -{' '}
            <a
              href="https://support.squadcast.com/live-call-routing/live-call-routing"
              target="_blank"
              rel="noreferrer"
              style={{
                color: '#1B7FF1',
              }}
            >
              {' '}
              Click here
            </a>
          </Text>
        </Flex>
      </Box>
    </VStack>
  );
};

export default HelpAndGuide;
