import { Center, Flex, Image, Text, VStack } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

type Props = {
  image: string;
  name: string;
  width?: string;
  height?: string;
  msg?: string | ReactNode;
};

function IllustrationBox({ image, name, width, height, msg }: Props) {
  return (
    <Center dir="column">
      <VStack>
        <Image
          width={width}
          height={height}
          sizes="xm"
          objectFit="contain"
          src={image}
          alt={name}
        />
        <Text>{msg || 'No Data'}</Text>
      </VStack>
    </Center>
  );
}

export default IllustrationBox;
