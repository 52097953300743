import { PRIORITY_OPTIONS } from 'library/common';
import { ASSIGNEE_OPTIONS } from './selectOptions';

const INCIDENT_CREATE_OBJ = 'SQ_INCIDENT_OBJ';
const DEFAULT_OBJ = {
  title: '',
  services: null,
  assignedkey: ASSIGNEE_OPTIONS[0],
  assigned: null,
  tags: [],
  priority: PRIORITY_OPTIONS[5],
};

export const setIncidentObj = (key: string, value: any) => {
  const obj = getIncidentObj();
  obj[key] = value;

  sessionStorage.setItem(INCIDENT_CREATE_OBJ, JSON.stringify(obj));
};

export const getIncidentObj = () => {
  const str = sessionStorage.getItem(INCIDENT_CREATE_OBJ);
  return str ? JSON.parse(str) : { ...DEFAULT_OBJ };
};

export const clearIncidentObj = () => {
  sessionStorage.removeItem(INCIDENT_CREATE_OBJ);
};
