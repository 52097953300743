import { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  borderStyle?: 'sharp' | 'shadow';
  padding?: string | '16px';
  animateOnHover?: boolean;
}

const calcAnimations = (props: any) => {
  if (props.animateOnHover) {
    return `
      &:hover {
        box-shadow: ${props.borderStyle !== 'sharp' ? props.theme.shadow.hover : 'none'};
        border: ${
          props.borderStyle === 'sharp' ? `1px solid ${props.theme.shades.lightGrey}` : 'none'
        };
      }
    `;
  }
};

const CardBlock = styled.div<IProps>`
  ${calcAnimations}
  padding: ${props => props.padding || '16px'};
  box-shadow: ${props => (props.borderStyle !== 'sharp' ? props.theme.shadow.default : 'none')};
  border: ${props =>
    props.borderStyle === 'sharp' ? `1px solid ${props.theme.shades.smoke}` : 'none'};
  border-radius: 3px;
  height: fit-content;
  width: fit-content;
  transition: box-shadow 0.3s, border 0.17s ease;
`;

/** @component */
export default CardBlock;
