import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../core/interfaces/IAppState';
import { requestLogin, onLoginSuccess, requestUserLogout } from '../../../core/actions/auth';
import { IComponentState, IComponentErrorState } from '../../../core/interfaces/IComponentState';
import { AuthService } from '../../../core/services';
import '../common.css';
import { Link, Redirect } from 'react-router-dom';
import { CommonAuth } from '../commonAuth';
import { Grid, SpinLoader, Heading, TextButton, Theme, Para } from 'uie/components';
import { T_WA_GS_EMAIL_VERIFIED, T_WA_GS_SSO_LOGIN } from '../../../core/const/tracker';
import { AppTracker } from '../../../shared/analytics/tracker';
import { IUserRequestLogin } from '../../../core/interfaces/IUserData';
import { Check, TickBadge } from 'icons';
import LogIn from '../logIn';

const { theme } = Theme;

interface IProps extends Pick<IAppState, 'auth'> {
  requestLogin: (params: IUserRequestLogin) => void;
  onLoginSuccess: (sessionId: string) => void;
  requestUserLogout: () => void;
}

interface IState {
  componentState: IComponentState;
  type:
    | 'sso'
    | 'reset'
    | 'back'
    | 'verify-email'
    | 'activate'
    | 'login'
    | 'get-started'
    | 'sso_enabled'
    | 'authenticate'
    | '';
  errors: IComponentErrorState;
  message: string;
  isActionDone: boolean;
}

/**
 * handles saml, email-resets and activations
 * there is a commonAuth implementations as a link between page navigation to persist emails
 */
class A0 extends Component<IProps, IState> {
  _authService = new AuthService();

  constructor(props: IProps) {
    super(props);

    this.state = {
      componentState: 'busy',
      type: '',
      message: '',
      errors: {},
      isActionDone: false,
    };
  }

  async componentDidMount() {
    try {
      const path = new URL(window.location.href).pathname;
      const params = new URL(window.location.href).searchParams;
      const session = params.get('session_token');
      const errorMessage = params.get('message');
      const _id = (this.props as any).match.params._id;
      const email = CommonAuth.db.email;
      CommonAuth.clearDb();

      if (path === '/sso/enabled') {
        this.setState({ type: 'sso_enabled' });
        return;
      }

      if (
        window.isMobile &&
        ((errorMessage && errorMessage !== '') || (session && session !== ''))
      ) {
        setTimeout(() => {
          document.location.href = document.location.href.replace('https', 'sqdapp');
        }, 0);
      }

      const type = path.includes('sso')
        ? 'sso'
        : path.includes('activate')
        ? 'activate'
        : path.includes('reset')
        ? 'reset'
        : path.includes('verify')
        ? 'verify-email'
        : path.includes('authenticate')
        ? 'authenticate'
        : 'back';

      this.setState({
        type,
      });

      if (type === 'reset') {
        if (email === '') {
          throw new Error('invalid Redirects');
        }

        this.setState({
          componentState: 'idle',
          type: 'reset',
          message: email,
        });
        return;
      }

      if (errorMessage && errorMessage !== '') {
        this.setState({
          type: 'sso',
          errors: {
            sso: errorMessage,
          },
          message: '',
          componentState: 'idle',
        });
        return;
      }

      if (type === 'verify-email') {
        if (email === '') {
          throw new Error('invalid Redirects');
        }
        this.setState({
          componentState: 'idle',
          type: 'verify-email',
          message: email,
        });
        return;
      }

      if (type === 'sso' && this.state.componentState === 'busy') {
        AppTracker.track(T_WA_GS_SSO_LOGIN);
      }

      if (type === 'activate' && _id) {
        this.setState({
          type: 'activate',
        });
        try {
          const {
            data: {
              data: { action, user, email: userEmail, message, token },
            },
          } = await this._authService.activateUser(_id);

          switch (action) {
            case 'verified': {
              this.setState({
                message: 'Your account is verified.',
              });
              AppTracker.track(T_WA_GS_EMAIL_VERIFIED);
              AppTracker.people({ 'Email Verified Time': new Date().toISOString() });
              break;
            }
            case 'already': {
              this.setState({
                message: 'Your account is verified.',
              });
              break;
            }
            case 'Profile incomplete': {
              this.props.onLoginSuccess(token);
              this.setState({
                message: 'Your invite has been verified. Please complete profile to continue.',
              });
              break;
            }
            default: {
              throw new Error('invalid');
            }
          }

          if (userEmail) {
            CommonAuth.db = {
              email: userEmail,
              id: new Date().getTime().toString(),
              redirectFrom: 'a0',
            };
          }

          if (user.email) {
            CommonAuth.db = {
              email: user.email,
              id: new Date().getTime().toString(),
              redirectFrom: 'a0',
            };
            setTimeout(() => {
              this.setState({
                type: 'get-started',
              });
            }, 3000);
          }
        } catch (err: any) {
          this.setState({
            errors: {
              activate: "Account is verified or doesn't exist",
            },
          });
        } finally {
          this.setState({
            componentState: 'idle',
          });
        }
        return;
      }

      if (type === 'authenticate') {
        const nonce = params.get('token');

        if (nonce === null || nonce === '') {
          return this.setAuthenticationErrorState();
        }
        const session_token = await this.exchangeNonceForSessionToken(nonce);
        if (session_token === '') {
          return this.setAuthenticationErrorState();
        }
        this.props.onLoginSuccess(session_token);
        return;
      }

      if (session && session.length > 0) {
        this.props.onLoginSuccess(session);
      } else {
        this.setState({
          componentState: 'idle',
          errors: {
            login: 'Login Failed. Please Try again.',
          },
        });

        return;
      }

      this.setState({
        componentState: 'idle',
        type: 'reset',
        errors: {
          sso: '__',
        },
      });
    } catch (err: any) {
      this.setState({
        componentState: 'idle',
        type: 'back',
        errors: {
          sso: '__',
        },
      });
    }
  }

  exchangeNonceForSessionToken = async (nonce: string | null) => {
    try {
      const authenticationResponse = await this._authService.authenticateLogin(nonce as string);
      const data = authenticationResponse.data.data;
      return data.session_token;
    } catch (err: any) {
      return '';
    }
  };

  setAuthenticationErrorState = () => {
    this.setState({
      componentState: 'idle',
      errors: {
        authenticate: 'Invalid Session token',
      },
    });
  };

  componentDidUpdate() {
    if (window.location.pathname === '/sso/enabled' && this.props.auth.action === 'REQUEST_LOGIN') {
      this.setState({ type: 'login' });
    }
  }

  resetPassword = async () => {
    if (this.state.type !== 'reset') {
      return;
    }

    this.setState({
      errors: {},
    });

    try {
      await this._authService.requestResetPassword(this.state.message);
      this.setState(
        {
          isActionDone: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ isActionDone: false });
          }, 2000);
        },
      );
    } catch (err: any) {
      this.setState({
        errors: {
          reset:
            err?.response?.data?.meta?.error_message === 'user not found'
              ? 'This email is not registered'
              : err?.response?.data?.meta?.error_message === 'Too many password reset attempts'
              ? 'Too many password reset attempts.Please try again after 5 minutes'
              : 'Looks like a network error. Please Try again',
        },
        isActionDone: false,
      });
    }
  };

  render() {
    const { type, componentState, errors, message } = this.state;

    if (type === 'login') {
      return <Redirect to="/" push={true} />;
    }

    if (type === 'back') {
      return <LogIn />;
    }
    if (type === 'get-started') {
      return <Redirect to="/register" push={true} />;
    }

    return (
      <div className="login-container">
        <div className="login-interact">
          <div
            style={{
              fontSize: '14px',
              lineHeight: '16px',
              textAlign: 'center',
              marginBottom: '120px',
            }}
          >
            <img
              className="banner-logo"
              src="/assets/new_logo.svg"
              alt="Squadcast-logo"
              style={{ width: '180px', marginBottom: '4px' }}
            />
            <div style={{ marginBottom: '24px' }}>Incident Response for SRE, Devops & IT teams</div>
          </div>
          {type === 'sso_enabled' && (
            <React.Fragment>
              <Heading fontSize={24} height={24}>
                SSO Enabled
              </Heading>
              <p className="login-resets">
                <span>
                  SSO has been enabled for your organization. Please use sign-in via SSO to
                  continue.
                </span>
              </p>
              <p className="login-resets">
                <span>
                  <TextButton onClick={this.props.requestUserLogout}>
                    <Para color={theme.shades.white}>Click here to logout.</Para>
                  </TextButton>
                </span>
              </p>
            </React.Fragment>
          )}
          {type === 'authenticate' && (
            <React.Fragment>
              <div style={{ textAlign: 'center', marginTop: '70%' }}>
                <Heading fontSize={24} height={24}>
                  Authorizing Login
                </Heading>

                {componentState === 'busy' && (
                  <Grid alignItems="center">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: '30%',
                        marginTop: '20px',
                      }}
                    >
                      <SpinLoader base="16px" />
                      <span className="ml-10">Signing In...</span>
                    </div>
                  </Grid>
                )}

                {componentState === 'idle' && errors.authenticate && (
                  <React.Fragment>
                    <p className="login-error-message">{errors.authenticate || ''}</p>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
          {type === 'sso' && (
            <React.Fragment>
              <Heading fontSize={24} height={24}>
                SSO Login
              </Heading>

              {componentState === 'busy' && (
                <Grid alignItems="center">
                  <SpinLoader base="16px" />
                  <span className="ml-10">Signing In...</span>
                </Grid>
              )}

              {componentState === 'idle' && errors.sso && (
                <React.Fragment>
                  <p className="login-error-message">{errors.sso || ''}</p>
                  <div style={{ marginLeft: -10 }}>
                    <Link className="login-signin-link" to="/">
                      Login
                    </Link>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {type === 'activate' && (
            <React.Fragment>
              <div style={{ textAlign: 'center', marginTop: '70%' }}>
                <Heading fontSize={24} height={24}>
                  Email{' '}
                  {componentState === 'idle' && !errors.activate ? (
                    <>
                      Verified <Check style={{ height: 24, verticalAlign: 'bottom' }} />
                    </>
                  ) : (
                    'Verification'
                  )}
                </Heading>
                {componentState === 'busy' && (
                  <Grid alignItems="center" style={{ marginTop: 20 }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: '30%',
                      }}
                    >
                      <SpinLoader base="16px" />
                      <span className="ml-10">Verifying email...</span>
                    </div>
                  </Grid>
                )}
                {componentState === 'idle' && errors.activate ? (
                  <div>
                    <p style={{ marginTop: 20 }} className="login-error-message">
                      {errors.activate || ''}
                    </p>
                    {this.props.auth.sessionId === '' && (
                      <div style={{ marginLeft: -10, marginTop: 20 }}>
                        <Link className="login-signin-link btn" to="/">
                          Login
                        </Link>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div style={{ marginLeft: -10, marginTop: 20 }}>
                      <Link className="login-signin-link btn" to="/">
                        Login
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}

          {type === 'verify-email' && (
            <React.Fragment>
              <Heading fontSize={24} height={24}>
                Verify your Email!
              </Heading>
              <p className="login-resets">
                <span>
                  You’re almost there. We have sent an email to{' '}
                  <span className="font-bold login-dark">{message}</span>. Just click on the link on
                  the email to complete your signup . If you don’t see it, please check your spam
                  folder as well.
                </span>
              </p>
            </React.Fragment>
          )}

          {type === 'reset' && (
            <React.Fragment>
              <div style={{ marginBottom: 24, textAlign: 'center' }}>
                <div>
                  <img
                    src="/assets/reset-link-sent.svg"
                    alt="Reset Link Sent"
                    style={{ width: '42px', marginBottom: '20px' }}
                  />
                </div>
                <Heading fontSize={24} height={24}>
                  Reset link sent!
                </Heading>
              </div>
              <p className="login-resets">
                <span>
                  We have sent an email with the password reset link to{' '}
                  <span className="font-bold login-dark">{message}</span>. Click on the link on the
                  email to reset the password for your account. If you don’t see it, please check
                  your spam folder as well.
                </span>
              </p>

              <p className="login-resets">
                <span>
                  <button className="login-signin-link btn" onClick={this.resetPassword}>
                    Click here to resend the email.
                  </button>
                  <span className="ml-10">{this.state.isActionDone ? 'Done!' : ''}</span>
                  {this.state.errors.reset && (
                    <p className="login-error-message">{this.state.errors.reset || ''}</p>
                  )}
                </span>
              </p>

              <Grid justifyContent="center" className="login-t-c">
                <span style={{ opacity: 1, fontStyle: 'normal' }}>
                  <Link className="login-signin-link btn" to="/" style={{ textDecoration: 'none' }}>
                    Back to Login Page
                  </Link>
                </span>
              </Grid>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default connect(({ auth }: IAppState) => ({ auth }), {
  requestLogin,
  onLoginSuccess,
  requestUserLogout,
})(A0);
