import React, { Component } from 'react';
import { connect } from 'react-redux';
import Calender from './calender';
import { IAppState } from '../../../../core/interfaces/IAppState';
import { Row } from '@squadcast/alchemy-ui/carbon';
import './index.css';
import CalendarModal from './calendar.modal';
import {
  ISCalendarEventEntity,
  ISCalendarEvent,
  ISCalendarGap,
} from '../../../../core/interfaces/ICalendar';
import EventModal from './event.modal';
import { CalendarService } from '../../../../core/services';
import { REQUEST_ORG_CALENDAR_SUCCESS } from '../../../../core/const/organization';
import { onRequestOrganizationCalendarConsumed } from '../../../../core/actions/organization/calender';
import { exception } from '../../../../core/exception';
import moment from 'moment';
import { API } from '../../../../core/api';
import OnCallModal from './onCall.modal';
import { IOrganizationEvents } from '../../../../core/interfaces/IOrganization';
import { Grid, Para, Theme, DropDown, TextButton, DialogModalFrame } from 'uie/components';
import { Link, RouteComponentProps, generatePath } from 'react-router-dom';
import UnsavedChangesGuard from '../../../../components/unsavedChangesGuard';
import { AppTracker } from '../../../../shared/analytics/tracker';
import { T_WA_GS_SCHEDULES_PAGE_VIEWED } from '../../../../core/const/tracker';
import { Locale } from 'core/helpers/dateUtils';
import { EventApi, ViewApi } from '@fullcalendar/react';
import { SCHEDULES_V2_PATH } from 'views/main/routes/routes';
import { UserAccessContextValue, withUserAccess } from 'core/userAccess/UserAccessContext';
import { linkStyles } from '../navigation-flows/useUINavigationFunctionV2';

const { theme } = Theme;

type IMix = Pick<IAppState, 'organization'> & RouteComponentProps;
interface IProps extends IMix {
  onRequestOrganizationCalendarConsumed: () => Record<string, any>;
  userAccess: UserAccessContextValue;
}

interface IState {
  calendarEvents: ISCalendarEventEntity[];
  calendarEventsInitialized: boolean;
  openModal: 'calender' | 'event' | 'call' | '';
  calendarModalProps: {
    id: string;
  };
  excludeList: string[];
  eventsExcludeList: string[];
  expandCal: string[];
  eventModalProps: ISCalendarEvent | null;
  showMultiDayTimes: boolean;
  isGapsInSchedule: ISCalendarGap[];
  start_date: Date;
  showUnsavedChangesModal: boolean;
  isDirty: boolean;
  editSchedulesDisabled: boolean;
}

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
};

class CalendarPage extends Component<IProps, IState> {
  public oUsers = this.props.organization.users.u.reduce((c: any, n) => {
    c[n.id] = {
      _id: n.id,
      name: `${n.first_name} ${n.last_name}`,
      type: 'u',
    };
    return c;
  }, {});

  public oSquads = this.props.organization.squads.s.reduce((c: any, n) => {
    c[n.id] = {
      _id: n.id,
      name: n.name,
      type: 's',
    };
    return c;
  }, {});

  public oCalendar = this.props.organization.calendar.c.reduce((c: any, n) => {
    c[n.id] = {
      _id: n.id,
      name: n.name,
      color: n.colour,
      type: 's',
    };
    return c;
  }, {});

  public calendarService = new CalendarService();

  constructor(props: IProps) {
    super(props);

    this.state = {
      calendarEvents: [],
      calendarEventsInitialized: false,
      openModal: '',
      calendarModalProps: { id: '' },
      eventModalProps: null,
      excludeList: [],
      eventsExcludeList: [],
      expandCal: [],
      showMultiDayTimes: false,
      isGapsInSchedule: [],
      start_date: new Date(),
      showUnsavedChangesModal: false,
      isDirty: false,
      editSchedulesDisabled: false,
    };
  }

  public componentDidMount() {
    this.getInit();

    /** This scrollTo was needed because the scroll state from previous page does not change when
    the user navigates to the calendar page (essentially because it only loads a new view)
    */
    window.scrollTo(0, 0);

    API.socket.subscribe(API.config.organizationId);
    API.socket.bind('reload-event', ({ message }: { message: IOrganizationEvents }) => {
      if (message === 'reload-calendar-events') {
        this.getInit();
      }
    });

    const orgConfig = this.props.organization.currentOrg.o?.config;
    this.setState({ editSchedulesDisabled: !!orgConfig?.disable_edit_old_schedules });

    if (this.props.location.hash) {
      this.handleCalendarFocus(this.props.location.hash.slice(1));
    }
    AppTracker.track(T_WA_GS_SCHEDULES_PAGE_VIEWED);
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.start_date !== this.state.start_date) {
      this.getCalendarSeries(this.state.start_date);
    }
  }

  handleCalendarFocus = (calendarId: string) => {
    const excludeList = this.props.organization.calendar.c
      .filter(c => c.id !== calendarId)
      .map(c => c.id);
    const eventsExcludeList = this.state.calendarEvents
      .filter(e => e.calendar_id !== calendarId)
      .map(e => e.id);
    this.setState({ excludeList, eventsExcludeList });
  };

  getInit = () => {
    this.getCalendarGaps();
  };

  public getCalendarGaps = async () => {
    try {
      const { data: gapsResponse } = await this.calendarService.getCalendarGaps();
      this.setState({ isGapsInSchedule: gapsResponse.data });
    } catch (e) {
      exception.handle('E_GET_CALENDAR_GAPS', e);
    }
  };

  public getCalendarSeries = async (date: Date, calendarId?: string) => {
    try {
      const calendarColourMap = this.props.organization.calendar.c.reduce((c: any, n) => {
        c[n.id] = n.colour;
        return c;
      }, {});

      const eventsPromise = await Promise.all(
        // if calendarId is not passed, get events for all the calendars
        // that belong to the org excluding those in the excludeList
        // else, get events of a single calendar
        !calendarId
          ? this.props.organization.calendar.c
              .filter(calender => !this.state.excludeList.includes(calender.id))
              .map(calendar =>
                this.calendarService.getCalendarEventsAll(calendar.id, new Date(date)),
              )
          : [this.calendarService.getCalendarEventsAll(calendarId!, new Date(date))],
      );

      const calendarEvents = eventsPromise
        .reduce((c: any, { data: response }) => {
          if (response.data !== null) {
            c.push(response.data);
          }
          return c;
        }, [])
        .flat()
        .map((c: ISCalendarEventEntity) => {
          const users = {
            ...this.oUsers,
            ...this.oSquads,
          };

          let title = [...(c.user_ids || []), ...(c.squad_ids || [])]
            .map(ids => users[ids]?.name ?? '(Deleted Entity)')
            .join(',');

          if (c.is_override) title += ' (Override)';

          const startTime = new Date(c.start_time);
          const endTime = new Date(c.end_time);

          // c.allDay = moment(endTime).diff(moment(startTime), 'days') === 1;
          c.start = startTime;
          c.end = endTime;
          c.title = title.length > 1 ? title : 'No assignees';
          c.color = title.length > 1 ? `${calendarColourMap[c.calendar_id]}4e` : theme.shades.grey;

          if (!c.name) {
            c.name = title;
          }

          return c;
        });

      // if calendarId not passed, then simply set calendarEvent to state
      // else, extend the existing state to add the new events as well
      if (!calendarId) {
        this.setState({ calendarEvents, calendarEventsInitialized: true });
      } else {
        const totalEvents = [...this.state.calendarEvents, ...calendarEvents];
        this.setState({ calendarEvents: totalEvents, calendarEventsInitialized: true });
      }
    } catch (err: any) {
      exception.handle('E_GENERATE_CALENDAR_MAP', err);
    }
  };

  public onRangeChange = ({ view }: { view: ViewApi }) => {
    try {
      this.setState({ start_date: view.activeStart, eventsExcludeList: [] });
    } catch (err: any) {
      return;
    }
  };

  public onSelectEvent = async (e: { event: EventApi }) => {
    const id = e.event.id;
    const event = e.event.extendedProps as ISCalendarEventEntity;

    // closes if any calendar overlay is open
    document.body.click();

    this.setState({
      eventModalProps: {
        id,
        calendar_id: event.calendar_id,
        series_id: event.series_id,
        start_time: new Date(event.start_time),
        end_time: new Date(event.end_time),
        name: event.name,
        user_ids: event.user_ids,
        squad_ids: event.squad_ids,
        is_override: event.is_override,
      },
      openModal: 'call',
    });
  };

  public onEditEvent = async () => {
    this.hide(false)();

    if (
      !(
        this.props.userAccess.hasUpdateAccess('schedules') ||
        this.props.userAccess.hasCreateAccess('schedules')
      )
    ) {
      return;
    }

    this.setState({
      openModal: 'event',
    });
  };

  public onSelectSlot = (e: { start: Date; end: Date }) => {
    if (
      !(
        this.props.userAccess.hasUpdateAccess('schedules') ||
        this.props.userAccess.hasCreateAccess('schedules')
      ) ||
      this.state.editSchedulesDisabled
    ) {
      return;
    }

    this.setState({
      eventModalProps: {
        id: '',
        calendar_id: '',
        series_id: '',
        start_time: e.start,
        end_time: e.end,
        name: '',
        user_ids: [],
        squad_ids: [],
        is_override: false,
      },
      openModal: 'event',
    });
  };

  public addNewCalender =
    (id = '') =>
    () => {
      if (!this.props.userAccess.hasCreateAccess('schedules')) {
        return;
      }

      this.setState({
        calendarModalProps: {
          id,
        },
        openModal: 'calender',
      });
    };

  public hide = (reload: boolean) => () => {
    if (reload) {
      this.getCalendarSeries(this.state.start_date);
    }

    this.setState({
      openModal: '',
      isDirty: false,
    });
  };

  private addAllToExclusion = () => {
    const { excludeList, calendarEvents } = this.state;
    const isAllExcluded = !excludeList.length;
    if (isAllExcluded) {
      const excludedSchedules = this.props.organization.calendar.c.map(calendar => calendar.id);
      const allCalendarEvents = calendarEvents.map(event => event.id);
      this.setState({ excludeList: excludedSchedules, eventsExcludeList: allCalendarEvents });
    } else {
      this.setState({ excludeList: [], eventsExcludeList: [] });
    }
  };

  public addToExclusion = (id: string) => async () => {
    const calendarEvents = this.state.calendarEvents
      .filter(({ calendar_id }) => calendar_id === id)
      .map(event => event.id);

    let eventsExcludeList: string[];
    const { excludeList } = this.state;

    // if the calendar was already in excludeList,
    // 1. remove the calendar from excludeList
    // 2. remove all events corresponding to that calendar from eventsExcludeList
    // 3. if the calendarEvents in current state don't have any events corresponding to this calendar, get events for this calendar.
    if (excludeList.includes(id)) {
      excludeList.splice(excludeList.indexOf(id), 1);

      if (calendarEvents.length === 0) {
        await this.getCalendarSeries(this.state.start_date, id);
      }

      eventsExcludeList = this.state.eventsExcludeList.filter(
        eventId => !calendarEvents.includes(eventId),
      );

      // if calendar wasn't present in excludeList,
      // 1. add the calendar to excludeList
      // 2. add events corresponding to this calendar to eventsExcludeList
    } else {
      excludeList.push(id);
      eventsExcludeList = [...this.state.eventsExcludeList, ...calendarEvents];
    }
    this.setState({ excludeList, eventsExcludeList });
  };

  public addToEventsExclusion = (id: string) => () => {
    this.setState(({ eventsExcludeList }) => {
      if (eventsExcludeList.includes(id)) {
        eventsExcludeList.splice(eventsExcludeList.indexOf(id), 1);
      } else {
        eventsExcludeList.push(id);
      }
      return { eventsExcludeList };
    });
  };

  public addToExpandCal = (id: string) => () => {
    this.setState(({ expandCal }) => {
      if (expandCal.includes(id)) {
        expandCal.splice(expandCal.indexOf(id), 1);
      } else {
        expandCal.push(id);
      }
      return { expandCal };
    });
  };

  public beforeRender = () => {
    if (this.props.organization.calendar.action === REQUEST_ORG_CALENDAR_SUCCESS) {
      this.getCalendarSeries(this.state.start_date);
      this.props.onRequestOrganizationCalendarConsumed();
    }
  };

  public setUnsavedChanges = (showUnsavedChangesModal: boolean) =>
    this.setState({ showUnsavedChangesModal });
  public closeUnsavedChangesModal = () => this.setState({ showUnsavedChangesModal: false });
  public onDiscardChanges = () =>
    this.setState({ showUnsavedChangesModal: false, openModal: '', isDirty: false });

  checkAndSetDirty = () => {
    if (!this.state.isDirty) this.setState({ isDirty: true });
  };

  checkDirtyAndCloseModal = () => {
    if (this.state.isDirty) {
      this.setUnsavedChanges(true);
    } else {
      this.setState({
        isDirty: false,
        openModal: '',
      });
    }
  };

  private getSelectAllAttr = (allExcluded: boolean) => {
    const someExcluded = !!this.props.organization.calendar.c.filter(calendar =>
      this.state.excludeList.includes(calendar.id),
    ).length;

    if (!allExcluded) {
      if (someExcluded) {
        return {
          background: 'var(--shades-cement)',
          icon: '/icons/dash.svg',
          boxShadow: 'var(--shades-cement)',
        };
      }
      return {
        background: 'var(--shades-cement)',
        icon: '/icons/check.svg',
        boxShadow: 'var(--shades-cement)',
      };
    } else {
      return {
        background: 'var(--shades-white)',
        icon: '/icons/check_grey.svg',
        boxShadow: 'var(--shades-lightGrey)',
      };
    }
  };

  public render() {
    this.beforeRender();
    const filteredCalendarEvents = this.state.calendarEvents.filter(
      ({ id }) => !this.state.eventsExcludeList.includes(id),
    );
    const schedulesCount = this.props.organization.calendar.c.filter(calender => {
      return this.state.calendarEvents.find(({ calendar_id }) => calendar_id === calender.id);
    }).length;

    const allExcluded = this.props.organization.calendar.c.every(calendar =>
      this.state.excludeList.includes(calendar.id),
    );

    const selectAllAttr = this.getSelectAllAttr(allExcluded);
    const { editSchedulesDisabled } = this.state;

    return (
      <div className="main-container">
        <div className=" ">
          <div className="float-left">
            <Grid alignItems="center">
              <h1 className="item-box-main-heading">Schedules</h1>
              <Para
                fontSize={12}
                style={{ display: 'inline-block', fontWeight: 300, paddingLeft: 5 }}
              >
                ({Locale.namedOffset})
              </Para>
              {schedulesCount > 0 && (
                <Para
                  fontSize={20}
                  style={{ display: 'inline-block', fontWeight: 300, paddingLeft: 10 }}
                >
                  ({schedulesCount})
                </Para>
              )}
              {this.state.isGapsInSchedule.length > 0 && (
                <DropDown
                  hook={
                    <Para
                      className="ml-10 calendar-gap-alert-bar cursor-pointer"
                      color={theme.danger.default}
                    >
                      You have gaps in your schedule
                    </Para>
                  }
                  width="500px"
                  maxWidth="500px"
                  height="200px"
                  maxHeight="400px"
                >
                  <Grid
                    type="column"
                    style={{
                      padding: 16,
                    }}
                  >
                    {this.state.isGapsInSchedule.map((gap, index) => (
                      <Grid type="column" key={index} style={{ marginBottom: 16 }}>
                        <Para
                          fontWeight={500}
                          color={this.oCalendar[gap.schedule_id]?.color ?? theme.shades.black}
                        >
                          {gap.schedule_name}
                        </Para>
                        <Grid>
                          <Para fontSize={14} color={theme.shades.grey}>
                            From
                          </Para>
                          <Para
                            fontSize={14}
                            color={theme.shades.grey}
                            fontWeight={500}
                            className="ml-10"
                          >
                            {Locale.toSimpleDateTime(gap.gap.start_time)}
                          </Para>
                          <Para fontSize={14} color={theme.shades.grey} className="ml-10">
                            to
                          </Para>
                          <Para
                            fontSize={14}
                            color={theme.shades.grey}
                            fontWeight={500}
                            className="ml-10"
                          >
                            {Locale.toSimpleDateTime(gap.gap.end_time)}
                          </Para>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </DropDown>
              )}
            </Grid>
            {editSchedulesDisabled && (
              <h4 className="m-0 mb-5">
                This is a read-only page. You can create/modify/delete schedules{' '}
                <Link
                  to={generatePath(SCHEDULES_V2_PATH)}
                  style={{ ...linkStyles, textDecoration: 'none' }}
                >
                  here
                </Link>
                .
              </h4>
            )}
          </div>
        </div>
        <div className="data-display-container">
          <Row flexWidth={12} justifyContent="center">
            <Row flexWidth={9}>
              <Calender
                events={filteredCalendarEvents}
                onSelectEvent={this.onSelectEvent}
                onSelectSlot={this.onSelectSlot}
                onRangeChange={this.onRangeChange}
              />
            </Row>
            <Row flexWidth={3}>
              <div
                className="ml-20 w-1-1 border-all"
                style={{ height: 740, position: 'sticky', top: 73, overflow: 'auto' }}
              >
                <div
                  className="border-underline pt-5 pb-5 mb-10"
                  style={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'var(--shades-white)',
                    zIndex: 1,
                    minHeight: 35,
                  }}
                >
                  <Row alignItems="center" justifyContent="space-between">
                    <p className="m-0 font-bold" style={{ paddingLeft: 9 }}>
                      Schedules
                    </p>
                    <div>
                      {this.props.userAccess.hasCreateAccess('schedules') &&
                        !editSchedulesDisabled && (
                          <TextButton buttonType="ghost" onClick={this.addNewCalender()}>
                            <Para fontSize={14} fontWeight={500} color={theme.primary.default}>
                              Add Schedule
                            </Para>
                          </TextButton>
                        )}
                    </div>
                  </Row>
                </div>
                {!!this.props.organization.calendar.c.length &&
                  this.state.calendarEventsInitialized && (
                    <div className="mb-20">
                      <Row alignItems="center">
                        <div className="mr-10 ml-10">
                          <div
                            className="calendar-color-block cursor-pointer"
                            onClick={this.addAllToExclusion}
                            style={{
                              backgroundColor: selectAllAttr.background,
                              boxShadow: `0px 0px 0px 2px ${selectAllAttr.boxShadow}`,
                            }}
                          >
                            <img
                              src={selectAllAttr.icon}
                              alt="check"
                              style={{ height: !allExcluded ? 15 : 8 }}
                            />
                          </div>
                        </div>
                        <Row flexWidth={11}>
                          <div className="w-parent cursor-pointer" onClick={this.addAllToExclusion}>
                            <p className="m-0 ml-10" style={{ color: 'var(--shades-lightGrey)' }}>
                              Select / Unselect All
                            </p>
                          </div>
                        </Row>
                      </Row>
                    </div>
                  )}
                {this.props.organization.calendar.c.map(calender => {
                  const isExcluded = this.state.excludeList.indexOf(calender.id) > -1;
                  const events = this.state.calendarEvents.filter(
                    ({ calendar_id }) => calendar_id === calender.id,
                  );

                  return (
                    <div key={calender.id}>
                      <div
                        className="pa-10 hover-highlight"
                        style={{
                          position: 'sticky',
                          top: 35,
                          backgroundColor: 'var(--shades-white)',
                        }}
                      >
                        <Row alignItems="center" justifyContent="space-between">
                          <div className="mr-10">
                            <div
                              className="calendar-color-block cursor-pointer"
                              onClick={this.addToExclusion(calender.id)}
                              style={{
                                backgroundColor: !isExcluded
                                  ? calender.colour
                                  : 'var(--shades-white)',
                                boxShadow: `0px 0px 0px 2px ${
                                  !isExcluded ? calender.colour : 'var(--shades-lightGrey)'
                                }`,
                              }}
                            >
                              <img
                                src={isExcluded ? '/icons/check_grey.svg' : '/icons/check.svg'}
                                alt="check"
                                style={{ height: isExcluded ? 8 : 15 }}
                              />
                            </div>
                          </div>
                          <Row flexWidth={11}>
                            <div
                              className={`w-parent ${
                                !editSchedulesDisabled ? 'cursor-pointer' : ''
                              }`}
                              onClick={
                                !editSchedulesDisabled ? this.addNewCalender(calender.id) : () => {}
                              }
                            >
                              <p className="m-0 ml-10" style={{ color: calender.colour }}>
                                {calender.name}
                              </p>
                            </div>
                          </Row>
                          <div style={events.length === 0 ? { display: 'none' } : {}}>
                            <Row flexWidth={2}>
                              <img
                                src="/icons/dropdown.svg"
                                alt="dropdown"
                                className="cursor-pointer"
                                title="Collapse/Expand"
                                onClick={this.addToExpandCal(calender.id)}
                                width={10}
                                height={20}
                                style={{ padding: '0 5px' }}
                              />
                            </Row>
                          </div>
                        </Row>
                      </div>
                      <div
                        className="calendar-event-collapse"
                        style={this.state.expandCal.includes(calender.id) ? { height: 'auto' } : {}}
                      >
                        {events.map(event => {
                          const excludeEvent = this.state.eventsExcludeList.indexOf(event.id) > -1;
                          return (
                            <div
                              className="calendar-event-container"
                              onClick={this.addToEventsExclusion(event.id)}
                              key={event.id}
                            >
                              <Row alignItems="center" justifyContent="space-between">
                                <div className="mr-10">
                                  <div
                                    className="calendar-color-block cursor-pointer"
                                    style={{
                                      backgroundColor: !excludeEvent
                                        ? calender.colour
                                        : 'var(--shades-white)',
                                      boxShadow: `0px 0px 0px 2px ${
                                        !excludeEvent ? calender.colour : 'var(--shades-lightGrey)'
                                      }`,
                                    }}
                                  >
                                    <img
                                      src={
                                        excludeEvent ? '/icons/check_grey.svg' : '/icons/check.svg'
                                      }
                                      alt="check"
                                      style={{ height: excludeEvent ? 8 : 15 }}
                                    />
                                  </div>
                                </div>
                                <Row flexWidth={11}>
                                  <div className="w-parent cursor-pointer">
                                    <p className="m-0 ml-10" style={{ color: calender.colour }}>
                                      {event.name}
                                    </p>
                                  </div>
                                  <Row flexWidth={11}>
                                    <div className="w-parent cursor-pointer">
                                      <p className="m-0 ml-10" style={{ color: calender.colour }}>
                                        [{Locale.toShortDateWoYear(event.start)}] [
                                        {Locale.to24HrTime(event.start)}] {event.name}
                                      </p>
                                    </div>
                                  </Row>
                                </Row>
                              </Row>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Row>
          </Row>
        </div>

        <DialogModalFrame id="calendar_modal" width="600px" onClose={this.checkDirtyAndCloseModal}>
          {this.state.openModal === 'calender' && (
            <CalendarModal
              checkAndSetDirty={this.checkAndSetDirty}
              hide={this.hide}
              id={this.state.calendarModalProps.id}
            />
          )}
        </DialogModalFrame>

        <DialogModalFrame id="calendar_oncall_modal" width="600px" onClose={this.hide(false)}>
          {this.state.openModal === 'call' && (
            <OnCallModal
              {...({
                hide: this.hide,
                editEvent: this.onEditEvent,
                ...this.state.eventModalProps,
              } as any)}
            />
          )}
        </DialogModalFrame>

        <DialogModalFrame
          id="calendar_event_modal"
          width="600px"
          onClose={this.checkDirtyAndCloseModal}
        >
          {this.state.openModal === 'event' && (
            <EventModal
              checkAndSetDirty={this.checkAndSetDirty}
              {...({
                hide: this.hide,
                ...this.state.eventModalProps,
                shiftCount: this.state.calendarEvents.length,
              } as any)}
            />
          )}
        </DialogModalFrame>

        <UnsavedChangesGuard
          isManual={true}
          showModal={this.state.showUnsavedChangesModal}
          onDiscardChanges={this.onDiscardChanges}
          onModalClose={this.closeUnsavedChangesModal}
        />
      </div>
    );
  }
}

export default connect(
  ({ organization }: IAppState) => ({
    organization,
  }),
  {
    onRequestOrganizationCalendarConsumed,
  },
)(withUserAccess(CalendarPage));
