import { Link as A } from '@chakra-ui/layout';
import { IGeneralMessages } from '../GeneralMessages';

export interface IMSTeamsMeetingLinkCreated extends Pick<IGeneralMessages, 'log'> {
  children?: React.ReactNode;
}

const MSTeamsMeetingLinkCreated = ({ log, children }: IMSTeamsMeetingLinkCreated) => {
  return (
    <>
      {children}

      {log.additionalInfo?.meeting_link ? (
        <>
          Meeting link:{' '}
          <A href={log.additionalInfo?.meeting_link || ''} isExternal>
            https://teams.microsoft.com/l/meetup-join
          </A>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export { MSTeamsMeetingLinkCreated };
