import { Grid, Label, Theme } from 'uie/components';
import { API, ExtensionService, IncidentCommunicationCardsService } from 'core';
import { ICommunicationCard } from 'core/interfaces/ICommunicationCard';
import { IExtensionStatus } from 'core/interfaces/IExtensions';
import { IOrganization, IOrganizationEvents } from 'core/interfaces/IOrganization';
import { AddIcon } from 'icons';
import { Channel } from 'pusher-js';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import CardForm from './CardForm';
import { CardList } from './CardList';
import Empty from './Empty';
import useShowToast from './hooks/useShowToast';
import ShowAddingNewCard from './ShowAddingNewCard';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
const { theme } = Theme;

const CancelButton = styled.div`
  margin-top: 16px;
  padding-left: 12px;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .iconWrapper {
    cursor: pointer;
  }
`;
interface State {
  isLoading: boolean;
  isAddingNewCard: boolean;
  cards: Array<ICommunicationCard>;
  inEditCard: ICommunicationCard | null;
  slackServiceStatus: IExtensionStatus;
  isMSTeamsMeetingUserConfigured: boolean;
}

const CommunicationChannels: React.FC<{
  incidentId: string;
  organization: IOrganization;
  isSuppressedIncident: boolean;
}> = ({ incidentId, organization, isSuppressedIncident }) => {
  const route: {
    incidentId: string;
  } = useParams();

  const communicationCardService = useMemo(
    () => new IncidentCommunicationCardsService(route.incidentId),
    [route.incidentId],
  );

  const extensionService = useMemo(() => new ExtensionService(), []);

  const [state, setState] = useState<State>({
    isLoading: true,
    isAddingNewCard: false,
    cards: [],
    inEditCard: null,
    slackServiceStatus: 'not_integrated',
    isMSTeamsMeetingUserConfigured: false,
  });

  const isSlackV2Enabled = organization.config?.['slackv2'];

  const canShowSlackCard =
    state.slackServiceStatus === 'integrated' &&
    state.cards.findIndex(card => card.type === 'slack') === -1;
  const canShowCreateMSTeamsMeetingLinkCard =
    state.isMSTeamsMeetingUserConfigured &&
    state.cards.findIndex(card => card.type === 'msteams_meeting_link') === -1;
  const isCardsEmpty = state.cards.length === 0;
  const showEmptyState = isCardsEmpty && !state.isAddingNewCard && !isSuppressedIncident;
  const isCardEditingActive = !!state.inEditCard;
  const canShowAddingIcon = !isCardEditingActive && !state.isAddingNewCard && !isSuppressedIncident;

  const { showErrorToast, showSuccessToast } = useShowToast();

  const handleAddNewClick = () => {
    setState({
      ...state,
      isAddingNewCard: true,
    });
  };

  const handleCancelClick = () => {
    setState({
      ...state,
      isAddingNewCard: false,
      inEditCard: null,
    });
  };

  const handleAddNewCardSuccess = (card: ICommunicationCard) => {
    setState({
      ...state,
      isAddingNewCard: false,
      cards: [...state.cards, card],
    });
  };

  const onOpenCard = (card: ICommunicationCard) => {
    window.open(card.url, '_blank');
  };

  const getCards = async () => {
    try {
      const {
        data: { data: cards },
      } = await communicationCardService.getAll();
      setState(prevState => ({
        ...prevState,
        cards,
      }));
    } catch (e) {
      showErrorToast("Error fetching incident's communication cards");
      console.log(e);
    }
  };

  const getSlackServiceStatus = async () => {
    try {
      const { data } = !isSlackV2Enabled
        ? await extensionService.getSlackStatus()
        : await extensionService.getSlackV2Status();
      setState(prevState => ({
        ...prevState,
        slackServiceStatus: data.data.status,
      }));
    } catch (e) {
      console.log(e);
    }
  };

  const getMSTeamsExtensionStatus = async () => {
    try {
      const { data } = await extensionService.getMSTeamsConfig();
      let isMSTeamsEnabled = false,
        isMeetingLinkUserConfigured = false;

      if (data.data.is_active) {
        isMSTeamsEnabled = true;
      }
      if (data.data.from_id && data.data.from_id.length > 0) {
        isMeetingLinkUserConfigured = true;
      }

      if (isMSTeamsEnabled && isMeetingLinkUserConfigured) {
        setState(prevState => ({
          ...prevState,
          isMSTeamsMeetingUserConfigured: isMSTeamsEnabled,
        }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setCardToEdit = (card: ICommunicationCard) => {
    setState({
      ...state,
      inEditCard: card,
    });
  };

  const socketi = useRef<Channel | null>(null);

  const bindPusherEvents = () => {
    socketi.current = API.socket.subscribe(incidentId);

    socketi.current.bind('reload-event', (data: { message: IOrganizationEvents }) => {
      if (data.message === 'reload-incident') {
        getCards();
        getSlackServiceStatus();
      }
    });
  };

  const unbindPusherEvents = () => {
    if (socketi.current) {
      socketi.current.unbind('reload-event');
    }
  };

  useEffect(() => {
    getCards();
    getSlackServiceStatus();
    getMSTeamsExtensionStatus();
    bindPusherEvents();
    return () => {
      unbindPusherEvents();
    };
  }, []);

  const submitCard = async (card: ICommunicationCard) => {
    try {
      const {
        data: { data: updatedCard },
      } = await communicationCardService.update(card.id || '', card);
      setState(prevState => ({
        ...prevState,
        cards: prevState.cards.map(c => (c.id === updatedCard.id ? updatedCard : c)),
        inEditCard: null,
      }));
    } catch (e) {
      showErrorToast('Error updating card');
      console.log(e);
    }
  };

  const deleteCard = async (cardId?: string) => {
    if (!cardId) {
      return;
    }
    try {
      await communicationCardService.delete(cardId);
      setState(prevState => ({
        ...prevState,
        cards: prevState.cards.filter(card => card.id !== cardId),
        inEditCard: null,
      }));
    } catch (e) {
      showErrorToast('Error deleting card');
      console.log(e);
    }
  };

  const archiveSlackChannel = async (slackChannelId?: string) => {
    if (!slackChannelId) {
      return;
    }
    try {
      const {
        data: { data: updatedCard },
      } = !isSlackV2Enabled
        ? await extensionService.archiveSlackChannel(slackChannelId)
        : await communicationCardService.archiveSlackCommChannel(slackChannelId);
      setState(prevState => ({
        ...prevState,
        cards: prevState.cards.map(c => (c.id === updatedCard.id ? updatedCard : c)),
        inEditCard: null,
      }));
      showSuccessToast('Successfully archived slack channel');
    } catch (e) {
      showErrorToast('Error archiving slack card');
      console.log(e);
    }
  };

  const hasUpdateAccess = useUserAccess().hasUpdateAccess('incidents');

  return (
    <Grid type="column" className="incident_details__soft_card invert">
      <HeaderWrapper>
        <Label fontSize={14} color={theme.shades.black} fontWeight={500}>
          {state.isAddingNewCard ? 'Add Communication Channel' : 'Communication Channels'}
        </Label>
        {canShowAddingIcon ? (
          <NoPermissionTooltip isDisabled={hasUpdateAccess}>
            <div
              className="iconWrapper"
              onClick={hasUpdateAccess ? handleAddNewClick : () => {}}
              style={
                !hasUpdateAccess
                  ? {
                      cursor: 'not-allowed',
                      opacity: 0.6,
                    }
                  : {}
              }
            >
              <AddIcon />
            </div>
          </NoPermissionTooltip>
        ) : null}
      </HeaderWrapper>
      {showEmptyState ? <Empty handleAddNewClick={handleAddNewClick} /> : null}
      {state.isAddingNewCard ? (
        <ShowAddingNewCard
          canShowSlackCard={canShowSlackCard}
          isSlackV2Enabled={isSlackV2Enabled}
          canShowCreateMSTeamsMeetingLinkCard={canShowCreateMSTeamsMeetingLinkCard}
          handleAddNewCardSuccess={handleAddNewCardSuccess}
          resetAddNewCard={handleCancelClick}
        />
      ) : null}
      {!state.isAddingNewCard && !isCardEditingActive ? (
        <CardList
          setCardToEdit={setCardToEdit}
          cards={state.cards}
          onClickCard={onOpenCard}
          onArchiveIconClick={archiveSlackChannel}
          onDeleteIconClick={deleteCard}
          isSuppressedIncident={isSuppressedIncident}
        />
      ) : null}
      {isCardEditingActive && state.inEditCard ? (
        <CardForm
          type={state.inEditCard?.type}
          communicationCard={state.inEditCard}
          isEditMode={state.inEditCard ? true : false}
          onCancel={handleCancelClick}
          onSubmit={value =>
            submitCard({
              ...state.inEditCard,
              ...value,
            })
          }
        />
      ) : null}
      {state.isAddingNewCard || isCardEditingActive ? (
        <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
      ) : null}
    </Grid>
  );
};

export default CommunicationChannels;
