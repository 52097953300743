import * as React from 'react';
import { SVGProps } from 'react';

const SvgStatusCake = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="status-cake_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.status-cake_svg__st1{fill:url(#status-cake_svg__SVGID_00000163044576834484414930000015502276427931854469_)}'
      }
    </style>
    <linearGradient
      id="status-cake_svg__SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={4.34}
      y1={3.92}
      x2={15.008}
      y2={12.871}
      gradientTransform="matrix(1 0 0 -1 0 22)"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#ff317d',
        }}
      />
      <stop
        offset={0.336}
        style={{
          stopColor: '#ff347f',
        }}
      />
      <stop
        offset={0.538}
        style={{
          stopColor: '#ff3c84',
        }}
      />
      <stop
        offset={0.704}
        style={{
          stopColor: '#ff4a8d',
        }}
      />
      <stop
        offset={0.851}
        style={{
          stopColor: '#ff5e99',
        }}
      />
      <stop
        offset={0.985}
        style={{
          stopColor: '#ff78aa',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#ff7cac',
        }}
      />
    </linearGradient>
    <path
      d="M15.763 10.094c-.353-.235-.752-.33-1.129-.47a42.776 42.776 0 0 0-1.857-.611c-.47-.142-.611-.189-.776.329-.14.493-.352.94-.54 1.41-.118.282-.165.611-.424.823-.446.376-.917.33-1.41.141-.235-.094-.353-.117-.682-.211-.165-.07-.165-.07-.282-.094a2.448 2.448 0 0 1-.588-.189c-.235-.117-.329-.094-.4.165-.047.094-.047.118-.07.212-.165.517-.33 1.01-.494 1.528-.164.54-.352 1.105-.517 1.645-.188.565-.4 1.129-.564 1.717a54.253 54.253 0 0 1-.588 1.81c-.117.329-.165.705 0 1.034.306.635 1.105.94 1.763.353.518-.47 1.035-.964 1.552-1.41l3.597-3.175c.517-.47 1.01-.963 1.552-1.41.705-.564 1.316-1.223 1.95-1.834.495-.493.471-1.41-.093-1.763z"
      style={{
        fill: 'url(#status-cake_svg__SVGID_1_)',
      }}
    />
    <linearGradient
      id="status-cake_svg__SVGID_00000124863717308469593480000012276493118023648181_"
      gradientUnits="userSpaceOnUse"
      x1={3.792}
      y1={16.459}
      x2={14.658}
      y2={16.459}
      gradientTransform="matrix(1 0 0 -1 0 22)"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#5cf2c9',
        }}
      />
      <stop
        offset={0.383}
        style={{
          stopColor: '#5ff2cb',
        }}
      />
      <stop
        offset={0.613}
        style={{
          stopColor: '#67f4d2',
        }}
      />
      <stop
        offset={0.802}
        style={{
          stopColor: '#75f6de',
        }}
      />
      <stop
        offset={0.969}
        style={{
          stopColor: '#89f9ee',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#8efaf2',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#status-cake_svg__SVGID_00000124863717308469593480000012276493118023648181_)',
      }}
      d="M4.22 9.906c.352.235.752.33 1.128.47.611.212 1.223.423 1.857.611.47.142.612.189.776-.329.141-.493.353-.94.54-1.41.118-.282.165-.612.424-.823.447-.376.917-.33 1.41-.141.236.094.353.117.682.211.165.07.165.07.283.094.211.047.4.094.587.188.235.118.33.095.4-.164.047-.094.047-.118.07-.212.165-.517.33-1.01.494-1.528.165-.54.353-1.105.517-1.646.188-.564.4-1.128.565-1.716.188-.61.376-1.199.587-1.81.118-.329.165-.705 0-1.034-.305-.635-1.105-.94-1.763-.353-.517.47-1.034.964-1.552 1.41L7.628 4.899c-.517.47-1.01.964-1.551 1.411-.705.564-1.317 1.223-1.951 1.834-.47.493-.447 1.41.094 1.763z"
    />
  </svg>
);

export default SvgStatusCake;
