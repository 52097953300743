import * as React from 'react';
import './index.css';
import { connect } from 'react-redux';
import EditUserModal from './edit.profile.modal';
import EditNotifcationsRules from './edit.notifications.modal';
import EditOncallReminderRules from './edit.oncallreminder.modal';
import { Link, Redirect, RouteComponentProps, generatePath, withRouter } from 'react-router-dom';
import ChangePassword from './changePassword.modal';
import { IAppState } from '../../../../core/interfaces/IAppState';
import {
  IUserNotificationRule,
  IUserProfile,
  IWeeklyReportsConfig,
} from '../../../../core/interfaces/IUserData';
import { IEscalationPolicy } from '../../../../core/interfaces/IEscalationPolicy';
import { ISquad } from '../../../../core/interfaces/ISquad';
import { ISCalendar } from '../../../../core/interfaces/ICalendar';
import { exception } from '../../../../core/exception';
import {
  ApiTokenService,
  ExtensionService,
  OncallReminderService,
  UsersService,
  WeeklyReportsService,
  BillingService,
} from '../../../../core/services';
import { IApiToken } from '../../../../core/interfaces/IApiToken';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Avatar,
  DialogModalFrame,
  TextButton,
  Theme,
  IconButton,
  Grid,
  Para,
  CopyBox,
  ContainerLoad,
  SpinLoader,
  Accordion,
  NumberCount,
  Label,
  CellBlock,
  Pill,
  Heading,
  CardBlock,
  Tooltip,
} from 'uie/components';
import cx from 'classnames';
import { AppTracker } from '../../../../shared/analytics/tracker';

import {
  ESCALATION_POLICIES_LIST_PATH,
  LEGACY_SCHEDULES_PATH,
  SCHEDULES_V2_DETAIL_PATH,
  TEAMS_PATH,
  TEAM_SQUADS_PATH,
} from 'views/main/routes/routes';
import {
  T_WA_GS_USER_API_TOKEN_CHANGED,
  T_WA_GS_USER_API_TOKEN_CREATED,
  T_WA_GS_USER_API_TOKEN_DELETED,
} from '../../../../core/const/tracker';
import VerifyPhoneNumberModal from './verifyPhone.modal';
import EditPhoneNumberModal from './edit.phone.modal';
import {
  DeleteIcon,
  EscalationIcon,
  NotificationGreyEmail,
  Refresh,
  ScheduleIcon,
  SquadIcon,
  TeamIcon,
} from '../../../../icons';
import { formatDistanceStrict } from 'date-fns';
import { Locale } from 'core/helpers/dateUtils';
import UpcomingOnCallShift from './upcomingOnCallShift';
import withSchedulesForProfile, {
  ScheduleInfoForProfile,
} from '../schedules/common/GetSchedulesForProfile';
import { Schedule } from '../schedules/graphql/types';
import idcard from '../../../../icons/idcard.svg';
import { hasReadAccessToEntityInATeam } from '../navigation-flows/helpers';
import { THEME_COLORS } from 'library/theme/colors';
import { RoleText } from '../settings/teams/render/members/OBACMember/types';
import { createStandaloneToast } from '@chakra-ui/react';
import ReceiveWeeklyStats from './receiveWeeklyStats';

type IModal =
  | 'notificationRules'
  | 'oncallReminderRules'
  | 'editUser'
  | 'changePassword'
  | 'apiTokens'
  | 'editPhoneNumber'
  | 'verifyPhoneNumber';

type IMoreMenu = 'users' | 'apiTokens';

interface IProps extends Pick<IAppState, 'userInfo' | 'organization'>, RouteComponentProps {
  schedulesList: ScheduleInfoForProfile[];
}

interface IState {
  escalationPolicies: IEscalationPolicy[];
  squads: ISquad[];
  schedules: ISCalendar[];
  teams: IUserProfile['teams'];
  mtta: number;
  mttr: number;
  acknowledged: number;
  resolved: number;
  showVacationModal: boolean;
  userId: string;
  user: IUserProfile | null;
  myProfile: boolean;
  openModal: IModal | '';
  redirect: boolean;
  menu: IMoreMenu | '';
  userApiToken: IApiToken | null;
  loadingUserApiToken: boolean;
  extensions: string[];
  openTeamIndex: number;
  allowOncallReminderRulesEdit: boolean;
  oncallReminderRules: IUserNotificationRule[];
  weeklyReportsConfig: IWeeklyReportsConfig | null;
}

const toast = createStandaloneToast();

class User extends React.Component<IProps, IState> {
  _isActive = this.props.organization.plan.p!.active;
  _isLoggedInUser = false;
  _apiTokenService = new ApiTokenService();
  _extensionService = new ExtensionService();
  _userService = new UsersService();
  _oncallReminderService = new OncallReminderService();
  _weeklyReportsService = new WeeklyReportsService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      escalationPolicies: [],
      squads: [],
      schedules: [],
      teams: [],
      mtta: 0,
      mttr: 0,
      userId: '',
      user: null,
      acknowledged: 0,
      resolved: 0,
      showVacationModal: false,
      myProfile: false,
      openModal: '',
      redirect: false,
      menu: '',
      userApiToken: null,
      loadingUserApiToken: false,
      extensions: [],
      openTeamIndex: -1,
      allowOncallReminderRulesEdit: true,
      oncallReminderRules: [],
      weeklyReportsConfig: null,
    };
  }

  public componentDidMount() {
    this.getData();
    this.getOncallReminderRules();
    if (this._isLoggedInUser) {
      this.getUserApiToken();
      this.getWeeklyReportsCfg();
    }
    this.getExtensions();
  }

  public componentDidUpdate(prevProps: IProps) {
    if (
      this.props.organization.selectedTeam.teamId !== prevProps.organization.selectedTeam.teamId
    ) {
      this.getOncallReminderRules();
    }
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (this.props.userInfo.action !== nextProps.userInfo.action) {
      this.getData();
    }
  }

  public openModal = (openModal: IModal) => () => this.setState({ openModal });
  public hideModal = () => this.setState({ openModal: '' }, () => this.getData());

  public openMenu = (menu: IMoreMenu) => this.setState({ menu });
  public hideMenu = () => this.setState({ menu: '' });

  toggleTeam = (index: number) => () => {
    this.setState(({ openTeamIndex }) => {
      if (openTeamIndex === index) {
        return { openTeamIndex: -1 };
      }

      return { openTeamIndex: index };
    });
  };

  public getOncallReminderRules = async () => {
    try {
      const {
        data: {
          data: { rules: oncallReminderRules, is_global },
        },
      } = await this._oncallReminderService.getOncallReminder(
        this.props.organization.selectedTeam.teamId,
      );
      this.setState({
        oncallReminderRules,
        allowOncallReminderRulesEdit: !is_global,
      });
    } catch (error) {
      exception.handle('E_GET_ONCALL_REMINDER_RULES', error);
      this.setState({ redirect: true });
    }
  };

  public getData = async () => {
    const userId: string = (this.props as any).match.params.userId || '';
    this._isLoggedInUser = userId === this.props.userInfo.d?.id ?? '';

    try {
      const {
        data: { data: user },
      } = await this._userService.getUserById(userId);

      this.setState({
        userId: userId,
        user: user,
        escalationPolicies: user.escalation_policies,
        teams: user.teams,
        squads: user.squads,
        schedules: user.schedules || [],
        mtta: user.mtta,
        mttr: user.mttr,
        acknowledged: user.acknowledged,
        resolved: user.resolved,
      });
    } catch (err: any) {
      exception.handle('E_USER_NOT_FOUND', err);
      this.setState({ redirect: true });
    }
  };

  public getUserApiToken = async () => {
    if (!this._isLoggedInUser) {
      return;
    }

    this.setState({ loadingUserApiToken: true });

    try {
      const { data: tokenResponse } = await this._apiTokenService.userGetToken();

      const response = tokenResponse?.data ?? null;

      this.setState({
        userApiToken: response,
      });
    } catch (error: any) {
      exception.handle('E_GET_USER_API_TOKEN', error);
    } finally {
      this.setState({ loadingUserApiToken: false });
    }
  };

  public getExtensions = async () => {
    const {
      data: { result, extensions },
    } = await this._extensionService.getExtentions();
    if (result) {
      this.setState({ extensions });
    }
  };

  public getWeeklyReportsCfg = async () => {
    if (!this._isLoggedInUser) {
      return;
    }
    const isFeatureAvailable = BillingService.getRule(
      { organization: this.props.organization },
      'weekly-reports',
    );
    if (!isFeatureAvailable) {
      return;
    }

    try {
      const {
        data: { data: weeklyReportsConfig },
      } = await this._weeklyReportsService.getWeeklyReportsConfig();

      this.setState({
        weeklyReportsConfig,
      });
    } catch (error: any) {
      toast({
        status: 'error',
        title: 'Something went wrong. Please try again.',
      });
      exception.handle('E_GET_WEEKLY_REPORTS_CONFIG', error);
    }
  };

  private handleWeeklyReportsConfigChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this._isLoggedInUser || !this.state.weeklyReportsConfig) {
      return;
    }

    const prevValue = this.state.weeklyReportsConfig.enabled;
    const updatedConfig = { ...this.state.weeklyReportsConfig, enabled: e.target.checked };

    this.setState({ weeklyReportsConfig: updatedConfig });

    try {
      const {
        data: { data: weeklyReportsConfig },
      } = await this._weeklyReportsService.updateWeeklyReportsConfig({
        enabled: e.target.checked,
        // todo: implement these ⬇️ in v2
        is_all_teams_selected: e.target.checked,
        team_ids: [],
      });

      this.setState({ weeklyReportsConfig });

      const title = weeklyReportsConfig.enabled
        ? "You're now subscribed to Weekly Team Reports."
        : "You're now unsubscribed from Weekly Team Reports.";

      toast({
        status: 'success',
        title,
      });
    } catch (error) {
      toast({
        status: 'error',
        title: 'Something went wrong. Please try again.',
      });
      exception.handle('E_UPDATE_WEEKLY_REPORTS_CONFIG', error);
      const revertedConfig = { ...this.state.weeklyReportsConfig, enabled: prevValue };
      this.setState({ weeklyReportsConfig: revertedConfig });
    }
  };

  public requestUserApiToken =
    (tokenId = '') =>
    async (_: any) => {
      if (!this._isLoggedInUser) {
        return;
      }
      tokenId.length > 0
        ? AppTracker.track(T_WA_GS_USER_API_TOKEN_CHANGED)
        : AppTracker.track(T_WA_GS_USER_API_TOKEN_CREATED);

      const { userCreateToken, userRefreshToken } = this._apiTokenService;
      this.setState({ loadingUserApiToken: true });

      try {
        const { data: tokenResponse } = await (tokenId.length > 0
          ? userRefreshToken(tokenId)
          : userCreateToken());
        const response = tokenResponse?.data ?? null;

        this.setState({
          userApiToken: response,
        });
      } catch (error: any) {
        exception.handle('E_CREATE_USER_API_TOKEN', error);
      } finally {
        this.setState({ loadingUserApiToken: false });
      }
    };

  public deleteUserApiToken = (tokenId: string) => async () => {
    if (!this._isLoggedInUser) {
      return;
    }
    AppTracker.track(T_WA_GS_USER_API_TOKEN_DELETED);

    this.setState({ loadingUserApiToken: true });
    try {
      await this._apiTokenService.userDeleteToken(tokenId);
      this.setState({ userApiToken: null });
    } catch (error: any) {
      exception.handle('E_CREATE_USER_API_TOKEN', error);
    } finally {
      this.setState({ loadingUserApiToken: false });
    }
  };

  public beforeRender = () => {
    const userId = (this.props as any).match.params.userId;
    if (this.state.userId !== userId) {
      this.setState({ userId }, () => this.getData());
    }

    const { user } = this.state;
    if (user?.time_zone && user?.time_zone !== Locale.userZone) Locale.userZone = user?.time_zone;
    if (user?.date_format && user?.date_format !== Locale.dateFormat)
      Locale.dateFormat = user?.date_format;
  };

  public render() {
    this.beforeRender();
    const { theme } = Theme;
    const { openModal, menu, user, redirect } = this.state;

    if (redirect) {
      return <Redirect to="/" push={true} />;
    }

    const _onCallUsers = (() => {
      const onCallUsersSet = new Set<string>();
      Object.values(this.props.organization.calendar.onCall).forEach(users =>
        users.forEach(u => onCallUsersSet.add(u.id)),
      );
      return onCallUsersSet;
    })();

    if (!user) {
      return <ContainerLoad isLoading={true} />;
    }

    const img = (
      <Avatar
        base={80}
        reduceColorString={user.id}
        attributeHook={_onCallUsers.has(user.id)}
        attributeProps={{
          base: 8,
          backgroundColor: theme.success.default,
        }}
      >
        {`${user.first_name} ${user.last_name}`}
      </Avatar>
    );

    const separatorElement = <Para color={THEME_COLORS.secondary[300]}>{' | '}</Para>;
    const currentUser = this.props.organization.currentUser;
    const allTeams = this.props.organization.teams.t;
    const orgConfig = this.props.organization.currentOrg.o?.config;
    const hideLegacySchedules = !!orgConfig?.show_migrated_schedules;
    const currentUserAbilities = currentUser.u?.abilities.map(a => a.slug) || [];
    const hasManageTeamsAbility = currentUserAbilities.includes('manage-teams');
    const isUserPartOfTeam = (teamId: string) => {
      return (
        allTeams
          .find(team => team.id === teamId)
          ?.members.find(member => member.user_id === currentUser.u?.id) || false
      );
    };
    const getTeamDetails = (teamId: string) => allTeams.find(team => team.id === teamId);
    return (
      <div className="main-container" onClick={this.hideMenu}>
        <div className="pb-10">
          <h1 className="item-box-main-heading">
            {(() => {
              if (user.id === this.props.userInfo.d!.id) {
                return <span>My Profile</span>;
              }
              return <span>{`${user.first_name} ${user.last_name}`}'s Profile</span>;
            })()}
          </h1>
        </div>
        <Grid flexWidth={12} justifyContent="flex-start">
          <Grid flexWidth={6}>
            <div className="w-1-1" style={{ marginRight: 30, position: 'sticky', top: 10 }}>
              <div
                className="br-3"
                style={{
                  marginBottom: 20,
                  padding: '30px 20px',
                  border: '1px solid var(--shades-smoke)',
                  position: 'relative',
                }}
              >
                <div className="clearfix">
                  <div className="profile-avatar no-select" style={{ verticalAlign: 'top' }}>
                    {img}
                  </div>
                  <div className="user-details-display">
                    <p
                      className="item-box-secondary-heading"
                      style={{ margin: 0, marginBottom: 11 }}
                    >
                      {`${user.first_name} ${user.last_name}`}
                    </p>
                    <Grid
                      type="row"
                      style={{ marginBottom: 5, marginTop: 5, alignItems: 'center' }}
                    >
                      <img
                        src="/icons/email.svg"
                        style={{ width: 14, height: 14, marginRight: 5 }}
                        alt="email"
                      />
                      <Para fontWeight={600} fontSize={14} style={{ maxWidth: '35ch' }}>
                        {user.email}
                      </Para>
                      &nbsp;
                      {!user.email_verified && (
                        <Para
                          fontSize={14}
                          color={theme.acknowledge.default}
                        >{`${'(Email not verified)'}`}</Para>
                      )}
                    </Grid>
                    {user.username_for_display && (
                      <Grid
                        type="row"
                        style={{ marginBottom: 5, marginTop: 5, alignItems: 'center' }}
                      >
                        <img
                          src={idcard}
                          style={{ width: 14, height: 14, marginRight: 5 }}
                          alt="idcard"
                        />
                        <Para fontSize={14} style={{ maxWidth: '35ch' }}>
                          {user.username_for_display}
                        </Para>
                      </Grid>
                    )}
                    {user.contact?.phone_number && (
                      <Grid
                        type="row"
                        style={{ marginBottom: 5, marginTop: 5, alignItems: 'center' }}
                      >
                        <img
                          src="/icons/phone.svg"
                          style={{ width: 14, height: 14, marginRight: 5 }}
                          alt="Phone"
                        />
                        &nbsp;
                        <span className="item-box-tagline">
                          {user.contact.dial_code + ' ' + user.contact.phone_number}
                        </span>
                      </Grid>
                    )}

                    <Grid
                      type="row"
                      style={{ marginBottom: 5, marginTop: 5, alignItems: 'center' }}
                    >
                      <img
                        src="/icons/time.svg"
                        style={{ width: 14, height: 14, marginRight: 5 }}
                        alt="timezone"
                      />
                      <Para fontSize={14} color={theme.sidebar.close} fontWeight={300}>
                        {`(${Locale.namedOffsetWithAbbr}) ${user.time_zone?.replace(/_/g, ' ')}`}
                      </Para>
                    </Grid>
                    <Grid
                      type="row"
                      style={{ marginBottom: 5, marginTop: 5, alignItems: 'center' }}
                    >
                      <img
                        src="/icons/calendar.svg"
                        style={{ width: 14, height: 14, marginRight: 5 }}
                        alt="date format"
                      />
                      <Para fontSize={14} color={theme.sidebar.close} fontWeight={300}>
                        {user.date_format || Locale.dateFormat}
                      </Para>
                    </Grid>

                    {this._isLoggedInUser && !user.email_verified && (
                      <>
                        <Para fontSize={14}>
                          <img
                            src="/icons/caution.png"
                            style={{ width: 14, height: 14, marginRight: 5 }}
                            alt="caution"
                          />
                          Please verify email before adding & verifying the phone number
                        </Para>
                      </>
                    )}
                    {this._isLoggedInUser && user.email_verified && (
                      <>
                        {!user.contact?.phone_number ? (
                          <>
                            <Para fontSize={14}>
                              <img
                                src="/icons/caution.png"
                                style={{ width: 14, height: 14, marginRight: 5 }}
                                alt="caution"
                              />
                              Add a phone number to receive SMS & phone notifications
                            </Para>
                            <TextButton
                              buttonType="inverted"
                              onClick={this.openModal('editPhoneNumber')}
                              style={{ color: theme.primary.default, marginTop: 10 }}
                            >
                              Add Phone Number
                            </TextButton>
                          </>
                        ) : (
                          <>
                            {!user.phone_verified && (
                              <div>
                                <div style={{ marginTop: 5, marginBottom: 5 }}>
                                  <img
                                    src="/icons/caution.png"
                                    style={{ width: 14, height: 14, marginRight: 5 }}
                                    alt="caution"
                                  />
                                  &nbsp;
                                  <span
                                    className="item-box-tagline"
                                    style={{
                                      position: 'relative',
                                      top: -2,
                                      fontWeight: 'normal',
                                    }}
                                  >
                                    Mobile number should be verified to enable Phone & SMS
                                    notifications
                                  </span>
                                </div>
                                <TextButton
                                  buttonType="inverted"
                                  onClick={this.openModal('verifyPhoneNumber')}
                                  style={{ color: theme.primary.default }}
                                >
                                  Verify Phone Number
                                </TextButton>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {user.id === this.props.userInfo.d!.id && (
                  <div style={{ position: 'absolute', right: 10, top: 10 }}>
                    <img
                      src="/icons/greys/more.svg"
                      className="cursor-pointer"
                      onClick={event => {
                        this.openMenu('users');
                        event.stopPropagation();
                      }}
                      alt="more"
                    />
                    {menu === 'users' && (
                      <div
                        className="config-modal animated fadeIn"
                        style={{ top: 35, right: -10, width: 170 }}
                      >
                        <div
                          className="cursor-pointer no-select"
                          onClick={this.openModal('editUser')}
                        >
                          <p className="item-box-tagline" style={{ margin: 0 }}>
                            Edit
                          </p>
                        </div>
                        <div
                          className="cursor-pointer no-select"
                          onClick={this.openModal('changePassword')}
                        >
                          <p className="item-box-tagline" style={{ margin: 0 }}>
                            Change Password
                          </p>
                        </div>
                        {user.email_verified && (
                          <div
                            className="cursor-pointer no-select"
                            onClick={this.openModal('editPhoneNumber')}
                          >
                            <p className="item-box-tagline" style={{ margin: 0 }}>
                              Change Phone Number
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div style={{ border: '1px solid var(--shades-smoke)' }}>
                {this._isLoggedInUser && (
                  <ReceiveWeeklyStats
                    is_weekly_reports_enabled={this.state.weeklyReportsConfig?.enabled ?? false}
                    handleChange={this.handleWeeklyReportsConfigChange}
                  />
                )}
                <div
                  className="clearfix user-metric-block"
                  style={{ borderBottom: '1px solid var(--shades-smoke)', textAlign: 'center' }}
                >
                  <div className="user-metric-container">
                    <p className="user-metric-heading" style={{ margin: 0 }}>
                      Acknowledged
                    </p>

                    <p className="item-box-secondary-heading">{this.state.acknowledged}</p>
                  </div>
                  <div className="user-metric-container">
                    <p className="user-metric-heading" style={{ margin: 0 }}>
                      Resolved
                    </p>
                    <p className="item-box-secondary-heading">{this.state.resolved}</p>
                  </div>
                </div>
                <div className="clearfix user-metric-block" style={{ textAlign: 'center' }}>
                  <div className="user-metric-container">
                    <p className="user-metric-heading" style={{ margin: 0 }}>
                      MTTA (Last Week)
                    </p>
                    <p className="item-box-secondary-heading">
                      {this.state.mtta.toFixed(2) + ' Hrs'}
                    </p>
                  </div>
                  <div className="user-metric-container">
                    <p className="user-metric-heading" style={{ margin: 0 }}>
                      MTTR (Last Week)
                    </p>
                    <p className="item-box-secondary-heading">
                      {this.state.mttr.toFixed(2) + ' Hrs'}
                    </p>
                  </div>
                </div>
              </div>

              {this._isLoggedInUser && this._isActive && (
                <div className="br-3 mt-20" style={{ border: '1px solid #e7ecf5', padding: 20 }}>
                  <Grid alignItems="center" justifyContent="space-between" className="mb-10">
                    <Para fontSize={14}>API Refresh Token</Para>
                    {!this.state.loadingUserApiToken && (
                      <Grid>
                        {this.state.userApiToken === null && (
                          <TextButton
                            id="new_api_button"
                            buttonType="ghost"
                            onClick={this.requestUserApiToken()}
                          >
                            <Para color={theme.primary.default}>Generate new API token</Para>
                          </TextButton>
                        )}
                        {this.state.userApiToken !== null && (
                          <Grid>
                            <div className="ml-10">
                              <IconButton
                                id="user_api_revoke"
                                name="revoke"
                                onClick={this.requestUserApiToken(this.state.userApiToken.id)}
                              >
                                <Refresh height={14} width={14} />
                              </IconButton>
                            </div>
                            <div className="ml-10">
                              <IconButton
                                id="user_api_delete"
                                name="delete"
                                onClick={this.deleteUserApiToken(this.state.userApiToken.id)}
                              >
                                <DeleteIcon height={14} width={14} color={theme.danger.default} />
                              </IconButton>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  {this.state.loadingUserApiToken && (
                    <Grid justifyContent="center" alignItems="center">
                      <SpinLoader base="10px" />
                      <span className="ml-10">Loading</span>
                    </Grid>
                  )}
                  {!this.state.loadingUserApiToken && (
                    <>
                      {this.state.userApiToken !== null && (
                        <div>
                          <Grid justifyContent="space-between" alignItems="center">
                            <CopyBox>{this.state.userApiToken.token}</CopyBox>
                          </Grid>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              {this.state.user && <UpcomingOnCallShift user={this.state.user} />}
            </div>
          </Grid>
          <Grid flexWidth={8}>
            <div className="w-1-1">
              <div style={{ border: '1px solid var(--shades-smoke)', padding: 20 }}>
                <Grid justifyContent="space-between">
                  <Heading fontSize={20} style={{ lineHeight: 1.2, marginBottom: 20 }}>
                    Incident Notification Rules
                    <Tooltip
                      padding="8px"
                      width="200px"
                      label="Define how and when you are notified when an incident is assigned to you."
                    >
                      <InfoOutlineIcon w={20} h={15} mb={1} ml={1} />
                    </Tooltip>
                  </Heading>
                  {this._isLoggedInUser && (
                    <div>
                      <TextButton onClick={this.openModal('notificationRules')} buttonType="ghost">
                        Edit
                      </TextButton>
                    </div>
                  )}
                </Grid>
                <Para style={{ marginBottom: 20 }}>
                  If an incident is assigned to {this._isLoggedInUser ? 'you' : user.first_name}
                  {', '}
                  {this._isLoggedInUser ? 'you' : user.first_name} will be notified via
                </Para>
                {user.notification_rules.map(
                  (notificationRule: IUserNotificationRule, index: number) => {
                    return (
                      <div key={index} style={{ position: 'relative' }}>
                        <div
                          className="profile-rule-line"
                          style={{
                            position: 'absolute',
                            height: '100%',
                            width: 0,
                            border: `1px solid ${theme.shades.whiteSmoke}`,
                            left: 27,
                            zIndex: -1,
                          }}
                        />
                        <Grid alignItems="center" style={{ padding: 10 }}>
                          <div className="show-escalation-after-image">
                            <img src="/icons/arrow_down.svg" width="16" alt="drop-down" />
                          </div>
                          <Para fontSize={14} style={{ marginLeft: 30 }}>
                            {`${notificationRule.type}`}&nbsp;
                            {isNaN(notificationRule.time) || notificationRule.time === 0
                              ? 'immediately'
                              : `after ${formatDistanceStrict(
                                  0,
                                  notificationRule.time * 60 * 1000,
                                )}`}
                          </Para>
                        </Grid>
                      </div>
                    );
                  },
                )}
                {this.state.extensions.includes('slack') && (
                  <Para style={{ marginTop: 20, fontSize: 14, fontWeight: 600 }}>
                    Your organization has enabled the Slack extension.{' '}
                    {this._isLoggedInUser ? 'You' : user.first_name} will also be notified via the
                    selected Slack channel.
                  </Para>
                )}
              </div>
              <div
                className="mt-20 mb-20"
                style={{ border: '1px solid var(--shades-smoke)', padding: 20 }}
              >
                <Grid justifyContent="space-between">
                  <Heading fontSize={20} style={{ lineHeight: 1.2, marginBottom: 20 }}>
                    On-Call Reminder Rules
                    <Tooltip
                      padding="8px"
                      width="200px"
                      label="Define how and when you are notified about your upcoming on-call shifts."
                    >
                      <InfoOutlineIcon w={20} h={15} mb={1} ml={1} />
                    </Tooltip>
                  </Heading>
                  {this._isLoggedInUser && (
                    <div>
                      <TextButton
                        onClick={this.openModal('oncallReminderRules')}
                        buttonType="ghost"
                        disabled={!this.state.allowOncallReminderRulesEdit}
                      >
                        Edit
                      </TextButton>
                    </div>
                  )}
                </Grid>
                <Para style={{ marginBottom: 20 }}>
                  If {this._isLoggedInUser ? 'you have ' : ' ' + user.first_name + ' has '}
                  upcoming on-call shift,{' '}
                  {this.state.oncallReminderRules && this.state.oncallReminderRules.length > 0
                    ? (this._isLoggedInUser ? 'you' : user.first_name) + ' will be notified via'
                    : ''}
                </Para>
                {this.state.oncallReminderRules && this.state.oncallReminderRules.length !== 0 ? (
                  this.state.oncallReminderRules.map(
                    (notificationRule: IUserNotificationRule, index: number) => {
                      return (
                        <div key={index} style={{ position: 'relative' }}>
                          <div
                            className="profile-rule-line"
                            style={{
                              position: 'absolute',
                              height: '100%',
                              width: 0,
                              border: `1px solid ${theme.shades.whiteSmoke}`,
                              left: 27,
                              zIndex: -1,
                            }}
                          />
                          <Grid alignItems="center" style={{ padding: 10 }}>
                            <div className="show-escalation-after-image" style={{ left: '100%' }}>
                              <img src="/icons/arrow_down.svg" width="16" alt="drop-down" />
                            </div>
                            <Para fontSize={14} style={{ marginLeft: 30 }}>
                              {`${notificationRule.type}, `}
                              {isNaN(notificationRule.time) || notificationRule.time === 0
                                ? 'when the schedule starts'
                                : `${formatDistanceStrict(
                                    0,
                                    notificationRule.time * 60 * 1000,
                                  )} before it starts`}
                            </Para>
                          </Grid>
                        </div>
                      );
                    },
                  )
                ) : (
                  <CardBlock style={{ backgroundColor: theme.danger.light, maxWidth: 680 }}>
                    {this._isLoggedInUser
                      ? `You won't be notified because you have disabled notifications for upcoming
                    on-call shifts. We recommend setting a reminder to ensure you don't miss your
                    shift.`
                      : `he/she won't be notified. ${user.first_name} has disabled On-call reminder notifications.`}
                  </CardBlock>
                )}
                {!this.state.allowOncallReminderRulesEdit && (
                  <Para style={{ marginTop: 20, fontSize: 14, fontWeight: 600 }}>
                    Global On-call Reminder Rules configuration has been enabled for your
                    organization.
                  </Para>
                )}
              </div>
              {this.state.teams?.length > 0 && (
                <div
                  className="mt-20 mb-20"
                  style={{ padding: 16, border: '1px solid #e7ecf5', borderRadius: 4 }}
                >
                  <Label>Team</Label>
                  {this.state.teams.map((t, ti) => {
                    const isOpen = this.state.openTeamIndex === ti;

                    const escalations = this.state.escalationPolicies.filter(
                      e => e.owner.id === t.id,
                    );
                    const schedules: Array<any> = [
                      ...(!hideLegacySchedules
                        ? this.state.schedules
                            .filter(s => s.owner.id === t.id)
                            .map(sch => ({ ...sch, isLegacy: true }))
                        : []),
                      ...(this.props.schedulesList
                        .filter(s => s.teamID === t.id)
                        .map(sch => ({ ...sch, isLegacy: false })) ?? []),
                    ];
                    const squads = this.state.squads.filter(sq => sq.owner.id === t.id);
                    const canViewEscalationPolicies =
                      isUserPartOfTeam(t.id) &&
                      hasReadAccessToEntityInATeam('escalationpolicy', getTeamDetails(t.id));

                    const canViewSchedules =
                      isUserPartOfTeam(t.id) &&
                      hasReadAccessToEntityInATeam('schedules', getTeamDetails(t.id));

                    const canViewSquads =
                      isUserPartOfTeam(t.id) &&
                      hasReadAccessToEntityInATeam('squad', getTeamDetails(t.id));

                    return (
                      <div className="mt-10" key={ti}>
                        <Accordion
                          title={
                            <Grid
                              alignItems="center"
                              justifyContent="space-between"
                              onClick={this.toggleTeam(ti)}
                            >
                              <Grid alignItems="center">
                                <TeamIcon height={16} width={16} className="mr-10" />
                                <Para>{t.name}</Para>
                              </Grid>
                              <Grid flexWidth={4} justifyContent="flex-end">
                                <Grid flexWidth={2} alignItems="center">
                                  <EscalationIcon
                                    height={16}
                                    width={16}
                                    className="mr-5"
                                    fill={theme.shades.cement}
                                  />
                                  <NumberCount>{escalations.length}</NumberCount>
                                </Grid>
                                <Grid flexWidth={2} className="ml-10" alignItems="center">
                                  <ScheduleIcon
                                    height={16}
                                    width={16}
                                    className="mr-5"
                                    fill={theme.shades.cement}
                                  />
                                  <NumberCount>{schedules.length}</NumberCount>
                                </Grid>
                                <Grid flexWidth={2} className="ml-10" alignItems="center">
                                  <SquadIcon
                                    height={16}
                                    width={16}
                                    className="mr-5"
                                    fill={theme.shades.cement}
                                  />
                                  <NumberCount>{squads.length}</NumberCount>
                                </Grid>
                              </Grid>
                            </Grid>
                          }
                          tabProps={{
                            style: {
                              background: isOpen ? theme.shades.whiteSmoke : 'transparent',
                              padding: '8px 4px',
                            },
                          }}
                        >
                          {isOpen && (
                            <div
                              className="mt-10"
                              style={{
                                borderRadius: 4,
                                border: '1px solid var(--shades-whiteSmoke)',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                {t.roles.length > 0 && (
                                  <div
                                    style={{
                                      padding: '8px 16px',
                                    }}
                                  >
                                    <Label>Team roles</Label>
                                    <Grid alignItems="center" className="mt-10" flexWrap="wrap">
                                      {t.roles.map((role, i) => {
                                        return (
                                          <CellBlock key={i} className="mr-10">
                                            <Para fontSize={14}>
                                              {RoleText[role as keyof typeof RoleText] ?? role}
                                            </Para>
                                          </CellBlock>
                                        );
                                      })}
                                    </Grid>
                                  </div>
                                )}
                                {hasManageTeamsAbility && (
                                  <TextButton
                                    buttonType="inverted"
                                    style={{ color: theme.primary.default, marginRight: '17.5px' }}
                                    onClick={() => {
                                      this.props.history.push(
                                        generatePath(TEAMS_PATH, { id: t.id }),
                                      );
                                    }}
                                  >
                                    View Team
                                  </TextButton>
                                )}
                              </div>

                              {escalations.length > 0 && (
                                <div
                                  style={{
                                    padding: '8px 16px',
                                  }}
                                >
                                  <Grid>
                                    <EscalationIcon
                                      className="mr-5"
                                      fill={theme.shades.grey}
                                      height={14}
                                      width={14}
                                    />
                                    <Label>Escalation Policies</Label>
                                  </Grid>

                                  <Grid alignItems="center" className="mt-10" flexWrap="wrap">
                                    {escalations.map(
                                      (escalationPolicy: IEscalationPolicy, index: number) => {
                                        const escalationLink = generatePath(
                                          ESCALATION_POLICIES_LIST_PATH,
                                          {
                                            id: escalationPolicy.id,
                                          },
                                        );
                                        const isLastElement = index === escalations.length - 1;
                                        let component = (
                                          <span
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '6px',
                                            }}
                                          >
                                            <Para
                                              fontSize={14}
                                              fontWeight={500}
                                              style={{
                                                margin: '0px 6px',
                                              }}
                                            >
                                              {escalationPolicy.name}
                                            </Para>
                                            {!isLastElement && separatorElement}
                                          </span>
                                        );
                                        if (canViewEscalationPolicies) {
                                          component = (
                                            <span
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '6px',
                                              }}
                                            >
                                              <Link
                                                key={index}
                                                to={escalationLink}
                                                style={{ textDecoration: 'none' }}
                                              >
                                                <Para
                                                  fontSize={14}
                                                  fontWeight={500}
                                                  style={{
                                                    color: '#3D6DD8',
                                                    cursor: 'pointer',
                                                    margin: '0px 6px',
                                                  }}
                                                >
                                                  {escalationPolicy.name}
                                                </Para>
                                              </Link>
                                              {!isLastElement && separatorElement}
                                            </span>
                                          );
                                        }
                                        return component;
                                      },
                                    )}
                                  </Grid>
                                </div>
                              )}

                              {schedules.length > 0 && (
                                <div
                                  style={{
                                    padding: '8px 16px',
                                  }}
                                  className={cx('', {
                                    'profile-user-on-call': _onCallUsers.has(user.id),
                                  })}
                                >
                                  <Grid>
                                    <ScheduleIcon
                                      className="mr-5"
                                      fill={theme.shades.grey}
                                      height={14}
                                      width={14}
                                    />
                                    <Label>
                                      {_onCallUsers.has(user.id) ? 'On Call' : 'Schedules'}
                                    </Label>
                                  </Grid>
                                  <Grid alignItems="center" className="mt-10" flexWrap="wrap">
                                    {schedules.map((calendar: ISCalendar, index: number) => {
                                      const scheduleLink = calendar.isLegacy
                                        ? generatePath(LEGACY_SCHEDULES_PATH)
                                        : generatePath(SCHEDULES_V2_DETAIL_PATH, {
                                            scheduleId: (calendar as unknown as Schedule).ID,
                                          });
                                      const isLastElement = index === schedules.length - 1;
                                      let component = (
                                        <span
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '6px',
                                          }}
                                        >
                                          <Para
                                            fontSize={14}
                                            fontWeight={500}
                                            style={{
                                              margin: '0px 6px',
                                            }}
                                          >
                                            {calendar.name}
                                          </Para>
                                          {!isLastElement && separatorElement}
                                        </span>
                                      );
                                      if (canViewSchedules) {
                                        component = (
                                          <span
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '6px',
                                            }}
                                          >
                                            <Link
                                              key={index}
                                              to={scheduleLink}
                                              style={{ textDecoration: 'none' }}
                                            >
                                              <Para
                                                fontSize={14}
                                                fontWeight={500}
                                                style={{
                                                  color: '#3D6DD8',
                                                  cursor: 'pointer',
                                                  margin: '0px 6px',
                                                }}
                                              >
                                                {calendar.name}
                                              </Para>
                                            </Link>
                                            {!isLastElement && separatorElement}
                                          </span>
                                        );
                                      }
                                      return component;
                                    })}
                                  </Grid>
                                </div>
                              )}

                              {squads.length > 0 && (
                                <div
                                  style={{
                                    padding: '8px 16px',
                                  }}
                                >
                                  <Grid>
                                    <SquadIcon
                                      className="mr-5"
                                      fill={theme.shades.grey}
                                      height={14}
                                      width={14}
                                    />
                                    <Label>Squads</Label>
                                  </Grid>
                                  <Grid alignItems="center" className="mt-10" flexWrap="wrap">
                                    {squads.map((squad: ISquad, index: number) => {
                                      const squadLink = generatePath(TEAM_SQUADS_PATH, {
                                        id: t.id,
                                        sqid: squad.id,
                                      });
                                      const isLastElement = index === squads.length - 1;
                                      let component = (
                                        <span
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '6px',
                                          }}
                                        >
                                          <Para
                                            fontSize={14}
                                            fontWeight={500}
                                            style={{
                                              margin: '0px 6px',
                                            }}
                                          >
                                            {squad.name}
                                          </Para>
                                          {!isLastElement && separatorElement}
                                        </span>
                                      );
                                      if (canViewSquads) {
                                        component = (
                                          <span
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '6px',
                                            }}
                                          >
                                            <Link
                                              key={index}
                                              to={squadLink}
                                              style={{
                                                textDecoration: 'none',
                                              }}
                                            >
                                              <Para
                                                fontSize={14}
                                                fontWeight={500}
                                                style={{
                                                  color: '#3D6DD8',
                                                  cursor: 'pointer',
                                                  margin: '0px 6px',
                                                }}
                                              >
                                                {squad.name}
                                              </Para>
                                            </Link>
                                            {!isLastElement && separatorElement}
                                          </span>
                                        );
                                      }
                                      return component;
                                    })}
                                  </Grid>
                                </div>
                              )}
                            </div>
                          )}
                        </Accordion>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <DialogModalFrame id="edit_user_modal" width="400px" onClose={this.hideModal}>
          {openModal === 'editUser' && <EditUserModal hide={this.hideModal} editUser={user} />}
        </DialogModalFrame>
        <DialogModalFrame id="edit_notif_modal" width="500px" onClose={this.hideModal}>
          {openModal === 'notificationRules' && (
            <EditNotifcationsRules
              hide={this.hideModal}
              editNotificationRules={user.notification_rules}
            />
          )}
        </DialogModalFrame>
        <DialogModalFrame id="edit_oncall_notif_modal" width="750px" onClose={this.hideModal}>
          {openModal === 'oncallReminderRules' && (
            <EditOncallReminderRules
              hide={this.hideModal}
              editReminderRules={this.state.oncallReminderRules}
            />
          )}
        </DialogModalFrame>
        <DialogModalFrame id="edit_password_modal" width="600px" onClose={this.hideModal}>
          {openModal === 'changePassword' && <ChangePassword hide={this.hideModal} />}
        </DialogModalFrame>
        <DialogModalFrame id="edit_phone_modal" width="400px" onClose={this.hideModal}>
          {openModal === 'editPhoneNumber' && (
            <EditPhoneNumberModal
              hide={this.hideModal}
              openVerificationModal={this.openModal('verifyPhoneNumber')}
              UserInfo={user}
            />
          )}
        </DialogModalFrame>
        <DialogModalFrame id="verify_phone_modal" width="300px" onClose={this.hideModal}>
          {openModal === 'verifyPhoneNumber' && (
            <VerifyPhoneNumberModal hide={this.hideModal} UserInfo={user} />
          )}
        </DialogModalFrame>
      </div>
    );
  }
}

const withRedux = connect(({ userInfo, organization }: IAppState) => ({ userInfo, organization }))(
  withRouter(withSchedulesForProfile(User)),
);

export default withRedux;
