import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import { useSelector } from 'react-redux';
import { useIncidentSummaryContext } from '../context/hook';

const IncidentSummaryUpgradeModal: React.FC = () => {
  const appState = useSelector((state: IAppState) => state);
  const { showUpgradeModal, setShowUpgradeModal } = useIncidentSummaryContext();
  return (
    <span>
      <UpgradePlanModal
        showModal={showUpgradeModal}
        message={BillingService.getMessage(0, 'incident-summary', appState)}
        header={BillingService.getHeader(0, 'incident-summary', appState)}
        hasBillingPermission={BillingService.hasManageBillingPermission(appState)}
        onCancel={() => setShowUpgradeModal(false)}
      />
    </span>
  );
};

export default IncidentSummaryUpgradeModal;
