import { CloseIcon, CopyIcon } from '@chakra-ui/icons';
import { useClipboard, Flex, Tooltip, Button, Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
interface IProp {
  text: string;
}
export const CopyToClipboard = (props: IProp) => {
  const [value, setValue] = React.useState(props.text);
  const { hasCopied, onCopy } = useClipboard(value);
  useEffect(() => {
    setValue(props.text);
  }, [props.text]);
  return (
    <Box mb={2}>
      <Tooltip
        label={hasCopied ? 'Copied' : 'Copy'}
        background={'black'}
        color={'white'}
        placement="bottom"
      >
        <CopyIcon onClick={onCopy} ml={2} color={'rgba(15, 97, 221, 1)'} />
      </Tooltip>
    </Box>
  );
};
