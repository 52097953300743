import { Theme } from 'uie/components';
import { Box, Flex, Text } from '@chakra-ui/react';

const HttpCallHeader = ({ headers }: { headers: Array<{ key: string; value: string }> }) => {
  const { theme } = Theme;

  return (
    <>
      {headers.some(header => header.value) && !!headers.length && (
        <Box mb={2}>
          <Text color="secondary.700" textStyle="bold_800" variant="h3" pb={1}>
            ADDITIONAL HEADER
          </Text>
          {headers
            ?.filter(header => header.value)
            .map((header, index) => {
              return (
                <Flex
                  key={index}
                  border={`1px solid  ${theme.shades.lightGrey}`}
                  mb={2}
                  minW={'300px'}
                >
                  <Flex
                    minWidth={'100px'}
                    borderRight={`1px solid  ${theme.shades.lightGrey}`}
                    py={2}
                    px={2}
                    flexWrap="wrap"
                  >
                    <Text>{header.key}</Text>
                  </Flex>

                  <Flex py={2} minWidth={'100px'} px={2} flexWrap="wrap">
                    <Text maxW={'300px'}>{header.value}</Text>
                  </Flex>
                </Flex>
              );
            })}
        </Box>
      )}
    </>
  );
};

export default HttpCallHeader;
