import React, { useState, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import {
  HStack,
  Button,
  Grid,
  Text,
  Divider,
  FormControl,
  FormErrorMessage,
  VStack,
  Box,
} from '@chakra-ui/react';
import { INewSchedule, ITimeSlot, InputMaybe, ICustomPeriod } from '../../../interface/schedule';
import { rotationsCustomizePattern } from '../../../constants/rotations.customize';
import {
  mapWeekdayFilterToSelection,
  validateSlots,
  getUpdatedTimeSlot,
  getStartTimeDropDownValues as getTimeOptions,
} from '../../../helpers/helpers.customrotations';
import IntervalAndFrequency from './intervalAndFrequency';
import DailyAndMonthlySlots from './dailyAndMonthlySlots';
import WeeklySlots from './weeklySlots';
import { errorMessages } from '../../../constants/errormessage';
import { initialSlotValue } from '../../../constants/rotations.customize';
import { DayOfWeek, CustomPeriodUnit } from 'views/main/organization/schedules/graphql/types';
import { getPaddedValue } from '../../../helpers/helpers.event';

const CustomRepeat = ({ rotationId, modalHandler, initializeCustomPeriod }: any) => {
  const {
    values: { rotations },
    handleChange,
  } = useFormikContext<INewSchedule>();

  const currentRotationIndex = rotations.findIndex(rt => rt.id === rotationId);

  const customPeriodSettings = rotations.find(rt => rt.id === rotationId)?.customPeriod;

  const daysOfWeekFilter = customPeriodSettings?.daysOfWeekFilter;
  const activeWeekDays: Array<DayOfWeek> = mapWeekdayFilterToSelection(daysOfWeekFilter);
  const activeWeekDayString = activeWeekDays.join(',');
  const repeatHoursAndTime = customPeriodSettings?.repeatHoursAndTime ?? false;
  const [timeOptions, setTimeOptions] = useState(getTimeOptions());

  useEffect(() => {
    initializeCustomPeriod(customPeriodSettings);
  }, []);

  useEffect(() => {
    if (
      customPeriodSettings?.periodUnit === CustomPeriodUnit.Day ||
      customPeriodSettings?.periodUnit === CustomPeriodUnit.Month
    ) {
      handleChange({
        target: {
          name: `rotations.${currentRotationIndex}.customPeriod.repeatHoursAndTime`,
          value: false,
        },
      });
      handleChange({
        target: {
          name: `rotations.${currentRotationIndex}.customPeriod.daysOfWeekFilter`,
          value: [...rotationsCustomizePattern.weekDayFilterDefaultValues],
        },
      });
      const idOfLastSlot = customPeriodSettings?.timeSlots
        ? customPeriodSettings?.timeSlots[customPeriodSettings?.timeSlots?.length - 1]?.id ?? 0
        : 0;
      handleChange({
        target: {
          name: `rotations.${currentRotationIndex}.customPeriod.timeSlots`,
          value: [
            {
              startTime: initialSlotValue.startTime,
              endTime: initialSlotValue.endTime,
              duration: initialSlotValue.duration,
              id: idOfLastSlot ? idOfLastSlot + 1 : 1,
              isSameDay: initialSlotValue.isSameDay,
            },
          ],
        },
      });
    } else {
      const filteredSlots = customPeriodSettings?.timeSlots?.filter(slot => !!slot.dayOfWeek);
      handleChange({
        target: {
          name: `rotations.${currentRotationIndex}.customPeriod.timeSlots`,
          value: filteredSlots,
        },
      });
    }
  }, [customPeriodSettings?.periodUnit]);

  useEffect(() => {
    if (customPeriodSettings?.periodUnit === CustomPeriodUnit.Week) {
      const filteredSlots = customPeriodSettings.timeSlots.filter(slot => !!slot.dayOfWeek);
      let updatedSlots: Array<ITimeSlot> = [];
      if (filteredSlots.length > 0) {
        if (repeatHoursAndTime) {
          const firstSlot = filteredSlots[0];
          activeWeekDays.forEach((weekday, index) => {
            updatedSlots = [...updatedSlots, { ...firstSlot, dayOfWeek: weekday, id: 1 }];
          });
        } else {
          filteredSlots.forEach((slot, index) => {
            updatedSlots = [...updatedSlots, { ...slot, id: index + 1 }];
          });
        }
      } else {
        const slotObject: ITimeSlot = {
          startTime: initialSlotValue.startTime,
          endTime: initialSlotValue.endTime,
          duration: initialSlotValue.duration,
          isSameDay: initialSlotValue.isSameDay,
        };
        activeWeekDays.forEach((weekday, index) => {
          updatedSlots = [
            ...updatedSlots,
            { ...slotObject, dayOfWeek: weekday, id: repeatHoursAndTime ? 1 : index + 1 },
          ];
        });
      }
      handleChange({
        target: {
          name: `rotations.${currentRotationIndex}.customPeriod.timeSlots`,
          value: updatedSlots,
        },
      });
    }
  }, [repeatHoursAndTime]);

  useEffect(() => {
    const currentDaysWithSlots =
      customPeriodSettings?.timeSlots?.map(option => option.dayOfWeek) ?? [];
    const dayAdded = activeWeekDays.find(weekday => currentDaysWithSlots.indexOf(weekday) === -1); //a day was added
    const dayRemoved = currentDaysWithSlots.find(
      weekday => activeWeekDays.indexOf(weekday as DayOfWeek) === -1,
    ); //a day was removed

    let updatedSlots = [...(customPeriodSettings?.timeSlots ?? [])];
    if (dayRemoved) {
      updatedSlots = updatedSlots.filter(option => option.dayOfWeek !== dayRemoved);
    }
    if (dayAdded) {
      const maxIdFromSlots = Math.max(
        ...(customPeriodSettings?.timeSlots.map(slot => slot.id ?? 0) ?? [0]),
      );
      updatedSlots.push({
        startTime: initialSlotValue.startTime,
        endTime: initialSlotValue.endTime,
        duration: initialSlotValue.duration,
        id: isFinite(maxIdFromSlots) ? maxIdFromSlots + 1 : 1,
        isSameDay: initialSlotValue.isSameDay,
        dayOfWeek: dayAdded,
      });
    }
    handleChange({
      target: {
        name: `rotations.${currentRotationIndex}.customPeriod.timeSlots`,
        value: updatedSlots,
      },
    });
  }, [activeWeekDayString]);

  const timeChangeHandler = (
    slotId: number | undefined,
    selectedValue: any,
    timefield: 'startTime' | 'endTime',
  ) => {
    if (selectedValue.__isNew__) {
      const dropdownValue = selectedValue.value;
      const hoursPart = dropdownValue.split(':')[0];
      const minutesPart = dropdownValue.split(':')[1];
      const hours = Number.parseInt(hoursPart);
      const minutes = Number.parseInt(minutesPart);
      if (isNaN(hours) || isNaN(minutes)) {
        return;
      }
      if (hours < 0 || hours > 24 || minutes < 0 || minutes > 60) {
        return;
      }
      const updatedDuration = hours * 60 + minutes;
      const updatedTimeStamp = [getPaddedValue(hours), getPaddedValue(minutes)].join(':');
      const newDropDownOption = {
        label: updatedTimeStamp,
        value: updatedTimeStamp,
        duration: updatedDuration,
      };
      const updatedDropDownOptions = [...timeOptions, newDropDownOption].filter(
        (option, index, self) => self.indexOf(option) === index,
      );
      setTimeOptions(updatedDropDownOptions);
    }
    const currentSlots = [...(customPeriodSettings?.timeSlots ?? [])];
    let updatedSlots = [...currentSlots];

    updatedSlots = currentSlots.map((slot: ITimeSlot) =>
      slot.id === slotId ? getUpdatedTimeSlot(slot, selectedValue, timefield) : slot,
    );

    handleChange({
      target: {
        name: `rotations.${currentRotationIndex}.customPeriod.timeSlots`,
        value: updatedSlots,
      },
    });
  };

  const addSlotHandler = (weekday: InputMaybe<DayOfWeek> | undefined) => {
    const maxIdFromSlots = Math.max(
      ...(customPeriodSettings?.timeSlots.map(slot => slot.id ?? 1) ?? [1]),
    );
    const slotObject: ITimeSlot = {
      startTime: initialSlotValue.startTime,
      endTime: initialSlotValue.endTime,
      duration: initialSlotValue.duration,
      isSameDay: initialSlotValue.isSameDay,
      id: maxIdFromSlots + 1,
      dayOfWeek: weekday,
    };

    const currentSlots = [...(customPeriodSettings?.timeSlots ?? [])];
    let updatedSlots = [...currentSlots];

    if (!repeatHoursAndTime) {
      updatedSlots = [...updatedSlots, slotObject];
    } else {
      activeWeekDays.forEach(weekday => {
        updatedSlots = [...updatedSlots, { ...slotObject, dayOfWeek: weekday }];
      });
    }
    handleChange({
      target: {
        name: `rotations.${currentRotationIndex}.customPeriod.timeSlots`,
        value: updatedSlots,
      },
    });
  };

  const removeSlotHandler = (slotId: number | undefined) => {
    if (!slotId) return;
    const updatedSlots = customPeriodSettings?.timeSlots.filter((slot: any) => slotId !== slot.id);
    handleChange({
      target: {
        name: `rotations.${currentRotationIndex}.customPeriod.timeSlots`,
        value: updatedSlots,
      },
    });
  };

  const uniqueWeekdays = Array.from(
    new Set((customPeriodSettings?.timeSlots ?? []).map((slot: ITimeSlot) => slot.dayOfWeek)),
  ).filter(weekday => !!weekday);

  const slotsByWeekdays =
    !repeatHoursAndTime && customPeriodSettings?.periodUnit === CustomPeriodUnit.Week
      ? uniqueWeekdays.map(weekday => ({
          day: weekday,
          slots: customPeriodSettings.timeSlots.filter(s => s.dayOfWeek === weekday),
        }))
      : [];

  const slotsForRepeatHoursAndTime = customPeriodSettings?.timeSlots?.filter(
    (slot: ITimeSlot) => slot.dayOfWeek && slot.dayOfWeek === activeWeekDays[0],
  );

  const areSlotsValid =
    !repeatHoursAndTime && customPeriodSettings?.periodUnit === CustomPeriodUnit.Week
      ? slotsByWeekdays.every(option => validateSlots(option.slots).areSlotsValid)
      : validateSlots(
          customPeriodSettings?.periodUnit === CustomPeriodUnit.Week
            ? slotsForRepeatHoursAndTime
            : customPeriodSettings?.timeSlots,
        ).areSlotsValid;

  const slotsErrorMessage = useMemo(() => {
    if (!areSlotsValid) {
      const arrSlotsErrorType: Array<string> = [];

      if (!repeatHoursAndTime && customPeriodSettings?.periodUnit === CustomPeriodUnit.Week) {
        slotsByWeekdays.forEach(option => {
          arrSlotsErrorType.push(validateSlots(option.slots).errorType);
        });
      } else {
        arrSlotsErrorType.push(
          validateSlots(
            customPeriodSettings?.periodUnit === CustomPeriodUnit.Week
              ? slotsForRepeatHoursAndTime
              : customPeriodSettings?.timeSlots,
          ).errorType,
        );
      }

      const firstErrorType = arrSlotsErrorType[0];

      return (
        errorMessages.rotations.invalidSlotConfiguration.find(
          errorObj => errorObj.type === firstErrorType,
        )?.message ?? ''
      );
    }
    return '';
  }, [
    areSlotsValid,
    repeatHoursAndTime,
    customPeriodSettings,
    slotsByWeekdays,
    slotsForRepeatHoursAndTime,
  ]);

  return (
    <>
      <Text size="lg" fontWeight="800" mt={2} color="#09325E" p={3}>
        {rotationsCustomizePattern.customRepeat.heading}
      </Text>
      <IntervalAndFrequency rotationId={rotationId} />
      <Divider w="100%" />
      {['day', 'month'].includes(customPeriodSettings?.periodUnit ?? '') && (
        <DailyAndMonthlySlots
          inputSlots={customPeriodSettings?.timeSlots}
          timeChangeHandler={timeChangeHandler}
          addSlotHandler={addSlotHandler}
          removeSlotHandler={removeSlotHandler}
          timeOptions={timeOptions.sort((option1, option2) => option1.duration - option2.duration)}
          areSlotsValid={areSlotsValid}
        />
      )}
      {customPeriodSettings?.periodUnit === CustomPeriodUnit.Week && (
        <WeeklySlots
          inputSlots={repeatHoursAndTime ? slotsForRepeatHoursAndTime : slotsByWeekdays}
          timeChangeHandler={timeChangeHandler}
          addSlotHandler={addSlotHandler}
          removeSlotHandler={removeSlotHandler}
          repeatHoursAndTime={repeatHoursAndTime}
          timeOptions={timeOptions.sort((option1, option2) => option1.duration - option2.duration)}
          switchChangeHandler={() =>
            handleChange({
              target: {
                name: `rotations.${currentRotationIndex}.customPeriod.repeatHoursAndTime`,
                value: !repeatHoursAndTime,
              },
            })
          }
        />
      )}
      <FormControl isInvalid={!areSlotsValid}>
        <FormErrorMessage pl={3}>{slotsErrorMessage}</FormErrorMessage>
        <HStack
          justifyContent="flex-end"
          marginTop={5}
          marginBottom={4}
          marginLeft={4}
          marginRight={4}
        >
          <Button variant="invertedDefault" value="cancel" onClick={modalHandler}>
            {rotationsCustomizePattern.customRepeat.buttons.cancel}
          </Button>
          <Button
            variant="default"
            value="done"
            onClick={modalHandler}
            isDisabled={
              !customPeriodSettings?.periodUnit ||
              (customPeriodSettings?.periodUnit === CustomPeriodUnit.Week &&
                activeWeekDays.length == 0) ||
              !areSlotsValid
            }
          >
            {rotationsCustomizePattern.customRepeat.buttons.done}
          </Button>
        </HStack>
      </FormControl>
    </>
  );
};

export default CustomRepeat;
