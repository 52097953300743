import * as React from 'react';
import { SVGProps } from 'react';

const SvgNewrelic = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#00ac69',
      }}
      d="M15.328 6.923v6.154L10 16.154V20l8.66-5V5z"
    />
    <path
      style={{
        fill: '#1ce783',
      }}
      d="m10 3.847 5.328 3.076L18.661 5 10 0 1.34 5l3.33 1.923z"
    />
    <path
      style={{
        fill: '#1d252c',
      }}
      d="M6.669 11.924v6.154L10 20V10L1.34 5v3.847z"
    />
  </svg>
);

export default SvgNewrelic;
