import { IAppState } from 'core/interfaces/IAppState';

export enum OwnerType {
  Squad = 'squad',
  Team = 'team',
  User = 'user',
}

export interface OwnerOption {
  name: string;
  id: string;
  username?: string;
  type: OwnerType;
}

export const getTeamOptions = ({ organization }: IAppState): OwnerOption[] => {
  const selectedTeam = organization.selectedTeam.team;

  if (!selectedTeam) {
    return [];
  }

  const options = [
    {
      name: selectedTeam.name,
      id: selectedTeam.id,
      type: OwnerType.Team,
    },
  ];

  return options;
};

export const getCurrentUserOption = ({ organization }: IAppState): OwnerOption[] | undefined => {
  const currentUser = organization.currentUser.u;

  if (!currentUser) {
    return;
  }

  return [
    {
      name: `${currentUser.first_name} ${currentUser.last_name}`,
      id: currentUser.id,
      username: currentUser.username_for_display,
      type: OwnerType.User,
    },
  ];
};

export const getAllUserOptions = ({ organization }: IAppState): OwnerOption[] => {
  const selectedTeam = organization.selectedTeam.team;

  if (!organization.users.u || !selectedTeam) {
    return [];
  }

  const membersOfSelectedTeam = selectedTeam.members.map(member => member.user_id);

  const options = organization.users.u
    .filter(user => membersOfSelectedTeam.includes(user.id))
    .filter(user => user.id !== organization.currentUser.u?.id)
    .filter(user => user.role !== 'stakeholder')
    .sort((a, b) => a.first_name.localeCompare(b.first_name))
    .map(({ first_name, last_name, id, username_for_display: username }) => ({
      name: `${first_name} ${last_name}`,
      id,
      username,
      type: OwnerType.User,
    }));
  return options;
};

export const getAllUserOptionsWithCurrentUser = ({ organization }: IAppState): OwnerOption[] => {
  const selectedTeam = organization.selectedTeam.team;

  if (!organization.users.u || !selectedTeam) {
    return [];
  }

  const membersOfSelectedTeam = selectedTeam.members.map(member => member.user_id);

  const options = organization.users.u
    .filter(user => membersOfSelectedTeam.includes(user.id))
    .filter(user => user.role !== 'stakeholder')
    .sort((a, b) => a.first_name.localeCompare(b.first_name))
    .map(({ first_name, last_name, id, username_for_display: username }) => ({
      name: `${first_name} ${last_name}`,
      id,
      username,
      type: OwnerType.User,
    }));

  return options;
};

export const getSquadOptions = ({ organization }: IAppState): OwnerOption[] => {
  const selectedTeam = organization.selectedTeam.team;

  if (!organization.squads.s || !selectedTeam) {
    return [];
  }

  const membersOfSelectedTeam = selectedTeam.members.map(member => member.user_id);

  const options = organization.squads.s
    .filter(squad => {
      return squad.members.some(member => membersOfSelectedTeam.includes(member));
    })
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name }) => ({
      name,
      id,
      type: OwnerType.Squad,
    }));

  return options;
};

export const getAllOwnerOptions = (state: IAppState, soruce?: 'LCR' | 'other'): OwnerOption[] => {
  // generates array of current team, squads, and users for Owner dropdown
  // uses redux store value as data source
  const origin = soruce ?? 'other';
  const teamOptions = getTeamOptions(state);
  const userOptions =
    origin === 'LCR' ? getAllUserOptionsWithCurrentUser(state) : getAllUserOptions(state);
  const squadOptions = getSquadOptions(state);
  const currentUser = getCurrentUserOption(state) ?? [];
  if (origin === 'LCR') return [...userOptions, ...squadOptions];
  else return [...currentUser, ...userOptions, ...squadOptions];
};
