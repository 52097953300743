import {
  Image,
  Tab as ChakraTab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProps,
  Text,
  useStyleConfig,
} from '@chakra-ui/react';
import { Children, ReactNode, useMemo } from 'react';
import New from 'icons/new_icon_3.svg';
import { CrownIcon } from 'icons';
import { InfoToolTip } from './InfoToolTip';

interface ILabel {
  isDisabled?: boolean;
  name: string;
  isNew?: boolean;
  isLocked?: boolean;
  infoText?: string;
}

export type Props = TabsProps & {
  size?: 'md' | 'lg';
  variant?: 'outline' | 'filled';
  labels: ILabel[];
  customHeaderElement?: ReactNode;
  children?: ReactNode;
};

export const Tab = ({
  labels,
  size = 'md',
  variant = 'outline',
  children,
  defaultIndex,
  customHeaderElement,
  ...rest
}: Props) => {
  const s = `${variant}_${size}`;
  const index = defaultIndex ?? 1;

  const styles = useStyleConfig('Tab', { variant, size: s });

  const isOutline = variant === 'outline';
  const roundness = size === 'md' ? '3px' : '4px';

  const panels = useMemo(() => {
    if (!Children.count(children)) return [];
    return [
      <TabPanel key={0}></TabPanel>,
      ...Children.toArray(children).map((child, i) => {
        return (
          <TabPanel p={0} key={i + 1}>
            {child}
          </TabPanel>
        );
      }),
    ];
  }, [children]);
  if (!labels.length) return null;

  return (
    <Tabs
      defaultIndex={Math.max(1, index)}
      {...rest}
      variant={isOutline ? 'enclosed' : 'unstyled'}
      size={size}
    >
      <TabList alignItems="center">
        {isOutline && (
          <ChakraTab key={'void'} sx={styles} isDisabled={true}>
            <Text variant={`${isOutline} ? 'formInput_800' : 'body'`} color={'inherit'}>
              {'  '}
            </Text>
          </ChakraTab>
        )}
        {labels.map((item, index, arr) => {
          const st = {
            borderRadius: !isOutline
              ? index === 0
                ? `${roundness} 0 0 ${roundness}`
                : index === arr.length - 1
                ? `0 ${roundness} ${roundness} 0`
                : '0px'
              : '0px',
          };
          return (
            <ChakraTab
              key={index}
              sx={{ ...styles, ...(!isOutline ? st : {}) }}
              isDisabled={item.isDisabled ?? false}
            >
              {item.isLocked && (
                <>
                  <CrownIcon />
                  &nbsp;
                </>
              )}
              <Text variant={`${isOutline} ? 'formInput_800' : 'body'`} color={'inherit'}>
                {item.name}
              </Text>
              {!item.isLocked && item.isNew && (
                <Image
                  src={New}
                  position="relative"
                  top="-10px"
                  padding="0px !important"
                  background="var(--chakra-colors-brand-white) !important"
                />
              )}
              {item.infoText && <InfoToolTip label={item.infoText} />}
            </ChakraTab>
          );
        })}
        {customHeaderElement && customHeaderElement}
      </TabList>
      <TabPanels>{panels}</TabPanels>
    </Tabs>
  );
};
