import * as React from 'react';
import { SVGProps } from 'react';

const SvgTerraformLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Terraform_Logo-20x20_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.Terraform_Logo-20x20_svg__st1{fill:#5c4ee5}'}</style>
    <path
      d="m13.333 12.992 5.467-3.15V3.525l-5.467 3.158"
      style={{
        fill: '#4040b2',
      }}
    />
    <path
      className="Terraform_Logo-20x20_svg__st1"
      d="m7.267 3.525 5.467 3.158v6.308L7.267 9.833M1.2 6.317l5.467 3.158V3.158L1.2 0m6.067 16.842L12.733 20v-6.317l-5.467-3.158"
    />
  </svg>
);

export default SvgTerraformLogo20X20;
