import { CountryType } from 'library/molecules/Form/EntityCountryDropDown/type';
import { OwnerOption } from 'library/molecules/Form/EntityOwnerDropdown/helper';
import { ILCRData, LCRDataLogs, LCRDataResponse } from '../common/interface';

export interface FormState {
  name: string;
  description: string;
  country: CountryType | undefined;
  routingType: string | undefined;
  routingBehavior: ROUTING_BEHAVIOR | undefined;
  recordCall: boolean;
  messageStructure?: string;
  greetingMessage: string;
  routingNumber: string | undefined;
  keywordMapping: MappingTableTypes[];
  notifyUser: boolean;
  callDuration: DurationType | undefined;
  owner: Omit<OwnerOption, 'name'> | undefined;
}

export interface LCRListState {
  lcrData: LCRDataResponse;
  lcrDataDetails: ILCRData;
  mappingData: MappingTableTypes[];
  lcrSessionData: LCRDataLogs;
  isLoading: boolean;
  hasError: boolean;
  formState: FormState;
  enableModalIsOpen: boolean;
  lcrStatusData: {
    id: string;
    enabled: boolean;
  };
  logsSearch: string;
  logsFilter: ILogsFilterDataTypes[] | undefined;
  logsFilterApply: ILogsFilterDataTypes[] | undefined;
}

export enum ROUTING_TYPE {
  Local = 'local',
  TollFree = 'toll-free',
}

export enum ROUTING_BEHAVIOR {
  VOICEMAIL = 'voicemail',
  CALL = 'oncall',
}

export enum COUNTRY_TYPE {
  USA = 'US',
  AUSTRALIA = 'AUS',
  CANADA = 'CAN',
  INDIA = 'IN',
}

export type LCRFormType = {
  name: string;
  description: string;
  Country: CountryType;
  routingType: string;
  routingNumber: string;
  routingBehaviour: ROUTING_BEHAVIOR;
  greetingMessage: string;
  mapKeyword: any; // TODO
  schedule: any; // TODO;
  keypadNumber: number;
  alias: string;
  Service: any;
  schdule: any;
};

export interface ILogsFilterDataTypes {
  key: string;
  label: string;
  value: string;
}
export interface MappingTableTypes {
  keypadNumber: number;
  alias: string;
  service: any;
  schedule: any;
}

export interface ServiceTypes {
  apiKey: string;
  epID: string;
  name: string;
  serviceID: string;
}

export interface ScheduleTypes {
  ID: number;
  name: string;
  teamId: string;
}

export interface DurationType {
  label: string;
  value: number;
}
