import React from 'react';
import { JiraServerModal } from '.';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TextButton, SelectBox } from '@squadcast/alchemy-ui/metal';
import {
  Row,
  Col,
  Input,
  FocusBlock,
  ErrorText,
  CheckBox,
  IconButton,
} from '@squadcast/alchemy-ui/carbon';
import shortid from 'shortid';
import { IJServicemap } from '../../../../../../../core/interfaces/IJira';
import cx from 'classnames';

function renders(this: JiraServerModal) {
  const {
    jira,
    componentTabState,
    appState,
    jiraProjects,
    projectIssues,
    projectSearchString,
    issuesSearchString,
    apiToken,
  } = this.state;

  return (
    <div>
      {componentTabState === 'token' && (
        <div>
          <div
            onClick={event => {
              event.stopPropagation();
            }}
            style={{ boxShadow: 'none', position: 'static' }}
          >
            <div className="font-bold item-box-tagline cursor-pointer description-key">
              <p style={{ wordBreak: 'break-all', marginRight: 120 }}>{apiToken}</p>
              <CopyToClipboard
                onCopy={() => {
                  this.setState({
                    copied: true,
                  });
                }}
                text={apiToken}
              >
                <button className="copy-button">
                  {this.state.copied ? <span>Copied!</span> : <span>Copy</span>}
                </button>
              </CopyToClipboard>
            </div>
          </div>

          <div className="mt-10">
            {jira && jira.host.length > 0 && (
              <p className="item-box-tagline font-bold m-0" style={{ marginBottom: 5 }}>
                Successfully connected to {jira.host}
              </p>
            )}
            {this.state.errors.testFailed && (
              <ErrorText text="We haven't received any connections. Please try again" />
            )}
          </div>

          <div className="mt-20">
            <TextButton
              id="to_projects"
              disabled={this.state.apiToken.length === 0}
              onClick={this.onCheckJiraConnection}
              text={appState === 'test-connections' ? 'Test Connection' : 'Next'}
            />
          </div>
        </div>
      )}

      {componentTabState === 'project' && (
        <div>
          <div>
            <p className="item-box-tagline font-bold m-0" style={{ marginBottom: 5 }}>
              Select a Jira Project
            </p>
            <SelectBox
              id="Jira_projects"
              name="Jira projects"
              label="Jira projects"
              onChange={this.onProjectSelect}
              selected={
                this.state.selectedProject.id !== '' ? (
                  <div>{this.state.selectedProject.name}</div>
                ) : (
                  <div>Please Select a project</div>
                )
              }
              enableSearchBox={true}
              searchText={this.state.projectSearchString}
              onSearchValueChange={searchValue => {
                this.setState({ projectSearchString: searchValue });
              }}
            >
              {jiraProjects
                .filter((project: any) =>
                  projectSearchString.length > 0
                    ? project.name.toLowerCase().indexOf(projectSearchString.toLowerCase()) > -1
                    : true,
                )
                .map((project: any, index: number) => (
                  <FocusBlock value={project} key={shortid.generate()}>
                    <p className="item-box-tagline" style={{ margin: 0 }}>
                      {project.name} | ({project.key})
                    </p>
                  </FocusBlock>
                ))}
            </SelectBox>
          </div>
          {this.state.selectedProject.id.length > 0 && projectIssues.length > 0 && (
            <div className="mt-20">
              <p className="item-box-tagline font-bold m-0" style={{ marginBottom: 5 }}>
                Select an Issue for {this.state.selectedProject.name}
              </p>
              <SelectBox
                id="jira_project_issue"
                name="Jira projects issues"
                label="Jira projects issues"
                onChange={this.onIssueSelect}
                selected={
                  this.state.selectedIssue.id !== '' ? (
                    <div>{this.state.selectedIssue.name}</div>
                  ) : (
                    <div>Please Select Project Issue</div>
                  )
                }
                enableSearchBox={true}
                searchText={this.state.issuesSearchString}
                onSearchValueChange={searchValue => {
                  this.setState({ issuesSearchString: searchValue });
                }}
              >
                {projectIssues
                  .filter((issues: any) =>
                    issuesSearchString.length > 0
                      ? issues.name.toLowerCase().indexOf(issuesSearchString.toLowerCase()) > -1
                      : true,
                  )
                  .map((issue: any, index: number) => (
                    <FocusBlock value={issue} key={shortid.generate()}>
                      <p className="item-box-tagline" style={{ margin: 0 }}>
                        {issue.name}
                      </p>
                    </FocusBlock>
                  ))}
              </SelectBox>
            </div>
          )}
          <div className="mt-20">
            <TextButton
              id="to_projects"
              disabled={this.state.selectedIssue.id.length === 0}
              onClick={() => this.setState({ componentTabState: 'map' })}
              text="Next"
            />
          </div>
        </div>
      )}
      {componentTabState === 'map' && (
        <div>
          <div>
            <p className="item-box-tagline font-bold ma-0" style={{ marginBottom: 5 }}>
              Map Jira Issue to Squadcast Status
            </p>
          </div>

          <div>
            {this.state.selectedIssue.id.length === 0 && (
              <div>
                <p>Please select a Jira project and a relevant Issue Map</p>
              </div>
            )}
            {this.state.selectedIssue.id.length !== 0 && this.state.issuesMap.length === 0 && (
              <div>
                <p>Please Add default issue status to your Jira project</p>
              </div>
            )}
            {this.state.issuesMap.map((issue, index) => {
              return (
                <div className="mt-10" key={index}>
                  <Row flexWidth={12} justifyContent="center" alignItems="center">
                    <Row flexWidth={6}>{issue.jira_status}</Row>
                    <Row flexWidth={6}>
                      <div className="w-1-1">
                        <SelectBox
                          id="jira_issue_to_status_map"
                          name="issue Map"
                          label="issue Map"
                          onChange={(_, value) => this.onIssueMapSelect(value, index)}
                          selected={
                            this.state.selectedIssue.id !== '' ? (
                              <div>{issue.system_status}</div>
                            ) : (
                              <div>Please Map status</div>
                            )
                          }
                        >
                          {this.incidentStatus.map((status: any, index: number) => (
                            <FocusBlock value={status} key={shortid.generate()}>
                              <p className="item-box-tagline" style={{ margin: 0 }}>
                                {status}
                              </p>
                            </FocusBlock>
                          ))}
                        </SelectBox>
                      </div>
                    </Row>
                  </Row>
                </div>
              );
            })}
          </div>

          <div className="mt-20">
            <TextButton
              id="to_services"
              disabled={this.state.selectedIssue.id.length === 0}
              onClick={() => this.setState({ componentTabState: 'service' })}
              text="Next"
            />
          </div>
        </div>
      )}
      {componentTabState === 'service' && (
        <div>
          {/* Add incidents to JIRA (Automatic or Manual) */}
          <div>
            <p className="item-box-tagline font-bold ma-0" style={{ marginBottom: 5 }}>
              Add incidents to Jira
            </p>
            <Row alignItems="center">
              <CheckBox
                id="is_jira_manual"
                name="is manual"
                label="is_manual"
                checked={this.state.isManual}
                onChange={() => {
                  this.setState({ isManual: true });
                  this.props.checkAndSetDirty();
                }}
              >
                Manually
              </CheckBox>
              <span className="ml-10" />
              <CheckBox
                id="is_jira_automatic"
                name="is manual"
                label="is_manual"
                checked={!this.state.isManual}
                onChange={() => {
                  this.setState({ isManual: true });
                  this.props.checkAndSetDirty();
                }}
              >
                Automatic
              </CheckBox>
            </Row>
          </div>

          <div className="mt-20">
            <p className="item-box-tagline font-bold ma-0" style={{ marginBottom: 5 }}>
              Add Services to Jira
            </p>

            <div style={{ position: 'relative' }}>
              <input
                id="squad-member"
                type="text"
                autoComplete="off"
                className={'input-design '}
                placeholder="Search Services by Name"
                value={this.state.searchString}
                onChange={event => {
                  this.setState({ searchString: event.target.value });
                }}
                onFocus={() => {
                  this.setState({ showDropDown: true });
                }}
                onBlur={() => {
                  setTimeout(() => {
                    this.setState({ showDropDown: false });
                  }, 300);
                }}
              />

              {this.state.showDropDown && (
                <div className="search-drop-down">
                  {Object.values(this.serviceMap).length && (
                    <div>
                      {Object.values(this.serviceMap)
                        .filter(
                          service =>
                            this.state.selectedServices.findIndex(s => s?.id === service.id) === -1,
                        )
                        .filter(
                          service =>
                            (service?.name || '')
                              .toLowerCase()
                              .indexOf(this.state.searchString.toLowerCase()) > -1,
                        )
                        .map((service, index) => {
                          return (
                            <div
                              onClick={() =>
                                this.addToServiceArray(service?.id || '', service?.name || '')
                              }
                              className="search-drop-down-item-content no-select cursor-pointer"
                              key={index}
                            >
                              <img src="/icons/greys/services.svg" alt="service" />
                              &nbsp;
                              <span>{service?.name || ''}</span>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="mt-20">
              <Row flexWrap="wrap">
                {this.state.selectedServices.map((service, index) => {
                  return (
                    <div className="item-member" style={{ margin: 5 }} key={index}>
                      <img
                        src="/icons/greys/services.svg"
                        className="item-member-icon"
                        alt="service"
                      />
                      <span style={{ verticalAlign: 'top' }}>
                        {service?.name || 'Service Deleted'}
                      </span>
                      <img
                        className="cursor-pointer close-icon"
                        src="/icons/close.svg"
                        alt="delete service"
                        onClick={() => this.removeService(service?.id || '')}
                      />
                    </div>
                  );
                })}
              </Row>
            </div>
          </div>

          {/* JQL - Service Mapping */}
          <div className="mt-20">
            <p className="item-box-tagline font-bold ma-0" style={{ marginBottom: 5 }}>
              JQL - Service Mapping
            </p>

            <Col>
              {this.state.serviceMaps.map((sm: IJServicemap, smIndex: number) => {
                return (
                  <div className="mt-10" key={`${smIndex}`}>
                    <Row flexWidth={12} justifyContent="flex-start" alignItems="center">
                      <Row flexWidth={6}>
                        <Input
                          id={`jql-${smIndex}`}
                          name="jql"
                          label="jql"
                          placeHolder="JQL"
                          type="text"
                          value={sm.jql}
                          onChange={(_, jql: string) =>
                            this.onServicemapChange(smIndex, 'jql', jql)
                          }
                          required={true}
                        />
                      </Row>

                      <Row flexWidth={5}>
                        <div className="w-1-1 ml-10">
                          <SelectBox
                            id={`jql-to-service-map-${smIndex}`}
                            name="service map"
                            label="service Map"
                            onChange={(_, serviceId) =>
                              this.onServicemapChange(smIndex, 'service_id', serviceId)
                            }
                            selected={
                              sm.service_id !== '' ? (
                                <div>{this.serviceMap[sm.service_id].name}</div>
                              ) : (
                                <div>Please select a service</div>
                              )
                            }
                            enableSearchBox={true}
                            searchText={this.state.serviceSearchString}
                            onSearchValueChange={searchValue => {
                              this.setState({ serviceSearchString: searchValue });
                            }}
                          >
                            {Object.values(this.serviceMap)
                              .filter(
                                service =>
                                  service.name
                                    .toLowerCase()
                                    .indexOf(this.state.serviceSearchString.toLowerCase()) > -1,
                              )
                              .map(service => (
                                <FocusBlock value={service.id} key={shortid.generate()}>
                                  <p className="item-box-tagline" style={{ margin: 0 }}>
                                    {service.name}
                                  </p>
                                </FocusBlock>
                              ))}
                          </SelectBox>
                        </div>
                      </Row>

                      <Row flexWidth={1}>
                        <div className="ml-10">
                          <IconButton
                            id={`remove-servicemap-${smIndex}`}
                            name="remove"
                            size="small"
                            overRideBorder={true}
                            ariaLabel="remove mapping"
                            icon="/icons/remove.svg"
                            onClick={() => this.onServicemapDelete(smIndex)}
                          />
                        </div>
                      </Row>
                    </Row>
                  </div>
                );
              })}
              <div className={cx('', { 'mt-20': this.state.serviceMaps.length > 0 })}>
                <TextButton
                  id="add_new_rule_tag"
                  text="Add servicemap"
                  size="compact"
                  type="ghost"
                  onClick={() => this.addNewServicemap()}
                />
              </div>
            </Col>
          </div>

          {/* Save & Integrate button */}
          <div className="mt-20">
            <Row alignItems="center">
              <TextButton
                id="to_done"
                loading={this.state.loading}
                disabled={
                  this.state.selectedIssue.id.length === 0 ||
                  this.state.selectedServices.length === 0
                }
                onClick={() => this.saveIntegrations()}
                text="Save & Integrate"
              />

              {this.state.updated && <span className="ml-10">Saved!</span>}
              {this.state.errors.save && this.state.errors.save.length > 0 && (
                <span className="ml-10">
                  <ErrorText text={`Failed! ${this.state.errors.save}`} />
                </span>
              )}
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}

export default renders;
