import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const RadioTheme: ComponentStyleConfig = {
  baseStyle: {
    label: {
      fontFamily: 'Mulish',
      fontSize: '1px',
      color: 'red',
    },
    control: {
      borderRadius: '9999px',
      borderWidth: '2px',
      borderStyle: 'solid',

      boxShadow: 'none',

      borderColor: 'brand.blue',
      bg: 'brand.white',

      // Button pressed action
      // Did not use _active as it persists the properties after key release
      '&:active': {
        bg: '#0D75EB',
        borderColor: '#0D75EB',
      },

      _hover: {
        ':not(:active)': {
          borderColor: 'brand.lightBlue',
        },
      },

      _disabled: {
        bg: 'primary.400',
        borderColor: 'primary.400',

        '&:active': {
          bg: 'primary.400',
          borderColor: 'primary.400',
        },

        _hover: {
          ':not(:active)': {
            borderColor: 'primary.400',
          },
        },
      },

      _focus: {
        boxShadow: 'none',
        borderColor: 'brand.lightBlue',
      },

      _checked: {
        bg: 'brand.white',
        borderColor: 'brand.blue',

        ':active': {
          color: 'secondary.200',
          bg: 'secondary.200',
        },

        _focus: {
          color: 'primary.100',
          bg: 'primary.100',
          borderColor: 'brand.lightBlue',
        },

        _hover: {
          color: 'primary.100',
          bg: 'primary.100',
          borderColor: 'brand.lightBlue',
        },

        _disabled: {
          color: 'brand.white',
          bg: 'brand.white',
          borderColor: 'primary.400',

          '&:active': {
            bg: 'brand.white',
            borderColor: 'primary.400',
          },

          _focus: {
            bg: 'brand.white',
            borderColor: 'primary.400',
          },
          _hover: {
            bg: 'brand.white',
            borderColor: 'primary.400',
          },
        },
      },
    },
  },
  sizes: {
    md: {
      control: {
        _checked: {
          borderWidth: '4px',
        },
        width: '12px',
        height: '12px',
      },
      label: {
        fontSize: '12px',
      },
    },
    lg: {
      control: {
        _checked: {
          borderWidth: '5px',
        },
        width: '16px',
        height: '16px',
      },
      label: {
        fontSize: '16px',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
