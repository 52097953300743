import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.886 5.388c1.014.843 1.752 1.437 2.226 2.872.975.58 2.187 1.437 2.398 1.74-.211.303-1.423 1.16-2.398 1.74-.474 1.436-1.212 2.029-2.226 2.871C17.774 13.123 20 10 20 10s-2.226-3.123-7.114-4.612zM7.114 5.388C6.1 6.231 5.362 6.825 4.888 8.26 3.913 8.84 2.7 9.697 2.49 10c.211.303 1.423 1.16 2.398 1.74.474 1.435 1.212 2.029 2.226 2.871C2.226 13.123 0 10 0 10s2.226-3.123 7.114-4.612z"
      fill="#faa344"
    />
    <g fill="#953537">
      <path d="M9.996 14.412c-2.256.04-4.347-1.816-4.355-4.372-.006-2.394 1.977-4.371 4.385-4.35a4.342 4.342 0 0 1 4.333 4.401c-.017 2.504-2.079 4.36-4.363 4.321zm-.013-2.41h1.506c.645 0 .645 0 .644-.65-.001-.36-.05-.408-.412-.408H8.32c-.058 0-.115 0-.172.002-.18.006-.28.09-.273.282.006.136 0 .273.001.409.003.307.057.364.363.365.582.002 1.164 0 1.745 0zm.041-3.879H8.538c-.703.001-.667-.077-.664.658.002.34.046.387.384.387h2.671c.302 0 .603-.002.905 0 .203 0 .306-.096.301-.304-.003-.15.002-.302-.001-.451-.006-.232-.064-.288-.3-.289h-1.81zm-.018 1.422c-.61 0-1.22-.002-1.829 0-.251 0-.298.05-.302.303-.002.15.001.302-.001.452-.001.187.085.281.274.279h3.593c.348 0 .393-.046.393-.4 0-.116.002-.23-.001-.345-.007-.234-.063-.288-.298-.288l-1.83-.001z" />
      <path d="M11.108 11.478c.001-.153.08-.24.232-.246.162-.005.259.09.264.244.004.16-.116.256-.26.252-.129-.003-.24-.086-.236-.25zM11.106 8.636c.013-.159.096-.24.248-.24.166 0 .25.104.247.255a.233.233 0 0 1-.249.239c-.153-.003-.243-.098-.246-.254zM11.107 10.043c.009-.13.071-.223.233-.226.168-.004.257.092.261.242.005.158-.107.256-.258.25-.135-.005-.234-.092-.236-.266z" />
    </g>
  </svg>
);

export default SvgComponent;
