import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { INIT_APP } from '../const/init';
import { requestUserInfo } from '../actions/auth';
import { requestUserOrganization } from '../actions/organization/initUserOrg';
import { requestRoles } from '../actions/public/roles';
import { requestIntegrations } from '../actions/public/integrations';

const initAppEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(INIT_APP),
    switchMap(() => [
      requestRoles(),
      requestIntegrations(),
      requestUserInfo(),
      requestUserOrganization(),
    ]),
  );

export default initAppEpic;
