import { Button, Link, Text } from '@chakra-ui/react';
import { ErrorText, Row } from '@squadcast/alchemy-ui/carbon';
import { Theme } from 'uie/components';
import Axios from 'axios';
import { API } from 'core/api';
import { T_WA_GS_SERVICE_DEPENDENCIES } from 'core/const/tracker';
import { IAppState } from 'core/interfaces/IAppState';
import { IService } from 'core/interfaces/IService';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppTracker } from 'shared/analytics/tracker';
import { AppConfig } from 'shared/app.config';

interface IProps extends Pick<IAppState, 'organization'> {
  dependencies: string[];
  serviceId: string;
  hide: () => void;
  checkAndSetDirty: () => void;
}

interface IState {
  showDropDown: boolean;
  searchString: string;
  dependencies: string[];
  busy: boolean;
  error: string;
}

class DependenciesModal extends Component<IProps, IState> {
  public services: { [id: string]: IService };
  constructor(props: IProps) {
    super(props);

    this.services = this.props.organization.services.s.reduce(
      (acc: Record<string, IService>, item: IService) => {
        acc[item.id] = item;
        return acc;
      },
      {},
    );

    this.state = {
      showDropDown: false,
      searchString: '',
      dependencies: this.props.dependencies || [],
      busy: false,
      error: '',
    };
  }

  public addToDependenciesArray = (id: string) => {
    this.setState(({ dependencies }) => ({
      dependencies: Array.from(new Set([...dependencies, id])),
      searchString: '',
    }));
    this.props.checkAndSetDirty();
  };

  public removeFromDependenciesArray = (id: string) => {
    this.setState(({ dependencies }) => ({
      dependencies: dependencies.filter(serviceId => serviceId !== id),
    }));
    this.props.checkAndSetDirty();
  };

  public saveDependencies = async () => {
    this.setState({ busy: true });
    try {
      await Axios.post(
        `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/services/${this.props.serviceId}/dependencies`,
        {
          data: this.state.dependencies,
        },
      );

      this.props.hide();
    } catch (error: any) {
      this.setState({
        error: error.response?.data?.meta?.error_message ?? 'Something went wrong',
        busy: false,
      });
    }

    AppTracker.track(T_WA_GS_SERVICE_DEPENDENCIES);
  };

  public render() {
    const { theme } = Theme;
    const { dependencies } = this.state;
    const serviceName = this.services[this.props.serviceId]?.name ?? 'Service';

    return (
      <div
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <div className="">
          <div className="float-left">
            <h1 className="modal-container-heading" style={{ marginRight: 10 }}>
              Service Dependencies
            </h1>
          </div>
        </div>
        <div>
          <div className="field-parent-div">
            <p
              className="modal-container-sub-heading"
              style={{ marginTop: 20, marginBottom: 10, lineHeight: 2 }}
            >
              Add Dependencies
            </p>
            <Text color="#979797" pb="8px" fontSize="xs">
              Add a list of services this service uses (upstream services) to ensure your critical
              service connections are captured in the Service Graph. Docs (
              <Link
                href="https://support.squadcast.com/services/alert-deduplication-rules/service-dependency-based-deduplication"
                target="_blank"
                rel="noopener noreferrer"
                variant="underlinedLink"
              >
                link
              </Link>
              )
            </Text>
            {dependencies.length > 0 && (
              <Text variant="italic" color={theme.font.label} pb="8px" fontSize="xs">
                {serviceName} is dependent on {dependencies.length} of the below services
              </Text>
            )}
            <div style={{ position: 'relative' }}>
              <input
                id="squad-member"
                type="text"
                className={'input-design '}
                placeholder="Search for a Service"
                autoComplete="off"
                value={this.state.searchString}
                onChange={event => {
                  this.setState({ searchString: event.target.value });
                }}
                onFocus={() => {
                  this.setState({ showDropDown: true });
                }}
                onBlur={() => {
                  setTimeout(() => {
                    this.setState({ showDropDown: false });
                  }, 300);
                }}
              />

              {this.state.showDropDown && (
                <div className="search-drop-down">
                  {this.props.organization.services.s.length && (
                    <div>
                      {this.props.organization.services.s
                        .filter(
                          service =>
                            this.props.serviceId !== service.id &&
                            this.state.dependencies.indexOf(service.id) === -1,
                        )
                        .filter(
                          service =>
                            service.name
                              .toLowerCase()
                              .indexOf(this.state.searchString.toLowerCase()) > -1,
                        )
                        .map((service, index) => {
                          return (
                            <div
                              onClick={() => this.addToDependenciesArray(service.id)}
                              className="search-drop-down-item-content no-select cursor-pointer"
                              key={index}
                            >
                              <img src="/icons/greys/services.svg" alt="services" />
                              &nbsp;
                              <span>{service.name}</span>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="mt-10">
              <Row flexWrap="wrap">
                {this.state.dependencies.length > 0 &&
                  this.state.dependencies
                    .sort((a: string, b: string) => {
                      const svc1: string = this.services[a]?.name ?? '';
                      const svc2: string = this.services[b]?.name ?? '';
                      return svc1.localeCompare(svc2);
                    })
                    .map((serviceId, index) => {
                      return this.services && this.services[serviceId] ? (
                        <div
                          className="item-member"
                          key={index}
                          style={{ marginTop: 5, marginRight: 10, marginLeft: 0 }}
                        >
                          <img
                            src="/icons/greys/services.svg"
                            className="item-member-icon"
                            alt="service"
                          />
                          <span>
                            {this.services[serviceId] ? this.services[serviceId].name : ''}
                          </span>
                          <img
                            className="cursor-pointer close-icon"
                            src="/icons/close.svg"
                            onClick={() => this.removeFromDependenciesArray(serviceId)}
                            alt="close"
                          />
                        </div>
                      ) : null;
                    })}
              </Row>

              <div className="mt-20">
                <Button
                  isLoading={this.state.busy}
                  onClick={this.saveDependencies}
                  loadingText="Save Dependency"
                  variant="default"
                  size="sm"
                >
                  Save Dependency
                </Button>

                {this.state.error && (
                  <div className="mt-10">
                    <ErrorText text={this.state.error} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state: IAppState) => ({
  organization: state.organization,
}))(DependenciesModal);
