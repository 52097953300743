import React, { Component } from 'react';
import { IComponentState } from '../../../../../core/interfaces/IComponentState';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { FileUploadService, IncidentWarroomService } from '../../../../../core/services';
import { IWRCMessage } from '../../../../../core/interfaces/IWarroom';
import { exception } from '../../../../../core/exception';
import { API } from '../../../../../core/api';
import './index.css';
import { watcherEvents } from '../../incidentDetails/renders/watcher';
import render from './render.index';
import { deepCopy } from 'core/helpers';
import { Theme } from 'uie/components';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_GS_INCIDENT_NOTES_STARRED } from '../../../../../core/const/tracker';
import { SettingTab } from '../../settings/const';
import {
  IncidentWatcherContext,
  IncidentWatcherContextProps,
} from '../Responses/IncidentWatcherContext';
import { ITransformedMessages } from './types';
import { Channel } from 'pusher-js';
const { theme } = Theme;
interface IProps extends Pick<IAppState, 'organization' | 'userInfo'> {
  incidentId: string;
  focusMessageId: string | null;
  notes: IWRCMessage[];
  setNotes: (newResponse: IWRCMessage[]) => void;
}

interface IState {
  responses: { [key: string]: IWRCMessage };
  transformed: ITransformedMessages[];
  componentState: Record<'warroom' | 'responses', IComponentState>;
  onlyStarred: boolean;
  hoverMessage: string;
  editMessageId: string;
  alertNoteId: string;
  previewImageSrc: string;
}

export class Responses extends Component<IProps, IState, IncidentWatcherContextProps> {
  public fileUploadService = new FileUploadService();
  static contextType = IncidentWatcherContext;
  context!: IncidentWatcherContextProps;

  public _onCallUsers = (() => {
    const onCallUsersSet = new Set<string>();
    Object.values(this.props.organization.calendar.onCall).forEach(users =>
      users.forEach(u => onCallUsersSet.add(u.id)),
    );
    return onCallUsersSet;
  })();

  private _warroomService = new IncidentWarroomService(this.props.incidentId);
  public _responseContainer: any = React.createRef();

  public _messageRefs: { [key: string]: React.RefObject<any> } = {};

  private _currentUserId = this.props.userInfo.d?.id as string;
  private _socketConnection: null | Channel = null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      transformed: [],
      responses: {},
      componentState: {
        responses: 'busy',
        warroom: 'busy',
      },
      onlyStarred: false,
      hoverMessage: '',
      editMessageId: '',
      alertNoteId: '',
      previewImageSrc: '',
    };
  }

  public render = render;

  public orgStakeholders = this.props.organization.stakeholders?.stk ?? [];

  componentDidMount() {
    this.suscribeToPusher(false);
    this.formatGetAllMessages(this.props.notes, false, false);
  }

  componentWillUnmount() {
    this._socketConnection?.unbind_all();
    this._socketConnection?.unsubscribe();
  }

  suscribeToPusher = (shouldGetMessage = true) => {
    this._socketConnection = API.socket.subscribe(this.props.incidentId);
    this._socketConnection.bind('reload-event', ({ message }: { message: string }) => {
      if (message.includes('reload-incident-warroom')) {
        this.handlePusherEvents(message);
      }
    });
    if (shouldGetMessage) {
      this.getAllMessages(false);
    }
  };

  fetchUsersForMyTeam = (usersPool: any, teamUserIds: any) => {
    const keySet = new Set(teamUserIds.map((obj: any) => obj.user_id));
    const commonObjects = usersPool.filter((obj: any) => keySet.has(obj.id));
    return commonObjects ?? [];
  };

  createEntityMap = () => {
    const myTeam = this.props.organization.teams.t.filter(
      (teamData: any) => teamData.id === this.props.organization.selectedTeam.teamId,
    );
    return {
      users: this.fetchUsersForMyTeam(this.props?.organization?.users?.u, myTeam[0].members).reduce(
        (c: any, u: any) => {
          c[u.id] = {
            name: `${u.first_name} ${u.last_name}`,
            _id: u.id,
            type: 'users',
            encoder: '@U',
            username: u.username_for_display,
          };
          return c;
        },
        {},
      ),
      squads: this.props.organization.squads.s.reduce((c: any, squad) => {
        c[squad.id] = {
          name: `${squad.name}`,
          teamId: squad.owner.id,
          _id: squad.id,
          type: 'squads',
          encoder: '@SQ',
        };
        return c;
      }, {}),
      teams: [this.props.organization.selectedTeam].reduce((c: any, team) => {
        c[team.teamId] = {
          name: `${team.team?.name}`,
          _id: team.teamId,
          type: 'teams',
          encoder: '@T',
        };
        return c;
      }, {}),
      stakeholders: this.orgStakeholders?.reduce((c: any, stakeholder) => {
        c[stakeholder.id] = {
          name: `${stakeholder.name}`,
          _id: stakeholder.id,
          type: 'stakeholders',
          encoder: '@SG',
        };
        return c as any;
      }, {}),
    };
  };

  canFollowTeamsAndSquadsLink = () => {
    const { organization } = this.props;
    const currentUser = organization.currentUser.u;
    const currentUserAbilitiesSlug = currentUser?.abilities.map(a => a.slug) || [];
    const isOrgPlanActive = organization.plan.p?.active;

    const filteredTabs = SettingTab.filter(t => t.name == 'Teams').filter(t => {
      if (currentUser?.role == 'account_owner') {
        return isOrgPlanActive ? true : !t.requiresActiveOrg;
      } else {
        return t.role === 'user' ? true : currentUserAbilitiesSlug.includes(t.requires as any);
      }
    });

    return filteredTabs.length > 0;
  };

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.incidentId !== prevProps.incidentId) {
      this._warroomService = new IncidentWarroomService(this.props.incidentId);
      this.componentDidMount();
    }
    if (this.props.focusMessageId !== prevProps.focusMessageId && this.props.focusMessageId) {
      this.focusMessage(this.props.focusMessageId);
    }

    if (prevProps.organization.users.u.length !== this.props.organization.users.u.length) {
      this._entityMap = this.createEntityMap();
    }

    /** Added 2 conditions to handle the syncing of notes when visiting a parent incident from child
    and vice versa */

    // Following condition will trigger an update of notes
    if (
      prevProps.notes.length !== this.props.notes.length ||
      !prevProps.notes.every((note, index) => note.id === this.props.notes[index].id)
    ) {
      this.componentDidMount();
    }

    // Following condition will trigger an update of responses (transformed notes)
    if (
      Object.keys(prevState.responses).length !== Object.keys(this.state.responses).length ||
      !Object.keys(prevState.responses).every(id => id in this.state.responses)
    ) {
      this.transformResponses();
    }
  }

  public _entityMap = this.createEntityMap();

  handlePusherEvents = async (message: string) => {
    const messageId = message.split(':')[2];
    switch (true) {
      case message.includes(':delete'):
        this.handleDeleteEvent(messageId);
        break;
      case message.includes(':star'):
      case message.includes(':unstar'):
      case message.includes(':update'):
        await this.handleUpdateEvent(messageId);
        break;
      case message.includes(':create'):
        this.getAllMessages(true);
        break;
    }
  };

  handleDeleteEvent = (messageId: string) => {
    const responses = deepCopy(this.state.responses);
    delete responses[messageId];
    this.setState({ responses }, this.transformResponses);
  };

  handleUpdateEvent = async (messageId: string) => {
    if (!this.state.responses[messageId]) return;
    try {
      const {
        data: { data: response },
      } = await this._warroomService.getSingleMessage(messageId);
      const responses = deepCopy(this.state.responses);
      responses[messageId] = response;
      this.setState({ responses }, this.transformResponses);
    } catch (err: any) {
      exception.handle('E_GET_INCIDENT_WR_MESSAGES', err);
      this.setState({
        componentState: {
          responses: 'error',
          warroom: 'idle',
        },
      });
    }
  };

  formatGetAllMessages = (
    responses: IWRCMessage[],
    isCreateEvent: boolean,
    shouldUpdateProps = true,
  ) => {
    const oldMessagesLength = this.state.transformed.length;
    if (responses.length > 0) {
      const newResponse = deepCopy(this.state.responses) as { [key: string]: IWRCMessage };
      responses.forEach((r: IWRCMessage) => {
        newResponse[r.id] = r;
      });
      if (shouldUpdateProps) {
        this.props.setNotes(Object.values(newResponse));
      }
      this.setState(
        {
          responses: newResponse,
          componentState: {
            responses: 'idle',
            warroom: 'idle',
          },
        },
        () => {
          this.transformResponses(shouldUpdateProps);
          if (isCreateEvent || oldMessagesLength === 0) this.scrollWindowToBottom();
        },
      );
    } else {
      this.setState({
        responses: {},
        componentState: {
          responses: 'init',
          warroom: 'idle',
        },
      });
    }
  };
  getAllMessages = async (isCreateEvent: boolean) => {
    try {
      const {
        data: { data: responses },
      } = await this.chooseAPICall(isCreateEvent);
      this.formatGetAllMessages(responses, isCreateEvent);
    } catch (err: any) {
      exception.handle('E_GET_INCIDENT_WR_MESSAGES', err);
      this.setState({
        componentState: {
          responses: 'error',
          warroom: 'idle',
        },
      });
    }
  };

  chooseAPICall = (isCreateEvent: boolean) => {
    const messagesLength = this.state.transformed.length;

    if (isCreateEvent && messagesLength > 0) {
      const lastMessage = this.state.transformed[messagesLength - 1];
      return this._warroomService.getMessageAfter(lastMessage.id);
    }
    if (this.state.onlyStarred) {
      return this._warroomService.getStarredMessages();
    }
    return this._warroomService.getAllMessages();
  };

  scrollWindowToBottom = () => {
    try {
      const steppers = () => {
        const responseWindow = this._responseContainer.current as HTMLElement;
        if (!responseWindow) {
          return;
        }
        responseWindow.scrollTop = responseWindow.scrollHeight;
      };
      requestAnimationFrame(steppers);
    } catch (err: any) {
      exception.handle('E_GET_INCIDENT_WR_MESSAGES', err);
    }
  };

  transformResponses = (shouldUpdateProps = true) => {
    try {
      const transformMessage = (message: string) => {
        if (message.includes('@SG')) {
          const pattern = /SG([\w-]+)>/;
          let stakeholderGroupId;
          let warromMessage;
          const match = message.match(pattern);
          if (match && match.length > 1) {
            const extractedString = match[1];
            stakeholderGroupId = extractedString;
            const messagePattern = /SG[^>]+>/g;
            warromMessage = message.replace(messagePattern, '').replace('<@', '');
          } else {
            console.log('Pattern not found or no match.');
          }
          return `[](/settings/teams/${this.props.organization.selectedTeam.teamId}/stakeholders/${stakeholderGroupId}) ${warromMessage}`;
        } else {
          const userReplaced = message.replace(/<@U\w{24}>/gm, (e: string) => {
            const userId = e.replace(/(<@U)|(>)/gm, '');
            return `[](/user/${userId})`;
          });
          const squadReplaced = userReplaced.replace(/<@SQ\w{24}>/gm, (e: string) => {
            const squadId = e.replace(/(<@SQ)|(>)/gm, '');
            if (squadId in this._entityMap.squads) {
              const { teamId, name } = this._entityMap.squads[squadId];
              const uriEncodedName = encodeURIComponent(name);
              return `[](/settings/teams/${teamId}/squads?name=${uriEncodedName})`;
            } else {
              return `Unknown Squad`;
            }
          });
          const teamReplaced = squadReplaced.replace(/<@T\w{24}>/gm, (e: string) => {
            const teamId = e.replace(/(<@T)|(>)/gm, '');
            return `[](/settings/teams/${teamId})`;
          });
          return teamReplaced;
        }
      };

      const responsesArray = Object.values(this.state.responses).slice(0);
      if (shouldUpdateProps) {
        this.props.setNotes(responsesArray);
      }
      if (responsesArray.length === 0) {
        this.setState({
          transformed: [],
          componentState: {
            responses: 'init',
            warroom: 'idle',
          },
        });
        return;
      }

      let transformed: ITransformedMessages[] = [];
      responsesArray
        .filter(note => note.incident_id === this.props.incidentId)
        .forEach(response => {
          const tMessage = transformMessage(response.message);
          transformed.push({
            id: response.id,
            created_at: response.created_at,
            message: tMessage,
            user: response.user,
            user_id: response.user_id,
            starred_info: response.starred_info,
            created_by_entity_type: response.created_by_entity_type,
            metadata: response?.metadata,
            is_edited: response?.is_edited,
          });
          this._messageRefs[response.id] = React.createRef();
        });

      transformed = transformed
        .slice(0)
        .sort(
          (a: ITransformedMessages, b: ITransformedMessages) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
        );
      this.setState({
        transformed,
        componentState: {
          responses: transformed.length > 0 ? 'idle' : 'init',
          warroom: 'idle',
        },
      });
    } catch (err: any) {
      exception.handle('E_SYNC_INCIDENT_WR_MESSAGES', err);
      this.setState({
        componentState: {
          responses: 'error',
          warroom: 'error',
        },
      });
    }
  };

  onMessageSend = async (message: string) => {
    try {
      const {
        data: { data: response },
      } = await this._warroomService.createMessage(
        message,
        this.fileUploadService.accessAttachmentArrayForNotes(),
      );
      const responses = deepCopy(this.state.responses);
      responses[response.id] = response;
      this.setState({ responses }, () => {
        this.transformResponses();
        this.scrollWindowToBottom();
      });
      this.fileUploadService.emptyAttachmentArrayForNotes();
    } catch (err: any) {
      exception.handle('E_ADD_INCIDENT_WR_MESSAGES', err);
      this.setState({
        componentState: {
          responses: 'error',
          warroom: 'error',
        },
      });
    }
  };

  onMessageStar = (messageId: string, star: boolean) => async () => {
    try {
      const {
        data: { data: response },
      } = await this._warroomService.starMessage(messageId, star);
      const responses = deepCopy(this.state.responses);
      responses[response.id] = response;
      this.setState({ responses }, this.transformResponses);
    } catch (err: any) {
      exception.handle('E_STAR_INCIDENT_WR_MESSAGES', err);
      this.setState({
        componentState: {
          responses: 'error',
          warroom: 'error',
        },
      });
    }
    if (star === true) {
      AppTracker.track(T_WA_GS_INCIDENT_NOTES_STARRED);
    }
  };

  onMessageUpdate = async (message: string) => {
    if (!message) {
      this.setState({ alertNoteId: this.state.editMessageId });
      return;
    }

    try {
      const {
        data: { data: response },
      } = await this._warroomService.updateMessage(this.state.editMessageId, message);
      const responses = deepCopy(this.state.responses);
      responses[response.id] = response;
      this.setState({ responses }, this.transformResponses);
    } catch (err: any) {
      exception.handle('E_UPDATE_INCIDENT_WR_MESSAGES', err);
      this.setState({
        componentState: {
          responses: 'error',
          warroom: 'error',
        },
      });
    } finally {
      this.setState({ editMessageId: '' });
    }
  };

  closeAlertDialog = () => {
    this.setState({ alertNoteId: '' });
  };

  onMsgDeletionByEdit = () => {
    const messageId = this.state.alertNoteId;
    this.closeAlertDialog();
    this.onMessageDelete(messageId)();
  };

  onMessageDelete = (messageId: string) => async () => {
    try {
      await this._warroomService.deleteMessage(messageId);
      this.handleDeleteEvent(messageId);
    } catch (err: any) {
      exception.handle('E_DELETE_INCIDENT_WR_MESSAGES', err);
      this.setState({
        componentState: {
          responses: 'error',
          warroom: 'error',
        },
      });
    }
  };

  focusMessage = (messageId: string) => {
    const ref = this._messageRefs[messageId];
    if (!!ref && !!ref.current) {
      const el = ref.current as HTMLElement;
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });

      const temp = el.style.backgroundColor;

      el.style.backgroundColor = theme.primary.light;
      setTimeout(() => {
        el.style.backgroundColor = temp;
      }, 1000);
    }
  };

  setOnlyStarred = (onlyStarred: boolean) => () => this.setState({ onlyStarred });

  setHoverMessage = (hoverMessage: string) => () => this.setState({ hoverMessage });

  setEditMessageId = (editMessageId: string) => () => this.setState({ editMessageId });

  isAuthor = (messageId: string): boolean =>
    this.state.responses[messageId] &&
    this.state.responses[messageId].user_id === this._currentUserId;

  getBackgroundColor = (message: ITransformedMessages) => {
    const isStarred = !!message.starred_info;
    switch (true) {
      // if starred message
      case !this.state.onlyStarred && isStarred:
        return `${theme.acknowledge.default}3a`;
      // if hovered message
      case this.state.hoverMessage === message.id:
        return theme.primary.light;
      default:
        return theme.shades.white;
    }
  };
}

export default connect(({ organization, userInfo }: IAppState) => ({ organization, userInfo }))(
  Responses,
);
