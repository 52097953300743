export interface EventChecked {
  isChecked: boolean;
  type: string;
  label: string;
}

export interface EventsDetails {
  eventClass: EventChecked;
  eventTypes: EventChecked[];
}

export const reactQueryConfig = {
  enabled: true,
  retry: 0,
  keepPreviousData: false,
  refetchOnWindowFocus: false,
};

export const EVENTS: EventsDetails[] = [
  {
    eventClass: { label: 'Incident', type: 'incident', isChecked: true },
    eventTypes: [
      { label: 'Triggered', type: 'triggered', isChecked: true },
      { label: 'Acknowledged', type: 'acknowledged', isChecked: false },
      { label: 'Resolved', type: 'resolved', isChecked: false },
      { label: 'Reassigned', type: 'reassigned', isChecked: false },
      { label: 'Priority Updated', type: 'priority_updated', isChecked: false },
    ],
  },
  {
    eventClass: { label: 'Notes', type: 'incident_notes', isChecked: false },
    eventTypes: [
      { label: 'Created', type: 'created', isChecked: false },
      { label: 'Updated', type: 'updated', isChecked: false },
      { label: 'Deleted', type: 'deleted', isChecked: false },
      { label: 'Starred', type: 'starred', isChecked: false },
      { label: 'Unstarred', type: 'unstarred', isChecked: false },
    ],
  },
  {
    eventClass: { label: 'Task', type: 'incident_task', isChecked: false },
    eventTypes: [
      { label: 'Created', type: 'created', isChecked: false },
      { label: 'Updated', type: 'updated', isChecked: false },
      { label: 'Deleted', type: 'deleted', isChecked: false },
      { label: 'Completed', type: 'completed', isChecked: false },
      { label: 'Uncompleted', type: 'uncompleted', isChecked: false },
    ],
  },
  {
    eventClass: {
      label: 'SLO Violating Incidents',
      type: 'slo_violating_incidents',
      isChecked: false,
    },
    eventTypes: [
      { label: 'Created', type: 'created', isChecked: false },
      { label: 'Marked as False Positive', type: 'false_positive_marked', isChecked: false },
      { label: 'Unmarked as Fasle Positive', type: 'false_positive_unmarked', isChecked: false },
    ],
  },
  {
    eventClass: { label: 'Postmortem', type: 'postmortem', isChecked: false },
    eventTypes: [
      { label: 'Created', type: 'created', isChecked: false },
      { label: 'Deleted', type: 'deleted', isChecked: false },
    ],
  },
  {
    eventClass: { label: 'Runbooks', type: 'runbooks', isChecked: false },
    eventTypes: [
      { label: 'Added', type: 'added', isChecked: false },
      { label: 'Deleted', type: 'deleted', isChecked: false },
    ],
  },
  {
    eventClass: { label: 'Communication channel', type: 'communication_channel', isChecked: false },
    eventTypes: [
      { label: 'Added', type: 'added', isChecked: false },
      { label: 'Updated', type: 'updated', isChecked: false },
      { label: 'Deleted', type: 'deleted', isChecked: false },
    ],
  },
  {
    eventClass: { label: 'Tags', type: 'incident_tags', isChecked: false },
    eventTypes: [
      { label: 'Updated', type: 'updated', isChecked: false },
      { label: 'Deleted', type: 'deleted', isChecked: false },
    ],
  },
];
