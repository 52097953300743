import * as React from 'react';
import { SVGProps } from 'react';

const SvgGitHubLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M10 .2C4.5.2 0 4.7 0 10.2c0 4.4 2.9 8.2 6.8 9.5.5.1.7-.2.7-.5v-1.9c-2.5.5-3.2-.6-3.4-1.2-.1-.3-.6-1.2-1-1.4-.3-.2-.8-.6 0-.7.8 0 1.4.7 1.5 1 1 1.7 2.4 1.3 3 1 .1-.7.3-1.1.6-1.3-2.2-.3-4.5-1.2-4.5-5 0-1.1.4-2 1-2.7-.1-.2-.5-1.2.1-2.6 0 0 .8-.3 2.8 1 .7-.2 1.6-.3 2.4-.3.8 0 1.7.1 2.5.3 1.9-1.3 2.8-1 2.8-1 .5 1.4.2 2.4.1 2.7.6.7 1 1.6 1 2.7 0 3.8-2.3 4.7-4.6 4.9.4.3.7.9.7 1.9v2.8c0 .3.2.6.7.5 4-1.3 6.8-5.1 6.8-9.5C20 4.7 15.5.2 10 .2z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#1b1f23',
      }}
    />
  </svg>
);

export default SvgGitHubLogo;
