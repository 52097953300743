import React, { ReactChild, ReactNode, HTMLAttributes } from 'react';
import hasChildren from '../../utils/hasChildrens';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

interface IProps {
  title: ReactChild;
  children: ReactNode;
  tabProps?: HTMLAttributes<HTMLDivElement>;
  shellProps?: HTMLAttributes<HTMLDivElement>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  transition: 0.3s max-height, 0.3s height ease;

  & > .t__grp > .ui__accordion-enter {
    opacity: 0;
    transform: translate3d(0, -5%, 0);
  }
  & > .t__grp > .ui__accordion-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.17s, transform 0.17s ease;
  }
  & > .t__grp > .ui__accordion-exit {
    opacity: 1;
  }
  & > .t__grp > .ui__accordion-exit-active {
    opacity: 0;
    transition: opacity 0.17s, transform 0.17s ease;
  }
`;

const TitleShell = styled.div`
  cursor: pointer;
  border: none;
  padding: 8px 16px;
  background: ${props => props.theme.shades.whiteSmoke};
  borderradius: 2px;
`;

const Accordion = (props: IProps) => {
  return (
    <Container {...props.shellProps}>
      <TitleShell {...props.tabProps}>{props.title}</TitleShell>
      <TransitionGroup className="t__grp">
        {hasChildren(props.children) && (
          <CSSTransition in={true} timeout={300} classNames="ui__accordion" unmountOnExit={true}>
            {props.children}
          </CSSTransition>
        )}
      </TransitionGroup>
    </Container>
  );
};

/** @component */
export default Accordion;
