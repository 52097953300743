import * as React from 'react';
import { SVGProps } from 'react';

const SvgTwilio = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      d="M9.6 7.533C9.6 8.667 8.667 9.6 7.533 9.6s-2.066-.933-2.066-2.067.933-2.066 2.066-2.066S9.6 6.4 9.6 7.533zM7.533 10.4c-1.133 0-2.067.933-2.067 2.067s.933 2.067 2.067 2.067S9.6 13.6 9.6 12.467 8.667 10.4 7.533 10.4zM20 10c0 5.533-4.467 10-10 10S0 15.533 0 10 4.467 0 10 0s10 4.467 10 10zm-2.667 0c0-4.067-3.267-7.333-7.333-7.333S2.667 5.933 2.667 10 5.933 17.333 10 17.333s7.333-3.266 7.333-7.333zm-4.866.4c-1.133 0-2.067.933-2.067 2.067s.933 2.067 2.067 2.067 2.067-.933 2.067-2.067S13.6 10.4 12.467 10.4zm0-4.933c-1.133 0-2.067.933-2.067 2.067S11.333 9.6 12.467 9.6s2.067-.933 2.067-2.067-.934-2.066-2.067-2.066z"
      style={{
        fill: '#f22f46',
      }}
    />
  </svg>
);

export default SvgTwilio;
