import { toSentenceCase } from 'core/helpers/stringUtils';
import { Tab } from 'library/atoms';
import moment from 'moment';
import { useAuditLogsListContext } from '../../context/auditLogsContext';
import { AUDITLOGS_TABS } from '../../types/index.auditLogs';
import AuditLogsList from '../auditLogsList';

function AuditLogsTabGroup() {
  const context = useAuditLogsListContext();

  const dateFilterValue =
    context.appliedFilters.startDate || context.appliedFilters.endDate
      ? `from ${moment(context.appliedFilters.startDate).format('DD/MM/YYYY')} to ${moment(
          context.appliedFilters.endDate,
        ).format('DD/MM/YYYY')}`
      : `from ${moment().subtract(1, 'days').format('DD/MM/YYYY')} to ${moment(Date.now()).format(
          'DD/MM/YYYY',
        )}`;

  const clearFilter = () => {
    const emptyStateValues = {
      ...context.appliedFilters,
      resource: [],
      actor: [],
      team: [],
      client: [],
      action: [],
      startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      endDate: moment(Date.now()).format('YYYY-MM-DD'),
    };
    context.setAppliedFilters({
      ...emptyStateValues,
      isExplicitlySet: false,
    });
    context.setDateFilters({
      lastNumberOfDays: null,
      created: null,
    });
    context.setShowDateFilters(false);
    context.clearAllFilters(emptyStateValues, () => {});
  };
  return (
    <Tab
      isLazy
      pt="12px"
      onChange={val => {
        if (val === 1) context.toggleActiveTab(AUDITLOGS_TABS.LIVE_LOGS);
        else context.toggleActiveTab(AUDITLOGS_TABS.EXPORT_HISTORY);
        context.setPagination({ pageIndex: 0, pageSize: 10 });
        clearFilter();
      }}
      labels={[
        {
          name: toSentenceCase(AUDITLOGS_TABS.LIVE_LOGS),
        },
        {
          name: toSentenceCase(AUDITLOGS_TABS.EXPORT_HISTORY),
        },
      ]}
      variant="outline"
    >
      {Object.values(AUDITLOGS_TABS).map((item, index) => {
        return <AuditLogsList status={item} key={index} dateFilterValue={dateFilterValue} />;
      })}
    </Tab>
  );
}

export default AuditLogsTabGroup;
