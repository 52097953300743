import DrawerFooterWrapper from 'library/molecules/Drawer';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import { triggerWebhookValidation } from 'views/main/organization/workflow/constant/validation';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { ManualTriggerWebhook } from 'views/main/organization/workflow/types';
import { FormFooter } from '../form-footer';
import TriggerWebhookForm from './TriggerWebhookForm';

const TriggeredWebhook = () => {
  const { actionForm, onActionSubmit, checkDirtyAndCloseDrawer } = useWorkflowFormContext();

  return (
    <FormWrapper<ManualTriggerWebhook>
      onSubmit={onActionSubmit}
      defaultValues={actionForm! as ManualTriggerWebhook}
      validationSchema={triggerWebhookValidation}
    >
      <TriggerWebhookForm />
      <DrawerFooterWrapper>
        <FormFooter<ManualTriggerWebhook> checkDirtyAndCloseDrawer={checkDirtyAndCloseDrawer} />
      </DrawerFooterWrapper>
    </FormWrapper>
  );
};

export default TriggeredWebhook;
