import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const AccordionTheme: ComponentStyleConfig = {
  baseStyle: {
    container: {
      borderWidth: '1px',
      bgGradient: 'linear(to-b, primary.100, brand.white)',
    },
    icon: {
      color: 'brand.blue',
      ml: '0px',
    },
    button: {
      _focus: {
        boxShadow: 'none',
      },
      _expanded: {
        borderTop: '0.75px solid primary.400',
        borderBottomWidth: '0px',
      },
      _hover: {
        bg: 'brand.white',
      },
      _disabled: {
        pointerEvents: 'none',
      },
    },
  },
  sizes: {
    md: {
      py: '12px',
      paddingLeft: '18px',
    },
    lg: {
      py: '16px',
      paddingLeft: '24px',
    },
  },
  defaultProps: {
    size: 'md',
  },
};
