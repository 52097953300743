import { useBoolean } from '@chakra-ui/react';
import {
  DialogModalFrame,
  ErrorBlock,
  FocusBlock,
  FormBlock,
  Grid,
  InputBlock,
  Label,
  MdBlock,
  Para,
  SelectBox,
  SpinLoader,
  TextButton,
  Theme,
} from 'uie/components';
import Tippy from '@tippy.js/react';
import UpgradeOnlyModal from 'components/upgradeonly.tooltip';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { FileUploadFeature, OperationType } from 'core/services/service.fileUpload';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { CrownSmallIcon } from 'icons';
import { UpdateStatusPageIcon } from 'library/icons';
import React, { useEffect, useState } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { fileUploadViewConfig } from 'views/config/fileUploadConfig';
import { STATUSPAGE_V2_PATH } from 'views/main/routes/routes';

import { T_WA_GS_STATUS_POSTED } from '../../../../../core/const/tracker';
import { exception } from '../../../../../core/exception';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IComponentErrorState } from '../../../../../core/interfaces/IComponentState';
import { IStatusPage } from '../../../../../core/interfaces/IStatusPage';
import {
  BillingService,
  FileUploadService,
  PublicMessageService,
  StatusPageService,
} from '../../../../../core/services';
import { AppTracker } from '../../../../../shared/analytics/tracker';

const { theme } = Theme;
const { imageTexts, ...fileUploadConfig } = fileUploadViewConfig;

interface IProps extends Pick<IAppState, 'organization'> {
  incidentId: string;
  fileUploadService: FileUploadService;
}

const IncidentDetailsUpdateStatusPage: React.FC<IProps> = (props: IProps) => {
  interface ISP {
    name: string;
    id: string;
  }

  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isStatusPageFeatureDisabled, setStatusPageDisabled] = useState<boolean>(false);
  const [statusPages, setStatusPages] = useState<ISP[]>([]);
  const [selectedSP, setSelectedSP] = useState<ISP | null>(null);
  const [SPSearch, setSPSearch] = useState<string>('');

  const [loading, setLoading] = useState<'get_SPs' | 'post_message' | ''>('');
  const [errors, setErrors] = useState<IComponentErrorState>({});
  const organization = useSelector((state: IAppState) => state.organization, shallowEqual);

  useEffect(() => {
    setStatusPageDisabled(BillingService.isFeatureDisabled(props, 'status-page'));
  }, []);
  const history = useHistory();

  const _SPService = new StatusPageService();
  const _PMService = new PublicMessageService({
    incidentId: props.incidentId,
  });

  const openModal = () => {
    setOpen(true);
    if (statusPages.length === 0) getAllStatusPages();
  };

  const checkStatusPageLimitsAndOpenModal = () => {
    if (isStatusPageFeatureDisabled) {
      return;
    }
    openModal();
  };

  const onStatusPageClickHandler = () => {
    if (organization.currentOrg.o?.config['statuspagev1.enabled']) {
      checkStatusPageLimitsAndOpenModal();
    } else {
      history.push(STATUSPAGE_V2_PATH);
    }
  };

  const closeModal = () => {
    setOpen(false);
    setMessage('');
    setStatus('');
    setErrors({});
    if (statusPages.length > 0) setSelectedSP(statusPages[0]);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => setStatus(e.target.value);

  const getAllStatusPages = async () => {
    setLoading('get_SPs');

    try {
      const {
        data: { data: statusPages },
      } = await _SPService.getAll();

      const SPs: ISP[] = statusPages.map((sp: IStatusPage) => ({
        name: sp.name,
        id: sp!.id || '',
      }));
      setStatusPages(SPs);
      if (SPs.length > 0) setSelectedSP(SPs[0]);
    } catch (err: any) {
      exception.handle('E_GET_ALL_STATUS_PAGES', err);
      setErrors({ message: err?.response?.data?.message ?? 'Network Error' });
    } finally {
      setLoading('');
    }
  };

  const handleSPSelect = (_: any, selectedPage: ISP) => setSelectedSP(selectedPage);

  const handleSubmit = async () => {
    if (selectedSP === null) {
      return;
    }
    if (status.trim() === '') {
      setErrors({
        message: 'Status is Required',
        e_status: 'true',
      });
      return;
    }
    if (message.trim() === '') {
      setErrors({
        message: 'Message is Required',
        e_message: 'true',
      });
      return;
    }

    setLoading('post_message');

    try {
      await _PMService.postMessage({
        status,
        message,
        statusPageId: selectedSP.id,
        attachments: props.fileUploadService.accessAttachmentArrayForStatusPage(),
      });

      props.fileUploadService.emptyAttachmentArrayForStatusPage();
      closeModal();
      AppTracker.track(T_WA_GS_STATUS_POSTED);
    } catch (err: any) {
      exception.handle('E_POST_PUBLIC_MESSAGE', err);
      setErrors({ message: err?.response?.data?.message ?? 'Network Error' });
    } finally {
      setLoading('');
    }
  };

  const userAccess = useUserAccess();

  const hasCreate = userAccess.hasCreateAccess('status_pages');
  const hasUpdateAccess = userAccess.hasUpdateAccess('status_pages');
  const hasReadAccess = userAccess.hasReadAccess('status_pages');
  const hasDeleteAccess = userAccess.hasDeleteAccess('status_pages');

  const [showUpgradeModal, setShowUpgradeModal] = useBoolean();

  return (
    <>
      <Grid
        onClick={isStatusPageFeatureDisabled ? setShowUpgradeModal.on : onStatusPageClickHandler}
        alignItems="center"
      >
        <UpdateStatusPageIcon style={{ width: '16px', marginRight: '5px' }} />
        Update Status Page
        {isStatusPageFeatureDisabled && <CrownSmallIcon className="ml-5 pro" />}
      </Grid>
      <UpgradePlanModal
        hasBillingPermission={BillingService.hasManageBillingPermission(props)}
        onCancel={() => {
          setShowUpgradeModal.off();
        }}
        showModal={showUpgradeModal}
        message={BillingService.getMessage(0, 'status-page', props)}
        header={BillingService.getHeader(0, 'status-page', props)}
      />
      <DialogModalFrame
        id="incident_details__update_status_page"
        onClose={closeModal}
        padding="32px"
        width="660px"
      >
        {open && (
          <Grid type="column">
            <Para
              fontWeight={500}
              color={theme.shades.cement}
              fontSize={24}
              className="pr-20 mb-10"
            >
              Update Status Page
            </Para>
            {loading === 'get_SPs' ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: 150,
                }}
              >
                <SpinLoader base="54px" />
              </div>
            ) : statusPages.length === 0 ? (
              <div
                style={{
                  margin: 20,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'centre',
                }}
              >
                <Para style={{ margin: 20, fontSize: 24 }}>No Status Page Found</Para>
                {hasCreate && (
                  <Link
                    to={{ pathname: '/statuspage', state: { openModal: 'add_new' } }}
                    style={{ textDecoration: 'none' }}
                  >
                    <TextButton
                      style={{
                        color: theme.shades.white,
                        fontSize: 16,
                        fontWeight: 600,
                        padding: 10,
                      }}
                      disabled={hasCreate}
                    >
                      Add a Status Page
                    </TextButton>
                  </Link>
                )}
              </div>
            ) : (
              <FormBlock onFormSubmit={handleSubmit} className="mt-20">
                <Label fontSize={14} fontWeight={400}>
                  Current Status
                </Label>
                <InputBlock
                  value={status}
                  onChange={handleStatusChange}
                  error={!!errors.e_status}
                  className="mt-10 mb-20"
                  placeholder="Resolved/Acknowledged/Investigating"
                />

                <Label fontSize={14} fontWeight={400}>
                  Custom Message
                </Label>
                <div className="mt-10 mb-20">
                  <MdBlock
                    value={message}
                    onChange={setMessage}
                    error={!!errors.e_message}
                    options={{
                      spellChecker: true,
                      placeholder: '(Supports markdown)',
                      imageUploadFunction: props.fileUploadService.getUploadFunctionForFeature(
                        FileUploadFeature.STATUS_PAGE,
                        OperationType.UPDATE,
                        selectedSP?.id,
                      ),
                      imageTexts: {
                        ...imageTexts,
                        sbInit: '',
                      },
                      ...fileUploadConfig,
                      renderImageUploadsAsLinks: false,
                    }}
                  />
                </div>
                <SelectBox
                  hook={
                    selectedSP ? (
                      <Grid alignItems="center">
                        <Para fontSize={16} className="ml-10">
                          {selectedSP.name}
                        </Para>
                      </Grid>
                    ) : (
                      <Para fontSize={16} className="ml-10">
                        Please Select Status Page
                      </Para>
                    )
                  }
                  searchHookProps={{
                    value: SPSearch,
                    height: '24px',
                    fontSize: '16px',
                    onChange: e => setSPSearch(e.target.value),
                  }}
                  disabled={statusPages.length === 0}
                  onValueChange={handleSPSelect}
                  height="auto"
                  maxHeight="200px"
                  width="100%"
                  maxWidth="100%"
                  padding="0"
                >
                  {statusPages
                    .filter(sp =>
                      SPSearch ? sp.name.toLowerCase().includes(SPSearch.toLowerCase()) : true,
                    )
                    .map((sp, idx) => (
                      <FocusBlock key={idx} value={sp} isSelected={sp.name === selectedSP?.name}>
                        <Grid alignItems="center">
                          <Para fontSize={16} className="ml-10">
                            {sp.name}
                          </Para>
                        </Grid>
                      </FocusBlock>
                    ))}
                </SelectBox>

                {errors.message && (
                  <ErrorBlock fontWeight={500} className="mt-20">
                    {errors.message}
                  </ErrorBlock>
                )}

                <TextButton
                  style={{ marginTop: '28px' }}
                  onClick={handleSubmit}
                  disabled={
                    loading === 'post_message' ||
                    status.trim() === '' ||
                    message.trim() === '' ||
                    selectedSP === null
                  }
                >
                  <Para fontWeight={400} fontSize={16} color={theme.shades.white}>
                    Update
                  </Para>
                </TextButton>
              </FormBlock>
            )}
          </Grid>
        )}
      </DialogModalFrame>
    </>
  );
};

export default connect(({ organization }: IAppState) => ({ organization }))(
  IncidentDetailsUpdateStatusPage,
);
