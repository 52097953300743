import * as React from 'react';
import { SVGProps } from 'react';

const SvgNodepingLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <circle
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#f60',
      }}
      cx={10}
      cy={10}
      r={8}
    />
  </svg>
);

export default SvgNodepingLogo20X20;
