import { THEME_COLORS } from 'library/theme/colors';
import { PROGRESS_STEPPER_TYPE } from 'views/shared/types';

export const queryKeys = {
  SERVICELIST: 'serviceList',
  SERVICEEXPANDEDLIST: 'serviceExpandedList',
  SERVICEDETAIL: 'serviceDetail',
  SERVICEDETAILSTATS: 'serviceDetailStats',
  SERVICEDETAILMETRICS: 'serviceDetailMetrics',
  SERVICEDEDUPLICATEDRULE: 'serviceDeduplicatedRule',
  SERVICEDEDUPEKEY: 'serviceDedupeKey',
  ALERTSOURCEDEDUPEKEY: 'alertSourceDedupeKey',
  SERVICETAGGINGRULE: 'serviceTaggingRule',
  SERVICEROUTINGRULE: 'serviceRoutingRule',
  SERVICESUPPRESSIONRULE: 'serviceSuppressionRule',
  SERVICEINCIDENTSTODAY: 'serviceIncidentsToday',
  SERVICEDELETE: 'serviceDelete',
  SERVICEDEPENCENCY: 'serviceDependency',
  SERVICEANALYTICS: 'serviceAnalytics',
  ALERTSOURCELISTDATA: 'alertSourceListData',
  GETTAGS: 'getTags',
  INTEGRATIONLIST: 'integrationList',
  GETALLTAGS: 'getALLTags',
  SERVICEDOPENINCIDENTS: 'openIncidents',
  SERVICELASTUPDATED: 'lastUpdated',
  SERVICEOWNER: 'serviceOwners',
  CUSTOMTEMPLATE: 'customTemplates',
  SERVICECONFIG: 'serviceConfig',
  SERVICE_GRAPH_QUERY_KEY: 'serviceGraphKey',
  SERVICE_GRAPH_DEP_QUERY_KEY: 'serviceDependenciesKey',
};

export const serviceListUrl = '/service-catalog';
export const serviceDetailUrl = '/service-catalog/:serviceId';
export const serviceCreateUrl = '/service-catalog/add/service';
export const addAlertSourceUrl = '/service-catalog/add/alertSource';
export const addalertSourceDuringCreationUrl = '/service-catalog/add/service/:serviceId';

export const reactQueryConfig = {
  enabled: true,
  retry: 1,
  staleTime: 0,
  keepPreviousData: true,
  cacheTime: 0,
  refetchOnWindowFocus: false,
};

export const PAGE_CHANGED = 'PAGE_CHANGED';
export const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
export const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';

export enum SearchActionKind {
  SEARCH_ENABLED_OR_DISABLED = 'SEARCH_ENABLED_OR_DISABLED',
  SEARCHED = 'SEARCHED',
  SORTING = 'SORTING',
  PAGE_INDEX_CHANGE = 'PAGE_INDEX_CHANGE',
  PAGE_CHANGED = 'PAGE_CHANGED',
  PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED',
  TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED',
  OPEN_DRAWER = 'OPEN_DRAWER',
  CLOSE_DRAWER = 'OPEN_DRAWER',
  ROW_SELECTION = 'ROW_SELECTION',
  RESET_DATA = 'RESET_DATA',
  FILTER_ENABLED_OR_DISABLED = 'FILTER_ENABLED_OR_DISABLED',
  FILTERED = 'FILTERED',
}

export const timeInterval = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

export const SERVICE_CREATION_STEP: PROGRESS_STEPPER_TYPE[] = [
  { index: 1, title: 'Define Service' },
  { index: 2, title: 'Add Alert Source' },
];

export const helpSupportWidth = 385;
export const serviceFormWidth = `calc(100% - ${helpSupportWidth + 12}px)`;

export const linkStyles = {
  color: '#3D6DD8',
  cursor: 'pointer',
};

export enum RuleTabLabels {
  tagging = 'Tagging Rules',
  routing = 'Routing Rules',
  dedupRule = 'Deduplication Rules',
  dedupKey = 'Key Based Deduplication',
  suppression = 'Suppression Rules',
}

export const STEP_LEVEL = 400;
export const DEFAULT_GRAPH_COLOR = THEME_COLORS.secondary[300];
export const NODE_BORDER_WIDTH = '2px';
export const EDGE_STROKE = 2;
export const HIGHLIGHTED_EDGE_STROKE = 4;
export const GRAPH_MAX_ZOOM = 4;
export const GRAPH_MIN_ZOOM = 0.2;
export const NODE_WIDTH = 172;
export const NODE_HEIGHT = 36;
