import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API } from 'core';
import { queryKeys, reactQueryConfig } from '../constant';
import { reactQueryConfigError } from '../helpers/service.reactqery';

export interface INotConfiguredTeam {
  data: TeamIds;
}

export interface TeamIds {
  team_ids: string[];
}

const getNotConfiguredTeams = async () => {
  const { data } = await axios.get<INotConfiguredTeam>(
    `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud/new/teams-not-configured-for-any-configurations`,
  );
  return data.data.team_ids;
};

export const useGetNotConfiguredTeams = () => {
  return useQuery([queryKeys.GETNOTCONFIGUREDTEAM], getNotConfiguredTeams, {
    ...reactQueryConfig,
    retry: 1,
    onError: (error: any) => {
      const description =
        error?.response?.data?.meta?.error_message || 'Failed to Get Unconfigured Teams';
      reactQueryConfigError({ description: description, title: 'Get Not Configured Teams' });
    },
  });
};
