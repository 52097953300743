import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.css';
import * as helpers from 'core/helpers';
import cx from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { API } from '../../../../../../core/api';
import { IAppState } from '../../../../../../core/interfaces/IAppState';
import { IRole } from '../../../../../../core/interfaces/IRole';
import { AppTracker } from '../../../../../../shared/analytics/tracker';
import { T_WA_GS_SSO_DISABLED, T_WA_GS_SSO_ENABLED } from '../../../../../../core/const/tracker';
import { ErrorBlock, Para, Theme, Tooltip } from 'uie/components';
import { SSOService } from '../../../../../../core/services';
import { AppConfig } from 'shared/app.config';
import ReactSelect, { OnChangeValue } from 'react-select';
import { SelectOption } from 'components/SecondaryFilter/types';

const providers = [
  'okta',
  'google',
  'AWS',
  'citrix',
  'jumpcloud',
  'azure AD',
  'microsoft ADFS',
  'custom SAML 2.0',
];

const providerOptions = providers.map(options => {
  const providerName = options.charAt(0).toUpperCase() + options.slice(1);
  return {
    label: providerName,
    value: options,
  };
});
interface IProps extends Pick<IAppState, 'roles'> {
  hide: () => void;
  showIntegrationMessage: (message: string) => void;
  checkAndSetDirty: () => void;
  removeModalDirty: () => void;
}

interface IState {
  message: string;
  endpoint: string;
  xKey: string;
  domain: string;
  enforceAccountOwnerLogin: boolean;
  defaultUserRoles: IRole;
  errorLogs: any;
  showHelp: boolean;
  token: string;
  isEnabled: boolean;
  selectedProvider: any;
  copied: boolean;
}

class SSOModel extends Component<IProps, IState> {
  private _SSOService = new SSOService();
  public defaultProvider: any = providerOptions[0];

  constructor(props: IProps) {
    super(props);
    this.state = {
      message: '',
      endpoint: '',
      xKey: '',
      domain: '',
      enforceAccountOwnerLogin: true,
      defaultUserRoles: this.props.roles.r.find(r => r.slug === 'user')!,
      errorLogs: {},
      showHelp: false,
      token: '',
      isEnabled: false,
      selectedProvider: providers[0],
      copied: false,
    };
  }

  public componentDidMount() {
    this.hide = this.hide.bind(this);
    this.setValue = this.setValue.bind(this);
    this.setDefaultUserRole = this.setDefaultUserRole.bind(this);
    this.onVerify = this.onVerify.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this._getToken(true);
  }

  public componentDidUpdate() {
    this.defaultProvider = {
      label:
        this.state.selectedProvider.charAt(0).toUpperCase() + this.state.selectedProvider.slice(1),
      value: this.state.selectedProvider,
    };
  }

  public hide() {
    this.props.hide();
  }

  public async _getToken(initial?: boolean) {
    try {
      const { data: response } = await this._SSOService.getSSOConfig(API.config.organizationId);

      if (response.data) {
        const data = response.data;
        const {
          allow_account_owner_email_login: enforceAccountOwnerLogin,
          entry_point: endpoint,
          token,
          x509_certificate: xKey,
          domain: domain,
          is_enabled: isEnabled,
          provider,
          new_user_role_id: newUserRoleID,
        } = data;

        if (
          (this.state.selectedProvider === 'okta' && (provider === 'okta' || provider === '')) ||
          (this.state.selectedProvider === 'google' && provider === 'google') ||
          (this.state.selectedProvider === 'AWS' && provider === 'AWS') ||
          (this.state.selectedProvider === 'citrix' && provider === 'citrix') ||
          (this.state.selectedProvider === 'jumpcloud' && provider === 'jumpcloud') ||
          (this.state.selectedProvider === 'azure AD' && provider === 'azure AD') ||
          (this.state.selectedProvider === 'microsoft ADFS' && provider === 'microsoft ADFS') ||
          (this.state.selectedProvider === 'custom SAML 2.0' && provider === 'custom SAML 2.0') ||
          initial
        ) {
          this.defaultProvider =
            provider !== ''
              ? {
                  label: provider.charAt(0).toUpperCase() + provider.slice(1),
                  value: provider,
                }
              : providerOptions[0];
          this.setState({
            enforceAccountOwnerLogin,
            endpoint,
            token,
            xKey,
            domain,
            isEnabled,
            defaultUserRoles:
              this.props.roles.r.find(r => r._id === newUserRoleID) ||
              this.props.roles.r.find(r => r.slug === 'user')!,
            selectedProvider: (provider !== '' ? provider : providers[0]) as any,
          });
        } else {
          this.setState({
            endpoint: '',
            xKey: '',
            domain: '',
            enforceAccountOwnerLogin: true,
            token,
            isEnabled: false,
          });
        }
      }
    } catch (err: any) {
      this.setState({
        message: err?.response?.data?.meta?.error_message ?? 'Network error',
      });
    }
  }

  public setValue(
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) {
    const name = event.target.name;
    const value = event.target.value;

    const newState: any = {};

    if (name === 'enforceAccountOwnerLogin') {
      newState[name] = !this.state[name];
    } else {
      newState[name] = value;
    }

    this.setState(newState);
    this.props.checkAndSetDirty();
  }

  public setDefaultUserRole(event: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      defaultUserRoles: this.props.roles.r.find(role => role._id === event.target.value) as IRole,
    });
    this.props.checkAndSetDirty();
  }

  public toggleHelp() {
    this.setState({
      showHelp: !this.state.showHelp,
    });
  }

  public onVerify() {
    const errorLogs: any = {};
    if (helpers.stringIsEmpty(this.state.endpoint)) {
      errorLogs.endpoint = 'Please provide End-point';
    } else {
      if (!helpers.isValidUrl(this.state.endpoint)) {
        errorLogs.endpoint =
          'Please provide a valid url of type [http|https]://endpoint.com/callback';
      }
    }

    if (helpers.stringIsEmpty(this.state.xKey)) {
      errorLogs.xKey = 'Please Provide X.506 certificate as key';
    }
    if (helpers.stringIsEmpty(this.state.domain)) {
      errorLogs.domain = 'Please Provide Domain';
    }

    if (helpers.objectIsEmpty(errorLogs)) {
      this._onSave();
    }

    this.setState({
      errorLogs,
    });
  }

  public async _onSave() {
    const { endpoint, xKey, domain, enforceAccountOwnerLogin, isEnabled, defaultUserRoles } =
      this.state;

    isEnabled
      ? AppTracker.track(T_WA_GS_SSO_ENABLED, { 'SSO Provider': this.state.selectedProvider })
      : AppTracker.track(T_WA_GS_SSO_DISABLED);

    try {
      const { data: response } = await this._SSOService.updateSSOConfig(API.config.organizationId, {
        entry_point: endpoint,
        x509_certificate: xKey,
        domain: domain,
        provider: this.state.selectedProvider,
        allow_account_owner_email_login: enforceAccountOwnerLogin,
        auto_add_users: true,
        new_user_role: defaultUserRoles.name,
        is_enabled: isEnabled,
      });

      if (response.data) {
        const integrationMessage = response.data.is_enabled
          ? 'SSO enabled successfully'
          : 'SSO configuration saved successfully';
        this.hide();

        this.props.showIntegrationMessage(integrationMessage);
      }
      this.props.removeModalDirty();
    } catch (err: any) {
      const errorLogs: any = {};
      if (err?.response?.data?.meta?.error_message === 'invalid domain') {
        errorLogs.domain = 'This domain is not allowed';
        this.setState({
          errorLogs,
        });
      } else if (err?.response?.data?.meta?.error_message === 'domain mismatch') {
        errorLogs.domain = 'Domain should be same as account owner email';
        this.setState({
          errorLogs,
        });
      } else {
        this.setState({
          message: err?.response?.data?.meta?.error_message ?? 'Network error',
        });
      }
    }
  }

  public handleChange = (newValue: OnChangeValue<SelectOption, false>) => {
    this._getToken();
    this.setState({
      selectedProvider: newValue?.value,
    });
  };

  public onCopy() {
    this.setState({
      copied: true,
    });

    setTimeout(() => {
      this.setState({ copied: false });
    }, 1000);
  }

  public render() {
    const ACSUrl =
      AppConfig.sso_auth_url + '/sso/saml/' + (this.state.token ? this.state.token : '__token__');

    return (
      <div
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <div className="clearfix modal-header-container">
          <div className="float-left" style={{ display: 'flex' }}>
            <h1 className="modal-container-heading">Enable Single Sign-on(SSO)</h1>
            <label className="switch no-select ml-10" style={{ position: 'relative', top: 3 }}>
              <input
                type="checkbox"
                id="togBtn"
                checked={this.state.isEnabled}
                onChange={() => {
                  this.setState({
                    isEnabled: !this.state.isEnabled,
                  });
                  this.props.checkAndSetDirty();
                }}
              />
              <div className="slider round">
                <span className="on" />
                <span className="off" />
              </div>
            </label>
          </div>
        </div>
        <div style={{ width: '300px', marginBottom: '10px' }}>
          <Para fontWeight={500} style={{ lineHeight: '12px', marginBottom: '10px' }}>
            Choose SSO
          </Para>
          <ReactSelect
            options={providerOptions}
            value={this.defaultProvider}
            onChange={this.handleChange}
          />
        </div>
        <div>
          <form
            className="integration-details-container"
            style={{ boxShadow: 'none', position: 'static' }}
            onSubmit={e => e.preventDefault()}
          >
            <div>
              <p className="font-bold">
                Configuration guide for{' '}
                <span className="capatilize">{this.state.selectedProvider}</span>
              </p>
            </div>

            {this.state.selectedProvider === 'custom SAML 2.0' && (
              <div>
                <p
                  className="item-box-tagline"
                  style={{
                    fontSize: '12px',
                    color: '#808080',
                  }}
                >
                  Squadcast supports any SAML 2.0-based SSO and you can set it up for your
                  organization.
                </p>
              </div>
            )}
            <div>
              <p className="item-box-tagline font-bold" style={{ margin: 0, marginBottom: 10 }}>
                Copy {this.state.selectedProvider === 'okta' ? 'this' : 'the ACS URL'} and paste it
                in your SSO provider system.
              </p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  autoFocus={true}
                  type="text"
                  autoComplete="off"
                  className={cx('input-design', {
                    'text-field-border': this.state.errorLogs.endpoint,
                  })}
                  name="acsurl"
                  value={
                    this.state.selectedProvider === 'okta'
                      ? this.state.token
                        ? this.state.token
                        : '__token__'
                      : ACSUrl
                  }
                  style={{ color: Theme.theme.primary.default }}
                  readOnly
                />
                <Tooltip
                  label={this.state.copied ? 'Copied' : 'Copy'}
                  offset={{ left: '0px', top: '-50px' }}
                  background={'black'}
                  color={'white'}
                  delay={0}
                >
                  <CopyToClipboard
                    onCopy={this.onCopy}
                    text={
                      this.state.selectedProvider === 'okta'
                        ? this.state.token
                          ? this.state.token
                          : '__token__'
                        : ACSUrl
                    }
                  >
                    <img
                      data-tip={true}
                      data-for="copyCustom"
                      src="/icons/copy-to-clipboard.png"
                      className="cursor-pointer"
                      style={{
                        width: 16,
                        height: 16,
                        paddingLeft: 5,
                      }}
                      alt="close"
                    />
                  </CopyToClipboard>
                </Tooltip>
              </div>
            </div>
            <div>
              <p
                className="item-box-tagline font-bold"
                style={{ margin: 0, marginBottom: 10, marginTop: 10 }}
              >
                Paste the SAML 2.0 Endpoint from your SSO provider below.
              </p>
              <input
                autoFocus={true}
                type="text"
                autoComplete="off"
                className={cx('input-design', {
                  'text-field-border': this.state.errorLogs.endpoint,
                })}
                placeholder="http://example.com/callback"
                name="endpoint"
                value={this.state.endpoint}
                onChange={this.setValue}
              />
              {this.state.errorLogs.endpoint && (
                <div className="error-block">{this.state.errorLogs.endpoint}</div>
              )}
            </div>
            <div style={{ marginTop: '20px' }}>
              <p className="item-box-tagline font-bold" style={{ margin: 0, marginBottom: 10 }}>
                Paste the X.509 Certificate from your SSO provider below.
              </p>
              <textarea
                className={cx('input-design', {
                  'text-field-border': this.state.errorLogs.xKey,
                })}
                placeholder="---- BEGIN LICENCE KEY ----"
                name="xKey"
                value={this.state.xKey}
                onChange={this.setValue}
                style={{
                  height: '100px',
                  paddingTop: '10px',
                  lineHeight: 'normal',
                  fontFamily: 'monospace',
                }}
              />
              {this.state.errorLogs.xKey && (
                <div className="error-block">{this.state.errorLogs.xKey}</div>
              )}
            </div>
            <div style={{ marginTop: '20px' }}>
              <p className="item-box-tagline font-bold" style={{ margin: 0, marginBottom: 10 }}>
                Enter your Organization Domain
              </p>
              <input
                autoFocus={true}
                type="text"
                autoComplete="off"
                className={cx('input-design', {
                  'text-field-border': this.state.errorLogs.domain,
                })}
                placeholder="example.com"
                name="domain"
                value={this.state.domain}
                onChange={this.setValue}
              />
              {this.state.errorLogs.domain && (
                <div className="error-block">{this.state.errorLogs.domain}</div>
              )}
            </div>
          </form>
        </div>
        <div>
          <div style={{ marginTop: '20px' }}>
            <p className="item-box-tagline font-bold" style={{ margin: 0, marginBottom: 10 }}>
              Default New User Role
            </p>
            <select
              value={this.state.defaultUserRoles._id}
              className="select-design"
              onChange={this.setDefaultUserRole}
            >
              {this.props.roles.r
                .filter(r => !r.name.includes('Owner'))
                .map((role, index) => {
                  return (
                    <option key={index} value={role._id}>
                      {role.name}
                    </option>
                  );
                })}
            </select>
          </div>

          <div style={{ marginTop: '20px' }}>
            <div style={{ display: 'flex' }}>
              <p className="item-box-tagline font-bold" style={{ margin: 0, marginBottom: 10 }}>
                Allow Account Owner to use email login.
              </p>
              <span>{this.state.enforceAccountOwnerLogin ? '(Default)' : ''}</span>
            </div>

            <div>
              <label
                className="item-box-tagline cursor-pointer no-select"
                htmlFor="checkbox"
                style={{ position: 'relative', top: -1 }}
              >
                <input
                  type="checkbox"
                  name="enforceAccountOwnerLogin"
                  checked={this.state.enforceAccountOwnerLogin}
                  onChange={this.setValue}
                />
                &nbsp; Account Owners
              </label>
            </div>
          </div>
        </div>
        <button className="main-button" onClick={this.onVerify} style={{ marginTop: '10px' }}>
          {' '}
          Save{' '}
        </button>
        <span style={{ marginLeft: '20px' }}>
          {this.state.message.includes('Integration Saved Successfully') ? (
            this.state.message
          ) : (
            <ErrorBlock>{this.state.message}</ErrorBlock>
          )}
        </span>
      </div>
    );
  }
}

export default connect((state: IAppState) => ({
  roles: state.roles,
}))(SSOModel);
