import request from '../commons';
import { API } from '..';

function getStakeHolders() {
  const req = request.get(API.config.batman)(
    `organizations/${API.config.organizationId}/stakeholder-groups?owner_id=${API.config.teamId}`,
  );
  return req;
}

export { getStakeHolders };
