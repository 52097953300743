import { Divider, Flex } from '@chakra-ui/layout';
import { DrawerFooter } from '@chakra-ui/modal';
import { FormButton } from 'library/atoms';
import { useFormContext } from 'react-hook-form';
import { useWorkflowListContext } from '../../context/workflowListContext';
import { WorkflowFilterForm } from '../../types';

const FilterFormFooter = () => {
  const workflowContext = useWorkflowListContext();
  const { getValues } = useFormContext<WorkflowFilterForm>();

  const disableClearAllButton =
    getValues('event') === null &&
    getValues('action') === null &&
    getValues('created_by') === null &&
    getValues('updated_by') === null &&
    getValues('enabled') === null &&
    getValues('tag')?.length === 0 &&
    getValues('tags') === null &&
    getValues('owner') === null;

  return (
    <Flex direction="column">
      <Divider />
      <DrawerFooter gap="20px" justifyContent={'flex-start'}>
        <FormButton variant="primary" type="submit" aria-label="submit" title="Apply" />
        <FormButton variant="secondary" onClick={workflowContext.onCloseFilter} title="Cancel" />
        <FormButton
          disabled={disableClearAllButton}
          variant="ghost"
          onClick={workflowContext.onClearFilter}
          title="Clear All"
        />
      </DrawerFooter>
    </Flex>
  );
};

export default FilterFormFooter;
