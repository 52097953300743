import {
  Accordion,
  CardBlock,
  ErrorBlock,
  FocusBlock,
  FormBlock,
  Grid,
  Heading,
  IconButton,
  InputBlock,
  Label,
  Para,
  SpinLoader,
  TextButton,
  Theme,
  Tooltip,
} from 'uie/components';
import { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, Route, RouteChildrenProps, RouteComponentProps } from 'react-router-dom';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { AddIcon, ArrowLeftIcon, CloseIcon, DefaultIcon } from '../../../../../icons';
import cx from 'classnames';
import OrganizationSettingsTeamsRender from './render';
import {
  IComponentErrorState,
  IComponentNetworkState,
} from '../../../../../core/interfaces/IComponentState';
import TeamsService from '../../../../../core/services/rbac/service.teams';
import { exception } from '../../../../../core/exception';
import { requestOrganizationTeams } from '../../../../../core/actions/organization/teams';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import { T_WA_GS_TEAMS_CREATED } from '../../../../../core/const/tracker';
import { BillingService } from 'core';
import UpgradeOnlyModal from 'components/upgradeonly.tooltip';
import Tippy from '@tippy.js/react';
import { RouterGuardProvider } from 'components/UnsavedChangeNavigation';
import React from 'react';
import libraryTheme from 'library/theme';
import { ChakraProvider } from '@chakra-ui/react';
import { SettingPageACL, withSettingPageACL } from './acl';

type IMix = Pick<IAppState, 'organization'> & RouteChildrenProps<'/settings/teams'>;
interface IProps extends IMix {
  requestOrganizationTeams: typeof requestOrganizationTeams;
  aclStore: SettingPageACL;
}

interface IState {
  openAccordion: 'new-team' | '';
  newTeamName: string;
  errors: IComponentErrorState;
  componentNetworkState: IComponentNetworkState | 'create-new-team';
  newTeamId: string;
  showUpgradeModal: boolean;
  teamLimitExceeded: boolean;
}

class OrganizationTeams extends Component<IProps, IState> {
  private _teamService = new TeamsService();

  constructor(props: IProps) {
    super(props);

    this.state = {
      openAccordion: '',
      errors: {},
      newTeamName: '',
      componentNetworkState: 'idle',
      newTeamId: '',
      showUpgradeModal: false,
      teamLimitExceeded: false,
    };
  }

  componentDidMount() {
    this.updatePlanTeamLimitsExceeded();
    /** This scrollTo was needed because the scroll state from previous page does not change when
    the user navigates to the calendar page (essentially because it only loads a new view)
    */
    window.scrollTo(0, 0);

    this.props.aclStore.setOBACEnabled(
      this.props.organization.currentOrg.o?.config.obac_enabled ?? false,
    );
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.organization.teams.action === 'REQUEST_ORG_TEAMS' &&
      this.props.organization.teams.action === 'REQUEST_ORG_TEAMS_SUCCESS'
    ) {
      this.setState({ openAccordion: '', newTeamName: '', componentNetworkState: 'idle' });
      this.updatePlanTeamLimitsExceeded();
    }

    if (
      this.state.newTeamId !== '' &&
      this.props.location.pathname.includes(this.state.newTeamId)
    ) {
      this.setState({ newTeamId: '' });
    }

    if (
      this.props.organization.currentOrg.o?.config.obac_enabled !==
      prevProps.organization.currentOrg.o?.config.obac_enabled
    ) {
      this.props.aclStore.setOBACEnabled(
        this.props.organization.currentOrg.o?.config.obac_enabled ?? false,
      );
    }

    //parse teamId where url is /settings/teams/:teamId
    const urlRegex = /^\/settings\/teams\/([a-zA-Z0-9]+)$/;
    const match = this.props.location.pathname.match(urlRegex);
    const prevMatch = prevProps.location.pathname.match(urlRegex);
    if (match) {
      const teamIdFromUrl = match[1];
      const prevTeamIdFromUrl = prevMatch ? prevMatch[1] : '';
      if (teamIdFromUrl !== prevTeamIdFromUrl) {
        this.props.aclStore.setTeamACL(
          this.props.organization.currentOrg.o?.organizationId ?? '',
          teamIdFromUrl,
        );
      }
    }
  }

  updatePlanTeamLimitsExceeded = async () => {
    this.setState({
      teamLimitExceeded: BillingService.isLimitExceeded(
        this.props,
        'teams',
        () => this.props.organization.teams.t.length,
      ),
    });
  };

  toggleAccordion = (openAccordion: IState['openAccordion']) => () =>
    this.setState({ openAccordion });

  onNewTeamNameChange = (e: ChangeEvent<HTMLInputElement>) =>
    this.setState({ newTeamName: e.target.value, errors: {} });

  onCreateNewTeam = async () => {
    if (this.state.newTeamName === '') return;

    this.setState({ componentNetworkState: 'create-new-team' });
    try {
      const {
        data: { data: newTeam },
      } = await this._teamService.createNew(this.state.newTeamName);
      this.setState({ newTeamId: newTeam.id });
      this.props.requestOrganizationTeams();
      AppTracker.track(T_WA_GS_TEAMS_CREATED, {
        'Team ID': newTeam?.id,
      });
    } catch (err: any) {
      this.setState({
        errors: {
          new_team: err?.response?.data?.meta?.error_message ?? 'Network issue, please try again!',
        },
        componentNetworkState: 'idle',
      });
      exception.handle('E_POST_TEAMS_CREATE', err);
    }
  };

  checkPlanLimitsAndAddTeam = async () => {
    if (this.state.openAccordion === '' && this.state.teamLimitExceeded) {
      this.setState({ showUpgradeModal: true });
    } else {
      this.setState({ openAccordion: this.state.openAccordion === 'new-team' ? '' : 'new-team' });
    }
  };

  render() {
    const { theme } = Theme;
    const { teams } = this.props.organization;
    const { openAccordion, errors, newTeamName, componentNetworkState, newTeamId } = this.state;
    const limit = BillingService.getLimit(this.props, 'teams');

    if (this.props.location.pathname === '/settings/teams') {
      return <Redirect to={`/settings/teams/${teams.t[0].id}`} />;
    }

    if (newTeamId !== '' && componentNetworkState === 'idle') {
      return <Redirect to={`/settings/teams/${newTeamId}`} />;
    }

    return (
      <React.Fragment>
        <ChakraProvider theme={libraryTheme}>
          <div id="org_settings__container">
            <div id="org_settings__sidebar">
              <Grid type="column" className="org_settings__sidebar_container">
                <Accordion
                  tabProps={{ style: { padding: 0 } }}
                  title={
                    <Grid alignItems="center" justifyContent="space-between">
                      <Grid alignItems="center">
                        <Link to="/settings" style={{ height: 20 }}>
                          <ArrowLeftIcon height={20} width={20} color={theme.shades.cement} />
                        </Link>
                        <Heading
                          className="ml-5"
                          fontSize={20}
                          height={32}
                          fontWeight={400}
                          color={theme.shades.black}
                          style={{ marginTop: 2 }}
                        >
                          Teams
                        </Heading>
                        {teams.t.length > 0 && (
                          <Para fontSize={20} color={theme.shades.black} style={{ marginLeft: 10 }}>
                            ({`${teams.t.length}`})
                          </Para>
                        )}
                      </Grid>
                      <Tippy
                        content={
                          this.state.teamLimitExceeded ? (
                            <UpgradeOnlyModal
                              hasBillingPermission={BillingService.hasManageBillingPermission(
                                this.props,
                              )}
                              align="right"
                              showModal={this.state.teamLimitExceeded}
                              message={BillingService.getMessage(limit, 'teams', this.props)}
                              header={BillingService.getHeader(limit, 'teams', this.props)}
                            />
                          ) : (
                            ''
                          )
                        }
                        interactive={true}
                        animation={'scale'}
                        placement="bottom"
                      >
                        <IconButton onClick={this.checkPlanLimitsAndAddTeam}>
                          {openAccordion !== 'new-team' ? (
                            <AddIcon
                              height={20}
                              width={20}
                              style={this.state.teamLimitExceeded ? { opacity: 0.1 } : {}}
                            />
                          ) : (
                            <CloseIcon height={20} width={20} />
                          )}
                        </IconButton>
                      </Tippy>
                    </Grid>
                  }
                >
                  {openAccordion === 'new-team' && (
                    <CardBlock style={{ background: theme.shades.white }} className="mt-10">
                      <FormBlock onFormSubmit={this.onCreateNewTeam}>
                        <Label fontSize={14}>Add new team</Label>

                        <InputBlock
                          className="mt-5"
                          style={{ height: 32, padding: 4 }}
                          value={newTeamName}
                          onChange={this.onNewTeamNameChange}
                          placeholder="Name"
                          error={!!errors.new_team}
                          autoFocus={true}
                        />
                        <ErrorBlock fontSize={14}>{errors.new_team}</ErrorBlock>

                        <TextButton
                          disabled={
                            newTeamName === '' || componentNetworkState === 'create-new-team'
                          }
                          onClick={this.onCreateNewTeam}
                          className="mt-10"
                        >
                          <Grid alignItems="center">
                            <Para color={theme.shades.white}>Create</Para>
                            {componentNetworkState === 'create-new-team' && (
                              <SpinLoader className="ml-5" base="12px" color={theme.shades.white} />
                            )}
                          </Grid>
                        </TextButton>
                      </FormBlock>
                    </CardBlock>
                  )}
                </Accordion>

                <Grid flexWidth={12} type="column" className="mt-10">
                  {teams.t.map((t, i) => {
                    return (
                      <Link to={`/settings/teams/${t.id}`} key={i} className="no_underline">
                        <FocusBlock
                          value={t.id}
                          padding="8px"
                          className={cx('org_settings__focus_tab', {
                            org_settings__in_focus: this.props.location.pathname.includes(t.id),
                          })}
                        >
                          <Grid flexWidth={12} justifyContent="space-between" alignItems="center">
                            <Para fontSize={14} fontWeight={400} color={theme.shades.cement}>
                              {t.name}
                            </Para>
                            {t.default && (
                              <div>
                                <Tooltip
                                  label="Default team"
                                  offset={{ top: '24px', left: '-100px' }}
                                >
                                  <DefaultIcon height={14} width={14} color={theme.shades.cement} />
                                </Tooltip>
                              </div>
                            )}
                          </Grid>
                        </FocusBlock>
                      </Link>
                    );
                  })}
                </Grid>
              </Grid>
            </div>

            <div id="org_settings__routes">
              <Route
                exact={true}
                path={`/settings/teams/:id`}
                render={(props: RouteComponentProps<any>) => {
                  return <OrganizationSettingsTeamsRender tab="members" {...props} />;
                }}
              />
              <Route
                exact={true}
                path={`/settings/teams/:id/:entity(squads|entities|members|roles|settings|stakeholder)`}
                render={(props: RouteComponentProps<any>) => {
                  return (
                    <RouterGuardProvider>
                      <OrganizationSettingsTeamsRender
                        tab={props.match?.params.entity}
                        {...props}
                      />
                    </RouterGuardProvider>
                  );
                }}
              />
            </div>
          </div>
        </ChakraProvider>
      </React.Fragment>
    );
  }
}

export default connect(({ organization }: IAppState) => ({ organization }), {
  requestOrganizationTeams,
})(withSettingPageACL(OrganizationTeams));
