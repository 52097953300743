export enum INCIDENTS_TAB {
  ALL = 'all',
  OPEN = 'open',
  TRIGGERED = 'triggered',
  ACKNOWLEDGED = 'acknowledged',
  RESOLVED = 'resolved',
  SUPPRESSED = 'suppressed',
}

export enum FILTERS_TAB {
  FORM = 1,
  LIST,
}

export enum INCIDENT_ACTION_ID {
  NONE = '',
  INLINE_ACKNOWLEDGE = 'inline-acknowledge',
  INLINE_REASSIGN = 'inline-reassign',
  INLINE_RESOLVE = 'inline-resolve',
  INLINE_RETRO = 'inline-retro',
  INLINE_UPDATE_TAGS = 'inline-update-tags',
  INLINE_UPDATE_PRIORITY = 'inline-update-priority',
  BULK_ACKNOWLEDGE = 'bulk-acknowledge',
  BULK_REASSIGN = 'bulk-reassign',
  BULK_RESOLVE = 'bulk-resolve',
  BULK_UPDATE_PRIORITY = 'bulk-priority',
  INCIDENT_MERGE = 'incident-merge',
  SNOOZE_UNSNOOZE = 'snooze-unsnooze',
}

export enum INCIDENT_STATUS {
  ALL = 'all',
  OPEN = 'open',
  TRIGGERED = 'triggered',
  ACKNOWLEDGED = 'acknowledged',
  RESOLVED = 'resolved',
  SUPPRESSED = 'suppressed',
}

export enum MERGE_ELIGIBILITY {
  NOT_SELECTED = 'not-selected',
  SAME_SERVICE = 'same-service',
  PARENT_INCIDENTS = 'parent-incidents',
  MAX_COUNT = 'max-count',
  ENABLED = 'enabled',
}
