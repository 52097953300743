import Axios from 'axios';
import { API } from '../../api';
import {
  ITeam,
  ITeamChildEntities,
  ITeamMemberDependencies,
  ITeamMemberReplaceReq,
} from '../../interfaces/ITeams';
class TeamsService {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/teams`;

  createNew = (name: string) =>
    Axios.post<{ data: ITeam }>(this._api, {
      name,
      description: '',
      members_ids: [API.config.userId],
    });

  update = (teamId: string, data: Pick<ITeam, 'name' | 'description' | 'members'>) =>
    Axios.put<{ data: ITeam }>(`${this._api}/${teamId}`, data);

  delete = (teamId: string) => Axios.delete<{ data: boolean }>(`${this._api}/${teamId}`);

  getEntities = (teamId: string) =>
    Axios.get<{ data: ITeamChildEntities }>(`${this._api}/${teamId}/entities`);

  deleteTeamMember = (teamId: string, memberId: string) =>
    Axios.delete(`${this._api}/${teamId}/members/${memberId}`);

  getDependencies = (teamId: string, memberId: string) =>
    Axios.get<{ data: ITeamMemberDependencies }>(
      `${this._api}/${teamId}/members/${memberId}/dependencies`,
    );

  replaceTeamMember = (teamId: string, memberId: string, payload: ITeamMemberReplaceReq) =>
    Axios.put(`${this._api}/${teamId}/members/${memberId}/replace`, payload);
}

export default TeamsService;
