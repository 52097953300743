import React, { HTMLAttributes, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { CopyIconButton } from '..';

export interface IShell {
  minHeight?: string | '400px';
  minWidth?: string | '200px';
}

export const Shell = styled.div<IShell>`
  min-width: ${props => props.minWidth || '200px'};
  min-height: ${props => props.minHeight || '400px'};
  width: ${props => props.minWidth || '200px'};
  height: ${props => props.minHeight || '400px'};
  background: ${props => props.theme.shades.black};
  color: ${props => props.theme.shades.white};
  padding: 2px 8px;
  border-radius: 3px;

  & > #code-renders {
    margin-top: 10px;
    padding: 4px 4px 0 4px;
    overflow: scroll;
    height: ${props => `calc(${props.minHeight || '400px'} - 50px)`};

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      min-height: 64px;
      background-color: ${props => props.theme.shades.grey};
      border-radius: 5px;
    }
  }

  & > pre,
  & > * > pre {
    font-family: ${props => props.theme.monoFontFamily};
  }
`;

export const CopyShell = styled.div<{ blockTop?: string }>`
  float: right;
  position: absolute;
  top: ${props => props.blockTop || '10px'};
  right: 20px;
  display: none;
  width: 24px;
  height: 24px;
  background-size: 100%;
  font-family: ${props => props.theme.monoFontFamily};
  user-select: none;

  & > * > * > svg {
    stroke: ${props => props.theme.shades.white};
    height: 16px;
    width: 16px;
  }

  & > * > * > span {
    margin-left: -60px;
    margin-top: -30px;
  }
`;

export const PreShell = styled.button`
  position: relative;
  width: 100%;
  text-align: left;
  color: white;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 8px 0;
  font-size: 14px !important;

  &:hover ${CopyShell} {
    display: block;
  }

  & > span,
  & > pre,
  & > p {
    font-family: ${props => props.theme.monoFontFamily};
    overflow-wrap: anywhere;
  }

  & > span > span {
    font-family: ${props => props.theme.monoFontFamily};
    background: ${props => props.theme.shades.white};
    padding: 4px 8px;
    border-radius: 3px;
    width: fit-content;
    color: ${props => props.theme.shades.black};
  }

  & > pre {
    margin: 4px 0 0 2px;
  }

  & > span > span.isFocus {
    text-decoration: underline;
  }

  & > span > span.isFocus,
  &:hover:not(.no-hover) > span > span,
  &:active:not(.no-hover) > span > span {
    background: ${props => props.theme.primary.default};
    color: ${props => props.theme.shades.white};
  }
`;

interface IPreBlockProps extends HTMLAttributes<HTMLDivElement> {
  isSelected?: boolean;
  index?: number;
  disabled?: boolean;
  scrollTo?: boolean;
  focusColor?: string | 'theme.shades.smoke';
  onSelectValue?: (event: React.MouseEvent<HTMLButtonElement>, index: number, value: any) => void;
  code: Record<string, unknown>;
  blockTop?: string;
  value: any;
  focus?: boolean;
}

export const PreBlock = (props: IPreBlockProps) => {
  const { code, blockTop, value, onSelectValue, index } = props;
  const renderCode = JSON.stringify(code, null, 2);

  const focusRef = useRef(null);

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onSelectValue) {
      onSelectValue(event, index || 0, value);
    }
  };

  useEffect(() => {
    if (focusRef.current && props.scrollTo) {
      (focusRef.current as unknown as HTMLDivElement).scrollIntoView({
        block: 'nearest',
        behavior: 'auto',
        inline: 'nearest',
      });
    }
  });

  const copyPreventDefault = (e: any) => e.stopPropagation();

  const isObjAssigner = renderCode[0] === '{' ? '{' : renderCode[0] === '[' ? '[' : null;

  return (
    <PreShell className={`${props.disabled ? 'no-hover' : ''}`} onClick={onClick} ref={focusRef}>
      {isObjAssigner && (
        <>
          {value !== '' && (
            <span>
              <span className={props.focus ? 'isFocus' : ''}>{value}</span> {`: ${isObjAssigner}`}
            </span>
          )}
          <pre style={{ flexWrap: 'wrap', marginLeft: isObjAssigner ? '-5px' : 2 }}>
            {isObjAssigner ? renderCode.substring(2, renderCode.length - 2) : renderCode}
          </pre>
          {value !== '' && <span>{isObjAssigner === '{' ? '}' : ']'}</span>}
        </>
      )}
      {!isObjAssigner && (
        <>
          {value !== '' && (
            <span>
              <span className={props.focus ? 'isFocus' : ''}>{value}</span>
            </span>
          )}
          <p style={{ flexWrap: 'wrap', marginTop: 10 }}>{String(renderCode)}</p>
        </>
      )}
      <CopyShell blockTop={blockTop} onClick={copyPreventDefault}>
        <CopyIconButton type="transparent">
          {value !== ''
            ? JSON.stringify({ [value]: code }, null, 2)
            : JSON.stringify(code, null, 2)}
        </CopyIconButton>
      </CopyShell>
    </PreShell>
  );
};
