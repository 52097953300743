export type GroupingWindowOption = {
  value: number;
  label: string;
};

export const defaultGroupingOptions: GroupingWindowOption[] = [
  {
    value: 5,
    label: '5 mins',
  },
  {
    value: 10,
    label: '10 mins',
  },
  {
    value: 15,
    label: '15 mins',
  },
  {
    value: 20,
    label: '20 mins',
  },
  {
    value: 45,
    label: '45 mins',
  },
  {
    value: 60,
    label: '1 hour',
  },
  {
    value: 120,
    label: '2 hours',
  },
  {
    value: 240,
    label: '4 hours',
  },
  {
    value: 480,
    label: '8 hours',
  },
  {
    value: 720,
    label: '12 hours',
  },
  {
    value: 1440,
    label: '24 hours',
  },
];
