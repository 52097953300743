import Axios from 'axios';
import { API } from 'core';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';
import { AppConfig } from 'shared/app.config';

const checkDuplicateStatusApi = async (squadcastDomainName: string) => {
  const { data: response } = await Axios.get(
    `${AppConfig.api_url}/v4/organizations/${API.config.organizationId}/statuspages/pageByDomain?domain=${squadcastDomainName}`,
  );
  return response.data;
};

export const useCheckDuplicateDomainStatus = (squadcastDomainName: string) => {
  const location = useLocation();
  const { data, isLoading, error } = useQuery(
    ['getDomainCheck', squadcastDomainName],
    () => checkDuplicateStatusApi(squadcastDomainName),
    {
      enabled: !location.search.includes('config'),
    },
  );

  return {
    data,
    isLoading,
    error,
  };
};
