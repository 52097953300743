import React, { HTMLAttributes, ReactChild } from 'react';
import styled from 'styled-components';
import { spin } from '../../utils/Animations';
interface IProps extends HTMLAttributes<HTMLDivElement> {
  base?: string | '16px';
  children?: ReactChild;
}

const SpinLoaderWChild = styled.div`
  height: fit-content;
  width: fit-content;
  margin: 10px;
  animation: ${spin} 0.6s infinite linear;
`;

const SpinLoaderWOChild = styled.div<IProps>`
  margin: 5px;
  height: ${props => props.base || '16px'};
  width: ${props => props.base || '16px'};
  border: 2px ${props => props.color || props.theme.primary.default}47 solid;
  border-top: 2px ${props => props.color || props.theme.primary.default} solid;
  border-radius: 50%;
  animation: ${spin} 0.6s infinite linear;
`;

const SpinLoader = (props: IProps) =>
  props.children ? (
    <SpinLoaderWChild {...props}>{props.children}</SpinLoaderWChild>
  ) : (
    <SpinLoaderWOChild {...props} />
  );

/** @component */
export default SpinLoader;
