import { Para, TextButton } from 'uie/components';
import React from 'react';
import { Member } from '../../types';
import AddSquadMembersModal from './AddMemberModal';

type AddMemberToSquadProps = {
  onSubmitted?: () => void;
  members: Member[];
  onHelpClick?: () => void;
};

function AddMemberToSquad(props: AddMemberToSquadProps) {
  const [addingNewMember, setAddingNewMember] = React.useState(false);

  React.useEffect(() => {
    setAddingNewMember(false);
  }, [props.members]);

  if (!addingNewMember) {
    return (
      <TextButton style={{ marginTop: '16px' }} onClick={() => setAddingNewMember(true)}>
        <Para fontSize={16} color="#fff">
          Add New Members
        </Para>
      </TextButton>
    );
  }

  return (
    <AddSquadMembersModal
      onClose={() => setAddingNewMember(false)}
      members={props.members}
      onHelpClick={props.onHelpClick}
    />
  );
}

export default AddMemberToSquad;
