import * as React from 'react';
import { SVGProps } from 'react';

const SvgRundeckByPagerdutyLogo20X20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#f73f39',
      }}
      d="M16.183 3.943 13.64-.097H0l2.545 4.039 13.638.001zM0 20.097h13.639l2.544-4.039H2.545L0 20.097zM5.09 7.98h13.638L20 10l-1.272 2.02H5.09L6.362 10 5.09 7.98z"
    />
  </svg>
);

export default SvgRundeckByPagerdutyLogo20X20;
