import { EntityDetailsPopover } from './Popover';
import PopoverContent, { Section } from './Popover/popoverContent';
import { Para } from 'uie/components';
import { Link } from 'react-router-dom';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { ISquad } from 'core/interfaces/ISquad';
import { IEscalationPolicy } from 'core/interfaces/IEscalationPolicy';
import { ITeam } from 'core/interfaces/ITeams';
import { IService } from 'core/interfaces/IService';
import { EntityType, GetEntityComponentProps } from './helpers';
import { getPopoverHeader } from './Popover/PopoverHeader';
import { PopoverSection } from './Popover/PopoverSection';
import { IAppState } from 'core/interfaces/IAppState';

const linkStyles = {
  color: '#3D6DD8',
  cursor: 'pointer',
};

export const UINavigationPopover = ({
  id,
  popoverPlacement,
  popoverTrigger,
  type,
  entityObject,
  entityName,
  getEntityLink,
  organization,
}: Pick<GetEntityComponentProps, 'id' | 'popoverPlacement' | 'popoverTrigger' | 'type'> & {
  entityName: string;
  entityObject: IUsersOfOrganization | ISquad | IEscalationPolicy | ITeam | IService | undefined;
  getEntityLink: (type: EntityType, entityId: string | undefined) => string;
  organization: IAppState['organization'];
}) => {
  const { getPopoverSections } = PopoverSection({
    entityType: type,
    entity: entityObject,
    getEntityLink,
    organization,
  });
  const LinkWithPopover = () => {
    return (
      <EntityDetailsPopover
        applyChakraStyle={false}
        placement={popoverPlacement ?? 'top-end'}
        trigger={
          popoverTrigger ?? (
            <Para as="span" style={{ ...linkStyles, fontSize: '14px' }}>
              {entityName || 'Deleted entity'}
            </Para>
          )
        }
        disablePortal
      >
        <PopoverContent
          header={getPopoverHeader(type, entityObject)}
          sections={getPopoverSections}
        />
      </EntityDetailsPopover>
    );
  };
  const LinkWithoutPopover = () => {
    let searchQuery = '';
    if (type === 'routing_rule') {
      searchQuery = '?rule-tab=routing&tab=rules';
    }
    if (type === 'suppression_rule') {
      searchQuery = '?rule-tab=suppression&tab=rules';
    }
    return (
      <Link
        to={
          searchQuery
            ? {
                pathname: getEntityLink(type, id),
                search: searchQuery,
              }
            : getEntityLink(type, id)
        }
        style={{
          ...linkStyles,
          fontSize: '14px',
          marginLeft: '0px',
          textDecoration: 'none',
        }}
      >
        {popoverTrigger ?? (entityName || 'Deleted Entity')}
      </Link>
    );
  };
  const SimplePopover = () => {
    return (
      popoverTrigger ?? (
        <Para as="span" style={{ fontSize: '14px' }}>
          {entityName || 'Deleted entity'}
        </Para>
      )
    );
  };

  return { SimplePopover, LinkWithPopover, LinkWithoutPopover };
};
