import { Box, HStack, Text } from '@chakra-ui/layout';
import { PRIORITY_COLORS } from 'library/theme/colors';
import { PRIORITY_TYPE } from 'library/types';
import { FC } from 'react';
import { PRIORITY, PRIORITY_OPTIONS } from './constant';

type PriorityValueProp = {
  value?: string | null;
  width?: string;
};

type PriorityOptionProp = PriorityValueProp & {
  label: string;
};

export const Priority: FC<PriorityValueProp> = ({ value, width }) => {
  const hasValue = !!value && value !== PRIORITY.Unset;

  return (
    <Box
      w={width ?? '26px'}
      p="3px 6px"
      backgroundColor={PRIORITY_COLORS(value)}
      borderRadius="3px"
      textAlign="center"
      display="flex"
    >
      <Text
        color={hasValue ? 'brand.white' : 'secondary.1000'}
        variant="hint_700"
        lineHeight="100%"
      >
        {hasValue ? value : '-'}
      </Text>
    </Box>
  );
};

export const PriorityOption: FC<PriorityOptionProp> = ({ value, label }) => {
  return (
    <HStack columnGap={2} alignItems="center">
      <Priority value={value} />
      <Text color="secondary.1000" variant="formInput">
        {label}
      </Text>
    </HStack>
  );
};

export const getPriorityOption = (val: PRIORITY_TYPE) => {
  return (
    PRIORITY_OPTIONS.find(opt => opt.value === val) || PRIORITY_OPTIONS[PRIORITY_OPTIONS.length - 1]
  );
};
