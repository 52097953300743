import { clear } from 'store/localStorage';
import { queryClient } from 'views';
import { GET_INCIDENT_ASSIGNABLE_ENTITIES_QUERY_KEY } from 'views/main/organization/incident-list/common/constants';

import { Init } from '.';
import { API } from '../../core/api';
import { exception } from '../../core/exception';
import { IOrganizationEvents } from '../../core/interfaces/IOrganization';
import { SSOService } from '../../core/services';
import { LocalStore } from '../../store/local';

/**
 * check if the /sso/saml responded with a session_token different than current_token
 */
export function ssoInitTokenIsDifferent(this: Init) {
  return (
    window.location.pathname === '/sso/saml' &&
    new URL(window.location.toString()).searchParams.get('session_token') !==
      this.props.auth.sessionId
  );
}

export function checkIfBrowserIsOnline(this: Init) {
  this.setState({
    networkState: navigator.onLine ? 'online' : 'offline',
  });
}

export async function resetStates(this: Init) {
  API.config.sessionId = '';
  API.config.organizationId = '';
  await LocalStore.removeHostname();
  clear();
  this.setState({
    componentState: 'idle',
    sessionId: '',
    organizationId: '',
    forceRoot: true,
    ssoEnabled: false,
    ssoChecked: false,
  });
}

/**
 * set mount states when the app is loaded
 */
export async function setMountStates(this: Init) {
  try {
    if (this.ssoInitTokenIsDifferent()) {
      if (this.props.INIT_ORG.orgId !== '') {
        const token = new URL(window.location.toString()).searchParams.get('session_token');
        if (token) {
          this.props.onLoginSuccess(token);
          return;
        }
        this.props.requestInitOrg('');
        this.props.onRequestUserOrganizationSuccess([]);
      }
      this.setState({ sessionId: '', organizationId: '', hostname: '', componentState: 'idle' });
      return;
    }

    const [sessionId, organizationId, hostname] = [
      this.props.auth.sessionId,
      this.props.INIT_ORG.orgId,
      await LocalStore.getHostname(),
    ];
    const hasOrganization = this.props.userOrganizations.o.length > 0;

    this.setState({
      sessionId,
      organizationId: hasOrganization ? organizationId : '',
      hostname: hostname || '',
    });

    if (sessionId === '' || organizationId === '') {
      this.setState({ componentState: 'idle' });
      return;
    }
  } catch (err: any) {
    exception.handle('E_INIT_INVALID_BEFORE_RENDER', err);
    this.props.requestUserLogout();
  }
}

export async function orgHasEnabledSSO(this: Init) {
  const _ssoService = new SSOService();
  try {
    const { data: ssoConfig } = await _ssoService.getSSOConfig(this.props.INIT_ORG.orgId);
    return ssoConfig.data && ssoConfig.data.is_enabled;
  } catch {
    return false;
  }
}

/**
 * start pusher and listen to org-events
 */
export async function initPusherEvents(this: Init) {
  API.socket.subscribe(API.config.organizationId);
  API.socket.bind('reload-event', ({ message }: { message: IOrganizationEvents }) => {
    switch (message) {
      case 'reload-users':
        this.props.requestOrganizationUsers();
        this.props.requestOrganizationTeams();
        window.location.reload();
        break;
      case 'reload-squad':
        this.props.requestOrganizationSquad();
        break;
      case 'reload-escalation-policies':
        this.props.requestOrganizationEscalation();
        break;
      case 'reload-services':
        this.props.requestOrganizationService();
        break;
      case 'reload-calendar':
        this.props.requestOrganizationCalendar();
        break;
      case 'reload-team':
        this.props.requestOrganizationTeams();
        break;
      case 'reload-incident-assignable-entities':
        queryClient.invalidateQueries({
          queryKey: [GET_INCIDENT_ASSIGNABLE_ENTITIES_QUERY_KEY],
        });
        break;
      default:
        break;
    }
  });
}
