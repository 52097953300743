import React, { useState, ReactElement, useRef, InputHTMLAttributes } from 'react';
import { Shell, Drop, IDrop, IShell } from './helper';
import { IFocusBlock } from '../FocusBlock';
import keyNav from '../../utils/keyNav';
import InputBlock from '../InputBlock';

type IMix = React.HTMLAttributes<HTMLDivElement> & IDrop & IShell;

interface IAutocomplete extends IMix {
  open?: boolean;
  children: ReactElement<IFocusBlock>[] | any;
  width?: string | '200px';
  disabled?: boolean;
  openOnFocus?: boolean | true;
  searchPropsHook?: InputHTMLAttributes<HTMLInputElement>;
  value: string;
  placeHolder: string;
  showDropDownOnSelect?: true;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange: (event: React.KeyboardEvent<HTMLDivElement>, value: any) => void;
}

const Autocomplete = (props: IAutocomplete) => {
  let selectedIndex = 0;
  const setSelectedItemIndex = () => {
    React.Children.map(props.children, (child: ReactElement<IFocusBlock>, i) => {
      if (child.props.isSelected) {
        selectedIndex = i;
      }
    });
  };
  setSelectedItemIndex();

  const [currentIndex, setCurrentIndex] = useState(selectedIndex);
  const [canScroll, setCanScroll] = useState(selectedIndex !== 0);
  const hookRef = useRef<HTMLInputElement>(null);

  let dropDownListLength = 0;
  const disabledIndex: number[] = [];

  const onClick = () => {
    setSelectedItemIndex();
    setCanScroll(true);
    setTimeout(() => setCanScroll(false), 20);
  };

  const onChildClick = (event: any, index: number, value: any) => {
    const newValue = value;
    props.onValueChange(event, newValue);
    setCurrentIndex(index);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (props.children.length === 0) {
      return;
    }
    setCanScroll(true);
    let index = currentIndex;
    let preventDefault = false;
    let cancelRoutine = false;

    const onEsc = () => {
      cancelRoutine = true;
      return;
    };

    keyNav(event)({
      onEsc,
      onTab: () => {
        if (disabledIndex.includes(currentIndex)) {
          cancelRoutine = true;
          return;
        }

        if (clonedChild[currentIndex]) {
          preventDefault = true;
          const newValue = clonedChild[currentIndex].props.value;
          props.onValueChange(event, newValue);
        } else {
          hookRef.current?.blur();
        }
      },
      onEnter: () => {
        if (disabledIndex.includes(currentIndex)) {
          cancelRoutine = true;
          return;
        }

        if (clonedChild[currentIndex]) {
          hookRef.current?.blur();
          const newValue = clonedChild[currentIndex].props.value;
          props.onValueChange(event, newValue);
        }
      },
      onKeyUp: () => {
        if (currentIndex - 1 >= 0) {
          index -= 1;
        } else {
          index = dropDownListLength;
        }
        preventDefault = true;
      },
      onKeyDown: () => {
        if (currentIndex + 1 <= dropDownListLength) {
          index += 1;
        } else {
          index = 0;
        }
        preventDefault = true;
      },
    });

    if (cancelRoutine) return;
    if (preventDefault) event.preventDefault();
    setCurrentIndex(index);
  };

  const onKeyUp = () => {
    setCanScroll(false);
  };

  const clonedChild = React.Children.map(props.children, (child: ReactElement<IFocusBlock>, i) => {
    const focus = i === currentIndex && !child.props.disabled;
    if (child.props.disabled) {
      disabledIndex.push(i);
    }
    return React.cloneElement(child, {
      focus,
      tabIndex: focus ? 0 : -1,
      key: i,
      index: i,
      scrollTo: focus && canScroll,
      onSelectValue: onChildClick,
    });
  });

  dropDownListLength = clonedChild.length - 1;

  const { onValueChange, ...dropdownProps } = props;

  return (
    <Shell width={props.width} onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
      <InputBlock
        className="hook"
        width={props.width || '200px'}
        height={props.height || '200px'}
        disabled={props.disabled}
        onFocus={onClick}
        error={props.error}
        placeholder={props.placeHolder}
        onChange={props.onChange}
        value={props.value}
        forwardRef={hookRef}
        {...props.searchPropsHook}
      />

      <Drop tabIndex={0} {...dropdownProps} width={props.width}>
        <React.Fragment>
          <div className="renders">{clonedChild}</div>
        </React.Fragment>
      </Drop>
    </Shell>
  );
};

/** @component */
export default Autocomplete;
