import { Cell } from 'react-table';
import { INCIDENT_DATA } from '../common/types';
import { ChangeEvent } from 'react';
import { HStack } from '@chakra-ui/react';
import { Checkbox, IconButton } from 'library/atoms';
import { OutlineStarIcon, StarIcon } from 'library/icons';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';

interface Props {
  selectedRowIds: Array<string>;
  updateCommonStatus: (rowIds: string[]) => void;
  setSelectedRowIds: (checkedRows: string[]) => void;
  starIncident: (id: string, isStarred: boolean) => void;
}

const CellCheckbox =
  ({ selectedRowIds, setSelectedRowIds, updateCommonStatus, starIncident }: Props) =>
  (cell: Cell<INCIDENT_DATA>) => {
    const { row } = cell;

    const StarIconButton = ({ row }: Cell<INCIDENT_DATA>) => {
      const hasUpdateAccess = useUserAccess().hasUpdateAccess('incidents');

      return (
        <NoPermissionTooltip isDisabled={hasUpdateAccess}>
          <IconButton
            variant="listing"
            aria-label="star"
            icon={row.original.isStarred ? <StarIcon width={16} /> : <OutlineStarIcon width={16} />}
            isDisabled={!hasUpdateAccess}
            onClick={() => starIncident(row.original.id, row.original.isStarred)}
          />
        </NoPermissionTooltip>
      );
    };

    const { onChange, ...args } = row.getToggleRowSelectedProps();
    const isChecked = selectedRowIds ? !!selectedRowIds.find(id => id === row.original.id) : false;
    const onSelectRow = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      let rowIds = [];
      if (!isChecked) {
        rowIds = [...(selectedRowIds ? selectedRowIds : []), row.original.id];
      } else {
        const removeUnchecked = selectedRowIds
          ? selectedRowIds.filter(checkedRow => checkedRow !== row.original.id)
          : [];
        rowIds = removeUnchecked;
      }

      updateCommonStatus(rowIds);
      setSelectedRowIds(rowIds);
    };
    const stateArgs = { isChecked };
    return (
      <HStack pl={6} spacing="14px" alignItems="center">
        <Checkbox {...args} {...stateArgs} onChange={onSelectRow} size="lg" />
        {StarIconButton(cell)}
      </HStack>
    );
  };

export default CellCheckbox;
