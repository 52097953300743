import React from 'react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

interface IButtonMenuItem {
  label: string;
  [key: string]: any;
}

interface IButtonMenuProps<T extends IButtonMenuItem> {
  activeItem: T;
  itemList: T[];
  onMenuClick: (item: T) => void;
  label?: string;
}

function ButtonMenu<T extends IButtonMenuItem>({
  activeItem,
  itemList,
  onMenuClick,
  label,
}: IButtonMenuProps<T>) {
  return (
    <Menu placement="bottom-end" matchWidth>
      <MenuButton
        aria-label={label}
        as={Button}
        variant="outline"
        _focus={{ boxShadow: 'outline' }}
        minW={110}
        rightIcon={<ChevronDownIcon />}
      >
        {activeItem.label}
      </MenuButton>

      <MenuList zIndex={4} minW="auto">
        {itemList.map(item => (
          <MenuItem
            key={item.label}
            onClick={() => onMenuClick(item)}
            fontSize="sm"
            _hover={{
              backgroundColor: item.label === activeItem.label ? 'blue.900' : '',
              textColor: item.label === activeItem.label ? 'white' : '',
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

const SQButton = {
  Menu: ButtonMenu,
};

export default SQButton;
