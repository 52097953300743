import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { API } from 'core';
import { T_WA_UP_GER_RULESET_EDITED, T_WA_UP_GER_RULESET_CREATED } from 'core/const/tracker';
import { Formik, FormikValues } from 'formik';
import { Divider, FormButton, useToast } from 'library/atoms';
import { FormikForm, FormInput, FormSelect, FormTextArea, RouteGuard } from 'library/molecules';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { AppTracker } from 'shared/analytics/tracker';
import { GER_PATH } from 'views/main/routes/routes';
import { AnyObject } from 'yup/lib/types';
import { memo } from 'react';
import { AppContext } from '../..';
import { createGer } from '../../query/useCreateGer';
import { useInvaliDateGER } from '../../query/useInvalidate';
import { updateGer } from '../../query/useUpdateGer';
import { GER_DATA, OWNER } from '../../types';
import { getGerPath } from '../../util/functions';
import { getAllOwnerOptions } from '../../util/owners';
import { GERFormValues, RULESET_TEMPLATE_PROPS_TYPE } from './ruleset-types';
import { GERRulesetValidation } from './validator';
import { AssigneeType } from 'library/enums';

const CREATE_GER_FORM_ID = 'CREATE_GER_FORM_ID';

type Props = {
  gerData?: GER_DATA;
};

export const AddRulesetDetails: FC<Props> = memo(({ gerData }) => {
  const history = useHistory();
  const isEditFlow = useMemo(() => !!gerData?.id, [!!gerData]);

  const invalidate = useInvaliDateGER();

  const onCancel = () => {
    history.push(GER_PATH);
  };

  const onSave = (data: AnyObject) => {
    const op = isEditFlow ? 'updated' : 'added';
    const eventName = isEditFlow ? T_WA_UP_GER_RULESET_EDITED : T_WA_UP_GER_RULESET_CREATED;
    toast({
      status: 'success',
      text: `Success: Ruleset ${op} successfully`,
    });
    const gerId = data?.id ?? '';
    AppTracker.track(eventName, {
      'GER Ruleset ID': gerId,
    });
    invalidate.invalidateDetail();
    invalidate.invalidateList();
    history.push(getGerPath(gerId));
  };

  const onError = (err: any) => {
    const errMsg =
      err?.response?.data?.meta.error_message ??
      `Unable to ${isEditFlow ? 'update' : 'add'} Ruleset`;

    toast({
      status: 'error',
      text: `Error : ${errMsg}`,
    });
  };

  const context = useContext(AppContext);
  const ownerOptions = getAllOwnerOptions(context?.organization);
  const toast = useToast();

  const getAPI = (values: FormikValues | GERFormValues) => {
    const { title, owner, description } = values;
    const gerTitle = title.trim();
    return isEditFlow
      ? updateGer((gerData as GER_DATA).id, gerTitle, owner.value, owner.assigneeType, description)
      : createGer(API.config.teamId, gerTitle, owner.value, owner.assigneeType, description);
  };

  const getOptions = (type: OWNER['type']) => {
    if (type === 'user') {
      return ownerOptions?.[0]?.options;
    }
    if (type === 'squad') {
      return ownerOptions?.[1]?.options;
    }
    return [];
  };
  const getGerValues = useCallback(() => {
    const getOwner = ({ type, id }: OWNER) => {
      const options = getOptions(type);
      const owner = options.find(owner => owner.value === id);

      if (owner) {
        return owner;
      } else {
        const currentUser = context?.organization?.currentUser?.u;
        if (!currentUser) return;
        return {
          label: `${currentUser.first_name} ${currentUser.last_name}`,
          value: currentUser.id,
          assigneeType: AssigneeType.User,
          username: currentUser.username_for_display,
        };
      }
    };
    return {
      title: isEditFlow ? (gerData as GER_DATA).name : '',
      description: isEditFlow ? (gerData as GER_DATA).description : '',
      owner: isEditFlow
        ? getOwner((gerData as GER_DATA).entity_owner)
        : getOwner({ type: 'team', id: API.config.teamId }),
    };
  }, [gerData?.id]);

  return (
    <>
      <Box pl={6} pt={4}>
        <Text variant="h1">{isEditFlow ? 'Edit' : 'Add'} Ruleset Details</Text>
        <Text variant="body">
          Centralize and streamline incident response with Global Event Ruleset, simplifying the
          configuration of alert routing across services.
        </Text>
      </Box>
      <Formik
        initialValues={getGerValues()}
        validationSchema={GERRulesetValidation}
        onSubmit={(values, { setSubmitting }) => {
          getAPI(values)
            .then(({ data }) => onSave(data.data))
            .catch(err => onError(err))
            .finally(() => setSubmitting(false));
        }}
      >
        {formik => {
          const { values, handleSubmit, setFieldValue, setFieldTouched, isSubmitting, errors } =
            formik;
          return (
            <RulesetTemplate
              values={values}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              isSubmitting={isSubmitting}
              errors={errors}
              {...{ ownerOptions, onCancel }}
              isEditFlow={isEditFlow}
            />
          );
        }}
      </Formik>
    </>
  );
});

const RulesetTemplate = memo((props: RULESET_TEMPLATE_PROPS_TYPE) => {
  const {
    values,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    errors,
    ownerOptions,
    onCancel,
    isEditFlow,
  } = props;

  const [enableGuard, setEnableGuard] = useState(false);

  const setObjFieldValue = (field: string, value: any) => {
    setFieldValue(field, value);
    setEnableGuard(true);
  };

  return (
    <>
      <form
        id={CREATE_GER_FORM_ID}
        onSubmit={e => {
          setEnableGuard(false);
          handleSubmit(e);
        }}
        style={{
          position: 'relative',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <Flex flexDir="column" px={6} pt={6} alignItems="flex-start" rowGap={2}>
          <FormInput
            label="Ruleset Name*"
            fieldName="title"
            placeholder="Enter Rule Name"
            onChange={setObjFieldValue}
            value={values.title}
            error={errors.title}
            autoFocus
          />
          <FormTextArea
            label="Ruleset Description"
            fieldName="description"
            onChange={setObjFieldValue}
            placeholder="Describe Ruleset"
            value={values.description}
            error={errors.description}
          />
          <FormSelect
            options={ownerOptions}
            value={values.owner}
            error={errors.owner}
            label="Ruleset Owner*"
            onChange={setObjFieldValue}
            onBlur={setFieldTouched}
            fieldName="owner"
            variant="assignee"
            useCustomValue={true}
            customValue="single"
            controlShouldRenderValue
          />
        </Flex>
        <Box position="absolute" width="full" bottom={4}>
          <Divider />
          <HStack pl={6} py={4}>
            <FormButton
              type="submit"
              form={CREATE_GER_FORM_ID}
              variant="primary"
              title={!isEditFlow ? 'Save' : 'Update'}
              isDisabled={!values.title.trim().length}
              isLoading={isSubmitting}
            />
            <FormButton
              variant="secondary"
              title="Cancel"
              onClick={() => {
                setEnableGuard(false);
                onCancel();
              }}
              disabled={isSubmitting}
            />
          </HStack>
        </Box>
      </form>
      <RouteGuard enableGuard={enableGuard} />
    </>
  );
});
