import { DeleteIcon } from '@chakra-ui/icons';
import { Flex, Text, VStack } from '@chakra-ui/layout';
import FormDropdown from 'library/molecules/Form/FormDropdown/FormDropdown';
import FormField from 'library/molecules/FormField/FormField';
import { ChatOpsIcon, GenericAttachmentIcon, VideoIcon } from 'icons';
import { useFormContext } from 'react-hook-form';
import {
  CHANNEL_LABEL,
  COMMUNICATION_CHANNEL_OPTIONS,
} from 'views/main/organization/workflow/constant';
import {
  CommunicationChannelTypes,
  CommunicationChannelTypesOption,
  SqCommunicationChannelAction,
} from 'views/main/organization/workflow/types';
import { IconButton, Input } from 'library/atoms';
import { THEME_COLORS } from 'library/theme/colors';

const DynamicIcon = ({ type }: { type?: CommunicationChannelTypes }) => {
  if (type === CommunicationChannelTypes.CHAT) {
    return <ChatOpsIcon height={16} width={16} />;
  }
  if (type === CommunicationChannelTypes.VIDEO) {
    return <VideoIcon height={16} width={16} />;
  }
  return <GenericAttachmentIcon height={16} width={16} />;
};

interface Props {
  index: number;
  remove: (index: number) => void;
}

const CommunicationLinkForm = ({ index, remove }: Props) => {
  const { formState, watch, getValues, setValue } = useFormContext<SqCommunicationChannelAction>();
  const channelType = watch(`data.channels.${index}.channelType`);

  return (
    <Flex flex={1} flexDirection="row" mb={4}>
      <Flex
        w={6}
        h={6}
        mr={2}
        borderRadius="50%"
        bgColor="secondary.900"
        color="brand.white"
        justifyContent="center"
        alignItems="center"
      >
        <Text color={THEME_COLORS.brand.white}>{index + 1}</Text>
      </Flex>
      <Flex pb={4} flexDirection="column" flex={1}>
        <Flex justifyContent="flex-end">
          <IconButton
            icon={<DeleteIcon />}
            aria-label="delete"
            onClick={() => remove(index)}
            zIndex={1}
          />
        </Flex>
        <VStack mt={-5} gap={2}>
          <FormField
            style={{ zIndex: 1 }}
            label="Select"
            error={formState.errors?.data?.channels?.[index]?.channelType?.message}
          >
            <FormDropdown<CommunicationChannelTypesOption>
              options={COMMUNICATION_CHANNEL_OPTIONS}
              name={`data.channels.${index}.channelType`}
              defaultValue={getValues(`data.channels.${index}.channelType`)}
            />
          </FormField>
          <FormField
            style={{ zIndex: 0 }}
            label={CHANNEL_LABEL[channelType.value]}
            error={formState.errors?.data?.channels?.[index]?.link?.message}
          >
            <Input
              addonPosition="left"
              addon={<DynamicIcon type={channelType?.value} />}
              variant="addon"
              aria-label="url"
              defaultValue={getValues(`data.channels.${index}.link`)}
              onChange={e =>
                setValue(`data.channels.${index}.link`, e.target.value, { shouldDirty: true })
              }
            />
          </FormField>
          <FormField
            label="Display text (optional)"
            error={formState.errors?.data?.channels?.[index]?.displayText?.message}
          >
            <Input
              defaultValue={getValues(`data.channels.${index}.displayText`)}
              onChange={e =>
                setValue(`data.channels.${index}.displayText`, e.target.value, {
                  shouldDirty: true,
                })
              }
            />
          </FormField>
        </VStack>
      </Flex>
    </Flex>
  );
};

export default CommunicationLinkForm;
