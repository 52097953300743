import React, { useEffect, useMemo, useState } from 'react';
import { UseMutationOptions } from 'react-query';

import {
  UpdateStatusPageStatusMutation,
  UpdateStatusPageStatusMutationVariables,
  useUpdateStatusPageStatusMutation,
} from '../graphql/mutation';
import { reactQueryConfigError } from '../helpers/helper.toast';
import { usePageDetails } from './usePageDetails';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_STATUS_UPDATED_MANUALLY } from 'core/const/tracker';

type onSubmit = () => void;

const useUpdatePageStatus = <TError = unknown, TContext = unknown>(
  onSuccess: (data: any) => void,
  options?: UseMutationOptions<
    UpdateStatusPageStatusMutation,
    TError,
    UpdateStatusPageStatusMutationVariables,
    TContext
  >,
) => {
  return useUpdateStatusPageStatusMutation({
    ...options,
    onSuccess: data => {
      onSuccess(data as any);
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_STATUS_UPDATED_MANUALLY);
    },
    onError: reactQueryConfigError('Update page status'),
  });
};

export function useStausUpdate(pageId: number, onSuccussHandler: onSubmit) {
  const [statusId, setStatusId] = useState(-1);
  const { details, isSuccess } = usePageDetails(pageId);

  useEffect(() => {
    if (isSuccess) {
      setStatusId(details.status.id);
    }
  }, [isSuccess]);

  const { mutateAsync: updateStatus } = useUpdatePageStatus(() => {
    onSuccussHandler();
  });

  const onFormSubmit = () => {
    updateStatus({ id: pageId, statusID: statusId });
  };

  return {
    statusId,
    setStatusId,
    onFormSubmit,
    statusCodes: details?.statusCodes || [],
    isSuccess: isSuccess,
  };
}
