import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import listViewPlugin from '@fullcalendar/list';
import luxon2Plugin from '@fullcalendar/luxon2';
import FullCalendar, { DayHeaderContentArg, EventContentArg } from '@fullcalendar/react';
import rrulePlugin from '@fullcalendar/rrule';
import timeViewPlugin from '@fullcalendar/timegrid';
import { CustomDrawerComponent } from 'components/chakra/Drawer';
import Loader from 'components/chakra/Loader';
import { DateTime } from 'luxon';
import React, { memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { GetSchedulesQuery } from 'views/main/organization/schedules/graphql/query';

import { useOrganizationConfig } from '../..';
import { RotationViewType } from '../../constants/schedules.rotation-type';
import { minutesDiffThreshold, rotationGapID } from '../../constants/schedules.view';
import { ScheduleEvent } from '../../graphql/types';
import {
  useGetAllPatternParticipants,
  IPatternParticipant,
} from '../../helpers/helpers.customrotations';
import {
  compareTodayTZDate,
  compareTwoDates,
  getEventDateRange,
  getFullDate,
  getMinutesDiff,
  getTZFormat,
  getTZTime,
  getUTCDateTime,
  isAllDayInTZ,
} from '../../helpers/helpers.date';
import {
  filterEventPerTheRotationType,
  splitEventsForTheListView,
} from '../../helpers/helpers.event';
import { getGapAsRotation, getOverridesAsRotation } from '../../helpers/helpers.schedule';
import { CalendarViewType, IScheduleEvents } from '../../interface/schedule';
import { EventDetails } from '../schedules.events/events.details';
import { ScheduleDetails } from '../schedules.events/schedules.details';
import { useScheduleHeaderContext } from '../schedules.header/context';
import { IListEvent, IListEventInfo } from '../schedules.list/listView.table';
import { CalendarEvent } from './calendar.events';
import { calendarStyles } from './calendar.styles';

const EventDetail = memo(
  ({
    event,
    view,
    disableEventPopover,
    disableEventModifiers,
    allParticipants,
    activeTimezone,
    overlappedByOverride,
  }: EventContentArg & {
    disableEventPopover?: boolean;
    disableEventModifiers?: boolean;
    allParticipants: IPatternParticipant[];
    activeTimezone: string;
    overlappedByOverride: boolean;
  }) => {
    const eventInfo = event.extendedProps as IScheduleEvents[number];
    const participantGroups = eventInfo.participants ?? [];
    const rotationId = eventInfo.rotationId;

    const eventParticipants: Array<any> = [];
    participantGroups?.forEach(participantGroup => {
      eventParticipants.push(...(participantGroup.participants ?? []));
    });

    const startTime = getTZTime(activeTimezone, new Date(event.start ?? ''));
    const endTime = getTZTime(activeTimezone, new Date(event.end ?? ''));

    const isPastEvent = compareTodayTZDate(new Date(event.end ?? ''), activeTimezone).isPast;

    if (!['timeGridWeek', 'timeGridDay', 'dayGridMonth'].includes(view.type)) return <></>;
    const isAllDayTZ = isAllDayInTZ(
      activeTimezone,
      new Date(event.start ?? ''),
      new Date(event.end ?? ''),
    );
    const minutesDifference = getMinutesDiff(
      (event.start ? new Date(event.start) : new Date()).toISOString(),
      (event.end ? new Date(event.end) : new Date()).toISOString(),
      activeTimezone,
    );

    const startDT = getUTCDateTime(
      (event.start ? new Date(event.start) : new Date()).toISOString(),
      activeTimezone,
    );
    const endDT = getUTCDateTime(
      (event.end ? new Date(event.end) : new Date()).toISOString(),
      activeTimezone,
    );
    const eventOffset = compareTwoDates(endDT, startDT).offset;

    return (
      <CalendarEvent
        view={view.type}
        backgroundColor={
          (isPastEvent && !eventInfo.isAGap) || overlappedByOverride
            ? 'gray.120'
            : event.backgroundColor
        }
        textColor={
          (isPastEvent && !eventInfo.isAGap) || overlappedByOverride ? '' : event.textColor
        }
        title={event.title}
        startTime={startTime}
        endTime={endTime}
        eventParticipants={eventParticipants}
        disableEventPopover={disableEventPopover || eventInfo.isAGap}
        isAllDayTZ={isAllDayTZ}
        isAGap={!!eventInfo.isAGap}
        isPastEvent={isPastEvent}
        overlappedByOverride={overlappedByOverride}
        showEventParticipants={
          view.type !== 'dayGridMonth'
            ? (minutesDifference ?? 0) >= minutesDiffThreshold.PARTICIPANTS
            : true
        }
        showEventTime={
          view.type !== 'dayGridMonth'
            ? (minutesDifference ?? 0) >= minutesDiffThreshold.TIME
            : true
        }
        showEventName={
          view.type !== 'dayGridMonth'
            ? (minutesDifference ?? 0) >= minutesDiffThreshold.NAME
            : true
        }
        eventOffset={eventOffset}
      >
        <EventDetails
          {...{
            participants: eventParticipants,
            allParticipants: allParticipants,
            schedule: eventInfo.scheduleName,
            rotation: event.title,
            startTime: startTime,
            endTime: endTime,
            repeats: eventInfo.repeats,
            dateRange: getEventDateRange(startDT, endDT),
            disableEventModifiers: disableEventModifiers || overlappedByOverride,
            timeZone: activeTimezone,
            eventId: event.id,
            rotationId: rotationId ?? 0,
            startDate: startDT.toSQLDate(),
            endDate: endDT.toSQLDate(),
            override: eventInfo.override,
            eventOffset: eventOffset,
            scheduleID: eventInfo.scheduleId ?? 0,
          }}
        />
      </CalendarEvent>
    );
  },
);

const dateClick =
  (
    setDateDrawerContent: any,
    setDateDrawerHeader: any,
    schedules: GetSchedulesQuery['schedules'] | [],
    allParticipants: IPatternParticipant[],
    isScheduleDetailsView: boolean,
    activeRotViewType: { label: string; value: RotationViewType },
    currentUserId: string | undefined,
    timeZone: string,
  ) =>
  (info: DateClickArg) => {
    const events = (schedules?.flatMap(schedule =>
      [
        ...(schedule.rotations ?? []),
        {
          ...getOverridesAsRotation(schedule.ID, schedule.name, timeZone),
          events:
            schedule.overrides?.map(o => ({
              ID: o.ID,
              startTime: o.startTime,
              endTime: o.endTime,
              isOverride: true,
              overrideID: o.ID,
              participants: o.overrideWith?.participants ?? [],
              override: o,
            })) ?? ([] as Event[]),
        },
        {
          ...getGapAsRotation(schedule.ID, schedule.name, timeZone),
          events:
            schedule.gaps?.map(g => ({
              ...g,
              ID: 0,
              isOverride: false,
              overrideID: undefined,
              participants: undefined,
              override: undefined,
            })) ?? ([] as Event[]),
        },
      ].flatMap(rotation => [
        ...(rotation.events?.map(event => {
          const startDT = getUTCDateTime(
            (event as unknown as ScheduleEvent).startTime,
            schedule.timeZone,
          );
          const endDT = getUTCDateTime(
            (event as unknown as ScheduleEvent).endTime,
            schedule.timeZone,
          );
          const overlappedByOverride =
            rotation.ID > rotationGapID
              ? !!schedule.overrides?.find(
                  o =>
                    getUTCDateTime(o.startTime, timeZone) <= startDT &&
                    endDT <= getUTCDateTime(o.endTime, timeZone),
                )
              : false;
          return {
            ...rotation,
            ...event,
            scheduleID: schedule.ID,
            rotationName: rotation.name,
            scheduleName: schedule.name,
            scheduleTimeZone: schedule.timeZone,
            scheduleTags: schedule.tags?.filter(tag => !!tag.key && !!tag.value) ?? [],
            overlappedByOverride,
          };
        }) ?? []),
      ]),
    ) ?? []) as IListEvent[];

    const clickedDate = new Date(info.dateStr);
    const dt = getUTCDateTime(info.dateStr, timeZone);

    const {
      eventsBelongingToToday,
      eventsStartedBeforeEndingToday,
      eventsStartedTodayEndingLater,
    } = splitEventsForTheListView(events, dt, timeZone);

    const sortedEvent: IListEvent[] = [
      ...eventsStartedBeforeEndingToday,
      ...eventsBelongingToToday,
      ...eventsStartedTodayEndingLater,
    ]
      .filter(e => {
        return isScheduleDetailsView
          ? filterEventPerTheRotationType(
              e,
              activeRotViewType.value,
              allParticipants,
              currentUserId,
            )
          : true;
      })
      .sort(
        (a, b) => new Date(a?.startTime || '').getTime() - new Date(b?.startTime || '').getTime(),
      );

    const allScheduleIDs = Array.from(new Set(sortedEvent.map(event => event.scheduleID)));

    const groupedEvents: Array<{ eventInfo: IListEventInfo }> = [];

    allScheduleIDs.forEach(id => {
      groupedEvents.push({
        eventInfo: {
          [clickedDate.toDateString()]: sortedEvent.filter(event => event.scheduleID === id),
        },
      });
    });

    setDateDrawerContent(
      <ScheduleDetails
        events={groupedEvents}
        allParticipants={allParticipants}
        isScheduleDetailsView={isScheduleDetailsView}
        timeZone={timeZone}
      />,
    );
    setDateDrawerHeader(getFullDate(clickedDate));
  };

const columnHeaderFormat =
  (viewType: CalendarViewType | undefined, timeZone: string) =>
  ({ date }: DayHeaderContentArg) => {
    const { weekdayShort, day } =
      viewType === CalendarViewType.dayGridMonth
        ? DateTime.fromJSDate(date).setZone(timeZone).toUTC()
        : DateTime.fromJSDate(date).setZone(timeZone);

    return (
      <VStack spacing={0}>
        <Text>{weekdayShort.toUpperCase()}</Text>
        {viewType !== CalendarViewType.dayGridMonth && <Text>{day}</Text>}
      </VStack>
    );
  };

type Props = {
  viewType?: CalendarViewType;
  timeZone: string;
  events: IScheduleEvents;
  height?: string;
  disableScheduleDrawer?: boolean;
  disableEventPopover?: boolean;
  disableEventModifiers?: boolean;
  schedules?: GetSchedulesQuery['schedules'];
  onSyncSelectedDate?: () => void;
  isScheduleDetailsView?: boolean;
};

const SchedulesCalendar = React.forwardRef<FullCalendar | null, Props>(
  (
    {
      viewType = CalendarViewType.timeGridWeek,
      timeZone,
      events,
      height,
      disableScheduleDrawer,
      disableEventPopover,
      disableEventModifiers,
      schedules,
      onSyncSelectedDate,
      isScheduleDetailsView,
    },
    calendarRef,
  ) => {
    const calendarWrapper = useRef<HTMLDivElement | null>(null);
    const fullCalendarRef = useRef<FullCalendar | null>(null);
    const {
      organization: { currentUser },
    } = useOrganizationConfig();

    const tzAbbr = `${getTZFormat(timeZone, 'ZZZZ')} (${getTZFormat(timeZone, 'ZZ')})`;

    const [loading, setLoading] = useState(true);
    const [dateDrawerContent, setDateDrawerContent] = useState<any>(null);
    const [dateDrawerHeader, setDateDrawerHeader] = useState<any>(null);
    const allParticipants = useGetAllPatternParticipants();
    const { activeRotViewType } = useScheduleHeaderContext();

    useImperativeHandle(
      calendarRef,
      () => {
        return fullCalendarRef.current;
      },
      [],
    );

    const EventDetailWithPopover = useCallback(
      ({ ...args }: EventContentArg) => {
        const eventInfo = args.event.extendedProps as IScheduleEvents[number];
        let overlappedByOverride = false;
        const correspondingSchedule = schedules?.find(sch => sch.ID === eventInfo.scheduleId);
        if (correspondingSchedule) {
          const startDT = getUTCDateTime(
            (args.event.start ? new Date(args.event.start) : new Date()).toISOString(),
            correspondingSchedule?.timeZone as string,
          );
          const endDT = getUTCDateTime(
            (args.event.end ? new Date(args.event.end) : new Date()).toISOString(),
            correspondingSchedule?.timeZone as string,
          );
          const overridesFromCorrespondingSchedule = correspondingSchedule?.overrides ?? [];
          overlappedByOverride = !eventInfo.isOverride
            ? !!overridesFromCorrespondingSchedule.find(
                o =>
                  getUTCDateTime(o.startTime, correspondingSchedule?.timeZone as string) <=
                    startDT &&
                  endDT <= getUTCDateTime(o.endTime, correspondingSchedule?.timeZone as string),
              )
            : false;
        }
        return (
          <EventDetail
            {...args}
            disableEventPopover={disableEventPopover}
            disableEventModifiers={disableEventModifiers}
            allParticipants={allParticipants}
            activeTimezone={timeZone}
            overlappedByOverride={overlappedByOverride}
          />
        );
      },
      [disableEventPopover, disableEventModifiers, allParticipants],
    );

    useEffect(() => {
      const calendarAPI = fullCalendarRef.current?.getApi();
      if (calendarAPI) {
        calendarAPI.changeView(viewType);
      }
    }, [viewType]);

    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
        onSyncSelectedDate?.();
      }, 400);

      return () => setLoading(true);
    }, []);

    return (
      <Box sx={calendarStyles(tzAbbr, height, loading)} ref={calendarWrapper}>
        {loading && <Loader.Spinner isLoading centered spinnerProps={{ mt: 20 }} />}
        <FullCalendar
          ref={node => {
            fullCalendarRef.current = node;
          }}
          timeZone={timeZone}
          selectable={true}
          nowIndicator={true}
          dayMaxEventRows={3}
          titleFormat={{ month: 'long', year: 'numeric' }}
          plugins={[
            dayGridPlugin,
            timeViewPlugin,
            listViewPlugin,
            interactionPlugin,
            luxon2Plugin,
            rrulePlugin,
          ]}
          initialView={'timeGridWeek'}
          eventDisplay="block"
          height={calendarWrapper.current?.clientHeight ?? 'auto'}
          expandRows
          firstDay={1}
          allDaySlot={false}
          showNonCurrentDates={false}
          headerToolbar={false}
          displayEventTime
          displayEventEnd
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            hour12: false,
          }}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            hour12: false,
          }}
          rerenderDelay={2000}
          dayHeaderContent={columnHeaderFormat(viewType, timeZone)}
          eventContent={EventDetailWithPopover}
          dateClick={dateClick(
            setDateDrawerContent,
            setDateDrawerHeader,
            schedules ? schedules : [],
            allParticipants,
            isScheduleDetailsView ?? false,
            activeRotViewType,
            currentUser.u?.id,
            timeZone,
          )}
          events={events}
        />

        {!disableScheduleDrawer && dateDrawerContent && (
          <CustomDrawerComponent
            isOpen={true}
            onClose={() => setDateDrawerContent(null)}
            title={
              <HStack spacing={5}>
                <ArrowBackIcon
                  onClick={() => setDateDrawerContent(null)}
                  _hover={{ cursor: 'pointer' }}
                />
                <Heading variant="h6">{dateDrawerHeader}</Heading>
              </HStack>
            }
            disableBodyPadding
            size="lg"
          >
            <>
              <Divider w="100%" />
              {dateDrawerContent}
            </>
          </CustomDrawerComponent>
        )}
      </Box>
    );
  },
);

export default SchedulesCalendar;
