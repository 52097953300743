import React, { TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';
import Grid from '../Grid';
import { CautionIcon } from '../../utils/icons';
interface IProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  height?: string | '150px';
  width?: string | '100%' | 'fit-content';
  fontSize?: string | '16px';
  padding?: string | '16px';
  error?: boolean;
}

const calcShadows = (props: any) =>
  props.error
    ? `0 0 0 1px ${props.theme.danger.default}`
    : `0 0 0 1px ${props.theme.primary.default}`;

const TextBlockHolder = styled.textarea<IProps>`
  font-family: ${props => props.theme.fontFamily};
  height: ${props => props.height || '150px'};
  width: ${props => props.width || '100%'};
  font-size: ${props => props.fontSize || '16px'};
  padding: ${props => props.padding || '16px'};
  border: none;
  box-shadow: 0 0 0 1px
    ${props => (props.error ? props.theme.danger.default : props.theme.shades.lightGrey)};
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #ffffff;
  outline: none;
  transition: 0.17s greyscale, 0.17s box-shadow, 0.17s filter, 0.17s background ease;

  &:hover:not(:disabled),
  &:focus {
    box-shadow: ${calcShadows};
  }

  &:disabled {
    cursor: no-drop !important;
    opacity: 0.33;
    color: ${props => props.theme.font.disabled};
    background-color: ${props => props.theme.shades.whiteSmoke};
    box-shadow: 0 0 0 1px ${props => props.theme.shades.smoke};
  }

  &::placeholder {
    color: ${props => props.theme.font.label};
  }
`;

const TextBlock = (props: IProps) => (
  <Grid width={props.width || '100%'} alignItems="flex-start">
    <TextBlockHolder {...props} />
    {props.error && (
      <CautionIcon style={{ marginLeft: -34, marginTop: 16, zIndex: 1 }} height={16} width={16} />
    )}
  </Grid>
);

/** @component */
export default TextBlock;
