import { useEffect } from 'react';
import { useWorkflowFormContext } from 'views/main/organization/workflow/context/workflowFormContext';
import { useFormContext } from 'react-hook-form';
import { MSTeamMessageUserAction } from 'views/main/organization/workflow/types';
import { FormFooter } from '../../squadcast/form-footer';
import { messageTeamUserValidation } from 'views/main/organization/workflow/constant/validation';
import MessageTeamUserForm from './messageTeamUserForm';
import { FormWrapper } from 'library/molecules/Form/FormWrapper/FormWrapper';
import DrawerFooterWrapper from 'library/molecules/Drawer';

const MessageTeamsUserContainer = () => {
  const { actionForm, onActionSubmit, checkDirtyAndCloseDrawer, setIsDirty } =
    useWorkflowFormContext();
  const { formState } = useFormContext<MSTeamMessageUserAction>();

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <FormWrapper<MSTeamMessageUserAction>
      onSubmit={onActionSubmit}
      defaultValues={actionForm! as MSTeamMessageUserAction}
      validationSchema={messageTeamUserValidation}
    >
      <MessageTeamUserForm />
      <DrawerFooterWrapper>
        <FormFooter<MSTeamMessageUserAction> checkDirtyAndCloseDrawer={checkDirtyAndCloseDrawer} />
      </DrawerFooterWrapper>
    </FormWrapper>
  );
};
export default MessageTeamsUserContainer;
