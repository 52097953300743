import { Text } from '@chakra-ui/react';
import { FormButton } from 'library/atoms';
import { Modal } from 'library/molecules';
import { FC } from 'react';

interface Props {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  onDiscard: () => void;
}

export const UnsavedChangesModal: FC<Props> = ({
  title = 'You haven’t saved any changes. Are you sure you want to discard the changes?',
  isOpen,
  onClose,
  onDiscard,
}) => {
  return (
    <Modal
      key="route-guard"
      closeOnOverlayClick={true}
      body={<Text variant="h4">{title}</Text>}
      isOpen={isOpen}
      onClose={onClose}
      title="Unsaved Changes"
      primaryButton={<FormButton title="Keep Editing" onClick={onClose} variant="primary" />}
      secondaryButton={
        <FormButton title="Discard Changes" onClick={onDiscard} variant="secondary" />
      }
    />
  );
};
