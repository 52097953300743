import {
  CardBlock,
  ContainerLoad,
  DialogModalFrame,
  Grid,
  Heading,
  IconButton,
  Para,
  TextButton,
  Theme,
} from 'uie/components';
import Tippy from '@tippy.js/react';
import UnsavedChangesGuard from 'components/unsavedChangesGuard';
import UpgradeOnlyModal from 'components/upgradeonly.tooltip';
import UpgradePlanModal from 'components/upgradeplan.modal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConfirmModal from '../../../../../components/confirm.modal';
import { T_WA_GS_POSTMORTEM_TEMPLATE_DELETED } from '../../../../../core/const/tracker';
import { exception } from '../../../../../core/exception';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IComponentNetworkState } from '../../../../../core/interfaces/IComponentState';
import { IOrganization } from '../../../../../core/interfaces/IOrganization';
import { IPostmortemTemplate } from '../../../../../core/interfaces/IPostmortems';
import {
  BillingService,
  IncidentService,
  PostmortemTemplateService,
} from '../../../../../core/services';
import { DeleteIcon, EditIcon } from '../../../../../icons';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import PostmortemTemplateModal from './postmortemTemplate.modal';

interface IProps extends Pick<IAppState, 'organization'> {
  selectedOrg: IOrganization;
}

interface IState {
  postmortemTemplates: IPostmortemTemplate[];
  selectedPostmortemTemplate: IPostmortemTemplate | null;
  componentNetworkState: IComponentNetworkState | 'request-postmortem-templates';
  showTemplateModal: boolean;
  showDeletePostmortemTemplateConfirm: boolean;
  showUpgradeModal: boolean;
  planLimitExceeded: boolean;
  showUpgradeToolTip: boolean;
  isModalDirty: boolean;
  showUnsavedChangesModal: boolean;
}

class OrganizationPostmortemTab extends Component<IProps, IState> {
  private _postmortemService = new PostmortemTemplateService(this.props.selectedOrg.organizationId);
  private _incidentService = new IncidentService();

  constructor(props: IProps) {
    super(props);

    this.state = {
      postmortemTemplates: [],
      selectedPostmortemTemplate: null,
      componentNetworkState: 'idle',
      showDeletePostmortemTemplateConfirm: false,
      showTemplateModal: false,
      showUpgradeModal: false,
      showUpgradeToolTip: false,
      planLimitExceeded: false,
      isModalDirty: false,
      showUnsavedChangesModal: false,
    };
  }

  async componentDidMount() {
    await this.getPostmortemTemplates();
    if (this.state.planLimitExceeded) {
      this.setState({ showUpgradeModal: true });
    }
  }

  updatePlanLimitExceeded = () => {
    this.setState({
      planLimitExceeded: BillingService.isLimitExceeded(
        this.props,
        'postmortem-templates',
        () => this.state.postmortemTemplates.length,
      ),
    });
  };

  getPostmortemTemplates = async () => {
    this.setState({ componentNetworkState: 'request-postmortem-templates' });
    try {
      const response = await this._postmortemService.get();
      const postmortemTemplates: IPostmortemTemplate[] = response.data.data;
      await this.setState({ postmortemTemplates });
      this.updatePlanLimitExceeded();
    } catch (err: any) {
      exception.handle('E_GET_POSTMORTEM_TEMPLATES', err);
      this.setState({ componentNetworkState: 'error' });
    } finally {
      this.setState({ componentNetworkState: 'idle' });
    }
  };

  public deletePostmortemTemplate = async () => {
    AppTracker.track(T_WA_GS_POSTMORTEM_TEMPLATE_DELETED);
    try {
      const postmortemTemplateID = this.state.selectedPostmortemTemplate!.id;
      await this._postmortemService.delete(postmortemTemplateID as string);
      await this.getPostmortemTemplates();
    } catch (err: any) {
      exception.handle('E_DELETE_POSTMORTEM_TEMPLATES', err);
    }
  };

  onSelectPostmortem = (type: 'delete' | 'edit', postmortem: IPostmortemTemplate) => () => {
    this.setState({
      showDeletePostmortemTemplateConfirm: type === 'delete',
      showTemplateModal: type === 'edit',
      selectedPostmortemTemplate: postmortem,
    });
  };

  closeModal = async () => {
    this.setState({
      showDeletePostmortemTemplateConfirm: false,
      showTemplateModal: false,
    });
    this.getPostmortemTemplates();
  };

  onConfirmDeleteTemplate = async (confirm: boolean) => {
    if (confirm) {
      await this.deletePostmortemTemplate();
    }
    this.hideOpenModals();
  };

  checkLimitAndShowTemplateModal = () => {
    if (this.state.planLimitExceeded) {
      this.setState({ showUpgradeModal: true });
    } else {
      this.setState(
        {
          selectedPostmortemTemplate: null,
          showTemplateModal: false,
          showDeletePostmortemTemplateConfirm: false,
        },
        () => {
          this.setState({ showTemplateModal: true });
        },
      );
    }
  };

  public hideOpenModals = () => {
    this.setState({
      showDeletePostmortemTemplateConfirm: false,
      showTemplateModal: false,
      isModalDirty: false,
      showUnsavedChangesModal: false,
    });
    this.getPostmortemTemplates();
  };

  closeUpgradeModal = () => {
    this.setState({ showUpgradeModal: false });
  };
  closeUnsavedChangesModal = () => this.setState({ showUnsavedChangesModal: false });

  onDiscardChanges = () => {
    this.setState({
      isModalDirty: false,
      showUnsavedChangesModal: false,
    });
    this.hideOpenModals();
  };

  //

  checkAndSetDirty = () => {
    if (!this.state.isModalDirty) this.setState({ isModalDirty: true });
  };

  //

  checkDirtyAndCloseModal = () => {
    if (this.state.isModalDirty) {
      this.setState({ showUnsavedChangesModal: true });
    } else {
      this.hideOpenModals();
    }
  };

  render() {
    const { theme } = Theme;
    const { componentNetworkState, postmortemTemplates, selectedPostmortemTemplate } = this.state;
    const limit = BillingService.getLimit(this.props, 'postmortem-templates');
    const featureDisabled = BillingService.isFeatureDisabled(this.props, 'postmortem-templates');

    return (
      <>
        <ContainerLoad isLoading={componentNetworkState === 'request-postmortem-templates'} />
        <Grid
          type="column"
          flexWidth={12}
          className="org_settings__container_padding global--soft-scroll"
        >
          <Grid justifyContent="space-between" alignItems="baseline">
            <Grid type="column">
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <Heading fontSize={20} height={32} fontWeight={500} color={theme.shades.black}>
                  Postmortem Templates
                </Heading>
                {postmortemTemplates.length > 0 && (
                  <Para fontSize={20} color={theme.shades.black} style={{ marginLeft: 10 }}>
                    ({postmortemTemplates.length})
                  </Para>
                )}
              </div>
              <Para
                fontSize={14}
                style={{
                  fontSize: '12px',
                  color: '#808080',
                  fontWeight: 300,
                }}
              >
                You can set one from the below pre-defined postmortem templates as default or choose
                to create/modify the existing ones.
              </Para>
            </Grid>
            <Tippy
              content={
                this.state.planLimitExceeded ? (
                  <UpgradeOnlyModal
                    hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
                    align="left"
                    showModal={true}
                    message={BillingService.getMessage(limit, 'postmortem-templates', this.props)}
                    header={BillingService.getHeader(limit, 'postmortem-templates', this.props)}
                  />
                ) : (
                  ''
                )
              }
              interactive={true}
              arrow={true}
              animation={'scale'}
            >
              <TextButton
                buttonType="inverted"
                style={this.state.planLimitExceeded ? { opacity: 0.5 } : { opacity: 1 }}
                onClick={this.checkLimitAndShowTemplateModal}
              >
                <Para color={theme.primary.default}>Add New Template</Para>
              </TextButton>
            </Tippy>
          </Grid>
          <div className="mt-20">
            {componentNetworkState === 'idle' && postmortemTemplates.length === 0 && (
              <Para
                fontSize={14}
                fontWeight={400}
                className="mb-20"
                color={theme.danger.default}
                style={{
                  textAlign: 'left',
                  padding: '0 0 0 8px',
                  backgroundColor: theme.danger.light,
                  width: '100%',
                  position: 'static',
                  height: 40,
                  lineHeight: '40px',
                }}
              >
                This organization doesn't have any postmortem templates.
              </Para>
            )}
            {postmortemTemplates.length !== 0 && (
              <Grid
                justifyContent="flex-start"
                alignItems="stretch"
                flexWrap="wrap"
                style={{ marginLeft: -10 }}
              >
                {postmortemTemplates.map(
                  (postmortemTemplate: IPostmortemTemplate, ptIndex: number) => {
                    return (
                      <Grid
                        key={ptIndex}
                        flexWidth={12}
                        style={{
                          paddingTop: 0,
                          marginBottom: '5px',
                          lineHeight: 'normal',
                        }}
                      >
                        <CardBlock padding="8px" borderStyle="sharp" style={{ width: '100%' }}>
                          <Grid flexWidth={12} alignItems="center" justifyContent="space-between">
                            <Para>{postmortemTemplate.name} </Para>

                            <Grid alignItems="center">
                              {postmortemTemplate.is_default ? <Para>Default template</Para> : ''}
                              <IconButton
                                name="edit"
                                base="24px"
                                className="ml-10"
                                onClick={this.onSelectPostmortem('edit', postmortemTemplate)}
                              >
                                <EditIcon width="16px" />
                              </IconButton>
                              {postmortemTemplates.length !== 1 && (
                                <IconButton
                                  base="24px"
                                  onClick={this.onSelectPostmortem('delete', postmortemTemplate)}
                                  className="ml-10"
                                >
                                  <DeleteIcon stroke={theme.danger.default} />
                                </IconButton>
                              )}
                            </Grid>
                          </Grid>
                        </CardBlock>
                      </Grid>
                    );
                  },
                )}
              </Grid>
            )}
          </div>
        </Grid>

        <DialogModalFrame id="template_modal" width="1144px" onClose={this.checkDirtyAndCloseModal}>
          {this.state.showTemplateModal && (
            <PostmortemTemplateModal
              hide={this.hideOpenModals}
              postmortemTemplate={selectedPostmortemTemplate}
              basePostmortemTemplates={postmortemTemplates}
              organization={this.props.selectedOrg as IOrganization}
              checkAndSetDirty={this.checkAndSetDirty}
            />
          )}
        </DialogModalFrame>

        <DialogModalFrame id="delete_postmortem_template" width="400px" onClose={this.closeModal}>
          {this.state.showDeletePostmortemTemplateConfirm && (
            <ConfirmModal
              hide={this.onConfirmDeleteTemplate}
              buttonText={'Confirm'}
              displayText={`Are you sure you want to delete this template: "${
                selectedPostmortemTemplate!.name
              }"?`}
            />
          )}
        </DialogModalFrame>
        {featureDisabled ? (
          <UpgradePlanModal
            hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
            key="upgrade-on-start"
            showModal={this.state.showUpgradeModal}
            onUpgrade={this.closeUpgradeModal}
            message={BillingService.getMessage(limit, 'postmortem-templates', this.props)}
            header={BillingService.getHeader(limit, 'postmortem-templates', this.props)}
            onCancel={() => {
              this.closeUpgradeModal();
              (this.props as any).history.goBack();
            }}
          />
        ) : (
          ''
        )}
        <UnsavedChangesGuard
          isManual={true}
          showModal={this.state.showUnsavedChangesModal}
          onModalClose={this.closeUnsavedChangesModal}
          onDiscardChanges={this.onDiscardChanges}
        />
      </>
    );
  }
}

export default connect(({ organization, userOrganizations, INIT_ORG }: IAppState) => ({
  selectedOrg: userOrganizations.o.find(o => o.organizationId === INIT_ORG.orgId) as IOrganization,
  organization,
}))(OrganizationPostmortemTab);
