import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipRule="evenodd" fillRule="evenodd">
      <path
        d="m1 10.151 1.78 1.78 4.297 4.297v-3.559l-2.518-2.518L10.15 4.56l2.191 2.19V1h-5.75l1.78 1.78-5.593 5.592z"
        fill="#929497"
      />
      <path
        d="m19 9.849-1.78-1.78-4.248-4.249-.012 3.547L15.44 9.85 9.85 15.44l-2.191-2.19V19h5.75l-1.78-1.78 5.593-5.592z"
        fill="#2e3191"
      />
    </g>
  </svg>
);

export default SvgComponent;
