import React, { useEffect, useState } from 'react';
import { DialogModalFrame, Grid, Para, TextButton, Theme } from 'uie/components';
import { Location } from 'history';
import { Prompt, useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import { IPricingEntity } from 'core/interfaces/IBillingPlan';
import { AppTracker } from '../../shared/analytics/tracker';
import { T_WA_UP_CONTINUE_WITH_FREE_PLAN } from '../../core/const/tracker';
import { T_WA_UP_UPGRADE } from '../../core/const/tracker';
const { theme } = Theme;

interface IProps {
  showModal?: boolean;
  showFreePlan: boolean;
  hasBillingPermission: boolean;
  onCancel: () => void;
  onFree: () => void;
  onUpgrade?: () => void;
}

const TrailExpiredModal = ({
  showModal,
  showFreePlan,
  onUpgrade,
  onFree,
  hasBillingPermission,
  onCancel: onCancelUpgrade,
}: IProps) => {
  const closeModal = () => {
    showModal = false;
  };
  const history = useHistory();
  const onUpgradePlanAction = async () => {
    onUpgrade ? await onUpgrade() : '';
    history.push('/settings/billings');
    AppTracker.track(T_WA_UP_UPGRADE);
  };
  const OnFreePlanButtonClicked = () => {
    AppTracker.track(T_WA_UP_CONTINUE_WITH_FREE_PLAN);
  };

  return (
    <>
      <DialogModalFrame
        root="unsaved-changes-confirm"
        id="modalFrame"
        onClose={() => {
          showModal = false;
          onCancelUpgrade ? onCancelUpgrade() : closeModal();
        }}
        padding="24px"
        width="404px"
        height="290px"
        hideClose={true}
      >
        {showModal && (
          <>
            <Para
              fontSize={24}
              fontWeight={500}
              className="mb-10 mt-10"
              style={{
                fontStyle: 'normal',
                fontFamily: 'Mulish',
                lineHeight: '40px',
                color: '#2D3964',
              }}
            >
              {'Trial Period Expired'}
            </Para>
            {hasBillingPermission ? (
              <>
                <Para
                  fontSize={14}
                  fontWeight={400}
                  style={{
                    fontFamily: 'Mulish',
                    fontStyle: 'normal',
                    lineHeight: '20px',
                    color: '#2D3964',
                  }}
                >
                  Your trial period has come to an end. You can upgrade to one of our{' '}
                  <a
                    href="https://app.squadcast.com/settings/billings"
                    style={{
                      color: '#0F61DD',
                      fontFamily: 'Mulish',
                      fontWeight: 600,
                      fontStyle: 'normal',
                      lineHeight: '16px',
                      fontSize: '14px',
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    paid plans
                  </a>{' '}
                  to continue using our full set of features
                  <br />
                  <br />
                </Para>
                <Para
                  fontSize={12}
                  fontWeight={400}
                  style={{
                    fontFamily: 'Mulish',
                    fontStyle: 'italic',
                    lineHeight: '16px',
                    color: '#2D3964',
                  }}
                >
                  Check what you will miss{' '}
                  <a
                    href="https://www.squadcast.com/pricing"
                    style={{
                      color: '#0F61DD',
                      fontFamily: 'Mulish',
                      fontWeight: 600,
                      fontStyle: 'normal',
                      lineHeight: '16px',
                      fontSize: '12px',
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                </Para>
                <Para
                  fontSize={12}
                  fontWeight={400}
                  style={{
                    fontFamily: 'Mulish',
                    fontStyle: 'italic',
                    lineHeight: '16px',
                    color: '#2D3964',
                    marginTop: '72px',
                  }}
                >
                  Need to extend your trial? &nbsp;{' '}
                  <a
                    href="mailto:support@squadcast.com"
                    style={{
                      color: '#0F61DD',
                      fontFamily: 'Mulish',
                      fontWeight: 600,
                      fontStyle: 'normal',
                      lineHeight: '16px',
                      fontSize: '12px',
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact our support team
                  </a>
                </Para>
              </>
            ) : (
              <>
                <Para
                  fontSize={14}
                  fontWeight={400}
                  style={{
                    fontFamily: 'Mulish',
                    fontStyle: 'normal',
                    lineHeight: '20px',
                    color: '#2D3964',
                  }}
                >
                  Your trial period has come to an end. You don't seem to have permissions to
                  Upgrade or continue using our Free plan. <br /> <br />
                  Reach out to your Account Owner to continue using Squadcast.
                  <br />
                  <br />
                </Para>
                <Para
                  fontSize={12}
                  fontWeight={400}
                  style={{
                    fontFamily: 'Mulish',
                    fontStyle: 'italic',
                    lineHeight: '20px',
                    color: '#2D3964',
                  }}
                >
                  You'll see this until your account has an active plan <br /> <br />
                  Need our help?{'  '}
                  <a
                    href="mailto:support@squadcast.com"
                    style={{
                      color: '#0F61DD',
                      fontFamily: 'Mulish',
                      fontWeight: 600,
                      fontStyle: 'normal',
                      lineHeight: '16px',
                      fontSize: '12px',
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact our support team
                  </a>
                </Para>
              </>
            )}

            {hasBillingPermission && (
              <div style={{ marginTop: 40 }}>
                <Grid justifyContent="flex-end">
                  {showFreePlan && (
                    <TextButton
                      buttonType="ghost"
                      onClick={() => {
                        onFree();
                        OnFreePlanButtonClicked();
                      }}
                      className="mr-20"
                    >
                      <Para
                        style={{
                          fontFamily: 'Mulish',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '24px',
                          textAlign: 'center',
                          color: '#0F61DD',
                        }}
                      >
                        Continue Free Plan
                      </Para>
                    </TextButton>
                  )}

                  <TextButton style={{ padding: '4px 14px' }} onClick={onUpgradePlanAction}>
                    <Para
                      style={{
                        fontFamily: 'Mulish',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '24px',
                        textAlign: 'center',
                      }}
                      color={theme.font.white}
                      fontSize={16}
                    >
                      Upgrade
                    </Para>
                  </TextButton>
                </Grid>
              </div>
            )}
          </>
        )}
      </DialogModalFrame>
    </>
  );
};
export default TrailExpiredModal;
