import { ServiceNowSvc } from 'core';
import {
  initialConfiguration,
  isServiceNowIntegrated,
  sanitizeDomain,
  verifyInputData,
} from '../helpers';
import { useEffect, useRef, useState } from 'react';
import { createStandaloneToast } from '@chakra-ui/react';
import { IServiceNowResponse } from 'core/interfaces/IExtensions';
import equal from 'fast-deep-equal/es6/react';
import { ServiceNowConfigurationProps } from '../servicenow_configuration';

type ConnectionStatus = 'verified' | 'not_verified' | 'failed' | 'loading';

type Config = {
  id: number;
  servicenow_domain: string;
  servicenow_username: string;
  servicenow_password: string;
  sync_mode: string;
};

export function useServiceNowConfiguration(props: ServiceNowConfigurationProps) {
  const toast = createStandaloneToast();
  const [isServiceNowEnabled, setIsServiceNowEnabled] = useState<boolean>(true);
  const [isVerifyButtonEnabled, setIsVerifyButtonEnabled] = useState<boolean>(false);
  const [verifyConnectionStatus, setVerifyConnectionStatus] =
    useState<ConnectionStatus>('not_verified');
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [integrationKey, setIntegrationKey] = useState<string>('');
  const [config, setConfig] = useState<Config>(initialConfiguration);
  const originalConfig = useRef<Config>(initialConfiguration);
  const onToggle = () => setIsServiceNowEnabled(!isServiceNowEnabled);

  const _servicenowSvc = new ServiceNowSvc();
  const verifyConfigHandler = async (cb: () => void) => {
    try {
      setVerifyConnectionStatus('loading');
      const domain = sanitizeDomain(config.servicenow_domain);
      setIsVerifyButtonEnabled(false);
      await _servicenowSvc.verifyServiceNowConfig({
        servicenow_domain: domain,
        servicenow_username: config.servicenow_username,
        servicenow_password: config.servicenow_password,
      });
      setVerifyConnectionStatus('verified');
      if (verifyInputData(config)) {
        cb();
      }
      return true;
    } catch (error) {
      setVerifyConnectionStatus('failed');
      return false;
    }
  };

  const fetchIntegrationKey = async () => {
    try {
      const {
        data: {
          data: { integration_key },
        },
      } = await _servicenowSvc.getServiceNowIntegrationKey();
      setIntegrationKey(integration_key);
    } catch (error) {
      toast({
        status: 'error',
        title: 'Error fetching integration key',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchIntegrationKey();
  }, []);

  const getConfigCallback = (cfg: IServiceNowResponse) => {
    if (!cfg || cfg.id === 0) {
      return;
    }
    const sanitizedDomain = cfg.servicenow_domain.replace('https://', '');
    const updatedConfig = {
      id: cfg.id,
      servicenow_domain: sanitizedDomain,
      servicenow_password: cfg.servicenow_password ?? '',
      servicenow_username: cfg.servicenow_username,
      sync_mode: cfg.sync_mode,
    };
    setIsServiceNowEnabled(true);
    setConfig(updatedConfig);
    originalConfig.current = updatedConfig;
    props.setWasEnabled(cfg.is_enabled);
    if (!cfg.is_enabled) setIsServiceNowEnabled(false);
  };

  useEffect(() => {
    props.onSaveConfiguration({
      ...config,
      is_enabled: isServiceNowEnabled,
    });
    const hasValidInputData = verifyInputData(config) && isServiceNowEnabled;
    setIsVerifyButtonEnabled(hasValidInputData);

    props.setIsButtonEnabled(false);
    setVerifyConnectionStatus('not_verified');

    if (!isServiceNowEnabled && isServiceNowIntegrated(props.serviceNowStatus)) {
      props.setIsButtonEnabled(true);
    }

    const hasConfigChanged =
      !equal(config, originalConfig.current) && config.id === originalConfig.current.id;
    props.setHasChanged(hasConfigChanged);
  }, [config, isServiceNowEnabled]);

  return {
    toast,
    isServiceNowEnabled,
    isVerifyButtonEnabled,
    verifyConnectionStatus,
    passwordShown,
    setPasswordShown,
    integrationKey,
    config,
    setConfig,
    verifyConfigHandler,
    onToggle,
    getConfigCallback,
  };
}
