import { Flex, Tag, TagLabel, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React from 'react';
import { Cell } from 'react-table';
import NodePopover from 'views/main/organization/service-catalog/components/Popover';
import { truncateString } from '../../../helpers/helper.details';

import { IMaintenance, IStatusPageComponentList } from '../../../Interface';

export const tableColumns = [
  {
    Header: 'DATE',
    accessor: (row: IMaintenance) => row.startTime,
    Cell: (props: any) => {
      const {
        row: {
          original: { startTime },
        },
      } = props.cell;

      const { timezone } = props;

      const date = DateTime.fromISO(startTime, { zone: timezone });
      return (
        <Flex align={'center'}>
          <Text variant="tableData" fontSize={40} mr={2}>
            {date.toFormat('dd')}
          </Text>
          <Flex direction={'column'}>
            <Text variant="tableData" fontWeight={800}>
              {date.toFormat('MMM')} {date.toFormat('yyyy')}
            </Text>
            <Text variant="tableData" fontWeight={800}>
              {date.weekdayLong}
            </Text>
          </Flex>
        </Flex>
      );
    },
  },
  {
    Header: 'TIME',
    accessor: (row: IMaintenance) => row.startTime,
    Cell: (props: any) => {
      const {
        row: {
          original: { startTime, endTime },
        },
      } = props.cell;

      const { timezone } = props;

      const startDate = DateTime.fromISO(startTime, { zone: timezone });
      const endDate = DateTime.fromISO(endTime, { zone: timezone });
      return (
        <Text variant="tableData">
          {startDate.toLocaleString(DateTime.TIME_SIMPLE)} -{' '}
          {endDate.toLocaleString(DateTime.TIME_SIMPLE)}
        </Text>
      );
    },
  },
  {
    Header: 'DURATION',
    accessor: (row: IMaintenance) => row.startTime,
    Cell: (props: any) => {
      const {
        row: {
          original: { startTime, endTime },
        },
      } = props.cell;
      const { timezone } = props;

      const startDate = DateTime.fromISO(startTime, { zone: timezone });
      const endDate = DateTime.fromISO(endTime, { zone: timezone });
      const diff = endDate.diff(startDate, ['hours', 'minutes']).toObject();
      const { hours = 0, minutes = 0 } = diff;
      return (
        <Text variant="tableData">
          {hours > 0 ? `${hours} hr${hours > 1 ? 's' : ''}` : null}{' '}
          {minutes > 0 ? `${minutes} min${minutes > 1 ? 's' : ''}` : null}
        </Text>
      );
    },
  },

  {
    Header: 'TITLE',
    accessor: (row: IMaintenance) => row.title,
    Cell: (cell: Cell<IMaintenance>) => {
      const {
        row: {
          original: { title },
        },
      } = cell;
      const str = truncateString(title, 20);
      return <Text variant="tableData">{str}</Text>;
    },
  },
  {
    Header: 'COMPONENTS',
    accessor: (row: IMaintenance) => row.components,
    Cell: (cell: Cell<IMaintenance>) => {
      const {
        row: {
          original: { components },
        },
      } = cell;
      const remainingItems = components.slice(2);
      const componentsText = components
        .slice(0, 2)
        .map((component: IStatusPageComponentList) => component.name)
        .join(' | ');
      return (
        <Flex align="center">
          <Text mr={4} variant="tableData">
            {componentsText}
          </Text>
          {remainingItems.length > 0 ? (
            <NodePopover
              triggerType="hover"
              trigger={
                <Tag size="md">
                  <TagLabel>+ {remainingItems.length}</TagLabel>
                </Tag>
              }
            >
              {remainingItems.map((component: IStatusPageComponentList) => (
                <Text key={component.id} variant="tableData">
                  {component.name}
                </Text>
              ))}
            </NodePopover>
          ) : null}
        </Flex>
      );
    },
  },
  {
    Header: 'NOTES',
    accessor: (row: IMaintenance) => row.note,
    Cell: (cell: Cell<IMaintenance>) => {
      const {
        row: {
          original: { note },
        },
      } = cell;
      const str = truncateString(note, 20);
      return <Text variant="tableData">{str}</Text>;
    },
  },
];
