import Axios from 'axios';
import { API } from 'core';

export const getNonce = (
  userId: string,
  redirectUrl: string,
  orgSlug: string,
  pageSlug: string,
  onSuccess: (nonce: string) => void,
  onError: () => void,
) => {
  const url = `${API.config.sqerberos}/generate-status-page-nonce/${orgSlug}/${userId}`;
  const headers = pageSlug ? { pageSlug } : { customDomain: redirectUrl };
  Axios.get(url, { headers })
    .then(res => {
      const nonce = res.data.data.nonce;
      onSuccess(nonce);
    })
    .catch(onError);
};
