import { Box, Flex, Text } from '@chakra-ui/layout';

import { ActionListItem, SubActionTypes } from '../../../types';

type Props = {
  action: ActionListItem;
  onClick: (type: SubActionTypes) => void;
  isAdded: boolean;
};

const enabledStyles = {
  cursor: 'pointer',
  bgColor: 'brand.white',
  _hover: { backgroundColor: 'primary.100' },
};

const disabledStyles = {
  cursor: 'not-allowed',
  opacity: 0.5,
};

const ActionItem = ({ action, onClick, isAdded }: Props) => {
  const blockAction = !action.allowMultiple && isAdded;
  return (
    <Flex
      key={action.name}
      onClick={() => !blockAction && onClick(action.name)}
      px={6}
      py={4}
      borderBottomWidth={1}
      justifyContent="space-between"
      alignItems="center"
      sx={blockAction ? disabledStyles : enabledStyles}
    >
      <Box>
        <Text variant="h4_800">{action.title}</Text>
        <Text variant="form_input" fontSize="14px">
          {action.description}
        </Text>
      </Box>
      {blockAction ? (
        <Text color="brand.green" textStyle="bold_600">
          Added
        </Text>
      ) : null}
    </Flex>
  );
};

export default ActionItem;
