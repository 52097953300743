import {
  REQUEST_ORG_SERVICES,
  REQUEST_ORG_SERVICES_SUCCESS,
  REQUEST_ORG_SERVICES_FAIL,
} from '../../const/organization';
import { IService } from '../../interfaces/IService';

const requestOrganizationService = () => ({
  type: REQUEST_ORG_SERVICES,
  payload: {},
});

const onRequestOrganizationServiceSuccess = (services: IService[]) => ({
  type: REQUEST_ORG_SERVICES_SUCCESS,
  payload: {
    services,
  },
});

const onRequestOrganizationServiceFail = (error: any) => ({
  type: REQUEST_ORG_SERVICES_FAIL,
  payload: {
    error,
  },
});

export {
  requestOrganizationService,
  onRequestOrganizationServiceSuccess,
  onRequestOrganizationServiceFail,
};
