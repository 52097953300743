import React, { useState } from 'react';
import axios from 'axios';
import { API } from '../../../../../core/api';
import { useHistory } from 'react-router-dom';
import { createStandaloneToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import { ServiceData } from '../Interfaces/intigration';
import { reactQueryConfigError, reactQueryConfigSuccess } from '../helpers/helper.service';

interface AddAlertSourcedata {
  alert_sources: string[];
  teamId?: string;
  api_key?: string;
  serviceId?: string;
}

const toast = createStandaloneToast();
const addAlertSource = async (payload: AddAlertSourcedata) => {
  const { data } = await axios.put<{ data: ServiceData }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/catalog-services/${payload.serviceId}/alert-sources`,
    {
      alert_sources: payload.alert_sources,
    },
  );
  return data;
};
export function useAddAlertSource() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const navigateOnSuccess = () => {
    setTimeout(() => {
      history.push('/service-catalog');
    }, 1000);
  };
  const { mutate: addalertsource_, data: data } = useMutation(
    (payload: AddAlertSourcedata) => addAlertSource(payload),
    {
      onError: reactQueryConfigError,
      onSuccess: data => {
        reactQueryConfigSuccess('Alert Source(s) Added');

        data;
        navigateOnSuccess();
      },
      retry: 1,
    },
  );
  const addAlertSourceData = data;
  return { addalertsource_, addAlertSourceData };
}
