import { IServiceExpressionOperation, IServiceExpressionBranch } from '../interfaces/IService';
import { opFormats } from '../const/comparators';
import validator from 'validator';

const isEmailValid = (email: string): boolean => {
  const re =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  return re.test(String(email).toLowerCase());
};
const isDomainValid = (domain: string): boolean => {
  const re = /^(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  return re.test(String(domain).toLowerCase());
};
const stringIsEmpty = (value: string) => {
  return !!(typeof value === 'string' && value.length === 0);
};

const objectIsEmpty = (value: Record<string, any>) => {
  try {
    return value && Object.keys(value).length === 0 && value.constructor === Object;
  } catch (_) {
    return true;
  }
};

const hasProperty = (o: Record<string, any>, v: string) => {
  try {
    return !objectIsEmpty(o) && Object.prototype.hasOwnProperty.call(o, v);
  } catch (_) {
    return false;
  }
};

const isValidUrl = (url: string) => {
  return validator.isURL(url);
};

/**
 *
 * @param initial
 * @param search
 * @returns
 */
const searchJson = (initial: any, search: string): any => {
  try {
    return search.split('.').reduce(function r(c: any, n: string): any {
      if (n === '') {
        return {};
      }

      if (Array.isArray(c)) {
        return c.map(e => r(e, n));
      }

      if (typeof c === 'object' && Object.hasOwnProperty.call(c, n)) {
        return c[n];
      }

      return {};
    }, initial);
  } catch (_) {
    return {};
  }
};

/**
 * downloads a file using local URL Blobs. Calling function should handle errors
 * calling fns should handle errors
 */
const downloadFile = (file: File | Blob, fileName: string) => {
  const URL = window.URL || (window as any).webkitURL;
  const download = URL.createObjectURL(file);

  const a = document.createElement('a');
  a.target = '_blank';
  if (typeof a.download === 'undefined') {
    (window as any).location = download;
  } else {
    a.href = download;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
  }

  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(download);
  }, 100);
};

/**
 * @description make a deep copy of nested object
 * @param object the object to be deep copied
 */
const deepCopy = <T = Record<string, any> | Date>(objectpassed: T) => {
  if (objectpassed === null || typeof objectpassed !== 'object') {
    return objectpassed;
  }

  if (objectpassed instanceof Date) {
    return new Date(objectpassed);
  }

  /**
   * TODO: change it to  JSON parse and JSON stringify
   */
  const object = objectpassed as Record<string, any>;
  const tempStorage = object.constructor();
  for (const key of Object.keys(objectpassed)) {
    tempStorage[key] = deepCopy(object[key]);
  }
  return tempStorage;
};

/**

 * @deprecated will be removed in future versions
 * @description safely get a property in object
 * @param object object to query on
 * @param property property to query (use dot'.' to do nested queries)
 * @param fallback fallback if not found
 */
const getProperty = <T = Record<string, any>>(
  object: T | any,
  property: string | keyof T,
  fallback: any,
): T | any => {
  try {
    const isNested = (property as string).includes('.');

    if (isNested) {
      const blocks = (property as string).split('.');
      return blocks.reduce((c: any, n: string) => c[n], object) || fallback;
    }

    return object[property as keyof T] || fallback;
  } catch (_) {
    return fallback;
  }
};

/**
 * @description safely replace a property in object
 * @param object object to query on
 * @param property property to query (use dot'.' to do nested queries)
 * @param fallback fallback if not found
 */
const replaceProperty = <T = any>(
  object: any,
  property: string | keyof T,
  fallback: any,
): T | any => {
  try {
    let propertySplit = property.toString().split(/( |\+|-|\(|\)|\*|\/|:|\?)/g);
    propertySplit = propertySplit.map(ele => {
      let temp = ele;
      Object.entries(object).forEach(([key, value]) => {
        if (ele.includes(key) && typeof value === 'string') {
          temp = value;
        }
      });
      return temp;
    });
    return propertySplit.join('') || fallback;
  } catch (_) {
    return fallback;
  }
};

/**
 * @deprecated will be removed in future versions
 * @param object object to search on
 * @param searchOn search value
 * @param graceMessage if undefined returns grace message,
 */
const findGracefully = <T = any>(object: T[], searchOn: keyof T, graceMessage: string) => {
  /**
   * @param key key to match search on
   * @param find find a value on object
   */
  return (key: any, find: keyof T) => {
    const result = object.find(o => o[searchOn] === key);

    if (!result || !result[find]) {
      return graceMessage;
    }

    return result[find];
  };
};

/**
 * @description maps array to [key: Value] and returns () =>
 * @param array array of objects
 * @param mapKey index Key
 * @param fallback grace message if error happens
 * @returns function (find: string) => any
 */
const mapArrayToObjectIndex = <T = any>(
  array: T[],
  mapKey: keyof T,
  fallback: any,
): ((find: string) => T) => {
  const map = array.reduce((c: any, n) => {
    c[n[mapKey]] = n;
    return c;
  }, {});
  return (find: string) => map[find] || fallback;
};

/**
 * @description returns flattened json object
 * @param obj object which needs to be flattened
 */
const flattenedObject = (obj: Record<string, any>): Record<string, any> => {
  const result: any = {};
  const recurse = (cur: any, prop: any) => {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      const l = cur.length;
      for (let i = 0; i < l; i += 1) {
        recurse(cur[i], `${prop}[${i}]`);
      }
      if (l === 0) {
        result[prop] = [];
      }
    } else {
      let isEmpty = true;
      Object.keys(cur).forEach((p: any) => {
        isEmpty = false;
        recurse(cur[p], prop ? `${prop}["${p}"]` : p);
      });

      if (isEmpty) {
        result[prop] = {};
      }
    }
  };
  recurse(obj, '');
  return result;
};

const getLHSType = (lhs: string, event: Event): string => {
  return typeof flattenedObject(event)[lhs];
};

const generateExpression = (basicExpression: IServiceExpressionBranch[], event: any): string => {
  return basicExpression
    .map(be => {
      const fs: string = opFormats[be.op];
      if (fs === '') return '';

      const rhs =
        getLHSType(be.lhs, event) === 'string' && be.op !== IServiceExpressionOperation.EOpFieldIs
          ? `"${be.rhs}"`
          : be.rhs;
      return fs.replace('{lhs}', be.lhs).replace('{rhs}', rhs);
    })
    .join(' && ');
};

const focusCard = (el: HTMLElement) => {
  if (el) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });

    const oldBoxShadow = el.style.boxShadow;
    el.style.boxShadow = '0px 0px 0px 2px var(--primary-default)';

    setTimeout(() => {
      if (el) el.style.boxShadow = oldBoxShadow;
    }, 1200);
  }
};

function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  const ret: any = {};
  keys.forEach(key => {
    ret[key] = obj[key];
  });
  return ret;
}

const sleep = (secs: number) => new Promise(res => setTimeout(res, secs * 1000));

/**
 * honestly i don't understand how this works,
  if it does please explain to @madhu
 * btw i wrote this!
 * it sorts operations to [create, read, update ,delete]
 */
const sortOperationRole = ([op1]: any[], [op2]: any[]) => {
  if (op1.includes('create')) return 1;
  if (op1.includes('read') && !op2.includes('create')) {
    return -1;
  }
  if (op1.includes('update') && op2.includes('delete')) {
    return -2;
  }
  return 2;
};

function debounce<Params extends any[]>(func: (...args: Params) => any, timeout: number) {
  let timerId: ReturnType<typeof setTimeout> | null = null;

  return (...args: Params) => {
    if (timerId) clearTimeout(timerId);

    timerId = setTimeout(() => {
      func(...args);
      timerId = null;
    }, timeout);
  };
}

const checkIfActionChanged = <T, K extends keyof T>(
  prevOp: T,
  thisOp: T,
  key: K,
  compareNext: T[K],
) => {
  return prevOp[key] !== thisOp[key] && thisOp[key] === compareNext;
};

const checkIfActionHasNotChanged = <T, K extends keyof T>(
  prevOp: T,
  thisOp: T,
  key: K,
  compareNext: T[K],
) => {
  return prevOp[key] === compareNext && thisOp[key] === compareNext;
};

const checkIfKeyChanged = <T, K extends keyof T>(prevOp: T, thisOp: T, key: K) => {
  return prevOp[key] !== thisOp[key];
};

const checkIfKeyHasNotChanged = <T, K extends keyof T>(prevOp: T, thisOp: T, key: K) => {
  return prevOp[key] === thisOp[key];
};

/**
 * @returns {boolean} if all objects in array not empty
 */
const checkAllObjectsAreValid = (params: any[]) => {
  try {
    return params.every(p => {
      switch (typeof p) {
        case 'object':
          return Array.isArray(p) ? p.length > 0 : !objectIsEmpty(p);
        case 'boolean':
          return p;
        case 'string':
          return p !== '';
        case 'number':
          return p > 0;
        default:
          return false;
      }
    });
  } catch (_) {
    return false;
  }
};

const checkIfAllActionsAreSuccess = (params: string[]) =>
  params.every(p => p.toUpperCase().includes('SUCCESS'));

const checkIfNotValid = (
  params: [key: any, shouldMatch: any, error: { [key: string]: string }][],
) =>
  params.reduce((c, n) => {
    let newInvalidState = c;
    if (n[0] === n[1]) {
      newInvalidState = { ...c, ...n[2] };
    }
    return newInvalidState;
  }, {});

export {
  isEmailValid,
  isDomainValid,
  stringIsEmpty,
  objectIsEmpty,
  isValidUrl,
  searchJson,
  downloadFile,
  hasProperty,
  getProperty,
  replaceProperty,
  findGracefully,
  mapArrayToObjectIndex,
  deepCopy,
  flattenedObject,
  generateExpression,
  getLHSType,
  focusCard,
  pick,
  sleep,
  debounce,
  sortOperationRole,
  checkIfActionChanged,
  checkIfKeyChanged,
  checkAllObjectsAreValid,
  checkIfAllActionsAreSuccess,
  checkIfActionHasNotChanged,
  checkIfKeyHasNotChanged,
  checkIfNotValid,
};
export { eventHelpers } from './events';
export { getTimeFromObjectId } from './mongoObjectId';
