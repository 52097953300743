import { INITIAL_STATE } from '../state';
import {
  L_REQUEST_AB_ADD_TO_TEST,
  L_REQUEST_AB_REMOVE_FROM_TEST,
  L_REQUEST_AB_USER_ADD_TO_TEST,
  L_REQUEST_AB_USER_REMOVE_FROM_TEST,
  L_REQUEST_AB_CLEAR,
} from '../../const/abTest';
import { RESET_STATE } from '../../const/init';

const initialAppState = INITIAL_STATE.APP_AB_TEST;

const abTests = (state = initialAppState, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ tests: [] },
      };
    case L_REQUEST_AB_USER_ADD_TO_TEST:
      return {
        ...state,
        ...{ tests: Array.from(new Set([...state.tests, action.payload.test])) },
      };
    case L_REQUEST_AB_USER_REMOVE_FROM_TEST:
      const tests = state.tests.splice(state.tests.indexOf(action.payload.test), 1);
      return {
        ...state,
        ...tests,
      };
    case L_REQUEST_AB_ADD_TO_TEST:
      return {
        ...state,
        ...{ tests: Array.from(new Set([...state.tests, ...action.payload.test])) },
      };
    case L_REQUEST_AB_REMOVE_FROM_TEST:
      return {
        ...state,
        ...{
          tests:
            action.payload.test[0] === L_REQUEST_AB_CLEAR
              ? []
              : state.tests.reduce((c: any, n) => {
                  if (![...action.payload.test].includes(n)) c.push(n);
                  return c;
                }, []),
        },
      };
    default:
      return state;
  }
};

export default abTests;
