import { Box, Flex } from '@chakra-ui/layout';
import { useEffect, useMemo } from 'react';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { OperatorOptions } from '../../../constant';
import useServiceList, { ServiceOption } from './useServiceList';
import { OperationOption } from '../../../types';
import { onChangeFilter, onChangeOperator, selectedFilter, selectedOperator } from '../utility';
import { OperatorTypes } from '../../../types/enums';
import { useHistory } from 'react-router';
import FormField from 'library/molecules/FormField/FormField';
import FormDropdown from 'library/molecules/Form/FormDropdown/FormDropdown';
import { Input } from 'library/atoms';

interface Props {
  filterFieldName: string;
  filterName: string;
}

const ServiceFilter = <TFormType extends FieldValues>({ filterFieldName, filterName }: Props) => {
  const { watch, setValue } = useFormContext<TFormType>();
  const { data: services, isLoading } = useServiceList();
  const history = useHistory();

  const operatorValue = watch(`${filterFieldName}.type` as Path<TFormType>);

  const showInputField = ['service_contains', 'service_contains_not'].includes(operatorValue);
  const hasPhraseError = watch(`${filterFieldName}.fields.value` as Path<TFormType>) === '';
  const entityObjects = useMemo(
    () =>
      services?.map(service => ({
        value: service.id,
        label: service.name,
      })) ?? [],
    [services],
  );
  const activeOperator = selectedOperator(filterFieldName, watch, OperatorOptions);
  const activeService = selectedFilter(filterFieldName, watch, entityObjects);
  useEffect(() => {
    if (showInputField) {
      if (
        activeOperator &&
        (activeOperator.value === OperatorTypes.IS || activeOperator.value === OperatorTypes.IS_NOT)
      ) {
        setValue(
          `${filterFieldName}.fields.value` as Path<TFormType>,
          entityObjects[0]?.value as PathValue<TFormType, Path<TFormType>>,
        );
      }
    } else {
      // we need to check label because after user enters value from input field instead of dropdown, it will saved in value entity but will not have label entity. Eg :{label:"",value:"something written by user"}
      // BE accepts {value:""} and also service input can be both input fields and dropdown so both has same place to store value
      if (activeService && activeService.label !== '') {
        setValue(
          `${filterFieldName}.fields.value` as Path<TFormType>,
          activeService.value as PathValue<TFormType, Path<TFormType>>,
        );
      } else {
        setValue(
          `${filterFieldName}.fields.value` as Path<TFormType>,
          entityObjects[0]?.value as PathValue<TFormType, Path<TFormType>>,
        );
      }
    }
  }, [showInputField, operatorValue, entityObjects]);

  useEffect(() => {
    if (showInputField && !history.location.pathname.includes('edit'))
      setValue(
        `${filterFieldName}.fields.value` as Path<TFormType>,
        '' as PathValue<TFormType, Path<TFormType>>,
      );
  }, [showInputField]);

  const onChangeInput = (value: string) => {
    setValue(
      `${filterFieldName}.fields` as Path<TFormType>,
      {
        value: value,
      } as PathValue<TFormType, Path<TFormType>>,
    );
  };
  // set default operator option if we dont have any value
  useEffect(() => {
    if (!activeOperator && !activeService) {
      setValue(
        `${filterFieldName}.type` as Path<TFormType>,
        'service_is' as PathValue<TFormType, Path<TFormType>>,
      );
      setValue(
        `${filterFieldName}.fields.value` as Path<TFormType>,
        entityObjects[0]?.value as PathValue<TFormType, Path<TFormType>>,
      );
    }
  }, []);

  return (
    <Flex flex={1} pt={2} gap={4} flexDirection="row">
      <Box flex={1}>
        <FormDropdown<OperationOption>
          options={OperatorOptions}
          name={`${filterFieldName}.operator`}
          value={activeOperator}
          onChange={op => {
            onChangeOperator(
              op,
              filterFieldName,
              filterName,
              setValue as (name: Path<TFormType>, value: any) => void,
            );
          }}
        />
      </Box>
      <Flex flex={3} flexDirection="column">
        {!showInputField ? (
          <FormField>
            <FormDropdown<ServiceOption>
              options={entityObjects}
              name={`${filterFieldName}.entity`}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              isLoading={isLoading}
              value={activeService}
              onChange={o => {
                onChangeFilter(
                  o,
                  filterFieldName,
                  setValue as (name: Path<TFormType>, value: any) => void,
                );
              }}
            />
          </FormField>
        ) : (
          <FormField error={hasPhraseError ? 'Phrase is required' : ''}>
            <Input
              onChange={e => onChangeInput(e.target.value)}
              value={watch(`${filterFieldName}.fields.value` as Path<TFormType>)}
            />
          </FormField>
        )}
      </Flex>
    </Flex>
  );
};

export default ServiceFilter;
