import { Box, Button, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import React, { Fragment, memo, useContext, useEffect, useMemo, useState } from 'react';

import { StatusPageContext } from '../..';
import { CustomDrawerComponent } from '../../components/Drawer';
import HelpGuide from '../../components/HelpGuide';
import { statusComponentHelpGuide } from '../../constants/statuspage.copy';
import { useComponents } from '../../hooks/useComponents';
import { IComponentsForm, IComponentTreeItem, IServiceOption } from '../../Interface';
import { ComponentForm } from './addComponentsForm/addComponents';
import ComponentList from './listComponent';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_CREATION_PAGE_2_VIEWED } from 'core/const/tracker';
import { connect } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { useServiceList } from 'views/main/organization/service-catalog/hooks/useServiceList';
import useServiceListOptions from '../../hooks/useServiceListData';
import { THEME_COLORS } from 'library/theme/colors';

interface Props {
  onSubmit: (values: any) => void;
  init: IComponentTreeItem[];
  isAdvanceSettingHidden: boolean;
}
type IOrganization = Pick<IAppState, 'organization'>;

interface IExtendedComponentProps extends Props, IOrganization {}
const StatusPageAddComponent: React.FC<any> = (props: IExtendedComponentProps) => {
  const selectedTeam = props.organization?.selectedTeam.teamId;
  const isAdvanceSettingHidden = props.isAdvanceSettingHidden;
  const { data } = useServiceList(selectedTeam);

  const serviceListData = useServiceListOptions(data);

  const { statusPagePaths, navigateToPath, setPreviousStep } = useContext(StatusPageContext);
  const {
    state: {
      components,
      selectedGroupId,
      groupOptions,
      componentForm,
      isNewComponent,
      componentCount,
    },
    addGroup,
    saveGroup,
    editGroup,
    cancelGroupChange,
    deleteGroup,
    initAddComponentForm,
    saveComponent,
    initEditComponent,
    deleteComponent,
    onDragEnd,
    onSubmit,
    error,
    setShowComponentForm,
    showComponentForm,
    reInitiateState,
  } = useComponents(props.onSubmit);

  useEffect(() => {
    if (props.init.length > 0) {
      reInitiateState(props.init);
    }
  }, [props.init]);

  useEffect(() => {
    AppTracker.track(T_WA_UP_STATUS_PAGE_V2_CREATION_PAGE_2_VIEWED);
  }, []);

  const showAddComponent = () => {
    initAddComponentForm();
    setShowComponentForm(true);
  };

  const showEditComponent = (compId: number, groupId?: number) => {
    initEditComponent(compId, groupId);
    setShowComponentForm(true);
  };

  return (
    <Fragment>
      <div style={{ backgroundColor: 'white', paddingTop: '24px', height: '100%' }}>
        <Text color={THEME_COLORS.secondary[900]} fontSize="24px">
          Add Components
        </Text>
        <Text color={THEME_COLORS.secondary[900]} fontSize="14px" mb={5}>
          Add Components that represent functionalities of your product/ service that make sense to
          your customers
        </Text>

        <Box w={'100%'}>
          <ComponentList
            selectedTeam={selectedTeam}
            showAddComponent={showAddComponent}
            showEditComponent={showEditComponent}
            addGroup={addGroup}
            saveGroup={saveGroup}
            editGroup={editGroup}
            cancelGroupChange={cancelGroupChange}
            deleteGroup={deleteGroup}
            deleteComponent={deleteComponent}
            components={components}
            selectedGroupId={selectedGroupId}
            onDragEnd={onDragEnd}
            groupOptions={groupOptions}
            componentCount={componentCount}
            canEdit
          />
          {error.length > 0 ? (
            <Text color={'red.500'} fontSize="14" ml={2}>
              {error}
            </Text>
          ) : null}
          <Box w="100%" bottom={0} mx={-6}>
            <Divider />
            <HStack px={6} py={4} gap={4} sx={{ position: 'static', bottom: 0 }}>
              <Button variant="default" onClick={() => onSubmit()}>
                Next : Customize your page
              </Button>
              <Button
                variant="invertedDefault"
                onClick={() => {
                  navigateToPath(statusPagePaths.STATUSPAGE_V2_PATH);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="invertedDefault"
                onClick={event => {
                  setPreviousStep();
                }}
                type="button"
                style={{ marginLeft: 'auto' }}
              >
                Previous
              </Button>
            </HStack>
          </Box>
        </Box>
      </div>
      <VStack spacing={4} align="stretch" h="100%" backgroundColor={'white'}>
        <HelpGuide helpGuide={statusComponentHelpGuide} />
      </VStack>
      <CustomDrawerComponent
        isOpen={showComponentForm}
        onClose={() => {
          setShowComponentForm(false);
        }}
        size="md"
        title={isNewComponent ? 'Add Component' : 'Edit Component'}
        isFooterLeftAllign
      >
        <ComponentForm
          componentData={components}
          isAdvanceSettingHidden={isAdvanceSettingHidden}
          onCancel={() => setShowComponentForm(false)}
          groupOptions={groupOptions}
          serviceOptions={serviceListData ?? []}
          init={componentForm}
          onSave={(values: IComponentsForm) => {
            saveComponent(values);
            setShowComponentForm(false);
          }}
        />
      </CustomDrawerComponent>
    </Fragment>
  );
};

export default connect(({ organization, APP_CONFIG }: IAppState) => ({
  organization,
  APP_CONFIG,
}))(memo(StatusPageAddComponent));
