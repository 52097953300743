import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import {
  REQUEST_INCIDENT_DETAIL_TASK,
  REQUEST_INCIDENT_DETAIL_TASK_FAIL,
} from '../../const/organization';
import { getIncidentDetailTask } from 'core/api/organization/getIncidentDetailRunbookOrTask';
import { requestIncidentDeatilTaskSuccess } from 'core/actions/organization/incidentDetailTask';
import { ITasks } from 'core/interfaces/ITask';

const getIncidentDetailTaskEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_INCIDENT_DETAIL_TASK),
    map(action => action.payload),
    switchMap(({ incidentId }: { incidentId: string }) => {
      return getIncidentDetailTask(incidentId).pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ result, data }: { result: boolean; data: ITasks }) => {
          return requestIncidentDeatilTaskSuccess(data);
        }),
        catchError((error: AjaxError) => {
          if (error.status === 403) {
            return of(requestIncidentDeatilTaskSuccess(undefined));
          }

          return of({
            type: REQUEST_INCIDENT_DETAIL_TASK_FAIL,
            payload: error.xhr,
            error: true,
          });
        }),
      );
    }),
  );

export default getIncidentDetailTaskEpic;
