import React from 'react';
import {
  IAnalyticsData,
  IAnalyticsPanel,
  IAnalyticsPanelRow,
} from '../../../../core/interfaces/IAnalytics';
import RGL, { WidthProvider } from 'react-grid-layout';

import Panel from './panel';
import PanelRow from './components/panelRow';

const ReactGridLayout = WidthProvider(RGL);

const lastGridWidth = 1200;

interface IProps {
  panels: (IAnalyticsPanel | IAnalyticsPanelRow)[];
  data: IAnalyticsData;
  updatePanelData: (
    panelID: string,
    data: any[],
    owner?: string,
    dateString?: string,
    timeRange?: { gt: string; lt: string },
  ) => void;
}

export const AnalyticsGrid: React.FC<IProps> = ({ panels, data, ...props }) => {
  const generateLayout = () => {
    return panels.map(panel => {
      return {
        i: panel.id,
        x: panel.gridPos.x,
        y: panel.gridPos.y,
        w: panel.gridPos.w,
        h: panel.gridPos.h,
        minW: 4,
        maxW: 12,
        maxH: 3,
        minH: panel.panelType === 'row' ? 0.25 : 2,
      };
    });
  };

  return (
    <ReactGridLayout
      width={lastGridWidth}
      isDraggable={false}
      isResizable={false}
      containerPadding={[0, 0]}
      margin={[20, 20]}
      layout={generateLayout()}
      cols={12}
      style={{
        margin: '0px 24px 20px 24px',
      }}
    >
      {panels.map(panel => {
        if (panel.panelType === 'row') {
          return (
            <div key={panel.id}>
              <PanelRow row={panel} />
            </div>
          );
        }

        return (
          <div key={panel.id}>
            <Panel
              panel={panel}
              data={data[panel.id] || { data: [], updatedAt: new Date(0) }}
              updatePanelData={props.updatePanelData}
            />
          </div>
        );
      })}
    </ReactGridLayout>
  );
};

export default AnalyticsGrid;
