import { Box, HStack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import useQueryParams from 'core/hooks/useQueryParams';
import { Dropdown, FormButton, Tag } from 'library/atoms';
import { CustomDrawerComponent, HoverBox } from 'library/molecules';
import { useEffect, useState } from 'react';
import { FilterEntity, useEntityOwnerFilter } from '../../owner-filters/hooks/useEntityOwnerFilter';
import { QUERY_PARAM_KEYS, RULESET_OWNER_TITLE } from '../util';
import { useGerFilter } from './state';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const GERFilter = ({ isOpen, onClose }: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  const {
    gerFilters,
    setGerFilter,
    removeGerFilter,
    removeGerFiltersByKey,
    setGerFilterApply,
    clearAllGerFilter,
    gerFilterApply,
    clearGerFilter,
    setInitGerFilter,
  } = useGerFilter();
  const query = useQueryParams();

  const filterQueryParam = query.get(QUERY_PARAM_KEYS.OWNER) ?? '';

  const { dropdownOptions, ownerFilterSelected } = useEntityOwnerFilter({
    queryParamsString: filterQueryParam,
    entity: FilterEntity.GER,
    selectedTeamId: '',
  });

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const onCancel = () => {
    clearGerFilter();
    onClose();
  };

  const onApply = () => {
    setGerFilterApply();
    onClose();
  };

  const manageTitle = (type: string) => {
    if (type === 'squad') {
      return `(Your Squad)`;
    } else return '';
  };

  const onChange = (item: any, key: string) => {
    if (item[0].value === 'select-all') {
      const squad = dropdownOptions.filter(dropdown => dropdown.label === 'You & Your Squads');
      squad.map(data =>
        data.options
          .filter(options => options.value !== 'select-all')
          .map(data => {
            const newFilter = {
              key: key,
              value: data.value,
              label: data.label + manageTitle(data.type),
              type: data.type,
              username: data.username,
            };
            const existingFilter = gerFilters.find(filter => filter.value === newFilter.value);
            if (!existingFilter) {
              setGerFilter(newFilter);
            }
          }),
      );
    } else {
      const newFilter = {
        key: key,
        value: item[0].value,
        label: item[0].label + manageTitle(item[0].type),
        type: item[0].type,
        username: item[0].username,
      };
      const existingFilter = gerFilters.find(filter => filter.value === newFilter.value);
      if (!existingFilter) {
        setGerFilter(newFilter);
      }
    }
  };

  const removeFilter = (value: string) => {
    removeGerFilter(value);
  };

  useEffect(() => {
    setInitGerFilter();
  }, [isOpen]);

  return (
    <CustomDrawerComponent
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
      title="Filter"
      disableBodyPadding
      footer={
        <HStack spacing={3}>
          <FormButton title="Apply" onClick={onApply} />
          <FormButton title="Cancel" variant="secondary" onClick={onCancel} />
          <FormButton
            title="Clear All"
            variant="ghost"
            disabled={!gerFilters.length}
            onClick={() => {
              clearAllGerFilter();
              onClose();
            }}
          />
        </HStack>
      }
    >
      <HoverBox>
        <Box
          display="grid"
          p={4}
          gap={2}
          width="full"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <HStack justifyContent="space-between">
            <Text variant="formInput_800" color="secondary.700">
              {RULESET_OWNER_TITLE}
            </Text>
            {isHovering && gerFilters.length && (
              <Text
                variant="formInput_800"
                color="secondary.700"
                onClick={() => {
                  removeGerFiltersByKey(RULESET_OWNER_TITLE);
                }}
                style={{ cursor: 'pointer' }}
              >
                Clear
              </Text>
            )}
          </HStack>
          <Dropdown
            options={dropdownOptions}
            value={[]}
            variant="avatar"
            isMulti={true}
            isClearable={false}
            controlShouldRenderValue={false}
            onChange={item => onChange(item, RULESET_OWNER_TITLE)}
            placeholder="Select squad, team or user"
          />
          <Wrap mt={3}>
            {gerFilters
              .filter(data => data.key === RULESET_OWNER_TITLE)
              .map(entity => {
                return (
                  <WrapItem key={entity.label.toString()}>
                    <Tag
                      size="md"
                      variant="filter"
                      title={entity.label}
                      onClose={() => removeFilter(entity.value)}
                      username={entity.username}
                    />
                  </WrapItem>
                );
              })}
          </Wrap>
        </Box>
      </HoverBox>
    </CustomDrawerComponent>
  );
};
