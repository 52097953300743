import axios from 'axios';
import { ICommunicationCard } from 'core/interfaces/ICommunicationCard';
import { API } from '../api';
import {
  IChannelsForTeamResp,
  IExtensionMSTeams,
  IExtensionMsTeamsGraphApiUser,
  IExtensionMsTeamsUser,
  IExtensionStatus,
  ISlackChannel,
  ISlackChannelResponse,
  ISlackChannelUserResponse,
  ISlackTeamResponse,
  SlackConfigurationPayload,
  SlackConfigurationType,
} from '../interfaces/IExtensions';

class ExtensionService {
  private _api = `${API.config.endpoint}/organizations/${API.config.organizationId}/extensions`;
  private _batman = `${API.config.batman}/organizations/${API.config.organizationId}/extensions`;

  getExtentions = () => axios.get(`${this._api}/all`);

  getSlackChannels = () =>
    axios.get<{ data: { channels: ISlackChannelResponse[] } }>(`${this._batman}/slack/channels`);

  getSlackV2Channels = () =>
    axios.get<{ data: { channels: ISlackChannelResponse[] } }>(`${this._batman}/slack_v2/channels`);

  getSlackV2ChannelUsers = () =>
    axios.get<{ data: { users: ISlackChannelUserResponse[] } }>(`${this._batman}/slack_v2/users`);

  createNewSlackChannel = (payload: { channel_name: string; incident_id: string }) =>
    axios.post<{ data: ICommunicationCard }>(`${this._batman}/slack/channel`, payload);

  createNewSlackV2Channel = (payload: { channel_name: string; incident_id: string }) =>
    axios.post<{ data: ICommunicationCard }>(`${this._batman}/slack_v2/channel`, payload);

  getOrganizationChannel = () =>
    axios.get<{ data: ISlackChannelResponse }>(`${this._batman}/slack/channels/info`);

  getOrganizationChannel_V2 = () =>
    axios.get<{ data: ISlackChannel }>(`${this._batman}/slack_v2/configure`);

  setOrganizationChannel = (channelId: string) =>
    axios.post<{ data: string }>(`${this._batman}/slack/channels`, { channel_id: channelId });

  updateSlackConfiguration = (payload: SlackConfigurationPayload) =>
    axios.put<{ data: SlackConfigurationType }>(`${this._batman}/slack_v2/configure`, payload);

  getSlackConfiguration = () =>
    axios.get<{ data: SlackConfigurationType }>(`${this._batman}/slack_v2/configure`);

  integrateSlack = (code: string) =>
    axios.post<{ data: string }>(`${this._batman}/slack`, { code });

  integrateSlackV2 = (code: string) =>
    axios.post<{ data: string }>(`${this._batman}/slack_v2`, { code });

  integrateMSTeams = (state: string) =>
    axios.post<{ data: string }>(`${this._batman}/msteams`, { state });

  getMSTeamsConfig = () => axios.get<{ data: IExtensionMSTeams }>(`${this._batman}/msteams/config`);
  getMSTeamsUserList = () =>
    axios.get<{ data: IExtensionMsTeamsUser[] }>(`${this._batman}/msteams/users`);
  getMSTeamsGraphAPIUserList = () =>
    axios.get<{ data: IExtensionMsTeamsGraphApiUser[] }>(`${this._batman}/msteams/ms-graph/users`);

  postMSTeamsConfig = (config: IExtensionMSTeams) =>
    axios.post<{ data: IExtensionMSTeams }>(`${this._batman}/msteams/config`, config);

  deleteMSTeamsConfig = () =>
    axios.delete<{ data: IExtensionMSTeams }>(`${this._batman}/msteams/config`);

  getMSTeamsChannels = (teamID: string) =>
    axios.post<{ data: IChannelsForTeamResp }>(`${this._batman}/msteams/channels`, { teamID });

  getSlackStatus = () =>
    axios.get<{ data: { status: IExtensionStatus } }>(`${this._batman}/slack/status`);

  getSlackV2Status = () =>
    axios.get<{ data: { status: IExtensionStatus } }>(`${this._batman}/slack_v2/status`);

  archiveSlackChannel = (slack_channel_id: string) =>
    axios.post<{ data: ICommunicationCard }>(`${this._batman}/slack/channel/archive`, {
      slack_channel_id,
    });

  revoke = (extension: string) => axios.delete<{ data: string }>(`${this._batman}/${extension}`);

  getSlackTeam = () => axios.get<{ data: ISlackTeamResponse }>(`${this._batman}/slack/team/info`);

  getSlackV2Team = () =>
    axios.get<{ data: ISlackTeamResponse }>(`${this._batman}/slack_v2/team/info`);
}

export default ExtensionService;
