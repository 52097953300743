export const errorMessages = {
  schedule: {
    name: 'Please enter the schedule name !',
    duplicateName: 'A schedule with this name already exists. Please select a unique name!',
    timezone: 'Please select a schedule timezone !',
    nameLength: 'The name of the schedule must not exceed NUM characters!',
    descriptionLength: 'The description of the schedule must not exceed NUM characters.',
    colorScheme: 'Please select a display colour!',
    owner: 'Please select a schedule owner!',
    tags: 'Please make sure that tags are not empty or remove them before proceeding!',
    duplicateTags: 'Please make sure that tags are not duplicated before proceeding!',
  },
  rotations: {
    name: 'Please enter the rotation name !',
    startsAt: 'Please select the rotation start date !',
    endsOn: 'Please select the rotation end date !',
    nameLength: 'The name of the rotation must not exceed NUM characters.',
    startEndDate: 'Start date should be less than end date',
    submit: 'One or more rotations contain errors. Please fix them before proceeding!',
    noRotations: 'Please add rotations before proceeding!',
    participantGroup: 'Please add participants before proceeding!',
    invalidSlotConfiguration: [
      { type: 'overlappingSlots', message: 'One or more slots overlap with each other' },
      { type: 'over24Hours', message: 'On call hours/day exceeds 24 hours' },
    ],
    noPreview:
      'One or more rotations contain invalid selection. Please fix them before proceeding!',
    duplicateNames: 'One or more rotations have the same name. Please fix them before proceeding!',
  },
};
