import { Location } from 'history';
import React, { useCallback, useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

/**
 * RouteGuard
 *
 * Route Based Guard - Used where the change in route is the trigger
 * checkDirty: Gets executed when there is a change in route
 *
 * Manual Guard - Used where the trigger is not the route
 * isManual: true if manual mode is being used
 * showModal: state of the unsaved change modal
 * onModalClose: Gets called when the `Keep Editing` button is clicked
 * onDiscardChanges: Gets called when the `Discard Changes` button is clicked
 *
 */
interface IProps {
  title: string;
  // Route based guard
  checkDirty?: () => boolean;

  // Manual guard
  isManual?: boolean;
  showModal?: boolean;
  onModalClose?: () => void;
  onDiscardChanges?: () => void;
}

const RouteGuard = ({
  title,
  checkDirty,
  isManual,
  showModal,
  onModalClose,
  onDiscardChanges,
}: IProps) => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);

  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && checkDirty?.()) {
      onOpen();
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const preventNavigation = useCallback(
    (e: BeforeUnloadEvent) => {
      if (checkDirty?.()) {
        e.preventDefault();
        e.returnValue = true;
      }
    },
    [checkDirty],
  );

  const handleConfirmNavigationClick = () => {
    onClose();
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation);
    }
  }, [confirmedNavigation, lastLocation, history]);

  useEffect(() => {
    window.addEventListener('beforeunload', preventNavigation);

    return () => window.removeEventListener('beforeunload', preventNavigation);
  }, [preventNavigation]);

  return (
    <>
      {!isManual && <Prompt message={handleBlockedNavigation} />}

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen || showModal || false}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{title}</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              variant="outline"
              onClick={isManual && onModalClose ? onModalClose : onClose}
            >
              Cancel
            </Button>
            <Button
              ml={3}
              onClick={
                isManual && onDiscardChanges ? onDiscardChanges : handleConfirmNavigationClick
              }
            >
              Yes, discard changes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default RouteGuard;
