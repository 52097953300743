import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { REQUEST_SAVE_USER_DEVICE_ID } from '../../const/auth';
import { postDeviceId } from '../../api/auth';
import { onRequestSaveUserDeviceSuccess } from '../../actions/auth';

const postDeviceInfoEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_SAVE_USER_DEVICE_ID),
    map((action: any) => action.payload),
    switchMap(
      ({ deviceId, userId, deviceOS }: { deviceId: string; userId: string; deviceOS: string }) => {
        return postDeviceId(deviceId, userId, deviceOS).pipe(
          map(() => {
            return onRequestSaveUserDeviceSuccess();
          }),
        );
      },
    ),
  );

export default postDeviceInfoEpic;
