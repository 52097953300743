import { Box, Button, Grid, HStack, Text, VStack } from '@chakra-ui/react';
import { THEME_COLORS } from 'library/theme/colors';
import {
  getStatusCodeColorMapping,
  getStatusCodeMapping,
} from 'views/main/organization/statuspage-v2/helpers/helper.timeline';

import useStatuspageComponentTimelineContext from '../hook';
import StatusPageTimelineOverrideFormDateSwitcher from './DateSwitcher';
import StatuspageComponentTimelineOverrideInput from './OverrideInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { AppTracker } from 'shared/analytics/tracker';
import {
  T_WA_UP_STATUS_PAGE_V2_TIMELINE_OVERRIDE_COMPONENT_TIMELINE_EDITED,
  T_WA_UP_STATUS_PAGE_V2_TIMELINE_OVERRIDE_EDIT_TIMELINE_MODAL_RESET,
} from 'core/const/tracker';
import { DateTime } from 'luxon';

export default function StatusPageTimelineOverrideForm() {
  const {
    statusCodes,
    activeDate,
    setActiveDate,
    componentDayLogMap,
    setOverrideOpen,
    override,
    setOverrideUnsaved,
    setUnsavedModalCallback,
    statusPageId,
    component,
  } = useStatuspageComponentTimelineContext();
  const statusCodesMap = getStatusCodeMapping(statusCodes);
  const statusCodesColorsMap = getStatusCodeColorMapping(statusCodes);
  const [activeDayLog, setActiveDayLog] = React.useState(componentDayLogMap[activeDate || '']);
  const [activeDayLogInitialValues, setActiveDayLogInitialValues] = React.useState<{
    operationalHours: number;
    operationalMinutes: number;
    degradedHours: number;
    degradedMinutes: number;
    partialOutageHours: number;
    partialOutageMinutes: number;
    majorOutageHours: number;
    majorOutageMinutes: number;
  } | null>(null);
  const totalUptimeInMinutes =
    (activeDayLog?.statusDurations?.operational?.duration || 0) +
    (activeDayLog?.statusDurations?.degraded?.duration || 0) +
    (activeDayLog?.statusDurations?.partialOutage?.duration || 0) +
    (activeDayLog?.statusDurations?.majorOutage?.duration || 0);

  const schema = yup.object().shape({
    operationalHours: yup.number().min(0).required('Operational hours are required'),
    operationalMinutes: yup.number().min(0).max(59).required('Operational minutes are required'),
    degradedHours: yup.number().min(0).required('Degraded hours are required'),
    degradedMinutes: yup.number().min(0).max(59).required('Degraded minutes are required'),
    partialOutageHours: yup.number().min(0).required('Partial outage hours are required'),
    partialOutageMinutes: yup
      .number()
      .min(0)
      .max(59)
      .required('Partial outage minutes are required'),
    majorOutageHours: yup.number().min(0).required('Major outage hours are required'),
    majorOutageMinutes: yup.number().min(0).max(59).required('Major outage minutes are required'),
  });

  const { setValue, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      operationalHours: Math.floor(
        ((activeDayLog?.override
          ? activeDayLog?.overrideData?.operational
          : activeDayLog?.statusDurations?.operational?.duration) || 0) / 60,
      ),
      operationalMinutes:
        ((activeDayLog?.override
          ? activeDayLog?.overrideData?.operational
          : activeDayLog?.statusDurations?.operational?.duration) || 0) % 60,
      degradedHours: Math.floor(
        ((activeDayLog?.override
          ? activeDayLog?.overrideData?.degraded
          : activeDayLog?.statusDurations?.degraded?.duration) || 0) / 60,
      ),
      degradedMinutes:
        ((activeDayLog?.override
          ? activeDayLog?.overrideData?.degraded
          : activeDayLog?.statusDurations?.degraded?.duration) || 0) % 60,
      partialOutageHours: Math.floor(
        ((activeDayLog?.override
          ? activeDayLog?.overrideData?.partialOutage
          : activeDayLog?.statusDurations?.partialOutage?.duration) || 0) / 60,
      ),
      partialOutageMinutes:
        ((activeDayLog?.override
          ? activeDayLog?.overrideData?.partialOutage
          : activeDayLog?.statusDurations?.partialOutage?.duration) || 0) % 60,
      majorOutageHours: Math.floor(
        ((activeDayLog?.override
          ? activeDayLog?.overrideData?.majorOutage
          : activeDayLog?.statusDurations?.majorOutage?.duration) || 0) / 60,
      ),
      majorOutageMinutes:
        ((activeDayLog?.override
          ? activeDayLog?.overrideData?.majorOutage
          : activeDayLog?.statusDurations?.majorOutage?.duration) || 0) % 60,
    },
    reValidateMode: 'onChange',
  });

  const values = watch();

  const totalDegraded =
    !isNaN(values?.degradedHours) && !isNaN(values?.degradedMinutes)
      ? values?.degradedHours * 60 + values?.degradedMinutes
      : 0;
  const totalPartialOutage =
    !isNaN(values?.partialOutageHours) && !isNaN(values?.partialOutageMinutes)
      ? values?.partialOutageHours * 60 + values?.partialOutageMinutes
      : 0;
  const totalMajorOutage =
    !isNaN(values?.majorOutageHours) && !isNaN(values?.majorOutageMinutes)
      ? values?.majorOutageHours * 60 + values?.majorOutageMinutes
      : 0;

  const totalOverrideDuration = totalDegraded + totalPartialOutage + totalMajorOutage;

  const overrideDurationInvalid = totalOverrideDuration > totalUptimeInMinutes;

  useEffect(() => {
    setActiveDayLog(componentDayLogMap[activeDate || ''] || null);
  }, [activeDate]);

  useEffect(() => {
    if (activeDayLog) {
      const activeDayLogInitValues = {
        operationalHours: Math.floor(
          ((activeDayLog?.override
            ? activeDayLog?.overrideData?.operational
            : activeDayLog?.statusDurations?.operational?.duration) || 0) / 60,
        ),
        operationalMinutes:
          ((activeDayLog?.override
            ? activeDayLog?.overrideData?.operational
            : activeDayLog?.statusDurations?.operational?.duration) || 0) % 60,
        degradedHours: Math.floor(
          ((activeDayLog?.override
            ? activeDayLog?.overrideData?.degraded
            : activeDayLog?.statusDurations?.degraded?.duration) || 0) / 60,
        ),
        degradedMinutes:
          ((activeDayLog?.override
            ? activeDayLog?.overrideData?.degraded
            : activeDayLog?.statusDurations?.degraded?.duration) || 0) % 60,
        partialOutageHours: Math.floor(
          ((activeDayLog?.override
            ? activeDayLog?.overrideData?.partialOutage
            : activeDayLog?.statusDurations?.partialOutage?.duration) || 0) / 60,
        ),
        partialOutageMinutes:
          ((activeDayLog?.override
            ? activeDayLog?.overrideData?.partialOutage
            : activeDayLog?.statusDurations?.partialOutage?.duration) || 0) % 60,
        majorOutageHours: Math.floor(
          ((activeDayLog?.override
            ? activeDayLog?.overrideData?.majorOutage
            : activeDayLog?.statusDurations?.majorOutage?.duration) || 0) / 60,
        ),
        majorOutageMinutes:
          ((activeDayLog?.override
            ? activeDayLog?.overrideData?.majorOutage
            : activeDayLog?.statusDurations?.majorOutage?.duration) || 0) % 60,
      };

      setActiveDayLogInitialValues(activeDayLogInitValues);

      Object.keys(activeDayLogInitValues).forEach(key => {
        setValue(key as any, activeDayLogInitValues[key as keyof typeof activeDayLogInitValues]);
      });

      setOverrideUnsaved(false);
    }
  }, [activeDayLog, setValue]);

  const isValid = schema.isValidSync(values);

  const onSubmit = () => {
    if (isValid) {
      addOverride();
    }
  };

  const durationChangeHandler =
    (
      name: 'operational' | 'degraded' | 'partialOutage' | 'majorOutage',
      type: 'hours' | 'minutes',
    ) =>
    (value: number) => {
      const formKey = `${name}${type === 'hours' ? 'Hours' : 'Minutes'}`;
      setValue(formKey as any, value);
      if (
        activeDayLogInitialValues &&
        activeDayLogInitialValues[formKey as keyof typeof activeDayLogInitialValues] !== value
      ) {
        setOverrideUnsaved(true);
      }
    };

  const addOverride = async () => {
    let res: any;

    // If values are same as initial values, then remove override
    if (
      activeDayLog.statusDurations.degraded?.duration ===
        values?.degradedHours * 60 + values?.degradedMinutes &&
      activeDayLog.statusDurations.majorOutage?.duration ===
        values?.majorOutageHours * 60 + values?.majorOutageMinutes &&
      activeDayLog.statusDurations.partialOutage?.duration ===
        values?.partialOutageHours * 60 + values?.partialOutageMinutes
    ) {
      res = await override({
        date: activeDayLog?.date,
      });
    } else {
      res = await override({
        date: activeDayLog?.date,
        duration: {
          degraded: values?.degradedHours * 60 + values?.degradedMinutes,
          majorOutage: values?.majorOutageHours * 60 + values?.majorOutageMinutes,
          partialOutage: values?.partialOutageHours * 60 + values?.partialOutageMinutes,
        },
      });
    }

    if (res && res?.updateStatusPageComponent?.id) {
      setOverrideOpen(false);
      setActiveDate(null);
      const trackingDate = DateTime.fromJSDate(new Date(activeDayLog?.date), { zone: 'utc' });
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_TIMELINE_OVERRIDE_COMPONENT_TIMELINE_EDITED, {
        'Status Page ID': statusPageId.toString(),
        'Component ID': component.id?.toString(),
        'Timeline Edit Date': trackingDate.toFormat('yyyy-MM-dd HH:mm:ss'),
      });
    }
  };

  const resetOverride = async () => {
    const res = await override({
      date: activeDayLog?.date,
    });

    if (res?.updateStatusPageComponent?.id) {
      setOverrideOpen(false);
      setActiveDate(null);
      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_TIMELINE_OVERRIDE_EDIT_TIMELINE_MODAL_RESET, {
        'Status Page ID': statusPageId.toString(),
        'Component ID': component.id?.toString(),
      });
    }
  };

  if (!activeDayLog) return <></>;

  return (
    <Box
      border={`1px solid ${THEME_COLORS.secondary[200]}`}
      borderRadius="6px"
      padding="16px"
      marginBottom="8px"
    >
      <form>
        <VStack>
          <HStack justify="space-between" width="100%">
            <Text fontSize="16px" fontWeight="400" color={THEME_COLORS.secondary[950]}>
              Edit Timeline
            </Text>
            <StatusPageTimelineOverrideFormDateSwitcher />
          </HStack>
          <Grid width="100%" templateColumns="repeat(2, 1fr)" gap={4}>
            <StatuspageComponentTimelineOverrideInput
              label={statusCodesMap['operational']}
              color={statusCodesColorsMap['operational']}
              timeInMinutes={activeDayLog?.statusDurations?.operational?.duration || 0}
              disabled
              hoursValue={values?.operationalHours}
              minutesValue={values?.operationalMinutes}
              hoursOnChange={durationChangeHandler('operational', 'hours')}
              minutesOnChange={durationChangeHandler('operational', 'minutes')}
            />
            <StatuspageComponentTimelineOverrideInput
              label={statusCodesMap['degraded']}
              color={statusCodesColorsMap['degraded']}
              timeInMinutes={activeDayLog?.statusDurations?.degraded?.duration || 0}
              hoursValue={values?.degradedHours}
              minutesValue={values?.degradedMinutes}
              hoursOnChange={durationChangeHandler('degraded', 'hours')}
              minutesOnChange={durationChangeHandler('degraded', 'minutes')}
            />
            <StatuspageComponentTimelineOverrideInput
              label={statusCodesMap['partial-outage']}
              color={statusCodesColorsMap['partial-outage']}
              timeInMinutes={activeDayLog?.statusDurations?.partialOutage?.duration || 0}
              hoursValue={values?.partialOutageHours}
              minutesValue={values?.partialOutageMinutes}
              hoursOnChange={durationChangeHandler('partialOutage', 'hours')}
              minutesOnChange={durationChangeHandler('partialOutage', 'minutes')}
            />
            <StatuspageComponentTimelineOverrideInput
              label={statusCodesMap['major-outage']}
              color={statusCodesColorsMap['major-outage']}
              timeInMinutes={activeDayLog?.statusDurations?.majorOutage?.duration || 0}
              hoursValue={values?.majorOutageHours}
              minutesValue={values?.majorOutageMinutes}
              hoursOnChange={durationChangeHandler('majorOutage', 'hours')}
              minutesOnChange={durationChangeHandler('majorOutage', 'minutes')}
            />
          </Grid>
          {overrideDurationInvalid && (
            <Text fontSize="12px" fontWeight="400" color={THEME_COLORS.brand.red} marginTop="16px">
              Non-operational time cannot exceed the total active time for the component.
            </Text>
          )}
          <HStack alignSelf="end" width="fit-content" gap="0px" paddingTop="8px">
            <Button
              color={THEME_COLORS.brand.blue}
              variant="ghost"
              size="xs"
              onClick={() => {
                setOverrideOpen(false);
                setActiveDate(null);
                setOverrideUnsaved(false);
                setUnsavedModalCallback(() => () => null);
              }}
              type="button"
            >
              Cancel
            </Button>
            <Button
              color={THEME_COLORS.brand.blue}
              boxShadow={`0 0 0 1px ${THEME_COLORS.brand.blue}`}
              height="22px"
              variant="outline"
              size="xs"
              onClick={resetOverride}
              type="button"
              disabled={!isValid}
            >
              Reset
            </Button>
            <Button
              size="xs"
              type="button"
              disabled={!isValid || overrideDurationInvalid}
              onClick={e => {
                e.preventDefault();
                onSubmit();
              }}
            >
              Save
            </Button>
          </HStack>
        </VStack>
      </form>
    </Box>
  );
}
