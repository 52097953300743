import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const DrawerTheme: ComponentStyleConfig = {
  sizes: {
    custom: {
      dialog: { maxWidth: '912px' },
    },
  },
  variants: {
    alwaysOpen: {
      dialog: {
        pointerEvents: 'auto',
      },
      dialogContainer: {
        pointerEvents: 'none',
      },
    },
  },
};
