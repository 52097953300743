import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ContainerLoad, Grid, SnackContext, Theme } from 'uie/components';
import { AppTracker } from 'shared/analytics/tracker';
import { TaskAndRunbookService } from 'core';
import { CHECK, CONTENT, snackMsg, TASK } from '../taskAndRunbook.constants';
import { requestIncidentDeatilTask } from 'core/actions/organization/incidentDetailTask';
import { IPatchReq, ITask } from 'core/interfaces/ITask';
import { TaskButton } from '../addTaskOrRunbookbuttons';
import { T_WA_GS_TASK_ADDED, T_WA_GS_TASK_DELETED, T_WA_GS_TASK_UPDATED } from 'core/const/tracker';
import { IAppState } from 'core/interfaces/IAppState';
import SnackBarComp from '../../../../runbooks/common/snackbar.component';
import { isAxiosError } from '../taskOrRunbook.helper';
import TaskModal from './TaskModal';
import TaskList from './taskList';
import { TaskDeleteModal } from '../deleteModal';
interface IProps_ extends Pick<IAppState, 'organization' | 'APP_CONFIG' | 'incidentTask'> {
  incidentId: string;
  focusMessageId: { id: string; type?: string; index?: number };
  currentTab: string;
  requestIncidentDeatilTask: typeof requestIncidentDeatilTask;
  getIncidentDetails: () => void;
  isSuppressedIncident: boolean;
}

function TaskTab({ ...props }: IProps_) {
  const _createSnack = SnackContext();
  const taskRefs = useRef<Array<HTMLDivElement>>([]);
  taskRefs.current = [];
  const _taskAndRunbookService = new TaskAndRunbookService(props.incidentId);

  const { sidebarState } = props.APP_CONFIG;

  //states
  const [collapseTask, setTaskCollapse] = useState<boolean>(true);
  const [enableDelete, setEnableDelete] = useState<number>(-1);
  const [enableTaskModel, setEnableTaskModel] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<IPatchReq | null>(null);
  const [taskContent, setContent] = useState<string>('');
  const [deleteModal, setEnableDeleteModal] = useState<boolean>(false);
  const [deleteTaskIndex, setDeleteTaskIndex] = useState<number | null>(null);
  const [showSnack, setShowSnack] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [background, setBackground] = useState<string>('');
  const listOfCheckedTask = props.incidentTask.tasks?.tasks.filter(step => step.completed);
  const [focusID, setFocusId] = useState<string>('');
  const { theme } = Theme;
  const hideSnackBar = () => {
    setShowSnack(false);
    setMessage('');
  };
  useEffect(() => {
    if (taskRefs.current) {
      if (props.focusMessageId.id !== focusID) {
        if (props.focusMessageId.type === TASK) {
          if (typeof props.focusMessageId.id !== 'undefined') {
            focusMessage(props.focusMessageId.id);
          }
        }
      }
    }

    if (!showSnack) {
      _createSnack(null);
    } else {
      _createSnack(
        <SnackBarComp message={message} background={background} hideSnackBar={hideSnackBar} />,
      );
    }
    return () => _createSnack(null);
  });

  const focusMessage = (runbookIs: string) => {
    const tasksIndex = props.incidentTask.tasks
      ? props.incidentTask.tasks?.tasks.findIndex(_ => _.id == runbookIs)
      : -1;
    const ref = taskRefs.current[tasksIndex];
    if (ref) {
      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });

      ref.style.backgroundColor = theme.primary.light;
      setTimeout(() => {
        ref.style.backgroundColor = '';
        setFocusId(props.focusMessageId.id);
      }, 5000);
    }
  };

  const errorMsg = (err: any, defaultMsg: string) => {
    if (isAxiosError(err)) {
      const errMsg = err?.response?.data?.meta?.error_message ?? defaultMsg;
      showSnackBar(errMsg, theme.danger.default, 8000);
    } else {
      showSnackBar(defaultMsg, theme.danger.default, 8000);
    }
  };
  const setUpdateTaskFn = (task: ITask, index: number) => {
    setSelectedTask({ ...task, index });
    setEnableTaskModel(true);
  };
  const showSnackBar = (msg: string, bg: string, timeout: number) => {
    setMessage(msg);
    setBackground(bg);
    setShowSnack(true);
    setTimeout(hideSnackBar, timeout);
  };

  const addTask = async () => {
    try {
      await _taskAndRunbookService.addTask({ task: taskContent, completed: false });
      props.requestIncidentDeatilTask(props.incidentId);
      AppTracker.track(T_WA_GS_TASK_ADDED, {});
      showSnackBar(snackMsg.ADD_TASK_SUCCESS, theme.success.default, 3000);
    } catch (err: any) {
      setEnableTaskModel(false);
      errorMsg(err, snackMsg.ADD_TASK);
    } finally {
      setEnableTaskModel(false);
      setContent('');
      props.getIncidentDetails();
    }
  };
  const updateTask = async (index: number, completed: boolean, content: string, type: string) => {
    try {
      let callAPI = _taskAndRunbookService.updateTask;
      if (type === CHECK) callAPI = _taskAndRunbookService.updateTaskCheck;
      if (type === CONTENT) callAPI = _taskAndRunbookService.updateTask;

      await callAPI({ completed, index, content });
      setEnableTaskModel(false);
      if (type === CHECK)
        showSnackBar(
          `Task has been marked ${completed ? 'complete' : 'Incomplete'} Successfully!`,
          theme.success.default,
          3000,
        );
      if (type === CONTENT) {
        AppTracker.track(T_WA_GS_TASK_UPDATED, {});
        showSnackBar(snackMsg.UPDATE_TASK_SUCCESS, theme.success.default, 3000);
      }
    } catch (err: any) {
      setEnableTaskModel(false);
      errorMsg(err, snackMsg.UPDATE_TASK);
    } finally {
      props.requestIncidentDeatilTask(props.incidentId);
      setSelectedTask(null);
      props.getIncidentDetails();
    }
  };
  const deleteTask = async (index: number) => {
    try {
      await _taskAndRunbookService.deleteTask(index);
      AppTracker.track(T_WA_GS_TASK_DELETED, {});
      showSnackBar(snackMsg.DELETE_TASK_SUCCESS, Theme.theme.success.default, 3000);
    } catch (err: any) {
      setEnableTaskModel(false);
      errorMsg(err, snackMsg.DELETE_TASK);
    } finally {
      setEnableDeleteModal(false);
      setDeleteTaskIndex(null);
      props.requestIncidentDeatilTask(props.incidentId);
      props.getIncidentDetails();
    }
  };
  const updateTaskCall = () => {
    if (selectedTask) {
      const { index, content, completed } = selectedTask;
      updateTask(index, completed, content, CONTENT);
    }
  };

  return (
    <>
      <Grid
        width={'100%'}
        alignItems="flex-start"
        type="column"
        justifyContent="flex-start"
        style={{ paddingRight: '20px' }}
      >
        {props.incidentTask.loading && (
          <div style={{ width: '100%' }}>
            <ContainerLoad isLoading={true} color={Theme.theme.primary.default} />
          </div>
        )}
        <TaskButton
          setSelectedTask={(task: IPatchReq | null): void => setSelectedTask(task)}
          setEnableTaskModal={(enable: boolean) => setEnableTaskModel(enable)}
          isSuppressedIncident={props.isSuppressedIncident}
        />
        <TaskList
          collapseTask={collapseTask}
          setTaskCollapse={setTaskCollapse}
          enableDelete={enableDelete}
          setEnableDelete={setEnableDelete}
          setDeleteTaskIndex={setDeleteTaskIndex}
          setEnableDeleteModal={setEnableDeleteModal}
          listOfCheckedTask={listOfCheckedTask}
          setUpdateTaskFn={setUpdateTaskFn}
          sidebarState={sidebarState}
          updateTask={updateTask}
          ref={taskRefs}
          tasks={props.incidentTask.tasks}
          isSuppressedIncident={props.isSuppressedIncident}
        />
      </Grid>
      <TaskModal
        enableTaskModel={enableTaskModel}
        setEnableModel={setEnableTaskModel}
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
        taskContent={taskContent}
        setContent={setContent}
        updateTaskCall={updateTaskCall}
        addTask={addTask}
      />
      <TaskDeleteModal
        action={deleteTask}
        deleteModal={deleteModal}
        deleteTaskIndex={deleteTaskIndex}
        setDeleteTaskIndex={setDeleteTaskIndex}
        setEnableDeleteModal={setEnableDeleteModal}
      />
    </>
  );
}

export default connect(
  ({ organization, APP_CONFIG, incidentTask }: IAppState) => ({
    organization,
    incidentTask,
    APP_CONFIG,
  }),
  { requestIncidentDeatilTask },
)(TaskTab);
