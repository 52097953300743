import {
  allOperators,
  booleanComparators,
  numberComparators,
  stringComparators,
} from 'core/const/comparators';
import { findGracefully } from 'core/helpers';
import { IAppState } from 'core/interfaces/IAppState';
import { IAlertSource } from 'core/interfaces/IIntegration';
import { DeduplicationRulesService, ServiceService } from 'core/services';
import React from 'react';
import { connect } from 'react-redux';
import {
  IDedupKey,
  IDedupeKeyData,
  IDedupeKeyInput,
  IDeduplicationRule,
  IRuleTimeUnit,
} from 'views/main/organization/service-catalog/Interfaces/automation-rule';
import { RoutingRules } from '..';
import { AutomationRuleContext, AutomationRuleContextProps } from '../context';

import render from './render.index';
import { ActiveAlertSource } from 'views/main/organization/service-catalog/hooks/useServiceDetail';

import { AppTracker } from 'shared/analytics/tracker';
import {
  T_WA_UP_DEDUPE_KEYS_KEY_CREATED,
  T_WA_UP_DEDUPE_KEYS_KEY_VIEWED,
  T_WA_UP_DEDUPE_KEYS_SHOW_OUTPUT_BUTTON_CLICKED,
} from 'core/const/tracker';

interface IIndexedDeduplicationRule extends IDeduplicationRule {
  id: number;
  rule_id: string;
  existing: boolean;
}

interface IProps extends Pick<IAppState, 'organization' | 'integrations'> {
  hide: () => void;
  isDirty: boolean;
  serviceId: string;
  checkAndSetDirty: (isModalFormDirty?: boolean) => void;
  refetchRule: () => void;
  editRuleId?: string;
  alertSourceName?: string;
  rules?: IDeduplicationRule[];
  alertSourcesConfiguredWithDedupeKeys: string[] | undefined;
  onDeleteAction: (
    rule_id: string,
    basic_expression: { lhs: string; op: string; rhs: string }[],
    ruleType: RoutingRules,
    alert_source_shortname: string | undefined,
  ) => void;
  onCopyDedupeKey: (dedupeKey: string) => void;
  mode: 'view' | 'edit' | 'create';
  activeAlertSources: ActiveAlertSource[] | undefined;
  enableDedupeKeyEditMode: (alertSourceName: string) => void;
}

interface IState {
  rules: IIndexedDeduplicationRule[];
  saving: boolean;
  fetching: boolean;
  parsing: boolean;
  deleting: boolean;
  loading: boolean;
  networkError: string;
  eventState: 'loading' | 'noEvents' | 'idle' | 'default' | 'notConfigured';
  event: any;
  alertSourceSearch: string;
  alertSource: IAlertSource | null;
  alertSourcesLoadState: 'loading' | 'success' | 'error';
  alertSourceErrorMessage: string;
  warningIndex: number | null;
  errors: string[];
  searchString: { [key: string]: string };
  swappedRuleIndexes: number;
  globalSearch: string;
  showUpgradeModal: boolean;
  planRuleLimitExceeded: boolean;
  orgDeduplicationRulesCount: number;
  existingDeleted: number;
  editRuleId: string | undefined;
  dedupeKey: IDedupKey;
  enableEditKey: boolean;
}
export class DedupeKeysModal extends React.Component<IProps, IState, AutomationRuleContextProps> {
  static contextType = AutomationRuleContext;
  context!: AutomationRuleContextProps;

  private ServiceService = new ServiceService(this.props.serviceId);
  private DeduplicationRulesService = new DeduplicationRulesService(this.props.serviceId);

  public _service = this.props.organization.services.s.find(s => s.id === this.props.serviceId);

  public alertSources: IAlertSource[] = this.props.integrations.i
    .filter(as => !as.hideAutomation && !as.deprecated)
    .map((as: any) => ({
      isActive: false,
      ...as,
    }));

  public _comparators: any = {
    string: stringComparators,
    number: numberComparators,
    boolean: booleanComparators,
    all: allOperators,
  };

  private initialState?: IState;

  constructor(props: IProps) {
    super(props);
    this.state = {
      rules: [],
      saving: false,
      fetching: false,
      deleting: false,
      parsing: false,
      loading: false,
      alertSourcesLoadState: 'loading',
      alertSourceErrorMessage: '',
      networkError: '',
      eventState: 'default',
      event: {},
      alertSourceSearch: '',
      alertSource: null,
      warningIndex: null,
      errors: [],
      searchString: {},
      swappedRuleIndexes: -1,
      globalSearch: '',
      planRuleLimitExceeded: false,
      showUpgradeModal: false,
      orgDeduplicationRulesCount: 0,
      existingDeleted: 0,
      editRuleId: props.editRuleId,
      dedupeKey: {
        key: '',
        time_window: 1,
        time_unit: IRuleTimeUnit.Hour,
        template_output: '',
      },
      enableEditKey: !props.alertSourceName,
    };
  }

  public render = render;

  public async componentDidMount() {
    this.getActiveAlertSources();
    if (!this.props.alertSourceName) {
      this.props.checkAndSetDirty(true);
    } else {
      this.props.checkAndSetDirty(false);
    }

    if (this.props.mode === 'view') {
      AppTracker.track(T_WA_UP_DEDUPE_KEYS_KEY_VIEWED);
    }
  }

  public getActiveAlertSources = async () => {
    try {
      const { data } = await this.ServiceService.getActiveAlertSources();
      const activeAlertSources = data.data as string[];

      this.alertSources.forEach((as: IAlertSource) => {
        if (activeAlertSources.includes(as._id)) {
          as.isActive = true;
        }
      });

      // sorting according to type
      this.alertSources.sort((as1: IAlertSource, as2: IAlertSource) => {
        const firstAlertSourceName = as1.type ?? '';
        const secondAlertSourceName = as2.type ?? '';
        if (!firstAlertSourceName || !secondAlertSourceName) return 0;
        if (firstAlertSourceName.toLowerCase() < secondAlertSourceName.toLowerCase()) {
          return -1;
        }
        if (firstAlertSourceName.toLowerCase() > secondAlertSourceName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      // sorting according to active status
      this.alertSources.sort((as1: IAlertSource, as2: IAlertSource) => {
        if (as1.isActive && !as2.isActive) {
          return -1;
        }
        if (!as1.isActive && as2.isActive) {
          return 1;
        }
        return 0;
      });
      this.setState({ alertSourcesLoadState: 'success' });
    } catch (err: any) {
      this.setState({
        alertSourceErrorMessage: `Network Error: ${
          err?.response?.data?.meta?.error_message ?? 'Network Error'
        }`,
        alertSourcesLoadState: 'error',
      });
    }
  };

  public getAlertSourceConfig = async (alertSource: IAlertSource) => {
    this.setState({
      alertSource,
      eventState: 'loading',
    });
    const isAlertSourceConfigured = (this.props.activeAlertSources ?? [])
      .map(as => as.short_name)
      .includes(alertSource.shortName);
    try {
      if (!isAlertSourceConfigured) {
        this.setState({
          eventState: 'notConfigured',
          event: {},
        });
      }
      const eventResponse = await this.ServiceService.getAlertSourceLatestEvent(alertSource._id);
      const pastTags = eventResponse.data.data.tags || {};
      Object.keys(pastTags).forEach(k => (pastTags[k] = pastTags[k].value));
      this.setState({
        eventState: 'idle',
        event: {
          event_count: 5,
          past: eventResponse.data.data.payload,
          past_source: alertSource.shortName,
          past_tags: pastTags,
          past_incident: {
            message: eventResponse.data.data.message,
            is_triggered: false,
            is_suppressed: false,
            is_acknowledged: true,
            is_dependency: true,
            service: findGracefully(
              this.props.organization.services.s,
              'id',
              'unknown-service',
            )(eventResponse.data.data.service_id, 'slug'),
          },
        },
        dedupeKey: { ...this.state.dedupeKey, template_output: '' },
      });
    } catch (err: any) {
      this.setState({
        eventState:
          isAlertSourceConfigured || (!isAlertSourceConfigured && this.props.mode !== 'create')
            ? 'noEvents'
            : 'notConfigured',
        event: {},
        dedupeKey: { ...this.state.dedupeKey, template_output: '' },
      });
    }
  };

  public getDedupeKeyForAlertSource = async () => {
    this.setState({ errors: [], fetching: true });
    try {
      const { data } = await this.ServiceService.getDedupeKeyForAlertSource(
        this.props.alertSourceName ?? '',
      );

      const dedupeKeyData: IDedupeKeyData = data.data;

      const isDurationInHours = dedupeKeyData.overlay.duration % 60 === 0;

      this.setState(({ dedupeKey }) => {
        dedupeKey.key = dedupeKeyData.overlay.template;
        dedupeKey.time_window = isDurationInHours
          ? dedupeKeyData.overlay.duration / 60
          : dedupeKeyData.overlay.duration;
        dedupeKey.time_unit = isDurationInHours ? IRuleTimeUnit.Hour : IRuleTimeUnit.Minute;
        return { dedupeKey, fetching: false, networkError: '' };
      });
    } catch (err: any) {
      this.setState({
        fetching: false,
        networkError: err?.response?.data?.meta?.error_message ?? 'Network Error',
      });
    }
  };

  public getDedupeKeyOutput = async () => {
    this.setState({ errors: [], parsing: true });
    try {
      const { data } = await this.ServiceService.getDedupeKeyOutput(
        this.state.dedupeKey.key,
        JSON.stringify(this.state.event.past),
      );

      const template_output: string = data.data;

      this.setState(({ dedupeKey }) => {
        dedupeKey.template_output = template_output;
        return { dedupeKey, parsing: false, networkError: '' };
      });

      AppTracker.track(T_WA_UP_DEDUPE_KEYS_SHOW_OUTPUT_BUTTON_CLICKED);
    } catch (err: any) {
      this.setState({
        parsing: false,
        networkError: err?.response?.data?.meta?.error_message ?? 'Network Error',
      });
    }
  };

  public deleteDedupeKey = async () => {
    if (this.props.alertSourceName) {
      this.setState({ errors: [], deleting: true });
      try {
        await this.ServiceService.deleteDedupeKeyForAlertSource(this.props.alertSourceName);

        this.setState({ deleting: false, networkError: '' });
      } catch (err: any) {
        this.setState({
          deleting: false,
          networkError: err?.response?.data?.meta?.error_message ?? 'Network Error',
        });
      }
    }
  };

  public save = async () => {
    this.setState({ errors: [], saving: true });

    const {
      alertSource,
      dedupeKey: { key, time_unit, time_window },
    } = this.state;

    const alertSourceName = (alertSource?.shortName ?? '').toLowerCase();

    let dedupeKeyDuration = 0;
    switch (time_unit) {
      case IRuleTimeUnit.Hour:
        dedupeKeyDuration = time_window * 60;
        break;
      case IRuleTimeUnit.Minute:
        dedupeKeyDuration = time_window;
        break;
    }

    const dedupeKeyInput: IDedupeKeyInput = {
      overlay_template_type: 'go',
      dedup_key_overlay: {
        template: key,
        duration: dedupeKeyDuration,
      },
    };

    try {
      if (!this.props.alertSourceName) {
        await this.context.createRule.mutateAsync({
          ruleType: RoutingRules.dedupekey,
          ruleDetail: dedupeKeyInput,
          alertSourceName,
        });

        AppTracker.track(T_WA_UP_DEDUPE_KEYS_KEY_CREATED);
      } else {
        await this.context.updateRule.mutateAsync({
          ruleType: RoutingRules.dedupekey,
          ruleDetail: dedupeKeyInput,
          alertSourceName: this.props.alertSourceName,
        });
      }
      this.setState({ saving: false, networkError: '' });
      this.props.hide();

      this.props.refetchRule();
    } catch (err: any) {
      this.setState({
        saving: false,
        networkError: err?.response?.data?.meta?.error_message ?? 'Network Error',
      });
    }
  };

  onSelectChange = (_: any, alertSource: IAlertSource) => {
    this.getAlertSourceConfig(alertSource);
    this.props.checkAndSetDirty(true);
  };

  onTextChange = (type: 'alertSourceSearch') => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [type]: event.target.value });
    this.props.checkAndSetDirty(true);
  };

  onChangeDedupeKeyTemplate = (value: string) => {
    if (!(this.props.alertSourceName && value === this.state.dedupeKey.key)) {
      this.props.checkAndSetDirty(true);
    }
    if (value.trim()) {
      this.setState(({ dedupeKey }) => {
        dedupeKey.key = value;
        dedupeKey.template_output = '';
        return { dedupeKey, networkError: '' };
      });
    } else {
      this.setState(({ dedupeKey }) => {
        dedupeKey.key = '';
        dedupeKey.template_output = '';
        return { dedupeKey, networkError: '' };
      });
    }
  };

  onChangeDedupeKeyTimeUnit = (_: React.KeyboardEvent<HTMLDivElement>, value: IRuleTimeUnit) => {
    this.setState(({ dedupeKey }) => {
      dedupeKey.time_unit = value;
      return { dedupeKey, networkError: '' };
    });
    this.props.checkAndSetDirty(true);
  };

  onChangeDedupeKeyDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.abs(parseInt(event.target.value, 10));

    this.setState(({ dedupeKey }) => {
      dedupeKey.time_window = value;
      return { dedupeKey, networkError: '' };
    });
    this.props.checkAndSetDirty(true);
  };

  enableEditMode = () => {
    this.setState({ enableEditKey: true });
  };

  validateDuration = () => {
    const {
      dedupeKey: { time_unit, time_window },
      errors,
    } = this.state;

    let dedupeKeyDuration = 0;
    switch (time_unit) {
      case IRuleTimeUnit.Hour:
        dedupeKeyDuration = time_window * 60;
        break;
      case IRuleTimeUnit.Minute:
        dedupeKeyDuration = time_window;
        break;
    }

    if (
      (dedupeKeyDuration > 48 * 60 || time_window === 0) &&
      !isNaN(time_window) &&
      !errors.find(err => err === 'Invalid duration')
    ) {
      this.setState({ errors: [...errors, 'Invalid duration'] });
    } else if (
      (dedupeKeyDuration <= 48 * 60 &&
        time_window > 0 &&
        errors.find(err => err === 'Invalid duration')) ||
      (isNaN(time_window) && errors.find(err => err === 'Invalid duration'))
    ) {
      this.setState({ errors: [...errors.filter(err => err !== 'Invalid duration')] });
    }
  };

  componentDidUpdate() {
    if (
      this.props.alertSourceName &&
      this.props.alertSourceName !== this.state.alertSource?.shortName
    ) {
      this.getDedupeKeyForAlertSource();
      const alertSource = this.alertSources.find(
        (altSrc: IAlertSource) => altSrc.shortName === this.props.alertSourceName,
      );
      alertSource && this.getAlertSourceConfig(alertSource);
    }
    this.validateDuration();
  }
}

export default connect(({ organization, integrations }: IAppState) => ({
  organization,
  integrations,
}))(DedupeKeysModal);
