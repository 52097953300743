import { INIT_APP, INIT_APP_FAIL, INIT_APP_SUCCESS, RESET_STATE } from '../const/init';
import { INITIAL_STATE } from './state';

const initialAppState = INITIAL_STATE.INTI_APP;

const initApp = (state = initialAppState, action: any) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        ...{ sessionId: '' },
      };
    case INIT_APP:
      return {
        ...state,
        ...{ sessionId: action.payload.sessionId },
      };
    case INIT_APP_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case INIT_APP_FAIL:
      return {
        ...state,
        ...action.payload.error,
      };
    default:
      return state;
  }
};

export default initApp;
