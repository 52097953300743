import { HELP_AND_GUIDE_NODE } from '../../types';

export const gerHelpAndGuideData: Partial<HELP_AND_GUIDE_NODE>[] = [
  {
    title: 'What are Global Event Rulesets?',
    desc: [
      'Global Event Rulesets let you create rulesets for alert routing, eliminating the need for individual alert source webhooks setup for each Service. This centralized routing simplifies configuration management, saving time and enhancing efficiency, particularly for users dealing with numerous micro-services.',
    ],
    note: {
      heading: 'Note:',
      desc: 'The scope for a Ruleset is the currently selected Team.',
      points: [
        'Start off by adding a Ruleset',
        'Within the details page of the created Ruleset, you can map one or more alert sources that will send alerts to the common endpoint',
        'Define Rules for each of the mapped alert sources. Each Rule consists of an expressions (evaluation condition) and an action (routing to a destination / target Service)',
        'We highly recommend that the Catch All Service is also configured, to ensure no incoming alerts are dropped when none of the defined Rules match',
      ],
    },
    type: 'help',
  },
  {
    note: {
      heading: 'Important:',
      desc: '',
      points: [
        'The payload you see on the right may be a sample payload provided by Squadcast for the selected alert source, if you have not set up alert source webhooks and started receiving alerts yet. If the webhooks have been set up and you are receiving alerts, then you will see the payload of the latest alert for that alert source',
        'Also note that, if alert sources support multiple types of payloads for different events, please ensure you refer to the documentation of your alert source for the different payload structures',
        'You will see only the Services for the selected Team',
      ],
    },
    type: 'help',
  },
  {
    note: {
      heading: 'Limits:',
      desc: 'Each Team can have up to 30 Rulesets. Each alert source can have up to a 1000 Rules.',
      points: [],
    },
    type: 'help',
  },
  {
    link: [
      {
        label: 'Read More',
        url: 'https://support.squadcast.com/global-event-rulesets/global-event-rulesets',
      },
    ],
    desc: ['If you need help with something, use the chat widget below to reach us.'],
    type: 'queries',
  },
];
