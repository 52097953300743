import React from 'react';
import { Para, Theme } from 'uie/components';
import { EntityType, hasReadAccessToEntity, JSXResponseForEntity } from '../helpers';
import { useUserAccess } from 'core/userAccess/UserAccessContext';

const ResponsesForActionIncidentUnsnooze: React.FC<
  Pick<JSXResponseForEntity, 'log' | 'getEntityComponentWithTruncatedName'> & {
    triggerType: string;
  }
> = ({ log, triggerType, getEntityComponentWithTruncatedName }) => {
  const { theme: legacyTheme } = Theme;
  const acceptableEntityTypesForPopover = ['user', 'service'];
  const userHasReadSquadsPermission = useUserAccess().hasReadAccess('squads');

  const assignedToEntityName = log.reason.split('to')?.[1] || '';
  const assignedByEntityName = log.reason.split('by')[1];
  const canNavigateByAssignedToEntity = assignedByEntityName !== 'Deleted Entity';

  const assignedByEntityComponent = canNavigateByAssignedToEntity ? (
    getEntityComponentWithTruncatedName({
      componentType: 'linkWPopover',
      name: assignedByEntityName?.split('and')[0],
      type: 'user',
      popoverPlacement: 'left',
      id: log.assignedBy,
    })
  ) : (
    <>{assignedByEntityName}</>
  );

  const canNavigateToAssignedToEntity =
    assignedToEntityName !== 'Deleted Entity' &&
    hasReadAccessToEntity(log.assignedTo as EntityType);
  const assignedToEntityComponent = canNavigateToAssignedToEntity ? (
    getEntityComponentWithTruncatedName({
      componentType: acceptableEntityTypesForPopover.includes(log.assignedTo)
        ? 'linkWPopover'
        : log.assignedTo === 'squad'
        ? userHasReadSquadsPermission
          ? 'linkWOPopover'
          : 'simple'
        : 'linkWOPopover',
      name: assignedToEntityName,
      type: log.assignedTo as EntityType,
      id: log.id,
      popoverPlacement: 'left',
    })
  ) : (
    <>{assignedToEntityName}</>
  );
  if (triggerType === 'auto' && !log.additionalInfo?.is_unsnooze_action) {
    return (
      <Para fontSize={14} color={legacyTheme.shades.cement}>
        {log.reason.split('by')[0]} by {assignedByEntityComponent}
      </Para>
    );
  } else {
    return (
      <Para fontSize={14} color={legacyTheme.shades.cement}>
        {log.reason.split('by')[0]} by {assignedByEntityComponent}
        {assignedByEntityComponent ? ' and reassigned to ' : ''}
        {assignedToEntityComponent ?? <></>}
      </Para>
    );
  }
};

export default ResponsesForActionIncidentUnsnooze;
