import { FileUploadService } from 'core';
import { FileUploadFeature, OperationType } from 'core/services/service.fileUpload';

export type entity = {
  _id: string;
  name: string;
  teamId?: string;
  type: string;
  encoder: string;
};

export type EntityRecord = {
  users: Record<string, entity>;
  squads: Record<string, entity>;
  teams: Record<string, entity>;
  stakeholders: Record<string, entity>;
};

export interface IProps {
  sendMessage: (message: string) => void;
  fileUploadService: FileUploadService;
  entities: EntityRecord;
  onCallUsers: Set<string>;
  initialMessage?: string;
  setEditMessageId?: (messageId: string) => () => void;
  position?: 'top' | 'bottom';
  onMessageChange?: (message: string) => void;
  hideSendButton?: boolean;
  featureName: FileUploadFeature;
  operationType: OperationType;
  placeHolder?: string;
}

export interface MsgRef {
  pos: CodeMirror.Position;
  replace: string;
  lineNumber: number;
  name: string;
  _id: string;
  type: 'users' | 'squads' | 'teams' | 'stakeholders';
  replacer: string;
}

export interface IState {
  message: string;
  position: 'top' | 'bottom';
  suggestions: {
    name: string;
    _id: string;
    type: 'users';
    encoder: string;
    displayType: 'user' | 'squad' | 'team' | 'stakeholder';
  }[];
  autoSuggestFocusState: { currentIndex: number; maxIndex: number };
  messageBoxHeight: 'auto' | '100%';
  messageRefs: {
    [key: string]: {
      pos: CodeMirror.Position;
      replace: string;
      lineNumber: number;
      name: string;
      _id: string;
      type: 'users' | 'squads' | 'teams' | 'stakeholders';
      replacer: string;
    };
  };
  cursorPos: CodeMirror.Position;
}

export interface AutoSuggestionDisplayConfig {
  [key: string]: {
    displayAs: string;
    icon: string;
  };
}

export enum NewLineState {
  Added,
  Deleted,
  NoChange,
}
