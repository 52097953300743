import React from 'react';
import { EntityType, hasReadAccessToEntity, JSXResponseForEntity } from '../helpers';
import { Para, Theme } from 'uie/components';
import { useUserAccess } from 'core/userAccess/UserAccessContext';

const ResponsesForIncidentSnoozed: React.FC<
  Omit<JSXResponseForEntity, 'getEntityComponent' | 'getEntityName'>
> = ({ log, logMessage, getEntityComponentWithTruncatedName }) => {
  const { theme: legacyTheme } = Theme;
  const acceptableEntityTypesForPopover = ['user', 'service'];
  const userHasReadSquadsPermission = useUserAccess().hasReadAccess('squads');

  const regex = logMessage.includes(' by ')
    ? /Incident snoozed till (.*) by/
    : /Incident snoozed till (.*)/;
  const assignedToEntityName = logMessage.match(regex)?.[1] || '';
  const assignedByEntityName = logMessage.split(' by ')[1];
  const assignedByEntityComponent =
    assignedByEntityName !== 'Deleted Entity' ? (
      getEntityComponentWithTruncatedName({
        componentType: 'linkWPopover',
        name: assignedByEntityName,
        type: 'user',
        popoverPlacement: 'left',
      })
    ) : (
      <>{assignedByEntityName}</>
    );

  const canNavigateToAssignedToEntity =
    assignedToEntityName !== 'Deleted Entity' &&
    hasReadAccessToEntity(log.assignedTo as EntityType);
  const assignedToEntityComponent = canNavigateToAssignedToEntity ? (
    getEntityComponentWithTruncatedName({
      componentType: acceptableEntityTypesForPopover.includes(log.assignedTo)
        ? 'linkWPopover'
        : log.assignedTo === 'squad'
        ? userHasReadSquadsPermission
          ? 'linkWOPopover'
          : 'simple'
        : 'linkWPopover',
      name: assignedToEntityName,
      type: log.assignedTo as EntityType,
      id: log.id,
      popoverPlacement: 'left',
    })
  ) : (
    <>{assignedToEntityName}</>
  );
  return (
    <Para fontSize={14} color={legacyTheme.shades.cement}>
      {log.reason.split(',')[0]}
      {assignedByEntityComponent ? ' by ' : ''}
      {assignedToEntityComponent ?? <></>}
    </Para>
  );
};

export default ResponsesForIncidentSnoozed;
