import { Box, Text } from '@chakra-ui/layout';
import { TAG_VARIANTS } from 'library/atoms/Tag/Tag';
import TagStack from 'library/atoms/Tag/TagStack';
import { SloAffectingAction } from 'views/main/organization/workflow/types';
interface Props {
  action: SloAffectingAction;
}

const SloAffectingCard = ({ action }: Props) => {
  return (
    <Box mt={2}>
      <Text color="secondary.700" variant="h4" textStyle="bold_800">
        SLO
      </Text>
      <Text variant="h3" mb={3}>
        {action.data.slo?.name}
      </Text>
      <Text color="secondary.700" variant="h4" textStyle="bold_800" mb={2}>
        SLI'S
      </Text>
      <TagStack
        tags={action.data.sli}
        variant={TAG_VARIANTS.PLAIN}
        defaultLength={action.data.sli.length}
        onClose={() => {}}
      />
    </Box>
  );
};

export default SloAffectingCard;
