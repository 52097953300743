import SecondaryFilterProvider from 'components/SecondaryFilter/context';
import { Page } from './behaviors/Page';
import { PostmortemsListing } from './PostmortemListing';
import { PostmortemDetail } from './PostmortemDetail';

const PostmortemsListingPage = () => (
  <Page pricingEntity="postmortem">
    <SecondaryFilterProvider
      defaultFilters={{ alert_sources: [], services: [], postmortem_status: [] }}
    >
      <PostmortemsListing />
    </SecondaryFilterProvider>
    <PostmortemDetail />
  </Page>
);

export default PostmortemsListingPage;
