import { Box, Button, Flex, FormControl, HStack, Input, Text, VStack } from '@chakra-ui/react';
import { Field, FormikProvider, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { PAGE_STATUS_LABELS } from '../../constants/status.constants';

import { useStausTypeList } from '../../hooks/useStatusTypeList';
import { IPageStatus, IStatusCode } from '../../Interface';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_EDITED } from 'core/const/tracker';

interface Props {
  pageId: number;
  onClose: () => void;
  isEdit?: boolean;
  onSuccess: () => void;
  onEdit?: () => void;
  canEdit: boolean;
}

interface FormProps {
  onClose: () => void;
  isEdit?: boolean;
  onEdit?: () => void;
  canEdit: boolean;
}

const StatusMessageForm = (props: FormProps) => {
  const { submitForm, values }: any = useFormikContext();

  return (
    <Box data-testid={props.isEdit ? 'message-form' : 'summary-info'} mb={10}>
      <form>
        <Text fontWeight={500} color={'gray.600'} fontSize={14}>
          The following messages will reflect at both - status page header and components
        </Text>
        <Flex mt={9}>
          <Flex flex={1}>
            <Text fontWeight={500} color={'gray.600'}>
              STATUS
            </Text>
          </Flex>
          <Flex flex={1}>
            <Text fontWeight={500} color={'gray.600'}>
              MESSAGE
            </Text>
          </Flex>
        </Flex>
        {values.status.map((status: IStatusCode, index: number) => {
          return (
            <Flex
              key={status.id}
              py={4}
              borderBottomWidth={1}
              align="center"
              data-testid="statuses"
            >
              <Flex flex={1} direction="column" mr={4}>
                <Flex flex={1} direction="row" align={'center'}>
                  <Box bgColor={status?.color} w={3} h={3} borderRadius={6} mr={2} />
                  <Text>{PAGE_STATUS_LABELS[status.slug]}</Text>
                </Flex>
                <Text color={'gray.600'} fontSize={12} ml={4}>
                  {status.description}
                </Text>
              </Flex>
              <Flex flex={1}>
                {!props.isEdit ? (
                  <Text>{status.message}</Text>
                ) : (
                  <FormControl>
                    <Field
                      as={Input}
                      type="text"
                      name={`status[${index}].message`}
                      placeholder="message"
                    />
                  </FormControl>
                )}
              </Flex>
            </Flex>
          );
        })}
        <Box w="100%" bottom={0} position="fixed" bgColor={'white'} zIndex="2" py={3}>
          {props.isEdit ? (
            <HStack spacing={6}>
              <Button onClick={submitForm} data-testid="save-form">
                Save
              </Button>
              <Button variant="invertedDefault" onClick={props.onClose}>
                Cancel
              </Button>
            </HStack>
          ) : null}
          {props.canEdit && !props.isEdit ? (
            <Button onClick={() => props.onEdit && props.onEdit()} data-testid="edit-btn">
              Edit status message
            </Button>
          ) : null}
        </Box>
      </form>
    </Box>
  );
};
const StatusMessage = (props: Props) => {
  const { formik } = useStausTypeList(props.pageId, () => {
    props.onSuccess();

    AppTracker.track(T_WA_UP_STATUS_PAGE_V2_EDITED);
  });

  return (
    <FormikProvider value={formik}>
      <StatusMessageForm
        onClose={props.onClose}
        isEdit={props.isEdit}
        onEdit={props.onEdit}
        canEdit={props.canEdit}
      />
    </FormikProvider>
  );
};

export default StatusMessage;
