import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from '@chakra-ui/react';
import Editor, { OnMount } from '@monaco-editor/react';
import { READONLY_MONACO_EDITOR_OPTIONS } from 'views/shared/constants';

const AccordionViewMode = ({
  incidentMessage,
  incidentDesc,
  handleEditorDidMount,
}: {
  incidentMessage: string;
  incidentDesc: string;
  handleEditorDidMount: OnMount;
}) => {
  return (
    <Box
      display="flex"
      flexDirection={'column'}
      border="1px solid #DADADA"
      borderRadius="8px"
      width="100%"
      mt={'20px'}
      minHeight={'600px'}
      alignItems="flex-start"
    >
      <Accordion allowMultiple defaultIndex={[0]} width="100%">
        <AccordionItem border="none">
          <Flex w="100%">
            <AccordionButton
              color="default"
              p={2}
              pt={0}
              w="100%"
              _focus={{ boxShadow: 'none' }}
              justifyContent="flex-start"
            >
              <Flex ml={5}>
                Incident Message
                <AccordionIcon ml={1.5} />
              </Flex>
            </AccordionButton>
          </Flex>
          <AccordionPanel>
            <Editor
              onMount={handleEditorDidMount}
              height="213px"
              language="json"
              value={incidentMessage}
              options={READONLY_MONACO_EDITOR_OPTIONS}
              width="100%"
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border="none">
          <Flex w="100%">
            <AccordionButton
              color="default"
              p={2}
              pt={0}
              w="100%"
              backgroundColor="inherit"
              _focus={{ boxShadow: 'none' }}
              justifyContent="flex-start"
            >
              <Flex ml={5}>
                Incident Description
                <AccordionIcon ml={1.5} />
              </Flex>
            </AccordionButton>
          </Flex>
          <AccordionPanel>
            <Editor
              onMount={handleEditorDidMount}
              height="213px"
              language="json"
              value={incidentDesc}
              options={READONLY_MONACO_EDITOR_OPTIONS}
              width="100%"
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default AccordionViewMode;
