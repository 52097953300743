import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { FormikProvider } from 'formik';
import React, { useRef, useState } from 'react';

import { reactQueryConfigSuccess } from '../../../helpers/helper.toast';
import { usePageMaintenance } from '../../../hooks/usePageMaintenance';
import MaintenanceForm from '../form';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_MAINTENANCE_EDITED } from 'core/const/tracker';

interface Props {
  pageId: number;
  onClose: () => void;
  maintenanceId: number;
  refetchMaintenanceList: () => void;
  disabled: boolean;
}

const UpdateMaintenance: React.FC<any> = (props: Props) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { formik, isSuccess } = usePageMaintenance(
    props.pageId,
    () => {
      props.refetchMaintenanceList();
      reactQueryConfigSuccess('Maintenance window is updated');

      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_MAINTENANCE_EDITED);
      props.onClose();
    },
    props.maintenanceId,
  );

  const onCancel = () => setShowConfirmDialog(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  return isSuccess ? (
    <FormikProvider value={formik}>
      <form>
        <MaintenanceForm disabled={props.disabled} />

        <Box
          w="100%"
          bottom={0}
          position="fixed"
          bgColor={'white'}
          zIndex="2"
          hidden={props.disabled}
        >
          <Divider />
          <HStack px={6} py={2} gap={4}>
            <Button
              variant="default"
              onClick={() =>
                formik.validateForm().then(errors => {
                  if (Object.keys(errors).length === 0) {
                    setShowConfirmDialog(true);
                  } else {
                    console.error('Errors:', errors);
                  }
                })
              }
            >
              Apply
            </Button>
            <Button variant="invertedDefault" onClick={props.onClose}>
              Cancel
            </Button>
          </HStack>
        </Box>
        <AlertDialog
          id="maintenance"
          isOpen={showConfirmDialog}
          onClose={onCancel}
          leastDestructiveRef={cancelRef}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Save changes
              </AlertDialogHeader>

              <AlertDialogBody>
                <Text>Are you sure you want to save the changes?</Text>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button variant="invertedDefault" onClick={onCancel} ref={cancelRef}>
                  Cancel
                </Button>
                <Button onClick={formik.submitForm} ml={3}>
                  Save
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </form>
    </FormikProvider>
  ) : (
    <Center h="60vh">
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
    </Center>
  );
};
export default UpdateMaintenance;
