import * as React from 'react';
import { SVGProps } from 'react';

const SvgInstana = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="instana_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.instana_svg__st3{fill:#22d8d8}'}</style>
    <g id="instana_svg__g844" transform="translate(-13.86 81.32) scale(.11488)">
      <path
        id="instana_svg__path2"
        d="m281.9-630.7-.8-.2c.3-3.2.4-6.5.3-9.7-.1-3.4-1.1-17.4-3.9-25.7-1.4-4.1-2.9-8.1-5.7-11.7-.3-.4-.6-.8-1-1.2-.3-.4-1.1-.1-.9.5 3.6 15.5 3.4 32.7.9 48.1-1.7 10-4 21.7-9.2 30.3-5.8 9.5-16.5 11.7-26.2 13.2-9.2 1.4-27.7 1.6-27.7 1.6s-18.6-.2-27.7-1.6c-9.6-1.5-20.3-3.7-26.2-13.2-5.2-8.6-7.6-20.3-9.2-30.3-2.5-15.4-2.7-32.6.9-48.1.1-.5-.6-.9-.9-.5-.3.4-.7.8-1 1.2-2.8 3.6-4.3 7.5-5.7 11.7-2.8 8.3-3.8 22.4-3.9 25.7-.1 3.2.1 6.5.3 9.7l-.8.2c-1.5.3-2.4 1.5-2.2 2.8l4.7 29.6c.2 1.3 1.5 2.2 3 2.2l3.4-.1c.7 1.6 1.5 3.2 2.4 4.8 2.1 3.8 5.1 6.5 8.7 8.7 3.5 2.1 7.3 3.2 11.3 4.1 4.6 1 9.2 1.7 13.9 2.2 1.2.2 3.3.4 4.6.6.3 0 .4.5.1.6-3.7 1.1-11.4 4.7-14.8 7.9-3.1 2.8-6 5.8-8.3 9.2-2 2.9-3.9 6-4.8 9.6v.1c-.7 3 1 6.1 4 7.1 14.8 5 31.2 7.7 48.3 7.7 17.2 0 33.5-2.8 48.3-7.7 2.9-1 4.7-4.1 4-7.1v-.1c-.9-3.5-2.7-6.6-4.8-9.6-2.4-3.4-5.2-6.4-8.3-9.2-3.4-3.2-11.2-6.8-14.8-7.9-.3-.1-.2-.5.1-.6 1.3-.2 3.4-.4 4.6-.6 4.7-.6 9.3-1.2 13.9-2.2 3.9-.9 7.8-2 11.3-4.1 3.7-2.2 6.7-4.9 8.8-8.7.9-1.6 1.7-3.2 2.4-4.8l3.4.1c1.5 0 2.8-.9 3-2.2l4.7-29.6c0-1.3-1-2.4-2.5-2.8"
        style={{
          fill: '#b7c1c6',
        }}
      />
      <linearGradient
        id="instana_svg__path9_00000148663303478613292920000006539696470713817739_"
        gradientUnits="userSpaceOnUse"
        x1={21176.945}
        y1={-30026.016}
        x2={21176.945}
        y2={-29983.992}
        gradientTransform="matrix(.1149 0 0 -.1149 -2225.098 -3983.19)"
      >
        <stop
          offset={0.602}
          style={{
            stopColor: '#b7c1c6',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#869196',
          }}
        />
      </linearGradient>
      <path
        id="instana_svg__path9"
        style={{
          fill: 'url(#instana_svg__path9_00000148663303478613292920000006539696470713817739_)',
        }}
        d="M260-548.6c-.9-3.5-2.7-6.6-4.8-9.6-2.4-3.4-5.2-6.4-8.3-9.2-3.4-3.2-11.2-6.8-14.8-7.9-.3-.1-.2-.5.1-.6 1.3-.2-12.7.5-24.4.5s-25.7-.7-24.4-.5c.3 0 .4.5.1.6-3.7 1.1-11.4 4.7-14.8 7.9-3.1 2.7-6 5.7-8.3 9.2-2 2.9-3.9 6-4.8 9.6v.1c-.8 3 1 6.1 4 7.1 14.8 5 31.2 7.7 48.3 7.7s33.5-2.8 48.3-7.7c2.8-1.1 4.6-4.2 3.8-7.2z"
      />
      <path
        id="instana_svg__path11"
        d="M142.8-662.4c-.8 11.8-.1 23.5 2.2 35.1 2.1 10.7 3.8 21.9 11.1 30.7 6.2 7.5 17.4 9.6 26.2 10.7 8.4 1 25.3 1.2 25.3 1.2s16.9-.2 25.3-1.2c8.9-1 20-3.2 26.2-10.7 7.3-8.8 9-20 11.1-30.7 2.3-11.5 3-23.3 2.2-35.1-.3-4.5-.9-9.1-1.7-13.6-.5-2.8-1.9-5.4-4-7.3-2.6-2.3-5.9-5.1-10.4-8.3-4.2-3-8.5-5.8-13.2-7.9-6.1-2.8-12.3-5-18.8-6.4-5.1-1.1-12.2-2-16.9-2-4.6 0-11.8.9-16.9 2-6.5 1.4-12.7 3.6-18.8 6.4-4.7 2.1-9 4.9-13.2 7.9-4.5 3.2-7.8 6-10.4 8.3-2.1 1.9-3.5 4.5-4 7.3-.4 4.5-1 9.1-1.3 13.6"
        style={{
          fill: '#172429',
        }}
      />
      <path
        id="instana_svg__path13"
        className="instana_svg__st3"
        d="M171.6-623.8c4.1-.3 7.6 3.7 8 8.8.4 5.1-2.6 9.6-6.7 9.8-4.1.3-7.6-3.7-8-8.8-.4-5.1 2.6-9.5 6.7-9.8"
      />
      <path
        id="instana_svg__path15"
        className="instana_svg__st3"
        d="M242.6-605.1c4.1.2 7.7-3.7 8.1-8.9.4-5.2-2.6-9.6-6.6-9.8-4.1-.2-7.7 3.7-8.1 8.9s2.5 9.5 6.6 9.8"
      />
      <linearGradient
        id="instana_svg__path22_00000009563644278203430720000001176194868993523329_"
        gradientUnits="userSpaceOnUse"
        x1={20633.75}
        y1={-28817.295}
        x2={20758.217}
        y2={-28817.295}
        gradientTransform="matrix(.1149 0 0 -.1149 -2225.098 -3983.19)"
      >
        <stop
          offset={0}
          style={{
            stopColor: '#43565e',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#172429',
          }}
        />
      </linearGradient>
      <path
        id="instana_svg__path22"
        style={{
          fill: 'url(#instana_svg__path22_00000009563644278203430720000001176194868993523329_)',
        }}
        d="M191.6-703.3c-6.2 1.3-12.2 3.5-18 6.1-4.5 2-8.6 4.7-12.6 7.5-4.3 3.1-7.5 5.7-10 8-2 1.8-3.3 4.3-3.8 7-.8 4.3-1.3 8.7-1.6 13.1-.5 7.2-.3 14.5.4 21.7 22.7-18.5 51.8-29.6 83.5-29.6 14.1 0 27.6 2.2 40.3 6.2-.3-3.8-.8-7.6-1.4-11.4-.5-2.7-1.8-5.1-3.8-7-2.5-2.2-5.7-4.9-10-8-4-2.9-8.1-5.5-12.6-7.5-5.8-2.6-11.8-4.8-18-6.1-4.8-1-11.8-1.9-16.2-1.9-4.4-.1-11.4.8-16.2 1.9"
      />
    </g>
  </svg>
);

export default SvgInstana;
