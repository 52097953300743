import Axios from 'axios';
import { API } from 'core';
import { EntityACL } from 'core/userAccess/types';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';
import { useToast } from 'library/atoms';
import { QUERY_CONFIG } from 'library/query-config';
import { QueryClient, useQuery } from 'react-query';
import { GER_DATA } from '../types';

import { QUERY_KEY } from './queryKeys';

const getDetail = async (id: string) => {
  const response = await Axios.get<{ data: GER_DATA; meta: { acl: EntityACL } }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/global-event-rules/${id}`,
  );
  const acl = response.data.meta.acl;

  setUserAccessStore({
    entityACLMap: {
      ...getUserAccessStore().entityACLMap,
      ger: {
        ...getUserAccessStore().entityACLMap?.ger,
        ...acl,
      },
    },
  });

  return response;
};

export const useGetGerDetail = (id: string) => {
  const toast = useToast();
  const { data, isSuccess, isError, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEY.GER_DETAIL, id],
    queryFn: () => getDetail(id),
    ...QUERY_CONFIG,
    ...{ enabled: !!id },
    onError: () => {
      toast({
        status: 'error',
        text: 'Error: Unable to fetch details, please try after some time',
      });
    },
  });
  const response = data?.data;
  return {
    isSuccess,
    isError,
    isLoading,
    isFetching,
    ...(response?.data ? { data: response.data } : {}),
  };
};

export const invalidateGERDetail = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(QUERY_KEY.GER_DETAIL);
};
