import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { IAppError } from 'api/errors';
import {
  analyticsLastUpdated,
  analyticsPageBFF,
  AnalyticsPageData,
  AnalyticsUpdatedData,
} from 'api/organization/bff';
import { API } from 'core';
import equal from 'fast-deep-equal/es6/react';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { generatePath, Link, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AppConfig } from 'shared/app.config';
import {
  ANALYTICS_V2_ORGANIZATION_PATH,
  ANALYTICS_V2_PATH,
  ANALYTICS_V2_TEAMS_PATH,
  ANALYTICS_V2_TEAM_PATH,
  ANALYTICS_V2_USERS_PATH,
} from 'views/main/routes/routes';
import ErrorPage from './ErrorPage/ErrorPage';
import { FiltersProvider } from './Filters/Filters.context';
import './index.css';
import Loader from './Loader/Loader';
import OrgAnalytics from './OrgAnalytics/OrgAnalytics';
import Sidebar, { ISidebarItem } from './Sidebar/Sidebar';
import TeamAnalytics from './TeamAnalytics/TeamAnalytics';
import { Filters } from './types';
import UserAnalytics from './UserAnalytics/UserAnalytics';
import { T_WA_UP_ANALYTICS_V2_PAGE_VIEWED } from '../../../../core/const/tracker';
import { AppTracker } from '../../../../shared/analytics/tracker';
import SecondaryFilterProvider from 'components/SecondaryFilter/context';
import { ChakraProvider } from '@chakra-ui/react';
import libraryTheme from 'library/theme';

const Handler404 = () => (
  <>
    <h1>404 not found</h1>
    <br />
    <p>
      Go&nbsp;
      <Link to={ANALYTICS_V2_PATH} className="no_underline">
        back
      </Link>
      &nbsp;to the main page.
    </p>
  </>
);

export const UserBelongsToNoTeamsAndNoOrgLevelPermissions = () => (
  <>
    <h1>Access denied</h1>
    <br />
    <p>
      This is awkward. Sorry about that. We found that you neither belong to a team nor have
      org-level permissions.
      <br />
      You might want to contact your team lead/admin to sort this out.
    </p>
  </>
);

function makeSidebarItems({ canUserAccessOrgLevelQueries, teams }: AnalyticsPageData) {
  let items: ISidebarItem[] = [];

  if (canUserAccessOrgLevelQueries) {
    items.push({ name: 'Organization', slug: 'organization', isChild: false });
  }

  if (teams.length) {
    items = items
      .concat([
        {
          name: 'Teams',
          slug: '',
          match: new RegExp(/(?:teams\?filters=)/),
          type: 'divider',
          isChild: false,
        },
      ])
      .concat(
        teams.map(team => ({
          name: team.name,
          slug: `teams?filters=${JSON.stringify({ teams: [team.id] })}`,
          match: new RegExp(
            new RegExp(/(?:%22teams%22:\[%22)/).source + team.id + new RegExp(/%22\]/).source,
          ),
          isChild: true,
        })),
      );
    // .concat([
    // { name: 'Services', slug: 'services' },
    // { name: 'User', slug: 'users' },
    // ]);
  }

  return items;
}

function findFirstAvailableAnalyticsPagePath({
  canUserAccessOrgLevelQueries,
  teams,
}: AnalyticsPageData) {
  if (canUserAccessOrgLevelQueries) {
    return ANALYTICS_V2_ORGANIZATION_PATH;
  }

  const firstTeam = teams[0];

  if (firstTeam) {
    return ANALYTICS_V2_TEAMS_PATH;
  }

  return null;
}

export const useLastUpdatedQuery = () => {
  return useQuery<AnalyticsUpdatedData, IAppError>(
    'analytics-last-updated',
    () => analyticsLastUpdated(API.config.batman, API.config.organizationId),
    {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );
};

const AnalyticsV2: React.FC<RouteComponentProps> = props => {
  const url = props.match.url;

  useEffect(() => {
    AppTracker.track(T_WA_UP_ANALYTICS_V2_PAGE_VIEWED);
  }, []);
  const { isLoading, error, data } = useQuery<AnalyticsPageData, IAppError>(
    'analyticsPage',
    () => analyticsPageBFF(API.config.batman, API.config.organizationId),
    {
      refetchOnWindowFocus: false,
    },
  );

  // This has been moved to the state because
  // of the data rentention limit field coming from B.E
  const [defaultFilters, setDefaultFilters] = useState<Filters>({
    date: {
      preset: '1_month',
      startDate: DateTime.now().minus({ months: 1 }),
      endDate: DateTime.now(),
      dataRetentionLimit: '',
    },
    teamIds: [],
    serviceIds: [],
    userIds: [],
    tags: [],
    priority: [],
    serviceOwnerIds: [],
  });

  useEffect(() => {
    if (data?.dataRetentionLimit) {
      const updatedFilters = {
        ...defaultFilters,
        date: {
          ...defaultFilters.date,
          //Save the data retention limit
          dataRetentionLimit: data.dataRetentionLimit ?? '',
        },
      };
      setDefaultFilters(updatedFilters);
    }
  }, [data]);

  const TeamAnalyticsCached = useCallback(() => {
    return <TeamAnalytics analyticsTeamList={data?.teams || []} />;
  }, [data?.teams]);

  if (isLoading) return <Loader />;

  if (error)
    return (
      <ErrorPage>
        <p>{error.error_message}</p>
      </ErrorPage>
    );

  const sidebarItems = makeSidebarItems(data!);

  const firstAvailableAnalyticsPagePath = findFirstAvailableAnalyticsPagePath(data!);

  const cubejsApi = cubejs(data!.cubeToken, {
    apiUrl: `${AppConfig.cube_url}/cubejs-api/v1`,
  });

  return (
    <SecondaryFilterProvider
      defaultFilters={{
        services: [],
        tags: [],
        users: [],
        priority: [],
        squads: [],
        serviceOwner: [],
        showServices: ['false'],
      }}
      isSingleSelection
    >
      <ChakraProvider theme={libraryTheme}>
        <CubeProvider cubejsApi={cubejsApi}>
          <FiltersProvider filters={defaultFilters}>
            <div id="org_settings__container">
              <h1>Analytics</h1>
              {sidebarItems.length ? (
                <Sidebar baseUrl={url} title="Analytics" items={sidebarItems} />
              ) : null}

              <Switch>
                <Route exact path={ANALYTICS_V2_PATH}>
                  {firstAvailableAnalyticsPagePath ? (
                    <Redirect to={firstAvailableAnalyticsPagePath} />
                  ) : (
                    <UserBelongsToNoTeamsAndNoOrgLevelPermissions />
                  )}
                </Route>
                <Route exact path={ANALYTICS_V2_ORGANIZATION_PATH} component={OrgAnalytics} />
                <Route path={ANALYTICS_V2_TEAMS_PATH} render={TeamAnalyticsCached} />
                {/* <Route path={ANALYTICS_V2_SERVICES_PATH} component={ServiceAnalytics} /> */}
                {/* <Route path={ANALYTICS_V2_USERS_PATH} component={UserAnalytics} /> */}
                <Route path="*" component={Handler404} />
              </Switch>
            </div>
          </FiltersProvider>
        </CubeProvider>
      </ChakraProvider>
    </SecondaryFilterProvider>
  );
};

const WrappedAnalyticsV2 = React.memo(AnalyticsV2, equal);

export default WrappedAnalyticsV2;
