import { TAG_VALUE_DROPDOWN_MAX_LENGTH } from 'core/const/immutables';

const stringShortener = (str = '', length: number) => {
  if (str.length > length) {
    return str.substring(0, length) + '...';
  }
  return str;
};

const toSentenceCase = (str = '') => {
  // eslint-disable-next-line
  return str.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, char => char.toUpperCase());
};

const truncate = (input: string, maxLength = 25) =>
  // +3 added as we are adding 3 dots too if it exceeds
  input?.length > maxLength + 3 ? `${input?.substring(0, maxLength)}...` : input;

const truncateTagLabel = (input: string) => truncate(input, TAG_VALUE_DROPDOWN_MAX_LENGTH);

/**
 * Adds 0 to the provided number to make it 2-digit
 * @param value Any numeric value
 * @returns
 */
const getPaddedValue = (value: number) => value.toString().padStart(2, '0');

export const pluralize = (count: number, singular: string, plural: string) => {
  return count === 1 ? singular : plural;
};

export { stringShortener, toSentenceCase, truncate, truncateTagLabel, getPaddedValue };
