import { Box, HStack, Spinner, Text } from '@chakra-ui/react';
import BulkActionButtons, { BulkActionsButtonsProps } from './BulkActionButtons';
import DownloadAndTagActions, { DownloadAndTagActionsProps } from './DownloadAndTagActions';
import { Tooltip } from 'library/atoms';
import { ErrorIcon } from 'library/icons';
import { nFormatter, toSentenceCase } from '../common/util';
import { INCIDENT_STATUS } from '../common/enums';
import { INVAL_COUNT } from '../common/constants';

type Props = DownloadAndTagActionsProps &
  BulkActionsButtonsProps & {
    showBulkActionButtons: boolean;
    count: number;
    isCountFetching: boolean;
    status: INCIDENT_STATUS;
  };

const ListToolbar = (props: Props) => {
  const { showBulkActionButtons, count, isCountFetching, status } = props;
  return (
    <Box
      p={4}
      pl={6}
      display="flex"
      flexDirection="row-reverse"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <DownloadAndTagActions {...props} />
      {showBulkActionButtons ? (
        <BulkActionButtons {...props} />
      ) : (
        <HStack>
          {isCountFetching ? (
            <Spinner color="brand.blue" size="xs" mr={4} />
          ) : (
            <>
              {count === INVAL_COUNT ? (
                <Tooltip
                  label="Accurate number of incidents is too large to fetch."
                  placement="bottom"
                >
                  <ErrorIcon width="12px" height="12px" />
                </Tooltip>
              ) : (
                <Text variant="formInput_800">{nFormatter(count)}</Text>
              )}
              <Text variant="formInput_800">
                &nbsp;{status !== INCIDENT_STATUS.ALL && `${toSentenceCase(status)} `}
                Incident{count > 1 && 's'}.&nbsp;
              </Text>
            </>
          )}
          <Text variant="formInput">Select one or more incidents to perform bulk actions</Text>
        </HStack>
      )}
    </Box>
  );
};

export default ListToolbar;
