import { Actions, Header, SearchInputField, SearchInputWrapper, Title, Wrapper } from './style';
import { SearchIcon } from 'icons';

type SearchInputProps = {
  placeholder: string;
  searchValue?: string;
  setSearchValue: (value: string) => void;
};
export const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  searchValue,
  setSearchValue,
}) => (
  <SearchInputWrapper>
    <SearchIcon height="16" width="16" />
    <SearchInputField
      type="text"
      placeholder={placeholder}
      value={searchValue}
      onChange={e => setSearchValue(e.target.value)}
      aria-label={placeholder}
    />
  </SearchInputWrapper>
);
