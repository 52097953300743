import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const SpinnerTheme: ComponentStyleConfig = {
  variants: {
    primary: {
      color: 'brand.white',
    },
    secondary: {
      color: 'brand.blue',
    },
    ghost: {
      color: 'brand.blue',
    },
    danger: {
      color: 'brand.white',
    },
    'ghost-danger': {
      color: 'brand.red',
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};
