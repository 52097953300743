import React from 'react';
import { StatusPage } from '.';
import { Row, Col, Href } from '@squadcast/alchemy-ui/carbon';
import { TextButton } from '@squadcast/alchemy-ui/metal';
import NewStatusPageModal from './modify.modal';
import cx from 'classnames';
import shortId from 'shortid';
import ConfirmModal from '../../../../components/confirm.modal';
import { Link } from 'react-router-dom';
import { DialogModalFrame, Grid, Para, Theme, Heading } from 'uie/components';
import UnsavedChangesGuard from '../../../../components/unsavedChangesGuard';
import { StatusPageIcon } from '../../../../icons';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { BillingService } from 'core';
import Tippy from '@tippy.js/react';
import UpgradeOnlyModal from 'components/upgradeonly.tooltip';

function render(this: StatusPage) {
  const _uA = this.props.userAccess;
  const hasCreate = _uA.hasCreateAccess('status_pages');
  const hasUpdate = _uA.hasUpdateAccess;
  const hasDelete = _uA.hasDeleteAccess;
  const { theme } = Theme;
  const limit = BillingService.getLimit(this.props, 'status-page');
  const isFeatureDisabled = BillingService.isFeatureDisabled(this.props, 'status-page');
  const onCancelAction = isFeatureDisabled
    ? () => (this.props as any).history.goBack()
    : () => this.setState({ showUpgradeModal: false });

  if (this.state.statusPages.length === 0) {
    return (
      <div className="main-container" onClick={() => this.setState({ showEditConfigId: '' })}>
        <div className={cx(['clearfix'], {})}>
          <div className="float-left">
            <h1 className="item-box-main-heading">Status Page</h1>
            <Heading
              fontSize={14}
              height={28}
              style={{
                fontWeight: 300,
                paddingBottom: '10px',
                marginBottom: '10px',
                fontSize: '12px',
                color: '#808080',
              }}
            >
              Status Page helps you communicate critical updates about outages and scheduled
              maintenance to your customers and stakeholders. Learn more about status page{' '}
              <a
                href="https://support.squadcast.com/status-page/status-page"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </Heading>
          </div>
          {hasCreate && (
            <Tippy
              content={
                this.state.showUpgradeToolTip ? (
                  <UpgradeOnlyModal
                    hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
                    align="left"
                    showModal={true}
                    message={BillingService.getMessage(limit, 'status-page', this.props)}
                    header={BillingService.getHeader(limit, 'status-page', this.props)}
                  />
                ) : (
                  ''
                )
              }
              interactive={true}
              arrow={true}
              animation={'scale'}
            >
              <div className="float-right" style={{ display: 'flex' }}>
                <div
                  className="ml-10"
                  onMouseOver={() => {
                    this.state.statusPageLimitExceeded
                      ? this.setState({ showUpgradeToolTip: true })
                      : {};
                  }}
                  style={this.state.statusPageLimitExceeded ? { opacity: 0.5 } : {}}
                >
                  <TextButton
                    id="new_status_page_es"
                    size="normal"
                    text="Add Status Page"
                    onClick={this.checkStatusPageLimitAndAddPage}
                  ></TextButton>
                </div>
              </div>
            </Tippy>
          )}
        </div>
        <Col height="100%">
          <Row justifyContent="center" alignItems="center">
            <Grid type="column" alignItems="center" width="500px" style={{ marginTop: 250 }}>
              <StatusPageIcon
                className="empty-state-img"
                height="70px"
                width="70px"
                fill={theme.primary.default}
              />
              <span className="mt-20" style={{ textAlign: 'center', fontSize: 24 }}>
                Looks like you don't have a Status Page.
              </span>
              {hasCreate && (
                <Row justifyContent="center">
                  <Tippy
                    content={
                      this.state.showUpgradeToolTip ? (
                        <UpgradeOnlyModal
                          hasBillingPermission={BillingService.hasManageBillingPermission(
                            this.props,
                          )}
                          align="left"
                          showModal={true}
                          message={BillingService.getMessage(limit, 'status-page', this.props)}
                          header={BillingService.getHeader(limit, 'status-page', this.props)}
                        />
                      ) : (
                        ''
                      )
                    }
                    interactive={true}
                    arrow={true}
                    animation={'scale'}
                  >
                    <div
                      className="mt-20 w-1-1"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <div className="float-right" style={{ display: 'flex' }}>
                        <div
                          className="ml-10"
                          onMouseOver={() => {
                            this.state.statusPageLimitExceeded
                              ? this.setState({ showUpgradeToolTip: true })
                              : {};
                          }}
                          style={this.state.statusPageLimitExceeded ? { opacity: 0.5 } : {}}
                        >
                          <TextButton
                            id="new_status_page_es"
                            size="normal"
                            text="Add Status Page"
                            onClick={this.checkStatusPageLimitAndAddPage}
                          ></TextButton>
                        </div>
                      </div>
                    </div>
                  </Tippy>
                </Row>
              )}
            </Grid>
          </Row>
        </Col>
        <DialogModalFrame
          id="new_status_page_modal"
          width="800px"
          onClose={this.checkDirtyAndCloseModal}
        >
          {this.state.openModal === 'add_new' && (
            <NewStatusPageModal
              pageId=""
              hide={this.hideNewStatusPageModal}
              checkAndSetDirty={this.checkAndSetDirty}
            />
          )}
        </DialogModalFrame>
        <UnsavedChangesGuard
          isManual={true}
          showModal={this.state.showUnsavedChangesModal}
          onDiscardChanges={this.onDiscardChanges}
          onModalClose={this.closeUnsavedChangesModal}
        />
        {isFeatureDisabled ? (
          <UpgradePlanModal
            hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
            key={1}
            showModal={this.state.showUpgradeModal}
            message={BillingService.getMessage(limit, 'status-page', this.props)}
            header={BillingService.getHeader(limit, 'status-page', this.props)}
            onCancel={() => {
              this.setState({ showUpgradeModal: false });
              onCancelAction();
            }}
            onUpgrade={() => this.setState({ showUpgradeModal: false })}
          />
        ) : (
          ''
        )}
      </div>
    );
  }

  const renderStatusPages = this.state.statusPages.map(page => {
    return (
      <div key={shortId.generate()} className="statuspage-card-blocks">
        <div className="pa-20">
          {(hasDelete || hasUpdate) && (
            <div className="float-right" style={{ position: 'relative' }}>
              <img
                src="/icons/greys/more.svg"
                className="cursor-pointer"
                onClick={event => {
                  this.setState({ showEditConfigId: page.id || '' });
                  event.stopPropagation();
                }}
                alt="more"
              />
              {this.state.showEditConfigId === page.id && (
                <div className="config-modal animated fadeIn" style={{ width: 190 }}>
                  {hasUpdate('status_pages', page.id) && (
                    <Link
                      style={{ display: 'block', padding: 15, textDecoration: 'none' }}
                      className="item-box-tagline"
                      to={`/statuspage/${page.id}`}
                    >
                      <div className=" no-select cursor-pointer" style={{ padding: 0 }}>
                        Edit
                      </div>
                    </Link>
                  )}
                  {hasDelete('status_pages', page.id) && (
                    <div
                      className=" no-select cursor-pointer"
                      onClick={() => {
                        this.onSetToDelete(page.id);
                      }}
                    >
                      <p className="item-box-tagline" style={{ margin: 0 }}>
                        Delete
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div>
            {hasUpdate('status_pages', page.id) ? (
              <Link to={`/statuspage/${page.id}`} style={{ textDecoration: 'none' }}>
                <p className="statuspage-card-heading m-0">{page.name}</p>
              </Link>
            ) : (
              <p className="statuspage-card-heading m-0">{page.name}</p>
            )}

            <p className="statuspage-card-description ma-0" style={{ color: 'var(--shades-grey)' }}>
              {`${page.services.length} service${page.services.length > 1 ? 's' : ''} selected`}
            </p>
            <div className="dash page-link pa-10 mt-20">
              {page.isPublic === true ? (
                <Href
                  link={`${this._env === 'dev' ? 'http' : 'https'}://${page.url}`}
                  newTab={true}
                >
                  <span>
                    {page.url}
                    <svg
                      className="ml-10"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
                        fill="#0f61dd"
                      />
                    </svg>
                  </span>
                </Href>
              ) : (
                <Link
                  to={`/statuspage/${page.id}`}
                  style={{ textDecoration: 'none', color: `${theme.primary.default}` }}
                >
                  <span>
                    {page.url}
                    <svg
                      className="ml-10"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
                        fill="#0f61dd"
                      />
                    </svg>
                  </span>{' '}
                </Link>
              )}
            </div>
          </div>
        </div>
        {isFeatureDisabled ? (
          <UpgradePlanModal
            hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
            showModal={this.state.showUpgradeModal}
            onUpgrade={() => this.setState({ showUpgradeModal: false })}
            message={BillingService.getMessage(limit, 'status-page', this.props)}
            header={BillingService.getHeader(limit, 'status-page', this.props)}
            onCancel={() => {
              this.setState({ showUpgradeModal: false });
              onCancelAction();
            }}
          />
        ) : (
          ''
        )}
      </div>
    );
  });

  const getStatusPage = (id: string) => this.state.statusPages.find(sp => sp.id === id);

  return (
    <div className="main-container" onClick={() => this.setState({ showEditConfigId: '' })}>
      <div className={cx(['clearfix'], {})}>
        <div className="float-left" style={{ marginBottom: 20 }}>
          <h1 className="item-box-main-heading" style={{ display: 'inline-block' }}>
            Status Page
          </h1>
          {this.state.statusPages.length > 0 && (
            <Para
              fontSize={20}
              style={{ display: 'inline-block', paddingLeft: 10, fontWeight: 300 }}
            >
              ({this.state.statusPages.length})
            </Para>
          )}
          <Heading
            fontSize={14}
            height={28}
            style={{
              fontWeight: 300,
              paddingBottom: '10px',
              marginBottom: '10px',
              fontSize: '12px',
              color: '#808080',
              display: 'block',
            }}
          >
            Status Page helps you communicate critical updates about outages and scheduled
            maintenance to your customers and stakeholders. Learn more about status page{' '}
            <a
              href="https://support.squadcast.com/status-page/status-page"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </Heading>
        </div>
        {hasCreate && (
          <Tippy
            content={
              this.state.showUpgradeToolTip ? (
                <UpgradeOnlyModal
                  hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
                  align="left"
                  showModal={true}
                  message={BillingService.getMessage(limit, 'status-page', this.props)}
                  header={BillingService.getHeader(limit, 'status-page', this.props)}
                />
              ) : (
                ''
              )
            }
            interactive={true}
            arrow={true}
            animation={'scale'}
          >
            <div className="float-right" style={{ display: 'flex' }}>
              <div className="ml-10">
                <div className="float-right" style={{ display: 'flex' }}>
                  <div
                    className="ml-10"
                    onMouseOver={() => {
                      this.state.statusPageLimitExceeded
                        ? this.setState({ showUpgradeToolTip: true })
                        : {};
                    }}
                    style={this.state.statusPageLimitExceeded ? { opacity: 0.5 } : {}}
                  >
                    <TextButton
                      id="new_status_page_es"
                      size="normal"
                      text="Add Status Page"
                      onClick={this.checkStatusPageLimitAndAddPage}
                    ></TextButton>
                  </div>
                </div>
              </div>
            </div>
          </Tippy>
        )}
      </div>
      <div id="runbook-containers" className="data-display-container" style={{ marginLeft: -10 }}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {renderStatusPages}
        </div>
      </div>

      <DialogModalFrame
        id="new_status_page_modal"
        width="800px"
        onClose={this.checkDirtyAndCloseModal}
      >
        {this.state.openModal === 'add_new' && (
          <NewStatusPageModal
            checkAndSetDirty={this.checkAndSetDirty}
            pageId={this.state.showEditConfigId}
            hide={this.hideNewStatusPageModal}
          />
        )}
      </DialogModalFrame>

      <DialogModalFrame id="remove_statuspage" width="400px" onClose={this.closeModal}>
        {this.state.removePageId !== '' && (
          <ConfirmModal
            hide={(result: boolean) => {
              if (result) {
                this.deletePage();
              }
              this.setState({
                removePageId: '',
              });
            }}
            buttonText={'Delete'}
            displayText={`Are you sure you want to delete this StatusPage: "${
              getStatusPage(this.state.removePageId)?.name ?? 'StatusPage Deleted'
            }"?`}
          />
        )}
      </DialogModalFrame>
      {isFeatureDisabled ? (
        <UpgradePlanModal
          hasBillingPermission={BillingService.hasManageBillingPermission(this.props)}
          showModal={this.state.showUpgradeModal}
          message={BillingService.getMessage(limit, 'status-page', this.props)}
          header={BillingService.getHeader(limit, 'status-page', this.props)}
          onCancel={() => {
            this.setState({ showUpgradeModal: false });
            onCancelAction();
          }}
          onUpgrade={() => this.setState({ showUpgradeModal: false })}
        />
      ) : (
        ''
      )}
      <UnsavedChangesGuard
        isManual={true}
        showModal={this.state.showUnsavedChangesModal}
        onDiscardChanges={this.onDiscardChanges}
        onModalClose={this.closeUnsavedChangesModal}
      />
    </div>
  );
}

export default render;
