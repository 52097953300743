import React, { useMemo } from 'react';

import { Box, Grid, HStack, Progress, Text, VStack } from '@chakra-ui/react';

type Props = {
  steps: {
    name: string;
  }[];
  activeStep: number;
};

function Stepper({ steps, activeStep }: Props) {
  const getProgressColor = (step: number) => {
    if (activeStep === step) {
      return '#627C98'; //confirm color code translation
    } else if (activeStep > step) {
      return 'green.500';
    }
    return 'gray.200';
  };

  return (
    <Grid templateColumns={`repeat(${steps.length}, 1fr)`} gap={8} p={6}>
      {steps.map((step, index) => {
        return (
          <VStack key={index} alignItems="flex-start">
            <Progress
              value={100}
              size="sm"
              w="100%"
              sx={{
                '& div': {
                  backgroundColor: getProgressColor(index),
                },
                height: '4px',
              }}
            />

            <Box>
              <Text fontWeight={600} fontSize="xs" color={getProgressColor(index)}>
                STEP {index + 1}
              </Text>
              <Text fontWeight={600} fontSize="xs">
                {step.name}
              </Text>
            </Box>
          </VStack>
        );
      })}
    </Grid>
  );
}

export default Stepper;
