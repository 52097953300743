import { Grid } from 'uie/components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import AddFilterBtn from './addFilterBtn';
import { FilterOptions } from './const';
import { useSecondaryFilters } from './context';
import DialogFilter from './DialogFilter';
import AlertSources from './filters/alert_sources';
import Services from './filters/services';
import Tags from './filters/tags';
import Teams from './filters/teams';
import Users from './filters/users';
import Priority from './filters/priority';
import { FilterOptionsLabel, SecondaryFiltersKeys, SecondaryFiltersType } from './types';
import PostmortemStatus from './filters/postmortem_status';
import { useLocation } from 'react-router';
import qs, { ParsedQuery } from 'query-string';

type Props = {
  serviceId?: string;
  teamId?: string;
  isMulti?: boolean;
};

const SecondaryFilter: React.FC<Props> = ({ ...props }) => {
  const { filters, activeFilters, updateActiveFilters, defaultFilters, updateAllFilterAtOnce } =
    useSecondaryFilters();
  const cachedFilters = useRef<typeof activeFilters>(activeFilters);
  const [initializedFilter, setInitializedFilter] = useState<typeof activeFilters[0]>();

  const { search } = useLocation();
  const queryFilters = (qs.parse(search) as ParsedQuery<string>)?.filters || '{}';

  const parsedParams = JSON.parse(queryFilters as string);
  useEffect(() => {
    // for page refresh, if url params has empty filters, this will remove those empty dropdown from UI

    let filteredParams: SecondaryFiltersType = {};
    for (const key in parsedParams) {
      if (!parsedParams[key].length) {
        filteredParams = { ...filteredParams, [key]: parsedParams[key] };
      }
    }
    updateAllFilterAtOnce(filteredParams);
  }, []);

  const filterOptionLabels: FilterOptionsLabel[] = useMemo(
    () =>
      (Object.keys(defaultFilters) as SecondaryFiltersKeys[]).map(key => ({
        key,
        label: FilterOptions[key],
      })),
    [filters],
  );

  const initializeFilter = (filter: typeof activeFilters[0]) => {
    let cachedFilterList = cachedFilters.current;
    const activeFilterList = [...activeFilters, filter];

    if (cachedFilterList.length < activeFilterList.length) {
      setInitializedFilter(activeFilterList[activeFilterList.length - 1]);
    }

    cachedFilterList = activeFilters;
    updateActiveFilters(filter);
  };

  useEffect(() => {
    cachedFilters.current = activeFilters;
  }, [activeFilters]);

  return (
    <Grid style={{ margin: '0 0 20px 0', gap: '24px' }}>
      {activeFilters.map(filterType => {
        if (filterType === 'alert_sources' && defaultFilters.alert_sources !== undefined) {
          return (
            <AlertSources
              selected={filters.alert_sources || []}
              teamId={props.teamId}
              isInitializedOpen={initializedFilter === filterType}
            />
          );
        } else if (filterType === 'tags' && defaultFilters.tags !== undefined) {
          return (
            <Tags
              selected={filters.tags || []}
              serviceId={props.serviceId}
              teamId={props.teamId}
              isInitializedOpen={initializedFilter === filterType}
              isMulti={props.isMulti}
            />
          );
        } else if (filterType === 'services' && defaultFilters.services !== undefined) {
          return (
            <Services
              selected={filters.services || []}
              isInitializedOpen={initializedFilter === filterType}
              isMulti={props.isMulti}
            />
          );
        } else if (filterType === 'teams' && defaultFilters.teams !== undefined) {
          return (
            <Teams
              selected={filters.teams || []}
              isInitializedOpen={initializedFilter === filterType}
              isMulti={props.isMulti}
            />
          );
        } else if (filterType === 'users' && defaultFilters.users !== undefined) {
          return (
            <Users
              selected={filters.users || []}
              isInitializedOpen={initializedFilter === filterType}
              isMulti={props.isMulti}
            />
          );
        } else if (filterType === 'priority' && defaultFilters.priority !== undefined) {
          return (
            <Priority
              selected={filters.priority || []}
              isInitializedOpen={initializedFilter === filterType}
              isMulti={props.isMulti}
            />
          );
        } else if (
          filterType === 'postmortem_status' &&
          defaultFilters.postmortem_status !== undefined
        ) {
          return (
            <PostmortemStatus
              selected={filters.postmortem_status || []}
              isInitializedOpen={initializedFilter === filterType}
              isMulti={props.isMulti}
            />
          );
        }
      })}
      {filterOptionLabels.length != activeFilters.length && (
        <AddFilterBtn
          options={filterOptionLabels}
          activeOptions={activeFilters}
          chooseOption={initializeFilter}
        />
      )}
    </Grid>
  );
};

export default SecondaryFilter;
export { DialogFilter };
