import { IService } from './IService';
import { IAttachmentRequest } from './ISignUpload';
import { ITeamEntityAccessControl, ITeamOwner } from './ITeams';

export interface IFollowUp {
  task: string;
  is_done: boolean;
}

export interface IPostmortem {
  id?: string;
  organization_id?: string;
  title: string;
  incident_id?: string;
  postmortem: string;
  follow_ups: IFollowUp[] | null;
  created_at?: Date;
  status: IPostmortemStatus;
  owner?: ITeamOwner;
  access_control?: ITeamEntityAccessControl<'postmortems'>[];
  attachments: IAttachmentRequest[];
}

export enum IPostmortemStatus {
  InProgress = 'in_progress',
  UnderReview = 'under_review',
  Published = 'published',
}

export interface IPostmortemDownloadPayload {
  format: 'pdf';
}

export interface IPostmortemTemplate {
  id?: string;
  organization_id?: string;
  name: string;
  slug?: string;
  template: string;
  is_default: boolean;
  is_deleted?: boolean;
  attachments: IAttachmentRequest[];
}

export interface IPostmortemFilter {
  key: PostmortemFilterTypes;
  title: string;
  value?: PostmortemFilterTypeValues;
}

export interface IPostmortemFilterOption {
  key: PostmortemFilterTypes;
  name: string;
}

export type PostmortemFilterTypes = 'service' | 'alert_source';

export const postmortemOgOptions: IPostmortemFilterOption[] = [
  { name: 'Service', key: 'service' },
  { name: 'Alert Source', key: 'alert_source' },
];

export interface IPostmortemFilters {
  service?: IPostmortemFilterService;
  alert_source?: IPostmortemFilterAlertSource;
}

export type PostmortemFilterTypeValues = IPostmortemFilterService | IPostmortemFilterAlertSource;

export interface IPostmortemFilterService {
  values: string[];
}

export interface IPostmortemFilterAlertSource {
  values: string[];
}

export interface IPostmortemList extends IPostmortem {
  incident_message: string;
  incident_time_of_creation: Date;
  service_name: string;
  alert_source: string;
  active_follow_ups_count: number;
}
export interface IPostmortemResponseData {
  result: IPostmortemList[];
  total_count: {
    count: number;
  }[];
}
export interface IPostmortemResponse {
  data: IPostmortemResponseData[];
}
