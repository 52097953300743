import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const NumberInputTheme: ComponentStyleConfig = {
  baseStyle: {
    field: {
      fontFamily: 'Mulish',
      bg: 'brand.white',
      borderWidth: '0.75px',

      '::placeholder': {
        color: 'secondary.400',
      },
      '::-ms-input-placeholder': {
        color: 'secondary.400',
      },

      _disabled: {
        color: '#09305A',
        opacity: 1,
        cursor: 'not-allowed',

        _hover: {
          borderColor: 'secondary.200',
        },
      },

      _invalid: {
        borderColor: '#E05000',
      },

      _hover: {
        borderColor: 'brand.blue',
      },

      _active: {
        borderColor: 'primary.300',
      },
      _focus: {
        borderColor: 'brand.blue',
      },
      _focusWithin: {
        borderColor: 'brand.blue',
      },
    },
    stepperGroup: {
      borderLeftWidth: '0.75px',
      borderLeftStyle: 'solid',
      margin: 0,
      alignItems: 'center',
      top: '0.75px',
    },
    stepper: {
      width: '100%',
      border: 'none',
      _disabled: {
        '& svg path': {
          fill: 'secondary.400',
        },
      },
      _hover: {
        '& svg path': {
          fill: 'secondary.700',
        },
      },
      _active: {
        '& svg path': {
          fill: 'secondary.900',
        },
        bg: 'transparent',
      },
    },
    divider: {
      borderBottom: 'none',
      alignSelf: 'flex-start',
    },
  },
  sizes: {
    md: {
      field: {
        borderRadius: '3px',
        height: '30px',
        px: '9px',
        py: '6px',
        fontSize: '12px',
      },
      stepperGroup: {
        width: '18px',
        height: '28.5px',
      },
      stepper: {
        height: '13.5px',
        '& svg': {
          width: '6.75px',
          height: '3.38px',
        },
      },
      divider: {
        height: '0.75px',
        width: '16.5px',
      },
    },
    lg: {
      field: {
        borderRadius: '3px',
        height: '40px',
        px: '12px',
        py: '8px',
        fontSize: '16px',
      },
      stepperGroup: {
        width: '24px',
        height: '38.5px',
        '& svg': {
          width: '9px',
          height: '4.5px',
        },
      },
      stepper: {
        height: '18px',
        '& svg': {
          width: '9px',
          height: '4.5px',
        },
      },
      divider: {
        height: '1px',
        width: '22.5px',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: null,
  },
};
