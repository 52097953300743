import { Theme } from 'uie/components';
import { Flex, Text } from '@chakra-ui/layout';
import { focusCard } from 'core';
import { useEffect, useRef } from 'react';

interface Props {
  title?: string;
  icon: React.ReactElement;
  children: React.ReactNode;
  id?: string;
}

const ActionCardContainer = ({ title, children, icon, id }: Props) => {
  const { theme } = Theme;
  const flexRef = useRef(null);
  const targetAction = window.location.hash.split('?')[0];

  const styleActionOnTarget =
    targetAction === '#' + id ? `1px solid ${theme.primary.default}` : 'secondary.200';

  useEffect(() => {
    if (flexRef.current && targetAction === '#' + id) {
      focusCard(flexRef.current);
    }
  }, [targetAction]);
  return (
    <Flex
      id={id}
      p={4}
      bgColor="brand.white"
      borderRadius={8}
      border={styleActionOnTarget}
      borderWidth={1}
      ref={flexRef}
    >
      <Flex>{icon}</Flex>
      <Flex ml={2} flexDirection="column">
        {title && (
          <Text variant="h2" textStyle="bold_600">
            {title}
          </Text>
        )}
        {children}
      </Flex>
    </Flex>
  );
};

export default ActionCardContainer;
