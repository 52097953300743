import { Grid, SpinLoader } from 'uie/components';
import React from 'react';

const Loader: React.FC = () => {
  return (
    <Grid type="column" alignItems="center">
      <SpinLoader base="32px" style={{ margin: 0, height: 32, width: 32 }}>
        <img src="/icons/squadcast-spinner.svg" alt="." width="32px" height="32px" />
      </SpinLoader>
    </Grid>
  );
};

export default Loader;
