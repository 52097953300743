import * as React from 'react';
import { SVGProps } from 'react';

const SvgServerdensity = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#222',
      }}
      d="M6.167.105C1.795 1.398-1.773 15.493 1.17 18.75s17.485-.357 18.645-4.996c1.16-4.64-9.233-14.898-13.65-13.65zm5.441 11.998c-.267.313-.624.58-1.025.759a3.409 3.409 0 0 1-2.944-.134c0 .401 0 1.026 1.962 1.16.58.044.892.044 1.517.133.134.045.223.045.357.09-.179.847-.491 1.516-1.294 1.427-.312-.045-.535 0-.625 0-3.077 0-3.746-1.517-3.746-2.81v-3.39c0-3.256 1.784-3.925 3.345-3.925a3.156 3.156 0 0 1 2.498 1.07c.669.758.981 1.784.937 2.81 0 1.026-.312 2.008-.982 2.81z"
    />
    <path
      style={{
        fill: '#222',
      }}
      d="M9.066 7.063a1.127 1.127 0 0 0-1.07.535 3.036 3.036 0 0 0-.402 1.74c-.045.58.09 1.16.401 1.65.268.357.67.536 1.07.491.447 0 .848-.178 1.16-.535.357-.491.491-1.07.447-1.695a2.534 2.534 0 0 0-.491-1.695 1.45 1.45 0 0 0-1.115-.491z"
    />
  </svg>
);

export default SvgServerdensity;
