import {
  IIncidentOverviewResponse,
  IIncidentSummaryResponse,
  IIncidentSummaryResponseMetadata,
} from 'core/interfaces/IIncidents';
import { QueryOptions } from 'react-query';

import { getIncidentOverview, getIncidentSummary } from '.';

export const getIncidentOverviewQuery: (
  incidentId: string,
  drawerOpen: boolean,
) => QueryOptions<
  | undefined
  | {
      data: IIncidentOverviewResponse;
    }
> = (incidentId: string, drawerOpen: boolean) => {
  return {
    queryKey: ['incidents', incidentId, 'overview', drawerOpen],
    queryFn: async () => {
      if (!drawerOpen) return undefined;
      const res = await getIncidentOverview(incidentId);
      return res.data;
    },
  };
};

export const getIncidentSummaryQuery: (
  incidentId: string,
  drawerOpen: boolean,
) => QueryOptions<
  | undefined
  | {
      data: IIncidentSummaryResponse;
      meta: IIncidentSummaryResponseMetadata;
    }
> = (incidentId: string, drawerOpen: boolean) => {
  return {
    queryKey: ['incidents', incidentId, 'summary', drawerOpen],
    queryFn: async () => {
      if (!drawerOpen) return undefined;
      const res = await getIncidentSummary(incidentId);
      return res.data;
    },
  };
};
