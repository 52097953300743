import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Text,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Tag,
  TagLabel,
  HStack,
} from '@chakra-ui/react';
import moment from 'moment';
import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { ISelectedOverviewFilterOptions, sliDistribution } from '../slo.interface';

export type IService = IAppState['organization']['services'];

interface IProps {
  services: IService['s'];
  getData?: (
    selectedFilter: Array<string>,
    selectedFilterOpt: ISelectedOverviewFilterOptions,
  ) => void;
  filterList: Array<{ id: string; name: string }>;
  slis?: sliDistribution | null | undefined;
}

const SLOOverviewFilter = ({ services, getData, filterList, slis }: IProps) => {
  const ref = useRef(null);
  const [selectedFilter, setFilter] = useState<Array<string>>([]);
  const [selectedFilterOpt, setFilterOpt] = useState<{
    service: Array<string>;
    sli: Array<string>;
    dateTime: { fromDate: Date; toDate: Date };
  }>({
    service: [],
    sli: [],
    dateTime: { fromDate: new Date(), toDate: new Date() },
  });
  const isSlis = selectedFilter.some(filter => filter == 'Slis');
  const isService = selectedFilter.some(filter => filter == 'service');
  const isDateTime = selectedFilter.some(filter => filter == 'dateTime');

  const isSliSelected = isSlis && selectedFilterOpt.sli.length;
  const isServiceSelected = isService && selectedFilterOpt.service.length;
  const isDateTimeSelected =
    isDateTime && selectedFilterOpt.dateTime.fromDate && selectedFilterOpt.dateTime.toDate;

  useEffect(() => {
    getData && getData(selectedFilter, selectedFilterOpt);
  }, [selectedFilterOpt, selectedFilter]);

  return (
    <>
      <HStack mb={2}>
        <Center>
          <Box mr={5}>
            <HStack height="70px" ref={ref}>
              {
                <Menu closeOnSelect={false}>
                  <MenuButton
                    color={'black.200'}
                    textAlign={'start'}
                    p={2}
                    borderWidth="1px"
                    borderRadius="md"
                    minWidth="100px"
                  >
                    <AddIcon fontSize="10px" />
                    Add Filter
                  </MenuButton>
                  <MenuList minWidth="240px">
                    <MenuOptionGroup
                      onChange={filters_ => {
                        const filters = (Array.isArray(filters_) && filters_) || [];
                        setFilter(filters);
                      }}
                      value={selectedFilter}
                      type="checkbox"
                    >
                      {filterList.map(({ id, name }, index) => {
                        return (
                          <MenuItemOption key={index} value={id}>
                            {name}
                          </MenuItemOption>
                        );
                      })}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              }

              {isService && (
                <Menu closeOnSelect={false}>
                  <MenuButton
                    color={'gray.500'}
                    textAlign={'start'}
                    w="100%"
                    p={2}
                    borderWidth="1px"
                    borderRadius="md"
                  >
                    <HStack>
                      <Text>Services</Text> <ChevronDownIcon />
                    </HStack>
                  </MenuButton>
                  <MenuList minWidth="240px">
                    <MenuOptionGroup
                      onChange={service => {
                        Array.isArray(service)
                          ? setFilterOpt({ ...selectedFilterOpt, service: [...service] })
                          : setFilterOpt({ ...selectedFilterOpt, service: [] });
                      }}
                      value={selectedFilterOpt['service']}
                      type="checkbox"
                    >
                      {services.map(({ id, name }, index) => {
                        return (
                          <MenuItemOption key={index} value={id}>
                            {name}
                          </MenuItemOption>
                        );
                      })}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              )}
              {isSlis && (
                <Menu closeOnSelect={false}>
                  <MenuButton
                    color={'gray.500'}
                    textAlign={'start'}
                    w="100%"
                    p={2}
                    borderWidth="1px"
                    borderRadius="md"
                  >
                    <HStack>
                      <Text>SLIs</Text> <ChevronDownIcon />
                    </HStack>
                  </MenuButton>
                  <MenuList minWidth="240px">
                    <MenuOptionGroup
                      onChange={sli => {
                        Array.isArray(sli)
                          ? setFilterOpt({ ...selectedFilterOpt, sli: [...sli] })
                          : setFilterOpt({ ...selectedFilterOpt, sli: [] });
                      }}
                      value={selectedFilterOpt['sli']}
                      type="checkbox"
                    >
                      {slis &&
                        slis?.sli_distribution &&
                        slis?.sli_distribution.map((sli, index) => {
                          return (
                            <MenuItemOption key={index} value={sli.sli}>
                              {sli.sli}
                            </MenuItemOption>
                          );
                        })}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              )}
            </HStack>
          </Box>
        </Center>
      </HStack>
      {isSliSelected ? (
        <HStack m={2} justifyContent="start" height="20px" direction="row">
          <Text>Sli:</Text>
          {selectedFilterOpt.sli.map((sli, index) => {
            return (
              <Tag size={'sm'} key={'sm'} borderRadius="full" variant="solid" colorScheme="gray">
                <TagLabel>{sli}</TagLabel>
              </Tag>
            );
          })}
        </HStack>
      ) : null}

      {isServiceSelected ? (
        <HStack m={2} justifyContent="start" height="20px" direction="row">
          <Text>Service:</Text>
          {services.map((service, index) => {
            if (
              Array.isArray(selectedFilterOpt.service) &&
              selectedFilterOpt.service.includes(service.id)
            ) {
              return (
                <Tag size={'sm'} key={'sm'} borderRadius="full" variant="solid" colorScheme="gray">
                  <TagLabel>{service.name}</TagLabel>
                </Tag>
              );
            }
          })}
        </HStack>
      ) : null}

      {isDateTimeSelected ? (
        <HStack m={2} justifyContent="start" height="20px" direction="row">
          <Text>Date Range:</Text>
          <Tag size={'sm'} key={'sm'} borderRadius="full" variant="solid" colorScheme="gray">
            <TagLabel>
              {'From: '}
              {moment(selectedFilterOpt.dateTime.fromDate).format('DD/MM/YYYY')}
            </TagLabel>
          </Tag>

          <Tag size={'sm'} key={'sm'} borderRadius="full" variant="solid" colorScheme="gray">
            <TagLabel>
              {'To: '}
              {moment(selectedFilterOpt.dateTime.toDate).format('DD/MM/YYYY')}
            </TagLabel>
          </Tag>
        </HStack>
      ) : null}
    </>
  );
};

export default React.memo(SLOOverviewFilter);
