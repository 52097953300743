import React from 'react';
import { IAnalyticsPanel, IAnalyticsPanelData } from '../../../../../core/interfaces/IAnalytics';
import { renderChart } from './helpers';

interface IProps {
  panel: IAnalyticsPanel;
  data: IAnalyticsPanelData;
}

const Basic: React.FC<IProps> = ({ data, panel }) => {
  return <div className="analytics__panel-content">{renderChart(panel, data.data)}</div>;
};

export default Basic;
