//NOTE:
import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API } from 'core';
import { queryKeys, reactQueryConfig } from '../constant';
import { IJiraProjects } from '../../../../../../../core/interfaces/IJiraNew';
import { reactQueryConfigError } from '../helpers/service.reactqery';

const getJiraProjectsForAccount = (accountId: string) => async () => {
  const { data } = await axios.get<IJiraProjects>(
    `${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud/new/${accountId}/projects`,
    //{acountID}/projects
  );
  return data.data;
};

export const useGetJiraProjectsForAccount = (accountId: string) => {
  return useQuery(
    [queryKeys.GETJIRAPROJECTSFORACCOUNT, accountId],
    getJiraProjectsForAccount(accountId),
    {
      ...reactQueryConfig,
      enabled: !!accountId,
      retry: 1,
      onError: (error: any) => {
        const description =
          error?.response?.data?.meta?.error_message || 'Failed to Get Jira Projects';
        reactQueryConfigError({ description: description, title: 'Get Project For Account' });
      },
    },
  );
};
