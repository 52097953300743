import { Flex, HStack, Image, Text, useDisclosure } from '@chakra-ui/react';
import { truncate } from 'core/helpers/stringUtils';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { IconButton, Tooltip } from 'library/atoms';
import { LibraryIcons } from 'library/icons';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { FC, Fragment, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { useMutateAlertQuery } from '../../hooks';

import { invalidateGERDetail } from '../../query/useGetGerDetail';
import { GERContext } from '../../store';
import { ALERT_INFO, RULESET } from '../../types';
import { DeleteGERRuleset } from './delete';

type Props = {
  rule: RULESET;
  alertsList: ALERT_INFO[];
  onSelection: () => void;
  gerId: string;
};

export const AlertEntity: FC<Props> = ({ rule, alertsList, onSelection, gerId }) => {
  const alert = alertsList.find(
    item =>
      item.shortName === rule.alert_source_shortname && item.version === rule.alert_source_version,
  );

  const queryClient = useQueryClient();
  const {
    state: { permissions },
  } = useContext(GERContext);
  const noOfRules = (rule.ordering ?? []).length;

  const userAccess = useUserAccess();

  const hasUpdateAccess = userAccess.hasUpdateAccess('ger', gerId);

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { updateAlert } = useMutateAlertQuery();

  const onDelete = () => {
    updateAlert();
    invalidateGERDetail(queryClient);
  };
  if (!alert) {
    return <Fragment />;
  }

  return (
    <Flex
      p={2}
      pl={6}
      alignItems="center"
      justifyContent="space-between"
      onClick={onSelection}
      cursor="pointer"
    >
      <HStack alignItems="center" gap={1}>
        <Image src={alert.iconURL} width="24px" />
        <Tooltip label={alert.name} placement="top">
          <Text variant="formInput" color="secondary.1000">
            {truncate(alert.name || rule.alert_source_shortname, 20)} ({noOfRules})
          </Text>
        </Tooltip>
      </HStack>
      <NoPermissionTooltip isDisabled={hasUpdateAccess}>
        <IconButton
          aria-label="delete"
          icon={<LibraryIcons.DeleteIcon />}
          variant="ghost"
          onClick={event => {
            event.stopPropagation();
            onDeleteOpen();
          }}
          isDisabled={!hasUpdateAccess}
        />
      </NoPermissionTooltip>

      <DeleteGERRuleset
        id={rule.global_event_rule_id}
        alert={alert}
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onSuccess={onDelete}
      />
    </Flex>
  );
};
