import Axios from 'axios';
import { API } from 'core';
import { getPriorityValue, PRIORITY } from 'library/common';
import qs from 'query-string';

export const bulkAckIncident = (incidentIds: string[]) =>
  Axios.post<{ result: boolean }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents/acknowledge`,
    {
      incident_ids: incidentIds,
    },
  );

export const bulkResolveIncident = (incidentIds: string[]) =>
  Axios.post<{ result: boolean }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents/resolve`,
    { incident_ids: incidentIds },
  );

export const bulkUpdatePriority = (incidentIds: string[], value: PRIORITY) => {
  const priority = getPriorityValue(value);
  return Axios.put<{ result: boolean }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents/priority`,
    { incident_ids: incidentIds, priority },
  );
};

export const updatePriority = (incidentId: string, value: PRIORITY) => {
  const priority = getPriorityValue(value);
  return Axios.patch<{ result: boolean }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${incidentId}/priority`,
    { priority },
  );
};

export const exportAPI = (props: any) =>
  Axios({
    url: `${API.config.batman}/organizations/${
      API.config.organizationId
    }/incidents/export?${qs.stringify(props)}`,
    method: 'GET',
    responseType: 'blob',
  });
