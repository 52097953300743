import request from '../commons';
import { API } from '../../api';

function getOrgCalendar() {
  const req = request.get(API.config.batman)(
    `organizations/${API.config.organizationId}/calendar?owner_id=${API.config.teamId}`,
  );
  return req;
}

function getOrgCalendarOnCall(calendarId: string) {
  const req = request.get(API.config.batman)(
    `organizations/${API.config.organizationId}/calendar/${calendarId}/on-call`,
  );
  return req;
}

export { getOrgCalendar, getOrgCalendarOnCall };
