import { Grid } from 'uie/components';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../../../core/interfaces/IAppState';
import { IIncident, ILog } from '../../../../../core/interfaces/IIncidents';
import { IOrganization } from '../../../../../core/interfaces/IOrganization';
import { IPublicMessage } from '../../../../../core/interfaces/IPublicMessage';
import { IStatusPage } from '../../../../../core/interfaces/IStatusPage';
import ExportTimeline from './activityTimelineComponents/ExportTimeline';
import ActivityMessages from './activityTimelineComponents/activityMessage';
import { ErrorBoundary } from 'views/shared/components';

export type setFocusMessageId = (id: string, type?: string, index?: number) => void;
export interface IProps extends Pick<IAppState, 'organization' | 'integrations'> {
  setFocusMessageId: setFocusMessageId;
  incident: IIncident;
  selectedOrg: IOrganization;
  statusPages: { [key: string]: IStatusPage };
  sloData: any;
  sloDetails: any;
  publicMessages: {
    action: 'public_message';
    additionalInfo: IPublicMessage;
    time: Date;
  }[];
}

const IncidentDetailsActivityBox = ({ incident, ...props }: IProps) => {
  //removed unused datas here.
  const _oUser = props.organization.users.u.reduce((c: any, n) => {
    c[n.id] = n;
    return c;
  }, {});

  const [logs, setLogs] = useState<ILog[]>([]);
  const computeLogs = () => {
    const _logs = [...incident.logs, ...props.publicMessages].slice(0).sort((a: any, b: any) => {
      const timeOfFirstLog = new Date(a.time).getTime();
      const timeOfSecondLog = new Date(b.time).getTime();
      //If both logs have occured at the same time e.g unmerge and retrigger, return whatever is first in the array e.g. unmerge
      if (timeOfFirstLog === timeOfSecondLog) return -1;
      //Else return the log which occured first
      return timeOfSecondLog - timeOfFirstLog;
    }) as ILog[];

    setLogs(_logs);
  };

  const setFocusMessageId = (id: string, type?: string, index?: number) => {
    props.setFocusMessageId(id, type, index);
  };

  useEffect(computeLogs, [incident.logs, props.publicMessages]);

  return (
    <ErrorBoundary>
      <Grid type="column" className="incident_details__soft_card invert">
        <ExportTimeline incident={incident} {...props} />
        <ActivityMessages
          incident={incident}
          organization={props.organization}
          logs={logs}
          oUser={_oUser}
          statusPages={props.statusPages}
          setFocusMessageId={setFocusMessageId}
        />
      </Grid>
    </ErrorBoundary>
  );
};

export default connect((state: IAppState) => ({
  organization: state.organization,
  selectedOrg: state.userOrganizations.o.find(
    (o: IOrganization) => o.organizationId === state.INIT_ORG.orgId,
  ) as IOrganization,
  integrations: state.integrations,
}))(IncidentDetailsActivityBox);
