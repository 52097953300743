import React, { ReactNode } from 'react';

import { Box, Heading, Link, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';

type ServiceTips = {
  title: string;
  desc: ReactNode[];
  link: { url: string; label: string }[];
  type: 'tips';
};

export type ServiceHelpGuide = (
  | {
      title: string;
      desc: ReactNode[];
      link: { url: string; label: string }[];
      type: 'help' | 'queries';
    }
  | ServiceTips
)[];

type Props = {
  helpSupportWidth: number;
  helpGuide: ServiceHelpGuide;
};

function HelpGuide({ helpSupportWidth, helpGuide }: Props) {
  return (
    <Box
      sx={{
        width: helpSupportWidth,
        height: 'auto',
        backgroundColor: 'white',
        borderRadius: 3,
      }}
    >
      <Box paddingX={6} paddingY={6} fontSize="24px">
        Help and Guide
      </Box>

      <VStack px={6} alignItems="flex-start" gap={4}>
        {helpGuide.map((guide, i) => {
          if (guide.type === 'help') {
            return (
              <Box key={`guide${i}`}>
                <Heading as="h4" size="xs" pb={1}>
                  {guide.title}
                </Heading>
                {guide.desc.map((tip, index) => (
                  <Text lineHeight={1.2} fontSize={14} key={`desc-${index}`}>
                    {tip}
                  </Text>
                ))}
                {guide.link.map(({ url, label }, index) => (
                  <Box key={`link-${index}`}>
                    <Link href={url} variant="italic" target="_blank" rel="noopener noreferrer">
                      {label}
                    </Link>
                  </Box>
                ))}
              </Box>
            );
          } else if (guide.type === 'tips') {
            return (
              <Box key={`guide${i}`}>
                <Heading as="h4" size="xs">
                  {guide.title}
                </Heading>
                <UnorderedList>
                  {guide.desc.map((tip, index) => (
                    <ListItem ml={4} key={index}>
                      <Text lineHeight={1.2} fontSize={14}>
                        {tip}
                      </Text>
                    </ListItem>
                  ))}
                </UnorderedList>
              </Box>
            );
          } else {
            return (
              <Box key={`guide${i}`} pt={6}>
                <Text variant="italic" fontSize="xs">
                  {guide.title}
                </Text>
              </Box>
            );
          }
        })}
      </VStack>
    </Box>
  );
}

export default HelpGuide;
