import React from 'react';
import { EventModal } from '..';
import { Row, FocusBlock, ErrorText } from '@squadcast/alchemy-ui/carbon';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';
import { SelectBox, InputBox } from '@squadcast/alchemy-ui/metal';
import shortid from 'shortid';
import repitition from './repition.render';
import rotation from './rotation.render';
import { Locale } from 'core/helpers/dateUtils';
import { Grid, Para } from 'uie/components';
import styled from 'styled-components';

const TimePickerGrid = styled(Grid)`
  align-items: center;
  border-radius: 6px;
  border: solid 1px #e7ecf5;
  margin-top: 10px;
  padding: 0 20px;
`;

function shiftCreateOrUpdate(this: EventModal) {
  const calendarDateFormat = Locale.dateFormat.replace(/D/g, 'd').replace(/Y/g, 'y');

  return (
    <div>
      <div className="mt-20">
        <InputBox
          id="shift_name"
          name="Shift Name"
          label="Shift Name"
          placeHolder="Name your shift"
          type="text"
          value={this.state.name}
          onChange={this.onShiftNameChange}
          required={true}
        />
      </div>
      <div>
        <label className="item-box-tagline font-bold mb-10 block">Schedule</label>
        <SelectBox
          id="select_calendar"
          name="calendar"
          label="calendar"
          onChange={this.onSelectCalendarChange}
          selected={
            <Row justifyContent="space-between" alignItems="center">
              <p
                style={{
                  margin: 0,
                }}
              >
                {this.state.calendar_id.length > 0
                  ? this.oCalender[this.state.calendar_id].name
                  : 'Please select a schedule'}
              </p>
              <div
                className="calendar-color-block"
                style={{
                  backgroundColor:
                    this.state.calendar_id.length > 0
                      ? this.oCalender[this.state.calendar_id].colour
                      : 'transparent',
                }}
              />
            </Row>
          }
          enableSearchBox={true}
          onSearchValueChange={this.onSearchValueChange}
        >
          {this.props.organization.calendar.c
            .filter(({ name }) => {
              return (
                this.state.calendarSearch === '' ||
                name.toLowerCase().indexOf(this.state.calendarSearch) > -1
              );
            })
            .map(({ id, name, colour }, index: number) => (
              <FocusBlock value={id} key={shortid.generate()}>
                <Row justifyContent="space-between" alignItems="center">
                  <p
                    style={{
                      margin: 0,
                    }}
                  >
                    {name}
                  </p>
                  <div
                    className="calendar-color-block"
                    style={{
                      backgroundColor: colour,
                    }}
                  />
                </Row>
              </FocusBlock>
            ))}
        </SelectBox>
        {!!this.state.errors.calendar_id && <ErrorText text={this.state.errors.calendar_id} />}
      </div>
      <div className="mt-20">
        <Row flexWidth={12} alignItems="center" justifyContent="space-between">
          <Row flexWidth={6}>
            <div className="w-1-1">
              <label className="item-box-tagline font-bold  mb-10">Start date</label>
              <DatePicker
                onChange={this.onDateTimePickerChange('start_time', 'date')}
                value={this.state.start_time}
                calendarIcon={null}
                clearIcon={null}
                showLeadingZeros
                format={calendarDateFormat}
              />
            </div>
          </Row>
          <Row flexWidth={6}>
            <div className="ml-20 w-1-1">
              <label className="item-box-tagline font-bold mb-10">Start time</label>
              <TimePickerGrid>
                <TimePicker
                  onChange={this.onDateTimePickerChange('start_time', 'time')}
                  value={this.state.start_time}
                  clearIcon={null}
                  clockIcon={null}
                  disableClock={true}
                />
                <Para fontSize={14}>({Locale.namedOffset})</Para>
              </TimePickerGrid>
            </div>
          </Row>
        </Row>
        <div className="mt-20">
          <Row flexWidth={12} alignItems="center" justifyContent="space-between">
            <Row flexWidth={6}>
              <div className="w-1-1">
                <label className="item-box-tagline font-bold mb-10">End date</label>
                <DatePicker
                  onChange={this.onDateTimePickerChange('end_time', 'date')}
                  value={this.state.end_time}
                  calendarIcon={null}
                  clearIcon={null}
                  showLeadingZeros
                  format={calendarDateFormat}
                />
              </div>
            </Row>
            <Row flexWidth={6}>
              <div className="ml-20 w-1-1">
                <label className="item-box-tagline font-bold mb-10">End time</label>
                <TimePickerGrid>
                  <TimePicker
                    onChange={this.onDateTimePickerChange('end_time', 'time')}
                    value={this.state.end_time}
                    clearIcon={null}
                    clockIcon={null}
                    disableClock={true}
                  />
                  <Para fontSize={14}>({Locale.namedOffset})</Para>
                </TimePickerGrid>
              </div>
            </Row>
          </Row>
          {['time', 'start_time', 'end_time'].some(t => !!this.state.errors[t]) &&
            ['time', 'start_time', 'end_time'].map((t, i) => (
              <div key={i}>
                {!!this.state.errors[t] && <ErrorText text={this.state.errors[t]} />}
              </div>
            ))}
        </div>
      </div>
      {repitition.call(this)}
      {rotation.call(this)}
    </div>
  );
}

export default shiftCreateOrUpdate;
