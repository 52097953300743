import {
  Autocomplete,
  CardBlock,
  ErrorBlock,
  FocusBlock,
  FormBlock,
  Grid,
  Heading,
  IconButton,
  InputBlock,
  Label,
  Para,
  Pill,
  SpinLoader,
  TextButton,
  Theme,
  Tooltip,
} from 'uie/components';
import { BillingService } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import { CloseIcon, UserIcon } from 'icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { findNonCommonObjects } from '../../helpers';
import { useStakeHolders } from '../../hooks/stakeholders.hooks';
import { IStakeHolderProps } from '../../types/stakeholder.types';
import { getFilteredUsers, getOrganizationUsers } from '../../utils/utils.stakeholders';

interface AddStakeHolderGroupPropType extends IStakeHolderProps {
  isEditMode: boolean;
  groupName?: string;
  stakeHolders?: any;
  closeAddCard: () => void;
  stakeHolderGroupdId?: string;
  refetch: () => void;
}

function AddStakeHolderGroup(props: AddStakeHolderGroupPropType) {
  const { theme } = Theme;
  const { team, organization, isEditMode, stakeHolders, groupName, closeAddCard, refetch } = props;
  const { addStakeHolderGroup, addingStakeHolders, updateStakeHolderGroup, errorMessage } =
    useStakeHolders();
  const [STKGroupName, setSTKGroupName] = useState(groupName ?? '');
  const [stkGroupMembers, setSTKGroupMemebrs] = useState(stakeHolders ?? []);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const stakeholderLimit = useMemo(() => {
    if (organization)
      return BillingService.getLimit(
        { organization } as unknown as Pick<IAppState, 'organization'>,
        'stakeholder-group-members',
      );
  }, [organization]);

  const canAddMoreStakeholders = useMemo(() => {
    return stakeholderLimit === 'unlimited' || stkGroupMembers.length < Number(stakeholderLimit);
  }, [stakeholderLimit, stkGroupMembers]);

  useEffect(() => {
    if (isEditMode && groupName) {
      setSTKGroupName(groupName);
      setSTKGroupMemebrs(stakeHolders);
    }
  }, [groupName, stakeHolders, isEditMode]);

  const cardHeader = useMemo(() => {
    if (isEditMode) return 'Edit';
    return 'New Group';
  }, []);

  const _oUsers = useMemo(() => {
    return getOrganizationUsers(organization);
  }, [organization]);

  const filteredUsers = useMemo(() => {
    const filter = getFilteredUsers(team, organization, searchTerm);
    return findNonCommonObjects(filter, stkGroupMembers);
  }, [team, organization, searchTerm, stkGroupMembers]);

  const addStakeHoldersToState = useCallback(
    (users: any) => {
      if (canAddMoreStakeholders) setSTKGroupMemebrs([...stkGroupMembers, users]);
    },
    [stkGroupMembers, stakeholderLimit],
  );

  const renderUserList = useMemo(() => {
    return filteredUsers.map((m, i) => {
      return (
        <FocusBlock key={i} value={m.id} disabled={!_oUsers[m.id]}>
          <Para className="global--text-align--left" fontSize={14}>
            {_oUsers[m.id]?.name ?? 'Deleted'}
          </Para>
        </FocusBlock>
      );
    });
  }, [filteredUsers]);

  const addStakeHolders = () => {
    if (isEditMode && props.stakeHolderGroupdId) {
      updateStakeHolderGroup(
        props.stakeHolderGroupdId,
        STKGroupName,
        stkGroupMembers,
        team.id,
        () => {
          closeAddCard();
          refetch();
        },
      );
    } else {
      addStakeHolderGroup(STKGroupName, stkGroupMembers, team.id, () => {
        closeAddCard();
        refetch();
      });
    }
  };

  const removeStakeHolder = useCallback(
    (userId: number) => {
      const filteredArray = stkGroupMembers.filter((stk: any) => {
        return stk.user_id != userId;
      });

      setSTKGroupMemebrs(filteredArray);
    },
    [stkGroupMembers],
  );

  const renderPills = useMemo(() => {
    return (stkGroupMembers || []).map((k: any, index: number) => {
      return (
        <Pill className="mt-10 mr-10" key={index}>
          <Grid alignItems="center">
            <UserIcon height={14} width={14} />
            <Para className="ml-5">{k.name}</Para>
            <IconButton
              onClick={() => removeStakeHolder(k.user_id)}
              color="transparent"
              className="ml-10"
            >
              <CloseIcon height={14} width={14} />
            </IconButton>
          </Grid>
        </Pill>
      );
    });
  }, [stkGroupMembers]);

  return (
    <CardBlock
      key={-99}
      className="mb-10 mt-10"
      padding="8px"
      animateOnHover={true}
      borderStyle="sharp"
    >
      <Grid type="column" width="700px">
        <Heading fontSize={16} height={24}>
          {cardHeader}{' '}
        </Heading>
        <FormBlock onFormSubmit={() => {}}>
          <div className="mt-10">
            <Label fontSize={14}>Name</Label>
            <div className="mt-5">
              <InputBlock
                height="32px"
                width="240px"
                padding="4px"
                placeholder="Stakeholder group name"
                value={STKGroupName}
                onChange={e => {
                  setSTKGroupName(e.target.value);
                }}
                error={false}
              />
              <ErrorBlock></ErrorBlock>
            </div>
          </div>

          <div>
            <Label fontSize={14}>Stakeholders</Label>
            <Grid alignItems="center" justifyContent="flex-start" flexWrap="wrap">
              {stkGroupMembers.length === 0 && (
                <Para fontSize={14} color={theme.font.disabled}>
                  No assigned stakeholders
                </Para>
              )}
              {renderPills}
            </Grid>
            <div className="mt-10">
              {canAddMoreStakeholders ? (
                <Autocomplete
                  onValueChange={(_: any, value: string) => {
                    addStakeHoldersToState({
                      name: _.target.textContent,
                      user_id: value,
                    });
                  }}
                  // maxHeight="200px"
                  height="auto"
                  style={{ height: 'auto' }}
                  width="240px"
                  value={searchTerm}
                  onChange={e => {
                    setSearchTerm(e.target.value);
                  }}
                  placeHolder="Search stakeholders"
                  showDropDownOnSelect={true}
                  searchPropsHook={{
                    height: 32,
                    style: {
                      height: 32,
                      padding: 4,
                    },
                  }}
                  error={false}
                >
                  {renderUserList}
                </Autocomplete>
              ) : (
                <ErrorBlock>{`Max stakeholders limit of ${stakeholderLimit} reached`}</ErrorBlock>
              )}
              {Boolean(errorMessage.length) && <ErrorBlock>{errorMessage}</ErrorBlock>}
            </div>
          </div>
        </FormBlock>
        <div className="mt-10">
          <Grid alignItems="center">
            <TextButton
              onClick={addStakeHolders}
              color={theme.primary.default}
              disabled={addingStakeHolders || !STKGroupName || !stkGroupMembers.length}
              height="26px"
            >
              <Grid alignItems="center">
                <Para color={theme.font.white}>Save</Para>
                {addingStakeHolders && (
                  <SpinLoader className="ml-10" color={theme.shades.white} base="8px" />
                )}
              </Grid>
            </TextButton>
            <TextButton
              onClick={() => closeAddCard()}
              buttonType="ghost"
              className="ml-10"
              disabled={false}
            >
              <Para color={theme.font.default}>Cancel</Para>
            </TextButton>
          </Grid>
        </div>
      </Grid>
    </CardBlock>
  );
}

export default React.memo(AddStakeHolderGroup);
