import { Box, Flex, VStack } from '@chakra-ui/layout';
import LCRTable from './LCRTable';
import { useHistory } from 'react-router';
import { LCR_CREATE_PATH } from 'views/main/routes/routes';
import { API } from 'core';
import { shallowEqual, useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import { useGetLCRLimit } from '../../hooks/lcrListing';
import { LCREmptyUIIcon } from 'icons';
import { Slider, SliderFilledTrack, SliderTrack } from '@chakra-ui/slider';
import { PageHeader } from 'library/molecules';
import { Text } from 'library/atoms/Text';
import { useUserAccess } from 'core/userAccess/UserAccessContext';

const LCRDashboard = () => {
  const teamId = API.config.teamId;
  const organization = useSelector((state: IAppState) => state.organization, shallowEqual);
  const team = organization.teams.t.find(t => t.id === teamId);
  const userAccess = useUserAccess();

  const hasLCRReadAccess = userAccess.hasReadAccess('routing_numbers');

  return hasLCRReadAccess ? <LCRList /> : <LCRNoPermissionList />;
};

const LCRList = () => {
  const history = useHistory();
  const teamId = API.config.teamId;
  const organization = useSelector((state: IAppState) => state.organization, shallowEqual);
  const team = organization.teams.t.find(t => t.id === teamId);
  const lcrLimit = useGetLCRLimit();
  const userAccess = useUserAccess();

  const hasLCRCreateAccess = userAccess.hasCreateAccess('routing_numbers');

  return (
    <VStack h="inherit" spacing={0} bg="container.background">
      <PageHeader
        title="Live Call Routing"
        desc="Live Call Routing allows end users to call a number and route those incoming calls and voicemails to the on-call responders."
        button={hasLCRCreateAccess ? 'Add New Routing Number' : ''}
        noButton={hasLCRCreateAccess ? false : true}
        onButtonClick={() => history.push(LCR_CREATE_PATH)}
      />
      <Flex p={5} w="100%" flexDirection="column">
        <Slider
          w={'264px'}
          min={0}
          max={lcrLimit.data?.total_available_minutes}
          value={lcrLimit.data?.minutes_used}
        >
          <SliderTrack height={'6px'} borderRadius="6px">
            <SliderFilledTrack color={'#1B7FF1'} />
          </SliderTrack>
        </Slider>
        <Flex justifyContent={'space-between'} w="264px" marginTop={2}>
          <Text variant="formInput_800">
            Used{' '}
            <Text as="span" variant="formInput">
              {lcrLimit.data?.minutes_used} mins
            </Text>{' '}
          </Text>
          <Text variant="formInput_800">
            Total
            <Text as="span" variant="formInput">
              {' '}
              {lcrLimit.data?.total_available_minutes} mins
            </Text>{' '}
          </Text>
        </Flex>
      </Flex>
      <LCRTable />
    </VStack>
  );
};

const LCRNoPermissionList = () => {
  return (
    <VStack style={{ marginTop: '30vh' }}>
      <Box
        width="full"
        height="full"
        bg="container.background"
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}
      >
        <LCREmptyUIIcon height={300} width={300} />
      </Box>
      <Box
        width="full"
        height="full"
        bg="container.background"
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}
      >
        <Text variant="h1" textStyle="bold_800">
          You do not have permission to view LCR.{' '}
        </Text>
      </Box>
    </VStack>
  );
};

export default LCRDashboard;
