import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { REQUEST_USER_INFO, REQUEST_USER_INFO_FAIL } from '../../const/auth';
import { onRequestUserInfoSuccess } from '../../actions/auth';
import { getUserInfo } from '../../api/auth';
import { AjaxResponse } from 'rxjs/ajax';
import { IUserInfo } from '../../interfaces/IUserData';

const getUserInfoEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_USER_INFO),
    map(action => action),
    switchMap(() => {
      return getUserInfo().pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ result, data: userInfo }: { result: boolean; data: IUserInfo }) => {
          return onRequestUserInfoSuccess(userInfo);
        }),
        catchError(error =>
          of({
            type: REQUEST_USER_INFO_FAIL,
            payload: error.xhr,
            error: true,
          }),
        ),
      );
    }),
  );

export default getUserInfoEpic;
