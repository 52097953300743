import {
  IServiceNowResponse,
  ServiceNowConfigurationPayload,
  ServiceNowUpdateConfigurationPayload,
} from 'core/interfaces/IExtensions';
import { useMutation } from 'react-query';
import {
  reactQueryConfigError,
  reactQueryConfigSuccess,
  reactQueryConfigWarning,
} from '../../jira-cloud/helpers/service.reactqery';
import { ServiceNowSvc } from 'core/services';
import { AppTracker } from 'shared/analytics/tracker';
import {
  TA_WA_UP_SNW_EXTENSION_EDITED,
  TA_WA_UP_SNW_EXTENSION_INTEGRATED,
} from 'core/const/tracker';

const sync_mode: { [key: string]: string } = {
  auto: 'Automatic',
  manual: 'Manual',
};

const status: { [key: string]: string } = {
  not_integrated: 'Not Integrated',
  partially_integrated: 'Partially Integrated',
  integrated: 'Integrated',
};

async function SaveServiceNowConfig(payload: ServiceNowConfigurationPayload) {
  const _servicenowSvc = new ServiceNowSvc();
  const data = await _servicenowSvc.saveServiceNowConfig(payload);
  return data.data.data;
}

async function UpdateServiceNowConfig(payload: ServiceNowUpdateConfigurationPayload) {
  const _servicenowSvc = new ServiceNowSvc();
  const data = await _servicenowSvc.updateServiceNowConfig(payload);
  return data.data.data;
}

const useSaveServiceNowConfig = (cb: () => void) => {
  return useMutation(
    'save-configuration',
    (payload: ServiceNowConfigurationPayload) => SaveServiceNowConfig(payload),
    {
      onSuccess: (data: IServiceNowResponse) => {
        cb();
        AppTracker.track(TA_WA_UP_SNW_EXTENSION_INTEGRATED, {
          'Servicenow Sync Type': sync_mode[data.sync_mode],
          'Servicenow Extension Completion Status': status[data.status],
        });
        if (data?.status === 'partially_integrated')
          reactQueryConfigWarning({
            title:
              'ServiceNow integration is incomplete because the Integration key is not activated',
            description: '',
          });
        else reactQueryConfigSuccess('ServiceNow integrated successfully');
      },
      onError: (error: any) => {
        const description =
          error?.response?.data?.meta?.error_message || 'Could not save serviceNow configuration';
        reactQueryConfigError({ description: description, title: 'Save Config' });
      },
    },
  );
};

const useUpdateServiceNowConfig = (wasEnabled: boolean, cb: () => void) => {
  return useMutation(
    'update-configuration',
    (payload: ServiceNowUpdateConfigurationPayload) => UpdateServiceNowConfig(payload),
    {
      onSuccess: (data: IServiceNowResponse) => {
        AppTracker.track(TA_WA_UP_SNW_EXTENSION_EDITED, {
          'Servicenow Extension Enabled / Disabled / Other Changes':
            wasEnabled === data.is_enabled ? 'No Change' : data.is_enabled ? 'Enabled' : 'Disabled',
        });
        cb();
        if (!data.is_enabled) {
          reactQueryConfigSuccess('ServiceNow Integration disabled temporarily');
          return;
        }
        if (data?.status === 'partially_integrated')
          reactQueryConfigWarning({
            title:
              'ServiceNow integration is incomplete because the Integration key is not activated',
            description: '',
          });
        else reactQueryConfigSuccess('ServiceNow Integration updated successfully');
      },
      onError: (error: any) => {
        const description =
          error?.response?.data?.meta?.error_message || 'Could not update serviceNow configuration';
        reactQueryConfigError({ description: description, title: 'Update Config' });
      },
    },
  );
};

export { useSaveServiceNowConfig, useUpdateServiceNowConfig };
