import React, { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { CautionIcon, WarnIcon, CheckIcon } from '../../utils/icons';
import Grid from '../Grid';

interface IToastContainer extends HTMLAttributes<HTMLDivElement> {
  maxWidth?: string | 'fit-content';
  maxHeight?: string | 'fit-content';
}

/** @component */
const ToastBlock = styled.div<IToastContainer>`
  width: ${props => props.maxWidth || 'fit-content'};
  max-width: ${props => props.maxWidth || '100%'};
  max-height: ${props => props.maxHeight || 'fit-content'};
  padding: 8px;
  border-radius: 3px;
  pointer-events: none;
`;

interface IProps extends IToastContainer {
  children: ReactNode;
}

const ErrorToastBlock = ({ children, ...props }: IProps) => (
  <ToastBlock {...props}>
    <Grid alignItems="baseline">
      <CautionIcon width={16} height={16} style={{ marginRight: 8, minWidth: 16, marginTop: 4 }} />
      <div style={{ marginTop: -4 }}>{children}</div>
    </Grid>
  </ToastBlock>
);

const WarnToastBlock = ({ children, ...props }: IProps) => (
  <ToastBlock {...props}>
    <Grid alignItems="baseline">
      <WarnIcon width={16} height={16} style={{ marginRight: 8, minWidth: 16, marginTop: 4 }} />
      <div style={{ marginTop: -4 }}>{children}</div>
    </Grid>
  </ToastBlock>
);

const SuccessToastBlock = ({ children, ...props }: IProps) => (
  <ToastBlock {...props}>
    <Grid alignItems="baseline">
      <CheckIcon width={16} height={16} style={{ marginRight: 8, minWidth: 16, marginTop: 4 }} />
      <div style={{ marginTop: -8 }}>{children}</div>
    </Grid>
  </ToastBlock>
);

/** @component */
export { ToastBlock, SuccessToastBlock, WarnToastBlock, ErrorToastBlock };
