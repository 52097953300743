import { PAYLOAD_VALIDATION_RESPONSE } from 'core/interfaces/IEventWebHooks';
import { TRIGGER_OPTIONS, WEBHOOK_TYPES } from '../webhook-const';
import { PAYLOAD_VALIDATION_MODEL } from './payload-validation.type';

export class PayloadValidationAdapter {
  adaptToModel(res: PAYLOAD_VALIDATION_RESPONSE[], webhookType: WEBHOOK_TYPES) {
    const data: PAYLOAD_VALIDATION_MODEL[] = [];

    if (Array.isArray(res) && res.length) {
      res.forEach(item => {
        let name: string;
        if (webhookType === WEBHOOK_TYPES.MANUAL) {
          name = 'Manually Triggered Webhook';
        } else {
          name =
            TRIGGER_OPTIONS.find(
              (opt: any) =>
                opt.event_class === item.event_class && opt.event_type === item.event_type,
            )?.label ?? '';
        }
        const valItem: PAYLOAD_VALIDATION_MODEL = {
          name,
          isPassed: item.is_success,
          errorMsg: item.error_message,
          output: item.output,
        };
        data.push(valItem);
      });
    }
    return data;
  }

  adaptFromModel(data: any) {
    throw 'Method not implemented';
  }
}
