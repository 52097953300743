import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#161616">
      <path d="M9.851 12.38a2.381 2.381 0 1 0 0-4.762 2.381 2.381 0 0 0 0 4.763Z" />
      <path d="M9.851 0C5.191 0 1.277 3.187.167 7.5a.477.477 0 0 0 .46.595h4.032a.471.471 0 0 0 .431-.28l.01-.017a5.238 5.238 0 1 1 0 4.4l-.009-.018a.475.475 0 0 0-.431-.279H.627a.476.476 0 0 0-.46.595c1.11 4.312 5.026 7.5 9.685 7.5 5.523-.001 10.008-4.478 10.008-10s-4.477-10-10-10L9.852 0Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
