import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { REQUEST_ORG_RUNBOOK, REQUEST_ORG_RUNBOOK_FAIL } from '../../const/organization';
import { onRequestOrganizationRunbookSuccess } from '../../actions/organization/runbooks';
import { IRunbook } from '../../interfaces/IRunBook';
import { getOrgRunbooks } from '../../api/organization/getRunbook';
import { EntityACLMeta } from 'core/userAccess/types';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';

const getOrganizationsRunbookEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_ORG_RUNBOOK),
    map(action => action.payload),
    switchMap((runbookData: { entity_owner?: string }) => {
      return getOrgRunbooks(runbookData.entity_owner || '').pipe(
        map(({ response }: AjaxResponse) => response),
        map(
          ({
            result,
            data,
            meta,
          }: {
            result: boolean;
            data: IRunbook[];
            meta: { acl: Record<string, EntityACLMeta> };
          }) => {
            setUserAccessStore({
              entityACLMap: {
                ...getUserAccessStore().entityACLMap,
                runbooks: {
                  ...getUserAccessStore().entityACLMap?.runbooks,
                  ...meta.acl,
                },
              },
            });
            return onRequestOrganizationRunbookSuccess(data);
          },
        ),
        catchError((error: AjaxError) => {
          if (error.status === 403) {
            return of(onRequestOrganizationRunbookSuccess([]));
          }

          return of({
            type: REQUEST_ORG_RUNBOOK_FAIL,
            payload: error.xhr,
            error: true,
          });
        }),
      );
    }),
  );

export default getOrganizationsRunbookEpic;
