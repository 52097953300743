import React from 'react';
import chakraUiTheme from '@chakra-ui/theme';
import {
  VStack,
  Button,
  FormControl,
  FormLabel,
  Box,
  FormErrorMessage,
  HStack,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import {
  Draggable,
  DropResult,
  DragDropContext,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd';
import { rotationsCustomizePattern } from '../../constants/rotations.customize';
import { INewSchedule } from '../../interface/schedule';
import {
  useGetAllPatternParticipants,
  IPatternParticipant,
  reOrderParticipants,
} from '../../helpers/helpers.customrotations';
import { useScheduleActionsContext } from '../../schedules.actions/context';
import ParticipantGroupContainer from './participantGroupContainer';
import { ScheduleActionTypes } from '../../constants/schedules.copy';
import { OwnerType } from 'views/main/organization/schedules/graphql/types';

type ErrorMessageObject = {
  participantGroups: string;
};

const PatternParticipants = ({ rotationId }: any) => {
  const {
    values: { rotations },
    errors,
    handleChange,
  } = useFormikContext<INewSchedule>();

  const { scheduleAction, participantsGroupToEdit, onClickEditParticipantGroup } =
    useScheduleActionsContext();

  const currentRotationParticipants = rotations.find(rt => rt.id === rotationId)?.participantGroups;

  const currentParticipantIDs = currentRotationParticipants
    ?.map(group => group.participants?.map(p => p?.id))
    ?.flat();

  const allPatternParticipants: Array<IPatternParticipant> = useGetAllPatternParticipants().filter(
    p => p.type !== OwnerType.Team && !currentParticipantIDs?.includes(p.value),
  );

  const currentRotationIndex = rotations.findIndex(rt => rt.id === rotationId);

  const addParticipantGroupHandler = () => {
    const idOfNewGroup = `Group_${currentRotationParticipants?.length}`;
    scheduleAction?.type === ScheduleActionTypes.EDIT_PARTICIPANTS &&
      onClickEditParticipantGroup(rotationId, idOfNewGroup);
    handleChange({
      target: {
        name: `rotations.${currentRotationIndex}.participantGroups.${currentRotationParticipants?.length}`,
        value: { participants: [], groupId: idOfNewGroup },
      },
    });
  };

  const changeParticipantsHandler = (participantGroupValue: any, groupIndex: number) => {
    const participantGroups = participantGroupValue;
    handleChange({
      target: {
        name: `rotations.${currentRotationIndex}.participantGroups.${groupIndex}`,
        value: {
          participants: participantGroups,
          groupId: rotations[currentRotationIndex].participantGroups?.[groupIndex]?.groupId
            ? rotations[currentRotationIndex].participantGroups?.[groupIndex]?.groupId
            : `Group_${groupIndex}`,
        },
      },
    });

    //Check if the participant group was emptied
    if (participantGroups.length === 0) {
      const changedGroupId =
        rotations[currentRotationIndex].participantGroups?.[groupIndex]?.groupId;
      const updatedParticipantGroups = rotations[currentRotationIndex].participantGroups?.filter(
        pg => pg.groupId !== changedGroupId,
      );
      handleChange({
        target: {
          name: `rotations.${currentRotationIndex}.participantGroups`,
          value: updatedParticipantGroups,
        },
      });
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedParticipants = reOrderParticipants(
      currentRotationParticipants,
      result.source.index,
      result.destination.index,
    );

    handleChange({
      target: {
        name: `rotations.${currentRotationIndex}.participantGroups`,
        value: [...reorderedParticipants],
      },
    });
  };

  const ParticipantGroupWrapper = ({ participants, children, groupId, isGroupEditable }: any) => {
    if (
      !scheduleAction ||
      (scheduleAction && scheduleAction.type! !== ScheduleActionTypes.EDIT_PARTICIPANTS) ||
      isGroupEditable
    ) {
      return <React.Fragment>{children}</React.Fragment>;
    }

    return (
      <HStack
        w="100%"
        _hover={{
          '& .group-actions': { display: 'flex', marginRight: '8px' },
          'background-color': `${chakraUiTheme.colors.blue['50']}`,
          '& .group-indicator': { background: `${chakraUiTheme.colors.blue['100']}` },
        }}
        borderRadius="6px"
        borderWidth="1px"
      >
        {children}
        <HStack display="none" className="group-actions">
          <Button
            variant="invertedDefault"
            onClick={() => {
              onClickEditParticipantGroup(rotationId, groupId);
            }}
          >
            Edit
          </Button>
        </HStack>
      </HStack>
    );
  };

  const isParticipantGroupEditable = (groupId: string) => {
    return (
      participantsGroupToEdit?.participantGroupId === groupId &&
      participantsGroupToEdit?.rotationId === rotationId
    );
  };

  return (
    <FormControl
      isInvalid={
        !!(errors.rotations && errors.rotations[currentRotationIndex]
          ? (errors.rotations[currentRotationIndex] as ErrorMessageObject).participantGroups
          : false)
      }
    >
      <VStack align="left" mt={3}>
        <FormLabel htmlFor="participants" variant="rotationPattern">
          {rotationsCustomizePattern.labels.participants}
        </FormLabel>
        {currentRotationParticipants && currentRotationParticipants.length === 0 ? (
          <ParticipantGroupContainer
            onChange={(value: any) => changeParticipantsHandler(value, 0)} //index 0 is hardcoded to make update in the first group initially
            groupNumber={1}
            options={allPatternParticipants}
          />
        ) : (
          <Box>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {(droppableProvided: DroppableProvided) => (
                  <VStack ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                    {currentRotationParticipants?.map(
                      (participantGroup: any, index: number): any => {
                        const defaultValue = participantGroup.participants;

                        return (
                          <Draggable
                            key={participantGroup.groupId}
                            draggableId={participantGroup.groupId}
                            index={index}
                          >
                            {(provided, snapshot) => {
                              const otherProps = {
                                ...provided.draggableProps,
                                style: {
                                  ...provided.draggableProps.style,
                                  width: snapshot.isDragging ? '80%' : '100%',
                                },
                              };

                              return (
                                <ParticipantGroupWrapper
                                  participants={defaultValue}
                                  groupId={participantGroup.groupId}
                                  isGroupEditable={
                                    scheduleAction?.type === ScheduleActionTypes.EDIT_PARTICIPANTS
                                      ? isParticipantGroupEditable(participantGroup.groupId)
                                      : true
                                  }
                                >
                                  <ParticipantGroupContainer
                                    onChange={(value: any) =>
                                      changeParticipantsHandler(value, index)
                                    }
                                    groupNumber={index + 1}
                                    options={allPatternParticipants}
                                    defaultValue={defaultValue}
                                    groupRef={provided.innerRef}
                                    {...otherProps}
                                    {...provided.dragHandleProps}
                                    showDragIcon={
                                      currentRotationParticipants.length > 1 ? true : false
                                    }
                                    isDisabled={
                                      scheduleAction?.type === ScheduleActionTypes.EDIT_PARTICIPANTS
                                        ? !isParticipantGroupEditable(participantGroup.groupId)
                                        : false
                                    }
                                    showTZOffsetOnHover
                                  />
                                </ParticipantGroupWrapper>
                              );
                            }}
                          </Draggable>
                        );
                      },
                    )}
                    {droppableProvided.placeholder}
                  </VStack>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        )}
        <Button
          disabled={
            currentRotationParticipants
              ? currentRotationParticipants?.length === 0
                ? currentRotationParticipants?.length === 0
                : currentRotationParticipants[currentRotationParticipants?.length - 1]?.participants
                    ?.length === 0
              : true
          }
          variant="invertedDefault"
          size="xs"
          alignSelf="flex-start"
          onClick={addParticipantGroupHandler}
        >
          {rotationsCustomizePattern.customRepeat.labels.addParticipants}
        </Button>
      </VStack>
      <FormErrorMessage>
        {errors.rotations && errors.rotations[currentRotationIndex]
          ? (errors.rotations[currentRotationIndex] as ErrorMessageObject).participantGroups
          : ''}
      </FormErrorMessage>
    </FormControl>
  );
};

export default PatternParticipants;
