import {
  L_REQUEST_AP_DISABLE_PREVIEW,
  L_REQUEST_AP_ENABLE_PREVIEW,
  L_REQUEST_AP_APP_ENABLED_PREVIEW,
} from '../../const/appPreview';
import { IAppPreviewType } from '../../interfaces/IAppPreview';

const addUserToPreview = (preview: IAppPreviewType) => ({
  type: L_REQUEST_AP_ENABLE_PREVIEW,
  payload: {
    preview,
  },
});

const removeUserFromPreview = (preview: IAppPreviewType) => ({
  type: L_REQUEST_AP_DISABLE_PREVIEW,
  payload: {
    preview,
  },
});

const addToAppPreview = (appPreviews: IAppPreviewType[]) => ({
  type: L_REQUEST_AP_APP_ENABLED_PREVIEW,
  payload: {
    appPreviews,
  },
});

export { addUserToPreview, removeUserFromPreview, addToAppPreview };
