import * as React from 'react';
import { ErrorBlock, Grid, Para, PulseLoader, SpinLoader, TextButton, Theme } from 'uie/components';
import { ISlackTeamResponse } from '../../../../../../../core/interfaces/IExtensions';
import { LocalStore } from '../../../../../../../store/local';
import { API } from '../../../../../../../core/api';
import { ExtensionService } from '../../../../../../../core/services';
import { exception } from '../../../../../../../core/exception';
import {
  IComponentState,
  IComponentErrorState,
  IComponentNetworkState,
} from '../../../../../../../core/interfaces/IComponentState';
import { CheckCircle } from '../../../../../../../icons';
import { AppTracker } from '../../../../../../../shared/analytics/tracker';
import { T_WA_GS_SLACK_REVOKED } from '../../../../../../../core/const/tracker';

const { theme } = Theme;

interface IProps {
  authorized: boolean;
  display: boolean;
  close: () => void;
  integrateError: string | null;
}

interface IState {
  componentState: IComponentState;
  componentErrorState: IComponentErrorState;
  componentNetworkState: IComponentNetworkState;
  team: ISlackTeamResponse | null;
}

class SlackAuth extends React.Component<IProps, IState> {
  _slackIntegration = API.config.integration.slack;
  _extensionService = new ExtensionService();

  constructor(props: IProps) {
    super(props);

    this.state = {
      componentState: props.integrateError ? 'error' : 'init',
      componentNetworkState: 'idle',
      componentErrorState: {},
      team: null,
    };
  }

  componentDidMount() {
    if (this.props.authorized) {
      this.getSlackTeam();
    }
  }

  getSlackTeam = async () => {
    try {
      this.setState({ componentState: 'busy' });
      const {
        data: { data: team },
      } = await this._extensionService.getSlackTeam();
      this.setState({ team, componentState: 'idle' });
    } catch (err: any) {
      exception.handle('E_SLACK_GET_TEAM', err);
      this.setState({
        componentState: 'error',
        componentErrorState: {
          E_SLACK_GET_TEAM: err?.response?.data?.meta?.error_message ?? 'Network Error',
        },
      });
    }
  };

  integrateWithSlack = async () => {
    this.setState({ componentNetworkState: 'request' });

    const client_id = this._slackIntegration.id;
    const redirect_uri = this._slackIntegration.redirects + '/setup-organization-slack';

    const scopes = [
      // bot token scopes
      'app_mentions:read',
      'channels:join',
      'channels:read',
      'chat:write',
      'commands',
      'groups:read',
      'groups:write',
      'im:read',
      'im:write',
      'mpim:read',
      'mpim:write',
      'team:read',
      'users:read',
      'users:read.email',
    ];

    // user token scopes
    const userScopes = [
      'channels:read',
      'channels:write',
      'groups:read',
      'groups:write',
      // 'identify',
      'users:read',
      'users:read.email',
    ];

    const slackState = new Date().getTime().toString();
    await LocalStore.setSlackState(slackState);
    window.location.href =
      'https://slack.com/oauth/v2/authorize?client_id=' +
      client_id +
      '&redirect_uri=' +
      redirect_uri +
      '&state=' +
      slackState +
      '&scope=' +
      scopes.join(',') +
      '&user_scope=' +
      userScopes.join(',');
  };

  revokeSlack = async () => {
    try {
      this.setState({ componentNetworkState: 'request' });
      AppTracker.track(T_WA_GS_SLACK_REVOKED);
      await this._extensionService.revoke('slack');
      this.setState({ componentNetworkState: 'success' });
      this.props.close();
    } catch (err: any) {
      exception.handle('E_SLACK_REVOKE', err);
      this.setState({
        componentNetworkState: 'error',
        componentErrorState: {
          E_SLACK_REVOKE: err?.response?.data?.meta?.error_message ?? 'Network Error',
        },
      });
    }
  };

  renderUnauthorized = () => {
    const { componentState, componentNetworkState } = this.state;
    const { integrateError } = this.props;
    return (
      <>
        {componentState === 'error' && (
          <Grid alignItems="center" justifyContent="flex-start">
            <ErrorBlock>{integrateError}</ErrorBlock>
          </Grid>
        )}
        {componentState === 'init' && (
          <>
            <Grid justifyContent="flex-start" className="mb-20">
              <Para color={theme.shades.smoke}>
                In this step, you will be asked to{' '}
                <span className="font-bold">choose a Slack workspace</span>. Choose a workspace
                where this integration will be used for.
              </Para>
            </Grid>
            <Grid justifyContent="flex-end">
              <TextButton
                width="100px"
                height="36px"
                onClick={this.integrateWithSlack}
                disabled={componentNetworkState === 'request'}
              >
                {componentNetworkState === 'request' ? (
                  <PulseLoader color={theme.shades.white} base="6px" />
                ) : (
                  <Para fontSize={16} color={theme.shades.white} fontWeight={500}>
                    Continue
                  </Para>
                )}
              </TextButton>
            </Grid>
          </>
        )}
      </>
    );
  };

  renderAuthorized = () => {
    const { componentState, componentErrorState, team, componentNetworkState } = this.state;
    return (
      <>
        {componentState === 'busy' && (
          <Grid alignItems="center" justifyContent="center">
            <SpinLoader base="16px" />
            <Para className="ml-10">Loading Slack Workspace</Para>
          </Grid>
        )}
        {componentState === 'error' && (
          <Grid alignItems="center" justifyContent="flex-start">
            <ErrorBlock>{componentErrorState['E_SLACK_GET_TEAM']}</ErrorBlock>
          </Grid>
        )}
        {componentState === 'idle' && (
          <>
            <Grid alignItems="center" justifyContent="flex-start" className="mb-20">
              <CheckCircle fill={theme.success.default} stroke={theme.shades.white} />
              <Para className="ml-10" color={theme.shades.smoke}>
                Successfully authorized the Slack workspace
              </Para>
            </Grid>
            <Grid justifyContent="space-around" className="mb-20" flexWidth={7}>
              <Grid>
                <img src={team?.icon.image_230} height="50px" width="50px" alt="workspace_icon" />
              </Grid>
              <Grid type="column" style={{ marginTop: 4 }}>
                <Para fontWeight={500}>{team?.name}</Para>
                <Para
                  fontWeight={500}
                  color={theme.shades.smoke}
                >{`${team?.domain}.slack.com`}</Para>
              </Grid>
            </Grid>
            {componentNetworkState === 'error' && (
              <ErrorBlock className="mt-10 ml-10">
                {componentErrorState['E_SLACK_REVOKE']}
              </ErrorBlock>
            )}
            <Grid justifyContent="flex-end">
              <TextButton
                color={theme.danger.default}
                width="100px"
                height="36px"
                onClick={this.revokeSlack}
                disabled={componentNetworkState === 'request'}
              >
                {componentNetworkState === 'request' ? (
                  <PulseLoader color={theme.shades.white} base="6px" />
                ) : (
                  <Para fontSize={16} color={theme.shades.white} fontWeight={500}>
                    Revoke
                  </Para>
                )}
              </TextButton>
            </Grid>
          </>
        )}
      </>
    );
  };

  render() {
    const { authorized, display } = this.props;

    if (!display) return null;

    return <>{authorized ? this.renderAuthorized() : this.renderUnauthorized()}</>;
  }
}
export default SlackAuth;
