import SQTooltip from 'components/chakra/Tooltip';
import { truncate } from 'views/main/organization/incident-list/common/util';

export const Ellipsis = ({ text, maxLength }: { text: string; maxLength: number }) => {
  return text.length > maxLength ? (
    <SQTooltip text={text} placement="bottom">
      {truncate(text, maxLength)}
    </SQTooltip>
  ) : (
    <>{text}</>
  );
};
