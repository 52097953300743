import { RepeatIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Switch,
  Text,
  Image,
  Button,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { SlackNotifyEvents } from 'core/interfaces/IExtensions';
import { useEffect, useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import { useGetSlackChannels } from './hooks/getSlackChannels';
import { useGetSlackConfig, useGetSlackTeamConfig } from './hooks/getSlackConfig';
import { EventChecked, EVENTS, EventsDetails } from './consts';
import { deepCopy } from 'core';
import { EventNotificationAccordian } from './notification_accordain';
import { AlertDialogComponent } from 'views/shared/components';
import { useRevokeSlack } from './hooks/integrateSlack';
import { THEME_COLORS } from 'library/theme/colors';
import { isEqual, sortBy } from 'lodash';

interface ReactSelectOptions {
  value: string;
  label: string;
}

interface Props {
  onClose: () => void;
  onSaveConfiguration: (
    notifyAllEvents: boolean,
    channelCreationMode: string,
    channelId: string,
    notifyEvents: SlackNotifyEvents[],
    update_to_main_channel: boolean,
  ) => void;
  setisButtonDisabled: (isDirty: boolean) => void;
}

function sortEventTypes(obj: {
  channelName: string;
  channelCreationMode: string;
  notifyAllEvents: boolean;
  channelId: string;
  slackExtensionId: number;
  notifyChannel: boolean;
  slackNotifyEvents: SlackNotifyEvents[];
}) {
  return {
    ...obj,
    slackNotifyEvents: sortBy(obj.slackNotifyEvents, 'event_class').map(event => ({
      ...event,
      event_types: event.event_types.sort(), // Ensure event_types are also sorted
    })),
  };
}

const SlackConfiguration = (props: Props) => {
  const slackConfigurationRes = useGetSlackConfig();
  const slackTeamConfigurationRes = useGetSlackTeamConfig();
  const slackChannelRes = useGetSlackChannels();
  const revokeSlack = useRevokeSlack();

  const [config, setConfig] = useState({
    channelName: '',
    channelCreationMode: '',
    notifyAllEvents: true,
    channelId: '',
    slackExtensionId: 0,
    notifyChannel: true,
    slackNotifyEvents: [] as SlackNotifyEvents[],
  });
  const [channelType, setChannelType] = useState<string>('channel_selection');
  const [events, setEvents] = useState(EVENTS);
  const [notificationType, setNotificationType] = useState('all_notifications');
  const { isOpen, onOpen, onClose } = useDisclosure();

  function onGetNotifyAllEventsValue(notifyAllEvents: boolean, notifyEvents: SlackNotifyEvents[]) {
    setConfig(prev => ({
      ...prev,
      notifyAllEvents: notifyAllEvents,
      slackNotifyEvents: notifyEvents,
    }));
  }

  function onCheckedNotifications(
    accordianEvents: { eventClass: EventChecked; eventTypes: EventChecked[] },
    idx: number,
  ) {
    const updatedEvent = events[idx];
    updatedEvent.eventClass = accordianEvents.eventClass;
    updatedEvent.eventTypes = accordianEvents.eventTypes;
    events[idx] = updatedEvent;

    setEvents([...events]);
  }

  useEffect(() => {
    const config = slackConfigurationRes.data;
    if (!config || config.slack_extension_id === 0) {
      return;
    }

    setConfig({
      channelCreationMode: config.channel_creation_mode,
      channelId: config.channel.id,
      channelName: config.channel.name,
      notifyAllEvents: config.notify_all_events,
      slackExtensionId: config.slack_extension_id || 0,
      slackNotifyEvents: config.notify_events,
      notifyChannel: config.notify_channel,
    });
    setNotificationType(config.notify_all_events ? 'all_notifications' : 'custom_notifications');

    const updatedEvents: EventsDetails[] = deepCopy(EVENTS);
    updatedEvents.forEach((event, index) => {
      const filtedEvent = config.notify_events.filter(
        notificationEvent => notificationEvent.event_class == event.eventClass.type,
      );
      if (filtedEvent.length === 0) {
        return;
      }

      event.eventClass.isChecked = true;
      filtedEvent[0].event_types.forEach(eventTypes => {
        event.eventTypes.forEach(type => {
          if (type.type == eventTypes) {
            type.isChecked = true;
          }
        });
      });
    });

    setEvents(updatedEvents);
  }, [slackConfigurationRes.isSuccess]);

  const channelOptions = useMemo(() => {
    return slackChannelRes.data?.channels.map(channel => {
      const option: ReactSelectOptions = {
        label: '#' + channel.name,
        value: channel.id,
      };
      return option;
    });
  }, [slackChannelRes.isSuccess, slackChannelRes.isRefetching]);

  useEffect(() => {
    const updatedNotifyEvents: SlackNotifyEvents[] = [];
    events.forEach(event => {
      const eventTypes: string[] = [];
      event.eventTypes.forEach(eventType => {
        if (eventType.isChecked) {
          eventTypes.push(eventType.type);
        }
      });
      if (eventTypes.length > 0) {
        updatedNotifyEvents.push({
          event_class: event.eventClass.type,
          event_types: eventTypes,
        });
      }
    });

    onGetNotifyAllEventsValue(notificationType === 'all_notifications', updatedNotifyEvents);
  }, [events, notificationType]);

  useEffect(() => {
    const slackDetail = slackConfigurationRes.data;

    if (slackDetail) {
      /// need to sort obj key so at to test 2nd level equality
      // without it lodash gives false even if obj is same but keys are at random order

      const sortedConfig = sortEventTypes(config);

      const sortedSlackDetail = sortEventTypes({
        channelCreationMode: slackDetail?.channel_creation_mode,
        channelId: slackDetail?.channel.id,
        channelName: slackDetail?.channel.name,
        notifyAllEvents: slackDetail?.notify_all_events,
        slackExtensionId: slackDetail?.slack_extension_id || 0,
        slackNotifyEvents: slackDetail?.notify_events,
        notifyChannel: slackDetail?.notify_channel,
      });

      if (!isEqual(sortedConfig, sortedSlackDetail)) {
        props.setisButtonDisabled(false);
      } else {
        props.setisButtonDisabled(true);
      }
    } else {
      props.setisButtonDisabled(false);
    }

    props.onSaveConfiguration(
      config.notifyAllEvents,
      config.channelCreationMode,
      config.channelId,
      config.slackNotifyEvents,
      config.notifyChannel,
    );
  }, [config]);

  return (
    <>
      {slackConfigurationRes.isLoading || slackTeamConfigurationRes.isLoading ? (
        <Center height={'100%'}>
          <Stack alignItems={'center'}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Stack>
        </Center>
      ) : (
        <>
          <Box>
            <Box mt="0.5rem">
              <Text color={THEME_COLORS.secondary[900]} fontSize="14px">
                The following Slack workspace has been successfully authorized.
              </Text>
              <Stack mt={5} direction={'row'}>
                <Image
                  boxSize="50px"
                  objectFit="cover"
                  src={slackTeamConfigurationRes.data?.icon.image_230}
                  alt="Dan Abramov"
                />
                <Box>
                  <Heading as="h4" size={'md'} color={THEME_COLORS.secondary[900]}>
                    {slackTeamConfigurationRes.data?.name}
                  </Heading>
                  <Text>{slackTeamConfigurationRes.data?.domain + '.slack.com'}</Text>
                </Box>
                <Spacer />
                <Flex alignItems={'center'}>
                  <Button colorScheme="red" size="sm" onClick={onOpen}>
                    Revoke
                  </Button>
                </Flex>
              </Stack>
            </Box>
            <Box mt={5}>
              <Text color={THEME_COLORS.secondary[900]} fontSize="14px">
                Please choose the default channel from the drowndown below to receive incident
                updates. You can also create a different channel, and refresh the dropdown
              </Text>
            </Box>
            <RadioGroup
              mt={5}
              onChange={e => {
                setChannelType(e);
              }}
              value={channelType}
              w="20rem"
            >
              <Stack direction={'column'}>
                <Radio size="md" value={'channel_id'} isChecked={'channel_id' === channelType}>
                  <Text fontSize="sm">Channel ID</Text>
                </Radio>
                <Radio
                  size="md"
                  value={'channel_selection'}
                  isChecked={'channel_selection' === channelType}
                  fontSize="sm"
                >
                  <Text fontSize="sm">Select a channel</Text>
                </Radio>
              </Stack>
            </RadioGroup>

            {channelType === 'channel_selection' ? (
              <Flex alignItems={'center'} mt={5}>
                <Box w="20rem" mr={2}>
                  <ReactSelect
                    onChange={e => {
                      setConfig(prev => ({ ...prev, channelId: e?.value || '' }));
                    }}
                    value={channelOptions?.filter(option => option.value == config.channelId)}
                    options={channelOptions}
                    placeholder="Select Channel"
                    isLoading={slackChannelRes.isLoading}
                  />
                </Box>
                <IconButton
                  icon={<RepeatIcon />}
                  aria-label={'refresh channels'}
                  onClick={() => {
                    slackChannelRes.refetch();
                  }}
                />
              </Flex>
            ) : (
              <Box w="20rem" mt={5}>
                <Input
                  placeholder="Enter channel id"
                  value={config.channelId}
                  onChange={e => {
                    setConfig(prev => ({ ...prev, channelId: e.target.value }));
                  }}
                />
              </Box>
            )}

            <Heading
              as="h4"
              size={'md'}
              my="1rem"
              color={THEME_COLORS.secondary[900]}
              marginTop={50}
            >
              Notification Settings
            </Heading>
            <Text color={THEME_COLORS.secondary[900]} fontSize="14px">
              By default incident updates are always sent to incident channels and as replies to the
              incident thread
            </Text>
            <HStack>
              <Heading as="h4" size={'xs'} my="1rem" color={THEME_COLORS.secondary[900]}>
                Send incident updates to the main channel
              </Heading>
              <Switch
                size="sm"
                isChecked={config.notifyChannel}
                onChange={() => {
                  setConfig(prev => ({
                    ...prev,
                    notifyChannel: !config.notifyChannel,
                  }));
                }}
              />
            </HStack>

            <Divider my="1rem" />
          </Box>
          <RadioGroup
            onChange={e => {
              setNotificationType(e);
            }}
            value={notificationType}
          >
            <Stack direction={'column'}>
              <Radio
                size="md"
                value={'all_notifications'}
                isChecked={'all_notifications' === notificationType}
              >
                <Text fontSize="sm">Send notification for all updates</Text>
              </Radio>
              <Radio
                size="md"
                value={'custom_notifications'}
                isChecked={'custom_notifications' === notificationType}
                fontSize="sm"
              >
                <Text fontSize="sm">Send custom notification</Text>
              </Radio>
            </Stack>
          </RadioGroup>
          {notificationType === 'custom_notifications' && (
            <Box>
              <Text fontSize="xs" pl="1.5rem" py="0.3rem">
                Choose the statuses to send notifications for:
              </Text>
              <Box py="0.5rem">
                {events.map((event, idx) => (
                  <EventNotificationAccordian
                    eventClass={event.eventClass}
                    eventTypes={event.eventTypes}
                    key={event.eventClass.label}
                    index={idx}
                    onCheckedNotifications={onCheckedNotifications}
                  />
                ))}
              </Box>
            </Box>
          )}
        </>
      )}
      <AlertDialogComponent
        isOpen={isOpen}
        onClose={onClose}
        callbackFn={() => {
          revokeSlack.mutate();
          props.onClose();
        }}
        msg={`Are you sure you want to revoke slack ?`}
        title={'Revoke Slack'}
        isDelete={false}
      />
    </>
  );
};

export default SlackConfiguration;
