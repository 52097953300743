import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { capitalize } from 'lodash';

export const Text: ComponentStyleConfig = {
  variants: {
    italic: {
      lineHeight: 1.2,
      fontStyle: 'italic',
    },
    smCapitalize: {
      textTransform: 'capitalize',
      fontSize: 'sm',
    },
    blackAlpha: {
      color: 'blackAlpha.600',
      fontSize: 'sm',
    },
    primartFitContent: {
      fontSize: 14,
      fontWeight: 600,
      height: 'fit-content',
      color: 'var(--primary-default)',
    },
    secondary: {
      fontSize: 14,
      fontWeight: 600,
      height: 'fit-content',
    },
    proceed: {
      height: '24px',
      fontSize: 16,
      fontWeight: 600,
      color: '#fff',
    },
    grayedCaption: {
      fontSize: 'xs',
      color: '#808080',
    },
  },
};
