import * as Types from './types';

import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from './fetcher/index';
import { StatusPageComponentTimeline } from './types';
export type GetStatusPageListQueryVariables = Types.Exact<{
  input: Types.ListStatusPagesReq;
}>;

export type GetStatusPageListQuery = {
  __typename?: 'Query';
  listStatusPages: {
    __typename?: 'ListStatusPagesRes';
    data: Array<{
      __typename?: 'StatusPageRes';
      id: number;
      name: string;
      isPublic: boolean;
      domainName: string;
      customDomainName: string;
      timezone: string;
      logoUrl: string;
      subscribersCount: number;
      isCustomDomainEnabled: boolean;
      isCustomARecordVerified: boolean;
      isCustomTxtVerified: boolean;
      underMaintenance: boolean;
      ownerType: string;
      ownerID: string;
      acl?: {
        __typename?: 'ACL';
        hasUpdateAccess: boolean;
        hasDeleteAccess: boolean;
        hasUpdateOwnerAccess: boolean;
      } | null;
      components: Array<{
        __typename?: 'ListStatusPagesComponent';
        id: number;
        name: string;
      } | null>;
      status: {
        __typename?: 'ListStatusPagesStatus';
        id: number;
        name: string;
        color: string;
        slug: string;
      };
      statusMaintenance: {
        __typename?: 'ListStatusPagesStatus';
        id: number;
        name: string;
        color: string;
      };
    } | null>;
    meta: {
      __typename?: 'PageMetaDataWithOrgTotalCount';
      totalCount: number;
      orgTotalCount: number;
    };
  };
};

export type GetStatusPageDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;

export type GetStatusPageDetailsQuery = {
  __typename?: 'Query';
  getStatusPageDetails: {
    __typename?: 'StatusPageDetails';
    id: number;
    organizationID: string;
    name: string;
    description?: string | null;
    isPublic?: boolean | null;
    domainName: string;
    customDomainName: string;
    logoUrl: string;
    timezone: string;
    isCustomDomainEnabled: boolean;
    isCustomARecordVerified: boolean;
    isCustomTxtVerified: boolean;
    txtRecord: string;
    aRecord: string;
    subscribersCount: number;
    maximumSubscribersLimit: number;
    hideFromSearchEngines: boolean;
    underMaintenance: boolean;
    ownerType: Types.NewStatusPageOwnerType;
    ownerID: string;
    teamID: string;
    themeColor: { __typename?: 'StatusPageThemeColor'; primary: string; secondary: string };
    acl?: {
      __typename?: 'ACL';
      hasUpdateAccess: boolean;
      hasDeleteAccess: boolean;
      hasUpdateOwnerAccess: boolean;
    } | null;
    components: Array<{
      __typename?: 'StatusPageComponentList';
      id: number;
      name: string;
      type: Types.NewStatusPageComponentType;
      description?: string | null;
      isHidden: boolean;
      underMaintenance: boolean;
      allowSubscription?: boolean | null;
      status?: {
        __typename?: 'ListStatusPagesStatus';
        id: number;
        name: string;
        color: string;
        slug: string;
      } | null;
      statusMaintenance: {
        __typename?: 'ListStatusPagesStatus';
        id: number;
        name: string;
        color: string;
      };
      components?: Array<{
        __typename?: 'StatusPageComponent';
        id: number;
        name: string;
        type: Types.NewStatusPageComponentType;
        description?: string | null;
        allowSubscription: boolean;
        isHidden: boolean;
        underMaintenance: boolean;
        status: {
          __typename?: 'ListStatusPagesStatus';
          id: number;
          name: string;
          color: string;
          slug: string;
        };
        statusMaintenance: {
          __typename?: 'ListStatusPagesStatus';
          id: number;
          name: string;
          color: string;
        };
      } | null> | null;
    } | null>;
    messageTemplates: Array<{
      __typename?: 'StatusPageMessageTemplate';
      id: number;
      name: string;
      color: string;
      defaultMessage: string;
      stepIndex: number;
    } | null>;
    statusCodes: Array<{
      __typename?: 'StatusPageCode';
      id: number;
      slug: string;
      message: string;
      color: string;
      description: string;
    } | null>;
    status: {
      __typename?: 'ListStatusPagesStatus';
      id: number;
      name: string;
      color: string;
      slug: string;
    };
    statusMaintenance: {
      __typename?: 'ListStatusPagesStatus';
      id: number;
      name: string;
      color: string;
    };
  };
};

export type GetStatusIssueDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  pageID: Types.Scalars['Int'];
}>;

export type GetStatusIssueDetailsQuery = {
  __typename?: 'Query';
  getStatusPageIssue: {
    __typename?: 'GetStatusPageIssueRes';
    id: number;
    title: string;
    isMigrated: boolean;
    pageID: number;
    components?: Array<{
      __typename?: 'IssueComponent';
      id: number;
      status: { __typename?: 'Status'; id: number; name: string; color: string };
    } | null> | null;
    issues: Array<{
      __typename?: 'Issues';
      state: { __typename?: 'Status'; id: number; name: string; color: string };
      stateMessages: Array<{
        __typename?: 'StatusPageIssueMessageRes';
        id: number;
        text: string;
        timestamp: any;
      } | null>;
    } | null>;
    currentIssueState: { __typename?: 'Status'; id: number; name: string; color: string };
  };
};

export type GetStatusIssueListQueryVariables = Types.Exact<{
  input: Types.ListStatusPageIssues;
}>;

export type GetStatusIssueListQuery = {
  __typename?: 'Query';
  listStatusPageIssues: {
    __typename?: 'ListStatusPageIssueRes';
    data: Array<{
      __typename?: 'StatusPageIssueRes';
      id: number;
      title: string;
      createdAt: any;
      components: Array<{ __typename?: 'IssueComponentWithName'; id: number; name: string }>;
      currentState: { __typename?: 'Status'; id: number; name: string; color: string };
    } | null>;
    meta: { __typename?: 'PageMetaData'; totalCount: number };
  };
};

export type GetStatusPageStatusesQueryVariables = Types.Exact<{
  pageID: Types.Scalars['Int'];
}>;

export type GetStatusPageStatusesQuery = {
  __typename?: 'Query';
  listStatusPageStatuses: Array<{
    __typename?: 'StatusPageStatuses';
    id: number;
    name: string;
    status: string;
    description: string;
    color: string;
  } | null>;
};

export type GetMaintenanceListQueryVariables = Types.Exact<{
  input: Types.ListStatusPageMaintenances;
}>;

export type GetMaintenanceListQuery = {
  __typename?: 'Query';
  listStatusPageMaintenances: {
    __typename?: 'ListStatusPageMaintenanceRes';
    data: Array<{
      __typename?: 'StatusPageMaintenanceDetail';
      id: number;
      title: string;
      pageID: number;
      note?: string | null;
      startTime: any;
      endTime: any;
      components: Array<{
        __typename?: 'StatusPageMaintenanceComponent';
        id: number;
        name: string;
      } | null>;
    } | null>;
    meta: { __typename?: 'PageMetaData'; totalCount: number };
  };
};

export type GetStatusPageMaintenanceDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;

export type GetStatusPageMaintenanceDetailsQuery = {
  __typename?: 'Query';
  getStatusPageMaintenanceDetails: {
    __typename?: 'StatusPageMaintenanceDetail';
    id: number;
    title: string;
    pageID: number;
    note?: string | null;
    startTime: any;
    endTime: any;
    components: Array<{
      __typename?: 'StatusPageMaintenanceComponent';
      id: number;
      name: string;
    } | null>;
  };
};

export type ListStatusPageSubscribersQueryVariables = Types.Exact<{
  input: Types.ListStatusPageSubscribersReq;
}>;

export type ListStatusPageSubscribersQuery = {
  __typename?: 'Query';
  listStatusPageSubscribers: {
    __typename?: 'ListStatusPageSubscribersRes';
    data: Array<{
      __typename?: 'ListStatusPageSubscriber';
      id: number;
      type: Types.StatusPageSubscriberType;
      emailID: string;
      webhookURL: string;
      status: string;
      subscribedOn: any;
      componentList: Array<{
        __typename?: 'StatusPageSubscriberComponent';
        id: number;
        name: string;
      } | null>;
    } | null>;
    meta: {
      __typename?: 'ListStatusPageSubscriberMeta';
      lastWeekSubscribersCount: number;
      maximumSubscribersLimit: number;
      totalSubscribersCount: { __typename?: 'PageMetaData'; totalCount: number };
    };
  };
};

export type GetStatusPageSubscriptionSettingsQueryVariables = Types.Exact<{
  pageID: Types.Scalars['Int'];
}>;

export type GetStatusPageSubscriptionSettingsQuery = {
  __typename?: 'Query';
  getStatusPageSubscriptionSettings: {
    __typename?: 'StatusPageSubscriptionSettings';
    contactEmail: string;
    sendOnlyEmailID: string;
    allowWebhookSubscription: boolean;
    componentsSubscription: { __typename?: 'SubscriptionDetails'; enabled: boolean; count: number };
    maintenanceSubscription: {
      __typename?: 'SubscriptionDetails';
      enabled: boolean;
      count: number;
    };
  };
};

export type ExportStatusPageSubscribersQueryVariables = Types.Exact<{
  pageID: Types.Scalars['Int'];
  teamID: Types.Scalars['String'];
}>;

export type ExportStatusPageSubscribersQuery = {
  __typename?: 'Query';
  exportStatusPageSubscribers: { __typename?: 'ExportStatusPageSubscribersRes'; message: string };
};

export const GetStatusPageListDocument = `
    query getStatusPageList($input: ListStatusPagesReq!) {
  listStatusPages(input: $input) {
    data {
      id
      name
      acl {
        hasUpdateAccess
        hasDeleteAccess
        hasUpdateOwnerAccess
      }
      isPublic
      domainName
      customDomainName
      timezone
      logoUrl
      components {
        id
        name
      }
      subscribersCount
      isCustomDomainEnabled
      isCustomARecordVerified
      isCustomTxtVerified
      status {
        id
        name
        color
        slug
      }
      underMaintenance
      statusMaintenance {
        id
        name
        color
      }
      ownerType
      ownerID
    }
    meta {
      totalCount
      orgTotalCount
    }
  }
}
    `;
export const useGetStatusPageListQuery = <TData = GetStatusPageListQuery, TError = unknown>(
  variables: GetStatusPageListQueryVariables,
  options?: UseQueryOptions<GetStatusPageListQuery, TError, TData>,
) =>
  useQuery<GetStatusPageListQuery, TError, TData>(
    ['getStatusPageList', variables],
    fetcher<GetStatusPageListQuery, GetStatusPageListQueryVariables>(
      GetStatusPageListDocument,
      variables,
    ),
    options,
  );
export const GetStatusPageDetailsDocument = `
    query getStatusPageDetails($id: Int!) {
  getStatusPageDetails(id: $id) {
    id
    organizationID
    name
    description
    isPublic
    domainName
    customDomainName
    logoUrl
    timezone
    themeColor {
      primary
      secondary
    }
    acl {
      hasUpdateAccess
      hasDeleteAccess
      hasUpdateOwnerAccess
    }
    components {
      id
      name
      type
      description
      isHidden
      status {
        id
        name
        color
        slug
      }
      underMaintenance
      statusMaintenance {
        id
        name
        color
      }
      allowSubscription
      components {
        id
        name
        type
        description
        allowSubscription
        isHidden
        status {
          id
          name
          color
          slug
        }
        underMaintenance
        statusMaintenance {
          id
          name
          color
        }
      }
    }
    messageTemplates {
      id
      name
      color
      defaultMessage
      stepIndex
    }
    statusCodes {
      id
      slug
      message
      color
      description
    }
    isCustomDomainEnabled
    isCustomARecordVerified
    isCustomTxtVerified
    txtRecord
    aRecord
    subscribersCount
    maximumSubscribersLimit
    hideFromSearchEngines
    status {
      id
      name
      color
      slug
    }
    underMaintenance
    statusMaintenance {
      id
      name
      color
    }
    ownerType
    ownerID
    teamID
  }
}
    `;
export const useGetStatusPageDetailsQuery = <TData = GetStatusPageDetailsQuery, TError = unknown>(
  variables: GetStatusPageDetailsQueryVariables,
  options?: UseQueryOptions<GetStatusPageDetailsQuery, TError, TData>,
) =>
  useQuery<GetStatusPageDetailsQuery, TError, TData>(
    ['getStatusPageDetails', variables],
    fetcher<GetStatusPageDetailsQuery, GetStatusPageDetailsQueryVariables>(
      GetStatusPageDetailsDocument,
      variables,
    ),
    options,
  );
export const GetStatusIssueDetailsDocument = `
    query getStatusIssueDetails($id: Int!, $pageID: Int!) {
  getStatusPageIssue(id: $id, pageID: $pageID) {
    id
    title
    isMigrated
    components {
      id
      status {
        id
        name
        color
      }
    }
    issues {
      state {
        id
        name
        color
      }
      stateMessages {
        id
        text
        timestamp
      }
    }
    currentIssueState {
      id
      name
      color
    }
    pageID
  }
}
    `;
export const useGetStatusIssueDetailsQuery = <TData = GetStatusIssueDetailsQuery, TError = unknown>(
  variables: GetStatusIssueDetailsQueryVariables,
  options?: UseQueryOptions<GetStatusIssueDetailsQuery, TError, TData>,
) =>
  useQuery<GetStatusIssueDetailsQuery, TError, TData>(
    ['getStatusIssueDetails', variables],
    fetcher<GetStatusIssueDetailsQuery, GetStatusIssueDetailsQueryVariables>(
      GetStatusIssueDetailsDocument,
      variables,
    ),
    options,
  );
export const GetStatusIssueListDocument = `
    query getStatusIssueList($input: ListStatusPageIssues!) {
  listStatusPageIssues(input: $input) {
    data {
      id
      title
      components {
        id
        name
      }
      currentState {
        id
        name
        color
      }
      createdAt
    }
    meta {
      totalCount
    }
  }
}
    `;
export const useGetStatusIssueListQuery = <TData = GetStatusIssueListQuery, TError = unknown>(
  variables: GetStatusIssueListQueryVariables,
  options?: UseQueryOptions<GetStatusIssueListQuery, TError, TData>,
) =>
  useQuery<GetStatusIssueListQuery, TError, TData>(
    ['getStatusIssueList', variables],
    fetcher<GetStatusIssueListQuery, GetStatusIssueListQueryVariables>(
      GetStatusIssueListDocument,
      variables,
    ),
    options,
  );
export const GetStatusPageStatusesDocument = `
    query getStatusPageStatuses($pageID: Int!) {
  listStatusPageStatuses(pageID: $pageID) {
    id
    name
    status
    description
    color
  }
}
    `;
export const useGetStatusPageStatusesQuery = <TData = GetStatusPageStatusesQuery, TError = unknown>(
  variables: GetStatusPageStatusesQueryVariables,
  options?: UseQueryOptions<GetStatusPageStatusesQuery, TError, TData>,
) =>
  useQuery<GetStatusPageStatusesQuery, TError, TData>(
    ['getStatusPageStatuses', variables],
    fetcher<GetStatusPageStatusesQuery, GetStatusPageStatusesQueryVariables>(
      GetStatusPageStatusesDocument,
      variables,
    ),
    options,
  );
export const GetMaintenanceListDocument = `
    query getMaintenanceList($input: ListStatusPageMaintenances!) {
  listStatusPageMaintenances(input: $input) {
    data {
      id
      title
      pageID
      note
      components {
        id
        name
      }
      startTime
      endTime
    }
    meta {
      totalCount
    }
  }
}
    `;
export const useGetMaintenanceListQuery = <TData = GetMaintenanceListQuery, TError = unknown>(
  variables: GetMaintenanceListQueryVariables,
  options?: UseQueryOptions<GetMaintenanceListQuery, TError, TData>,
) =>
  useQuery<GetMaintenanceListQuery, TError, TData>(
    ['getMaintenanceList', variables],
    fetcher<GetMaintenanceListQuery, GetMaintenanceListQueryVariables>(
      GetMaintenanceListDocument,
      variables,
    ),
    options,
  );
export const GetStatusPageMaintenanceDetailsDocument = `
    query getStatusPageMaintenanceDetails($id: Int!) {
  getStatusPageMaintenanceDetails(id: $id) {
    id
    title
    pageID
    note
    components {
      id
      name
    }
    startTime
    endTime
  }
}
    `;
export const useGetStatusPageMaintenanceDetailsQuery = <
  TData = GetStatusPageMaintenanceDetailsQuery,
  TError = unknown,
>(
  variables: GetStatusPageMaintenanceDetailsQueryVariables,
  options?: UseQueryOptions<GetStatusPageMaintenanceDetailsQuery, TError, TData>,
) =>
  useQuery<GetStatusPageMaintenanceDetailsQuery, TError, TData>(
    ['getStatusPageMaintenanceDetails', variables],
    fetcher<GetStatusPageMaintenanceDetailsQuery, GetStatusPageMaintenanceDetailsQueryVariables>(
      GetStatusPageMaintenanceDetailsDocument,
      variables,
    ),
    options,
  );
export const ListStatusPageSubscribersDocument = `
    query listStatusPageSubscribers($input: ListStatusPageSubscribersReq!) {
  listStatusPageSubscribers(input: $input) {
    data {
      id
      type
      componentList {
        id
        name
      }
      emailID
      webhookURL
      status
      subscribedOn
    }
    meta {
      totalSubscribersCount {
        totalCount
      }
      lastWeekSubscribersCount
      maximumSubscribersLimit
    }
  }
}
    `;
export const useListStatusPageSubscribersQuery = <
  TData = ListStatusPageSubscribersQuery,
  TError = unknown,
>(
  variables: ListStatusPageSubscribersQueryVariables,
  options?: UseQueryOptions<ListStatusPageSubscribersQuery, TError, TData>,
) =>
  useQuery<ListStatusPageSubscribersQuery, TError, TData>(
    ['listStatusPageSubscribers', variables],
    fetcher<ListStatusPageSubscribersQuery, ListStatusPageSubscribersQueryVariables>(
      ListStatusPageSubscribersDocument,
      variables,
    ),
    options,
  );
export const GetStatusPageSubscriptionSettingsDocument = `
    query getStatusPageSubscriptionSettings($pageID: Int!) {
  getStatusPageSubscriptionSettings(pageID: $pageID) {
    contactEmail
    sendOnlyEmailID
    allowWebhookSubscription
    componentsSubscription {
      enabled
      count
    }
    maintenanceSubscription {
      enabled
      count
    }
  }
}
    `;
export const useGetStatusPageSubscriptionSettingsQuery = <
  TData = GetStatusPageSubscriptionSettingsQuery,
  TError = unknown,
>(
  variables: GetStatusPageSubscriptionSettingsQueryVariables,
  options?: UseQueryOptions<GetStatusPageSubscriptionSettingsQuery, TError, TData>,
) =>
  useQuery<GetStatusPageSubscriptionSettingsQuery, TError, TData>(
    ['getStatusPageSubscriptionSettings', variables],
    fetcher<
      GetStatusPageSubscriptionSettingsQuery,
      GetStatusPageSubscriptionSettingsQueryVariables
    >(GetStatusPageSubscriptionSettingsDocument, variables),
    options,
  );
export const ExportStatusPageSubscribersDocument = `
    query exportStatusPageSubscribers($pageID: Int!, $teamID: String!) {
  exportStatusPageSubscribers(pageID: $pageID, teamID: $teamID) {
    message
  }
}
    `;
export const useExportStatusPageSubscribersQuery = <
  TData = ExportStatusPageSubscribersQuery,
  TError = unknown,
>(
  variables: ExportStatusPageSubscribersQueryVariables,
  options?: UseQueryOptions<ExportStatusPageSubscribersQuery, TError, TData>,
) =>
  useQuery<ExportStatusPageSubscribersQuery, TError, TData>(
    ['exportStatusPageSubscribers', variables],
    fetcher<ExportStatusPageSubscribersQuery, ExportStatusPageSubscribersQueryVariables>(
      ExportStatusPageSubscribersDocument,
      variables,
    ),
    options,
  );

export interface GetStatusPageComponentTimelineQuery {
  getStatusPageComponentTimeline: StatusPageComponentTimeline;
}

export interface GetStatusPageComponentTimelineQueryVariables {
  id: number;
}

export const GetStatusPageComponentTimelineDocument = `
  query getStatusPageComponentTimeline($id: Int!) {
    getStatusPageComponentTimeline(id: $id) {
      data {
        date
        overallStatus {
          slug
          color
        }
        override
        overrideData {
          majorOutage
          partialOutage
          degraded
          operational
        }
        statusDurations {
          operational {
            slug
            duration
          }
          partialOutage {
            slug
            duration
          }
          underMaintenance {
            slug
            duration
          }
          majorOutage {
            slug
            duration
          }
          degraded {
            slug
            duration
          }
        }
      }
      meta {
        totalCount
      }
    }
  }
`;

export const useGetStatusPageComponentTimelineQuery = <
  TData = GetStatusPageComponentTimelineQuery,
  TError = unknown,
>(
  variables: GetStatusPageComponentTimelineQueryVariables,
  options?: UseQueryOptions<GetStatusPageComponentTimelineQuery, TError, TData>,
) =>
  useQuery<GetStatusPageComponentTimelineQuery, TError, TData>(
    ['getStatusPageComponentTimeline', variables],
    fetcher<GetStatusPageComponentTimelineQuery, GetStatusPageComponentTimelineQueryVariables>(
      GetStatusPageComponentTimelineDocument,
      variables,
    ),
    options,
  );
