import * as React from 'react';
import { SVGProps } from 'react';

const SvgSite24X7 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#80b541',
      }}
      d="M.18 11.054c.242.12.603.241.985.241.401 0 .622-.16.622-.422 0-.24-.18-.381-.663-.542C.482 10.111.04 9.75.04 9.167c0-.663.562-1.185 1.486-1.185.442 0 .763.1 1.004.2l-.2.724c-.161-.08-.442-.201-.824-.201s-.562.18-.562.381c0 .241.22.362.723.542.682.261 1.024.623 1.024 1.165 0 .663-.502 1.205-1.586 1.205-.442 0-.884-.12-1.105-.241l.18-.703zM3.896 8.404c0 .24-.181.441-.482.441-.281 0-.462-.2-.462-.441 0-.261.18-.442.462-.442s.482.18.482.442zm-.904 3.614V9.187h.883v2.831h-.883zM5.321 8.303v.803h.643v.643H5.32v1.024c0 .342.08.502.342.502.1 0 .2-.02.26-.02v.663c-.12.04-.32.08-.582.08-.28 0-.522-.1-.662-.241-.16-.16-.241-.442-.241-.843V9.769h-.382v-.643h.382v-.622l.883-.2zM6.888 10.833c.02.362.381.542.803.542.3 0 .542-.04.783-.12l.12.602c-.28.12-.642.181-1.024.181-.964 0-1.506-.562-1.506-1.446 0-.723.442-1.506 1.426-1.506.924 0 1.265.723 1.265 1.426 0 .16-.02.281-.02.341l-1.847-.02zm1.044-.602c0-.221-.1-.582-.502-.582-.382 0-.522.341-.542.582h1.044z"
    />
    <path d="M8.916 12.018v-.542l.482-.442c.823-.743 1.224-1.165 1.245-1.606 0-.302-.181-.543-.623-.543-.321 0-.602.161-.803.322l-.241-.643c.281-.22.723-.381 1.245-.381.863 0 1.325.502 1.325 1.184 0 .643-.462 1.145-1.004 1.627l-.341.281v.02h1.425v.723h-2.71zM13.333 12.018v-.904h-1.666v-.582l1.425-2.309h1.085v2.209h.441v.682h-.461v.904h-.824zm0-1.566v-.823c0-.221.02-.462.02-.703h-.02c-.12.24-.22.462-.341.703l-.502.823v.02h.843v-.02z" />
    <path d="m15.402 9.187.28.462.242.421h.02c.08-.16.14-.301.22-.442l.242-.461h.964l-.944 1.365.944 1.486h-1.004l-.282-.502c-.08-.14-.16-.281-.22-.422h-.02c-.06.16-.141.281-.221.422l-.261.502h-.964l.964-1.446-.944-1.385h.984z" />
    <path d="M20 8.243v.562l-1.566 3.213h-.944l1.566-3.032v-.02H17.33v-.723H20z" />
  </svg>
);

export default SvgSite24X7;
