import React, { useEffect, useState } from 'react';
import { DialogModalFrame, Grid, Para, TextButton, Theme } from 'uie/components';
import { Location } from 'history';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import { whileStatement } from '@babel/types';
import './index.css';

const { theme } = Theme;

interface IProps {
  showModal?: boolean;
  message: string;
  header: string;
  align: 'left' | 'right';
  hideLearnMore?: boolean;
  hasBillingPermission: boolean;
}

const UpgradeOnlyModal = ({
  showModal,
  header,
  align: justify,
  message,
  hideLearnMore,
  hasBillingPermission,
}: IProps) => {
  const history = useHistory();
  const onUpgradePlanAction = () => {
    history.push('/settings/billings');
  };

  const left = justify === 'left' ? -300 : -20;
  return (
    <>
      <div
        style={{
          width: 400,
          minHeight: 230,
          position: 'relative',
          top: 10,
          left: left,
          backgroundColor: 'white',
          padding: 16,
          boxShadow: '0px 0px 8px 2px rgba(0, 0, 0, 0.12)',
        }}
      >
        {showModal && (
          <>
            <Para
              fontSize={22}
              fontWeight={500}
              style={{
                lineHeight: '40px',
                color: '#2D3964',
                fontStyle: 'normal',
                fontSize: '24',
              }}
              className="mb-10 mt-10"
            >
              {header}
            </Para>

            <Para
              fontSize={16}
              fontWeight={500}
              style={{
                lineHeight: '24px',
                fontStyle: 'normal',
                color: '#969AA0',
                fontFamily: 'Mulish',
                whiteSpace: 'pre-wrap',
              }}
              color={theme.shades.cement}
            >
              {message}
              <br />
              <br />
              {!hasBillingPermission ? (
                <>
                  You do not seem to have the right permissions to upgrade the current plan. Reach
                  out to your Account Owner for the same.
                </>
              ) : !hideLearnMore ? (
                <>
                  To use this feature,{' '}
                  <Link
                    to="/settings/billings"
                    target="_blank"
                    rel="noreferrer"
                    className="modal_link"
                  >
                    upgrade to one of our higher plans
                  </Link>{' '}
                  or learn more about all our plans{' '}
                  <a
                    href="https://www.squadcast.com/pricing"
                    target="_blank"
                    rel="noreferrer"
                    className="modal_link"
                  >
                    here.
                  </a>
                </>
              ) : (
                <></>
              )}

              <div style={{ marginTop: 15 }}>
                <Grid justifyContent="flex-end">
                  <TextButton
                    style={{ padding: '4px 8px' }}
                    onClick={onUpgradePlanAction}
                    disabled={!hasBillingPermission}
                  >
                    <Para color={theme.font.white} fontWeight={500} fontSize={16}>
                      Upgrade
                    </Para>
                  </TextButton>
                </Grid>
              </div>
            </Para>
          </>
        )}
      </div>
    </>
  );
};
export default UpgradeOnlyModal;
