import { INIT_ORG, INIT_ORG_FAIL, INIT_ORG_SUCCESS } from '../../const/init';

const requestInitOrg = (orgId: string) => ({
  type: INIT_ORG,
  payload: {
    orgId,
  },
});

const onInitOrgSuccess = (payload: any) => ({
  payload,
  type: INIT_ORG_SUCCESS,
});

const onInitOrgFail = (error: any) => ({
  type: INIT_ORG_FAIL,
  payload: {
    error,
  },
});

export { requestInitOrg, onInitOrgSuccess, onInitOrgFail };
