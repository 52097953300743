import axios from 'axios';
import { ACL, EntityACL, EntityACLMeta } from 'core/userAccess/types';
import { AppConfig } from 'shared/app.config';
import { create } from 'zustand';

const url = AppConfig.api_url;

export type SettingPageACL = {
  OBACEnabled: boolean;
  setOBACEnabled: (val: boolean) => void;

  isLoading: boolean;

  teamACL: Pick<ACL, 'stakeholder_groups' | 'squads' | 'teams'> | null;
  ACLOnEntity: Partial<Record<keyof ACL, Record<string, EntityACLMeta>>> | null;

  setTeamACL: (organizationId: string, teamId: string) => void;
  setACLOnEntity: (entityType: Partial<keyof ACL>, acl: EntityACL) => void;

  hasReadAccess: (
    entityType: keyof Pick<ACL, 'stakeholder_groups' | 'squads' | 'teams'>,
  ) => boolean;
  hasCreateAccess: (
    entityType: keyof Pick<ACL, 'stakeholder_groups' | 'squads' | 'teams'>,
  ) => boolean;
  hasDeleteAccess: (
    entityType: keyof Pick<ACL, 'stakeholder_groups' | 'squads' | 'teams'>,
    id?: string,
  ) => boolean;
  hasUpdateAccess: (
    entityType: keyof Pick<ACL, 'stakeholder_groups' | 'squads' | 'teams'>,
    id?: string,
  ) => boolean;
  hasUpdateOwnerAccess?: (
    entityType: keyof Pick<ACL, 'stakeholder_groups' | 'squads' | 'teams'>,
    id?: string,
  ) => boolean;
};

const getACL = async (organization: string, currentTeam: string) => {
  const response = await axios.get<{
    data: ACL;
    meta: {
      status: number;
    };
  }>(`${url}/v3/organizations/${organization}/teams/${currentTeam}/acl`);
  return response;
};

export const useSettingPageACL = create<SettingPageACL>((set, get) => ({
  OBACEnabled: false,
  setOBACEnabled: (val: boolean) => set({ OBACEnabled: val }),
  isLoading: false,
  teamACL: null,
  ACLOnEntity: null,
  setTeamACL: async (organizationId: string, teamId: string) => {
    if (!teamId) return;
    set({ ...get(), isLoading: true });
    try {
      const res = await getACL(organizationId, teamId);
      set({ ...get(), teamACL: res.data.data });
    } catch (e) {
      set({
        ...get(),
        teamACL: {
          stakeholder_groups: {
            read: false,
            create: false,
            update: false,
            delete: false,
          },
          squads: {
            read: false,
            create: false,
            update: false,
            delete: false,
          },
          teams: {
            read: false,
            create: false,
            update: false,
            delete: false,
          },
        },
      });
    }
    set({ ...get(), isLoading: false });
  },
  setACLOnEntity: (entityType: Partial<keyof ACL>, acl: EntityACL) =>
    set({
      ...get(),
      ACLOnEntity: {
        ...get().ACLOnEntity,
        [entityType]: {
          ...(get()?.ACLOnEntity?.[entityType] ?? {}),
          ...acl,
        },
      },
    }),

  hasReadAccess: (entityType: keyof Pick<ACL, 'stakeholder_groups' | 'squads' | 'teams'>) => {
    const { OBACEnabled, isLoading, teamACL } = get();
    if (OBACEnabled) return true;
    if (isLoading) return true;
    if (!teamACL) return true;
    return teamACL[entityType]?.read ?? false;
  },
  hasCreateAccess: (entityType: keyof Pick<ACL, 'stakeholder_groups' | 'squads' | 'teams'>) => {
    const { OBACEnabled, isLoading, teamACL } = get();
    if (OBACEnabled) {
      return teamACL?.[entityType]?.create ?? false;
    }
    if (isLoading) return true;
    if (!teamACL) return true;
    return teamACL[entityType]?.create ?? false;
  },
  hasDeleteAccess: (
    entityType: keyof Pick<ACL, 'stakeholder_groups' | 'squads' | 'teams'>,
    id?: string,
  ) => {
    const { OBACEnabled, ACLOnEntity, teamACL } = get();
    if (OBACEnabled) {
      return id !== undefined && id !== null
        ? ACLOnEntity?.[entityType]?.[id]?.has_delete_access ?? false
        : teamACL?.[entityType]?.delete ?? false;
    }
    if (!teamACL) return true;
    return teamACL?.[entityType]?.delete ?? false;
  },
  hasUpdateAccess: (
    entityType: keyof Pick<ACL, 'stakeholder_groups' | 'squads' | 'teams'>,
    id?: string,
  ) => {
    const { OBACEnabled, ACLOnEntity, teamACL } = get();
    if (OBACEnabled) {
      return id !== undefined && id !== null
        ? ACLOnEntity?.[entityType]?.[id]?.has_update_access ?? false
        : teamACL?.[entityType]?.update ?? false;
    }
    if (!teamACL) return true;
    return teamACL?.[entityType]?.update ?? false;
  },
  hasUpdateOwnerAccess: (
    entityType: keyof Pick<ACL, 'stakeholder_groups' | 'squads' | 'teams'>,
    id?: string,
  ) => {
    const { OBACEnabled, ACLOnEntity, teamACL } = get();
    if (OBACEnabled) {
      return id !== undefined && id !== null
        ? ACLOnEntity?.[entityType]?.[id]?.has_update_owner_access ?? false
        : teamACL?.[entityType]?.update ?? false;
    }
    if (!teamACL) return true;
    return teamACL?.[entityType]?.update ?? false;
  },
}));

export function withSettingPageACL<P>(Component: React.ComponentType<P>) {
  return (props: Omit<P, 'aclStore'>) => {
    const value = useSettingPageACL(store => store);

    return <Component {...(props as P)} aclStore={value} />;
  };
}

export const {
  getState: getSettingPageACL,
  subscribe: subscribeToSettingPageACL,
  setState: setSettingPageACL,
} = useSettingPageACL;
