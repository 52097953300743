import { Box, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { API, exception } from 'core';
import { FormButton, useToast } from 'library/atoms';
import { CustomDrawerComponent, Loader, TagGroup } from 'library/molecules';
import { useState } from 'react';

import {
  DEFAULT_REACT_QUERY_CONFIG,
  DUPLICATE_TAGS_ERROR_MSG,
  INVALID_TAGS_ERROR_MSG,
  INVALID_TAG_KEY_ERROR_MSG,
  VALID_TAG_KEYS,
} from '../common/constants';
import { INCIDENT_TAGS, IncidentListActionProps } from '../common/types';
import { truncate } from '../common/util';
import { useUpdateIncidentTagsMutation } from '../graphql/mutation';
import { useGetIncidentActiveTagsQuery } from '../graphql/query';

type Props = IncidentListActionProps & {
  tags: Array<INCIDENT_TAGS>;
};

export const UpdateTags = ({ incidentIds, incidentName, onSave, onClose, tags }: Props) => {
  const [errorMsg, setErrorMsg] = useState<string>();
  const [incidentTags, setIncidentTags] = useState<INCIDENT_TAGS[]>(tags);
  const [isDirty, setIsDirty] = useState(false);

  const toast = useToast();

  const { data, isLoading } = useGetIncidentActiveTagsQuery(
    {
      teamID: API.config.teamId,
    },
    {
      ...DEFAULT_REACT_QUERY_CONFIG,
      onError: error => {
        exception.handle('E_GET_ALL_TAGS_INCIDENTS', error);
      },
    },
  );

  const { mutateAsync: updateIncidentTags, isLoading: isUpdatingTags } =
    useUpdateIncidentTagsMutation();

  const updateTags = () => {
    // check for empty key/value
    if (incidentTags.some(tag => tag.key === '' || tag.value === '')) {
      setErrorMsg(INVALID_TAGS_ERROR_MSG);
      return;
    }

    // check for special characters in key
    if (incidentTags.some(tag => !VALID_TAG_KEYS.test(tag.key))) {
      setErrorMsg(INVALID_TAG_KEY_ERROR_MSG);
      return;
    }

    if (errorMsg) {
      setErrorMsg('');
    }

    // check for uniqueness
    const set = new Set(incidentTags.map(tag => `${tag.key}:${tag.value}`));
    if (set.size < incidentTags.length) {
      setErrorMsg(DUPLICATE_TAGS_ERROR_MSG);
      return;
    }

    // call update tags API
    updateIncidentTags({
      incidentID: incidentIds[0],
      tags: incidentTags,
    })
      .then(() => {
        toast({
          status: 'success',
          text: `Success: Tags updated for '${truncate(incidentName ?? '')}'`,
        });
        onSave();
      })
      .catch((error: any) => {
        exception.handle('E_INCIDENT_UPDATE_TAGS', error);
        toast({
          status: 'error',
          text: 'Error: Unable to update tags',
        });
        console.error(error);
      });
  };

  const TextGridItem = (label: string) => (
    <Text variant="formInput_800" color="secondary.700">
      {label}
    </Text>
  );

  return (
    <CustomDrawerComponent
      onClose={onClose}
      isOpen={true}
      title="Update Tags"
      disableBodyPadding
      size="md"
      footer={
        <HStack spacing={5}>
          <FormButton
            title="Confirm"
            isDisabled={!isDirty}
            isLoading={isUpdatingTags}
            onClick={updateTags}
          ></FormButton>
          <FormButton
            variant="secondary"
            isDisabled={isUpdatingTags}
            title="Cancel"
            onClick={onClose}
          ></FormButton>
        </HStack>
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Box p={4}>
          <Grid pb={6} templateColumns="1fr 1.75fr" gap={6} alignItems="flex-start">
            <GridItem>{TextGridItem('Key')}</GridItem>
            <GridItem>{TextGridItem('Value')}</GridItem>
          </Grid>
          <TagGroup
            tags={incidentTags ?? []}
            allTags={data?.getIncidentActiveTags}
            getUpdatedTags={tags => {
              setIncidentTags(tags);
              setErrorMsg('');
              if (!isDirty) {
                setIsDirty(true);
              }
            }}
            showError={errorMsg}
          />
        </Box>
      )}
    </CustomDrawerComponent>
  );
};
