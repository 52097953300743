export type MemberList = {
  data: Member[];
};

export type Member = {
  user_id: string;
  role: Role;
  abilities?: Abilities;
};

export type MemberRowProps = Member & {
  name: string;
  email: string;
  email_verified: boolean;
  username_for_display: string;
};

export type Abilities = {
  a: boolean;
};

export enum Role {
  Member = 'member',
  Owner = 'owner',
  Stakeholder = 'stakeholder',
}

export const UpdateRole = {
  [Role.Member]: Role.Owner,
  [Role.Owner]: Role.Member,
  [Role.Stakeholder]: Role.Member,
};

export const RoleText = {
  [Role.Member]: 'Team Member',
  [Role.Owner]: 'Team Owner',
  [Role.Stakeholder]: 'Stakeholder',
};

export const RoleConversionText = {
  [Role.Member]: 'Convert to Team Owner',
  [Role.Owner]: 'Convert to Team Member',
  [Role.Stakeholder]: '',
};

export type AddMemberToTeamPayload = {
  team_id: string;
  members: Member[];
};

export type PatchMemberRolePayload = {
  role: Role;
  user_id: string;
  team_id: string;
  replace_with?: string;
};
