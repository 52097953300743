import React, { Fragment, memo, useMemo } from 'react';
import CountUp from 'react-countup';

import {
  Box,
  Flex,
  Grid,
  VStack,
  Spinner,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  HStack,
  Icon,
} from '@chakra-ui/react';
import NodePopover from '../../../components/Popover';
import { Loader, ServiceTooltip } from '../../../components';
import { IServiceMetrics, IServiceStats } from '../../../Interfaces/intigration';
import { useLastUpdatedServiceQuery } from '../../../hooks/useServiceList';
import moment from 'moment';
import DelayIcon from '../../../icons/AlertSources/DataSync';

const calcDeltaDiff = (prev: number, next: number) =>
  parseFloat(((next / (prev || 1)) * 100).toFixed(2));

interface ServiceMetricsProps {
  isDisabled: boolean;
  stats: IServiceStats | null;
  isStatsLoading: boolean;
  statsMonthly: IServiceStats | null;
  isStatsMonthlyLoading: boolean;
  metrics: IServiceMetrics | null;
  isMetricsLoading: boolean;
  metricsMonthly: IServiceMetrics | null;
  isMetricsMonthlyLoading: boolean;
}

const ServiceMetrics = memo(
  ({
    isDisabled,
    stats,
    isStatsLoading,
    statsMonthly,
    isStatsMonthlyLoading,
    metrics,
    isMetricsLoading,
    metricsMonthly,
    isMetricsMonthlyLoading,
  }: ServiceMetricsProps) => {
    const serviceLastUpdated = useLastUpdatedServiceQuery();
    const serviceStats = useMemo(
      () => [
        {
          key: 'triggered',
          title: 'TRIGGERED',
          loading: isStatsLoading,
          loadingMonthly: isStatsMonthlyLoading,
          count: stats?.currentData.triggered || 0,
          oldCount: stats?.previousData.triggered || 0,
          countMonthly: statsMonthly?.currentData.triggered || 0,
          oldCountMonthly: statsMonthly?.previousData.triggered || 0,
        },
        {
          key: 'acknowledged',
          title: 'ACKNOWLEDGED',
          loading: isStatsLoading,
          loadingMonthly: isStatsMonthlyLoading,
          count: stats?.currentData.acknowledged || 0,
          oldCount: stats?.previousData.acknowledged || 0,
          countMonthly: statsMonthly?.currentData.acknowledged || 0,
          oldCountMonthly: statsMonthly?.previousData.acknowledged || 0,
        },
        {
          key: 'resolved',
          title: 'RESOLVED',
          loading: isStatsLoading,
          loadingMonthly: isStatsMonthlyLoading,
          count: stats?.currentData.resolved || 0,
          oldCount: stats?.previousData.resolved || 0,
          countMonthly: statsMonthly?.currentData.resolved || 0,
          oldCountMonthly: statsMonthly?.previousData.resolved || 0,
        },
        {
          key: 'mtta',
          title: 'LIFETIME MTTA',
          loading: isMetricsLoading,
          loadingMonthly: isMetricsMonthlyLoading,
          count: metrics?.currentData.mtta || 0,
          oldCount: metrics?.previousData.mtta || 0,
          countMonthly: metricsMonthly?.currentData.mtta || 0,
          oldCountMonthly: metricsMonthly?.previousData.mtta || 0,
        },
        {
          key: 'mttr',
          title: 'LIFETIME MTTR',
          loading: isMetricsLoading,
          loadingMonthly: isMetricsMonthlyLoading,
          count: metrics?.currentData.mttr || 0,
          oldCount: metrics?.previousData.mttr || 0,
          countMonthly: metricsMonthly?.currentData.mttr || 0,
          oldCountMonthly: metricsMonthly?.previousData.mttr || 0,
        },
      ],
      [
        isStatsLoading,
        isStatsMonthlyLoading,
        stats,
        statsMonthly,
        isMetricsLoading,
        isMetricsMonthlyLoading,
        metrics,
        metricsMonthly,
      ],
    );

    return (
      <Grid
        templateColumns={{ md: '1fr 1fr 1fr', lg: '1fr 1fr 1fr 1.25fr 1.25fr' }}
        columnGap={1}
        rowGap={3}
        mt={3}
        pr={3}
        pl={3}
      >
        {serviceStats.map((stat, i) => {
          const delta = calcDeltaDiff(stat.oldCountMonthly, stat.countMonthly);

          const isMeanTime = ['mtta', 'mttr'].includes(stat.key);
          const statColor = isMeanTime
            ? { inc: 'red.500', dec: 'green.500' }
            : { inc: 'green.500', dec: 'red.500' };

          const statCount = isMeanTime ? stat.count.toFixed(2) : stat.count;
          const statCountMonthly = isMeanTime ? stat.countMonthly.toFixed(2) : stat.countMonthly;
          const statOldCountMonthly = isMeanTime
            ? stat.oldCountMonthly.toFixed(2)
            : stat.oldCountMonthly;

          return (
            <Box
              py={3}
              px={5}
              mx={1}
              borderColor={'gray.200'}
              bg="white"
              borderWidth="1px"
              borderRadius={'lg'}
              key={i}
            >
              <StatGroup>
                <Stat>
                  <StatLabel fontSize={14} fontWeight={500}>
                    {stat.title}
                    {['mtta', 'mttr'].includes(stat.key) ? (
                      serviceLastUpdated &&
                      serviceLastUpdated.isSuccess && (
                        <ServiceTooltip
                          text={`Updated ${moment(serviceLastUpdated.data.last_updated).fromNow(
                            true,
                          )} ago`}
                          placement="top"
                        >
                          <span>
                            <Icon w={3} h={3} ml={2} as={DelayIcon}></Icon>
                          </span>
                        </ServiceTooltip>
                      )
                    ) : (
                      <ServiceTooltip
                        text={`Shows number of incidents by the configured alert sources that have occurred overall within your data retention period.`}
                        placement="top"
                      >
                        <span>
                          <Icon w={3} h={3} ml={2} as={DelayIcon}></Icon>
                        </span>
                      </ServiceTooltip>
                    )}
                  </StatLabel>

                  <Flex justifyContent="space-between" alignItems="flex-end" wrap="wrap">
                    {stat.loading && <Loader.Spinner />}

                    {!stat.loading &&
                      (isDisabled ? (
                        'N/A'
                      ) : (
                        <Fragment>
                          <StatNumber fontSize={40} fontWeight={400}>
                            <CountUp
                              preserveValue={true}
                              duration={0.8}
                              decimals={isMeanTime ? 2 : 0}
                              start={statCount}
                              end={statCount}
                            />
                            {isMeanTime && (
                              <span
                                style={{ fontSize: 14, marginLeft: 2, fontWeight: 600 }}
                                className="item-box-tagline"
                              >
                                Hrs
                              </span>
                            )}
                          </StatNumber>

                          <NodePopover
                            triggerType="hover"
                            trigger={
                              <StatHelpText
                                mb={2}
                                display="flex"
                                alignItems="center"
                                cursor="default"
                              >
                                {delta !== 0 && (
                                  <StatArrow
                                    mr={1}
                                    type={delta > 0 ? 'increase' : 'decrease'}
                                    color={delta > 0 ? statColor.inc : statColor.dec}
                                  />
                                )}
                                {delta > 10000 ? '> 10000' : delta}%
                              </StatHelpText>
                            }
                          >
                            <HStack>
                              <VStack p={2}>
                                <Text fontSize="sm" fontWeight={500}>
                                  This Month
                                </Text>
                                <Text fontSize="2xl">{statCountMonthly}</Text>
                              </VStack>

                              <VStack p={2}>
                                <Text fontSize="sm" fontWeight={500}>
                                  Last Month
                                </Text>
                                <Text fontSize="2xl">{statOldCountMonthly}</Text>
                              </VStack>
                            </HStack>
                          </NodePopover>
                        </Fragment>
                      ))}
                  </Flex>
                </Stat>
              </StatGroup>
            </Box>
          );
        })}
      </Grid>
    );
  },
);

export default ServiceMetrics;
