import { FC } from 'react';
import LibraryTheme from 'library/theme';
import { useDeleteResponderMutation } from '../hooks/useDeleteResponder';
import { Box, ChakraProvider, Text } from '@chakra-ui/react';
import { Modal } from 'library/molecules';
import { FormButton } from 'library/atoms';
import { IAppState } from 'core/interfaces/IAppState';
import { IAssignedTo } from 'core/interfaces/IIncidents';
import useUINavigationFunctions from '../../../../navigation-flows/useUINavigationFunctionV2';

type Props = {
  organization: IAppState['organization'];
  incidentId: string;
  responder: IAssignedTo;
  isOpen: boolean;
  onClose: () => void;
};

export const DeleteResponder: FC<Props> = ({
  organization,
  incidentId,
  responder,
  isOpen,
  onClose,
}) => {
  const { getEntityName } = useUINavigationFunctions(organization);
  const responderName = getEntityName(responder.type, responder.id);
  const { mutate: deleteResponder, isLoading } = useDeleteResponderMutation(
    incidentId,
    responder.type,
    responderName,
    onClose,
  );

  const onDelete = () => {
    deleteResponder({ incidentId, responderId: responder.id });
  };

  return (
    <ChakraProvider theme={LibraryTheme} resetCSS={false}>
      <Modal
        key="delete-responder"
        title={''}
        body={
          <Box display="flex" flexDirection="column" gap="10px">
            <Text variant="h4_800">
              Are you sure you want to remove {responderName} from additional responders list?
            </Text>
          </Box>
        }
        isOpen={isOpen}
        onClose={onClose}
        primaryButton={
          <FormButton
            isLoading={isLoading}
            title="Yes, remove"
            onClick={onDelete}
            variant="danger"
          />
        }
        secondaryButton={
          <FormButton onClick={onClose} title="Cancel" variant="secondary" isDisabled={isLoading} />
        }
      />
    </ChakraProvider>
  );
};
