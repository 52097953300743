import { Box, Button, Center, Divider, Flex, Spinner, Text } from '@chakra-ui/react';
import { debounce } from 'core';
import React, { ComponentState, FC, useContext, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Search } from '../../components/Search';
import { SearchActionKind } from '../../constants/status.constants';
import { getLoadingState } from '../../helpers/helper.service';
import useQueryParams from '../../hooks/useQueryParams';
import { useSubscribersList } from '../../hooks/useSubscribersList';
import { IPageSubscriberList } from '../../Interface';
import SubscriberList from './table';

interface Props {
  pageId: number;
}

const SubscribersTab = (props: Props) => {
  const {
    isLoading,
    isFetched,
    isError,
    list,
    meta,
    isSuccess,
    state: {
      pagination: { queryPageIndex, queryPageSize },
      search: { enabled: isSearchEnabled, searchText },
    },
    dispatch,
    onSubscribersListExport,
  } = useSubscribersList(props.pageId);
  const query = useQueryParams();
  const history = useHistory();
  const searchQueryParam = query.get('search');

  const debouncedSearch = useRef(
    debounce((searchParam: string) => {
      dispatch({ type: SearchActionKind.SEARCHED, searchText: searchParam || '' });
      dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex: 1 });
    }, 1000),
  ).current;

  useEffect(() => {
    const hasSearchParam = typeof searchQueryParam === 'string';

    if (hasSearchParam) {
      dispatch({
        type: SearchActionKind.SEARCH_ENABLED_OR_DISABLED,
        isEnabled: true,
      });

      debouncedSearch(searchQueryParam);
    } else {
      dispatch({
        type: SearchActionKind.SEARCH_ENABLED_OR_DISABLED,
        isEnabled: false,
      });
    }
  }, [query.get('search')]);

  const SubscribersListTable = (
    {
      failed: () => <></>,
      loading: () => (
        <Center h="60vh" data-testid="loader-sub">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      ),
      done: () => {
        return (
          <SubscriberList
            list={list as IPageSubscriberList[]}
            pageChange={(queryPageIndex: number) =>
              dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex })
            }
            pageSizeChange={(queryPageSize: number) => {
              dispatch({ type: SearchActionKind.PAGE_SIZE_CHANGED, queryPageSize });
              dispatch({ type: SearchActionKind.PAGE_INDEX_CHANGE, queryPageIndex: 1 });
            }}
            queryPageIndex={queryPageIndex}
            queryPageSize={queryPageSize}
            totalCount={meta.totalSubscribersCount?.totalCount || 0}
          />
        );
      },
    } as Record<ComponentState, FC>
  )[getLoadingState(isLoading, isFetched, isError)];

  const MemoizedSubscribersListTable = useMemo(
    () => SubscribersListTable,
    [isLoading, isFetched, isError, list],
  );
  const lastWeekSubscriberCount = meta?.lastWeekSubscribersCount || 0;
  const totalSubs = meta.totalSubscribersCount?.totalCount;

  return (
    <Box pt={4}>
      <Flex justifyContent={'space-between'} gap={5} mb={6}>
        <Text>{lastWeekSubscriberCount} new subscribers this week</Text>
        <Flex gap={5}>
          <Search
            setSearchTerm={searchTerm => {
              query.delete('search');
              query.append('search', searchTerm);
              history.push({ search: query.toString() });
            }}
            searchTerm={searchQueryParam || ''}
            searchEnabled={isSearchEnabled}
            setSearchEnabled={isEnabled => {
              if (isEnabled) {
                query.append('search', '');
              } else {
                query.delete('search');
              }
              history.push({ search: query.toString() });
            }}
            isDisabled={!isSuccess}
            testId="sub-search"
          />

          <>
            <Center height={8}>
              <Divider orientation="vertical" />
            </Center>
            <Button
              variant="default"
              disabled={!totalSubs}
              size="sm"
              onClick={() => onSubscribersListExport()}
            >
              Export
            </Button>
          </>
        </Flex>
      </Flex>
      <MemoizedSubscribersListTable />
    </Box>
  );
};

export default SubscribersTab;
