import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1312_171)">
      <path
        fill="#31C9F5"
        d="M15.582 5.132l1.31-1.81h-.936L15.1 4.506l-.841-1.184h-.933l1.29 1.81-1.309 1.825h.936l.873-1.217.854 1.217h.936l-1.325-1.825zM12.793 3.322h-.902v3.634h.902V3.322zM12.343 1.629a.555.555 0 00-.55.558c0 .308.212.545.535.545a.57.57 0 00.402-.162.554.554 0 00-.386-.941zM9.956 3.732l-.098-.41h-.773v3.652h.905V4.407c.288-.264.481-.361.724-.361.143 0 .281.04.402.116l.322-.775a.964.964 0 00-.5-.148c-.369 0-.66.165-.983.494zM7.795 6.296c-.24 0-.354-.113-.354-.458V4.045h.854l.225-.722H7.438V2.222l-.901.261v.839h-.645v.722h.645v2.025c0 .593.373.951.991.951h.043c.354 0 .788-.1 1.047-.28l-.276-.625a.982.982 0 01-.547.18v.001zM4.876 1.629h-.008a.55.55 0 00-.54.558c0 .313.226.545.548.545a.55.55 0 100-1.103zM5.328 3.322h-.904v3.634h.904V3.322zM2.716 4.241v-.016c.386-.213.595-.607.595-1.049 0-.66-.484-1.283-1.547-1.283H.312v5.064h1.597c1.16 0 1.74-.706 1.74-1.496 0-.594-.355-1.07-.934-1.219zM1.232 2.664h.42c.532 0 .773.297.773.674 0 .377-.275.674-.773.674h-.42V2.663v.001zm.563 3.538h-.563V4.771h.678c.5 0 .822.264.822.69 0 .51-.338.741-.936.741z"
      ></path>
      <path
        fill="#0167A2"
        d="M6.425 11.61c0-1.38-.978-2.39-3.002-2.39a7.253 7.253 0 00-3.142.74l.454 1.282c.698-.302 1.466-.604 2.41-.604.767 0 1.466.303 1.466 1.144.034 1.88-4.226 2.013-4.612 6.557h6.53v-1.38H2.2c.594-2.287 4.226-2.791 4.226-5.349z"
      ></path>
      <path
        fill="#0167A2"
        d="M12.225 9.22h-1.222l-4.645 5.82v1.11h4.19v2.221h1.677v-2.22h1.397v-1.347h-1.397V9.22zm-1.677 4.272v1.278H9.431c-.349 0-.977.034-1.186.034l2.374-3.096c0 .27-.07 1.078-.07 1.784zM16.907 8.266a3.092 3.092 0 100 6.185 3.105 3.105 0 002.182-.911c.56-.559.907-1.329.911-2.182a3.092 3.092 0 00-3.093-3.092zm0 5.618a2.527 2.527 0 110-5.054h.004a2.527 2.527 0 01-.005 5.054z"
      ></path>
      <path fill="#0167A2" d="M17.113 9.809h-.416v1.967h1.966v-.418h-1.55V9.81z"></path>
    </g>
    <defs>
      <clipPath id="clip0_1312_171">
        <path fill="#fff" d="M0 0H20V16.743H0z" transform="translate(0 1.629)"></path>
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
