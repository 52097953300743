import { Box, Button, Center, Divider, HStack, Spinner, Text } from '@chakra-ui/react';
import { FormikProvider } from 'formik';
import React from 'react';

import { reactQueryConfigSuccess } from '../../../helpers/helper.toast';
import { usePageIssue } from '../../../hooks/usePageIssue';
import { StateFormContainer } from '../../../statuspage.add/statuspage.issue/states/StateFormContainer';
import { IssueDetails } from './issueDetails';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_STATUS_PAGE_V2_ISSUE_UPDATED } from 'core/const/tracker';

interface Props {
  pageId: number;
  issueId: number;
  onSuccess: () => void;
}

const IssueUpdate = (props: Props) => {
  const { formik, isSuccess } = usePageIssue(
    props.pageId,
    () => {
      props.onSuccess();
      reactQueryConfigSuccess('Issue Updated');

      AppTracker.track(T_WA_UP_STATUS_PAGE_V2_ISSUE_UPDATED, {
        'Status Page V2 Issue Update Type': 'Issue Message',
      });
    },
    props.issueId,
  );

  return (
    <>
      {isSuccess ? (
        <Box bgColor="white">
          <Box mb={10}>
            {formik.values.isMigrated ? (
              <Text fontSize="sm" color={'red.500'} m={2}>
                We only support editing the Issue Title for historical issues. To do this, click on
                Edit Issue Details on the top right corner
              </Text>
            ) : null}
            <FormikProvider value={formik}>
              <IssueDetails />
              <Box px={6}>
                <StateFormContainer isUpdateFlow />
              </Box>
            </FormikProvider>
          </Box>
        </Box>
      ) : (
        <Center h="60vh">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      )}
    </>
  );
};

export default IssueUpdate;
