import React from 'react';
import { SubActionTypes } from 'views/main/organization/workflow/types';
import { Box, Circle, Flex } from '@chakra-ui/layout';
import { ACTION_TITLE } from 'views/main/organization/workflow/constant';
import { Tooltip } from '@chakra-ui/tooltip';
import { ACTION_ICONS_MAP } from './constant';
import { SeeMore } from 'library/atoms/SeeMore';

interface ActionsStackProps {
  actionTypes: SubActionTypes[];
  actionNames: string[];
  defaultLength?: number;
}
const ActionsStack: React.FC<ActionsStackProps> = ({
  actionTypes,
  defaultLength = 2,
  actionNames,
}) => {
  const view = actionTypes.slice(0, defaultLength);
  const remaining = actionTypes.slice(defaultLength);
  return (
    <Flex align="center" gap={'2'}>
      {view.map((actionType, index) => {
        return ACTION_ICONS_MAP['md'][actionType] ? (
          <Circle
            size="8"
            backgroundColor="primary.400"
            shadow="sm"
            justifyContent="center"
            alignItems="center"
            key={index}
          >
            <Tooltip label={ACTION_TITLE[actionNames[index] as keyof typeof ACTION_TITLE]}>
              <Box>{ACTION_ICONS_MAP['sm'][actionType]}</Box>
            </Tooltip>
          </Circle>
        ) : null;
      })}
      {remaining.length > 0 && (
        <SeeMore
          items={remaining.map((actionType, index) => {
            return ACTION_ICONS_MAP['md'][actionType] ? (
              <Tooltip
                key={index}
                label={ACTION_TITLE[remaining[index] as keyof typeof ACTION_TITLE]}
              >
                <Box>{ACTION_ICONS_MAP['md'][actionType]}</Box>
              </Tooltip>
            ) : null;
          })}
        />
      )}
    </Flex>
  );
};

export { ActionsStack };
