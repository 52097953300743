import { Box, Icon, Text } from '@chakra-ui/react';
import { ProfileIcon } from 'library/icons';
import { TERTIARY_COLORS } from 'library/theme/colors';

type Props = {
  name: string;
};

export const SquadAvatar = ({ name }: Props) => {
  let initials = name
    .match(/(\b\S)?/g)
    ?.join('')
    .match(/(^\S|\S$)?/g)
    ?.join('')
    .toUpperCase();

  if (!initials) {
    if (!name) return <></>;
    else initials = name.slice(0, 2);
  }

  // as per DD, we have 13 diff colors for avatar and  Ascii code of first letter - ascii code of  'A'
  let colorIndex = (initials.charCodeAt(0) - 65) % 13;
  // fallback added for squad names starting with numbers or any other special char
  if (colorIndex < 0) {
    colorIndex = 0;
  }

  return (
    <Box
      bg={TERTIARY_COLORS[colorIndex]}
      display="flex"
      columnGap={0.5}
      p={1}
      borderRadius={16}
      alignItems="center"
      justifyContent="center"
      minWidth={9}
    >
      <Icon as={ProfileIcon} color="brand.white" w="12px" />
      <Text variant="avatar_600" color="brand.white">
        {initials}
      </Text>
    </Box>
  );
};
