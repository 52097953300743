import React, { HTMLAttributes, ReactNode, KeyboardEvent } from 'react';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  onFormSubmit: () => void;
  children: ReactNode;
}

const FormBlock = ({ children, onFormSubmit, onSubmit, ...props }: IProps) => {
  const onKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (!(e.keyCode === 13 && (e.metaKey || e.ctrlKey))) return;

    if (onFormSubmit) {
      onFormSubmit();
    }
  };

  return (
    <div onKeyDown={onKeyPress} {...props}>
      {children}
    </div>
  );
};

/** @component */
export default FormBlock;
