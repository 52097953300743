import React, { Fragment, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import {
  Column,
  TableToggleCommonProps,
  useExpanded,
  useTable,
  usePagination,
  useRowSelect,
  useSortBy,
  useGlobalFilter,
} from 'react-table';

import { FaSortAlphaUp, FaSortAlphaUpAlt } from 'react-icons/all';

import {
  Box,
  Button,
  Checkbox as CCheckbox,
  CheckboxProps,
  Flex,
  HStack,
  Icon,
  IconButton,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import ExpandedDetail from '../../service.home/service.list/ExpandedDetail';

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import { usePaginationCustom, DOTS } from '../../hooks/usePagination';

interface IDataTable {
  columns: Column<Record<any, any>>[];
  data: Record<string, any>[];
  // Element to show on expanded
  expandedView?: ReactNode;
  // Should expand all rows
  isAllExpanded?: boolean;
  // Element to show on hover
  hoverView?: ReactElement;
  height?: string;
  width?: string;

  searchText?: string;

  sortBy?: (sortField: string, sort_order: 'asc' | 'des') => void;
  sorted?: { sort_by: string; sort_order: 'asc' | 'des' | string };

  isClientPagination?: boolean;
  paginationProps?: {
    queryPageIndex: number;
    queryPageSize: number;
    totalCount: number;
    pageChange: (queryPageIndex: number) => void;
    pageSizeChange: (queryPageSize: number) => void;
  };

  hoverViewStyling?: {
    right: string;
  };

  disableOverflow?: boolean;
}

/**
 * General datatable component
 * @param props IDataTable
 * @returns JSX Table Element
 */
const DataTable = React.memo(
  ({
    // queryPageIndex,
    // queryPageSize,
    // totalCount,
    columns,
    data,
    expandedView,
    isAllExpanded,
    hoverView,
    hoverViewStyling,
    height,
    width,
    isClientPagination,
    paginationProps,
    sortBy,
    disableOverflow,
    searchText,
    sorted,
  }: IDataTable) => {
    const tableInstance = useTable(
      {
        columns,
        data,
        //pagination
        initialState: {
          pageIndex: isClientPagination ? 0 : 1,
        },
        manualPagination: !isClientPagination, // Tell the usePagination
        // hook that we'll handle our own data fetching
        // This means we'll also have to provide our own
        // pageCount.

        pageCount: isClientPagination
          ? undefined
          : paginationProps
          ? paginationProps.totalCount
          : 0,
        autoResetPage: !isClientPagination,
      },
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect,
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      setGlobalFilter,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, selectedRowIds },
      ///Pagination
      toggleAllRowsExpanded,
    } = tableInstance;

    const countRef = useRef(10);
    const {
      queryPageIndex = 1,
      queryPageSize = 10,
      totalCount,
      pageSizeChange,
      pageChange,
    } = isClientPagination
      ? {
          queryPageIndex: pageIndex + 1,
          queryPageSize: pageSize,
          totalCount: rows.length,
          pageSizeChange: setPageSize,
          pageChange: (page: number) => gotoPage(page - 1),
        }
      : paginationProps ?? {};

    const [hoveredRow, setHoveredRow] = useState<string | null>(null);

    useEffect(() => {
      if (searchText?.trim().length) {
        setGlobalFilter(searchText);
      } else {
        setGlobalFilter('');
      }
    }, [searchText]);

    useEffect(() => {
      toggleAllRowsExpanded(isAllExpanded ?? false);
    }, [isAllExpanded, toggleAllRowsExpanded]);

    return (
      <Box overflowY={disableOverflow ? 'unset' : 'auto'} height={height} width={width}>
        <Table {...getTableProps()}>
          <Thead bg="gray.50">
            {headerGroups.map(headergroup => {
              return (
                <Tr {...headergroup.getHeaderGroupProps()} key={headergroup.id}>
                  {headergroup.headers.map(column => {
                    return (
                      <Th {...column.getHeaderProps()} color="gray.700" key={column.id}>
                        <Flex justifyContent="flex-start" alignItems="center">
                          {column.render('Header')}
                          {column.canSort && (
                            <Icon
                              ml={1}
                              aria-label="sort"
                              as={
                                sorted && sorted.sort_by == column.id.toLowerCase()
                                  ? sorted.sort_order === 'asc'
                                    ? FaSortAlphaUp
                                    : FaSortAlphaUpAlt
                                  : FaSortAlphaUpAlt
                              }
                              bgColor="transparent"
                              size="sm"
                              onClick={() => {
                                sorted && sorted.sort_by == column.id.toLowerCase()
                                  ? sorted.sort_order === 'asc'
                                    ? sortBy && sortBy(column.id.toLowerCase(), 'des')
                                    : sortBy && sortBy(column.id.toLowerCase(), 'asc')
                                  : sortBy && sortBy(column.id.toLowerCase(), 'des');
                              }}
                            />
                          )}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {!(isClientPagination ? page : rows).length && (
              <Tr>
                <Td colSpan={columns.length}>
                  <Text fontSize={14} textAlign="center">
                    No data
                  </Text>
                </Td>
              </Tr>
            )}
            {(isClientPagination ? page : rows).map(row => {
              prepareRow(row);

              return (
                <Fragment key={row.id}>
                  <Tr
                    {...row.getRowProps()}
                    _hover={{ '> td a': { color: '#3D6DD8' } }}
                    onMouseEnter={() => {
                      hoverView && setHoveredRow(row.id);
                    }}
                    onMouseLeave={() => hoverView && setHoveredRow(null)}
                    bgColor={hoverView && hoveredRow === row.id ? 'gray.100' : 'white'}
                  >
                    {row.cells.map(cell => {
                      return (
                        <Td {...cell.getCellProps()} key={cell.getCellProps().key}>
                          <Flex justifyContent="flex-start" alignItems="center">
                            {cell.render('Cell')}
                          </Flex>
                        </Td>
                      );
                    })}

                    {/* Show this view on hover */}
                    {hoverView && hoveredRow === row.id ? (
                      <Td
                        p={0}
                        position={hoverView && hoveredRow === row.id ? 'relative' : 'static'}
                      >
                        <Flex
                          height="80%"
                          position="absolute"
                          right={hoverViewStyling?.right ?? '5rem'}
                          top="50%"
                          transform="translateY(-50%)"
                          bgColor="gray.100"
                        >
                          {React.cloneElement(hoverView, {
                            rowData: { row },
                            onMouseEnter: () => {
                              setHoveredRow(row.id);
                            },
                          })}
                        </Flex>
                      </Td>
                    ) : null}
                  </Tr>

                  {/* Show this view on expand */}
                  {row.isExpanded && (
                    <Tr {...row.getRowProps()}>
                      <Td colSpan={columns.length} p={0}>
                        <ExpandedDetail serviceID={row.original.id} />
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              );
            })}
          </Tbody>
        </Table>

        <Pagination
          enabled={!!(paginationProps || isClientPagination)}
          queryPageSize={queryPageSize}
          pageSizeChange={pageSizeChange}
          queryPageIndex={queryPageIndex}
          pageChange={pageChange}
          totalCount={totalCount ?? 0}
        />
      </Box>
    );
  },
);

interface PaginationProps {
  enabled: boolean;
  queryPageSize: number;
  pageSizeChange?: (size: number) => void;
  queryPageIndex: number;
  pageChange?: (page: number) => void;
  totalCount: number;
}
export const Pagination = ({
  enabled,
  queryPageSize,
  pageSizeChange,
  queryPageIndex,
  pageChange,
  totalCount,
}: PaginationProps) => {
  const paginationRange = usePaginationCustom({
    totalCount,
    pageSize: queryPageSize,
    siblingCount: 1,
    currentPage: queryPageIndex,
  });

  return enabled ? (
    <Box px={{ base: '4', md: '6' }} p="5">
      <HStack spacing="60">
        <HStack spacing={10}>
          <Select
            value={queryPageSize}
            width="81px"
            borderRadius="6px"
            size="sm"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              pageSizeChange && pageSizeChange(parseInt(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 50, 100].map((value, index) => {
              return (
                <option key={index} value={value}>
                  {value}
                </option>
              );
            })}
          </Select>

          <Text fontSize="sm">
            Showing {queryPageSize > totalCount ? totalCount : queryPageSize} of {totalCount}
          </Text>
        </HStack>
        <HStack spacing={5}>
          <IconButton
            aria-label="Search services"
            size="sm"
            variant="unstyled"
            fontSize="14px"
            fontWeight="medium"
            borderRadius={0}
            disabled={queryPageIndex == 1}
            onClick={() => pageChange && pageChange(queryPageIndex - 1)}
            icon={<ChevronLeftIcon />}
          />

          {paginationRange &&
            paginationRange.map((page, index) => {
              return (
                <Button
                  key={index}
                  variant="ghost"
                  fontSize="14px"
                  fontWeight="medium"
                  borderRadius="6px"
                  size="sm"
                  p={0}
                  bgColor={queryPageIndex === page ? 'rgba(245, 245, 245, 1)' : 'white'}
                  onClick={() => pageChange && typeof page != 'string' && pageChange(page)}
                  disabled={page === DOTS}
                >
                  {page}
                </Button>
              );
            })}

          <IconButton
            aria-label="Search services"
            size="sm"
            variant="unstyled"
            fontSize="14px"
            fontWeight="medium"
            borderRadius={0}
            onClick={() => pageChange && pageChange(queryPageIndex + 1)}
            disabled={queryPageIndex * queryPageSize >= totalCount}
            icon={<ChevronRightIcon />}
          />
        </HStack>
      </HStack>
    </Box>
  ) : null;
};

const Checkbox = (checkboxProps: TableToggleCommonProps & CheckboxProps) => {
  const { indeterminate, ...rest } = checkboxProps;
  const checkboxRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (checkboxRef.current && typeof indeterminate === 'boolean') {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [checkboxRef, indeterminate]);

  return <CCheckbox ref={checkboxRef} {...rest} />;
};

export default DataTable;

Checkbox.displayName = 'Checkbox';
export { Checkbox };
