import { useFormik } from 'formik';
import React, { useState } from 'react';

import { Box, Heading, Input, Stack, Text } from '@chakra-ui/react';

import { useValidateJiraKey } from '../../../hooks/validateJiraKey';
import { Theme } from 'uie/components';

interface Props {
  apiToken: string;
  apiError: any;
  isEdit: boolean;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  formikError: string | undefined;
}

function TokenSlider(props: Props) {
  const { theme: squadcastTheme } = Theme;
  return (
    <Box marginTop="20px">
      <Text fontSize="sm">
        Paste the client token in the below Jira API token box. For more details, please refer the
        Squadcast -{' '}
        <a
          target="_blank"
          href="https://support.squadcast.com/docs/jira-cloud"
          rel="noreferrer"
          style={{
            textDecoration: 'none',
            color: squadcastTheme.primary.default,
          }}
        >
          Jira Cloud Integration Support Article
        </a>
      </Text>
      <Stack spacing={2}>
        <Heading as="h6" size="sm" marginTop={'20px'}>
          Jira API Token
        </Heading>
        <Input
          placeholder="Place your Jira API token here"
          size="md"
          name="apiToken"
          onChange={event => {
            props.handleChange(event);
          }}
          defaultValue={props.apiToken}
          isDisabled={props.isEdit}
        />
        <Text fontSize={12} color="red.500" fontWeight={500}>
          {props.apiError && props.apiError?.response?.data?.meta?.error_message}
        </Text>
        <Text fontSize={12} color="red.500" fontWeight={500}>
          {props.formikError && props.formikError}
        </Text>
      </Stack>
    </Box>
  );
}

export default TokenSlider;
