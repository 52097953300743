import { Grid, Label, Theme } from 'uie/components';
import { connect } from 'react-redux';

import { IAppState } from '../../../../../../core/interfaces/IAppState';
import { IIncident } from '../../../../../../core/interfaces/IIncidents';
import IncidentNotificationLogs from '../notifications/notificationLogs';
import { RespondersList } from './components/responders-listing';
import { RESPONDER_LIST_TYPE } from './enums';
import { useGetResponders } from './hooks/useGetResponders';
import { useEffect, useRef } from 'react';
import { Channel } from 'pusher-js';
import { API } from 'core';
import { IOrganizationEvents } from 'core/interfaces/IOrganization';
import { useQueryClient } from 'react-query';
import { invalidateRespondersList } from './hooks/helper';

const { theme } = Theme;

interface IProps extends Pick<IAppState, 'organization'> {
  incident: IIncident;
}

const IncidentDetailsResponderBox = ({ incident, ...props }: IProps) => {
  const { isSuccess, responders } = useGetResponders(incident.id);
  const queryClient = useQueryClient();

  const socketi = useRef<Channel | null>(null);

  const bindPusherEvents = () => {
    socketi.current = API.socket.subscribe(incident.id);

    socketi.current.bind('reload-event', (data: { message: IOrganizationEvents }) => {
      if (data.message === 'reload-incident') {
        invalidateRespondersList(queryClient);
      }
    });
  };

  const unbindPusherEvents = () => {
    if (socketi.current) {
      socketi.current.unbind('reload-event');
    }
  };

  useEffect(() => {
    bindPusherEvents();
    return () => unbindPusherEvents();
  }, []);

  return (
    <Grid
      type="column"
      className="incident_details__soft_card invert incident_details__responders_list"
    >
      <Grid alignItems="center" justifyContent="space-between">
        <Label fontSize={14} color={theme.shades.black} fontWeight={500}>
          Responder{incident.assignedTo.length > 1 ? 's' : ''}
        </Label>
        <IncidentNotificationLogs incidentId={incident.id} />
      </Grid>
      <RespondersList
        organization={props.organization}
        incident={incident}
        assigneeList={incident.assignedTo}
        type={RESPONDER_LIST_TYPE.ASSIGNEE}
      />
      {isSuccess && (
        <RespondersList
          organization={props.organization}
          incident={incident}
          assigneeList={responders}
          type={RESPONDER_LIST_TYPE.RESPONDER}
        />
      )}
    </Grid>
  );
};

export default connect(({ organization }: IAppState) => ({
  organization,
}))(IncidentDetailsResponderBox);
