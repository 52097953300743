import { Duration } from 'luxon';
import { getValidDurations } from './graphs.utils';
import { round } from './resultset.utils';
import { largestUnit, Units, UnitsFn } from './units.utils';

export function durationUnits(): UnitsFn {
  return (durationInSeconds: number) => {
    const duration = Duration.fromDurationLike({ seconds: durationInSeconds }).shiftTo(
      'days',
      'hours',
      'minutes',
      'seconds',
      'milliseconds',
    );

    const validDurations = getValidDurations({
      days: duration.days,
      hours: duration.hours,
      minutes: duration.minutes,
      seconds: duration.seconds,
      milliseconds: duration.milliseconds,
    });

    const tooltip = Duration.fromDurationLike({ ...validDurations }).toHuman({
      unitDisplay: 'short',
    });

    const unit = largestUnit(duration);
    const length = round(duration.as(unit));
    const display = Duration.fromObject({ [unit]: length }).toHuman({ unitDisplay: 'short' });
    return [display, tooltip];
  };
}

export function applyUnits(value?: number, unitsFn?: UnitsFn): Units {
  if (value === undefined) {
    return ['', ''];
  }

  if (unitsFn === undefined) {
    const str = value.toLocaleString();
    return [str, str];
  }

  return unitsFn(value);
}

export function textUnits(unit: string): UnitsFn {
  // TODO: I18N + pluralize, unit would be a key

  return (value: number) => {
    const display = value.toLocaleString();
    const base = `${display} ${unit}`;

    const tooltip = value > 1 ? base + 's' : base;

    return [display, tooltip];
  };
}
