import Axios from 'axios';
import qs from 'query-string';
import { API } from 'core';
import { QUERY_CONFIG } from 'library/query-config';
import { QueryClient, useQuery } from 'react-query';

import { QUERY_KEY } from './queryKeys';
import { EntityACL, EntityACLMeta } from 'core/userAccess/types';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';
import { GER_DATA } from '../types';

const getList = async (
  teamId: string,
  page: number,
  limit: number,
  search?: string,
  ownerIds?: string[],
) => {
  const props = {
    owner_id: teamId,
    page_size: limit,
    page_number: page + 1,
    ...(search ? { 'filters.search': search } : null),
    ...(ownerIds?.length ? { 'filters.owner_id[]': ownerIds } : null),
  };
  const response = await Axios.get(
    `${API.config.batman}/organizations/${
      API.config.organizationId
    }/global-event-rules?${qs.stringify(props)}`,
  );
  const acl = response.data.meta.acl;

  setUserAccessStore({
    entityACLMap: {
      ...getUserAccessStore().entityACLMap,
      ger: {
        ...getUserAccessStore().entityACLMap?.ger,
        ...acl,
      },
    },
  });
  return response;
};

export const useGerList = (
  teamId: string,
  page: number,
  limit: number,
  searchTerm?: string,
  ownerIds?: string[],
) => {
  const { data, isSuccess, isError, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEY.GER_LIST, teamId, page, limit, searchTerm, ownerIds],
    queryFn: () => getList(teamId, page, limit, searchTerm, ownerIds),
    ...QUERY_CONFIG,
  });
  const response = data?.data ?? { data: {}, meta: {} };
  return {
    gerData: response.data,
    isSuccess,
    isError,
    isLoading,
    isFetching,
    count: response.meta!.total_count ?? 0,
  };
};

export const invalidateGERList = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(QUERY_KEY.GER_LIST);
};
