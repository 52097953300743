import qs from 'query-string';
import React, { memo, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Grid,
  Heading,
  Icon,
  Skeleton,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';

import Card from '../../components/Card';
import { addAlertSourceUrl } from '../../contants/service.contant';
import useQueryParams from '../../helpers/helper.query-params';
import {
  useMultiServiceAutomationRulesQuery,
  useServiceAlertSourcesQuery,
} from '../../hooks/useExpandedView';
import { useMultiServiceMaintenanceQuery } from '../../hooks/useMentainance';
import { useServiceAnalyticsQuery } from '../../hooks/useServiceAnalytics';
import {
  useServiceDependencyQuery,
  useTodayServiceIncidentsQuery,
  useUpdateDependencyMutation,
} from '../../hooks/useServiceDetail';
import { OrganizationContext } from '../../index';
import { AlertSources } from '../../service.home/service.list/ExpandedDetail';
import ServiceAnalytics, { analyticsRange, AnalyticsRangeType } from './service.analytics';
import ServiceDependency from './service.dependency';
import ServiceIncidents from './service.incidents';
import ServiceRules from './service.rule';
import CustomTemplates from './service.templates';
import { useLastUpdatedServiceQuery, useServiceList } from '../../hooks/useServiceList';
import { useAlertSourceList } from '../../hooks/useAlerSourceList';
import { IIntegration } from '../../Interfaces/intigration';
import { Loader, ServiceTooltip } from '../../components';
import { AutomationRuleProvider } from './service.rule/context';
import { PlaceholderText } from '../../common';
import DelayIcon from '../../icons/AlertSources/DataSync';
import moment from 'moment';
import { useServicesOpenIncidentsQuery } from '../../hooks/useServiceOpenIncidents';
import UpgradePlanModal from 'components/upgradeplan.modal';
import { CrownIcon } from 'icons';
import { BillingService } from 'core';
import { IAppState } from 'core/interfaces/IAppState';
import { useSelector } from 'react-redux';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import { useUserAccess } from 'core/userAccess/UserAccessContext';

const queryParams = {
  tab: 'tab',
};

const tabs = [
  { label: 'Summary', key: 'summary' },
  { label: 'Automation', key: 'rules' },
  { label: 'Dependencies', key: 'dependencies' },
  { label: 'Custom Content Templates', key: 'customContent' },
];

const ServiceDetailAttribute = memo(() => {
  const organization = React.useContext(OrganizationContext);
  const { serviceId } = useParams<{ serviceId: string }>();

  const orgDetail = { organization } as Pick<IAppState, 'organization'>;
  const isCCTDisabled = BillingService.isFeatureDisabled(orgDetail, 'custom-content-templates');

  const history = useHistory();
  const query = useQueryParams();
  const activeParamsTab = query.get(queryParams.tab);
  const activeTeamId = organization?.selectedTeam.teamId ?? '';

  const [activeAnalyticsRange, setActiveAnalyticsRange] = React.useState(analyticsRange[0].key);
  const [showUpgradeModal, setUpgradeModal] = React.useState(false);

  const activeTab = useMemo(() => {
    const activeTabIndex = tabs.findIndex(tab => tab.key === activeParamsTab) || 0;
    return activeTabIndex > -1 ? activeTabIndex : 0;
  }, [activeParamsTab]);

  const granularityForAnalytics = useMemo(
    () => analyticsRange.find(ar => ar.key === activeAnalyticsRange)?.granularity ?? 'day',
    [activeAnalyticsRange],
  );

  const { alertSourceList = [] }: { alertSourceList: IIntegration[] } = useAlertSourceList();
  const {
    data: alertSources,
    isLoading: isAlertSourcesLoading,
    refetch,
  } = useServiceAlertSourcesQuery(serviceId, activeTeamId);

  const { data: serviceList } = useServiceList(activeTeamId);

  const {
    data: serviceDependency,
    isLoading: dependencyLoading,
    isFetching: dependencyFetching,
    refetch: refetchDependency,
  } = useServiceDependencyQuery(serviceId, activeTeamId);

  const serviceDependencyList = useMemo(
    () => serviceDependency?.data.services.map(s => s.id) ?? [],
    [serviceDependency],
  );

  const { mutateAsync: updateDependency, isLoading: dependencyUpdating } =
    useUpdateDependencyMutation(serviceId, activeTeamId);

  const dependencyAutomations = useMultiServiceAutomationRulesQuery(
    serviceDependencyList,
    activeTeamId,
  );
  const { data: openIncidentsData = {}, isLoading: isLoadingIncidentsMetrics } =
    useServicesOpenIncidentsQuery(serviceDependencyList, activeTeamId);
  const dependencyMaintenance = useMultiServiceMaintenanceQuery(serviceDependencyList);
  const { data: analyticsData = [], isFetching: isLoadingAnalytics } = useServiceAnalyticsQuery(
    serviceId,
    granularityForAnalytics,
  );
  const { data: serviceDetailIncidents, isLoading: isIncidentsLoading } =
    useTodayServiceIncidentsQuery(serviceId, activeTeamId);

  const incidentList = useMemo(() => {
    return (
      serviceDetailIncidents?.incidents.map(incident => {
        const alertSource = Array.isArray(alertSourceList)
          ? alertSourceList.find(alert => alert._id === incident.alert_source_id)
          : null;

        return {
          ...incident,
          alert_source_short_name: alertSource?.shortName ?? '',
          alert_source_type: alertSource?.type ?? '',
        };
      }) ?? []
    );
  }, [serviceDetailIncidents?.incidents]);

  const updateDependencyList = async (dependencies: string[], callbackFn: () => void) => {
    try {
      await updateDependency(serviceDependencyList.filter(d => !dependencies.includes(d)));
      callbackFn();
    } catch (error) {
      console.error(error);
    }
  };

  const serviceLastUpdated = useLastUpdatedServiceQuery();

  const toggleAnalyticsRange = (key: AnalyticsRangeType) => () => {
    setActiveAnalyticsRange(key);
  };

  const handleTabChange = (index: number, replaceHistory?: boolean) => {
    const config = {
      pathname: history.location.pathname,
      search: qs.stringifyUrl({
        url: '',
        query: { [queryParams.tab]: tabs.find((_tab, i) => i === index)?.key },
      }),
    };

    history.replace(config);
  };

  const onAlertSourceAdd = () => {
    history.push(`${addAlertSourceUrl}?service=${serviceId}`);
  };

  useEffect(() => {
    if (!activeParamsTab) {
      handleTabChange(0);
    }
  }, [activeParamsTab]);

  const OBACEnabled = useSelector(
    (state: IAppState) => state.organization.currentOrg?.o?.config.obac_enabled,
  );
  const hasUpdateAccess = useUserAccess().hasUpdateAccess;
  const hasDeleteAccess = useUserAccess().hasDeleteAccess;

  const hasUpdate = hasUpdateAccess('services', serviceId);

  return (
    <Box p={4}>
      <Tabs
        isLazy
        variant="enclosed"
        index={activeTab}
        _focus={{ outline: 'none' }}
        onChange={index => {
          handleTabChange(index);
          if (index === 3 && isCCTDisabled) {
            setUpgradeModal(true);
          } else {
            setUpgradeModal(false);
          }
        }}
      >
        <TabList _focus={{ outline: 'none' }}>
          {tabs.map(tab => (
            <Tab _focus={{ outline: 'none' }} key={tab.key}>
              {tab.key === 'customContent' && isCCTDisabled && <CrownIcon />}
              &nbsp;{tab.label}
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          <TabPanel p={0}>
            <VStack align="stretch">
              <Flex p={5} justifyContent="center">
                <ButtonGroup size="sm" isAttached variant="outline">
                  {analyticsRange.map(({ key }) => (
                    <Button
                      isActive={activeAnalyticsRange === key}
                      key={key}
                      onClick={toggleAnalyticsRange(key)}
                    >
                      <Text fontSize={14} fontWeight={400} casing="capitalize">
                        {key}
                      </Text>
                    </Button>
                  ))}
                </ButtonGroup>
              </Flex>

              <Grid gridTemplateColumns={{ md: '1fr', lg: '2fr 5fr' }} columnGap={3}>
                <Card
                  header={
                    <Flex justifyContent={'space-between'} alignItems={'center'}>
                      <Heading as="h5" fontSize={18} fontWeight={400} color="black">
                        Alert Sources
                        {serviceLastUpdated && serviceLastUpdated.isSuccess && (
                          <ServiceTooltip
                            text={`Updated ${moment(serviceLastUpdated.data.last_updated).fromNow(
                              true,
                            )} ago`}
                            placement="top"
                          >
                            <span>
                              <Icon w={3.5} h={3.5} ml={2} as={DelayIcon}></Icon>
                            </span>
                          </ServiceTooltip>
                        )}
                      </Heading>
                      <NoPermissionTooltip isDisabled={hasUpdate}>
                        <Button
                          color="default"
                          variant="outline"
                          size="sm"
                          onClick={onAlertSourceAdd}
                          isDisabled={!hasUpdate}
                        >
                          Add
                        </Button>
                      </NoPermissionTooltip>
                    </Flex>
                  }
                  body={
                    <Box pos="relative" h="calc(100% - 64px)">
                      <Box pos="absolute" h="100%" w="100%">
                        {isAlertSourcesLoading ? (
                          <Loader.Spinner centered />
                        ) : (
                          <AlertSources
                            width="100%"
                            height="100%"
                            expandedAlertData={{ data: alertSources ?? 0 }}
                            serviceId={serviceId}
                            refetchAlert={() => {
                              refetch();
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  }
                />

                <VStack align="stretch" rowGap={3}>
                  <Box height={450} width="99%" bgColor="gray.50">
                    {isLoadingAnalytics && (
                      <Flex justifyContent="center" alignItems="center" height="inherit">
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="md"
                        />
                      </Flex>
                    )}

                    {!isLoadingAnalytics &&
                      serviceLastUpdated &&
                      !!analyticsData.length &&
                      serviceLastUpdated.isSuccess && (
                        <Flex justifyContent="flex-end" alignItems="center" mt={2} mr={3}>
                          <Text as="i" fontSize="xs" color="blackAlpha.600">
                            <span>
                              <Icon w={3} h={3} mr={1} as={DelayIcon}></Icon>
                            </span>
                            Updated {moment(serviceLastUpdated.data.last_updated).fromNow(true)} ago
                          </Text>
                        </Flex>
                      )}
                    {!isLoadingAnalytics &&
                      (analyticsData.length ? (
                        <ServiceAnalytics
                          data={analyticsData}
                          granularity={granularityForAnalytics}
                        />
                      ) : (
                        <Flex justifyContent="center" alignItems="center" height="inherit">
                          <PlaceholderText
                            message="Nothing to show here"
                            tip={
                              'Tip: Alert sources are how services receive incidents. Add your first one to see the value of the platform.'
                            }
                          />
                        </Flex>
                      ))}
                  </Box>

                  <Card
                    header={
                      <Heading as="h5" fontSize={18} fontWeight={400} color="black">
                        Incidents Today
                      </Heading>
                    }
                    body={
                      <Flex p={4}>
                        <ServiceIncidents
                          incidents={incidentList}
                          incidentsLoading={isIncidentsLoading}
                        />
                      </Flex>
                    }
                  />
                </VStack>
              </Grid>
            </VStack>
          </TabPanel>

          <TabPanel p={0}>
            <AutomationRuleProvider serviceId={serviceId}>
              <ServiceRules />
            </AutomationRuleProvider>
          </TabPanel>

          <TabPanel p={0}>
            <ServiceDependency
              serviceList={serviceList?.data.services ?? []}
              serviceDependency={serviceDependency}
              dependencyMetrics={{
                metrics: openIncidentsData,
                loading: isLoadingIncidentsMetrics,
              }}
              dependencyAutomations={dependencyAutomations.map(automations => ({
                ...automations.data,
                loading: automations.isFetching,
              }))}
              dependencyMaintenance={dependencyMaintenance.map(maintenance => ({
                data: maintenance.data,
                loading: maintenance.isFetching,
              }))}
              serviceId={serviceId}
              dependencyLoading={dependencyLoading}
              dependencyFetching={dependencyFetching}
              refetchDependency={refetchDependency}
              deletingDependency={dependencyUpdating}
              updateDependency={updateDependencyList}
            />
          </TabPanel>
          <TabPanel p={0}>
            <CustomTemplates orgDetail={orgDetail} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <UpgradePlanModal
        hasBillingPermission={BillingService.hasManageBillingPermission(orgDetail)}
        showModal={showUpgradeModal}
        message={BillingService.getMessage(0, 'custom-content-templates', orgDetail)}
        onCancel={() => {
          setUpgradeModal(false);
          handleTabChange(0);
        }}
        header={BillingService.getHeader(0, 'custom-content-templates', orgDetail)}
        onUpgrade={() => setUpgradeModal(false)}
      />
    </Box>
  );
});

export default ServiceDetailAttribute;
