import { Grid, Label, FocusedSearch, ErrorBlock } from 'uie/components';
import { IAppState } from 'core/interfaces/IAppState';
import { IUsersOfOrganization } from 'core/interfaces/IUserData';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import AddMemberToTeam from './AddMemberToTeam';
import { useGetAllMembers, useUpdateRole } from './hook';
import { MemberRow } from './MemberRow';
import { ConfirmConvertToMembersModal, ConfirmDeleteMemberModal } from './Modal/ConfirmModal';
import { DepsModal } from './Modal/ConflictModal/DepsModal';
import { MemberConflictModal } from './Modal/ConflictModal/MemberConflict';
import { useOBACMemberStore } from './store';
import { useState } from 'react';
import { Role } from './types';
import { PageLoader } from 'library/atoms';
import { Spinner } from '@chakra-ui/spinner';
import { ErrorText } from '@squadcast/alchemy-ui/carbon';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_MEMBER_UPDATED_TO_MEMBER } from 'core/const/tracker';

export function ObacMember() {
  const { id: teamId } = useParams<{ id: string }>();

  const [value, setValue] = useState('');

  const organization = useSelector((state: IAppState) => state.organization);

  const team = organization?.teams?.t?.find?.(t => t.id === teamId);
  const { data: members, isLoading, isError } = useGetAllMembers(teamId);

  const closeActionsDrawer = useOBACMemberStore(store => store.closeActionsDrawer);

  const showConfirmDeleteMemberModal = useOBACMemberStore(store => store.confirmDeleteModal);
  const closeConfirmMemberDeleteModal = useOBACMemberStore(store => store.closeConfirmDeleteModal);
  const actingMemberName = useOBACMemberStore(store => store.actingMemberName);
  const actingMemberId = useOBACMemberStore(store => store.actingMemberId);

  const isRemoveMemberConflictModalOpened = useOBACMemberStore(
    store => store.isRemoveMemberConflictModalOpened,
  );

  const closeRemoveMemberConflictModal = useOBACMemberStore(
    store => store.closeRemoveMemberConflictModal,
  );

  const isUpdateRoleConflictModalOpened = useOBACMemberStore(
    store => store.isUpdateRoleConflictModalOpened,
  );

  const openUpdateRoleConflictModal = useOBACMemberStore(
    store => store.openUpdateRoleConflictModal,
  );

  const setDeleteState = useOBACMemberStore(store => store.setDeleteState);

  const deleteState = useOBACMemberStore(store => store.deleteState);

  const closeUpdateRoleConflictModal = useOBACMemberStore(
    store => store.closeUpdateRoleConflictModal,
  );

  const confirmConvertToMembersModal = useOBACMemberStore(
    store => store.confirmConvertToMembersModal,
  );
  const closeConfirmConvertToMembersModal = useOBACMemberStore(
    store => store.closeConfirmConvertToMembersModal,
  );

  const actingMemberRole = useOBACMemberStore(store => store.actingMemberRole);

  const teams = useSelector((state: IAppState) => state.organization.teams.t);
  const teamName = teams.find(t => t.id === teamId)?.name;

  const { mutate: updateRole } = useUpdateRole({
    onSuccess: () => {
      closeActionsDrawer();
      AppTracker.track(T_WA_MEMBER_UPDATED_TO_MEMBER, {
        'Team ID': teamId,
      });
    },
    onError: (e: any) => {
      if (e.response?.status === 409) {
        openUpdateRoleConflictModal();
      }
    },
  });

  if (isLoading) {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Spinner color="black" />
      </div>
    );
  }
  if (isError) {
    return <ErrorBlock>This is an un-acceptable error</ErrorBlock>;
  }

  const filteredMembers = members?.filter?.(member => {
    return member.name.toLowerCase().includes(value.toLowerCase());
  });

  return (
    <div>
      <Grid
        type="column"
        style={{
          marginRight: '20%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '4px',
            marginBottom: '8px',
          }}
        >
          <FocusedSearch
            value={value}
            height="32px"
            onChange={e => setValue(e.target.value)}
            placeholder="Search right here"
          />
        </div>
        <Grid flexWidth={12} alignItems="center">
          <Grid flexWidth={5} alignItems="center">
            <Label
              style={{
                fontSize: '14px',
                lineHeight: '150%',
              }}
            >
              Name
            </Label>
          </Grid>
          <Grid flexWidth={6}>
            <Label
              style={{
                fontSize: '14px',
                lineHeight: '150%',
              }}
            >
              Team Role
            </Label>
          </Grid>
          <Grid flexWidth={1} />
        </Grid>
        {filteredMembers?.map?.((user, i) => {
          return (
            <MemberRow user={user} key={user.user_id} teamId={teamId} members={members ?? []} />
          );
        })}
      </Grid>
      <AddMemberToTeam members={members ?? []} teamId={teamId} />

      <ConfirmDeleteMemberModal
        openModal={showConfirmDeleteMemberModal}
        handleCloseModal={closeConfirmMemberDeleteModal}
        teamName={teamName ?? ''}
        userName={actingMemberName}
        onConfirm={confirm => {
          if (confirm) {
            setDeleteState('checking_conflicts');
          }
          closeConfirmMemberDeleteModal();
        }}
      />
      <ConfirmConvertToMembersModal
        openModal={confirmConvertToMembersModal}
        handleCloseModal={() => closeConfirmConvertToMembersModal()}
        memberName={actingMemberName ?? ''}
        onConfirm={confirm => {
          updateRole({
            team_id: teamId,
            user_id: actingMemberId ?? '',
            role: Role.Member,
          });
          closeConfirmConvertToMembersModal();
        }}
      />
      <MemberConflictModal
        openModal={isRemoveMemberConflictModalOpened}
        closeModal={() => {
          closeRemoveMemberConflictModal();
        }}
        memberName={actingMemberName ?? ''}
        members={members ?? []}
        onConfirm={() => {}}
        attemptedAction="remove"
      />
      <MemberConflictModal
        openModal={isUpdateRoleConflictModalOpened}
        closeModal={() => {
          closeUpdateRoleConflictModal();
        }}
        memberName={actingMemberName ?? ''}
        members={members ?? []}
        onConfirm={(selectedMember: IUsersOfOrganization) => {
          updateRole({
            team_id: teamId,
            user_id: actingMemberId ?? '',
            role: actingMemberRole as Role,
            replace_with: selectedMember.id,
          });
          closeUpdateRoleConflictModal();
        }}
        attemptedAction="updateRole"
      />
      <DepsModal isOpen={deleteState !== undefined} team={team!} />
    </div>
  );
}
