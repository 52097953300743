import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { last3Months } from '../../../../core/const/analytics';
import { IAnalyticsConfig, IAnalyticsMaps } from '../../../../core/interfaces/IAnalytics';
import { IAppState } from '../../../../core/interfaces/IAppState';
import { IIntegration } from '../../../../core/interfaces/IIntegration';
import { IService } from '../../../../core/interfaces/IService';

interface IContextProps {
  config: IAnalyticsConfig;
  maps: IAnalyticsMaps;
  updateMaps: () => void;
  updateConfig: (partialConfig: Partial<IAnalyticsConfig>) => void;
}

// tslint:disable-next-line: no-object-literal-type-assertion
export const AnalyticsContext = React.createContext<IContextProps>({} as IContextProps);

type IProps = Pick<IAppState, 'organization' | 'integrations'>;

const defaultAnalyticsConfig: IAnalyticsConfig = {
  selectedDate: new Date(new Date().setDate(new Date().getDate() - 1)),
  selectedLines: [],
  timeRange: {
    lt: new Date(),
    gt: last3Months,
  },
};

const AnalyticsProvider: React.FC<IProps> = ({ children, organization, integrations }) => {
  const [config, setConfig] = useState<IAnalyticsConfig>(defaultAnalyticsConfig);
  const [maps, setMaps] = useState<IAnalyticsMaps>(
    calculateMaps(organization.services.s, integrations.i),
  );

  const updateMaps = () => {
    setMaps(calculateMaps(organization.services.s, integrations.i));
  };

  const updateConfig = (partialConfig: Partial<IAnalyticsConfig>) =>
    setConfig({ ...config, ...partialConfig });

  return (
    <AnalyticsContext.Provider value={{ config, maps, updateMaps, updateConfig }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default connect(({ organization, integrations }: IAppState) => ({
  organization,
  integrations,
}))(AnalyticsProvider);

export const useAnalyticsContext = () => useContext(AnalyticsContext);
export const AnalyticsConsumer = AnalyticsContext.Consumer;

const calculateMaps = (
  serviceList: IService[],
  integrationsList: IIntegration[],
): IAnalyticsMaps => ({
  service: serviceList.reduce((c: any, s: IService) => {
    c[s.id] = s.name;
    return c;
  }, {}),
  integrationType: integrationsList.reduce((c: any, i: IIntegration) => {
    c[i._id] = i.type;
    return c;
  }, {}),
});
