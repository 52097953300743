import styled from 'styled-components';
import { slideDownOpen, slideUpClose, slideDownClose, slideUpOpen } from '../../utils/Animations';

export interface IDrop {
  height?: string | 'fit-content';
  width?: string | 'fit-content';
  maxHeight?: string | 'fit-content';
  maxWidth?: string | 'fit-content';
  top?: string | '10px';
  padding?: string | '0';
  offset?: string | '0px';
  invertAnimation?: boolean | false;
}

export const DropDownContainer = styled.div<IDrop>`
  left: ${props => props.offset || '0px'};
  height: ${props => props.height || 'fit-content'};
  max-height: ${props => props.maxHeight || 'fit-content'};
  width: ${props => props.width || 'fit-content'};
  max-width: ${props => props.maxWidth || 'fit-content'};
  margin-top: ${props => props.top || '10px'};
  background-color: ${props => props.theme.shades.white};
  padding: ${props => props.padding || '0'};
  display: none;
  border-radius: 3px;
  box-shadow: ${props => props.theme.shadow.default};
  position: absolute;
  z-index: 999;
  overflow-x: auto;
  overflow-y: auto;
  animation-duration: 0.16s;
  animation-fill-mode: both;
  animation-name: ${props => (props.invertAnimation ? slideUpOpen : slideDownOpen)};

  &.close {
    animation-name: ${props => (props.invertAnimation ? slideDownClose : slideUpClose)};
  }

  &:focus-visible {
    outline: none;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 64px;
    background: ${props => props.theme.shades.smoke};
    border-radius: 5px;
    border: 3px solid ${props => props.theme.shades.white};
  }

  &:focus {
    outline: none;
  }
`;

export const DropDownHook = styled.div.attrs(props => ({ ...props, tabIndex: 0 }))`
  display: block;
  transition: all 0.17s ease-in;
  position: relative;

  &:focus ${DropDownContainer}, &:focus-within ${DropDownContainer} {
    display: block;
  }
`;

interface IDropDownOptionShellProps {
  height?: string | 'fit-content';
  width?: string | '100%';
  padding?: string | '8px 16px';
  focusColor?: string | 'props.theme.primary.light';
}

export const DropDownOptionShell = styled.button<IDropDownOptionShellProps>`
  font-family: ${props => props.theme.fontFamily};
  height: ${props => props.height || 'fit-content'};
  width: ${props => props.width || '100%'};
  padding: ${props => props.padding || '8px 16px'};
  background: ${props => props.theme.shades.white};
  font-size: 14px;
  border: none;
  transition: 0.17s opacity, 0.17s box-shadow, 0.17s filter, 0.17s background ease-in-out;
  display: flex;
  outline: none;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;

  &:disabled {
    opacity: 0.33;
    cursor: no-drop !important;
  }

  &:hover:not(:disabled) {
    opacity: 1;
    background: ${props => props.focusColor || `${props.theme.primary.default}0e`};
  }

  &:active:not(:disabled) {
    opacity: 1;
    background: ${props => props.focusColor || `${props.theme.primary.default}1e`};
  }

  &:focus {
    background: ${props => props.focusColor || `${props.theme.primary.default}1e`};
    opacity: 1;
  }

  &:focus-visible {
    outline: ${props => props.theme.focus.default};
    outline-offset: 4px;
  }
`;
