import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import {
  REQUEST_INCIDENT_DETAIL_RUNBOOK,
  REQUEST_INCIDENT_DETAIL_RUNBOOK_FAIL,
} from '../../const/organization';
import { getIncidentDetailRunbooks } from 'core/api/organization/getIncidentDetailRunbookOrTask';
import { requestIncidentDetailRunbookSuccess } from 'core/actions';
import { IRunbookList } from 'core/interfaces/ITask';

const getIncidentDetailRunbookEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(REQUEST_INCIDENT_DETAIL_RUNBOOK),
    map(action => action.payload),
    switchMap(({ incidentId }: { incidentId: string }) => {
      return getIncidentDetailRunbooks(incidentId).pipe(
        map(({ response }: AjaxResponse) => response),
        map(({ result, data }: { result: boolean; data: IRunbookList[] }) => {
          return requestIncidentDetailRunbookSuccess(data);
        }),
        catchError((error: AjaxError) => {
          if (error.status === 403) {
            return of(requestIncidentDetailRunbookSuccess([]));
          }

          return of({
            type: REQUEST_INCIDENT_DETAIL_RUNBOOK_FAIL,
            payload: error.xhr,
            error: true,
          });
        }),
      );
    }),
  );

export default getIncidentDetailRunbookEpic;
