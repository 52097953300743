import React from 'react';
import { Grid, Label, SpinLoader } from 'uie/components';
import UserItem from './userItem';

import './index.css';
import { IUsersOfOrganization } from '../../../../../../core/interfaces/IUserData';

interface IProps {
  users: IUsersOfOrganization[];
  roleFilter: string[];
  searchTerm: string;
  resendVerificationMail: (user: IUsersOfOrganization) => () => Promise<boolean>;
  deleteUser: (userID: string) => void;
  updateUser: (userID: string, index: number, roleID: string, role: string) => void;
  saving: number;
  orgHasEnabledSSO: boolean;
}

const Table: React.FC<IProps> = props => {
  return (
    <Grid type="column" width="100%" className="mt-20">
      <Grid flexWidth={12} className="user-header">
        <Grid flexWidth={3}>
          <Label fontWeight={500}>Name</Label>
        </Grid>
        <Grid flexWidth={3}>
          <Label fontWeight={500}>Email</Label>
        </Grid>
        <Grid flexWidth={2}>
          <Label fontWeight={500}>Phone</Label>
        </Grid>
        <Grid flexWidth={2}>
          <Label fontWeight={500}>Organization role</Label>
        </Grid>
        <Grid flexWidth={2} />
      </Grid>
      <Grid type="column">
        {props.users.length > 0 ? (
          props.users.map((user, index: number) => (
            <UserItem
              key={user.id}
              index={index}
              user={user}
              resendVerificationMail={props.resendVerificationMail}
              deleteUser={props.deleteUser}
              updateUser={props.updateUser}
              saving={props.saving}
              orgHasEnabledSSO={props.orgHasEnabledSSO}
            />
          ))
        ) : props.roleFilter.length === 0 && props.searchTerm.length === 0 ? (
          <Grid alignItems="center" justifyContent="center" className="mt-20">
            <SpinLoader />
            <span className="ml-10">Loading...</span>
          </Grid>
        ) : (
          <Grid alignItems="center" justifyContent="center">
            <p className="empty-state-headline">No Users Found</p>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Table;
