import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Para, SnackBar, SnackContext, TextButton, Theme } from 'uie/components';
import { matchPath, useLocation } from 'react-router-dom';
import { IAppState } from 'core/interfaces/IAppState';
import { CloseIcon, PhoneDisabledIcon } from 'icons';
import { Link } from 'react-router-dom';
import { LocalStore } from 'store/local';
import ProfileService from 'core/services/service.editprofile';
import { SCHEDULES_V2_PATH } from '../routes/routes';

interface IProps {
  organization: IAppState['organization'];
}

const { theme } = Theme;

const Banner = (props: IProps) => {
  const _profileService = new ProfileService();

  // Snack
  const _createSnack = SnackContext();
  const [showSnack, setShowSnack] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [background, setBackground] = useState<string>('');
  const [showBannerForSchedules, setShowBannerForSchedules] = useState<boolean>(true);
  const [showMigratedSchedulesBanner, setShowMigratedSchedulesBanner] = useState<boolean>(false);
  const { pathname } = useLocation();
  const orgConfig = props.organization.currentOrg.o?.config;

  const closeSchedulesBanner = () => {
    LocalStore.setShouldShowSchedulesBannerTopBar(false);
    setShowBannerForSchedules(false);
  };

  const closeMigratedSchedulesBanner = () => {
    LocalStore.setShouldShowMigratedSchedulesBannerTopBar(false);
    setShowMigratedSchedulesBanner(false);
  };

  useEffect(() => {
    if (matchPath(pathname, SCHEDULES_V2_PATH)) {
      closeSchedulesBanner();
      closeMigratedSchedulesBanner();
    }
  }, [pathname]);

  useEffect(() => {
    if (orgConfig && orgConfig.show_migrated_schedules) {
      LocalStore.setShouldShowMigratedSchedulesBannerTopBar(
        !!LocalStore.shouldShowMigratedSchedulesBannerTopBar(),
      );
      setShowMigratedSchedulesBanner(!!LocalStore.shouldShowMigratedSchedulesBannerTopBar());
    }
    if (orgConfig && orgConfig.disable_old_schedules) {
      closeSchedulesBanner();
    } else {
      LocalStore.setShouldShowSchedulesBannerTopBar(!!LocalStore.shouldShowSchedulesBannerTopBar());
      setShowBannerForSchedules(!!LocalStore.shouldShowSchedulesBannerTopBar());
    }
  }, [orgConfig]);

  useEffect(() => {
    if (!showSnack) {
      _createSnack(null);
    } else {
      _createSnack(
        <SnackBar background={background} maxWidth="300px">
          <Grid alignItems="center" justifyContent="center">
            <Para color={theme.shades.white} fontWeight={400} style={{ textAlign: 'center' }}>
              {message}
            </Para>
          </Grid>
        </SnackBar>,
      );
    }
    return () => _createSnack(null);
  });

  const hideSnackBar = () => {
    setShowSnack(false);
    setMessage('');
  };

  const showSnackBar = (msg: string, bg: string, timeout: number) => {
    setMessage(msg);
    setBackground(bg);
    setShowSnack(true);
    setTimeout(hideSnackBar, timeout);
  };

  const resendVerificationEmail = async () => {
    try {
      await _profileService.resendVerificationEmail();
      showSnackBar('Verification email sent successfully', theme.success.default, 5000);
    } catch (err) {
      showSnackBar('Something went wrong, try again later', theme.danger.default, 5000);
    } finally {
    }
  };

  const [showPhoneVerificationBanner, setShowPhoneVerificationBanner] = useState(false);
  // update the state on component mount
  useEffect(() => {
    (async () => {
      setShowPhoneVerificationBanner(await LocalStore.shouldShowVerifyPhoneNumberTopBar());
    })();
  }, []);

  const removePhoneVerificationBanner = async () => {
    try {
      await LocalStore.setShouldShowVerifyPhoneNumberTopBar(false);
      setShowPhoneVerificationBanner(false);
    } catch (err) {
      showSnackBar('Something went wrong!!', theme.danger.default, 5000);
    }
  };

  const renderVerifyEmailMessage = () => {
    return (
      <>
        <a
          href="https://support.squadcast.com/docs/squadcast-faq#2-how-can-i-enable-sms-and-phone-calls-for-my-account"
          target="_blank"
          rel="noopener noreferrer"
        >
          <PhoneDisabledIcon height={12} width={12} color={theme.shades.white} />
        </a>

        <Para fontSize={14} className="ml-5" fontWeight={400} color={theme.shades.white}>
          <a
            href="https://support.squadcast.com/docs/squadcast-faq#2-how-can-i-enable-sms-and-phone-calls-for-my-account"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: theme.shades.white }}
          ></a>
          To enable notifications, verify your email address.
        </Para>
        <TextButton
          buttonType="ghost"
          onClick={resendVerificationEmail}
          style={{
            marginLeft: 10,
            boxShadow: 'none',
            padding: 2,
          }}
        >
          <Para fontSize={14} color={theme.shades.white} style={{ textDecoration: 'underline' }}>
            Resend Verification Link
          </Para>
        </TextButton>
      </>
    );
  };

  const renderSandboxMessage = () => {
    return (
      <>
        <Para fontSize={14} className="ml-5" fontWeight={400}>
          To enable Phone and SMS notifications, reach out to
          <a
            href="mailto:support@squadcast.com?subject=Request to lift sandbox"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@squadcast.com
          </a>
        </Para>
      </>
    );
  };

  const renderVerifyPhoneMessage = () => {
    return (
      <>
        <Link to={`/user/${props.organization.currentUser.u?.id}`}>
          <Para fontSize={14} color={theme.shades.white} style={{ textDecoration: 'underline' }}>
            Verify your Phone Number
          </Para>
        </Link>
        <Para fontSize={14} className="ml-5" fontWeight={400} color={theme.shades.white}>
          to enable Phone and SMS notifications
        </Para>
        <IconButton onClick={removePhoneVerificationBanner}>
          <CloseIcon color={theme.shades.white} height={15} />
        </IconButton>
      </>
    );
  };

  const renderSchedulesIsLiveMessage = () => {
    return (
      <>
        <Link
          to={SCHEDULES_V2_PATH}
          onClick={closeSchedulesBanner}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <Para fontSize={14} color={theme.shades.white} style={{ textDecoration: 'underline' }}>
            Check out improved Schedules in Beta!
          </Para>
          <Para as="span" style={{ marginLeft: '5px' }}>
            🎉
          </Para>
        </Link>
        <IconButton onClick={closeSchedulesBanner}>
          <CloseIcon color={theme.shades.white} height={15} />
        </IconButton>
      </>
    );
  };

  const renderSchedulesAreMigratedMessage = () => {
    return (
      <>
        <Link
          to={SCHEDULES_V2_PATH}
          onClick={closeMigratedSchedulesBanner}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <Para fontSize={14} color={theme.shades.white} style={{ textDecoration: 'underline' }}>
            Schedules migration completed! Check it out here!
          </Para>
          <Para as="span" style={{ marginLeft: '5px' }}>
            🎉
          </Para>
        </Link>
        <IconButton onClick={closeMigratedSchedulesBanner}>
          <CloseIcon color={theme.shades.white} height={15} />
        </IconButton>
      </>
    );
  };

  let content = null;
  let bgcolor = theme.shades.cement;

  if (!props.organization.currentUser.u?.email_verified) {
    content = renderVerifyEmailMessage();
    bgcolor = theme.primary.default;
  } else if (!props.organization.currentUser.u.phone_verified && showPhoneVerificationBanner) {
    content = renderVerifyPhoneMessage();
    bgcolor = theme.primary.default;
  } else if (props.organization.currentOrg.o?.sandbox) {
    content = renderSandboxMessage();
    bgcolor = theme.shades.whiteSmoke;
  }

  return content !== null ? (
    <Grid
      justifyContent="center"
      alignItems="center"
      className="top_bar__sandbox"
      style={{
        backgroundColor: bgcolor,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      {content}
    </Grid>
  ) : (
    <>
      {showBannerForSchedules && (
        <Grid
          justifyContent="center"
          alignItems="center"
          className="top_bar__sandbox"
          style={{
            backgroundColor: theme.primary.default,
            marginLeft: '20px',
          }}
        >
          {renderSchedulesIsLiveMessage()}
        </Grid>
      )}
      {showMigratedSchedulesBanner && (
        <Grid
          justifyContent="center"
          alignItems="center"
          className="top_bar__sandbox"
          style={{
            backgroundColor: theme.primary.default,
            marginLeft: '20px',
          }}
        >
          {renderSchedulesAreMigratedMessage()}
        </Grid>
      )}
    </>
  );
};

export default Banner;
