import React, { ReactNode } from 'react';

import { Box, Container } from '@chakra-ui/react';

type Props = {
  children: ReactNode;
  customheight?: boolean;
};

function Layout({ children, customheight }: Props) {
  return (
    <Container
      display="flex"
      bg="gray.200"
      px="8px"
      py={2}
      maxW="container.xxl"
      minHeight={customheight ? '' : 'calc(100vh - 64px)'}
    >
      <Box bg="shades.white" flex="1" borderRadius={4} overflowY="auto">
        {children}
      </Box>
    </Container>
  );
}

export default Layout;
