import { HStack, Text } from '@chakra-ui/react';
import { IncidentTab } from './IncidentTab';
import { Tab } from 'library/atoms';
import { INCIDENT_STATUS, INCIDENTS_TAB } from '../common/enums';
import useLastUpdatedDateTime from '../hooks/useLastUpdatedDateTime';
import { useContext } from 'react';
import { getTabIndex, getTabName } from '../common/util';
import { ActionKind, IncidentListContext } from '../store';
import { toSentenceCase } from 'core/helpers/stringUtils';
import useTabGroup from 'core/hooks/useTabGroup';

const TAB_INDEX_QUERY_KEY = 'tab';

interface IncidentTabGroupProps {
  onOpenCreateIncident: () => void;
}

const IncidentTabGroup = ({ onOpenCreateIncident }: IncidentTabGroupProps) => {
  const { lastUpdatedDateTime, updateTime } = useLastUpdatedDateTime();
  const { activeTabIndex, setActiveTabIndex } = useTabGroup({
    includeInSearchParam: true,
    queryParamKey: TAB_INDEX_QUERY_KEY,
    getTabIndexFn: getTabIndex,
    getTabNameFn: getTabName,
  });

  const { dispatch } = useContext(IncidentListContext);

  const handleTabsChange = (index: number) => {
    setActiveTabIndex(index);
    dispatch({ type: ActionKind.RESET_BULK_SELECTION });
  };

  return (
    <Tab
      isLazy
      index={activeTabIndex}
      onChange={handleTabsChange}
      pt="12px"
      labels={[
        {
          name: toSentenceCase(INCIDENTS_TAB.ALL),
        },
        {
          name: toSentenceCase(INCIDENTS_TAB.OPEN),
          infoText: 'Lists all the Triggered and Acknowledged incidents',
        },
        {
          name: toSentenceCase(INCIDENTS_TAB.TRIGGERED),
        },
        {
          name: toSentenceCase(INCIDENTS_TAB.ACKNOWLEDGED),
        },
        {
          name: toSentenceCase(INCIDENTS_TAB.RESOLVED),
        },
        {
          name: toSentenceCase(INCIDENTS_TAB.SUPPRESSED),
        },
      ]}
      variant="outline"
      customHeaderElement={
        <HStack ml="auto" mr={6}>
          <Text variant="hint" color="secondary.1000">
            Auto refreshes in ~ 10 sec&nbsp;
          </Text>
          <Text variant="hint" color="secondary.700">
            (Last updated: {lastUpdatedDateTime})
          </Text>
        </HStack>
      }
    >
      {Object.values(INCIDENT_STATUS).map((item, index) => {
        return (
          <IncidentTab
            key={index}
            status={item}
            onListingUpdate={updateTime}
            onCreate={onOpenCreateIncident}
          />
        );
      })}
    </Tab>
  );
};
export default IncidentTabGroup;
