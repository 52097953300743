import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const TagTheme: ComponentStyleConfig = {
  baseStyle: {
    _focus: {
      boxShadow: 'none',
    },
  },
  sizes: {
    md: {
      container: {
        borderRadius: '3px',
        minHeight: '15px',
      },
    },
    lg: {
      container: {
        borderRadius: '4px',
        minHeight: '20px',
      },
    },
  },
  variants: {
    filter: ({ size }) => ({
      label: {
        pr: size === 'md' ? '3px' : '4px',
        fontWeight: '700',
      },
      container: {
        bg: 'secondary.100',
        p: {
          color: 'secondary.900',
        },
      },
      closeButton: {
        _focus: {
          boxShadow: 'none',
          bg: 'none',
        },
        fontSize: size === 'md' ? '9px' : '12px',
        width: size === 'md' ? '9px' : '12px',
        height: size === 'md' ? '9px' : '12px',
        margin: '0px',
        color: '#6C6B95',
      },
      _hover: {
        boxShadow: 'none',
        container: {
          bg: 'secondary.200',
        },
      },
    }),
    keyValue: ({ color, size }) => ({
      container: {
        border: '1px solid', // same width for both 1440 and 1920 as per figma
        borderColor: color,
      },
      closeButton: {
        _focus: {
          boxShadow: 'none',
          bg: 'none',
        },
        fontSize: size === 'md' ? '9px' : '12px',
        width: size === 'md' ? '9px' : '12px',
        height: size === 'md' ? '9px' : '12px',
        marginTop: '1px',
        ml: '2px',

        color: color || '#6C6B95',
        opacity: 1,
      },
    }),
    incidentStatus: ({ colorScheme }) => ({
      container: {
        padding: '4px 12px',
        fontWeight: 800,
        fontSize: '12px',
        color: 'brand.white',
        borderRadius: '4px',
        textTransform: 'capitalize',
        background: `incidentStatusTag.${colorScheme}`,
      },
    }),
  },
  defaultProps: {
    size: 'md',
    variant: 'keyValue',
  },
};
