import {
  REQUEST_ORG_USERS,
  REQUEST_ORG_USERS_SUCCESS,
  REQUEST_ORG_USERS_FAIL,
} from '../../const/organization';
import { IUserInfo } from '../../interfaces/IUserData';

const requestOrganizationUsers = () => ({
  type: REQUEST_ORG_USERS,
  payload: {},
});

const onRequestOrganizationUsersSuccess = (users: IUserInfo[]) => ({
  type: REQUEST_ORG_USERS_SUCCESS,
  payload: {
    users,
  },
});

const onRequestOrganizationUsersFail = (error: any) => ({
  type: REQUEST_ORG_USERS_FAIL,
  payload: {
    error,
  },
});

export {
  requestOrganizationUsers,
  onRequestOrganizationUsersSuccess,
  onRequestOrganizationUsersFail,
};
