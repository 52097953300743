import { Flex, ListItem, UnorderedList, VStack } from '@chakra-ui/layout';
import { Link, useLocation } from 'react-router-dom';
import { safeEncodeUriComponent } from 'views/main/organization/workflow/utils';
import GreetingMessage from './GreetingMessage';
import { useLCRStore } from 'views/main/organization/lcr/hooks/lcr.state';
import KeypadActionCard from './KeypadActionCard';
import { useEffect } from 'react';
import { AppTracker } from 'shared/analytics/tracker';
import { T_WA_UP_LCR_DETAILS_PAGE_LOADED } from 'core/const/tracker';
import { Text } from 'library/atoms/Text';
const LCRDetailsTab = () => {
  const path = useLocation();
  const lcrDetails = useLCRStore();

  useEffect(() => {
    AppTracker.track(T_WA_UP_LCR_DETAILS_PAGE_LOADED, {
      'LCR ID': lcrDetails?.lcrDataDetails.id,
    });
  }, []);

  return (
    <Flex flex={1} mt="4">
      <VStack flex={1} align="stretch">
        <Text variant="h3_800">INDEX</Text>
        <UnorderedList mt={0}>
          <ListItem ml={5} color={'brand.blue'}>
            <Link to={`${path.pathname}#${safeEncodeUriComponent('Greeting Message')}`}>
              Greeting Message
            </Link>
          </ListItem>
          {lcrDetails.lcrDataDetails.keypad_mappings.map((data, index) => (
            <ListItem ml={5} color={'brand.blue'} key={index}>
              <Link to={`${path.pathname}#${safeEncodeUriComponent(data.digit.toString())}`}>
                Keypad {data.digit}
              </Link>
            </ListItem>
          ))}
        </UnorderedList>
      </VStack>
      <VStack flex={3} align="stretch" bg="primary.100" py={4}>
        <GreetingMessage
          data={lcrDetails.lcrDataDetails.greeting_message}
          id={encodeURIComponent('Greeting Message')}
        />
        <KeypadActionCard data={lcrDetails.lcrDataDetails.keypad_mappings} />
      </VStack>
    </Flex>
  );
};

export default LCRDetailsTab;
