import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.st0{fill-rule:evenodd;clip-rule:evenodd;fill:url(#Rectangle_00000167377483903784195250000002886303204410215350_)}'
      }
    </style>
    <g id="Icon-Architecture_x2F_64_x2F_Arch_x5F_Amazon-RDS_x5F_64">
      <g id="Icon-Architecture-BG_x2F_64_x2F_Database">
        <linearGradient
          id="Rectangle_00000116938776319338437490000011028103633482781864_"
          gradientUnits="userSpaceOnUse"
          x1={-1297.575}
          y1={-20.8}
          x2={-1297.325}
          y2={-21.05}
          gradientTransform="matrix(80 0 0 80 103806 1684)"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#2e27ad',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#527fff',
            }}
          />
        </linearGradient>
        <path
          id="Rectangle"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'url(#Rectangle_00000116938776319338437490000011028103633482781864_)',
          }}
          d="M0 0h20v20H0z"
        />
      </g>
      <path
        id="Amazon-RDS_Icon_64_Squid"
        d="m3.85 3.5 2.325 2.325-.35.35L3.5 3.85v1.9H3v-2.5c0-.15.1-.25.25-.25h2.5v.5h-1.9zM17 3.25v2.5h-.5v-1.9l-2.325 2.325-.35-.35L16.15 3.5h-1.9V3h2.5c.15 0 .25.1.25.25zm-.5 11h.5v2.5c0 .15-.1.25-.25.25h-2.5v-.5h1.9l-2.325-2.325.35-.35L16.5 16.15v-1.9zm-.125-4.45c0-.825-.95-1.65-2.55-2.2l.15-.475c1.85.625 2.9 1.6 2.9 2.675s-1.05 2.05-2.9 2.675L13.8 12c1.625-.55 2.575-1.375 2.575-2.2zM3.65 9.8c0 .8.9 1.6 2.4 2.125l-.175.475C4.15 11.775 3.15 10.825 3.15 9.8s1-1.975 2.725-2.6l.175.475C4.525 8.2 3.65 9 3.65 9.8zm2.525 4.375L3.85 16.5h1.9v.5h-2.5c-.15 0-.25-.1-.25-.25v-2.5h.5v1.9l2.325-2.325.35.35zM10 7.825c-1.775 0-2.75-.45-2.75-.65s.975-.65 2.75-.65 2.75.45 2.75.65c0 .175-.975.65-2.75.65zm0 1.925c-1.7 0-2.75-.475-2.75-.725V7.8c.625.35 1.7.5 2.75.5s2.125-.15 2.75-.5v1.225c0 .275-1.05.725-2.75.725zm0 1.925c-1.7 0-2.75-.475-2.75-.725V9.725c.6.35 1.675.55 2.75.55s2.125-.175 2.75-.55v1.225c0 .25-1.05.725-2.75.725zm0 1.7c-1.775 0-2.75-.475-2.75-.725v-1.025c.6.35 1.675.55 2.75.55s2.125-.175 2.75-.55v1.025c0 .25-.975.725-2.75.725zm0-7.35c-1.575 0-3.25.35-3.25 1.15v5.475c0 .8 1.625 1.225 3.25 1.225s3.25-.425 3.25-1.225V7.175c0-.8-1.675-1.15-3.25-1.15z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);

export default SvgComponent;
