import { opFormats } from 'core/const/comparators';
import { IBasicExpression, IRuleExpressionOperation } from '../Interfaces/automation-rule';

export const generateExpression = (basicExpression: IBasicExpression[], event: any): string => {
  return basicExpression
    ? basicExpression
        .map(be => {
          const fs: string = opFormats[be.op];
          if (fs === '') return '';

          const rhs =
            getLHSType(be.lhs, event) === 'string' && be.op !== IRuleExpressionOperation.EOpFieldIs
              ? `"${be.rhs}"`
              : be.rhs;
          return fs.replace('{lhs}', be.lhs).replace('{rhs}', rhs);
        })
        .join(' && ')
    : '';
};

export const getLHSType = (lhs: string, event: Event): string => {
  return typeof flattenedObject(event)[lhs];
};

/**
 * @description returns flattened json object
 * @param obj object which needs to be flattened
 */
export const flattenedObject = (obj: Record<string, any>): Record<string, any> => {
  const result: any = {};
  const recurse = (cur: any, prop: any) => {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      const l = cur.length;
      for (let i = 0; i < l; i += 1) {
        recurse(cur[i], `${prop}[${i}]`);
      }
      if (l === 0) {
        result[prop] = [];
      }
    } else {
      let isEmpty = true;
      Object.keys(cur).forEach((p: any) => {
        isEmpty = false;
        recurse(cur[p], prop ? `${prop}["${p}"]` : p);
      });

      if (isEmpty) {
        result[prop] = {};
      }
    }
  };
  recurse(obj, '');
  return result;
};
