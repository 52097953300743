import { Text } from '@chakra-ui/react';
import { T_WA_UP_GER_RULESET_DELETED } from 'core/const/tracker';
import { FormButton, useToast } from 'library/atoms';
import { Modal } from 'library/molecules';
import { FC, useState } from 'react';
import { useQueryClient } from 'react-query';
import { AppTracker } from 'shared/analytics/tracker';
import { QUERY_KEY } from '../query/queryKeys';

import { deleteGer } from '../query/useDeleteGer';

type Props = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const GERDelete: FC<Props> = ({ id, isOpen, onSuccess, onClose }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const onDelete = () => {
    setIsLoading(true);
    deleteGer(id)
      .then(() => {
        onDeleteSuccess();
      })
      .catch(error => {
        onError(error);
      });
  };

  const onDeleteSuccess = () => {
    setIsLoading(false);

    queryClient.invalidateQueries(QUERY_KEY.ORG_LEVEL_GER_COUNT, { refetchInactive: true });

    toast({
      status: 'success',
      text: 'Rule deleted successfully',
    });
    AppTracker.track(T_WA_UP_GER_RULESET_DELETED, {
      'GER Ruleset ID': id,
    });
    onSuccess();
  };

  const onError = (err: any) => {
    setIsLoading(false);
    const errMsg = err?.response?.data?.meta.error_message ?? 'Unable to delete ruleset';

    toast({
      status: 'error',
      text: `Error: ${errMsg}`,
    });
    console.error(err);
  };

  return (
    <Modal
      key="delete-ger"
      body={
        <Text variant="formInput" color="secondary.700">
          Deleting a ruleset will remove all the event rules and their relevant configurations. This
          action cannot be undone. Are you sure you want to proceed?
        </Text>
      }
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={
        <FormButton title="Delete" onClick={onDelete} isLoading={isLoading} variant="danger" />
      }
      secondaryButton={
        <FormButton title="Cancel" onClick={onClose} disabled={isLoading} variant="secondary" />
      }
      title="Delete Ruleset"
    />
  );
};
