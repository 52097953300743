import { useDisclosure } from '@chakra-ui/hooks';
import { Box } from '@chakra-ui/react';
import { API } from 'core';
import { GlobalTeamIDGate } from 'core/hooks/useCompareGlobalAndEntityTeamId';
import { useUserAccess } from 'core/userAccess/UserAccessContext';
import { Breadcrumb } from 'library/atoms';
import { PageHeader } from 'library/molecules';
import { NotFoundInTeamAlert } from 'library/organisms';
import { LinkObject } from 'library/types';
import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GER_PATH } from 'views/main/routes/routes';

import { AppContext } from '..';
import { BoxLoader, GERError } from '../components';
import { GERDelete } from '../delete';
import { useGetGerDetail } from '../query/useGetGerDetail';
import { GER_ERROR, getGerEditPath } from '../util';
import { Rulesets } from './rulesets';
import { GERStats } from './stats';

export const GERDetails = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const context = useContext(AppContext);

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { data, isLoading, isError } = useGetGerDetail(id);
  const userAccess = useUserAccess();

  const canDelete = userAccess.hasDeleteAccess('ger', id);
  const canUpdate = userAccess.hasUpdateAccess('ger', id);

  if (isLoading) {
    return <BoxLoader />;
  }

  if (isError) {
    return <GERError />;
  }

  if (!data) {
    return <GERError error={GER_ERROR.NO_DATA} />;
  }

  const breadCrumbs: LinkObject[] = [
    { link: GER_PATH, text: 'Global Event Rulesets' },
    { link: window.location.pathname, text: data.name },
  ];

  const afterDelete = () => {
    onDeleteClose();
    history.push(GER_PATH);
  };

  const onEdit = () => {
    history.push(getGerEditPath(id));
  };

  return (
    <>
      <GlobalTeamIDGate entityTeamId={data.owner_id} />
      <Box px="6" paddingTop="3" width="100%" bg="container.background">
        <Breadcrumb data={breadCrumbs} />
      </Box>
      <PageHeader
        title={data.name}
        desc={data.description}
        button={'Edit Ruleset'}
        onButtonClick={onEdit}
        secondaryButton={'Delete Ruleset'}
        secondaryButtonVariant="secondary-danger-create"
        onSecondaryButtonClick={onDeleteOpen}
        disableTopPadding
        buttonDisabled={!canUpdate}
        secondaryButtonDisabled={!canDelete}
      >
        <GERStats gerData={data} organization={context?.organization} />
      </PageHeader>
      <Rulesets gerData={data} />
      <GERDelete isOpen={isDeleteOpen} onSuccess={afterDelete} onClose={onDeleteClose} id={id} />
    </>
  );
};
