import { keyframes } from 'styled-components';

export const slideDownOpen = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -5%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const slideDownClose = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, -5%, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
`;

export const slideUpOpen = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, -5%, 0);
  }
`;

export const slideUpClose = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -5%, 0);
  }
`;

export const pulse = keyframes`
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

export const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;
