export const DEFAULT_REACT_QUERY_CONFIG = {
  keepPreviousData: true,
  refetchOnWindowFocus: true,
  staleTime: 5000,
  cacheTime: 10 * 60 * 1000,
};

export const QUERY_CONFIG = {
  ...DEFAULT_REACT_QUERY_CONFIG,
  enabled: true,
  retry: false,
  refetchOnMount: true,
  refetchOnReconnect: true,
};
