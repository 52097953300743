import cx from 'classnames';
import { exception } from 'core/exception';
import { IAppState } from 'core/interfaces/IAppState';
import { IIncidentStatus } from 'core/interfaces/IIncidents';
import { IJiraCloud, IJIssueType, IJProject, IJStatusmap } from 'core/interfaces/IJira';
import { JiraCloudService, ServiceService } from 'core/services';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  ICreateConfigForAccountProps,
  IIssueDetail,
  IJiraAccDetail,
  ITeamAccDetail,
} from 'core/interfaces/IJiraNew';

import { CloseButton, Text } from '@chakra-ui/react';
import { Row, SpinLoader } from '@squadcast/alchemy-ui/carbon';
import { Grid, Heading, Para } from 'uie/components';

import { ServiceDetail, useServiceDetailQuery } from '../../../hooks/useServiceDetail';
import renders from './render.index';
import { IJIraCloudServiceNew } from '../../../hooks/useServiceList';
import { useQueryClient } from 'react-query';
import { queryKeys } from '../../../contants/service.contant';
import { JiraCloudServiceNew } from 'core/services/services.jiracloud';
import { ServiceJiraIntegration } from 'core/services/service.service';
import { AppTracker } from '../../../../../../../shared/analytics/tracker';
import { T_WA_UP_JIRA_CLOUD_PROJECT_MAPPED_TO_SERVICE } from '../../../../../../../core/const/tracker';
interface IProps extends Partial<Pick<IAppState, 'organization'>> {
  hide: () => void;
  serviceId: string;
  service: ServiceDetail | undefined;
  checkAndSetDirty: () => void;
  setIsDirty: (isSaved: boolean) => void;
  refetchAfterAction?: () => void;
}

interface IState {
  jira: IJIraCloudServiceNew | null;
  appState: 'get-configs' | 'get-projects' | 'update-configs' | 'no-token';
  loading: boolean;
  isDeleting: boolean;
  componentTabState: 'project' | 'map' | 'service';
  copied: boolean;
  apiToken: string;
  isCreated: boolean;
  searchString: string;
  showDropDown: boolean;
  errors: { [key: string]: string };
  jiraProjects: IJProject[];
  jiraAccounts: ITeamAccDetail[];
  selectedAccount: {
    id: string;
    name: string;
  };
  selectedProject: {
    id: string;
    name: string;
    key: string;
  };
  selectedIssue: IIssueDetail;
  projectIssues: IIssueDetail[];
  projectSearchString: string;
  issuesSearchString: string;
  accountSearchString: string;
  issuesMap: Pick<ICreateConfigForAccountProps, 'status_maps'>['status_maps'];
  isManual: boolean;
  updated: boolean;
}

export class JiraCloudExtensionModal extends Component<IProps, IState> {
  public incidentStatus = ['triggered', 'acknowledged', 'resolved'];
  private JCServiceNew: JiraCloudServiceNew = new JiraCloudServiceNew();
  private SServiceNew: ServiceJiraIntegration | undefined = this.props.service
    ? new ServiceJiraIntegration(this.props.serviceId)
    : undefined;

  constructor(props: IProps) {
    super(props);
    this.state = {
      jira: null,
      isCreated: false,
      appState: 'get-configs',
      loading: false,
      isDeleting: false,
      copied: false,
      apiToken: '',
      componentTabState: 'project',
      searchString: '',
      showDropDown: false,
      jiraProjects: [],
      jiraAccounts: [],
      selectedAccount: {
        id: '',
        name: '',
      },
      selectedProject: {
        id: '',
        name: '',
        key: '',
      },
      selectedIssue: {
        id: '',
        name: '',
        statuses: [],
      },
      projectIssues: [],
      errors: {},
      projectSearchString: '',
      issuesSearchString: '',
      accountSearchString: '',
      issuesMap: [] as Pick<ICreateConfigForAccountProps, 'status_maps'>['status_maps'],
      isManual: true,
      updated: false,
    };
  }

  public componentDidMount() {
    this.getJiraAccounts();
    this.getJiraConfigs(this.props.service?.extensions.jira_cloud);
  }

  public getJiraConfigs = async (
    jiraDetail: IJIraCloudServiceNew | null | undefined,
    toNext = false,
  ) => {
    this.setState({ appState: 'get-configs' });

    try {
      if (!jiraDetail) {
        this.setState({ appState: 'no-token' });
        return;
      }
      const jiraConfigs = jiraDetail;
      this.setState(
        {
          jira: {
            ...jiraConfigs,
          },
          apiToken: jiraConfigs.token,
          appState: 'get-projects',
        },
        () => {
          this.mapUpdates(toNext);
        },
      );
    } catch (err: any) {
      this.setState({ appState: 'no-token' });
      exception.handle('E_JIRA_CLOUD_GET_CONFIG', err);
    }
  };

  public mapUpdates = (toNext = false) => {
    const { jira } = this.state;
    if (!jira) return;
    const newState = {
      isCreated: true,
      isManual: jira.creation_mode == 'manual',
      apiToken: jira.token,
      issuesMap: jira.status_maps,
      selectedAccount: {
        id: jira.jira_account_id,
        name: jira.account_name,
      },
      selectedProject: {
        id: jira.project_id,
        key: jira.project_key,
        name: jira.project_name,
      },
      selectedIssue: {
        id: jira.issue_id,
        name: jira.issue_name,
        statuses: jira.status_maps.map(status => ({
          name: status.jira_status,
          id: status.jira_issue_status_id,
        })),
      },
    };

    const componentTabState = toNext ? 'project' : this.state.componentTabState;
    this.setState(
      (oldState: IState) => ({ ...oldState, ...newState, componentTabState }),
      () => {
        this.getJiraProjectNew();
        this.getJiraIssueNew();
      },
    );
  };

  public getJiraAccounts = async () => {
    try {
      const { data: JiraAccForTeam } = await this.JCServiceNew.GetJiraAccountsOfTeam();
      if (JiraAccForTeam.length > 0) {
        this.setState({ jiraAccounts: JiraAccForTeam, isCreated: true });
      }
    } catch (e) {
      exception.handle('E_JIRA_CLOUD_GET_ACCOUNT', e);
    }
  };
  public getJiraProjectNew = async () => {
    this.setState({ appState: 'get-projects' });

    try {
      const data = await this.JCServiceNew.getJiraProjects(this.state.selectedAccount.id);
      this.setState({ jiraProjects: data.data });
    } catch (err: any) {
      exception.handle('E_JIRA_CLOUD_GET_PROJECTS', err);
    }
  };
  public getJiraIssueNew = async () => {
    this.setState({ appState: 'get-projects' });

    try {
      const data = await this.JCServiceNew.getJiraProjectIssues(
        this.state.selectedAccount.id,
        this.state.selectedProject.id,
      );
      this.setState({
        projectIssues: data.data,
      });
    } catch (err: any) {
      exception.handle('E_JIRA_CLOUD_GET_PROJECT_ISSUES', err);
    }
  };

  public onAccountSelect = async (
    _: any,
    { account_id, account_name, key }: ITeamAccDetail & { key: string },
  ) => {
    this.setState(
      {
        selectedAccount: {
          id: account_id,
          name: account_name,
        },
        projectSearchString: '',
        issuesSearchString: '',
        projectIssues: [],
        selectedIssue: {
          id: '',
          name: '',
          statuses: [],
        },
      },
      () => {
        this.getJiraProjectNew();
        this.props.checkAndSetDirty();
      },
    );
  };
  public onProjectSelect = async (
    _: any,
    { id, key, name }: { id: string; key: string; name: string },
  ) => {
    this.setState(
      {
        selectedProject: {
          id,
          name,
          key,
        },
        projectSearchString: '',
        issuesSearchString: '',
        projectIssues: [],
        selectedIssue: {
          id: '',
          name: '',
          statuses: [],
        },
      },
      () => {
        this.getJiraIssueNew();
        this.props.checkAndSetDirty();
      },
    );
  };

  public onIssueSelect = (_: any, value: IIssueDetail) => {
    this.setState({
      selectedIssue: value,
      projectSearchString: '',
      issuesSearchString: '',
      issuesMap: value?.statuses!.map(status => ({
        jira_status: status.name,
        incident_status: 'Triggered',
        jira_issue_status_id: status.id,
      })),
    });
    this.props.checkAndSetDirty();
  };

  public onIssueMapSelect = (value: string, index: number) => {
    this.setState(({ issuesMap }) => {
      issuesMap[index].incident_status = value;
      return { issuesMap };
    });
    this.props.checkAndSetDirty();
  };

  public saveIntegration = async () => {
    this.setState({ loading: true });

    if (!this.SServiceNew) return;

    const { selectedIssue, selectedProject, issuesMap, isManual } = this.state;
    if (
      selectedIssue.id.length === 0 ||
      selectedProject.id.length === 0 ||
      issuesMap.length === 0
    ) {
      return;
    }

    try {
      const payloadNew = {
        project_id: selectedProject.id,
        project_key: selectedProject.key,
        project_name: selectedProject.name,
        jira_link: '',
        issue_id: selectedIssue.id,
        issue_name: selectedIssue.name,
        creation_mode: this.state.isManual ? 'manual' : 'automatic',
        status_maps: issuesMap,
        account_name: this.state.selectedAccount.name,
        account_id: this.state.selectedAccount.id,
      };

      if (this.props.service?.extensions?.jira_cloud) {
        if (this.props.service.extensions.jira_cloud?.id)
          await this.SServiceNew.updateJiraCloudForService({
            config_id: this.props.service.extensions.jira_cloud?.id.toString(),
            ...payloadNew,
          });
      } else {
        await this.SServiceNew.createJiraCloudForService({
          ...payloadNew,
        });
        AppTracker.track(T_WA_UP_JIRA_CLOUD_PROJECT_MAPPED_TO_SERVICE);
      }

      this.setState({ loading: false }, () => {
        this.props.hide();
        this.props.refetchAfterAction?.();
      });
    } catch (err: any) {
      const errMessage = err.response?.data?.meta?.error_message || 'Network Error';
      this.setState({ errors: { save: errMessage }, loading: false, updated: false });
      exception.handle('E_JIRA_CLOUD_SAVE_INTEGRATION', err);
    }
  };

  public onDeleteJira = async () => {
    if (!this.SServiceNew) return;
    try {
      this.setState({ isDeleting: true });
      if (this.props?.service?.extensions.jira_cloud?.id) {
        await this.SServiceNew.deleteJiraCloudForService(
          this.state.selectedAccount.id,
          this.props?.service?.extensions?.jira_cloud?.id.toString(),
        );
        this.props.hide();
        this.props.refetchAfterAction?.();
      }
    } catch (err: any) {
      const errMessage = err.response?.data?.meta?.error_message || 'Network Error';
      this.setState({ errors: { save: errMessage }, loading: false, updated: false });
      exception.handle('E_JIRA_CLOUD_SAVE_INTEGRATION', err);
    } finally {
      this.setState({ isDeleting: false });
    }
  };

  public render() {
    const { appState, componentTabState } = this.state;

    if (appState === 'get-configs') {
      return (
        <div
          style={{ width: '100%' }}
          onClick={event => {
            event.stopPropagation();
          }}
        >
          <div className="clearfix modal-header-container">
            <div
              className="float-left"
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h1 className="modal-container-heading">Jira Cloud Service Extension</h1>
              <CloseButton onClick={this.props.hide} />
            </div>
          </div>
          <div>
            <Row justifyContent="center" alignItems="center">
              <SpinLoader />
              <span className="ml-10">Loading</span>
            </Row>
          </div>
        </div>
      );
    }

    return (
      <div
        style={{ width: '100%' }}
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <Grid className="mb-20" justifyContent="space-between">
          <Grid alignItems="flex-start" type="column">
            <Heading height={35} fontSize={24}>
              Jira Cloud Service Extension
            </Heading>
            <Text fontSize={16}>{this.props.service?.name}</Text>
          </Grid>

          <CloseButton onClick={this.props.hide} />
        </Grid>
        <div>
          <Row justifyContent="flex-start" alignItems="center">
            {[
              {
                name: 'Select Project',
                key: 'project',
                on: 'token',
              },
              {
                name: 'Map Status',
                key: 'map',
                on: 'token',
              },
            ]
              .filter(tab => (tab.on ? appState !== 'no-token' : true))
              .map((tab, i, e) => {
                return (
                  <React.Fragment key={i}>
                    <button
                      className={cx('link', {
                        active: componentTabState === tab.key,
                      })}
                      style={{ marginRight: 5 }}
                      onClick={() => {
                        this.setState({ componentTabState: tab.key as any });
                      }}
                      tabIndex={0}
                    >
                      {tab.name}
                    </button>
                    {i !== 1 && e.length !== 1 && (
                      <span
                        style={{
                          borderTop: '1px solid var(--shades-grey)',
                          width: 60,
                        }}
                        className="ml-20 mr-20"
                      />
                    )}
                  </React.Fragment>
                );
              })}
          </Row>
          <div className="mt-20">{renders.call(this)}</div>
        </div>
      </div>
    );
  }
}

const JiraCloudExtension = connect((state: IAppState) => ({
  organization: state.organization,
}))(JiraCloudExtensionModal);

function JiraCloudExtensionModalHoc(props: Omit<IProps, 'refetchAfterAction'>) {
  const queryClient = useQueryClient();
  const queryKey = [queryKeys.SERVICEDETAIL, props.serviceId];

  const refetchAfterAction = () => {
    queryClient.invalidateQueries(queryKey);
  };

  return (
    <JiraCloudExtension
      refetchAfterAction={refetchAfterAction}
      {...{
        ...props,
      }}
    />
  );
}

export default JiraCloudExtension;
export { JiraCloudExtensionModalHoc };
