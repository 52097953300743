import * as React from 'react';
import { SelectBox, InputBox, TextButton } from '@squadcast/alchemy-ui/metal';
import { Row, Col, FocusBlock, CheckBox } from '@squadcast/alchemy-ui/carbon';
import { PostmortemTemplateService } from '../../../../../core/services';
import { IPostmortemTemplate } from '../../../../../core/interfaces/IPostmortems';
import { IOrganization } from '../../../../../core/interfaces/IOrganization';
import { AppTracker } from '../../../../../shared/analytics/tracker';
import {
  T_WA_GS_POSTMORTEM_TEMPLATE_UPDATED,
  T_WA_GS_POSTMORTEM_TEMPLATE_CREATED,
} from '../../../../../core/const/tracker';
import { JSONCodeBlock, MdBlock } from 'uie/components';
import FileUploadService, {
  FileUploadFeature,
  OperationType,
} from 'core/services/service.fileUpload';
import { fileUploadViewConfig } from 'views/config/fileUploadConfig';
import UpgradePlanModal from 'components/upgradeplan.modal';

interface IProps {
  hide: (reload: boolean) => void;
  postmortemTemplate: IPostmortemTemplate | null;
  basePostmortemTemplates: IPostmortemTemplate[];
  organization: IOrganization;
  checkAndSetDirty: () => void;
}

interface IState {
  selectedBaseTemplate: { name: string; template: string } | null;
  isDescriptionFocus: boolean;
  postmortemTemplate: IPostmortemTemplate;
  isAdding: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  errorMessage: string;
}

const { imageTexts, ...fileUploadConfig } = fileUploadViewConfig;

class PostmortemTemplateModal extends React.Component<IProps, IState> {
  private PTMTService = new PostmortemTemplateService(this.props.organization.organizationId);
  private fileUploadService = new FileUploadService();

  private templateVariables = {
    id: 'Incident ID',
    message: 'Incident Message',
    description: 'Incident Description',
    priority: 'Incident Priority',
    tags: [
      {
        key: 'incident_tag_key1',
        value: 'incident_tag_value1',
      },
      {
        key: 'incident_tag_key2',
        value: 'incident_tag_value2',
      },
      {
        key: 'incident_tag_key3',
        value: 'incident_tag_value3',
      },
    ],
    currentUser: 'Name of loggedIn user',
    serviceName: 'Name of the service corresponding to the incident',
    serviceType: 'Type of the service corresponding to the incident',
    timelines: [
      {
        timeline_event_title: 'Incident Triggered And Assigned To The Matrix',
        timeline_event_detail: '',
        timeline_event_action_type: 'triggered',
        timestamp: 'Fri Oct 25 2019 04:50 p.m.',
      },
      {
        timeline_event_title: 'Incident Acknowledged By Morpheus',
        timeline_event_detail: '',
        timeline_event_action_type: 'acknowledged',
        timestamp: 'Fri Oct 25 2019 04:52 p.m.',
      },
      {
        timeline_event_title: 'Neo created communication channel Meet',
        timeline_event_detail: 'Video Conference Link: app.squadcast.com',
        timeline_event_action_type: 'communication_card_created',
        timestamp: 'Fri Oct 25 2019 04:54 p.m.',
      },
      {
        timeline_event_title: 'Morpheus checked step 1 from runbook The Matrix',
        timeline_event_detail: 'Runbook step: Battle of Zion',
        timeline_event_action_type: 'runbook_step_checked',
        timestamp: 'Fri Oct 25 2019 04:55 p.m.',
      },
      {
        timeline_event_title: 'Incident Resolved By Neo',
        timeline_event_detail: '',
        timeline_event_action_type: 'resolved',
        timestamp: 'Fri Oct 25 2019 04:55 p.m.',
      },
    ],
    triggerTime: 'Fri Oct 25 2019 16:52:17 GMT+0530 (India Standard Time)',
    resolveTime: 'Fri Oct 25 2019 16:55:17 GMT+0530 (India Standard Time)',
    duration: '3 second(s)',
    now: 'Returns the current time',
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedBaseTemplate: null,
      isDescriptionFocus: false,
      postmortemTemplate:
        props.postmortemTemplate !== null
          ? props.postmortemTemplate
          : {
              name: '',
              template: '',
              is_default: false,
              attachments: [],
            },
      isAdding: false,
      isUpdating: false,
      isDeleting: false,
      errorMessage: '',
    };
  }

  public addTemplate = async () => {
    AppTracker.track(T_WA_GS_POSTMORTEM_TEMPLATE_CREATED);
    try {
      const newTemplate = this.state.postmortemTemplate;
      this.setState({ isAdding: true });
      newTemplate.attachments =
        this.fileUploadService.accessAttachmentArrayForPostmortemTemplates();
      await this.PTMTService.create(newTemplate);
      this.fileUploadService.emptyAttachmentArrayForPostmortemTemplates();

      this.props.hide(true);
    } catch (err: any) {
      this.setState({
        isAdding: false,
        errorMessage: err?.response?.data?.meta?.error_message ?? 'Network Error',
      });
    }
  };

  public updateTemplate = async () => {
    AppTracker.track(T_WA_GS_POSTMORTEM_TEMPLATE_UPDATED);
    try {
      const updatedTemplate = this.state.postmortemTemplate;
      this.setState({ isUpdating: true });
      updatedTemplate.attachments =
        this.fileUploadService.accessAttachmentArrayForPostmortemTemplates();
      await this.PTMTService.update(updatedTemplate);
      this.fileUploadService.emptyAttachmentArrayForPostmortemTemplates();
      this.props.hide(true);
    } catch (err: any) {
      this.setState({
        isUpdating: false,
        errorMessage: err?.response?.data?.meta?.error_message ?? 'Network Error',
      });
    }
  };

  public updatePostMortemTemplates = (value: string) => {
    this.setState(({ postmortemTemplate }) => {
      postmortemTemplate.template = value;
      return { postmortemTemplate };
    });
    this.props.checkAndSetDirty();
  };

  public render() {
    const { basePostmortemTemplates } = this.props;
    const isUpdate = !!this.props.postmortemTemplate;
    return (
      <div
        onClick={event => {
          event.stopPropagation();
        }}
        style={{ width: '100%' }}
      >
        <div className="">
          <div className="float-left">
            <h1 className="modal-container-heading">{isUpdate ? 'Update ' : 'Add '} Template</h1>
          </div>
        </div>

        <div className="mt-20">
          <Row flexWidth={12} justifyContent="flex-start" alignItems="stretch">
            <Row flexWidth={7}>
              <Col>
                {isUpdate ? (
                  ''
                ) : (
                  <div className="mb-10">
                    <SelectBox
                      id="base_postmortem_template"
                      name="base_postmortem_template"
                      label="Select a base postmortem template"
                      onChange={(_, value) => {
                        this.setState((prevState: IState, props: IProps) => {
                          prevState.selectedBaseTemplate = value;
                          prevState.postmortemTemplate.template = value.template;
                          return prevState;
                        });
                      }}
                      selected={
                        this.state.selectedBaseTemplate === null ? (
                          <p style={{ margin: 0 }}>Please select a base postmortem template</p>
                        ) : (
                          <p style={{ margin: 0 }}>{this.state.selectedBaseTemplate.name}</p>
                        )
                      }
                    >
                      {basePostmortemTemplates.map(
                        (baseTemplate: IPostmortemTemplate, index: number) => (
                          <FocusBlock value={baseTemplate} key={index}>
                            <p className="item-box-tagline" style={{ margin: 0 }}>
                              <span>{baseTemplate.name}</span>
                            </p>
                          </FocusBlock>
                        ),
                      )}
                    </SelectBox>
                  </div>
                )}

                <div className="mb-10">
                  <InputBox
                    id="postmortem-template-name"
                    name="name"
                    label="Name"
                    type="text"
                    placeHolder="Postmortem Template Name"
                    value={this.state.postmortemTemplate.name}
                    onChange={(_, value) => {
                      this.setState(({ postmortemTemplate }) => {
                        postmortemTemplate.name = value;
                        return { postmortemTemplate };
                      });
                      this.props.checkAndSetDirty();
                    }}
                  />
                </div>

                <div className="mb-10">
                  <Row flexWidth={12}>
                    <div style={{ width: 'calc(100%)' }}>
                      <p
                        className="item-box-tagline font-bold"
                        style={{ margin: 0, marginBottom: 10 }}
                      >
                        Template
                      </p>
                      <MdBlock
                        onChange={this.updatePostMortemTemplates}
                        value={this.state.postmortemTemplate.template}
                        options={{
                          spellChecker: true,
                          placeholder: '(Supports markdown)',
                          imageUploadFunction: this.fileUploadService.getUploadFunctionForFeature(
                            FileUploadFeature.POSTMORTEM_TEMPLATE,
                            isUpdate ? OperationType.UPDATE : OperationType.CREATE,
                            isUpdate ? this.state.postmortemTemplate.id : '',
                          ),
                          imageTexts: {
                            ...imageTexts,
                            sbInit: '',
                          },
                          ...fileUploadConfig,
                          renderImageUploadsAsLinks: false,
                        }}
                        maxHeight="400px"
                      />
                    </div>
                  </Row>
                </div>

                <div className="mb-10">
                  <CheckBox
                    id="postmortem-is-default"
                    name="is_default"
                    label="Set as Default"
                    checked={this.state.postmortemTemplate.is_default}
                    onChange={() => {
                      this.setState(({ postmortemTemplate }) => {
                        postmortemTemplate.is_default = !postmortemTemplate.is_default;
                        return { postmortemTemplate };
                      });
                      this.props.checkAndSetDirty();
                    }}
                  >
                    Set as Default
                  </CheckBox>
                </div>

                <div className="mb-10">
                  <span style={{ color: '', fontWeight: 600 }}>{this.state.errorMessage}</span>
                </div>

                <div className="mt-20">
                  {isUpdate ? (
                    <TextButton
                      id="update-postmortem-template"
                      text="Update"
                      loading={this.state.isUpdating}
                      onClick={this.updateTemplate}
                    />
                  ) : (
                    <TextButton
                      id="add-postmortem-template"
                      text="Add"
                      loading={this.state.isAdding}
                      onClick={this.addTemplate}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row flexWidth={5}>
              <Col>
                <div className="w-1-1 ml-20" style={{ position: 'sticky', top: 20 }}>
                  <JSONCodeBlock
                    code={this.templateVariables}
                    shellProps={{
                      minHeight: '600px',
                      minWidth: 'calc(100% - 40px)',
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    );
  }
}

export default PostmortemTemplateModal;
