import axios from 'axios';
import { API } from 'core';
import { isArray } from 'lodash';
import { useQueryClient } from 'react-query';
import { QUERY_CONFIG } from '../../incident-list/common/constants';
import { useListServicesQuery } from '../../incident-list/graphql/query';

export function useServices() {
  const fetchServices = async (maintainerIDs: string[], teamID?: any) => {
    const query = `
    query listServices($teamID: String!, $maintainerIDs: [String!]) {
      listServices(teamID: $teamID, maintainerIDs: $maintainerIDs) {
        serviceID
        name
        apiKey
        epID
      }
    }
  `;

    const variables = { teamID, maintainerIDs };

    try {
      const response = await axios.post(
        `${API.config.batman}/organizations/${API.config.organizationId}/graphql`,
        {
          query,
          variables,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${API.bearer}`,
          },
        },
      );

      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      return response.data.data.listServices;
    } catch (error) {
      console.error('Error fetching services:', error);
      throw error;
    }
  };
  return {
    fetchServices,
  };
}

export const useGetServiceListForMaintainer = (
  maintainerIDs: string[],
  autoFetch: boolean,
  teamData?: any,
) => {
  const config = { ...QUERY_CONFIG, staleTime: 1000 };

  const selectedTeamId = teamData;
  const { refetch, data } = useListServicesQuery(
    { teamID: selectedTeamId, maintainerIDs: maintainerIDs },
    { ...config, enabled: autoFetch },
  );
  const servicesData = data && isArray(data.listServices) ? data.listServices : [];
  const serviceList = servicesData.map(service => {
    return { label: service.name, value: service.serviceID };
  });
  return { serviceList, refetch };
};
