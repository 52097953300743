import { SchedulesHelpGuide } from '../interface/schedule';

export const schedulesHelpGuide: SchedulesHelpGuide = [
  {
    title: 'What’s a Schedule?',
    desc: [
      'An On-call Schedule determines who will be notified when an incident is triggered. When an incident impacts a Service, notifications are sent in the Service’s associated Escalation Policy. ',
    ],
    note: 'Add the Schedule to an Escalation Policy to activate it.',
    link: [
      {
        url: 'https://support.squadcast.com/schedules/schedules-new/adding-a-schedule',
        label: 'Read More',
      },
      {
        url: 'https://support.squadcast.com/schedules/schedules-new/videos-how-to-set-up-common-use-cases',
        label: 'Watch how to set up common use cases',
      },
    ],
    type: 'help',
  },
  {
    title: 'How to pick a Time Zone?',
    desc: [
      'Each schedule can be configured for a specific timezone. Helps in the case of a distributed team to ensure you get the shift timings right. Add a tag for the timezone to any schedule to denote which  timezone is affected by the schedule, such as L1 APAC On-call.',
    ],
    link: [
      {
        url: 'https://support.squadcast.com/schedules/schedules-new/adding-a-schedule#how-to-pick-a-time-zone',
        label: 'Read More',
      },
      {
        url: 'https://www.youtube.com/watch?v=Xmy5SGrsW0w',
        label: 'Watch how to adjust Day Light Savings',
      },
    ],
    type: 'help',
  },
  {
    title: 'How to pick an Owner?',
    desc: [
      'A schedule owner is someone who can be contacted to understand, modify or delete the Schedule.',
    ],
    note: 'By default, the team is set as the owner. You may change it using the drop-down.',
    link: [
      {
        url: 'https://support.squadcast.com/schedules/schedules-new/adding-a-schedule#how-to-pick-an-owner',
        label: 'Read More',
      },
    ],
    type: 'help',
  },
  {
    title: 'How to use Tags?',
    desc: [
      'Use tags to organize, classify and add context to your schedules. Adding tags such as Type: 24x7 Business, Environment: Prod, and Team: 24x7 L1 can help structure and add more context to your schedules.',
    ],
    link: [
      {
        url: 'https://support.squadcast.com/schedules/schedules-new/adding-a-schedule#how-to-use-tags',
        label: 'Read More',
      },
    ],
    type: 'help',
  },
  {
    title: 'How to use Rotations?',
    desc: [
      'You can pick one or more rotations that closely match your need. Choose from the predefined templates or build custom rotation/events.',
    ],
    note: 'You can also add squads to a rotation.',
    link: [
      {
        url: 'https://support.squadcast.com/schedules/schedules-new/adding-a-schedule#3.-customize-rotations',
        label: 'Read More',
      },
    ],
    type: 'help',
  },
  {
    title: 'Tips:',
    desc: [
      'Add the schedule to an escalation policy to activate it.',
      'You can assign a user, squad, or team as a schedule owner.',
      'Give your schedules meaningful names that reflect the pattern it follows (24x7, Business, Non-Business, Weekend), the team it is defined for (24x7 L1), and the timezone it affects (L1 APAC on-call).',
      'Keep your rotations as simple as possible, preferably with a continuous rotation of the same users to make your on-call schedule easy to manage.',
      'You can leverage scheduled overrides to address holidays or schedule conflicts.',
      'Ensure that there are no gaps in your Schedule.',
    ],
    link: [],
    type: 'tips',
  },
  {
    title: 'If you need help with something, use the chat widget below to reach us.',
    desc: [''],
    link: [],
    type: 'queries',
  },
];
