import Axios from 'axios';
import { API } from 'core';
import { SHIFT_TO } from '../util';

export const reorderRule = (
  gerId: string,
  alertVersion: string,
  alertName: string,
  ruleId: string,
  shiftTo: SHIFT_TO,
) => {
  const body = {
    shift_index_by: shiftTo,
  };
  return Axios.patch(
    `${API.config.batman}/organizations/${API.config.organizationId}/global-event-rules/${gerId}/rulesets/${alertVersion}/${alertName}/rules/${ruleId}/priority`,
    body,
  );
};
