import React, { Component, ChangeEvent } from 'react';
import { IComponentErrorState } from '../../../core/interfaces/IComponentState';
import cx from 'classnames';
import '../common.css';
import { Link, Redirect } from 'react-router-dom';
import { isEmailValid } from '../../../core/helpers';
import { AuthService } from '../../../core/services';
import { CommonAuth } from '../commonAuth';
import { Grid, Heading } from 'uie/components';

interface IProps {}

interface IState {
  errors: IComponentErrorState;
  email: string;
  sent: boolean;
  time: string;
}

class ForgotPassword extends Component<IProps, IState> {
  _authService = new AuthService();

  constructor(props: IProps) {
    super(props);

    this.state = {
      email: '',
      errors: {},
      sent: false,
      time: '',
    };
  }

  onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value, errors: {} });
  };

  onVerify = () => {
    const errors: any = {};
    if (emailVerification(this.state.email).length > 0) {
      errors.email = 'Please enter a valid Email-id';
    }

    this.setState({ errors });
    return Object.entries(errors).length > 0;
  };

  resetPassword = async () => {
    if (this.onVerify()) {
      return;
    }

    try {
      await this._authService.requestResetPassword(this.state.email);
      const time = new Date().getTime().toString();
      CommonAuth.db = {
        email: this.state.email,
        id: time,
        redirectFrom: 'forgot-password',
      };
      this.setState({
        time,
        sent: true,
      });
    } catch (err: any) {
      this.setState({
        errors: {
          email:
            err?.response?.data?.meta?.error_message === 'user not found'
              ? 'This email is not registered'
              : err?.response?.data?.meta?.error_message === 'Too many password reset attempts'
              ? 'Too many password reset attempts. Please try again after 5 minutes'
              : 'Looks like a network error. Please Try again',
        },
      });
    }
  };

  render() {
    const { email, errors, sent, time } = this.state;

    if (sent) {
      return <Redirect to={`/reset/${time}`} exact={true} />;
    }

    return (
      <div className="login-container">
        <div className="login-interact">
          <div
            style={{
              fontSize: '14px',
              lineHeight: '16px',
              textAlign: 'center',
              marginBottom: '120px',
            }}
          >
            <img
              className="banner-logo"
              src="/assets/new_logo.svg"
              alt="Squadcast-logo"
              style={{ width: '180px', marginBottom: '4px' }}
            />
            <div style={{ marginBottom: '24px' }}>Incident Response for SRE, Devops & IT teams</div>
          </div>
          <div style={{ marginBottom: 24, textAlign: 'center' }}>
            <div>
              <img
                src="/assets/forgot-password.svg"
                alt="Forgot Password"
                style={{ width: '42px', marginBottom: '20px' }}
              />
            </div>
            <Heading fontSize={24} height={24} style={{ lineHeight: '34px' }}>
              Forgot Password?
            </Heading>
            <div style={{ fontSize: '18px', fontWeight: 400, marginTop: '4px' }}>
              We'll send you a link to reset your password via email
            </div>
          </div>

          <div className="login-input-container">
            <label className="login-label" htmlFor="login_email">
              Work Email
            </label>
            <input
              id="login_email"
              className={cx('login-input', { error: errors.email && errors.email.length > 0 })}
              value={email}
              placeholder="example@company.com"
              onChange={this.onValueChange}
              type="email"
            />
            <p className="login-error-message">{errors.email || ''}</p>
          </div>

          <button
            className="login-get-started"
            disabled={email === ''}
            onClick={this.resetPassword}
          >
            Reset Password
          </button>

          <Grid justifyContent="center" className="login-t-c">
            <span style={{ opacity: 1, fontStyle: 'normal' }}>
              <Link className="login-signin-link btn" to="/" style={{ textDecoration: 'none' }}>
                Back to Login Page
              </Link>
            </span>
          </Grid>
        </div>
      </div>
    );
  }
}

function emailVerification(email: string): string {
  if (isEmailValid(email)) {
    return '';
  }

  return "Doesn't Look right. Try Again ?";
}

export default ForgotPassword;
