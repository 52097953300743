import styled from 'styled-components';
import { slideDownOpen, slideUpClose } from '../../utils/Animations';

export interface IDrop {
  height?: string | '300px';
  width?: string | '200px';
  maxHeight?: string | '300px';
  maxWidth?: string | '200px';
  top?: string | '10px';
  padding?: string | '0';
  extend?: number | 0;
}

export const Drop = styled.div<IDrop>`
  height: calc(
    ${props => props.height || '300px'} + ${props => (props.extend ? `${props.extend} + 16px` : '')}
  );
  max-height: calc(
    ${props => props.height || '300px'} + ${props => (props.extend ? `${props.extend} + 16px` : '')}
  );
  width: ${props => props.width || '200px'};
  max-width: ${props => props.width || '200px'};
  margin-top: ${props => props.top || '10px'};
  background-color: ${props => props.theme.shades.white};
  display: none;
  border-radius: 3px;
  box-shadow: ${props => props.theme.shadow.default};
  position: absolute;
  padding: ${props => props.padding || '0'};
  z-index: 999;
  animation-duration: 0.17s;
  animation-fill-mode: both;
  animation-name: ${slideDownOpen};
  animation-timing-function: ease;

  &.close {
    animation-name: ${slideUpClose};
  }

  & > .renders {
    height: ${props => props.height || '300px'};
    max-height: ${props => props.maxHeight || '300px'};
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 3px;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      min-height: 64px;
      background: ${props => props.theme.shades.smoke};
      border-radius: 5px;
      border: 3px solid ${props => props.theme.shades.white};
    }
  }

  &:focus {
    outline: none;
  }
`;

export interface IShell {
  isOpen?: boolean;
  isFocused?: boolean;
  width?: string | '200px';
  error?: boolean;
  padding?: string | '16px';
  height?: string | '50px';
}

const calcShadows = (props: any) =>
  props.error
    ? `0 0 0 1px ${props.theme.danger.default}`
    : `0 0 0 1px ${props.theme.primary.default}`;

export const Shell = styled.div<IShell>`
  width: 100%;
  height: fit-content;
  border: none;
  display: block;
  position: relative;

  & > .hook {
    width: ${props => props.width || '200px'};
    max-width ${props => props.width || '200px'};
    height: ${props => props.height || '50px'};
    box-shadow: 0 0 0 1px ${props =>
      props.error ? props.theme.danger.default : props.theme.shades.lightGrey};
    background-color: ${props => props.theme.shades.white};
    padding: ${props => props.padding || '16px'};
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    transition: 0.17s greyscale, 0.17s box-shadow, 0.17s filter, 0.17s background ease;

    &:focus, &:hover {
      background-color: ${props => props.theme.shades.white} !important;
      box-shadow: ${calcShadows};
    }

    &:disabled, &.disabled{
      cursor: no-drop !important;
      filter: opacity(0.66);
      color: ${props => props.theme.font.disabled};
      box-shadow: 0 0 0 1px ${props => props.theme.shades.smoke};
    }
  }

  &:focus-within > ${Drop} {
    display: block;
  }

  &:focus {
    outline: none;
  }
`;
