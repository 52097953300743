import { IPricingEntity } from 'core/interfaces/IBillingPlan';
import React from 'react';

import { IRole } from '../../../../core/interfaces/IRole';
import { IUserAbilitySlugMap } from '../../../../core/interfaces/IUserData';
import OrganizationAPITokenTab from './api-token';
import OrganizationBillingTab from './billing';
import OrganizationAuditLogsTab from './audit-logs';
import OrganizationExtensionTab from './integrations';
import JiraCloudSettings from './integrations/jira-cloud';
import OrganizationSettingsTab from './organization';
import OrganizationPermissionsTab from './persmissions';
import OrganizationPostmortemTab from './postmortems';
import OrganizationUserTab from './users';
import { CreateWebhook } from './webhooks-v2/create/create-webhook';
import WebhookV2 from './webhooks-v2/webhookV2';

interface ITab {
  accessor:
    | 'org'
    | 'token'
    | 'postmortem'
    | 'billings'
    | 'extension'
    | 'user'
    | 'permissions'
    | 'webhooks'
    | 'teams'
    | 'extensions/jira-cloud'
    | 'webhook/create'
    | 'audit-logs'
    | 'webhooks/:id';
  name:
    | 'Organization'
    | 'API Tokens'
    | 'Permissions'
    | 'Billing'
    | 'Extensions'
    | 'Users'
    | 'Postmortem Templates'
    | 'Webhooks'
    | 'Teams'
    | 'Jira Cloud Extension'
    | 'Create Webhook'
    | 'Audit Logs'
    | 'Edit Webhook';
  requires: keyof IUserAbilitySlugMap | 'none';
  role: Exclude<IRole['slug'], 'stakeholder'> | 'inherit'; // inherits the requires field
  component: React.ComponentType<any> | null;
  requiresActiveOrg: boolean;
  requiresPro?: boolean;
  pricingEntity?: IPricingEntity;
}

export const SettingTab: ITab[] = [
  {
    accessor: 'org',
    name: 'Organization',
    requires: 'none',
    role: 'account_owner',
    component: OrganizationSettingsTab,
    requiresActiveOrg: false,
  },
  {
    accessor: 'user',
    name: 'Users',
    requires: 'manage-users',
    role: 'inherit',
    component: OrganizationUserTab,
    requiresActiveOrg: false,
  },
  {
    accessor: 'permissions',
    name: 'Permissions',
    requires: 'manage-users',
    role: 'inherit',
    component: OrganizationPermissionsTab,
    requiresActiveOrg: false,
  },
  {
    accessor: 'teams',
    name: 'Teams',
    requires: 'manage-teams',
    role: 'inherit',
    component: null,
    requiresActiveOrg: true,
  },
  {
    accessor: 'token',
    name: 'API Tokens',
    requires: 'manage-api-tokens',
    role: 'inherit',
    component: OrganizationAPITokenTab,
    requiresActiveOrg: true,
  },
  {
    accessor: 'webhooks',
    name: 'Webhooks',
    requires: 'manage-webhooks',
    role: 'inherit',
    component: WebhookV2,
    requiresPro: true,
    requiresActiveOrg: true,
    pricingEntity: 'event-hooks',
  },
  {
    accessor: 'extension',
    name: 'Extensions',
    requires: 'manage-extensions',
    role: 'inherit',
    component: OrganizationExtensionTab,
    requiresActiveOrg: true,
  },
  {
    accessor: 'postmortem',
    name: 'Postmortem Templates',
    requires: 'manage-postmortem-templates',
    role: 'inherit',
    component: OrganizationPostmortemTab,
    requiresActiveOrg: true,
    requiresPro: false,
    pricingEntity: 'postmortem-templates',
  },
  {
    accessor: 'billings',
    name: 'Billing',
    requires: 'manage-billing',
    role: 'inherit',
    component: OrganizationBillingTab,
    requiresActiveOrg: false,
  },
  {
    accessor: 'audit-logs',
    name: 'Audit Logs',
    requires: 'manage-audit-logs',
    role: 'inherit',
    component: OrganizationAuditLogsTab,
    requiresActiveOrg: true,
    pricingEntity: 'audit-logs',
  },
];

export const SettingsInternalTabs: ITab[] = [
  {
    accessor: 'extensions/jira-cloud',
    name: 'Jira Cloud Extension',
    requires: 'manage-extensions',
    role: 'inherit',
    component: JiraCloudSettings,
    requiresActiveOrg: false,
  },
  {
    accessor: 'webhook/create',
    name: 'Create Webhook',
    requires: 'manage-webhooks',
    role: 'inherit',
    component: CreateWebhook,
    requiresPro: true,
    requiresActiveOrg: true,
    pricingEntity: 'event-hooks',
  },
  {
    accessor: 'webhooks/:id',
    name: 'Edit Webhook',
    requires: 'manage-webhooks',
    role: 'inherit',
    component: CreateWebhook,
    requiresPro: true,
    requiresActiveOrg: true,
    pricingEntity: 'event-hooks',
  },
];
