import { Flex } from '@chakra-ui/layout';
import { Priority, PRIORITY_OPTIONS } from 'library/common';
import React from 'react';

const PriorityOptions = () => {
  return PRIORITY_OPTIONS.map(option => {
    const priorityElement = React.createElement(Priority, { value: option.value });
    return {
      id: option.value,
      name: (
        <Flex gap={2}>
          {priorityElement} {option.label}
        </Flex>
      ),
    };
  });
};

export default PriorityOptions;
