export class Failure extends Error {
  isFailure = true;
  statusCode = 400;
  description = '';

  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, Failure.prototype);
  }

  getErrorMessage() {
    return '[Failure] ' + this.message;
  }

  static unknown(statusCode: number, message?: string) {
    const failure = new Failure(message || 'Unknown failure');
    failure.statusCode = statusCode;
    return failure;
  }

  static notFound(statusCode: number, message: string) {
    const failure = new Failure(message);
    failure.statusCode = statusCode;
    return failure;
  }

  static globalNotFound(statusCode: number) {
    const failure = new Failure('Resource does not exist.');
    failure.statusCode = statusCode;
    return failure;
  }

  static isFailure(err: any): err is Failure {
    return err.isFailure === true;
  }
}
