import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { Box, Button, FormControl, FormErrorMessage, HStack, VStack, Text } from '@chakra-ui/react';

import { useScheduleActionsContext } from '../../schedules.actions/context';
import { errorMessages } from '../../constants/errormessage';
import { schedulesTextCopy } from '../../constants/schedules.copy';
import { INewSchedule } from '../../interface/schedule';
import { rotationStyles } from '../../schedules.add/schedules.customizerotations/rotation.styles';
import RotationPattern from '../../schedules.add/schedules.customizerotations/rotationPattern';
import { useGetMockEventsQuery } from 'views/main/organization/schedules/graphql/query';
import { NewSchedule, TimeDurationUnit } from 'views/main/organization/schedules/graphql/types';
import { defaultReactQueryConfig, formatScheduleRequest } from '../../helpers/helpers.schedule';
import { useScheduleHeaderContext } from '../../schedules.view/schedules.header/context';

const EditRotations = () => {
  const formik = useFormikContext<INewSchedule>();
  const { closeEditSchedule } = useScheduleActionsContext();
  const {
    visibleDates: { twoWeeksView: dateRange },
  } = useScheduleHeaderContext();

  const {
    values: { rotations: existingRotations },
    errors,
    isSubmitting,
    handleChange,
  } = formik;

  const { data, isLoading: isMockEventsLoading } = useGetMockEventsQuery(
    {
      schedule: formatScheduleRequest(formik.values, true) as NewSchedule,
      from: dateRange[0].toJSDate(),
      till: dateRange.slice(-1)[0].toJSDate(),
      In: TimeDurationUnit.Hours,
    },
    defaultReactQueryConfig,
  );

  useEffect(() => {
    if (data) {
      existingRotations.forEach((rt, index) => {
        const mockEvent = data?.mockEvents?.mockEvents?.find(
          mockEvent => mockEvent.rotationIndex === index,
        );
        if (mockEvent) {
          handleChange({
            target: {
              name: `rotations.${index}.onCallHoursPerParticipant`,
              value: mockEvent?.onCallDurationPerParticipant ?? 0,
            },
          });
        }
      });
    }
  }, [data]);

  const onRemoveRotation = (id: string) => {
    const rotationToRemove = existingRotations.find(rt => rt.id === id);
    if (rotationToRemove) {
      const filteredRotations = existingRotations.filter(rt => rt.id !== id);
      formik.setFieldValue('rotations', filteredRotations, true);
    }
  };

  const areRotationNamesDuplicate = errors.rotations === errorMessages.rotations.duplicateNames;
  const isValidEndDate = errors.rotations === errorMessages.rotations.startEndDate;

  return (
    <>
      <Box bg="white" sx={rotationStyles()}>
        <VStack w="100%" mt={1} spacing={4} h="calc(100vh - 160px)" overflowY="auto">
          {existingRotations?.map((rotation, index) => (
            <RotationPattern
              key={rotation.id}
              rotationId={rotation.id}
              onRemoveRotation={() => onRemoveRotation(rotation.id)}
              rotationBackgroundColor={rotation.color}
            />
          ))}
        </VStack>
        <VStack
          alignItems="flex-start"
          marginTop={4}
          marginBottom={4}
          marginLeft={4}
          bottom={0}
          position="absolute"
        >
          <FormControl isInvalid={!!errors.rotations}>
            <FormErrorMessage>
              {errors.rotations
                ? areRotationNamesDuplicate
                  ? errorMessages.rotations.duplicateNames
                  : isValidEndDate
                  ? errorMessages.rotations.startEndDate
                  : errorMessages.rotations.submit
                : errorMessages.rotations.noRotations}
            </FormErrorMessage>
            <HStack mt={2}>
              <Button
                variant="default"
                type="submit"
                onClick={() => formik.handleSubmit()}
                isDisabled={isMockEventsLoading}
                isLoading={isSubmitting}
              >
                {schedulesTextCopy.update.buttons.save}
              </Button>

              <Button variant="invertedDefault" onClick={closeEditSchedule}>
                {schedulesTextCopy.common.cancel}
              </Button>
            </HStack>
          </FormControl>
        </VStack>
      </Box>
    </>
  );
};

export default EditRotations;
