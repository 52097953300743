import { Box, Tooltip, useClipboard } from '@chakra-ui/react';
import { BetaIcon, Copy2Icon, GenAiIcon } from 'icons';
import { THEME_COLORS } from 'library/theme/colors';
import { Grid, IconButton, Para } from 'uie/components';

import { useIncidentSummaryContext } from '../../../context/hook';

const IncidentSummaryDrawerSuccessTitle: React.FC = () => {
  const { lastUpdatedTimestampString, markdownSummaryForCopy } = useIncidentSummaryContext();
  const summaryMarkdown = markdownSummaryForCopy();
  const { onCopy, hasCopied } = useClipboard(summaryMarkdown);

  return (
    <Grid alignItems="center" justifyContent="center">
      <Box>
        <Grid alignItems="center">
          <GenAiIcon height={46} width={46} className="mr-5" />
          <Para fontSize={27} fontWeight={400}>
            Incident Summary
          </Para>
          <BetaIcon
            height={18}
            width={33}
            className="newFlag"
            style={{ position: 'relative', top: 3, left: 5 }}
          />
        </Grid>
        <Para
          fontSize={12.5}
          fontWeight={400}
          fontFamily='"Mulish", sans-serif'
          color={THEME_COLORS.secondary[700]}
          style={{ position: 'relative', left: 7 }}
        >
          Last update : {lastUpdatedTimestampString()}
        </Para>
      </Box>
      <Box
        style={{
          position: 'absolute',
          right: 44,
          top: '0.5rem',
        }}
      >
        <Tooltip
          label={hasCopied ? 'Copied' : 'Copy Incident Summary'}
          placement="bottom-end"
          offset={[0, 18]}
          hasArrow
        >
          <IconButton
            aria-label="copy"
            onClick={e => {
              onCopy();
              e.target.blur();
            }}
            style={{ padding: '2.5px' }}
          >
            <Copy2Icon style={{ width: 18, height: 18 }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Grid>
  );
};

export default IncidentSummaryDrawerSuccessTitle;
