import React from 'react';
import { CopyIcon, InfoIcon, CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Link,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { shallowEqual, useSelector } from 'react-redux';
import { IAppState } from 'core/interfaces/IAppState';
import Select, { StylesConfig, GroupBase } from 'react-select';
import { ParticipantOptionLabel, SingleValue } from '../../../components/Avatar';

import { timezones } from '../../../constants/statuspage.copy';
import { getAllPatternParticipants, getSquadcastHostPrefix } from '../../../helpers/helper.details';
import { EnterIcon } from '../../../icons';
import { IPatternParticipant, IVerifyStatusPageCustomDomain } from '../../../Interface';
import { NewStatusPageOwnerType } from '../../../graphql/types';
import { useCheckDuplicateDomainStatus } from '../../../hooks/useCheckDuplicateStatus';
import { debounce } from 'core';
import { THEME_COLORS } from 'library/theme/colors';

export interface Props {
  isEdit?: boolean;
  showConfigDetails?: boolean;
  onVerifyDomain?: () => void;
  domainStatus?: IVerifyStatusPageCustomDomain;
  isDomainVerifying?: boolean;
}

const styles: StylesConfig<IPatternParticipant, false, GroupBase<IPatternParticipant>> = {
  input: (provided, { selectProps: { inputValue, isMulti } }) => ({
    ...provided,
    ...(!(inputValue || isMulti) ? { position: 'absolute' } : {}),
  }),
};

const PageConfigForm = (props: Props) => {
  const [showConfig, setShowConfig] = useState(props.showConfigDetails || false);
  const currentuser = useSelector(
    (state: IAppState) => state.organization.currentUser.u,
    shallowEqual,
  );
  const { isDomainVerifying, domainStatus, onVerifyDomain } = props;
  const { touched, errors, values, setFieldValue }: any = useFormikContext();

  const [localValue, setLocalValue] = useState(values.squadcastDomainName);
  const duplicateDomainStatus = useCheckDuplicateDomainStatus(values.squadcastDomainName ?? '');
  const { data } = !props.isEdit ? duplicateDomainStatus : { data: undefined };

  const debouncedSetFieldValue = useCallback(
    debounce(value => setFieldValue('squadcastDomainName', value), 1000),
    [setFieldValue],
  );
  const handleDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setLocalValue(value); // Update the local state immediately
    debouncedSetFieldValue(value); // Debounce the Formik state update
  };

  const handleChange = (val: any) => {
    setFieldValue('timezone', val);
  };

  const { isEdit } = props;
  const urlPrefix = getSquadcastHostPrefix();
  const ownerOptions = getAllPatternParticipants();
  let ownerValue = ownerOptions.find(
    option => option.id === values?.owner?.id && option.type === values?.owner?.type,
  );
  if (!ownerValue) {
    if (currentuser) {
      ownerValue = {
        id: currentuser!.id,
        type: NewStatusPageOwnerType.User,
        label: `${currentuser.first_name} ${currentuser.last_name}`,
      };
    }
  }
  // Update local state if Formik value changes (e.g., from an external reset)
  React.useEffect(() => {
    setLocalValue(values.squadcastDomainName);
  }, [values.squadcastDomainName]);

  return (
    <Box
      style={{
        overflowY: 'auto',
        padding: isEdit ? '0px' : '24px 0px',
        width: isEdit ? '100%' : '70%',
      }}
    >
      <FormControl isInvalid={errors.statusPageName && touched.statusPageName} mb={8}>
        <FormLabel htmlFor="statusPageName">Name*</FormLabel>
        <Field
          as={Input}
          type="text"
          name="statusPageName"
          id="status-name"
          placeholder="Eg : Public Status Page"
        />
        {errors?.statusPageName && touched.statusPageName && (
          <FormErrorMessage>{errors.statusPageName}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={errors.statusPageName} mb={8}>
        <FormLabel htmlFor="timezone">Timezone*</FormLabel>
        <Field
          as={Select}
          name="timezone"
          id="timezone"
          value={values.timezone}
          options={timezones}
          onChange={handleChange}
        />
        <Text mt={2} fontSize={14} color={THEME_COLORS.secondary[700]}>
          Issue updates and component timelines will be shown in your selected timezone.
        </Text>
      </FormControl>

      <FormControl isInvalid={!!(errors.owner?.id && touched.owner)} mb={8} px={1}>
        <FormLabel htmlFor="owner">Owner*</FormLabel>
        <Select
          name="owner"
          inputId="owner"
          options={ownerOptions}
          value={ownerValue}
          onChange={(newValue: any) =>
            setFieldValue('owner', { id: newValue.id, type: newValue.type })
          }
          components={{ SingleValue }}
          formatOptionLabel={ParticipantOptionLabel}
          placeholder={'Type a user name'}
          styles={styles}
        />
        <FormErrorMessage>{errors.owner?.id}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.description && touched.description} mb={8}>
        <FormLabel>
          Status Description{' '}
          <span style={{ color: THEME_COLORS.secondary[700], fontWeight: 400 }}>(optional)</span>
        </FormLabel>

        <Field
          as={Textarea}
          id="statusDescription"
          name="description"
          placeholder="Status Description"
        />
        {errors?.description && touched.description && (
          <FormErrorMessage>{errors.description}</FormErrorMessage>
        )}
      </FormControl>

      {!isEdit ? (
        <Flex mb={8}>
          <FormControl mr={4}>
            <FormLabel>
              Send Only Email{' '}
              <span style={{ color: THEME_COLORS.secondary[700], fontWeight: 400 }}>
                (for updates)
              </span>
            </FormLabel>
            <Input value={values.sendOnlyEmailID} disabled />
          </FormControl>

          <FormControl isInvalid={errors.contactEmail && touched.contactEmail}>
            <FormLabel>
              Fallback Support Email*
              <Tooltip
                label="This email will appear in the notification updates, enabling end users to easily reach out to you for assistance whenever needed."
                backgroundColor={'blackAlpha.900'}
              >
                <InfoIcon fontSize={'small'} />
              </Tooltip>
            </FormLabel>

            <Field as={Input} type="text" name="contactEmail" placeholder="info@squadcast.com" />
            {errors?.contactEmail && touched.contactEmail && (
              <FormErrorMessage>{errors.contactEmail}</FormErrorMessage>
            )}
          </FormControl>
        </Flex>
      ) : null}

      <Box bgColor={THEME_COLORS.primary[100]} p={6}>
        <FormControl mb={30}>
          <FormLabel htmlFor="domainSettings" fontSize={18}>
            Domain Settings*
          </FormLabel>
          <label style={{ display: 'flex' }}>
            {isEdit ? <Field type="radio" name="domainSettings" value="1" /> : null}
            <Text ml={isEdit ? 2 : 0} fontWeight="medium">
              Use Squadcast Public URL
            </Text>
          </label>
          {values.domainSettings === '1' ? (
            <FormControl
              isInvalid={
                (data && !isEdit) || (errors.squadcastDomainName && touched.squadcastDomainName)
              }
              ml={isEdit ? 5 : 0}
              my={2}
            >
              <InputGroup>
                <InputLeftAddon>
                  <Text>{urlPrefix}</Text>
                </InputLeftAddon>
                <Field
                  as={Input}
                  disabled={isEdit}
                  placeholder="type domain name"
                  value={localValue}
                  onChange={handleDomainChange}
                  bgColor="white"
                  maxWidth={'200px'}
                />
              </InputGroup>
              {data && !isEdit ? (
                <FormErrorMessage>Domain name already exists.</FormErrorMessage>
              ) : (
                errors?.squadcastDomainName &&
                touched.squadcastDomainName && (
                  <FormErrorMessage>{errors.squadcastDomainName}</FormErrorMessage>
                )
              )}
              {!isEdit ? (
                <Text mt={2} color={THEME_COLORS.secondary[700]} fontSize={14}>
                  Note: You can map a custom domain within `Page Configuration` post page creation
                </Text>
              ) : null}
            </FormControl>
          ) : null}
          {isEdit && (
            <Flex align={'baseline'} mt={2}>
              <Field type="radio" name="domainSettings" value="2" />
              <Box>
                <Text ml={2} fontWeight="medium">
                  Map a custom domain name
                </Text>
                <Text ml={2}>
                  A and TXT Record verifications help us map and verify the ownership of your custom
                  domain.{' '}
                  <Link
                    isExternal
                    color={'blue.500'}
                    href="https://support.squadcast.com/status-page/status-page#edit-page-configuration-or-configure-custom-domain"
                  >
                    Know more
                  </Link>
                </Text>
                <Text fontSize={12} color={'red.500'} hidden={values.domainSettings === '1'} ml={2}>
                  For users that have mapped and verified CNAME, this will continue without
                  disruptions. However, we urge you to verify your A and TXT record as well.
                </Text>
              </Box>
            </Flex>
          )}
          {values.domainSettings === '2' && isEdit && (
            <Flex ml={5} mt={2} gap={1} align="flex-start" direction={'column'}>
              <FormControl isInvalid={errors.customPageHostName}>
                <FormLabel>Page Hostname</FormLabel>
                <Flex>
                  <InputGroup>
                    <Input
                      placeholder="Eg : status.newrelic.com"
                      value={values.customPageHostName}
                      onChange={e => setFieldValue('customPageHostName', e.target.value, true)}
                      bgColor="white"
                      maxWidth={'300px'}
                    />
                    <InputRightAddon onClick={() => setShowConfig(true)}>
                      <Icon as={EnterIcon} />
                    </InputRightAddon>
                  </InputGroup>
                  {showConfig ? (
                    <Flex>
                      <Button
                        onClick={() => onVerifyDomain && onVerifyDomain()}
                        isLoading={isDomainVerifying}
                      >
                        {domainStatus?.isCustomARecordVerified.isVerified &&
                        domainStatus.isCustomTxtVerified.isVerified
                          ? 'Revalidate all records'
                          : 'Validate records'}
                      </Button>
                    </Flex>
                  ) : null}
                </Flex>
                {errors?.customPageHostName && (
                  <FormErrorMessage>{errors.customPageHostName}</FormErrorMessage>
                )}
              </FormControl>
              {showConfig && isEmpty(errors?.customPageHostName) ? (
                <Flex direction="column" mt={6}>
                  <Flex mb={8}>
                    <Text color={THEME_COLORS.secondary[700]} fontWeight="medium">
                      A Record
                    </Text>

                    <Flex direction={'column'} ml={12}>
                      <Flex direction={'column'} mb={4}>
                        <Text>Host Name</Text>
                        <div
                          className="cursor-pointer description-key mt-6 flex pr-10"
                          style={{
                            width: '350px',
                            backgroundColor: THEME_COLORS.secondary[100],
                          }}
                        >
                          <Flex flex={1}>
                            <Text>{values.customPageHostName}</Text>
                          </Flex>
                          <CopyToClipboard text={values.customPageHostName}>
                            <CopyIcon color="blue.400" />
                          </CopyToClipboard>
                        </div>
                      </Flex>
                      <Flex direction={'column'}>
                        <Text>Value</Text>
                        <div
                          className="cursor-pointer description-key mt-6 flex pr-10"
                          style={{
                            width: '350px',
                            backgroundColor: THEME_COLORS.secondary[100],
                          }}
                        >
                          <Flex flex={1}>
                            <Text>{values.aRecord || ''}</Text>
                          </Flex>
                          <CopyToClipboard text={values.aRecord || ''}>
                            <CopyIcon color="blue.400" />
                          </CopyToClipboard>
                        </div>
                      </Flex>
                      {!domainStatus?.isCustomARecordVerified.isVerified ? (
                        <Text color="red.400">{domainStatus?.isCustomARecordVerified.message}</Text>
                      ) : (
                        <Flex color={'green.500'} alignItems="center">
                          <CheckIcon />
                          <Text ml={1}>Verified</Text>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                  <Flex>
                    <Text color={THEME_COLORS.secondary[700]} fontWeight="medium">
                      TXT Record
                    </Text>

                    <Flex direction={'column'} ml={4}>
                      <Flex direction={'column'} mb={4}>
                        <Text>Host Name</Text>
                        <div
                          className="cursor-pointer description-key mt-6 flex pr-10"
                          style={{
                            width: '350px',
                            backgroundColor: THEME_COLORS.secondary[100],
                          }}
                        >
                          <Flex flex={1}>
                            <Text>{values.customPageHostName}</Text>
                          </Flex>
                          <CopyToClipboard text={values.customPageHostName}>
                            <CopyIcon color="blue.400" />
                          </CopyToClipboard>
                        </div>
                      </Flex>
                      <Flex direction={'column'}>
                        <Text>Value</Text>
                        <div
                          className="cursor-pointer description-key mt-6 flex pr-10"
                          style={{
                            width: '350px',
                            backgroundColor: THEME_COLORS.secondary[100],
                          }}
                        >
                          <Flex flex={1}>
                            <Text>{values.txtRecord || ''}</Text>
                          </Flex>
                          <CopyToClipboard text={values.txtRecord || ''}>
                            <CopyIcon color="blue.400" />
                          </CopyToClipboard>
                        </div>
                      </Flex>
                      {!domainStatus?.isCustomTxtVerified.isVerified ? (
                        <Text color="red.400">{domainStatus?.isCustomTxtVerified.message}</Text>
                      ) : (
                        <Flex color={'green.500'} alignItems="center">
                          <CheckIcon />
                          <Text ml={1}>Verified</Text>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              ) : null}
            </Flex>
          )}
          {isEdit && !values.privatePage && (
            <Box my={6}>
              <FormLabel>
                <Text
                  fontWeight={600}
                  ml={0}
                  mb={2}
                  color={THEME_COLORS.secondary[700]}
                  fontSize={16}
                >
                  Search engines
                </Text>
                <Checkbox
                  isChecked={values.hideFromSearchEngines}
                  borderColor={'#767676'}
                  borderRadius={1}
                  onChange={e => setFieldValue('hideFromSearchEngines', e.target.checked)}
                >
                  Hide my status page from search engines
                </Checkbox>
              </FormLabel>
            </Box>
          )}
        </FormControl>
      </Box>
      {!isEdit ? (
        <Box my={6} ml={1}>
          <FormLabel>
            <Checkbox
              isChecked={values.privatePage}
              onChange={e => setFieldValue('privatePage', e.target.checked)}
            >
              Make Page Private
            </Checkbox>
            <Text fontWeight={400} ml={6} color={THEME_COLORS.secondary[700]} fontSize={14}>
              You cannot change this setting in the future. Only Squadcast authenticated users can
              view a private page.
            </Text>
          </FormLabel>
        </Box>
      ) : null}
    </Box>
  );
};
export default PageConfigForm;
