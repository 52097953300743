import React from 'react';
import { Grid, Para, SpinLoader, TextButton, Theme } from 'uie/components';
import { IIncident } from '../../../../../../../core/interfaces/IIncidents';
import { IJiraCloud } from '../../../../../../../core/interfaces/IJira';
import { JiraCloudService } from '../../../../../../../core/services';
import { exception } from '../../../../../../../core/exception';
import { AppTracker } from '../../../../../../../shared/analytics/tracker';
import { T_WA_GS_INCIDENT_ACTIONS_JIRA_CLOUD } from '../../../../../../../core/const/tracker';
import { ServiceService } from 'core/services';
import { JiraCloudServiceNew } from 'core/services/services.jiracloud';
import { IJiraAccDetail } from 'core/interfaces/IJiraNew';

const { theme } = Theme;

interface IProps {
  incident: IIncident;
  serviceName: string;
  getIncidentDetails: () => void;
}

interface IState {
  config: IJiraCloud | null;
  serviceConfigured: boolean;
  loading: 'config' | 'ticket' | '';
  errorMessage: string;
  successfullyCreated: boolean;
  jiraAccounts: IJiraAccDetail[];
}

class JiraCloudAction extends React.Component<IProps, IState> {
  private _jiraCloudService = new JiraCloudService();
  private JCServiceNew: JiraCloudServiceNew = new JiraCloudServiceNew();
  private _service = new ServiceService(this.props.incident.service_id);
  constructor(props: IProps) {
    super(props);

    this.state = {
      config: null,
      serviceConfigured: false,
      loading: '',
      errorMessage: '',
      successfullyCreated: false,
      jiraAccounts: [],
    };
  }

  componentDidMount() {
    this.getJiraAccounts();
  }

  public getJiraAccounts = async () => {
    try {
      const { data: jiraAccounts } = await this.JCServiceNew.getJiraAccounts();
      if (jiraAccounts.length > 0) {
        this.setState({ jiraAccounts }, () => {
          this.checkForErrors();
        });
      }
    } catch (e) {
      exception.handle('E_JIRA_CLOUD_GET_ACCOUNT', e);
      this.setState({
        loading: '',
        successfullyCreated: false,
        errorMessage: exception.getErrorMsg(e),
      });
      AppTracker.track(T_WA_GS_INCIDENT_ACTIONS_JIRA_CLOUD);
    }
  };

  public checkForErrors = () => {
    let errorMessage = '';

    if (!(this.state.jiraAccounts.length > 0)) errorMessage = 'Please integrate with Jira Cloud';
    this.setState({ errorMessage });
  };

  public createTicketNew = async () => {
    this.setState({ loading: 'ticket' });
    try {
      await this.JCServiceNew.postNewJiraTicket(this.props.incident.id);
      this.setState({ loading: '', successfullyCreated: true });
    } catch (e) {
      exception.handle('E_JIRA_CLOUD_CREATE_TICKET', e);
      this.setState({
        loading: '',
        successfullyCreated: false,
        errorMessage: exception.getErrorMsg(e),
      });
    } finally {
      this.props.getIncidentDetails();
    }
    AppTracker.track(T_WA_GS_INCIDENT_ACTIONS_JIRA_CLOUD);
  };

  render() {
    return (
      <Grid className="mt-20 mb-20">
        {this.state.loading !== 'config' ? (
          !this.state.errorMessage ? (
            !this.state.successfullyCreated ? (
              <Grid justifyContent="space-around" width="100%">
                <TextButton
                  buttonType="inverted"
                  height="36px"
                  onClick={this.createTicketNew}
                  disabled={this.state.loading === 'ticket'}
                  style={{ padding: '0 44px' }}
                >
                  <Para fontWeight={500} fontSize={18} color={theme.primary.default}>
                    Create a Ticket in Jira
                  </Para>
                </TextButton>
              </Grid>
            ) : (
              <Para>A Jira ticket has been created for this incident in Jira Project </Para>
            )
          ) : (
            <Para>{this.state.errorMessage}</Para>
          )
        ) : (
          <Grid justifyContent="space-around" width="100%">
            <SpinLoader />
          </Grid>
        )}
      </Grid>
    );
  }
}

export default JiraCloudAction;
