import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" {...props}>
    <g fill="none">
      <path fill="#FFF" d="M0 0h90v90H0z" />
      <g fill="#000">
        <path d="m63.35 59.048 4.842.93c4.807.922 8.475-4.217 6.028-8.445L57.037 21.85c-2.448-4.228-8.745-3.63-10.347.984l-1.615 4.647a8.496 8.496 0 0 1 2.38 2.652l14.781 25.535a8.475 8.475 0 0 1 1.114 3.38Zm-2.933-.562a5.813 5.813 0 0 0-.593-1.429L45.042 31.522a5.827 5.827 0 0 0-.944-1.227l-3.126 9L46.69 55.85l13.727 2.635ZM19.828 68.18c-3.593-.564-5.75-4.29-4.443-7.676l6.358-16.474c1.726-4.472 7.878-4.953 10.28-.803L43.15 62.448c2.403 4.15-1.09 9.224-5.837 8.478l-17.485-2.745Z" />
        <path d="m46.69 64.567 7.43.988c4.7.624 8.074-4.403 5.703-8.498L45.041 31.522c-2.37-4.095-8.423-3.695-10.23.677l-2.858 6.911a8.49 8.49 0 0 1 2.483 2.728l11.126 19.22a8.47 8.47 0 0 1 1.127 3.509Zm-2.873-.382a5.776 5.776 0 0 0-.667-1.738l-11.127-19.22a5.78 5.78 0 0 0-1.175-1.445l-5.276 12.76c-1.43 3.457.811 7.327 4.528 7.82l13.717 1.823Z" />
      </g>
    </g>
  </svg>
);
export default SvgComponent;
