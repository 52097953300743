import { ExtensionService } from 'core';
import React from 'react';
import { useQuery } from 'react-query';
import { reactQueryConfigError } from '../../jira-cloud/helpers/service.reactqery';

async function GetSlackChannels() {
  const _extensionService = new ExtensionService();
  const { data } = await _extensionService.getSlackV2Channels();
  return data.data;
}

const useGetSlackChannels = () => {
  return useQuery('slack-channels', () => GetSlackChannels(), {
    retry: 1,
    staleTime: 0,
    keepPreviousData: true,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      const description =
        error?.response?.data?.meta?.error_message || 'Error connecting to server';
      reactQueryConfigError({ description: description, title: 'Get Slack channels' });
    },
  });
};

export { useGetSlackChannels };
