import {
  Autocomplete,
  CardBlock,
  DialogModalFrame,
  DropDown,
  DropDownOptionShell,
  ErrorBlock,
  FocusBlock,
  FormBlock,
  Grid,
  Heading,
  IconButton,
  InputBlock,
  Label,
  Para,
  Pill,
  SpinLoader,
  TextButton,
  Theme,
  Tooltip,
} from 'uie/components';
import RouteLeavingGuard from 'components/UnsavedChangeNavigation';
import { NoPermissionTooltip } from 'library/molecules/NoPermissionTooltip';
import React from 'react';
import ConfirmModal from '../../../../../../../components/confirm.modal';
import { ReduceEntity } from '../../../../../../../core/interfaces/IHelpers';
import { ISquad } from '../../../../../../../core/interfaces/ISquad';
import { IUsersOfOrganization } from '../../../../../../../core/interfaces/IUserData';
import {
  AlertTriangleIcon,
  CloseIcon,
  MoreVerticalIcon,
  SquadIcon,
  UserIcon,
} from '../../../../../../../icons';
import { getAssignableTeamMembers } from '../../../../../../../shared/reducers';
import { OrganizationSettingsTeamsRenderSquads } from './';
import { DeleteSquadConflict } from './components/ConflictModal/DeleteSquadConflict';

function editSquad(
  this: OrganizationSettingsTeamsRenderSquads,
  squad: Partial<ISquad>,
  _oUsers: ReduceEntity<IUsersOfOrganization, 'id' | 'email_verified' | 'phone_verified', 'name'>,
) {
  const { theme } = Theme;
  const { errors, searchMembers, networkState } = this.state;
  const { team, organization } = this.props;

  const filteredUsers = getAssignableTeamMembers(team, organization.users.u).filter(
    m =>
      m.role !== 'stakeholder' &&
      !squad.members?.includes(m.id) &&
      (_oUsers[m.id]?.name ?? 'Deleted').toLowerCase().includes(searchMembers.toLowerCase()),
  );

  return (
    <CardBlock
      key={-99}
      className="mb-10 mt-10"
      padding="8px"
      animateOnHover={true}
      borderStyle="sharp"
    >
      <Grid type="column" width="700px">
        <Heading fontSize={16} height={24}>
          {squad.id ? 'Edit' : 'New Squad'}
        </Heading>

        <FormBlock onFormSubmit={squad.id ? this.onUpdateSquad : this.onCreateNewSquad}>
          <div className="mt-10">
            <Label fontSize={14}>Name</Label>
            <div className="mt-5">
              <InputBlock
                height="32px"
                width="240px"
                padding="4px"
                placeholder="Squad name"
                value={squad.name}
                onChange={squad.id ? this.onEditSquadNameChange : this.onNewSquadNameChange}
                error={!!(errors.new_squad__name || errors.edit_squad__name)}
              />
              <ErrorBlock>{errors.new_squad__name || errors.edit_squad__name}</ErrorBlock>
            </div>
          </div>

          <div>
            <Label fontSize={14}>Members</Label>
            <Grid alignItems="center" justifyContent="flex-start" flexWrap="wrap">
              {(squad.members || []).length === 0 && (
                <Para fontSize={14} color={theme.font.disabled}>
                  No assigned members
                </Para>
              )}
              {(squad.members || []).map((id, j) => {
                const u = _oUsers[id];

                return (
                  <Pill className="mt-10 mr-10" key={j}>
                    <Tooltip
                      disabled={u.email_verified as boolean}
                      label={'User has not verified email'}
                      delay={0}
                      offset={{
                        top: '-65px',
                        left: '-10px',
                      }}
                    >
                      <Grid alignItems="center">
                        <UserIcon height={14} width={14} />
                        {!u.email_verified && (
                          <AlertTriangleIcon
                            stroke={theme.danger.default}
                            height={14}
                            width={14}
                            className="ml-5"
                          />
                        )}
                        <Para className="ml-5">{u.name}</Para>
                        <IconButton
                          onClick={
                            squad.id
                              ? this.onEditSquadMembersRemove(id)
                              : this.onRemoveMemberFromNewSquad(id)
                          }
                          color="transparent"
                          className="ml-10"
                        >
                          <CloseIcon height={14} width={14} />
                        </IconButton>
                      </Grid>
                    </Tooltip>
                  </Pill>
                );
              })}
            </Grid>
            <div className="mt-10">
              <Autocomplete
                onValueChange={
                  squad.id ? this.onEditSquadMembersAdd : this.onAddNewMemberToNewSquad
                }
                maxHeight="200px"
                width="240px"
                value={searchMembers}
                onChange={this.onSearchMembersChange}
                placeHolder="Search team members"
                showDropDownOnSelect={true}
                searchPropsHook={{
                  height: 32,
                  style: {
                    height: 32,
                    padding: 4,
                  },
                }}
                error={!!(errors.new_squad__members || errors.edit_squad__members)}
              >
                {filteredUsers.map((m, i) => {
                  return (
                    <FocusBlock key={i} value={m.id} disabled={!_oUsers[m.id]}>
                      <Para className="global--text-align--left" fontSize={14}>
                        {_oUsers[m.id]?.name ?? 'Deleted'}
                      </Para>
                    </FocusBlock>
                  );
                })}
                {filteredUsers.length === 0 ? (
                  <FocusBlock key={1} value={-1} disabled={true} isSelected={false}>
                    <Para fontSize={14}>None found!</Para>
                  </FocusBlock>
                ) : (
                  <></>
                )}
              </Autocomplete>
              <ErrorBlock>{errors.new_squad__members || errors.edit_squad__members}</ErrorBlock>
            </div>
          </div>
        </FormBlock>

        <div className="mt-10">
          <ErrorBlock>{errors.network_err}</ErrorBlock>
          <Grid alignItems="center">
            <TextButton
              onClick={squad.id ? this.onUpdateSquad : this.onCreateNewSquad}
              color={theme.primary.default}
              disabled={networkState === 'save'}
              height="26px"
            >
              <Grid alignItems="center">
                <Para color={theme.font.white}>Save</Para>
                {networkState === 'save' && (
                  <SpinLoader className="ml-10" color={theme.shades.white} base="8px" />
                )}
              </Grid>
            </TextButton>
            <TextButton
              onClick={squad.id ? this.onCancelEditSquad : this.onCancelAddNewSquad}
              buttonType="ghost"
              className="ml-10"
              disabled={networkState === 'save'}
            >
              <Para color={theme.font.default}>Cancel</Para>
            </TextButton>
          </Grid>
        </div>
      </Grid>
    </CardBlock>
  );
}

export function render(this: OrganizationSettingsTeamsRenderSquads) {
  const { theme } = Theme;
  const { networkState, squads, deleteSquadId, editSquadId, newSquad } = this.state;
  const { team, organization, search } = this.props;
  const _uA = this.props.aclStore;
  const hasCreate = _uA.hasCreateAccess('squads');

  const targetSquadId = window.location.hash.split('?')[0];

  const _oUsers = organization.users.u.reduce(
    (
      c: ReduceEntity<IUsersOfOrganization, 'id' | 'email_verified' | 'phone_verified', 'name'>,
      u,
    ) => {
      c[u.id] = {
        id: u.id,
        name: `${u?.first_name ?? 'Deleted'} ${u?.last_name ?? ''}`,
        email_verified: u.email_verified,
        phone_verified: u.phone_verified,
      };

      return c;
    },
    {},
  );

  const _squadMaps = squads.reduce(
    (c: ReduceEntity<ISquad, 'id' | 'name' | 'members'>, { id, name, members }) => {
      c[id] = {
        id,
        name,
        members,
      };
      return c;
    },
    {},
  );

  const squadIsBeingEdited = (squadId: string) => {
    return this.state.editSquadId !== '' && this.state.editSquadId === squadId;
  };

  const filteredSquads = squads.filter(
    s => squadIsBeingEdited(s.id) || s.name.toLowerCase().includes(search.toLowerCase()),
  );
  return (
    <>
      <div>
        {networkState === 'request' && (
          <Grid alignItems="center">
            <SpinLoader />
            <Para fontSize={14} className="ml-10">
              Loading squads for <span className="font-bold">{team.name}</span>
            </Para>
          </Grid>
        )}
        {networkState !== 'request' && (
          <div style={{ paddingBottom: 150 }}>
            {squads.length >= 0 && filteredSquads.length >= 0 && (
              <div>
                <Para style={{ marginBottom: '10px', fontSize: '12px', color: '#808080' }}>
                  Squads are smaller groups of members who are responsible for specific projects
                  within a team. Learn more about squads{' '}
                  <a
                    href="https://support.squadcast.com/manage-teams/squads"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </Para>
                <Para
                  fontSize={12}
                  color={theme.font.label}
                  style={{ fontStyle: 'italic', paddingBottom: 10 }}
                >
                  {`${squads.length} Squads belong to this team`}
                </Para>
              </div>
            )}
            {squads.length === 0 && (
              <div>
                <Para fontSize={16} color={theme.font.label}>
                  No squads added
                </Para>
              </div>
            )}
            {squads.length > 0 && filteredSquads.length === 0 && (
              <div>
                <Para fontSize={16} color={theme.font.label}>
                  Search did not match any squad
                </Para>
              </div>
            )}
            {filteredSquads.length > 0 &&
              filteredSquads.map((s, i) => {
                if (editSquadId === s.id) {
                  return editSquad.call(this, s, _oUsers);
                }
                const styleObjectOnTarget =
                  targetSquadId === '#' + s.id
                    ? {
                        border: `1px solid ${theme.primary.default}`,
                      }
                    : {};
                return (
                  <CardBlock
                    ref={ref => {
                      if (this._squadBlockRef) {
                        this._squadBlockRef[s.id] = ref! as HTMLDivElement;
                      }
                    }}
                    key={i}
                    className="mb-10"
                    padding="8px"
                    animateOnHover={true}
                    borderStyle="sharp"
                    style={{ ...styleObjectOnTarget }}
                  >
                    <Grid type="column" width="700px">
                      <Grid alignItems="center" justifyContent="space-between">
                        <Grid alignItems="center">
                          <SquadIcon height="14px" width="14px" />
                          <Para className="ml-10" fontSize={16} height="24px">
                            {s.name}
                          </Para>
                        </Grid>
                        <div>
                          {editSquadId === '' && !newSquad && (
                            <DropDown
                              hook={
                                <IconButton>
                                  <MoreVerticalIcon
                                    height="14px"
                                    width="14px"
                                    stroke={theme.shades.cement}
                                  />
                                </IconButton>
                              }
                              width="100px"
                              maxWidth="100px"
                              height="auto"
                            >
                              <div>
                                <NoPermissionTooltip
                                  isDisabled={this.props.aclStore.hasUpdateAccess('squads', s.id)}
                                >
                                  <DropDownOptionShell
                                    padding="8px"
                                    onClick={this.onEditSquad(s.id)}
                                    disabled={!this.props.aclStore.hasUpdateAccess('squads', s.id)}
                                  >
                                    <Para>Edit</Para>
                                  </DropDownOptionShell>
                                </NoPermissionTooltip>
                                <NoPermissionTooltip
                                  isDisabled={this.props.aclStore.hasDeleteAccess('squads', s.id)}
                                >
                                  <DropDownOptionShell
                                    padding="8px"
                                    onClick={this.onToggleDelete({
                                      deleteSquadId: s.id,
                                      deleteSquadName: s.name,
                                    })}
                                    disabled={!this.props.aclStore.hasDeleteAccess('squads', s.id)}
                                  >
                                    <Para color={theme.danger.default}>Delete</Para>
                                  </DropDownOptionShell>
                                </NoPermissionTooltip>
                              </div>
                            </DropDown>
                          )}
                        </div>
                      </Grid>
                      <Grid alignItems="center" justifyContent="flex-start" flexWrap="wrap">
                        {s.members.map((id, j) => {
                          const u = _oUsers[id];

                          return (
                            <Pill key={j} className="mt-10 mr-10">
                              <Tooltip
                                label={u.email_verified ? '' : 'User has not verified email'}
                                delay={u.email_verified ? 10000 : 0}
                                offset={{
                                  top: '-65px',
                                  left: '-10px',
                                }}
                              >
                                <Grid alignItems="center">
                                  <UserIcon height={14} width={14} />
                                  {!u.email_verified && (
                                    <AlertTriangleIcon
                                      stroke={theme.danger.default}
                                      height={14}
                                      width={14}
                                      className="ml-5"
                                    />
                                  )}
                                  <Para className="ml-5">{u.name}</Para>
                                </Grid>
                              </Tooltip>
                            </Pill>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </CardBlock>
                );
              })}
            {newSquad !== null && hasCreate && editSquad.call(this, newSquad, _oUsers)}
            <NoPermissionTooltip isDisabled={hasCreate}>
              <TextButton className="mt-20" onClick={this.onAddNewSquad} disabled={!hasCreate}>
                <Para color={theme.font.white}>Add new squad</Para>
              </TextButton>
            </NoPermissionTooltip>
          </div>
        )}
      </div>
      <DialogModalFrame
        id="organization_team_settings__delete_squad"
        onClose={this.onToggleDelete({ deleteSquadId: '', deleteSquadName: '' })}
        width="400px"
      >
        {deleteSquadId.length > 0 && (
          <ConfirmModal
            displayText={
              <Para>
                Delete <span className="font-bold">{_squadMaps[deleteSquadId].name}</span> squad
                with{' '}
                <span className="font-bold">
                  {_squadMaps[deleteSquadId].members.length} Members
                </span>{' '}
                ?
              </Para>
            }
            buttonText={`Delete ${_squadMaps[deleteSquadId].name}`}
            primaryButtonColor={theme.danger.default}
            cancelText="No"
            isBusy={networkState === 'save'}
            hide={this.onHideConfirmDelete}
          />
        )}
      </DialogModalFrame>
      <DeleteSquadConflict
        openModal={this.state.isDeleteConflictModalOpen}
        setOpenModal={() => {
          this.setState({ isDeleteConflictModalOpen: false, conflictData: null });
        }}
        squadName={this.state.deleteSquadName}
        entities={this.state.conflictData ?? {}}
      />
      <RouteLeavingGuard
        when={this.state.hasUnsavedChanged}
        shouldBlockNavigation={location => {
          if (this.state.hasUnsavedChanged) return true;
          return false;
        }}
        navigate={path => this.props.history.push(path)}
      />
    </>
  );
}
