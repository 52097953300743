import React from 'react';
import { DialogModalFrame } from 'uie/components';
import ConfirmationBox from 'views/main/organization/runbooks/common/deleteConfirmation';
import { NO, YES } from './taskAndRunbook.constants';

interface ITaskDeleteProps {
  deleteModal: boolean;
  deleteTaskIndex: number | null;
  action: (a: number) => void;
  setEnableDeleteModal: (value: boolean) => void;
  setDeleteTaskIndex: (value: number | null) => void;
}
interface IRunbookDeleteProps {
  deleteModal: boolean;
  deleteRunbookId: string;
  action: (a: string) => void;
  setEnableDeleteModal: (value: boolean) => void;
  setDeleteRunbookId: (value: string) => void;
}
const TaskDeleteModal = ({
  deleteModal,
  deleteTaskIndex,
  action,
  setDeleteTaskIndex,
  setEnableDeleteModal,
}: ITaskDeleteProps) => {
  const deleteConfirmation = (type: string) => {
    if (type === YES && deleteTaskIndex != null) action(deleteTaskIndex);

    if (type === NO) {
      setEnableDeleteModal(false);
      setDeleteTaskIndex(null);
    }
  };
  return (
    <DialogModalFrame
      id="delete_runbook_modal"
      hideClose={true}
      onClose={() => {
        setDeleteTaskIndex(null);
        setEnableDeleteModal(false);
      }}
      width="300px"
    >
      {deleteModal && (
        <ConfirmationBox
          action={deleteConfirmation}
          runbookName={`${deleteTaskIndex}`}
          isRunbook={false}
        />
      )}
    </DialogModalFrame>
  );
};

const RunbookDeleteModal = ({
  deleteModal,
  deleteRunbookId,
  action,
  setEnableDeleteModal,
  setDeleteRunbookId,
}: IRunbookDeleteProps) => {
  const deleteConfirmation = (type: string) => {
    if (type === YES && deleteRunbookId.length) action(deleteRunbookId);

    if (type === NO) {
      setEnableDeleteModal(false);
      setDeleteRunbookId('');
    }
  };
  return (
    <DialogModalFrame
      id="delete_runbook_modal"
      hideClose={true}
      onClose={() => {
        setEnableDeleteModal(false);
        setDeleteRunbookId('');
      }}
      width="300px"
    >
      {deleteModal && (
        <ConfirmationBox
          action={deleteConfirmation}
          runbookName={deleteRunbookId}
          isRunbook={true}
        />
      )}
    </DialogModalFrame>
  );
};

export { TaskDeleteModal, RunbookDeleteModal };
