import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <linearGradient
      id="a"
      gradientUnits="userSpaceOnUse"
      x1={-644.955}
      y1={379.603}
      x2={-644.955}
      y2={380.396}
      gradientTransform="matrix(25.2082 0 0 -25.1919 16268.126 9582.915)"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#0052cc',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#2684ff',
        }}
      />
    </linearGradient>
    <path
      d="M17.626.006H2.377A2.378 2.378 0 0 0 0 2.381v15.226a2.376 2.376 0 0 0 2.378 2.387h15.248A2.375 2.375 0 0 0 20 17.607V2.381A2.378 2.378 0 0 0 17.626.006zM8.625 14.402a.796.796 0 0 1-.801.791H4.492a.791.791 0 0 1-.788-.791V4.486a.79.79 0 0 1 .788-.791h3.332c.436.002.79.355.791.791l.01 9.916zm7.69-4.553a.793.793 0 0 1-.801.791h-3.332a.794.794 0 0 1-.791-.791V4.486a.794.794 0 0 1 .791-.791h3.332a.791.791 0 0 1 .788.791l.013 5.363z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: 'url(#a)',
      }}
    />
  </svg>
);

export default SvgComponent;
