import React from 'react';
import axios from 'axios';
import { API } from '../../../../../../core/api';
import { useMutation, useQueryClient } from 'react-query';
import { createStandaloneToast } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { watcherEvents } from '.';
const toast = createStandaloneToast();
const queryKey = 'watch';

interface IWatchSourceData {
  incidentID: string;
}

interface IUserSub {
  user_id: string;
  subscribed_events: string[];
}
interface WatcherData {
  data: {
    incident_watchers: IUserSub[];
  };
  meta: {
    total: number;
  };
}
interface Subscribed {
  created_at: string;
  deleted_at: null;
  id: number;
  incident_id: string;
  org_id: string;
  owner_id: string;
  subscribed_events: string[];
  updated_at: string;
  user_id: string;
  showToast: boolean;
}

const watchData = async (watchsubscription: IWatchSourceData) => {
  const response = await axios.get<WatcherData>(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${watchsubscription.incidentID}/watchers?owner_id=${API.config.teamId}`,
  );
  return response.data.data;
};

export function useWatchData({ incidentID }: IWatchSourceData) {
  return useQuery([queryKey, incidentID], () => watchData({ incidentID }), {
    onError: (error: any) => {
      const title =
        error?.response?.data?.meta?.error_message ||
        'There is an unexpected error fetching incident watcher. Please refresh and try again';
      toast.closeAll();
      toast({ title, status: 'error', variant: 'subtle', isClosable: true });
    },
  });
}

const createWatch = async (watchsubscription: IUserSub & { incidentID: string }) => {
  const { data } = await axios.post<{ data: Subscribed }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${watchsubscription.incidentID}/watch`,
    {
      user_id: watchsubscription.user_id,
      subscribed_events: watchsubscription.subscribed_events,
      owner_id: API.config.teamId,
    },
  );
  return data;
};

export function useSubscription() {
  const queryClient = useQueryClient();

  const { mutateAsync: createWatch_, data } = useMutation(createWatch, {
    onError: (error: any) => {
      const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
      toast.closeAll();
      toast({ title, status: 'error', variant: 'subtle', isClosable: true });
    },
    onSuccess: data => {
      const event =
        watcherEvents.find(v => v.value === data.data.subscribed_events?.[0])?.text ?? '';
      queryClient.invalidateQueries([queryKey, data.data.incident_id]);
      if (event) {
        toast({
          title: 'Success !',
          description: `You are subscribed to this incident. You have chosen ${event} as your incident watch preference. Check your email for notifications
 `,
          status: 'success',
          duration: 7000,
          isClosable: true,
        });
      }
    },
  });
  const suscribeData = data;
  return { createWatch_, suscribeData };
}
// update

const updateWatch = async (watchsubscription: IUserSub & { incidentID: string }) => {
  const { data } = await axios.put<{ data: Subscribed }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${watchsubscription.incidentID}/watch`,
    {
      user_id: watchsubscription.user_id,
      subscribed_events: watchsubscription.subscribed_events,
      owner_id: API.config.teamId,
    },
  );
  return data;
};

export function useUpdateubscription() {
  const queryClient = useQueryClient();

  const { mutateAsync: updateWatch_, data } = useMutation(updateWatch, {
    onError: (error: any) => {
      const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
      toast.closeAll();
      toast({ title, status: 'error', variant: 'subtle', isClosable: true });
    },
    onSuccess: data => {
      queryClient.invalidateQueries([queryKey, data.data.incident_id]);
    },
  });
  const UpdatesuscribeData = data;
  return { updateWatch_, UpdatesuscribeData };
}

const createUnWatch = async (watchUnsubscription: IUserSub & { incidentID: string }) => {
  await axios.post(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${watchUnsubscription.incidentID}/unwatch?owner_id=${API.config.teamId}`,
    {
      user_id: watchUnsubscription.user_id,
      subscribed_events: watchUnsubscription.subscribed_events,
      owner_id: API.config.teamId,
    },
  );
  return watchUnsubscription.incidentID;
};

export function useUnSubscription() {
  const queryClient = useQueryClient();

  const { mutateAsync: createUnWatch_, data } = useMutation(createUnWatch, {
    onError: (error: any) => {
      const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
      toast.closeAll();
      toast({ title, status: 'error', variant: 'subtle', isClosable: true });
    },
    onSuccess: data => {
      queryClient.invalidateQueries([queryKey, data]);

      toast({
        title: 'Success!',
        description: `You have been unsubscribed from this incident`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    },
  });
  const UnsuscribeData = data;
  return { createUnWatch_, UnsuscribeData };
}

// adding

const tagUserWatch = async (watchsubscription: IUserSub & { incidentID: string }) => {
  const { data } = await axios.post<{ data: Subscribed }>(
    `${API.config.batman}/organizations/${API.config.organizationId}/incidents/${watchsubscription.incidentID}/watch`,
    {
      user_id: watchsubscription.user_id,
      subscribed_events: watchsubscription.subscribed_events,
      owner_id: API.config.teamId,
    },
  );
  return data;
};

export function useSubscribeUser() {
  const queryClient = useQueryClient();

  const { mutateAsync: tagUserWatch_, data } = useMutation(tagUserWatch, {
    onError: (error: any) => {
      const title = error?.response?.data?.meta?.error_message || 'error connecting to server';
      toast.closeAll();
    },
    onSuccess: data => {
      queryClient.invalidateQueries([queryKey, data.data.incident_id]);
    },
  });
  const taguserData = data;
  return { tagUserWatch_, taguserData };
}
