import {
  CellBlock,
  DropDown,
  DropDownOptionShell,
  Grid,
  IconButton,
  Label,
  Para,
  Theme,
} from 'uie/components';
import {
  AlertTriangleIcon,
  MoreVerticalIcon,
  NotificationGreyEmail,
  ProfileIcon,
  UsernameIcon,
} from 'icons';
import { pick } from 'lodash';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useRemoveMemberFromSquad, useRemoveSquad, useUpdateMemberRole } from '../hooks/useSquad';
import { ConflictEntityTypes, DeleteSquadConflictData, SquadMember } from '../types';
import AddMemberToSquad from './AddMemberToSquad';
import { ConfirmConvertToMembersModal, ConfirmDeleteModal } from './ConfirmModal';
import { DeleteSquadConflict } from './ConflictModal/DeleteSquadConflict';
import MemberConflict from './ConflictModal/MemberConflict';
import { RoleAbilitiesModal } from './RoleAbilitiesModal';
import { SquadHeader } from './SquadPageHeader';
import { StyledHelpIcon } from './styles';

type SquadTableProps = {
  members: SquadMember[];
  squadName: string;
  onDeleted: () => void;
};
export function SquadTable({ members, squadName, onDeleted }: SquadTableProps) {
  const { theme } = Theme;

  const filterMembers = (members: SquadMember[], searchValue: string) => {
    if (!searchValue) return members;
    return members.filter(member => {
      return (
        member.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
        member.email?.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
  };

  const [searchValue, setSearchValue] = React.useState('');
  const filteredMembers = filterMembers(members, searchValue);
  const [actingUser, setActingUser] = React.useState<SquadMember>();
  const [openModal, setOpenModal] = React.useState(false);
  const [attemptedAction, setAttemptedAction] = React.useState<
    'remove' | 'updateRole' | undefined
  >();

  const [openDeleteSquadModal, setOpenDeleteSquadModal] = React.useState(false);
  const [conflictedEntities, setConflictedEntities] = React.useState<DeleteSquadConflictData>({});
  const [isHelpModalOpen, setIsHelpModalOpen] = React.useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
  const [showConfirmSquadDelete, setShowConfirmSquadDelete] = React.useState(false);
  const [openActionsDropdown, setOpenActionsDropdown] = React.useState(false);
  const [openConfirmConvertToMembersModal, setOpenConfirmConvertToMembersModal] =
    React.useState(false);

  const { mutate: removeMember, isLoading: isRemoveMemberLoading } = useRemoveMemberFromSquad({
    onError: (err: any) => {
      if (err?.response?.status === 409) {
        setAttemptedAction('remove');
        setOpenModal(true);
      }
      setOpenActionsDropdown(false);
    },
    onSuccess: () => {
      setAttemptedAction(undefined);
      setActingUser(undefined);
      setOpenModal(false);
      setOpenActionsDropdown(false);
    },
  });

  const { mutate: updateRole, isLoading: isMemberUpdating } = useUpdateMemberRole({
    onError: (err: any) => {
      if (err?.response?.status === 409) {
        setAttemptedAction('updateRole');
        setOpenModal(true);
      }
      setOpenActionsDropdown(false);
    },
    onSuccess: () => {
      setAttemptedAction(undefined);
      setActingUser(undefined);
      setOpenModal(false);
      setOpenActionsDropdown(false);
    },
  });

  const { mutate: deleteSquad } = useRemoveSquad({
    onError: (err: any) => {
      if (err?.response?.status === 409) {
        setOpenDeleteSquadModal(true);
        setConflictedEntities(pick(err.response?.data?.meta?.conflict_data, ConflictEntityTypes));
      } else {
        setOpenDeleteSquadModal(false);
      }
    },
    onSuccess: () => {
      setOpenDeleteSquadModal(false);
      onDeleted();
    },
  });

  const location = useLocation();
  const hash = location.hash;
  const selectedId = hash.replace('#', '');

  return (
    <Grid
      type="column"
      width="100%"
      style={{
        paddingLeft: '18px',
        paddingRight: '18px',
        paddingTop: '15px',
      }}
    >
      <SquadHeader
        searchValue={searchValue}
        setSearchValue={value => {
          setSearchValue(value);
        }}
        title={squadName}
        searchPlaceholder="Search members"
        onDeleteClick={() => {
          setShowConfirmSquadDelete(true);
        }}
        membersInSquad={members.length}
      />
      {filteredMembers.length === 0 ? null : (
        <Grid flexWidth={12} alignItems="center">
          <Grid flexWidth={5} alignItems="center">
            <Label
              style={{
                fontSize: '14px',
                lineHeight: '150%',
              }}
            >
              Name
            </Label>
          </Grid>
          <Grid flexWidth={6}>
            <Label
              style={{
                fontSize: '14px',
                lineHeight: '150%',
              }}
            >
              Role
            </Label>
            <IconButton
              style={{ padding: 0, marginLeft: '6px', marginTop: '1px' }}
              onClick={() => {
                setIsHelpModalOpen(true);
              }}
            >
              <StyledHelpIcon />
            </IconButton>
          </Grid>
          <Grid flexWidth={1} />
        </Grid>
      )}
      <div className="organization_settings_teams_members__list">
        {filteredMembers.length === 0 ? (
          <Para fontSize={16} style={{ marginTop: '8px', fontWeight: 400, color: '#8A92A3' }}>
            No results found
          </Para>
        ) : (
          filteredMembers.map((user, i) => {
            const conversionText = user?.role === 'owner' ? 'Squad Member' : 'Squad Owner';
            const role = user?.role === 'owner' ? 'Squad Owner' : 'Squad Member';
            return (
              <Grid
                key={i}
                flexWidth={12}
                alignItems="flex-start"
                style={{
                  marginTop: '8px',
                }}
                className="mb-5"
              >
                <Grid flexWidth={5}>
                  <div>
                    <Para fontSize={16} style={{ maxWidth: '30ch' }}>
                      {user?.name ?? 'Deleted'}
                    </Para>
                    <Grid alignItems="flex-start">
                      <NotificationGreyEmail
                        height={12}
                        width={12}
                        style={{ marginRight: 5, marginTop: 4 }}
                      />
                      <Para fontSize={12} color={theme.font.label} style={{ maxWidth: '30ch' }}>
                        {user?.email ?? 'Deleted'}
                      </Para>
                      {!user?.email_verified && (
                        <AlertTriangleIcon
                          height={10}
                          width={10}
                          className="ml-5"
                          stroke={theme.danger.default}
                        />
                      )}
                    </Grid>
                    {user?.username_for_display && (
                      <Grid alignItems="flex-start">
                        <UsernameIcon
                          height={12}
                          width={12}
                          style={{ marginRight: 5, marginTop: 4 }}
                        />
                        <Para fontSize={12} color={theme.font.label} style={{ maxWidth: '30ch' }}>
                          {user?.username_for_display ?? ''}
                        </Para>
                      </Grid>
                    )}
                    <Grid alignItems="flex-start">
                      <ProfileIcon
                        height={12}
                        width={12}
                        stroke={theme.shades.grey}
                        style={{ marginRight: 5, marginTop: 4 }}
                      />
                      <Para fontSize={12} color={theme.font.label}>
                        user
                      </Para>
                    </Grid>
                  </div>
                </Grid>
                <Grid flexWidth={6}>
                  <Grid alignItems="center" flexWrap="wrap">
                    <CellBlock className="mr-10 mt-10">
                      <Para fontSize={14}>{role}</Para>
                    </CellBlock>
                  </Grid>
                </Grid>
                {members.length > 1 ? (
                  <Grid flexWidth={1} justifyContent="flex-end">
                    <DropDown
                      hook={
                        <IconButton
                          onClick={() => setOpenActionsDropdown(true)}
                          disabled={isRemoveMemberLoading || isMemberUpdating}
                        >
                          <MoreVerticalIcon
                            height="14px"
                            width="14px"
                            stroke={theme.shades.cement}
                          />
                        </IconButton>
                      }
                      height="auto"
                      width="212px"
                      maxWidth="212px"
                      style={{ left: '-212px' }}
                    >
                      {openActionsDropdown ? (
                        <div>
                          <DropDownOptionShell
                            padding="8px"
                            onClick={() => {
                              setOpenActionsDropdown(false);
                              setActingUser(user);

                              if (user.role === 'owner') {
                                setOpenConfirmConvertToMembersModal(true);
                              } else {
                                updateRole({
                                  squadId: selectedId,
                                  memberId: user.user_id,
                                  role: 'owner',
                                });
                              }
                            }}
                          >
                            <Para>Convert to {conversionText}</Para>
                          </DropDownOptionShell>
                          <DropDownOptionShell
                            padding="8px"
                            onClick={() => {
                              setShowConfirmDelete(true);
                              setActingUser(user);
                            }}
                          >
                            <Para color={theme.danger.default}>Remove</Para>
                          </DropDownOptionShell>
                        </div>
                      ) : null}
                    </DropDown>
                  </Grid>
                ) : null}
              </Grid>
            );
          })
        )}
      </div>
      {filteredMembers.length !== 0 ? (
        <AddMemberToSquad
          members={members}
          onHelpClick={() => {
            setIsHelpModalOpen(true);
          }}
        />
      ) : null}

      <MemberConflict
        attemptedAction={attemptedAction}
        setOpenModal={() => {
          setOpenModal(false);
        }}
        openModal={openModal}
        memberName={actingUser?.name ?? 'Deleted User'}
        members={members}
        onConfirm={user => {
          if (attemptedAction === 'remove') {
            removeMember({
              squadId: selectedId,
              memberId: actingUser?.user_id ?? '',
              replaceWith: user.id,
            });
          } else if (attemptedAction === 'updateRole') {
            updateRole({
              squadId: selectedId,
              memberId: actingUser?.user_id ?? '',
              replaceWith: user.id,
              role: 'member',
            });
          }
        }}
      />
      <DeleteSquadConflict
        openModal={openDeleteSquadModal}
        setOpenModal={() => {
          setOpenDeleteSquadModal(false);
        }}
        squadName={squadName}
        entities={conflictedEntities}
      />
      <RoleAbilitiesModal
        openModal={isHelpModalOpen}
        setOpenModal={() => setIsHelpModalOpen(false)}
      />
      <ConfirmDeleteModal
        openModal={showConfirmDelete}
        setOpenModal={() => setShowConfirmDelete(false)}
        squadName={squadName}
        userName={actingUser?.name ?? ''}
        onConfirm={confirm => {
          setShowConfirmDelete(false);
          removeMember({ squadId: selectedId, memberId: actingUser?.user_id ?? '' });
        }}
      />
      <ConfirmDeleteModal
        openModal={showConfirmSquadDelete}
        setOpenModal={() => setShowConfirmSquadDelete(false)}
        squadName={squadName}
        members={members}
        userName={actingUser?.name ?? ''}
        onConfirm={confirm => {
          setShowConfirmSquadDelete(false);
          deleteSquad(selectedId);
        }}
      />
      <ConfirmConvertToMembersModal
        openModal={openConfirmConvertToMembersModal}
        setOpenModal={() => setOpenConfirmConvertToMembersModal(false)}
        memberName={actingUser?.name ?? ''}
        onConfirm={confirm => {
          setOpenConfirmConvertToMembersModal(false);
          updateRole({
            squadId: selectedId,
            memberId: actingUser?.user_id ?? '',
            role: 'member',
          });
        }}
      />
    </Grid>
  );
}
