import { exception } from 'core';
import { T_WA_GS_INCIDENT_LIST_EXPORTED } from 'core/const/tracker';
import moment from 'moment';
import { AppTracker } from 'shared/analytics/tracker';

import { exportAPI } from '../common/commands';
import { INCIDENT_STATUS } from '../common/enums';
import { getIncidentStatusType } from '../common/util';
import { filterManager } from '../filters/manager';
import { getFiltersForDownload } from '../filters/manager/util';
import { ILCOLUMN } from '../store';

export async function exportIncidents(
  type: string,
  userID: string,
  status: INCIDENT_STATUS,
  teamId: string,
  timeZone: string,
  sortBy: ILCOLUMN,
  searchTerm?: string,
): Promise<{
  isSuccess: boolean;
  message?: string;
}> {
  return new Promise(resolve => {
    const selectedStatus = getIncidentStatusType(status);
    const downloadFilters = getFiltersForDownload(
      filterManager.getFiltersData(timeZone) ?? {},
      sortBy,
    );

    const params = {
      ...downloadFilters,
      type,
      status: selectedStatus,
      owner_id: teamId,
      ...(searchTerm ? { textFilter: searchTerm } : null),
    };

    AppTracker.track(T_WA_GS_INCIDENT_LIST_EXPORTED);
    exportAPI(params)
      .then(response => {
        const responseType = type === 'csv' ? 'text/csv' : 'application/json';
        const blobURL = window.URL.createObjectURL(
          new Blob([response.data], { type: responseType }),
        );

        const hiddenElement = document.createElement('a');
        hiddenElement.href = blobURL;
        hiddenElement.target = '_blank';
        hiddenElement.download = `incident_export_${moment().format(
          'DD/MM/YYYY_hh:mm:ss',
        )}.${type}`;
        hiddenElement.click();
        resolve({
          isSuccess: true,
        });
      })
      .catch(error => {
        exception.handle('E_EXPORT_CSV_INCIDENTS', error);
        const responseBlob = new Blob([error.response.data]);
        responseBlob.text().then(text => {
          const resData = JSON.parse(text);
          resolve({
            isSuccess: false,
            message: resData.meta.error_message,
          });
        });
      });
  });
}
