import React, { useEffect, useMemo } from 'react';

import { CalendarDatum, CalendarTooltipProps, TimeRange } from '@nivo/calendar';

import { CalendarChart, Filters } from '../types';
import { useCalendarContext } from './CalendarPanel.context';
import { differenceInDays, toDateTime } from '../utils/date.utils';
import styled from 'styled-components';
import { Theme } from 'uie/components';
import { ResponsiveWrapper } from '@nivo/core';
import { DateTime } from 'luxon';

const { theme } = Theme;

const CustomTooltip = styled('div')<{ itemColor: string }>`
  background: ${theme.shades.white};
  color: inherit;
  font-size: inherit;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
  padding: 5px 9px;
  border: 1px solid ${theme.shades.smoke};
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > span:last-child {
      font-size: 10px;
      color: ${theme.shades.cement};
    }
    & > div {
      white-space: pre;
      display: flex;
      align-items: center;
      & > span:first-child {
        display: block;
        background-color: ${({ itemColor }) => itemColor};
        width: 12px;
        height: 12px;
        margin-right: 7px;
      }
    }
  }
`;

const formatTooltip = ({ value, day, color }: CalendarTooltipProps) => {
  return (
    <CustomTooltip itemColor={color}>
      <div>
        <div>
          <span></span>
          <span>
            {day}: <strong>{value}</strong>
          </span>
        </div>
        <span>Click on the block to view the incident status of that day</span>
      </div>
    </CustomTooltip>
  );
};

export const getYearDiff = (startDate: DateTime, endDate: DateTime) =>
  Math.floor(differenceInDays(startDate, endDate) / 365) + 1;

interface Props {
  filters: Filters;
  chart: CalendarChart;
  calendar: CalendarDatum[];
}

const CalendarPanel: React.FC<Props> = ({ filters, calendar }) => {
  const { updateActiveDate } = useCalendarContext();

  const yearDiff = getYearDiff(filters.date.startDate, filters.date.endDate);

  const [startDate, endDate] = useMemo(
    () => [filters.date.startDate.toJSDate(), filters.date.endDate.plus({ days: 1 }).toJSDate()],
    [filters.date],
  );

  const hasValue = (day: string) => {
    return calendar.find(data => data.day === day)?.value;
  };

  useEffect(() => {
    if (filters.date.endDate) {
      updateActiveDate(filters.date.endDate);
    }
  }, [filters.date.endDate]);

  return (
    <ResponsiveWrapper>
      {({ width, height }) => (
        <TimeRange
          // TODO: Use other calculated number to make tooltip visible on scrollable chart during hover at the end
          width={width * yearDiff * (yearDiff > 1 ? 1.15 : 1)}
          height={height}
          data={calendar}
          from={startDate}
          to={endDate}
          emptyColor="#eeeeee"
          colors={['#9ECBE0', '#6AAED6', '#4291C5', '#2271B5', '#0B519C']}
          margin={{ top: 50, right: 100, bottom: 40, left: 40 }}
          dayBorderWidth={2}
          dayBorderColor="#fff"
          tooltip={formatTooltip}
          weekdayTicks={[0, 1, 2, 3, 4, 5, 6]}
          onClick={data =>
            hasValue(data.day) ? updateActiveDate(toDateTime(new Date(data.day))) : null
          }
        />
      )}
    </ResponsiveWrapper>
  );
};

export default CalendarPanel;
