import React, { ReactElement, HTMLAttributes, useState, useEffect } from 'react';
import styled from 'styled-components';
import { IFocusBlock } from '../FocusBlock';
import cx from 'classnames';
interface IShellProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactElement<IFocusBlock>[] | any;
  alignTabs?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  justifyContent?: 'flex-start' | 'flex-end';
  borderCords?: {
    x: number | 0;
    y: number | 2;
  };
  focusColor?: string | 'transparent';
  shellWidth?: string | '100%';
  tabWidth?: string | 'fit-content';
  tabPadding?: string | '4px 0';
  tabCellPadding?: string | '6px 8px';
  initialTab: string;
}

const calcCords = ({ borderCords }: IShellProps) =>
  borderCords ? `${borderCords.x}px ${borderCords.y}px ` : '0 2px';

const Shell = styled.div<IShellProps>`
  justify-content: ${props => props.justifyContent};
  flex-direction: ${props => props.alignTabs};
  box-shadow: ${calcCords} 0 0 ${props => props.theme.shades.whiteSmoke};
  display: flex;
  width: ${props => props.shellWidth || '100%'};
  & > .selectedTab {
    box-shadow: ${calcCords} 0 0 ${props => props.theme.primary.default};
    opacity: 1;
    &,
    & > * {
      transition: background, opacity, border 0.17s ease-in;
    }
    & > * {
      background: ${props => props.theme.primary.light};
      color: ${props => props.theme.primary.default};
    }
  }
  & > .tab {
    padding: ${props => props.tabPadding || '2px 0'};
    width: ${props => props.tabWidth || 'fit-content'};
    & > * {
      padding: ${props => props.tabCellPadding || '6px 8px'};
      border-radius: 3px;
    }
  }
  & > .tab:not(.selectedTab) {
    & > * {
      color: ${props => props.theme.shades.cement};
    }
    &:hover > * {
      background: ${props => props.theme.shades.whiteSmoke};
    }
  }
`;

interface IProps extends IShellProps {
  onTabSelect: (tab: string) => void;
  isManual?: boolean;
}

const Tabs = ({ children, onTabSelect, ...props }: IProps) => {
  const [tab, setTab] = useState(props.initialTab);

  const onSelectValue = (e: any, index: number, value: string) => {
    setTab(value);
    onTabSelect(value);
  };

  useEffect(() => {
    setTab(props.initialTab);
  }, [props.initialTab]);

  const clonedChild = React.Children.map(children, (child: ReactElement<IFocusBlock>, i) => {
    const toCompare = props.isManual ? props.initialTab : tab;
    const selectedTab = child.props.value === toCompare;
    return React.cloneElement(child, {
      onSelectValue,
      className: cx([child.props.className, 'tab'], { selectedTab }),
      focus: false,
      key: i,
      index: i,
      focusColor: props.focusColor || 'transparent',
    });
  });

  return <Shell {...props}>{clonedChild}</Shell>;
};

/** @component */
export default Tabs;
