import React, { useEffect, useMemo, useRef } from 'react';
import { PriorityIcon } from 'icons';
import { Text } from '@chakra-ui/react';
import Wrapper from './Wrapper';
import { FILTER_TYPE_LABEL, OPERATOR_LABEL } from '../../../constant';
import { OperatorTypes } from '../../../types/enums';
import { safeEncodeUriComponent } from '../../../utils';
import { focusCard } from 'core';

import { ActiveWorkflowTarget } from './ActiveWorkflowTarget';
import PriorityOptions from '../../../create-or-update/actions/squadcast/priority/PriorityOptions';
import { Priority } from 'library/common';
import { BE_FieldType } from '../../../types';

interface IProps {
  priority?: BE_FieldType;
}

export const PriorityCondition: React.FC<IProps> = ({ priority }) => {
  const flexRef = useRef(null);

  const prioritiesOption = useMemo(() => {
    if (!priority) return [];
    return PriorityOptions();
  }, []);

  const { isactionTrigger, styleActionOnTarget, targetAction } = ActiveWorkflowTarget('#Priority');

  useEffect(() => {
    if (flexRef.current && targetAction === '#Priority') {
      focusCard(flexRef.current);
    }
  }, [isactionTrigger, priority, targetAction]);

  const PriorityName = prioritiesOption.find(
    priorityData => priority && priorityData.id === priority.fields.value,
  );
  if (!priority) return null;

  return (
    <Wrapper
      id={safeEncodeUriComponent(FILTER_TYPE_LABEL.priority)}
      border={styleActionOnTarget}
      ref={flexRef}
    >
      <PriorityIcon width={24} height={24} />

      <Text variant="h1" as="h1">
        <strong> Priority </strong>
      </Text>

      <Text fontSize="2xl">
        {OPERATOR_LABEL[priority?.type.split('priority_')[1] as OperatorTypes]}
      </Text>
      <Priority value={PriorityName?.id} width="32px" />
      <Text fontSize="2xl">{PriorityName?.name?.props?.children[2]}</Text>
    </Wrapper>
  );
};
