import axios from 'axios';
import { API } from '../api';

import {
  IExtensionStatus,
  IIncidentServiceNowMapping,
  IServiceNowIntegrationKey,
  IServiceNowResponse,
  ServiceNowConfigurationPayload,
  ServiceNowUpdateConfigurationPayload,
  ServiceNowVerifyPayload,
} from '../interfaces/IExtensions';

class ServiceNowSvc {
  private _api = `${API.config.batman}/organizations/${API.config.organizationId}/extensions/servicenow`;
  private _incidentApi = `${API.config.batman}/organizations/${API.config.organizationId}/incidents`;

  public getServiceNowConfig = () => axios.get<{ data: IServiceNowResponse }>(`${this._api}`);

  public getIncidentMapping = (incidentId: string) =>
    axios.get<{ data: IIncidentServiceNowMapping }>(`${this._api}/incidents/${incidentId}`);

  public getServiceNowStatus = () =>
    axios.get<{ data: { status: IExtensionStatus; is_enabled?: boolean } }>(`${this._api}/status`);

  public saveServiceNowConfig = (payload: ServiceNowConfigurationPayload) =>
    axios.post<{ data: IServiceNowResponse }>(`${this._api}`, payload);

  public updateServiceNowConfig = (payload: ServiceNowUpdateConfigurationPayload) =>
    axios.put<{ data: IServiceNowResponse }>(`${this._api}`, payload);

  public verifyServiceNowConfig = (payload: ServiceNowVerifyPayload) =>
    axios.post<{ data: string }>(`${this._api}/verify`, payload);

  public getServiceNowIntegrationKey = () =>
    axios.get<{ data: IServiceNowIntegrationKey }>(`${this._api}/integration-key`);

  public createServiceNowIncident = (incidentId: string) =>
    axios.post(`${this._incidentApi}/${incidentId}/actions/servicenow/new`);
}

export default ServiceNowSvc;
