import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import { LCR_DETAILS_TABS } from '../../../common/constant';
import LCRLogsTab from './LCRLogs';
import LCRDetailsTab from './LCRDetails';
import { getSessionPath } from '../../../common/utils';
import { Tab } from 'library/atoms';

const LCRDetailsBody = () => {
  const history = useHistory();
  const params = useParams<{ id: string; tabs: string }>();

  const onTabChange = useCallback(
    (index: number) => {
      const tab = LCR_DETAILS_TABS[index - 1];
      history.push(getSessionPath(params.id, tab.queryKey));
    },
    [history, params.id],
  );
  const currentTabKey = typeof params.tabs === 'string' ? params.tabs : 0;
  const currentTabIndex = currentTabKey
    ? LCR_DETAILS_TABS.findIndex(tab => tab.queryKey === params.tabs)
    : 0;

  return (
    <Tab
      onChange={onTabChange}
      labels={LCR_DETAILS_TABS}
      defaultIndex={currentTabIndex + 1}
      variant="outline"
      isLazy
    >
      <LCRDetailsTab />

      <LCRLogsTab />
    </Tab>
  );
};

export default LCRDetailsBody;
