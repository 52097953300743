import * as React from 'react';
import { SVGProps } from 'react';

const SvgNixstats = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
    <path
      style={{
        fill: '#32d282',
      }}
      d="M4.835 19.881v-2.695c0-.167.089-.276.197-.375 1.64-1.638 3.278-3.267 4.897-4.916.177-.177.256-.108.385.02 1.638 1.639 3.267 3.277 4.916 4.916.128.128.168.267.168.444V20c-.454-.444-.84-.82-1.224-1.204-1.254-1.254-2.518-2.498-3.752-3.771-.246-.257-.375-.237-.612 0-1.52 1.54-3.06 3.07-4.59 4.6-.089.089-.158.217-.227.326a.582.582 0 0 1-.158-.07z"
    />
    <path
      style={{
        fill: '#2cc6a2',
      }}
      d="M3.038 4.62C4.155 5.726 5.28 6.83 6.396 7.937c.563.553 1.106 1.115 1.678 1.668.178.168.207.276.01.464-1.639 1.62-3.258 3.248-4.887 4.877a.618.618 0 0 1-.473.187c-.85-.01-1.708 0-2.567 0-.04-.246.168-.316.276-.434 1.51-1.52 3.011-3.03 4.541-4.531.267-.267.257-.395 0-.652C3.364 7.917 1.775 6.308.176 4.7c.71-.119 1.421-.02 2.132-.06.247-.01.484.03.73-.02z"
    />
    <path
      style={{
        fill: '#2ab6ca',
      }}
      d="M19.82 4.689c-1.283 1.293-2.557 2.586-3.84 3.88-.325.325-.641.661-.987.967-.257.227-.257.365 0 .602 1.53 1.51 3.05 3.04 4.57 4.56.11.11.277.179.287.415h-2.695c-.188 0-.296-.118-.415-.236-1.599-1.6-3.208-3.209-4.817-4.808-.168-.168-.237-.266-.04-.464 1.659-1.639 3.298-3.287 4.946-4.936.03-.03.089-.05.128-.069.79.06 1.59.01 2.38.03.167.02.335-.03.483.059z"
    />
    <path
      style={{
        fill: '#21a4fe',
      }}
      d="M5.033.04c.631.651 1.263 1.312 1.905 1.964.977.987 1.974 1.964 2.951 2.961.237.247.366.227.603-.01 1.51-1.53 3.04-3.05 4.56-4.57.109-.109.227-.207.415-.385v2.784c0 .187-.109.296-.227.414-1.6 1.6-3.208 3.209-4.808 4.818-.187.187-.286.217-.503.01-1.58-1.6-3.179-3.189-4.768-4.778-.109-.109-.257-.208-.257-.405V.108c.05-.029.09-.049.129-.068z"
    />
    <path
      style={{
        fill: '#09c29c',
      }}
      d="M3.038 4.62c-.641.158-1.293.04-1.934.069-.306.02-.622.01-.928.01-.08-.08-.01-.109.05-.109.937 0 1.875-.059 2.813.03z"
    />
    <path
      style={{
        fill: '#0fb3c8',
      }}
      d="M19.82 4.689h-2.389c-.158 0-.325.05-.464-.07.938-.078 1.876-.019 2.814-.029.03 0 .059.03.079.04-.01.02-.02.04-.04.059z"
    />
  </svg>
);

export default SvgNixstats;
