import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
} from '@chakra-ui/react';

import { ProgressStepsJira } from '../../../Component/ProgressBarJira';
import { useCreateJiraCloudAccount } from '../../../hooks/createJiraAccount';
import { IJiraAccDetail } from '../../../../../../../../../core/interfaces/IJiraNew';
import { useUpdateJiraAccount } from '../../../hooks/updateJiraAccount';
import { useValidateJiraKey } from '../../../hooks/validateJiraKey';
import AccountDetailsSlider from './AccountDetailsSlider';
import SelectTeamsSlider from './SelectTeamsSlider';
import TokenSlider from './TokenSlider';
import { FormikHelpers, useFormik } from 'formik';

interface Props {
  isOpen: boolean;
  onCloseAddAccountModal: () => void;
  accountDetail?: IJiraAccDetail;
}

interface ISliderPorps {
  apiToken: string;
  jiraAccountName: string;
  teamsIds: string[];
  is_all_teams_configured: boolean;
}

function AddAccountModal(props: Props) {
  const steps = useMemo(
    () => [
      { title: 'Token', description: '' },
      { title: 'Account Details', description: '' },
      { title: 'Select Teams', description: '' },
    ],
    [],
  );

  const [sliderValue, setSliderValue] = useState(0);
  const [enableTokenVerification, setEnableTokenVerification] = useState(false);
  const [jiraLink, setJiraLink] = useState('');

  const handleAddAccount = async (
    values: ISliderPorps,
    formikHelpers: FormikHelpers<ISliderPorps>,
  ) => {
    try {
      const payload = {
        accounts_name: values.jiraAccountName,
        jiraClientKey: values.apiToken,
        account_link: jiraLink,
        is_all_teams_configured: values.is_all_teams_configured,
      };
      const allTeamConsideredPayload = !values.is_all_teams_configured
        ? { ...payload, teamsIds: values.teamsIds }
        : payload;

      if (props.accountDetail && props.accountDetail?.id.toString()) {
        await updateJiraAccount({
          ...allTeamConsideredPayload,
          accountId: props.accountDetail?.id.toString(),
        });
      } else {
        await createAccount({
          ...allTeamConsideredPayload,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  // formik
  const addAccountSchema = Yup.object().shape({
    apiToken: Yup.string().required('Jira API Token is required'),
    jiraAccountName: Yup.string()
      .required('Jira Account Name is required')
      .max(100, 'Account Name Too Long'),
    is_all_teams_configured: Yup.boolean(),
    teamsIds: Yup.array().when('is_all_teams_configured', {
      is: false,
      then: Yup.array().min(1, 'Please select ').required('Please select at least one team'),
      otherwise: Yup.array(),
    }),
  });

  const formik = useFormik<ISliderPorps>({
    initialValues: {
      apiToken: '',
      jiraAccountName: '',
      is_all_teams_configured: false,
      teamsIds: [],
    },
    validationSchema: addAccountSchema,
    onSubmit: handleAddAccount,
    enableReinitialize: true,
  });

  //hooks
  const {
    mutateAsync: createAccount,
    error,
    isLoading: isCreatingAccount,
    isSuccess: isCreateAccountSuccess,
  } = useCreateJiraCloudAccount();
  const {
    mutateAsync: updateJiraAccount,
    isLoading: isUpdatingAccount,
    isSuccess: isUpdateAccSuccess,
  } = useUpdateJiraAccount();

  const {
    data: tokenDeatil,
    isSuccess: tokenVerified,
    isError: tokenVerificationFailed,
    error: tokenVerificationError,
    isLoading: isVerifyingToken,
  } = useValidateJiraKey(formik.values.apiToken, enableTokenVerification);

  function selecteTeam(selectedTeams: string[]) {
    formik.setFieldValue('teamsIds', selectedTeams, true);
    if (formik.values.is_all_teams_configured) {
      formik.setFieldValue('is_all_teams_configured', false, true);
    }
  }
  function selectAllTeam() {
    formik.setFieldValue('teamsIds', [], true);
    formik.setFieldValue('is_all_teams_configured', true, true);
  }
  function onNextSlider() {
    if (!tokenVerified && sliderValue === 0 && !props.accountDetail?.jira_client_key) {
      setEnableTokenVerification(true);
    } else if (sliderValue < 2) {
      setSliderValue(prev => prev + 1);
    }
  }

  function onPrevSlider() {
    if (sliderValue > 0) {
      setSliderValue(prev => prev - 1);
    }
  }

  const vaidataAndNext = async () => {
    try {
      // if (!ifLastStep) {
      const formikErrors = await formik.validateForm();
      if (sliderValue === 0 && !formikErrors.apiToken) {
        onNextSlider();
      } else if (sliderValue === 1 && !formikErrors.jiraAccountName) {
        onNextSlider();
      } else if (sliderValue === 2 && !formikErrors.teamsIds) {
        formik.handleSubmit();
      }
      // }
    } catch (e: any) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (isCreateAccountSuccess || isUpdateAccSuccess) {
      props.onCloseAddAccountModal();
    }
  }, [isCreateAccountSuccess, isUpdateAccSuccess]);

  React.useEffect(() => {
    if (props.accountDetail) {
      setJiraLink(props.accountDetail?.account_link);
      formik.setValues({
        apiToken: props.accountDetail.jira_client_key,
        jiraAccountName: props.accountDetail.account_name,
        teamsIds: props.accountDetail?.team_ids?.map(team => team.team_id),
        is_all_teams_configured: props.accountDetail.is_all_teams_configured,
      });
    }
  }, [props.accountDetail]);

  React.useEffect(() => {
    if (tokenVerified && !props.accountDetail) {
      setJiraLink(tokenDeatil?.account_url || '');
      onNextSlider();
    }
    if (tokenVerificationError) {
      setEnableTokenVerification(false);
    }
  }, [tokenVerified, tokenVerificationFailed]);

  const ifLastStep = useMemo(() => sliderValue === steps.length - 1, [sliderValue]);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onCloseAddAccountModal} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <form>
          <ModalHeader>{props.accountDetail ? 'Update' : 'Add'} Jira Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ProgressStepsJira steps={steps} onChangeStep={() => {}} sliderValue={sliderValue} />
            {sliderValue == 0 && (
              <TokenSlider
                apiToken={formik.values.apiToken}
                apiError={tokenVerificationError}
                isEdit={props.accountDetail?.jira_client_key ? true : false}
                formikError={formik.errors.apiToken}
                handleChange={formik.handleChange}
              />
            )}
            {sliderValue == 1 && (
              <AccountDetailsSlider
                apiToken={formik.values.apiToken}
                name={formik.values.jiraAccountName}
                formikError={formik.errors.jiraAccountName}
                handleChange={formik.handleChange}
              />
            )}
            {sliderValue == 2 && (
              <SelectTeamsSlider
                cloudName={formik.values.jiraAccountName}
                selectTeam={selecteTeam}
                selectAllTeam={selectAllTeam}
                selectedTeams={formik.values.teamsIds}
                formikError={formik.errors.teamsIds}
                isAllTeamsConfigured={formik.values.is_all_teams_configured}
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Box display={'flex'} flexDirection="row" justifyContent={'flex-end'} mb={5}>
              <Button
                background="primary.default"
                color="shades.white"
                fontWeight={400}
                fontSize={16}
                _hover={{ opacity: 0.6 }}
                mr={3}
                onClick={sliderValue === 0 ? props.onCloseAddAccountModal : onPrevSlider}
              >
                {sliderValue === 0 ? 'Cancel' : 'Previous'}
              </Button>
              <Button
                background="primary.default"
                color="shades.white"
                fontWeight={400}
                fontSize={16}
                onClick={() => vaidataAndNext()}
                isLoading={isVerifyingToken || isCreatingAccount || isUpdatingAccount}
                loadingText={
                  isVerifyingToken ? 'Verifying' : isCreatingAccount ? 'Saving' : 'Updating'
                }
                disabled={(sliderValue === 2 && !!formik.errors.teamsIds) || formik.isSubmitting}
              >
                {ifLastStep ? (props.accountDetail ? 'Update' : 'Save & Integrate') : 'Save & Next'}
              </Button>
            </Box>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default AddAccountModal;
