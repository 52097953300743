import { IAdvancedFilterBlockGroup } from 'uie/components/AdvancedFilterBlock/types';
import { IEventWebhookTrigger } from 'core/interfaces/IEventWebHooks';
import { AnyObject } from 'yup/lib/types';

import {
  DEFAULT_HEADER_KEY,
  DEFAULT_HEADER_VALUE,
  EMPTY_URL_OBJ,
  HEADER_TYPE,
  PAYLOAD_TYPES,
  URL_TYPE,
  WEBHOOK_TYPES,
} from '../webhook-const';
import { WEBHOOK_ACTIONS } from './webhook.action';

export interface IWebhookContext {
  id?: string;
  name: string;
  description: string;
  email: string;
  urls: URL_TYPE[];
  headers: HEADER_TYPE[];
  trigger_type: WEBHOOK_TYPES;
  teams: string[];
  version: string;
  triggers: IEventWebhookTrigger[];
  filters: IAdvancedFilterBlockGroup;
  is_all_teams_configured: boolean;
  payload_type: PAYLOAD_TYPES;
  custom_payload: string;
  custom_payload_template_slug: string;
  isDataLoaded: boolean;
}

export const INITIAL_FILTER_STATE = { op: 'and', conditions: [] } as IAdvancedFilterBlockGroup;

export const INITIAL_WEBHOOK_STATE: IWebhookContext = {
  name: '',
  description: '',
  email: '',
  urls: [EMPTY_URL_OBJ],
  headers: [
    {
      key: DEFAULT_HEADER_KEY,
      value: DEFAULT_HEADER_VALUE,
    },
  ],
  trigger_type: WEBHOOK_TYPES.MANUAL,
  teams: [],
  version: 'v2',
  triggers: [],
  filters: INITIAL_FILTER_STATE,
  is_all_teams_configured: true,
  payload_type: PAYLOAD_TYPES.STANDARD,
  custom_payload: '',
  custom_payload_template_slug: '',
  isDataLoaded: false,
};

export const WebhookReducer = (state: IWebhookContext, action: { type: string; payload?: any }) => {
  const { type, payload } = action;
  let newState = { ...state, ...payload };

  switch (type) {
    case WEBHOOK_ACTIONS.GET_DETAILS:
      const email = payload.mail_ids?.[0] ?? '';
      const headers: HEADER_TYPE[] = [
        {
          key: DEFAULT_HEADER_KEY,
          value: DEFAULT_HEADER_VALUE,
        },
      ];
      if (payload.header) {
        Object.entries(payload.header).forEach(([key, value]) => {
          if (key !== DEFAULT_HEADER_KEY && value !== DEFAULT_HEADER_VALUE) {
            headers.push({
              key,
              value: `${value}`,
            });
          }
        });
      }
      newState = { ...payload, email, headers, isDataLoaded: true };
      break;
    case WEBHOOK_ACTIONS.SAVE_DETAILS:
      newState.headers = payload.headers;
      newState.urls = payload.urls;
      break;
    case WEBHOOK_ACTIONS.SAVE_TYPE_INFO:
      newState.teams = payload.teams;
      if (payload.trigger_type === WEBHOOK_TYPES.MANUAL) {
        newState.payload_type = PAYLOAD_TYPES.CUSTOM;
        newState.triggers = [];
        delete newState.filters;
      } else {
        newState.triggers = payload.triggers;
        newState.teams = [];
      }
      break;
    case WEBHOOK_ACTIONS.SAVE_PAYLOAD:
    default:
    // do nothing
  }
  return newState;
};

export const getWebhookUpsertData = (state: IWebhookContext, payload: any) => {
  const { headers, email, ...payloadData } = state;

  const header = getHeaderFromHeadersArr(headers);
  const mail_ids: string[] = [];

  if (email) {
    mail_ids.push(email);
  }

  return { ...payloadData, ...payload, language: 'jsonnet', header, mail_ids };
};

export const getHeaderFromHeadersArr = (headers: HEADER_TYPE[]) => {
  const header: AnyObject = {};

  if (Array.isArray(headers) && headers.length) {
    headers.forEach((item: HEADER_TYPE) => {
      header[item.key] = item.value;
    });
  }
  return header;
};
