import { InfoOutlineIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  ChakraProvider,
  CloseButton,
  createStandaloneToast,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  T_WA_UP_INCIDENT_WATCHER_SUBSCRIBED,
  T_WA_UP_INCIDENT_WATCHER_SUBSCRIBE_UPDATED_TO_ALL_ACTIVITY,
  T_WA_UP_INCIDENT_WATCHER_SUBSCRIBE_UPDATED_TO_INCIDENT_LIFECYCLE,
  T_WA_UP_INCIDENT_WATCHER_SUBSCRIBE_UPDATED_TO_INCIDENT_LIFECYCLE_WITH_NOTE,
  T_WA_UP_INCIDENT_WATCHER_UNSUBSCRIBED,
} from 'core/const/tracker';
import { IAppState } from 'core/interfaces/IAppState';
import { AlternateEyeFilledIcon, AlternateEyeIcon } from 'icons';
import { matchSorter } from 'match-sorter';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppTracker } from 'shared/analytics/tracker';
import { useSubscription, useUnSubscription, useUpdateubscription, useWatchData } from './FetchApi';
import { ProfileItem } from './ProfileItem';
const toast = createStandaloneToast();

const INCIDENT_WATCHER_STAKEHOLDER_FEATURE_FLAG = 'notify_only_stakeholders_for_incident_lifecycle';

export const watcherEvents = [
  {
    value: 'incident_lifecycle_with_notes',
    text: 'Incident Lifecycle + Notes',
    label: 'Receive notifications on states of incident + every comment in notes section',
  },
  {
    value: 'incident_lifecycle',
    text: 'Incident Lifecycle',
    label:
      'Receive notifications on states of incident i.e when incident is acknowledged or resolved',
  },
  {
    value: 'all_activity',
    text: 'All activity',
    label:
      'Receive notifications on change to states of incident, comments in notes, actions on incident, activities on runbooks, tasks, communication channels & SLOs, updates to status page, postmortem & tags',
  },
];

const Watcher = ({ incidentId }: { incidentId: string }) => {
  const userList = useSelector((state: IAppState) => state.organization.users.u);
  const currentUser = useSelector((state: IAppState) => state.organization.currentUser.u);
  const organization = useSelector((state: IAppState) => state.organization.currentOrg.o);
  const [subscribedEvent, setSubscribedEvent] = React.useState('');
  const [subscribedEventLabel, setSubscribedEventLabel] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const [isIncidentSubscribed, setIncidentSubscribed] = React.useState(false);
  const { data: subscriptionlist, isSuccess } = useWatchData({ incidentID: incidentId });
  const { createWatch_ } = useSubscription();
  const { createUnWatch_ } = useUnSubscription();
  const { updateWatch_ } = useUpdateubscription();

  const lifecycleNoteEvent = watcherEvents[0].value;

  const [users, setUsers] = React.useState<{ id: string; name: string; username?: string }[]>([]);
  const filteredUsers = useMemo(
    () => matchSorter(users, searchValue || '', { keys: ['name'] }),
    [users, searchValue],
  );

  function handleUserSubscription(value: string) {
    let watcherEvent = value || lifecycleNoteEvent;

    if (currentUser?.id) {
      if (
        organization?.config[INCIDENT_WATCHER_STAKEHOLDER_FEATURE_FLAG] &&
        currentUser?.role == 'stakeholder'
      ) {
        watcherEvent = watcherEvents[1].value;
      }
      setSubscribedEvent(watcherEvent);
      try {
        createWatch_({
          user_id: currentUser.id,
          incidentID: incidentId,
          subscribed_events: [watcherEvent],
        });
        AppTracker.track(T_WA_UP_INCIDENT_WATCHER_SUBSCRIBED);
      } catch (error) {
        console.error(error);
      }
    }
  }

  const onSearchUser = (e: any) => {
    const searchvalue = e.target.value;
    setSearchValue(searchvalue);
  };

  function handleUserUnsubscribe(unsubscribe: string) {
    if (currentUser?.id) {
      try {
        createUnWatch_({
          user_id: currentUser.id,
          incidentID: incidentId,
          subscribed_events: [unsubscribe],
        });

        setSubscribedEvent('');
        setIncidentSubscribed(false);
        AppTracker.track(T_WA_UP_INCIDENT_WATCHER_UNSUBSCRIBED);
      } catch (error) {
        console.error(error);
      }
    }
  }

  function handleUpdateValue(value: string) {
    if (currentUser?.id && value) {
      try {
        updateWatch_({
          user_id: currentUser.id,
          incidentID: incidentId,
          subscribed_events: [value],
        });
        setSubscribedEvent(value);
        if (value === 'incident_lifecycle_with_notes') {
          AppTracker.track(
            T_WA_UP_INCIDENT_WATCHER_SUBSCRIBE_UPDATED_TO_INCIDENT_LIFECYCLE_WITH_NOTE,
          );
        } else if (value === 'incident_lifecycle') {
          AppTracker.track(T_WA_UP_INCIDENT_WATCHER_SUBSCRIBE_UPDATED_TO_INCIDENT_LIFECYCLE);
        } else if (value === 'all_activity') {
          AppTracker.track(T_WA_UP_INCIDENT_WATCHER_SUBSCRIBE_UPDATED_TO_ALL_ACTIVITY);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  function handleSubscriptionChange(value: string) {
    const userSubscribedEventLabel = watcherEvents.find(v => v.value === value)?.text ?? '';

    if (
      subscribedEvent != '' &&
      value &&
      value !== subscribedEvent &&
      subscriptionlist?.incident_watchers.length
    ) {
      toast({
        title: ' Success! ',
        description: `You are subscribed to this incident. You have chosen ${userSubscribedEventLabel} as your incident watch preference. Check your email for notifications`,
        status: 'success',
        duration: 7000,
        isClosable: true,
      });

      handleUpdateValue(value);
    }
    setSubscribedEvent(value);
    setSubscribedEventLabel(userSubscribedEventLabel);
  }

  useEffect(() => {
    if (subscriptionlist) {
      const results = (subscriptionlist.incident_watchers ?? []).map(watcher => {
        const usr = userList.find(user => watcher.user_id === user.id);
        return {
          id: usr?.id ?? watcher.user_id,
          name: usr?.first_name + ' ' + usr?.last_name,
          username: usr?.username_for_display,
        };
      });
      setUsers(results);
    } else {
      setUsers([]);
    }
  }, [subscriptionlist, userList]);

  useEffect(() => {
    if (subscriptionlist) {
      const userSubscribedEvent =
        subscriptionlist?.incident_watchers.find(w => w.user_id === currentUser?.id)
          ?.subscribed_events?.[0] ?? '';
      const userSubscribedEventLabel =
        watcherEvents.find(v => v.value === userSubscribedEvent)?.text ?? '';

      setSubscribedEvent(userSubscribedEvent);
      setSubscribedEventLabel(userSubscribedEventLabel);

      if (userSubscribedEvent) {
        setIncidentSubscribed(true);
      } else {
        setIncidentSubscribed(false);
      }
    }
  }, [subscriptionlist]);

  return (
    <div>
      <Box style={{ marginLeft: '20px', display: 'flex' }} position="relative">
        <Menu>
          <MenuButton
            as={Button}
            sx={{
              height: 8,
              border: '1px solid #7A9AE5',
              padding: '6px',
              py: 0,
              backgroundColor: '#F9FBFE',
              boderRadius: '4px',
            }}
            _focus={{
              outline: 'none',
            }}
          >
            <HStack justifyContent={users.length ? 'flex-start' : 'center'}>
              {!isIncidentSubscribed ? <AlternateEyeIcon /> : <AlternateEyeFilledIcon />}

              {users.length && (
                <AvatarGroup size="xs" max={3}>
                  {users.map(user => {
                    return (
                      <Avatar key={user.id} src="#" name={user.username ? user.name : 'Deleted'} />
                    );
                  })}
                </AvatarGroup>
              )}
            </HStack>
          </MenuButton>

          <MenuList sx={{ width: '450px', paddingTop: '0' }}>
            <MenuItem
              closeOnSelect={true}
              bgColor="#F4F4F4"
              display="flex"
              justifyContent="flex-end"
              border="none"
              _hover={{
                background: '#F4F4F4',
              }}
              py={0}
            >
              <CloseButton
                marginTop={3}
                border={'none'}
                marginBottom={2}
                background={'transparent'}
              />
            </MenuItem>

            <Tabs>
              <TabList bgColor="#F4F4F4">
                <Tab
                  width="100%"
                  paddingBottom={3}
                  bgColor="#F4F4F4"
                  borderLeft="none"
                  borderRight="none"
                  borderTop="none"
                  _focus={{
                    boxShadow: 'unset',
                  }}
                >
                  Your Watch Options
                </Tab>
                <Tab
                  width="100%"
                  paddingBottom={3}
                  bgColor="#F4F4F4"
                  borderLeft="none"
                  borderRight="none"
                  borderTop="none"
                  _focus={{
                    boxShadow: 'unset',
                  }}
                >
                  Incident Watchers ({subscriptionlist?.incident_watchers?.length ?? 0})
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel sx={{ paddingBottom: '0', padding: '0' }}>
                  <Accordion allowToggle>
                    <Heading
                      as="h4"
                      fontSize="sm"
                      margin="3px 0"
                      color="gray.600"
                      paddingTop="10px"
                      paddingLeft="15px"
                    >
                      You will receive email notifications for this incident
                    </Heading>
                    <AccordionItem sx={{ border: 'none' }}>
                      <HStack w="100%">
                        <HStack flex={1}>
                          <Flex paddingLeft="15px">
                            <Text fontSize="sm" fontWeight={500}>
                              {subscribedEvent ? 'Watching : ' : 'Watch'}
                            </Text>
                            <Text fontSize="sm" fontWeight={500} ml={2}>
                              {subscribedEventLabel}
                            </Text>
                          </Flex>

                          <Box flex="1" textAlign="right" marginRight="2">
                            <Switch
                              size="sm"
                              isChecked={isIncidentSubscribed}
                              onChange={e => {
                                const isChecked = e.target.checked;
                                setIncidentSubscribed(isChecked);

                                if (isChecked) handleUserSubscription(subscribedEvent);
                                else handleUserUnsubscribe(subscribedEvent);
                              }}
                            />
                          </Box>
                        </HStack>

                        <AccordionButton
                          w="auto"
                          border="none"
                          bg="transparent"
                          _focus={{
                            outline: 'none',
                            bg: 'transparent',
                          }}
                          _hover={{
                            outline: 'none',
                            bg: 'transparent',
                          }}
                        >
                          <AccordionIcon />
                        </AccordionButton>
                      </HStack>

                      <AccordionPanel paddingBottom="0" paddingLeft="0" padding="0">
                        <RadioGroup value={subscribedEvent} onChange={handleSubscriptionChange}>
                          <Stack>
                            <List spacing={2} padding={0} marginBottom={2}>
                              {watcherEvents.map(itemvalue => {
                                return (
                                  <ListItem
                                    key={itemvalue.value}
                                    sx={{
                                      borderBottom: '1px solid #DADADA',
                                      paddingLeft: '15px',
                                      paddingBottom: '15px',
                                    }}
                                    _last={{
                                      borderBottom: 'none',
                                      paddingBottom: 'none',
                                    }}
                                  >
                                    <Radio
                                      isDisabled={!isIncidentSubscribed}
                                      value={itemvalue.value}
                                      checked={itemvalue.value === subscribedEvent}
                                    >
                                      {itemvalue.text}
                                    </Radio>
                                    <Tooltip
                                      hasArrow
                                      label={itemvalue.label}
                                      backgroundColor="white"
                                      color="gray.600"
                                      p={4}
                                      direction="ltr"
                                    >
                                      <InfoOutlineIcon color="gray.400" marginLeft="4" />
                                    </Tooltip>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Stack>
                        </RadioGroup>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </TabPanel>
                <TabPanel>
                  <Stack spacing={3}>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <SearchIcon color="gray.300" />
                      </InputLeftElement>
                      <Input
                        variant="outline"
                        placeholder="search watcher by name"
                        type="search"
                        onChange={onSearchUser}
                      />
                    </InputGroup>

                    {filteredUsers.length ? (
                      filteredUsers.map(user => {
                        return (
                          <ProfileItem
                            item={user}
                            key={user.id}
                            setUsers={setUsers}
                            watcher={undefined}
                            isUser={user.id === currentUser?.id}
                          />
                        );
                      })
                    ) : (
                      <Text fontSize="sm" textAlign="center">
                        No watchers
                      </Text>
                    )}
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </MenuList>
        </Menu>
      </Box>
    </div>
  );
};

const IncidentWatcher = ({ incidentId }: { incidentId: string }) => {
  return (
    <ChakraProvider resetCSS={false}>
      <Watcher incidentId={incidentId} />
    </ChakraProvider>
  );
};

export default IncidentWatcher;
