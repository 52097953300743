import { API } from 'core';
import axios, { AxiosResponse } from 'axios';
import { AppConfig } from 'shared/app.config';
import { EntitiesForType, EntityCount, EntityList, EntityListPayload } from '../types';

const url = AppConfig.api_url;
export const getEntityCounts = (teamId: string) => {
  return axios.get<{
    data: EntityCount;
  }>(`${url}/v3/organizations/${API.config.organizationId}/teams/${teamId}/entities/count`);
};

export const getEntitiesData = (teamId: string, params: EntityListPayload) => {
  return axios.get<EntitiesForType>(
    `${url}/v3/organizations/${API.config.organizationId}/teams/${teamId}/entities`,
    {
      params: params,
    },
  );
};
