import { Button, VStack } from '@chakra-ui/react';
import EmptyUI from 'library/atoms/Empty';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { EXTENSION_PATH } from 'views/main/routes/routes';

interface Iprops {
  action: string;
}
const IntegrateActionsUI: FC<Iprops> = ({ action }) => {
  const history = useHistory();

  return (
    <VStack justifyContent={'center'} mt={6}>
      <EmptyUI
        content={`${action} is not integrated. Please integrate to continue.`}
        circleH="250px"
        textSize="h2"
      />
      <Button onClick={() => history.replace(EXTENSION_PATH)}>Integrate {action}</Button>
    </VStack>
  );
};

export default IntegrateActionsUI;
